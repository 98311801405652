import { Component, Input, OnInit } from '@angular/core';
import { Liability, PayoffType } from 'src/app/models';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { SavingsOverTime } from '../debt-management-calculator/debt-management-calculator.component';
import { CalculatorHousingParams } from '../housing-calculator/housing-calculator.component';

@Component({
  selector: 'refi-benefits-calculator',
  templateUrl: 'refi-benefits-calculator.component.html',
  styleUrls: ['./refi-benefits-calculator.component.scss']
})
export class RefiBenefitsCalculatorComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  currentLoanInterestRate: number;

  @Input()
  currentLoanTerm: number;

  borrowerName: string;

  currentParams = new CalculatorHousingParams();
  proposedParams = new CalculatorHousingParams();

  totalDebtToBePaidOffBalance: number = 0;
  totalDebtToBePaidOffMonthly: number = 0;

  newMonthlyPayment: number = 0;

  monthlyResidualIncomeIncrease: number = 0;
  currentPandI: number = 0;
  proposedPandI: number = 0;

  savings: SavingsOverTime[] = [];

  liabilities: Liability[] = [];

  constructor() { }

  ngOnInit() {
    this.findPrimaryBorrowerAndGetBorrowerName();
    this.calculateCurrentAndProposedHousingParams();
    this.liabilities = this.getDebtsOtherThanMortgageForSubjectProperty();
    this.calculateTotalDebtToBePaidOff();
    this.newMonthlyPayment = this.calculateNewMonthlyPayment();
    this.monthlyResidualIncomeIncrease = this.calculateMonthlyResidualIncomeIncrease();
    this.savings = this.calculataSavingsYearByYear();
  }

  private findPrimaryBorrowerAndGetBorrowerName = (): void => {
    const primaryBorrower = this.mortgage.borrowers.find(borrower => borrower.typeOfBorrower === "PrimaryBorrower");
    this.borrowerName = primaryBorrower.firstName + ' ' + primaryBorrower.lastName;
  }

  private calculateCurrentAndProposedHousingParams = (): void => {
    this.currentParams.hazard = (Number(this.mortgage.currentHousingExpense?.homeownersInsurance) || 0) + (Number(this.mortgage.currentHousingExpense?.supplementalPropertyInsurance) || 0);
    this.currentParams.hoa = Number(this.mortgage.currentHousingExpense?.homeownersAssociationDuesAndCondominiumFees) || 0;
    this.currentParams.interestRate = this.currentLoanInterestRate || 0;
    this.currentParams.pi = Number(this.mortgage.currentHousingExpense?.firstMortgagePrincipalAndInterest) || 0;
    this.currentParams.pmi = Number(this.mortgage.currentHousingExpense?.mortgageInsurance) || 0;
    this.currentParams.taxes = Number(this.mortgage.currentHousingExpense?.realEstateTax) || 0;
    this.currentParams.term = this.currentLoanTerm || 0;
    this.currentParams.piTi = this.currentParams.pi + this.currentParams.hazard + this.currentParams.taxes + this.currentParams.pmi;
    this.currentParams.totalHousing = this.currentParams.piTi + this.currentParams.hoa;

    this.proposedParams.hazard = (Number(this.mortgage.proposedHousingExpense.homeownersInsurance) || 0) + (Number(this.mortgage.proposedHousingExpense.supplementalPropertyInsurance) || 0);
    this.proposedParams.hoa = Number(this.mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees) || 0;
    this.proposedParams.interestRate = this.mortgage.mortgageTerm.interestRate ? this.mortgage.mortgageTerm.interestRate / 100 : 0;
    this.proposedParams.pi = this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest;
    this.proposedParams.pmi = Number(this.mortgage.proposedHousingExpense.mortgageInsurance) || 0;
    this.proposedParams.taxes = Number(this.mortgage.proposedHousingExpense.realEstateTax) || 0;
    this.proposedParams.term = Number(this.mortgage.mortgageTerm.noOfMonths) || 0;
    this.proposedParams.piTi = this.proposedParams.pi + this.proposedParams.hazard + this.proposedParams.taxes + this.proposedParams.pmi;
    this.proposedParams.totalHousing = this.proposedParams.piTi + this.proposedParams.hoa;
  }

  private calculateTotalDebtToBePaidOff = (): void => {
    this.totalDebtToBePaidOffMonthly = this.liabilities.map(l => l.monthlyPayment).reduce((prev, curr) => prev + curr, 0);
    this.totalDebtToBePaidOffBalance = this.liabilities.map(l => l.unpaidBalance).reduce((prev, curr) => prev + curr, 0);
  }

  private getDebtsOtherThanMortgageForSubjectProperty = (): Liability[] => {
    let liabilities = this.mortgage.liabilities.filter(l => l.payoffType == PayoffType.Full);

    let indexForLiabilityForSubjectProperty: number | undefined = undefined;
    for (let i = 0; i < liabilities.length; i++) {
      if (liabilities[i].reoId) {
        const reo = this.mortgage.realEstateOwned.find(reo => reo.reoId === liabilities[i].reoId);
        if (reo && this.mortgage.subjectProperty) {
          if (reo.address1.toLocaleLowerCase() === this.mortgage.subjectProperty.address1.toLocaleLowerCase() &&
            reo.city.toLocaleLowerCase() === this.mortgage.subjectProperty.city.toLocaleLowerCase() &&
            reo.state.toLocaleLowerCase() === this.mortgage.subjectProperty.state.toLocaleLowerCase() &&
            reo.zipCode.toLocaleLowerCase() === this.mortgage.subjectProperty.zipCode.toLocaleLowerCase()) {
            indexForLiabilityForSubjectProperty = i;
            break;
          }
        }
      }
    }

    if (indexForLiabilityForSubjectProperty >= 0) {
      liabilities.splice(indexForLiabilityForSubjectProperty, 1);
    }

    return liabilities;
  }

  private calculateNewMonthlyPayment = (): number => {
    let allLiabilitiesPayment: number = 0;
    let payOffLiabilitiesPayment: number = 0;
    this.mortgage.liabilities.forEach(liability => {
      allLiabilitiesPayment += liability.monthlyPayment;
    })
    this.liabilities.forEach(liability => {
      payOffLiabilitiesPayment += liability.monthlyPayment;
    })

    return allLiabilitiesPayment - payOffLiabilitiesPayment;
  }

  private calculateMonthlyResidualIncomeIncrease = (): number => {
    this.currentPandI = this.mortgage.currentHousingExpense?.firstMortgagePrincipalAndInterest || 0;
    this.proposedPandI = this.mortgage.proposedHousingExpense?.firstMortgagePrincipalAndInterest || 0;

    const savingsFromReducingMonthlyHousingExpenses = this.currentPandI - this.proposedPandI;

    return savingsFromReducingMonthlyHousingExpenses + this.totalDebtToBePaidOffMonthly;
  }

  private calculataSavingsYearByYear = (): SavingsOverTime[] => {
    let nowDate = new Date();
    let savings = [];

    for (let i = 0; i < 6; i++) {
      const saving = new SavingsOverTime(i == 0 ? nowDate : new Date("1-1-" + (nowDate.getFullYear() + i)), this.monthlyResidualIncomeIncrease * 2 + (12 * this.monthlyResidualIncomeIncrease * i));
      savings.push(saving);
    }

    return savings;
  }
}
