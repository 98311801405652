export enum CreditVendor {
  MeridianLinkSoftPull = 0,
  MeridianLinkHardPull,
  FannieMae,
  FreddieMac,
  FactualDataByCbc,
  SharperLending,
  EquifaxMortgageSolutions,
  Xactus,
  CredCoSoftPull,
  CredCoHardPull,
  FactualDataSoftPull,
  FactualDataHardPull,
  EncompassCredit,
  ISoftPull,
}

export enum CreditVendorEnum {
  MeridianLinkSoftPull = "MeridianLinkSoftPull",
  MeridianLinkHardPull = "MeridianLinkHardPull",
  FannieMae = "FannieMae",
  FreddieMac = "FreddieMac",
  FactualDataByCbc = "FactualDataByCbc",
  SharperLending = "SharperLending",
  EquifaxMortgageSolutions = "EquifaxMortgageSolutions",
  Xactus = "Xactus",
  CredCoSoftPull = "CredCoSoftPull",
  CredCoHardPull = "CredCoHardPull",
  FactualDataSoftPull = "FactualDataSoftPull",
  FactualDataHardPull = "FactualDataHardPull",
  EncompassCredit = "EncompassCredit",
  ISoftPull = "ISoftPull",
}
