<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <p-table #dt [value]="fileTrackingInfo" [paginator]="true" [rows]="25" *ngIf="fileTrackingInfo"
            [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['actionValue','dateCreated','userProfile.firstName','userProfile.lastName','userProfile.userName']"
            [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <span class="p-input-icon-right p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control" (ngModelChange)="dt.first=0"
                            (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'actionValue'">
                        Action
                        <p-sortIcon [field]="'actionValue'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="['userProfile.firstName', 'userProfile.lastName', 'userProfile.userName']">
                        By
                        <p-sortIcon [field]="['userProfile.firstName', 'userProfile.lastName', 'userProfile.userName']"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'dateCreated'">
                        Date Created
                        <p-sortIcon [field]="'dateCreated'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td> {{row.actionValue}} </td>
                    <td>
                        <span *ngIf="row.by!=null && (row.userProfile.firstName != null || row.userProfile.lastName != null)">
                            {{row.userProfile.firstName}} {{row.userProfile.lastName}}
                        </span>
                        <span *ngIf="row.by!=null && row.userProfile.firstName == null && row.userProfile.lastName == null">
                            {{row.userProfile.userName}}
                        </span>
                    </td>
                    <td> {{row.dateCreated | date: 'MM/dd/yyyy h:mma'}} </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="text-center"> No history found. </td>
                </tr>
            </ng-template>
        </p-table>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">Close</button>
</div>