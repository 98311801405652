<loading-indicator *ngIf="loadingLoanDocs" [loadingMessage]="'Loading loan docs, please wait...'">
</loading-indicator>
<div class="card" *ngIf="!loadingLoanDocs && !errorMessage">
  <div class="card-header">
    <h4 class="card-title"><i class="fas fa-files-o me-2"></i> Loan Docs</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="btn-toolbar" role="toolbar">
          <div class="btn-group">
            <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect"
              [ngClass]="{ current: docFilter === 'all' }" (click)="docFilter = 'all'">
              All
            </button>
            <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect"
              [ngClass]="{ current: docFilter === 'loda' }" (click)="docFilter = 'loda'">
              Loda
            </button>
            <button [ngShow]="!isTpoUser && userPermissions.losEnabled" type="button"
              class="btn btn-sm btn-outline-secondary waves-light waves-effect"
              [ngClass]="{ current: docFilter === 'los' }" (click)="docFilter = 'los'">
              LOS
            </button>
            <button *ngIf="!isTpoUser && userPermissions.losEnabled"
              [disabled]="!currentApplication?.losVendor || !currentApplication?.refNumber || !currentApplication?.losInstanceId"
              type="button" class="btn btn-primary btn-sm waves-effect waves-light ms-3 mx-1"
              (click)="onOpenSyncLodaDocWithLosClicked()">
              <i class="fas fa-sync-alt me-1"></i> Sync with LOS
            </button>
            <button type="button" class="btn btn-primary btn-sm waves-effect waves-light"
              [ngClass]="{ 'ms-2': isTpoUser || !userPermissions.losEnabled }" [routerLink]="[
                userType != 'tpo'
                  ? '/' +
                    userType +
                    '/export-documents/' +
                    currentApplication.applicationId
                  : '/' +
                    userType +
                    '/app-details/' +
                    currentApplication.applicationId +
                    '/export-documents'
              ]">
              <i class="fas fa-plus me-1"></i>
              Export Documents
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-2 text-end mt-1">
        <label style="vertical-align: sub"> Stack Order </label>
      </div>

      <div class="col-md-4">
        <select class="form-select" [(ngModel)]="selectedStackingOrderId" name="stackingOrderId"
          style="width: 100%; height: 36px" required (ngModelChange)="onStackingOrderChanged(selectedStackingOrderId)">
          <option [ngValue]="0">All</option>
          <option *ngFor="let stackingOrder of filteredStackingOrders" [ngValue]="stackingOrder.stakingOrderId">
            {{ stackingOrder.docType }}
          </option>
        </select>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 text-center mb-1">
          <button type="button" class="btn btn-primary btn-sm waves-effect waves-light" (click)="onAddLoanDocClicked()">
            <i class="fas fa-plus me-1"></i> Add Loan Doc
          </button>
        </div>
        <div class="col-md-12 text-center mb-1">
          <span> OR </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-3 row" style="border: 2px dashed #889988">
            <file-upload [(ngModel)]="uploadedFiles" multiple="true" name="files"
              (ngModelChange)="onFileUploadChanged(uploadedFiles)">
            </file-upload>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="accordion" id="accordion">
        <ng-container *ngFor="let docType of filteredDocumentTypes">
          <loan-docs-accordion (loanDocUpdated)="onLoanDocUpdated($event)" [docType]="docType"
            *ngIf="showDocType(docType)" [loanDocs]="loanDocs" [isTpoUser]="isTpoUser"
            [userPermissions]="userPermissions" [documentTypesPerRoles]="documentTypesPerRoles" [roles]="roles"
            [currentApplication]="currentApplication" [docFilter]="docFilter" [vendorsList]="vendorsList"
            [globalConfig]="globalConfig">
          </loan-docs-accordion>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="errorMessage" class="alert alert-outline-danger d-flex align-items-center mb-0" role="alert">
  <i class="ti ti-checks alert-icon me-2"></i>
  <strong>Error:</strong> {{ errorMessage }}
</div>
