import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { SelectedPriceCardViewModel } from '../../models/pricing/selected-price-card-view.model';
import { UpfrontCosts } from '../pricing-search-v2/pricing-search-v2.component';
import { ViewAdjustmentsDialogComponent } from '../view-adjustments-dialog/view-adjustments-dialog.component';

@Component({
  selector: 'selected-price-card',
  templateUrl: './selected-price-card.component.html',
  styleUrls: ['./selected-price-card.component.scss']
})
export class SelectedPriceCardComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  selectedPrice: SelectedPriceCardViewModel;

  @Input()
  upfrontCosts: UpfrontCosts;

  @Output()
  deleteRequested: EventEmitter<SelectedPriceCardViewModel> = new EventEmitter<SelectedPriceCardViewModel>();

  isRebate: boolean = false;

  pmiMipFfGfPercent = 0;

  constructor(private readonly injector: Injector, private readonly _modalService: NgbModal) {
    super(injector);
  }

  ngOnInit(): void {
    this.isRebate = !this.selectedPrice.discountDollars;
    this.pmiMipFfGfPercent = this.upfrontCosts.pmiMipFfGfPercent / 100;
  }

  onDeleteClicked = () => {
    this.deleteRequested.emit(this.selectedPrice);
  }

  onAdjustmentsClicked = () => {
    const modalRef = this._modalService.open(ViewAdjustmentsDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.adjustments = this.selectedPrice.adjustments;
  }
}
