<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row">
    <div class="col-lg-2">
      <div class="card">
        <div class="card-body">
          <div class="nav flex-column nav-tabs text-center" aria-orientation="vertical">
            <a class="nav-link waves-effect waves-light active" data-bs-toggle="pill" href="#profile">Profile</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#contact-info">Contact
              Info</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#state-licensing">State
              Licensing</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#user-lead-routing" *ngIf="isAdmin">
              Lead Routing
            </a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#application-settings">Application
              Settings</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#email-settings">Email
              Settings</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#email-templates">Email
              Templates</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#sms-settings">SMS
              Settings</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#sms-templates">SMS
              Templates</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#voicemail-templates">Voicemail
              Templates</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#integrations">Integrations</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#customer-retention">Customer
              Retention</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#support"
              *ngIf="applicationContext.userPermissions.superAdmin">Support</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill" href="#goals">Goals</a>
            <a class="nav-link waves-effect waves-light" data-bs-toggle="pill"
              href="#prequal-letter-generation-settings">Prequal Letter Generation Settings</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-10">
      <div class="tab-content">
        <ng-template [ngIf]="loading" [ngIfElse]="tabContent">
          <loading-indicator></loading-indicator>
        </ng-template>
        <ng-template #tabContent>
          <div class="tab-pane fade active show" id="profile">
            <app-basic-profile [profile]="userProfile.userProfile" [timezones]="timezones"
              [userImage]="userProfile.userImage" [signatureImage]="userProfile.signatureImage">
            </app-basic-profile>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="contact-info">
            <app-contact [profile]="userProfile.userProfile" [states]="configs.states"></app-contact>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="state-licensing">
            <state-licensing [userCompanyGuid]="userProfile.userProfile.userCompanyGuid"></state-licensing>
          </div>
          <div class="tab-pane fade" id="user-lead-routing">
            <user-lead-routing [userProfile]="userProfile.userProfile">
            </user-lead-routing>
          </div>
          <div class="tab-pane fade" id="application-settings">
            <app-application-settings [profile]="userProfile.userProfile" [docTypes]="configs.stackingOrderDocTypes"
              [allowCancelEsignOverride]="userProfile.allowCancelEsignOverride"
              [allowCopyLoanOverride]="userProfile.allowCopyLoanOverride"
              [allowDeleteTaskOverride]="userProfile.allowDeleteTaskOverride">
            </app-application-settings>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="email-settings">
            <profile-email-settings 
              *ngIf="userProfile" 
              [profile]="userProfile" 
              [tenantKvp]="tenantKvp"
              [isSelfUserProfile]="isSelfUserProfile">
            </profile-email-settings>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="email-templates">
            <app-email-templates #emailTemplates [(definedEmailTemplate)]="userProfile.definedEmailTemplate"
              [configs]="configs"></app-email-templates>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="sms-settings">
            <profile-sms-settings
              [userProfile]="userProfile"
              [configs]="configs"
              [smsAlertsEnabled]="applicationContext.userPermissions.smsAlertsEnabled">
            </profile-sms-settings>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="sms-templates">
            <app-sms-templates [userProfile]="userProfile" [definedSmsTemplate]="userProfile.definedSmsTemplate"
              [configs]="configs" [smsAlertsEnabled]="applicationContext.userPermissions.smsAlertsEnabled"
              [availableMergeFields]="availableMergeFields">
            </app-sms-templates>
            <ng-container *ngTemplateOutlet="saveSection"></ng-container>
          </div>
          <div class="tab-pane fade" id="voicemail-templates">
            <app-voice-mail-templates [userCompanyGuid]="userProfile.userProfile.userCompanyGuid">
            </app-voice-mail-templates>
          </div>
          <div class="tab-pane fade" id="integrations">
            <div class="card">
              <div class="card-body">
                <integrations 
                  [scope]="userProfile.userProfile.userType === UserType.Tpo ? 'TpoUser' : 'User'" 
                  [profileLevel]="userProfile" 
                  [systemLevel]="systemLevel" 
                  [externalCompanyId]="externalCompanyId">
                </integrations>
              </div>
            </div>
            <div></div>
          </div>
          <div class="tab-pane fade" id="customer-retention">
            <customer-retention></customer-retention>
          </div>
          <div class="tab-pane fade" id="support">
            <app-support [userCompanyGuid]="userProfile.userProfile.userCompanyGuid"></app-support>
          </div>
          <div class='tab-pane fade' id='goals'>
            <user-goals></user-goals>
          </div>
          <div class='tab-pane fade' id='prequal-letter-generation-settings'>
            <div class="card">
              <div class="card-body">
                <prequal-letter-settings [prequalGenerationSettings]="prequalGenerationSettings" [type]="'user'"
                  [userCompanyGuid]="userCompanyGuid">
                </prequal-letter-settings>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #saveSection>
          <div class="d-flex justify-content-end">
            <button *ngIf="!isSelfUserProfile" type="button" class="btn btn-secondary ms-2"
              [routerLink]="externalCompanyId ? ['/admin/external-companies/' + externalCompanyId] : ['/admin/company/users']">
              Cancel
            </button>
            <button type="button" class="btn btn-primary ms-2" [disabled]="saveInProgress" (click)="saveInfo()">
              <span *ngIf="!saveInProgress"><i class="fa fa-save me-2"></i> Save</span>
              <span *ngIf="saveInProgress">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </span>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</admin-page-template>
