<div class='modal-header'>
  <h5 class='modal-title'>Add Annual Usage</h5>
  <button type='button' class='btn-close' (click)='activeModal.dismiss()' aria-label='Close'></button>
</div>

<div class='modal-body'>
  <input type='number'
         class='form-control numeric-without-arrows'
         [(ngModel)]='annualUsage'
         name='ls_usage_annual'
         placeholder='Annual Usage (KWh)'
         numeric
         [allowNegative]='false'
  >
</div>

<div class='modal-footer'>
  <button type='button' class='btn btn-secondary' (click)='activeModal.dismiss()'>Cancel</button>
  <button type='button' class='btn btn-primary' (click)='onApply()'>Apply</button>
</div>
