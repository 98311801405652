<div class="accordion-item" *ngIf="document">
    <h5 class="accordion-header m-0" id="headingCopyApp{{document.documentTypeId}}">
        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapseCopyApp'+document.documentTypeId" aria-expanded="true"
            [attr.aria-controls]="'#collapseCopyApp'+document.documentTypeId">
            {{document.documentTypeName}}
        </button>
    </h5>
    <div id="collapseCopyApp{{document.documentTypeId}}" class="accordion-collapse collapse"
        aria-labelledby="headingCopyApp{{document.documentTypeId}}" data-bs-parent="#accordionCopyApp">
        <div class="accordion-body">
            <p-table #dt1 [value]="filteredDocs" [paginator]="true" [rows]="25" *ngIf="filteredDocs"
                [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true" [globalFilterFields]="['description']"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines" [(selection)]="selectedRows">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control"
                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                (ngModelChange)="dt1.first=0"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">
                            <p-tableHeaderCheckbox (click)="onSelectAllByDocumentClicked(document.documentTypeId, rowHeaderCheckBox.checked)"
                                #rowHeaderCheckBox>
                            </p-tableHeaderCheckbox>
                        </th>
                        <th [pSortableColumn]="'description'">
                            Description
                            <p-sortIcon [field]="'description'"></p-sortIcon>
                        </th>
                        <th>
                            View File
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr *ngIf="doc.documentTypeId == document.documentTypeId">
                        <td class="text-center">
                            <p-tableCheckbox *ngIf="doc.docFiles.length > 0" [value]="doc"
                                [id]="doc.loanDocId + '_' + rowIndex"
                                (click)="onToggleCheckboxClicked(doc, rowCheckBox.checked)" #rowCheckBox>
                            </p-tableCheckbox>
                        </td>
                        <td> {{doc.description}} </td>
                        <td>
                            <div *ngFor="let file of doc.docFiles">
                                <input type="checkbox" value="{{file.guid}}" [(ngModel)]="file.isSelected"
                                    [checked]="file.isSelected" /> {{file.fileName}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9" class="text-center"> No document found. </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>