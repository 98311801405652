import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ThirdPartyCredential } from 'src/app/models/third-party/third-party-creds.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';

@Component({
  selector: 'truv-asset-verification-modal',
  templateUrl: './truv-asset-verification-modal.component.html',
  styleUrls: ['./truv-asset-verification-modal.component.scss']
})
export class TruvAssetVerificationModal extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  credential: ThirdPartyCredential;
  isSaving: boolean;

  keyValuePairs = {
    clientId: null,
  };

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _notificationService: NotificationService,
    public activeModal: NgbActiveModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keyValuePairs.clientId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'ClientId') || {};
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);
    this.isSaving = true;
    this.credential.companyId = this.applicationContext.userPermissions.companyId;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}
