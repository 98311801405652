import {AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, Renderer2} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { MiPaymentType, MiQuoteSearchResult } from 'src/app/models/mi-quote-search-result.model';
import { Constants } from 'src/app/services/constants';
import { Price, PricedProductRow } from '../../models/pricing/priced-product-row.model';
import { PricingRunResult } from '../../models/pricing/pricing-run-result.model';
import { ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';
import { BaseProduct, Product } from '../../models/pricing/product-search-result.model';
import { Rate } from '../../models/pricing/rate.model';
import { MiQuoteSearchDialogComponent, SelectedMiQuoteInfo } from '../mi-quote-search-dialog/mi-quote-search-dialog.component';
import { MiQuoteSelectDialogComponent } from '../mi-quote-select-dialog/mi-quote-select-dialog.component';
import { PricingTransactionType } from '../pricing-details/pricing-details.component';
import { PinnedProduct, PricedScenario, Scenario } from '../pricing-search-v2/pricing-search-v2.component';
import {ApplicationContextBoundComponent} from '../../../../shared/components';

@Component({
  selector: 'product-search-results',
  templateUrl: 'product-search-results.component.html',
  styleUrls: ['product-search-results.component.scss']
})
export class ProductSearchResultsComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  pricedProducts: PricedProductRow[] = [];

  filteredProducts: PricedProductRow[] = [];

  searchId: string;

  isTpo: boolean = false;

  @Input()
  canPriceApplyToLoan: boolean;

  @Input()
  lockStatus: string;

  get transactionType(): PricingTransactionType {
    return this._transactionType;
  }

  @Input()
  set transactionType(value: PricingTransactionType) {
    this._transactionType = value;
    if (value) {
      if (value == PricingTransactionType.reprice) {
        this.isReprice = true;
      }
      else if (value == PricingTransactionType.programChange) {
        this.isProgramChange = true
      }
    }
  }

  @Input()
  requestLockEnabled: boolean = false;

  @Input()
  hideQmColumn: boolean = false;

  @Input()
  miIntegrationEnabled: boolean = false;

  @Input()
  isShowVendorsPanel: boolean = false;

  @Input()
  existingPinnedPricesByScenario: Scenario[];

  @Output()
  addToScenariosRequested: EventEmitter<PricedScenario> = new EventEmitter<PricedScenario>();

  @Output()
  createToScenarioRequested: EventEmitter<{ product: BaseProduct, price: Price, pricedScenario: PricedScenario }> = new EventEmitter<{ product: BaseProduct, price: Price, pricedScenario: PricedScenario }>();

  @Output()
  showLoanQuoteRequested: EventEmitter<{ product: BaseProduct, price: Price, pricedScenario: PricedScenario }> = new EventEmitter<{ product: BaseProduct, price: Price, pricedScenario: PricedScenario }>();

  @Output()
  requestLockClicked: EventEmitter<{ product: BaseProduct, price: Price, pricedScenario: PricedScenario }> = new EventEmitter<{ product: BaseProduct, price: Price, pricedScenario: PricedScenario }>();

  private _pricedScenarios: PricedScenario[] = [];

  @Input()
  set pricedScenarios(pricedScenarios: PricedScenario[]) {
    this._pricedScenarios = pricedScenarios;
    this.checkPinnedProducts(pricedScenarios)
  }

  get pricedScenarios(): PricedScenario[] {
    return this._pricedScenarios;
  }

  @Input()
  set pricingRunResult(pricingRunResult: PricingRunResult) {
    pricingRunResult.eligibleProducts = this.customSort(pricingRunResult.eligibleProducts);
    this.searchId = pricingRunResult.searchId;
    this.pricedProducts = pricingRunResult.eligibleProducts.concat(pricingRunResult.ineligibleProducts);
    this.showMI = !this.isTpo && (this.showMI || pricingRunResult.eligibleProducts.some(x => x.product.miRequiredOver80Ltv == "true" && this.miIntegrationEnabled) || pricingRunResult.eligibleProducts.some(x => x.prices.some(y => y.monthlyMi > 0)));
  }

  get pricingRunRequest(): ProductSearchRequest | undefined {
    return this._pricingRunRequest;
  }
  @Input()
  set pricingRunRequest(value: ProductSearchRequest | undefined) {
    this._pricingRunRequest = value;
  }

  @Output()
  ineligibleProductDetailsClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  productDetailsClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  productPriceSelectedForApplication: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  productPriceRepricedForApplication: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  productPriceProgramChangeForApplication: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  qualifiedMortgageDetailsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pricePinned: EventEmitter<ProductPricePinEvent> = new EventEmitter<ProductPricePinEvent>();

  @Output()
  priceUnpinned: EventEmitter<ProductPricePinEvent> = new EventEmitter<ProductPricePinEvent>();

  eligibleProductRates: Map<string, Rate[]> = new Map<string, Rate[]>();

  productsTable: any;

  eligibleProductsTable = null;

  allProductsTable = null;

  InEligibleProductsTable = null;

  isClicked: boolean = false;

  filter: string = "eligible";

  combinations: any[] = [];

  showMI: boolean = false;

  isReprice: boolean = false;
  isProgramChange: boolean = false;
  pinnedProductTableNotEmpty: boolean = false;

  private _lastPinnedPrice: Price;

  private _lastPinnedPrices: Price[] = [];

  private _pricingRunRequest: ProductSearchRequest | undefined;

  private _transactionType: PricingTransactionType = null;

  constructor(private readonly _modalService: NgbModal,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private readonly injector: Injector,
  ) {
    super(injector);
    this.isTpo = this.applicationContext.isTpo;
  }

  customSort = (pricedProducts: PricedProductRow[]) => {
    pricedProducts.forEach(product => {
      product['parPrice'] = product.prices[0].price;
      product['parRate'] = product.prices[0].rate;
    });

    const fields: string[] = ['parRate', 'parPrice'];
    const orders: any[] = ['asc', 'desc'];

    let sortedPricedProducts = _.orderBy(pricedProducts, fields, orders);

    return sortedPricedProducts;
  }

  onIneligibleProductDetailsClicked = (productId: number) => {
    this.ineligibleProductDetailsClicked.emit(productId);
  }

  onProductDetailsClicked = (productId: number) => {
    this.productDetailsClicked.emit(productId);
  }

  onQualifiedMortgageClicked = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    this.qualifiedMortgageDetailsClicked.emit({ productId: product.productId, lockPeriod: price.lockPeriod, rate: price.rate, psRequest: pricedScenario?.pricingScenario });
  }

  onSelectProductPrice = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    this.productPriceSelectedForApplication.emit({ productId: product.productId, price: price, pricingScenario: pricedScenario?.pricingScenario });
  }

  onRepriceProductPrice = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    this.productPriceRepricedForApplication.emit({ product: product, price: price, pricingScenario: pricedScenario?.pricingScenario });
  }

  onProgramChangeProductPrice = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    this.productPriceProgramChangeForApplication.emit({ product: product, price: price, pricingScenario: pricedScenario?.pricingScenario });
  }

  onFilterChanged = () => {
    let filteredProducts = [];
    switch (this.filter) {
      case "eligible":
        filteredProducts = this.pricedProducts.filter(p => p.isEligible);
        break;
      case "ineligible":
        filteredProducts = this.pricedProducts.filter(p => !p.isEligible);
        break;
      case "all":
        const eligibleProducts = _.orderBy(this.pricedProducts.filter(p => p.isEligible), ['productName'], ['asc']);
        const ineligibleProducts = _.orderBy(this.pricedProducts.filter(p => !p.isEligible), ['productName'], ['asc']);
        this.filteredProducts = [...eligibleProducts, ...ineligibleProducts];
        return;
    }
    this.filteredProducts = _.orderBy(filteredProducts, ['productName'], ['asc']);
  }

  onPinClicked = (product: PricedProductRow, price: Price) => {
    price.isPinned = !price.isPinned;
    this._lastPinnedPrice = price;
    this._lastPinnedPrices.push(price);
    const event: ProductPricePinEvent = {
      product: product,
      price: price,
    }
    if (price.isPinned) {
      this.pricePinned.emit(event);
    } else {
      this.priceUnpinned.emit(event);
    }
  }

  onPinClickedFromPinnedScenarios = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    const event: ProductPricePinEvent = {
      product: product,
      price: price,
      scenarioKey: pricedScenario.scenarioKey
    }
    this.priceUnpinned.emit(event);
  }

  unpinLastPin = () => {
    this._lastPinnedPrice.isPinned = false;
  }

  unpinPrice = (lockPeriod: number, rate: number) => {
    this._lastPinnedPrices.forEach(price => {
      if (price.lockPeriod == lockPeriod && price.rate == rate) {
        price.isPinned = false;
      }
    })
  }

  onCreateScenarioClicked = (product: PinnedProduct, price: Price, pricedScenario: PricedScenario) => {
    const p = product.product.product;
    this.createToScenarioRequested.emit({ product: p, price, pricedScenario });
  }

  onShowLoanQuoteClicked = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    this.showLoanQuoteRequested.emit({ product: product.product, price, pricedScenario });
  }

  onAddScenarioClicked = (pricedScenario: PricedScenario) => {
    this.addToScenariosRequested.emit(pricedScenario);
  }

  onRequestLockClicked = (product: PricedProductRow, price: Price, pricedScenario: PricedScenario) => {
    const p = product.product;
    this.requestLockClicked.emit({ product: p, price, pricedScenario });
  }

  ngOnInit() {
    if (this.lockStatus == 'Requested' || this.lockStatus == 'Accepted') {
      this.canPriceApplyToLoan = false;
    }
    this.onFilterChanged();
    if (this.existingPinnedPricesByScenario) {
      const groupedSearchCriterias = _.groupBy(this.existingPinnedPricesByScenario, item => `"${item.requestInfo.loanInformation.baseLoanAmount}+${item.requestInfo.loanInformation.ltv}+${item.requestInfo.loanInformation.loanPurpose}"`);
      Object.keys(groupedSearchCriterias).forEach(key => {
        let combination = groupedSearchCriterias[key];
        let scenarios: PinnedScenariosByProductName[] = [];
        combination.forEach(scenario => {
          scenario.pinnedPrices.forEach(price => {
            scenarios.push({
              productName: scenario.productName,
              price: price,
              baseLoanAmount: scenario.requestInfo.loanInformation.baseLoanAmount,
              ltv: scenario.requestInfo.loanInformation.ltv,
              loanPurpose: scenario.requestInfo.loanInformation.loanPurpose,
              rate: scenario.pinnedPrice.rate,
              lockPeriod: scenario.pinnedPrice.lockPeriod,
              pI: scenario.pinnedPrice.principalAndInterest,
              pITI: scenario.pinnedPrice.principalAndInterestTaxesInsurance,
              isEligible: scenario.isEligible
            })
          })
        })
        this.combinations.push({ scenarios: scenarios });
      })
    }
  }

  ngAfterViewInit(): void {
    this.updateTableHeight();
  }

  getSelectedRates = (product: Product): Rate[] => {
    if (this.eligibleProductRates.has(product.productId)) {
      return this.eligibleProductRates.get(product.productId);
    }
    return [];
  }

  onGetMiClicked = (product: PricedProductRow, price: Price) => {
    const modalRef = this._modalService.open(MiQuoteSearchDialogComponent, {
      windowClass: 'modal-w-90',
      backdrop: 'static',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.interestRate = price.rate;
    modalRef.componentInstance.pricingRunRequest = this.pricingRunRequest;
    modalRef.componentInstance.product = product;
    modalRef.result.then((data: SelectedMiQuoteInfo) => {
      price.selectedMiQuote = data.selectedMiQuote;
      price.miQuotes = data.quoteResults;

      if (data.selectedMiQuote && data.selectedMiQuote.paymentType == MiPaymentType.PeriodicMonthly) {
        price.monthlyMi = data.selectedMiQuote.premiumAmount;
      }

    }, () => {
    });

  }

  onSelectMiQuoteClicked = (product: PricedProductRow, price: Price) => {
    const modalRef = this._modalService.open(MiQuoteSelectDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.interestRate = price.rate;
    modalRef.componentInstance.searchResult = { results: price.miQuotes, errors: [] } as MiQuoteSearchResult;
    modalRef.componentInstance.selectedMiQuoteInfo = price.selectedMiQuote;

    modalRef.result.then((data: SelectedMiQuoteInfo) => {
      price.selectedMiQuote = data.selectedMiQuote;
      price.miQuotes = data.quoteResults;

      if (data.selectedMiQuote && data.selectedMiQuote.paymentType == MiPaymentType.PeriodicMonthly) {
        price.monthlyMi = data.selectedMiQuote.premiumAmount;
      }

    }, () => {
    });
  }

  private checkPinnedProducts(pricedScenarios: PricedScenario[]) {
    this.pinnedProductTableNotEmpty = pricedScenarios.some(
      scenario => scenario.pinnedProducts.length > 0
    );
  }

  private updateTableHeight(): void {
    const dataTableWrapper = this.elRef.nativeElement.querySelector('.p-datatable-wrapper');

    if (this.isShowVendorsPanel) {
      this.renderer.setStyle(dataTableWrapper, 'height', 'calc(100vh - 322px)', 1);
    } else {
      this.renderer.setStyle(dataTableWrapper, 'height', 'calc(100vh - 273px)', 1);
    }
  }

}

export class PinnedScenariosByProductName {
  productName: string;
  price: number;
  baseLoanAmount: number;
  ltv: number;
  loanPurpose: string;
  isEligible: boolean;
  pI: number;
  pITI: number;
  rate: number;
  lockPeriod: number;
}

export class ProductPricePinEvent {
  product: PricedProductRow;
  price: Price;
  scenarioKey?: string;
}

