import { PricingEngineVendor } from "src/app/modules/admin/tpo-config/models/configuration-product.model";
import { PricingRateCreditApplicationMethod } from "./rate.model";
import { MiQuoteDetail } from "src/app/models/mi-quote-search-result.model";
import { AlignmentRoleUsers } from "src/app/modules/new-application/models/process-submission.model";

export class ProductSearchRequestInfo {
  request: ProductSearchRequest
  disableCreditScore: boolean;
  disableDTI: boolean;

  constructor() {
    this.request = new ProductSearchRequest();
    this.disableCreditScore = false;
    this.disableDTI = false;
  }
}
export class ProductSearchRequest {
  version: string;
  thirdPartySearchId: string;
  companyTimezone: string;
  borrowerInformation: BorrowerInformation;
  loanInformation: LoanInformation;
  compInformation: CompInformation;
  propertyInformation: PropertyInformation;
  qmInformation: QmInformation;
  loanLevelDebtToIncomeRatio: number;
  representativeCreditScore: number;
  buydown: string;
  productIds: Array<string> = [];
  amortizationTypes: Array<string> = [];
  armFixedTerms: Array<number> = [];
  loanTerms: Array<number> = [];
  loanTypes: Array<string> = [];
  productTypes: Array<string> = [];
  interestOnly: boolean;
  desiredLockPeriod: number;
  desiredPrice: number;
  desiredRate: number;
  historicalSearchDate: string;
  historicalRateSheetId: string;
  optimalBlueOriginatorId: number;
  optimalBlueChannelId: number;
  applicationId: number;
  credentialId: number;
  pricingChannelId: string | number;
  tpoOrgId: string;
  wholesalePriceGroup: string;
  correspondentPriceGroup: string;
  nonDelegatedPriceGroup: string;
  channel: string;
  pricingSearchMode: PricingSearchMode;

  taxesAndInsurance: number; //

  constructor() {
    this.borrowerInformation = new BorrowerInformation();
    this.loanInformation = new LoanInformation();
    this.propertyInformation = new PropertyInformation();
    this.qmInformation = new QmInformation();
    this.compInformation = new CompInformation();
  }
}

export class CompInformation {
  fixedAmount: number;
  percent: number;
  minAmount: number;
  maxAmount: number;
  calculatedAdjustment: number;
  calculatedAmount: number;
  includeLoCompensationInPricing: LoCompensationInPricing = LoCompensationInPricing.LenderPaid;
  lenderFee: number;
  rollLenderFee: boolean;
  percentBasedOn: CompensationPercentBasedOn = CompensationPercentBasedOn.TotalLoanAmount;
}

export enum LoCompensationInPricing {
  BorrowerPaid = 'BorrowerPaid',
  LenderPaid = 'LenderPaid',
  Correspondent = 'Correspondent'
}

export enum CompensationPercentBasedOn {
  TotalLoanAmount = 'TotalLoanAmount',
  LoanAmount = 'LoanAmount'
}

export class BorrowerInformation {
  address1: string;
  address2: string;
  applicationDate: string;
  assetDocumentation: string;
  bankruptcy: string = "";
  bankruptcyChapter: string;
  bankruptcyDisposition: string;
  bankStatementsForIncome: string;
  citizenship: string;
  city: string;
  country: string;
  dateOfBirth: string;
  debtToIncomeRatio: number;
  disclosureDate: string;
  email: string;
  employmentDocumentation: string;
  fico: number;
  firstName: string;
  firstTimeHomeBuyer: boolean;
  foreclosure: string;
  homePhone: string;
  incomeDocumentation: string;
  lastName: string;
  middleName: string;
  monthlyIncome: number;
  monthsReserves: number;
  mortgageLatesRolling: number;
  mortgageLatesX120: number;
  mortgageLatesX30: number;
  mortgageLatesX60: number;
  mortgageLatesX90: number;
  pledgedAssets: boolean;
  selfEmployed: boolean;
  ssn: string;
  state: string;
  suffix: string;
  typeOfVeteran: string;
  vaFirstTimeUse: boolean;
  workPhone: string;
  zipCode: string;
  totalLiquidAssets: number;
  numberOfFinancedProperties: number;
}

export class LoanInformation {
  assetDepletion: boolean;
  autoDebit: boolean;
  automatedUnderwritingSystem: string;
  documentationType: string;
  baseLoanAmount: number;
  borrowerPaidMi: boolean;
  calculateTotalLoanAmount: boolean;
  cashOutAmount: number;
  communityAffordableSecond: boolean;
  currentServicer: string;
  waiveEscrows: boolean;
  intentToOccupy: boolean;
  nonOccupyingCoBorrower: boolean;
  employeeLoan: boolean;
  taxesAndInsuranceMonthly: number;
  exemptFromVaFundingFee: boolean;
  expandedGuidelines: ExpandedGuidelines;
  externalStatus: number;
  fhaCaseAssigned: string;
  fhaCaseEndorsement: string;
  firstLienAmount: number;
  helocDrawnAmount: number;
  helocLineAmount: number;
  interestOnlyTerm: number;
  leadSource: number;
  lienType: string;
  loanPurpose: string;
  refinancePurpose: string;
  // loanType: string[];
  loanType: string;
  prepaymentPenalty: string;
  secondLienAmount: number;
  totalLoanAmount: number;
  upfrontPmiMipFfGfAmount: number;
  upfrontPmiMipFfGfFinancedAmount: number;
  upfrontPmiMipFfGfPaidinCash: number;
  upfrontPmiMipFfGfPercent: number;
  includeFeesInBasePrice: boolean;
  customFieldValues: Array<CustomFieldValue> = [];
  isStudentLoanCashout: boolean;
  ltv: number; // This is ignored by the server
  cltv: number; // This is ignored by the server

  constructor() {
    this.expandedGuidelines = new ExpandedGuidelines();
  }
}

export class PropertyInformation {
  appraisedValue: number;
  city: string;
  construction: boolean;
  corporateRelocation: boolean;
  county: string;
  countyFips: string;
  numberOfStories: number;
  numberOfUnits: string;
  occupancy: string;
  propertyStreetAddress: string;
  attachmentType: string;
  constructionMethod: string;
  manufacturedHomeWidthType: string;
  projectType: string;
  projectDesignType: string;
  isPlannedUnitDevelopment: boolean;
  salesPrice: number;
  state: string;
  uniqueDwelling: string;
  zipCode: string;
  isNonWarrantableProject: boolean;
  isCondotel: boolean;
  isInRuralArea: boolean;
  isMixedUse: boolean;
  isSeasonalProperty: boolean;
  areaMedianIncome: number;
}

export class QmInformation {
  additionalQmFeeAmount: number;
  additionalQmFeesAffectApor: boolean;
  borrowerPaidLoCompensation: number;
  cashIncentiveDiscountAmount: number;
  creditInsuranceFeeAmount: number;
  documentPreperationFeeAmount: number;
  escrowTrusteeFeeAmount: number;
  financedPmi: number;
  notaryCreditReportFeeAmount: number;
  propertyAssessmentFeeAmount: number;
  refinancePrepaymentFeeAmount: number;
  titleFeeAmount: number;
}

export class ExpandedGuidelines {
  bankruptcyOutcome: string;
  bankruptcySeasoning: string;
  bankruptcyType: string;
  debtConsolidation: boolean;
  debtServiceCoverageRatio: number;
  housingEventSeasoning: string;
  housingEventType: string;
  incomeVerificationType: string;
  mortgageLatesx12012Mos: number;
  mortgageLatesx12013To24Mos: number;
  mortgageLatesx3012Mos: number;
  mortgageLatesx3013To24Mos: number;
  mortgageLatesx6012Mos: number;
  mortgageLatesx6013To24Mos: number;
  mortgageLatesx9012Mos: number;
  mortgageLatesx9013To24Mos: number;
  uniqueProperty: boolean | null;
  bankruptcyMonths: number;
  chargeOffMonths: number;
  deedinLieuMonths: number;
  defaultNoticeMonths: number;
  foreclosureMonths: number;
  loanModificationMonths: number;
  shortSaleMonths: number;
  incomeVerificationMethod: string;
  bankStatementExpenseMethod: string;
  monthsFullDoc: number;
  months1099: number;
  monthsCpaPandL: number;
  monthsPersonalBankStatements: number;
  monthsBusinessBankStatements: number;
  voeAmount: number;
  assetAmount: number;
  assetDepletionAmount: number;
  investorExperience: string;
  propertiesOwned: number;
  tradelineType: string;
  deedInLieuType: string;
  chareOffType: string;
  foreclosureType: string;
  shortSaleType: string;
  forbearanceType: string;
}

export class CustomFieldValue {
  customFieldId: string;
  name: string;
  value: string;

  constructor(customFieldId: string, name: string, value: string) {
    this.customFieldId = customFieldId;
    this.name = name;
    this.value = value || null;
  }
}

export class ForwardLockRequest {
  request: ProductSearchRequest;
  miQuoteDetail: MiQuoteDetail;
  losCredentialId: number
  losOptions?: Record<any, string>
  pricingEngineVendor?: PricingEngineVendor
  productId: string
  rate: number
  price: number
  lockTerm: number
  rateCreditApplicationMethod: PricingRateCreditApplicationMethod
  borrower_FirstName: string
  borrower_LastName: string
  borrower_EmailAddress: string
  borrower_IsNonOccupyingPrimaryBorrower: boolean
  borrower_NumberOfPropertiesOwned: number
  loan_IsRelocation: boolean
  loan_LoanServicer: string
  property_LotSizeInAcres: number
  primaryRoleUserId: string;
  additionalAlignmentRoleUsers: AlignmentRoleUsers;
}

export enum PricingSearchMode {
  Hypothetical = "Hypothetical",
  Assignment = "Assignment",
  Reprice = "Reprice",
  ProgramChange = "ProgramChange"
}
