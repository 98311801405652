import {Component, Input, OnInit} from '@angular/core';
import {TableColumn} from '../../../../../shared/models/tale-column.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {SystemLevelService} from '../../../../../services/system-level.service';
import {Constants} from '../../../../../services/constants';
import {GlobalEmailModalComponent} from './global-email-modal/global-email-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../../../../services/notification.service';
import { ThirdPartyCredential } from 'src/app/models';

@Component({
    selector: 'global-email',
    templateUrl: './global-email.component.html'
})
export class GlobalEmailComponent implements OnInit {
    @Input()
    microsoftGraph?: ThirdPartyCredential;

    globalFilterFields: string[] = ['name', 'serviceType', 'ipPoolName'];
    columns: TableColumn[] = [];
    services: any[] = [];
    isLoading: boolean = false;
    isDeleteClicked = {};

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _spinner: NgxSpinnerService,
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.columns = [
            {field: 'name', header: 'Name', visible: true},
            {field: 'serviceType', header: 'Service Type', visible: true},
            {field: 'ipPoolName', header: 'Ip Address Pool Name', visible: true}
        ];
        this.fetchAll();
    }

    private fetchAll() {
        this._systemLevelService
            .getGlobalServices('email')
            .subscribe({
                next: res => {
                    this.services = res;
                }
            });
    }

    public onAddEdit(row?): void {
        const modalRef = this._modalService.open(GlobalEmailModalComponent, Constants.modalOptions.medium);
        modalRef.componentInstance.service = row ? Object.assign({}, row) : { serviceType: '' };
        modalRef.componentInstance.microsoftGraph = this.microsoftGraph || {};
        modalRef.result.then(
            (result) => {
                if (row) {
                    this.services = this.services.map(el => el.id === row.id ? result.item2 : el);
                } else {
                    this.services = [
                        ...this.services,
                        ...[result]
                    ];
                }
            },
            () => {}
        );
    }

    public onDelete(row): void {
        this._spinner.show();
        this._systemLevelService.deleteGlobalService('email', row.id)
            .subscribe({
                next: res => {
                    this.services = this.services.filter(el => el.id !== row.id);
                    this._spinner.hide();
                },
                error: err => {
                    this._spinner.hide();
                    this._notificationService.showError(
                        err ? err.message : 'Unable to save global email.',
                        'Global Email'
                    );
                }
            });
    }
}
