import { Component, Injector, Input, OnInit } from '@angular/core';
import { Configuration } from '../../../../../models/configuration.model';
import { Company, RoleList, ThirdPartyCredential, ThirdPartyCredentialArray } from '../../../../../models';
import { Constants } from '../../../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { LenderConfigService } from '../../../../admin/lender-config/services/lender-config.service';
import { combineLatest } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'home-owners-insurance',
    templateUrl: './home-owners-insurance.component.html'
})
export class HomeOwnersInsuranceComponent extends ApplicationContextBoundComponent implements OnInit {
    @Input() company: Company;
    @Input() isHomeOwnersInsuranceEnabled: boolean;
    @Input() homeOwnersInsuranceEnabled: Configuration;

    constructor(
        private readonly injector: Injector
    ) {
        super(injector);
    }

}
