import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {autoId} from '../../../core/services/utils';
import {FeeViewModel} from '../fee-view-model';
import {FeeSection, FeeSectionViewType} from '../fee-section.model';
import {FormGroup, NgForm} from '@angular/forms';
import {FeeUpdater, FeeUpdaterService} from '../services/fee-updater.service';
import {CalculatedFeeType, FeeCalculatedValues} from '../fees.model';
import {cloneDeep} from 'lodash';
import {concatMap, filter, map, Observable, Subscription, take} from 'rxjs';

@Component({
  selector: 'fee-period-editor',
  templateUrl: './fee-period-editor.component.html',
  styleUrls: ['./fee-period-editor.component.scss'],
  providers: [FeeUpdaterService],
})
export class FeePeriodEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fee: FeeViewModel;
  @Input() feeSection: FeeSection;

  @Input() editable: boolean = true;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<FeeViewModel>();

  @ViewChild('form')
  protected ngForm: NgForm;

  private get _form(): FormGroup | undefined {
    return this.ngForm?.form;
  }

  protected get effectiveFee(): FeeViewModel {
    return this._feeUpdater.fee ?? this._fallbackFee;
  }

  protected get calculatedValues(): FeeCalculatedValues {
    return this.effectiveFee?.fee.calculatedValues ?? {};
  }

  protected get canEditBorrowerPaid(): boolean {
    const fee = this.effectiveFee.fee;

    return fee.isChargeUserEditable && fee.isChargeOverridden;
  }

  protected canBeImpound: boolean = false;

  private _id: string = autoId();

  private _monthlyFeeType: CalculatedFeeType = CalculatedFeeType.MonthlyPaymentPremium;

  private _feeUpdater?: FeeUpdater;

  // Placeholder for the input field binding
  private _fallbackFee = FeeViewModel.empty();

  private _updateFeesSubscription?: Subscription;
  private _saveSubscription?: Subscription;

  constructor(private readonly _feeUpdaterService: FeeUpdaterService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('feeSection' in changes) {
      this.canBeImpound = this.feeSection.type === FeeSectionViewType.Escrow;
    }

    if ('fee' in changes || 'feeSection' in changes) {
      this.initFeeUpdater();

      this.invalidateImpound();
      this.invalidateMonths();
    }

    if ('editable' in changes) {
      this.invalidateUpdatingFeesSubscriptionState();
    }
  }

  ngOnInit() {
    this.subscribeToUpdatingFees();
    if (this._updateFeesSubscription == null) {
      this.invalidateUpdatingFeesSubscriptionState();
    }
  }

  ngOnDestroy() {
    this._updateFeesSubscription?.unsubscribe();
    this._saveSubscription?.unsubscribe();
    this._feeUpdater?.destroy();
  }

  private initFeeUpdater(): void {
    this._feeUpdater?.destroy();
    this._feeUpdater = this._feeUpdaterService.createUpdater(this.fee);

    this.effectiveFee.fee.calculatedValues = cloneDeep(this.fee.fee.calculatedValues) ?? {};
  }

  private invalidateUpdatingFeesSubscriptionState(): void {
    if (this.editable) {
      this.subscribeToUpdatingFees();
    } else {
      this._updateFeesSubscription?.unsubscribe();
    }

    setTimeout(() => {
      this.setFormEnabled(this.editable);
    });
  }

  private subscribeToUpdatingFees(): void {
    this._updateFeesSubscription?.unsubscribe();
    this._updateFeesSubscription = this._feeUpdater.updatingState$.subscribe(isUpdating => {
      this.setFormEnabled(!isUpdating);
    });
  }

  private setFormEnabled(enabled: boolean): void {
    if (enabled) {
      this._form?.enable();
    } else {
      this._form?.disable();
    }
  }

  private waitForFeesToUpdate(): Observable<void> {
    return this._feeUpdater.updatingState$.pipe(
      filter(isUpdating => !isUpdating),
      take(1),
      map(() => undefined)
    );
  }

  protected onFieldChange<T>(previousValue: T, newValue: T): void {
    if (previousValue !== newValue) {
      this.updateFees();
    }
  }

  protected onImpoundChange(): void {
    this.invalidateMonths();

    if (this.effectiveFee.fee.isChargeUserEditable) {
      this.updateFees();
    }
  }

  protected updateFees(): void {
    this._feeUpdater.update();
  }

  private invalidateImpound(): void {
    const fee = this.effectiveFee.fee;

    if (fee.calculatedFeeType == this._monthlyFeeType && this.canBeImpound) {
      fee.isImpound = fee.calculatedValues.months > 0;
    }
  }

  private invalidateMonths(): void {
    const fee = this.effectiveFee.fee;
    const calculatedValues = fee.calculatedValues;
    const months = calculatedValues.months;

    calculatedValues.months = !this.canBeImpound || fee.isImpound ? months ?? 2 : 0;
  }

  protected getId(elementId: string): string {
    return `${elementId}-${this._id}`;
  }

  protected onCancel(): void {
    this.cancel.emit();
  }

  protected onSave(): void {
    this._saveSubscription?.unsubscribe();

    this._saveSubscription = this.waitForFeesToUpdate()
      .pipe(
        // Fees should already be updated on blur. Still, we want to make sure that the fees are
        // updated before we save the fee.
        concatMap(() => this._feeUpdater.updateWithResult())
      )
      .subscribe(result => {
        this.save.emit(result);
      });
  }
}
