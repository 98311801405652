<div class="modal-header">
  <h5 class="modal-title w-100">
    {{
      !feeDefinitionData || feeDefinitionData.feeDefinitionId == 0
        ? 'New'
        : 'Edit'
    }}
    {{ feeDefinitionData?.feeTemplateId ? 'Fee Template Fee' : 'Fee Definition'}} 
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <form
    id="feeDefinitionForm"
    #feeDefinitionForm="ngForm"
    name="feeDefinitionForm"
  >
    <fee-definition-details
      [feeDefinition]="feeDefinitionData"
      (feePercentChanged)="feePercentsValid = $event"
    ></fee-definition-details>
  </form>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary"
    [disabled]="isSaving || (!feePercentsValid && feeDefinitionData.feePercentOf)"
    (click)="save()"
  >
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
