import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(items: any[], searchFieldName: string, query: string): any {
        if (!searchFieldName || !query) {
            return items;
        }
        return items.filter(item => item[searchFieldName].toLowerCase().includes(query.toLowerCase()));
    }
}