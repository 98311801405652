import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PricingScenario } from '../../../models/pricing/pricing-scenario.model';
import * as _ from 'lodash';

@Component({
  selector: 'scenario-notes-editor',
  templateUrl: './scenario-notes-editor.component.html',
  styleUrls: ['./scenario-notes-editor.component.scss']
})
export class ScenarioNotesEditorComponent implements OnInit {

  @Input()
  set scenario(scenario: PricingScenario) {
    this._originalScenario = scenario;
    this._scenario = _.cloneDeep(scenario);
  }

  @Output()
  applied: EventEmitter<PricingScenario> = new EventEmitter<PricingScenario>();

  @Output()
  cancelled: EventEmitter<PricingScenario> = new EventEmitter<PricingScenario>();

  get scenario(): PricingScenario {
    return this._scenario;
  }

  private _scenario: PricingScenario;

  private _originalScenario: PricingScenario;

  constructor() {
  }

  ngOnInit(): void {
  }

  onApplyClicked = () => {
    this.applied.emit(this._scenario);
  }

  onCancelClicked = () => {
    this.cancelled.emit(this._originalScenario);
  }

}
