import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddApplicationComponent } from './components/add-application/add-application.component';
import { NewContactComponent } from './components/new-contact/new-contact.component';
import { ContactsComponent } from './contacts.component';
import { BorrowerEditorComponent } from '../borrower/components/borrower-editor/borrower-editor.component';

const routes: Routes = [
  {
    path: '',
    component: ContactsComponent,
  },
  {
    data: {
      breadcrumb: 'Add Contact',
    },
    path: 'add',
    component: NewContactComponent,
  },
  {
    data: {
      breadcrumb: 'New Contact',
    },
    path: 'new',
    component: NewContactComponent,
  },
  {
    data: {
      breadcrumb: 'Edit Contact',
    },
    path: ':id/details',
    component: BorrowerEditorComponent,
  },
  {
    data: {
      breadcrumb: 'Add Application',
    },
    path: ':id/add-application',
    component: AddApplicationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule { }
