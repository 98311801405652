import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { get, set } from 'lodash';
import { filter, fromEvent, Subscription, take } from 'rxjs';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'merge-field-context-menu',
  templateUrl: './merge-field-context-menu.component.html',
  styleUrls: ['./merge-field-context-menu.component.scss'],
})

export class MergeFieldContextMenuComponent implements OnInit {
  @ViewChild('search') search: ElementRef;
  @ViewChild('contextMenu') mergeFieldsMenu: TemplateRef<any>;

  @Input() menuItems: Array<EnumerationItem>;
  
  targetProperty: string;
  textEditor: any;
  searchMergeFieldText: string;
  targetObject: any;
  targetControl: any;

  overlayRef: OverlayRef | null;
  sub: Subscription;
  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) { }
  ngOnInit(): void {

  }

  show(event: MouseEvent, targetObject: any, targetProperty: string, textEditor: any) {
    this.close();
    this.targetControl = event.target;
    this.targetProperty = targetProperty;
    this.targetObject = targetObject;
    this.textEditor = textEditor;
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.mergeFieldsMenu, this.viewContainerRef)
    );

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 200);
  }

  close() {
    this.targetProperty = null;
    this.targetObject = null;
    this.textEditor = null;
    this.searchMergeFieldText = '';
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  mergeFieldClicked(selectedMergeField: string) {
    if (this.textEditor) {
      this.textEditor.editorInstance.model.change(writer => {
        this.textEditor.editorInstance.model
          .insertContent(writer.createText(`%${selectedMergeField}%`));
        this.textEditor.editorInstance.editing.view.focus();
      });
      this.close();
      return;
    }
    if (this.targetControl.selectionStart < 0 || this.targetControl.selectionEnd < 0) {
      return;
    }
    const existingText = get(this.targetObject, this.targetProperty, '');

    set(this.targetObject,
      this.targetProperty,
      existingText.substring(0, this.targetControl.selectionStart) +
      `%${selectedMergeField}%` +
      existingText.substring(this.targetControl.selectionEnd)
    );

    const newCaretPosition = this.targetControl.selectionStart + selectedMergeField.length + 2;
    setTimeout(() => {
      this.targetControl.focus()
      this.targetControl.setSelectionRange(newCaretPosition, newCaretPosition);
    });
    this.close();
  }
}
