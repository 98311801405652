<ng-container *ngIf="provider == 'Truv'">
  <div class="table-responsive">
    <table class="table mb-0 table-centered text-center">
      <thead class="table-light">
        <tr>
          <th>Borrower Name</th>
          <td>Task Status</td>
          <td>Order Status</td>
          <td>Date</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders">
          <td>
            {{order.firstName + ' ' + order.lastName}}
          </td>
          <td>
            {{order.taskStatus}}
          </td>
          <td>
            {{order['statusDescription']}}
          </td>
          <td>
            {{order.dateInserted | date: 'short'}}
          </td>
          <td>
            <ng-container *ngIf="order['loading']; else actions">
              <span class="spinner-border spinner-border-sm text-primary" role="status"></span>
            </ng-container>

            <ng-template #actions>
              <button class="btn btn-sm btn-outline-primary m-1" *ngIf="order.formFreeHistoryId" (click)="importEmployments(order)">
                Import Employments
              </button>

              <button class="btn btn-sm btn-outline-primary" *ngIf="order.latestReportId || order.reportData" (click)="getReport(order)">View
                Report</button>

              <button class="btn btn-sm btn-outline-primary m-1"
                *ngIf="order.formFreeHistoryId && (order.status == 'AccountConnected' || (order.latestReportId || order.reportData))"
                (click)="generateReport(order)">Generate Report
              </button>

              <button class="btn btn-sm btn-outline-primary m-1" *ngIf="order.isRepullReport"
                [disabled]="!order.latestReportId && !order.accountChekOrderId"
                (click)="repullReport(order)">Repull Report
              </button>

              <button class="btn btn-sm btn-outline-primary m-1" *ngIf="order.accountChekOrderId"
                (click)="processOrderEvents(order)">Process Events
              </button>

              <button *ngIf="!order.formFreeHistoryId" class="btn btn-sm btn-outline-primary"
                (click)="inviteBorrower(order)" [disabled]="order['inviting']">
                <span *ngIf="order['inviting']">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Inviting...
                </span>
                <span *ngIf="!order['inviting']">Invite To Complete</span>
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="provider == 'TWN'">
  <div class="card bg-light px-3 mb-2">
    <div class="row">
      <div class=" d-flex">
        <h6 class="col-sm-4">Run</h6>
        <h6 class="col-sm-4">Borrower Name</h6>
        <h6 class="col-sm-4">SSN</h6>
      </div>
    </div>
  </div>
  <div class="card" *ngFor="let borrower of mortgageBorowers; let borrowerIndex = index">
    <div class="card-body">
      <div class="accordion accordion-flush" id="accordionDetail_{{borrowerIndex}}">
        <div class="accordion-item">
          <div class="row">
            <div class="d-flex">
              <div class="col-sm-4 form-check form-switch mb-2 mb-lg-0" [ngClass]="{ 'p-0': isVoePullDisabled }">
                <p class="accordion-header m-0" id="heading{{borrowerIndex}}">
                  <input *ngIf="!isVoePullDisabled" class="form-check-input" type="checkbox" data-bs-toggle="collapse"
                    id="run_{{borrowerIndex}}" [attr.data-bs-target]="'#collapse'+borrowerIndex"
                    [attr.aria-expanded]="borrower.shouldAccordionExpanded"
                    [attr.aria-controls]="'collapse'+borrowerIndex" [(ngModel)]="borrower.shouldAccordionExpanded">
                  <i *ngIf="isVoePullDisabled" class="fas fa-exclamation-circle text-danger cursor--help"
                    title="TWN pull is not enabled for user role">
                  </i>
                </p>
              </div>
              <div class="col-sm-4 name"> {{borrower.firstName}} {{borrower.lastName}} </div>
              <div class="col-sm-4 ssn"> {{borrower.last4Ssn || '--'}} </div>
            </div>
          </div>
          <!-- todo make some animation when closing - issue with 'show' ngClass -->
          <div id="collapse{{borrowerIndex}}" class="accordion-collapse collapse mt-2"
            [ngClass]="{'show': borrower.shouldAccordionExpanded}" [attr.aria-labelledby]="'heading'+borrowerIndex"
            data-bs-parent="#accordionDetail_{{borrowerIndex}}">
            <div class="card mb-0 card-body">
              <div class="form-group row">
                <div class="form-group col-md-2 col-sm-4">
                  <label class="mb-2"><b>Salary Key</b></label>
                  <div>
                    <input type="text" id="salary-key" class="form-control" title="{{borrower.form.salaryKey}}"
                      [(ngModel)]="borrower.form.salaryKey" />
                  </div>
                </div>
                <div class="form-group col-md-2 col-sm-4">
                  <label class="mb-2"><b>Verify Type</b></label>
                  <div>
                    <select name="verifyType{{borrowerIndex}}" id="verifyType{{borrowerIndex}}" class="form-select"
                      title="{{displayVerifyTypeTitle(borrower.form.requestType)}}"
                      [(ngModel)]="borrower.form.requestType">
                      <option value="Employment">Employment Only</option>
                      <option value="IncomeAndEmployment">Employment + Income</option>
                    </select>
                  </div>
                </div>

                <div class="form-group col-md-2 col-sm-4">
                  <label class="mb-2"><b>Employer Name</b></label>
                  <div>
                    <input type="text" class="form-control" title="{{borrower.form.employerName}}"
                      [(ngModel)]="borrower.form.employerName" />
                  </div>
                </div>
                <!-- <div class="form-group col-md-2 col-sm-4">
                                <label class="mb-2"><b>Verify From</b></label>
                                <div>
                                    <select name="verifyFrom{{borrowerIndex}}" id="verifyFrom{{borrowerIndex}}"
                                        class="form-select"
                                        title="{{displayVerifyTypeFrom(borrower.form.employeeStatusFilter)}}"
                                        [(ngModel)]="borrower.form.employeeStatusFilter">
                                        <option value="Active">Current Employer(s)</option>
                                        <option value="Inactive">Previous Employer(s)</option>
                                        <option value="Active,Inactive">Current + Previous Employer(s)
                                        </option>
                                    </select>
                                </div>
                            </div> -->

                <div class="form-group col-md-2 col-sm-4">
                  <label class="mb-2"><b>Employer Code</b></label>
                  <div>
                    <input type="text" class="form-control" title="{{borrower.form.employerCode}}"
                      [(ngModel)]="borrower.form.employerCode" />
                  </div>
                </div>
                <div class="form-group col-md-2 col-sm-4">
                  <label class="mb-2"><b>Alternate ID</b></label>
                  <div>
                    <input type="text" class="form-control" title="{{borrower.form.alternateEmployeeId}}"
                      [(ngModel)]="borrower.form.alternateEmployeeId" />
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="mb-2"><b>Consent</b></label>
                <div>
                  <label class="text-left checkbox-align">
                    <input type="checkbox" [(ngModel)]="borrower.authorizedTheWorkNumber" />
                    Borrower has consented to digitally verifying Income and/or Employment
                  </label>
                </div>
              </div>
              <div class="separate-line"></div>
              <div class="text-center mb-4">
                <span>Drag employers from the digitally sourced employer to the matching 1003 employer.
                  Skipping this step might create duplicates.</span>
              </div>
              <div class="employment-detail row" cdkDropListGroup>
                <div class="col-md-5">

                  <span class="title">
                    1003 Employment
                  </span>
                  <div class="history" *ngFor="let employment of borrower.employments; let index = index"
                    [attr.data-allowdrop]="!borrower.digitallySourcedEmployments[index]?.isMatched"
                    (cdkDropListDropped)="dropEmployment(employment)"
                    [cdkDropListEnterPredicate]="isDropValid(borrower.digitallySourcedEmployments[index]?.isMatched)"
                    (cdkDropListExited)="exited($event)" (cdkDropListEntered)="entered($event)" cdkDropList>
                    <span>
                      {{employment?.employer}} Employer
                      <br>
                      {{employment?.position ? employment?.position : '--'}}
                      <br>
                      Employed {{employment?.duration}}
                      <ng-container *ngIf="borrower.form.requestType == 'IncomeAndEmployment'">
                        <br>
                        Monthly Base: {{(getMonthlyIncomeByType(employment?.incomes, 'Base') |
                        currency:'USD':'symbol':'.2':'en-US') || '-'}}
                        <br>
                        Monthly Bonus: {{(getMonthlyIncomeByType(employment?.incomes, 'Bonus') |
                        currency:'USD':'symbol':'.2':'en-US') || '-'}}
                        <br>
                        Monthly Comission: {{(getMonthlyIncomeByType(employment?.incomes, 'Commissions') |
                        currency:'USD':'symbol':'.2':'en-US') || '-'}}
                        <br>
                        Monthly Other: {{(getMonthlyIncomeByType(employment?.incomes, 'OtherTypesOfIncome') |
                        currency:'USD':'symbol':'.2':'en-US') || '-'}}
                        <br>
                        Monthly Overtime: {{(getMonthlyIncomeByType(employment?.incomes, 'Overtime') |
                        currency:'USD':'symbol':'.2':'en-US') || '-'}}
                      </ng-container>
                    </span>
                  </div>

                  <div class="history history-new text-center" (cdkDropListDropped)="addNewEmployment(borrower)"
                    data-allowdrop="true" cdkDropList>
                    <span class="order-vv-add-employment">+ Add New Employment</span>
                  </div>
                </div>

                <div class="col-md-5">
                  <span class="title">
                    Digitally Sourced Employment
                  </span>
                  <div *ngIf="borrower.digitallySourcedEmployments.length > 0" style="height: 100%" cdkDropList
                    [cdkDropListSortingDisabled]="true">
                    <div class="history drag-item"
                      [ngClass]="{'empty': !employment, 'drag-item--matched': employment?.isMatched,
                                            'incomeAndEmploymentHeight': borrower.form.requestType == 'IncomeAndEmployment'}"
                      *ngFor="let employment of borrower.digitallySourcedEmployments" [cdkDragData]="employment"
                      [cdkDragDisabled]="employment?.isMatched" (cdkDragStarted)="dragStarted($event, employment)"
                      (cdkDragEnded)="dragEnded($event)" cdkDrag>
                      <span>
                        {{employment?.employerWithStatus}}
                        <br>
                        {{employment?.position}}
                        <br>
                        Employed {{employment?.duration}}
                        <ng-container *ngIf="borrower.form.requestType == 'IncomeAndEmployment'">
                          <ng-container *ngFor="let income of (employment?.incomes | sort: 'desc':'year' | limitTo: 1)">
                            <br>Monthly Base: {{income.base ?
                            (calculateMonthlyIncome(income.base) | currency:'USD':'symbol':'.2':'en-US') : '-'}}
                            <br>Monthly Bonus: {{income.bonus ?
                            (calculateMonthlyIncome(income.bonus) | currency:'USD':'symbol':'.2':'en-US') : '-'}}
                            <br>Monthly Comission: {{income.comission ?
                            (calculateMonthlyIncome(income.comission) | currency:'USD':'symbol':'.2':'en-US') : '-'}}
                            <br>Monthly Other: {{income.other ?
                            (calculateMonthlyIncome(income.other) | currency:'USD':'symbol':'.2':'en-US') : '-'}}
                            <br>Monthly Overtime: {{income.overtime ?
                            (calculateMonthlyIncome(income.overtime) | currency:'USD':'symbol':'.2':'en-US') : '-'}}
                          </ng-container>
                        </ng-container>

                      </span>
                    </div>
                  </div>
                  <div class="history ds-employment" *ngIf="borrower.digitallySourcedEmployments.length === 0">
                    <span>
                      No record found.
                    </span>
                  </div>
                </div>
                <div class="col-md-2">
                  <span class="title">
                    1003 Match
                  </span>
                  <div class="match-status" [ngClass]="{'income': borrower.form.requestType === 'IncomeAndEmployment'}"
                    *ngFor="let employment of borrower.digitallySourcedEmployments">
                    <span class="yes" *ngIf="employment?.isMatched">
                      <i class="fa fa-check-circle" aria-hidden="true"></i>
                    </span>
                    <span class="no" *ngIf="!employment?.isMatched">
                      <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-center">
    <div class="alert alert-danger border-0" role="alert" *ngIf="error" style="margin-right: auto">
      <strong>{{error.header}}</strong> {{error.message}}
    </div>

    <button type="submit" *ngIf="!isRunningVOI && !isVoePullDisabled" class="btn btn-primary pull-right"
      (click)="runVoi()" [disabled]="isLoanReadOnly || isRunVoiDisabled()" style="width: 120px">
      {{!isLoanReadOnly ? 'Run VOI/VOE' : 'Run VOI/VOE (Readonly)'}}
    </button>
    <button type="submit" *ngIf="isRunningVOI" class="btn btn-primary pull-right" style="width: 120px" disabled>
      <span class="spinner-border spinner-border-sm" role="status"></span>
    </button>
  </div>
</ng-container>
