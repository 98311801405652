<p-table #ordersTable [value]="orders" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
  [resizableColumns]="true" [globalFilterFields]="globalFilterFields"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption">
    <div class="p-d-flex" style="align-items:center">
      <span class="p-input-icon-right ms-2 p-ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" class="form-control" (input)="ordersTable.filterGlobal($event.target.value, 'contains')"
        (ngModelChange)="ordersTable.first=0" placeholder="Search keyword" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Actions</th>
      <th [pSortableColumn]="'thirdPartyOrderId'" pResizableColumn>Envelope Id
        <p-sortIcon [field]="'thirdPartyOrderId'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'status'" pResizableColumn>Status
        <p-sortIcon [field]="'status'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'dateInserted'" pResizableColumn>Added On
        <p-sortIcon [field]="'dateInserted'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'signingLinkSentInEmail'" pResizableColumn>Signing Through Email
        <p-sortIcon [field]="'signingLinkSentInEmail'"></p-sortIcon>
      </th>
      <th>View</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr [ngClass]="{'selected': order.documentSigningOrderId == selectedOrderId}">
      <td>
        <span class="d-flex align-items-center justify-content-center w-100">
          <button class="btn btn-sm btn-primary" (click)="onSelectOrderClicked(order)">
            Select
          </button>
        </span>
      </td>
      <td>{{order.thirdPartyOrderId}}</td>
      <td>{{order.status}}</td>
      <td>{{order.dateInserted | date:'short'}}</td>
      <td>{{order.signingLinkSentInEmail ? 'Yes' : 'No'}}</td>
      <td>
        <button class="btn btn-sm btn-soft-primary" type="button"
          *ngIf="order.loanDocTasks && order.loanDocTasks.length > 0" (click)="onViewSignedDocClicked(order)">
          <span *ngIf="docLoadingStatuses.get(order.documentSigningOrderId)" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ docLoadingStatuses.get(order.documentSigningOrderId) ? 'Please wait...' : 'View Signed Document'}}
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
