import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Borrower } from "src/app/models";
import { MessageHistoryItem } from "src/app/models/message.model";
import { Application } from "../../../app-details/models/application.model";
import { BorrowerFull } from "../../../app-details/models/full-borrower.model";
import { AppDetailsService } from "../../../app-details/services/app-details.service";
import { CorrespondenceService } from "../../../correspondence/services/correspondence.service";
import { ContactsService } from "src/app/modules/contacts/services/contacts.service";

@Component({
  selector: 'borrower-editor',
  templateUrl: './borrower-editor.component.html',
  styleUrls: ['./borrower-editor.component.scss']
})
export class BorrowerEditorComponent implements OnInit {

  @Input() borrowerId: number;
  @Input() tab: 'contact' | 'applications' | 'history' | 'notes' = 'contact'
  @Input() loanId: number;
  @Input() hasCancelButton: boolean = false;

  @Output() borrowerEditCancelled = new EventEmitter<void>;
  @Output() borrowerSaved = new EventEmitter<Borrower>();

  allApplicants: Application;

  borrowerMessageHistory: MessageHistoryItem[] = [];

  fullBorrower: BorrowerFull;

  constructor(
    private readonly _appDetailsService: AppDetailsService,
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _contactsService: ContactsService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (!this.borrowerId) {
        this.borrowerId = params.borrowerId ? params.borrowerId : this.borrowerId;
      }
      if (!this.loanId) {
        this.loanId = params.loanId;
      }

      this.getAllApplications(this.borrowerId);
      this.getMessageHistory(this.borrowerId);
    });
  }

  onBorrowerLoaded = (borrower: BorrowerFull) => {
    this.fullBorrower = borrower;
  }

  onBorrowerSaved = (borrower: BorrowerFull) => {
    this.fullBorrower = { ...borrower };
    this._contactsService.updateContactsTable(borrower.borrower);
    this.borrowerSaved.emit(borrower.borrower);
  }

  protected onBorrowerEditCancelled = () => {
    this.borrowerEditCancelled.emit();
  }

  private getAllApplications = (borrowerId: number) => {
    this._appDetailsService.getAllApplication(borrowerId).subscribe(result => {
      this.allApplicants = result;
    },
    )
  }

  private getMessageHistory = (borrowerId: number) => {
    this._correspondenceService.getMessagesSentToBorrower(borrowerId).subscribe(messageHistory => {
      this.borrowerMessageHistory = messageHistory;
    }, error => {
    })
  }
}
