<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Url</label>
    </div>
    <div class="col-md-10">
      <input type="text" class="form-control" [(ngModel)]="vendor.url">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class='custom-control-label'>API Key</label>
    </div>
    <div class='col-md-10'>
      <password-input [(ngModel)]='apiKeyItem.value' [name]="'loanpass_api_key'"></password-input>
    </div>
  </div>
  <div class='row mt-3'>
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'>Default Pricing Profile</label>
    </div>
    <div class='col-md-10'>
      <ng-select [(ngModel)]='defaultPricingProfileIdItem.value' [clearable]='pricingProfileOptions != null'
        [items]='pricingProfileOptions' [loading]='pricingProfileOptions == null' bindLabel='name'
        bindValue='id'></ng-select>
    </div>
  </div>
  <div class='row mt-3'>
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'>User Accounts</label>
    </div>
    <div class='col-md-10 d-inline-flex align-items-end'>
      <div class="form-check me-4">
        <input type="checkbox" id="allowUserAccounts" class="form-check-input" ToggleValue [true]="'1'" [false]="'0'"
          [(ngModel)]="allowUserAccountsItem.value">
        <label class="form-check-label" for="allowUserAccounts">Allow User Accounts</label>
      </div>

      <div class="form-check">
        <input type="checkbox" id="forceUserAccounts" class="form-check-input" ToggleValue [true]="'1'" [false]="'0'"
          [(ngModel)]="forceUserAccountsItem.value">
        <label class="form-check-label" for="forceUserAccounts">Force User Accounts</label>
      </div>
    </div>
  </div>
  <div class='row mt-3' *ngIf="vendor?.credentialId">
    <div class='col-md-2'>
    </div>
    <div class='col-md-10'>
      <button type="button" class="btn btn-info" (click)="onRepriceFieldsClicked()">
        Field Editor
      </button>
    </div>
  </div>
  <div class="row mt-3" *ngIf="vendor?.credentialId">
    <div class="col-md-2 d-flex align-items-center justify-content-end">
      <label class='custom-control-label'>Mappings</label>
    </div>
    <div class="col-md-10">
      <div class="row">
        <div class="col-md-3">
          <h5>To LoanPass</h5>
          <div>
            <button type="button" class="btn btn-primary" (click)="onFieldsMappingClicked('ToExecuteRequest')">
              Loanpass Pricing Request
            </button>
          </div>
        </div>
        <div class="col-md-9">
          <h5>To Lodasoft</h5>
          <div>
            <button type="button" class="btn btn-primary me-2" (click)="onFieldsMappingClicked('ToProductSearchRequest')">
              Lodasoft Pricing Request
            </button>
            <button type="button" class="btn btn-primary me-2" (click)="onFieldsMappingClicked('ToProduct')">
              Product
            </button>
            <button type="button" class="btn btn-primary" (click)="onFieldsMappingClicked('ToQuote')">
              Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='row mt-3' *ngIf="vendor?.credentialId">
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'>Custom Fields</label>
    </div>
    <div class='col-md-10'>
      <button type="button" class="btn btn-primary" (click)="onCustomFieldsMappingClicked()">
        Manage
      </button>
    </div>
  </div>
  <div class='row mt-3' *ngIf="vendor?.credentialId">
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'></label>
    </div>
    <div class='col-md-10'>
      <button type="button" class="btn btn-outline-danger" (click)="onResetClicked()">
        Reset All Configuration
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
