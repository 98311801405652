<div class="row mt-1">
    <div class="col-md-12">
        <div class="form-check form-check-inline">
            <input id="ernstFeesEnabled" class="form-check-input" type="checkbox" [(ngModel)]="ernstFeesEnabled.value"
                ToggleValue [true]="1" [false]="0" (ngModelChange)="onErnstEnabledStatusChanged()">
            <label class="form-check-label" for="ernstFeesEnabled">Enable Ernst Fees</label>
        </div>
        <div class="form-check form-check-inline ms-2">
            <input id="lodestarFeesEnabled" class="form-check-input" type="checkbox" [(ngModel)]="lodestarFeesEnabled.value"
                ToggleValue [true]="1" [false]="0" (ngModelChange)="onLodestarEnabledStatusChanged()">
            <label class="form-check-label" for="lodestarFeesEnabled">Enable LodeStar Fees</label>
        </div>
        <div class="form-check form-check-inline ms-2">
            <input id="mctoFeesEnabled" class="form-check-input" type="checkbox" [(ngModel)]="mctoFeesEnabled.value"
                ToggleValue [true]="1" [false]="0" (ngModelChange)="onMCTOEnabledStatusChanged()">
            <label class="form-check-label" for="mctoFeesEnabled">Enable MCTO Fees</label>
        </div>
    </div>
    <div class="col-md-12">
        <hr>
    </div>
    <ng-container *ngIf="ernstFeesEnabled.value == 1 || lodestarFeesEnabled.value == 1 || mctoFeesEnabled.value == 1">
        <div class="col-md-2 text-end">
            <label class="custom-control-label"> Default Fee Provider </label>
        </div>
        <div class="col-md-4">
            <select class="form-select" name="defaultPricing" [(ngModel)]="defaultFeeProvider.valueStr"
                (ngModelChange)="onSelectedDefaultFeeProviderChanged()">
                <option value="">-- Select One --</option>
                <ng-container *ngFor="let vendor of feeProviders;trackBy: trackByValue">
                    <option [value]="vendor.value"> {{vendor.name}} </option>
                </ng-container>
            </select>
        </div>
    </ng-container>
    <div class="d-flex align-content-end flex-wrap">
        <div class="mt-4 me-2" *ngFor="let credential of providerCredentials">
            <credential-item [credential]="credential" (openCredentialModal)="openFeeEditor(credential)">
            </credential-item>
        </div>
        <ng-container *ngIf="missingFeeProviders.length > 0">
            <div class="mt-4 me-2">
                <create-credential [credentialType]="'NewProvider'" (openCredentialModal)="openProviderPicker()">
                </create-credential>
            </div>
        </ng-container>
    </div>
</div>
