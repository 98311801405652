import {LoanFee} from './fees.model';

/**
 * This is different from the {@link FeeSectionType} as this has additional types to use for the
 * view.
 */
export enum FeeSectionViewType {
  RealEstateCommission = 'RealEstateCommission',
  Origination = 'Origination',
  Prepaids = 'Prepaids',
  Escrow = 'Escrow',
  TitleCharges = 'TitleCharges',
  GovernmentTaxesAndFees = 'GovernmentTaxesAndFees',
  Other = 'Other',
}

export interface FeeSection {
  readonly type: FeeSectionViewType;
  readonly fees: readonly LoanFee[];
}
