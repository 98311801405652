<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header" *ngIf="!embedded">
                <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-list-ul me-2"></i>
                    Automated Underwriting
                </h4>
            </div>
            <div class="card-body">
                <div *ngIf="!isLoadingLastRanDU && !isLoadingLastRanDO && !isLoadingLastRanLPA; else loadingAus">
                    <div class="row">
                        <div class="col-md-6" *ngIf="isDesktopUnderwriterEnabled">
                            <div class="card">
                                <div *ngIf="!runningDuProcess && !isLoadingLastRanDU">
                                    <div class="card-header d-flex justify-content-between">
                                        <h4 class="card-title">
                                            Desktop Underwriter (DU)
                                        </h4>
                                        <div *ngIf="duHistory.length > 0">
                                            <a href="javascript:void(0);" (click)="onShowHistoryClicked('DU')">
                                                Show {{isDuHistoryOpened ? 'Latest Details' : 'History'}}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card-body aus-card" [ngStyle]="{'height': isDuHistoryOpened ? '355px' : '300px'}"
                                        [ngClass]="{'aus-not-run-yet': !duResult?.dateInserted}">
                                        <div *ngIf="!duResult?.dateInserted" class="text-center">
                                            AUS not run yet
                                            <br>
                                            <a class="cursor d-block" (click)="openDUModal()">
                                                DU Settings
                                            </a>
                                            <br>
                                            <div *ngIf="showDUSystemLevelLink">
                                                <ng-container *ngTemplateOutlet="linkToSystemLevel"></ng-container>
                                            </div>
                                            <span *ngIf="duResult?.dateInserted && !isDuFieldsSet()"
                                                class="text-danger text-center">
                                                Missing Credit Information
                                            </span>
                                        </div>
                                        <div *ngIf="duResult?.dateInserted" class="table-responsive">
                                            <table *ngIf="!isDuHistoryOpened; else duHistoryContent"
                                                class="table table-striped mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td> Credit Provider </td>
                                                        <td> {{duResult?.requestData.thirdPartyCreditProviderId}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Casefile Id </td>
                                                        <td> {{duResult?.vendorFileId}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Credit Reference # </td>
                                                        <td> {{duResult?.refNoCsv}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <i title="{{duResult?.isSuccessful ? 'Last Run was Successful' : 'Last Run not was Successful'}}"
                                                                class="{{duResult?.isSuccessful ? 'fa fa-check-circle text-success me-1' : 'fa fa-times-circle text-danger me-1'}}"></i>
                                                            Last Run
                                                        </td>
                                                        <td> {{ duResult?.dateInserted ? ' ' + (duResult.dateInserted | date: 'MM/dd/yyyy hh:mm a') : '' }} </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #duHistoryContent>
                                                <table class="table table-striped mb-0">
                                                    <tbody>
                                                        <tr *ngFor="let item of duHistory | sort: 'asc':'dateUpdated'">
                                                          <td width="30px">
                                                            <input id="{{item.duHistoryId}}"
                                                            [ngModel]="duResult.duHistoryId"
                                                            [value]="item.duHistoryId" type="radio"
                                                            (change)="onDuHistoryCheckChanged($event, item)">
                                                          </td>
                                                            <td>
                                                                <a href="javascript:void(0);"
                                                                    (click)="showDuHistoryDetails(item)">
                                                                    {{ (item.dateUpdated || item.dateInserted) | date: 'MM/dd/yyyy hh:mm a'}}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div *ngIf="!isDuHistoryOpened" class="card-footer">
                                        <div class="btn-group float-end mb-2" aria-label="Basic example" role="group">
                                            <button type="button" class="btn btn-outline-primary active"
                                                (click)="runDu(true)"
                                                [disabled]="isLoanReadOnly || !isDesktopUnderwriterEnabled || runningDuProcess || tpoDisclosed">
                                                Run DU
                                            </button>
                                            <button type="button" class="btn btn-outline-primary"
                                                [disabled]="isLoanReadOnly || tpoDisclosed"
                                                (click)="openDUModal()">
                                                <i class="fa fa-cog cursor float-end"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <loading-indicator *ngIf="runningDuProcess || isLoadingLastRanDU"
                                    [loadingMessage]="'Running DU, please wait...'"
                                    [customClass]="'no-shadow fix-height'">
                                </loading-indicator>

                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="isDOEnabled">
                            <div class="card">
                                <div *ngIf="!runningDoProcess && !isLoadingLastRanDO">
                                    <div class="card-header d-flex justify-content-between">
                                        <h4 class="card-title">
                                            Desktop Originator (DO)
                                        </h4>
                                        <div *ngIf="doHistory.length > 0">
                                            <a href="javascript:void(0);" (click)="onShowHistoryClicked('FannieMae')">
                                                Show {{isDoHistoryOpened ? 'Latest Details' : 'History'}}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card-body aus-card" [ngStyle]="{'height': isDoHistoryOpened ? '355px' : '300px'}"
                                        [ngClass]="{'aus-not-run-yet': !doResult?.dateInserted}">
                                        <div *ngIf="!doResult?.dateInserted" class="text-center">
                                            AUS not run yet

                                            <br>
                                            <div *ngIf="showDOSystemLevelLink">
                                                <ng-container *ngTemplateOutlet="linkToSystemLevel"></ng-container>
                                            </div>
                                            <span *ngIf="!isDoCreditRefSet()"
                                                class="text-danger text-center cursor" (click)="openDOModal()">
                                                Missing Credit Information.
                                            </span>
                                            <span *ngIf="!isDoSponsoringLenderSet()"
                                                class="text-danger text-center cursor" (click)="openDOModal()">
                                                Missing Sponsoring Lender.
                                            </span>
                                        </div>
                                        <div *ngIf="doResult?.dateInserted" class="table-responsive">
                                            <table class="table table-striped mb-0"
                                                *ngIf="!isDoHistoryOpened; else doHistoryContent">
                                                <tbody>
                                                    <tr>
                                                        <td> Credit Provider </td>
                                                        <td> {{doResult?.requestData.thirdPartyCreditProviderId}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Sponsoring Lender </td>
                                                        <td> {{doResult?.brokerLenderId}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Casefile Id </td>
                                                        <td> {{doResult?.vendorFileId}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Submission Status </td>
                                                        <td> {{doResult?.submissionStatus}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Credit Reference # </td>
                                                        <td> {{doResult?.refNoCsv}} </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <i title="{{doResult?.isSuccessful ? 'Last Run was Successful' : 'Last Run not was Successful'}}"
                                                                class="{{doResult?.isSuccessful ? 'fa fa-check-circle text-success me-1' : 'fa fa-times-circle text-danger me-1'}}"></i>
                                                            Last Run
                                                        </td>
                                                        <td> {{ doResult?.dateInserted ? ' ' +
                                                            (doResult.dateInserted |
                                                            date:
                                                            'MM/dd/yyyy hh:mm a') : '' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #doHistoryContent>
                                                <table class="table table-striped mb-0">
                                                    <tbody class="justify-content-top">
                                                        <tr *ngFor="let item of doHistory | sort: 'asc':'dateUpdated'">
                                                            <td width="30px">
                                                              <input id="{{item.duHistoryId}}"
                                                              [ngModel]="doResult.duHistoryId"
                                                              [value]="item.duHistoryId" type="radio"
                                                              (change)="onDoHistoryCheckChanged($event, item)">
                                                            </td>
                                                            <td>
                                                                <a href="javascript:void(0);"
                                                                    (click)="showDoHistoryDetails(item)">
                                                                    {{ (item.dateUpdated || item.dateInserted) | date: 'MM/dd/yyyy hh:mm a'}}
                                                                  </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <span *ngIf="doResult?.submissionStatus === 'Final'" class="flex-grow-1">
                                            <i class="fas fa-exclamation-triangle text-primary"></i>
                                            Casefile is finalized; Lender must release to resubmit.
                                        </span>
                                        <div *ngIf="!isDoHistoryOpened" class="btn-group float-end mb-2"
                                            aria-label="Basic example" role="group">
                                            <button type="button" class="btn btn-outline-primary active"
                                                (click)="runDo(true)"
                                                [disabled]="isLoanReadOnly || !isDOEnabled || runningDoProcess || doResult?.submissionStatus === 'Final'">
                                                Run DO </button>
                                            <button type="button"
                                                [disabled]="isLoanReadOnly"
                                                class="btn btn-outline-primary"
                                                (click)="openDOModal()">
                                                <i class="fa fa-cog cursor float-end"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <loading-indicator *ngIf="runningDoProcess || isLoadingLastRanDO"
                                    [loadingMessage]="'Running DO, please wait...'"
                                    [customClass]="'no-shadow fix-height'">
                                </loading-indicator>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="!isDesktopUnderwriterEnabled && !isDOEnabled">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">
                                        Desktop Underwriter/Originator (DU/DO)
                                    </h4>
                                </div>
                                <div class="card-body aus-card aus-not-run-yet">
                                    <div class="text-center">
                                        DU/DO is not enabled
                                        <ng-container *ngTemplateOutlet="linkToSystemLevel"></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="isLpaEnabled">
                            <div class="card">
                                <div *ngIf="!runningLpaProcess && !isLoadingLastRanLPA">
                                    <div class="card-header d-flex justify-content-between">
                                        <h4 class="card-title">
                                            Loan Product Advisor (LPA)
                                        </h4>
                                        <div *ngIf="lpaHistory.length > 0">
                                            <a href="javascript:void(0);" (click)="onShowHistoryClicked('LPA')">
                                                Show {{isLpaHistoryOpened ? 'Latest Details' : 'History'}}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card-body aus-card" [ngStyle]="{'height': isLpaHistoryOpened ? '355px' : '300px'}"
                                        [ngClass]="{'aus-not-run-yet': (!lpaResult?.isSuccessful || !lpaResult?.dateInserted) && !isLpaHistoryOpened }">
                                        <div class="table-responsive">
                                            <table class="table table-striped mb-0"
                                                *ngIf="!isLpaHistoryOpened; else lpaHistoryContent">
                                                <tbody>
                                                    <tr *ngIf="lpaParams.keyIdentifier || lpaParams.loanIdentifier">
                                                        <td> LPA Key / LID </td>
                                                        <td>
                                                            <div>
                                                                <span> {{lpaParams.keyIdentifier ?
                                                                    lpaParams.keyIdentifier : '-'}} /
                                                                    {{lpaParams.loanIdentifier ?
                                                                    lpaParams.loanIdentifier : '-'}}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="lpaParams.loanStatus">
                                                        <td> Loan Status </td>
                                                        <td> <span> {{lpaParams.loanStatus}} </span> </td>
                                                    </tr>
                                                    <tr *ngIf="lpaParams.branchId">
                                                        <td> Lender Branch </td>
                                                        <td> <span> {{lpaParams.branchId}} </span> </td>
                                                    </tr>
                                                    <tr *ngIf="lpaResult && lpaResult.dateInserted">
                                                        <td> Last Run </td>
                                                        <td> {{ lpaResult?.dateInserted ? ' ' +
                                                            (lpaResult.dateInserted
                                                            | date:
                                                            'MM/dd/yyyy hh:mm a') : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="lpaResult && lpaResult.recommendation">
                                                        <td> Recommendation </td>
                                                        <td> {{ lpaResult?.recommendation }} <i *ngIf="lpaResult?.recommendation == 'Caution'" class="fa fa-exclamation-triangle text-warning"></i>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngIf="lpaParams.creditRequest && (lpaParams.creditRequest.creditVendor || lpaParams.creditRequest.technicalAffiliateCode)">
                                                        <td> Credit Provider </td>
                                                        <td>
                                                            <span>
                                                                {{lpaParams.creditRequest &&
                                                                lpaParams.creditRequest.creditVendor ?
                                                                lpaParams.creditRequest.creditVendor : ''}}
                                                                {{lpaParams.creditRequest &&
                                                                lpaParams.creditRequest.technicalAffiliateName
                                                                ?
                                                                (' - ' +
                                                                lpaParams.creditRequest.technicalAffiliateName)
                                                                : ''}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngFor="let creditRequest of lpaParams.creditRequest?.jointBorrowerCreditRequests; let i = index">
                                                        <td>
                                                            <span class="ms-5" for="borrower_{{i}}"> <b>
                                                                    {{borrowerNames[i]}} </b> :
                                                                {{creditRequest.creditRequestType}}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="creditRequest.creditReferenceNumber">
                                                                <b> Ref # </b>
                                                                {{creditRequest.creditReferenceNumber}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <ng-template #lpaHistoryContent>
                                                <table class="table table-striped mb-0">
                                                    <tbody>
                                                        <tr *ngFor="let item of lpaHistory | sort: 'asc':'dateUpdated'">
                                                            <td>
                                                                <a href="javascript:void(0);"
                                                                    (click)="showLpaHistoryDetails(item)">
                                                                    {{ (item.dateUpdated || item.dateInserted) | date:
                                                                    'MM/dd/yyyy
                                                                    hh:mm a'
                                                                    }}</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="(!lpaErrorMessage && !lpaResult?.dateInserted && !isLpaHistoryOpened) ||
                                              (lpaErrorMessage || (!lpaResult?.isSuccessful) && !isLpaHistoryOpened)"
                                            class="text-center mt-4">
                                            <div *ngIf="!lpaResult?.dateInserted" class="mb-2">
                                                <span class="mb-2">AUS not run yet</span>
                                                <a class="cursor d-block" (click)="openLPAModal()">
                                                    LPA Settings
                                                </a>
                                            </div>
                                            <div *ngIf="showLPASystemLevelLink">
                                                <ng-container *ngTemplateOutlet="linkToSystemLevel"></ng-container>
                                            </div>
                                            <span *ngIf="lpaResult?.dateInserted && !isLpaValid()"
                                                class="text-danger text-center cursor">
                                                Missing Credit Information.
                                            </span>
                                            <span *ngIf="lpaErrorMessage" class="text-danger">AUS Error:
                                                {{lpaErrorMessage}}
                                            </span>
                                            <span *ngIf="lpaResult && lpaResult.status" class="text-danger">AUS
                                                Error:
                                                {{lpaResult.status}}
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="!isLpaHistoryOpened" class="card-footer">
                                        <div class="btn-group float-end mb-2"
                                            aria-label="Basic example" role="group">
                                            <button type="button" class="btn btn-outline-primary active"
                                                (click)="runLpa(true)" [disabled]="isLoanReadOnly || !isLpaEnabled || runningLpaProcess || tpoDisclosed">
                                                Run LPA </button>
                                            <button type="button" class="btn btn-outline-primary"
                                                [disabled]="isLoanReadOnly || tpoDisclosed"
                                                (click)="openLPAModal()">
                                                <i class="fa fa-cog cursor float-end"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <loading-indicator *ngIf="runningLpaProcess || isLoadingLastRanLPA"
                                    [loadingMessage]="'Running LPA, please wait...'"
                                    [customClass]="'no-shadow fix-height'">
                                </loading-indicator>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="!isLpaEnabled">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">
                                        Loan Product Advisor (LPA)
                                    </h4>
                                </div>
                                <div class="card-body aus-card aus-not-run-yet">
                                    <div class="text-center">
                                        LPA is not enabled
                                        <ng-container *ngTemplateOutlet="linkToSystemLevel"></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <!-- DU -->
                            <div *ngIf="isDesktopUnderwriterEnabled">
                                <div class="card aus-card-result"
                                    *ngIf="duResult?.dateInserted && !runningDuProcess && !isLoadingLastRanDU">
                                    <div class="card-header">
                                        <h4 class="card-title"> Result - {{ duResult?.dateInserted ?
                                            (duResult.dateInserted
                                            |
                                            date:
                                            'MM/dd/yyyy
                                            hh:mm a') : '' }} </h4>
                                    </div>
                                    <div class="card-body aus-card-body-result">
                                        <p class="mt-1 fw-bold" [ngClass]="{'text-danger': !duResult?.isSuccessful}">
                                            {{duResult.status}}</p>

                                        <div *ngIf="duResult?.isSuccessful" class="table-responsive border border-1">
                                            <table class="table table-striped mb-0 border">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {{duResult.recommendation}}
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="duResult.isEligible"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!duResult.isEligible"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Appraisal {{duResult.propertyInspectionWaiver ? '' :
                                                            'Not'}}
                                                            Waived
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="duResult.propertyInspectionWaiver"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!duResult.propertyInspectionWaiver"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Income {{duResult.incomeVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="duResult.incomeVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!duResult.incomeVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Assets {{duResult.assetsVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="duResult.assetsVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!duResult.assetsVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Employment {{duResult.employmentVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="duResult.employmentVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!duResult.employmentVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul class="pdf-link my-3">
                                            <li *ngFor="let document of duResult.documents">
                                                <a class="text-soft-primary" (click)="downloadDuFile(document)">
                                                    <i class="fa fa-file"></i>
                                                    {{document.fileName + document.extension}}
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <!-- DO -->
                            <div *ngIf="!isDesktopUnderwriterEnabled && isDOEnabled">
                                <div class="card aus-card-result"
                                    *ngIf="doResult?.dateInserted && !runningDoProcess && !isLoadingLastRanDO">
                                    <div class="card-header">
                                        <h4 class="card-title"> Result - {{ doResult?.dateInserted ?
                                            (doResult.dateInserted
                                            |
                                            date:
                                            'MM/dd/yyyy hh:mm a') : '' }} </h4>
                                    </div>
                                    <div class="card-body aus-card-body-result">
                                        <p class="mt-1 fw-bold" [ngClass]="{'text-danger': !doResult?.isSuccessful}">
                                            {{doResult.status}}</p>

                                        <div *ngIf="doResult?.isSuccessful" class="table-responsive border border-1">
                                            <table class="table table-striped mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {{doResult.recommendation}}
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="doResult.isEligible"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!doResult.isEligible"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Appraisal {{doResult.propertyInspectionWaiver ? '' :
                                                            'Not'}}
                                                            Waived
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="doResult.propertyInspectionWaiver"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!doResult.propertyInspectionWaiver"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Income {{doResult.incomeVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="doResult.incomeVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!doResult.incomeVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Assets {{doResult.assetsVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="doResult.assetsVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!doResult.assetsVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Employment {{doResult.employmentVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="doResult.employmentVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!doResult.employmentVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul class="pdf-link my-3">
                                            <li *ngFor="let document of doResult.documents">
                                                <a class="text-soft-primary" (click)="downloadDuFile(document)">
                                                    <i class="fa fa-file"></i>
                                                    {{document.fileName + document.extension}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- LPA -->
                        <div class="col-md-6">
                            <ng-container *ngIf="isLpaEnabled">
                                <div class="card aus-card-result"
                                    *ngIf="lpaResult?.dateInserted && !runningLpaProcess && !isLoadingLastRanLPA">
                                    <div class="card-header">
                                        <h4 class="card-title"> Result - {{ lpaResult?.dateInserted ?
                                            (lpaResult.dateInserted
                                            | date:
                                            'MM/dd/yyyy hh:mm a') : '' }} </h4>
                                    </div>
                                    <div class="card-body aus-card-body-result">
                                        <p class="mt-1 fw-bold" [ngClass]="{'text-danger': !lpaResult?.isSuccessful}">
                                            {{lpaResult.status}}</p>
                                        <div *ngIf="lpaResult?.isSuccessful" class="table-responsive border border-1">
                                            <table class="table table-striped mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {{lpaResult.recommendation}}
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="lpaResult.isEligible || (lpaResult.recommendation == 'Accept' && isGovernmentLoan)"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!lpaResult.isEligible && !(lpaResult.recommendation == 'Accept' && isGovernmentLoan)"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Appraisal {{lpaResult.propertyInspectionWaiver ? '' :
                                                            'Not'}} Waived
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="lpaResult.propertyInspectionWaiver"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!lpaResult.propertyInspectionWaiver"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Income {{lpaResult.incomeVerified ? '' : 'Not'}} Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="lpaResult.incomeVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!lpaResult.incomeVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Assets {{lpaResult.assetsVerified ? '' : 'Not'}} Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="lpaResult.assetsVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!lpaResult.assetsVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Employment {{lpaResult.employmentVerified ? '' : 'Not'}}
                                                            Validated
                                                        </td>
                                                        <td>
                                                            <div class="float-end">
                                                                <i class="fa fa-check-circle text-success"
                                                                    *ngIf="lpaResult.employmentVerified"></i>
                                                                <i class="fa fa-times-circle text-danger"
                                                                    *ngIf="!lpaResult.employmentVerified"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div *ngIf="lpaResult.isMismo34 && lpaResult.documents.length > 0">
                                            <ul class="pdf-link my-3">
                                                <li *ngFor="let document of lpaResult.documents">
                                                    <a class="text-soft-primary" (click)="downloadLpaFile(document)">
                                                        <i class="fa fa-file-pdf-o" hidden="true"></i>
                                                        {{document.fileName + (document.extension ? document.extension :
                                                        '')}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul class="pdf-link" *ngIf="lpaXML && !lpaResult.isMismo34">
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.fullFeedback)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>Full Feedback
                                                </a>
                                            </li>
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.creditInFile)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>Credit In File
                                                </a>
                                            </li>
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.mergedCredit)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>Merged Credit
                                                </a>
                                            </li>
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.mergedCreditCertificate)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>Merged Credit
                                                    Certificate
                                                </a>
                                            </li>
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.docChecklist)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>Doc Checklist
                                                </a>
                                            </li>
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.hve)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>HVE
                                                </a>
                                            </li>
                                            <li>
                                                <a class="text-soft-primary"
                                                    (click)="displayLpaResults(lpaXSLFilePaths.errors)">
                                                    <i class="fa fa-file-text-o" hidden="true"></i>Errors
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- <div style="height: 200px; overflow-y: scroll; white-space: pre-line">
                                        <p class="mt-1 fw-bold" [ngClass]="{'text-danger': !lpaResult?.isSuccessful}">
                                            {{lpaResult.ausMessages}}</p>
                                    </div> -->
                                        <div *ngIf="lpaErrors.length">
                                            <p>Errors: </p>
                                            <ul class="errors-list-lpa">
                                                <li *ngFor="let error of lpaErrors | filter: 'message'">
                                                    {{error.message}}
                                                    [{{error.category}}:{{error.code}}]
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div id="lpa-result"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <button class="btn btn-primary float-end"
                        (click)="runDualAus()" [disabled]="isLoanReadOnly || canRunDualAUS() || tpoDisclosed">
                        <span *ngIf="!runningLpaProcess && !runningDuProcess && !runningDoProcess">Run Dual AUS</span>
                        <span *ngIf="runningLpaProcess || runningDuProcess || runningDoProcess"
                            class="spinner-border spinner-border-sm align-middle" role="status"></span>
                        <span *ngIf="runningLpaProcess || runningDuProcess || runningDoProcess"> Please wait...</span>
                    </button>
                </div>
                <ng-template #loadingAus>
                    <loading-indicator></loading-indicator>
                </ng-template>
            </div>

        </div>
    </div>
</div>


<ng-template #linkToSystemLevel>
    <a *ngIf="!isTPOUser" class="cursor d-block" (click)="goToSystemLevel()">
        System Level Settings
    </a>
</ng-template>
