import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { EnumerationItem } from "../models/simple-enum-item.model";

@Injectable()
export class ChannelService {

  constructor() { }

  getChannelsFromCommaDelimitedString = (channels: string, returnIdAsValue: boolean = false): EnumerationItem[] => {
    if (!channels) {
      return [];
    }
    let channelEnumItems: EnumerationItem[] = [];
    const channelNames: string[] = channels.split(',').map(c => c.trim());
    if (channelNames.includes('Retail')) {
      channelEnumItems.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
    }
    if (channelNames.includes('Wholesale')) {
      channelEnumItems.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
    }
    if (channelNames.includes('Broker')) {
      channelEnumItems.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
    }
    if (channelNames.includes('Correspondent')) {
      channelEnumItems.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
    }
    if (channelNames.includes('NonDelegatedCorrespondent')) {
      channelEnumItems.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
    }
    return channelEnumItems;
  }

  getChannels = (enumFlagValue: number, returnIdAsValue: boolean = false): Array<EnumerationItem> => {
    let channels: Array<EnumerationItem> = [];
    switch (enumFlagValue) {
      case 0:
        break;
      case 1:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        break;
      case 2:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        break;
      case 3:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        break;
      case 4:
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        break;
      case 5:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        break;
      case 6:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        break;
      case 7:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        break;
      case 8:
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 9:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 10:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 11:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 12:
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 13:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 14:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 15:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        break;
      case 16:
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 17:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 18:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 19:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 20:
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 21:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 22:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 23:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 24:
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 25:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 26:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 27:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 28:
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 29:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 30:
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
      case 31:
        channels.push({ name: 'Retail', value: returnIdAsValue ? 1 : 'Retail' });
        channels.push({ name: 'Wholesale', value: returnIdAsValue ? 2 : 'Wholesale' });
        channels.push({ name: 'Broker', value: returnIdAsValue ? 4 : 'Broker' });
        channels.push({ name: 'Correspondent', value: returnIdAsValue ? 8 : 'Correspondent' });
        channels.push({ name: 'NonDelegatedCorrespondent', value: returnIdAsValue ? 16 : 'NonDelegatedCorrespondent' });
        break;
    }
    return channels;
  };

  getChannelsNumber = (channels: Array<string>): number => {
    if (!channels) return 0;

    let result = 0;
    const orderedChannels = _.sortBy(channels);
    if (_.isEqual(orderedChannels, ["Retail"])) {
      result = 1;
    } else if (_.isEqual(orderedChannels, ["Wholesale"])) {
      result = 2;
    } else if (_.isEqual(orderedChannels, ["Retail", "Wholesale"])) {
      result = 3;
    } else if (_.isEqual(orderedChannels, ["Broker"])) {
      result = 4;
    } else if (_.isEqual(orderedChannels, ["Broker", "Retail"])) {
      result = 5;
    } else if (_.isEqual(orderedChannels, ["Broker", "Wholesale"])) {
      result = 6;
    } else if (_.isEqual(orderedChannels, ["Broker", "Retail", "Wholesale"])) {
      result = 7;
    } else if (_.isEqual(orderedChannels, ["Correspondent"])) {
      result = 8;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "Retail"])) {
      result = 9;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "Wholesale"])) {
      result = 10;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "Retail", "Wholesale"])) {
      result = 11;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent"])) {
      result = 12;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "Retail"])) {
      result = 13;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "Wholesale"])) {
      result = 14;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "Retail", "Wholesale"])) {
      result = 15;
    } else if (_.isEqual(orderedChannels, ["NonDelegatedCorrespondent"])) {
      result = 16;
    } else if (_.isEqual(orderedChannels, ["Retail", "NonDelegatedCorrespondent"])) {
      result = 17;
    } else if (_.isEqual(orderedChannels, ["Wholesale", "NonDelegatedCorrespondent"])) {
      result = 18;
    } else if (_.isEqual(orderedChannels, ["Retail", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 19;
    } else if (_.isEqual(orderedChannels, ["Broker", "NonDelegatedCorrespondent"])) {
      result = 20;
    } else if (_.isEqual(orderedChannels, ["Broker", "Retail", "NonDelegatedCorrespondent"])) {
      result = 21;
    } else if (_.isEqual(orderedChannels, ["Broker", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 22;
    } else if (_.isEqual(orderedChannels, ["Broker", "Retail", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 23;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "NonDelegatedCorrespondent"])) {
      result = 24;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "Retail", "NonDelegatedCorrespondent"])) {
      result = 25;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 26;
    } else if (_.isEqual(orderedChannels, ["Correspondent", "Retail", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 27;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "NonDelegatedCorrespondent"])) {
      result = 28;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "Retail", "NonDelegatedCorrespondent"])) {
      result = 29;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 30;
    } else if (_.isEqual(orderedChannels, ["Broker", "Correspondent", "Retail", "Wholesale", "NonDelegatedCorrespondent"])) {
      result = 31;
    }
    return result
  }

  getChannelsListForDropdown = (channels: string): Array<EnumerationItem> => {
    return this.getChannelsFromCommaDelimitedString(channels)
      .map(channel => ({
        value: channel.value,
        name: channel.name,
      }));
  }
}
