<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarMiddle>

  <generic-filter class="ms-2" [label]="'All Users'" [filterHeader]="'Users'" [filterOptions]="usersToFilterWith"
    [selectedOptions]="selectedUserOptionsOnUserFilter" [type]="'multiSelect'" [icon]="'fas fa-filter'"
    (filtersChanged)="onUsersFilterChanged($event)">
  </generic-filter>

  <div class="d-inline-flex ms-3">
    <div class="form-check form-switch form-switch-success me-2">
      <label for="showLoanEventsCheckbox" class="form-check-label">Show Loan Events</label>
      <input id="showLoanEventsCheckbox" class="form-check-input" [(ngModel)]="showLoanEvents"
        (click)="showLoanEventsClicked(showLoanEvents)" type="checkbox" />
    </div>
    <div class="form-check form-switch form-switch-success me-2">
      <label for="expandLoanEventsCheckbox" class="form-check-label">Expand All Events</label>
      <input id="expandLoanEventsCheckbox" class="form-check-input" [(ngModel)]="expandLoanEvents"
        (click)="expandLoanEventsClicked()" type="checkbox" checked />
    </div>
  </div>
</ng-template>

<ng-template #mainContent>
  <div class="calendar-container">
    <full-calendar #fullCalendar id="fullCalendar" [options]="calendarOptions"></full-calendar>
  </div>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarMiddleContentTemplate]="pageActionBarMiddle">
</admin-page-template>
<drawer #createCalendarEventDrawer [title]="'Set Appointment'" [name]="'createCalendarEventDrawer'"
  (closed)="onDrawerClosed()" [options]="drawerOptions">
</drawer>
<drawer #editLeadEditorDrawer [name]="'editLeadEditorDrawer'"
  (closed)="onDrawerClosed()" [options]="editLeadDrawerOptions">
</drawer>