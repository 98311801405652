<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">PROFILE SETUP</h4>
            </div>
            <div class="card-body" >
                <ng-wizard [config]="configSetupWizard" (stepChanged)="stepChanged($event)" name="profileSetup" *ngIf="userProfile">

                    <ng-wizard-step [title]="'Profile'">

                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please fill in the missing information shown in the Profile Information.</h5>

                        <app-basic-profile [profile]="userProfile.userProfile" [timezones]="timezones"
                            [userImage]="userProfile.userImage" [signatureImage]="userProfile.signatureImage">
                        </app-basic-profile>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Contact Info'">
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please fill in the missing information shown in the Contact Information.</h5>

                        <app-contact [profile]="userProfile.userProfile" [states]="configs.states"></app-contact>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'State Licensing'">
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please fill in the State Licensing.</h5>
                        
                        <state-licensing [userCompanyGuid]="userProfile.userProfile.userCompanyGuid"></state-licensing>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Application Settings'">
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please select the default stacking order.</h5>

                        <div class="mx-4">
                            <app-application-settings [profile]="userProfile.userProfile"
                                [docTypes]="configs.stackingOrderDocTypes"
                                [allowCancelEsignOverride]="userProfile.allowCancelEsignOverride"
                                [allowCopyLoanOverride]="userProfile.allowCopyLoanOverride"
                                [allowDeleteTaskOverride]="userProfile.allowDeleteTaskOverride">
                            </app-application-settings>
                        </div>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Lead Routing'">

                        <user-lead-routing [userProfile]="userProfile.userProfile">
                        </user-lead-routing>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Email Settings'"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;" 
                            *ngIf="userProfile.emailService.serviceType == 'Smtp' || userProfile.emailService.serviceType == 'ExchangeWebServices'"> 
                            Please enter the username and password.
                        </h5>

                        <profile-email-settings *ngIf="userProfile"
                            [profile]="userProfile"
                            [tenantKvp]="tenantKvp"
                            [amazonWebServices]="amazonWebServices"
                            [isSelfUserProfile]="isSelfUserProfile"
                        ></profile-email-settings>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Email Templates'"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Do you want to override any email templates?</h5>

                        <div class="card-body">    
                            <div class="radio radio-info form-check-inline" style="margin-right: 1rem !important;">
                                <input type="radio" id="editEmailRadio1" [value]="true" name="editEmailRadio" [(ngModel)]="editEmailTemplates">
                                <label for="editEmailRadio1"> Yes </label>
                            </div>
                            <div class="radio form-check-inline">
                                <input type="radio" id="editEmailRadio2" [value]="false" name="editEmailRadio" [(ngModel)]="editEmailTemplates" checked>
                                <label for="editEmailRadio2"> No </label>
                            </div>
                        </div>

                        <app-email-templates [hidden]="!editEmailTemplates" [(definedEmailTemplate)]="userProfile.definedEmailTemplate"
                            [configs]="configs"></app-email-templates>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'SMS Templates'"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Do you want to override any SMS templates?</h5>

                        <div class="card-body">    
                            <div class="radio radio-info form-check-inline" style="margin-right: 1rem !important;">
                                <input type="radio" id="editSMSRadio1" [value]="true" name="editSMSRadio" [(ngModel)]="editSMSTemplates">
                                <label for="editSMSRadio1"> Yes </label>
                            </div>
                            <div class="radio form-check-inline">
                                <input type="radio" id="editSMSRadio2" [value]="false" name="editSMSRadio" [(ngModel)]="editSMSTemplates" checked>
                                <label for="editSMSRadio2"> No </label>
                            </div>
                        </div>

                        <app-sms-templates [userProfile]="userProfile" [hidden]="!editSMSTemplates"
                            [definedSmsTemplate]="userProfile.definedSmsTemplate" [configs]="configs">
                        </app-sms-templates>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Voicemail Templates'"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Do you want to upload any voicemail messages to be played when the borrower is not responding?</h5>

                        <div class="card-body">    
                            <div class="radio radio-info form-check-inline" style="margin-right: 1rem !important;">
                                <input type="radio" id="editVoicemailRadio1" [value]="true" name="editVoicemailRadio" [(ngModel)]="editVoicemailTemplates">
                                <label for="editVoicemailRadio1"> Yes </label>
                            </div>
                            <div class="radio form-check-inline">
                                <input type="radio" id="editVoicemailRadio2" [value]="false" name="editVoicemailRadio" [(ngModel)]="editVoicemailTemplates" checked>
                                <label for="editVoicemailRadio2"> No </label>
                            </div>
                        </div>

                        <app-voice-mail-templates [userCompanyGuid]="userProfile.userProfile.userCompanyGuid" [hidden]="!editVoicemailTemplates">
                        </app-voice-mail-templates>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Integrations - Credit'"
                        *ngIf="isCreditEnabledForCompany"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please enter individual credentials. </h5>

                        <div class="mx-4">
                            <credit [scope]="'User'" [creditReportingEnabled]="userProfile.creditReportingEnabled"
                                [requireLosLoanLinkForCreditReporting]="userProfile.requireLosLoanLinkForCreditReporting"
                                [documentTypes]="configs.documentType" [creditCredentials]="creditCredentials">
                            </credit>
                        </div>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Integrations - Document Signing'"> 
                        <div class="mx-4">
                            <e-signing [scope]="'User'" [documentSigningEnabled]="userProfile.documentSigningEnabled"
                                [losDocumentSigningEnabled]="userProfile.losDocumentSigningEnabled" [systemLevel]="systemLevel"
                                [docuSign]="docuSign" [documentTypes]="configs.documentType">
                            </e-signing>
                        </div>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Integrations - Aus'" 
                        *ngIf="isDesktopUnderwriterEnabledForCompany || isDesktopOriginatorEnabledForCompany || isLpaEnabledForCompany">
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please enter individual DU, DO, or LPA credentials. </h5>

                        <div class="mx-4">
                            <aus [scope]="'User'" [desktopUnderwriterConfig]="systemLevel.desktopUnderwriterEnabled"
                                [desktopOriginatorConfig]="systemLevel.desktopOriginatorEnabled"
                                [lpaConfig]="systemLevel.lpaEnabled" [credentialsForDu]="du" [credentialsForDo]="do"
                                [credentialsForLpa]="lpa"
                                [isDesktopUnderwriterEnabledForCompany]="isDesktopUnderwriterEnabledForCompany"
                                [isDesktopOriginatorEnabledForCompany]="isDesktopOriginatorEnabledForCompany"
                                [isLpaEnabledForCompany]="isLpaEnabledForCompany">
                            </aus>
                        </div>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Integrations - LOS'"
                        *ngIf="isLosEnabledForCompany"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please enter individual LOS credentials. </h5>

                        <div class="mx-4">
                            <los-integration [scope]="'User'" [losEnabled]="userProfile.losEnabled"
                                [losCredentials]="losCredentials">
                            </los-integration>
                        </div>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Integrations - Social Media'"> 
                        <h5 class="ms-2" style="font-size:large; font-weight: 400;"> Please link an account. </h5>

                        <div class="mx-4">
                            <social-media [scope]="'User'" [socialMediaIntegrationEnabled]="userProfile.socialMediaIntegrationEnabled"
                                [ayrshare]="ayrshare">
                            </social-media>
                        </div>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Finish'">
                        <div class="card-body text-center mt-3">
                            <h5>Signup completed!</h5>
                            <button class="btn btn-primary mt-4" (click)="gotoDashboard()">Go to Dashboard</button>
                        </div>
                    </ng-wizard-step>

                    
                </ng-wizard>

                <div class="btn-toolbar justify-content-end mt-2">
                    <div role="group" class="btn-group mr-2 ng-wizard-btn-group">
                        <button class="btn btn-secondary ng-wizard-btn-prev" [disabled]="currentStep.index == 0" (click)="showPreviousStep()">
                            Previous
                        </button>
                        <button class="btn btn-secondary ng-wizard-btn-next" [disabled]="currentStep.title == 'Finish'" (click)="nextStep()">
                            Next
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
