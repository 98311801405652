<form #quoteSearchForm="ngForm" class="quote-search-form mt-2" novalidate id="quoteSearchForm" name="quoteSearchForm">
  <div class="row {{showMultipleColumns ? 'mx-n2 gx-4' : ''}}">
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-3' : ''}}">
      <div class="row {{showMultipleColumns ? 'border' : ''}}">
        <div class="col-md-12">
          <div class="mb-3 text-center">
            <h5>Loan Info</h5>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="channel">Channel</label>
            <select class="form-select" id="channel" section="loan-information" name="channel" #channelField="ngModel"
              [ngClass]="{'is-invalid' : channelField && channelField.touched && channelField.invalid}"
              [(ngModel)]="quoteSearchRequest.requestorInformation.channel" required [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of channelOptions" [ngValue]="option.name">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="loanPurposeField">Loan Purpose</label>
            <select class="form-select" id="loanPurposeField" section="loan-information" name="loanPurposeField"
              #loanPurposeField="ngModel"
              [ngClass]="{'is-invalid' : loanPurposeField && loanPurposeField.touched && loanPurposeField.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.loanPurpose" (ngModelChange)="onLoanPurposeChanged()" required [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of loanPurposeOptions" [ngValue]="option.alternateValue">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="loanNumberField">Loan Number / Quote Name</label>
            <input class="form-control" type="text" section="loan-information" [required]="true" name="loanNumberField"
              #loanNumberInput="ngModel" [disabled]="true"
              [ngClass]="{'is-invalid' : loanNumberInput && loanNumberInput.touched && loanNumberInput.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.loanNumber">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <ng-container
          *ngIf="['RefinanceCashOut', 'Refinance', 'StreamlineRefinance'].includes(quoteSearchRequest.loanInformation.loanPurpose)">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label" for="refinancePurposeField">Refinance Purpose</label>
              <select class="form-select" id="refinancePurposeField" section="loan-information"
                name="refinancePurposeField" #refinancePurposeField="ngModel"
                [ngClass]="{'is-invalid' : refinancePurposeField && refinancePurposeField.touched && refinancePurposeField.invalid}"
                [(ngModel)]="quoteSearchRequest.loanInformation.refinancePurpose" required>
                <option [ngValue]="null">--Select One--</option>
                <option *ngFor="let option of refinancePurposeOptions" [ngValue]="option.alternateValue">
                  {{option.name}}</option>
              </select>
              <div class="invalid-feedback">This field is required</div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label" for="refinancePrimaryPurposeField">Refinance Primary Purpose</label>
              <select class="form-select" id="refinancePrimaryPurposeField" section="loan-information"
                name="refinancePrimaryPurposeField" #refinancePrimaryPurpose="ngModel"
                [ngClass]="{'is-invalid' : refinancePrimaryPurpose && refinancePrimaryPurpose.touched && refinancePrimaryPurpose.invalid}"
                [(ngModel)]="quoteSearchRequest.loanInformation.refinancePrimaryPurpose" required>
                <option [ngValue]="null">--Select One--</option>
                <option *ngFor="let option of refinancePrimaryPurposes" [ngValue]="option.value">
                  {{option.name}}</option>
              </select>
              <div class="invalid-feedback">This field is required</div>
            </div>
          </div>

        </ng-container>


        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="amortType">Amortization Type </label>
            <select class="form-select" id="amortType" section="loan-information" name="amortType"
              #amortTypeField="ngModel"
              [ngClass]="{'is-invalid' : amortTypeField && amortTypeField.touched && amortTypeField.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.amortizationType" (change)="onAmortizationTypeChanged()" [required]="false" [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of amortizationTypeOptions" [ngValue]="option.alternateValue">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="amortizationTerm">Amortization Term (months) </label>
            <input class="form-control" type="number" section="loan-information" [required]="true"
              name="amortizationTerm" #amortizationTermInput="ngModel" [disabled]="!isQuickPricer"
              [ngClass]="{'is-invalid' : amortizationTermInput && amortizationTermInput.touched && amortizationTermInput.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.amortizationTerm">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="loanAmount">Base Loan Amount </label>
            <currency-input [name]="'loanAmount'" [required]="true" [isZeroValid]="false" [allowDecimals]="false"
              section="loan-information" [(ngModel)]="quoteSearchRequest.loanInformation.baseLoanAmount"
              [readonly]="!isQuickPricer">
            </currency-input>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="loanTerm">Loan Term (months) </label>
            <input class="form-control" type="number" section="loan-information" [required]="true" name="loanTerm"
              #loanTermInput="ngModel" [disabled]="!isQuickPricer"
              [ngClass]="{'is-invalid' : loanTermInput && loanTermInput.touched && loanTermInput.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.loanTerm">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6" *ngIf="quoteSearchRequest.loanInformation.amortizationType == 'ARM'">
          <div class="mb-3">
            <label class="form-label" for="armFixedTermMonths">Arm Fixed Term (months)</label>
            <input class="form-control" type="number" section="loan-information" [required]="false"
              name="armFixedTermMonths" #armFixedTermMonthsInput="ngModel" [disabled]="!isQuickPricer"
              [ngClass]="{'is-invalid' : armFixedTermMonthsInput && armFixedTermMonthsInput.touched && armFixedTermMonthsInput.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.armFixedTermMonths">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6" *ngIf="quoteSearchRequest.loanInformation.amortizationType == 'ARM'">
          <div class="mb-3">
            <label class="form-label" for="armSubsequentChangePeriodMonths">Arm Subseq. Change Period (months)</label>
            <input class="form-control" type="number" section="loan-information" [required]="false"
              name="armSubsequentChangePeriodMonths" #armSubsequentChangePeriodMonthsInput="ngModel" [disabled]="!isQuickPricer"
              [ngClass]="{'is-invalid' : armSubsequentChangePeriodMonthsInput && armSubsequentChangePeriodMonthsInput.touched && armSubsequentChangePeriodMonthsInput.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.armSubsequentChangePeriodMonths">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="interestRate">Interest Rate</label>
            <percent-input name="interestRate" [readonly]="true" section="loan-information"
              [(ngModel)]="quoteSearchRequest.loanInformation.interestRate" [rateIsTrueToTextValue]="false"
              [required]="true">
            </percent-input>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="buydown">Buydown</label>
            <select class="form-select" id="buydown" section="loan-information" name="buydown" #buydownField="ngModel"
              [ngClass]="{'is-invalid' : buydownField && buydownField.touched && buydownField.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.buydown" [required]="false" [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of buyDownOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="loanProgram">Loan Program</label>
            <select class="form-select" id="loanProgram" section="loan-information" name="loanProgram"
              #loanProgramField="ngModel"
              [ngClass]="{'is-invalid' : loanProgramField && loanProgramField.touched && loanProgramField.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.specialLoanProgram"
              (ngModelChange)="onLoanProgramChanged()" [required]="false" [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of specialLoanProgramOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6" *ngIf="quoteSearchRequest.loanInformation.specialLoanProgram == 'Other'">
          <div class="mb-3">
            <label class="form-label" for="cityField">Loan Program Description</label>
            <input class="form-control" type="text" section="loan-information" [required]="true" name="cityField"
              #loanProgramDescriptionInput="ngModel" [disabled]="!isQuickPricer"
              [ngClass]="{'is-invalid' : loanProgramDescriptionInput && loanProgramDescriptionInput.touched && loanProgramDescriptionInput.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.loanProgramOtherDescription">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="duRecommendation">DU Recommendation</label>
            <select class="form-select" id="duRecommendation" section="loan-information" name="duRecommendation"
              #duRecommendationField="ngModel"
              [ngClass]="{'is-invalid' : duRecommendationField && duRecommendationField.touched && duRecommendationField.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.duAusRecommendation" [required]="false"
              [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of duAusRecommendationOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="lpaRecommendation">LPA Recommendation</label>
            <select class="form-select" id="lpaRecommendation" section="loan-information" name="lpaRecommendation"
              #lpaRecommendationField="ngModel"
              [ngClass]="{'is-invalid' : lpaRecommendationField && lpaRecommendationField.touched && lpaRecommendationField.invalid}"
              [(ngModel)]="quoteSearchRequest.loanInformation.lpaAusRecommendation" [required]="false"
              [disabled]="!isQuickPricer">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of lpaAusRecommendationOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="isRehabilitation">Rehabilitation</label>
            <div class='form-switch'>
              <input name="isRehabilitation" id="isRehabilitation" type='checkbox' class='form-check-input'
                [(ngModel)]="quoteSearchRequest.loanInformation.isRehabilitation" [required]="false"
                #isRehabilitationInput="ngModel" [disabled]="!isQuickPricer" section="loan-information"
                [ngClass]="{'is-invalid' : isRehabilitationInput && isRehabilitationInput.touched && isRehabilitationInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="corporateRelocation">Corporate Relocation</label>
            <div class='form-switch'>
              <input name="isCorporateRelocation" id="isCorporateRelocation" type='checkbox' class='form-check-input'
                [(ngModel)]="quoteSearchRequest.loanInformation.isCorporateRelocation" [required]="false"
                #corporateRelocationInput="ngModel" [disabled]="!isQuickPricer" section="loan-information"
                [ngClass]="{'is-invalid' : corporateRelocationInput && corporateRelocationInput.touched && corporateRelocationInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-3' : ''}}">
      <div class="row {{showMultipleColumns ? 'border' : ''}}">
        <div class="col-md-12">
          <div class="mb-3 text-center">
            <h5>Property Info</h5>
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label" for="propertyAddress">Address</label>
            <address-autocomplete-input (addressChange)='handleAddressChange($event)' section="property-information"
              [(ngModel)]='quoteSearchRequest.propertyInformation.address' id="borrower-address" name='address' #address='ngModel'
              placeholder='Number and Street' [required]='true' [class.is-invalid]='address && address.touched && address.invalid'>
            </address-autocomplete-input>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="propertyZIPfield">Zip Code</label>
            <div class="input-group mb-3">
              <zip-code-input [(ngModel)]="quoteSearchRequest.propertyInformation.zipCode" [required]="true"
                (blur)="onZipCodeBlur($event)" style="width:100%" [name]="'propertyZIPfield'"
                section="property-information" (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
              </zip-code-input>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="cityField">City</label>
            <input class="form-control" type="text" section="property-information" [required]="true" name="cityField"
              #cityFieldInput="ngModel" [disabled]="false"
              [ngClass]="{'is-invalid' : cityFieldInput && cityFieldInput.touched && cityFieldInput.invalid}"
              [(ngModel)]="quoteSearchRequest.propertyInformation.city">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="stateField">State</label>
            <select class="form-select" id="stateField" name="stateField" [name]="'stateField'" #stateField="ngModel"
              [(ngModel)]="quoteSearchRequest.propertyInformation.state" [disabled]="false"
              section="property-information"
              [ngClass]="{'is-invalid' : stateField && stateField.touched && stateField.invalid}" required>
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of stateOptions" value="{{option.value}}">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="countyField">County</label>
            <input class="form-control" name="countyField" required #countyField="ngModel"
              section="property-information"
              [ngClass]="{'is-invalid' : countyField && countyField.touched && countyField.invalid}"
              (typeaheadOnSelect)="onCountySelect($event)" [(ngModel)]="quoteSearchRequest.propertyInformation.county"
              [typeahead]="countyList" typeaheadOptionField="name" [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="10" />
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="occupancyField">Occupancy</label>
            <select class="form-select" id="occupancyField" name="occupancyField" [name]="'occupancyField'"
              #occupancyInput="ngModel" [(ngModel)]="quoteSearchRequest.propertyInformation.occupancy"
              [disabled]="!isQuickPricer" section="property-information"
              [ngClass]="{'is-invalid' : occupancyInput && occupancyInput.touched && occupancyInput.invalid}" required>
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of occupancyOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="attachmentTypeField">Attachment Type</label>
            <select class="form-select" id="attachmentTypeField" name="attachmentTypeField"
              [name]="'attachmentTypeField'" #attachmentTypeInput="ngModel"
              [(ngModel)]="quoteSearchRequest.propertyInformation.attachmentType" [disabled]="!isQuickPricer"
              section="property-information"
              [ngClass]="{'is-invalid' : attachmentTypeInput && attachmentTypeInput.touched && attachmentTypeInput.invalid}"
              required>
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of attachmentTypeOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="numberOfUnitsField">Number Of Units </label>
            <select class="form-select" id="numberOfUnitsField" name="numberOfUnitsField" [name]="'numberOfUnitsField'"
              #numberOfUnitsInput="ngModel" [(ngModel)]="quoteSearchRequest.propertyInformation.numberOfUnits"
              [disabled]="!isQuickPricer" section="property-information"
              [ngClass]="{'is-invalid' : numberOfUnitsInput && numberOfUnitsInput.touched && numberOfUnitsInput.invalid}"
              required>
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of numberOfUnitsOptions" [ngValue]="option.value">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="numberOfStories">Number Of Stories </label>
            <input class="form-control" type="number" section="property-information" [required]="true"
              name="numberOfStories" #numberOfStoriesInput="ngModel" [disabled]="!isQuickPricer"
              [ngClass]="{'is-invalid' : numberOfStoriesInput && numberOfStoriesInput.touched && numberOfStoriesInput.invalid}"
              [(ngModel)]="quoteSearchRequest.propertyInformation.numberOfStories">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>


        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="isConstruction">Construction</label>
            <div class='form-switch'>
              <input name="isConstruction" id="isConstruction" type='checkbox' class='form-check-input'
                [(ngModel)]="quoteSearchRequest.propertyInformation.isConstruction" [required]="false"
                #isConstructionInput="ngModel" [disabled]="!isQuickPricer" section="property-information"
                [ngClass]="{'is-invalid' : isConstructionInput && isConstructionInput.touched && isConstructionInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="constructionMethod">Construction Method</label>
            <select class="form-select" id="constructionMethod" name="constructionMethod"
              #constructionMethodInput="ngModel" [(ngModel)]="quoteSearchRequest.propertyInformation.constructionMethod"
              [required]="true" section="property-information"
              [ngClass]="{'is-invalid' : constructionMethodInput && constructionMethodInput.touched && constructionMethodInput.invalid}">
              >
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of constructionMethodOptions" [ngValue]="option.alternateValue">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6" *ngIf="isConstructionTypeManufactured()">
          <div class="mb-3">
            <label class="form-label" for="manufacturedHomeWidthType">Home Width Type</label>
            <select class="form-select" name="manufacturedHomeWidthType" id="manufacturedHomeWidthType"
              section="property-information"
              [(ngModel)]="quoteSearchRequest.propertyInformation.manufacturedHomeWidthType">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="option.alternateValue" *ngFor="let option of manufacturedHomeWidthTypeOptions">
                {{option.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="projectType">Project Type</label>
            <select class="form-select" name="projectType" id="projectType" section="property-information"
              [(ngModel)]="quoteSearchRequest.propertyInformation.projectType">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="option.alternateValue" *ngFor="let option of projectTypeOptions">{{option.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="isProjectTypeCondominium()">
          <div class="mb-3">
            <label class="form-label" for="projectDesignType">Project Design Type</label>
            <select class="form-select" name="projectDesignType" id="projectDesignType" section="property-information"
              [(ngModel)]="quoteSearchRequest.propertyInformation.projectDesignType">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="option.alternateValue" *ngFor="let option of projectDesignTypeOptions">
                {{option.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="isPlannedUnitDevelopment">Planned Unit Development</label>
            <div class='form-switch'>
              <input name="isPlannedUnitDevelopment" id="isPlannedUnitDevelopment" type='checkbox' class='form-check-input'
                [(ngModel)]="quoteSearchRequest.propertyInformation.isPlannedUnitDevelopment" [required]="false"
                #isPlannedUnitDevelopmentInput="ngModel" [disabled]="!isQuickPricer" section="property-information"
                [ngClass]="{'is-invalid' : isPlannedUnitDevelopmentInput && isPlannedUnitDevelopmentInput.touched && isPlannedUnitDevelopmentInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="purchasePrice">Purchase Price </label>
            <currency-input [name]="'purchasePrice'" [required]="true" [isZeroValid]="false" [allowDecimals]="false"
              section="property-information" [(ngModel)]="quoteSearchRequest.propertyInformation.salesPrice"
              [readonly]="!isQuickPricer">
            </currency-input>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="appraisalValue">Appraised Value </label>
            <currency-input [name]="'appraisalValue'" [required]="true" [isZeroValid]="false" [allowDecimals]="false"
              section="property-information" [(ngModel)]="quoteSearchRequest.propertyInformation.appraisedValue"
              [readonly]="!isQuickPricer">
            </currency-input>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-3' : ''}}">
      <div class="row {{showMultipleColumns ? 'border' : ''}}">
        <div class="col-md-12">
          <div class="mb-3 text-center">
            <h5>Borrower Info</h5>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="housingExpenseRatioPercent">Housing Expense Ratio %</label>
            <percent-input name="housingExpenseRatioPercent" [disabled]="false" section="borrower-information"
              [(ngModel)]="quoteSearchRequest.borrowerInformation.housingExpenseRatioPercent"
              [rateIsTrueToTextValue]="false" [required]="true">
            </percent-input>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="debtToIncomeRatio">DTI Ratio without MI %</label>
            <percent-input name="debtToIncomeRatio" [disabled]="true" section="borrower-information"
              [(ngModel)]="quoteSearchRequest.borrowerInformation.debtToIncomeRatio" [rateIsTrueToTextValue]="false"
              [required]="true">
            </percent-input>
          </div>
        </div>

        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label d-flex align-items-center justify-content-between" for="borrowerDetails">Borrowers
              <button class="btn btn-sm btn-soft-danger pull-right" *ngIf="haveMultipleRowSelected()"
                (click)="bulkDeleteClicked()"><i class="fa fa-trash-alt me-1"></i> Delete Selected</button></label>
            <div class="w-100">
              <div class="row" *ngIf="quoteSearchRequest.borrowerInformation?.borrowerDetails?.length">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <tbody>
                      <ng-container
                        *ngFor="let borr of quoteSearchRequest.borrowerInformation.borrowerDetails; let i = index">
                        <tr (mouseenter)="hoveredItems[borr.id] = true" (mouseleave)="hoveredItems[borr.id] = false">
                          <td style="width: 100%">
                            <span class="d-inline-flex align-items-center">
                              <span class="me-2" style="width: 15px;">
                                <div class="form-check" *ngIf="hoveredItems[borr.id] || selectedRows[borr.id]">
                                  <input id="selectedRows-{{ borr.id }}" class="form-check-input" type="checkbox"
                                    name="selectedRows-{{ borr.id }}" [(ngModel)]="selectedRows[borr.id]" />
                                </div>
                              </span>
                              <i (click)="expandedItems[borr.id] = !expandedItems[borr.id]; editItems[borr.id] = true"
                                class="me-2 cursor {{
                                  expandedItems[borr.id] ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
                                }}"></i>
                              <span class="d-inline-flex align-items-center">
                                <text-input [(ngModel)]="borr.firstName" class="me-2" name="firstName-{{ borr.id }}"
                                  [editorMode]="'Inline'" [placeholder]="'First Name'" [disabled]="!isQuickPricer"></text-input>
                                <text-input [(ngModel)]="borr.lastName" name="lastName-{{ borr.id }}"
                                  [editorMode]="'Inline'" [placeholder]="'Last Name'" [disabled]="!isQuickPricer"></text-input>
                              </span>
                            </span>
                          </td>
                        </tr>
                        <tr *ngIf="expandedItems[borr.id]">
                          <td colspan="1">
                            <quote-additional-borrower-editor [borrower]="borr"
                              [isEdit]="editItems[borr.id]" (close)="closeTaxTranscriptClicked(borr.id, $event)"
                              (save)="editTaxTranscriptClicked(borr.id, $event)">
                            </quote-additional-borrower-editor>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mt-3">
                <div class="form-group">
                  <button type="button" class="btn btn-sm btn-outline-primary" (click)="addBorrowerClicked()">
                    <i class="fas fa-plus me-1"></i>
                    Add Borrower </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-3' : ''}}">
      <div class="row {{showMultipleColumns ? 'border' : ''}}">
        <div class="col-md-12">
          <div class="mb-3 text-center">
            <h5>Mortgage Insurance Info</h5>
          </div>
        </div>

        <div class="col-md-6" *ngIf="false">
          <div class="mb-3">
            <label class="form-label" for="coveragePercentage">Coverage %</label>
            <percent-input name="coveragePercentage" [disabled]="false" section="mi-information"
              [(ngModel)]="quoteSearchRequest.searchCriteria.coveragePercentage" [rateIsTrueToTextValue]="false"
              [required]="true">
            </percent-input>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="emailField">Email</label>
            <input class="form-control" type="email" section="mi-information" [required]="true" name="emailField"
              #emailInput="ngModel" [disabled]="false" trim email
              [ngClass]="{'is-invalid' : emailInput && emailInput.touched && emailInput.invalid}"
              [(ngModel)]="quoteSearchRequest.requestorInformation.email">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>
