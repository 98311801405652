import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableModule } from 'primeng/table';
import { InternalContactsModule } from '../internal-contacts/internal-contacts.module';
import { ExternalContactsModule } from '../external-contacts/external-contacts.module';
import { FileContactsComponent } from './components/file-contacts.component';
import { ExternalContactsV2Module } from '../external-contacts-v2/external-contacts-v2.module';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    InternalContactsModule,
    ExternalContactsModule,
    ExternalContactsV2Module
  ],
  declarations: [
    FileContactsComponent
  ],
  exports: []
})
export class FileContactsModule {
  constructor() { }
}
