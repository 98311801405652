<div class="modal-header">
    <h5 class="modal-title">LOS User</h5>
</div>
<div class="modal-body">
    <div class="row mb-3">
        <div class="col-md-12 pt-2">
            <label class="custom-control-label">User Name:</label>
            <input type="text" class="form-control" [(ngModel)]="testLosUser.userName" readonly>
        </div>
        <div class="col-md-12 pt-2">
            <label class="custom-control-label">First Name:</label>
            <input type="text" class="form-control" [(ngModel)]="testLosUser.firstName" readonly>
        </div>
        <div class="col-md-12 pt-2">
            <label class="custom-control-label">Last Name:</label>
            <input type="text" class="form-control" [(ngModel)]="testLosUser.lastName" readonly>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pt-2">
            <label class="custom-control-label">User Roles:</label>
            <p *ngFor="let item of testLosUser.userRoles">{{item}}</p>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <button
        type="button"
        class="btn btn-secondary right"
        (click)="close()"
    >
        Close
    </button>
</div>
