import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, catchError, filter, switchMap, take } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { AuthService } from 'src/app/services/auth.service';

import { DateTime } from "luxon";
import { LocalStorageService } from './local-storage.service';
import { AuthData } from 'src/app/models/auth/auth-data.model';
import { Utils } from './utils';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response.model';
import { LoginStatus } from 'src/app/models/auth/login-status.enum';

const TOKEN_HEADER_KEY = "Bearer";

const TOKEN_EXPIRATION_TOLERANCE_IN_SECS = 900;

const TOKEN_EXPIRATION_ERROR = 'Token Expiration';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private _isRefreshing = false;
  private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _navigationService: NavigationService,
    private readonly _authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authData = this._localStorage.authorizationData;
    if (!request.url.includes('refreshToken')) {
      if (this.isTokenAboutToExpire(authData)) {
        return this.refreshToken(request, next);
      }
    }

    if (authData && authData.token) {
      request = this.addTokenHeader(request, authData.token);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.error === TOKEN_EXPIRATION_ERROR) {
          return this.refreshToken(request, next);
        } else if (error.status === 401) {
          this._authService.clearAll();
          this._navigationService.navigateToLogin();
        }
        error.currentPath = location.pathname;
        error.message = error?.error?.message || null;
        throw error;
      })
    );
  }

  private isTokenAboutToExpire = (authData: AuthData) => {
    if (authData) {
      const expiresAt = DateTime.fromISO(authData.expiresAt.toString());
      const now = DateTime.fromJSDate(new Date());
      const secondsToExpiration = expiresAt.diff(now, 'seconds');
      return secondsToExpiration.seconds <= TOKEN_EXPIRATION_TOLERANCE_IN_SECS;
    }
    return false;
  }

  private addTokenHeader = (request: HttpRequest<any>, token: string) => {
    return request.clone({
      setHeaders: {
        Authorization: `${TOKEN_HEADER_KEY} ${token}`
      }
    });
  }

  private refreshToken = (request: HttpRequest<any>, next: HttpHandler) => {
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._refreshTokenSubject.next(null);

      const refreshToken = this._localStorage.authorizationData.refreshToken;

      if (refreshToken) {
        return this._authService.refreshToken(refreshToken).pipe(
          switchMap((authResponse: any) => {
            if (authResponse?.loginStatus === LoginStatus.Error) {
              this._authService.clearAll();
              this._navigationService.navigateToLogin();
              return throwError(authResponse?.errorMessage);
            }
            this.persistAuthData(this._localStorage.authorizationData.companyGuid,
              this._localStorage.authorizationData.rememberMe,
              authResponse)
            this._isRefreshing = false;
            this._refreshTokenSubject.next(authResponse.jwtToken);

            return next.handle(this.addTokenHeader(request, authResponse.jwtToken));
          }), catchError(error => {
            if (error.error !== TOKEN_EXPIRATION_ERROR && error.status === 401) {
              this._authService.clearAll();
              this._navigationService.navigateToLogin();
              return throwError(error);
            }
            return next.handle(request);
          })
        );
      } else {
        this._authService.clearAll();
        this._navigationService.navigateToLogin();
      }
    }
    return this._refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private persistAuthData = (companyGuid: string, rememberMe: boolean, authResponse: AuthenticationResponse): void => {
    let jwtPayload = Utils.parseJwt(authResponse.jwtToken);
    const authData: AuthData = {
      companyGuid,
      userCompanyGuid: authResponse.userCompanyGuid,
      token: authResponse.jwtToken,
      userName: authResponse.userName,
      refreshToken: authResponse.refreshToken,
      companies: authResponse.availableCompanies,
      rememberMe,
      expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
    };
    this._localStorage.authorizationData = authData;
  }
}
