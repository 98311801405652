<form id="productForm" #productForm="ngForm" name="productForm">
  <div class="p-3">
    <div *ngIf="isShowField('active')" class="float-end">
      <div class="form-check form-switch form-switch-success">
        <input class="form-check-input" type="checkbox" name="isActive" id="isActive" [(ngModel)]="product.active">
        <label class="form-check-label fw-bold" for="isActive">Is Active</label>
      </div>
    </div>
    <div *ngIf="isShowField('productName')" class="mb-3">
      <label class="control-label" for="productName">Product Name</label>
      <input id="productName" type="text" name="productName" class="form-control"
        [ngClass]="{'is-invalid': nameInput && nameInput.touched && nameInput.invalid}"
        [(ngModel)]="product.productName" #nameInput="ngModel" required />
      <div class="invalid-feedback">This field is required</div>
    </div>

    <div class="mb-3" *ngIf="isShowField('productCategory')">
      <label class="control-label" for="productCategory">Product Category</label>
      <ng-select width="100%" name="productCategory" bindLabel="name" bindValue="value"
        placeholder="Select Product Category" class="custom-ng-select" [multiple]="false"
        [(ngModel)]="product.productCategory" [items]="categorySelectList" [addTag]="addNewCategory">
        <ng-template ng-tag-tmp let-search="searchTerm">
          <b>Add New Category</b>: {{search}}
        </ng-template>
      </ng-select>
    </div>

    <div class="mb-3" *ngIf="isShowField('enabledChannels')">
      <label for="channels">Role Channels</label>
      <ng-select width="100%" name="channels" bindLabel="name" bindValue="name" [(ngModel)]="selectedRolechannels"
        placeholder="Select Channels" [closeOnSelect]="false" [multiple]="true" [items]="enabledChannels">
      </ng-select>
    </div>

    <div class="mb-3" *ngIf="isShowField('term')">
      <label for="term">Term</label>
      <input id="term" numeric [allowNegative]="false" name="term" class="form-control" [(ngModel)]="product.term"
        #termInput="ngModel" [ngClass]="{
            'is-invalid': termInput && termInput.touched && termInput.invalid
          }" required />
      <div class="invalid-feedback">This field is required</div>
    </div>

    <div class="mb-3" *ngIf="isShowField('pricingVendor')">
      <label for="pricingVendor">Pricing Vendor</label>
      <select class="form-select" name="pricingVendor" id="pricingVendor" (change)="handlePricingVendorChange()"
        [(ngModel)]="product.pricingVendor">
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let vendor of pricingVendors" [ngValue]="vendor.value">
          {{ vendor.name }}
        </option>
      </select>
    </div>
    <ng-container>
      <div class="mb-3" *ngIf="isShowField('pricingVendorProductId')">
        <label for="pricingVendorProductId">Pricing Vendor Product Id</label>
        <ng-select *ngIf="product.pricingVendor === 'OptimalBlue'" name="pricingVendorProductId" bindLabel="display" bindValue="productId" placeholder="Select product"
          class="custom-ng-select" #pricingVendorProductInput="ngModel" [items]="filteredPricingVendorProducts"
          [(ngModel)]="product.pricingVendorProductId"
          [ngClass]="{ 'is-invalid': !product.pricingVendorProductId && product.pricingVendor && pricingVendorProductInput && pricingVendorProductInput.touched }"
          appendTo="body" [required]="!!product.pricingVendor"></ng-select>
          <input *ngIf="product.pricingVendor === 'LenderPrice' || product.pricingVendor === 'Polly' || product.pricingVendor === 'MeridianLink' || product.pricingVendor === 'LoanPass' || product.pricingVendor === 'LoanPassIframe'" type="text" name="pricingVendorProductId" class="form-control"
          [(ngModel)]="product.pricingVendorProductId" #pricingVendorProductInput="ngModel" required
          [ngClass]="{ 'is-invalid': pricingVendorProductInput && pricingVendorProductInput.touched && pricingVendorProductInput.invalid }">
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="mb-3" *ngIf="isShowField('losVendor')">
        <label for="losVendor">Los Vendor</label>
        <select class="form-select" name="losVendor" id="losVendor" (change)="handleLosVendorChange()"
          [(ngModel)]="product.losVendor">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let vendor of losVendors" [ngValue]="vendor.value">
            {{ vendor.name }}
          </option>
        </select>
      </div>

      <div class="mb-3" *ngIf="isShowField('losVendorProductId')">
        <label for="losVendorProductId">Los Vendor Product Id</label>
        <ng-select name="losVendorProductId" bindLabel="display" bindValue="losProductId" placeholder="Select product"
          class="custom-ng-select" #losVendorProductInput="ngModel" [items]="filteredLosVendorProducts"
          [(ngModel)]="product.losVendorProductId"
          [ngClass]="{ 'is-invalid': !product.losVendorProductId && product.losVendor && losVendorProductInput && losVendorProductInput.touched }"
          appendTo="body" [required]="!!product.losVendor"></ng-select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </ng-container>
    <div class="text-end">
      <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
        Close
      </button>
      <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" [disabled]="isSaving">
        <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="isSaving"></span>
        Save
      </button>
    </div>
  </div>
</form>
