import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {AmortizationComponent} from './components/amortization.component';
import {AmortizationService} from '../../services/amortization.service';
import {TableModule} from 'primeng/table';

@NgModule({
    imports: [
        SharedModule,
        TableModule
    ],
    declarations: [
        AmortizationComponent
    ],
    exports: [
        AmortizationComponent
    ],
    providers: [
        AmortizationService
    ]
})
export class AmortizationModule {
  constructor() {

  }
}


