<div class="modal-header">
    <h5 class="modal-title">Appraisal Condition</h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <label class="form-label" for="category">Condition Category</label>
            <select class="form-select" [(ngModel)]="newCondition.conditionCategory" name="category">
                <option value="">-- Select One --</option>
                <option [value]="category.value" *ngFor="let category of conditionCategories" title="{{category.name}}">
                    {{category.name}}
                </option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label class="form-label" for="conditionText">Condition Text</label>
            <textarea rows="8" class="form-control" name="conditionText" [(ngModel)]="newCondition.conditionText"
                #conditionText="ngModel" autocomplete="off">
                </textarea>
        </div>  
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary right"
        [disabled]="!newCondition.conditionCategory  || !newCondition.conditionText"
        (click)="activeModal.close(newCondition)">Add</button>
</div>