import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from "../../../../../../../shared/components";
import {SystemLevelService} from "../../../../../../../services/system-level.service";
import {Configuration} from "../../../../../../../models";
import { ScopeType } from '../../../integrations.component';
import { ConfigurationService } from 'src/app/services/configuration.service';

interface Role {
  roleId: number;
  roleName: string;
  isChecked: boolean;
}

@Component({
  selector: 'roles-finalize-do-findings',
  templateUrl: './roles-finalize-do-findings-modal.component.html'
})
export class RolesFinalizeDoFindingsModalComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input() branchId: number;
  @Input() scope: ScopeType;
  @Input() allowedRolesToFinalizeDOFindingsConfig: Configuration;

  roles: Role[] = [];
  isSaving: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notifyService: NotificationService,
    private readonly _configurationService: ConfigurationService,
    public activeModal: NgbActiveModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.allowedRolesToFinalizeDOFindingsConfig.valueStr) {
      this.allowedRolesToFinalizeDOFindingsConfig.valueStr = '';
    }
    const valueStr = this.allowedRolesToFinalizeDOFindingsConfig.valueStr.split(',');
    this.roles = this.applicationContext.globalConfig.roles.map(el => ({
      roleId: el.roleId,
      roleName: el.roleName,
      isChecked: valueStr.includes(el.roleId.toString())
    }))
  }

  save(): void {
    this.isSaving = true;
    this.allowedRolesToFinalizeDOFindingsConfig.valueStr = this.roles.filter(el => el.isChecked).map(({roleId}) => roleId).join();

    let requestMethod = null;
    if (this.scope === 'Branch') {
      this.allowedRolesToFinalizeDOFindingsConfig.branchId = this.branchId;
      requestMethod = this._configurationService.upsertBranchConfiguration(this.allowedRolesToFinalizeDOFindingsConfig);
    } else {
      requestMethod = this._systemLevelService.saveConfiguration(this.allowedRolesToFinalizeDOFindingsConfig);
    }

    requestMethod.subscribe({
      next: res => {
        this._notifyService.showSuccess("Changes saved successfully.", "Success");
        this.activeModal.close(res);
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't save the changes.", "Error");
      }
    }).add(() => {
      this.isSaving = false;
    });
  }
}
