import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lead-first-last-name-search-dialog',
  templateUrl: './lead-first-last-name-search-dialog.component.html',
  styleUrls: ['./lead-first-last-name-search-dialog.component.scss']
})
export class LeadFirstLastNameSearchDialogComponent implements OnInit {

  firstName: string;
  lastName: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onSearch = () => {
    this.activeModal.close({firstName: this.firstName, lastName: this.lastName});
  }

}
