<ng-container *ngIf="!loadingSystemData; else loadingContent">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active': currentTab === 'email-settings' }"
            (click)="currentTab = 'email-settings'">
            Email Settings
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active': currentTab === 'email-templates' }"
            (click)="currentTab = 'email-templates'">
            Email Templates
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active': currentTab === 'email-scheduling' }"
            (click)="currentTab = 'email-scheduling'">
            Email Scheduling
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div *ngIf="currentTab === 'email-settings'" class="pt-3">
          <email-settings
            *ngIf="!loadingSystemData"
            [emailService]="emailService"
            [type]="type"
            [microsoftGraph]="microsoftGraph"
            [amazonWebServices]="amazonWebServices"
            [allowEmailImpersonation]="allowEmailImpersonation"
            [restrictedUserEmailTemplateOverrides]="restrictedUserEmailTemplateOverrides"
            [companyId]="companyId"
            (settingsUpdated)="getSystemLevel()">
          </email-settings>
        </div>
        <div *ngIf="currentTab === 'email-templates'" class="pt-3">
          <email-templates
            *ngIf="!loadingSystemData"
            [definedEmailTemplate]="definedEmailTemplate"
            [branchSettings]="branchSettings"
            [reminderEmailEnabled]="reminderEmailEnabled"
            [reminderEmailInterval]="reminderEmailInterval"
            [type]="type"
            (settingsUpdated)="getSystemLevel()">
          </email-templates>
        </div>
        <div *ngIf="currentTab === 'email-scheduling'" class="pt-3">
          <correspondence-weekly-schedule [delivery]="'Email'"></correspondence-weekly-schedule>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingContent>
  <loading-indicator loadingMessage="Loading Email Settings..." [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>


