<div class="modal-body">
    <form #upsertLoanPurposeForm="ngForm" ngbAutofocus class="row">
        <div *ngIf="enabledChannels.length" class="mb-3">
            <label class="control-label">Channels</label>
            <ng-select width="100%" name="channels" bindLabel="text" bindValue="id" placeholder="Select Channels"
                [multiple]="true" [closeOnSelect]="false" [(ngModel)]="multiselectChannelsModel"
                [items]="multiselectChannels">
            </ng-select>
        </div>
        <div class="mb-3">
            <label class="control-label">Loan Purpose</label>
            <input class="form-control"
                [ngClass]="{ 'is-invalid' : loanPurposeName && loanPurposeName.touched && loanPurposeName.invalid }"
                name="loanPurposeName" [(ngModel)]="loanPurpose.loanPurposeName" #loanPurposeName="ngModel" required />
            <div class="invalid-feedback">Loan purpose is required.</div>
        </div>
        <div class="mb-3">
            <label class="control-label">Transaction Type</label>
            <select class="form-control" name="transactionType" [(ngModel)]="loanPurpose.transactionType">
                <option value="">-- Select One --</option>
                <option *ngFor="let type of transactionTypes" value="{{type.value}}">{{type.name}}</option>
            </select>
        </div>
        <div class="mb-3" *ngIf="loanPurpose.transactionType == 'Mortgage'">
            <label class="control-label">Mortgage Loan Purpose</label>
            <select class="form-control" name="mortgageLoanPurpose" [(ngModel)]="loanPurpose.mortgageLoanPurpose">
                <option value="">-- Select One --</option>
                <option *ngFor="let type of mortgageLoanPurposeTypes" value="{{type.value}}">{{type.name}}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary" (click)="onClose.emit()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" style="width: 75px">
            <span *ngIf="!saving">Save</span>
            <span class="spinner-border spinner-border-sm" role="status" *ngIf="saving"></span>
        </button>
    </div>
</div>
