import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user/user.model';
import { Address, Borrower } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { BorrowerAccountStatus, BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ConfirmPortalInviteComponent } from '../confirm-portal-invite/confirm-portal-invite.component';
import { DuplicateDialogComponent } from 'src/app/shared/components/duplicate-dialog/duplicate-dialog.component';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { NgForm } from '@angular/forms';
import { BorrowersService } from '../../services/borrowers.service';
import { State } from 'src/app/modules/leads/components/lead-editor/lead-general-info/lead-general-info.component';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { ManualDialParams } from 'src/app/modules/dialer/models/manual-dial-params.model';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { combineLatest } from 'rxjs';
import { ContactsService } from 'src/app/modules/contacts/services/contacts.service';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'borrower-details',
  templateUrl: './borrower-details.component.html',
  styleUrls: ['./borrower-details.component.scss']
})
export class BorrowerDetailsComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("borrowerDetailsForm")
  borrowerDetailsForm: NgForm | undefined;

  @Input()
  borrowerId: number = null;

  @Input()
  loanId: number = null;

  @Input()
  companyId: number = null;

  @Input()
  isManualDial: boolean = true;

  @Input()
  hasCancelButton: boolean = true;

  @Input()
  hasSaveAndCloseButton: boolean = false;

  @Input()
  isMortgage: boolean = false;

  @Output()
  borrowerLoaded = new EventEmitter<BorrowerFull>();

  @Output()
  savedBorrower = new EventEmitter<any>();

  @Output() dialClicked = new EventEmitter<any>();

  @Output()
  borrowerEditCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  roles: Role[] = [];
  users: User[] = [];

  fullBorrower: BorrowerFull = null;

  currentPortalUserName: string;
  states: State[] = [];
  isSendingInvite: boolean = false;
  creditEnabled: boolean = false;
  isSaving: boolean = false;
  smsEnabledForCompany: boolean;
  dialerEnabled: boolean;

  maxDate: Date = new Date();
  minDate: Date = new Date("1/1/1900");

  protected borrowerForPortalStatus: BorrowerDto;

  private _duplicateBorrowers: Borrower[] = [];
  private _loggedInUserId: string;
  private _companyId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _notifsService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _applicationcontextService: ApplicationContextService,
    private readonly _borrowersService: BorrowersService,
    private readonly _dialerService: DialerService,
    private readonly _conversationService: ConversationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _configurationService: ConfigurationService
  ) {
    super(injector);
  }

  ngOnInit() {
    this._applicationcontextService.context.subscribe(context => {
      this.users = context.globalConfig.users;
      this.smsEnabledForCompany = context.userPermissions.smsAlertsEnabled;
      this.dialerEnabled = context.userPermissions.dialerEnabled;
      this.states = Object.keys(context.globalConfig.states).map(key => new State({ code: key, name: context.globalConfig.states[key] }));
      this.roles = context.globalConfig.roles.filter(r => !!r.allowBorrowerAssignment);
      this._loggedInUserId = context.userPermissions.userId;
      this._companyId = context.userPermissions.companyId;
      //this.creditEnabled = context.userPermissions.creditMonitoringEnabled;
      //TODO: We need to ask Kevin for this field, bacause it does not seem to exist in the swagger data model.

      if (this.borrowerId) {
        this.getFullBorrower(this.borrowerId);
      }
      else {
        this._configurationService.getCompanyConfiguration('EnableSmsAlertsByDefault').subscribe({
          next: (enableSmsAlertsByDefault => {
            const smsEnabledByDefault = enableSmsAlertsByDefault?.value === 1;
            this.fullBorrower = new BorrowerFull();
            this.fullBorrower.borrower.companyId = this.companyId;
            this.fullBorrower.borrower.borrowerContact = this._loggedInUserId;
            this.fullBorrower.borrower.alertViaSms = this.smsEnabledForCompany && smsEnabledByDefault;
          }),
          error: (err) => {
            this._notifsService.showError(err?.message || 'Unable to get System Level Info', 'Error!');
          }
        })
      }
    });
  }

  resendInviteToBorrower = (borrowerId: number) => {
    this.isSendingInvite = true;
    this._borrowersService.inviteBorrower(borrowerId, this.loanId).subscribe(result => {
      this.isSendingInvite = false;
      this.fullBorrower.accountStatus = BorrowerAccountStatus.Invited;
      this._notifsService.showSuccess(
        'Invite send succesfully.',
        'Notification Alert'
      );
    },
      (error) => {
        this.isSendingInvite = false;
        this._notifsService.showError(
          error && error.error ? error.error.message : 'Unable to send invite',
          'Error!'
        );
      });
  }

  getFullBorrower = (borrowerId: number) => {
    if (!borrowerId) {
      return;
    }
    combineLatest([
      this._configurationService.getCompanyConfiguration('EnableSmsAlertsByDefault'),
      this._borrowersService.getBorrower(borrowerId, true)
    ])
      .subscribe({
        next: ([enableSmsAlertsByDefault, borrower]) => {
          if (borrower.borrower == null) {
            const smsEnabledByDefault = enableSmsAlertsByDefault?.value === 1;
            this.borrowerId = 0;
            this.fullBorrower.borrower.alertViaSms = this.smsEnabledForCompany && smsEnabledByDefault;
          } else {
            this.currentPortalUserName = borrower.portalUserName;
            this.fullBorrower = borrower;
            this.borrowerId = borrower.borrower.borrowerId;
            this.borrowerLoaded.emit(borrower);
          }
          // TODO: We needed this lookup because of this inconsistency - read above.
          this.borrowerForPortalStatus = new BorrowerDto();
          this.borrowerForPortalStatus.borrowerId = this.fullBorrower.borrower.borrowerId;
          this.borrowerForPortalStatus.isRegister = this.fullBorrower.isRegister;
          this.borrowerForPortalStatus.isInvited = this.fullBorrower.isInvited;
          this.borrowerForPortalStatus.email = this.fullBorrower.borrower.email;
        },
        error: (err) => {
          this._notifsService.showError(err?.message || 'Unable to get Borrower', 'Error!');
        }
      });
  }

  cancel = () => {
    this.borrowerEditCancelled.emit(); //The emit on the left probably doesn't work, but I didn't delete it anyway..
    this.closeDrawer.emit();
  }

  saveBorrower = (closeAfterSave: boolean = false) => {
    this.trimBorrowerDetails();

    if (this.isMortgage) {
      this.savedBorrower.emit(this.fullBorrower);
    } else {
      if (!this.borrowerId) {
        this.isSaving = true;
        this._spinner.show();
        this._borrowersService.duplicateBorrower(this.fullBorrower).subscribe({
          next: (response) => {
            this._duplicateBorrowers = response;
            if (!this._duplicateBorrowers || this._duplicateBorrowers.length == 0) {
              this.upsertBorrower();
              return;
            }
            this._spinner.hide();
            let items = this._duplicateBorrowers.map(borrower => {
              let borrowersContact = this.users.find(u => u.userCompanyGuid == borrower.borrowerContact);
              return {
                id: borrower.borrowerId,
                firstName: borrower.firstName,
                lastName: borrower.lastName,
                email: borrower.email,
                dateInserted: borrower.dateInserted,
                borrowerContact: borrowersContact ? `${borrowersContact.firstName} ${borrowersContact.lastName}` : ""
              }
            }
            )
            const modalRef = this._modalService.open(DuplicateDialogComponent, Constants.modalOptions.medium);
            modalRef.componentInstance.items = _.orderBy(items, ['dateInserted'], ['asc']);
            modalRef.componentInstance.userType = "borrower";
            modalRef.result.then(borrId => {
              if (borrId > 0) {
                this.isSaving = false;
                this._spinner.hide();
                this.goToBorrowerDetails(borrId);
              }
              else { // add
                this.upsertBorrower();
              }
            })
          },
          error: (error: HttpErrorResponse) => {
            this.isSaving = false;
            this._spinner.hide();
          }
        })
      }
      else {
        this.upsertBorrower(closeAfterSave);
      }
    }
  }

  upsertBorrower = (closeAfterSave: boolean = false) => {
    this.isSaving = true;
    this._spinner.show();
    this.fullBorrower.borrower.companyId = this._companyId;
    this._borrowersService.upsertBorrower(this.fullBorrower).subscribe({
      next: (response) => {
        this.fullBorrower = response;

        if (this.borrowerId > 0) {
          this.currentPortalUserName = response.portalUserName;
          this._notifsService.showSuccess("Borrower updated!", "Successful!");
        } else {
          this._notifsService.showSuccess("New borrower added!", "Successful!");
        }
        this.isSaving = false;
        this._spinner.hide();
        this.savedBorrower.emit(this.fullBorrower);
      },
      error: (err) => {
        this.isSaving = false;
        this._spinner.hide();
        this._notifsService.showError(err.error ? err.error.message || err : '', "Error!");
      }
    })
  }

  portalUserNameChange = () => {
    if (this.fullBorrower.borrower.email == this.currentPortalUserName) {
      return;
    }
    const modalRef = this._modalService.open(ConfirmPortalInviteComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.title = "Confirm Portal User Name Change";
    modalRef.componentInstance.text = "Are you sure you want to change the user name for this borrower? \n\n Changing the borrower's user name will require that the borrower now login to the Portal using this new user name.";
    modalRef.result.then((result) => {
      if (result != 'cancel') {
        this.fullBorrower.portalUserName = this.fullBorrower.borrower.email;
      }
    });
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    if (zipCode) {
      this.fullBorrower.borrower[modelNamePrefix + "State"] = zipCode.state.toLowerCase();
      this.fullBorrower.borrower[modelNamePrefix + "City"] = _.startCase(_.toLower(zipCode.city)); // titleCase string
      this.fullBorrower.borrower[modelNamePrefix + "Zip"] = zipCode.zipcode;
    }
  }

  usersByRole = (roleId: number): User[] => {
    return this.users.filter(u => u.roleId == roleId);
  }

  goToBorrowerDetails = (borrowerId: number) => {
    this.borrowerId = borrowerId;
    this.ngOnInit();
  }

  save = (closeAfterSave: boolean = false) => {
    if (this.borrowerDetailsForm) {
      this.borrowerDetailsForm.form.markAllAsTouched();
      if (this.borrowerDetailsForm.form.valid) {
        this.saveBorrower(closeAfterSave);
      }
    }
  }

  dial = (phoneNumber: string, phoneType: PhoneType): void => {
    if (this.isManualDial) {
      let data = {
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: this.fullBorrower.borrower.firstName,
        lastName: this.fullBorrower.borrower.lastName,
        recordType: RecordType.Borrower,
        recordId: this.fullBorrower.borrower.borrowerId,
        applicationId: this.loanId
      } as ManualDialParams;
      this._dialerService.openCallControlPanel(undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, data)
    } else {
      this.dialClicked.emit({
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: this.fullBorrower.borrower.firstName,
        lastName: this.fullBorrower.borrower.lastName,
        recordType: RecordType.Borrower,
        recordId: this.fullBorrower.borrower.borrowerId,
        applicationId: this.loanId
      })
    }
  }

  onOpenSmsChat = (borrower: Borrower, phone: string) => {
    this._conversationService.openSmsChat({
      userPhone: phone,
      userName: `${borrower.firstName} ${borrower.lastName}`,
      openDrawer: true,
      openChat: true
    })
  }

  onEnableSmsToggled = (alertViaSms: boolean) => {
    this.fullBorrower.borrower.alertViaSms = alertViaSms;
  }

  protected handleAddressChange(e: Partial<Address>): void {
    const borrower = this.fullBorrower.borrower;
    borrower.mailingStreet = ''; // to reset the last populated address.

    setTimeout(() => {
      borrower.mailingStreet = e.address1;
      borrower.mailingCity = e.city;
      borrower.mailingState = e.state;
      borrower.mailingZip = e.zipCode;
    }, 200);
  }

  private trimBorrowerDetails = () => {
    if (!this.fullBorrower?.borrower) return;
    this.fullBorrower.borrower.firstName = this.fullBorrower.borrower.firstName?.trim();
    this.fullBorrower.borrower.lastName = this.fullBorrower.borrower.lastName?.trim();
    this.fullBorrower.borrower.email = this.fullBorrower.borrower.email?.trim();
  }
}
