import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ContactListType } from 'src/app/models';
import { CallControlPanelActionBarComponent } from 'src/app/modules/dialer/components/call-control-panel-action-bar/call-control-panel-action-bar.component';
import { DialListBasic } from 'src/app/modules/dialer/models/dial-list-basic.model';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';

@Component({
  selector: 'app-new-lead-received-dialog',
  templateUrl: './new-lead-received-dialog.component.html',
  styleUrls: ['./new-lead-received-dialog.component.scss']
})
export class NewLeadReceivedDialogComponent implements OnInit {

  @Input() leadModel: any;
  @Input() notifyOnly: boolean = false;
  @Input() isFreeForAllAccept: boolean = false;
  @Input() dialLists: DialListBasic[] = [];
  @Input() currentDialListId: number;

  @Output()
  closeDialog: EventEmitter<never> = new EventEmitter<never>(); // closing parent dialog

  isSaving: boolean = false;
  isModalOpen: boolean = false;
  dialListModel: number;

  @ViewChild(CallControlPanelActionBarComponent) callPanel: CallControlPanelActionBarComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _dialerService: DialerService,
    private readonly _notifyService: NotificationService,
    private readonly _drawerService: DrawerService,
  ) { }

  ngOnInit(): void {
    this.dialListModel = this.dialLists && this.dialLists.length > 0 ? this.dialLists[0].dialListId : null;
  }

  ngAfterViewInit(): void {
    if (this.callPanel) {
      this.isModalOpen = this.callPanel.isPanelOpened;
    }
  }

  acceptLead = () => {
    this.activeModal.close();
  }

  editLead = (): void => {
    this.activeModal.close();
    this.closeDialog.emit();

    this._drawerService.openDrawer({
      contactListType: ContactListType.Lead,
      recordId: this.leadModel.leadId,
    });
  }

  confirmDialList = (dialListId: number, dialNow: boolean) => {
    this._dialerService.addItemsToDialList(dialListId, [this.leadModel.leadId])
      .subscribe({
        next: () => {
          this._notifyService.showSuccess("Your information has been saved successfully.", "Success!")
          this.activeModal.dismiss();
          if (dialNow) {
            let selectedDialList = this.dialLists.find(l => l.dialListId == dialListId);
            this._dialerService.openCallControlPanel(selectedDialList, null, this.dialLists, undefined, undefined, true, undefined, undefined, undefined)
          }
        },
        error: (error) => {
          this._notifyService.showError(error?.message || `Couldn't add lead to dial list${dialNow ? " and dial now" : ""}.`, "Error");
        }
      })
  }

  addToDialListAndDial = (dialNow: boolean) => {
    let dialListId = this.currentDialListId;
    this._dialerService.addItemsToDialList(dialListId, [this.leadModel.leadId])
      .subscribe({
        next: () => {
          this._notifyService.showSuccess("Your information has been saved successfully.", "Success!");
          this._dialerService.publish({
            eventType: DialerEventType.dialerv2_acceptAndDialFreeForAllLead,
            data: {
              leadId: this.leadModel.leadId,
              dialListId: dialListId,
              dialNow: dialNow
            }
          })
          this.activeModal.dismiss();
        },
        error: (error) => {
          this._notifyService.showError(error?.message || `Couldn't add lead to dial list${dialNow ? " and dial now" : ""}.`, "Error");
        }
      })
  }
}
