import { Component } from '@angular/core';

@Component({
  selector: 'enable-message',
  template: `
    <h4 class="fw-light">
      <ng-content></ng-content>
    </h4>
  `
})
export class EnableMessageComponent {

}
