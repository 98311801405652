<div class="d-flex justify-content-between">
  <div class="col-md-4 col-sm-6 form-group">
    <label for="borrowerType{{index}}"> Borrower Type </label>
    <select class="form-select" name="borrowerType{{index}}" id="borrowerType{{index}}"
      [(ngModel)]="borrower.mortgagePartyType" (change)="onBorrowerTypeChanged()">
      <option value="{{type.value}}" *ngFor="let type of borrowerTypes">{{type.name}}
      </option>
    </select>
  </div>
  <div class="col-md-8 col-sm-6 form-group">
    <button type="button" class="btn btn-sm btn-outline-danger float-end" (click)="onDeleteBorrowerClicked()">
      <i class="fas fa-trash-alt me-1"></i> Delete Borrower
    </button>
  </div>
</div>
<ng-container *ngIf="borrower.mortgagePartyType === 'Entity'">
  <form #docPrepEntityForm="ngForm" id="docPrepEntityForm">
    <div class="row">
      <div class="col-md-8 form-group">
        <label for="entityName{{uniqueId}}"> Entity Name </label>
        <input type="text" class="form-control" id="entityName{{uniqueId}}" name="entityName{{uniqueId}}"
          [(ngModel)]="borrower.entityName">
      </div>
      <div class="col-md-4 form-group">
        <label for="entityType{{uniqueId}}"> Entity Type </label>
        <select id="entityType{{uniqueId}}" name="entityType{{uniqueId}}" class="form-select"
          [(ngModel)]="borrower.entityType">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let opt of entityTypes" [ngValue]="opt.value">
            {{ opt.name }}
          </option>
        </select>
      </div>
      <document-preparation-signer *ngFor="let signer of borrower.signers; let index = index" [signer]="signer"
        [index]="index" [sellerBorrowerType]="{source: 'borrower', type: 'Entity'}"
        (deleteSigner)="onDeleteSignerClicked($event)">
      </document-preparation-signer>
      <div class="row">
        <div class="form-group">
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddSignerClicked()">
            <i class="fas fa-plus me-1"></i> Add Signer
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-6 form-group">
        <label for="entityBorrowerAddress1"> Borrower Mailing Address </label>
        <address-autocomplete-input #address='ngModel' (addressChange)='handleAddressChange($event)'
          [(ngModel)]='mailingAddress.address.address1' id='entityBorrowerAddress1' name='entityBorrowerAddress1'
          placeholder='' [disabled]="occupancyType === 'PrimaryResidence'"></address-autocomplete-input>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <div class="row">
          <div class="form-group col-xs-6 col-sm-6 pe-0">
            <label for="city"> City </label>
            <input id="city" name="city" type="text" class="form-control custom-input-left-side"
              [(ngModel)]="mailingAddress.address.city" [disabled]="occupancyType === 'PrimaryResidence'"/>
          </div>
          <div class="form-group col-xs-6 col-sm-2 px-0">
            <label for="state">State</label>
            <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
              [(ngModel)]="mailingAddress.address.state" [disabled]="occupancyType === 'PrimaryResidence'">
              <option [ngValue]="null"></option>
              <option *ngFor="let state of states" [ngValue]="state.value">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-xs-6 col-sm-4 ps-0">
            <label for="zipCode">Zip Code</label>
            <zip-code-input class="custom-input-right-side" [(ngModel)]="mailingAddress.address.zipCode"
              [name]="'zipCode'" [required]="false" (selectionChanged)="onZipCodeRelatedInfoChanged($event)"
              [disabled]="occupancyType === 'PrimaryResidence'">
            </zip-code-input>
          </div>
        </div>
      </div>
    </div>
    
    <!-- alert -->
    <ng-container *ngIf="occupancyType === 'PrimaryResidence'" [ngTemplateOutlet]="primaryResidencyOccupancyAlert"></ng-container>

    <hr class="mt-0">
  </form>
</ng-container>
<ng-container *ngIf="borrower.mortgagePartyType === 'Individual'">
  <form #docPrepIndividualForm="ngForm" id="docPrepIndividualForm">
    <div class="row">
      <div class="col-md-2 form-group">
        <label for="firstName_{{uniqueId}}"> First </label>
        <input type="text" class="form-control" id="firstName_{{uniqueId}}" name="firstName_{{uniqueId}}"
          [(ngModel)]="borrower.firstName">
      </div>
      <div class="col-md-2 form-group">
        <label for="middleName_{{uniqueId}}"> Middle </label>
        <input type="text" class="form-control" id="middleName_{{uniqueId}}" name="middleName_{{uniqueId}}"
          [(ngModel)]="borrower.middleName">
      </div>
      <div class="col-md-2 form-group">
        <label for="lastName_{{uniqueId}}"> Last </label>
        <input type="text" class="form-control" id="lastName_{{uniqueId}}" name="lastName_{{uniqueId}}"
          [(ngModel)]="borrower.lastName">
      </div>
      <div class="col-md-2 form-group">
        <label for="maritalStatus_{{uniqueId}}"> Marital Status </label>
        <select class="form-select" name="maritalStatus_{{uniqueId}}" id="maritalStatus_{{uniqueId}}"
          [(ngModel)]="borrower.maritalStatus">
          <option [ngValue]="null">-- Select One --</option>
          <option value="{{ms.value}}" *ngFor="let ms of maritalStatuses">{{ms.name}}
          </option>
        </select>
      </div>
      <div class="col-md-2 form-group">
        <label for="suffix_{{uniqueId}}"> Suffix </label>
        <select id="suffix_{{uniqueId}}" name="suffix_{{uniqueId}}" class="form-select"
          [(ngModel)]="borrower.nameSuffix">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
            {{ opt.name }}
          </option>
        </select>
      </div>
      <div class="col-md-2 form-group">
        <label for="borrowerType_{{uniqueId}}"> Role </label>
        <select class="form-select" name="borrowerType_{{uniqueId}}" id="borrowerRole_{{uniqueId}}"
          [(ngModel)]="borrower.signingRole">
          <option [ngValue]="null">-- Select One --</option>
          <option value="{{role.value}}" *ngFor="let role of borrowerRoles">{{role.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 form-group special-break-point">
        <div class="form-check mb-0">
          <label for="poa_{{uniqueId}}" class="form-check-label"> POA {{borrower.powerOfAttorney ?
            'Name' :
            ''}} </label>
          <input id="poa_{{uniqueId}}" name="poa_{{uniqueId}}" class="form-check-input"
            [(ngModel)]="borrower.powerOfAttorney" (ngModelChange)="onPOAChanged($event, borrower)" type="checkbox" />
        </div>
        <input *ngIf="borrower.powerOfAttorney" type="text" class="form-control" id="poaName_{{uniqueId}}"
          name="poaName_{{uniqueId}}" [(ngModel)]="borrower.powerOfAttorneyName">
      </div>
      <div class="col-md-10 special-break-point">
        <div class="row">
          <div class="col-12 col-lg-5 form-group">
            <label for="borrowerAddress1"> Borrower Mailing Address </label>
            <address-autocomplete-input #address='ngModel' (addressChange)='handleAddressChange($event)'
              [(ngModel)]='mailingAddress.address.address1' id='borrowerAddress1' name='borrowerAddress1'
              placeholder='' [disabled]="occupancyType === 'PrimaryResidence'"></address-autocomplete-input>
          </div>
          <div class="col-12 col-lg-7 form-group">
            <div class="row">
              <div class="form-group col-xs-6 col-sm-6 pe-0">
                <label for="city"> City </label>
                <input id="city" name="city" type="text" class="form-control custom-input-left-side"
                  [(ngModel)]="mailingAddress.address.city" [disabled]="occupancyType === 'PrimaryResidence'"/>
              </div>
              <div class="form-group col-xs-6 col-sm-2 px-0">
                <label for="state">State</label>
                <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
                  [(ngModel)]="mailingAddress.address.state" [disabled]="occupancyType === 'PrimaryResidence'">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let state of states" [ngValue]="state.value">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-xs-6 col-sm-4 ps-0">
                <label for="zipCode">Zip Code</label>
                <zip-code-input class="custom-input-right-side" [(ngModel)]="mailingAddress.address.zipCode"
                  [name]="'zipCode'" [required]="false" [disabled]="occupancyType === 'PrimaryResidence'"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
                </zip-code-input>
              </div>
            </div>
          </div>
        </div>

        <!-- alert -->
        <ng-container *ngIf="occupancyType === 'PrimaryResidence'" [ngTemplateOutlet]="primaryResidencyOccupancyAlert"></ng-container>
      </div>
    </div>
    <hr class="mt-0">
  </form>
</ng-container>
<ng-container *ngIf="borrower.mortgagePartyType === 'Trust'">
  <form #docPrepTrustForm="ngForm" id="docPrepTrustForm">
    <div class="row">
      <div class="col-md-8 form-group">
        <label for="trustName{{uniqueId}}"> Trust Name </label>
        <input type="text" class="form-control" id="trustName{{uniqueId}}" name="trustName{{uniqueId}}"
          [(ngModel)]="borrower.trustName">
      </div>
      <div class="col-md-4 form-group">
        <label for="trustDate{{uniqueId}}"> Trust Date</label>
        <date-input name="trustDate{{uniqueId}}" [id]="'trustDate{{uniqueId}}'"
          [(ngModel)]="borrower.trustDate"></date-input>
      </div>
      <div class="col-md-12 form-group">
        <label> Trust Party</label>
      </div>
      <document-preparation-trust-party *ngFor="let trustParty of borrower.trustParties; let index = index"
        [suffixes]="suffixes" [index]="index" [trustParty]="trustParty"
        (deleteTrustParty)="onDeleteTrustPartyClicked($event, borrower)">
      </document-preparation-trust-party>
      <div class="row">
        <div class="form-group">
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddTrustPartyClicked()">
            <i class="fas fa-plus me-1"></i> Add Trust Party
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-6 form-group">
        <label for="trustBorrowerAddress1"> Borrower Mailing Address </label>
        <address-autocomplete-input #address='ngModel' (addressChange)='handleAddressChange($event)'
          [(ngModel)]='mailingAddress.address.address1' id='trustBorrowerAddress1' name='trustBorrowerAddress1'
          placeholder='' [disabled]="occupancyType === 'PrimaryResidence'"></address-autocomplete-input>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <div class="row">
          <div class="form-group col-xs-6 col-sm-6 pe-0">
            <label for="city"> City </label>
            <input id="city" name="city" type="text" class="form-control custom-input-left-side"
              [(ngModel)]="mailingAddress.address.city" [disabled]="occupancyType === 'PrimaryResidence'"/>
          </div>
          <div class="form-group col-xs-6 col-sm-2 px-0">
            <label for="state">State</label>
            <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
              [(ngModel)]="mailingAddress.address.state" [disabled]="occupancyType === 'PrimaryResidence'">
              <option [ngValue]="null"></option>
              <option *ngFor="let state of states" [ngValue]="state.value">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-xs-6 col-sm-4 ps-0">
            <label for="zipCode">Zip Code</label>
            <zip-code-input class="custom-input-right-side" [(ngModel)]="mailingAddress.address.zipCode"
              [name]="'zipCode'" [required]="false" [disabled]="occupancyType === 'PrimaryResidence'"
              (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
            </zip-code-input>
          </div>
        </div>
      </div>
    </div>
    
    <!-- alert -->
    <ng-container *ngIf="occupancyType === 'PrimaryResidence'" [ngTemplateOutlet]="primaryResidencyOccupancyAlert"></ng-container>

    <hr class="mt-0">
  </form>
</ng-container>

<ng-template #primaryResidencyOccupancyAlert>
  <div class="row mt-0 px-0 mx-0 alert alert-warning" style="margin-top: -1rem !important;">
    <div class="col-md-12">
      When 'Primary Residence' occupancy is selected the Subject Property will be used as the borrowers address
    </div>
  </div>
</ng-template>