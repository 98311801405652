import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LoanStatusAssociation } from '../../models';

@Injectable()
export class LoanStatusAssociationService {
  constructor(private readonly _dataService: DataService) {}

  getAllLoanStatusAssociations(
    id: number,
    companyId: number
  ): Observable<Array<LoanStatusAssociation>> {
    return this._dataService.get(
      `api/Admin/GetAllLoanStatusAssociationsByLoanPurpose/${id}?companyId=${companyId}`
    );
  }

  insertLoanStatusAssociation(
    data: LoanStatusAssociation
  ): Observable<LoanStatusAssociation> {
    return this._dataService.post(
      `api/Admin/InsertLoanStatusAssociation`,
      data
    );
  }

  updateLoanStatusAssociation(data: LoanStatusAssociation): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateLoanStatusAssociation`,
      data
    );
  }

  updateLoanStatusAssociationOrder(
    sortedIds: Array<{ id: number }>,
    loanPurposeId: number,
    companyId: number
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateLoanStatusFlowOrder/${loanPurposeId}?companyId=${companyId}`,
      sortedIds
    );
  }

  deleteLoanStatusAssociation(
    id: number,
    companyId: number
  ): Observable<undefined> {
    return this._dataService.delete(
      `api/Admin/DeleteLoanStatusAssociation/${id}?companyId=${companyId}`
    );
  }
}
