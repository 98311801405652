export class GatherTask {
    accountNumber: string = '';
    billingName: string = '';
    billingAddress: string = '';
    billingAddress2: string = '';
    billingCity: string = '';
    billingState: string = '';
    billingZip: string = '';
    billingPhoneNumber: string = '';
    billingEmail: string = '';
    taskBalance: number = 0;
    creditCardExpirationDate: string = '';
    paymentType: string = '';
    creditCardExpirationMonth?: number;
    creditCardExpirationYear?: number;
}