<div class="modal-header">
  <h6 class="modal-title">Please search for the loan from your LOS</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="row row border-bottom">
    <div class="col-md-3 form-group">
      <label for="losVendor">LOS Vendor</label>
      <select class="form-select" id="losVendor" name="losVendor" [(ngModel)]="losCredentialId"
        (change)="changeLosCredential(losCredentialId)">
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let item of losVendors" value="{{item.credentialId}}">
          {{item.losVendorName}}
        </option>
      </select>
    </div>
    <div class="col-md-2 form-group">
      <label for="losReference">LOS Reference</label>
      <input class="form-control" id="losReference" name="losReference" type="text" [(ngModel)]="losReference"
        [disabled]="!losCredentialId">
    </div>
    <div class="col-md-3 form-group">
      <label for="borrowerFirst">Borrower First</label>
      <input class="form-control" id="borrowerFirst" name="borrowerFirst" type="text" [(ngModel)]="borrowerFirst"
        [disabled]="!losCredentialId || selectedVendor.borrowerDisabled">
    </div>
    <div class="col-md-3 form-group">
      <label for="borrowerLast">Borrower Last</label>
      <input class="form-control" id="borrowerLast" name="borrowerLast" type="text" [(ngModel)]="borrowerLast"
        [disabled]="!losCredentialId || selectedVendor.borrowerDisabled">
    </div>
    <div class="col-md-1" style="margin-top: 28px;">
      <a *ngIf="!isSearching" id="search" name="search" (click)="search()" href="javascript: void(0);"
        [ngStyle]="{ 'pointer-events': losCredentialId && (losReference || borrowerFirst || borrowerLast) ? 'auto' : 'none' }">
        <i class="fa fa-lg fa-search"></i>
      </a>
      <i class="fa fa-lg fa-sync fa-spin" *ngIf="isSearching"></i>
    </div>
  </div>
  <div class="row scrollable-content">
    <div class="col-md-12">
      <h3 *ngIf="loanSearchList && !loanSearchList.length">
        There are no loans matching the search criteria.
      </h3>

      <div class="table-responsive">
        <table class="table mb-0 table-centered" *ngIf="loanSearchList">
          <thead>
          <tr>
            <td>Select</td>
            <td>Loan Number</td>
            <td>Borrower First</td>
            <td>Borrower Last</td>
            <td>Subject Property Address</td>
            <td>Subject Property CSZ</td>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let loan of loanSearchList">
            <td>
              <input type="radio" name="search" [(ngModel)]="selectedLoanId"
                value="{{loan.losIdentifier}}"
                (click)="setSelectedLoan(loan.loanNumber == undefined ? '' : loan.loanNumber, loan.losIdentifier)" />
            </td>
            <td>{{loan.loanNumber}}</td>
            <td>{{loan.borrowerFirstName}}</td>
            <td>{{loan.borrowerLastName}}</td>
            <td>{{loan.subjectPropertyAddress}}</td>
            <td>{{loan.subjectPropertyCity}}, {{loan.subjectPropertyState}} {{loan.subjectPropertyZip}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="link()"
          [disabled]="isLinking || !selectedLoan || !selectedLoan.losIdentifier.length">
          <span *ngIf="isLinking" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Link with LOS Loan
        </button>
      </div>
    </div>
  </div>
</div>
