import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { MentionMessageScope } from 'src/app/models/mention.model';
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { BorrowersService } from '../../../../modules/borrower/services/borrowers.service';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanService } from 'src/app/services/loan';
import { MentionsService } from 'src/app/services/mentions.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mention-item',
  templateUrl: 'mention-item.component.html',
  styleUrls: ['mention-item.component.scss']
})
export class MentionItemComponent implements OnInit, OnDestroy {

  @Input()
  public mention: MentionViewModel;

  @Output()
  mentionClicked: EventEmitter<MentionViewModel> = new EventEmitter<MentionViewModel>();

  isAdmin: boolean = false;
  borrowerLoading: boolean = false;

  userId: string;

  borrowerName: string;

  applicationMode: string;

  mentionTrackingUrl: string;

  private _applicationContextServiceSubscription: Subscription;

  constructor(
    private readonly _loanService: LoanService,
    private readonly _borrowerService: BorrowersService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _router: Router,
    private readonly _navigationService: NavigationService,
    private readonly _notifyService: NotificationService,
    private readonly _mentionsService: MentionsService,
    private readonly _environment: EnvironmentService,
  ) {
    this._applicationContextServiceSubscription = this._applicationContextService.context.subscribe(context => {
      this.isAdmin = context.userPermissions.admin;
      this.userId = context.userPermissions.userId;
    })
  }

  ngOnInit(): void {

    if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
      this.applicationMode = 'loda-nextgen'
    } else {
      this.applicationMode = 'admin'
    }

    // fetch borrower info via applicationId or borrowerId
    if (this.mention) {
      if (this.mention.applicationId) {

        this.borrowerLoading = true;
        this._loanService.getBorrowers(this.mention.applicationId)
          .subscribe({
            next: (borrowers: BorrowerDto[]) => {
              const borrower = borrowers.find(b => b.isPrimary);
              this.borrowerName = Utils.getPersonsFullName(borrower);

              this.borrowerLoading = false;
            },
            error: (err) => {
              this.borrowerLoading = false;
              this._notifyService.showError(err?.message || "Couldn't load borrowers.", "Error!");
            }
          })

      }
      else if (this.mention.borrowerId) {

        this.borrowerLoading = true;
        this._borrowerService.getBorrower(this.mention.borrowerId, this.isAdmin)
          .subscribe({
            next: (borrower: BorrowerFull) => {
              this.borrowerName = Utils.getPersonsFullName(borrower.borrower);

              this.borrowerLoading = false;
            },
            error: (err) => {
              this.borrowerLoading = false;
              this._notifyService.showError(err.message || err, "Error!");
            }
          })

      }
    }
  }

  ngOnDestroy(): void {
    this._applicationContextServiceSubscription?.unsubscribe();
  }

  onMentionClicked = (e: any) => {
    if (e.target.id === 'appInMention') {
      const url = this._router.serializeUrl(this._router.createUrlTree(['/' + this.applicationMode + '/app-details/' + this.mention.applicationId]));
      window.open(url, '_blank');
      return;
    }
    else {
      this.mentionClicked.emit(this.mention);
    }
  }

  markAsRead = () => {
    if (this.mention.openDate) return;

    this.mentionTrackingUrl = `${this._environment.apiInfo.apiBaseUrl}mentions/pixel/${this.userId}/${this.mention.trackingGuid}`;
    this._mentionsService.publish({ type: "reload" })
  }
}

export class MentionViewModel {
  fromFullName: string;
  fromAvatarUrl: string;
  content: string;
  timeAgo: string;
  messageId: number;
  entityId: number;
  messageScope: MentionMessageScope;
  trackingGuid: string;
  openDate: string;
  applicationId: number;
  borrowerId: number;
}
