import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessSubmissionModel } from '../../models/process-submission.model';

@Component({
  selector: 'submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})
export class SubmissionComponent implements OnInit {

  @Input()
  processSubmissionData: ProcessSubmissionModel;

  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  previousStep: EventEmitter<any> = new EventEmitter<any>();

  submitFileClicked: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  back = () => {
    this.previousStep.emit();
  }

  submitFile = () => {
    this.submitFileClicked = true;
    this.submit.emit(this.processSubmissionData);
  }
}
