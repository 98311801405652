import {Injectable, OnDestroy} from '@angular/core';
import {FeeContextService} from './fee-context.service';
import {BehaviorSubject, combineLatest, map, Observable} from 'rxjs';
import {LoanFee} from '../fees.model';
import {FeeUtils} from '../utils/utils';
import {FeeSection, FeeSectionViewType} from '../fee-section.model';
import {uniqWith} from 'lodash';
import areFeeReferencesEqual = FeeUtils.areFeeReferencesEqual;

/**
 * This class provides an extra layer of abstraction to control the visibility of fees in the UI.
 */
@Injectable()
export class FeeDisplayService implements OnDestroy {
  private readonly _hiddenFeesSubject = new BehaviorSubject<readonly LoanFee[]>([]);

  private get _hiddenFees(): readonly LoanFee[] {
    return this._hiddenFeesSubject.value;
  }

  constructor(private readonly _feeContextService: FeeContextService) {}

  ngOnDestroy() {
    this._hiddenFeesSubject.complete();
  }

  /**
   * Hides the given fees.
   * @param {readonly LoanFee[]} fees - The fees to be hidden.
   * @remarks Unknown fees will be ignored if any.
   */
  public hideFees(fees: readonly LoanFee[]): void {
    // TODO: We may want to optimize this in the future
    const hiddenFees = uniqWith(this._hiddenFees.concat(fees), areFeeReferencesEqual);
    this._hiddenFeesSubject.next(hiddenFees);
  }

  /**
   * Shows all fees.
   * @remarks Does nothing if all fees are already shown or there are no fees.
   */
  public showAllFees(): void {
    this._hiddenFeesSubject.next([]);
  }

  private filterDisplayedFees(
    fees: readonly LoanFee[],
    hiddenFees: readonly LoanFee[]
  ): readonly LoanFee[] {
    if (hiddenFees.length === 0) {
      return fees;
    }

    // TODO: We may want to optimize this in the future
    return fees.filter(fee => !hiddenFees.some(f => areFeeReferencesEqual(fee, f)));
  }

  public getFeeSectionChanges$(
    type: FeeSectionViewType,
    compareFn?: (a: FeeSection, b: FeeSection) => boolean
  ): Observable<FeeSection | undefined> {
    return combineLatest([
      this._feeContextService.getFeeSectionChanges$(type, compareFn),
      this._hiddenFeesSubject,
    ]).pipe(
      map(([section, hiddenFees]) => ({
        ...section,
        fees: this.filterDisplayedFees(section.fees, hiddenFees),
      }))
    );
  }
}
