<div class="card-body d-flex" *ngIf="processSubmissionData" style="min-height:400px;">
    <div class="row justify-content-center w-100 my-auto">
        <div class="col-md-6 text-center">
            <p *ngIf="processSubmissionData.submissionViaFile" class="card-subtitle font-18 mb-2">Please click register to import this file</p>
            <span class="alert alert-block alert-info" style="display:inline-block" *ngIf="submitFileClicked">
                <i class="fa fa-exclamation-circle"></i>
                <span style="font-size:large"> Please note this may take up to 30 seconds to
                    process. Please be patient and do not click refresh.</span>
            </span>
        </div>
    </div>
</div>
<div class="text-center" *ngIf="processSubmissionData">
    <button class="btn btn-primary me-2" (click)="back()">Back</button>
    <button id="new-app-submit-btn" class="btn btn-primary" (click)="submitFile()">{{processSubmissionData.submissionViaFile ? 'Register File' : 'Save New Application'}}</button>
</div>