<div class="modal-header">
    <h5 class="modal-title">Link Documents</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div *ngFor="let docType of globalConfig.documentType" id="accordion_modal">
        <div class="accordion-item" *ngIf="showDocumentType(docType) && docType.documentTypeId > -1">
            <h5 class="accordion-header m-0" id="heading_modal{{docType.documentTypeId}}">
                <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse_modal'+docType.documentTypeId" aria-expanded="true"
                    [attr.aria-controls]="'#collapse_modal'+docType.documentTypeId">
                    {{docType.documentTypeName}}
                </button>
            </h5>
            <div id="collapse_modal{{docType.documentTypeId}}" class="accordion-collapse collapse"
                aria-labelledby="heading_modal{{docType.documentTypeId}}" data-bs-parent="#accordion_modal">
                <div class="accordion-body">
                    <p-table #dt [value]="loanDocs" *ngIf="loanDocs"
                        styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    Description
                                </th>
                                <th>
                                    View File
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-loanDoc>
                            <tr *ngIf="loanDoc.documentTypeId == docType.documentTypeId">
                                <td> {{loanDoc.description}}
                                </td>
                                <td>
                                    <table class='tbl_File view' style="width:100%">
                                        <tr *ngFor="let file of loanDoc.docFiles">
                                            <td>
                                                <input name="checkbox" type="checkbox" value="file.guid" [checked]="isDocumentLinked(file)" (click)="linkDocument(file)">
                                                {{file.fileName}}
                                                <a class="file-action" title="View" (click)="viewFile(file)">
                                                    <i class='fa fa-eye'></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr *ngIf="loanDoc.docFiles.length === 0">
                                            <td>No files available</td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2" class="text-center"> No document found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-link" style="text-decoration: none;" (click)="activeModal.close('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close(this.task)">Select ({{task.docFiles.length}}) Doc{{task.docFiles.length > 1 ? '(s)' : ''}}</button>
</div>