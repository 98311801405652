<div class="modal-header">
  <h5 class="modal-title">Lead Interval Config</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <form #intervalConfigForm="ngForm">
    <div class="mb-3 row">
      <label for="config" class="col-md-5 form-label align-self-center mb-lg-0">
        Auto Refresh Interval (mins)
      </label>
      <div class="col-md-7">
        <p-inputNumber styleClass="loda-input" inputStyleClass="form-control col-md-6" name="intervalTime" min="0"
          [(ngModel)]="config.intervalTime"
          #intervalTime="ngModel"
          [ngClass]="{'is-invalid' : intervalTime && intervalTime.touched && intervalTime.invalid}"
          mode="decimal" [maxFractionDigits]="0" [minFractionDigits]="0">
        </p-inputNumber>
        <div class="invalid-feedback">Please enter an auto-refresh interval.</div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>

  <button
    type="submit"
    class="btn btn-primary"
    (click)="save()"
  >
    Save
  </button>
</div>
