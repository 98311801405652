import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LoanFicoSummaryComponent } from "./components/loan-fico-summary/loan-fico-summary.component";
import { MultiBorrowerFicoInfoComponent } from "./components/multi-borrower-fico-info/multi-borrower-fico-info.component";
import { TableModule } from "primeng/table";

@NgModule({
  imports: [
    SharedModule,
    TableModule
  ],
  declarations: [
    LoanFicoSummaryComponent,
    MultiBorrowerFicoInfoComponent
  ],
  exports: [
    LoanFicoSummaryComponent
  ],
  providers: [
  ]
})
export class LoanFicoModule {
  constructor() {
  }
}
