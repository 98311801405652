import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChecklistsChecklistComponent } from './components/checklists-checklist/checklists-checklist.component';
import { ChecklistsQuestionsComponent } from './components/checklists-questions/checklists-questions.component';

const routes: Routes = [
    {
      path: 'questions',
      data: {
        breadcrumb: 'Questions',
      },
      component: ChecklistsQuestionsComponent
    },
    {
      path: 'check-list-items',
      data: {
        breadcrumb: 'Checklist Items',
      },
      component: ChecklistsChecklistComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChecklistsRoutingModule { }
