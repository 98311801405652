import { NgModule } from '@angular/core';
import { LoanActivityComponent } from './loan-activity.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoanActivityCardComponent } from './loan-activity-card/loan-activity-card.component';
import { EmailSmsPreviewDialogComponent } from './email-sms-preview-dialog/email-sms-preview-dialog.component';

@NgModule({
    imports: [SharedModule],
    declarations: [
        LoanActivityComponent,
        LoanActivityCardComponent,
        EmailSmsPreviewDialogComponent
    ],
    exports: [
        LoanActivityComponent,
        LoanActivityCardComponent,
        EmailSmsPreviewDialogComponent
    ],
    providers: []
})
export class LoanActivityModule { }
