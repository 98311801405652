<form #form='ngForm' *ngLet='disabled as disabled'>
  <!-- Header -->
  <div class='component-header'>
    <h5>{{ effectiveFee.name }}</h5>

    <!-- Close button -->
    <button
      (click)='onClickClose()'
      [disabled]='isSaving'
      aria-label='Close'
      class='btn btn-icon close-icon'
      title='Close'
      type='button'
    >
      <i class='fas fa-times'></i>
    </button>
  </div>

  <!-- Body -->
  <div
    [class.no-percent-column]='isPercentOfDisabled'
    class='table'
  >
    <div class='table__spacer'></div>

    <!-- Fee Percent of -->
    <div class='table__percent-of'>
      <ng-container *ngLet="id('feePercentOf') as id">
        <label [for]='id'>Fee Percent of</label>

        <div class='fee-percent-of-input'>
          <select
            (ngModelChange)='setFeePercentOf($event)'
            [disabled]='disabled'
            [id]='id'
            [ngModel]='effectiveFee.fee.feePercentOf'
            class='form-control'
            name='feePercentOf'
          >
            <option [ngValue]='undefined'>None</option>

            <option
              *ngFor='let feePercentOf of feePercentOfOptions'
              [ngValue]='feePercentOf.value'
            >
              {{ feePercentOf.name }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf='!isPercentOfDisabled'>
        <ng-container *ngLet="id('percentPlusDollarAmount') as id">
          <label [for]='id'>Amount</label>

          <div class='fee-percent-plus-dollar-amount-input'>
            <div class='p-0'>
              <currency-input
                (change)='updateFees()'
                (ngModelChange)='setPercentPlusDollarAmount($event)'
                [allowNegative]='false'
                [attr.title]="
                  isPercentPlusDollarAmountDisabled && !isPercentPlusDollarAmountInvalid
                    ? 'Split fees cannot have additional dollar amounts'
                    : undefined
                "
                [id]='id'
                [isInvalid]='isPercentPlusDollarAmountInvalid'
                [ngModel]='effectiveFee.fee.percentPlusDollarAmount'
                [placeholder]='0 | currency'
                [readonly]='disabled || isPercentPlusDollarAmountDisabled'
                class='p-0'
                name='percentPlusDollarAmount'
              ></currency-input>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class='table__percent-of-error' *ngIf='percentPlusDollarAmountError as error'>
      <div class='percent-of-error-message'>
        {{ error }}
      </div>
    </div>

    <div class='table__spacer'></div>

    <div class='table__header'>
      <div class='paid-by-column'>
        Paid By
      </div>

      <div class='at-closing-column'>
        At Closing
      </div>

      <div class='percent-column'>
        Percent
      </div>

      <div class='before-closing-column'>
        Before Closing
      </div>

      <div class='amount-paid-column'>
        Amount Paid
      </div>
    </div>

    <div class='table__spacer'></div>

    <div
      *ngFor='let payer of effectiveFee.payers; let i = index'
      class='table__row'
    >
      <div class='paid-by-column'>
        {{ payer.name }}
      </div>

      <div class='at-closing-column'>
        <div class='p-0'>
          <currency-input
            (change)='updateFees()'
            [(ngModel)]='payer.atClosing.dollar'
            [allowNegative]='false'
            [id]='id("atClosing-" + i)'
            [readonly]='disabled'
            class='p-0'
            name='atClosing{{i}}'
          ></currency-input>
        </div>
      </div>

      <div class='percent-column'>
        <div class='p-0'>
          <ng-container *ngLet='{value: 0} as inputState'>
            <percent-input
              (blur)='inputState.value !== payer.atClosing.percent && updateFees()'
              (focus)='inputState.value = payer.atClosing.percent'
              [(ngModel)]='payer.atClosing.percent'
              [id]='id("percent-" + i)'
              [readonly]='disabled'
              class='p-0'
              name='percent{{i}}'
            ></percent-input>
          </ng-container>
        </div>
      </div>

      <div class='before-closing-column'>
        <div class='p-0'>
          <currency-input
            (change)='updateFees()'
            [(ngModel)]='payer.beforeClosing.dollar'
            [allowNegative]='false'
            [id]='id("beforeClosing-" + i)'
            [placeholder]='0 | currency'
            [readonly]='disabled'
            class='p-0'
            name='beforeClosing{{i}}'
          ></currency-input>
        </div>
      </div>

      <div class='amount-paid-column'>
        <div class='p-0'>
          <currency-input
            [id]='id("amountPaid-" + i)'
            [ngModelOptions]='{ standalone: true }'
            [ngModel]='payer.total.calculatedDollar'
            [placeholder]='0 | currency'
            [readonly]='true'
            class='p-0'
          ></currency-input>
        </div>
      </div>
    </div>

    <div class='table__divider'>
      <div class='divider'></div>
    </div>

    <div class='table__footer'>
      <div class='paid-by-column'>
        Total Amount
      </div>

      <div class='at-closing-column'></div>
      <div class='percent-column'></div>
      <div class='before-closing-column'></div>

      <div class='amount-paid-column'>
        <div class='p-0'>
          <currency-input
            [id]='id("total")'
            [ngModelOptions]='{ standalone: true }'
            [ngModel]='effectiveFee.total'
            [placeholder]='0 | currency'
            [readonly]='true'
            class='p-0'
          ></currency-input>
        </div>
      </div>
    </div>

    <div class='table__spacer'></div>
  </div>

  <!-- Footer -->
  <div
    [class.justify-content-end]='!editable'
    class='component-footer'
  >
    <ng-container *ngIf='editable; else nonEditable'>
      <!-- Reset button -->
      <button
        (click)='onClickReset()'
        [disabled]='disabled'
        aria-label='Reset'
        class='btn btn-outline-light'
        title='Reset'
        type='button'
      >
        Reset
      </button>

      <div class='d-flex gap-component'>
        <!-- Cancel button -->
        <button
          (click)='onClickCancel()'
          [disabled]='isSaving'
          aria-label='Cancel'
          class='btn btn-outline-light'
          title='Cancel'
          type='button'
        >
          Cancel
        </button>

        <!-- Save button -->
        <button
          (click)='onClickSave()'
          [disabled]='isSaving'
          aria-label='Save'
          class='btn btn-primary'
          title='Save'
          type='button'
        >
          Save
        </button>
      </div>
    </ng-container>

    <ng-template #nonEditable>
      <button
        (click)='onClickClose()'
        [disabled]='isSaving'
        aria-label='Close'
        class='btn btn-primary'
        title='Close'
        type='button'
      >
        Close
      </button>
    </ng-template>
  </div>
</form>
