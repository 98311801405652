import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { assign, cloneDeep, differenceWith, isArray, isEmpty, isEqual, max, pick } from 'lodash';
import { finalize, Subscription } from 'rxjs';
import { ApplicationContext, LoanDoc, MortgageBorrower } from 'src/app/models';
import { AppraisalFormType } from 'src/app/models/appraisal/appraisal-form-type.model';
import { AppraisalOrderView } from 'src/app/models/appraisal/appraisal-order-view.model';
import { AppraisalThirdPartyContact } from 'src/app/models/appraisal/appraisal-third-party-contact.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LoanDocsByCategory } from 'src/app/modules/correspondence/components/add-attachment-dialog/add-attachment-dialog.component';
import { ExternalContactsService } from 'src/app/modules/external-contacts/services/external-contacts.service';
import { AppraisalService } from 'src/app/services/appraisal.service';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { NgForm } from '@angular/forms';
import { AppraisalDocument } from 'src/app/models/appraisal/appraisal-document.model';
import { AppraisalNote } from 'src/app/models/appraisal/appraisal-note.model';

@Component({
  selector: 'appraisal-tab',
  templateUrl: './appraisal-tab.component.html',
  styleUrls: ['./appraisal-tab.component.scss']
})
export class AppraisalTabComponent extends ApplicationContextBoundComponent implements OnInit, OnChanges {
  @ViewChild('appraisalsForm') appraisalsForm: NgForm | undefined;

  @Input() appId: number;
  @Input() appraisalFormTypes: Array<AppraisalFormType>;
  @Input() noteDeliveryGroups: Array<EnumerationItem>;
  @Input() appraisalDocTypes: Array<EnumerationItem>;
  @Input() conditionCategories: Array<EnumerationItem>;
  @Input() loanDocs: Array<LoanDoc>;
  @Input() loanDocsByCategory: Array<LoanDocsByCategory>;
  @Input() recentOrder: AppraisalOrderView;
  @Input() createNewTabSelected: boolean;

  @Output() createOrder: EventEmitter<AppraisalOrderView> = new EventEmitter<AppraisalOrderView>();
  @Output() updateOrder: EventEmitter<AppraisalOrderView> = new EventEmitter<AppraisalOrderView>();

  borrowersAndExternalContacts = [];
  hasExternalContacts: boolean;
  hasBorrowers: boolean;
  appraisalRequest: Partial<AppraisalOrderView>;
  cancelingAppraisalOrder: boolean;
  refreshigOrder: boolean;
  orderHoldStateChanging: boolean;
  submitting: boolean;
  errorMsg: string;
  isActiveOrder: boolean = false;
  validThirdPartyContactFields = [
    'email',
    'firstName',
    'lastName',
    'homePhoneNumber',
    'otherPhoneNumber',
    'role'
  ];

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _appraisalService: AppraisalService,
    private readonly _notificationService: NotificationService,
    private readonly _externalContactsService: ExternalContactsService,
    private readonly _loanDocsService: LoanDocsService,
    public datePipe: DatePipe) {
    super(injector);
  }

  ngOnInit(): void {
    this.getExternalContacts();
    this.initializeAppraisalRequest();
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.createNewTabSelected?.firstChange ||
      changes.recentOrder?.firstChange) {
      return;
    }
    if (changes.createNewTabSelected?.currentValue !== changes.createNewTabSelected?.previousValue ||
      (changes.recentOrder && changes.recentOrder.currentValue?.appraisalOrderId !== changes.recentOrder.previousValue?.appraisalOrderId)) {
      this.initializeAppraisalRequest();
    }
  }

  private initialize(applicationContext: ApplicationContext) {
    this.loanDocsByCategory = this._loanDocsService.generateLoanDocsByCategory(
      this.loanDocs,
      applicationContext.globalConfig.documentType,
      applicationContext.userPermissions,
      applicationContext.isTpo
    );
    this.populateBorrowerAsExternalContacts(applicationContext.currentMortgage.borrowers);
    const mortgageAppliedFor = applicationContext.currentMortgage?.mortgageTerm?.mortgageAppliedFor;
    this.filterAppraisalFormTypes(mortgageAppliedFor);
  }

  cancelAppraisalOrder(appraisalOrderId: number): void {
    this.cancelingAppraisalOrder = true;
    this._appraisalService.cancelAppraisalOrder(appraisalOrderId)
      .pipe(finalize(() => this.cancelingAppraisalOrder = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess('Appraisal Order cancelled.', 'Cancel Appraisal Order');
          this.updateAppraisalObject(res);
          this.updateOrder.emit(res);
          this.showHideErrorContainer(false);
        },
        error: err => {
          this.showHideErrorContainer(true, err?.message);
          this._notificationService.showError((err && err.error.message) ? err.error.message : 'Failed to cancel the order.', 'Cancel Appraisal Order');
        }
      });
  }

  placeAppraisalOrderOnHold(appraisalOrderId: number): void {
    this.orderHoldStateChanging = true;
    this._appraisalService.placeAppraisalOrderOnHold(appraisalOrderId)
      .pipe(finalize(() => this.orderHoldStateChanging = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess('Appraisal Order put on hold.', 'Hold Appraisal Order');
          this.updateAppraisalObject(res);
          this.updateOrder.emit(res);
          this.showHideErrorContainer(false);
        },
        error: err => {
          this.showHideErrorContainer(true, err?.message);
          this._notificationService.showError((err && err.error.message) ? err.error.message : 'Failed to put the order on hold.', 'Hold Appraisal Order');
        }
      });
  }

  refreshAppraisalOrder(appraisalOrderId: number): void {
    this.refreshigOrder = true;
    this._appraisalService.refreshAppraisalOrder(appraisalOrderId)
      .pipe(finalize(() => this.refreshigOrder = false))
      .subscribe({
        next: res => {
          this.updateAppraisalObject(res);
          this.updateOrder.emit(res);
          this.showHideErrorContainer(false);
        },
        error: err => {
          this.showHideErrorContainer(true, err?.message);
          this._notificationService.showError((err && err.error.message) ? err.error.message : 'Failed to refresh the order.', 'Refresh Appraisal Order');
        }
      });
  }

  resumeAppraisalOrderFromHold(appraisalOrderId: number): void {
    this.orderHoldStateChanging = true;
    this._appraisalService.resumeAppraisalOrderFromHold(appraisalOrderId)
      .pipe(finalize(() => this.orderHoldStateChanging = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess('Appraisal Order resumed.', 'Resume Appraisal Order');
          this.updateAppraisalObject(res);
          this.updateOrder.emit(res);
          this.showHideErrorContainer(false);
        },
        error: err => {
          this.showHideErrorContainer(true, err?.message);
          this._notificationService.showError((err && err.error.message) ? err.error.message : 'Failed to resume the order.', 'Resume Appraisal Order');
        }
      });
  }

  private updateAppraisalObject(updatedAppraisal: AppraisalOrderView) {
    assign(this.recentOrder, updatedAppraisal);
    this.initializeAppraisalRequest();
  }

  private initializeAppraisalRequest() {
    if (!this.recentOrder) {
      this.appraisalRequest = {
        applicationId: this.appId,
        appraisalForms: [],
        documents: [],
        isRushOrder: false,
        targetDueDate: '',
        amcOrAppraiserIdToAssign: null,
        notes: [],
        thirdPartyContact: this.getEmptyThirdPartyContact(),
      }
    } else {
      this.appraisalRequest = {
        applicationId: this.appId,
        amcOrAppraiserIdToAssign: null,
        appraisalForms: this.recentOrder.appraisalForms ? this.recentOrder.appraisalForms : [],
        documents: (!this.createNewTabSelected && this.recentOrder.documents) ? this.recentOrder.documents.map(doc => ({
          docFileGuid: doc.docFileGuid,
          fileName: doc.fileName,
          docFileId: this.getLoanDocFileId(doc.docFileGuid),
          isPdf: doc.fileName.toLowerCase().endsWith(".pdf"),
          creationDate: this.getLoanDocCreationDate(doc.docFileGuid),
          appraisalDocumentType: doc.appraisalDocumentType
        })) : [],
        isRushOrder: this.recentOrder.isRushOrder,
        targetDueDate: this.recentOrder.targetDueDate,
        notes: (!this.createNewTabSelected && this.recentOrder.notes)
          ? this.recentOrder.notes.map(note => ({
            note: note.note,
            deliveryGroups: note.deliveryGroups,
            thirdPartyNoteId: note.thirdPartyNoteId,
          }))
          : [],
        thirdPartyContact: this.recentOrder.thirdPartyContact
          ? cloneDeep(this.recentOrder.thirdPartyContact)
          : this.getEmptyThirdPartyContact()
      }
    }
    this.isActiveOrder = !['Completed', 'Cancelled'].includes(this.recentOrder?.orderStatus);
  }

  private showHideErrorContainer(show: boolean, msg: string = ''): void {
    if (show) {
      this.errorMsg = msg;
      return;
    }
    this.errorMsg = '';
  }

  getExternalContacts() {
    this._externalContactsService.getExternalContacts(this.appId)
      .subscribe(externalContacts => {
        this.hasExternalContacts = externalContacts.length > 0;
        let tempId = max(this.borrowersAndExternalContacts.map(contact => contact.id)) || 0;
        externalContacts.forEach(externalContact => {
          this.borrowersAndExternalContacts.push({
            id: ++tempId,
            email: externalContact.email,
            firstName: externalContact.firstName,
            lastName: externalContact.lastName,
            homePhoneNumber: externalContact.mobilePhone,
            type: 'ExternalContact'
          });
        });
      });
  }

  populateContactInfo(selectedContactId: number) {
    if (!selectedContactId) {
      return;
    }
    const matchingContact = this.borrowersAndExternalContacts
      .find(c => c.id == selectedContactId);
    if (!matchingContact) {
      return;
    }
    this.appraisalRequest.thirdPartyContact = {
      email: matchingContact.email || '',
      firstName: matchingContact.firstName || '',
      lastName: matchingContact.lastName || '',
      homePhoneNumber: matchingContact.homePhoneNumber || '',
      otherPhoneNumber: matchingContact.otherPhoneNumber || '',
      role: ''
    };
  }

  private populateBorrowerAsExternalContacts(borrowers: Array<MortgageBorrower>) {
    this.borrowersAndExternalContacts = [];
    if (!isArray(borrowers)) {
      return;
    }
    this.hasBorrowers = borrowers.length > 0;
    borrowers.forEach((borrower, index) => {
      this.borrowersAndExternalContacts.push({
        id: ++index,
        email: borrower.primaryEmail,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        homePhoneNumber: borrower.homePhone,
        otherPhoneNumber: borrower.mobilePhone,
        type: 'Borrower'
      });
    });
  }

  private getEmptyThirdPartyContact(): AppraisalThirdPartyContact {
    return {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      homePhoneNumber: '',
      otherPhoneNumber: ''
    }
  }

  private getLoanDocFileId(loanDocFileGuid: string): number | null {
    if (!isArray(this.loanDocs)) {
      return;
    }
    const matchingFile = this.loanDocs.flatMap(doc => doc.docFiles).find(file => file.guid == loanDocFileGuid);
    return matchingFile ? matchingFile.docFileId : null;
  }

  private getLoanDocCreationDate(loanDocFileGuid: string): string | null {
    if (!isArray(this.loanDocs)) {
      return;
    }
    const matchingFile = this.loanDocs.flatMap(doc => doc.docFiles).find(file => file.guid == loanDocFileGuid);
    return matchingFile ? this.datePipe.transform(matchingFile.dateInserted, 'short') : null;

  }

  private filterAppraisalFormTypes(mortgageAppliedFor: string): void {
    this.appraisalFormTypes = this.appraisalFormTypes
      .filter(ft =>
        !ft.mortgageType ||
        !mortgageAppliedFor ||
        ft.mortgageType === mortgageAppliedFor
      );
  }

  submitAppraisal() {
    if (this.createNewTabSelected) {
      this.orderAppraisal();
      return;
    }
    this.updateAppraisal();
  }

  private orderAppraisal() {
    const req = this.setThirdPartyContactObj();
    this.appraisalsForm.form.markAllAsTouched();
    if (!this.appraisalsForm.form.valid) {
      return;
    }
    this.submitting = true;
    this._appraisalService.createAppraisalOrder(req)
      .pipe(finalize(() => this.submitting = false))
      .subscribe({
        next: (res) => {
          this.recentOrder = res;
          this.createOrder.emit(res);
          this.showHideErrorContainer(false);
          this._notificationService.showSuccess('Appraisal Order created Successfully', 'Success');
        }, error: (err) => {
          this.showHideErrorContainer(true, err?.message);
          this._notificationService.showError(err.error ? err.error.message : "Couldn't created appraisal order", 'Error');
        }
      });
  }

  private updateAppraisal() {
    this.appraisalsForm.form.markAllAsTouched();
    if (!this.appraisalsForm.form.valid) {
      return;
    }
    const req = this.setThirdPartyContactObj();
    this.prepareUpdateRequest(req);

    this.submitting = true;
    this._appraisalService.updateAppraisalOrder(this.recentOrder.appraisalOrderId, req)
      .pipe(finalize(() => this.submitting = false))
      .subscribe({
        next: (res) => {
          this.updateAppraisalObject(res);
          this.updateOrder.emit(res);
          this.showHideErrorContainer(false);
          this._notificationService.showSuccess('Appraisal Order updated Successfully', 'Success');
        },
        error: (err) => {
          this.showHideErrorContainer(true, err?.message);
          this._notificationService.showError(err.error ? err.error.message : "Couldn't updated appraisal order", 'Error');
        }
      });
  }

  private prepareUpdateRequest(currentRequest: Partial<AppraisalOrderView>): void {
    let hasChanges = false;
    if (this.recentOrder.isRushOrder == currentRequest.isRushOrder) {
      currentRequest.isRushOrder = null;
    } else {
      hasChanges = true;
    }

    if (this.recentOrder.targetDueDate == currentRequest.targetDueDate) {
      currentRequest.targetDueDate = null;
    } else if (!hasChanges) {
      hasChanges = true;
    }

    if (this.areEqual(this.recentOrder.appraisalForms, currentRequest.appraisalForms)) {
      currentRequest.appraisalForms = null;
    } else if (!hasChanges) {
      hasChanges = true;
    }

    const existingDocs = this.recentOrder.documents
      ? this.recentOrder.documents.map(doc => ({
        docFileGuid: doc.docFileGuid,
        appraisalDocumentType: doc.appraisalDocumentType
      }))
      : [];
    const currentDocs = currentRequest.documents.map(doc => ({
      docFileGuid: doc.docFileGuid,
      appraisalDocumentType: doc.appraisalDocumentType
    }));
    if (this.areEqual(existingDocs, currentDocs)) {
      currentRequest.documents = null;
    } else if (!hasChanges) {
      hasChanges = true;
    }

    const recentNotes = this.recentOrder.notes
      ? this.recentOrder.notes.map(note => ({
        note: note.note,
        deliveryGroups: note.deliveryGroups
      }))
      : [];
    const currentNotes = currentRequest.notes.map(note => ({
      note: note.note,
      deliveryGroups: note.deliveryGroups
    }));
    if (this.areEqual(recentNotes, currentNotes)) {
      currentRequest.notes = null;
    } else if (!hasChanges) {
      hasChanges = true;
    }
    if (isEqual(
      pick(this.recentOrder.thirdPartyContact, this.validThirdPartyContactFields),
      pick(currentRequest.thirdPartyContact, this.validThirdPartyContactFields)
    )) {
      currentRequest.thirdPartyContact = null;
    } else if (!hasChanges) {
      hasChanges = true;
    }
  }

  private areEqual(source1: any[], source2: any[]) {
    // differenceWith only compares source1 with Source2. so if there are elements in source2 that
    // are missing in source 1 then it will skip.
    const oneWay = differenceWith(source1, source2, isEqual);
    const otherWay = differenceWith(source2, source1, isEqual);
    return isEmpty(oneWay) && isEmpty(otherWay);
  }

  private setThirdPartyContactObj() {
    if (this.isThirdPartyContactAllFalsy()) {
      const newRequest = cloneDeep(this.appraisalRequest);
      newRequest.thirdPartyContact = null;
      return newRequest;
    }
    return cloneDeep(this.appraisalRequest);
  }

  isThirdPartyContactAllFalsy() {
    if (!this.appraisalRequest) {
      return false;
    }
    const contacts = this.appraisalRequest.thirdPartyContact;
    return Object
      .keys(contacts)
      .filter(k => this.validThirdPartyContactFields.includes(k))
      .every(function (k) {
        return !contacts[k];
      });
  }

  onDocumentsUpdated(updatedDocs: Array<AppraisalDocument>) {
    this.appraisalRequest.documents = updatedDocs;
  }

  onNotessUpdated(updatedNotes: Array<AppraisalNote>) {
    this.appraisalRequest.notes = updatedNotes;
  }
}
