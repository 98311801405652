import { Component, ViewChildren, QueryList, Output, EventEmitter, Input } from "@angular/core";
import { NgbDropdown, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import { Utils } from "src/app/core/services/utils";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";

export class Filters {
  dateRange: IDateRange;

  dateRangeToString = (): string => {
    let cloneFilters = JSON.parse(JSON.stringify(this)) as Filters; // deep clone

    if (cloneFilters.dateRange) {
      cloneFilters["Date"] = this.dateRange.displayText;
      delete cloneFilters.dateRange;
    }

    let selectedFilterItems = Object.keys(cloneFilters).filter(key => cloneFilters[key]);

    if (selectedFilterItems.length == 0) {
      return null;
    }

    let str = selectedFilterItems.map(key => `<b>` + key + " = </b>" + '"' + cloneFilters[key] + '"')
      .join(`<b>` + " and " + `</b>`);

    return str;
  }
}

export interface IDateRange {
  id: number;
  name: string;
  displayText: string;
  startDate: Date;
  endDate: Date;
}

export abstract class DateRangeBase implements IDateRange {

  abstract get id(): number;
  abstract get name(): string;
  abstract get displayText(): string;

  private _startDate: Date;
  private _endDate: Date;

  get startDate(): Date {
    return this._startDate;
  }

  get endDate(): Date {
    return this._endDate;
  }

  protected abstract calculateStartDate(now: DateTime);
  protected abstract calculateEndDate(now: DateTime);

  constructor() {
    const now = DateTime.now();
    this._startDate = this.calculateStartDate(now);
    this._endDate = this.calculateEndDate(now);
  }
}

export class AllTime extends DateRangeBase {

  get id(): number {
    return 11;
  }
  get name(): string {
    return "All Time";
  }

  get displayText(): string {
    return "All Time";
  }

  protected calculateStartDate(now: DateTime) {
    return DateTime.fromObject({ month: 1, day: 1, year: 2015 }).toJSDate();
  }
  protected calculateEndDate(now: DateTime) {
    return now;
  }
}

export class ThisMonth extends DateRangeBase {

  get id(): number {
    return 5;
  }

  get name(): string {
    return "This Month";
  }

  get displayText(): string {
    return "This Month";
  }

  protected calculateStartDate(now: DateTime) {
    return now.startOf('month').toJSDate();
  }

  protected calculateEndDate(now: DateTime) {
    return now.endOf('month').toJSDate();
  }
}

export class ThisWeek extends DateRangeBase {

  get id(): number {
    return 3;
  }

  get name(): string {
    return "This Week";
  }

  get displayText(): string {
    return "This Week";
  }

  protected calculateStartDate(now: DateTime) {
    return now.startOf('week').minus({ days: 1 }).toJSDate();
  }

  protected calculateEndDate(now: DateTime) {
    return now.endOf('week').minus({ days: 1 }).toJSDate();
  }
}
@Component({
  selector: 'date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']

})
export class DateRangeFilterComponent {

  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  @Input()
  set selectedRange(range: IDateRange) {
    this._selectedRange = range;
    if (!range) {
      this.startDate = null;
      this.endDate = null;
    } else {
      this.startDate = range.startDate;
      this.endDate = range.endDate;
    }
  }

  @Input()
  set selectedRangeId(id: number) {
    this.onDateRangeChanged(id);
  }

  get selectedRange(): IDateRange {
    return this._selectedRange;
  }

  @Input() showLabel: boolean = true;

  @Output() rangeChanged = new EventEmitter<IDateRange>();

  startDate: Date = null;
  endDate: Date = null;

  onCustomStartDateChangedDebounce = debounce((startDate: string) => this.onCustomStartDateChanged(startDate), 1000);
  onCustomEndDateChangedDebounce = debounce((endDate: string) => this.onCustomEndDateChanged(endDate), 1000);

  private _selectedRange: IDateRange = null;

  static dateRanges: EnumerationItem[] = [
    { name: "Today", value: 1, alternateValue: "Today" },
    { name: "Yesterday", value: 2, alternateValue: "Yesterday" },
    { name: "This Week", value: 3, alternateValue: "ThisWeek" },
    { name: "Last Week", value: 4, alternateValue: "LastWeek" },
    { name: "This Month", value: 5, alternateValue: "ThisMonth" },
    { name: "Last Month", value: 6, alternateValue: "LastMonth" },
    { name: "This Quarter", value: 7, alternateValue: "ThisQuarter" },
    { name: "Last Quarter", value: 8, alternateValue: "LastQuarter" },
    { name: "Year To Date", value: 9, alternateValue: "YearToDate" },
    { name: "Last Year", value: 10, alternateValue: "LastYear" },
    { name: "All Time", value: 11, alternateValue: "AllTime" },
    { name: "Custom", value: 28, alternateValue: "Custom" },
  ];

  static datePeriods: EnumerationItem[] = [
    { name: "1. Quarter", value: 12 },
    { name: "2. Quarter", value: 13 },
    { name: "3. Quarter", value: 14 },
    { name: "4. Quarter", value: 15 },
    { name: "January", value: 16 },
    { name: "February", value: 17 },
    { name: "March", value: 18 },
    { name: "April", value: 19 },
    { name: "May", value: 20 },
    { name: "June", value: 21 },
    { name: "July", value: 22 },
    { name: "August", value: 23 },
    { name: "September", value: 24 },
    { name: "October", value: 25 },
    { name: "November", value: 26 },
    { name: "December", value: 27 },
  ];

  get dateRanges() {
    return DateRangeFilterComponent.dateRanges;
  }

  get datePeriods() {
    return DateRangeFilterComponent.datePeriods;
  }

  constructor(
    public activeModal: NgbActiveModal) {
  }

  onCustomStartDateChanged = (startDate: string) => {
    this._selectedRange.startDate = Utils.parseAsLocal(startDate, false);
    this.startDate = this._selectedRange.startDate;
    if (!this._selectedRange.startDate || !this._selectedRange.endDate) {
      return;
    }
    this.emitCustomDateRangeChange();
  }

  onCustomEndDateChanged = (endDate: string) => {
    this._selectedRange.endDate = Utils.parseAsLocal(endDate, true);
    this.endDate = this._selectedRange.endDate;
    if (!this._selectedRange.startDate || !this._selectedRange.endDate) {
      return;
    }
    this.emitCustomDateRangeChange();
  }

  onDateRangeChanged = (id: number): void => {
    this._selectedRange = DateRangeFilterComponent.getSelectedRangeById(id)

    this.startDate = this._selectedRange.startDate;
    this.endDate = this._selectedRange.endDate;

    if (id !== 28) {
      this.closeDropDowns();  
      this.rangeChanged.emit(this._selectedRange);
    }
  }

  private closeDropDowns = (): void => {
    if (this.dropdowns) {
      this.dropdowns.toArray().forEach(el => {
        el.close();
      });
    }
  }

  static findSelectedRange = (value: number): EnumerationItem => {
    return ([...DateRangeFilterComponent.dateRanges, ...DateRangeFilterComponent.datePeriods] as EnumerationItem[]).find(range => range.value == value);
  }

  static findSelectedRangeByDuration = (duration: string): EnumerationItem => {
    return (DateRangeFilterComponent.dateRanges).find(range => range.alternateValue == duration);
  }

  static getSelectedRangeByDuration = (duration: string) => {
    let selectedDateRange = DateRangeFilterComponent.findSelectedRangeByDuration(duration);
    if (selectedDateRange) {
      return DateRangeFilterComponent.getSelectedRangeById(selectedDateRange.value);
    }
  }

  static getSelectedRangeById = (id: number): IDateRange => {
    let selectedRangeEnum = DateRangeFilterComponent.findSelectedRange(id);
    let selectedRange = {
      id: id,
      name: selectedRangeEnum.name,
      displayText: selectedRangeEnum.name,
      startDate: new Date(),
      endDate: new Date()
    } as IDateRange;

    return Utils.getDateRangeById(selectedRange, id);
  }

  private emitCustomDateRangeChange = (): void => {
    this.closeDropDowns();

    this.rangeChanged.emit({
      id: this._selectedRange.id,
      name: 'Custom',
      displayText: "From: " + DateTime.fromJSDate(this._selectedRange.startDate).toLocaleString(DateTime.DATE_SHORT) + " To: " +
        DateTime.fromJSDate(this._selectedRange.endDate).toLocaleString(DateTime.DATE_SHORT),
      startDate: this._selectedRange.startDate,
      endDate: this._selectedRange.endDate
    });
  }
}
