<div class="modal-header">
    <h5 class="modal-title w-100">
        Lodasoft's Marketing AI Assistant
    </h5>

    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
    <form id="textGenerationForm" #textGenerationForm="ngForm" name="textGenerationForm">
        <div class="mb-3">
            <label class="form-label" for="selectPrompt">What are you looking to communicate?</label>
            <select name="selectPrompt" class="form-control" (change)="promptChanged(selectedPrompt)"
                [(ngModel)]="selectedPrompt">
                <option value="">-- Select One --</option>
                <optgroup label="Marketing">
                    <option value="m-cashout">Cashout Refinance</option>
                    <option value="m-debt-consolidation">Debt Consolidation</option>
                    <option value="m-heloc">HELOC</option>
                    <option value="m-purchase">Purchase</option>
                    <option value="m-custom">Custom Marketing</option>
                </optgroup>
                <optgroup label="Transactional">
                    <option value="t-new-inquiry">New Inquiry</option>
                    <option value="t-introduction">Loan Officer Introduction</option>
                    <option value="t-submit-processing">Submitted to Processing</option>
                    <option value="t-submit-uw">Submitted to UW</option>
                    <option value="t-conditional">Conditionally Approved</option>
                    <option value="t-resubmit-uw">Resubmitted to UW</option>
                    <option value="t-ctc">Clear to Close</option>
                    <option value="t-custom">Custom Transactional</option>
                </optgroup>
            </select>
        </div>
        <div class="mb-3" *ngIf="selectedPrompt">
            <label class="form-label" for="promptText">Describe the type of {{generationType}} you wish to send.</label>
            <textarea id="promptText" name="promptText" class="form-control" maxlength="255" rows="3"
                [ngClass]="{'is-invalid': promptTextField && promptTextField.touched && promptTextField.invalid}"
                #promptTextField="ngModel" [(ngModel)]="promptText" required></textarea>
        </div>
        <div class="mb-3">
            <button type="button" class="btn btn-primary" [disabled]="isLoading || !promptText"
                (click)="generateText()">
                <span *ngIf="!isLoading">{{suggestedText ? 'Try Again' : 'Suggest '+ generationType +' Content'}} </span>
                <span *ngIf="isLoading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Generating {{generationType}}
                </span>
            </button>
        </div>
        <div *ngIf="suggestedText" class="mb-3">
          <ng-container *ngIf='isEmail; else notEmail'>
            <label class='form-label' for='suggestedEmailSubject'>Subject</label>
            <textarea id='suggestedEmailSubject' name='suggestedEmailSubject' class='form-control mb-3'
                      style='resize: none'
                      [rows]='1'
                      [ngClass]="{
                          'is-invalid': suggestedEmailSubjectField
                          && suggestedEmailSubjectField.touched
                          && suggestedEmailSubjectField.invalid
                        }"
                      #suggestedEmailSubjectField='ngModel' [(ngModel)]='suggestedEmail.subject' disabled></textarea>

            <label class='form-label' for='suggestedEmailBody'>Body</label>
            <textarea id='suggestedEmailBody' name='suggestedEmailBody' class='form-control'
                      [rows]='15'
                      [ngClass]="{
                          'is-invalid': suggestedEmailBodyField
                          && suggestedEmailBodyField.touched
                          && suggestedEmailBodyField.invalid
                        }"
                      #suggestedEmailBodyField='ngModel' [(ngModel)]='suggestedEmail.body' disabled></textarea>
          </ng-container>

          <ng-template #notEmail>
            <label class="form-label" for="suggestedText">Suggested {{generationType}}</label>
            <textarea id="suggestedText" name="suggestedText" class="form-control"
                rows="{{generationType == 'SMS' ? 7 : 15 }}"
                [ngClass]="{'is-invalid': suggestedTextField && suggestedTextField.touched && suggestedTextField.invalid}"
                #suggestedTextField="ngModel" [(ngModel)]="suggestedText" disabled></textarea>
          </ng-template>
        </div>

    </form>
</div>

<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
        <i class="fa fa-close"></i> Cancel
    </button>

    <button *ngIf="suggestedText" type="button" class="btn btn-primary" (click)="onClickUseSuggestedButton()">
        Use Suggested {{generationType}}
    </button>
</div>
