<div class="modal-header">
    <h5 class="modal-title">Confirm Deletion</h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div *ngIf="!showItemListDropDown" class="text-center">
        <p>Would you like to default all the existing places this {{itemType?.toLowerCase()}} was used? </p>
        <em *ngIf="itemType === 'Role'">
            *Note: If you do not provide a replacement role, and there are tasks in the system assigned to that role, it
            will not delete.
        </em>
    </div>
    <div *ngIf="showItemListDropDown">
        <div class="d-flex align-items-center">
            <label class="col-sm-5 control-label" for="allItems">Replace with {{itemType}}:</label>
            <div class="col-sm-7">
                <select class="form-select" id="availableItems" name="availableItems" [(ngModel)]="replacementId">
                    <option value="0">-- Select One --</option>
                    <option *ngFor="let item of ItemsList" value={{item.value}}>
                        {{item.name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary center" (click)="activeModal.dismiss()">Cancel</button>
        <button *ngIf="!showItemListDropDown" type="button" class="btn btn-primary center" (click)="confirmDelete()">
            No & Delete</button>
        <button *ngIf="!showItemListDropDown" type="button" class="btn btn-primary center"
            (click)="showItemListDropDown=true">Yes</button>
        <button *ngIf="showItemListDropDown" type="button" class="btn btn-primary center" [disabled]="!replacementId"
            (click)="confirmDelete()">Replace & Delete</button>
    </div>
</div>