export class EnumerationItem<T = any> {
  name: string;
  value: T;
  alternateValue?: any;
  id?: number;
  groupName?: string | null;
  displayFormat?: string | null;
  supportedPricingVendors?: string | null;

  constructor(name: string, value: T, groupName?: string, displayFormat?: string, supportedPricingVendors?: string) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.displayFormat = displayFormat;
    this.supportedPricingVendors = supportedPricingVendors;
  }
}
