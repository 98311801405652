<div class="row">
  <div class="card-body">
    <div class="table-responsive" style="border-bottom: none;">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th class="col-3"> Account Owner </th>
            <th class="col-3"> Account Type </th>
            <th class="col-2"> Amount </th>
            <th class="col-2"> Account Number </th>
            <th class="col-1 text-center"> Action </th>
            <th class="col-1"> </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let asset of assets; let index = index;" style="height: 50px;">
            <td class="col-3">
              <ng-select2 class="select2 mb-3 select2-multiple" name="owningBorrowerIds{{asset.assetId}}"
                [(ngModel)]="asset.owningBorrowerIds" [options]="optionsMultipleSelect" [data]="possibleAccountOwners"
                (ngModelChange)="onAccountOwnersChanged(asset)">
              </ng-select2>
            </td>
            <td class="col-3">
              <select id="assetType" name="assetType" class="form-select" [(ngModel)]="asset.assetType">
                <option [ngValue]="null"></option>
                <option *ngFor="let assetType of assetTypes" [ngValue]="assetType.value">
                  {{ assetType.name }}
                </option>
              </select>
            </td>

            <td class="col-2">
              <currency-input name="cashMarketValue{{asset.assetId}}" [(ngModel)]="asset.cashMarketValue"
                (ngModelChange)="calculateSubTotals()">
              </currency-input>
            </td>

            <td class="col-2">
              <input id="accountNumber" maxlength="19" class="form-control" placeholder="Account Number"
                name="accountNumber" [(ngModel)]="asset.accountNumber">
            </td>

            <td class="col-1 text-center">
              <a href="javascript: void(0);" (click)="deleteAsset(index)"><i class="fa fa-trash-alt mx-1"></i></a>
            </td>
            <td class="col-1"></td>
          </tr>
          <tr>
            <td class="col-2">
            </td>
            <td class="col-2 text-end">
              <b>Total</b>
            </td>
            <td class="col-2">
              <b><span class="ps-2"> {{cashMarketSubTotal | currency}} </span></b>
            </td>
            <td class="col-2">
            </td>
            <td class="col-2 text-end">
            </td>
            <td class="col-1 text-center">
            </td>
            <td class="col-1">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>