<div class="d-flex flex-column p-0" style="height: 100%;">
  <div class="page-action-bar-middle">
    <div class="d-flex">
      <div class="special-radio-btn-group flex-grow-1">
        <div class="container-fluid g-0">
          <div class="row mx-xs-0">
            <div class="col-md-6">
              <div class="d-flex" *ngIf="useStripoEditor">
                <div class="btn-group me-3">
                  <button type="button" class="btn btn-outline-secondary" title="Leave" (click)="onCancelClicked()">
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </button>
                </div>
                <!-- <generic-filter [icon]="'fas fa-chalkboard-teacher'" [label]="'Choose template'"
                  [noOptionsMessage]="'There are no templates'" [width]="171"
                  [filterHeader]="'Choose any email template'" [filterOptions]="savedTemplateOptions"
                  [type]="'singleSelect'" (filtersChanged)="onTemplateSelected($event)"
                  [hidden]="inFullScreenWidgetDetailsMode">
                </generic-filter> -->
                <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 btn-stripo" (click)="onStartWithStripDefaultClicked()">
                  <span class="pe-1"> Generate </span>
                  <img src="assets/images/stripo.png" alt="Stripo" width="56">
                  <span class="ps-1"> Default </span>
                </button>
                <div class="toggle-icon-group ms-3">
                  <button type="button" id="undoButton{{documentId}}" class="btn btn-outline-secondary icon-btn" title="Undo">
                    <i class="fas fa-reply"></i>
                  </button>
                  <button type="button" id="historyButton{{documentId}}" class="btn btn-outline-secondary icon-btn" title="History">
                    <i class="fas fa-history"></i>
                  </button>
                  <button type="button" id="redoButton{{documentId}}" class="btn btn-outline-secondary icon-btn" title="Redo">
                    <i class="fas fa-share"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 text-end">

              <div class="btn-group d-md-inline-flex flex-wrap justify-content-center mt-xs-2">

                <div class="btn-group">
                  <button type="button" *ngIf="useStripoEditor" id="viewCodeButton{{documentId}}" class="btn btn-outline-secondary ms-2 h-xs-fit-content"
                    title="Code editor">
                    <i class="fas fa-code"></i>
                  </button>
                  <button *ngIf="useStripoEditor" type="button" class="btn btn-outline-secondary ms-2" title="Email settings">
                    <i class="fas fa-cog"></i>
                  </button>
                  <div class="form-check form-switch form-switch-success ms-2 ms-xs-0">
                    <label for="editModeToggleForPreview" class="form-check-label mt-1"> Email Preview </label>
                    <input name="editModeToggleForPreview" id="editModeToggleForPreview"
                      class="form-check-input mt-2"
                      (change)="onPreviewModeToggled($event)" type="checkbox" />
                  </div>
                  <div class="form-check form-switch form-switch-success ms-2 ms-xs-0">
                    <label for="stripoEditorToggler" class="form-check-label mt-1"> Use Stripo Editor </label>
                    <input name="stripoEditorToggler" id="stripoEditorToggler"
                      class="form-check-input mt-2"
                      [checked]="useStripoEditor"
                      (change)="onStripoEditorToggled($event)" type="checkbox" />
                  </div>
                  <button type="button" class="btn btn-primary ms-2" title="Save" (click)="onSaveClicked()">
                    Save
                    <i class="fas fa-save ms-1 color-default"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content" style="height: 100%;">
    <div class="full-height panels-container mx-3" style="height: 100%;">
      <div [hidden]="isPreviewMode || !useStripoEditor" class="row" style="--bs-gutter-x: 0; height: 100%;">
        <div
          class="full-height col-xs-5 col-md-4 settings-container col-lg-3 esdev-no-padding esdev-fixed settings-left-side">
          <div id="stripoSettingsContainer{{documentId}}"></div>
        </div>
        <!-- <div
          class="full-height history-container mail-right-side col-lg-offset-3 col-md-offset-4 col-lg-9 col-md-8 col-xs-12">
          <div id="changeHistoryContainer{{documentId}}"></div>
        </div> -->
        <div
          class="full-height preview-container mail-right-side col-lg-offset-3 col-md-offset-4 col-lg-9 col-md-8 col-xs-12">
          <div id="stripoPreviewContainer{{documentId}}"></div>
        </div>
      </div>
      <div *ngIf="!useStripoEditor && !isPreviewMode" style="height: 100%">
        <rich-text-editor [name]="documentId" [height]="'100%'"
          [(ngModel)]="html" [mergeFields]="mergeFields">
        </rich-text-editor>
      </div>
      <div [hidden]="!isPreviewMode">
        <iframe style="width:100%; height: 100%; border:1px solid #e3ebf6; border-radius: 0.25rem;"
          id="htmlPreviewPanel"></iframe>
      </div>
    </div>
  </div>
</div>
