<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>
<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary" [disabled]="superAdmin" (click)="openUpsertDrawer()">
    <i class="fas fa-plus"></i> Add New
  </button>
</ng-template>

<ng-template #mainContent>
  <p-table #dt1 [columns]="selectedColumns" [value]="items" [paginator]="true" [rows]="25" [resizableColumns]="true"
    responsiveLayout="scroll" name="checklist-item-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="globalFilterFields" [autoLayout]="true" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" styleClass="p-datatable-gridlines"
    sortField="checkListName" sortMode="single" scrollable="true" [scrollHeight]="scrollHeight">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">
        <span>

        </span>

        <span class="ms-auto">
          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl
            defaultLabel="Choose Columns"></p-multiSelect>

          <span class="p-input-icon-right ms-2 h-100">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0" placeholder="Search keyword" />
          </span>
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 5rem">
          <span>Action</span>
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center w-100">

            <span>
              <i class="fa fa-play cursor text-primary" (click)="showSearchApplicationModal(rowData)" title="Show item evals"></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i class="fa fa-pencil-alt cursor text-primary" (click)="showItemTreeViewDrawer(rowData)" title="Edit"></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i *ngIf="!isDeleteClicked[rowData.checklistId]" class="fa fa-trash-alt cursor text-danger"
                (click)="isDeleteClicked[rowData.checklistId] = true" title="Delete"></i>

              <i *ngIf="isDeleteClicked[rowData.checklistId]" class="fa fa-times cursor text-danger me-2"
                (click)="isDeleteClicked[rowData.checklistId] = false" title="Cancel"></i>

              <i *ngIf="isDeleteClicked[rowData.checklistId]" class="fa fa-check-circle cursor text-danger"
                (click)="onDeleteItemClicked(rowData.checklistId)" title="Delete"></i>
            </span>
          </span>
        </td>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <span *ngSwitchCase="'checkListName'">
            <!-- <a class="link me-2" title="Click for show tree list view of item"
              (click)="showItemTreeViewDrawer(rowData)">{{ rowData['checkListName'] }}</a> -->

            {{ rowData['checkListName'] }}
          </span>

          <span *ngSwitchCase="'loanStatusId'">
            {{ getSelectedLoanStatus(rowData.loanStatusId)?.loanStatusName }}
          </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight" [pageMainContentTemplate]="mainContent">
</admin-page-template>


<drawer #upsertCheckListDrawer title="{{ currentCheckListRecord?.checklistId ? 'Edit' : 'New' }} Checklist Item"
  [name]="'upsertCheckListDrawer'" [templateRef]="upsertCheckListRef" [options]="upsertCheckListDrawerOptions">
</drawer>
<ng-template #upsertCheckListRef>
  <check-list-item-details #upsertCheckList [checkListItem]="currentCheckListRecord" [loanStatuses]="loanStatuses"
    (close)="onDrawerClose($event)"></check-list-item-details>
</ng-template>

<drawer #checkListTreeViewDrawer title="{{ currentCheckListRecord?.checklistId ? 'Edit' : 'New' }} Checklist"
  [name]="'checkListTreeViewDrawer'" [templateRef]="checkListTreeViewRef" [options]="checkListTreeViewDrawerOptions">
</drawer>
<ng-template #checkListTreeViewRef>
  <check-list-item-treelist #checkListTreeView [companyId]="companyId" [checklistId]="currentCheckListRecord.checklistId"
    [globalConfig]="globalConfig" [loanStatuses]="loanStatuses" (close)="onTreeViewDrawerClose($event)"></check-list-item-treelist>
</ng-template>
