import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IDateRange } from '../date-range-filter/date-range-filter.component';
import { FilterOption } from '../generic-filter/generic-filter.component';

@Component({
  selector: 'generic-date-range-filter',
  templateUrl: 'generic-date-range-filter.component.html',
  styleUrls: ['generic-date-range-filter.component.scss']
})
export class GenericDateRangeFilterComponent implements OnInit {

  get label(): string {
    return this._label;
  }

  @Input()
   set label(value: string) {
    this._label = value;
    this.labelLocal = value;
  }

  @Input()
  icon: string;

  @Input() set dateRange(dateRange: IDateRange) {
    this._dateRange = dateRange;
    if (this._dateRange) {
      this.labelLocal = this._dateRange.displayText;
    }
  };

  get dateRange(): IDateRange {
    return this._dateRange;
  }

  @Input()
  selected: boolean = false;

  @Input()
  popoverLocation: string = "left";

  @Input() clearVisible: boolean = true;

  @Output()
  filtersChanged: EventEmitter<FilterOption> = new EventEmitter<FilterOption>();

  labelLocal: string;

  private _label: string;

  private _dateRange: IDateRange = null;

  constructor(private readonly _elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  onClickedOutside = (event: any) => {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.selected = false;
    }
  }

  ngOnInit() {
  }

  toggleSelection = () => {
    this.selected = !this.selected;
  }

  onClearClicked = () => {
    this._dateRange = null;
    this.filtersChanged.emit(null);
  }

  dateRangeChanged = (selectedDateRange: IDateRange) => {
    this._dateRange = selectedDateRange;
    let selectedOption: FilterOption = selectedDateRange ? new FilterOption({
      displayName: selectedDateRange.displayText,
      value: selectedDateRange.id,
      dateRange: selectedDateRange
    }) : null;

    if (selectedDateRange) {
      selectedOption.isSelected = true;
      this.labelLocal = selectedDateRange.displayText;
    }
    this.filtersChanged.emit(selectedOption);
  }
}
