<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-file-alt me-2"></i>
          Document Preparation
        </h4>
      </div>
      <div class="card-body">
        <loading-indicator *ngIf="isLoadingDocPrepData" [loadingMessage]="'Loading document preparation, please wait...'">
        </loading-indicator>
        <div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical" *ngIf="!isLoadingDocPrepData">
          <div class="button-items">
            <div class="float-end">
              <div class="btn-group control-btn">
                <button class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown">
                  Generate Packet ... <i class="fas fa-caret-down"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" *ngFor="let docPrepPacketType of docPrepPacketTypes"
                    (click)="onGeneratePacketClicked(docPrepPacketType.value)">{{docPrepPacketType.name}}
                  </a>
                </div>
                <button class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown"
                  (click)="systemGenerateDocumentMenuActive = false">
                  Generate Document ... <i class="fas fa-caret-down"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" *ngFor="let docPrepType of docPrepTypes"
                    (click)="onGenerateDocClicked(docPrepType.value)">{{docPrepType.name}}
                  </a>
                </div>
              </div>
              <button type="button" class="btn btn-primary me-1" (click)="onSaveClicked()">
                <i class="fas fa-save me-1"></i>
                Save
              </button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!isLoadingDocPrepData">
          <div class="col-xl-12">
            <document-preparation-loan-info
              [docPrep]="docPreparationData"
              (loanPurposeChanged)="onLoanPurposeChanged()">
            </document-preparation-loan-info>
          </div>
          <div class="col-xl-12">
            <document-preparation-property-info
              [docPrep]="docPreparationData">
            </document-preparation-property-info>
          </div>
          <div class="col-xl-12">
            <document-preparation-legal-description
            [docPrep]="docPreparationData">
            </document-preparation-legal-description>
          </div>
          <div class="col-xl-12">
            <document-preparation-borrowers-v2
              [docPrep]="docPreparationData"
              [borrowerRoles]="borrowerRoles"
              [entityTypes]="entityTypes"
              [borrowerTypes]="partyTypes"
              [suffixes]="suffixes"
              [states]="states"
              [maritalStatuses]="maritalStatuses">
            </document-preparation-borrowers-v2>
          </div>
          <div class="col-xl-12">
            <document-preparation-sellers-v2
              [docPrep]="docPreparationData"
              [entityTypes]="entityTypes"
              [sellerTypes]="partyTypes"
              [suffixes]="suffixes"
              [maritalStatuses]="maritalStatuses">
            </document-preparation-sellers-v2>
          </div>
          <div class="col-xl-12" *ngIf="isPurchase">
            <document-preparation-deed
              [deedTypes]="deedTypes"
              [docPrep]="docPreparationData">
            </document-preparation-deed>
          </div>
          <div class="col-xl-12" *ngIf="isRefi">
            <document-preparation-renewal-and-extension
              [docPrep]="docPreparationData">
            </document-preparation-renewal-and-extension>
          </div>
          <div class="col-xl-12">
            <document-preparation-title-company
            [docPrep]="docPreparationData">
            </document-preparation-title-company>
          </div>
          <div class="col-xl-12">
            <document-preparation-lender-info
              [docPrep]="docPreparationData">
            </document-preparation-lender-info>
          </div>
          <div class='col-xl-12' *ngIf='docPreparationData.externalCompanyId != null'>
            <document-preparation-invoicing
              [docPrep]='docPreparationData'>
            </document-preparation-invoicing>
          </div>
          <div class="col-xl-12">
            <document-preparation-custom-info
              [docPrep]="docPreparationData">
            </document-preparation-custom-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
