export interface LeadRouteDaySchedule {
  leadRouteDayScheduleModelId: number;
  leadRouteId: number;
  dayOfWeek: DayOfWeek;
  allDay: string;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  scheduleTimeZone: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}
