<div class="card">
    <div class="card-header">
        <h4 class="card-title">Contact</h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Home Phone</label>
                    <div class="col-sm-10">
                        <input class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
                            type="text" [(ngModel)]="profile.homePhone">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Cell Phone</label>
                    <div class="col-sm-10">
                        <input class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
                            type="text" [(ngModel)]="profile.cellPhone">
                    </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Caller Id (Override)</label>
                  <div class="col-sm-10">
                      <input [disabled]="!dialerEnabled" class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
                          type="text" [(ngModel)]="profile.callerIdOverride">
                  </div>
              </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Work Phone</label>
                    <div class="col-sm-10">
                        <input class="form-control" type="text" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'" [(ngModel)]="profile.workPhone">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Fax</label>
                    <div class="col-sm-10">
                        <input class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
                            type="text" [(ngModel)]="profile.fax">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-header">
        <h4 class="card-title">Address</h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Address</label>
                    <div class="col-sm-10">
                        <input class="form-control" placeholder="Address" type="text"
                            [(ngModel)]="profile.mailingStreet">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">City</label>
                    <div class="col-sm-10">
                        <input class="form-control" placeholder="City" type="text"
                            [(ngModel)]="profile.mailingCity">
                    </div>
                </div>
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Zip Code</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="Zip Code" type="text"
                            [(ngModel)]="profile.mailingZip">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Address 2</label>
                    <div class="col-sm-10">
                        <input class="form-control" placeholder="Address 2" type="text"
                            [(ngModel)]="profile.mailingPOBox">
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">State</label>
                    <div class="col-sm-10">

                        <select class="form-select" [(ngModel)]="profile.mailingState">
                            <option value="">-- Select One --</option>
                            <option *ngFor="let state of statesList" value={{state.value}}>
                                {{state.name}}({{ state.alternateValue }})
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
