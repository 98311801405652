<div class="accordion-item" *ngIf="doc">
    <h5 class="accordion-header m-0" id="heading-{{doc.documentTypeId}}">
        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse-'+doc.documentTypeId" aria-expanded="true"
            [attr.aria-controls]="'#collapse-'+doc.documentTypeId">
            {{doc.documentTypeName}}
        </button>
    </h5>
    <div id="collapse-{{doc.documentTypeId}}" class="accordion-collapse collapse"
        aria-labelledby="heading-{{doc.documentTypeId}}" data-bs-parent="#accordionSync">
        <div class="accordion-body">
            <p-table #dt1 [value]="filteredDocs" [paginator]="true" [rows]="25" *ngIf="filteredDocs.length > 0"
                [rowsPerPageOptions]="[10,25,50]"
                [globalFilterFields]="['description','docFiles','docFiles.docFile.fileName','docFiles.docFile.error']"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines" [(selection)]="selectedFiles">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control" name="searchKeyword"
                                (ngModelChange)="dt1.first=0"
                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'description'">
                            Description
                            <p-sortIcon [field]="'description'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'docFiles'">
                            Sync Status
                            <p-sortIcon [field]="'docFiles'"></p-sortIcon>
                        </th>
                        <th class="text-center">
                            <p-tableHeaderCheckbox (click)="onSelectAllByDocumentClicked(rowHeaderCheckBox.checked)"
                                [disabled]="!isFilterNotRestricted(docFilter) || (docFilter !== 'All' && hasNoneFilteredFile(doc.loanDocs, docFilter))"
                                #rowHeaderCheckBox>
                            </p-tableHeaderCheckbox>

                        </th>
                        <th [pSortableColumn]="'docFiles.docFile.fileName'">
                            File Name
                            <p-sortIcon [field]="'docFiles.docFile.fileName'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'docFiles.docFile.error'">
                            Errors
                            <p-sortIcon [field]="'docFiles.docFile.error'"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr>
                        <td [rowSpan]="filesLengthToRender(doc.docFiles, docFilter) + 1">
                            {{doc.description}}
                            <div [hidden]="docFilter !== 'Unmapped'" [ngClass]="{'mt-2': doc.description}">
                                <a class="text-primary" (click)="onAddMappingForVendorClicked(doc)">
                                    Add mapping for '{{doc.title}}'
                                </a>
                            </div>
                        </td>
                    </tr>
                    <ng-container *ngFor="let file of doc.docFiles">
                        <tr *ngIf="docFilter == 'All' || (file.sync === docFilter && !isFileNotRestricted(file))">
                            <td>
                                {{file.sync}}
                            </td>
                            <td class="text-center">
                                <p-tableCheckbox [value]="file" [id]="file.globalId"
                                    (click)="onToggleCheckboxClicked(file.globalId, doc, rowCheckBox.checked)" [disabled]="file.disabled" #rowCheckBox>
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <div>
                                    <a (click)="viewLoanDocument(file.docFile)" title="View" class="mx-2">
                                        <i class='far fa-eye'></i>
                                    </a>
                                    <i class="far"
                                        [ngClass]="{'fa-file-pdf': file.docFile.mimeType === 'application/pdf', 'fa-file': file.docFile.mimeType !== 'application/pdf'}"></i>
                                    <span class="dark-fa-color mx-1" style="word-break: break-all;">
                                        {{file.docFile.fileName || file.docFile.title}}
                                    </span>
                                </div>
                                <span class="date-inner-wrap"> {{file.docFile.dateInserted | date:'MM/dd/yyyy h:mma'}}
                                </span>
                            </td>
                            <td> {{file.error ? file.error : ''}} </td>
                        </tr>
                        <tr *ngIf="(docFilter == 'PushToLos' || docFilter == 'PullFromLos') && (file.sync === docFilter && isFileNotRestricted(file))">
                            <td>
                                {{file.sync}}
                            </td>
                            <td class="text-center">
                                <p-tableCheckbox [value]="file" [id]="file.globalId"
                                    (click)="onToggleCheckboxClicked(file.globalId, doc, rowCheckBox.checked)" [disabled]="file.disabled" #rowCheckBox>
                                </p-tableCheckbox>
                            </td>
                            <td>
                                <div>
                                    <a (click)="viewLoanDocument(file.docFile)" title="View" class="mx-2">
                                        <i class='far fa-eye'></i>
                                    </a>
                                    <i class="far"
                                        [ngClass]="{'fa-file-pdf': file.docFile.mimeType === 'application/pdf', 'fa-file': file.docFile.mimeType !== 'application/pdf'}"></i>
                                    <span class="dark-fa-color mx-1" style="word-break: break-all;">
                                        {{file.docFile.fileName || file.docFile.title}}
                                    </span>
                                </div>
                                <span class="date-inner-wrap"> {{file.docFile.dateInserted | date:'MM/dd/yyyy h:mma'}}
                                </span>
                            </td>
                            <td> {{file.error ? file.error : ''}} </td>
                        </tr>
                    </ng-container>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5" class="text-center"> No document found. </td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="filteredDocs.length == 0">
                <h5 class="text-center"> There are no files that were synchronized. </h5>
            </div>
        </div>
    </div>
</div>
