<div class="modal-header">
  <h5 class="modal-title"> Second Lien Amount </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #secondLienForm="ngForm" novalidate id="secondLienForm" name="secondLienForm">
    <div class="row">
      <div class="mb-3">
        <label class="form-label" for="loanType">Loan Type</label>
        <select class="form-select" id="loanType" name="loanType"
          #typeOfLoan="ngModel"
          [ngClass]="{'is-invalid' : typeOfLoan && typeOfLoan.touched && typeOfLoan.invalid}"
          [(ngModel)]="loanType"
          (ngModelChange)="onLoanTypeChanged()"
          required>
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let option of loanTypeOptions" value="{{option.value}}">
            {{option.name}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="loanType == 'Mortgage'">
        <div class="mb-3">
          <label class="form-label" for="helocDrawnAmount">Second Lien Amount </label>
          <currency-input [name]="'secondLienAmount'" [required]="true" [(ngModel)]="loanInformation.secondLienAmount">
          </currency-input>
          <div class="invalid-feedback">This field is required</div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="loanType == 'HELOC'">
        <div class="mb-3">
          <label class="form-label" for="helocDrawnAmount">HELOC Drawn Amount </label>
          <currency-input [name]="'helocDrawnAmount'" [required]="true"
            [(ngModel)]="loanInformation.helocDrawnAmount">
          </currency-input>
          <div class="invalid-feedback">This field is required</div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="loanType == 'HELOC'">
        <div class="mb-3">
          <label class="form-label" for="helocLineAmount">HELOC Line Amount </label>
          <currency-input [name]="'helocLineAmount'" [required]="true"
            [(ngModel)]="loanInformation.helocLineAmount">
          </currency-input>
          <div class="invalid-feedback">This field is required</div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
</div>
