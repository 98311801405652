export class TaskStatsGroup {
  title: string;
  stats: TaskStats[] = [];
  color?: string;
  isVisible: boolean = true;

  constructor(title: string, stats: TaskStats[], color?: string, isVisible?: boolean) {
    this.title = title;
    this.stats = stats;
    this.color = color;
    if (isVisible !== undefined) {
      this.isVisible = isVisible;
    }
  }
}

export class TaskStats {
  name: string;
  value: string;
  id: number

  constructor(name: string, value: string, id: number) {
    this.name = name;
    this.value = value;
    this.id = id;
  }
}
