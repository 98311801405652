<div class="modal-header">
  <h6 class="modal-title">Create Loan in LOS</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <h4 *ngIf="losOptions && losOptions.useCredentialId && losOptions.useCredentialId !== selectedCredentialId && !isLoadingVendorData">
    This loan will be created using Loan Officer's credentials for LOS
  </h4>

  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="mb-3 row">
        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Vendor Name</label>
        <div class="col-sm-8 position-relative">
          <select
            class="form-select"
            id="vendor"
            name="vendor"
            [disabled]="isCreating || isLoadingVendorData"
            [(ngModel)]="selectedCredentialId"
            (change)="changeVendor(selectedCredentialId)"
          >
            <option [ngValue]="null">--Select One--</option>
            <option *ngFor="let vendor of losVendors" value="{{vendor.credentialId}}">
              {{vendor.losVendorName}}
            </option>
          </select>
          <span *ngIf="isLoadingVendorData" class="spinner-border spinner-border-sm dropdown-spinner" role="status" aria-hidden="true"></span>
        </div>
      </div>

      <ng-container *ngIf="losOptions && losOptions.options">
        <div class="mb-3 row" *ngFor="let option of losOptions.options; let i = index;">
          <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">
            {{option.display ? option.display : option.name}}
          </label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              id="text_{{i}}"
              name="text_{{i}}"
              *ngIf="option.type === 'String'"
              [(ngModel)]="losTypeValue[option.name]"
              (change)="setAllowedValue(losTypeValue[option.name], option.name)"
            />
            <input
              type="number"
              class="form-control"
              id="number_{{i}}"
              name="number_{{i}}"
              *ngIf="option.type === 'Int' || option.type === 'Decimal'"
              [(ngModel)]="losTypeValue[option.name]"
              (change)="setAllowedValue(losTypeValue[option.name], option.name)"
            />
            <input
              type="date"
              class="form-control"
              id="date_{{i}}"
              name="date_{{i}}"
              *ngIf="option.type === 'Int' || option.type === 'Date'"
              [(ngModel)]="losTypeValue[option.name]"
              (change)="setAllowedValue(losTypeValue[option.name], option.name)"
            />
            <select
              class="form-select"
              id="option_{{i}}"
              name="option_{{i}}"
              *ngIf="option.type === 'Enum'"
              [(ngModel)]="losTypeValue[option.name]"
              [disabled]="!!option.onSelectOption && !losTypeValue[option.onSelectOption]"
              (change)="selectAllowedValue(losTypeValue[option.name], option.name)"
            >
              <option [ngValue]="null">--Select One--</option>
              <option
                *ngFor="let allowedValue of option.allowedValues"
                value="{{allowedValue.value}}"
                [disabled]="allowedValue.value === null"
                [ngStyle]="{'font-weight': allowedValue.value === null ? 'bold' : ''}"
              >
                <span *ngIf="allowedValue.value !== null">&nbsp;&nbsp;&nbsp;</span>
                {{allowedValue.display}}
              </option>
            </select>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="create()"
                [disabled]="!createAllowed || isCreating || isLoadingVendorData">
          <span *ngIf="isCreating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Create
        </button>
      </div>
    </div>
  </div>
</div>
