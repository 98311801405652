import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactListType, PipelineItem } from 'src/app/models';
import { DialList } from 'src/app/modules/dialer/models/dial-list.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import Swal from 'sweetalert2';
import { AddToDialListConfirmationDialogComponent } from './add-to-dial-list-confirmation-dialog/add-to-dial-list-confirmation-dialog.component';
import { uniqBy } from 'lodash';
import { AgentForTable } from 'src/app/modules/agents/models/agent-for-table.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
@Component({
  selector: 'app-add-to-dial-list',
  templateUrl: './add-to-dial-list.component.html',
  styleUrls: ['./add-to-dial-list.component.scss']
})
export class AddToDialListComponent implements OnInit {

  @Input() contactListType: ContactListType;
  @Input() selectedRows: any[] = [];
  @Input() idFieldName: string;

  @Output()
  onAddedToDialList: EventEmitter<never> = new EventEmitter<never>();

  isLoading: boolean = false;
  selectedDialList: DialList = null;

  constructor(
    private readonly _notify: NotificationService,
    private readonly _dialerService: DialerService,
    private readonly _modalService: NgbModal,
    private readonly _router: Router) {
  }

  ngOnInit(): void {
  }

  openAddItemsToDialerListConfirmationModal = (): void => {
    let rowsWithoutPhones = []
    if (this.contactListType === ContactListType.Lead) {
      this.selectedRows = uniqBy(this.selectedRows, 'leadId')
      rowsWithoutPhones = (this.selectedRows as Lead[]).filter(r => !(r.mobilePhone || r.phone || r.workPhone || r.coMobilePhone || r.coPhone || r.coWorkPhone));
    } else if (this.contactListType === ContactListType.Borrower) {
      this.selectedRows = uniqBy(this.selectedRows, 'borrowerId')
      rowsWithoutPhones = (this.selectedRows as BorrowerDto[]).filter(r => !(r.mobilePhone || r.homePhone));
    } else if (this.contactListType === ContactListType.Agent) {
      this.selectedRows = uniqBy(this.selectedRows, 'agentId')
      rowsWithoutPhones = (this.selectedRows as AgentForTable[]).filter(r => !(r.mobilePhone || r.workPhone));
    } else if (this.contactListType === ContactListType.Application) {
      this.selectedRows = uniqBy(this.selectedRows, 'applicationId')
      rowsWithoutPhones = (this.selectedRows as PipelineItem[]).filter(r => !(r.mobilePhone || r.homePhone));
    } else {
      rowsWithoutPhones = this.selectedRows.filter(r => !(r.mobilePhone || r.homePhone));
    }

    if (rowsWithoutPhones.length) {
      let htmlRows = rowsWithoutPhones.map(r => `<li>${r.displayName}</li>`).join("");

      Swal.fire({
        title: 'Missing Phone Number!',
        html: `
        <div style="text-align: left;">The ${this.contactListType.toLowerCase()}s listed below do not have a phone number set, so they will not be added into dial list.
        <div style="margin-top: 15px; max-height: 300px; overflow-y: auto;">
        <ul style=" columns: 3;">
        ${htmlRows}
        </ul>
        </div>
        </div>
        `,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });

      return;
    }

    let modalOptions = Constants.modalOptions.medium;
    modalOptions.scrollable = false;
    const modalRef = this._modalService.open(AddToDialListConfirmationDialogComponent, modalOptions);
    modalRef.componentInstance.selectedItems = this.selectedRows;
    modalRef.componentInstance.contactListType = this.contactListType;

    modalRef.result.then((res: { toDialListId: number, isRoutingRequested: boolean }) => {
      this._dialerService.addItemsToDialList(res.toDialListId, this.selectedRows.map(item => item[this.idFieldName]))
        .subscribe({
          next: () => {
            this.selectedRows = [];
            this.selectedDialList = null;

            this._notify.showSuccess(this.contactListType + 's added to dial list succesfuly', 'Dial List');

            if (this.onAddedToDialList.observed) {
              this.onAddedToDialList.emit();
            }

            if (res.isRoutingRequested) {
              this._router.navigate(['admin/dialer']);
            }
          },
          error: (error) => {
            this._notify.showError(`Error ${error.message} while adding ${ContactListType[this.contactListType].toLowerCase()}s to dial list`, 'Dial List')
          }
        });
    }, () => {});
  }

  // openDeleteDialListDialog = (selectedDialList: DialList): void => {
  //   this.selectedDialList = selectedDialList;
  //   const modalRef = this._modalService.open(DeleteListConfirmationDialogComponent, Constants.modalOptions.medium);
  //   modalRef.componentInstance.deleteDialList = this.selectedDialList;
  //   modalRef.result.then(() => {
  //     this.deleteDialList(this.selectedDialList.dialListId);
  //   }, () => {
  //   });
  // }

  // openMoveToDialListDialog = (selectedDialList: DialList): void => {

  //   this.selectedDialList = selectedDialList;

  //   const modalRef = this._modalService.open(MoveToOtherListConfirmationDialogComponent, Constants.modalOptions.medium);
  //   modalRef.componentInstance.fromDialList = this.selectedDialList;
  //   modalRef.componentInstance.toDialLists = this.getOtherDialLists(this.selectedDialList);

  //   modalRef.result.then((res: { toDialListId: number, isMoving: boolean }) => {
  //     if (res.isMoving) {
  //       this.moveToDialList(this.selectedDialList.dialListId, res.toDialListId);
  //     }
  //     else {
  //       this.deleteDialList(this.selectedDialList.dialListId);
  //     }
  //   }, () => {
  //   });
  // }

  // addNewDialList = (): void => {
  //   const modalRef = this._modalService.open(NewDialListDialogComponent, Constants.modalOptions.medium);
  //   modalRef.componentInstance.contactListType = this.contactListType;
  // }

}
