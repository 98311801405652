import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-url',
  templateUrl: './app-url.component.html'
})
export class AppUrlComponent implements OnInit {
  @Input() label: string;
  @Input() model: string;
  @Input() disableCopy: boolean;

  constructor() {}

  ngOnInit(): void {}
}
