import { Component, Input } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-percentage-input',
  templateUrl: './urla-percentage-input.component.html',
  providers: [MakeProvider(UrlaPercentageInputComponent)]
})
export class UrlaPercentageInputComponent extends BaseUrlaInputComponent {

  @Input()
  suffix: string;

  @Input()
  formElement: boolean;

 @Input()
  maxLength: number | null = null;
}
