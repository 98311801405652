import { Component, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'tpo-cd-request',
  templateUrl: 'tpo-cd-request.component.html'
})
export class TpoCdRequestComponent extends ApplicationContextBoundComponent implements OnDestroy {
  isTpo: boolean = false;
  srcUrl: string = 'https://tpoapps.prmg.net/lstpoforms/DocumentOrderForm'

  private readonly _contextSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _environmentService: EnvironmentService,
    private readonly _localStorageService: LocalStorageService) {
    super(injector);
    this.isTpo = this.applicationContext.isTpo;
    this.srcUrl = this.srcUrl + `?baseUrl=${this._environmentService.apiInfo.apiBaseUrl}&appId=${this.applicationContext.application.applicationId}&token=${this._localStorageService.authorizationData.token}`;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }
}
