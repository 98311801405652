<form #upsertLoanStatusForm="ngForm" ngbAutofocus class="row">
    <div class="p-3">
        <div class="mb-3">
            <label class="control-label">Loan Status</label>
            <input class="form-control"
                [ngClass]="{ 'is-invalid' : loanStatusName && loanStatusName.touched && (loanStatusName.invalid || isDuplicated()) }"
                name="loanStatusName" [(ngModel)]="loanStatus.loanStatusName" #loanStatusName="ngModel" required />
            <div class="invalid-feedback" *ngIf="loanStatusName && loanStatusName.touched && loanStatusName.invalid">
                Loan status name is required.</div>
            <div class="invalid-feedback" *ngIf="isDuplicated()">{{loanStatus.loanStatusName}} already exists.</div>
        </div>
        <div *ngIf="enabledChannels.length" class="mb-3">
            <label class="control-label">Channels</label>
            <ng-select width="100%" name="channels" bindLabel="text" bindValue="id" placeholder="Select Channels"
                [multiple]="true" [closeOnSelect]="false" [(ngModel)]="multiselectChannelsModel"
                [items]="multiselectChannels">
            </ng-select>
        </div>
        <div class="mb-3">
            <label class="control-label">Select By Role</label>
            <ng-select width="100%" name="selectByRole" bindLabel="text" bindValue="id" class="custom-ng-select"
                [(ngModel)]="multiselectSelectByRoleModel" placeholder="Select Roles" [closeOnSelect]="false"
                [multiple]="true" [items]="multiselectSelectByRole">
            </ng-select>
        </div>
        <div class="mb-3">
            <label class="control-label">Loan Stage (for pipeline)</label>
            <select class="form-select" name="loanStage" id="loanStage" [(ngModel)]="loanStatus.loanStage"
                placeholder="">
                <option value="">--Select One--</option>
                <ng-container *ngFor="let lsg of loanStages | groupBy: 'groupName'">
                    <optgroup label="{{lsg.key}}">
                        <option *ngFor="let ls of lsg.value; let i = index" value="{{ls.value}}">
                            {{ls.name}}
                        </option>
                    </optgroup>
                </ng-container>
            </select>
        </div>
        <div class="mb-3">
            <label class="control-label">Email/Sms From Role</label>
            <select class="form-select" name="emailFromRoleId" [(ngModel)]="loanStatus.emailFromRoleId">
                <option value="">-- Select One --</option>
                <option *ngFor="let role of roles" value="{{role.roleId}}">{{role.roleName}}</option>
            </select>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="form-check form-switch form-switch-success">
                            <input class="form-check-input" type="checkbox" name="showAsArchive" id="showAsArchive"
                                [(ngModel)]="loanStatus.showAsArchive">
                            <label class="form-check-label" for="showAsArchive">Show As Archive</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-check form-switch form-switch-success">
                            <input class="form-check-input" type="checkbox" name="hideFromCounter" id="hideFromCounter"
                                [(ngModel)]="loanStatus.hideFromCounter">
                            <label class="form-check-label" for="hideFromCounter">Hide From Counter</label>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6" *ngIf="isSuperAdmin">
                        <div class="form-check form-switch form-switch-success">
                            <input class="form-check-input" type="checkbox" name="isUsedForBilling"
                                id="isUsedForBilling" [(ngModel)]="loanStatus.isUsedForBilling">
                            <label class="form-check-label" for="isUsedForBilling">Used For Billing</label>
                        </div>
                    </div>
                  <div class="col-lg-5 col-md-6">
                    <div class="form-check form-switch form-switch-success">
                      <input class="form-check-input" type="checkbox" name="removeFromAllDialCampaigns" id="removeFromAllDialCampaigns"
                             [(ngModel)]="loanStatus.removeFromAllDialCampaigns">
                      <label class="form-check-label" for="removeFromAllDialCampaigns">Remove From All Dial Campaigns</label>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="text-end">
    <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
        Close
    </button>
    <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" [disabled]="saving">
        <span class="spinner-border spinner-border-sm" role="status" *ngIf="saving"></span>
        Save
    </button>
</div>
