<div class="modal-header">
  <h5 class="modal-title">Search Again </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body" id="newApplicationDialog">
  <div class="row">
      <div class="col-md-12 mb-3">
        <label class="col-sm-8 control-label">First Name</label>
        <input class="form-control" name="firstName"
          [(ngModel)]="firstName"/>
      </div>
      <div class="col-md-12 mb-3">
        <label class="col-sm-8 control-label">Last Name</label>
        <input class="form-control" name="lastName"
          [(ngModel)]="lastName"/>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" type="button" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-primary" type="button" (click)="onSearch()" [disabled]="!firstName && !lastName">Search</button>
</div>
