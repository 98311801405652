import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'video-recorder',
  templateUrl: 'video-recorder.component.html',
  styleUrls: ['video-recorder.component.scss'],
})
export class VideoRecorderComponent implements OnInit, OnDestroy {

  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('video') videoElementRef: ElementRef;

  @Output()
  videoSelectedForUse: EventEmitter<Blob[]> = new EventEmitter<Blob[]>();

  videoElement: HTMLVideoElement;
  recordVideoElement: HTMLVideoElement;

  recordedBlobs: Blob[];
  downloadUrl: string;
  loadComplete: boolean = false;
  error: string = null;

  protected isRecording: boolean = false;
  protected countingDownBeforeRecording: boolean = false;
  protected inPreviewMode: boolean = false;

  protected counter: number = 3;

  private _mediaRecorder: MediaRecorder;
  private _stream: MediaStream;
  private _counterMax: number = 3;
  private _mimeType: string = 'video/webm';
  private _options: any = { mimeType: this._mimeType, audio: true };

  constructor() {
  }

  async ngOnInit() {
    navigator.mediaDevices
      .getUserMedia({
        video: {},
        audio: true
      })
      .then((stream) => {
        this.videoElement = this.videoElementRef.nativeElement;
        this.recordVideoElement = this.recordVideoElementRef.nativeElement;

        this._stream = stream;
        this.videoElement.srcObject = this._stream;
        this._mediaRecorder = new MediaRecorder(this._stream, this._options);
        this.loadComplete = true;
      }).catch((err) => {
        console.log(err);
        this.error = err.message;
      });
  }

  ngOnDestroy(): void {
    if (this._stream) {
      this._stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  onCancelClicked() {
    this.countingDownBeforeRecording = false;
    this.counter = this._counterMax;
  }

  onStartRecordingCicked() {
    this.countingDownBeforeRecording = true;
    const intervalForTimer: NodeJS.Timeout = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(intervalForTimer);
        this.startRecording();
        this.countingDownBeforeRecording = false;
      }
    }, 1000);
  }

  onVideoSelectedForUse() {
    this.videoSelectedForUse.emit(this.recordedBlobs);
  }

  stopRecording() {
    this.counter = this._counterMax;
    this._mediaRecorder.stop();
    this.isRecording = !this.isRecording;
    this.inPreviewMode = true;
    console.log('Recorded Blobs: ', this.recordedBlobs);
  }

  goBackToRecording() {
    this._mediaRecorder = new MediaRecorder(this._stream, this._options);
    this.inPreviewMode = false;
  }

  private startRecording() {
    this.recordedBlobs = [];
    try {
      this._mediaRecorder.start(); // collect 100ms of data
      this.isRecording = !this.isRecording;
      this.subscribeToDataAvailableEvent();
      this.subscribeToStopRecordingEvent();
    } catch (err) {
      console.log(err);
      this.error = err.message;
    }
  }

  private subscribeToDataAvailableEvent() {
    try {
      this._mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  private subscribeToStopRecordingEvent() {
    try {
      this._mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: this._mimeType,
        });
        this.downloadUrl = window.URL.createObjectURL(videoBuffer); // you can download with <a> tag
        this.recordVideoElement.src = this.downloadUrl;
      };
    } catch (error) {
      console.log(error);
    }
  }
}
