<div class="container">
  <div class="row mt-1" *ngIf="scope === 'Company'">
    <div class="col-md-12 ">
      <div class="form-check form-check-inline" *ngIf="rockConnection">
        <input
          id="rockConnectionEnabled"
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="rockConnection.active">
        <label class="form-check-label" for="rockConnectionEnabled">Rock Connections Enabled</label>
      </div>
      <div class="form-check form-check-inline ms-4" *ngIf="transUnionConfig">
        <input
          id="TransUnionEnabled"
          class="form-check-input"
          type="checkbox"
          ToggleValue
          [(ngModel)]="transUnionConfig.transunionImportEnabled.value"
          [true]="1"
          [false]="0">
        <label class="form-check-label" for="TransUnionEnabled">Trans Union Enabled</label>
      </div>
    </div>
    <div class="col-md-12">
      <hr>
    </div>
  </div>
  <div class="row" *ngIf="rockConnection?.active">
    <div class="col-md-12">
      <h4>RockConnection</h4>
      <hr class="dashed">
    </div>
    <div class="col-md-12 gx-5">
      <rock-connection-config-editor #rockConnectionComponent [rockConnection]="rockConnection">
      </rock-connection-config-editor>
    </div>
  </div>

  <div class="row" *ngIf="!rockConnection?.active">
    <div class="col-md-12 gx-5">
      <h4 class="fw-light">
        Please, enable RockConnection above!
      </h4>
    </div>
  </div>
  <div class="row" *ngIf="transUnionConfig.transunionImportEnabled.value === 1 && scope === 'Company'">
    <div class="col-md-12"  >
      <h4>Transunion</h4>
      <hr class="dashed">
    </div>
    <div class="row">
      <div class="col-md-12 gx-5">
        <trans-union-config-editor #transUnionComponent [transUnionConfig]="transUnionConfig">
        </trans-union-config-editor>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="transUnionConfig.transunionImportEnabled.value === 0">
    <div class="col-md-12 gx-5">
      <h4 class="fw-light">
        Please, enable Transunion above!
      </h4>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-md-12">
      <div class="text-end">
        <button class="btn btn-primary mx-4" (click)="onSaveClicked()">
          <i class="fas fa-save me-1"></i>Save</button>
      </div>
    </div>
  </div>
</div>


