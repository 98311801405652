import { MiQuoteDetail } from "src/app/models/mi-quote-search-result.model";
import { ProductSearchRequest } from "./product-search-request-info.model";
import { PricingRateCreditApplicationMethod } from "./rate.model";
import { ChannelEnum } from "src/app/models";

export class AssignedProductPrice {
  request: ProductSearchRequest;
  pricingEngineVendor: string;
  channelId: number; //
  productId: number;
  rate: number;
  price: number;
  lockTerm: number;
  rateCreditApplicationMethod: PricingRateCreditApplicationMethod;
  miQuote: MiQuoteDetail;
  optimalBlueOriginatorId	: number;
  optimalBlueChannelId: number;
  credentialId: number;
  applicationId: number;
  pricingChannelId: string;
  tpoOrgId: string;
  wholesalePriceGroup: string;
  correspondentPriceGroup: string;
  nonDelegatedPriceGroup: string;
  channel: ChannelEnum;
  autoCreateLosLoan: boolean;
}
