import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, LoanApplication } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { ChangeOfCircumstanceDetail, ChangeOfCircumstanceStatus } from '../../disclosure-tracking/models/disclosure-tracking.model';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';
import { CocReasonsService } from '../coc-details.service';
import { MortgageModelFieldsConfig } from 'src/app/shared/services/mortgage-field-validation-utils';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'app-coc-reasons',
  templateUrl: './coc-reasons.component.html',
  styleUrls: ['./coc-reasons.component.scss']
})
export class CocReasonsComponent implements OnChanges, OnDestroy {
  
  @Input()
  disclosureTrackingId?: number;

  @Input()
  loanDocTask?: LoanDocDashboardTask;

  @Input()
  readonly?: boolean;

  @Input()
  mortgageId: number;

  @Input()
  hostedInModal: boolean = false;

  @Input()
  urlaFieldsConfig: MortgageModelFieldsConfig;

  mortgage: UrlaMortgage;
  application: LoanApplication;

  cocDetailsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }
  selectedCocDetails: ChangeOfCircumstanceDetail;
  changeOfCircumstances: ChangeOfCircumstanceDetail[] = [];

  componentId: number;
  appId: number;

  drawerName: string;

  submitButtonEnabled: boolean = false;

  private _baseChangeOfCircumstances: ChangeOfCircumstanceDetail[] = [];

  private _contextSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly _ctxService: ApplicationContextService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _cocReasonsService: CocReasonsService,
    private readonly _drawerService: DrawerService,
  ) {
    this.componentId = Utils.getUniqueId() * (-1);
    this.drawerName = `cocDetailsDrawer_${this.componentId}`;
  }

  ngOnChanges(): void {
    this._drawerService.hide(this.drawerName);
    this._setChangeOfCoc();
  }

  ngOnDestroy(): void {
    this._contextSubscription.unsubscribe();
    this._loanInfoChangesSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.loanDocTask) {
      if (this.loanDocTask.loanDocTaskId) {
        this._getChangeOfCircumstances(this.loanDocTask.loanDocTaskId);
      }

      this.appId = this.loanDocTask.applicationId;
    }
    else {
      this._contextSubscription = this._ctxService.context.subscribe(ctx => {
        this._initialize(ctx);
      });

      this._loanInfoChangesSubscription = this._ctxService.loanInfoChanges.subscribe(ctx => {
        this._initialize(ctx);
      });
    }
  }

  onEditClicked(cocDetails: ChangeOfCircumstanceDetail) {
    this._closeDrawer();

    setTimeout(() => {
      this.selectedCocDetails = cloneDeep(cocDetails);
      this._drawerService.show(this.drawerName, 10);
    }, 100)
  }

  setStatus(status: ChangeOfCircumstanceStatus, cocDetails: ChangeOfCircumstanceDetail) {
    cocDetails.changeOfCircumstanceStatus = status;
    this.onSaveClicked(cocDetails);
  }

  onAddNewClicked() {
    this._closeDrawer();

    setTimeout(() => {
      this.selectedCocDetails = new ChangeOfCircumstanceDetail();
      this.selectedCocDetails.loanDocTaskId = this.loanDocTask?.loanDocTaskId || null;
      this._drawerService.show(this.drawerName, 10);
    }, 100)
  }

  onSubmitCoCsClicked() {
    if (this.loanDocTask.taskStatus == "ReviewReady") {
      const haveRejectedCoC = this.changeOfCircumstances.some(coc => coc.changeOfCircumstanceStatus == ChangeOfCircumstanceStatus.Rejected);
      if (haveRejectedCoC) {
        this.loanDocTask.taskStatus = "Pending";
      } else {
        this.loanDocTask.taskStatus = "Completed";
      }
    } else if (this.loanDocTask.taskStatus == "Pending") {
      if (!!this.loanDocTask.reviewPartyId) {
        this.loanDocTask.taskStatus = "ReviewReady";
      } else {
        this.loanDocTask.taskStatus = "Approved";
      }
    }
  }

  onCocDetailsSaved(cocDetail: ChangeOfCircumstanceDetail) {
    const index = this._baseChangeOfCircumstances.findIndex(coc => coc.changeOfCircumstanceId === cocDetail.changeOfCircumstanceId);
    if (index === -1) {
      this._baseChangeOfCircumstances.push(cocDetail);
    } else {
      this._baseChangeOfCircumstances[index] = cocDetail;
    }

    this._setChangeOfCoc();
    this._closeDrawer();
  }

  onCocDetailsDrawerClosed() {
    this._closeDrawer();
  }

  private _closeDrawer() {
    this._drawerService.hide(this.drawerName, 10);
    this.selectedCocDetails = null;
  }

  onDeleteClicked(changeOfCircumstanceId: number) {
    this._spinner.show();
    this._cocReasonsService.deleteCocDetails(changeOfCircumstanceId).subscribe({
      next: () => {
        this._baseChangeOfCircumstances = this._baseChangeOfCircumstances.filter(c => c.changeOfCircumstanceId != changeOfCircumstanceId);
        this._setChangeOfCoc();

        this._notifyService.showSuccess("Change of circumstance deleted successfully.", "Success!");
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't delete change of circumstance.", "Error!")
      }
    }).add(() => {
      this._spinner.hide();
    })
  }

  private _initialize(ctx: ApplicationContext) {
    if (!ctx.application) {
      return;
    }

    this.application = ctx.application;
    this.mortgage = ctx.application.mortgageLoan;
    this.appId = this.application.applicationId;
    this.mortgageId = this.application.mortgageId;

    this.urlaFieldsConfig = this._urlaValidationService.getUrlaFieldsConfigForMortgage(this.mortgage, !!this.application.losIdentifier);

    this._getChangeOfCircumstances();
  }

  private _getChangeOfCircumstances(loanDocTaskId?: number) {
    this._spinner.show();
    let obs = loanDocTaskId ? this._cocReasonsService.getCocDetailsByTask(loanDocTaskId) : this._cocReasonsService.getCocDetails(this.application.mortgageId);
    obs.subscribe({
      next: (response) => {
        this._baseChangeOfCircumstances = [...response];
        this._setChangeOfCoc();
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load change of circumstances", "Error!");
      }
    }).add(() => {
      this._spinner.hide();
    })
  }

  private _setChangeOfCoc() {
    if (this.disclosureTrackingId) {
      this.changeOfCircumstances = this._baseChangeOfCircumstances.filter(res => res.disclosureTrackingId === this.disclosureTrackingId);
    } else {
      this.changeOfCircumstances = this._baseChangeOfCircumstances;
    }

    if (!!this.loanDocTask) {
      if (this.loanDocTask.taskStatus == "ReviewReady") {
        const haveRequestedCoC = this.changeOfCircumstances.some(coc => coc.changeOfCircumstanceStatus == ChangeOfCircumstanceStatus.Requested);
        if (haveRequestedCoC) {
          this.submitButtonEnabled = false;
        } else {
          this.submitButtonEnabled = true;
        }
      } else {
        this.submitButtonEnabled = true;
      }
    }
  }

  private onSaveClicked(cocDetails: ChangeOfCircumstanceDetail) {
    this._spinner.show();
    this._cocReasonsService.upsertCocDetails(this.mortgageId, cocDetails).subscribe({
      next: (response) => {
        this._spinner.hide();
        this._notifyService.showSuccess(
          'Change of circumstance saved successfully.',
          'Success!'
        );

        this.onCocDetailsSaved(response);
      },
      error: (error) => {
        this._spinner.hide();
        this._notifyService.showError(
          error?.message || "Couldn't upsert change of circumstance",
          "Error!"
        );
      }
    });
  }
}
