import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Key, ThirdPartyCredential } from '../../../../../../models';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { TestCredentialResultsModalComponent } from '../../los/test-credential-results-modal/test-credential-results-modal.component';
import * as _ from 'lodash';
import { ScopeType } from '../../integrations.component';

@Component({
	selector: 'credential-modal',
	templateUrl: './credential-modal.component.html'
})
export class CredentialModalComponent extends ApplicationContextBoundComponent implements OnInit {

	@ViewChild('credentialForm')
	credentialForm: NgForm | undefined;

	@Input()
	scope: ScopeType;

	userCompanyGuid?: string;
	credential: ThirdPartyCredential;
	credentialCopy: ThirdPartyCredential;
	keys: Key[];
	additionalConfigValueFieldType: string;
	hideUsername: boolean;
	hidePassword: boolean;
	hideAdditionalConfig: boolean;
	isSaving: boolean;
	isTestSaving: boolean;
	isTestingCredential: boolean;

	constructor(
		private readonly injector: Injector,
		private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
		private readonly _systemLevelService: SystemLevelService,
		private readonly _notificationService: NotificationService,
		private readonly _modalService: NgbModal,
		public activeModal: NgbActiveModal,
	) {
		super(injector);
	}

	ngOnInit(): void {
		this.credentialCopy = _.cloneDeep(this.credential)
		this.setCredentialConfigData();
	}

	setCredentialConfigData(): void {
		this.keys = this._thirdPartyCredentialsService.getAdditionalConfigKeys(this.credential);
		this.additionalConfigValueFieldType = this._thirdPartyCredentialsService.getAdditionConfigValueFieldType(this.credential);
		this.hideUsername = this._thirdPartyCredentialsService.hideUsername(this.credential);
		this.hidePassword = this._thirdPartyCredentialsService.hidePassword(this.credential);
		this.hideAdditionalConfig = this._thirdPartyCredentialsService.hideAdditionalConfig(this.credential);
	}

	addConfig(): void {
		this.credential.thirdPartyKeyValuePairs.push({
			key: '',
			value: '',
			alias: '',
			userName: '',
			password: ''
		})
	}

	saveCredential(): void {
		this.credentialForm.form.markAllAsTouched();
		if (!this.credentialForm.form.valid) return;

		this.isSaving = true;
		this.credential.companyId = this.applicationContext.userPermissions.companyId;
		this._systemLevelService.saveCredential(this.credential)
			.pipe(finalize(() => {
				this.isSaving = false;
			})).subscribe({
				next: res => {
					this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
					this.activeModal.close(res)
				},
				error: error => {
					console.log(error)
					this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
				}
			});
	}

	saveTestCredential() {
		this.credentialForm.form.markAllAsTouched();
		if (!this.credentialForm.form.valid) {
			return;
		}

		this.isTestSaving = true;
		this.credential.companyId = this.applicationContext.userPermissions.companyId;
		this._systemLevelService.saveCredential(this.credential)
			.pipe(finalize(() => {
				this.isTestSaving = false;
			})).subscribe({
				next: res => {
					this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
					this.credentialCopy = _.cloneDeep(this.credential);
					this.credential = res;
				},
				error: error => {
					console.log(error)
					this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
				}
			});
	}

	onChangeVendor(): void {
		this.setCredentialConfigData();
		this.credential.thirdPartyKeyValuePairs = [];
	}

	onTestCredentials() {
		if (_.isEqual(this.credential, this.credentialCopy)) {
			this.testCredential()
		} else {
			this._notificationService.showInfo("Please save credentials before testing", "System Level");
		}
	}

	testCredential(): void {
		let body = {
			vendorName: this.credential.vendorName,
			userName: this.credential.userName,
			credentialId: this.credential.credentialId,
		};
		if (this.credential.vendorName === 'EncompassApi' || this.credential.vendorName === 'MortgageDirector') {
			Object.assign(body, { password: this.credential.password })
		}
		this.isTestingCredential = true;
		this._systemLevelService
			.testLosCredential(body)
			.subscribe({
				next: res => {
					const modalRef = this._modalService.open(TestCredentialResultsModalComponent);
					modalRef.componentInstance.testLosUser = res;
					this.isTestingCredential = false;
				},
				error: error => {
					this._notificationService.showError(
						error ? error.error && error.error.message ? error.error.message : error.error : `Unable to test ${this.credential.vendorName} credentials.`,
						'LOS Config'
					);
					this.isTestingCredential = false;
				}
			});
	}
}
