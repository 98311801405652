import { Component, Input, OnInit } from '@angular/core';
import { MessageHistoryItem } from 'src/app/models/message.model';

@Component({
  selector: 'message-status',
  templateUrl: 'message-status.component.html',
  styleUrls: ['message-status.component.scss']
})

export class MessageStatusComponent implements OnInit {

  @Input()
  message: MessageHistoryItem;

  constructor() { }

  ngOnInit() {
    const x = 10;
   }
}
