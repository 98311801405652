import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppraisalComponent } from './components/appraisal/appraisal.component';
import { AppraisalHistoryComponent } from './components/appraisal-history/appraisal-history.component';
import { AppraisalTabComponent } from './components/appraisal-tab/appraisal-tab.component';
import { AppraisalDocsComponent } from './components/appraisal-docs/appraisal-docs.component';
import { AppraisalNotesComponent } from './components/appraisal-notes/appraisal-notes.component';
import { AddAppraisalNoteDialogComponent } from './components/add-appraisal-note-dialog/add-appraisal-note-dialog.component';
import { AppraisalConditionsComponent } from './components/appraisal-conditions/appraisal-conditions.component';
import { AddAppraisalConditionDialogComponent } from './components/add-appraisal-condition-dialog/add-appraisal-condition-dialog.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReggoraModule } from '../reggora/reggora.module';
import { AppraisalManagementComponent } from './components/appraisal-management/appraisal-management.component';

@NgModule({
  imports: [SharedModule, MultiSelectModule, NgSelectModule, ReggoraModule],
  declarations: [AppraisalComponent, AppraisalHistoryComponent, AppraisalTabComponent, AppraisalDocsComponent, 
    AppraisalNotesComponent, AddAppraisalNoteDialogComponent, AppraisalConditionsComponent, 
    AddAppraisalConditionDialogComponent, AppraisalManagementComponent],
  exports: [AppraisalComponent, AppraisalHistoryComponent, AppraisalTabComponent, AppraisalManagementComponent]
})
export class AppraisalModule { }
