import { Component, Input, OnInit } from '@angular/core';
import { MortgageBorrower } from 'src/app/models';
import { DocPrep } from '../../models/doc-prep.model';
import Swal from 'sweetalert2';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { TransactionPartyType } from '../../models/transaction-party-type.enum';

@Component({
  selector: 'document-preparation-borrowers-v2',
  templateUrl: 'document-preparation-borrowers-v2.component.html',
  styleUrls: ['./document-preparation-borrowers-v2.component.scss']
})

export class DocumentPreparationBorrowersV2Component implements OnInit {

  @Input()
  docPrep: DocPrep;

  @Input()
  borrowerTypes: EnumerationItem[] = [];

  @Input()
  borrowerRoles: EnumerationItem[] = [];

  @Input()
  entityTypes: EnumerationItem[] = [];

  @Input()
  suffixes: EnumerationItem[] = [];

  @Input()
  maritalStatuses: EnumerationItem[] = [];
  
  constructor() { }

  ngOnInit() { }

  protected onAddBorrowerClicked = () => {
    const newBorrower = new MortgageBorrower();
    newBorrower.mortgagePartyType = TransactionPartyType.Individual;
    newBorrower.mortgageId = this.docPrep.mortgageId;
    this.docPrep.borrowers.push(newBorrower);
  }

  protected onDeleteBorrowerClicked = (borrower: MortgageBorrower) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this borrower?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self.docPrep.borrowers.indexOf(borrower);
        if (index >= 0) {
          self.docPrep.borrowers.splice(index, 1);
        }
      }
    });
  }
}