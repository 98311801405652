import { Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CampaignService } from 'src/app/modules/campaign/Services/campaign.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { TransUnionConfiguration } from '../cloud-platform-config-editor/cloud-platform-config-editor.component';
import { CampaignModel } from 'src/app/models/campaign/campaign.model';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/services/notification.service';
import { NgForm } from '@angular/forms';
import { ContactListService } from 'src/app/services/contact-list.service';
import { ContactListType } from 'src/app/models';

@Component({
  selector: 'trans-union-config-editor',
  templateUrl: './trans-union-config-editor.component.html',
  styleUrls: ['./trans-union-config-editor.component.scss']
})
export class TransUnionConfigEditorComponent extends ApplicationContextBoundComponent {

  @Input() transUnionConfig: TransUnionConfiguration;

  @ViewChild("loanType")
  loanTypeComponent: ElementRef<HTMLSelectElement>;

  @ViewChild("loanPurpose")
  loanPurposeComponent: ElementRef<HTMLSelectElement>;

  @ViewChild("transUnionConfigForm")
  transUnionConfigForm: NgForm;

  contactListwithLead: ContactListType[] = [];
  enabledChannels: EnumerationItem[] = [];
  loanPurposes: LoanPurpose[] = [];
  loanTypes: LoanType[] = [];
  leadCampaigns: CampaignModel[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _campaignService: CampaignService,
    private readonly _notifyService: NotificationService,
    private readonly _contactListService: ContactListService
  ) {
    super(injector);
    this.applicationContextService.context.subscribe(context => {
      this.loanPurposes = context.globalConfig.loanPurpose;
      this.loanTypes = context.globalConfig.loanType;
      this.enabledChannels = context.globalConfig.enabledChannels;
      this.initializeLeadCampaignDropdown();
    });
  }

  validate = (): boolean => {
    if (this.transUnionConfigForm) {
      this.transUnionConfigForm.form.markAllAsTouched();
      return this.transUnionConfigForm.form.valid;
    }
    return false;
  }

  private initializeLeadCampaignDropdown = () => {
    this._contactListService.getContactList().subscribe({
      next: (contactLists) => {
        const leadContactLists = contactLists.filter((contact) => contact.contactListType === ContactListType.Lead);
        this._campaignService.getCampaigns().subscribe({
          next: (campaigns) => {
            campaigns.forEach(campaign => {
              const isLeadCampaign = !!leadContactLists.find(cl => cl.contactListId == campaign.contactListId);
              if (isLeadCampaign) {
                this.leadCampaigns.push(campaign)
              }
            })
            this.leadCampaigns = _.orderBy(this.leadCampaigns, ['name'], ['asc']);
          },
          error: (error => {
            this.leadCampaigns = [];
            this._notifyService.showError(
              error?.message || 'Error updating the campaign status',
              'Error'
            );
          })
        })
      },
      error: (error => {
        this.leadCampaigns = [];
        this._notifyService.showError(
          error?.message || 'Error updating the campaign status',
          'Error'
        );
      })
    });
  }
}
