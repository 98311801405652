import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DataService } from '../core/services/data.service';
import { Utils } from '../core/services/utils';
import { CredentialInfo, CredentialKvpSchema, CredentialScopeType, CreditVendorEnum, Key, ThirdPartyCredential, ThirdPartyCredentialArray, ThirdPartyCredentialSchemaReadonlyArray, ThirdPartyCredentialType, UserThirdPartyCredential } from '../models';
import { EnumerationItem } from '../models/simple-enum-item.model';
import { ScopeType } from '../modules/system-level/components/integrations/integrations.component';

@Injectable()
export class ThirdPartyCredentialsService {
  constructor(private readonly _dataService: DataService) { }

  public getVendors = (credential: ThirdPartyCredential): EnumerationItem[] => {
    let vendors: EnumerationItem[];
    switch (credential.credentialType) {
      case 'LosVendor':
        vendors = [
          { name: 'Encompass SDK', value: 'Encompass' },
          { name: 'Encompass API', value: 'EncompassApi' },
          { name: 'Open Close', value: 'OpenClose' },
          { name: 'Mortgage Director', value: 'MortgageDirector' },
          { name: 'Calyx Point', value: 'CalyxPoint' },
        ];
        break;
      case 'Disclosure':
        vendors = [
          { name: 'Doc Magic', value: 'DocMagic' },
        ];
        break;
      case 'Title':
        vendors = [
          { name: 'Doma', value: 'StatesTitle'},
          { name: 'SoftPro', value: 'SoftPro'},
        ];
        break;
      case 'AppraisalVendor':
        vendors = [
          { name: 'Connexions', value: 'Connexions' },
          { name: 'Reggora', value: 'Reggora' }
        ];
        break;
      case 'OcrVendor':
        vendors = [
          { name: 'Visionet', value: 'Visionet' },
          { name: 'DDA', value: 'DDA' }
        ];
        break;
      case 'CreditVendor':
        vendors = [
          { name: 'Meridian Link Hard Pull', value: CreditVendorEnum.MeridianLinkHardPull },
          { name: 'Meridian Link Soft Pull', value: CreditVendorEnum.MeridianLinkSoftPull },
          { name: 'Xactus', value: CreditVendorEnum.Xactus },
          { name: 'CredCo Soft Pull', value: CreditVendorEnum.CredCoSoftPull },
          { name: 'CredCo Hard Pull', value: CreditVendorEnum.CredCoHardPull },
          { name: 'FactualData Soft Pull', value: CreditVendorEnum.FactualDataSoftPull },
          { name: 'FactualData Hard Pull', value: CreditVendorEnum.FactualDataHardPull },
          { name: 'Encompass Credit', value: CreditVendorEnum.EncompassCredit },
          { name: 'iSoftpull', value: CreditVendorEnum.ISoftPull }
        ];
        break;
      case 'LdeVendor':
        vendors = [
          { name: 'United Wholesale Mortgage', value: 'UnitedWholesaleMortgage' }
        ];
        break;
      case 'VOA':
        vendors = [
          { name: 'FormFree', value: 'FormFree' },
          { name: 'Truv', value: 'Truv' },
        ];
        break;
      case 'VOE/VOI':
        vendors = [
          { name: 'TWN', value: 'TWN' },
          { name: 'Truv', value: 'Truv' },
        ];
        break;
      case 'LoanBeam':
        vendors = [
          { name: 'LoanBeam', value: 'LoanBeam' }
        ];
        break;
      case 'DocumentSigning':
        vendors = [
          { name: 'DocuSign', value: 'DocuSign' },
          { name: 'DocMagic', value: 'DocMagic' }
        ];
        break;
      default:
        vendors = [
          { name: credential.vendorName, value: credential.vendorName }
        ]
        break;
    }
    return vendors;
  };

  public getVendorDisplayName = (vendorName: string): string => {
    // TODO add other vendors
    const obj = {
      'Encompass': 'Encompass SDK',
      'EncompassApi': 'Encompass API',
      'OpenClose': 'Open Close',
      'MortgageDirector': 'Mortgage Director',
      'CalyxPoint': 'Calyx Point',
    }

    return obj[vendorName] ? obj[vendorName] : Utils.spinalTapCaseString(vendorName);
  }

  public getAdditionalConfigKeys = (credential: ThirdPartyCredential): Key[] => {
    let keys: Key[];
    switch (credential.vendorName) {
      case 'Encompass':
        keys = [{ key: 'SDK_Key', displayText: 'SDK_Key' }];
        break;
      case 'EncompassApi':
        keys = [
          { key: 'ClientSecret', displayText: 'ClientSecret' },
          { key: 'InstanceId', displayText: 'InstanceId' },
          { key: 'ClientId', displayText: 'ClientId' },
          { key: 'LoanFolders', displayText: 'LoanFolders' },
          { key: 'CCSiteId', displayText: 'Consumer Connect Site Id' },
        ];
        break;
      case 'OpenClose':
        keys = [
          { key: 'ClientSecret', displayText: 'ClientSecret' },
          { key: 'ClientId', displayText: 'ClientId' },
        ];
        break;
      case 'MortgageDirector':
        keys = [
          { key: 'AppId', displayText: 'AppId' },
          { key: 'AppPassword', displayText: 'AppPassword' },
          { key: 'HardCodedValue', displayText: 'HardCodedValue' },
        ];
        break;
      default:
        break;
    }
    return keys;
  };

  public getAdditionConfigValueFieldType = (credential: ThirdPartyCredential): string => {
    if (credential.credentialType === 'LosVendor') {
      return 'Password';
    } else {
      return 'Text';
    }
  }

  public hideUsername = (credential: ThirdPartyCredential): boolean => {
    if (['CalyxPoint'].includes(credential.vendorName)) {
      return true;
    } else {
      return false;
    }
  }

  public hidePassword = (credential: ThirdPartyCredential): boolean => {
    if (['OpenClose', 'CalyxPoint'].includes(credential.vendorName)) {
      return true;
    } else {
      return false;
    }
  }

  public hideAdditionalConfig = (credential: ThirdPartyCredential): boolean => {
    if (['CalyxPoint', 'Visionet'].includes(credential.vendorName)) {
      return true;
    } else {
      return false;
    }
  }

  public getNewCredential = (credentialType: string): ThirdPartyCredential => {
    return {
      credentialType: credentialType,
      vendorName: '',
      thirdPartyKeyValuePairs: [],
      active: false,
      channels: '',
      branchId: '',
      alias: '',
      url: '',
      userName: '',
      password: ''
    };
  }

  public setCredentialKeyValuePairs = (
    credential: ThirdPartyCredential,
    keyValuePairs: any
  ): ThirdPartyCredential => {
    let index;
    let keyName;
    for (let key of Object.keys(keyValuePairs)) {
      keyName = key.charAt(0).toUpperCase() + key.slice(1);
      index = credential.thirdPartyKeyValuePairs.findIndex(el => el.key === keyName);
      if (index > -1) {
        credential.thirdPartyKeyValuePairs[index] = keyValuePairs[key];
      } else {
        credential.thirdPartyKeyValuePairs.push({
          ...keyValuePairs[key],
          key: keyName,
          value: keyValuePairs[key].value || ''
        });
      }
    }
    return credential;
  }

  public filterSchemaKvPairs = (scope: ScopeType, kvp: CredentialKvpSchema[]): CredentialKvpSchema[] => {
    if (!kvp) return [];

    return kvp.filter(kv => {
      if (kv.scope === "CompanyAndUser") return true;

      if (scope === "Company") {
        return kv.scope === "Company";
      }
      else if (scope === "TpoUser") {
        return kv.scope === "TpoUser";
      }
      else {
        return kv.scope === "User";
      }
    })
  }

  getDocumentSigningCredentials = (companyId: number): Observable<ThirdPartyCredential> => {
    const url = `api/third-party-credential/credentialType/DocumentSigning/vendorName/DocuSign?companyId=${companyId}`
    return this._dataService.get(url)
  }

  updateCredential = (credential: ThirdPartyCredential) => {
    const url = `api/third-party-credential/${credential.credentialId}`
    return this._dataService.put(url, credential)
  }

  // new credentials api
  getCredentialSchemas = (credentialType: ThirdPartyCredentialType, scopeFilter: ScopeType): Observable<ThirdPartyCredentialSchemaReadonlyArray> => {
    const url = `api/third-party-credential/schema?credentialType=${credentialType}&scope=${scopeFilter}`
    return this._dataService.get(url)
  }

  getCredentialsForCompany = (credentialType: ThirdPartyCredentialType): Observable<ThirdPartyCredentialArray> => {
    const url = `api/third-party-credential/company?credentialType=${credentialType}`
    return this._dataService.get(url)
  }

  getCredentialVendorNamesForCompany = (credentialType: ThirdPartyCredentialType): Observable<string> => {
    const url = `api/third-party-credential/company/vendor-names?credentialType=${credentialType}`
    return this._dataService.get(url)
  }

  upsertCredentialsForCompany = (credential: ThirdPartyCredential): Observable<ThirdPartyCredential> => {
    const url = `api/third-party-credential/company`;
    return this._dataService.post(url, credential);
  }

  deleteCredentialsForCompany = (credentialId: number): Observable<any> => {
    const url = `api/third-party-credential/company/${credentialId}`;
    return this._dataService.delete(url);
  }

  getCredentialsForUser = (credentialType: ThirdPartyCredentialType, userId?: string): Observable<UserThirdPartyCredential> => {
    let queryParams = `credentialType=${credentialType}`;
    if (userId) {
      queryParams += `&userId=${userId}`;
    }

    const url = `api/third-party-credential/user?${queryParams}`
    return this._dataService.get(url)
  }

  insertCredentialsForUser = (credential: ThirdPartyCredential, companyCredentialId: number): Observable<ThirdPartyCredential> => {
    const url = `api/third-party-credential/user/${companyCredentialId}`;
    return this._dataService.put(url, credential);
  }

  updateCredentialsForUser = (credential: ThirdPartyCredential): Observable<ThirdPartyCredential> => {
    const url = `api/third-party-credential/user/${credential.credentialId}`;
    return this._dataService.post(url, credential);
  }

  deleteCredentialsForUser = (credentialId: number): Observable<any> => {
    const url = `api/third-party-credential/user/${credentialId}`;
    return this._dataService.delete(url);
  }

  updateThirdPartyCredentialsForBranch(credential: ThirdPartyCredential): Observable<ThirdPartyCredential[]> {
    return this._dataService.post(
      `api/third-party-credential/branch`,
      credential
    );
  }

  getThirdPartyCredentialsForBranch(branchId: number, credentialType: ThirdPartyCredentialType): Observable<ThirdPartyCredential[]> {
    return this._dataService.get(
      `api/third-party-credential/branch?branchId=${branchId}&credentialType=${credentialType}`,
    );
  }

  getCredentialsByVendor = (credentialType: ThirdPartyCredentialType, vendorName: string, userId?: string, companyId?: number): Observable<ThirdPartyCredential> => {
    let queryParams = [];
    if (userId) {
      queryParams.push("userId=" + userId);
    }
    if (companyId) {
      queryParams.push("companyId=" + companyId);
    }

    let url = `api/third-party-credential/credentialType/${credentialType}/vendorName/${vendorName}`
    if (queryParams.length) {
      url += "?" + queryParams.join("&");
    }
    return this._dataService.get(url)
  }

  getCredentialsForUserCompany(credentialType: ThirdPartyCredentialType): Observable<CredentialInfo[]> {
    return this._dataService.get(`api/third-party-credential/user-company?credentialType=${credentialType}`);
  }
}
