import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IDateRange } from '../date-range-filter/date-range-filter.component';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'generic-filter',
  templateUrl: 'generic-filter.component.html',
  styleUrls: ['generic-filter.component.scss']
})
export class GenericFilterComponent implements OnInit, OnChanges {

  @Input()
  set label(label: string) {
    this._originalLabel = label;
    this.setLabel();
  }

  get label() {
    return this._label;
  }

  @Input()
  icon: string;

  @Input()
  filterHeader: string;

  @Input()
  selected: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  showMoreFilterOptions?: ShowMoreFilterOptions;

  @Input()
  sortOptionsByDisplayName: boolean = true;

  @Input()
  width: number | undefined;

  @Input()
  maxWidth: number | undefined;

  @Input()
  noOptionsMessage: string = "There are no options";

  @Input()
  selectedItemsLabel: string = "Selected";

  @Input()
  searchable: boolean = false;

  @Input()
  enableSelectAll: boolean = false;

  get searchString(): string {
    return this._searchString;
  }

  set searchString(value: string) {
    this._searchString = value;

    const { _originalFilterOptions } = this;

    this._filterOptions = value
      ? _originalFilterOptions.filter(({ isSelected, displayName }) =>
        isSelected || displayName.toLowerCase().includes(value.toLowerCase()))
      : _originalFilterOptions;
  }

  private _searchString: string;

  get maxSelectedOptions(): number | undefined {
    return this._maxSelectedOptions;
  }

  @Input()
  set maxSelectedOptions(value: number | undefined) {
    if (value != null) {
      if (this.type !== 'multiSelect') {
        throw new Error(
          'type must be "multiSelect" to use maxSelectedOptions'
        );
      }

      if (!Number.isSafeInteger(value) || value <= 0) {
        throw new Error(
          'if supplied, maxSelectedOptions must be a positive integer',
        );
      }
    }

    this._maxSelectedOptions = value;
  }

  private _maxSelectedOptions: number | undefined;

  private _originalLabel: string;
  private _label: string;

  @Input()
  set filterOptions(filterOptions: FilterOption[]) {
    this._originalFilterOptions = filterOptions;
    this._filterOptions = filterOptions;
    this.setLabel();
  }

  get selectedOptions(): FilterOption[] {
    return this._originalFilterOptions.filter(({ isSelected }) => isSelected);
  }

  @Input()
  set selectedOptions(selectedOptions: FilterOption[]) {
    this._originalFilterOptions.forEach(o => {
      const withinSelectedOptions = selectedOptions.find(so => so.value == o.value);
      o.isSelected = !!withinSelectedOptions;
    })
    this.setLabel();
    // reset the selected options in the setter
    this.searchString = this.searchString;
  }

  get filterOptions() {
    return this._filterOptions;
  }

  private _originalFilterOptions: FilterOption[] = [];
  private _filterOptions: FilterOption[] = [];

  @Input()
  type: string = "multiSelect"

  @Output()
  filtersChanged: EventEmitter<FilterOption[]> = new EventEmitter<FilterOption[]>();

  showMore: boolean = false;
  isAllBasedSelected: boolean = false;
  isAllOpositeSelected: boolean = false;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _notificationService: NotificationService,
  ) { }

  @HostListener('document:click', ['$event'])
  onClickedOutside = (event: any) => {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.selected = false;
    }
  }

  ngOnInit() { }

  ngOnChanges() {
    if (this.showMoreFilterOptions?.allowSelectAll) {
      this.setSelectAllValue();
    }
  }

  clear = () => {
    this._label = this._originalLabel;
    this._filterOptions.forEach(o => {
      o.isSelected = false;
    });
  }

  updateBadges = (updatedBadges: FilterOptionBadge[]) => {
    updatedBadges.forEach(b => {
      const option = this._filterOptions.find(o => o.value == b.value);
      if (option) {
        option.badge = b.badge;
      }
    });
  }

  onClicked = () => {
    this.selected = !this.selected;
  }

  onClearAllClicked = () => {
    this._filterOptions.forEach(o => {
      o.isSelected = false;
    });
    this._label = this._originalLabel;
    this.filtersChanged.emit([]);

    this.searchString = "";
  }

  onSelectAllButtonClicked = () => {
    this._filterOptions = this._originalFilterOptions;
    this._filterOptions.forEach(o => {
      o.isSelected = true;
    });
    this._label = this._originalLabel;
    this.filtersChanged.emit([]);

    this.searchString = "";
  }

  onSelectAllClicked = (baseFilter?: boolean, opositeFilter?: boolean) => {
    // select all if no filter is set
    if (!this.showMoreFilterOptions.baseOptionsFilter && !this.showMoreFilterOptions.opositeOptionsFilter) {
      this.filterOptions.forEach(o => o.isSelected = this.isAllBasedSelected);
    }
    // select all if any of filters is set
    else {
      if (baseFilter) {
        if (this.showMoreFilterOptions.baseOptionsFilter) {
          const key = Object.keys(this.showMoreFilterOptions.baseOptionsFilter)[0];
          const value = Object.values(this.showMoreFilterOptions.baseOptionsFilter)[0];
          this.filterOptions.filter(o => o[key] === value).forEach(o => o.isSelected = this.isAllBasedSelected);
        }
      }

      if (opositeFilter) {
        if (this.showMoreFilterOptions.opositeOptionsFilter) {
          const key = Object.keys(this.showMoreFilterOptions.opositeOptionsFilter)[0];
          const value = Object.values(this.showMoreFilterOptions.opositeOptionsFilter)[0];
          this.filterOptions.filter(o => o[key] === value).forEach(o => o.isSelected = this.isAllOpositeSelected);
        }
      }
    }

    const selectedOptions: FilterOption[] = this._filterOptions.filter(o => o.isSelected);

    this.setLabel();
    this.filtersChanged.emit(selectedOptions);
  }

  private canSelectNewOption() {
    const { maxSelectedOptions } = this;

    if (maxSelectedOptions == null) return true;

    return this.selectedOptions.length < maxSelectedOptions;
  }

  private canToggleOption(filterOption: FilterOption) {
    return filterOption.isSelected ? true : this.canSelectNewOption();
  }

  onCheckChanged = (event: Event, filterOption: FilterOption) => {
    let selectedOptions: FilterOption[] = [];

    if (!this.canToggleOption(filterOption)) {
      event.preventDefault();

      this._notificationService.showError(
        `Up to ${this.maxSelectedOptions} options can be selected.`,
        'Error',
      );

      return;
    }
    filterOption.isSelected = !filterOption.isSelected;

    if (this.type === 'singleSelect') {
      const otherOptions = this.filterOptions.filter(o => o.value != filterOption.value);
      otherOptions.forEach(o => o.isSelected = false);
    }
    selectedOptions = this._filterOptions.filter(o => o.isSelected);

    if (this.showMoreFilterOptions?.allowSelectAll) {
      this.handleSelectAllChange();
    }

    this.setLabel();
    this.filtersChanged.emit(selectedOptions);
  }

  toggleShowMore = () => {
    this.showMore = !this.showMore;
  }

  private setLabel = () => {
    const selectedOptions = this.selectedOptions;

    if (selectedOptions.length === 1) {
      this._label = selectedOptions[0].displayName;
    } else if (selectedOptions.length >= 2) {
      this._label = selectedOptions.length + " " + this.selectedItemsLabel;
    } else {
      this._label = this._originalLabel;
    }
  }

  private setSelectAllValue = () => {
    if (this.showMoreFilterOptions.baseOptionsFilter) {
      const key = Object.keys(this.showMoreFilterOptions.baseOptionsFilter)[0];
      const value = Object.values(this.showMoreFilterOptions.baseOptionsFilter)[0];
      const filteredValues = this.filterOptions.filter(o => o[key] === value)
      this.isAllBasedSelected = filteredValues.length > 0 && filteredValues.every(o => o.isSelected);
    }

    if (this.showMoreFilterOptions.opositeOptionsFilter) {
      const key = Object.keys(this.showMoreFilterOptions.opositeOptionsFilter)[0];
      const value = Object.values(this.showMoreFilterOptions.opositeOptionsFilter)[0];
      const filteredValues = this.filterOptions.filter(o => o[key] === value)
      this.isAllOpositeSelected = filteredValues.length > 0 && filteredValues.every(o => o.isSelected);
    }

    if (!this.showMoreFilterOptions.baseOptionsFilter && !this.showMoreFilterOptions.opositeOptionsFilter) {
      this.isAllBasedSelected = this.filterOptions.length > 0 && this.filterOptions.every(o => o.isSelected);
    }
  }

  private handleSelectAllChange = () => {
    this.setSelectAllValue();
  }
}

export class FilterOption {
  displayName: string;
  value: any;
  badge?: string | number;
  groupName?: string;
  isSelected?: boolean = false;
  isDisabled?: boolean = false;
  active?: boolean = false;
  dateRange?: IDateRange;
  displayHtml?: string | null;

  constructor({ displayName, value, groupName, badge, dateRange, active, isSelected }: FilterOption) {
    this.displayName = displayName;
    this.value = value;
    this.groupName = groupName || null;
    this.badge = badge || null;
    this.dateRange = dateRange || null;
    this.active = !!active;
    this.isSelected = !!isSelected;
  }
}

export class FilterOptionBadge {
  value: any;
  badge: string;

  constructor(value: any, badge: string) {
    this.value = value;
    this.badge = badge;
  }
}

export class ShowMoreFilterOptions {
  label?: string = ""
  baseOptionsFilter?: {
    [key: string]: string | boolean
  }
  opositeOptionsFilter?: {
    [key: string]: string | boolean
  }
  allowSelectAll?: boolean = false
}
