import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { v4 as uuidv4 } from 'uuid';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { PricingService } from '../../services/pricing.service';

@Component({
  selector: 'pinned-scenario-card',
  templateUrl: 'pinned-scenario-card.component.html',
  styleUrls: ['./pinned-scenario-card.component.scss']
})
export class PinnedScenarioCardComponent implements OnInit, AfterViewInit {

  @Output()
  createLoanClicked = new EventEmitter<number>();

  @Output()
  deleteScenarioClicked = new EventEmitter<number>();

  @Output()
  loanQuoteClicked = new EventEmitter<PricingScenario>();

  @Output()
  editScenarioClicked = new EventEmitter<PricingScenario>();

  @Output()
  rateAprEditClicked = new EventEmitter<PricingScenario>();

  @Output()
  feesEditClicked = new EventEmitter<PricingScenario>();

  @Output()
  applyToLoanClicked = new EventEmitter<any>();

  @Output()
  applyScenarioToLoanClicked = new EventEmitter<PricingScenario>();

  @Output()
  refreshCompleted = new EventEmitter<PricingScenario>();

  @Input()
  isLoanReadOnly: boolean = false;

  @Input()
  scenario: PricingScenario;

  @Input()
  applicationId: number;

  @Input()
  index: number;

  @Input()
  displayExpensesOverlay: boolean = true;

  mouseCursorOnDeleteButton: boolean = false;

  history: any;

  historyOptions: any;

  spinnerName: string;

  feesTotal: number = 0;

  prepaidFeesTotal: number = 0;

  editClicked: boolean = false;

  isDrawingChart: boolean = false;
  chartId: string = '';
  chartWidth: string = '20vw';
  chartHeight: string = '20vh';

  private _originalCopyOfScenarioName: string;

  private _resizeObserver: ResizeObserver;

  private _fees: LoanFee[];

  private _scenario: PricingScenario;

  constructor(private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _feeService: FeeService,
    private readonly _pricingService: PricingService) {
    this.spinnerName = uuidv4();
    this.chartId = uuidv4();
  }

  refresh = () => {
    this._spinnerService.show(this.spinnerName);
    this._pricingService.refreshScenario(this.scenario.pricingScenarioId).subscribe({
      next: (refreshedScenario) => {
        refreshedScenario['index'] = this.index;
        this.scenario = refreshedScenario;
        this.generatePriceHistoryChart();
      }, error: (err) => {
        let errorMessage = "";
        if (err) {
          errorMessage = err.message || err.error;
        }
        errorMessage = errorMessage || "An error occurred while refreshing the scenario.";
        this._notificationService.showError(errorMessage, "Error!")
      }
    }).add(() => { this._spinnerService.hide(this.spinnerName); this.refreshCompleted.emit(this.scenario); })
  }

  ngOnInit() {
    this.generatePriceHistoryChart();
    this.calculateFeeValues();
  }

  ngAfterViewInit(): void {
    const container = document.getElementById(this.chartId);
    this._resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.chartHeight = (cr.height * .95) + "";
        this.chartWidth = (cr.width * .95) + "";

        if (!this.isDrawingChart) {
          this.reDrawChartInternal();
        }
      }
    });
    if (this._resizeObserver && container && container instanceof HTMLElement) {
      this._resizeObserver.observe(container);
    }
  }

  ngOnDestroy(): void {
    if (this._resizeObserver) {
      const container = document.getElementById(this.chartId);
      if (container) {
        this._resizeObserver.unobserve(container);
      }
    }
  }

  onCreateLoanClicked = (scenarioId: number) => {
    this.createLoanClicked.emit(scenarioId);
  }

  onApplyToLoanClicked = (scenario: PricingScenario) => {
    this.applyScenarioToLoanClicked.emit(scenario);
  }

  onDeleteScenarioClicked = (scenarioId: number) => {
    this.deleteScenarioClicked.emit(scenarioId);
  }

  onRateAprEditClicked = (scenario: PricingScenario) => {
    this.rateAprEditClicked.emit(scenario);
  }

  onFeesEditClicked = (scenario: PricingScenario) => {
    this.feesEditClicked.emit(scenario);
  }

  onFeesCopyFromLoanClicked = (scenario: PricingScenario) => {
    this._spinnerService.show();
    this._feeService.importLoanFeesToScenario(scenario.pricingScenarioId, scenario.applicationId).subscribe(loanFees => {
      this._fees = loanFees;
      this.calculateFeeTotals(loanFees);
      this._spinnerService.hide();
      this._notificationService.showSuccess("Fees copied from loan successfully.", "Success");
    }, err => {
      this._spinnerService.hide();
      this._notificationService.showError(err?.error?.message || "An error occurred while copying fees from loan to the Scenario", "Error!");
    });
  }

  onRefreshClicked = () => {
    this.refresh();
  }

  onNotesApplied = (newScenario: PricingScenario) => {
    this.scenario.borrowerNotes = newScenario.borrowerNotes;
    this.saveScenario();
  }

  onTaxesApplied = (newScenario: PricingScenario) => {
    this.scenario.monthlyTaxes = newScenario.monthlyTaxes;
    this.scenario.monthlyInsurance = newScenario.monthlyInsurance;
    this.scenario.totalPayment = newScenario.totalPayment;
    this.saveScenario();
  }

  onEditScenarioNameClicked = () => {
    this._originalCopyOfScenarioName = this.scenario.name;
    this.editClicked = true;
  }

  onSaveScenarioNameClicked = (scenario: PricingScenario) => {
    this.editScenarioClicked.emit(scenario);
    this.editClicked = false;
  }

  onCancelScenarioNameClicked = () => {
    this.scenario.name = this._originalCopyOfScenarioName;
    this.editClicked = false;
  }

  onAutoRefreshToggled = (scenario: PricingScenario) => {
    this._spinnerService.show();
    this._pricingService.savePricingScenario(scenario).subscribe((result) => {
      if (result.autoRefreshEnabled) {
        this._notificationService.showSuccess("Auto-refresh has been successfully enabled.", "Success");
      } else {
        this._notificationService.showSuccess("Auto-refresh has been successfully disabled.", "Success");
      }
    }, err => {
      this._notificationService.showError(err.error.message || "An error occurred while performing auto refresh the Scenario", "Error!");
    }).add(() => this._spinnerService.hide());
  }

  onShareWithBorrowerChanged = () => {
    this.saveScenario();
  }

  onLoanQuoteClicked = (scenario: PricingScenario) => {
    scenario['fees'] = this._fees;
    this.loanQuoteClicked.emit(scenario);
  }

  reloadScenario = () => {
    this.isDrawingChart = true;
    this._spinnerService.show(this.spinnerName);
    this._pricingService.getPricingScenario(this.scenario.pricingScenarioId).subscribe({
      next: (refreshedScenario) => {
        refreshedScenario['index'] = this.index;
        this.scenario = refreshedScenario;
        this.generatePriceHistoryChart();
      }, error: (err) => {
        let errorMessage = "";
        if (err) {
          errorMessage = err.message || err.error;
        }
        errorMessage = errorMessage || "An error occurred while refreshing the scenario.";
        this._notificationService.showError(errorMessage, "Error!")
      }
    }).add(() => {
      this._spinnerService.hide(this.spinnerName);
      this.isDrawingChart = false;
      this.refreshCompleted.emit(this.scenario);
    })
  }

  private reDrawChartInternal = () => {
    this.isDrawingChart = true;
    setTimeout(() => {
      this.isDrawingChart = false;
    }, 500);
  }

  private getPrices = () => {
    let prices: number[] = [];
    this.scenario.history.forEach(history => {
      if (history && history.adjustedPrice) {
        const price = 100 - history.adjustedPrice;
        prices.push(price);
      }
    })
    return prices;
  }

  private getDates = () => {
    let dates: any[] = [];
    this.scenario.history.forEach(history => {
      if (history && history.date) {
        dates.push(new Date(history.date).toLocaleDateString("en-US"));
      }
    })
    return dates;
  }

  private saveScenario = () => {
    this._spinnerService.show();
    this._pricingService.savePricingScenario(this.scenario).subscribe((result) => {
      this._notificationService.showSuccess("Scenario has been saved successfully.", "Success");
    }, err => {
      this._notificationService.showError(err.error.message || "An error occurred while saving the Scenario", "Error!");
    }).add(() => this._spinnerService.hide());
  }

  private calculateFeeValues = () => {
    this._spinnerService.show();
    this._feeService.getScenarioFees(this.scenario.pricingScenarioId).subscribe(fees => {
      this._fees = fees;
      this.calculateFeeTotals(fees);
    }).add(() => this._spinnerService.hide());
  }

  private generatePriceHistoryChart = () => {
    this.history = {
      data: {
        labels: this.getDates(),
        datasets: [
          {
            label: "Price",
            backgroundColor: '#42A5F5',
            data: this.getPrices(),
            tension: 0.5,
            completeHistory: this.scenario.history
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (tooltipItem) => {
                return [
                  `Price: ${tooltipItem.formattedValue < 0 ? '(' + Math.abs(tooltipItem.formattedValue) + ')' : tooltipItem.formattedValue}`,
                  `Lock Term: ${tooltipItem.dataset.completeHistory[tooltipItem.dataIndex]?.lockTerm}`,
                  `Adjusted Rate: ${tooltipItem.dataset.completeHistory[tooltipItem.dataIndex]?.adjustedRate}`
                ];
              }
            }
          }
        },
        barWidth: 26,
        scales: {
          x: {
            ticks: {
              color: '#000'
            },
            grid: {
              color: 'rgba(255,255,255,0.2)'
            },
            display: false
          },
          y: {
            ticks: {
              color: '#000'
            },
            grid: {
              color: 'rgba(255,255,255,0.2)'
            },
            display: false
          }
        }
      }
    };
  }

  private calculateFeeTotals = (fees: LoanFee[]) => {
    this.feesTotal = fees?.filter(fee => !fee.isSummaryFee)
      ?.reduce((prev, cur) => prev + (cur.calculatedValues.borrowerTotal || 0), 0) || 0;
    this.prepaidFeesTotal = fees?.filter(fee => !fee.isSummaryFee)
      ?.filter(f => f.feeSection === FeeSectionEnum.Prepaids)
      ?.reduce((prev, cur) => prev + (cur.calculatedValues.borrowerTotal || 0), 0) || 0;
  }
}
