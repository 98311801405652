import { Component, Input, OnInit } from "@angular/core";
import { finalize } from "rxjs";
import { LeadTableConfig } from "src/app/modules/lead-table/models/lead-table-config.model";
import { Lead } from "src/app/modules/leads/models/lead.model";
import { NotificationService } from "src/app/services/notification.service";
import { AgentsService } from "../../services/agents.service";
import { CommonService } from 'src/app/services/common.service';
import { LeadsPreferences, WebPreferences } from 'src/app/models/web-preferences.model';

@Component({
  selector: 'agent-leads',
  templateUrl: './agent-leads.component.html',
})
export class AgentLeadsComponent implements OnInit {
  @Input()
  agentId: number;

  leads: Lead[] = [];

  leadTableConfig: LeadTableConfig = {
    columns: [
      { field: 'lastName', header: 'Name', order: 1, visible: true },
      { field: 'mobilePhone', header: 'Contact Info', order: 2, visible: true },
      { field: 'loanPurposeId', header: 'Loan Purpose', order: 3, visible: true },
      { field: 'leadStatusId', header: 'Lead Status', order: 4, visible: true },
      { field: 'leadSource', header: 'Lead Source', order: 5, visible: true },
      { field: 'leadCampaignId', header: 'Lead Campaign', order: 6, visible: true },
      { field: 'subjectPropertyAddress', header: 'Subject Property Address', order: 7, visible: true },
      { field: 'loanAmount', header: 'Loan Amount', order: 8, visible: true },
      { field: 'interestRate', header: 'İnterest Rate', order: 9, visible: true },
      { field: 'leadContactUserId', header: 'Assigned To', order: 10, visible: true },
      { field: 'dateInserted', header: 'Created On', order: 11, visible: true },
      { field: 'contactAttempts', header: 'Contact Attempts', order: 12, visible: true },
      { field: 'lastAttemptedContact', header: 'Last Attempted Contact', order: 13, visible: true },
      { field: 'lastSuccessfulContact', header: 'Last Successful Contact', order: 14, visible: true },
    ],
    isSearchButtonVisible: true,
    isFilterButtonsVisible: false,
    isAddTodialListButtonVisible: false,
    isNewButtonVisible: false,
    isDeleteButtonVisible: false,
    isRowSelectionVisible: false,
    isColumnSelectionVisible: false,
    isReassignLeadVisible: false,
    isExportToCsvVisible: false,
    scrollOffset: 304,
    extraGlobalFilterFields: ['firstName', 'lastName', 'mobilePhone','homePhone', 'workPhone', 'email', 'leadSource', 'subjectPropertyAddress1', 'subjectPropertyCity', 'subjectPropertyState', 'subjectPropertyZip']
  };
  error: any;

  isLoading: boolean = true;

  webPreferences: WebPreferences;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _agentsService: AgentsService,
    private readonly _commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.error = null;
    this._commonService.getWebPreferences().subscribe(response => {
      this.webPreferences = response || new WebPreferences();
      if (!this.webPreferences.leadsPreferences) {
        this.webPreferences.leadsPreferences = new LeadsPreferences();
      }
    this._loadLeads();
    });
  }

  private _loadLeads() {
    this.isLoading = true;

    this._agentsService.getAllLeads(this.agentId)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe({
        next: (response) => {
          this.leads = response;
        },
        error:  (err) => {
          this.error = err;
        },
      })
  }
}
