import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCssControlsComponent } from './components/custom-css-controls/custom-css-controls.component';
import { SharedModule } from '../../../shared/shared.module';
import { CustomCssService } from './services/custom-css.service';



@NgModule({
  declarations: [
    CustomCssControlsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    CustomCssControlsComponent,
  ],
  providers: [
    CustomCssService
  ]
})
export class ConfigSharedModule { }
