<div class="row">
    <div class="col-lg-12">   
        <div *ngIf="(data && data.length > 0) || (optionalConditions && optionalConditions.length > 0)" class="mb-4">
            <conditions-table [otherConditions]="data" [otherOptionalConditions]="optionalConditions" [hideFilter]="true" [forSubmission]="true" [borrowers]="borrowers" (onTaskUpdated)="taskUpdated()"></conditions-table>
        </div>                         
        <div class="card" *ngIf="(!data || data.length == 0) && (!optionalConditions || optionalConditions.length == 0)">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">                      
                        <h4 class="card-title">Items Required For Submission</h4>                      
                    </div>
                </div>                                 
            </div>
            <div class="card-body">
                <div class="row">
                    <h4><i class="fa fa-check-circle green"></i> All Required / Optional Conditions Provided </h4>
                </div>
            </div>
        </div>
    </div>
</div>