import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocPrepTrustParty } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'document-preparation-trust-party',
  templateUrl: 'document-preparation-trust-party.component.html',
  styleUrls: ['./document-preparation-trust-party.component.scss']
})

export class DocumentPreparationTrustPartyComponent implements OnInit {

  @Output()
  deleteTrustParty: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  trustParty: DocPrepTrustParty;

  @Input()
  suffixes: EnumerationItem;

  @Input()
  index: number;

  protected uniqueId: string;

  constructor() {
    this.uniqueId = uuidv4();
  }

  ngOnInit() { }

  onDeleteClicked = (index: number) => {
    this.deleteTrustParty.emit(index);
  }
}
