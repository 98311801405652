<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #mainContent>
  <fees-library></fees-library>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageMainContentTemplate]="mainContent">
</admin-page-template>
