<ng-template #contextMenu>
    <ul class="merge-field-menu">
        <li>
            <span class="p-input-icon-right w-100">
                <i class="pi pi-search"></i>
                <input #search [(ngModel)]="searchMergeFieldText" type="text" class="form-control w-100" aria-label="search"
                    aria-describedby="search" placeholder="Search merge field" />
            </span>
        </li>
        <li class="merge-field-item" *ngFor="let mField of menuItems | search: 'name': searchMergeFieldText"
            (click)="mergeFieldClicked(mField.value)">
            {{mField.name}}
        </li>
    </ul>
</ng-template>