import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoanFee } from 'src/app/models/fee/fee.model';
import * as _ from 'lodash';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'final-fee-summary',
  templateUrl: 'final-fee-summary.component.html'
})
export class FinalFeeSummaryComponent implements OnInit {

  @Input()
  fees: LoanFee[] = [];

  @Input()
  applicationId: number;

  @Input()
  index: number;

  cardHeights = {}

  @Output()
  feesAppliedToLoan: EventEmitter<LoanFee[]> = new EventEmitter<LoanFee[]>();

  @Output()
  executionCompleted: EventEmitter<any> = new EventEmitter<any>();

  feesGroupedBySection: any = {};

  sections: FeeSectionEnum[] = [];

  totalFeeAmount: number = 0;

  applyingFees: boolean = false;

  feesApplied: boolean = false;

  errorMessage: string = null;

  constructor(private readonly _feeService: FeeService,
    private readonly _notifsService: NotificationService) { }

  ngOnInit() {
    this.feesGroupedBySection = _.groupBy(this.fees, e => e.feeSection);
    this.sections = Object.keys(this.feesGroupedBySection).map(s => s as FeeSectionEnum);
    this.sections.forEach(section => {
      this.totalFeeAmount += (this.feesGroupedBySection[section]).reduce((accum, item) => accum + (item.calculatedValues?.totalFee || 0), 0);
    })
  }

  applySelectedFeesToLoan = () => {
    const observer = {
      next: (updatedFees => {
        this.applyingFees = false;
        this.feesApplied = false;
        this.feesAppliedToLoan.emit(updatedFees);
      }),
      error: (error => {
        this.applyingFees = false;
        this.executionCompleted.emit();
        this.errorMessage = error && error.error ? error.error : "An error occurred while applying fees to loan.";
        this._notifsService.showError(this.errorMessage, "Error!");
      })
    }
    this.errorMessage = null;
    this.applyingFees = true;
    this._feeService.saveFees(this.applicationId, this.fees).subscribe(observer);
  }
}
