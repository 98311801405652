import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role, UserProfile } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { Alignment } from '../../internal-contacts/models/alignment.model';
import { CopyFormApp } from '../models/copy-form-app-model';

@Component({
  selector: 'next-copy-application-dialog',
  templateUrl: 'next-copy-application-dialog.component.html',
  styleUrls: ['next-copy-application-dialog.component.scss']
})

export class NextCopyAppDialogComponent implements OnInit {

  @Input()
  copyFormApp: CopyFormApp;

  @Input()
  title: string;

  @Input()
  docGuidsToCopy: string[];

  @Input()
  contactRoles: Role[];

  @Input()
  users: User[];

  @Input()
  usersAll: User[];

  @Input()
  branchUsers: UserProfile[];

  @Input()
  externalCompanyUsers: UserProfile[];

  @Input()
  alignments: Alignment[];

  @Input()
  contactUserIdsByRole: any;

  @Input()
  appId: number;

  @Input()
  companyId: number;

  contactSelection: string = 'original';

  losAligmentModel = {};

  constructor(public activeModal: NgbActiveModal,
    private readonly _loanService: LoanService,
    private readonly _router: Router,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit() { }

  onCancelClicked = () => {
    this.activeModal.dismiss();
  }

  onSaveClicked = () => {
    this._spinner.show();
    const data = {
      model: [],
      docGuidsToCopy: this.copyFormApp.docGuidsToCopy,
    };

    let selectedContacts;
    if (this.contactSelection == 'original') {
      selectedContacts = this.contactUserIdsByRole;
    } else if (this.contactSelection == 'lo') {
      selectedContacts = this.losAligmentModel;
    }

    if (!_.isEmpty(selectedContacts)) {
      this.contactRoles.map((role, key) => {
        data.model.push({
          "applicationId": this.appId,
          "roleId": role.roleId,
          "userId": selectedContacts[role.roleId],
          "companyId": this.companyId
        });
      });
    }

    this._loanService.copyApplication(this.appId, this.copyFormApp, this.contactSelection, data).subscribe((response) => {
      this._spinner.hide();
      this._notifyService.showSuccess('Your application has been successfully copied!', 'Success');
      if (!this.copyFormApp.alertUserOfNewFile) {
        this._router.navigate(['admin/app-details/' + response.applicationId]);
      }
      this.activeModal.dismiss();
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError('Error ' + err ? err.message || err : '', 'Copy Application');
    })

  }

  updateContactSelection = (roleId: number, userId: string) => {

  }
}
