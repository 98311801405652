import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingInfo } from 'src/app/models/tracking-info.model';
import { TrackingInfoHistory } from '../../models/tracking-info-history.model';

@Component({
  selector: 'loan-docs-tracking-dialog',
  templateUrl: 'loan-docs-tracking-dialog.component.html'
})

export class LoanDocsTrackingDialogComponent implements OnInit {

  @Input()
  trackingInfos: TrackingInfo;

  @Input()
  title: string;

  newTrackingInfos: TrackingInfo[] = [];

  trackingInfoHistory: TrackingInfoHistory[] = [];

  columns: any[];

  dateFormat = "MMM, dd YYYY h:mm a";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.loadTrackingInfos();
    this.columns = [
      { field: 'actionValue', header: 'Action', sortable: true },
      { field: 'fullName', header: 'By', sortable: true },
      { field: 'dateCreated', header: 'DateCreated', sortable: true },
    ];
  }

  onCancelClicked = () => {
    this.activeModal.close('cancel');
  }

  loadTrackingInfos = () => {
    this.newTrackingInfos = Object.values(this.trackingInfos);

    if (this.newTrackingInfos) {
      this.trackingInfoHistory = this.newTrackingInfos.map(i => new TrackingInfoHistory(i.actionValue,
        this.getNameBy(i.by), i.dateCreated));
    }
  }

  private getNameBy = (data: string) => {
    let user = this.newTrackingInfos.find((user: TrackingInfo) => user.userProfile.id === data);
    let userProfile = user.userProfile;
    return user && (userProfile.firstName && userProfile.lastName) != null ? (user.userProfile.firstName + ' ' + user.userProfile.lastName) : user.userProfile.userName;
  }
}
