import { AfterViewInit, Component, ElementRef, HostListener, Input, TemplateRef, ViewChild } from '@angular/core';
import { LicenseValidationResponse } from '../../models/license-validation-response.model';
import { LicenseValidationResult } from '../../models/license-validation.result.model';

@Component({
  selector: 'license-info',
  templateUrl: './license-info.component.html',
  styleUrls: ['./license-info.component.scss'],
})
export class LicenseInfoComponent implements AfterViewInit {
  @ViewChild('templateStatusBadge', { static: true })
  protected templateStatusBadge: TemplateRef<any>;
  @ViewChild('templateFlagBadge', { static: true })
  protected templateFlagBadge: TemplateRef<any>;

  protected error?: string | undefined;

  @Input()
  set data(value: LicenseValidationResponse | undefined) {
    this.items = value == null ? [] : this.createItemsFromResponse(value);
    this.error = value?.error;

    setTimeout(() => {
      this.invalidateLastItemColumnSpan();
    });
  }

  @ViewChild('itemsGrid')
  protected itemsGridElement: ElementRef<HTMLElement>;

  protected items: LicenseInfoItem[] | undefined;
  protected lastItemColumnSpan: any | null = null;

  ngAfterViewInit() {
    this.invalidateLastItemColumnSpan();
  }

  @HostListener('window:resize')
  protected onResize(): void {
    this.invalidateLastItemColumnSpan();
  }

  private invalidateLastItemColumnSpan() {
    this.lastItemColumnSpan = null;

    const gridElement = this.itemsGridElement?.nativeElement;
    if (gridElement == null) {
      return;
    }

    // An item consists of two columns in the grid (label and value)
    const ITEM_COLUMN_COUNT = 2;
    const itemCount = (this.items?.length ?? 0) * ITEM_COLUMN_COUNT;
    if (itemCount === 0) {
      return;
    }

    const computedStyle = window.getComputedStyle(gridElement);
    const propertyValue = computedStyle.getPropertyValue('grid-template-columns');
    const columnCount = propertyValue?.split(' ').length ?? 0;
    if (columnCount === 0) {
      return;
    }

    const remainderColumnCount = itemCount % columnCount;

    // If the items fit perfectly into the columns, no need to set a span
    if (remainderColumnCount === 0) {
      return;
    }

    // Calculate how many columns the last item should span to fill the row
    const emptyColumnCount = columnCount - remainderColumnCount;

    // span 1 is the default value, item itself is one column
    this.lastItemColumnSpan = `span ${emptyColumnCount + 1}`;
  }

  private createItemsFromResponse(value: LicenseValidationResponse): LicenseInfoItem[] {
    const lastRunDateItem: LicenseInfoItem = {
      label: responseLabels.validationRunDate,
      value: value.validationRunDate,
    };

    if (value.error) {
      const notApplicableItems = [
        responseLabels.overallValidationResult,
        responseLabels.companyValidationResult,
        responseLabels.loanOfficerValidationResult,
        responseLabels.loanOfficerSponsorshipsResult,
        responseLabels.isFederallyRegistered,
      ].map((label) => this.createNotApplicableItem(label));

      return [...notApplicableItems, lastRunDateItem];
    }

    return [
      {
        label: responseLabels.overallValidationResult,
        template: this.templateStatusBadge,
        value: value.overallValidationResult,
      },
      {
        label: responseLabels.companyValidationResult,
        template: this.templateStatusBadge,
        value: value.companyValidationResult,
      },
      {
        label: responseLabels.loanOfficerValidationResult,
        template: this.templateStatusBadge,
        value: value.loanOfficerValidationResult,
      },
      {
        label: responseLabels.loanOfficerSponsorshipsResult,
        template: this.templateStatusBadge,
        value: value.loanOfficerSponsorshipsResult,
      },
      {
        label: responseLabels.isFederallyRegistered,
        template: this.templateFlagBadge,
        value: value.isFederallyRegistered,
      },
      lastRunDateItem,
    ];
  }

  private createNotApplicableItem(label: string): LicenseInfoItem {
    return {
      label,
      template: this.templateStatusBadge,
      value: LicenseValidationResult.NotApplicable,
    };
  }
}

interface LicenseInfoItem {
  label: string;
  template?: TemplateRef<any>;
  value: any;
}

const responseLabels: Readonly<{ [key in keyof LicenseValidationResponse]: string }> = {
  overallValidationResult: 'Overall',
  companyValidationResult: 'Company License',
  loanOfficerValidationResult: 'Loan Officer License',
  loanOfficerSponsorshipsResult: 'Loan Officer Sponsorships',
  isFederallyRegistered: 'Is Federally Registered',
  validationRunDate: 'Last Run',
  error: 'Error',
};
