import { ZipCodeLookupResult } from '../app/models/zipcode-lookup-result.model';
import { Address } from '../app/models';
import { toProperCase } from '../app/core/services/string-utils';

export class ZipCodeLookupParser {
  parseZipCodeLookup(lookup: ZipCodeLookupResult): Partial<Address> {
    const { zipcode, state, city, county } = lookup;
    const address: Partial<Address> = {};

    address.zipCode = zipcode || null;
    address.state = state ? state.toLowerCase() : null;
    address.city = city ? toProperCase(city) : null;
    address.county = county ? toProperCase(county) : null;
    address.country = 'us';

    return address;
  }
}
