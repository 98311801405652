import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { ConfigurationProduct } from "../modules/admin/tpo-config/models/configuration-product.model";
import { Channel } from "../modules/leads/models/lead.model";

@Injectable()
export class ProductService {

  constructor(private readonly _dataService: DataService) {
  }

  getProducts = (channel?: Channel): Observable<ConfigurationProduct[]> => {
    let url = `api/Admin/GetAllProductByCompanyId${channel ? '?channel=' + channel : ''}`;
    return this._dataService.get(url)
  }

  getProduct = (productId: number): Observable<ConfigurationProduct> => {
    let url = `api/Admin/GetProductById/${productId}`;
    return this._dataService.get(url)
  }

  updateProduct = (product: ConfigurationProduct): Observable<any> => {
    let url = `api/Admin/UpdateProduct`;
    return this._dataService.post(url, product);
  }

  insertProduct = (product: ConfigurationProduct): Observable<any> => {
    let url = `api/Admin/InsertProduct`;
    return this._dataService.post(url, product);
  }

  deleteProduct = (productId: number): Observable<any> => {
    let url = `api/Admin/DeleteProduct/${productId}`;
    return this._dataService.delete(url);
  }

  reOrderProducts = (productIds: number[]): Observable<any> => {
    let url = `api/Admin/ReorderProducts`;
    return this._dataService.post(url, productIds);
  }
}
