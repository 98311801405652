<div *ngIf="options?.length" class="btn-toolbar" role="toolbar">
    <ng-template ngFor let-option [ngForOf]="options | sort : 'asc': 'type'" let-index="index">
        <button [disabled]="isButtonDisabled(option)" *ngIf="option.type === gridActionButtonType.SimpleButton"
            class="btn btn-sm me-2" [class]="option.buttonClass ?? 'btn-primary'" (click)="option.onClick()">
            <span *ngIf="option.isLoading && option.isLoading()" class="mx-1 spinner-border spinner-border-sm"
                role="status" aria-hidden="true"></span>
            <i *ngIf="option.iconClass" [class]="option.iconClass"></i>
            {{showLabel(option.label)}}
        </button>
        <div *ngIf="option.type === gridActionButtonType.ChannelDropDown" class="btn-group mx-2" role="group">
            <label class="pt-2 form-label">{{option.label}}:</label>
            <select #select class="form-select ms-2 w-auto" name="channels"
                (change)="option.onSelectionChanged($event.target.value)">
                <option value="NOFILTER" [hidden]="!option.showSelectOneOption">-- Select One --</option>
                <option *ngFor="let channel of option.enabledChannels" value={{channel.value}}
                    [selected]="channel.value === option.defaultSelection">
                    {{channel.name}}
                </option>
            </select>
        </div>

        <div *ngIf="option.type === gridActionButtonType.SearchInput" class="ms-auto">
            <span class="p-input-icon-right ms-2 h-100">
                <i class="pi pi-search"></i>
                <input #search type="text" class="form-control h-100" aria-label="search" aria-describedby="search"
                    [placeholder]="option.searchInputPlaceholder" />
            </span>
        </div>
        <div *ngIf="option.type === gridActionButtonType.ButtonsGroup" class="input-group" role="group">
            <ng-template ngFor let-btnOpt [ngForOf]="option.buttons" let-btnIndex="index">
                <input type="radio" class="btn-check" (click)="btnOpt.onClick()" name="action-button"
                    id="action-btn-{{btnIndex}}" autocomplete="off" [checked]="btnOpt.isChecked">
                <label class="btn btn-outline-primary" for="action-btn-{{btnIndex}}">{{btnOpt.text}}</label>
            </ng-template>
        </div>
        <div *ngIf="option.type === gridActionButtonType.Checkbox">
            <label class="mx-1 my-2 cursor">
                <input class="form-check-input" type="checkbox"
                    (change)="option.onCheckboxClick($event.target.checked)">
                {{showLabel(option.label)}}
            </label>
        </div>
    </ng-template>
</div>
