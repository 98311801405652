import { Component, OnInit, Input } from '@angular/core';
import { UrlaBorrower, UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { BorrowerIncomeComponent } from '../borrower-income/borrower-income.component';
import { EmploymentTypeEnum } from 'src/app/models';
@Component({
  selector: 'income-analysis',
  templateUrl: 'income-analysis.component.html',
  styleUrls: ['./income-analysis.component.scss']
})

export class IncomeAnalysisComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  totalHouseholdIncome: number = 0;

  constructor() { }

  ngOnInit() {
    this.calculateTotalHouseholdIncome();
  }

  static setBorrowerDefaults(borrower: UrlaBorrower) {
    BorrowerIncomeComponent.setBorrowerDefaults(borrower);
  }

  onTotalIncomeChanged = () => {
    this.calculateTotalHouseholdIncome();
  }

  private calculateTotalHouseholdIncome = () => {
    let grandTotalEmploymentIncome: number = 0;
    let grandTotalNonEmploymentIncome: number = 0;

    this.mortgage.borrowers.forEach(borrower => {
      if (borrower) {
        borrower.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer).forEach(employment => {
          if (employment.selfEmployed)
            grandTotalEmploymentIncome += employment.selfEmploymentMonthlyIncomeOrLoss || 0;
          employment.incomes?.forEach(i => grandTotalEmploymentIncome += i.monthlyIncome || 0);
        });
        borrower.nonEmploymentIncomes.forEach(income => {
          grandTotalNonEmploymentIncome += income.monthlyIncome;
        });
      }
    });
    this.totalHouseholdIncome = grandTotalEmploymentIncome + grandTotalNonEmploymentIncome;
  }
}
