<div class="modal-header">
  <h5 class="modal-title w-100">
    {{ !lenderData || !lenderData.lenderId ? 'New' : 'Edit' }} Lender Information
  </h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <form id="lenderForm" #lenderForm="ngForm" name="lenderForm">
    <div class="card">
      <div class="card-header">Lender Info</div>
      <div class="card-body">
        <lender-info [lender]="lenderData" [pricingVendors]="pricingVendors"></lender-info>
      </div>
    </div>

    <div class="card">
      <div class="card-header">AE Info</div>
      <div class="card-body">
        <lender-ae-info [lender]="lenderData"></lender-ae-info>
      </div>
    </div>

    <div class="card">
      <div class="card-header">Payoff Info</div>
      <div class="card-body">
        <lender-payoff-info [lender]="lenderData"></lender-payoff-info>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-end">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i> Cancel
  </button>

  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="save()">
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Saving
    </span>
  </button>
</div>