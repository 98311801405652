import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoanDocsV2Component } from './components/loan-docs-v2.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [],
    component: LoanDocsV2Component,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoanDocsV2RoutingModule { }
