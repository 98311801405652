import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocuSignDataComponent } from './components/docusign/docusign-data.component';
import { DocuSignDocumentsTableComponent } from './components/docusign-documents-table/docusign-documents.table.component';
import { DocuSignRecipientsTableComponent } from './components/docusign-recipients-table/docusign-recipients-table.component';
import { EsignatureHistoryDialogComponent } from './components/esignature-history-dialog/esignature-history-dialog.component';
import { ESignatureHistoryComponent } from './components/esignature-history/esignature-history.component';
import { EsignatureOrdersTableComponent } from './components/esignature-orders-table/esignature-orders-table.component';
import { EsignatureOriginalDocsTableComponent } from './components/esignature-original-docs-table/esignature-original-docs-table.component';
import { EsignatureRecipientsTableComponent } from './components/esignature-recipients-table/esignature-recipients-table.component';
import { EsignatureTasksTableComponent } from './components/esignature-tasks-table/esignature-tasks-table.component';
import { ESignatureService } from './services/esignature.service';

@NgModule({
    imports: [
        SharedModule,
        TableModule
    ],
    declarations: [
        ESignatureHistoryComponent,
        EsignatureHistoryDialogComponent,
        EsignatureOrdersTableComponent,
        EsignatureRecipientsTableComponent,
        EsignatureTasksTableComponent,
        EsignatureOriginalDocsTableComponent,
        DocuSignDataComponent,
        DocuSignDocumentsTableComponent,
        DocuSignRecipientsTableComponent
        
    ],
    exports: [
        ESignatureHistoryComponent,
        EsignatureHistoryDialogComponent,
        EsignatureOrdersTableComponent,
        EsignatureRecipientsTableComponent,
        EsignatureTasksTableComponent,
        EsignatureOriginalDocsTableComponent,
        DocuSignDataComponent,
        DocuSignDocumentsTableComponent,
        DocuSignRecipientsTableComponent
    ],
    providers: [
        ESignatureService
    ]
})
export class EsignatureModule {
  constructor() { }
}
