import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { FeeDefinitionModel } from 'src/app/models/fee/fee-definition.model';
import { FeePercentOfFieldEnum } from 'src/app/models/fee/fee-percent-of-field.enum';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'fee-definition-summary-table',
  templateUrl: 'fee-definition-summary-table.component.html',
  styleUrls: ['./fee-definition-summary-table.component.scss']
})

export class FeeDefinitionSummaryTableComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() title: string;
  @Input() feeSection: FeeSectionEnum;
  @Input() fees: FeeTemplateFee[];

  @Output() selectedFeesChanged = new EventEmitter<FeeDefinitionModel[]>();

  allFeeSectionDefinitions: FeeDefinitionModel[] = [];
  percentOfFields: EnumerationItem[] = [];
  companyId: number;
  selectedRows: FeeDefinitionModel[] = [];

  constructor(
    private readonly _feeService: FeeService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {

    this.applicationContextService.context.subscribe(context => {
      this.companyId = context.userPermissions.companyId;
    })

    this.allFeeSectionDefinitions.forEach((fee) => {
      this.initNullFeeValues(fee);
    })

    this.getPercentOfFields();
    this.getAllFeeDefinitions();
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  getPercentOfFields = () => {
    for (let enumMember in FeePercentOfFieldEnum) {
      let enumName = this.getEnumName(FeePercentOfFieldEnum[enumMember]);
      if (enumName) {
        this.percentOfFields.push({ name: enumName, value: FeePercentOfFieldEnum[enumMember] });
      }
    }
  }

  hasAlreadyAdded = (feeDefinitionId: number): boolean => {
    return this.fees.some(d => d.feeDefinitionId == feeDefinitionId);
  }

  isRowSelectable = (event: any) => {
    return !this.hasAlreadyAdded(event.data.feeDefinitionId);
  }

  onAllRowsSelected = () => {
    this.selectedFeesChanged.emit(this.selectedRows);
  }

  onRowSelect = () => {
    this.selectedFeesChanged.emit(this.selectedRows);
  }

  onRowUnselect = () => {
    this.selectedFeesChanged.emit(this.selectedRows);
  }

  private getAllFeeDefinitions = () => {
    this._spinner.show();
    this._feeService.getFeeDefinitions()
      .subscribe(definitions => {
        this.allFeeSectionDefinitions = definitions.filter(d => d.feeSection == this.feeSection);
        this._spinner.hide()

      }, (err) => {
        this._spinner.hide()
        this._notifyService.showError(err?.message || err.error, "Error!");
      });
  }

  private initNullFeeValues = (fee: FeeDefinitionModel): void => {
    if (fee.calculatedValues.totalFeePercent === null) {
      fee.calculatedValues.totalFeePercent = 0;
    }
    if (fee.calculatedValues.totalFee === null) {
      fee.calculatedValues.totalFee = 0;
    }
    if (fee.paidOutsideClosing === null) {
      fee.paidOutsideClosing = 0;
    }
    if (fee.calculatedValues.sellerConcessions === null) {
      fee.calculatedValues.sellerConcessions = 0;
    }
    if (fee.calculatedValues.sellerTotal === null) {
      fee.calculatedValues.sellerTotal = 0;
    }
    if (fee.calculatedValues.borrowerTotal === null) {
      fee.calculatedValues.borrowerTotal = 0;
    }
    if (fee.calculatedValues.monthlyFee === null) {
      fee.calculatedValues.monthlyFee = 0;
    }

  }
}
