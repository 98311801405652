import { Component, Injector, Input, OnInit } from '@angular/core';
import { orderBy } from 'lodash';
import { ApplicationContext, Configuration, UserProfile } from 'src/app/models';
import { StackingOrderDocTypes } from 'src/app/models/config/stacking-order-doc-types.model';
import { DashboardConfig } from 'src/app/modules/dashboardv2/models/dashboard-config.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../profile.service';

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() profile: Partial<UserProfile>;
  @Input() docTypes: StackingOrderDocTypes[] = [];
  @Input() allowCancelEsignOverride: Configuration;
  @Input() allowCopyLoanOverride: Configuration;
  @Input() allowDeleteTaskOverride: Configuration;

  protected dashboards: DashboardConfig[] = [];

  constructor(
    injector: Injector,
    private readonly _profileService: ProfileService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.profile.stackOrderId) {
      this.profile.stackOrderId = 0
    }
    this._profileService.getAvailableDashboardsForUser(this.profile.userCompanyGuid).subscribe((dashboards) =>
      {
        this.dashboards = !dashboards ? []
        : orderBy(dashboards, 'displayName');
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
