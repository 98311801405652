import { Component, Injector, OnInit } from '@angular/core';
import { Observer } from 'firebase/messaging';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContext, LoanDoc } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DocPrep, DocPrepDocumentType, DocPrepPacketType } from '../models/doc-prep.model';
import { TransactionPartyType } from '../models/transaction-party-type.enum';

@Component({
  selector: 'document-preparation',
  templateUrl: 'document-preparation.component.html',
  styleUrls: ['./document-preparation.component.scss']
})
export class DocumentPreparationComponent extends ApplicationContextBoundComponent implements OnInit {

  loanPurposes: LoanPurpose[] = [];
  loanTypes: LoanType[] = [];

  partyTypes: EnumerationItem[] = [];
  entityTypes: EnumerationItem[] = [];
  borrowerRoles: EnumerationItem[] = [];
  deedTypes: EnumerationItem[] = [];
  suffixes: EnumerationItem[] = [];
  maritalStatuses: EnumerationItem[] = [];

  protected isLoadingDocPrepData: boolean = false;
  protected docPreparationData: DocPrep;

  protected docPrepTypes: EnumerationItem[] = [];
  protected docPrepPacketTypes: EnumerationItem[] = [];

  protected isPurchase: boolean = false;
  protected isRefi: boolean = false;

  private _loanPurposePurchaseEnumValue: string;
  private _loanPurposeRefiEnumValue: string;

  constructor(
    private readonly _mortgageService: MortgageService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _enumsService: EnumerationService,
    private readonly _loanDocService: LoanDocService,
    private readonly _notifsService: NotificationService,
    injector: Injector,
  ) {
    super(injector);
    this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.initialize(context);
      }
    });
    this.docPrepTypes = this._enumsService.docPrepTypes;
    this.docPrepPacketTypes = this._enumsService.docPrepPacketTypes;
  }

  ngOnInit() {
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this.suffixes = enums[Constants.enumerations.suffix];
      this.maritalStatuses = enums[Constants.enumerations.maritalStatuses];
      this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
      this._loanPurposeRefiEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
    });

    this.borrowerRoles = this._enumsService.borrowerRoles;
    this.partyTypes = this._enumsService.partyTypes;
    this.entityTypes = this._enumsService.entityTypes;
    this.deedTypes = this._enumsService.deedTypes;
  }

  onSaveClicked = () => {
    if (this.docPreparationData.closingDate) {
      this.docPreparationData.closingDate = new Date(this.docPreparationData.closingDate).toISOString();
    }
    if (this.docPreparationData.invoicePaidDate) {
      this.docPreparationData.invoicePaidDate = new Date(this.docPreparationData.invoicePaidDate).toISOString();
    }

    if (this.docPreparationData.borrowers.length > 0) {
      this.docPreparationData.borrowers.forEach(borrower => {
        if (borrower.mortgagePartyType === TransactionPartyType.Individual) {
          borrower.entityName = null;
          borrower.entityType = null;
          borrower.trustName = null;
          borrower.trustDate = null;
          borrower.trustParties = null;
        }
        if (borrower.mortgagePartyType === TransactionPartyType.Entity) {
          borrower.firstName = null;
          borrower.middleName = null;
          borrower.lastName = null;
          borrower.nameSuffix = null;
          borrower.signingRole = null;
          borrower.powerOfAttorney = null;
          borrower.trustName = null;
          borrower.trustDate = null;
          borrower.trustParties = null;
        }
        if (borrower.mortgagePartyType === TransactionPartyType.Trust) {
          borrower.firstName = null;
          borrower.middleName = null;
          borrower.lastName = null;
          borrower.nameSuffix = null;
          borrower.signingRole = null;
          borrower.powerOfAttorney = null;
          borrower.entityName = null;
          borrower.entityType = null;
          borrower.trustDate = new Date(borrower.trustDate).toISOString();
        }
      });
    }
    if (this.docPreparationData.sellers.length > 0) {
      this.docPreparationData.sellers.forEach(seller => {
        if (seller.mortgagePartyType === TransactionPartyType.Individual) {
          seller.entityName = null;
          seller.mortgageEntityType = null;
          seller.signers = null;
          seller.trustName = null;
          seller.trustDate = null;
        }
        if (seller.mortgagePartyType === TransactionPartyType.Entity) {
          seller.firstName = null;
          seller.lastName = null;
          seller.suffix = null;
          seller.trustName = null;
          seller.trustDate = null;
        }
        if (seller.mortgagePartyType === TransactionPartyType.Trust) {
          seller.firstName = null;
          seller.lastName = null;
          seller.suffix = null;
          seller.entityName = null;
          seller.mortgageEntityType = null;
          seller.trustDate = new Date(seller.trustDate).toISOString();
        }
      });
    }
    this._spinnerService.show();

    this._mortgageService.saveDocPreparationData(this.applicationContext.application.applicationId, this.docPreparationData)
      .subscribe({
        next: () => {
          this.getDocPrep(this.applicationContext.application.applicationId);
          this._notifsService.showSuccess('Saved Document Preparation successfuly.', 'Success');
        }, error: (error) => {
          this._notifsService.showError(error.message || 'Unable to save Document Preparation', 'Error');
        }
      })
      .add(() => this._spinnerService.hide())
  }

  onLoanPurposeChanged = () => {
    this.decideIfLoanIsRefiOrPurchase();
  }

  onGeneratePacketClicked = (packetType: DocPrepPacketType) => {
    this._spinnerService.show();
    this._mortgageService.generatePacket(this.docPreparationData.applicationId, packetType).subscribe({
      next: (doc: LoanDoc) => {
        this._notifsService.showSuccess('Packet has been successfully generated.', 'Success');
        if (doc && doc.docFiles?.length) {
          let fileGuid = doc.docFiles[0].guid;
          this._loanDocService.viewPdfFile(fileGuid).subscribe({
            next: (data: BlobPart) => {
              const blob = new Blob([data], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            },
            error: (error: any) => {
              this._notifsService.showError(error.message || 'Unable to download packet.', 'Error');
            }
          })
        }
      },
      error: (error) => {
        this._notifsService.showError(error.message || 'Unable to generate packet.', 'Error');
      }
    }).add(() => this._spinnerService.hide());
  }

  onGenerateDocClicked = (docPrepType: DocPrepDocumentType) => {
    const observer: Observer<LoanDoc> = {
      next: (doc: LoanDoc) => {
        this._notifsService.showSuccess('Document has been successfully generated.', 'Success');
        if (doc && doc.docFiles?.length) {
          let fileGuid = doc.docFiles[0].guid;
          this._spinnerService.show();
          this._loanDocService.viewPdfFile(fileGuid).subscribe(data => {
            this._spinnerService.hide();
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          }, error => {
            this._notifsService.showError(error.message || 'Unable to download document.', 'Error');
          });
        } else {
          this._spinnerService.hide();
          this._notifsService.showError("No File exist.", "Error");
        }
      },
      error: (error: Error) => {
        this._notifsService.showError(error.message || 'Unable to generate document.', 'Error');
      },
      complete: () => {
        this._spinnerService.hide();
      }
    }
    this._spinnerService.show();
    this._mortgageService.generateDocument(this.docPreparationData.applicationId, docPrepType).subscribe(observer)
      .add(() => { this._spinnerService.hide(); });
  }

  private initialize = (context: ApplicationContext) => {
    if (!context.application) {
      return;
    }
    this.loanPurposes = context.globalConfig.loanPurpose;
    this.loanTypes = context.globalConfig.loanType;
    this.getDocPrep(context.application.applicationId);
  }

  private getDocPrep = (applicationId: number) => {
    this.isLoadingDocPrepData = true;
    const observer: Observer<DocPrep> = {
      next: (docPreparationData: DocPrep) => {
        this.docPreparationData = docPreparationData;
        this.decideIfLoanIsRefiOrPurchase();
      },
      error: (error) => {
        this._notifsService.showError(error.message || 'Unable to get Document Preparation', 'Error');
      },
      complete: () => {
        this.isLoadingDocPrepData = false;
      }
    }

    this._mortgageService.getDocPreparationData(applicationId).subscribe(observer);
  }

  private decideIfLoanIsRefiOrPurchase = () => {
    this.isPurchase = this.docPreparationData.loanPurposeType && this.docPreparationData.loanPurposeType == this._loanPurposePurchaseEnumValue;
    this.isRefi = this.docPreparationData.loanPurposeType && this.docPreparationData.loanPurposeType == this._loanPurposeRefiEnumValue;
  }
}
