import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { BorrowerDto } from '../../contacts/models/borrower-dto.model';
import { Lead } from '../../leads/models/lead.model';
import { ContactListSearchResult } from '../models/contact-list-search-result.model';
import { FullTextSearch } from '../models/full-text-search.model';
import { SearchCriteria } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private readonly _dataService: DataService) { }

  searchLeads = (data: FullTextSearch): Observable<Lead[]> => {
    const url = 'api/leads/search';
    return this._dataService.post(url, data);
  }

  searchBorrowers = (data: FullTextSearch): Observable<BorrowerDto[]> => {
    const url = 'api/borrower/search';
    return this._dataService.post(url, data);
  }

  searchApplications = (data: FullTextSearch): Observable<PipelineItem[]> => {
    const url = 'api/Loan/search';
    return this._dataService.post(url, data);
  }

  searchContactList = (data: SearchCriteria): Observable<ContactListSearchResult[]> => {
    const url = 'api/contact-list/search';
    return this._dataService.post(url, data);
  }
}
