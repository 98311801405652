import { ThirdPartyKeyValueArray } from '.';

export class ThirdPartyCredential {
  credentialId?: number;
  credentialType: ThirdPartyCredentialType | string;
  vendorName: string = "";
  alias?: string = "";
  channels?: string = "";
  branchId?: string = "";
  url?: string = "";
  userName: string = "";
  password?: string = "";
  userId?: string;
  companyId?: number;
  active?: boolean = false;
  thirdPartyKeyValuePairs: ThirdPartyKeyValueArray = [];
  requestTimeout?: string;
  insertedBy?: string
  updatedBy?: string
  dateInserted?: string
  dateUpdated?: string

  constructor(credentialType?: ThirdPartyCredentialType, vendorName?: string, userId?: string, branchId?: string) {
    this.credentialType = credentialType || "";
    this.vendorName = vendorName || "";
    this.userId = userId || null;
    this.branchId = branchId || null;
  }
}

export class UserThirdPartyCredential {
  constructor() { }

  credentials: ThirdPartyCredentialArray = [];
  companyCredentials: UserCompanyThirdPartyCredentialArray = [];
  isTpoUser: boolean
  saveAndTest: boolean
}

export type UserCompanyThirdPartyCredential = {
  credentialId: number,
  credentialName: string,
  vendorName: string,
}

/**
 * Used in GET for user credentials
 */
export type UserCompanyThirdPartyCredentialArray = ReadonlyArray<UserCompanyThirdPartyCredential>

export class ThirdPartyCredentialSchema {
  credentialType: ThirdPartyCredentialType
  vendorName: string
  fixedBaseUrl: string
  hasUserName: boolean
  hasPassword: boolean
  hasUserCreds: boolean
  instanceKey: string
  kvPairs: CredentialKvpSchema[] = []
}

export class CredentialInfo {
  credentialId: number;
  credentialName: string;
  vendorName: string;
}

export enum ThirdPartyCredentialType {
  AppraisalVendor = "AppraisalVendor",
  AUSCasefileAccess = "AUSCasefileAccess",
  AUSOriginator = "AUSOriginator",
  AusVendor = "AusVendor",
  CreditVendor = "CreditVendor",
  Disclosure = "Disclosure",
  DocumentSigning = "DocumentSigning",
  Email = "Email",
  Fees = "Fees",
  LdeVendor = "LdeVendor",
  LoanBeam = "LoanBeam",
  LosVendor = "LosVendor",
  OcrVendor = "OcrVendor",
  PricingVendor = "PricingVendor",
  SocialMedia = "SocialMedia",
  Title = "Title",
  VOA = "VOA",
  VOEorVOI = "VOEorVOI",
  CloudPlatform = "CloudPlatform",
  Mi = "Mi"
}

export type ThirdPartyCredentialArray = Array<ThirdPartyCredential>;
export type ThirdPartyCredentialSchemaReadonlyArray = ReadonlyArray<ThirdPartyCredentialSchema>;

export type CredentialScopeType = "User" | "TpoUser" | "Company" | "CompanyAndUser" | "Global";

export class CredentialKvpSchema {
  name: string
  displayName: string
  scope: CredentialScopeType
  optional: boolean
}
