<div class="modal-header">
  <h5 class="modal-title">Credential</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #credentialForm="ngForm">
    <div class="container">
      <div class="row pt-3" *ngIf="channels.length > 0">
        <div class="col-md-2 text-end">
          <label class="custom-control-label" for="channels-dropdown">Channel</label>
        </div>
        <div class="col-md-4">
          <ng-select width="100%" name="channels-dropdown" bindLabel="name" bindValue="value"
            placeholder="Select Channels" [closeOnSelect]="false" [multiple]="true" (change)="setCredentialChannels()"
            [(ngModel)]="selectedChannels" [items]="channels" appendTo="body">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice:0:2">
                <span class="ng-value-label">{{item.name}}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 2">
                <span class="ng-value-label">{{items.length - 2}} more...</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-2 text-start">
          <label class="custom-control-label" for="doUserName">Username</label>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" name="doUserName" [(ngModel)]="doCredential.userName"
            [ngClass]="{'is-invalid' : doUserNameField && doUserNameField.touched && doUserNameField.invalid}"
            #doUserNameField="ngModel" required />
        </div>
        <div class="col-md-2 text-end">
          <label class="custom-control-label" for="doPassword">Password</label>
        </div>
        <div class="col-md-4">
          <password-input [name]="'doPassword'" [(ngModel)]="doCredential.password" required="true"></password-input>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-12">
          <div class="fieldset-title">
            <div class="row">
              <div class="col-md-2 pt-2 ps-2">Credit Providers</div>
              <div *ngIf="!doFannieMaeLoadingError && doCreditProviders.length" class="col-md-3 text-start">
                <ng-container *ngTemplateOutlet="saveAndLoadButton"></ng-container>
              </div>
              <div *ngIf="!doFannieMaeLoadingError" class="col-md-4 text-start">
                <button class="btn btn-outline-primary" (click)="addCreditProvider()"
                  [hidden]="!(doCredential.userName && doCredential.password)">
                  Add Credit Provider
                </button>
              </div>
              <div class="alert alert-danger border-0 fs-6 mt-3 mb-3" *ngIf="doFannieMaeLoadingError" role="alert">
                <strong><i class="la la-exclamation-circle text-danger"></i></strong> DO Authentication failed. Please Update your DO credentials.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!doFannieMaeLoadingError">
        <div class="row pt-3" *ngIf="doKeyValuePairs.creditProviders.length">
          <div class="col-md-2 text-start">
            <label class="custom-control-label" for="doDefaultdd">Default Credit Provider</label>
          </div>
          <div class="col-md-4">
            <select name="doDefaultdd" class="form-select" [(ngModel)]="doKeyValuePairs.defaultCreditProvider.value">
              <option value="">-- Select One --</option>
              <option *ngFor="let item of doKeyValuePairs.creditProviders" value="{{ item.key }}">
                {{ item.key }}
              </option>
            </select>
          </div>
        </div>
        <div class="row pt-3" *ngIf="!doCreditProviders.length">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <ng-container *ngTemplateOutlet="saveAndLoadButton"></ng-container>
          </div>
        </div>
        <div class="row pt-3" *ngFor="let item of doKeyValuePairs.creditProviders; let index = index">
          <div class="col-md-2 text-start">
            <label class="custom-control-label" for="doProviderName-{{index}}">Credit Provider Name</label>
          </div>
          <div class="col-md-2 position-relative">
            <select name="doProviderName-{{index}}" class="form-select" [(ngModel)]="item.key">
              <option value="">-- Select One --</option>
              <option *ngFor="let ele of doCreditProviders; trackBy: trackByKey" value="{{ ele.key }}"
                [ngValue]="ele.key">
                {{ ele.displayText }}
              </option>
            </select>
            <span *ngIf="loadingDoAgencies" id="agenciesSpinner" class="spinner-border spinner-border-sm"></span>
          </div>
          <div class="col-md-1 text-start">
            <label class="custom-control-label" for="doAlias_{{index}}">Alias</label>
          </div>
          <div class="col-md-1">
            <input name="doAlias_{{index}}" type="text" class="form-control" [(ngModel)]="item.alias" />
          </div>
          <div class="col-md-1 text-start">
            <label class="custom-control-label" for="doUserName_{{index}}">Username</label>
          </div>
          <div class="col-md-1">
            <input name="doUserName_{{index}}" type="text" class="form-control" [(ngModel)]="item.userName" />
          </div>
          <div class="col-md-1 text-start">
            <label class="custom-control-label" for="doCreditProvPassword_{{index}}">Password</label>
          </div>
          <div class="col-md-2">
            <password-input [name]="'doCreditProvPassword_' + index" [(ngModel)]="item.password">
            </password-input>
          </div>
          <div class="col-md-1">
            <confirm-delete [list]="doKeyValuePairs.creditProviders" [index]="index"></confirm-delete>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="fieldset-title">
          <div class="col-md-2 pt-2 ps-2">Institutions</div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-2 text-start">
          <label class="custom-control-label" for="defaultInstution">Default Institution</label>
        </div>
        <div class="col-md-4">
          <select name="defaultInstution" class="form-select" [(ngModel)]="doKeyValuePairs.defaultInstitution.value">
            <option value="">-- Select One --</option>
            <option *ngFor="let lender of doLenders" value="{{ lender.thirdPartyInstitutionId }}"
              [ngValue]="lender.thirdPartyInstitutionId">
              {{ lender.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save</span>
  </button>
</div>
<ng-template #saveAndLoadButton>
  <button class="btn btn-outline-primary" *ngIf="agenciesRequested"
    [disabled]="!(doCredential.userName && doCredential.password)" (click)="saveAndLoadCreditProviders()"
    style="width: 215px">
    <span *ngIf="!isSaving">
      {{(!doFannieMaeLoadingError && doCreditProviders.length) ? 'Reload' : 'Save & Load'}} Credit Providers
    </span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </span>
  </button>
</ng-template>