import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import config from 'devextreme/core/config';
import { DrawerService } from './shared/services/drawer.service';
import { Subscription } from 'rxjs';
import { ApplicationContextService } from './services/application-context.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'admin-app';

  isLoading: boolean = true;

  private readonly _router = inject(Router);
  private readonly _modalService = inject(NgbModal);
  private readonly _drawerService = inject(DrawerService);
  private readonly _titleService = inject(Title);

  private readonly _routerEventsSubscription: Subscription;

  protected faviconUrl: string = '/assets/images/favicon_loda.ico';

  constructor(private readonly _applicationContextService: ApplicationContextService,
    private window: Window
  ) {

    this._routerEventsSubscription = this._router.events.subscribe((routerEvent: RouterEvent) => {
      this.handleRouterEvent(routerEvent);
    });

    // Specifying a currency globally
    config({ defaultCurrency: 'USD' });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._routerEventsSubscription?.unsubscribe();
  }

  private handleRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationEnd) {
      this._drawerService.hideAll();

      this._modalService.dismissAll();

      if (routerEvent.url) {
        if (!routerEvent.url.match(/^\/admin\/app-details/) && !routerEvent.url.match(/^\/tpo\/app-details/)) {
          const hostname = this.window.location.hostname;
          if (hostname.toLowerCase().includes('leo.prmg.net')) {
            this._titleService.setTitle('LEO - Loan Express Originator');
          } else {
            this._titleService.setTitle('Lodasoft - CMA Platform');
          }
          this._applicationContextService.resetLoanInfo();
        }

        if (routerEvent.url.match(/^\/#/)) {
          this.handleOldHashRoutesForBackwardCompatibility(routerEvent.url);
        }
      }
      this.isLoading = false;
    }

    if (
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.isLoading = false;
    }
  }

  private handleOldHashRoutesForBackwardCompatibility = (url: string) => {
    if (url.match(/^\/#\/appdetail/)) {
      this.handleAppDetailHashRouteForBackwardCompatibility(url);
      return;
    } else if (url.match(/^\/#\/events/)) {
      let urlToRedirectTo = url.replace('/#/events', '/admin/calendar');
      this._router.navigateByUrl(urlToRedirectTo);
      return;
    }
    let urlToRedirectTo = url.replace('/#', '');
    urlToRedirectTo = this.properlyEncodeUrl(urlToRedirectTo);
    this._router.navigateByUrl(urlToRedirectTo);
  };

  private handleAppDetailHashRouteForBackwardCompatibility = (url: string) => {
    const segments = url.split('/');
    const indexForAppDetailSegment = segments.findIndex(
      (s) => s.toLocaleLowerCase() === 'appdetail'
    );
    if (indexForAppDetailSegment >= 0) {
      const indexOfAppIdSegment = indexForAppDetailSegment + 2;

      if (indexForAppDetailSegment <= segments.length - 1) {
        const appId = segments[indexOfAppIdSegment];
        const urlToRedirectTo = `/admin/app-details/${appId}`;
        this._router.navigateByUrl(urlToRedirectTo);
      }
    }
  };

  private splitQueryParam = (queryParamNameAndValue: string) => {
    let index = 0;
    for (index; index < queryParamNameAndValue.length; index++) {
      if (queryParamNameAndValue.charAt(index) === '=') {
        break;
      }
    }
    const paramName = queryParamNameAndValue.slice(0, index);
    const paramValue = queryParamNameAndValue.slice(index + 1, queryParamNameAndValue.length);
    return {
      paramName,
      paramValue
    }
  }

  private properlyEncodeUrl = (url: string) => {
    const baseUrlAndQuery = url.split('?');
    if (baseUrlAndQuery.length === 1) {
      return url;
    }
    const queryStringParts = baseUrlAndQuery[1].split('&');
    let queryString = '';
    queryStringParts.forEach((part) => {
      const nameAndValue = this.splitQueryParam(part);
      let queryStringSegment = '';
      if (nameAndValue.paramName && nameAndValue.paramValue) {
        queryStringSegment =
          nameAndValue.paramName + '=' + encodeURIComponent(nameAndValue.paramValue);
        if (queryStringSegment) {
          queryString += '&' + queryStringSegment;
        }
      }
    });
    if (queryString) {
      queryString = queryString.substring(1);
    }
    return baseUrlAndQuery[0] + '?' + queryString;
  };
}
