import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { TitleOrderTrackingHistory } from "../modules/app-details/components/title-history/models/title-order-tracking-history";
import { TitleOrder, TitleOrderDocument } from "../modules/app-details/components/title-history/models/title-order.model";
import { CreateTitleOrderRequest } from "../models/title/create-title-order-request.model";
import { QuoteHistory } from "../models/title/quote-history.model";
import { UpsertTitleOrderResponse } from "../models/title/upsert-title-order-response.model";
import { TitleLender } from "../models/title/title-lender.model";

@Injectable()
export class TitleService {

  constructor(private readonly _dataService: DataService) { }

  syncQuoteToLoanFees = (titleQuoteHistoryId: number): Observable<any> => {
    const url = 'api/title/quotes/' + titleQuoteHistoryId + '/sync-to-loan-fees';
    return this._dataService.post(url, {});
  }

  postQuotes = (applicationId: number, loanDocTaskId: number): Observable<QuoteHistory> => {
    const url = 'api/title/quotes?applicationId=' + applicationId + '&loanDocTaskId=' + loanDocTaskId;
    return this._dataService.post(url, {});
  }

  postOrder = (request: CreateTitleOrderRequest): Observable<UpsertTitleOrderResponse> => {
    const url = 'api/title/orders';
    return this._dataService.post(url, request);
  }

  getQuotes = (applicationId: number): Observable<QuoteHistory[]> => {
    const url = 'api/title/quotes/history?applicationId=' + applicationId;
    return this._dataService.get(url);
  }

  getLenders = (): Observable<TitleLender[]> => {
    const url = 'api/title/lenders';
    return this._dataService.get(url);
  }

  getSelectedLender = (titleOrderId: number): Observable<TitleLender> => {
    const url = 'api/title/orders/' + titleOrderId + '/lender';
    return this._dataService.get(url);
  }

  updateLender = (titleOrderId: number, selectedLenderId: number): Observable<TitleLender> => {
    const url = `api/title/orders/${titleOrderId}/lender?thirdPartyLenderId=${selectedLenderId}`;
    return this._dataService.post(url, {});
  }

  getOrders = (applicationId: number): Observable<TitleOrder[]> => {
    const url = 'api/title/orders?applicationId=' + applicationId;
    return this._dataService.get(url);
  }

  getTitleOrderHistory = (titleOrderId: number): Observable<TitleOrderTrackingHistory[]> => {
    return this._dataService.get(`api/title/orders/${titleOrderId}/tracking-history`);
  }

  postOrderDocument = (titleOrderId: number, doc: TitleOrderDocument): Observable<TitleOrderDocument> => {
    const url = `api/title/orders/${titleOrderId}/documents`;
    return this._dataService.post(url, doc);
  }

  deleteOrder = (titleOrderId: number): Observable<any> => {
    const url = `api/title/orders/${titleOrderId}`;
    return this._dataService.delete(url);
  }

  refreshEvents = (titleOrderId: number): Observable<any> => {
    const url = `api/title/orders/${titleOrderId}/refresh-events`;
    return this._dataService.post(url, {});
  }

  getOrderDetails = (titleOrderId: number): Observable<TitleOrder> => {
    return this._dataService.get(`api/title/orders/${titleOrderId}`);
  }
}
