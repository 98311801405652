import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';

@Component({
    selector: 'role-picker-dialog',
    templateUrl: 'role-picker-dialog.component.html'
})

export class RolePickerDialogComponent implements OnInit {

    @Input()
    companyName: string;

    @Input()
    companyGuid: string;

    @Input()
    roles: AuthenticationUserCompany[] = [];

    selectedRoleId: number;

    isSwitching: boolean = false;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    onSwitchClicked = () => {
      const selectedOne = this.roles.find(r => r.roleId === this.selectedRoleId);
      this.activeModal.close(selectedOne);
    }
}
