export class EmailServiceModel {
    id: number
    userId: string
    branchId: number
    name: string
    serviceType: string = null
    server: string
    port: number
    userName: string
    password: string
    isSecure: true
    servicesUrl: string
    apiKey: string
    ipPoolName: string
    isGlobal: true
    isDisabled: true
    disabledReason: string
    companyId: number
    insertedBy: string
    dateInserted: string
    updatedBy: string
    dateUpdated: string
    awsRegion: AwsRegion = null
}

export enum AwsRegion {
    US_EAST_2 = "US_EAST_2",
    US_EAST_1 = "US_EAST_1",
    US_WEST_1 = "US_WEST_1",
    US_WEST_2 = "US_WEST_2",
    AF_SOUTH_1 = "AF_SOUTH_1",
    AP_EAST_1 = "AP_EAST_1",
    AP_SOUTH_2 = "AP_SOUTH_2",
    AP_SOUTHEAST_3 = "AP_SOUTHEAST_3",
    AP_SOUTH_1 = "AP_SOUTH_1",
    AP_NORTHEAST_3 = "AP_NORTHEAST_3",
    AP_NORTHEAST_2 = "AP_NORTHEAST_2",
    AP_SOUTHEAST_1 = "AP_SOUTHEAST_1",
    AP_SOUTHEAST_2 = "AP_SOUTHEAST_2",
    AP_NORTHEAST_1 = "AP_NORTHEAST_1",
    CA_CENTRAL_1 = "CA_CENTRAL_1",
    EU_CENTRAL_1 = "EU_CENTRAL_1",
    EU_WEST_1 = "EU_WEST_1",
    EU_WEST_2 = "EU_WEST_2",
    EU_SOUTH_1 = "EU_SOUTH_1",
    EU_WEST_3 = "EU_WEST_3",
    EU_SOUTH_2 = "EU_SOUTH_2",
    EU_NORTH_1 = "EU_NORTH_1",
    EU_CENTRAL_2 = "EU_CENTRAL_2",
    ME_SOUTH_1 = "ME_SOUTH_1",
    ME_CENTRAL_1 = "ME_CENTRAL_1",
    SA_EAST_1 = "SA_EAST_1",
}
