<div class="modal-header">
  <h5 class="modal-title w-100">Possible Duplicate(s)</h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  We found the following possible duplicate {{userType}}(s). Please select if you wish
  to still create the {{userType}} or go to an existing {{userType}}.
  <br />
  <br />
  <select [ngModel]="selectedId" (ngModelChange)="changed($event)" class="form-select">
    <option [ngValue]="0">Create New</option>
    <option *ngFor="let item of items" [ngValue]="item.id">
      Go to {{ item.firstName }} {{ item.lastName
      }}{{ !!item.email ? ' - ' + item.email : '' }} - Created:
      {{ item.dateInserted | date: 'shortDate' }}
      {{ !!item.borrowerContact ? ' - Contact: ' + item.borrowerContact : '' }}
    </option>
  </select>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>

  <button
    type="submit"
    class="btn btn-primary"
    (click)="activeModal.close(selectedId)"
  >
    Submit
  </button>
</div>
