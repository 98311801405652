<div class="col-md-12" *ngIf="!isMersEnabled">
    <enable-message>Call to Enable this feature!</enable-message>
</div>

<div *ngIf="isMersEnabled">
    <div class="row mt-1">
        <div class="col-md-12">
            <toggle-configuration [title]="'Enable MERS'" [id]="'mersEnabled'"
                [configuration]="mersConfig"></toggle-configuration>
        </div>
        <div class="col-md-12 mt-1">
            <hr class="mt-3 mb-0" />
        </div>
        <div *ngIf="mersConfig.value">
            <div class="fieldset-title mt-3">
                MERS Configuration
                <span class="badge font-14 badge-soft-primary">New!</span>
            </div>
            <form #mersCredsForm="ngForm" novalidate id="mers-credential-form" name="mersCredsForm">
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">Organization ID</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" name="orgId" maxlength="7" class="form-control"
                            [(ngModel)]="mersOrganizationId" #orgIdField="ngModel"
                            [ngClass]="{'is-invalid' : orgIdField && orgIdField.touched && orgIdField.invalid}"
                            required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">Lodasoft Vendor ID</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" name="vendorId" class="form-control" [(ngModel)]="lodasoftMersVendorId">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-end">
                            <div class="col-md-12 my-1">
                                <hr />
                            </div>
                            <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
                                <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
                                <span *ngIf="isSaving">
                                    <span class="spinner-border spinner-border-sm"></span> Saving
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-12">
        <enable-message *ngIf="mersConfig.value === 0">Enable this feature above!</enable-message>
    </div>
</div>