<div class="row">
    <div *ngIf="supportsChannelConfig && channels.length > 0" class="col-md-6 mt-1">
        <label class="custom-control-label">Channel</label>
        <ng-select width="100%" name="channels-dropdown" bindLabel="name" bindValue="value"
            placeholder="Select Channels" [closeOnSelect]="false" [multiple]="true" (change)="setCredentialChannels()"
            [(ngModel)]="selectedChannels" [items]="channels" appendTo="body" [disabled]="disabledChannel">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice:0:2">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                    <span class="ng-value-label">{{items.length - 2}} more...</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
    <div class="col-md-6 mt-1" *ngIf="scope == 'Branch'">
        <label *ngIf="showBranchEditor" class="custom-control-label">Branch</label>
        <select *ngIf="showBranchEditor" class="form-select" name="branchId" [(ngModel)]="credential.branchId">
            <option value="">-- Select One --</option>
            <option *ngFor="let item of branches" value={{item.branchId}}>
                {{item.branchName}}
            </option>
        </select>
    </div>
</div>
<div class="row">
    <div class="col-md-6 mt-1">
        <label class="custom-control-label">Vendor</label>
        <input class="form-control" *ngIf="vendorSelectionDisabled" name="vendorName"
            [value]="credential.vendorName | splitCamelcase" disabled />
        <ng-container *ngIf="!vendorSelectionDisabled && scope != 'Company' && this.isLosCredentials">
            <select class="form-select"
                [ngClass]="{'is-invalid': vendorName && vendorName.touched && vendorName.invalid}" name="vendorName"
                #vendorDropdown #vendorName="ngModel" [(ngModel)]="selectedVendorId" (ngModelChange)="changeVendor()"
                required>
                <option value="">-- Select One --</option>
                <option *ngFor="let vendor of vendors | sort: 'asc':'name'" [ngValue]="vendor.id">
                    {{vendor.name}}
                </option>
            </select>
            <div class="invalid-feedback">Vendor is required.</div>
        </ng-container>
        <ng-container *ngIf="!vendorSelectionDisabled && (scope === 'Company' || !this.isLosCredentials)">
            <select class="form-select"
                [ngClass]="{'is-invalid': vendorName && vendorName.touched && vendorName.invalid}" name="vendorName"
                #vendorDropdown #vendorName="ngModel" [(ngModel)]="selectedVendor" (ngModelChange)="changeVendor()"
                required>
                <option value="">-- Select One --</option>
                <option *ngFor="let vendor of vendors | sort: 'asc':'name'" [value]="vendor.value">
                    {{vendor.name}}
                </option>
            </select>
            <div class="invalid-feedback">Vendor is required.</div>
        </ng-container>

    </div>
    <div class="col-md-6 mt-1" *ngIf="!(scope === 'TpoUser' && credential.vendorName === 'MeridianLink')">
        <label class="custom-control-label">Alias</label>
        <input type="text" class="form-control" name="alias"
            [ngClass]="{'is-invalid': alias && alias.touched && alias.invalid}" #alias="ngModel"
            [(ngModel)]="credential.alias" required>
        <div class="invalid-feedback">Alias is required.</div>
    </div>
</div>
