<div class="p-2">
  <disclosure-tracking-config [appId]="appId" [timeZone]="timeZone">
  </disclosure-tracking-config>
</div>
<div class="p-2">
  <disclosure-tracking-history 
    [urlaFieldsConfig]="urlaFieldsConfig"
    [historyItems]="historyItems" 
    [borrowers]="borrowers" 
    [appId]="appId"
    [usersAll]="usersAll">
  </disclosure-tracking-history>
</div>