import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { EventEditorDialogComponent } from './components/event-editor-dialog/event-editor-dialog.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { EventsService } from './services/events.service';
import { CalendarComponent } from './components/calendar/calendar.component';
import { EventsRoutingModule } from './events-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarUserFilterDialogComponent } from './components/calendar-user-filter-dialog/calendar-user-filter-dialog.component';
import { EventEditorComponent } from './components/event-editor/event-editor.component';
import { NgSelectModule } from '@ng-select/ng-select';

FullCalendarModule.registerPlugins([
  timeGridPlugin,
  interactionPlugin,
  dayGridPlugin
]);

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    EventsRoutingModule,
    FullCalendarModule,
    NgSelectModule
  ],
  declarations: [
    EventEditorDialogComponent,
    EventEditorComponent,
    CalendarComponent,
    CalendarUserFilterDialogComponent
  ],
  exports: [
    EventEditorComponent,
    EventEditorDialogComponent,
    CalendarComponent,
    CalendarUserFilterDialogComponent
  ],
  providers: [
    EventsService
  ]
})
export class EventsModule {
  constructor() { }
}
