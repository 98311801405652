import { Component, Injector, Input, OnInit } from '@angular/core';
import { Configuration } from '../../../../../models/configuration.model';
import { RoleList, ThirdPartyCredential, ThirdPartyCredentialArray } from '../../../../../models';
import { Constants } from '../../../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { AppraisalCredentialModalComponent } from './appraisal-credential-modal/appraisal-credential-modal.component';
import { cloneDeep } from 'lodash';
import { ScopeType } from '../integrations.component';

@Component({
	selector: 'appraisal',
	templateUrl: './appraisal.component.html'
})
export class AppraisalComponent extends ApplicationContextBoundComponent implements OnInit {

	@Input() 
	appraisalOrderingEnabled: Configuration;

	@Input() 
	appraisalCredentials: ThirdPartyCredentialArray;

	@Input()
	scope: ScopeType;

	isSaving: boolean;
	roles: RoleList;
	constructor(
		private readonly injector: Injector,
		private readonly _modalService: NgbModal
	) {
		super(injector);
	}

	ngOnInit(): void {
		this.roles = this.applicationContext.globalConfig.roles;
	}

	openCredentialModal(credential: ThirdPartyCredential): void {
		const modalRef = this._modalService.open(AppraisalCredentialModalComponent, Constants.modalOptions.large);
		modalRef.componentInstance.credential = cloneDeep(credential);
		modalRef.componentInstance.scope = this.scope;
		modalRef.componentInstance.roles = this.roles;
		modalRef.result.then(
			(result) => {
				const index = this.appraisalCredentials.findIndex(el => el.credentialId === result.credentialId);
				if (index > -1) {
					this.appraisalCredentials[index] = result;
				} else {
					this.appraisalCredentials.push(result);
				}
			},
			() => { }
		);
	}
}
