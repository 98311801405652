<div [ngClass]="{'is-invalid': agentListIdsControl && agentListIdsControl.touched && agentListIdsControl.invalid}">
  <ng-multiselect-dropdown
    [placeholder]="'-- Select --'"
    [settings]="multiSelectSettings"
    [data]="allAgentsList"
    [formControl]="agentListIdsControl"
    [required]="isAgentTypeRequired"
  ></ng-multiselect-dropdown>
</div>
<div class="invalid-feedback" *ngIf="agentListIdsControl && agentListIdsControl.touched && agentListIdsControl.invalid">This field is required</div>

<div class="input-group mt-2" *ngIf="!agentType">
  <input
    type="text"
    class="form-control"
    placeholder="New Tag"
    [(ngModel)]="newListTag"
    [ngModelOptions]="{standalone: true}"
    #model="ngModel"
  />
  <span class="input-group-append">
    <button
      class="btn btn-soft-primary border"
      type="button"
      [disabled]="!newListTag"
      (click)="addNewTag()"
    >
      Add New Tag
    </button>
  </span>
</div>
