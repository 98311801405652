import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditReport } from 'src/app/models';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CreditService } from '../../services/credit.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'credit-report-history',
  templateUrl: 'credit-report-history.component.html',
  styleUrls: ['./credit-report-history.component.scss'],
})
export class CreditReportHistoryComponent implements OnInit {
  private _creditHistory: CreditReport[];
  successfulCreditRuns: CreditReport[] = [];
  failedCreditRuns: CreditReport[] = [];
  historyToShow: CreditReport[] = [];
  currentStatusFilter: string = 'all';

  @Input()
  set creditHistory(creditHistory: CreditReport[]) {
    creditHistory.forEach((history) => {
      history['creationDate'] = history.dateUpdated
        ? new Date(history.dateUpdated)
        : new Date(history.dateInserted);
    });

    const successfulJointCreditRuns = creditHistory.filter(cr => cr.joint && cr.successful);
    var crGroups = _.groupBy(
      successfulJointCreditRuns,
      (x) => x.integrationHistoryId
    );
    Object.keys(crGroups).forEach((key) => {
      if (crGroups[key].length > 1) {
        let histItem = cloneDeep(crGroups[key][1]);
        histItem.borrowerName += ' & ' + crGroups[key][0].borrowerName;
        histItem.showImport = true;
        this.successfulCreditRuns.push(histItem);
      } else {
        let histItem = cloneDeep(crGroups[key][0]);
        histItem.showImport = true;
        this.successfulCreditRuns.push(histItem);
      }
    });

    const successfulSingleCreditRuns = creditHistory.filter(cr => !cr.joint && cr.successful);
    successfulSingleCreditRuns.forEach((cr) => {
      let histItem = cloneDeep(cr);
      histItem.showImport = true;
      this.successfulCreditRuns.push(histItem);
    });
    this.failedCreditRuns = creditHistory.filter((r) => !r.successful);
    this._creditHistory = _.orderBy(
      this.successfulCreditRuns.concat(this.failedCreditRuns),
      (x) => x['creationDate'],
      'desc'
    );
    this.historyToShow = this.filterHistory();

    this.historyToShow.forEach(h => {
      h.equifax = h.equifaxScore || h.equifax;
      h.experian = h.experianScore || h.experian;
      h.transUnion = h.transUnionScore || h.transUnion;
      h.refNumber = h.referenceNumber || h.refNumber;
      h.date = h.creditRunDate || h.date;
    });
  }

  get creditHistory(): CreditReport[] {
    return this._creditHistory;
  }

  @Input()
  creditInfoId: number;

  isSubmitError: boolean = false;
  errorMessage: string;

  @Output()
  creditRunCompleted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  reissueSucceeded: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  importLiabilitiesClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private readonly _creditService: CreditService,
    private readonly _loanDocService: LoanDocService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit() {
    this.historyToShow = this.filterHistory();
  }

  onCreditReportFilterChanged = () => {
    this.historyToShow = this.filterHistory();
  };

  onReissueClicked = (integrationHistoryId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._creditService.reissueCreditReport(integrationHistoryId).subscribe(
      (result) => {
        this.reissueSucceeded.emit(integrationHistoryId);
        this._spinner.hide();
      },
      (error) => {
        this._spinner.hide();
        this.showError(error.errorMessage);
        this._notifyService.showError(
          error ? error.error.message : 'Error trying to re-Issue.',
          'Failure!'
        );
      }
    );
  };

  openLoanDoc = (loanDocId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(
      (result) => {
        if (result && result.docFiles.length) {
          let fileGuid = result.docFiles[0].guid;
          this._loanDocService.viewPdfFile(fileGuid).subscribe((data) => {
            this._spinner.hide();
            const blob = new Blob([data], {
              type: data['type'] || 'application/pdf',
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });
        } else {
          this._spinner.hide();
          this.showError('No File exist.');
        }
      },
      (error) => {
        this._spinner.hide();
        this.showError(error.errorMessage);
      }
    );
  };

  closeErrors = () => {
    this.isSubmitError = false;
  };

  openReport = (integrationHistoryId: number) => {
    this.closeErrors();
    this._spinner.show();
    this._creditService.getReportContent(integrationHistoryId).subscribe(
      (data) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: data['type'] || 'text/html' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this._spinner.hide();
        this.showError(error.message);
      }
    );
  };

  numberOfReportsJointWith = (creditReport: CreditReport) => {
    if (!creditReport.joint) {
      return 1;
    }
    if (creditReport.joint) {
      const reportsThatAreJointwithThisOne = this.creditHistory.filter(
        (r) =>
          r.referenceNumber === creditReport.referenceNumber &&
          r.joint == creditReport.joint
      );
      return reportsThatAreJointwithThisOne.length;
    }
  };

  openImportLiabilitiesTab = (creditInfoId: number) => {
    this.closeErrors();
    this.importLiabilitiesClicked.emit(creditInfoId);
  };

  filterHistory = (): CreditReport[] => {
    switch (this.currentStatusFilter) {
      case 'success':
        return this.successfulCreditRuns;
      case 'error':
        return this.failedCreditRuns;
      default:
        return this.creditHistory;
    }
  };

  private showError = (message: string) => {
    this.isSubmitError = true;
    this.errorMessage = message;
  };
}
