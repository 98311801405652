import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { assign } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AppraisalCondition } from 'src/app/models/appraisal/appraisal-condition.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { AppraisalService } from 'src/app/services/appraisal.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { AddAppraisalConditionDialogComponent } from '../add-appraisal-condition-dialog/add-appraisal-condition-dialog.component';

@Component({
  selector: 'appraisal-conditions',
  templateUrl: './appraisal-conditions.component.html',
  styleUrls: ['./appraisal-conditions.component.scss']
})
export class AppraisalConditionsComponent implements OnInit, OnChanges {
  @Input() appraisalOrderId: number;
  @Input() isReadonly: boolean = false;
  @Input() conditionCategories: Array<EnumerationItem>;
  conditions: Array<AppraisalCondition> = [];
  actionInProgressForId: number | null;
  conditionsLoading: boolean;
  savingAppraisalCondition: boolean;
  constructor(
    private readonly _appraisalService: AppraisalService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    public datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getAppraisalConditions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appraisalOrderId?.firstChange) {
      return;
    }
    if (changes.appraisalOrderId?.currentValue !== changes.appraisalOrderId?.previousValue) {
      this.getAppraisalConditions();
    }
  }

  openConditionsDialog() {
    const modalRef = this._modalService.open(AddAppraisalConditionDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.conditionCategories = this.conditionCategories;
    modalRef.result.then((newCondition: AppraisalCondition) => {
      this.conditions.push({
        thirdPartyConditionId: newCondition.thirdPartyConditionId,
        conditionCategory: newCondition.conditionCategory,
        conditionText: newCondition.conditionText,
        conditionCategoryName: this.getConditionDisplayName(newCondition.conditionCategory),
        status: 'New',
        requestedOn: '',
        approvedOn: ''
      });
    }, () => { });
  }

  getAppraisalConditions() {
    this.conditionsLoading = true;
    this._appraisalService.getAppraisalConditions(this.appraisalOrderId)
      .pipe(finalize(() => this.conditionsLoading = false))
      .subscribe(res => {
        this.conditions = res.map((condition: AppraisalCondition) => ({
          thirdPartyConditionId: Number(condition.thirdPartyConditionId),
          conditionText: condition.conditionText,
          conditionCategoryName: this.getConditionDisplayName(condition.conditionCategory),
          conditionCategory: condition.conditionCategory,
          status: condition.status,
          requestedOn: condition.requestedOn ? this.datePipe.transform(condition.requestedOn, 'short') : '',
          approvedOn: condition.approvedOn ? this.datePipe.transform(condition.approvedOn, 'short') : ''
        }))
      }, err => {
        this._notifyService.showError(err ? err.message || err : "Error", "Unable to load appraisal condition due to unknown reason.");
      });
  }

  getConditionDisplayName(conditionCategory: string) {
    const matchingCategory = this.conditionCategories.find(cat => cat.value === conditionCategory);
    return matchingCategory ? matchingCategory.name : '';
  }

  saveAppraisalCondition() {
    const request: Partial<AppraisalCondition>[] = this.conditions.filter(condition => condition.thirdPartyConditionId < 0)
      .map(condition => ({
        conditionText: condition.conditionText,
        conditionCategory: condition.conditionCategory,
      }));
    if (request.length === 0) {
      this._notifyService.showWarning('conditions are already saved...', 'Warning!');
      return;
    }
    this.savingAppraisalCondition = true;
    this._appraisalService.saveAppraisalConditions(this.appraisalOrderId, request)
      .pipe(finalize(() => this.savingAppraisalCondition = false))
      .subscribe(res => {
        this._notifyService.showSuccess('Condition saved successfully.', 'Success');
        this.getAppraisalConditions();
      }, err => {
        this._notifyService.showError(err ? err.error.message : 'Unable to save appraisal condition due to unknown reason.', 'Error!');
      });
  }

  denyCondition(id: number) {
    const self = this;
    const msg = 'Are you sure you want to deny this condition?';
    this.showConfirmationDialog('Confirm Deny', msg, true, false)
      .then(function (response) {
        if (!response.isConfirmed) {
          return;
        }
        self.actionInProgressForId = id;
        self._appraisalService.denyAppraisalCondition(self.appraisalOrderId, [{
          thirdPartyConditionId: id,
          note: response.value ? response.value : null
        }])
          .pipe(finalize(() => self.actionInProgressForId = null))
          .subscribe(res => {
            self._notifyService.showSuccess('Condition denied successfully.', 'Success');
            self.getAppraisalConditions();
          }, err => {
            self._notifyService.showError(err ? err.message : 'Unable to deny appraisal condition due to unknown reason.', 'Error!');
          })
      }, function () {
      })
  };

  cancelCondition(id: number) {
    const self = this;
    const msg = 'Are you sure you want to cancel this condition?';
    this.showConfirmationDialog('Confirm Cancel', msg, true, true)
      .then(function (response) {
        if (!response.isConfirmed) {
          return;
        }
        self.actionInProgressForId = id;
        self._appraisalService.cancelAppraisalCondition(self.appraisalOrderId, [{
          thirdPartyConditionId: id,
          note: response.value
        }])
          .pipe(finalize(() => self.actionInProgressForId = null))
          .subscribe(res => {
            self._notifyService.showSuccess('Condition cancelled successfully.', 'Success');
            self.getAppraisalConditions();
          }, err => {
            self._notifyService.showError(err ? err.message : 'Unable to cancel appraisal condition due to unknown reason.', 'Error!');
          })
      }, function () {
      })
  };

  removeCondition(id: number) {
    this.removeConditionRow(id);
  }

  approveCondition(id: number) {
    const self = this;
    const msg = 'Are you sure you want to approve this condition?';
    this.showConfirmationDialog('Confirm Approve', msg, false)
      .then(function (response) {
        if (!response.isConfirmed) {
          return;
        }
        self.actionInProgressForId = id;
        self._appraisalService.approveAppraisalCondition(self.appraisalOrderId, [{
          thirdPartyConditionId: id
        }])
          .pipe(finalize(() => self.actionInProgressForId = null))
          .subscribe(res => {
            self._notifyService.showSuccess('Condition approved successfully.', 'Success');
            self.getAppraisalConditions();
          }, err => {
            self._notifyService.showError(err ? err.message : 'Unable to approve appraisal condition due to unknown reason.', 'Error!');
          })
      }, function () {
      })
  }

  removeConditionRow(id: number) {
    const index = this.conditions.findIndex(condition => condition.thirdPartyConditionId === id);
    this.conditions.splice(index, 1);
  }

  private showConfirmationDialog(heading: string, confirmationMessage: string, showComment: boolean, commentRequired: boolean = false) {
    const alertOptions: SweetAlertOptions = {
      title: heading,
      text: confirmationMessage,
      icon: 'question',
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      showCancelButton: true
    };
    if (showComment) {
      _.assignIn(alertOptions, {
        input: 'textarea',
        inputLabel: `Comment(${commentRequired ? 'Required' : 'Optional'})`,
        inputPlaceholder: 'Type your comment here...',
        inputAttributes: {
          'aria-label': 'Type your comment here'
        },
        inputValidator: (value: string) => {
          if (!value && commentRequired) {
            return 'Comment is required.'
          }
        }
      });
    }
    return Swal.fire(alertOptions)
  }

  get hasNewConditions() {
    return this.conditions.some(condition => condition.thirdPartyConditionId < 0);
  }
}
