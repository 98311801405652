<ng-template #mainContent>
    <ng-continer *ngIf="currentView === 'CalendarView'">
        <full-calendar [options]="calendarOptions"></full-calendar>
    </ng-continer>
    <ng-continer *ngIf="currentView === 'ListView'">
        <business-days-list-view [holidayEvents]="mergedCalendarDays"
            (onShowCalendarViewClicked)="currentView = 'CalendarView'"
            (onEditEventClicked)="onListViewEventClick($event)"></business-days-list-view>
    </ng-continer>
</ng-template>

<admin-page-template [hasPageActionBar]="false" [pageMainContentTemplate]="mainContent">
</admin-page-template>
<drawer [title]="'Business Calendar'" [name]="'businessCalendarEventDrawer'" [templateRef]="businessCalendarContentRef"
    [options]="drawerOptions">
</drawer>
<ng-template #businessCalendarContentRef>
    <upsert-calendar-event [holidayEvent]="currentHolidayEvent"
        (close)="onDrawerClosed($event)"></upsert-calendar-event>
</ng-template>