<div class="card tpo-report-card" style="min-height: 199px;">
  <div class="card-header">
    <h4 class="card-title"><i class="fas fa-users"></i> Borrowers({{borrowers.length}})</h4>
  </div>
  <div class="card-body" style="margin-top: -15px;">
    <ul class="list-group custom-list-group mb-n1">
      <li class="list-group-item align-items-center d-flex justify-content-between" *ngIf="primaryBorrower">
          <div class="media">
              <div class="media-body align-self-center">
                  <h6 class="mt-0 mb-1">{{primaryBorrower.lastName + ", " + primaryBorrower.firstName}}</h6>
                  <ul class="list-inline mb-0 text-muted">
                      <li class="list-inline-item me-2"><span><i class="fas fa-map-marker-alt me-2 text-secondary font-14"></i></span>{{primaryBorrower.mailingStreet}}</li>
                      <li class="list-inline-item me-2"><span><i class="far fa-envelope me-2 text-secondary font-14"></i></span>{{primaryBorrower.email}}</li>
                  </ul>
                  <ul class="list-inline mb-0 text-muted">
                    <li class="list-inline-item me-2"><span><i class="fas fa-phone me-2 text-secondary font-14"></i></span>{{primaryBorrower.mobilePhone ?
                      '(M) ' + (primaryBorrower.mobilePhone | phone) :
                      '(H) ' + (primaryBorrower.homePhone | phone)}}</li>
                    <li class="list-inline-item me-2"><span><i class="fas fa-star me-2 text-secondary font-14"></i></span>{{primaryBorrower.creditScore}}</li>
                </ul>
              </div>
          </div>
      </li>
      <li class="list-group-item align-items-center d-flex justify-content-between" *ngIf="coBorrower">
        <div class="media">
            <div class="media-body align-self-center">
                <h6 class="mt-0 mb-1">{{coBorrower.lastName + ", " + coBorrower.firstName}}</h6>
                <ul class="list-inline mb-0 text-muted">
                    <li class="list-inline-item me-2"><span><i class="fas fa-map-marker-alt me-2 text-secondary font-14"></i></span>{{coBorrower.mailingStreet}}</li>
                    <li class="list-inline-item me-2"><span><i class="far fa-envelope me-2 text-secondary font-14"></i></span>{{coBorrower.email}}</li>
                </ul>
                <ul class="list-inline mb-0 text-muted">
                  <li class="list-inline-item me-2"><span><i class="fas fa-phone me-2 text-secondary font-14"></i></span>{{coBorrower.mobilePhone ?
                    '(M) ' + (coBorrower.mobilePhone | phone) :
                    '(H) ' + (coBorrower.homePhone | phone)}}</li>
                  <li class="list-inline-item me-2"><span><i class="fas fa-star me-2 text-secondary font-14"></i></span>{{coBorrower.creditScore}}</li>
              </ul>
            </div>
        </div>
    </li>
  </ul>
  </div>
</div>
