import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CreateFeeModel } from '../../../models/fee/create-fee.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateFeeModalComponent } from '../modals/create-fee-modal/create-fee-modal.component';
import { NgForm } from '@angular/forms';
import { FeeValidationModel } from '../../../models/fee/fee-validation.model';
import { FeeSectionEnum } from '../../../models/fee/fee-section.enum';
import { FeeService } from '../../../services/fee.service';
import { CalculatedFeeTypeEnum } from '../../../models/fee/calculated-fee-type.enum';
import { Constants } from 'src/app/services/constants';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import Swal from 'sweetalert2';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'other-fees',
  templateUrl: 'other-fees.component.html',
  styleUrls: ['other-fees.component.scss']
})
export class OtherFeesComponent implements AfterViewInit {

  @ViewChild('otherForm') otherForm: NgForm;

  @Input()
  set fees(fees: LoanFee[]) {
    this._fees = fees.filter(fee => fee.feeSection === this.feeSection && !this._feeService.isRealEstateFee(fee));
  }

  get fees(): LoanFee[] {
    return this._fees;
  }

  @Input()
  feeSystemDetails: FeeSystemDetails;

  @Output() onFeeAdded = new EventEmitter<CreateFeeModel>();
  @Output() onFeeValueUpdated = new EventEmitter<LoanFee>();
  @Output() onFeeRemoved = new EventEmitter<string>();
  @Output() onValidationChanged = new EventEmitter<FeeValidationModel>();
  @Output() openFeeEditor = new EventEmitter<LoanFee>();

  feeSection = FeeSectionEnum.Other;
  feesTotal = 0;
  isValidForm = true;

  private _fees: LoanFee[] = [];

  constructor(
    private modalService: NgbModal,
    private readonly _feeService: FeeService
  ) {
  }

  ngAfterViewInit(): void {
    if (this.otherForm) {
      this.otherForm.statusChanges.subscribe(status => this.onFormValidationStatusChanged(status));
    }
  }

  scrollToFirstInvalidControl() {
    Utils.validateAndScrollToFirstInvalidControl(this.otherForm);
  }

  onFormValidationStatusChanged = (status: string) => {
    this.isValidForm = status !== "INVALID";
    this.onValidationChanged.next({ section: this.feeSection, calculatedType: null, isValid: this.isValidForm });
    if (!this.isValidForm) {
      this.otherForm.form.markAllAsTouched();
    }
  }

  openAddFeeModal() {

    const modalRef = this.modalService.open(CreateFeeModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.feeSystemDetails = this.feeSystemDetails;
    modalRef.componentInstance.hudNumbersToExclude = [...new Set(this._fees.map(fee => fee.hudNumber))].filter(hud => hud);
    modalRef.componentInstance.feeTypesToExclude = [...new Set(this._fees.map(fee => fee.feeType))].filter(feeType => feeType);
    modalRef.componentInstance.title = 'Create Other Fee';
    modalRef.componentInstance.allowFreeformFeeEntry = true;
    modalRef.componentInstance.restrictHudNumberEntryBySectionType = true;
    modalRef.componentInstance.feeSection = this.feeSection;
    modalRef.componentInstance.calculatedFeeType = CalculatedFeeTypeEnum.Default;

    modalRef.result.then(
      (data: CreateFeeModel) => {
        if (data.hudNumber && data.hudNumber.length === 4) {
          const existingFee = this._fees.find(fee => fee.hudNumber === data.hudNumber);
          if (existingFee) {
            Swal.fire({
              title: 'Fee Exists',
              html: `<span>A fee with HUD# <strong>${existingFee.hudNumber}</strong> already exists.</span>`,
              icon: 'warning',
              confirmButtonText: 'OK'
            });
          } else {
            this.onFeeAdded.next(data);
          }
        } else {
          let hudNumberToUse = this.findUnusedHudNumber();
          data.hudNumber = hudNumberToUse.toString();
          this.onFeeAdded.next(data);
        }
      }, () => { });
  }

  removeFee(fee) {
    this.onFeeRemoved.next(fee);
  }

  updateFee = (fee: LoanFee) => {
    this.onFeeValueUpdated.emit(fee);
  }

  private findUnusedHudNumber = (): number => {
    let hudNumberToUse = 1800;
    if (this._fees && this._fees.length) {
      for (let i = 1800; i <= 1899; i++) {
        const existingFee = this._fees.find(fee => fee.hudNumber === i.toString());
        if (!existingFee) {
          hudNumberToUse = i;
          break;
        }
      }
    }
    return hudNumberToUse;
  }

  onFeeEditorOpened = (fee: LoanFee) => {
    this.openFeeEditor.emit(fee);
  }
}
