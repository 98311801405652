import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { NextGenLayoutComponent } from './components/nextgen-layout.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { RightNavComponent } from './components/right-nav/right-nav.component';
import { RightSubNavComponent } from './components/right-sub-nav/right-sub-nav.component';
import { MetismenuAngularModule } from '@metismenu/angular';
import { DialerModule } from '../../dialer/dialer.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { LoanActivityModule } from '../../loan-activity/loan-activity.module';
import { TasksModule } from '../../tasks/tasks.module';
import { RolePickerDialogComponent } from './components/role-picker-dialog/role-picker-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    MetismenuAngularModule,
    DialerModule,
    LayoutModule,
    LoanActivityModule,
    TasksModule,
  ],
  declarations: [
    HeaderComponent,
    SideNavComponent,
    NextGenLayoutComponent,
    RightNavComponent,
    RightSubNavComponent,
    RolePickerDialogComponent
  ],
  exports: [

  ],
  providers:[]
})
export class NextGenLayoutModule {}
