import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { intersection } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { finalize } from 'rxjs/operators';
import {
  ContactListColumnDefinition,
  KeyDateConfig,
  LoanStatus,
  RoleList,
} from 'src/app/models';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Task } from 'src/app/models/task/task.model';
import { ExpressionBuilderDialog } from 'src/app/modules/expression-builder/expression-builder-dialog.component';
import { ExpressionBuilderData } from 'src/app/modules/expression-builder/expression-builder.model';
import { ExpressionBuilderDialogService } from 'src/app/modules/expression-builder/services/expression-builder-dialog.service';
import { ChannelService } from 'src/app/services/channel.service';
import { Constants } from 'src/app/services/constants';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'upsert-key-date',
  templateUrl: 'upsert-key-date-dialog.component.html',
})
export class UpsertKeyDateDialog implements OnInit {
  @Input() keyDate: KeyDateConfig;
  @Input() companyId: number;
  @Input() lookupData: {
    roles: RoleList;
    enabledChannels: EnumerationItem[];
    loanStatuses: Array<LoanStatus>;
    taskStatuses: Array<EnumerationItem>;
    tasks: Array<Task>;
    allKeyDateTypes: Array<EnumerationItem>;
    usedKeyDateTypes: Array<{ keyDateConfigurationId: number, keyDateType: string; channels: string }>;
  };
  @Input() expressionFields: ContactListColumnDefinition[];
  @Input() baseMessageTitle: string;
  @Output()
  onKeyDateConvertedToExpressionAndSaved = new EventEmitter<KeyDateConfig>();

  @Output()
  onKeyDateChangeConvertDirection = new EventEmitter<KeyDateConfig>();

  @Output()
  onSave: EventEmitter<KeyDateConfig> = new EventEmitter<KeyDateConfig>();

  @Output()
  onClose: EventEmitter<never> = new EventEmitter<never>();

  @ViewChild('upsertKeyDateForm') upsertKeyDateForm: NgForm | undefined;

  editMode: boolean;
  saving: boolean;
  converting: boolean;
  loadingExpression: boolean;

  multiselectChannels: Array<EnumerationItem> = [];
  multiselectEditByRole: Array<EnumerationItem> = [];

  multiselectChannelsModel: EnumerationItem[] = [];
  multiselectEditByRoleModel: EnumerationItem[]  = [];
  filteredKeyDateTypes: EnumerationItem[] = [];
  keyDateExpressions: ExpressionGroup;
  availableLoanStatusForChannel: EnumerationItem[] = [];
  showAsMonthYear: boolean;

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _keyDateServices: KeyDatesService,
    private readonly _notificationService: NotificationService,
    private readonly _channelService: ChannelService,
    private readonly _expressionBuilderDialogService: ExpressionBuilderDialogService
  ) { }

  ngOnInit(): void {
    this.editMode = this.keyDate.keyDateConfigurationId ? true : false;
    this.showAsMonthYear = this.keyDate.pipelineDateFormat == "MMM, yyyy";

    if (this.keyDate.expressionGroupId) {
      this.getExpression();
    }

    this.initDefaultSelectOption();

    this.setMultiSelectData();
    this.filterKeyDateTypesAndLoanStatuses();
  }

  handleLoanStatusChange() {
    if (this.keyDate.loanStatusId) {
      this.keyDate.taskId = null;
      this.keyDate.taskStatus = null;
      this.keyDate.systemTaskType = null;
    }
  }

  handleSystemTaskTypeChange() {
    if (!this.keyDate.taskId && !this.keyDate.systemTaskType) {
      this.keyDate.taskStatus = null;
    }
    if (this.keyDate.systemTaskType) {
      this.keyDate.taskId = null;
      this.keyDate.loanStatusId = null;
    }
  }

  handleTaskChange() {
    if (!this.keyDate.taskId && !this.keyDate.systemTaskType) {
      this.keyDate.taskStatus = null;
    }
    if (this.keyDate.taskId) {
      this.keyDate.loanStatusId = null;
      this.keyDate.systemTaskType = null;
    }
  }

  pipelineDateFormatChanged = () => {
    if (this.showAsMonthYear) {
      this.keyDate.pipelineDateFormat = "MMM, yyyy";
    } else {
      this.keyDate.pipelineDateFormat = null
    }
  }

  onExpressionClick() {
    this.openExpressionDialog(this.keyDateExpressions);
  }

  convertToExpression() {
    this.converting = true;
    this._keyDateServices
      .convertKeyDateToExpression(
        this.companyId,
        this.keyDate.keyDateConfigurationId
      )
      .pipe(
        finalize(() => {
          this.converting = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.keyDate = res;
          this.getExpression();
          this.initDefaultSelectOption();
          this.onKeyDateChangeConvertDirection.emit(this.keyDate);
          this.convertKeyDateIntoExpressionMessageSuccess();
        },
        error: ({ error }) => this.convertKeyDateIntoExpressionMessageError(error)
      });
  }

  revertExpressionConversion() {
    this.converting = true;
    this._keyDateServices
      .revertExpressionConversion(
        this.companyId,
        this.keyDate.keyDateConfigurationId
      )
      .pipe(
        finalize(() => {
          this.converting = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.keyDate = res;
          this.keyDateExpressions = null;
          this.initDefaultSelectOption();
          this.onKeyDateChangeConvertDirection.emit(this.keyDate);
          this.revertExpressionConversionMessageSuccess();
        },
        error: ({ error }) => {
          this.revertExpressionConversionMessageError(error);
        }
      });
  }

  saveAndConvertToExpression() {
    if (this.upsertKeyDateForm) {
      this.upsertKeyDateForm.form.markAllAsTouched();
      if (!this.upsertKeyDateForm.form.valid) return;
    }

    this.saving = true;
    this.checkValuesBeforeRequests();

    this._keyDateServices.insertKeyDate(this.companyId, this.keyDate)
      .subscribe({
        next: (keyDate) => {
          this.keyDate = keyDate;

          this.upsertKeyDateMessageSuccess('insert');

          this._keyDateServices
            .convertKeyDateToExpression(
              this.companyId,
              this.keyDate.keyDateConfigurationId
            )
            .pipe(
              finalize(() => {
                this.saving = false;
              })
            )
            .subscribe({
              next: (res) => {
                this.editMode = true;
                this.keyDate.expressionGroupId = res.expressionGroupId;
                this.getExpression();
                this.onKeyDateConvertedToExpressionAndSaved?.emit(this.keyDate)

                this.convertKeyDateIntoExpressionMessageSuccess();
              },
              error: ({ error }) => this.convertKeyDateIntoExpressionMessageError(error)
            });
        },
        error: ({ error }) => {
          this.saving = false;
          this.upsertKeyDateMessageError(error, 'insert');
        }
      });
  }

  save() {
    if (!this.upsertKeyDateForm) return;
    this.upsertKeyDateForm.form.markAllAsTouched();
    if (!this.upsertKeyDateForm.form.valid) return;

    this.checkValuesBeforeRequests();

    // please keep it below checkValuesBeforeRequests()
    if (!this.isKeyDateValid()) return;

    this.saving = true;

    if (!this.keyDateExpressions && this.keyDate.expressionGroupId) {
      this._expressionBuilderDialogService.deleteExpression(this.keyDate.expressionGroupId)
        .subscribe({
          next: (result) => {
            this.keyDate.expressionGroupId = null;
            this.editMode ? this.update() : this.insert();
          },
          error: (error) => {
            this._notificationService.showError(
              error?.message || 'Unable to save expression group(s) and key date.',
              'Key Date'
            );
            this.saving = false;
          }
        })
    } else {
      if (this.keyDateExpressions) {
        this._expressionBuilderDialogService.saveExpressionGroups(this.keyDateExpressions)
          .subscribe({
            next: (result) => {
              this._notificationService.showSuccess(
                'Expression group(s) saved successfully',
                'Expression Groups'
              );
              this.keyDate.expressionGroupId = result.expressionGroupId;
              this.editMode ? this.update() : this.insert();
            },
            error: (error) => {
              this._notificationService.showError(
                error?.message || 'Unable to save expression group(s) and key date.',
                'Key Date'
              );
              this.saving = false;
            }
          });
      }
      else {
        this.editMode ? this.update() : this.insert();
      }

    }
  }

  close() {
    this.onClose.emit();
  }

  channelSelectionChanged() {
    this.filterKeyDateTypesAndLoanStatuses();
  }

  filterKeyDateTypesAndLoanStatuses() {
    if (this.lookupData.enabledChannels.length === 0) {
      const restrictedKeyDates = this.lookupData.usedKeyDateTypes.filter(x => x.keyDateType != this.keyDate.keyDateType).map(kdt => kdt.keyDateType);

      this.filteredKeyDateTypes = this.lookupData.allKeyDateTypes
        .filter(kd => kd.value === this.keyDate.keyDateType || !restrictedKeyDates.includes(kd.value));
      this.availableLoanStatusForChannel = this.lookupData.loanStatuses.map(ls => ({
        value: Number(ls.loanStatusId),
        name: ls.loanStatusName
      }));
      return;
    }
    let restrictedKeyDates = [];
    if (this.multiselectChannelsModel.length === 0) {
      restrictedKeyDates = this.lookupData.usedKeyDateTypes
        .filter(kdt => !kdt.channels && kdt.keyDateType != this.keyDate.keyDateType)
        .map(kdt => kdt.keyDateType);
    } else {
      restrictedKeyDates = this.lookupData.usedKeyDateTypes
        .filter(kdt => this.filterUsedKeyDateTypesByChannel(kdt.channels) && kdt.keyDateType != this.keyDate.keyDateType)
        .map(kdt => kdt.keyDateType);
    }
    if (restrictedKeyDates.includes(this.keyDate.keyDateType)) {
      this.keyDate.keyDateType = null;
    }
    this.filteredKeyDateTypes = this.lookupData.allKeyDateTypes
      .filter(kd => kd.value === this.keyDate.keyDateType || !restrictedKeyDates.includes(kd.value));

    this.availableLoanStatusForChannel = this.lookupData.loanStatuses
      .filter(ls => this.multiselectChannelsModel.length === 0 || this.filterUsedKeyDateTypesByChannel(ls.enabledChannels))
      .map(ls => ({
        value: ls.loanStatusId,
        name: ls.loanStatusName
      }));
    if (!this.availableLoanStatusForChannel.find(ls => ls.value == this.keyDate.loanStatusId)) {
      this.keyDate.loanStatusId = null;
    }
    if (!this.availableLoanStatusForChannel.find(ls => ls.value == this.keyDate.updateToLoanStatusId)) {
      this.keyDate.updateToLoanStatusId = null;
    }
  }

  private filterUsedKeyDateTypesByChannel(channels: string) {
    if (!channels) {
      return false;
    }

    const channelList = this._channelService.getChannelsFromCommaDelimitedString(channels)
      .map(item => item.name);
    return intersection(this.multiselectChannelsModel.map(c => c.value), channelList).length > 0;
  }

  private update() {
    this._keyDateServices
      .updateKeyDate(this.companyId, this.keyDate)
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.upsertKeyDateMessageSuccess('updated');
          this.onSave.emit(res);
        },
        error: ({ error }) => {
          this.upsertKeyDateMessageError(error, 'update');
        }
      });
  }

  private insert() {
    this._keyDateServices
      .insertKeyDate(this.companyId, this.keyDate)
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe({
        next: (res) => {
          this.upsertKeyDateMessageSuccess('insert');
          this.onSave.emit(res);
        },
        error: ({ error }) => {
          this.upsertKeyDateMessageError(error, 'insert');
        }
      });
  }

  private checkValuesBeforeRequests() {
    this.keyDate.editByRole = this.multiselectEditByRoleModel?.map(rm => rm.value)?.join() || null;
    this.keyDate.enabledChannels = this.multiselectChannelsModel?.map(rm => rm.value)?.join() || null;
    if (!this.keyDate.taskId && !this.keyDate.systemTaskType) {
      this.keyDate.taskStatus = null;
    }
    if (this.keyDate.loanStatusId) {
      this.keyDate.taskId = null;
      this.keyDate.taskStatus = null;
      this.keyDate.systemTaskType = null;
    }
    if (this.keyDate.taskId || this.keyDate.systemTaskType) {
      this.keyDate.loanStatusId = null;
    }
  }

  private setMultiSelectData() {
    this.setMultiSelectDataForEnabledChannels();
    this.setMultieslectDataForEditByRole();
  }

  private setMultiSelectDataForEnabledChannels() {
    this.multiselectChannels = this.lookupData.enabledChannels.map(
      (channel) => ({
        name: channel.name,
        value: channel.name,
      })
    );

    this.multiselectChannelsModel = this._channelService
      .getChannelsFromCommaDelimitedString(this.keyDate.enabledChannels)
  }

  private setMultieslectDataForEditByRole() {
    this.multiselectEditByRole = this.lookupData.roles.map((role) => ({
      value: role.roleId.toString(),
      name: role.roleName,
    }));

    const selectedRoles = this.keyDate.editByRole?.split(',');
    if (selectedRoles && selectedRoles.length) {
      this.multiselectEditByRoleModel = this.multiselectEditByRole.filter(r => {
        const match = selectedRoles.find(roleId => roleId == r.value);
        return !!match;
      });
    }
    else {
      this.multiselectEditByRoleModel = [];
    }
  }

  private openExpressionDialog(expressionGroup: ExpressionGroup) {
    const modalRef = this._modalService.open(ExpressionBuilderDialog, Constants.modalOptions.large);
    modalRef.componentInstance.expressionBuilderData = <ExpressionBuilderData>{
      expressionGroup: expressionGroup,
      fields: this.expressionFields,
    };
    modalRef.componentInstance.companyId = this.companyId;
    modalRef.componentInstance.saveExpression.subscribe((expressionGroup) => {
      if (expressionGroup) {
        this.keyDateExpressions = expressionGroup;
      }

      modalRef.close();
    });

    modalRef.componentInstance.deleteExpression.subscribe(() => {
      if (this.keyDate.expressionGroupId) {
        this.keyDateExpressions = null;
      }

      modalRef.close();
    })
  }

  private initDefaultSelectOption() {
    if (!this.keyDate.loanStatusId) {
      this.keyDate.loanStatusId = null;
    }
    if (!this.keyDate.taskId) {
      this.keyDate.taskId = null;
    }
    if (!this.keyDate.taskStatus) {
      this.keyDate.taskStatus = null;
    }
    if (!this.keyDate.systemTaskType) {
      this.keyDate.systemTaskType = null;
    }
    if (!this.keyDate.updateToLoanStatusId) {
      this.keyDate.updateToLoanStatusId = null;
    }
  }

  private getExpression() {
    this.loadingExpression = true;
    this._keyDateServices
      .getExpressiosForKeyDate(this.keyDate.expressionGroupId)
      .pipe(finalize(() => this.loadingExpression = false))
      .subscribe({
        next: (res) => {
          this.keyDateExpressions = res;
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't load expresions for this key date",
            this.baseMessageTitle
          );
        }
      });
  }

  // checks wether key date is duplicated or not
  private isKeyDateValid(): boolean {
    // validation not needed
    if (!this.keyDate.keyDateType) {
      return true;
    }

    const types = this.lookupData.usedKeyDateTypes?.filter(t => t.keyDateType === this.keyDate.keyDateType && t.keyDateConfigurationId != this.keyDate.keyDateConfigurationId);
    if (!types || types.length === 0) return true;

    // both dont have channels
    if (types.some(t => !t.channels) && !this.keyDate.enabledChannels) {
      this._notificationService.showError("Key date with provided type and without channels already exists.", "Error!");
      return false
    }

    const keyDateChannelsArr = this.keyDate.enabledChannels?.replaceAll(" ", "")?.split(",") || [];

    // both have at least one channel shared
    const haveAtLeastOneTheSameChannel = types.some(t => {
      const tChannels = t.channels?.replaceAll(" ", "")?.split(",") || [];
      return keyDateChannelsArr.some(c => tChannels.indexOf(c) > -1);
    });
    if (haveAtLeastOneTheSameChannel) {
      this._notificationService.showError("Key date with provided type and with at least one the same channel already exists.", "Error!");
      return false
    }

    return true;
  }

  // messages
  private upsertKeyDateMessageSuccess(type: string) {
    this._notificationService.showSuccess(
      `Key date ${type} successfully`,
      this.baseMessageTitle
    );
  }

  private upsertKeyDateMessageError(error, type: string) {
    this._notificationService.showError(
      error?.message || `Couldn't ${type} key date`,
      this.baseMessageTitle
    );
  }

  private convertKeyDateIntoExpressionMessageSuccess() {
    this._notificationService.showSuccess(
      'Converted key date into the expression successfully',
      this.baseMessageTitle
    );
  }

  private convertKeyDateIntoExpressionMessageError(error) {
    this._notificationService.showError(
      error?.message || "Couldn't convert key date to the expresion",
      this.baseMessageTitle
    );
  }

  private revertExpressionConversionMessageSuccess() {
    this._notificationService.showSuccess(
      'Reverted expression conversion successfully',
      this.baseMessageTitle
    );
  }

  private revertExpressionConversionMessageError(error) {
    this._notificationService.showError(
      error?.message || "Couldn't revert expression conversion",
      this.baseMessageTitle
    );
  }
}
