<div class="modal-header">
  <h5 class="modal-title"> Repricing Fields </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <button class="btn btn-primary pull-right" (click)="addFieldSpec()">
        Add Field Spec
      </button>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 25%;">Field Name</th>
            <th style="width: 15%">Default Value</th>
            <th style="width: 15%;">Field Access</th>
            <th style="width: 15%;">Reprice Field Access</th>
            <th style="width: 15%;">Hidden Enum Options</th>
            <th style="width: 15%;" class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="pricingConfig">
          <tr *ngFor="let fieldSpec of pricingConfig.fieldSpecs, let i = index">
            <td style="width: 25%;">
              <p-dropdown id="field_{{fieldSpec['id']}}" name="field_{{fieldSpec['id']}}" styleClass="w-100"
                [options]="lodaFieldsEnumList" [(ngModel)]="fieldSpec.fieldName"
                (ngModelChange)="onFieldNameChanged(fieldSpec)" [showClear]="true" [filter]="true" filterBy="name"
                optionLabel="name" optionValue="value" placeholder="Pick a field">
              </p-dropdown>
            </td>
            <td style="width: 15%;">

              <!-- List input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName) == 'List'">
                <p-multiSelect
                  [options]="fieldOptionsMap.get(fieldSpec.fieldName)" [ngModel]="selectedOptionsMap.get(fieldSpec.fieldName)"
                  (ngModelChange)="multiSelectChanged($event, fieldSpec)" selectedItemsLabel="{0} selected" optionLabel="name" optionValue="value"
                  name="defaultValue_{{fieldSpec['id']}}" ngDefaultControl styleClass="w-100"
                  defaultLabel="Choose Options">
                </p-multiSelect>
              </ng-container>

              <!-- Enum input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName) == 'Enum'">
                <select class="form-select" [(ngModel)]="fieldSpec.defaultValue" id="defaultValue_{{fieldSpec['id']}}"
                  name="defaultValue_{{fieldSpec['id']}}">
                  <option [ngValue]="null">-- Select One --</option>
                  <option [ngValue]="opt.value" *ngFor="let opt of fieldOptionsMap?.get(fieldSpec.fieldName)">
                    {{opt.name}}
                  </option>
                </select>
              </ng-container>

              <!-- string input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName) == 'System.String' || !fieldTypeMap.get(fieldSpec.fieldName)">
                <input id="defaultValue_{{fieldSpec['id']}}" name="defaultValue_{{fieldSpec['id']}}" type="text"
                  class="form-control" [(ngModel)]="fieldSpec.defaultValue" placeholder="Default Value">
              </ng-container>

              <!-- number input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName)?.includes('System.Int32')">
                <input class="form-control numeric-without-arrows" name="defaultValue_{{fieldSpec['id']}}"
                  id="defaultValue_{{fieldSpec['id']}}" type="number" numeric [(ngModel)]="fieldSpec.defaultValue" />
              </ng-container>

              <!-- decimal input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName)?.includes('System.Decimal')">
                <input name="'desiredPrice'" name="defaultValue_{{fieldSpec['id']}}"
                  id="defaultValue_{{fieldSpec['id']}}" numeric [decimals]="3" class="form-control"
                  [(ngModel)]="fieldSpec.defaultValue" />
              </ng-container>

              <!-- boolean input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName)?.includes('System.Boolean')">
                <select class="form-select" [(ngModel)]="fieldSpec.defaultValue" id="defaultValue_{{fieldSpec['id']}}"
                  name="defaultValue_{{fieldSpec['id']}}">
                  <option [ngValue]="null">-- Select One --</option>
                  <option [ngValue]="'true'">Yes</option>
                  <option [ngValue]="'false'">No</option>
                </select>
              </ng-container>

              <!-- date input -->
              <ng-container *ngIf="fieldTypeMap.get(fieldSpec.fieldName)?.includes('System.DateTimeOffset')">
                <date-input [name]="'defaultValue_' + fieldSpec['id']" [id]="'defaultValue_' + fieldSpec['id']"
                  [(ngModel)]="fieldSpec.defaultValue">
                </date-input>
              </ng-container>

            </td>
            <td style="width: 15%;">
              <select class="form-select" [(ngModel)]="fieldSpec.fieldAccess" id="fieldAccess_{{fieldSpec['id']}}"
                name="fieldAccess_{{fieldSpec['id']}}">
                <option [ngValue]="null">-- Select One --</option>
                <option [ngValue]="opt.value" *ngFor="let opt of fieldAccessOptions">{{opt.name}}</option>
              </select>
            </td>
            <td style="width: 15%;">
              <select class="form-select" [(ngModel)]="fieldSpec.repriceFieldAccess"
                id="repriceFieldAccess_{{fieldSpec['id']}}" name="repriceFieldAccess_{{fieldSpec['id']}}">
                <option [ngValue]="null">-- Select One --</option>
                <option [ngValue]="opt.value" *ngFor="let opt of fieldAccessOptions">{{opt.name}}</option>
              </select>
            </td>
            <td style="width: 15%;">
              <div class="w-100"  *ngIf="['Enum', 'List'].includes(fieldTypeMap.get(fieldSpec.fieldName))">
                <p-multiSelect
                  [options]="fieldOptionsMap.get(fieldSpec.fieldName)" [(ngModel)]="fieldSpec.hiddenEnumOptions"
                  selectedItemsLabel="{0} selected" optionLabel="name" optionValue="value"
                  name="hiddenEnumOptions_{{fieldSpec['id']}}" ngDefaultControl styleClass="w-100"
                  defaultLabel="Choose Options">
                </p-multiSelect>
              </div>
            </td>
            <td style="width: 15%;" class="text-center">
              <button *ngIf="!(activeDeletionIndex === i)" type="button" title="Delete" class="btn btn-danger btn-sm"
                (click)="onDeleteButtonClicked(i)"><i class="fa fa-trash"></i>
              </button>
              <button *ngIf="activeDeletionIndex === i" type="button" title="Accept" class="btn btn-success btn-sm me-2"
                (click)="onDeleteConfirmClicked(fieldSpec)"><i class="fa fa-check"></i>
              </button>
              <button *ngIf="activeDeletionIndex === i" title="Cancel" type="button" class="btn btn-danger btn-sm"
                (click)="onDeleteCancelled()"><i class="fa fa-close"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button *ngIf="pricingConfig?.pricingConfigurationId" type="button" class="btn btn-danger right"
    (click)="deleteConfig()">
    Delete
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveConfig()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
