import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Goal } from '../../models/goal.model';
import { Table } from 'primeng/table';
import { catchError, map, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { splitCamelCase, toProperCase } from '../../../../../core/services/string-utils';
import { CurrencyPipe } from '@angular/common';
import { GoalDependencies, GoalService } from '../../services/goal.service';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../../services/notification.service';

type KeyDateLike = {
  keyDateConfigurationId: number;
  displayName: string;
}

@Component({
  selector: 'goal-table',
  templateUrl: './goal-table.component.html',
  styleUrls: ['./goal-table.component.scss'],
})
export class GoalTableComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * This overrides the value from {@link GoalService.getGoals}, which is the
   * default value.
   */
  @Input() goalsOverride?: readonly Goal[];
  @Input() actions?: readonly GoalTableAction[];
  @Input() goalActions?: readonly GoalAction[];
  @Input() selectable: boolean = false;
  @Input() loadingOverride?: boolean;
  @Input() title?: string;

  @Input() set selectedGoals(value: readonly Goal[]) {
    this.selection = value.map(this.tableGoalFromGoal);
  }

  @Output() selectedGoalsChange: EventEmitter<Goal[]> =
    new EventEmitter<Goal[]>();

  protected goals: TableGoal[];
  protected selection: TableGoal[];

  private _fields: readonly string[] = Object.freeze([
    'name',
    'timeFrame',
    'keyDate',
    'targetUnits',
    'targetVolume',
    'targetProfit',
  ]);

  protected readonly columns: readonly Column[] = Object.freeze(
    this._fields.map((field, i) => ({
      field,
      header: toProperCase(splitCamelCase(field)),
      order: i + 1,
    })),
  );

  protected get columnCount(): number {
    let count = this.columns.length;
    if (this.hasActions) ++count;
    if (this.selectable) ++count;

    return count;
  }

  protected readonly sortableFields: Set<string> = Object.freeze(
    new Set(this._fields),
  );

  protected get selectionMode(): string {
    return this.selectable ? 'multiple' : 'none';
  }

  protected get hasActions(): boolean {
    return this.actions?.length > 0;
  }

  protected get hasGoalActions(): boolean {
    return this.goalActions?.length > 0;
  }

  private _loading: boolean = false;
  protected get loading(): boolean {
    return this._loading || this.loadingOverride;
  }

  private readonly _transformCurrency: (value: number) => string;

  private _keyDateLookup: Map<number, KeyDateLike> = new Map();

  private _goalChangesSubscription?: Subscription;
  private _destroyed$: ReplaySubject<void> = new ReplaySubject(1);

  constructor(
    private readonly _goalService: GoalService,
    private readonly _notificationService: NotificationService,
    currencyPipe: CurrencyPipe,
  ) {
    this._transformCurrency = (value: number) => currencyPipe.transform(value);
  }

  ngOnInit(): void {
    // If goalsOverride is set, then we don't need to subscribe to goal changes.
    // Because it is a responsibility of the parent component to update the
    // goalsOverride property when the goals change.
    // Instead, we can just initialize the goals with the goalsOverride value
    // in the ngOnChanges lifecycle hook.
    if (this.goalsOverride == null) {
      this.subscribeToGoalChanges();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();

    // Don't need to unsubscribe from goal changes as it is handled by the
    // _destroyed$ subject.
  }

  ngOnChanges(changes: SimpleChanges): void {
    const goalsOverrideChange = changes.goalsOverride;
    if (goalsOverrideChange != null) {
      const goalsOverride = goalsOverrideChange.currentValue;

      if (goalsOverride == null) {
        this.subscribeToGoalChanges();
      } else {
        this.initGoals(goalsOverride);
      }
    }
  }

  protected onSelectionChange(selection: readonly TableGoal[]): void {
    this.selection = [...selection];
    this.selectedGoalsChange.emit(selection.map(({goal}) => goal));
  }

  private setKeyDateLookup(
    keyDateGroups: readonly {keyDateConfigurations: KeyDateLike[]}[]
  ): void {
    this._keyDateLookup = new Map(
      keyDateGroups.flatMap(({ keyDateConfigurations }) =>
        keyDateConfigurations.map(
          ({ keyDateConfigurationId, displayName }) => [
            keyDateConfigurationId,
            { keyDateConfigurationId, displayName },
          ])));
  }

  private tableGoalFromGoal = (goal: Goal) => TableGoal.fromGoal({
      goal: goal,
      keyDate: this._keyDateLookup.get(goal.keyDateConfigurationId),
      transformCurrency: this._transformCurrency,
    },
  );

  private setTableGoals = (goals: readonly Goal[]): void => {
    this.goals = goals.map(this.tableGoalFromGoal);
  }

  private initGoals(goals: readonly Goal[]): void {
    const dependencies$ = this._keyDateLookup.size > 0
      ? of(undefined)
      : this.initGoalDependencies();

    dependencies$.pipe(
      takeUntil(this._destroyed$),
      map(() => goals),
    ).subscribe(this.setTableGoals);
  }

  private initGoalDependencies(): Observable<void> {
    this._loading = true;

    return this._goalService.getGoalDependencies().pipe(
      finalize(() => {
        this._loading = false;
      }),
      catchError((error) => {
        const message = error.message ||
          'An unknown error occurred while loading goal data.';
        console.error(message, error);
        this._notificationService.showError(message, 'Error');

        return of(
          new GoalDependencies({
            keyDateGroups: [],
            users: [],
          }),
        );
      }),
      tap((dependencies) => {
        this.setKeyDateLookup(dependencies.keyDateGroups);
      }),
      map(() => undefined),
    );
  }

  private subscribeToGoalChanges(): void {
    this._goalChangesSubscription?.unsubscribe();
    this._goalChangesSubscription = this._goalService.goalChanges$.pipe(
      takeUntil(this._destroyed$),
      catchError((error) => {
        const message = error?.message ||
          'An unknown error occurred while loading goals.';
        console.error(message, error);
        this._notificationService.showError(message, 'Error');

        return of([]);
      }),
    ).subscribe((goals) => this.initGoals(goals));
  }

  protected isColumnSortable(column: Column) {
    return this.sortableFields.has(column.field);
  }

  protected onInputSearch(event: Event, table: Table): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    table.filterGlobal(value, 'contains');
  }

  protected onClickAction(action: GoalTableAction): void {
    action.onAction();
  }

  protected onClickGoalAction(action: GoalAction, tableGoal: TableGoal): void {
    action.onAction(tableGoal.goal);
  }
}

interface Column {
  readonly field: string;
  readonly header: string;
  readonly order: number;
}

export class GoalTableAction {
  readonly template: TemplateRef<any>;
  readonly onAction: () => void;

  constructor({template, onAction}: GoalTableAction) {
    this.template = template;
    this.onAction = onAction;
  }
}

export class GoalAction {
  readonly template: TemplateRef<any>;
  readonly onAction: (goal: Goal) => void;

  constructor({ template, onAction }: GoalAction) {
    this.template = template;
    this.onAction = onAction;
  }
}

interface TableGoalArgs {
  readonly goal: Goal;
  readonly timeFrame: string;
  readonly keyDate: string;
  readonly targetVolume: string;
  readonly targetProfit: string;
}

// @ts-ignore
class TableGoal {
  readonly goal: Goal;
  readonly timeFrame: string;
  readonly keyDate: string;
  readonly targetVolume: string;
  readonly targetProfit: string;

  constructor({
                goal,
                timeFrame,
                keyDate,
                targetVolume,
                targetProfit,
              }: TableGoalArgs) {
    Object.assign(this, goal);
    this.goal = goal;
    this.timeFrame = timeFrame;
    this.keyDate = keyDate;
    this.targetVolume = targetVolume;
    this.targetProfit = targetProfit;
  }

  static fromGoal(
    { goal, keyDate, transformCurrency }: {
      goal: Goal,
      keyDate: KeyDateLike | null,
      transformCurrency: (value: number) => string,
    },
  ): TableGoal {
    const { timeFrame, targetVolume, targetProfit } = goal;

    return new TableGoal({
      goal,
      timeFrame: splitCamelCase(timeFrame),
      keyDate: keyDate?.displayName ?? '',
      targetVolume: transformCurrency(targetVolume),
      targetProfit: transformCurrency(targetProfit),
    });
  }
}
