import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'confirm-delete',
    templateUrl: './confirm-delete.component.html'
})
export class ConfirmDeleteComponent implements OnInit {
    @Input() list: Array<any>;
    @Input() index: number;

    isDeleteClicked = {};

    constructor() {}

    ngOnInit(): void {
        if (this.index === undefined) {
            this.index = Math.floor(Math.random() * Date.now())
        }
    }
}
