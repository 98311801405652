import { ApiInfo, IEnvironment } from 'src/app/core/services/environment/environment.interface';

const apiInfo = new ApiInfo();
apiInfo.apiBaseUrl = 'https://api.lodasoft.dev/';
apiInfo.publicApiBaseUrl = 'https://publicapi.lodasoft.dev/';
apiInfo.docuViewareApiBaseUrl = 'https://docapi.lodasoft.dev/';
apiInfo.jobApiBaseUrl = 'https://jobapi.lodasoft.dev/';

export const environment: IEnvironment = {
  production: false,
  enableDebugTools: true,
  environment: 'loda-dv',
  logLevel: 'debug',
  apiInfo,
  clientRoot: 'localhost:4457',
  firebase: {
    apiKey: "AIzaSyC7uR_HZ7mEguvfPwGaQRrhlPmU2T4nzZY",
    authDomain: "api-project-838789724427.firebaseapp.com",
    projectId: "api-project-838789724427",
    storageBucket: "api-project-838789724427.appspot.com",
    messagingSenderId: "838789724427",
    appId: "1:838789724427:web:7bb621b9daf5947aab4a0e",
    measurementId: "G-R7E5LG2X92",
    vapidKey: "BPX-LYY4Uy2FnxliZinOP0_FVEoULKEv5XgsIiVpGwTaoh6UIvKXdTlA0V28t5ipkz9Ah_HvFod00Of7VekXqek",
  },
  faviconUrl: 'https://api.lodasoft.dev/company/co/favicon',
};
