import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoanApplication, Role, UserProfile } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'confirm-responsible-party-change-dialog',
  templateUrl: './confirm-responsible-party-change-dialog.component.html',
  styleUrls: ['./confirm-responsible-party-change-dialog.component.scss']
})
export class ConfirmResponsiblePartyChangeDialogComponent implements OnInit {

  count: number;

  responsibilityPartyUserId: string = '';

  users: User[];

  application: LoanApplication;

  branchUsers: UserProfile[];

  externalCompanyUsers: UserProfile[];

  roles: Role[];

  constructor(public activeModal: NgbActiveModal,
    private readonly _internalContactsService: InternalContactsService,
    private _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (this.application?.externalCompanyId && this.application.externalCompanyId > 0) {
      this.loadExternalCompanyUsers();
    }
  }

  private loadExternalCompanyUsers = () => {
    this._spinner.show();
    this._internalContactsService.getExternalCompanyUsers(this.application.companyId, this.application.externalCompanyId).subscribe(result => {
      this.externalCompanyUsers = result;
      this.branchUsers = this.externalCompanyUsers.filter(el => el.branchId == this.application.branchId);
      this.branchUsers.forEach(bu => {
        const matchedRole = this.roles.find(r => r.roleId == bu.roleId);
        bu['roleName'] = matchedRole ? matchedRole.roleName : 'TPO Users';
      })
    }, error => {
      const errorMessage = error ? error.message : "An error occurred while saving external contact.";
      this._notifyService.showError(errorMessage, "Error!");
    }).add(() => this._spinner.hide());
  }

}
