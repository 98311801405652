<div class="accordion accordion-flush" id="accordionRelatedEntities" *ngIf="relatedEntities && (relatedEntities.agents.length > 0 ||
  relatedEntities.borrowers.length > 0 || relatedEntities.leads.length > 0)">
  <div class="accordion-item" *ngIf="relatedEntities.agents && relatedEntities.agents.length > 0">
    <h5 class="accordion-header m-0" id="accordion-agents">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{'collapsed': defaultOpen !== 'Agent'}"
        data-bs-target="#flush-collapseAgents" [aria-expanded]="defaultOpen === 'Agent'" aria-controls="flush-collapseAgents">
        Agents
      </button>
    </h5>
    <div id="flush-collapseAgents" class="accordion-collapse collapse" aria-labelledby="accordion-agents"
      [ngClass]="{'show': defaultOpen === 'Agent'}"
      data-bs-parent="#accordionRelatedEntities">
      <div class="accordion-body">
        <div class="row">
          <div class="card related-entities-card" *ngFor="let agent of relatedEntities.agents" style="width: 23rem;">
            <ng-container *ngIf="agent">
              <div class="card-header" (click)="onEditAgentClicked(agent)">
                <h4 class="card-title">
                  {{agent.firstName + ' ' + agent.lastName + ' ('+ agent.applications.length +')'}}
                </h4>
              </div>
            </ng-container>
            <ng-container *ngIf="agent.applications && agent.applications.length > 0">
              <div style="max-height: 266px; overflow: auto;">
                <div class="card-item" *ngFor="let app of agent.applications"
                  [routerLink]="['/' + applicationMode + '/app-details/' + app.applicationId]">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="d-flex">
                        <div class="media-body align-self-center text-truncate">
                          <div class="d-flex">
                            <i class="fas fa-map-pin text-danger font-16" style="margin-top: 1px;"></i>
                            <h4 class="m-0 fw-semibold text-dark font-15 white-space-normal ms-2"> {{app.mailingStreet
                              ||
                              'N/A'}} </h4>
                          </div>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark"> Status :
                            </span>
                            {{app.loanStatusName || 'N/A'}} </p>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark"> Purpose :
                            </span>
                            {{app.loanPurposeName || 'N/A'}} </p>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark"> Type : </span>
                            {{app.loanTypeName || 'N/A'}} </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div>
                        <h5 class="font-15 m-0 fw-bold"> {{app.loanAmount | currency}} </h5>
                        <p class="mb-0 fw-semibold text-end"> Amount </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <span *ngIf="!agent.applications.length" class="p-3"> No Applications </span>
          </div>
          <span *ngIf="relatedEntities.agents && relatedEntities.agents.length === 0"> No Agents </span>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="relatedEntities.borrowers && relatedEntities.borrowers.length > 0">
    <h5 class="accordion-header m-0" id="accordion-borrowers">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{'collapsed': defaultOpen !== 'Borrower'}"
        data-bs-target="#flush-collapseBorrowers" [aria-expanded]="defaultOpen === 'Borrower'" aria-controls="flush-collapseBorrowers">
        Borrowers
      </button>
    </h5>
    <div id="flush-collapseBorrowers" class="accordion-collapse collapse" aria-labelledby="accordion-borrowers"
      [ngClass]="{'show': defaultOpen === 'Borrower'}"
      data-bs-parent="#accordionRelatedEntities">
      <div class="accordion-body">
        <div class="row">
          <div class="card related-entities-card" *ngFor="let borrower of relatedEntities.borrowers"
            style="width: 23rem;">
            <ng-container *ngIf="borrower">
              <div class="card-header d-flex" (click)="onEditBorrowerClicked(borrower)">
                <i class="fas fa-user-tie text-primary mt-1" style="margin-right: 0.65rem;"></i>
                <h4 class="card-title" style="font-size: 13px;">
                  {{borrower.firstName + ' ' + borrower.lastName + ' ('+ borrower.applications.length +')'}}
                </h4>
              </div>
            </ng-container>
            <ng-container *ngIf="borrower.applications && borrower.applications.length > 0">
              <div style="max-height: 266px; overflow: auto;">
                <div class="card-item" *ngFor="let app of borrower.applications"
                  [routerLink]="['/' + applicationMode + '/app-details/' + app.applicationId]">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="d-flex">
                        <div class="media-body align-self-center text-truncate">
                          <div class="d-flex">
                            <i class="fas fa-map-pin text-danger font-16" style="margin-top: 1px;"></i>
                            <h4 class="m-0 fw-semibold text-dark font-15 white-space-normal ms-2"> {{app.mailingStreet
                              ||
                              'N/A'}} </h4>
                          </div>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark"> Status :
                            </span>
                            {{app.loanStatusName || 'N/A'}} </p>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark"> Purpose :
                            </span>
                            {{app.loanPurposeName || 'N/A'}} </p>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark"> Type : </span>
                            {{app.loanTypeName || 'N/A'}} </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div>
                        <h5 class="font-15 m-0 fw-bold"> {{app.loanAmount | currency}} </h5>
                        <p class="mb-0 fw-semibold text-end"> Amount </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <span *ngIf="!borrower.applications.length" class="p-3"> No Applications </span>
          </div>
          <span *ngIf="relatedEntities.borrowers && relatedEntities.borrowers.length === 0"> No Borrowers </span>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="relatedEntities.leads && relatedEntities.leads.length > 0">
    <h5 class="accordion-header m-0" id="accordion-leads">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{'collapsed': defaultOpen !== 'Lead'}"
        data-bs-target="#flush-collapseLeads" [aria-expanded]="defaultOpen === 'Lead'" aria-controls="flush-collapseLeads">
        Leads
      </button>
    </h5>
    <div id="flush-collapseLeads" class="accordion-collapse collapse" aria-labelledby="accordion-leads"
      [ngClass]="{'show': defaultOpen === 'Lead'}"
      data-bs-parent="#accordionRelatedEntities">
      <div class="accordion-body">
        <div class="row">
          <div class="card related-entities-card" *ngFor="let lead of relatedEntities.leads" style="width: 23rem;">
            <ng-container *ngIf="lead">
              <div class="card-header d-flex" (click)="onEditLeadClicked(lead)">
                <i class="fas fa-flag text-primary mt-1" style="margin-right: 0.65rem;"></i>
                <h4 class="card-title" style="font-size: 13px;">
                  {{lead.firstName + ' ' + lead.lastName + (lead.presentAddress1 ? ' - ' + lead.presentAddress1 :
                  '')}}
                </h4>
              </div>
              <div style="max-height: 266px; overflow: auto;">
                <div class="card-item" (click)="onEditLeadClicked(lead)">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="d-flex">
                        <div class="media-body align-self-center text-truncate">
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark">
                              Loan Status Name :
                            </span>
                            {{lead.loanStatusName || 'N/A'}} </p>
                          <p class="text-muted mb-0 font-13 white-space-normal"><span class="text-dark">
                              Loan Purpose Name :
                            </span>
                            {{lead.loanPurposeName || 'N/A'}} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <span *ngIf="relatedEntities.leads && relatedEntities.leads.length === 0"> No Leads </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center align-items-center vertical-center" *ngIf="!relatedEntities || (relatedEntities.agents.length == 0 &&
  relatedEntities.borrowers.length == 0 && relatedEntities.leads.length == 0)">
  <div class="alert alert-primary border-0 b-round" role="alert">
    No related entities were found for this phone number.
  </div>
</div>

