<div class='card'>
  <div class='card-header'>
    <h4 class='card-title'>License Info</h4>
  </div>

  <div class='card-body'>
    <div *ngIf='error' class='alert alert-danger'>
      <strong>Error:</strong> {{ error }}
    </div>

    <ng-container *ngIf='items; else loading'>
      <div #itemsGrid *ngIf='items.length; else noDataState' class='item-grid'>
        <ng-container *ngFor='let item of items; let last = last'>
          <div>
            {{ item.label }}
          </div>

          <div [style.grid-column]='last ? lastItemColumnSpan : null'>
            <ng-container *ngIf='item.template as template; else textValue'>
              <ng-container *ngTemplateOutlet='template; context: { $implicit: item.value }'>
              </ng-container>
            </ng-container>
            <ng-template #textValue>
              {{ item.value }}
            </ng-template>
          </div>
        </ng-container>
      </div>

      <ng-template #noDataState>
        <div class='text-muted no-data-state'>
          No data available
        </div>
      </ng-template>
    </ng-container>

    <ng-template #loading>
      <div class='li-loading'>
        <span class='spinner-border spinner-border-sm'></span>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #templateStatusBadge let-status>
  <license-info-status-badge [status]='status'></license-info-status-badge>
</ng-template>

<ng-template #templateFlagBadge let-value>
  <license-info-badge
    [variant]='value ? "success" : "error"'
    minWidth='8ch'
  >
    <span>{{ value ? "Yes" : "No" }}</span>
  </license-info-badge>
</ng-template>
