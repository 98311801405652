<div class="inline-editor input-group"
  [ngClass]="{
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }"
  [hidden]="editorMode === 'Inline' && !isEditActive && !(model && model.touched && model.invalid)"
>
  <input
    type="text"
    class="form-control no-right-border"
    [dropSpecialCharacters]="false"
    [validation]="false"
    #control
    [pattern]="pattern"
    [mask]="mask"
    [placeholder]="placeHolder"
    [name]="name"
    [id]="id"
    [(ngModel)]="value"
    #model="ngModel"
    [readonly]="readonly"
    [required]="required"
    [disabled]="disabled"
    (focus)="isEditActive = true"
    (blur)="onBlur($event)"
    [ngClass]="{ 'is-invalid': model && model.touched && model.invalid }"
  />
  <span class="input-group-append">
    <button
      class="btn btn-default border"
      type="button"
      id="search-zip-code-btn"
      [disabled]="disabled"
      (click)="searchZipcode(selectionsDropdown)"
    >
      <span
        *ngIf="loadingZipcodes"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>

      <i class="fa fa-search" *ngIf="!loadingZipcodes"></i>
    </button>
  </span>

  <button
    class="btn p-0 border-0 bg-transparent ms-1"
    title="Apply"
    type="button"
    *ngIf="editorMode == 'Inline'"
    (mousedown)="onApplyClicked($event)"
  >
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button
    class="btn p-0 border-0 bg-transparent ms-1"
    title="Cancel"
    type="button"
    *ngIf="editorMode == 'Inline'"
    (mousedown)="onCancelClicked($event)"
  >
    <i class="fa fa-times-circle text-danger"></i>
  </button>

  <div class="invalid-feedback" *ngIf="model.errors && model.errors.required">
    This field is required
  </div>
  <div class="invalid-feedback" *ngIf="model.errors && model.errors.pattern">
    This field is in invalid format
  </div>
</div>

<div ngbDropdown #selectionsDropdown="ngbDropdown" class="w-100">
  <a ngbDropdownToggle style="display: none"></a>
  <div ngbDropdownMenu class="w-100 overflow-auto">
    <span *ngIf="suggestions.length == 0" class="mx-2">No records found</span>
    <button
      ngbDropdownItem
      *ngFor="let suggestion of suggestions"
      type="button"
      class="px-1"
      (click)="selectZipcode(suggestion)"
    >
      {{ suggestion.city }} / {{ suggestion.county }} , {{ suggestion.state }}
    </button>
  </div>
</div>

<span
  [ngClass]="{
    'empty-field': value == null || value == undefined || value == ''
  }"
  class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}"
  title="Click to Edit"
  *ngIf="
    editorMode == 'Inline' && !isEditActive && !(model && model.touched && model.invalid)"
  (click)="onEditModeToggledOn()"
>
  <span class="val" *ngIf="value">{{value}}</span>
  <span class="placeholder" *ngIf="!value">--</span>
</span>

