<div class='card'>
  <div class='card-header'>
    <h4 class='card-title'>
      Custom Info
    </h4>
  </div>

  <div class='card-body'>
    <custom-data-info
      [customData]='docPrep.customData'
      title='Custom Info'
    ></custom-data-info>
  </div>
</div>
