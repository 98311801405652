<div class="row mt-1">
	<ng-container *ngIf="!isLoading; else loadingSpinner">
		<div class="col-md-12" *ngIf="scope === 'Company'">
			<div class="col-md-12">
				<toggle-configuration [title]="'Enable LOS Integration'" [id]="'losEnabled'" [configuration]="losEnabled">
				</toggle-configuration>
			</div>
			<div class="col-md-12 mt-1">
				<hr class="mt-3 mb-0" />
			</div>
		</div>
		<div class="d-flex align-content-end flex-wrap"
			*ngIf="(['User', 'TpoUser'].includes(scope) || (scope === 'Company' && losEnabled.value)) && credentials && credentialSchema">
			<ng-container *ngIf="['User', 'TpoUser'].includes(scope); else: companyContent">
				<div class="mt-4 me-2" *ngFor="let credential of credentials.credentials">
					<credential-item-v2 [scope]="scope" [credential]="credential" [credentials]="credentials.credentials"
						[isTpoUser]="credentials.isTpoUser" [saveAndTest]="credentials.saveAndTest"
						[companyCredentials]="credentials.companyCredentials" [credentialSchema]="credentialSchema"
						[userCompanyGuid]="userCompanyGuid"></credential-item-v2>
				</div>
			</ng-container>
			<ng-template #companyContent>
				<div class="mt-4 me-2" *ngFor="let credential of credentials">
					<credential-item-v2 [scope]="scope" [credential]="credential" [credentials]="credentials"
						[credentialSchema]="credentialSchema" [userCompanyGuid]="userCompanyGuid"></credential-item-v2>
				</div>
			</ng-template>
			<div class="mt-4 me-2">
				<create-credential-v2 [scope]="scope" [credentialType]="'LosVendor'" [userCompanyGuid]="userCompanyGuid"
					[credentials]="['User', 'TpoUser'].includes(scope) ? credentials.credentials : credentials"
					[isTpoUser]="scope !== 'Company' ? credentials.isTpoUser : false"
					[saveAndTest]="scope !== 'Company' ? credentials.saveAndTest : false"
					[companyCredentials]="['User', 'TpoUser'].includes(scope) ? credentials.companyCredentials : []"
					[credentialSchema]="credentialSchema"></create-credential-v2>
			</div>
		</div>
		<div class="col-md-12" *ngIf="scope === 'Company'">
			<enable-message *ngIf="losEnabled.value === 0">Enable this feature above!</enable-message>
		</div>
	</ng-container>
	<ng-template #loadingSpinner>
		<div style="width: 100%;">
			<loading-indicator customClass="los-loading-indicator"></loading-indicator>
		</div>
	</ng-template>
</div>
