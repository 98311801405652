import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { KeyDatesHistoryDialogComponent } from './components/key-dates-history-dialog/key-dates-history-dialog.component';
import { KeyDatesComponent } from './components/key-dates.component';

@NgModule({
    imports: [
        SharedModule,
        TableModule
    ],
    declarations: [KeyDatesComponent, KeyDatesHistoryDialogComponent],
    exports: [KeyDatesComponent, KeyDatesHistoryDialogComponent],
    providers: [KeyDatesService]
})
export class KeyDatesModule {
  constructor() { }
}
