export class NotifyPartyLoanContact {
    userId: string;
    roleId: number;
    firstName: string;
    lastName: string;
    roleName: string;
    order: number;

    constructor(userId: string, roleId: number, firstName: string, lastName: string, roleName: string, order: number) {
        this.userId = userId;
        this.roleId = roleId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.roleName = roleName;
        this.order = order;
    }
}
