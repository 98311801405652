<div class="cash-out">
  <h3 class="co-style-1">
    <span class="co-style-2">Cash Out</span>
  </h3>

  <div class="co-style-3">
    <div class="co-style-4">
      <housing-calculator
        class="co-style-5"
        [mortgage]="mortgage"
        [parameters]="proposedParameters"
        [readOnlyAll]="true"
        (parametersChanged)="onParametersChanged($event)"
      ></housing-calculator>
    </div>
    <div class="co-style-6">
      <div class="co-style-7">
        <div class="co-style-8">
          <img class="co-style-9"
          [src]="imageData"
            style="width: 300px; height: auto; margin: auto"
          />
          <div class="co-style-10">
            <span class="form-control" readonly>
              {{cashOutAmount| currency: 'USD':'symbol':'.2':'en-US'}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

