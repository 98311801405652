<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="trackingInfoHistory">
    <div class="row">
        <p-table #dt1 [value]="trackingInfoHistory" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
            [globalFilterFields]="['actionValue','fullName','dateCreated']" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <span class="p-input-icon-right p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control" (ngModelChange)="dt1.first=0"
                            (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : ''">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-trackingInfo>
                <tr>
                    <td> {{trackingInfo.actionValue}} </td>
                    <td> {{trackingInfo.fullName}} </td>
                    <td> {{trackingInfo.dateCreated | date: dateFormat}} </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="text-center"> No tracking info found. </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Close </button>
</div>