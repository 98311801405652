export class TableConfig {
  columns: Column[];
  extraGlobalFilterFields?: string[] | null = null;
  rowHeight: RowHeight | null = RowHeight["60px"];
  scrollable: boolean = false;
  scrollOffset?: number | null = 255;
  localStorageKey?: string;
}

export class Column {
  field: string;
  sortField?: string;
  header: string;
  visible?: boolean;
  order?: number;
  dataType?: string;
  sortable?: boolean;
  minWidth?: string;
}

export enum RowHeight {
  '40px' = '40',
  '50px' = '50',
  '60px' = '60',
  '70px' = '70',
  '80px' = '80',
  '90px' = '90',
  '100px' = '100',
  '110px' = '110',
  '120px' = '120',
  '130px' = '130',
  '140px' = '140',
  '150px' = '150',
  '160px' = '160',
  '170px' = '170',
  '180px' = '180',
  '190px' = '190',
  '200px' = '200',
  '210px' = '210',
  '220px' = '220',
  '230px' = '230',
  '240px' = '240',
  '250px' = '250',
  '260px' = '260',
  '270px' = '270',
  '280px' = '280',
  '290px' = '290',
  '300px' = '300',
}

