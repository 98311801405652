<div class="card" *ngIf="esignConditions.length > 0">
  <p-table #eSignConditionTable [value]="esignConditions" [rows]="25" scrollHeight="flex" [scrollable]="false"
    [globalFilterFields]="['detail', 'borrower', 'description', 'requestDate', 'dueDate', 'status']"
    [showCurrentPageReport]="true" sortField="dueDate" sortMode="single" expandableRows="true" rowExpandMode="single"
    styleClass="p-datatable-gridlines conditions-data-table" dataKey="loanDocTaskId">
    <ng-template pTemplate="caption">
      <div class="p-d-flex" style="min-height: 34px;">
        <h4 class="d-flex me-5 m-0" style="font-size: 14px;"><i class="fas fa-file-signature me-2 m-auto"></i>
          <span class="m-auto"> Documents that Require eSigning </span>
        </h4>

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr (dragenter)="onDragEnterHeader($event)">
        <th *ngFor="let col of filteredTableColumns" [pSortableColumn]="col.sortable ? col.field : '' "
          [style]="col.style">
          {{col.header}}
          <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-expanded="expanded">
      <tr class="drop_zone" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)" (drop)="onDrop($event,row)">
        <td class="text-center">
          <div class="d-flex align-items-center justify-content-center w-100">
            <button *ngIf="row.docFiles?.length > 0" type="button" pButton pRipple [pRowToggler]="row"
              style="margin-left:-42px;" class="p-button-text p-button-rounded p-button-plain me-3"
              [icon]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></button>
            <a [disabled]="esignInProgress" (click)="esignTaskModal(row)" *ngIf="(['EsignDocument','LosEsign'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady' && row.userId == userId &&
            row.borrowerId == null" style="min-height: 48px; line-height: 48px;">
              <i *ngif="esignInProgress" class="fa fa-spin"></i> eSign
            </a>
            <a (click)="esignTaskModal(row)" [disabled]="true" *ngIf="(['EsignDocument','LosEsign'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady' && row.userId != userId &&
            row.borrowerId == null" style="min-height: 48px; line-height: 48px;">
              eSign Needed
            </a>
            <a (click)="resendEsignNotification(row)" *ngIf="(['EsignDocument','LosEsign'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady' &&
              row.borrowerId" style="min-height: 48px; line-height: 48px;">
              Send Reminder
            </a>
            <div class="dragover p-2 ms-auto">
              <span>DROP FILES HERE</span>
            </div>
          </div>
        </td>
        <td>
          <span style='overflow:auto' class="text-cell-primary"> {{row.description}} </span>
          <br />
          <span *ngIf="row.borrowerName" class="text-muted"> For: {{ row.borrowerName }}</span>
          <br />
          <span *ngIf="row.conditionText" class="wrap"
            style='font-style: italic;'> Condition Text:
            {{row.conditionText}} </span>
        </td>
        <td>
          <span class="text-cell-primary">
            {{row.dueDate | date: 'short'}}</span>
          <br />
          <span *ngIf="row.lastRequest" class="text-muted"> Requested {{ row.lastRequest }}</span>
        </td>
        <td class="text-center">
          <span *ngIf="row.taskStatus == 'Rejected'" [title]="row.note"
            class="badge badge-soft-danger cursor">{{row.taskStatus}}</span>
          <span *ngIf="row.taskStatus != 'Rejected'" class="badge badge-soft-info">{{row.taskStatus}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData>
      <tr *ngIf="rowData.docFiles?.length > 0">
        <td colspan="4">
          <table class="condtionLoanDocTable p-2">
            <thead>
              <tr>
                <th style="width: 24.5%;">File Name</th>
                <th style="width: 20%;">Created By</th>
                <th style="width: 20%;">Date Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let docFile of rowData.docFiles">
                <td>
                  <a [contextMenu]="basicMenu" [contextMenuSubject]="docFile" *ngIf="!docFile['shouldEdit']"
                    (dblclick)="onViewFileClicked(docFile)" class="px-2 text-ellipsis"
                    [title]="'Double Click for View: ' + docFile.fileName"> {{docFile.fileName}}
                  </a>
                  <div class="d-flex px-2" *ngIf="docFile['shouldEdit']">
                    <input style="width: 10rem;" type="text" class="form-control" name="docFile{{docFile.docFileId}}"
                      id="docFile{{docFile.docFileId}}" [(ngModel)]="docFile['fileNameWithoutExtension']" />
                    <div class="d-flex ms-2 mt-1">
                      <a (click)="onFileRenameConfirmed(docFile)">
                        <span> <i class="fa fa-check-circle text-success" style="font-size: small;"></i>
                        </span>
                      </a>
                      <a (click)="onFileRenameCancelled(docFile)">
                        <span class="ms-2"> <i class="fa fa-times text-danger" style="font-size: small;"></i> </span>
                      </a>
                    </div>
                  </div>
                </td>
                <td> <span class="pe-1 h-none" style="font-weight: 600;"> {{getInsertedBy(docFile.insertedBy)}} </span>
                </td>
                <td>
                  <span style="font-weight: 600;" class="h-none"> {{docFile.dateInserted | date:'short'}} </span>
                </td>
                <td>
                  <span>
                    <a class="dropdown-toggle ms-2" data-bs-toggle="dropdown" aria-haspopup="false"
                      aria-expanded="false" href="#" role="button">
                      <i class="fas fa-ellipsis-v" style="color: #828c96;"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <a class="dropdown-item" (click)="onViewFileClicked(docFile)">View Document</a>
                      <a class="dropdown-item" (click)="onShowMergeFilesClicked(docFile)">Modify Pages</a>
                      <a class="dropdown-item" (click)="onFileRenameClicked(docFile)">Rename</a>
                      <a class="dropdown-item" (click)="downloadDocument(docFile.loanDocId)">Download</a>
                      <a class="dropdown-item" (click)="comfirmDelete(docFile.guid)">Delete</a>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr style="height: 65px;">
        <td colspan="4" class="text-center"> There are no outstanding eSign tasks</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="card">
  <div *ngFor="let bucket of conditionBuckets" class="mb-3 p-2">
    <p-table #dt [value]="filteredList[bucket]" [paginator]="false" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
      scrollHeight="flex" [scrollable]="true" [globalFilterFields]="['detail', 'borrower', 'description',
        'requestDate', 'dueDate', 'status']" [showCurrentPageReport]="true" sortField="dueDate" sortMode="single"
      [sortOrder]="-1" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      styleClass="p-datatable-gridlines conditions-data-table" expandableRows="true" rowExpandMode="single"
      dataKey="loanDocTaskId">
      <ng-template pTemplate="caption">
        <div class="p-d-flex" style="min-height: 34px;">
          <h4 class="d-flex me-5 m-0" style="font-size: 14px;"><i class="fas fa-paper-plane me-2 m-auto"></i>
            <span class="m-auto"> {{bucket}} Conditions {{forSubmission ? 'Required for Submission' : ''}} </span>
            <span *ngIf="applicationState[bucket] != 'All' && !loading"
              class="m-auto ms-4">({{filteredList[bucket].length}} of
              {{conditionCount[bucket]}} {{applicationState[bucket]}})</span>
          </h4>
          <div class="btn-group ms-auto" role="group" [hidden]="hideFilter">
            <input type="radio" class="btn-check" name="btnradio_{{bucket}}" id="all_{{bucket}}"
              [(ngModel)]="applicationState[bucket]" value="All" autocomplete="off"
              (click)="filterApplications(bucket,'All')" checked="applicationState[bucket] == 'All'">
            <label class="btn btn-secondary" for="all_{{bucket}}">All</label>
            <input type="radio" class="btn-check" name="btnradio_{{bucket}}" id="outstanding_{{bucket}}"
              [(ngModel)]="applicationState[bucket]" value="Outstanding" autocomplete="off"
              (click)="filterApplications(bucket,'Outstanding')" checked="applicationState[bucket] == 'Outstanding'">
            <label class="btn btn-secondary" for="outstanding_{{bucket}}">Outstanding</label>
            <input type="radio" class="btn-check" name="btnradio_{{bucket}}" id="pending_{{bucket}}"
              [(ngModel)]="applicationState[bucket]" value="PendingApproval" autocomplete="off"
              (click)="filterApplications(bucket,'PendingApproval')"
              checked="applicationState[bucket] == 'PendingApproval'">
            <label class="btn btn-secondary" for="pending_{{bucket}}">TPO Submitted</label>
            <input type="radio" class="btn-check" name="btnradio_{{bucket}}" id="completed_{{bucket}}"
              [(ngModel)]="applicationState[bucket]" value="Completed" autocomplete="off"
              (click)="filterApplications(bucket,'Completed')" checked="applicationState[bucket] == 'Completed'">
            <label class="btn btn-secondary" for="completed_{{bucket}}">Cleared</label>
          </div>
          <span class="p-input-icon-right p-ml-auto" [hidden]="hideFilter">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control" (ngModelChange)="dt.first=0"
              (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td style="width: 65px;">
            <div class="d-flex align-items-center justify-content-center">
              <a *ngIf="['EsignDocument','OnlineApplication','DigitalAssetVerification','GenerateDocument','RequestAppraisal','LosEsign','GatherPayment','PerformAction'].indexOf(row.taskType) == -1"
                (click)="editTaskModal(row)">
                <i class="fas fa-upload" style="color:#1f9ad6 !important"></i>
              </a>
              <a *ngIf="['PerformAction'].indexOf(row.taskType) > -1 && ['Pending', 'Rejected'].indexOf(row.taskStatus) > -1"
                href="javascript:void(0);" (click)="markTaskComplete(row)">
                Mark Complete
              </a>
              <a (click)="docGenTaskModal(row)"
                *ngIf="(['GenerateDocument'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady'">
                Start
              </a>
              <a (click)="editTaskModal(row)"
                *ngIf="(['RequestAppraisal','GatherPayment'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady'">
                Start
              </a>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between">
              <div style="max-width: 80%;">
                <ng-container
                  *ngIf="row.docTypeName && row.description && (row.description != row.conditionText); else noDescription">
                  <span class="text-cell-primary" class="wrap" style="max-width: 900px;">
                    {{row.docTypeName}} - {{row.description}}
                  </span>
                </ng-container>
                <ng-template #noDescription>
                  <span *ngIf="row.docTypeName && !row.description" class="text-cell-primary">{{row.docTypeName}} -
                  </span>
                  <span *ngIf="row.description && row.description != row.conditionText"
                    class="text-cell-primary wrap">{{row.description}}</span>
                  <span *ngIf="row.description && row.description == row.conditionText"
                    class="text-cell-primary">Condition</span>
                </ng-template>
                <div class="ms-3 my-1">
                  <!-- <br *ngIf="row.borrowerName"> -->
                  <span *ngIf="row.borrowerName" class="text-muted"> For: {{ row.borrowerName }}</span>
                </div>
                <div class="my-1">
                  <span class="wrap"> {{row.conditionText ||
                    'N/A'}} </span>
                </div>
                <div class="ms-3">
                  <div *ngIf="!(row.docFiles?.length)">
                    <div class="text-muted">
                      There are no files attached to this condition.
                    </div>
                  </div>
                  <div *ngIf="row.docFiles?.length">
                    <div class="text-muted">
                      There are {{row.docFiles.length}} file(s) attached to this condition. <a style="color: #35aaef;"
                        (click)="row.showAllDocs = !row.showAllDocs;"> {{ row.showAllDocs ? 'Hide All' : 'Show
                        All'}}</a>
                    </div>
                  </div>
                  <div *ngIf="row.docFiles?.length && row.showAllDocs">
                    <div *ngFor="let file of docFilesFilter(row); let index = index" [ngClass]="{'b-top': index != 0}">
                      <view-file [allowDelete]="true" [allowMergeSelect]="false" [displaySingleRow]="true" [file]="file"
                        (fileDeleted)="onFileDeleted($event, row)" (fileRenamed)="onFileRenamed($event, row)"
                        [appId]="row.applicationId" [globalConfig]="applicationContext.globalConfig"
                        (viewDocFile)="onViewFileClicked($event)">
                      </view-file>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <span class="text-muted"><i>Due date: {{row.dueDate | date:'MM/dd/yyyy h:mm:ss
                    a'}}</i></span><br *ngIf="row.lastRequest">
                <span *ngIf="row.lastRequest" class="text-muted"> <i> Requested {{ row.lastRequest }} </i> </span>
              </div>
            </div>
          </td>
          <td class="text-center" style="width: 100px;">
            <span *ngIf="row.taskStatus == 'Rejected'" [title]="row.note"
              class="badge badge-soft-danger cursor">{{row.taskStatus}}</span>
            <span *ngIf="row.taskStatus != 'Rejected'" class="badge badge-soft-info">{{row.taskStatus}}</span>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="rowexpansion" let-rowData>
        <tr>
          <td colspan="4">
            <table class="condtionLoanDocTable p-2">
              <thead>
                <tr>
                  <th style="width: 24.5%;">File Name</th>
                  <th style="width: 20%;">Created By</th>
                  <th style="width: 20%;">Date Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let docFile of rowData.docFiles">
                  <td>
                    <a [contextMenu]="basicMenu" [contextMenuSubject]="docFile" *ngIf="!docFile['shouldEdit']"
                      (dblclick)="onViewFileClicked(docFile)" class="px-2 text-ellipsis"
                      [title]="'Double Click for View: ' + docFile.fileName"> {{docFile.fileName}}
                    </a>
                    <div class="d-flex px-2" *ngIf="docFile['shouldEdit']">
                      <input style="width: 10rem;" type="text" class="form-control" name="docFile{{docFile.docFileId}}"
                        id="docFile{{docFile.docFileId}}" [(ngModel)]="docFile['fileNameWithoutExtension']" />
                      <div class="d-flex ms-2 mt-1">
                        <a (click)="onFileRenameConfirmed(docFile)">
                          <span> <i class="fa fa-check-circle text-success" style="font-size: small;"></i>
                          </span>
                        </a>
                        <a (click)="onFileRenameCancelled(docFile)">
                          <span class="ms-2"> <i class="fa fa-times text-danger" style="font-size: small;"></i> </span>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td> <span class="pe-1 h-none" style="font-weight: 600;"> {{getInsertedBy(docFile.insertedBy)}}
                    </span>
                  </td>
                  <td>
                    <span style="font-weight: 600;" class="h-none"> {{docFile.dateInserted | date:'short'}} </span>
                  </td>
                  <td>
                    <span>
                      <a class="dropdown-toggle ms-2" data-bs-toggle="dropdown" aria-haspopup="false"
                        aria-expanded="false" href="#" role="button">
                        <i class="fas fa-ellipsis-v" style="color: #828c96;"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a class="dropdown-item" (click)="onViewFileClicked(docFile)">View Document</a>
                        <a class="dropdown-item" (click)="onShowMergeFilesClicked(docFile)">Modify Pages</a>
                        <a class="dropdown-item" (click)="onFileRenameClicked(docFile)">Rename</a>
                        <a class="dropdown-item" (click)="downloadDocument(docFile.loanDocId)">Download</a>
                        <a class="dropdown-item" (click)="comfirmDelete(docFile.guid)">Delete</a>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-template> -->
      <ng-template pTemplate="emptymessage">
        <tr style="height: 65px;">
          <td colspan="4" class="text-center"> There are no conditions/tasks </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="card" *ngIf="forSubmission && otherOptionalConditions.length > 0">
  <p-table #dt [value]="otherOptionalConditions" [paginator]="false" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
    scrollHeight="flex" [scrollable]="true" [globalFilterFields]="['detail', 'borrower', 'description',
        'requestDate', 'dueDate', 'status']" [showCurrentPageReport]="true" sortField="dueDate" sortMode="single"
    [sortOrder]="1" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines conditions-data-table" expandableRows="true" rowExpandMode="single"
    dataKey="loanDocTaskId">
    <ng-template pTemplate="caption">
      <div class="p-d-flex" style="min-height: 34px;">
        <h4 class="d-flex me-5 m-0" style="font-size: 14px;"><i class="fas fa-paper-plane me-2 m-auto"></i>
          <span class="m-auto"> Optional Conditions (Provide for a Cleaner Approval) </span>
        </h4>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr (dragenter)="onDragEnterHeader($event)">
        <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : '' " [style]="col.style">
          {{col.header}}
          <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr class="drop_zone" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)" (drop)="onDrop($event,row)">
        <td>
          <div class="d-flex align-items-center justify-content-center w-100">
            <button *ngIf="row.docFiles?.length > 0" type="button" pButton pRipple [pRowToggler]="row"
              style="margin-left:-42px;" class="p-button-text p-button-rounded p-button-plain me-3"
              [icon]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></button>
            <a *ngIf="['EsignDocument','OnlineApplication','DigitalAssetVerification','GenerateDocument','RequestAppraisal','LosEsign','GatherPayment','PerformAction'].indexOf(row.taskType) == -1"
              href="javascript:void(0);" (click)="editTaskModal(row)" style="min-height: 48px; line-height: 48px;">
              <i class="fas fa-upload" style="color:#1f9ad6 !important; margin-top: 1.1rem;"></i>
            </a>
            <a *ngIf="['PerformAction'].indexOf(row.taskType) > -1 && ['Pending', 'Rejected'].indexOf(row.taskStatus) > -1"
              href="javascript:void(0);" (click)="markTaskComplete(row)" style="min-height: 48px;line-height: 48px;">
              Mark Complete
            </a>
            <a (click)="docGenTaskModal(row)" style="min-height: 48px;line-height: 48px;"
              *ngIf="(['GenerateDocument'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady'">
              Start
            </a>
            <a (click)="editTaskModal(row)" style="min-height: 48px;line-height: 48px;"
              *ngIf="(['RequestAppraisal','GatherPayment'].indexOf(row.taskType) > -1) && row.taskStatus != 'Completed' && row.taskStatus != 'ReviewReady'">
              Start
            </a>
            <div [hidden]="isDraggedFile" class="dragover p-2 ms-auto">
              <span>DROP FILES HERE</span>
            </div>
          </div>
        </td>
        <td>
          <span style='overflow:auto' class="text-cell-primary"> {{row.description}} </span>
          <br />
          <span *ngIf="row.borrowerName" class="text-muted"> For: {{ row.borrowerName }}</span>
          <br />
          <span *ngIf="row.conditionText" class="wrap"
            style='font-style: italic;'> Condition Text:
            {{row.conditionText}} </span>
        </td>
        <td>
          <span class="text-cell-primary">
            {{row.dueDate | date: 'short'}}</span>
          <br />
          <span *ngIf="row.lastRequest" class="text-muted"> Requested {{ row.lastRequest }}</span>
        </td>
        <td class="text-center">
          <span *ngIf="row.taskStatus == 'Rejected'" [title]="row.note"
            class="badge badge-soft-danger cursor">{{row.taskStatus}}</span>
          <span *ngIf="row.taskStatus != 'Rejected'" class="badge badge-soft-info">{{row.taskStatus}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData>
      <tr>
        <td colspan="4">
          <table class="condtionLoanDocTable p-2">
            <thead>
              <tr>
                <th style="width: 24.5%;">File Name</th>
                <th style="width: 20%;">Created By</th>
                <th style="width: 20%;">Date Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let docFile of rowData.docFiles">
                <td>
                  <a [contextMenu]="basicMenu" [contextMenuSubject]="docFile" *ngIf="!docFile['shouldEdit']"
                    (dblclick)="onViewFileClicked(docFile)" class="px-2 text-ellipsis"
                    [title]="'Double Click for View: ' + docFile.fileName"> {{docFile.fileName}}
                  </a>
                  <div class="d-flex px-2" *ngIf="docFile['shouldEdit']">
                    <input style="width: 10rem;" type="text" class="form-control" name="docFile{{docFile.docFileId}}"
                      id="docFile{{docFile.docFileId}}" [(ngModel)]="docFile['fileNameWithoutExtension']" />
                    <div class="d-flex ms-2 mt-1">
                      <a (click)="onFileRenameConfirmed(docFile)">
                        <span> <i class="fa fa-check-circle text-success" style="font-size: small;"></i>
                        </span>
                      </a>
                      <a (click)="onFileRenameCancelled(docFile)">
                        <span class="ms-2"> <i class="fa fa-times text-danger" style="font-size: small;"></i> </span>
                      </a>
                    </div>
                  </div>
                </td>
                <td> <span class="pe-1 h-none" style="font-weight: 600;"> {{getInsertedBy(docFile.insertedBy)}} </span>
                </td>
                <td>
                  <span style="font-weight: 600;" class="h-none"> {{docFile.dateInserted | date:'short'}} </span>
                </td>
                <td>
                  <span>
                    <a class="dropdown-toggle ms-2" data-bs-toggle="dropdown" aria-haspopup="false"
                      aria-expanded="false" href="#" role="button">
                      <i class="fas fa-ellipsis-v" style="color: #828c96;"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <a class="dropdown-item" (click)="onViewFileClicked(docFile)">View Document</a>
                      <a class="dropdown-item" (click)="onShowMergeFilesClicked(docFile)">Modify Pages</a>
                      <a class="dropdown-item" (click)="onFileRenameClicked(docFile)">Rename</a>
                      <a class="dropdown-item" (click)="downloadDocument(docFile.loanDocId)">Download</a>
                      <a class="dropdown-item" (click)="comfirmDelete(docFile.guid)">Delete</a>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr style="height: 65px;">
        <td colspan="4" class="text-center"> There are no conditions/tasks </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<context-menu>
  <ng-template contextMenuItem let-item (execute)="onFileRenameClicked($event.item)">
    Rename
  </ng-template>
</context-menu>