export class FileDto {
    guid: string;
    loanDocId: number;
    losDocFileId: string;
    fileName: string;
    note: string;
    borrowerNote: string;
    condition: boolean;
    createDate: string;
    expirationDate: string;
    docFileType: string;
    active: boolean;

    // Not in the API model
    selectedForAction?: boolean;
    linkWithTask?: boolean;
    status?: number;
    convertedToPdf?: boolean;
    confirmDelete?: boolean;
    file: File
}