import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoanSummaryCardAdminComponent } from './loan-summary-card-admin.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContactInfoComponent } from 'src/app/shared/components/contact-info/contact-info.component';

@NgModule({
  declarations: [
    LoanSummaryCardAdminComponent
  ],
  imports: [
    SharedModule,
    NgbModule
  ],
  exports: [
    LoanSummaryCardAdminComponent
  ],
  providers: [

  ]
})
export class LoanSummaryCardAdminModule { }
