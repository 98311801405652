import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanDoc } from 'src/app/models';
import { AppraisalDocument } from 'src/app/models/appraisal/appraisal-document.model';

@Component({
  selector: 'add-documents-dialog',
  templateUrl: './add-documents-dialog.component.html',
  styleUrls: ['./add-documents-dialog.component.scss']
})
export class AddDocumentsDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  @Input()
  appraisalDocTypesEnum = [];

  @Input()
  loanDocs: LoanDoc[] = [];

  @Input()
  newDocs: AppraisalDocument[] = [];

  newLoanDocId: string = "";

  newAppraisalDocType: string = "";


  ngOnInit(): void {
  }

  addDoc = () => {
    this.newDocs.push({
      docFileGuid: this.newLoanDocId,
      appraisalDocumentType: this.newAppraisalDocType,
      fileName: this.loanDocs.find(doc => doc.loanDocId.toString() == this.newLoanDocId).description
    });

    this.newLoanDocId = "";
    this.newAppraisalDocType = "";
  }

  removeDoc = (docFileGuid: string) => {
    const index = this.newDocs.findIndex(doc => doc.docFileGuid === docFileGuid);
    this.newDocs.splice(index, 1);
  }

}
