import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModel } from 'src/app/models/alert.model';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() user = {};


  @Input()
  unreadConversations: boolean;

  @Output()
  mentionsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  niceInContactClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  notificationsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  alertsCreated: EventEmitter<AlertModel[]> = new EventEmitter<AlertModel[]>();

  @Output()
  conversationsToggled: EventEmitter<any> = new EventEmitter<any>();

  searchText: string = '';
  private _status: boolean = false;

  constructor(private readonly _router: Router) {
  }

  onAlertsCreated = (alerts: AlertModel[]) => {
    this.alertsCreated.emit(alerts);
  }

  onNotificationsClicked = (notifications: any) => {
    this.notificationsClicked.emit(notifications);
  }

  onMentionsClicked = (open: boolean) => {
    this.mentionsClicked.emit(open);
  }

  onNiceInContactClicked = (activeTab: string) => {
    this.niceInContactClicked.emit(activeTab);
  }

  onConversationsToggled = () => {
    this.conversationsToggled.emit();
  }

  search = () => {
    if (this.searchText != '') {
      const url = "loda-nextgen/search?param=" + this.searchText + "&searchMode=Contains";
      this._router.navigateByUrl(url);
    }
  }

  toggleNav() {
    this._status = !this._status;
    if (this._status) {
      document.getElementById('navigation').style.left = '0px';
    } else {
      document.getElementById('navigation').style.left = '-225px';
    }
  }
}
