import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { MortgageCtoFeeWizardComponent } from "./mortgage-cto-fee-wizard.component";
import { FeewiseWizardCompletedComponent } from "./feewise-wizard-completed/feewise-wizard-completed.component";
import { FeeService } from "src/app/services/fee.service";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    MortgageCtoFeeWizardComponent,
    FeewiseWizardCompletedComponent
  ],
  exports: [
    MortgageCtoFeeWizardComponent,
    FeewiseWizardCompletedComponent
  ],
  providers: [
    FeeService
  ]
})
export class MortgageCtoFeeWizardModule {
  constructor() {
  }
}


