<div class="row">
    <div class="col-sm-12 p-4 pb-0">
        <ng-wizard [config]="config">
            <ng-wizard-step [title]="'Purchase Phone Number'">
                <purchase-phone-number
                    (phoneNumberSelected)="phoneNumberSelected($event)"
                    (messagingServiceSelected)="messagingServiceSelected($event)"
                ></purchase-phone-number>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Set Friendly Name'">
                <set-friendly-name
                    (friendlyNameSet)="friendlyNameSet($event)"
                ></set-friendly-name>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Incoming Actions'">
                <incoming-actions
                    #incomingActions
                    [telephony]="telephony"
                    [users]="users"
                ></incoming-actions>
            </ng-wizard-step>
        </ng-wizard>
    </div>
    <div class="col-sm-12 text-end p-4">
        <button
            type="button"
            class="btn btn-secondary mx-2 width-100p"
            (click)="showPreviousStep()"
            *ngIf="config.selected > 0"
        >
            Back
        </button>

        <button
            type="button"
            class="btn btn-primary"
            style="width: 120px"
            [disabled]="isSaving || (
                (this.config.selected === 0 && (!phoneNumber || !messagingService)) ||
                (this.config.selected === 1 && !friendlyName) ||
                (this.config.selected === 2 && !incomingActionsEditor.telephonyForm.form.valid)
            )"
            (click)="submit()"
        >
            <span *ngIf="!isSaving">{{this.config.selected === 2 ? 'Finish' : 'Next'}}</span>
            <span *ngIf="isSaving">
              <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
              ></span>
            </span>
        </button>
    </div>
</div>

