<div class="d-flex justify-content-between">
  <div class="col-md-4 col-sm-6 form-group">
    <label for="sellerType{{index}}"> Seller Type </label>
    <select class="form-select" name="sellerType{{index}}" id="sellerType{{index}}"
      [(ngModel)]="seller.mortgagePartyType" (change)="onSellerTypeChanged()">
      <option value="{{type.value}}" *ngFor="let type of sellerTypes">{{type.name}}
      </option>
    </select>
  </div>
  <div class="col-md-8 col-sm-6 form-group">
    <button type="button" class="btn btn-sm btn-outline-danger float-end" (click)="onDeleteSellerClicked()">
      <i class="fas fa-trash-alt me-1"></i> Delete Seller
    </button>
  </div>
</div>
<ng-container *ngIf="seller.mortgagePartyType === 'Entity'">
  <form #docPrepEntityForm="ngForm" id="docPrepEntityForm">
    <div class="row">
      <div class="col-md-8 form-group">
        <label for="entityName{{uniqueId}}"> Entity Name </label>
        <input type="text" class="form-control" id="entityName{{uniqueId}}" name="entityName{{uniqueId}}"
          [(ngModel)]="seller.entityName">
      </div>
      <div class="col-md-4 form-group">
        <label for="entityType{{uniqueId}}"> Entity Type </label>
        <select id="entityType{{uniqueId}}" name="entityType{{uniqueId}}" class="form-select"
          [(ngModel)]="seller.mortgageEntityType">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let opt of entityTypes" [ngValue]="opt.value">
            {{ opt.name }}
          </option>
        </select>
      </div>
      <document-preparation-signer *ngFor="let signer of seller.signers; let index = index" [signer]="signer"
        [index]="index" [sellerBorrowerType]="{source: 'seller', type: 'Entity'}"
        (deleteSigner)="onDeleteSignerClicked($event)">
      </document-preparation-signer>
      <div class="row">
        <div class="form-group">
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddSignerClicked()">
            <i class="fas fa-plus me-1"></i> Add Signer
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-6 form-group">
        <label for="EntitySellerAddress1"> Seller Mailing Address </label>
        <address-autocomplete-input #address='ngModel' (addressChange)='handleAddressChange($event)'
          [(ngModel)]='seller.mailingStreet' id='EntitySellerAddress1' name='EntitySellerAddress1'
          placeholder=''></address-autocomplete-input>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <div class="row">
          <div class="form-group col-xs-6 col-sm-6 pe-0">
            <label for="city"> City </label>
            <input id="city" name="city" type="text" class="form-control custom-input-left-side"
              [(ngModel)]="seller.mailingCity" />
          </div>
          <div class="form-group col-xs-6 col-sm-2 px-0">
            <label for="state">State</label>
            <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
              [(ngModel)]="seller.mailingState">
              <option [ngValue]="null"></option>
              <option *ngFor="let state of states" [ngValue]="state.value">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-xs-6 col-sm-4 ps-0">
            <label for="zipCode">Zip Code</label>
            <zip-code-input class="custom-input-right-side" [(ngModel)]="seller.mailingZip" [name]="'zipCode'"
              [required]="false" (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
            </zip-code-input>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-0">
  </form>
</ng-container>
<ng-container *ngIf="seller.mortgagePartyType === 'Individual'">
  <form #docPrepIndividualForm="ngForm" id="docPrepIndividualForm">
    <div class="row">
      <div class="col-md-4 form-group">
        <label for="firstName_{{uniqueId}}"> First </label>
        <input type="text" class="form-control" id="firstName_{{uniqueId}}" name="firstName_{{uniqueId}}"
          [(ngModel)]="seller.firstName">
      </div>
      <div class="col-md-4 form-group">
        <label for="lastName_{{uniqueId}}"> Last </label>
        <input type="text" class="form-control" id="lastName_{{uniqueId}}" name="lastName_{{uniqueId}}"
          [(ngModel)]="seller.lastName">
      </div>
      <div class="col-md-2 form-group">
        <label for="maritalStatus_{{uniqueId}}"> Marital Status </label>
        <select class="form-select" name="maritalStatus_{{uniqueId}}" id="maritalStatus_{{uniqueId}}"
          [(ngModel)]="seller.maritalStatus">
          <option [ngValue]="null">-- Select One --</option>
          <option value="{{ms.value}}" *ngFor="let ms of maritalStatuses">{{ms.name}}
          </option>
        </select>
      </div>
      <div class="col-md-2 form-group">
        <label for="suffix_{{uniqueId}}"> Suffix </label>
        <select id="suffix_{{uniqueId}}" name="suffix_{{uniqueId}}" class="form-select" [(ngModel)]="seller.nameSuffix">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
            {{ opt.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 form-group special-break-point">
        <div class="form-check mb-0">
          <label for="poa_{{uniqueId}}" class="form-check-label"> POA {{seller.powerOfAttorney ?
            'Name' :
            ''}} </label>
          <input id="poa_{{uniqueId}}" name="poa_{{uniqueId}}" class="form-check-input"
            [(ngModel)]="seller.powerOfAttorney" (ngModelChange)="onPOAChanged($event, seller)" type="checkbox" />
        </div>
        <input *ngIf="seller.powerOfAttorney" type="text" class="form-control" id="poaName_{{uniqueId}}"
          name="poaName_{{uniqueId}}" [(ngModel)]="seller.powerOfAttorneyName">
      </div>
      <div class="col-md-10 special-break-point">
        <div class="row">
          <div class="col-12 col-lg-5 form-group">
            <label for="sellerAddress1"> Seller Mailing Address </label>
            <address-autocomplete-input #address='ngModel' (addressChange)='handleAddressChange($event)'
              [(ngModel)]='seller.mailingStreet' id='sellerAddress1' name='sellerAddress1'
              placeholder=''></address-autocomplete-input>
          </div>
          <div class="col-12 col-lg-7 form-group">
            <div class="row">
              <div class="form-group col-xs-6 col-sm-6 pe-0">
                <label for="city"> City </label>
                <input id="city" name="city" type="text" class="form-control custom-input-left-side"
                  [(ngModel)]="seller.mailingCity" />
              </div>
              <div class="form-group col-xs-6 col-sm-2 px-0">
                <label for="state">State</label>
                <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
                  [(ngModel)]="seller.mailingState">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let state of states" [ngValue]="state.value">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-xs-6 col-sm-4 ps-0">
                <label for="zipCode">Zip Code</label>
                <zip-code-input class="custom-input-right-side" [(ngModel)]="seller.mailingZip" [name]="'zipCode'"
                  [required]="false" (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
                </zip-code-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-0">
  </form>
</ng-container>
<ng-container *ngIf="seller.mortgagePartyType === 'Trust'">
  <form #docPrepTrustForm="ngForm" id="docPrepTrustForm">
    <div class="row">
      <div class="col-md-8 form-group">
        <label for="trustName{{uniqueId}}"> Trust Name </label>
        <input type="text" class="form-control" id="trustName{{uniqueId}}" name="trustName{{uniqueId}}"
          [(ngModel)]="seller.trustName">
      </div>
      <div class="col-md-4 form-group">
        <label for="trustDate{{uniqueId}}"> Trust Date</label>
        <date-input name="trustDate{{uniqueId}}" [id]="'trustDate{{uniqueId}}'"
          [(ngModel)]="seller.trustDate"></date-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-6 form-group">
        <label for="trustSellerAddress1"> Seller Mailing Address </label>
        <address-autocomplete-input #address='ngModel' (addressChange)='handleAddressChange($event)'
          [(ngModel)]='seller.mailingStreet' id='trustSellerAddress1' name='trustSellerAddress1'
          placeholder=''></address-autocomplete-input>
      </div>
      <div class="col-12 col-lg-6 form-group">
        <div class="row">
          <div class="form-group col-xs-6 col-sm-6 pe-0">
            <label for="city"> City </label>
            <input id="city" name="city" type="text" class="form-control custom-input-left-side"
              [(ngModel)]="seller.mailingCity" />
          </div>
          <div class="form-group col-xs-6 col-sm-2 px-0">
            <label for="state">State</label>
            <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
              [(ngModel)]="seller.mailingState">
              <option [ngValue]="null"></option>
              <option *ngFor="let state of states" [ngValue]="state.value">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-xs-6 col-sm-4 ps-0">
            <label for="zipCode">Zip Code</label>
            <zip-code-input class="custom-input-right-side" [(ngModel)]="seller.mailingZip" [name]="'zipCode'"
              [required]="false" (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
            </zip-code-input>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-0">
  </form>
</ng-container>