import {Injectable} from '@angular/core';
import {DataService} from '../core/services/data.service';
import {Observable} from 'rxjs';
import {CocHistoryModel} from '../models/coc/coc-history.model';

@Injectable()
export class CocService {
  constructor(private readonly _dataService: DataService) { }

  getAllCoc = (applicationId: string): Observable<CocHistoryModel[]> => {
    const url = `api/Task/get-all-coc/${applicationId}`;
    return this._dataService.get(url);
  };

  createCoc = (applicationId: number, changes: any): Observable<any> => {
    const url = `api/task/create-coc/${applicationId}`;
    return this._dataService.post(url, changes);
  }

  getTaskById = (taskId: string): Observable<any> => {
    const url = `api/Task/get-all-coc/${taskId}/GetTaskDashboardViewById`;
    return this._dataService.get(url);
  };
}
