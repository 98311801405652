export class ChangePasswordRequest {
    oldPassword: string = "";
    newPassword: string = "";
    confirmPassword: string = "";

    constructor(oldPassword: string, newPassword: string, confirmPassword: string) {
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
        this.confirmPassword = confirmPassword;
    }
}

export interface ChangeBorrowerPasswordRequest {
    email: string;
    newPassword: string;
    companyId: number;
}