import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { CocReason } from "src/app/modules/disclosure-tracking/models/disclosure-tracking.model";

@Injectable()
export class CocReasonsConfigService {
  constructor(private readonly _dataService: DataService) { }


  getCoCReasons(): Observable<Array<CocReason>> {
    return this._dataService.get(
      `api/disclosure/coc-reasons`
    );
  }

  upsertCoCReason( data: CocReason ): Observable<CocReason> {
    return this._dataService.post(
      `api/disclosure/coc-reasons/${data.changeOfCircumstanceReasonId || 0}`,
      data
    );
  }

  updateOrder(sortedIds: Array<{ id: number }>): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/disclosure/coc-reasons/UpdateCocReasonOrder`,
      sortedIds
    );
  }

  deleteCocReason( cocReasonId: number): Observable<undefined> {
    let url = `api/disclosure/coc-reasons/${cocReasonId}`;
    return this._dataService.delete(url);
  }
}
