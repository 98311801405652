import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EditorMode } from '../currency-input/currency-input.component';

@Component({
  selector: 'number-input',
  templateUrl: 'number-input.component.html',
  providers: [MakeProvider(NumberInputComponent)],
  viewProviders: [formViewProvider]
})
export class NumberInputComponent extends AbstractValueAccessor implements OnInit {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('control')
  input: ElementRef<HTMLInputElement>;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  name: string;

  @Input()
  required: boolean;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  inlineTextClass: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Input()
  allowNegative: boolean = true;

  @Input()
  allowEmpty: boolean = true;

  @Input() decimals: number = 0;

  @Input() max: number | null = null;

  @Input() min: number | null = null;

  @Input() placeholder: string;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  id: string;

  isEditActive: boolean = false;

  private _originalValue: any;

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());
  }

  onBlurred = ($event: any) => {
    this.checkIsInvalid(this._value, true);
    if (this.isEditActive) {
      this.apply();
    }
    this.blur.emit($event);
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
    this.isEditActive = true;
  }

  protected doSetValue(v: any) {
    if (v !== this._value) {
      if (v === "") {
        v = null;
      }
      this._value = v;
      this.onChange(v);
    }
  }

  override registerOnChange(fn: (_: number | null) => void): void {
    this.onChange = (value) => {
      let numericValue: number | null = null;
      if (!value && value !== 0) {
        if (!this.allowEmpty) {
          numericValue = 0;
        }
      } else {
        numericValue = parseFloat(value);
      }
      fn(numericValue != 0 ? numericValue : value);
    };
  }

  override writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this._originalValue = value; // for initializing

    this.isEditActive = !this.model?.control.valid;
  }

  onCancelClicked = (event: any) => {
    event.preventDefault();
    this.isEditActive = false;
    this.value = this._originalValue;
  }

  onApplyClicked = (event: any) => {
    this.model.control.markAsTouched();
    event.preventDefault();
    this.apply();
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;
    }
  }

  private validateMax(value: string) {
    if (!value) return true;
    if (this.max == null || this.max == undefined) return true;
    return Number(value) <= this.max;
  }

  private validateMin(value: string) {
    if (!value) return true;
    if (this.min == null || this.min == undefined) return true;
    return Number(value) >= this.min;
  }

  private checkIsInvalid = (value: string, setFormErrors: boolean = false): boolean => {
    let isValid = true;

    isValid = this.validateMax(value);
    if (!isValid) {
      if (setFormErrors) {
        this.model.control.setErrors({ 'max': true });
      }
      return true;
    }

    isValid = this.validateMin(value);
    if (!isValid) {
      if (setFormErrors) {
        this.model.control.setErrors({ 'min': true });
      }
      return true;
    }

    if (isValid) {
      if (setFormErrors) {
        this.model.control.setErrors(null);
      }
      return false;
    }
  }
}
