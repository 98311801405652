<div class="row">
  <div class="col-md-8 form-group">
    <div class="row">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-4">
            <label for="firstName_{{index}}_{{uniqueId}}"> First </label>
            <input type="text" class="form-control" id="firstName_{{index}}_{{uniqueId}}"
              name="firstName_{{index}}_{{uniqueId}}" [(ngModel)]="trustParty.first">
          </div>
          <div class="col-md-4">
            <label for="middleName_{{index}}_{{uniqueId}}"> Middle </label>
            <input type="text" class="form-control" id="middleName_{{index}}_{{uniqueId}}"
              name="middleName_{{index}}_{{uniqueId}}" [(ngModel)]="trustParty.middle">
          </div>
          <div class="col-md-4">
            <label for="lastName_{{index}}_{{uniqueId}}"> Last </label>
            <input type="text" class="form-control" id="lastName_{{index}}_{{uniqueId}}"
              name="lastName_{{index}}_{{uniqueId}}" [(ngModel)]="trustParty.last">
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <label for="suffix_{{index}}_{{uniqueId}}"> Suffix </label>
        <select id="suffix_{{index}}_{{uniqueId}}" name="suffix_{{index}}_{{uniqueId}}" class="form-select" [(ngModel)]="trustParty.suffix">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
            {{ opt.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row mt-4">
      <div class="col-md-4">
        <div class="d-inline-flex">
          <div class="form-check">
            <label for="borrowerCheck_{{index}}_{{uniqueId}}" class="form-check-label"> Borrower </label>
            <input id="borrowerCheck_{{index}}_{{uniqueId}}" name="borrowerCheck_{{index}}_{{uniqueId}}" class="form-check-input"
              [(ngModel)]="trustParty.borrower" type="checkbox" />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-inline-flex">
          <div class="form-check">
            <label for="settlerCheck_{{index}}_{{uniqueId}}" class="form-check-label"> Settlor </label>
            <input id="settlerCheck_{{index}}_{{uniqueId}}" name="settlerCheck_{{index}}_{{uniqueId}}" class="form-check-input"
              [(ngModel)]="trustParty.settlor" type="checkbox" />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-inline-flex">
          <div class="form-check">
            <label for="trusteeCheck_{{index}}_{{uniqueId}}" class="form-check-label"> Trustee </label>
            <input id="trusteeCheck_{{index}}_{{uniqueId}}" name="trusteeCheck_{{index}}_{{uniqueId}}" class="form-check-input"
              [(ngModel)]="trustParty.trustee" type="checkbox" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1">
    <div class="text-end mt-4 me-3">
      <a class="text-danger" (click)="onDeleteClicked(index)" title="Delete trust party"><i class="fas fa-trash-alt"></i></a>
    </div>
  </div>
</div>
