import {Component, Input, OnInit} from '@angular/core';
import {DocumentTemplate} from '../../../../../models/config/global-config.model';

@Component({
  selector: 'e-consent-settings',
  templateUrl: './e-consent-settings.component.html'
})
export class EConsentSettingsComponent implements OnInit {
  @Input() onlineApplicationSettings: any;
  @Input() eConsentDocumentTemplates: DocumentTemplate[];

  constructor() {}

  ngOnInit(): void {}
}
