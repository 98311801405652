<div class="modal-header">
  <h5 class="modal-title">Edit Custom Data Items</h5>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <div class="col-md-12">
      <label class="custom-control-label">List Type</label>
      <select class="form-select standard" [(ngModel)]="customDataConfigModel['multiLookupType'+number]" (change)="getLookupItems()">
        <option value="">-- Select One --</option>
        <option *ngFor="let item of listTypes" value={{item.value}}>
          {{item.name}}
        </option>
      </select>
    </div>
  </div>
  <loading-indicator *ngIf="loading" [loadingMessage]="'Loading items'"></loading-indicator>
  <div class="row mb-3" *ngFor="let item of lookupItems; let index = index">
    <div class="col-md-6">
      <label class="custom-control-label">Name</label>
      <input type="text" class="form-control" [(ngModel)]="item.name" [disabled]="customDataConfigModel['multiLookupType'+number] !== 'Custom'">
    </div>
    <div [ngClass]="{'col-md-6': customDataConfigModel['multiLookupType'+number] !== 'Custom', 'col-md-5': customDataConfigModel['multiLookupType'+number] === 'Custom'}">
      <label class="custom-control-label">Value</label>
      <input type="text" class="form-control" [(ngModel)]="item.value" [disabled]="customDataConfigModel['multiLookupType'+number] !== 'Custom'">
    </div>
    <div class="col-md-1 text-center pt-4" *ngIf="customDataConfigModel['multiLookupType'+number] === 'Custom'">
      <a (click)="lookupItems.splice(index, 1)">
        <i class="far fa-trash-alt text-danger mt-2"></i>
      </a>
    </div>
  </div>
  <div class="row mb-3" *ngIf="customDataConfigModel['multiLookupType'+number] === 'Custom' && !loading">
    <div class="col-md-4">
      <button type="button" class="btn btn-soft-primary w-100" (click)="lookupItems.push({ name: '', value: '', type: 'Custom' })">
        Add
      </button>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <button
    type="button"
    class="btn btn-secondary right"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary right"
    (click)="activeModal.close(lookupItems)"
  >
    Ok
  </button>
</div>
