import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of } from 'rxjs';
import { CredentialInfo, Mortgage, ThirdPartyCredentialType } from 'src/app/models';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CreateTitleOrderRequest, TitleOrderTypeEnum, TitleProductTypeEnum } from 'src/app/models/title/create-title-order-request.model';
import { QuoteHistory } from 'src/app/models/title/quote-history.model';
import { TitleDocumentTypeEnum, TitleOrder, TitleOrderDocument, TitleOrderEvent } from 'src/app/modules/app-details/components/title-history/models/title-order.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { TitleService } from 'src/app/services/title.service';
import { TitleOrderDocumentsDialogComponent } from '../title-order-documents-dialog/title-order-documents-dialog.component';

@Component({
  selector: 'quotes-task-order',
  templateUrl: './quotes-task-order.component.html',
  styleUrls: ['./quotes-task-order.component.scss']
})
export class QuotesTaskOrderComponent implements OnInit, OnDestroy {

  @Input()
  appId: number;

  @Input()
  task: LoanDocDashboardTask;

  @Input()
  mortgage: Mortgage;

  @Input()
  globalConfig: GlobalConfig;

  quote: QuoteHistory;

  titleQuoteHistoryId: number;
  orderNumber: string;
  orderStatus: string;
  titleOrderStatus: string;

  titleOrderId: number;
  loanDocId: number;

  loanPurpose: LoanPurpose;
  loanType: LoanType;

  quoteErrors = [];
  orderErrors = [];

  lenderList = [];

  selectedLender = {
    thirdPartyLenderId: null
  };

  isLenderSaving: boolean = false;
  syncingQuote: boolean = false;
  alreadyHaveLoanDoc: boolean = false;

  selectedVendorCredential: CredentialInfo = null;
  titleVendorCredentials: CredentialInfo[] = [];

  orderType: TitleOrderTypeEnum = TitleOrderTypeEnum.TitleOnly;
  productType: TitleProductTypeEnum = TitleProductTypeEnum.LoanPolicy;
  notes: string = null;
  integrationProvider: string = null;

  orderTypes: EnumerationItem[] = [];
  productTypes: EnumerationItem[] = [];
  documentTypes: EnumerationItem[] = [];
  orderStatuses: EnumerationItem[] = [];

  titleOrderDocuments: TitleOrderDocument[] = [];
  willBeUploadedOrderDocuments: TitleOrderDocument[] = [];
  titleOrderEvents: TitleOrderEvent[] = [];

  descriptions = {
    orderStatus: null,
    orderType: null,
    productType: null
  }

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _titleService: TitleService,
    private readonly _thirdPartyCredentialService: ThirdPartyCredentialsService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _taskService: TaskService,
    private readonly _modalService: NgbModal
  ) { }

  ngOnDestroy(): void {
    if (this.willBeUploadedOrderDocuments.length && this.loanDocId) {
      // delete uploaded files (backed out of the order creation)
      this.getLoanDoc(this.loanDocId, (loanDoc) => {

        let fileGuids = this.willBeUploadedOrderDocuments.map(d => d.docFileGuid);
        let observables = fileGuids.map(guid => this._loanDocsService.deleteDocFile(loanDoc, guid));

        this._spinner.show();
        forkJoin(observables).subscribe({
          next: () => {

            if (!this.alreadyHaveLoanDoc) {
              this._loanDocsService.deleteLoanDoc(this.loanDocId).subscribe({
                next: () => {
                  this._spinner.hide();
                },
                error: () => {
                  this._spinner.hide();
                  this._notifyService.showError('Failed to delete the loan doc!', 'Failure');
                }
              })
            }
            else {
              this._spinner.hide();
            }

          },
          error: () => {
            this._spinner.hide();
            this._notifyService.showError('Failed to delete the uploaded doc files!', 'Failure');
          }
        })
      })
    }
  }

  ngOnInit(): void {

    this.alreadyHaveLoanDoc = !!this.task.loanDocId;

    this.loanType = this.globalConfig.loanType.find(l => l.mortgageAppliedForType == this.mortgage.mortgageTerm.mortgageAppliedFor);
    this.loanPurpose = this.globalConfig.loanPurpose.find(l => l.loanPurposeName == this.mortgage.subjectProperty.purposeOfLoan);

    this._enumsService.getTitleEnumerations().subscribe(res => {
      this.orderTypes = res[Constants.titleEnumerations.orderType];
      this.productTypes = res[Constants.titleEnumerations.productType];
      this.documentTypes = res[Constants.titleEnumerations.documentType];
      this.orderStatuses = res[Constants.titleEnumerations.orderStatus];

      this.getOrderHistory();
    });

    this.getTitleVendorCredentials();
    this.getLenderList();
  }

  onSelectedVendorCredentialChanged = () => {
    if (this.selectedVendorCredential?.vendorName == "StatesTitle") {

      this._spinner.show();
      this._titleService.getQuotes(this.appId).subscribe({
        next: (quotes) => {

          this._spinner.hide();

          if (quotes.length) {
            this.quote = quotes[0];
            this.titleQuoteHistoryId = this.quote.titleQuoteHistoryId;
          }

        },
        error: (err) => {
          this._spinner.hide();
          this._notifyService.showError(err.message || "Errors have been encountered when loading quotes", "Error!");
        }
      })
    }
  }

  getQuote = () => {
    this._spinner.show();
    this._titleService.postQuotes(this.appId, this.task.loanDocTaskId).subscribe((response) => {
      this._spinner.hide();
      this.quote = response;
      this.titleQuoteHistoryId = response.titleQuoteHistoryId;
    }, (err) => {
      this._spinner.hide();
      this.quoteErrors = this.formatErrorsToObject(err.message);
    });
  }

  syncQuoteFeesToLoan = () => {
    this._spinner.show();
    this._titleService.syncQuoteToLoanFees(this.titleQuoteHistoryId).subscribe({
      next: () => {
        this._spinner.hide();
        this._notifyService.showSuccess("Syncing quote fees to loan succeed", "Success");
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err ? err.message : "Failed to sync quotes fees to loan.", "Error");
      }
    });
  }

  submitOrder = () => {

    let orderRequest: CreateTitleOrderRequest = new CreateTitleOrderRequest();
    orderRequest.applicationId = this.appId;
    orderRequest.credentialId = this.selectedVendorCredential.credentialId;
    orderRequest.loanDocTaskId = this.task.loanDocTaskId;
    orderRequest.orderType = this.orderType;
    orderRequest.productType = this.productType;
    orderRequest.notes = [{ text: this.notes }];
    orderRequest.documents = this.titleOrderDocuments;

    this._spinner.show();
    this._titleService.postOrder(orderRequest).subscribe({
      next: (response) => {
        this.titleOrderId = response.titleOrderId;

        this.getOrderDetails((isSuccess: boolean) => {
          if (isSuccess) {
            this.reloadSelectedTask();
          }
          else {
            this._spinner.hide();
          }
        });

      }, error: (err) => {
        this._spinner.hide();
        this.orderErrors = this.formatErrorsToObject(err.message);
      }
    })
  }

  deleteOrder = () => {
    this._spinner.show();
    this._titleService.deleteOrder(this.titleOrderId).subscribe({
      next: () => {
        this._spinner.hide();
        this.setOrderFields(null);

        this.getOrderHistory();
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || "Errors have been encountered when deleting order", "Error!");
      }
    })
  }

  refreshEvents = () => {
    this._spinner.show();
    this._titleService.refreshEvents(this.titleOrderId).subscribe({
      next: () => {
        this._spinner.hide();
        this.getOrderDetails();
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || "Errors have been encountered when deleting order", "Error!");
      }
    })
  }

  updateLender = () => {
    if (!this.selectedLender || !this.selectedLender.thirdPartyLenderId) {
      return;
    }

    this.isLenderSaving = true;
    this._spinner.show();
    this._titleService.updateLender(this.titleOrderId, this.selectedLender.thirdPartyLenderId).subscribe((response) => {
      _.assign(this.selectedLender, response);
      this.isLenderSaving = false;
      this._spinner.hide();
      this._notifyService.showSuccess('Lender updated successfully.', 'Success');
    }, (err) => {
      this.isLenderSaving = false;
      this._spinner.hide();
      this._notifyService.showError(err.message || 'Unable to update lender.', 'Lender Update Failed');
    })
  }

  onAddDocClicked = () => {
    const modalRef = this._modalService.open(TitleOrderDocumentsDialogComponent, Constants.modalOptions.large)
    modalRef.componentInstance.titleOrderId = this.titleOrderId;
    modalRef.componentInstance.documentTypes = this.documentTypes;
    modalRef.componentInstance.task = this.task;
    modalRef.result.then((result: { refreshRequired: boolean, docType: TitleDocumentTypeEnum, fileDto: FileDto, loanDocId: number }) => {

      let doc = new TitleOrderDocument();
      doc.titleOrderId = this.titleOrderId;
      doc.documentType = result.docType;
      doc.fileName = result.fileDto.fileName;
      doc.docFileGuid = result.fileDto.guid;

      if (this.titleOrderId) {
        this.uploadTitleOrderDocument(doc, (res2: TitleOrderDocument) => {
          this.titleOrderDocuments.push(res2);
          if (result.refreshRequired) {
            this._spinner.show();
            this.reloadSelectedTask();
          }
        })
      }
      else {
        if (!this.loanDocId) {
          this.loanDocId = result.loanDocId;
        }

        this.titleOrderDocuments.push(doc);
        this.willBeUploadedOrderDocuments.push(doc);
        if (result.refreshRequired) {
          this._spinner.show();
          this.reloadSelectedTask();
        }
      }

    }, (res) => {
    });
  }

  viewLoanDocument = (doc: TitleOrderDocument) => {
    this._loanDocsService.viewLoanDocContent(doc.docFileGuid).subscribe((data) => {
      const blob = new Blob([data], { type: data['type'] });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  downloadDocument = (doc: TitleOrderDocument) => {
    this._loanDocsService.getLoanDocContent(doc.docFileGuid).subscribe((data) => {
      const blob = new Blob([data], { type: data['type'] });
      let downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.setAttribute('download', doc.fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  private formatErrorsToObject = (errors) => {
    if (errors.includes('\r\n')) {
      errors = errors.split('\r\n');
    } else {
      this._notifyService.showError(errors, 'Task');
      return [];
    }

    let error = null;
    return errors.map(err => {
      error = err.split(':');
      if (error.length === 2) {
        return {
          field: error[0].trim().replace('_', ' '),
          message: error[1].trim()
        };
      } else {
        this._notifyService.showError(err, 'Task');
      }
    });
  }

  private getLoanDoc = (loanDocId: number, cb: Function) => {
    this._spinner.show();
    this._loanDocsService.getLoanDoc(loanDocId).subscribe({
      next: (res) => {
        this._spinner.hide();
        cb(res);
      }, error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || 'Failed to get the loan doc!', 'Failure');
      }
    })
  }

  private uploadTitleOrderDocument = (doc: TitleOrderDocument, cb: Function) => {
    this._spinner.show();
    this._titleService.postOrderDocument(this.titleOrderId, doc).subscribe({
      next: (res2: TitleOrderDocument) => {
        this._spinner.hide();
        cb(res2);
      }, error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || 'Failed to save the order doc!', 'Failure');
      }
    })
  }

  private getOrderHistory = () => {
    this._spinner.show();

    this._titleService.getOrders(this.appId).subscribe({
      next: (orders) => {
        this._spinner.hide();

        if (orders && orders.length) {

          // sort orders by descending
          orders.sort((o1, o2) => (new Date(o2.dateUpdated || o2.dateInserted)).getTime() - (new Date(o1.dateUpdated || o1.dateInserted)).getTime());
          const mostRecentOrder = orders[0];

          this.setOrderFields(mostRecentOrder);
        }
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || "Errors have been encountered when loading orders", "Error!");
      }
    })
  }

  private getOrderDetails = (cb?: Function) => {
    this._spinner.show();

    this._titleService.getOrderDetails(this.titleOrderId).subscribe({
      next: (order: TitleOrder) => {
        this._spinner.hide();
        this.setOrderFields(order);
        if (cb) {
          cb(true);
        }
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || "Errors have been encountered when loading ordr details", "Error!");

        if (cb) {
          cb(false);
        }
      }
    })
  }

  private getLenderList = () => {
    this._spinner.show();

    this._titleService.getLenders().subscribe({
      next: (res) => {
        this._spinner.hide();
        this.lenderList = res || [];
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || "Errors have been encountered when loading lenders", "Error!");
      }
    })
  }

  private getTitleVendorCredentials = () => {
    this._thirdPartyCredentialService.getCredentialsForUserCompany(ThirdPartyCredentialType.Title).subscribe((response) => {
      this.titleVendorCredentials = response;
    }, (err) => {
      this._notifyService.showError(err.message || "Errors have been encountered when loading title vendor credentials", "Error!");
    });
  }

  private reloadSelectedTask = () => {
    this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId).subscribe((response) => {
      this.task = Object.assign(this.task, response);
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err ? err.message || err : "Load task fail", "Error");
    });
  }

  private getSelectedLender = () => {
    this._titleService.getSelectedLender(this.titleOrderId).subscribe((response) => {
      this._spinner.hide();
      _.assign(this.selectedLender, response);
    }, (err) => {
      this._spinner.hide();
    });
  }

  private setOrderFields = (order: TitleOrder) => {
    this.orderNumber = order?.thirdPartyOrderId || null;
    this.orderStatus = order?.thirdPartyOrderStatus || null;
    this.titleOrderId = order?.titleOrderId || null;

    this.titleOrderStatus = order?.orderStatus || null;
    this.orderType = order?.orderType || TitleOrderTypeEnum.TitleOnly;
    this.productType = order?.productType || TitleProductTypeEnum.LoanPolicy;
    this.integrationProvider = order?.integrationProvider || null;

    this.titleOrderDocuments = order?.documents || [];
    this.titleOrderEvents = order?.events || [];

    if (this.titleOrderId && order?.integrationProvider == "StatesTitle") {
      this.getSelectedLender();
    }

    // set descriptions
    if (this.orderType) {
      const orderTypeEnum = this.orderTypes.find(t => t.value == this.orderType);
      if (orderTypeEnum) {
        this.descriptions.orderType = orderTypeEnum.name;
      }
    }

    if (this.productType) {
      const productTypeEnum = this.productTypes.find(t => t.value == this.productType);
      if (productTypeEnum) {
        this.descriptions.productType = productTypeEnum.name;
      }
    }

    if (this.titleOrderStatus) {
      const orderStatusEnum = this.orderStatuses.find(t => t.value == this.titleOrderStatus);
      if (orderStatusEnum) {
        this.descriptions.orderStatus = orderStatusEnum.name;
      }
    }
  }
}
