<div class="modal-header">
  <h5 class="modal-title">Send {{ sendEmail ? 'Email' : 'SMS' }}</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body mb-1" id="sendEmailSms">
  <div style="padding-bottom: 10px">
    <a
      class="nav-link float-end"
      style="padding: 0"
      (click)="sendEmail = !sendEmail"
      >Switch to {{ sendEmail ? 'SMS' : 'Email' }}</a
    >
  </div>
  <hr class="hr-dashed" />

  <common-send-email
    *ngIf="sendEmail"
    #commonEmailSender
    [body]="emailBody"
    [subject]="emailSubject"
    [to]="emailTo"
    [emailTemplates]="emailTemplates"
    [showTemplates]="showEmailTemplates"
    (selectedEmailTemplateChanged)="selectedEmailTemplateChanged($event)"
  ></common-send-email>

  <common-send-sms
    *ngIf="!sendEmail"
    #commonSmsSender
    [recipients]="smsRecipients"
    [toPhoneNumber]="smsToPhoneNumber"
  ></common-send-sms>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
    data-bs-dismiss="modal"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="onSendClicked()"
    data-bs-dismiss="modal"
  >
    Send
  </button>
</div>
