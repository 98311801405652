import { CalculatedFeeTypeEnum } from './calculated-fee-type.enum';
import { FeeSectionEnum } from './fee-section.enum';
import { FeeTypeEnum } from './fee-type.enum';

export class CreateFeeModel {
  hudNumber: string;
  feeName: string;
  feeSection: FeeSectionEnum;
  calculatedFeeType: CalculatedFeeTypeEnum;
  feeType: FeeTypeEnum;
}
