import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Lender } from 'src/app/models/config/lender.model';

@Component({
  selector: 'lender-ae-info',
  templateUrl: './lender-ae-info.component.html',
  styleUrls: ['./lender-ae-info.component.scss'],
  viewProviders: [formViewProvider],
})
export class LenderAeInfoComponent implements OnInit {

  @Input() lender: Lender;

  urlPattern: string = "[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?";

  constructor() { }

  ngOnInit(): void {
  }

}
