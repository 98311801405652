import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class RemainContactService {

  constructor(private readonly _dataService: DataService) { }

  getAllRemainExternalContacts = (appId: number) => {
    const url = 'api/Loan/' + appId + '/AllRemainExternalContacts';
    return this._dataService.get(url);
  }

  selectAgentForExternalContact = (appId: number, agentId: number, agentTypeId: number) => {
    const url = 'api/Loan/' + appId + '/SelectAgentForExternalContact/' + agentId + '?agentTypeId=' + agentTypeId;
    return this._dataService.post(url, {});
  }
}
