import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentType, LoanDoc } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SyncLoanDoc } from '../../models/sync-loan-docs/sync-loan-doc.model';
import { LoanDocsService } from '../../services/loan-docs.service';

@Component({
    selector: 'doc-mapping-for-vendor-dialog',
    templateUrl: 'doc-mapping-for-vendor-dialog.component.html'
})

export class DocMappingForVendorDialogComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    docTypes: DocumentType[];

    @Input()
    doc: SyncLoanDoc | LoanDoc;

    @Input()
    companyId: number;

    @Input()
    losVendor: string;

    selectedDocTypeId: number = -1;

    constructor(public activeModal: NgbActiveModal,
        private readonly _loanDocsService: LoanDocsService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _notifyService: NotificationService) { }

    ngOnInit() {
    }

    onCancelClicked = () => {
        this.activeModal.close('cancel');
    }

    onSaveClicked = () => {
        this._spinner.show();
        this._loanDocsService.addDocTypes(this.doc, this.losVendor, this.selectedDocTypeId, this.companyId).subscribe(result => {
            this._spinner.hide();
            this.activeModal.close(this.doc);
        }, err => {
            this._spinner.hide();
            this._notifyService.showError(err ? err.error : "Unable to add mapping for vendor.", 'Failure!');
        })
    }

    onSelectionChanged = (selectedDocTypeId: number) => {
        this.selectedDocTypeId = selectedDocTypeId;
    }
}