<form #licensingForm="ngForm" novalidate id="upsert-licensing-form" name="upsertLicensingForm">
    <div class="p-3">
        <div class="mb-3">
            <label class="form-label" for="state">State</label>
            <select class="form-select" name="state" [(ngModel)]="stateLicensing.state"
                [ngClass]="{'is-invalid': stateField && stateField.touched && (stateField.invalid || stateAlreadyExists)}"
                #stateField="ngModel" required>
                <option value=""> -- Select One -- </option>
                <option *ngFor="let item of states" value="{{item.value}}">
                    {{item.name}}
                </option>
            </select>
            <div *ngIf="stateAlreadyExists" class="invalid-feedback">State Record already exists.</div>
        </div>
        <div class="mb-3">
            <label class="form-label" for="nmls">Lic/Reg#</label>
            <input type="text" class="form-control" name="nmls" [(ngModel)]="stateLicensing.nmls"
                [ngClass]="{'is-invalid': nmlsField && nmlsField.touched && nmlsField.invalid}" #nmlsField="ngModel"
                required>
        </div>
        <div class="mb-3">
            <label class="form-label" for="notes">Notes</label>
            <textarea class="form-control" rows="5" name="notes" [(ngModel)]="stateLicensing.notes"></textarea>
        </div>
        <div class="mb-3">
            <label class="form-label" for="issueDate">Issue Date</label>
            <date-input
                [id]="'issueDate-date'"
                [name]="'issueDate-date'"
                [(ngModel)]="stateLicensing.issueDate"
                [max]="stateLicensing.expirationDate"
                [required]="true"
            ></date-input>
        </div>
        <div class="mb-3">
            <label class="form-label" for="expirationDate">Expiration Date</label>
            <date-input
                [id]="'expiration-date'"
                [name]="'expiration-date'"
                [(ngModel)]="stateLicensing.expirationDate"
                [min]="stateLicensing.issueDate"
                [required]="true"
            ></date-input>
        </div>
        <div class="mb-3">
            <div class="form-check form-switch form-switch-success">
                <input class="form-check-input" type="checkbox" name="isActive" id="isActive"
                    [(ngModel)]="stateLicensing.active">
                <label class="form-check-label" for="isActive">Is Active</label>
            </div>
        </div>
        <div class="text-end">
            <button class="btn btn-soft-secondary me-2" (click)="cancel()">
                Cancel
            </button>
            <button class="btn btn-soft-primary me-2" type="button" (click)="save()" [disabled]="saveInProgress">
                <span *ngIf="saveInProgress" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <i *ngIf="!saveInProgress" class="fa fa-save"></i>
                Save
            </button>
        </div>
    </div>
</form>