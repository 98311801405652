import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user/user.model';
import { NotificationService } from 'src/app/services/notification.service';
import { UpdateAction } from '../../../models/bulk-update-lead.model';
import { LeadsService } from '../../../services/leads.service';

@Component({
  selector: 'app-reassign-lead-user-confirmation-dialog',
  templateUrl: './reassign-lead-user-confirmation-dialog.component.html',
  styleUrls: ['./reassign-lead-user-confirmation-dialog.component.scss']
})
export class ReassignLeadUserConfirmationDialogComponent{

  @Input()
  leadIds: number[] = [];

  @Input()
  users: User[] = [];

  selectedReassignUserGUID: string = null;

  isSaving: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _leadsService: LeadsService,
    private readonly _notifyService: NotificationService) { }

  confirmReassign = (): void => {

    this.isSaving = true;

    let request = {
        leadIds: this.leadIds,
        updates: [
            {
                propertyName: "LeadContactUserId",
                value: this.selectedReassignUserGUID,
                action: UpdateAction.Set
            }
        ]
    }

    this._leadsService.bulkUpdateLead(request)
    .subscribe(leads => {

      this._notifyService.showSuccess("Your information has been saved successfully.","Success!");
      this.isSaving = false;

      this.activeModal.close(this.selectedReassignUserGUID);
    },err => {
      this.isSaving = false;
      this._notifyService.showError(err ? err.message || err : '', "Error")
    })


  }

}
