import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ContactList } from 'src/app/models';
import { Alignment } from 'src/app/modules/internal-contacts/models/alignment.model';

@Injectable()
export class AlignmentService {
  constructor(private readonly _dataService: DataService) { }

  getAlignmentsByChannelAndCompanyId = (companyId: number, selectedChannel: string = null): Observable<Alignment[]> => {
    let url = `api/admin/AllAlignmentModel?companyId=${companyId}`;
    if (selectedChannel) {
      url += `&channel=${selectedChannel}`;
    }
    return this._dataService.get(url);
  };

  insertAlignment = (newAlignment: Alignment, companyId: number): Observable<Alignment> => {
    const url = `api/admin/InsertAlignmentModel?companyId=${companyId}`;
    return this._dataService.post(url, newAlignment);
  };

  updateAlignment = (alignment: Alignment, companyId: number): Observable<Alignment> => {
    const url = `api/admin/UpdateAlignmentModel?companyId=${companyId}`;
    return this._dataService.post(url, alignment);
  };

  deleteAlignment = (alignmentId: number, companyId: number): Observable<void> => {
    let url = `api/admin/DeleteAlignmentModel/${alignmentId}?companyId=${companyId}`;
    return this._dataService.delete(url);
  };
}
