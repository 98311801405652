import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationContext, LdeCredential, LdeProviderFeature, LoanApplication, LosCredential, LosProviderFeature } from 'src/app/models';
import { Lender } from 'src/app/models/config/global-config.model';
import { LdeService } from 'src/app/services/lde.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'lde-loan-link',
  templateUrl: 'lde-loan-link.component.html',
  styleUrls: ['lde-loan-link.component.scss']

})

export class LdeLoanLinkComponent implements OnInit {
  @Input() ldeVendors: any;
  @Input() context: ApplicationContext;

  isSearching = false;
  isLinking = false;

  loanSearchList;
  selectedVendor;

  selectedLoanId;
  selectedLoan;

  ldeCredentialId: number = null;
  ldeReference: string;
  borrowerFirst: string;
  borrowerLast: string;
  lenders: Lender[];
  ldeProviders: LosCredential[];
  loading: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly injector: Injector,
    private readonly _ldeService: LdeService,
    private readonly _notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.lenders = this.context.globalConfig.lender;
    this._ldeService.getLosCredentialsForLinkLoanWithLde(this.context.application.applicationId).subscribe((response) => {
      this.ldeProviders = (response as any) || [];
      this.ldeProviders.forEach(vendor => {
        vendor['borrowerDisabled'] = vendor.vendorFeatures.indexOf(LosProviderFeature.Lookup) > -1;
      })
      if (this.ldeProviders.length > 0) {
        if (this.context.application.lenderId) {
          var ldeVendor = this.lenders.find(x => x.lenderId === this.context.application.lenderId)?.ldeVendor;
          if (!!ldeVendor) {
            const index = this.ldeProviders.findIndex(v => v.losVendor === ldeVendor);
            if (index > -1) {
              this.ldeProviders = this.ldeProviders.filter(x => x.losVendor == ldeVendor);
              if (this.ldeProviders.length === 1) {
                this.changeLdeCredential(this.ldeProviders[0].credentialId);
              }
            }
          }
        }
      }
      this.loading = false;
    });
  }

  changeLdeCredential(ldeCredentialId) {
    this.ldeCredentialId = ldeCredentialId;
    this.selectedVendor = this.ldeVendors.find((provider) => provider.credentialId === Number(this.ldeCredentialId)) || {};
    if (this.selectedVendor.borrowerDisabled) {
      this.borrowerFirst = '';
      this.borrowerLast = '';
    }
  }

  search() {
    this.isSearching = true;

    this.selectedLoanId = undefined;
    this.selectedLoan = undefined;

    let filter = this.ldeCredentialId + '?';
    if (this.ldeReference && this.ldeReference.length > 0)
      filter += 'loanNumber=' + this.ldeReference + '&';
    if (this.borrowerFirst && this.borrowerFirst.length > 0)
      filter += 'borrowerFirstName=' + this.borrowerFirst + '&';
    if (this.borrowerLast && this.borrowerLast.length > 0)
      filter += 'borrowerLastName=' + this.borrowerLast + '&';

    this._ldeService.searchLde(filter).subscribe({
      next: (response) => {
        this.loanSearchList = response;
        this.isSearching = false;
      },
      error: (err) => {
        this.isSearching = false;
        this.loanSearchList = undefined;
        this._notificationService.showError(err?.message || "Unable to search results...", "Error")
      }
    });
  }

  setSelectedLoan(loanNumber, ldeIdentifier) {
    console.log(loanNumber, ldeIdentifier)
    this.selectedLoanId = ldeIdentifier;
    this.selectedLoan = {
      loanNumber: loanNumber,
      ldeIdentifier: ldeIdentifier,
    };
  }

  link() {
    this.isLinking = true;

    this._ldeService.link(
      this.context.application.applicationId,
      this.ldeCredentialId,
      this.selectedLoan.ldeIdentifier,
      this.selectedLoan.loanNumber)
      .subscribe((response) => {
        this.isLinking = false;
        this.activeModal.close(response);
      }, err => {
        this.isLinking = false;
        this._notificationService.showError(err?.message || "Link Error", 'Link Error');
      });
  }
}
