import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyCredential } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-visionet-vendor-editor-dialog',
  templateUrl: './visionet-vendor-editor-dialog.component.html',
  styleUrls: ['./visionet-vendor-editor-dialog.component.scss']
})
export class VisionetVendorEditorDialogComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild('credentialForm') credentialForm: NgForm | undefined;
  @Input() credential: ThirdPartyCredential;
  channels: EnumerationItem[] = [];
  selectedChannels: string[];
  isSaving: boolean;

  constructor(private readonly injector: Injector,
    private readonly _channelService: ChannelService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    public activeModal: NgbActiveModal) {
    super(injector);
  }

  ngOnInit(): void {
    const areChannelsEnabled = this.applicationContext.userPermissions.enabledChannels?.length > 0
    if (areChannelsEnabled) {
      this.channels = this._channelService.getChannelsListForDropdown(this.applicationContext.userPermissions.enabledChannels);
      this.selectedChannels = this._channelService
        .getChannelsListForDropdown(this.credential.channels)
        .map(channel => channel.name);
    }
  }

  setCredentialChannels(): void {
    this.credential.channels = this.selectedChannels.length ? this.selectedChannels.join(', ') : '';
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.isSaving = true;
    this._systemLevelService.saveCredential(this.credential)
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          console.log(error)
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      }).add(() => this.isSaving = false);
  }
}
