<div>
  <div class='fieldset-title mb-3'>Styling</div>
  <custom-css-controls [config]='customCssControlsConfig'>
  </custom-css-controls>

  <div class="fieldset-title mt-5">New Online Application</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Default Credit Vendor</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" [(ngModel)]="onlineApplicationSettings.defaultCreditVendor">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let item of creditVendorOptions" value={{item.value}}>
            {{item.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Report Type</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" [(ngModel)]="onlineApplicationSettings.defaultReportType">
          <option [ngValue]="null">-- Select One --</option>
          <option value="Merge">Merge</option>
          <option value="Preapproval">PreApproval</option>
          <option value="Prequal">Prequal</option>
          <option value="RMCR">RMCR</option>
          <option value="SoftPull">SoftPull</option>
          <option value="Streamline">Streamline</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end"></div>
      <div class="col-md-4">
        <div class="form-check form-check-inline ms-2">
          <input id="pullEquifax" class="form-check-input" type="checkbox"
            [(ngModel)]="onlineApplicationSettings.pullEquifax" ToggleValue [true]="true" [false]="false">
          <label class="form-check-label" for="pullEquifax">Pull Equifax</label>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end"></div>
      <div class="col-md-4">
        <div class="form-check form-check-inline ms-2">
          <input id="pullExperian" class="form-check-input" type="checkbox"
            [(ngModel)]="onlineApplicationSettings.pullExperian" ToggleValue [true]="true" [false]="false">
          <label class="form-check-label" for="pullExperian">Pull Experian</label>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end"></div>
      <div class="col-md-4">
        <div class="form-check form-check-inline ms-2">
          <input id="pullTransUnion" class="form-check-input" type="checkbox"
            [(ngModel)]="onlineApplicationSettings.pullTransUnion" ToggleValue [true]="true" [false]="false">
          <label class="form-check-label" for="pullTransUnion">Pull TransUnion</label>
        </div>
      </div>
    </div>
  </div>

  <!-- 3rd party tracking -->
  <div class="fieldset-title mt-5">3rd Party Tracking</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label" for="facebook">Facebook</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" id="facebook" [(ngModel)]="onlineApplicationSettings.facebookTrackingCode">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label" for="google">Google</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" id="google" [(ngModel)]="onlineApplicationSettings.googleTrackingCode">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label" for="google">Hubspot</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" id="google" [(ngModel)]="onlineApplicationSettings.hubspotTrackingCode">
      </div>
    </div>
  </div>
</div>