import { ConferenceParticipant } from "./conference-participant.model";

export class ConferenceLine {
  conferenceLineId: number;
  name: string;
  thirdPartyConferenceId: string;
  currentStatus: ConferenceStatus;
  agentUserId: string;
  participants: ConferenceParticipant[];
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum ConferenceStatus {
  NotInUse = "NotInUse",
  ConnectedIdle = "ConnectedIdle",
  CallActive = "CallActive"
}

