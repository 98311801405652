import { Component, Input, OnInit } from '@angular/core';
import { RegulationZDetail } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'atr-qm-exemption-eligibility',
  templateUrl: 'atr-qm-exemption-eligibility.component.html',
  styleUrls: ['./atr-qm-exemption-eligibility.component.scss']
})

export class AtrQmExemptionEligibilityComponent implements OnInit {

  @Input()
  regulationZDetail: RegulationZDetail;

  @Input()
  abilityToRepayExemptionCreditorOrganizationTypes: EnumerationItem[];

  @Input()
  abilityToRepayExemptionLoanProgramTypes: EnumerationItem[];

  protected isAbilityToRepayRequirements: boolean = false;
  protected creditorTypeChecked: boolean = false;
  protected loanProgramChecked: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType) {
      this.isAbilityToRepayRequirements = true;
      this.creditorTypeChecked = true;
    }
    if (this.regulationZDetail.abilityToRepayExemptionLoanProgramType) {
      this.isAbilityToRepayRequirements = true;
      this.loanProgramChecked = true;
    }
    if (this.regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits) {
      this.isAbilityToRepayRequirements = true;
    }
    if (this.regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose) {
      this.isAbilityToRepayRequirements = true;
    }
  }

  onAbilityToRepayRequirementsClicked = () => {
    if (!this.isAbilityToRepayRequirements) {
      this.creditorTypeChecked = false;
      this.loanProgramChecked = false;
      this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType = null;
      this.regulationZDetail.abilityToRepayExemptionLoanProgramType = null;
      this.regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits = false;
      this.regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose = false;
    } else {
      if (!(this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType || this.regulationZDetail.abilityToRepayExemptionLoanProgramType || this.regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits || this.regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose)) {
        this.creditorTypeChecked = true;
        this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType = this.abilityToRepayExemptionCreditorOrganizationTypes[0].value;
      }
    }
  }

  onCreditorTypeCheckboxClicked = () => {
    // this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType = !this.creditorTypeChecked ? null : !this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType ? this.abilityToRepayExemptionCreditorOrganizationTypes[0].value : this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType;
    if (!this.creditorTypeChecked) {
      this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType = null;
    } else {
      if (!this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType) {
        this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType = this.abilityToRepayExemptionCreditorOrganizationTypes[0].value;
      }
    }
  }

  onCreditorTypeChanged = () => {
    if (this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationType !== 'Other') {
      this.regulationZDetail.abilityToRepayExemptionCreditorOrganizationTypeOtherDescription = null;
    }
  }

  onLoanProgramCheckboxClicked = () => {
    if (!this.loanProgramChecked) {
      this.regulationZDetail.abilityToRepayExemptionLoanProgramType = null;
    } else {
      if (!this.regulationZDetail.abilityToRepayExemptionLoanProgramType) {
        this.regulationZDetail.abilityToRepayExemptionLoanProgramType = this.abilityToRepayExemptionLoanProgramTypes[0].value;
      }
    }
  }

  onLoanProgramChanged = () => {
    if (this.regulationZDetail.abilityToRepayExemptionLoanProgramType !== 'Other') {
      this.regulationZDetail.abilityToRepayExemptionLoanProgramTypeOtherDescription = null;
    }
  }
}