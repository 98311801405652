<div class="card border-top border-0 border-4 border-white">
  <div class="card-header">
    <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-cog me-2"></i>
      Pricing Scenarios
    </h4>
    <div class="float-end button-items">
      <button class="btn btn-primary btn-sm" *ngIf="pricingScenarios.length"
          (click)="onLoanComparisonClicked()">Loan Comparison</button>
    </div>
  </div>
  <div class="card-body mb-4">
    <loading-indicator *ngIf="isLoading" [loadingMessage]="'Loading pricing scenarios...'"></loading-indicator>
    <div *ngIf="!isLoading">
      <pinned-scenarios
        [applicationId]="application.applicationId"
        [isLoanReadOnly]="isLoanReadOnly"
        [scenarios]="pricingScenarios"
        (applyScenarioToLoanClicked)="onApplyScenarioToLoanClicked($event)">
      </pinned-scenarios>
    </div>
  </div>
</div>
