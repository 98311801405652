<form #percentInputForm="ngForm">
  <div *ngIf="!inEditMode" class="form-input"
    [ngClass]="
      { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'bg-transparent' : readonly
      }"
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">

    <span class="input-label" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>
    <div class="row calculated-field align-right justify-content-between">
      <div class="col-xs-6 px-2 urla-currency" [ngClass]="{ 'col-sm-11': formElement, 'col-sm-12': !formElement }">
        <input
          type="text"
          #percentage
          mask="999.000"
          suffix="%"
          [(ngModel)]="value"
          #model="ngModel"
          name="{{name}}"
          [required]="required"
          [dropSpecialCharacters]="false"
          [placeholder]="'%'"
          [ngClass]="{
            'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
            'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
          }"
          [attr.maxLength]="maxLength ? maxLength.toString() : null"
          [disabled]="disabled"
          class="input-element"
          [readonly]="readonly"/>
      </div>
    </div>
  </div>
  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
