<form #sendEmailForm="ngForm" ngbAutofocus>
  <div class="row mb-3" *ngIf="recipientVisible">
    <div class="col-12 form-group">
      <label class="form-label" for="recipientEmailAddresses">To</label>
      <input class="form-control" [ngClass]="{'is-invalid': recipients && recipients.touched && recipients.invalid}"
        type="email" trim name="recipientEmailAddresses" [(ngModel)]="to" #recipients="ngModel" required/>
      <div class="invalid-feedback">To is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <a class="text-start nav-link" style="padding:0px" (click)="willAddCcOrBcc = true">CC or BCC</a>
    </div>
  </div>
  <ng-container *ngIf="willAddCcOrBcc">
    <div class="row mb-3">
      <div class="col-12 form-group">
        <label class="form-label">CC</label>
        <input class="form-control" name="ccEmailAddresses" [(ngModel)]="cc" #recipients="ngModel" />
      </div>
      <div class="col-12 form-group">
        <label class="form-label">BCC</label>
        <input class="form-control" name="bccEmailAddresses" [(ngModel)]="bcc" #recipients="ngModel" />
      </div>
    </div>
  </ng-container>
  <div class="row mb-3" *ngIf="emailTemplates.length >= 0 && showTemplates">
    <div class="col-12">
      <a class="text-start nav-link" style="padding:0px" (click)="useTemplateEmail = true">Use Template Email</a>
    </div>
  </div>
  <ng-container *ngIf="useTemplateEmail">
    <div class="row mb-3">
      <div class="col-12 form-group">
        <label class="form-label" for="emailTemplate">Email Template</label>
        <select class="form-select" name="emailTemplate" id="emailTemplate" [(ngModel)]="selectedEmailTemplateId"
          (ngModelChange)="onSelectedEmailTemplateChanged()" placeholder="Select a Template">
          <option [ngValue]="null">--Select a Template--</option>
          <option *ngFor="let emailTemplate of emailTemplates" [ngValue]="emailTemplate.emailTemplateId"
            [hidden]="!emailTemplate.emailTemplateName">
            {{ emailTemplate.emailTemplateName }}
          </option>
        </select>
      </div>
    </div>
  </ng-container>
  <div class="row mb-3">
    <div class="col-12 form-group">
      <label class="form-label" for="subject">Subject</label>
      <input class="form-control"
        [ngClass]="{ 'is-invalid': emailSubject && emailSubject.touched && emailSubject.invalid }" name="subject"
        [(ngModel)]="subject" #emailSubject="ngModel" required />
      <div class="invalid-feedback">Please, enter a subject</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 form-group">
      <label class="form-label" for="body">Body <span *ngIf="emailGenerationEnabled" class="ms-2 text-primary fw-bold cursor" (click)="openEmailGenerationModal()">Need an idea?
        Click here...</span></label>
      <rich-text-editor [name]="'emailBody'" [(ngModel)]="body"  [mergeFields]="emailMergeFieldsWithUnSubLink">
      </rich-text-editor>
      <div *ngIf="!body && validationDone" class="invalid text-danger">Please, enter the body of your email</div>

      <div class="d-flex align-items-center" *ngIf="emailMergeFieldsWithUnSubLink">
        <span class="text-muted flex-grow-1">
            You can use mouse right click button to select and insert a merge a
            field.
        </span>
        <button type="button" class="btn btn-secondary my-2" (click)="openSendTestEmailModal()">Send Test
            Email</button>
    </div>

    </div>
  </div>
  <div class="custom-control custom-switch switch-success">
    <input type="checkbox" class="custom-control-input" id="appendEmailSignature" name="appendEmailSignature"
      [(ngModel)]="appendEmailSignature" />
    <label style="padding-left: 4px" class="form-label" for="appendEmailSignature">Append Email Signature</label>
  </div>
</form>

<merge-field-context-menu [menuItems]="availableMergeFields" *ngIf="emailMergeFieldsWithUnSubLink">
</merge-field-context-menu>
