import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, forkJoin } from 'rxjs';
import { ApplicationContext, ExternalCompany, LoanApplication, Mortgage, Role } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProcessSubmissionModel } from '../../models/process-submission.model';
import { NewApplicationService } from '../../services/new-application.service';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input()
  context: ApplicationContext;

  @Input()
  isImportFromAusAllowed: boolean;

  @Output()
  nextStep: EventEmitter<{ processSubmissionData: any, step?: string }> = new EventEmitter<any>();

  @Output()
  file: EventEmitter<any> = new EventEmitter<any>();

  isTpoUser: boolean;
  isAdminUser: boolean;
  enabledChannels: Array<EnumerationItem>;
  externalCompanies: ExternalCompany[];
  availableUsers: User[];
  processSubmissionData: ProcessSubmissionModel = new ProcessSubmissionModel();
  selectedChannel: string = '';
  selectedCompanyId: number | '';
  firstRole: Role;
  primaryRoleUserId: string;
  losEnabled: boolean;
  isSuperAdmin: boolean = false;
  wholesaleEnabled: boolean;
  correspondentEnabled: boolean;
  nonDelCorrespondentEnabled: boolean;
  processingFannie: boolean;
  loanData: LoanApplication;
  tpoRoles: Role[] = [];

  constructor(
    private readonly _channelService: ChannelService,
    private readonly _newApplicationService: NewApplicationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService) { }

  ngOnInit(): void {
    this.enabledChannels = [];
    this.primaryRoleUserId = this.context.currentlyLoggedInUser.userCompanyGuid;
    this.losEnabled = this.context.userPermissions.losEnabled;
    this.isTpoUser = this.context.isTpo;
    this.isAdminUser = this.context.userPermissions.admin;
    this.isSuperAdmin = this.context.userPermissions.superAdmin;
    this.tpoRoles = this.context.globalConfig.roles.filter(r => r.tpoEnabled);

    if (this.isTpoUser) {
      let externalCompanyId: number;
      const user = this.context.globalConfig.tpoUsers.find(u => u.userCompanyGuid === this.context.currentlyLoggedInUser.userCompanyGuid);

      if (user) {
        const branch = this.context.globalConfig.branches.find(b => b.branchId === user.branchId);
        externalCompanyId = branch?.externalCompanyId || user.externalCompanyId;
        let availableUsers = this.context.globalConfig.tpoUsers.filter(x => x.externalCompanyId === externalCompanyId);
        if (branch) {
          availableUsers = availableUsers.filter(x => this.context.userPermissions.branchIds.indexOf(x.branchId) > -1);
        }
        this.availableUsers = availableUsers;
      }

      this.externalCompanies = this.context.globalConfig.externalCompanies.filter(x => x.externalCompanyId === externalCompanyId);
      if (this.externalCompanies.length > 0) {
        const availableChannelNames = this._channelService
          .getChannelsFromCommaDelimitedString(this.externalCompanies[0]?.enabledChannels)
          .map(c => c.name);
        this.enabledChannels = this.context.globalConfig.enabledChannels.filter(c => availableChannelNames.includes(c.name));
        this.wholesaleEnabled = this.enabledChannels.filter(x => x.name == 'Wholesale').length > 0;
        this.correspondentEnabled = this.enabledChannels.filter(x => x.name == 'Correspondent').length > 0;
        this.nonDelCorrespondentEnabled = this.enabledChannels.filter(x => x.name == 'NonDelegatedCorrespondent').length > 0;
      }
      this.selectedCompanyId = externalCompanyId;
      this.selectedChannel = this.wholesaleEnabled ? "Wholesale" : this.nonDelCorrespondentEnabled ? "NonDelegatedCorrespondent" : "Correspondent";
    } else {
      this.externalCompanies = this.context.globalConfig.externalCompanies;
      this.availableUsers = this.context.globalConfig.users;
      this.enabledChannels = this.context.globalConfig.enabledChannels;
    }

    this.firstRole = this.getFirstRoleByChannel(this.selectedChannel);

    this.availableUsers.forEach(user => {
      user['userFullName'] = Utils.getPersonsDisplayName(user);
    });

    this.availableUsers = this.availableUsers.sort((a, b) => a['userFullName'].localeCompare(b['userFullName']));

    ($('.dropify') as any).dropify();
  }

  processMismoFile = (data) => {
    this._spinner.show();
    this._newApplicationService.uploadFileToUrl(`api/new-submission/parse-mismo-34?sanitizeLoan=${data.sanitizeLoanData}`, data.mismoFiles)
      .subscribe({
        next: (response) => {
          this.processSubmissionData.mortgageViewModel = response.mortgageViewModel;
          this.processSubmissionData.suggestedBorr = response.suggestedBorrowers;
          this.processSubmissionData.loanNumber = response.loanNumber;
          this.processSubmissionData.loanPurposeId = response.loanPurposeId;
          this.processSubmissionData.loanTypeId = response.loanTypeId;
          this.processSubmissionData.linkedBorrowers = [];
          this.processSubmissionData.submissionViaFile = true;
          this.processSubmissionData.importMismo = true;
          this.processSubmissionData.channel = data.channel;
          this.processSubmissionData.primaryRoleUserId = data.primaryRoleUserId;
          this.processSubmissionData.additionalAlignmentRoleUsers = data.additionalAlignmentRoleUsers;
          this.processSubmissionData.loanCharacteristics = []
          this.processSubmissionData.borrowerCharacteristics = []
          this.processSubmissionData.keyDateValues = response.keyDateValues
          this.processSubmissionData.loanFees = response.loanFees;
          this.processSubmissionData.sanitizeLoanData = data.sanitizeLoanData;

          this.processSubmissionData.loanData = response.loanData;

          this.file.emit(data.mismoFiles);
          this.nextStep.emit({ processSubmissionData: this.processSubmissionData, step: "uploadFile" });
          this._spinner.hide();
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't process MISMO 3.4 file", "Error")
          this._spinner.hide();
        }
      });
  }

  processDuFile = (data) => {
    this._spinner.show();
    this._newApplicationService.uploadFileToUrl("api/new-submission/parse-du", data.duFiles)
      .subscribe({
        next: (response) => {
          this.processSubmissionData.mortgageViewModel = response.mortgageViewModel;
          this.processSubmissionData.suggestedBorr = response.suggestedBorrowers;
          this.processSubmissionData.loanNumber = response.loanNumber;
          this.processSubmissionData.loanPurposeId = response.loanPurposeId;
          this.processSubmissionData.loanTypeId = response.loanTypeId;
          this.processSubmissionData.linkedBorrowers = [];
          this.processSubmissionData.submissionViaFile = true;
          this.processSubmissionData.importMismo = false;
          this.processSubmissionData.channel = data.channel;
          this.processSubmissionData.primaryRoleUserId = data.primaryRoleUserId;
          this.processSubmissionData.additionalAlignmentRoleUsers = data.additionalAlignmentRoleUsers;
          this.processSubmissionData.loanCharacteristics = []
          this.processSubmissionData.borrowerCharacteristics = []

          this.file.emit(data.duFiles);
          this.nextStep.emit({ processSubmissionData: this.processSubmissionData, step: "uploadFile" });
          this._spinner.hide();
        },
        error: (err) => {
          this._notificationService.showError(err?.message || 'Unable to parse DU 3.2 file', 'Error!');
          this._spinner.hide();
        }
      });
  }

  processFannie(data: any) {
    this._spinner.show();
    this.processingFannie = true;
    this._newApplicationService.importFannie(data.casefileId)
      .pipe(finalize(() => {
        this._spinner.hide();
        this.processingFannie = false;
      }))
      .subscribe({
        next: (response) => {
          this.processSubmissionData.mortgageViewModel = response.mortgageViewModel;
          this.processSubmissionData.suggestedBorr = response.suggestedBorrowers;
          this.processSubmissionData.loanNumber = response.loanNumber;
          this.processSubmissionData.loanPurposeId = response.loanPurposeId;
          this.processSubmissionData.loanTypeId = response.loanTypeId;
          this.processSubmissionData.linkedBorrowers = [];
          this.processSubmissionData.submissionViaFile = true;
          this.processSubmissionData.importMismo = true;
          this.processSubmissionData.channel = data.channel;
          this.processSubmissionData.primaryRoleUserId = data.primaryRoleUserId;
          this.processSubmissionData.additionalAlignmentRoleUsers = data.additionalAlignmentRoleUsers;
          this.processSubmissionData.loanCharacteristics = [];
          this.processSubmissionData.borrowerCharacteristics = [];
          this.processSubmissionData.keyDateValues = response.keyDateValues;
          this.processSubmissionData.loanData = response.loanData;

          this.nextStep.emit({ processSubmissionData: this.processSubmissionData, step: "uploadFile" });
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't Import from Fannie", "Error")
        }
      });
  }

  importFromLos = (data) => {
    this._spinner.show();
    this._newApplicationService.importFromLos(data.losCredentialId, data.selectedLoan.losIdentifier)
      .subscribe({
        next: (response) => {
          this.processSubmissionData.credentialId = data.losCredentialId;
          this.processSubmissionData.mortgageViewModel = response.mortgageViewModel;
          this.processSubmissionData.mortgageViewModel.channel = data.channel;
          this.processSubmissionData.suggestedBorr = response.suggestedBorrowers;
          this.processSubmissionData.losIdentifier = response.losIdentifier;
          this.processSubmissionData.loanNumber = data.selectedLoan.loanNumber;
          this.processSubmissionData.linkedBorrowers = [];
          this.processSubmissionData.submissionViaFile = true;
          this.processSubmissionData.importMismo = true;
          this.processSubmissionData.channel = data.channel;
          this.processSubmissionData.primaryRoleUserId = data.primaryRoleUserId;
          this.processSubmissionData.additionalAlignmentRoleUsers = data.additionalAlignmentRoleUsers;
          this.processSubmissionData.referralSourceType = data.referralSourceType;
          this.processSubmissionData.secondaryReferralSourceType = data.secondaryReferralSourceType;

          this.processSubmissionData.loanPurposeId = this.getLoanPurposeIdFromMortgage(response.mortgageViewModel);
          this.processSubmissionData.loanTypeId = this.getLoanTypeIdFromMortgage(response.mortgageViewModel);
          this.processSubmissionData.loanCharacteristics = []
          this.processSubmissionData.borrowerCharacteristics = []
          this.processSubmissionData.loanData = response.loanData;
          if (this.processSubmissionData.loanData?.application) {
            this.processSubmissionData.loanData.application.channel = data.channel;
            if (this.processSubmissionData.loanData.mortgage) {
              this.processSubmissionData.loanData.mortgage.channel = data.channel;
            }
          }

          this.firstRole = this.getFirstRoleByChannel(response.mortgageViewModel.channel);

          this.nextStep.emit({ processSubmissionData: this.processSubmissionData, step: "uploadFile" });
          this._spinner.hide();
        }, error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't import from LOS.", "Error")
          this._spinner.hide();
        }
      });
  }


  getFirstRoleByChannel = (channel) => {
    if (!channel) {
      return this.context.globalConfig.firstRole;
    }
    return this.context.globalConfig.channelRoles[channel.toLowerCase()][0];
  }

  private getLoanPurposeIdFromMortgage(mortgage: Mortgage) {
    if (!mortgage?.subjectProperty?.purposeOfLoan) return "";
    var lp = this.context.globalConfig.loanPurpose.filter(x => x.mortgageLoanPurpose == mortgage?.subjectProperty?.purposeOfLoan);
    if (lp.length > 0)
      return lp[0].loanPurposeId;
    return "";
  }

  private getLoanTypeIdFromMortgage(mortgage: Mortgage) {
    if (!mortgage?.mortgageTerm?.mortgageAppliedFor) return "";
    var lt = this.context.globalConfig.loanType.filter(x => x.mortgageAppliedForType == mortgage?.mortgageTerm?.mortgageAppliedFor);
    if (lt.length > 0)
      return lt[0].loanTypeId;
    return "";
  }

  startNewApp = (data) => {
    this._spinner.show();
    const promises: any = {};
    promises.firstBorrower = this._newApplicationService.getSuggestedBorrower(data.mortgageModel.borrowers[0].firstName, data.mortgageModel.borrowers[0].lastName, data.mortgageModel.borrowers[0].primaryEmail);
    if (data.mortgageModel.borrowers.length > 1) {
      promises.secondBorrower = this._newApplicationService.getSuggestedBorrower(data.mortgageModel.borrowers[1].firstName, data.mortgageModel.borrowers[1].lastName, data.mortgageModel.borrowers[1].primaryEmail);
    }
    forkJoin(promises)
      .subscribe({
        next: (response: any) => {
          this.processSubmissionData.mortgageViewModel = data.mortgageModel;
          this.processSubmissionData.linkedBorrowers = [];
          this.processSubmissionData.primaryRoleUserId = data.primaryRoleUserId;
          this.processSubmissionData.additionalAlignmentRoleUsers = data.additionalAlignmentRoleUsers;
          this.processSubmissionData.loanNumber = undefined;
          this.processSubmissionData.loanPurposeId = data.loanPurposeId;
          this.processSubmissionData.loanTypeId = data.loanTypeId;
          this.processSubmissionData.channel = data.channel;
          this.processSubmissionData.referralSource = data.referralSource;
          this.processSubmissionData.secondaryReferralSource = data.secondaryReferralSource;
          this.processSubmissionData.referralSourceType = data.referralSourceType;
          this.processSubmissionData.secondaryReferralSourceType = data.secondaryReferralSourceType;
          this.processSubmissionData.referredTo = data.referredTo;
          this.processSubmissionData.insideReferralSource = data.insideReferralSource;
          this.processSubmissionData.submissionViaFile = true;
          this.processSubmissionData.importMismo = false;
          this.processSubmissionData.suggestedBorr = { 0: response.firstBorrower }

          if (data.mortgageModel.borrowers.length > 1) {
            this.processSubmissionData.suggestedBorr[1] = response.secondBorrower;
          }
          this.processSubmissionData.loanCharacteristics = [];
          this.processSubmissionData.borrowerCharacteristics = [];
          this.processSubmissionData.loanData = response.loanData;
          this.processSubmissionData.availableLoanTypes = data.availableLoanTypes;
          this.processSubmissionData.availableLoanPurposes = data.availableLoanPurposes;

          this.nextStep.emit({ processSubmissionData: this.processSubmissionData, step: "uploadFile" });
          this._spinner.hide();
        },
        error: () => {
          this._spinner.hide();
        }
      });
  }
}
