
  <div class="card p-2">
    <div class="card-body">
      <div *ngIf="defaultPlaceholder; else contentInfo" class="text-center">
        <h3 style="font-weight: 300">{{ defaultPlaceholder }}</h3>
      </div>

      <ng-template #contentInfo>
        <fee-definition-table
          [feeTemplate]="template"
          [fees]="originationFees"
          [feeSection]="'Origination'"
          [title]="
            'A. Origination Charges - 800. Items Payable in Connection with Loan'
          "
          [expressionFields]="expressionFields"
          [feeSystemDetails]="feeSystemDetails"
          (onTemplateFeesChanged)="templateFeesChanged($event)"
        ></fee-definition-table>

        <div class="mt-4">
          <fee-definition-table
            [feeTemplate]="template"
            [fees]="servicesNoShopFees"
            [feeSection]="'ServicesNoShop'"
            [title]="'B. Services Borrower Cannot Shop For'"
            [expressionFields]="expressionFields"
            [feeSystemDetails]="feeSystemDetails"
            (onTemplateFeesChanged)="templateFeesChanged($event)"
          ></fee-definition-table>
        </div>

        <div class="mt-4">
          <fee-definition-table
            [feeTemplate]="template"
            [fees]="servicesFees"
            [feeSection]="'Services'"
            [title]="'C. Services Borrower Can Shop For - 1100. Title Charges'"
            [expressionFields]="expressionFields"
            [feeSystemDetails]="feeSystemDetails"
            (onTemplateFeesChanged)="templateFeesChanged($event)"
          ></fee-definition-table>
        </div>

        <div class="mt-4">
          <fee-definition-table
            [feeTemplate]="template"
            [fees]="governmentTaxesAndFees"
            [feeSection]="'GovernmentTaxesAndFees'"
            [title]="
              'E. Taxes And Other Government Fees - 1200. Government Recording & Transfer Charges'
            "
            [expressionFields]="expressionFields"
            [feeSystemDetails]="feeSystemDetails"
            (onTemplateFeesChanged)="templateFeesChanged($event)"
          ></fee-definition-table>
        </div>

        <div class="mt-4">
          <fee-definition-table
            [feeTemplate]="template"
            [fees]="prepaidsFees"
            [feeSection]="'Prepaids'"
            [title]="
              'F. Prepaid - 900. Items Required by Lender to Be Paid in Advance'
            "
            [isReadOnly]="true"
            [feeSystemDetails]="feeSystemDetails"
          ></fee-definition-table>
        </div>

        <div class="mt-4">
          <fee-definition-table
            [feeTemplate]="template"
            [fees]="escrowFees"
            [feeSection]="'Escrow'"
            [title]="
              'G. Initial Escrow Payment at Closing - 1000. Reserves Deposited with Lender'
            "
            [isReadOnly]="true"
            [feeSystemDetails]="feeSystemDetails"
          ></fee-definition-table>
        </div>

        <div class="mt-4">
          <fee-definition-table
            [feeTemplate]="template"
            [fees]="otherFees"
            [feeSection]="'Other'"
            [title]="'H. Other - 1300. Additional Settlement Charges'"
            [expressionFields]="expressionFields"
            [feeSystemDetails]="feeSystemDetails"
            (onTemplateFeesChanged)="templateFeesChanged($event)"
          ></fee-definition-table>
        </div>
      </ng-template>
    </div>
  </div>

