import { Channel } from "src/app/modules/leads/models/lead.model";
import { ExpressionGroup } from "../expressions/expression-group.model";

export class Task {
  taskId: number;
  taskName: string;
  triggerAssociations: string;
  expressionGroupId: number;
  expressionGroup: ExpressionGroup;
  borrowerTrigger: TaskApplyToEnum;
  isLeadTask: boolean;
  systemGeneratedTask: SystemGeneratedTaskTypeEnum;
  condition: boolean;
  conditionId: string;
  conditionText: string;
  conditionType: ConditionMilestoneTypeEnum;
  roleId: number;
  documentTypeId: number;
  reviewRequiredRoleId: number;
  notifyPartyRoleId: number;
  escalationRoleId: number;
  documentTemplateModelId: number;
  channel: Channel;
  taskType: TaskTypeEnum;
  defaultTaskPriority: TaskPriorityEnum;
  dueDays: number;
  permittedAgentTypes: string;
  tasksOnCompletion: string;
  documentDataHeader: string;
  documentData: string;
  docDataString: string;
  expectedCompletionMinutes: number;
  requestBorrower: boolean;
  alwaysShowPending: boolean;
  helpUrl: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum SystemGeneratedTaskTypeEnum {
  OnlineApplication = "OnlineApplication",
  DigitalAssetVerification = "DigitalAssetVerification"
}

export enum TaskApplyToEnum {
  Loan = "Loan",
  PrimaryBorrower = "PrimaryBorrower",
  AllBorrowers = "AllBorrowers",
  SelectBorrowers = "SelectBorrowers"
}

export enum ConditionMilestoneTypeEnum {
  Approval = "Approval",
  Docs = "Docs",
  Funding = "Funding",
  Closing = "Closing",
  Purchase = "Purchase"
}

export enum TaskTypeEnum {
  RequestDocument = "RequestDocument",
  ViewDocument = "ViewDocument",
  AcknowledgeReceipt = "AcknowledgeReceipt",
  EsignDocument = "EsignDocument",
  RequestInformation = "RequestInformation",
  PerformAction = "PerformAction",
  OnlineApplication = "OnlineApplication",
  DigitalAssetVerification = "DigitalAssetVerification",
  GenerateDocument = "GenerateDocument",
  RequestPayoff = "RequestPayoff",
  RequestHoi = "RequestHoi",
  RequestTitle = "RequestTitle",
  RequestEscrow = "RequestEscrow",
  RequestAppraisal = "RequestAppraisal",
  LosEsign = "LosEsign",
  WetSignDocument = "WetSignDocument",
  LoanBeamSync = "LoanBeamSync",
  GatherPayment = "GatherPayment",
  ChangeOfCircumstance = "ChangeOfCircumstance",
  Escalation = "Escalation", 
  DenialTerminationChange = 'DenialTerminationChange',
  QuickClientUpdateReferralLead = 'QuickClientUpdateReferralLead', // lead task only
  QuickClientUpdatePreApproved = 'QuickClientUpdatePreApproved',
  QuickClientUpdateInProcess = 'QuickClientUpdateInProcess',
  Checklist = 'Checklist',
  DocumentTypeReview = 'DocumentTypeReview',
}

export enum TaskPriorityEnum {
  Low = "Low",
  Normal = "Normal",
  High = "High",
  Immediate = "Immediate"
}
