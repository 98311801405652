<div class="row">
    <div class="col-sm-12 font-16 pt-1">
        <a (click)="isDeleteClicked[index] = true" *ngIf="!isDeleteClicked[index]">
            <i class="fa fa-trash-alt me-0 text-danger"></i>
        </a>
        <a (click)="isDeleteClicked[index] = false" *ngIf="isDeleteClicked[index]">
            <i class="fa fa-times-circle me-2 text-danger"></i>
        </a>
        <a (click)="isDeleteClicked[index] = false; list.splice(index, 1)"
           *ngIf="isDeleteClicked[index]">
            <i class="fa fa-check-circle text-primary"></i>
        </a>
    </div>
</div>
