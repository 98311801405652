import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LeadStatus } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { instanceOfA } from 'src/utils';
import { LeadStatusAssociation, LeadStatusFlow } from '../../../models';
import { LeadStatusFlowService } from '../../../services';

@Component({
  templateUrl: 'lead-status-flow.component.html',
  styleUrls: ['./lead-status-flow.component.scss'],
})
export class LeadStatusFlowComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  loading: boolean;
  saving: boolean;
  leadStatuses: Array<LeadStatus> = [];
  leadStatusAssociated: Array<LeadStatusAssociation> = [];
  sortableLeadStatusAssociations: Array<LeadStatusFlow> = [];

  leadStatusesOptions: any = {
    group: {
      name: 'chart',
      pull: 'clone',
      put: false,
    },
    animation: 150,
    fallbackTolerance: 3,
    sort: false,
    onEnd: (evt) => {
      if (evt.from.id === evt.to.id) {
        return;
      }

      const parentStatusId = this.getStatusIdFromClass(evt.to.id);
      if (!this.isDropValid(evt.item.id, parentStatusId)) {
        this.removeInvalidItemAfterDrop(evt.newIndex, parentStatusId);
      }
      this.onStatusMoved();
    },
  };

  leadStatusAssociatedSortableOptions: any = {
    group: {
      name: 'chart',
      pull: false,
    },
    animation: 150,
    fallbackOnBody: true,
    invertSwap: true,
    emptyInsertThreshold: 20,
    sort: false,
  };

  loanPurposeName: string;

  private _companyId: number;
  private _loanPurposeId: number;
  private _statusType: string;

  private _paramsSubscription: Subscription;
  private _queryParamsSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _leadStatusFlowService: LeadStatusFlowService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
    this._paramsSubscription = this._activatedRoute.params.subscribe((params) => {
      this._loanPurposeId = parseInt(params['loanPurposeId']);
      this.loanPurposeName = params['loanPurposeName'];
    });
    this._queryParamsSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      this._statusType = params['statusType'];
    });
    this._companyId = this.applicationContext.userPermissions.companyId;
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    if (this._paramsSubscription) {
      this._paramsSubscription.unsubscribe();
    }
    if (this._queryParamsSubscription) {
      this._queryParamsSubscription.unsubscribe();
    }
  }

  save() {
    this.saving = true;

    const combined = combineLatest(
      this.sortableLeadStatusAssociations.map((association) => {
        return this._leadStatusFlowService.updateLeadStatusFlowTree(
          this._statusType,
          this._loanPurposeId,
          association.statusId,
          association.subordinates.map((sub) => sub.statusId).join(','),
          this._companyId
        );
      })
    );

    combined
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe(
        (res) => {
          this.goBack();
        },
        ({ error }) => {
          this._notificationService.showError(
            error || "Couldn't update lead status flow",
            'Lead Status Flow'
          );
        }
      );
  }

  removeFromTree({ statusId, parentStatusId }) {
    if (parentStatusId) {
      const association = this.sortableLeadStatusAssociations.find(
        (a) => a.statusId === parentStatusId
      );
      const index = association.subordinates.findIndex(
        (a) => a.statusId === statusId
      );
      association.subordinates.splice(index, 1);
    } else {
      const index = this.sortableLeadStatusAssociations.findIndex(
        (a) => a.statusId === statusId
      );
      this.sortableLeadStatusAssociations.splice(index, 1);
    }
  }

  cancel() {
    this.goBack();
  }

  private getData() {
    this.loading = true;
    const combined = combineLatest([
      this._leadStatusFlowService.getAllLeadStatuses(
        this._statusType,
        this._companyId
      ),
      this._leadStatusFlowService.getAllLeadStatusAssociations(
        this._statusType,
        this._loanPurposeId,
        this._companyId
      ),
    ]);

    combined
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        ([leadStatuses, leadStatusAssociations]) => {
          this.leadStatuses = leadStatuses;
          this.mapLeadStatusAssociation(leadStatusAssociations);
        },
        ({ error }) => {
          this._notificationService.showError(
            error || "Couldn't load lead statuses flow",
            'Lead Status Flow'
          );
        }
      );
  }

  private prepareStatusToDisplay<T>(status: T): LeadStatusFlow {
    const result: LeadStatusFlow = new LeadStatusFlow();

    if (instanceOfA<LeadStatusFlow>(status, 'statusId')) return status;

    if (status['loanStatusName']) {
      result.displayName = status['loanStatusName'];
    }
    if (status['loanStatusId']) {
      result.statusId = status['loanStatusId'];
    }
    if (!status['subordinates']) {
      result.subordinates = [];
    }
    return result;
  }

  private mapLeadStatusAssociation(leadStatusAssociations) {
    this.sortableLeadStatusAssociations = leadStatusAssociations.map(
      (association) => {
        const ids = association.associatedStatuses?.split(',') || [];

        const subordinates: Array<LeadStatusFlow> = [];
        this.leadStatuses.forEach((ls) => {
          if (ids.indexOf(ls.loanStatusId.toString()) > -1) {
            subordinates.push({
              displayName: ls.loanStatusName,
              statusId: ls.loanStatusId,
              subordinates: [],
              level: 1,
            });
          }
        });

        const status = this.leadStatuses.find(
          (ls) => ls.loanStatusId === association.loanStatusId
        );
        return {
          displayName: status.loanStatusName,
          statusId: status.loanStatusId,
          subordinates: subordinates,
          level: 0,
        };
      }
    );
  }

  private goBack() {
    this._router.navigate(['admin/loan-config/loan-purpose']);
  }

  // drag and drop

  private onStatusMoved() {
    this.sortableLeadStatusAssociations.forEach((association, index) => {
      association.level = 0;
      if (!association.subordinates) {
        association = this.prepareStatusToDisplay(association);
        this.sortableLeadStatusAssociations[index] = association;
      }
      association.subordinates = association.subordinates.map((a, index) => {
        a = this.prepareStatusToDisplay(a);
        a.level = 1;
        return a;
      });
    });
  }

  private removeInvalidItemAfterDrop(newIndex: number, parentStatusId: string) {
    if (parentStatusId === '') {
      return this.sortableLeadStatusAssociations.splice(newIndex, 1);
    }
    const parent = this.sortableLeadStatusAssociations.find(
      (a) => a.statusId === parseInt(parentStatusId)
    );
    if (parent) {
      parent.subordinates.splice(newIndex, 1);
    }
  }

  private isDropValid(itemId: string, parentId: string): boolean {
    if (parentId === itemId) return false;

    if (parentId === '') {
      const index = this.sortableLeadStatusAssociations.findIndex(
        (a) => a.statusId === parseInt(itemId)
      );
      if (index > -1) return false;
    } else {
      const parent = this.sortableLeadStatusAssociations.find(
        (a) => a.statusId === parseInt(parentId)
      );
      if (!parent) return false;
      const index = parent.subordinates.findIndex(
        (s) => s.statusId === parseInt(itemId)
      );
      if (index > -1) return false;
    }

    return true;
  }

  private getStatusIdFromClass(value: string): string {
    return value.split('_')[1] || '';
  }
}
