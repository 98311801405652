<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button
    [disabled]="saving"
    type="button"
    class="btn btn-default"
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    [disabled]="saving"
    type="button"
    class="btn btn-primary"
    (click)="save()"
  >
    <span *ngIf="!saving">Save</span>
    <span
      *ngIf="saving"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </button>
</ng-template>

<ng-template #mainContent>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
        <div class="card-header">
          <h5>Lead Statuses Associated - {{loanPurposeName}}</h5>
        </div>
        <div class="card-body">
          <div
            *ngIf="!sortableLeadStatusAssociations.length"
            class="alert alert-info"
            role="alert"
          >
            Please start dragging lead statuses from the lead statuses list and
            drop it below to get started.
          </div>
          <div
            id="lead-statuses-associated"
            [sortablejs]="sortableLeadStatusAssociations"
            [sortablejsOptions]="leadStatusAssociatedSortableOptions"
            class="sortable-list-container"
          >
            <div
              id="parent-status_{{ status.statusId }}"
              class="list-item"
              *ngFor="let status of sortableLeadStatusAssociations"
            >
              <tree-sortable-lead-status
                [options]="leadStatusAssociatedSortableOptions"
                [status]="status"
                (removeFromTree)="removeFromTree($event)"
              >
              </tree-sortable-lead-status>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h5>Lead Statuses</h5>
        </div>
        <div class="card-body">
          <div
            id="lead-statuses"
            *ngIf="leadStatuses"
            [sortablejs]="leadStatuses"
            [sortablejsOptions]="leadStatusesOptions"
            class="sortable-list-container"
          >
            <div
              id="{{ status.loanStatusId }}"
              class="list-group-item tree-user mb-2 move"
              *ngFor="let status of leadStatuses | sort: 'loanStatusName'"
            >
              {{ status.loanStatusName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight"
>
</admin-page-template>
