import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFile } from 'src/app/models/file.interface';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { FilePackageUpload } from '../../models/file-package-upload.model';

@Component({
  selector: 'esignature-original-docs-table',
  templateUrl: 'esignature-original-docs-table.component.html'
})

export class EsignatureOriginalDocsTableComponent implements OnInit {

  @Input()
  set documents(docs: IFile[]) {
    this._docs = docs;
    if (!this._docs) {
      this.emptyMessage = "Original documents need to be loaded..."
    }
  }

  get documents(): IFile[] {
    return this._docs;
  }

  @Output()
  viewDocumentClicked: EventEmitter<DocFile> = new EventEmitter<DocFile>();

  @Output()
  viewFilePackageClicked: EventEmitter<FilePackageUpload> = new EventEmitter<FilePackageUpload>();

  isLoadingDocument: boolean = false;

  emptyMessage = "There are no documents."

  private _docs: IFile[] = null;

  constructor() { }

  ngOnInit() { }

  onViewDocumentClicked = (file: FilePackageUpload | DocFile) => {
    if (!(<any>file).filePackageId) {
      this.viewDocumentClicked.emit(<DocFile>file);
    } else {
      this.viewFilePackageClicked.emit(<FilePackageUpload>file);
    }
  }
}
