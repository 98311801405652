import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableModule } from 'primeng/table';
import { UpsertApplicationComponent } from './components/upsert-application/upsert-application.component';
import { BorrowersService } from './services/borrowers.service';
import { SelectBorrowerComponent } from './components/select-borrower/select-borrower.component';
import { BorrowersTableComponent } from './components/borrowers-table/borrowers-table.component';
import { BorrowerApplicationsComponent } from './components/borrower-applications/borrower-applications.component';
import { BorrowerEditorComponent } from './components/borrower-editor/borrower-editor.component';
import { BorrowerRoutingModule } from './borrower.routing.module';
import { BorrowerDetailsComponent } from './components/borrower-details/borrower-details.component';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { ApplicationsModule } from '../applications/applications.module';
import { AddBorrowerComponent } from './components/add-borrower/add-borrower.component';
import { NewMortgageBorrowerDialogComponent } from './components/select-borrower/new-mortgage-borrower-dialog/new-mortgage-borrower-dialog.component';
import { BorrowerNotesComponent } from './components/borrower-notes/borrower-notes.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    BorrowerRoutingModule,
    TableModule,
    CorrespondenceModule,
    ApplicationsModule
  ],
  declarations: [
    BorrowerEditorComponent,
    UpsertApplicationComponent,
    SelectBorrowerComponent,
    AddBorrowerComponent,
    BorrowersTableComponent,
    BorrowerApplicationsComponent,
    BorrowerDetailsComponent,
    NewMortgageBorrowerDialogComponent,
    BorrowerNotesComponent
  ],
  exports: [
    BorrowersTableComponent,
    BorrowerDetailsComponent,
    SelectBorrowerComponent,
    BorrowerEditorComponent,
    BorrowerApplicationsComponent,
  ],
  providers: [BorrowersService],
  schemas: [NO_ERRORS_SCHEMA]
})
export class BorrowerModule { }
