<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm" (ngSubmit)="saveCredential()" novalidate>
  <div class="modal-header">
    <h5 class="modal-title">TWN Credential</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <credential-modal-basic [scope]="scope" [credential]="credential" [vendorSelectionDisabled]="!!credential.vendorName">
        </credential-modal-basic>
      </div>
      <div class="col-md-12 mt-1">
        <label class="custom-control-label">Url</label>
        <input type="text" class="form-control" name="url"  [(ngModel)]="credential.url">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Username</label>
        <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Password</label>
        <password-input [name]="'password'" [(ngModel)]="credential.password"></password-input>
      </div>
      <div class="col-md-12 mt-1">
        <label class="custom-control-label">Document Type</label>
        <select class="form-select" name="savedDocumentTypeId"  [(ngModel)]="keyValuePairs.savedDocumentTypeId.value">
          <option value="">-- Select One --</option>
          <option *ngFor="let item of documentTypes" value={{item.documentTypeId}}>
            {{item.documentTypeName}}
          </option>
        </select>
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">App Id</label>
        <input type="text" name="appId" class="form-control" [(ngModel)]="keyValuePairs.appId.value">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Platform</label>
        <input type="text" name="platform" class="form-control" [(ngModel)]="keyValuePairs.platform.value">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Intermediary</label>
        <input type="text" name="intermediary" class="form-control" [(ngModel)]="keyValuePairs.intermediary.value">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Reseller/Customer</label>
        <input type="text" name="resellerCustomer" class="form-control" [(ngModel)]="keyValuePairs.resellerCustomer.value">
      </div>
    </div>
  </div>
  <div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary right"
      [disabled]="isSaving"
    >
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
    </button>
  </div>
</form>
