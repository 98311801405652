import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Utils } from 'src/app/core/services/utils';
import { ContactListColumnDefinition } from 'src/app/models/contact-list.model';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { CalculatedFeeTypeEnum } from 'src/app/models/fee/calculated-fee-type.enum';
import { CreateFeeModel } from 'src/app/models/fee/create-fee.model';
import { FeeDefinitionModel } from 'src/app/models/fee/fee-definition.model';
import { FeePercentOfFieldEnum } from 'src/app/models/fee/fee-percent-of-field.enum';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ExpressionBuilderDialog } from 'src/app/modules/expression-builder/expression-builder-dialog.component';
import { ExpressionBuilderDialogService } from 'src/app/modules/expression-builder/services/expression-builder-dialog.service';
import { CreateFeeModalComponent } from 'src/app/modules/fees/modals/create-fee-modal/create-fee-modal.component';
import { Constants } from 'src/app/services/constants';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FeeDefinitionDetailsDialogComponent } from '../../../dialogs/fee-definition-details-dialog/fee-definition-details-dialog.component';
import { FeeTemplateFromLibraryDialogComponent } from '../../../dialogs/fee-template-from-library-dialog/fee-template-from-library-dialog.component';

@Component({
  selector: 'fee-definition-table',
  templateUrl: 'fee-definition-table.component.html',
  styleUrls: ['./fee-definition-table.component.scss']
})

export class FeeDefinitionTableComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() title: string;
  @Input() feeSection: FeeSectionEnum;
  @Input() isReadOnly: boolean = false;
  @Input() expressionFields: ContactListColumnDefinition[] = [];

  @Input()
  set fees(value: FeeTemplateFee[]) {
    this._fees = value;
    if(value){
      this.feeDefinitions = value || [];
      this.feeDefinitions.forEach((fee) => {
        this.initNullFeeValues(fee);
      });
    }
  }

  @Input()
  set feeTemplate(value: FeeTemplate) {
    this._feeTemplate = value;
    this.keyName = value ? "feeTemplateFeeId" : "feeDefinitionId";
  }

  get feeTemplate(): FeeTemplate {
    return this._feeTemplate;
  }

  get fees(): FeeTemplateFee[] {
    return this._fees;
  }

  @Output()
  onTemplateFeesChanged: EventEmitter<FeeTemplateFee[]> = new EventEmitter<FeeTemplateFee[]>();

  @Input()
  feeSystemDetails: FeeSystemDetails = new FeeSystemDetails();

  isDeleteClicked = {};
  feeDefinitions: FeeTemplateFee[];

  percentOfFields: EnumerationItem[] = [];

  companyId: number;

  keyName: string;

  private _fees: FeeTemplateFee[] = [];
  private _feeTemplate: FeeTemplate = null;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _feeService: FeeService,
    private readonly _expressionService: ExpressionBuilderDialogService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.applicationContextService.context.subscribe(context => {
      this.companyId = context.userPermissions.companyId;
    })

    this.getPercentOfFields();
  }

  addAdHocFeeClicked = () => {
    const modalRef = this._modalService.open(CreateFeeModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.title = this.title.split("-")[0] + '- Create a Fee';
    modalRef.componentInstance.allowFreeformFeeEntry = true;
    modalRef.componentInstance.feeSection = this.feeSection;
    modalRef.componentInstance.feeSystemDetails = this.feeSystemDetails;
    modalRef.componentInstance.calculatedFeeType = CalculatedFeeTypeEnum.Default;

    modalRef.result.then(
      (data: CreateFeeModel) => {
        this.addFee(data);
      }, () => { });
  }

  addFromLibraryClicked = () => {
    const modalRef = this._modalService.open(FeeTemplateFromLibraryDialogComponent, Constants.modalOptions.ninetyPercentOfScreenWidth);
    modalRef.componentInstance.title = this.title.split("-")[0] + '- Add Fees to Template: ' + this.feeTemplate?.name;
    modalRef.componentInstance.feeSection = this.feeSection;
    modalRef.componentInstance.fees = this.feeDefinitions;
    modalRef.componentInstance.feeTemplate = this.feeTemplate;

    modalRef.result.then((newFees: FeeTemplateFee[]) => {
      if (this.feeTemplate) {
        this.feeTemplate.fees = newFees;
        this.feeTemplate = { ...this.feeTemplate };
      }
      this.feeDefinitions = newFees.filter(f => f.feeSection === this.feeSection);
      this.isDeleteClicked = {};
    }, () => { });
  }

  removeFeeClicked = (fee: FeeTemplateFee): void => {
    if (this.feeTemplate) {
      this.feeDefinitions = this.feeDefinitions.filter(d => d.feeTemplateFeeId != fee.feeTemplateFeeId);
      this.updateFeeTemplateAfterRemove(fee);
    } else {
      this._feeService.deleteFeeDefinition(fee.feeDefinitionId).subscribe(() => {
        this.feeDefinitions = this.feeDefinitions.filter(d => d.feeDefinitionId != fee.feeDefinitionId);
      });
    }
  }

  onEditClicked = (fee: FeeTemplateFee) => {
    const modalRef = this._modalService.open(FeeDefinitionDetailsDialogComponent, Constants.modalOptions.ninetyPercentOfScreenWidth);
    modalRef.componentInstance.feeDefinition = fee;

    modalRef.result.then((result: FeeTemplateFee) => {
      this.initNullFeeValues(result);
      this.feeDefinitions = [...this.feeDefinitions.filter(f => f.feeSection === this.feeSection).map(d => {
        return d[this.keyName] == fee[this.keyName] ? result : d;
      })];

      if (this.feeTemplate) {
        this.feeTemplate.fees = this.feeTemplate.fees.filter(f => f.feeSection !== this.feeSection).concat(this.feeDefinitions);
        this.updateFeeTemplate();
      } else {

      }
    }, () => { });
  }

  onExpressionClicked = (fee: FeeTemplateFee) => {
    if (fee.expressionId) {
      this._expressionService.getExpressionGroup(fee.expressionId)
        .subscribe(group => {
          this.openExpressionBuilderDialog(fee, group);
        }, (err) => {
          this._notifyService.showError(err?.message || err.error, 'Error!');
        })
    }
    else {
      this.openExpressionBuilderDialog(fee, null);
    }
  }

  openExpressionBuilderDialog = (fee: FeeTemplateFee, expressionGroup: ExpressionGroup) => {
    const modalRef = this._modalService.open(ExpressionBuilderDialog, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.expressionBuilderData = {
      expressionGroup: expressionGroup,
      fields: this.expressionFields,
      isCustomContactList: false,
      contactListId: this.expressionFields?.length > 0 ? this.expressionFields[0].contactListId : null
    };
    modalRef.componentInstance.companyId = this.companyId;
    modalRef.componentInstance.saveExpression.subscribe((newExpressionGroup) => {
      if (!newExpressionGroup) {
        return modalRef.close();
      }

      const index = this.feeTemplate?.fees.findIndex(f => f.feeTemplateFeeId === fee.feeTemplateFeeId);
      if (this.feeTemplate && index === -1) {
        return modalRef.close();
      }

      modalRef.componentInstance.saving = true;
      this._expressionService.saveExpressionGroups(newExpressionGroup)
        .pipe(finalize(() => modalRef.componentInstance.saving = false))
        .subscribe({
          next: (res) => {
            fee.expressionId = res.expressionGroupId;

            if (this.feeTemplate) {
              this.feeTemplate.fees[index].expressionId = res.expressionGroupId;
              this.updateFeeTemplate();
            } else {
              this.updateFeeDefinition(fee);
            }

            modalRef.close();
          },
          error: (error) => {
            this._notifyService.showError(
              error?.message || 'Unable to save expression group(s) and update fee definition',
              'Fee Definition'
            );
          }
        })
    });

    modalRef.componentInstance.deleteExpression.subscribe(() => {
      const index = this.feeTemplate?.fees.findIndex(f => f.feeTemplateFeeId === fee.feeTemplateFeeId);

      if (this.feeTemplate && (index === -1 || !this.feeTemplate?.fees[index].expressionId)) {
        return modalRef.close();
      }

      let expresionId = this.feeTemplate ? this.feeTemplate.fees[index].expressionId : fee.expressionId;

      modalRef.componentInstance.saving = true;
      this._expressionService.deleteExpression(expresionId)
        .pipe(finalize(() => modalRef.componentInstance.saving = false))
        .subscribe({
          next: () => {
            if (this.feeTemplate) {
              this.feeTemplate.fees[index].expressionId = null;
              this.feeTemplate.fees = [...this.feeTemplate.fees];
              this.updateFeeTemplate();
            } else {
              fee.expressionId = null;
              this.updateFeeDefinition(fee);
            }
            modalRef.close();
          },
          error: (error) => {
            this._notifyService.showError(
              error?.message || 'Unable to save expression group(s) and update fee definition',
              'Fee Definition'
            );
          }
        })
    })
  }

  addFee = (fee: CreateFeeModel): void => {
    let newFee = new FeeDefinitionModel();
    newFee.hudNumber = fee.hudNumber;
    newFee.name = fee.feeName;
    newFee.feeSection = fee.feeSection;
    newFee.calculatedFeeType = fee.calculatedFeeType;
    newFee.calculatedValues.isChargeUserEditable = true;

    if (fee.feeType) {
      newFee.feeType = fee.feeType;
    }

    this._feeService.insertFeeDefinition(newFee)
      .subscribe({
        next: (newFee: FeeDefinitionModel) => {
          this.feeDefinitions = [...this.feeDefinitions.filter(f => f.feeSection === this.feeSection), newFee as FeeTemplateFee];
          if (this.feeTemplate) {
            this.feeTemplate.fees = this.feeTemplate.fees.filter(f => f.feeSection !== this.feeSection).concat(this.feeDefinitions);
            this.updateFeeTemplate();
          }
        },
        error: (error) => {
          this._notifyService.showError(
            error?.message || "Couldn't save fees.",
            'Fee Save Error'
          );
        }
      })
  }

  updateFeeTemplateAfterRemove = (fee: FeeTemplateFee) => {

    const index = this.feeTemplate.fees.findIndex(f => f.feeTemplateFeeId === fee.feeTemplateFeeId);
    if (index > -1) {
      this.feeTemplate.fees.splice(index, 1);
    }

    this.updateFeeTemplate();
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  getPercentOfFields = () => {
    for (let enumMember in FeePercentOfFieldEnum) {
      let enumName = this.getEnumName(FeePercentOfFieldEnum[enumMember]);
      if (enumName) {
        this.percentOfFields.push({ name: enumName, value: FeePercentOfFieldEnum[enumMember] });
      }
    }
  }

  getTotalFeePercent = (fee: FeeDefinitionModel): number => {

    let percent = ((fee.borrowerFeePercent != null ? Number(fee.borrowerFeePercent) : 0) +
      (fee.sellerFeePercent != null ? Number(fee.sellerFeePercent) : 0) +
      (fee.lenderFeePercent != null ? Number(fee.lenderFeePercent) : 0) +
      (fee.thirdPartyFeePercent != null ? Number(fee.thirdPartyFeePercent) : 0));

    return percent ? percent : null;
  }

  getTotalFeeDollar = (fee: FeeDefinitionModel): number => {

    let totalDollar = (fee.borrowerFeeDollar != null ? Number(fee.borrowerFeeDollar) : 0) +
      (fee.sellerFeeDollar != null ? Number(fee.sellerFeeDollar) : 0) +
      (fee.lenderFeeDollar != null ? Number(fee.lenderFeeDollar) : 0) +
      (fee.thirdPartyFeeDollar != null ? Number(fee.thirdPartyFeeDollar) : 0);

    return fee.feePercentOf == null || (fee.feePercentOf != null && this.percentOfFields.length === 0) ? totalDollar : null
  }

  calculatePercentAllocationsForUi = (fee: FeeDefinitionModel) => {
    let borrowerFeePercent = 0;
    let sellerFeePercent = 0;
    let lenderFeePercent = 0;
    let thirdPartyFeePercent = 0;

    let totalFeePercent = this.getTotalFeePercent(fee)

    if (totalFeePercent > 0) {
      if (fee.borrowerFeePercent != null) {
        borrowerFeePercent = (Number(fee.borrowerFeePercent) / Number(totalFeePercent)) * 100;
      }
      if (fee.sellerFeePercent != null) {
        sellerFeePercent = (Number(fee.sellerFeePercent) / Number(totalFeePercent)) * 100;
      }
      if (fee.lenderFeePercent != null) {
        lenderFeePercent = (Number(fee.lenderFeePercent) / Number(totalFeePercent)) * 100;
      }
      if (fee.thirdPartyFeePercent != null) {
        thirdPartyFeePercent = (Number(fee.thirdPartyFeePercent) / Number(totalFeePercent)) * 100;
      }
    } else {
      if (fee.defaultPaidBy === "Borrower") {
        borrowerFeePercent = 100;
      } else if (fee.defaultPaidBy === "Seller") {
        sellerFeePercent = 100;
      } else if (fee.defaultPaidBy === "Lender") {
        lenderFeePercent = 100;
      } else {
        thirdPartyFeePercent = 100;
      }
    }

    return {
      borrowerFeePercent: borrowerFeePercent,
      sellerFeePercent: sellerFeePercent,
      lenderFeePercent: lenderFeePercent,
      thirdPartyFeePercent: thirdPartyFeePercent
    }
  }

  private initNullFeeValues = (fee: FeeDefinitionModel): void => {
    if (fee.calculatedValues.totalFeePercent === null) {
      fee.calculatedValues.totalFeePercent = 0;
    }
    if (fee.calculatedValues.totalFee === null) {
      fee.calculatedValues.totalFee = 0;
    }
    if (fee.paidOutsideClosing === null) {
      fee.paidOutsideClosing = 0;
    }
    if (fee.calculatedValues.sellerConcessions === null) {
      fee.calculatedValues.sellerConcessions = 0;
    }
    if (fee.calculatedValues.sellerTotal === null) {
      fee.calculatedValues.sellerTotal = 0;
    }
    if (fee.calculatedValues.borrowerTotal === null) {
      fee.calculatedValues.borrowerTotal = 0;
    }
    if (fee.calculatedValues.monthlyFee === null) {
      fee.calculatedValues.monthlyFee = 0;
    }

  }

  private updateFeeTemplate = () => {
    this._spinner.show();
    this._feeService.updateFeeTemplate(this.feeTemplate.feeTemplateId, this.feeTemplate)
      .subscribe({
        next: (result) => {
          this.feeTemplate = { ...result };
          this.feeTemplate.fees = [...result.fees];
          this._notifyService.showSuccess("Fee Template updated Successfully", "Success");
          this.onTemplateFeesChanged.emit(this.feeTemplate.fees || []);
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldn't update fee template", "Error!");
        }
      }).add(() => this._spinner.hide())
  }

  private updateFeeDefinition = (fee: FeeDefinitionModel) => {
    this._spinner.show();
    this._feeService.updateFeeDefinition(fee.feeDefinitionId, fee)
      .subscribe({
        next: (result) => {
          const index = this.feeDefinitions.findIndex(f => f.feeDefinitionId === result.feeDefinitionId);
          if (index > -1) {
            this.feeDefinitions[index] = result as FeeTemplateFee; // its definition
          }
          this.feeDefinitions = [...this.feeDefinitions];
          this._notifyService.showSuccess("Fee updated Successfully", "Success");
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldn't update fee", "Error!");
        }
      }).add(() => this._spinner.hide())
  }
}
