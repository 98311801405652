import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'ernst-provider-editor-dialog',
  templateUrl: 'ernst-provider-editor-dialog.component.html',
  styleUrls: ['./ernst-provider-editor-dialog.component.scss']
})

export class ErnstProviderEditorDialogComponent implements OnInit {

  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  inEditMode: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notifyService: NotificationService,
  ) { }

  ngOnInit() {
  }

  saveCredential = () => {
    this._spinner.show();
    this._systemLevelService.saveCredential(this.vendor).subscribe({
      next: (result) => {
        this._notifyService.showSuccess("Credentials saved successfully.", "Success");
        this.activeModal.close(result);
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't save credentials.", "Error!");
      }
    }).add(() => this._spinner.hide());
  }
}