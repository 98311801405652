import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { LosEvent, LosTransactionHistory } from 'src/app/models/los/los-info.model';
import { LosEventDirection, LosEventOperation, LosEventSource, LosEventStatus } from 'src/app/models';
import { clone } from 'lodash';
import { NotificationService } from 'src/app/services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'los-transaction-history',
  templateUrl: 'los-transaction-history.component.html',
  styleUrls: ['./los-transaction-history.component.scss']
})
export class LosTransactionHistoryComponent implements OnInit {
  @Input()
  transactionHistory: LosTransactionHistory;

  loadingHistory: boolean = false;

  applicationTimeStr: string = '';
  losLoanTimeStr: string = '';
  stateKey = 'los-lde-transaction-history-table-state';

  filteredTransactionHistoryEvents: LosEvent[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public _datePipe: DatePipe,
    private _notifyService: NotificationService,
    private readonly _clipboard: Clipboard
  ) {

  }

  ngOnInit(): void {
    if (this.transactionHistory.applicationTime) {
      this.applicationTimeStr = this._datePipe.transform(this.transactionHistory.applicationTime, 'MMM d, y h:mm:ss a');
    }
    if (this.transactionHistory.losLoanTime) {
      this.losLoanTimeStr = this._datePipe.transform(this.transactionHistory.losLoanTime, 'MMM d, y h:mm:ss a');
    }

    this.filteredTransactionHistoryEvents = clone(this.transactionHistory.events);

    this.filteredTransactionHistoryEvents.forEach((event) => {
      event['dateUpdatedAsDate'] = new Date(event.dateUpdated ?? event.dateInserted);
    })

    this.filteredTransactionHistoryEvents = [...this.filteredTransactionHistoryEvents];
  }

  onFilterChanged({
    eventSourceFilter,
    directionFilter,
    operationFilter,
    statusFilter,
  }: {
    eventSourceFilter: LosEventSource
    directionFilter: LosEventDirection
    operationFilter: LosEventOperation
    statusFilter: LosEventStatus
  }) {
    this.filteredTransactionHistoryEvents = this.transactionHistory.events.filter(event => 
      (!eventSourceFilter || eventSourceFilter === event.eventSource) &&
      (!directionFilter || directionFilter === event.eventDirection) &&
      (!operationFilter || operationFilter === event.eventOperation) &&
      (!statusFilter || statusFilter === event.status)
    )
  }

  onCopyRequestTraceId(reqTraceId: string) {
    this._clipboard.copy(reqTraceId);
    this._notifyService.showInfo('Request trace ID copied to clipboard.', 'Copied!');
  }
}
