<form class="form-horizontal" id="serviceForm" name="serviceForm" #serviceForm="ngForm" (ngSubmit)="saveService()" novalidate>
    <div class="modal-header">
        <h5 class="modal-title">Global Email</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <label class="custom-control-label">Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    [ngClass]="{'is-invalid': name && name.touched && name.invalid}"
                    #name="ngModel"
                    placeholder="Name"
                    [(ngModel)]="service.name"
                    required>
                <div class="invalid-feedback">Name is required.</div>
            </div>
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Server Type</label>
                <select
                    class="form-control"
                    id="serviceType"
                    name="serviceType"
                    [ngClass]="{'is-invalid': serviceType && serviceType.touched && serviceType.invalid}"
                    #serviceType="ngModel"
                    [(ngModel)]="service.serviceType"
                    required
                >
                    <option value="">-- Select One --</option>
                    <option value="Smtp">SMTP</option>
                    <option value="ExchangeWebServices">Exchange</option>
                    <option value="SendGrid">Lodasoft</option>
                    <option value="ExchangeMicrosoftGraph" *ngIf="tenantKvp">Exchange (Azure/Modern Authentication)</option>
                </select>
                <div class="invalid-feedback">Server type is required.</div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'SendGrid'">
                <label class="custom-control-label">Auth Key</label>
                <input
                    type="text"
                    class="form-control"
                    name="apiKey"
                    [ngClass]="{'is-invalid': apiKey && apiKey.touched && apiKey.invalid}"
                    #apiKey="ngModel"
                    placeholder="Auth key (provided by lodasoft)"
                    [(ngModel)]="service.apiKey"
                    required
                >
                <div class="invalid-feedback">Auth key is required.</div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'SendGrid'">
                <label class="custom-control-label">Ip Address Pool Name</label>
                <input
                    type="text"
                    class="form-control"
                    name="ipPoolName"
                    [ngClass]="{'is-invalid': ipPoolName && ipPoolName.touched && ipPoolName.invalid}"
                    #ipPoolName="ngModel"
                    placeholder="Ip Address Pool Name (provided by lodasoft)"
                    [(ngModel)]="service.ipPoolName"
                    required
                >
                <div class="invalid-feedback">Ip address pool name is required.</div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType !== 'SendGrid' && service.serviceType !== 'ExchangeMicrosoftGraph'">
                <label class="custom-control-label">Username</label>
                <input type="text" class="form-control" name="userName" [(ngModel)]="service.userName">
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType !== 'SendGrid' && service.serviceType !== 'ExchangeMicrosoftGraph'">
                <label class="custom-control-label">Password</label>
                <password-input [name]="'password'" [(ngModel)]="service.password"></password-input>
            </div>
            <div class="col-md-12 mt-3" *ngIf="service.serviceType !== 'SendGrid' && service.serviceType !== 'ExchangeMicrosoftGraph'">
                <div class="form-group mb-0">
                    <div class="form-check form-check-inline">
                        <input id="isSecure" name="isSecure" class="form-check-input" type="checkbox"
                               [(ngModel)]="service.isSecure" ToggleValue [true]="true" [false]="false">
                        <label class="form-check-label" for="isSecure">HTTPS</label>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'Smtp'">
                <label class="custom-control-label">Server</label>
                <input type="text" class="form-control" name="server" placeholder="Server name/ip" [(ngModel)]="service.server">
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'Smtp'">
                <label class="custom-control-label">Port</label>
                <input type="text" class="form-control" name="port" placeholder="Port Number" [(ngModel)]="service.port">
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType == 'ExchangeWebServices'">
                <label class="custom-control-label">Service Url</label>
                <input type="text" class="form-control" name="servicesUrl" placeholder="Exchange web services url" [(ngModel)]="service.servicesUrl">
            </div>

            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'ExchangeMicrosoftGraph'">
                <label class="custom-control-label">Email Sender</label>
                <select class="form-select"
                    *ngIf="emailServiceUserNameToDisplay"
                    name="companyWideDefaultEmailSender"
                    (change)="handleUserChange(service.userName)"
                    [(ngModel)]="service.userName">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let item of users" value={{item.email.toLowerCase()}}>
                        {{item.displayName}}
                    </option>
                </select>
                <select class="form-select"
                    *ngIf="!emailServiceUserNameToDisplay"
                    name="companyWideDefaultEmailSender"
                    (change)="handleUserChange(service.userName)"
                    [(ngModel)]="service.userName">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let item of users" value={{item.email.toLowerCase()}}>
                        {{item.displayName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button
            type="button"
            class="btn btn-secondary right"
            (click)="activeModal.dismiss()"
        >
            Cancel
        </button>
        <button
            type="submit"
            class="btn btn-primary right"
        >
            <span *ngIf="saving" class="spinner-border spinner-border-sm"></span>
            <span *ngIf="!saving">Save</span>
        </button>
    </div>

</form>
