<div class="account-body accountbg">
    <div class="container">
        <div class="row vh-100 d-flex justify-content-center">
            <div class="col-10 align-self-center">
                <div class="row">
                    <div class="col-lg-5 mx-auto">
                        <div class="card">
                            <div class="card-body p-0">
                                <div class="text-center p-3">
                                    <h4 class="mt-3" style="color: steelblue;">Reset Your Password</h4>
                                    <p class="text-muted  mb-0">Please enter your new password below, and confirm new password.</p>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="form-group alert alert-danger alert-block" *ngIf="error">
                                    <h4 class="alert-heading mt-0">Failed!</h4>
                                    {{error}}
                                </div>
                                <form
                                    [formGroup]="resetPasswordForm"
                                    class="form-horizontal auth-form"
                                    novalidate
                                    id="resetPasswordForm"
                                    name="resetPasswordForm"
                                    autocomplete="off">
                                    <div class="form-group mb-2">
                                        <label class="form-label" for="username">Email</label>
                                        <div class="input-group">
                                            <input
                                                type="email"
                                                class="form-control"
                                                name="email"
                                                formControlName="email"
                                                autocomplete="username"
                                                required
                                                email
                                                trim
                                                [(ngModel)]="formData.email"
                                                [ngClass]="{'is-invalid': email && email.touched && email.invalid}"
                                                (change)="onEmailChangedDebounce()">
                                            <div *ngIf="email.errors?.['required']" class="invalid-feedback">Email is required.</div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="formData.email">
                                        <div class="form-group mb-2">
                                            <label class="form-label" for="userCompanyGuid">Company</label>
                                            <select class="form-select" name="userCompanyGuid" id="userCompanyGuid" formControlName="userCompanyGuid" [(ngModel)]="formData.userCompanyGuid">
                                                <option [ngValue]="null">--Select One--</option>
                                                <ng-container *ngFor="let companies of availableCompanies | groupBy: 'companyGuid'">
                                                    <optgroup label="{{companies.value[0].companyName}}">
                                                        <option *ngFor="let company of companies.value; let i = index" value="{{company.userCompanyGuid}}">
                                                            {{company.userType}}
                                                        </option>
                                                    </optgroup>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="formData.userCompanyGuid">
                                        <div class="form-group mb-2">
                                            <label class="form-label" for="password">Password</label>
                                            <div class="input-group">
                                                <input
                                                    type="password"
                                                    class="form-control"
                                                    name="password"
                                                    formControlName="password"
                                                    autocomplete="new-password"
                                                    requiresDigit
                                                    requiresNonAplhaNumeric
                                                    required
                                                    minlength="8"
                                                    [caseSize]="'both'"
                                                    [(ngModel)]="formData.password"
                                                    [ngClass]="{'is-invalid' : password && password.touched && password.invalid}">
                                                <div *ngIf="password.errors?.['required']" class="invalid-feedback">Password is required.</div>
                                                <div *ngIf="password.errors?.['minlength']" class="invalid-feedback">Password must be at least 8 characters long.<br/></div>
                                                <div *ngIf="password.errors?.['uppercase']" class="invalid-feedback">Password must contain an uppercase character.<br/></div>
                                                <div *ngIf="password.errors?.['lowercase']" class="invalid-feedback">Password must contain a lowercase character.<br/></div>
                                                <div *ngIf="password.errors?.['requiresDigit']" class="invalid-feedback">Password must contain at least one digit.<br/></div>
                                                <div *ngIf="password.errors?.['requiresNonAplhaNumeric']" class="invalid-feedback">Password must contain non-alphanumeric character.<br/></div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-2">
                                            <label class="form-label" for="confirmPassword">Confirm Password</label>
                                            <div class="input-group">
                                                <input
                                                    type="password"
                                                    class="form-control"
                                                    name="confirmPassword"
                                                    formControlName="confirmPassword"
                                                    autocomplete="new-password"
                                                    required
                                                    [(ngModel)]="formData.confirmPassword"
                                                    [ngClass]="{'is-invalid' : confirmPassword && (confirmPassword.touched && confirmPassword.invalid) || resetPasswordForm.hasError('stringDoesntMatch')}">
                                                <div *ngIf="confirmPassword.errors?.['required']" class="invalid-feedback">Confirm password is required.</div>
                                                <div *ngIf="resetPasswordForm.hasError('stringDoesntMatch')" class="invalid-feedback">Confirm password doesn't match password.<br/></div>
                                            </div>
                                        </div>
    
                                        <div class="form-group mb-0 row">
                                            <div class="col-12 mt-2">
                                                <button class="btn btn-primary w-100 waves-effect waves-light" type="button" (click)="resetPassword()">
                                                    <span *ngIf="resettingPassword" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Reset Password
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="form-group mt-2 mb-0 alert alert-success alert-block" *ngIf="resetDone">
                                        <h4 class="alert-heading mt-0">Success!</h4>
                                        Password changed successfully!
                                        <br />
                                        <a [routerLink]="['/login']">Click here to login</a>
                                    </div>
                                </form>
                                <p class="text-muted text-center mb-0 mt-3">Already have an account?
                                    <a [routerLink]="['/login']" class="text-primary ms-2">Log in</a>
                                </p>
                                <p class="text-muted text-center mb-0">Don't have an account?
                                    <a href="https://www.lodasoft.com/#contact-us" class="text-primary ms-2">Request a Demo</a>
                                </p>
                                <!--end form-->
                            </div>
                            <div class="card-body bg-light-alt text-center login-footer">
                                <span class="text-muted d-none d-sm-inline-block">Lodasoft © {{currentYear}}
                                    <script>
                                        document.write(new Date().getFullYear())
                                    </script>
                                </span>
                            </div>
                        </div>
                        <!--end card-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</div>
