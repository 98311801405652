<div class="row">
  <div class="col-md-12" *ngIf="scope === 'Company'">
    <div class="mt-1 d-flex align-content-end flex-wrap">
      <div *ngIf="isDesktopUnderwriterEnabledForCompany" class="me-3">
        <toggle-configuration [title]="'Enable DU'" [id]="'desktopUnderwriterConfig'"
          [configuration]="desktopUnderwriterConfig" (onChange)="doDuSelectionChanged('DU')"></toggle-configuration>
      </div>

      <div *ngIf="isDesktopOriginatorEnabledForCompany" class="me-3">
        <toggle-configuration [title]="'Enable DO'" [id]="'desktopOriginatorConfig'"
          [configuration]="desktopOriginatorConfig" (onChange)="doDuSelectionChanged('FannieMae')"></toggle-configuration>
      </div>

      <div *ngIf="isLpaEnabledForCompany" class="me-3">
        <toggle-configuration [title]="'Enable LPA'" [id]="'lpaConfig'"
          [configuration]="lpaConfig"></toggle-configuration>
      </div>
    </div>
    <div class="col-md-12 my-1">
      <hr />
      <div class="row mt-3">
          <div class="col-md-2">
              <label class="custom-control-label">Document Type</label>
          </div>
          <div class="col-md-4">
              <select class="form-select" [(ngModel)]="selectedDocumentTypeId" (change)="onDocTypeChanged(selectedDocumentTypeId)">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let item of documentTypes" [ngValue]="item.documentTypeId">
                      {{item.documentTypeName}}
                  </option>
              </select>
          </div>
      </div>
      <hr />
  </div>
  </div>
</div>

<hr class="col-md-12" *ngIf="scope === 'Company'">

<div class="row">
  <div class="col-md-12">
    <div class="fieldset-title">
      <div class="row">
        Desktop Underwriter (DU)
      </div>
    </div>
  </div>

  <div class="col-md-12 d-flex align-content-end flex-wrap"
    *ngIf="isDesktopUnderwriterEnabledForCompany && desktopUnderwriterConfig.value == 1">
    <div class="mt-4 me-2" *ngFor="let credential of credentialsForDu">
      <credential-item [credential]="credential" [credentials]="credentialsForDu"
        (openCredentialModal)="openCredentialModal($event, 'DU')">
      </credential-item>
    </div>
    <div class="mt-4 me-2" *ngIf="showAddDUCredsButton">
      <create-credential [credentialType]="'AusVendor'" [credentials]="credentialsForDu"
        (openCredentialModal)="openCredentialModal($event, 'DU')"></create-credential>
    </div>
  </div>

  <div class="col-md-12" *ngIf="isDesktopUnderwriterEnabledForCompany && desktopUnderwriterConfig.value == 0">
    <enable-message *ngIf="scope !== 'Branch'">
      Enable this feature above!
    </enable-message>
    <enable-message *ngIf="scope === 'Branch'">
      Enable this feature under <a [routerLink]="['/admin/system-level/integrations']" fragment="aus">system level settings</a>
    </enable-message>
  </div>
  <div class="col-md-12" *ngIf="!isDesktopUnderwriterEnabledForCompany">
    <enable-message>Call to enable this feature!</enable-message>
  </div>
</div>

<div class="row" *ngIf="scope != 'TpoUser'">
  <div class="col-md-12">
    <div class="fieldset-title">
      <div class="row mt-4">
        <div class="col-md-8 mt-3 p-0">
          Desktop Originator (DO)
        </div>
        <div class="col-md-4 text-end" *ngIf="!isTpo">
          <button class="btn btn-outline-primary" (click)="openRolesFinalizeDoFindingsModal()">
            Roles To Finalize DO Findings
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 d-flex align-content-end flex-wrap"
    *ngIf="isDesktopOriginatorEnabledForCompany && desktopOriginatorConfig.value == 1">
    <div class="mt-4 me-2" *ngFor="let credential of credentialsForDo">
      <credential-item [credential]="credential" [credentials]="credentialsForDo"
        (openCredentialModal)="openCredentialModal($event, 'FannieMae')">
      </credential-item>
    </div>
    <div class="mt-4 me-2" *ngIf="showAddDOCredsButton">
      <create-credential [credentialType]="'AUSOriginator'" [credentials]="credentialsForDu"
        (openCredentialModal)="openCredentialModal($event, 'FannieMae')"></create-credential>
    </div>
  </div>

  <div class="col-md-12" *ngIf="isDesktopOriginatorEnabledForCompany && desktopOriginatorConfig.value == 0">
    <enable-message *ngIf="scope !== 'Branch'">
      Enable this feature above!
    </enable-message>
    <enable-message *ngIf="scope === 'Branch'">
      Enable this feature under <a [routerLink]="['/admin/system-level/integrations']" fragment="aus">system level settings</a>
    </enable-message>
  </div>
  <div class="col-md-12" *ngIf="!isDesktopOriginatorEnabledForCompany">
    <enable-message>Call to enable this feature!</enable-message>
  </div>
</div>

<div class="row mt-4" *ngIf="scope != 'TpoUser'">
  <div class="col-md-12">
    <div class="fieldset-title mt-3">
      <div class="row">
        Loan Product Advisor (LPA)
      </div>
    </div>
  </div>

  <div class="col-md-12 d-flex align-content-end flex-wrap" *ngIf="isLpaEnabledForCompany && lpaConfig.value == 1">
    <div class="mt-4 me-2" *ngFor="let credential of credentialsForLpa">
      <credential-item [credential]="credential" [credentials]="credentialsForLpa"
        (openCredentialModal)="openCredentialModal($event, 'LPA')">
      </credential-item>
    </div>
    <div class="mt-4 me-2" *ngIf="showAddLPACredsButton">
      <create-credential [credentialType]="'AusVendor'" [credentials]="credentialsForLpa"
        (openCredentialModal)="openCredentialModal($event , 'LPA')"></create-credential>
    </div>
  </div>

  <div class="col-md-12" *ngIf="isLpaEnabledForCompany && lpaConfig.value == 0">
    <enable-message *ngIf="scope !== 'Branch'">
      Enable this feature above!
    </enable-message>
    <enable-message *ngIf="scope === 'Branch'">
      Enable this feature under <a [routerLink]="['/admin/system-level/integrations']" fragment="aus">system level settings</a>
    </enable-message>
  </div>
  <div class="col-md-12" *ngIf="!isLpaEnabledForCompany">
    <enable-message>Call to enable this feature!</enable-message>
  </div>
</div>
