import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { remove } from 'lodash';
import { DocFile } from 'src/app/models';
import { AppraisalDocument } from 'src/app/models/appraisal/appraisal-document.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { AddAttachmentDialogComponent, LoanDocsByCategory } from 'src/app/modules/correspondence/components/add-attachment-dialog/add-attachment-dialog.component';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'appraisal-docs',
  templateUrl: './appraisal-docs.component.html',
  styleUrls: ['./appraisal-docs.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class AppraisalDocsComponent implements OnInit {
  @Input() appId: number;
  @Input() loanDocsByCategory: Array<LoanDocsByCategory>
  @Input() appraisalDocTypes: Array<EnumerationItem>
  @Input() selectedDocuments: Array<AppraisalDocument>
  @Input() isReadonly: boolean = false;
  @Output() updateDocuments = new EventEmitter();

  loadingDocFileGuid: string;
  constructor(private readonly _modalService: NgbModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    public datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  openLoanDocDialog() {
    const modalRef = this._modalService.open(AddAttachmentDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.loanDocsByCategory = this.loanDocsByCategory;
    modalRef.result.then((selectedFiles: Array<DocFile>) => {
      this.selectedDocuments = [];
      selectedFiles.forEach(doc => {
        this.selectedDocuments.push({
          docFileGuid: doc.guid,
          fileName: doc.fileName,
          docFileId: doc.docFileId,
          mimeType: doc.mimeType,
          isPdf: doc.fileName.toLowerCase().endsWith(".pdf"),
          creationDate: this.datePipe.transform(doc.dateInserted, 'short'),
          appraisalDocumentType: this.findExistingAppraisalDocumentType(doc.guid)
        });
      });
      this.updateDocuments.emit(this.selectedDocuments);
    }, () => { });
  }

  private findExistingAppraisalDocumentType(fileGuid: string): string {
    const matchingDoc = this.selectedDocuments.find(doc => doc.docFileGuid == fileGuid);
    return matchingDoc ? matchingDoc.appraisalDocumentType : '';
  }

  viewDocument(file: AppraisalDocument): void {
    this.loadingDocFileGuid = file.docFileGuid;
    this._loanDocsService.viewLoanDocContent(file.docFileGuid).subscribe({
      next: data => {
        const blob = new Blob([data], { type: data['type'] });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (error) => {
        this._notifyService.showError(error ? error.message || error : "Couldn't view appraisal document.", "Error!");
      }
    }).add(() => this.loadingDocFileGuid = null);
  }

  removeDoc(docFileGuid: string) {
    remove(this.selectedDocuments, (doc: AppraisalDocument) => doc.docFileGuid === docFileGuid);
    this.updateDocuments.emit(this.selectedDocuments);
    const matchingFile = this.loanDocsByCategory
      .flatMap(loanDoc => loanDoc.documents)
      .flatMap(doc => doc.docFiles)
      .find(file => file.guid === docFileGuid)
    if (!matchingFile) {
      return;
    }
    matchingFile.isSelectedToAttach = false;
  }
}
