<div class="card tpo-report-card" *ngIf="application">
  <div class="card-header">
    <h4 class="card-title"> <i class="fas fa-file-medical-alt"></i> Loan Details </h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{loanType ? loanType : '---'}}</h5>
        <span class="text-secondary">Loan Type</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{loanPurpose ? loanPurpose : '---'}}</h5>
        <span class="text-secondary">Loan Purpose</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12" *ngIf="isPurchase">
        <h5 class="mt-0 mb-1">{{application.purchasePrice ? (application.purchasePrice | currency) : '---'}}</h5>
        <span class="text-secondary">Purchase Price</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12" *ngIf="!isPurchase">
        <h5 class="mt-0 mb-1">{{refinancePurpose ? refinancePurpose : '---'}}</h5>
        <span class="text-secondary">Refinance Purpose</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{application.presentValue ? (application.presentValue | currency) : '---'}}</h5>
        <span class="text-secondary">Appraised Value</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{application.loanAmount ? (application.loanAmount | currency) : '---'}}</h5>
        <span class="text-secondary">Loan Amount</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6 col-sm-6 col-xs-12" *ngIf="!isPurchase"></div>
      <div class="form-group col-md-4 col-sm-6 col-xs-12" *ngIf="isPurchase"></div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12" *ngIf="isPurchase">
        <h5 class="mt-0 mb-1">{{downPayment ? (downPayment | currency) : '---'}}</h5>
        <span class="text-secondary">Down Payment</span>
      </div>

      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{application.mipAmount ? (application.mipAmount | currency) : '---'}}</h5>
        <span class="text-secondary">MIP/PMI Amount</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{totalLoanAmount ? (totalLoanAmount | currency) : '---'}}</h5>
        <span class="text-secondary">Total Loan Amount</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{application.subordinateLienAmount ? (application.subordinateLienAmount | currency) :
          '---'}}</h5>
        <span class="text-secondary">Subordinate Lien Amount</span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-4 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{product ? product : '---'}}</h5>
        <span class="text-secondary">Product</span>
      </div>
      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{application.productPricing.term ? application.productPricing.term : '---' }}</h5>
        <span class="text-secondary">Term</span>
      </div>

      <div class="form-group col-md-2 col-sm-6 col-xs-12">
        <h5 class="mt-0 mb-1">{{application.productPricing.price ? application.productPricing.price : '---'}}</h5>
        <span class="text-secondary">Price</span>
      </div>

      <div class="form-group col-md-2 col-sm-6 col-xs-12" *ngIf="!(isPRMG && ['Correspondent','NonDelegatedCorrespondent'].includes(application?.channel))">
        <h5 class="mt-0 mb-1">{{application.productPricing.compType ? application.productPricing.compType : '---'}}</h5>
        <span class="text-secondary">Comp Type</span>
      </div>
    </div>
  </div>
</div>
