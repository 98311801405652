<div *ngIf="fee">
  <div class="row">
    <label class="col-sm-4 col-form-label" for="isPercentage">Fee % Of</label>
    <div class="col-sm-8">
      <select id="feePercentOf" name="feePercentOf" class="form-select" placeholder="feePercentOf"
        [(ngModel)]="fee.feePercentOf">
        <option [ngValue]="null">--Select One--</option>
        <option *ngFor="let feePercentOf of feePercentOfOptions" [ngValue]="feePercentOf.value">
          {{ feePercentOf.name }}
        </option>
      </select>
    </div>
  </div>
  <!-- <div
    class="row mt-1" *ngIf="fee.feePercentOf">
    <label class="col-sm-4 col-form-label" for="totalFeePercent">Total Fee Percent</label>
    <div class="col-sm-8">
    <percent-input
      [digitsInfo]="'1.6-6'"
      id="totalFeePercent"
      name="totalFeePercent"
      [ngModel]="fee.totalFeePercent"
      (ngModelChange)="totalFeePercent = $event; onTotalFeePercentChanged()"
    ></percent-input></div>
  </div> -->
  <div class="row mt-1">
    <label class="col-sm-4 col-form-label" for="borrower"
      >Borrower {{fee.feePercentOf ? '%' : '$'}}</label
    >
    <div class="col-sm-8">
      <currency-input *ngIf="!fee.feePercentOf"
        [name]="'borrower'"
        [(ngModel)]="fee.borrowerFeeDollar">
      </currency-input>
      <percent-input *ngIf="fee.feePercentOf"
        [name]="'borrower'"
        [(ngModel)]="fee.borrowerFeePercent">
      </percent-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-4 col-form-label" for="seller"
      >Seller {{fee.feePercentOf ? '%' : '$'}}</label
    >
    <div class="col-sm-8">
      <currency-input *ngIf="!fee.feePercentOf"
        [name]="'seller'"
        [(ngModel)]="fee.sellerFeeDollar">
      </currency-input>
      <percent-input *ngIf="fee.feePercentOf"
        [name]="'seller'"
        [(ngModel)]="fee.sellerFeePercent">
      </percent-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-4 col-form-label" for="lender"
      >Lender {{fee.feePercentOf ? '%' : '$'}}</label
    >
    <div class="col-sm-8">
      <currency-input *ngIf="!fee.feePercentOf"
        [name]="'lender'"
        [(ngModel)]="fee.lenderFeeDollar">
      </currency-input>
      <percent-input *ngIf="fee.feePercentOf"
        [name]="'lender'"
        [(ngModel)]="fee.lenderFeePercent">
      </percent-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-4 col-form-label" for="thirdParty"
      >Third Party {{fee.feePercentOf ? '%' : '$'}}</label
    >
    <div class="col-sm-8">
      <currency-input *ngIf="!fee.feePercentOf"
        [name]="'thirdParty'"
        [(ngModel)]="fee.thirdPartyFeeDollar">
      </currency-input>
      <percent-input *ngIf="fee.feePercentOf"
        [name]="'thirdParty'"
        [(ngModel)]="fee.thirdPartyFeePercent">
      </percent-input>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <button type="button" class="btn btn-sm btn-outline-secondary me-1" (click)="onCancelClicked()"> Cancel
    </button>
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="onApplyClicked()"> Apply
    </button>
  </div>
</div>
