import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-duplicate-borrower-email-dialog',
  templateUrl: './duplicate-borrower-email-dialog.component.html',
  styleUrls: ['./duplicate-borrower-email-dialog.component.scss']
})
export class DuplicateBorrowerEmailDialogComponent {

  @Input() dupBorrowers = [];
  @Input() dupCoBorrowers = [];

  selections = {
    selectedBorrowerId: 0,
    selectedCoBorrowerId: 0
  };

  constructor(public activeModal: NgbActiveModal) { }
}
