import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, forkJoin } from 'rxjs';
import { MessageDeliveryEnum } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DayOfWeek } from 'src/app/modules/admin/lead-config/models/lead-route-day-schedule.model';
import { NotificationService } from 'src/app/services/notification.service';
import { MessageAllowedSendRange, TimeZoneType } from '../../models/message-allowed-send-range.model';
import { CorrespondenceService } from '../../services/correspondence.service';

@Component({
  selector: 'correspondence-weekly-schedule',
  templateUrl: './correspondence-weekly-schedule.component.html',
  styleUrls: ['./correspondence-weekly-schedule.component.scss']
})
export class CorrespondenceWeeklyScheduleComponent implements OnInit {
  @ViewChild('weeklyScheduleForm')
  weeklyScheduleForm: NgForm | undefined;

  @Input() delivery: MessageDeliveryEnum;
  @Input() dayRanges: MessageAllowedSendRange[];

  @Output() rangesChanged = new EventEmitter<MessageAllowedSendRange[]>();

  daysOfWeek: DayOfWeek[] = [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday
  ];

  selectedTimezoneType: TimeZoneType = TimeZoneType.local;
  timezoneTypes: EnumerationItem[] = [
    new EnumerationItem("Local Timezone", TimeZoneType.local),
    new EnumerationItem("Unknown Timezone", TimeZoneType.unknown),
  ];
  isSaving: boolean;

  constructor(
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    this.getAllMessageAllowedSendRanges();
  }

  getRangeByDay = (day: DayOfWeek) => {
    let matched = this.dayRanges?.find(r => r.dayOfWeek == day);
    if (matched) {
      return matched;
    }
    else {
      let allowedRange = new MessageAllowedSendRange();
      allowedRange.delivery = this.delivery;
      allowedRange.dayOfWeek = day;
      return allowedRange;
    }
  }

  setToDefaults = () => {
    this._spinnerService.show();
    this._correspondenceService.insertAllowedSendRangeForDeliveryDefaults(this.selectedTimezoneType, this.delivery)
      .subscribe({
        next: (ranges) => {
          this.getAllMessageAllowedSendRanges();
          this.rangesChanged.emit(ranges);
        },
        error: (err) => {
          this._spinnerService.hide();
          this._notificationService.showError(err.message || err, "Error!");
        }
      })
  }

  onRangesChanged = (changesRange: MessageAllowedSendRange) => {
    const matchingRangeIndex = this.dayRanges.findIndex(r => r.dayOfWeek === changesRange.dayOfWeek);
    if (matchingRangeIndex === -1) {
      this.dayRanges.push(changesRange);
    }
    else {
      this.dayRanges[matchingRangeIndex] = changesRange;
    }

    this.rangesChanged.emit(this.dayRanges);
  }

  onTypezoneChanged = () => {
    this.getAllMessageAllowedSendRanges();
  }

  save = (): void => {
    if (!this.weeklyScheduleForm) {
      return;
    }

    this.weeklyScheduleForm.form.markAllAsTouched();
    if (!this.weeklyScheduleForm.form.valid) {
      return;
    }

    let obs = [];
    this.isSaving = true;
    this.dayRanges.forEach(r => {
      if (r.messageAllowedSendRangeId) {
        if (!this.isRangeEmpty(r)) {
          r.scheduleType = this.selectedTimezoneType;
          obs.push(this._correspondenceService.updateAllowedSendRange(r))
        }
        else {
          obs.push(this._correspondenceService.deleteAllowedSendRange(r.messageAllowedSendRangeId));
        }
      }
      else {
        if (!this.isRangeEmpty(r)) {
          r.scheduleType = this.selectedTimezoneType;
          obs.push(this._correspondenceService.insertAllowedSendRange(r));
        }
      }
    });

    forkJoin(obs)
      .pipe(finalize((() => this.isSaving = false)))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`${this.delivery} schedule saved successfully.`, `${this.delivery} Schedule`);
          this.getAllMessageAllowedSendRanges();
        },
        error: err => {
          this._notificationService.showError(err?.message || 'Unable to save.', `${this.delivery} Schedule`);
        }
      });
  }

  private isRangeEmpty = (range: MessageAllowedSendRange) => {
    return range.startHour == null && range.endHour == null && !range.enabledAllDay;
  }

  private getAllMessageAllowedSendRanges = () => {
    this._spinnerService.show();
    this._correspondenceService.getAllowedSendRanges(this.selectedTimezoneType, this.delivery)
      .subscribe({
        next: (ranges) => {
          this.dayRanges = ranges || [];
        },
        error: (err) => {
          this._notificationService.showError(err.message || err, "Error!");
        }
      }).add(() => this._spinnerService.hide())
  }
}
