import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { CallHistory } from 'src/app/models/voice/call-history.model';
import { NotificationService } from 'src/app/services/notification.service';
import { VoiceService } from 'src/app/services/voice.service';
import { DateRangeFilterComponent, Filters } from 'src/app/shared/components/date-range-filter/date-range-filter.component';
import { FilterOption } from 'src/app/shared/components/generic-filter/generic-filter.component';
import { LoanActivityService } from '../../loan-activity/services/loan-activity.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription, interval, tap } from 'rxjs';

@Component({
  selector: 'dial-call-history',
  templateUrl: './dial-call-history.component.html',
  styleUrls: ['./dial-call-history.component.scss']
})
export class DialCallHistoryComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output()
  manualDialClicked: EventEmitter<string> = new EventEmitter();

  callHistory: CallHistory[] = [];
  filter: Filters = new Filters();
  isDetailViewActive: boolean = false;
  timeGroupedCallHistory: Map<string, CallHistory[]> = new Map();
  selectedCallHistory: CallHistory = null;
  isLoading: boolean = false;

  userId: string;

  protected callDates: string[] = [];
  protected hasListScroll: boolean;

  private _listScrollSubscription?: Subscription;
  private _contextSubscription: Subscription;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _voiceService: VoiceService,
    private readonly _loanActivityService: LoanActivityService,
    private readonly _contextService: ApplicationContextService
  ) {
    this._contextSubscription = this._contextService.context.subscribe(ctx => {
      this.userId = ctx.userPermissions.userId;
    });
  }

  ngOnInit(): void {
    this.filter.dateRange = DateRangeFilterComponent.getSelectedRangeByDuration('LastMonth');
    this.getCallHistory();
  }

  ngAfterViewInit(): void {
    this.subscribeToListScroll();
    this._contextSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this._listScrollSubscription?.unsubscribe();
  }

  private subscribeToListScroll() {
    this._listScrollSubscription?.unsubscribe();
    this._listScrollSubscription = interval(200).pipe(
      tap(_ => {
        const callDatesListElem = document.getElementById('call-dates-list');
        if (!callDatesListElem) return;

        const height = callDatesListElem.clientHeight;

        const childHeights = Array.from(
          callDatesListElem.getElementsByClassName('summary-row'),
          (e) => e.clientHeight,
        ).reduce((acc, h) => acc + h, 0);

        this.hasListScroll = height < childHeights;
      }),
    ).subscribe();
  }

  goToDetail(history: CallHistory) {
    this.selectedCallHistory = history;
    this.isDetailViewActive = true;
  }

  goToList() {
    this.isDetailViewActive = false;
    this.selectedCallHistory = null;
  }

  private getCallHistory = () => {
    this.isLoading = true;
    this._voiceService.getCallHistory({
      startDate: this.filter.dateRange.startDate,
      endDate: this.filter.dateRange.endDate,
      userId: this.userId
    }).subscribe({
      next: (response) => {
        this.callHistory = response.map(vh => ({
          ...vh,
          durationText: this.getDurationText(vh.durationInSeconds),
          groupDate: this.getFormattedMonthYear(vh.dateInserted),
          fromWithoutCountryCode: this.removeCountryCodeFromTel(vh.from),
          toWithoutCountryCode: this.removeCountryCodeFromTel(vh.to)
        }));

        const groups = _.chain(this.callHistory)
          .sortBy("dateInserted")
          .reverse()
          .groupBy("groupDate")
          .value();

        this.callDates = Object.keys(groups);

        this.callDates.forEach(date => {
          this.timeGroupedCallHistory.set(date, groups[date]);
        })
      },
      error: (err) => {
        this._notifyService.showError(err?.message || 'Unable to get call history.', 'Error!');
      }
    }).add(() => this.isLoading = false);
  }

  onRangesFilterChanged = (selectedRangeOption: FilterOption) => {
    this.filter.dateRange = selectedRangeOption.dateRange;
    this.filter = { ...this.filter };
    this.getCallHistory()
  }

  private getDurationText(durationInSeconds: number) {
    if (!durationInSeconds) {
      return '00:00';
    }

    durationInSeconds = Math.round(durationInSeconds); // intValue

    const minutes = Math.floor(durationInSeconds / 60);
    const remainingSeconds = Math.round((durationInSeconds % 60)*10)/10;
    return `${Utils.pad(minutes)}:` + (remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds);
  }

  private getFormattedMonthYear(date: string): string {
    const dateFormated = date.split("T")[0];
    const dateParts = dateFormated.split("-"); // year-month-day

    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]);
    const day = Number(dateParts[2]);

    const dateObj = new Date(year, month - 1, day);
    const monthStr = dateObj.toLocaleString('default', { month: 'long' });

    return monthStr + ", " + year;
  }

  private removeCountryCodeFromTel(tel: string): string {
    return tel ? tel.slice(2) : undefined;
  }

  search(text: string) {
    if (!text) {
      const groups = _.chain(this.callHistory)
        .sortBy("dateInserted")
        .reverse()
        .groupBy("groupDate")
        .value();

      this.callDates = Object.keys(groups);

      this.timeGroupedCallHistory.clear();
      this.callDates.forEach(date => {
        this.timeGroupedCallHistory.set(date, groups[date]);
      })

    }
    else {
      let filterResult = Utils.filter(["groupDate", "direction", "fromWithoutCountryCode", "toWithoutCountryCode", "startDate", "endDate", "durationText", "disposition"], text, this.callHistory);

      const groups = _.chain(filterResult)
        .sortBy("dateInserted")
        .reverse()
        .groupBy("groupDate")
        .value();

      this.callDates = Object.keys(groups);

      this.timeGroupedCallHistory.clear();
      this.callDates.forEach(date => {
        this.timeGroupedCallHistory.set(date, groups[date]);
      })
    }
  }

  openCallPanel(history: CallHistory) {
    const numberToCall = this.removeCountryCodeFromTel(history.to);
    this.manualDialClicked.emit(numberToCall);
  }

  downloadRecordingDetails(participantId: number) {
    this._spinner.show();
    this._loanActivityService.getRecording(participantId).subscribe({
      next: (response) => {
        this._spinner.hide();
        this._loanActivityService.downloadURI(response.mp3RecordingUri);
      }, error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message, 'Error');
      }
    });
  }

  loadRecordingDetails(participantId: number) {
    this._spinner.show();
    this._loanActivityService.getRecording(participantId).subscribe({
      next: (response) => {
        this._spinner.hide();
        window.open(response.mp3RecordingUri, '_blank');
      }, error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message, 'Error');
      }
    });
  }

}
