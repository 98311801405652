import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LoanPassPricerComponent } from "./loanpass-pricer.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    LoanPassPricerComponent
  ],
  exports: [
    LoanPassPricerComponent
  ]
})
export class LoanPassPricerModule {
  constructor() {
  }
}


