import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Subscription } from 'rxjs';
import { NewApplicationService } from 'src/app/modules/new-application/services/new-application.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';

@Component({
  selector: 'create-escalation-dialog',
  templateUrl: './create-escalation-dialog.component.html',
  styleUrls: ['./create-escalation-dialog.component.scss']
})
export class CreateEscalationDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("escalationForm")
  escalationForm: NgForm;

  loanId: number;

  description: string;

  notes: string;

  uploadedFiles: Array<File> = [];

  autoConvertToPdf: boolean = false;

  useDynamicCompression: boolean = true;

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _taskService: TaskService,
    private readonly _newApplicationService: NewApplicationService,
    private readonly _notifService: NotificationService,
    private readonly _spinner: NgxSpinnerService) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.loanId = context.application?.applicationId;
      });
    } else {
      this.loanId =
        this.applicationContext.application.applicationId;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onOkClicked = () => {
    if (this.escalationForm) {
      this.escalationForm.form.markAllAsTouched();
      if (this.escalationForm.form.valid) {
        this._spinner.show();
        this._taskService.createEscalation(this.loanId, this.description, this.notes).subscribe((responseTask) => {
          const observables = [];
          if (this.uploadedFiles && this.uploadedFiles.length > 0) {
            this.uploadedFiles.forEach(file => {
              observables.push(this._newApplicationService.uploadFileForTask(file, responseTask.loanDocTaskId, false, this.autoConvertToPdf,
                this.useDynamicCompression));
            });

            forkJoin(observables).subscribe((result) => {
              let errorCount = 0;
              result.forEach(fileUploadResult => {
                if (!fileUploadResult) {
                  errorCount++;
                }
              });
              if (errorCount > 0) {
                console.log("Detected " + errorCount + " error(s).");
              }
              this._spinner.hide();
              this._notifService.showSuccess("Escalation created successfully.", "Success!");
            }, (err) => {
              this._spinner.hide();
              this._notifService.showError('Error: ' + this.getErrorMessage(err), 'File Upload Error');
            });
          }
          this._spinner.hide();
          this._notifService.showSuccess("Escalation created successfully.", "Success!");
        }, (err) => {
          this._spinner.hide();
          this._notifService.showError('Error: ' + this.getErrorMessage(err), 'Escalation creation failed');
        });
        this.activeModal.close();
      }
    }
  }

  onCancelClicked = () => {
    this.activeModal.close('cancel');
  }

  private getErrorMessage = (error: any): string => {
    if (error) {
      if (error.error) {
        if (error.error.message) {
          return error.error.message;
        }
        return error.error;
      } else if (error.message) {
        return error.message;
      }
      return error;
    }
    return "There was an error - please contact your Administrator."
  }
}
