import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { BasicProfileComponent } from './components/profile-page/basic-profile/basic-profile.component';
import { ContactComponent } from './components/profile-page/contact/contact.component';
import { ProfileEmailSettingsComponent } from './components/profile-page/email-settings/profile-email-settings.component';
import { EmailTemplatesComponent } from './components/profile-page/email-templates/email-templates.component';
import { SmsTemplatesComponent } from './components/profile-page/sms-templates/sms-templates.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ApplicationSettingsComponent } from './components/profile-page/application-settings/application-settings.component';
import { VoiceMailTemplatesComponent } from './components/profile-page/voice-mail-templates/voice-mail-templates.component';
import { CustomerRetentionComponent } from './components/profile-page/customer-retention/customer-retention.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SystemLevelModule } from '../system-level/system-level.module';
import { TableModule } from 'primeng/table';
import { NgWizardModule } from 'ng-wizard';
import { UpsertVoicemailComponent } from './components/profile-page/voice-mail-templates/upsert-voicemail/upsert-voicemail.component';
import { StateLicensingComponent } from './components/profile-page/state-licensing/state-licensing.component';
import { UpsertStateLicensingComponent } from './components/profile-page/state-licensing/upsert-state-licensing/upsert-state-licensing.component';
import { UserLeadRoutingComponent } from './components/profile-page/user-lead-routing/user-lead-routing.component';
import { RoutingTabComponent } from './components/profile-page/user-lead-routing/routing-tab/routing-tab.component';
import { RedistributionTabComponent } from './components/profile-page/user-lead-routing/redistribution-tab/redistribution-tab.component';
import { FetchTabComponent } from './components/profile-page/user-lead-routing/fetch-tab/fetch-tab.component';
import { WarmTransferTabComponent } from './components/profile-page/user-lead-routing/warm-transfer-tab/warm-transfer-tab.component';
import { TreeModule } from 'primeng/tree';
import { RoutingTreeviewComponent } from './components/profile-page/user-lead-routing/routing-treeview/routing-treeview.component';
import { SetupProfileComponent } from './components/setup-profile/setup-profile.component';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { RegistrationModule } from '../registration/registration.module';
import { SupportComponent } from "./components/profile-page/support/support.component";
import { GoalModule } from '../admin/goal/goal.module';
import { UserGoalModule } from './modules/user-goal/user-goal.module';
import { DialerConfigService } from '../admin/dialer-config/services/dialer-config.service';
import { LeadRoutingService } from '../admin/lead-config/services/lead-routing.service';
import { PrequalLetterSettingsModule } from '../prequal-letter-settings/prequal-letter-settings.module';
import { ProfileSmsSettingsComponent } from './components/profile-page/profile-sms-settings/profile-sms-settings.component';
import { PurchasePhoneNumberWizardComponent } from './components/profile-page/profile-sms-settings/setup-phone-number/purchase-phone-number-wizard.component';
import { PurchasePhoneNumberComponent } from './components/profile-page/profile-sms-settings/setup-phone-number/purchase-phone-number/purchase-phone-number.component';
import { SetFriendlyNameComponent } from './components/profile-page/profile-sms-settings/setup-phone-number/set-friendly-name/set-friendly-name.component';
import { CallForwardingComponent } from './components/profile-page/profile-sms-settings/setup-phone-number/call-forwarding/call-forwarding.component';
import { VerifyCallerIdComponent } from './components/profile-page/profile-sms-settings/setup-phone-number/verify-caller-id/verify-caller-id.component';

@NgModule({
  declarations: [
    ProfilePageComponent,
    BasicProfileComponent,
    ContactComponent,
    ProfileSmsSettingsComponent,
    ProfileEmailSettingsComponent,
    EmailTemplatesComponent,
    SmsTemplatesComponent,
    ApplicationSettingsComponent,
    VoiceMailTemplatesComponent,
    CustomerRetentionComponent,
    UpsertVoicemailComponent,
    PurchasePhoneNumberWizardComponent,
    PurchasePhoneNumberComponent,
    SetFriendlyNameComponent,
    CallForwardingComponent,
    VerifyCallerIdComponent,
    StateLicensingComponent,
    UpsertStateLicensingComponent,
    UserLeadRoutingComponent,
    RoutingTabComponent,
    RedistributionTabComponent,
    FetchTabComponent,
    WarmTransferTabComponent,
    RoutingTreeviewComponent,
    SetupProfileComponent,
    SupportComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    ImageCropperModule,
    SystemLevelModule,
    TableModule,
    TreeModule,
    NgWizardModule,
    RegistrationModule,
    GoalModule,
    UserGoalModule,
    PrequalLetterSettingsModule
  ],
  exports: [
    ContactComponent,
    ProfileEmailSettingsComponent,
    ApplicationSettingsComponent,
    PurchasePhoneNumberWizardComponent,
  ],
  providers: [
    ThirdPartyCredentialsService, DialerConfigService, LeadRoutingService
  ]
})
export class ProfileModule { }
