import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { PipelineItem } from 'src/app/models';
import { AddApplicationComponent } from 'src/app/modules/contacts/components/add-application/add-application.component';
import { Constants } from 'src/app/services/constants';
import { RowHeight } from 'src/app/shared/models/table-config.model';
import { NotificationService } from '../../../../services/notification.service';
import { ApplicationTableConfig } from '../../../applications/application-table/application-table-config.model';
import { ContactsService } from '../../../contacts/services/contacts.service';

@Component({
  selector: 'borrower-applications',
  templateUrl: './borrower-applications.component.html',
  styleUrls: ['./borrower-applications.component.scss'],
})
export class BorrowerApplicationsComponent implements OnInit {
  /**
   * If not supplied, {@link ContactsService.getAllApplicationInfo} is used with
   * {@link borrowerId} to get applications.
   */
  @Input() allApplications?: PipelineItem[];
  @Input() borrowerId: number;

  protected applications: PipelineItem[] | undefined;
  protected applicationTableConfig: ApplicationTableConfig;
  protected error: any | undefined;
  protected isLoading: boolean = this.allApplications == null;

  private get applications$(): Promise<PipelineItem[]> {
    const { allApplications } = this;

    return allApplications
      ? Promise.resolve([...allApplications])
      : firstValueFrom(
        this._contactsService.getAllApplicationInfo(this.borrowerId),
      );
  }

  constructor(
    private readonly _contactsService: ContactsService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.applicationTableConfig = {
      columns: [
        { field: 'applicationId', header: 'ID / Purpose', order: 1, visible: true },
        { field: 'lastName', header: 'Borrower / Property', order: 2, visible: true },
        { field: 'loanStatusName', header: 'Loan Status', order: 3, visible: true },
        { field: 'mobilePhone', header: 'Contact Info', order: 4, visible: true },
        { field: 'loanAmount', header: 'Loan Amount', order: 5, visible: true },
        { field: 'interestRate', header: 'Interest Rate', order: 6, visible: true },
        { field: 'primaryRoleContact', header: 'Loan Contact', order: 7, visible: true },
        { field: 'appCreateDate', header: 'Date Created', order: 8, visible: true },
        { field: 'refNumber', header: 'Ref#', order: 9, visible: false },
      ],
      extraGlobalFilterFields: ['firstName', 'email', 'homePhone', 'mailingStreet', 'mailingCity', 'mailingState', 'mailingZip', 'applicationIdWithPadding'],
      isAddNewButtonVisible: true,
      isShowArchiveButtonVisible: false,
      isAddToDialListButtonVisible: false,
      rowHeight: RowHeight['80px'],
      scrollable: true,
    };

    await this.runWithLoading(async () => {
      this.applications = await this.tryGetApplications();
    });
  }

  private async runWithLoading(fn: () => Promise<void>): Promise<void> {
    this.isLoading = true;

    try {
      await fn();
    } finally {
      this.isLoading = false;
    }
  }

  private handleLoadApplicationsError(e: any) {
    const error = {
      ...e,
      message: e.message ?? 'Unable to get applications',
    };
    this.error = error;

    this._notificationService.showError(
      error.message,
      'Applications',
    );
    console.error(error);
  }

  private async tryGetApplications(): Promise<PipelineItem[] | undefined> {
    try {
      this.error = undefined;

      const applications = await this.applications$;
      return applications.map(this.setBorrowerName) as PipelineItem[];
    } catch (e) {
      this.handleLoadApplicationsError(e);
    }
  }

  /**
   * Returns a copy of {@link application} with `borrowerName` property added.
   *
   * FIXME: This function was migrated during merging with
   *  ContactApplicationsComponent. Should examine whether it is necessary.
   * @param application
   */
  private setBorrowerName = (application: PipelineItem): PipelineItem => {
    const { firstName, lastName } = application;
    return {
      ...application,
      borrowerName: [firstName, lastName].join(', '),
    } as PipelineItem;
  };

  private addNewApplication = async (application: PipelineItem): Promise<void> => {
    return this.runWithLoading(async () => {
      this.allApplications = null;
      const applications = await this.tryGetApplications();
      this.applications = applications;
    });
  };

  onAddNewApplicationModalOpened(): void {
    const modalRef = this._modalService.open(AddApplicationComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.id = this.borrowerId;

    modalRef.result.then((result: any) => {
      this.addNewApplication(result);
    }, () => {
    });
  }
}
