export class Lender {
    active: boolean;
    address: string;
    address2: string;
    aeEmail: string;
    aeFax: string;
    aeMobile: string;
    aeName: string;
    aePhone: string;
    city: string;
    companyId: number;
    dateInserted: Date;
    dateUpdated: Date;
    externalCompanyId: number;
    fhaLenderId: number;
    insertedBy: string;
    lenderId: number;
    pricingVendor: string;
    pricingVendorInvestorId: string;
    mortgageeClause: string;
    name: string;
    payoffEmail: string;
    payoffEmailInstructions: string;
    payoffFax: string;
    payoffFaxInstructions: string;
    payoffNotes: string;
    payoffPhone: string;
    payoffPhoneInstructions: string;
    phone: string;
    sortOrder: number;
    state: string;
    titleClause: string;
    updatedBy: string;
    vaLenderId: number;
    zip: string;
    website: string;
}
