<div class="modal-header">
    <h5 class="modal-title">
        Send Test Email
    </h5>

    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
    <form id="sendTestEmail" #sendTestEmail="ngForm" name="sendTestEmail">
        <div class="mb-3">
            <label class="form-label" for="promptText">Email Address</label>
            <input type="email" class="form-control" id="email" name="email" trim email
                [ngClass]="{'is-invalid' : userEmail && userEmail.touched && userEmail.invalid}" required
                [(ngModel)]="emailAddress" #userEmail="ngModel" placeholder="Email Address">
            <div class="invalid-feedback">Please enter a valid email address.</div>
        </div>
    </form>
</div>

<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
        <i class="fa fa-close"></i> Cancel
    </button>

    <button [disabled]="isLoading" type="button" class="btn btn-primary" (click)="sendEmail()">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="!isLoading">Send Email</span>
    </button>
</div>