import { Pipe } from "@angular/core";
import { Utils } from "src/app/core/services/utils";

@Pipe({
  name: "padLeft"
})
export class PadLeftPipe {

  transform(number: number, padChar: string, length: number) {
    return Utils.padLeft(number + '', padChar, length);
  }
}
