<div class="modal-header">
    <h5 class="modal-title text-center">Reassign Branch?</h5>
</div>
<div class="modal-body">
    <div class="row m-3 text-center">
        <span>
            Would you like to reassign this application to the branch the loan officer is assigned to?
        </span>
    </div>
</div>
<div class="modal-footer form-footer text-center">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('false')">No</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close('true')">Yes</button>
</div>