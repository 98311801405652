<loading-indicator *ngIf="isLoading" [loadingMessage]="'Loading Change of Circumstance Entry...'"></loading-indicator>

<div *ngIf="!isLoading">
  <form #cocForm="ngForm">
    <div *ngIf="summary.length === 0">
      <div class="row">
        <div class="col-md-4">
          <label class="form-label" for="dateOfDiscovery">Date of Discovery</label>
          <input type="date" class="form-control" id="dateOfDiscovery" name="dateOfDiscovery"
                 (ngModelChange)="dateOfDiscovery = $event" [ngModel]="dateOfDiscovery | date:'y-MM-dd'"
                 [ngClass]="{'is-invalid' : dateOfDiscoveryInput && dateOfDiscoveryInput.touched && dateOfDiscoveryInput.invalid}"
                 #dateOfDiscoveryInput="ngModel" required>
        </div>
        <div class="col-md-8">
          <label class="form-label" for="requestedReason">Requested Reason</label>
          <select class="form-select" id="requestedReason" name="requestedReason" [(ngModel)]="requestedReason"
                  [ngClass]="{'is-invalid' : requestedReasonSelect && requestedReasonSelect.touched && requestedReasonSelect.invalid}"
                  #requestedReasonSelect="ngModel" required>
            <option value="">---Select Reason---</option>
            <option *ngFor="let reason of reasonForChanges" value={{reason}}>
              {{reason}}
            </option>
          </select>
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="col-md-3" *ngFor="let entry of entries; let i = index;">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="entryCheck_{{i}}" name="entryCheck_{{i}}"
                   [checked]="entry.isSelected" (change)="entry.isSelected = !entry.isSelected">
            <label class="form-check-label" for="entryCheck_{{i}}">
              {{entry.name}}
            </label>
          </div>
        </div>
      </div>
      <br><br>
      <div class="card" *ngIf="checkedEntryExists">
        <table class="table mb-0 table-centered">
          <thead>
          <tr>
            <th class="w-75">Change Type</th>
            <th>Requested Value</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let entry of entries; let i = index;">
            <tr *ngIf="entry.isSelected">
              <td class="w-75" [ngStyle]="{borderBottom: entry.info || entry.warning ? 'none' : ''}">
                {{entry.name}}
              </td>
              <td [ngStyle]="{borderBottom: entry.info || entry.warning ? 'none' : ''}">
                <input type="text" id="textInput_{{i}}" name="textInput_{{i}}" class="form-control"
                       [(ngModel)]="entry.newValue" *ngIf="entry.type === cocEntryTypeEnum.Text"
                       [ngClass]="{'is-invalid' : textInput && textInput.touched && textInput.invalid}"
                       #textInput="ngModel" required/>
                <input type="number" id="numberInput_{{i}}" name="numberInput_{{i}}"  class="form-control"
                       [(ngModel)]="entry.newValue" *ngIf="entry.type === cocEntryTypeEnum.Number"
                       [ngClass]="{'is-invalid' : numberInput && numberInput.touched && numberInput.invalid}"
                       #numberInput="ngModel" required/>
                <select class="form-select" id="selectInput_{{i}}" name="selectInput_{{i}}" *ngIf="entry.type === cocEntryTypeEnum.Select"
                        [ngClass]="{'is-invalid' : selectInput && selectInput.touched && selectInput.invalid}" [(ngModel)]="entry.newValue"
                        #selectInput="ngModel" required>
                  <option *ngFor="let option of entry.options" value={{option.name}}>
                    {{option.name}}
                  </option>
                </select>
                <currency-input id="currencyInput_{{i}}" name="currencyInput_{{i}}" [(ngModel)]="entry.newValue"
                                *ngIf="entry.type === cocEntryTypeEnum.Currency" [required]="true" [allowNegative]="false"
                                [ngClass]="{'is-invalid' : currencyInput && currencyInput.touched && currencyInput.invalid}"
                                #currencyInput="ngModel"></currency-input>
                <percent-input id="percentInput_{{i}}" name="percentInput_{{i}}" *ngIf="entry.type === cocEntryTypeEnum.Percent"
                               [(ngModel)]="entry.newValue" [required]="true"
                               [ngClass]="{'is-invalid' : percentInput && percentInput.touched && percentInput.invalid}"
                               #percentInput="ngModel"></percent-input>
              </td>
            </tr>
            <tr *ngIf="entry.warning" [hidden]="!entry.isSelected">
              <td colspan="2" [ngStyle]="{borderBottom: entry.info ? 'none' : ''}">
                <div class="alert alert-warning border-0" role="alert">
                  {{entry.warning}}
                </div>
              </td>
            </tr>
            <tr *ngIf="entry.info" [hidden]="!entry.isSelected">
              <td colspan="2">
                <div class="alert alert-info border-0" role="alert">
                  {{entry.info}}
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<div class="text-end" *ngIf="summary.length === 0 && !isLoading">
  <button class="btn btn-secondary" (click)="onClearClicked()" style="margin-right: 10px;">
    Clear Current Changes
  </button>
  <button class="btn btn-primary" (click)="onNextClicked()" [disabled]="!checkedEntryExists">
    Next <i class="fa fa-chevron-right"></i>
  </button>
</div>

<div class="row" *ngIf="summary.length > 0 && !isLoading">
  <table class="table mb-0 table-centered">
    <thead>
    <tr>
      <th>Date of Discovery</th>
      <th>Request Type</th>
      <th>Requested Change</th>
      <th>Reason for Change</th>
      <th>Requested Value</th>
      <th>Date Added</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of summary;">
      <td>
        {{item.dateOfDiscovery | date:'shortDate'}}
      </td>
      <td>
        {{item.requestType}}
      </td>
      <td>
        {{item.requestedChange}}
      </td>
      <td>
        {{item.reasonForChange}}
      </td>
      <td>
        {{item.requestedValue}}
      </td>
      <td>
        {{item.dateAdded | date:'short'}}
      </td>
    </tr>
    </tbody>
  </table>
  <div>
    <br><br>
    <button class="btn btn-secondary" (click)="addAdditionalChanges()" style="margin-right: 10px;">
      Add Additional Changes
    </button>
    <button class="btn btn-secondary" (click)="onClearClicked()">
      Start Over
    </button>
    <button class="btn btn-primary" (click)="submitChangesForApproval()" style="float: right;">
      Submit Changes for Approval
    </button>
  </div>
</div>
