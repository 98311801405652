import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvailableFreeForAllLead, AvailableWithModalRef } from 'src/app/models/available-free-for-all-lead';
import { ConferenceParticipant } from 'src/app/modules/dialer/models/conference-participant.model';
import { DialList } from 'src/app/modules/dialer/models/dial-list.model';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';
import { DialerEvent, DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { LeadRouteEventType } from 'src/app/modules/leads/models/lead-event.model';
import { Constants } from 'src/app/services/constants';
import { LeadRouteService } from 'src/app/services/lead-route.service';
import { AcceptAndDialFreeLeadsDialogComponent } from './dialogs/accept-and-dial-free-leads-dialog/accept-and-dial-free-leads-dialog.component';
import { NewLeadAssignedDialogComponent } from './dialogs/new-lead-assigned-dialog/new-lead-assigned-dialog.component';
import { WarmTransferLeadAvailableDialogComponent } from './dialogs/warm-transfer-lead-available-dialog/warm-transfer-lead-available-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { audit, Subscription } from 'rxjs';
import { ContactListService } from 'src/app/services/contact-list.service';
import { ContactList } from 'src/app/models';

@Component({
  selector: 'lead-route-leads',
  template: ''
})
export class LeadRouteLeadsComponent implements OnInit, OnDestroy {

  areLeadsLoaded: boolean = false;
  dialLists: DialList[] = [];
  availableLeadsForWarmTransfer: AvailableWithModalRef[] = [];
  availableInboundLeads: AvailableWithModalRef[] = [];
  availableShotgunLeads: AvailableWithModalRef[] = [];
  warmTransferAudio = new Audio('assets/sound/WarmTransferAlert.mp3');
  inboundAudio = new Audio('assets/sound/inbound-ringing.mp3');
  shotgunAudio = new Audio('assets/sound/ShotgunAlert.mp3');
  currentDialListId: number;
  participants: ConferenceParticipant[] = [];

  private _dialerEventSubscription: Subscription;
  private _leadRouteEventSubscription: Subscription;
  private _closeNewLeadDialogEventSubscription: Subscription;

  constructor(
    private readonly _leadRouteService: LeadRouteService,
    private readonly _contactListService: ContactListService,
    private readonly _dialerService: DialerService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
  ) {
    this._dialerEventSubscription = this._dialerService.events.subscribe((event: DialerEvent) => {
      if (!event) return;

      switch (event.eventType) {
        case DialerEventType.changeSelectedDialList:
          this.listener_changeSelectedDialList(event.data);
          break;
        case DialerEventType.changeParticipants:
          this.listener_changeParticipants(event.data);
          break;
      }
    })

    this._leadRouteEventSubscription = this._leadRouteService.events.subscribe(event => {
      if (!event) return;

      switch (event.eventType) {
        case LeadRouteEventType.NewAssignedLeadPosted:
          this.showNewAssignLead(event.data);
          break;
        case LeadRouteEventType.FreeForAllLeadAvailable:
          this.showAvailableLead(event.data);
          break;
        case LeadRouteEventType.WarmTransferLeadAvailable:
          if (event.data.leadRouteHistoryId) {
            this.showWarmTransferLeadAvailable(event.data);
          }
          break;
        case LeadRouteEventType.FreeForAllLeadTaken:
        case LeadRouteEventType.CancelWarmTransferLeadAvailable:
          this.removeFromList(event.data.leadRouteHistoryId);
          break;
      }
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this._dialerEventSubscription) {
      this._dialerEventSubscription.unsubscribe();
      if (this._leadRouteEventSubscription) {
        this._leadRouteEventSubscription.unsubscribe();
      }
    }
    this._closeNewLeadDialogEventSubscription?.unsubscribe();
  }

  listener_changeSelectedDialList = (dialListId: number) => {
    this.currentDialListId = dialListId;
  }

  listener_changeParticipants = (participants: ConferenceParticipant[]) => {
    this.participants = participants;
    this.setWarmTransferAudioLoop();
  }

  showAvailableLead = (model: AvailableFreeForAllLead) => {
    //inboundcall
    if (!model.contactListId) {
      try {
        this.inboundAudio.play();
      }
      catch (err) { }

      const modalRef = this._modalService.open(AcceptAndDialFreeLeadsDialogComponent, Constants.modalOptions.medium);
      this.availableInboundLeads.push({ leadInfo: model, modalRef: modalRef });
      modalRef.componentInstance.model = model;
      modalRef.componentInstance.contactListName = "";

      modalRef.result.then((result: any) => {
        this.removeFromList(model.leadRouteHistoryId);
        this._dialerService.publish({
          eventType: DialerEventType.ReloadActiveConferenceForInbound,
          data: {}
        });
      }, () => { this.removeFromList(model.leadRouteHistoryId); });
      return;
    }

    //lead route ffa
    this._contactListService.getContactListById(model.contactListId).subscribe({
      next: (contactList) => {
        try {
          this.shotgunAudio.play();
        }
        catch (err) { }
        const modalRef = this._modalService.open(AcceptAndDialFreeLeadsDialogComponent, Constants.modalOptions.medium);
        this.availableShotgunLeads.push({ leadInfo: model, modalRef: modalRef });
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.contactListName = contactList.name;

        modalRef.result.then((result: any) => {
          this.removeFromList(model.leadRouteHistoryId);
        }, () => { this.removeFromList(model.leadRouteHistoryId); });
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load dial lists.", "Error");
      }
    });
  }


  removeFromList = (leadRouteHistoryId: number) => {
    console.log("trying to remove alert from list", leadRouteHistoryId);

    let warmTransferIndexFound = this.availableLeadsForWarmTransfer.findIndex(ele => ele.leadInfo.leadRouteHistoryId === leadRouteHistoryId);
    if (warmTransferIndexFound > -1) {
      try {
        this.warmTransferAudio.pause();
      }
      catch (err) { }
      if (this.availableLeadsForWarmTransfer[warmTransferIndexFound].modalRef) {
        console.log("removing warm transfer alert from list", leadRouteHistoryId);
        try {
          this.availableLeadsForWarmTransfer[warmTransferIndexFound].modalRef.dismiss();
        }
        catch (err) { }
      }
      this.availableLeadsForWarmTransfer.splice(warmTransferIndexFound, 1);
    }

    let sIndexFound = this.availableShotgunLeads.findIndex(ele => ele.leadInfo.leadRouteHistoryId === leadRouteHistoryId);
    if (sIndexFound > -1) {
      try {
        this.shotgunAudio.pause();
      } catch (err) { }
      if (this.availableShotgunLeads[sIndexFound].modalRef && !this.availableShotgunLeads[sIndexFound].modalRef.componentInstance.disabledDialog) {
        console.log("removing inbound transfer alert from list", leadRouteHistoryId);
        try {
          this.availableShotgunLeads[sIndexFound].modalRef.dismiss();
        }
        catch (err) { }
      }
      this.availableShotgunLeads.splice(sIndexFound, 1);
    }

    let indexFound = this.availableInboundLeads.findIndex(ele => ele.leadInfo.leadRouteHistoryId === leadRouteHistoryId);
    if (indexFound > -1) {
      try {
        this.inboundAudio.pause();
      } catch (err) { }
      if (this.availableInboundLeads[indexFound].modalRef && !this.availableInboundLeads[indexFound].modalRef.componentInstance.disabledDialog) {
        console.log("removing inbound transfer alert from list", leadRouteHistoryId);
        try {
          this.availableInboundLeads[indexFound].modalRef.dismiss();
        }
        catch (err) { }
      }
      this.availableInboundLeads.splice(indexFound, 1);
    }
  }

  showWarmTransferLeadAvailable = (model) => {
    let modalRef = this._modalService.open(WarmTransferLeadAvailableDialogComponent, Constants.modalOptions.medium);

    this.availableLeadsForWarmTransfer.push({ leadInfo: model, modalRef: modalRef });

    modalRef.componentInstance.model = model;

    modalRef.result.then((result: any) => {
      this.removeFromList(model.leadRouteHistoryId);
    }, () => {
      this.removeFromList(model.leadRouteHistoryId);
    });

    this.setWarmTransferAudioLoop();

    this.warmTransferAudio.play();
  }

  showNewAssignLead = (leadModel) => {
    this._dialerService.getDialLists()
      .subscribe(dialLists => {
        this.dialLists = dialLists.filter(l => l.adminManaged === false);

        let modalRef = this._modalService.open(NewLeadAssignedDialogComponent, Constants.modalOptions.medium);
        modalRef.componentInstance.leadModel = leadModel;
        this._closeNewLeadDialogEventSubscription = modalRef.componentInstance.closeDialog.subscribe(() => {
          modalRef.close();
        })

        modalRef.result.then((result: any) => {
        }, () => {
        });
      });
  }

  private setWarmTransferAudioLoop = () => {
    if (this.participants.length > 0) {
      this.warmTransferAudio.loop = false;
    } else {
      this.warmTransferAudio.loop = true;
    }
  }
}
