import { ExpressionDataType, ExpressionFieldType } from "./expression.model";

export class CustomField {
    customFieldId: number;
    name: string;
    dataType: ExpressionDataType;
    fieldType: ExpressionFieldType;
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;

    fieldPath?: any; // todo: api does not inlude this
}
