import { Component, Injector, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { User } from 'src/app/models/user/user.model';
import { NgWizardConfig, NgWizardService, THEME } from 'ng-wizard';
import { Utils } from 'src/app/core/services/utils';
import { finalize, concatMap, concatWith, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApplicationContextBoundComponent, IncomingActionsComponent, TelephonyItem } from '../../../../../../shared/components';
import { DrawerService } from '../../../../../../shared/services/drawer.service';
import { Profile } from '../../../../../../models';
import { TelephonyService } from 'src/app/modules/voice-sms-settings/modules/telephony-service.model';
import { StoredFileService } from 'src/app/services/stored-file.service';
import { StoredFileModel } from 'src/app/models/stored-file.model';
import { VoiceSmsSettingsService } from 'src/app/modules/voice-sms-settings/services/voice-sms-settings.service';
import {TwilioPhoneNumber} from './twilio-phone-number';

@Component({
  selector: 'purchase-phone-number-wizard',
  templateUrl: './purchase-phone-number-wizard.component.html',
})
export class PurchasePhoneNumberWizardComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output() updateTelephonyList: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() userProfile: Profile;

  @ViewChild("incomingActions")
  incomingActionsEditor: IncomingActionsComponent;

  phoneNumber: TwilioPhoneNumber;
  messagingService: string;
  friendlyName: string;
  isSaving: boolean;
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: false,
    },
  };

  users: User[] = [];

  protected telephony: TelephonyService = null;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly injector: Injector,
    private readonly _notificationService: NotificationService,
    private readonly _ngWizardService: NgWizardService,
    private readonly _drawerService: DrawerService,
    private readonly _storedFileService: StoredFileService,
    private readonly _voiceSmsSettingsService: VoiceSmsSettingsService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.users = this.applicationContext.globalConfig.usersAll;
  }

  showPreviousStep() {
    this._ngWizardService.previous();
    this.config.selected--;
  }

  showNextStep() {
    this._ngWizardService.next();
    this.config.selected++;
  }

  phoneNumberSelected(phoneNumber: TwilioPhoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  messagingServiceSelected(messagingService: string) {
    this.messagingService = messagingService;
  }

  friendlyNameSet(friendlyName: string) {
    this.friendlyName = friendlyName;
  }

  submit() {
    switch (this.config.selected) {
      case 0:
        this.showNextStep();
        break;
      case 1:
        this.setTelephony();
        this.showNextStep();
        break;
      case 2:
        const telephonyToSave = this.incomingActionsEditor?.getTelephonyItemToSave();
        if (telephonyToSave)
          this.saveIncomingActions(telephonyToSave);
        break;
    }
  }

  private saveIncomingActions = (telephony: TelephonyItem) => {
    this.isSaving = true;

    const company = this.applicationContext.globalConfig.company.find(c => c.companyId == this.applicationContext.userPermissions.companyId);

    this._voiceSmsSettingsService.purchasePhoneNumber(Utils.cleanPhone(this.phoneNumber.phoneNumber), this.friendlyName, this.messagingService).pipe(
      concatMap(data => {
          return of(data)
      }),
      concatMap(data => {
        if (!this.messagingService) {
          data = this._voiceSmsSettingsService.saveSmsForwardingPhoneNumber(Utils.cleanPhone(this.phoneNumber.phoneNumber), company.companyGUID);
        }
        return of(data).pipe(finalize(() => this.isSaving = false));
      }),
      concatMap(data => {
        if (telephony.incomingCallAudio) {
          return Utils.toBase64(telephony.incomingCallAudio)
                  .pipe(
                    concatMap(base64FileData => this._storedFileService.storedFileUpload({
                      fileName: telephony.incomingCallAudio.name,
                      mimeType: telephony.incomingCallAudio.type,
                      base64FileData: base64FileData,
                    })),
                    tap((result: StoredFileModel) => telephony.telephony.incomingCallAudioFileId = result.storedFileId)
                  );
        }
        return of(data);
      }),
      concatMap(data => {
        if (telephony.voicemailAudio) {
          return Utils.toBase64(telephony.voicemailAudio)
            .pipe(
              concatMap(base64FileData => this._storedFileService.storedFileUpload({
                fileName: telephony.voicemailAudio.name,
                mimeType: telephony.voicemailAudio.type,
                base64FileData: base64FileData,
              })),
              tap((result: StoredFileModel) => telephony.telephony.voicemailAudoFileId = result.storedFileId)
            );
        }
        return of(data);
      }),
      concatWith(this._voiceSmsSettingsService.createTelephony(telephony.telephony))
    ).subscribe({
      next: () => {
        this.updateTelephonyList.emit(true);
        this._drawerService.hide("setupPhoneNumberDrawer", 100);
        this._notificationService.showSuccess('Purchased phone number successfully.', 'Success!');
      },
      error: (err) => {
        this._notificationService.showError(err?.message || 'Unable to create phone number.', 'Phone Number Setup');
      }
    });
  }

  private setTelephony() {
    this.telephony = new TelephonyService();
    this.telephony.userId = this.userProfile?.userProfile.userCompanyGuid;
    this.telephony.name = this.friendlyName;
    this.telephony.fromPhoneNumber = Utils.cleanPhone(this.phoneNumber.phoneNumber);
    this.telephony.latitude = this.phoneNumber.latitude;
    this.telephony.longitude = this.phoneNumber.longitude;
    this.telephony.city = this.phoneNumber.locality;
    this.telephony.state = this.phoneNumber.region;
    this.telephony.postalCode = this.phoneNumber.postalCode;
    this.telephony.isGlobal = !this.userProfile;
    this.telephony.serviceType = "Twilio";
  }
}
