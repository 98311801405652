<div class="card">
  <div class="card-header">
    <h4 class="card-title">Borrower Info</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerFirstName" class="control-lavel">First Name</label>
        <input
          id="wireReq_borrowerFirstName"
          type="text"
          name="wireReq_borrowerFirstName"
          class="form-control"
          [ngClass]="{'is-invalid': borrowerFirstName && borrowerFirstName.touched && borrowerFirstName.invalid}"
          [(ngModel)]="wireRequest.borrowerFirstName"
          (ngModelChange)="onBorrowerNamesChaned()"
          #borrowerFirstName="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerMiddleInitial" class="control-lavel">Middle Initial</label>
        <input
          id="wireReq_borrowerMiddleInitial"
          type="text"
          name="wireReq_borrowerMiddleInitial"
          class="form-control"
          [ngClass]="{'is-invalid': borrowerMiddleInitial && borrowerMiddleInitial.touched && borrowerMiddleInitial.invalid}"
          [(ngModel)]="wireRequest.borrowerMiddleInitial"
          #borrowerMiddleInitial="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerLastName" class="control-lavel">Last Name</label>
        <input
          id="wireReq_borrowerLastName"
          type="text"
          name="wireReq_borrowerLastName"
          class="form-control"
          [ngClass]="{'is-invalid': borrowerLastName && borrowerLastName.touched && borrowerLastName.invalid}"
          [(ngModel)]="wireRequest.borrowerLastName"
          (ngModelChange)="onBorrowerNamesChaned()"
          #borrowerLastName="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerSocialSecurityNumber" class="control-lavel">SSN</label>
        <input
          id="wireReq_borrowerSocialSecurityNumber"
          class="form-control"
          name="wireReq_borrowerSocialSecurityNumber"
          type="text"
          [(ngModel)]="wireRequest.borrowerSocialSecurityNumber"
          #ssnInput="ngModel"
          [ngClass]="{'is-invalid' : ssnInput && ssnInput.touched && ssnInput.invalid}"
          mask="000-00-0000"
          placeHolderCharacter="#"
          [showMaskTyped]="true"
          required
        />
        <div class="invalid-feedback" *ngIf="ssnInput && ssnInput.touched && ssnInput.invalid">
          <div *ngIf="ssnInput.errors?.['mask']">Please enter a valid SSN</div>
          <div *ngIf="ssnInput.errors?.['required']">This field is required</div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerDateOfBirth" class="control-lavel">Date of Birth</label>
        <date-input
          [name]="'wireReq_borrowerDateOfBirth'"
          [id]="'wireReq_borrowerDateOfBirth'"
          [(ngModel)]="wireRequest.borrowerDateOfBirth"
          [required]="true"
        ></date-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerEthnicity" class="control-lavel">Ethnicity</label>
        <select
          name="wireReq_borrowerEthnicity"
          id="wireReq_borrowerEthnicity"
          class="form-select"
          [ngClass]="{'is-invalid' : borrowerEthnicity && borrowerEthnicity.touched && borrowerEthnicity.invalid}"
          [(ngModel)]="wireRequest.borrowerEthnicity"
          #borrowerEthnicity="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let ethnicity of ethnicities" value="{{ethnicity.value}}">{{ethnicity.name | splitCamelcase}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerRace" class="control-lavel">Race</label>
        <select
          name="wireReq_borrowerRace"
          id="wireReq_borrowerRace"
          class="form-select"
          [ngClass]="{'is-invalid' : borrowerRace && borrowerRace.touched && borrowerRace.invalid}"
          [(ngModel)]="wireRequest.borrowerRace"
          #borrowerRace="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let race of races" value="{{race.value}}">{{race.name | splitCamelcase}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerSex" class="control-lavel">Sex</label>
        <select
          name="wireReq_borrowerSex"
          id="wireReq_borrowerSex"
          class="form-select"
          [ngClass]="{'is-invalid' : borrowerSex && borrowerSex.touched && borrowerSex.invalid}"
          [(ngModel)]="wireRequest.borrowerSex"
          #borrowerSex="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let sex of sexTypes" value="{{sex.value}}">{{sex.name | splitCamelcase}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_borrowerIncome" class="control-lavel">Income</label>
        <currency-input
          id="wireReq_borrowerIncome"
          name="wireReq_borrowerIncome"
          [(ngModel)]="wireRequest.borrowerIncome"
          [allowNegative]="false"
          [required]="true"
        >
        </currency-input>
      </div>
    </div>
  </div>
</div>