import { Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { AdverseReason } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import {
  ActionButtonOption,
  GridActionButtonType,
} from 'src/app/shared/models/action-button-options.model';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import { DeleteWithReplacementConfirmationDialogComponent } from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import { AdverseReasonService } from '../../services';
import { UpsertAdverseReasonDialog } from './upsert-adverse-reason-dialog/upsert-adverse-reason-dialog.component';

@Component({
  templateUrl: 'adverse-reason.component.html',
})
export class AdverseReasonComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  @ViewChild(Table)
  adverseTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('adverseReasonOrderDrawer')
  adverseReasonOrderDrawer: DrawerComponent;

  @ViewChild('adverseReasonOrder')
  adverseReasonOrder: RearrangeOrderComponent

  actionButtonOptions: Array<ActionButtonOption>;
  adverseColumns = [];
  adverseReasons: Array<AdverseReason>;
  itemsToOrder: Array<{ name: string, value: number }> = [];

  allowAddNew: boolean;

  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  currentAdverseReason: Partial<AdverseReason>;
  upsertAdverseReasonDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  private _companyId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _adverseReasonService: AdverseReasonService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
  ) {
    super(injector);
    this._companyId = this.applicationContext.userPermissions.companyId;
    this.allowAddNew = this.applicationContext.userPermissions.superAdmin ? false : true;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.adverseColumns = [
      { field: 'adverseReasonName', header: 'Adverse Reason Name', sortable: true },
      { field: 'order', header: 'Sort', sortable: true },
    ];
    this.setActionButtonOptions();
    this.populateAdverseReasons();
  }

  showUpsertDialog(row?: AdverseReason) {
    this.currentAdverseReason = cloneDeep(row) || {};
    this._drawerService.show("upsertAdverseReasonDrawer", 100);
  }

  onUpsertAdverseReasonDrawerClose(updatedAdverseReason: AdverseReason) {
    if (!updatedAdverseReason) {
      this._drawerService.hide("upsertAdverseReasonDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertAdverseReasonDrawer", 100);
    this.updateTable(updatedAdverseReason);
  }

  showDeleteDialog(rowData: AdverseReason) {
    const modalRef = this._modalService.open(
      DeleteWithReplacementConfirmationDialogComponent
    );
    modalRef.componentInstance.ItemsList = this.adverseReasons
      .filter((ls) => rowData.adverseReasonId !== ls.adverseReasonId)
      .map((ls) => ({
        value: ls.adverseReasonId,
        name: ls.adverseReasonName,
      }));
    modalRef.componentInstance.itemType = 'adverse reason';
    modalRef.result.then((assignedAdverseReasonId: string) => {
      this.deleteRow(rowData, parseInt(assignedAdverseReasonId));
    },
      () => { }
    );
  }

  openOrderDrawer() {
    if (!this.adverseReasons.length) {
      return;
    }

    this.itemsToOrder = this.setAdverseReasonItems();
    this._drawerService.show("adverseReasonOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedAdverseReasons: Array<EnumerationItem>) {
    this.adverseReasonOrder.saveInProgress = true;
    const sortedIds = sortedAdverseReasons.map((r) => ({
      id: r.value,
    }));
    this._adverseReasonService
      .updateAdverseReasonOrder(sortedIds, this._companyId)
      .pipe(finalize(() => {
        this.adverseReasonOrder.saveInProgress = false;
        this.closeOrderDrawer();
      })
      )
      .subscribe({
        next: () => {
          this.adverseTable.sortField = "";
          this.adverseTable.sortOrder = 0;
          this.adverseTable.reset();
          this.gridHeaderActionBar.search.nativeElement.value = '';
          this.populateAdverseReasons();
          this._notificationService.showSuccess(
            'Adverse reason order updated successfully',
            'Adverse Reason'
          );
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to update adverse reason order',
            'Adverse Reason'
          );
        }
      });
  }

  closeOrderDrawer() {
    this._drawerService.hide("adverseReasonOrderDrawer", 100);
  }

  private setActionButtonOptions(): void {
    this.actionButtonOptions = [
      {
        type: GridActionButtonType.SearchInput,
        searchInputPlaceholder: 'Search...',
        onSearch: (value: string) => this.adverseTable.filterGlobal(value, 'contains'),
      },
    ];
  }

  private populateAdverseReasons() {
    this._spinner.show();
    this._adverseReasonService
      .getAllAdverseReasons(this._companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this.adverseReasons = res;
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't load adverse reasons.",
            'Adverse Reason'
          );
        }
      });
  }

  private updateTable(result: AdverseReason) {
    const index = this.adverseReasons.findIndex(ls => ls.adverseReasonId === result.adverseReasonId);
    if (index === -1) {
      this.adverseReasons.push(result);
    } else {
      this.adverseReasons[index] = result;
    }
    this.adverseReasons = [...this.adverseReasons];
  }

  private deleteRow(rowData: AdverseReason, assignedAdverseReasonId: number) {
    this._adverseReasonService.deleteAdverseReason(
      rowData.adverseReasonId,
      this._companyId,
      assignedAdverseReasonId
    )
      .subscribe({
        next: (res) => {
          const index = this.adverseReasons.findIndex(
            (ls) => ls.adverseReasonId === rowData.adverseReasonId
          );
          this.adverseReasons.splice(index, 1);
          this.adverseReasons = [...this.adverseReasons];
          this._notificationService.showSuccess(
            'Adverse reason removed succesfully.',
            'Adverse Reason'
          );
        },
        error: (err) =>
          this._notificationService.showError(
            err?.message || "Couldn't delete adverse reason",
            'Adverse Reason'
          )
      });
  }

  private setAdverseReasonItems() {
    return chain(this.adverseReasons)
      .orderBy((ls) => ls.order)
      .map((ls) => ({
        name: ls.adverseReasonName,
        value: ls.adverseReasonId,
      }))
      .value();
  }
}
