<div class="modal-header">
    <h5 class="modal-title"> Partial Payoff Amount </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="mb-3 row mx-2">

        <label class="form-label" for="payoffAmount">Enter your payoff amount: </label>
        <currency-input name="payoffAmount" [(ngModel)]="partialPayoffAmount" (ngModelChange)="checkPayoffAmount()"></currency-input>

        <div *ngIf="isMax" class="m-3">
            <span>You entered the total unpaid balance as your payoff amount. Would you like to fully payoff your debt?</span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close(partialPayoffAmount)">OK</button>
</div>