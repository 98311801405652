<div class="row p-1 border m-1" *ngIf="range">
  <div class="col-2 d-flex align-items-center justify-content-center" style="font-size: 15px;">
    {{ dayOfWeek }}
    <i *ngIf="(!startTimeStr || !endTimeStr) && !range.enabledAllDay"
      class="la la-question-circle ms-1 cursor--help text-primary"
      style="font-size: 1.1em"
      placement="top"
      ngbTooltip="Based on current setup {{delivery === 'SMS' ? 'SMSes' : delivery + 's'}} won't be sent on this day."
      tooltipClass="custom-tooltip"
    ></i>
  </div>
  <div class="col-10">
    <div class="row">
      <div class="col">
        <div class="row">
          <label for="startTime-{{ dayOfWeek }}" class="col col-form-label">
            Start Time
          </label>
          <div class="col">
            <input
              [(ngModel)]="startTimeStr"
              (ngModelChange)="onFieldChanged('startTimeStr')"
              name="startTime-{{ dayOfWeek }}"
              class="form-control"
              type="time"
              id="startTime-{{ dayOfWeek }}"
              [disabled]="range.enabledAllDay"
              [required]="startTimeStr == null && endTimeStr != null"
              [maxTime]="endTimeStr"
              [ngClass]="{
                'is-invalid':
                startTime && startTime.touched && startTime.invalid
              }"
              #startTime="ngModel"
            />
            <div class="invalid-feedback" *ngIf="startTime.errors?.['required']">This field is required.</div>
            <div class="invalid-feedback" *ngIf="startTime.errors?.['maxTime']">Start Time must be less than {{ getTimeString(endTimeStr) }}</div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label for="endTime-{{ dayOfWeek }}" class="col col-form-label"
            >End Time</label
          >
          <div class="col">
            <input
              [(ngModel)]="endTimeStr"
              (ngModelChange)="onFieldChanged('endTimeStr')"
              name="endTime-{{ dayOfWeek }}"
              class="form-control"
              type="time"
              id="endTime-{{ dayOfWeek }}"
              [disabled]="range.enabledAllDay"
              [required]="endTimeStr == null && startTimeStr != null"
              [minTime]="startTimeStr"
              [ngClass]="{
                'is-invalid':
                endTime && endTime.touched && endTime.invalid
              }"
              #endTime="ngModel"
            />
            <div class="invalid-feedback" *ngIf="endTime.errors?.['required']">This field is required.</div>
            <div class="invalid-feedback" *ngIf="endTime.errors?.['minTime']">End Time must be grater than {{ getTimeString(startTimeStr) }}</div>
          </div>
        </div>
      </div>
      <div class="col d-flex align-items-center justify-content-between ps-4">
        <div class="checkbox">
          <input
            type="checkbox"
            class="form-check-input"
            id="allDay-{{ dayOfWeek }}"
            name="allDay-{{ dayOfWeek }}"
            [(ngModel)]="range.enabledAllDay"
            (ngModelChange)="onFieldChanged('enabledAllDay')"
          />
          <label for="allDay-{{ dayOfWeek }}"> All Day</label>
        </div>
        <div>
          <i class="fa fa-trash-alt cursor text-danger" (click)="clearPickers()" title="Clear Schedule"></i>
        </div>
      </div>
    </div>
  </div>
</div>
