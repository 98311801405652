import { CalculatedFeeTypeEnum } from './calculated-fee-type.enum';
import { FeeCalculatedValues } from './fee-calculated-values.model';
import { FeePaidByEnum } from './fee-paid-by.enum';
import { FeePaidToEnum } from './fee-paid-to.enum';
import { FeePercentOfFieldEnum } from './fee-percent-of-field.enum';
import { FeeSectionEnum } from './fee-section.enum';
import { FeeTypeEnum } from './fee-type.enum';

export class FeeDefinitionModel {
  feeDefinitionId: number;
  expressionId: number;
  defaultPaidBy: FeePaidByEnum = null;
  inactive?: boolean;
  hudNumber?: string;
  name?: string;
  feeSection: FeeSectionEnum = null;
  feeType: FeeTypeEnum = null;
  calculatedFeeType: CalculatedFeeTypeEnum = null;
  isAprFee: boolean;
  allowSplit: boolean;
  feePercentOf: FeePercentOfFieldEnum = null;
  borrowerFeeDollar?: number;
  borrowerFeePercent?: number;
  sellerFeeDollar?: number;
  sellerFeePercent?: number;
  lenderFeeDollar?: number;
  lenderFeePercent?: number;
  thirdPartyFeeDollar?: number;
  thirdPartyFeePercent?: number;
  canSellerConcessionsApply: boolean;
  paidOutsideClosing?: number;
  isChargeUserEditable: boolean;
  sumInHudNumber?: number;
  calculatedValues: FeeCalculatedValues = new FeeCalculatedValues();
  paidTo: null | string;
  canSetPaidTo: boolean;
  paidToType: FeePaidToEnum;
  borrowerSelectedServiceProvider: boolean;
  canShop: boolean;
  note: string;
  showOnHUD: boolean;
  includeInRegulationZPointsAndFeesCalculations: boolean;

  companyId?: number;
  insertedBy?: null | string;
  dateInserted: Date;
  updatedBy?: null | string;
  dateUpdated: Date;
}
