<div class="modal-header">
  <h5 class="modal-title">Add {{contactListType == "Borrower" ? "Contact" : contactListType}}s to Dial List</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
    You are about to add {{ selectedItems.length }} {{(contactListType == "Borrower" ? "Contact" : contactListType).toLowerCase()}}{{selectedItems.length > 1 ? 's' : ''}} to the dial list. Do you want to continue?

    <div class="input-group align-items-center mt-4">
      <label for="addTo" class="form-label align-self-center me-2">Add To:</label>
      <!-- <select
        [(ngModel)]="selectedDialListId"
        class="form-select"
        name="addTo"
        id="addTo"
      >
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let dialList of dialLists" [value]="dialList.dialListId">
          {{ dialList.name }}
        </option>
      </select> -->

      <div ngbDropdown class="d-inline-block dropdown" placement="bottom-end">
        <button class="btn btn-primary d-flex justify-content-between align-items-center" id="dropdownBasic1"
          ngbDropdownToggle>
          <span class="w-100 text-center">{{
            selectedDialList ? selectedDialList.name : 'Select One'
            }}</span>
          <span><i class="fa fa-caret-down mx-1"></i></span>
        </button>
    
        <ul class="dropdown-menu cursor" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <li ngbDropdownItem (click)="selectedDialList = null">
            -- Select One --
          </li>
          <li ngbDropdownItem (click)="addNewDialList()">+ Add New</li>
    
          <li ngbDropdownItem *ngIf="dialListsLoading" class="dialLoading text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </li>
    
          <li ngbDropdownItem class="d-flex justify-content-between" *ngFor="let dialList of dialLists"
            (click)="selectedDialList = dialList; selectedDialListId = dialList.dialListId">
            <span>{{ dialList.name }}</span>
            <span>
              <!-- <span title="Move" role="button" (click)="openMoveToDialListDialog(dialList)" class="m-2"><i
                  class="fa fa-dolly"></i></span> -->
              <span title="Delete" role="button" (click)="openDeleteDialListDialog(dialList)"><i
                  class="fa fa-trash-alt cursor text-danger ms-2"></i></span>
            </span>
          </li>
        </ul>
      </div>

    </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-soft-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> No
  </button>

  <button
    type="button"
    class="btn btn-soft-primary "
    (click)="onOkClicked()"
    [disabled]="!selectedDialListId"
  >
    <i class="fa fa-check"></i> Yes
  </button>

  <button
    type="button"
    class="btn btn-soft-primary "
    (click)="onOkClicked(true)"
    [disabled]="!selectedDialListId"
  >
    <i class="fa fa-check"></i> Yes & Take me there
  </button>
</div>
