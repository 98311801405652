<div class="alert alert-danger border-0" role="alert" *ngIf="error">
  <strong>ERROR:</strong> An error occurred while loading the applications
</div>

<application-table
  *ngIf="!isLoading; else spinner"
  [applications]="applications"
  [applicationTableConfig]="applicationTableConfig"
>
</application-table>

<ng-template #spinner>
  <loading-indicator loadingMessage="Loading applications...">
  </loading-indicator>
</ng-template>
