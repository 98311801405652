<div class="row pt-3">
    <div class="col-sm-4">
        <div class="form-group mb-3">
            <label class="form-label mb-lg-0 text-start">Phone Number's Friendly Name</label>
            <div>
                <input class="form-control" placeholder="Friendly Name" name="friendly-name" type="text"
                       [(ngModel)]="friendlyName" (change)="this.friendlyNameSet.emit(friendlyName)"/>
            </div>
        </div>
    </div>
</div>
