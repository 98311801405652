import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {BorrowerCalculatedStats} from 'src/app/modules/urla/models/urla-mortgage.model';
import {Constants} from 'src/app/services/constants';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {LeadEmploymentIncomeInDetailComponent} from '../lead-employment-income-in-detail/lead-employment-income-in-detail.component';
import {LeadOtherIncomeInDetailComponent} from '../lead-other-income-in-detail/lead-other-income-in-detail.component';
import {sum} from '../../../../../shared/utils/math-utils';

@Component({
  selector: 'lead-borrower-income',
  templateUrl: 'lead-borrower-income.component.html',
  styleUrls: ['./lead-borrower-income.component.scss']
})
export class LeadBorrowerIncomeComponent implements OnInit {

  @ViewChild('employmentInDetail') employmentInDetail: LeadEmploymentIncomeInDetailComponent;
  @ViewChild('otherInDetail') otherInDetail: LeadOtherIncomeInDetailComponent;

  @Input()
  borrower: any;

  @Output()
  grandTotalIncomeChanged: EventEmitter<any> = new EventEmitter<any>();

  yesNoOptions: EnumerationItem[] = [];
  ownershipShareTypes: EnumerationItem[] = [];
  employmentIncomeTypes: EnumerationItem[] = [];
  nonEmploymentIncomeTypes: EnumerationItem[] = [];
  incomePayPeriods: EnumerationItem[] = [];

  totalOtherIncome: number = 0;
  totalEmploymentIncome: number = 0;

  showEmploymentsMonthlyIncome: boolean = false;
  showOtherIncome: boolean = false;

  constructor(private readonly _enumsService: EnumerationService) {
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.ownershipShareTypes = enums[Constants.enumerations.employmentOwnershipShare];
      this.employmentIncomeTypes = enums[Constants.enumerations.incomeType].filter((t: any) => this.filterEmploymentIncomes(t.value));
      this.nonEmploymentIncomeTypes = enums[Constants.enumerations.incomeType].filter((t: any) => this.filterNonEmploymentIncomes(t.value));
      this.incomePayPeriods = this._enumsService.getIncomePayPeriods();
    });
  }

  ngOnInit() {
    this.calculateTotalIncome();
  }

  onAddEmploymentClicked = () => {
    this.showEmploymentsMonthlyIncome = true;
    setTimeout(() => {
      this.employmentInDetail.addEmployment();
    });
  }

  onAddOtherIncomeClicked = () => {
    this.showOtherIncome = true;
    setTimeout(() => {
      this.otherInDetail.addOtherIncome();
    });
  }

  protected calculateTotalIncome = () => {
    this.totalEmploymentIncome = this.borrower.employments.reduce(
      (acc: number, e: any) =>
        sum(
          acc,
          e.monthlyIncome,
          e.bonusIncome,
          e.commissionsIncome,
          e.overtimeIncome,
          e.isSelfEmployed ? e.selfEmploymentMonthlyIncomeOrLoss : 0,
        ),
      0,
    );

    this.totalOtherIncome = this.borrower.nonEmploymentIncomes.reduce(
      (acc: number, e: any) =>
        sum(
          acc,
          e.monthlyIncome,
          e.bonusIncome,
          e.commissionsIncome,
          e.overtimeIncome,
        ),
      0,
    );

    if (!this.borrower.calculatedStats) {
      this.borrower.calculatedStats = new BorrowerCalculatedStats();
    }
    this.borrower.calculatedStats.totalMonthlyIncome = this.totalOtherIncome + this.totalEmploymentIncome;
    this.grandTotalIncomeChanged.emit();
  }

  private filterEmploymentIncomes = (incomeType: string): boolean => {
    return incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime);
  }

  private filterNonEmploymentIncomes = (incomeType: string): boolean => {
    return !(incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime));
  }
}
