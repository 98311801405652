<ng-template #pageActionBarLeft>
    <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
    <button *ngIf="allowAddNew" class="btn btn-primary" (click)="showUpsertDialog()">
        <i class="fa fa-plus"></i>
        Add New
    </button>
</ng-template>

<ng-template #mainContent>
    <form #agentTypeForm="ngForm">
        <p-table #loanTypeTable [columns]="loanTypeColumns" columnResizeMode="fit" [value]="loanTypes"
            [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll"
            name="loan-type-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true"
            [showCurrentPageReport]="true" scrollable="true" [scrollHeight]="scrollHeight"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single"
            [globalFilterFields]="['loanTypeName']">
            <ng-template pTemplate="caption">
              <div class="d-flex justify-content-end">
                  <span class="p-input-icon-right ms-2">
                    <i class="pi pi-search"></i>
                    <input
                      pInputText
                      type="text"
                      class="form-control h-100"
                      (input)="loanTypeTable.filterGlobal($event.target.value, 'contains')"
                      (ngModelChange)="loanTypeTable.first=0"
                      placeholder="Search..."
                    />
                  </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of loanTypeColumns" [pSortableColumn]="col.field"
                        [pSortableColumnDisabled]="!col.sortable">
                        <div *ngIf="col.field !== 'order'">
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </div>
                        <div *ngIf="col.field === 'order'">
                            <button *ngIf="allowAddNew" type="button" id="loan-type-order-link" class="btn link-primary p-0 m-0"
                                (click)="openOrderDrawer(); $event.stopPropagation();">Sort</button>
                            <span *ngIf="!allowAddNew">Sort</span>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td>
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="fa fa-pencil-alt cursor text-primary" (click)="showUpsertDialog(row)"
                                title="Edit Role"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(row)"
                                title="Delete Role"></i>
                        </span>
                    </td>
                    <td>{{ row.loanTypeName }}</td>
                    <td>{{ row.showInOnlineApp | yesNo }}</td>
                    <td>{{ row.defaultForOnlineApp | yesNo }}</td>
                    <td>{{ row.mortgageAppliedForType }}</td>
                    <td>{{ row.order }}</td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
    [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #loanTypeOrderDrawer [title]="'Loan Types Order'" [name]="'loanTypeOrderDrawer'"
    [templateRef]="loanTypeOrderRef" [options]="orderDrawerOptions">
</drawer>
<ng-template #loanTypeOrderRef>
    <rearrange-order #loanTypeOrder [itemList]="itemsToOrder" (close)="closeOrderDrawer($event)"
        (save)="onOrderDrawerSave($event)"></rearrange-order>
</ng-template>
<drawer #upsertLoanTypeDrawer [title]="currentLoanType?.loanTypeId ? 'Edit Loan Type' : 'Add Loan Type'"
    [name]="'upsertLoanTypeDrawer'" [templateRef]="upsertLoanTypeRef" [options]="upsertLoanTypeDrawerOptions">
</drawer>
<ng-template #upsertLoanTypeRef>
    <upsert-loan-type [loanType]="currentLoanType" [mortgageAppliedForTypes]="mortgageAppliedForTypes"
        [companyId]="companyId" (close)="onUpsertLoanTypeDrawerClose($event)"></upsert-loan-type>
</ng-template>
