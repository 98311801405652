import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { LosProduct } from 'src/app/models/los/los-product.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import {
  InvestorProductList,
} from 'src/app/modules/pricing/models/pricing/pricing-investor-product.model';
import { Product } from 'src/app/modules/pricing/models/pricing/product-search-result.model';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product.service';
import { TableColumn } from 'src/app/shared/models/tale-column.model';
import { ConfigurationProduct } from '../../../tpo-config/models/configuration-product.model';

@Component({
  selector: 'upsert-lender-product',
  templateUrl: './upsert-lender-product.component.html',
  styleUrls: ['./upsert-lender-product.component.scss'],
  viewProviders: [formViewProvider],
})
export class UpsertLenderProductComponent implements OnInit {

  @ViewChild("productForm") productForm: NgForm | undefined;

  @Input() product: ConfigurationProduct = new ConfigurationProduct();
  @Input() categories: string[] = [];
  @Input() pricingVendors: { name: string, value: string, products: InvestorProductList[], flatProducts: Product[] }[] = [];
  @Input() losVendors: { name: string, value: string, products: LosProduct[] }[] = [];
  @Input() fields: TableColumn[] = [];
  @Input() enabledChannels: EnumerationItem[] = [];
  @Output() close: EventEmitter<ConfigurationProduct> = new EventEmitter<ConfigurationProduct>();

  isSaving: boolean = false;
  selectedRolechannels: string[] = [];
  categorySelectList: EnumerationItem[] = [];
  filteredPricingVendorProducts: Product[] = [];
  filteredLosVendorProducts: LosProduct[] = [];

  constructor(
    private readonly _productService: ProductService,
    private readonly _notifyService: NotificationService,
    private readonly _channelService: ChannelService
  ) {
  }

  ngOnInit(): void {
    this.product.losVendor = this.product.losVendor ? this.product.losVendor : null;
    this.product.losVendorProductId = this.product.losVendorProductId ? this.product.losVendorProductId : null;
    this.product.pricingVendorProductId = this.product.pricingVendorProductId ? this.product.pricingVendorProductId : null;

    this.product.pricingVendor = !this.product.pricingVendor || this.product.pricingVendor === 'Unknown'
      ? null
      : this.product.pricingVendor;
  
    this.categorySelectList = this.categories.map(cat => ({
      name: cat,
      value: cat
    }));

    this.selectedRolechannels = this._channelService
      .getChannelsFromCommaDelimitedString(this.product?.enabledChannels)
      .map(ch => ch.name);

    if (this.product?.pricingVendor) {
      this.getFilteredVendorProducts();
    }

    if (this.product?.losVendor) {
      this.getFilteredLosVendorProducts();
    }
  }

  save() {
    if (this.productForm) {
      this.productForm.form.markAllAsTouched();
      if (this.productForm.form.valid) {
        this.isSaving = true;
        this.product.enabledChannels = this.selectedRolechannels.length > 0 ? this.selectedRolechannels.join(',') : null;
        if (this.product.productId) { // isUpdate
          this._productService.updateProduct(this.product)
            .subscribe({
              next: () => {
                this.isSaving = false;
                this._notifyService.showSuccess("Product updated Successfully", "Success");
                this.close.emit(this.product);
              },
              error: (err) => {
                this.isSaving = false;
                this._notifyService.showError(err?.message || 'Unable to Update Product', "Error");
              }
            });
        }
        else {
          this._productService.insertProduct(this.product)
            .subscribe({
              next: () => {
                this.isSaving = false;
                this._notifyService.showSuccess("Product inserted Successfully", "Success");
                this.close.emit(this.product);
              },
              error: (err) => {
                this.isSaving = false;
                this._notifyService.showError(err?.message || 'Unable to Insert Product', "Error");
              }
            });
        }
      }
    }
  }

  onClose() {
    this.close.emit();
  }

  addNewCategory = (newCategory: string) => {
    return { name: newCategory, value: newCategory };
  }

  isShowField = (fieldName: string): boolean => {
    return this.fields.findIndex(f => f.field == fieldName) > -1;
  }

  handlePricingVendorChange = () => {
    this.product.pricingVendorProductId = null;
    this.getFilteredVendorProducts();
  }

  handleLosVendorChange = () => {
    this.product.losVendorProductId = null;
    this.getFilteredLosVendorProducts();
  }

  private getFilteredVendorProducts = () => {
    if (this.product.pricingVendor) {
      this.filteredPricingVendorProducts = this.pricingVendors.find(i => i.value == this.product.pricingVendor)?.flatProducts?.map(product => ({
        ...product,
        display: `${product.productId} : ${product.productName}`
      })) || [];
    } else {
      this.filteredPricingVendorProducts = [];
    }

    this.filteredPricingVendorProducts = [...this.filteredPricingVendorProducts];
  }

  private getFilteredLosVendorProducts = () => {
    if (this.product.losVendor) {
      this.filteredLosVendorProducts = this.losVendors.find(i => i.value == this.product.losVendor)?.products?.map(product => ({
        ...product,
        display: `${product.losProductId} : ${product.losProductName}`
      })) || [];
    } else {
      this.filteredLosVendorProducts = [];
    }
    
    this.filteredLosVendorProducts = [...this.filteredLosVendorProducts];
  }
}
