import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from './controls/select/select.component';
import { ThemeService } from './theme.service';



const MODULES = [
  NgbDropdownModule,
  NgbTypeaheadModule,
  NgbDatepickerModule,
  NgbPopoverModule,
  CdkTableModule,
  NgbModule
];

const components = [SelectComponent];

@NgModule({
    declarations: components,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ...MODULES],
    exports: [...MODULES, ...components],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ThemeModule {

  static forRoot(config: ThemeService): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        {provide: ThemeService, useValue: config }
      ],
      //schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
    };
  }
}
