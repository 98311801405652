import { Component, Input } from '@angular/core';

const CLASS_NAME = 'li-badge';

export type LicenseInfoStatusBadgeVariant = 'success' | 'error' | 'neutral';

@Component({
  selector: 'license-info-badge',
  templateUrl: './license-info-badge.component.html',
  styleUrls: ['./license-info-badge.component.scss'],
})
export class LicenseInfoBadgeComponent {
  @Input()
  minWidth?: string | undefined;

  protected modifierClassName: string | undefined;

  @Input()
  set variant(value: LicenseInfoStatusBadgeVariant) {
    const className = getModifierClassNameForVariant(value);
    if (className == null) {
      console.error(`Unknown variant: ${value}`);
    }

    this.modifierClassName = className;
  }
}

function getModifierClassNameForVariant(status: LicenseInfoStatusBadgeVariant): string | undefined {
  switch (status) {
    case 'success':
      return `${CLASS_NAME}--success`;
    case 'error':
      return `${CLASS_NAME}--error`;
    case 'neutral':
      return `${CLASS_NAME}--neutral`;
  }
}
