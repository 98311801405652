<div class="row mt-1">
    <div class="col-md-12" *ngIf="scope === 'Company'">
        <div class="row">
            <div class="col-md-12">
                <toggle-configuration
                    [title]="'Enable Social Media'"
                    [id]="'socialMediaIntegrationEnabled'"
                    [configuration]="socialMediaIntegrationEnabled"
                ></toggle-configuration>
            </div>
            <div class="col-md-12">
                <hr>
            </div>
        </div>
    </div>
    <div class="col-md-12" *ngIf="scope === 'User' || (scope === 'Company' && socialMediaIntegrationEnabled.value)">
        <div class="row">
            <div class="col-md-12">
                <div class="fieldset-title">
                    Social Media
                    <span class="badge font-14 badge-soft-primary">New!</span>
                </div>
            </div>
            <div class="col-md-12">
                <h4 class="warning-alert">
                    <i class="fa fa-info-circle text-warning" *ngIf="!keyValuePairs.profileRefId.value">
                        No social media account linked. Click the button below to link an account
                    </i>
                    <i class="fa fa-check-circle text-success" *ngIf="keyValuePairs.profileRefId.value">
                        Social Media account is already linked. Click the button below to relink the account
                    </i>
                    <div class="pt-3 font-15 text-success" *ngIf="keyValuePairs.profileRefId.value">
                        <strong>Ref ID: {{keyValuePairs.profileRefId.value}}</strong>
                    </div>
                </h4>
            </div>
            <div class="col-md-12 pt-2">
                <button class="btn btn-primary" [disabled]="socialMediaProfileLoading" (click)="linkSocialMediaAccounts()">
                    <span *ngIf="!socialMediaProfileLoading">
                        {{keyValuePairs.profileRefId.value ? 'Relink Social Media Account' : 'Link Social Media Account' }}
                    </span>
                    <span *ngIf="socialMediaProfileLoading">
                        <span class="spinner-border spinner-border-sm"></span> Please wait...
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-12" *ngIf="scope === 'Company'">
        <enable-message *ngIf="socialMediaIntegrationEnabled.value === 0">
            Enable this feature above!
        </enable-message>
    </div>
</div>
