import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { finalize } from 'rxjs';
import { LoanStatus } from 'src/app/models';
import { CheckList } from 'src/app/models/checklist.model';
import { CheckListService } from 'src/app/services/check-list.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'check-list-item-details',
  templateUrl: './check-list-item-details-dialog.component.html',
  styleUrls: ['./check-list-item-details-dialog.component.scss']
})
export class CheckListItemDetailsDialogComponent implements OnInit {

  @ViewChild("checkListItemForm") checkListItemForm: NgForm | undefined;

  isSaving: boolean = false;

  get checkListItem(): CheckList {
    return this._checkListItem;
  }
  @Input()
  set checkListItem(value: CheckList) {
    this._checkListItem = value;
    if (value) {
      this.checkListItemData = cloneDeep(value);
    }
  }

  @Input() loanStatuses: LoanStatus[] = [];
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkListItemData: CheckList = new CheckList();

  private _checkListItem: CheckList;

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    if (!this.checkListItemData.loanStatusId)
      this.checkListItemData.loanStatusId = null;
  }

  onClose() {
    this.close.emit();
  }

  save = () => {
    if (this.checkListItemForm) {
      this.checkListItemForm.form.markAllAsTouched();
      if (this.checkListItemForm.form.valid) {
        this.isSaving = true;
        let observable;
        if (this.checkListItemData.checklistId) {
          observable = this._checkListService.updateChecklistItem(this.checkListItemData);
        }
        else {
          observable = this._checkListService.insertChecklistItem(this.checkListItemData);
        }

        observable
          .pipe(finalize(() => this.isSaving = false))
          .subscribe({
            next: () => {
              if (this.checkListItemData.checklistId) {
                this._notifyService.showSuccess("Update done successfully", "Success");
              }
              else {
                this._notifyService.showSuccess("Insert done successfully", "Success");
              }
              this.close.emit(true);
            }, error: (err) => {
              this._notifyService.showError(err.error.message, "Error");
            }
          });
      }
    }
  }
}
