<ng-container *ngIf="taskCategories; else loading">
  <div class="row g-0">
    <div class="col"></div>
  </div>
  <div class="mb-3">
    <div class="row g-0 mb-2" *ngFor="let category of taskCategories">
      <div class="col-{{ borrowers.length < 4 ? 5 : 3 }} d-flex align-items-center">
        <label class="task-category-name text-center">
          {{ category.taskCategoryName }}
        </label>
      </div>
      <div class="col no-padding" *ngFor="let borrower of borrowers; let i = index">
        <label class="text-center ms-2" *ngIf="category.taskCategoryId == taskCategories[0].taskCategoryId">{{ i + 1
          }}</label>
        <div class="checkbox borrower-info-checkbox">
          <input id="char_{{ category.taskCategoryId }}_{{ borrower.borrowerId }}_{{ id }}" (change)="
              onCharacteristicCheckChanged(
                category.taskCategoryId,
                borrower.borrowerId
              )
            " name="char_{{ category.taskCategoryId }}_{{ borrower.borrowerId }}_{{ id }}" type="checkbox" [checked]="
              isCharacteristicCheckedForBorrower(
                borrower.borrowerId,
                category.taskCategoryId
              )
            " />
          <label for="char_{{ category.taskCategoryId }}_{{ borrower.borrowerId }}_{{ id }}">
          </label>
          <span class="borrower-info-span" *ngIf="category.taskOption === 'RequestQuantity'">
            <a class="text-primary" (click)="
                openEditCharacteristicDialog(
                  category,
                  borrower.borrowerId,
                  false
                )
              ">
              {{
              '(' +
              getCharacteristicQuantity(
              borrower.borrowerId,
              category.taskCategoryId
              ) +
              ')'
              }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <button class="btn btn-outline-primary" type="button" [disabled]="isPullingHistory"
      (click)="onViewHistoryClicked()">
      <span *ngIf="isPullingHistory" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ isPullingHistory ? 'Please wait...' : 'View History' }}
    </button>
    <button class="btn btn-primary float-end" type="button" [disabled]="!thereAreChanges" (click)="onSaveClicked()">
      <span *ngIf="isSavingCharacteristics" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      {{ isSavingCharacteristics ? 'Please wait...' : 'Save' }}
    </button>
    <button class="btn btn-outline-primary float-end" type="button" (click)="onCancel()">
      Cancel
    </button>
  </div>
</ng-container>
<ng-template #loading>
  <loading-indicator [loadingMessage]="''"></loading-indicator>
</ng-template>