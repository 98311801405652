import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ActionTriggerType, CampaignModel } from 'src/app/models/campaign/campaign.model';
import { CampaignAction, VoicemailActionData } from 'src/app/models/campaign/campaign-action.model';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { MessageHistoryItem } from 'src/app/models/message.model';
import { SearchCriteria } from 'src/app/models';
import { PerformanceCounter } from 'src/app/models/campaign/performance-counter.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  
  private _currentCampaign: BehaviorSubject<CampaignModel> = new BehaviorSubject<CampaignModel>(null);

  constructor(private readonly _dataService: DataService) { }

  setCampaignActionOrder(campaignActions: Array<CampaignAction>) {
    var orders = [ActionTriggerType.Immediate, ActionTriggerType.Absolute, ActionTriggerType.TriggerAfter];
    campaignActions.sort((action1, action2) => {
      return (orders.indexOf(action1.actionTriggerType)) - (orders.indexOf(action2.actionTriggerType));
    });
    campaignActions.forEach((action, index) => {
      if (action.actionTriggerType === ActionTriggerType.TriggerAfter) {
        const parentDisplayOrder = campaignActions.find(a => a.campaignActionId == action.triggerAfterActionId)?.displayOrder;
        const tempCampaigId = Math.abs(action.campaignActionId);
        action.displayOrder = parentDisplayOrder + tempCampaigId / Math.pow(10, tempCampaigId.toString().length + 1);
        return;
      }
      action.displayOrder = index;
    });
  }

  get currentCampaign(): BehaviorSubject<CampaignModel> {
    return this._currentCampaign;
  }

  getCampaigns = (): Observable<any[]> => {
    const url = `api/campaign`;
    return this._dataService.get(url);
  };

  getCampaignById = (campaignId: number, includePerfCounters?: boolean): Observable<CampaignModel> => {
    const url = `api/campaign/${campaignId}?includePerfCounters=${includePerfCounters ? "true" : "false"}`;
    return this._dataService.get(url)
      .pipe(
        map((campaign: CampaignModel) => {
          this._currentCampaign.next(campaign);
          return campaign;
        }), catchError(err => {
          throw err;
        })
      );
  };

  getCampaignPerfCounter = (campaignId: number): Observable<PerformanceCounter> => {
    const url = `api/campaign/${campaignId}?includePerfCounters=true`;
    return this._dataService.get(url)
      .pipe(
        map((campaign: CampaignModel) => {
          return campaign.performanceCounters;
        }), catchError(err => {
          throw err;
        })
      );
  };

  copyCampaignById = (campaignId: number): Observable<CampaignModel> => {
    const url = `api/campaign/${campaignId}/copy`;
    return this._dataService.post(url, null);
  };

  updateCampaignStatus(
    campaignId: number,
    isActive: boolean
  ): Observable<CampaignModel> {
    const url = `api/campaign/${campaignId}/status/${isActive}`;
    return this._dataService.post(url, null);
  }

  updateCampaign(campaign: CampaignModel): Observable<CampaignModel> {
    const url = `api/campaign/${campaign.campaignId}`;
    return this._dataService.post(url, campaign);
  }

  getGlobalServices = (service: string): Observable<any> => {
    return this._dataService.get(`api/Admin/service-model/${service}`);
  }

  uploadVoicemailFile = (
    campaignId: number,
    campaignActionId: number,
    voicemailActionDataId: number,
    formData: FormData
  ): Observable<VoicemailActionData> => {
    const url = `api/campaign/${campaignId}/actions/${campaignActionId}/voicemail/${voicemailActionDataId}`;
    return this._dataService.postFormData(url, formData);
  }

  addCampaign = (payload) => {
    let url = `api/campaign`;
    if (payload.campaignId) {
      url += `/${payload.campaignId}`;
    }
    return this._dataService.post(url, payload);
  }

  addCampaigns(campaigns: readonly CampaignModel[]): Observable<readonly CampaignModel[]> {
    return forkJoin(
      campaigns.map((campaign) => this.addCampaign(campaign)),
    );
  }

  deleteCampaign = (campaignId: number) => {
    return this._dataService.delete(`api/campaign/${campaignId}`);
  }

  getCampaignActivityDetails(campaignId: number, type: string): Observable<MessageHistoryItem[]> {
    return this._dataService.get(`api/campaign/${campaignId}/activity/${type}`);
  }

  getCampaignActivityContactDetails(params: SearchCriteria, camapignId: number) {
    let query = `campaignId=${camapignId}`;
    if (params) {
      Object.keys(params).forEach((key: keyof SearchCriteria) => {
        if (params[key] || params[key] === 0) {
          if (key === 'isOrderDescending') {
            const sortOrder = (params[key] ? 'desc' : 'asc');
            query += `&orderDirection=${sortOrder}`;
          } else {
            query += `&${key}=${params[key]}`;
          }
        }
      })
    }

    return this._dataService.get(
      `api/campaign/${camapignId}/activity/records?${query}`
    );
  }

  generateText = (prompt: string): Observable<string> => {
    let url = `api/content-generation`;
    return this._dataService.post(url, {
      contentType: 'Text',
      prompt
    }, null, {
      responseType: "text",
    });
  }
}
