<ng-template #pageActionBarLeft>
  <div class="d-flex justify-content-between w-100">
    <bread-crum></bread-crum>
    <div class="hamburger-menu d-md-none">
      <button class="btn btn-hamburger" (click)="toggleMenu()">
        <i class="fas fa-bars" style="color: #7081b9;"></i>
      </button>
      <div *ngIf="isMenuOpen" class="hamburger-menu-content">
        <generic-date-range-filter class="block-item ms-2 mt-2 mb-1" [label]="selectedFilters.dateRange?.displayText"
          [dateRange]="selectedFilters.dateRange" (filtersChanged)="onRangesFilterChanged($event)">
        </generic-date-range-filter>

        <generic-filter #leadsFilter class="block-item ms-2" [label]="'All Users'" [filterHeader]="'Users'"
          [filterOptions]="usersForFiltering" [type]="'singleSelect'" (filtersChanged)="onUsersFilterChanged($event)">
        </generic-filter>

        <generic-filter #leadsFilter class="block-item ms-2" [label]="'All Campaigns'" [filterHeader]="'Lead Campaigns'"
          [filterOptions]="campaignsForFiltering" (filtersChanged)="onCampaignsFilterChangedDebounce($event)">
        </generic-filter>

        <generic-filter #leadsFilter class="block-item ms-2" [label]="'All Branches'" [filterHeader]="'Branches'"
          [filterOptions]="branchesForFiltering" [type]="'singleSelect'"
          (filtersChanged)="onBranchesFilterChanged($event)">
        </generic-filter>

        <generic-filter #leadsFilter class="block-item ms-2" [label]="'All Statuses'" [filterHeader]="'Lead Statuses'"
          [filterOptions]="leadStatusesForFiltering" (filtersChanged)="onLeadStatusesFilterChangedDebounce($event)">
        </generic-filter>

        <generic-filter #leadsFilter class="block-item ms-2" [label]="'All States'" [filterHeader]="'States'"
          [filterOptions]="statesForFiltering" (filtersChanged)="onStatesFilterChangedDebounce($event)">
        </generic-filter>

        <generic-filter #leadsFilter class="block-item ms-2" [label]="'All Tags'" [filterHeader]="'Lead Tags'"
          [filterOptions]="tagsForFiltering" [type]="'singleSelect'" (filtersChanged)="onTagsFilterChanged($event)">
        </generic-filter>

        <div class="ms-3 mt-2" *ngIf="leadTableConfig.isFilterButtonsVisible">
          <div class="form-check form-switch form-switch-success me-2">
            <label for="filter_ShowAsUnassigned" class="form-check-label"> Show Unassigned</label>
            <input id="filter_ShowAsUnassigned" class="form-check-input" [ngModel]="showUnassignedLeads"
              (ngModelChange)="unassignedChange($event)" type="checkbox" />
          </div>

          <div class="form-check form-switch form-switch-success me-2">
            <label for="filter_ShowAsArchive" class="form-check-label"> Show Archived</label>
            <input id="filter_ShowAsArchive" class="form-check-input" [ngModel]="selectedFilters.showArchived"
              (ngModelChange)="showAsArchiveChange($event)" type="checkbox" />
          </div>

          <div class="form-check form-switch form-switch-success me-2">
            <label for="filter_ShowInactiveUsers" class="form-check-label"> Show Inactive Users</label>
            <input id="filter_ShowInactiveUsers" class="form-check-input" [ngModel]="showInactiveUsers"
              (ngModelChange)="showInactiveUsersChange($event)" type="checkbox" />
          </div>

          <div class="form-check form-switch form-switch-success me-2">
            <label for="editModeToggle" class="form-check-label">Power User Mode</label>
            <input id="editModeToggle" class="form-check-input" [(ngModel)]="inPowerUserMode" type="checkbox" checked
              (change)="inPowerUserModeChange()" />
          </div>
        </div>
        <div class="btn-group mt-2 d-block">
          <button type="button" class="btn btn-soft-danger btn-clear-all ms-2 h-xs-fit-content" title="Clear All"
            (click)="clearAll()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <button type="button" class="btn btn-primary btn-mobile" *ngIf="leadTableConfig.isNewButtonVisible"
            style="width: 7rem" (click)="onAddNewLeadClicked()"><i class="fas fa-plus me-2"></i>New Lead
          </button>
          <div class="btn-group-left d-flex ms-2">
            <button class="btn tippy-btn m-left-1 btn-mobile"
              [ngClass]="{'btn-success btn-success-hover button-item-text': intervalConfig?.intervalTime > 0, 'btn-secondary btn-secondary-point-event': !intervalConfig?.intervalTime || intervalConfig?.intervalTime == 0}"
              type="button" (click)="onRefreshClicked()" style="width: 10rem">

              <span *ngIf="intervalConfig?.intervalTime > 0 else refreshDisabled">
                <i class="fas fa-sync me-2"></i>Auto Refresh: {{masterRefreshCounterDisplay}}
              </span>
              <ng-template #refreshDisabled>
                <span style="cursor: pointer;">
                  Auto Refresh Disabled
                </span>
              </ng-template>
            </button>
            <button class="btn btn-outline-secondary tippy-btn m-left-1"
              (click)="$event.stopPropagation(); openIntervalSettingsDialog()">
              <i class="fas fa-wrench"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pageActionBarMiddle>
  <div class="filters-container">
    <generic-date-range-filter class="ms-2" [label]="selectedFilters.dateRange?.displayText"
      [dateRange]="selectedFilters.dateRange" (filtersChanged)="onRangesFilterChanged($event)">
    </generic-date-range-filter>

    <generic-filter #leadsFilter class="ms-2" [label]="'All Users'" [filterHeader]="'Users'"
      [filterOptions]="usersForFiltering" [type]="'singleSelect'" (filtersChanged)="onUsersFilterChanged($event)">
    </generic-filter>

    <generic-filter #leadsFilter class="ms-2" [label]="'All Campaigns'" [filterHeader]="'Lead Campaigns'"
      [filterOptions]="campaignsForFiltering" (filtersChanged)="onCampaignsFilterChangedDebounce($event)">
    </generic-filter>

    <generic-filter #leadsFilter class="ms-2" [label]="'All Branches'" [filterHeader]="'Branches'"
      [filterOptions]="branchesForFiltering" [type]="'singleSelect'" (filtersChanged)="onBranchesFilterChanged($event)">
    </generic-filter>

    <generic-filter #leadsFilter class="ms-2" [label]="'All Statuses'" [filterHeader]="'Lead Statuses'"
      [filterOptions]="leadStatusesForFiltering" (filtersChanged)="onLeadStatusesFilterChangedDebounce($event)">
    </generic-filter>

    <generic-filter #leadsFilter class="ms-2" [label]="'All States'" [filterHeader]="'States'"
      [filterOptions]="statesForFiltering" (filtersChanged)="onStatesFilterChangedDebounce($event)">
    </generic-filter>

    <generic-filter #leadsFilter class="ms-2" [label]="'All Tags'" [filterHeader]="'Lead Tags'"
      [filterOptions]="tagsForFiltering" [type]="'singleSelect'" (filtersChanged)="onTagsFilterChanged($event)">
    </generic-filter>

    <div class="d-inline-flex ms-3" *ngIf="leadTableConfig.isFilterButtonsVisible">
      <div class="form-check form-switch form-switch-success me-2">
        <label for="filter_ShowAsUnassigned" class="form-check-label"> Show Unassigned</label>
        <input id="filter_ShowAsUnassigned" class="form-check-input" [ngModel]="showUnassignedLeads"
          (ngModelChange)="unassignedChange($event)" type="checkbox" />
      </div>

      <div class="form-check form-switch form-switch-success me-2">
        <label for="filter_ShowAsArchive" class="form-check-label"> Show Archived</label>
        <input id="filter_ShowAsArchive" class="form-check-input" [ngModel]="selectedFilters.showArchived"
          (ngModelChange)="showAsArchiveChange($event)" type="checkbox" />
      </div>

      <div class="form-check form-switch form-switch-success me-2">
        <label for="filter_ShowInactiveUsers" class="form-check-label"> Show Inactive Users</label>
        <input id="filter_ShowInactiveUsers" class="form-check-input" [ngModel]="showInactiveUsers"
          (ngModelChange)="showInactiveUsersChange($event)" type="checkbox" />
      </div>

      <div class="form-check form-switch form-switch-success me-2">
        <label for="editModeToggle" class="form-check-label">Power User Mode</label>
        <input id="editModeToggle" class="form-check-input" [(ngModel)]="inPowerUserMode" type="checkbox" checked
          (change)="inPowerUserModeChange()" />
      </div>
    </div>
    <div class="btn-group">
      <button type="button" class="btn btn-soft-danger btn-clear-all ms-2 h-xs-fit-content" title="Clear All"
        (click)="clearAll()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary btn-mobile hide-on-mobile" *ngIf="leadTableConfig.isNewButtonVisible"
    style="width: 7rem" (click)="onAddNewLeadClicked()"><i class="fas fa-plus me-2"></i>New Lead
  </button>
  <div class="btn-group-left d-flex mt-1 ms-1 hide-on-mobile">
    <button class="btn tippy-btn m-left-1 btn-mobile"
      [ngClass]="{'btn-success btn-success-hover button-item-text': intervalConfig?.intervalTime > 0, 'btn-secondary btn-secondary-point-event': !intervalConfig?.intervalTime || intervalConfig?.intervalTime == 0}"
      type="button" (click)="onRefreshClicked()" style="width: 10rem">

      <span *ngIf="intervalConfig?.intervalTime > 0 else refreshDisabled">
        <i class="fas fa-sync me-2"></i>Auto Refresh: {{masterRefreshCounterDisplay}}
      </span>
      <ng-template #refreshDisabled>
        <span style="cursor: pointer;">
          Auto Refresh Disabled
        </span>
      </ng-template>
    </button>
    <button class="btn btn-outline-secondary tippy-btn m-left-1"
      (click)="$event.stopPropagation(); openIntervalSettingsDialog()">
      <i class="fas fa-wrench"></i>
    </button>
  </div>
</ng-template>

<ng-template #mainContent>
  <div class="accordion">
    <div class="accordion-item">
      <h5 class="accordion-header m-0" id="headingOne">
        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
          (click)="isMetricsOpened = true">
          My Lead Metrics
        </button>
      </h5>

      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
        <div class="accordion-body">
          <lead-metrics *ngIf="isMetricsOpened" [filters]="selectedFilters"></lead-metrics>
        </div>
      </div>
    </div>
  </div>

  <lead-table *ngIf="!inPowerUserMode && webPreferences" 
    #leadTable [showUnassignedLeads]="showUnassignedLeads"
    [exportOnlyColumnsMapping]="exportOnlyColumnsMapping" 
    [filters]="selectedFilters"
    [leadTableConfig]="leadTableConfig" 
    [webPreferences]="webPreferences"
    (editRequestedForLead)="onEditRequestedForLead($event)">
  </lead-table>
  <power-user-lead-table *ngIf="inPowerUserMode" 
    #powerUserLeadTable 
    [showUnassignedLeads]="showUnassignedLeads"
    [filters]="selectedFilters"
    [leadTableConfig]="leadTableConfig" 
    [webPreferences]="webPreferences"
    (editRequestedForLead)="onEditRequestedForLead($event)">
  </power-user-lead-table>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarMiddleContentTemplate]="pageActionBarMiddle"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #addLeadEditorDrawer [title]="'New Lead'" [name]="'addLeadEditorDrawer'" [templateRef]="newLeadContentRef"
  [options]="newLeadDrawerOptions">
</drawer>
<ng-template #newLeadContentRef>
  <lead-editor #leadEditor [leadId]="leadToEdit ? leadToEdit : null" (newLeadAdded)="onNewLeadAdded($event)"
    (closeDrawer)="onLeadEditorDrawerClosed()">
  </lead-editor>
</ng-template>

<drawer #editLeadEditorDrawer [title]="'Editing Lead'" [name]="'editLeadEditorDrawer'"
  [templateRef]="editLeadContentRef" [options]="editLeadDrawerOptions">
</drawer>
<ng-template #editLeadContentRef>
  <app-view-lead-drawer [leadToEdit]="leadToEdit" [isDrawer]="true">
  </app-view-lead-drawer>
</ng-template>