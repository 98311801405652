export class Quote {
    lockPeriod: number;
    baseRate: number;
    basePrice: number;
    adjustedRate: number;
    adjustedPrice: number;
    totalPriceAdjustment: number;
    totalRateAdjustment: number;
    totalMarginAdjustment: number;
    totalSrpAdjustment: number;
    apr: number;
    pricingLastUpdated: string;
    lockExpirationDate: string;
    margin: number;
    principalAndInterest: number;
    monthlyMi: number;
    monthlyInsurance: number;
    monthlyTaxes: number;
    totalPayment: number;
    totalCredit: number;
    loCompensationDollars: number;
    loCompensationPercent: number;
    borrowerPaidClosingCostDollars: number;
    currentFeeCreditDollar: number;
    totalClosingCost: number;
    totalFeesDollars: number;
    totalFeesPercent: number;
    discountDollars: number;
    discountPercent: number;
    rebateDollars: number;
    rebatePercent: number;
    originationDollars: number;
    originationPercent: number;
    lenderFeesDollars: number;
    lenderFeesPercent: number;
    thirdPartyFeesDollars: number;
    thirdPartyFeesPercent: number;
    qmStatus: String[];
    adjustments: Adjustment[];
}

export class Adjustment {
    pricingAdjustmentId: number;
    reason: string;
    description: string;
    adjustmentType: AdjustmentType;
    valueType: AdjustmentValueType;
    isHidden: boolean;
    amount: number;
    applicationId: number;

    companyId: number;
    insertedBy: string;
    dateInserted: Date;
    updatedBy: string;
    dateUpdated: Date;
}


export enum AdjustmentValueType {
    Point = 'Point',
    Rate = 'Rate',
  }

export enum AdjustmentType
{
    ParameterValueAdjustor = 'ParameterValueAdjustor',
    ProgramNameModifier = 'ProgramNameModifier',
    RateGridModifier = 'RateGridModifier',
    LLPA = 'LLPA',
    Sub = 'Sub',
    Margin = 'Margin',
    MSR = 'MSR',
    TIER = 'TIER',
    OtherLLPA = 'OtherLLPA',
    AgencyLLPA = 'AgencyLLPA',
    AllPriceType = 'AllPriceType',
    CompPlan = 'CompPlan',
    ToFee = 'ToFee',
    PricingException = 'PricingException',
    LockExtension = 'LockExtension',
    CapAdjustment = 'CapAdjustment',
    RME = 'RME',
    Eligibility = 'Eligibility',
    PointRounding = 'PointRounding',
    DescrptiveAdjustment = 'DescrptiveAdjustment',
}