import { MessageDirection } from "src/app/models/chat-sms.model";

export class VoiceHistory {
  voiceHistoryId: number;
  callGuid: string;
  callIndex: number;
  direction: MessageDirection;
  disposition: DialerDisposition;
  startDate: Date;
  endDate: Date;
  durationInSeconds: number;
  thirdPartyConferenceId: string;
  thirdPartyCallId: string;
  from: string;
  fromCity: string;
  fromState: string;
  fromZip: string;
  to: string;
  toCity: string;
  tostate: string;
  toZip: string;
  assignedUserId: string;
  warmTransferredToVoiceHistoryId: number;
  warmTransferredToUserId: string;
  callRecorded: boolean;
  coachingCall: boolean;
  dataSynced: boolean;
  syncAttempts: number;
  dialListRecordId: number;
  contactListId: number;
  leadId: number;
  applicationId: number;
  borrowerId: number;
  agentId: number;
  creditMonitoringDataId: number;
  externalCompanyId: number;
  userCompanyGuid: string;
  contactListRecordId: number;
  conversationId: number;

  recordFirstName: string;
  recordLastName: string;

  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}

export enum DialerDisposition {
  Busy = "Busy",
  Connected = "Connected",
  Voicemail = "Voicemail",
  WarmTransferred = "WarmTransferred"
}
