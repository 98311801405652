import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { LookupType, Question } from 'src/app/models/question.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ExpressionService } from 'src/app/modules/expression-builder/services/expression.service';
import { CheckListService } from 'src/app/services/check-list.service';
import { ContactListService } from 'src/app/services/contact-list.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ContactList, ContactListColumnDefinition, ContactListType } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { FunctionEditorWithParametersConfig } from 'src/app/shared/components/function-editor-with-context-params/function-editor-with-context-params.component';
import { QuestionAnswersDialogComponent } from '../../dialogs/question-answers-dialog/question-answers-dialog.component';
import { combineLatest, finalize, forkJoin, of, Subscription } from 'rxjs';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'upsert-checklist-question',
  templateUrl: './upsert-question.component.html',
  styleUrls: ['./upsert-question.component.scss']
})
export class UpsertQuestionComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('questionForm') questionForm: NgForm | undefined;

  @ViewChild(QuestionAnswersDialogComponent) questionAnsList: QuestionAnswersDialogComponent;

  @Input()
  set question(value: Question) {
    if (value) {
      if (!value.questionType) {
        value.questionType = null;
      }
      if (!value.mortgageFunctionExpression) {
        value.mortgageFunctionExpression = null;
      }
      if (!value.borrowerFunctionExpression) {
        value.borrowerFunctionExpression = null;
      }
      value.expressionHtml = this.getExpressionAsHtml(value.expressionGroup);
      this._question = value;
      this.questionData = _.cloneDeep(value);
      this.borrowerFunctionExpression = this.questionData.borrowerFunctionExpression;
      this.mortgageFunctionExpression = this.questionData.mortgageFunctionExpression;

    } else {
      this._question = null;
    }
  }
  @Input() companyId: number;

  @Input()
  set globalConfig(value: GlobalConfig) {
    if (value) {
      this.expressionService = new ExpressionService(value, this._enumService);
    }
  }
  @Output() close: EventEmitter<Question> = new EventEmitter<Question>();

  isSaving: boolean = false;
  expressionInfo: any = {};
  expressionService: ExpressionService;
  appContactListFields: ContactListColumnDefinition[];

  mortgageFunctionConfig: FunctionEditorWithParametersConfig[] = null;
  borrowerFunctionConfig: FunctionEditorWithParametersConfig[] = null;

  borrowerFunctions: Array<EnumerationItem> = [];
  selectableQuestionTypes: EnumerationItem[] = [];
  lookupTypes: EnumerationItem[] = [];
  questionData: Question = new Question();
  loadingMortgageFunctions: boolean;
  loadingBorrowerFunctions: boolean;
  loadingQuestionTypes: boolean;

  mortgageFunctionExpression: string = "";
  borrowerFunctionExpression: string = "";

  validatingMortExpression: boolean;
  mortExpressionValid: boolean | null = null;

  validatingBorrExpression: boolean;
  borrExpressionValid: boolean | null = null;

  borrowerCharacteristicList: Array<EnumerationItem> = [];
  loanCharacteristicList: Array<EnumerationItem> = [];
  tasks: Array<EnumerationItem> = [];
  private _question: Question;

  get question(): Question {
    return this._question;
  }

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _checkListService: CheckListService,
    private readonly _notifyService: NotificationService,
    private readonly _contactListService: ContactListService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _enumService: EnumerationService,
    public activeModal: NgbActiveModal
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.borrowerCharacteristicList = context.globalConfig.borrowerCharacteristics.map(borrChar => ({
        value: borrChar.taskCategoryId,
        name: borrChar.enabledChannels && borrChar.enabledChannels !== 'Disabled'
          ? `${borrChar.taskCategoryName} (${borrChar.enabledChannels})`
          : borrChar.taskCategoryName
      }));
      this.loanCharacteristicList = context.globalConfig.loanCharacteristics.map(loanChar => ({
        value: loanChar.taskCategoryId,
        name: loanChar.enabledChannels && loanChar.enabledChannels !== 'Disabled'
          ? `${loanChar.taskCategoryName} (${loanChar.enabledChannels})`
          : loanChar.taskCategoryName
      }));
      this.tasks = context.globalConfig.tasks.map(task => ({
        value: task.taskId,
        name: task.channel
          ? `${task.taskName} (${task.channel})`
          : task.taskName
      }));
    });
    this.getLookupTypes();
    this.getQuestionTypes();
    this.getMortgageFunctions();
    this.getBorrowerFunctions();
    this.getContactList();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  onMortgageFunctionChanged = (functionExpression: string) => {
    this.questionData.mortgageFunctionExpression = functionExpression;
  }

  onBorrowerFunctionChanged = (functionExpression: string) => {
    this.questionData.borrowerFunctionExpression = functionExpression;
  }

  private getExpressionAsHtml(expressionGroup: ExpressionGroup): string {
    if (!expressionGroup) {
      return;
    }
    return this.expressionService.getExpressionHtml(expressionGroup, this.appContactListFields || []);
  }

  private getContactList() {
    this._spinner.show();
    this._contactListService.getContactList()
      .subscribe({
        next: (response: Array<ContactList>) => {
          const selectedContactList = response.find(contact => contact.contactListType === ContactListType.Application && contact.isCustom === false);
          this.appContactListFields = this._contactListService.setContactListColumns(selectedContactList?.columns);
          this.expressionInfo.displayFields = this.appContactListFields;
          this.expressionInfo.contactListId = selectedContactList.contactListId;
          this.expressionInfo.isContactListCustom = false;
          this.questionData.expressionHtml = this.getExpressionAsHtml(this.questionData.expressionGroup);
          this.expressionInfo.text = this.questionData.expressionHtml;
          this.expressionInfo.group = this.questionData.expressionGroup;
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to load contact list', "Error!");
        }
      }).add(() => {
        this._spinner.hide();
      });
  }

  getQuestionTypes = () => {
    //hard coding question types to only MultiSelect,SelectList and Value options only
    //because other types are not implmented yet.
    this.selectableQuestionTypes = [{
      name: "Multi Select",
      value: "MultiSelect"
    }, {
      name: "Select List",
      value: "SelectList"
    }, {
      name: "Value",
      value: "Value"
    }, {
      name: "Trigger Only",
      value: "TriggerOnly"
    }];
  }

  getLookupTypes = () => {
    for (let enumMember in LookupType) {
      let name = this.getEnumNameWithoutNone(LookupType[enumMember]);
      if (name) {
        this.lookupTypes.push({ name: name, value: LookupType[enumMember] });
      }
    }
  }

  getMortgageFunctions = () => {
    this.loadingMortgageFunctions = true;
    this._checkListService.getMortgageFunctions()
      .subscribe({
        next: (mortFuncs) => {
          const mortgageFunctionConfig = new FunctionEditorWithParametersConfig();
          mortgageFunctionConfig.triggerCharacter = '[';
          mortgageFunctionConfig.triggerKeyCode = 219;
          mortgageFunctionConfig.parameters = mortFuncs.map(mf => ({
            id: `[${mf}]`,
            text: `[${mf}]`
          }));
          this.mortgageFunctionConfig = [mortgageFunctionConfig];
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to get mortgage functions.', "Error");
        }
      }).add(() => {
        this.loadingMortgageFunctions = false;
      });
  }

  getBorrowerFunctions = () => {
    this.loadingBorrowerFunctions = true;
    this._checkListService.getBorrowerFunctions()
      .subscribe({
        next: (borrFuncs) => {
          const borrowerFunctionConfig = new FunctionEditorWithParametersConfig();
          borrowerFunctionConfig.triggerCharacter = '[';
          borrowerFunctionConfig.triggerKeyCode = 219;
          borrowerFunctionConfig.parameters = borrFuncs.map(mf => ({
            id: `[${mf}]`,
            text: `[${mf}]`
          }));
          this.borrowerFunctionConfig = [borrowerFunctionConfig];
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to get borrower functions.', "Error");
        }
      }).add(() => {
        this.loadingBorrowerFunctions = false;
      });
  }

  getEnumNameWithoutNone = (enumValue: string): string => {
    enumValue = enumValue != "None" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  validateExpression = (type: string) => {
    if (type === 'Mortgage') {
      if (this.validatingMortExpression || !this.questionData.mortgageFunctionExpression) {
        return;
      }
      this.mortExpressionValid = null;
      this.validatingMortExpression = true;
      this._checkListService.getMortgageFunctionsTest(this.questionData.mortgageFunctionExpression)
        .pipe(finalize(() => this.validatingMortExpression = false))
        .subscribe({
          next: (isValid) => {
            this.mortExpressionValid = isValid;
          },
          error: (err) => {
            this._notifyService.showError(err?.message || 'Unable to validate expression', 'Error!');
          }
        });
    } else {
      if (this.validatingBorrExpression || !this.questionData.borrowerFunctionExpression) {
        return;
      }
      this.borrExpressionValid = null;
      this.validatingBorrExpression = true;
      this._checkListService.getBorrowerFunctionsTest(this.questionData.borrowerFunctionExpression)
        .pipe(finalize(() => this.validatingBorrExpression = false))
        .subscribe({
          next: (isValid) => {
            this.borrExpressionValid = isValid;
          },
          error: (err) => {
            this._notifyService.showError(err?.message || 'Unable to validate expression', 'Error!');
          }
        });
    }
  }

  save = () => {
    if (this.questionForm) {
      this.questionForm.form.markAllAsTouched();
      if (this.questionForm.form.valid) {
        this.isSaving = true;
        if (!this.questionData.mortgageFunctionExpression && !this.questionData.borrowerFunctionExpression) {
          this.upsertQuestion();
          return;
        }
        let observables = [];
        if (this.questionData.mortgageFunctionExpression) {
          observables.push(this._checkListService.getMortgageFunctionsTest(this.questionData.mortgageFunctionExpression));
        } else {
          observables.push(of(true))
        }
        if (this.questionData.borrowerFunctionExpression) {
          observables.push(this._checkListService.getBorrowerFunctionsTest(this.questionData.borrowerFunctionExpression));
        } else {
          observables.push(of(true))
        }
        forkJoin(observables)
          .subscribe({
            next: (response: boolean[]) => {
              this.mortExpressionValid = response[0];
              this.borrExpressionValid = response[1];
              if (this.mortExpressionValid && this.borrExpressionValid) {
                this.upsertQuestion();
              } else {
                this.isSaving = false;
                this._notifyService.showError('Please verify functions expression before saving.', 'Error!');
              }
            }, error: err => {
              this.isSaving = false;
              this._notifyService.showError(err?.message || 'unable to validate expressions', "Error!");
            }
          });

      }
    }
  }

  private upsertQuestion = () => {
    let observable;
    if (this.questionData.questionId) {
      observable = this._checkListService.updateQuestion(this.questionData);
    }
    else {
      observable = this._checkListService.insertQuestion(this.questionData);
    }

    observable
      .subscribe({
        next: (resp) => {
          if (this.questionData.questionId) {
            this._notifyService.showSuccess("Update done successfully", "Success");
          }
          else {
            this.questionData = resp;
            this._notifyService.showSuccess("Insert done successfully", "Success");
          }
          if ([LookupType.TriggerOnly, LookupType.Value].includes(this.questionData.questionType)) {
            this.close.emit(this.questionData);
            return;
          }
          this.insertNewlyCreatedQuestionAnswers(this.questionData.questionId);
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to Save changes.', "Error");
        }
      }).add(() => {
        this.isSaving = false;
      });
  }

  private insertNewlyCreatedQuestionAnswers = (newlyCreatedQuestionId: number) => {
    const unsavedAnswers = this.questionAnsList.answers
      .filter(ans => ans.questionAnswerId < 0)
      .map(ans => {
        ans.questionAnswerId = null;
        ans.questionId = newlyCreatedQuestionId;
        return this._checkListService.insertQuestionAnswer(ans);
      });
    if (unsavedAnswers.length === 0) {
      this.close.emit(this.questionData);
    }
    const combined = combineLatest(unsavedAnswers);
    combined.pipe(finalize(() => this.close.emit(this.questionData))).subscribe();
  }

  updateExpressionGroup(expressionGroup: ExpressionGroup | null) {
    if (!expressionGroup) {
      this.questionData.expressionGroupId = null;
      this.questionData.expressionGroup = null;
      this.expressionInfo.group = null;
      this.expressionInfo.text = "";
    } else {
      this.questionData.expressionGroup = expressionGroup;
      this.expressionInfo.group = expressionGroup;
      this.expressionInfo.text = this.expressionService.getExpressionHtml(expressionGroup, this.appContactListFields);
    }
  }

  onClose = (): void => {
    this.close.emit();
  }
}
