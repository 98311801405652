import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListColumnDefinition, UserPermissions } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { ExpressionBuilderDialogService } from 'src/app/modules/expression-builder/services/expression-builder-dialog.service';
import { ExpressionService } from 'src/app/modules/expression-builder/services/expression.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ExpressionsService } from 'src/app/services/expressions.service';

@Component({
  selector: 'cloud-platform-expression-editor',
  templateUrl: 'cloud-platform-expression-editor.component.html'
})

export class CloudPlatformExpressionEditorComponent implements OnInit {

  @Input()
  expression: CloudPlatformExpression;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  contactListFields: ContactListFields;

  @Input()
  userPermissions: UserPermissions;

  expressionConfig: any = {};

  companyId: number | null = null;

  private _expressionService: ExpressionService;

  constructor(private readonly _expressionsService: ExpressionsService,
    private readonly _expressionBuilderService: ExpressionBuilderDialogService,
    private readonly _enumsService: EnumerationService,
    private readonly _spinner: NgxSpinnerService) { }

  ngOnInit() {
    this._expressionService = new ExpressionService(this.globalConfig, this._enumsService);
    this.companyId = this.userPermissions.companyId;
    this.setExpressionEditorFields();
    this.initializeExpressionConfig();
  }

  onExpressionChanged = (expressionGroup: ExpressionGroup) => {
    if (!expressionGroup) {
      this._expressionsService.deleteExpression(this.expression.expressionGroupId).subscribe({
        next: () => {
          this.expression.expressionGroupId = null;
          this.expressionConfig.group = null;
          this.expressionConfig.text = null;
        },
        error: (error => {

        })
      })
      return;
    }

    this.expressionConfig.text = this._expressionService.getExpressionHtml(expressionGroup, this.expressionConfig.displayFields);

    let apiCall = null;
    if (!expressionGroup.expressionGroupId) {
      apiCall = this._expressionsService.createExpressionGroup(expressionGroup);
    } else {
      apiCall = this._expressionsService.updateExpressionGroup(expressionGroup);
    }

    this._spinner.show();
    apiCall.subscribe(result => {
      this.expression.expressionGroupId = expressionGroup.expressionGroupId;
      this.expressionConfig.group = result;
      this.expression.expressionGroupId = result.expressionGroupId;
    }, error => {

    }).add(() => {
      this._spinner.hide();
    })
  }

  onExpressionTypeChanged = () => {
    this.setExpressionEditorFields();
    this.initializeExpressionConfig();
    this.expressionConfig.group = null;
    this.expressionConfig.text = "";
  }

  private initializeExpressionConfig = () => {
    this._expressionBuilderService.getExpressionGroup(this.expression.expressionGroupId).subscribe(expressionGroup => {
      this.expressionConfig.group = expressionGroup;
      this.expressionConfig.isContactListCustom = false;
      this.expressionConfig.text = this._expressionService.getExpressionHtml(expressionGroup, this.expressionConfig.displayFields);
    })
  }

  private setExpressionEditorFields = () => {
    if (this.expression.expressionType == '1') {
      this.expressionConfig.displayFields = this.contactListFields.applicationContactListFields;
      this.expressionConfig.contactListId = this.contactListFields.applicationContactListId;
    } else {
      this.expressionConfig.displayFields = this.contactListFields.leadContactListFields;
      this.expressionConfig.contactListId = this.contactListFields.leadContactListId;
    }
  }
}

export class CloudPlatformExpression {
  expressionType: string;
  expressionGroupId: number;
  campaignName: string;
}

export class ContactListFields {
  applicationContactListFields: ContactListColumnDefinition[] = [];
  applicationContactListId: number;
  leadContactListFields: ContactListColumnDefinition[] = [];
  leadContactListId: number;
  constructor(applicationContactListId: number, applicationContactListFields: ContactListColumnDefinition[],
    leadContactListId: number, leadContactListFields: ContactListColumnDefinition[]) {
    this.applicationContactListFields = applicationContactListFields;
    this.leadContactListFields = leadContactListFields;
    this.leadContactListId = leadContactListId;
    this.applicationContactListId = applicationContactListId;
  }
}


