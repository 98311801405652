<div class="card-group mb-3">
    <div class="card tpo-report-card text-center">
        <div class="card-header">
            <h4 class="card-title">{{title}}</h4>
            <p class="card-text pt-2"><small class="text-muted">{{description}}</small></p>
        </div>
        <div class="chart-card">
            <div class="chart-card__count">
                <span [ngStyle]="{color: getColor()}">
                    <div>
                        <b>{{value}}</b>
                    </div>
                </span>
            </div>
        </div>
        <div class="card-body">
            <p class="card-text pt-2 chart-card__text"><small class="text-muted">{{text}}</small></p>
        </div>
    </div>
</div>
