import { Component, Injector, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DocumentType, ThirdPartyCredential } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { Constants } from "../../../../../services/constants";
import { ScopeType } from '../integrations.component';
import { TwnIncomeEmploymentVerificationModal } from "./twn-income-employment-verification-modal/twn-income-employment-verification-modal.component";
import { TruvIncomeEmploymentVerificationModal } from './truv-income-employment-verification-modal/truv-income-employment-verification-modal.component';
import { VendorPickerModalComponent } from '../vendor-picker-modal/vendor-picker-modal.component';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';

@Component({
  selector: 'income-employment-verification',
  templateUrl: './income-employment-verification.component.html'
})
export class IncomeEmploymentVerificationComponent extends ApplicationContextBoundComponent {
  @Input() documentTypes: Array<DocumentType>;
  @Input() digitalEmploymentVerificationEnabled: Configuration;
  @Input() incomeEmploymentVerificationCredentials: Array<ThirdPartyCredential>;
  @Input() scope: ScopeType;

  isSaving: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly route: ActivatedRoute
  ) {
    super(injector);
  }


  openCredentialModal(credential: ThirdPartyCredential): void {
    let modalComponent;

    if (credential.vendorName == "TWN") {
      modalComponent = TwnIncomeEmploymentVerificationModal;
    }
    else if (credential.vendorName == "Truv") {
      modalComponent = TruvIncomeEmploymentVerificationModal;

      if(credential.credentialType != "VOI"){
        credential.credentialType = "VOI";
      }
    }

    const modalRef = this._modalService.open(modalComponent, Constants.modalOptions.large);
    modalRef.componentInstance.documentTypes = this.documentTypes;
    modalRef.componentInstance.credential = credential;
    modalRef.componentInstance.scope = this.scope;

    const userCompanyGuid = this.route.snapshot.params.id || this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
    modalRef.componentInstance.userId = this.scope === 'User' ? userCompanyGuid : null;

    modalRef.result.then(
      (result) => {
        const index = this.incomeEmploymentVerificationCredentials.findIndex(el => el.credentialId === result.credentialId);
        if (index > -1) {
          this.incomeEmploymentVerificationCredentials[index] = result;
        } else {
          this.incomeEmploymentVerificationCredentials.push(result);
        }
      },
      () => { }
    );
  }

  openVendorPicker = (credential: ThirdPartyCredential) => {
    const modalRef = this._modalService.open(VendorPickerModalComponent, { ...Constants.modalOptions.medium, scrollable: false });
    modalRef.componentInstance.type = "VOE/VOI";
    modalRef.componentInstance.vendors = this._thirdPartyCredentialsService.getVendors(credential);

    modalRef.result.then(
      (selectedVendor: string) => {
        credential.vendorName = selectedVendor;
        this.openCredentialModal(credential);
      },
      () => { }
    );
  }
}
