<div *ngIf="!showTab" class="mt-3">
    <identifying-documents-table [borrower]="selectedBorrower"></identifying-documents-table>
</div>

<div *ngIf="showTab" class="mt-3">
    <ul class="nav nav-tabs d-flex justify-content-between" role="tablist" id="borrower-tabs"
        style="border-bottom: 1px solid #d5d9d9;">
        <div class="d-flex">
            <li *ngFor="let borrowers of borrowerGroups" class="nav-item borrower-group group-button-list me-2">
                <ng-container *ngFor="let borrower of borrowers; let i = index; let last = last">
                    <a class="nav-link" data-bs-toggle="tab" href="#borr_identifyingDocuments_{{borrower.borrowerId}}" role="tab"
                        (click)="selectBorrower(borrower)" aria-selected="true"
                        [ngClass]="{ active: isSelectedBorrower(borrower), 'pe-2': i % 2 == 0 && !last, 'ps-2': i % 2 == 1 }">
                        <span>
                            {{getBorrowerDisplayName(borrower)}}
                        </span>
                    </a>
                    <span *ngIf="!last" class="vertical-divider">
                        <span></span>
                    </span>
                </ng-container>
            </li>
        </div>
    </ul>
    <div class="tab-content my-3">
        <div *ngFor="let borrower of mortgageBorrowers; let index = index" [hidden]="!isSelectedBorrower(borrower)"
            class="tab-pane" [ngClass]="{ active: isSelectedBorrower(borrower)}" id="borr_identifyingDocuments_{{borrower.borrowerId}}"
            role="tabpanel">
            <identifying-documents-table [borrower]="borrower"></identifying-documents-table>
        </div>
    </div>
</div>

<div class="col-sm-12 text-center">
    <button class="btn btn-primary" (click)="onSaveClicked()">
        Save
    </button>
</div>
