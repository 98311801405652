<div class="row mt-1">
	<div class="col-md-12">
		<toggle-configuration [title]="'Enable Appraisal Integration'" [id]="'appraisalOrderingEnabled'"
			[configuration]="appraisalOrderingEnabled"></toggle-configuration>
	</div>
	<div class="col-md-12 mt-1">
		<hr class="mt-3 mb-0" />
	</div>
	<div class="d-flex align-content-end flex-wrap" *ngIf="appraisalOrderingEnabled.value">
		<div class="mt-4 me-2" *ngFor="let credential of appraisalCredentials">
			<credential-item 
				[scope]="scope"
				[credential]="credential" 
				[credentials]="appraisalCredentials"
				(openCredentialModal)="openCredentialModal($event)">
			</credential-item>
		</div>
		<div class="mt-4 me-2">
			<create-credential 
				[scope]="scope"
				[credentialType]="'AppraisalVendor'" 
				[credentials]="appraisalCredentials"
				(openCredentialModal)="openCredentialModal($event)">
			</create-credential>
		</div>
	</div>
	<div class="col-md-12">
		<enable-message *ngIf="appraisalOrderingEnabled.value === 0">
			Enable this feature above!
		</enable-message>
	</div>
</div>