import { LoanDoc } from "../loan";
import { DocumentTemplate } from "../config/document-template.model";
import { CustomTask } from "../task/custom-task.model";
import { Task } from "../task/task.model";
import { FileDto } from "./file-dto.model";

export class LoanDocDashboardTask {
  branchId: number;
  primaryBorrowerId: number;
  fullName: string;
  applicationName: string;
  responsibleParty: string;
  docTypeText: string;
  expirationDate: string;
  docFiles: Array<FileDto> = [];
  reviewParty: string;
  hasTemplateDocument: boolean;
  mailingStreet: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  refNumber: string;
  losLoanDocId: string;
  loanDocTaskId: number;
  taskCategoryId: number;
  leadId: number;
  applicationId: number;
  borrowerId: number;
  agentId: number;
  taskId: number;
  customTaskId: number;
  borrowerCharacteristicId: number;
  loanCharacteristicId: number;
  documentTemplateModelId: number;
  loanDocId: number;
  documentTypeId: number;
  roleId: number;
  userId: string;
  notifyPartyId: string;
  reviewPartyId: string;
  templateDocumentUrl: string;
  helpUrl: string;
  taskType: string;
  taskPriority: string;
  description: string;
  note: string;
  borrowerFacingNote: string;
  requestedBy: string;
  dueDays: number;
  conditionId: string;
  conditionText: string;
  losConditionRefId: string;
  losConditionStatus: string;
  losConditionStatusDate: string;
  losPortalPackageRefId: string;
  thirdPartyIntegrationType: string;
  thirdPartyIntegrationProvider: string;
  taskStatus: string;
  conditionStatus: string;
  conditionType: string;
  requestBorrower: boolean;
  condition: boolean;
  requiresReview: boolean;
  requestDate: string;
  dueDate: string;
  snoozeDate: string;
  submittedDate: string;
  acknowledgementDate: string;
  completeDate: string;
  followUpDate: string;
  active: boolean;
  printInternally: boolean;
  printExternally: boolean;
  existingLenderId: number;
  hoiAgentId: number;
  titleAgentId: number;
  escrowAgentId: number;
  appraisalAgentId: number;
  alwaysShowPending: boolean;
  taskModel: Task;
  customTaskModel: CustomTask;
  loanDocModel: LoanDoc;
  documentTemplateModel: DocumentTemplate;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  multipleBorrower?: number[];
  checklistId: number;
}
