<ng-container *ngIf="!isLoading; else loading">
    <form #voicemailForm="ngForm" novalidate id="upsert-voicemail-form" name="upsertVoicemailForm">
        <div class="p-3">
            <div class="mb-3">
                <label class="form-label" for="file-name">File Name</label>
                <input type="text" class="form-control" name="file-name" [(ngModel)]="voicemail.name"
                    [ngClass]="{'is-invalid': fileNameField && fileNameField.touched && fileNameField.invalid}"
                    #fileNameField="ngModel" required>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mb-3 row">
                            <file-upload id="file-upload-container" [(ngModel)]="uploadedFiles" name="files"
                                [ngClass]="{'is-invalid': fileRequiredError}" (ngModelChange)="onFileUploadChanged()"
                                accept=".mp3,.wav,.m4a">
                            </file-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <button class="btn btn-soft-secondary me-2" (click)="cancel()">
                    Cancel
                </button>
                <button class="btn btn-soft-primary me-2" type="button" (click)="save()" [disabled]="saveInProgress">
                    <span *ngIf="saveInProgress" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    <i *ngIf="!saveInProgress" class="fa fa-save"></i>
                    Save
                </button>
            </div>
        </div>
    </form>
</ng-container>
<ng-template #loading>
    <div class="offset-lg-0 offset-xxl-1 offset-xxxl-2 col-lg-12 col-xxl-10 col-xxxl-8">
        <loading-indicator [customClass]="'loading-indicator'" [loadingMessage]="'Please Wait ...'">
        </loading-indicator>
    </div>
</ng-template>