import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { EmailConfigurationComponent } from './components/email-configuration.component';
import { EmailSettingsComponent } from './components/email-settings/email-settings.component';
import { EmailTemplatesComponent } from './components/email-templates/email-templates.component';
import { EmailConfigRoutingModule } from './email-configuration-routing.module';

@NgModule({
  imports: [
    SharedModule,
    EmailConfigRoutingModule,
    CorrespondenceModule,
  ],
  declarations: [
    EmailTemplatesComponent,
    EmailSettingsComponent,
    EmailConfigurationComponent
  ],
  exports: [
    EmailConfigurationComponent,
  ],
  providers: []
})
export class EmailConfigModule {
  constructor() { }
}
