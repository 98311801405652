<div class="card" [ngClass]="{'border-0': isDrawer}">
  <div class="card-body">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active">
          <span> Contact Info </span>
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <div id="contacttInfo" class="tab-pane p-3 active" role="tabpanel">
        <borrower-details
          (closeDrawer)="onCloseDrawer()"
          (savedBorrower)="onBorrowerSaved($event)">
        </borrower-details>
      </div>
    </div>
  </div>
</div>
