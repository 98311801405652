import { CalculatedFeeTypeEnum } from "./calculated-fee-type.enum";
import { FeeProrationTypeEnum } from "./fee-proration-type.enum";

export class FeeCalculatedValues {
  isChargeUserEditable: boolean;
  calculatedFeeType: null | CalculatedFeeTypeEnum;
  totalFee?: number;
  borrowerTotal?: number;
  lenderTotal?: number;
  sellerTotal?: number;
  thirdPartyTotal?: number;
  prorationAmount?: number;
  prorationType: null | FeeProrationTypeEnum;
  prorationStartDate: Date;
  prorationEndDate: Date;
  percentOfAmount?: number;
  sellerConcessions: number;
  totalFeePercent?: number;

  estimatedClosingDate: string;
  paidThroughDate?: string;
  disbursementDate: string;
  firstPaymentDate: string;
  oddDays:number;
  interestCredit: boolean;
  paidOnClosing: number;
  months: number;
  monthlyFee: number;
  taxesDueBeforeFirstPayment: number;
  propertyTaxPaymentOption: string;

  borrowerPaidAtClosing: number;
  lenderPaidAtClosing: number;
  sellerPaidAtClosing: number;
  thirdPartyPaidAtClosing: number;
  totalPaidAtClosing: number;

  calculationMethod?: PerDiemCalculationMethodType;
}

export enum PerDiemCalculationMethodType {
  Item360 = "Item360",
  Item365 = "Item365",
  Item365Or366 = "Item365Or366",
}
