<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #incomeForm="ngForm" novalidate id="incomeForm" name="incomeForm" >
    <div class="loan-app main">
      <div class="grid-layout">
        <div class="grid-layout-row" *ngFor="let income of incomes; let i = index">
          <urla-dropdown [label]="i == 0 ? 'Income Type' : null" class="col-sm-3" name="incomeType{{i}}" [readonly]="true"
            [(ngModel)]="income.typeOfIncome" [options]="incomeTypes"></urla-dropdown>
          <urla-currency-input [allowNegative]="false" [label]="i == 0 ? 'Amount' : null" class="col-sm-3" name="amount{{i}}"
            [(ngModel)]="income.amount" (ngModelChange)="onAmountChanged(income)"></urla-currency-input>
          <urla-dropdown [label]="i == 0 ? 'Pay Period' : null" class="col-sm-2" name="payPeriod{{i}}" [required]="true"
            [(ngModel)]="income.payPeriod" (ngModelChange)="onAmountChanged(income)" [options]="incomePayPeriods"></urla-dropdown>
          <urla-digits-input name="hoursPerWeek{{i}}" [label]="i == 0 ? 'Hrs/Week' : null" class="col-sm-1"
            [(ngModel)]="income.hoursPerWeek" (ngModelChange)="onAmountChanged(income)"
            [required]="income.payPeriod === PayPeriod.Hourly" [readonly]="income.payPeriod !== PayPeriod.Hourly">
          </urla-digits-input>
          <urla-currency-input [label]="i == 0 ? 'Monthly' : null" class="col-sm-2"
            [ngStyle]="{'padding-top': i != 0 ? '10px' : '0px', 'padding-left': i != 0 ? '10px' : '0px' }"
            [readonly]="true" name="monthlyIncome{{i}}" [showBorder]="false"
            [(ngModel)]="income.monthlyValue"></urla-currency-input>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      Total monthly income: <b>{{ totalIncome | currency }}</b>
    </div>
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
        <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
</div>
