import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Borrower } from 'src/app/models';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { ChatService } from 'src/app/services/chat.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FileAttachment } from 'src/app/shared/components/send-mms/send-mms.component';
import { CorrespondenceService } from '../../services/correspondence.service';
import { SendEmailComponent } from '../send-email/send-email.component';
import { SendSmsComponent } from '../send-sms/send-sms.component';

@Component({
  selector: 'send-email-sms-dialog',
  templateUrl: 'send-email-sms-dialog.component.html',
  styleUrls: ['send-email-sms-dialog.component.scss']
})
export class SendEmailSmsDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("emailSender")
  emailSender: SendEmailComponent;

  @ViewChild("smsSender")
  smsSender: SendSmsComponent;

  @Input()
  applicationId: number;

  @Input()
  attachments: DocFile[] = [];

  @Input()
  body: string = "";

  @Input()
  to: string = "";

  @Input()
  isShowOnlyEmail: boolean = false;

  @Input()
  isShowOnlyMMS: boolean = false;

  @Input()
  isModal: boolean = true;

  @Input()
  showBorrowerPicker: boolean = true;

  @Output()
  sentSuccessfully: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  borrowers: Borrower[];

  sendEmail: boolean = true;

  fileAttachments: FileAttachment[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _notificationService: NotificationService,
    private readonly _loanService: LoanService,
    private readonly injector: Injector,
    private readonly _chatService: ChatService) {
    super(injector);
  }

  ngOnInit() {
    this.applicationContextService.context.subscribe(context => {
      if (!this.applicationId && context.application) {
        this.applicationId = context.application.applicationId;
        this.borrowers = context.borrowers;
      }
      if (!this.borrowers) {
        this._loanService.getBorrowers(this.applicationId).subscribe((borrowers) => {
          this.borrowers = borrowers as Borrower[];
        });
      };
    });
  }

  onSendClicked = () => {
    if (this.sendEmail) {
      this.doSendEmail();
      return;
    }
    this.sendSms();
  }

  onCancelClicked = () => {
    this.activeModal.dismiss();
    this.cancelled.emit();
  }

  private doSendEmail = () => {
    if (!this.emailSender.validate()) {
      return;
    }
    let email = this.emailSender.getEmailToSend();
    this._spinner.show();
    this._correspondenceService.sendLoanEmail(email, this.emailSender.appendEmailSignature, null,
      this.emailSender.impersonationFrom, this.emailSender.impersonationFromUserId).subscribe(() => {
        this._spinner.hide();
        this.activeModal.close();
        this._notificationService.showSuccess("Email has been successfully sent.", "Success!");
        this.sentSuccessfully.emit();
      }, error => {
        this._spinner.hide();
      });
  }

  private sendSms = () => {
    if (!this.smsSender.validate()) {
      return;
    }
    let sms = this.smsSender.getSmsToSend();
    this._spinner.show();
    this._chatService.queueSms(sms).subscribe(() => {
      this._spinner.hide();
      this.activeModal.close();
      this._notificationService.showSuccess("SMS has been successfully sent.", "Success!");
      this.sentSuccessfully.emit();
    }, error => {
      this._spinner.hide();
    });
  }
}
