<form id="lpaForm" #lpaForm="ngForm" name="lpaForm">
  <div class="modal-header">
    <h5 class="modal-title"> Loan Product Advisor (LPA) </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
  </div>
  <div class="modal-body scrollable-modal">
    <div class="alert alert-danger border-0" role="alert" *ngIf="!lpaParams.isSuccessful && lpaParams.status">
      {{ lpaParams.status }}
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> Login Information </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="d-flex">
              <label for="authUserId"> <b> LPA User ID </b> </label>
              <span *ngIf="hasCreditCredentials && !isTPOUser" (click)="overrideLpaCredsClicked()"
                class="text-primary text-decoration-underline cursor ms-3">
                {{ !overrideLpaCredentials ? 'Override Credentials' : 'Use Default' }}</span>
              <div *ngIf="overrideLpaCredentials" class="form-check ms-2">
                <input class="form-check-input" type="checkbox" name="writeAusCredsToUserProfile" autocomplete="off"
                  id="writeAusCredsToUserProfile" [(ngModel)]="lpaParams.writeAusCredsToUserProfile">
                <label class="form-check-label" for="writeAusCredsToUserProfile">
                  Save to user profile
                </label>
              </div>
            </div>

            <input *ngIf="!overrideLpaCredentials" id="lpaUserId" name="lpaUserId" class="form-control" type="password"
              placeholder="" [disabled]="true"
              [value]="randomText" autocomplete="new-password" name="userName">
            <ng-container *ngIf="overrideLpaCredentials">
              <input id="lpaUserId" name="lpaUserId" class="form-control" type="text" required
                [ngClass]="{ 'is-invalid': overriddenUserName && overriddenUserName.touched && overriddenUserName.invalid }"
                placeholder="" [(ngModel)]="lpaParams.ausUsernameOverride" #overriddenUserName="ngModel" autocomplete="new-username"
                name="userName">
              <div class="invalid-feedback"
                *ngIf="overriddenUserName.touched && overriddenUserName.invalid && overriddenUserName.errors?.['required']">
                Please, enter a username.
              </div>
            </ng-container>
          </div>
          <div class="col-md-6 mb-2 position-relative">
            <label for="lpaPasswd"> <b> LPA Password </b> </label>
            <input id="lpaPasswd" name="lpaPasswd" *ngIf="!overrideLpaCredentials" class="form-control"
              type="password" placeholder="" [disabled]="true"
              [value]="randomText" type="password" autocomplete="off" name="password">
            <ng-container *ngIf="overrideLpaCredentials">
              <input id="lpaPasswd" name="lpaPasswd" class="form-control" type="password" placeholder=""
                [(ngModel)]="lpaParams.ausPasswordOverride" #overriddenPassword="ngModel" required
                [ngClass]="{ 'is-invalid': overriddenPassword && overriddenPassword.touched && overriddenPassword.invalid }"
                type="{{showPassword ? 'text' : 'password'}}" autocomplete="new-password" name="password">
              <i *ngIf="overrideLpaCredentials" (click)="showPassword = !showPassword" class="fa fa-eye toggle-password"></i>
              <div class="invalid-feedback"
                *ngIf="overriddenPassword.touched && overriddenPassword.invalid && overriddenPassword.errors?.['required']">
                Please, enter a password.
              </div>
            </ng-container>
          </div>
          <div class="col-md-6">
            <label for="servicerNo"> <b> Seller Servicer Number </b> </label>
            <input id="servicerNo" class="form-control" type="text"
              [hidden]="lpaProperties.nonOriginatingThirdPartyId || lpaProperties.thirdPartyOriginatorId"
              [(ngModel)]="lpaProperties.sellerServicerNumber" name="sellerServicerNumber" disabled>
          </div>
          <div *ngIf="isFHALoan && lpaProperties.fhaLenderId" class="col-md-6 mb-2">
            <label for="lenderId"> <b> FHA Lender ID </b> </label>
            <input id="lenderId" class="form-control" type="text" [(ngModel)]="lpaProperties.fhaLenderId"
              name="fhaLenderId" disabled>
          </div>
          <div *ngIf="isFHALoan && lpaProperties.fhaSponsorId" class="col-md-12 mb-2">
            <label for="sponsorId"> <b> FHA Sponsor ID </b> </label>
            <input id="sponsorId" class="form-control" type="text" [(ngModel)]="lpaProperties.fhaSponsorId"
              name="fhaSponsorId" disabled>
          </div>
          <div class="col-sm-6 mb-2"
            [hidden]="lpaProperties.sellerServicerNumber || lpaProperties.nonOriginatingThirdPartyId">
            <label for="tpoId">TPO Number</label>
            <input id="tpoId" class="form-control" type="text" [(ngModel)]="lpaProperties.thirdPartyOriginatorId"
              name="thirdPartyOriginatorId" disabled>
          </div>
          <div class="col-sm-6" [hidden]="lpaProperties.sellerServicerNumber || lpaProperties.thirdPartyOriginatorId">
            <label for="notpId">Non-Originating Third Party ID</label>
            <input id="notpId" class="form-control" type="text" [(ngModel)]="lpaProperties.nonOriginatingThirdPartyId"
              name="nonOriginatingThirdPartyId" disabled>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header d-flex justify-content-between" [ngClass]="{'card-header-border-radius': !orderCredit}">
        <h4 class="card-title"> {{creditReportingHistory && creditReportingHistory.length > 0 ? "Credit Order"
          :"Order" }} </h4>
        <div class="form-check">
          <input id="orderCredit" class="form-check-input" type="checkbox" [(ngModel)]="orderCredit"
            name="orderCredit">
          <label for="orderCredit"> Order Credit </label>
        </div>
      </div>
      <div class="card-body" *ngIf="orderCredit">
        <div class="row mb-2">
          <div class="col-md-6">
            <label> <b> Credit Provider </b> </label>
            <select id="creditProvider" class="form-select"
              [ngClass]="{ 'is-invalid': creditProvider && creditProvider.touched && creditProvider.invalid }"
              [(ngModel)]="lpaParams.creditRequest.creditVendor" name="creditProvider"
              (ngModelChange)="loadTechnicalAffiliates()" #creditProvider="ngModel" required>
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let creditProvider of creditProviders"
                [ngValue]="creditProvider.name">
                {{creditProvider.displayName}}
              </option>
            </select>
            <div class="invalid-feedback"
              *ngIf="creditProvider.touched && creditProvider.invalid && creditProvider.errors?.['required']">
              Credit provider is required.
            </div>
          </div>
          <div class="col-sm-6"
            *ngIf="technicalAffiliates && technicalAffiliates.length">
            <label for="technicalAffiliate">Technical Affiliate</label>
            <select id="technicalAffiliate" class="form-select" name="technicalAffiliate"
              [(ngModel)]="lpaParams.creditRequest.technicalAffiliateCode">
              <option value="">-- Select One --</option>
              <option *ngFor="let item of technicalAffiliates" value={{item.code}}>
                {{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="row"
          *ngFor="let creditRequest of lpaParams.creditRequest.jointBorrowerCreditRequests; let i = index;">
          <div class="col-md-6">
            <label for="borrower_{{i}}"> <b> {{ borrowerNames[i] }} </b>
            </label>
            <select id="borrowerCredReq{{i}}" class="mb-2 form-select credit__request_type" name="borrowerCredReq{{i}}"
              [ngClass]="{ 'is-invalid': borrowerCredReq && borrowerCredReq.touched && borrowerCredReq.invalid }"
              [(ngModel)]="creditRequest.creditRequestType" (ngModelChange)="onCreditRequestTypeChanged(creditRequest)"
              #borrowerCredReq="ngModel" required>
              <option value="" *ngIf="!creditRequest.creditRequestType">-- Select One --</option>
              <option value="Order" *ngIf="!isTPOUser">Order</option>
              <option value="Reissue">Reissue</option>
              <option value="Reorder" *ngIf="!isTPOUser">Reorder</option>
            </select>
            <div class="invalid-feedback"
              *ngIf="borrowerCredReq.touched && borrowerCredReq.invalid && borrowerCredReq.errors?.['required']">
              Credit request type is required.
            </div>
          </div>
          <div *ngIf="creditRequest.creditRequestType !== 'Order'" class="col-md-6">
            <label> <b> Credit Reference # </b> </label>
            <input class="form-control credit__reference_number" type="text" placeholder="Credit Reference"
              name="borrower_{{i}}" id="borrower_{{i}}"
              [ngClass]="{ 'is-invalid': borrower && borrower.touched && borrower.invalid }"
              [(ngModel)]="creditRequest.creditReferenceNumber"
              autocomplete="off"
              #borrower="ngModel" required />
            <div class="invalid-feedback" *ngIf="borrower.touched && borrower.invalid && borrower.errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="!isTPOUser">
      <div class="card-header d-flex justify-content-between" [ngClass]="{'card-header-border-radius': !enableAssetIncomeVerificationPanel}">
        <h4 class="card-title"> Asset/Income Verification </h4>
        <div class="form-check">
          <input id="assetIncomeVerification" class="form-check-input" type="checkbox"
            [(ngModel)]="enableAssetIncomeVerificationPanel" name="assetIncomeVerification">
          <label for="assetIncomeVerification"> Order Asset/Income Verification </label>
        </div>
      </div>
      <div class="card-body text-end" *ngIf="enableAssetIncomeVerificationPanel">
        <div class="table-responsive text-start">
          <table class="table mb-0 table-centered">
            <thead class="table-light text-center">
              <tr>
                <th> Borrower </th>
                <th> Verification </th>
                <th> Report </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let aiv of lpaParams.verification; let i = index;  trackBy: trackByIndex">
                <td>
                  <ng-select width="100%" name="verification_borrower_{{i}}" bindLabel="name" bindValue="value"
                    class="custom-ng-select" placeholder="Select Borrower" [closeOnSelect]="false" multiple="true"
                    #borrowerField="ngModel" [items]="borrowersList" required
                    [ngClass]="{'is-invalid': borrowerField && borrowerField.touched && borrowerField.invalid}"
                    [(ngModel)]="aiv.borrowerIds" appendTo="body">
                  </ng-select>
                </td>
                <td>
                  <select class="form-select" name="verification_{{i}}" [(ngModel)]="aiv.company"
                    [ngClass]="{'is-invalid': companyField && companyField.touched && companyField.invalid}"
                    #companyField="ngModel" required>
                    <option value="">-- Select One --</option>
                    <option [value]="vComp.value" *ngFor="let vComp of lpaVerificationCompanies" [title]="vComp.name">
                      {{vComp.name}}
                    </option>
                  </select>
                </td>
                <td>
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <input type="text" name="report_{{i}}" class="form-control"
                        [ngClass]="{'is-invalid': reportField && reportField.touched && reportField.invalid}"
                        #reportField="ngModel" [(ngModel)]="aiv.reportNumber" required>
                    </div>
                    <a class="text-danger mt-2 ms-1 cursor" title="Delete" (click)="removeIncomeVerificationRecord(i)">
                      <i class='fa fa-trash'></i>
                    </a>
                  </div>
                </td>
              </tr>
              <tr *ngIf="lpaParams.verification.length === 0">
                <td colspan="3" class="empty-docs">
                  <em>Add verification request using the button below</em>
                </td>
              </tr>
              <tr>
                <button class="btn btn-soft-primary btn-sm mb-1 mt-1" (click)="addAssetIncomeVerificationRecord()">
                  <i class="fas fa-plus"></i> Asset/Income Verification Record
                </button>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> Submission Information </h4>
      </div>
      <div class="card-body">
        <div class="row ms-05 mb-1">
          <div class="form-check">
            <input id="mergeCredit" class="form-check-input" type="checkbox" [(ngModel)]="lpaParams.getMergedCreditReport"
              name="mergeCredit">
            <label for="mergeCredit"> Download Merged Credit Report </label>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6">
            <label for="lpaKeyNo"> <b> LPA Key Number </b> </label>
            <input class="form-control" type="text" id="lpaKeyNo" [(ngModel)]="lpaParams.keyIdentifier" name="lpaKeyNo">
          </div>
          <div class="col-md-6">
            <label for="loanProductId"> <b> Loan Product ID </b> </label>
            <input class="form-control" type="text" id="loanProductId" [(ngModel)]="lpaParams.loanIdentifier"
              name="loanProductId">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for='loanStatus'> <b> Case State Type </b> </label>
            <select id="loanStatus" class="form-select" [(ngModel)]="lpaParams.loanStatus" name="loanStatus">
              <option value="">-- Select One --</option>
              <option value="Application">Application</option>
              <option value="FinalDisposition">Final Disposition</option>
              <option value="PostClosingQualityControl">Post Closing Quality Control</option>
              <option value="Prequalification">Pre-Qualification</option>
              <option value="Underwriting">Underwriting</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="loanProductId"> <b> LPA Lender Branch ID </b> </label>
            <input class="form-control"
              type="text" id="lpaLenderBranchId" name="lpaLenderBranchId" [(ngModel)]="lpaParams.branchId" >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
      (click)="activeModal.dismiss()">Close</button>
    <button type="button" class="btn btn-primary" (click)="submitRequest()">
      Run LPA
    </button>
  </div>
</form>
