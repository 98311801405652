import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss']
})

export class LogoComponent {

  @Input()
  logoUrlsToTry: string[] = [];

  @Input()
  isLinkDisabled: boolean = false;

  get imageUrl() {
    if (this._imageRetries > this.logoUrlsToTry.length - 1) {
      return 'assets/images/logo.png';
    }
    return this.logoUrlsToTry[this._imageRetries];
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  onImageLoaded() {
    this.detach();
  }

  onImageError() {
    this._imageRetries++;
  }

  private _imageRetries: number = 0;

  private detach() {
    this.cdr.detach();
  }
}
