import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProfileService} from '../../../../../profile.service';
import {finalize} from 'rxjs/operators';
import {NotificationService} from '../../../../../../../services/notification.service';
import {TwilioPhoneNumber} from '../twilio-phone-number';
import {MessagingService} from '../messaging-service';

@Component({
    selector: 'purchase-phone-number',
    templateUrl: './purchase-phone-number.component.html',
})
export class PurchasePhoneNumberComponent implements OnInit {

    @Output() phoneNumberSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() messagingServiceSelected: EventEmitter<any> = new EventEmitter<any>();

    phoneNumber: TwilioPhoneNumber;
    messagingService: string;
    areaCode: string = "";
    isTollFree: boolean = false;
    isFetchingNumbers: boolean;
    phoneNumbers: TwilioPhoneNumber[] = [];
    messagingServices: MessagingService[] = [];

    constructor(
        private readonly _profileService: ProfileService,
        private readonly _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.getMessagingServices();
    }

    getPhoneList(): void {
        this.isFetchingNumbers = true;
        this._profileService.getPhoneNumbersList(this.areaCode, this.isTollFree)
            .pipe(finalize(() => {
                this.isFetchingNumbers = false;
            }))
            .subscribe({
                next: (res) => {
                    this.phoneNumbers = res;
                },
                error: err => {
                    this._notificationService.showError(
                        err?.message || 'Unable to purchase phone numbers',
                        'Phone Number Setup'
                    );
                }
            });
    }

    getMessagingServices(): void {
        this._profileService.getMessagingServiceList()
            .subscribe({
                next: (res) => {
                    this.messagingServices = res;
                },
                error: err => {
                    this._notificationService.showError(
                        err?.message || 'Unable to get messaging services',
                        'Phone Number Setup'
                    );
                }
            });
    }
}
