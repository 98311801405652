import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import {
  AbstractValueAccessor,
  MakeProvider,
} from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Constants } from 'src/app/services/constants';
import { getDaysForMonth } from 'src/utils';
import { EditorMode } from '../currency-input/currency-input.component';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? (date.month < 10 ? '0' + date.month : date.month) +
      this.DELIMITER +
      (date.day < 10 ? '0' + date.day : date.day) +
      this.DELIMITER +
      date.year
      : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? (date.month < 10 ? '0' + date.month : date.month) +
      this.DELIMITER +
      (date.day < 10 ? '0' + date.day : date.day) +
      this.DELIMITER +
      date.year
      : '';
  }
}

@Component({
  selector: 'date-input',
  templateUrl: 'date-input.component.html',
  styleUrls: ['date-input.component.scss'],
  providers: [
    MakeProvider(DateInputComponent),
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  viewProviders: [formViewProvider]
})
export class DateInputComponent
  extends AbstractValueAccessor
  implements OnInit, OnChanges {

  @ViewChild('model') model: NgModel;

  @ViewChild('control')
  input: ElementRef<HTMLInputElement>;

  @Input() name: string;

  @Input() required: boolean;

  @Input() containerBody: boolean = false;

  @Input() readonly: boolean;

  @Input() disabled: boolean;

  @Input() max: Date | null = null;

  @Input() min: Date | null = null;

  @Input() editorMode: EditorMode = EditorMode.Classic;

  @Input() inlineTextClass: string;

  @Input() inlineDateFormat: string = "MM/dd/yyyy"// "MM/dd/yyyy h:mma";

  @Input() placeholder: string = "mm/dd/yyyy";

  @Output() selectedDate: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  protected id: string;

  datePattern = Constants.regexPatterns.date;

  initiallyInvalid: boolean = false;

  isEditActive: boolean = false;

  private _originalValue: any

  constructor() {
    super();
  }

  handleInitErrorValueClick() {
    this.initiallyInvalid = false;
    const input = document.querySelector(`#${this.id}`) as HTMLInputElement;
    input.value = this._value;
    setTimeout(() => input.focus(), 10);
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
    this.isEditActive = true;
  }

  getContainer = () => {
    if (this.containerBody) {
      return 'body';
    }
  }

  override writeValue(value: any) {
    this.formatDateOnInit(value);
    this.fillMissingDigits();

    // check only if there is already a value; should behave as other form controls (e.g for required fields)
    const isInvalid = this.checkIsInvalid(this.value);
    if (this.value) {
      this.initiallyInvalid = isInvalid;
    }

    this.onChange(this.value);
    this._originalValue = this.value; // for initializing
  }

  private changeValue(value) {
    const input = document.querySelector(`#${this.id}`) as HTMLInputElement;
    input.value = value;
    this.onChange(value);
  }

  private stopTyping(value) {
    const validValue = value.slice(0, value.length - 1);
    this.changeValue(validValue);
  }

  private validateDay = (value): boolean => {
    const invalidDate = value.split('/')[1] > 31;
    return invalidDate;
  }

  private validateMonth = (value): boolean => {
    const invalidDate = value.split('/')[0] > 12;
    return invalidDate;
  }

  private validateMax(value) {
    const date = DateTime.fromJSDate(new Date(value));
    const maxDate = this.datePattern.test(this.max as any) ? DateTime.fromJSDate(new Date(this.max)) : DateTime.fromJSDate(this.max);
    const invalidDate = date > maxDate;
    return invalidDate;
  }

  private validateMin(value) {
    const date = DateTime.fromJSDate(new Date(value));
    const minDate = this.datePattern.test(this.min as any) ? DateTime.fromJSDate(new Date(this.min)) : DateTime.fromJSDate(this.min);
    const invalidDate = date < minDate;
    return invalidDate;
  }

  private validateFullDate = (value): boolean => {
    const invalidDate = this.isValidAsFullDate(value);
    return invalidDate;
  }

  private checkIsInvalid = (value, markPartialAsInvalid: boolean = false, setFormErrors: boolean = false): boolean => {
    let invalidDate = false;
    if (!value) {
      value = "";

      if (this.required) {
        return true;
      }

      if (setFormErrors) {
        this.model.control.setErrors(null);
      }
      return false;
    }

    if (value.length === 10) {
      invalidDate = this.validateFullDate(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'invalid': true });
        }
        return true;
      }
      invalidDate = this.validateDay(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'invalid': true });
        }
        return true;
      }
      invalidDate = this.validateMonth(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'invalid': true });
        }
        return true;
      }
      // validate min and max only if date is full
      invalidDate = this.validateMax(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'max': true });
        }
        return true;
      }
      invalidDate = this.validateMin(value);
      if (invalidDate) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'min': true });
        }
        return true;
      }
    } else {
      if (markPartialAsInvalid) {
        if (setFormErrors) {
          this.model.control.setErrors({ 'invalid': true });
        }
        return true;
      }
    }

    if (setFormErrors) {
      this.model.control.setErrors(null);
    }
    return false;
  }

  onBlurred = (event: any) => {
    this.checkIsInvalid(this._value, true, true);
    this.blur.emit(event);
    if (this.isEditActive && (!event.relatedTarget || (event.relatedTarget && event.relatedTarget.id !== 'calendar-button'))) {
      this.apply();
    }
  }

  onDateChange(value) {
  }

  applyDateMask({ target }) {
    this.initiallyInvalid = false;
    let invalidDate = false;
    const dateStr = target.value?.replace(/\D/g, '').slice(0, 10) || '';

    let result = '';

    if (dateStr.length >= 5) {
      result = `${dateStr.slice(0, 2)}/${dateStr.slice(2, 4)}/${dateStr.slice(
        4,
        8
      )}`;

      this.checkIsInvalid(result);

      return this.changeValue(result);
    }
    if (dateStr.length >= 3) {
      result = `${dateStr.slice(0, 2)}/${dateStr.slice(2, 4)}`;

      invalidDate = this.checkIsInvalid(result);
      if (invalidDate && target.value.length === 5)
        return this.stopTyping(target.value);

      return this.changeValue(result);
    }
    if (dateStr.length > 0) {
      result = `${dateStr.slice(0, 2)}`;

      invalidDate = this.checkIsInvalid(result);
      if (invalidDate) return this.stopTyping(target.value);

      return this.changeValue(result);
    }

    return this.changeValue(result);
  }

  dateSelected(): void {
    let invalid = this.checkIsInvalid(this._value, false, true);
    this.selectedDate.next(this.value);
    if (!invalid) {
      this.apply();
    }
  }

  ngOnInit() {
    this.initNameAndId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validateMinAndMax(changes);
  }

  onCancelClicked = (event: any) => {
    event.preventDefault();
    this.isEditActive = false;
    this.value = this._originalValue;
    this.blur.emit();
  }

  onApplyClicked = (event: any) => {
    event.preventDefault();
    this.model.control.markAsTouched();
    this.apply();
  }

  private initNameAndId(): void {
    // We don't need a valid UUID, just a unique string.
    // So we remove the dashes to make it simpler and usable as a property key.
    const uniqueSuffix = uuidv4().replace(/-/g, '');

    this.name = this.name + uniqueSuffix;
    this.id = this.name;
  }

  private apply = () => {
    let invalid = this.checkIsInvalid(this.value);
    if (!invalid) {
      this._originalValue = this.value;
      this.isEditActive = false;
    }
  }

  // Set missing zeros on init.
  // Usually when data comes from third party its missing first 0 in month if its before then October.
  private fillMissingDigits() {
    let _value = null;

    const splittedDate = this.value?.split("/") || [];
    if (splittedDate.length === 0) return;

    if (splittedDate[0]?.length === 1) {
      _value = 0 + splittedDate[0] + "/";
    } else if (splittedDate[0]?.length === 2) {
      _value = splittedDate[0] + "/";
    }

    if (splittedDate[1]?.length === 1) {
      _value += (0 + splittedDate[1] + "/");
    } else if (splittedDate[1]?.length === 2) {
      _value += (splittedDate[1] + "/");
    }

    if (splittedDate[2]) {
      _value += splittedDate[2];
    }

    this.value = _value;
  }

  private isValidAsFullDate(value) {
    const maxDays = getDaysForMonth(value.split('/')[0], value.split('/')[2]);
    const areDaysValid = value.split('/')[1] <= maxDays;
    return !this.datePattern.test(value) || !areDaysValid;
  }

  private formatDateOnInit(value?: string) {
    if (!value) {
      this.value = "";
      return;
    }

    if (this.datePattern.test(value)) {
      this.value = value;
      return;
    }

    const date = moment(value).format('MM/DD/YYYY');
    if (date === 'Invalid date') {
      this.value = "";
      return
    }

    this.value = date;
  }

  // support for dynamic min and max
  private validateMinAndMax({ min, max }: SimpleChanges) {
    if (!this.model) return;

    // check if not in date format
    if (max?.currentValue && this.datePattern.test(max.currentValue)) {
      this.max = new Date(max.currentValue);
    }

    // check if not in date format
    if (min?.currentValue && this.datePattern.test(min.currentValue)) {
      this.min = new Date(min.currentValue);
    }

    this.checkIsInvalid(this.value, false, true);
  }
}
