<div>
  <span *ngIf="!smsEnabledForUser" class="label label-warning">
    Call to enable SMS Alerts
  </span>
  <span *ngIf="smsEnabledForUser && !phone" class="label label-warning pull-right">
    {{type}} phone required to enable SMS Alerts
  </span>
  <div *ngIf="smsEnabledForUser && phone" class="form-check form-switch form-switch-success">
    <input class="form-check-input"
      type="checkbox"
      name="enableSms"
      id="enableSms"
      (change)="onEnableSmsToggled()"
      [(ngModel)]="enableSms"
    />
    <label class="form-check-label" for="enableSms">
      Enable SMS Alerts
    </label>
  </div>
</div>
