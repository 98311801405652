import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'HTMLListPipe',
})
export class HTMLListPipe implements PipeTransform {
  transform(value: string, listType: HTMLListTags = 'ul') {
    if (!value) {
      return null;
    }

    if (typeof value !== 'string') {
      return JSON.stringify(value);
    }

    if (!value.includes('\n') && !value.includes('\r')) {
      return value;
    }

    const listArray = value.split(/\r?\n/).map(text => text.trim()).filter(text => !!text);
    return `<${listType}><li>${listArray.join('</li><li>')}</li></${listType}>`;
  }
}

type HTMLListTags = 'ul' | 'ol' | 'li' | 'dl' | 'dt' | 'dd';