import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LenderPriceMockComponent } from "./lenderprice-mock.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    LenderPriceMockComponent
  ],
  exports: [
    LenderPriceMockComponent
  ],
  providers: [
  ]
})
export class LenderPriceMockModule {
  constructor() {
  }
}
