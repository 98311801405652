import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from '@angular/forms';
import { WireRequestService } from "../../services/wire-request.service";
import { NotificationService } from "src/app/services/notification.service";
import { EnumerationService } from "src/app/services/enumeration-service";
import { forkJoin } from "rxjs";
import { Constants } from "src/app/services/constants";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { WireRequestModel, WireSexType } from "../../models/wire-request.model";
import { saveAs } from 'file-saver';

@Component({
  templateUrl: 'create-wire-request.component.html',
  styleUrls: ['create-wire-request.component.scss'],
  selector: 'create-wire-request-drawer',
})
export class CreateWireRequestDrawer implements OnInit {
  @Input()
  applicationId: number;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("createWireForm")
  createWireForm: NgForm | null = null;

  wireRequest: WireRequestModel = new WireRequestModel();

  amortizationTypes: EnumerationItem[] = [];
  appraisalTypes: EnumerationItem[] = [];
  documentationTypes: EnumerationItem[] = [];
  loanTypes: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];
  rateTypes: EnumerationItem[] = [];
  occupancies: EnumerationItem[] = [];
  ethnicities: EnumerationItem[] = [];
  races: EnumerationItem[] = [];
  sexTypes: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  investorNames: EnumerationItem[] = [];

  isLoading: boolean;
  isExporting: boolean;
  isCoBorrowerDisabled: boolean;

  constructor(
    private readonly _wireRequestService: WireRequestService,
    private readonly _notifyService: NotificationService,
    private readonly _enumService: EnumerationService,
  ) {}

  ngOnInit(): void {
    this.getInitData();
  }

  onExportToCsvClicked() {
    this.exportCsv();
  }

  onExportToExcelClicked() {
    this.exportExcel();
  }

  onCloseClicked() {
    this.onClose.emit();
  }

  onBorrowerNamesChanged() {
    this.isCoBorrowerDisabled = !this.wireRequest.borrowerFirstName || !this.wireRequest.borrowerLastName;
  }

  private getInitData() {
    this.isLoading = true;

    forkJoin({
      wireEnums: this._enumService.gerWireRequestEnumeration(),
      wireRequest: this._wireRequestService.getWireRequestData(this.applicationId),
    })
    .subscribe({
      next: ({ wireEnums, wireRequest }) => {
        this.amortizationTypes = wireEnums[Constants.enumerations.wireRequestAmortizationType] || [];
        this.appraisalTypes = wireEnums[Constants.enumerations.wireRequestAppraisalType]?.map(type => ({
          ...type,
          name: type.name?.replace(/^[_]/, '')
        })) || [];
        this.documentationTypes = wireEnums[Constants.enumerations.wireRequestDocumentationType] || [];
        this.loanTypes = wireEnums[Constants.enumerations.wireRequestLoanType] || [];
        this.propertyTypes = wireEnums[Constants.enumerations.wireRequestPropertyType] || [];
        this.rateTypes = wireEnums[Constants.enumerations.wireRequestRateType] || [];
        this.occupancies = wireEnums[Constants.enumerations.wireRequestOccupancy] || [];
        this.ethnicities = wireEnums[Constants.enumerations.wireRequestEthnicity] || [];
        this.races = wireEnums[Constants.enumerations.wireRequestRace] || [];
        this.sexTypes = Object.values(WireSexType).map(item => ({ name: item, value: item }));
        this.lienPositionTypes = wireEnums[Constants.enumerations.wireRequestLienPosition] || [];
        this.investorNames = wireEnums[Constants.enumerations.wireRequestInvestorName] || [];

        this.wireRequest = { ...this.wireRequest, ...wireRequest }; // keep default values

        this.isCoBorrowerDisabled = !this.wireRequest.borrowerFirstName || !this.wireRequest.borrowerLastName;
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load wire request data.", "Error");
      }
    }).add(() => this.isLoading = false);
  }

  private exportCsv() {
    if (!this.isFormValid()) return;

    this.isExporting = true;
    this._wireRequestService.exportCsv(this.applicationId, this.wireRequest).subscribe({
      next: (response) => {
        saveAs(
          new Blob([response.body], { type: 'application/octet-stream' }),
          'WireRequest.csv'
        );
      },
      error: (error) => {
        this._notifyService.showError(error?.message || 'Unable to export data to CSV.', 'Error');
      }
    }).add(() => {
      this.isExporting = false;
    })
  }

  private exportExcel() {
    if (!this.isFormValid()) return;

    this.isExporting = true;
    this._wireRequestService.exportExcel(this.applicationId, this.wireRequest).subscribe({
      next: (response) => {

        saveAs(
          new Blob([response.body], { type: 'application/xml' }),
          'WireRequest.xlsx'
        );
      },
      error: (error) => {
        this._notifyService.showError(error?.message || 'Unable to export data to Excel.', 'Error');
      }
    }).add(() => {
      this.isExporting = false;
    })
  }

  private isFormValid(): boolean {
    if (!this.createWireForm) return false;
    this.createWireForm.form.markAllAsTouched();

    const isvalid = this.createWireForm.form.valid;
    if (!isvalid) this.scrollToFirstInvalid();

    return isvalid;
  }

  private scrollToFirstInvalid = () => {
    this.createWireForm.form.markAllAsTouched();
    let firstInvalidOneId: string = null;

    const keys = Object.keys(this.createWireForm.form.controls);

    for (let i = 0; i <= keys.length - 1; i++) {
      const key = keys[i];
      if (this.createWireForm.form.controls.hasOwnProperty(key)) {
        if (this.createWireForm.form.controls[key].status === 'INVALID') {
          firstInvalidOneId = key;
          break;
        }
      }
    }

    if (!firstInvalidOneId) return;

    this.scrollToElement(firstInvalidOneId);
  }

  private scrollToElement = (id: string) => {
    const element = document.querySelector(`label[for=${id}]`);
    if (!element) return;

    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 250)
  };
}