import { Mortgage, MortgageInsuranceDetail } from '../../../models';
import { areNumbersEqual } from '../math-utils';

export module MiOrFundingFeeUtils {
  export const DEFAULT_TOLERANCE = 1.0;

  /**
   * Checks if the financed amount is equal to the total amount.
   * @param {MortgageInsuranceDetail} insuranceDetail
   * @param {object} [options]
   * @param {number} [options.comparisonTolerance=1.0]
   */
  export function areFinancedAndTotalAmountsEqual(
    insuranceDetail: Readonly<MortgageInsuranceDetail>,
    options?: {
      comparisonTolerance?: number;
    },
  ): boolean {
    const financedAmount = insuranceDetail.miOrFundingFeeFinancedAmount;
    const totalAmount = insuranceDetail.miOrFundingFeeTotalAmount;

    const tolerance = options?.comparisonTolerance ?? DEFAULT_TOLERANCE;
    return areNumbersEqual(financedAmount, Math.floor(totalAmount ?? 0), tolerance);
  }

  /**
   * Tries to set the {@link MortgageInsuranceDetail#financeEntireMiOrFundingFee}
   * property to `true` if the financed amount is equal to the total amount.
   * @param {MortgageInsuranceDetail} insuranceDetail
   * @param {object} [options]
   * @param {number} [options.comparisonTolerance=1.0]
   */
  export function trySetEntireMiOrFundingFee(
    insuranceDetail: MortgageInsuranceDetail,
    options?: {
      comparisonTolerance?: number;
    },
  ): void {
    // Do not set the property if the total amount is zero.
    if (
      areNumbersEqual(
        insuranceDetail?.miOrFundingFeeTotalAmount,
        0,
        options?.comparisonTolerance ?? DEFAULT_TOLERANCE,
      )
    ) {
      return;
    }

    if (areFinancedAndTotalAmountsEqual(insuranceDetail, options)) {
      insuranceDetail.financeEntireMiOrFundingFee = true;
    }
  }

  export function invalidateMiOrFundingFeeAmountField(
    this: { miOrFundingFeeAmount: number },
    mortgage: Mortgage,
  ): void {
    const insuranceDetail = mortgage.mortgageInsuranceDetail;
    if (insuranceDetail.financeEntireMiOrFundingFee) {
      Object.defineProperty(this, 'miOrFundingFeeAmount', {
        get: () => Math.floor(insuranceDetail.miOrFundingFeeTotalAmount ?? 0),
        set: () => {
          // Do nothing.
        },
      });
    } else {
      Object.defineProperty(this, 'miOrFundingFeeAmount', {
        get: () => mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount,
        set: (value: number) => {
          mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount = Math.floor(value ?? 0);
        },
      });
    }
  }
}
