<div class="row mt-1">
    <div class="col-md-12">
        <toggle-configuration [title]="'Enable Disclosure Integration'" [id]="'docMagicEnabled'"
            [configuration]="docMagicEnabled"></toggle-configuration>
    </div>
    <div class="col-md-12 mt-1">
        <hr class="mt-3 mb-0" />
    </div>
    <div class="d-flex align-content-end flex-wrap" *ngIf="docMagicEnabled.value">
        <div class="mt-4 me-2" *ngFor="let credential of disclosureCredentials">
            <credential-item [credential]="credential" [credentials]="disclosureCredentials"
                (openCredentialModal)="openCredentialModal($event)"></credential-item>
        </div>
        <div class="mt-4 me-2">
            <create-credential [credentialType]="'Disclosure'" [credentials]="disclosureCredentials"
                (openCredentialModal)="openCredentialModal($event)"></create-credential>
        </div>
    </div>
    <div class="col-md-12">
        <enable-message *ngIf="docMagicEnabled.value === 0">Enable this feature above!</enable-message>
    </div>
</div>