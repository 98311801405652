<div class="modal-header">
  <h5 class="modal-title">
    Confirm Delete <strong>"{{ deleteDialList.name }}"</strong>
  </h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body" *ngIf="!isLoading; else loading">
  <p>
    There are
    {{
      dialListRecordsCount > 0
        ? dialListRecordsCount + ' record(s)'
        : 'no records'
    }}
    in this dial list. <span *ngIf="dialListRecordsCount > 0">
      Do you want to move them into another dial list before you delete this one?
    </span>
  </p>

  <div class="card-body" *ngIf="dialListRecordsCount > 0">
    <div class="radio form-check-inline">
      <input type="radio" id="inlineRadio1" value="yes" name="radioInline" [(ngModel)]="isMove">
      <label for="inlineRadio1"> Yes </label>
    </div>
    <div class="radio form-check-inline ms-3">
        <input type="radio" id="inlineRadio2" value="no" name="radioInline" [(ngModel)]="isMove">
        <label for="inlineRadio2"> No </label>
    </div>
  </div>
  
  <p *ngIf="isMove == 'no' || dialListRecordsCount == 0">
    This is a permanent operation. Do you really want to delete this list?
  </p>

  <div class="input-group align-items-center mb-2" *ngIf="isMove == 'yes'">
    <label for="moveTo" class="form-label align-self-center mb-lg-0 text-end me-2">Move To:</label>
    <select
      [(ngModel)]="selectedDialListId"
      class="form-select"
      name="moveToDialList"
    >
      <option [value]="null">-- Select One --</option>
      <option
        *ngFor="let dialList of toDialLists"
        [value]="dialList.dialListId"
      >
        {{ dialList.name }}
      </option>
    </select>
  </div>
</div>


<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary "
    (click)="onOkClicked()"
    [disabled]="!isMove && dialListRecordsCount > 0"
  >
    <i class="fa fa-check"></i>
    {{ isMove == 'yes' ? 'Move Records and Delete' : 'Delete'}}
  </button>
</div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading ..."></loading-indicator>
</ng-template>
