<ng-container *ngIf="viewType; else loading">
  <ng-container *ngIf="viewType == 'management'">
    <appraisal-management [isPipelineView]="isPipelineView"></appraisal-management>
  </ng-container>
  <ng-container *ngIf="viewType == 'reggora'">
    <reggora [applicationId]="appId"></reggora>
  </ng-container>
</ng-container>

<ng-template #loading>
  <loading-indicator></loading-indicator>
</ng-template>
