<div class="modal-header" *ngIf="isModal">
    <h5 class="modal-title"> {{title}} </h5>
    <div>
        <a class="modal-title toggleHover me-4" title="Full Screen" (click)="onToggleFullScreen()"> Toggle
            Full Screen <i class="fas fa-expand-arrows-alt"></i> </a>
        <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
    </div>
</div>
<div class="modal-body" id="docFilesEdit" *ngIf="file">
    <div class="row">
        <form #docFilesEditForm="ngForm" novalidate id="docFilesEditForm" name="docFilesEditForm">
            <div *ngIf="!isToggleFullScreen" class="hide-wrap-on-fullscreen">
                <div class="form-group row">
                    <label class="col-sm-2 form-label align-self-center text-end mt-1"> Filename: </label>
                    <div class="col-sm-4">
                        <input class="form-control" type="text" name="fileName" placeholder="File Name"
                            [(ngModel)]="file.fileName" />
                    </div>
                    <label class="col-sm-2 form-label align-self-center text-end mt-1"> Ext: </label>
                    <div class="col-sm-4">
                        <input class="form-control" type="text" name="originalExt" placeholder="Undefined"
                            [(ngModel)]="fileExtension" disabled />
                    </div>
                    <label class="col-sm-2 form-label align-self-center text-end mt-4"> Note: </label>
                    <div class="col-sm-10 mt-3">
                        <input class="form-control " type="text" name="note" placeholder="Note"
                            [(ngModel)]="file.note" />
                    </div>
                    <label class="col-sm-2 form-label align-self-center text-end mt-4"> Borrower Note: </label>
                    <div class="col-sm-10 mt-3">
                        <input class="form-control" type="text" name="borrowerNote" [(ngModel)]="file.borrowerNote"
                            disabled />
                    </div>
                </div>
                <div class="legend mt-2">
                    <span> Image Splitting and Manipulation. </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-9">
                    <div *ngIf="!docuViewareControlHtml" class="alert alert-warning mt-2">
                        <span> Please wait while we process the image and load the image viewer. </span>
                    </div>
                    <div id="docuViewer" style="width: 100%;  height: calc(100vh - 175px); margin-bottom: 15px;">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <button class="btn btn-primary" (click)="onExtractPagesClicked()"> Extract Selected Pages
                        </button>
                    </div>
                    <div class="instruction-field" style="min-height: 26rem;">
                        <article class="sortable-grid ui-sortable" id="docSplitRules">
                            <div class="card" *ngFor="let instruction of instructions; let index = index"
                                id="instruction_{{index}}">
                                <div class="card-header d-flex justify-content-between text-white"
                                    style="background: #60747c">
                                    <span> Document Instruction {{index + 1}} </span>
                                    <button type="button" class="btn-close btn-close-white" aria-label="Close"
                                        (click)="onRemoveInstructionClicked(index)"></button>
                                </div>
                                <div class="card-body">
                                    <div class="form-group row">
                                        <label class="col-sm-4 form-label align-self-center text-end mt-1"> Pages: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" name="pages_{{index}}"
                                                [(ngModel)]="instruction.pages" placeholder="Pages e.g (1-3,5,7-9)" />
                                        </div>
                                        <label class="col-sm-4 form-label align-self-center text-end mt-3"> Link to Task </label>
                                        <div class="col-sm-8">
                                            <div class="mt-3">
                                                <input type="checkbox" class="checkbox style-checkbox mt-1"
                                                    name="LinkToTask_{{index}}" id="linkToTask_{{index}}"
                                                    checked="instruction.linkToTask"
                                                    [(ngModel)]="instruction.linkToTask"
                                                    (click)="onLinkTaskToggleClicked(instruction.linkToTask, instruction)">
                                            </div>
                                        </div>
                                        <div *ngIf="instruction.linkToTask" class="d-flex">
                                            <label class="col-sm-4 form-label align-self-center text-end mt-2 pt-1 marginForLabels"> Task:
                                            </label>
                                            <div class="col-sm-8">
                                                <select class="form-select mt-2"
                                                    (change)="selectedTaskChanged(instruction.loanDocTaskId)"
                                                    [(ngModel)]="instruction.loanDocTaskId" name="selectTask_{{index}}">
                                                    <option value=""> -- Select One -- </option>
                                                    <optgroup *ngFor="let borrower of borrowers"
                                                        label="{{borrower.firstName + ' ' + borrower.lastName + '\'s Tasks'}}">
                                                        <ng-container
                                                            *ngFor="let task of taskList | sort: 'asc':'description'">
                                                            <option *ngIf="borrower.borrowerId === task.borrowerId && task.taskType === 'RequestDocument'"
                                                                value="{{task.loanDocTaskId}}"
                                                                selected="instruction.loanDocTaskId == task.loanDocTaskId">
                                                                {{task.description}}
                                                            </option>
                                                        </ng-container>
                                                    </optgroup>
                                                    <optgroup label="Loan Tasks">
                                                    <ng-container
                                                        *ngFor="let task of taskList | sort: 'asc':'description'">
                                                        <option *ngIf="!task.borrowerId && task.taskType === 'RequestDocument'"
                                                            value="{{task.loanDocTaskId}}"
                                                            selected="instruction.loanDocTaskId == task.loanDocTaskId">
                                                            {{task.description}}
                                                        </option>
                                                    </ng-container>
                                                </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                        <label class="col-sm-4 form-label align-self-center"></label>
                                        <div class="col-sm-8" [ngClass]="{'mt-3': instruction.linkToTask}">
                                            <div class="btn-group">
                                                <button class="btn btn-secondary" name="newFile_{{index}}"
                                                    [ngClass]="{'active': instruction.filter === 'newFile'}"
                                                    btn-radio="false"
                                                    (click)="onNewAndExistingFileButtonChanged(instruction, 'newFile')">
                                                    <span> New File </span>
                                                </button>
                                                <button class="btn btn-secondary" btn-radio="true"
                                                    name="mergeWithExisting_{{index}}"
                                                    [ngClass]="{'active': instruction.filter === 'mergeWithExisting'}"
                                                    *ngIf="!instruction.linkToTask && docFileList && docFileList.length > 0"
                                                    (click)="onNewAndExistingFileButtonChanged(instruction, 'mergeWithExisting')">
                                                    <span> Merge With Existing </span>
                                                </button>
                                                <button class="btn btn-secondary" btn-radio="true"
                                                    name="mergeWithExisting2_{{index}}"
                                                    [ngClass]="{'active': instruction.filter === 'mergeWithExisting'}"
                                                    *ngIf="instruction.linkToTask && selectedTask && selectedTask.docFiles && selectedTask.docFiles.length > 0"
                                                    (click)="onNewAndExistingFileButtonChanged(instruction, 'mergeWithExisting')">
                                                    <span> Merge With Existing </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="!instruction.linkToTask && instruction.filter === 'mergeWithExisting'"
                                            class="d-flex">
                                            <label class="col-md-4 form-label text-end align-self-center mt-3 marginForLabels">
                                                Document Type Filter:
                                            </label>
                                            <div class="col-sm-8">
                                                <select class="form-select mt-3" name="docTypeFilter_{{index}}"
                                                    [(ngModel)]="instruction.documentTypeId"
                                                    (change)="onFilterDocFileListClicked(index)">
                                                    <option *ngFor="let docType of existingPdfDocumentTypeList"
                                                        value="{{docType.documentTypeId}}"
                                                        [selected]="instruction.documentTypeId==docType.documentTypeId">
                                                        {{docType.documentTypeName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="!instruction.linkToTask && instruction.filter === 'mergeWithExisting'"
                                            class="d-flex">
                                            <label class="col-md-4 form-label align-self-center text-end pt-2 mt-3 marginForLabels">
                                                Existing File:
                                            </label>
                                            <div class="col-sm-8">
                                                <select class="form-select mt-3" name="docFileNameFilter_{{index}}"
                                                    [(ngModel)]="instruction.mergeWithExistingGuid">
                                                    <option *ngFor="let docFile of docFileListFiltered"
                                                        value="{{docFile.docFileGuid}}"
                                                        [selected]="instruction.mergeWithExistingGuid==docFile.docFileGuid">
                                                        {{docFile.docFileName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="instruction.linkToTask && selectedTask && instruction.filter === 'mergeWithExisting'"
                                            class="d-flex">
                                            <label class="col-md-4 form-label align-self-center text-end pt-2 mt-3 marginForLabels">
                                                Existing File:
                                            </label>
                                            <div class="col-sm-8">
                                                <select class="form-select mt-3" name="docFileNameFilter_{{index}}"
                                                    [(ngModel)]="instruction.mergeWithExistingGuid">
                                                    <option *ngFor="let docFile of selectedTask.docFiles"
                                                        value="{{docFile.guid}}"
                                                        [selected]="instruction.mergeWithExistingGuid==docFile.guid">
                                                        {{docFile.fileName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="d-flex mt-3"
                                            *ngIf="instruction.filter === 'mergeWithExisting' && instruction.loanDocTaskId">
                                            <label class="col-sm-4 form-label align-self-center marginForLabels"></label>
                                            <div class="col-sm-8">
                                                <div class="btn-group">
                                                    <button class="btn btn-secondary" name="prepend_{{index}}"
                                                        [ngClass]="{'active': instruction.mergeWithExistingLocation === 'Prepend'}"
                                                        btn-radio="'Prepend'"
                                                        (click)="onAppendAndPrependButtonChanged(instruction, 'Prepend')">
                                                        <span> Prepend </span>
                                                    </button>
                                                    <button class="btn btn-secondary" btn-radio="'Append'"
                                                        name="append_{{index}}"
                                                        [ngClass]="{'active': instruction.mergeWithExistingLocation === 'Append'}"
                                                        (click)="onAppendAndPrependButtonChanged(instruction, 'Append')">
                                                        <span> Append </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex"
                                            *ngIf="!instruction.linkToTask && globalConfig && instruction.filter !== 'mergeWithExisting'">
                                            <label class="col-md-4 form-label align-self-center text-end pt-1 mt-3 marginForLabels">
                                                Document Type:
                                            </label>
                                            <div class="col-sm-8">
                                                <select class="form-select mt-3" name="docTypeFilter_{{index}}"
                                                    [(ngModel)]="instruction.documentTypeId">
                                                    <option *ngFor="let docType of globalConfig.documentType"
                                                        value="{{docType.documentTypeId}}"
                                                        [selected]="instruction.documentTypeId==docType.documentTypeId">
                                                        {{docType.documentTypeName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="!instruction.linkToTask && instruction.filter !== 'mergeWithExisting'" class="d-flex">
                                            <label class="col-sm-4 form-label align-self-center text-end pt-1 mt-3 marginForLabels">
                                                Description:
                                            </label>
                                            <div class="col-sm-8">
                                                <input class="form-control mt-3" type="text"
                                                    name="description_{{index}}" [(ngModel)]="instruction.description"
                                                    placeholder="Description" />
                                            </div>
                                        </div>
                                        <div *ngIf="instruction.filter !== 'mergeWithExisting'" class="d-flex">
                                            <label class="col-sm-4 form-label align-self-center text-end pt-1 mt-3 marginForLabels">
                                                File Name:
                                            </label>
                                            <div class="col-sm-8">
                                                <input class="form-control mt-3" type="text" name="fileName_{{index}}"
                                                    [(ngModel)]="instruction.fileName" placeholder="File Name" />
                                            </div>
                                        </div>
                                        <label class="col-sm-4 form-label align-self-center text-end mt-3"> Remove From Original </label>
                                        <div class="col-sm-8">
                                            <div class="mt-3">
                                                <input type="checkbox" class="checkbox style-checkbox mt-1"
                                                    name="removeFromOriginal_{{index}}"
                                                    id="removeFromOriginal_{{index}}"
                                                    checked="instruction.removePagesFromSource"
                                                    [(ngModel)]="instruction.removePagesFromSource">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer form-footer" *ngIf="isModal">
    <button type="button" class="btn btn-primary right" (click)="onSaveClicked()"> <i class="fas fa-save me-1"></i> Save
    </button>
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
</div>
