import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { LeadStatus } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { LeadStatusAssociation } from '../../../../models';
import { LeadStatusAssociationService } from '../../../../services';

@Component({
  selector: 'upsert-lead-status-association',
  templateUrl: 'upsert-lead-status-association.component.html',
})
export class UpsertLeadStatusAssociation implements OnInit {
  @Input() leadStatusAssociation: LeadStatusAssociation;
  @Input() leadStatuses: Array<LeadStatus>;
  @Input() loanPurposes: Array<LoanPurpose>;
  @Input() permissions: any;

  @Output()
  onSave: EventEmitter<LeadStatusAssociation> = new EventEmitter<LeadStatusAssociation>();

  @Output()
  onClose: EventEmitter<never> = new EventEmitter<never>();

  @ViewChild('upsertLeadStatusAssociationForm')
  upsertLeadStatusAssociationForm: NgForm | null;

  editMode: boolean;
  saving: boolean;
  disallowedThirdPartyServices: Array<EnumerationItem> = [];
  disallowedServicesModel = [];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _notificationService: NotificationService,
    private readonly _leadStatusAssociationService: LeadStatusAssociationService,
    private readonly _enumerationService: EnumerationService
  ) { }

  ngOnInit(): void {
    this._enumerationService.getMortgageEnumerations()
      .subscribe(res => {
        this.getDisallowedServicesEnum(res);
        this.setMultiSelectData();
      });

    this.editMode = this.leadStatusAssociation.loanPurposeId ? true : false;
    if (!this.editMode) {
      this.leadStatusAssociation.loanStatusId = null;
      this.leadStatusAssociation.loanPurposeId = this.loanPurposes[0].loanPurposeId;
    }
  }

  private getDisallowedServicesEnum(value) {
    const result = value['ThirdPartyServiceType'] || [];
    let params = [];
    if (this.permissions.isCreditReportingEnabled) {
      const item0 = result.find(el => el.value == 'CreditHardPull'); // hard pull
      const item1 = result.find(el => el.value == 'CreditSoftPull'); // soft pull
      params.push(item0);
      params.push(item1);
    }
    this.disallowedThirdPartyServices = params.map((p) => ({
      value: p.value,
      name: p.name,
    }));
  }

  private setMultiSelectData() {
    this.disallowedServicesModel = this.leadStatusAssociation.disallowedThirdPartyServices
      ? this.leadStatusAssociation.disallowedThirdPartyServices.split(',')
      : [];
  }

  private update() {
    this._leadStatusAssociationService.updateLeadStatusAssociation(this.leadStatusAssociation)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Lead status associaton updated successfully',
            'Lead Status Associaton'
          );
          this.onSave.emit(this.leadStatusAssociation)
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || "Couldn't update selected lead status associaton",
            'Lead Status Associaton'
          );
        }
      })
  }

  private insert() {
    this._leadStatusAssociationService.insertLeadStatusAssociation(this.leadStatusAssociation)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Lead status associaton added successfully',
            'Lead Status Associaton'
          );
          this.onSave.emit(res)
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || "Couldn't insert new lead status associaton",
            'Lead Status Associaton'
          );
        }
      });
  }

  save() {
    if (!this.upsertLeadStatusAssociationForm) return
    this.upsertLeadStatusAssociationForm.form.markAllAsTouched();
    if (!this.upsertLeadStatusAssociationForm.form.valid) return;

    this.leadStatusAssociation.disallowedThirdPartyServices = this.disallowedServicesModel ? this.disallowedServicesModel.join() : null;
    this.saving = true;
    this.editMode ? this.update() : this.insert();
  }
}
