import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep, isArray } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeNode } from 'primeng/api';
import { combineLatest, finalize } from 'rxjs';
import { UserProfile } from 'src/app/models';
import { StateLicensing } from 'src/app/models/user/state-licensing.model';
import { LeadRouteUser } from 'src/app/modules/admin/lead-config/models/lead-route-user.model';
import { UserStateSetting } from 'src/app/modules/admin/lead-config/models/lead-routes-agents.model';
import { LeadRouteGroup } from 'src/app/modules/leads/models/lead-route-group.model';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'routing-treeview',
  templateUrl: './routing-treeview.component.html',
  styleUrls: ['./routing-treeview.component.scss']
})
export class RoutingTreeviewComponent implements OnInit {
  @Input() userProfile: UserProfile;
  @Input() leadRouteGroups: LeadRouteGroup[]

  @Output() onSave: EventEmitter<void> = new EventEmitter<void>();

  stateLicensingList: StateLicensing[];
  leadRoutingTreeViewData: TreeNode[] = [];
  isLoading: boolean = true;
  constructor(private readonly _profileService: ProfileService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loadStateLicensings();
  }

  loadStateLicensings() {
    this.isLoading = true;
    this._profileService.getStateLicensing(this.userProfile.userCompanyGuid)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (response) => {
          this.stateLicensingList = response
            .map(usl => ({
              ...usl,
              state: usl.state ? usl.state.toUpperCase() : ''
            }));
          this.generateTreeView();
        },
        error: (err) => {
          this._notificationService.showError(err?.message || 'Unable to fetch state licensing list ', 'State Licensing')
        }
      });
  }

  generateTreeView = () => {
    const allLeadRoutes = this.leadRouteGroups.flatMap(grp => grp.routes);
    this.leadRouteGroups.forEach(grp => {
      const matchingUsers = grp.routes
        .flatMap(route => route.users)
        .filter(user => user.userId == this.userProfile.userCompanyGuid);
      if (matchingUsers.length === 0) {
        return;
      }
      const grpNode: TreeNode = {
        key: 'grp_' + grp.leadRouteGroupId,
        label: grp.name,
        data: grp,
        expanded: true,
        children: matchingUsers
          .map(user => {
            if (!user.throttlePeriod) {
              user.throttlePeriod = ''
            }
            if (!user.throttleInterval) {
              user.throttleInterval = ''
            }
            this.setUserStateSettings(user);
            return {
              key: 'route_' + user.leadRouteId,
              label: allLeadRoutes.find(route => route.leadRouteId === user.leadRouteId)?.name || 'Unknow Route Name',
              data: allLeadRoutes.find(route => route.leadRouteId === user.leadRouteId),
              expanded: true,
              children: [{
                key: 'user_' + user.leadRouteUserId,
                label: this.userProfile.firstName + ' ' + this.userProfile.lastName,
                data: user,
                expanded: true,
                children: this.stateLicensingList
                  .map(stateLicensing => ({
                    key: 'setting_' + stateLicensing.userLicenseId,
                    label: stateLicensing.state ? stateLicensing.state.toUpperCase() : '',
                    data: user.userStateSettings.find(ss => ss.state && ss.state.toUpperCase() == stateLicensing.state.toUpperCase()),
                    expanded: true,
                    children: []
                  }))
              }]
            }
          })
      }
      this.leadRoutingTreeViewData.push(grpNode);
    });
  }

  setUserStateSettings(user: LeadRouteUser) {
    if (!isArray(user.userStateSettings)) {
      user.userStateSettings = [];
    }
    this.stateLicensingList.forEach(stateLicensing => {
      var matchingSetting = user.userStateSettings.find(ss => ss.state && ss.state.toUpperCase() == stateLicensing.state.toUpperCase());
      if (!matchingSetting) {
        user.userStateSettings.push({
          leadRouteUserStateSettingsId: 0,
          state: stateLicensing.state.toUpperCase(),
          active: false,
          leadRouteUserId: user.leadRouteUserId,
          addToRoute: false
        });
      } else {
        matchingSetting.addToRoute = true;
      }
    });
  }

  save() {
    this._spinnerService.show();
    const request = cloneDeep(this.leadRoutingTreeViewData
      .flatMap(lr => lr.children)
      .flatMap(lr => lr.children)
      .flatMap(user => user.data)
    );
    const promises = [];
    request.forEach(user => {
      if (!user.throttlePeriod) {
        user.throttlePeriod = null
      }
      if (!user.throttleInterval) {
        user.throttleInterval = null
      }
      promises.push(this._profileService.updateLeadRouteUserInfo({
        ...user,
        userStateSettings: undefined
      }))
      user.userStateSettings.forEach((setting: UserStateSetting) => {
        if (setting.addToRoute) {
          setting.leadRouteUserStateSettingsId > 0
            ? promises.push(this._profileService.updateUserStateSetting(setting))
            : promises.push(this._profileService.insertUserStateSetting(setting))
        } else if (setting.leadRouteUserStateSettingsId) {
          promises.push(this._profileService.deleteUserStateSetting(setting.leadRouteUserStateSettingsId))
        }
      });
    });
    combineLatest(promises)
      .pipe(finalize(() => this._spinnerService.hide()))
      .subscribe({
        next: res => {
          this.onSave.emit();
          this._notificationService.showSuccess('User Lead Routing Saved Successfully', "Success!");
        }, error: err => {
          this._notificationService.showError(err.message || 'unable to save user lead routing', "Error!");
        }
      });
  }
}
