<form class="form-horizontal" #transUnionConfigForm="ngForm" novalidate id="transUnionConfigForm"
  *ngIf="transUnionConfig">
  <div class="row mt-2">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Import Lead Loan Purpose ID</label>
    </div>
    <div class="col-md-4 d-flex justify-content-between">
      <div class="flex-grow-1">
        <select class="form-select" name="transunionImportLeadLoanPurposeId"
          id="transunionImportLeadLoanPurposeId" [(ngModel)]="transUnionConfig.transunionImportLeadLoanPurposeId.value"
          #loanPurposeInput="ngModel" [ngClass]="{
          'is-invalid':
          loanPurposeInput &&
          loanPurposeInput.touched &&
          loanPurposeInput.invalid
        }" required>
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let loanPurpose of loanPurposes" value="{{loanPurpose.loanPurposeId}}">
            {{ loanPurpose.loanPurposeName }}
          </option>
        </select>
        <div class="invalid-feedback">Please enter a value.
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Import Lead Loan Type ID</label>
    </div>
    <div class="col-md-4 d-flex justify-content-between">
      <div class="flex-grow-1">
        <select #loanTypeInput="ngModel" class="form-select" name="transunionImportLeadLoanTypeId" id="transunionImportLeadLoanTypeId"
          [(ngModel)]="transUnionConfig.transunionImportLeadLoanTypeId.value" [ngClass]="{
            'is-invalid':
            loanTypeInput &&
            loanTypeInput.touched &&
            loanTypeInput.invalid
          }"required>
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let loanType of loanTypes" value="{{loanType.loanTypeId}}">
            {{ loanType.loanTypeName }}
          </option>
        </select>
        <div class="invalid-feedback">Please enter a value.
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Import Lead Channel</label>
    </div>
    <div class="col-md-4 d-flex justify-content-between">
      <div class="flex-grow-1">
        <select #loanChannel id="transunionImportLeadChannel" name="transunionImportLeadChannel"
          class="form-select col-sm-4" [(ngModel)]="transUnionConfig.transunionImportLeadChannel.valueStr"
          #channelInput="ngModel"
          [ngClass]="{'is-invalid' : channelInput && channelInput.touched && channelInput.invalid}">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let item of enabledChannels" value="{{item.name}}">
            {{ item.name }}
          </option>
        </select>

        <div class="invalid-feedback">Please enter a value.
        </div>
      </div>
    </div>
  </div>
    <div class="row mt-2">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Import Lead Campaign ID</label>
      </div>
      <div class="col-md-4 d-flex justify-content-between">
        <div class="flex-grow-1">
          <select #loanChannel id="transunionImportLeadCampaignId" name="transunionImportLeadCampaignId"
            class="form-select col-sm-4" [(ngModel)]="transUnionConfig.transunionImportLeadCampaignId.value"
            #campaignInput="ngModel"
            [ngClass]="{'is-invalid' : campaignInput && campaignInput.touched && campaignInput.invalid}">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let item of leadCampaigns" value="{{item.campaignId}}">
              {{ item.name }}
            </option>
          </select>
          <div class="invalid-feedback">Please enter a value.
          </div>
        </div>
      </div>
    </div>
</form>
