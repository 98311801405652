import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ExpressionConfig } from 'src/app/models/expressions/expression-config.model';
import { ExpressionValidationResult } from 'src/app/models/expressions/expression-validation-result.model';

import * as _ from 'lodash';
import { AlertType } from 'src/app/models/alert-type.enum';
import { CustomAlert } from 'src/app/models/custom-alert.model';
import { AutoCompleteItem } from 'src/app/models/expressions/auto-complete-item.model';
import { FunctionalExpressionService } from 'src/app/modules/expression-builder/services/functional-expression.service';

@Component({
  selector: 'expression-editor',
  templateUrl: 'expression-editor.component.html',
  styleUrls: ['expression-editor.component.scss']
})

export class ExpressionEditorComponent implements OnInit, AfterViewInit {

  expression: string = "";

  fields: AutoCompleteItem[] = [];
  operatorValues: AutoCompleteItem[] = [];

  validationResult: ExpressionValidationResult;

  alert: CustomAlert;

  triggerCharacter = '[';

  @Input()
  rows: number = 20;

  @Input()
  columns: number = 50;

  private _searchText = "";
  lastWord: string = "";
  private _lastCharacter: string = "";

  private _isMenuVisible = false;

  keyboardShortcut = (event: KeyboardEvent) => {
    this._lastCharacter = "";
    if (this._isMenuVisible) {
      return false;
    }

    if (event.keyCode === 219) {
      this._lastCharacter = event.key;
      return true;
    }

    if ((event.keyCode === 32 || event.code === '32') && event.ctrlKey) {
      return true;
    }

    if ((event.keyCode === 50 || event.code === '50') && event.shiftKey) {
      this.triggerCharacter = '@';
      this._lastCharacter = '@';
      return true;
    }

    let key = event.key;

    let found = this.fields.find(f => f.displayName.includes(key));
    if (found) return true;
    found = this.operatorValues.find(o => o.displayName.toLocaleLowerCase().startsWith(key.toLocaleLowerCase()));
    if (found) return true;
    return false;
  }

  private _config: ExpressionConfig;

  @Input()
  set config(config: ExpressionConfig) {
    this._config = _.cloneDeep(config);
    this.operatorValues = this._config.operators.map(o => new AutoCompleteItem(o.name, o.op));
    this.fields = this._config.fields;
  }

  constructor(private readonly _expressionService: FunctionalExpressionService) {
  }

  ngAfterViewInit(): void {
    $("#expression").focus();
  }

  ngOnInit() {

  }

  onMenuShown = () => {
    this._searchText = '';
    this._isMenuVisible = true;
    if (this._lastCharacter == '[') {
      this._searchText = '[';
    }
    if (this._lastCharacter == '@') {
      this._searchText = '@';
    }
  }

  onMenuHidden = () => {
    this._isMenuVisible = false;
    this.triggerCharacter = '[';
  }

  findChoices = (searchText: string) => {
    if (this._searchText.length > 0 && (this._lastCharacter == '[' || this._lastCharacter == '@')) {
      searchText = this._searchText;
    }
    const allOptions = this.operatorValues.concat(this.fields);
    return allOptions.filter(item =>
      item.displayName.toLowerCase().includes(searchText.toLowerCase())
    ).map(r => r.displayName);
  }

  getChoiceLabel = (choice: string) => {
    const allOptions = this.operatorValues.concat(this.fields);
    const itemFound = allOptions.find(i => i.displayName == choice);
    if (itemFound) {
      return itemFound.value + " ";
    }
    return "";
  }

  onExpressionChanged = () => {
    const lastWord = this.expression.split(" ").splice(-1)[0];
    if (lastWord.length > 0) {
      this.lastWord = lastWord;
    }
    // Experiment to make intellisense *somewhat" context aware //
    if (this.lastWord === "[MailingStreet]") {
      const multiplication = this.operatorValues.find(o => o.value == "*");
      if (multiplication) {
        const multiplicationIndex = this.operatorValues.indexOf(multiplication);
        if (multiplicationIndex >= 0) {
          this.operatorValues.splice(multiplicationIndex, 1);
        }
      }

    } else {
      this.operatorValues = this._config.operators.map(o => new AutoCompleteItem(o.name, o.op));
    }
  }

  onValidateClicked = () => {
    this.alert = null;
    this._expressionService.parse(this.expression).subscribe(result => {
      this.validationResult = result;
      if (this.validationResult.isValid) {
        this.alert = new CustomAlert("Success!", "Your expression is valid.", AlertType.Success);
      } else {
        this.alert = new CustomAlert("Syntax Error!", "Your expression is not valid. Error: " + this.validationResult.error + ", index: " + this.validationResult.index, AlertType.Error);
      }
    });
  }

  onClearClicked = () => {
    this.expression = "";
    this.onExpressionChanged();
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  handleDragEnter(ev: any) {
    $("#expression").addClass("over");
  }

  handleDragLeave(ev: any) {
    $("#expression").removeClass("over");
  }

  drop(ev: any) {
    $("#expression").removeClass("over");
    var operator = ev.dataTransfer.getData("operator");
    this.insertAtCursor($("#expression")[0], operator + " ");
  }

  insertAtCursor = (myField, myValue) => {
    if (myField.selectionStart || myField.selectionStart == '0') {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
    } else {
        myField.value += myValue;
    }
    myField.focus();
  }

}
