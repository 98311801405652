import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyCredential, ThirdPartyCredentialArray, ThirdPartyCredentialSchema, ThirdPartyCredentialSchemaReadonlyArray, ThirdPartyKeyValue, UserCompanyThirdPartyCredential, UserCompanyThirdPartyCredentialArray } from '../../../../../../models';
import {ThirdPartyCredentialsService} from '../../../../../../services/third-party-credentials.service';
import {NgForm} from '@angular/forms';
import {SystemLevelService} from '../../../../../../services/system-level.service';
import {NotificationService} from '../../../../../../services/notification.service';
import {ApplicationContextBoundComponent} from '../../../../../../shared/components';
import { TestCredentialResultsModalComponent } from '../../los/test-credential-results-modal/test-credential-results-modal.component';
import * as _ from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ScopeType } from '../../integrations.component';
import { Constants } from 'src/app/services/constants';

@Component({
  templateUrl: './credential-modal-v2.component.html'
})
export class CredentialModalV2Component extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  set credentialSchema(value) {
    this._credentialSchema = value || [];
  }
  get credentialSchema(): ThirdPartyCredentialSchemaReadonlyArray {
    return this._credentialSchema;
  }

  @Input()
  set credentials(value) {
    this._credentials = value || [];
  }
  get credentials(): ThirdPartyCredentialArray {
    return this._credentials;
  }

  @Input()
  set companyCredentials(value) {
    this._companyCredentials = value || [];
  }
  get companyCredentials(): UserCompanyThirdPartyCredentialArray {
    return this._companyCredentials;
  }

  @Input()
  scope: ScopeType;

  @Input()
  isTpoUser: boolean | null;

  @Input()
  saveAndTest: boolean | null;

  @Input()
  userCompanyGuid: string;

  @Input()
  isLosCredentials: boolean = false;

  @Output()
  onCredentialUpserted: EventEmitter<ThirdPartyCredential> = new EventEmitter<ThirdPartyCredential>();

  @ViewChild('credentialForm')
  credentialForm: NgForm | undefined;

  credential: ThirdPartyCredential;
  vendorSchema: ThirdPartyCredentialSchema;
  kvPairs: { [key: string]: ThirdPartyKeyValue } = {};

  additionalConfigValueFieldType: string;

  companyCredentialId: number; // used for user pages

  isSaving: boolean;
  isTestingAndSaving: boolean;

  vendors: EnumerationItem[] = [];
  loanFolders: Array<{ display: string, value: string }> = [];

  private _credentialSchema: ThirdPartyCredentialSchemaReadonlyArray = [];
  private _credentials: ThirdPartyCredentialArray = [];
  private _companyCredentials: UserCompanyThirdPartyCredentialArray = [];

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    public activeModal: NgbActiveModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.scope === "Company") {
      this.vendors = this.credentialSchema.map(schema => ({
        value: schema.vendorName,
        name: this._thirdPartyCredentialsService.getVendorDisplayName(schema.vendorName),
      }))
    } else {
      // get only vendors + their schemas from system level settings
      this.vendors = this._companyCredentials.reduce((pV, cV) => {
        const schema = this.credentialSchema.find(s => s.vendorName === cV.vendorName);
        if (!schema) return pV;
        return [
          ...pV,
          {
            value: cV.vendorName,
            name: `${this._thirdPartyCredentialsService.getVendorDisplayName(cV.vendorName)} (${cV.credentialName})`,
            id: cV.credentialId,
          }
        ]
      }, [])
    }

    if (this.credential) {
      this.getVendorSchema();
    } else {
      this.credential = new ThirdPartyCredential();
    }

    this.setCredentialConfig();

    if (this.credential.credentialType === 'LosVendor' && this.kvPairs['LoanFolders']) {
      this.setLoanFolders();
    }
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.isSaving = true;

    if (this.scope === 'User' || this.scope === 'TpoUser') {
      this.credential.userId = this.userCompanyGuid;
    }
    this.credential.companyId = this.applicationContext.userPermissions.companyId;

    this.overwriteThirdPartyKeyValuePairs();

    let observer;
    if (this.credential.credentialId) { // update
      if (this.scope === "Company") {
        observer = this._thirdPartyCredentialsService.upsertCredentialsForCompany(this.credential);
      } else {
        observer = this._thirdPartyCredentialsService.updateCredentialsForUser(this.credential);
      }
    } else { // insert
      if (this.scope === "Company") {
        observer = this._thirdPartyCredentialsService.upsertCredentialsForCompany(this.credential);
      } else {
        observer = this._thirdPartyCredentialsService.insertCredentialsForUser(this.credential, this.companyCredentialId);
      }
    }

    observer.subscribe({
      next: res => {
        this._notificationService.showSuccess(`Credential saved successfully.`, 'Success');
        this.activeModal.close(res);
      },
      error: error => {
        console.log(error)
        this._notificationService.showError(`${error?.message || 'Unable to save.'}`, 'Error');
      }
    }).add(() => this.isSaving = false)
  }

  saveAndTestCredential() {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) {
      return;
    }

    this.isTestingAndSaving = true;

    if (this.scope === 'User' || this.scope === 'TpoUser') {
      this.credential.userId = this.userCompanyGuid;
    }
    this.credential.companyId = this.applicationContext.userPermissions.companyId;

    this.overwriteThirdPartyKeyValuePairs();

    let observer;
    if (this.credential.credentialId) { // update
      if (this.scope === "Company") {
        observer = this._thirdPartyCredentialsService.upsertCredentialsForCompany(this.credential);
      } else {
        observer = this._thirdPartyCredentialsService.updateCredentialsForUser(this.credential);
      }
    } else { // insert
      if (this.scope === "Company") {
        observer = this._thirdPartyCredentialsService.upsertCredentialsForCompany(this.credential);
      } else {
        observer = this._thirdPartyCredentialsService.insertCredentialsForUser(this.credential, this.companyCredentialId);
      }
    }

    observer.subscribe({
      next: res => {
        this.credential = res;

        this.onCredentialUpserted.emit(res);
        this._notificationService.showSuccess(`Credential saved successfully.`, 'Success');

        this.testCredential();
      },
      error: error => {
        this.isTestingAndSaving = false;
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'Error');
      }
    })
  }

  onChangeVendor(model : {vendorName: string, companyCredentialId?: number }): void {
    if (this.scope === "User" || this.scope === 'TpoUser') {
      this.companyCredentialId = model.companyCredentialId;
    }
    this.credential.thirdPartyKeyValuePairs = [];

    this.getVendorSchema();
    this.resetCredentialConfig();
    this.setCredentialConfig();

    if (this.credential.credentialType === 'LosVendor' && this.kvPairs['LoanFolders']) {
      this.setLoanFolders();
    }
  }

  testCredential(): void {
    let body = {
      vendorName: this.credential.vendorName,
      userName: this.credential.userName,
      credentialId: this.credential.credentialId,
    };

    if (this.vendorSchema.hasPassword) {
      Object.assign(body, { password: this.credential.password });
    }

    this._systemLevelService
      .testLosCredential(body)
      .subscribe({
        next: res => {
          const modalRef = this._modalService.open(TestCredentialResultsModalComponent, Constants.modalOptions.medium);
          modalRef.componentInstance.testLosUser = res;
          modalRef.componentInstance.closeDialog.subscribe(() => {
            // Dismiss because we dont need to pass credential again.
            // Test is always after save which upserts credential.
            this.activeModal.dismiss();
          })
        },
        error: error => {
          this._notificationService.showError(
            error ? error.error && error.error.message ? error.error.message : error.error : `Unable to test ${this.credential.vendorName} credentials.`,
            'Error'
          );
        }
      }).add(() => this.isTestingAndSaving = false);
  }

  addLoanFolder = ({ value }) => {
    this.loanFolders.push({
      display: value.trim(),
      value: value.trim(),
    })

    const loanFolderKvp = this.kvPairs['LoanFolders'];

    if (loanFolderKvp) {
      loanFolderKvp.value = this.loanFolders
        .map((el) => el.value)
        .join(';');
    }
  }

  removeLoanFolder = ({ value }) => {
    const index = this.loanFolders.findIndex((el) => el.value === value);
    if (index === -1) return false;

    this.loanFolders.splice(index, 1);

    const loanFolderKvp = this.kvPairs['LoanFolders'];

    if (loanFolderKvp) {
      loanFolderKvp.value = this.loanFolders
        .map((el) => el.value)
        .join(';');
    }
  }

  private getVendorSchema() {
    this.vendorSchema = this.credentialSchema.find(schema => schema.vendorName === this.credential.vendorName);
    if (this.vendorSchema) {
      this.vendorSchema.kvPairs = this._thirdPartyCredentialsService.filterSchemaKvPairs(this.scope, this.vendorSchema.kvPairs);
    }
  }

  private setLoanFolders() {
    const loanFolders = this.kvPairs['LoanFolders']?.value;
    if (loanFolders) {
      this.loanFolders = loanFolders.split(';').map(v => ({ display: v, value: v }));
    }
  }

  private setCredentialConfig() {
    if (!this.vendorSchema?.kvPairs) return;

    this.vendorSchema.kvPairs.filter(kv => kv.scope === this.scope || kv.scope === "CompanyAndUser").forEach(kv => {
      const credentialKvp = this.credential.thirdPartyKeyValuePairs.find(kvp => kvp.key === kv.name);
      this.kvPairs[kv.name] = credentialKvp ? credentialKvp : new ThirdPartyKeyValue(kv.name, "");
    })
    this.additionalConfigValueFieldType = this._thirdPartyCredentialsService.getAdditionConfigValueFieldType(this.credential);
  }

  private resetCredentialConfig() {
    this.kvPairs = {};
    this.credential.password = null;
    this.credential.userName = null;
    this.credential.url = null;
    this.credential.alias = null;
  }

  private overwriteThirdPartyKeyValuePairs() {
    const displayedThirdPartyKvPairs = Object.values(this.kvPairs).map(val => val);
    this.credential.thirdPartyKeyValuePairs = this.credential.thirdPartyKeyValuePairs.filter(kvp => !displayedThirdPartyKvPairs.some(uiKvp => uiKvp.key === kvp.key));
    // overwrite existing ones
    this.credential.thirdPartyKeyValuePairs = [...this.credential.thirdPartyKeyValuePairs, ...displayedThirdPartyKvPairs];
  }
}
