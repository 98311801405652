import { Component, Injector, Input, OnInit } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'outstanding-conditions-card',
  templateUrl: 'outstanding-conditions-card.component.html',
  styleUrls: ['./outstanding-conditions-card.component.scss']
})
export class OutstandingConditionsCardComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  primaryBorrowerId: number;

  @Input()
  isTpoSubmitted: boolean = false;

  outstandingConditionsCount: string;

  constructor(private readonly _taskService: TaskService,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this._taskService.getTpoTaskCount(this.appId, 'Outstanding').subscribe((response) => {
      this.outstandingConditionsCount = response.toString();
    }, (err) => {
      this.outstandingConditionsCount = '??';
    });
  }
}
