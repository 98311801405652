<div class="card" [ngClass]="{'border-0': isDrawer}">
  <ng-template #spinner>
    <loading-indicator [loadingMessage]="'Loading agent details...'"></loading-indicator>
  </ng-template>
  <div class="card-body h-100 d-flex flex-column" *ngIf="!loading else spinner">
    <ul class="nav nav-tabs pe-0" role="tablist" [hidden]="agentFull.agent.agentId <= 0">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: tab === 'agentInfo' }" (click)="selectTab('agentInfo')"
          data-bs-toggle="tab" href="javascript: void(0)" role="tab">
          Agent Info
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: tab === 'applications' }" (click)="selectTab('applications')"
          data-bs-toggle="tab" href="javascript: void(0)" role="tab">
          Applications
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: tab === 'leads' }" (click)="selectTab('leads')" data-bs-toggle="tab"
          href="javascript: void(0)" role="tab">
          Leads
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: tab === 'history' }" (click)="selectTab('history')"
          data-bs-toggle="tab" href="javascript: void(0)" role="tab">
          Email/SMS History
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: tab === 'notes' }" (click)="selectTab('notes')" data-bs-toggle="tab"
          href="javascript: void(0)" role="tab">
          Notes
        </a>
      </li>
      <li class="nav-item" *ngIf="currentUserIsPrimaryRoleUser && wizardUrls.length > 0">
        <a class="nav-link" data-bs-toggle="dropdown" href="#" aria-expanded="false">
          Online App Links
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" *ngFor="let wizUrl of wizardUrls" (click)="copyLink(wizUrl)">
            {{wizUrl.name}}
          </a>
        </div>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ active: tab === 'companyInfo' }" (click)="selectTab('companyInfo')"
          data-bs-toggle="tab" href="javascript: void(0)" role="tab">
          Company Info
        </a>
      </li>
    </ul>
    <div [ngClass]="{'tab-content': agentFull.agent.agentId > 0 }">
      <div id="agentInfo" [ngClass]="{ 'tab-pane': agentFull.agent.agentId > 0, 'active': tab === 'agentInfo' }"
        role="tabpanel" *ngIf="tab === 'agentInfo'">
        <agent-info [agentFull]="agentFull" [hideSaveCloseButton]="isDrawer" [isManualDial]="isManualDial" (savedAgent)="savedAgent($event)"
          (closed)="closeWindow()"  (dialClicked)="onDialClicked.emit($event)"></agent-info>
      </div>

      <div id="applications" class="tab-pane p-3" [ngClass]="{ active: tab === 'applications' }" role="tabpanel"
        *ngIf="tab === 'applications'">
        <agent-applications [agentId]="agentId"></agent-applications>
      </div>

      <div id="leads" class="tab-pane p-3" [ngClass]="{ active: tab === 'leads' }" role="tabpanel"
        *ngIf="tab === 'leads'">
        <agent-leads [agentId]="agentId"></agent-leads>
      </div>

      <div id="history" class="tab-pane p-3" [ngClass]="{ active: tab === 'history' }" role="tabpanel"
        *ngIf="tab === 'history'">
        <correspondence-history-table *ngIf="agentMessageHistory; else loadingMessages" [history]="agentMessageHistory"
          [scrollOffsetInPixels]="304">
        </correspondence-history-table>
        <ng-template #loadingMessages>
          <loading-indicator [loadingMessage]="'Loading message history, please wait...'"></loading-indicator>
        </ng-template>
      </div>

      <div id="agentInfo" class="tab-pane p-3" [ngClass]="{ active: tab === 'notes' }" role="tabpanel"
        *ngIf="tab === 'notes'">
        <agent-notes [agentId]="agentId"></agent-notes>
      </div>

      <div id="agentInfo" class="tab-pane p-3" [ngClass]="{ active: tab === 'companyInfo' }" role="tabpanel"
        *ngIf="tab === 'companyInfo'">
        <agent-company-info [agentFull]="agentFull" (agentCompanySaved)="onAgentCompanySaved($event)"></agent-company-info>
      </div>
    </div>
  </div>
</div>
