<div class="left-sidenav tpo-report-card" id="{{uniqueId}}">
  <div class="menu-content h-100" data-simplebar>
    <ul class="metismenu left-sidenav-menu sticky" id="metismenu">
      <li (click)="onListItemClicked($event, 'loan-summary')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'loan-summary'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/loan-summary']">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Loan Summary</span>
        </a>
      </li>
      <li (click)="onListItemClicked($event, 'urla')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'urla'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/urla']">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>URLA 2020</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>
        <ul class="nav-second-level" aria-expanded="false"
          [ngClass]="{'mm-show': tab === 'urla'}">
          <urla-menu *ngIf="tab === 'urla'" (menuItemClicked)='onMenuItemClicked($event)'></urla-menu>
        </ul>
      </li>
      <li [hidden]="!featurePermissions.isServicesEnabled || isCompanyPulseProcessing" class="tpo-parent-menu-item mm-active" id="services-menu-list">
        <a style="color: #68728c!important" aria-disabled="true">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Services</span>
        </a>
        <ul aria-expanded="true" id="services-menu-ulist" style="padding-left: 19px!important;"
          class='nav-second-level metismenu left-sidenav-menu left-sidenav-menu-padding mm-show'>
          <menu-item [hidden]="!featurePermissions.isCreditAllowed" [label]="'Credit'" (menuItemClicked)="onMenuItemClicked($event)"
            [link]="'/tpo/app-details/' + loanId + '/credit-reports'" [status]="'pending'" [id]="'credit'"
            [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isVoiVoeAllowed" [label]="'VOI/VOE'" (menuItemClicked)="onMenuItemClicked($event)"
            [link]="'/tpo/app-details/' + loanId + '/digital-emp-income-verification'" [status]="'pending'"
            [id]="'voi-voe'" [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isVoaAllowed" [label]="'VOA'" (menuItemClicked)="onMenuItemClicked($event)"
            [link]="'/tpo/app-details/' + loanId + '/digital-asset-verification'" [status]="'pending'" [id]="'voa'"
            [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isAusAllowed" [label]="'AUS'" (menuItemClicked)="onMenuItemClicked($event)"
            [link]="'/tpo/app-details/' + loanId + '/aus'" [status]="'pending'" [id]="'aus'" [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isDisclosureGenerationAllowed" [label]="'Disclosures'" (menuItemClicked)="onMenuItemClicked($event)"
            [isActive]="tab === 'disclosures'"
            [link]="'/tpo/app-details/' + loanId + '/disclosures'" [status]="'pending'" [id]="'disclosures'" [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isAppraisalOrderAllowed || (isCompanyPRMG && ['Correspondent','NonDelegatedCorrespondent'].includes(applicationContext?.application?.channel))" [label]="'Appraisal'"
            (menuItemClicked)="onMenuItemClicked($event)" [link]="'/tpo/app-details/' + loanId + '/appraisal'"
            [status]="'pending'" [id]="'appraisal'" [groupId]="'services'">
          </menu-item>
        </ul>
      </li>
      <li [hidden]="!featurePermissions.isFeesEnabled || isCompanyPulseProcessing" (click)="onListItemClicked($event, 'fees')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'fees'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/fees']">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Fees</span></a>
      </li>
      <li [hidden]="!isManualPricingEntryAvailable" (click)="onListItemClicked($event, 'pricing')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'pricing'}">
         <a [routerLink]="['/tpo/app-details/' + loanId + '/edit-pricing']">
          <i class="status-indicator fas fa-lock-open" style="color: orange"> </i>
        <span>Pricing</span></a>
      </li>
      <li class="tpo-parent-menu-item" (click)="onListItemClicked($event, 'pricing')"
        [ngClass]="{'mm-active': tab === 'pricing'}" [hidden]="!featurePermissions.pricingEnabled || isManualPricingEntryAvailable">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/pricing']">
          <i class="status-indicator fas fa-lock-open mb-1" style="color: orange"> </i> <span>Pricing</span>
          <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
        </a>
        <ul class="nav-second-level" [attr.aria-expanded]="false"
          [ngClass]="{'mm-show': tab === 'pricing' || tab === 'pricing-history' || tab === 'application-pricing-scenarios' || tab === 'amortization'}">
          <li class="nav-item" [ngClass]="{'mm-active': tab === 'pricing-history'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/pricing-history']">
              <i class="status-indicator fas fa-question-circle text-warning"></i>
              <span> Pricing History </span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'mm-active': tab === 'application-pricing-scenarios'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/application-pricing-scenarios']">
              <i class="status-indicator fas fa-question-circle text-warning"></i>
              <span> Pricing Scenarios </span>
            </a>
          </li>
          <li *ngIf="featurePermissions.isAmortizationTableMenuItemVisible" class="nav-item"
            [ngClass]="{'mm-active': tab === 'amortization'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/amortization']">
              <i class="status-indicator fas fa-question-circle text-warning"></i>
              <span> Amortization Table </span>
            </a>
          </li>
        </ul>
      </li>
      <li class="tpo-parent-menu-item" (click)="onListItemClicked($event, 'conditions')"
        [ngClass]="{'mm-active': tab === 'conditions'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/conditions']">
          <span [ngClass]="{'menu-span-padding': false}">Conditions</span>
        </a>
      </li>
      <li class="tpo-parent-menu-item" (click)="onListItemClicked($event, 'submission')"
        [ngClass]="{'mm-active': tab === 'submission'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/submission']">
          <span [ngClass]="{'menu-span-padding': false}">Submission</span>
        </a>
      </li>
      <li class="tpo-parent-menu-item" (click)="setExpandedItems()"
        [ngClass]="{'mm-active': tab === 'change-of-circumstance' || tab === 'escalation-history' || tab === 'loan-docs' || tab === 'internal-contacts' || tab === 'key-dates' || tab === 'loan-activity'}">
        <a href="javascript: void(0);">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Additional</span>
          <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
        </a>
        <ul class="nav-second-level" style="list-style: none;" aria-expanded="false"
          [ngClass]="{'mm-show': tab === 'change-of-circumstance' || tab === 'escalation-history' || tab === 'loan-docs' || tab === 'internal-contacts' || tab === 'key-dates' || tab === 'loan-activity'}">
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" *ngIf="!(isCompanyPRMG && ['Correspondent'].includes(applicationContext?.application?.channel))"
            [ngClass]="{'mm-active': tab === 'change-of-circumstance'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/change-of-circumstance']">
              Change of Circumstance</a>
          </li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()"
            [ngClass]="{'mm-active': tab === 'escalation-history'}"><a class="nav-link"
              [routerLink]="['/tpo/app-details/' + loanId + '/escalation-history']">Escalation
              History</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" [ngClass]="{'mm-active': tab === 'loan-docs'}"><a
              class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/loan-docs']">Loan
              Docs</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()"
            [ngClass]="{'mm-active': tab === 'internal-contacts'}"><a class="nav-link"
              [routerLink]="['/tpo/app-details/' + loanId + '/internal-contacts']">Internal
              Contacts</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" [ngClass]="{'mm-active': tab === 'key-dates'}"><a
              class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/key-dates']">Key
              Dates</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" [ngClass]="{'mm-active': tab === 'loan-activity'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/loan-activity']">Loan
              Activity</a>
          </li>
          <!-- <li class="nav-item"><a class="nav-link" href="javascript: void(0);"><i
                class="ti-control-record"></i>Email/SMS History</a></li> -->
          <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/prequal-detail']"><i class="ti-control-record"></i>Prequal
              Letter</a></li> -->
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- end left-sidenav-->
