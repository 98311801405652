import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContext, UserPermissions } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EsignDocumentDialogComponent } from '../edit-task-dialog/components/esign-document-dialog/esign-document-dialog.component';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { DocumentSigningOrder } from 'src/app/modules/loan-docs/models/document-signing-order.model';
import * as moment from 'moment';

@Component({
  selector: 'task-editor',
  templateUrl: './task-editor.component.html',
  styleUrls: ['./task-editor.component.scss'],
})
export class TaskEditorComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  task: LoanDocDashboardTask;

  @Input()
  filterId: number;

  @Input()
  hostedInModal: boolean = false;

  @Input()
  taskReadonly: boolean;

  @Input()
  startESign: boolean;

  @Input()
  startDocumentGeneration: boolean;

  @Input()
  openFileUpload: boolean;

  @Input()
  refreshMentions: boolean = false;

  @Input()
  borrowers?: BorrowerDto[] = [];

  @Output()
  refreshClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  saveClicked: EventEmitter<any> = new EventEmitter<any>();

  protected globalConfig: GlobalConfig;

  private _eSignOrder: DocumentSigningOrder;

  private _userPermissions: UserPermissions;

  private _isTaskRefreshed: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _taskService: TaskService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _loanService: LoanService,
    private readonly _loanDocsService: LoanDocsService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.task.taskType === 'QuickClientUpdateReferralLead' || this.task.taskType === 'QuickClientUpdatePreApproved' || this.task.taskType === 'QuickClientUpdateInProcess')
      return;

    this.task.note = '';
    this.task.borrowerFacingNote = '';

    this.initializeContextBasedState(this.applicationContext);
    this.initialize();
  }

  private initializeContextBasedState = (context: ApplicationContext) => {
    this.globalConfig = context.globalConfig;
    this._userPermissions = context.userPermissions;
    if (!this.task.notifyPartyId) {
      this.task.notifyPartyId = '';
    }
  };

  private initialize = () => {

    this.taskReadonly = this.taskReadonly || (this._userPermissions.readOnlyTasks && this.task.userId != this._userPermissions.userId);

    if (this.task.taskType === 'Esign') {
      this.populateDataForEsignTask();
    }

    setTimeout(() => {
      this.initControls();
    }, 200);
  };

  private initControls = () => {
    if (this.startDocumentGeneration) {
      this.onGenerateDocumentClicked();
    } else if (this.startESign) {
      this.signDocument();
    }
  };

  saveTask = () => {
    if (!this.task.documentTypeId) {
      this.task.documentTypeId = null;
    }
    if (
      (!this.task.reviewPartyId || this.task.reviewPartyId == '') &&
      this.task.requiresReview
    ) {
      return this._notifyService.showError(
        'You must select a Review Party',
        'Error'
      );
    }

    if (
      (!this.task.loanDocTaskId || this.task.loanDocTaskId == 0) &&
      !this.task.multipleBorrower &&
      this.task.requestBorrower
    ) {
      return this._notifyService.showError(
        'You must assign a Borrower to this task.',
        'Error'
      );
    }

    if (this.task.notifyPartyId == '') {
      this.task.notifyPartyId = null;
    }

    if (
      (this.task.reviewPartyId && this.task.reviewPartyId == '') ||
      !this.task.requiresReview
    ) {
      this.task.reviewPartyId = null;
      this.task.requiresReview = false;
    }

    if (this.task.loanDocTaskId != 0) {
      //TODO - what is this twisted logic? Why are we getting the task back from the database???
      this._spinner.show();
      this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId)
        .subscribe({
          next: (response) => {
            this.task.loanDocId = response.loanDocId;
            if (this.task.followUpDate != response.followUpDate) {
              this.setFollowUpDate();
            }

            this.createOrUpdateTaskWithUploadedFiles(this.task);
          },
          error: (error) => {
            this._spinner.hide();
            this._notifyService.showError(
              error && error.error
                ? error.error.message
                : 'An error occurred while saving the task.',
              'Error!'
            );
          }
        });
    } else {
      this.setFollowUpDate();
      this.createOrUpdateTaskWithUploadedFiles(this.task);
    }
  };

  addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  onEsignTaskRefreshed = () => {
    this._isTaskRefreshed = true;
  }

  onDocumentGenerated = () => {
    this.task.taskStatus = this.task.requiresReview ? 'ReviewReady' : 'Completed';
    this.saveTask();
  }

  onDocumentGeneratedCanceled = () => {
    this.closeClicked.emit(this._isTaskRefreshed);
  }

  onGenerateDocumentClicked = () => {
    this.startDocumentGeneration = true;

    const selfDialog = document.querySelector(".modal-dialog") as HTMLElement;
    if (!selfDialog) return;

    selfDialog.style.width = "50%";
    selfDialog.style.maxWidth = "50%";
  }

  signDocument = () => {
    const modalRef = this._modalService.open(
      EsignDocumentDialogComponent,
      Constants.modalOptions.xlarge
    );
    modalRef.componentInstance.loanDocTaskId = this.task.loanDocTaskId;
    modalRef.componentInstance.documentSigningVendor = this._eSignOrder?.documentSigningVendor;

    modalRef.result.then((result) => {
      this.saveClicked.emit();
    });
  };

  onSaveClicked = () => {
    this.saveClicked.emit();
  }

  onCancelQcuTaskEditClicked = () => {
    this.closeClicked.emit(this._isTaskRefreshed);
  }

  private populateDataForEsignTask = () => {
    this._loanDocsService.getSigningOrder(this.task.loanDocTaskId).subscribe(
      (result) => {
        this._eSignOrder = result && result.length > 0 ? result[0] : null;
      },
      (error) => {
        this._notifyService.showError(
          error.message ||
          `Error getting eSign order for task: ${this.task.loanDocTaskId}.`,
          'Failure'
        );
      }
    );
  };

  private createOrUpdateTaskWithUploadedFiles = (task: LoanDocDashboardTask) => {

    const data = {
      loanDocTask: task,
      multipleBorrower: task.multipleBorrower,
      numFiles: task.docFiles.length,
      daysToPostpone: task.followUpDate
        ? moment(new Date(task.followUpDate), 'MM/DD/YYYY').diff(
          moment().startOf('day'),
          'days'
        )
        : 0,
    };
    this.upsertLoanDocTask(data);
  };


  upsertLoanDocTask = (data) => {
    this._spinner.show();
    this._taskService.upsertLoanDocTask(data).subscribe((responseTask) => {
      this.saveClicked.emit();
    },
      (error) => {
        this._spinner.hide();
        this._notifyService.showError(
          error && error.error
            ? error.error.message
            : 'An error occurred while saving the task.',
          'Error!'
        );
      }
    );
  };

  private setFollowUpDate = () => {
    if (!this.task.followUpDate) return;

    const followUpDate = new Date(this.task.followUpDate);
    const now = new Date();
    const date = new Date(followUpDate.getFullYear(), followUpDate.getMonth(), followUpDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    this.task.followUpDate = this.addDays(date, 1).toISOString();
  }
}

export class TaskImageProcessStatus {
  processedContent: string;
  numberOfImagesLeftToProcess: number;
}
