import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { remove } from 'lodash';
import { finalize, Subscription } from 'rxjs';
import { QuestionAnswer } from 'src/app/models/question-answer.model';
import { Question } from 'src/app/models/question.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CheckListService } from 'src/app/services/check-list.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { QuestionAnswerDetailsDialogComponent } from '../question-answer-details-dialog/question-answer-details-dialog.component';

@Component({
  selector: 'question-answers-list',
  templateUrl: './question-answers-dialog.component.html',
  styleUrls: ['./question-answers-dialog.component.scss']
})
export class QuestionAnswersDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() questionData: Question;

  answers: QuestionAnswer[] = [];
  selectedColumns: any[] = [];
  columns: any[] = [];
  globalFilterFields: string[] = [];
  borrowerCharacteristicList: Array<EnumerationItem> = [];
  loanCharacteristicList: Array<EnumerationItem> = [];
  tasks: Array<EnumerationItem> = [];
  isLoadingAnswers: boolean = false;
  isDeleteClicked = {};
  superAdmin: boolean = false;
  channelsEnabled: boolean;

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.channelsEnabled = context.globalConfig.enabledChannels.length > 0;
      this.superAdmin = context.userPermissions.superAdmin;
      this.borrowerCharacteristicList = context.globalConfig.borrowerCharacteristics.map(borrChar => ({
        value: borrChar.taskCategoryId,
        name: borrChar.enabledChannels
          ? `${borrChar.taskCategoryName} (${borrChar.enabledChannels})`
          : borrChar.taskCategoryName
      }));
      this.loanCharacteristicList = context.globalConfig.loanCharacteristics.map(loanChar => ({
        value: loanChar.taskCategoryId,
        name: loanChar.enabledChannels
          ? `${loanChar.taskCategoryName} (${loanChar.enabledChannels})`
          : loanChar.taskCategoryName
      }));
      this.tasks = context.globalConfig.tasks.map(task => ({
        value: task.taskId,
        name: task.channel
          ? `${task.taskName} (${task.channel})`
          : task.taskName
      }));
    });

    this.columns = [
      { field: 'answerText', header: 'Answer Text', order: 1, visible: true }
    ]

    this.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });
    this.getQuestionAnswers();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  getQuestionAnswers = (): void => {
    if (!this.questionData.questionId) {
      return;
    }
    this.isLoadingAnswers = true;

    this._checkListService.getAllQuestionAnswers(this.questionData.questionId)
      .pipe(finalize(() => this.isLoadingAnswers = false))
      .subscribe({
        next: (result) => {
          this.answers = result || [];
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'could not fetch associated answers', 'Error!');
        }
      });
  }

  addNewQuestionAnswer = (): void => {
    let modalRef = this._modalService.open(QuestionAnswerDetailsDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.questionId = this.questionData.questionId;
    modalRef.componentInstance.loanCharacteristicList = this.loanCharacteristicList;
    modalRef.componentInstance.borrowerCharacteristicList = this.borrowerCharacteristicList;
    modalRef.componentInstance.tasks = this.tasks;
    modalRef.componentInstance.channelsEnabled = this.channelsEnabled;

    modalRef.result.then((newlyAddedAnswer: QuestionAnswer) => {
      const matchingIndex = this.answers.findIndex(ans => ans.questionAnswerId === newlyAddedAnswer.questionAnswerId);
      if (matchingIndex > -1) {
        this.answers[matchingIndex] = newlyAddedAnswer;
      } else {
        this.answers.push(newlyAddedAnswer);
      }
      this.answers = [...this.answers];
    }, () => {
    });
  }

  onEditQuestionAnswerClicked = (questionAnswer: QuestionAnswer) => {
    let modalRef = this._modalService.open(QuestionAnswerDetailsDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.questionId = this.questionData.questionId;
    modalRef.componentInstance.questionAnswer = questionAnswer;
    modalRef.componentInstance.loanCharacteristicList = this.loanCharacteristicList;
    modalRef.componentInstance.borrowerCharacteristicList = this.borrowerCharacteristicList;
    modalRef.componentInstance.tasks = this.tasks;
    modalRef.componentInstance.channelsEnabled = this.channelsEnabled;

    modalRef.result.then((updatedAnswer: QuestionAnswer) => {
      const matchingIndex = this.answers.findIndex(ans => ans.questionAnswerId === updatedAnswer.questionAnswerId);
      if (matchingIndex > -1) {
        this.answers[matchingIndex] = updatedAnswer;
      } else {
        this.answers.push(updatedAnswer);
      }
      this.answers = [...this.answers];
    }, () => {
    });
  }

  onDeleteQuestionAnswerClicked = (questionAnswerId: number) => {
    const matchingAnswer = this.answers.find(ans => ans.questionAnswerId === questionAnswerId);
    if (!matchingAnswer) {
      this._notifyService.showError('could not find the answer', 'Error!');
      return;
    }
    if (questionAnswerId < 0) {
      remove(this.answers, matchingAnswer);
      this.answers = [...this.answers];
      return;
    }
    this._checkListService.deleteQuestionAnswer(questionAnswerId)
      .subscribe({
        next: () => {
          remove(this.answers, matchingAnswer);
          this.answers = [...this.answers];
          this._notifyService.showSuccess("Question answer removed succesfully", "Success");

        }, error: (err) => {
          this._notifyService.showError(err.error.message || "Error encountered while deleting question answer", "Error!");
        }
      });
  }
}
