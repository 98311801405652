import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { UrlaBorrower } from 'src/app/modules/urla/models/urla-mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  selector: 'review-data-borrowers',
  templateUrl: './review-data-borrowers.component.html',
  styleUrls: ['./review-data-borrowers.component.scss'],
  viewProviders: [formViewProvider],
})
export class ReviewDataBorrowersComponent implements OnInit {

  @Input()
  isCompanyDeepHaven: boolean = false

  @Input()
  set mortgageBorrowers(mortgageBorrowers: UrlaBorrower[]) {
    this._mortgageBorrowers = mortgageBorrowers;
    this.applications = [];
    this.detailsVisible = {};
    this.initPrintIndex();
    this.sortBorrowers();
    this.initApplications();
    this.arrangeApplications();
  }

  get mortgageBorrowers(): UrlaBorrower[] {
    return this._mortgageBorrowers;
  }

  applications = [];

  detailsVisible: {} = {};

  mortgagePartyTypeOptions: EnumerationItem[] = [];

  private _mortgageBorrowers: UrlaBorrower[] = [];

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _enumsService: EnumerationService) {
  }

  ngOnInit() {
    this._spinner.show();
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this._spinner.hide();
      this.mortgagePartyTypeOptions = [...result[Constants.mortgageEnumerations.mortgagePartyType]];
      if (this.isCompanyDeepHaven) {
        const indexOfTrustOption = this.mortgagePartyTypeOptions.findIndex((option) => option.value === 'Trust');
        if (indexOfTrustOption > -1) {
          this.mortgagePartyTypeOptions.splice(indexOfTrustOption, 1);
        }
      }
    }, (err) => {
      this._spinner.hide();
    })
  }

  public getBorrowers() {
    const savedBorrowers = [];

    for (let appIndex = 0; appIndex < this.applications.length; appIndex++) {
      let borrowers = this.applications[appIndex];

      for (let borrowerIndex = 0; borrowerIndex < borrowers.length; borrowerIndex++) {
        borrowers[borrowerIndex].printApplicationIndex = appIndex;
        borrowers[borrowerIndex].jointWithBorrowerId = borrowerIndex == 0 ? null : borrowers[0].borrowerId;
        savedBorrowers.push(borrowers[borrowerIndex]);
      }
    }
    return savedBorrowers;
  }

  protected drop(event: CdkDragDrop<number[]>) {
    // inside same container
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    // between containers
    else {
      // add borrower
      if (event.container.data.length < 2) {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
        this.arrangeApplications();
      }
      // swap borrowers
      else {
        const dataValue = event.container.data[event.currentIndex];
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);

        const dataIndex = event.container.data.findIndex(i => i === dataValue);
        transferArrayItem(event.container.data,
          event.previousContainer.data,
          dataIndex,
          event.previousIndex);
      }
    }
  }

  protected maskSsnNumber = (ssnNumber: string) => {
    if (ssnNumber) {
        let arr = ssnNumber.split('');
        for (let i = 0; i < arr.length - 4; i++) {
            if (arr[i] != '-') {
                arr[i] = '#';
            }
        }
        return arr.join('');
    }
  }

  protected formatMailingState = (state: string) => {
    if (state == null || state == '') {
        return '—';
    } else {
        return state.toUpperCase();
    }
  };

  private initPrintIndex() {
    let maxPrintApplicationIndex = Math.max.apply(Math, this.mortgageBorrowers.map(o => o.printApplicationIndex));

    let nextIndex = Number.isNaN(maxPrintApplicationIndex) ? 0 : maxPrintApplicationIndex++;
    for (const borrower of this.mortgageBorrowers) {
      if (borrower.printApplicationIndex == undefined || borrower.printApplicationIndex == null) {
        if (borrower.jointWithBorrowerId) {
          let borr = this.mortgageBorrowers.find(b => b.borrowerId == borrower.jointWithBorrowerId);
          if (borr) {
            borrower.printApplicationIndex = borr.printApplicationIndex;
          } else {
            borrower.printApplicationIndex = nextIndex;
            nextIndex++;
          }
        } else {
          borrower.printApplicationIndex = nextIndex;
          nextIndex++;
        }
      }
      this.detailsVisible[borrower.borrowerId] = false;
    }
  }

  private sortBorrowers() {
    this._mortgageBorrowers = _.orderBy(this.mortgageBorrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);
  }

  private initApplications() {
    for (const borrower of this.mortgageBorrowers) {
      if (this.applications.length <= borrower.printApplicationIndex) {
        this.applications.push([]);
      }
      if (!this.applications[borrower.printApplicationIndex]) {
        this.applications[borrower.printApplicationIndex] = [];
      }

      this.applications[borrower.printApplicationIndex].push(borrower);
    }
  }

  private arrangeApplications() {
    this.deleteEmptyContainers();
    if (this.mortgageBorrowers.length > 1) {
      if(!this.applications.every(app => app.length === 1)) {
        this.applications.push([]);
      }
    }
  }

  private deleteEmptyContainers() {
    for (let i = 0; i <= this.applications.length - 1; i++) {
      if (this.applications[i].length === 0) {
        this.applications.splice(i, 1);
        i--;
      }
    }
  }
}
