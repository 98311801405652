import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NotificationService } from '../../../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomCssService } from '../../services/custom-css.service';
import { StyleSheetUseType } from '../../models/style-sheet-use-type.model';

@Component({
  selector: 'custom-css-controls',
  templateUrl: './custom-css-controls.component.html',
  styleUrls: ['./custom-css-controls.component.scss'],
})
export class CustomCssControlsComponent {

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  @Input()
  title: string;

  @Input()
  config: CustomCssControlsConfig;

  private get useType(): StyleSheetUseType {
    return this.config.useType;
  }

  protected get filename(): string {
    return this.config.filename;
  }

  protected get filepath(): string {
    return this.config.filepath;
  }

  @Output()
  cssFileChange: EventEmitter<CustomCssFileChangeReason> = new EventEmitter<CustomCssFileChangeReason>();

  constructor(
    private readonly _customCssService: CustomCssService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
  ) {
  }

  onViewYourCssFileClicked = () => {
    this._spinner.show();
    this._customCssService.download(this.useType)
      .subscribe({
        next: (data) => {

          const blob = new Blob([data], { type: 'text/css' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: (error) => {
          const title = 'Info!';
          if (error.status === 404) {
            this._notificationService.showInfo('You have not uploaded a css file yet.', title);
          } else {
            this._notificationService.showInfo('An error occurred while downloading css override file. ', title);
            console.error(error);
          }
        },
      }).add(() => {
      this._spinner.hide();
    });
  };

  onUploadYourCssFileClicked = () => {
    this.fileInput.nativeElement.click();
  };

  onResetToDefaultClicked = () => {
    this._spinner.show();

    this._customCssService.delete(this.useType).subscribe({
      next: () => {
        this._notificationService.showSuccess('Successfully deleted css override file.', 'Success!');
        this.cssFileChange.emit(CustomCssFileChangeReason.Deleted);
      },
      error: error => {
        this._notificationService.showError((error && error.message ? error.message : 'An error occurred while deleting css override file. '), 'Error!');
      },
    }).add(() => {
      this._spinner.hide();
    });
  };

  onCssFileChanged = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget.files[0];

    let fileType = file.name.split('.').pop();
    if (fileType !== 'css') {
      this._notificationService.showError(
        'Only .css files are allowed to upload.',
        'Error!',
      );
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this._spinner.show();

    this._customCssService.upload(this.useType, formData).subscribe({
      next: () => {
        this._notificationService.showSuccess('Successfully uploaded css override file.', 'Success!');
        this.cssFileChange.emit(CustomCssFileChangeReason.Updated);
      }, error: error => {
        this._notificationService.showError((error && error.message ? error.message : 'An error occurred while uploading css override file. '), 'Error!');
      },
    }).add(() => {
      this._spinner.hide();

      this.fileInput.nativeElement.value = '';
    });
  };
}

export class CustomCssControlsConfig {
  constructor(
    readonly useType: StyleSheetUseType,
    readonly filename: string,
    readonly fileDir: string = "/assets/css",
  ) {
    this.filepath = `${fileDir}/${filename}`;
  }

  readonly filepath: string;

  static Tpo = () => new CustomCssControlsConfig(
    StyleSheetUseType.Tpo,
    'tpo-override-unset.css',
  );

  static BorrowerPortal = () => new CustomCssControlsConfig(
    StyleSheetUseType.BorrowerPortal,
    'borrower-portal-override-unset.css',
  );

  static AgentPortal = () => new CustomCssControlsConfig(
    StyleSheetUseType.AgentPortal,
    'agent-portal-override-unset.css',
  );

  static OnlineApp = () => new CustomCssControlsConfig(
    StyleSheetUseType.OnlineApp,
    'online-application-override-unset.css',
  );
}

export enum CustomCssFileChangeReason {
  Updated,
  Deleted,
}
