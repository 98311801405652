<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Document Signing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Document Signing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm" novalidate>
    <div class="row">
      <div class="col-md-12">
        <credential-modal-basic [scope]="scope" [credential]="credential" [disabledChannel]="disabledChannel">
        </credential-modal-basic>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <label class="custom-control-label">Username</label>
        <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
      </div>
      <div class="col-md-6">
        <label class="custom-control-label">Account Id</label>
        <input type="text" name="url" class="form-control"
          [ngClass]="{'is-invalid' : accountId && accountId.touched && accountId.invalid}"
          [(ngModel)]="keyValuePairs.accountId.value" #accountId="ngModel" required />
        <div class="invalid-feedback">Accound id is required.</div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <label class="custom-control-label">Document Type</label>
        <select class="form-select" name="savedDocumentTypeId" [(ngModel)]="keyValuePairs.savedDocumentTypeId.value">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let item of documentTypes" value={{item.documentTypeId}}>
            {{item.documentTypeName}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input id="useCustomAccount" name="useCustomAccount" class="form-check-input" type="checkbox"
            [(ngModel)]="keyValuePairs.useCustomAccount.value" ToggleValue [true]="'1'" [false]="'0'">
          <label class="form-check-label" for="useCustomAccount">
            Use Default Account
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input id="suppressDocuSignEmails" name="suppressDocuSignEmails" class="form-check-input" type="checkbox"
            [(ngModel)]="keyValuePairs.suppressDocuSignEmails.value" ToggleValue [true]="'1'" [false]="'0'">
          <label class="form-check-label" for="suppressDocuSignEmails">
            Suppress DocuSign emails and make user sign through Lodasoft
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input id="reviewRequired" name="reviewRequired" class="form-check-input" type="checkbox"
            [(ngModel)]="keyValuePairs.reviewRequired.value" ToggleValue [true]="'1'" [false]="'0'">
          <label class="form-check-label" for="reviewRequired">
            Auto select "Review Required" on eSign tasks
          </label>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
