<table class="table mb-0">
  <thead>
    <tr class="bg-striped">
      <th class="w-33">
        <span class="fw-bold"> {{borrower.firstName + ' ' + borrower.lastName}} </span>
      </th>
      <th>
        <span class="fw-bold">  </span>
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="w-45">
        <span [ngClass]="{'fw-bold': showEmploymentsMonthlyIncome}"> Employment(s) </span>
        <i (click)="showEmploymentsMonthlyIncome = !showEmploymentsMonthlyIncome" title="Show the Detail"
          class="text-muted cursor ms-1 fas"
          [ngClass]="{'fa-eye': !showEmploymentsMonthlyIncome, 'fa-eye-slash': showEmploymentsMonthlyIncome}"></i>
      </td>
      <td>
        <span [ngClass]="{'fw-bold': showEmploymentsMonthlyIncome}">
          {{totalEmploymentIncome | currency}}
        </span>
      </td>
      <td class="text-end">
        <button class="btn btn-sm btn-soft-primary" (click)="onAddEmploymentClicked()"> Add Employment </button>
      </td>
    </tr>
    <ng-container *ngIf="showEmploymentsMonthlyIncome">
      <tr>
        <td colspan="3">
          <employment-income-in-detail
            #employmentInDetail
            [employments]="borrower.employments"
            (incomesChanged)="incomesChanged()">
          </employment-income-in-detail>
        </td>
      </tr>
    </ng-container>
    <tr>
      <td>
        <span [ngClass]="{'fw-bold': showOtherIncome}"> Other Income </span>
        <i (click)="showOtherIncome = !showOtherIncome" title="Show the Detail" class="text-muted cursor ms-1 fas"
          [ngClass]="{'fa-eye': !showOtherIncome, 'fa-eye-slash': showOtherIncome}"></i>
      </td>
      <td>
        <span [ngClass]="{'fw-bold': showOtherIncome}"> {{totalOtherIncome | currency}} </span>
      </td>
      <td class="text-end">
        <button class="btn btn-sm btn-soft-primary" (click)="onAddOtherIncomeClicked()"> Add Other Income </button>
      </td>
    </tr>
    <ng-container *ngIf="showOtherIncome">
      <tr>
        <td colspan="3">
          <other-income-in-detail
            #otherInDetail
            [incomes]="borrower.nonEmploymentIncomes"
            (incomesChanged)="incomesChanged()">
          </other-income-in-detail>
        </td>
      </tr>
    </ng-container>
    <tr class="bg-striped">
      <td class="text-end">
        <span class="fw-bold me-2"> Total Monthly Income </span>
      </td>
      <td> <span class="fw-bold"> {{borrower.calculatedStats?.totalMonthlyIncome | currency}} </span>
      </td>
      <td></td>
    </tr>
  </tbody>
</table>
