import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mortgage } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { PricingEvents } from '../../models/pricing/pricing-events';
import { Adjustment } from '../../models/pricing/pricing-quote.model';
import { PricingService } from '../../services/pricing.service';
import { ViewAdjustmentsDialogComponent } from '../view-adjustments-dialog/view-adjustments-dialog.component';

@Component({
  selector: 'pricing-history-table',
  templateUrl: 'pricing-history-table.component.html',
  styleUrls: ['./pricing-history-table.component.scss']
})
export class PricingHistoryTableComponent extends ApplicationContextBoundComponent implements OnInit {

  pricingHistory: PricingEvents[];

  pricingHistoryColumns: any[] = [];

  private _currentMortgage: Mortgage;

  adjustments: Adjustment[] = [];

  isLoading: boolean = false;

  @Input()
  set currentMortgage(mortgage: Mortgage) {
    if (mortgage) {
      this._currentMortgage = mortgage;
      this.getProductPricingHistory();
    }
  }

  get currentMortgage(): Mortgage {
    return this._currentMortgage;
  }

  constructor(
    private injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _modalService: NgbModal,
    private readonly _notifsService: NotificationService
  ) {
    super(injector);
    this.applicationContextService.context.subscribe(context => {
      this._currentMortgage = context.currentMortgage;
      this.getProductPricingHistory();
    })
  }

  ngOnInit() {
    this.pricingHistoryColumns = [
      { field: 'eventDate', header: 'Event Date', order: 1, visible: true, sortable: false },
      { field: 'eventAction', header: 'Action', order: 2, visible: true, sortable: false },
      { field: 'productName', header: 'Product Name', order: 3, visible: true, sortable: false },
      { field: 'rate', header: 'Rate', order: 4, visible: true, sortable: false },
      { field: 'price', header: 'Price', order: 5, visible: true, sortable: false },
      { field: 'lockTerm', header: 'Lock Term', order: 6, visible: true, sortable: false },
      { field: 'lockDate', header: 'Lock Date', order: 7, visible: true, sortable: false },
      { field: 'lockExpirationDate', header: 'Lock Expiration Date', order: 8, visible: true, sortable: false },
      { field: 'adjustments', header: 'View Adjustments', order: 9, visible: true, sortable: false },
    ];
  }

  getProductPricingHistory() {
    this.isLoading = true;
    this._pricingService
      .getProductPricingHistory(this._currentMortgage.applicationId)
      .subscribe({
        next: (response) => {
          // descending sorting by the event date field
          response.sort((d1, d2) => new Date(d2.eventDate).getTime() - new Date(d1.eventDate).getTime());

          this.pricingHistory = response;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this._notifsService.showError(
            err ? err.message : 'Unable to request Pricing History',
            'Pricing request failed!'
          );
        }
      });
  }

  onViewAdjustmentsClicked = (pricingEventId: number) => {
    const pricing = this.pricingHistory.find(h => h.pricingEventId === pricingEventId);
    if (pricing) {
      const modalRef = this._modalService.open(ViewAdjustmentsDialogComponent, Constants.modalOptions.medium);
      modalRef.componentInstance.adjustments = pricing.pricingAdjustments;
    }
  };
}
