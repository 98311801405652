import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { chain, isNil } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, Borrower, Branch, Configuration, CustomData, ExternalCompany, HousingExpense, Income, LoanApplication, MortgageBorrower } from 'src/app/models';
import { AddressLookupResult } from 'src/app/models/address-lookup-result.model';
import { LeadCampaign } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanProduct } from 'src/app/models/config/product.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { SolarInfoModel, SolarPaymentMethodEnum, SolarProjectTypeEnum, SolarPropertyTypeEnum } from 'src/app/models/loan/solar-info.model';
import { MortgageDti } from 'src/app/models/mortgage-dti.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { UpsertReferralSourceComponent } from 'src/app/modules/referral-source/components/upsert-referral-source/upsert-referral-source.component';
import { AgentService } from 'src/app/services/agent.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { AgentFull } from '../../../models/agent.model';
import { AppDetailsService } from '../../../services/app-details.service';
import { AddressDetailViewDialogComponent } from '../address-detail-view-dialog/address-detail-view-dialog.component';
import { AnnualUsageDialogComponent } from '../annual-usage-dialog/annual-usage-dialog.component';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';


@Component({
  selector: 'loan-summary-solar',
  templateUrl: './loan-summary-solar.component.html',
  styleUrls: ['./loan-summary-solar.component.scss']
})
export class LoanSummarySolarComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("loanForm")
  loanForm: NgForm;

  @Output()
  dialClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loadingStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  editModeToggle: boolean = false;
  editorMode: string = 'Inline';
  application: LoanApplication;

  proposedHousingExpenseTotal: number;
  currentHousingExpenseTotal: number;
  purchaseCreditsTotal: number;
  reoTotal: number;
  assetTotal: number;
  liabilityTotal: number;
  incomeTotal: number;
  totalMonthlyDebts: number;
  housingPaymentsTotal: number;
  unPaidBalanceTotal: number;

  currentHousingExpense: HousingExpense;
  proposedHousingExpense: HousingExpense;

  borrowers: MortgageBorrower[] = [];
  loanBorrowers: Borrower[] = [];
  incomes: Income[] = [];

  totalDownPayment: number;
  downPaymentSources: string;

  mortgageDti: MortgageDti = null;

  piTiInfo: any;
  totalPiTi: number;

  haveMapError: boolean = false;

  mapId: number = Utils.getUniqueId();

  companyId: number;
  enabledChannels: EnumerationItem[] = [];
  externalCompanies: ExternalCompany[] = [];
  externalCompanyBranches: Branch[] = [];
  externalCompanyBranchesFiltered: Branch[] = [];

  agents: ReferralSource[] = [];
  users: User[] = [];

  leadCampaigns: LeadCampaign[] = [];

  customData: CustomData;

  allLoanPurposes: LoanPurpose[] = [];
  products: LoanProduct[] = [];

  productTypes: EnumerationItem[] = [];
  attachmentTypes: EnumerationItem[] = [];
  paymentMethods: EnumerationItem[] = [];
  utilityProviders: EnumerationItem[] = [];
  projectTypes: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  amortizationTypes: EnumerationItem[] = [];
  mortgageAppliedForTypes: EnumerationItem[] = [];
  governmentRefinanceTypes: EnumerationItem[] = [];
  propertyOccupancyTypes: EnumerationItem[] = [];
  loanPurposeOptions: EnumerationItem[] = [];
  loanTypeOptions: EnumerationItem[] = [];

  yesNoOptions: EnumerationItem[] = [
    { name: "Yes", value: true },
    { name: "No", value: false }
  ];

  showPrequalDetailsSection: boolean = false;
  showCurrentLoanInfoSection: boolean = false;
  secondPageActionBarVisible: boolean = false;

  states: EnumerationItem[] = [];

  isSubjectPropertyAddressHidden: boolean = false;

  private _loanPurposeValueChanged: boolean;
  private _oldLoanPurposeValue: number;

  constructor(
    private readonly injector: Injector,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _loanService: LoanService,
    private readonly _agentService: AgentService,
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.applicationContextService.context.subscribe(appContext => {
      this.initialize(appContext)
    });

    this.applicationContextService.loanInfoChanges.subscribe(appContext => {
      if (appContext.application) {
        this.initialize(appContext);
      }
    });
  }

  ngAfterViewInit() {
    if (this.application) {
      this.initPropertyAddressMap();
    }
  }

  private initialize = async (appContext: ApplicationContext): Promise<void> => {
    this.loadingStateChange.emit(true);

    this.secondPageActionBarVisible = appContext.isCallControlPanelOpen;
    this.application = _.cloneDeep(appContext.application);
    if (!this.application.solar)
      this.application.solar = new SolarInfoModel();

    this.companyId = appContext.userPermissions.companyId;
    this.editModeToggle = appContext.isLoanEditModeOpen;
    this.editorMode = appContext.isLoanEditModeOpen ? 'Classic' : 'Inline';

    this.enabledChannels = _.cloneDeep(appContext.globalConfig.enabledChannels);
    this.externalCompanies = _.cloneDeep(appContext.globalConfig.externalCompanies);
    this.users = _.cloneDeep(appContext.globalConfig.users) || [];
    this.users.forEach(u => {
      u["userFullName"] = this.getUserFullName(u.userCompanyGuid);
    });

    this.leadCampaigns = _.cloneDeep(appContext.globalConfig.leadCampaigns.filter(x => x.active || x.leadCampaignId == this.application.leadCampaignId)) || [];
    this.customData = _.cloneDeep(appContext.customData);
    this.allLoanPurposes = _.cloneDeep(appContext.globalConfig.loanPurpose);
    this.products = _.cloneDeep(appContext.globalConfig.product) || [];

    this._oldLoanPurposeValue = this.application.loanPurposeId;

    await Promise.all([
      this.loadLoanHiddenFields(),
      ...(this.companyId != null ? [this.loadCompanyBranches()] : []),
      ...(this.application != null ? [
        this.loadLoanBorrowers(this.application),
        this.loadAgents(),
        this.onChannelSelect(this.application.channel),
        this.onExternalCompanySelect(this.application.externalCompanyId)
      ] : []),
      this.loadEnums(),
    ]);

    this.loadingStateChange.emit(false);
  }

  initPropertyAddressMap = () => {

    let queryAddress = this.application.solar?.address;

    if (!queryAddress) {
      return;
    }

    let el = document.getElementById("propertyAddressMap" + this.mapId) as HTMLElement;
    if (!el) {
      return;
    }

    const map = new google.maps.Map(el);

    let neighbourhoodService = new google.maps.places.PlacesService(map);

    if (this.application.solar.city) {
      queryAddress += ', ' + this.application.solar.city;
    }

    if (this.application.solar.state) {
      queryAddress += ', ' + this.application.solar.state.toUpperCase();
    }

    if (this.application.solar.zip) {
      queryAddress += ' ' + this.application.solar.zip;
    }

    neighbourhoodService.textSearch({ query: queryAddress }, (results: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus, pagination: google.maps.places.PlaceSearchPagination) => {

      if (status == google.maps.places.PlacesServiceStatus.OK && results.length) {

        let location = results[0].geometry.location;
        let placeId = results[0].place_id;

        let mapProp: google.maps.MapOptions = {
          center: location,
          zoom: 18,
          gestureHandling: "none",
          streetViewControl: false,
          zoomControl: false,
          fullscreenControl: false,
          keyboardShortcuts: false,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          tilt: 0
        };

        map.setOptions(mapProp);

        const marker = new google.maps.Marker({
          position: location,
          map: map,
          icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|FFFF00',
          label: { text: this.application.solar.address, color: "white", fontWeight: "bold", className: "property-address-marker" }
        } as google.maps.ReadonlyMarkerOptions);

        google.maps.event.addListener(marker, 'click', () => {
          this.openPropertyAddressDialog(placeId)
        });

        google.maps.event.trigger(map, "resize");

      }
      else {
        this.haveMapError = true;
      }
    })

  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.application.solar.state = zipCode.state?.toLowerCase();
      this.application.solar.city = Utils.toTitleCase(zipCode.city);
      this.application.solar.zip = zipCode.zipcode;
    }
  }

  onHandleAddressChange = (lookupResult: AddressLookupResult) => {
    this.application.solar.address = null; // to reset the last populated address.

    if (!lookupResult) {
      this.application.solar.city = null;
      this.application.solar.state = null;
      this.application.solar.zip = null;
    }
    else {
      if (lookupResult.city) {
        this.application.solar.city = lookupResult.city;
      }

      if (lookupResult.state) {
        this.application.solar.state = lookupResult.state;
      }

      if (lookupResult.zipCode) {
        this.application.solar.zip = lookupResult.zipCode;
      }

      if (lookupResult.address) {
        this.application.solar.address = lookupResult.address;
      }
    }

    this.initPropertyAddressMap();

  }

  onBorrowerDeleted = (loanBorrowers: Borrower[]) => {
    this.loanBorrowers = loanBorrowers;
    this._loanService.getApplicationModel(this.application.applicationId, true).subscribe(loanInfoModel => {
      this.applicationContextService.updateMortgageAndApplication(loanInfoModel.mortgageLoan, loanInfoModel, undefined, loanBorrowers);
      this._spinner.hide();
    }, (error) => {
      this._spinner.hide();
      this._notifyService.showError(
        error ? error.message : 'Unable to reload loan',
        'Error!'
      );
    });
  }

  onBorrowerUpdated = (loanBorrowers: Borrower[]) => {
    this.applicationContextService.updateBorrowers(loanBorrowers);
  }

  onUpdatedPrimaryBorrower = (data: { application: LoanApplication, borrowers: Borrower[] }) => {
    this.application = data.application;
    let mortgage = data.application.mortgageLoan || null; // compatibilty: admin is deployed before api

    this.applicationContextService.updateMortgageAndApplication(mortgage, this.application, this.customData, data.borrowers);
  }

  loadEnums = () => {
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.productTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
      this.attachmentTypes = result[Constants.mortgageEnumerations.attachmentType];
      this.propertyOccupancyTypes = result[Constants.enumerations.propertyTypes];

      this.lienPositionTypes = result[Constants.mortgageEnumerations.lienPositionType];
      this.amortizationTypes = result[Constants.mortgageEnumerations.amortizationType];
      this.mortgageAppliedForTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
      this.governmentRefinanceTypes = result[Constants.mortgageEnumerations.governmentRefinanceType];

      let loanPurposes = this.applicationContext.globalConfig.loanPurpose || [];
      this.loanPurposeOptions = loanPurposes.map(lt => new EnumerationItem(lt.loanPurposeName, lt.loanPurposeId));

      let loanTypes = this.applicationContext.globalConfig.loanType || [];
      this.loanTypeOptions = loanTypes.map(lt => new EnumerationItem(lt.loanTypeName, lt.loanTypeId));
    });

    this.states = this._enumsService.states;

    this.projectTypes = [
      new EnumerationItem(SolarProjectTypeEnum.Commercial, SolarProjectTypeEnum.Commercial),
      new EnumerationItem(SolarProjectTypeEnum.Residential, SolarProjectTypeEnum.Residential)
    ]

    this.paymentMethods = [
      new EnumerationItem(SolarPaymentMethodEnum.Cash, SolarPaymentMethodEnum.Cash),
      new EnumerationItem(SolarPaymentMethodEnum.Finance, SolarPaymentMethodEnum.Finance)
    ]

    this.propertyTypes = [
      new EnumerationItem(SolarPropertyTypeEnum.SFRAttached, SolarPropertyTypeEnum.SFRAttached),
      new EnumerationItem(SolarPropertyTypeEnum.SFRDetached, SolarPropertyTypeEnum.SFRDetached),
      new EnumerationItem(SolarPropertyTypeEnum.Condominium, SolarPropertyTypeEnum.Condominium),
      new EnumerationItem(SolarPropertyTypeEnum.Cooperative, SolarPropertyTypeEnum.Cooperative),
      new EnumerationItem(SolarPropertyTypeEnum.Manufactured, SolarPropertyTypeEnum.Manufactured),
      new EnumerationItem(SolarPropertyTypeEnum.Modular, SolarPropertyTypeEnum.Modular),
      new EnumerationItem(SolarPropertyTypeEnum.PUD, SolarPropertyTypeEnum.PUD),
      new EnumerationItem(SolarPropertyTypeEnum.MultiFamilyResidence, SolarPropertyTypeEnum.MultiFamilyResidence),
      new EnumerationItem(SolarPropertyTypeEnum.Other, SolarPropertyTypeEnum.Other),
    ]
  }

  openPropertyAddressDialog = (placeID: string) => {
    let modalRef = this._modalService.open(AddressDetailViewDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.googlePlaceId = placeID;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  getWords = (str: string) => {
    return Utils.splitCamelCaseString(str);
  }

  getPersonFullNames = (borrowerIds: number[], isLoanBorrower: boolean = false): string => {
    let borrowerArray = isLoanBorrower ? this.loanBorrowers : this.borrowers as any[];
    let matchedPersons = borrowerIds.map(id => borrowerArray.find(b => b.borrowerId == id));
    return matchedPersons.length > 0 ? matchedPersons.map(b => Utils.getBorrowerFullName(b)).join(",") : null;
  }

  getUsageTotal = () => {
    if (!this.application.solar) return 0;

    return (this.application.solar.janUsage ?? 0)
      + (this.application.solar.febUsage ?? 0)
      + (this.application.solar.marUsage ?? 0)
      + (this.application.solar.aprUsage ?? 0)
      + (this.application.solar.mayUsage ?? 0)
      + (this.application.solar.junUsage ?? 0)
      + (this.application.solar.julUsage ?? 0)
      + (this.application.solar.augUsage ?? 0)
      + (this.application.solar.sepUsage ?? 0)
      + (this.application.solar.octUsage ?? 0)
      + (this.application.solar.novUsage ?? 0)
      + (this.application.solar.decUsage ?? 0);
  }

  loadLoanBorrowers = (application: LoanApplication) => {
    if (application.applicationId) {
      this._loanService.getBorrowers(application.applicationId)
        .subscribe((borrowers: Borrower[]) => {
          this.loanBorrowers = borrowers;
        })
    }
  }

  loadCompanyBranches = () => {
    this._appDetailsService.getAllBranches(this.companyId).subscribe((branches) => {
      if (branches && branches.length > 0) {
        this.externalCompanyBranches = branches;
      }
    },
      (error) => {
        this._notifyService.showError(
          error ? error.message : 'Unable to get branches',
          'Error!'
        );
      });
  }

  loadAgents = (defaultSelectedAgent: AgentFull = null) => {
    this._agentService.getAllReferralSources().subscribe(response => {
      var ag = chain(response)
        .filter(a => !isNil(a.firstName) || !isNil(a.lastName))
        .orderBy(['lastName', 'firstName'])
        .value();

      ag.forEach(a => {
        a["agentFullName"] = this.getAgentFullName(a.agentId);
      })

      if (defaultSelectedAgent) {
        const referralSourceToSelect = ag.find(a => a.firstName.toLocaleLowerCase() === defaultSelectedAgent.agent.firstName.toLocaleLowerCase() &&
          a.lastName.toLocaleLowerCase() === defaultSelectedAgent.agent.lastName.toLocaleLowerCase());
        if (referralSourceToSelect) {
          this.application.referralSource = referralSourceToSelect.agentId;
        }
      }
      this.agents = ag;
    });
  }

  onChannelSelect = (channel: string) => {
    if (channel == 'Retail' || channel == 'Broker') {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => !x.externalCompanyId);
    }
    else if (channel == 'Wholesale') {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => !!x.externalCompanyId);
    }
    else
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches;
  }

  onExternalCompanySelect = (externalCompanyId: number) => {
    if (!this.application.externalCompanyId) {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => !x.externalCompanyId);
    }
    else if (this.application.externalCompanyId > 0) {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => x.externalCompanyId == externalCompanyId);
    }
    else
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches;
  }

  toggleEditModeChange = (isChecked: boolean) => {
    this.applicationContextService.toggleLoanEditMode(isChecked);
    this.editorMode = isChecked ? 'Classic' : 'Inline';
  }

  saveLoanInfo = () => {
    this.loanForm.form.markAllAsTouched();
    if (!this.loanForm.form.valid) {
      return;
    }

    this.adjustCustomData();

    const payLoad = new LoanDetailsInfo();
    payLoad.application = this.application;
    payLoad.customData = this.customData;

    this._spinner.show();

    forkJoin({
      loanInfo: this._appDetailsService.saveLoanInfo(this.application.applicationId, payLoad)
        .pipe(
          map((res) => res),
          catchError(error => {
            this._notifyService.showError(
              error.message || 'Unable to save Loan Info',
              'Error!'
            );
            return of(null);
          }))
    })
      .subscribe({
        next: (multiResult) => {
          if (!multiResult.loanInfo) {
            this._spinner.hide();
            return;
          }

          this.application = multiResult.loanInfo.application;
          this._oldLoanPurposeValue = this.application.loanPurposeId;

          this.applicationContextService.updateMortgageAndApplication(null, this.application, this.customData);

          this._notifyService.showSuccess(
            'Your loan has been saved successfully.',
            'Success!'
          );

          if (this._loanPurposeValueChanged) {
            this._loanPurposeValueChanged = false;
            this.saveLoanStatus();
          } else {
            this._spinner.hide();
          }
        },
        error: () => {
          this._spinner.hide();
        }
      })
  }

  onAddAnnualUsageClicked() {
    const modalRef = this._modalService.open(AnnualUsageDialogComponent, Constants.modalOptions.small);
    modalRef.result.then((annualUsage?: number) => {
      if (annualUsage == null) return;

      const solar = this.application.solar;
      const monthlyUsage = Math.round(annualUsage / 12);

      solar.janUsage = monthlyUsage;
      solar.febUsage = monthlyUsage;
      solar.marUsage = monthlyUsage;
      solar.aprUsage = monthlyUsage;
      solar.mayUsage = monthlyUsage;
      solar.junUsage = monthlyUsage;
      solar.julUsage = monthlyUsage;
      solar.augUsage = monthlyUsage;
      solar.sepUsage = monthlyUsage;
      solar.octUsage = monthlyUsage;
      solar.novUsage = monthlyUsage;
      solar.decUsage = monthlyUsage;
    });
  }

  onAddHomeInsuranceDialog() {
    const modalRef = this._modalService.open(UpsertReferralSourceComponent, Constants.modalOptions.fullScreen);
    this.application.referralSource = null;

    modalRef.result.then((newAgent: AgentFull) => {
      this.loadAgents(newAgent);
    }, () => {
    });
  }

  updateLoanBorrowers = () => {
    this.applicationContextService.updateBorrowers().subscribe(borrowers => {
      this.loanBorrowers = [...borrowers];
    });
  }

  adjustCustomData() {
    if (Array.isArray(this.customData.customData1)) {
      this.customData.customData1 = JSON.stringify(this.customData.customData1)
    }
    if (Array.isArray(this.customData.customData2)) {
      this.customData.customData2 = JSON.stringify(this.customData.customData2)
    }
    if (Array.isArray(this.customData.customData3)) {
      this.customData.customData3 = JSON.stringify(this.customData.customData3)
    }
    if (Array.isArray(this.customData.customData4)) {
      this.customData.customData4 = JSON.stringify(this.customData.customData4)
    }
    if (Array.isArray(this.customData.customData5)) {
      this.customData.customData5 = JSON.stringify(this.customData.customData5)
    }
    if (Array.isArray(this.customData.customData6)) {
      this.customData.customData6 = JSON.stringify(this.customData.customData6)
    }
    if (Array.isArray(this.customData.customData7)) {
      this.customData.customData7 = JSON.stringify(this.customData.customData7)
    }
    if (Array.isArray(this.customData.customData8)) {
      this.customData.customData8 = JSON.stringify(this.customData.customData8)
    }
    if (Array.isArray(this.customData.customData9)) {
      this.customData.customData9 = JSON.stringify(this.customData.customData9)
    }
    if (Array.isArray(this.customData.customData10)) {
      this.customData.customData10 = JSON.stringify(this.customData.customData10)
    }
  }

  private getAgentFullName = (agentId: number) => {
    if (agentId) {
      const referToToSelect = this.agents.find(a => a.agentId == agentId);
      return referToToSelect ? Utils.getPersonsDisplayName(referToToSelect) : null;
    }

    return null;
  }

  private getUserFullName = (userCompanyGuid: string) => {
    if (userCompanyGuid) {
      let user = this.users.find(u => u.userCompanyGuid == userCompanyGuid);
      return user ? Utils.getPersonsDisplayName(user) : null;
    }

    return null;
  }


  private saveLoanStatus = () => {
    this._appDetailsService.setLoanStatus(this.application.applicationId).subscribe((result) => {
      if (result) {
        const loanStatusId = this.application.loanStatusId ? this.application.loanStatusId : -1
        this._appDetailsService.setNextLoanStatus(this.application.loanPurposeId, loanStatusId, this.application.applicationId).subscribe(() => {
          this._spinner.hide();
        });
      }
      this._spinner.hide();
    }, error => {
      this._notifyService.showError(
        error ? error.message : 'Unable to get Loan Status',
        'Error!'
      );
      this._spinner.hide();
    });
  }

  private loadLoanHiddenFields = async (): Promise<void> => {
    const response = await firstValueFrom(this._appDetailsService.getLoanHiddenFields());
    const parsedHiddenFields = this.getHiddenFields(response);

    this.isSubjectPropertyAddressHidden = parsedHiddenFields.findIndex(f => f === "Subject Property") > -1;
  }

  private getHiddenFields = (loanHiddenFields?: Configuration): Array<string> => {
    if (!loanHiddenFields?.valueStr) return [];
    return loanHiddenFields.valueStr?.split(",").map(el => el.trim());
  }
}

