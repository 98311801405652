import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'microsoft-login-redirect',
  templateUrl: 'microsoft-login-redirect.component.html'
})

export class MicrosoftLoginRedirectComponent implements OnInit, OnDestroy {

  tenantGuid: string = "";
  adminConsent: string = "";

  private _activatedRouteQueryParamsSubscription: Subscription;
  private _activatedRouteParamsSubscription: Subscription;

  constructor(private readonly _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
      this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
        this.adminConsent = queryParams.admin_consent;
        this.tenantGuid = queryParams.tenant;

        if (this.adminConsent === "True") {
          window.opener.tenantGUID = this.tenantGuid;
        }
        window.close();
      });
    });
  }

  ngOnDestroy(): void {
    this._activatedRouteQueryParamsSubscription?.unsubscribe();
    this._activatedRouteParamsSubscription?.unsubscribe();
  }
}
