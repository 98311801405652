<h4 class="card-title">{{ title }}</h4>
<div class="row">
<p-table [value]="feeDefinitions" responsiveLayout="scroll" styleClass="p-datatable-sm p-datatable-gridlines"
  sortField="feeDefinitionId">
  <ng-template pTemplate="caption" *ngIf="!(isReadOnly && !feeTemplate)">
    <div class="d-flex justify-content-end">
      <span *ngIf="!isReadOnly">
        <button *ngIf="!feeTemplate" type="button" class="btn btn-primary me-2" (click)="addAdHocFeeClicked()">
          <i class="fas fa-plus"></i> Add Fee
        </button>
        <div *ngIf="!!feeTemplate" ngbDropdown #myDrop="ngbDropdown" class="d-inline-block" display="dynamic"
          placement="bottom-right">
          <button type="button" class="btn btn-primary" id="dropdownBasicFee" ngbDropdownToggle>
            Add Fee<i class="ms-2 fas fa-caret-down"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicFee">
            <button ngbDropdownItem (click)="addFromLibraryClicked()">
              From Library
            </button>
            <button ngbDropdownItem (click)="addAdHocFeeClicked()">
              Ad-hoc
            </button>
          </div>
        </div>
      </span>
      <span *ngIf="isReadOnly && !!feeTemplate" style="color: orange">
        ** {{ getEnumName(feeSection) }} Fees will be added to loans based
        on loan criteria
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn='hudNumber'>
        HUD#

        <p-sortIcon field='hudNumber'></p-sortIcon>
      </th>
      <th>Sum in HUD#</th>
      <th pSortableColumn='name'>
        Name

        <p-sortIcon field='name'></p-sortIcon>
      </th>
      <th>APR</th>
      <th>Fee % of</th>
      <th>Total Fee %</th>
      <th>Total Fee $</th>
      <th>Default Paid By</th>
      <th>Can Be Split</th>
      <th>Bor/Sel/Len/3rd P.[$]</th>
      <th>Bor/Sel/Len/3rd P.[%]</th>
      <th>Sel. Concess.</th>
      <th>Can Set Paid To</th>
      <th style="width: 10rem; text-align: center">
        <span>Actions</span>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{ rowData.hudNumber }}</td>
      <td>{{ rowData.sumInHudNumber }}</td>
      <td>{{ rowData.name }}</td>
      <td>{{ rowData.isAprFee ? 'Yes' : 'No' }}</td>
      <td>{{ getEnumName(rowData.feePercentOf) || '---' }}</td>
      <td>
        {{ (getTotalFeePercent(rowData) | number: '1.3-3') || '---' }}
      </td>
      <td>{{ (getTotalFeeDollar(rowData) | currency) || '---' }}</td>
      <td>{{ getEnumName(rowData.defaultPaidBy) || '---' }}</td>
      <td>{{ rowData.allowSplit ? 'Yes' : 'No' }}</td>
      <td>
        <div class="row" style="
                  background-color: #fafafa;
                  border-radius: 4px;
                  padding-top: 8px;
                  padding-bottom: 8px;
                " *ngIf="
                  rowData.feePercentOf == null ||
                  (rowData.feePercentOf != null && percentOfFields.length === 0)
                ">
          <div class="col-3">
            {{ rowData.borrowerFeeDollar | currency }}
          </div>
          <div class="col-3">
            {{ rowData.sellerFeeDollar | currency }}
          </div>
          <div class="col-3">
            {{ rowData.lenderFeeDollar | currency }}
          </div>
          <div class="col-3">
            {{ rowData.thirdPartyFeeDollar | currency }}
          </div>
        </div>
      </td>
      <td>
        <div class="row" style="
                  background-color: #fafafa;
                  border-radius: 4px;
                  padding-top: 8px;
                  padding-bottom: 8px;
                " *ngIf="
                  rowData.feePercentOf != null && percentOfFields.length > 0
                ">
          <div class="col-3">
            {{
            calculatePercentAllocationsForUi(rowData)
            ?.borrowerFeePercent | number
            }}
          </div>
          <div class="col-3">
            {{
            calculatePercentAllocationsForUi(rowData)?.sellerFeePercent
            | number
            }}
          </div>
          <div class="col-3">
            {{
            calculatePercentAllocationsForUi(rowData)?.lenderFeePercent
            | number
            }}
          </div>
          <div class="col-3">
            {{
            calculatePercentAllocationsForUi(rowData)
            ?.thirdPartyFeePercent | number
            }}
          </div>
        </div>
      </td>
      <td>{{ rowData.canSellerConcessionsApply ? 'Yes' : 'No' }}</td>
      <td>{{ rowData.canSetPaidTo ? 'Yes' : 'No' }}</td>
      <td>
        <span class="d-flex align-items-center justify-content-center w-100" *ngIf="!isReadOnly">
          <span>
            <i class="fa fa-pencil-alt cursor text-primary" (click)="onEditClicked(rowData)" title="Edit"></i>
          </span>

          <span class="mx-2"> | </span>

          <span>
            <i *ngIf="!isDeleteClicked[rowData[keyName]]" class="fa fa-trash-alt cursor text-danger"
              (click)="isDeleteClicked[rowData[[keyName]]] = true" title="Delete"></i>

            <i *ngIf="isDeleteClicked[rowData[[keyName]]]" class="fa fa-times cursor text-danger me-2"
              (click)="isDeleteClicked[rowData[[keyName]]] = false" title="Cancel"></i>

            <i *ngIf="isDeleteClicked[rowData[[keyName]]]" class="fa fa-check-circle cursor text-danger"
              (click)="removeFeeClicked(rowData)" title="Delete"></i>
          </span>

          <span class="mx-2" *ngIf="expressionFields && expressionFields.length > 0">
            |
          </span>

          <span *ngIf="expressionFields && expressionFields.length > 0">
            <a class="text-primary" (click)="onExpressionClicked(rowData)">Expr.</a>
          </span>
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="14" class="text-center">
        {{ !isLoading ? 'There are no definition.' : 'Loading...' }}
      </td>
    </tr>
  </ng-template>
</p-table>
</div>
