<div class="px-2 pt-2 d-flex justify-content-end align-items-center" *ngIf="unreadMentions.length > 0">
  <button class="btn btn-link text-success" (click)="markAllAsRead()">
    Mark All As Read
  </button>
</div>

<infinite-scroll (scrolled)="onScroll()" [height]="unreadMentions.length > 0 ? '10px' : '0px'">
  <div class="activity px-2 pt-2">
    <mention-item *ngFor="let mention of unreadMentions"
      #unreadMentionItem
      (mentionClicked)="onMentionClicked($event)"
      [mention]="mention">
    </mention-item>
  </div>
  <div *ngIf="isLoadingUnread && !allUnreadRecordsHaveBeenLoaded"
    style="text-align: center; display: flex; align-items: center; justify-content: center; height: 25px;">
    Fetching {{pageSize}} unread mentions...
    <span class="spinner-border spinner-border-sm ml-4" role="status" aria-hidden="true"></span>
  </div>
  <div *ngIf="allUnreadRecordsHaveBeenLoaded && unreadMentions.length > 0"
    style="text-align: center; background-color: white; border-radius: 8px; display: flex; align-items: center; justify-content: center; height: 25px;">
    All unread mentions have been loaded
  </div>
  <div *ngIf="!isLoadingUnread && !allUnreadRecordsHaveBeenLoaded"
    style="text-align: center; display: flex; align-items: center; justify-content: center;">
    <a (click)="onLoadMore()">Load More</a>
  </div>
</infinite-scroll>

<div class="d-flex justify-content-center mb-4" *ngIf="!showReadMentions">
  <button class="btn btn-link text-secondary" (click)="onShowReadMentionsClicked()">
    <i class="fas fa-book-open me-2"></i>
    Show Read Mentions
  </button>
</div>
<ng-container *ngIf="showReadMentions">
  <infinite-scroll (scrolled)="onScroll(true)" [height]="'10px'">
    <div class="activity px-2 pt-2">
      <mention-item *ngFor="let mention of readMentions"
        #readMentionItem
        (mentionClicked)="onMentionClicked($event)"
        [mention]="mention">
      </mention-item>
    </div>

    <div *ngIf="isLoadingRead && !allReadRecordsHaveBeenLoaded"
      style="text-align: center; display: flex; align-items: center; justify-content: center; height: 25px;">
      Fetching {{pageSize}} read mentions...
      <span class="spinner-border spinner-border-sm ml-4" role="status" aria-hidden="true"></span>
    </div>
    <div *ngIf="allReadRecordsHaveBeenLoaded"
      style="text-align: center; background-color: white; border-radius: 8px; display: flex; align-items: center; justify-content: center; height: 25px;">
      All read mentions have been loaded
    </div>

    <div *ngIf="!isLoadingRead && !allReadRecordsHaveBeenLoaded"
      style="text-align: center; display: flex; align-items: center; justify-content: center;">
      <a (click)="onLoadMore(true)">Load More</a>
    </div>
  </infinite-scroll>
</ng-container>

