<div class="row mt-1">
    <div class="col-md-12">
        <div class="col-md-12">
            <toggle-configuration [title]="'Enable Lender Data Exchange Integration'" [id]="'ldeEnabled'" [configuration]="ldeEnabled">
            </toggle-configuration>
        </div>
        <div class="col-md-12 mt-1">
            <hr class="mt-3 mb-0" />
        </div>
    </div>
    <div class="d-flex align-content-end flex-wrap" *ngIf="ldeEnabled.value">
        <div class="mt-4 me-2" *ngFor="let credential of ldeCredentials">
            <credential-item [credential]="credential" [credentials]="ldeCredentials" [scope]="scope"
                             (openCredentialModal)="openCredentialModal($event)"></credential-item>
        </div>
        <div class="mt-4 me-2">
            <create-credential [credentialType]="'LdeVendor'" [credentials]="ldeCredentials"
                               (openCredentialModal)="openCredentialModal($event)"></create-credential>
    </div>
    <div class="col-md-12">
        <enable-message *ngIf="ldeEnabled.value === 0">Enable this feature above!</enable-message>
    </div>
</div>
