import { Component, Input, OnInit } from '@angular/core';
import {ThirdPartyCredential, ThirdPartyCredentialArray} from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import {Constants} from '../../../../../services/constants';
import {LdeModalComponent} from './lde-modal/lde-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../../../../services/notification.service';
import { ScopeType } from '../integrations.component';

@Component({
    selector: 'lde-integration',
    templateUrl: './lde.component.html'
})
export class LdeComponent implements OnInit {
    @Input() ldeEnabled: Configuration;
    @Input() ldeCredentials: ThirdPartyCredentialArray;
    @Input() scope: ScopeType;

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _notificationService: NotificationService
    ) {}

    ngOnInit(): void {}

    openCredentialModal(credential: ThirdPartyCredential): void {
        const modalRef = this._modalService.open(LdeModalComponent, Constants.modalOptions.large);
        modalRef.componentInstance.credential = credential;
        modalRef.componentInstance.scope = this.scope;
        modalRef.result.then(
            (result) => {
                const index = this.ldeCredentials.findIndex(el => el.credentialId === result.credentialId);
                if (index > -1) {
                    this.ldeCredentials[index] = result;
                } else {
                    this.ldeCredentials.push(result);
                }
            },
            () => { }
        );
    }
}
