import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { GlobalCalendarDay, OtherCalendarDay } from "../models/calendar-days.model";

@Injectable()
export class CalendarDaysService {
  constructor(
    private readonly _dataService: DataService,
  ) { }

  getGlobalHolidays = (): Observable<GlobalCalendarDay[]> => {
    const url = 'api/admin/calendar-days/global/all';
    return this._dataService.get(url);
  }

  getOtherHolidays = (): Observable<OtherCalendarDay[]> => {
    const url = 'api/admin/calendar-days/all';
    return this._dataService.get(url);
  }

  insertHoliday = (request: OtherCalendarDay): Observable<OtherCalendarDay> => {
    const url = 'api/admin/calendar-days';
    return this._dataService.post(url, request);
  }

  updateHoliday = (request: OtherCalendarDay): Observable<OtherCalendarDay> => {
    const url = `api/admin/calendar-days/${request.calendarDayId}`;
    return this._dataService.post(url, request);
  }

  deleteHoliday = (calendarDayId: number): Observable<void> => {
    const url = `api/admin/calendar-days/${calendarDayId}`;
    return this._dataService.delete(url);
  }
}