export enum FeeTypeEnum {
  CityPropertyTaxes = 'CityPropertyTaxes',
  CityPropertyTaxesReserve = 'CityPropertyTaxesReserve',
  CountyPropertyTaxes = 'CountyPropertyTaxes',
  CountyPropertyTaxesReserve = 'CountyPropertyTaxesReserve',
  MudTax = 'MudTax',
  MudTaxReserve = 'MudTaxReserve',
  VillageTownSchoolTax = 'VillageTownSchoolTax',
  VillageTownSchoolTaxReserve = 'VillageTownSchoolTaxReserve',
  HomeownersInsurance = 'HomeownersInsurance',
  HomeownersInsuranceReserve = 'HomeownersInsuranceReserve',
  MortgageInsurance = 'MortgageInsurance',
  MortgageInsuranceReserve = 'MortgageInsuranceReserve',
  FloodInsurance = 'FloodInsurance',
  WindHailInsurance = 'WindHailInsurance',
  HoaDues = 'HoaDues',
  AggregateAccountingAdjustment = 'AggregateAccountingAdjustment',
  OddDaysInterest = 'OddDaysInterest',
  BuyerAgentRealEstateCommission = 'BuyerAgentRealEstateCommission',
  SellerAgentRealEstateCommission = 'SellerAgentRealEstateCommission',
  RealEstateCommissionTotal = 'RealEstateCommissionTotal',
  Ufmip = 'Ufmip',
  VaFundingFee = 'VaFundingFee',
  DiscountFee = 'DiscountFee',
  UsdaGuaranteeFee = 'UsdaGuaranteeFee',
  BoroughPropertyTax = 'BoroughPropertyTax',
  BoroughPropertyTaxReserve = 'BoroughPropertyTaxReserve',
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationDuesReserve = 'CondominiumAssociationDuesReserve',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  CondominiumAssociationSpecialAssessmentReserve = 'CondominiumAssociationSpecialAssessmentReserve',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationDuesReserve = 'CooperativeAssociationDuesReserve',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  CooperativeAssociationSpecialAssessmentReserve = 'CooperativeAssociationSpecialAssessmentReserve',
  DistrictPropertyTax = 'DistrictPropertyTax',
  DistrictPropertyTaxReserve = 'DistrictPropertyTaxReserve',
  EarthquakeInsurancePremium = 'EarthquakeInsurancePremium',
  EarthquakeInsurancePremiumReserve = 'EarthquakeInsurancePremiumReserve',
  FloodInsurancePremium = 'FloodInsurancePremium',
  FloodInsurancePremiumReserve = 'FloodInsurancePremiumReserve',
  HailInsurancePremium = 'HailInsurancePremium',
  HailInsurancePremiumReserve = 'HailInsurancePremiumReserve',
  HazardInsurancePremium = 'HazardInsurancePremium',
  HazardInsurancePremiumReserve = 'HazardInsurancePremiumReserve',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  omeownersAssociationDuesReserve = 'omeownersAssociationDuesReserve',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeownersAssociationSpecialAssessmentReserve = 'HomeownersAssociationSpecialAssessmentReserve',
  SchoolPropertyTax = 'SchoolPropertyTax',
  SchoolPropertyTaxReserve = 'SchoolPropertyTaxReserve',
  StatePropertyTax = 'StatePropertyTax',
  StatePropertyTaxReserve = 'StatePropertyTaxReserve',
  TownPropertyTax = 'TownPropertyTax',
  TownPropertyTaxReserve = 'TownPropertyTaxReserve',
  VillagePropertyTax = 'VillagePropertyTax',
  VillagePropertyTaxReserve = 'VillagePropertyTaxReserve',
  VolcanoInsurancePremium = 'VolcanoInsurancePremium',
  VolcanoInsurancePremiumReserve = 'VolcanoInsurancePremiumReserve',
  WindAndStormInsurancePremium = 'WindAndStormInsurancePremium',
  WindAndStormInsurancePremiumReserve = 'WindAndStormInsurancePremiumReserve',
  ApplicationFee = 'ApplicationFee',
  AppraisalDeskReviewFee = 'AppraisalDeskReviewFee',
  AppraisalFee = 'AppraisalFee',
  AppraisalFieldReviewFee = 'AppraisalFieldReviewFee',
  AppraisalManagementCompanyFee = 'AppraisalManagementCompanyFee',
  AsbestosInspectionFee = 'AsbestosInspectionFee',
  AssignmentPreparationFee = 'AssignmentPreparationFee',
  AssumptionFee = 'AssumptionFee',
  AttorneyFee = 'AttorneyFee',
  AutomatedUnderwritingFee = 'AutomatedUnderwritingFee',
  AVMFee = 'AVMFee',
  BankruptcyMonitoringFee = 'BankruptcyMonitoringFee',
  BondFee = 'BondFee',
  BondReviewFee = 'BondReviewFee',
  CertificationFee = 'CertificationFee',
  ChosenInterestRateCreditOrChargeTotal = 'ChosenInterestRateCreditOrChargeTotal',
  CommitmentFee = 'CommitmentFee',
  CopyOrFaxFee = 'CopyOrFaxFee',
  CourierFee = 'CourierFee',
  CreditDisabilityInsurancePremium = 'CreditDisabilityInsurancePremium',
  CreditLifeInsurancePremium = 'CreditLifeInsurancePremium',
  CreditPropertyInsurancePremium = 'CreditPropertyInsurancePremium',
  CreditReportFee = 'CreditReportFee',
  CreditUnemploymentInsurancePremium = 'CreditUnemploymentInsurancePremium',
  DebtCancellationInsurancePremium = 'DebtCancellationInsurancePremium',
  DebtSuspensionInsurancePremium = 'DebtSuspensionInsurancePremium',
  DeedPreparationFee = 'DeedPreparationFee',
  DisasterInspectionFee = 'DisasterInspectionFee',
  DiscountOnRepairsFee = 'DiscountOnRepairsFee',
  DocumentaryStampFee = 'DocumentaryStampFee',
  DocumentPreparationFee = 'DocumentPreparationFee',
  DownPaymentProtectionFee = 'DownPaymentProtectionFee',
  DryWallInspectionFee = 'DryWallInspectionFee',
  ElectricalInspectionFee = 'ElectricalInspectionFee',
  ElectronicDocumentDeliveryFee = 'ElectronicDocumentDeliveryFee',
  EnvironmentalInspectionFee = 'EnvironmentalInspectionFee',
  EscrowHoldbackFee = 'EscrowHoldbackFee',
  EscrowServiceFee = 'EscrowServiceFee',
  EscrowWaiverFee = 'EscrowWaiverFee',
  FilingFee = 'FilingFee',
  FloodCertification = 'FloodCertification',
  FoundationInspectionFee = 'FoundationInspectionFee',
  HeatingCoolingInspectionFee = 'HeatingCoolingInspectionFee',
  HighCostMortgageCounselingFee = 'HighCostMortgageCounselingFee',
  HomeInspectionFee = 'HomeInspectionFee',
  HomeownersAssociationServiceFee = 'HomeownersAssociationServiceFee',
  HomeWarrantyFee = 'HomeWarrantyFee',
  Item203KConsultantFee = 'Item203KConsultantFee',
  Item203KDiscountOnRepairs = 'Item203KDiscountOnRepairs',
  Item203KInspectionFee = 'Item203KInspectionFee',
  Item203KPermits = 'Item203KPermits',
  Item203KSupplementalOriginationFee = 'Item203KSupplementalOriginationFee',
  Item203KTitleUpdate = 'Item203KTitleUpdate',
  LeadInspectionFee = 'LeadInspectionFee',
  LendersAttorneyFee = 'LendersAttorneyFee',
  LoanDiscountPoints = 'LoanDiscountPoints',
  LoanLevelPriceAdjustment = 'LoanLevelPriceAdjustment',
  LoanOriginationFee = 'LoanOriginationFee',
  LoanOriginatorCompensation = 'LoanOriginatorCompensation',
  ManualUnderwritingFee = 'ManualUnderwritingFee',
  ManufacturedHousingInspectionFee = 'ManufacturedHousingInspectionFee',
  ManufacturedHousingProcessingFee = 'ManufacturedHousingProcessingFee',
  MERSRegistrationFee = 'MERSRegistrationFee',
  ModificationFee = 'ModificationFee',
  MoldInspectionFee = 'MoldInspectionFee',
  MortgageBrokerFee = 'MortgageBrokerFee',
  MortgageSurchargeCountyOrParish = 'MortgageSurchargeCountyOrParish',
  MortgageSurchargeMunicipal = 'MortgageSurchargeMunicipal',
  MortgageSurchargeState = 'MortgageSurchargeState',
  MortgageTaxCreditServiceFee = 'MortgageTaxCreditServiceFee',
  MultipleLoansClosingFee = 'MultipleLoansClosingFee',
  MunicipalLienCertificateFee = 'MunicipalLienCertificateFee',
  NotaryFee = 'NotaryFee',
  OtherSupplementalPropertyInsurancePremium = 'OtherSupplementalPropertyInsurancePremium',
  OtherSupplementalPropertyInsurancePremiumReserve = 'OtherSupplementalPropertyInsurancePremiumReserve',
  OurOriginationChargeTotal = 'OurOriginationChargeTotal',
  PayoffRequestFee = 'PayoffRequestFee',
  PestInspectionFee = 'PestInspectionFee',
  PlumbingInspectionFee = 'PlumbingInspectionFee',
  PowerOfAttorneyPreparationFee = 'PowerOfAttorneyPreparationFee',
  PowerOfAttorneyRecordingFee = 'PowerOfAttorneyRecordingFee',
  PreclosingVerificationControlFee = 'PreclosingVerificationControlFee',
  ProcessingFee = 'ProcessingFee',
  ProgramGuaranteeFee = 'ProgramGuaranteeFee',
  PropertyInspectionWaiverFee = 'PropertyInspectionWaiverFee',
  RadonInspectionFee = 'RadonInspectionFee',
  RateLockFee = 'RateLockFee',
  RealEstateCommissionBuyersBroker = 'RealEstateCommissionBuyersBroker',
  RealEstateCommissionSellersBroker = 'RealEstateCommissionSellersBroker',
  ReconveyanceFee = 'ReconveyanceFee',
  ReconveyanceTrackingFee = 'ReconveyanceTrackingFee',
  RecordingFeeForAssignment = 'RecordingFeeForAssignment',
  RecordingFeeForDeed = 'RecordingFeeForDeed',
  RecordingFeeForMortgage = 'RecordingFeeForMortgage',
  RecordingFeeForMunicipalLienCertificate = 'RecordingFeeForMunicipalLienCertificate',
  RecordingFeeForOtherDocument = 'RecordingFeeForOtherDocument',
  RecordingFeeForRelease = 'RecordingFeeForRelease',
  RecordingFeeForSubordination = 'RecordingFeeForSubordination',
  RecordingFeeTotal = 'RecordingFeeTotal',
  RecordingServiceFee = 'RecordingServiceFee',
  RedrawFee = 'RedrawFee',
  ReinspectionFee = 'ReinspectionFee',
  RenovationConsultantFee = 'RenovationConsultantFee',
  RepairsFee = 'RepairsFee',
  RoofInspectionFee = 'RoofInspectionFee',
  SepticInspectionFee = 'SepticInspectionFee',
  SettlementFee = 'SettlementFee',
  SettlementOrClosingFeesTotal = 'SettlementOrClosingFeesTotal',
  SigningAgentFee = 'SigningAgentFee',
  SmokeDetectorInspectionFee = 'SmokeDetectorInspectionFee',
  StateTitleInsuranceFee = 'StateTitleInsuranceFee',
  StructuralInspectionFee = 'StructuralInspectionFee',
  SubordinationFee = 'SubordinationFee',
  SurveyFee = 'SurveyFee',
  TaxServiceFee = 'TaxServiceFee',
  TaxStampForCityDeed = 'TaxStampForCityDeed',
  TaxStampForCityMortgage = 'TaxStampForCityMortgage',
  TaxStampForCountyDeed = 'TaxStampForCountyDeed',
  TaxStampForCountyMortgage = 'TaxStampForCountyMortgage',
  TaxStampForStateDeed = 'TaxStampForStateDeed',
  TaxStampForStateMortgage = 'TaxStampForStateMortgage',
  TaxStatusResearchFee = 'TaxStatusResearchFee',
  TemporaryBuydownAdministrationFee = 'TemporaryBuydownAdministrationFee',
  TemporaryBuydownPoints = 'TemporaryBuydownPoints',
  TitleAbstractFee = 'TitleAbstractFee',
  TitleBorrowerClosingProtectionLetterFee = 'TitleBorrowerClosingProtectionLetterFee',
  TitleCertificationFee = 'TitleCertificationFee',
  TitleClosingCoordinationFee = 'TitleClosingCoordinationFee',
  TitleClosingFee = 'TitleClosingFee',
  TitleClosingProtectionLetterFee = 'TitleClosingProtectionLetterFee',
  TitleDocumentPreparationFee = 'TitleDocumentPreparationFee',
  TitleEndorsementFee = 'TitleEndorsementFee',
  TitleExaminationFee = 'TitleExaminationFee',
  TitleFinalPolicyShortFormFee = 'TitleFinalPolicyShortFormFee',
  TitleInsuranceBinderFee = 'TitleInsuranceBinderFee',
  TitleInsuranceFee = 'TitleInsuranceFee',
  TitleLendersCoveragePremium = 'TitleLendersCoveragePremium',
  TitleNotaryFee = 'TitleNotaryFee',
  TitleOwnersCoveragePremium = 'TitleOwnersCoveragePremium',
  TitleServicesFeeTotal = 'TitleServicesFeeTotal',
  TitleServicesSalesTax = 'TitleServicesSalesTax',
  TitleUnderwritingIssueResolutionFee = 'TitleUnderwritingIssueResolutionFee',
  TransferTaxTotal = 'TransferTaxTotal',
  UnderwritingFee = 'UnderwritingFee',
  VerificationOfAssetsFee = 'VerificationOfAssetsFee',
  VerificationOfEmploymentFee = 'VerificationOfEmploymentFee',
  VerificationOfIncomeFee = 'VerificationOfIncomeFee',
  VerificationOfResidencyStatusFee = 'VerificationOfResidencyStatusFee',
  VerificationOfTaxpayerIdentificationFee = 'VerificationOfTaxpayerIdentificationFee',
  VerificationOfTaxReturnFee = 'VerificationOfTaxReturnFee',
  WaterTestingFee = 'WaterTestingFee',
  WellInspectionFee = 'WellInspectionFee',
  WireTransferFee = 'WireTransferFee',
  TaxStampCityCountyTotal = 'TaxStampCityCountyTotal',
  TaxStampStateTotal = 'TaxStampStateTotal',
}
