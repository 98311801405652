<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title">Qualified Mortgage Details</h5>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <table class="table table-striped table-bordered table-hover">
          <thead>
              <tr>
                  <th>Description</th>
                  <th>Value</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>Base Loan Amount</td>
                  <td>{{productSearchRequest.loanInformation.baseLoanAmount | currency}}</td>
              </tr>
              <tr>
                  <td>Total Loan Amount</td>
                  <td>{{productSearchRequest.loanInformation.totalLoanAmount | currency}}</td>
              </tr>
              <tr>
                  <td>QM Loan Amount</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td>Rate</td>
                  <td>{{(qualifiedMortgageDetails.quotes[0].adjustedRate / 100) | percent:'1.3-3'}}</td>
              </tr>
              <tr>
                  <td>APOR Date</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td>APOR</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td>Start/Par Rate</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td>Start/Par Price</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td>APR</td>
                  <td>{{qualifiedMortgageDetails.quotes[0].apr}}</td>

              </tr>
              <tr>
                  <td>Price</td>
                  <td>{{qualifiedMortgageDetails.quotes[0].adjustedPrice}}</td>
              </tr>
              <tr>
                  <td>Lock Period</td>
                  <td>{{qualifiedMortgageDetails.quotes[0].lockPeriod}}</td>
              </tr>
              <!--<tr>
                  <td>Product</td>
                  <td>{{qmDetails.productName}}</td>
              </tr> -->
          </tbody>
      </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close </button>
  </div>
</div>
