<div class="inline-editor" *ngIf="editorMode != 'InlineReadOnly' && !disabled" [ngClass]="{
    'input-group': editorMode == 'Inline',
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }" [hidden]="editorMode === 'Inline' && !isEditActive && !(model && model.touched && model.invalid)">

  <address-autocomplete-input
    #control
    #model='ngModel'
    (addressChange)='onAddressChanged($event)'
    (blur)='onBlur($event)'
    (focus)='isEditActive = true'
    [(ngModel)]='value'
    [class.is-invalid]='model && model.touched && model.invalid'
    [disabled]='disabled'
    [id]='name'
    [name]='name'
    [placeholder]='placeholder'
    [readonly]='readonly'
    [required]='required'
  ></address-autocomplete-input>

  <button class="btn p-0 border-0 bg-transparent ms-1" title="Apply" type="button" *ngIf="editorMode == 'Inline'"
    (mousedown)="onApplyClicked($event)">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Cancel" type="button" *ngIf="editorMode == 'Inline'"
    (mousedown)="onCancelClicked($event)">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
  <div class="invalid-feedback">This field is required</div>
</div>
<span [ngClass]="{
  'empty-field': value == null || value == ''
  }" class="inline-editor-container form-control bg-transparent display-inline px-0 {{inlineTextClass}}"
  title="Click to Edit" *ngIf="editorMode == 'Inline' && !isEditActive && !(model && model.touched && model.invalid)"
  (click)="onEditModeToggledOn()">
  <span class="val" *ngIf="value">{{value}}</span>
  <span class="placeholder" *ngIf="!value">--</span>
</span>
<span [ngClass]="{
  'empty-field': value == null || value == ''
  }" class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{inlineTextClass}}"
  *ngIf="editorMode == 'InlineReadOnly' || disabled">
  <span class="val" *ngIf="value">{{value}}</span>
  <span class="placeholder" *ngIf="!value">--</span>
</span>
