import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertModel, AlertType } from 'src/app/models/alert.model';
import { AlertsService } from 'src/app/services/alerts.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AlertBucket, Notifications } from '../header-actions/header-actions.component';
import * as _ from 'lodash';
import { combineLatest, finalize } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { PreviewSentEmailDialogComponent } from 'src/app/modules/correspondence/components/preview-sent-email-dialog/preview-sent-email-dialog.component';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {

  @Input()
  modal = false;

  @Input()
  data: Notifications = new Notifications();

  @Output()
  numberOfAlertsChanged: EventEmitter<NumberOfAlertsChangedEvent> = new EventEmitter<NumberOfAlertsChangedEvent>();

  @Output()
  clickedGotoDetails: EventEmitter<AlertModel> = new EventEmitter<AlertModel>();

  notifications: AlertModel[] = [];
  numberOfRecords = 20;

  alertsSpinner: string = "alertsSpinner";

  selectedAlertBuckets: AlertBucket[] = [];
  availableBucketsForFiltering: AlertBucket[] = [];

  cleaningAll: boolean = false;

  filtersMultiSelectSettings: IDropdownSettings = {
    idField: 'type',
    textField: 'name',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };

  constructor(
    private readonly _alertsService: AlertsService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly _emailService: EmailService
  ) { }

  onScroll(event: GlobalEventHandlersEventMap): void {
    if (this.shouldLoadMore(event)) {
      this.numberOfRecords = this.numberOfRecords + 20;
      this.loadMoreRecords();
    }
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.numberOfRecords = 20;
    setTimeout(() => {
      this.refreshFilters();
      this.onFilterChanged();
    });
  }

  shouldLoadMore = (event: any): boolean => {
    if (
      event.target.offsetHeight + event.target.scrollTop + 50 >=
      event.target.scrollHeight
    ) {
      return true;
    }
  }

  gotoDetails = (alert: AlertModel) => {

    this.clickedGotoDetails.emit(alert);
    // if (alert && alert.alertType == 'BorrowerMessageAlert') {
    //   //open the message conversation in the chats panel
    // }
    // else if (alert && alert.applicationId && alert.taskId) {
    //   //Open the task in the side panel
    // }
    // else if (alert && alert.applicationId && alert.borrowerId) {
    //   this._router.navigate([
    //     `admin/app-details/${alert.applicationId}`,
    //   ]);
    // }
    // else if (alert && alert.leadId) {
    //   //route to leads page and open lead in modal
    // }
  }

  deleteAlert = (alert: AlertModel, suppressNotification: boolean) => {
    if (suppressNotification) this._spinner.show(this.alertsSpinner);
    else alert['deleting'] = true;

    this._alertsService.clearAlert(alert.alertId).subscribe({
      next: () => {
        this.notifications = this.notifications.filter(
          (item) => item.alertId !== alert.alertId
        );

        this.removeAlert(alert);
        setTimeout(() => {
          this.refreshFilters();
        });

        if (!suppressNotification) {
          this._notificationService.showSuccess(
            'Notification deleted successfuly.',
            'Success!'
          );
        }
      },
      error: (err) => {
        if (!suppressNotification) {
          this._notificationService.showError(
            err?.message || "Couldn't delete notification",
            'Error!'
          );
        }
      }
    }).add(() => {
      if (suppressNotification) {
        this._spinner.hide(this.alertsSpinner);
      } else {
        alert['deleting'] = false;
        this._notificationService.showSuccess(
          'Notification deleted successfuly.',
          'Success!'
        );
      }
    })
  }

  resendEmail = (alert: AlertModel) => {
    this._spinner.show(this.alertsSpinner);
    this._alertsService.setEmailPiorityToRetry(alert.messageModelId).subscribe(
      (res) => {
        this._notificationService.showSuccess(
          'Notification resent succesfuly.',
          'Success!'
        );
        this.deleteAlert(alert, true);
      },
      () => {
        this._spinner.hide(this.alertsSpinner);
        this._notificationService.showError(
          'Error Resending notification.',
          'Error!'
        );
      },
      () => {
        this._spinner.hide(this.alertsSpinner);
      }
    );
  }

  onFilterChanged = () => {
    this.numberOfRecords = 20;
    const alerts = this.applyFilters();
    this.notifications = alerts.slice(0, this.numberOfRecords);
  }

  clearAll = () => {
    this.cleaningAll = true;

    let alertTypesToDelete: AlertType[];
    let combined: any;

    if (this.selectedAlertBuckets.length === 0) {
      alertTypesToDelete = this.data.alertBuckets.map(alert => alert.type);
      combined = combineLatest(alertTypesToDelete.map((type) => this._alertsService.clearAllByType(type)));
    } else {
      alertTypesToDelete = this.selectedAlertBuckets.map(alert => alert.type);
      combined = combineLatest(alertTypesToDelete.map((type) => this._alertsService.clearAllByType(type)));
    }

    combined.pipe(finalize(() => {
      this.cleaningAll = false
    })).subscribe({
      next: () => {
        this.data.alertBuckets.forEach(alert => {
          if (alertTypesToDelete.indexOf(alert.type) > -1) {
            alert.alerts = [];
          }
        })
        this.refreshFilters();
        this._notificationService.showSuccess("All alerts and notifications have been removed.", "Success!");
      },
      error: (err) => {
        this._notificationService.showError(err?.error?.message || "Couldn't clear all of the alerts and notifications.", "Error!");
      }
    })
  }

  openDetailsDialog = (notification: AlertModel) => {
    if (notification.alertType === AlertType.FailedEmailAlert) {
      this.showFailedEmailDetails(notification);
    }
    if (notification.alertType === AlertType.FailedSmsAlert) {
      this.showFailedSmsDetails(notification);
    }
  }

  private refreshFilters = () => {
    const updatedFiltersWithNewAlertCounts = this.generateSelectedBucketFiltersWithAlertCounts();
    this.availableBucketsForFiltering = this.generateAvailableBucketsForFilteringWithAlertCounts();
    this.selectedAlertBuckets = updatedFiltersWithNewAlertCounts;
  }

  private initialize = () => {
    this.availableBucketsForFiltering = this.generateAvailableBucketsForFilteringWithAlertCounts();
    const alerts = this.applyFilters();
    this.notifications = alerts.slice(0, this.numberOfRecords);
  }

  private applyFilters = (): AlertModel[] => {
    let alerts = [];
    if (this.selectedAlertBuckets.length) {
      alerts = this.data.getAlertsInBucketsOfTypes(this.selectedAlertBuckets.map(b => b.type));
    } else {
      this.data.alertBuckets.forEach(b => {
        alerts = alerts.concat(b.alerts);
      })
    }
    
    alerts.filter(alert => alert.alertText.includes("@")).forEach(alert => {
      alert.alertText = this.fixMentionDisplayText(alert.alertText);
    })

    return _.orderBy(alerts,
      ['createDate'],
      ['desc']);
  }

  private generateSelectedBucketFiltersWithAlertCounts = () => {
    const updatedFiltersWithNewAlertCounts = [];
    this.selectedAlertBuckets.forEach(sb => {
      const originalBucket = this.data.alertBuckets.find(b => b.type === sb.type);
      updatedFiltersWithNewAlertCounts.push(new AlertBucket(originalBucket.name + " (" + originalBucket.alerts.length + ")", sb.type, sb.typeId));
    });
    return updatedFiltersWithNewAlertCounts;
  }

  private generateAvailableBucketsForFilteringWithAlertCounts = () => {
    const availableBucketsForFiltering = [];
    this.data.alertBuckets.forEach(ab => {
      availableBucketsForFiltering.push(new AlertBucket(ab.name + " (" + ab.alerts.length + ")", ab.type, ab.typeId));
    });
    return availableBucketsForFiltering;
  }

  private loadMoreRecords() {
    const alerts = this.applyFilters();
    this.notifications = alerts.slice(0, this.numberOfRecords);
  }

  private removeAlert = (alertToRemove: AlertModel) => {
    const relatedBucket = this.data.alertBuckets.find(b => b.type === alertToRemove.alertType);
    if (relatedBucket) {
      const indexOfAlertToRemove = relatedBucket.alerts.findIndex(a => a.alertId === alertToRemove.alertId);
      if (indexOfAlertToRemove >= 0) {
        relatedBucket.alerts.splice(indexOfAlertToRemove, 1);
        this.numberOfAlertsChanged.emit(new NumberOfAlertsChangedEvent(alertToRemove.alertType, relatedBucket.alerts.length));
      }
    }
  }

  private showFailedSmsDetails = (notification: AlertModel) => {
    this._spinner.show();
    this._emailService.getEmailDetail(notification.messageModelId).subscribe(details => {
      const modalRef = this._modalService.open(PreviewSentEmailDialogComponent, Constants.modalOptions.xlarge);
      // const email = this.history.find(email => email.id === details.id);
      // if (email) {
      //   details.extendedDetail = email.extendedDetail;
      // }
      modalRef.componentInstance.message = details;
    }, err => {
      this._notificationService.showError(err ? err.message || err : 'Unable to get notification details.', "Error");
    }).add(() => this._spinner.hide());
  }

  private showFailedEmailDetails = (notification: AlertModel) => {
    this._spinner.show();
    this._emailService.getEmailDetail(notification.messageModelId).subscribe(details => {
      const modalRef = this._modalService.open(PreviewSentEmailDialogComponent, Constants.modalOptions.xlarge);
      // const email = this.history.find(email => email.id === details.id);
      // if (email) {
      //   details.extendedDetail = email.extendedDetail;
      // }
      modalRef.componentInstance.message = details;
    }, err => {
      this._notificationService.showError(err ? err.message || err : 'Unable to get notification details.', "Error");
    }).add(() => this._spinner.hide());
  }

  private fixMentionDisplayText = (displayText: string): string => {
    const regexp = new RegExp(/@\{(?<display>[\w ]+):(?<type>[\w ]+)(?::(?<key>[\w-])+)?\}/g);
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      return displayText;
    }
    const fixedDisplayText = displayText.replace(results[0], `<a class='mention'>@${results[1]}</a>`);
    return this.fixMentionDisplayText(fixedDisplayText);
  }
}

export class NumberOfAlertsChangedEvent {
  newNumber: number;
  alertType: AlertType;

  constructor(alertType: AlertType, newNumber: number) {
    this.alertType = alertType;
    this.newNumber = newNumber;
  }
}
