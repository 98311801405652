import { BorrowerType } from 'src/app/modules/leads/models/lead-credit.model';
import { CreditVendorEnum } from './credit-vendor.model';

export class CreditReport {
  borrowerType: BorrowerType;
  creditID: number;
  loanDocId: number;
  refNumber: string;
  creditVendor: CreditVendorEnum;
  date: string;
  equifaxRequested: boolean;
  equifax: number;
  experianRequested: boolean;
  experian: number;
  transUnionRequested: boolean;
  transUnion: number;
  joint: boolean;
  borrowerId: number;
  borrowerName: string;
  applicationId: number;
  integrationHistoryId: number;
  successful: boolean;
  failureReason?: string;
  creditTechnicalAffiliateId: number;
  aus: string;
  companyId: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
  showImport?: boolean;

  referenceNumber: string;
  equifaxScore: number;
  experianScore: number;
  transUnionScore: number;
  creditRunDate: string;
}

export type CreditReportArray = Array<CreditReport>;
