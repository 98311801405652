import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadEvent } from 'src/app/modules/leads/models/lead-event.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { NotificationService } from 'src/app/services/notification.service';

class AttemptedContact {
  wasSuccess: boolean;
  notes: string;
  to: string;
}

@Component({
  selector: 'app-log-attempted-contact-dialog',
  templateUrl: './log-attempted-contact-dialog.component.html',
  styleUrls: ['./log-attempted-contact-dialog.component.scss']
})
export class LogAttemptedContactDialogComponent implements OnInit {

  @Input() lead: Lead;

  isSaving: boolean = false;
  attemptedContact: AttemptedContact = new AttemptedContact();

  constructor(
    private readonly _leadsService: LeadsService,
    private readonly _notifyService: NotificationService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.attemptedContact.to = this.lead.phone;
  }

  save = () => {

    this.isSaving = true;

    let leadEvent = {
      leadId: this.lead.leadId,
      type: this.attemptedContact.wasSuccess ? 'phoneSuccess' : 'phoneAttempt',
      note: this.attemptedContact.notes
    } as LeadEvent;

    this._leadsService.addLeadEvent(leadEvent)
    .subscribe(event => {
      this.isSaving = false;
      this._notifyService.showSuccess("Event added succussfully","Successful");
      this.activeModal.close();
    }, () => {
      this.isSaving = false;
      this._notifyService.showError("Error encountered while adding event","Error!");
    })

  }

}
