<link rel="stylesheet" type="text/css" [href]="overrideCssUrl | safeUrl">

<div class="topbar" id="tpo-header">
  <div class="container container-width">
    <tpo-header></tpo-header>
  </div>
</div>

<router-outlet></router-outlet>

<div class="container-fluid footer-style">
  <div class="page-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center text-sm-start mb-0 ms-2" *ngIf="tpoConfig && tpoConfig.footerMergedHtml"
          [innerHtml]="tpoConfig.footerMergedHtml"></div>
        <div class="text-center text-sm-start mb-0 ms-2" *ngIf="!tpoConfig || !tpoConfig.footerMergedHtml">
          &copy;
          {{currentYear}} Lodasoft
        </div>
      </div>
    </div>
  </div>
</div>

<favicon-loader [faviconUrl]="faviconUrl"></favicon-loader>
