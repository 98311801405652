import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash';
import { Constants } from 'src/app/services/constants';
import { PriceCell } from '../../models/pricing/price-cell.model';
import { PricedProductRow } from '../../models/pricing/priced-product-row.model';
import { Adjustment, Quote } from '../../models/pricing/pricing-quote.model';
import { PricedProduct } from '../../models/pricing/product-pricing-detail.model';
import { SelectedPriceCardViewModel } from '../../models/pricing/selected-price-card-view.model';
import { UpfrontCosts } from '../pricing-search-v2/pricing-search-v2.component';
import { ViewAdjustmentsDialogComponent } from '../view-adjustments-dialog/view-adjustments-dialog.component';
import { ViewNotesAdvisoriesDialogComponent } from '../view-notes-advisories-dialog/view-notes-advisories-dialog.component';

@Component({
  selector: 'product-detail-dialog',
  templateUrl: './product-detail-dialog.component.html',
  styleUrls: ['./product-detail-dialog.component.scss']
})
export class ProductDetailDialogComponent implements OnInit {

  @Input()
  selectedProduct: PricedProductRow;

  @Input()
  notesAndAdvisories: string[] = [];

  @Input()
  adjustments: Adjustment[] = [];

  @Input()
  taxesAndInsurance: number;

  @Input()
  upfrontCosts: UpfrontCosts;

  @Input()
  isSingleSelection: boolean;

  @Input()
  set prices(prices: PriceCell[]) {
    this._prices = prices;
    this.priceViewModelsByRate.clear();
    this.rates = [...new Set(prices.map(p => p.rate))];
    this.lockPeriods = orderBy([...new Set(prices.map(p => p.lockPeriod))]);
    this.rates.forEach(rate => {
      this.lockPeriods.forEach(lockPeriod => {
        const priceViewModel = this.getPriceViewModel(rate, lockPeriod);
        let pricesPerRate = this.priceViewModelsByRate.get(rate);
        if (pricesPerRate) {
          pricesPerRate.push(priceViewModel);
        } else {
          pricesPerRate = [];
          pricesPerRate.push(priceViewModel);
          this.priceViewModelsByRate.set(rate, pricesPerRate);
        }
      })
    })
  }

  lockPeriods: number[];

  rates: number[];

  selectedPrices: SelectedPriceCardViewModel[] = [];

  priceViewModelsByRate: Map<number, any[]> = new Map<number, any[]>();

  private _prices: PriceCell[] = [];

  getPriceViewModel = (rate: number, lockPeriod: number): any => {
    const price = this._prices.find(p => p.rate == rate && p.lockPeriod == lockPeriod);
    const isSelected = this.selectedProduct.prices.find(price => price.rate == rate && price.lockPeriod == lockPeriod);
    if (isSelected) {
      price.selected = true;
      if (isSelected.isPinned) {
        price.isPinned = true;
      } else {
        price.isPinned = false;
      }
      this.onPriceSelectionChanged(price);
    }
    let priceViewModel: any = null;
    if (price) {
      priceViewModel = {
        value: price.price.toFixed(3),
        price: price.price,
        payment: price.payment,
        rate: price.rate,
        lockPeriod: price.lockPeriod,
        monthlyMi: price.monthlyMi,
        discountDollars: price.discountDollars,
        discountPercent: price.discountPercent,
        adjustments: price.adjustments,
        selected: isSelected ? true : false,
        rebatePercent: price.rebatePercent,
        rebateDollars: price.rebateDollars
      }
    } else {
      priceViewModel = {
        value: "N/A",
        price: null,
        rate: rate,
        lockPeriod: lockPeriod,
        selected: false
      }
    }
    return priceViewModel;
  }

  onPriceSelectionChanged = (price: PriceCell) => {
    if (price.selected) {
      const cardViewModel = new SelectedPriceCardViewModel(price, this.selectedProduct.totalLoanAmount,this.taxesAndInsurance);
      if (this.isSingleSelection) {
        const rates = Array.from(this.priceViewModelsByRate.keys());
        rates.forEach(rate => {
          const pricesForRate = this.priceViewModelsByRate.get(rate);
          pricesForRate.forEach(p => {
            if (!(p.rate === price.rate && p.lockPeriod === price.lockPeriod)) {
              p.selected = false;
            }
          })
        })
        this.selectedPrices = [];
      }
      this.selectedPrices.push(cardViewModel);
    } else {
      this.removePriceFromSelectionList(price.rate, price.lockPeriod);
    }
  }

  getSelectedPrice = (result: PricedProduct, price: number): Quote => {
    return result.quotes.find(q => q.adjustedPrice == price);
  }

  onOkClicked = () => {
    const selectedPrices = this.selectedPrices.sort((a, b) => a.rate - b.rate);
    this.activeModal.close(selectedPrices);
  }

  onSelectedPriceRemovalRequested = (selectedPrice: SelectedPriceCardViewModel) => {
    this.removePriceFromSelectionList(selectedPrice.rate, selectedPrice.lockPeriod);

    const pricesPerRate = this.priceViewModelsByRate.get(selectedPrice.rate);
    const priceToUncheck = pricesPerRate.find(p => p.lockPeriod == selectedPrice.lockPeriod);
    if (priceToUncheck) {
      priceToUncheck.selected = false;
    }
  }

  constructor(private readonly _modalService: NgbModal, public activeModal: NgbActiveModal,) {
  }

  onAdjustmentsClicked = () => {
    const modalRef = this._modalService.open(ViewAdjustmentsDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.adjustments = this.adjustments;
  }

  viewNotesAndAdvisories = () => {
    const modalRef = this._modalService.open(ViewNotesAdvisoriesDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.notesAndAdvisories = this.notesAndAdvisories;
  }

  removePriceFromSelectionList = (rate: number, lockPeriod: number) => {
    const selectedPriceToRemove = this.selectedPrices.find(p => p.rate == rate && p.lockPeriod == lockPeriod);
    if (selectedPriceToRemove) {
      const index = this.selectedPrices.indexOf(selectedPriceToRemove);
      if (index >= 0) {
        this.selectedPrices.splice(index, 1);
      }
    }
  }

  ngOnInit(): void {
  }

}
