import { Component, Input, OnInit } from '@angular/core';
import { DocPrep } from '../../models/doc-prep.model';

@Component({
  selector: 'document-preparation-legal-description',
  templateUrl: 'document-preparation-legal-description.component.html'
})

export class DocumentPreparationLegalDescriptionComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  constructor() { }

  ngOnInit() { }

  onPrintLegalDescriptionClicked = (isChecked: boolean) => {

  }
}