import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-fee-definition-details-dialog',
  templateUrl: './fee-definition-details-dialog.component.html',
  styleUrls: ['./fee-definition-details-dialog.component.scss'],
  viewProviders: [formViewProvider],
})
export class FeeDefinitionDetailsDialogComponent implements OnInit {

  @ViewChild("feeDefinitionForm") feeDefinitionForm: NgForm | undefined;

  get feeDefinition(): FeeTemplateFee {
    return this._feeDefinition;
  }
  @Input()
  set feeDefinition(value: FeeTemplateFee) {
    this._feeDefinition = value;
    if (value) {
      this.feeDefinitionData = _.cloneDeep(value);
    }
  }

  isSaving: boolean = false;
  feeDefinitionData: FeeTemplateFee = new FeeTemplateFee();
  feePercentsValid: boolean = true;

  private _feeDefinition: FeeTemplateFee;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _feeService: FeeService,
    private readonly _notifyService: NotificationService
  ) {
  }


  ngOnInit(): void {
  }

  save = () => {

    if (this.feeDefinitionForm) {

      this.feeDefinitionForm.form.markAllAsTouched();

      if (this.feeDefinitionForm.form.valid) {
        this.isSaving = true;

        if (this.feeDefinitionData.feeTemplateFeeId) {
          // fee template
          this.activeModal.close(this.feeDefinitionData);
        }
        else { // fee library
          if (this.feeDefinitionData.feeDefinitionId) { // isUpdate
            this._feeService.updateFeeDefinition(this.feeDefinitionData.feeDefinitionId, this.feeDefinitionData)
              .subscribe((result) => {
                this.isSaving = false;
                this._notifyService.showSuccess("Fee Definition updated Successfully", "Success");
                this.activeModal.close(result);
              }, (err) => {
                this.isSaving = false;
                this._notifyService.showError(err?.message || err.error.message, "Error");
              })
          }
          else {
            this._feeService.insertFeeDefinition(this.feeDefinitionData)
              .subscribe((result) => {
                this.isSaving = false;
                this._notifyService.showSuccess("Fee Definition inserted Successfully", "Success");
                this.activeModal.close(result);
              }, (err) => {
                this.isSaving = false;
                this._notifyService.showError(err?.message || err.error.message, "Error");
              })
          }
        }


      }

    }

  }

}
