import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';

export class CalculatorHousingParams {
  interestRate: number = 0;
  term: number = 0;
  pi: number = 0;
  hazard: number = 0;
  taxes: number = 0;
  pmi: number = 0;
  piTi: number = 0;
  hoa: number = 0;
  totalHousing: number = 0;
}

@Component({
  selector: 'housing-calculator',
  templateUrl: './housing-calculator.component.html',
})
export class HousingCalculatorComponent implements OnInit {

  @Input() mortgage: UrlaMortgage;
  @Input() parameters = new CalculatorHousingParams();
  @Input() title: string = "Proposed";
  @Input() readOnlyAll: boolean = false;

  @Input()
  currentLoanInterestRate: number;

  @Input()
  currentLoanTerm: number;

  @Output() parametersChanged = new EventEmitter<CalculatorHousingParams>();

  interestRate: number;
  term: number;

  constructor(private readonly _mortgageCalculationService: MortgageCalculationService) { }

  ngOnInit(): void {
    this.calculateDefaults();
  }

  calculateDefaults = () => {

    if (this.title === "Today") {
      this.parameters.interestRate = this.currentLoanInterestRate || 0;
      this.parameters.term = this.currentLoanTerm || 0;
      this.parameters.pi = Number(this.mortgage.currentHousingExpense?.firstMortgagePrincipalAndInterest) || 0;
      this.parameters.hoa =  Number(this.mortgage.currentHousingExpense?.homeownersAssociationDuesAndCondominiumFees) || 0;
      this.parameters.hazard = (Number(this.mortgage.currentHousingExpense?.homeownersInsurance) || 0) + ( Number(this.mortgage.currentHousingExpense?.supplementalPropertyInsurance) || 0);
      this.parameters.taxes = Number(this.mortgage.currentHousingExpense?.realEstateTax) || 0;
      this.parameters.pmi = Number(this.mortgage.currentHousingExpense?.mortgageInsurance) || 0;
    } else {
      this.parameters.interestRate =  this.mortgage.mortgageTerm.interestRate ? this.mortgage.mortgageTerm.interestRate / 100 : 0;
      this.parameters.term =  Number(this.mortgage.mortgageTerm.noOfMonths) || 0;
      this.parameters.pi =  this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest;
      this.parameters.hoa =  Number(this.mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees) || 0;
      this.parameters.hazard = (Number(this.mortgage.proposedHousingExpense.homeownersInsurance) || 0) + ( Number(this.mortgage.proposedHousingExpense.supplementalPropertyInsurance) || 0);
      this.parameters.taxes = Number(this.mortgage.proposedHousingExpense.realEstateTax) || 0;
      this.parameters.pmi = Number(this.mortgage.proposedHousingExpense.mortgageInsurance) || 0;
    }

    this.calculateTotalPiTis();
  }

  calculateTotalPiTis = () => {
    this.parameters.piTi = this.parameters.pi + this.parameters.hazard + this.parameters.taxes + this.parameters.pmi;

    this.calculateTotalHousingPayments();

    this.parametersChanged.emit(this.parameters);
  }

  calculateTotalHousingPayments = () => {
    this.parameters.totalHousing = this.parameters.piTi + this.parameters.hoa;
    this.parametersChanged.emit(this.parameters);
  }
}
