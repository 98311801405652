<div class="modal-header">
  <h5 class="modal-title">Transaction History</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <ng-template [ngIf]="loadingHistory" [ngIfElse]="content">
    <loading-indicator [loadingMessage]="'Loading history...'"></loading-indicator>
  </ng-template>
  <ng-template #content>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3 row">
          <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Application Time</label>
          <div class="col-sm-8">
            <input class="form-control" id="applicationTime" name="applicationTime"
              type="text" [(ngModel)]="applicationTimeStr" [disabled]="true">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3 row">
          <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">LDE File Time</label>
          <div class="col-sm-8">
            <input class="form-control" id="ldeFileTime" name="ldeFileTime"
              type="text" [(ngModel)]="ldeLoanTimeStr" [disabled]="true">
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="transactionHistory">
      <h5>Lodasoft to LOS Transactions ({{transactionHistory?.events?.length}})</h5>
    </div>

    <p-table #dt1 [value]="filteredTransactionHistoryEvents" [paginator]="true" [rows]="25" *ngIf="transactionHistory"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10,25,50]" [resizableColumns]="true"
      [globalFilterFields]="['eventSource', 'eventDirection', 'eventOperation', 'description', 'status', 'errors', 'dateUpdated']"
      styleClass="p-datatable-gridlines" stateStorage="local" [stateKey]="stateKey">
      <ng-template pTemplate="caption">
        <div class="d-flex justify-content-between align-items-center">
          <los-lde-transaction-history-filters
            (filterChanged)="onFilterChanged($event)"
          ></los-lde-transaction-history-filters>

          <div class="p-input-icon-right p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText
              type="text"
              class="form-control"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              (ngModelChange)="dt1.first=0"
              placeholder="Search keyword"
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'eventSource'" pResizableColumn>Event Source
            <p-sortIcon [field]="'eventSource'"></p-sortIcon></th>
          <th [pSortableColumn]="'direction'" pResizableColumn>Direction
            <p-sortIcon [field]="'direction'"></p-sortIcon></th>
          <th [pSortableColumn]="'operation'" pResizableColumn>Operation
            <p-sortIcon [field]="'operation'"></p-sortIcon></th>
          <th width="330px" [pSortableColumn]="'description'" pResizableColumn>Description
            <p-sortIcon [field]="'description'"></p-sortIcon></th>
          <th [pSortableColumn]="'status'" pResizableColumn>Status
            <p-sortIcon [field]="'status'"></p-sortIcon></th>
          <th width="450px" [pSortableColumn]="'errors'" pResizableColumn>Comments/Errors
            <p-sortIcon [field]="'errors'"></p-sortIcon></th>
          <th [pSortableColumn]="'dateUpdated'" pResizableColumn>Timestamp
            <p-sortIcon [field]="'dateUpdated'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-event>
        <tr>
          <td ellipsis>
            {{event.eventSource}}
          </td>
          <td ellipsis>
            {{event.eventDirection | splitCamelcase}}
          </td>
          <td ellipsis>
            {{event.eventOperation | splitCamelcase}}
          </td>
          <td ellipsis>
            {{event.description}}
          </td>
          <td ellipsis>
            <span [ngClass]="event.status === 'Completed' ? 'text-success' :
            event.status === 'Failed' ? 'text-danger' :
            event.status === 'Ignored' ? 'text-warning' : 'text-info'">
              {{event.status}}
            </span>
          </td>
          <td style="overflow: hidden; white-space: normal;" [innerHTML]="event.errors | HTMLListPipe">
          </td>
          <td ellipsis>
            <span *ngIf="event.reqTraceId" title="Copy to Clipboard">
              <i class="far fa-copy cursor text-primary me-1" (click)="onCopyRequestTraceId(event.reqTraceId)"></i>
            </span>
            {{event.dateUpdated | date:'MMMM d, y hh:mm a'}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7" class="text-center"> There is no transaction history </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
</div>