import {Component, Injector, Input, OnInit} from '@angular/core';
import { WizardUrl } from '../../../../../models';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../services/system-level.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ScopeType } from '../integrations.component';
import {ApplicationContextBoundComponent} from '../../../../../shared/components';

@Component({
  selector: 'online-application',
  templateUrl: './online-application.component.html',
})
export class OnlineApplicationComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input() scope: ScopeType;
  @Input() wizardUrls: WizardUrl[];
  @Input() company: any;

  token: string;

  constructor(
      private readonly injector: Injector,
    private readonly _localStorageService: LocalStorageService,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService
  ) {
      super(injector);
  }

  ngOnInit(): void {
    const authData = this._localStorageService.authorizationData;
    if (authData) {
      this.token = authData.token;
    }
  }

  toggleActivateOnlineAppFlow(wizardUrl: WizardUrl): void {
    this._spinner.show();
    this._systemLevelService
      .toggleActivateOnlineAppFlow(wizardUrl)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        error: (error) => {
          this._notificationService.showError(
            `${error ? error.message : 'Unable to save.'}`,
            'System Level'
          );
        },
      });
  }

  toggleDefaultOnlineAppFlow(wizardUrl: WizardUrl): void {
    this._spinner.show();
    this._systemLevelService
      .toggleDefaultOnlineAppFlow(wizardUrl)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this.wizardUrls = res;
        },
        error: (error) => {
          this._notificationService.showError(
            `${error ? error.message : 'Unable to save.'}`,
            'System Level'
          );
        },
      });
  }

  showConfirmationDialog(wizardUrl: WizardUrl): void {
    if (wizardUrl.isDefault) {
      this._notificationService.showError('The default online application cannot be removed.', 'Error');
      return;
    }

    Swal.fire({
      title: 'Are you sure you want to delete this online application?',
      text: 'This action will be irreversible.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result: SweetAlertResult) => {
      if (!result.value) return;
      this.deleteOnlineApp(wizardUrl);
    });
  }

  private deleteOnlineApp(wizardUrl: WizardUrl) {
    this._systemLevelService.deleteOnlineAppFlow(wizardUrl)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: () => {
          const index = this.wizardUrls.findIndex(flow => flow.flowId === wizardUrl.flowId);
          if (index === -1) return;
          this.wizardUrls.splice(index, 1);
          this._notificationService.showSuccess(
            "Online application removed successfully.",
            'Success!'
          );
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || "Couldn't delete online application.",
            'Error!'
          );
        },
      });
  }
}
