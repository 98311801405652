import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { RoleBasedInternalContactsComponent } from "./role-based-internal-contacts.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    RoleBasedInternalContactsComponent
  ],
  exports: [
    RoleBasedInternalContactsComponent
  ],
  providers: []
})
export class RoleBasedInternalContactsModule {
  constructor() {
  }
}
