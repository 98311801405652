/**
 * Splits a camel case string into separate words.
 *
 * @param value - The string to split.
 * @returns The split string, with spaces inserted between words.
 *
 * @example
 * ```typescript
 * const result = splitCamelCase("helloWorld");
 * console.log(result); // "hello World"
 * ```
 */
export function splitCamelCase(value: string | undefined): string {
  return value?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '';
}

/**
 * Splits a camel case string into separate words.
 * Also splits every consecutive capital letter into a separate word.
 * @param {string} value - The string to split.
 * @returns {string} The split string, with spaces inserted between words.
 *
 * @example
 * ```typescript
 * const result = splitCamelCaseAndCapitals("ABeautifulDay");
 * console.log(result); // "A Beautiful Day"
 * ```
 */
export function splitCamelCaseAndCapitals(value: string | undefined): string {
  return value?.replace(/([a-zA-Z])([A-Z])/g, '$1 $2') ?? '';
}

/**
 * Converts a string to proper case (title case).
 *
 * @param value - The string to convert.
 * @returns The converted string, with the first character of each word
 * capitalized.
 *
 * @example
 * ```typescript
 * const result = toProperCase("hello world");
 * console.log(result); // "Hello World"
 * ```
 */
export function toProperCase(value: string | undefined): string {
  return value?.replace(
    /\b\w+/g,
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  ) ?? '';
}

/**
 * Converts a string to kebab case.
 *
 * @param value - The string to convert.
 * @returns The converted string, with each word separated by a hyphen and all characters in lowercase.
 *
 * @example
 * ```typescript
 * const result = toKebabCase("Hello World");
 * console.log(result); // "hello-world"
 * ```
 */
export function toKebabCase(value: string | undefined): string {
  return value?.trim().replace(
    /\b\w+\b/g,
    word => word.toLowerCase(),
  ).replace(/\s+/g, '-') ?? '';
}

