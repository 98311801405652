<div class="modal-header">
  <h5 class="modal-title"> New Title Order Doc </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <form #titleOrderDocForm="ngForm" novalidate id="titleOrderDocForm" name="titleOrderDocForm">
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end"> Doc Type </label>
          <div class="col-sm-6">
            <select class=" mb-3 form-select" name="docType" [(ngModel)]="documentType">
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let docType of documentTypes" [ngValue]="docType.value">
                {{docType.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 form-label mb-lg-0 text-end"> Files </label>
          <div class="col-sm-10 d-flex">
            <div class="col-sm-2">
              <input type="file" id="docFile" name="docFile" style="display:none" (change)="onDocFilesChanged($event)"
                multiple="false" />
              <button class="btn btn-primary" onclick="$('#docFile').click()">Add file</button>
              <span class="ms-4"> OR </span>
            </div>
            <div class="form-group row col-sm-5 ms-2" style="border: 2px dashed #889988">
              <file-upload [(ngModel)]="uploadedNewFiles" multiple="false" name="files"
                (ngModelChange)="onFileUploadChanged(uploadedNewFiles)">
              </file-upload>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="docFile">
          <table class="text-center">
            <tr>
              <th> File name </th>
              <th style="width: 15rem"> Action </th>
            </tr>
            <tr>
              <td>
                {{docFile.fileName}}
              </td>
              <td>
                <button type="button" class="btn btn-danger" (click)="removeDocFile()">
                  Remove
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()"> Cancel </button>
  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()" [disabled]="!docFile"> <i
      class="fas fa-save me-1 text-white"></i> Save
  </button>
</div>
