import { ChannelEnum } from "src/app/models";

export class BaseRequest {
  optimalBlueChannelId: number;
  optimalBlueOriginatorId: number;
  applicationId: number;
  credentialId: number;
  pricingChannelId: string;
  tpoOrgId: string;
  wholesalePriceGroup: string;
  correspondentPriceGroup: string;
  nonDelegatedPriceGroup: string;
  channel: ChannelEnum;

  constructor(optimalBlueChannelId?: number) {
    this.optimalBlueChannelId = optimalBlueChannelId;
  }
}
