import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { Constants } from 'src/app/services/constants';
import { CalculatedFeeTypeEnum } from '../../../models/fee/calculated-fee-type.enum';
import { CreateFeeModel } from '../../../models/fee/create-fee.model';
import { FeeSectionEnum } from '../../../models/fee/fee-section.enum';
import { FeeValidationModel } from '../../../models/fee/fee-validation.model';
import { CreateFeeModalComponent } from '../modals/create-fee-modal/create-fee-modal.component';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'prepaid-fees',
  templateUrl: 'prepaid-fees.component.html',
  styleUrls: ['prepaid-fees.component.scss']
})
export class PrepaidFeesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('prepaidForm')
  prepaidForm: NgForm;

  @Input()
  set fees(fees: LoanFee[]) {
    this._fees = fees;
    this.initialize();
  }

  get fees(): LoanFee[] {
    return this._fees;
  }

  @Input()
  feeSystemDetails: FeeSystemDetails;

  @Output() onFeeValueUpdated = new EventEmitter<LoanFee>();
  @Output() onFeeAdded = new EventEmitter<CreateFeeModel>();
  @Output() openFeeEditor = new EventEmitter<LoanFee>();
  @Output() onValidationChanged = new EventEmitter<FeeValidationModel>();

  feeSection = FeeSectionEnum.Prepaids;
  feesTotal = 0;
  isValidForm = true;

  prepaidFeesOtherThanOddDaysInterest: LoanFee[] = [];
  oddDaysInterestFees: LoanFee[] = [];

  oddInterestFeeType = CalculatedFeeTypeEnum[CalculatedFeeTypeEnum.OddDaysInterest];

  private _formValidationStatusChangesSubscription: Subscription;

  private _fees: LoanFee[];

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.prepaidForm) {
      this.prepaidForm.statusChanges.subscribe(status => this.onFormValidationStatusChanged(status));
    }
  }

  ngOnDestroy() {
    if (this._formValidationStatusChangesSubscription) {
      this._formValidationStatusChangesSubscription.unsubscribe();
    }
  }

  scrollToFirstInvalidControl() {
    Utils.validateAndScrollToFirstInvalidControl(this.prepaidForm);
    //Utils.scrollByAmount(300);
  }

  onFormValidationStatusChanged = (status: string) => {
    this.isValidForm = status !== "INVALID";
    this.onValidationChanged.next({ section: this.feeSection, calculatedType: null, isValid: this.isValidForm });
    if (!this.isValidForm) {
      this.prepaidForm.form.markAllAsTouched();
    }
  }

  onFeeEditorOpened = (fee: LoanFee) => {
    this.openFeeEditor.emit(fee);
  }

  openAddFeeModal() {
    const modalRef = this.modalService.open(CreateFeeModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.feeSystemDetails = this.feeSystemDetails;
    modalRef.componentInstance.hudNumbersToExclude = [...new Set(this._fees.map(fee => fee.hudNumber))].filter(hud => hud);
    modalRef.componentInstance.feeTypesToExclude = [...new Set(this._fees.map(fee => fee.feeType))].filter(feeType => feeType);
    modalRef.componentInstance.title = 'Create Prepaid Fee';
    modalRef.componentInstance.allowFreeformFeeEntry = false;
    modalRef.componentInstance.feeSection = this.feeSection;
    modalRef.componentInstance.calculatedFeeType = CalculatedFeeTypeEnum.MonthlyPaymentPremium;
    modalRef.componentInstance.restrictHudNumberEntryBySectionType = true;

    modalRef.result.then(
      (data: CreateFeeModel) => {
        this.onFeeAdded.next(data);
      }, () => { });
  }

  calculateTotal = () => {
    this.feesTotal = 0;
    this._fees.filter(x => !x.isSummaryFee).forEach((fee) => {
      if (!fee.borrowerFeeDollar || fee.borrowerFeeDollar.toString() == '-') {
        fee.borrowerFeeDollar = 0;
      }
      this.feesTotal += fee.borrowerFeeDollar;
    });
  }

  updateFeeValue = (fee: LoanFee) => {
    this.onFeeValueUpdated.emit(fee);
  }

  private initialize = () => {
    this.calculateTotal();
    this.oddDaysInterestFees = this._fees.filter(f => f.calculatedFeeType === CalculatedFeeTypeEnum.OddDaysInterest);
    this.prepaidFeesOtherThanOddDaysInterest = this._fees.filter(f => f.calculatedFeeType !== CalculatedFeeTypeEnum.OddDaysInterest);
  }
}
