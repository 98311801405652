export class WireRequestModel {
  requestedDate: string
  warehousePrincipal: number
  fundsToSendAmount: number
  investorName: WireRequestInvestorName = null
  noteAmount: number
  noteDate: string
  noteTermInMonths: number
  noteRate: number
  eNote: boolean = null
  takeoutExpirationDate: string
  mersNumber: string
  closingAgentPrimaryBankAbaRoutingNumber: string
  closingAgentFinalBankAccountNumber: string
  closingAgentOrderNumber: string

  borrowerFirstName: string
  borrowerMiddleInitial: string
  borrowerLastName: string
  borrowerSocialSecurityNumber: string
  borrowerDateOfBirth: string
  borrowerEthnicity: WireRequestEthnicity = null
  borrowerIncome: number
  borrowerRace: WireRequestRace = null
  borrowerSex: WireSexType = null

  coBorrowerFirstName: string
  coBorrowerMiddleInitial: string
  coBorrowerLastName: string
  coBorrowerSocialSecurityNumber: string
  coBorrowerDateOfBirth: string
  coBorrowerEthnicity: WireRequestEthnicity = null
  coBorrowerIncome: number
  coBorrowerRace: WireRequestRace = null
  coBorrowerSex: WireSexType = null

  address: string
  city: string
  state: string
  zip: string
  county: string

  amortizationType: WireRequestAmortizationType = null
  appraisalDate: string
  appraisalType: WireRequestAppraisalType = null
  appraisedValue: number
  documentationType: WireRequestDocumentationType = null
  loanType: WireRequestLoanType = null
  propertyType: WireRequestPropertyType = null
  rateType: WireRequestRateType = null
  lienPosition: WireRequestLienPosition = null
  occupancy: WireRequestOccupancy = null
  readonly originatorLoanNumber: number
  originatorProductCode: string
  salesPrice: number = 0
  otherLiens: number = 0
  creditScore: number
  grossIncome: number
}

export enum WireRequestEthnicity {
  HispanicOrLatine = "HispanicOrLatine",
  NotHispanicOrLatino = "NotHispanicOrLatino",
  InformationNotProvided = "InformationNotProvided",
  NotApplicable = "NotApplicable",
}

export enum WireRequestRace {
  AmericanIndianOrAlaskaNative = "AmericanIndianOrAlaskaNative",
  Asian = "Asian",
  BlackOrAfricanAmerican = "BlackOrAfricanAmerican",
  NativeHawaiianOrPacificIslander = "NativeHawaiianOrPacificIslander",
  White = "White",
  InformationNotProvided = "InformationNotProvided",
}

export enum WireSexType {
  Female = "Female",
  Male = "Male",
  InformationNotProvided = "InformationNotProvided",
  NotApplicable = "NotApplicable",
}

export enum WireRequestAmortizationType {
  ARM = "ARM",
  FRM = "FRM"
}

export enum WireRequestAppraisalType {
  _1004 = "_1004",
  _2055 = "_2055",
  PIW = "PIW",
  PIA = "PIA"
}

export enum WireRequestDocumentationType {
  Full = "Full",
  Alt = "Alt",
  Streamline = "Streamline"
}

export enum WireRequestLoanType {
  Hedged = "Hedged",
  Locked = "Locked"
}

export enum WireRequestPropertyType {
  SFR = "SFR",
  Condo = "Condo",
  ManfHome = "ManfHome",
  Multi = "Multi"
}

export enum WireRequestRateType {
  AE = "AE",
  AI = "AI"
}

export enum WireRequestOccupancy {
  OwnerOccupied = "OwnerOccupied",
  NonOwnedOccupied = "NonOwnedOccupied",
  Second = "Second"
}

export enum WireRequestLienPosition {
  Undefined = "Undefined",
  NotALien = "NotALien",
  FirstLien = "FirstLien",
  SecondLien = "SecondLien",
}

export enum WireRequestInvestorName {
  FlagstarBank = "Flagstar Bank, FSB",
  HomePointFinancialCorp = "Home Point Financial Corporation",
  NewrezLLC = "Newrez LLC C-O Deutsche Bank National Trust Company",
  UnitedWholesaleMortgage = "United Wholesale Mortgage"
}