<div class="card">
  <div class="card-header">
    <h4 class="card-title">CoBorrower Info</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerFirstName" class="control-lavel">First Name</label>
        <input
          id="wireReq_coBorrowerFirstName"
          type="text"
          name="wireReq_coBorrowerFirstName"
          class="form-control"
          [(ngModel)]="wireRequest.coBorrowerFirstName"
          [disabled]="isCoBorrowerDisabled"
        />
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerMiddleInitial" class="control-lavel">Middle Initial</label>
        <input
          id="wireReq_coBorrowerMiddleInitial"
          type="text"
          name="wireReq_coBorrowerMiddleInitial"
          class="form-control"
          [(ngModel)]="wireRequest.coBorrowerMiddleInitial"
          [disabled]="isCoBorrowerDisabled"
        />
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerLastName" class="control-lavel">Last Name</label>
        <input
          id="wireReq_coBorrowerLastName"
          type="text"
          name="wireReq_coBorrowerLastName"
          class="form-control"
          [(ngModel)]="wireRequest.coBorrowerLastName"
          [disabled]="isCoBorrowerDisabled"
        />
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerSocialSecurityNumber" class="control-lavel">SSN</label>
        <input
          id="wireReq_coBorrowerSocialSecurityNumber"
          class="form-control"
          name="wireReq_coBorrowerSocialSecurityNumber"
          type="text"
          [(ngModel)]="wireRequest.coBorrowerSocialSecurityNumber"
          #coSsnInput="ngModel"
          [ngClass]="{'is-invalid' : coSsnInput && coSsnInput.touched && coSsnInput.invalid}"
          mask="000-00-0000"
          placeHolderCharacter="#"
          [showMaskTyped]="true"
          [disabled]="isCoBorrowerDisabled"
        />
        <div class="invalid-feedback">Please enter a valid SSN</div>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerDateOfBirth" class="control-lavel">Date of Birth</label>
        <date-input
          [name]="'wireReq_coBorrowerDateOfBirth'"
          [id]="'wireReq_coBorrowerDateOfBirth'"
          [(ngModel)]="wireRequest.coBorrowerDateOfBirth"
          [readonly]="isCoBorrowerDisabled"
        ></date-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerEthnicity" class="control-lavel">Ethnicity</label>
        <select
          name="wireReq_coBorrowerEthnicity"
          id="wireReq_coBorrowerEthnicity"
          class="form-select"
          [(ngModel)]="wireRequest.coBorrowerEthnicity"
          [disabled]="isCoBorrowerDisabled"
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let ethnicity of ethnicities" value="{{ethnicity.value}}">{{ethnicity.name | splitCamelcase}}</option>
        </select>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerRace" class="control-lavel">Race</label>
        <select
          name="wireReq_coBorrowerRace"
          id="wireReq_coBorrowerRace"
          class="form-select"
          [(ngModel)]="wireRequest.coBorrowerRace"
          [disabled]="isCoBorrowerDisabled"
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let race of races" value="{{race.value}}">{{race.name | splitCamelcase}}</option>
        </select>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerSex" class="control-lavel">Sex</label>
        <select
          name="wireReq_coBorrowerSex"
          id="wireReq_coBorrowerSex"
          class="form-select"
          [(ngModel)]="wireRequest.coBorrowerSex"
          [disabled]="isCoBorrowerDisabled"
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let sex of sexTypes" value="{{sex.value}}">{{sex.name | splitCamelcase}}</option>
        </select>
      </div>
      <div class="col-sm-6 col-md-4 mt-3">
        <label for="wireReq_coBorrowerIncome" class="control-lavel">Income</label>
        <currency-input
          id="wireReq_coBorrowerIncome"
          name="wireReq_coBorrowerIncome"
          [(ngModel)]="wireRequest.coBorrowerIncome"
          [allowNegative]="false"
          [disabled]="isCoBorrowerDisabled"
        >
        </currency-input>
      </div>
    </div>
  </div>
</div>