import {Component, Input, OnInit} from '@angular/core';
import {ThirdPartyCredential} from '../../../../../../models';

@Component({
    selector: 'credentials-basic',
    templateUrl: './credentials-basic.component.html'
})
export class CredentialsBasicComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() title: string;
    @Input() titleLabel?: string;
    @Input() titleLabelBg?: string;
    @Input() showDeleteCredentials?: boolean;
    @Input() thirdPartyCredential?: ThirdPartyCredential;
    @Input() credentialAdditionalProperties?: any;
    @Input() enableMessageShow?: boolean;
    @Input() enableMessage?: string;
    @Input() hideUsername?: boolean;
    @Input() hidePassword?: boolean;

    constructor() {}

    ngOnInit(): void {
        if (!this.thirdPartyCredential) {
            this.thirdPartyCredential = {
                url: '',
                userName: '',
                password: '',
                credentialType: '',
                vendorName: '',
                alias: '',
                thirdPartyKeyValuePairs: []
            };
        }
    }
}
