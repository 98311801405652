<div
  class="inline-editor"
  *ngIf="editorMode != 'InlineReadOnly' && !disabled"
  [ngClass]="{
    'input-group': editorMode == 'Inline',
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }"
  [hidden]="
    editorMode === 'Inline' &&
    !isEditActive &&
    !(model && model.touched && model.invalid) &&
    !disabled
  "
>
  <input
    [(ngModel)]="value"
    type="text"
    #control
    #model="ngModel"
    name="{{ name }}"
    id="{{ name }}"
    placeholder="{{ placeholder }}"
    [required]="required"
    [allowZero]="isZeroValid"
    [minValue]="min"
    [readonly]="readonly"
    [attr.tabindex]='readonly ? -1 : null'
    (keydown)="onKeyDown($event)"
    (blur)="onBlurred($event)"
    (focus)="isEditActive = true; onFocused($event)"
    [disabled]="disabled"
    class="form-control"
    [ngClass]="{
      'is-invalid': forceInvalid || (model && model.touched && (model.invalid || customErrors)),
      'marked-as-invalid': highlightAsInvalid
    }"
    style="text-decoration: none !important;"
  />
  <button
    class="btn p-0 border-0 bg-transparent ms-1"
    title="Apply"
    type="button"
    *ngIf="editorMode == 'Inline' && !disabled"
    (mousedown)="onApplyClicked($event)"
  >
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button
    class="btn p-0 border-0 bg-transparent ms-1"
    title="Cancel"
    type="button"
    *ngIf="editorMode == 'Inline' && !disabled"
    (mousedown)="onCancelClicked($event)"
  >
    <i class="fa fa-times-circle text-danger"></i>
  </button>
  <ng-container *ngIf='forceInvalid || (model && model.touched)'>
    <ng-container *ngIf='model.invalid'>
      <div *ngIf="model.errors?.['required']" class='invalid-feedback'>This field is required</div>
      <div *ngIf="model.errors?.['cannotBeZero']" class='invalid-feedback'>This field cannot be zero</div>
      <div *ngIf="model.errors?.['minValue']" class='invalid-feedback'>This field cannot be less than {{ this.min }}</div>
    </ng-container>

    <ng-container *ngIf='customErrors'>
      <ng-container *ngFor='let error of customErrors | keyvalue'>
        <div class='invalid-feedback'>{{ error.value }}</div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<span
  [ngClass]="{
    'empty-field': value == null || value == ''
  }"
  class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}"
  title="Click to Edit"
  *ngIf="
    editorMode == 'Inline' &&
    !isEditActive &&
    !(model && model.touched && model.invalid) &&
    !disabled
  "
  (click)="onEditModeToggledOn()"
>
  <span class="val" *ngIf="value">{{ value | currency }}</span>
  <span class="placeholder" *ngIf="!value">--</span>
</span>

<span
  [ngClass]="{
    'empty-field': value == null
  }"
  class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}"
  *ngIf="editorMode == 'InlineReadOnly' || disabled"
>
  <span class='val' *ngIf='value != null'>{{
    value | currency
  }}</span>
  <span class='placeholder' *ngIf='value == null'
    >--</span
  >
</span>
