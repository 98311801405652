import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';

@Injectable()
export class NewApplicationService {

  constructor(private readonly _dataService: DataService) { }

  uploadFileToUrl = (url: string, files: FileList) => {
    var formData = new FormData();

    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
    }
    return this._dataService.postFormData(url, formData);
  }

  uploadFileForTask = (
    file: File,
    taskId: number,
    autoTransition: boolean = true,
    autoConvertToPdf: boolean = false,
    useDynamicCompression: boolean = false
  ) => {
    const url = `api/File/UpsertFileFromTask/${taskId}`
    const query = `?autoTransition=${autoTransition}&autoConvertToPdf=${autoConvertToPdf}&useDynamicCompression=${useDynamicCompression}`

    var formData = new FormData();

    formData.append("file", file);
    formData.append("model", JSON.stringify(file));

    return this._dataService.postFormData(url + query, formData);
  }

  uploadFileForLoanDoc = (
    file: File,
    loanDocId: number,
    autoTransition: boolean,
    autoConvertToPdf: boolean,
    useDynamicCompression: boolean) => {
    const url = `api/File/UpsertFileFromLoanDoc/${loanDocId}`
    const query = `?autoTransition=${autoTransition}&autoConvertToPdf=${autoConvertToPdf}&useDynamicCompression=${useDynamicCompression}`

    var formData = new FormData();

    formData.append("file", file);
    formData.append("model", JSON.stringify(file));

    return this._dataService.postFormData(url + query, formData);
  }

  uploadFilesForLoanDocs = (files: File[], metadata: any, loanDocId: number,
    autoTransition: boolean, autoConvertToPdf: boolean,
    useDynamicCompression: boolean) => {
    const url = "api/File/UpsertFileFromLoanDoc/" + loanDocId
      + "?autoTransition=" + autoTransition + "&autoConvertToPdf=" + autoConvertToPdf + "?autoConvertToPdf=false&useDynamicCompression=" + useDynamicCompression

    var formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file" + i, files[i]);
      }
    }
    if (metadata) {
      formData.append("model", JSON.stringify(metadata));
    }
    return this._dataService.postFormData(url, formData);
  }

  getExternalCompanyUsers = (externalCompanyId) => {
    const url = `api/Admin/AllUserModel?externalCompanyId=${externalCompanyId}`;
    return this._dataService.get(url);
  }

  importFromLos = (losCredentialId, losIdentifier) => {
    const url = 'api/Loan/LOS/import/' + losCredentialId + '/' + losIdentifier;
    return this._dataService.get(url);
  }

  getSuggestedBorrower = (firstName: string, lastName: string, email: string) => {
    const url = 'api/new-submission/suggest-borrowers?firstName=' + encodeURIComponent(firstName) + '&lastName=' + encodeURIComponent(lastName) + '&email=' + encodeURIComponent(email);
    return this._dataService.get(url);
  }

  processSubmission = (importMismo: boolean, submissionViaFile: boolean, data: any): Observable<{ applicationId: number, loanStatusId: number, primaryBorrowerId: number }> => {
    const url = 'api/new-submission/process-submission' + (importMismo || !submissionViaFile ? "-mismo-34" : "");
    return this._dataService.post(url, data);
  }

  importFannie = (casefileId: string): Observable<any> => {
    const url = `api/new-submission/import-fannie?casefileId=${casefileId}`;
    return this._dataService.post(url, null);
  }

}
