import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductPriceValidation } from '../../models/pricing/product-price-validation.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'pricing-validation-dialog',
  templateUrl: 'pricing-validation-dialog.component.html',
  styleUrls: ['./pricing-validation-dialog.component.scss'],
})

export class PricingValidationDialogComponent implements OnInit {

  @Input()
  pricingValidation: ProductPriceValidation

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.pricingValidation.items.forEach(i => {
      const fieldNameArray = i.pricingField.split('.');
      if (fieldNameArray?.length) {
        i.pricingField = Utils.splitCamelCaseString(fieldNameArray[fieldNameArray.length - 1]);
      }
    })
  }


}