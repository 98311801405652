<div class="modal-header">
  <h5 class="modal-title"> Create Scenario </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <form #createScenarioNameForm="ngForm" novalidate id="createScenarioNameForm" name="createScenarioNameForm">
      <div class="row">
        <input type="text" class="form-control" autocomplete="off" placeholder="Enter a Name" name="createScenarioName"
          [(ngModel)]="scenarioName" #name="ngModel" [ngClass]="{'is-invalid' : name && name.touched && name.invalid}"
          required />
        <div class="invalid-feedback"> This field is required </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()" data-bs-dismiss="modal"> Save
  </button>
</div>