<p-table #dt2 [columns]="selectedColumns" [value]="filteredLeads" [paginator]="true" [rows]="25"
  name="leads-power-user-table-v2" [rowsPerPageOptions]="[25, 50, 100]" [globalFilterFields]="globalFilterFields"
  [(selection)]="selectedRows" [autoLayout]="true" [showCurrentPageReport]="true"
  styleClass="p-datatable-gridlines p-datatable-sm"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  scrollable="true" [reorderableColumns]="true"
  [scrollHeight]="scrollHeight" [selectionPageOnly]="true"
  stateStorage="local" stateKey="leads-power-user-table-state" (onStateSave)="onStateSave($event)"
  sortMode="multiple" [multiSortMeta]="!tableState ? null : tableState.multiSortMeta">
  <ng-template pTemplate="caption">
    <div class="row mb-1">
      <div class="col-md-4 offset-md-4 col-12 d-flex justify-content-center mb-xs-2">
        <button [disabled]="selectedRows.length == 0" class="btn btn-primary mx-2 hide-on-mobile" (click)="openReassignModal()"> Reassign Lead</button>
        <app-add-to-dial-list
          [selectedRows]="selectedRows"
          [contactListType]="contactListType"
          [idFieldName]="'leadId'"
          (onAddedToDialList)="addedToDialList($event)"
        ></app-add-to-dial-list>
        <button class="btn btn-primary me-2 hide-on-mobile" (click)="exportToCSV()"> Export To CSV</button>
      </div>

      <div class="col-md-4 col-12 d-flex justify-content-end justify-content-xs-between">
        <!-- mobile -->
        <div class="btn-group hide-on-non-mobile">
          <button
            type="button"
            class="btn text-primary"
            title="Reassign Lead"
            [disabled]="selectedRows.length == 0"
            (click)="openReassignModal()"
            style="pointer-events: auto"
          >
            <i class="fas fa-people-arrows"></i>
          </button>

          <button
            type="button"
            class="btn text-primary"
            title="Export To CSV"
            (click)="exportToCSV()"
          >
            <i class="fas fa-file-csv"></i>
          </button>

          <button
            type="button"
            class="btn text-primary"
            data-bs-container="body"
            data-bs-toggle="popover"
            data-trigger="click"
            placement="bottom"
            (click)="openColumnSelectorForMobile()"
            [popover]="mobileColumnSelectorPopover"
            [outsideClick]="true"
            [containerClass]="'mobile-col-selector-popover'"
          >
            <i class="fa fa-cog"></i>
          </button>
          <ng-template #mobileColumnSelectorPopover>
            <p-multiSelect #mobileColumnSelector [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
              selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl defaultLabel="Choose Columns">
            </p-multiSelect>
          </ng-template>
        </div>

        <!-- desktop -->
        <p-multiSelect [options]="columns"
          [(ngModel)]="selectedColumns" optionLabel="header" selectedItemsLabel="{0} columns selected"
          name="selectedColumns" ngDefaultControl defaultLabel="Choose Columns" class="hide-on-mobile"></p-multiSelect>

        <!-- desktop and mobile -->
        <span class="p-input-icon-right ms-2 h-100">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="form-control h-100"
            [(ngModel)]="globalSearchString"
            (ngModelChange)="onGlobalSearchStringChanged($event)"
            placeholder="Search keyword" />
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="justify-content-center" style="min-width: 150px;">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        <span class="ms-2">{{'Check All Visible'}}</span>
      </th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="min-width: 250px;" pReorderableColumn>
        <ng-container [ngSwitch]="col.dataType">
          <p-columnFilter
            *ngSwitchCase="'currency'"
            type="numeric"
            currency="USD"
            [field]="col.field"
            display="menu">
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'percentage'"
            type="numeric"
            minFractionDigits="0"
            maxFractionDigits="3"
            [field]="col.field"
            display="menu">
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'phone'"
            type="text"
            [field]="col.field"
            display="menu">
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'leadStatus'"
            field="leadStatusName"
            matchMode="in" display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false">
            <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Lead Status Picker</span>
                </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="leadStatuses" placeholder="Any"
                  (onChange)="filter($event.value)" optionLabel="loanStatusName" optionValue="loanStatusName">
                </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'loanType'"
            field="loanTypeName"
            matchMode="in" display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false">
            <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Loan Type Picker</span>
                </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="loanTypes" placeholder="Any"
                  (onChange)="filter($event.value)" optionLabel="loanTypeName" optionValue="loanTypeName">
                </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'loanPurpose'"
            field="loanPurposeName"
            matchMode="in" display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false">
            <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Lead Status Picker</span>
                </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="loanPurposes" placeholder="Any"
                  (onChange)="filter($event.value)" optionLabel="loanPurposeName" optionValue="loanPurposeName">
                </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'leadCampaign'"
            field="leadCampaignName"
            matchMode="in" display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false">
            <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Lead Campaign Picker</span>
                </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="leadCampaigns" placeholder="Any"
                  (onChange)="filter($event.value)" optionLabel="name" optionValue="name">
                </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchCase="'leadListIds'"
            field="leadListIds"
            [matchMode]="'tags-filter'" display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false">
            <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                    <span class="font-bold">Tag Picker</span>
                </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="leadLists" placeholder="Any"
                  (onChange)="filter($event.value)" optionLabel="name" optionValue="leadListId">
                </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngSwitchDefault
            [type]="col.dataType"
            [field]="col.field"
            display="menu">
          </p-columnFilter>
        </ng-container>
        <span>{{ col.header }}</span>
        <p-sortIcon [field]="col.field" class="pull-right sort-icon-margin"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <span>
          <p-tableCheckbox [value]="rowData"> </p-tableCheckbox>
          <span class="mx-1"> | </span>
        </span>

        <span *ngIf="editRequestedForLead.observed">
          <i class="fa fa-pencil-alt cursor text-info" (click)="onEditLeadDetailsClicked(rowData)" title="Edit"></i>
        </span>

        <span>
          <span class="mx-1"> | </span>
          <i class="fa fa-trash-alt cursor text-danger"
            (click)="onDeleteLeadClicked(rowData.leadId)" title="Delete" id="btnLeadDelete"></i>
        </span>
      </td>
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span class="text-cell-primary" *ngSwitchCase="'interestRate'">
          {{ rowData[col.field] ? rowData[col.field].toFixed(3) + "%" : '--' }}
        </span>
        <span class="text-cell-primary" *ngSwitchCase="'leadListIds'">
          <span class="badge rounded-pill m-1 row" style="background-color: #8f4397; float: left"
            *ngFor="let leadListId of rowData['leadListIds']">
            {{ getLeadListNameById(leadListId) }}
          </span>
        </span>
        <ng-container *ngSwitchDefault>
          <ng-container [ngSwitch]="col.dataType">
            <span class="text-cell-primary" *ngSwitchCase="'currency'">
              {{ rowData[col.field] | currency : 'USD' : 'symbol' : '1.0-0' }}
            </span>
            <span class="text-cell-primary" *ngSwitchCase="'phone'">
              {{ rowData[col.field] | phone }}
            </span>
            <span class="text-cell-primary" *ngSwitchCase="'date'">
              {{ rowData[col.field] | date: 'short' }}
            </span>
            <span class="text-cell-primary" *ngSwitchDefault>
              {{ rowData[col.field] }}
            </span>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="selectedColumns.length + 1" class="text-center">
        There are no leads for the selected filters
      </td>
    </tr>
  </ng-template>
</p-table>
