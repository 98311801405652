import { Injectable } from "@angular/core";
import { delay, Observable, of } from 'rxjs';
import { AuthenticationRequest } from "../models/auth/authentication-request.model";
import { ApplicationContextService } from "./application-context.service";
import { NavigationService } from "./navigation.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import { DataService } from "../core/services/data.service";
import { AuthenticationResponse, AuthenticationUserCompany } from "../models/auth/authentication-response.model";
import { ForgotPasswordModel, ResetPasswordModel, UserType } from "../models";
import { ChangeBorrowerPasswordRequest, ChangePasswordRequest } from "../models/auth/change-password-request.model";
import { FirebaseService } from "./firebase";
import { SystemLevelService } from "./system-level.service";
import { EnvironmentService } from "../core/services/environment/environment.service";
import { GenerateSecretAppServiceResponse } from "../models/auth/generate-secret-app-service-response.model";

@Injectable()
export class AuthService {

  private _companyGuid: string;

  get companyGuid(): string {
    return this._companyGuid;
  }

  constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _applicationContext: ApplicationContextService,
    private readonly _navigationService: NavigationService,
    private readonly _dataService: DataService,
    private readonly _firebaseService: FirebaseService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _environment: EnvironmentService
  ) { }

  logout = (): Observable<any> => {
    return of(this.logoutPrivate().subscribe().add(() => {
      this._firebaseService.disconnect();
      this.clearAll();
      this._navigationService.navigateToLogin(null, false);
    }));
  };

  logoutPrivate = (): Observable<any> => {
    const url = 'api/auth/logout';
    return this._dataService.post(url, {});
  };

  clearAll = (): void => {
    if (this._localStorageService.authorizationData) {
      this._localStorageService.authorizationData = null;
    }
    this._applicationContext.reset();
  }

  signIn = (request: AuthenticationRequest): Observable<AuthenticationResponse> => {
    const url = 'api/auth/signin';
    return this._dataService.postWithoutAuth(url, request);
  }

  refreshToken = (refreshToken: string): Observable<AuthenticationResponse> => {
    const url = `api/auth/refreshToken?refreshToken=${refreshToken}`;
    return this._dataService.post(url, {});
  }

  exchangeToken = (companyGuid: string, userType: UserType, userCompanyGuid: string): Observable<AuthenticationResponse> => {
    const url = `api/auth/exchangeToken?companyGuid=${companyGuid}&userType=${userType}&userCompanyGuid=${userCompanyGuid}`;
    return this._dataService.post(url, {});
  }

  exchangeReadOnlyTokenForApplication = (applicationId: number): Observable<AuthenticationResponse> => {
    const url = `api/auth/exchangeToken/readonly/application?applicationId=${applicationId}`;
    return this._dataService.post(url, {});
  }

  exchangeReadOnlyTokenForBorrower = (borrowerId: number): Observable<AuthenticationResponse> => {
    const url = `api/auth/exchangeToken/readonly/borrower?borrowerId=${borrowerId}`;
    return this._dataService.post(url, {});
  }

  recoverPassword = (data: ForgotPasswordModel): Observable<any> => {
    const url = "api/auth/ForgotPassword";
    return this._dataService.post(url, data);
  }

  changePassword = (changePasswordRequest: ChangePasswordRequest): Observable<any> => {
    const url = "api/auth/ChangePassword";
    return this._dataService.post(url, changePasswordRequest);
  }

  changeBorrowerPassword = (changePasswordRequest: ChangeBorrowerPasswordRequest): Observable<any> => {
    const url = "api/Superadmin/ChangeBorrowerPassword";
    return this._dataService.post(url, changePasswordRequest);
  }

  resetPassword = (data: ResetPasswordModel): Observable<any> => {
    const url = "api/auth/ResetPassword";
    return this._dataService.post(url, data);
  }

  saveRegistration = (registration, token, userActive) => {
    let url;
    if (token == undefined) {
      url = 'api/auth/register' + (userActive ? 'link' : '');
    }
    else {
      url = 'api/auth/register' + (userActive ? 'link' : '') + '?token=' + token;
    }
    return this._dataService.post(url, registration);
  }

  sendTwoFactorEmailCode = (userCompanyGuid: string): Observable<any> => {
    const url = `api/auth/SendTwoFactorEmailCode?userCompanyGuid=${userCompanyGuid}`;
    return this._dataService.post(url, {});
  }

  sendTwoFactorPhoneCode = (userCompanyGuid: string): Observable<any> => {
    const url = `api/auth/SendTwoFactorPhoneCode?userCompanyGuid=${userCompanyGuid}`;
    return this._dataService.post(url, {});
  }

  confirmPhoneNumber = (phoneNumber: string, areacode: string, securityCode: string): Observable<any> => {
    return this._dataService.post(`api/auth/ConfirmUpdatePhone?phone=${phoneNumber}&areacode=${areacode}&code=${securityCode}`, {});
  }

  changeProfileUserPassword = (userCompanyGuid: string, body: any): Observable<any> => {
    const url = `api/Superadmin/SetPassword?userGuid=${userCompanyGuid}`;
    return this._dataService.post(url, body);
  }

  changeProfileUsername = (userCompanyGuid: string, username: string): Observable<any> => {
    const url = `api/Superadmin/SetUsername?userGuid=${userCompanyGuid}&newEmail=${encodeURIComponent(username)}`;
    return this._dataService.post(url, {});
  }

  getAvailableCompanies = (email: string): Observable<AuthenticationUserCompany[]> => {
    return this._dataService.get(`api/auth/AvailableCompanies?userName=${encodeURIComponent(email)}&scope=${UserType.Admin}`);
  }

  confirmUpdatePhoneAnonymous = (phoneNumber: string, areacode: string, code: string, request: AuthenticationRequest): Observable<any> => {
    return this._dataService.postWithoutAuth(`api/auth/ConfirmUpdatePhoneAnonymous?phone=${phoneNumber}&areacode=${areacode}&code=${code}`, request);
  }

  updatePhoneAnonymous = (phoneNumber: string, areacode: string, request: AuthenticationRequest): Observable<any> => {
    return this._dataService.postWithoutAuth(`api/auth/UpdatePhoneAnonymous?phone=${phoneNumber}&areacode=${areacode}`, request);
  }

  generateSecretService = (serviceType: string, appName: string): Observable<any> => {
    const url = `api/Auth/connect/generate-secret/service/${serviceType}?applicationName=${appName}`;
    return this._dataService.post(url, {});
  }

  generateSecretAppService = (appName: string): Observable<GenerateSecretAppServiceResponse> => {
    const url = `api/Auth/connect/app-service/generate-secret?applicationName=${appName}`;
    return this._dataService.post(url, {});
  }

  renerateSecretAppService = (clientId: string): Observable<GenerateSecretAppServiceResponse> => {
    const url = `api/Auth/connect/app-service/generate-secret/${clientId}`;
    return this._dataService.post(url, {});
  }

  getAllAppServices = (): Observable<GenerateSecretAppServiceResponse[]> => {
    const url = `api/Auth/connect/app-service`;
    return this._dataService.get(url, {});
  }

  revokeSecret = (clientId: string): Observable<any> => {
    const url = `api/Auth/connect/${clientId}/revoke-secret`;
    return this._dataService.post(url, {});
  }
}
