<a
  (click)="onAction()"
  *ngIf="status.actionName.length > 0 && !disabledInviteLink"
  (mouseover)="actionLinkVisible = true"
  (mouseout)="actionLinkVisible = false"
  class="link"
  [ngClass]="{
    'text-danger': status.type === 3,
    'text-warning': status.type === 2,
    'text-success': status.type === 1
  }"
>
  {{
    actionLinkVisible && status.actionName.length > 0
      ? status.actionName
      : status.name
  }}
</a>
<span
  [ngClass]="{
    'text-danger': status.type === 3,
    'text-warning': status.type === 2,
    'badge': status.type === 1,
    'badge-soft-success': status.type === 1
  }"
  *ngIf="status.actionName.length === 0 || disabledInviteLink"
  >{{ status.name }}</span
>
