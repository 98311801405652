import { CustomField } from "./custom-fields.model";


export class CustomFieldSection {
    pricingCustomFieldSectionId: number;
    name: string;
    pricingVendor: string;
    displayOrder: number;
    customFields: Array<CustomField> = [];
    companyId: number;
    modelGuid: string;
    insertedBy: string;
    updatedBy: string;
    dateInserted: string;
    dateUpdated: string;
    constructor(vendor: 'Polly' | 'LenderPrice' | 'MeridianLink' | 'LoanPass' | 'LoanPassIframe'){
        this.pricingVendor = vendor;
    }
}