import { Observable, of } from 'rxjs';

export function tryGetErrorMessage(error: unknown): string | undefined {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'object' && error !== null) {
    if ('message' in error && error['message']) {
      // @ts-ignore
      return tryGetErrorMessage(error.message);
    }

    if ('error' in error) {
      // @ts-ignore
      return tryGetErrorMessage(error.error);
    }
  }

  return undefined;
}

export function getErrorMessageOrDefault(
  error: unknown,
  options?: { defaultMessage?: string }
): string {
  return (
    tryGetErrorMessage(error) ??
    options?.defaultMessage ??
    'An unknown error has occurred'
  );
}

export function ignoreError<T>(_: any, __: Observable<T>): Observable<T | undefined> {
  // console.error(getErrorMessageOrDefault(error));
  return of(undefined);
}
