import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-snooze-dialog',
  templateUrl: './confirm-snooze-dialog.component.html',
  styleUrls: ['./confirm-snooze-dialog.component.scss']
})
export class ConfirmSnoozeDialogComponent {

  count: number;

  snoozeDurationInMinutes: number = 15;

  constructor(public activeModal: NgbActiveModal) { }
}
