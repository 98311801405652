<div class="modal-header">
  <h5 class="modal-title"> {{ title }} </h5>
  <div class="event-informations">
    <div *ngIf="showAddressAndBorrowerInfo">
      <i class="fas fa-user user-icon"></i>
      <label>Borrower: {{event?.borrowerName}}</label>
    </div>
    <div *ngIf="showAddressAndBorrowerInfo">
      <i class="fas fa-home home-icon"></i>
      <label>Address: {{event?.displayAddress}}</label>
    </div>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
  </div>
</div>
<div class="modal-body">
  <event-editor #eventEditor [event]="event"
    [showActionControls]="false" (eventSaved)="onEventSaved($event)" (onFormInvalid)="onFormInvalid()" [eventType]="eventType" [readonly]="event.eventId == 0"
    [selectedDate]="selectedDate">
  </event-editor>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" *ngIf="event && event.eventId > 0" (click)="onDeleteClick()"
    [disabled]="isLoading || isDeleteLoading" data-bs-dismiss="modal">
    <span *ngIf="isDeleteLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ isDeleteLoading ? 'Please wait...' : 'Delete'}}
  </button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Cancel
  </button>
  <button class="btn btn-primary float-end" type="button" [disabled]="isLoading" (click)="onSaveClicked()">
    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ isLoading ? 'Please wait...' : 'Save'}}
  </button>
</div>