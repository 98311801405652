import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {CocEntryComponent} from './entry/coc-entry.component';
import {CocHistoryComponent} from './history/coc-history.component';
import {CocService} from '../../services/coc.service';
import {ChangeOfCircumstanceComponent} from './change-of-circumstance.component';
import {LookupService} from '../../services/lookup.service';
import { CocTaskDialogComponent } from './history/coc-task-dialog/coc-task-dialog.component';
import { TableModule } from 'primeng/table';

@NgModule({
    imports: [
        SharedModule,
        TableModule
    ],
    declarations: [
        ChangeOfCircumstanceComponent,
        CocEntryComponent,
        CocHistoryComponent,
        CocTaskDialogComponent
    ],
    exports: [
        ChangeOfCircumstanceComponent,
        CocEntryComponent,
        CocHistoryComponent
    ],
    providers: [
        CocService,
        LookupService
    ]
})
export class ChangeOfCircumstanceModule {
  constructor() {

  }
}
