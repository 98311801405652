export class Event {
  eventId: number;
  title: string;
  eventType: EventType = null;
  description: string;
  privilege: EventVisibility = null;
  location: string;
  icon?: string;
  className?: string;
  allDay: boolean;
  dateStart: string;
  dateEnd: string;
  systemAllDayEventDate?: string;
  eventRepeat: string;
  remindBeforeMinutes: number;
  remindToaster: boolean;
  remindSms: boolean;
  remindEmail: boolean;
  userId: string;
  userName: string;
  loginName?: string;
  applicationId: number;
  leadId: number;
  borrowerId: number;
  borrowerName: string;
  borrowerPhone?: string;
  displayAddress: string;
  readOnly: boolean;
  loanPurposeName?: string;
  primaryRoleContact?: string;
  propertyWillBe?: PropertyWillBeForEvents;
  companyId: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
  thirdPartyEventId: string;
}

export enum EventVisibility {
  Private = "Private",
  Public = "Public"
}

export enum EventType {
  Appointment = "Appointment",
  EstimatedClosing = "EstimatedClosing",
  LockExpiration = "LockExpiration"
}

export enum EventTypeColorEnum {
  Appointment = '#396bef',
  EstimatedClosing = '#1fbb00',
  LockExpiration = '#a90329'
}

export enum PropertyWillBeForEvents {
  PrimaryResidence = "PrimaryResidence",
  SecondaryResidence = "SecondaryResidence",
  Investment = "Investment"
}


export class CalendarOriginalEventModel {
  id: number;
  start: string;
  end: string;
  title: string;
  backgroundColor: string;
  borderColor: string;
  extendedProps: ExtendedPropsModel;
  allDay: boolean;

  constructor() {
    this.start = '';
    this.end = '';
    this.title = '';
    this.borderColor = '';
    this.backgroundColor = '';
    this.extendedProps = {description: '', loanPurposeName: '', primaryRoleContact: ''};
    this.allDay = false;
  }
}

export class ExtendedPropsModel {
  description: string;
  loanPurposeName: string;
  primaryRoleContact: string;
}
