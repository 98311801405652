import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentsModule } from '../modules/agents/agents.module';
import { AppDetailsModule } from '../modules/app-details/app-details.module';
import { ContactsModule } from '../modules/contacts/contacts.module';
import { DialerModule } from '../modules/dialer/dialer.module';
import { LeadsModule } from '../modules/leads/leads.module';
import { LoanActivityModule } from '../modules/loan-activity/loan-activity.module';
import { NewApplicationModule } from '../modules/new-application/new-application.module';
import { TasksModule } from '../modules/tasks/tasks.module';
import { AlertsService } from '../services/alerts.service';
import { DrawerService } from '../shared/services/drawer.service';
import { SharedModule } from '../shared/shared.module';
import { LayoutAdminComponent } from './admin/admin.component';
import { AdminHeaderComponent } from './admin/header/admin-header.component';
import { CallsOnLeadsOnComponent } from './admin/header/calls-on-leads-on/calls-on-leads-on.component';
import { HeaderActionsComponent } from './admin/header/header-actions/header-actions.component';
import { HeaderMenuComponent } from './admin/header/header-menu/header-menu.component';
import { NotificationsListComponent } from './admin/header/notifications-list/notifications-list.component';
import { AcceptAndDialFreeLeadsDialogComponent } from './admin/lead-route-leads/dialogs/accept-and-dial-free-leads-dialog/accept-and-dial-free-leads-dialog.component';
import { NewLeadAssignedDialogComponent } from './admin/lead-route-leads/dialogs/new-lead-assigned-dialog/new-lead-assigned-dialog.component';
import { NewLeadReceivedDialogComponent } from './admin/lead-route-leads/dialogs/new-lead-received-dialog/new-lead-received-dialog.component';
import { WarmTransferLeadAvailableDialogComponent } from './admin/lead-route-leads/dialogs/warm-transfer-lead-available-dialog/warm-transfer-lead-available-dialog.component';
import { LeadRouteLeadsComponent } from './admin/lead-route-leads/lead-route-leads.component';
import { LayoutAuthenticationComponent } from './authentication/authentication.component';
import { HeaderClearStorageComponent } from './widgets/clear-storage.component';
import { HeaderIconComponent } from './widgets/icon.component';
import { HeaderNotifyComponent } from './widgets/notify.component';
import { HeaderSearchComponent } from './widgets/search.component';
import { HeaderTaskComponent } from './widgets/task.component';
import { HeaderUserComponent } from './widgets/user.component';
import { ConversationsModule } from '../modules/conversations/conversations.module';
import { BorrowerModule } from '../modules/borrower/borrower.module';
import { TpoLayoutComponent } from './tpo/tpo-layout/tpo-layout.component';
import { TpoHeaderComponent } from './tpo/tpo-header/tpo-header.component';
import { DialNumberAndCallHistoryModule } from '../modules/dial-number-and-call-history/dial-number-and-call-history.module';
// eslint-disable-next-line import/order

const Components = [TpoLayoutComponent, LayoutAdminComponent];

const HeaderComponents = [
  HeaderSearchComponent,
  HeaderNotifyComponent,
  HeaderTaskComponent,
  HeaderIconComponent,
  HeaderClearStorageComponent,
  HeaderUserComponent,
  AdminHeaderComponent, HeaderMenuComponent, HeaderActionsComponent,
  TpoHeaderComponent
];

const Passport = [LayoutAuthenticationComponent];

@NgModule({
  imports: [
    SharedModule,
    DialerModule,
    LeadsModule,
    LoanActivityModule,
    TasksModule,
    AppDetailsModule,
    AgentsModule,
    ContactsModule,
    NewApplicationModule,
    ConversationsModule,
    BorrowerModule,
    DialNumberAndCallHistoryModule,
  ],
  declarations: [
    ...Components, ...HeaderComponents, ...Passport,
    NotificationsListComponent,
    CallsOnLeadsOnComponent, LeadRouteLeadsComponent,
    AcceptAndDialFreeLeadsDialogComponent, WarmTransferLeadAvailableDialogComponent,
    NewLeadAssignedDialogComponent, NewLeadReceivedDialogComponent
  ],
  exports: [...Components, ...HeaderComponents, ...Passport,
    NotificationsListComponent],
  providers: [AlertsService, NgbActiveModal, DrawerService]
})
export class LayoutModule { }
