
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { PipelineStatsComponent } from './components/pipeline-stats/pipeline-stats.component';
import { PipelineTableV2Component } from './components/pipeline-table-v2/pipeline-table-v2.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { PipelineDialogComponent } from './components/pipeline-dialog/pipeline-dialog.component';
import { PipelineFilterV2Component } from './components/pipeline-filter-v2/pipeline-filter-v2.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { LoanStatusService } from '../admin/loan-config/services';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AppDetailsModule } from '../app-details/app-details.module';
import { PipelineFilterModule } from './components/pipeline-filter/pipeline-filter.module';
import { LoanConfigModule } from '../admin/loan-config/loan-config.module';
import { CustomDataInfoModule } from '../admin/custom-data-info/custom-data-info.module';

@NgModule({
    imports: [
        SharedModule,
        TableModule,
        MultiSelectModule,
        PopoverModule.forRoot(),
        ContextMenuModule.forRoot({
            useBootstrap4: true
        }),
        AppDetailsModule,
        PipelineFilterModule,
        LoanConfigModule,
        CustomDataInfoModule
    ],
  declarations: [
    PipelineStatsComponent,
    PipelineTableV2Component,
    PipelineDialogComponent,
    PipelineFilterV2Component
  ],
  exports: [
    PipelineStatsComponent,
    PipelineTableV2Component,
    PipelineDialogComponent,
    PipelineFilterV2Component
  ],
  providers: [LoanStatusService]
})
export class PipelineModule {
  constructor() { }
}


