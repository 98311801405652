import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { CallHistory, CallHistoryFilterRequest } from '../models/voice/call-history.model';
import { CallRecordingDetails } from '../models/voice/call-recording-details.models';
import { ConferenceLine } from '../modules/dialer/models/conference-line.model';
import { ConferenceResponse } from '../modules/dialer/models/conference-response.model';
import { FetchLeadResponse } from '../modules/dialer/models/fetch-lead-response.model';
import { LeadRouterResponse } from '../modules/dialer/models/lead-router-response.model';
import { ManualCallRequest } from '../modules/dialer/models/manual-call-request.model';
import { StartWarmTransferResponse } from '../modules/dialer/models/start-warm-transfer-response.model';
import { StartConferenceResponse, VoiceResponse } from '../modules/dialer/models/voice-response.model';

@Injectable({
  providedIn: 'root'
})
export class VoiceService {

  constructor(private readonly _dataService: DataService) { }

  getCallRecording = (voiceHistoryId: number): Observable<CallRecordingDetails> => {
    return this._dataService.get("api/voice/call/" + voiceHistoryId + "/recording");
  }

  startConference = (conferenceLineId?: number): Observable<StartConferenceResponse> => {
    let url = "api/voice/conference";
    if (conferenceLineId) {
      url += '?' + _.compact([
        conferenceLineId ? `conferenceLineId=${conferenceLineId}` : ''
      ]).join('&');
    }

    return this._dataService.post(url, {});
  }

  endConference = (conferenceId: number): Observable<VoiceResponse> => {
    return this._dataService.post("api/voice/conference/" + conferenceId + "/end", {});
  }

  addParticipantToConference = (conferenceId: number, targetNumber?: string, dialListRecordId?: number, telephonyPoolId?: number): Observable<ConferenceResponse> => {
    let url = "api/voice/conference/" + conferenceId + "/participant";

    if (targetNumber || dialListRecordId || telephonyPoolId) {
      url += '?' + _.compact([
        targetNumber ? `targetNumber=${targetNumber}` : '',
        dialListRecordId ? `dialListRecordId=${dialListRecordId}` : '',
        telephonyPoolId ? `poolId=${telephonyPoolId}` : ''
      ]).join('&');
    }

    return this._dataService.post(url, {});
  }

  removeActiveParticipantFromConference = (conferenceId: number, participantId: number): Observable<any> => {
    return this._dataService.delete("api/voice/conference/" + conferenceId + "/participant/" + participantId)
      .pipe(
        catchError(err => {
          if (err.indexOf("Unable to find conference line that this conference belongs to.") > -1) {
            return of(true);
          }
          else {
            throw err;
          }
        }))
  }

  changeConferenceStatus = (activeConferenceId: number, activeParticipantId: number, status: 'hold' | 'unhold' | 'mute' | 'unmute'): Observable<any> => {
    return this._dataService.post("api/voice/conference/" + activeConferenceId + "/participantId/" + activeParticipantId + "/" + status, {});
  }

  fetchConferenceLead = (activeConferenceId: number, telephonyPoolId?: number): Observable<FetchLeadResponse> => {
    let url = "api/voice/conference/" + activeConferenceId + "/fetch-queued";

    if (telephonyPoolId) {
      url += `?telephonyPoolId=${telephonyPoolId}`
    }
    
    return this._dataService.post(url, {})
      .pipe(
        map(response => {
          if (!response.dialRecord) {
            throw new Error("No Lead Found");
          }
          else {
            return response;
          }
        }))
  }

  cancelWarmTransfer = (activeConferenceId: number, participantId: number): Observable<any> => {
    return this._dataService.post("api/voice/conference/" + activeConferenceId + "/warm-transfer/cancel", {});
  }

  publishWarmTransfer = (activeConferenceId: number): Observable<LeadRouterResponse> => {
    return this._dataService.post("api/voice/conference/" + activeConferenceId + "/warm-transfer/publish", {});
  }

  startWarmTransfer = (voiceHistoryId: number, leadRouteHistoryId: number): Observable<StartWarmTransferResponse> => {
    return this._dataService.post(`api/voice/${voiceHistoryId}/warm-transfer/start/${leadRouteHistoryId}`, {});
  }

  completeWarmTransfer = (activeConferenceId: number): Observable<ConferenceResponse> => {
    return this._dataService.post(`api/voice/conference/${activeConferenceId}/warm-transfer/complete`, {});
  }

  getActiveConference = (): Observable<ConferenceLine> => {
    return this._dataService.get("api/voice/conference/active");
  }

  // manually calls a number
  manualCallNumber = (manualCallRequest: ManualCallRequest): Observable<VoiceResponse> => {
    return this._dataService.post("api/voice/call", manualCallRequest);
  }

  getCallHistory = (requestFilter: CallHistoryFilterRequest): Observable<CallHistory[]> => {
    return this._dataService.post("api/voice/history/search", requestFilter);
  }
}
