<p-table
  #dt
  *ngIf="!isLoading; else loading"
  #orderHistoryTable
  [value]="histories"
  [paginator]="true"
  [rows]="25"
  [showCurrentPageReport]="true"
  [resizableColumns]="true"
  [globalFilterFields]="globalFilterFields"
  sortField="dateInserted"
  sortMode="single"
  [sortOrder]="-1"
  responsiveLayout="scroll"
  scrollable="true"
  [scrollHeight]="scrollHeight"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[10, 25, 50]"
  styleClass="p-datatable-gridlines"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex" style="align-items: center">
      <span class="p-input-icon-right ms-2 p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          class="form-control"
          (input)="
          orderHistoryTable.filterGlobal($event.target.value, 'contains')
          "
          (ngModelChange)="dt.first=0"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="text-center">Actions</th>
      <th [pSortableColumn]="'thirdPartyOrderId'" pResizableColumn>
        Order ID
        <p-sortIcon [field]="'thirdPartyOrderId'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'eventType'" pResizableColumn>
        Event Type
        <p-sortIcon [field]="'eventType'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'dateInserted'" pResizableColumn>
        Inserted Date
        <p-sortIcon [field]="'dateInserted'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-historyItem>
    <tr>
      <td class="text-center">
        <span class="text-cell-primary btn-link" *ngIf="!isDocumentType(historyItem.eventType)"
          ><a (click)="onViewDetailsClicked(historyItem)" >View Detail</a></span
        >
      </td>
      <td>
        <span class="text-cell-primary">{{ historyItem.thirdPartyOrderId }}</span>
      </td>
      <td>
        <div class="row">
          <span class="text-muted col">{{ getEventTypeName(historyItem.eventType) }}</span>
          <span *ngIf="isDocumentType(historyItem.eventType)" class="col ps-2">
            <span>
              <a (click)="downloadDocument(historyItem.documentEventData)" class="mt-2">
                <span class="dark-fa-color mx-1">
                  {{ historyItem.documentEventData.fileName }}
                </span>
              </a>

              <a
                (click)="viewDocument(historyItem.documentEventData)"
                title="View"
                class="mx-2 align-text-bottom"
              >
                <i class="far fa-eye"></i>
              </a>
              <a
                title="Download"
                class="align-text-bottom"
                (click)="downloadDocument(historyItem.documentEventData)"
              >
                <i class="fas fa-download"></i>
              </a>
            </span>
            <br>
            <span>
              Third Party Doc ID: {{ historyItem.documentEventData.thirdPartyDocumentId }}
            </span>
          </span>
        </div>
      </td>
      <td>
        <span class="text-muted">{{
          historyItem.dateInserted | date: 'short'
        }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center">No history items found</td>
    </tr>
  </ng-template>
</p-table>

<ng-template #loading>
  <loading-indicator
    [loadingMessage]="'Loading history...'"
  ></loading-indicator>
</ng-template>

<drawer [title]="'Title History Detail'" [name]="'viewTitleHistoryDetailDrawer'" [templateRef]="viewTitleHistoryDetailContentRef"
  [options]="viewTitleHistoryDetailDrawerOptions">
</drawer>
<ng-template #viewTitleHistoryDetailContentRef>
  <div style="padding: 20px">
   <title-history-detail [allHistories]="histories" [history]="selectedHistory"></title-history-detail>
  </div>
</ng-template>
