<ng-template [ngIf]="loading" [ngIfElse]="content">
  <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
</ng-template>
<ng-template #content>
  <h5 style="text-align: center;" *ngIf="loanStatusType === 'notAssociated'">
    This application is not linked with LOS yet.
  </h5>
  <h5 style="text-align: center;" *ngIf="loanStatusType === 'referenced'">
    This application is referencing LOS loan <b>{{application.refNumber}}</b>.
  </h5>
  <h5 style="text-align: center;" *ngIf="loanStatusType === 'linked'">
    This application is linked to loan <b>{{application.refNumber ? application.refNumber : "[Ln# not specified]"}}</b>
    via <b>{{selectedLosVendor}}</b>.
    <br />
    <br />
    Sync direction: <b>{{losSyncDirection}}</b>.
  </h5>

  <div style="text-align: center;">
    <button type="button" class="btn btn-link" (click)="showTransactionHistory()" [disabled]="areButtonsDisabled()">
      Show transaction history
    </button>
  </div>

  <br>

  <div class="button-group" style="text-align: center;">
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()"
      (click)="referenceLosLoan(false)" *ngIf="loanStatusType === 'notAssociated'">
      Reference LOS Loan
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()" (click)="linkLosLoan()"
      *ngIf="losEnabled === true && loanStatusType === 'notAssociated'">
      Link LOS Loan
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()"
      (click)="createLosLoan()" *ngIf="losEnabled === true && loanStatusType === 'notAssociated'">
      Create LOS Loan
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()"
      (click)="referenceLosLoan(true)" *ngIf="loanStatusType === 'referenced'">
      Edit LOS reference
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1"
      [disabled]="areButtonsDisabled() || application.losSyncDir === 'None'" (click)="pullFromLos()"
      *ngIf="loanStatusType === 'linked'">
      <span *ngIf="pullingFromLos" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Pull from LOS
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1"
      [disabled]="areButtonsDisabled() || application.losSyncDir === 'None' || application.losSyncDir === 'LosToLoda'"
      (click)="pushToLos()" *ngIf="loanStatusType === 'linked'">
      <span *ngIf="pushingToLos" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Push to LOS
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1"
      [hidden]="!transactionHistory?.selectiveUpdateEnabled"
      (click)="partialPushToLos()" *ngIf="loanStatusType === 'linked'">
      <span *ngIf="pushingToLos" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Partial Push to LOS
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" (click)="syncDocuments()"
      *ngIf="loanStatusType === 'linked'" [disabled]="areButtonsDisabled()">
      Sync Documents
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()" (click)="unlinkLos()"
      *ngIf="loanStatusType === 'linked'">
      <span *ngIf="unlinkingFromLos" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Unlink LOS Loan
    </button>
    <button type="button" class="text-nowrap btn btn-secondary ms-3 mx-1" (click)="changeSyncDirection()"
      *ngIf="loanStatusType === 'linked'" [disabled]="areButtonsDisabled()">
      Change Sync Direction
    </button>
  </div>
</ng-template>