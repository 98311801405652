<div class="row style-11">
  <div class="d-grid style-12">
    <span class="text-center fw-bold fs-3 style-13" style="color: #0fc4f1;"> Proposed Refinance Benefits for
      {{borrowerName}}
    </span>
    <span class="text-center style-14" style="font-size: x-small;">
      Your actual rate, payment, and cost could be higher. Obtain an official Loan Estimate before choosing a loan
      option.
    </span>
  </div>
</div>

<div class="row mt-3 style-15" style="background-color: #f2f2f2;">
  <div class="col-md-6 style-16">
    <div class="d-grid style-17" style="border-right: 1px solid;">
      <span class="text-center fs-4 style-18"> {{this.currentParams.totalHousing | currency}} </span>
      <span class="text-center fs-4 style-19"> Your Current Total Payments </span>
    </div>

  </div>
  <div class="col-md-6 style-20">
    <div class="d-grid style-21">
      <span class="text-center fs-4 fw-bold style-22" style="color: #0fc4f1;"> {{this.proposedParams.totalHousing |
        currency}}
      </span>
      <span class="text-center fs-4 fw-bold style-23" style="color: #0fc4f1;"> Your new proposed payment </span>
    </div>
  </div>
</div>

<div class="row mt-4 style-24" style="border: 1px solid;">
  <div class="col-md-12 style-25" style="background-color: #f2f2f2; border-bottom: 1px solid;">
    <div class="text-center style-26">
      <span class="fs-4 fw-bold style-27" style="color: #0fc4f1;"> Proposed Refinance Solution: Conv Fixed 30 Year
      </span>
    </div>
  </div>
  <div class="col-md-12 style-28">
    <table class="table mb-0 style-29">
      <thead class="style-30">
        <tr class="style-31">
          <th class="table-header style-32"> </th>
          <th class="table-header style-33"> CURRENT </th>
          <th class="table-header fw-bold style-34" style="color: #0fc4f1;"> PROPOSED </th>
        </tr>
      </thead>
      <tbody class="style-35">
        <tr class="style-36">
          <td class="style-37"> Term </td>
          <td class="style-38"> {{this.currentParams.term}} </td>
          <td class="style-39"> {{this.proposedParams.term}} </td>
        </tr>
        <tr class="style-40">
          <td class="style-41"> P & I </td>
          <td class="style-42"> {{this.currentParams.pi | currency}} </td>
          <td class="style-43"> {{this.proposedParams.pi | currency}} </td>
        </tr>
        <tr class="style-44">
          <td class="style-45"> Hazard </td>
          <td class="style-46"> {{this.currentParams.hazard | currency}} </td>
          <td class="style-47"> {{this.proposedParams.hazard | currency}} </td>
        </tr>
        <tr class="style-48">
          <td class="style-49"> Taxes </td>
          <td class="style-50"> {{this.currentParams.taxes | currency}} </td>
          <td class="style-51"> {{this.proposedParams.taxes | currency}} </td>
        </tr>
        <tr class="style-52">
          <td class="style-53"> P(MI) </td>
          <td class="style-54"> {{this.currentParams.pmi | currency}} </td>
          <td class="style-55"> {{this.proposedParams.pmi | currency}} </td>
        </tr>
        <tr class="style-56">
          <td class="style-57"> PITI </td>
          <td class="style-58"> {{this.currentParams.piTi | currency}} </td>
          <td class="style-59"> {{this.proposedParams.piTi | currency}} </td>
        </tr>
        <tr class="style-60">
          <td class="style-61"> HOA </td>
          <td class="style-62"> {{this.currentParams.hoa | currency}} </td>
          <td class="style-63"> {{this.proposedParams.hoa | currency}} </td>
        </tr>
        <tr class="style-64" style="border-top: 1px solid;">
          <td class="fw-bold style-65"> Total Mortgage Payment(s) </td>
          <td class="fw-bold style-66"> {{this.currentParams.totalHousing | currency}} </td>
          <td class="fw-bold style-67"> <span class="style-68" style="color: #0fc4f1;">
              {{this.proposedParams.totalHousing | currency}} </span>
          </td>
        </tr>
        <tr class="style-69">
          <td class="style-70"> Interest Rate </td>
          <td class="fw-bold style-71"> {{this.currentParams.interestRate | percent:'1.2-2'}} </td>
          <td class="fw-bold style-72"> {{this.proposedParams.interestRate | percent:'1.2-2'}} </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row mt-4 style-73" style="border: 1px solid;">
  <div class="col-md-12 style-74" style="background-color: #f2f2f2; border-bottom: 1px solid;">
    <div class="text-center style-75">
      <span class="fs-4 fw-bold style-76" style="color: #0fc4f1;"> Debts to be paid off </span>
    </div>
  </div>
  <div class="col-md-12 style-77">
    <table class="table mb-0 style-78">
      <thead class="style-79">
        <tr class="style-80">
          <th class="table-header fw-bold style-81"> Account </th>
          <th class="table-header fw-bold style-82"> Type </th>
          <th class="table-header fw-bold style-83"> Balance </th>
          <th class="table-header fw-bold style-84"> Monthly Payment </th>
        </tr>
      </thead>
      <tbody class="style-85">
        <tr class="style-86" *ngFor="let liability of liabilities">
          <td class="style-87"> {{liability.holderName}} </td>
          <td class="style-88"> {{liability.typeOfLiability}} </td>
          <td class="style-89"> {{liability.unpaidBalance | currency}} </td>
          <td class="style-90"> {{liability.monthlyPayment | currency}} </td>
        </tr>
        <tr class="style-101" style="border-top: 1px solid;">
          <td class="style-102"> Total Debt to be Paid Off </td>
          <td class="style-103"> </td>
          <td class="style-104"> {{totalDebtToBePaidOffBalance | currency}} </td>
          <td class="style-105"> {{totalDebtToBePaidOffMonthly | currency}} </td>
        </tr>
        <tr class="style-106">
          <td class="fw-bold style-107"> New Monthly Payment </td>
          <td class="style-108"> </td>
          <td class="style-109"> </td>
          <td class="fw-bold style-110" style="color: #0fc4f1;"> {{newMonthlyPayment | currency}} </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row mt-4 style-111" style="background-color: #f2f2f2;padding: 25px;">
  <div class="col-md-6 style-112">
    <div class="d-grid mt-5 style-113">
      <span class="text-center fs-3 fw-bold style-114" style="color: #0fc4f1;"> {{monthlyResidualIncomeIncrease |
        currency}}
      </span>
      <span class="text-center fs-3 fw-bold style-115"> Savings Monthly </span>
    </div>
  </div>
  <div class="col-md-6 style-116">
    <div class="text-center style-117">
      <span class="style-118"> Savings based on difference between your current monthly
        payment and the new loan. </span>
    </div>
    <div class="d-grid mt-3 style-119">
      <span class="fw-bold fs-4 text-center style-120" style="color: #0fc4f1;"> {{savings[1].amount | currency}} </span>
      <span class="text-center style-121"> Savings in 1st Year </span>
    </div>
    <div class="d-grid mt-3 style-122">
      <span class="fw-bold fs-4 text-center style-123" style="color: #0fc4f1;"> {{savings[5].amount | currency}} </span>
      <span class="text-center style-124"> Savings in 5 Years </span>
    </div>
  </div>
</div>
<!-- <div class="row mt-4" style="background-color: #f2f2f2;">
  <div class="col-md-6">
    <div class="d-grid" style="margin-top: 4rem;">
      <span class="text-center fs-3 fw-bold" style="color: #0fc4f1;"> $187,000 </span>
      <span class="text-center fs-3 fw-bold"> Lifetime Interest Savings </span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="d-grid mt-3">
      <span class="fw-bold fs-4 text-center" style="color: #0fc4f1;"> $42,000 </span>
      <span class="text-center"> Interest Paid on Current Loan </span>
    </div>
    <div class="d-grid mt-3">
      <span class="fw-bold fs-4 text-center" style="color: #0fc4f1;"> $400,000 </span>
      <span class="text-center"> Interest Payment on Current Remaining Term </span>
      <span class="text-center"> (27 years) </span>
    </div>
    <div class="d-grid mt-3 mb-3">
      <span class="fw-bold fs-4 text-center" style="color: #0fc4f1;"> $213,000 </span>
      <span class="text-center"> Total Interest Payment on New Loan Term </span>
      <span class="text-center"> (30 years) </span>
    </div>
  </div>
</div> -->