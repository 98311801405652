import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {
  EmploymentTypeEnum,
  HousingExpense,
  Liability,
  MortgageBorrower,
  MortgageInsuranceDetail,
  PayoffType,
  ResidencyAddress,
  ResidencyBasis,
  ResidencyType,
} from 'src/app/models';
import {Constants} from 'src/app/services/constants';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {MortgageCalculatedStats, UrlaMortgage} from '../models/urla-mortgage.model';
import {PricingScenario} from '../../pricing/models/pricing/pricing-scenario.model';
import {sum} from 'src/app/shared/utils/math-utils';

@Injectable({
  providedIn: 'root'
})
export class MortgageCalculationService {

  reoLiabilityAssociationChanged = new Subject<void>();

  liabilitySubject = new Subject<any>();

  otherMortgagesChanged = new Subject<any>();

  private _loanPurposePurchaseEnumValue: string;
  private _loanPurposeConstructionToPermanentEnumValue: string;
  private _loanPurposeConstructionOnlyEnumValue: string;
  private _loanPurposeRefiEnumValue: string;
  private _fhaMortgageTypeValue: string;
  private _vaMortgageTypeValue: string;

  constructor(private readonly _enumsService: EnumerationService) {
  }

  calculateMortgageStatistics = (mortgage: UrlaMortgage) => {
    if (!mortgage.calculatedStats) {
      mortgage.calculatedStats = new MortgageCalculatedStats();
    }

    const lenderCreditEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit);
    this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
    this._loanPurposeRefiEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
    this._loanPurposeConstructionToPermanentEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.ConstructionToPermanent);
    this._loanPurposeConstructionOnlyEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.ConstructionOnly);
    this._fhaMortgageTypeValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.MortgageAppliedForType.FHA);
    this._vaMortgageTypeValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.MortgageAppliedForType.VA);

    const lenderCredit = mortgage.transactionDetail?.purchaseCredits.find(p => p.purchaseCreditType == lenderCreditEnumValue);
    if (lenderCredit) {
      mortgage.calculatedStats.lenderCredit = lenderCredit.purchaseCreditAmount;
    }
    mortgage.calculatedStats.emd = this.calculateEarnestMoneyDeposit(mortgage);
    mortgage.calculatedStats.totalPaidOffForRefinance = this.calculateTotalPayOffForRefinance(mortgage);
    mortgage.calculatedStats.landValue = this.calculateLandValueChange(mortgage);
    mortgage.calculatedStats.estimatedClosingCostsAmount = this.calculateEstimatedClosingCosts(mortgage);
    mortgage.calculatedStats.financialPartialPayoffTotalAmount = this.calculateFinancialPartialPayoffTotalAmount(mortgage);
    mortgage.calculatedStats.totalDue = this.calculateTotalDue(mortgage);
    mortgage.calculatedStats.totalLoanOrDrawAmount = this.calculateTotalLoanOrDrawAmount(mortgage);
    mortgage.calculatedStats.totalMortgageLoans = this.calculateTotalMortgage(mortgage);
    mortgage.calculatedStats.totalOtherCredit = this.calculateTotalOtherCredit(mortgage);
    mortgage.calculatedStats.totalCredit = this.calculateTotalCredit(mortgage);
    mortgage.calculatedStats.totalMortgageLoansAndCredits = this.calculateTotalMortgageLoansAndCredits(mortgage);
    mortgage.calculatedStats.totalDueFromBorrowers = this.calculateTotalDueFromBorrowers(mortgage);
    mortgage.calculatedStats.cashFromOrToTheBorrower = this.calculateCashFromOrToTheBorrower(mortgage);
    mortgage.calculatedStats.proposedMonthlyPaymentTotal = this.calculateHousingExpenseTotal(mortgage.proposedHousingExpense);
    mortgage.calculatedStats.currentMonthlyPaymentTotal = this.calculateHousingExpenseTotal(mortgage.currentHousingExpense);
    mortgage.calculatedStats.sourceOfFunds = this.calculateSourceOfFundsValue(mortgage);
  }

  calculateOtherPaidOffsTotal = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    mortgage.realEstateOwned?.forEach(reo => {
      if (reo.isSubjectProperty) {
        let filterReoOfLiabilities = mortgage.liabilities?.filter(l => l.reoId !== reo.reoId) || [];
        filterReoOfLiabilities.forEach(liability => {
          if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
            subTotal += Number(liability.unpaidBalance);
          }
          else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
            subTotal += Number(liability.partialPayoffAmount);
          }
        });
      }
    });
    return subTotal;
  }

  calculateLiabilitySubTotal = (mortgage: UrlaMortgage): {
    monthlyPaymentSubTotal: number;
    unpaidBalanceSubTotal: number;
    partialPayoffAmount: number;
    omittedAmount: number;
  } => (mortgage.liabilities ?? [])?.reduce((acc, current) => {
    if (current.isExcluded) {
      acc.omittedAmount += Number(current.unpaidBalance) || 0;
    } else {
      if (current.payoffType !== this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
        acc.monthlyPaymentSubTotal += Number(current.monthlyPayment) || 0;
        acc.partialPayoffAmount += Number(current.partialPayoffAmount) || 0;
      } else {
        acc.partialPayoffAmount += Number(current.unpaidBalance) || 0;
      }

      acc.unpaidBalanceSubTotal += Number(current.unpaidBalance) || 0;
    }

    return acc;
  }, {
    monthlyPaymentSubTotal: 0,
    unpaidBalanceSubTotal: 0,
    partialPayoffAmount: 0,
    omittedAmount: 0,
  });

  calculateOtherLiensOnSubjectProperty = (mortgage: UrlaMortgage): number => {
    const subjectPropertyReo = mortgage.realEstateOwned.find(reo => reo.isSubjectProperty);
    if (subjectPropertyReo) {
      const mortgageLiabilityTypes = [
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan),
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC)
      ];

      const liabilitiesLinkedToSubjectPropertyReo = subjectPropertyReo.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );

      let mortgageLiabilities = mortgage.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        l.reoId === subjectPropertyReo.reoId &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );
      mortgageLiabilities = mortgageLiabilities.concat(liabilitiesLinkedToSubjectPropertyReo);

      const unpaidBalanceOfMortgageLiabilities = mortgageLiabilities
        .map(l => l.unpaidBalance || 0)
        .reduce((accumulator, current) => accumulator + current, 0);

      return unpaidBalanceOfMortgageLiabilities
    }
    return 0;
  }

  calculateTotalMonthlyPaymentAndTotalLiensForOtherMortgages = (mortgage: UrlaMortgage): MortgageLienAndMonthlyPaymentAmount => {
    const subjectPropertyReo = mortgage.realEstateOwned.find(reo => reo.isSubjectProperty);
    const result: MortgageLienAndMonthlyPaymentAmount = {
      monthlyPayment: 0,
      lienAmount: 0
    }
    let unpaidBalanceOfMortgageLiabilities = 0;
    let monthlyPaymentTotalForMortgageLiabilities = 0;
    if (subjectPropertyReo) {
      const mortgageLiabilityTypes = [
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan),
        this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC)
      ];

      const liabilitiesLinkedToSubjectPropertyReo = subjectPropertyReo.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );

      let mortgageLiabilities = mortgage.liabilities.filter(l =>
        mortgageLiabilityTypes.includes(l.typeOfLiability) &&
        l.reoId === subjectPropertyReo.reoId &&
        !l.isExcluded &&
        l.payoffType !== PayoffType.Full
      );

      mortgageLiabilities = mortgageLiabilities.concat(liabilitiesLinkedToSubjectPropertyReo);

      unpaidBalanceOfMortgageLiabilities = mortgageLiabilities
        .map(l => l.unpaidBalance || 0)
        .reduce((accumulator, current) => accumulator + current, 0);

      monthlyPaymentTotalForMortgageLiabilities = mortgageLiabilities
        .map(l => l.monthlyPayment || 0)
        .reduce((accumulator, current) => accumulator + current, 0);
    }

    const relatedMortgagesTotalUnpaidBalance = mortgage.relatedMortgages
      .filter(l => !l.willBeModifiedIntoSubjectLoan && !l.willBeRefinancedIntoSubjectLoan)
      .map(l => l.loanOrDrawAmount || 0)
      .reduce((accumulator, current) => accumulator + current, 0);

    const relatedMortgagesMonthlyPaymentTotal = mortgage.relatedMortgages
      .filter(l => !l.willBeModifiedIntoSubjectLoan && !l.willBeRefinancedIntoSubjectLoan)
      .map(l => l.monthlyPayment || 0)
      .reduce((accumulator, current) => accumulator + current, 0);

    result.monthlyPayment = monthlyPaymentTotalForMortgageLiabilities + relatedMortgagesMonthlyPaymentTotal;
    result.lienAmount = unpaidBalanceOfMortgageLiabilities + relatedMortgagesTotalUnpaidBalance;

    return result;
  }

  calculateTotalPayOffForRefinance = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    if (this.isPurposeOfLoanRefinance(mortgage)) {
      mortgage.realEstateOwned?.forEach(reo => {
        if (reo.isSubjectProperty) {
          reo.liabilities?.filter(l => !l.isExcluded)?.forEach(liability => {
            if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
              subTotal += Number((liability.unpaidBalance || 0));
            }
            else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
              subTotal += Number((liability.partialPayoffAmount || 0));
            }
          });

          mortgage.liabilities?.filter(l => l.reoId == reo.reoId && !l.isExcluded)?.forEach(liability => {
            if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
              subTotal += Number((liability.unpaidBalance || 0));
            }
            else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
              subTotal += Number((liability.partialPayoffAmount || 0));
            }
          });
        }
      });
    }
    return subTotal;
  }

  calculateFinancialPartialPayoffTotalAmount = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let subjProp = mortgage.realEstateOwned?.find(reo => reo.isSubjectProperty);
    let filterReoOfLiabilities = mortgage.liabilities?.filter(l => !l.reoId || (subjProp && l.reoId !== subjProp.reoId));
    filterReoOfLiabilities?.forEach(liability => {
      if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
        subTotal += Number((liability.unpaidBalance || 0));
      }
      else if (liability.payoffType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial)) {
        subTotal += Number((liability.partialPayoffAmount || 0));
      }
    });
    return subTotal;
  }

  calculateLandValueChange = (mortgage: UrlaMortgage) => {
    if (!mortgage || !mortgage.subjectProperty)
      return 0;
    let landValue = 0;
    if (mortgage.subjectProperty.landValueType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Appraised)) {
      landValue = Number(mortgage.subjectProperty.lotAppraisedValue);
    } else if (mortgage.subjectProperty.landValueType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Original)) {
      landValue = Number(mortgage.subjectProperty.lotOriginalCost);
    } else {
      landValue = 0;
    }
    return landValue;
  }

  calculateEstimatedClosingCosts = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    if (mortgage.transactionDetail?.prepaidItemsEstimatedAmount) {
      subTotal += Number(mortgage.transactionDetail.prepaidItemsEstimatedAmount);
    }
    if (mortgage.transactionDetail?.prepaidEscrowsTotalAmount) {
      subTotal += Number(mortgage.transactionDetail.prepaidEscrowsTotalAmount);
    }
    if (mortgage.transactionDetail?.estimatedClosingCostsExcludingPrepaidsAmount) {
      subTotal += Number(mortgage.transactionDetail.estimatedClosingCostsExcludingPrepaidsAmount);
    }
    if (mortgage.mortgageInsuranceDetail?.miOrFundingFeeTotalAmount) {
      subTotal += Number(mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount);
    }

    return subTotal;
  }

  calculateTotalDue = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    if (mortgage.transactionDetail?.purchasePriceAmount) {
      subTotal += Number(mortgage.transactionDetail.purchasePriceAmount);
    }
    if (mortgage.transactionDetail?.alterationsImprovementsAndRepairsAmount) {
      subTotal += Number(mortgage.transactionDetail.alterationsImprovementsAndRepairsAmount);
    }

    if (mortgage.calculatedStats?.landValue) {
      subTotal += Number(mortgage.calculatedStats.landValue);
    }
    if (mortgage.calculatedStats?.financialPartialPayoffTotalAmount) {
      subTotal += Number(mortgage.calculatedStats.financialPartialPayoffTotalAmount);
    }
    if (mortgage.calculatedStats?.estimatedClosingCostsAmount) {
      subTotal += Number(mortgage.calculatedStats.estimatedClosingCostsAmount);
    }
    if (mortgage.transactionDetail?.borrowerPaidDiscountPointsTotalAmount) {
      subTotal += Number(mortgage.transactionDetail.borrowerPaidDiscountPointsTotalAmount);
    }
    if (mortgage.calculatedStats?.totalPaidOffForRefinance) {
      subTotal += Number(mortgage.calculatedStats.totalPaidOffForRefinance);
    }

    return subTotal;
  }

  calculateTotalLoanOrDrawAmount = (mortgage: UrlaMortgage) => {
    let total = 0;
    mortgage.relatedMortgages?.forEach(relatedMortgage => {
      if (relatedMortgage.loanOrDrawAmount && !relatedMortgage.willBeRefinancedIntoSubjectLoan && !relatedMortgage.willBeModifiedIntoSubjectLoan) {
        total += Number(relatedMortgage.loanOrDrawAmount);
      }
    });
    return total;
  }

  calculateLtv = (
    mortgage: UrlaMortgage,
    newValues?: {
      newLoanAmount: number,
      newPresentValue: number,
      newPurchasePriceAmount: number,
      newProductType: string,
      newMiAndFundingFee: number
    }
  ): number => {
    let newLoanAmount: number;
    let newPresentValue: number;
    let newPurchasePriceAmount: number;
    let newProductType: string;
    let newMiAndFundingFee: number;

    if (newValues) {
      newLoanAmount = newValues.newLoanAmount;
      newPresentValue = newValues.newPresentValue;
      newPurchasePriceAmount = newValues.newPurchasePriceAmount;
      newProductType = newValues.newProductType;
      newMiAndFundingFee = newValues.newMiAndFundingFee;
    }

    const miOrFundingFeeFinancedAmount = Math.floor(newMiAndFundingFee ?? mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0);
    const totalLoanAmount = newLoanAmount ?? (mortgage.mortgageTerm.totalLoanAmount || (mortgage.mortgageTerm.amount ?? 0 + miOrFundingFeeFinancedAmount));
    let presentValue = newPresentValue ?? (mortgage.subjectProperty.presentValue ?? 0);
    const purchasePriceAmount = newPurchasePriceAmount ?? mortgage.transactionDetail.purchasePriceAmount;

    if (!purchasePriceAmount) { // for Refinance
      if (presentValue == 0) {
        return 0
      } else {
        return totalLoanAmount / presentValue;
      }
    } else { // for Purchase
      if (
        purchasePriceAmount &&
        purchasePriceAmount > 0 &&
        !(presentValue && presentValue != 0 && purchasePriceAmount > presentValue)
      ) {
        presentValue = purchasePriceAmount;
      }

      if (presentValue == 0) {
        return 0;
      } else {
        return totalLoanAmount / presentValue;
      }
    }
  }

  calculateCltv = (
    mortgage: UrlaMortgage,
    newValues?: {
      newLoanAmount: number,
      newPresentValue: number,
      newPurchasePriceAmount: number,
      newProductType: string,
      newMiAndFundingFee: number
    }
  ): number => {
    let newLoanAmount: number;
    let newPresentValue: number;
    let newPurchasePriceAmount: number;
    let newProductType: string;
    let newMiAndFundingFee: number;

    if (newValues) {
      newLoanAmount = newValues.newLoanAmount;
      newPresentValue = newValues.newPresentValue;
      newPurchasePriceAmount = newValues.newPurchasePriceAmount;
      newProductType = newValues.newProductType;
      newMiAndFundingFee = newValues.newMiAndFundingFee;
    }

    const sub = mortgage.transactionDetail.subordinateLienAmount ?? 0;

    const miOrFundingFeeFinancedAmount = Math.floor(newMiAndFundingFee ?? mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0);
    const totalLoanAmount = newLoanAmount ?? (mortgage.mortgageTerm.totalLoanAmount || (mortgage.mortgageTerm.amount ?? 0 + miOrFundingFeeFinancedAmount));
    let presentValue = newPresentValue ?? (mortgage.subjectProperty.presentValue ?? 0);
    const purchasePriceAmount = newPurchasePriceAmount ?? mortgage.transactionDetail.purchasePriceAmount;

    if (!purchasePriceAmount) { // for Refinance
      if (presentValue == 0) {
        return 0
      } else {
        return (totalLoanAmount + sub) / presentValue;
      }
    } else { // for Purchase
      if (
        purchasePriceAmount &&
        purchasePriceAmount > 0 &&
        !(presentValue && presentValue != 0 && purchasePriceAmount > presentValue)
      ) {
        presentValue = purchasePriceAmount;
      }

      if (presentValue == 0) {
        return 0
      } else {
        return (totalLoanAmount + sub) / presentValue;
      }
    }
  }

  calculateLtvAndCltv = (
    mortgage: UrlaMortgage,
    newValues?: {
      newLoanAmount: number,
      newPresentValue: number,
      newPurchasePriceAmount: number,
      newProductType: string,
      newMiAndFundingFee: number
    }
  ): { ltv: number, cltv: number } => {
    const ltv = this.calculateLtv(mortgage, newValues);
    const cltv = this.calculateCltv(mortgage, newValues);

    return { ltv, cltv };
  }

  calculateTotalMortgage = (mortgage: UrlaMortgage) => {
    const getFinancedAmount = (insuranceDetail: MortgageInsuranceDetail | undefined) => {
      if (!insuranceDetail) {
        return 0;
      }

      return insuranceDetail.financeEntireMiOrFundingFee
        ? Math.floor(insuranceDetail.miOrFundingFeeTotalAmount || 0)
        : insuranceDetail.miOrFundingFeeFinancedAmount;
    };

    return sum(
      mortgage.mortgageTerm.amount,
      getFinancedAmount(mortgage.mortgageInsuranceDetail),
      mortgage.calculatedStats?.totalLoanOrDrawAmount,
    );
  }

  calculateTotalLenderCredit = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let purchaseCredits = mortgage.transactionDetail?.purchaseCredits.filter(p => p.purchaseCreditType === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit)
    );
    purchaseCredits?.forEach(purchaseCredit => {
      if (purchaseCredit?.purchaseCreditAmount) {
        subTotal += Number(purchaseCredit.purchaseCreditAmount);
      }
    });
    return subTotal;
  }

  calculateEarnestMoneyDeposit = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let emdAssets = mortgage.assets?.filter(p => p.assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.EarnestMoneyCashDepositTowardPurchase)
    );
    emdAssets?.forEach(purchaseCredit => {
      if (purchaseCredit?.cashMarketValue) {
        subTotal += Number(purchaseCredit.cashMarketValue);
      }
    });
    return subTotal;
  }

  calculateTotalOtherCredit = (mortgage: UrlaMortgage) => {
    let subTotal = 0;
    let purchaseCredits = mortgage.transactionDetail?.purchaseCredits.filter(p =>
      p.purchaseCreditType !== this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit)
      && p.purchaseCreditType !== this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SellerCredit)
    );
    purchaseCredits?.forEach(purchaseCredit => {
      if (purchaseCredit?.purchaseCreditAmount) {
        subTotal += Number(purchaseCredit.purchaseCreditAmount);
      }
    });
    return subTotal;
  }

  calculateTotalCredit = (mortgage: UrlaMortgage) => {
    let sellerPaidClosingCostsAmount = 0;
    if (mortgage.transactionDetail?.sellerPaidClosingCostsAmount) {
      sellerPaidClosingCostsAmount = Number(mortgage.transactionDetail.sellerPaidClosingCostsAmount);
    }
    return this.calculateTotalOtherCredit(mortgage) + this.calculateTotalLenderCredit(mortgage) + this.calculateEarnestMoneyDeposit(mortgage) + sellerPaidClosingCostsAmount;
  }

  calculateTotalDueFromBorrowers = (mortgage: UrlaMortgage) => {
    let totalDueFromBorrowers = (-1) * Number(mortgage.calculatedStats.totalMortgageLoans) +
      Number(mortgage.calculatedStats.totalCredit);
    return totalDueFromBorrowers;
  }

  calculateCashFromOrToTheBorrower = (mortgage: UrlaMortgage) => {
    let cashFromOrToTheBorrower = mortgage.calculatedStats.totalDue - mortgage.calculatedStats.totalMortgageLoansAndCredits;
    return cashFromOrToTheBorrower;
  }

  calculateTotalMortgageLoansAndCredits = (mortgage: UrlaMortgage) => {
    const totalMortgageLoansAndCredits = (mortgage.calculatedStats.totalMortgageLoans ?? 0) + (mortgage.calculatedStats.totalCredit ?? 0);
    return totalMortgageLoansAndCredits;
  }

  calculateHousingExpenseTotal = (proposedHousingExpense: HousingExpense) => {
    if (!proposedHousingExpense)
      return 0;
    let subProposedTotalValue = 0;
    subProposedTotalValue += Number(proposedHousingExpense.rent) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.firstMortgagePrincipalAndInterest) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.otherMortgageLoanPrincipalAndInterest) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.homeownersInsurance) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.supplementalPropertyInsurance) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.realEstateTax) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.mortgageInsurance) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees) || 0;
    subProposedTotalValue += Number(proposedHousingExpense.otherHousingExpense) || 0;
    return subProposedTotalValue;
  }

  calculateSourceOfFundsValue = (mortgage: UrlaMortgage) => {
    let subSourceOfFundsValue = 0;
    mortgage.transactionDetail?.purchaseCredits.forEach(purchaseCredit => {
      subSourceOfFundsValue += Number(purchaseCredit.purchaseCreditAmount);
    });
    return subSourceOfFundsValue;
  }

  calculateBorrowerTotalMonthlyIncome = (borrower: MortgageBorrower) => {
    const currentEmployments = borrower?.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    let totalEmploymentIncome: number = 0;
    currentEmployments?.forEach(e => {
      if (e.incomes.length) {
        let employmentIncome: number = e.incomes.map(i => i.monthlyIncome).reduce(this.sum, 0);
        totalEmploymentIncome += employmentIncome;
      }
    });
    let totalOtherIncome: number = borrower.nonEmploymentIncomes.map(i => i.monthlyIncome).reduce(this.sum,0);
    let totalMonthlyIncome = totalOtherIncome + totalEmploymentIncome;
    return totalMonthlyIncome;
  }

  calculateBorrowerMonthlyIncome = (borrower: MortgageBorrower) => {
    const currentEmployments = borrower?.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    let totalEmploymentIncome: number = 0;
    currentEmployments?.forEach(e => {
      if (e.incomes.length) {
        let employmentIncome: number = e.incomes.map(i => i.monthlyIncome).reduce(this.sum, 0);
        totalEmploymentIncome += employmentIncome;
      }
    });
    return totalEmploymentIncome;
  }

  calculateBorrowerMonthlyDebts = (mortgage: UrlaMortgage, filterTypeOfLiability?: string) => {
    let liabilities = mortgage?.liabilities.filter(e => e.isExcluded !== true && e.payoffType !== PayoffType.Full && e.monthlyPayment > 0);

    if (filterTypeOfLiability) {
      liabilities = liabilities?.filter(x => x.typeOfLiability == filterTypeOfLiability);
    }

    let totalExpense: number = 0;
    liabilities?.forEach(e => {
      totalExpense += (e.monthlyPayment ?? 0);
    });

    if (mortgage.subjectProperty.propertyWillBe != 'PrimaryResidence' && (!filterTypeOfLiability || filterTypeOfLiability == 'RentalExpense')) {
      const groupedAddresses: Array<ResidencyAddress> = [];

      mortgage.borrowers.forEach(b => {
        const borrowerRentAddress = b.residencyAddresses?.find(x => x.residencyType == ResidencyType.PresentAddress && x.residencyBasis === ResidencyBasis.Rent)
        if (borrowerRentAddress) {
          const existingAddresss = groupedAddresses.find(add =>
            add.address?.address1 === borrowerRentAddress.address?.address1 &&
            add.address?.city === borrowerRentAddress.address?.city &&
            add.address?.country === borrowerRentAddress.address?.country &&
            add.address?.state === borrowerRentAddress.address?.state &&
            add.address?.zipCode === borrowerRentAddress.address?.zipCode
          )

          if (!existingAddresss) {
            groupedAddresses.push(borrowerRentAddress);
          }
        }
      })

      totalExpense += groupedAddresses.reduce((pv, cv) => pv + (cv.rent ?? 0), 0);
    }

    if (!filterTypeOfLiability) {
      totalExpense += this.calculateOtherREOLiabilities(mortgage);
    }

    return totalExpense;
  }

  calculateDownPayment = (mortgage: UrlaMortgage) => {
    const isRefi = this.isPurposeOfLoanRefinance(mortgage);
    if (isRefi) {
      return;
    }
    const homeValue = this.calculateHomeValue(mortgage);
    const downPayment = (homeValue ?? 0) - (mortgage.mortgageTerm.amount ?? 0);
    return downPayment;
  }

  calculateHomeValue = (mortgage: UrlaMortgage) => {
    let minValue = this.minOfSalesPriceAndAppraisedValue(mortgage);
    if (!minValue) {
      minValue = 0;
    }
    return minValue;
  }

  calculateOtherREOLiabilities = (mortgage: UrlaMortgage) => {

    let totalExpense: number = 0;
    mortgage.realEstateOwned?.forEach(e => {
      if (
        !e.isSubjectProperty &&
        (
          e.includeTaxesInsuranceInDebtRatios ?? true
        ) &&
        (
          e.dispositionStatus == "Retained" ||
          e.dispositionStatus == "RetainForPrimaryOrSecondaryResidence" ||
          e.dispositionStatus == "RentalProperty"
        )
      ) {
        totalExpense += (e.monthlyMiscExpenses ?? 0);
      }
    });

    return totalExpense;
  }

  sendReoEvent() {
    this.reoLiabilityAssociationChanged.next();
  }

  sendLiabilityEvent(liability?) {
    this.liabilitySubject.next(liability ? liability : null);
  }

  publishOtherMortgagesChangedEvent() {
    this.otherMortgagesChanged.next(null);
  }

  getFtcSectionAmountsForScenario = (mortgage: UrlaMortgage, scenario: PricingScenario) => {

    this.calculateMortgageStatistics(mortgage);

    let ftcSectionAmounts = {
      A: mortgage.transactionDetail?.purchasePriceAmount ?? 0,
      B: mortgage.transactionDetail?.alterationsImprovementsAndRepairsAmount ?? 0,
      C: this.calculateLandValueChange(mortgage),
      D: this.calculateTotalPayOffForRefinance(mortgage),
      E: this.calculateFinancialPartialPayoffTotalAmount(mortgage),
      F: {
        estimated: mortgage.transactionDetail?.prepaidItemsEstimatedAmount ?? 0,
        escrow: mortgage.transactionDetail?.prepaidEscrowsTotalAmount ?? 0,
        estimatedClosingCosts: mortgage.transactionDetail?.estimatedClosingCostsExcludingPrepaidsAmount ?? 0,
        pmi: mortgage.mortgageInsuranceDetail ? mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount : 0,
        total: mortgage.transactionDetail?.estimatedClosingCostsAmount ?? 0,
      },
      G: mortgage.transactionDetail?.borrowerPaidDiscountPointsTotalAmount,
      H: 0,
      I: {
        nonFinancedAmount: mortgage.mortgageTerm?.amount,
        financedAmount: mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0,
        total: 0
      },
      J: this.calculateTotalLoanOrDrawAmount(mortgage),
      K: 0,
      L: mortgage.transactionDetail?.sellerPaidClosingCostsAmount,
      M: {
        lender: this.calculateTotalLenderCredit(mortgage),
        other: this.calculateTotalOtherCredit(mortgage)
      },
      N: this.calculateTotalCredit(mortgage),
      allTotal: mortgage.transactionDetail?.cashFromToBorrowerAmount ?? 0,
    };

    ftcSectionAmounts["I"]["total"] = (ftcSectionAmounts["I"]["nonFinancedAmount"] || 0) + (ftcSectionAmounts["I"]["financedAmount"] || 0);

    // total of A through G
    ftcSectionAmounts["H"] = (ftcSectionAmounts["A"] || 0) + (ftcSectionAmounts["B"] || 0) + (ftcSectionAmounts["C"] || 0) + (ftcSectionAmounts["D"] || 0)
      + (ftcSectionAmounts["E"] || 0) + (ftcSectionAmounts["F"]["total"] || 0) + (ftcSectionAmounts["G"] || 0);

    // total of I and J
    ftcSectionAmounts["K"] = (ftcSectionAmounts["I"]["total"] || 0) + (ftcSectionAmounts["J"] || 0);

    return ftcSectionAmounts;
  }

  getFtcSectionAmounts = (mortgage: UrlaMortgage) => {

    this.calculateMortgageStatistics(mortgage);

    let ftcSectionAmounts = {
      A: mortgage.transactionDetail?.purchasePriceAmount ?? 0,
      B: mortgage.transactionDetail?.alterationsImprovementsAndRepairsAmount ?? 0,
      C: this.calculateLandValueChange(mortgage),
      D: this.calculateTotalPayOffForRefinance(mortgage),
      E: this.calculateFinancialPartialPayoffTotalAmount(mortgage),
      F: {
        estimated: mortgage.transactionDetail?.prepaidItemsEstimatedAmount ?? 0,
        escrow: mortgage.transactionDetail?.prepaidEscrowsTotalAmount ?? 0,
        estimatedClosingCosts: mortgage.transactionDetail?.estimatedClosingCostsExcludingPrepaidsAmount ?? 0,
        pmi: mortgage.mortgageInsuranceDetail ? mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount : 0,
        total: mortgage.transactionDetail?.estimatedClosingCostsAmount ?? 0,
      },
      G: mortgage.transactionDetail?.borrowerPaidDiscountPointsTotalAmount,
      H: 0,
      I: {
        nonFinancedAmount: mortgage.mortgageTerm?.amount,
        financedAmount: mortgage.mortgageInsuranceDetail?.miOrFundingFeeFinancedAmount ?? 0,
        total: 0
      },
      J: this.calculateTotalLoanOrDrawAmount(mortgage),
      K: 0,
      L: mortgage.transactionDetail?.sellerPaidClosingCostsAmount,
      M: {
        lender: this.calculateTotalLenderCredit(mortgage),
        other: this.calculateTotalOtherCredit(mortgage)
      },
      N: this.calculateTotalCredit(mortgage),
      allTotal: mortgage.calculatedStats.cashFromOrToTheBorrower ?? 0,
    };

    ftcSectionAmounts["I"]["total"] = (ftcSectionAmounts["I"]["nonFinancedAmount"] || 0) + (ftcSectionAmounts["I"]["financedAmount"] || 0);

    // total of A through G
    ftcSectionAmounts["H"] = (ftcSectionAmounts["A"] || 0) + (ftcSectionAmounts["B"] || 0) + (ftcSectionAmounts["C"] || 0) + (ftcSectionAmounts["D"] || 0)
      + (ftcSectionAmounts["E"] || 0) + (ftcSectionAmounts["F"]["total"] || 0) + (ftcSectionAmounts["G"] || 0);

    // total of I and J
    ftcSectionAmounts["K"] = (ftcSectionAmounts["I"]["total"] || 0) + (ftcSectionAmounts["J"] || 0);

    return ftcSectionAmounts;
  }

  getPiTiPaymentInfo = (mortgage: UrlaMortgage): { pi: number, ti: number, other: number } => {
    let paymentInfo: any = {
      pi: 0,
      ti: 0,
      other: 0
    };

    const proposedExpenses = mortgage.proposedHousingExpense ||
      (
        mortgage.borrowers && mortgage.borrowers[0] && mortgage.borrowers[0].currentHousingExpenses &&
          mortgage.borrowers[0].currentHousingExpenses.isCurrent === false ? mortgage.borrowers[0].currentHousingExpenses
          : undefined
      );

    if (proposedExpenses) {
      paymentInfo.pi = proposedExpenses.firstMortgagePrincipalAndInterest || 0;
      paymentInfo.ti = (proposedExpenses.homeownersInsurance || 0) + (proposedExpenses.realEstateTax || 0);
      paymentInfo.other =
        (proposedExpenses.otherMortgageLoanPrincipalAndInterest || 0) +
        (proposedExpenses.mortgageInsurance || 0) +
        (proposedExpenses.homeownersAssociationDuesAndCondominiumFees || 0) +
        (proposedExpenses.otherHousingExpense || 0) +
        (proposedExpenses.supplementalPropertyInsurance || 0) +
        (proposedExpenses.rent || 0);
    }

    return paymentInfo;
  }

  setMonthlyPaymentOptions = (liability: Liability) => {
    return [0.5, 1, 5].map(el => ({
      displayText: `${el}% of Unpaid Balance ($${parseFloat(((liability.unpaidBalance / 100) * el).toFixed(2))})`,
      value: el
    }));
  };

  setMonthlyPayment = (liability: Liability, percent: number) => {
    liability.monthlyPayment = parseFloat(((liability.unpaidBalance / 100) * percent).toFixed(2));
  }

  isPurposeOfLoanRefinance = (mortgage: UrlaMortgage): boolean => {
    return this.isLoanPurposeSet(mortgage) && mortgage.subjectProperty.purposeOfLoan == this._loanPurposeRefiEnumValue;
  }

  isPurposeOfLoanPurchase = (mortgage: UrlaMortgage): boolean => {
    return this.isLoanPurposeSet(mortgage) && mortgage.subjectProperty.purposeOfLoan == this._loanPurposePurchaseEnumValue;
  }

  isPurposeOfLoanConstructionToPerm = (mortgage: UrlaMortgage): boolean => {
    return this.isLoanPurposeSet(mortgage) && mortgage.subjectProperty.purposeOfLoan == this._loanPurposeConstructionToPermanentEnumValue;
  }

  isPurposeOfLoanConstructionOnly = (mortgage: UrlaMortgage): boolean => {
    return this.isLoanPurposeSet(mortgage) && mortgage.subjectProperty.purposeOfLoan == this._loanPurposeConstructionOnlyEnumValue;
  }

  isFhaLoan = (mortgage: UrlaMortgage): boolean => {
    return this.isMortgageTypeSet(mortgage) && mortgage.mortgageTerm.mortgageAppliedFor == this._fhaMortgageTypeValue;
  }

  isVaLoan = (mortgage: UrlaMortgage): boolean => {
    return this.isMortgageTypeSet(mortgage) && mortgage.mortgageTerm.mortgageAppliedFor == this._vaMortgageTypeValue;
  }

  private sum = (a: number, b: number): number => {
    if (!a) a = 0;
    if (!b) b = 0;
    return a + b;
  }

  private minOfSalesPriceAndAppraisedValue = (mortgage: UrlaMortgage): number => {
    let presentValue = mortgage.mortgageTerm?.appraisedValue ?? mortgage.subjectProperty?.presentValue ?? 0;
    let purchasePriceAmount = mortgage.transactionDetail?.purchasePriceAmount ?? 0;

    if (this.isPurposeOfLoanPurchase(mortgage)) {
      var salesPrice = purchasePriceAmount ? purchasePriceAmount : Number.MAX_VALUE;
      var appraisedValue = presentValue ? presentValue : Number.MAX_VALUE;
      var min = Math.min(salesPrice, appraisedValue);
      return min != Number.MAX_VALUE ? min : 0;
    } else {
      // Refinance
      return presentValue;
    }
  };

  private isLoanPurposeSet = (mortgage: UrlaMortgage): boolean => {
    return !!(mortgage.subjectProperty && mortgage.subjectProperty.purposeOfLoan);
  }

  private isMortgageTypeSet = (mortgage: UrlaMortgage): boolean => {
    return !!(mortgage.mortgageTerm && mortgage.mortgageTerm.mortgageAppliedFor);
  }
}

export class MortgageLienAndMonthlyPaymentAmount {
  lienAmount: number;
  monthlyPayment: number;
}
