import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanStatusHistoryItem } from 'src/app/models/loan/loan-status-history.model';

@Component({
  selector: 'loan-status-history-dialog',
  templateUrl: 'loan-status-history-dialog.component.html'
})

export class LoanStatusHistoryDialogComponent implements OnInit {

  @Input()
  set history(history: LoanStatusHistoryItem[]) {
    this._history = history;
    this._history.forEach(h => {
      const name = h.userProfile ? (h.userProfile.firstName + ' ' + h.userProfile.lastName) : '--';
      h['name'] = name;
    })
  }

  get history(): LoanStatusHistoryItem[] {
    return this._history;
  }

  private _history: LoanStatusHistoryItem[] = [];

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() { }
}
