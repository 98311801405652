<form #form='ngForm'>
  <div class='modal-header'>
    <h2 class='modal-title'>{{ title }}</h2>

    <button
      (click)='onClickCancel()'
      aria-label='Close'
      class='btn-close'
      type='button'
    ></button>
  </div>

  <div class='modal-body'>
    <ng-container *ngFor='let creditItem of creditItems; let i = index'>
      <div class='credit-item'>
        <ng-container *ngIf='creditItem.canSetCreditType'>
          <span
            *ngLet='id("credit-type-" + i) as id'
            class='form-group'
          >
            <label [for]='id'>Credit Type</label>

            <select
              #control='ngModel'
              (ngModelChange)='onChangeCreditType(creditItem, $event)'
              [class.is-invalid]='control.invalid && control.touched'
              [id]='id'
              [ngModel]='creditItem.creditType'
              class='form-select'
              name='creditType{{ i }}'
              required
            >
              <option
                *ngIf='creditItem.creditType === null'
                [value]='null'
                selected
              >
                Select Credit Type
              </option>

              <option
                *ngFor='let option of creditTypeOptions'
                [value]='option.value'
              >
                {{ option.name }}
              </option>
            </select>

            <span
              *ngIf='control.errors?.required'
              class='invalid-feedback'
            >
              Credit Type is required
            </span>
          </span>
        </ng-container>

        <span
          *ngLet='id("description-" + i) as id'
          class='form-group'
        >
            <label [for]='id'>Description</label>

            <input
              #control='ngModel'
              [(ngModel)]='creditItem.description'
              [attr.title]='creditItem.isDescriptionReadonly ? "No description required" : null'
              [class.is-invalid]='control.invalid && control.touched'
              [disabled]='creditItem.isDescriptionReadonly'
              [id]='id'
              [placeholder]='creditItem.isDescriptionReadonly ? "N/A" : "Enter description"'
              class='form-control'
              name='description{{ i }}'
              required
              type='text'
            />

            <span
              *ngIf='control.errors?.required'
              class='invalid-feedback'
            >
              Description is required
            </span>
          </span>

        <span
          *ngLet='id("amount-" + i) as id'
          class='form-group'
        >
          <label [for]='id'>Amount</label>

          <currency-input
            #control='ngModel'
            (ngModelChange)='onChangeAmount(creditItem, $event)'
            [allowNegative]='false'
            [id]='id'
            [isZeroValid]='creditItem.isZeroAmountValid'
            [ngModel]='creditItem.amount'
            [required]='true'
            name='amount{{ i }}'
            placeholder='$0.00'
          ></currency-input>
        </span>

        <button
          #pop='bs-popover'
          *ngIf='creditItem.canDelete'
          [outsideClick]='true'
          [popover]='confirmDeletePopover'
          aria-label='Delete'
          class='btn btn-outline-primary-alt btn-icon delete-credit-btn'
          title='Delete'
          type='button'
        >
          <i class='fa fa-trash-alt'></i>

          <ng-template #confirmDeletePopover>
            <div class='p-2 d-flex flex-column align-items-center gap-1'>
              <b>Are you sure?</b>

              <div class='d-flex gap-1'>
                <button
                  (click)='pop.hide()'
                  class='btn btn-secondary btn-sm'
                >
                  No
                </button>

                <button
                  (click)='onClickDeleteCredit(i); pop.hide()'
                  class='btn btn-danger btn-sm'
                >
                  Yes
                </button>
              </div>
            </div>
          </ng-template>
        </button>
      </div>

      <div
        *ngIf='creditItem.isLastInSection'
        class='h-divider'
      ></div>
    </ng-container>

    <div class='d-flex align-items-end justify-content-between'>
      <button
        (click)='onClickAddCredit()'
        class='btn btn-soft-primary btn-icon add-credit-btn'
        type='button'
      >
        <i class='fa fa-plus'></i>

        Add Credit
      </button>

      <span>
        <strong>Total: </strong>
        {{ totalAmount | currency }}
      </span>
    </div>
  </div>

  <div class='modal-footer gap-2'>
    <button
      (click)='onClickCancel()'
      aria-label='Cancel'
      class='btn btn-secondary'
      type='button'
    >
      {{ effectiveCancelButtonLabel }}
    </button>

    <button
      (click)='onClickConfirm()'
      aria-label='Ok'
      class='btn btn-primary icon-btn'
      type='button'
    >
      {{ effectiveOkButtonLabel }}
    </button>
  </div>
</form>
