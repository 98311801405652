import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { LoanStatusFlow } from '../../../../models';

@Component({
  selector: 'tree-sortable-loan-status',
  templateUrl: 'tree-sortable-loan-status.component.html',
  styleUrls: ['tree-sortable-loan-status.component.scss'],
})
export class TreeSortableLoanStatusComponent implements OnInit {
  @Input() status: LoanStatusFlow;
  @Input() options;
  @Output() removeFromTree = new EventEmitter<{
    statusId: number;
    parentStatusId: number;
  }>();

  optionsCopy: any;

  constructor() { }

  ngOnInit(): void {
    this.optionsCopy = _.cloneDeep(this.options);
    this.optionsCopy.group.put = this.status.level === 0;
  }
}
