import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LosVendor } from 'src/app/models';
import Swal from 'sweetalert2';
import { IOption, LosOptionModel } from '../../../models/los/los-option.model';
import { LosService } from '../../../services/los.service';
import { NotificationService } from '../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'los-create',
  templateUrl: 'los-loan-create.component.html',
  styles: [` .dropdown-spinner{
                position: absolute;
                right: 40px;
                top: 7px;
              }
          `]
})
export class LosLoanCreateComponent implements OnInit {
  @Input() applicationId;
  @Input() losVendors;

  isLoadingVendorData = false;
  isCreating = false;
  selectedCredentialId: number = null;
  createAllowed = true;

  losOptions: LosOptionModel;
  losTypeValue = {};

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _losService: LosService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (this.losVendors?.length === 1) {
      this.startWithOneVendor();
    }
  }

  changeVendor(credentialId) {
    this.selectedCredentialId = +credentialId || null;

    if (credentialId == null) {
      this.losOptions = undefined;
      this.createAllowed = false;
      return;
    }

    this.isLoadingVendorData = true;
    this._losService.getLosOptions(this.applicationId, +credentialId).subscribe({
      next: (response) => {
        this.losOptions = response;

        this.losOptions.options.forEach((option) => {
          if (option.name === 'loanTemplate') {
            this.formatLoanTemplates(option);
          }

          if (option.type === 'Enum') {
            const allowedValuesWithValues = option.allowedValues?.filter(val => val.value != null); // null option is considered as a parent (disabled)
            if (allowedValuesWithValues?.length === 1) {
              this.setAllowedValue(allowedValuesWithValues[0].value, option.name);
            } else {
              this.setCustomEnumValues(option, +credentialId);
            }
          }
        });

        this.isLoadingVendorData = false;
        this.createAllowed = true;
      },
      error: (err) => {
        this.isLoadingVendorData = false;
        this.createAllowed = false;
        this._notificationService.showError(
          err?.message || 'Unable to get data for selected provider.',
          'Error'
        );
      }
    });
  }

  selectAllowedValue(value: string, name: string) {
    this.setAllowedValue(value, name);

    const controlledOptions = this.losOptions.options?.filter(option => option.onSelectOption === name) || [];
    if (controlledOptions.length === 0) {
      return;
    }

    if (!this.losTypeValue[name]) {
      // reset values
      controlledOptions.forEach(option => {
        this.losTypeValue[option.name] = null;
      })

      return;
    }

    // reset values
    controlledOptions.forEach(option => {
      this.losTypeValue[option.name] = null;
    })

    this._getAllowedValueForOtherOption(controlledOptions);
  }

  setAllowedValue(value: string, name: string) {
    this.losTypeValue[name] = value ? value.trim() : null;
  }

  create() {
    if (!this.losOptions) {
      return;
    }

    this.isCreating = true;

    const requestBody = this._parseOptionsBeforeRequest();

    this._losService
      .create(this.applicationId, this.losOptions.useCredentialId, requestBody)
      .subscribe({
        next: (response) => {
          this.isCreating = false;
          this.activeModal.close(response);
          if (response?.losVendor === LosVendor.MortgageDirector) {
            Swal.fire('', 'Any edits you make will not be reflected in Mortgage Director.', 'info');
          }
        },
        error: (err) => {
          this.isCreating = false;
          this._notificationService.showError(
            err?.message || 'Error',
            'Error'
          );
        }
      });
  }

  private _getAllowedValueForOtherOption(controlledOptions: IOption[]) {
    this._spinnerService.show();

    const requestBody = this._parseOptionsBeforeRequest();
    this._losService.getOptionsBasedOnOtherOption(this.applicationId, this.losOptions.useCredentialId, requestBody)
      .subscribe({
        next: (response) => {
          controlledOptions.forEach(option => {
            option.allowedValues = response.options?.find(resOpt => resOpt.name === option.name)?.allowedValues || [];
          })
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'Unable to get other options.',
            'Error'
          );
        }
      }).add(() => {
        this._spinnerService.hide();
      });
  }

  private formatLoanTemplates(option: IOption) {
    let folderName = '';
    for (let i = 0; i < option.allowedValues.length; i++) {
      let newFolderName = option.allowedValues[i].display.substr(
        0,
        option.allowedValues[i].value.lastIndexOf('\\')
      );
      if (folderName !== newFolderName) {
        option.allowedValues.splice(i, 0, {
          display: newFolderName,
          value: null,
        });
        folderName = newFolderName;
      } else {
        option.allowedValues[i].display = option.allowedValues[
          i
        ].display.substr(
          option.allowedValues[i].value.lastIndexOf('\\') + 1
        );
      }
    }
  }

  private setCustomEnumValues(option: IOption, credentialId: number) {
    this.setAllowedValue(null, option.name);
  }

  private startWithOneVendor = () => {
    this.changeVendor(this.losVendors[0].credentialId);
  }

  private _parseOptionsBeforeRequest = () => {
    const data = [];

    this.losOptions.options.forEach((opt) => {
      data.push({
        name: opt.name,
        value: this.losTypeValue[opt.name]
          ? this.losTypeValue[opt.name]
          : opt.default,
      });
    });

    return data;
  }
}
