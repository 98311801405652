import { Component, Input, OnInit } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ThirdPartyCredential } from '../../../../../../models';

@Component({
    selector: 'delete-credentials',
    templateUrl: './delete-credentials.component.html'
})
export class DeleteCredentialsComponent implements OnInit {
    @Input() thirdPartyCredential: ThirdPartyCredential;
    @Input() additionalProperties: any;

    constructor() {
    }

    ngOnInit(): void { }

    deleteCredentialsConfirmation(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to remove credentials for ${this.thirdPartyCredential.credentialType} - ${this.thirdPartyCredential.vendorName}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result: SweetAlertResult) => {
            if (!result.value) {
                return;
            }
            this.clearCredential();
        });
    }

    clearCredential(): void {
        this.thirdPartyCredential.alias = '';
        this.thirdPartyCredential.url = '';
        this.thirdPartyCredential.userName = '';
        this.thirdPartyCredential.password = '';

        if (this.thirdPartyCredential.vendorName === 'LoanBeam') {
            this.thirdPartyCredential.thirdPartyKeyValuePairs = [
                { key: 'ApiKey', value: '', alias: '', userName: '', password: '' },
                { key: 'HmacKey', value: '', alias: '', userName: '', password: '' },
                { key: 'LodaDocTypes', value: '', alias: '', userName: '', password: '' },
                { key: 'LoanBeamDocTypes', value: '', alias: '', userName: '', password: '' },
            ];
            this.additionalProperties.apiKey.value = '';
            this.additionalProperties.hmacKey.value = '';
        } else {
            this.thirdPartyCredential.thirdPartyKeyValuePairs = [];
        }

        // checks for additional properties
        if (this.thirdPartyCredential.vendorName === 'DU') {
            this.additionalProperties.creditProviders = [];
            if (this.additionalProperties.defaultCreditProvider) {
                this.additionalProperties.defaultCreditProvider.value = '';
            }
            this.additionalProperties.institutions = [];
        }

        if (this.thirdPartyCredential.vendorName === 'FannieMae' &&
            this.thirdPartyCredential.credentialType === 'AUSOriginator') {
            this.additionalProperties.creditProviders = [];
            if (this.additionalProperties.defaultCreditProvider) {
                this.additionalProperties.defaultCreditProvider.value = '';
            }
        }

        if (this.thirdPartyCredential.vendorName === 'LPA') {
            this.additionalProperties.sellerServicerNumber.value = '';
            this.additionalProperties.lpaPassword.value = '';
            this.additionalProperties.fhaLenderId.value = '';
            this.additionalProperties.fhaSponsorId.value = '';
            this.additionalProperties.thirdPartyOriginatorId.value = '';
            this.additionalProperties.thirdPartyOriginatorIsCorrespondentLender.value = '0';
            this.additionalProperties.nonOriginatingThirdPartyId.value = '';
        }

        if (['Encompass', 'EncompassApi', 'OpenClose', 'MortgageDirector'].includes(this.thirdPartyCredential.vendorName)) {
            this.additionalProperties.splice(0, this.additionalProperties.length);
        }

        if (this.additionalProperties) {
            if (this.additionalProperties.technicalAffiliate) {
                this.additionalProperties.technicalAffiliate.value = null;
            }
            if (this.additionalProperties.interfaceIdentifiers) {
                this.additionalProperties.interfaceIdentifiers = [];
            }
        }
    }
}
