import { Component, Input, OnInit } from '@angular/core';
import { CustomData } from 'src/app/models/loan';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'loan-custom-data',
  templateUrl: './loan-custom-data.component.html',
  styleUrls: ['./loan-custom-data.component.scss']
})
export class LoanCustomDataComponent implements OnInit {

  @Input() applicationId: number;

  isSaving: boolean = false;
  customData: CustomData;

  constructor(private _loanService: LoanService,
    private _notifyService: NotificationService) { }

  ngOnInit(): void {

    this._loanService.getLoanCustomData(this.applicationId)
    .subscribe(customData => {
      this.customData = customData;
    },(err) => {
      this._notifyService.showError("Error encountered while loading custom data of the loan","Error!");
    });

  }

  save = () => {

    this.isSaving = true;

    let preparedData = this.prepareArrayFields(this.customData);

    this._loanService.saveLoanCustomData(this.applicationId, preparedData )
    .subscribe(customData => {
      this.customData = customData;
      this.isSaving = false;
      this._notifyService.showSuccess("Custom Data saved successfully.","Successfull!");

    }, (err) => {
      this.isSaving = false;
      this._notifyService.showError("Error encountered while saving custom data","Error!");
    })

  }

  prepareArrayFields = (customData: CustomData): CustomData => {
    if (Array.isArray(customData.customData1)) {
        customData.customData1 = JSON.stringify(customData.customData1)
    }
    if (Array.isArray(customData.customData2)) {
        customData.customData2 = JSON.stringify(customData.customData2)
    }
    if (Array.isArray(customData.customData3)) {
        customData.customData3 = JSON.stringify(customData.customData3)
    }
    if (Array.isArray(customData.customData4)) {
        customData.customData4 = JSON.stringify(customData.customData4)
    }
    if (Array.isArray(customData.customData5)) {
        customData.customData5 = JSON.stringify(customData.customData5)
    }
    if (Array.isArray(customData.customData6)) {
        customData.customData6 = JSON.stringify(customData.customData6)
    }
    if (Array.isArray(customData.customData7)) {
        customData.customData7 = JSON.stringify(customData.customData7)
    }
    if (Array.isArray(customData.customData8)) {
        customData.customData8 = JSON.stringify(customData.customData8)
    }
    if (Array.isArray(customData.customData9)) {
        customData.customData9 = JSON.stringify(customData.customData9)
    }
    if (Array.isArray(customData.customData10)) {
        customData.customData10 = JSON.stringify(customData.customData10)
    }
    return customData;
}

}
