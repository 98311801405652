import { Component, EventEmitter, Injector, Input, Output, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, Subscription } from 'rxjs';
import { CreditReport, CreditVendor, CreditVendorEnum, ThirdPartyCredential } from 'src/app/models';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { Constants } from 'src/app/services/constants';
import { MenuService } from 'src/app/services/menu.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { CreditBorrower } from '../../models/credit-borrower.model';
import * as _ from 'lodash';
import { CreditService } from '../../services/credit.service';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { AdminService } from 'src/app/services/admin.service';
import { ServicesPermissions } from '../../models/services-permissions.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImportLiability } from '../../models/import-liability.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContext } from 'src/app/models/application-context.model';
import { BorrowerType } from 'src/app/modules/leads/models/lead-credit.model';
import { Utils } from 'src/app/core/services/utils';
import { isEmpty } from 'lodash';
import { UrlaMortgage } from "../../../../urla/models/urla-mortgage.model";

@Component({
  selector: 'credit-reports',
  templateUrl: 'credit-reports.component.html',
  styleUrls: ['./credit-reports.component.scss']
})
export class CreditReportsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() leadId: number = null;
  @Input() creditVendor: CreditVendor = null;
  @Input() embedded: boolean = false;
  @Input() mortgage?: UrlaMortgage;

  @Output()
  creditRunCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  applicationId: number = null;

  isLoanReadOnly: boolean = false;

  tab: 'CreditRun' | 'CreditReportHistory' | 'Liabilities' = 'CreditRun';

  loanStatusType: string;

  isTpoUser: boolean;

  creditPermission: boolean;

  isServicesEnabled: boolean = true;

  creditInfoId: number;

  borrowers: CreditBorrower[];

  creditVendors: ThirdPartyCredential[];

  servicesPermissions: ServicesPermissions;

  loadingHistory: boolean = false;

  isLatestRunCalculationsFinished: boolean = false;

  creditHistory: CreditReport[] = [];

  liabilitiesHistory: ImportLiability[];

  permissionLoaded: boolean;

  companyId: number;

  latestPullCredit: CreditReport;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _menuService: MenuService,
    private readonly _adminService: AdminService,
    private readonly _leadsService: LeadsService,
    private readonly _creditService: CreditService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.applicationContext = context;
        this.getInitData(this.applicationContext);
      }
    });
  }

  ngOnInit() {
    this.companyId = this.applicationContext.userPermissions.companyId;

    if (this.leadId) {
      this.getInitData();
    } else if (this.applicationContext?.application?.applicationId) {
      this.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
      this.getInitData(this.applicationContext);

      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.isLoanReadOnly = context.applicationIsReadOnly;
          this.applicationContext = context;
          this.getInitData(this.applicationContext);
        }
      });
    }

    this.creditInfoId = null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  loadHistory = () => {
    this.loadingHistory = true;

    const result = !this.leadId ?
      this._creditService.getCreditReportHistory(true, this.applicationId) :
      this._creditService.getCreditReportHistory(false, this.leadId);

    result.subscribe({
      next: (result) => {
        this.creditHistory = this.leadId ? _.orderBy(result, ['creditRunDate', 'isJoint'], ['desc', 'desc']) : _.orderBy(result, ['date', 'creditID', 'joint'], ['desc', 'desc', 'desc']);

        this.latestPullCredit = this.creditHistory[0];

        if (this.borrowers != null) {
          if (this.leadId) {
            const borrower = this.borrowers.find(b => b.borrowerType === BorrowerType.PrimaryBorrower);
            const coBorrower = this.borrowers.find(b => b.borrowerType === BorrowerType.CoBorrower);

            this.creditHistory.forEach(history => {
              if (history.borrowerType === BorrowerType.PrimaryBorrower && borrower) {
                history.borrowerName = Utils.getPersonsDisplayName(borrower);
              }
              if (history.borrowerType === BorrowerType.CoBorrower && coBorrower) {
                history.borrowerName = Utils.getPersonsDisplayName(coBorrower);
              }
            })

            if (borrower) {
              // credit reports for borrower
              borrower.latestCreditReport = new CreditReport();
              const borrowerHistories = this.creditHistory.filter(history => history.borrowerType === borrower?.borrowerType);
              if (borrowerHistories.length > 0) {
                borrower.latestCreditReport = borrowerHistories[0];
              }
            }

            if (coBorrower) {
              // credit reports for coBorrower
              coBorrower.latestCreditReport = new CreditReport();
              const coBorrowerHistories = this.creditHistory.filter(history => history.borrowerType === coBorrower?.borrowerType);
              if (coBorrowerHistories.length > 0) {
                coBorrower.latestCreditReport = coBorrowerHistories[0];
              }
            }
          } else {
            this.borrowers.forEach((borrower) => {
              borrower.latestCreditReport = new CreditReport();
              const borrowerHistories = this.creditHistory.filter(history => history.borrowerId === borrower.borrowerId);
              if (borrowerHistories.length > 0) {
                borrower.latestCreditReport = borrowerHistories[0];
              }
            });
          }
          this.isLatestRunCalculationsFinished = true;
        }
        this.setStatusIcons();

        this.loadingHistory = false;
      },
      error: (error) => {
        this.loadingHistory = false;
        this._notifyService.showError(error?.message || "Couldn't load credit report history.", "Error!");
      }
    });
  }

  onCreditRunCompleted = (isSuccess: boolean) => {
    /*
    AY: The child already raises this after a successful run.
    if (!this.leadId) {
      this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
    }
    */

    this.creditRunCompleted.emit(isSuccess);
    /*
    AY: The child component already raises the event above, which eventually gets caught by this component and it refreshes itself and
    reloads history - so calling it again results in double refresh after run.
    this.loadHistory();
    */
  }

  onCreditRunSuccessfullyCompleted = () => {
    this._notifyService.showSuccess("Credit has been run successfully.", "Success!");
    const creditVendorsObservable = !this.leadId
      ? this._creditService.getCreditVendorsForLoan(this.applicationId)
      : this._creditService.getCreditVendorsForLead(this.leadId);

    creditVendorsObservable.subscribe({
      next: (creditVendors) => {
        this.creditVendors = creditVendors || [];
      },
      error: (err) => {
        this._notifyService.showError(err?.message || "Couldn't get credit vendors", "Error");
      }
    });
    if (this.leadId) {
      this.getInitData();
    }
  }

  onReissueSucceeded = (integrationHistoryId: number) => {
    const creditRun = this.creditHistory.find(history => history.integrationHistoryId === integrationHistoryId);
    if (creditRun) {
      this.openTab('CreditRun');
    }
    if (!this.leadId)
      this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
    else
      this.loadHistory();
  }

  onImportLiabilitiesClicked = (creditInfoId: number) => {
    this.creditInfoId = creditInfoId;
    const creditInfo = this.creditHistory.find(cH => cH.creditID === creditInfoId);
    if (creditInfo) {
      this._spinner.show();
      this._creditService.getLiabilitiesHistory(this.creditInfoId).subscribe(result => {
        const keys = Object.keys(result.liabilitiesBySocialSecurityNumber);
        this.liabilitiesHistory = result.liabilitiesBySocialSecurityNumber;
        this._spinner.hide();
        if (keys && keys.length > 0) {
          this.openTab('Liabilities');
        }
        else {
          this._notifyService.showWarning("No liabilities found in credit report", 'Warning');
        }

      }, (err) => {
        this._spinner.hide();
        this._notifyService.showError(err ? err.error.message || err.message : '', 'Error');
      });
    }
  }

  openTab = (name: 'CreditRun' | 'CreditReportHistory' | 'Liabilities') => {
    if (name !== "Liabilities") {
      this.creditInfoId = null;
    }
    if (this.tab !== name) {
      this.tab = name;
    }
  };

  getLeadBorrowers = (): Observable<CreditBorrower[]> => {
    return this._leadsService.getLead(this.leadId)
      .pipe(map(lead => {

        let borrowers = [];

        if (lead.firstName) {
          borrowers.push({
            borrowerId: 0,
            firstName: lead.firstName,
            lastName: lead.lastName,
            last4Ssn: lead.socialSecurityNumber,
            birthDate: lead.birthDate,
            pull: true,
            borrowerType: BorrowerType.PrimaryBorrower
          });
        }

        if (lead.coFirstName) {
          borrowers.push({
            borrowerId: 1,
            firstName: lead.coFirstName,
            lastName: lead.coLastName,
            last4Ssn: lead.coSocialSecurityNumber,
            birthDate: lead.coBirthDate,
            pull: true,
            borrowerType: BorrowerType.CoBorrower
          });
        }

        return borrowers;

      }));
  }

  private getInitData = (context: ApplicationContext = null) => {
    if (this.leadId) {
      this.getInitDataForLead();
    } else {
      if (context) {
        const application = context.application;
        this.applicationId = application.applicationId;
        this.isTpoUser = context.isTpo;
        this.borrowers = context.borrowers as unknown as CreditBorrower[];
        this.borrowers.forEach(borr => {
          let mb = this.mortgage?.borrowers.find(x => x.contactId == borr.borrowerId);
          borr.jointWithMortgageBorrowerId = mb?.jointWithBorrowerId;
          borr.mortgageBorrowerId = mb?.borrowerId;
          borr.mortgageAppId = mb?.printApplicationIndex;
        });
      }

      this.getInitDataForLoan();
    }
  };

  private getInitDataForLead = () => {
    const combined = combineLatest([
      this.getLeadBorrowers(),
      this._creditService.getCreditVendorsForLead(this.leadId)
    ])

    this.loadingHistory = true;
    combined.subscribe({
      next: ([borrowers, creditVendors]) => {
        this.initialize(borrowers as CreditBorrower[], creditVendors);
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load data.", "Error!");
        this.loadingHistory = false;
      }
    });
  }

  private getInitDataForLoan = () => {
    this.loadingHistory = true;
    this._creditService.getCreditVendorsForLoan(this.applicationId).subscribe({
      next: (creditVendors) => {
        const creditBorrowers: CreditBorrower[] = this.applicationContext.borrowers as any[];
        this.initialize(creditBorrowers, creditVendors);
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load data.", "Error!");
        this.loadingHistory = false;
      }
    });
  }

  private initialize = (borrowers: CreditBorrower[], creditVendors: ThirdPartyCredential[]) => {
    this.borrowers = borrowers || [];
    this.creditVendors = creditVendors?.filter(vendor =>
      vendor.vendorName === CreditVendorEnum.MeridianLinkSoftPull ||
      vendor.vendorName === CreditVendorEnum.MeridianLinkHardPull ||
      vendor.vendorName === CreditVendorEnum.Xactus ||
      vendor.vendorName === CreditVendorEnum.CredCoSoftPull ||
      vendor.vendorName === CreditVendorEnum.CredCoHardPull
    ) || [];

    if (this.isTpoUser) {
      this.getPermissions();
    } else {
      this.setPermissionsForAdminUser();
    }
    this.loadHistory();
  }

  private getPermissions = () => {
    this._adminService.getTpoConfiguration()
      .subscribe({
        next: (result) => {
          this.servicesPermissions = {
            ausPermissions: result.ausPermissions,
            creditPermissions: result.creditPermissions,
            isVOAAllowed: result.isVOAAllowed,
            isVOIVOEAllowed: result.isVOIVOEAllowed,
            columnSelectorEnabled: result.columnSelectorEnabled,
            isPullEnabled: true,
            isLinkedToLos: this.loanStatusType === "linked"
          }

          this.isServicesEnabled = this.servicesPermissions.creditPermissions !== "0" ||
            this.servicesPermissions.ausPermissions !== "0" ||
            this.servicesPermissions.isVOAAllowed ||
            this.servicesPermissions.isVOIVOEAllowed
          this.permissionLoaded = true;
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'unable to load TPO configurations', 'Error');
          this.permissionLoaded = false;
        }
      });
  }

  private setPermissionsForAdminUser = () => {
    this.servicesPermissions = {
      ausPermissions: "1",
      creditPermissions: "2",
      isVOAAllowed: true,
      isVOIVOEAllowed: true,
      columnSelectorEnabled: true,
      isPullEnabled: true,
      isLinkedToLos: true
    }
    this.permissionLoaded = true;
  }

  private setStatusIcons = () => {
    const failedCredits = this.borrowers.filter(borrower => borrower.latestCreditReport && !isEmpty(borrower.latestCreditReport) && !borrower.latestCreditReport.successful);
    const noCreditHistory = this.borrowers.filter(borrower => !borrower.latestCreditReport || isEmpty(borrower.latestCreditReport));
    const successfulRunCredit = failedCredits.length == 0 && noCreditHistory.length == 0;
    const noCreditRecord = failedCredits.length == 0 && noCreditHistory.length > 0;

    let status = MenuItemStatus.Loading;

    if (failedCredits.length > 0) {
      status = MenuItemStatus.Error;
    } else if (noCreditRecord) {
      status = MenuItemStatus.Pending;
    } else if (successfulRunCredit) {
      status = MenuItemStatus.Success;
    }
    this._menuService.setStatus(Constants.menu.servicesMenuItems.credit, status);
  }
}
