<div class="inline-editor"
  class="form-group d-flex align-items-center"
  [style.display]="(editorMode === 'Inline' && !isEditActive && !(model && model.touched && model.invalid)) ? 'none !important' : ''"
>
  <span class="w-100">
    <textarea
      [(ngModel)]="value"
      #control
      #model="ngModel"
      name="{{ name }}"
      id="{{ name }}"
      rows="{{ rows }}"
      [required]="required"
      [readonly]="readonly"
      [disabled]="disabled"
      placeholder="{{ placeholder }}"
      class="form-control"
      (focus)="isEditActive = true"
      (blur)="onBlur($event)"
      [ngClass]="{
        'is-invalid': model && model.touched && model.invalid
      }"
      style="resize: none"
    >
    </textarea>
    <div class="invalid-feedback">This field is required</div>
  </span>

  <span *ngIf="editorMode == 'Inline'">
    <button
      class="btn p-0 border-0 bg-transparent ms-1"
      title="Apply"
      type="button"
      (mousedown)="onApplyClicked($event)"
    >
      <i class="fa fa-check-circle text-success"></i>
    </button>
    <button
      class="btn p-0 border-0 bg-transparent ms-1"
      title="Cancel"
      type="button"
      (mousedown)="onCancelClicked($event)"
    >
      <i class="fa fa-times-circle text-danger"></i>
    </button>
  </span>
</div>
<span
  [ngClass]="{
    'empty-field': value == null || value == undefined || value == ''
  }"
  class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}"
  title="Click to Edit"
  *ngIf="editorMode == 'Inline' && !isEditActive && !(model && model.touched && model.invalid)"
  (click)="onEditModeToggledOn()"
>
  <span
    class="val"
    *ngIf="(value != null && value != undefined) || value != ''"
    >{{ value }}</span
  >
  <span
    class="placeholder"
    *ngIf="value == null || value == undefined || value == ''"
    >--</span
  >
</span>

