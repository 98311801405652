export enum MessageStatusEnum {
  Unprocessed = "Unprocessed",
  InProcess = "InProcess",
  Paused = "Paused",
  FailureDeliveryNotEnabled = "FailureDeliveryNotEnabled",
  Failure = "Failure",
  SkippedDueToStatus = "SkippedDueToStatus",
  Success = "Success",
  CombinedChild = "CombinedChild"
}
