<correspondence-history-table
  *ngIf="history; else loading"
  [history]="history"
  [scrollOffsetInPixels]="scrollOffsetInPixels"
  (historyChanged)="refreshHistoryTable($event)"
></correspondence-history-table>

<ng-template #loading>
  <loading-indicator
    [loadingMessage]="'Loading messages...'"
  ></loading-indicator>
</ng-template>
