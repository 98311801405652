import { Component, Injector, Input, OnInit } from "@angular/core";
import { WireRequestModel } from "../../../models/wire-request.model";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { AddressLookupResult } from "src/app/models/address-lookup-result.model";
import { formViewProvider } from "src/app/core/services/form-view.provider";

@Component({
  templateUrl: "create-wire-request-address.component.html",
  selector: "create-wire-request-address",
  viewProviders: [formViewProvider]
})
export class CreateWireRequestAddress extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  wireRequest: WireRequestModel;

  states: EnumerationItem[];

  constructor(
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.applicationContextService.context.subscribe(context => {
      this.states = Object.keys(context.globalConfig.states).map(key => new EnumerationItem(context.globalConfig.states[key], key));
    });
  }

  onAddressChanged(address: AddressLookupResult) {
    this.wireRequest.address = address.address;
    this.wireRequest.city = address.city;
    this.wireRequest.state = address.state;
    this.wireRequest.zip = address.zipCode;
  }
}