import { Component, Input, OnInit } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LosPackageConditionReport } from '../../../request-models/loan-validation-response.model';

@Component({
  selector: 'los-package-condition-reports',
  templateUrl: './los-package-condition-reports.component.html',
  styleUrls: ['./los-package-condition-reports.component.scss']
})
export class LosPackageConditionReportsComponent implements OnInit {

  @Input() conditionReports: LosPackageConditionReport[];

  losPackageTypes: EnumerationItem[] = [
    new EnumerationItem("At Application", "AtApplication"),
    new EnumerationItem("Three Day", "ThreeDay"),
    new EnumerationItem("At Lock", "AtLock"),
    new EnumerationItem("Approval", "Approval"),
  ];

  constructor() { }

  ngOnInit(): void {
    this.conditionReports.forEach(r => {
      r["packageTypeName"] = this.losPackageTypes.find(t => t.value == r.packageType)?.name;
    })
  }

}
