import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoanDocsCardViewComponent } from './components/loan-docs-card-view.component';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
  ],
  declarations: [
    LoanDocsCardViewComponent,
  ],
  exports: [
    LoanDocsCardViewComponent
  ],
  providers: []
})
export class LoanDocsCardViewModule {
  constructor() { }
}
