import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BaseRequest } from 'src/app/modules/pricing/models/pricing/base-request.model';
import { CustomFieldSection } from 'src/app/modules/pricing/models/pricing/custom-field-section.model';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { NotificationService } from 'src/app/services/notification.service';
import { PricingCustomFieldSectionService } from 'src/app/services/pricing-custom-field-section.service';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'pricing-custom-field-sections',
  templateUrl: './pricing-custom-field-sections.component.html',
  styleUrls: ['./pricing-custom-field-sections.component.scss']
})
export class PricingCustomFieldSectionsComponent implements OnInit {

  @ViewChild("customFieldSectionsForm") customFieldSectionsForm: NgForm;

  @Input() credentialId: number;
  @Input() vendor: "LoanPass" | "Polly" | "LenderPrice" | "MeridianLink" | "LoanPassIframe" = "LoanPass";

  customFields: CustomField[] = [];
  orginalCustomFields: CustomField[] = [];

  loadingCustomFieldSections: boolean;

  sections: CustomFieldSection[] = [];
  orginalCustomFieldSections: CustomFieldSection[] = [];

  dependsOnCustomFields: CustomField[] = [];

  isRequiredSectionName: boolean = false;

  customFieldId: number = -1;

  pricingCustomFieldSectionId: number = -1;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _pricingCustomFieldService: PricingCustomFieldService,
    private readonly _pricingCustomFieldSectionService: PricingCustomFieldSectionService
  ) { }

  ngOnInit(): void {
    this.loadCustomFieldSections();
  }

  onSaveClicked = () => {

    this.customFieldSectionsForm.form.markAllAsTouched();
    if (!this.customFieldSectionsForm.form.valid) {
      return;
    }

    let obs: Observable<any>[] = [];

    this.sections.forEach((cfs: CustomFieldSection, index: number) => {
      cfs.displayOrder = index;
      cfs.customFields.forEach(cf => {
        if (cf.options?.length > 0 && cf.type !== 'List') {
          cf.options = [];
        }
        if (cf.dependsOnValue && cf.dependsOnCustomField.type !== 'List') {
          cf.dependsOnValues = [cf.dependsOnValue];
        }
      });
      
      if (cfs.pricingCustomFieldSectionId) {
        obs.push(this._pricingCustomFieldSectionService.updateCustomField(cfs));
      } else {
        obs.push(this._pricingCustomFieldSectionService.insertCustomField(cfs));
      }
    });
    const deletedCustomFieldSections = this.orginalCustomFieldSections.filter(o => !this.sections.map(c => c.pricingCustomFieldSectionId).includes(o.pricingCustomFieldSectionId));
    deletedCustomFieldSections.forEach(del => obs.push(this._pricingCustomFieldSectionService.deleteCustomField(del?.pricingCustomFieldSectionId)));

    this._spinnerService.show();
    forkJoin(obs).subscribe({
        next: ((result: any) => {
          this.activeModal.close();
          this._spinnerService.hide();
          this._notificationService.showSuccess(
            'Custom Field Sections successfuly updated',
            'Success!'
          );
        }),
        error: ((error: any) => {
          this._spinnerService.hide();
          this._notificationService.showError(
            error?.message || 'Unable to update custom field sections',
            'Failure!'
          );
        })
    });
  }

  addSection(): void {
    let section = new CustomFieldSection(this.vendor);
    section['tempUniqueId'] =  Math.floor(Math.random() * Date.now());
    section.displayOrder = this.sections.length + 1;
    this.sections.push(section);
    this.isRequiredSectionName = this.sections.filter(s => !s.name).length > 1;
    this.pricingCustomFieldSectionId -= 1;
  }

  addCustomField = (section: CustomFieldSection) => {
    let customField = new CustomField(this.vendor);
    customField.sortOrder = section.customFields.length;
    section.customFields.push(customField);
    this.customFieldId -= 1;
  }

  deleteSection = (section: CustomFieldSection) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this section?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      let index = self.sections.findIndex(s => s['tempUniqueId'] == section['tempUniqueId']);
      self.sections.splice(index, 1);
      self.isRequiredSectionName = self.sections.filter(s => !s.name).length > 1;
    });
  }

  drop(event: CdkDragDrop<number[]>) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.sections.forEach(section => {
        section.displayOrder = this.sections.indexOf(section) + 1;
      })
  }

  sectionNameChanged = () => {
    this.isRequiredSectionName = this.sections.filter(s => !s.name).length > 1;
  }

  loadDependsOnCustomFields = () => {
    this.dependsOnCustomFields = [];
    this.sections.forEach(section => {
      if (section.customFields && section.customFields.length > 0) {
        section.customFields.forEach(customField => {
          this.dependsOnCustomFields.push(customField);
        })
      }
    });
  }

  onCustomFieldNameChanged = () => {
    this.loadDependsOnCustomFields();
  }

  private loadCustomFieldSections() {
    this.loadingCustomFieldSections = true;
    this._pricingCustomFieldSectionService.getCustomFieldSections(this.vendor).subscribe({
      next: ((response) => {
        this.sections = _.orderBy(response, 'displayOrder');
        this.orginalCustomFieldSections = _.cloneDeep(this.sections);
        this.sections.forEach(section => {
          section['tempUniqueId'] =  Math.floor(Math.random() * Date.now());
          if (section.customFields && section.customFields.length > 0 ) {
            section.customFields.forEach(field => {
              field.tempUniquId =  Math.floor(Math.random() * Date.now());

            })
          }
        })
        
        this.isRequiredSectionName = this.sections.filter(s => !s.name).length > 1;
        this.loadDependsOnCustomFields();
        this.sections.forEach(section => {
          if (section.customFields && section.customFields.length > 0) {
            section.customFields.forEach(customField => {
              if (customField.displayMode !== 'AlwaysShow') {
                let field = this.dependsOnCustomFields.find(cf => cf.name === customField.dependsOnCustomFieldName);
                if (field) {
                  customField.dependsOnCustomField = field;
                  if (customField.displayMode === 'DependsOnSpecificValue' && field.type !== 'List') {
                    customField.dependsOnValue = customField.dependsOnValues[0];
                  }
                }
              }
              
            })
          }
        });
      }),
      error: ((error: any) => {
        this._notificationService.showError(error?.message || 'Unable to get custom fields for Loan Pass', 'Failure!');
      })
    }).add(() => this.loadingCustomFieldSections = false);
  }

}
