<div class="row g-2">
  <div class="col-sm-12">
    <ng-container *ngIf="['ToExecuteRequest','ToProductSearchRequest'].includes(mappingType)">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ active: tab === 'fieldMappings' }" (click)="tab = 'fieldMappings'"
            data-bs-toggle="tab" href="#fieldMappings" role="tab" aria-selected="true"> Field Mappings </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ active: tab === 'customMappings' }" (click)="tab = 'customMappings'"
            data-bs-toggle="tab" href="#customMappings" role="tab" aria-selected="false"> Custom Field Mappings </a>
        </li>
      </ul>

      <div class="tab-content">
        <div *ngIf="tab === 'fieldMappings'" class="tab-pane" [ngClass]="{ active: tab === 'fieldMappings'}"
          id="fieldMappings" role="tabpanel">
          <p-table [value]="destinationFields" [paginator]="false" responsiveLayout="scroll" [autoLayout]="true"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
              <tr>
                <th style="min-width: 20rem">Destination {{mappingType == 'ToExecuteRequest' ? 'LoanPass' : 'Lodasoft'}}
                  Field </th>
                <th class="text-center" style="width: 12rem">{{mappingType == 'ToExecuteRequest' ? 'LoanPass' :
                  'Lodasoft'}} Field Type</th>
                <th style="min-width: 20rem" *ngIf="mappingType == 'ToProductSearchRequest'">Source {{mappingType ==
                  'ToExecuteRequest' ? 'Lodasoft' : 'LoanPass'}} Field </th>
                <th class="text-center" style="min-width: 20rem">Source {{mappingType == 'ToExecuteRequest' ? 'Lodasoft'
                  :
                  'LoanPass'}} Field Expression</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-field let-index="rowIndex">
              <tr>
                <td>
                  <label *ngIf="mappingType == 'ToExecuteRequest'">{{field.name}}</label>
                  <label *ngIf="mappingType == 'ToProductSearchRequest'">{{field.label}}</label>
                </td>
                <td>
                  <div class="d-flex align-items-center justify-content-center">
                    <ng-container *ngIf="mappingType == 'ToExecuteRequest'">
                      <label *ngIf="!isEnumByLoanPassField.get(field.id); else enumMappingBtn">
                        {{ mappingByLoanPassField.get(field.id).loanPassFieldType }}
                      </label>
                    </ng-container>
                    <ng-container *ngIf="mappingType == 'ToProductSearchRequest'">
                      <label *ngIf="!isEnumByLodaField.get(field.path); else enumMappingBtn">
                        {{ getDestinationFieldTypeName(field.path) }}
                      </label>
                    </ng-container>

                    <ng-template #enumMappingBtn>
                      <button title="Edit enum mappings" type="button" class="btn btn-primary btn-sm"
                        (click)="onEditEnumClicked(field)"> Enum </button>
                    </ng-template>
                  </div>
                </td>
                <td *ngIf="mappingType == 'ToProductSearchRequest'">
                  <p-dropdown id="loanPassField_{{index}}" name="loanPassField_{{index}}" styleClass="w-100"
                    [options]="loanPassFieldsConfig[fieldType]"
                    [(ngModel)]="mappingByLodaField.get(field.path).loanPassFieldId" [showClear]="true" [filter]="true"
                    filterBy="name" optionLabel="name" optionValue="id" optionDisabled="disabled"
                    placeholder="Pick a source field"></p-dropdown>
                </td>
                <td>
                  <input *ngIf="mappingType == 'ToExecuteRequest'" id="expression_{{index}}" name="expression_{{index}}"
                    readonly type="text" class="form-control"
                    [(ngModel)]="mappingByLoanPassField.get(field.id).expression"
                    (click)="onEditCustomExpressionClicked(mappingByLoanPassField.get(field.id))"
                    placeholder="Custom Expression">

                  <input *ngIf="mappingType == 'ToProductSearchRequest'" id="expression_{{index}}"
                    name="expression_{{index}}" readonly type="text" class="form-control"
                    [(ngModel)]="mappingByLodaField.get(field.path).expression"
                    (click)="onEditCustomExpressionClicked(mappingByLodaField.get(field.path))"
                    placeholder="Custom Expression">
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colpan]="mappingType == 'ToProductSearchRequest' ? 4 : 3" class="text-center">
                  There are no items.
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="tab === 'customMappings'" class="tab-pane" [ngClass]="{ active: tab === 'customMappings'}"
          id="customMappings" role="tabpanel">
          <p-table [value]="customMappings" [paginator]="false" responsiveLayout="scroll" [autoLayout]="true">
            <ng-template pTemplate="caption">
              <div class="d-flex justify-content-start">
                <button class="btn btn-primary" (click)="addMapping(true)">
                  Add Mapping
                </button>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Destination {{mappingType == 'ToExecuteRequest' ? 'LoanPass' : 'Lodasoft Custom'}} Field</th>
                <th>Source {{mappingType == 'ToExecuteRequest' ? 'Lodasoft Custom' : 'LoanPass'}} Field </th>
                <th style="width:12rem;text-align:center;">Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-mapping let-index="rowIndex">
              <tr>
                <td>
                  <p-dropdown *ngIf="mappingType == 'ToExecuteRequest'" id="loanPassField_{{index}}"
                    name="loanPassField_{{index}}" styleClass="w-100" [options]="loanPassFieldsConfig[fieldType]"
                    [(ngModel)]="mapping.loanPassFieldId" [showClear]="true" [filter]="true" filterBy="name"
                    optionLabel="name" optionValue="id" optionDisabled="disabled"
                    placeholder="Pick a destination field"></p-dropdown>

                  <p-dropdown *ngIf="mappingType == 'ToProductSearchRequest'" id="pricingCustomFieldId_{{index}}"
                    name="pricingCustomFieldId_{{index}}" styleClass="w-100" [options]="customFields"
                    [(ngModel)]="mapping.pricingCustomFieldId" (ngModelChange)="pricingCustomFieldChanged(mapping)"
                    [showClear]="true" [filter]="true" filterBy="displayName" optionLabel="displayName"
                    optionValue="pricingCustomFieldId" placeholder="Pick a destination custom field"></p-dropdown>

                </td>
                <td>
                  <p-dropdown *ngIf="mappingType == 'ToExecuteRequest'" id="pricingCustomFieldId_{{index}}"
                    name="pricingCustomFieldId_{{index}}" styleClass="w-100" [options]="customFields"
                    [(ngModel)]="mapping.pricingCustomFieldId" (ngModelChange)="pricingCustomFieldChanged(mapping)"
                    [showClear]="true" [filter]="true" filterBy="displayName" optionLabel="displayName"
                    optionValue="pricingCustomFieldId" placeholder="Pick a source custom field"></p-dropdown>

                  <p-dropdown *ngIf="mappingType == 'ToProductSearchRequest'" id="loanPassField_{{index}}"
                    name="loanPassField_{{index}}" styleClass="w-100" [options]="loanPassFieldsConfig[fieldType]"
                    [(ngModel)]="mapping.loanPassFieldId" [showClear]="true" [filter]="true" filterBy="name"
                    optionLabel="name" optionValue="id" optionDisabled="disabled"
                    placeholder="Pick a source field"></p-dropdown>
                </td>
                <td style="width:12rem;">
                  <div class="text-center">
                    <button *ngIf="!(activeDeletionIndex === index)" type="button" title="Delete" class="btn btn-danger"
                      (click)="onDeleteButtonClicked(index)"><i class="fa fa-trash"></i>
                    </button>
                    <button *ngIf="activeDeletionIndex === index" type="button" title="Accept"
                      class="btn btn-success me-2" (click)="onDeleteConfirmClicked(index)"><i class="fa fa-check"></i>
                    </button>
                    <button *ngIf="activeDeletionIndex === index" title="Cancel" type="button" class="btn btn-danger"
                      (click)="onDeleteCancelled()"><i class="fa fa-close"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colpan]="3" class="text-center">
                  There are no items.
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="['ToProduct','ToQuote'].includes(mappingType)">
      <p-table [value]="mappings" [paginator]="false" responsiveLayout="scroll" [autoLayout]="true">
        <ng-template pTemplate="caption">
          <div class="d-flex justify-content-start">
            <button class="btn btn-primary" (click)="addMapping()">
              Add Mapping
            </button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Destination Lodasoft Field</th>
            <th>Expression </th>
            <th style="width:12rem;text-align:center;">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
          <tr>
            <td>
              <p-dropdown id="destinationFieldPath_{{index}}" name="destinationFieldPath_{{index}}" styleClass="w-100"
                [options]="destinationFieldPaths" [(ngModel)]="item.destinationFieldPath"
                (ngModelChange)="destinationFieldPathChanged()" [showClear]="true" [filter]="true" filterBy="path"
                optionLabel="label" optionValue="path" optionDisabled="disabled"
                placeholder="Pick a destination field"></p-dropdown>
            </td>
            <td>
              <input id="expression_{{index}}" name="expression_{{index}}" readonly type="text" class="form-control"
                [(ngModel)]="item.expression" (click)="onEditCustomExpressionClicked(item)"
                placeholder="Custom Expression">
            </td>
            <td style="width:12rem;">
              <div class="text-center">
                <button *ngIf="!(activeDeletionIndex === index)" type="button" title="Delete" class="btn btn-danger"
                  (click)="onDeleteButtonClicked(index)"><i class="fa fa-trash"></i>
                </button>
                <button *ngIf="activeDeletionIndex === index" type="button" title="Accept" class="btn btn-success me-2"
                  (click)="onDeleteConfirmClicked(index)"><i class="fa fa-check"></i>
                </button>
                <button *ngIf="activeDeletionIndex === index" title="Cancel" type="button" class="btn btn-danger"
                  (click)="onDeleteCancelled()"><i class="fa fa-close"></i>
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colpan]="3" class="text-center">
              There are no items.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
  </div>
</div>
