import { Component, Input, OnInit } from '@angular/core';
import { CustomAlert } from 'src/app/models/custom-alert.model';

@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss'],
})

export class AlertComponent implements OnInit {

  @Input()
  alert: CustomAlert;

  constructor() { }

  ngOnInit() { }
}
