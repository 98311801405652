import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { LiteAccountInfo } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { GenerateBorrowerVerificationReportRequest } from 'src/app/models/voa/generate-borrower-verification-report-request';
import { FormFreeHistory } from 'src/app/models/voa/voa-form-free-history.model';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-generate-voa-order-dialog',
  templateUrl: './generate-voa-order-dialog.component.html',
  styleUrls: ['./generate-voa-order-dialog.component.scss']
})
export class GenerateVoaOrderDialogComponent implements OnInit {

  @ViewChild("generateReportForm") generateReportForm: NgForm;

  @Input() order: FormFreeHistory;
  @Input() accountInfo: LiteAccountInfo[] = [];
  @Input() daysBackOptions: number[] = [];

  request: GenerateBorrowerVerificationReportRequest = new GenerateBorrowerVerificationReportRequest();

  accountItems: EnumerationItem[] = [];

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.request.applicationId = this.order.applicationId;
    this.request.borrowerId = this.order.borrowerId;
    this.request.daysBack = this.order.daysBack;

    this.accountItems = this.accountInfo.map((ai) => new EnumerationItem(`${ai.accountHolder} - ${ai.accountName} #${ai.accountNumber}`,ai.accountId));
    this.request.thirdPartyAccountIdFilter = this.accountItems.map(i => i.value);
  }

  onSubmitClicked = () => {
    this.generateReportForm.form.markAllAsTouched();
    if (!this.generateReportForm.valid) {
      return;
    }

    this._spinnerService.show();
    this._loanServicesService
      .generatePdfReport(this.order.formFreeHistoryId, this.request)
      .subscribe({
        next: (res) => {
          this._spinnerService.hide();

          this._notificationService.showSuccess(
            'Report generated successfully',
            'VOA Loan Service'
          );

          this.activeModal.close(res);
        },
        error: (e) => {
          this._spinnerService.hide();
          this._notificationService.showError(
            e ? e.message : 'Unable to generate report',
            'VOA Loan Service'
          );
        }
      }
      );
  }

}
