<div class="inline-editor" *ngIf="editorMode != 'InlineReadOnly' && !disabled" [ngClass]="{
    'input-group': editorMode == 'Inline' ,
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }" [hidden]="editorMode === 'Inline' && !isEditActive && !(model && model.touched && model.invalid) && !disabled">
  <input *ngIf="!rateIsTrueToTextValue" type="text" [ngModel]="value | percent : digitsInfo" #model="ngModel" #control (focus)="onFocus($event)"
    (blur)="onBlur($event)" (keydown)="onKeyDown($event)"
    name="{{ name }}" [readonly]="readonly" [required]="required" placeholder="{{placeholder}}"
    [id]="id" class="form-control" #percentage [mask]="mask" suffix="%" [disabled]="disabled"
    [ngClass]="{ 'is-invalid': model && model.touched && model.invalid }" />
  <input *ngIf="rateIsTrueToTextValue" type="text" [ngModel]="value | number : digitsInfo" #model="ngModel" #control (focus)="onFocus($event)"
    (blur)="onBlur($event)" (keydown)="onKeyDown($event)"
    name="{{ name }}" [readonly]="readonly" [required]="required" placeholder="{{placeholder}}"
    [id]="id" class="form-control" #percentage [mask]="mask" suffix="%" [disabled]="disabled"
    [ngClass]="{ 'is-invalid': model && model.touched && model.invalid }" />
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Apply" type="button"
    *ngIf="editorMode == 'Inline' && !disabled" (mousedown)="onApplyClicked($event)">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Cancel" type="button"
    *ngIf="editorMode == 'Inline' && !disabled" (mousedown)="onCancelClicked($event)">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
  <div class="invalid-feedback">This field is required</div>
</div>

<span [ngClass]="{
    'empty-field': value == null || value == undefined
  }" class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" title="Click to Edit"
  *ngIf="editorMode == 'Inline' && !isEditActive && !(model && model.touched && model.invalid) && !disabled"
  (click)="onEditModeToggledOn()">
  <span class="val" *ngIf="rateIsTrueToTextValue && value != null && value != undefined">{{(value /100) | percent :
    digitsInfo}}</span>
  <span class="val" *ngIf="!rateIsTrueToTextValue && value != null && value != undefined">{{value | percent :
    digitsInfo}}</span>
  <span class="placeholder" *ngIf="value == null || value == undefined">--</span>
</span>
<span [ngClass]="{
    'empty-field': value == null || value == undefined
  }" class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" title="Click to Edit" *ngIf="editorMode == 'InlineReadOnly' || disabled">
  <span class="val" *ngIf="rateIsTrueToTextValue && value != null && value != undefined">{{(value /100) | percent :
    digitsInfo}}</span>
  <span class="val" *ngIf="!rateIsTrueToTextValue && value != null && value != undefined">{{value | percent :
    digitsInfo}}</span>
  <span class="placeholder" *ngIf="value == null || value == undefined">--</span>
</span>
