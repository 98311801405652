import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { AlignmentService } from 'src/app/modules/alignment/services/alignment.service';
import { AvailableAlignmentsDialogComponent } from 'src/app/modules/internal-contacts/components/available-alignments-dialog/available-alignments-dialog.component';
import { Alignment } from 'src/app/modules/internal-contacts/models/alignment.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { AlignmentRoleUsers } from '../../../models/process-submission.model';
import { chain } from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContextService } from 'src/app/services/application-context.service';

@Component({
  selector: 'role-based-internal-contacts',
  templateUrl: './role-based-internal-contacts.component.html',
  styleUrls: ['./role-based-internal-contacts.component.scss']
})
export class RoleBasedInternalContactsComponent implements OnInit, OnChanges {

  @Input()
  selectedCompanyId: number;

  @Input()
  selectedChannel: string;

  @Input()
  primaryRoleUserId: string;

  @Input()
  isTpoUser: boolean;

  @Input()
  firstRole: Role;

  @Input()
  secondaryRoles: Role[];

  @Input()
  loadingUsers: boolean;

  @Input()
  roleUsers: User[] = [];

  @Input()
  primaryContactIsRequired: boolean = false;

  primaryRoleUsers: User[] = [];
  secondaryRoleUsers: User[] = [];

  tpoRoleUsersMap: AlignmentRoleUsers = {};
  alignments: Alignment[] = [];

  currentlyLoginUserId: string = null;

  secondaryRoleUsersByRoleId: Map<number, User[]> = new Map<number, User[]>();

  @Output()
  primaryRoleContactChanged: EventEmitter<string> = new EventEmitter();

  @Output()
  secondaryRoleContactChanged: EventEmitter<AlignmentRoleUsers> = new EventEmitter();

  constructor(
    private readonly _alignmentService: AlignmentService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _contextService: ApplicationContextService
  ) { }

  ngOnInit(): void {
    this._contextService.context.subscribe(ctx => {
      this.currentlyLoginUserId = ctx.currentlyLoggedInUser.userCompanyGuid;
    })

    this.secondaryRoles.forEach(r => {
      this.tpoRoleUsersMap[r.roleId] = null;
      const usersWhoHaveRole = this.secondaryRoleUsers.filter(u => u.roleId == r.roleId);
      this.secondaryRoleUsersByRoleId.set(r.roleId, usersWhoHaveRole);
    });
    this.fetchAlignmentsByChannel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const firstRoleChange = changes['firstRole'];
    const roleUsersChange = changes['roleUsers'];
    if (firstRoleChange || roleUsersChange) {
      if (roleUsersChange) {
        const roleUsers = roleUsersChange.currentValue;
        if (roleUsers) {
          this.primaryRoleUserId = "";
          this.setAvailableUserFullName();
          this.primaryRoleUsers = chain(roleUsers || [])
            .filter(user => user.roleId === this.firstRole.roleId)
            .sortBy(user => [user.lastName, user.firstName])
            .value();
        }
        if (this.firstRole) {
          this.secondaryRoleUsers = chain(this.roleUsers || [])
            .filter(user => (this.isTpoUser ? true : !!user.active))
            .sortBy(user => [user.lastName, user.firstName])
            .value();
          this.secondaryRoles.forEach(r => {
            this.tpoRoleUsersMap[r.roleId] = null;
            const usersWhoHaveRole = this.secondaryRoleUsers.filter(u => u.roleId == r.roleId);
            this.secondaryRoleUsersByRoleId.set(r.roleId, usersWhoHaveRole);
          });
        }
      }
    }
  }

  onPrimaryRoleUserChanged(userId) {
    var secondaryUpdated = false;
    this.secondaryRoles.forEach(r => {
      if (!this.tpoRoleUsersMap[r.roleId] || this.tpoRoleUsersMap[r.roleId] == "") {
        this.tpoRoleUsersMap[r.roleId] = userId;
        secondaryUpdated = true;
      }
    });
    this.primaryRoleContactChanged.emit(userId);
    if (secondaryUpdated) {
      this.onSecondaryRoleUserChanged();
    }
  }

  onSecondaryRoleUserChanged = () => {
    this.secondaryRoleContactChanged.emit(this.tpoRoleUsersMap);
  }

  protected onShowAvailableAlignmentsClicked() {
    const modalRef = this._modalService.open(AvailableAlignmentsDialogComponent, Constants.modalOptions.medium)
    modalRef.componentInstance.title = 'Available Alignments';
    modalRef.componentInstance.roles = this.isTpoUser ? this.secondaryRoles : [this.firstRole, ...this.secondaryRoles];
    modalRef.componentInstance.users = this.isTpoUser ? this.secondaryRoleUsers : this.roleUsers;
    modalRef.componentInstance.selectedUser = this.primaryRoleUsers.find(user => user.userCompanyGuid === this.primaryRoleUserId);
    modalRef.componentInstance.availableAlignments = this.alignments;
    modalRef.componentInstance.isTpo = true;

    modalRef.result.then((data: any) => {
      if (this.isTpoUser) {
        Object.keys(this.tpoRoleUsersMap).forEach((roleId) => {
          this.tpoRoleUsersMap[roleId] = data[roleId];
        });
        this.secondaryRoleContactChanged.emit(this.tpoRoleUsersMap);
      }
      else {
        this.primaryRoleContactChanged.emit(data[this.firstRole.roleId]);
      }
    }, (res) => {
    });
  }

  private fetchAlignmentsByChannel() {
    this._alignmentService.getAlignmentsByChannelAndCompanyId({
      externalCompanyId: this.selectedCompanyId || null,
      selectedChannel: this.selectedChannel || null,
      selectedUser: null
    })
      .subscribe({
        next: (response) => {
          this.alignments = response.filter(a => a.usersInAlignment.findIndex(ua => ua.userId == this.primaryRoleUserId) > -1);

          if (this.alignments.length == 1) {
            let userId = this.alignments[0].usersInAlignment.find(u => u.roleId == this.firstRole.roleId).userId;
            this.primaryRoleContactChanged.emit(userId);

            if (this.isTpoUser) {
              Object.keys(this.tpoRoleUsersMap).forEach(roleId => {
                this.tpoRoleUsersMap[roleId] = this.alignments[0].usersInAlignment.find(u => u.roleId == Number(roleId)).userId;
              });
              this.secondaryRoleContactChanged.emit(this.tpoRoleUsersMap);
            }
          }

          if (!this.alignments.length) {

            if (!this.primaryRoleUserId) {
              const loginUser = this.primaryRoleUsers.find(u => u.userCompanyGuid == this.currentlyLoginUserId);
              if (loginUser) {
                this.primaryRoleUserId = this.currentlyLoginUserId;
                this.primaryRoleContactChanged.emit(this.primaryRoleUserId);
              }
            }

            if (this.secondaryRoles.length && this.primaryRoleUserId) {
              if (!this.secondaryRoleUsers.find(u => u.userCompanyGuid == this.primaryRoleUserId)) {
                const matchingUser = this.primaryRoleUsers.find(u => u.userCompanyGuid == this.primaryRoleUserId);
                this.secondaryRoleUsers.push(matchingUser);

                this.secondaryRoleUsers = chain(this.secondaryRoleUsers)
                  .sortBy(user => [user.lastName, user.firstName])
                  .value();
              }

              this.secondaryRoles.forEach(r => {
                this.tpoRoleUsersMap[r.roleId] = this.primaryRoleUserId;
              });

              this.secondaryRoleContactChanged.emit(this.tpoRoleUsersMap);
            }

          }
        },
        error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to get channel alignments', 'Get Alignments');
        }
      });
  }

  private setAvailableUserFullName() {
    this.roleUsers.forEach(user => {
      user["userFullName"] = Utils.getPersonsDisplayName(user);
      if(this.isTpoUser){
        user["userFullName"] += !!user.active ? "" : " (Inactive)";
      }
    });
  }

}
