import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LoanStatus } from 'src/app/models';
import { LoanStatusAssociation } from '../../models';

@Injectable()
export class LoanStatusFlowService {
  constructor(private readonly _dataService: DataService) { }

  getAllLoanStatusAssociations(
    statusType: string,
    loanPurposeId: number,
    companyId: number
  ): Observable<Array<LoanStatusAssociation>> {
    return this._dataService.get(
      `api/Admin/GetAll${statusType}StatusAssociationsByLoanPurpose/${loanPurposeId}?companyId=${companyId}`
    );
  }

  getAllLoanStatuses(
    statusType: string,
    companyId: number
  ): Observable<Array<LoanStatus>> {
    return this._dataService.get(
      `api/Admin/All${statusType}StatusModel?companyId=${companyId}`
    );
  }

  updateLoanStatusFlowTree(
    statusType: string,
    loanPurposeId: number,
    statusId: number,
    associated: string,
    channel: string
  ): Observable<Array<{ id: number }>> {
    let url = `api/Admin/SaveAssociate${statusType}Status/${loanPurposeId}/${statusId}`;
    url += `/${associated ? associated : '0'}`;
    if (channel) {
      url += `?channel=${channel}`;
    }
    return this._dataService.post(url, {});
  }
}
