import { Component, Injector, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ApplicationContext, ExtendedFormFreeHistory, ExtendedFormFreeReportHistory, FormFreeHistory, Mortgage, ThirdPartyCredentialArray, TwnHistoriesArray } from 'src/app/models';
import { ThirdPartyIntegrationProvider } from 'src/app/models/fee/fee.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanService, LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';

@Component({
  selector: 'digital-emp-income-verification',
  templateUrl: 'twn.component.html',
  styleUrls: ['./twn.component.scss']
})
export class TwnComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  loanId: number;

  mortgage: Mortgage;

  borrowers: BorrowerDto[] = [];
  twnHistories: TwnHistoriesArray = [];
  twnHistoriesExcludedData: TwnHistoriesArray = [];
  orders: FormFreeHistory[] = [];
  voieCredentials: ThirdPartyCredentialArray;
  allowProductOpts: EnumerationItem[] = [];
  orderStatusOpts: EnumerationItem[] = [];
  orderHistory: ExtendedFormFreeReportHistory[] = [];

  loadingData: boolean = false;
  isVoePullDisabled: boolean = false;
  isLoanReadOnly: boolean = false;

  protected providers: EnumerationItem[] = null;
  protected selectedProvider: string = null;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _loanService: LoanService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _enumsService: EnumerationService,
    private readonly _notificationService: NotificationService,
  ) {
    super(injector);
  }

  ngOnInit() {
    //todo app context should be loaded in parent component
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.applicationContext = context;
        if (context.application) {
          this.initializeFromContext(context);
        }
      });
    } else {
      this.initializeFromContext(this.applicationContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onProviderChanged = () => {
    if (this.selectedProvider == "TWN") {
      this.getTwnData();
    } else if (this.selectedProvider == "Truv") {
      this.getTruvData();
    }
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.isLoanReadOnly = context.applicationIsReadOnly;
    this.loanId = context.application.applicationId;
    this.isVoePullDisabled = context.userPermissions.disablePullVOE;
    this.getData();
  }

  private getData = () => {
    this.loadingData = true;

    const combined = combineLatest([
      this._loanService.getBorrowers(this.loanId),
      this._loanServicesService.getFormFreeHistory(this.loanId),
      this._systemLevelService.getSystemLevel()
    ]);
    combined.subscribe({
      next: ([borrowers, orders, sysLevel]) => {
        this.borrowers = borrowers || [];
        this.orders = (orders || []).filter(o => o.integrationProvider == 'Truv');

        this.loadingData = false;

        const creds = JSON.parse(JSON.stringify(sysLevel));
        this.voieCredentials = creds.thirdPartyCredentials.filter(el => ["VOI", "VOE/VOI"].includes(el.credentialType));

        if (this.orders.length) {
          this.selectedProvider = "Truv";
        }

        if (this.voieCredentials?.length) {
          this.providers = this.voieCredentials.map(cred => new EnumerationItem(cred.vendorName, cred.vendorName));
          if (this.providers.length === 1 && !this.selectedProvider) {
            this.selectedProvider = this.providers[0].value;
          }
        } else {
          this.selectedProvider = "TWN";
        }

        if (this.selectedProvider) {
          this.onProviderChanged();
        }
      },
      error: (error) => {
        this.loadingData = false;
        this._notificationService.showError(
          error?.message || 'Unable to load data',
          'VOI/VOE Loan Service'
        );
      }
    });
  };

  private getTwnData = () => {
    this.loadingData = true;

    const combined = combineLatest([
      this._loanService.getMortgage(this.loanId),
      this._loanServicesService.getTwnHistories(this.loanId),
      this._loanServicesService.getTwnHistories(this.loanId, true)
    ]);
    combined.subscribe({
      next: ([mortgage, twnHistories, twnHistoriesExcludedData]) => {
        this.mortgage = mortgage;
        this.twnHistories = twnHistories || [];
        this.twnHistoriesExcludedData = twnHistoriesExcludedData || [];
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to load TWN data',
          'VOI/VOE Loan Service'
        );
      }
    }).add(() => this.loadingData = false);

  };

  private getTruvData = () => {
    this.loadingData = true;

    const combined = combineLatest([
      this._loanServicesService.getFormFreeReportHistory(this.loanId),
      this._enumsService.getEnumerationsByType("LE.Lodasoft.Common.Enums.ThirdParty.BorrowerVerification.BorrowerVerificationProductType"),
      this._enumsService.getEnumerationsByType("LE.Lodasoft.Common.Enums.ThirdParty.BorrowerVerification.BorrowerVerificationOrderStatus")
    ]);
    combined.subscribe({
      next: ([reportHistory, productOptions, orderStatuses]) => {
        this.prepareOrdersHistoryToView(this.orders);
        this.extendOrderAndHistory(reportHistory || []);
        this.allowProductOpts = productOptions;
        this.orderStatusOpts = orderStatuses;

        this.orders.forEach(o => {
          o["statusDescription"] = this.orderStatusOpts.find(opt => opt.value == o.status)?.name;
        });
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to load Truv data',
          'VOI/VOE Loan Service'
        );
      }
    }).add(() => this.loadingData = false);
  };


  prepareOrdersHistoryToView = (orders: FormFreeHistory[]) => {
    this.borrowers.forEach((borrower) => {
      const found = orders.some((item) => item.borrowerId == borrower.borrowerId);
      if (found) return;

      orders.push({
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        email: borrower.email,
        borrowerId: borrower.borrowerId,
        phoneNumber: borrower.mobilePhone,
        integrationProvider: ThirdPartyIntegrationProvider.Truv
      } as FormFreeHistory);
    });

    this.orders = [...orders];
  };

  private extendOrderAndHistory = (
    reportHistory: ExtendedFormFreeReportHistory[]
  ) => {
    reportHistory?.forEach((hist) => {
      this.orders.forEach((order: ExtendedFormFreeHistory) => {
        if (order.formFreeHistoryId == hist.formFreeHistoryId) {
          hist.firstName = order.firstName;
          hist.lastName = order.lastName;
          order.reportId = hist.reportId;
          order.isRepullReport = hist.reportId && !hist.reportData;
          order.reportData = hist.reportData;
        }
      });
    });
    this.orderHistory = reportHistory.filter(h => h.reportType && h.reportType != "Assets");
  };

  loadHistory = () => {
    this._loanServicesService.getFormFreeReportHistory(this.loanId).subscribe(
      (reportHistory) => {
        this.extendOrderAndHistory(reportHistory);
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to load history',
          'VOA Loan Service'
        );
      }
    );
  };
}
