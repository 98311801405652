import { PaymentInfo } from "../../credit/models/credit-request.model";

export class CreditUpgradeRequest {
    integrationHistoryId: number;
    experian: boolean;
    equifax: boolean;
    transUnion: boolean;
    paymentInfo: PaymentInfo;

    constructor() {
        this.paymentInfo = new PaymentInfo();
    }
}