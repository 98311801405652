import * as _ from 'lodash';
import { Constants } from 'src/app/services/constants';

export class DiffChecker {
  get diff(): { [k: string]: any } | undefined {
    return this._diff;
  }

  private _diff: { [k: string]: any } | undefined;

  constructor(
    public readonly previous: any,
    public readonly current: any,
    public readonly name: string,
    public readonly options?: DiffCheckerOptions,
  ) {
    const floatPrecision = options?.floatPrecision;
    if (floatPrecision && !_.isNumber(floatPrecision)) {
      throw new Error('floatPrecision must be a number');
    }
    if (floatPrecision && !_.isInteger(floatPrecision) || floatPrecision <= 0) {
      throw new Error('floatPrecision must be a positive integer');
    }
  }

  private _filterOutNullish(obj) {
    if (_.isObject(obj)) {
      const fieldsToIgnoreWhileDiffChecking = obj[Constants.dirtyCheckIgnoreFieldsMetaDataField];
      const filteredOne = Object.entries(obj)
        .filter(([, v]) => v != null)
        .reduce((r, [key, value]) => ({ ...r, [key]: this._filterOutNullish(value) }), {});
      if (fieldsToIgnoreWhileDiffChecking) {
        filteredOne[Constants.dirtyCheckIgnoreFieldsMetaDataField] = fieldsToIgnoreWhileDiffChecking;
      }
      return filteredOne;
    }

    return obj;
  }

  /**
   * Customizer for _.isEqualWith to compare numbers with precision.
   * @param objValue
   * @param srcValue
   */
  private customizer = (objValue, srcValue) => {
    const precision = this.options?.floatPrecision;
    let value1 = objValue;
    let value2 = srcValue;
    const isNumber = !isNaN(value1) && !isNaN(value2);
    if (precision && isNumber) {
      value1 = parseFloat(value1);
      value2 = parseFloat(value2);
      value1 = value1.toFixed(precision);
      value2 = value2.toFixed(precision);
    }

    return _.isEqual(value1, value2);
  }

  /**
   * Calculates the difference between two objects recursively.
   * @param previous The original object to compare against.
   * @param current The current object to compare.
   * @param prefix The key prefix used to store the path.
   * @returns An object containing the differences between the two objects.
   */
  private _calculateDiff(previous: any, current: any, prefix = ''): any {
    const diff: any = {};

    const stack: { key: string, previous: any, current: any }[] = [{ key: prefix, previous, current }];

    while (stack.length > 0) {
      const { key, previous, current } = stack.pop()!;

      if (!_.isEqual(previous, current)) {
        if (_.isObject(previous) && _.isObject(current)) {
          const keys = _.union(Object.keys(previous), Object.keys(current));

          for (const k of keys) {
            if (k === Constants.dirtyCheckIgnoreFieldsMetaDataField || (current[Constants.dirtyCheckIgnoreFieldsMetaDataField] &&
              current[Constants.dirtyCheckIgnoreFieldsMetaDataField].includes(k))) {
              continue;
            }
            const newKey = key ? `${key}.${k}` : k;
            const prevValue = previous?.[k];
            const currValue = current?.[k];
            if (_.isObject(prevValue) || _.isObject(currValue)) {
              stack.push({ key: newKey, previous: prevValue, current: currValue });
            } else if (!_.isEqualWith(prevValue, currValue, this.customizer)) {
              diff[newKey] = {
                previous: prevValue,
                current: currValue,
              };
            }
          }
        } else {
          diff[key] = {
            previous: previous,
            current: current,
          };
        }
      }
    }

    return diff;
  }

  calculateDiff = (logDiff = false): { [k: string]: any } | undefined => {
    const previous = this._filterOutNullish(this.previous);
    const current = this._filterOutNullish(this.current);

    const diff = this._calculateDiff(previous, current, this.name);
    if (Object.keys(diff).length < 1) {
      this._diff = undefined;
      return undefined;
    }

    this._diff = Object.freeze(diff);
    if (logDiff) {
      this.logDiff();
    }

    return diff;
  };

  logDiff() {
    const { _diff } = this;
    if (_diff == null) {
      console.log(String(_diff));
      return;
    }

    Object.entries(_diff)
      .map(([key, { previous, current }]) => {
        const output = [
          key,
          // The String constructor is used in case the value is nullish
          this.valueToString(previous),
          '\u2192',
          this.valueToString(current),
        ].join(' ');

        return _.isObject(current) ? [output, current] : [output];
      })
      .forEach((e) => console.log(...e));
  }

  private valueToString = (value: any) => {
    if (_.isString(value)) {
      return `"${value}"`;
    }

    return String(value);
  }
}

type DiffCheckerOptions = {
  // The number of decimal places to use when comparing floating point numbers.
  floatPrecision?: number;
}
