<div class="card">
    <div class="card-header">
        <h4 class="card-title">SMS Templates</h4>
    </div>
    <div class="card-body">
        <div class="accordion" *ngIf="smsAlertsEnabled; else elseBlock">
            <div class="accordion-item">
                <h5 class="accordion-header m-0">
                    <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                            data-bs-target="#bit" aria-expanded="true" aria-controls="collapse1">
                        Borrower Invite Template <span *ngIf="definedSmsTemplate.inviteOverride === true" class="text-success ms-1">- (Overridden)</span>
                    </button>
                </h5>
                <div id="bit" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <div class="form-group mb-3 row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-10">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" id="sms-invite-override" type="checkbox"
                                           [(ngModel)]="definedSmsTemplate.inviteOverride">
                                    <label class="form-check-label" for="sms-invite-override">Override Company Borrower
                                        Invite Template</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.inviteOverride">
                            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end"
                                   [innerHTML]="'You can use ${RegistrationLink} to auto merge the borrower registration link.'"></label>
                            <div class="col-sm-10">
                                <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.inviteSms"
                                    (contextmenu)="onContextMenu($event, definedSmsTemplate, 'inviteSms')"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h5 class="accordion-header m-0">
                    <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                            data-bs-target="#ait" aria-expanded="true" aria-controls="collapse1">
                        Agent Invite Template <span *ngIf="definedSmsTemplate.inviteAgentOverride === true" class="text-success ms-1">- (Overridden)</span>
                    </button>
                </h5>
                <div id="ait" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <div class="form-group mb-3 row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-10">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="sms-invite-agent-override"
                                           [(ngModel)]="definedSmsTemplate.inviteAgentOverride">
                                    <label class="form-check-label" for="sms-invite-agent-override">Override Company Agent Invite
                                        Template</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.inviteAgentOverride">
                            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end"
                                   [innerHTML]="'You can use ${RegistrationLink} to auto merge the agent registration link.'">
                            </label>
                            <div class="col-sm-10">
                                <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.inviteAgentSms"
                                    (contextmenu)="onContextMenu($event, definedSmsTemplate, 'inviteAgentSms')"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h5 class="accordion-header m-0">
                    <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                            data-bs-target="#trt" aria-expanded="true" aria-controls="collapse1">
                        Task Request / Status Update Template <span *ngIf="definedSmsTemplate.requestOverride === true" class="text-success ms-1">- (Overridden)</span>
                    </button>
                </h5>
                <div id="trt" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <div class="form-group mb-3 row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-10">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="sms-request-override"
                                           [(ngModel)]="definedSmsTemplate.requestOverride">
                                    <label class="form-check-label" for="sms-request-override">Override Company Request
                                        Template</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.requestOverride">
                            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end"></label>
                            <div class="col-sm-10">
                                <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.requestSms"
                                    (contextmenu)="onContextMenu($event, definedSmsTemplate, 'requestSms')"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h5 class="accordion-header m-0">
                    <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                            data-bs-target="#tmt" aria-expanded="true" aria-controls="collapse1">
                        Task Reminder Template <span *ngIf="definedSmsTemplate.reminderOverride === true" class="text-success ms-1">- (Overridden)</span>
                    </button>
                </h5>
                <div id="tmt" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <ng-template [ngIf]="userProfile.reminderSmsEnabled.value != 1" [ngIfElse]="smsReminderContent">
                            <div class="alert alert-info border-0 m-0" role="alert">
                                Reminder Sms is not enabled at the company level
                            </div>
                        </ng-template>

                        <ng-template #smsReminderContent>
                            <div class="form-group mb-3 row">
                                <div class="col-sm-2"></div>
                                <div class="col-sm-10">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="sms-reminder-override"
                                               [(ngModel)]="definedSmsTemplate.reminderOverride">
                                        <label class="form-check-label" for="sms-reminder-override">Override Company Agent Invite
                                            Template</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.reminderOverride">
                                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">
                                </label>
                                <div class="col-sm-10">
                                    <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.reminderSms"
                                        (contextmenu)="onContextMenu($event, definedSmsTemplate, 'reminderSms')"></textarea>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #elseBlock>
            <h4 class="fw-light">Call to enable this feature!</h4>
        </ng-template>
    </div>
</div>

<merge-field-context-menu [menuItems]="availableMergeFields">
</merge-field-context-menu>