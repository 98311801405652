import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Profile, UserProfile} from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DefinedSmsTemplate } from 'src/app/modules/sms-configuration/models';
import { MergeFieldContextMenuComponent } from 'src/app/shared/components/merge-field-context-menu/merge-field-context-menu.component';

@Component({
    selector: 'app-sms-templates',
    templateUrl: './sms-templates.component.html',
    styleUrls: ['./sms-templates.component.scss']
})
export class SmsTemplatesComponent {
    @Input() userProfile: Profile;
    @Input() definedSmsTemplate;
    @Input() smsAlertsEnabled: boolean;
    @Input() availableMergeFields: EnumerationItem[];

    @ViewChild(MergeFieldContextMenuComponent) mergeFieldContextMenu: MergeFieldContextMenuComponent;

    constructor() {}

    onContextMenu($event: MouseEvent, target: DefinedSmsTemplate, targetProperty: string): void {
        this.mergeFieldContextMenu.show(
            $event,
            target,
            targetProperty,
            null
        );
        $event.preventDefault();
        $event.stopPropagation();
    }
}
