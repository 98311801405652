import { Component, Injector, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { LeadCampaign } from 'src/app/models/config/global-config.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
@Component({
  selector: 'lead-lead-source-or-compaign',
  templateUrl: './lead-lead-source-or-compaign.component.html',
  styleUrls: ['./lead-lead-source-or-compaign.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadLeadSourceOrCompaignComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() lead: Lead;
  @Input() isDrawer: boolean = false;

  leadCampaigns: LeadCampaign[] = [];
  isAdmin: boolean = false;

  constructor(private readonly injector: Injector) {
    super(injector)
  }

  ngOnInit(): void {

    this.applicationContextService.context.subscribe(context => {
      this.leadCampaigns = _.orderBy(context.globalConfig.leadCampaigns.filter(x=>x.active || x.leadCampaignId == this.lead.leadCampaignId), (o) => o.name.toLowerCase());
      this.isAdmin = context.userPermissions.admin;
    });

  }

  leadCampaignChanged = (campaignId: number): void => {

    let campaign = this.leadCampaigns.find(x => x.leadCampaignId == campaignId);

    if (campaign) {
      this.lead.leadSource = campaign.leadSourceName;
    }

  }

}
