<form #upsertSubStatusForm="ngForm" ngbAutofocus name="subStatusForm" id="subStatusForm">
  <div class="p-3">
    <div class="mb-3">
      <label class="control-label" for="subStatusName">Sub Status</label>
      <input type='text' class="form-control"
        [ngClass]="{ 'is-invalid' : subStatusName && subStatusName.touched && subStatusName.invalid }"
        name="subStatusName" [(ngModel)]="subStatus.subStatusName" #subStatusName="ngModel" required />
      <div class="invalid-feedback">Sub status name is required.</div>
    </div>
    <div class="mb-3">
      <label class="control-label">Select By Role</label>
      <ng-select width="100%" name="selectByRole" bindLabel="text" bindValue="id" class="custom-ng-select"
        placeholder="Select Roles" [closeOnSelect]="false" [(ngModel)]="multiselectSelectByRoleModel" [multiple]="true"
        [items]="multiselectSelectByRole" appendTo="body">
      </ng-select>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-check form-switch form-switch-success">
              <input class="form-check-input" type="checkbox" name="isSuspenseSubStatus" id="isSuspenseSubStatus"
                [(ngModel)]="subStatus.isSuspenseSubStatus">
              <label class="form-check-label" for="isSuspenseSubStatus">Is Suspend Sub Status</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-check form-switch form-switch-success">
              <input class="form-check-input" type="checkbox" name="hideFromCounter" id="hideFromCounter"
                [(ngModel)]="subStatus.hideFromCounter">
              <label class="form-check-label" for="hideFromCounter">Hide From Counter</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end">
      <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
        Close
      </button>
      <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" style="width: 75px"
        [disabled]="saving">
        <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
        Save
      </button>
    </div>
  </div>
</form>