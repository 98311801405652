import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

type CaseType = 'upper' | 'lower' | 'both';
export function caseSizeValidator(type: CaseType): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (type === 'upper') {
      const regexp = /[A-Z]/g;
      const isValid = !control.value || regexp.test(control.value);
      return isValid ? null : { uppercase: { value: control.value } };
    } 

    if (type === 'lower') {
      const regexp = /[a-z]/g;
      const isValid = !control.value || regexp.test(control.value);
      return isValid ? null : { lowercase: { value: control.value } };
    }

    if (type === 'both') {
      const lowercaseRegexp = /[a-z]/g;
      const uppercaseRegexp = /[A-Z]/g;

      const isLowercaseValid = !control.value || lowercaseRegexp.test(control.value);
      const isUppercaseValid = !control.value || uppercaseRegexp.test(control.value);

      if (isLowercaseValid && isUppercaseValid) {
        return null;
      }

      const result = {}
      if (!isLowercaseValid) {
        result['lowercase'] = { value: control.value }
      }
      if (!isUppercaseValid) {
        result['uppercase'] = { value: control.value }
      }
      return result;
    }
  };
}

@Directive({
  selector: '[caseSize]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CaseSizeValidatorDirective,
      multi: true,
    },
  ],
})
export class CaseSizeValidatorDirective implements Validator {
  @Input('caseSize')
  caseSize: CaseType;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.caseSize ? caseSizeValidator(this.caseSize)(control) : null;
  }
}
