<div class="fieldset-title">Change Borrower Password</div>
<div class="pt-3">
    <form class="form-horizontal auth-form" #changePasswordForm="ngForm" novalidate id="changePasswordForm"
        name="changePasswordForm" action="index.html">
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="username">Borrower Email Address</label>
            </div>
            <div class="col-md-4">
                <input type="email" autocomplete="off" class="form-control" name="username" id="username"
                    placeholder="Enter Borrower Email" #userName="ngModel"
                    [ngClass]="{'is-invalid' : userName && userName.touched && userName.invalid}" required
                    email trim
                    [(ngModel)]="changePasswordRequest.borrowerEmail" email>
                <div class="invalid-feedback">Please enter Borrower's email address.</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="newPassword">New Password</label>
            </div>
            <div class="col-md-4">
                <input type="password" class="form-control" id="newPassword" name="newPassword"
                    [ngClass]="{'is-invalid' : newPass && newPass.touched && newPass.invalid}" required
                    [(ngModel)]="changePasswordRequest.newPassword" #newPass="ngModel" placeholder="New Password">
                <div class="invalid-feedback">Please enter your new password.</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="confirmPassword">Confirm New Password</label>
            </div>
            <div class="col-md-4">
                <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                    [ngClass]="{'is-invalid' : confirmPass && confirmPass.touched && confirmPass.invalid}"
                    pattern="{{changePasswordRequest.newPassword}}" required
                    [(ngModel)]="changePasswordRequest.confirmPassword" #confirmPass="ngModel"
                    placeholder="Confirm New Password">
                <div class="invalid-feedback">Please confirm your new password.</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6 text-end">
                <button class="btn btn-primary waves-effect waves-light" type="button" [disabled]="submitting"
                    (click)="onChangePasswordClicked()">
                    <span *ngIf="submitting" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Change Password
                </button>
            </div>
        </div>
    </form>
</div>
