<div class="input-group"
  [ngClass]="{ 'is-invalid': (model && model.invalid && initiallyInvalid) || (model && model.touched && model.invalid) }"
  [hidden]="editorMode === 'InlineReadOnly' || disabled ||
      (editorMode === 'Inline' && !isEditActive && !(this.model && this.model.touched && this.model.invalid))">
  <input class="form-control is-invalid no-right-border" *ngIf="initiallyInvalid"
    [ngClass]="{ 'disabled bg-transparent': readonly }" (click)="handleInitErrorValueClick()"
    id="{{ id }}_container" name="{{ name }}_container" [(ngModel)]="value" readonly />
  <input [hidden]="initiallyInvalid" id="{{ id }}" #control name="{{ name }}" class="form-control no-right-border"
    placeholder="{{placeholder}}" maxlength="10" [ngClass]="{
      'is-invalid': model && model.touched && model.invalid,
      'ng-invalid': model && model.touched && model.invalid
    }" [(ngModel)]="value" [required]="required" [readonly]="readonly" (dateSelect)="dateSelected()"
    (ngModelChange)="onDateChange($event)" firstDayOfWeek="7" #model="ngModel" #d="ngbDatepicker"
    ngbDatepicker [container]="getContainer()" [maxDate]="{ year: 2100, month: 1, day: 1 }" [minDate]="{ year: 1900, month: 1, day: 1 }"
    (keyup)="applyDateMask($event)" (blur)="onBlurred($event)" (focus)="isEditActive = true" />
  <button id="calendar-button" class="btn btn-sm btn-outline-primary" type='button' (click)="d.toggle()" [disabled]="readonly">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
      class="feather feather-calendar align-self-center icon-xs">
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6"></line>
      <line x1="3" y1="10" x2="21" y2="10"></line>
    </svg>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Apply" type="button" *ngIf="editorMode == 'Inline'"
    (mousedown)="onApplyClicked($event)">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Cancel" type="button" *ngIf="editorMode == 'Inline'"
    (mousedown)="onCancelClicked($event)">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
</div>

<span [ngClass]="{
    'empty-field': value == null || value == undefined
  }" class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" title="Click to Edit" *ngIf="editorMode == 'Inline' && !isEditActive && !disabled" (click)="onEditModeToggledOn()">
  <span class="val" *ngIf="value">{{value | date : inlineDateFormat}}</span>
  <span class="placeholder" *ngIf="!value">--</span>
</span>

<span [ngClass]="{
    'empty-field': value == null || value == undefined
  }" class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" *ngIf="editorMode == 'InlineReadOnly' || disabled">
  <span class="val fw-bold" *ngIf="value">{{value | date : inlineDateFormat}}</span>
  <span class="placeholder" *ngIf="!value">--</span>
</span>

<ng-container *ngIf="!!model && ((editorMode === 'Inline' && isEditActive) || editorMode === 'Classic')">
  <div *ngIf="model.touched && model.errors && model.errors.required" class="invalid-feedback">
    This field is required
  </div>
  <div *ngIf="model.touched && model.errors && model.errors.invalid" class="invalid-feedback">
    This field is invalid
  </div>
  <div *ngIf="model.touched && model.errors && model.errors.max" class="invalid-feedback">
    Date cannot be greater than {{ max | date: 'MM.dd.yyyy' }}
  </div>
  <div *ngIf="model.touched && model.errors && model.errors.min" class="invalid-feedback">
    Date cannot be smaller than {{ min | date: 'MM.dd.yyyy' }}
  </div>
</ng-container>
