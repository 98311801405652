import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanApplication } from 'src/app/models';
import { LockRepriceResponse } from '../../models/pricing/lock-reprice-response.model';
import { Price, PricedProductRow } from '../../models/pricing/priced-product-row.model';
import { PricedScenario } from '../pricing-search-v2/pricing-search-v2.component';

@Component({
  selector: 'app-reprice-lock-changes-dialog',
  templateUrl: './reprice-lock-changes-dialog.component.html',
  styleUrls: ['./reprice-lock-changes-dialog.component.scss']
})
export class RepriceLockChangesDialogComponent implements OnInit {

  @Input()
  currentApplication: LoanApplication;

  @Input()
  selection: {product: PricedProductRow, price: Price, pricedScenario: PricedScenario};

  @Input()
  data: LockRepriceResponse;

  @Input()
  isReprice: boolean = true;

  discountRebatePercent: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.discountRebatePercent = 100 - this.currentApplication?.productPricing?.price;
  }

}
