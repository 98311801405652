import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataService } from "src/app/core/services/data.service";
import { DocumentSigningOrder } from "../../loan-docs/models/document-signing-order.model";
import { DocuSignEnvelope } from "../models/docusign-envelope.model";
import { FilePackageUpload } from "../models/file-package-upload.model";

@Injectable()
export class ESignatureService {

  constructor(private readonly _dataService: DataService) {
  }

  getSigningOrdersForApplication = (applicationId: number): Observable<DocumentSigningOrder[]> => {
    const url = `api/Signing/application/${applicationId}/orders`;
    return this._dataService.get(url);
  }

  loadDocuSignData = (thirdPartyOrderId: string): Observable<DocuSignEnvelope> => {
    const url = `api/Signing/Envelope/${thirdPartyOrderId}`;
    return this._dataService.get(url);
  }

  viewDocusignDocument = (thirdPartyOrderId: string, documentId: number): Observable<string> => {
    const url = `api/Signing/Envelope/${thirdPartyOrderId}/documents/${documentId}/content`;
    return this._dataService.get(url);
  }

  getFilePackageUploads = (filePackageId: number): Observable<FilePackageUpload[]> => {
    const url = `api/DocSign/package/${filePackageId}/uploads`;
    return this._dataService.get(url);
  }

  getFilePackageUpload = (filePackageUploadId: number): Observable<FilePackageUpload> => {
    const url = `api/DocSign/package/uploads/${filePackageUploadId}`;
    return this._dataService.get(url);
  }

  downloadSignedDocument = (loanDocTaskId: number): Observable<BlobPart> => {
    const url = `api/Signing/order/${loanDocTaskId}/documents/combined/content/view`;
    return this._dataService.downloadBinaryFile(url).pipe(map((result: any) => {
      return result.body;
    }));
  }
  esignCancelOrder(loanDocTaskId: number, reason: string) {
    const queryParam = reason ? `?cancelReason=${reason}` : '';
    return this._dataService.delete(`api/Signing/tasks/${loanDocTaskId}/order${queryParam}`)
  }
  refresh(loanDocTaskId: number) {
    return this._dataService.post(`api/Signing/order/${loanDocTaskId}/refresh`, {})
  }
}
