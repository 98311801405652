import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeService } from 'src/app/services/fee.service';

@Component({
  selector: 'fee-summary-card',
  templateUrl: 'fee-summary-card.component.html'
})
export class FeeSummaryCardComponent implements OnInit, AfterViewInit {

  @Input()
  fees: LoanFee[] = [];

  @Input()
  feeSection: FeeSectionEnum;

  @Input()
  index: number;

  @Input()
  cardHeights = {}

  sectionDisplayName: string = "";

  totalAmount: number = 0;

  constructor(private readonly _feeService: FeeService) { }

  ngOnInit() {
    this.sectionDisplayName = this._feeService.getShortFeeSectionDisplayName(this.feeSection);

    this.fees.forEach(fee => {
      this.totalAmount += (fee.calculatedValues?.totalFee || 0);
    })
  }

  ngAfterViewInit() {
    let element = document.getElementById('fees' + this.index);
    let cardHeight = element.clientHeight;
    this.cardHeights[this.index] = cardHeight + 32;

    if (this.index % 2) {
      if ( this.cardHeights[this.index] > this.cardHeights[this.index-1]) {
        if ((this.cardHeights[this.index] < 340)) {
          this.cardHeights[this.index-1] = this.cardHeights[this.index];
        }
        else if (this.cardHeights[this.index-1] < 340) {
          this.cardHeights[this.index-1] = 340;
        }      
      } else {
        if ((this.cardHeights[this.index-1] < 340)) {
          this.cardHeights[this.index] = this.cardHeights[this.index-1];
        }
        else if (this.cardHeights[this.index] < 340) {
          this.cardHeights[this.index] = 340;
        }
      }
    }
  }
}
