import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmploymentTypeEnum } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BorrowerCalculatedStats, UrlaBorrower } from 'src/app/modules/urla/models/urla-mortgage.model';
import { EmploymentIncomeInDetailComponent } from '../employment-income-in-detail/employment-income-in-detail.component';
import { OtherIncomeInDetailComponent } from '../other-income-in-detail/other-income-in-detail.component';
import { PayPeriod } from 'src/app/shared/utils/pay-period/pay-period.utils';

@Component({
  selector: 'borrower-income',
  templateUrl: 'borrower-income.component.html',
  styleUrls: ['./borrower-income.component.scss']
})
export class BorrowerIncomeComponent implements OnInit {

  @ViewChild("employmentInDetail") employmentInDetail: EmploymentIncomeInDetailComponent;
  @ViewChild("otherInDetail") otherInDetail: OtherIncomeInDetailComponent;

  @Input()
  borrower: UrlaBorrower;

  @Output()
  totalIncomeChanged: EventEmitter<any> = new EventEmitter<any>();

  incomePayPeriods: EnumerationItem[] = [];

  totalOtherIncome: number = 0;
  totalEmploymentIncome: number = 0;
  grandTotalEmploymentIncome: number = 0;

  showEmploymentsMonthlyIncome: boolean = false;
  showOtherIncome: boolean = false;
  isSelfEmployed: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.checkSelfEmployed();
    this.calculateTotalIncome();
  }

  static setBorrowerDefaults(borrower: UrlaBorrower) {
    BorrowerIncomeComponent.setBorrowerTotalIncome(borrower);
  }

  onAddEmploymentClicked = () => {
    this.showEmploymentsMonthlyIncome = true;
    setTimeout(() => {
      this.employmentInDetail.addEmployment();
    });
  }

  onAddOtherIncomeClicked = () => {
    this.showOtherIncome = true;
    setTimeout(() => {
      this.otherInDetail.addOtherIncome();
    });
  }

  incomesChanged = () => {
    this.calculateTotalIncome();
    this.checkSelfEmployed();
    this.totalIncomeChanged.emit();
  }

  private static calculateTotalIncome(borrower: UrlaBorrower): {
    totalEmploymentIncome: number,
    totalOtherIncome: number,
  } {
    const sum = (a: number | undefined, b: number | undefined): number =>
      (a ?? 0) + (b ?? 0);

    const currentEmployments = borrower.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    let totalEmploymentIncome = 0;
    currentEmployments.forEach(e => {
      let employmentIncome: number = 0;
      if (e.incomes && e.incomes.length > 0) {
        employmentIncome = e.incomes.map(i => i.monthlyIncome).reduce(sum) || 0;
      }
      totalEmploymentIncome += employmentIncome;
      if (e.selfEmployed) {
        totalEmploymentIncome += e.selfEmploymentMonthlyIncomeOrLoss || 0;
      }
    });
    let totalOtherIncome = 0;

    borrower.nonEmploymentIncomes?.forEach(income => {
      if (!income.payPeriod) {
        income.payPeriod = PayPeriod.Monthly;
        income.amount = income.monthlyIncome;
      }
      if (!income.monthlyIncome) {
        income.monthlyIncome = 0;
      }
      totalOtherIncome += income.monthlyIncome;
    })

    return { totalEmploymentIncome, totalOtherIncome };
  }

  private static setBorrowerTotalIncome(borrower: UrlaBorrower) {
    const {
      totalOtherIncome,
      totalEmploymentIncome
    } = BorrowerIncomeComponent.calculateTotalIncome(borrower);

    borrower.calculatedStats ??= new BorrowerCalculatedStats();
    borrower.calculatedStats.totalMonthlyIncome = totalOtherIncome + totalEmploymentIncome;

    return { totalOtherIncome, totalEmploymentIncome }
  }

  private setDisplayTotalIncome = (totalEmploymentIncome: number, totalOtherIncome: number) => {
    this.totalEmploymentIncome = totalEmploymentIncome;
    this.totalOtherIncome = totalOtherIncome;
  }

  private calculateTotalIncome = () => {
    const { totalOtherIncome, totalEmploymentIncome } = BorrowerIncomeComponent.setBorrowerTotalIncome(this.borrower);
    this.setDisplayTotalIncome(totalEmploymentIncome, totalOtherIncome);
  }

  private checkSelfEmployed = () => {
    if (this.borrower.employments) {
      this.isSelfEmployed = this.borrower.employments.some(employment => employment.selfEmployed);
    }
  }
}
