import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { MenuItemStatus } from '../../models/enums/menu-item-status.enum';
@Component({
  selector: 'menu-item',
  templateUrl: 'menu-item.component.html',
  styleUrls: ['menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements OnInit {

  private _status: MenuItemStatus = MenuItemStatus.None;
  private _isActive: boolean = false;

  @ViewChild('template', { static: true }) _template: any;

  @Input()
  id: string;

  @Input()
  label: string;

  @Input()
  groupId: string;

  @Input()
  link: string;

  @Input()
  hidden: string;

  @Input()
  hiddenIcon: boolean;

  @Input()
  set isActive(isActive: boolean) {
    this._isActive = isActive;
    this._changeDetectorRef.detectChanges();
  }

  get isActive(): boolean {
    return this._isActive;
  }

  @Output()
  menuItemClicked: EventEmitter<string> = new EventEmitter<string>();

  isError: boolean = false;
  isSuccess: boolean = false;
  isPending: boolean = false;
  isLoading: boolean = false;

  @Input()
  set status(status: MenuItemStatus) {
    this._status = status;
    this.isError = (this._status === MenuItemStatus.Error);
    this.isSuccess = (this._status === MenuItemStatus.Success);
    this.isPending = (this._status === MenuItemStatus.Pending);
    this.isLoading = (this._status === MenuItemStatus.Loading);
    this._changeDetectorRef.detectChanges();
  };

  get status(): MenuItemStatus {
    return this._status;
  }

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private readonly _viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    this._viewContainerRef.createEmbeddedView(this._template);
  }

  onMenuItemClicked = () => {
    this.menuItemClicked.emit(this.id);
  }
}
