<div class="card-body">
  <div class="row">
    <div class="form-group offset-lg-3 col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="householdNetIncome"> Total Net Income </label>
      <currency-input id="householdNetIncome" name="householdNetIncome" [(ngModel)]="totalNetHouseholdIncome"
        (ngModelChange)="calculateIncomes()" readonly="true">
      </currency-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="taxRateOfHouseholdNetIncome" class="label-tax"> Tax Rate </label>

      <percent-input class="tax-rates" name="taxRateOfHouseholdNetIncome" [(ngModel)]="incomeTaxRate" [rateIsTrueToTextValue]="false"
        (ngModelChange)="calculateIncomes()">
      </percent-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <span class="text-primary d-flex pt-4"> (Total Gross Income X .{{(1 - incomeTaxRate) | number}})
      </span>
    </div>
  </div>
  <div class="row">
    <div class="form-group offset-lg-3 col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="totalMoExpenses"> Total Monthly Expenses </label>
      <currency-input id="totalMoExpenses" name="totalMoExpenses" [(ngModel)]="a_thru_f" readonly="true">
      </currency-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="taxRateOfTotalMoExpenses"> % of Net </label>
      <percent-input class="tax-rates" name="taxRateOfTotalMoExpenses" [rateIsTrueToTextValue]="false"
        [(ngModel)]="ratioOfTotalMonthlyExpensesToTotalNetIncome" readonly="true">
      </percent-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <span class="text-primary d-flex pt-4"> (A thru F) </span>
    </div>
  </div>
  <div class="row">
    <div class="form-group offset-lg-3 col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="nonMortgageExpenses" class="text-danger"> Non Mortgage Expenses </label>

      <currency-input id="nonMortgageExpenses" name="nonMortgageExpenses" [(ngModel)]="c_thru_f" readonly="true">
      </currency-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="taxRateOfNonMortgageExpenses"> % of Net </label>
      <percent-input class="tax-rates" name="taxRateOfNonMortgageExpenses" [rateIsTrueToTextValue]="false"
        [(ngModel)]="ratioOfNonMortgageExpensesToTotalNetIncome" readonly="true">
      </percent-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <span class="text-primary d-flex pt-4"> (C thru F) </span>
    </div>
  </div>
  <div class="row">
    <div class="form-group offset-lg-3 col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="residualIncome"> Residual Income </label>
      <currency-input id="residualIncome" name="residualIncome" [(ngModel)]="residualIncome" readonly="true">
      </currency-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
      <label for="taxRateOfResidualIncome"> % of Net </label>
      <percent-input class="tax-rates" name="taxRateOfResidualIncome" [rateIsTrueToTextValue]="false"
        [(ngModel)]="ratioOfResidualIncomeToTotalNetIncome" readonly="true">
      </percent-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <span class="text-primary d-flex pt-4"> (Total Net Income - Total Mo. Expenses) </span>
    </div>
  </div>
</div>