<div class="modal-header">
  <h6 class="modal-title">Reference LDE Loan</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
    (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-612">
      <div class="mb-3 row">
        <label class="col-sm-6 form-label align-self-center mb-lg-0 text-end">LDE Loan Number</label>
        <div class="col-sm-6">
          <input class="form-control" id="ldeLoanNumber" name="ldeLoanNumber" type="text" [(ngModel)]="ldeLoanNumber">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="isSaving || (!ldeRefNumber && !ldeLoanNumber)">
          <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ ldeRefNumber && !ldeLoanNumber ? 'Clear Reference' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</div>