import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { DataService } from "src/app/core/services/data.service";
import { ExpressionValidationResult } from "src/app/models/expressions/expression-validation-result.model";
import { Operator } from "src/app/models/expressions/operator.model";

@Injectable()
export class FunctionalExpressionService {

    constructor(private _dataService: DataService) {
    }

    getOperators = (): Observable<Operator[]> => {
      const url = `api/expressions/operators`;
      return this._dataService.get(url);
    }

    parse = (expression: string): Observable<ExpressionValidationResult> => {
      const encodedExpression = encodeURIComponent(expression);
      const url = `api/expressions/parse?expression=${encodedExpression}`;
      return this._dataService.post(url, {});
    }

}
