<div class="modal-header">
  <h5 class="modal-title"> Link Loan to Lead </h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <p-table #dt1 [columns]="selectedColumns" [value]="filteredLeads" [paginator]="true" [rows]="25"
      name="lead-list-table-v2" [rowsPerPageOptions]="[25, 50, 100]" [globalFilterFields]="globalFilterFields"
      [autoLayout]="true" [showCurrentPageReport]="true" styleClass="p-datatable-gridlines p-datatable-sm"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" scrollable="true"
      scrollHeight="50vh" [selectionPageOnly]="true" stateStorage="local" stateKey="link-to-lead-table-state"
      sortMode="multiple">
      <ng-template pTemplate="caption">
        <div class="row mb-1">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <div>
              <button class="btn btn-primary" type="button" (click)="onSearchAgain()">Search Again</button>
            </div>
            <div>
              <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl
                defaultLabel="Choose Columns"></p-multiSelect>

              <span class="p-input-icon-right ms-2 h-100">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="form-control h-100" [(ngModel)]="globalSearchString"
                  (ngModelChange)="onGlobalSearchStringChanged($event)" placeholder="Search keyword" />
              </span>
            </div>


          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="justify-content-center">
            <span class="ms-2"> Action </span>
          </th>
          <th *ngFor="let col of getSortedColumns()" [pSortableColumn]="col.field" style="max-width: 120px;">
            {{ col.header }}
            <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="index">
        <tr [pSelectableRow]="rowData">
          <td>
            <span>
              <p-tableRadioButton [value]="rowData" (click)="onSelectLead(rowData)">
              </p-tableRadioButton>
            </span>

            <br />
            <span class="text-muted text-ellipsis cell-mw150"
              title="{{rowData.loanPurposeName}} {{rowData.loanTypeName ? ('- ' + rowData.loanTypeName) : ''}}">{{rowData.loanPurposeName}}
              {{rowData.loanTypeName ? ('- ' + rowData.loanTypeName) : ''}}</span>
          </td>
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <span *ngSwitchCase="'lastName'">
              <span class="text-cell-primary">{{ rowData['displayName'] }}</span>
              <br />
              <span class="text-muted text-ellipsis cell-mw200">{{ rowData.subjectPropertyAddress1 || '--'
                }}
                {{rowData.subjectPropertyCity}}{{rowData.subjectPropertyCity ? ',' : ''}}
                {{rowData.subjectPropertyState ? rowData.subjectPropertyState.toUpperCase() : ''}}
                {{rowData.subjectPropertyZip}}</span>
            </span>

            <span *ngSwitchCase="'mobilePhone'">
              <table-cell-contact-info [contactInfo]="rowData" [recordType]="'Lead'" [recordId]="rowData.leadId"
                [dialerEnabled]="dialerEnabled" [isLinksDisabled]="true"></table-cell-contact-info>
            </span>

            <span *ngSwitchCase="'leadStatusId'">
              <span class="text-cell-primary">{{ rowData['leadStatusName'] }}</span>
              <br><i class="fa fa-sticky-note-o"></i>
              <span class="text-muted text-ellpisis">{{rowData.mostRecentNote}}</span>
            </span>

            <span *ngSwitchCase="'leadContactUserId'">
              <span class="text-cell-primary">{{ rowData['leadContactName'] }}</span>
              <br />
              <span class="text-muted">On: {{ rowData['leadContactAssignmentDate'] | date: 'short'
                }}</span>
            </span>

            <div *ngSwitchCase="'leadListIds'" style="max-width: max-content">
              <span *ngIf="rowData['referralSourceName']" class="text-cell-primary">{{
                rowData['referralSourceName']
                }}</span>
              <br *ngIf="rowData['referralSourceName']" />
              <span class="badge rounded-pill m-1 row" style="background-color: #8f4397; float: left"
                *ngFor="let leadListId of rowData['leadListIds']">
                {{ getLeadListNameById(leadListId) }}
              </span>
            </div>

            <span *ngSwitchCase="'dateInserted'">
              <span> {{ rowData['dateInserted'] | date: 'short' }} </span>
            </span>

            <span class="text-cell-primary" *ngSwitchDefault>
              {{ rowData[col.field] }}
            </span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedColumns.length + 1" class="text-center">
            There are no leads for the selected filters
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <div *ngIf="selectedLead" class="row d-block">
    <div class="mt-2">
      <span>
        Lead <strong> {{selectedLead.name}} </strong> will be linked to this application.
      </span>
    </div>
    <div class="form-check form-check-inline ms-2" [ngClass]="{'mt-2': selectedLead}">
      <input id="copyReferralSourceInfo" name="copyReferralSourceInfo" class="form-check-input" type="checkbox"
        [(ngModel)]="request.copyReferralSourceInfo">
      <label class="form-check-label" for="copyReferralSourceInfo"> Copy the Referral Source from Lead
        to Application </label>
    </div>
    <div class="form-check form-check-inline ms-2 mt-2">
      <input id="copyLeadCampaignInfoFromLead" name="copyLeadCampaignInfoFromLead" class="form-check-input"
        type="checkbox" [(ngModel)]="request.copyLeadCampaignInfoFromLead">
      <label class="form-check-label" for="copyLeadCampaignInfoFromLead"> Copy Lead Campaign Info from Lead to
        Application </label>
    </div>
    <div class="form-check form-check-inline ms-2 mt-2">
      <input id="copyLeadActivityFromLead" name="copyLeadActivityFromLead" class="form-check-input" type="checkbox"
        [(ngModel)]="request.copyLeadActivityFromLead">
      <label class="form-check-label" for="copyLeadActivityFromLead"> Copy Lead Activity from Lead to
        Application </label>
    </div>
    <div class="form-check form-check-inline ms-2 mt-2">
      <input id="copyCustomDataFromLead" name="copyCustomDataFromLead" class="form-check-input" type="checkbox"
        [(ngModel)]="request.copyCustomDataFromLead">
      <label class="form-check-label" for="copyCustomDataFromLead"> Copy Custom Data from Lead to
        Application </label>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()" [disabled]="!selectedLead"> Save
  </button>
</div>
