import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Configuration } from '../../../../../models/configuration.model';
import { ThirdPartyCredential, ThirdPartyCredentialType, ThirdPartyKeyValue } from '../../../../../models';
import { SystemLevelService } from '../../../../../services/system-level.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Constants } from 'src/app/services/constants';
import { ErnstProviderEditorDialogComponent } from './ernst-provider-editor-dialog/ernst-provider-editor-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeeService } from 'src/app/services/fee.service';
import { FeeProviderPickerDialogComponent } from './fee-provider-picker-dialog/fee-provider-picker-dialog.component';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LodestarProviderEditorDialogComponent } from './lodestar-provider-editor-dialog/lodestar-provider-editor-dialog.component';
import { forkJoin } from 'rxjs';
import { MortgageCTOProviderEditorDialogComponent } from './mcto-provider-editor-dialog/mcto-provider-editor-dialog.component';

@Component({
  selector: 'fees',
  templateUrl: './fees.component.html'
})
export class FeesComponent implements OnInit {

  @Input() ernstFeesEnabled: Configuration;
  @Input() lodestarFeesEnabled: Configuration;
  @Input() mctoFeesEnabled: Configuration;

  @Input() defaultFeeProvider: Configuration;

  @Input() providerCredentials: ThirdPartyCredential[] = [];
  @Input() documentTypes: DocumentType[] = [];

  @Input() defaultFeeProviderVal: string;

  @Output()
  defaultProviderChanged: EventEmitter<string> = new EventEmitter<string>();

  isSaving: boolean;

  feeProviders: EnumerationItem[] = [];
  missingFeeProviders: EnumerationItem[] = [];

  constructor(
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _feesService: FeeService,
    private readonly _enumerationService: EnumerationService
  ) { }

  ngOnInit(): void {
    if (!this.defaultFeeProvider) {
      this.defaultFeeProvider = new Configuration("DefaultFeeProvider");
      this.defaultFeeProvider.valueStr = this.defaultFeeProviderVal;
    }
    this._enumerationService.getFeeEnumerations().subscribe(enums => {
      // Why isn't fee providers in the enums coming back from the API?
      this.loadVendors();
    })
  }

  openFeeEditor = (e: ThirdPartyCredential) => {
    const ModalComponent = this.getProviderModalComponent(e.vendorName as ProviderType);
    const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
    modalRef.componentInstance.vendor = _.cloneDeep(e);
    modalRef.componentInstance.documentTypes = this.documentTypes;

    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        let existingVendorIndex = this.providerCredentials.findIndex(v => v.credentialId == result.credentialId);
        if (existingVendorIndex >= 0) {
          this.providerCredentials[existingVendorIndex] = result;
        }
      },
      () => { }
    );
  }

  onSelectedDefaultFeeProviderChanged = () => {
    this._spinner.show();
    this._systemLevelService.saveConfiguration(this.defaultFeeProvider).subscribe({
      next: res => {
        this._spinner.hide();
        this.defaultProviderChanged.emit(this.defaultFeeProvider.valueStr);
        this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
      },
      error: error => {
        this._spinner.hide();
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      }
    });
  }

  onErnstEnabledStatusChanged = () => {
    const observer = {
      next: (result => {
        this._spinner.hide();
        this.loadVendors();
      }),
      error: (error => {
        this._spinner.hide();
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      })
    }
    this._spinner.show();
    this._systemLevelService.saveConfiguration(this.ernstFeesEnabled).subscribe(observer);
  }

  onLodestarEnabledStatusChanged = () => {
    const observer = {
      next: (result => {
        this._spinner.hide();
        this.loadVendors();
      }),
      error: (error => {
        this._spinner.hide();
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      })
    }
    this._spinner.show();
    this._systemLevelService.saveConfiguration(this.lodestarFeesEnabled).subscribe(observer);
  }

  onMCTOEnabledStatusChanged = () => {
    const observer = {
      next: (result => {
        this._spinner.hide();
        this.loadVendors();
      }),
      error: (error => {
        this._spinner.hide();
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      })
    }
    this._spinner.show();
    this._systemLevelService.saveConfiguration(this.mctoFeesEnabled).subscribe(observer);
  }

  openProviderPicker = () => {
    const modalRef = this._modalService.open(FeeProviderPickerDialogComponent, { ...Constants.modalOptions.medium, scrollable: false });
    modalRef.componentInstance.missingFeeProviders = this.missingFeeProviders;

    modalRef.result.then(
      (selectedProvider: string) => {
        const ModalComponent = this.getProviderModalComponent(selectedProvider as ProviderType);
        const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
        const vendor = new ThirdPartyCredential(ThirdPartyCredentialType.Fees, selectedProvider);
        modalRef.componentInstance.vendor = vendor;
        modalRef.componentInstance.documentTypes = this.documentTypes;
        modalRef.componentInstance.inEditMode = false;

        modalRef.result.then(
          (result: ThirdPartyCredential) => {
            this.providerCredentials.push(result);
            this.populateDefaultFeeProviders();
            const vendorIndex = this.missingFeeProviders.findIndex(fp => fp.name === result.vendorName);
            if (vendorIndex >= 0) {
              this.missingFeeProviders.splice(vendorIndex, 1);
            }
          },
          () => { }
        );
      },
      () => { }
    );
  }

  private loadVendors = () => {
    this.missingFeeProviders = [];
    const observer = {
      next: ((vendorNames: string[]) => {
        this._spinner.hide();

        const allFeeProviders = this._enumerationService.feeProviders.filter(p => vendorNames.includes(p.value));

        const apiCallsToCheckEnabledStatus = {};
        allFeeProviders.forEach(provider => {
          apiCallsToCheckEnabledStatus[provider.value] = this._feesService.isVendorEnabled(provider.value, true);
        });

        const enabledStatusCheckObserver = {
          next: ((enabledStatuses: string[]) => {
            this._spinner.hide();
            for (let i = allFeeProviders.length - 1; i >= 0; i--) {
              if (!enabledStatuses[allFeeProviders[i].value]) {
                const indexOfProviderOnVendorDropdown = this.feeProviders.findIndex(v => v.name === allFeeProviders[i].value);
                if (indexOfProviderOnVendorDropdown >= 0) {
                  this.feeProviders.splice(indexOfProviderOnVendorDropdown, 1);
                }
              } else {
                let providerCredential = this.providerCredentials.find(v => v.vendorName == allFeeProviders[i].value);
                if (!providerCredential) {
                  const alreadyExists = this.missingFeeProviders.some(fp => fp.name === allFeeProviders[i].value);
                  if (alreadyExists) {
                    return;
                  }
                  const missingProvider = new EnumerationItem(allFeeProviders[i].value, allFeeProviders[i].value);
                  this.missingFeeProviders.push(missingProvider);
                }
              }
            }
          }),
          error: ((error: any) => {
            this._spinner.hide();
          })
        }

        if (Object.keys(apiCallsToCheckEnabledStatus).length) {
          this._spinner.show();
          forkJoin(apiCallsToCheckEnabledStatus).subscribe(enabledStatusCheckObserver);
        }
      }),
      error: ((error: any) => {
        this._spinner.hide();
        this._notificationService.showError(error.error ? error.error.message : "An error occurred while getting fee providers.", "Error!");
      })
    }
    this._spinner.show();
    this.populateDefaultFeeProviders();
    this._feesService.getSupportedFeeProviders().subscribe(observer);
  }

  trackByValue(index, vendor) {
    return vendor.value;
  }

  private populateDefaultFeeProviders() {
    this.feeProviders = _.chain(this.providerCredentials)
      .map(v => ({
        name: v.vendorName,
        value: v.vendorName,
      }))
      .uniqBy(v => v.value)
      .value();
  }

  private getProviderModalComponent = (type: ProviderType) => {
    if (type === "Ernst") return ErnstProviderEditorDialogComponent
    if (type === "Lodestar") return LodestarProviderEditorDialogComponent
    if (type === "MortgageCTO") return MortgageCTOProviderEditorDialogComponent
  }
}

export type ProviderType = "Ernst" | "Lodestar" | "MortgageCTO";

export class LodestarKeyValuePairs {
  clientName: ThirdPartyKeyValue = new ThirdPartyKeyValue("ClientName", null);
  savedDocumentTypeId: ThirdPartyKeyValue = new ThirdPartyKeyValue("SavedDocumentTypeId", null);
  importSellerPaidFees: ThirdPartyKeyValue = new ThirdPartyKeyValue("ImportSellerPaidFees", null);
  autoSelectRequestPropertyTax: ThirdPartyKeyValue = new ThirdPartyKeyValue("AutoSelectRequestPropertyTax", null);
}
