import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { LdeService } from 'src/app/services/lde.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'lde-loan-reference',
  templateUrl: 'lde-loan-reference.component.html'
})

export class LdeLoanReferenceComponent implements OnInit {
  @Input()
  applicationId: number;

  @Input()
  ldeRefNumber: string;

  isSaving = false;
  ldeLoanNumber: string;

  constructor(public activeModal: NgbActiveModal,
    private readonly _ldeService: LdeService,
    private readonly _notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.ldeLoanNumber = _.clone(this.ldeRefNumber);
  }

  save() {
    this.isSaving = true;
    this._ldeService.reference(this.applicationId, this.ldeLoanNumber).subscribe((response) => {
      if (!this.ldeLoanNumber) {
        this._notificationService.showSuccess('Reference number cleared successfully.', 'Success');
      }
      else {
        this._notificationService.showSuccess('Reference number saved successfully.', 'Success');
      }
      this.isSaving = false;
      this.activeModal.close(response.ldeRefNumber);
    }, error => {
      this._notificationService.showError(error ? error.message : 'Couldn\'t save the refernce number.', 'Error');
      this.isSaving = false;
    });
  }
}
