<div class="card p-3">
    <div class="card-header">
        <div class="card-title">
            Documents:
            <a *ngIf="!isReadonly" class="add-doc-btn" (click)="openLoanDocDialog()">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Add Documents
            </a>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table mb-0 table-centered">
                <thead class="table-light text-center">
                    <tr>
                        <th> Loan Doc </th>
                        <th> Appraisal Doc Type </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let doc of selectedDocuments">
                        <td class="text-left">
                            <span class="text-dark">
                                <i class="fa text-primary" [class]="doc.isPdf ? 'fa-file-pdf' : 'fa-file'"
                                    aria-hidden="true"></i>
                                {{doc.fileName}}
                            </span>
                            <span class="date-inner-wrap">{{doc.creationDate}}</span>
                        </td>
                        <td>
                            <select class="form-select" name="appraisalDocType-{{doc.docFileId}}" required
                                #appDocType="ngModel" [(ngModel)]="doc.appraisalDocumentType"
                                [ngClass]="{'is-invalid': appDocType && appDocType.touched && appDocType.invalid}">
                                <option value="">-- Select One --</option>
                                <option [value]="type.value" *ngFor="let type of appraisalDocTypes"
                                    title="{{type.name}}">
                                    {{type.name}}
                                </option>
                            </select>
                        </td>
                        <td>
                          <div class="d-flex">
                            <a class="file-action" title="View" (click)="viewDocument(doc)">
                                <span *ngIf="doc.docFileGuid != loadingDocFileGuid" class='fa fa-eye'></span>
                                <span *ngIf="doc.docFileGuid === loadingDocFileGuid"
                                    class="spinner-border spinner-border-sm"></span>
                            </a>
                            <a *ngIf="!isReadonly" class="file-action text-danger" title="Delete" (click)="removeDoc(doc.docFileGuid)">
                                <i class='fa fa-trash'></i>
                            </a>
                          </div>
                        </td>
                    </tr>
                    <tr *ngIf="selectedDocuments.length === 0">
                        <td colspan="3" class="empty-docs">
                            <em>No document found. Add document by clicking "Add Document" button.</em>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
