import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { MortgageCallReportService } from '../../../services/mortgage-call-report.service';
import { AuditDataValues, McrFullAuditData } from '../../reports/mortgage-call/models/mcr-audit-data.model';
import { Utils } from 'src/app/core/services/utils';
import { getQuarterEnumDisplayName } from '../../reports/mortgage-call/models/quarter-enum.model';
import { DiffChecker } from 'src/utils/diff-checker';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { from } from 'rxjs/internal/observable/from';

@Component({
  selector: 'edit-mcr-audit-data',
  templateUrl: './edit-mcr-audit-data.component.html',
  styleUrls: ['./edit-mcr-audit-data.component.scss']
})
export class EditMcrAuditDataComponent implements OnInit {

  @ViewChild("mcrAuditForm") mcrAuditForm: NgForm | undefined;

  @Input()
  set mcrAuditData(data: McrFullAuditData) {
    if (data) {
      this._mcrAuditData = data;
      if (!this._mcrAuditData.validationResults) {
        this._mcrAuditData.validationResults = [];
      }
      if (!this._mcrAuditData.auditData.manual) {
        this._mcrAuditData.auditData.manual = { pipelinePositions: {} } as AuditDataValues;
      }
      if (!this._mcrAuditData.auditData.calculated) {
        this._mcrAuditData.auditData.calculated = { pipelinePositions: {} } as AuditDataValues;
      }

      this.year = this._mcrAuditData.auditData.year;
      this.quarter = getQuarterEnumDisplayName(this._mcrAuditData.auditData.quarter);
      this.initialize();
    }
  }

  get mcrAuditData(): McrFullAuditData {
    return this._mcrAuditData;
  }

  @Output() saveFinished = new EventEmitter<McrFullAuditData>();
  @Output() exit = new EventEmitter<any>();

  isSaving: boolean = false;

  loanTypes: EnumerationItem[] = [];
  loanPurposes: EnumerationItem[] = [];
  pipelinePositions: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];
  lienTypes: EnumerationItem[] = [];
  mortgageDirections: EnumerationItem[] = [];
  reverseMortgageTypes: EnumerationItem[] = [];
  reverseMortgagePurposes: EnumerationItem[] = [];
  qualifiedMortgageTypes: EnumerationItem[] = [];
  mortgageServicingDispositions: EnumerationItem[] = [];
  channels: EnumerationItem[] = [];
  states: EnumerationItem[] = [];

  lockFields = {};
  invalidFields = {};

  values = new AuditDataValues();
  overriddenValues = new AuditDataValues();

  year: number;
  quarter: string;

  private _mcrAuditData: McrFullAuditData = new McrFullAuditData();
  private _pristineAuditDataValues: AuditDataValues | null = null;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _mortgageCallReportServie: MortgageCallReportService,
    private readonly _contextService: ApplicationContextService,
    private readonly _notifyService: NotificationService,
    private readonly _enumerationService: EnumerationService
  ) { }

  ngOnInit(): void {

    this.loanTypes = this._enumerationService.mcrLoanTypes;
    this.loanPurposes = this._enumerationService.mcrLoanPurposes;
    this.pipelinePositions = this._enumerationService.mcrApplicationPipelinePositions.map(e => {
      e.value = Utils.lowerCaseFirstLetter(e.value); // first character must be lowercase for matching
      return e;
    });
    this.propertyTypes = this._enumerationService.mcrPropertyTypes;
    this.lienTypes = this._enumerationService.mcrLienTypes;
    this.mortgageDirections = this._enumerationService.mcrMortgageDirections;
    this.reverseMortgageTypes = this._enumerationService.mcrReverseMortgageTypes;
    this.reverseMortgagePurposes = this._enumerationService.mcrReverseMortgagePurposes;
    this.qualifiedMortgageTypes = this._enumerationService.mcrQualifiedMortgageTypes;
    this.mortgageServicingDispositions = this._enumerationService.mcrMortgageServicingDispositions;
    this.channels = this._enumerationService.mcrChannels;

    this._contextService.context.subscribe(context => {
      this.states = Object.keys(context.globalConfig.states).map(s => new EnumerationItem(context.globalConfig.states[s], s));
    })

  }

  save = () => {
    if (!this.mcrAuditForm) return;
    this.mcrAuditForm.form.markAllAsTouched();
    if (!this.mcrAuditForm.form.valid) return;

    this.isSaving = true;

    // omit both locked and overrided fields
    Object.keys(this.overriddenValues)
      .forEach(key => {
        if (key == "pipelinePositions") {
          Object.keys(this.overriddenValues.pipelinePositions)
            .forEach(key2 => {
              if (this.lockFields["pipelinePositions." + key2]) {
                this.overriddenValues.pipelinePositions[key2] = null;
              }
            })
        }
        else {
          if (this.lockFields[key]) {
            this.overriddenValues[key] = null;
          }
        }
      });

    // add unlocked but modified fields to overriddens
    Object.keys(this.values)
      .forEach(key => {
        if (key == "pipelinePositions") {
          Object.keys(this.values.pipelinePositions)
            .forEach(key2 => {
              if (!this.lockFields["pipelinePositions." + key2]) {
                this.overriddenValues.pipelinePositions[key2] = this.values.pipelinePositions[key2];
              }
            })
        }
        else {
          if (!this.lockFields[key]) {
            this.overriddenValues[key] = this.values[key];
          }
        }
      })

    if (!this.values.excludeFromReport) {
      this.overriddenValues.excludeFromReport = null;
    }
    else {
      this.overriddenValues.excludeFromReport = true;
    }

    this._spinner.show();
    this._mortgageCallReportServie.saveAuditData(this._mcrAuditData.auditData.applicationId, this._mcrAuditData.auditData.year, this._mcrAuditData.auditData.quarter, this.overriddenValues)
      .subscribe({
        next: (result) => {
          this._mcrAuditData.auditData = result;
          this.saveFinished.emit(this._mcrAuditData);
          this.isSaving = false;
          this.initialize();
          this._spinner.hide();
          this._notifyService.showSuccess("MCR Audit Data saved succesfully", 'Success');
        },
        error: (err) => {
          this._notifyService.showError(err.error.message || err.error || "Error", 'Error-Saving Audit Data');
          this.isSaving = false;
          this._spinner.hide();
        }
      });
  }

  lockUnlockEdit = (fieldName: string) => {
    this.lockFields[fieldName] = !this.lockFields[fieldName];

    if (fieldName.includes("pipelinePositions.")) {
      let key = fieldName.split("pipelinePositions.")[1];
      if (this.lockFields[fieldName]) {
        if (this.values.pipelinePositions[key] != this._mcrAuditData.auditData.calculated.pipelinePositions[key]) {
          this.overriddenValues.pipelinePositions[key] = this.values.pipelinePositions[key];
        }
        this.values.pipelinePositions[key] = this._mcrAuditData.auditData.calculated.pipelinePositions[key];
      }
      else {
        if (this.overriddenValues.pipelinePositions[key] != null && this.overriddenValues.pipelinePositions[key] != undefined && this.values.pipelinePositions[key] != this.overriddenValues.pipelinePositions[key]) {
          this.values.pipelinePositions[key] = this.overriddenValues.pipelinePositions[key];
        }
      }
    }
    else {
      if (this.lockFields[fieldName]) {
        if (this.values[fieldName] != this._mcrAuditData.auditData.calculated[fieldName]) {
          this.overriddenValues[fieldName] = this.values[fieldName];
        }
        this.values[fieldName] = this._mcrAuditData.auditData.calculated[fieldName];
      }
      else {
        if (this.overriddenValues[fieldName] != null && this.overriddenValues[fieldName] != undefined && this.values[fieldName] != this.overriddenValues[fieldName]) {
          this.values[fieldName] = this.overriddenValues[fieldName];
        }
      }
    }
  }

  backClicked = async () => {
    if (this.checkIfMCRIsDirty()) {
      await from(this.showNotSavedDialog());
    }
    else {
      this.exit.emit();
    }
  }

  private checkIfMCRIsDirty = () => {
    const diffChecker = new DiffChecker(
      this._pristineAuditDataValues,
      this.values,
      'auditDataValues',
      { floatPrecision: 2 },
    );

    return diffChecker.calculateDiff(true) != null;
  }

  // return closing
  private showNotSavedDialog = async (): Promise<boolean> => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'Are you sure?',
      text: `You have changes that are not saved. Would you like to save your changes? If you choose 'Exit without saving' below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Exit!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Exit without saving!`,
      reverseButtons: true
    });

    if (result.isDenied){
      this.exit.emit();
      return true;
    }

    if (!result.isConfirmed){
      return false;
    }

    await this.save();

    return true;
  }

  private initialize = () => {
    Object.keys(this.values)
      .forEach(key => {

        // control for pipelinePositions
        if (key == "pipelinePositions") {

          const validationErrorsForField = this._mcrAuditData.validationResults
            .find(vr => vr.memberNames.map(field => field.toLowerCase()).includes('pipelinePositions'.toLocaleLowerCase()));
          if (validationErrorsForField) {
            this.invalidFields['pipelinePositions'] = validationErrorsForField.errorMessage;
          }

          Object.keys(this.values.pipelinePositions)
            .forEach(key2 => {
              if (Object.keys(this._mcrAuditData.auditData.manual.pipelinePositions).includes(key2)) {
                this.values.pipelinePositions[key2] = this._mcrAuditData.auditData.manual.pipelinePositions[key2];
                this.lockFields['pipelinePositions.' + key2] = false;
              }
              else if (Object.keys(this._mcrAuditData.auditData.calculated.pipelinePositions).includes(key2)) {
                this.values.pipelinePositions[key2] = this._mcrAuditData.auditData.calculated.pipelinePositions[key2];
                this.lockFields['pipelinePositions.' + key2] = true;
              }
            });
        }
        else {
          const validationErrorsForField = this._mcrAuditData.validationResults
            .find(vr => vr.memberNames.map(field => field.toLowerCase()).includes(key.toLocaleLowerCase()));
          if (validationErrorsForField) {
            this.invalidFields[key] = validationErrorsForField.errorMessage;
          }

          if (Object.keys(this._mcrAuditData.auditData.manual).includes(key)) {
            this.values[key] = this._mcrAuditData.auditData.manual[key];
            this.lockFields[key] = false;
          }
          else if (Object.keys(this._mcrAuditData.auditData.calculated).includes(key)) {
            this.values[key] = this._mcrAuditData.auditData.calculated[key];
            this.lockFields[key] = true;
          }
        }

      });

    this._pristineAuditDataValues = _.cloneDeep(this.values);
  }
}
