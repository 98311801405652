<p-table
    #dt1
    [columns]="columns"
    [value]="services"
    [paginator]="true"
    [rows]="25"
    responsiveLayout="scroll"
    name="global-service-table-v2"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="globalFilterFields"
    [autoLayout]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines"
    sortMode="single"
    sortField="name"
>
    <ng-template pTemplate="caption">
        <div class="d-flex justify-content-between">
      <span>
        <button
            type="button"
            class="btn btn-primary me-2"
            (click)="onAddEdit()"
        >
          <i class="fas fa-plus"></i> Add New
        </button>
      </span>

            <span class="ms-auto">

        <span class="p-input-icon-right ms-2 h-100">
          <i class="pi pi-search"></i>
          <input
              pInputText
              type="text"
              class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              (ngModelChange)="dt1.first=0"
              placeholder="Search keyword"
          />
        </span>
      </span>
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th style="width: 7rem; text-align: center">
                <span>Action</span>
            </th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <span
        >{{ col.header }}
            <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon
            ></span>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
        <tr>
            <td>
                <span class="d-flex align-items-center justify-content-center w-100">
                  <span>
                    <i
                        class="fa fa-pencil-alt cursor text-primary"
                        (click)="onAddEdit(row)"
                        title="Edit"
                    ></i>
                  </span>
                    <span class="mx-2"> | </span>

                  <span>
                    <i
                        *ngIf="!isDeleteClicked[row.telephonyServiceId]"
                        class="fa fa-trash-alt cursor text-danger"
                        (click)="isDeleteClicked[row.telephonyServiceId] = true"
                        title="Delete"
                    ></i>

                    <i
                        *ngIf="isDeleteClicked[row.telephonyServiceId]"
                        class="fa fa-times cursor text-danger me-2"
                        (click)="isDeleteClicked[row.telephonyServiceId] = false"
                        title="Cancel"
                    ></i>

                    <i
                        *ngIf="isDeleteClicked[row.telephonyServiceId]"
                        class="fa fa-check-circle cursor text-danger"
                        (click)="onDelete(row)"
                        title="Delete"
                    ></i>
                  </span>
                </span>
            </td>
            <td *ngFor="let col of columns" [ngSwitch]="col.field">

                <span *ngSwitchDefault>
          {{ row[col.field] }}
        </span>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3" class="text-center">
                {{ !isLoading ? 'There are no tags.' : 'Loading...' }}
            </td>
        </tr>
    </ng-template>
</p-table>
