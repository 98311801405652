export class MergeDocFilesInstruction {
    sourceDocFileGuid: string;
    destinationDocFileGuid: string;
    destinationLoanDocId: number;
    destinationLoanDocTaskId: number;
    documentTypeId: number;
    description: string;
    fileName: string;
    note: string;
    borrowerNote: string;
    condition: boolean;
    mergeMode: string;
    allPages: boolean;
    customPages: number[];
    removePagesFromSource: boolean;

    constructor(sourceDocFileGuid: string) {
        this.sourceDocFileGuid = sourceDocFileGuid;
    }
}