<div class="card-body" *ngIf="processSubmissionData" style="min-height:400px;">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <p class="card-subtitle font-20 my-3">Please select all the borrower characteristics that apply</p>
            <div class="row mt-3">
                <div class="col-md-{{(processSubmissionData.mortgageViewModel.borrowers.length > 3 ? 4 : 6)}}"></div>
                <div class="col-md-{{(processSubmissionData.mortgageViewModel.borrowers.length > 3 ? 1 : 2)}} text-center"
                    *ngFor="let borrower of processSubmissionData.mortgageViewModel.borrowers; trackBy:index">
                    <strong>{{borrower.firstName}} {{borrower.lastName}}</strong>
                </div>
            </div>
            <div class="row" *ngFor="let bc of allowedBorrowerCharacterstics">
                <div class="col-md-{{(processSubmissionData.mortgageViewModel.borrowers.length > 3 ? 4 : 6)}}">
                    <label [disabled]="bc.taskOption === 'RequestQuantity'">
                        <span *ngIf="isCharacteristicCheckedForAnyBorrower(bc.taskCategoryId)">
                            <strong>{{bc.questionText ? (bc.questionText.indexOf("?") > -1 ? 
                                bc.questionText : bc.questionText + '?') : 
                                bc.taskCategoryName}} 
                            </strong>
                        </span>
                        <span *ngIf="!isCharacteristicCheckedForAnyBorrower(bc.taskCategoryId)">
                            <strong>{{bc.questionText ? (bc.questionText.indexOf("?") > -1 ? 
                                bc.questionText : bc.questionText + '?') : 
                                bc.taskCategoryName}} 
                            </strong>
                        </span>
                    </label>
                </div>
                <div class="col-md-{{(processSubmissionData.mortgageViewModel.borrowers.length > 3 ? 1 : 2)}} text-center"
                    *ngFor="let borrower of processSubmissionData.mortgageViewModel.borrowers; let index = index">
                    <label>
                        <!-- TODO: [checked] -->
                        <input class="form-check-input" type="checkbox" value="{{borrower}}"
                            (click)="toggleBorrowerCharacteristic(bc.taskCategoryId, index)"
                            [checked]="isBorrowerCharacteristicCheckedForBorrower(bc.taskCategoryId, index)"
                            [disabled]="bc.taskOption === 'RequestQuantity'">
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center">
    <button class="btn btn-primary me-2" (click)="back()">Back</button>
    <button id="new-app-borrower-char-proceed-btn" class="btn btn-primary" (click)="proceed()">Proceed To Final Review</button>
</div>
