<ng-container *ngIf="!isLoading; else loadingContent">
<div class="row">
    <div class="col-md-12">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" [ngClass]="{ 'active': currentTab === 'general-settings' }"
                    (click)="currentTab = 'general-settings'">
                    General Settings
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active': currentTab === 'e-consent-settings' }"
                    (click)="currentTab = 'e-consent-settings'">
                    EConsent Settings
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active': currentTab === 'credit-auth-settings' }"
                    (click)="currentTab = 'credit-auth-settings'">
                    Credit Auth Settings
                </a>
            </li>
        </ul>

        <div class="tab-content">
            <div *ngIf="currentTab === 'general-settings'" class="pt-3">
                <general-settings [onlineApplicationSettings]="onlineApplicationSettings"
                    [creditVendorOptions]="creditVendorOptions"></general-settings>
            </div>
            <div *ngIf="currentTab === 'e-consent-settings'" class="pt-3">
                <e-consent-settings [onlineApplicationSettings]="onlineApplicationSettings"
                    [eConsentDocumentTemplates]="eConsentDocumentTemplates"></e-consent-settings>
            </div>
            <div *ngIf="currentTab === 'credit-auth-settings'" class="pt-3">
                <credit-auth-settings [onlineApplicationSettings]="onlineApplicationSettings"
                    [creditAuthDocumentTemplates]="creditAuthDocumentTemplates"></credit-auth-settings>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="text-end">
            <div class="col-md-12 my-1">
                <hr />
            </div>
            <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
                <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
                <span *ngIf="isSaving">
                    <span class="spinner-border spinner-border-sm"></span> Saving
                </span>
            </button>
        </div>
    </div>
</div>
</ng-container>
<ng-template #loadingContent>
    <loading-indicator loadingMessage="Loading Online App Settings..."
        [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>