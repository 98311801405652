<p-table [value]="feeComparisonRows" responsiveLayout="stack" [responsive]="true" rowGroupMode="subheader" groupRowsByOrder="0"
  groupRowsBy="section">
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width:200px">HUD#</th>
      <th style="min-width:300px; width:200px">Name</th>
      <th style="min-width:200px">Existing Fee</th>
      <th style="min-width:200px">Template Fee</th>
      <th style="min-width:200px">Calculated Fee</th>
      <th style="min-width:200px">Vendor Fee</th>
      <th style="min-width:200px">Final</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-fee>
    <tr pRowGroupHeader>
      <td colspan="7" style="background-color: #f8f9fa;">
        <span class="text-dark mb-0 fw-semibold">{{feeSectionDisplayNames[fee.section]}}</span>
        <span class="pull-right text-dark mb-0 fw-semibold">{{totalAmountBySection.get(fee.section) | currency}}</span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-fee let-rowIndex="rowIndex">
    <tr>
      <td title="This is a duplicate hud number." [ngClass]="{
        'duplicate-hud-cell': fee.isDuplicate
        }" style="min-width:200px;background-color: #f8f9fa;" class="text-dark mb-0 fw-semibold">
        <i *ngIf="fee.isDuplicate" class="fas fa-exclamation-triangle mx-1"></i>
        {{fee.hudNumber}}
      </td>
      <td style="min-width:300px">
        {{fee.selectedFee?.name || fee.name}}
      </td>
      <td style="min-width:200px"
        (click)="onFeeCellClicked(fee, 'existingLoanFee')"
        [ngClass]="{
          'selectable-fee-cell': !fee.vendorFee && fee.existingLoanFee,
          'selected-fee-cell': fee.existingLoanFee?.isSelected
          }">
        {{fee.existingLoanFee?.amountAndPayerDisplayText}}
      </td>
      <td style="min-width:200px" class="selectable-fee-cell"
        (click)="onFeeCellClicked(fee, 'templateFee')"
        [ngClass]="{
          'selectable-fee-cell': !fee.vendorFee && fee.templateFee,
          'selected-fee-cell': fee.templateFee?.isSelected
        }">
        {{fee.templateFee?.amountAndPayerDisplayText}}
      </td>
      <td style="min-width:200px"
        (click)="onFeeCellClicked(fee, 'calculatedLoanFee')"
        [ngClass]="{
          'selectable-fee-cell': !fee.vendorFee && fee.calculatedLoanFee,
          'selected-fee-cell': fee.calculatedLoanFee?.isSelected
        }">
        {{fee.calculatedLoanFee?.amountAndPayerDisplayText}}
      </td>
      <td style="min-width:200px"
        (click)="onFeeCellClicked(fee, 'vendorFee')"
        [ngClass]="{
          'locked-for-selection': fee.vendorFee,
          'selected-fee-cell': fee.vendorFee?.isSelected
          }">
        {{fee.vendorFee?.amountAndPayerDisplayText}}
      </td>
      <td style="min-width:200px">
        {{fee.selectedFeeAmountAndPayerDisplayText}}
      </td>
    </tr>
  </ng-template>
</p-table>
