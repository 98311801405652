<div class="modal-header">
  <h5 class="modal-title">Loan Status History</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <p-table #dt1 [value]="history" [paginator]="true" [rows]="25" *ngIf="history"
      [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines" sortField="dateInserted" [sortOrder]="-1">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'actionValue'">Loan Status
            <p-sortIcon [field]="'actionValue'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'subStatusActionValue'">Sub Status
            <p-sortIcon [field]="'subStatusActionValue'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'name'">By
            <p-sortIcon [field]="'name'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'dateInserted'">Date
            <p-sortIcon [field]="'dateInserted'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-historyItem>
        <tr>
          <td>{{historyItem.actionValue}}</td>
          <td>{{historyItem.subStatusActionValue}}</td>
          <td>{{historyItem['name']}}</td>
          <td>{{historyItem.dateInserted | date:'short'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')">Close</button>
      </div>
    </div>
  </div>
</div>
