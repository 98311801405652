import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { firstValueFrom, forkJoin } from 'rxjs';
import { formViewProvider } from "src/app/core/services/form-view.provider";
import { Configuration, CustomData, EmploymentTypeEnum, TypeOfIncomeEnum } from "src/app/models";
import { Constants } from "src/app/services/constants";
import { EnumerationService } from "src/app/services/enumeration-service";
import { NotificationService } from "src/app/services/notification.service";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { DuplicateDialogComponent } from "src/app/shared/components/duplicate-dialog/duplicate-dialog.component";
import { ImportLeadFromDuDialogComponent } from "./dialogs/import-lead-from-du-dialog/import-lead-from-du-dialog.component";
import { LeadAssignmentInfoComponent } from "./lead-assignment-info/lead-assignment-info.component";
import { map } from 'rxjs/operators';
import { LeadEmployment, LeadBorrowerEmployment } from "../../models/lead-employment.model";
import { Lead } from "../../models/lead.model";
import { ParseDuLeadResult } from "../../models/parse-du-lead.model";
import { LeadsService } from "../../services/leads.service";
import { Router } from "@angular/router";
import { LeadCustomDataComponent } from "../dialogs/view-lead-drawer/lead-actions/lead-custom-data/lead-custom-data.component";
import { LoanPurpose, TransactionType } from "src/app/models/config/loan-purpose.model";
import { DiffChecker } from "src/utils/diff-checker";
import { copyToClipboard } from "src/utils";
import { ConfigurationService } from "src/app/services/configuration.service";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { LeadUtilsService } from "../../services/lead-utils.service";

@Component({
  selector: 'lead-editor',
  templateUrl: './lead-editor.component.html',
  styleUrls: ['./lead-editor.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadEditorComponent extends ApplicationContextBoundComponent implements OnInit, OnChanges {

  @Input()
  lead: Lead

  @Input()
  isManualDial: boolean = false;

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newLeadAdded: EventEmitter<Lead> = new EventEmitter<Lead>();

  @Output()
  leadUpdated: EventEmitter<Lead> = new EventEmitter<Lead>();

  @Output()
  phoneClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('upsertLead')
  upsertLeadForm: NgForm | undefined;

  @ViewChild('leadAssignment')
  leadAssignmentComponent: LeadAssignmentInfoComponent;

  @ViewChild('leadCustomData')
  leadCustomDataComponent: LeadCustomDataComponent;

  isInitDataLoaded: boolean = false;
  isTpo: boolean = false;
  isSolar: boolean = false;
  

  showAssignmentInfo: boolean = true;
  showSubjectPropertyBody: boolean = true;
  showCurrentLoanInfoBody: boolean = true;
  showLoanDetailBody: boolean = true;
  showEmploymentBody: boolean = true;
  showCampaignLeadSourceBody: boolean = true;
  showCustomInfoBody: boolean = true;

  isSaving: boolean = false;
  isLoading: boolean = false;
  loadingEmployments: boolean = false;

  isSubjectPropertyAddressHidden: boolean = false;
  isAppraisedValueHidden: boolean = false;
  isSsnHidden: boolean = false;
  isCreditPullHidden: boolean = false;
  isPullCreditEnabled: boolean = false;
  showTags: boolean = false;

  companyName: string = null;

  originalLeadData: Lead;

  employments: LeadEmployment[] = [];

  employmentBorrower: LeadBorrowerEmployment = new LeadBorrowerEmployment();
  employmentCoBorrower: LeadBorrowerEmployment = new LeadBorrowerEmployment();

  protected isBankruptcy : boolean = false;
  protected coIsBankruptcy : boolean = false;
  protected bankruptcyTypeList: EnumerationItem[] = [];
  protected bestTimeToContactList: EnumerationItem[] = [];

  private _loanPurposes: LoanPurpose[] = [];

  constructor(
    private readonly _leadsService: LeadsService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _configurationService: ConfigurationService,
    private readonly _leadsUtilsService: LeadUtilsService,
    public router: Router,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.bankruptcyTypeList = enums[Constants.enumerations.bankruptcyTypes];
    });

    this.bestTimeToContactList = [
      { name: "None", value: 0 },
      { name: "Morning", value: 1 },
      { name: "Afternoon", value: 2},
      { name: "Evening", value: 4 }
    ];

    if (!this.lead) {
      this.lead = new Lead();
    }
    // make lower case states in case lead has a different casing. in that case the dropdown will not select the state.
    this.lead.presentState = this.lead.presentState?.toLowerCase();
    this.lead.mailingState = this.lead.mailingState?.toLowerCase();
    this.lead.subjectPropertyState = this.lead.subjectPropertyState?.toLowerCase();
    this.lead.coMailingState = this.lead.coMailingState?.toLowerCase();
    this.lead.coPresentState = this.lead.coPresentState?.toLowerCase();

    await this.getConfigItems();

    const context = await firstValueFrom(this.applicationContextService.context);
    const company = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId);
    this.companyName = company.companyName;
    this._loanPurposes = context.globalConfig.loanPurpose || [];

    this.isTpo = context.isTpo;
    this.isSolar = this._loanPurposes.find(x =>
      x.loanPurposeId == context?.application?.loanPurposeId
      || x.loanPurposeId === this.lead.loanPurposeId
    )?.transactionType == TransactionType.Solar;

    if (!this.lead.leadId) {
      if (this.isTpo) {
        this.lead.leadContactUserId = null;
        this.lead.insideReferralSource = context.currentlyLoggedInUser.userCompanyGuid;
      } else {
        this.lead.leadContactUserId = context.currentlyLoggedInUser.userCompanyGuid;
      }
    } else {
      await this.getDataForLead();
    }

    if (!this.lead.downPayment) {
      this.lead.downPayment = 0;
    }

    if (!this.lead.coBestTimeToContact) {
      this.lead.coBestTimeToContact = 0;
    }
    if (!this.lead.bestTimeToContact) {
      this.lead.bestTimeToContact = 0;
    }
    if (!this._leadsUtilsService.isPurchaseTransaction(this.lead.loanPurposeId, true)) {
      this.lead.downPayment = 0;
    }

    this.originalLeadData = _.cloneDeep(this.lead);
    this.isInitDataLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const leadStatusUpdateChange = changes['isLeadStatusUpdated'];
    if (leadStatusUpdateChange) {
      const isUpdated = leadStatusUpdateChange.currentValue;
      if (isUpdated) {
        if (this.originalLeadData) {
          this.originalLeadData.leadStatusId = this.lead.leadStatusId;
        }
      }
    }
  }

  cancelUpdates = () => {
    const currentLead = _.cloneDeep(this.originalLeadData);
    this.lead = currentLead;

    this.leadUpdated.emit(currentLead);
  }

  onLeadStatusSaved = () => {
    this.originalLeadData = _.cloneDeep(this.lead);
  }

  isDirty = () => {
    const diffChecker = new DiffChecker(
      this.originalLeadData,
      this.lead,
      'lead',
    );

    const ignoredFields = [
      'dateInserted', 'dateUpdated', 'insertedBy', 'updatedBy',
      'displayName', 'leadStatusName', 'leadCampaignName', 'loanPurposeName', 'loanTypeName', 'leadContactName', 'lastStatusDate', 'referralSourceName',
      'coAuthorizedCreditCheck', 'coAuthorizationMethod', 'coDateAuthorizedCreditCheck'
    ]

    if (!Object.keys(this.originalLeadData).includes(Constants.dirtyCheckIgnoreFieldsMetaDataField)) {
      Object.defineProperty(this.originalLeadData, Constants.dirtyCheckIgnoreFieldsMetaDataField, {
        value: ignoredFields,
        writable: true,
        configurable: true
      });
    }

    if (!Object.keys(this.lead).includes(Constants.dirtyCheckIgnoreFieldsMetaDataField)) {
      Object.defineProperty(this.lead, Constants.dirtyCheckIgnoreFieldsMetaDataField, {
        value: ignoredFields,
        writable: true,
        configurable: true
      });
    }

    return diffChecker.calculateDiff(true) != null || this.leadCustomDataComponent?.isDirty();
  }

  save = async (): Promise<void> => {
    if (!this.upsertLeadForm) return;
    if (!this.validate()) return;

    if (this.isTpo) {
      this.lead.leadContactUserId = null;
    }

    this.trimBorrowerLeadDetails();

    this.isSaving = true;
    if (this.lead.leadId) {
      return this.upsertLead(this.lead.leadId);
    } else {
      return this.addLead();
    }
  };

  upsertLead = async (leadId: number) => {
    this.isSaving = true;


    let observable = leadId
      ? this._leadsService.updateLead(this.lead)
      : this._leadsService.addLead(this.lead);

    try {
      const response = await firstValueFrom(
        observable.pipe(map((response) => {
          if (!response) {
            throw new Error(`Super admin cannot new borrower for ${this.companyName}`);
          }

          return { ...new Lead, ...response }
        }))
      );

      const preparedCustomData = this.leadCustomDataComponent?.prepareArrayFields();
      const customDataResponse = await firstValueFrom(this._leadsService.saveCustomLeadData(response.leadId, preparedCustomData))
      response.customData = customDataResponse;

      this.originalLeadData = _.cloneDeep(response);
      this.lead = _.cloneDeep(response);
      this.isSaving = false;

      if (leadId > 0) {
        // isUpdate
        this._notifyService.showSuccess('Lead updated!', 'Successful!');

        await this.saveLeadEmployments(leadId);

        return this.onLeadUpdated(response);
      } else {
        // Here we need to add the tags
        const apiCalls: any = {};
        this.leadAssignmentComponent.leadLists.forEach((leadList) => {
          apiCalls[leadList.leadListId] = this._leadsService.addLeadListById(
            response.leadId,
            leadList.leadListId
          );
        });
        if (Object.keys(apiCalls).length > 0) {
          this.isSaving = true;
          await firstValueFrom(forkJoin(apiCalls));
          this.isSaving = false;
          this._notifyService.showSuccess(
            'New lead added!',
            'Successful!'
          );
        }

        if (this.isTpo) {
          this.router.navigate(['tpo/dashboard']);
        } else {
          await this.saveLeadEmployments(response.leadId);

          this._leadsService.updateLeadsTable(response);
          this.newLeadAdded.emit(response);
        }
      }
    } catch (error) {
      this._notifyService.showError(
        error.error
          ? error.error.message
          : 'An error occurred while saving lead.',
        'Error'
      );
      this.isSaving = false;
    }
  };

  addLead = async () => {
    try {
      const dupeLeads: Lead[] = await firstValueFrom(this._leadsService.duplicateLead(this.lead));

      if (!dupeLeads || dupeLeads.length == 0) {
        return this.upsertLead(null); // add new
      }

      const modalRef = this._modalService.open(
        DuplicateDialogComponent,
        Constants.modalOptions.medium
      );

      let items = dupeLeads.map((lead) => ({
        id: lead.leadId,
        firstName: lead.firstName,
        lastName: lead.lastName,
        email: lead.email,
        dateInserted: lead.dateInserted,
      }));

      modalRef.componentInstance.items = _.orderBy(
        items,
        ['dateInserted'],
        ['asc']
      );
      modalRef.componentInstance.userType = 'lead';

      modalRef.result.then(
        (leadId) => {
          if (leadId > 0) {
            this.isSaving = false;
          } else {
            // add new
            return this.upsertLead(null);
          }
        },
        () => {
          // Modal cancel
          this.isSaving = false;
        }
      );
    } catch (e) {
      this.isSaving = false;
      this._notifyService.showError(e?.message || "Couldn't save leads.", 'Error!');
    }
  };

  copyLeadRefToClipboard = () => {
    copyToClipboard(this.lead.leadRefId);
    this._notifyService.showInfo("Link copied to clipboard", "Info");
  };

  openImportLeadModal = () => {
    let modalRef = this._modalService.open(
      ImportLeadFromDuDialogComponent,
      Constants.modalOptions.medium
    );

    modalRef.result.then(
      (response: ParseDuLeadResult) => {
        if (!response.leadViewModel.leadContactUserId) {
          response.leadViewModel.leadContactUserId =
            this.lead.leadContactUserId;
        }

        response.leadViewModel.subjectPropertyState = response.leadViewModel.subjectPropertyState?.toLocaleLowerCase() || null;
        response.leadViewModel.presentState = response.leadViewModel.presentState?.toLocaleLowerCase() || null;
        response.leadViewModel.mailingState = response.leadViewModel.mailingState?.toLocaleLowerCase() || null;
        response.leadViewModel.coMailingState = response.leadViewModel.coMailingState?.toLocaleLowerCase() || null;
        response.leadViewModel.coPresentState = response.leadViewModel.coPresentState?.toLocaleLowerCase() || null;

        this.lead = { ...response.leadViewModel };
      },
      () => { }
    );
  };

  onLeadUpdated = async (lead: Lead) => {
    if (this.isTpo) {
      this.router.navigate(['tpo/dashboard']);
    } else {
      await this.getEmploymentsForLead();
      this.leadUpdated.emit(lead);
    }
  };

  onCloseDrawer = () => {
    this.closeDrawer.emit();
  };

  public validate = (): boolean => {
    this.upsertLeadForm.form.markAllAsTouched();
    let firstInvalidOneId: string = null;

    const isValid = this.upsertLeadForm.form.valid;
    if (!isValid) {
      const keys = Object.keys(this.upsertLeadForm.form.controls);

      for (let i = 0; i <= keys.length - 1; i++) {
        const key = keys[i];
        if (this.upsertLeadForm.form.controls.hasOwnProperty(key)) {
          if (this.upsertLeadForm.form.controls[key].status === 'INVALID') {
            firstInvalidOneId = key;
            break;
          }
        }
      }
    }
    if (firstInvalidOneId) {
      this.scrollToElement(firstInvalidOneId);
    }
    return isValid;
  }

  dialInfoClicked = (e) => {
    this.phoneClicked.emit(e);
  }

  onLeadReloaded = () => this.initLead()

  onLoanPurposeChanged = (loanPurposeId: number) => {
    this.isSolar = this._loanPurposes.find(x => x.loanPurposeId == loanPurposeId)?.transactionType == TransactionType.Solar;
  }

  private initLead = async (): Promise<void> => {
    this.isLoading = true;

    try {
      const lead = await firstValueFrom(this._leadsService.getLead(this.lead.leadId));
      if (lead.leadId && lead.leadId > 0) {
        this.lead = lead;
        return this.getDataForLead();
      }

      throw new Error('Lead does not exist');
    } catch (e) {
      this._notifyService.showError(e?.message, 'Error!');
    } finally {
      this.isLoading = false;
    }
  }

  private scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const topPos = element.offsetTop;
      setTimeout(() => {
        document.getElementById('lead-editor-main').scrollTop = topPos - 10;
      }, 250);
    }
  };

  private trimBorrowerLeadDetails = () => {
    if (!this.lead) return;
    this.lead.firstName = this.lead.firstName?.trim();
    this.lead.lastName = this.lead.lastName?.trim();
    this.lead.email = this.lead.email?.trim();
  }

  private async getEmploymentsForLead(): Promise<void> {
    this.loadingEmployments = true;

    try {
      this.employments = (await firstValueFrom(this._leadsService.getLeadEmployments(this.lead.leadId))) || [];
      this.makeBorrowerEmployments();
    } catch (e) {
      this._notifyService.showError(e?.message || "Couldn't get employments for lead.", "Error!");
    } finally {
      this.loadingEmployments = false;
    }
  }

  // create lead borrower and co-borrower objects with employments
  private makeBorrowerEmployments = () => {
    this.employmentBorrower.employments = this.employments.filter(e => !e.isCoBorrowerEmployment && !this.filterNonEmploymentIncomes(e.typeOfIncome)).map(e => ({
      ...e,
      calculatedStats: {
        monthlyIncome: (e.monthlyIncome || 0) + (e.bonusIncome || 0) + (e.commissionsIncome || 0) + (e.overtimeIncome || 0)
      }
    }));
    this.employmentBorrower.nonEmploymentIncomes = this.employments.filter((e: LeadEmployment) => !e.isCoBorrowerEmployment && this.filterNonEmploymentIncomes(e.typeOfIncome)).map(e => ({
      ...e,
      calculatedStats: {
        monthlyIncome: (e.monthlyIncome || 0) + (e.bonusIncome || 0) + (e.commissionsIncome || 0) + (e.overtimeIncome || 0)
      }
    }));
    this.employmentBorrower.firstName = this.lead.firstName || "";
    this.employmentBorrower.lastName = this.lead.lastName || "";
    this.employmentBorrower.calculatedStats = {
      totalMonthlyIncome: this.employmentBorrower.employments.reduce((pV: number, cV: LeadEmployment) => pV + cV.monthlyIncome, 0),
    };

    this.employmentCoBorrower.employments = this.employments.filter(e => e.isCoBorrowerEmployment && !this.filterNonEmploymentIncomes(e.typeOfIncome)).map(e => ({
      ...e,
      calculatedStats: {
        monthlyIncome: (e.monthlyIncome || 0) + (e.bonusIncome || 0) + (e.commissionsIncome || 0) + (e.overtimeIncome || 0)
      }
    }));
    this.employmentCoBorrower.nonEmploymentIncomes = this.employments.filter((e: LeadEmployment) => e.isCoBorrowerEmployment && this.filterNonEmploymentIncomes(e.typeOfIncome)).map(e => ({
      ...e,
      calculatedStats: {
        monthlyIncome: (e.monthlyIncome || 0) + (e.bonusIncome || 0) + (e.commissionsIncome || 0) + (e.overtimeIncome || 0)
      }
    }));
    this.employmentCoBorrower.firstName = this.lead.coFirstName || "";
    this.employmentCoBorrower.lastName = this.lead.coLastName || "";
    this.employmentCoBorrower.calculatedStats = {
      totalMonthlyIncome: this.employmentCoBorrower.employments.reduce((pV: number, cV: LeadEmployment) => pV + cV.monthlyIncome, 0),
    };
  }

  private filterNonEmploymentIncomes = (incomeType: TypeOfIncomeEnum): boolean => {
    return !(incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime));
  }

  private saveLeadEmployments = async (leadId: number): Promise<void> => {
    await this.saveLeadBorrowerEmployment(leadId);

    if (this.lead.coFirstName || this.lead.coLastName) {
      return this.saveLeadCoBorrowerEmployment(leadId);
    }

    return this.removeLeadCoBorrowerEmployment(); // remove when coBorrower is removed
  }

  // private saveLeadCustomData = async (leadId: number): Promise<void> => {
  //   let preparedData = this.prepareArrayFields(this.customData);

  //   this._leadsService.saveCustomLeadData(this.lead.leadId, preparedData)
  //   .subscribe(customData => {
  //     this.lead.customData = customData;
  //   }, (err) => {
  //     this.isSaving = false;
  //     this._notifyService.showError("Error encountered while saving custom data","Error!");
  //   })
  // }


  private saveBorrowerEmployment = async (leadId: number, isCoBorrowerEmployment: boolean) => {
    const borrower = isCoBorrowerEmployment ? this.employmentCoBorrower : this.employmentBorrower;

    const requestEmploymentsForBorrower: LeadEmployment[] = borrower.employments.map(leadEmployment => ({
      leadId: leadId,
      leadEmploymentId: leadEmployment.leadEmploymentId ?? null,
      isCurrentEmployer: leadEmployment.employmentType === EmploymentTypeEnum.CurrentEmployer,
      selfEmploymentMonthlyIncomeOrLoss: leadEmployment.selfEmploymentMonthlyIncomeOrLoss,
      borrowerOwnershipShare: leadEmployment.borrowerOwnershipShare,
      isSelfEmployed: leadEmployment.isSelfEmployed,
      isCoBorrowerEmployment: isCoBorrowerEmployment,
      monthlyIncome: leadEmployment.monthlyIncome ?? 0,
      commissionsIncome: leadEmployment.commissionsIncome ?? 0,
      overtimeIncome: leadEmployment.overtimeIncome ?? 0,
      bonusIncome: leadEmployment.bonusIncome ?? 0,
      companyName: leadEmployment.companyName,
      typeOfIncome: TypeOfIncomeEnum.Base
    }));

    const mapEmployment = async (leadEmployment): Promise<void> => {
      if (leadEmployment.leadEmploymentId) {
        try {
          await firstValueFrom(this._leadsService.updateLeadEmployment(leadEmployment));
        } catch (e) {
          throw e.message ? e : new Error(`Couldn't update lead employment ${leadEmployment.leadEmploymentId}.`);
        }
      } else {
        try {
          const employment = await firstValueFrom(this._leadsService.addLeadEmployment(leadEmployment));
          leadEmployment.leadEmploymentId = employment.leadEmploymentId;
        } catch (e) {
          throw e.message ? e : new Error("Couldn't insert new lead employment.");
        }
      }
    };

    const mapNonEmploymentIncome = (leadEmployment) => {
      leadEmployment.leadId = leadId;
      leadEmployment.isCoBorrowerEmployment = isCoBorrowerEmployment;

      return mapEmployment(leadEmployment);
    }

    return Promise.all([
      ...requestEmploymentsForBorrower.map(mapEmployment),
      ...borrower.nonEmploymentIncomes.map(mapNonEmploymentIncome),
    ]).catch(e => this._notifyService.showError(e.message, "Error")) as Promise<void>;
  }

  private saveLeadBorrowerEmployment = async (leadId: number): Promise<void> => {
    return this.saveBorrowerEmployment(leadId, false);
  }

  private saveLeadCoBorrowerEmployment = (leadId: number) => {
    return this.saveBorrowerEmployment(leadId, true);
  }

  private removeLeadCoBorrowerEmployment = (): Promise<void> => {
    const { employments, nonEmploymentIncomes } = this.employmentCoBorrower;

    const mapper = (leadEmployment: LeadEmployment): Promise<void> =>
      firstValueFrom(this._leadsService.deleteLeadEmployment(leadEmployment.leadEmploymentId)).then(() => undefined);

    return Promise.all([
      ...employments.filter((leadEmployment) => leadEmployment.leadEmploymentId).map(mapper),
      ...nonEmploymentIncomes.filter((leadEmployment) => leadEmployment.leadEmploymentId).map(mapper),
    ]).then(() => undefined) as Promise<void>;
  };

  private getConfigItems = async () => {
    await firstValueFrom(this._configurationService.getCompanyConfiguration('LoanHiddenFields'))
      .then((loanHiddenFields: Configuration) => {
        const hiddenFields = loanHiddenFields ? loanHiddenFields['valueStr'] : '';

        this.isSubjectPropertyAddressHidden = hiddenFields.indexOf('Subject Property') > -1
        this.isAppraisedValueHidden = hiddenFields.indexOf('Appraised Value') > -1;
        this.isSsnHidden = hiddenFields.indexOf('Social Security Number') > -1;


      })
      .catch(() => {
      })
      .finally(() => {
      });

    await firstValueFrom(this._configurationService.getCompanyConfiguration('CreditReportingEnabled'))
      .then((cReportingEnabled: Configuration) => {
        let creditReportingEnabled = cReportingEnabled;

        if (this.lead.leadId) {
          this.isCreditPullHidden = this.isSsnHidden;
          if (!this.isCreditPullHidden) {
            this.isPullCreditEnabled = creditReportingEnabled.value == 1
          }
        }
      })
      .catch(() => {
      })
      .finally(() => {
      });
  }

  private getDataForLead = async () => {
    await this.getEmploymentsForLead();

    await firstValueFrom(this._leadsService.getCustomLeadData(this.lead.leadId))
      .then((resp: CustomData) => {
        this.lead.customData = { ...resp };
      })
      .catch(() => {
        this.lead.customData = new CustomData();
        this.lead.customData.leadId = this.lead.leadId;
      })
      .finally(() => {
        this.originalLeadData = _.cloneDeep(this.lead);
      });
  }
}
