<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarMiddle>
  <div class="h-100 d-flex align-items-center">
    <ng-container *ngIf="areChannelsEnabled">
      <generic-filter class="ms-2" [label]="'Select Channels'" [filterHeader]="'Channels'"
        [filterOptions]="channelsForFiltering" [type]="'singleSelect'"
        (filtersChanged)="onChannelFilterChanged($event)">
      </generic-filter>
    </ng-container>
  </div>
</ng-template>

<ng-template #pageActionBarRight>
  <button [disabled]="saving" type="button" class="btn btn-default" (click)="cancel()">
    Cancel
  </button>
  <button [disabled]="saving" type="button" class="btn btn-primary" (click)="save()">
    <i *ngIf="!saving" class="fa fa-save me-2"></i>
    <span *ngIf="saving" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
    Save
  </button>
</ng-template>
<ng-template #mainContent>
  <div class="container-fluid">
    <div *ngIf="areChannelsEnabled && !selectedChannel" class="row justify-content-md-center">
      <div class="col-md-6 text-center fw-bold alert alert-warning" role="alert">
        <i class="fa fa-exclamation-triangle me-2"></i>
        Please select a channel to get started.
      </div>
    </div>
    <div *ngIf="!areChannelsEnabled || selectedChannel" class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5>Loan Statuses Associated - {{loanPurposeName}}</h5>
          </div>
          <div class="card-body">
            <div *ngIf="!loading && !sortableLoanStatusAssociations.length" class="alert alert-danger" role="alert">
              <i class="fa fa-exclamation-circle ms-2"></i>
              There are no loan status associated to this loan purpose.
              Please associate loan statuses to loan purpose and then return to this screen.
            </div>
            <div id="loan-statuses-associated" [sortablejs]="sortableLoanStatusAssociations"
              [sortablejsOptions]="loanStatusAssociatedSortableOptions" class="sortable-list-container">
              <div id="parent-status_{{ status.statusId }}" class="list-item"
                *ngFor="let status of sortableLoanStatusAssociations">
                <tree-sortable-loan-status [options]="loanStatusAssociatedSortableOptions" [status]="status"
                  (removeFromTree)="removeFromTree($event)">
                </tree-sortable-loan-status>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5>Loan Statuses</h5>
          </div>
          <div class="card-body">
            <div id="loan-statuses" *ngIf="availableLoanStatuses" [sortablejs]="filteredLoanStatuses"
              [sortablejsOptions]="loanStatusesOptions" class="sortable-list-container">
              <div id="{{ status.loanStatusId }}" class="list-group-item tree-user mb-2 move"
                *ngFor="let status of filteredLoanStatuses | sort: 'loanStatusName'">
                {{ status.loanStatusName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight"
  [pageActionBarMiddleContentTemplate]="pageActionBarMiddle">
</admin-page-template>
