<div class="accordion" id="disclosuresAccordion">
  <ng-container *ngFor="
      let disclosureInfo of job?.disclosureInfos;
      let i = index
    ">
    <div class="accordion-item">
      <h5 class="accordion-header m-0" id="headingDoc{{ i }}">
        <button class="accordion-button {{
            i > 0 ? 'collapsed' : ''
          }} fw-semibold" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseDoc' + i"
          aria-expanded="true" [attr.aria-controls]="'#collapseDoc' + i">
          <i class="fas fa-file-pdf-o me-2"></i>
          {{ disclosureInfo.description }}
          <span class="ms-2 tab-1003-val-icon">
            <i *ngIf="type == 'audit'" class="status-indicator fas" style="float: right" [ngClass]="{
                fa: disclosureInfo.status == 'AuditCompleted',
                'fa-check-circle':
                  disclosureInfo.status == 'AuditCompleted',
                'text-success': disclosureInfo.status == 'AuditCompleted',

                'fa-times-circle': disclosureInfo.status == 'Failed',
                'text-danger': disclosureInfo.status == 'Failed',

                'fa-circle-notch':
                  disclosureInfo.status == 'AuditPending',
                'fa-spin': disclosureInfo.status == 'AuditPending'
              }"></i>
            <i *ngIf="type == 'generate'" class="status-indicator fas" style="float: right" [ngClass]="{
                fa: disclosureInfo.status == 'DocGenCompleted',
                'fa-check-circle':
                  disclosureInfo.status == 'DocGenCompleted',
                'text-success':
                  disclosureInfo.status == 'DocGenCompleted',

                'fa-times-circle': disclosureInfo.status == 'Failed',
                'text-danger': disclosureInfo.status == 'Failed',

                'fa-circle-notch':
                  disclosureInfo.status == 'DocGenPending',
                'fa-spin': disclosureInfo.status == 'DocGenPending'
              }"></i>
          </span>
        </button>
      </h5>

      <div id="collapseDoc{{ i }}" class="accordion-collapse collapse {{ i == 0 ? 'show' : '' }}"
        aria-labelledby="headingDoc{{ i }}" data-bs-parent="#disclosuresAccordion">
        <div class="accordion-body">
          <div>
            <div *ngIf=" disclosureInfo.errors &&
                disclosureInfo.errors != 'Fatal, business rule violations'
              " class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
              <div class="media">
                <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
                <div class="media-body align-self-center">
                  <span>{{ disclosureInfo.errors }}</span>
                </div>
              </div>
            </div>

            <table *ngIf="disclosureInfo?.dataAuditRuleMessages?.length" class="table table-striped">
              <tbody>
                <ng-container *ngFor="let msg of disclosureInfo.dataAuditRuleMessages">
                  <tr *ngIf="msg.severity == 'Required'">
                    <td><i class="fas fa-times-circle text-danger me-2"></i> Required</td>
                    <td>{{msg.validationMessages[0]}}</td>
                    <td [ngClass]="{'text-end': msg.losFieldId}">{{ msg.fieldDescription }}</td>
                    <td>
                      <encompass-disclosures-input-editors [type]="'dataType'" [fieldId]="'losFieldId'" [formatFieldName]="'dataFormat'"
                        [object]="msg" [values]="correctedValues" *ngIf="msg.losFieldId">
                      </encompass-disclosures-input-editors>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngFor="let msg of disclosureInfo.dataAuditRuleMessages">
                  <tr *ngIf="msg.severity == 'Recommended'">
                    <td><i class='fas fa-exclamation-circle text-warning me-2'></i> Recommended</td>
                    <td>{{msg.validationMessages[0]}}</td>
                    <td [ngClass]="{'text-end': msg.losFieldId}">{{ msg.fieldDescription }}</td>
                    <td>
                      <encompass-disclosures-input-editors [type]="'dataType'" [fieldId]="'losFieldId'" [formatFieldName]="'dataFormat'"
                        [object]="msg" [values]="correctedValues" *ngIf="msg.losFieldId">
                      </encompass-disclosures-input-editors>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div *ngIf="
              !disclosureInfo.errors && documentsVisible
            ">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" (click)="selectedTab = 'previewLE'">
                <a class="nav-link" [ngClass]="{ active: selectedTab == 'previewLE' }" data-bs-toggle="tab" role="tab"
                  aria-selected="true">
                  Preview LE
                </a>
              </li>
              <li class="nav-item" (click)="selectedTab = 'documents'">
                <a class="nav-link" [ngClass]="{ active: selectedTab == 'documents' }" data-bs-toggle="tab" role="tab"
                  aria-selected="true">
                  Documents
                </a>
              </li>
              <li class="nav-item" (click)="selectedTab = 'parties'">
                <a class="nav-link" [ngClass]="{ active: selectedTab == 'parties' }" data-bs-toggle="tab" role="tab"
                  aria-selected="false">
                  Parties
                </a>
              </li>
              <li class="nav-item" (click)="selectedTab = 'failedDocs'" *ngIf="disclosureInfo?.failedDocs?.length">
                <a class="nav-link" [ngClass]="{ active: selectedTab == 'failedDocs' }" data-bs-toggle="tab" role="tab"
                  aria-selected="false">
                  Failed Docs
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="previewLE" class="tab-pane p-3" role="tabpanel"
                [ngClass]="{ active: selectedTab == 'previewLE' }">
                <encompass-disclosure-preview-le [info]="disclosureInfo"></encompass-disclosure-preview-le>
              </div>
              <div id="documents" class="tab-pane p-3" role="tabpanel"
                [ngClass]="{ active: selectedTab == 'documents' }">
                <encompass-disclosure-documents [info]="disclosureInfo" [isTpoUser]="isTpoUser"></encompass-disclosure-documents>
              </div>
              <div id="parties" class="tab-pane p-3" role="tabpanel" [ngClass]="{ active: selectedTab == 'parties' }">
                <encompass-disclosure-parties [info]="disclosureInfo"></encompass-disclosure-parties>
              </div>
              <div id="failedDocs" class="tab-pane p-3" role="tabpanel"
                [ngClass]="{ active: selectedTab == 'failedDocs' }"  *ngIf="disclosureInfo?.failedDocs?.length">
                <encompass-disclosure-failed-docs [info]="disclosureInfo"></encompass-disclosure-failed-docs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
