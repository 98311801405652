import { Injectable } from '@angular/core';
import { GoalService } from '../../../../admin/goal/services/goal.service';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { Goal } from '../../../../admin/goal/models/goal.model';
import { ApplicationContextService } from '../../../../../services/application-context.service';

@Injectable({
  providedIn: 'root',
})
export class UserGoalService {
  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _goalService: GoalService,
  ) {
  }

  private get userId$(): Observable<string> {
    return this._applicationContextService.context.pipe(
      map((context) => context.currentlyLoggedInUser.userId),
    );
  }

  forCurrentUser(): Observable<UserGoalServiceBoundToUser> {
    return this.userId$.pipe(
      map((userId) => this.forUser(userId)),
    );
  }

  forUser(userId: string): UserGoalServiceBoundToUser {
    return new UserGoalServiceBoundToUser(this, userId);
  }

  addGoals(userId: string, ...goals: readonly Goal[]): Observable<readonly Goal[]> {
    const addUserIdToGoal = addUserToGoalCallable(userId);
    const newGoals = goals.map(addUserIdToGoal);
    return forkJoin(
      newGoals.map((goal) => this._goalService.updateGoal(goal)),
    );
  }

  getGoals(userId: string): Observable<Goal[]> {
    return this._goalService.getGoals().pipe(
      map((goals) => goals.filter((goal) => goal.userIds.has(userId))),
    );
  }

  removeGoals(userId: string, ...goalIds: readonly number[]): Observable<readonly Goal[]> {
    const goalIdsSet = new Set(goalIds);
    const removeUserIdFromGoal = removeUserFromGoalCallable(userId);
    return this._goalService.getGoals().pipe(
      map((goals) => goals.filter((goal) => goalIdsSet.has(goal.id))
        .map(removeUserIdFromGoal)),
      switchMap((goals) => forkJoin(
        goals.map((goal) => this._goalService.updateGoal(goal)),
      )),
    );
  }
}

export class UserGoalServiceBoundToUser {
  constructor(
    private readonly _userGoalService: UserGoalService,
    private readonly _userId: string,
  ) {
  }

  addGoals(...goals: readonly Goal[]): Observable<readonly Goal[]> {
    return this._userGoalService.addGoals(this._userId, ...goals);
  }

  getGoals(): Observable<readonly Goal[]> {
    return this._userGoalService.getGoals(this._userId);
  }

  removeGoals(...goalIds: readonly number[]): Observable<readonly Goal[]> {
    return this._userGoalService.removeGoals(this._userId, ...goalIds);
  }
}

function addUserToGoalCallable(userId: string) {
  return (goal: Goal): Goal =>
    new Goal({
      ...goal,
      userIds: [...goal.userIds, userId],
    });
}

function removeUserFromGoalCallable(userId: string) {
  return (goal: Goal): Goal => {
    const previousUserIds = goal.userIds;
    if (!previousUserIds.has(userId)) {
      return goal;
    }

    const userIds = Array.from(previousUserIds).filter((id) => id !== userId);

    return new Goal({
      ...goal,
      userIds,
    });
  };
}
