<ng-template #pageActionBarLeft>
  <bread-crum class="tpo-bread-crumb"></bread-crum>
</ng-template>

<ng-template #mainContent>
  <new-application></new-application>
</ng-template>

<tpo-page-template [pageMainContentTemplate]="mainContent" [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</tpo-page-template>
