<div class="modal-body" id="coc-details-drawer">
  <form #cocDetailsForm="ngForm">
    <div class="row">
      <!-- left column -->
      <div class="col-md-6 col-xs-12">
        <div class="row">
          <div class="col-md-12">
            <label class="control-label" for="changeOfCircumstanceStatus-{{componentId}}">
              Status
            </label>
            <select id="changeOfCircumstanceStatus-{{componentId}}" name="changeOfCircumstanceStatus"
              class="form-select" [(ngModel)]="cocDetails.changeOfCircumstanceStatus"
              #changeOfCircumstanceStatus="ngModel" [ngClass]="{
                'is-invalid':
                  changeOfCircumstanceStatus &&
                  changeOfCircumstanceStatus.touched &&
                  changeOfCircumstanceStatus.invalid
              }" [disabled]="readonly || !!loanDocTask" required>
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let item of statuses" value="{{item.value}}">
                {{ item.name }}
              </option>
            </select>
            <div class="invalid-feedback">This field is required.</div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="disclosureReason-{{componentId}}" class="col-sm-4 control-label">
              Disclosure Reason
            </label>
            <select name="disclosureReason-{{componentId}}" class="form-select"
              [(ngModel)]="cocDetails.disclosureReason" #disclosureReasonValue="ngModel" [ngClass]="{
                'is-invalid':
                disclosureReasonValue &&
                disclosureReasonValue.touched &&
                disclosureReasonValue.invalid
              }" [disabled]="readonly" required>
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let item of disclosureReasons | sort:'asc': 'name'" [ngValue]="item.value">
                {{ item.name }}
              </option>
            </select>
            <div class="invalid-feedback">This field is required.</div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xs-12 mt-3">
            <label class="control-label" for="changesReceivedDate-{{componentId}}">
              Changes Received
            </label>
            <date-input [id]="'changesReceivedDate-' + componentId" [name]="'changesReceivedDate-' + componentId"
              [(ngModel)]="cocDetails.changesOfCircumstanceReceivedDate" [required]="
                urlaFieldsConfig[
                  'changeOfCircumstance.changesOfCircumstanceReceivedDate'
                ] == 'required' ||
                urlaFieldsConfig[
                  'changeOfCircumstance.changesOfCircumstanceReceivedDate'
                ] == 'requested'
              " (ngModelChange)="onReceivedDateChanged()" [readonly]="readonly"></date-input>
          </div>
          <div class="col-md-6 col-xs-12 mt-3">
            <label class="control-label" for="revisedLEDueDate-{{componentId}}">
              Revised Due
            </label>
            <date-input [id]="'revisedLEDueDate-' + componentId" [name]="'revisedLEDueDate-' + componentId"
              [(ngModel)]="revisedDueDate" [readonly]="true"></date-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <span> Description </span>
            <textarea id="description-{{componentId}}" name="description-{{componentId}}" class="form-control" rows="4"
              [(ngModel)]="cocDetails.disclosureReasonAdditionalDescription" [required]="
                urlaFieldsConfig[
                  'changeOfCircumstance.disclosureReasonAdditionalDescription'
                ] == 'required' ||
                urlaFieldsConfig[
                  'changeOfCircumstance.disclosureReasonAdditionalDescription'
                ] == 'requested'
              " #disclosureReasonAdditionalDescription="ngModel" [ngClass]="{
                'is-invalid':
                  disclosureReasonAdditionalDescription &&
                  disclosureReasonAdditionalDescription.touched &&
                  disclosureReasonAdditionalDescription.invalid
              }" [disabled]="readonly"></textarea>
            <div class="invalid-feedback">This field is required.</div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <span> Comments </span>
            <textarea id="comments-{{componentId}}" name="comments-{{componentId}}" class="form-control" rows="4"
              [(ngModel)]="cocDetails.disclosureComments" [required]="
                urlaFieldsConfig['changeOfCircumstance.disclosureComments'] ==
                  'required' ||
                urlaFieldsConfig['changeOfCircumstance.disclosureComments'] ==
                  'requested'
              " #disclosureComments="ngModel" [ngClass]="{
                'is-invalid':
                  disclosureComments &&
                  disclosureComments.touched &&
                  disclosureComments.invalid
              }" [disabled]="readonly"></textarea>
            <div class="invalid-feedback">This field is required.</div>
          </div>
        </div>
      </div>

      <!-- right column -->
      <div class="col-md-6 col-xs-12">
        <div class="mt-3">
          <div class="form-check form-switch form-switch-success cursor mt-4 mb-3">
            <input id="showAllFees-{{componentId}}" name="showAllFees-{{componentId}}" class="form-check-input cursor"
              type="checkbox" [(ngModel)]="showAllFees" (change)="onShowAllFeesToggled()" [disabled]="readonly" />
            <label for="showAllFees-{{componentId}}" class="form-check-label cursor">
              Show all fees
            </label>
          </div>
          <p-table #dt1 [value]="tableFees" [(selection)]="selectedFees" dataKey="_id" (onRowSelect)="onRowToggled()"
            (onRowUnselect)="onRowToggled()" (onHeaderCheckboxToggle)="onAllRowsToggled()"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  Fee Name
                </th>
                <th>
                  Disclosed Value
                </th>
                <th>
                  Current Value
                </th>
                <th style="width: 3rem" *ngIf="!readonly">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-fee let-index>
              <tr class="fee-table-body cursor">
                <td> {{ fee.feeName || '--' }} </td>
                <td> {{ fee.disclosedValue | currency }} </td>
                <td> {{ fee.loanValue | currency }} </td>
                <td *ngIf="!readonly">
                  <p-tableCheckbox [value]="fee"></p-tableCheckbox>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="readonly ? 3 : 4" class="text-center">
                  No fees have been updated since disclosure
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p *ngIf="!readonly" class="badge badge-soft-info p-2 mt-2">
            Please save to persist selected fees.
          </p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-check form-switch form-switch-success cursor mt-4">
              <input id="hasChangeOfCircumstance-{{componentId}}" name="hasChangeOfCircumstance-{{componentId}}"
                class="form-check-input cursor" type="checkbox" [(ngModel)]="cocDetails.hasChangeOfCircumstance"
                (change)="onChangedCircumstance()" [disabled]="readonly" />
              <label for="hasChangeOfCircumstance-{{componentId}}" class="form-check-label cursor">
                Changed Circumstance
              </label>
            </div>
            <div class="mt-3" *ngIf="cocDetails.hasChangeOfCircumstance">
              <label for="reason-{{componentId}}" class="col-sm-4 control-label">
                CoC Reason
              </label>
              <div>
                <select id="reason-{{componentId}}" name="reason-{{componentId}}" class="form-select"
                  [(ngModel)]="cocDetails.changeOfCircumstanceReasonId" (ngModelChange)="onCocReasonChanged($event)"
                  #cocReason="ngModel" [ngClass]="{
                                  'is-invalid':
                                  cocReason &&
                                  cocReason.touched &&
                                  cocReason.invalid
                                }" [disabled]="readonly" required>
                  <option [ngValue]="null">-- Select One --</option>
                  <ng-container *ngFor="let cocReason of cocReasons | groupBy: 'reasonCategory'">
                    <optgroup label="{{cocReason.key === 'undefined' ? 'Not Set' : cocReason.key}}">
                      <option *ngFor="let reason of cocReason.value; let i = index" value="{{reason.changeOfCircumstanceReasonId}}">
                        {{ reason.title }} {{reason.disclosureReason ? '(' + reason.disclosureReason + ')' : '' }}
                      </option>
                    </optgroup>
                  </ng-container>
                </select>
                <div class="invalid-feedback">This field is required.</div>
              </div>
              <div *ngIf="cocDetails.disclosureReason == 'Other'" class="mt-3">
                <span> Other Description </span>
                <input id="other-description-{{componentId}}" name="other-description-{{componentId}}"
                  class="form-control" rows="4" [(ngModel)]="cocDetails.disclosureReasonOtherDescription" [required]="
                    urlaFieldsConfig[
                      'changeOfCircumstance.disclosureReasonOtherDescription'
                    ] == 'required' ||
                    urlaFieldsConfig[
                      'changeOfCircumstance.disclosureReasonOtherDescription'
                    ] == 'requested'
                  " #disclosureReasonOtherDescription="ngModel" [ngClass]="{
                    'is-invalid':
                      disclosureReasonOtherDescription &&
                      disclosureReasonOtherDescription.touched &&
                      disclosureReasonOtherDescription.invalid
                  }" [disabled]="readonly" />
                <div class="invalid-feedback">This field is required.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer w-100">
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-secondary me-2" (click)="onCloseClicked()">
      <i class="fa fa-close"></i> {{ readonly ? 'Close' : 'Cancel' }}
    </button>
    <button *ngIf="!readonly" type="button" class="btn btn-primary" [disabled]="isSaving" (click)="onSaveClicked()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Saving
      </span>
    </button>
  </div>
</div>
