import { AusData, Employment, EscrowDetail, Extension, GovernmentLoanDetail, HousingExpense, Mortgage, MortgageBorrower, MortgageInsuranceDetail, MortgageTerm, OriginatorInformation, PurchaseCredit, RealEstateOwned, RegulationZDetail, SubjectProperty, TransactionDetail } from "src/app/models";

export class UrlaMortgage extends Mortgage {
    calculatedStats: MortgageCalculatedStats = new MortgageCalculatedStats();
    channel: string;
    constructor() {
      super();
      this.borrowers = [];
      this.liabilities = [];
      this.assets = [];
      this.realEstateOwned = [];
      this.transactionDetail = new TransactionDetail();
      this.subjectProperty = new SubjectProperty();
      this.escrowDetail = new EscrowDetail();
      this.mortgageTerm = new MortgageTerm();
      this.mortgageInsuranceDetail = new MortgageInsuranceDetail();
      this.governmentLoanDetail = new GovernmentLoanDetail();
      this.relatedMortgages = [];
      this.originatorInformation = new OriginatorInformation();
      this.proposedHousingExpense = new HousingExpense();
      this.currentHousingExpense = new HousingExpense();
      this.ausData = new AusData();
      this.extension = new Extension();
      this.regulationZDetail = new RegulationZDetail();
    }
}

export class UrlaBorrower extends MortgageBorrower {
    calculatedStats: BorrowerCalculatedStats = new BorrowerCalculatedStats();
}

export class UrlaEmployment extends Employment {
    calculatedStats: EmploymentCalculatedStats = new EmploymentCalculatedStats();
}

export class MortgageCalculatedStats {
    landValue: number;
    sourceOfFunds: number;
    totalPaidOffForRefinance: number;
    totalDue: number;
    totalLoanOrDrawAmount: number;
    totalMortgageLoans: number;
    lenderCredit: number;
    emd: number;
    purchaseCredits: Array<PurchaseCredit>;
    totalCredit: number;
    totalDueFromBorrowers: number;
    estimatedClosingCostsAmount: number;
    cashFromOrToTheBorrower: number;
    totalOtherCredit: number;
    financialPartialPayoffAmount: number;
    financialOtherPartialPayoffAmount: number;
    financialPartialPayoffTotalAmount: number;
    proposedMonthlyPaymentTotal: number;
    currentMonthlyPaymentTotal: number;
    totalMortgageLoansAndCredits: number;
  }

  export class BorrowerCalculatedStats {
    totalMonthlyIncome: number;
  }

  export class EmploymentCalculatedStats {
    monthlyIncome: number;
  }
