import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocuSignDocument } from '../../models/docusign-envelope.model';

@Component({
  selector: 'docusign-documents-table',
  templateUrl: 'docusign-documents-table.component.html'
})

export class DocuSignDocumentsTableComponent implements OnInit {

  @Input()
  documents: DocuSignDocument[] = [];

  @Input()
  showCaption: boolean = false;

  @Output()
  viewDocumentClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  emptyMessage: string = "There are no documents.";

  constructor() { }

  ngOnInit() { }

  onViewDocumentClicked = (documentId: string) => {
    this.viewDocumentClicked.emit(documentId);
  }
}
