<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
</div>
<div class="modal-body">
  <div class="row mb-3 text-center">
      <p [innerText]="text"></p>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">No</button>
  <button type="button" class="btn btn-primary right" (click)="activeModal.close('yes')">Yes</button>
</div>
