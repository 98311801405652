import { UrlaMortgage } from "src/app/modules/urla/models/urla-mortgage.model";
import { MortgageUtils } from "../utils/mortgage/mortgage-utils";
import { LoanPurposeTypeEnum } from "src/app/modules/app-details/components/title-history/models/title-order.model";
import { ChannelEnum } from "src/app/models";

export enum MortgageFieldRequiredState {
	None = 'none',
	Requested = 'requested',
	Required = 'required'
}

export class MortgageModelFieldsConfig {
  mortgage = {
    borrower: {
      firstName: MortgageFieldRequiredState.Requested,
      middleName: MortgageFieldRequiredState.None,
      lastName: MortgageFieldRequiredState.Requested,
      nameSuffix: MortgageFieldRequiredState.None,
      primaryEmail: MortgageFieldRequiredState.Requested,
      mobilePhone: MortgageFieldRequiredState.Requested,
      homePhone: MortgageFieldRequiredState.Requested,
      socialSecNum: MortgageFieldRequiredState.Requested,
      dateOfBirth: MortgageFieldRequiredState.Requested,
      authorizationMethod: MortgageFieldRequiredState.Requested,
      maritalStatus: MortgageFieldRequiredState.Requested,
      unmarriedAddendum: MortgageFieldRequiredState.Requested,
      domesticRelationshipType: MortgageFieldRequiredState.Requested,
      unmarriedRelationshipState: MortgageFieldRequiredState.Requested,
      dateAuthorizedCreditCheck: MortgageFieldRequiredState.Requested,
      hasServedInMilitary: MortgageFieldRequiredState.Requested,
      militaryStatus: MortgageFieldRequiredState.Requested,
      militaryTourEndDate: MortgageFieldRequiredState.Requested,
      fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType: MortgageFieldRequiredState.Requested,

      alias: {
        firstName: MortgageFieldRequiredState.Requested,
        middleName: MortgageFieldRequiredState.None,
        lastName: MortgageFieldRequiredState.Requested,
        nameSuffix: MortgageFieldRequiredState.None
      },

      residencyAddress: {
        address1: MortgageFieldRequiredState.Requested,
        address2: MortgageFieldRequiredState.None,
        city: MortgageFieldRequiredState.Requested,
        state: MortgageFieldRequiredState.Requested,
        zipCode: MortgageFieldRequiredState.Requested,
        country: MortgageFieldRequiredState.Requested,
        residencyBasis: MortgageFieldRequiredState.Requested,
        durationAtAddress: MortgageFieldRequiredState.Requested,
        rent: MortgageFieldRequiredState.Requested,
        isSubjectProperty: MortgageFieldRequiredState.Requested
      },

      employment: {
        employer: MortgageFieldRequiredState.Requested,
        position: MortgageFieldRequiredState.None,
        startDate: MortgageFieldRequiredState.Requested,
        endDate: MortgageFieldRequiredState.Requested,
        address: {
          address1: MortgageFieldRequiredState.Requested,
          address2: MortgageFieldRequiredState.None,
          city: MortgageFieldRequiredState.Requested,
          state: MortgageFieldRequiredState.Requested,
          zipCode: MortgageFieldRequiredState.Requested,
          country: MortgageFieldRequiredState.Requested
        },
        employerPhone: MortgageFieldRequiredState.Requested,
        yearsInLineOfWork: MortgageFieldRequiredState.None,
        selfEmployed: MortgageFieldRequiredState.None,
        borrowerOwnershipShare: MortgageFieldRequiredState.Requested,
        selfEmploymentMonthlyIncomeOrLoss: MortgageFieldRequiredState.Requested
      },

      declarations: {
        residenceStatus: MortgageFieldRequiredState.Requested,
        occupyProperty: MortgageFieldRequiredState.Requested,
        havePropertiesOwnership: MortgageFieldRequiredState.Requested,
        typeOfProperty: MortgageFieldRequiredState.Requested,
        heldTitleHow: MortgageFieldRequiredState.Requested,
        haveRelationshipWithSeller: MortgageFieldRequiredState.Requested,
        borrowedDownPayment: MortgageFieldRequiredState.Requested,
        undisclosedMoneyAmount: MortgageFieldRequiredState.None,
        applyingForOtherMortgage: MortgageFieldRequiredState.Requested,
        applyingForNonMortgageCredit: MortgageFieldRequiredState.Requested,
        propertyHasLienWithCleanEnergyProgram: MortgageFieldRequiredState.Requested,
        coSignedLoan: MortgageFieldRequiredState.Requested,
        haveJudgement: MortgageFieldRequiredState.Requested,
        haveTaxDebt: MortgageFieldRequiredState.Requested,
        haveLawsuit: MortgageFieldRequiredState.Requested,
        haveConveyedTitleInLieuOfForeclosure: MortgageFieldRequiredState.Requested,
        haveCompletedShortSale: MortgageFieldRequiredState.Requested,
        beenForclosed: MortgageFieldRequiredState.Requested,
        declaredBankruptcy: MortgageFieldRequiredState.Requested,
        bankruptcyType: MortgageFieldRequiredState.Requested,

      },

      governmentMonitors: {
        ethnicity: MortgageFieldRequiredState.Requested,
        sex: MortgageFieldRequiredState.Requested,
        race: MortgageFieldRequiredState.Requested,
        applicationTakenMethod: MortgageFieldRequiredState.Requested,
        isEthnicityBasedOnVisualOrSurname: MortgageFieldRequiredState.Requested,
        isGenderBasedOnVisualOrSurname: MortgageFieldRequiredState.Requested,
        isRaceBasedOnVisualOrSurname: MortgageFieldRequiredState.Requested
      }

    },

    asset: {
      assetType: MortgageFieldRequiredState.Requested,
      depository: MortgageFieldRequiredState.Requested,
      accountNumber: MortgageFieldRequiredState.Requested,
      cashMarketValue: MortgageFieldRequiredState.Requested,
      giftOrGrantSource: MortgageFieldRequiredState.Requested,
      isDeposited: MortgageFieldRequiredState.Requested
    },

    liability: {
      typeOfLiability: MortgageFieldRequiredState.Requested,
      holderName: MortgageFieldRequiredState.Requested,
      accountNumber: MortgageFieldRequiredState.Requested
    },

    realEstateOwned: {
      address1: MortgageFieldRequiredState.Requested,
      city: MortgageFieldRequiredState.Requested,
      state: MortgageFieldRequiredState.Requested,
      zipCode: MortgageFieldRequiredState.Requested,
      borrowerPicker: MortgageFieldRequiredState.Requested,
      currentUsageType: MortgageFieldRequiredState.Requested,
      dispositionStatus: MortgageFieldRequiredState.Requested,
      intendedUsageType: MortgageFieldRequiredState.Requested,
      isSubjectProperty: MortgageFieldRequiredState.Requested,
      typeOfProperty: MortgageFieldRequiredState.Requested,
      marketValue: MortgageFieldRequiredState.Requested,
      monthlyMiscExpenses: MortgageFieldRequiredState.Requested,
      percentOwned: MortgageFieldRequiredState.None,
      vacancyFactor: MortgageFieldRequiredState.None,
      grossRentalIncome: MortgageFieldRequiredState.None,
      netRentalIncome: MortgageFieldRequiredState.None,
    },

    mortgageTerm: {
      amount: MortgageFieldRequiredState.Requested,
      appraisedValue: MortgageFieldRequiredState.Requested,
      isMixedUseProperty: MortgageFieldRequiredState.None,
      lenderCaseNumber: MortgageFieldRequiredState.Requested,
      universalLoanNumber: MortgageFieldRequiredState.Requested,
      agencyCaseNumber: MortgageFieldRequiredState.Requested,
      mortgageAppliedFor: MortgageFieldRequiredState.Requested,
      interestRate: MortgageFieldRequiredState.None,
      noOfMonths: MortgageFieldRequiredState.None,
      lienPosition: MortgageFieldRequiredState.Requested,
      amortizationType: MortgageFieldRequiredState.Requested,
      loanAmortizationPeriodType: MortgageFieldRequiredState.None,
    },

    subjectProperty: {
      purposeOfLoan: MortgageFieldRequiredState.Requested,
      refiPurpose: MortgageFieldRequiredState.Requested,
      refinancePrimaryPurpose: MortgageFieldRequiredState.Requested,
      propertyWillBe: MortgageFieldRequiredState.Requested,
      address1: MortgageFieldRequiredState.Requested,
      address2: MortgageFieldRequiredState.None,
      city: MortgageFieldRequiredState.Requested,
      state: MortgageFieldRequiredState.Requested,
      zipCode: MortgageFieldRequiredState.Requested,
      county: MortgageFieldRequiredState.Requested,
      noOfUnits: MortgageFieldRequiredState.Requested,
      constructionMethod: MortgageFieldRequiredState.Requested,
      isFhaSecondaryResidence: MortgageFieldRequiredState.Requested,
      presentValue: MortgageFieldRequiredState.Requested,
      expectedGrossMonthlyRentalIncome: MortgageFieldRequiredState.None,
      expectedNetMonthlyRentalIncome: MortgageFieldRequiredState.None,
      refinanceProgram: MortgageFieldRequiredState.Requested,
      constructionToPermanentClosingType: MortgageFieldRequiredState.Requested,
      attachmentType: MortgageFieldRequiredState.Requested,
      projectType: MortgageFieldRequiredState.Requested,
      projectDesignType: MortgageFieldRequiredState.Requested,
      propertyHasLienWithCleanEnergyProgram: MortgageFieldRequiredState.Requested,
      improvementCost: MortgageFieldRequiredState.None,
      lotAcquiredDate: MortgageFieldRequiredState.None,
      lotOriginalCost: MortgageFieldRequiredState.None,
      lotAppraisedValue: MortgageFieldRequiredState.None,
      mannerTitleWillBeHeld: MortgageFieldRequiredState.Requested,
      estate: MortgageFieldRequiredState.Requested,
      leaseHoldExpirationDate: MortgageFieldRequiredState.Requested,
      titleWillBeHeldNames: MortgageFieldRequiredState.None,
      titleCurrentlyHeldInNames: MortgageFieldRequiredState.Requested,
      trustClassification: MortgageFieldRequiredState.None,
      nativeAmericanLandsType: MortgageFieldRequiredState.None,
      communityLendingProductType: MortgageFieldRequiredState.None,
      communitySecondsRepaymentType: MortgageFieldRequiredState.None
    },

    relatedMortgage: {
      lienType: MortgageFieldRequiredState.Requested,
      monthlyPayment: MortgageFieldRequiredState.Requested,
      loanOrDrawAmount: MortgageFieldRequiredState.Requested,
      creditLimit: MortgageFieldRequiredState.Requested,
      isHeloc: MortgageFieldRequiredState.Requested
    },

    originatorInformation: {
      organizationName: MortgageFieldRequiredState.Requested,
      originatorFhaLenderId: MortgageFieldRequiredState.None,
      originatorFhaSponsorId: MortgageFieldRequiredState.None,
      organizationAddress1: MortgageFieldRequiredState.Requested,
      organizationAddress2: MortgageFieldRequiredState.None,
      organizationCity: MortgageFieldRequiredState.Requested,
      organizationState: MortgageFieldRequiredState.Requested,
      organizationZipCode: MortgageFieldRequiredState.Requested,
      name: MortgageFieldRequiredState.Requested,
      organizationNmls: MortgageFieldRequiredState.Requested,
      stateLicense: MortgageFieldRequiredState.Requested,
      email: MortgageFieldRequiredState.Requested,
      phone: MortgageFieldRequiredState.Requested,
      fax: MortgageFieldRequiredState.None,
      nmls: MortgageFieldRequiredState.None,
      stateLicenseState: MortgageFieldRequiredState.None,
      organizationStateLicense: MortgageFieldRequiredState.None,
      organizationStateLicenseState: MortgageFieldRequiredState.None,
      organizationVaSponsorId: MortgageFieldRequiredState.None,
      organizationVaLenderId: MortgageFieldRequiredState.None,
      originatorVaLenderId: MortgageFieldRequiredState.None,
      underwriterId: MortgageFieldRequiredState.None,
    },
        
    proposedHousingExpense: {
      otherMortgageLoanPrincipalAndInterest: MortgageFieldRequiredState.None,
      homeownersInsurance: MortgageFieldRequiredState.None,
      supplementalPropertyInsurance: MortgageFieldRequiredState.Requested,
      realEstateTax: MortgageFieldRequiredState.Requested,
      homeownersAssociationDuesAndCondominiumFees: MortgageFieldRequiredState.Requested,
      otherHousingExpense: MortgageFieldRequiredState.None
    },

    extension: {
        balloonTermMonths: MortgageFieldRequiredState.Requested,
        interestOnlyTermMonths: MortgageFieldRequiredState.Requested,
        prepaymentPenaltyTermMonths: MortgageFieldRequiredState.Requested,
        balloonPaymentAmount: MortgageFieldRequiredState.None,

        buydown: {
            initialBuydownRate: MortgageFieldRequiredState.Requested,
            buydownCalculationType: MortgageFieldRequiredState.Requested,

            buydownScheduleItem: {
                adjustmentPercent: MortgageFieldRequiredState.None,
                paymentCount: MortgageFieldRequiredState.None
            }
        }
    },

    ausData: {
      negativeAmortizationType: MortgageFieldRequiredState.Requested,
      armIndexSourceType: MortgageFieldRequiredState.Requested,
      armIndexCurrent: MortgageFieldRequiredState.Requested,
      armMarginRate: MortgageFieldRequiredState.Requested,
      armQualifyingRate: MortgageFieldRequiredState.Requested,
      firstPaymentAdjustmentMonths: MortgageFieldRequiredState.Requested,
      subsequentPaymentAdjustmentMonths: MortgageFieldRequiredState.Requested
    },

    governmentLoanDetail: {
      borrowerFundingFeePercent: MortgageFieldRequiredState.None,
      refundProratedUnearnedUpFront: MortgageFieldRequiredState.None,
      roundToNearest50Dollars: MortgageFieldRequiredState.None,
      insuranceChargesIncludedInLoanPayments: MortgageFieldRequiredState.None,
      insuranceChargesCollectedAtClosing: MortgageFieldRequiredState.None,
      lenderPaidMortgageInsurance: MortgageFieldRequiredState.None,
      borrowerHasPositiveRentalHistory: MortgageFieldRequiredState.None,
      sectionOfActType: MortgageFieldRequiredState.None,
      fhaMiPremiumRefundAmount: MortgageFieldRequiredState.None,
      fhaAnnualPremiumPercent: MortgageFieldRequiredState.None,
      fhaAssignmentDate: MortgageFieldRequiredState.None,
      fhaEndorsementDate: MortgageFieldRequiredState.None,
      fhaEnergyRelatedRepairsOrImprovementsAmount: MortgageFieldRequiredState.None,
      fhaGeneralServicesAdministrationIdentifier: MortgageFieldRequiredState.None,
      fhaInsuranceProgramType: MortgageFieldRequiredState.None,
      fhaPremiumAnniversaryYearToDateRemittanceAmount: MortgageFieldRequiredState.None,
      fhaRefinanceInterestOnExistingLienAmount: MortgageFieldRequiredState.None,
      fhaRefinanceOriginalExistingFhaCaseIdentifier: MortgageFieldRequiredState.None,
      fhaRefinanceOriginalExistingUpfrontMIPremiumAmount: MortgageFieldRequiredState.None,
      fhaMIRenewalRatePercent: MortgageFieldRequiredState.None,
      vaResidualIncomeAmount: MortgageFieldRequiredState.None,
      vaEntitlementIdentifier: MortgageFieldRequiredState.None,
    }

  };
}

export class MortgageFieldConfig {
  hidden: boolean = false;
  required: string = MortgageFieldRequiredState.None;
  readonly: boolean = false;
  hiddenEnumOptions: string[] = [];

  constructor (required: string, hidden?: boolean, readonly?: boolean, hiddenEnumOptions?: string[]) {
    this.required = required;
    this.hidden = hidden ? hidden : false;
    this.readonly = readonly ? readonly : false;
    this.hiddenEnumOptions = hiddenEnumOptions ? hiddenEnumOptions : [];
  }
}

export function loadDefaultQuickApplyFieldsConFig(quickApplyFieldsConfig) {
	quickApplyFieldsConfig['mortgage.borrower.firstName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.middleName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.lastName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nameSuffix'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.signingRole'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.primaryEmail'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.mobilePhone'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.entityVestingType'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.languagePreference'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.homePhone'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.socialSecNum'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.creditRefNumber'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.dateOfBirth'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.authorizationMethod'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.maritalStatus'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.unmarriedAddendum'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.domesticRelationshipType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.alias.aliasType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.alias.firstName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.alias.middleName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.alias.lastName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.alias.nameSuffix'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address1'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address2'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.city'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.state'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.country'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationYears'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.rent'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = MortgageFieldRequiredState.Requested;

	quickApplyFieldsConfig['mortgage.borrower.employment.employer'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.employment.position'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.employment.employmentType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.employment.employerPhone'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.employment.startDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.employment.endDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.employment.incomes.monthlyIncome'] = MortgageFieldRequiredState.Requested;

	// quickApplyFieldsConfig['mortgage.borrower.employment.address.address1'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.address.address2'] = MortgageFieldRequiredState.None;
	// quickApplyFieldsConfig['mortgage.borrower.employment.address.city'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.address.state'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.address.zipCode'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.address.country'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.aliases'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = MortgageFieldRequiredState.None;
	// quickApplyFieldsConfig['mortgage.borrower.employment.selfEmployed'] = MortgageFieldRequiredState.None;
	// quickApplyFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = MortgageFieldRequiredState.Requested;
	// quickApplyFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.declarations.explanation.description'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.race'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = MortgageFieldRequiredState.Requested;

	// Borrower Military Service
	quickApplyFieldsConfig['mortgage.borrower.hasServedInMilitary'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.branchOfService'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.militaryServiceNumber'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.militaryServedAsName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.militaryTourStartDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = MortgageFieldRequiredState.None;

	// Borrower VA
	quickApplyFieldsConfig['mortgage.borrower.caivrsIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = MortgageFieldRequiredState.None;

	// Borrower Nearest Living Relative
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = MortgageFieldRequiredState.Requested;

	quickApplyFieldsConfig['mortgage.asset.assetType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.asset.depository'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.asset.accountNumber'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.asset.cashMarketValue'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.asset.giftOrGrantSource'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.asset.isDeposited'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.typeOfLiability'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.holderName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.liability.mortgageType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.lienPosition'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.unpaidBalance'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.partialPayoffAmount'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.reoId'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.liability.accountNumber'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.address1'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.city'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.state'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.zipCode'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.county'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = MortgageFieldRequiredState.Required;
	quickApplyFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.marketValue'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.realEstateOwned.percentOwned'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.amount'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.interestRate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lienPosition'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.amortizationType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isConvertible'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.hasENote'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isAssumable'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isAssumed'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.refiPurpose'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.address1'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.address2'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.city'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.state'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.county'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.noOfUnits'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.constructionMethod'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.presentValue'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.occupancyPercent'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.attachmentType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.projectType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.projectDesignType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.improvementCost'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.estate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.trustClassification'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = MortgageFieldRequiredState.Requested;

	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.currentUsageType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.bathroomCount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.bedroomCount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.projectName'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.relatedMortgage.lienType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.relatedMortgage.creditLimit'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.relatedMortgage.isHeloc'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationCity'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationState'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.name'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationNmls'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.stateLicense'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.originatorInformation.email'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.phone'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.extension.balloonTermMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.extension.initialBuydownRate'] = MortgageFieldRequiredState.Requested;

	quickApplyFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.salesContractDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.ausData.negativeAmortizationType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.ausData.armIndexSourceType'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.ausData.armIndexCurrent'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.ausData.armMarginRate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.isRealEstateToBeSold'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.isDwellingCovered'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = MortgageFieldRequiredState.None;

	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.format'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = MortgageFieldRequiredState.Requested;
	quickApplyFieldsConfig['mortgage.borrower.signingRole'] = MortgageFieldRequiredState.Requested;

	quickApplyFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = MortgageFieldRequiredState.None;
	quickApplyFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = MortgageFieldRequiredState.None;

	return quickApplyFieldsConfig;
}

export function loadDefaultUrlaFieldsConFig(): MortgageModelFieldsConfig {
	let urlaFieldsConfig = {};
	urlaFieldsConfig['mortgage.borrower.firstName'] =  new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.middleName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nameSuffix'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.primaryEmail'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.mobilePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.homePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.socialSecNum'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.creditRefNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.authorizationMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.maritalStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.unmarriedAddendum'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.domesticRelationshipType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.entityVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.aliasType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.firstName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.alias.middleName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.alias.nameSuffix'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.rent'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.employer'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.position'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.startDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.endDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.address.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.address.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.address.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.address.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.address.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.employerPhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.aliases'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmployed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nonEmploymentIncomes.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.race'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	// Borrower Military Service
	urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.branchOfService'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryServiceNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryServedAsName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryTourStartDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryTourEndDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isVaFundingFeeExempt'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	// Borrower VA
	urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	// Borrower Nearest Living Relative
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.asset.assetType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.depository'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.accountNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.cashMarketValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.giftOrGrantSource'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.isDeposited'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.monthlyPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.holderName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.accountNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.typeOfLiability'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.marketValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.percentOwned'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.amount'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.interestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.lienPosition'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.amortizationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isConvertible'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasENote'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAssumable'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAssumed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.refiPurpose'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.county'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.presentValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.attachmentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.projectType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.projectDesignType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.improvementCost'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.estate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.trustClassification'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.bathroomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.bedroomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.relatedMortgage.lienType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.creditLimit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.isHeloc'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.name'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.stateLicense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.email'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.phone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.balloonTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.initialBuydownRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.transactionDetail.purchasePriceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.salesContractDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.ausData.negativeAmortizationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armIndexSourceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armIndexCurrent'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armMarginRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.isRealEstateToBeSold'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.isDwellingCovered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingFirstPaymentDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.format'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.signingRole'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['changeOfCircumstance.disclosureReason'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['cchangeOfCircumstance.changeOfCircumstanceReason'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.feeDetail'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['legalDescriptions.fullDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.abbreviatedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.otherUnparsedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['legalDescriptions.governmentSurveyDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.landGrantDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.metesAndBoundsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.nativeAmericanLandDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.rancheroDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.otherParsedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['parcelIdentifications.parcelIdentificationNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.assessorUnformattedIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.torrensCertificateIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.identificationTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	return urlaFieldsConfig as MortgageModelFieldsConfig;
}

export function loadDeepHavenUrlaFieldsConFig(): MortgageModelFieldsConfig {
	let urlaFieldsConfig = {} as MortgageModelFieldsConfig;
	urlaFieldsConfig['mortgage.borrower.firstName'] =  new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.middleName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.nameSuffix'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.mortgagePartyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.primaryEmail'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.mobilePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.homePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.socialSecNum'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.creditRefNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.authorizationMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.maritalStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.unmarriedAddendum'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.domesticRelationshipType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.entityVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.alias.aliasType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.firstName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.alias.middleName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.alias.nameSuffix'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.rent'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.borrower.employment.employer'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.position'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.startDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.endDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.address.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.address.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.address.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.address.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.address.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.employerPhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.aliases'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmployed'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.borrower.nonEmploymentIncomes.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.race'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	// Borrower Military Service
	urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.branchOfService'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryServiceNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryServedAsName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryTourStartDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.militaryTourEndDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isVaFundingFeeExempt'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	// Borrower VA
	urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	// Borrower Nearest Living Relative
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.asset.assetType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.depository'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.accountNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.cashMarketValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.giftOrGrantSource'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.asset.isDeposited'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.monthlyPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.holderName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.accountNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.liability.typeOfLiability'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.marketValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.percentOwned'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.amount'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.interestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.lienPosition'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.amortizationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isConvertible'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasENote'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAssumable'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAssumed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.refiPurpose'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.subjectProperty.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.subjectProperty.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.subjectProperty.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.subjectProperty.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.subjectProperty.county'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.subjectProperty.constructionMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.presentValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.attachmentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.projectType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.projectDesignType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.improvementCost'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.estate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.trustClassification'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);



	urlaFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.currentUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.bathroomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.bedroomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.subjectProperty.projectName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.relatedMortgage.lienType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.creditLimit'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.isHeloc'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, false, true);
	urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.name'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, false, true);
	urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.stateLicense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.stateLicenseState'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState'] = new MortgageFieldConfig(MortgageFieldRequiredState.None, true);
	urlaFieldsConfig['mortgage.originatorInformation.email'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.phone'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.balloonTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.extension.initialBuydownRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);

	urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.transactionDetail.purchasePriceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.salesContractDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.ausData.negativeAmortizationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armIndexSourceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armIndexCurrent'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.armMarginRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.isRealEstateToBeSold'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.isDwellingCovered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.format'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
	urlaFieldsConfig['mortgage.borrower.signingRole'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);

	urlaFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['changeOfCircumstance.disclosureReason'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	urlaFieldsConfig['cchangeOfCircumstance.changeOfCircumstanceReason'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.feeDetail'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['legalDescriptions.fullDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.abbreviatedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.otherUnparsedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['legalDescriptions.governmentSurveyDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.landGrantDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.metesAndBoundsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.nativeAmericanLandDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.rancheroDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.otherParsedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['parcelIdentifications.parcelIdentificationNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.assessorUnformattedIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.torrensCertificateIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.identificationTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	return urlaFieldsConfig;
}

export function updateUrlaFieldConfigBasedOnMortgage(urlaFieldsConfig, mortgage: UrlaMortgage, isLinkedToLos: boolean) {

	//HACK - REMOVE ME AFTER CONFIGURABLE
	if (mortgage.companyId == 229) {
		urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
		urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	}

	if (mortgage.companyId == 256) {
		urlaFieldsConfig['mortgage.borrower.firstName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
		urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
		urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	}

	urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = isLinkedToLos ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = isLinkedToLos ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = isLinkedToLos ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);

	//this validation should be geared toward loan operation... Only when drawing docs... LATER
	urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = isLinkedToLos ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = mortgage.mortgageTerm.mortgageAppliedFor == "FHA" ? new MortgageFieldConfig(MortgageFieldRequiredState.Required) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = mortgage.mortgageTerm.mortgageAppliedFor == "FHA" ? new MortgageFieldConfig(MortgageFieldRequiredState.Required) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = mortgage.mortgageTerm.mortgageAppliedFor == "VA" ? new MortgageFieldConfig(MortgageFieldRequiredState.Required) : new MortgageFieldConfig(MortgageFieldRequiredState.None);

	if (mortgage.subjectProperty.propertyWillBe == "Investment" && mortgage.subjectProperty.expectedNetMonthlyRentalIncome > 0) {
		const borr = MortgageUtils.getPrimaryBorrowerOrFirst(mortgage);
		if (borr && (borr.hasOneYearLandlordExperience == null || borr.hasOneYearLandlordExperience == undefined)) {
			urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
		}
	}
	else {
		urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	}

	switch (mortgage.mortgageTerm.mortgageAppliedFor) {
		case "VA":
			urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
			urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = mortgage.subjectProperty.refinanceProgram == "InterestRateReductionRefinanceLoan" ? new MortgageFieldConfig(MortgageFieldRequiredState.None) : new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
			urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = mortgage.subjectProperty.purposeOfLoan == LoanPurposeTypeEnum.Refinance ? new MortgageFieldConfig(MortgageFieldRequiredState.Requested) : new MortgageFieldConfig(MortgageFieldRequiredState.None);
			break;

		case "FHA":
			urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
			urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
			urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			break;

		case "Conventional":
			urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
			urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Requested);
			urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			break;

		default:
			urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
			break;
	}

	if ([229, 276].includes(mortgage.companyId)) {
		urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.Required);
	}

	if (mortgage.companyId == 222 && mortgage.channel === ChannelEnum.Correspondent) {
		urlaFieldsConfig['mortgage.borrower.firstName'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.lastName'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.socialSecNum'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.mortgageTerm.amount'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.address1'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.city'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.state'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.zipCode'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.county'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.mortgageTerm.interestRate'].required = MortgageFieldRequiredState.Required;
		urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'].required = MortgageFieldRequiredState.Required;
	}

	return urlaFieldsConfig;
}

export function allFieldStateNone(): MortgageModelFieldsConfig {
	const urlaFieldsConfig = {};
	urlaFieldsConfig['mortgage.borrower.firstName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.middleName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nameSuffix'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.primaryEmail'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.mobilePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.homePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.socialSecNum'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.creditRefNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.authorizationMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.maritalStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.unmarriedAddendum'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.domesticRelationshipType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.aliasType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.firstName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.middleName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.lastName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.alias.nameSuffix'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.entityVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.rent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.employer'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.position'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.startDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.endDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.address.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.employerPhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.aliases'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmployed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nonEmploymentIncomes.monthlyIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.race'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	// Borrower Military Service
	urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.branchOfService'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryServiceNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryServedAsName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryTourStartDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.militaryTourEndDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isVaFundingFeeExempt'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	// Borrower VA
	urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	// Borrower Nearest Living Relative
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.asset.assetType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.asset.depository'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.asset.accountNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.asset.cashMarketValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.asset.giftOrGrantSource'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.asset.isDeposited'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.liability.monthlyPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.liability.holderName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.liability.accountNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.liability.typeOfLiability'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.country'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.marketValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.percentOwned'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.amount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.interestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lienPosition'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.amortizationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isConvertible'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasENote'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAssumable'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isAssumed'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.refiPurpose'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.address1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.address2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.city'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.state'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.zipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.county'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.constructionMethod'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.constructionStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.presentValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.attachmentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectDesignType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.improvementCost'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.estate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.trustClassification'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.currentUsageType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.bathroomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.bedroomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.relatedMortgage.lienType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.relatedMortgage.creditLimit'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.relatedMortgage.isHeloc'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationState'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.name'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.stateLicense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.email'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.phone'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.balloonTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.initialBuydownRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.transactionDetail.purchasePriceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.salesContractDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.negativeAmortizationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.armIndexSourceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.armIndexCurrent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.armMarginRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.isRealEstateToBeSold'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.isDwellingCovered'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingFirstPaymentDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.format'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.borrower.signingRole'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['changeOfCircumstance.disclosureReason'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.disclosureComments'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['cchangeOfCircumstance.changeOfCircumstanceReason'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['changeOfCircumstance.feeDetail'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['legalDescriptions.fullDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.abbreviatedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.otherUnparsedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['legalDescriptions.governmentSurveyDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.landGrantDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.metesAndBoundsDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.nativeAmericanLandDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.rancheroDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['legalDescriptions.otherParsedDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	urlaFieldsConfig['parcelIdentifications.parcelIdentificationNumber'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.assessorUnformattedIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.torrensCertificateIdentifier'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
	urlaFieldsConfig['parcelIdentifications.identificationTypeOtherDescription'] = new MortgageFieldConfig(MortgageFieldRequiredState.None);

	return urlaFieldsConfig as MortgageModelFieldsConfig;
}
