<ng-container *ngIf="taskCategories; else loading">
  <div class="row mb-2">
    <div class="checkbox" *ngFor="let category of taskCategories | sort: 'asc':'order'">
      <input id="char_{{ category.taskCategoryId }}_{{ id }}" name="char_{{ category.taskCategoryId }}_{{ id }}"
        type="checkbox" (change)="onCharacteristicCheckChanged(category.taskCategoryId)"
        [checked]="isCharacteristicCheckedForLoan(category.taskCategoryId)" />
      <label for="char_{{ category.taskCategoryId }}_{{ id }}">
        {{ category.taskCategoryName }}
      </label>
      <span *ngIf="category.taskOption === 'RequestQuantity'">
        <a class="text-primary" (click)="openEditCharacteristicDialog(category.taskCategoryId, false)">
          {{
          '(' + getCharacteristicQuantity(category.taskCategoryId) + ')'
          }}</a>
      </span>
    </div>
  </div>
  <div>
    <button class="btn btn-outline-primary" type="button" [disabled]="isPullingHistory"
      (click)="onViewHistoryClicked()">
      <span *ngIf="isPullingHistory" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ isPullingHistory ? 'Please wait...' : 'View History' }}
    </button>
    <button class="btn btn-primary float-end" type="button" [disabled]="!thereAreChanges" (click)="onSaveClicked()">
      <span *ngIf="isSavingCharacteristics" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      {{ isSavingCharacteristics ? 'Please wait...' : 'Save' }}
    </button>
    <button class="btn btn-outline-primary float-end" type="button" (click)="onCancel()">
      Cancel
    </button>
  </div>
</ng-container>
<ng-template #loading>
  <loading-indicator [loadingMessage]="''"></loading-indicator>
</ng-template>