import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '../../../../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import {
    ThirdPartyCredential,
    ThirdPartyCredentialArray,
    ThirdPartyCredentialSchemaReadonlyArray,
    ThirdPartyCredentialType,
    UserCompanyThirdPartyCredentialArray
} from '../../../../../../models';
import * as _ from 'lodash';
import { CredentialModalV2Component } from '../credential-modal-v2/credential-modal-v2.component';
import { ScopeType } from '../../integrations.component';

@Component({
    selector: 'create-credential-v2',
    templateUrl: './create-credential-v2.component.html',
    styleUrls: ['./create-credential-v2.component.scss']
})
export class CreateCredentialV2Component implements OnInit {

    @Input()
    scope: ScopeType;

    @Input()
    credentialType: ThirdPartyCredentialType;

    @Input()
    userCompanyGuid: string;

    @Input()
    credentialSchema: ThirdPartyCredentialSchemaReadonlyArray = [];

    @Input()
    credentials: ThirdPartyCredentialArray;

    @Input()
    companyCredentials?: UserCompanyThirdPartyCredentialArray = []; // for user only

    @Input()
    isTpoUser: boolean | null;
  
    @Input()
    saveAndTest: boolean | null;

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
    ) {}

    ngOnInit(): void {}

    openCredentialModal(): void {
        const credential = new ThirdPartyCredential(this.credentialType)
        this._thirdPartyCredentialsService.getNewCredential(this.credentialType);

        const modalRef = this._modalService.open(CredentialModalV2Component, Constants.modalOptions.large);
        modalRef.componentInstance.credential = _.cloneDeep(credential);
        modalRef.componentInstance.credentialSchema = this.credentialSchema.map(schema => ({
            ...schema,
            kvPairs: this._thirdPartyCredentialsService.filterSchemaKvPairs(this.scope, schema.kvPairs),
        }));
        modalRef.componentInstance.scope = this.scope;
        modalRef.componentInstance.isLosCredentials = true;
        modalRef.componentInstance.userCompanyGuid = this.userCompanyGuid;
        modalRef.componentInstance.isTpoUser = this.isTpoUser;
        modalRef.componentInstance.saveAndTest = this.saveAndTest;
        modalRef.componentInstance.onCredentialUpserted.subscribe(result => {
            this.onCredentialInserted(result);
        })

        if (this.scope === "User" || this.scope === "TpoUser") {
            modalRef.componentInstance.companyCredentials = this.companyCredentials;
        }

        modalRef.result.then(
            (result) => {
                this.onCredentialInserted(result);
            },
            () => {}
        );
    }

    private onCredentialInserted(credential: ThirdPartyCredential) {
        const index = this.credentials.findIndex(c => c.credentialId === credential.credentialId);
        if (index > -1) {
            this.credentials[index] = _.cloneDeep(credential); // when save and test is clicked and then save
        } else {
            this.credentials.push(_.cloneDeep(credential));
        }
    }
}
