import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { Mention, MentionMessage, MentionMessageScope } from 'src/app/models/mention.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { MentionsService } from 'src/app/services/mentions.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MentionItemComponent, MentionViewModel } from '../mention-item/mention-item.component';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { Subscription } from 'rxjs';
import { ApplicationContext } from 'src/app/models';

@Component({
  selector: 'mentions-stream',
  templateUrl: './mentions-stream.component.html',
  styleUrls: ['./mentions-stream.component.scss']
})
export class MentionsStreamComponent implements OnInit, OnDestroy {

  readMentions: MentionViewModel[] = [];
  unreadMentions: MentionViewModel[] = [];

  isLoadingUnread: boolean = false;
  isLoadingRead: boolean = false;
  markingAsRead: boolean = false;

  currentReadPage: number = 1;
  currentUnreadPage: number = 1;
  pageSize: number = 15;

  allReadRecordsHaveBeenLoaded: boolean = false;
  allUnreadRecordsHaveBeenLoaded: boolean = false;

  showReadMentions = false;

  private _context: ApplicationContext;

  @Output()
  mentionClicked: EventEmitter<MentionViewModel> = new EventEmitter<MentionViewModel>();

  @ViewChildren("unreadMentionItem")
  unreadMentionItems: QueryList<MentionItemComponent>

  @ViewChildren("readMentionItem")
  readMentionItems: QueryList<MentionItemComponent>

  private _applicationContextServiceSubscription: Subscription;
  private readonly _mentionsServiceSubscription: Subscription;

  constructor(
    private readonly _mentionsService: MentionsService,
    private readonly _environment: EnvironmentService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notifyService: NotificationService,
  ) {
    this._mentionsServiceSubscription = this._mentionsService.events.subscribe(event => {
      if (!event) return;
      if (event.type === "reload") {
        setTimeout(this.initialize, 1000);
      }
    })
  }

  ngOnInit(): void {
    this._applicationContextServiceSubscription = this._applicationContextService.context.subscribe(context => {
      this._context = context;
      this.initialize();
    });
  }

  ngOnDestroy(): void {
    this._applicationContextServiceSubscription?.unsubscribe();
    this._mentionsServiceSubscription?.unsubscribe();
  }

  onScroll = (readScroll: boolean = false) => {
    if (readScroll) {
      this.loadMoreRead();
    } else {
      this.loadMoreUnread();
    }
  }

  onMentionClicked = (mentionItem: MentionViewModel) => {
    this.mentionClicked.emit(mentionItem);
  }

  onShowReadMentionsClicked = () => {
    this.initReadMentions();
  }

  markAllAsRead = () => {
    if (this.unreadMentionItems.length === 0) return;

    this.unreadMentionItems.forEach(component => {
      component.mentionTrackingUrl = `${this._environment.apiInfo.apiBaseUrl}mentions/pixel/${this._context.userPermissions.userId}/${component.mention.trackingGuid}`;
    })

    this._mentionsService.publish({ type: "reload" })
  }

  onLoadMore = (forRead?: boolean) => {
    if (forRead) {
      this.loadMoreRead();
    } else {
      this.loadMoreUnread()
    }
  }

  private initialize = () => {
    this.currentReadPage = 1;
    this.currentUnreadPage = 1;
    this.allReadRecordsHaveBeenLoaded = false;
    this.allUnreadRecordsHaveBeenLoaded = false;

    this.initUnreadMentions();

    if (this.showReadMentions) {
      this.initReadMentions();
    }
  }

  private initUnreadMentions = () => {
    this.isLoadingUnread = true; // unread on init
    this._mentionsService.getMentions({
      pageNumber: this.currentUnreadPage,
      pageSize: this.pageSize,
      forRead: false
    }).subscribe({
      next: (mentionsInfo) => {
        this.unreadMentions = mentionsInfo.mentions.map(m => this.toViewModel(m));

        if (mentionsInfo.mentions.length === 0) {
          this.allUnreadRecordsHaveBeenLoaded = true;
        } else {
          this.currentUnreadPage++;
        }
      },
      error: (err) => {
        this._notifyService.showError(err?.message || "Couldn't load unread mentions.", "Error!");
      }
    }).add(() => this.isLoadingUnread = false);
  }

  private initReadMentions = () => {
    this.showReadMentions = true;
    this.isLoadingRead = true; // unread on init
    this._mentionsService.getMentions({
      pageNumber: this.currentReadPage,
      pageSize: this.pageSize,
      forRead: true
    }).subscribe({
      next: (mentionsInfo) => {
        this.readMentions = mentionsInfo.mentions.map(m => this.toViewModel(m));

        if (mentionsInfo.mentions.length === 0) {
          this.allReadRecordsHaveBeenLoaded = true;
        } else {
          this.currentReadPage++;
        }
      },
      error: (err) => {
        this._notifyService.showError(err?.message || "Couldn't load read mentions.", "Error!");
      }
    }).add(() => this.isLoadingRead = false);
  }

  private loadMoreUnread = () => {
    if (this.isLoadingUnread) return;
    if (this.allUnreadRecordsHaveBeenLoaded) return;

    this.isLoadingUnread = true;

    this._mentionsService.getMentions({
      pageNumber: this.currentUnreadPage,
      pageSize: this.pageSize,
      forRead: false
    }).subscribe({
      next: (mentionsInfo) => {
        if (mentionsInfo.mentions.length === 0) {
          // All unread mentions are loaded - stop!
          this.allUnreadRecordsHaveBeenLoaded = true;
        } else {
          this.unreadMentions = this.unreadMentions.concat(mentionsInfo.mentions.map(m => this.toViewModel(m)));
          this.currentUnreadPage++;
        }
      },
      error: (err) => {
        this._notifyService.showError(err?.message || "Couldn't load unread mentions.", "Error!");
      }
    }).add(() => this.isLoadingUnread = false);
  }

  private loadMoreRead = () => {
    if (this.isLoadingRead) return;
    if (this.allReadRecordsHaveBeenLoaded) return;

    this.isLoadingRead = true;
    this._mentionsService.getMentions({
      pageNumber: this.currentReadPage,
      pageSize: this.pageSize,
      forRead: true,
    }).subscribe({
      next: (mentionsInfo) => {
        if (mentionsInfo.mentions.length === 0) {
          // All read mentions are loaded - stop!
          this.allReadRecordsHaveBeenLoaded = true;
        } else {
          this.readMentions = this.readMentions.concat(mentionsInfo.mentions.map(m => this.toViewModel(m)));
          this.currentReadPage++;
        }
      },
      error: (err) => {
        this._notifyService.showError(err?.message || "Couldn't load read mentions.", "Error!");
      }
    }).add(() => this.isLoadingRead = false);
  }

  private toViewModel = (mention: Mention): MentionViewModel => {
    let viewModel = new MentionViewModel();
    viewModel.fromFullName = this._context.globalConfig.getUserFullName(mention.fromUserId);
    viewModel.fromAvatarUrl = this._context.globalConfig.getUserAvatarUrl(mention.fromUserId);
    viewModel.content = MentionsUtils.generateDisplayHtmlWithMentions(mention.messageModel.content);
    viewModel.timeAgo = Utils.timeAgo(mention.dateInserted);
    viewModel.messageId = mention.messageModel.messageId;
    viewModel.messageScope = mention.messageModel.messageScope;
    viewModel.entityId = this.getEntityIdByScope(mention.messageModel);
    viewModel.trackingGuid = mention.trackingGuid;
    viewModel.openDate = mention.openDate;
    viewModel.applicationId = mention.messageModel.applicationId;
    viewModel.borrowerId = mention.messageModel.borrowerId;
    return viewModel;
  }

  private fixMentionDisplayText = (displayText: string): string => {
    const regexp = new RegExp(/@\{(?<display>[\w ]+):(?<type>[\w ]+)(?::(?<key>[\w-])+)?\}/g);
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      return displayText;
    }
    const fixedDisplayText = displayText.replace(results[0], `<a class='mention'>@${results[1]}</a>`);
    return this.fixMentionDisplayText(fixedDisplayText);
  }

  private getEntityIdByScope = (mentionMessage: MentionMessage): number => {
    switch (mentionMessage.messageScope) {
      case MentionMessageScope.InternalMessage:
        return mentionMessage.applicationId;
      case MentionMessageScope.TaskNote:
        return mentionMessage.loanDocTaskId;
    }
  }
}
