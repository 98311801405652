<ng-container *ngIf="!isDetailViewActive">
  <h4 class="d-flex align-items-center justify-content-between">
    <span class="text-muted">Calls</span>
    <span>
      <generic-date-range-filter [label]="filter.dateRange?.displayText" [icon]="'fa fa-filter'" [popoverLocation]="'bottom'"
        [dateRange]="filter.dateRange" [clearVisible]="false" (filtersChanged)="onRangesFilterChanged($event)">
      </generic-date-range-filter>
    </span>
  </h4>
  <div class="w-100" style="height: 40px;">
    <span class="p-input-icon-right w-100 h-100">
      <i class="pi pi-search"></i>
      <input pInputText type="text" class="form-control h-100" style="min-height: 2.5rem;" (input)="search($event.target.value)"
        [disabled]="isLoading" placeholder="Search keyword" />
    </span>
  </div>
  <div class="w-100 mt-2 p-2 call-dates-list d-flex flex-column flex-grow-1" *ngIf="!isLoading" id="call-dates-list">
    <ng-container *ngFor="let date of callDates">
      <label class="control-label my-1">{{ date }}</label>
      <div class="w-100 summary-container">
        <div class="row summary-row" [class.me-1]="hasListScroll" title="Go To Detail"
          *ngFor="let callHistory of timeGroupedCallHistory?.get(date)" (click)="goToDetail(callHistory)">
          <div class="col-2">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center">
              <div class="border rounded-circle p-2"
                [ngClass]="{'outgoing-call': callHistory.direction == 'Outbound', 'incoming-call': callHistory.direction == 'Inbound'}">
                <!-- outgoing-call.svg -->
                <svg *ngIf="callHistory.direction == 'Outbound'" xmlns="http://www.w3.org/2000/svg" width="20"
                  height="20" viewBox="0 0 64 64" stroke-width="3" stroke="currentColor" fill="none">
                  <path
                    d="M11.11,8.4a2.62,2.62,0,0,0-2.53,2.78c.35,6,2,20.64,9.9,29.77,9.46,11,21.78,14.79,34.42,14.23a2.68,2.68,0,0,0,2.52-2.65V42.92a4,4,0,0,0-3.09-3.86L46,37.66a4,4,0,0,0-4.16,1.69l-1.4,2.12a1,1,0,0,1-1.22.37C36,40.45,23.17,34.45,21.76,24.33a1,1,0,0,1,.48-1l2.54-1.55a4,4,0,0,0,1.81-4.21L25.2,11.13a4,4,0,0,0-4-3.12Z" />
                  <polyline points="41.7 10.21 53.34 10.53 53.02 22.17" />
                  <line x1="53.34" y1="10.53" x2="37.11" y2="25.89" />
                </svg>

                <!-- incoming-call.svg -->
                <svg *ngIf="callHistory.direction == 'Inbound'" xmlns="http://www.w3.org/2000/svg" width="20"
                  height="20" viewBox="0 0 64 64" stroke-width="3" stroke="currentColor" fill="none">
                  <path
                    d="M11.11,8.4a2.62,2.62,0,0,0-2.53,2.78c.35,6,2,20.64,9.9,29.77,9.46,11,21.78,14.79,34.42,14.23a2.68,2.68,0,0,0,2.52-2.65V42.92a4,4,0,0,0-3.09-3.86L46,37.66a4,4,0,0,0-4.16,1.69l-1.4,2.12a1,1,0,0,1-1.22.37C36,40.45,23.17,34.45,21.76,24.33a1,1,0,0,1,.48-1l2.54-1.55a4,4,0,0,0,1.81-4.21L25.2,11.13a4,4,0,0,0-4-3.12Z" />
                  <polyline points="48.75 25.89 37.12 25.57 37.43 13.94" />
                  <line x1="37.11" y1="25.57" x2="53.34" y2="10.21" />
                </svg>
              </div>
            </div>
          </div>
          <div class="col-6">
            <h5 class="mb-0" style="margin-top: 12px;">
              <a class="nav-link d-inline p-0" title="Call Details" (click)="$event.stopPropagation(); goToDetail(callHistory)">
                {{ (callHistory.toWithoutCountryCode | mask: '(000) 000-0000') || '--' }}
              </a>
            </h5>
            <div>{{ callHistory.durationText }}</div>
          </div>
          <div class="col-4">
            <div class="d-flex align-items-center justify-content-end" style="margin-top: 18px;">
              <ng-container *ngIf="callHistory.callRecorded">
                <a (click)="$event.stopPropagation(); loadRecordingDetails(callHistory.voiceHistoryId)"
                title="Play Recording" class="btn btn-xs btn-soft-primary me-1" href="javascript: void(0);">
                <i class="fa fa-play-circle" aria-hidden="true"></i>
                </a>
                <a (click)="$event.stopPropagation(); downloadRecordingDetails(callHistory.voiceHistoryId)"
                  title="Download Recording" class="btn btn-xs btn-soft-primary me-1" href="javascript: void(0);">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </ng-container>
              <a (click)="$event.stopPropagation(); openCallPanel(callHistory)"
                title="Dial Number" class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
                <i class="fa fa-phone" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="isLoading" class="h-100 px-2">
    <loading-indicator [customClass]="'no-shadow'" loadingMessage="Loading..."></loading-indicator>
  </div>
</ng-container>
<ng-container *ngIf="isDetailViewActive">
  <h4 class="d-flex align-items-center mb-4">
    <span class="flex-grow-1 text-start text-muted">Details</span>
    <span class="text-primary cursor" (click)="goToList()" title="Go To List"><i class="fas fa-arrow-left me-2"></i>
      Calls</span>
  </h4>
  <div class="call-details flex-grow-1">
    <h4 class="d-flex align-items-center justify-content-between mb-2">
      <span class="text-muted">Summary</span>
      <span> </span>
    </h4>
    <div class="card me-1" style="height: 300px; overflow: auto;">
      <div class="card-body">
        <h2>Transcript</h2>
        <div>Coming soon...</div>
      </div>
    </div>
    <h4 class="d-flex align-items-center justify-content-between my-2">
      <span class="text-muted">Information</span>
      <span></span>
    </h4>
    <div class="summary-detail-container mb-1">
      <div class="row summary-detail-row">
        <div class="col-6"><span class="text-muted">Direction</span></div>
        <div class="col-6 text-end">{{ selectedCallHistory.direction || '--' }}</div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">From</span></div>
        <div class="col-6 text-end">{{ (selectedCallHistory.fromWithoutCountryCode | mask: '(000) 000-0000' ) || '--'
          }}
        </div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">To</span></div>
        <div class="col-6 text-end">{{ (selectedCallHistory.toWithoutCountryCode | mask: '(000) 000-0000' ) || '--'
          }}
        </div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">Start Date</span></div>
        <div class="col-6 text-end">{{ selectedCallHistory.startDate | date: 'longDate' }}</div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">End Date</span></div>
        <div class="col-6 text-end">{{ selectedCallHistory.endDate | date: 'longDate' }}</div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">Duration</span></div>
        <div class="col-6 text-end">
          <span class="text-success badge badge-soft-success"> {{ selectedCallHistory.durationText || '--' }} </span>
        </div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">Disposition</span></div>
        <div class="col-6 text-end">{{ selectedCallHistory.disposition || '--' }}</div>
      </div>
      <div class="row summary-detail-row">
        <div class="col-6 "><span class="text-muted">Actions</span></div>
        <div class="col-6 text-end">
          <div *ngIf="selectedCallHistory.callRecorded">
            <a (click)="loadRecordingDetails(selectedCallHistory.voiceHistoryId)" title="Play Recording"
              class="btn btn-xs btn-soft-primary me-2" href="javascript: void(0);">
              <i class="fa fa-play-circle" aria-hidden="true"></i>
            </a>
            <a (click)="downloadRecordingDetails(selectedCallHistory.voiceHistoryId)" title="Download Recording"
              class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
              <i class="fa fa-download" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
