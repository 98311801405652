<!-- row1 -->
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="aeName">AE Name</label>
    <input
      id="aeName"
      type="text"
      name="aeName"
      class="form-control"
      [(ngModel)]="lender.aeName"
      #firstNameInput="ngModel"
    />
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="website">Website</label>
    <div class="d-flex align-items-center justify-content-between">
      <input id="website" type="url" name="website" class="form-control" [(ngModel)]="lender.website"
        pattern="{{urlPattern}}" #websiteInput="ngModel"
        [ngClass]="{'is-invalid' : websiteInput && websiteInput.touched && websiteInput.invalid}" />
      <a class="ms-2" *ngIf="lender.website && websiteInput.valid" href="{{lender.website}}" target="_new">
        <i class="fa fa-globe"></i>
      </a>
    </div>
    <div class="invalid-feedback d-block" *ngIf="websiteInput.errors?.['pattern']">Please enter a valid website url</div>
  </div>
</div>
<!-- row 2 -->
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="aeMobile"
      >Mobile Number &nbsp;&nbsp;<a
        [hidden]="!lender.aeMobile"
        href="tel:{{ lender.aeMobile }}"
        ><i class="fa fa-fw fa-phone text-muted"></i
      ></a>
    </label>
    <input
      class="form-control"
      id="aeMobile"
      name="aeMobile"
      type="text"
      [(ngModel)]="lender.aeMobile"
      mask="(000) 000-0000"
      placeHolderCharacter="X"
      [showMaskTyped]="true"
      #aeMobileInput="ngModel"
      [ngClass]="{'is-invalid' : aeMobileInput && aeMobileInput.touched && aeMobileInput.invalid}"
    />
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
  <div class="form-group col-xs-12 col-sm-4 col-lg-3">
    <label for="aePhone"
      >Phone Number &nbsp;&nbsp;<a
        [hidden]="!lender.aePhone"
        href="tel:{{ lender.aePhone }}"
        ><i class="fa fa-fw fa-phone text-muted"></i
      ></a>
    </label>
    <input
      class="form-control"
      id="aePhone"
      name="aePhone"
      type="text"
      [(ngModel)]="lender.aePhone"
      mask="(000) 000-0000"
      placeHolderCharacter="X"
      [showMaskTyped]="true"
      #aePhoneInput="ngModel"
      [ngClass]="{'is-invalid' : aePhoneInput && aePhoneInput.touched && aePhoneInput.invalid}"
    />
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="aeFax">AE Fax</label>
    <input
      id="aeFax"
      name="aeFax"
      class="form-control"
      placeHolderCharacter="X"
      [mask]="'(000) 000-0000'"
      [showMaskTyped]="true"
      [(ngModel)]="lender.aeFax"
      #aeFaxInput="ngModel"
      [ngClass]="{'is-invalid' : aeFaxInput && aeFaxInput.touched && aeFaxInput.invalid}"
    />
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="aeEMail">Email Address</label>
    <input
      id="aeEmail"
      name="aeEmail"
      [ngClass]="{'is-invalid' : aeEmail && aeEmail.touched && aeEmail.invalid}"
      type="email"
      email
      trim
      class="form-control"
      #aeEmail="ngModel"
      placeholder="example@domain.com"
      [(ngModel)]="lender.aeEmail"
    />
    <div class="invalid-feedback">Please enter a valid e-mail address.</div>
  </div>
</div>
