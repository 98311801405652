import {FeeViewModel} from '../fee-view-model';
import {NotificationService} from '../../../services/notification.service';
import {cloneDeep} from 'lodash';
import {BehaviorSubject, catchError, defer, EMPTY, map, Observable, skip, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {FeeContextService} from './fee-context.service';
import {FeeUtils} from '../utils/utils';
import {FeeDataAccessService} from './fee-data-access.service';
import areFeeReferencesEqual = FeeUtils.areFeeReferencesEqual;

@Injectable()
export class FeeUpdaterService {
  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _feeDataAccessService: FeeDataAccessService,
    private readonly _feeContextService: FeeContextService
  ) {}

  /**
   * Creates a new instance of {@link FeeUpdater} with the given fee.
   * @param {FeeViewModel} fee - The fee to be updated.
   * @returns {FeeUpdater} The instance of {@link FeeUpdater}.
   * @remarks The instance of {@link FeeUpdater} should be destroyed by calling
   * {@link FeeUpdater.destroy} when it's no longer needed to avoid memory
   * leaks.
   */
  public createUpdater(fee: FeeViewModel): FeeUpdater {
    return new FeeUpdater(
      fee,
      this._notificationService,
      this._feeDataAccessService,
      this._feeContextService
    );
  }
}

interface SetFeeOptions {
  preserveOriginalFees?: boolean;
}

/**
 * This class is responsible for updating the fee.
 * It is created with the ability to be saved multiple times in mind.
 * This is why we need to keep track of the last saved fee.
 * To compare the current fee with the last saved one for dirty checking.
 * (We want to avoid unnecessary API calls on blur if the value is not changed.)
 *
 * @remarks This class is not meant to be used directly.
 * Use {@link FeeUpdaterService} to create an instance of this class.
 * @remarks The instance of this class should be destroyed by calling
 * {@link destroy} when it's no longer needed to avoid memory leaks.
 */
export class FeeUpdater {
  private _originalFee: FeeViewModel = FeeViewModel.empty();
  private _lastUpdatedFee: FeeViewModel = FeeViewModel.empty();

  public get fee(): FeeViewModel {
    return this._fee;
  }

  private _updatingState$ = new BehaviorSubject<boolean>(false);
  public readonly updatingState$ = this._updatingState$.asObservable();

  private _updateSubscription?: Subscription;

  private _fee$ = new BehaviorSubject<FeeViewModel>(FeeViewModel.empty());
  public readonly fee$ = this._fee$.asObservable();

  private get _fee(): FeeViewModel {
    return this._fee$.value;
  }

  constructor(
    fee: FeeViewModel,
    private readonly _notificationService: NotificationService,
    private readonly _feeDataAccessService: FeeDataAccessService,
    private readonly _feeContextService: FeeContextService,
  ) {
    this.setFee(fee);
  }

  destroy() {
    this._updateSubscription?.unsubscribe();
  }

  private setFee(fee: FeeViewModel, options?: SetFeeOptions): void {
    options = {
      preserveOriginalFees: options?.preserveOriginalFees ?? false,
    };

    if (!options.preserveOriginalFees) {
      this._originalFee = cloneFeeViewModel(fee);
    }

    const clone = () => cloneFeeViewModel(fee);

    this._lastUpdatedFee = clone();
    this._fee$.next(clone());
    this._updatingState$.next(false);
  }

  private update$(): Observable<FeeViewModel> {
    return defer(() => {
      return this._feeDataAccessService.updateFee({
        value: this._fee.fee,
        existingFees: this._feeContextService.fees,
      });
    }).pipe(
      tap((fees) => {
        const predicate = areFeeReferencesEqual.bind(null, this._fee.fee);
        const fee = fees.find(predicate);

        if (fee == null) {
          throw new Error('The updated fee was not found in the result');
        }

        // @ts-ignore - Allow patching the object in this service.
        this.setFee(FeeViewModel.fromFee(fee), {preserveOriginalFees: true});
      }),
      catchError(error => {
        const defaultMessage = 'An error occurred while updating the fees';
        console.error(defaultMessage, error);

        const message =
          error?.message ?? error?.error?.message ?? defaultMessage;
        this._notificationService.showError(message, 'Error');

        // Undo the changes because the update failed.
        this.setFee(this._lastUpdatedFee, {preserveOriginalFees: true});

        return EMPTY;
      }),
      map(() => this._fee),
    );
  }

  /**
   * Updates the payers of the fee. The updating is an async operation. The result of the operation
   * can be observed through the {@link updatingState$} and {@link fee$} observables.
   */
  public update(): void {
    this._updatingState$.next(true);

    this._updateSubscription?.unsubscribe();
    this._updateSubscription = this.update$().subscribe(fee => {
      this._fee$.next(fee);
      this._updatingState$.next(false);
    });
  }

  /**
   * This is a convenience method to update the payers and get the result in one go unlike
   * {@link update} which only updates the payers.
   */
  public updateWithResult(): Observable<FeeViewModel> {
    return new Observable<FeeViewModel>(subscriber => {
      const subscription = this._fee$
        .pipe(
          skip(1), // Skip the current value.
        )
        .subscribe(value => {
          subscriber.next(value); // Emit the new value when it's available.
          subscriber.complete();
        });

      // Cause it to emit a new value by updating the fee.
      this.update();

      return subscription;
    });
  }

  public discardChanges(): void {
    this.setFee(this._originalFee, {preserveOriginalFees: true});
  }
}

function cloneFeeViewModel(feeViewModel: FeeViewModel): FeeViewModel {
  return FeeViewModel.fromFee(cloneDeep(feeViewModel.fee));
}
