<div class="accordion" id="disclosuresAccordion">
  <ng-container *ngFor="
      let disclosureInfo of job?.disclosureInfos;
      let i = index
    ">
    <div class="accordion-item">
      <h5 class="accordion-header m-0" id="headingDoc{{ i }}">
        <button class="accordion-button {{
            i > 0 ? 'collapsed' : ''
          }} fw-semibold" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseDoc' + i"
          aria-expanded="true" [attr.aria-controls]="'#collapseDoc' + i">
          <i class="fas fa-file-pdf-o me-2"></i>
          {{ disclosureInfo.description }}
          <span class="ms-2 tab-1003-val-icon">
            <i *ngIf="type == 'audit'" class="status-indicator fas" style="float: right" [ngClass]="{
                fa: disclosureInfo.status == 'AuditCompleted',
                'fa-check-circle':
                  disclosureInfo.status == 'AuditCompleted',
                'text-success': disclosureInfo.status == 'AuditCompleted',

                'fa-times-circle': disclosureInfo.status == 'Failed',
                'text-danger': disclosureInfo.status == 'Failed',

                'fa-circle-notch':
                  disclosureInfo.status == 'AuditPending',
                'fa-spin': disclosureInfo.status == 'AuditPending'
              }"></i>
            <i *ngIf="type == 'generate'" class="status-indicator fas" style="float: right" [ngClass]="{
                fa: disclosureInfo.status == 'DocGenCompleted',
                'fa-check-circle':
                  disclosureInfo.status == 'DocGenCompleted',
                'text-success':
                  disclosureInfo.status == 'DocGenCompleted',

                'fa-times-circle': disclosureInfo.status == 'Failed',
                'text-danger': disclosureInfo.status == 'Failed',

                'fa-circle-notch':
                  disclosureInfo.status == 'DocGenPending',
                'fa-spin': disclosureInfo.status == 'DocGenPending'
              }"></i>
          </span>
        </button>
      </h5>

      <div id="collapseDoc{{ i }}" class="accordion-collapse collapse  {{ i == 0 && disclosureInfo.errors ? 'show' : '' }}"
        aria-labelledby="headingDoc{{ i }}" data-bs-parent="#disclosuresAccordion">
        <div class="accordion-body">
          <div>
            <div *ngIf=" disclosureInfo.errors &&
                disclosureInfo.errors != 'Fatal, business rule violations'
              " class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
              <div class="media">
                <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
                <div class="media-body align-self-center">
                  <span>{{ disclosureInfo.errors }}</span>
                </div>
              </div>
            </div>

            <table *ngIf="disclosureInfo?.dataAuditRuleMessages?.length" class="table table-striped">
              <tbody>
                <ng-container *ngFor="let msg of disclosureInfo.dataAuditRuleMessages">
                  <tr *ngIf="msg.severity == 'Critical' || msg.severity == 'Fatal' || msg.severity == 'Error'">
                    <td><i class="fas fa-times-circle text-danger me-2"></i> Errors</td>
                    <td>{{msg.validationMessages[0]}}</td>
                    <td [ngClass]="{'text-end': msg.losFieldId}">{{ msg.fieldDescription }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngFor="let msg of disclosureInfo.dataAuditRuleMessages">
                  <tr *ngIf="msg.severity == 'Warning'">
                    <td><i class='fas fa-exclamation-circle text-warning me-2'></i> Warnings</td>
                    <td>{{msg.validationMessages[0]}}</td>
                    <td [ngClass]="{'text-end': msg.losFieldId}">{{ msg.fieldDescription }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          
        </div>
       
      </div>
      <div *ngIf="!disclosureInfo.errors && documentsVisible">
        <div class="accordion" id="documentAccordion">
          <ng-container *ngFor="
            let document of disclosureInfo.documents;
            let i = index">
            <div class="accordion-item">
              <h5 class="accordion-header m-0" id="disclosureHeading{{ i }}">
                <button class="accordion-button {{ i > 0 ? 'collapsed' : '' }}
                fw-semibold" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseDisclosureDoc' + i"
                aria-expanded="true" [attr.aria-controls]="'#collapseDisclosureDoc' + i">
                  <i class="fas fa-file-pdf-o me-2"></i>
                  {{ document.name }}
                </button>
              </h5>
              <div id="collapseDisclosureDoc{{ i }}" class="accordion-collapse collapse  {{ i == 0 ? 'show' : '' }}"
                   aria-labelledby="disclosureHeading{{ i }}" data-bs-parent="#documentAccordion">
                <div class="accordion-body">
                  <meridian-disclosure-preview-pdf [documentData]="document.downloadedContent"></meridian-disclosure-preview-pdf>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
