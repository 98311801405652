<div class="card">
  <div class="card-header">
    Change Borrower Password
  </div>
  <div class="card-body">
    <div class="pt-3">
      <form class="form-horizontal auth-form" #changePasswordForm="ngForm" novalidate id="changePasswordForm"
            name="changePasswordForm" action="index.html">
        <div class="row mb-3">
          <div class="col-md-2 text-end">
            <label class="form-check-label" for="newPassword">New Password</label>
          </div>
          <div class="col-md-4">
            <input type="password" class="form-control" id="newPassword" name="newPassword"
                   [ngClass]="{'is-invalid' : newPass && newPass.touched && newPass.invalid}" required
                   [(ngModel)]="newPassword" #newPass="ngModel" placeholder="New Password">
            <div class="invalid-feedback">Please enter your new password.</div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-2 text-end">
            <label class="form-check-label" for="confirmPassword">Confirm New Password</label>
          </div>
          <div class="col-md-4">
            <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
                   [ngClass]="{'is-invalid' : confirmPass && confirmPass.touched && confirmPass.invalid}"
                   pattern="{{newPassword}}" required
                   [(ngModel)]="confirmNewPassword" #confirmPass="ngModel"
                   placeholder="Confirm New Password">
            <div class="invalid-feedback">Please confirm your new password.</div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card-footer">
    <div class="row mb-3">
      <div class="col-md-6 text-end">
        <button class="btn btn-primary waves-effect waves-light" type="button" [disabled]="submittingPassword"
                (click)="onChangePasswordClicked()">
                    <span *ngIf="submittingPassword" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>


<div class="card">
  <div class="card-header">
    Change Username
  </div>
  <div class="card-body">
    <div class="pt-3">
      <form class="form-horizontal auth-form" #changeUsernameForm="ngForm" novalidate id="changeUsernameForm"
            name="changePasswordForm" action="index.html">
        <div class="row mb-3">
          <div class="col-md-2 text-end">
            <label class="form-check-label" for="newPassword">Username</label>
          </div>
          <div class="col-md-4">
            <input type="email" class="form-control" id="username" name="username"
                   [ngClass]="{'is-invalid' : newUsername && newUsername.touched && newUsername.invalid}" required
                   [(ngModel)]="username" #newUsername="ngModel" placeholder="Username">
            <div class="invalid-feedback">Please enter the username.</div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card-footer">
    <div class="row mb-3">
      <div class="col-md-6 text-end">
        <button class="btn btn-primary waves-effect waves-light" type="button" [disabled]="submittingUsername"
                (click)="onChangeUsernameClicked()">
                    <span *ngIf="submittingUsername" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
          Change Username
        </button>
      </div>
    </div>
  </div>
</div>
