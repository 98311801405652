export class Features {
  docEditingEnabled: boolean;
  docGenerationEnabled: boolean;
  docMagicEnabled: boolean;
  enhancedReportingEnabled: boolean;
  full1003Enabled: boolean;
  feesEnabled: boolean;
  leadManagementEnabled: boolean;
  dialerEnabled: boolean;
  onlineApplicationEnabled: boolean;
  onlineAppV3Enabled: boolean;
  digitalAssetVerificationEnabled: boolean;
  digitalEmploymentVerificationEnabled: boolean;
  prequalGenerationEnabled: boolean;
  smsAlertsEnabled: boolean;
  rvmAlertsEnabled: boolean;
  emailTestsEnabled: boolean;
  inviteBorrowerCheckedByDefault: boolean;
  creditMonitoringEnabled: boolean;
  creditReportingEnabled: boolean;
  ausEnabled: boolean;
  desktopOriginatorEnabled: boolean;
  desktopUnderwriterEnabled: boolean;
  lpaEnabled: boolean;
  losEnabled: boolean;
  pricingEnabled: boolean;
  appraisalOrderingEnabled: boolean;
  titleOrderingEnabled: boolean;
  campaignsEnabled: boolean;
  multipleCredentialSupportEnabled: boolean;
  leadPostingApiAuthEnabled: boolean;
  externalCompanyEnabled: boolean;
  documentSigningEnabled: boolean;
  losDocumentSigningEnabled: boolean;
  losDocumentSigningExtendedTokenLifespanEnabled: boolean;
  loanBeamEnabled: boolean;
  ocrEnabled: boolean;
}
