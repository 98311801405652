export class ThirdPartyKeyValue {
  constructor(key?: string, value?: string) {
    this.key = key;
    this.value = value;
  }

  readonly thirdPartyKeyValueId?: number;
  key: string;
  value: string;
  alias?: string;
  userName?: string;
  password?: string;
  readonly credentialId?: number;
}

export type ThirdPartyKeyValueArray = Array<ThirdPartyKeyValue>;

export class Key {
  key: string;
  displayText: string;
}
