<div class="modal-header">
  <h6 class="modal-title">Editing Expression</h6>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="config">
  <form class="row g-3" #createExpressionForm="ngForm" novalidate id="createExpressionForm" name="createExpressionForm">
    <div class="col-md-6">
      <expression-editor [config]="config"></expression-editor>
    </div>
    <div class="col-md-6">
      <h6 class="card-title">Operators</h6>
      <hr>
      <div class="row row-cols-auto g-1">
        <div class="col" *ngFor="let operator of config.operators">
          <button draggable="true" [ngStyle]="{'opacity': divStyle}" [ngClass]="{'selected-operator': selectedOperator?.name == operator?.name}" [id]="operator.syntax"
            (dragstart)="handleDragStart($event)" (dragend)="handleDragEnd($event)"
            (click)="onOperatorClicked(operator)" type="button" class="btn btn-sm btn-light">{{operator.name}} <span
              class="badge bg-dark">{{operator.op}}</span>
          </button>
        </div>
      </div>
      <div class="mt-3" *ngIf="selectedOperator">
        <h6 class="card-title">Help</h6>
        <hr>
        <div class="card">
          <div class="card-body m-0 p-0">
            <table class="table table-bordered my-0">
              <tr>
                <td>
                  <i class="bx-like font-18 align-middle me-1"></i>Description:
                </td>
                <td>
                  {{selectedOperator.description}}
                </td>
              </tr>
              <tr>
                <td><i class="bx-star font-18 align-middle me-1"></i>Is Logical:</td>
                <td>{{selectedOperator.isLogical ? "Yes" : "No"}}</td>
              </tr>
              <tr>
                <td>
                  <i class="bx-like font-18 align-middle me-1"></i>Help:
                </td>
                <td>
                  <div [innerHTML]="selectedOperator.helpText | safeHtml"></div>
                </td>
              </tr>
              <tr>
                <td>
                  <i class="bx-like font-18 align-middle me-1"></i>Syntax:
                </td>
                <td>
                  {{selectedOperator.syntax}}
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onOkClicked()"> Ok </button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()"> Cancel </button>
</div>
