<div class="modal-header">
    <h5 class="modal-title">Documents</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" id="addDocuments">
    <form id="addDocumentsFrom" name="addDocumentsFrom" class="form-horizontal">
        <table class="table table-striped table-bordered table-hover no-footer">
            <thead>
                <tr>
                    <th class="col-md-5">Loan Doc</th>
                    <th class="col-md-5">Appraisal Doc Type</th>
                    <th class="col-md-2">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let doc of newDocs">
                    <td><input class="form-control" value="{{doc.fileName}}" title="{{doc.fileName}}" disabled /></td>
                    <td><input class="form-control" value="{{doc.appraisalDocumentType}}" title="{{doc.appraisalDocumentType}}" disabled /></td>
                    <td>
                        <button class="btn btn-danger" ng-click="removeDoc(doc.docFileGuid)" style="width: 70px">Delete</button>
                    </td>
                </tr>
                <tr style="background: white;">
                    <td>
                        <select id="new-loan-doc" class="form-control" name="newLoanDoc" [(ngModel)]="newLoanDocId" required>
                            <option value="">-- Select One --</option>
                            <option value="{{doc.loanDocId}}" *ngFor="let doc of loanDocs" title="{{doc.description}}">{{doc.description}}</option>
                        </select>
                    </td>
                    <td>
                        <select class="form-control" id="new-appraisal-doc" name="newAppraisalDoc" [(ngModel)]="newAppraisalDocType" required>
                            <option value="">-- Select One --</option>
                            <option value="{{type.value}}" *ngFor="let type of appraisalDocTypesEnum" title="{{type.name}}">
                                {{type.name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <button class="btn btn-success" style="width: 70px" (click)="addDoc()">Add</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>