import { Company, LoanStatus } from ".";

export class CheckList {
  checklistId: number;
  checkListName: string;
  checklistTriggerType: ChecklistTriggerType;
  loanStatusId: number;
  loanStatus: LoanStatus;
  questionConfiguration: string;
  companyModel: Company;

  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;

  triggerOnUrlaCompletion: boolean;
  triggerOnSubmission: boolean;
}

export enum ChecklistTriggerType{
  Status = 'Status', //user interactive
  Continuous = 'Continuous',
}
