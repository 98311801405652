import {NgModule} from '@angular/core';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {FeesEditorComponent} from './fees-editor/fees-editor.component';
import {OriginationChargesComponent} from './origination-charges/origination-charges.component';
import {FeeSplitEditorComponent} from './editors/fee-split-editor/fee-split-editor.component';
import {OtherFeesComponent} from './other-fees/other-fees.component';
import {RealEstateCommissionComponent} from './real-estate-commission/real-estate-commission.component';
import {SellerConcessionsComponent} from './seller-concessions/seller-concessions.component';
import {ServicesBorrowerNotShoppedForComponent} from './services-borrower-not-shopped-for/services-borrower-not-shopped-for.component';
import {ServicesBorrowerCanShopForComponent} from './services-borrower-can-shop-for/services-borrower-can-shop-for.component';
import {TaxesOtherGovernmentFeesComponent} from './taxes-other-government-fees/taxes-other-government-fees.component';
import {SharedModule} from '../../shared/shared.module';
import {FeeService} from '../../services/fee.service';
import {CreateFeeModalComponent} from './modals/create-fee-modal/create-fee-modal.component';

import {PropertyTaxComponent} from './editors/property-tax/property-tax.component';
import {UpfrontMonthlyPaymentPremiumComponent} from './editors/upfront-monthly-payment-premium/upfront-monthly-payment-premium.component';
import {FeeTemplateModalComponent} from './modals/fee-template-modal/fee-template-modal.component';
import {UpdateLoanFeeModalComponent} from './modals/update-loan-fee-modal/update-loan-fee-modal.component';
import {ErnstFeeQuestionModalComponent} from './modals/ernst-fee-question-modal/ernst-fee-question-modal.component';
import {AppDetailsService} from '../app-details/services/app-details.service';
import {AddressUpdateModalComponent} from './modals/address-update-modal/address-update-modal.component';
import {LoanFeesComponent} from './loan-fees.component';
import {ScenarioFeesDialogComponent} from './scenario-fees-dialog/scenario-fees-dialog.component';
import {EscrowFeesComponent} from './escrow-fees/escrow-fees.component';
import {PrepaidFeesComponent} from './prepaid-fees/prepaid-fees.component';
import {DefaultFeesComponent} from './editors/default-fees/default-fees.component';
import {GenericFeesComponent} from './editors/generic-fees/generic-fees.component';
import {FeeWizardComponent} from './fee-wizard/fee-wizard.component';
import {FeeComparisonComponent} from './fee-wizard/fee-comparison/fee-comparison.component';
import {FinalFeeSummaryComponent} from './fee-wizard/final-fee-summary/final-fee-summary.component';
import {FeeSummaryCardComponent} from './fee-wizard/fee-summary-card/fee-summary-card.component';
import {ImportLoanCalculatedFeesComponent} from './fee-wizard/import-loan-calculated-fees/import-loan-calculated-fees.component';
import {SelectFeeTemplateComponent} from './fee-wizard/select-fee-template/select-fee-template.component';
import {ImportFeesFromVendorComponent} from './fee-wizard/import-fees-from-vendor/import-fees-from-vendor.component';
import {FeeApplicationComparisonComponent} from './fee-application-comparison/fee-application-comparison.component';
import {FeeApplicationComparisonDialogComponent} from './fee-application-comparison-dialog/fee-application-comparison-dialog.component';
import {TableModule} from 'primeng/table';
import {FeesVendorQuestionnaireComponent} from './fee-wizard/fees-vendor-questionnaire/fees-vendor-questionnaire.component';
import {FeeProviderPickerDialogComponent} from './modals/fee-provider-picker-dialog/fee-provider-picker-dialog.component';
import {FeeUtils} from './services/fee-utils.service';
import {FeeTotalsComponent} from './fee-totals/fee-totals.component';
import {PreCheckFeeWizardDialogComponent} from './pre-check-fee-wizard-dialog/pre-check-fee-wizard-dialog.component';
import {FeeCalculationService} from './services/fee-calculation.service';
import {EscrowFeesScheduleComponent} from './escrow-fees-schedule/escrow-fees-schedule.component';
import {FeeDetailsEditorModule} from './editors/fee-details-editor/fee-details-editor.module';
import {MortgageCtoFeeWizardModule} from './mortgage-cto-fee-wizard/mortgage-cto-fee-wizard.module';
import {FeesV2Module} from '../fees-v2/fees-v2.module';
import {CashToCloseModule} from '../cash-to-close/cash-to-close.module';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    FeeDetailsEditorModule,
    PopoverModule.forRoot(),
    MortgageCtoFeeWizardModule,
    FeesV2Module,
    CashToCloseModule,
  ],
  declarations: [
    FeesEditorComponent,
    LoanFeesComponent,
    EscrowFeesComponent,
    OriginationChargesComponent,
    OtherFeesComponent,
    PrepaidFeesComponent,
    RealEstateCommissionComponent,
    SellerConcessionsComponent,
    ServicesBorrowerNotShoppedForComponent,
    ServicesBorrowerCanShopForComponent,
    TaxesOtherGovernmentFeesComponent,
    CreateFeeModalComponent,
    DefaultFeesComponent,
    GenericFeesComponent,
    PropertyTaxComponent,
    UpfrontMonthlyPaymentPremiumComponent,
    FeeTemplateModalComponent,
    UpdateLoanFeeModalComponent,
    ErnstFeeQuestionModalComponent,
    AddressUpdateModalComponent,
    ScenarioFeesDialogComponent,
    FeeWizardComponent,
    ImportLoanCalculatedFeesComponent,
    SelectFeeTemplateComponent,
    ImportFeesFromVendorComponent,
    FeeApplicationComparisonComponent,
    FeeApplicationComparisonDialogComponent,
    FeesVendorQuestionnaireComponent,
    FeeProviderPickerDialogComponent,
    FeeComparisonComponent,
    FinalFeeSummaryComponent,
    FeeSummaryCardComponent,
    FeeSplitEditorComponent,
    FeeTotalsComponent,
    PreCheckFeeWizardDialogComponent,
    EscrowFeesScheduleComponent
  ],
  exports: [
    FeesEditorComponent,
    LoanFeesComponent,
    EscrowFeesComponent,
    OriginationChargesComponent,
    OtherFeesComponent,
    PrepaidFeesComponent,
    RealEstateCommissionComponent,
    SellerConcessionsComponent,
    ServicesBorrowerNotShoppedForComponent,
    ServicesBorrowerCanShopForComponent,
    TaxesOtherGovernmentFeesComponent,
    CreateFeeModalComponent,
    DefaultFeesComponent,
    GenericFeesComponent,
    PropertyTaxComponent,
    UpfrontMonthlyPaymentPremiumComponent,
    FeeTemplateModalComponent,
    UpdateLoanFeeModalComponent,
    ErnstFeeQuestionModalComponent,
    AddressUpdateModalComponent,
    ScenarioFeesDialogComponent,
    EscrowFeesScheduleComponent,
    FeeDetailsEditorModule,
    FeeSplitEditorComponent,
  ],
  providers: [
    FeeService,
    AppDetailsService,
    FeeUtils,
    FeeCalculationService
  ]
})
export class FeesModule {
  constructor() {
  }
}


