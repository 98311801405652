import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { BorrowerNote } from '../../borrower/models/borrower-note.model';
import { BorrowerDto } from '../models/borrower-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  updateContactsTableSubject: Subject<BorrowerDto> = new Subject<BorrowerDto>();

  constructor(private _service: DataService) { }

  updateContactsTable = (borrower: BorrowerDto) => {
    this.updateContactsTableSubject.next(borrower);
  }

  getContacts(): Observable<BorrowerDto[]>{
    return this._service.get('api/Borrower/AllBorrower/');
  }

  delete(id) {
    return this._service.delete(`api/Borrower/${id}`)
  }

  getById(id) {
    return this._service.get(`api/Borrower/${id}/true`);
  }

  sendInvite(id) {
    return this._service.post(`api/borrower/InviteBorrower/${id}`, {});
  }

  getHistory(id) {
    return this._service.get(`api/Email/GetEmailsSentToBorrower/${id}`);
  }

  resend(messageId) {
    return this._service.post(`api/Email/SetEmailPriorityToRetry/${messageId}`, {});
  }

  reQueue(messageId) {
    return this._service.post(`api/Message/RequeueMessage//${messageId}`, {});
  }

  getBorrowerAddress(borrowerId) {
    return this._service.get(`api/Loan/0/GetFirstBorrowerAddress/${borrowerId}`)
  }

  saveApplication(id, payload){
    return this._service.post(`api/Borrower/${id}/AddNewApplication/false`, payload);
  }

  getAllApplicationInfo = (borrowerId: number): Observable<PipelineItem[]> => {
    const url = 'api/Borrower/' + borrowerId + '/GetAllApplicationInfo/';
    return this._service.get(url);
  }

  getBorrowerNotes = (borrowerId: number): Observable<BorrowerNote[]> => {
    const url = 'api/Borrower/' + borrowerId + '/notes';
    return this._service.get(url);
  }

  postBorrowerNote = (borrowerId: number, note: BorrowerNote) => {
    const url = 'api/Borrower/' + borrowerId + '/note';
    return this._service.post(url, note);
  }


}
