<div class="modal-header" *ngIf="title">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="btn-close" (click)="onClose()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div [sortablejs]="itemList" [sortablejsOptions]="{animation: 150, ghostClass: 'ghost'}" class="sortable-list-container">
        <div class="list-group-item mb-2" *ngFor="let item of itemList">
            <span>{{ item.name }}</span>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="!hideFooter">
    <div class="button-items">
        <button type="button" class="btn btn-secondary center" (click)="onClose()">Cancel</button>
        <button [disabled]="saveInProgress" type="button" class="btn btn-primary center" (click)="onSave()">
            <span *ngIf="!saveInProgress">Save</span>
            <span *ngIf="saveInProgress" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
        </button>
    </div>
</div>
