import { Component, Input, OnInit } from '@angular/core';
import { LosDisclosureInfo, LosGeneratedDocument } from '../../../request-models/los-disclosure-gen-job';

@Component({
  selector: 'encompass-disclosure-documents',
  templateUrl: './encompass-disclosure-documents.component.html',
  styleUrls: ['./encompass-disclosure-documents.component.scss']
})
export class EncompassDisclosureDocumentsComponent  implements OnInit {

  @Input() info: LosDisclosureInfo;
  @Input() isTpoUser: boolean;

  columns: any[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'fileName', header: 'File Name' },
    ];
  }

  viewDocument = (doc: LosGeneratedDocument) => {
    const decodedData = window.atob(doc.downloadedContent);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  downloadDocument = (doc: LosGeneratedDocument) => {
    const decodedData = window.atob(doc.downloadedContent);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    const mimeType = "application/pdf";
    const blob = new Blob([uInt8Array], { type: mimeType, });
    let downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    let fileName = doc.fileName;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
