<div class="modal-header">
  <h5 class="modal-title">Select MI Quote</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <mi-quote-search-results
      [result]="searchResult"
      [showReRunSearch]="true"
      [interestRate]="interestRate"
      (quoteSelected)="onQuoteSelected($event)"
      (quoteSelectedAfterReRun)="onQuoteSelectedAfterReRun($event)"
    ></mi-quote-search-results>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close(selectedMiQuoteInfo)"
    data-bs-dismiss="modal"
  >
    OK
  </button>
</div>
