import { Component, Input, OnInit } from '@angular/core';
import { FeeSources, LoanFee } from 'src/app/models/fee/fee.model';
import { v4 as uuidv4 } from 'uuid';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'fee-application-comparison',
  templateUrl: 'fee-application-comparison.component.html',
  styleUrls: ['./fee-application-comparison.component.scss']
})
export class FeeApplicationComparisonComponent implements OnInit {

  @Input()
  newFeesSectionHeader: string;

  @Input()
  existingFees: LoanFee[] = [];

  @Input()
  newFees: LoanFee[] = [];

  feesToCompare: FeeComparisonRow[] = [];

  feesToApply: LoanFee[] = [];

  constructor(private readonly _currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.prepareComparisonTable();
    this.prepareFeesToApply();
  }

  onNewFeeSelectionChanged = (fee: FeeComparisonRow) => {
    if (fee.willKeepNew) {
      fee.willKeepOld = false;
    }
    this.prepareFeesToApply();
  };

  onExistingFeeSelectionChanged = (fee: FeeComparisonRow) => {
    if (fee.willKeepOld) {
      fee.willKeepNew = false;
    }
    this.prepareFeesToApply();
  };

  private prepareFeesToApply = () => {
    this.feesToApply = [];
    this.feesToCompare.forEach((row) => {
      if (row.status === ComparisonStatus.InBothSets) {
        if (!row.willKeepNew) {
          const feeToApply: LoanFee = this.existingFees.find((fee) => fee['conflictResolutionId'] === row.loanFeeId);
          if (feeToApply) {
            this.feesToApply.push(feeToApply);
          }
        } else {
          const feeToApply: LoanFee = this.newFees.find((fee) => fee['conflictResolutionId'] === row.loanFeeId);
          if (feeToApply) {
            feeToApply.loanFeeId = Number(row.loanFeeId);
            this.feesToApply.push(feeToApply);
          }
        }
      } else if (row.status === ComparisonStatus.OnlyInNewSet) {
        if (row.willKeepNew) {
          const feeToApply: LoanFee = this.newFees.find((fee) => fee['conflictResolutionId'] === row.loanFeeId);
          if (feeToApply) {
            this.feesToApply.push(feeToApply);
          }
        }
      } else {
        const feeToApply = this.existingFees.find((fee) => fee['conflictResolutionId'] === row.loanFeeId);
        if (row.willKeepOld) {
          if (feeToApply) {
            this.feesToApply.push(feeToApply);
          }
        }
      }
    });
  };

  private prepareComparisonTable = () => {
    this.existingFees.forEach((existingFee) => {
      const newFeeWithSameHudNumber = this.newFees.find(
        (newFee) => newFee.hudNumber === existingFee.hudNumber
      );
      const comparison = new FeeComparisonRow();
      comparison.hudNumber = existingFee.hudNumber;
      comparison.loanFeeId = existingFee.loanFeeId.toString();
      comparison.existingAmountAndPayer = this.getAmountAndPayerDisplayText(existingFee);
      comparison.oldName = existingFee.name;
      comparison.oldSource = existingFee.latestFeeValueSource;
      if (newFeeWithSameHudNumber) {
        comparison.canNotDeselectNew = (newFeeWithSameHudNumber.latestFeeValueSource === FeeSources.Loan
          || (!newFeeWithSameHudNumber.latestFeeValueSource && newFeeWithSameHudNumber.feeSource === FeeSources.Loan));
        comparison.newAmountAndPayer = this.getAmountAndPayerDisplayText(newFeeWithSameHudNumber);
        comparison.status = ComparisonStatus.InBothSets;
        comparison.newAmountAndPayer = this.getAmountAndPayerDisplayText(newFeeWithSameHudNumber);
        comparison.newName = newFeeWithSameHudNumber.name;
        comparison.newSource = newFeeWithSameHudNumber.latestFeeValueSource;
      } else {
        comparison.status = ComparisonStatus.OnlyInExistingSet;
        comparison.willKeepOld = false;
      }
      existingFee['conflictResolutionId'] = comparison.loanFeeId.toString();
      this.feesToCompare.push(comparison);
    });

    this.newFees.forEach((newFee) => {
      const existingFeeWithSameHudNumber = this.existingFees.find(
        (existingFee) => existingFee.hudNumber === newFee.hudNumber
      );
      const comparison = new FeeComparisonRow();
      comparison.hudNumber = newFee.hudNumber;
      comparison.newAmountAndPayer = this.getAmountAndPayerDisplayText(newFee);
      comparison.newName = newFee.name;
      comparison.newSource = newFee.latestFeeValueSource;
      comparison.canNotDeselectNew = (newFee.latestFeeValueSource === FeeSources.Loan || (!newFee.latestFeeValueSource && newFee.feeSource === FeeSources.Loan));
      if (existingFeeWithSameHudNumber) {
        comparison.existingAmountAndPayer = this.getAmountAndPayerDisplayText(existingFeeWithSameHudNumber);
        comparison.oldName = existingFeeWithSameHudNumber.name;
        comparison.oldSource = existingFeeWithSameHudNumber.latestFeeValueSource;
        newFee['conflictResolutionId'] = existingFeeWithSameHudNumber.loanFeeId.toString();
        const alreadyInComparison = this.feesToCompare.find(
          (feeToCompare) =>
            feeToCompare.loanFeeId ===
            existingFeeWithSameHudNumber.loanFeeId.toString()
        );
        if (!alreadyInComparison) {
          comparison.status = ComparisonStatus.InBothSets;
          this.feesToCompare.push(comparison);
        }
      } else {
        comparison.loanFeeId = uuidv4();
        comparison.status = ComparisonStatus.OnlyInNewSet;
        newFee['conflictResolutionId'] = comparison.loanFeeId.toString();
        this.feesToCompare.push(comparison);
      }
    });

    this.feesToCompare.forEach((row) => {
      if (
        row.status === ComparisonStatus.InBothSets ||
        row.status === ComparisonStatus.OnlyInNewSet
      ) {
        row.willKeepNew = true;
      } else {
        row.willKeepOld = false;
      }
    });
  };

  private getPayer = (loanFee: LoanFee): Payer => {
    if ((loanFee.borrowerFeeDollar != null && loanFee.borrowerFeeDollar > 0) ||
      (loanFee.borrowerFeePercent != null && loanFee.borrowerFeePercent > 0)) {
      return Payer.Borrower;
    }
    if ((loanFee.lenderFeeDollar != null && loanFee.lenderFeeDollar > 0) ||
      (loanFee.lenderFeePercent != null && loanFee.lenderFeePercent > 0)) {
      return Payer.Lender;
    }
    if ((loanFee.sellerFeeDollar != null && loanFee.sellerFeeDollar > 0) ||
      (loanFee.sellerFeePercent != null && loanFee.sellerFeePercent > 0)) {
      return Payer.Seller;
    }
    if ((loanFee.thirdPartyFeeDollar != null && loanFee.thirdPartyFeeDollar > 0) ||
      (loanFee.thirdPartyFeePercent != null && loanFee.thirdPartyFeePercent > 0)) {
      return Payer.ThirdParty;
    }
  }

  private getAmountAndPayerDisplayText = (fee: LoanFee): string => {
    const amount = fee.calculatedValues?.totalFee ?? 0;
    const amountFormatted = this._currencyPipe.transform(amount, 'USD', 'symbol', '1.2-2');
    const payer = this.getPayer(fee);
    switch(payer) {
      case Payer.Borrower:
        return amountFormatted + " (B)";
      case Payer.Seller:
        return amountFormatted + " (S)";
      case Payer.Lender:
        return amountFormatted + " (L)";
      case Payer.ThirdParty:
        return amountFormatted + " (#rd)";
      default:
        return amountFormatted;
    }
  }
}

export class FeeComparisonRow {
  loanFeeId: string;
  hudNumber: string;
  status: ComparisonStatus;
  willKeepNew: boolean = false;
  willKeepOld: boolean = false;
  oldSource: string;
  newSource: string;
  oldName: string;
  newName: string;
  existingAmountAndPayer: string;
  newAmountAndPayer: string;
  canNotDeselectNew: boolean = false;
}

export enum ComparisonStatus {
  OnlyInExistingSet = 'OnlyInExistingSet',
  OnlyInNewSet = 'OnlyInNewSet',
  InBothSets = 'InBothSets',
}

export enum Payer {
  Borrower = 'Borrower',
  Lender = 'Lender',
  Seller = 'Seller',
  ThirdParty = 'ThirdParty'
}

