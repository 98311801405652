import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyCredential } from '../../../../../../models';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { NgForm } from '@angular/forms';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { ScopeType } from '../../integrations.component';

@Component({
    selector: 'lde-modal',
    templateUrl: './lde-modal.component.html'
})
export class LdeModalComponent extends ApplicationContextBoundComponent implements OnInit {
    @Input()
    credential: ThirdPartyCredential;

    @Input()
    scope: ScopeType;

    @ViewChild('credentialForm')
    credentialForm: NgForm | undefined;

    isSaving: boolean;

    constructor(
        private readonly injector: Injector,
        private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
        public activeModal: NgbActiveModal,
        private readonly _notificationService: NotificationService,
        private readonly _systemLevelService: SystemLevelService
    ) {
        super(injector);
    }

    ngOnInit(): void {}

    saveCredential(): void {
        this.credentialForm.form.markAllAsTouched();
        if (!this.credentialForm.form.valid) return;
        this.isSaving = true;
        this.credential.companyId = this.applicationContext.userPermissions.companyId;
        this._systemLevelService.saveCredential(this.credential)
            .pipe(finalize(() => {
                this.isSaving = false;
            })).subscribe({
            next: res => {
                this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
                this.activeModal.close(res)
            },
            error: error => {
                this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
            }
        });
    }
}
