<div class="modal-header">
  <h5 class="modal-title w-100">
    {{ title }}
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <fee-definition-summary-table
    [feeSection]="feeSection"
    [title]="title"
    [fees]="fees"
    (selectedFeesChanged)="feesChanged($event)"
  ></fee-definition-summary-table>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary"
    [disabled]="isSaving"
    (click)="save()"
  >
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
