<div class="card stats-card">
  <div class="card-header" [ngStyle]="{'background-color': taskStatsGroup.color}">
    <h4 class="card-title text-center text-white">{{taskStatsGroup.title}}</h4>
  </div>
  <div class="container g-0">
    <div class="row g-0 text-center">
      <div class="col g-0 task-cell" *ngFor="let stat of taskStatsGroup.stats">
        <span class="d-block task-header-size">{{stat.name}}</span>
        <a *ngIf="stat.value" (click)="onStatSelected(stat.id)">
          <span [ngClass]="{'stat-selected': getStatSelectionStatus(stat.id)}" class="stat-count">
            {{stat.value}}
          </span>
        </a>
        <i *ngIf="!stat.value" class="d-block task-value fas fa-sync-alt fa-spin"
          style="font-size: 1.45rem; margin-top: 4px; margin-bottom: 4px;"></i>
      </div>
    </div>
  </div>
</div>
