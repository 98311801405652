import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ChannelService } from 'src/app/services/channel.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import {
  ActionButtonOption,
  GridActionButtonType,
} from 'src/app/shared/models/action-button-options.model';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import { DeleteWithReplacementConfirmationDialogComponent } from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import { LoanPurposeService } from '../../services';
import { Constants } from 'src/app/services/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  templateUrl: 'loan-purpose.component.html',
  styleUrls: ['./loan-purpose.component.scss']
})
export class LoanPurposeComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  @ViewChild(Table)
  loanPurposeTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('loanPurposeOrderDrawer')
  loanPurposeOrderDrawer: DrawerComponent;

  @ViewChild('loanPurposeOrder')
  loanPurposeOrder: RearrangeOrderComponent

  actionButtonOptions: Array<ActionButtonOption>;
  loanPurposeColumns = [];
  loanPurposes: Array<LoanPurpose>;
  filteredLoanPurposes: Array<LoanPurpose> = [];
  transactionTypes: Array<EnumerationItem> = [];
  mortgageLoanPurposeTypes: Array<EnumerationItem> = [];
  enabledChannels: EnumerationItem[] = [];
  itemsToOrder: Array<{ name: string, value: number }> = [];

  allowAddNew: boolean = false;
  channelsEnabled: boolean = false;

  applicationMode: string;
  selectedChannelNameForFilter: string;
  upsertDrawerTitle: string;

  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  upsertDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  loanPurposeToUpsert: LoanPurpose;

  private _companyId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _loanPurposeService: LoanPurposeService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _enumerationService: EnumerationService,
    private readonly _navigationService: NavigationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _channelService: ChannelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
    private readonly _localStorageService: LocalStorageService
  ) {
    super(injector);
    this._companyId = this.applicationContext.userPermissions.companyId;
    this.allowAddNew = this.applicationContext.userPermissions.superAdmin ? false : true;
    this.mortgageLoanPurposeTypes =
      this._enumerationService.mortgageLoanPurposeTypes;
    this.transactionTypes =
      this._enumerationService.transactionTypes;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this._applicationContextService.context.subscribe((result) => {
      this.enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(result.userPermissions.enabledChannels);
      this.channelsEnabled = !!this.enabledChannels.length;
      this.selectedChannelNameForFilter = 'All';
      this.loanPurposeColumns = [
        { field: 'enabledChannels', header: 'Channels', visible: this.channelsEnabled, sortable: true },
        { field: 'loanPurposeName', header: 'Loan Purpose', visible: true, sortable: true },
        { field: 'transactionType', header: 'Transaction Type', visible: true, sortable: true },
        { field: 'mortgageLoanPurpose', header: 'Mortgage Loan Purpose', visible: true, sortable: true },
        { field: 'order', header: 'Sort', visible: true, sortable: false }
      ];
      this.setActionButtonOptions();
      this.populateLoanPurposes();
    })
    if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
      this.applicationMode = 'loda-nextgen';
    } else {
      this.applicationMode = 'admin';
    }
  }

  displayMortgageLoanPurposeType(value: string): string {
    return this.mortgageLoanPurposeTypes.find(type => type.value === value)?.name || '';
  }

  displayTransactionType(value: string): string {
    return this.transactionTypes.find(type => type.value === value)?.name || '';
  }

  showDeleteDialog(rowData: LoanPurpose) {
    const modalRef = this._modalService.open(DeleteWithReplacementConfirmationDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.ItemsList = this.loanPurposes
      .filter((ls) => rowData.loanPurposeId !== ls.loanPurposeId)
      .map((ls) => ({
        value: ls.loanPurposeId,
        name: ls.loanPurposeName,
      }));
    modalRef.componentInstance.itemType = 'loan purpose';
    modalRef.result.then((assignedLoanPurposeId: string) => {
      this.deleteRow(rowData, parseInt(assignedLoanPurposeId));
    }, () => { });
  }

  openUpsertDrawer(rowData?: LoanPurpose) {
    if (this.loanPurposeToUpsert) {
      this.onUpsertDrawerClose();
    }

    this.upsertDrawerTitle = rowData ? "Edit Loan Purpose" : "Add New Loan Purpose";
    this.loanPurposeToUpsert = rowData ? cloneDeep(rowData) : {} as LoanPurpose;

    this._drawerService.show("loanPurposeUpsertDrawer");
  }

  onUpsertDrawerSave(res: LoanPurpose) {
    this.updateTableAfterUpsert(res);
    this.applicationContextService.updateLoanPurposeList(this.loanPurposes);
  }

  onUpsertDrawerClose() {
    this._drawerService.hide("loanPurposeUpsertDrawer")
    setTimeout(() => {
      this.loanPurposeToUpsert = null;
      this.upsertDrawerTitle = null;
    }, 100);
  }

  openOrderDrawer() {
    if (!this.loanPurposes.length) {
      return;
    }

    this.itemsToOrder = this.setLoanPurposeItems();
    this._drawerService.show("loanPurposeOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedLoanPurposes: Array<EnumerationItem>) {
    this.loanPurposeOrder.saveInProgress = true;
    const sortedIds = sortedLoanPurposes.map((r) => ({
      id: r.value,
    }));
    this._loanPurposeService.updateLoanPurposeOrder(sortedIds, this._companyId)
      .pipe(finalize(() => {
        this.loanPurposeOrder.saveInProgress = false;
        this.closeOrderDrawer();
      }))
      .subscribe(() => {
        this.loanPurposeTable.sortField = "";
        this.loanPurposeTable.sortOrder = 0;
        this.loanPurposeTable.reset();
        this.gridHeaderActionBar.search.nativeElement.value = '';
        this.populateLoanPurposes();
        this._notificationService.showSuccess(
          'Loan purpose order updated successfully',
          'Loan Purpose'
        );
      }, ({ error }) => {
        this._notificationService.showError(
          error?.message || 'Unable to update loan purpose order',
          'Loan Purpose'
        );
      }
      );
  }

  closeOrderDrawer() {
    this._drawerService.hide("loanPurposeOrderDrawer", 100);
  }

  private filterLoanPurposeByChannel(): void {
    if (this.selectedChannelNameForFilter === 'All') {
      this.filteredLoanPurposes = this.sortLoanPurposeByOrder(this.loanPurposes);
      return;
    }
    const filteredStatuses = this.getSelectedChannelLoanStatus;
    this.filteredLoanPurposes = this.sortLoanPurposeByOrder(filteredStatuses);
  }

  private get getSelectedChannelLoanStatus() {
    return this.loanPurposes.filter((loanStatus: LoanPurpose) => loanStatus.enabledChannels &&
      loanStatus.enabledChannels.includes(this.selectedChannelNameForFilter));
  }

  sortLoanPurposeByOrder(loanStatus: Array<LoanPurpose>, forceSortByOrderColumn: boolean = false): Array<LoanPurpose> {
    if (this.loanPurposeTable.sortField && !forceSortByOrderColumn) {
      return cloneDeep(loanStatus);
    }
    return orderBy(loanStatus, item => item.order);
  }

  private populateLoanPurposes() {
    this._spinner.show();
    this._loanPurposeService.getAllLoanPurposes(this._companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe(res => {
        this.loanPurposes = res;
        this.filterLoanPurposeByChannel();
      }, err => {
        this._notificationService.showError(
          err?.message || "Couldn't load loan purposes",
          'Loan Purpose'
        );
      }
      );
  }

  private setActionButtonOptions(): void {
    this.actionButtonOptions = [
      {
        type: GridActionButtonType.SearchInput,
        searchInputPlaceholder: 'Search...',
        onSearch: (value) => this.loanPurposeTable.filterGlobal(value, 'contains'),
      },
    ];

    if (this.channelsEnabled) {

      const selectedChannel = this._localStorageService.getItem("loanPurposeChannel") as string;

      if (selectedChannel) {
        this.selectedChannelNameForFilter = selectedChannel;
      }

      this.actionButtonOptions.push({
        type: GridActionButtonType.ChannelDropDown,
        label: 'Filter By Channel',
        showSelectOneOption: false,
        defaultSelection: selectedChannel ? selectedChannel : 'All',
        enabledChannels: [{
          value: 'All',
          name: 'All'
        }, ...this.enabledChannels
        ],
        onSelectionChanged: (selectedChannel: string) => {
          this.selectedChannelNameForFilter = selectedChannel;
          this._localStorageService.setItem("loanPurposeChannel", selectedChannel);
          this.filterLoanPurposeByChannel();
        }
      });
    }
  }

  private updateTableAfterUpsert(result: LoanPurpose) {
    const index = this.loanPurposes.findIndex(ls => ls.loanPurposeId === result.loanPurposeId);
    if (index === -1) {
      this.loanPurposes.push(result);
    } else {
      this.loanPurposes[index] = result;
    }
    this.filterLoanPurposeByChannel();
    this.onUpsertDrawerClose();
  }

  private deleteRow(rowData: LoanPurpose, assignedLoanPurposeId: number) {
    this._loanPurposeService.deleteLoanPurpose(
      rowData.loanPurposeId,
      this._companyId,
      assignedLoanPurposeId
    )
      .subscribe(res => {
        const index = this.loanPurposes.findIndex(
          (ls) => ls.loanPurposeId === rowData.loanPurposeId
        );
        this.loanPurposes.splice(index, 1);
        this._notificationService.showSuccess('Loan purpose removed succesfully.', 'Loan Purpose');
        this.populateLoanPurposes();
      }, err =>
        this._notificationService.showError(
          err?.message || "Couldn't delete loan purpose",
          'Loan Purpose'
        )
      );
  }

  private setLoanPurposeItems() {
    return chain(this.loanPurposes)
      .orderBy((ls) => ls.order)
      .map((ls) => ({
        name: ls.loanPurposeName,
        value: ls.loanPurposeId,
      }))
      .value();
  }
}
