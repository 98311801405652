import { Component, Input, OnInit } from '@angular/core';
import { Utils } from 'src/app/core/services/utils';
import { Address, ResidencyType, SubjectProperty } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.scss']
})
export class PropertyInfoComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  set isSubjectPropertyAddressTbd(isSubjectPropertyAddressTbd: boolean) {
    this._isSubjectPropertyAddressTbd = isSubjectPropertyAddressTbd;
    if (this._isSubjectPropertyAddressTbd) {
      this.mortgage.subjectProperty.address1 = 'TBD';
    }
  }

  get isSubjectPropertyAddressTbd(): boolean {
    return this._isSubjectPropertyAddressTbd;
  }

  states: EnumerationItem[] = [];
  propertyConstructionMethods: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];

  private _isSubjectPropertyAddressTbd: boolean;

  constructor(private readonly _enumerationService: EnumerationService) { }

  ngOnInit(): void {
    this.states = this._enumerationService.states;
    this._enumerationService.getMortgageEnumerations().subscribe(enums => {
      this.propertyTypes = enums[Constants.enumerations.propertyTypes];
      this.propertyConstructionMethods = enums[Constants.enumerations.propertyConstructionMethod];
    });
  }

  static setMortgageDefaults(mortgageBorrower: UrlaMortgage) {
    const { subjectProperty } = mortgageBorrower;

    subjectProperty && PropertyInfoComponent.setAddressDefaults(subjectProperty);
  }

  // FIXME: Duplicate code between LoanBorrowerInfoComponent
  private static setAddressDefaults(subjectProperty: SubjectProperty) {
    subjectProperty.address1 ??= "";
    subjectProperty.address2 ??= "";
    subjectProperty.city ??= "";
    // Because address.state is used by a select element the default value is
    // undefined, not empty string
    //
    // address.state ??= "";
    subjectProperty.zipCode ??= "";
    subjectProperty.county ??= "";
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.mortgage.subjectProperty["state"] = zipCode.state.toLowerCase();
      this.mortgage.subjectProperty["city"] = Utils.toTitleCase(zipCode.city);
      this.mortgage.subjectProperty["zipCode"] = zipCode.zipcode;
      this.mortgage.subjectProperty["county"] = zipCode.county;
      this.mortgage.subjectProperty["country"] = 'us';
    }
  }

  copyAddressFromPresent = () => {
    let borrPresentAddress = this.mortgage.borrowers[0].residencyAddresses.filter(x => x.residencyType == ResidencyType.PresentAddress);
    if (borrPresentAddress.length > 0)
      this.mortgage.subjectProperty["address1"] = borrPresentAddress[0].address?.address1;
    this.mortgage.subjectProperty["address2"] = borrPresentAddress[0].address?.address2;
    this.mortgage.subjectProperty["state"] = borrPresentAddress[0].address?.state?.toLowerCase();
    this.mortgage.subjectProperty["city"] = borrPresentAddress[0].address?.city;
    this.mortgage.subjectProperty["zipCode"] = borrPresentAddress[0].address?.zipCode;
    this.mortgage.subjectProperty["county"] = borrPresentAddress[0].address?.county;
  }

  protected handleAddressChange(e: Partial<Address>): void {
    const propertyAddress = this.mortgage.subjectProperty;
    propertyAddress.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      propertyAddress.address1 = e.address1;
      propertyAddress.city = e.city;
      propertyAddress.state = e.state;
      propertyAddress.zipCode = e.zipCode;
      propertyAddress.county = e.county;
    }, 200);
  }
}
