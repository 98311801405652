<p-table #dt1 [value]="history" [paginator]="true" [rows]="25" *ngIf="history" [rowsPerPageOptions]="[10,25,50]"
  styleClass="p-datatable-gridlines" sortField="dateInserted" [sortOrder]="-1">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 5rem">
        <span>Action</span>
      </th>
      <th [pSortableColumn]="'loanPurpose'">Loan Purpose
        <p-sortIcon [field]="'loanPurpose'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'loanAmount'">Loan Amount
        <p-sortIcon [field]="'loanAmount'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'ltv'">LTV
        <p-sortIcon [field]="'ltv'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'pricingEngineVendor'">Vendor
        <p-sortIcon [field]="'pricingEngineVendor'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'insertedAtDate'">Date
        <p-sortIcon [field]="'insertedAtDate'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-historyItem>
    <tr>
      <td>
        <span class="d-flex align-items-center justify-content-center w-100">
          <span>
            <i class="fa fa-play cursor text-primary" (click)="onRunAgainClicked(historyItem)"
              title="Run Search Again"></i>
          </span>
        </span>
      </td>
      <td>
        {{historyItem.loanPurpose}}
      </td>
      <td>
        {{historyItem.loanAmount | currency}}
      </td>
      <td>
        {{historyItem.ltv | percent : '1.1-2'}}
      </td>
      <td>
        {{historyItem.pricingEngineVendor}}
      </td>
      <td>{{historyItem.insertedAtDate | date:'short'}}</td>
    </tr>
  </ng-template>
</p-table>
