<div class="modal-header">
  <h5 class="modal-title">Generate Updated VOA Report </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">

  <form #generateReportForm="ngForm">
    <div class="row">
      <div class="col-md-4 text-end">
        <label class="custom-control-label">Report Type</label>
      </div>
      <div class="col-md-8">
        <select class="form-select" name="reportType" [(ngModel)]="request.reportType" #reportTypeField="ngModel"
          [ngClass]="{'is-invalid': reportTypeField && reportTypeField.touched && reportTypeField.invalid}" required>
          <option [ngValue]="'Assets'">Verification of Assets</option>
          <option [ngValue]="'DirectDepositSwitch'">Deposit-Based Verification of Employment (10-Day PCV)</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4 text-end">
        <label class="custom-control-label">Transaction / Account History</label>
      </div>
      <div class="col-md-8">
        <select class="form-select" name="daysBack" [(ngModel)]="request.daysBack" #daysBackField="ngModel"
          [ngClass]="{'is-invalid': daysBackField && daysBackField.touched && daysBackField.invalid}" required>
          <option [ngValue]="null">-- Select One --</option>
          <option [ngValue]="opt" *ngFor="let opt of daysBackOptions">{{opt}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4 text-end">
        <label class="custom-control-label">Accounts On Report</label>
      </div>
      <div class="col-md-8">
        <ng-select width="100%" name="accounts" bindLabel="name" bindValue="value" #accountsField="ngModel"
          [ngClass]="{ 'is-invalid' : accountsField && accountsField.touched && accountsField.invalid }"
          class="custom-ng-select" placeholder="Select Account" [closeOnSelect]="false"
          [(ngModel)]="request.thirdPartyAccountIdFilter" [multiple]="true" [items]="accountItems" required>
        </ng-select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary me-2" (click)="onSubmitClicked()"> Submit
  </button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
</div>
