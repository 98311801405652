import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LenderConfigFeeTemplatesComponent } from './components/lender-config-fee-templates/lender-config-fee-templates.component';
import { LenderConfigFeesLibraryComponent } from './components/lender-config-fees-library/lender-config-fees-library.component';
import { LenderConfigLendersComponent } from './components/lender-config-lenders/lender-config-lenders.component';
import { LenderConfigProductsComponent } from './components/lender-config-products/lender-config-products.component';

export const routes: Routes = [
  {
    path: 'lenders',
    data: {
      breadcrumb: 'Lenders',
    },
    component: LenderConfigLendersComponent
  },
  {
    path: 'products',
    data: {
      breadcrumb: 'Products',
    },
    component: LenderConfigProductsComponent
  },
  {
    path: 'fees-library',
    data: {
      breadcrumb: 'Fees Library',
    },
    component: LenderConfigFeesLibraryComponent
  },
  {
    path: 'fee-templates',
    data: {
      breadcrumb: 'Fee Templates',
    },
    component: LenderConfigFeeTemplatesComponent
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LenderConfigRoutingModule {}
