import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo',
})
export class LimitToPipe implements PipeTransform {
  transform(values: Array<any>, limit: number) {
    if (!values) return;
    if (!Array.isArray(values))
      return console.warn(
        `Input values of ${Object.keys({ values })[0]} must be an array.`
      );

    return values.splice(0, limit);
  }
}
