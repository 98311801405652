<ng-template #template>
  <li
    [id]="id"
    class="nav-item app-menu-item"
    [ngClass]="{ 'mm-active': isActive }"
    [hidden]="hidden"
  >
    <span class="tab-1003-val-icon">
      <i
        class="status-indicator fas"
        style="float: right"
        *ngIf="!hiddenIcon"
        [ngClass]="{
          'fa-check-circle text-success': isSuccess,
          'fa-question-circle text-warning': isPending,
          'fa-times-circle text-danger': isError,
          'fa-circle-notch fa-spin': isLoading
        }"
      ></i>
    </span>
    <a
      class="nav-link text-ellipsis app-menu-item-link"
      [title]="label"
      [disabled]="status === 'loading'"
      [routerLink]="link && link.length > 0 ? [link] : []"
      (click)="onMenuItemClicked()"
    >
      {{ status === 'loading' ? 'Loading...' : label }}
    </a>
  </li>
</ng-template>
