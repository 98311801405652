import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Role, RoleList } from 'src/app/models/role.model';
import { ApplicationMenu } from '../../../../services/application-menu';

@Injectable()
export class RolesService {

  constructor(private readonly _dataService: DataService) { }

  getCompanyRoles = (companyId: number): Observable<RoleList> => {
    const url = `api/admin/allRoleModel?companyId=${companyId}`;
    return this._dataService.get(url).pipe(
      map((list: RoleList) => list.map(deserializeRole))
    );
  };

  insertCompanyRole = (newRole: Role): Observable<Role> => {
    const url = `api/admin/InsertRoleModel`;
    return this._dataService
      .post(url, serializeRole(newRole))
      .pipe(map(deserializeRole));
  };

  updateCompanyRole = (role: Role): Observable<Role> => {
    const url = `api/admin/UpdateRoleModel`;
    return this._dataService
      .post(url, serializeRole(role))
      .pipe(map(deserializeRole));
  };

  deleteCompanyRole = (roleId: number, replaceWithRoleId: number, companyId: number): Observable<void> => {
    let url = `api/admin/DeleteRoleModel/${roleId}?companyId=${companyId}`;
    if (replaceWithRoleId) {
      url += `&replaceWithRoleId=${replaceWithRoleId}`;
    }
    return this._dataService.delete(url);
  };

  updateRoleOrder = (sortedRoleIds: Array<{}>, roleChannelName: string = ''): Observable<void> => {
    let url = `api/Admin/UpdateRoleOrder`;
    if (roleChannelName && roleChannelName !== 'All') {
      url += `?channel=${roleChannelName}`;
    }
    return this._dataService.post(url, sortedRoleIds);
  };
}

function deserializeMenuConfig(
  menuConfig: string | undefined,
): ApplicationMenu {
  if (!menuConfig) {
    console.error(
      'Empty menu config found in role. Using default menu.');
    return new ApplicationMenu();
  }

  const ids = menuConfig.split(',');
  return ApplicationMenu.fromIds(ids);
}

function deserializeRole(role: Record<string, any> | undefined): Role {
  const instance = new Role();
  if (role == null) return instance;
  Object.assign(instance, role);

  instance.menuConfig = deserializeMenuConfig(role.menuConfig);

  return instance;
}

function serializeMenuConfig(menuConfig: ApplicationMenu): string {
  if (menuConfig.items.length === 0) {
    throw new Error('A valid menu configuration must have at least one item');
  }

  return menuConfig.items.map(item => item.id).join(',');
}

function serializeRole(role: Role): Record<string, any> {
  const result: Record<string, any> = {};
  Object.assign(result, role);

  const menuConfig = role.menuConfig || (() => {
      console.error('Empty menu config found in role. Saving default menu.');
      return new ApplicationMenu();
    })();

  result.menuConfig = serializeMenuConfig(menuConfig);

  return result;
}
