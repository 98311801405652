import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loan-amount-calculator-dialog',
  templateUrl: './loan-amount-calculator-dialog.component.html',
  styleUrls: ['./loan-amount-calculator-dialog.component.scss']
})
export class LoanAmountCalculatorDialogComponent implements OnInit {

  @ViewChild('loanAmountForm')
  loanAmountForm: NgForm | undefined;

  @Input()
  purchasePriceAmount: number;

  @Input()
  loanAmount: number;

  loanAmountLocal: number = 0;
  downPaymentRatio: number = 0;
  customDownPaymentRatio: number = 0;
  downPayment: number = 0;
  purchasePrice: number = 0;
  predefinedRatios: number[] = [0.03, 0.035, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.purchasePrice = this.purchasePriceAmount ?? 0;
    this.loanAmountLocal = this.loanAmount ?? 0;

    this.downPayment = this.purchasePrice - this.loanAmountLocal;
    this.populateRatios();
  }

  onDownPaymentRatioChanged = () => {
    if (this.downPaymentRatio != null) {
      this.downPayment = this.purchasePrice * (this.downPaymentRatio ?? 0);
      this.customDownPaymentRatio = this.downPaymentRatio;
    }
    else {
      this.downPayment = this.purchasePrice * this.customDownPaymentRatio;
      this.downPaymentRatio = null;
    }

    this.loanAmountLocal = this.purchasePrice - this.downPayment;
  }

  onDownpaymentAmountChanged = () => {
    this.loanAmountLocal = this.purchasePrice - this.downPayment;
    this.populateRatios();
  }

  onOkClicked = () => {
    if (this.loanAmountForm) {
      this.loanAmountForm.form.markAllAsTouched();
      if (this.loanAmountForm.form.valid) {
        this.activeModal.close(this.loanAmountLocal);
      }
    }
  }

  private populateRatios = () => {
    if (this.downPayment > 0) {
      let ratio = this.downPayment / this.purchasePrice;
      if (this.predefinedRatios.includes(ratio)) {
        this.downPaymentRatio = ratio;
      }
      else {
        this.downPaymentRatio = null;
        this.customDownPaymentRatio = ratio;
      }
    }
  }

}
