<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Fee Provider {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Fee Provider {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class="custom-control-label"> Url </label>
    </div>
    <div class="col-md-10">
      <input type="text" class="form-control" [(ngModel)]="vendor.url">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class="custom-control-label"> Username </label>
    </div>
    <div class="col-md-4">
      <input type="text" class="form-control" [(ngModel)]="vendor.userName">
    </div>
    <div class="col-md-1 text-end">
      <label class="custom-control-label"> Password </label>
    </div>
    <div class="col-md-5">
      <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName"></password-input>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>