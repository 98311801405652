import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { isArray } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Observer, catchError, combineLatest, finalize, map, of } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { Borrower } from 'src/app/models';
import { LookupType } from 'src/app/models/question.model';
import { CheckListQuestion, CheckListService, QuestionSummary } from 'src/app/services/check-list.service';

@Component({
  selector: 'checklist-evaluator-all-questions-visible',
  templateUrl: 'checklist-evaluator-all-questions-visible.component.html',
})
export class ChecklistEvaluatorAllQuestionsVisibleComponent implements OnInit {

  @ViewChild('questionForm')
  questionForm: NgForm;

  @Input()
  checklistId: number;

  @Input()
  applicationId: number;

  @Input()
  borrowers: Borrower[] = [];

  @Input()
  checklistQuestions: CheckListQuestion[];

  @Output()
  saveCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected questionsToBeAnswered: QuestionToBeAnswered[] = [];

  protected answeredAllQuestions: boolean = false;

  protected multiSelectSettings: IDropdownSettings = {
    idField: 'questionAnswerId',
    textField: 'answerText'
  }

  protected get totalQuestionsCount(): number {
    return this.questionsToBeAnswered.length;
  }

  protected isSavingAnswers: boolean = false;

  private allQuestions: QuestionSummary[] = [];

  constructor(private readonly _checklistService: CheckListService,
    private readonly _spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initialize();
  }

  public validate = (): boolean => {
    if (this.questionForm) {
      this.questionForm.form.markAllAsTouched();
      return this.questionForm.form.valid;
    }
    return true;
  }

  public saveAnswers = (): Observable<any> => {
    this.isSavingAnswers = true;
    const apiCalls = [];

    this.questionsToBeAnswered.forEach(question => {
      const checklistQuestion = question.question;
      const answerGivenToQuestion = question.answersGivenByUser;
      if (question.questionMetadata.questionType === LookupType.MultiSelect) {
        if (answerGivenToQuestion.choicesSelected && answerGivenToQuestion.choicesSelected.length >= 0) {
          const existingAnswerIds = checklistQuestion.submittedAnswers.map(a => a.questionAnswerId);
          answerGivenToQuestion.choicesSelected.map(c => c.questionAnswerId).filter(answer => !existingAnswerIds.includes(answer))
            .forEach(answer => {
              apiCalls.push(
                this._checklistService.insertAnswerToChecklist({
                  answerText: null,
                  questionAnswerId: answer,
                  applicationId: this.applicationId,
                  questionId: checklistQuestion.questionId,
                  checklistVersionId: checklistQuestion.versionId,
                  borrowerId: question.borrowerId
                }, checklistQuestion.checklistId)
              );
            });
          const deletedAnswers = checklistQuestion.submittedAnswers
            .filter(answer => !answerGivenToQuestion.choicesSelected.map(c => c.questionAnswerId).includes(answer.questionAnswerId))
          deletedAnswers.forEach(deletedAnswer => {
            apiCalls.push(
              this._checklistService.deleteAnswerInChecklist(checklistQuestion.checklistId, deletedAnswer.checklistAnswerId)
            );
          });
        }
      } else {
        const alreadyHasAnswer = checklistQuestion.submittedAnswers && isArray(checklistQuestion.submittedAnswers) && checklistQuestion.submittedAnswers.length > 0;
        if (alreadyHasAnswer) {
          const answerGiven = answerGivenToQuestion.singleAnswer || answerGivenToQuestion.choiceSelected;
          const existingAnswer = answerGivenToQuestion.singleAnswer
            ? checklistQuestion.submittedAnswers[0].answerText
            : checklistQuestion.submittedAnswers[0].questionAnswerId;
          const answerChanged = answerGiven != existingAnswer;
          if (answerChanged) {
            const answerToUpdate = checklistQuestion.submittedAnswers.map(a => ({
              applicationId: a.applicationId,
              checklistAnswerId: a.checklistAnswerId,
              checklistVersionId: a.checklistVersionId,
              questionAnswerId: a.questionAnswerId,
              borrowerId: question.borrowerId,
              answerText: a.answerText,
              questionId: a.questionId
            }))[0];
            answerToUpdate.answerText = answerGivenToQuestion.singleAnswer || null;
            answerToUpdate.questionAnswerId = answerGivenToQuestion.choiceSelected || null;
            apiCalls.push(
              this._checklistService.updateAnswerInChecklist(
                checklistQuestion.checklistId,
                answerToUpdate.checklistAnswerId,
                answerToUpdate)
            );
          }
        } else {
          apiCalls.push(
            this._checklistService.insertAnswerToChecklist({
              answerText: answerGivenToQuestion.singleAnswer || null,
              questionAnswerId: answerGivenToQuestion.choiceSelected || null,
              applicationId: this.applicationId,
              questionId: checklistQuestion.questionId,
              checklistVersionId: checklistQuestion.versionId,
              borrowerId: question.borrowerId
            }, checklistQuestion.checklistId)
          );
        }
      }
    });

    if (apiCalls.length === 0) {
      return of(null)
    }

    const combined = combineLatest(apiCalls);

    return combined.pipe(
      map(result => {
        this.isSavingAnswers = false;
        this.saveCompleted.emit(true);
        return result;
      }),
      finalize(() => this.isSavingAnswers = false),
      catchError(err => {
        throw err;
      })
    );
  }

  protected onAnswerChanged = (question: QuestionToBeAnswered) => {
    this.removeSubsequentlyAddedQuestionsIfNeeded(question);
    this.addSubsequentQuestionsToTheFlowIfCurrentAnswerRequiresIt(question);
  }

  private initialize = () => {
    const observer: Observer<QuestionSummary[]> = {
      next: (questions: QuestionSummary[]) => {
        this.allQuestions = questions;
        if (this.checklistQuestions && this.checklistQuestions.length > 0) {
          this.initializeQuestionsToBeAnswered();
        }
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    }

    this._spinner.show();
    this._checklistService.getAllQuestionsWithAnswers().subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }

  private addSubsequentQuestionsToTheFlowIfCurrentAnswerRequiresIt = (currentQuestionToBeAnswered: QuestionToBeAnswered) => {
    const answerGivenToCurrentQuestion = currentQuestionToBeAnswered.answersGivenByUser;
    currentQuestionToBeAnswered.question?.answers.forEach(answer => {
      if (answerGivenToCurrentQuestion.choicesSelected?.map(c => c.questionAnswerId).includes(answer.questionAnswerId) ||
        answerGivenToCurrentQuestion.singleAnswer === answer.questionAnswerId.toString() ||
        answerGivenToCurrentQuestion.choiceSelected === answer.questionAnswerId) {

        const questionsThatNeedToBeAdded: QuestionToBeAnswered[] = answer.questions.map(q => {
          const questionMetadata = this.allQuestions.find(qm => qm.questionId === q.questionId);
          const questionToBeAdded = this.toQuestionToBeAnswered(q, questionMetadata, currentQuestionToBeAnswered.borrowerId, currentQuestionToBeAnswered.borrowerDisplayName);
          questionToBeAdded.parentQuestionId = currentQuestionToBeAnswered.question.questionId;
          return questionToBeAdded;
        });
        const index = this.questionsToBeAnswered.indexOf(currentQuestionToBeAnswered);
        this.questionsToBeAnswered.splice(index + 1, 0, ...questionsThatNeedToBeAdded);
      }
    });
  }

  private removeSubsequentlyAddedQuestionsIfNeeded = (currentQuestionToBeAnswered: QuestionToBeAnswered) => {
    const subsequentQuestions = this.questionsToBeAnswered.filter(q => q.parentQuestionId === currentQuestionToBeAnswered.question.questionId);
    subsequentQuestions.forEach(question => {
      const index = this.questionsToBeAnswered.indexOf(question);
      this.questionsToBeAnswered.splice(index, 1);
    });
  }

  private initializeQuestionsToBeAnswered = () => {
    this.checklistQuestions.map(question => {
      const questionMetadata = this.allQuestions.find(q => q.questionId === question.questionId);
      if (question.validForBorrowerIds && question.validForBorrowerIds.length > 0) {
        question.validForBorrowerIds.forEach(borrowerId => {
          const borrower = this.borrowers.find(b => b.borrowerId === borrowerId);
          if (borrower) {
            const borrowerDisplayName = borrower ? Utils.getPersonsDisplayName(borrower) : '';
            const questionSpecificToBorrower = this.toQuestionToBeAnswered(question, questionMetadata, borrower.borrowerId, borrowerDisplayName);
            this.questionsToBeAnswered.push(questionSpecificToBorrower);
            this.addSubsequentQuestionsToTheFlowIfCurrentAnswerRequiresIt(questionSpecificToBorrower);
          }
        });
      } else {
        const questionToBeAnswered = this.toQuestionToBeAnswered(question, questionMetadata)
        this.questionsToBeAnswered.push(questionToBeAnswered);
        this.addSubsequentQuestionsToTheFlowIfCurrentAnswerRequiresIt(questionToBeAnswered);
      }
    });
  }

  private toQuestionToBeAnswered = (question: CheckListQuestion, questionMetadata: QuestionSummary,
    borrowerId?: number, borrowerDisplayName?: string): QuestionToBeAnswered => {
    const q = new QuestionToBeAnswered();
    q.question = question;
    q.borrowerDisplayName = borrowerDisplayName;
    q.borrowerId = borrowerId;
    q.questionMetadata = questionMetadata;
    const alreadyHasAnswer = question.submittedAnswers && isArray(question.submittedAnswers) && question.submittedAnswers.length > 0;
    if (alreadyHasAnswer) {
      if (q.questionMetadata.questionType === LookupType.MultiSelect) {
        q.answersGivenByUser.choicesSelected = question.submittedAnswers.map(a => {
          return {
            questionAnswerId: a.questionAnswerId,
            answerText: questionMetadata.answers.find(ans => ans.questionAnswerId === a.questionAnswerId)?.answerText
          }

        });
      } else if (q.questionMetadata.questionType === LookupType.SelectList) {
        q.answersGivenByUser.choiceSelected = question.submittedAnswers[0].questionAnswerId;
      } else if (q.questionMetadata.questionType === LookupType.Value) {
        q.answersGivenByUser.singleAnswer = question.submittedAnswers[0].answerText;
      }
    }
    return q;
  }
}

/// This is the view model used by the UI, combining the question and the metadata and the answer given by the user
export class QuestionToBeAnswered {
  question: CheckListQuestion;
  questionMetadata: QuestionSummary;
  borrowerDisplayName: string;
  borrowerId: number | null;
  answersGivenByUser: AnswersGivenByUser;
  parentQuestionId: number | null | undefined;

  constructor() {
    this.answersGivenByUser = new AnswersGivenByUser();
  }
}

export class AnswersGivenByUser {
  choicesSelected: AnswerChoice[] | null | undefined = null;
  choiceSelected: number | null = null;
  singleAnswer: string | null = null;
}

export class AnswerChoice {
  questionAnswerId: number;
  answerText: string;
}

