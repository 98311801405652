import { Component, Input } from '@angular/core';

@Component({
  selector: 'loan-activity-card',
  templateUrl: 'loan-activity-card.component.html',
})
export class LoanActivityCardComponent {

  @Input() appId: number;
  @Input() leadId: number;

  constructor() {
  }
}
