<div class="modal-header">
  <h5 class="modal-title"> {{inEditMode ? 'Edit' : 'Create'}} MI Quote Vendor {{(vendor &&
    vendor.vendorName) ? ' : ' + vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #miQuoteForm="ngForm" novalidate id="miQuoteForm">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Url</label>
      </div>
      <div class="col-md-10">
        <select [(ngModel)]="vendor.url" class="form-select" name="url" #urlInput="ngModel"
          [ngClass]="{'is-invalid': urlInput && urlInput.touched && urlInput.invalid}" required>
          <option value=""> -- Select One -- </option>
          <option *ngFor="let url of urls" [ngValue]="url">{{url}}</option>
        </select>
        <div class="invalid-feedback"> Url is required </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Username</label>
      </div>
      <div class="col-md-4">
        <input type="text" name="userName" class="form-control" [(ngModel)]="vendor.userName" #userNameInput="ngModel"
          [ngClass]="{'is-invalid': userNameInput && userNameInput.touched && userNameInput.invalid}" required>
        <div class="invalid-feedback"> Username is required </div>
      </div>
      <div class="col-md-1 text-end">
        <label class="custom-control-label">Password</label>
      </div>
      <div class="col-md-5">
        <password-input [name]="'password'" [(ngModel)]="vendor.password" [name]="vendor.vendorName"
          [required]="true"></password-input>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Organization Id</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" name="organizationId"
          [(ngModel)]="vendor.thirdPartyKeyValuePairs[0].value" #organizationIdField="ngModel"
          [ngClass]="{'is-invalid': organizationIdField && organizationIdField.touched && organizationIdField.invalid}"
          required />
        <div class="invalid-feedback"> Organization Id is required </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
