import { ProductSearchRequest } from "./product-search-request-info.model";

export class LockRepriceRequest {
  productSearchRequest: ProductSearchRequest;
  applicationId: number;
  losIdentifier: string;
  assignedSearchId: string;
  lockTerm: number;
  rate: number;
  productId: string;
  productCode: string;
  pmlTemplateId: string;
  previewChanges: boolean;
  repriceUsingCurrentRate: boolean;
  skipLosPull: boolean;
}
