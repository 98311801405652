import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrequalLetterSettingsComponent } from './components/prequal-letter-settings.component';

const routes: Routes = [
  {path : '', component : PrequalLetterSettingsComponent},
  
];
  
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrequalLetterSettingsRoutingModule { }