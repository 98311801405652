import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ExternalContact } from '../../loan-docs/models/external-contact.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalContactsService {

  constructor(private readonly _dataService: DataService) { }

  getExternalContacts = (appId: number): Observable<ExternalContact[]> => {
    const url = 'api/Loan/' + appId + '/AllExternalContacts';
    return this._dataService.get(url);
  }

  removeAgentFromExternalContact = (appId: number, agentId: number) => {
    const url = 'api/Loan/' + appId + '/RemoveAgentFromExternalContact/' + agentId;
    return this._dataService.delete(url);
  }
}
