export class MiQuoteSearchResult {
  quoteId: string;
  results: MiQuoteDetail[];
  success: boolean;
  errors: string[];
}

export class MiQuoteDetail {
  quoteDate: Date;
  providerQuoteId: string;
  providerName: MiCompanyNameType;
  providerNameOther: string;
  status: MiStatus;
  paymentType: MiPaymentType;
  premiumPaidBy: PremiumPaidBy;
  statusReasons: string[];
  isRefundable: boolean;
  premiumAmount: number;
  premiumRatePercent: number;
  premiumUpfrontAmount: number
  premiumUpfrontPercent: number;
  quoteDetailUrl: string;
  premiumPayments: MiPaymentDetail[];
}

export class MiPaymentDetail {
  duration: number;
  amount: number;
  rate: number;
  taxAmount: number;
  paymentAndTaxAmount: number;
}

export enum MiCompanyNameType {
  ArchMI = "ArchMI",
  CMG = "CMG",
  Essent = "Essent",
  Genworth = "Genworth",
  MGIC = "MGIC",
  NationalMI = "NationalMI",
  Other = "Other",
  PMI = "PMI",
  Radian = "Radian",
  RMIC = "RMIC",
  Triad = "Triad",
  UGI = "UGI"
}

export enum MiStatus {
  Success = "Success",
  NoEligibleProducts = "NoEligibleProducts",
  Failure = "Failure",
  TimeOut = "TimeOut",
  Other = "Other"
}

export enum MiPaymentType {
  Unknown = "Unknown",
  PeriodicMonthly = "PeriodicMonthly",
  SplitPremium = "SplitPremium",
  SinglePayment = "SinglePayment",
  LenderPaidSinglePayment = "LenderPaidSinglePayment"
}

export enum PremiumPaidBy {
  Borrower = "Borrower",
  Lender = "Lender",
  Both = "Both"
}
