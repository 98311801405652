import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-dropdown',
  templateUrl: 'urla-dropdown.component.html',
  styleUrls: ['urla-dropdown.component.scss'],
  providers: [MakeProvider(UrlaDropdownComponent)]
})
export class UrlaDropdownComponent extends BaseUrlaInputComponent {

  @Input()
  options: EnumerationItem[] = [];

  @Input()
  hasEditButton = false;

  @Output()
  editClickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  unselectedValue: string | null | undefined = "";

  constructor() {
    super();
  }

  onFocused = () => {

  }

  getOptionLabel = () => {
    if (this.readonly) {
      let label = '—';
      if (this.options && (this.value != null)) {
        this.options.forEach(option => {
          if (option.value.toString() == this.value.toString()) {
            label = option.name;
          }
        });
      }
      return label;
    }
  }
}
