<ng-template #pageActionBarLeft>
    <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
    <button *ngIf="allowAddNew" class="btn btn-primary" (click)="showUpsertDialog()">
        <i class="fa fa-plus"></i>
        Add New
    </button>
</ng-template>

<ng-template #mainContent>
    <form #agentTypeForm="ngForm">
        <p-table #loanStatusTable [columns]="loanStatusColumns" columnResizeMode="fit" [value]="filteredLoanStatuses"
            [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll"
            name="loan-status-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines p-datatable-sm" [selectionPageOnly]="true" sortMode="single"
            scrollable="true" [scrollHeight]="scrollHeight" [globalFilterFields]="globalFilterFields">
            <ng-template pTemplate="caption">
                <div class="d-flex justify-content-between" style="position: relative">
                    <div class="btn-toolbar">
                        <div *ngIf="channelsEnabled" role="group" class="btn-group">
                            <label class="pt-2 form-label">Filter By Role Channel:</label>
                            <select name="channels" class="form-select ms-2 w-auto"
                                [(ngModel)]="selectedChannelNameForFilter"
                                (change)="onChannelFilterChange($event.target.value)">
                                <option value="All"> All </option>
                                <option *ngFor="let channel of enabledChannels" value="{{channel.value}}">
                                    {{channel.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="btn-toolbar" *ngIf="loanStatusTable">
                        <span class="p-input-icon-right ms-2 h-100">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control h-100"
                                (input)="loanStatusTable.filterGlobal($event.target.value, 'contains')"
                                (ngModelChange)="loanStatusTable.first=0"
                                placeholder="Search...">
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of loanStatusColumns | filter: {visible: true}"
                        [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
                        <div *ngIf="col.field !== 'order'">
                          {{ col.header }}
                          <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                      </div>
                      <div *ngIf="col.field === 'order'">
                          <button *ngIf="allowAddNew" type="button" id="loan-status-order-link" class="btn link-primary p-0 m-0"
                              (click)="showOrderDrawer(); $event.stopPropagation();">Sort</button>
                          <span *ngIf="!allowAddNew">Sort</span>
                      </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td>
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="fa fa-pencil-alt cursor text-primary" (click)="showUpsertDialog(row)"
                                title="Edit Role"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(row)"
                                title="Delete Role"></i>
                        </span>
                    </td>
                    <td>{{ row.loanStatusName }}</td>
                    <td *ngIf="channelsEnabled">{{ row.enabledChannels }}</td>
                    <td class="wrap-long-text">{{ getRoleNameCsv(row.selectByRole) }}</td>
                    <td>{{ row.showAsArchive | yesNo }}</td>
                    <td>{{ row.hideFromCounter | yesNo }}</td>
                    <td>{{ row.removeFromAllDialCampaigns | yesNo }}</td>
                    <td *ngIf="applicationContext.userPermissions.superAdmin">{{ row.isUsedForBilling | yesNo }}</td>
                    <td>{{ displayEmailSmsRole(row.emailFromRoleId) }}</td>
                    <td> {{ row.loanStage }}</td>
                    <td>{{ row.order }}</td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
    [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>


<drawer #loanStatusOrderDrawer [title]="'Loan Status Order'" [name]="'loanStatusOrderDrawer'"
    [templateRef]="loanStatusOrderRef" [options]="loanStatusOrderDrawerOptions">
</drawer>
<ng-template #loanStatusOrderRef>
    <rearrange-order #loanStatusOrder [itemList]="statusesToOrder" (close)="closeOrderDrawer($event)"
        (save)="onOrderSave($event)"></rearrange-order>
</ng-template>

<drawer #upsertLoanStatusDrawer [title]="currentLoanStatus?.loanStatusId ? 'Edit Loan Status' : 'Add Loan Status'"
    [name]="'upsertLoanStatusDrawer'" [templateRef]="upsertLoanStatusRef" [options]="upsertLoanStatusDrawerOptions">
</drawer>
<ng-template #upsertLoanStatusRef>
    <upsert-loan-status [loanStatus]="currentLoanStatus" [roles]="roles" [enabledChannels]="enabledChannels"
        [companyId]="companyId" [allLoanStatuses]="loanStatuses" (close)="onUpsertLoanStatusDrawerClose($event)" [isSuperAdmin]="isSuperAdmin"></upsert-loan-status>
</ng-template>
