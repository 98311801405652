<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="form-check city-state-option" *ngFor="let cityCounty of serializedOptions; let i = index">
      <input
        class="form-check-input"
        type="radio"
        name="option{{i}}"
        id="option{{i}}"
        value="{{cityCounty}}"
        [(ngModel)]="selectedOption">
      <label class="form-check-label" for="option{{i}}">
        {{cityCounty}}
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()" data-bs-dismiss="modal"> OK
  </button>
</div>
