import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { AdverseReason, LoanStatus } from 'src/app/models';

@Injectable()
export class AdverseReasonService {
  constructor(private readonly _dataService: DataService) {}

  getAllAdverseReasons(companyId: number): Observable<Array<AdverseReason>> {
    return this._dataService.get(
      `api/Admin/AllAdverseReasonModel?companyId=${companyId}`
    );
  }

  insertAdverseReason(
    companyId: number,
    data: AdverseReason
  ): Observable<AdverseReason> {
    return this._dataService.post(
      `api/Admin/InsertAdverseReasonModel?companyId=${companyId}`,
      data
    );
  }

  updateAdverseReason(
    companyId: number,
    data: AdverseReason
  ): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateAdverseReasonModel?companyId=${companyId}`,
      data
    );
  }

  updateAdverseReasonOrder(
    sortedIds: Array<{ id: number }>,
    companyId: number
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateAdverseReasonOrder?companyId=${companyId}`,
      sortedIds
    );
  }

  deleteAdverseReason(
    adverseReasonId: number,
    companyId: number,
    assignedAdverseReasonId?: number
  ): Observable<undefined> {
    let url = `api/Admin/DeleteAdverseReasonModel/${adverseReasonId}?companyId=${companyId}`;

    if (assignedAdverseReasonId) {
      url += `&replaceWithAdverseReasonId=${assignedAdverseReasonId}`;
    }

    return this._dataService.delete(url);
  }
}
