export class Goal {
  readonly id: number;
  readonly name: string;
  readonly timeFrame: GoalTimeFrame;
  readonly keyDateConfigurationId: number;
  readonly targetUnits: number;
  readonly targetVolume: number;
  readonly targetProfit: number;
  readonly userIds: Set<string>;

  constructor(params: Omit<Goal, 'userIds'> & { userIds: readonly string[] }) {
    const {userIds, ...rest} = params;

    Object.assign(
      this,
      rest,
      {userIds: Object.freeze(new Set(userIds))},
    );
  }
}

export enum GoalTimeFrame {
  Today = 'Today',
  ThisWeekToDate = 'ThisWeekToDate',
  ThisMonthToDate = 'ThisMonthToDate',
  ThisQuarterToDate = 'ThisQuarterToDate',
  ThisYearToDate = 'ThisYearToDate',
}
