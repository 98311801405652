import { Component, Input, OnInit } from '@angular/core';
import { Borrower } from 'src/app/models';

@Component({
  selector: 'tpo-borrower',
  templateUrl: 'tpo-borrower.component.html',
  styleUrls: ['./tpo-borrower.component.scss'],
})
export class TpoBorrowerComponent implements OnInit {

  @Input()
  set borrowers(borrowers: Borrower[]) {
    if (borrowers) {
      this._borrowers = borrowers;
    }
    this.primaryBorrower = borrowers.find(b => b.isPrimary);
    const coBorrowers = borrowers.filter(b => !b.isPrimary);
    if (coBorrowers.length) {
      this.coBorrower = coBorrowers[0];
    }
  }

  get borrowers(): Borrower[] {
    return this._borrowers;
  }

  primaryBorrower: Borrower;
  coBorrower: Borrower;

  private _borrowers: Borrower[] = [];

  constructor() { }

  ngOnInit() {
  }
}
