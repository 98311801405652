<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-12">
    <label for="payoffNotes">Payoff Notes</label>
    <textarea rows="3" [disabled]="isReadOnly" id="payoffNotes" name="payoffNotes" class="form-control"
      placeholder="Payoff Notes..." [(ngModel)]="lender.payoffNotes" #payoffNotesInput="ngModel">
    </textarea>
  </div>
</div>
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="payoffEmail">Payoff Email</label>
    <input id="payoffEmail" name="payoffEmail" email [disabled]="isReadOnly" trim
      [ngClass]="{'is-invalid' : payoffEmail && payoffEmail.touched && payoffEmail.invalid}" type="email"
      class="form-control" placeholder="example@domain.com" #payoffEmail="ngModel" [(ngModel)]="lender.payoffEmail" />
    <div class="invalid-feedback">Please enter a valid e-mail address.</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-9">
    <label for="payoffEmailInstructions">Email Instructions</label>
    <textarea rows="3" [disabled]="isReadOnly" id="payoffEmailInstructions" name="payoffEmailInstructions"
      class="form-control" placeholder="Payoff Email Instructions..." [(ngModel)]="lender.payoffEmailInstructions"
      #payoffEmailInstructionsInput="ngModel"></textarea>
  </div>
</div>
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="payoffPhone">Payoff Phone</label>
    <input class="form-control" id="payoffPhone" name="payoffPhone" type="text" [(ngModel)]="lender.payoffPhone"
      mask="(000) 000-0000" placeHolderCharacter="X" [showMaskTyped]="true" [disabled]="isReadOnly"
      #payoffPhoneInput="ngModel"
      [ngClass]="{'is-invalid' : payoffPhoneInput && payoffPhoneInput.touched && payoffPhoneInput.invalid}" />
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-9">
    <label for="payoffPhoneInstructions">Phone Instructions</label>
    <textarea rows="3" id="payoffPhoneInstructions" [disabled]="isReadOnly" name="payoffPhoneInstructions"
      class="form-control" placeholder="Payoff Phone Instructions..." [(ngModel)]="lender.payoffPhoneInstructions"
      #payoffPhoneInstructionsInput="ngModel"></textarea>
  </div>
</div>
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="payoffFax">Payoff Fax</label>
    <input id="payoffFax" name="payoffFax" class="form-control" [disabled]="isReadOnly" placeholder="fax"
      [mask]="'(000) 000-0000'" placeHolderCharacter="X" [showMaskTyped]="true" [(ngModel)]="lender.payoffFax"
      #payoffFaxInput="ngModel"
      [ngClass]="{'is-invalid' : payoffFaxInput && payoffFaxInput.touched && payoffFaxInput.invalid}" />
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-9">
    <label for="payoffFaxInstructions">Fax Instructions</label>
    <textarea rows="3" [disabled]="isReadOnly" id="payoffFaxInstructions" name="payoffFaxInstructions"
      class="form-control" placeholder="Payoff Fax Instructions..." [(ngModel)]="lender.payoffFaxInstructions"
      #payoffFaxInstructionsInput="ngModel"></textarea>
  </div>
</div>
