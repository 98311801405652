import { NgModule } from "@angular/core";
import { TableModule } from "primeng/table";
import { SharedModule } from "src/app/shared/shared.module";
import { LoanDtiSummaryComponent } from "./components/loan-dti-summary/loan-dti-summary.component";

@NgModule({
  imports: [
    SharedModule,
    TableModule
  ],
  declarations: [
    LoanDtiSummaryComponent
  ],
  exports: [
    LoanDtiSummaryComponent
  ],
  providers: [
  ]
})
export class LoanDtiModule {
  constructor() {
  }
}
