<angular-editor
  [(ngModel)]="value"
  [name]="name"
  #editor
  [ngClass]="{'single-line': singleLine}"
  id='html-editor'
  (contextmenu)="onRightClick($event)"
  [config]="config"
  toolTip=""
  [mentionConfig]="mergeFieldsMentionConfig"
  [mentionListTemplate]="mentionListTemplate"
  (opened)="onMentionsListOpened()"
  (closed)="onMentionsListClosed()"
  (paste)='onPaste($event)'>
  <ng-template *ngIf="showUnsubscribeToolbarButton" #customButtons let-executeCommandFn="executeCommandFn">
    <ae-toolbar-set>
      <ae-button iconClass="fa fa-bell-slash" title="Insert Unsubscribe Link"
                 (buttonClick)="executeCommandFn('insertHtml', unsubLinkAnchorTag)">
      </ae-button>
    </ae-toolbar-set>
  </ng-template>
</angular-editor>

<simple-merge-field-context-menu
  *ngIf="mergeFields"
  [menuItems]="mergeFields"
  (mergeFieldSelected)="onMergeFieldSelected($event)"
  #contextMenu>
</simple-merge-field-context-menu>

<ng-template #mentionListTemplate let-item="item">
  <span (mouseenter)="selectedMergeField = item.value" class="merge-field-item">{{item.value}}</span>
</ng-template>
