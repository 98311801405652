<div>
  <div class="d-flex">
    <h4 class='card-title flex-grow-1'>
      <strong> DTI Ratio
        <span *ngIf="!recalculatingDti" class='fw-bolder ms-2' [ngClass]="{
            'dti-danger': (frontEndDti > 37),
            'dti-warning': (frontEndDti > 32 && frontEndDti <= 37),
            'dti-success': (frontEndDti <= 32)
          }"> {{frontEndDti > 999 ? '--' : (frontEndDti || 0)}}%
          <i *ngIf='!recalculatingDti && !shouldNotShowFrontEndExpensesInfoIcon' [outsideClick]='true'
             [popover]='dtiRatioTooltipTemplate' [title]='getDtiRatioTooltipText()' class='fa fa-info-circle'
             placement='bottom'></i>
          <ng-template #dtiRatioTooltipTemplate>
            <div [innerHTML]='getDtiRatioTooltipText(true)'></div>
          </ng-template>
        </span>
        <span class='text-muted'> /
        </span>
        <ng-container *ngIf="recalculatingDti">
          <span class="fw-bolder button-item-text"> Refreshing... </span>
          <i class="fas fa-sync fa-spin ms-2"></i>
        </ng-container>
        <span *ngIf="!recalculatingDti" class='fw-bolder' [ngClass]="{
            'dti-danger': (backEndDti > 47),
            'dti-warning': (backEndDti > 43 && backEndDti <= 47),
            'dti-success': (backEndDti <= 43)
          }"> {{backEndDti > 999 ? '--' : (backEndDti || 0)}}% </span> </strong>
    </h4>
    <div>
      <span>
        <a (click)='onRefreshClicked()' class='text-primary' style='font-size: small;'> <i
          class='fas fa-redo-alt fa-rotate-180 text-primary me-1'></i> Refresh </a>
      </span>
      <button type="button" class="btn-close btn-dti" (click)="onCloseClicked()"></button>
    </div>
  </div>
</div>

<hr>

<div class='tables'>
  <div class='tables__table'>
    <h5 class='tables__heading'> Income </h5>

    <p-table
      id='income-table'
      [value]='incomeItems'
      [scrollable]='true'
      scrollHeight='flex'
      dataKey="name"
      responsiveLayout='scroll'
    >
      <ng-template pTemplate='header'>
        <tr>
          <th class='text-start'> Description</th>
          <th class='text-end'> Amount</th>
        </tr>
      </ng-template>

      <ng-template pTemplate='body' let-income let-expanded="expanded">
        <tr>
          <td [pRowToggler]='income' [style.cursor]="income['hasChildItems'] ? 'pointer' : 'default'"
            class='text-start dti__description'>
            <div class='d-flex gap-2'>
              <span class="d-flex justify-content-between">
                <span [title]='income.name' class='text-ellipsis' style="max-width: 12rem;">
                  <span> {{income.name}} </span>
                </span>
                <i *ngIf="income.info" class="fa fa-info-circle pt-1 ms-1" [title]='income.info'></i>
              </span>
              <span *ngIf="income['hasChildItems']">
                <i class='fas fa-angle-{{expanded ? "up" : "down"}}'></i>
              </span>
            </div>
          </td>
          <td class='text-end'>{{income.amount | currency}}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-income>
        <tr>
          <td colspan='2' style='padding: 0 !important;'>
            <p-table [value]='income.childDetail' class='no-shadow-p-table'
                     dataKey='name'>
              <ng-template pTemplate='body' let-income>
                <tr>
                  <td class='text-start dti__description text-muted' [title]='income.name'>
                    {{'(' + income.name + ')'}}
                    <i *ngIf="income.info" class="fa fa-info-circle" [title]='income.info'></i>
                  </td>
                  <td class='text-end'>{{income.amount | currency}}</td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate='summary'>
        <div class='table__summary'>
          <span> Total Monthly Income </span>
          <span>{{incomeMonthlyTotal | currency}}</span>
        </div>
      </ng-template>
    </p-table>
  </div>

  <div class='tables__table'>
    <h5 class='tables__heading'> Debt </h5>

    <p-table
      id='debt-table'
      [value]='debtItems'
      [scrollable]='true'
      scrollHeight='flex'
      dataKey="name"
      responsiveLayout='scroll'
    >
      <ng-template pTemplate='header'>
        <tr>
          <th class='text-start'> Description</th>
          <th class='text-end'> Amount</th>
        </tr>
      </ng-template>

      <ng-template pTemplate='body' let-debt let-expanded="expanded">
        <tr>
          <td [pRowToggler]='debt' [style.cursor]="debt['hasChildItems'] ? 'pointer' : 'default'"
            class='text-start dti__description'>
            <div class='d-flex gap-2'>
              <span class="d-flex justify-content-between">
                <span [title]='debt.name' class='text-ellipsis' style="max-width: 14rem;">
                  <span> {{debt.name}} </span>
                </span>
                <i *ngIf="debt.info" class="fa fa-info-circle pt-1 ms-1" [title]='debt.info'></i>
              </span>

              <span *ngIf="debt['hasChildItems']">
                <i class='ms-1 fas fa-angle-{{expanded ? "up" : "down"}}'></i>
              </span>
            </div>
          </td>
          <td class='text-end'>{{debt.amount | currency}}<span class="text-decoration-line-through" *ngIf="debt.crossedOutAmount"><br />{{debt.crossedOutAmount || currency}}</span></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-debt>
        <tr>
          <td colspan='2' style='padding: 0 !important'>
            <p-table [value]='debt.childDetail' class='no-shadow-p-table'
                     dataKey='name'>
              <ng-template pTemplate='body' let-debt>
                <tr>
                  <td class='text-start dti__description text-muted' [title]='debt.name'>
                    {{'(' + debt.name + ')'}}
                    <i *ngIf="debt.info" class="fa fa-info-circle" [title]='debt.info'></i>
                  </td>
                  <td class='text-end'>{{debt.amount | currency}}</td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate='summary'>
        <div class='table__summary'>
          <span> Total Monthly Debt </span>
          <span>{{debtMonthlyTotal | currency}}</span>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>
