export class GoalRecord {
  readonly id: number;
  readonly name: string;
  readonly targetUnits: number;
  readonly targetVolume: number;
  readonly targetProfit: number;
  readonly userIds: Set<string>;
  readonly unitsAchieved: number;
  readonly volumeAchieved: number;
  readonly profitAchieved: number;

  constructor(params: Omit<GoalRecord, 'userIds'>
    & { userIds: readonly string[] }) {
    const {userIds, ...rest} = params;

    Object.assign(
      this,
      rest,
      {userIds: Object.freeze(new Set(userIds))},
    );
  }
}

export interface GoalRecordResponse {
  goalId: number;
  goalName: string;
  userCompanyGuid: string;
  targetUnits: number;
  targetVolume: number;
  targetProfit: number;
  unitsAchieved: number;
  volumeAchieved: number;
  profitAchieved: number;
}

export function goalRecordFromResponse(
  response: GoalRecordResponse,
): GoalRecord {
  return new GoalRecord({
    id: response.goalId,
    name: response.goalName,
    targetUnits: response.targetUnits,
    targetVolume: response.targetVolume,
    targetProfit: response.targetProfit,
    userIds: [response.userCompanyGuid],
    unitsAchieved: response.unitsAchieved,
    volumeAchieved: response.volumeAchieved,
    profitAchieved: response.profitAchieved,
  });
}
