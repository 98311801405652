import { ThirdPartyIntegrationProvider } from "../fee/fee.model"
import { BorrowerVerificationProductType } from "./borrower-verification-account-link";

export class BorrowerVerificationUserEnrollmentRequest {
  firstName: string;
  lastName: string;
  email: string;
  last4Ssn: string;
  socialSecurityNumber: string;
  phoneNumber: string;
  requestType: BorrowerVerificationRequestType;
  maxHeightInPixels: number;
  maxWidthInPixels: number;
  leadId: number;
  applicationId: number;
  borrowerId: number;
  accountMonitoringInterval: BorrowerVerificationAccountMonitoringDuration;
  daysBack: number;
  integrationProvider: ThirdPartyIntegrationProvider;
  allowedProducts: BorrowerVerificationProductType[];
}

export enum BorrowerVerificationAccountMonitoringDuration {
  Days30 = "Days30",
  Days60 = "Days60",
  Days90 = "Days90"
}

export enum BorrowerVerificationRequestType {
  full = "full",
  lite = "lite"
}
