import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { HeaderMenuItem } from 'src/app/layout/admin/header/models/header-menu.model';
import { ApplicationContext, UserData } from 'src/app/models';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { Constants } from 'src/app/services/constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { RolePickerDialogComponent } from '../role-picker-dialog/role-picker-dialog.component';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent extends ApplicationContextBoundComponent implements OnInit {
  lodaLogoImagePath: string;
  amerisaveLogoImagePath: string;
  plusImagePath: string;
  menu: HeaderMenuItem[] = [];
  config: any;
  userHasAdminPermission: boolean = false;
  userHasSuperAdminPermission: boolean = false;
  allowMortgageCallReports: boolean = false;
  branchesEnabled: boolean = false;
  externalCompanyEnabled: boolean = false;
  pricingEnabled: boolean = false;
  menuItemStatuses: Map<string, boolean> = new Map<string, boolean>();

  @Input()
  currentlyLoggedInUser: UserData;

  @Input()
  currentCompanyGuid: string;

  currentlyLoggedInCompany: AuthenticationUserCompany;

  otherCompaniesThatCanBeSwitchedTo: AuthenticationUserCompany[] = [];

  readonly allowedTabs = {
    ['Dashboard']: 'dashboardv2',
    ['Pipeline']: 'pipeline',
    ['Calendar']: 'calendar',
  }

  constructor(private dataService: DataService,
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _navigationService: NavigationService) {
    super(injector);
    this.applicationContextService.context.subscribe(context => {
      this.setFieldsFromContext(context);
    });
    this.applicationContextService.changes.subscribe(context => {
      this.setFieldsFromContext(context);
    });
  }

  ngOnInit(): void {
    this.lodaLogoImagePath = 'assets/images/lodasoft.svg'
    this.amerisaveLogoImagePath = 'assets/images/amerisave.svg'
    this.plusImagePath = 'assets/images/plus.svg';

    this.currentlyLoggedInCompany = this.currentlyLoggedInUser.allUserCompanyData.find(c => c.companyGuid == this.currentCompanyGuid);
    this.otherCompaniesThatCanBeSwitchedTo = this.currentlyLoggedInUser.allUserCompanyData.filter(c => c.companyGuid != this.currentCompanyGuid);
    this.otherCompaniesThatCanBeSwitchedTo.sort((a, b) => a.companyName.localeCompare(b.companyName));

    this.dataService.getRelativePath('./assets/app-data.json').subscribe((res) => {
      this.menu = res.menu;
      this.config = res.app;
      if (this.applicationContext) {
        this._updateRecentApplications(this.applicationContext);
      }
      this.menu = this.menu.filter(item => !item.requireAdminPermission || this.userHasAdminPermission);

    });
  }

  setFieldsFromContext = (context: ApplicationContext) => {
    this.userHasAdminPermission = context.userPermissions.admin;
    this.userHasSuperAdminPermission = context.userPermissions.superAdmin;
    this.allowMortgageCallReports = context.userPermissions.allowMortgageCallReports;
    this.branchesEnabled = context.userPermissions.branchesEnabled;
    this.pricingEnabled = context.userPermissions.pricingEnabled;
    this._updateRecentApplications(context);
  }

  eventTrigger = (menuItem: HeaderMenuItem) => {
    if (menuItem.event) {
      this[menuItem.event]();
    }
  }

  getRouterLink = (menuItem: HeaderMenuItem): string => {
    return !menuItem.event ? menuItem.link : undefined;
  }

  onCompanySelectedToSwitchTo = (companyGuid: string, companyName: string) => {
    const optionsToSwitchTo = this.currentlyLoggedInUser.allUserCompanyData.filter(c => c.companyGuid == companyGuid);
    if (optionsToSwitchTo.length > 1) {
      let modalRef = this._modalService.open(RolePickerDialogComponent, Constants.modalOptions.medium);
      modalRef.componentInstance.companyName = companyName;
      modalRef.componentInstance.companyGuid = companyGuid;
      modalRef.componentInstance.roles = optionsToSwitchTo;
      modalRef.result.then((result) => {
        const companyToGoTo = this.currentlyLoggedInUser.allUserCompanyData.find((c) => c.companyGuid === result.companyGuid && c.userType === result.userType);
        if (companyToGoTo) {
          this._navigationService.navigateToCompanySwitchUrl(companyToGoTo);
        }
      }, error => { });
    }
  }

  onMenuToggled = (id: string, menuItem: HeaderMenuItem, level?: number) => {
    if (!menuItem?.children?.length) {
      this.menu.forEach(item => {
        this._resetSelectedItems(item);
      })
      if (this.allowedTabs[menuItem.text] && level === 1) {
        menuItem.selected = true;
      } else if (level > 1) {
        menuItem.selected = true;
      }
    }

    let currentStatus = this.menuItemStatuses.get(id);
    if (!currentStatus) {
      this.menuItemStatuses.set(id, true);
    } else {
      this.menuItemStatuses.set(id, false);
    }

    this.eventTrigger(menuItem);
  }

  openKpiDashboard = () => {
    let url = "/loda-nextgen/kpi-dashboard"
    window.open(url, '_blank');
  }

  private _resetSelectedItems = (item) => {
    item.selected = false;

    if (!item.children?.length) {
      return;
    }

    item.children.forEach(child => {
      this._resetSelectedItems(child);
    })
  }
  private _updateRecentApplications = (context: ApplicationContext) => {
    let recentAppsMenuItems: HeaderMenuItem[] = [];
    if (context != null) {
      const recentApplicationList = context.userPermissions.recentApplications;
      if (recentApplicationList) {
        recentApplicationList.forEach(recentApp => {
          const menuItems = new HeaderMenuItem(null, "fas fa-folder", recentApp.displayText, `app-details/${recentApp.applicationId}`);
          recentAppsMenuItems.push(menuItems);
        })
        let recentApps = new HeaderMenuItem(recentAppsMenuItems, "fas fa-star", "Recent Apps", null);
        const existingOne = this.menu.find(mi => mi.text === 'Recent Apps');
        if (!existingOne) {
          this.menu.push(recentApps);
        } else {
          existingOne.children = recentApps.children;
        }
      }
    }
  }
}
