<div class="modal-body">
    <form #upsertLeadStatusAssociationForm="ngForm" ngbAutofocus class="row">
        <div class="mb-3">
            <label class="control-label">Disallowed Services</label>
            <ng-select width="100%" name="disallowedServices" bindLabel="name" bindValue="value" placeholder="Select Services"
                [multiple]="true" [closeOnSelect]="false" [(ngModel)]="disallowedServicesModel"
                [items]="disallowedThirdPartyServices">
            </ng-select>
        </div>
        <div class="mb-3">
            <label class="control-label">Lead Status</label>
            <select class="form-select"
                [ngClass]="{ 'is-invalid' : loanStatus && loanStatus.touched && loanStatus.invalid }" name="loanStatus"
                [(ngModel)]="leadStatusAssociation.loanStatusId" #loanStatus="ngModel" required>
                <option [ngValue]="null">-- Select One --</option>
                <option *ngFor="let status of leadStatuses" value="{{status.loanStatusId}}">
                    {{status.loanStatusName}}
                </option>
            </select>
            <div class="invalid-feedback">Loan status is required.</div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary" (click)="onClose.emit()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" style="width: 75px">
            <span *ngIf="!saving">Save</span>
            <span class="spinner-border spinner-border-sm" role="status" *ngIf="saving"></span>
        </button>
    </div>
</div>
