import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ThirdPartyCredential, ThirdPartyCredentialType } from 'src/app/models/third-party/third-party-creds.model';
import { Constants } from 'src/app/services/constants';
import { PmiRateProVendorEditorDialogComponent } from './pmi-rate-pro-vendor-editor-dialog/pmi-rate-pro-vendor-editor-dialog.component';

@Component({
  selector: 'mortgage-insurance-quote',
  templateUrl: './mortgage-insurance-quote.component.html',
  styleUrls: ['./mortgage-insurance-quote.component.scss']
})
export class MortgageInsuranceQuoteComponent implements OnInit {

  @Input() miQuotes: ThirdPartyCredential[] = [];

  constructor(private _modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openVendorPicker = () => {
    const modalRef = this._modalService.open(PmiRateProVendorEditorDialogComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
    const vendor = new ThirdPartyCredential(ThirdPartyCredentialType.Mi, "PmiRatePro");
    vendor.alias = "Mi";
    modalRef.componentInstance.vendor = vendor;
    modalRef.componentInstance.inEditMode = false;

    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        this.miQuotes.push(result);
      },
      () => { }
    );
  }

  openVendorEditor = (e: ThirdPartyCredential) => {
    const modalRef = this._modalService.open(PmiRateProVendorEditorDialogComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
    modalRef.componentInstance.vendor = _.cloneDeep(e);

    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        let existingVendorIndex = this.miQuotes.findIndex(v => v.credentialId == result.credentialId);
        if (existingVendorIndex >= 0) {
          this.miQuotes[existingVendorIndex] = result;
        }
      },
      () => { }
    );
  }

  onCredsDelete = (deletedCred: ThirdPartyCredential) => {
  }
}
