<div class="call-summary-container col-md-show-height" *ngIf="isPanelOpened">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between justify-content-sm-center">
        <div class="flex-column-mobile btn-group-left">
          <button type="button" id="buttonParticipants" class="btn btn-outline-secondary tippy-btn"
            style="min-width: 500px;" data-bs-container="body" data-bs-toggle="popover" placement="bottom"
            [popover]="templateParticipants" data-trigger="click" [outsideClick]="true"
            [containerClass]="'templateParticipants'">
            <div class="d-flex align-items-center justify-content-between margin-top-negative-8">
              <div>
                <div class="row me-1">
                  <div class="col padding-xxs-mobile m-0 p-0 align-items-center d-flex justify-content-between me-5">
                    <div class="media d-flex">
                      <div class="participantFirstLetter participation-avatar me-2 my-0">
                        {{ getWordsFirstLetters(currentUserFullName) }}
                      </div>
                    </div>
                    <!--end media-->
                    <div class="d-flex align-items-center">

                      <h6 class="d-inline me-1">
                        <strong>
                          {{ conferenceTimer }}
                        </strong>
                      </h6>

                      <button class="btn btn-sm btn-outline-success btn-icon-square-sm me-1"
                        (click)="$event.stopPropagation(); muteDialer()"
                        *ngIf="activeConferenceExists() && !dialerMuted">
                        <i class="fa fa-microphone"></i>
                      </button>
                      <button class="btn btn-sm btn-outline-danger btn-icon-square-sm me-1"
                        (click)="$event.stopPropagation(); unMuteDialer()"
                        *ngIf="activeConferenceExists() && dialerMuted">
                        <i class="fa fa-microphone-slash"></i>
                      </button>
                      <button class="btn btn-sm btn-outline-danger btn-icon-square-sm"
                        (click)="$event.stopPropagation(); hangUpConference()" *ngIf="activeConferenceExists()">
                        <i class="fa fa-phone-slash"></i>
                      </button>
                    </div>
                  </div>
                  <ng-container *ngIf="participants && participants.length > 0">
                    <div class="col padding-xxs-mobile p-0 m-0 align-items-center d-flex justify-content-between me-1"
                      style="min-width: 220px; border-left: 1px;" *ngFor="let participant of participants" [ngClass]="{
                      'completed': participant.callStatus === 'Completed'
                    }">
                      <div class="media d-flex">
                        <div class="participantFirstLetter me-2 my-0" style="width: 30px; height: 30px;">
                          {{ participant.participantFirstLetters }}
                        </div>
                      </div>
                      <!--end media-->
                      <div class="d-flex align-items-center" *ngIf="participant.callStatus !== 'Completed'">
                        <i class="text-dark me-1">({{ participant.onHold == true ? 'On Hold' : participant.callStatus
                          }})</i>

                        <h6 class="d-inline me-1">
                          <strong>
                            {{ participant.timer }}
                          </strong>
                        </h6>
                        <button class="btn btn-sm btn-outline-danger btn-icon-square-sm me-1"
                          *ngIf="activeConferenceExists() && participant.onHold && !participant.isAgent"
                          (click)="$event.stopPropagation(); unHold(participant.conferenceParticipantId)">
                          <i class="fa fa-play"></i>
                        </button>
                        <button class="btn btn-sm btn-outline-success btn-icon-square-sm me-1"
                          *ngIf="activeConferenceExists() && !participant.onHold && !participant.isAgent"
                          (click)="$event.stopPropagation(); hold(participant.conferenceParticipantId)">
                          <i class="fa fa-pause"></i>
                        </button>
                        <button class="btn btn-sm btn-outline-danger btn-icon-square-sm"
                          (click)="$event.stopPropagation(); removeActiveParticipantFromConference(false)">
                          <i class="fa fa-phone-slash"></i>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div>
                <i class="fas fa-angle-down" placement="bottom" ngbTooltip="Participants in a call"></i>
                <button *ngIf="!manualDial" (click)="$event.stopPropagation(); dialNext()" [disabled]="
                    !activeConferenceExists() ||
                    isCurrentlyDialing ||
                    fetchingLead ||
                    acceptingWarmTransfer ||
                    !selectedDialList
                  " class="btn btn-sm button-background-color">
                  <span *ngIf="!isCurrentlyDialing" placement="bottom" ngbTooltip="Dial next lead"><i
                      class="fa fa-step-forward text-info"></i></span>
                  <span *ngIf="isCurrentlyDialing" placement="bottom" ngbTooltip="Fetch V2">
                    <i class="fas fa-sync fa-spin text-primary"></i>
                  </span>
                </button>
              </div>
            </div>

          </button>
          <ng-template #templateParticipants style="z-index:9999!important">
            <div class="card mb-0" style="z-index:9999!important;min-height: 295px">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="p-0">Participants ({{ participants.length }})</h5>
                  <span class="p-0">Started At: <strong>{{ conferenceStartTime }}</strong></span>
                </div>
              </div>
              <div class="card-body">
                <ul class="list-group custom-list-group mb-2">
                  <li class="list-group-item align-items-center d-flex justify-content-between mb-2">
                    <div class="media">
                      <img class="d-flex align-self-center me-2 rounded-circle filter-yellow-user"
                        src="assets/images/faUser.svg" alt="" height="36" />
                      <div class="media-body align-self-center">
                        <h6 class="m-0">
                          <strong>{{ currentUserFullName }}</strong>
                        </h6>
                      </div>
                    </div>
                    <!--end media-->
                    <div class="align-self-center">
                      <h6 class="d-inline me-1">
                        <strong>
                          {{ conferenceTimer }}
                        </strong>
                      </h6>

                      <button class="btn btn-outline-success btn-icon-square-sm me-1" (click)="muteDialer()"
                        *ngIf="activeConferenceExists() && !dialerMuted">
                        <i class="fa fa-microphone"></i>
                      </button>
                      <button class="btn btn-outline-danger btn-icon-square-sm me-1" (click)="unMuteDialer()"
                        *ngIf="activeConferenceExists() && dialerMuted">
                        <i class="fa fa-microphone-slash"></i>
                      </button>
                      <button class="btn btn-outline-danger btn-icon-square-sm" (click)="hangUpConference()"
                        *ngIf="activeConferenceExists()">
                        <i class="fa fa-phone-slash"></i>
                      </button>
                    </div>
                  </li>
                  <li class="list-group-item align-items-center d-flex justify-content-between mb-2"
                    *ngFor="let participant of participants" [ngClass]="{
                        'completed': participant.callStatus === 'Completed'
                      }">
                    <div class="media d-flex">
                      <div class="participantFirstLetter me-2">
                        {{ participant.participantFirstLetters }}
                      </div>
                      <div class="media-body align-self-center participant-name">
                        <h6 class="mt-0 mb-1">
                          <strong *ngIf="participant.participantName"> {{ participant.participantName }} <i
                              class="fa fa-search ms-2" data-bs-container="body" data-bs-toggle="popover"
                              placement="right" [popover]="templateRelatedEntities" data-trigger="click"
                              [outsideClick]="true" [containerClass]="'templateRelatedEntities'"
                              (click)="openRelatedEntities(participant)"></i></strong>
                          <strong *ngIf="!participant.participantName"> {{ participant.phoneNumber | phone }} <i
                              class="fa fa-search ms-2" data-bs-container="body" data-bs-toggle="popover"
                              placement="right" [popover]="templateRelatedEntities" data-trigger="click"
                              [outsideClick]="true" [containerClass]="'templateRelatedEntities'"
                              (click)="openRelatedEntities(participant)"></i></strong>
                        </h6>
                      </div>
                    </div>
                    <!--end media-->
                    <div class="d-flex align-items-center" *ngIf="participant.callStatus !== 'Completed'">
                      <i class="text-dark me-1">({{ participant.onHold == true ? 'On Hold' : participant.callStatus }})
                      </i>

                      <h6 class="d-inline align-items-center me-1">
                        <strong>
                          {{ participant.timer }}
                        </strong>
                      </h6>

                      <button class="btn btn-outline-success btn-icon-square-sm me-1"
                        (click)="hold(participant.conferenceParticipantId)"
                        *ngIf="activeConferenceExists() && !participant.onHold && !participant.isAgent">
                        <i class="fa fa-pause"></i>
                      </button>
                      <button class="btn btn-outline-danger btn-icon-square-sm me-1"
                        (click)="unHold(participant.conferenceParticipantId)"
                        *ngIf="activeConferenceExists() && participant.onHold && !participant.isAgent">
                        <i class="fa fa-play"></i>
                      </button>
                      <button class="btn btn-outline-disabled btn-icon-square-sm me-1"
                        *ngIf="activeConferenceExists() && participant.isAgent">
                        <!-- <i class="fa fa-microphone"></i> -->
                      </button>
                      <button class="btn btn-outline-danger btn-icon-square-sm"
                        (click)="removeActiveParticipantFromConference(false)">
                        <i class="fa fa-phone-slash"></i>
                      </button>
                    </div>
                  </li>
                </ul>

                <div *ngIf="!isCurrentlyDialing && conferenceEnded">
                  The conference just ended - please, dial again.
                </div>

                <div class="text-muted">Conference Line Id: {{activeConference?.conferenceLineId || '--'}}</div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-grid" *ngIf="!isDialInProgress">
                    <button (click)="dialNumber()" [disabled]="isCurrentlyDialing || fetchingLead"
                      class="btn button-background-color ps-3 pe-4">
                      <span *ngIf="!isCurrentlyDialing && !fetchingLead"><i class="fa fa-phone-alt text-success"></i>
                        Dial</span>
                      <span *ngIf="isCurrentlyDialing || fetchingLead" placement="bottom" ngbTooltip="Fetch V2">
                        <i class="fas fa-sync fa-spin text-primary"></i>
                      </span>
                    </button>
                  </div>
                  <div class="col d-grid" *ngIf="isDialInProgress">
                    <button (click)="removeActiveParticipantFromConference(false)"
                      [disabled]="isCurrentlyDialing || fetchingLead" class="btn button-background-color ps-3 pe-4">
                      <span *ngIf="!isCurrentlyDialing && !fetchingLead">
                        <i class="fa fa-phone-slash text-danger"></i> Hang Up
                      </span>
                      <span *ngIf="isCurrentlyDialing || fetchingLead" placement="bottom" ngbTooltip="Fetch V2">
                        <i class="fas fa-sync fa-spin text-primary"></i>
                      </span>
                    </button>
                  </div>

                  <div class="col d-grid" *ngIf="!manualDial">
                    <button (click)="dialNext()" [disabled]="
                          !activeConferenceExists() ||
                          isCurrentlyDialing ||
                          fetchingLead ||
                          acceptingWarmTransfer
                        " class="btn button-background-color ps-3 pe-4">
                      <span *ngIf="!isCurrentlyDialing" placement="bottom" ngbTooltip="Dial next lead">
                        <i class="fa fa-step-forward text-info"></i> Next
                      </span>
                      <span *ngIf="isCurrentlyDialing" placement="bottom" ngbTooltip="Fetch V2">
                        <i class="fas fa-sync fa-spin text-primary"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #templateRelatedEntities style="z-index:9999!important">
            <div class="card mb-0" style="z-index:9999!important;min-height: 395px;min-width: 650px;">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="p-0">Related Entities</h5>
                </div>
              </div>
              <div class="card-body">
                <related-entities *ngIf="!loadingRelatedEntities; else loading" [relatedEntities]="relatedEntities"
                  (editBorrower)="onEditBorrowerClicked($event)" (editLead)="onEditLeadClicked($event)">
                </related-entities>
                <ng-template #loading>
                  <loading-indicator loadingMessage="Loading Related Entities..."></loading-indicator>
                </ng-template>
              </div>
              <div class="card-footer">
                <div class="row">

                </div>
              </div>
            </div>
          </ng-template>

          <button type="button" id="buttonProgress" class="btn btn-outline-secondary p-1 tippy-btn m-left-1"
            data-bs-container="body" data-bs-toggle="popover" placement="bottom" [popover]="templateProgress"
            data-trigger="click" [outsideClick]="true" [containerClass]="'templateProgress'" style="min-width: 300px">
            <div class="d-flex">

              <div style="height: 25px; width: 200px; position: relative" placement="bottom"
                ngbTooltip="Call in progress">
                <i class="fa fa-circle-notch progress-cycle-style" style="height: 25px"></i>

                <div class="progress" style="height: 25px">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{
                        width: !selectedDialList
                          ? '20%'
                          : (manualDial ? 100 : ((selectedRecordIndex + 1) /
                              selectedDialList?.records?.length) *
                              100) +
                            '%'
                      }"></div>
                </div>

                <i *ngIf="selectedRecordIndex ===
                  selectedDialList?.records?.length - 1" class="fa fa-check-double progress-tick-style"
                  aria-hidden="true" style="height: 25px"
                  [ngClass]="{'complete': selectedRecordIndex === selectedDialList?.records?.length - 1}"></i>

                <span class="text-dark" style="height: 25px; position: absolute; top: 3px; left: 85px ">
                  <strong>{{ selectedRecordIndex + 1 }} of
                    {{ manualDial ? 1 : selectedDialList?.records?.length }}</strong></span>

              </div>
              <div class="d-flex">
                <div *ngIf="!manualDial">
                  <button class="btn btn-sm button-background-color" [disabled]="true" *ngIf="isCurrentlyDialing">
                    <span *ngIf="!isCurrentlyDialing" placement="bottom" ngbTooltip="Voicemail (coming soon)">
                      <i class="fa fa-voicemail text-warning"></i>
                    </span>
                    <span *ngIf="isCurrentlyDialing" placement="bottom" ngbTooltip="Fetch V2">
                      <i class="fas fa-sync fa-spin text-primary"></i>
                    </span>
                  </button>
                </div>
                <div *ngIf="!manualDial">
                  <button class="btn btn-sm button-background-color" (click)="$event.stopPropagation(); fetch()"
                    [disabled]="isCurrentlyDialing" placement="bottom" ngbTooltip="Fetch V2">
                    <i
                      class="fas fa-sync {{ fetchingLead || isCurrentlyDialing || acceptingWarmTransfer ? 'fa-spin' : ''}} text-success"></i>
                  </button>
                </div>
                <div *ngIf="activeConference && participants.length && !warmTransferStatus">
                  <button class="btn btn-sm button-background-color m-0" [disabled]="publishingWarmXfer"
                    (click)="$event.stopPropagation(); publishWarmTransfer();" placement="bottom"
                    ngbTooltip="Publish warm transfer">
                    <i [hidden]="publishingWarmXfer" class="fa fa-random"></i>
                    <i [hidden]="!publishingWarmXfer" class="fa fa-spin"></i>
                  </button>
                </div>
                <div *ngIf="activeConference && activeConference.agentUserId == currentUserId && (
                    warmTransferStatus === 'Published' ||
                    warmTransferStatus === 'Started' )
                  " placement="bottom" ngbTooltip="Cancel warm transfer">
                  <button class="btn btn-sm button-background-color m-0"
                    (click)="$event.stopPropagation(); cancelWarmTransfer()">
                    <i class="fa fa-times text-danger"></i>
                  </button>
                </div>
                <div
                  *ngIf="activeConference && activeConference.agentUserId == currentUserId && warmTransferStatus === 'Started'"
                  placement="bottom" ngbTooltip="Complete warm transfer">
                  <button class="btn btn-sm button-background-color m-0" [disabled]="completingWarmTransfer"
                    (click)="$event.stopPropagation(); completeWarmTransfer()">
                    <i class="fa fa-check text-primary"></i>
                  </button>
                </div>
                <div *ngIf="!manualDial">
                  <button class="btn btn-sm button-background-color m-0"
                    (click)="$event.stopPropagation(); findOnDialListClicked()" placement="bottom"
                    ngbTooltip="Find on dial list">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>

              <i class="fas fa-angle-down m-auto ms-2" placement="bottom"
                ngbTooltip="Open loan/lead info for a client"></i>
            </div>
          </button>
          <ng-template #templateProgress style="z-index:9999!important">
            <div class="card mb-0 " style="z-index:9999!important">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="p-0">
                    Dial Progress - {{ selectedDialList?.name }}
                  </h5>
                  <button class="btn btn-primary m-0" *ngIf="!manualDial" (click)="findOnDialListClicked()">
                    <i class="fa fa-search"></i> Find on Dial List
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row pt-4" *ngIf="selectedRecordForCall">
                  <span class="font-14 pb-1 text-center">
                    <strong class="me-1">{{ selectedRecordForCall.firstName }} {{ selectedRecordForCall.lastName
                      }}</strong>
                    <span *ngIf="phoneNumberToCall">{{ phoneNumberToCall.phoneNumber | phone }} -
                      {{ phoneNumberToCall?.phoneType }}
                    </span>
                  </span>
                </div>
                <div style="height: 30px; position: sticky">
                  <i class="fa fa-circle-notch progress-cycle-style" style="height: 30px"></i>

                  <div class="progress" style="height: 30px">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                      aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{
                        width: !selectedDialList
                          ? '20%'
                          : (manualDial ? 100 : ((selectedRecordIndex + 1) /
                              selectedDialList?.records?.length) *
                              100) +
                            '%'
                      }"></div>
                  </div>

                  <i *ngIf="selectedRecordIndex ===
                  selectedDialList?.records?.length - 1" class="fa fa-check-double progress-tick-style"
                    aria-hidden="true" style="top:9px;height: 30px" [ngClass]="{
                      'complete':
                        selectedRecordIndex ===
                        selectedDialList?.records?.length - 1
                    }"></i>
                </div>

                <div class="row">
                  <span class="font-14 pb-4 pt-1 text-center">Dialing
                    <strong>{{ selectedRecordIndex + 1 }} of
                      {{ manualDial ? 1 : selectedDialList?.records?.length }}</strong></span>
                </div>
              </div>
              <div class="card-footer">
                <div class="row" *ngIf="activeConferenceExists()">
                  <div class="col d-grid" *ngIf=" activeConference.agentUserId == currentUserId && (warmTransferStatus === 'Published' || warmTransferStatus === 'Started')
                    ">
                    <button class="btn button-background-color" (click)="cancelWarmTransfer()">
                      Cancel Warm Transfer
                      <i class="fa fa-times text-danger"></i>
                    </button>
                  </div>
                  <div class="col d-grid"
                    *ngIf="activeConference.agentUserId == currentUserId && warmTransferStatus === 'Started'">
                    <button class="btn button-background-color" [disabled]="completingWarmTransfer"
                      (click)="completeWarmTransfer()">
                      Complete Warm Transfer
                      <i class="fa fa-check text-primary"></i>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col d-grid" *ngIf="!manualDial">
                    <button class="btn button-background-color" [disabled]="true" *ngIf="isCurrentlyDialing">
                      <span *ngIf="!isCurrentlyDialing" placement="bottom" ngbTooltip="Voicemail (coming soon)">
                        <i class="fa fa-voicemail text-warning"></i> Voicemail (coming soon)
                      </span>
                      <span *ngIf="isCurrentlyDialing" placement="bottom" ngbTooltip="Fetch V2">
                        <i class="fas fa-sync fa-spin text-primary"></i>
                      </span>
                    </button>
                  </div>
                  <div class="col d-grid" *ngIf="!manualDial">
                    <button class="btn button-background-color" (click)="fetch()" [disabled]="isCurrentlyDialing">
                      <span>
                        <i class="fas fa-sync {{
                            fetchingLead ||
                            isCurrentlyDialing ||
                            acceptingWarmTransfer
                              ? 'fa-spin'
                              : ''
                          }} text-success"></i>
                        Fetch V2
                      </span>
                    </button>
                  </div>
                  <div class="col d-grid" *ngIf="participants.length && !warmTransferStatus">
                    <button class="btn btn-outline-secondary button-background-color" [disabled]="publishingWarmXfer"
                      (click)="publishWarmTransfer()">
                      <i [hidden]="publishingWarmXfer" class="fa fa-random"></i>
                      <i [hidden]="!publishingWarmXfer" class="fa fa-spin"></i> Publish Warm Transfer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <button type="button" id="buttonMessage"
            class="btn btn-outline-secondary tippy-btn m-left-1 dialer-button-mobile" data-bs-container="body"
            data-bs-toggle="popover" placement="bottom" [popover]="templateMessage" data-trigger="click"
            [outsideClick]="true" [containerClass]="'templateMessage'">
            <i class="fas fa-sticky-note me-2" style=" font-size: 12px;"></i>
            <span class="fw-bolder button-item-text">Quick Notes</span> <i class="fas fa-angle-down ms-2"></i>
          </button>
          <ng-template #templateMessage style="z-index:9999!important">
            <div class="card mb-0" style="z-index:9999!important">
              <div class="card-header pb-1 pt-1">
                <h5 class="p-0">{{ quickNoteHeader }}</h5>
              </div>
              <div class="card-header" *ngIf="latestNote">
                <div class="row">
                  <p class="mb-0">{{ latestNote }}</p>
                </div>

                <div class="row mt-2">
                  <span style="font-size:12px"><strong>{{ latestNoteAuthor }}</strong>
                    {{ latestNoteTime }}</span>
                </div>
              </div>
              <div class="card-body">


                <div class="row" *ngIf="selectedRecordForCall && selectedRecordForCall.leadId">
                  <label class="col-md-4 text-start form-label fw-bolder">Lead Status</label>
                  <div class="col-md-8">
                    <lead-status [selectedRecord]="selectedRecordForCall" [currentDialOfRecord]="selectedDialList">
                    </lead-status>
                  </div>
                </div>

                <textarea id="textarea" class="form-control" style="height: 95px" maxlength="255" rows="2"
                  [placeholder]="
                    'Add Note' + ' ( This note has a limit of 255 chars. )'
                  " [(ngModel)]="note"></textarea>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-grid">
                    <button class="btn btn-info float-end" (click)="addNote()">
                      <i class="fas fa-plus"></i> Add Note
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <button type="button" id="buttonSms" class="btn btn-outline-secondary tippy-btn m-left-1 dialer-button-mobile"
            data-bs-container="body" data-bs-toggle="popover" placement="bottom" [popover]="templateSms"
            data-trigger="click" [outsideClick]="true" [containerClass]="'templateSms'">
            <i class="fas fa-sms me-2" style="font-size:12px;"></i>
            <span class="fw-bolder button-item-text">Quick SMS</span> <i class="fas fa-angle-down ms-2"></i>
          </button>
          <ng-template #templateSms style="z-index:9999!important">
            <div class="card mb-0 card-border-remover" style="z-index:9999!important">
              <div class="card-header">
                <h5 class="p-0">Quick SMS</h5>
              </div>
              <div class="card-body">
                <form id="dialerCallMessageForm" #dialerCallMessageForm="ngForm" name="dialerCallMessageForm">
                  <div *ngIf="!manualDial" class="row">
                    <label class="col-md-4 text-start font-12 col-form-label" for="numbersToSms">To (Phone
                      Number):</label>
                    <div class="col-md-8">
                      <select class="form-select" name="numbersToSms" [(ngModel)]="numberToSendSmsTo" id="numbersToSms">
                        <option [ngValue]="null">-- Select --</option>

                        <optgroup [label]="possibleNumbersToSendSmsTo.borrowerName || '(name not set)'">
                          <option id="borrowerMobileNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.borrowerMobileNumber
                            ">
                            Mobile:
                            {{ possibleNumbersToSendSmsTo.borrowerMobileNumber | phone }}
                          </option>
                          <option id="borrowerHomeNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.borrowerHomeNumber
                            ">
                            Home:
                            {{ possibleNumbersToSendSmsTo.borrowerHomeNumber | phone }}
                          </option>
                        </optgroup>
                        <optgroup *ngIf="possibleNumbersToSendSmsTo.coBorrowerName">
                          <option id="coBorrowerMobileNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.coBorrowerMobileNumber
                            ">
                            Mobile:
                            {{
                            possibleNumbersToSendSmsTo.coBorrowerMobileNumber | phone
                            }}
                          </option>
                          <option id="coBorrowerHomeNumber" [ngValue]="
                              possibleNumbersToSendSmsTo.coBorrowerHomeNumber
                            ">
                            Home:
                            {{ possibleNumbersToSendSmsTo.coBorrowerHomeNumber | phone }}
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="manualDial" class="row">
                    <label class="col-md-4 text-start col-form-label">To</label>
                    <div class="col-md-8">
                      <input class="form-control input-field" required name="numberToSendMessageTo" id="smsTo"
                        name="smsTo" type="text" mask="(000) 000-0000" placeHolderCharacter="9" [showMaskTyped]="true"
                        #smsToInput="ngModel" [ngClass]="{
                          'is-invalid':
                            smsToInput &&
                            (smsToInput.touched || sendSmsClicked) &&
                            smsToInput.invalid
                        }" [(ngModel)]="numberToSendSmsTo" />
                      <div class="invalid-feedback">This field is required</div>
                    </div>
                  </div>

                  <textarea id="textarea" class="form-control mt-3" style="height: 95px" maxlength="255" rows="2"
                    [placeholder]="
                      'Message' + ' ( This textarea has a limit of 255 chars. )'
                    " name="smsBody" required [(ngModel)]="textMessage" #textMessageInput="ngModel" [ngClass]="{
                      'is-invalid':
                        textMessageInput &&
                        (textMessageInput.touched || sendSmsClicked) &&
                        textMessageInput.invalid
                    }"></textarea>

                  <div class="invalid-feedback">This field is required</div>
                </form>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-grid">
                    <button class="btn btn-info" (click)="onSendSmsClicked()" [disabled]="sending">
                      <span *ngIf="!sending"><i class="fa fa-paper-plane"></i> Send</span>
                      <span *ngIf="sending" placement="bottom" ngbTooltip="Fetch V2">
                        <i class="fas fa-sync fa-spin text-primary"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <button type="button"
            class="btn text-danger btn-outline-secondary tippy-btn m-left-1 button-radius margin-bottom-20 dialer-button-mobile"
            (click)="isPanelOpened = false; listener_endCurrentCall()" placement="bottom"
            ngbTooltip="Close power dialer">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<drawer [title]="'Editing...'" #editorForPartyBeingCalledDrawer [name]="'editorForPartyBeingCalledDrawer'"
  [options]="drawerOptions">
</drawer>