import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { QuickPriceHistoryItem } from '../../models/pricing/quick-price-history-item.model';
import { PricingService } from '../../services/pricing.service';
import { Observer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';

@Component({
  selector: 'quick-price-history',
  templateUrl: 'quick-price-history.component.html',
})
export class QuickPriceHistoryComponent implements OnInit {

  @Output()
  runAgainClicked: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();

  protected history: HistoryItem[] = [];

  constructor(
    private readonly _pricingService: PricingService,
    private readonly _spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.initialize();
  }

  public refresh() {
    this.initialize();
  }

  protected onRunAgainClicked = (historyItem: HistoryItem) => {
    const searchRequest = JSON.parse(historyItem.searchRequestJson);
    this.runAgainClicked.emit(searchRequest);
  }

  private initialize = () => {
    const observer: Observer<QuickPriceHistoryItem[]> = {
      next: (history: QuickPriceHistoryItem[]) => {
        const historyItems: HistoryItem[] = [];
        history.forEach((item: QuickPriceHistoryItem) => {
          const historyItem = new HistoryItem();
          const searchRequest = JSON.parse(item.requestData);
          historyItem.searchRequestJson = item.requestData;
          historyItem.insertedAtDate = new Date(item.dateInserted);
          historyItem.loanAmount =
            searchRequest?.loanInformation?.baseLoanAmount;
          historyItem.loanPurpose = searchRequest?.loanInformation?.loanPurpose;
          historyItem.ltv = searchRequest?.loanInformation?.ltv;
          historyItem.pricingEngineVendor = item.vendor;
          historyItems.push(historyItem);
        });
        this.history = historyItems;
      },
      error: (err: any) => {},
      complete: () => {},
    };
    this._spinner.show();
    this._pricingService
      .getQuickPriceHistory()
      .subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  };
}

export class HistoryItem {
  insertedAtDate: Date;
  loanAmount: number;
  loanPurpose: string;
  ltv: number;
  pricingEngineVendor: string;
  searchRequestJson: string;
}
