import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { chain, cloneDeep, orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { LeadStatus } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import {
  ActionButtonOption,
  GridActionButtonType,
} from 'src/app/shared/models/action-button-options.model';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LeadStatusAssociation } from '../../../models';
import { LeadStatusAssociationService } from '../../../services';

@Component({
  templateUrl: 'lead-status-association.component.html',
  styleUrls: ['lead-status-association.component.scss'],
})
export class LeadStatusAssociationComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild(Table)
  leadStatusTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('leadStatusAssociationOrderDrawer')
  leadStatusAssociationOrderDrawer: DrawerComponent;

  @ViewChild('leadStatusAssociationOrder')
  leadStatusAssociationOrder: RearrangeOrderComponent

  actionButtonOptions: Array<ActionButtonOption>;
  leadStatusColumns = [];
  leadStatusAssociations: Array<LeadStatusAssociation>;
  leadStatuses: Array<LeadStatus>;
  loanPurposes: Array<LoanPurpose>;
  itemsToOrder: Array<{ name: string, value: number }> = [];
  upsertDrawerLeadStatuses: Array<LeadStatus> = [];

  allowAddNew: boolean;
  isCreditReportingEnabled: boolean;

  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  upsertDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  leadStatusAssociationToUpsert: LeadStatusAssociation;

  permissions: any;

  upsertDrawerTitle: string;

  loanPurposeName: string;

  private _companyId: number;
  private _loanPurposeId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _leadStatusAssociationService: LeadStatusAssociationService,
    private readonly _notificationService: NotificationService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
  ) {
    super(injector);
    this._activatedRoute.params.subscribe((params) => {
      this._loanPurposeId = params['loanPurposeId'];
      this.loanPurposeName = params['loanPurposeName'];
    });
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.leadStatusColumns = [
      { field: 'loanStatusName', header: 'Lead Status', visible: true, sortable: true },
      { field: 'disallowedThirdPartyServices', header: 'Disallowed Services', sortable: true },
      { field: 'order', header: 'Sort', sortable: true }
    ]
    this.applicationContextService.context.subscribe(res => {
      this.isCreditReportingEnabled = res.userPermissions.creditReportingEnabled;
      this._companyId = res.userPermissions.companyId;
      this.allowAddNew = res.userPermissions.superAdmin ? false : true;
      this.leadStatuses = res.globalConfig.leadStatus;
      this.loanPurposes = res.globalConfig.loanPurpose.filter(ls => ls.loanPurposeId == this._loanPurposeId);
      this.permissions = {
        isCreditReportingEnabled: this.isCreditReportingEnabled,
      }
    });
    this.setActionButtonOptions();
    this.populateLeadStatusAssociations();
  }

  showDeleteDialog(rowData: LeadStatusAssociation) {
    Swal.fire({
      title: 'Lead Status Association',
      text: 'Are you sure you want to delete this lead status association?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) return;
      this.deleteRow(rowData);
    });
  }

  openUpsertDrawer(rowData?: LeadStatusAssociation) {
    this._drawerService.hideAll();
    this.upsertDrawerTitle = rowData ? "Edit Lead Status Association" : "Add New Lead Status Association";
    this.leadStatusAssociationToUpsert = rowData ? cloneDeep(rowData) : {} as LeadStatusAssociation;

    this.upsertDrawerLeadStatuses = this.leadStatuses
      .filter(ls => !this.leadStatusAssociations
        .some(a => a.loanStatusId === ls.loanStatusId) ||
        rowData?.loanStatusId === ls.loanStatusId
      )

    this._drawerService.show("leadStatusAssociationUpsertDrawer")
  }

  onUpsertDrawerSave(res: LeadStatusAssociation) {
    this.updateTableAfterUpsert(res);
  }

  onUpsertDrawerClose() {
    this._drawerService.hide("leadStatusAssociationUpsertDrawer");
  }

  openOrderDrawer() {
    if (!this.leadStatusAssociations.length) {
      return;
    }

    this.itemsToOrder = this.setItemsOrder();
    this._drawerService.show("leadStatusAssociationOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedLeadStatuses: Array<EnumerationItem>) {
    this.leadStatusAssociationOrder.saveInProgress = true;

    const sortedIds = sortedLeadStatuses.map(r => ({ id: r.value }));

    this._leadStatusAssociationService.updateLeadStatusAssociationOrder(
      sortedIds,
      this._loanPurposeId,
      this._companyId
    )
      .pipe(finalize(() => {
        this.leadStatusAssociationOrder.saveInProgress = false;
        this.closeOrderDrawer();
      }))
      .subscribe(() => {
        this.leadStatusTable.sortField = "";
        this.leadStatusTable.sortOrder = 0;
        this.leadStatusTable.reset();
        this.gridHeaderActionBar.search.nativeElement.value = '';
        this.populateLeadStatusAssociations();
        this._notificationService.showSuccess(
          'Lead status association order updated successfully',
          'Lead Status Association'
        );
      }, err => {
        this._notificationService.showError(
          err?.message || 'Unable to update lead status association order',
          'Lead Status Association'
        );
      });
  }

  closeOrderDrawer() {
    this._drawerService.hide("leadStatusAssociationOrderDrawer", 100);
  }

  sortLeadStatusAssociationsGridByOrder(forceSortByOrderColumn: boolean = false): void {
    if (this.leadStatusTable.sortField && !forceSortByOrderColumn) {
      return;
    }
    this.leadStatusAssociations = orderBy(this.leadStatusAssociations, item => Number(item.order));
  }

  getDisallowedServices(value: string): string {
    let result = '';
    if (!value) {
      return '';
    }
    const arr = value?.split(',') || [];
    arr.forEach((el) => {
      if (el === 'CreditHardPull') {
        if (result.length) result += ', ';
        result += 'Credit - Hard Pull';
      }
      if (el === 'CreditSoftPull') {
        if (result.length) result += ', ';
        result += 'Credit - Soft Pull';
      }
    });
    return result;
  }

  getLoanPurposeName(value: number): string {
    return this.loanPurposes.find(purpose => purpose.loanPurposeId == value)?.loanPurposeName || '';
  }

  getLoanStatusName(value: number): string {
    return this.leadStatuses.find(status => status.loanStatusId == value)?.loanStatusName || '';
  }

  private setActionButtonOptions(): void {
    this.actionButtonOptions = [
      {
        type: GridActionButtonType.SearchInput,
        searchInputPlaceholder: 'Search...',
        onSearch: (value: string) => this.leadStatusTable.filterGlobal(value, 'contains'),
      },
    ];
  }

  private populateLeadStatusAssociations() {
    this._spinner.show();
    this._leadStatusAssociationService.getAllLeadStatusAssociations(this._loanPurposeId, this._companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe((res) => {
        this.leadStatusAssociations = res.map(lsa => ({
          ...lsa,
          loanStatusName: this.getLoanStatusName(Number(lsa.loanStatusId)),
        }));;
        this.sortLeadStatusAssociationsGridByOrder();
      });
  }

  private updateTableAfterUpsert(result: LeadStatusAssociation) {
    result['loanStatusName'] = this.getLoanStatusName(Number(result.loanStatusId));
    const index = this.leadStatusAssociations.findIndex(ls => ls.id === result.id);
    if (index === -1) {
      this.leadStatusAssociations.push(result);
    } else {
      this.leadStatusAssociations[index] = result;
    }
    this.leadStatusAssociations = [...this.leadStatusAssociations];
    this.onUpsertDrawerClose();
  }

  private deleteRow(rowData: LeadStatusAssociation) {
    this._leadStatusAssociationService.deleteLeadStatusAssociation(rowData.id, this._companyId)
      .subscribe(res => {
        const index = this.leadStatusAssociations.findIndex(
          (ls) => ls.id === rowData.id
        );
        this.leadStatusAssociations.splice(index, 1);
        this.leadStatusAssociations = [...this.leadStatusAssociations];

        this._notificationService.showSuccess(
          'Lead status association removed succesfully.',
          'Lead Status Association'
        );
      }, err => {
        this._notificationService.showError(
          err?.message || "Couldn't delete lead status association",
          'Lead Status Association'
        );
      }
      );
  }

  private setItemsOrder() {
    return chain(this.leadStatusAssociations)
      .orderBy(ls => ls.order)
      .map(ls => ({
        name: this.getLoanStatusName(ls.loanStatusId),
        value: ls.id,
      }))
      .value();
  }
}
