import {DeepReadonly} from '../../../../utils/types';
import {FeeSystemDetails, FeeSystemDetailsFeeTypeDetails, FeeType, LoanFee} from '../fees.model';
import {FeeViewModel} from '../fee-view-model';
import {FeeModelUtils} from './model-utils';
import {cloneDeep} from 'lodash';
import {DiffChecker} from '../../../../utils/diff-checker';
import {assignDefaultProps, deepFreeze} from '../../../shared/utils/object/utils';
import {FeeSection} from '../fee-section.model';

export module FeeUtils {
  const requiredFeeProperties: DeepReadonly<Partial<LoanFee>> = deepFreeze({
    calculatedValues: {
      totalFeePercent: 0,
      totalFee: 0,
      sellerConcessions: 0,
      sellerTotal: 0,
      borrowerTotal: 0,
    },
    borrowerFeeDollar: 0,
    borrowerFeePercent: 0,
    sellerFeeDollar: 0,
    sellerFeePercent: 0,
    brokerFeeDollar: 0,
    lenderFeeDollar: 0,
    lenderFeePercent: 0,
    thirdPartyFeeDollar: 0,
    thirdPartyFeePercent: 0,
    paidOutsideClosingBorrowerAmount: 0,
    paidOutsideClosingSellerAmount: 0,
    paidOutsideClosingBrokerAmount: 0,
    paidOutsideClosingLenderAmount: 0,
    paidOutsideClosingThirdPartyAmount: 0,
  });

  const defaultFeeProperties = deepFreeze(FeeModelUtils.createEmptyLoanFee());

  /**
   * Returns the fees that have no total fee.
   * @param {readonly LoanFee[]} fees - The fees to filter.
   * @remarks No total fee means that {@link LoanFee.calculatedValues.totalFee} is either 0 or
   * nullish.
   */
  export function getZeroFees(fees: readonly Readonly<LoanFee>[]): LoanFee[] {
    return fees.filter(fee => !fee.calculatedValues?.totalFee);
  }

  /**
   * Sets the missing fee properties in the given fee object (in-place) with
   * default values.
   *
   * @param {Partial<LoanFee>} fee - The fee object to assign default properties to.
   * @returns {LoanFee} - The given fee object with default properties assigned.
   */
  export function assignRequiredMissingFeeProperties(fee: Partial<LoanFee>): LoanFee {
    return assignDefaultProps(fee, requiredFeeProperties);
  }

  export function assignAllMissingFeeProperties(fee: Partial<LoanFee>): LoanFee {
    return assignDefaultProps(fee, defaultFeeProperties);
  }

  export function sortFeesByHudNumber(fees: readonly Readonly<LoanFee>[]): LoanFee[] {
    return ([...fees] as Array<Readonly<LoanFee>>).sort((a, b) => {
      const aHudNumber = a.hudNumber ?? '';
      const bHudNumber = b.hudNumber ?? '';

      const hudNumberComparison = aHudNumber.localeCompare(bHudNumber, 'en-US', {numeric: true});
      if (hudNumberComparison !== 0) {
        return hudNumberComparison;
      }

      const aSumInHudNumber = a.sumInHudNumber ?? 0;
      const bSumInHudNumber = b.sumInHudNumber ?? 0;
      return aSumInHudNumber - bSumInHudNumber;
    });
  }

  /**
   * Adds up the fee totals.
   *
   * @param {FeeViewModel[]} fees - The array of fee totals.
   * @return {number} - The sum of the seller, other, and borrower fees, excluding summary fees.
   */
  export function addUpFeeTotals(fees: FeeViewModel[]): number {
    let total = 0;
    for (let feeViewModel of iterFeeViewModels(fees)) {
      if (!feeViewModel.hasSubFees) {
        total += feeViewModel.total;
      }
    }
    return total;
  }

  /**
   * Merges the source fees into the target fees.
   * If a fee in the source fees has the same ID as a fee in the target fees, the fee in the source fees will replace the fee in the
   * target fees.
   * Otherwise, the fee in the source fees will be added to the end of the target fees.
   * @param {readonly LoanFee[]} target - The target fees.
   * @param {readonly LoanFee[]} source - The source fees.
   * @param {object} [options] - The options for the merge.
   * @param {keyof LoanFee} [options.compareBy='hudNumber'] - The key to compare the fees by.
   * @returns {LoanFee[]} - The merged fees.
   */
  export function mergeFees(
    target: readonly Readonly<LoanFee>[],
    source: readonly Readonly<LoanFee>[],
    options?: {
      compareBy?: keyof LoanFee;
    }
  ): LoanFee[] {
    const compareBy = options?.compareBy ?? 'hudNumber';
    const sourceIdMap = new Map(source.map(fee => [fee[compareBy], fee]));

    const result = target.reduce((acc, fee) => {
      const sourceFee = sourceIdMap.get(fee[compareBy]);
      if (sourceFee) {
        acc.push(sourceFee);
        sourceIdMap.delete(fee[compareBy]);
      } else {
        acc.push(fee);
      }

      return acc;
    }, [] as LoanFee[]);

    // Add the remaining fees (if any) from the source to the end of the array.
    return [...result, ...sourceIdMap.values()];
  }

  /**
   * Merges all the fee arrays in {@link fees} into one.
   * The array that comes later in the array takes precedence.
   * @param {readonly LoanFee[][]} fees - The fee arrays to merge.
   * @param {object} [options] - The options for the merge.
   * @param {keyof LoanFee} [options.compareBy='hudNumber'] - The key to compare the fees by.
   * @returns {LoanFee[]} - The merged fees.
   */
  export function mergeAllFees(
    fees: readonly Readonly<LoanFee>[][],
    options?: Parameters<typeof mergeFees>[2]
  ): LoanFee[] {
    return fees.reduce((acc, fees) => {
      return mergeFees(acc, fees, options);
    }, [] as LoanFee[]);
  }

  /**
   * Finds the summary fee types from the fee system details.
   * @param {FeeSystemDetails} feeSystemDetails - The fee system details.
   * @returns {Set<FeeType>} - The summary fee types.
   */
  export function findSummaryFeeTypes(feeSystemDetails: FeeSystemDetails): Set<FeeType> {
    const findWithFeeType = () => {
      return feeSystemDetails.feeTypes.reduce((acc, details) => {
        if (details.feeType?.endsWith('Total')) {
          acc.add(details.feeType);
        }

        return acc;
      }, new Set<FeeType>());
    };

    const findWithSumInHudNumber = () => {
      const typeDetails = feeSystemDetails.feeTypes;

      const feeDetailsByHudNumber = typeDetails.reduce((acc, details) => {
        const {hudNumber} = details;
        if (hudNumber == null) {
          // console.error(`Hud number is missing for fee type: ${details.feeType}`);
          return acc;
        }

        if (acc.has(hudNumber)) {
          // console.error(`Duplicate hud number: ${hudNumber}`);
          return acc;
        }

        acc.set(hudNumber, details);

        return acc;
      }, new Map<string, FeeSystemDetailsFeeTypeDetails>());

      return typeDetails.reduce((acc, details) => {
        const {sumInHudNumber} = details;
        if (sumInHudNumber != null) {
          const feeType = feeDetailsByHudNumber.get(sumInHudNumber.toString());
          if (feeType == null) {
            console.error(`Fee type details not found for hud number: ${sumInHudNumber}`);
            return acc;
          }

          acc.add(feeType.feeType);
        }

        return acc;
      }, new Set<FeeType>());
    };

    return new Set([...findWithFeeType(), ...findWithSumInHudNumber()]);
  }

  /**
   * Compares two LoanFee objects by their properties (values).
   * @param {LoanFee} a
   * @param {LoanFee} b
   * @returns {boolean} - Whether the given LoanFee objects are equal in terms
   * of their properties.
   * @remarks This is a high-cost operation.
   */
  export function areFeesEqual(a: LoanFee, b: LoanFee): boolean {
    if (!areFeeReferencesEqual(a, b)) {
      return false;
    }

    const aClone = assignAllMissingFeeProperties(cloneDeep(a));
    const bClone = assignAllMissingFeeProperties(cloneDeep(b));

    const diffChecker = new DiffChecker(aClone, bClone, 'fee');

    return diffChecker.calculateDiff() == null;
  }

  /**
   * Whether the given LoanFee objects represent the same fee reference.
   * @param {LoanFee} a
   * @param {LoanFee} b
   * @returns {boolean} - Whether the given LoanFee objects represent the same
   * fee reference regardless of their current properties.
   * @remarks This is a low-cost operation as it only compares the identity of
   * the fees.
   */
  export function areFeeReferencesEqual(a: Readonly<LoanFee>, b: Readonly<LoanFee>): boolean {
    if (a == null || b == null) {
      return false;
    }
    if (a === b) {
      return true;
    }

    return (
      areFeeIdsEqual(a, b) ||
      (strictEqualNonNil(a.hudNumber, b.hudNumber) && a.feeSection === b.feeSection)
    );
  }

  /**
   * Whether the given FeeSection objects represent the same fee section reference.
   * @param {FeeSection} a
   * @param {FeeSection} b
   * @param {(a: LoanFee, b: LoanFee) => boolean} [compareFeeFn] - The function to use for comparing
   * the fees.
   * If not provided, {@link areFeesEqual} will be used.
   * @returns {boolean} - Whether the given FeeSection objects represent the same
   * fee section reference regardless of their current properties.
   */
  export function areFeeSectionsEqual(
    a: FeeSection,
    b: FeeSection,
    compareFeeFn?: (a: LoanFee, b: LoanFee) => boolean
  ): boolean {
    if (a == null || b == null) {
      return false;
    }
    if (a === b) {
      return true;
    }
    if (a.type !== b.type) {
      return false;
    }
    const aFees = a.fees;
    const bFees = b.fees;

    if (aFees == null || bFees == null) {
      return false;
    }
    if (aFees.length !== bFees.length) {
      return false;
    }

    const effectiveCompareFeeFn = compareFeeFn ?? areFeesEqual;
    for (let i = 0; i < aFees.length; i++) {
      if (!effectiveCompareFeeFn(aFees[i], bFees[i])) {
        return false;
      }
    }

    return true;
  }

  /**
   * Whether the given FeeSection objects represent the same fee section reference.
   * @param {FeeSection} a
   * @param {FeeSection} b
   * @returns {boolean} - Whether the given FeeSection objects represent the same fee section
   * reference regardless of their current properties.
   */
  export function areFeeSectionReferencesEqual(a: FeeSection, b: FeeSection): boolean {
    if (a == null || b == null) {
      return false;
    }
    if (a === b) {
      return true;
    }

    return a.type === b.type;
  }

  function areFeeIdsEqual(a: LoanFee, b: LoanFee): boolean {
    // `loanFeeId` being 0 means the fee is not saved yet.
    return a.loanFeeId === 0 || b.loanFeeId === 0
      ? false
      : strictEqualNonNil(a.loanFeeId, b.loanFeeId);
  }

  export function* iterFeeViewModels(
    feeViewModels: readonly FeeViewModel[]
  ): Generator<FeeViewModel> {
    for (const feeViewModel of feeViewModels) {
      yield* iterFeeViewModel(feeViewModel);
    }
  }

  export function* iterFeeViewModel(feeViewModel: FeeViewModel): Generator<FeeViewModel> {
    yield feeViewModel;

    for (const subFee of feeViewModel.subFees) {
      yield* iterFeeViewModel(subFee);
    }
  }
}

function strictEqualNonNil<T>(a: T, b: T): boolean {
  return a != null && a === b;
}
