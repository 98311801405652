<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="form-group row">
            <label class="form-label align-self-center mb-lg-0">
                Select the export format for your documents:
            </label>
            <div>
                <select class="form-select" name="selectedFormat" [(ngModel)]="selectedFormat"
                    (change)="onSelectionChanged(selectedFormat)">
                    <option *ngFor="let docFormat of docFormats" value="{{docFormat.value}}">
                        {{docFormat.name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
    <button type="button" class="btn btn-primary right" (click)="onSaveClicked()"> Export </button>
</div>