import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'new-application-dialog',
    templateUrl: 'new-application-dialog.component.html',
    styleUrls: ['new-application-dialog.component.scss']
})

export class NewApplicationDialogComponent implements OnInit {

    @Input()
    scenarioId: number;

    constructor(public activeModal: NgbActiveModal) { }

    newAppCreatedSuccessfully = (e: any) => {
        this.activeModal.close('success');
    }

    ngOnInit() { }

}