import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeesReviewComponent} from './fees-review/fees-review.component';
import {FeesReviewSectionComponent} from './fees-review/fees-review-section/fees-review-section.component';
import {SharedModule} from '../../shared/shared.module';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {FeeSplitEditorComponent} from './fee-split-editor/fee-split-editor.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AddFeeModalComponent} from './add-fee-modal/add-fee-modal.component';
import {FeePeriodEditorComponent} from './fee-period-editor/fee-period-editor.component';
import {PrepaidInterestEditorComponent} from './prepaid-interest-editor/prepaid-interest-editor.component';
import {FeeShopConfigEditorComponent} from './fee-shop-config-editor/fee-shop-config-editor.component';
import {FeeDataAccessService} from './services/fee-data-access.service';

@NgModule({
  declarations: [
    FeesReviewComponent,
    FeesReviewSectionComponent,
    FeeSplitEditorComponent,
    AddFeeModalComponent,
    FeePeriodEditorComponent,
    PrepaidInterestEditorComponent,
    FeeShopConfigEditorComponent,
    AddFeeModalComponent,
  ],
  imports: [CommonModule, SharedModule, CollapseModule, ReactiveFormsModule],
  exports: [FeesReviewComponent, AddFeeModalComponent],
  providers: [FeeDataAccessService],
})
export class FeesV2Module {}
