import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Configuration } from '../../../../../../models/configuration.model';
import { UserService } from 'src/app/services/user.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
    selector: 'toggle-configuration',
    templateUrl: './toggle-configuration.component.html'
})
export class ToggleConfigurationComponent extends ApplicationContextBoundComponent implements OnInit {
    @Input() title: string;
    @Input() id: string;
    @Input() configuration: Configuration;

    @Output()
    onChange?: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private readonly injector: Injector,
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _userService: UserService,
    ) {
        super(injector);
    }

    ngOnInit(): void {}

    toggleConfiguration(configuration: Configuration): void {
        this._spinner.show();
        this._systemLevelService.saveConfiguration(configuration)
        .subscribe({
            next: res => {
                if (this.onChange?.observed) {
                    this.onChange.emit();
                }

                this.updatePermissionMenu();
                this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
            }
        }).add(() => this._spinner.hide());
    }

    private updatePermissionMenu() {
        this._userService.getUserPermissions().subscribe((permissions) => {
          this.applicationContext.userPermissions = permissions;
        });
      }
}
