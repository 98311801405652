import { Component, Input, OnInit } from '@angular/core';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { DocPrep } from '../../models/doc-prep.model';
import { Constants } from 'src/app/services/constants';
import { Address } from '../../../../../models';

@Component({
  selector: 'document-preparation-property-info',
  templateUrl: 'document-preparation-property-info.component.html',
  styleUrls: ['./document-preparation-property-info.component.scss']
})

export class DocumentPreparationPropertyInfoComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  protected states: EnumerationItem[] = [];
  protected occupancyOptions: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService) { }

  ngOnInit() {
    this.states = this._enumsService.states;
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this.occupancyOptions = enums[Constants.mortgageEnumerations.propertyWillBeType];
    });
  }

  protected handleAddressChange(e: Partial<Address>): void {
    const propertyAddress = this.docPrep;
    propertyAddress.subjectPropertyAddress = ''; // to reset the last populated address.

    setTimeout(() => {
      propertyAddress.subjectPropertyAddress = e.address1;
      propertyAddress.subjectPropertyCity = e.city;
      propertyAddress.subjectPropertyState = e.state;
      propertyAddress.subjectPropertyZipCode = e.zipCode;
      propertyAddress.subjectPropertyCounty = e.county;
    }, 200);
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.docPrep.subjectPropertyState = zipCode.state.toLowerCase();
      this.docPrep.subjectPropertyCity = Utils.toTitleCase(zipCode.city);
      this.docPrep.subjectPropertyZipCode = zipCode.zipcode;
      this.docPrep.subjectPropertyCounty = zipCode.county;
    }
  }
}
