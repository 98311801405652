<ng-container *ngIf="!isLoading; else loading">
    <div id="hmdaDrawerContainer" class="mb-5">
        <div *ngIf="!docGenMode" class="panel mb-1">
          <h5 class="panel-header m-0">
            Loan & Property Info
          </h5>
          <div class="panel-body">
            <div class="row loan-property-section">
              <div class="col-md-12">
                <ul class="list-group">
                  <li class="list-group-item text-dark fw-bold">Borrowers</li>
                  <li class="list-group-item text-dark ms-3">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item" *ngFor="let borrowers of borrowerGroups; let i = index">
                        <a class="nav-link" [ngClass]="{ active: tab == borrowers[0].borrowerId }"
                          (click)="tab = borrowers[0].borrowerId" data-bs-toggle="tab" href="javascript: void(0)" role="tab">
                          <span *ngFor="let borrower of borrowers; let j = index">
                            {{j == 0 ? '' : ' / '}}{{getBorrowerDisplayName(borrower)}}
                          </span>
                        </a>
                      </li>
                    </ul>

                    <div *ngFor="let borrowers of borrowerGroups" id="applications" class="tab-pane"
                      [ngClass]="{ active: tab == borrowers[0].borrowerId }" role="tabpanel">
                      <ng-container *ngIf="tab == borrowers[0].borrowerId">
                        <div class="row mt-3">
                          <div *ngFor="let borrower of borrowers" class="col-sm-12"
                            [ngClass]="{'col-md-6' : borrowers.length > 1 }">
                              <div class="row">
                                  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
                                    <label for="firstName{{borrower.borrowerId}}">First Name</label>
                                    <input id="firstName{{borrower.borrowerId}}" type="text" name="firstName{{borrower.borrowerId}}" class="form-control" placeholder="First name"
                                      [(ngModel)]="borrower.firstName" #firstNameInput="ngModel" />
                                  </div>
                                  <div class="form-group col-xs-12 col-sm-6 col-lg-2">
                                    <label for="middleName{{borrower.borrowerId}}">Middle Name</label>
                                    <input id="middleName{{borrower.borrowerId}}" name="middleName{{borrower.borrowerId}}" type="text" class="form-control" placeholder="Middle name"
                                      [(ngModel)]="borrower.middleName" />
                                  </div>
                                  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
                                    <label for="lastName{{borrower.borrowerId}}">Last Name</label>
                                    <input id="lastName{{borrower.borrowerId}}" name="lastName{{borrower.borrowerId}}" type="text" class="form-control" placeholder="Last name"
                                      [(ngModel)]="borrower.lastName" #lastNameInput="ngModel" />
                                  </div>
                                  <div class="form-group col-xs-12 col-sm-6 col-lg-1">
                                    <label for="suffix{{borrower.borrowerId}}"> Suffix </label>
                                    <select id="suffix{{borrower.borrowerId}}" name="suffix{{borrower.borrowerId}}" class="form-select"
                                      [(ngModel)]="borrower.nameSuffix" #suffix="ngModel">
                                      <option [ngValue]="null">-- Select One --</option>
                                      <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
                                        {{ opt.name }}
                                      </option>
                                    </select>
                                  </div>
                              </div>

                              <div class="row pt-2">
                                <div class="col-md-12 py-2">
                                  <div class="border py-2" style="padding: 15px 10px 5px 10px;">
                                    <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
                                      Present Address
                                    </span>
                                    <div class="row mb-2" style="padding-top: 6px;">
                                      <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': borrowers.length > 1}">
                                        <label for="presentStreet{{borrower.borrowerId}}">Street Address</label>
                                        <address-autocomplete-input
                                          (addressChange)='handleAddressChange($event, mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address)'
                                          [(ngModel)]='mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address.address1'
                                          id='presentStreet{{borrower.borrowerId}}'
                                          name='presentStreet{{borrower.borrowerId}}'
                                          placeholder=''
                                        ></address-autocomplete-input>
                                      </div>
                                      <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': borrowers.length > 1}">
                                        <label for="presentPOBox{{borrower.borrowerId}}">Unit/Suite</label>
                                        <input id="presentPOBox{{borrower.borrowerId}}" name="presentPOBox{{borrower.borrowerId}}" type="text" class="form-control"
                                          [(ngModel)]="mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address.address2" />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': borrowers.length > 1}">
                                        <label for="presentCity{{borrower.borrowerId}}">City</label>
                                        <input id="presentCity{{borrower.borrowerId}}" name="presentCity{{borrower.borrowerId}}" type="text" class="form-control"
                                          [(ngModel)]="mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address.city" #presentCityInput="ngModel" />
                                        <div class="invalid-feedback">This field is required</div>
                                      </div>
                                      <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': borrowers.length > 1}">
                                        <div class="row">
                                          <div class="col">
                                            <label for="presentState{{borrower.borrowerId}}">State</label>
                                            <select id="presentState{{borrower.borrowerId}}" name="presentState{{borrower.borrowerId}}" class="form-select" placeholder="State"
                                              [(ngModel)]="mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address.state">
                                              <option [ngValue]="null"></option>
                                              <option *ngFor="let state of states" [ngValue]="state.value">
                                                {{ state.name }}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="col">
                                            <label for="presentZip{{borrower.borrowerId}}">Zip Code</label>
                                            <zip-code-input [(ngModel)]="mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address.zipCode" [name]="'presentZip' + borrower.borrowerId" [required]="false"
                                              [disabled]="false" [readonly]="false" [use9digits]="false"
                                              (selectionChanged)="onZipCodeRelatedInfoChanged($event, mortgageBorrowerPresentAddresMap.get(borrower.borrowerId).address)"></zip-code-input>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </li>
                  <li class="list-group-item text-dark fw-bold">Lender/Broker</li>
                  <li class="list-group-item text-dark ms-3">
                    <div class="row mb-2">
                      <div class="form-group col-xs-12 col-sm-4">
                        <label for="lName">Name</label>
                        <span class="d-flex justify-content-start align-items-center">
                          <select id="lName" name="lName"
                            class="form-select"
                            placeholder="Lender" [(ngModel)]="application.lenderId"
                            [disabled]="true"
                          >
                            <option [ngValue]="null">-- Select One --</option>
                            <option *ngFor="let lender of lenders" [ngValue]="lender.lenderId">
                              {{ lender.name }}
                            </option>
                          </select>

                          <button *ngIf="application.lenderId" (click)="showLenderInfoDialog()"
                            class="btn d-flex justify-content-center align-items-center ms-1 p-0" type="button">
                            <i class="fas fa-info-circle" style="color: var(--ck-color-button-on-color)"></i>
                          </button>
                        </span>
                      </div>
                      <div class="form-group col-xs-12 col-sm-4">
                        <label for="laddress1">Street Address</label>
                        <address-autocomplete-input
                          #lAddress='ngModel'
                          (addressChange)='handleAddressChange($event, lenderAddress)'
                          [(ngModel)]='lenderAddress.address1'
                          [disabled]='true'
                          id='laddress1'
                          name='laddress1'
                          placeholder=''
                          type='text'
                        ></address-autocomplete-input>
                      </div>
                      <div class="form-group col-xs-12 col-sm-4">
                        <label for="laddress2">Unit/Suite</label>
                        <input id="laddress2" name="laddress2" type="text" class="form-control"
                          [(ngModel)]="lenderAddress.address2" [disabled]="true"/>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="form-group col-xs-12 col-sm-4">
                        <label for="lCity">City</label>
                        <input id="lCity" name="lCity" type="text" class="form-control"
                          [(ngModel)]="lenderAddress.city" [disabled]="true" />
                      </div>
                      <div class="form-group col-xs-12 col-sm-4">
                            <label for="lState">State</label>
                            <select id="lState" name="lState"
                              class="form-select"
                              placeholder="State" [(ngModel)]="lenderAddress.state" [disabled]="true"
                              >
                              <option [ngValue]="null"></option>
                              <option *ngFor="let state of states" [ngValue]="state.value">
                                {{ state.name }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-xs-12 col-sm-4">
                            <label for="lZip">Zip Code</label>
                            <zip-code-input [(ngModel)]="lenderAddress.zipCode"
                            [name]="'lZip'" [disabled]="true">
                            </zip-code-input>
                          </div>
                    </div>
                  </li>
                  <li class="list-group-item text-dark fw-bold">Subject Property
                    <span class="pull-right">
                      <div class="form-check form-switch mb-0">
                      <input [(ngModel)]="subjectPropertyAddressIsTbd" class="form-check-input" type="checkbox"
                        id="tbdSwitchCheck" name="tbdSwitchCheck"
                        (ngModelChange)="onSubjectPropertyTBDCheckChanged()">
                      <label class="form-label mb-0">To be determined</label>
                      </div>
                    </span>
                  </li>
                  <li class="list-group-item text-dark ms-3">
                    <div class="row mb-2">
                      <div class="form-group col-xs-12 col-sm-8 custom-div-left-side">
                        <label for="address1">Street Address</label>
                        <address-autocomplete-input
                          #address='ngModel'
                          (addressChange)="handleAddressChange($event, subjectProperty)"
                          [(ngModel)]='subjectProperty.address1'
                          [disabled]='subjectPropertyAddressIsTbd'
                          [ngClass]="{
                            'invalid-requested' : address && address.invalid && address.errors && address.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'requested',
                            'invalid-required' : address && address.invalid && address.errors && address.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'required'
                          }"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'requested'"
                          class='custom-input-left-side'
                          id='address1'
                          name='address1'
                          placeholder=''
                          type='text'
                        ></address-autocomplete-input>
                      </div>
                      <div class="form-group col-xs-12 col-sm-4 custom-div-right-side">
                        <label for="address2">Unit/Suite</label>
                        <input id="address2" name="address2" type="text" class="form-control custom-input-right-side"
                          [(ngModel)]="subjectProperty.address2" [disabled]="subjectPropertyAddressIsTbd"/>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="form-group col-xs-12 col-sm-4 custom-div-left-side">
                        <label for="propertyCity">City</label>
                        <input id="propertyCity" name="propertyCity" type="text" class="form-control custom-input-left-side"
                          [(ngModel)]="subjectProperty.city" #propertyCity="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'required' ||
                          quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'requested'"
                          [ngClass]="{
                            'invalid-requested' : propertyCity && propertyCity.invalid && propertyCity.errors && propertyCity.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'requested',
                            'invalid-required' : propertyCity && propertyCity.invalid && propertyCity.errors && propertyCity.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'required'
                          }" />
                      </div>
                      <div class="form-group col-xs-12 col-sm-2 px-0">
                            <label for="propertyState">State</label>
                            <select id="propertyState" name="propertyState" #propertyState="ngModel"
                              class="form-select custom-input-left-side custom-input-right-side"
                              placeholder="State" [(ngModel)]="subjectProperty.state"
                              [required]="quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'requested'"
                              [ngClass]="{
                                'invalid-requested' : propertyState && propertyState.invalid && propertyState.errors && propertyState.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'requested',
                                'invalid-required' : propertyState && propertyState.invalid && propertyState.errors && propertyState.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'required'
                              }" >
                              <option [ngValue]="null"></option>
                              <option *ngFor="let state of states" [ngValue]="state.value">
                                {{ state.name }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-xs-12 col-sm-3 px-0">
                            <label for="propertyZip">Zip Code</label>
                            <zip-code-input [(ngModel)]="subjectProperty.zipCode" [name]="'propertyZip'"
                              class="custom-input-left-side custom-input-right-side" (blur)="onZipCodeRelatedInfoChanged($event, subjectProperty)"
                              (selectionChanged)="onZipCodeRelatedInfoChanged($event, subjectProperty)"
                              [required]="quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'requested'"
                              [ngClass]="{
                                'invalid-requested' : !subjectProperty.zipCode
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'requested',
                                'invalid-required' : !subjectProperty.zipCode
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'required'
                              }" >
                            </zip-code-input>
                          </div>
                          <div class="form-group col-xs-12 col-sm-3 custom-div-right-side">
                            <label for="propertyCounty">County</label>
                            <input id="propertyCounty" name="propertyCounty" type="text" class="form-control custom-input-right-side"
                              [(ngModel)]="subjectProperty.county" [required]="true" #propertyCounty="ngModel"
                              [required]="quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'requested'"
                              [ngClass]="{
                                'invalid-requested' : propertyCounty && propertyCounty.invalid && propertyCounty.errors && propertyCounty.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'requested',
                                'invalid-required' : propertyCounty && propertyCounty.invalid && propertyCounty.errors && propertyCounty.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'required'
                              }"  />
                          </div>
                    </div>
                  </li>
                </ul>
              </div>
          </div>
          </div>
        </div>
        <div *ngIf="!docGenMode" class="panel mb-1">
            <h5 class="panel-header m-0">
                Principal Reason(s) for Credit Denial, Termination, or Other Action Taken Concerning Credit
            </h5>
            <div class="panel-body">
                <div class="row">
                    <label class="form-label fw-bold">Description of Account, Transaction or Requested
                        Credit.</label>
                    <input class="form-control mb-3" name="descriptionOfAccount"
                        [(ngModel)]="denialTerminationChangeDetail.descriptionOfAccount" />
                </div>
                <div class="row">
                    <label class="form-label fw-bold">Description of Action Taken</label>
                    <input class="form-control mb-3" name="descriptionOfActionTaken"
                        [(ngModel)]="denialTerminationChangeDetail.descriptionOfActionTaken" />
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <ul class="list-group">
                            <li class="list-group-item text-dark fw-bold">A.CREDIT</li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="noCredFile" class="form-check-input" type="checkbox" name="noCredFile"
                                        [(ngModel)]="denialTerminationChangeDetail.noCreditFile">
                                    <label class="form-check-label text-dark" for="noCredFile">
                                        No Credit File
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="insufficientNumberOfCreditReferencesProvided" class="form-check-input"
                                        type="checkbox" name="insufficientNumberOfCreditReferencesProvided"
                                        [(ngModel)]="denialTerminationChangeDetail.insufficientNumberOfCreditReferencesProvided">
                                    <label class="form-check-label text-dark"
                                        for="insufficientNumberOfCreditReferencesProvided">
                                        Insufficient Number of Credit References Provided
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="limitedCreditExperience" class="form-check-input" type="checkbox"
                                        name="limitedCreditExperience"
                                        [(ngModel)]="denialTerminationChangeDetail.limitedCreditExperience">
                                    <label class="form-check-label text-dark" for="limitedCreditExperience">
                                        Limited Credit Experience
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unableToVerifyCreditReferences" class="form-check-input" type="checkbox"
                                        name="unableToVerifyCreditReferences"
                                        [(ngModel)]="denialTerminationChangeDetail.unableToVerifyCreditReferences">
                                    <label class="form-check-label text-dark" for="unableToVerifyCreditReferences">
                                        Unable to Verify Credit References
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="garneshmentAttachment" class="form-check-input" type="checkbox"
                                        name="garneshmentAttachment"
                                        [(ngModel)]="denialTerminationChangeDetail.garneshmentAttachment">
                                    <label class="form-check-label text-dark" for="garneshmentAttachment">
                                        Garnishment Attachment
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="foreclosureRepossession" class="form-check-input" type="checkbox"
                                        name="foreclosureRepossession"
                                        [(ngModel)]="denialTerminationChangeDetail.foreclosureRepossession">
                                    <label class="form-check-label text-dark" for="foreclosureRepossession">
                                        Foreclosure Repossession
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="collectionJudgement" class="form-check-input" type="checkbox"
                                        name="collectionJudgement"
                                        [(ngModel)]="denialTerminationChangeDetail.collectionJudgement">
                                    <label class="form-check-label text-dark" for="collectionJudgement">
                                        Collection Judgement
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="insufficientCredit" class="form-check-input" type="checkbox"
                                        name="insufficientCredit"
                                        [(ngModel)]="denialTerminationChangeDetail.insufficientCredit">
                                    <label class="form-check-label text-dark" for="insufficientCredit">
                                        Insufficient Credit
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="insuffientIncomeForTotalObligations" class="form-check-input"
                                        type="checkbox" name="insuffientIncomeForTotalObligations"
                                        [(ngModel)]="denialTerminationChangeDetail.insuffientIncomeForTotalObligations">
                                    <label class="form-check-label text-dark" for="insuffientIncomeForTotalObligations">
                                        Insufficient Income for Total Obligations
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unacceptablePaymentRecordOnPreviousMortgage" class="form-check-input"
                                        type="checkbox" name="unacceptablePaymentRecordOnPreviousMortgage"
                                        [(ngModel)]="denialTerminationChangeDetail.unacceptablePaymentRecordOnPreviousMortgage">
                                    <label class="form-check-label text-dark"
                                        for="unacceptablePaymentRecordOnPreviousMortgage">
                                        Unacceptable Payment Record on Previous Mortgage
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="delinquentPastOrPresentCreditObligationsWithOthers"
                                        class="form-check-input" type="checkbox"
                                        name="delinquentPastOrPresentCreditObligationsWithOthers"
                                        [(ngModel)]="denialTerminationChangeDetail.delinquentPastOrPresentCreditObligationsWithOthers">
                                    <label class="form-check-label text-dark"
                                        for="delinquentPastOrPresentCreditObligationsWithOthers">
                                        Delinquent Past or Present Credit Obligations with Others
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="bankruptcy" class="form-check-input" type="checkbox" name="bankruptcy"
                                        [(ngModel)]="denialTerminationChangeDetail.bankruptcy">
                                    <label class="form-check-label text-dark" for="bankruptcy">
                                        Bankruptcy
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unacceptableTypeOfCreditReferencesProvided" class="form-check-input"
                                        type="checkbox" name="unacceptableTypeOfCreditReferencesProvided"
                                        [(ngModel)]="denialTerminationChangeDetail.unacceptableTypeOfCreditReferencesProvided">
                                    <label class="form-check-label text-dark"
                                        for="unacceptableTypeOfCreditReferencesProvided">
                                        Unacceptable Type of Credit References Provided
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="poorCreditPerformanceWithUs" class="form-check-input" type="checkbox"
                                        name="poorCreditPerformanceWithUs"
                                        [(ngModel)]="denialTerminationChangeDetail.poorCreditPerformanceWithUs">
                                    <label class="form-check-label text-dark" for="poorCreditPerformanceWithUs">
                                        Poor Credit Performance With Us
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="numberOfRecentInquiriesOnCreditBureauReport" class="form-check-input"
                                        type="checkbox" name="numberOfRecentInquiriesOnCreditBureauReport"
                                        [(ngModel)]="denialTerminationChangeDetail.numberOfRecentInquiriesOnCreditBureauReport">
                                    <label class="form-check-label text-dark"
                                        for="numberOfRecentInquiriesOnCreditBureauReport">
                                        Number of Recent Inquiries on Credit Bureau Report
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark fw-bold">B.EMPLOYMENT STATUS</li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unableToVerifyEmployment" class="form-check-input" type="checkbox"
                                        name="unableToVerifyEmployment"
                                        [(ngModel)]="denialTerminationChangeDetail.unableToVerifyEmployment">
                                    <label class="form-check-label text-dark" for="unableToVerifyEmployment">
                                        Unable to Verify Employment
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="lengthOfEmployment" class="form-check-input" type="checkbox"
                                        name="lengthOfEmployment"
                                        [(ngModel)]="denialTerminationChangeDetail.lengthOfEmployment">
                                    <label class="form-check-label text-dark" for="lengthOfEmployment">
                                        Length of Employment
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="temporaryOrIrregularEmployment" class="form-check-input" type="checkbox"
                                        name="temporaryOrIrregularEmployment"
                                        [(ngModel)]="denialTerminationChangeDetail.temporaryOrIrregularEmployment">
                                    <label class="form-check-label text-dark" for="temporaryOrIrregularEmployment">
                                        Temporary or Irregular Employment
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list-group">
                            <li class="list-group-item text-dark fw-bold">C.INCOME</li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="incomeInsufficientForAmountOfCreditRequested" class="form-check-input"
                                        type="checkbox" name="incomeInsufficientForAmountOfCreditRequested"
                                        [(ngModel)]="denialTerminationChangeDetail.incomeInsufficientForAmountOfCreditRequested">
                                    <label class="form-check-label text-dark"
                                        for="incomeInsufficientForAmountOfCreditRequested">
                                        Income Insufficient for Amount of Credit Requested
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unableToVerifyIncome" class="form-check-input" type="checkbox"
                                        name="unableToVerifyIncome"
                                        [(ngModel)]="denialTerminationChangeDetail.unableToVerifyIncome">
                                    <label class="form-check-label text-dark" for="unableToVerifyIncome">
                                        Unable to Verify Income
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="excessiveObligationsInRelationToIncome" class="form-check-input"
                                        type="checkbox" name="excessiveObligationsInRelationToIncome"
                                        [(ngModel)]="denialTerminationChangeDetail.excessiveObligationsInRelationToIncome">
                                    <label class="form-check-label text-dark"
                                        for="excessiveObligationsInRelationToIncome">
                                        Excessive Obligations in Relation to Income
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark fw-bold">D.RESIDENCY</li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="temporaryResidence" class="form-check-input" type="checkbox"
                                        name="temporaryResidence"
                                        [(ngModel)]="denialTerminationChangeDetail.temporaryResidence">
                                    <label class="form-check-label text-dark" for="temporaryResidence">
                                        Temporary Residence
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="lengthOfResidence" class="form-check-input" type="checkbox"
                                        name="lengthOfResidence"
                                        [(ngModel)]="denialTerminationChangeDetail.lengthOfResidence">
                                    <label class="form-check-label text-dark" for="lengthOfResidence">
                                        Length of Residence
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unableToVerifyResidence" class="form-check-input" type="checkbox"
                                        name="unableToVerifyResidence"
                                        [(ngModel)]="denialTerminationChangeDetail.unableToVerifyResidence">
                                    <label class="form-check-label text-dark" for="unableToVerifyResidence">
                                        Unable to Verify Residence
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark fw-bold">E.OTHER</li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="creditApplicationIncomplete" class="form-check-input" type="checkbox"
                                        name="creditApplicationIncomplete"
                                        [(ngModel)]="denialTerminationChangeDetail.creditApplicationIncomplete">
                                    <label class="form-check-label text-dark" for="creditApplicationIncomplete">
                                        Credit Application Incomplete
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-3">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="inadequateCollateral" class="form-check-input" type="checkbox"
                                        name="inadequateCollateral"
                                        [(ngModel)]="denialTerminationChangeDetail.inadequateCollateral">
                                    <label class="form-check-label text-dark" for="inadequateCollateral">
                                        Inadequate Collateral
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unacceptableProperty" class="form-check-input" type="checkbox"
                                        name="unacceptableProperty"
                                        [(ngModel)]="denialTerminationChangeDetail.unacceptableProperty">
                                    <label class="form-check-label text-dark" for="unacceptableProperty">
                                        Unacceptable Property
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="insufficientPropertyData" class="form-check-input" type="checkbox"
                                        name="insufficientPropertyData"
                                        [(ngModel)]="denialTerminationChangeDetail.insufficientPropertyData">
                                    <label class="form-check-label text-dark" for="insufficientPropertyData">
                                        Insufficient Data — Property
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unacceptableAppraisal" class="form-check-input" type="checkbox"
                                        name="unacceptableAppraisal"
                                        [(ngModel)]="denialTerminationChangeDetail.unacceptableAppraisal">
                                    <label class="form-check-label text-dark" for="unacceptableAppraisal">
                                        Unacceptable Appraisal
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="unacceptableLeaseholdEstate" class="form-check-input" type="checkbox"
                                        name="unacceptableLeaseholdEstate"
                                        [(ngModel)]="denialTerminationChangeDetail.unacceptableLeaseholdEstate">
                                    <label class="form-check-label text-dark" for="unacceptableLeaseholdEstate">
                                        Unacceptable Leasehold Estate
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item text-dark ms-5">
                                <div class="form-check form-switch form-switch-success">
                                    <input id="valueOrTypeOfCollateralIsNotSufficient" class="form-check-input"
                                        type="checkbox" name="valueOrTypeOfCollateralIsNotSufficient"
                                        [(ngModel)]="denialTerminationChangeDetail.valueOrTypeOfCollateralIsNotSufficient">
                                    <label class="form-check-label text-dark"
                                        for="valueOrTypeOfCollateralIsNotSufficient">
                                        Value or Type of Collateral is Not Sufficient
                                    </label>
                                </div>
                            </li>
                            <li class="list-group-item ms-3 pb-0">
                                <label class="form-check-label text-dark">
                                    We do not grant credit to any application on...
                                </label>
                            </li>
                            <li class="list-group-item ms-3 pt-0">
                                <input type="text" class="form-control" [(ngModel)]="reason1">
                            </li>
                            <li class="list-group-item pt-0 ms-3">
                                <input type="text" class="form-control" [(ngModel)]="reason2">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-4">
                    <label class="form-label fw-bold">Factors that Adverseley affected Credit Score</label>
                    <input class="form-control mb-3" name="factorsThatAdverseleyAffectedCreditScore"
                        [(ngModel)]="denialTerminationChangeDetail.factorsThatAdverseleyAffectedCreditScore" />
                </div>
            </div>
        </div>
        <div *ngIf="!docGenMode" class="panel mb-1">
            <h5 class="panel-header m-0">
                Statement of Denial
            </h5>
            <div class="panel-body">
                <div class="row mb-3 align-items-center">
                    <div class="col-md-2">
                        <label class="form-label" for="denialDate">
                            Date Denied
                        </label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control" type="date" name="denialDate"
                            [ngModel]="denialDate | date:'yyyy-MM-dd'" (ngModelChange)="denialDate = $event;">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-md-2">
                        <label class="form-label" for="denialBy">
                            Denied By
                        </label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control" type="text" name="denialBy" [(ngModel)]="denialTerminationChangeDetail.deniedByUserName">
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                    <div class="col-md-2">
                        <label class="form-label" for="mailingDate">
                            Mailed On
                        </label>
                    </div>
                    <div class="col-md-2">
                        <input class="form-control" type="date" name="mailingDate"
                            [ngModel]="mailingDate | date:'yyyy-MM-dd'" (ngModelChange)="mailingDate = $event;">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="docGenMode" class="mb-5">
            <div class="form-group">
                <label class="form-label mb-0">Which document do you want to generate?</label>
                <ng-select width="100%" name="documentType" bindLabel="documentTypeName" bindValue="documentTypeId"
                    class="custom-ng-select" placeholder="Select Document Type" [closeOnSelect]="true"
                    [items]="documentTypes" [(ngModel)]="selectedDocumentTypeId">
                </ng-select>
            </div>
        </div>
        <div class="d-flex float-end mt-3 mb-3">
            <button *ngIf="!docGenMode" class="btn btn-primary mx-1" type="button" [disabled]="isSaving"
                (click)="onCloseClicked()">
                Close
            </button>
            <button *ngIf="!docGenMode" type="button" class="btn btn-primary mx-1" (click)="docGenMode = true"
                [disabled]="isSaving">
                <span *ngIf="isGeneratingDoc" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                Generate Document
            </button>
            <button *ngIf="!docGenMode" type="button" class="btn btn-primary mx-1" (click)="onSaveClicked(false)"
                [disabled]="isSaving">
                <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
            <button *ngIf="docGenMode" type="button" class="btn btn-primary mx-1" (click)="onSaveClicked(true)"
                [disabled]="isGeneratingDoc || !selectedDocumentTypeId">
                <span *ngIf="isGeneratingDoc" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                OK
            </button>
            <button *ngIf="docGenMode" type="button" class="btn btn-primary mx-1" (click)="docGenMode = false"
                [disabled]="isGeneratingDoc">
                Cancel
            </button>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div class="offset-lg-0 offset-xxl-1 offset-xxxl-2 col-lg-12 col-xxl-10 col-xxxl-8">
        <loading-indicator [loadingMessage]="'Please wait...'"
            [customClass]="'init-loading-indicator'"></loading-indicator>
    </div>
</ng-template>
