import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, LoanApplication } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { StackingOrderDocTypes } from 'src/app/models/config/stacking-order-doc-types.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { LoanDocsService } from '../../loan-docs/services/loan-docs.service';
import { ExportDocFilesRequest } from '../models/export-doc-files-request.model';
import { ExportFiles } from '../models/export-files.model';
import { ExportLoanDocFiles } from '../models/export-loan-doc-files.model';
import { ExportLoanDocsFilter } from '../models/export-loan-docs-filter.model';
import { ExportFileService } from '../services/export-file.service';
import { ExportLoanDialogComponent } from './export-loan-dialog/export-loan-dialog.component';

@Component({
  selector: 'export-loan-docs',
  templateUrl: 'export-loan-docs.component.html',
  styleUrls: ['export-loan-docs.component.scss'],
})

export class ExportLoanDocsComponent extends ApplicationContextBoundComponent implements OnInit {

  loadingExportingFiles: boolean = true;

  filter: ExportLoanDocsFilter = new ExportLoanDocsFilter();

  isTpoUser: boolean;

  filteredStackingOrders: StackingOrderDocTypes[];

  filteredDocumentTypes: ExportFiles[] = [];

  currentApplication: LoanApplication;

  exportDocFiles: ExportFiles[] = [];

  userType: string;

  selectedDocumentFormatAtSystemLevel: string;

  selectedStackingOrderId: number;

  hasTaskFiles: boolean = false;
  hasConditionFiles: boolean = false;

  hasCheckedExportDocs: boolean = false;

  isSelectAllClicked: boolean = false;

  isAllExpanded: boolean = false;

  routerAppDetail: string = 'app-details';

  selectedFiles: ExportLoanDocFiles[] = [];

  hasPageActionBar: boolean = true;

  private globalConfig: GlobalConfig;

  private _docFormats: EnumerationItem[] = [];

  private _roleId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _exportFileService: ExportFileService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService,
    private readonly _notificationService: NotificationService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.applicationContextService.context.subscribe(context => {
      this.hasPageActionBar = !context.isTpo;

      if (context.application) {
        let loanId = context.application.applicationId;
        this.initializeDocs(loanId);
      }
      else {
        this._activatedRoute.queryParams.subscribe((queryParams) => {
          this._activatedRoute.params.subscribe((routeParams) => {
            const loanId = Number(this._activatedRoute.snapshot.paramMap.get('id'));
            this.initializeDocs(loanId);
          });
        });

      }
    })
  }

  onFilterChanged = (filter: string) => {
    this.filter.filter = filter;
    this.filter = { ...this.filter };
  }

  onFilterExclusionsChanged = () => {
    // You need to do this, only to change the filter reference, so that the child component that has the filter as an input re-renders...
    this.filter = { ...this.filter };
  }

  onSelectAllByFilterClicked = () => {
    this.isSelectAllClicked = !this.isSelectAllClicked;
    if (this.isSelectAllClicked) {
      this.exportDocFiles.forEach(docFile => docFile.docFiles.forEach(file => {
        file.checked = true
        if (file.checked && this.selectedFiles.length > 0) {
          const existingOne = this.selectedFiles.find(f => f.guid === file.guid);
          if (!existingOne) {
            this.selectedFiles.push(file);
          }
        } else {
          this.selectedFiles.push(file);
        }
      }));
      if (this.selectedFiles.length > 0) {
        this.hasCheckedExportDocs = true;
      }
    }
    else {
      this.exportDocFiles.forEach(docFile => docFile.docFiles.forEach(file => file.checked = false));
      this.selectedFiles = [];
      this.hasCheckedExportDocs = false;
    }
  }

  onStackingOrderChanged = () => {
    this.filteredDocumentTypes = this.filterDocTypesByStackingOrder();
  }

  onExportClicked = () => {
    const modalRef = this._modalService.open(ExportLoanDialogComponent, Constants.modalOptions.medium)
    modalRef.componentInstance.title = 'Select Document Format';
    modalRef.componentInstance.docFormats = this._docFormats;
    modalRef.componentInstance.selectedFormat = this.selectedDocumentFormatAtSystemLevel;
    modalRef.result.then((result) => {
      const selectedFormat = result;
      this.exportSelectedFiles(this.selectedFiles, selectedFormat);
    }, (res) => {
    });
  }

  onExportAllTasksClicked = () => {
    if (!this.hasTaskFiles) return;

    const taskFiles = [];
    this.exportDocFiles.forEach(docFile => docFile.docFiles.forEach(file => {
      if (!file.condition) {
        taskFiles.push(file);
      }
    }))

    const modalRef = this._modalService.open(ExportLoanDialogComponent, Constants.modalOptions.medium)
    modalRef.componentInstance.title = 'Select Document Format';
    modalRef.componentInstance.docFormats = this._docFormats;
    modalRef.componentInstance.selectedFormat = this.selectedDocumentFormatAtSystemLevel;
    modalRef.result.then((result) => {
      const selectedFormat = result;
      this.exportSelectedFiles(taskFiles, selectedFormat);
    }, (res) => {
    });
  }

  onExportAllConditionsClicked = () => {
    if (!this.hasConditionFiles) return;

    const taskFiles = [];
    this.exportDocFiles.forEach(docFile => docFile.docFiles.forEach(file => {
      if (!!file.condition) {
        taskFiles.push(file);
      }
    }))

    const modalRef = this._modalService.open(ExportLoanDialogComponent, Constants.modalOptions.medium)
    modalRef.componentInstance.title = 'Select Document Format';
    modalRef.componentInstance.docFormats = this._docFormats;
    modalRef.componentInstance.selectedFormat = this.selectedDocumentFormatAtSystemLevel;
    modalRef.result.then((result) => {
      const selectedFormat = result;
      this.exportSelectedFiles(taskFiles, selectedFormat);
    }, (res) => {
    });
  }

  onFilesUpdatedFromTableCheckBox = (file: ExportLoanDocFiles) => {
    if (file.checked) {
      this.selectedFiles.push(file);
    } else {
      const existingOne = this.selectedFiles.find(f => f.guid === file.guid);
      if (existingOne) {
        const index = this.selectedFiles.indexOf(existingOne);
        this.selectedFiles.splice(index, 1);
      }
    }
    this.isAvailableExportButton(this.selectedFiles.length);
  }

  onFilesUpdatedFromHeaderCheckBox = (files: ExportLoanDocFiles[]) => {
    const filteredFiles = files.filter(file => file.checked);
    if (filteredFiles.length > 0) {
      if (this.selectedFiles.length > 0) {
        files.forEach(file => {
          const existingOne = this.selectedFiles.find(f => f.guid === file.guid);
          if (!existingOne) {
            this.selectedFiles.push(file);
          }
        });
      } else {
        this.selectedFiles = this.selectedFiles.concat(files);
      }
    } else {
      files.forEach(file => {
        const existingOne = this.selectedFiles.find(f => f.guid === file.guid);
        if (existingOne) {
          const index = this.selectedFiles.indexOf(existingOne);
          this.selectedFiles.splice(index, 1);
        }
      });
    };
    this.isAvailableExportButton(this.selectedFiles.length);
  }

  private initializeDocs = (loanId: number) => {
    this._spinner.show();
    this.applicationContextService.updateLoanInfo(loanId).subscribe(
      (context) => {
        this._spinner.hide();
        this.initialize(context);
      },
      (error) => {
        this._spinner.hide();
        this._notificationService.showError(
          error ? error.message : 'Unable update loan info.',
          'Error!'
        );
      }
    );
  }

  private isAvailableExportButton = (length: number): void => {
    if (length > 0) {
      this.hasCheckedExportDocs = true;
    } else {
      this.hasCheckedExportDocs = false;
    }
  }

  private exportSelectedFiles = (files: ExportLoanDocFiles[], selectedFormat: string) => {
    const fileGuids: string[] = files.map(file => file.guid);
    const data = new ExportDocFilesRequest(fileGuids, selectedFormat);
    this._spinner.show();
    this._exportFileService.exportDocFiles(this.filter.exportPdf, data).subscribe((response: any) => {
      const blob = new Blob([response.body]);
      let downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);

      let fileName = Utils.getFileNameFromContentDisposition(response.headers);
      if (!fileName) {
        fileName = this.filter.exportPdf ? "Download.pdf" : "Download.zip";
      }
      downloadLink.setAttribute('download', fileName);

      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.getExportDocFiles(this.currentApplication.applicationId);
      this.hasCheckedExportDocs = false;
      this._spinner.hide();
      this.selectedFiles = [];
    }, err => {
      this.getExportDocFiles(this.currentApplication.applicationId);
      this._spinner.hide();
      this._notifyService.showError(err ? err.error.error : "Error trying to export docs.", 'Failure!');
      this.selectedFiles = [];
    })
  }

  private filterDocTypesByStackingOrder = (): ExportFiles[] => {
    if (this.applicationContext.globalConfig.stackingOrderDocTypes) {
      const stackingOrder = this.applicationContext.globalConfig.stackingOrderDocTypes.find(x => x.stakingOrderId == this.selectedStackingOrderId);
      if (stackingOrder && stackingOrder.associate) {
        let associatedDocTypes = stackingOrder.associate.split(',');
        if (associatedDocTypes) {
          this.filteredDocumentTypes = [];
          associatedDocTypes.forEach(associatedDocTypeId => {
            let documentType = this.exportDocFiles.find(x => x.documentTypeId == Number(associatedDocTypeId));
            if (documentType && !this.filteredDocumentTypes.find(x => x.documentTypeId == documentType.documentTypeId)) {
              this.filteredDocumentTypes.push(documentType);
            }
          });
          return this.filteredDocumentTypes;
        }
      }
    }
    return this.exportDocFiles;
  }

  private getExportDocFiles = (appId: number) => {
    this.loadingExportingFiles = true;
    this._exportFileService.getExportDocFiles(appId).subscribe({
      next: (result) => {
        this.exportDocFiles = result;
        this.filteredDocumentTypes = this.filterDocTypesByStackingOrder();
    
        this.hasTaskFiles = result.some(docFile => docFile.docFiles.some(file => !file.condition));
        this.hasConditionFiles = result.some(docFile => docFile.docFiles.some(file => !!file.condition));
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load doc files", "Error");
      },
      complete: () => {
        this.loadingExportingFiles = false;
      }
    });
  }

  private getDocExportFormatValue = (companyId: number) => {
    this._loanDocsService.loadExportFormat().subscribe(result => {
      if (result) {
        this.selectedDocumentFormatAtSystemLevel = result;
      }
    })
  }

  private initialize = (context: ApplicationContext) => {
    this.isTpoUser = context.isTpo;
    this.globalConfig = context.globalConfig;
    this.currentApplication = context.application;
    this.selectedStackingOrderId = context.userPermissions.stackingOrderId ?? 0;

    this.filteredStackingOrders = this.globalConfig.stackingOrderDocTypes.filter(docType => !this.isTpoUser || docType.showOnTPOPortal);
    this._docFormats = this._enumsService.documentFormats;

    this.getExportDocFiles(this.currentApplication.applicationId);
    this.getDocExportFormatValue(context.userPermissions.companyId);

    if (this.isTpoUser) {
      this.userType = 'tpo';
      this.routerAppDetail = 'app-detail';
    } else {
      this.userType = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
        this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
    }
  }
}
