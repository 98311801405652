import { NgModule } from "@angular/core";
import { LdeInfoModule } from "src/app/modules/lde-info/lde.module";
import { LosInfoModule } from "src/app/modules/los-info/los.module";
import { SharedModule } from "src/app/shared/shared.module";
import { LosLdeSummaryComponent } from "./components/los-lde-summary/los-lde-summary.component";

@NgModule({
  imports: [
    SharedModule,
    LdeInfoModule,
    LosInfoModule
  ],
  declarations: [
    LosLdeSummaryComponent
  ],
  exports: [
    LosLdeSummaryComponent
  ],
  providers: [
  ]
})
export class LoanLosLdeModule {
  constructor() {
  }
}


