<div
  class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
  [ngClass]="{ 'sticky-nav-tabs-open-dialer': secondPageActionBarVisible }"
>
  <div class="button-items">
    <div class="float-end">
      <button
        type="button"
        class="btn nav nav-link me-2 d-inline"
        [print]="[
          'loan-summary-pdf',
          {
            printMode: 'template-popup',
            pageTitle: getPersonFullNames([borrowers[0]?.borrowerId])
          }
        ]"
      >
        Print as PDF
      </button>

      <button type="button" class="btn btn-primary" (click)="saveLoanInfo()">
        <i class="fas fa-save"></i>
        Save
      </button>
    </div>
  </div>
</div>

<div id="loan-summary-pdf">
  <form #loanForm="ngForm" novalidate id="loanForm" name="loanForm">
    <loan-borrowers
      [actionButtonsVisible]="true"
      [title]="'Homeowners'"
      (deleted)="onBorrowerDeleted($event)"
      (updated)="onBorrowerUpdated($event)"
      (updatedPrimaryBorrower)="onUpdatedPrimaryBorrower($event)">
    </loan-borrowers>

    <div class="card" id="referralInfo">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-user me-2"></i> Referral Info
          <button
            type="button"
            class="btn btn-outline-primary btn-sm pull-right"
            (click)="onAddHomeInsuranceDialog()"
          >
            Add Referral
          </button>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row py-1">
              <div class="col-6">Referral Source</div>
              <div class="col-6">
                <select-input-vscroll
                  [(ngModel)]="application.referralSource"
                  [optionValueKey]="'agentId'"
                  [optionNameKey]="'agentFullName'"
                  [options]="agents"
                  name="ls_referralSource"
                  [editorMode]="'Inline'"
                  [placeholder]="'Referral Source'"
                ></select-input-vscroll>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Referred To</div>
              <div class="col-6">
                <select-input-vscroll
                  [(ngModel)]="application.referredTo"
                  [optionValueKey]="'agentId'"
                  [optionNameKey]="'agentFullName'"
                  [options]="agents"
                  name="ls_referredTo"
                  [editorMode]="'Inline'"
                  [placeholder]="'Referred To'"
                ></select-input-vscroll>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Inside Referral Source</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="application.insideReferralSource"
                  [optionValueKey]="'userCompanyGuid'"
                  [optionNameKey]="'userFullName'"
                  [options]="users"
                  name="ls_insideReferralSource"
                  [editorMode]="'Inline'"
                  [placeholder]="'Inside Referral Source'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Secondary Referral Source</div>
              <div class="col-6">
                <select-input-vscroll
                  [(ngModel)]="application.secondaryReferralSource"
                  [optionValueKey]="'agentId'"
                  [optionNameKey]="'agentFullName'"
                  [options]="agents"
                  name="ls_secondaryReferralSource"
                  [editorMode]="'Inline'"
                  [placeholder]="'Secondary Referral Source'"
                ></select-input-vscroll>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row py-1">
              <div class="col-6">Lead Source</div>
              <div class="col-6">
                <text-input
                  [(ngModel)]="application.leadSource"
                  name="ls_leadSource"
                  [editorMode]="'Inline'"
                  [placeholder]="'Lead Source'"
                ></text-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Lead Campaign</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="application.leadCampaignId"
                  [optionValueKey]="'leadCampaignId'"
                  [optionNameKey]="'name'"
                  [options]="leadCampaigns"
                  name="ls_leadCampaign"
                  [editorMode]="'Inline'"
                  [placeholder]="'Lead Campaign'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Lead Ref Id</div>
              <div class="col-6">
                <text-input
                  [(ngModel)]="application.leadRefId"
                  name="ls_leadRefId"
                  [editorMode]="'Inline'"
                  [placeholder]="'Lead Ref Id'"
                ></text-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" id="heaAndPropertyInfo">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-file-contract me-2"></i> HEA & Property Info
          <button
            type="button"
            class="btn btn-outline-primary btn-sm pull-right"
          >
            Title
          </button>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row py-1">
              <div class="col-6">HEA Purpose</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.subjectProperty.purposeOfLoan"
                  [options]="loanPurposeOptions"
                  name="ls_loanPurpose"
                  [editorMode]="'Inline'"
                  [required]="true"
                  (blur)="onPurposeOfLoanChanged()"
                  [placeholder]="'Loan Purpose'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Property Occupancy</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.subjectProperty.propertyWillBe"
                  [options]="propertyOccupancyTypes"
                  name="ls_propertyOccupancy"
                  [editorMode]="'Inline'"
                  [required]="true"
                  [placeholder]="'Property Occupancy'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Property Type</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.subjectProperty.propertyType"
                  [options]="propertyTypes"
                  name="ls_propertyType"
                  [editorMode]="'Inline'"
                  [placeholder]="'Property Type'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">HEA FICO</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="ficoOnFile" class="fw-bold">{{
                    ficoOnFile
                  }}</span>
                  <span *ngIf="!ficoOnFile">--</span>
                </span>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">HEA Product</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="application.productPricing.productId"
                  (blur)="selectProduct(application.productPricing.productId)"
                  [optionValueKey]="'productId'"
                  [optionNameKey]="'productName'"
                  [options]="products"
                  name="ls_loanProduct"
                  [editorMode]="'Inline'"
                  [placeholder]="'Loan Product'"
                ></select-input>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">Channel</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="application.channel"
                  [optionValueKey]="'name'"
                  [options]="enabledChannels"
                  name="ls_channel"
                  [editorMode]="'Inline'"
                  [placeholder]="'Channel'"
                ></select-input>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">Branch</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="application.branchId"
                  [optionValueKey]="'branchId'"
                  [optionNameKey]="'branchName'"
                  [options]="externalCompanyBranches"
                  name="ls_branch"
                  [editorMode]="'Inline'"
                  [placeholder]="'Branch'"
                ></select-input>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">HEA Exchange Rate / ACL</div>
              <div class="col-6">
                <span class="d-flex justify-content-start align-items-center">
                  <i
                    *ngIf="
                      application &&
                      application.productPricing &&
                      application.productPricing.lockStatus == 'Accepted'
                    "
                    class="fas fa-lock me-1"
                    style="color: orange"
                  ></i>
                  <i
                    *ngIf="
                      application &&
                      application.productPricing &&
                      application.productPricing.lockStatus != 'Accepted'
                    "
                    class="fas fa-lock-open me-1"
                    style="color: orange"
                  ></i>
                  <i
                    *ngIf="application && !application.productPricing"
                    class="fas fa-lock-open me-1"
                    style="color: orange"
                  ></i>
                  <percent-input
                    class="d-inline-block"
                    [inlineTextClass]="'text-success'"
                    [(ngModel)]="application.productPricing.rate"
                    name="ls_interestRate"
                    [digitsInfo]="'1.3-3'"
                    [editorMode]="'Inline'"
                    [placeholder]="'Rate'"
                  ></percent-input>
                  <span class="mx-2">/</span>
                  <percent-input
                    class="d-inline-block"
                    [inlineTextClass]="'text-secondary'"
                    [(ngModel)]="application.productPricing.apr"
                    name="ls_apr"
                    [digitsInfo]="'1.3-3'"
                    [editorMode]="'Inline'"
                    [placeholder]="'ACL'"
                  ></percent-input>
                </span>
                <span
                  >Expires
                  <date-input
                    class="d-inline-block"
                    [(ngModel)]="application.productPricing.lockExpirationDate"
                    name="ls_lockExpirationDate"
                    [editorMode]="'Inline'"
                    [placeholder]="'Expiration Date'"
                  ></date-input
                ></span>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">DTI (front / back)</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0 fw-bold"
                >
                  <span
                    [ngClass]="{
                      'dti-danger': mortgageCalculationDetails?.dti?.frontEndDti > 47,
                      'dti-warning':
                        mortgageCalculationDetails?.dti?.frontEndDti > 43 &&
                        mortgageCalculationDetails?.dti?.frontEndDti <= 47,
                      'dti-success': mortgageCalculationDetails?.dti?.frontEndDti <= 43
                    }"
                  >
                    {{
                      mortgageCalculationDetails?.dti?.frontEndDti > 999
                        ? '--'
                        : (mortgageCalculationDetails?.dti?.frontEndDti || 0 | number : '1.0-2')
                    }}%
                  </span>
                  /
                  <span
                    [ngClass]="{
                      'dti-danger': mortgageCalculationDetails?.dti?.backEndDti > 47,
                      'dti-warning':
                        mortgageCalculationDetails?.dti?.backEndDti > 43 &&
                        mortgageCalculationDetails?.dti?.backEndDti <= 47,
                      'dti-success': mortgageCalculationDetails?.dti?.backEndDti <= 43
                    }"
                  >
                    {{
                      mortgageCalculationDetails?.dti?.backEndDti > 999
                        ? '--'
                        : (mortgageCalculationDetails?.dti?.backEndDti || 0 | number : '1.0-2')
                    }}%
                  </span>
                </span>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6 d-block">
                <div class="d-flex justify-content-between align-items-center">
                  <label>LTV</label>
                  <percent-input
                    [(ngModel)]="application.ltv"
                    name="ls_ltv"
                    [editorMode]="'Inline'"
                    (blur)="onLtvValueChanged()"
                    [placeholder]="'LTV'"
                  ></percent-input>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    [style.width.%]="(application.ltv || 0) * 100"
                    aria-valuenow="{{ (application.ltv || 0) * 100 }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6 d-block">
                <div class="d-flex justify-content-between align-items-center">
                  <label>CLTV</label>
                  <span
                    class="form-control border-0 bg-transparent display-inline px-0 text-end"
                  >
                    <span *ngIf="application.cltv" class="fw-bold">{{
                      application.cltv | percent : '1.2-2'
                    }}</span>
                    <span *ngIf="!application.cltv">--</span>
                  </span>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    [style.width.%]="(application.cltv || 0) * 100"
                    aria-valuenow="{{ (application.cltv || 0) * 100 }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row py-1">
              <div class="col-6">Monthly Debt</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0 text-warning"
                >
                  <span *ngIf="totalMonthlyDebts" class="fw-bold">{{
                    totalMonthlyDebts | currency
                  }}</span>
                  <span *ngIf="!totalMonthlyDebts">--</span>
                </span>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">Unpaid Balance</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="unPaidBalanceTotal" class="fw-bold">{{
                    unPaidBalanceTotal | currency
                  }}</span>
                  <span *ngIf="!unPaidBalanceTotal">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Monthly Income</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0 text-success"
                >
                  <span *ngIf="incomeTotal" class="fw-bold">{{
                    incomeTotal | currency
                  }}</span>
                  <span *ngIf="!incomeTotal">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Current Housing</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="currentHousingExpenseTotal" class="fw-bold">{{
                    currentHousingExpenseTotal | currency
                  }}</span>
                  <span *ngIf="!currentHousingExpenseTotal">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Total Monthly Payments</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="housingPaymentsTotal" class="fw-bold">{{
                    housingPaymentsTotal | currency
                  }}</span>
                  <span *ngIf="!housingPaymentsTotal">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Proposed Housing</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="proposedHousingExpenseTotal" class="fw-bold">{{
                    proposedHousingExpenseTotal | currency
                  }}</span>
                  <span *ngIf="!proposedHousingExpenseTotal">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Total Monthly PITI</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="totalPiTi" class="fw-bold">{{
                    totalPiTi | currency
                  }}</span>
                  <span *ngIf="!totalPiTi">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Total Assets Value</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="assetTotal" class="fw-bold">{{
                    assetTotal | currency
                  }}</span>
                  <span *ngIf="!assetTotal">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Cash from/To Borrower</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0 text-danger"
                >
                  <span *ngIf="ftcSectionAmounts['allTotal']" class="fw-bold">{{
                    ftcSectionAmounts['allTotal'] | currency
                  }}</span>
                  <span *ngIf="!ftcSectionAmounts['allTotal']">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Month Reserves</div>
              <div class="col-6">
                <number-input
                  [allowNegative]="false"
                  [(ngModel)]="mortgage.transactionDetail.reserves"
                  name="ls_monthReserves"
                  [editorMode]="'Inline'"
                  [placeholder]="'Month Reserves'"
                >
                </number-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" id="heaInfo">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-file-contract me-2"></i> HEA Info
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row py-1">
              <div class="col-6">Property Purchase Price</div>
              <div class="col-6">
                <currency-input
                  [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount"
                  (blur)="calculateTotalDue(); onPurchasePriceAmountChanged()"
                  name="ls_propertyPurchasePrice"
                  [editorMode]="'Inline'"
                  [placeholder]="'Property Purchase Price'"
                ></currency-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Appraised Property Value</div>
              <div class="col-6">
                <currency-input
                  [(ngModel)]="mortgage.subjectProperty.presentValue"
                  name="ls_appraisedPropertyValue"
                  [editorMode]="'Inline'"
                  [required]="true"
                  (blur)="onAppraisedPropertyValueChanged()"
                  [placeholder]="'Appraised Property Value'"
                ></currency-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Base HEA Amount</div>
              <div class="col-6">
                <currency-input
                  [(ngModel)]="mortgage.mortgageTerm.amount"
                  name="ls_baseLoanAmount"
                  [editorMode]="'Inline'"
                  (blur)="onBaseLoanAmountChanged()"
                  [placeholder]="'Base HEA Amount'"
                ></currency-input>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">Subordinate Lien Amount</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span
                    *ngIf="mortgage.transactionDetail.subordinateLienAmount"
                    class="fw-bold"
                    >{{
                      mortgage.transactionDetail.subordinateLienAmount
                        | currency
                    }}</span
                  >
                  <span
                    *ngIf="!mortgage.transactionDetail.subordinateLienAmount"
                    >--</span
                  >
                </span>
              </div>
            </div>

            <div class="row py-1">
              <div class="col-6">Lien Position</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.mortgageTerm.lienPosition"
                  [options]="lienPositionTypes"
                  name="ls_lienPosition"
                  [editorMode]="'Inline'"
                  [required]="true"
                  [placeholder]="'Lien Position'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Total Loan Amount</div>
              <div class="col-6">
                <currency-input
                  [(ngModel)]="mortgage.mortgageTerm.totalLoanAmount"
                  name="ls_totalLoanAmount"
                  [editorMode]="'Inline'"
                  [placeholder]="'Total Loan Amount'"

                ></currency-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Down Payment</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="totalDownPayment" class="fw-bold">{{
                    totalDownPayment | currency
                  }}</span>
                  <span *ngIf="!totalDownPayment">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Down Payment Sources</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span *ngIf="downPaymentSources" class="fw-bold">{{
                    downPaymentSources
                  }}</span>
                  <span *ngIf="!downPaymentSources">--</span>
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Amortization Type</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.mortgageTerm.amortizationType"
                  [options]="amortizationTypes"
                  name="ls_amortizationType"
                  [editorMode]="'Inline'"
                  [required]="true"
                  [placeholder]="'Amortization Type'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Term (Months)</div>
              <div class="col-6">
                <number-input
                  [allowNegative]="false"
                  [(ngModel)]="mortgage.mortgageTerm.noOfMonths"
                  name="ls_noOfMonths"
                  [editorMode]="'Inline'"
                  (blur)="onMortgageTermChanged()"
                  [placeholder]="'Term (Months)'"
                ></number-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">HEA Type</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.mortgageTerm.mortgageAppliedFor"
                  [options]="mortgageAppliedForTypes"
                  name="ls_mortgageAppliedFor"
                  [editorMode]="'Inline'"
                  [required]="true"
                  (blur)="productTypeChanged()"
                  [placeholder]="'HEA Type'"
                ></select-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row py-1">
              <div class="col-6">Interest Rate</div>
              <div class="col-6">
                <percent-input
                  [(ngModel)]="mortgage.mortgageTerm.interestRate"
                  name="ls_mortgageInterestRate"
                  [editorMode]="'Inline'"
                  (blur)="onInterestRateChanged()"
                  [placeholder]="'Interest Rate'"
                ></percent-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Qualifying Rate</div>
              <div class="col-6">
                <percent-input
                  [(ngModel)]="mortgage.mortgageTerm.qualifyingRate"
                  name="ls_qualifyingRate"
                  [editorMode]="'Inline'"
                  [required]="true"
                  [placeholder]="'Qualifying Rate'"
                ></percent-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Interest Only (Months)</div>
              <div class="col-6">
                <number-input
                  [allowNegative]="false"
                  [(ngModel)]="mortgage.extension.interestOnlyTermMonths"
                  name="ls_interestOnlyTermMonths"
                  [editorMode]="'Inline'"
                  [placeholder]="'Interest Only (Months)'"
                ></number-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Initial Fixed Period (Months)</div>
              <div class="col-6">
                <number-input
                  [allowNegative]="false"
                  [(ngModel)]="
                    mortgage.subjectProperty.constructionPeriodMonths
                  "
                  name="ls_constructionPeriodMonths"
                  [editorMode]="'Inline'"
                  [placeholder]="'Initial Fixed Period (Months)'"
                ></number-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">ARM Margin</div>
              <div class="col-6">
                <number-input
                  [(ngModel)]="mortgage.ausData.armMarginRate"
                  name="ls_armMarginRate"
                  [editorMode]="'Inline'"
                  [placeholder]="'ARM Margin'"
                ></number-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">ARM Index</div>
              <div class="col-6">
                <number-input
                  [(ngModel)]="mortgage.ausData.armIndexCurrent"
                  name="ls_armIndexCurrent"
                  [editorMode]="'Inline'"
                  [placeholder]="'ARM Index'"
                ></number-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">MI Rate</div>
              <div class="col-6">
                <percent-input
                  [(ngModel)]="
                    mortgage.governmentLoanDetail.fhaMIRenewalRatePercent
                  "
                  name="ls_miRate"
                  [digitsInfo]="'1.3-3'"
                  [editorMode]="'Inline'"
                  [placeholder]="'MI Rate'"
                ></percent-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Monthly MI</div>
              <div class="col-6">
                <span
                  class="form-control border-0 bg-transparent display-inline px-0"
                >
                  <span
                    *ngIf="mortgage.proposedHousingExpense.mortgageInsurance"
                    class="fw-bold"
                    >{{
                      mortgage.proposedHousingExpense.mortgageInsurance
                        | currency
                    }}</span
                  >
                  <span
                    *ngIf="!mortgage.proposedHousingExpense.mortgageInsurance"
                    >--</span
                  >
                </span>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Document Type</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.subjectProperty.refinanceProgram"
                  [options]="governmentRefinanceTypes"
                  name="ls_governmentRefinanceTypes"
                  [editorMode]="'Inline'"
                  [required]="true"
                  [placeholder]="'Document Type'"
                ></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Prepay Penalty</div>
              <div class="col-6">
                <select-input
                  [(ngModel)]="mortgage.extension.isPenaltyChargedInEventOfPrepayment"
                  [options]="yesNoOptions"
                  name="ls_isPenaltyChargedInEventOfPrepayment"
                  [editorMode]="'Inline'"
                  [placeholder]="'Prepay Penalty'"
                ></select-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" id="creditInfo">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-users me-2"></i> Credit Info
        </h4>
      </div>
      <div class="card-body">
        <div class="row" #creditInfoDetails>
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th style="width: 40%" class="text-secondary">Name</th>
                <th class="text-secondary">Experian</th>
                <th class="text-secondary">Trans Union</th>
                <th class="text-secondary">Equifax</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let borrower of borrowers">
                <td>{{ getPersonFullNames([borrower.borrowerId]) }}</td>
                <td>{{ borrower.creditScore_Experian || '--' }}</td>
                <td>{{ borrower.creditScore_TransUnion || '--' }}</td>
                <td>{{ borrower.creditScore_Equifax || '--' }}</td>
              </tr>
              <tr *ngIf="borrowers.length == 0">
                <td colspan="4" style="height: 50px; text-align: center">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row" id="propertyInfo">
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title">
              <i class="fas fa-map-marked me-2"></i> Property Info
              <button
                type="button"
                class="btn btn-outline-primary btn-sm pull-right"
              >
                AVM
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div
              class="w-100 mt-2"
              style="height: 50% !important"
              #mapElementContainer
            >
              <div
                *ngIf="!mortgage.subjectProperty.address1 || haveMapError"
                class="h-100 w-100 bg-info d-flex align-items-center justify-content-center text-white fw-bold"
              >
                {{ mortgage.subjectProperty.address1 || 'No Address Selected' }}
              </div>

              <div
                id="propertyAddressMap{{ mapId }}"
                #mapElement
                style="width: 100%; height: 100%"
                [hidden]="!mortgage.subjectProperty.address1 || haveMapError"
              ></div>
            </div>

            <div class="row" style="height: 50% !important">
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Address</div>
                  <div class="col-6">
                    <address-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.address1"
                      name="ls_address"
                      [editorMode]="'Inline'"
                      (addressChanged)="onHandleAddressChange($event)"
                      [placeholder]="'Address'"
                    ></address-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">City</div>
                  <div class="col-6">
                    <text-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.city"
                      name="ls_city"
                      [editorMode]="'Inline'"
                      [placeholder]="'City'"
                    ></text-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">State</div>
                  <div class="col-6">
                    <select-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.state"
                      [options]="states"
                      name="ls_state"
                      [editorMode]="'Inline'"
                      [placeholder]="'State'"
                    ></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Zip</div>
                  <div class="col-6">
                    <zip-code-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.zipCode"
                      [name]="'ls_zipCode'"
                      [editorMode]="'Inline'"
                      (selectionChanged)="onZipCodeRelatedInfoChanged($event)"
                      [inlinePlaceholder]="'Zip'"
                    >
                    </zip-code-input>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Property Type</div>
                  <div class="col-6">
                    <select-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.propertyType"
                      [options]="propertyTypes"
                      name="ls_propertyType"
                      [editorMode]="'Inline'"
                      [placeholder]="'Property Type'"
                    ></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Attachment Type</div>
                  <div class="col-6">
                    <select-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.attachmentType"
                      [options]="attachmentTypes"
                      name="ls_attachmentType"
                      [editorMode]="'Inline'"
                      [placeholder]="'Attachment Type'"
                    ></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Occupancy</div>
                  <div class="col-6">
                    <select-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.propertyWillBe"
                      [options]="propertyOccupancyTypes"
                      name="ls_propertyOccupancy"
                      [editorMode]="'Inline'"
                      [placeholder]="'Occupancy'"
                    ></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Number Of Units</div>
                  <div class="col-6">
                    <number-input
                      [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="mortgage.subjectProperty.noOfUnits"
                      name="ls_noOfUnits"
                      [editorMode]="'Inline'"
                      [required]="true"
                      [placeholder]="'Number Of Units'"
                      [min]="1"
                      [max]="10"
                    ></number-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title">
              <i class="fas fa-hand-holding-usd me-2"></i> Housing Expenses
            </h4>
          </div>
          <div class="card-body">
            <div class="row" #housingExpenses>
              <table class="table table-hover table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th class="text-secondary">Expense</th>
                    <th class="text-secondary" style="text-align: right">
                      Current
                    </th>
                    <th class="text-secondary" style="text-align: right">
                      Proposed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Rent</td>
                    <td style="text-align: right">
                      {{ (currentHousingExpense?.rent | currency) || '--' }}
                    </td>
                    <td style="text-align: right">
                      {{ (proposedHousingExpense?.rent | currency) || '--' }}
                    </td>
                  </tr>
                  <tr>
                    <td>First Mortgage P&I</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.firstMortgagePrincipalAndInterest
                          | currency) || '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.firstMortgagePrincipalAndInterest
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Other Financing P&I</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.otherMortgageLoanPrincipalAndInterest
                          | currency) || '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.otherMortgageLoanPrincipalAndInterest
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Homeowners Insurance</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.homeownersInsurance
                          | currency) || '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.homeownersInsurance
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Supplemental Property Insurance</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.supplementalPropertyInsurance
                          | currency) || '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.supplementalPropertyInsurance
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Property Taxes</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.realEstateTax | currency) ||
                          '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.realEstateTax | currency) ||
                          '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Mortagage Insurance</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.mortgageInsurance | currency) ||
                          '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.mortgageInsurance
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>HOA Dues</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.homeownersAssociationDuesAndCondominiumFees
                          | currency) || '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.homeownersAssociationDuesAndCondominiumFees
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Other</td>
                    <td style="text-align: right">
                      {{
                        (currentHousingExpense?.otherHousingExpense
                          | currency) || '--'
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        (proposedHousingExpense?.otherHousingExpense
                          | currency) || '--'
                      }}
                    </td>
                  </tr>
                  <tr style="font-size: larger">
                    <td style="font-weight: bold">Total</td>
                    <td style="text-align: right; font-weight: bold">
                      {{ (currentHousingExpenseTotal | currency) || '--' }}
                    </td>
                    <td style="text-align: right; font-weight: bold">
                      {{ (proposedHousingExpenseTotal | currency) || '--' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" id="totalQualifiedMonthly">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-hand-holding-usd me-2"></i> Total Qualified Monthly
          Income:
          <span class="text-success">{{
            (incomeTotal | currency) || '--'
          }}</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th style="width: 30%" class="text-secondary">Borrower</th>
                <th class="text-secondary" style="width: 15%">Income Source</th>
                <th class="text-secondary" style="width: 5%; text-align: right">
                  2022
                </th>
                <th class="text-secondary" style="width: 5%; text-align: right">
                  2021
                </th>
                <th class="text-secondary" style="width: 5%; text-align: right">
                  2020
                </th>
                <th class="text-secondary" style="width: 5%; text-align: right">
                  2019
                </th>
                <th
                  class="text-secondary"
                  style="width: 15%; text-align: right"
                >
                  Monthly Income
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let income of incomes">
                <td>{{ getPersonalFullNameByEmployment(income.borrowerId, income.employmentId) }}</td>
                <td>
                  {{ getWords(income.typeOfIncome) }}
                  <i class="fa fa-parking"></i>
                </td>
                <td style="text-align: right">$0</td>
                <td style="text-align: right">$0</td>
                <td style="text-align: right">$0</td>
                <td style="text-align: right">$0</td>
                <td style="text-align: right">
                  {{ (income.monthlyIncome | currency) || '--' }}
                </td>
              </tr>
              <tr *ngIf="incomes.length == 0">
                <td colspan="7" style="height: 50px; text-align: center">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card" id="totalMonthlyDebt">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-hand-holding-usd me-2"></i> Total Monthly Debt:
          <span class="text-warning">{{
            (liabilityTotal | currency) || '--'
          }}</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th class="text-secondary">Associated To</th>
                <th class="text-secondary">Debt Type</th>
                <th class="text-secondary">Creditor</th>
                <th class="text-secondary" style="text-align: right">
                  Payment
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let liability of mortgage.liabilities">
                <td>{{ getPersonFullNames(liability.owningBorrowerIds) }}</td>
                <td>
                  {{ getWords(liability.typeOfLiability) }}
                  <i class="fa fa-parking"></i>
                </td>
                <td>{{ liability.holderName }}</td>
                <td style="text-align: right">
                  {{ (liability.monthlyPayment | currency) || '--' }}
                </td>
              </tr>
              <tr *ngIf="mortgage.liabilities.length == 0">
                <td colspan="4" style="height: 50px; text-align: center">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card" id="totalAssets">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-hand-holding-usd me-2"></i> Total Assets:
          <span class="text-success">{{
            (assetTotal | currency) || '--'
          }}</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th class="text-secondary">Associated To</th>
                <th class="text-secondary">Account Type</th>
                <th class="text-secondary">Bank / Institution</th>
                <th class="text-secondary" style="text-align: right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let asset of mortgage.assets">
                <td>{{ getPersonFullNames(asset.owningBorrowerIds) }}</td>
                <td>{{ getWords(asset.assetType) }}</td>
                <td>{{ getWords(asset.depository) }}</td>
                <td style="text-align: right">
                  {{ (asset.cashMarketValue | currency) || '--' }}
                </td>
              </tr>
              <tr *ngIf="mortgage.assets.length == 0">
                <td colspan="4" style="height: 50px; text-align: center">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card" id="realEstateOwned">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-hand-holding-usd me-2"></i> Real Estate Owned:
          <span class="text-success">{{ (reoTotal | currency) || '--' }}</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th class="text-secondary">Owned By</th>
                <th class="text-secondary">Property Status</th>
                <th class="text-secondary">Property Usage</th>
                <th class="text-secondary" style="text-align: right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let reo of mortgage.realEstateOwned">
                <td>{{ getPersonFullNames(reo.owningBorrowerIds) }}</td>
                <td>{{ getWords(reo.dispositionStatus) }}</td>
                <td>{{ getWords(reo.intendedUsageType) }}</td>
                <td style="text-align: right">
                  {{ (reo.marketValue | currency) || '--' }}
                </td>
              </tr>
              <tr *ngIf="mortgage.realEstateOwned.length == 0">
                <td colspan="4" style="height: 50px; text-align: center">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card" id="purchaseCredits">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-hand-holding-usd me-2"></i> Purchase Credits:
          <span class="text-success">{{
            (purchaseCreditsTotal | currency) || '--'
          }}</span>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th class="text-secondary">Account Type</th>
                <th class="text-secondary" style="text-align: right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let credit of mortgage.transactionDetail.purchaseCredits
                "
              >
                <td>{{ getWords(credit.purchaseCreditType) }}</td>
                <td style="text-align: right">
                  {{ (credit?.purchaseCreditAmount | currency) || '--' }}
                </td>
              </tr>
              <tr
                *ngIf="mortgage.transactionDetail?.purchaseCredits.length == 0"
              >
                <td colspan="4" style="height: 50px; text-align: center">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card" id="transactionDetails">
      <div class="card-header">
        <h4 class="card-title">
          <i class="fas fa-hand-holding-usd me-2"></i> Transaction Details
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <table class="table table-hover table-borderless">
            <thead>
              <tr class="border-bottom">
                <th class="text-secondary">Description</th>
                <th class="text-secondary" style="text-align: right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Purchase Price</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['A'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Alterations, Improvements, Repairs</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['B'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Land (if acquired separately)</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['C'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Refinance (inc debts to be paid)</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['D'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Estimated Prepaid Items</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['F']?.estimated | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Estimated Closing Costs</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['F']?.total | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>PMI, MIP, Funding Fee</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['F']?.pmi | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Discount (if borrower will pay)</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['G'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Total Costs</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['N'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Subordinate Financing</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['M']?.lender | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Borrower's Closing Costs Paid By Seller</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['L'] | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Total other credits</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['M']?.total | currency) || '--' }}
                </td>
              </tr>
              <tr>
                <td>Loan Amount (exclude PMI, MIP, Funding Fee Financed)</td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    (ftcSectionAmounts['I']?.nonFinancedAmount | currency) ||
                      '--'
                  }}
                </td>
              </tr>
              <tr>
                <td>PMI, MIP, Funding Fee Financed</td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    (ftcSectionAmounts['I']?.financedAmount | currency) || '--'
                  }}
                </td>
              </tr>
              <tr>
                <td>Total Loan Amount</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['I']?.total | currency) || '--' }}
                </td>
              </tr>
              <tr style="font-size: larger">
                <td style="font-weight: bold">Cash from/to Borrower</td>
                <td style="text-align: right; font-weight: bold">
                  {{ (ftcSectionAmounts['allTotal'] | currency) || '--' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card" id="customInfo">
      <div class="card-header">
        <h4 class="card-title"><i class="fas fa-key me-2"></i> Custom Info</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <custom-data-info
            [application]="application"
            [customData]="customData"
            [isEdit]="false"
            [labelAlignment]="'left'"
          >
          </custom-data-info>
        </div>
      </div>
    </div>
  </form>
</div>
