import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { Profile } from "src/app/models/profile.model";
import { GlobalEmailTemplateModel } from "src/app/models/user/global-email-template.model";
import { StateLicensing } from "src/app/models/user/state-licensing.model";
import { UserProfile } from "src/app/models/user/user-profile.model";
import { UserVoicemail } from "src/app/models/user/user-voicemail.model";
import { LeadRouteUser } from "../admin/lead-config/models/lead-route-user.model";
import { UserStateSetting } from "../admin/lead-config/models/lead-routes-agents.model";
import { PrequalGenerationSettings } from "../prequal-letter-settings/models";
import { SendSmsRequest } from "src/app/models/send-sms-request.model";
import { DashboardConfig } from "../dashboardv2/models/dashboard-config.model";

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private _dataService: DataService) { }
    currentUserProfile: Profile;

    setLoggedInUserProfile(profile: Profile) {
        this.currentUserProfile = profile;
    }

    getLoggedInUserProfile() {
        return this.currentUserProfile;
    }

    getUserProfile(userGuid: string): Observable<Profile> {
        return this._dataService.get(`api/Admin/Profile/${userGuid}`);
    }

    sendTestSMS(request: Partial<SendSmsRequest>): Observable<void> {
        return this._dataService.post('api/Sms/SendTestSms', request);
    }

    sendEmailTest(profile: Partial<UserProfile>): Observable<void> {
        return this._dataService.post('api/Email/SendTestEmail', profile);
    }

    public sendTestEmailForThirdParty = (data, serviceType: string, fromEmail: string): Observable<void> => {
        let url = `api/Email/SendTestEmailViaThirdPartyCredentials?EmailServiceType=${serviceType}`;
        if (fromEmail) {
            url += `&from=${encodeURIComponent(fromEmail)}`;
        }
        return this._dataService.post(url, data);
    };

    saveUserProfile(profile: Profile): Observable<any> {
        return this._dataService.post('api/Admin/SaveProfile', profile);
    }
    updatePhoneNumber(phone, areaCode) {
        return this._dataService.post(`api/Auth/UpdatePhone?phone=${phone}&areaCode=${areaCode}`, {});
    }
    deletePhoneNumber() {
        return this._dataService.post(`api/Auth/DeleteTwoFactorPhone`, {});
    }
    updateTwoFactor(value) {
        return this._dataService.post(`api/Auth/UpdateTwoFactor?twoFactor=${value}`, {});
    }

    public autoDiscoverExchangeUrl = (data): Observable<string> => {
        return this._dataService.post(`api/Email/AutoDiscoverExchangeUrl`, data);
    }

    loadDefaultEmailTemplates = (): Observable<GlobalEmailTemplateModel[]> => {
        return this._dataService.get(`api/Admin/AllGlobalEmailTemplates`);
    }

    getUserVoicemails = (userId: string): Observable<UserVoicemail[]> => {
        return this._dataService.get(`api/Admin/${userId}/user-voicemail`);
    }

    getUserVoicemailById = (userVoicemailId: number): Observable<UserVoicemail> => {
        return this._dataService.get(`api/Admin/user-voicemail/${userVoicemailId}`);
    }

    updateUserVoicemail = (userVoicemailId: number, formData: FormData): Observable<UserVoicemail> => {
        return this._dataService.postFormData(`api/Admin/user-voicemail/${userVoicemailId}`, formData);
    }

    addNewUserVoicemail = (formData: FormData): Observable<UserVoicemail> => {
        return this._dataService.postFormData(`api/Admin/user-voicemail`, formData);
    }

    deleteUserVoicemail = (userVoicemailId: number): Observable<void> => {
        return this._dataService.delete(`api/Admin/user-voicemail/${userVoicemailId}`);
    }

    getUserVoicemailFile = (userVoicemailId: number): Observable<BlobPart> => {
        return this._dataService.downloadFile(`api/Admin/user-voicemail/${userVoicemailId}/data`);
    }

    getPhoneNumbersList = (areaCode: string, isTollFree: boolean): Observable<any> => {
        return this._dataService.get(`api/voice/get-available-phone-numbers/countrycode/US/areacode/${areaCode}?istollfree=${isTollFree}`);
    }

    getAvailableDashboardsForUser = (userCompanyGuid: string): Observable<DashboardConfig[]> => {
        return this._dataService.get(`api/admin/dashboards/all/for-user/${userCompanyGuid}`);
    }

    getMessagingServiceList = (): Observable<any> => {
        return this._dataService.get(`api/voice/get-messaging-services`);
    }

    getStateLicensing = (userCompanyGuid: string): Observable<StateLicensing[]> => {
        return this._dataService.get(`api/admin/${userCompanyGuid}/user-licensing`);
    }

    insertStateLicensing = (request: StateLicensing): Observable<StateLicensing> => {
        return this._dataService.post(`api/admin/user-licensing`, request);
    }

    updateStateLicensing = (request: StateLicensing): Observable<StateLicensing> => {
        return this._dataService.post(`api/admin/user-licensing/${request.userLicenseId}`, request);
    }

    deleteStateLicensing = (userLicenseId: number): Observable<void> => {
        return this._dataService.delete(`api/admin/user-licensing/${userLicenseId}`);
    }

    updateLeadRouteUserInfo = (user: LeadRouteUser): Observable<LeadRouteUser> => {
        return this._dataService.post(`api/leadroutes/users/${user.leadRouteUserId}`, user);
    }

    insertUserStateSetting = (request: UserStateSetting): Observable<UserStateSetting> => {
        return this._dataService.post('api/leadroutes/userstatesettings', request);
    }

    updateUserStateSetting = (request: UserStateSetting): Observable<UserStateSetting> => {
        return this._dataService.post(`api/leadroutes/userstatesettings/${request.leadRouteUserStateSettingsId}`, request);
    }

    deleteUserStateSetting = (stateSettingsId: number): Observable<void> => {
        return this._dataService.delete(`api/leadroutes/userstatesettings/${stateSettingsId}`);
    }

    getUserPrequalGenerationSettings = (userCompanyGuid: string) => {
      return this._dataService.get(`api/admin/users/${userCompanyGuid}/prequal-gen-settings`);
    }

    upsertUserPrequalGenerationSettings = (userCompanyGuid: string, settings: PrequalGenerationSettings): Observable<PrequalGenerationSettings> => {
      return this._dataService.post(`api/admin/users/${userCompanyGuid}/prequal-gen-settings`, settings);
    }
}
