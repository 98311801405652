import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { SubStatus } from 'src/app/models';

@Injectable()
export class SubStatusService {
  constructor(private readonly _dataService: DataService) {}

  getAllSubStatuses(companyId: number): Observable<Array<SubStatus>> {
    return this._dataService.get(
      `api/Admin/AllSubStatusModel?companyId=${companyId}`
    );
  }

  insertSubStatus(companyId: number, data: SubStatus): Observable<SubStatus> {
    return this._dataService.post(
      `api/Admin/InsertSubStatusModel?companyId=${companyId}`,
      data
    );
  }

  updateSubStatus(companyId: number, data: SubStatus): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateSubStatusModel?companyId=${companyId}`,
      data
    );
  }

  updateSubStatusOrder(
    sortedIds: Array<{ id: number }>,
    companyId: number
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateSubStatusOrder?companyId=${companyId}`,
      sortedIds
    );
  }

  deleteSubStatus(
    subStatusId: number,
    companyId: number,
    assignedSubStatusId?: number
  ): Observable<undefined> {
    let url = `api/Admin/DeleteSubStatusModel/${subStatusId}?companyId=${companyId}`;

    if (assignedSubStatusId) {
      url += `&replaceWithSubStatusId=${assignedSubStatusId}`;
    }

    return this._dataService.delete(url);
  }
}
