export class LosDisclosureGenJob {
  losLoanId: string;
  lockId: string;
  generationOptions: LosDisclosureGenOptions;
  sendOptions: LosDisclosureSendOptions;
  jobStatus: LosDisclosureJobStatus;
  errors: string;
  disclosureInfos: LosDisclosureInfo[];
}

export class LosDisclosureGenOptions {
  disclosureType: LosDisclosureType;
  storePackageDoc: boolean;
  packageDocType: string;
  packageFileNamePattern: string;
  losLoanId: string;
}

export class LosDisclosureSendOptions {
  defaultRecipientEmail: string;
  defaultSenderName: string;
  defaultSenderEmail: string;
  brokerMessageTemplate: string;
  isFulfillmentEnabled: boolean;
  daysUntilFulfillmentTriggers: number;
}

export class GenerateBackgroundDisclosuresRequest {
  options:LosDisclosureGenOptions;
  job:LosDisclosureGenJob;
}

export enum LosDisclosureJobStatus {
  Pending = "Pending",
  Retry = "Retry",
  Completed = "Completed",
  Failed = "Failed"
}

export enum LosDisclosureType {
  Initial = "Initial",
  Closing = "Closing"
}

export class LosDisclosureInfo {
  status: LosDisclosureStatus;
  errors: string;
  description: string;
  metadata: LosDisclosureInfoMetadata;
  externPackageAuditId: string;
  externPackageId: string;
  documents: LosGeneratedDocument[];
  parties: LosParty[];
  failedDocs: string[];
  packageDocumentId: string;
  packageAttachmentId: string;
  disclosureTrackingId: string;
  dataAuditRuleMessages: DisclosureFieldValidationResults[];
  planCodeAuditMessages: string[];
}

export class LosDisclosureInfoMetadata {
  borrowerPairId: string;
  legacyBorrowerPairId: string;
  loanOfficerEmail: string;
  borrowerEmail: string;
  coBorrowerEmail: string;
  borrowerNames: string;
  auditId: string;
  documentSetId: string;
  documentSet: string;
  sendPackageId: string;
}

export enum LosDisclosureStatus {
  NotStarted = "NotStarted", AuditPending = "AuditPending",
  AuditCompleted = "AuditCompleted", DocGenPending = "DocGenPending",
  DocGenCompleted = "DocGenCompleted", DocsSaved = "DocsSaved", Failed = "Failed",
  PackageSent = "PackageSent", PackageDelivered = "PackageDelivered", Completed = "Completed"
}

export class LosGeneratedDocument {
  name: string;
  fileName: string;
  downloadUrl: string;
  authorization: string;
  signatureType: string;
  formType: string;
  downloadedContent: string; // byte
  trackedDocumentId: string;
}

export class DisclosureFieldValidationResults {
  losFieldId: string;
  validationMessages: string[];
  severity: string;
  fieldDescription: string;
  dataType: string;
  dataFormat: string;
  maxLength: number; // byte
  nullable?: boolean;
  readOnly?: boolean;
  valueOptions?: ValueOptions[];
}

export class ValueOptions {
  key: string;
  value: string;
}

export class LosParty {
  partyId: string;
  partyType: string;
  fullName: string;
  emailAddress: string;
  secondaryEmailAddress: string;
  homePhoneNumber: string;
  businessPhoneNumber: string;
  cellPhoneNumber: string;
}

