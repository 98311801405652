<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary" [disabled]="superAdmin" (click)="addNewLender()">
    <i class="fas fa-plus"></i> Add New
  </button>
</ng-template>

<ng-template #mainContent>
  <p-table #dt1 [columns]="selectedColumns" [value]="lenders" [paginator]="true" [rows]="25" responsiveLayout="scroll"
    name="lender-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="globalFilterFields"
    [autoLayout]="true" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" styleClass="p-datatable-gridlines"
    sortMode="single" [sortField]="defaultSortingField" scrollable="true" [scrollHeight]="scrollHeight">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">
        <span class="ms-auto">
          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl
            defaultLabel="Choose Columns"></p-multiSelect>

          <span class="p-input-icon-right ms-2 h-100">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0" placeholder="Search keyword" />
          </span>
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 7rem; text-align: center;">
          <span>Action</span>
        </th>
        <th *ngFor="let col of getSortedColumns()" [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="col.field === defaultSortingField">
          <span>
            <a *ngIf="!superAdmin && col.field == 'sortOrder'; else colHeaderText" class="text-primary"
              (click)="openOrderDrawer()">{{ col.header }}</a>
            <ng-template #colHeaderText>
              <span>{{ col.header }}</span>
            </ng-template>
          </span>
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center w-100">
            <span>
              <i class="fa fa-pencil-alt cursor text-primary" (click)="onEditLenderClicked(rowData)" title="Edit"></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i *ngIf="!isDeleteClicked[rowData.lenderId]" class="fa fa-trash-alt cursor text-danger"
                (click)="isDeleteClicked[rowData.lenderId] = true" title="Delete"></i>

              <i *ngIf="isDeleteClicked[rowData.lenderId]" class="fa fa-times cursor text-danger me-2"
                (click)="isDeleteClicked[rowData.lenderId] = false" title="Cancel"></i>

              <i *ngIf="isDeleteClicked[rowData.lenderId]" class="fa fa-check-circle cursor text-danger"
                (click)="onDeleteLenderClicked(rowData.lenderId)" title="Delete"></i>
            </span>
          </span>
        </td>
        <td *ngFor="let col of getSortedColumns()" [ngSwitch]="col.field">
          <span *ngSwitchCase="'active'">
            {{rowData.active ? 'Yes' : 'No'}}
          </span>

          <span *ngSwitchCase="'ldeVendor'">
            <span class="text-cell-primary">{{rowData.ldeVendor == 0 ? '' : rowData.ldeVendor}}</span>
          </span>

          <span *ngSwitchCase="'name'">
            <span class="text-cell-primary">{{rowData.name}}</span>
          </span>
          <span *ngSwitchCase="'pricingVendor'">
            {{ getEnumName(rowData.pricingVendor) }}
          </span>

          <span *ngSwitchCase="'pricingVendorInvestorId'">
            {{ getInvestorNameById( rowData.pricingVendor, rowData.pricingVendorInvestorId ) }}
          </span>
          <span *ngSwitchCase="'address'">
            <span class="text-cell-primary">{{rowData.phone | phone}}</span><br>
            <span class="text-muted">{{rowData.address}} </span><br>
            <span class="text-muted">{{rowData.city}}, {{getState(rowData.state)?.name}} {{rowData.zip}}</span>
          </span>

          <span *ngSwitchCase="'aeName'">
            <span class="text-cell-primary">{{rowData.aeName}}</span><br>
            <span class="text-muted">{{rowData.aePhone}}</span><br>
            <span class="text-muted">{{rowData.aeEmail}}</span>
          </span>

          <span *ngSwitchCase="'mortgageeClause'">
            <span style="word-wrap: normal;" class="text-muted">{{rowData.mortgageeClause}}</span>
          </span>

          <span *ngSwitchCase="'titleClause'">
            <span style="word-wrap: normal;" class="text-muted">{{rowData.titleClause}}</span>
          </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10" class="text-center">
          {{ !isLoading ? 'There are no lenders configured.' : 'Loading...' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #lendersOrderDrawer [title]="'Lender Order'" [name]="'lendersOrderDrawer'" [templateRef]="lendersOrderRef"
  [options]="orderDrawerOptions">
</drawer>
<ng-template #lendersOrderRef>
  <rearrange-order #lendersOrder [itemList]="itemsToOrder" (close)="closeOrderDrawer($event)"
    (save)="onOrderDrawerSave($event)"></rearrange-order>
</ng-template>