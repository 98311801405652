<a class="nav-link float-end switch-sms-button" style="padding: 0" (click)="sendEmail = !sendEmail" >Switch to {{ sendEmail ? 'SMS' : 'Email' }}</a>
<common-send-email
  *ngIf="sendEmail"
  #commonEmailSender
  [body]="emailBody"
  [subject]="emailSubject"
  [to]="emailTo"
  [emailTemplates]="emailTemplates"
  [showTemplates]="showEmailTemplates"
  (selectedEmailTemplateChanged)="selectedEmailTemplateChanged($event)"
></common-send-email>

<common-send-sms
  *ngIf="!sendEmail"
  #commonSmsSender
  [recipients]="smsRecipients"
  [toPhoneNumber]="smsToPhoneNumber"
></common-send-sms>

<div class="d-flex justify-content-end mt-2">
  <button
    type="button"
    class="btn btn-primary"
    (click)="onSendClicked()"
    data-bs-dismiss="modal"
  >
    Send {{ sendEmail ? 'Email' : 'Sms' }}
  </button>
</div>
