import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactListColumnDefinition } from 'src/app/models';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { ExpressionBuilderDialog } from 'src/app/modules/expression-builder/expression-builder-dialog.component';
import { ExpressionBuilderData } from 'src/app/modules/expression-builder/expression-builder.model';
import { Constants } from 'src/app/services/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'expression-input',
  templateUrl: './expression-input.component.html',
  styleUrls: ['./expression-input.component.scss']
})
export class ExpressionInputComponent implements OnInit, OnDestroy {

  @Input()
  expression: { text: string, group: ExpressionGroup, displayFields: ContactListColumnDefinition[], isContactListCustom: boolean, contactListId: number };

  @Input()
  companyId: number;

  @Input()
  readOnly: boolean;

  @Output()
  onExpressionChanged = new EventEmitter<ExpressionGroup>();

  private _saveExpressionSubscription: Subscription;
  private _deleteExpressionSubscription: Subscription;

  constructor(private readonly _modalService: NgbModal) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._saveExpressionSubscription?.unsubscribe();
    this._deleteExpressionSubscription?.unsubscribe();
  }

  openExpressionModal() {
    const modalInstance = this._modalService.open(ExpressionBuilderDialog, { ...Constants.modalOptions.xlarge, scrollable: false });
    modalInstance.componentInstance.expressionBuilderData = <ExpressionBuilderData>{
      expressionGroup: this.expression.group,
      fields: this.expression.displayFields,
      isCustomContactList: this.expression.isContactListCustom || false,
      contactListId: this.expression.contactListId
    };
    modalInstance.componentInstance.companyId = this.companyId;
    this._saveExpressionSubscription = modalInstance.componentInstance.saveExpression.subscribe((expressionGroup: ExpressionGroup) => {
      this.onExpressionChanged.emit(expressionGroup);
      modalInstance.close();
    });
    this._deleteExpressionSubscription = modalInstance.componentInstance.deleteExpression.subscribe(() => {
      this.onExpressionChanged.emit();
      modalInstance.close();
    });
  }
}
