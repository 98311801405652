import { ICharacteristic } from "./characterictic-interface";
import { CharacteristicMergeValue } from "./characteristic-merge-value.model";

export class BorrowerCharacteristic implements ICharacteristic {
    borrowerCharacteristicId: number;
    applicationId: number;
    borrowerId: number;
    characteristicId: number;
    characteristicMergeValues: Array<CharacteristicMergeValue>;
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;
    pendingDeletion: boolean = false;
    enabledChannels: string;

    constructor(borrowerCharacteristicId: number, applicationId: number, characteristicId: number, borrowerId: number) {
      this.borrowerCharacteristicId = borrowerCharacteristicId;
      this.applicationId = applicationId;
      this.characteristicId = characteristicId;
      this.borrowerId = borrowerId;
      this.characteristicMergeValues = [];
    }
}