import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { TableModule } from "primeng/table";
import { QuickPriceHistoryComponent } from "./quick-price-history.component";

@NgModule({
  imports: [
    TableModule,
    SharedModule,
  ],
  declarations: [
    QuickPriceHistoryComponent
  ],
  exports: [
    QuickPriceHistoryComponent
  ],
  providers: [
  ]
})
export class QuickPriceHistoryModule {
  constructor() {
  }
}


