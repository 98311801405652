<p-table
  #dt3
  [value]="filteredMessages"
  sortMode="single"
  sortField="statusConditionDescription"
  styleClass="p-datatable-gridlines p-datatable-sm"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [scrollable]="true"
  scrollHeight="'400px'"
  [paginator]="true"
  [rows]="10"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [showCurrentPageReport]="true"
  [showPageLinks]="false"
>
  <ng-template pTemplate="caption">
    <div class="d-flex align-items-center justify-content-between">
      <button type="button" class="btn btn-primary" (click)="refreshClicked()">Refresh Audits</button>
      <p-multiSelect
        [(ngModel)]="selectedCategories"
        [options]="categories"
        placeholder="Select Category"
        (onChange)="filterByStatus($event.value)"
      >
        <ng-template let-option pTemplate="item">
          <span *ngIf=" option == 'Info'" class="badge bg-info">
            <i class="me-1 fa fa-info-circle"></i>Info
          </span>
          <span *ngIf=" option == 'Warning'" class="badge bg-warning">
            <i class="me-1 fa fa-exclamation-circle"></i>Warning
          </span>
          <span *ngIf=" option == 'Pass'" class="badge bg-success">
            <i class="me-1 fa fa-check-circle"></i>Pass
          </span>
          <span *ngIf="!['Info','Warning','Pass'].includes(option)" class="badge bg-secondary">
            <i class="me-1 fa fa-question-circle"></i>{{option}}
          </span>
        </ng-template>
      </p-multiSelect>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="statusDescription">
        Status Description <p-sortIcon field="statusDescription"></p-sortIcon>
      </th>
      <th pSortableColumn="status">
        Status <p-sortIcon field="status"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-message>
    <tr>
      <td>{{ message.statusDescription }}</td>
      <td>
        {{ message.status }}
        <br />
        <span *ngIf=" message.statusConditionDescription == 'Info'" class="badge bg-info">
          <i class="me-1 fa fa-info-circle"></i>Info
        </span>
        <span *ngIf=" message.statusConditionDescription == 'Warning'" class="badge bg-warning">
          <i class="me-1 fa fa-exclamation-circle"></i>Warning
        </span>
        <span *ngIf=" message.statusConditionDescription == 'Pass'" class="badge bg-success">
          <i class="me-1 fa fa-check-circle"></i>Pass
        </span>
        <span *ngIf="!['Info','Warning','Pass'].includes(message.statusConditionDescription)" class="badge bg-secondary">
          <i class="me-1 fa fa-question-circle"></i>{{message.statusConditionDescription}}
        </span>
        <span class="text-muted" *ngIf="!message.statusConditionDescription">NA</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="2" class="text-center">No messages found.</td>
    </tr>
  </ng-template>
</p-table>
