import { Component, Input, OnInit } from '@angular/core';
import { LosDisclosureGenJob, LosDisclosureInfo, LosGeneratedDocument } from '../../../request-models/los-disclosure-gen-job';

@Component({
  selector: 'meridian-disclosure-info',
  templateUrl: './meridian-disclosure-info.component.html',
  styleUrls: ['./meridian-disclosure-info.component.scss']
})
export class MeridianDisclosureInfoComponent implements OnInit {

  @Input() type: string;
  @Input() job: LosDisclosureGenJob;
  @Input() documentsVisible: boolean = false;
  @Input() showOnlyRecommendedValidations: boolean = false;

  @Input() isTpoUser: boolean = false;

  selectedTab: string = 'disclosurePreview';

  constructor() { }

  ngOnInit(): void {
  }

}
