<p-table #dt1 [value]="histories" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
    [showCurrentPageReport]="true" [globalFilterFields]="['actionValue','insertedBy','dateInserted']"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines">
    <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <span class="p-input-icon-right p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="form-control" (ngModelChange)="dt1.first=0"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th [pSortableColumn]="'actionValue'">
                Contact Status
                <p-sortIcon [field]="'actionValue'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'insertedBy'">
                By
                <p-sortIcon [field]="'insertedBy'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'actionDate'">
                Date
                <p-sortIcon [field]="'actionDate'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-internalContact>
        <tr>
            <td> {{internalContact.actionValue}} </td>
            <td> {{internalContact.insertedBy}} </td>
            <td> {{internalContact['actionDate'] | date: dateFormat}} </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3" class="text-center"> No document found. </td>
        </tr>
    </ng-template>
</p-table>
