import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DialListRecord } from "src/app/modules/dialer/models/dial-list-record.model";
import { DialList } from "src/app/modules/dialer/models/dial-list.model";
import { DialerService } from "src/app/modules/dialer/services/dialer.service";

@Component({
  selector: 'delete-list-confirmation-dialog',
  templateUrl: './delete-list-confirmation-dialog.component.html',
  styleUrls: ['./delete-list-confirmation-dialog.component.scss']
})
export class DeleteListConfirmationDialogComponent {

  @Input() deleteDialList: DialList;
  @Input() toDialLists: DialList[];

  selectedDialListId:number = null;;

  isLoading: boolean = false;
  dialListRecordsCount: number = 0;

  isMove: string;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _dialerService: DialerService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._dialerService.getDialListRecords(this.deleteDialList.dialListId)
      .subscribe((dialListRecords: DialListRecord[]) => {
        this.isLoading = false;
        this.dialListRecordsCount = dialListRecords.length;;
      })
  }

  onOkClicked = () => {
    this.activeModal.close({ toDialListId: this.selectedDialListId, isMoving: this.isMove });
  }
}
