import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { SearchService } from 'src/app/modules/search/services/search.service';
import { LoanService } from 'src/app/services/loan';

export interface LoanSearchCriteria {
  type: 'AppNo' | 'GlobalSearch';
  applicationId?: number | '';
  searchText?: string;
}

@Component({
  selector: 'search-loan-app',
  templateUrl: './search-app.component.html',
  styleUrls: ['./search-app.component.scss']
})
export class SearchAppComponent implements OnInit {
  isSearching: boolean;
  searchCriteria: LoanSearchCriteria = {
    type: 'AppNo',
  };
  loanList: any;
  selectedLoanId: number;
  borrowersLoading: boolean;
  
  private borrowerNameById: Map<number, string> = new Map<number, string>();

  constructor(public activeModal: NgbActiveModal,
    private readonly _searchService: SearchService,
    private readonly _loanService: LoanService) { }

  ngOnInit(): void {
  }

  searchTypeChanged() {
    if (this.searchCriteria.type === 'AppNo') {
      this.searchCriteria.searchText = '';
    } else {
      this.searchCriteria.applicationId = '';
    }
  }

  searchApp() {
    if (this.searchCriteria.type === 'AppNo' && this.searchCriteria.applicationId) {
      this.searchByAppId();
    } else if (this.searchCriteria.searchText) {
      this.searchByKeyword();
    }
  }

  private searchByAppId() {
    this.isSearching = true;
    this._loanService.getApplicationModel(Number(this.searchCriteria.applicationId), false)
      .subscribe({
        next: (loan) => {
          this._loanService.getBorrowers(loan.applicationId)
            .pipe(finalize(() => this.isSearching = false))
            .subscribe({
              next: (borrowers) => {
                borrowers.forEach(borr => {
                  this.borrowerNameById.set(borr.borrowerId, `${borr.firstName} ${borr.lastName}`);
                });
                this.loanList = [{
                  applicationId: loan.applicationId,
                  firstName: borrowers.find(b => b.isPrimary)?.firstName,
                  lastName: borrowers.find(b => b.isPrimary)?.lastName
                }];
                this.setSelectedLoan(loan.applicationId);
              }, error: () => {
                this.loanList = [];
              }
            })
        }, error: () => {
          this.isSearching = false;
          this.loanList = [];
        }
      });
  }

  private searchByKeyword() {
    this.isSearching = true;
    this._searchService.searchApplications({ searchText: this.searchCriteria.searchText, searchMode: 'Contains' })
      .pipe(finalize(() => this.isSearching = false))
      .subscribe({
        next: (response) => {
          this.loanList = response.map(loan => ({
            applicationId: loan.applicationId,
            firstName: loan.firstName,
            lastName: loan.lastName
          }));
        }, error: () => {
          this.loanList = [];
        }
      });
  }

  setSelectedLoan(applicationId: number) {
    this.selectedLoanId = applicationId;
  }

  ok() {
    if (this.searchCriteria.type === 'GlobalSearch') {
      this.borrowersLoading = true;
      this._loanService.getBorrowers(this.selectedLoanId)
        .pipe(finalize(() => this.borrowersLoading = false))
        .subscribe({
          next: (borrowers) => {
            borrowers.forEach(borr => {
              this.borrowerNameById.set(borr.borrowerId, `${borr.firstName} ${borr.lastName}`);
            });
            this.activeModal.close({ appId: this.selectedLoanId, borrowerNameById: this.borrowerNameById });
          },
          error: () => {
          }
        });
    } else {
      this.activeModal.close({ appId: this.selectedLoanId, borrowerNameById: this.borrowerNameById });
    }
  }
}
