import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { LoanService } from 'src/app/services/loan/loan.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConditionsTableComponent } from './components/conditions-table/conditions-table.component';
import { ConditionsTasksComponent } from './conditions-tasks.component';
import { TotalOutstandingConditionsDialogComponent } from './components/total-outstanding-conditions-dialog/total-outstanding-conditions-dialog.component';
import { ConditionsTaskInformationDialogComponent } from './components/conditions-task-information-dialog/conditions-task-information-dialog.component';
import { AdminConditionTableComponent } from './components/admin-condition-table/admin-condition-table.component';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { ImportConditionsDialogComponent } from './components/import-conditions-dialog/import-conditions-dialog.component';
import { LosInfoModule } from '../los-info/los.module';
import { ImportConditionsDialogLDEComponent } from './components/import-conditions-dialog-lde/import-conditions-dialog-lde.component';
import { ImportConditionsDialogBulkComponent } from './components/import-conditions-dialog-bulk/import-conditions-dialog-bulk.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TasksModule } from '../tasks/tasks.module';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ButtonModule } from 'primeng/button';
import { TaskEmailSenderComponent } from './components/task-email-sender/task-email-sender.component';
import { TaskEmailSenderModalComponent } from './components/task-email-sender-modal/task-email-sender-modal.component';
import { TpoConditionTableComponent } from './components/tpo-condition-table/tpo-condition-table.component';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
@NgModule({
    imports: [
        SharedModule,
        TableModule,
        LosInfoModule,
        NgWizardModule.forRoot(ngWizardConfig),
        NgSelectModule,
        TasksModule,
        ContextMenuModule,
        ButtonModule
    ],
    declarations: [
        ConditionsTableComponent,
        ConditionsTasksComponent,
        ConditionsTaskInformationDialogComponent,
        TotalOutstandingConditionsDialogComponent,
        AdminConditionTableComponent,
        TpoConditionTableComponent,
        ImportConditionsDialogComponent,
        ImportConditionsDialogLDEComponent,
        ImportConditionsDialogBulkComponent,
        TaskEmailSenderComponent,
        TaskEmailSenderModalComponent,
    ],
    exports: [
        ConditionsTableComponent,
        ConditionsTasksComponent
    ],
    providers: [LoanService]
})
export class ConditionsTasksModule {
  constructor() {}
}
