<div *ngIf="unownedBorrowers else spinner">
  <p-table #dt [value]="unownedBorrowers" [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll"
    name="contact-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true" [showCurrentPageReport]="true"
    styleClass="p-datatable-gridlines" [selectionPageOnly]="true"
    [globalFilterFields]="['lastName','firstName','mailingStreet','mobilePhone','homePhone','email']">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button class="btn btn-primary" (click)="onCreateBorrowerClicked()">
          Create New Borrower
        </button>
        <span class="p-input-icon-right p-ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
          (ngModelChange)="dt.first=0" placeholder="Search keyword" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Action</th>
        <th [pSortableColumn]="'lastName'">
          Last Name
          <p-sortIcon [field]="'lastName'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'firstName'">
          First Name
          <p-sortIcon [field]="'firstName'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'mailingStreet'">
          Address
          <p-sortIcon [field]="'mailingStreet'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'mobilePhone'">
          Mobile Phone
          <p-sortIcon [field]="'mobilePhone'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'homePhone'">
          Home Phone
          <p-sortIcon [field]="'homePhone'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'email'">
          E-Mail
          <p-sortIcon [field]="'email'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-borrowers>
      <tr>
        <td width="100">
          <button class="btn btn-success btn-xs" type="button"
           (click)="onSelectClicked(borrowers.borrowerId)">
            Add To {{isMortgage ? 'Mortgage' : 'Loan'}}</button>
        </td>
        <td>{{borrowers.lastName}}</td>
        <td>{{borrowers.firstName}}</td>
        <td>{{borrowers.mailingStreet}}</td>
        <td>{{borrowers.mobilePhone | phone}}</td>
        <td>{{borrowers.homePhone | phone}}</td>
        <td>{{borrowers.email}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<ng-template #spinner>
  <loading-indicator [loadingMessage]="'Loading borrowers, please wait...'"></loading-indicator>
</ng-template>
