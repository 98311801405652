<p-table #docsTable [value]="documents" [resizableColumns]="true" styleClass="p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="'fileName'" pResizableColumn>File Name
        <p-sortIcon [field]="'fileName'"></p-sortIcon>
      </th>
      <th pResizableColumn>View Document
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-doc>
    <tr>
      <td>{{doc.fileName}}</td>
      <td>
        <button class="btn btn-sm btn-soft-primary"
        type="button"
          (click)="onViewDocumentClicked(doc)">
          <span
            *ngIf="isLoadingDocument"
            class="spinner-border spinner-border-xs"
            role="status"
            aria-hidden="true">
          </span>
          {{ isLoadingDocument ? 'Please wait...' : 'View Document'}}
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center"> {{emptyMessage}} </td>
    </tr>
  </ng-template>
</p-table>
