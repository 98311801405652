<div class="loan-ratios-stats" *ngIf="ltvDetails">
  <div class="d-flex justify-content-between">
    <h4 class="card-title">
      <strong> Loan Ratios </strong>
    </h4>
    <button type="button" class="btn-close btn-ltv" (click)="onClickedCloseLoanRatios()"></button>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-4">
      <loan-ltv-donut-chart [title]="'LTV'" [value]="ltvDetails?.ltv">
      </loan-ltv-donut-chart>
    </div>
    <div class="col-md-4">
      <loan-ltv-donut-chart [title]="'CLTV'" [value]="ltvDetails?.cltv">
      </loan-ltv-donut-chart>
    </div>

    <div class="col-md-4">
      <loan-ltv-donut-chart [title]="'HCLTV'" [value]="ltvDetails?.hcltv">
      </loan-ltv-donut-chart>
    </div>

  </div>
  <div class="row">
    <table class="ltv-details-table">
      <tr>
        <td>Base Loan Amount:</td>
        <td><strong>{{ (ltvDetails?.baseLoanAmount | currency) || '--' }}</strong></td>
      </tr>
      <tr>
        <td>Total Loan Amount:</td>
        <td><strong>{{ (ltvDetails?.totalLoanAmount | currency) || '--' }}</strong></td>
      </tr>
      <tr>
        <td>Collateral Value:</td>
        <td><strong>{{ (ltvDetails?.collateralValue | currency) || '--' }}</strong></td>
      </tr>
      <tr>
        <td>Subordinate Lien Amount:</td>
        <td><strong>{{ (ltvDetails?.subordinateLienAmount | currency) || '--' }}</strong></td>
      </tr>
      <tr>
        <td>Subordinate Lien Credit Limit Total:</td>
        <td><strong>{{ (ltvDetails?.subordinateLienCreditLimitTotal | currency) || '--' }}</strong></td>
      </tr>
    </table>
  </div>
</div>
