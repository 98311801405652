import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { DataService } from 'src/app/core/services/data.service';
import { Observable } from 'rxjs';
import { BranchLoanOptions } from '../models/branch-loan-options.model';
import { Borrower, Branch, CustomDataConfig, LoanApplication, MortgageTerm } from 'src/app/models';
import { Lookup } from '../models/lookup.model';
import { Application } from '../models/application.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { catchError, map } from 'rxjs/operators';
import { BorrowerFull } from '../models/full-borrower.model';
import { LoanProduct } from 'src/app/models/config/product.model';
import { Configuration } from 'src/app/models/configuration.model';
import { Router } from '@angular/router';
import { TelephonyServiceModel } from '../../sms-configuration/models';

@Injectable()
export class AppDetailsService {
  @Output()
  loanStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private readonly _dataService: DataService,
    private router: Router
  ) { }

  getApplicationTaskCounts = (
    applicationId: number
  ): Observable<DashboardTaskCounts> => {
    const url = `api/loan/${applicationId}/gettaskcountbyapplicationid`;
    return this._dataService.get(url);
  };

  getAllBranches = (companyId: number): Observable<Branch[]> => {
    const url = `api/Admin/AllBranchModel?companyId=${companyId}&activeOnly=true`;
    return this._dataService.get(url);
  };

  getAllBranchLoanOptions = (
    branchId: number,
    companyId: number
  ): Observable<BranchLoanOptions> => {
    const url = `api/Admin/GetAllBranchLoanOptions/${branchId}?companyId=${companyId}`;
    return this._dataService.get(url);
  };

  getProductById = (productId: number): Observable<LoanProduct> => {
    const url = `api/Admin/GetProductById/${productId}`;
    return this._dataService.get(url);
  };

  getCustomDataConfig = (): Observable<CustomDataConfig> => {
    const url = `api/Admin/get-custom-data-config`;
    return this._dataService.get(url);
  };

  getErnstFeesEnabled = (): Observable<boolean> => {
    const url = `api/Admin/get-ernst-fees-enabled-config`;
    return this._dataService.get(url);
  };

  getLodestarFeesEnabled = (): Observable<boolean> => {
    const url = `api/Admin/get-lodestar-fees-enabled-config`;
    return this._dataService.get(url);
  };

  getLoanHiddenFields = (): Observable<Configuration> => {
    const url = `api/Admin/get-loan-hidden-fields-config`;
    return this._dataService.get(url);
  };

  getSmsServiceConfig = (): Observable<TelephonyServiceModel> => {
    const url = `api/Admin/get-sms-service-config`;
    return this._dataService.get(url);
  };

  getLookUps = (lookupType: string): Observable<Lookup[]> => {
    const url = `api/contact-list/lookups/${lookupType}`;
    return this._dataService.get(url);
  };

  inviteBorrower = (borrowerId: number, loanId: number): Observable<any> => {
    const url = `api/Borrower/InviteBorrower/${borrowerId}?applicationId=${loanId}`;
    return this._dataService.post(url, {});
  };

  getFullBorrower = (borrowerId: number): Observable<BorrowerFull> => {
    const url = `api/Borrower/${borrowerId}/true`;
    return this._dataService.get(url);
  }

  setDuplicateBorrower = (): Observable<Borrower[]> => {
    const url = `api/DuplicateBorrower/true`;
    return this._dataService.post(url, {});
  }

  setUpsertBorrower = (fullBorrower: BorrowerFull): Observable<BorrowerFull> => {
    const url = `api/UpsertBorrower/true`;
    return this._dataService.post(url, fullBorrower);
  }

  removeBorrower = (loanId: number, borrowerId: number): Observable<any> => {
    const url = `api/Loan/${loanId}/RemoveBorrowerFromLoan/${borrowerId}`;
    return this._dataService.post(url, {});
  };

  setBorrowerPrimaryLoan = (loanId: number, borrowerId: number): Observable<LoanApplication> => {
    const url = `api/Loan/${loanId}/SetBorrowerAsPrimaryOnLoan/${borrowerId}`;
    return this._dataService.post(url, {});
  };

  setAllowPrimaryToSatisfy = (loanId: number, borrowerId: number, allow: boolean): Observable<any> => {
    const url = `api/Loan/${loanId}/SetAllowPrimaryToSatisfyFlagOnLoan/${borrowerId}/${allow}`;
    return this._dataService.post(url, {});
  };

  saveLoanInfo = (loanId: number, loanInfo: LoanDetailsInfo): Observable<LoanDetailsInfo> => {
    const clientPage = this.router.url?.split("/").pop();

    const url = `api/Loan/${loanId}/SaveLoanInfo`;
    return this._dataService.post(url, loanInfo, {
      "client-page": clientPage
    });
  };

  setLoanStatus = (applicationId: number): Observable<any> => {
    const url = `api/Loan/${applicationId}/SaveFirstLoanStatusByLoanPurpose`;
    return this._dataService.post(url, []);
  };

  getBorrowers = (applicationId: number): Observable<Borrower[]> => {
    const url = `api/loan/${applicationId}/borrowers`;
    return this._dataService.get(url);
  };

  getAllApplication = (borrowerId: number): Observable<Application> => {
    const url = `api/Borrower/${borrowerId}/GetAllApplicationInfo`;
    return this._dataService.get(url);
  };

  checkForOnlineAppTask = (appId: number) => {
    const url = `api/Loan/${appId}/check-for-online-app-task`;
    return this._dataService.post(url, {});
  }

  checkForLoanBeamDocSyncTask = (appId: number) => {
    const url = `api/Loan/${appId}/check-for-loanbeam-docsync-task`;
    return this._dataService.post(url, {});
  }

  loanBeamDocSyncTask = (appId: number) => {
    const url = `api/Loan/${appId}/loanbeam-docsync-task`;
    return this._dataService.post(url, {});
  }

  addOnlineAppTask = (appId: number) => {
    const url = `api/Loan/${appId}/add-online-app-task`;
    return this._dataService.post(url, {});
  }

  updateQuickClientTask = (appId: number, qcuTaskType: string) => {
    const url = `api/Loan/${appId}/add-qcu-task?qcuTaskType=${qcuTaskType}`;
    return this._dataService.post(url, {});
  }

  setNextLoanStatus = (
    loanPurposeId: number,
    loanStatusId: number,
    applicationId: number
  ): Observable<any> => {
    const url = `api/Lookup/GetLoanStatusForLoanPurpose/${loanPurposeId}/${loanStatusId}?applicationId=${applicationId}`;
    return this._dataService.post(url, {}).pipe(
      map(
        (result) => {
          this.loanStatusChanged.emit();
          return result;
        },
        catchError((err) => {
          throw new Error(err);
        })
      )
    );
  };

  getCustomLookUps = (num: number): Observable<Lookup[]> => {
    const url = `api/contact-list/custom-lookups/${num}`;
    return this._dataService.get(url);
  };

  generateMERS = (applicationId: number): Observable<MortgageTerm> => {
    const url = `api/mers/generate-mers-id/${applicationId}`;
    return this._dataService.post(url, null);
  }
}
