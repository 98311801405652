<div class="modal-header">
  <h5 class="modal-title">Mortgage Insurance Information</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #mortgageInsuranceForm="ngForm" novalidate id="mortgageInsuranceForm" name="mortgageInsuranceForm">
    <div class="card">
      <div class="card-body">
        <div class="loan-app">
          <div class="grid-layout edit-area">
            <div class="grid-layout-row">
                <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'miCompanyNameTypeOtherDescription'"
                  [label]="'MI Company'"
                  [(ngModel)]="mortgage.mortgageInsuranceDetail.miCompanyNameTypeOtherDescription">
                </urla-text-input>
                <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'caseNumber'" [label]="'Case #'"
                  [(ngModel)]="mortgage.mortgageInsuranceDetail.miCaseNumber"></urla-text-input>
            </div>
            <div class="grid-layout-row">
                <urla-date-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'miOrderedDate'" [label]="'MI Ordered Date'"
                  required [(ngModel)]="mortgage.mortgageInsuranceDetail.miOrderedDate"></urla-date-input>
                <urla-date-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'miReceivedDate'" [label]="'MI Received Date'"
                  required [(ngModel)]="mortgage.mortgageInsuranceDetail.miReceivedDate"></urla-date-input>
            </div>
            <div class="grid-layout-row">
              <urla-date-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'scheduledAmortizationMidpointDate'" [label]="'Scheduled Amortization Midpoint Date'"
                  required [(ngModel)]="mortgage.mortgageInsuranceDetail.scheduledAmortizationMidpointDate"></urla-date-input>
                <urla-date-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'borrowerMiTerminationDate'" [label]="'Borrower MI Termination Date'"
                  required [(ngModel)]="mortgage.mortgageInsuranceDetail.borrowerMiTerminationDate"></urla-date-input>
            </div>
            <div class="grid-layout-row">
              <urla-boolean-checkbox class="col-sm-6 col-xs-6 negative-margin-5" layout="single" [label]="'Mortgage Insurance Required'"
                [name]="'isMortgageInsuranceRequired'" [(ngModel)]="mortgage.mortgageInsuranceDetail.isMortgageInsuranceRequired">
              </urla-boolean-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="loan-app">
          <div class="grid-layout">
            <div class="grid-layout-row">
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="premiumRefundableTypeOptions"
                [name]="'premiumRefundableType'" [(ngModel)]="mortgage.mortgageInsuranceDetail.premiumRefundableType"
                [label]="'MI Refundable Option'"></urla-dropdown>
              <urla-percentage-input class="col-sm-6 col-xs-6 negative-margin-5" label="Coverage Percent"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.mortgageInsuranceCoveragePercent"
                [name]="'mortgageInsuranceCoveragePercent'">
              </urla-percentage-input>
            </div>
            <div class="grid-layout-row" *ngIf="mortgage.mortgageInsuranceDetail.premiumRefundableType === 'Other'">
              <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5" label="MI Refundable Option Description"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.premiumRefundableTypeOtherDescription"
                [name]="'premiumRefundableTypeOtherDescription'">
              </urla-text-input>
            </div>
            <div class="grid-layout-row">
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="renewalTypeOptions" [name]="'renewalTypeOptions'"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.renewalType" [label]="'Renewal Type'"></urla-dropdown>
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="renewalCalculationTypeOptions"
                [name]="'renewalCalculationTypeOptions'"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.renewalCalculationType" [label]="'Renewal Option'">
              </urla-dropdown>
            </div>
            <div class="grid-layout-row" *ngIf="mortgage.mortgageInsuranceDetail.renewalCalculationType === 'Other'">
              <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5" label="Renewal Option Description"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.renewalCalculationTypeOtherDescription"
                [name]="'renewalCalculationTypeOtherDescription'">
              </urla-text-input>
            </div>
            <div class="grid-layout-row">
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="premiumRatePlanTypeOptions"
                [name]="'premiumRatePlanType'" [(ngModel)]="mortgage.mortgageInsuranceDetail.premiumRatePlanType"
                [label]="'Payment Option'">
              </urla-dropdown>
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="paymentFrequencyOptions"
                [name]="'paymentFrequency'"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.paymentFrequency" [label]="'Payment Frequency'">
              </urla-dropdown>
            </div>
            <div class="grid-layout-row" *ngIf="mortgage.mortgageInsuranceDetail.paymentFrequency === 'Other'">
              <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5" label="Payment Frequency Description"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.paymentFrequencyOtherDescription"
                [name]="'paymentFrequencyOtherDescription'">
              </urla-text-input>
            </div>
            <div class="grid-layout-row">
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="durationTypeOptions" [name]="'duration'"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.duration" [label]="'Years of Coverage'"></urla-dropdown>
              <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="premiumSourceOptions"
                [name]="'premiumSource'" [(ngModel)]="mortgage.mortgageInsuranceDetail.premiumSource"
                [label]="'Premium Source'">
              </urla-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Mortgage Insurance</h4>
      </div>
      <div class="card-body">
        <div class="loan-app">
          <div class="grid-layout">
            <div class="grid-layout-row">
              <urla-currency-input class="col-sm-3 col-xs-3 negative-margin-5" [hasCalculator]="true" [isEditableEvenIfItHasACalculator]="true"
                [label]="'1. MI Pmt'" [(ngModel)]="mortgage.mortgageInsuranceDetail.level1MonthlyAmount"
                (calculatorClicked)="onMonthlyAmountCalculatorClicked()" [name]="'level1MonthlyAmount'">
              </urla-currency-input>
              <urla-digits-input class="col-sm-3 col-xs-3 negative-margin-5" label="Months"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.level1Months" [name]="'level1Months'">
              </urla-digits-input>
              <urla-currency-input class="col-sm-3 col-xs-3 negative-margin-5" [hasCalculator]="true" [isEditableEvenIfItHasACalculator]="true"
                [label]="'2. MI Pmt'" [(ngModel)]="mortgage.mortgageInsuranceDetail.level2MonthlyAmount"
                (calculatorClicked)="onMonthlyAmountCalculatorClicked()" [name]="'level2MonthlyAmount'">
              </urla-currency-input>
              <urla-digits-input class="col-sm-3 col-xs-3 negative-margin-5" label="Months"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.level2Months" [name]="'level2Months'">
              </urla-digits-input>
            </div>

          </div>
          <div class="grid-layout">
            <div class="grid-layout-row">
              <urla-percentage-input class="col-sm-3 col-xs-3 negative-margin-5" label="Cancel At"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.cancelAtPercent" [name]="'cancelAtPercent'">
              </urla-percentage-input>
              <urla-digits-input class="col-sm-3 col-xs-3 negative-margin-5" label="Months of PMI"
                [(ngModel)]="mortgage.mortgageInsuranceDetail.monthsPrepaid"
                [name]="'monthsPrepaid'">
              </urla-digits-input>
              <urla-boolean-checkbox class="col-sm-5 col-xs-5 negative-margin-5" layout="single" [label]="'Prepaid'"
                [name]="'isMIPrepaid'" [(ngModel)]="mortgage.mortgageInsuranceDetail.isMIPrepaid"
                (ngModelChange)="onIsPrepaidChanged()">
              </urla-boolean-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
        <button type="button" class="btn btn-primary" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
</div>
