<div class="card report-card border-0" *ngIf="application">
  <div class="card-header border-bottom-0 pb-0">
    <h4 class="card-title">Details</h4>
  </div>
  <div class="card-body pt-1">
    <div class="row d-flex justify-content-center">
      <div class="col">
        <a class="m-0 loan-number-link" title="Loan ID"
          [routerLink]="['/admin/app-details/' + application.applicationId]">{{applicationIdWithPadding}}</a>
        <p class="mb-0 text-truncate text-muted" title="Loan Purpose / Loan Type"><span class="text-success"><i
              class="mdi mdi-checkbox-marked-circle-outline me-1"></i></span>{{ loanPurposeName }}
          {{ loanTypeName ? '- ' + loanTypeName : '' }}</p>
        <p class="mb-0 text-truncate text-dark" title="LOS Loan Number">LOS# {{ application.refNumber }}</p>
      </div>
      <div class="col align-self-center px-2">
        <div class="d-flex align-items-center justfy-content-center my-2">
          <i class="fas fa-map-pin fa-2x me-2"></i>
          <p class="text-dark font-weight-semibold mb-0" title="Subject Property Address"> {{ application.mortgageLoan?.subjectProperty?.address1 || 'No Street Address' }}
            <span *ngIf="
                application.mortgageLoan?.subjectProperty?.city ||
                application.mortgageLoan?.subjectProperty?.state ||
                application.mortgageLoan?.subjectProperty?.zipCode">
              &nbsp;
              {{
                application.mortgageLoan?.subjectProperty.city
              ? application.mortgageLoan?.subjectProperty.city + ','
              : ''
              }}
              {{ application.mortgageLoan?.subjectProperty.state | uppercase }}
              {{ application.mortgageLoan?.subjectProperty.zipCode }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card border-0" *ngIf="borrowers && borrowers.length">
  <div class="card-header border-bottom-0 pb-0">
    <h4 class="card-title">Borrowers</h4>
  </div>
  <div class="card-body pt-1">
    <div class="table-responsive">
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th>Name</th>
            <th>Credit Score</th>
            <th>Portal Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let borrower of borrowers | sort : 'isPrimary'; let i = index">
            <td>{{ borrower['fullName'] }}
              <button type="button" id="buttonBorrowerInfo" class="btn ps-0 pb-0 pt-0" data-bs-container="body"
                data-bs-toggle="popover" placement="right" [popover]="templateBorrowerInfo" data-trigger="click"
                [outsideClick]="true" [containerClass]="'templateBorrowerInfo'">
                <i class="fa fa-address-book" style="font-size: 1.3em"></i>
              </button>
              <ng-template #templateBorrowerInfo>
                <table-cell-contact-info [contactInfo]="borrower" [emailEnabled]="false" [recordType]="'Borrower'"
                  [recordId]="borrower.borrowerId" [applicationId]="application.applicationId"
                  [dialerEnabled]="true" [isManualDial]="true">
                </table-cell-contact-info>

                <div class="row mt-2 contact-info-address">
                  <span><strong>Address:</strong> {{ borrower.mailingStreet }}
                    <br />
                    {{ borrower.mailingCity }}
                    {{
                    borrower.mailingState
                    ? borrower.mailingState.toUpperCase()
                    : ''
                    }}
                    {{
                    borrower.mailingZip ? ', ' + borrower.mailingZip : ''
                    }}
                  </span>
                </div>
              </ng-template>
            </td>
            <td>
              {{ borrower.creditScore || '--' }}
              <button type="button" class="btn ps-0 pb-0 pt-0" data-bs-container="body" data-bs-toggle="popover"
                placement="right" [popover]="templateCreditInfo" data-trigger="click" [outsideClick]="true"
                [containerClass]="'templateCreditInfo'">
                <i class="fa fa-flag-checkered"></i>
              </button>

              <ng-template #templateCreditInfo>
                <div class="row mt-2 credit-scores-popover">
                  <ul style="list-style: none;">
                    <li><span>Experian:</span> {{ borrower.creditScore_Experian || '--' }}</li>
                    <li><span>TransUnion:</span> {{ borrower.creditScore_TransUnion || '--' }}</li>
                    <li><span>Equifax:</span> {{ borrower.creditScore_Equifax || '--' }}</li>
                  </ul>
                </div>
              </ng-template>
            </td>
            <td>
              <portal-status-indicator [borrower]="borrower" [applicationId]="application.applicationId"
              [disabledInviteLink]="disableBorrowerPortalInviteLink">
              </portal-status-indicator>
            </td>
          </tr>
        </tbody>
      </table>

    </div><!--end table-responsive-->
  </div>
</div>
