<!-- row1 -->
<div class="row">
  <div class="form-group d-flex justify-content-end">
    <div class="form-check form-switch mx-2 form-switch-success" style="padding-top: 20px; margin-left: 10px;">
      <input class="form-check-input mr-2" type="checkbox" id="active" name="active" [(ngModel)]="lender.active"
        #condition="ngModel" [checked]="lender.active">
      <label class="form-check-label mb-2" for="flexSwitchCheckDefault">Is Active</label>
    </div>
  </div>
</div>
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-4">
    <label for="name">Name of Lender</label>
    <input id="name" type="text" name="name" class="form-control" [(ngModel)]="lender.name" #nameInput="ngModel"
      [ngClass]="{'is-invalid' : nameInput && nameInput.touched && nameInput.invalid}" required />
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-2" *ngIf="ldeEnabled">
    <label for="name">Lender Data Exchange</label>
    <select id="ldeVendor" name="ldeVendor" class="form-select" [(ngModel)]="lender.ldeVendor">
      <option [ngValue]="null">-- Select One --</option>
      <option *ngFor="let vendor of vendorsList?.names" [ngValue]="vendor">
        {{ vendor }}
      </option>
    </select>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-2">
    <label for="phone">Phone Number &nbsp;&nbsp;<a [hidden]="!lender.phone" href="tel:{{ lender.phone }}"><i
          class="fa fa-fw fa-phone text-muted"></i></a>
    </label>
    <input class="form-control" id="phone" name="phone" type="text" [(ngModel)]="lender.phone" mask="(000) 000-0000"
      placeHolderCharacter="X" [showMaskTyped]="true" #phoneInput="ngModel"
      [ngClass]="{'is-invalid' : phoneInput && phoneInput.touched && phoneInput.invalid}" />
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-2">
    <label for="pricing">Pricing Vendor</label>
    <ng-select width="100%" name="pricingVendor" bindLabel="name" bindValue="value" class="custom-ng-select"
      placeholder="Select Pricing Vendor" [closeOnSelect]="true" [items]="pricingVendors"
      (change)="handlePricingVendorChange()" [(ngModel)]="lender.pricingVendor" appendTo="body">
    </ng-select>
    <div class="invalid-feedback">Please select pricing vendor</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-2">
    <label for="pricing">Pricing Vendor Investor</label>
    <ng-select name="pricingVendorInvestor" bindLabel="display" bindValue="investorId" placeholder="Select Investor"
      class="custom-ng-select" #pricingVendorProductInput="ngModel" [items]="filteredPricingVendorInvestors"
      [(ngModel)]="lender.pricingVendorInvestorId"
      [ngClass]="{ 'is-invalid': !lender.pricingVendorInvestorId && lender.pricingVendor && pricingVendorProductInput && pricingVendorProductInput.touched }"
      appendTo="body" [required]="!!lender.pricingVendor"></ng-select>
    <div class="invalid-feedback">Please select investor</div>
  </div>
</div>
<!-- row 2 -->
<div class="row">
  <div class="form-group col-xs-12 col-sm-8 col-lg-4">
    <label for="address">Address</label>
    <input id="address" name="address" type="text" class="form-control" placeholder="123 Main St."
      [(ngModel)]="lender.address" #addressInput="ngModel" />
  </div>
  <div class="form-group col-xs-12 col-sm-4 col-lg-2">
    <label for="address2">Unit/Suite</label>
    <input id="address2" name="address2" type="text" class="form-control" [(ngModel)]="lender.address2" />
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="city">City</label>
    <input id="city" name="city" type="text" class="form-control" [(ngModel)]="lender.city" #cityInput="ngModel" />
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <div class="row">
      <div class="col">
        <label for="state">State</label>
        <select id="state" name="state" class="form-select" placeholder="State" [(ngModel)]="lender.state">
          <option [ngValue]="null"></option>
          <option *ngFor="let state of states" [ngValue]="state.value">
            {{ state.name }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="zip">Zip Code</label>
        <zip-code-input [(ngModel)]="lender.zip" [name]="'zip'" [required]="false" [disabled]="false" [readonly]="false"
          (selectionChanged)="onZipCodeRelatedInfoChanged($event)"></zip-code-input>
      </div>
    </div>
  </div>
</div>

<!-- row 3 -->
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-6">
    <label for="mortgageeClause">Mortgagee Clause</label>
    <textarea rows="3" id="mortgageeClause" name="mortgageeClause" class="form-control"
      placeholder="Mortgagee Clause..." [(ngModel)]="lender.mortgageeClause" #mortgageeClauseInput="ngModel">
    </textarea>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-6">
    <label for="titleClause">Title Clause</label>
    <textarea rows="3" id="titleClause" name="titleClause" class="form-control" placeholder="Title Clause..."
      [(ngModel)]="lender.titleClause" #titleClauseInput="ngModel">
    </textarea>
  </div>
</div>