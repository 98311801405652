import { Component, Injector, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { Question } from 'src/app/models/question.model';
import { CheckListService } from 'src/app/services/check-list.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'checklists-questions',
  templateUrl: './checklists-questions.component.html',
  styleUrls: ['./checklists-questions.component.scss']
})
export class ChecklistsQuestionsComponent extends ApplicationContextBoundComponent implements OnInit {

  questions: Question[] = [];
  selectedColumns: any[] = [];
  columns: any[] = [];
  globalFilterFields: string[] = [];
  isDeleteClicked = {};
  superAdmin: boolean = false;
  companyName: string;
  companyId: number;
  currentQuestion: Question;
  globalConfig: GlobalConfig;
  upsertChecklistQuestionDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  private _applicationCOntextSubscription: Subscription;

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _drawerService: DrawerService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();

    this._applicationCOntextSubscription = this.applicationContextService.context.subscribe(context => {
      this.superAdmin = context.userPermissions.superAdmin;
      this.companyId = context.userPermissions.companyId;
      this.globalConfig = context.globalConfig;
      let matchedCompany = context.globalConfig.company.find(c => c.companyGUID == context.currentlyLoggedInUser.userCompanyGuid)
      this.companyName = matchedCompany ? matchedCompany.companyName : null;
    });

    this.columns = [
      { field: 'questionText', header: 'Question Text', order: 1, visible: true },
      { field: 'questionType', header: 'Question Type', order: 2, visible: true }
    ]

    this.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });
    this.getQuestions();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._applicationCOntextSubscription?.unsubscribe();
  }

  openQuestionDrawer = (rowData: Question): void => {
    if (this.currentQuestion) {
      this.onUpsertQuestionDrawerClose(null);
    }
    this.currentQuestion = cloneDeep(rowData || {} as Question);
    this._drawerService.show('upsertChecklistQuestionDrawer', 100);
  }

  onUpsertQuestionDrawerClose = (question: Question): void => {
    this._drawerService.hide("upsertChecklistQuestionDrawer", 100);
    if (question) {
      this.getQuestions();
    }
    this.currentQuestion = null;
  }

  getQuestions = (): void => {
    this._spinner.show();
    this._checkListService.getAllQuestions()
      .subscribe({
        next: result => {
          this.questions = result;
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to load checklist questions', "Error!");
        }
      }).add(() => {
        this._spinner.hide();
      });
  }

  onDeleteQuestionClicked = (questionId: number) => {
    this._checkListService.deleteQuestion(questionId)
      .subscribe({
        next: () => {
          this._notifyService.showSuccess("Question removed succesfully", "Success");
          this.getQuestions();
        }, error: (err) => {
          this._notifyService.showError(err?.message || "Error encountered while deleting question", "Error!");
        }
      });
  }

  getSplittedEnumValue = (enumValue: string): string => {
    return Utils.splitCamelCaseString(enumValue);
  }
}
