import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { LoanDoc } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LoanDocService {

  constructor(private readonly _dataService: DataService) { }

  upsertLoanDoc = (data) => {
    const url = 'api/Loan/' + data.applicationId + '/UpsertLoanDoc';
    return this._dataService.post(url, data);
  }

  getLoanDoc = (loanDocId: number): Observable<LoanDoc> => {
    const url = `api/LoanDoc/${loanDocId}?includeDocFiles=true`;
    return this._dataService.get(url);
  }

  viewFile = (fileGuid: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/File/ViewFile/${fileGuid}`);
  }

  downloadFile = (fileGuid: string, mimeType: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/File/DownloadFile/${fileGuid}`);
  }

  viewPdfFile = (fileGuid: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/File/ViewFile/${fileGuid}`);
  }

  renderHtmlToLoanDoc = (html: string, target: "PDF" | "JPG", title: string, applicationId: number, borrowerId: number): Observable<LoanDoc> => {
    const url = `api/LoanDoc/renderHtmlToLoanDoc?target=${target}&title=${title}&applicationId=${applicationId}&borrowerId=${borrowerId}`;
    return this._dataService.post(url, html);
  }

  renderHtmlToFile = (html: string, target: "PDF" | "JPG", title: string): Observable<HttpResponse<Blob>> => {
    const url = `api/LoanDoc/renderHtmlToFile?target=${target}&title=${title}`;
    return this._dataService.post(url, html, null,{observe: 'response', responseType: 'blob'});
  }
}
