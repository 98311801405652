<div class="modal-header">
    <h5 class="modal-title">Confirm Postponement</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row mb-3 text-center">
        <p>You are changing <i>{{count}}</i> task{{count > 1 ? 's' : ''}}!</p>

        <div class="row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end"><b>Due Date:</b></label>
            <div class="col-sm-8">
              <date-input name="postponeDate"
                [id]="'postponeDate'"
                [(ngModel)]="postponeDate">
              </date-input>
            </div>
        </div>

        <p class="mt-2">Do you want continue?</p>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">No</button>
    <button type="button" class="btn btn-primary right" [disabled]="!postponeDate" (click)="activeModal.close(postponeDate)">Yes</button>
</div>
