<form id="mcrAuditForm" #mcrAuditForm="ngForm" name="mcrAuditForm">
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <span class="fw-bold">MCR Audit Data ( {{quarter}}-{{year}} )</span>
        <span>
          <button type="button" class="btn btn-primary" (click)="backClicked()">Back</button>
        </span>
      </div>
    </div>
    <div class="card-body pb-0">
      <div class="row">
        <div class="col-6">
          <div class="w-100 d-flex flex-column">
            <div class="mb-2">If an input is 'unlocked' <i style="color:orange" class="fas fa-lock-open"></i> that
              means a user has manually overridden the value. This value will not change automatically.</div>
            <div>If an input is 'locked' <i style="color:orange" class="fas fa-lock"></i> that means the
              value has been calculated upon the Application data.
              This value will be recalculated when the loan is saved.</div>
          </div>
        </div>
        <div class="col-6">
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <h3
                    class="text-{{values.excludeFromReport ? 'danger' : !mcrAuditData.auditData.isComplete ? 'warning' : 'success'}}">
                    {{values.excludeFromReport || !mcrAuditData.auditData.isComplete ? 'Not' : ''}} Complete</h3>
                  <p class="mb-0 text-muted" style="max-width: 500px">
                    <span *ngIf="!values.excludeFromReport && !mcrAuditData.auditData.isComplete">
                      Some parts of this Audit Data are incomplete. This application WILL be included in generated
                      reports, which may lead to incorrect data. Please verify the information below is correct. If
                      desired, check 'Exclude' and then save to
                      exclude this application from all generated Mortgage Call Reports.
                    </span>
                    <span *ngIf="values.excludeFromReport">
                      This Application has been manually excluded from all Mortgage Call Reports. Please uncheck the
                      'Exclude' button below and save to include this Application in generated reports.
                    </span>
                    <span *ngIf="!values.excludeFromReport && mcrAuditData.auditData.isComplete">
                      This Application has the minimum amount of data
                      required to use it in a
                      Mortgage Call Report. NOTE: This does not mean that the data is correct and/or does not need
                      modification. Please, verify that
                      the values below are correct, and override any to their correct values as necessary.
                    </span>
                  </p>
                </div>
                <div class="col-auto align-self-center">
                  <div class="report-main-icon bg-light-alt">
                    <svg *ngIf="values.excludeFromReport || !mcrAuditData.auditData.isComplete"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-triangle text-{{values.excludeFromReport ? 'danger' : 'warning'}}">
                      <path
                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                      </path>
                      <line x1="12" y1="9" x2="12" y2="13"></line>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <svg *ngIf="!values.excludeFromReport && mcrAuditData.auditData.isComplete"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-check text-success">
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </div>
                </div>
              </div>
            </div><!--end card-body-->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <div class="row mb-4">
            <div class="col">
              <div class="row">
                <div class="col-12">
                  <div class="form-check">
                    <label for="excludeFromReport" class="form-check-label">Exclude From Reports</label>
                    <input id="excludeFromReport" class="form-check-input me-2"
                      style="width: 18px; height: 18px; margin-top: -2px" name="excludeFromReport" type="checkbox"
                      [(ngModel)]="values.excludeFromReport" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-12">
                  <div class="form-check">
                    <label for="syncFromApplication" class="form-check-label">Sync From Application</label>
                    <input id="syncFromApplication" class="form-check-input me-2"
                      style="width: 18px; height: 18px; margin-top: -2px" name="syncFromApplication" type="checkbox"
                      [(ngModel)]="values.syncFromApplication" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col"></div>
            <div class="col"></div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="state">State
                    <i [title]="invalidFields['state']" *ngIf="invalidFields['state']" class="fa fa-exclamation-circle"
                      style="color: red"></i>
                  </label>
                  <select id="state" name="state" class="form-select" placeholder="State"
                    [ngClass]="{'is-invalid' : invalidFields['state']}" [disabled]="lockFields['state']"
                    [(ngModel)]="values.state">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let state of states" [ngValue]="state.value">
                      {{ state.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('state')" class="btn btn-sm">
                      <span [title]="lockFields['state'] ? 'Locked' : 'Unlocked'">
                        <i class="fas fa-lock{{
                            !lockFields['state'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="channel">Channel
                    <i [title]="invalidFields['channel']" *ngIf="invalidFields['channel']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="channel" name="channel" class="form-select" placeholder="Channel"
                    [ngClass]="{'is-invalid' : invalidFields['channel']}" [disabled]="lockFields['channel']"
                    [(ngModel)]="values.channel">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let channel of channels" [ngValue]="channel.value">
                      {{ channel.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('channel')" class="btn btn-sm">
                      <span [title]="lockFields['channel'] ? 'Locked' : 'Unlocked'">
                        <i class="fas fa-lock{{
                            !lockFields['channel'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="loanType">Loan Type
                    <i [title]="invalidFields['loanType']" *ngIf="invalidFields['loanType']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="loanType" name="loanType" class="form-select" placeholder="Loan Type"
                    [ngClass]="{'is-invalid' : invalidFields['loanType']}" [disabled]="lockFields['loanType']"
                    [(ngModel)]="values.loanType">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let loanType of loanTypes" [ngValue]="loanType.value">
                      {{ loanType.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('loanType')" class="btn btn-sm">
                      <span [title]="lockFields['loanType'] ? 'Locked' : 'Unlocked'">
                        <i class="fas fa-lock{{
                            !lockFields['loanType'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="loanPurpose">Loan Purpose
                    <i [title]="invalidFields['loanPurpose']" *ngIf="invalidFields['loanPurpose']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="loanPurpose" name="loanPurpose" class="form-select" placeholder="Loan Purpose"
                    [ngClass]="{'is-invalid' : invalidFields['loanPurpose']}" [disabled]="lockFields['loanPurpose']"
                    [(ngModel)]="values.loanPurpose">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let loanPurpose of loanPurposes" [ngValue]="loanPurpose.value">
                      {{ loanPurpose.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('loanPurpose')" class="btn btn-sm">
                      <span [title]="
                          lockFields['loanPurpose'] ? 'Locked' : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['loanPurpose'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <div class="row">
                <label for="isHOEPA">Is HOEPA?
                  <i [title]="invalidFields['isHOEPA']" *ngIf="invalidFields['isHOEPA']"
                    class="fa fa-exclamation-circle" style="color: red"></i>
                </label>
              </div>
              <div class="row border" style="height: 33.5px; border-radius: 0.5rem !important">
                <div class="col-11 d-flex align-items-center justify-content-start">
                  <input id="isHOEPA" class="form-check-input" style="width: 18px; height: 18px; margin-top: -2px"
                    [ngClass]="{'is-invalid' : invalidFields['isHOEPA']}" name="isHOEPA" type="checkbox"
                    [disabled]="lockFields['isHOEPA']" [(ngModel)]="values.isHOEPA" />
                </div>
                <div class="col-1 d-flex align-items-center justify-content-start">
                  <button (click)="lockUnlockEdit('isHOEPA')" class="btn btn-sm" style="margin-left: -12px">
                    <span [title]="lockFields['isHOEPA'] ? 'Locked' : 'Unlocked'">
                      <i class="fas fa-lock{{
                            !lockFields['isHOEPA'] ? '-open' : ''
                          }}" style="color: orange"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="propertyType">Property Type
                    <i [title]="invalidFields['propertyType']" *ngIf="invalidFields['propertyType']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="propertyType" name="propertyType" class="form-select" placeholder="Property Type"
                    [ngClass]="{'is-invalid' : invalidFields['propertyType']}" [disabled]="lockFields['propertyType']"
                    [(ngModel)]="values.propertyType">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let type of propertyTypes" [ngValue]="type.value">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('propertyType')" class="btn btn-sm">
                      <span [title]="
                          lockFields['propertyType'] ? 'Locked' : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['propertyType'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="applicationAmountStartOfQuarter">Application Amount Start Of Quarter
                    <i [title]="invalidFields['applicationAmountStartOfQuarter']"
                      *ngIf="invalidFields['applicationAmountStartOfQuarter']" class="fa fa-exclamation-circle"
                      style="color: red"></i>
                  </label>
                  <currency-input name="applicationAmountStartOfQuarter"
                    [highlightAsInvalid]="invalidFields['applicationAmountStartOfQuarter']"
                    [disabled]="lockFields['applicationAmountStartOfQuarter']"
                    [placeholder]="'$1000'"
                    [(ngModel)]="values.applicationAmountStartOfQuarter"></currency-input>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="
                        lockUnlockEdit('applicationAmountStartOfQuarter')
                      " class="btn btn-sm">
                      <span [title]="
                          lockFields['applicationAmountStartOfQuarter']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['applicationAmountStartOfQuarter']
                              ? '-open'
                              : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="applicationAmountEndOfQuarter">Application Amount End Of Quarter
                    <i [title]="invalidFields['applicationAmountEndOfQuarter']"
                      *ngIf="invalidFields['applicationAmountEndOfQuarter']" class="fa fa-exclamation-circle"
                      style="color: red"></i>
                  </label>
                  <currency-input name="applicationAmountEndOfQuarter"
                    [highlightAsInvalid]="invalidFields['applicationAmountEndOfQuarter']"
                    [disabled]="lockFields['applicationAmountEndOfQuarter']"
                    [placeholder]="'$1000'"
                    [(ngModel)]="values.applicationAmountEndOfQuarter"></currency-input>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('applicationAmountEndOfQuarter')" class="btn btn-sm">
                      <span [title]="
                          lockFields['applicationAmountEndOfQuarter']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['applicationAmountEndOfQuarter']
                              ? '-open'
                              : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="lienType">Lien Type
                    <i [title]="invalidFields['lienType']" *ngIf="invalidFields['lienType']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="lienType" name="lienType" class="form-select" placeholder="Lien Type"
                    [ngClass]="{'is-invalid' : invalidFields['lienType']}" [disabled]="lockFields['lienType']"
                    [(ngModel)]="values.lienType">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let type of lienTypes" [ngValue]="type.value">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('lienType')" class="btn btn-sm">
                      <span [title]="lockFields['lienType'] ? 'Locked' : 'Unlocked'">
                        <i class="fas fa-lock{{
                            !lockFields['lienType'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="mortgageDirection">Mortgage Direction
                    <i [title]="invalidFields['mortgageDirection']" *ngIf="invalidFields['mortgageDirection']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="mortgageDirection" name="mortgageDirection" class="form-select"
                    [ngClass]="{'is-invalid' : invalidFields['mortgageDirection']}" placeholder="Mortgage Direction"
                    [disabled]="lockFields['mortgageDirection']" [(ngModel)]="values.mortgageDirection">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let direction of mortgageDirections" [ngValue]="direction.value">
                      {{ direction.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('mortgageDirection')" class="btn btn-sm">
                      <span [title]="
                          lockFields['mortgageDirection']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['mortgageDirection'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="brokerFeesCollected">Broker Fees Collected
                    <i [title]="invalidFields['brokerFeesCollected']" *ngIf="invalidFields['brokerFeesCollected']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <currency-input name="brokerFeesCollected" [disabled]="lockFields['brokerFeesCollected']"
                    [highlightAsInvalid]="invalidFields['brokerFeesCollected']"
                    [(ngModel)]="values.brokerFeesCollected">
                  </currency-input>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('brokerFeesCollected')" class="btn btn-sm">
                      <span [title]="
                          lockFields['brokerFeesCollected']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['brokerFeesCollected'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="lenderFeesCollected">Lender Fees Collected
                    <i [title]="invalidFields['lenderFeesCollected']" *ngIf="invalidFields['lenderFeesCollected']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <currency-input name="lenderFeesCollected" [disabled]="lockFields['lenderFeesCollected']"
                    [highlightAsInvalid]="invalidFields['lenderFeesCollected']"
                    [(ngModel)]="values.lenderFeesCollected">
                  </currency-input>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('lenderFeesCollected')" class="btn btn-sm">
                      <span [title]="
                          lockFields['lenderFeesCollected']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['lenderFeesCollected'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="reverseMortgageType">Reverse Mortgage Type
                    <i [title]="invalidFields['reverseMortgageType']" *ngIf="invalidFields['reverseMortgageType']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="reverseMortgageType" name="reverseMortgageType" class="form-select"
                    placeholder="Reverse Mortgage Type" [disabled]="lockFields['reverseMortgageType']"
                    [ngClass]="{'is-invalid' : invalidFields['reverseMortgageType']}"
                    [(ngModel)]="values.reverseMortgageType">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let type of reverseMortgageTypes" [ngValue]="type.value">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('reverseMortgageType')" class="btn btn-sm">
                      <span [title]="
                          lockFields['reverseMortgageType']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['reverseMortgageType'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="reverseMortgagePurpose">Reverse Mortgage Purpose
                    <i [title]="invalidFields['reverseMortgagePurpose']" *ngIf="invalidFields['reverseMortgagePurpose']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="reverseMortgagePurpose" name="reverseMortgagePurpose" class="form-select"
                    placeholder="Reverse Mortgage Purpose" [disabled]="lockFields['reverseMortgagePurpose']"
                    [ngClass]="{'is-invalid' : invalidFields['reverseMortgagePurpose']}"
                    [(ngModel)]="values.reverseMortgagePurpose">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let purpose of reverseMortgagePurposes" [ngValue]="purpose.value">
                      {{ purpose.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('reverseMortgagePurpose')" class="btn btn-sm">
                      <span [title]="
                          lockFields['reverseMortgagePurpose']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['reverseMortgagePurpose'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="qualifiedMortgageType">Qualified Mortgage Type
                    <i [title]="invalidFields['qualifiedMortgageType']" *ngIf="invalidFields['qualifiedMortgageType']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <select id="qualifiedMortgageType" name="qualifiedMortgageType" class="form-select"
                    placeholder="Qualified Mortgage Type" [disabled]="lockFields['qualifiedMortgageType']"
                    [ngClass]="{'is-invalid' : invalidFields['qualifiedMortgageType']}"
                    [(ngModel)]="values.qualifiedMortgageType">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let type of qualifiedMortgageTypes" [ngValue]="type.value">
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('qualifiedMortgageType')" class="btn btn-sm">
                      <span [title]="
                          lockFields['qualifiedMortgageType']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['qualifiedMortgageType'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="mortgageServicingDisposition">Mortgage Servicing Disposition
                    <i [title]="invalidFields['mortgageServicingDisposition']"
                      *ngIf="invalidFields['mortgageServicingDisposition']" class="fa fa-exclamation-circle"
                      style="color: red"></i>
                  </label>
                  <select id="mortgageServicingDisposition" name="mortgageServicingDisposition" class="form-select"
                    [ngClass]="{'is-invalid' : invalidFields['mortgageServicingDisposition']}"
                    placeholder="Mortgage Servicing Disposition" [disabled]="lockFields['mortgageServicingDisposition']"
                    [(ngModel)]="values.mortgageServicingDisposition">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let disposition of mortgageServicingDispositions" [ngValue]="disposition.value">
                      {{ disposition.name }}
                    </option>
                  </select>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('mortgageServicingDisposition')" class="btn btn-sm">
                      <span [title]="
                          lockFields['mortgageServicingDisposition']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['mortgageServicingDisposition']
                              ? '-open'
                              : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="card">
              <div class="card-header">
                Pipeline Positions
                <i [title]="invalidFields['pipelinePositions']" *ngIf="invalidFields['pipelinePositions']"
                          class="fa fa-exclamation-circle" style="color: red"></i>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3 px-0" *ngFor="let pPosition of pipelinePositions">
                    <div class="row mx-1">
                      <label class="text-truncate" style="max-width: 270px !important;" title="{{pPosition.name}}" for="{{pPosition.value}}">{{pPosition.name}}?
                      </label>
                    </div>
                    <div class="row border mx-1 mb-2" style="height: 33.5px; border-radius: 0.5rem !important">
                      <div class="col-11 d-flex align-items-center justify-content-start">
                        <input id="{{pPosition.value}}" class="form-check-input"
                          style="width: 18px; height: 18px; margin-top: -2px"
                          [ngClass]="{'is-invalid' : invalidFields['pipelinePositions.'+pPosition.value]}" name="{{pPosition.value}}"
                          type="checkbox" [disabled]="lockFields['pipelinePositions.'+pPosition.value]"
                          [(ngModel)]="values.pipelinePositions[pPosition.value]" />
                      </div>
                      <div class="col-1 d-flex align-items-center justify-content-start">
                        <button (click)="lockUnlockEdit('pipelinePositions.'+pPosition.value)" class="btn btn-sm"
                          style="margin-left: -20px">
                          <span [title]="lockFields['pipelinePositions.'+pPosition.value] ? 'Locked' : 'Unlocked'">
                            <i class="fas fa-lock{{
                                  !lockFields['pipelinePositions.'+pPosition.value] ? '-open' : ''
                                }}" style="color: orange"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-start align-items-center w-100">
        <span class="fw-bold">Branch Profitability</span>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group">
          <div class="row mb-4">
            <!-- branch gross profit -->
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="branchGrossProfit">Branch Profit
                    <i [title]="invalidFields['branchGrossProfit']" *ngIf="invalidFields['branchGrossProfit']"
                      class="fa fa-exclamation-circle" style="color: red"></i>
                  </label>
                  <currency-input name="branchGrossProfit" [disabled]="lockFields['branchGrossProfit']"
                    [highlightAsInvalid]="invalidFields['branchGrossProfit']"
                    [(ngModel)]="values.branchGrossProfit"></currency-input>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('branchGrossProfit')" class="btn btn-sm">
                      <span [title]="
                          lockFields['branchGrossProfit']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['branchGrossProfit'] ? '-open' : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- LO Compensation -->
            <div class="col">
              <div class="row">
                <div class="col-11">
                  <label for="loanOfficerCompensation">LO Compensation
                    <i [title]="invalidFields['loanOfficerCompensation']"
                      *ngIf="invalidFields['loanOfficerCompensation']" class="fa fa-exclamation-circle"
                      style="color: red"></i>
                  </label>
                  <currency-input name="loanOfficerCompensation" [disabled]="lockFields['loanOfficerCompensation']"
                    [highlightAsInvalid]="invalidFields['loanOfficerCompensation']"
                    [(ngModel)]="values.loanOfficerCompensation"></currency-input>
                </div>
                <div class="col-1">
                  <div class="h-100 d-flex align-items-end justify-content-center">
                    <button (click)="lockUnlockEdit('loanOfficerCompensation')" class="btn btn-sm">
                      <span [title]="
                          lockFields['loanOfficerCompensation']
                            ? 'Locked'
                            : 'Unlocked'
                        ">
                        <i class="fas fa-lock{{
                            !lockFields['loanOfficerCompensation']
                              ? '-open'
                              : ''
                          }}" style="color: orange"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!--  -->
            <div class="col">
              <div class="row">
                <div class="col-11"></div>
                <div class="col-1"></div>
              </div>
            </div>

            <!--  -->
            <div class="col">
              <div class="row">
                <div class="col-11"></div>
                <div class="col-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="text-end">
  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="save()">
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Saving
    </span>
  </button>
</div>
