import { AppraisalCondition } from "./appraisal-condition.model";
import { AppraisalDocument } from "./appraisal-document.model";
import { AppraisalNote } from "./appraisal-note.model";
import { AppraisalRequestingContact } from "./appraisal-requesting-contact.model";
import { AppraisalThirdPartyContact } from "./appraisal-third-party-contact.model";

export class AppraisalOrderView {
    appraisalOrderId: number;
    applicationId: number;
    loanDocTaskId: number;
    paymentInformationLoanDocTaskId: number;
    documentLoanDocId: number;
    thirdPartyOrderId: string;
    orderStatus: string;
    requestedDate: string;
    assignedAmcOrAppraiser?: string; // field comming from db
    amcOrAppraiserIdToAssign?: string;// field expected in save/update request
    reportDeliveryDate: string;
    targetDueDate: string;
    appraisalForms: string[];
    isRushOrder: boolean
    messages: string[];
    borrowerBalanceDue: number;
    requestingContacts: AppraisalRequestingContact[];
    thirdPartyContact: AppraisalThirdPartyContact;
    notes: AppraisalNote[];
    documents: AppraisalDocument[];
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;
    conditions?: AppraisalCondition[];
}