import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { CreateFeeModel } from '../../../../models/fee/create-fee.model';
import { FeeService } from '../../../../services/fee.service';
import { FeeTypeEnum } from '../../../../models/fee/fee-type.enum';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { CalculatedFeeTypeEnum } from 'src/app/models/fee/calculated-fee-type.enum';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import * as _ from 'lodash';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'create-fee-modal',
  templateUrl: 'create-fee-modal.component.html'
})
export class CreateFeeModalComponent implements OnInit {

  @ViewChild('createFeeForm') createFeeForm: NgForm | undefined;

  @Input() title: string;
  @Input() allowFreeformFeeEntry: boolean;
  @Input() feeSection: FeeSectionEnum;
  @Input() feeType: FeeTypeEnum;
  @Input() calculatedFeeType: CalculatedFeeTypeEnum;
  @Input() restrictHudNumberEntryBySectionType: boolean = false;
  @Input() hudNumbersToExclude: string[] = [];
  @Input() feeTypesToExclude: string[] = [];

  @Input()
  feeSystemDetails: FeeSystemDetails;

  fee: CreateFeeModel = new CreateFeeModel();
  hudNumberDisabled = false;

  hudNumbeRestrictionMaskConfig: HudNumberRestrictionMaskConfig = null;
  hudNumberMask: string = null;
  hudNumberPrefix: string = null;

  availableFeeTypes: EnumerationItem[] = [];

  isExistHud: boolean = false;

  private _feeTypes: EnumerationItem[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumerationService,
    private readonly _feeService: FeeService) {
  }

  ngOnInit() {
    let tmpFee = new LoanFee();
    tmpFee.feeType = this.feeType;
    if (this._feeService.isRealEstateFee(tmpFee)) {
      this.fee.hudNumber = "704";
      this.hudNumberDisabled = true;
    }
    if (this.feeSection === FeeSectionEnum.Prepaids) {
      this.hudNumbeRestrictionMaskConfig = this.getHudNumberRestrictionMaskConfigForPrepaid();
    } else if (this.feeSection === FeeSectionEnum.Origination) {
      this.hudNumbeRestrictionMaskConfig = this.getHudNumberRestrictionMaskConfigForOriginationCharges();
    } else if (this.feeSection === FeeSectionEnum.Other) {
      this.hudNumbeRestrictionMaskConfig = this.getHudNumberRestrictionMaskConfigForOtherFees();
    }
    const observer = {
      next: (enums => {
        this._feeTypes = enums[Constants.feeEnumerations.feeType];
        this.populateFeeTypesDropdown();
      }),
      error: (error => {
      })
    }
    this._enumsService.getFeeEnumerations().subscribe(observer);
  }

  onSelectedFeeTypeChanged = () => {
    const feeTypeInfo = this.feeSystemDetails.feeTypes.find(feeType => feeType.feeType === this.feeType);
    if (feeTypeInfo) {
      this.fee.hudNumber = feeTypeInfo.hudNumber;
      this.fee.feeName = feeTypeInfo.name;
    }
  }

  onOkClicked() {
    if (this.createFeeForm) {
      this.createFeeForm.form.markAllAsTouched();
      if (this.createFeeForm.form.valid && !this.isExistHud) {
        this.fee.feeSection = (this.feeSection === FeeSectionEnum.RealEstateCommission ? FeeSectionEnum.Other : this.feeSection);
        this.fee.calculatedFeeType = this.calculatedFeeType;
        this.fee.feeType = this.feeType;
        this.activeModal.close(this.fee);
      }
    }
  }

  onHudNumberChanged = () => {
    this.isExistHud = false;
    let matchedHudNo = this.hudNumbersToExclude.find(hudNo =>  this.fee.hudNumber && hudNo == this.fee.hudNumber);
    if(matchedHudNo){
      this.isExistHud = true;
    }
  }

  private populateFeeTypesDropdown = () => {
    const feeSection = this.feeSection === FeeSectionEnum.RealEstateCommission ? FeeSectionEnum.Other : this.feeSection;
    const feeMetaDataForSection = this.feeSystemDetails.feeSections.find(section => section.feeSection === feeSection);
    if (feeMetaDataForSection) {

      const allAvailableFeeTypesForSection = this.feeSystemDetails.feeTypes.filter(feeType => feeMetaDataForSection.availableFeeTypes.includes(feeType.feeType));

      const unusedFeeTypes = allAvailableFeeTypesForSection
        .filter(feeType => (!feeType.hudNumber || !this.hudNumbersToExclude.includes(feeType.hudNumber)) && (!feeType.feeType || !this.feeTypesToExclude.includes(feeType.feeType)))
        .map(feeType => feeType.feeType);

      this.availableFeeTypes = this._feeTypes.filter(feeType => unusedFeeTypes.includes(feeType.value));
      if (this.feeSection === FeeSectionEnum.RealEstateCommission) {
        this.availableFeeTypes = this.availableFeeTypes.filter(feeType => this._feeService.getRealEstateFeeTypes().includes(feeType.value));
      } else if (this.feeSection === FeeSectionEnum.Other) {
        this.availableFeeTypes = this.availableFeeTypes.filter(feeType => !this._feeService.getRealEstateFeeTypes().includes(feeType.value));
      }
    }
  }

  private getHudNumberRestrictionMaskConfigForOtherFees = (): HudNumberRestrictionMaskConfig => {
    const hudNumberRestrictionMask = new HudNumberRestrictionMaskConfig();
    hudNumberRestrictionMask.mask = "AA";
    hudNumberRestrictionMask.prefix = "18";
    hudNumberRestrictionMask.minLength = 4;
    hudNumberRestrictionMask.patterns = {
      A: {
        pattern: new RegExp("[0-9]")
      }
    };
    return hudNumberRestrictionMask;
  }

  private getHudNumberRestrictionMaskConfigForPrepaid = (): HudNumberRestrictionMaskConfig => {
    const hudNumberRestrictionMask = new HudNumberRestrictionMaskConfig();
    hudNumberRestrictionMask.mask = "AAB";
    hudNumberRestrictionMask.prefix = "9";
    hudNumberRestrictionMask.minLength = 3;
    hudNumberRestrictionMask.patterns = {
      A: {
        pattern: new RegExp("[0-9]")
      },
      B: {
        pattern: new RegExp("[a-z]"),
        optional: true
      }
    };
    return hudNumberRestrictionMask;
  }

  private getHudNumberRestrictionMaskConfigForOriginationCharges = (): HudNumberRestrictionMaskConfig => {
    const hudNumberRestrictionMask = new HudNumberRestrictionMaskConfig();
    hudNumberRestrictionMask.mask = "AAB";
    hudNumberRestrictionMask.prefix = "8";
    hudNumberRestrictionMask.minLength = 3;
    hudNumberRestrictionMask.patterns = {
      A: {
        pattern: new RegExp("[0-9]")
      },
      B: {
        pattern: new RegExp("[a-z]"),
        optional: true
      }
    };
    return hudNumberRestrictionMask;
  }
}

export class HudNumberRestrictionMaskConfig {
  mask: string = null;
  prefix: string = null;
  patterns: any = null;
  minLength: number | null = null;
}
