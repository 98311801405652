import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { DisclosureDocSetAccordionComponent } from './components/disclosure-doc-set-accordion/disclosure-doc-set-accordion.component';
import { DisclosureMessageComponent } from './components/disclosure-message/disclosure-message.component';
import { DisclosuresWizardComponent } from './components/disclosures-wizard/disclosures-wizard.component';
import { PreCheckDisclosureWizardDialogComponent } from './components/pre-check-disclosure-wizard-dialog/pre-check-disclosure-wizard-dialog.component';
import { DisclosureAuditResultsComponent } from './components/disclosure-audit-results/disclosure-audit-results.component';
import { DisclosureTrackingModule } from '../../disclosure-tracking/disclosure-tracking.module';

@NgModule({
    imports: [SharedModule, TableModule, DisclosureTrackingModule],
    declarations: [
        DisclosureDocSetAccordionComponent,
        DisclosureMessageComponent,
        DisclosuresWizardComponent,
        PreCheckDisclosureWizardDialogComponent,
        DisclosureAuditResultsComponent
    ],
    exports: [
        DisclosureDocSetAccordionComponent,
        DisclosureMessageComponent,
        DisclosureAuditResultsComponent,
        DisclosuresWizardComponent
    ],
    providers: []
})
export class DisclosuresModule {
  constructor() {}
}
