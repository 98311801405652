import { Component, Input } from '@angular/core';
import { WireRequestModel } from '../../../models/wire-request.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  templateUrl: 'create-wire-request-loan.component.html',
  selector: 'create-wire-request-loan',
  viewProviders: [formViewProvider]
})
export class CreateWireRequestLoan {
  @Input()
  wireRequest: WireRequestModel;

  @Input()
  amortizationTypes: EnumerationItem[];

  @Input()
  appraisalTypes: EnumerationItem[];

  @Input()
  documentationTypes: EnumerationItem[];

  @Input()
  loanTypes: EnumerationItem[];

  @Input()
  propertyTypes: EnumerationItem[];

  @Input()
  rateTypes: EnumerationItem[];

  @Input()
  occupancies: EnumerationItem[];

  @Input()
  lienPositionTypes: EnumerationItem[];

  constructor() {}
}
