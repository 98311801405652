<div class="modal-header">
  <h5 class="modal-title w-100">
    Checklist
  </h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <div *ngIf="questionsLoading || savingAnswer">
    <loading-indicator [loadingMessage]="questionsLoading ? 'Loading Checklist...' : 'Saving Answer...'"
      [customClass]="'no-shadow'"> </loading-indicator>
  </div>
  <div *ngIf="!questionsLoading && !savingAnswer">
    <div *ngIf="checklistQuestions.length === 0" class="m-3 text-center">
      there are no questions associated with this checklist.
    </div>
    <div *ngIf="showSummary">
      <div class="p-2" *ngFor="let summaryItem of summary">
        <label *ngIf="summaryItem.borrowerDisplayName" class="mb-1">
          Borrower: {{ summaryItem.borrowerDisplayName }}
        </label>
        <label class="fw-bold d-block mb-2" for="selectedAnswers">
          <em *ngIf="summaryItem.questionType === 'TriggerOnly'">[Trigger Question] </em>
          {{ summaryItem.questionText }}
        </label>
        <div *ngIf="summaryItem.questionType !== 'TriggerOnly'">
          <label class="mb-1" for="selectedAnswers"> Answer:</label>
          {{ getAnswerText(summaryItem) }}
        </div>
      </div>
    </div>
    <form *ngIf="checklistQuestions.length > 0 && !showSummary" id="questionEvalForm" #questionEvalForm="ngForm"
      name="questionEvalForm">
      <div *ngIf="currentQuestion" class="p-2">
        <label *ngIf="currentQuestion.borrowerDisplayName" class="fw-bold d-block mb-2" for="borrowerName">
          Borrower: {{ currentQuestion.borrowerDisplayName }}
        </label>
        <label class="fw-bold d-block mb-2" for="selectedAnswers">
          {{ currentQuestion.questionText }}
        </label>
        <label class="mb-1" for="selectedAnswers"> Answer:</label>
        <div [ngSwitch]="currentQuestion.questionType">
          <div *ngSwitchCase="'MultiSelect'">
            <ng-select width="100%" name="selectedAnswers" bindLabel="answerText" bindValue="questionAnswerId"
              class="custom-ng-select" placeholder="Select one or more options" [closeOnSelect]="false"
              [items]="currentQuestion.answers" #answerField="ngModel" required [(ngModel)]="currentQuestion.userAnswer"
              multiple="true" [ngClass]="{'is-invalid': answerField && answerField.touched && answerField.invalid}"
              appendTo="body">
            </ng-select>
          </div>

          <div *ngSwitchCase="'SelectList'">
            <select name="selectedAnswers" class="form-select"
              [ngClass]="{'is-invalid': answerField && answerField.touched && answerField.invalid}"
              [(ngModel)]="currentQuestion.userAnswer" #answerField="ngModel" required>
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let answer of currentQuestion.answers" [ngValue]="answer.questionAnswerId">
                {{ answer.answerText }}
              </option>
            </select>
          </div>

          <div *ngSwitchCase="'Value'">
            <input type="text" class="form-control" name="selectedAnswers"
              [ngClass]="{'is-invalid': answerField && answerField.touched && answerField.invalid}"
              [(ngModel)]="currentQuestion.userAnswer" #answerField="ngModel" required />
          </div>

          <div *ngSwitchDefault></div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer"
  [class]=" showSummary ? 'justify-content-end' : 'justify-content-between'">
  <div *ngIf="!showSummary">
    <i class="fa fa-info-circle text-primary me-1"></i>
    <span class="text-muted font-size-10">
      Total number of questions are subject to change as per selected answer.
    </span>
  </div>
  <div>
    <span *ngIf="!showSummary" class="text-secondary me-3">{{currentQuestionCount}} of {{totalQuestionsCount}}
      Questions</span>
    <button *ngIf="checklistQuestions.length === 0" type="button" class="btn btn-secondary"
      (click)="activeModal.dismiss()">
      Close
    </button>
    <button [disabled]="savingAnswer" *ngIf="checklistQuestions.length > 0" type="button" class="btn btn-primary"
      (click)="saveAnswerAndProceedToNextQuestion()">
      {{showSummary ? 'Finish' : 'Next'}}
    </button>
  </div>
</div>
