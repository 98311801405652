<div class="row">
    <div class="col-md-6 col-lg-4 mb-3">
        <div class="row">
            <div class="form-group col-xs-12">
                <label for="mortgagePandI">Mortgage P&I</label>
                <currency-input name="mortgagePandI" [readonly]="true"
                    [(ngModel)]="mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
                <label for="hoi">HOI</label>
                <currency-input name="hoi" [readonly]="isHomesiteHack"
                    [(ngModel)]="mortgage.proposedHousingExpense.homeownersInsurance"
                    (ngModelChange)="calculateTotalHousingPayment()">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
                <label for="propertyTaxes">Property Taxes</label>
                <currency-input name="propertyTaxes" [readonly]="isHomesiteHack"
                    [(ngModel)]="mortgage.proposedHousingExpense.countyPropertyTax"
                    (ngModelChange)="calculateTotalHousingPayment()">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
              <label for="supplementalPropertyInsurance">Supplemental Property Insurance</label>
              <currency-input name="supplementalPropertyInsurance" [readonly]="isHomesiteHack"
                  [(ngModel)]="mortgage.proposedHousingExpense.supplementalPropertyInsurance"
                  (ngModelChange)="calculateTotalHousingPayment()">
              </currency-input>
          </div>
            <div class="form-group col-xs-12">
                <label for="mtgInsurance">Mtg Insurance</label>
                <currency-input name="mtgInsurance" [readonly]="isHomesiteHack"
                    [(ngModel)]="mortgage.proposedHousingExpense.mortgageInsurance"
                    (ngModelChange)="calculateTotalHousingPayment()">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
                <label for="hoa">HOA</label>
                <currency-input name="hoa" [readonly]="isHomesiteHack"
                    [(ngModel)]="mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees"
                    (ngModelChange)="calculateTotalHousingPayment()">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
                <label for="otherMortgagePandI">Other Mortgage P&I</label>
                <currency-input name="otherMortgagePandI"
                    [(ngModel)]="mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest"
                    (ngModelChange)="calculateTotalHousingPayment()">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
                <label for="totalHousingPayment">Total Housing Payment</label>
                <currency-input name="totalHousingPayment" [readonly]="true" [(ngModel)]="totalHousingPayment">
                </currency-input>
            </div>
            <div class="form-group col-xs-12">
              <label for="amortizationType">Amortization Type</label>
              <select-input [(ngModel)]="mortgage.mortgageTerm.amortizationType" [options]="amortizationTypes"
                  name="amortizationType" [placeholder]="'Amortization Type'"></select-input>
            </div>
            <div class="form-group col-xs-12">
              <label for="mortgagePurpose">Mortgage Purpose</label>
              <select-input [(ngModel)]="mortgage.subjectProperty.purposeOfLoan" [options]="loanPurposeOptions"
                  name="mortgagePurpose" [placeholder]="'Mortgage Purpose'" (blur)="onMortgagePurposeOfLoanChanged()"></select-input>
            </div>
            <div class="form-group col-xs-12">
              <label for="mortgageType">Mortgage Type</label>
              <select-input [(ngModel)]="mortgage.mortgageTerm.mortgageAppliedFor" [options]="mortgageAppliedForTypes"
                  name="mortgageType" [placeholder]="'Mortgage Type'" (blur)="productTypeChanged()"></select-input>
            </div>
        </div>
    </div>
    <div class="offset-lg-2 col-md-6 col-lg-4 mb-3">
        <div class="row">
            <div class="form-group col-xs-12">
                <label for="loanAmount">Loan Amount</label>
                <currency-input name="loanAmount"
                    [allowDecimals]="false" [(ngModel)]="mortgage.mortgageTerm.amount"
                    (blur)="onBaseLoanAmountChanged()">
                </currency-input>
            </div>

            <div class="form-group col-xs-12">
                <label for="currentInterestRate">Interest Rate</label>
                <percent-input name="currentInterestRate" [(ngModel)]="currentInterestRate"
                    (blur)="onInterestRateChanged()" [rateIsTrueToTextValue]="false">
                </percent-input>
            </div>

            <div class="form-group col-xs-12">
                <label for="noOfMonths">Term (months)</label>
                <input id="noOfMonths" name="noOfMonths" class="form-control" type="number"
                    [(ngModel)]="mortgage.mortgageTerm.noOfMonths"
                    (blur)="onMortgageTermChanged()" />
            </div>

            <div *ngIf="!isAppraisedValueHidden && isRefinance" class="form-group col-xs-12">
                <label for="appraisedValue">Appraised Value</label>
                <currency-input name="appraisedValue" [(ngModel)]="mortgage.mortgageTerm.appraisedValue"
                    (blur)="onAppraisedValueChanged()">
                </currency-input>
            </div>

            <div *ngIf="!isEstimatedValueHidden" class="form-group col-xs-12">
              <label for="estimatedValue">Estimated Value</label>
              <currency-input name="estimatedValue" [(ngModel)]="mortgage.subjectProperty.presentValue"
                  (blur)="onEstimatedValueOrPurchasePriceChanged($event)">
              </currency-input>
            </div>

            <div *ngIf="!isRefinance" class="form-group col-xs-12">
                <label for="purchasePrice">Purchase Price</label>
                <currency-input name="purchasePrice" [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount"
                    (blur)="onEstimatedValueOrPurchasePriceChanged()">
                </currency-input>
            </div>

            <div class="form-group col-xs-12" *ngIf="isRefinance">
                <label for="cashOutAmount">Cash Out Amount</label>
                <currency-input name="cashOutAmount" [(ngModel)]="application.cashOutAmount"></currency-input>
            </div>
            <div class="form-group col-xs-12" *ngIf="!isRefinance">
                <label for="downAmount">Down Amount</label>
                <currency-input name="downAmount" [(ngModel)]="downPayment" [allowNegative]="true"
                    (blur)="onDownpaymentAmountChanged()"></currency-input>
            </div>

            <div class="form-group col-xs-12">
                <label for="totalLoanAmount">Total Loan Amount</label>
                <currency-input name="totalLoanAmount" [readonly]="true" [(ngModel)]="totalLoanAmount">
                </currency-input>
            </div>
            <div class="form-group col-xs-6">
                <label for="ltv">LTV</label>
                <percent-input name="ltv" [readonly]="true" [(ngModel)]="ltv" [rateIsTrueToTextValue]="true"></percent-input>
            </div>
            <div class="form-group col-xs-6">
                <label for="cltv">CLTV</label>
                <percent-input name="cltv" [readonly]="true" [(ngModel)]="cltv" [rateIsTrueToTextValue]="true"></percent-input>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 mb-3">
        <div class="mb-2 d-flex justify-content-between">
            <div class="d-flex">
                <h5>
                    <strong> Other Liens on the Mortgage </strong>
                </h5>
                <a class="my-auto ms-3" (click)="showOtherReo = !showOtherReo;">{{showOtherReo ?
                    'Hide' : 'Show'}}</a>
            </div>
            <div [hidden]="!showOtherReo">
                <button class="btn btn-sm btn-primary me-2" role="button" (click)="addRelatedMortgage()">
                    <i class="fas fa-plus me-2"></i>Add Other Liens
                </button>
            </div>

        </div>
        <div class="row" [hidden]="!showOtherReo">
            <div class="table-responsive" style="border-bottom: none;">
                <table class="table">
                    <thead class="table-light">
                        <tr>
                            <th class="col-2"> Creditor </th>
                            <th class="col-2"> Lien Type </th>
                            <th class="col-2"> FHA Source </th>
                            <th class="col-1"> MO PMT </th>
                            <th class="col-2"> Loan Amt/Draw </th>
                            <th class="col-1"> Credit Limit </th>
                            <th class="col-1"> HELOC </th>
                            <th class="col-1 text-center"> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let relatedMortgage of mortgage.relatedMortgages; let index = index;"
                            style="height: 50px;">
                            <td class="col-2">
                                <input class="form-control" name="creditorName_{{index}}"
                                    [(ngModel)]="relatedMortgage.creditorName">
                            </td>

                            <td class="col-2">
                                <select id="lienType_{{index}}" name="lienType_{{index}}" class="form-select"
                                    [(ngModel)]="relatedMortgage.lienType">
                                    <option [ngValue]="null">--Select One--</option>
                                    <option *ngFor="let lienPositionType of lienPositionTypes"
                                        [ngValue]="lienPositionType.value">
                                        {{ lienPositionType.name }}
                                    </option>
                                </select>
                            </td>

                            <td class="col-2">
                                <select id="fhaSource_{{index}}" name="fhaSource_{{index}}" class="form-select"
                                    [(ngModel)]="relatedMortgage.fundsSource">
                                    <option [ngValue]="null">--Select One--</option>
                                    <option *ngFor="let giftGrantSource of giftGrantSources"
                                        [ngValue]="giftGrantSource.value">
                                        {{ giftGrantSource.name }}
                                    </option>
                                </select>
                            </td>

                            <td class="col-1">
                                <currency-input name="moPmt_{{index}}" [(ngModel)]="relatedMortgage.monthlyPayment">
                                </currency-input>
                            </td>

                            <td class="col-2">
                                <currency-input name="loanOrDrawAmount_{{index}}"
                                    [(ngModel)]="relatedMortgage.loanOrDrawAmount">
                                </currency-input>
                            </td>

                            <td class="col-1">
                                <currency-input name="creditLimit_{{index}}" [(ngModel)]="relatedMortgage.creditLimit">
                                </currency-input>
                            </td>

                            <td class="col-1">
                                <select id="isHeloc_{{index}}" name="isHeloc_{{index}}" class="form-select"
                                    [(ngModel)]="relatedMortgage.isHeloc">
                                    <option [ngValue]="null">--Select One--</option>
                                    <option *ngFor="let option of yesNoOptions" [ngValue]="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </td>


                            <td class="col-1 text-center">
                                <a href="javascript: void(0);" (click)="deleteRelatedMortgage(index)">
                                    <i class="fa fa-trash-alt"></i>
                                </a>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
