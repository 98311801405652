import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user/user.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { AgentsService } from '../../services/agents.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UpdateAction } from 'src/app/modules/leads/models/bulk-update-lead.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'agents-bulk-assign-contact-dialog',
  templateUrl: './agents-bulk-assign-contact-dialog.component.html',
  styleUrls: ['./agents-bulk-assign-contact-dialog.component.scss']
})
export class AgentsBulkAssignContactDialogComponent implements OnInit {

  @Input() agentIds: number[] = [];

  users: User[] = [];
  selectedUser: string = null;

  isSaving: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _contextService: ApplicationContextService,
    private readonly _agentsService: AgentsService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this._contextService.context.subscribe(context => {
      this.users = context.globalConfig.users.map(user => {
        return {
          ...user,
          userFullName: Utils.getPersonsDisplayName(user)
        }
      });
    });
  }

  save = (): void => {

    let matchedUser = this.users.find(u => u.userCompanyGuid == this.selectedUser);

    this.isSaving = true;

    let request = {
      agentIds: this.agentIds,
      updates: [
        {
          propertyName: "AgentContactUserId",
          value: this.selectedUser,
          action: UpdateAction.Set
        }
      ]
    };

    this._agentsService.bulkUpdateAgent(request)
      .subscribe({
        next: (agents) => {
          this.isSaving = false;
          this._notificationService.showSuccess("Your information has been saved successfully.", "Success!");

          this.activeModal.close(matchedUser);
        },
        error: (err) => {
          this.isSaving = false;
          this._notificationService.showError(err ? err.message || err : '', "Error");
        }
      })
  }

}

