import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MentionsResponse } from '../models/mention.model';

@Injectable()
export class MentionsService {

  get events(): BehaviorSubject<MentionEvent> {
    return this._eventTracker;
  }

  publish(event: MentionEvent): void {
    this._eventTracker.next(event);
  }

  private _eventTracker = new BehaviorSubject<MentionEvent>(null);

  constructor(private readonly _dataService: DataService) { }

  getMentions = (pageInfo: { pageNumber: number, pageSize: number, forRead: boolean } = null): Observable<MentionsResponse> => {
    let url = "api/common/mentions";
    if (pageInfo) {
      url = `api/common/mentions?pageNumber=${pageInfo.pageNumber}&pageSize=${pageInfo.pageSize}`;
      url += pageInfo.forRead ? `&filterByReadStatus=Read` : `&filterByReadStatus=Unread`;
    }

    return this._dataService.get(url);
  }

  getMentionsCount = (): Observable<Omit<MentionsResponse, "mentions">> => {
    return this._dataService.get(`api/common/mentionsCount`);
  }
}

export class MentionEvent {
  type: "reload"
}
