<div class="modal-header">
  <h5 class="modal-title"> Terms & Conditions </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body" style="max-height: 550px;" id="sectionConvertedToPDF">
  <div id="left" class="terms-and-conditions">
    <h3 style="text-align: center;">WEB SITE TERMS-OF-USE AGREEMENT</h3>
    <p><br /><strong style="font-size: 13px;">LODASOFT WELCOMES YOU TO <a href="https://{{hostUrl}}">{{hostUrl |
          uppercase}}</a>. WE ASK THAT YOU READ THE
        FOLLOWING TERMS OF USE,
        WHICH CONSTITUTE A LICENSE THAT COVERS YOUR USE OF THIS SITE AND ANY TRANSACTIONS THAT YOU
        ENGAGE IN THROUGH THIS SITE ("AGREEMENT"). BY ACCESSING, VIEWING, OR USING THIS SITE, YOU
        ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE WITH THESE TERMS. IF YOU DO NOT WISH
        TO BE BOUND BY THESE TERMS, PLEASE DO NOT USE THIS SITE.</strong></p>

    <h4 class="m-0"><strong>USE OF SITE</strong></h4>
    <p>&nbsp; &nbsp; &nbsp; This Web site is provided solely for the use of current and future customers
      of LODASOFT to provide you with information about our company, to permit you to use and
      participate in the services of the site, and to enable you to contact us with any questions or
      comments that you may have. Any other use of this site is prohibited. By way of example, you
      should not use any features of this site that permit communications or postings to post,
      transmit, display, or otherwise communicate:</p>
    <ol>
      <li>
        <p class="m-0">Any defamatory, threatening, obscene, harassing, or otherwise unlawful information;</p>
      </li>
      <li>
        <p class="m-0">Any advertisement, solicitation, spam, chain letter, or other similar type of information;</p>
      </li>
      <li>
        <p class="m-0">any encouragement of illegal activity;</p>
      </li>
      <li>
        <p class="m-0">Unauthorized use or disclosure of private, personally identifiable information of others; or</p>
      </li>
      <li>
        <p class="m-0">Any materials subject to trademark, copyright, or other laws protecting any materials or
          data of others in the absence of a valid license or other right to do so.</p>
      </li>
    </ol>
    <h4 class="m-0"><strong>SITE CONTENTS AND OWNERSHIP</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;The information contained on this site, including all images, designs,
      photographs, writings, graphs, data, and other materials ("Materials") are the property of
      LODASOFT and are protected by copyrights, trademarks, trade secrets, or other proprietary
      rights. Permission is granted to display, copy, distribute, download, and print portions of this
      site solely for the purposes of using this site for the authorized uses described above. You
      must retain all copyright and other proprietary notices on all copies of the Contents. You shall
      comply with all copyright laws worldwide in your use of this Web site and prevent unauthorized
      copying of the Contents. Except as provided in this Notice, LODASOFT does not grant you any
      express or implied right in or under any patents, trademarks, copyrights, or trade secret
      information.</p>
    <h4 class="m-0"><strong>DISCLAIMER OF WARRANTY</strong></h4>
    <p>&nbsp; &nbsp; &nbsp; You expressly agree that use of this Web site is at your sole risk. Neither
      LODASOFT, its affiliates, nor any of their officers, directors, employees, agents, third-party
      content providers, or licensors (collectively, "Providers"), or the like, warrant that this site
      will be uninterrupted or error-free; nor do they make any warranty as to the results that may be
      obtained from the use of this site, or as to the accuracy, completeness, reliability, security,
      or currency of the Materials.</p>
    <p>&nbsp; &nbsp; &nbsp;The Materials may contain errors, omissions, inaccuracies, or outdated
      information. Further, LODASOFT does not warrant reliability of any statement or other
      information displayed or distributed through the site. LODASOFT reserves the right, in its sole
      discretion, to correct any errors or omissions in any portion of the site. LODASOFT may make any
      other changes to this site, the Materials and the products, programs, services, or prices (if
      any) described in this site at any time without notice.</p>
    <p>&nbsp; &nbsp; &nbsp; LODASOFT is not responsible for any use to which the
      information you post on this site is put by anyone viewing it. LODASOFT is not the agent or
      representative of any user of the site.</p>
    <p>&nbsp; &nbsp; &nbsp; THIS SITE AND THE INFORMATION, CONTENT, AND MATERIALS ON THIS SITE ARE
      PROVIDED ON AN "AS IS," "WHERE IS," AND "WHERE AVAILABLE" BASIS. LODASOFT MAKES NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE,
      THE CONTENT, INFORMATION, OR THE MATERIALS ON THIS SITE. TO THE FULLEST EXTENT PERMISSIBLE UNDER
      APPLICABLE LAW, LODASOFT EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND,
      WITH RESPECT TO ANY OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY GOODS OR OTHER
      PRODUCTS OR SERVICES OFFERED, SOLD, OR DISPLAYED ON THIS SITE OR YOUR USE OF THIS SITE
      GENERALLY, INCLUDING WARRANTIES OF MERCHANTABILITY, ACCURACY OF INFORMATION, QUALITY, TITLE,
      FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW
      THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE
      EXTENT SUCH JURISDICTION'S LAW APPLIES TO THIS AGREEMENT.</p>
    <h4 class="m-0"><strong>LIMITATION OF LIABILITIES</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;YOU AGREE THAT LODASOFT AND ITS PROVIDERS SHALL NOT BE LIABLE FOR ANY DAMAGE,
      LOSS, OR EXPENSE OF ANY KIND ARISING OUT OF OR RESULTING FROM YOUR POSSESSION OR USE OF THE
      MATERIALS, CONTENT, OR INFORMATION ON THIS SITE REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN
      TORT, CONTRACT, OR OTHERWISE. IN NO EVENT, INCLUDING, WITHOUT LIMITATION, A NEGLIGENT ACT, SHALL
      LODASOFT OR ANY OF ITS PROVIDERS BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, LOSS OR
      CORRUPTION OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR
      INTERRUPTION OF BUSINESS), ARISING OUT OF OR IN ANY WAY RELATED TO THE MATERIALS, CONTENT, OR
      INFORMATION ON THIS SITE OR ANY OTHER PRODUCTS, SERVICES, OR INFORMATION OFFERED, SOLD, OR
      DISPLAYED ON THIS SITE, YOUR USE OF, OR INABILITY TO USE, THIS SITE GENERALLY, OR OTHERWISE IN
      CONNECTION WITH THIS AGREEMENT, REGARDLESS OF WHETHER LODASOFT OR ANY OF ITS PROVIDERS HAVE BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES DO NOT ALLOW THE LIMITATION OF
      LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
    </p>
    <h4 class="m-0"><strong>INDEMNIFICATION</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;You agree to indemnify, defend, and hold harmless LODASOFT, its affiliates,
      agents, employees, and licensors from and against any and all claims and expenses, including
      reasonable attorney fees, arising out of or related in any way to your use of the site,
      violation of this Agreement, violation of any law or regulation, or violation of any proprietary
      or privacy right.</p>
    <h4 class="m-0"><strong>PRIVACY POLICY</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;Click <a href="/#/privacy-and-policy" target="_blank">here</a> to access
      LODASOFT's Privacy Policy governing the use of
      information that LODASOFT obtains from you through your use of this Web site.</p>
    <h4 class="m-0"><strong>LIMITATIONS ON CLAIM</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;Any cause of action you may have with respect to your use of this site must
      be commenced within one (1) year after the claim or cause of action arises.</p>
    <h4 class="m-0"><strong>TERM AND TERMINATION</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;Without limiting its other remedies, LODASOFT may immediately discontinue,
      suspend, terminate, or block your and any user's access to this site at any time in our sole
      discretion.</p>
    <h4 class="m-0"><strong>HYPERLINK DISCLAIMERS</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;As a convenience to you, we may provide on this site links to Web sites
      operated by other entities (collectively the "Linked Sites"). If you use any Linked Sites, you
      will leave this site. If you decide to visit any Linked Site, you do so at your own risk and it
      is your responsibility to take all protective measures to guard against viruses or other
      destructive elements. Linked Sites, regardless of the linking form (e.g., hotlinks, hypertext
      links, IMG links) are not maintained, controlled, or otherwise governed by LODASOFT. The
      content, accuracy, opinions expressed, and other links provided by Linked Sites are not
      investigated, verified, monitored, or endorsed by LODASOFT. LODASOFT does not endorse, make any
      representations regarding or warrant any information, goods, and/or services appearing and/or
      offered on any Linked Site, other than linked information authored by LODASOFT. Links do not
      imply that LODASOFT or this site sponsors, endorses, is affiliated or associated with, or is
      legally authorized to use any trademark, trade name, logo, or copyright symbol displayed in or
      accessible through the links, or that any Linked Site is authorized to use any trademark, trade
      name, logo or copyright symbol of LODASOFT or any of its affiliates or subsidiaries. Except for
      links to information authored by LODASOFT, LODASOFT is neither responsible for nor will it be
      liable under any theory based upon (i) any Linked Site; (ii) any information and/or content
      found on any Linked Site; or (iii) any site(s) linked to or from any Linked Site. If you decide
      to visit any Linked Sites and/or transact any business thereon, you do so at your own risk.
      LODASOFT reserves the right to discontinue any Linked Site at any time without prior notice.
      Please contact the Web masters of any Linked Sites concerning any information, goods, and/or
      services appearing thereon.</p>
    <h4 class="m-0"><strong>CONTROLLING LAW, JURISDICTION AND INTERNATIONAL USERS</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;This Agreement is governed by and shall be construed in accordance with the
      laws of the State of Michigan, U.S.A., without reference to its conflicts of laws provisions.
      LODASOFT makes no representation that the materials are appropriate or available for use outside
      the United States. If you access this site from outside the United States, you will be
      responsible for compliance with all local laws. You agree to comply with all laws and
      regulations applicable to your use of this site. You agree to submit to the personal and
      exclusive jurisdiction of the state or federal courts located within Wayne County, Michigan for
      any disputes with LODASOFT arising out of your use of this site.</p>
    <h4 class="m-0"><strong>ENTIRE AGREEMENT</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;This Agreement constitutes the entire agreement between LODASOFT and you with
      respect to this Web site, and it supersedes all prior or contemporaneous communications and
      proposals, whether electronic, oral, or written, between you and LODASOFT with respect to this
      Web site. A printed version of this Agreement and of any notice given in electronic form shall
      be admissible in judicial or administrative proceedings based upon or relating to this Agreement
      to the same extent and subject to the same conditions as other business documents and records
      originally generated and maintained in printed form. If for any reason a court of competent
      jurisdiction finds any provision of this Agreement or portion thereof to be unenforceable, that
      provision shall be enforced to the maximum extent permissible so as to affect the intent of this
      Agreement, and the remainder of this Agreement shall continue in full force and effect. No
      waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any
      preceding or subsequent breach or default.</p>
    <h4 class="m-0"><strong>MODIFICATIONS TO AGREEMENT</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;We may revise this Agreement at any time and you agree to be bound by the
      revised Agreement. Any such modifications will become effective upon the date they are first
      posted to this site. It is your responsibility to return to this Agreement from time to time to
      review the most current terms and conditions. LODASOFT does not and will not assume any
      obligation to notify you of changes to this Agreement.</p>
    <h4 class="m-0"><strong>ELECTRONIC COMMUNICATIONS AND ELECTRONIC SIGNATURES</strong></h4>
    <p>&nbsp; &nbsp; &nbsp;You agree to be bound by any affirmation, assent, or agreement you transmit
      through this Web site, including but not limited to any consent you give to receive
      communications from LODASOFT solely through electronic transmission. You agree that when in the
      future you click on an "I agree," "I consent," or other similarly worded "button" or entry field
      with your mouse, keystroke, or other computer device, your agreement or consent will be legally
      binding and enforceable and the legal equivalent of your handwritten signature.</p>
    <p>&nbsp;</p>
  </div>
</div>
<div class="modal-footer" style="display:block;">
  <button type="button" class="btn btn-danger" [print]="printConfig">
    <i class="fa fa-print"></i> Print
  </button>
  <button type="button" class="btn btn-primary float-end" (click)="iAgreeClicked()">
    <i class="fa fa-check"></i> I Agree
  </button>
  <button type="button" class="btn btn-secondary float-end" (click)="activeModal.dismiss()"> Cancel
  </button>
</div>
