<ng-template [ngIf]="isLoading" [ngIfElse]="content">
  <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
</ng-template>

<ng-template #content>
  <div class="row mb-3">
    <p-table #dt1 [value]="highCostTestValidations" [paginator]="false" [rows]="25" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]"
      styleClass="p-datatable-gridlines">
      <ng-template pTemplate="caption">
        <h4 class="my-0 d-flex align-items-center justify-content-between">Compliance Summary
          <button class="btn btn-sm ms-2 btn-primary" (click)="reRunTestsClicked()">
            Re-run Tests
          </button>
        </h4>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>  </th>
          <th> Status </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            {{rowData.highCostMortgageComputationName }}
            <ng-container *ngIf="rowData.isHighCostLoan && rowData.regulationZDeterminationReason">
              <br>
              <div class="ms-2 text-muted">{{rowData.regulationZDeterminationReason }}</div>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="rowData['status'] == 'pass'">
             <span class="text-success cursor--help" placement="bottom" [ngbTooltip]="hintTooltip" tooltipClass="hint-tooltip"
             container="body"> PASS <i class="ms-2 fas fa-check-circle"></i> </span>
            </ng-container>
            <ng-container *ngIf="rowData['status'] == 'fail'">
              <span class="text-danger cursor--help" placement="bottom" [ngbTooltip]="hintTooltip" tooltipClass="hint-tooltip"
              container="body"> FAIL <i class="ms-2 fas fa-times-circle"></i></span>
            </ng-container>
            <ng-container *ngIf="rowData['status'] == 'na'">
              <span class="text-secondary cursor--help" placement="bottom" [ngbTooltip]="hintTooltip" tooltipClass="hint-tooltip"
              container="body">N/A <i class="ms-2 fas fa-ban"></i></span>
            </ng-container>
            <ng-template #hintTooltip>
              <div>{{rowData.regulationZDeterminationReason || "No Reason"}}</div>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" class="text-center"> There is no data. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="row mb-1">
    <a [routerLink]="['/admin/app-details/' + appId + '/coc-details']">Changes of Circumstance</a>
  </div>
  <div class="row le-cd">
    <p-table #dt1 [value]="disclosureValidation.items" [paginator]="false" [rows]="25" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10,25,50]"
      styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th> </th>
          <th> Disclosed </th>
          <th> Loan </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-validationItem>
        <tr>
          <td> {{validationItem.pricingField}} </td>
          <td> {{validationItem.assignedValue}} </td>
          <td [ngClass]="{'highlight-pricing': validationItem.hasChanged}"> {{validationItem.loanValue}} </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3" class="text-center"> There is no data. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>
