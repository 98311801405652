<div *ngIf="!isLoading; else loading">
  <ng-container *ngIf="!selectedLosVendor && !(docProviders?.length > 0)">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title d-flex align-items-center">
          <span><i class="fas fa-file-pdf-o me-2"></i> Disclosures Wizard</span>
        </h4>
      </div>
      <div class="card-body" style="min-height: 600px;">
        <div class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <span>{{error || "Please create a loan in the LOS before attempting to generate disclosures"}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf=" !error &&
      ((!selectedDocProvider && docProvidersOptionSelected) ||
        (!docProvidersOptionSelected && !losOptionSelected) ||
        !wizardShowing);
      else wizardTemplate
    ">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title d-flex align-items-center">
              <span><i class="fas fa-file-pdf-o me-2"></i> Disclosures Wizard</span>
            </h4>
          </div>
          <div class="card-body d-flex align-items-center justify-content-center"
            [ngStyle]="{'min-height': isTpoUser ? '607px' : '603px'}">
            <div class="d-flex flex-column">
              <h4 class="card-title mb-3">Send From</h4>
              <div class="d-flex justify-content-center">
                <div style="width: 300px">
                  <div class="form-check mb-2">
                    <input class="form-check-input" type="radio" name="docProviders" id="docProviders"
                      [checked]="!losOptionSelected" value="docProvider" [(ngModel)]="sendFrom"
                      (ngModelChange)="onSendFromChange()" />
                    <label class="form-check-label" for="docProviders">
                      Document Providers
                    </label>
                  </div>

                  <select [(ngModel)]="selectedDocProvider" (ngModelChange)="onSelectedDocProviderChanged()"
                    [disabled]="!docProvidersOptionSelected" class="form-select ms-3">
                    <option [ngValue]="null">-- Select One --</option>
                    <option *ngFor="let docProvider of docProviders" [ngValue]="docProvider.value">
                      {{ docProvider.name }}
                    </option>
                  </select>

                  <div class="form-check mt-4">
                    <input class="form-check-input" type="radio" name="los" id="los" value="los"
                      [checked]="!!losOptionSelected" [(ngModel)]="sendFrom" (ngModelChange)="onSendFromChange()"
                      [disabled]="
                        losOptionSelected && !disclosureCredentials.length
                      " />
                    <label class="form-check-label" for="los"> LOS </label>
                  </div>

                  <ng-container *ngIf="losOptionSelected && (!disclosureCredentials.length || !selectedLosVendor)">
                    <div class="alert custom-alert custom-alert-warning icon-custom-alert shadow-sm my-3 ms-3"
                      role="alert">
                      <div class="media">
                        <i
                          class="la la-exclamation-triangle alert-icon text-warning font-30 me-3 align-self-center"></i>
                        <div class="media-body">
                          <div *ngIf="!disclosureCredentials.length" class="d-inline-block">Your LOS does not support
                            Disclosure Generation</div>
                          <div *ngIf="!selectedLosVendor">There are no LOS vendor set for the application</div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="d-flex justify-content-center my-4">
                    <button type="button" class="btn btn-primary" [disabled]="
                        (!selectedDocProvider && docProvidersOptionSelected) ||
                        (!docProvidersOptionSelected && !losOptionSelected) ||
                        (losOptionSelected && (!disclosureCredentials.length || !selectedLosVendor))
                      " (click)="onNextClicked()">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #wizardTemplate>
    <encompass-disclosure-wizard 
      *ngIf="selectedLosVendor=='EncompassApi' && losOptionSelected && !userCanRunStreamlinedDisclosures" 
      [applicationId]="applicationId"
      [losIdentifier]="losIdentifier" 
      [isTpoUser]="isTpoUser" 
      [credentialId]="selectedCredential.credentialId"
      [hasCancelButton]="false">
    </encompass-disclosure-wizard>

    <encompass-disclosure-wizard-streamlined
      *ngIf="selectedLosVendor=='EncompassApi' && losOptionSelected && userCanRunStreamlinedDisclosures" 
      [credentialId]="selectedCredential.credentialId"
      [hasCancelButton]="false">
    </encompass-disclosure-wizard-streamlined>

    <meridian-disclosure-wizard *ngIf="selectedLosVendor=='MeridianLink' && losOptionSelected" [applicationId]="applicationId"
      [losIdentifier]="losIdentifier" [isTpoUser]="isTpoUser" [credentialId]="selectedCredential.credentialId"
      [hasCancelButton]="false"></meridian-disclosure-wizard>
    <disclosures-wizard *ngIf="
        selectedDocProvider &&
        selectedDocProvider == 'DocMagic' &&
        docProvidersOptionSelected
      " [hasCancelButton]="docProviders.length > 1"
      (canceled)="selectedDocProvider = null; wizardShowing = false"></disclosures-wizard>
  </ng-template>
</div>

<ng-template #loading>
  <loading-indicator></loading-indicator>
</ng-template>
