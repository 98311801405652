import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RunDisclosuresComponent } from './run-disclosures.component';
import { RunDisclosuresRoutingModule } from './run-disclosures-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DisclosuresModule } from '../disclosure/disclosures.module';
import { EncompassDisclosureWizardModule } from '../encompass-disclosure-wizard/encompass-disclosure-wizard.module';
import { LosInfoModule } from '../../los-info/los.module';
import {MeridianDisclosureWizardModule} from "../meridian-disclosure-wizard/meridian-disclosure-wizard.module";

@NgModule({
  declarations: [
    RunDisclosuresComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RunDisclosuresRoutingModule,
    DisclosuresModule,
    EncompassDisclosureWizardModule,
    LosInfoModule,
    MeridianDisclosureWizardModule
  ]
})
export class RunDisclosuresModule { }
