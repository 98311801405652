<div class="row">
  <div class="d-flex align-content-end flex-wrap">
    <div class="mt-4 me-2" *ngFor="let vendor of miQuotes">
      <credential-item [credential]="vendor" [credentials]="miQuotes"
        (onCredentialSucessfullyDeleted)="onCredsDelete($event)" (openCredentialModal)="openVendorEditor($event)">
      </credential-item>
    </div>
    <div class="mt-4 me-2">
      <create-credential [credentialType]="'NewVendor'" (openCredentialModal)="openVendorPicker()"></create-credential>
    </div>
  </div>
</div>
