<div class="row mt-4">
  <div class="col-md-12">
    <div class="card-body px-0 mb-2" *ngIf="
        invalidPackageConditions.length ||
        !validationResponse.planCodePassed || 
        invalidElibilityCheckRuleStatus.length > 0 || 
        validationResponse.complianceReportResponse.status != 'Success'
      ">
      <div class="p-2">
        <div class="fieldset-title mb-2">
          <i class="fas fa-exclamation-triangle alert-icon text-danger align-self-center me-1"></i>
          Invalid Results
        </div>
        <div class="accordion" id="invalidResultAccordion">
          <div class="accordion-item" *ngIf="invalidPackageConditions.length">
            <h5 class="accordion-header m-0" id="headingOne">
              <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseOne'" aria-expanded="true"
                [attr.aria-controls]="'#collapseOne'">
                Package Condition Report
              </button>
            </h5>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#invalidResultAccordion">
              <div class="accordion-body">
                <los-package-condition-reports
                  [conditionReports]="invalidPackageConditions"></los-package-condition-reports>
              </div>
            </div>
          </div>
          <div class="accordion-item" *ngIf="!validationResponse.planCodePassed">
            <h5 class="accordion-header m-0" id="headingTwo">
              <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseTwo'" aria-expanded="true"
                [attr.aria-controls]="'#collapseTwo'">
                Plan Code Conflicts
              </button>
            </h5>
            <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
              data-bs-parent="#invalidResultAccordion">
              <div class="accordion-body">
                <los-plan-code-conflicts [conflicts]="
                    validationResponse.planCodeConflicts
                  "></los-plan-code-conflicts>
              </div>
            </div>
          </div>
          <div class="accordion-item" *ngIf="invalidElibilityCheckRuleStatus.length > 0">
            <h5 class="accordion-header m-0" id="headingTwo">
              <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseTwo'" aria-expanded="true"
                [attr.aria-controls]="'#collapseTwo'">
                Eligibility Failures
              </button>
            </h5>
            <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
              data-bs-parent="#invalidResultAccordion">
              <div class="accordion-body">
                <p-table [value]="invalidElibilityCheckRuleStatus" [paginator]="false">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Eligibility Check Failure</th>
                      <th>Missing Value</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-check>
                    <tr>
                      <td>{{ faultTypeDescriptions[check.eligibilityFaultType] }}</td>
                      <td>
                        <encompass-disclosures-input-editors *ngIf="!check.missingFields"
                          [type]="'type'" [fieldId]="'fieldId'" [object]="check.missingFields"
                          [values]="correctedValues">
                        </encompass-disclosures-input-editors>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
          <div class="accordion-item" *ngIf="validationResponse.complianceReportResponse && validationResponse.complianceReportResponse.status != 'Success'">
            <h5 class="accordion-header m-0" id="headingTwo">
              <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseTwo'" aria-expanded="true"
                [attr.aria-controls]="'#collapseTwo'">
                Compliance Checks
              </button>
            </h5>
            <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
              data-bs-parent="#invalidResultAccordion">
              <div class="accordion-body">
                <!-- Need a component to show the failure message and show the file in a pdf viewer like -->
                <div class="d-flex justify-content-center align-items-center mt-5 mb-5" *ngIf="validationResponse.complianceReportResponse.reportFileData">
                  <a (click)="showComplianceReport = !showComplianceReport;">{{showComplianceReport ? 'Hide' : 'Show' }} Compliance Report</a>
                </div>
                <div *ngIf="showComplianceReport == true">
                  <encompass-disclosure-preview-pdf
                    [documentData]="validationResponse.complianceReportResponse.reportFileData"></encompass-disclosure-preview-pdf>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0" *ngIf="incorrectValues.length">
      <div class="p-2">
        <div class="fieldset-title mb-2">
          <i class="fas fa-exclamation-triangle alert-icon text-danger align-self-center me-1"></i>
          Incorrect Fields
        </div>
        <p-table [value]="incorrectValues" [paginator]="false">
          <ng-template pTemplate="header">
            <tr>
              <th>Field Name</th>
              <th>Current Value</th>
              <th>New Value</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-incorrectValue>
            <tr>
              <td>{{ incorrectValue.fieldName }}</td>
              <td>{{ incorrectValue.currentValue }}</td>
              <td>
                <select *ngIf="incorrectValue?.expectedValues?.length" class="form-select" [(ngModel)]="
                    correctedValues[incorrectValue.fieldId]
                      .requestedValue
                  ">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="
                      let expectedValue of incorrectValue.expectedValues
                    " [ngValue]="expectedValue">
                    {{ expectedValue }}
                  </option>
                </select>
                <encompass-disclosures-input-editors 
                  *ngIf="!incorrectValue?.expectedValues?.length"
                  [type]="'type'" 
                  [fieldId]="'fieldId'" 
                  [object]="incorrectValue"
                  [values]="correctedValues">
                </encompass-disclosures-input-editors>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
