import { LeadFilters } from "../modules/leads/models/lead-filters.model";
import { Column } from "../shared/models/table-config.model";
import { PipelineFilter } from "./pipeline";

export class WebPreferences {
  pipelinePreferences: PipelinePreferences = new PipelinePreferences();
  pipelineReferences: PipelineView = new PipelineView();
  dialerReferences: DialerPreferences = new DialerPreferences();
  conversationPreferences: ConversationPreferences = new ConversationPreferences();
  mcrPreferences: McrPreferences = new McrPreferences();
  internalContactsPreferences: InternalContactsPreferences = new InternalContactsPreferences();
  leadsPreferences: LeadsPreferences = new LeadsPreferences();
  externalOrgPreferences: ExternalOrgPreferences = new ExternalOrgPreferences();
}

export class DialerPreferences {
  selectedColumns: {} = {}
}

export class ExternalOrgPreferences {
  externalOrgSelectedColumns: Column[];
  externalOrgTableState: any;
}
export class LeadsPreferences {
  powerUserModeToggledOn: boolean;
  leadsFilterSetting: LeadFilters;
  leadsTableSelectedColumns: Column[];
  leadsTableState: any;
  leadsPowerUserTableSelectedColumns: Column[];
  leadsPowerUserTableState: any;
}

export class PipelineView {
  pipelineSelectedColumns: Column[];
  pipelineIsFilterSectionVisible: boolean;
  pipelineFiltersList: PipelineFilter[];
  pipelineTableState: any;
  pipelineSelectedColumnsForTpo: Column[];
  pipelineTableStateForTpo: any;
}

export class NamedPipelineView {
	name: string;
	view: PipelineView;
	isLastViewed: boolean;
	
}

export class PipelinePreferences {
  savedNamedViews: NamedPipelineView[] = [];
  unnamedDefaultView: PipelineView;
}

export class ConversationPreferences {
  selectedChatMediumFilters: string[];
  showArchived: boolean = false;
}

export class McrPreferences {
  states: string[];
}

export class InternalContactsPreferences {
  viewType: InternalContactsViewType;
}

export enum InternalContactsViewType {
  card = "card",
  list = "list"
}
