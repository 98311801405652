import { Component, Input, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Lead } from 'src/app/modules/leads/models/lead.model';

@Component({
  selector: 'lead-current-loan-info',
  templateUrl: './lead-current-loan-info.component.html',
  styleUrls: ['./lead-current-loan-info.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadCurrentLoanInfoComponent {

  @Input() lead: Lead;
  @Input() isDrawer: boolean = false;

  @ViewChild("calculatorPopover")
  calculatorPopover: PopoverDirective;

  constructor() { }

  onEscrowCalculatorOpened = () => {
    this.calculatorPopover.show();
  }

  onEscrowCalculatorClosed = () => {
    this.calculatorPopover.hide();
  }

  onEscrowCalculatorSaved = (calculatedMonthlyValue: number) => {
    this.lead.escrow = calculatedMonthlyValue;
    this.onEscrowCalculatorClosed();
  }
}
