<div class="card">
    <div class="card-header d-flex align-items-center justify-content-center">
        <h4 class="card-title flex-grow-1">Pre-Recorded Voicemails</h4>
        <button class="btn btn-primary" (click)="onUpsertVoicemailDrawerOpened()">
            <i class="fas fa-plus"></i> Add Voicemail
        </button>
    </div>
    <div class="card-body">
        <p-table #voicemailTable [columns]="voicemailColumns" columnResizeMode="fit" [value]="voicemails"
            [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll" scrollable="true"
            [scrollHeight]="scrollHeight" name="voicemail-table-v2" [rowsPerPageOptions]="[10, 25, 50]"
            [autoLayout]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single"
            [globalFilterFields]="['name']">
            <ng-template pTemplate="caption">
                <div class="d-flex justify-content-end">
                    <span>
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText #searchText type="text" class="form-control"
                                (input)="voicemailTable.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search voicemail" />
                        </span>
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of voicemailColumns | filter: {visible: true}"
                        [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
                        {{ col.header }}
                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td>
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="fa fa-pencil-alt cursor text-primary" (click)="onUpsertVoicemailDrawerOpened(row)"
                                title="Edit Voicemail"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger"
                                (click)="showDeleteConfirmationDialog(row.userVoicemailId)"
                                title="Delete Voicemail"></i>
                        </span>
                    </td>
                    <td>{{ row.name }}</td>
                    <td>
                        <span *ngIf="loadingFileContentForId === row.userVoicemailId"
                            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span *ngIf="!row.playableFileUrl && loadingFileContentForId !== row.userVoicemailId"
                            class="cursor" (click)="getVoicemailFileAndPlay(row)"><i class="fa fa-play-circle"></i> Play
                            Voicemail</span>
                        <div *ngIf="row.playableFileUrl &&  loadingFileContentForId !== row.userVoicemailId">
                            <audio controls autoplay>
                                <source [src]="row.playableFileUrl">
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="text-center"> There are no pre recorded voicemails. </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<drawer #upsertVoicemailDrawer [name]="'upsertVoicemailDrawer'" [templateRef]="upsertVoicemailRef"
    [options]="upsertVoicemailDrawerOptions">
</drawer>
<ng-template #upsertVoicemailRef>
    <upsert-voicemail [userCompanyGuid]="userCompanyGuid" [voicemail]="selectedVoicemail"
        (close)="onUpsertVoicemailDrawerClose($event)"></upsert-voicemail>
</ng-template>