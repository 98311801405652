<div class="card">
    <div class="card-header">
        <h4 class="card-title">Alerts</h4>
    </div>
    <div class="card-body">
        <div *ngIf="stikkumEnrollmentStatus === 'None'">
            <h4 class="text-center p-5">
                <i class="fa fa-info-circle text-primary">
                    User is not enrolled for alerts.
                </i>
            </h4>
        </div>
        <div *ngIf="stikkumEnrollmentStatus !== 'None'">
            <p-table
                #dt1
                [columns]="columns"
                [value]="alerts"
                [paginator]="true"
                [rows]="25"
                responsiveLayout="scroll"
                name="global-service-table-v2"
                [rowsPerPageOptions]="[10, 25, 50]"
                [globalFilterFields]="globalFilterFields"
                [autoLayout]="true"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines"
                sortMode="single"
                sortField="name"
            >
                <ng-template pTemplate="caption">
                    <div>
                        <date-range-filter
                            [showLabel]="false"
                            (rangeChanged)="dateRangeChanged($event)"
                        ></date-range-filter>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        <span>
                            {{ col.header }}
                            <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
                        </span>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td *ngFor="let col of columns" [ngSwitch]="col.field">
                        <span *ngSwitchDefault>
                            {{ row[col.field] }}
                        </span>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8" class="text-center">
                            There are no alerts.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
