import { Component, Input, OnChanges } from "@angular/core";
import { FeeTypeEnum } from "src/app/models/fee/fee-type.enum";
import { LoanFee } from "src/app/models/fee/fee.model";

@Component({
  selector: "fee-totals",
  templateUrl: "fee-totals.component.html",
})
export class FeeTotalsComponent implements OnChanges {
  @Input() fees: LoanFee[] = [];

  borrowerTotal: number = 0;
  sellerTotal: number = 0;
  otherTotal: number = 0;
  feesTotal: number = 0;

  ngOnChanges(): void {
    this.calcualateTotals();
  }

  private calcualateTotals() {
    const fees = this.fees.filter(x => !x.isSummaryFee);

    this.borrowerTotal = 0;
    this.sellerTotal = 0;
    this.otherTotal = 0;

    fees.forEach(fee => {
      const isDiscountFee = fee.feeType === FeeTypeEnum.DiscountFee || fee.feeType === FeeTypeEnum.LoanDiscountPoints;
      if (isDiscountFee) {
        this.sellerTotal += (fee.sellerFeeDollar ?? 0);
        this.otherTotal += (fee.lenderFeeDollar ?? 0) + (fee.thirdPartyFeeDollar ?? 0);
        this.borrowerTotal += (fee.borrowerFeeDollar ?? 0) - ((fee.lenderFeeDollar ?? 0) + (fee.thirdPartyFeeDollar ?? 0));
      } else {
        this.sellerTotal += (fee.calculatedValues.sellerTotal ?? 0);
        this.otherTotal += (fee.calculatedValues.lenderTotal ?? 0) + (fee.calculatedValues.thirdPartyTotal ?? 0);
        this.borrowerTotal += (fee.calculatedValues.borrowerTotal ?? 0) - ((fee.calculatedValues.lenderTotal ?? 0) + (fee.calculatedValues.thirdPartyTotal ?? 0));
      }
    })

    this.feesTotal = this.borrowerTotal + this.sellerTotal + this.otherTotal;
  }
}