import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LoanFee} from '../fees.model';
import {FeeSection, FeeSectionViewType} from '../fee-section.model';
import {FeesReviewSectionAction} from './fees-review-section/fees-review-section.component';
import {FeeContextService} from '../services/fee-context.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'fees-review',
  templateUrl: './fees-review.component.html',
  styleUrls: ['./fees-review.component.scss'],
})
export class FeesReviewComponent implements OnInit, OnDestroy {
  /**
   * Controls mutability of fees.
   *
   * If set to true, it allows modification (mutation) to the fees including adding, removing, or
   * updating the fees. Otherwise, it disables any modification to the fees.
   *
   * By default, it is set to true, allowing changes to the fees.
   */
  @Input() editable: boolean = true;

  @Output() feesChange = new EventEmitter<readonly LoanFee[]>();

  @Output() showFeeDetails = new EventEmitter<LoanFee>();

  @Output() scheduleEscrowFees = new EventEmitter<void>();

  protected feeSections: readonly FeeSection[] = [];

  protected sectionActions?: Map<FeeSectionViewType, FeesReviewSectionAction[]> = new Map();

  private _feeChangesSubscription?: Subscription;

  constructor(private readonly _feeContextService: FeeContextService) {}

  ngOnInit(): void {
    this.initSectionActions();

    this.subscribeToFeeChanges();
  }

  ngOnDestroy(): void {
    this._feeChangesSubscription?.unsubscribe();
  }

  private subscribeToFeeChanges() {
    this._feeChangesSubscription?.unsubscribe();
    this._feeChangesSubscription = this._feeContextService.getFeeSectionsChanges$().subscribe(_ => {
      this.feeSections = this._feeContextService.feeSections;
    });
  }

  private initSectionActions() {
    if (this.sectionActions?.size > 0) {
      console.warn('Section actions have already been initialized');
    }

    this.sectionActions = new Map([
      [
        FeeSectionViewType.Escrow,
        [
          {
            label: 'Schedule',
            iconClass: 'fa fa-calendar',
            onClick: this.onClickScheduleEscrowFees,
          },
        ],
      ],
    ]);
  }

  private onClickScheduleEscrowFees = (): void => {
    this.scheduleEscrowFees.emit();
  };

  protected onShowFeeDetails(fee: LoanFee): void {
    this.showFeeDetails.emit(fee);
  }
}
