import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DocumentType, ThirdPartyCredential, ThirdPartyKeyValue } from '../../../../../../models';
import { NotificationService } from '../../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'app-truv-income-employment-verification-modal',
  templateUrl: './truv-income-employment-verification-modal.component.html',
  styleUrls: ['./truv-income-employment-verification-modal.component.scss']
})
export class TruvIncomeEmploymentVerificationModal extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  @Input() userId: string | null;
  @Input() documentTypes: Array<DocumentType>;
  @Input() credential: ThirdPartyCredential;
  @Input() scope: ScopeType;

  keyValuePairs = {
    savedDocumentTypeId: null,
    vOAReportDocumentTypeId: null,
    vOIReportDocumentTypeId: null,
    singleEmploymentReportDocumentTypeId: null,
    statementDocumentTypeId: null,
    taxDocumentDocumentTypeId: null,
    invoiceDocumentTypeId: null,
    vABenefitsDocumentTypeId: null,
    disabilityBenefitsDocumentTypeId: null,
    templateId: null,
    clientId: null
  };
  isSaving: boolean;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keyValuePairs.savedDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SavedDocumentTypeId') || new ThirdPartyKeyValue('SavedDocumentTypeId', "");
    this.keyValuePairs.templateId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'TemplateId') || new ThirdPartyKeyValue('TemplateId', "");
    this.keyValuePairs.clientId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'ClientId') || new ThirdPartyKeyValue('ClientId', "");
    this.keyValuePairs.vOAReportDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'VOAReportDocumentTypeId') || new ThirdPartyKeyValue('VOAReportDocumentTypeId', "");
    this.keyValuePairs.vOIReportDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'VOIReportDocumentTypeId') || new ThirdPartyKeyValue('VOIReportDocumentTypeId', "");
    this.keyValuePairs.singleEmploymentReportDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SingleEmploymentReportDocumentTypeId') || new ThirdPartyKeyValue('SingleEmploymentReportDocumentTypeId', "");
    this.keyValuePairs.statementDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'StatementDocumentTypeId') || new ThirdPartyKeyValue('StatementDocumentTypeId', "");
    this.keyValuePairs.taxDocumentDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'TaxDocumentDocumentTypeId') || new ThirdPartyKeyValue('TaxDocumentDocumentTypeId', "");
    this.keyValuePairs.invoiceDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'InvoiceDocumentTypeId') || new ThirdPartyKeyValue('InvoiceDocumentTypeId', "");
    this.keyValuePairs.vABenefitsDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'VABenefitsDocumentTypeId') || new ThirdPartyKeyValue('VABenefitsDocumentTypeId', "");
    this.keyValuePairs.disabilityBenefitsDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'DisabilityBenefitsDocumentTypeId') || new ThirdPartyKeyValue('DisabilityBenefitsDocumentTypeId', "");
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);
    this.credential.companyId = this.applicationContext.userPermissions.companyId;
    this.credential.userId = this.userId;

    this.isSaving = true;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}

