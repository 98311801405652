import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ResetPasswordModel } from '../../models';
import { AuthService } from '../../services/auth.service';
import { Constants } from '../../services/constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { finalize, map, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounce } from 'lodash';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  formData: ResetPasswordModel = new ResetPasswordModel();
  resetPasswordForm: UntypedFormGroup;

  error: string = null;
  upper = 'upper'
  resetDone: boolean = false;
  resettingPassword: boolean = false;

  possibleLogoUrls: string[] = [];

  currentYear: number = new Date().getFullYear();

  availableCompanies: AuthenticationUserCompany[] = [];

  private _activatedRouteSubscription: Subscription;

  constructor(
    private readonly _authService: AuthService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly _spinnerService: NgxSpinnerService,
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
  }

  ngOnInit(): void {
    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      const token = params[Constants.authorization.token];
      const email = params[Constants.authorization.userName];
      if (!token) {
        return this._navigationService.navigateToLogin();
      }
      this.formData.code = token;
      this.formData.email = email;

      if (this.formData.email) {
        this.getAvailableCompanies();
      }
    });

    this.resetPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.formData.email),
      password: new UntypedFormControl(this.formData.password),
      confirmPassword: new UntypedFormControl(this.formData.confirmPassword),
      userCompanyGuid: new UntypedFormControl(this.formData.userCompanyGuid),
    }, { validators: (group: AbstractControl) => this._matchStringValidator(group, 'password', 'confirmPassword') });
  }

  onEmailChangedDebounce = debounce(this.onEmailChanged, 300);

  private onEmailChanged() {
    if (!this.email.valid) {
      this.formData.userCompanyGuid = null;
      return;
    }

    this.getAvailableCompanies();
  }

  // form controls
  get email() { return this.resetPasswordForm.get('email'); }
  get password() { return this.resetPasswordForm.get('password'); }
  get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }
  get userCompanyGuid() { return this.resetPasswordForm.get('userCompanyGuid'); }

  resetPassword = () => {
    this.resetPasswordForm.markAllAsTouched();
    if (!this.resetPasswordForm.valid) return;

    this.error = null;
    this.resettingPassword = true;

    this._authService.resetPassword(this.formData)
      .pipe(finalize(() => this.resettingPassword = false))
      .subscribe({
        next: (result) => {
          this.resetDone = true;
        },
        error: (error) => {
          this.error = this._errorMsg(error);
          this.resetDone = false;
        }
      })
  };

  ngOnDestroy(): void {
    this._activatedRouteSubscription?.unsubscribe();
  }

  private _errorMsg(error): string {
    let result: string = ""

    if (typeof error === 'string' && error.indexOf("InvalidToken") > -1) {
      result = "Invalid token.";
    } else if (error.message) {
      result = error.message;
    } else {
      result = "Passwords must contain an uppercase, lowercase, non-alphanumeric character and require at least one digit.";
    }

    return result
  }

  private _matchStringValidator = (group: AbstractControl, nameOne: string, nameTwo: string): ValidationErrors | null => {
    const firstValue = group.get(nameOne)?.value;
    const secondValue = group.get(nameTwo)?.value;
    return !firstValue || !secondValue || firstValue === secondValue ? null : { stringDoesntMatch: true }
  }

  private getAvailableCompanies = () => {
    this._spinnerService.show();
    this._authService.getAvailableCompanies(this.formData.email).subscribe({
      next: (response) => {
        this.availableCompanies = response || [];

        if (this.availableCompanies.length === 1) {
          this.formData.userCompanyGuid = this.availableCompanies[0].userCompanyGuid;
        }
      },
      error: (error) => {
        this.error = this._errorMsg(error.message || "Unable to get companies for a provided email");
      }
    }).add(() => {
      this._spinnerService.hide();
    });
  }
}
