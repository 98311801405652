import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Borrower, DocumentType, LoanApplication, LoanDoc, UserPermissions } from 'src/app/models';
import { EmailDocFile, LoanDocsByCategory } from '../../correspondence/components/add-attachment-dialog/add-attachment-dialog.component';
import { DocFile, DocFileUploadResponse } from '../models/doc-file.model';
import { DocumentGenerationRequest } from '../models/document-generation-request.model';
import { DocumentSigningOrder } from '../models/document-signing-order.model';
import { MergeDocFilesRequest } from '../models/merge-files/merge-doc-files-request.model';
import { SyncLoanDoc } from '../models/sync-loan-docs/sync-loan-doc.model';
import { LoanDocsTask } from '../models/task.model';
import { FileDto } from 'src/app/models/borrower/file-dto.model';

@Injectable({
  providedIn: 'root'
})
export class LoanDocsService {

  documentGenerated = new Subject();

  documentUploaded = new Subject();

  constructor(private readonly _dataService: DataService) { }

  public getLoanDocs = (loanId: number, importLosDocs?: boolean): Observable<LoanDoc[]> => {
    if (importLosDocs != null) {
      return this._dataService.get(`api/Loan/${loanId}/GetAllLoanDocs?importLosDocs=${importLosDocs}`);
    }
    return this._dataService.get(`api/Loan/${loanId}/GetAllLoanDocs`);
  }

  public getDocFiles = (loanId: number) => {
    return this._dataService.get(`api/Loan/${loanId}/GetAllDocFiles`);
  }

  public upsertLoanDoc = (data: LoanDoc) => {
    const url = 'api/Loan/' + data.applicationId + '/UpsertLoanDoc';
    return this._dataService.post(url, data);
  }

  public upsertFile = (data: DocFile) => {
    const url = 'api/LoanDoc/' + data.loanDocId + '/UpsertFile';
    return this._dataService.post(url, data);
  }

  public uploadFileForLoanDoc = (file: File, docFile: DocFile, loanDocId: number,
    autoTransition: boolean, autoConvertToPdf: boolean,
    useDynamicCompression: boolean): Observable<FileDto> => {
    const url = "api/File/UpsertFileFromLoanDoc/" + loanDocId
      + "?autoTransition=" + autoTransition + "&autoConvertToPdf=" + autoConvertToPdf + "&useDynamicCompression=" + useDynamicCompression

    var formData = new FormData();

    if (file) {
      formData.append("file", file);
    }
    formData.append("model", JSON.stringify(docFile));

    return this._dataService.postFormData(url, formData);
  }

  public deleteLoanDoc = (loanDocId: number) => {
    const url = 'api/LoanDoc/' + loanDocId + '/RemoveLoanDoc';
    return this._dataService.delete(url);
  }

  public getLoanDoc = (loanDocId: number, includeDocFiles: boolean = false): Observable<LoanDoc> => {
    const url = 'api/LoanDoc/' + loanDocId + "?includeDocFiles=" + includeDocFiles;
    return this._dataService.get(url);
  }

  public loadExportFormat = (): Observable<any> => {
    return this._dataService.get(`api/Admin/get-document-export-format`);
  }

  public loadDocumentSigningReviewRequiredConfig = (): Observable<boolean> => {
    return this._dataService.get(`api/Admin/get-docusign-review-required-config`);
  }

  public getBorrowers = (appId: number): Observable<Borrower> => {
    return this._dataService.get(`api/Loan/${appId}/Borrowers`);
  }

  public getApplicationModel = (appId: number): Observable<LoanApplication> => {
    return this._dataService.get(`api/Loan/${appId}/GetApplicationModel`);
  }

  public linkLoanDocToFile = (data: LoanDocsTask, fileGuid: string) => {
    const url = 'api/File/LinkFileToLoanDoc/' + data.loanDocId + '/' + fileGuid;
    return this._dataService.post(url, data);
  }

  public mergeDocFiles = (data: MergeDocFilesRequest, loanDocId: number): Observable<MergeDocFilesRequest> => {
    const url = 'api/LoanDoc/' + loanDocId + "/MergeDocFiles";
    return this._dataService.post(url, data);
  }

  viewLoanDocContent = (fileGuid: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/File/ViewFile/${fileGuid}`);
  }

  getLoanDocContent = (fileGuid: string): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/File/DownloadFile/${fileGuid}`);
  }

  downloadFiles(files: readonly DocFile[]): Observable<readonly BlobPart[]> {
    return forkJoin(
      files.map((file) => this.getLoanDocContent(file.guid)),
    );
  }

  getFileTrackingInfo = (docGuid: string): Observable<any> => {
    return this._dataService.get(`api/File/TrackingFile/${docGuid}`);
  }

  convertToPdf = (data: DocFile, fileGuid: string): Observable<any> => {
    const url = `api/File/convert-to-pdf/${fileGuid}`;
    return this._dataService.post(url, data, null, { responseType: 'text' });
  }

  setProcessAdrOcr = (data: DocFile, fileGuid: string): Observable<any> => {
    const url = `api/LoanDoc/${fileGuid}/process-ocr-adr`;
    return this._dataService.post(url, data);
  }

  setMergeFiles = (appId: number, loanDocId: number, docGuids: string[]): Observable<any> => {
    const url = `api/Loan/${appId}/loan-docs/${loanDocId}/merge-files`;
    return this._dataService.post(url, docGuids);
  }

  getSyncLodaDocWithLos = (appId: number, type: string): Observable<any> => {
    return this._dataService.get(`api/Loan/LOS/docSync/${type}/${appId}`);
  }

  getSyncLodaDocWithLde = (appId: number, type: string): Observable<any> => {
    return this._dataService.get(`api/Loan/LDE/docSync/${type}/${appId}`);
  }

  setSyncDocs = (appId: number, doc: SyncLoanDoc): Observable<SyncLoanDoc> => {
    const url = `api/Loan/LOS/docSync/${appId}`;
    return this._dataService.post(url, doc);
  }

  setLdeSyncDocs = (appId: number, doc: SyncLoanDoc): Observable<SyncLoanDoc> => {
    const url = `api/Loan/LDE/docSync/${appId}`;
    return this._dataService.post(url, doc);
  }

  syncLoanDocWithLos = (data: LoanDoc) => {
    const url = 'api/Loan/' + data.applicationId + '/UpsertLoanDoc?saveToLos=true';
    return this._dataService.post(url, data);
  }

  syncDocFileWithLos = (data: LoanDoc, docFileId: number) => {
    const url = `api/Loan/${data.applicationId}/loan-docs/${data.loanDocId}/doc-files/${docFileId}/sync-with-los`;
    return this._dataService.post(url, data);
  }

  syncLoanDocWithLde = (data: LoanDoc) => {
    const url = 'api/Loan/' + data.applicationId + '/UpsertLoanDoc?saveToLde=true';
    return this._dataService.post(url, data);
  }

  deleteDocFile = (data: LoanDoc, guid: string) => {
    const url = `api/File/RemoveFile/${guid}`;
    return this._dataService.post(url, data);
  }

  getSigningOrder = (loanDocTaskId: number): Observable<any> => {
    return this._dataService.get(`api/Signing/order/${loanDocTaskId}`);
  }

  refreshSigningOrder = (data: DocumentSigningOrder, loanDocTaskId: number): Observable<DocumentSigningOrder> => {
    const url = `api/Signing/order/${loanDocTaskId}/refresh`;
    return this._dataService.post(url, data);
  }

  getSigningOrderCombinedContent = (loanDocTaskId: number): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/Signing/order/${loanDocTaskId}/documents/combined/content/view`);
  }

  addDocTypes = (data: SyncLoanDoc | LoanDoc, losVendor: string, selectedDocTypeId: number, companyId: number): Observable<SyncLoanDoc> => {
    const url = `api/admin/LosConfig/doctypes/add?losVendor=${losVendor}&docTitle=${data.title}&documentTypeId=${selectedDocTypeId}&companyId=${companyId}`;
    return this._dataService.post(url, data);
  }

  generateDocument = (request: DocumentGenerationRequest): Observable<any> => {
    const url = `api/admin/generated-documents`;
    return this._dataService.post(url, request).pipe(map(response => {
      this.documentGenerated.next(null);
      return response;
    }));
  }

  generateLoanDocsByCategory = (loanDocs: LoanDoc[],
    documentTypes: Array<DocumentType>,
    userPermissions: UserPermissions,
    isTpo: boolean
  ): LoanDocsByCategory[] => {
    let loanDocsByCategory: LoanDocsByCategory[] = [];

    const documentTypesToUse = documentTypes
      .filter(docType => this.filterDocTypes(docType, userPermissions, isTpo));

    // These will go under document type = 0
    const loanDocsWithUnrecognizedTypes = loanDocs.filter(d => d.documentTypeId == 0 || !documentTypesToUse.map(dt => dt.documentTypeId).includes(d.documentTypeId));
    if (loanDocsWithUnrecognizedTypes.length > 0) {
      const documentTypeWithZeroId = documentTypesToUse.find(dt => dt.documentTypeId == 0);
      if (documentTypeWithZeroId) {
        const docs: LoanDocsByCategory = {
          documentType: documentTypeWithZeroId.documentTypeName,
          documents: this.convertDocFilesToEmailDocFiles(loanDocsWithUnrecognizedTypes)
        }
        loanDocsByCategory.push(docs);
      }
    }

    documentTypesToUse.filter(dt => dt.documentTypeId != 0).forEach(dt => {
      const loanDocsWithType = loanDocs.filter(ld => ld.documentTypeId == dt.documentTypeId);
      if (loanDocsWithType.length > 0) {
        const docs: LoanDocsByCategory = {
          documentType: dt.documentTypeName,
          documents: this.convertDocFilesToEmailDocFiles(loanDocsWithType)
        }
        loanDocsByCategory.push(docs);
      }
    });
    return loanDocsByCategory;
  }

  private filterDocTypes(docType: DocumentType, userPermissions: UserPermissions, isTpo: boolean) {
    if (docType.restrictedToRoles && docType.restrictedToRoles.length > 0) {
      const roles = docType.restrictedToRoles.split(',');
      return roles.filter(r => r == userPermissions.roleId.toString()).length > 0 && (!isTpo || docType.showOnTPOPortal);
    }
    return docType.documentTypeId > (isTpo ? -1 : -2) && (!isTpo || docType.showOnTPOPortal);
  }

  private convertDocFilesToEmailDocFiles = (loanDocs: LoanDoc[]): LoanDoc[] => {
    return loanDocs.map(d => {
      d.docFiles.forEach(f => <EmailDocFile>f);
      return d;
    })
  }

  viewDocFileContent = (loanId: number, userId: string, docFileId?: number, losDocFileId?: string) => {
    let url = `api/Loan/${loanId}/${userId}/ViewDocFileContent`;
    if (docFileId) {
      url += '?docFileId=' + docFileId;
    }
    if (losDocFileId) {
      url += docFileId ? '&' : '?' + 'losDocFileId=' + losDocFileId;
    }

    return this._dataService.downloadFile(url);
  }

  sendEventDocumentUploaded = () => {
    this.documentUploaded.next(null);
  }
}
