import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { MiQuoteSearchBorrowerInfo } from 'src/app/models/mi-quote-search-request.model';

@Component({
  selector: 'quote-additional-borrower-editor',
  templateUrl: './quote-additional-borrower-editor.component.html',
  styleUrls: ['./quote-additional-borrower-editor.component.scss']
})
export class QuoteAdditionalBorrowerEditorComponent implements OnInit {

  @ViewChild('additionalBorrowerForm') additionalBorrowerForm: NgForm | undefined;

  @Input() borrower: MiQuoteSearchBorrowerInfo;
  @Input() isEdit: boolean;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  copyBorrower: MiQuoteSearchBorrowerInfo;

  constructor() { }

  ngOnInit(): void {
    this.copyBorrower = _.cloneDeep(this.borrower);
  }

  onClose() {
    this.close.emit(this.isEdit);
  }

  onSave() {
    let isValid = this.onValidate();
    if (!isValid) {
      return;
    }

    this.save.emit(this.copyBorrower);
  }

  protected onValidate = (): boolean => {
    if (!this.additionalBorrowerForm) return false;

    this.additionalBorrowerForm.form.markAllAsTouched();
    if (!this.additionalBorrowerForm.form.valid) return false;

    return true;
  }


}
