import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentsComponent } from './agents.component';
import { InsertAgentForExternalContactComponent } from './components/insert-agent-for-external-contact/insert-agent-for-external-contact.component';
import { UpsertAgentComponent } from './components/upsert-agent/upsert-agent.component';

const routes: Routes = [
  {
    path: '',
    component: AgentsComponent,
  },
  {
    data: {
      breadcrumb: 'Agent Info',
    },
    path: 'upsertagent/:id',
    component: UpsertAgentComponent,
  },
  {
    data: {
      breadcrumb: 'Agent Info',
    },
    path: 'upsertagent/:id/:appId/:borrowerId/:remainContactPage',
    component: UpsertAgentComponent,
  },
  {
    data: {
      breadcrumb: 'Agent Info',
    },
    path: 'insert-agent-for-external-contact/:appId/:borrowerId',
    component: InsertAgentForExternalContactComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgentsRoutingModule { }
