import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { Message } from "../models";
import { MessageHistory } from "../models/message/message-history.model";

@Injectable()
export class EmailService {

  constructor(private readonly _dataService: DataService) {
  }

  setEmailPriorityToRetry = (messageId: number): Observable<MessageHistory[]> => {
    let url = "api/Email/setEmailPriorityToRetry/" + messageId;
    return this._dataService.post(url, {});
  }

  getEmailsSentForApp = (applicationId: number): Observable<MessageHistory[]> => {
    let url = "api/Email/GetEmailsSentForApp/" + applicationId;
    return this._dataService.get(url);
  }

  getEmailDetail = (id: number): Observable<MessageHistory> => {
    let url = "api/Email/GetEmailDetail/" + id;
    return this._dataService.get(url);
  }

  sendTestCampaignEmail = (to: string, subject: string, body: string): Observable<void> => {
    let url = "api/Email/send-test-campaign-email";
    return this._dataService.post(url, { to, subject, body });
  }
}
