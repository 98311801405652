import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from 'src/app/models';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';
import { Constants } from 'src/app/services/constants';
import { Select2OptionData } from 'ng-select2';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'asset-and-cash-flow',
  templateUrl: 'asset-and-cash-flow.component.html',
  styleUrls: ['./asset-and-cash-flow.component.scss']
})

export class AssetAndCashFlowComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    this.initializeAssets();
    this.initializePossibleAccountOwners();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  assetTypes: EnumerationItem[] = [];

  assets: Asset[] = [];

  possibleAccountOwners: Array<Select2OptionData> = [];

  editingIx: number = -1;

  omitsFromTotal: Array<number> = [];

  thereIsAtLeastOneInvalidLineItem: boolean = false;

  cashMarketSubTotal: number = 0;

  @Output()
  changedvalue: EventEmitter<any> = new EventEmitter<any>();

  borrowers = []

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  private _mortgage: UrlaMortgage;

  constructor(
    private readonly _enumsService: EnumerationService) { }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.assetTypes = enums[Constants.enumerations.assetTypes];
    });
  }

  static setMortgageDefaults(mortgage: UrlaMortgage) {
    const { assets, liabilities } = mortgage;

    const idsToString = (obj: {owningBorrowerIds: (string | number)[]}) => {
      const {owningBorrowerIds} = obj;
      obj.owningBorrowerIds = owningBorrowerIds.map((id) => id.toString());
    }

    assets
      .forEach(idsToString);

    // FIXME: The type is number but it is set to a string value by ngModel
    liabilities
      .forEach(idsToString)
  }

  addAsset = () => {
    let asset = new Asset();
    asset.assetId = Utils.getUniqueId();
    asset.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ? 
      [this.possibleAccountOwners[0].id] : [];
    this.assets.push(asset);
    this.mortgage.assets.push(asset);
  }

  deleteAsset = (deleteIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (deleteIx === self.editingIx) {
          self.editingIx = -1;
        }
        let asset = self.assets[deleteIx];
        if (asset) {
          let indexInMortgageAssets = self.mortgage.assets.indexOf(asset);
          self.mortgage.assets.splice(indexInMortgageAssets, 1);
        }
        self.assets.splice(deleteIx, 1);
        self.calculateSubTotals();
        const oneWithEmptyAccountOwnersIndex = self.assets.findIndex(a => !a.owningBorrowerIds.length);
        self.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;

      }
    });
  }

  onAccountOwnersChanged = (asset: Asset) => {
    this.thereIsAtLeastOneInvalidLineItem = !asset.owningBorrowerIds.length;
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage && this._mortgage.borrowers) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = Utils.getBorrowerFullName(borrower);
        let borrowerOption = new EnumerationItem(borrowerFullName, borrower.borrowerId);
        this.borrowers.push(borrowerOption);

        const possibleAccountOwner = {
          id: borrower.borrowerId.toString(),
          text: borrowerFullName
        }
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }

  private initializeAssets = () => {
    this.assets = [];
    if (this._mortgage.assets) {
      this._mortgage.assets.forEach(asset => {
        let borrIds = [];
        asset.owningBorrowerIds.forEach(id => {
          borrIds.push(id.toString());
        });
        asset.owningBorrowerIds = borrIds;
        this.assets.push(asset);
      });
      this.calculateSubTotals();
    }
  }

  calculateSubTotals = () => {
    let subTotals = {
      cashMarketSubTotal: 0
    }

    this.assets.forEach(asset => {
      subTotals.cashMarketSubTotal += Number(asset.cashMarketValue || 0);
    });
    this.cashMarketSubTotal = subTotals.cashMarketSubTotal;
    this.changedvalue.emit(this.cashMarketSubTotal)
  }

}
