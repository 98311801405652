import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AvailableAlignmentsDialogComponent } from './components/available-alignments-dialog/available-alignments-dialog.component';
import { InternalContactsComponent } from './components/internal-contacts.component';
import { TasksReassignmentDialogComponent } from './components/tasks-reassignment-confimation-dialog/tasks-reassignment-confimation-dialog.component';
import { BranchReAssignmentConfirmationDialogComponent } from './components/branch-reassignment-confirmation-dialog/branch-reassignment-confirmation-dialog.component';
import { InternalContactsHistoryComponent } from './components/internal-contacts-history/internal-contacts-history.component';
import { TableModule } from 'primeng/table';
import { CorrespondenceModule } from '../correspondence/correspondence.module';

@NgModule({
    imports: [
        SharedModule,
        TableModule,
        CorrespondenceModule
    ],
    declarations: [
        InternalContactsComponent,
        AvailableAlignmentsDialogComponent,
        TasksReassignmentDialogComponent,
        BranchReAssignmentConfirmationDialogComponent,
        InternalContactsHistoryComponent
    ],
    exports: [
        InternalContactsComponent,
        AvailableAlignmentsDialogComponent
    ]
})
export class InternalContactsModule {
  constructor() { }
}
