<div class="modal-header">
  <h5 class="modal-title"> Please fill required information </h5>
  <button type="button" class="btn-close" (click)="onDismiss()" aria-label="Close"></button>
</div>
<div class="modal-body d-flex align-items-center justify-content-center" style="overflow: visible; min-height: 320px;">
  <form #preFeeWizardForm="ngForm">
    <div class="mb-3 row mx-2">
      <label class="form-label" for="fee_wizard_exstimatedClosingDate">Estimated Closing Date</label>
      <date-input
        id="fee_wizard_exstimatedClosingDate"
        name="fee_wizard_exstimatedClosingDate"
        [(ngModel)]="estimatedClosingDate"
        (ngModelChange)="onEstimatedClosingDateChanged()"
        [required]="true"
      ></date-input>
    </div>
    <div class="mb-3 row mx-2" *ngIf="showApplicationDate">
      <label class="form-label" for="fee_wizard_application-date">Application Date</label>
      <date-input
        id="fee_wizard_application-date"
        name="fee_wizard_application-date"
        [ngModel]="applicationDate"
        [readonly]="true"
      ></date-input>
    </div>
    <div class="mb-3 row mx-2">
      <label class="form-label" for="fee_wizard_disbursement">Disbursement</label>
      <date-input
        id="fee_wizard_disbursement"
        name="fee_wizard_disbursement"
        [ngModel]="disbursement"
        [readonly]="true"
      ></date-input>
    </div>
    <div class="mb-3 row mx-2">
      <label class="form-label" for="fee_wizard_firstPayment">First Payment Date</label>
      <date-input
        id="fee_wizard_firstPayment"
        name="fee_wizard_firstPayment"
        [ngModel]="firstPayment"
        [readonly]="true"
      ></date-input>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
  <button class="btn btn-primary float-end" type="button" (click)="onSave()" [disabled]="!estimatedClosingDate">
    <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Save
  </button>
</div>
