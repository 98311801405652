import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CityCountyState } from 'src/app/models/city-county-state.model';

@Component({
  selector: 'city-county-picker-dialog',
  templateUrl: 'city-county-picker-dialog.component.html',
  styleUrls: ['city-county-picker-dialog.component.scss'],
})
export class CityCountyPickerDialogComponent implements OnInit {
  @Input()
  set optionsToPickFrom(options: CityCountyState[]) {
    this._optionsToPickFrom = options;
    this.serializedOptions = options.map((o) => o.city + '-' + o.county);
  }

  serializedOptions: string[] = [];

  private _optionsToPickFrom: CityCountyState[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  selectedOption: string = null;

  @Input()
  title: string = 'Pick a City/County';

  ngOnInit() {}

  onOkClicked = () => {
    const selectedOption = this._optionsToPickFrom.find(
      (o) => o.city + '-' + o.county === this.selectedOption
    );
    this.activeModal.close(selectedOption);
  };
}
