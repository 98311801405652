import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHaveMortgageLtvCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';

@Component({
  selector: 'loan-ltv-summary',
  templateUrl: 'loan-ltv-summary.component.html',
  styleUrls: ['loan-ltv-summary.component.scss']
})

export class LoanLtvSummaryComponent implements OnInit {

  @Output()
  closeRequested: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  mortgageId: number;

  @Input()
  ltvDetails: IHaveMortgageLtvCalculationDetails;

  constructor() { }

  ngOnInit() { }

  onClickedCloseLoanRatios = () => {
    this.closeRequested.emit();
  }
}
