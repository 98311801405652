import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'enter-payoff-dialog',
  templateUrl: './enter-payoff-dialog.component.html',
  styleUrls: ['./enter-payoff-dialog.component.scss']
})
export class EnterPayoffDialogComponent implements OnInit {

  @Input()
  unpaidBalance: number;

  @Input()
  partialPayoffAmount: number;

  isMax: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.checkPayoffAmount();
  }

  checkPayoffAmount = () => {
    if (this.partialPayoffAmount >= this.unpaidBalance) {
      this.partialPayoffAmount = this.unpaidBalance;
      this.isMax = true;
    } else {
      this.isMax = false;
    }
  }

}
