import { Component, HostListener, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, forkJoin, from } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { ApplicationContext, LoanApplication, LosCredential, LosProviderFeature, LosVendor, ThirdPartyCredentialType, UserType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2';
import { EncompassDisclosureWizardComponent } from '../encompass-disclosure-wizard/components/encompass-disclosure-wizard/encompass-disclosure-wizard.component';

@Component({
  selector: 'app-run-disclosures',
  templateUrl: './run-disclosures.component.html',
  styleUrls: ['./run-disclosures.component.scss']
})
export class RunDisclosuresComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild(EncompassDisclosureWizardComponent) wizard: EncompassDisclosureWizardComponent | undefined;

  losVendors: LosVendor[] = [];
  selectedLosVendor: LosVendor = null;

  disclosureCredentials: LosCredential[] = [];
  selectedCredential: LosCredential = null;

  applicationId: number = null;
  losIdentifier: string = null;
  isTpoUser: boolean = false;

  isLoading: boolean = false;
  context: ApplicationContext;

  docProvidersOptionSelected: boolean = false;
  losOptionSelected: boolean = false;

  sendFrom: string;
  error: string = null;

  docProviders: EnumerationItem[] = [];
  selectedDocProvider: string = null;

  application: LoanApplication;

  wizardShowing: boolean = false;

  protected userCanRunStreamlinedDisclosures: boolean = false;

  private _loanInfoChangesSubscription: Subscription;
  private _losLoanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);


    this._loanInfoChangesSubscription = this.applicationContextService.loanLosLdeChanges.subscribe((context) => {
      this.initialize(context);
    });
  }

  ngOnInit(): void {

    //TODO: Remove this after testing
    this.userCanRunStreamlinedDisclosures =  this.applicationContext.userPermissions.userType === UserType.Tpo && 
        this.applicationContext.userPermissions.companyId === 222;

    if (this.applicationContext?.application?.applicationId) {

      var loEmail = this.applicationContext.currentMortgage.originatorInformation.email;
      var borrEmails = this.applicationContext.currentMortgage.borrowers.map(x => x.primaryEmail);
      let unique = [...new Set(borrEmails)];

      if (this.applicationContext.currentMortgage.borrowers.filter(x => x.primaryEmail?.toLowerCase() == loEmail?.toLowerCase()).length > 0) {
        this.error = "The Loan Officer Email can NOT match any of the borrower emails.";
      }
      else if (unique.length != borrEmails.length) {
        this.error = "Borrowers must have unique email addresses.";
      }
      else {
        this.initialize(this.applicationContext);
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._losLoanInfoChangesSubscription) {
      this._losLoanInfoChangesSubscription.unsubscribe();
    }
  }

  confirm = (): boolean | Observable<boolean> => {
    return from(this.showConfirmationForNavigatingAway());
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(this.wizard && this.wizard.isRunning());
  }

  onSendFromChange = () => {
    this.docProvidersOptionSelected = this.sendFrom == "docProvider";
    this.losOptionSelected = !this.docProvidersOptionSelected;

    if (this.docProvidersOptionSelected) {
      if (this.docProviders.length == 1) {
        this.selectedDocProvider = this.docProviders[0].value;
      }
    } else {
      this.selectedDocProvider = null;
    }
  }

  onSelectedDocProviderChanged = () => {
    if (this.selectedDocProvider && !this.docProvidersOptionSelected) {
      this.docProvidersOptionSelected = true;
    }
  }

  onNextClicked = () => {
    this.wizardShowing = true;
  }

  private initialize(context: ApplicationContext) {
    this.context = context;
    this.application = this.context.application;
    this.applicationId = this.application.applicationId;
    this.losIdentifier = this.application.losIdentifier;
    this.isTpoUser = this.applicationContext.userPermissions.userType == UserType.Tpo;

    this.getVendors();
  }

  private getVendors = () => {
    this.isLoading = true;

    forkJoin({
      credVenderNames: this._thirdPartyCredentialsService.getCredentialVendorNamesForCompany(ThirdPartyCredentialType.Disclosure),
      losCredentials: this._losService.getLosCredentialsForLoan(this.applicationId)
    })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (multiResult) => {

          if (multiResult.credVenderNames.length > 0) {
            const allVendors = this._thirdPartyCredentialsService.getVendors({ credentialType: "Disclosure" } as any)
            this.docProviders = allVendors.filter(v => multiResult.credVenderNames.includes(v.value));
          }
          else {
            this.docProviders = [];
          }

          this.disclosureCredentials = multiResult.losCredentials.filter(d => d.vendorFeatures.filter(f => f == LosProviderFeature.DisclosureGen).length);
          this.losVendors = [...new Set(this.disclosureCredentials.map(cred => cred.losVendor).filter(v => !!v))];

          if (this.losVendors.length) {
            this.selectedLosVendor = this.losVendors.find(lv => lv == this.application.losVendor) || null;
            this.selectedCredential = this.selectedLosVendor ? this.disclosureCredentials.find(cred => cred.losVendor == this.selectedLosVendor) : this.disclosureCredentials.length == 1 ? this.disclosureCredentials[0] : null;
            if (!this.selectedLosVendor && this.selectedCredential) {
              this.selectedLosVendor = this.selectedCredential.losVendor;
            }

            // If there are no Doc vendors, directly go into the LOS based wizard
            if (!this.docProviders.length) {
              this.losOptionSelected = true;
              this.onNextClicked();
            }
          }
        },
        error: () => {
          this._notificationService.showError(`'Unable to fetch disclosure providers.`, 'Disclosure Providers');
        }
      });
  }

  private showConfirmationForNavigatingAway = async (): Promise<boolean> => {
    const self = this;

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to leave the disclosures wizard?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    });

    if (!result.value) {
      return false;
    }

    self._losService.unlockApplication(self.wizard.credentialId, self.applicationId, self.wizard.lockId).subscribe();
    return true;
  }
}
