<div class="row">
  <div class="col-md-3">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> Compliance Timeline </h4>
      </div>
      <div class="card-body">
        <div class="mb-2">
          <label> Application Date </label>
          <date-input [name]="'applicationDate'" [id]="'applicationDate'"
            [(ngModel)]="keyDateValues['ApplicationReceived']"></date-input>
        </div>
        <div class="mb-2">
          <label> LE Due </label>
          <date-input [name]="'leDue'" [id]="'leDue'" [(ngModel)]="keyDateValues['LEDue']"></date-input>
        </div>
        <!-- <div class="mb-2">
          <label> eConsent </label>
          <date-input [name]="'eConsent'" [id]="'eConsent'" readonly="true"></date-input>
        </div> -->
        <div class="mb-2">
          <label> Intent to Proceed </label>
          <date-input [name]="'intentToProceedConfig'" [id]="'intentToProceedConfig'"
            [(ngModel)]="keyDateValues['IntentToProceedReceived']"></date-input>
        </div>
        <div class="mb-2">
          <label> Earliest Fee Collection </label>
          <date-input [name]="'earliestFeeCollection'" [id]="'earliestFeeCollection'"
            [(ngModel)]="keyDateValues['EarliestFeeCollection']"></date-input>
        </div>
        <div class="mb-2">
          <label> Earliest Closing </label>
          <date-input [name]="'earliestClosing'" [id]="'earliestClosing'"
            [(ngModel)]="keyDateValues['EarliestClosing']"></date-input>
        </div>
        <div class="mb-2">
          <label> Estimated Closing </label>
          <date-input [name]="'estimatedClosing'" [id]="'estimatedClosing'"
            [(ngModel)]="keyDateValues['EstimatedClosing']"></date-input>
        </div>
        <div class="mb-2">
          <label> Disclosure Tracking Timezone </label>
          <input name="disclosureTrackingTimezone" id="disclosureTrackingTimezone" class="form-control"
            [(ngModel)]="timeZone" readonly />
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> LE Tracking </h4>
      </div>
      <div class="card-body">
        <div class="mb-2">
          <label> LE Sent </label>
          <date-input [name]="'leSent'" [id]="'leSent'" [(ngModel)]="keyDateValues['LEIssued']"></date-input>
        </div>
        <div class="mb-2">
          <label> LE Recieved </label>
          <date-input [name]="'leRecieved'" [id]="'leRecieved'" [(ngModel)]="keyDateValues['LESigned']"></date-input>
        </div>
        <div class="mb-2">
          <label> Revised LE Sent </label>
          <date-input [name]="'revisedLeSent'" [id]="'revisedLeSent'"
            [(ngModel)]="keyDateValues['LERevisedIssued']"></date-input>
        </div>
        <div class="mb-2">
          <label> Revised LE Recieved </label>
          <date-input [name]="'revisedLeRecieved'" [id]="'revisedLeRecieved'"
            [(ngModel)]="keyDateValues['LERevisedSigned']"></date-input>
        </div>
        <div class="mb-2">
          <label> SSPL Sent </label>
          <date-input [name]="'ssplSent'" [id]="'ssplSent'"
            [(ngModel)]="keyDateValues['SettlementServiceProviderListSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> Safe Harbor Sent </label>
          <date-input [name]="'safeHarborSent'" [id]="'safeHarborSent'"
            [(ngModel)]="keyDateValues['SafeHarborSent']"></date-input>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> CD Tracking </h4>
      </div>
      <div class="card-body">
        <div class="mb-2">
          <label> CD Sent </label>
          <date-input [name]="'cdSent'" [id]="'cdSent'" [(ngModel)]="keyDateValues['CDIssued']"></date-input>
        </div>
        <div class="mb-2">
          <label> CD Recieved </label>
          <date-input [name]="'cdRecieved'" [id]="'cdRecieved'" [(ngModel)]="keyDateValues['CDSigned']"></date-input>
        </div>
        <div class="mb-2">
          <label> Revised CD Sent </label>
          <date-input [name]="'revisedCdSent'" [id]="'revisedCdSent'"
            [(ngModel)]="keyDateValues['CDRevisedIssued']"></date-input>
        </div>
        <div class="mb-2">
          <label> Revised CD Recieved </label>
          <date-input [name]="'revisedCdRecieved'" [id]="'revisedCdRecieved'"
            [(ngModel)]="keyDateValues['CDRevisedSigned']"></date-input>
        </div>
        <div class="mb-2">
          <label> Post Consummation Disclosure Sent </label>
          <date-input [name]="'postConsummationDisclosureSent'" [id]="'postConsummationDisclosureSent'"
            [(ngModel)]="keyDateValues['PostConsummationDisclosureSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> Post Consummation Disclosure Recieved </label>
          <date-input [name]="'postConsummationDisclosureRecieved'" [id]="'postConsummationDisclosureRecieved'"
            [(ngModel)]="keyDateValues['PostConsummationDisclosureSigned']"></date-input>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> Other Tracking </h4>
      </div>
      <div class="card-body">
        <div class="mb-2">
          <label> Affiliated Business Disclosure Provided </label>
          <date-input [name]="'affiliatedBusinessDisclosureProvided'" [id]="'affiliatedBusinessDisclosureProvided'"
            [(ngModel)]="keyDateValues['AffiliatedBusinessDisclosureSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> CHARM Brooklet Provided </label>
          <date-input [name]="'charmBrookletProvided'" [id]="'charmBrookletProvided'"
            [(ngModel)]="keyDateValues['CharmBookletSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> Special Info Brooklet Provided </label>
          <date-input [name]="'specialInfoBrookletProvided'" [id]="'specialInfoBrookletProvided'"
            [(ngModel)]="keyDateValues['SpecialInfoBookletSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> HELOC Brochure Provided </label>
          <date-input [name]="'helocBrochureProvided'" [id]="'helocBrochureProvided'"
            [(ngModel)]="keyDateValues['HelocBrochureSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> 1st Appraisal Provided </label>
          <date-input [name]="'firstAppraisalProvided'" [id]="'firstAppraisalProvided'"
            [(ngModel)]="keyDateValues['FirstAppraisalSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> Subsequent Appraisal Provided </label>
          <date-input [name]="'subsequentAppraisalProvided'" [id]="'subsequentAppraisalProvided'"
            [(ngModel)]="keyDateValues['SubsequentAppraisalSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> AVM Provided </label>
          <date-input [name]="'avmProvided'" [id]="'avmProvided'" [(ngModel)]="keyDateValues['AvmSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> Home Counseling Disclosure Provided </label>
          <date-input [name]="'homeCounselingDisclosureProvided'" [id]="'homeCounselingDisclosureProvided'"
            [(ngModel)]="keyDateValues['HomeCounselingDisclosureSent']"></date-input>
        </div>
        <div class="mb-2">
          <label> High Cost Disclosure </label>
          <date-input [name]="'highCostDisclosure'" [id]="'highCostDisclosure'"
            [(ngModel)]="keyDateValues['HighCostDisclosureSent']"></date-input>
        </div>
      </div>
    </div>
  </div>
  <div class="text-end me-3">
    <button type="button" class="btn btn-outline-primary" (click)="save()">
      Save
    </button>
  </div>
</div>