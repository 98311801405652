import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'app-vendor-picker-modal',
  templateUrl: './vendor-picker-modal.component.html',
  styleUrls: ['./vendor-picker-modal.component.scss']
})
export class VendorPickerModalComponent implements OnInit {

  @ViewChild("vendorPickerForm")
  vendorPickerForm: NgForm;

  @Input()
  vendors: EnumerationItem[] = [];

  @Input()
  type: string // ThirdPartyCredentialType;

  selectedVendor: EnumerationItem = new EnumerationItem("-- Select One --", null);

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.vendors.length == 1) {
      this.selectedVendor = this.vendors[0];
    }
  }

  onOkClicked = () => {
    this.vendorPickerForm.form.markAllAsTouched();
    if (this.vendorPickerForm.form.valid) {
      const selectedPricingVendor = this.vendors.find(vendor => vendor.value == this.selectedVendor.value);
      this.activeModal.close(selectedPricingVendor.name);
    }
  }
}
