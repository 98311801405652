import { Injectable } from '@angular/core';
import { DataService } from '../../../../core/services/data.service';
import { delay, firstValueFrom, of } from 'rxjs';
import { CampaignSubscriptionModel } from '../../../../models/campaign/campaign-subscription.model';

@Injectable()
export class EmailPreferenceCenterService {
  constructor(private readonly _dataService: DataService) { }

  getSubscribed(companyGuid: string, messageGuid: string, entry: string): Promise<CampaignSubscriptionModel[]> {
    const url = `api/campaign/${companyGuid}/subscribed/${messageGuid}`;
    return firstValueFrom(this._dataService.post(url, `"${entry}"`));
  }

  subscribe(campaignGuid: string, entry: string): Promise<void> {
    const url = `api/campaign/${campaignGuid}/subscribe/${encodeURIComponent(entry)}`;
    return firstValueFrom(this._dataService.post(url, null));
  }

  unsubscribe(campaignGuid: string, entry: string): Promise<void> {
    const url = `api/campaign/${campaignGuid}/unsubscribe/${encodeURIComponent(entry)}`;
    return firstValueFrom(this._dataService.post(url, null));
  }

  unsubscribeAll(companyGuid: string, entry: string, messageGuid: string): Promise<void> {
    const url = `api/campaign/${companyGuid}/unsubscribe/${encodeURIComponent(entry)}/all?messageGuid=${messageGuid}`;
    return firstValueFrom(this._dataService.post(url, null));
  }
}
