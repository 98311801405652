import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocFile } from '../../models/doc-file.model';
import { Instruction } from '../../models/instruction.model';
import { TaskService } from 'src/app/services/task.service';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Borrower, LoanDoc } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { LoanDocsService } from '../../services/loan-docs.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DocuViewareService } from 'src/app/services/docuvieware.service';
import { DocFileList } from '../../models/doc-file-list.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { MergeDocFilesInstruction } from '../../models/merge-files/merge-doc-files-instruction.model';
import { MergeDocFilesRequest } from '../../models/merge-files/merge-doc-files-request.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Observable, catchError, of, tap } from 'rxjs';

@Component({
  selector: 'doc-files-edit-dialog',
  templateUrl: 'doc-files-edit-dialog.component.html',
  styleUrls: ['doc-files-edit-dialog.component.scss']
})
export class DocFilesEditDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('docFilesEditForm') docFilesEditForm: NgForm | undefined;

  @Input()
  title: string;

  @Input()
  fileExtension: string;

  @Input()
  file: DocFile;

  @Input()
  loanDocs: LoanDoc[];

  @Input()
  appId: number;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  isModal: boolean = true;

  instructions: Instruction[] = [];

  taskList: LoanDocTask[] = [];

  docFileList: LoanDoc[] = [];

  docFileListFiltered: LoanDoc[] = [];

  existingPdfDocumentTypeList: any[] = []

  borrowers: Borrower;

  onPageOrderChanged: boolean;

  selectedTask: LoanDocDashboardTask;

  originalFileName: string;

  tempFilter: string;

  docuViewareControlHtml: string;

  isToggleFullScreen: boolean = true;

  private _loadingInterval: any;

  constructor(private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _taskService: TaskService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    private readonly _docuviewareService: DocuViewareService) {
    super(injector);
  }

  ngOnInit() {
    console.log(this.file);
    this.initialize();
  }

  onCancelClicked = () => {
    this.activeModal.dismiss();
  }

  onSaveClicked = () => {
   this.save().subscribe(result => {
    
   });
  }

  onExtractPagesClicked = () => {
    let pages = this._docuviewareService.getSelectedThumbnailItems();
    if (!pages.length) {
      this._notifyService.showError("Please select pages to extract!", 'Failure');
      return;
    }

    pages.forEach(page => {
      this._docuviewareService.setThumbnailSelected(page, false);
    })

    this.instructions.push(new Instruction("", null, "Append", pages.join(',')));
    this.tempFilter = 'Append';
  }

  onRemoveInstructionClicked = (index: number) => {
    this.instructions.splice(index, 1);
  };

  selectedTaskChanged = (loanDocTaskId: string) => {
    if (!!loanDocTaskId) {
      this._taskService.getTaskDashboardViewById(Number(loanDocTaskId)).subscribe((result: LoanDocDashboardTask) => {
        this.selectedTask = result;
      })
    }
  }

  onToggleFullScreen = () => {
    this.isToggleFullScreen = !this.isToggleFullScreen;
    if (!this.isToggleFullScreen) {
      this.changeModalWidth('modal-full-width', 'modal-w-90');
    } else {
      this.changeModalWidth('modal-w-90', 'modal-full-width');
    }
  }

  onLinkTaskToggleClicked = (toggleState: boolean, instruction: Instruction) => {
    if (toggleState) {
      return;
    }
    instruction.loanDocTaskId = undefined;
    instruction.mergeWithExistingLocation = undefined;
  }

  onNewAndExistingFileButtonChanged = (instruction: Instruction, filter: string) => {
    instruction.filter = filter;
    instruction.mergeWithExistingLocation = this.tempFilter;
  }

  onAppendAndPrependButtonChanged = (instruction: Instruction, filter: string) => {
    instruction.mergeWithExistingLocation = filter;
    this.tempFilter = filter;
  }


  onFilterDocFileListClicked = (index: number) => {
    this.docFileListFiltered = this.docFileList.reduce((i, v) => {
      if (v.documentTypeId === Number(this.instructions[index].documentTypeId))
        i.push(v);
      return i;
    }, []);
  }

  public save = (): Observable<MergeDocFilesRequest> => {
    let mergeInstructions: MergeDocFilesInstruction[] = [];
    if (this.instructions.length > 0) {
      this.instructions.forEach(instruction => {
        let mergeInstruction = new MergeDocFilesInstruction(this.file.guid);

        if (instruction.filter === 'mergeWithExisting') {
          mergeInstruction.destinationDocFileGuid = instruction.mergeWithExistingGuid;
          mergeInstruction.mergeMode = instruction.mergeWithExistingLocation;
        } else {
          mergeInstruction.destinationLoanDocTaskId = instruction.loanDocTaskId;
          mergeInstruction.documentTypeId = instruction.documentTypeId;
          mergeInstruction.description = instruction.description;
          mergeInstruction.note = instruction.note;
          mergeInstruction.fileName = instruction.fileName;
          mergeInstruction.mergeMode = 'Append';
        }
        mergeInstruction.customPages = this.getPages(instruction.pages);
        mergeInstruction.removePagesFromSource = instruction.removePagesFromSource;

        mergeInstructions.push(mergeInstruction);
      });
    }

    return new Observable<MergeDocFilesRequest>(observer => {
      this._docuviewareService.saveDocument((result: string) => {
        this._spinner.show();
        let docFileContent = {};
        docFileContent[this.file.guid] = result;
        let file = new MergeDocFilesRequest(
          docFileContent, mergeInstructions,
          (this.file.fileName == this.originalFileName ? null : (this.file.fileName + "." + this.fileExtension)),
          (this.file.fileName == this.originalFileName ? null : this.file.guid),
          this.file.note);

          return this._loanDocsService.mergeDocFiles(file, this.file.loanDocId).pipe(
            tap(result => {
              this._spinner.hide();
              this._notifyService.showSuccess('Document has been successfully saved!', 'Success');
              this.applicationContextService.updateLoanTasks();
              this.activeModal?.close(result);
            }),
            catchError(error => {
              this._spinner.hide();
              this._notifyService.showError(error.message || 'Failed to save the document!', 'Failure');
              return of(undefined);
            }),
          ).subscribe(observer);
      }, () => {
        this._spinner.hide();
        this._notifyService.showError('Failed to edit the document!', 'Failure');
        observer.error('Failed to edit the document!');
      });
    });
  }

  private initialize = () => {
    this.loadLoanDocTasks();
    this.makeDocFileList();
    this.makeExistingPdfDocumentTypeList();
    this.loadBorrowers();

    this._docuviewareService.getDocuViewareMarkup().subscribe(result => {
      this.docuViewareControlHtml = result.htmlContent;
      $("#docuViewer").html(result.htmlContent);
      this._loadingInterval = setInterval(() => this.showDocWhenLoaded(), 500);
    })

    this.originalFileName = this.file.fileName;
  }

  private showDocWhenLoaded = (): void => {
    if (this._docuviewareService.isLoaded()) {
      clearInterval(this._loadingInterval);
      this._loadingInterval = 0;
      this._docuviewareService.registerOnThumbnailsOrderChanged(this.onPageOrderChanged);
      let rotateLeftButton = $("#rotateLeftButtonMergeDocDisplay");
      if (rotateLeftButton) {
        rotateLeftButton[0].onclick = null;
        rotateLeftButton.click(() => {
          this._docuviewareService.postCustomServerAction("RotatePage", { rotation: 270 });
        });
      }

      let rotateRightButton = $("#rotateRightButtonMergeDocDisplay");
      if (rotateRightButton) {
        rotateRightButton[0].onclick = null;
        rotateRightButton.click(() => {
          this._docuviewareService.postCustomServerAction("RotatePage", { rotation: 90 });
        });
      }

      this._loanDocsService.viewLoanDocContent(this.file.guid).subscribe(result => {
        this._docuviewareService.loadFromByteArray(result);
      });
    }
  }

  private loadLoanDocTasks = () => {
    this._spinner.show();
    this._taskService.getTaskByLoan(this.appId).subscribe((result: LoanDocTask[]) => {
      this.taskList = result;
      this._spinner.hide();
    }, err => {
      this._spinner.hide();
    })
  }

  private makeDocFileList = () => {
    if (this.loanDocs) {
      this.docFileList = this.loanDocs.reduce((t, u) => {
        u.docFiles.forEach(docFile => {
          if (docFile.fileName.endsWith(".pdf")) {
            t.push(new DocFileList(docFile.guid, docFile.fileName, u.documentTypeId));
          }
        })
        return t;
      }, []);
    }
  }

  private makeExistingPdfDocumentTypeList = () => {
    if (this.globalConfig) {
      this.existingPdfDocumentTypeList = this.globalConfig.documentType.reduce((t, u) => {
        for (let x = 0; x < this.docFileList.length; x++) {
          if (u.documentTypeId == this.docFileList[x].documentTypeId) {
            t.push(u);
            break;
          }
        }
        return t;
      }, []);
    }
  }

  private loadBorrowers = () => {
    this._loanDocsService.getBorrowers(this.appId).subscribe(result => {
      this.borrowers = result;
    })
  }

  private getPages = (pageAsString: string) => {
    let pages = [];
    let ins = pageAsString.split(',');
    if (!ins) {
      return pages;
    }
    ins.forEach(item => {
      if (item.indexOf('-') > 0) {
        let pts = item.split('-');
        for (let i: any = pts[0]; i <= pts[1]; i++)
          pages.push(i);
      } else
        pages.push(item);
    });
    return pages;
  }

  private changeModalWidth = (oldOne: string, newOne: string) => {
    const query = `[role="dialog"]`;
    let existingOne = document.querySelector(query);
    if (existingOne) {
      existingOne.classList.replace(oldOne, newOne);
    }
  }
}
