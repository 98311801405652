<div class="account-body accountbg"
  [style.background-image]="backgroundImageUrl ? 'url(' + backgroundImageUrl + ')' : ''">
  <img #backgroundTestImage [src]="backgroundImageUrl" height="90" [hidden]="true"
    (error)="onTestBackgroundImageErrored()">

  <div class="container">
    <div class="row vh-100 d-flex justify-content-center align-self-center">

      <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-10 mx-auto align-self-center">
        <div class="card">
          <div class="card-body p-0 login-header">
            <div class="text-center p-3">
              <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
              <h4 class="mb-1" style="color: steelblue; margin-top: 0;" class="mb-4">Oops! Something went wrong!</h4>
              <h5 class="mb-1" style="color: steelblue; margin-top: 0;">
                It looks like we're currently making updates, please stand by...
              </h5>
              <img class="mt-4 mb-3" src="assets/images/busy_128.png" style="margin: auto" />
              <h5 class="mb-1" style="color: steelblue; margin-top: 0;">
                This screen will automatically refresh when we're complete.
              </h5>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="tab-content">
              <div class="px-3 pt-2" *ngIf="error">
                <div class="alert alert-danger border-0 mb-0" role="alert">
                  <strong>
                    <div>{{error.header}}</div>
                  </strong>
                  {{error.message}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body bg-light-alt text-center error-footer">
            <span class="text-muted d-none d-sm-inline-block">Lodasoft © {{currentYear}}
              <script>
                { { document.write(new Date().getFullYear()) } }
              </script>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
