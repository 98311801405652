import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs';
import { UserVoicemail } from 'src/app/models/user/user-voicemail.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ProfileService } from '../../../profile.service';

@Component({
  selector: 'app-voice-mail-templates',
  templateUrl: './voice-mail-templates.component.html',
  styleUrls: ['./voice-mail-templates.component.scss']
})
export class VoiceMailTemplatesComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild('upsertVoicemailDrawer') upsertVoicemailDrawer: DrawerComponent;

  @Input() userCompanyGuid: string;
  voicemailColumns = [];
  voicemails: Array<UserVoicemail> = [];
  upsertVoicemailDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  selectedVoicemail: UserVoicemail;
  loadingFileContentForId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _drawerService: DrawerService,
    private readonly _profileService: ProfileService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _domSanitizer: DomSanitizer) {
    super(injector)
    super.scrollOffset = 240;
  }

  ngOnInit(): void {
    this.loadUserVoicemails();
    this.voicemailColumns = [
      { field: 'name', header: 'Name', visible: true, sortable: true },
      { field: 'file', header: 'File', visible: true, sortable: false },
    ];
  }

  getVoicemailFileAndPlay(voicemail: UserVoicemail) {
    if (voicemail.playableFileUrl) {
      return;
    }
    this.loadingFileContentForId = voicemail.userVoicemailId;
    this._profileService.getUserVoicemailFile(voicemail.userVoicemailId)
      .pipe(finalize(() => this.loadingFileContentForId = null))
      .subscribe({
        next: (blob) => {
          const fileName = `${voicemail.name}.${voicemail.fileType.toLowerCase()}`;
          const voicemailFile = new File([blob], fileName, { type: `audio/${voicemail.fileType.toLowerCase()}` });

          voicemail.playableFileUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(voicemailFile));
        },
        error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to load voicemail file', 'Voicemail');
        }
      });
  }

  showDeleteConfirmationDialog(userVoicemailId: number) {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this voicemail?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      self._spinner.show();
      self._profileService.deleteUserVoicemail(userVoicemailId)
        .pipe(finalize(() => self._spinner.hide()))
        .subscribe({
          next: () => {
            self._notifyService.showSuccess(
              'voicemail deleted successfuly',
              'Voicemail'
            );
            self.removeVoicemailFromGrid(userVoicemailId);
          },
          error: (err) => {
            self._notifyService.showError(
              err?.message || 'Unable to delete voicemail',
              'Voicemail'
            );
          },
        });
    });
  }

  removeVoicemailFromGrid(userVoicemailId: number) {
    this.voicemails = this.voicemails.filter(vm => vm.userVoicemailId !== userVoicemailId);
  }

  loadUserVoicemails() {
    this._profileService.getUserVoicemails(this.userCompanyGuid)
      .subscribe({
        next: (response) => {
          this.voicemails = response;
        },
        error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to fetch user voicemails ', 'User Voicemails')
        }
      });
  }

  onUpsertVoicemailDrawerClose(updatedVoicemail: UserVoicemail) {
    this._drawerService.hide("upsertVoicemailDrawer", 10);
    if (!updatedVoicemail) {
      return;
    }
    const matchingRecordIndex = this.voicemails.findIndex(vm => vm.userVoicemailId === updatedVoicemail.userVoicemailId);
    if (matchingRecordIndex === -1) {
      this.voicemails.push(updatedVoicemail);
      return;
    }
    this.voicemails[matchingRecordIndex] = updatedVoicemail;
  }

  onUpsertVoicemailDrawerOpened(voicemail = null) {
    this.upsertVoicemailDrawer.title = voicemail ? `Edit ${voicemail.name}` : "Add Voicemail";
    this.selectedVoicemail = cloneDeep(voicemail) || {};
    this._drawerService.show("upsertVoicemailDrawer", 10);
  }
}
