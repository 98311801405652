import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { ProcessSubmissionModel } from '../../models/process-submission.model';

@Component({
  selector: 'loan-characteristics',
  templateUrl: './loan-characteristics.component.html',
  styleUrls: ['./loan-characteristics.component.scss']
})
export class LoanCharacteristicsComponent implements OnInit {

  @Input()
  processSubmissionData: ProcessSubmissionModel;

  @Input()
  context;

  @Input()
  allowedLoanCharacterstics;

  @Input()
  allowedBorrowerCharacterstics;

  @Output()
  nextStep: EventEmitter<{ processSubmissionData: ProcessSubmissionModel }> = new EventEmitter<any>();

  @Output()
  previousStep: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.allowedLoanCharacterstics = _.orderBy(this.allowedLoanCharacterstics, [['order'], ['asc']]);
  }

  toggleLoanCharacteristic = (taskCategoryId) => {
    const index = this.processSubmissionData.loanCharacteristics.indexOf(taskCategoryId);
    if (index > -1) {
      this.processSubmissionData.loanCharacteristics.splice(index, 1);
    } else {
      this.processSubmissionData.loanCharacteristics.push(taskCategoryId);
    }
  }

  isLoanCharacteristicChecked = (taskCategoryId) => {
    return this.allowedLoanCharacterstics.findIndex(lc => lc.taskCategoryId == taskCategoryId) > -1;
  }

  back = () => {
    this.previousStep.emit();
  }

  proceed = () => {
    this.nextStep.emit({ processSubmissionData: this.processSubmissionData });
  }

}
