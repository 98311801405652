import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, defaultIfEmpty, defer, forkJoin, from, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { ThirdPartyFeeOptions } from 'src/app/models/fee/third-party-fees-options.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EnumerationService } from '../../../../services/enumeration-service';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { Constants } from '../../../../services/constants';
import { LoanPurposeTypeEnum } from '../../../app-details/components/title-history/models/title-order.model';
import { tap } from 'rxjs/operators';
import { UrlaMortgage } from '../../../urla/models/urla-mortgage.model';
import { cloneDeep } from 'lodash';
import { NgForm } from '@angular/forms';
import { AppDetailsService } from '../../../app-details/services/app-details.service';
import { AddressLookupService } from '../../../../services/address-lookup.service';
import { ZipCodeLookupResult } from '../../../../models/zipcode-lookup-result.model';
import { Address } from '../../../../models';

@Component({
  selector: 'import-fees-from-vendor',
  templateUrl: 'import-fees-from-vendor.component.html'
})
export class ImportFeesFromVendorComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  applicationId: number;

  @Input()
  supportedVendorNames: string[] = [];

  @Output()
  vendorSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  vendorFeesRetrieved: EventEmitter<LoanFee[]> = new EventEmitter<LoanFee[]>();

  @Output()
  vendorQuestionsRetrieved: EventEmitter<ThirdPartyFeeOptions> = new EventEmitter<ThirdPartyFeeOptions>();

  @Output()
  executionStarted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  executionCompleted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  makeVendorFeesSkipVisible: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('invalidFieldsForm')
  protected invalidFieldsForm: NgForm;

  selectedVendorName: string;

  thirdPartyFeeOptions: ThirdPartyFeeOptions;

  initialValidationErrorMessage: string = null;

  progressMessage: string = "";

  questionnaireRunning: boolean = false;

  questionnaireStarted: boolean = false;

  acquiredFeesFromVendor: boolean = false;

  defaultFeeProviderName: string;

  private _originalMortgage: UrlaMortgage;
  protected currentMortgage: UrlaMortgage;

  protected fieldValidationConfig: ValidFieldsConfig = new ValidFieldsConfig();

  protected loanPurposeOptions: EnumerationItem[] = [];
  protected mortgageAppliedForOptions: EnumerationItem[] = [];
  protected stateOptions: EnumerationItem[] = [];

  protected isSubjectPropertyAddressTbd: boolean = false;

  protected get canSaveLoanInfo(): boolean {
    return this._saveLoanInfoSubscription == null;
  }

  private _initCurrentMortgageSubscription: Subscription | null = null;
  private _initEnumerationsSubscription: Subscription | null = null;
  private _initVendorsSubscription: Subscription | null = null;
  private _saveLoanInfoSubscription: Subscription | null = null;
  private _appContextSubscription: Subscription | null = null;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _feeService: FeeService,
    private readonly _contextService: ApplicationContextService,
    private readonly _enumerationService: EnumerationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _addressLookupService: AddressLookupService,
    injector: Injector,
  ) {
    super(injector);
    this._appContextSubscription = this._contextService.context.subscribe(ctx => {
      this.defaultFeeProviderName = ctx.userPermissions.defaultFeeProvider;
    });
  }

  ngOnInit() {
    this.initEnumerations();
    this.initCurrentMortgage();
  }

  ngOnDestroy() {
    this._initCurrentMortgageSubscription?.unsubscribe();
    this._initEnumerationsSubscription?.unsubscribe();
    this._initVendorsSubscription?.unsubscribe();
    this._saveLoanInfoSubscription?.unsubscribe();
    this._appContextSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  private initCurrentMortgage(mortgage?: UrlaMortgage): void {
    this._initCurrentMortgageSubscription?.unsubscribe();

    const currentMortgage$ = defer(() => {
      if (mortgage != null) {
        return of(mortgage);
      }

      const existingMortgage = this.applicationContext?.currentMortgage;
      if (existingMortgage != null) {
        return of(existingMortgage);
      }

      return this._contextService.context.pipe(
        map(ctx => ctx.currentMortgage),
      );
    });

    this._initCurrentMortgageSubscription = currentMortgage$.subscribe(mortgage => {
      this._originalMortgage = mortgage;
      this.currentMortgage = cloneDeep(mortgage);

      this.initValidFieldsConfig();

      if (this.fieldValidationConfig.isAllValid) {
        this.initialValidationErrorMessage = null;
        if (this.defaultFeeProviderName) {
          this.selectedVendorName = this.supportedVendorNames.find(v => v.toLowerCase() === this.defaultFeeProviderName?.toLowerCase());
          this.onVendorSelectionChanged();
        } else if (this.supportedVendorNames.length == 1) {
          this.selectedVendorName = this.supportedVendorNames[0];
          this.onVendorSelectionChanged();
        }
      } else {
        this.initialValidationErrorMessage = 'Data is missing from the loan. Please correct to load fees from a vendor.';
        this.executionCompleted.emit();
      }
    });
    this._initCurrentMortgageSubscription.add(() => {
      this._initCurrentMortgageSubscription = null;
    });
  }

  private initEnumerations(): void {
    this._initEnumerationsSubscription?.unsubscribe();

    this.stateOptions = this._enumerationService.states;
    this._initEnumerationsSubscription = this._enumerationService
      .getMortgageEnumerations().subscribe(enums => {
        this.loanPurposeOptions = enums[Constants.enumerations.loanPurposes];
        this.mortgageAppliedForOptions = enums[Constants.mortgageEnumerations.mortgageAppliedForType];
      });
    this._initEnumerationsSubscription.add(() => {
      this._initEnumerationsSubscription = null;
    });
  }

  private initValidFieldsConfig(): void {
    const mortgage = this._originalMortgage;
    const subjectProperty = mortgage.subjectProperty;
    const mortgageTerm = mortgage.mortgageTerm;

    this.fieldValidationConfig = new ValidFieldsConfig({
      address1: !!subjectProperty.address1,
      city: !!subjectProperty.city,
      state: !!subjectProperty.state,
      county: !!subjectProperty.county,
      purposeOfLoan: !!subjectProperty.purposeOfLoan,
      mortgageAppliedFor: !!mortgageTerm.mortgageAppliedFor,
      amount: !!mortgageTerm.amount,
      purchasePriceAmount: this.isPurchasePriceAmountValid(),
    });

    this.isSubjectPropertyAddressTbd = (subjectProperty?.address1?.toLowerCase() === 'tbd');
  }

  private isPurchasePriceAmountValid(
    loanPurpose?: LoanPurposeTypeEnum,
  ): boolean {
    loanPurpose ??=
      this._originalMortgage.subjectProperty.purposeOfLoan as LoanPurposeTypeEnum;
    return loanPurpose === LoanPurposeTypeEnum.Purchase
      ? !!this._originalMortgage.transactionDetail.purchasePriceAmount
      : true;
  }

  protected onChangeAddress(address: Partial<Address>): void {
    Object.assign(this.currentMortgage.subjectProperty, address);
  }

  protected onChangeZipCode(result: ZipCodeLookupResult): void {
    const address = this._addressLookupService.parseZipCodeLookup(result);
    if (address.zipCode == null) {
      return;
    }

    Object.assign(this.currentMortgage.subjectProperty, address);
  }

  protected onChangeLoanPurpose(value: LoanPurposeTypeEnum): void {
    this.fieldValidationConfig = new ValidFieldsConfig({
      ...this.fieldValidationConfig,
      purchasePriceAmount: this.isPurchasePriceAmountValid(value),
    });

    if (!this.fieldValidationConfig.purchasePriceAmount) {
      delete this.currentMortgage.transactionDetail.purchasePriceAmount;
    }
  }

  protected onVendorSelectionChanged(): void {
    this.vendorSelectionChanged.emit(this.selectedVendorName);
  }

  protected onVendorQuestionnaireCompleted(
    thirdPartyFeeOptions: ThirdPartyFeeOptions,
  ): void {
    const requestFeesObserver = {
      next: (loanFeesFromVendor => {
        this.acquiredFeesFromVendor = true;
        this.questionnaireRunning = false;
        this.executionCompleted.emit();
        this.vendorFeesRetrieved.emit(loanFeesFromVendor);
      }),
      error: (error => {
        this.questionnaireRunning = false;
        this.initialValidationErrorMessage = error.error ? error.error.message : 'An error occurred while requesting third party loan fees.';
        this.executionCompleted.emit();
        this._notificationService.showError(this.initialValidationErrorMessage, 'Error!');
      })
    }
    this.initialValidationErrorMessage = null;
    this.questionnaireRunning = true;
    this.progressMessage = "Requesting Vendor Fees..."
    this.executionStarted.emit();
    this._feeService.requestThirdPartytFees(
      this.applicationId,
      thirdPartyFeeOptions,
    ).subscribe(requestFeesObserver);
  }

  protected onSubmitInvalidFieldsForm(): void {
    const form = this.invalidFieldsForm.form;
    form.markAllAsTouched();

    if (form.invalid) {
      this._notificationService.showError(
        'Please correct the errors on the form.',
        'Error');
      return;
    }
    this._saveLoanInfoSubscription?.unsubscribe();

    const application = this.applicationContext.application;
    application.mortgageLoan = this.currentMortgage;

    const loanInfo = {
      application: application,
      customData: null,
    };

    form.disable();

    this._saveLoanInfoSubscription = this._appDetailsService.saveLoanInfo(
      application.applicationId,
      loanInfo,
    ).pipe(
      catchError(error => {
        const defaultErrorMessage = 'An error occurred while saving loan info.';
        const errorMessage = error.error ? error.error.message : defaultErrorMessage;

        console.error(defaultErrorMessage, error);
        this._notificationService.showError(errorMessage, 'Error');

        // Revert the mortgage loan to the original value if the save fails.
        application.mortgageLoan = this._originalMortgage;

        return of(undefined);
      }),
    ).subscribe((result) => {
      const application = result?.application;
      if (!application) {
        return;
      }

      this._contextService.updateMortgageAndApplication(
        application.mortgageLoan,
        application,
      );

      this._notificationService.showSuccess(
        'Loan info saved successfully.',
        'Success',
      );

      this.initCurrentMortgage(application.mortgageLoan);
    });
    this._saveLoanInfoSubscription.add(() => {
      form.enable();
      this._saveLoanInfoSubscription = null;
      if (this.fieldValidationConfig.isAllValid) {
        this.makeVendorFeesSkipVisible.emit();
      }
    });
  }

  public startVendorQuestionnaire(): void {
    this.questionnaireStarted = true;
    const observer = {
      next: (options => {
        if (!options.options.inspectionFeesRequestOptions) {
          options.options.inspectionFeesRequestOptions = {
            property: {},
            asbestosInspectionRequestOptions: {},
            drywallInspectionRequestOptions: {},
            leadInspectionRequestOptions: {},
            moldInspectionRequestOptions: {}
          }
        }
        this.questionnaireRunning = false;
        this.thirdPartyFeeOptions = options;
        this.vendorQuestionsRetrieved.emit(options);
        this.executionCompleted.emit();
      }),
      error: (error => {
        this.questionnaireStarted = false;
        this.questionnaireRunning = false;
        this.executionCompleted.emit();
        this.initialValidationErrorMessage = 'An error occurred while getting third party provider fee options.';
        if (error.error && error.error.message) {
          if (!error.error.message.includes('<!DOCTYPE html>')) {
            this.initialValidationErrorMessage = error.error.message;
          }
        }
        this._notificationService.showError(this.initialValidationErrorMessage, 'Error!');
      })
    }
    this.initialValidationErrorMessage = null;
    this.questionnaireRunning = true;
    this.progressMessage = "Loading Vendor Questions...";
    this.executionStarted.emit();
    this._feeService.getThirdPartyFeeOptions(
      this.applicationId,
      this.selectedVendorName,
    ).subscribe(observer);
  }
}

class ValidFieldsConfig {
  readonly address1: boolean = false;
  readonly city: boolean = false;
  readonly state: boolean = false;
  readonly county: boolean = false;
  readonly purposeOfLoan: boolean = false;
  readonly mortgageAppliedFor: boolean = false;
  readonly amount: boolean = false;
  readonly purchasePriceAmount: boolean = false;

  get isAllValid(): boolean {
    return this.isAddressValid &&
      this.purposeOfLoan &&
      this.mortgageAppliedFor &&
      this.amount &&
      this.purchasePriceAmount;
  }

  get isAddressValid(): boolean {
    return this.address1 && this.city && this.state && this.county;
  }

  constructor(
    args?: Omit<ValidFieldsConfig, 'isAddressValid' | 'isAllValid'>,
  ) {
    if (!args) {
      return;
    }

    this.address1 = args.address1;
    this.city = args.city;
    this.state = args.state;
    this.county = args.county;
    this.purposeOfLoan = args.purposeOfLoan;
    this.mortgageAppliedFor = args.mortgageAppliedFor;
    this.amount = args.amount;
    this.purchasePriceAmount = args.purchasePriceAmount;
  }
}
