<ng-container *ngIf="!isLoading; else loadingContent">
    <div class="fieldset-title">Document Export Format</div>
    <div class="pt-3">
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="custom-control-label">Format to use</label>
            </div>
            <div class="col-md-4">
                <select class="form-select" [(ngModel)]="documentExportFormat.valueStr">
                    <option [ngValue]="">-- Select One --</option>
                    <option value="originalfilename">/Loan/(OriginalFileName as FileName)</option>
                    <option value="original">/Loan/(DocType - BorrowerLast, BorrowerFirst as FileName)</option>
                    <option value="originalreverse">/Loan/(DocType - BorrowerFirst BorrowerLast as FileName)</option>
                    <option value="original2">/Loan/(BorrowerLast, BorrowerFirst - DocType as FileName)</option>
                    <option value="original2reverse">/Loan/(BorrowerFirst BorrowerLast - DocType as FileName)</option>
                    <option value="borroweroriginalfilename">/Loan/(BorrowerLast, BorrowerFirst - OriginalFileName as
                        FileName)
                    </option>
                    <option value="borroweroriginalfilenamereverse">/Loan/(BorrowerFirst BorrowerLast - OriginalFileName
                        as FileName)
                    </option>
                    <option value="borrowerdoctype">/Borrower/(DocType as FileName)</option>
                    <option value="borrowerdoctypefilename">/Borrower/DocType/OriginalFileName</option>
                </select>
            </div>
        </div>
    </div>
    <div class="text-end">
        <div class="col-md-12 my-1">
            <hr/>
        </div>
        <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>
</ng-container>
<ng-template #loadingContent>
    <loading-indicator loadingMessage="Loading Export Formats..."
        [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>
