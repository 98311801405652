import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Borrower } from './borrower.model';
import { CustomData } from './custom-data.model';
import { HomeEquityInvestmentModel } from './home-equity-investment.model';
import { ProductPricing } from './product-pricing.model';
import { SolarInfoModel } from './solar-info.model';
import { MortgageLanguage } from '../mortgage.model';

export class NewApplicationRequest {
  application: LoanApplication;
  customData: CustomData;
  primaryRoleUserId: string;
  coBorrower: Borrower;
}

export class LoanApplication {
  applicationId: number;
  userId: string;
  primaryBorrowerId: number;
  loanStatusId: number;
  subStatusId: number;
  adverseReasonId: number;
  loanTypeId: number;
  loanPurposeId: number;
  customDataId: number;
  productPricingId: number;
  existingLenderId: number;
  hoiAgentId: number;
  titleAgentId: number;
  escrowAgentId: number;
  lenderId: number;
  leadId: number;
  leadCampaignId: number;
  leadRefId: string;
  leadSource: string;
  onlineApplicationName: string;
  onlineApplicationVersion: string;
  pauseMessages: boolean;
  branchId: number;
  duCasefuleId: string;
  ldeRefNumber: string;
  ldeIdentifier: string;
  ldeInstanceId: string;
  ldeVendor: string;
  ldeSyncDir: DataExchangeSyncDir;
  refNumber: string;
  losIdentifier: string;
  losInstanceId: string;
  losVendor: string;
  losCreditScore?: number;
  losSyncDir: DataExchangeSyncDir;
  loanAmount: number;
  totalLoanAmount: number;
  mipAmount: number;
  cashOutAmount: number;
  subordinateLienAmount: number;
  purchasePrice: number;
  presentValue: number;
  downAmount: number;
  ltv: number;
  cltv: number;
  hcltv: number;
  estimatedClosingDate: string;
  closingDate: string;
  fundingDate: string;
  cdIssueDate: string;
  cdSignedDate: string;
  originationDate: string;
  payoffExpirationDate: string;
  lastPaymentDate: string;
  disbursementDate: string;
  firstPaymentDate: string;
  mailingStreet: string;
  mailingPOBox: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  monsterInsightsUrl: string;
  referralSource: number;
  secondaryReferralSource: number;
  referredTo: number;
  insideReferralSource: string;
  firstAttemptedContact: string;
  firstSuccessfulContact: string;
  lastAttemptedContact: string;
  lastSuccessfulContact: string;
  redistributionCount: number;
  lastRedistributionDate: string;
  fetchCount: number;
  lastFetchDate: string;
  contactAttempts: number;
  isMismo34Application: boolean;
  channel: string;
  disclosurePath: string;
  externalCompanyId: number;
  contactListId: number;
  contactListRecordId: number;
  copiedFromApplicationId: number;
  copyReason: string;
  removeFromReporting: boolean;
  hasBeenCopied: boolean;
  currentFeeTemplateId: number;
  productPricing: ProductPricing;
  mortgageId: number;
  solarId: number;
  solar: SolarInfoModel;
  homeEquityInvestmentId: number;
  homeEquityInvestment: HomeEquityInvestmentModel;
  languagePreference?: MortgageLanguage;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  mortgageLoan: UrlaMortgage;
}

export enum LosLdeConnectionStatus {
  None = "None",
  Referenced = "Referenced",
  LosToLoda = "LosToLoda",
  FullSync = "FullSync"
}

export enum DataExchangeSyncDir {
  None = "None",
  LosToLoda = "LosToLoda",
  Both = "Both"
}

