<div class="modal-header">
  <h5 class="modal-title"> Create App Credentials </h5>
</div>
<div class="modal-body">
  <form #appPasswordsDialogForm="ngForm" ngbAutofocus class="row">
    <div class="col-md-12 form-group">
      <label for="app-name"> App Name </label>
      <input type="text" id="app-name" name="app-name" class="form-control" [(ngModel)]="appName"
        #appNameInput="ngModel" autocomplete="off" required [readonly]="isGeneratePasswdSuccessfully"
        [ngClass]="{'is-invalid' : appNameInput && appNameInput.touched && appNameInput.invalid}">
      <div class="invalid-feedback">Please enter app name.</div>
    </div>
    <!-- <div class="col-md-12 form-group">
      <label for="serviceType">Service Type</label>
      <select class="form-select"
        [ngClass]="{ 'is-invalid' : serviceTypeInput && serviceTypeInput.touched && serviceTypeInput.invalid }"
        name="serviceType" [(ngModel)]="serviceType" #serviceTypeInput="ngModel" required>
        <option value="">-- Select One --</option>
        <option *ngFor="let type of serviceTypesForGenerateSecret" value="{{type.value}}">{{ type.name }}</option>
      </select>
      <div class="invalid-feedback">Service type is required.</div>
    </div> -->
    <div class="col-md-12 form-group" *ngIf="!isGeneratePasswdSuccessfully">
      <button type="button" class="btn btn-primary" (click)="generateAppPassword()"
        *ngIf="!isClickedGenerateBtn && !isGeneratePasswdSuccessfully"> Generate Client ID and Password
      </button>
      <span *ngIf="isClickedGenerateBtn && !isGeneratePasswdSuccessfully"> <i class="fas fa-sync-alt fa-spin me-1">
        </i> Generating ID and password... </span>
    </div>
    <div class="col-md-12 form-group" *ngIf="isGeneratePasswdSuccessfully">
      <label for="generated-client-id"> Generated Client ID </label>
      <div class="d-flex align-items-center">
        <input type="text" id="generated-client-id" name="generated-client-id" class="form-control"
          [(ngModel)]="generatedClientId" autocomplete="off" readonly>
        <i class="far fa-copy cursor ms-2" (click)="copyToClipboard(generatedClientId, 'id')"></i>
      </div>
    </div>
    <div class="col-md-12 form-group" *ngIf="isGeneratePasswdSuccessfully">
      <label for="generated-client-secret"> Generated Client Secret </label>
      <div class="d-flex align-items-center">
        <input type="text" id="generated-client-secret" name="generated-client-secret" class="form-control"
          [(ngModel)]="generatedClientSecret" autocomplete="off" readonly>
        <i class="far fa-copy cursor ms-2" (click)="copyToClipboard(generatedClientSecret, 'secret')"></i>
      </div>
    </div>
    <div class="col-md-12 form-group" *ngIf="isGeneratePasswdSuccessfully">
      <div class="alert alert-warning border-0" role="alert" style="background-color: #fbf2ec;">
        <i class="fas fa-exclamation-triangle me-1"></i>
        <strong>Be careful!</strong> Your client id and secret has been generated successfully.
        Please, copy the client secret above. You'll not be able to see it again when this dialog closes.
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCloseClicked()"> Close </button>
  <!-- <button class="btn btn-primary float-end" type="button" (click)="onOperationConfirmed()">
    Save
  </button> -->
</div>