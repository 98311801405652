<div class="modal-header">
  <h5 class="modal-title">Reassign Lead(s) User</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <!-- <div class="text-center">
    Are you sure you want to reassign these lead(s)?
  </div> -->

  <div class="input-group align-items-center mt-4">
    <label for="addTo" class="form-label align-self-center me-2">Reassign To:</label>

    <select class="form-select ms-2" [(ngModel)]="selectedReassignUserGUID">
      <option [ngValue]="null">-- Reassign User --</option>
      <option *ngFor="let user of users" [ngValue]="user.userCompanyGuid">
        {{ user.lastName + ', ' + user.firstName }}
      </option>
    </select>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i>Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary "
    [disabled]="isSaving || !selectedReassignUserGUID"
    (click)="confirmReassign()"
  >
    <span *ngIf="!isSaving">Reassign</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
