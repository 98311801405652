<div *ngIf="applicationContext.userPermissions.onlineApplicationEnabled && applicationContext.userPermissions.onlineAppV3Enabled; else elseBlock">
  <div class="fieldset-title pt-3">
    Dynamic Online Applications {{scope === 'Company' ? '(unassigned)' : ''}}
    <span class="badge badge-soft-primary" style="font-size: 14px !important;">New!</span>
  </div>
  <div *ngFor="let onlineApp of wizardUrls; let index = index">
    <div class="pt-3 pb-3" *ngIf="onlineApp.isCustom">
      <div class="row">
        <div class="col-sm-12">
          <app-url [label]="onlineApp.name" [model]="onlineApp.url" [disableCopy]="!onlineApp.isActive"></app-url>
        </div>
      </div>
      <div class="mt-2 d-flex justify-content-center" *ngIf="scope === 'Company'">
        <div class="mx-4">
          <a class="cursor" href="{{onlineApp.url}}&editToken={{token}}" target="_new">
            <i class="fas fa-pencil-alt"></i> Edit
          </a>
        </div>
        <div class="mx-4">
          <a class="cursor" href="javascript:void(0)" (click)="showConfirmationDialog(onlineApp)">
            <i class="fa fa-trash-alt text-danger"></i> Delete
          </a>
        </div>
        <div class="mx-4">
          <div class="form-check form-switch form-switch-success cursor">
            <input id="activate-{{index}}" class="form-check-input cursor" type="checkbox" [(ngModel)]="onlineApp.isActive" (change)="toggleActivateOnlineAppFlow(onlineApp)" [disabled]="onlineApp.isActive && onlineApp.isDefault"/>
            <label for="activate-{{index}}" class="form-check-label cursor">{{onlineApp.isActive ? 'Deactivate' : 'Activate'}}</label>
          </div>
        </div>
        <div class="mx-4">
          <div class="form-check form-switch form-switch-success cursor">
            <input id="default-{{index}}" class="form-check-input cursor" type="checkbox" [(ngModel)]="onlineApp.isDefault" (change)="toggleDefaultOnlineAppFlow(onlineApp)" [disabled]="!onlineApp.isActive"/>
            <label for="default-{{index}}" class="form-check-label cursor">Default</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center pt-3 pb-3" *ngIf="company.onlineAppV3Enabled && scope === 'Company'">
    <a class="btn btn-link"
       href="https://{{company.onlineAppV3Url}}/register?companyGuid={{company.companyGUID}}&userGuid=unassigned&editToken={{token}}"
       target="_new">Create a New Flow</a>
  </div>
</div>
<ng-template #elseBlock>
  <h4 class="fw-light">Call to enable this feature!</h4>
</ng-template>
