<div class="modal-header">
  <h5 class="modal-title">Credential</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #credentialForm="ngForm">
    <div class="row pt-3" *ngIf="channels.length > 0">
      <div class="col-md-2 text-end">
        <label class="custom-control-label" for="channels-dropdown">Channel</label>
      </div>
      <div class="col-md-4">
        <ng-select width="100%" name="channels-dropdown" bindLabel="name" bindValue="value"
          placeholder="Select Channels" [closeOnSelect]="false" [multiple]="true" (change)="setCredentialChannels()"
          [(ngModel)]="selectedChannels" [items]="channels" appendTo="body">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:2">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 2">
              <span class="ng-value-label">{{items.length - 2}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="row pt-3">

      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaUserName">Auth User ID</label>
      </div>
      <div class="col-md-4 pt-3">
        <input type="text" class="form-control" name="lpaUserName" [(ngModel)]="lpaCredential.userName"
          [ngClass]="{'is-invalid' : lpaUserNameField && lpaUserNameField.touched && lpaUserNameField.invalid}"
          #lpaUserNameField="ngModel" required />
      </div>

      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaPassword">Auth Password</label>
      </div>
      <div class="col-md-4 pt-3">
        <password-input [name]="'lpaPassword'" [(ngModel)]="lpaCredential.password" required="true"></password-input>
      </div>

      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaSellerServicerNumber">Seller Servicer Number</label>
      </div>
      <div class="col-md-4 pt-3">
        <input type="text" class="form-control" name="lpaSellerServicerNumber"
          [(ngModel)]="lpaKeyValuePairs.sellerServicerNumber.value" [disabled]="
            !(!!lpaKeyValuePairs.thirdPartyOriginatorId.value && lpaKeyValuePairs.thirdPartyOriginatorIsCorrespondentLender.value === '1') &&
            (!!lpaKeyValuePairs.thirdPartyOriginatorId.value || !!lpaKeyValuePairs.nonOriginatingThirdPartyId.value)
          "
          [ngClass]="{'is-invalid' : sellerServicerNumberField && sellerServicerNumberField.touched && sellerServicerNumberField.invalid}"
          #sellerServicerNumberField="ngModel"
          [required]="!lpaKeyValuePairs.thirdPartyOriginatorId?.value && !lpaKeyValuePairs.nonOriginatingThirdPartyId?.value" />
      </div>

      <div class="col-md-6 text-end pt-3">
      </div>

      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaFhaLenderId">FHA Lender Id</label>
      </div>
      <div class="col-md-4 pt-3">
        <input type="text" class="form-control" name="lpaFhaLenderId" [(ngModel)]="lpaKeyValuePairs.fhaLenderId.value">
      </div>

      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaFhaSponsorId">FHA Sponsor Id</label>
      </div>
      <div class="col-md-4 pt-3">
        <input type="text" class="form-control" name="lpaFhaSponsorId"
          [(ngModel)]="lpaKeyValuePairs.fhaSponsorId.value">
      </div>

      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaTpoNo">TPO Number (only used if not a seller)</label>
      </div>
      <div class="col-md-4 pt-3">
        <input type="text" class="form-control" name="lpaTpoNo"
          [(ngModel)]="lpaKeyValuePairs.thirdPartyOriginatorId.value" [disabled]="
            (!!lpaKeyValuePairs.sellerServicerNumber.value && lpaKeyValuePairs.thirdPartyOriginatorIsCorrespondentLender.value !== '1') ||
            !!lpaKeyValuePairs.nonOriginatingThirdPartyId.value
          "
          [ngClass]="{'is-invalid' : thirdPartyOriginatorIdField && thirdPartyOriginatorIdField.touched && thirdPartyOriginatorIdField.invalid}"
          #thirdPartyOriginatorIdField="ngModel"
          [required]="!lpaKeyValuePairs.sellerServicerNumber.value && !lpaKeyValuePairs.nonOriginatingThirdPartyId.value" />

        <div class="form-check form-check-inline mt-1">
          <input id="thirdPartyOriginatorIsCorrespondentLender" name="thirdPartyOriginatorIsCorrespondentLender"
            class="form-check-input" type="checkbox"
            [(ngModel)]="lpaKeyValuePairs.thirdPartyOriginatorIsCorrespondentLender.value" ToggleValue [true]="'1'"
            [false]="'0'">
          <label class="form-check-label" for="thirdPartyOriginatorIsCorrespondentLender">Is Correspondent
            Lender
          </label>
        </div>
      </div>
      <div class="col-md-2 text-end pt-3">
        <label class="custom-control-label" for="lpaNonOriginatingThirdPartyId">
          Non-Originating Third Party Id (contract processors/underwriters)
        </label>
      </div>
      <div class="col-md-4 pt-3">
        <input type="text" class="form-control" name="lpaNonOriginatingThirdPartyId"
          [(ngModel)]="lpaKeyValuePairs.nonOriginatingThirdPartyId.value"
          [disabled]="!!lpaKeyValuePairs.sellerServicerNumber.value || !!lpaKeyValuePairs.thirdPartyOriginatorId.value"
          [ngClass]="{'is-invalid' : nonOriginatingThirdPartyIdField && nonOriginatingThirdPartyIdField.touched && nonOriginatingThirdPartyIdField.invalid}"
          #nonOriginatingThirdPartyIdField="ngModel"
          [required]="!lpaKeyValuePairs.sellerServicerNumber.value && !lpaKeyValuePairs.thirdPartyOriginatorId.value" />
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save</span>
  </button>
</div>