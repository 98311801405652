import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, forkJoin } from 'rxjs';
import { LoanDoc, Mortgage } from 'src/app/models';
import { AppraisalCondition } from 'src/app/models/appraisal/appraisal-condition.model';
import { AppraisalDocument } from 'src/app/models/appraisal/appraisal-document.model';
import { AppraisalFormType } from 'src/app/models/appraisal/appraisal-form-type.model';
import { AppraisalNote } from 'src/app/models/appraisal/appraisal-note.model';
import { AppraisalOrderView } from 'src/app/models/appraisal/appraisal-order-view.model';
import { AppraisalThirdPartyContact } from 'src/app/models/appraisal/appraisal-third-party-contact.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { AppraisalService } from 'src/app/services/appraisal.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { AddDocumentsDialogComponent } from './add-documents-dialog/add-documents-dialog.component';
import { AddNotesDialogComponent } from './add-notes-dialog/add-notes-dialog.component';
import { DatePipe } from '@angular/common';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'appraisal-task-order',
  templateUrl: './appraisal-task-order.component.html',
  styleUrls: ['./appraisal-task-order.component.scss']
})
export class AppraisalTaskOrderComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  task: LoanDocDashboardTask;

  @Input()
  mortgage: Mortgage;

  tab: string;

  appraisals: AppraisalOrderView[] = [];

  appraisalFormTypes: AppraisalFormType[] = [];

  loanDocs: LoanDoc[];

  activeAppraisal: AppraisalOrderView;

  newDocs: AppraisalDocument[] = [];

  newNotes: AppraisalNote[] = [];

  thirdPartyContact: AppraisalThirdPartyContact = new AppraisalThirdPartyContact();

  appraisalErrors: string[];

  isRushOrder: boolean = false;

  targetDueDate: Date;

  deliveryGroups: string = "";

  newNote: string = "";

  newLoanDocId: string = "";

  newAppraisalDocType: string = "";

  newCondition: AppraisalCondition = {
    thirdPartyConditionId: Utils.getUniqueId(),
    conditionText: '',
    conditionCategory: ''
  };

  conditions: Array<AppraisalCondition> = [];

  conditionsLoading: boolean;

  selectedAppraisalFormTypes: string[] = [];

  deliveryGroupsEnum = [];

  appraisalCategoriesEnum = [];

  appraisalDocTypesEnum = [];

  savingAppraisalCondition: boolean;

  actionInProgressForId: number | null;

  constructor(private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _appraisalService: AppraisalService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _taskService: TaskService,
    private readonly _modalService: NgbModal,
    private readonly _enumerationService: EnumerationService,
    public datePipe: DatePipe) { }

  ngOnInit(): void {

    this.tab = 'appraisalStatus';

    const promises = [this._appraisalService.getAppraisalOrders(this.task.loanDocTaskId, true),
    this._appraisalService.getAppraisalFormTypes(),
    this._loanDocsService.getLoanDocs(this.appId, false),
    this._enumerationService.getAppraisalEnumerations()];

    forkJoin(promises).subscribe(([order, formTypes, loanDocs, appraisalEnums]) => {
      this.appraisals = order;
      this.appraisalFormTypes = formTypes;
      this.loanDocs = loanDocs;
      this.deliveryGroupsEnum = appraisalEnums['NoteDeliveryGroup'];
      this.appraisalCategoriesEnum = appraisalEnums['AppraisalConditionCategory'];
      this.appraisalDocTypesEnum = appraisalEnums['AppraisalDocumentType'];
      this.findActiveAppraisal();
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "Error", "Error");
    });
  }

  findActiveAppraisal() {
    this.activeAppraisal = this.appraisals.find(appraisal => appraisal.orderStatus !== 'Cancelled') || new AppraisalOrderView();
    if (this.activeAppraisal.notes) {
      this.activeAppraisal.notes.forEach(note => {
        note.thirdPartyNoteId = `id-${Math.random().toString(26).slice(2)}`
      })
    }
    if (this.activeAppraisal.documents) {
      this.activeAppraisal.documents.forEach(document => {
        document.thirdPartyDocumentId = `id-${Math.random().toString(26).slice(2)}`
      })
    }
    if (this.activeAppraisal.appraisalOrderId) {
      this.getAppraisalConditions();
    }
  }

  handleAppraisalViewChange = (value: string) => {
    this.tab = value;
    if (value === 'appraisalStatus') {
    } else if (value === 'appraisalConditions') {
      this.findActiveAppraisal()
    } else if (value === 'appraisalNotes') {
      if (this.activeAppraisal.notes) {
        this.activeAppraisal.notes.forEach(note => {
          note.thirdPartyNoteId = `id-${Math.random().toString(26).slice(2)}`
        });
      } else {
        this.activeAppraisal.notes = [];
      }

    } else if (value === 'appraisalDocuments') {
      if (this.activeAppraisal.documents) {
        this.activeAppraisal.documents.forEach(document => {
          document.thirdPartyDocumentId = `id-${Math.random().toString(26).slice(2)}`
        });
      } else {
        this.activeAppraisal.documents = [];
      }
    }
  }

  submitAppraisalOrder = () => {
    this._spinner.show();
    const data = {
      loanDocTaskId: this.task.loanDocTaskId,
      applicationId: this.appId,
      appraisalForms: this.selectedAppraisalFormTypes,
      documents: this.newDocs,
      isRushOrder: this.isRushOrder,
      targetDueDate: this.targetDueDate,
      notes: this.newNotes.map(n => ({ note: n.note, deliveryGroups: n.deliveryGroups })),
      thirdPartyContact: this.thirdPartyContact,
    }

    this._appraisalService.postAppraisalOrders(data).subscribe((response) => {
      this.updateAppraisal(response);
      this.reloadSelectedTask();
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
      this.appraisalErrors = this.formatErrorsToObject(err.message);
    });
  }

  updateAppraisal = (appraisal) => {
    const index = this.appraisals.findIndex(item => item.appraisalOrderId === appraisal.appraisalOrderId);
    if (index === -1) {
      this.appraisals.push(appraisal)
    } else {
      this.appraisals[index] = appraisal;
    }
    this.findActiveAppraisal();
  }

  reloadSelectedTask = () => {
    this._spinner.show();
    this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId).subscribe((response) => {
      this.task = Object.assign(this.task, response);
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err ? err.message || err : "Load task fail", "Error")
    });
  }

  formatErrorsToObject = (errors) => {
    if (errors && errors.includes('\r\n')) {
      errors = errors.split('\r\n');
    } else {
      this._notifyService.showError(errors, 'Task');
      return;
    }

    let error = null;
    return errors.map(err => {
      error = err.split(':');
      if (error.length === 2) {
        return {
          field: error[0].trim().replace('_', ' '),
          message: error[1].trim()
        };
      } else {
        this._notifyService.showError(err, 'Task');
      }
    });
  }

  openNotesPopup = () => {
    const modalRef = this._modalService.open(AddNotesDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.newNotes = this.newNotes;
    modalRef.componentInstance.deliveryGroupsEnum = this.deliveryGroupsEnum;

  }

  openDocumentsPopup = () => {
    const modalRef = this._modalService.open(AddDocumentsDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.newDocs = this.newDocs;
    modalRef.componentInstance.loanDocs = this.loanDocs;
    modalRef.componentInstance.appraisalDocTypesEnum = this.appraisalDocTypesEnum;
  }

  cancelAppraisalOrder = (appraisalOrderId) => {
    this._spinner.show();
    this._appraisalService.cancelAppraisalOrder(appraisalOrderId).subscribe((response) => {
      this.updateAppraisal(response);
      this._spinner.hide();
    }, (err) => {
      this.appraisalErrors = this.formatErrorsToObject(err.message);
      this._spinner.hide();
    });
  }

  placeAppraisalOrderOnHold = (appraisalOrderId) => {
    this._spinner.show();
    this._appraisalService.placeAppraisalOrderOnHold(appraisalOrderId).subscribe((response) => {
      this.updateAppraisal(response);
      this._spinner.hide();
    }, (err) => {
      this.appraisalErrors = this.formatErrorsToObject(err.message);
      this._spinner.hide();
    });
  }

  refreshAppraisalOrder = (appraisalOrderId) => {
    this._spinner.show();
    this._appraisalService.refreshAppraisalOrder(appraisalOrderId).subscribe((response) => {
      this.updateAppraisal(response);
      this._spinner.hide();
    }, (err) => {
      this.appraisalErrors = this.formatErrorsToObject(err.message);
      this._spinner.hide();
    });
  }

  resumeAppraisalOrderFromHold = (appraisalOrderId) => {
    this._spinner.show();
    this._appraisalService.resumeAppraisalOrderFromHold(appraisalOrderId).subscribe((response) => {
      this.updateAppraisal(response);
      this._spinner.hide();
    }, (err) => {
      this.appraisalErrors = this.formatErrorsToObject(err.message);
      this._spinner.hide();
    });
  }

  removeAppraisalNote = (id) => {
    const index = this.activeAppraisal.notes.findIndex(note => note.thirdPartyNoteId === id);
    if (index === -1) {
      return;
    }
    this.activeAppraisal.notes.splice(index, 1);
  }

  updateOrder = () => {
    const data = _.cloneDeep(this.activeAppraisal);
    if (this.activeAppraisal.notes) {
      data.notes = this.activeAppraisal.notes.map(n => ({ note: n.note, deliveryGroups: n.deliveryGroups }))
    }
    if (this.activeAppraisal.documents) {
      data.documents = this.activeAppraisal.documents.map(d => ({
        docFileGuid: d.docFileGuid,
        appraisalDocumentType: d.appraisalDocumentType
      }));
    }
    this._spinner.show();
    this._appraisalService.createAppraisalOrder(data).subscribe((response) => {
      this.updateAppraisal(response);
      this.reloadSelectedTask();
    }, (err) => {
      this.appraisalErrors = this.formatErrorsToObject(err.message);
      this._spinner.hide();
    })
  }

  removeAppraisalDoc = (id) => {
    const index = this.activeAppraisal.documents.findIndex(d => d.thirdPartyDocumentId === id);
    if (index === -1) {
      return;
    }
    this.activeAppraisal.documents.splice(index, 1);
  }

  addAppraisalCondition = () => {
    this.conditions.push({
      thirdPartyConditionId: this.newCondition.thirdPartyConditionId,
      conditionCategory: this.newCondition.conditionCategory,
      conditionText: this.newCondition.conditionText,
      conditionCategoryName: this.getConditionDisplayName(this.newCondition.conditionCategory),
      status: 'New',
      requestedOn: '',
      approvedOn: ''
    });

    this.newCondition = {
      thirdPartyConditionId: Utils.getUniqueId(),
      conditionText: '',
      conditionCategory: ''
    }
  }

  saveAppraisalCondition = () => {
    const request: Partial<AppraisalCondition>[] = this.conditions.filter(condition => condition.thirdPartyConditionId < 0)
      .map(condition => ({
        conditionText: condition.conditionText,
        conditionCategory: condition.conditionCategory,
      }));
    if (request.length === 0) {
      this._notifyService.showWarning('conditions are already saved...', 'Warning!');
      return;
    }
    this.savingAppraisalCondition = true;
    this._appraisalService.saveAppraisalConditions(this.activeAppraisal.appraisalOrderId, request)
      .pipe(finalize(() => this.savingAppraisalCondition = false))
      .subscribe(res => {
        this._notifyService.showSuccess('Condition saved successfully.', 'Success');
        this.getAppraisalConditions();
      }, err => {
        this._notifyService.showError(err ? err.error.message : 'Unable to save appraisal condition due to unknown reason.', 'Error!');
      });
  }

  denyCondition = (id: number) => {
    const self = this;
    const msg = 'Are you sure you want to deny this condition?';
    this.showConfirmationDialog('Confirm Deny', msg, true, false)
      .then(function (response) {
        if (!response.isConfirmed) {
          return;
        }
        self.actionInProgressForId = id;
        self._appraisalService.denyAppraisalCondition(self.activeAppraisal.appraisalOrderId, [{
          thirdPartyConditionId: id,
          note: response.value ? response.value : null
        }])
          .pipe(finalize(() => self.actionInProgressForId = null))
          .subscribe(res => {
            self._notifyService.showSuccess('Condition denied successfully.', 'Success');
            self.getAppraisalConditions();
          }, err => {
            self._notifyService.showError(err ? err.message : 'Unable to deny appraisal condition due to unknown reason.', 'Error!');
          })
      }, function () {
      })
  };

  cancelCondition = (id: number) => {
    const self = this;
    const msg = 'Are you sure you want to cancel this condition?';
    this.showConfirmationDialog('Confirm Cancel', msg, true, true)
      .then(function (response) {
        if (!response.isConfirmed) {
          return;
        }
        self.actionInProgressForId = id;
        self._appraisalService.cancelAppraisalCondition(self.activeAppraisal.appraisalOrderId, [{
          thirdPartyConditionId: id,
          note: response.value
        }])
          .pipe(finalize(() => self.actionInProgressForId = null))
          .subscribe(res => {
            self._notifyService.showSuccess('Condition cancelled successfully.', 'Success');
            self.getAppraisalConditions();
          }, err => {
            self._notifyService.showError(err ? err.message : 'Unable to cancel appraisal condition due to unknown reason.', 'Error!');
          })
      }, function () {
      })
  };

  removeCondition = (id: number) => {
    this.removeConditionRow(id);
  }

  approveCondition = (id: number) => {
    const self = this;
    const msg = 'Are you sure you want to approve this condition?';
    this.showConfirmationDialog('Confirm Approve', msg, false)
      .then(function (response) {
        if (!response.isConfirmed) {
          return;
        }
        self.actionInProgressForId = id;
        self._appraisalService.approveAppraisalCondition(self.activeAppraisal.appraisalOrderId, [{
          thirdPartyConditionId: id
        }])
          .pipe(finalize(() => self.actionInProgressForId = null))
          .subscribe(res => {
            self._notifyService.showSuccess('Condition approved successfully.', 'Success');
            self.getAppraisalConditions();
          }, err => {
            self._notifyService.showError(err ? err.message : 'Unable to approve appraisal condition due to unknown reason.', 'Error!');
          })
      }, function () {
      })
  }

  removeConditionRow = (id: number) => {
    const index = this.conditions.findIndex(condition => condition.thirdPartyConditionId === id);
    this.conditions.splice(index, 1);
  }



  getAppraisalConditions = () => {
    this.conditionsLoading = true;
    this._appraisalService.getAppraisalConditions(this.activeAppraisal.appraisalOrderId)
      .pipe(finalize(() => this.conditionsLoading = false))
      .subscribe(res => {
        this.conditions = res.map((condition: AppraisalCondition) => ({
          thirdPartyConditionId: Number(condition.thirdPartyConditionId),
          conditionText: condition.conditionText,
          conditionCategoryName: this.getConditionDisplayName(condition.conditionCategory),
          conditionCategory: condition.conditionCategory,
          status: condition.status,
          requestedOn: condition.requestedOn ? this.datePipe.transform(condition.requestedOn, 'short') : '',
          approvedOn: condition.approvedOn ? this.datePipe.transform(condition.approvedOn, 'short') : ''
        }))
      }, err => {
        this._notifyService.showError(err ? err.message || err : "Error", "Unable to load appraisal condition due to unknown reason.");
      });
  }

  getConditionDisplayName = (conditionCategory: string) => {
    const matchingCategory = this.appraisalCategoriesEnum.find(cat => cat.value === conditionCategory);
    return matchingCategory ? matchingCategory.name : '';
  }

  addAppraisalNote = () => {
    const id = `id-${Math.random().toString(26).slice(2)}`;
    this.activeAppraisal.notes.push({
      note: this.newNote,
      deliveryGroups: this.deliveryGroups,
      thirdPartyNoteId: id
    });

    this.newNote = '';
    this.deliveryGroups = '';
  }

  addAppraisalDoc = () => {
    this.activeAppraisal.documents.push({
      docFileGuid: this.newLoanDocId,
      appraisalDocumentType: this.newAppraisalDocType,
      fileName: this.loanDocs.find(doc => doc.loanDocId.toString() == this.newLoanDocId).description
    });

    this.newLoanDocId = "";
    this.newAppraisalDocType = "";
  }

  allOrdersCancelled = () => {
    return this.appraisals.every(appraisal => appraisal.orderStatus === 'Cancelled');
  }

  get hasNewConditions() {
    return this.conditions.some(condition => condition.thirdPartyConditionId < 0);
  }

  private showConfirmationDialog = (heading: string, confirmationMessage: string, showComment: boolean, commentRequired: boolean = false) => {
    const alertOptions: SweetAlertOptions = {
      title: heading,
      text: confirmationMessage,
      icon: 'question',
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      showCancelButton: true
    };
    if (showComment) {
      _.assignIn(alertOptions, {
        input: 'textarea',
        inputLabel: `Comment(${commentRequired ? 'Required' : 'Optional'})`,
        inputPlaceholder: 'Type your comment here...',
        inputAttributes: {
          'aria-label': 'Type your comment here'
        },
        inputValidator: (value: string) => {
          if (!value && commentRequired) {
            return 'Comment is required.'
          }
        }
      });
    }
    return Swal.fire(alertOptions)
  }

}
