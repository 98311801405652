import { Component, EventEmitter, Input, OnChanges, OnInit, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Borrower, LoanApplication } from 'src/app/models';
import { TaskCategory } from 'src/app/models/config/global-config.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { CharacteristicsQuantitiesDialogComponent } from '../characteristics-quantities-dialog/characteristics-quantities-dialog.component';
import { Constants } from 'src/app/services/constants';
import { BorrowerCharacteristicFactory } from '../characteristics-quantities-dialog/borrower-characteristic.factory';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { CharacteristicsPreviewDialogComponent } from '../characteristics-preview-dialog/characteristics-preview.dialog.component';
import { BorrowerCharacteristicsHistoryDialogComponent } from '../borrower-characteristics-history-dialog/borrower-characteristics-history-dialog.component';
import { BorrowerCharacteristic, CharacteristicMergeValue } from 'src/app/models/characteristics';
import { Subscription } from 'rxjs';

import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'borrower-characteristics-card',
  templateUrl: 'borrower-characteristics-card.component.html',
  styleUrls: ['./borrower-characteristics-card.component.scss']
})
export class BorrowerCharacteristicsCardComponent implements OnInit, OnChanges, OnDestroy {

  @Output() taskCategoriesChanged = new EventEmitter<{ borrower: Borrower, categories: TaskCategory[] }[]>();

  @Input()
  application: LoanApplication;

  @Input()
  borrowerCharacteristics: BorrowerCharacteristic[] = [];

  @Output()
  borrowerCharacteristicsSaved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();
  borrowers: Borrower[] = [];

  taskCategories: TaskCategory[] = [];

  thereAreChanges: boolean = false;

  isSavingCharacteristics: boolean = false;

  id: string;

  private _initialCharacteristics: BorrowerCharacteristic[] = [];

  private _nextCharacteristicId: number = -1;

  private _hasBeenInitializedOnce: boolean = false;

  private _loanInfoChangesSubscription: Subscription;
  private _contextSubscription: Subscription;

  constructor(private readonly _applicationContextService: ApplicationContextService,
    private readonly _loanService: LoanService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal) {
    this.id = uuidv4();
    this._loanInfoChangesSubscription = this._applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.borrowers = context.borrowers;
      }
    });
  }

  ngOnInit() {
    this._hasBeenInitializedOnce = true;
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this.borrowers = context.borrowers;
      this.taskCategories = context.globalConfig.taskCategory.filter(t => t.characteristicType === 'BorrowerCharacteristic');
      this.taskCategories.sort(function (a, b) {
        return a.order - b.order;
      });
      this.initialize();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._hasBeenInitializedOnce) {
      this.initialize();
    }
  }

  ngOnDestroy(): void {
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
  }

  onViewHistoryClicked = () => {
    this._spinner.show();
    this._loanService.getBorrowerCharacteristicsHistory(this.application.applicationId).subscribe(history => {
      this._spinner.hide();
      const modalRef = this._modalService.open(BorrowerCharacteristicsHistoryDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.borrowers = this.borrowers;
      modalRef.componentInstance.history = history;
    }, error => {
      this._spinner.hide();
      this._notificationService.showError(
        error ? error.message : 'Unable to get borrower characteristics history.',
        'Error - Borrower Characteristics!'
      );
    })
  }

  publishSavedTaskCategories = () => {
    let borrowerTaskCategories = this.borrowers.map(b => {
      let selectedCategories = this.taskCategories.filter(c => this.isCharacteristicCheckedForBorrower(b.borrowerId, c.taskCategoryId));
      return {
        borrower: b,
        categories: selectedCategories
      }
    });

    this.taskCategoriesChanged.emit(borrowerTaskCategories);
  }

  onSaveClicked = () => {
    this.isSavingCharacteristics = true;
    const onesToSave = this.borrowerCharacteristics.filter(c => !c.pendingDeletion);
    this._loanService.saveBorrowerCharacteristicsPreview(this.application.applicationId, onesToSave).subscribe((loanDocTasks: LoanDocTask[]) => {
      this.publishSavedTaskCategories();
      if (loanDocTasks && loanDocTasks.length > 0) {
        this.showCharacteristicsPreviewDialog(onesToSave, loanDocTasks);
      } else {
        this.saveBorrowerCharacteristics(this.application.applicationId, onesToSave, []);
      }
    }, error => {
      this.isSavingCharacteristics = false;
      this._notificationService.showError(
        error ? error.message : 'Unable to save borrower characteristics preview.',
        'Error - Borrower Characteristics!'
      );
    });
  }

  onCancel() {
    if (this.thereAreChanges) {
      this.showUnsavedChangesDialog();
      return;
    }
    this.onClose.emit();
  }

  private showUnsavedChangesDialog() {
    Swal.fire({
      title: 'Unsaved Changes?',
      text: 'Do you want to discard unsaved changes?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this.onClose.emit();
    });
  }

  isCharacteristicCheckedForAnyBorrower = (characteristicId: number): boolean => {
    return this.borrowerCharacteristics.filter(c => c.characteristicId == characteristicId && !c.pendingDeletion).length > 0;
  }

  isCharacteristicCheckedForBorrower = (borrowerId: number, characteristicId: number): boolean => {
    return this.borrowerCharacteristics.filter(c => c.borrowerId == borrowerId && c.characteristicId == characteristicId && !c.pendingDeletion).length > 0;
  }

  getCharacteristicQuantity = (borrowerId: number, characteristicId: number): number => {
    var bcs = this.borrowerCharacteristics.filter(c => c.characteristicId == characteristicId && c.borrowerId == borrowerId && !c.pendingDeletion);
    if (bcs && bcs.length > 0) {
      return bcs.length;
    }
    return 0;
  }

  openEditCharacteristicDialog = (category: TaskCategory, borrowerId: number, addingForTheFirstTime: boolean) => {
    const characteristicId = category.taskCategoryId;
    let existingCharacteristics = this.borrowerCharacteristics.filter(c => c.characteristicId == characteristicId && c.borrowerId == borrowerId);
    if (existingCharacteristics.length === 0) {
      let newOne = new BorrowerCharacteristic(this._nextCharacteristicId--, this.application.applicationId, characteristicId, borrowerId);
      this.borrowerCharacteristics.push(newOne);
      existingCharacteristics = this.borrowerCharacteristics.filter(c => c.characteristicId == characteristicId && c.borrowerId == borrowerId);
      addingForTheFirstTime = true;
    }
    existingCharacteristics.forEach(c => {
      if (!c.characteristicMergeValues || c.characteristicMergeValues.length === 0) {
        c.characteristicMergeValues = [];
        category.fields.forEach(f => {
          c.characteristicMergeValues.push(new CharacteristicMergeValue(f.characteristicFieldId));
        });
      }
    });

    const modalRef = this._modalService.open(CharacteristicsQuantitiesDialogComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.taskCategory = this.taskCategories.find(c => c.taskCategoryId === characteristicId);
    modalRef.componentInstance.nextCharacteristicId = this._nextCharacteristicId;
    modalRef.componentInstance.characteristics = existingCharacteristics;
    modalRef.componentInstance.characteristicFactory = new BorrowerCharacteristicFactory();
    modalRef.componentInstance.applicationId = this.application.applicationId;
    modalRef.componentInstance.ownerId = borrowerId;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        let remaining = this.borrowerCharacteristics.filter(c => !(c.characteristicId == characteristicId && c.borrowerId == borrowerId));
        if (result != undefined) {
          result.forEach(characteristic => {
            remaining.push(characteristic);
          });
        }
        this.borrowerCharacteristics = remaining;
        this.thereAreChanges = !_.isEqual(this.borrowerCharacteristics, this._initialCharacteristics);
      } else {
        if (addingForTheFirstTime) {
          this.borrowerCharacteristics = this.borrowerCharacteristics.filter(c => !(c.characteristicId == characteristicId && c.borrowerId == borrowerId));
        }
      }
    }, (err) => {
      if (addingForTheFirstTime) {
        this.borrowerCharacteristics = this.borrowerCharacteristics.filter(c => !(c.characteristicId == characteristicId && c.borrowerId == borrowerId));
      }
    });
  }

  onCharacteristicCheckChanged = (characteristicId: number, borrowerId: number) => {
    let index = [];
    for (let i = 0; i < this.borrowerCharacteristics.length; i++) {
      if (this.borrowerCharacteristics[i].characteristicId === characteristicId && this.borrowerCharacteristics[i].borrowerId === borrowerId) {
        index.push(i);
      }
    }
    if (index.length === 0) {
      const newOne = new BorrowerCharacteristic(this._nextCharacteristicId--, this.application.applicationId, characteristicId, borrowerId);
      this.borrowerCharacteristics.push(newOne);

      if (this.taskCategories.filter(x => x.taskCategoryId == characteristicId)[0].taskOption === 'RequestQuantity') {
        const category = this.taskCategories.find(c => c.taskCategoryId === characteristicId);
        this.openEditCharacteristicDialog(category, borrowerId, true);
      }
    }
    else {
      index.forEach(i => {
        this.borrowerCharacteristics[i].pendingDeletion = this.borrowerCharacteristics[i].pendingDeletion === true ? false : true;
      });
    }
    this.thereAreChanges = !_.isEqual(this.borrowerCharacteristics, this._initialCharacteristics);
  }

  private initialize = () => {
    this._initialCharacteristics = _.cloneDeep(this.borrowerCharacteristics);
    this.publishSavedTaskCategories();
  }

  private showCharacteristicsPreviewDialog = (characteristicsToSave: BorrowerCharacteristic[], tasks: LoanDocTask[]) => {
    this.isSavingCharacteristics = false;
    const modalRef = this._modalService.open(CharacteristicsPreviewDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.borrowers = this.borrowers;
    modalRef.componentInstance.characteristicType = 2;
    modalRef.componentInstance.tasksPreview = tasks;
    modalRef.componentInstance.taskCategories = this.taskCategories;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.saveBorrowerCharacteristics(this.application.applicationId, characteristicsToSave, result);
      } else {
        this._notificationService.showWarning(
          'Borrower characteristics save operation has been cancelled.',
          'Warning!'
        );
      }
    }, (err) => {
      this._notificationService.showWarning(
        'Borrower characteristics save operation has been cancelled.',
        'Warning!'
      );
    });
  }

  private saveBorrowerCharacteristics = (applicationId: number, characteristics: BorrowerCharacteristic[], loanDocTasks: LoanDocTask[]) => {
    this._loanService.saveBorrowerCharacteristics(applicationId, characteristics, loanDocTasks).subscribe(result => {
      this.isSavingCharacteristics = false;
      this.thereAreChanges = false;
      this.borrowerCharacteristics = result;
      this._initialCharacteristics = _.cloneDeep(this.borrowerCharacteristics);
      this._notificationService.showSuccess(
        'Successfully saved borrower characteristics!',
        'Success!'
      );
      this.borrowerCharacteristicsSaved.emit();
    }, error => {
      this.isSavingCharacteristics = false;
      this._notificationService.showError(
        error ? error.message : 'Unable to save borrower characteristics.',
        'Error - Borrower Characteristics!'
      );
    });
  }
}
