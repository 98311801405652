<div class="modal-header">
  <h5 class="modal-title">Allowed Roles To Finalize DO Findings</h5>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12" *ngFor="let role of roles">
      <div class="form-check form-check-inline">
        <input id="{{role.roleId}}" name="{{role.roleId}}" class="form-check-input" type="checkbox"
               [(ngModel)]="role.isChecked">
        <label class="form-check-label" for="{{role.roleId}}">{{role.roleName}}</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary right"
    (click)="save()"
    [disabled]="isSaving"
  >
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
    <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
  </button>
</div>
