<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row">
    <div class="col-lg-2">
      <div class="card height-setter position-sticky">
        <div class="card-body">
          <div class="nav flex-column nav-tabs text-center" aria-orientation="vertical">
            <a
              class="nav-link waves-effect waves-light active"
              [ngClass]="{ 'active': currentTab === 'email-configuration' }"
              [routerLink]="['/admin/system-level/email-configuration']"
              (click)="currentTab = 'email-configuration'">
              Email Configuration</a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'sms-configuration' }"
              [routerLink]="['/admin/system-level/sms-configuration']"
              (click)="currentTab = 'sms-configuration'">
              SMS Configuration
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'application-settings' }"
              [routerLink]="['/admin/system-level/application-settings']"
              (click)="currentTab = 'application-settings'">
              Application Settings
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'business-calendar' }"
              [routerLink]="['/admin/system-level/business-days-calendar']"
              (click)="currentTab = 'business-calendar'">
              Business Calendar
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'borrower-portal-settings' }"
              [routerLink]="['/admin/system-level/borrower-portal-settings']"
              (click)="currentTab = 'borrower-portal-settings'">
              Borrower Portal Settings
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'agent-portal-settings' }"
              [routerLink]="['/admin/system-level/agent-portal-settings']"
              (click)="currentTab = 'agent-portal-settings'">
              Agent Portal Settings
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'prequal-letter-generation-settings' }"
              [routerLink]="['/admin/system-level/prequal-letter-generation-settings']"
              (click)="currentTab = 'prequal-letter-generation-settings'">
              Prequal Letter Generation Settings
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'online-application-settings' }"
              [routerLink]="['/admin/system-level/online-application-settings']"
              (click)="currentTab = 'online-application-settings'">
              Online Application Settings
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'document-export-format' }"
              [routerLink]="['/admin/system-level/document-export-format']"
              (click)="currentTab = 'document-export-format'">
              Document Export Format
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'integrations' }"
              [routerLink]="['/admin/system-level/integrations']"
              (click)="currentTab = 'integrations'">
              Integrations
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'app-passwords' }"
              [routerLink]="['/admin/system-level/app-passwords']"
              (click)="currentTab = 'app-passwords'">
              App Passwords
            </a>
            <a
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'company-profile-ext' }"
              [routerLink]="['/admin/system-level/company-profile-ext']"
              (click)="currentTab = 'company-profile-ext'">
              Company Profile (Ext)
            </a>
            <a
              *ngIf="applicationContext.userPermissions.superAdmin"
              class="nav-link waves-effect waves-light"
              [ngClass]="{ 'active': currentTab === 'super-admin-support' }"
              [routerLink]="['/admin/system-level/super-admin-support']"
              (click)="currentTab = 'super-admin-support'">
              Support Tools
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-10">
      <div class="card height-setter">
        <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="tab-content">
                  <router-outlet></router-outlet>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</admin-page-template>
