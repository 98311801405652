import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { KeyDateGroup } from 'src/app/models';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'upsert-key-date-group',
  templateUrl: 'upsert-key-date-group-dialog.component.html',
})
export class UpsertKeyDateGroupDialog implements OnInit {
  @Input() keyDateGroup: KeyDateGroup;
  @Input() companyId: number;
  @Input() baseMessageTitle: string;
  @Output() close: EventEmitter<KeyDateGroup> = new EventEmitter<KeyDateGroup>();

  @ViewChild('upsertKeyDateGroupForm')
  upsertKeyDateGroupForm: NgForm | undefined;

  editMode: boolean;
  saving: boolean;

  constructor(
    private readonly _keyDateServices: KeyDatesService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.editMode = this.keyDateGroup.keyDateGroupId ? true : false;
    if (!this.editMode) {
      this.keyDateGroup.column = '';
    }
  }

  save() {
    if (this.upsertKeyDateGroupForm) {
      this.upsertKeyDateGroupForm.form.markAllAsTouched();
      if (!this.upsertKeyDateGroupForm.form.valid) return;
    }

    this.saving = true;
    this.editMode ? this.update() : this.insert();
  }

  private update() {
    this._keyDateServices
      .updateKeyDateGroup(this.companyId, this.keyDateGroup)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Key date group updated successfully',
            `${this.baseMessageTitle} Group`
          );
          if (!res.keyDateConfigurations) {
            res.keyDateConfigurations = this.keyDateGroup.keyDateConfigurations || [];
          }
          this.close.emit(res);
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't update key date group",
            `${this.baseMessageTitle} Group`
          );
        }
      });
  }

  onClose() {
    this.close.emit();
  }

  private insert() {
    this._keyDateServices
      .insertKeyDateGroup(this.companyId, this.keyDateGroup)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Key date group added successfully',
            `${this.baseMessageTitle} Group`
          );
          if (!res.keyDateConfigurations) {
            res.keyDateConfigurations = [];
          }
          this.close.emit(res);
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't update key date group",
            `${this.baseMessageTitle} Group`
          );
        }
      });
  }
}
