import {Component, OnInit, ViewChild} from '@angular/core';
import {CocHistoryComponent} from './history/coc-history.component';

@Component({
  selector: 'change-of-circumstance',
  templateUrl: 'change-of-circumstance.component.html'
})

export class ChangeOfCircumstanceComponent implements OnInit {
  @ViewChild(CocHistoryComponent) historyComponent : CocHistoryComponent;

  selectedTab = 'entry';

  constructor() {

  }

  ngOnInit() {

  }

  changeTab(tabName: string) {
    if(this.selectedTab !== tabName) {
      this.selectedTab = tabName;

      if(tabName === 'history') {
        this.historyComponent.ngOnInit();
      }
    }
  }
}
