import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanCharacteristicsHistoryItem } from 'src/app/models/loan/loan-characteristic-history.model';

@Component({
  selector: 'loan-characteristics-history-dialog',
  templateUrl: 'loan-characteristics-history-dialog.component.html'
})

export class LoanCharacteristicsHistoryDialogComponent implements OnInit {

  @Input()
  history: LoanCharacteristicsHistoryItem[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }
}
