import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TypeOfIncomeEnum} from 'src/app/models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {Utils} from 'src/app/core/services/utils';
import Swal from 'sweetalert2';
import {LeadsService} from 'src/app/modules/leads/services/leads.service';
import {NotificationService} from 'src/app/services/notification.service';
import {LeadIncome} from 'src/app/modules/leads/models/lead-employment.model';
import {getErrorMessageOrDefault} from '../../../../../shared/utils/error-utils';
import { PayPeriod, PayPeriodUtils } from 'src/app/shared/utils/pay-period/pay-period.utils';

@Component({
  selector: 'lead-other-income-in-detail',
  templateUrl: 'lead-other-income-in-detail.component.html',
  styleUrls: ['./lead-other-income-in-detail.component.scss']
})

export class LeadOtherIncomeInDetailComponent implements OnInit {
  @Input() set incomes(value: LeadIncome[]) {
    this.effectiveIncomes = value;
    this.setTotalMonthlyIncome();
  }

  protected effectiveIncomes: LeadIncome[] = [];

  @Output()
  readonly incomesChange = new EventEmitter<LeadIncome[]>();

  @Output()
  readonly incomeChange = new EventEmitter<LeadIncome>();

  @Input()
  readonly nonEmploymentIncomeTypes: EnumerationItem[] = [];

  @Input()
  readonly incomePayPeriods: EnumerationItem[] = [];

  protected totalMonthlyIncome: number = 0;

  private _editingIx: number = -1;

  protected readonly PayPeriod = PayPeriod;

  constructor(
    private readonly _leadsService: LeadsService,
    private readonly _notifyService: NotificationService,
  ) { }

  ngOnInit() {
    this.effectiveIncomes.forEach(income => {
      income.amount = income.monthlyIncome;
      income.payPeriod = PayPeriod.Monthly;
    })
  }

  protected onAmountChanged(income: LeadIncome): void {
    if (income.payPeriod !== PayPeriod.Hourly && income.hoursPerWeek) {
      income.hoursPerWeek = null;
    }
    income.monthlyIncome = PayPeriodUtils.calculateMonthlyAmountByPayPeriod(
      income.amount,
      income.payPeriod as PayPeriod,
      income.hoursPerWeek,
    );

    this.setTotalMonthlyIncome();
    this.incomeChange.emit(income);
  }

  public addOtherIncome() {
    let income = new LeadIncome();
    income.incomeId = Utils.getUniqueId();
    income.payPeriod = this.incomePayPeriods[0].value;
    const typeOfOtherIncome = this.nonEmploymentIncomeTypes.find(income => income.value == TypeOfIncomeEnum.OtherTypesOfIncome);
    income.typeOfIncome = typeOfOtherIncome.value;

    this.incomesChange.emit([...this.effectiveIncomes, income]);
  }

  protected async deleteIncomeClicked(deleteIx: number): Promise<void> {
    const income = this.effectiveIncomes[deleteIx];
    if (income == null) {
      console.error('Income not found at index', deleteIx);
      this._notifyService.showError('Couldn\'t delete income.', 'Error!');
      return;
    }

    const emitIncomesChange = () => {
      this.incomesChange.emit(this.effectiveIncomes.filter((_, ix) => ix !== deleteIx));
    };

    if (!income.leadEmploymentId) {
      emitIncomesChange();
      return;
    }

    const answer = await Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    })

    if (!answer.isConfirmed) {
      return;
    }

    if (deleteIx === this._editingIx) {
      this._editingIx = -1;
    }

    this._leadsService.deleteLeadEmployment(income['leadEmploymentId']).subscribe({
      next: () => {
        this._notifyService.showSuccess('Income deleted successfully', 'Success!');

        emitIncomesChange();
      },
      error: (error) => {
        this._notifyService.showError(
          getErrorMessageOrDefault(error, {
            defaultMessage: 'Couldn\'t delete income.',
          }),
          'Error!',
        );
      },
    });
  }

  private setTotalMonthlyIncome = () => {
    this.totalMonthlyIncome = PayPeriodUtils.calculateTotalMonthlyIncome(this.effectiveIncomes);
  }
}
