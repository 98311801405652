import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppraisalNote } from 'src/app/models/appraisal/appraisal-note.model';

@Component({
  selector: 'add-notes-dialog',
  templateUrl: './add-notes-dialog.component.html',
  styleUrls: ['./add-notes-dialog.component.scss']
})
export class AddNotesDialogComponent implements OnInit {

  @Input()
  deliveryGroupsEnum = [];

  @Input()
  newNotes: AppraisalNote[];

  deliveryGroups: string = "";

  newNote: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  addNote = () => {
    const id = `id-${Math.random().toString(26).slice(2)}`
    this.newNotes.push({ 
      note: this.newNote, 
      deliveryGroups: this.deliveryGroups, 
      thirdPartyNoteId: id
    });

    this.newNote = '';
    this.deliveryGroups = '';
  }

  removeNote(id: string) {
    const index = this.newNotes.findIndex(order => order.thirdPartyNoteId === id)
    this.newNotes.splice(index, 1)
  }

}
