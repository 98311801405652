<div class='card mb-0'>
  <div class='card-header'>
    <h5>Please confirm email address for {{ borrowerName }}</h5>
  </div>

  <div class='card-body'>
    <div class='form-group'>
      <label [for]='id("email")'>Email Address</label>

      <input
        (ngModelChange)='onChangeEmail($event)'
        [id]='id("email")'
        [ngModelOptions]='{ standalone: true }'
        [ngModel]='email'
        class='form-control'
        name='email'
        type='email'
      >
    </div>

    <div
      class='form-group'
      title='Would you like to also update the borrower record with this email address?'
    >
      <label [for]='id("update")'>Update Borrower Record</label>

      <input
        (ngModelChange)='onChangeEmailOverride($event)'
        [attr.title]='forceEmailUpdate ? "Borrower record must be updated in this context" : null'
        [disabled]='forceEmailUpdate || isLoading'
        [id]='id("update")'
        [ngModelOptions]='{ standalone: true }'
        [ngModel]='isEmailOverridden'
        class='form-check-inline'
        name='update'
        type='checkbox'
      >
    </div>
  </div>

  <div class='card-footer d-flex justify-content-end gap-3'>
    <button
      (click)='onClickCancel()'
      [id]='id("cancel")'
      class='btn btn-secondary'
      type='button'
    >
      Cancel
    </button>

    <button
      (click)='sendEmail()'
      [id]='id("send")'
      class='btn btn-primary'
      type='button'
    >
      {{ actionLabel }}
    </button>
  </div>
</div>
