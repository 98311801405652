<ul ngbNav [(activeId)]='tab' class='nav-tabs' #nav='ngbNav'>
  <li ngbNavItem [ngbNavItem]="'contact'">
    <a ngbNavLink>Contact Info</a>
    <ng-template ngbNavContent>
      <div class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3" role="alert">
        <div class="media">
          <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
          <div class="media-body align-self-center">
            <h5>*** Please note. Any information entered in this window will not auto-populate the URLA 2020.</h5>
          </div>
        </div>
      </div>
      <borrower-details [borrowerId]='borrowerId' [loanId]='loanId' (borrowerLoaded)='onBorrowerLoaded($event)'
        (savedBorrower)='onBorrowerSaved($event)' (borrowerEditCancelled)='onBorrowerEditCancelled()'>
      </borrower-details>
    </ng-template>
  </li>

  <li ngbNavItem [ngbNavItem]="'applications'">
    <a ngbNavLink>Applications</a>
    <ng-template ngbNavContent>
      <borrower-applications [allApplicants]='allApplicants' [borrowerId]='borrowerId'></borrower-applications>
    </ng-template>
  </li>

  <li ngbNavItem [ngbNavItem]="'history'">
    <a ngbNavLink>Email/SMS History</a>
    <ng-template ngbNavContent>
      <correspondence-history-table *ngIf='borrowerMessageHistory; else loadingMessages'
        [history]='borrowerMessageHistory' [autoAdaptHeight]='true'></correspondence-history-table>
    </ng-template>
    <ng-template #loadingMessages>
      <loading-indicator [loadingMessage]="'Loading message history, please wait...'">
      </loading-indicator>
    </ng-template>
  </li>

  <li ngbNavItem [ngbNavItem]="'notes'">
    <a ngbNavLink>Notes</a>
    <ng-template ngbNavContent>
      <borrower-notes [borrowerId]='borrowerId'></borrower-notes>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]='nav' class='m-3'></div>