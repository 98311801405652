import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IBreadCrumb } from '../components';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private _eventsSubject = new Subject<BreadcrumbEvent>()

  get events(): Subject<BreadcrumbEvent> {
    return this._eventsSubject;
  }

  overrideWith = (breadCrums: IBreadCrumb[]) => {
    this._eventsSubject.next(new BreadcrumbEvent(BreadcrumbEventType.OverrideRequested, breadCrums));
  }

  append = (breadCrums: IBreadCrumb[]) => {
    this._eventsSubject.next(new BreadcrumbEvent(BreadcrumbEventType.AppendRequested, breadCrums));
  }
}

export class BreadcrumbEvent {
  type: BreadcrumbEventType;
  data: IBreadCrumb[] = [];

  constructor(type: BreadcrumbEventType, data: IBreadCrumb[]) {
    this.type = type;
    this.data = data;
  }
}

export enum BreadcrumbEventType {
  OverrideRequested = 1,
  AppendRequested = 2
}
