import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-postpone-dialog',
  templateUrl: './confirm-postpone-dialog.component.html',
  styleUrls: ['./confirm-postpone-dialog.component.scss']
})
export class ConfirmPostponeDialogComponent implements OnInit {

  postponeDate: NgbDateStruct;

  count: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
