<div class="modal-header">
  <h5 class="modal-title"> Add New Application </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form class="form-horizontal auth-form" #startNewAppForm="ngForm" novalidate id="startNewAppForm"
    name="startNewAppForm" action="#">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Information</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6" *ngIf="enabledchannels.length > 0">
            <div class="form-group mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Channel</label>
              <div class="col-sm-9">
                <select class="form-select" required [(ngModel)]="data.application.channel" name="channel"
                  (change)="changeSelectedChannel(data.application.channel)" #channel="ngModel" [ngClass]="{
                    'is-invalid': channel && channel.touched && channel.invalid
                  }">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let channel of enabledchannels" value="{{ channel.name }}"
                    [selected]="data.application.channel === channel.name">
                    {{ channel.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a channel</div>
              </div>
            </div>
            <div class="mb-3 row" [hidden]="data.application.channel !== 'Wholesale'">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Company</label>
              <div class="col-sm-9">
                <select class="form-select" [(ngModel)]="data.application.companyId" name="company"
                  (change)="loadExternalCompanyUsers(data.application.companyId)">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let company of globalConfig.externalCompanies" value="{{ company.externalCompanyId }}"
                    [selected]="
                      data.application.companyId == company.externalCompanyId
                    ">
                    {{ company.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mb-3 row" [hidden]="!data.application.channel">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">{{
                globalConfig.firstRole.roleName }}</label>
              <div class="col-sm-9">
                <select class="form-select" [(ngModel)]="data.primaryRoleUserId" name="internalContact"
                  (change)="populateLoanOptions()">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let user of availableUsers" value="{{ user.userCompanyGuid }}">
                    {{ user.lastName }}, {{ user.firstName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Purpose</label>
              <div class="col-sm-9">
                <select class="form-select" required [(ngModel)]="data.application.loanPurposeId"
                  (change)="fillLoanStatus(data.application.loanPurposeId)" #loanPurpose="ngModel" name="loanPurposeId"
                  [ngClass]="{
                    'is-invalid':
                      loanPurpose && loanPurpose.touched && loanPurpose.invalid
                  }">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let loan of availableLoanPurpose" value="{{ loan.loanPurposeId }}">
                    {{ loan.loanPurposeName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a loan purpose</div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Status</label>
              <div class="col-sm-9">
                <input name="loanstatus" id="loanstatus" class="form-control" readonly style="background-color: #eee"
                  [(ngModel)]="currentLoanStatusName" />
              </div>
            </div>
            <div class="form-group mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Loan Type</label>
              <div class="col-sm-9">
                <select class="form-select" required [(ngModel)]="data.application.loanTypeId" #loanType="ngModel"
                  name="loanTypeId" [ngClass]="{
                    'is-invalid': loanType && loanType.touched && loanType.invalid
                  }">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let loan of availableLoanTypes" value="{{ loan.loanTypeId }}">
                    {{ loan.loanTypeName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a loan type</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Address
          <button type="button" (click)="useBorrowerAddress(true)" class="mx-2 btn btn-outline-primary btn-sm">
            Use Borrower Address
          </button>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">
                Address
              </label>
              <div class="col-sm-9">
                <address-autocomplete-input
                  (addressChange)='handleAddressChange($event, data.application)'
                  [(ngModel)]='data.application.mailingStreet'
                  id='address'
                  name='address'
                  placeholder='Number and Street'
                ></address-autocomplete-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address 2</label>
              <div class="col-sm-9">
                <input class="form-control" id="address2" name="address2" type="text"
                  [(ngModel)]="data.application.mailingPOBox" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">City</label>
              <div class="col-sm-9">
                <input class="form-control" placeholder="City" id="city" name="city" type="text"
                  [(ngModel)]="data.application.mailingCity" />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Zip Code</label>
              <div class="col-sm-9">
                <zip-code-input [(ngModel)]="data.application.mailingZip" [name]="'mailingZip'"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'borrower')">
                </zip-code-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">State</label>
              <div class="col-sm-9">
                <select class="form-select" name="state" [(ngModel)]="data.application.mailingState">
                  <option selected>-- Select One --</option>
                  <option *ngFor="let item of globalConfig.states | keyvalue" value="{{ item.key }}">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!enabledCoBorrower" class="mb-3">
      <button class="btn btn-primary-outline" (click)="addCoBorr()">
        Add a Co-borrower
      </button>
    </div>
    <div *ngIf="enabledCoBorrower" class="mb-3" (click)="removeCoBorr()">
      <button class="btn btn-primary-outline">Remove CoBorrower</button>
    </div>
    <div class="card" *ngIf="enabledCoBorrower">
      <div class="card-header">
        <h4 class="card-title">CoBorrower Information</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">First Name</label>
              <div class="col-sm-10">
                <input class="form-control" placeholder="First Name" id="coborrowerfirstname" name="coborrowerfirstname"
                  type="text" [(ngModel)]="data.coBorrower.firstName" required [ngClass]="{
                    'is-invalid': coFirst && coFirst.touched && coFirst.invalid
                  }" #coFirst="ngModel" />
                <div class="invalid-feedback">Please enter a first name</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Last Name</label>
              <div class="col-sm-10">
                <input class="form-control" placeholder="Last Name" id="coborrowerlastname" name="coborrowerlastname"
                  type="text" [(ngModel)]="data.coBorrower.lastName" required [ngClass]="{
                    'is-invalid': coLast && coLast.touched && coLast.invalid
                  }" #coLast="ngModel" />
                <div class="invalid-feedback">Please enter a last name</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Email</label>
              <div class="col-sm-10">
                <input class="form-control" placeholder="Email" id="coborrowerEmail" name="coborrowerEmail" type="text"
                  [(ngModel)]="data.coBorrower.email" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Birth Date</label>
              <div class="col-sm-10">
                <date-input
                  name="coborrowerbirthdate"
                  [id]="'coborrowerbirthdate'"
                  [(ngModel)]="data.coBorrower.birthDate">
                </date-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Phone</label>
              <div class="col-sm-10">
                <input class="form-control" name="coborrowerphone" placeholder="Phone" type="text" mask="(000) 000-0000"
                  [(ngModel)]="data.coBorrower.homePhone" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Work Phone</label>
              <div class="col-sm-10">
                <input class="form-control" name="coborrowerworkphone" placeholder="Work Phone" type="text"
                  mask="(000) 000-0000" [(ngModel)]="data.coBorrower.workPhone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="enabledCoBorrower">
      <div class="card-header">
        <h4 class="card-title">
          CoBorrower Address
          <button type="button" (click)="useBorrowerAddress(false)" class="ms-2 btn btn-outline-primary btn-sm">
            Use Borrower Address
          </button>
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address</label>
              <div class="col-sm-9">
                <address-autocomplete-input
                  (addressChange)='handleAddressChange($event, data.coBorrower)'
                  [(ngModel)]='data.coBorrower.mailingStreet'
                  id='coaddress'
                  name='coaddress'
                  placeholder='Number and Street'
                ></address-autocomplete-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Address 2</label>
              <div class="col-sm-9">
                <input class="form-control" id="address1" name="address1" type="text"
                  [(ngModel)]="data.coBorrower.mailingPOBox" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">City</label>
              <div class="col-sm-9">
                <input class="form-control" placeholder="City" id="cocity" name="cocity" type="text"
                  [(ngModel)]="data.coBorrower.mailingCity" />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Zip Code</label>
              <div class="col-sm-9">
                <zip-code-input [(ngModel)]="data.coBorrower.mailingZip" [name]="'cozipCode'"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'coBorrower')">
                </zip-code-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 row">
              <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">State</label>
              <div class="col-sm-9">
                <select class="form-select" name="coState" [(ngModel)]="data.coBorrower.mailingState">
                  <option selected>-- Select One --</option>
                  <option *ngFor="let item of globalConfig.states | keyvalue" value="{{ item.key }}">
                    {{ item.value }}
                  </option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-end">
  <button class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>

  <button class="btn btn-primary mx-2" (click)="save()" [disabled]="isSaving">
    <span *ngIf="!isSaving">
      Save Application
    </span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Saving
    </span>
  </button>
</div>
