<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Sellers
      </div>
    </h4>
  </div>
  <div class="card-body">
    <div class="row" *ngFor="let seller of docPrep.sellers; let i = index">
     <document-preparation-seller-v2
      [index]="i"
      [seller]="seller"
      [sellerTypes]="sellerTypes"
      [suffixes]="suffixes"
      [entityTypes]="entityTypes"
      [maritalStatuses]="maritalStatuses"
      (deleteSellerClicked)="onDeleteSellerClicked($event)">
     </document-preparation-seller-v2>
    </div>
    <div class="row mt-3">
      <div class="form-group">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddSellerClicked()">
          <i class="fas fa-plus me-1"></i>
          Add Seller </button>
      </div>
    </div>
  </div>
</div>