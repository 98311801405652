import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-status-dialog',
  templateUrl: './confirm-status-dialog.component.html',
  styleUrls: ['./confirm-status-dialog.component.scss']
})
export class ConfirmStatusDialogComponent {

  selectedTasks: any;

  requestBorrower: boolean;

  canSave: boolean = false;

  status: string = null;
  reviewStatus: string = null
  requestedStatus: string = null
  requestedRequiresReviewStatus: string = null
  rejectedStatus: string = null
  rejectedRequiresReviewStatus: string = null
  submittedStatus: string = null
  submittedRequiresReviewStatus: string = null
  approveStatus: string = null
  approveRequiresReviewStatus: string = null
  notApplicableStatus: string = null
  notApplicableRequiresReviewStatus: string = null

  constructor(public activeModal: NgbActiveModal) { }

  setTaskStatusBulk = (list, status) => {
    if (!status) {
      this.canSave = false;
      return;
    }
    this.canSave = true;
    list.forEach((item) => {
      item.updatedStatus = status;
    });
  }

}
