import { Component, Injector, Input, OnInit } from '@angular/core';
import { groupBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { LoanDoc, MortgageBorrower } from 'src/app/models';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'identifying-documents',
  templateUrl: './identifying-documents.component.html',
  styleUrls: ['./identifying-documents.component.scss']
})
export class IdentifyingDocumentsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  selectedBorrower: MortgageBorrower;

  @Input()
  showTab: boolean = false;

  mortgageBorrowers: MortgageBorrower[] = []

  activeBorrower: MortgageBorrower;

  borrowerGroups: MortgageBorrower[][] = [];

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  constructor(
    injector: Injector,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _notificationService: NotificationService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _loanDocService: LoanDocService,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(injector)
   }

  ngOnInit(): void {
    this.getBorrowerDisplayName = Utils.getBorrowerDisplayName;

    this.mortgageBorrowers = this.applicationContext.application.mortgageLoan.borrowers;
    this.borrowerGroups = this.mortgageBorrowers.length > 0 ? Object.values(groupBy(this.mortgageBorrowers, 'printApplicationIndex')) : [];
    if (this.mortgageBorrowers && this.mortgageBorrowers.length > 0){
      this.activeBorrower = this.mortgageBorrowers[0];
    }
  }

  selectBorrower = (borrower: MortgageBorrower) => {
    this.activeBorrower = borrower;
  }

  isSelectedBorrower(borrower: MortgageBorrower): boolean {
    return this.activeBorrower?.borrowerId === borrower.borrowerId;
  }

  onSaveClicked = () => {
    if (this.selectedBorrower) {
      let index = this.mortgageBorrowers.findIndex(b => b.borrowerId == this.selectedBorrower.borrowerId);
      this.mortgageBorrowers[index] = this.selectedBorrower;
    }

    this.mortgageBorrowers.forEach(borrower => {
      if (borrower.identifyingDocuments && borrower.identifyingDocuments.length > 0) {
        borrower.identifyingDocuments.forEach(id => {
          id.issueDate = id.issueDate ? new Date(id.issueDate).toISOString() : '';
          id.expirationDate = id.expirationDate ? new Date(id.expirationDate).toISOString() : '';
        })
      }
    });

    this.applicationContext.application.mortgageLoan.borrowers = this.mortgageBorrowers;


    this._spinner.show();

    let loanDocRequest: any = {}
    this.mortgageBorrowers.forEach(borrower => {
      if (borrower.identifyingDocuments && borrower.identifyingDocuments.length > 0) {
        borrower.identifyingDocuments.forEach(document => {
          if (document.file && document.file.isNewFile) {
            let loanDoc = new LoanDoc(this.applicationContext.application.applicationId);
            document.file['active'] = true;
            document.file['guid'] = null;
            loanDoc.docFiles.push(document.file);
            loanDocRequest[document.identifyingDocumentId] = this._loanDocService.upsertLoanDoc(loanDoc);
          }
        })
      }
    });
    if (Object.keys(loanDocRequest).length) {
      forkJoin(loanDocRequest).subscribe(reponses => {
        let uploadFileRequest: any = {};
        Object.keys(reponses).forEach(key => {
          if (reponses[key]) {
            const borr = this.mortgageBorrowers.find(borr => borr.identifyingDocuments.some(id => id.identifyingDocumentId == Number(key)));
            const document = borr.identifyingDocuments.find(id => id.identifyingDocumentId == Number(key));
            const borrIndex = this.mortgageBorrowers.findIndex(borr => borr.identifyingDocuments.some(id => id.identifyingDocumentId == Number(key)));
            const documentIndex = this.mortgageBorrowers[borrIndex].identifyingDocuments.findIndex(id => id.identifyingDocumentId == Number(key))
            this.mortgageBorrowers[borrIndex].identifyingDocuments[documentIndex].loanDocId = reponses[key].loanDocId
            uploadFileRequest[key] = this._loanDocsService.uploadFileForLoanDoc(document.file.file, document.file, reponses[key].loanDocId, false, false, false);
          }
        });
        forkJoin(uploadFileRequest).subscribe(reponses => {
          let getLoanDocRequest: any = {};
          Object.keys(reponses).forEach(key => {
            if (reponses[key]) {
              const borrIndex = this.mortgageBorrowers.findIndex(borr => borr.identifyingDocuments.some(id => id.identifyingDocumentId == Number(key)));
              const documentIndex = this.mortgageBorrowers[borrIndex].identifyingDocuments.findIndex(id => id.identifyingDocumentId == Number(key));
              getLoanDocRequest[key] = this._loanDocService.getLoanDoc(this.mortgageBorrowers[borrIndex].identifyingDocuments[documentIndex].loanDocId);
            }
          });
          forkJoin(getLoanDocRequest).subscribe(reponses => {
            Object.keys(reponses).forEach(key => {
              if (reponses[key]) {
                const borrIndex = this.mortgageBorrowers.findIndex(borr => borr.identifyingDocuments.some(id => id.identifyingDocumentId == Number(key)));
                const documentIndex = this.mortgageBorrowers[borrIndex].identifyingDocuments.findIndex(id => id.identifyingDocumentId == Number(key));
                this.mortgageBorrowers[borrIndex].identifyingDocuments[documentIndex].docFileId = reponses[key].docFiles[0]?.docFileId;
              }
            });
            this.saveLoanInfo();
          }, (err) => {
            this._spinner.hide();
            this._notificationService.showError(
              (err.error?.message || err.message) || 'An error occurred.',
              'Error!',
            );
          });
        }, (err) => {
          this._spinner.hide();
          this._notificationService.showError(
            (err.error?.message || err.message) || 'An error occurred.',
            'Error!',
          );
        });
      }, (err) => {
        this._spinner.hide();
        this._notificationService.showError(
          (err.error?.message || err.message) || 'An error occurred.',
          'Error!',
        );
      });
    } else {
      this.saveLoanInfo();
    }

  }

  saveLoanInfo = () => {
    this._appDetailsService.saveLoanInfo(
      this.applicationContext.application.applicationId,
      {
        application: this.applicationContext.application,
        customData: null,
      }
    ).subscribe(response => {
      this._spinner.hide();
      const applicationResult = response.application;
      this.applicationContextService.updateMortgageAndApplication(applicationResult.mortgageLoan, applicationResult);
      this._notificationService.showSuccess(
        'Mortgage has been saved successfully.',
        'Success!'
      );
    }, (err) => {
      this._spinner.hide();
      this._notificationService.showError(
        (err.error?.message || err.message) || 'An error occurred.',
        'Error!',
      );

    });
  }

}
