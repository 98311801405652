import { BorrowerDto } from "src/app/modules/contacts/models/borrower-dto.model";

export class Borrower extends BorrowerDto {
  pull?: boolean;
  middleName: string;
  suffix: string;
  acceptedEConsentDate: string;
  acceptedEConsentIpAddress: string;
  socialSecNum: string;

  alertViaSms: boolean;
  workPhone: string;
  extPhone: string;
  fax: string;

  doNotCall: boolean;
  doNotEmail: boolean;

  loanBeamApplicationId: number;
  contactListId: number;
  contactListRecordId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
}

export enum BorrowerEnum {
  'Primary Borrower' = 0,
  'Co Borrower' = 1,
}
