<div class="row mb-2">
    <div class="table-responsive" style="border-bottom: none;">
        <table class="table mb-0">
            <thead class="table-light">
                <tr>
                    <th class="col-2"> Account Owner </th>
                    <th class="col-2"> Creditor </th>
                    <th class="col-1"> Mo. Payment </th>
                    <th class="col-1"> Balance(s) </th>
                    <th class="col-1"> Payoffs </th>
                    <th class="col-3"> Type </th>
                    <th class="col-1"> Interest Rate </th>
                    <th class="col-1 text-center"> Action </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let liability of liabilities; let index = index;" style="height: 50px;">
                    <td class="col-2">
                        <ng-select2 class="select2 mb-3 select2-multiple"
                            name="owningBorrowerIds{{liability.liabilityId}}" [(ngModel)]="liability.owningBorrowerIds"
                            [options]="optionsMultipleSelect" [data]="possibleAccountOwners"
                            (ngModelChange)="accountTypeOrBorrowerChange(liability)">
                        </ng-select2>
                    </td>

                    <td class="col-2">
                        <input class="form-control" name="creditor" [(ngModel)]="liability.holderName">
                    </td>

                    <td class="col-1">
                        <currency-input name="moPayment" [(ngModel)]="liability.monthlyPayment"
                            (ngModelChange)="onLiabilityEdited()">
                        </currency-input>
                    </td>
                    <td class="col-1">
                        <currency-input name="balance" [(ngModel)]="liability.unpaidBalance"
                            (ngModelChange)="onLiabilityEdited()" (blur)="unpaidBalanceChange(liability)">
                        </currency-input>
                    </td>

                    <td class="col-1">
                        <currency-input name="moPayment" [(ngModel)]="liability.partialPayoffAmount" [readonly]="true"
                            (ngModelChange)="onLiabilityEdited()">
                        </currency-input>
                    </td>

                    <td class="col-3">
                        <select id="typeOfLiability" name="typeOfLiability" class="form-select"
                            [(ngModel)]="liability.typeOfLiability" (ngModelChange)="onLiabilityEdited()">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let liabilityType of liabilityTypeOptions" [ngValue]="liabilityType.value">
                                {{ liabilityType.name }}
                            </option>
                        </select>
                    </td>

                    <td class="col-1">
                        <percent-input name="interestRate" [(ngModel)]="liability.interestRate" (ngModelChange)="onLiabilityEdited()"></percent-input>
                    </td>

                    <td class="col-1 text-center">
                        <a href="javascript: void(0);" class="dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" title="Payoff"
                            [ngClass]="{'dollar-color': liability.payoffType == 'Partial' || liability.payoffType == 'Full'}"
                            *ngIf="liability.unpaidBalance">
                            <i class="fa fa-dollar-sign"></i>
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item w-100" role="button"
                                (click)="setPayoffAmountToFull(liability)">Full</a>
                            <a class="dropdown-item w-100" role="button"
                                (click)="openPayoffDialog(liability)">Partial</a>
                            <a class="dropdown-item w-100" role="button"
                                (click)="setPayoffAmountToNone(liability)">None</a>
                        </div>

                        <a href="javascript: void(0);" (click)="deleteLiability(index)"><i
                                class="fa fa-trash-alt mx-2"></i></a>
                    </td>
                </tr>
                <tr>
                    <td class="col-2">
                    </td>
                    <td class="col-2 text-end">
                        <b>Total</b>
                    </td>
                    <td class="col-1">
                        <b><span class="ps-2">{{financialMonthlyPaymentSubTotal | currency}}</span></b>
                    </td>
                    <td class="col-1">
                        <b><span class="ps-2">{{financialUnpaidBalanceSubTotal | currency}}</span></b>
                    </td>
                    <td class="col-1">
                        <b><span class="ps-2">{{financialPartialPayoffAmount | currency}}</span></b>
                    </td>
                    <td class="col-3 text-end">
                        <b>Blended %</b>
                    </td>
                    <td class="col-1 text-center">
                        <b><span>--</span></b>
                    </td>
                    <td class="col-1">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
