import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, StepValidationArgs, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, defer, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { ApplicationContext, Borrower, LosCredential, LosCredentialsArray, LosProviderFeature } from 'src/app/models';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { Table } from 'primeng/table';
import { chain } from 'lodash';
import { finalize, tap } from 'rxjs/operators';
import { LosCondition } from '../../../../models/los/los-condition.model';

@Component({
  selector: 'import-conditions-dialog',
  templateUrl: './import-conditions-dialog.component.html',
  styleUrls: ['./import-conditions-dialog.component.scss']
})
export class ImportConditionsDialogComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  context: ApplicationContext;

  @ViewChild(Table) leadTagsTable: Table;

  globalFilterFields: string[] = ['loanNumber', 'borrowerFirstName', 'borrowerLastName'];


  losVendorStr: string;

  losProviders: LosCredentialsArray = [];

  conditionList = [];

  mappedLosConditionRefIds = [];

  taskList = [];

  internalContacts = [];

  documentTypes = [];

  loanSearchList;

  selectedVendor;

  selectedLoanId: string;
  selectedLoan: any;

  borrowers: Borrower[] = [];

  // loanDocTaskId: number;

  ownerRole: string = '';

  rowFilter: string = '';

  printFilter: string = '';

  isSearching: boolean = false;

  submitting: boolean = false;

  step: number = 1;

  loadingConditions: boolean = false;

  losCredentialId: number = null;
  losReference: string;
  borrowerFirst: string;
  borrowerLast: string;

  loanSearchListColumns;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: false,
    },
  };

  constructor(private readonly _losService: LosService,
    public activeModal: NgbActiveModal,
    private readonly _ngWizardService: NgWizardService,
    private readonly _taskService: TaskService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _notifyService: NotificationService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initWizard().subscribe();

    this.documentTypes = this.context.globalConfig.documentType;
    this.getLoanTasks();
    this.getBorrowers();
    this.setInternalContacts();
    this.loanSearchListColumns = [
      { field: 'selection', header: 'Select', visible: true, sortable: false },
      { field: 'loanNumber', header: 'Loan Number', visible: true, sortable: true },
      { field: 'borrowerFirstName', header: 'Borrower First', visible: true, sortable: true },
      { field: 'borrowerLastName', header: 'Borrower Last', visible: true, sortable: true },
      { field: 'subjectPropertyAddress', header: 'Subject Property Address', visible: true, sortable: true },
      { field: 'subjectPropertyCity', header: 'Subject Property City', visible: true, sortable: true },
    ];
    this.loanSearchListColumns.forEach(column => {
      this.globalFilterFields.push(column.field);
    });
  }

  private initWizard(): Observable<void> {
    const application = this.context.application;
    const losVendor = application.losVendor;
    this.losVendorStr = losVendor;

    // If the application has a LOS vendor, we can skip the first step.
    const init$ = !losVendor
      ? this.initWithStep1()
      : this.initWithStep2();

    return from(this._spinner.show()).pipe(
      switchMap(() => init$),
      switchMap(() => from(this._spinner.hide())),
      map(() => void 0),
    );
  }

  /**
   * If the application doesn't have a LOS vendor, we need to show the first
   * step.
   */
  private initWithStep1(): Observable<void> {
    return this._losService.getLosVendorsForImportConditions(this.appId).pipe(
      catchError((error) => {
        const fallbackMessage = 'Couldn\'t get LOS vendors.';
        const message = error?.error?.message || error?.message || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
        return of([] as LosCredential[]);
      }),
      tap((losVendors) => {
        const vendors = losVendors || [];
        vendors.forEach((vendor) => {
          // To disable first and last name input fields
          vendor['borrowerDisabled'] =
            vendor.vendorFeatures.indexOf(LosProviderFeature.Lookup) > -1;
        });
        this.losProviders = vendors;

        if (!vendors.length) {
          return;
        }

        const application = this.context.application;
        const index = losVendors.findIndex(v => v.losVendor === application.losVendor);
        if (index > -1) {
          this.losVendorStr = application.losVendor;
        }
        // if losVendorStr was not found, and we want to select default provider
        else if (vendors.length === 1) {
          this.startWithOneVendor();
        }
      }),
      map(() => void 0),
    );
  }

  /**
   * If the application has a LOS vendor, we can skip the first step.
   */
  private initWithStep2(): Observable<void> {
    return defer(() => {
      this.loadingConditions = true;

      this.showNextStep();

      const application = this.context.application;
      return this._losService.getLosConditions(application.applicationId).pipe(
        catchError((error) => {
          const fallbackMessage = 'Couldn\'t get LOS conditions.';
          const message = error?.error?.message || error?.message
            || fallbackMessage;
          console.error(fallbackMessage, error);
          this._notifyService.showError(message, 'Error');
          return of([] as LosCondition[]);
        }),
        tap(this.setLosConditions.bind(this)),
        finalize(() => this.loadingConditions = false),
      );
    }).pipe(map(() => void 0));
  }

  showPreviousStep(event?: Event) {
    this._ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this._ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this._ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this._ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    this.step = args.step.index + 1;
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  changeLosCredential(losCredentialId: number) {
    this.losCredentialId = losCredentialId;
    this.selectedVendor = this.losProviders.find((provider) => provider.credentialId === +this.losCredentialId) || {};
    if (this.selectedVendor.borrowerDisabled) {
      this.borrowerFirst = '';
      this.borrowerLast = '';
    }
  }

  search() {
    this.isSearching = true;

    this.selectedLoanId = undefined;
    this.selectedLoan = undefined;

    let filter = this.losCredentialId + '?';
    if (this.losReference && this.losReference.length > 0)
      filter += 'loanNumber=' + this.losReference + '&';
    if (this.borrowerFirst && this.borrowerFirst.length > 0)
      filter += 'borrowerFirstName=' + this.borrowerFirst + '&';
    if (this.borrowerLast && this.borrowerLast.length > 0)
      filter += 'borrowerLastName=' + this.borrowerLast + '&';

    this._losService.searchLos(filter).pipe(
      finalize(() => this.isSearching = false),
    ).subscribe({
      next: (response) => {
        this.loanSearchList = response;
        this.isSearching = false;
      },
      error: (error) => {
        const fallbackMessage = 'Couldn\'t search LOS.';
        const message = error?.error?.message || error?.message
          || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
      }
    });
  }

  setSelectedLoan(loanNumber, losIdentifier) {
    this.selectedLoanId = losIdentifier;
    this.selectedLoan = {
      loanNumber: loanNumber,
      losIdentifier: losIdentifier,
    };
  }

  protected selectLoan(): void {
    from(this._spinner.show()).pipe(
      switchMap(() => {
        this.loadingConditions = true;

        return this._losService.getLosConditionsUsingCredentialId(
          this.losCredentialId,
          this.selectedLoanId,
        );
      }),
      catchError((error) => {
        const fallbackMessage = 'Couldn\'t get LOS conditions.';
        const message = error?.error?.message || error?.message || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
        return of([] as LosCondition[]);
      }),
      tap((losConditions) => {
        this.setLosConditions(losConditions);
        if (losConditions.length > 0) {
          this.showNextStep();
        }
      }),
      switchMap(() => {
        this.loadingConditions = false;
        return from(this._spinner.hide());
      }),
      map(() => undefined),
    ).subscribe();
  }

  private setLosConditions(losConditions: LosCondition[]): void {
    losConditions = losConditions || [];
    this.conditionList = losConditions.filter(x =>
      this.mappedLosConditionRefIds.indexOf(x.losConditionRefId) < 0);
  }

  getLoanTasks() {
    this._taskService.getTaskByLoan(this.appId).subscribe((response) => {
      this.taskList = chain(response)
        .filter(x => !x.losConditionRefId)
        .orderBy(task => task.description)
        .value();
      this.mappedLosConditionRefIds = response.filter(x => x.losConditionRefId != null).map(x => x.losConditionRefId);
    });
  }

  getBorrowers = () => {
    this._appDetailsService.getBorrowers(this.appId).subscribe((response) => {
      this.borrowers = response;
    })
  }

  importLoanConditions = () => {
    const [
      importCalls,
      linkCalls,
    ] = this.conditionList.reduce((acc, cond) => {
      if (!cond.import || !cond.loanDocTaskId) {
        return acc;
      }

      const selectedTask = cond.selectedTask;
      if (cond.loanDocTaskId == '0') {
        if (selectedTask.documentTypeId == 0) {
          selectedTask.documentTypeId = null;
        }

        selectedTask.losConditionRefId = cond.losConditionRefId;
        selectedTask.applicationId = this.appId;
        selectedTask.active = true;
        selectedTask.requestBorrower = cond.mappedLoanDocTask.requestBorrower;

        let data = {
          loanDocTask: selectedTask,
          multipleBorrower: null,
        };

        if (selectedTask.borrowerId && selectedTask.borrowerId != '') {
          data.multipleBorrower = [selectedTask.borrowerId];
        }

        acc[0].push(this._taskService.upsertLoanDocTask(data));
        return acc;
      }

      const data = {
        loanDocTaskId: cond.loanDocTaskId,
        losConditionRefId: cond.losConditionRefId,
        conditionId: cond.conditionId,
        condition: true,
        conditionText: selectedTask.description,
        conditionType: selectedTask.priorTo,
        userId: selectedTask.userId,
      };
      acc[1].push(this._taskService.linkToLosCondition(data));
      return acc;
    }, [[], []] as [Observable<never>[], Observable<never>[]]);

    if (importCalls.length === 0 && linkCalls.length === 0) {
      this._notifyService.showWarning('No conditions selected.', 'Warning');
      return;
    }

    const import$ = importCalls.length > 0
      ? forkJoin(importCalls).pipe(
        tap(() => this._notifyService.showSuccess(
          `Imported ${importCalls.length} conditions successfully.`,
          'Success',
        )),
        catchError((error) => {
          const fallbackMessage = 'Couldn\'t import conditions.';
          const message = error?.error?.message || error?.message || fallbackMessage;
          console.error(fallbackMessage, error);
          this._notifyService.showError(message, 'Error');
          return of([] as LosCondition[]);
        }),
      )
      : of([]);

    const link$ = linkCalls.length > 0
      ? forkJoin(linkCalls).pipe(
        tap(() => this._notifyService.showSuccess(
          `Linked ${linkCalls.length} conditions successfully.`,
          'Success',
        )),
        catchError((error) => {
          const fallbackMessage = 'Couldn\'t link conditions.';
          const message = error?.error?.message || error?.message || fallbackMessage;
          console.error(fallbackMessage, error);
          this._notifyService.showError(message, 'Error');
          return of([]);
        }),
      )
      : of([]);

    this.submitting = true;
    forkJoin([
      import$,
      link$,
    ]).pipe(
      finalize(() => this.submitting = false),
    ).subscribe(() => {
      this.activeModal.close(importCalls.length + linkCalls.length);
    });
  }

  toggleSelectedCondition = (losConditionRefId) => {
    var cond = this.conditionList.find(x => x.losConditionRefId == losConditionRefId);
    if (cond.import)
      cond.import = false;
    else
      cond.import = true;
  }

  setTaskIdForCondition = (cond: any) => {
    let selectedTask = this.taskList.find(x => x.loanDocTaskId == cond.loanDocTaskId);
    cond.selectedTask = selectedTask ? selectedTask : cond.mappedLoanDocTask;
    if (!cond.selectedTask.documentTypeId) {
      cond.selectedTask.documentTypeId = null;
    }
  }

  getRoleContact = (channel) => {
    let retVal = [];
    if (this.context.globalConfig.enabledChannels.length > 0 && channel && channel != '') {
      this.context.globalConfig.channelRoles[channel.toLowerCase()].forEach(role => {
        if (role.isLoanContact) {
          const roleChannel = role.roleChannels.find(el => el.channel === channel);
          role.orderByChannel = roleChannel.order || null;
          role.order = role.orderByChannel;
          retVal.push(role);
        }
      });
      // retVal = $filter('orderBy')(retVal, 'orderByChannel');
    } else {
      this.context.globalConfig.roles.forEach(function (role) {
        if (role.isLoanContact) {
          retVal.push(role);
        }
      });
      // retVal = $filter('orderBy')(retVal, 'order');
    }
    return retVal;
  }

  setInternalContacts = () => {
    this._internalContactsService.getInternalContacts(this.appId).subscribe((response) => {
      const roleContacts = this.getRoleContact(this.context.application.channel);
      roleContacts.forEach(rc => {
        var internalContact = response.find(el => el.roleId === rc.roleId);
        if (internalContact && internalContact.userId) {
          var user = this.context.globalConfig.users.find(el => el.userCompanyGuid === internalContact.userId);
          if (!user)
            user = this.context.globalConfig.tpoUsers.find(el => el.userCompanyGuid === internalContact.userId)
          if (user) {
            this.internalContacts.push({
              id: user.userCompanyGuid,
              roleId: rc.roleId,
              firstName: user.firstName,
              lastName: user.lastName,
              roleName: rc.roleName,
              order: rc.order
            });
          }
        }
      });
    });
  }

  private startWithOneVendor = () => {
    this.changeLosCredential(this.losProviders[0].credentialId);
  }
}
