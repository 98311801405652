<div class="row">
  <div class="col-xs-12 col-md-6">
    <div class="row form-group" *ngIf="enabledChannels.length > 0">
      <label class="form-label" for="channel">Channel</label>
      <div>
        <select
          id="channel"
          name="channel"
          class="form-select"
          required
          [(ngModel)]="channel"
          (ngModelChange)="showChannelConfirmationDialog()"
          #channelInput="ngModel"
          [ngClass]="{
            'is-invalid':
              channelInput && channelInput.touched && channelInput.invalid
          }"
        >
          <option [ngValue]="null">-- Select One --</option>
          <option
            *ngFor="let item of enabledChannels"
            [ngValue]="item.name"
            [hidden]="item.name == 'Wholesale'"
          >
            {{ item.name }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <div class="row form-group">
      <label class="form-label" for="loanPurposeId">Loan Purpose</label>
      <div>
        <select
          class="form-select"
          name="loanPurposeId"
          id="loanPurposeId"
          [(ngModel)]="loanPurposeId"
          (ngModelChange)="showLoanPurposeChangeConfirmationDialog()"
          #loanPurposeIdInput="ngModel"
          [ngClass]="{
            'is-invalid':
              loanPurposeIdInput &&
              loanPurposeIdInput.touched &&
              loanPurposeIdInput.invalid
          }"
          required
        >
          <option [ngValue]="null">--Select One--</option>
          <option
            *ngFor="let loanPurpose of loanPurposeOptions"
            [ngValue]="loanPurpose.value"
          >
            {{ loanPurpose.name }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <div class="row form-group">
      <label class="form-label" for="loanTypeId">Loan Type</label>
      <div>
        <select
          class="form-select"
          name="loanTypeId"
          id="loanTypeId"
          [(ngModel)]="application.loanTypeId"
          (ngModelChange)="onLoanTypeChanged()"
        >
          >
          <option [ngValue]="null">--Select One--</option>
          <option
            *ngFor="let loanType of loanTypeOptions"
            [ngValue]="loanType.value"
          >
            {{ loanType.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <label class="form-label" for="leadCampaign">Lead Campaign</label>
      <div>
        <select
          class="form-select"
          id="leadCampaign"
          name="leadCampaign"
          #leadCampaign="ngModel"
          (ngModelChange)="onLeadCampaignChanged()"
          [(ngModel)]="application.leadCampaignId"
          [disabled]="
            isAdmin
              ? false
              : !!application.leadCampaignId && !isLeadCampaignDirty
          "
        >
          <option [ngValue]="null">--Select One--</option>
          <option
            *ngFor="let leadCampaign of leadCampaigns"
            value="{{ leadCampaign.leadCampaignId }}"
          >
            {{ leadCampaign.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-group d-xs-none" *ngIf="!enabledChannels.length">
      <label class="form-label" for="leadRefId">Lead Ref Id</label>
      <div>
        <input
          class="form-control"
          [disabled]="!application.leadRefId ? false : !isReferralIdDirty"
          name="leadRefId"
          (ngModelChange)="onReferralIdChanged()"
          [(ngModel)]="application.leadRefId"
          #leadRefId="ngModel"
          [ngClass]="{
            'is-invalid': leadRefId && leadRefId.touched && leadRefId.invalid
          }"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <div class="row form-group">
      <label class="form-label" for="referralSource">Referral Source</label>
      <div>
        <div class="d-flex">
          <div class="flex-grow-1">
            <ng-select
              width="100%"
              name="referralSource"
              bindLabel="displayName"
              bindValue="agentId"
              placeholder="Select Referral Source"
              [closeOnSelect]="true"
              [(ngModel)]="application.referralSource"
              [items]="referralSources"
            >
            </ng-select>
          </div>
          <a
            class="btn btn-outline-primary"
            style="margin-left: 3px"
            (click)="onAddNewReferralSourceClicked()"
          >
            <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="form-label" for="secondaryReferralSource"
        >Secondary Referral Source</label
      >
      <div>
        <div class="d-flex">
          <div class="flex-grow-1">
            <ng-select
              width="100%"
              name="secondaryReferralSource"
              bindLabel="displayName"
              bindValue="agentId"
              placeholder="Select Referral Source"
              [closeOnSelect]="true"
              [(ngModel)]="application.secondaryReferralSource"
              [items]="referralSources"
            >
            </ng-select>
          </div>
          <a
            class="btn btn-outline-primary"
            style="margin-left: 3px"
            (click)="onAddNewReferralSourceClicked()"
          >
            <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="form-label" for="leadSource">Lead Source</label>
      <div>
        <input
          class="form-control"
          name="leadSource"
          [(ngModel)]="application.leadSource"
          #leadSource="ngModel"
          [ngClass]="{
            'is-invalid': leadSource && leadSource.touched && leadSource.invalid
          }"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
    <div class="row form-group" *ngIf="enabledChannels.length > 0">
      <label class="form-label" for="leadRefId">Lead Ref Id</label>
      <div>
        <input
          class="form-control"
          [disabled]="!application.leadRefId ? false : !isReferralIdDirty"
          name="leadRefId"
          (ngModelChange)="onReferralIdChanged()"
          [(ngModel)]="application.leadRefId"
          #leadRefId="ngModel"
          [ngClass]="{
            'is-invalid': leadRefId && leadRefId.touched && leadRefId.invalid
          }"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
    <div class="d-block d-sm-none row form-group" *ngIf="!enabledChannels.length">
      <label class="form-label" for="leadRefId">Lead Ref Id</label>
      <div>
        <input
          class="form-control"
          [disabled]="!application.leadRefId ? false : !isReferralIdDirty"
          name="leadRefId"
          (ngModelChange)="onReferralIdChanged()"
          [(ngModel)]="application.leadRefId"
          #leadRefId="ngModel"
          [ngClass]="{
            'is-invalid': leadRefId && leadRefId.touched && leadRefId.invalid
          }"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
  </div>
</div>
