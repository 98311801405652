<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Legal Description
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #docPrepLegalDescForm="ngForm" id="docPrepLegalDescForm">
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="legalDescription"> Legal Description </label>
          <textarea id="legalDescription" name="legalDescription" class="form-control" rows="4"
            [(ngModel)]="docPrep.legalDescription">
          </textarea>
        </div>
      </div>
      <div class="row">
        <div class="d-inline-flex">
          <div class="form-check">
            <label for="isPrintLegalDesc" class="form-check-label"> Print Legal Description on Exhibit A </label>
            <input id="isPrintLegalDesc" name="isPrintLegalDesc" class="form-check-input"
              [(ngModel)]="docPrep.printLegalDescriptionOnExhibitA"
              (ngModelChange)="onPrintLegalDescriptionClicked($event)" type="checkbox" />
          </div>
        </div>
      </div>
    </form>
  </div>
</div>