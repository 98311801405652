import { Component, Injector, Input, OnInit } from '@angular/core';
import { SystemLevel } from 'src/app/models';
import { Configuration } from 'src/app/models/configuration.model';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DefinedSmsTemplate, TelephonyServiceModel } from '../models';

@Component({
  selector: 'sms-configuration',
  templateUrl: './sms-configuration.component.html'
})
export class SmsConfigurationComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  telephonyService: TelephonyServiceModel;

  @Input()
  smsAllowedSendTimeRange: Configuration;

  @Input()
  definedSmsTemplate: DefinedSmsTemplate;

  @Input()
  smsAlertsEnabled: boolean;

  @Input()
  type: "branch" | "system-level";

  reminderSmsEnabled: Configuration;

  reminderSmsInterval: Configuration;

  enableSmsAlertsByDefault: Configuration;

  currentTab: string = 'sms-settings';
  isLoading: boolean;

  constructor(private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService) {
    super(injector);
  }

  ngOnInit(): void {
    this.applicationContextService.context.subscribe((context) => {
      this.smsAlertsEnabled = context.userPermissions.smsAlertsEnabled;
    });
    if (this.type === 'branch') {
      return;
    }
    if (!this.type) {
      this.type = 'system-level';
    }
    this.getSystemLevel();
  }

  private getSystemLevel = () => {
    this.isLoading = true;
    this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
      const systemLevel = JSON.parse(JSON.stringify(data));
      this.telephonyService = systemLevel.telephonyService || {};
      this.definedSmsTemplate = systemLevel.definedSmsTemplate || {};
      this.reminderSmsEnabled = systemLevel.reminderSmsEnabled || {};
      this.reminderSmsInterval = systemLevel.reminderSmsInterval || {};
      this.smsAllowedSendTimeRange = systemLevel.smsAllowedSendTimeRange || {};
      this.enableSmsAlertsByDefault = systemLevel.enableSmsAlertsByDefault;
    }).add(() => this.isLoading = false);
  }
}