<div class="form-group mb-2">
  <label for="dateRange" class="form-label me-2" *ngIf="showLabel">Date Range</label>
  <div ngbDropdown class="d-inline-block">
    <button
      class="btn btn-sm btn-outline-primary d-flex justify-content-between align-items-center"
      id="dropdownBasic1"
      ngbDropdownToggle
      style="min-width: 150px"
    >
      <span class="w-100 text-center">
        {{
          selectedRange && selectedRange.name
            ? selectedRange.name
            : 'Select One'
        }}
      </span>
      <span><i class="fa fa-caret-down"></i></span>
    </button>

    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button
        ngbDropdownItem
        *ngFor="let range of dateRanges"
        (click)="onDateRangeChanged(range.value)"
      >
        <span>{{ range.name }}</span>
      </button>

      <div
        ngbDropdown
        class="d-inline-block dropdown"
        placement="right top"
        display="dynamic"
      >
        <button
          class="btn d-flex justify-content-between align-items-center"
          id="dropdownBasic2"
          ngbDropdownToggle
        >
          <span class="w-100 ms-2">All Dates in the Period</span>
          <span class="ms-2"><i class="fa fa-caret-right"></i></span>
        </button>

        <div
          class="dropdown-menu dropdown-placement"
          ngbDropdownMenu
          aria-labelledby="dropdownBasic2"
          style="overflow-y: auto; height: 300px; top: -128px"
        >
          <button
            ngbDropdownItem
            *ngFor="let period of datePeriods"
            (click)="onDateRangeChanged(period.value)"
          >
            <span>{{ period.name }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <div class="col mb-1">
    <div class="d-flex align-items-end">
      <label for="beginDate" class="form-label me-2" style="min-width: 40px">
        From
      </label>
      <input
        type="date"
        id="beginDate"
        name="beginDate"
        class="form-control"
        [disabled]="!selectedRange || selectedRange.id != 28"
        [ngModel]="startDate | date: 'yyyy-MM-dd'"
        (ngModelChange)="onCustomStartDateChanged($event)"
      />
    </div>
  </div>

  <div class="col mb-1">
    <div class="d-flex align-items-end" [ngClass]="{ 'is-invalid': endDateInput && endDateInput.touched && (endDate < startDate)}">
      <label for="endDate" class="form-label me-2" style="min-width: 40px">To</label>
      <input
        type="date"
        id="endDate"
        name="endDate"
        class="form-control"
        [min]="startDate"
        #endDateInput="ngModel"
        [disabled]="!selectedRange || selectedRange.id != 28"
        [ngModel]="endDate | date: 'yyyy-MM-dd'"
        (ngModelChange)="onCustomEndDateChanged($event)"
        [ngClass]="{ 'is-invalid': endDateInput && endDateInput.touched && (endDate < startDate)}"
      />
    </div>
    <div class="invalid-feedback" *ngIf="startDate">To Date cannot be smaller than {{startDate?.toLocaleDateString()}}</div>
  </div>

</div>
