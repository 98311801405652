import { CalculatedFeeTypeEnum } from "./calculated-fee-type.enum";
import { FeePaidToEnum } from "./fee-paid-to.enum";
import { FeePercentOfFieldEnum } from "./fee-percent-of-field.enum";
import { FeeSectionEnum } from "./fee-section.enum";
import { FeeTypeEnum } from "./fee-type.enum";

export class FeeSystemDetails {
  feeTypes: FeeTypeDetails[] = [];
  feeSections: FeeSectionDetails[] = [];
}

export interface FeeTypeDetails {
  name: string;
  description: string;
  feeType: FeeTypeEnum;
  hudNumber: string;
  sumInHudNumber: number;
  isAprFee: boolean;
  canSetPaidTo: boolean;
  canSellerConcessionsApply: boolean;
  feeSections: FeeSectionEnum;
  calculatedFeeType: CalculatedFeeTypeEnum;
  feePercentOf: FeePercentOfFieldEnum;
  paidToType: FeePaidToEnum
}

export interface FeeSectionDetails {
  minHudNumber: number;
  maxHudNumber: number;
  feeSection: FeeSectionEnum;
  name: string;
  order: number;
  availableFeeTypes: FeeTypeEnum[];
  availableCalculatedFeeTypes: CalculatedFeeTypeEnum[];
}
