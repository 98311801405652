import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tasks-reassignment-confimation-dialog',
    templateUrl: 'tasks-reassignment-confimation-dialog.component.html'
})

export class TasksReassignmentDialogComponent implements OnInit {

    @Input()
    title: string;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() { }

    onConfirmReassignClicked = (response: string) => {
        this.activeModal.close(response);
    }

    onCancelClicked = () => {
        this.activeModal.close('cancel');
    }
}