import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { SubStatus, Role } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import {
  ActionButtonOption,
  GridActionButtonType,
} from 'src/app/shared/models/action-button-options.model';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import { DeleteWithReplacementConfirmationDialogComponent } from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import { SubStatusService } from '../../services';

@Component({
  templateUrl: 'sub-status.component.html'
})
export class SubStatusComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  @ViewChild(Table)
  subStatusTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('subStatusOrderDrawer')
  subStatusOrderDrawer: DrawerComponent;

  @ViewChild('subStatusOrder')
  subStatusOrder: RearrangeOrderComponent

  actionButtonOptions: Array<ActionButtonOption>;
  subStatusColumns = [];
  subStatuses: Array<SubStatus> = [];
  roles: Array<Role> = [];
  itemsToOrder: Array<{ name: string, value: number }> = [];

  loadingSubStatuses: boolean;
  allowAddNew: boolean;
  currentSubStatus: Partial<SubStatus>;
  companyId: number;

  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  upsertSubStatusDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  constructor(
    private readonly injector: Injector,
    private readonly _notificationService: NotificationService,
    private readonly _subStatusService: SubStatusService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.companyId = this.applicationContext.userPermissions.companyId;
    this.allowAddNew = this.applicationContext.userPermissions.superAdmin ? false : true;
    this.subStatusColumns = [
      { field: 'subStatusName', header: 'Sub Status', sortable: true },
      { field: 'selectByRole', header: 'Select By Role', sortable: true },
      { field: 'isSuspenseSubStatus', header: 'Is Suspend Sub Status', sortable: true },
      { field: 'hideFromCounter', header: 'Hide From Counter', sortable: true },
      { field: 'order', header: 'Sort', sortable: true },
    ];
    this.applicationContextService.context.subscribe((res) => {
      this.roles = res.globalConfig.roles;
      this.setActionButtonOptions();
      this.populateSubStatues();
    });
  }

  getRoleNameCsv(roleIds: string): string {
    if (!roleIds) {
      return '';
    }
    const roleIdList = roleIds.split(',');
    return this.roles.filter(role => roleIdList.includes(role.roleId.toString()))
      .map(role => role.roleName)
      .join(',');
  }

  showUpsertDialog(row: SubStatus): void {
    this.currentSubStatus = cloneDeep(row) || {};
    this._drawerService.show("upsertSubStatusDrawer", 100);
  }

  onUpsertLoanStatusDrawerClose(updatedSubStatus: SubStatus) {
    if (!updatedSubStatus) {
      this._drawerService.hide("upsertSubStatusDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertSubStatusDrawer", 100);
    this.updateTableAfterUpsert(updatedSubStatus);
  }

  showDeleteDialog(rowData: SubStatus): void {
    const modalRef = this._modalService.open(
      DeleteWithReplacementConfirmationDialogComponent
    );
    modalRef.componentInstance.ItemsList = this.subStatuses
      .filter((ls) => rowData.subStatusId !== ls.subStatusId)
      .map((ls) => ({
        value: ls.subStatusId,
        name: ls.subStatusName,
      }));
    modalRef.componentInstance.itemType = 'sub status';
    modalRef.result.then((assignedSubStatusId: string) => {
      this.deleteRow(rowData, parseInt(assignedSubStatusId));
    },
      () => { }
    );
  }

  openOrderDrawer(): void {
    if (!this.subStatuses.length) {
      return;
    }

    this.itemsToOrder = this.setSubStatusItems();
    this._drawerService.show("subStatusOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedSubStatuses: Array<EnumerationItem>) {
    this.subStatusOrder.saveInProgress = true;

    const sortedSubStatusIds = sortedSubStatuses.map((r) => ({ id: r.value }));

    this._subStatusService.updateSubStatusOrder(sortedSubStatusIds, this.companyId)
      .pipe(finalize(() => {
        this.subStatusOrder.saveInProgress = false;
        this.closeOrderDrawer();
      }))
      .subscribe({
        next: () => {
          this.subStatusTable.sortField = "";
          this.subStatusTable.sortOrder = 0;
          this.subStatusTable.reset();
          this.gridHeaderActionBar.search.nativeElement.value = '';
          this.populateSubStatues();
          this._notificationService.showSuccess(
            'Sub status order updated successfully',
            'Sub Status'
          );
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to update sub status order ',
            'Sub Status'
          );
        }
      });
  }
  closeOrderDrawer() {
    this._drawerService.hide("subStatusOrderDrawer", 100);
  }

  private setActionButtonOptions(): void {
    this.actionButtonOptions = [
      {
        type: GridActionButtonType.SearchInput,
        searchInputPlaceholder: 'Search...',
        onSearch: (value: string) => this.subStatusTable.filterGlobal(value, 'contains'),
      },
    ];
  }

  private populateSubStatues() {
    this._spinner.show();
    this._subStatusService
      .getAllSubStatuses(this.companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this.subStatuses = res;
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error ? error.message : "Couldn't load sub statuses.",
            'Sub Status'
          );
        }
      });
  }

  private updateTableAfterUpsert(result: SubStatus) {
    const index = this.subStatuses.findIndex(
      (ls) => ls.subStatusId === result.subStatusId
    );
    if (index === -1) {
      this.subStatuses.push(result);
    } else {
      this.subStatuses[index] = result;
    }
    this.subStatuses = [...this.subStatuses];
  }

  private deleteRow(rowData: SubStatus, assignedSubStatusId: number) {
    this._subStatusService.deleteSubStatus(rowData.subStatusId, this.companyId, assignedSubStatusId)
      .subscribe({
        next: (res) => {
          const index = this.subStatuses.findIndex(ls => ls.subStatusId === rowData.subStatusId);
          this.subStatuses.splice(index, 1);
          this.subStatuses = [...this.subStatuses];

          this._notificationService.showSuccess(
            'Sub status removed succesfully.',
            'Sub Status'
          );
        },
        error: (err) =>
          this._notificationService.showError(
            err?.message || "Couldn't delete sub status",
            'Sub Status'
          )
      });
  }

  private setSubStatusItems() {
    return chain(this.subStatuses)
      .orderBy((ls) => ls.order)
      .map((ls) => ({
        name: ls.subStatusName,
        value: ls.subStatusId,
      }))
      .value();
  }
}
