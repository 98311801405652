import {Component, Input, OnInit} from '@angular/core';
import {LeadPostingUrl} from '../../../../../models';

@Component({
  selector: 'lead-posting',
  templateUrl: './lead-posting.component.html'
})
export class LeadPostingComponent implements OnInit {
  @Input() leadPostingUrls: LeadPostingUrl[];

  constructor() {}

  ngOnInit(): void {}
}
