import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import {
  AddressLookup,
  AddressLookupOptions,
  AddressLookupResult,
} from '../../../../utils/address-lookup-parser';
import { merge } from 'lodash';
import { AddressLookupService } from '../../../services/address-lookup.service';
import { autoId } from '../../../core/services/utils';

const defaultAddressLookupOptions: Options = {
  types: ['geocode'],
  componentRestrictions: { country: 'us' },
  bounds: null,
  strictBounds: false,
  fields: null,
  origin: null,
};

const defaultInputPropKey: keyof AddressLookupResult = 'address1';

@Component({
  selector: 'address-autocomplete-input',
  templateUrl: './address-autocomplete-input.component.html',
  styleUrls: ['./address-autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressAutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AddressAutocompleteInputComponent implements ControlValueAccessor, OnChanges, OnInit {

  @Input() addressLookupOptions?: Partial<Options>;

  protected effectiveAddressLookupOptions: Options;

  private _value: string;

  protected get value(): string {
    return this._value;
  }

  protected set value(value: string) {
    this._value = value;
    this.onChange(value);
  }

  protected disabled?: boolean;

  /**
   * Whether the autocomplete functionality is disabled. Disabling the autocomplete functionality
   * will not disable the input.
   */
  @Input() autocompleteDisabled?: boolean;

  @Input('id') inputId?: string;
  @HostBinding() id: string = autoId();

  // TODO: Replace with style variants (e.g., 'classic', 'plain', etc.)
  //       This attribute exposes internal styling
  @Input() inputClass: string = 'form-control';
  @Input() readonly?: boolean;
  @Input() required?: boolean;
  /**
   * Whether the input is disabled. Disabling the input will also disable the autocomplete
   * functionality, but not vice versa.
   */
  @Input() placeholder: string = 'Enter a location';

  @Output() addressChange = new EventEmitter<Partial<AddressLookupResult>>();

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef<HTMLInputElement>;
  @ViewChild('plainInput') plainInput: ElementRef<HTMLInputElement>;

  private get _activeInput(): HTMLInputElement {
    return this.autocompleteDisabled
      ? this.plainInput.nativeElement
      : this.autocompleteInput.nativeElement;
  }

  private inputPropKey: keyof AddressLookupResult = defaultInputPropKey;

  private get lookupOptions(): AddressLookupOptions {
    const options: Partial<Options> = this.effectiveAddressLookupOptions ?? {};

    return {
      fallbackCountry: options.componentRestrictions?.country,
    };
  }

  constructor(
    private readonly _addressLookupService: AddressLookupService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const addressLookupOptionsChange = changes.addressLookupOptions;
    if (addressLookupOptionsChange != null) {
      this.resetEffectiveAddressLookupOptions(addressLookupOptionsChange.currentValue);
    }
  }

  ngOnInit() {
    if (this.addressLookupOptions == null) {
      this.resetEffectiveAddressLookupOptions();
    }
  }

  private resetEffectiveAddressLookupOptions(options?: Partial<Options>) {
    if (options == null) {
      this.effectiveAddressLookupOptions = defaultAddressLookupOptions;
      return;
    }

    this.effectiveAddressLookupOptions = merge(
      {},
      defaultAddressLookupOptions,
      options,
    );

    this.invalidateInputPropKey();
  }

  private invalidateInputPropKey(options?: Options) {
    // The target property is designated as a "company name", not an address line.
    // FIXME: Check for possible conflicts as `types` is an array.
    options ??= this.effectiveAddressLookupOptions;
    this.inputPropKey = options.types.includes('establishment')
      ? 'name'
      : defaultInputPropKey;
  }

  protected handleAddressChange(lookup: AddressLookup) {
    const address = this._addressLookupService.parseAddressLookup(
      lookup,
      this.lookupOptions,
    );

    const inputPropValue = address[this.inputPropKey];
    if (inputPropValue != null) {
      this._value = inputPropValue;
      this.onChange(inputPropValue);
    }

    this.addressChange.emit(address);
  }

  focus(options?: FocusOptions): void {
    this._activeInput.focus(options);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (_: string) => {
  }

  onTouched = () => {
  };

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
