import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/core/services/document.service';
import { DisclosureDocumentSet, DisclosureDocument } from 'src/app/models/third-party/disclosure';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'disclosure-doc-set-accordion',
  templateUrl: 'disclosure-doc-set-accordion.component.html',
  styleUrls: ['disclosure-doc-set-accordion.component.scss'],
})
export class DisclosureDocSetAccordionComponent implements OnInit {

  @Input()
  docSet: DisclosureDocumentSet;

  @Input()
  accordionOpened: boolean = false;

  columns: Array<any> = [];

  constructor(private readonly _documentService: DocumentService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    this.columns = [
      { field: 'description', header: 'Description' },
      { field: 'documentData', header: 'View Document' },
    ];
  }

  viewDocument = (doc: DisclosureDocument) => {
    const decodedData = window.atob(doc.documentData);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: doc.mimeType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
