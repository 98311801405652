import { Component, Input, OnInit } from '@angular/core';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { DocPrep } from '../../models/doc-prep.model';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { Address } from '../../../../../models';

@Component({
  selector: 'document-preparation-title-company',
  templateUrl: 'document-preparation-title-company.component.html',
  styleUrls: ['./document-preparation-title-company.component.scss']
})

export class DocumentPreparationTitleCompanyComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  protected states: EnumerationItem[] = [];

  constructor(private readonly _enumerationService: EnumerationService) { }

  ngOnInit() {
    this.states = this._enumerationService.states;
    if (!this.docPrep.titleCompany) {
      this.docPrep.titleCompany = new ExternalContact();
    }
  }

  protected handleAddressChange(e: Partial<Address>, addressType: 'Company' | 'Mailing'): void {
    let patchAddress: () => void;
    const titleCompany = this.docPrep.titleCompany;
    if (addressType === 'Mailing') {
      titleCompany.mailingStreet = ''; // to reset the last populated address.

      patchAddress = () => {
        titleCompany.mailingStreet = e.address1;
        titleCompany.mailingCity = e.city;
        titleCompany.mailingState = e.state;
        titleCompany.mailingZip = e.zipCode;
      }
    } else {
      titleCompany.companyAddress1 = ''; // to reset the last populated address.

      patchAddress = () => {
        titleCompany.companyAddress1 = e.address1;
        titleCompany.companyCity = e.city;
        titleCompany.companyState = e.state;
        titleCompany.companyZip = e.zipCode;
      }
    }

    setTimeout(patchAddress, 200);
  }

  protected onCopyAddressClicked = () => {
    this.docPrep.titleCompany.mailingStreet = this.docPrep.titleCompany.companyAddress1;
    this.docPrep.titleCompany.mailingCity = this.docPrep.titleCompany.companyCity;
    this.docPrep.titleCompany.mailingState = this.docPrep.titleCompany.companyState;
    this.docPrep.titleCompany.mailingZip = this.docPrep.titleCompany.companyZip;
    this.docPrep.titleCompany.mobilePhone = this.docPrep.titleCompany.companyPhone;
  }

  protected emailFormatValidate = (email): boolean => {
    return !email ||
      (
        email
        &&
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, addressType: string) => {
    if (zipCode) {
      if (addressType === 'Mailing') {
        this.docPrep.titleCompany.mailingState = zipCode.state.toLowerCase();
        this.docPrep.titleCompany.mailingCity = Utils.toTitleCase(zipCode.city);
        this.docPrep.titleCompany.mailingZip = zipCode.zipcode;
        this.docPrep.titleCompany.mailingCountry = 'us';
      } else {
        this.docPrep.titleCompany.companyState = zipCode.state.toLowerCase();
        this.docPrep.titleCompany.companyCity = Utils.toTitleCase(zipCode.city);
        this.docPrep.titleCompany.companyZip = zipCode.zipcode;
        this.docPrep.titleCompany.companyCountry = 'us';
      }
    }
  }
}
