import { Component, Input, OnInit } from '@angular/core';
import { MessageHistoryItem } from 'src/app/models/message.model';
import { CorrespondenceService } from 'src/app/modules/correspondence/services/correspondence.service';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'lead-email-history',
  templateUrl: './lead-email-history.component.html',
  styleUrls: ['./lead-email-history.component.scss']
})
export class LeadEmailHistoryComponent implements OnInit {

  @Input() lead: Lead;

  @Input()
  public set isReloadRequired(value: boolean) {
    if (value) {
      this.ngOnInit();
    }
  }

  leadEmailHistory: MessageHistoryItem[];

  isLoadingHistory: boolean = false;

  constructor(private readonly _notifsService: NotificationService,
    private _correspondenceService: CorrespondenceService) { }

  ngOnInit(): void {
    this.getLeadEmailHistory();
  }

  private getLeadEmailHistory = () =>{
    this.isLoadingHistory = true;
    this._correspondenceService.getMessagesSentForLead(this.lead.leadId)
    .subscribe(history => {
      this.leadEmailHistory = history;
    }, error => {
      let errorMessage = "An error occurred while retrieving Email/SMS history.";
      if (error && error.message) {
        errorMessage = error.message;
      }
      this._notifsService.showError(errorMessage, "Error!");
      this.leadEmailHistory = [];
    }).add(() => {
      this.isLoadingHistory = false;
    })
  }
}
