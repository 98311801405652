<div class="card p-3">
    <div class="card-header">
        <div class="card-title">
            Conditions:
            <a *ngIf="!isReadonly" class="add-condition-btn" (click)="openConditionsDialog()">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Add Condition
            </a>
        </div>
    </div>
    <div class="card-body">
        <ng-template [ngIf]="!conditionsLoading" [ngIfElse]="loading">
            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="table-light">
                        <tr>
                            <th class="col-md-1">Status</th>
                            <th class="col-md-3">Category</th>
                            <th class="col-md-3">Text</th>
                            <th class="col-md-2">Requested On</th>
                            <th class="col-md-2">Approved On</th>
                            <th class="col-md-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let condition of conditions">
                            <td>{{condition.status}}</td>
                            <td>{{condition.conditionCategoryName}}</td>
                            <td>{{condition.conditionText}}</td>
                            <td>{{condition.requestedOn}}</td>
                            <td>{{condition.approvedOn}}</td>
                            <td *ngIf="!isReadonly">
                                <a *ngIf="(condition.thirdPartyConditionId < 0 || 
                                    (condition.status === 'Pending' || condition.status === 'Requested')) &&
                                    actionInProgressForId != condition.thirdPartyConditionId" class="dropdown-toggle"
                                    data-bs-toggle="dropdown">
                                    <i class="menu fa fa-ellipsis-v fa-lg" aria-hidden="true"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" (click)="approveCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId > 0 && 
                                            (condition.status === 'Pending' || condition.status === 'Requested')">
                                        Approve
                                    </a>
                                    <a class="dropdown-item" (click)="denyCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId > 0 && 
                                            (condition.status === 'Pending' || condition.status === 'Requested')">
                                        Deny
                                    </a>
                                    <a class="dropdown-item" (click)="cancelCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId > 0 && 
                                            (condition.status === 'Pending' || condition.status === 'Requested')">
                                        Cancel
                                    </a>
                                    <a class="dropdown-item" (click)="removeCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId < 0">
                                        Remove
                                    </a>
                                </div>
                                <div class="dropdown" *ngIf="actionInProgressForId === condition.thirdPartyConditionId">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="conditions.length === 0">
                            <td colspan="6" class="empty-condition">
                                <em>No condition Found. Add new condition by clicking "Add Condition" button.</em>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!isReadonly">
                    <button class="btn btn-primary" type="button" (click)="saveAppraisalCondition()"
                        [disabled]="savingAppraisalCondition || !hasNewConditions">
                        <span *ngIf="!savingAppraisalCondition">Save</span>
                        <span *ngIf="savingAppraisalCondition" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true">
                        </span>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template #loading>
            <loading-indicator></loading-indicator>
        </ng-template>
    </div>
</div>