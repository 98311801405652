import { Component, Input, OnInit } from '@angular/core';
import { InternalContactHistoryItemViewModel } from '../../models/internal-contact-history-item.view.model';
import * as _ from 'lodash';

@Component({
  selector: 'internal-contacts-history',
  templateUrl: 'internal-contacts-history.component.html',
  styleUrls: ['internal-contacts-history.component.scss'],
})
export class InternalContactsHistoryComponent implements OnInit {

  @Input()
  set internalContactsHistory(
    internalContactsHistory: InternalContactHistoryItemViewModel[]
  ) {
    this.histories = internalContactsHistory;
    this.histories.forEach(h => {
      h["actionDate"] = new Date(h.dateInserted);
    });
    this.histories = _.orderBy(this.histories,
      ['actionDate'],
      ['desc']);
  }

  get internalContactsHistory(): InternalContactHistoryItemViewModel[] {
    return this.histories;
  }

  histories: InternalContactHistoryItemViewModel[];

  dateFormat = 'MMM, dd YYYY h:mm a';

  constructor() {}

  ngOnInit() {}
}
