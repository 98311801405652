import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentType } from 'src/app/modules/admin/company/models/agent-type.model';
import { AgentsService } from 'src/app/modules/agents/services/agents.service';
import { AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-select-agent-type-dialog',
  templateUrl: './select-agent-type-dialog.component.html',
  styleUrls: ['./select-agent-type-dialog.component.scss']
})
export class SelectAgentTypeDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  agentId: number;

  @Input()
  selectingForConversion: boolean;

  @Input()
  showRedirectToLoanButton: boolean;

  associateAgentAs: number = null;

  agentTypes: AgentType[];

  agentFull: AgentFull;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _agentsService: AgentsService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly injector: Injector
  ) {
    super(injector);

    this.agentTypes = this.applicationContext.globalConfig.agentType || [];

    if (this.agentTypes.length === 1) {
      this.associateAgentAs = this.agentTypes[0].agentTypeId;
    }
  }

  ngOnInit(): void {
    this._spinner.show();
    this._agentsService.getAgent(this.agentId).subscribe((response) => {
      this.agentFull = response;
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : '', 'Error');
    }).add(() => {
      this._spinner.hide();
    });
  }

  submit = (redirectToLoan: boolean) => {
    this.activeModal.close({
      associateAgentAs: this.associateAgentAs,
      redirectToLoan: redirectToLoan
    });
  }
}
