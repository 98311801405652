import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, fromEvent, Observable, Subscription } from 'rxjs';
import { ITimer } from '../../../../utils/timer';
import { debounceTime, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

// We consider the recent tab dead if it does not update the storage value for
// this duration.
const _recentTabTimeoutDurationMs = 1500;

@Component({
  selector: 'session-timeout-sync',
  template: '',
})
export class SessionTimeoutSyncComponent implements OnInit, OnDestroy {
  private _timer: ITimer | undefined;
  @Input() set timer(value: ITimer | undefined) {
    this._timer = value;
    this.resetChannelElapsed();

    this._play$Subscription?.unsubscribe();
    this._writeToChannelSubscription?.unsubscribe();
    if (value != null) {
      this._play$Subscription = this.subscribeToPlay$();
      this._writeToChannelSubscription = this.subscribeToWriteToChannel();
    }
  }

  private _channel: ISessionTimeoutChannel;
  private _writeToChannelSubscription: Subscription | undefined;
  private _readFromChannelSubscription: Subscription;
  private _recentTabTimeoutSubscription: Subscription;
  private _visibilitySubscription: Subscription;

  private _play$Subscription: Subscription | undefined;

  private get _isDocumentVisible(): boolean {
    return document.visibilityState === 'visible';
  }

  ngOnInit(): void {
    this.initChannel();
    this._readFromChannelSubscription = this.subscribeToReadFromChannel();
    this._recentTabTimeoutSubscription = this.subscribeToRecentTabTimeout();
    this._visibilitySubscription = this.subscribeToVisibility();

    if (this._isDocumentVisible) {
      this._channel.setAsRecentTab();
    }
  }

  ngOnDestroy(): void {
    this._play$Subscription?.unsubscribe();
    this._writeToChannelSubscription?.unsubscribe();
    this._readFromChannelSubscription.unsubscribe();
    this._recentTabTimeoutSubscription.unsubscribe();
    this._visibilitySubscription.unsubscribe();
  }

  private resetChannelElapsed(): void {
    const timer = this._timer;
    const channel = this._channel;
    if (timer == null || channel == null) {
      return;
    }

    if (channel.isRecentTab) {
      channel.elapsed = timer.elapsed ?? 0;
    }
  }

  private initChannel(): void {
    this._channel = new SessionTimeoutStorage();
    this.resetChannelElapsed();
  }

  private subscribeToPlay$(): Subscription {
    return this._timer.play$.pipe(
      filter(() => this._channel.isRecentTab),
    ).subscribe((from) => {
      this._channel.elapsed = from;
    });
  }

  private subscribeToWriteToChannel(): Subscription {
    return this._timer.tick$.pipe(
      filter(() => this._channel.isRecentTab),
    ).subscribe((elapsed) => {
      this._channel.elapsed = elapsed;
    });
  }

  private subscribeToReadFromChannel(): Subscription {
    return this._channel.pipe(
      filter(({ elapsed }) => this._timer.elapsed !== elapsed),
    ).subscribe(({ elapsed }) => {
      // The timer is synchronized to the value of the other tabs.
      this._timer.play(elapsed);
    });
  }

  private subscribeToRecentTabTimeout(): Subscription {
    return this._channel.pipe(
      debounceTime(_recentTabTimeoutDurationMs),
      filter(() => !this._channel.isRecentTab),
    ).subscribe(() => {
      // Setting any of the tabs to recent is sufficient. The tab that last
      // changed this value is considered recent.
      const channel = this._channel;
      this._channel.setAsRecentTab();

      // If this tab was visible, it would already be the recent tab before.
      // Resume the timer where it left off as this tab is not visible at this
      // time.
      this._timer.play(channel.elapsed);
    });
  }

  private subscribeToVisibility(): Subscription {
    return fromEvent(document, 'visibilitychange')
      .pipe(
        filter(() => this._isDocumentVisible),
      ).subscribe(() => {
        this._channel.setAsRecentTab();
      });
  }
}

interface SessionTimeoutState {
  readonly elapsed: number;
  readonly recentTabId: string;
}

interface ISessionTimeoutChannel extends Observable<SessionTimeoutState> {
  elapsed: number;
  readonly isRecentTab: boolean;

  setAsRecentTab(): void;
}

class SessionTimeoutStorage extends Observable<SessionTimeoutState> implements ISessionTimeoutChannel {
  public static readonly storageKey: string = 'sessionTimeoutState';

  public static readonly tabId: string = uuidv4();

  constructor() {
    super((subscriber) => {
      const message$ = fromEvent<StorageEvent>(
        window,
        'storage',
      ).pipe(
        filter(({ key }) => key === SessionTimeoutStorage.storageKey),
        map(() => this.getState()),
      );
      const subscription = message$.subscribe(subscriber);

      return () => {
        subscription.unsubscribe();
      };
    });
  }

  private getState(): SessionTimeoutState {
    const readState = (): SessionTimeoutState => {
      const value = localStorage.getItem(SessionTimeoutStorage.storageKey);
      const parsed = value != null ? JSON.parse(value) : {};

      parsed.elapsed ??= 0;
      parsed.recentTabId ??= '';

      return parsed as SessionTimeoutState;
    };
    const state = readState();

    // validate [elapsed]
    (() => {
      const value = state.elapsed;

      if (!Number.isSafeInteger(value) || value < 0) {
        throw new Error(
          `[elapsed] must be zero or a positive integer, actual: ${value}`,
        );
      }
    })();

    return state;
  }

  private setState(value: Partial<SessionTimeoutState>): void {
    const result = Object.assign(this.getState(), value);
    const json = JSON.stringify(result);
    localStorage.setItem(SessionTimeoutStorage.storageKey, json);
  }

  get elapsed(): number {
    return this.getState().elapsed;
  }

  set elapsed(value: number) {
    this.setState({ elapsed: value });
  }

  setAsRecentTab(): void {
    this.setState({ recentTabId: SessionTimeoutStorage.tabId });
  }

  get isRecentTab(): boolean {
    return this.getState().recentTabId === SessionTimeoutStorage.tabId;
  }
}
