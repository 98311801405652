<div id="mySidenav" class="sidenav">
  <ul metis-menu class="left-sidenav-menu mm-show" id="metismenu">
    <li class="dropdown notification-list">
      <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-bs-toggle="dropdown" role="button"
        aria-haspopup="false" aria-expanded="false">
        <span class="text-ellipsis" style="padding-right: 13px;">{{currentlyLoggedInCompany.companyName}}</span><span
          class="menu-arrow">
          <i class="mdi mdi-apps"></i>
        </span>
      </a>
      <div class="dropdown-menu loda-dropdown-menu dropdown-menu-end dropdown-lg pt-0 left-sidenav-dropdown-menu">
        <a (click)="onCompanySelectedToSwitchTo(currentlyLoggedInCompany.companyGuid, currentlyLoggedInCompany.companyName)"
          class="dropdown-item-text font-14 m-0 loda-dropdown-padding border-bottom d-flex align-items-center">
          <img [src]="lodaLogoImagePath" />
          <strong>{{currentlyLoggedInCompany.companyName}}</strong>
          <i class="ms-auto dripicons-checkmark"></i>
        </a>
        <ng-container *ngFor="let companies of otherCompaniesThatCanBeSwitchedTo | groupBy: 'companyGuid'">
          <a (click)="onCompanySelectedToSwitchTo(companies.value[0].companyGuid, companies.value[0].companyName)"
            class="dropdown-item-text font-14 m-0 loda-dropdown-padding border-bottom d-flex align-items-center">
            <img [src]="lodaLogoImagePath" />
            <strong>{{companies.value[0].companyName}}</strong>
            <i *ngIf="currentCompanyGuid == companies.value[0].companyGuid" class="ms-auto dripicons-checkmark"></i>
          </a>
        </ng-container>
      </div>
    </li>

    <li *ngFor="let item of menu; let i = index;"  [hidden]="(item.requireAdminPermission && !userHasAdminPermission) || (item.requireSuperAdminPermission && !userHasSuperAdminPermission)"
      [ngClass]="{'dropdown dropend notification-list' : item.children && item.children.length}">
      <a [ngClass]="{'nav-link dropdown-toggle arrow-none waves-light waves-effect' : item.children && item.children.length}"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span>{{ item.text }}</span><span class="menu-arrow">
          <i [ngClass]="item.icon"></i>
        </span>
      </a>
      <ul *ngIf="item.children" class="dropdown-menu loda-dropdown-menu dropdown-menu-end dropend-menu">
        <li *ngFor="let ch of item.children; let ii = index;" class="dropdown dropend" [hidden]="ch.hide || (ch.requireAdminPermission && !userHasAdminPermission) || (ch.requireCallReportPermission && !allowMortgageCallReports) || (ch.requireEnabledPricingPermission && !pricingEnabled) || (ch.requireSuperAdminPermission && !userHasSuperAdminPermission)">
          <a [ngClass]="{'active': ch.selected }" [routerLink]="getRouterLink(ch)">
            <span>{{ ch.text }}</span><span class="menu-arrow">
              <i *ngIf="ch.icon" [ngClass]="ch.icon"></i>
            </span>
          </a>
          <ul *ngIf="ch.children" class="dropdown-menu loda-dropdown-menu dropdown-menu-end dropend-menu">
            <li *ngFor="let sub of ch.children; let ii = index;" class="dropdown dropend"  [hidden]="(sub.requireAdminPermission && !userHasAdminPermission) || (sub.requireEnabledBranchesPermission && !branchesEnabled) || (sub.requireEnabledPricingPermission && !pricingEnabled) || (sub.requireExternalCompanyEnabled && !externalCompanyEnabled)">
              <a [ngClass]="{'active': sub.selected }" [routerLink]="getRouterLink(sub)">
                <span>{{ sub.text }}</span><span class="menu-arrow">
                  <i *ngIf="sub.icon" [ngClass]="sub.icon"></i>
                </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
