<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>
<ng-template #pageActionBarRight>
  <button type="button" class="btn btn-primary" [disabled]="superAdmin" (click)="openQuestionDrawer()">
    <i class="fas fa-plus"></i> Add New
  </button>
</ng-template>
<ng-template #mainContent>
  <p-table #dt1 [columns]="selectedColumns" [value]="questions" [paginator]="true" [rows]="25" [resizableColumns]="true"
    responsiveLayout="scroll" name="question-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="globalFilterFields" [autoLayout]="true" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" styleClass="p-datatable-gridlines"
    sortField="questionText" sortMode="single" scrollable="true" [scrollHeight]="scrollHeight">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">
        <span>

        </span>

        <span class="ms-auto">
          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl
            defaultLabel="Choose Columns"></p-multiSelect>

          <span class="p-input-icon-right ms-2 h-100">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0" placeholder="Search keyword" />
          </span>
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 5rem">
          <span>Action</span>
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center w-100">
            <span>
              <i class="fa fa-pencil-alt cursor text-primary" (click)="openQuestionDrawer(rowData)" title="Edit"></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i *ngIf="!isDeleteClicked[rowData.questionId]" class="fa fa-trash-alt cursor text-danger"
                (click)="isDeleteClicked[rowData.questionId] = true" title="Delete"></i>

              <i *ngIf="isDeleteClicked[rowData.questionId]" class="fa fa-times cursor text-danger me-2"
                (click)="isDeleteClicked[rowData.questionId] = false" title="Cancel"></i>

              <i *ngIf="isDeleteClicked[rowData.questionId]" class="fa fa-check-circle cursor text-danger"
                (click)="onDeleteQuestionClicked(rowData.questionId)" title="Delete"></i>
            </span>
          </span>
        </td>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <span *ngSwitchCase="'questionType'">
            {{ getSplittedEnumValue(rowData['questionType']) }}
          </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight" [pageMainContentTemplate]="mainContent">
</admin-page-template>

<drawer #upsertChecklistQuestionDrawer title="{{ currentQuestion?.questionId ? 'Edit' : 'New' }} Question"
  [name]="'upsertChecklistQuestionDrawer'" [templateRef]="upsertChecklistQuestionRef"
  [options]="upsertChecklistQuestionDrawerOptions">
</drawer>
<ng-template #upsertChecklistQuestionRef>
  <upsert-checklist-question #upsertChecklistQuestion [globalConfig]="globalConfig" [companyId]="companyId"
    [question]="currentQuestion" (close)="onUpsertQuestionDrawerClose($event)">
  </upsert-checklist-question>
</ng-template>