<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button *ngIf="allowAddNew" class="btn btn-primary" (click)="showUpsertDialog()">
    <i class="fa fa-plus"></i>
    Add New
  </button>
</ng-template>

<ng-template #mainContent>
  <form #agentTypeForm="ngForm">
    <p-table #cocReasonTable [columns]="cocReasonColumns" columnResizeMode="fit" [value]="cocReasons" [paginator]="true"
      [rows]="25" [resizableColumns]="true" responsiveLayout="scroll" name="coc-reasons-list-table-v2"
      [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true" [showCurrentPageReport]="true" scrollable="true"
      [scrollHeight]="scrollHeight" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single"
      [globalFilterFields]="['adverseReasonName']">
      <ng-template pTemplate="caption">
        <grid-top-action-bar #gridHeaderActionBar [options]="actionButtonOptions"></grid-top-action-bar>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="justify-content-left">
            <span>Action</span>
          </th>
          <th [class]="col.cssClass" *ngFor="let col of cocReasonColumns" [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="!col.sortable">
            {{ col.header }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td class="width-100p">
            <span class="d-flex align-items-center justify-content-center">
              <i class="fa fa-pencil-alt cursor text-primary" (click)="showUpsertDialog(row)" title="Edit"></i>
              <span class="mx-2"> | </span>
              <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(row)" title="Delete"></i>
            </span>
          </td>
          <td *ngFor="let col of cocReasonColumns" [ngSwitch]="col.field">
            <span *ngSwitchCase="'disclosureReason'">
              <span *ngIf="row.disclosureReason == 'Other'">
                {{row.disclosureReasonOtherDescription}}
              </span>
              <span *ngIf="row.disclosureReason != 'Other'">
                {{row["disclosureReasonDisplayName"]}}
              </span>
            </span>
            <span *ngSwitchDefault>
              {{ row[col.field] }}
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="cocReasonColumns.length + 1" class="text-center"> There are no CoC reasons. </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #upsertCocReasonDrawer
  [title]="currentCoCReason?.changeOfCircumstanceReasonId ? 'Edit CoC Reason' : 'Add CoC Reason'"
  [name]="'upsertCoCReasonDrawer'" [templateRef]="upsertCoCReasonRef" [options]="upsertCoCReasonDrawerOptions">
</drawer>
<ng-template #upsertCoCReasonRef>
  <upsert-coc-reason [cocReason]="currentCoCReason" [disclosureReasons]="disclosureReasons"
    (close)="onUpsertCoCReasonDrawerClose($event)"></upsert-coc-reason>
</ng-template>
