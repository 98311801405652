<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>
<ng-template #pageActionBarRight>
  <button
              type="button"
              class="btn btn-primary me-2"
              (click)="addNewTemplate()"
            >
              <i class="fas fa-plus"></i> Add New
            </button>
</ng-template>
<ng-template #mainContent>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Fee Templates</h4>
    </div>
    <div class="card-body">
      <p-table
        #dt1
        [columns]="columns"
        [value]="feeTemplates"
        [paginator]="true"
        [rows]="25"
        responsiveLayout="scroll"
        name="lender-list-table-v2"
        [rowsPerPageOptions]="[10, 25, 50]"
        [scrollHeight]="scrollHeight"
        [scrollable]="true"
        [globalFilterFields]="globalFilterFields"
        [autoLayout]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        styleClass="p-datatable-gridlines"
        sortField="enabledChannels"
      >
        <ng-template pTemplate="caption">
          <div class="d-flex justify-content-between">

            <span class="ms-auto">
              <p-multiSelect
                [options]="columns"
                [(ngModel)]="selectedColumns"
                optionLabel="header"
                selectedItemsLabel="{0} columns selected"
                name="selectedColumns"
                ngDefaultControl
                defaultLabel="Choose Columns"
              ></p-multiSelect>

              <span class="p-input-icon-right ms-2 h-100">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  class="form-control h-100"
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  (ngModelChange)="dt1.first=0"
                  placeholder="Search keyword"
                />
              </span>
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10rem; text-align: center;">
              <span>Action</span>
            </th>
            <th
              *ngFor="let col of getSortedColumns()"
              [pSortableColumn]="col.field"
            >
              <span>{{ col.header }}</span>
              <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>
              <span
                class='d-flex align-items-center justify-content-center w-100 gap-2'
              >
                <span>
                  <i
                    class="fa fa-pencil-alt cursor text-primary"
                    (click)="onEditTemplateClicked(rowData)"
                    title="Edit"
                  ></i>
                </span>

                <span class='vertical-divider'></span>

                <span>
                  <i
                    *ngIf="!isDeleteClicked[rowData.feeTemplateId]"
                    class="fa fa-trash-alt cursor text-danger"
                    (click)="isDeleteClicked[rowData.feeTemplateId] = true"
                    title="Delete"
                  ></i>

                  <i
                    *ngIf="isDeleteClicked[rowData.feeTemplateId]"
                    class="fa fa-times cursor text-danger me-2"
                    (click)="isDeleteClicked[rowData.feeTemplateId] = false"
                    title="Cancel"
                  ></i>

                  <i
                    *ngIf="isDeleteClicked[rowData.feeTemplateId]"
                    class="fa fa-check-circle cursor text-danger"
                    (click)="onDeleteTemplateClicked(rowData.feeTemplateId)"
                    title="Delete"
                  ></i>
                </span>

                <span class='vertical-divider'></span>

                <!-- Copy button -->
                <span>
                  <button class='btn px-0' type='button'>
                    <i
                      class='fa fa-copy cursor text-primary'
                      (click)='copyFeeTemplate(rowData)'
                      title='Copy'
                    ></i>
                  </button>
                </span>

                <span class='vertical-divider'></span>

                <span>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    (click)="selectFeeTemplate(rowData)"
                  >
                    {{
                      selectedFeeTemplate &&
                      selectedFeeTemplate.feeTemplateId == rowData.feeTemplateId
                        ? 'Selected'
                        : 'Select'
                    }}
                  </button>
                </span>
              </span>
            </td>
            <td *ngFor="let col of getSortedColumns()" [ngSwitch]="col.field">
              <span *ngSwitchCase="'enabledChannels'">
                {{
                  rowData.enabledChannels && rowData.enabledChannels != 'Disabled'
                    ? rowData.enabledChannels
                    : ''
                }}
              </span>

              <span *ngSwitchCase="'expressionId'">
                <div [innerHTML]="getExpressionHTMLById(rowData.expressionId)" class="text-center"></div>
              </span>

              <span *ngSwitchDefault>
                {{ rowData[col.field] }}
              </span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="11" class="text-center">
              {{ !isLoading ? 'There are no template.' : 'Loading...' }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <fees-library
    [defaultPlaceholder]="selectedFeeTemplate ? null : 'Select a row above to edit the details'"
    [feeDefinitions]="selectedFeeTemplate ? selectedFeeTemplate.fees : []"
    [title]="
      'Fees in Template' +
      (selectedFeeTemplate ? ' - ' + selectedFeeTemplate.name : '')
    "
    [template]="selectedFeeTemplate"
    (onTemplateFeesChanged)="templateFeesChanged($event)"
  ></fees-library>

</ng-template>
<admin-page-template
  [useActionBarSections]="true"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight"
  [pageMainContentTemplate]="mainContent"
></admin-page-template>
