import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CampaignSubscriptionModel } from '../../../models/campaign/campaign-subscription.model';
import { NgForm } from '@angular/forms';
import { EmailPreferenceCenterService } from '../../../modules/admin/email-campaign-config/services/email-preference-center.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'email-preference-center',
  templateUrl: './email-preference-center.component.html',
  styleUrls: ['./email-preference-center.component.scss'],
})
export class EmailPreferenceCenterComponent implements OnInit, OnDestroy {

  protected readonly possibleLogoUrls: string[];
  protected email: string = '';
  protected subscriptions: CampaignSubscriptionModel[] = [];

  protected subscriptionGroups: CampaignGroup[] = [];

  protected areQueryParamsValid: boolean = true;
  protected hasAnySubscribed: boolean = false;

  protected state: 'email' | 'preferences' = 'email';

  private _paramsSubscription?: Subscription;

  private _companyGuid: string;
  private _messageGuid: string;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _emailPreferenceCenterService: EmailPreferenceCenterService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    navigationService: NavigationService,
  ) {
    this.possibleLogoUrls = navigationService.possibleLogoUrls;
  }

  ngOnInit(): void {
    this.initFromQueryParams();
  }

  ngOnDestroy() {
    this._paramsSubscription?.unsubscribe();
  }

  protected async onSubmitEmail(emailForm: NgForm) {
    if (emailForm.invalid) {
      return;
    }

    const {
      email,
      _companyGuid,
      _messageGuid,
      _emailPreferenceCenterService,
    } = this;

    await this.showSpinner();
    try {
      this.subscriptions = await _emailPreferenceCenterService.getSubscribed(
        _companyGuid,
        _messageGuid,
        email,
      );
      this.state = 'preferences';
      this.populateCampaignGroups();
      this.hasAnySubscribed = this.subscriptionGroups.some((e) => !e.unsubscribed);
    } catch (e) {
      if (e.status === 404) {
        this.showErrorNotification('Email address not found.');
      } else {
        this.showUnknownErrorNotification(e);
      }
    } finally {
      await this.hideSpinner();
    }
  }

  protected async onClickUnsubscribeAll() {
    const {
      email,
      _emailPreferenceCenterService,
      _companyGuid,
    } = this;

    await this.showSpinner();
    try {
      await _emailPreferenceCenterService.unsubscribeAll(_companyGuid, email, this._messageGuid);

      this.unsubscribeAll();
      this.showPreferencesUpdatedNotification();
    } catch (e) {
      this.showUnknownErrorNotification(e);
    } finally {
      await this.hideSpinner();
    }
  }

  protected async onClickCampaignToggle(event, campaignGroup: CampaignGroup) {
    event.preventDefault();

    await this.showSpinner();
    try {
      if (campaignGroup.unsubscribed) {
        await this.subscribeToCampaignGroup(campaignGroup);
      } else {
        await this.unsubscribeFromCampaignGroup(campaignGroup);
      }
      
      // FIXME: Param re-assign
      campaignGroup.campaignSubscriptions.forEach((e) => e.unsubscribed = !e.unsubscribed);
      campaignGroup.unsubscribed = !campaignGroup.unsubscribed;

      this.hasAnySubscribed = this.subscriptionGroups.some((e) => !e.unsubscribed);

      this.showPreferencesUpdatedNotification();
    } catch (e) {
      this.showUnknownErrorNotification(e);
    } finally {
      await this.hideSpinner();
    }
  }

  private showSpinner(): Promise<void> {
    return this._spinnerService.show().then(() => undefined);
  }

  private hideSpinner(): Promise<void> {
    return this._spinnerService.hide().then(() => undefined);
  }

  private showPreferencesUpdatedNotification() {
    this._notificationService.showSuccess(
      'Your preferences has been successfully updated.',
      'Success',
    );
  }

  private initFromQueryParams(): void {
    this._paramsSubscription?.unsubscribe();

    const { queryParams } = this._route;

    this._paramsSubscription = queryParams.pipe(
      tap((params) => {
        if (params.companyGuid == null || params.messageGuid == null) {
          this.showErrorNotification('companyGuid and messageGuid are required.');
          this.areQueryParamsValid = false;
        }
      }),
    ).subscribe((params) => {
      const { companyGuid, messageGuid } = params;

      this._companyGuid = companyGuid;
      this._messageGuid = messageGuid;
    });
  }

  private showErrorNotification(message: string) {
    this._notificationService.showError(
      message,
      'Error',
    );
  }

  private showUnknownErrorNotification(error: any) {
    console.error(error);
    this.showErrorNotification('An error has occurred.');
  }


  private populateCampaignGroups = () => {
    this.subscriptionGroups = [];
    const uniqueCampaignGroupNames = [...new Set(this.subscriptions.map(item => item.preferencesCenterCampaignName))];
    uniqueCampaignGroupNames.forEach((group) => {
      const campaignGroup = new CampaignGroup();
      campaignGroup.preferencesCenterCampaignName = group;
      campaignGroup.campaignSubscriptions = this.subscriptions.filter(item => item.preferencesCenterCampaignName === group);
      campaignGroup.unsubscribed = campaignGroup.campaignSubscriptions.some(item => item.unsubscribed);
      this.subscriptionGroups.push(campaignGroup);
    });
  }

  private unsubscribeAll() {
    this.hasAnySubscribed = false;
    this.subscriptions.forEach((e) => e.unsubscribed = true);
    this.populateCampaignGroups();
  }

  private subscribeToCampaignGroup = (campaignGroup: CampaignGroup) => {
    const apisToCall = [];
    campaignGroup.campaignSubscriptions.forEach((subscription) => {
      if (subscription.unsubscribed) {
        apisToCall.push(this._emailPreferenceCenterService.subscribe(subscription.campaignGuid, this.email));
      }
    });
    return Promise.all(apisToCall);
  }

  private unsubscribeFromCampaignGroup = (campaignGroup: CampaignGroup): Promise<any> => {
    const apisToCall = [];
    campaignGroup.campaignSubscriptions.forEach((subscription) => {
      if (!subscription.unsubscribed) {
        apisToCall.push(this._emailPreferenceCenterService.unsubscribe(subscription.campaignGuid, this.email));
      }
    });
    return Promise.all(apisToCall);
  }
}

export class CampaignGroup {
  preferencesCenterCampaignName: string;
  campaignSubscriptions: CampaignSubscriptionModel[] = []
  unsubscribed: boolean;
}
