import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { QuestionAnswer } from 'src/app/models/question-answer.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CheckListService } from 'src/app/services/check-list.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-question-answer-details-dialog',
  templateUrl: './question-answer-details-dialog.component.html',
  styleUrls: ['./question-answer-details-dialog.component.scss']
})
export class QuestionAnswerDetailsDialogComponent implements OnInit {

  @ViewChild("questionAnswerForm") questionAnswerForm: NgForm | undefined;

  get questionAnswer(): QuestionAnswer {
    return this._questionAnswer;
  }

  @Input()
  set questionAnswer(value: QuestionAnswer) {
    this._questionAnswer = value;
    if (value) {
      this.questionAnswerData = value;
    }
  }

  @Input() questionId: number;
  @Input() borrowerCharacteristicList: Array<EnumerationItem> = [];
  @Input() loanCharacteristicList: Array<EnumerationItem> = [];
  @Input() tasks: Array<EnumerationItem> = [];
  @Input() borrCharacteristicOptionList: Array<EnumerationItem> = [];
  @Input() channelsEnabled: boolean;
  
  questionAnswerData: QuestionAnswer = new QuestionAnswer();
  isSaving: boolean = false;

  private _questionAnswer: QuestionAnswer;

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _notifyService: NotificationService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (!this.questionAnswerData.questionId && this.questionId) {
      this.questionAnswerData.questionId = this.questionId;
    }
  }

  save = () => {
    if (this.questionAnswerForm) {
      this.questionAnswerForm.form.markAllAsTouched();
      if (this.questionAnswerForm.form.valid) {
        if (!this.questionId) {
          this.questionAnswerData.questionAnswerId = Utils.getUniqueId();
          this.activeModal.close(this.questionAnswerData);
          return;
        }
        this.isSaving = true;
        let observable;
        if (this.questionAnswerData.questionAnswerId) {
          observable = this._checkListService.updateQuestionAnswer(this.questionAnswerData);
        }
        else {
          observable = this._checkListService.insertQuestionAnswer(this.questionAnswerData);
        }

        observable.pipe(finalize(() => this.isSaving = false))
          .subscribe({
            next: () => {
              if (this.questionAnswerData.questionAnswerId) {
                this._notifyService.showSuccess("Update done successfully", "Success");
              }
              else {
                this._notifyService.showSuccess("Insert done successfully", "Success");
              }
              this.activeModal.close(this.questionAnswerData);

            }, error: (err) => {
              this._notifyService.showError(err.error.message, "Error");
            }
          });
      }
    }
  }
}
