import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LoanType } from 'src/app/models';

@Injectable()
export class LoanTypeService {
  constructor(private readonly _dataService: DataService) {}

  getAllLoanTypes(companyId: number): Observable<Array<LoanType>> {
    return this._dataService.get(
      `api/Admin/AllLoanTypeModel?companyId=${companyId}`
    );
  }

  insertLoanType(companyId: number, data: LoanType): Observable<LoanType> {
    return this._dataService.post(
      `api/Admin/InsertLoanTypeModel?companyId=${companyId}`,
      data
    );
  }

  updateLoanType(companyId: number, data: LoanType): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateLoanTypeModel?companyId=${companyId}`,
      data
    );
  }

  updateLoanTypeOrder(
    sortedIds: Array<{ id: number }>,
    companyId: number
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateLoanTypeOrder?companyId=${companyId}`,
      sortedIds
    );
  }

  deleteLoanType(
    loanTypeId: number,
    companyId: number,
    assignedLoanTypeId?: number
  ): Observable<undefined> {
    let url = `api/Admin/DeleteLoanTypeModel/${loanTypeId}?companyId=${companyId}`;

    if (assignedLoanTypeId) {
      url += `&replaceWithLoanTypeId=${assignedLoanTypeId}`;
    }

    return this._dataService.delete(url);
  }
}
