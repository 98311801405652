<div class="modal-header">
    <h5 class="modal-title">Task Information</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="task">
    <div class="row text-end">
        <a href="javascript: void(0);" (click)="toggleTaskView()">Show Task {{historyVisible ? "Details" :
            "History"}}</a>
        <hr>
    </div>
    <div *ngIf="!historyVisible">
        <form novalidate>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Description</label>
                        <div class="col-sm-10">
                            <input class="form-control" placeholder="Description" id="description"
                                name="description" type="text" [(ngModel)]="task.description" disabled>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-3 row">
                        <label for="notes" class="col-sm-2 form-label mb-lg-0 text-end">Notes</label>
                        <div class="col-sm-10">
                            <ul *ngIf="listInternalNotes && listInternalNotes.length > 0">
                                <li *ngFor="let note of listInternalNotes">
                                    <span class="note-pre-wrap">{{note.content}}</span>
                                    <div>{{note.senderFirstName}} {{note.senderLastName}}
                                        ({{note.postTime | date:'MMM d, y h:mm:ss a'}})</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="historyVisible">
        <p-table #dt [value]="taskTrackingInfo" [paginator]="true" [rows]="25" *ngIf="taskTrackingInfo"
            [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['actionValue','dateCreated','firstName','lastName','userName']"
            [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <div class="p-d-flex">
                    <span class="p-input-icon-right p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control" (ngModelChange)="dt.first=0"
                            (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="'dateCreated'">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-taskTrackingInfo>
                <tr>
                    <td> {{taskTrackingInfo.actionValue}} </td>
                    <td> {{taskTrackingInfo.dateCreated | date: 'MM/dd/yyyy h:mma'}} </td>
                    <td>
                        <span *ngIf="taskTrackingInfo.by!=null && (taskTrackingInfo.userProfile.firstName != null || taskTrackingInfo.userProfile.lastName != null)">
                            {{taskTrackingInfo.userProfile.firstName}} {{taskTrackingInfo.userProfile.lastName}}
                        </span>
                        <span *ngIf="taskTrackingInfo.by!=null && taskTrackingInfo.userProfile.firstName == null && taskTrackingInfo.userProfile.lastName == null">
                            {{taskTrackingInfo.userProfile.userName}}
                        </span>
                        <span *ngIf="taskTrackingInfo.borrowerId!=null">
                            {{taskTrackingInfo.borrower.firstName}} {{taskTrackingInfo.borrower.lastName}}
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="text-center"> No history found. </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">Close</button>
</div>