<div class="message-container">
	<span class="message-content text-dark">You don't have your credit credentials configured in the system.</span>
</div>
<div class="message-container mb-2">
	<span class="message-content text-dark fw-semibold">Please select your credit vendor from the choices below.</span>
</div>

<div *ngIf="loading" class="cred-vendor-picker-container align-items-center justify-content-center">
	<loading-indicator></loading-indicator>
</div>

<div *ngIf="errorLoadingProviders"
		class="cred-vendor-picker-container align-items-center justify-content-center">
		<div class="alert alert-danger border-0 fs-6 mt-3 mb-3" role="alert">
			<strong><i class="la la-exclamation-circle text-danger"></i></strong> Unable to load credit providers.
		</div>
</div>

<div *ngIf="showAllAvailableVendorsInDropDown && !loading && !errorLoadingProviders"
	class="cred-vendor-picker-container align-items-center justify-content-center">
	<div class="card-body">
		<form class="form-horizontal auth-form" #createCreditCredentialsForm="ngForm" novalidate id="createCreditCredentialsForm" name="createCreditCredentialsForm">
			<div class="mb-2">
				<div><label for="providerNumber" class="form-label text-dark">Select Your Credit Vendor</label></div>
				<p-dropdown id="providerNumber" name="providerNumber" [options]="allDuCreditProviders"
					[required]="true"
					[disabled]="saving"
					[ngClass]="{'is-invalid': providerNumberField && providerNumberField.touched && providerNumberField.invalid}"
					#providerNumberField="ngModel"
					[(ngModel)]="selectedProviderNumber" [showClear]="true" [filter]="true" optionLabel="name"
					(ngModelChange)="onFannieProviderNumberSelected($event)"
					optionValue="providerNumber" placeholder="-- Select One --">
				</p-dropdown>
				<div class="invalid-feedback">Please select a credit vendor.</div>
			</div>
			<div class="mb-2">
				<label for="userName" class="form-label text-dark">Username</label>
				<input name="userName" 
					[required]="true"
					[(ngModel)]="userName" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': userNameField && userNameField.touched && userNameField.invalid}"
					name="branchZip" 
					#userNameField="ngModel"/>
				<div class="invalid-feedback">Please enter your username.</div>
			</div>
			<div class="mb-2">
				<label for="password" class="form-label text-dark">Password</label>
				<input name="password" 
					[required]="true"
					[(ngModel)]="password" 
					[disabled]="saving"
					class="form-control" 
					[ngClass]="{'is-invalid': passwordField && passwordField.touched && passwordField.invalid}"
					name="password" 
					#passwordField="ngModel"/>
				<div class="invalid-feedback">Please enter your password.</div>
			</div>

			<div class="mb-2" style="text-align: center; display: grid;">
				<button *ngIf="!saving" (click)="onSaveClicked()"
					class="btn btn-primary" name="saveButton"
					id="saveButton">Save
				</button>
				<button class="btn btn-primary waves-effect waves-ligh" type="button" disabled=""
					*ngIf="saving">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					Please wait...
				</button>
			</div>
		</form>
	</div>
</div>

<div *ngIf="!showAllAvailableVendorsInDropDown && !loading && !errorLoadingProviders"
	class="cred-vendor-grid-container align-items-center justify-content-center">
	<div class="search-container">
		<span class="p-input-icon-right" style="width: 300px;">
			<i class="pi pi-search"></i>
			<input pInputText type="text" class="form-control h-100" [(ngModel)]="searchString"
				(ngModelChange)="onSearchStringChanged()" placeholder="Search" />
		</span>
	</div>
	<button (click)="onAgencySelected(agency)" title="{{agency.name}}" *ngFor="let agency of filteredDuCreditProviders"
		class="col-sm-3 cred-button-grid-item">{{agency.name}}</button>
</div>

<div class="message-container">
	<a *ngIf="!showAllAvailableVendorsInDropDown" class="message-content" href="javascript:void(0)"
		(click)="onIDontSeeMyVendorClicked()">Don't see your credit vendor? Click here...</a>
	<a *ngIf="showAllAvailableVendorsInDropDown" class="message-content" href="javascript:void(0)"
		(click)="onBackToVendorButtonsViewClicked()">&lt; Back</a>
</div>