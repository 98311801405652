<p-table [value]="conflicts" [paginator]="false">
  <ng-template pTemplate="header">
    <tr>
      <th>Description</th>
      <th>Plan Value</th>
      <th>Encompass Value</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-conflict>
    <tr>
      <td>{{conflict.description}}</td>
      <td>{{conflict.planValue}}</td>
      <td>{{conflict.encompassValue}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="3" class="text-center">
        There are no conflict
      </td>
    </tr>
  </ng-template>
</p-table>
