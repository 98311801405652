import { Branch } from "../branch.model"
import { Company } from "../company-model"

export class ExternalCompany extends Company {
    accountExecutiveUserId: string = null
    accountExecutiveAssignedDate: string
    applicationDate: string
    approvedDate: string
    billingAddress: string
    billingCity: string
    billingState: string = null
    billingZip: string
    entityType: string = null
    errorOmissionsCopmany: string
    errorOmissionsExpirationDate: string
    errorOmissionsPolicyNumber: string
    externalCompanyURL: string
    financialsLastUpdated: string
    financialsPeriod: string
    incorporationDate: string
    legalName: string
    name: string
    netWorth: string
    notes: string
    owner: string
    phoneNumber: string
    stateOfIncorporation: string
    status: string = null
    ssnOrTaxId: string
    statusDate: string
    timezone: string = null
    type: string = null
    losEntityId: string = null
    losParentId: string = null
    losTPOId: string = null

    appraisalOrderingEnabled: boolean
    docMagicEnabled: boolean
    ocrEnabled: boolean

    children?: Branch[] = [];

    //UI only
    accountExecutiveName: string;

    readonly externalCompanyId: number
    
    constructor() {
        super()
    }
}