import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CocReason } from 'src/app/modules/disclosure-tracking/models/disclosure-tracking.model';
import { NotificationService } from 'src/app/services/notification.service';
import { CocReasonsConfigService } from '../coc-reasons-config.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'upsert-coc-reason',
  templateUrl: './upsert-coc-reason-dialog.component.html',
  styleUrls: ['./upsert-coc-reason-dialog.component.scss']
})
export class UpsertCocReasonDialogComponent implements OnInit {

  @Input() cocReason: CocReason;
  @Input() disclosureReasons: EnumerationItem[] = [];

  @Output() close: EventEmitter<CocReason> = new EventEmitter<CocReason>();

  @ViewChild('upsertCocReasonForm') upsertCocReasonForm: NgForm | undefined;

  saving: boolean;
  editMode: boolean;

  protected reasonCategories: EnumerationItem[] = [];

  constructor(
    private readonly _cocReasonsConfigService: CocReasonsConfigService,
    private readonly _notificationService: NotificationService,
    private readonly _enumsService: EnumerationService
  ) { }

  ngOnInit(): void {
    this.editMode = this.cocReason.changeOfCircumstanceReasonId ? true : false;
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.reasonCategories = enums[Constants.mortgageEnumerations.changeOfCircumstanceReasonCategory];
    });
    if (!this.cocReason.reasonCategory) {
      this.cocReason.reasonCategory = null;
    }
  }

  onClose() {
    this.close.emit();
  }

  save() {
    if (this.upsertCocReasonForm) {
      this.upsertCocReasonForm.form.markAllAsTouched();
      if (!this.upsertCocReasonForm.form.valid) return;
    }

    this.saving = true;

    this._cocReasonsConfigService.upsertCoCReason(this.cocReason)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'CoC reason' + (this.editMode ? " updated " : " added ") + 'successfully',
            'CoC Reason'
          );

          let disclosureReason = this.disclosureReasons.find(dr => dr.value == res.disclosureReason);
            res["disclosureReasonDisplayName"] = disclosureReason ? disclosureReason.name : null;
          let reasonCategory = this.reasonCategories.find(dr => dr.value == res.reasonCategory);
            res["reasonCategoryDisplayName"] = reasonCategory ? reasonCategory.name : null;

          this.close.emit(res);
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't" + (this.editMode ? " update " : " insert new ") + "CoC reason",
            'CoC Reason'
          );
        }
      });
  }

}
