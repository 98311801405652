<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="currentLoanInfo">Current Unpaid Principal Balance</label>
    <currency-input
      name="currentLoanInfo"
      [(ngModel)]="lead.currentLoanUnpaidPrincipalBalance"
      [readonly]="false"
      [disabled]="false"
      [required]="false"
    ></currency-input>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="currentRate">Interest Rate</label>
    <percent-input
      name="currentRate"
      [readonly]="false"
      [required]="false"
      [disabled]="false"
      [(ngModel)]="lead.currentLoanInterestRate"
    ></percent-input>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="term">Term (months)</label>
    <input
      id="term"
      name="term"
      class="form-control"
      numeric [allowNegative]="false"
      [(ngModel)]="lead.currentLoanTerm"
    />
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="escrow">Escrows (monthly)</label>
    <div class="d-flex">
      <currency-input
        name="escrow"
        [(ngModel)]="lead.escrow"
        [readonly]="false"
        [disabled]="false"
        [required]="false"
        style="width: -webkit-fill-available"
      ></currency-input>
      <a
        href="javascript: void(0);"
        class="mt-2"
        placement="start"
        [popover]="escrowCalculator"
        triggers=""
        #calculatorPopover="bs-popover"
        (click)="onEscrowCalculatorOpened()"
      >
        <i class="fa fa-calculator ms-2"></i>
      </a>
      <ng-template #escrowCalculator>
        <lead-escrow-calculator
          [monthlyEscrow]="lead.escrow"
          (save)="onEscrowCalculatorSaved($event)"
          (close)="onEscrowCalculatorClosed()"
        ></lead-escrow-calculator>
      </ng-template>
    </div>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="currentPAndI">Current P&I</label>
    <currency-input
      name="currentPAndI"
      [(ngModel)]="lead.currentLoanPrincipalAndInterestPayment"
    ></currency-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="firstMortgageBalance">First Mortgage Balance</label>
    <currency-input
      name="firstMortgageBalance"
      [(ngModel)]="lead.firstMortgageBalance"
    >
    </currency-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="secondMortgageBalance">Second Mortgage Balance</label>
    <currency-input
      name="secondMortgageBalance"
      [(ngModel)]="lead.secondMortgageBalance"
      >
    </currency-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="currentLTV">Current LTV</label>
    <percent-input
      name="currentLTV"
      [readonly]="false"
      [required]="false"
      [disabled]="false"
      [(ngModel)]="lead.currentLTV"
      >
    </percent-input>
  </div>
</div>
