import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { ExternalCompanyContact } from "../models";
import { CountByFilter } from "../models/pipeline/counting-by-filter-model";
import { PipelineItem } from "../models/pipeline/pipeline-item.model";
import { UserRolesFilter } from "../models/pipeline/requests/user-roles-filter.model";
import { RoleUserAppCounts } from "../models/pipeline/role-user-app-counts.model";

@Injectable()
export class PipelineService {

  constructor(private readonly _dataService: DataService) {
  }

  getDistinctUserRoles = (filter: UserRolesFilter): Observable<RoleUserAppCounts[]> => {
    const url = 'api/Pipeline/GetDistinctUserRoles';
    return this._dataService.post(url, filter);
  }

  getByFilter = (filter: UserRolesFilter): Observable<PipelineItem[]> => {
    const url = 'api/Pipeline/get-by-filter';
    return this._dataService.post(url, filter);
  }

  getCountByFilter = (filter: UserRolesFilter): Observable<CountByFilter> => {
    const url = 'api/Pipeline/get-count-by-filter';
    return this._dataService.post(url, filter);
  }

  getAllApplicationsForInternalContact = (archive: boolean = false): Observable<PipelineItem[]> => {
    const url = `api/Pipeline/GetAllApplicationsForInternalContact?archive=${archive}`;
    return this._dataService.get(url);
  }

  getLoanTeam = (externalCompanyId: number): Observable<ExternalCompanyContact[]> => {
    const url = `api/Admin/external-company/contact/${externalCompanyId}`;
    return this._dataService.get(url);
  };
}
