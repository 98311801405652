import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditBorrower } from 'src/app/modules/loan-services/credit/models/credit-borrower.model';
import { CreditService } from 'src/app/modules/loan-services/credit/services/credit.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'multi-borrower-fico-info',
  templateUrl: 'multi-borrower-fico-info.component.html',
  styleUrls: ['./multi-borrower-fico-info.component.scss'],
})

export class MultiBorrowerFicoInfoComponent implements OnInit {

  @Input()
  borrowers: CreditBorrower[] = [];

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _creditService: CreditService,
    private readonly _notifsService: NotificationService
  ) { }

  ngOnInit() {
  }

  creditFileNoClicked = (refNumber: string) => {
    navigator.clipboard.writeText(refNumber);
    this._notifsService.showInfo("Credit File No has been copied to clipboard", "Info!");
  }

  reportGeneratedClicked = (integrationHistoryId: number) => {
    this._spinner.show();
    this._creditService.getReportContent(integrationHistoryId).subscribe(data => {
      const blob = new Blob([data], { type: data['type'] || 'text/html' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }, error => {
      const errorMessage = error && error.message ? error.message : "An error occurred while downloading the credit report.";
      this._notifsService.showError(errorMessage, "Error!");
    }).add(() => {
      this._spinner.hide();
    });
  }
}
