import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PendingChangesGuard } from './route-guards/pending-changes.guard';
import { EnvironmentService } from './services/environment/environment.service';
import { HttpRequestCache } from './services/http-request-cache.decorator';

/**
 * This abstract class used for module building by extending this class
 * prevents importing the module into somewhere else than root App Module.
 */
export abstract class EnsureImportedOnceModule {
  protected constructor(targetModule: any) {
    if (targetModule) {
      throw new Error(`${targetModule.constructor.name} has already been loaded.`);
    }
  }
}

@NgModule({
  imports: [
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    EnvironmentService,
    PendingChangesGuard
  ],
})
export class CoreModule extends EnsureImportedOnceModule {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}

