<ng-container *ngIf='content'>
  <license-info-badge
    [variant]='content.variant'
    minWidth='8ch'
  >
    <span *ngIf='content.iconClassName as iconClassName'>
      <i [class]='iconClassName'></i>
    </span>

    <span>{{ content.label }}</span>
  </license-info-badge>
</ng-container>
