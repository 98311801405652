import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LosTransactionHistoryComponent } from './los-transaction-history/los-transaction-history.component';
import { ApplicationContextBoundComponent } from '../../shared/components';
import { LosLoanReferenceComponent } from './los-loan-reference/los-loan-reference.component';
import { LosLoanLinkComponent } from './los-loan-link/los-loan-link.component';
import { LosLoanCreateComponent } from './los-loan-create/los-loan-create.component';
import { LosService } from '../../services/los.service';
import {
  LoanApplication,
  LosAppOperationResult,
  LosProviderFeature,
  LosTransactionHistory,
} from '../../models';
import { NotificationService } from '../../services/notification.service';
import { LosSyncDirectionComponent } from './los-sync-direction/los-sync-direction.component';
import { SyncLodaDocWithLosDialogComponent } from '../loan-docs/components/sync-loda-doc-with-los-dialog/sync-loda-doc-with-los-dialog.component';
import { Constants } from 'src/app/services/constants';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { VendorsList } from '../loan-docs/models/vendors-list-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, forkJoin, of, take } from 'rxjs';

@Component({
  selector: 'los-info',
  templateUrl: 'los-info.component.html',
  styleUrls: ['./los-info.component.scss'],
})
export class LosInfoComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output()
  syncDirectionUpdated: EventEmitter<string> = new EventEmitter<string>();

  pullingFromLos: boolean = false;
  pushingToLos: boolean = false;
  unlinkingFromLos: boolean = false;
  loading: boolean = false;
  buttonPressed: boolean = false;
  losEnabled: boolean;

  losVendors;
  globalConfig: GlobalConfig;
  transactionHistory: LosTransactionHistory;
  application: LoanApplication;

  losSyncDirection: string = '';
  selectedLosVendor: string = '';
  loanStatusType: string;

  private vendorsList: VendorsList;

  private readonly _syncDirections = {
    None: 'No Data Flow',
    LosToLoda: 'LOS to Loda Only',
    Both: 'Bi-directional Data Flow',
  };

  constructor(
    private readonly injector: Injector,
    private modalService: NgbModal,
    private readonly _losService: LosService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.loading = true;

    this.applicationContextService.loanLosLdeChanges.subscribe((context) => {
      this.losEnabled = context.userPermissions.losEnabled;
      this.application = context.application;
      this.globalConfig = context.globalConfig;
      this.updateUi();
      this._reloadTransactionHistory();
    });

    if (this.applicationContext.application) {
      this.application = this.applicationContext.application;
      this.losEnabled = this.applicationContext.userPermissions.losEnabled;
      this.globalConfig = this.applicationContext.globalConfig;
      this.initialize();
    }
  }

  referenceLosLoan(isEditable: boolean) {
    const modalRef = this.modalService.open(LosLoanReferenceComponent, {
      centered: true,
    });
    modalRef.componentInstance.applicationId = this.application.applicationId;
    if (isEditable) {
      modalRef.componentInstance.refNumber = this.application.refNumber;
    }

    modalRef.result.then(
      (result: string) => {
        this.application.refNumber = result;

        this.applicationContextService.refreshApplicationAfterLosLdeRefChange(this.application);

        this._reloadTransactionHistory();
      },
      () => { }
    );
  }

  linkLosLoan() {
    this._losService.getLosCredentialsForLinkLoanWithLos(this.application.applicationId).subscribe({
      next: (losVendors) => {
        const modalRef = this.modalService.open(LosLoanLinkComponent, {
          size: 'xl',
          centered: true
        });
        modalRef.componentInstance.applicationId = this.application.applicationId;
        modalRef.componentInstance.losVendors = losVendors.filter(
          (vendor) =>
            (vendor.vendorFeatures.indexOf(LosProviderFeature.Search) > -1 && vendor.vendorFeatures.indexOf(LosProviderFeature.SyncMortgage) > -1) ||
            vendor.vendorFeatures.indexOf(LosProviderFeature.Lookup) > -1
        );

        modalRef.result.then(
          (result: LosAppOperationResult | null) => {
            if (result) {
              this.application.losIdentifier = result.losIdentifier;
              this.application.refNumber = result.refNumber;
              this.application.losVendor = result.losVendor;
              this.application.losSyncDir = result.losSyncDir;
              this.application.losInstanceId = result.losInstanceId;

              this.applicationContextService.updateApplication(this.application);
            }

            this.updateUi();
            this._reloadTransactionHistory();
          },
          () => { }
        );
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load LOS vendors", "Error");
      }
    })
  }

  createLosLoan() {
    this._losService.getLosCredentialsForCreateLoanInLos(this.application.applicationId).subscribe({
      next: (losVendors) => {
        const modalRef = this.modalService.open(LosLoanCreateComponent, {
          centered: true,
          backdrop: 'static'
        });
        modalRef.componentInstance.applicationId = this.application.applicationId;
        modalRef.componentInstance.losVendors = losVendors.filter(vendor => vendor.vendorFeatures.indexOf(LosProviderFeature.CreateMortgage) > -1);

        modalRef.result.then(
          (result: LosAppOperationResult | null) => {
            if (result) {
              this.applicationContextService.updateLoanAfterLosLdeChange(result);
            }

            this._reloadTransactionHistory();
          },
          () => { }
        );
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load LOS vendors", "Error")
      }
    })
  }

  pullFromLos() {
    this.pullingFromLos = true;
    this.buttonPressed = true;
    this._spinner.show();

    this._losService.pullFromLos(this.application.applicationId).subscribe(
      (result: LosAppOperationResult | null) => {
        if (result) {
          this.applicationContextService.updateLoanAfterLosLdeChange(result);
        }

        this._reloadTransactionHistory();
        
        this._notificationService.showSuccess('Pulled from LOS', 'Success');
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
      }

    ).add(() => {
      this.pullingFromLos = false;
      this.buttonPressed = false;
      this._spinner.hide();
    });
  }

  pushToLos() {
    this.pushingToLos = true;
    this.buttonPressed = true;
    this._spinner.show();
    this._losService.pushToLos(this.application.applicationId).subscribe(
      (result: LosAppOperationResult | null) => {
        if (result) {
          this.applicationContextService.updateLoanAfterLosLdeChange(result);
        }
        this._notificationService.showSuccess('Pushed to LOS', 'Success');
        this.pushingToLos = false;
        this.buttonPressed = false;

        this._reloadTransactionHistory();
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
        this.pushingToLos = false;
        this.buttonPressed = false;
      }
    ).add(() => { this._spinner.hide(); });
  }

  partialPushToLos() {
    this.pushingToLos = true;
    this.buttonPressed = true;
    this._spinner.show();
    this._losService.partialPushToLos(this.application.applicationId).subscribe(
      (result: LosAppOperationResult | null) => {
        if (result) {
          this.applicationContextService.updateLoanAfterLosLdeChange(result);
        }
        this._notificationService.showSuccess('Pushed to LOS', 'Success');
        this.pushingToLos = false;
        this.buttonPressed = false;

        this._reloadTransactionHistory();
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
        this.pushingToLos = false;
        this.buttonPressed = false;
      }
    ).add(() => { this._spinner.hide(); });
  }

  syncDocuments() {
    const modalRef = this.modalService.open(SyncLodaDocWithLosDialogComponent, Constants.modalOptions.xlarge)
    modalRef.componentInstance.title = 'Synchronize Documents With LOS';
    modalRef.componentInstance.appId = this.application.applicationId;
    modalRef.componentInstance.userId = this.application.userId;
    modalRef.componentInstance.companyId = this.application.companyId;
    modalRef.componentInstance.docTypes = this.globalConfig.documentType.filter(doc => doc.documentTypeId > -1);
    modalRef.componentInstance.losVendor = this.filteredVendors(this.vendorsList, this.application.losVendor);
    modalRef.result.then((result) => {
      if (result === 'cancel') {
        return;
      }

      this._reloadTransactionHistory();

      //TODO(Kaan): Call Context Service to auto Refresh the loan docs. Wire up to same service on loan docs to auto refresh.
      // this.applicationContextService.forceLoanDocUpdate();
    }, (res) => {
    });
  }

  showTransactionHistory() {
    const modalRef = this.modalService.open(LosTransactionHistoryComponent, Constants.modalOptions.ninetyPercentOfScreenWidth)
    modalRef.componentInstance.transactionHistory = this.transactionHistory;

    modalRef.result.then((result) => {
      if (result === 'cancel') return;
    }, () => {
      //
    });
  }

  private getVendors = () => {
    this._losService.getVendors()
      .subscribe({
        next: (result) => {
          this.vendorsList = result;
        },
        error: (err) => {
          this._notificationService.showError(err ? err.error : "Couldn't get vendor list!", 'Failure');
        }
      });
  }

  private filteredVendors = (vendorsList: VendorsList, losVendor: string) => {
    if (vendorsList.values.length === 0) {
      return "";
    }
    const index = vendorsList.values.findIndex(value => value === losVendor);
    if (index === -1) {
      return "";
    }
    return vendorsList.names[index];
  }

  unlinkLos() {
    this.unlinkingFromLos = true;
    this.buttonPressed = true;

    this._losService.unlink(this.application.applicationId).subscribe(
      () => {
        this.application.losIdentifier = null;
        this.application.refNumber = null;
        this.application.losVendor = null;
        this.application.losSyncDir = null;
        this.application.losInstanceId = null;

        this.applicationContextService.updateLoanAfterLosLdeUnlinkChange(this.application);

        this.updateUi();

        this._notificationService.showSuccess('Loan Unlinked from LOS', 'Success');
        this.unlinkingFromLos = false;
        this.buttonPressed = false;

        this._reloadTransactionHistory();
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
        this.unlinkingFromLos = false;
        this.buttonPressed = false;
      }
    );
  }

  changeSyncDirection() {
    const modalRef = this.modalService.open(LosSyncDirectionComponent, {
      centered: true,
    });
    modalRef.componentInstance.applicationId = this.application.applicationId;
    modalRef.componentInstance.syncDirection = this.application.losSyncDir;

    modalRef.result.then((result) => {
      this.application.losSyncDir = result;

      this.applicationContextService.updateApplicationAfterLosLdeSyncChange(this.application);

      this.syncDirectionUpdated.emit(result);
      this.setSyncDirectionDisplayValue();

      this._reloadTransactionHistory();
    });
  }

  areButtonsDisabled(): boolean {
    return this.unlinkingFromLos || this.pullingFromLos || this.pushingToLos;
  }

  private updateUi() {
    this.setLoanStatusType();
    this.setSyncDirectionDisplayValue();
    this.setSelectedLosVendor();
  }

  private setLoanStatusType() {
    const { refNumber, losVendor, losIdentifier } = this.application;
    if (refNumber && !losVendor && !losIdentifier) {
      this.loanStatusType = 'referenced';
    } else if (losVendor && losIdentifier) {
      this.loanStatusType = 'linked';
    } else {
      this.loanStatusType = 'notAssociated';
    }
  }

  private setSyncDirectionDisplayValue() {
    this.losSyncDirection =
      this._syncDirections[this.application.losSyncDir] ||
      this._syncDirections['None'];
  }

  private setSelectedLosVendor() {
    this.selectedLosVendor = this.application?.losVendor || null;
  }

  private initialize() {
    this.getVendors();
    this.updateUi();

    const subscription = forkJoin({
      losVendors: this._losService.getLosCredentialsForLoan(this.application.applicationId).pipe(catchError(() => of(null))),
      transactionHistory: this._losService.getTransactionHistory(this.application.applicationId).pipe(catchError(() => of(null))),
    }).subscribe({
      next: ({ losVendors, transactionHistory }) => {
        this.losVendors = losVendors || [];

        this.losVendors.forEach((vendor) => {
          if (this.application.losVendor === vendor.losVendor) {
            this.selectedLosVendor = vendor.losVendorName;
          }
          if (vendor.vendorFeatures.indexOf('Lookup') > -1) {
            vendor.borrowerDisabled = true;
          }
        });

        this.transactionHistory = transactionHistory || new LosTransactionHistory();
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message,
          'Error!'
        );
      },
      complete: () => {
        subscription.unsubscribe();
        this.loading = false;
      }
    });
  }

  private _reloadTransactionHistory() {
    this.loading = true;
    this._losService.getTransactionHistory(this.application.applicationId).subscribe({
      next: (transactionHistory) => {
        this.transactionHistory = transactionHistory || new LosTransactionHistory();
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load transaction history", "Error!");
      }
    }).add(() => {
      this.loading = false;
    });
  }
}
