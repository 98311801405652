import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateLicensing } from 'src/app/models/user/state-licensing.model';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'upsert-state-licensing',
  templateUrl: './upsert-state-licensing.component.html',
  styleUrls: ['./upsert-state-licensing.component.scss']
})
export class UpsertStateLicensingComponent implements OnInit {
  @Input() stateLicensing: StateLicensing
  @Input() states: EnumerationItem[];
  @Input() userCompanyGuid: string;
  @Input() existingStates: string[];

  @ViewChild('licensingForm') licensingForm: NgForm | undefined;

  @Output() close: EventEmitter<StateLicensing> = new EventEmitter<StateLicensing>()

  saveInProgress: boolean;

  constructor(
    private readonly _profileService: ProfileService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    // map from display to value
    if (this.stateLicensing?.state === "All States") {
      this.stateLicensing.state = "US"
    }
  }

  save() {
    this.licensingForm.form.markAllAsTouched();
    if (!this.licensingForm.form.valid || this.issueDateGreaterThenExpiration || this.stateAlreadyExists) {
      return;
    }

    this.stateLicensing.allStates = this.stateLicensing.state === "US";

    this.saveInProgress = true;
    this.stateLicensing.userLicenseId
      ? this.updateRecord()
      : this.insertRecord();
  }

  private insertRecord() {
    this._profileService.insertStateLicensing(this.stateLicensing)
      .pipe(finalize(() => {
        this.saveInProgress = false;
      }))
      .subscribe({
        next: (response) => {
          this._notifyService.showSuccess('New Record added successfully', 'State Licensing');
          this.close.emit(response)
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to add new record', 'State Licensing');
        }
      });
  }

  private updateRecord() {
    this._profileService.updateStateLicensing(this.stateLicensing)
      .pipe(finalize(() => {
        this.saveInProgress = false;
      }))
      .subscribe({
        next: (response) => {
          this._notifyService.showSuccess('Record updated successfully', 'State Licensing');
          this.close.emit(response)
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to add update record', 'State Licensing');
        }
      });
  }

  get issueDateGreaterThenExpiration() {
    if (!this.stateLicensing.issueDate || !this.stateLicensing.expirationDate) {
      return false;
    }
    const issueDate = Date.parse(this.stateLicensing.issueDate.toString());
    const expirationDate = Date.parse(this.stateLicensing.expirationDate.toString());
    return issueDate > expirationDate;
  }

  get stateAlreadyExists() {
    if (!this.stateLicensing.state) {
      return false;
    }
    return this.existingStates.includes(this.stateLicensing.state.toUpperCase());
  }

  cancel() {
    this.close.emit();
  }
}
