import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'ocr-vendor-picker-dialog',
  templateUrl: './ocr-vendor-picker-dialog.component.html'
})

export class OCRVendorPickerDialogComponent implements OnInit {

  @ViewChild("vendorPickerForm")
  vendorPickerForm: NgForm;

  @Input()
  vendors: EnumerationItem[] = [];

  selectedVendor: EnumerationItem = new EnumerationItem("-- Select One --", null);

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onOkClicked = () => {
    this.vendorPickerForm.form.markAllAsTouched();
    if (this.vendorPickerForm.form.valid) {
      const selectedVendor = this.vendors.find(vendor => vendor.value == this.selectedVendor.value);
      this.activeModal.close(selectedVendor.name);
    }
  }
}