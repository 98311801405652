import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFreeHistory } from 'src/app/models';

@Component({
  selector: 'import-assets-dialog',
  templateUrl: 'import-assets-dialog.component.html',
  styleUrls: ['import-assets-dialog.component.scss'],
})
export class ImportAssetsDialogComponent implements OnInit {

  @Input()
  order: FormFreeHistory;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
