import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EnvironmentService } from "../core/services/environment/environment.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import { AuthenticationUserCompany } from "../models/auth/authentication-response.model";

@Injectable()
export class NavigationService {

  constructor(private readonly _router: Router,
    private readonly _localStorageService: LocalStorageService,
    private readonly _environment: EnvironmentService,
    @Inject(DOCUMENT) private readonly _document: Document) {
  }

  applicationMode: ApplicationMode = location.pathname.split('/')[1] == 'admin' ? ApplicationMode.Classic :
    location.pathname.split('/')[1] == 'loda-nextgen' ? ApplicationMode.NextGen : ApplicationMode.Classic;

  navigateToLogin = (companyGuid?: string, setReturnUrl: boolean = true) => {
    let queryParams: any = {};
    if (companyGuid) {
      queryParams.companyGuid = companyGuid;
    }
    if (location.pathname && location.pathname !== '/login' && setReturnUrl) {
      queryParams.returnUrl = location.pathname;
    }
    this.navigateToPath('login', queryParams);
  }

  navigateToError = () => {
    this.navigateToPath('error');
  }

  navigateToPath = (path: string, queryParams?: any): void => {
    if (!queryParams) {
      queryParams = {};
    } else {
      queryParams = { queryParams: queryParams };
    }
    const urlToGo = this._router.createUrlTree([path], queryParams).toString();
    this._router.navigateByUrl(urlToGo);
  }

  // roleId and roleName are not nulled in a company.component
  navigateToCompanySwitchUrl = (company: AuthenticationUserCompany, isSuperAdmin: boolean = false) => {
    if (this._environment.environment === 'Local' || this._environment.environment === 'loda-dv' || this._environment.environment === 'loda-local' || isSuperAdmin) {
      this._router.navigate(['switch-company'], {
        queryParams: { companyGuid: company.companyGuid, userType: company.userType, userCompanyGuid: company.userCompanyGuid },
      });
      return;
    }
    if (!company.companyUrl) {
      return;
    }
    const urls = company.companyUrl.split('|');
    if (urls.length > 0) {
      this._document.location.href = `https://${urls[0]}/switch-company?companyGuid=${company.companyGuid}&userType=${company.userType}`;
    }
  }

  get possibleLogoUrls(): string[] {
    let possibleLogoUrls: string[] = [];
    possibleLogoUrls.push(this._environment.apiInfo.apiBaseUrl + 'company/co/register');
    return possibleLogoUrls;
  }
}

export enum ApplicationMode {
  Classic = 1,
  NextGen = 2
}
