import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs';
import { Borrower } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { TaskStats } from 'src/app/modules/tasks/models/task-stats-group.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'lead-tasks',
  templateUrl: './lead-tasks.component.html',
  styleUrls: ['./lead-tasks.component.scss']
})
export class LeadTasksComponent implements OnInit {

  @Input() lead: Lead;

  @Input() taskStats: TaskStats[];

  @Input()
  public set filterId(value: number) {
    this._filterId = value;

    if (value) {
      this.getTasks();
    }
  }

  public get filterId(): number {
    return this._filterId;
  }

  @Input()
  impersonateUserId: string;

  @Output() taskStatusUpdated = new EventEmitter<boolean>();

  leadBorrowers: Partial<Borrower>[] = [];
  leadTasks: LoanDocDashboardTask[] = [];
  isLoading: boolean = false;

  private _filterId: number;

  constructor(private _dashboardService: DashboardService,
    private _notifyService: NotificationService) { }


  ngOnInit(): void {
    if (this.lead.firstName || this.lead.lastName) {
      this.leadBorrowers.push(
        {
          firstName: this.lead.firstName || "",
          middleName: this.lead.middleName || "",
          lastName: this.lead.lastName || "",
          creditScore: this.lead.creditScore || 0,
          mailingStreet: this.lead.mailingAddress1,
          mailingCity: this.lead.mailingCity,
          mailingState: this.lead.mailingState,
          mailingZip: this.lead.mailingZip,
          mobilePhone: this.lead.mobilePhone,
          homePhone: this.lead.phone,
          email: this.lead.email,
          isPrimary: true,
        }
      )
    }

    if (this.lead.coFirstName || this.lead.coLastName) {
      this.leadBorrowers.push(
        {
          firstName: this.lead.coFirstName || "",
          middleName: this.lead.coMiddleName || "",
          lastName: this.lead.coLastName || "",
          creditScore: this.lead.coCreditScore || 0,
          mailingStreet: this.lead.coMailingAddress1,
          mailingCity: this.lead.coMailingCity,
          mailingState: this.lead.coMailingState,
          mailingZip: this.lead.coMailingZip,
          mobilePhone: this.lead.coMobilePhone,
          homePhone: this.lead.coPhone,
          email: this.lead.coEmail,
          isPrimary: false,
        }
      )
    }
  }

  getTasks = (): void => {
    this.isLoading = true;
    if (this.lead.leadId && this.filterId) {
      this._dashboardService.getTaskByFilterInLead(this.filterId, this.lead.leadId, this.impersonateUserId)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: (tasks) => {
            this.leadTasks = tasks;
          },
          error: () => {
            this._notifyService.showError("Error encountered while loading lead tasks", "Error!");
          }
        });
    }
  }

  reloadTasksClicked = () => {
    this.getTasks();
    this.taskStatusUpdated.emit(true);
  }

  changeStat = (stat: TaskStats) => {
    this._filterId = stat.id;
    this.getTasks();
  }

}
