import { Component, Output, OnInit, EventEmitter } from "@angular/core";
import { ApplicationContextBoundComponent } from "src/app/shared/components";

@Component({
  selector: "los-lde-summary",
  templateUrl: "los-lde-summary.component.html",
  styleUrls: ['./los-lde-summary.component.scss']
})
export class LosLdeSummaryComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output()
  losSyncDirectionUpdated: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  ldeSyncInfoUpdated: EventEmitter<string> = new EventEmitter<string>();

  losEnabled: boolean
  ldeEnabled: boolean;

  type: 'los' | 'lde';

  ngOnInit(): void {
    this.applicationContextService.context.subscribe(context => {
      this.losEnabled = context.userPermissions.losEnabled;
      this.ldeEnabled = context.userPermissions.ldeEnabled;
    })
    this.type = this.losEnabled ? 'los' : 'lde';
  }

  onLosSyncDirectionUpdated = (syncDirection: string) => {
    this.losSyncDirectionUpdated.emit(syncDirection);
  }

  onLdeSyncInfoUpdated = () => {
    this.ldeSyncInfoUpdated.emit();
  }
}
