import { LeadFilters } from "./lead-filters.model";

export class LeadMilestonesReportSingle {
  leads: number;
  activeLeads: number;
  convertedLeads: number;
  contactRate: number;
  conversionRate: number;
  speedToContactAttemptSeconds: number;
  speedToContactSeconds: number;

  filterTotalLeads: number;
  filterTotalActiveLeads: number;
  filterTotalConvertedLeads: number;
  filterTotalConversionRate: number;
  filterTotalContactRate: number;
  filterSpeedToContactAttemptSeconds: number;
  filterSpeedToContactSeconds: number;
}

export class LeadMilestonesReport extends  LeadMilestonesReportSingle{
  
  totalLeads: number;
  totalActiveLeads: number;
  totalConvertedLeads: number;
  totalConversionRate: number;
  totalContactRate: number;
  totalSpeedToContactAttemptSeconds: number;
  totalSpeedToContactSeconds: number;
}

export class LeadMilestonesReportData {
  description: string;
  speedToContactAttemptSeconds: number;
  speedToContactSeconds: number;
  leads: number;
  activeLeads: number;
  convertedLeads: number;
  contactRate: number;
  conversionRate: number;

  constructor(d,sa,s,l,a,cl,cr,convr){
    this.description = d;
    this.speedToContactAttemptSeconds = sa;
    this.speedToContactSeconds = s;
    this.leads = l;
    this.activeLeads = a;
    this.convertedLeads = cl;
    this.contactRate = cr;
    this.conversionRate = convr;
  }
}

export interface LeadPerformanceReport {
  newLeadsReceived: number;
  contactAttemptsCount: number;
  contactSuccessCount: number;
  emailsSentCount: number;
  smsSentCount: number;
  uniqueLeadsContactedCount: number;
  leadsConvertedCount: number;
}

export interface LeadPerformanceDetailReport {
  labels: string[];
  newLeadsReceived: number[];
  contactAttemptsCount: number[];
  contactSuccessCount: number[];
  emailsSentCount: number[];
  smsSentCount: number[];
  uniqueLeadsContactedCount: number[];
  leadsConvertedCount: number[];
}

export class LeadReportFilterData extends LeadFilters {
  referralSourceId: number;
  leadsource: string;

  filterDisplayName: string;
}
