import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GovernmentLoanDetail, Mortgage} from 'src/app/models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {Constants} from 'src/app/services/constants';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {MortgageService} from 'src/app/services/mortgage.service';
import {NotificationService} from 'src/app/services/notification.service';
import {UrlaMortgage} from '../../models/urla-mortgage.model';
import * as _ from 'lodash';
import {NgxSpinnerService} from 'ngx-spinner';
import {sum} from '../../../../shared/utils/math-utils';
import {MiOrFundingFeeUtils} from '../../../../shared/utils/mortgage/mi-or-funding-fee-utils';

@Component({
  selector: 'mortgage-insurance-calculation-dialog',
  templateUrl: './mortgage-insurance-calculation-dialog.component.html',
  styleUrls: ['./mortgage-insurance-calculation-dialog.component.scss']
})
export class MortgageInsuranceCalculationDialogComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = _.cloneDeep(mortgage);
    if (!this._mortgage.governmentLoanDetail) {
      this._mortgage.governmentLoanDetail = new GovernmentLoanDetail();
    }
    this.onFinanceAllChanged();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  calculationBasedOnOptions: EnumerationItem[] = [];

  protected miOrFundingFeeAmount: number = 0;
  private invalidateMiOrFundingFeeAmountField: (mortgage: Mortgage) => void
    = MiOrFundingFeeUtils.invalidateMiOrFundingFeeAmountField.bind(this);

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
  ) {
  }

  miFactorFieldLocked: boolean = false;

  locked: boolean = false;

  private _mortgage: UrlaMortgage;

  ngOnInit() {
    this._enumsService.getFeeEnumerations().subscribe(result => {
      this.calculationBasedOnOptions = result[Constants.enumerations.feePercentOfFieldType];
    });
  }

  onIsPrepaidChanged = () => {
    if (!this._mortgage.mortgageInsuranceDetail.isMIPrepaid) {
      this._mortgage.mortgageInsuranceDetail.prepaidPremiumTotal = null;
    }
  }

  onBaseLoanAmountChanged = () => {
    const amount = !this._mortgage.mortgageTerm.amount ? 0 : Number(this._mortgage.mortgageTerm.amount);
    const miOrFundingFeeFinancedAmount = !this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount ? 0 : Number(this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount);
    this._mortgage.mortgageTerm.totalLoanAmount = amount + miOrFundingFeeFinancedAmount;
    if (amount) {
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent =
        parseFloat(((this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount / amount) * 100).toFixed(3));
    }
  }

  protected onFinanceAllChanged(): void {
    this.invalidateMiOrFundingFeeAmountField(this._mortgage);
    this.onMiOrFundingFeeFinancedAmountChanged(this.miOrFundingFeeAmount);
  }

  protected onMiOrFundingFeeFinancedAmountChanged(value: number): void {
    this.miOrFundingFeeAmount = Math.floor(value);

    const mortgage = this._mortgage;
    const mortgageTerm = mortgage.mortgageTerm;

    mortgageTerm.totalLoanAmount = sum(
      mortgageTerm.amount,
      value,
    );
  }

  onMiOrFundingFeeTotalAmountChanged = () => {
    if (!this.locked) {
      return;
    }

    if (!this._mortgage.mortgageInsuranceDetail.miOrFundingFeeUpFrontAmount) {
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeUpFrontAmount = 0;
    }
    this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount =
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount - this._mortgage.mortgageInsuranceDetail.miOrFundingFeeUpFrontAmount;

    if (this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount)
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount = Math.floor(this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount);

    if (this._mortgage.mortgageTerm.amount) {
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent =
        parseFloat(((this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount / this._mortgage.mortgageTerm.amount) * 100).toFixed(3));
    }
  }

  onMiOrFundingFeeTotalPercentChanged = () => {
    if (this.locked) {
      return;
    }
    if (this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent) {
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount =
        (this._mortgage.mortgageTerm.amount * this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent) / 100;
    }
  }

  onMiOrFundingFeeUpFrontAmountChanged = () => {
    this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount = sum(
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount,
      -this._mortgage.mortgageInsuranceDetail.miOrFundingFeeUpFrontAmount,
    );

    if (this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount)
      this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount = Math.floor(this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount);
  }

  onOkClicked = () => {
    MiOrFundingFeeUtils.trySetEntireMiOrFundingFee(this._mortgage.mortgageInsuranceDetail);
    this.activeModal.close(this._mortgage);
  };

  calculateMi = (): void => {
    this._spinner.show();
    this._mortgageService.calculateMortgageInsurance(this._mortgage).subscribe({
      next: result => {
        this._mortgage.mortgageTerm.totalLoanAmount = result.totalLoanAmount;
        this._mortgage.mortgageInsuranceDetail.level1MonthlyAmount = result.level1MonthlyAmount;
        this._mortgage.mortgageInsuranceDetail.level2MonthlyAmount = result.level2MonthlyAmount;
        this._mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount = result.miOrFundingFeeFinancedAmount;
        this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount = result.miOrFundingFeeTotalAmount;
        this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent = result.miOrFundingFeeTotalPercent;
        this._mortgage.mortgageInsuranceDetail.prepaidPremiumTotal = result.prepaidPremiumTotal;
      },
      error: error => {
        this._notifsService.showError(
          error ? error.message : 'Unable to get Current Loan Info',
          'Error!'
        );
      }
    }).add(() => this._spinner.hide());
  }
}
