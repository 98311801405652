import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { SelectAgentTypeDialogComponent } from 'src/app/modules/remain-contact/components/remain-contact/select-agent-type-dialog/select-agent-type-dialog.component';
import { RemainContactService } from 'src/app/modules/remain-contact/services/remain-contact.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { AgentsService } from '../../services/agents.service';
import { ISaveAgentEvent } from 'src/app/modules/agent-info/agent-info.component';

@Component({
  selector: 'insert-agent-for-external-contact',
  templateUrl: '../upsert-agent/upsert-agent.component.html',
  styleUrls: ['../upsert-agent/upsert-agent.component.scss']
})
export class InsertAgentForExternalContactComponent implements OnInit {

  loading: boolean;

  agentFull: AgentFull;

  tab: 'agentInfo' | 'history' | 'notes';

  appId: number;

  @Input()
  isDrawer: boolean = false;

  constructor(private readonly route: ActivatedRoute,
    private readonly _agentsService: AgentsService,
    private readonly _router: Router,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _remainContactService: RemainContactService) { }

  ngOnInit(): void {
    this.appId = Number(this.route.snapshot.paramMap.get('appId'));
    this.tab = 'agentInfo';
    this.loading = true;
    this._agentsService.getAgent(0).subscribe({
      next: (response) => {
        this.agentFull = response;
        this.loading = false;
      }, error: (err) => {
        this.loading = false;
        this._notifyService.showError(err ? err.message || err : '', 'Error');
        this._router.navigate(['admin/agents']);
      }
    });
  }

  closeWindow = () => {
    this._router.navigate(['admin/remain-contact/' + this.route.snapshot.params.appId]);
  }

  savedAgent = (event: ISaveAgentEvent) => {
    this._spinner.show();
    event.agent.appId = this.appId;
    this._agentsService.saveAgent(event.agent, event.suppressInvite).subscribe({
      next: (response) => {
        this._spinner.hide();
        this.agentFull = response;
        this._notifyService.showSuccess('Successful!', 'Save Success');
        if (event.isClosedAfterSave) {
          this.viewSelectAgentTypeModal(response.agent.agentId);
        }
      }, error: (err) => {
        this._spinner.hide();
        this._notifyService.showError('Error! ' + err ? err.message || err : '', 'Save Fail');
      }
    });
  }

  viewSelectAgentTypeModal = (agentId: number) => {
    const modalRef = this._modalService.open(SelectAgentTypeDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.agentId = agentId;
    modalRef.componentInstance.selectingForConversion = false;
    modalRef.componentInstance.showRedirectToLoanButton = false;

    modalRef.result.then((result) => {
      if (result != 'cancel') {
        this._remainContactService.selectAgentForExternalContact(this.appId, agentId, result.associateAgentAs)
          .subscribe({
            next: (response) => {
              this._notifyService.showSuccess("Select External Contact Success", "Successful!");
              this.closeWindow();
            }, error: (err) => {
              this._notifyService.showError(err ? err.message || err : "Select External Contact Fail", "Error!");
              this.closeWindow();
            }
          });
      } else {
        this._notifyService.showError("Agent Not Associated to Loan", "Warning!");
        this.closeWindow();
      }
    }, (err) => {
      this._notifyService.showError("Agent Not Associated to Loan", "Warning!");
      this.closeWindow();
    });

  }

}
