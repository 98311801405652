import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formViewProvider } from '../../../../core/services/form-view.provider';
import { CalculatedFeeTypeEnum } from 'src/app/models/fee/calculated-fee-type.enum';
import { LoanFee } from 'src/app/models/fee/fee.model';

@Component({
  selector: 'generic-fees',
  templateUrl: 'generic-fees.component.html',
  styleUrls: ['../../loan-fees.component.scss'],
  viewProviders: [formViewProvider]
})

export class GenericFeesComponent implements OnInit {

  @Input() fees: LoanFee[];
  @Input() deleteAllowed: boolean = true;
  @Input() showImpound: boolean = false;
  @Input() allowLockStatusChange: boolean = true;

  @Output() onFeeRemoved = new EventEmitter<LoanFee>();
  @Output() onFeeUpdated = new EventEmitter<LoanFee>();
  @Output() openFeeEditor = new EventEmitter<LoanFee>();

  pendingDeleteIndex = -1;

  constructor() { }

  defaultFeeType: CalculatedFeeTypeEnum = CalculatedFeeTypeEnum.Default;
  monthlyFeeType: CalculatedFeeTypeEnum = CalculatedFeeTypeEnum.MonthlyPaymentPremium;
  upFrontMonthlyPremium: CalculatedFeeTypeEnum = CalculatedFeeTypeEnum.UpFrontMonthlyPremium;

  ngOnInit() {
    // fix existing records
    if(this.fees && this.fees.length && this.showImpound){
      this.fees.forEach(fee => {
        this.adjustImpount(fee);
        this.adjustMonths(fee);
      })
    }
  }

  removeFee(fee: LoanFee) {

    this.pendingDeleteIndex = -1;
    this.onFeeRemoved.next(fee);
  }

  onMonthlyChanged(fee: LoanFee) {
    if (!!fee.calculatedValues.isChargeUserEditable) {
      this.onFeeUpdated.emit(fee);
    }
  }

  onFeeEditorOpened = (fee: LoanFee) => {
    this.openFeeEditor.emit(fee);
  }

  onMonthsChanged(fee: LoanFee) {
    this.adjustImpount(fee);

    if (!!fee.calculatedValues.isChargeUserEditable) {
      this.onFeeUpdated.emit(fee);
    }
  }

  onSellerConcessionChange(fee: LoanFee) {
    this.onFeeUpdated.emit(fee);
  }

  onBorrowerFeeDollarChanged(fee: LoanFee) {
    if (!!fee.calculatedValues.isChargeUserEditable && !!fee.isChargeOverridden) {
      this.onFeeUpdated.emit(fee);
    }
  }

  onLockStatusChanged = (fee: LoanFee) => {
    fee.isChargeOverridden = !fee.isChargeOverridden;
    this.onFeeUpdated.emit(fee);
  }

  onIsImpoundChanged = (fee: LoanFee) => {
    this.adjustMonths(fee);

    if (!!fee.calculatedValues.isChargeUserEditable) {
      this.onFeeUpdated.emit(fee);
    }
  }

  private adjustImpount = (fee: LoanFee) => {
    if (fee.calculatedFeeType == this.monthlyFeeType && this.showImpound) {
      if (fee.calculatedValues.months > 0) {
        fee.isImpound = true;
      }
      else {
        fee.isImpound = false;
      }
    }
  }

  private adjustMonths = (fee: LoanFee) => {
    if (fee.calculatedFeeType == this.monthlyFeeType && this.showImpound) {
      if (fee.isImpound) {
        if (!fee.calculatedValues.months) {
          fee.calculatedValues.months = 2;
        }
      }
      else {
        fee.calculatedValues.months = 0;
      }
    }
  }
}
