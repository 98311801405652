import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME,
} from 'ng-wizard';
import { of } from 'rxjs';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { ProcessSubmissionModel } from '../../models/process-submission.model';
import { NewApplicationService } from '../../services/new-application.service';
import { forkJoin } from 'rxjs';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { NotificationService } from 'src/app/services/notification.service';
import { ChannelEnum } from 'src/app/models';
import { AdminService } from 'src/app/services/admin.service';
import { CheckListQuestion, CheckListService } from 'src/app/services/check-list.service';
import { CheckListItemEvalDialogComponent } from 'src/app/modules/admin/checklists/dialogs/check-list-item-eval-dialog/check-list-item-eval-dialog.component';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.scss'],
})
export class NewApplicationComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output()
  newAppCreatedSuccessfully = new EventEmitter<boolean>();

  @Input()
  scenarioId: number;

  duFiles: FileList;

  processSubmissionData: ProcessSubmissionModel;

  allowedLoanCharacterstics: any = [];
  allowedBorrowerCharacterstics: any = [];

  useDynamicCompression: boolean = true;
  isImportFromAusAllowed: boolean;
  isCorrSearchForCreditOnSubmissionEnabled: boolean;

  isTpoUser = true;

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: false,
      // markAllPreviousStepsAsDone: true
    },
  };

  isValidTypeBoolean: boolean = true;
  showMissingInfo: boolean;
  showKeyDates: boolean;

  isHomesiteHack = false;
  loadMissingInfo: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _checkListService: CheckListService,
    private readonly _ngWizardService: NgWizardService,
    private readonly _newApplicationService: NewApplicationService,
    private readonly _navigationService: NavigationService,
    private readonly _loanDocService: LoanDocService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _adminService: AdminService,
  ) {
    super(injector);

    this.isTpoUser = this.applicationContext.isTpo;
    this.isHomesiteHack = this.applicationContext.userPermissions.companyId == 158;

    if (!this.applicationContext.userPermissions.showNewSubmissionForm && !this.applicationContext.userPermissions.superAdmin) {
      if (this.applicationContext.isTpo) {
        this._navigationService.navigateToPath('/tpo/dashboard');
      } else {
        if (this._navigationService.applicationMode == ApplicationMode.Classic) {
          this._navigationService.navigateToPath('/admin/dashboardv2');
        }
        if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
          this._navigationService.navigateToPath('/loda-nextgen/dashboard');
        }
      }
    }
  }

  ngOnInit(): void {
    this._getTpoConfig();
  }

  showPreviousStep(event?: Event) {
    this._ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this._ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this._ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this._ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {

  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }

  duFile = (file: FileList) => {
    this.duFiles = file;
  };

  backToUploadFile = () => {
    this.loadMissingInfo = false;
    this.showMissingInfo = false;
    this.showKeyDates = false;
  }

  nextStep = ({
    processSubmissionData,
    step
  }: {
    processSubmissionData: ProcessSubmissionModel,
    step?: string
  }) => {
    this.processSubmissionData = processSubmissionData;

    if (step === 'uploadFile') {
      this.allowedLoanCharacterstics = this._filterCharacteristicsByChannel(this.processSubmissionData.channel, 'Loan');
      this.allowedBorrowerCharacterstics = this._filterCharacteristicsByChannel(this.processSubmissionData.channel, 'Borrower');

      if (this.applicationContext.userPermissions.companyId == 128 && this.isTpoUser === true) {
        this.processSubmissionData.loanTypeId = "";
      }
    }
    if (step === 'reviewData') {
      this.loadMissingInfo = true;
    }

    this.showKeyDates = ( this.processSubmissionData.channel !== ChannelEnum.Wholesale || !this.isTpoUser || this.applicationContext.userPermissions.companyId == 229 || this.applicationContext.userPermissions.companyId == 276) &&
      this.processSubmissionData.loanData?.keyDateValues && Object.keys(this.processSubmissionData.loanData.keyDateValues).length > 0;

    const willCheckIfCreditRefsMissing =
			this.processSubmissionData.channel !== ChannelEnum.Correspondent ||
			(this.processSubmissionData.channel === ChannelEnum.Correspondent && this.isCorrSearchForCreditOnSubmissionEnabled);

    this.showMissingInfo =
      !!this.processSubmissionData.mortgageViewModel &&
        !!this.processSubmissionData.loanData?.creditInfo &&
      (
        this.showKeyDates ||
        !this.processSubmissionData.mortgageViewModel.subjectProperty.noOfUnits ||
        (willCheckIfCreditRefsMissing && this.processSubmissionData.loanData.mortgage.borrowers.some(b => !b.creditRefNumber))
      );

    this.showNextStep();
  };

  submit = (processSubmissionData: ProcessSubmissionModel) => {
    this._spinner.show();

    this.processSubmissionData = processSubmissionData;

    let loanCharacteristics = [];
    const borrowerCharacteristics = [];

    if (this.processSubmissionData.loanCharacteristics) {
      loanCharacteristics = this.processSubmissionData.loanCharacteristics.map(lc => ({ characteristicId: lc }));
    }

    if (this.processSubmissionData.borrowerCharacteristics) {
      this.processSubmissionData.borrowerCharacteristics
        .filter(bc => bc.selectedCharacteristics)
        .forEach(bc => {
          bc.selectedCharacteristics.forEach(sc => {
            borrowerCharacteristics.push({ borrowerId: bc.borrowerId, characteristicId: sc });
          });
        });
    }

    const linkBorrowers = {};
    this.processSubmissionData.linkedBorrowers.forEach((linkedBorrower) => {
      if (!linkedBorrower.borrower) {
        return;
      }
      linkBorrowers[linkedBorrower.index] = linkedBorrower.borrower.borrowerId;
    });

    const data = {
      credentialId: this.processSubmissionData.credentialId,
      mortgageViewModel: this.processSubmissionData.mortgageViewModel,
      loanData: this.processSubmissionData.importMismo ? this.processSubmissionData.loanData : null,
      linkBorrowers,
      loanCharacteristics: loanCharacteristics,
      borrowerCharacteristics: borrowerCharacteristics,
      loanPurposeId: this.processSubmissionData.loanPurposeId,
      loanTypeId: this.processSubmissionData.loanTypeId,
      loanNumber: this.processSubmissionData.loanNumber,
      channel: this.processSubmissionData.channel ? this.processSubmissionData.channel : undefined,
      referralSource: this.processSubmissionData.referralSource ? this.processSubmissionData.referralSource : undefined,
      referredTo: this.processSubmissionData.referredTo ? this.processSubmissionData.referredTo : undefined,
      secondaryReferralSource: this.processSubmissionData.secondaryReferralSource ? this.processSubmissionData.secondaryReferralSource : undefined,

      referralSourceType: this.processSubmissionData.referralSourceType ? this.processSubmissionData.referralSourceType : undefined,
      secondaryReferralSourceType: this.processSubmissionData.secondaryReferralSourceType ? this.processSubmissionData.secondaryReferralSourceType : undefined,

      insideReferralSource: this.processSubmissionData.insideReferralSource ? this.processSubmissionData.insideReferralSource : undefined,
      losIdentifier: this.processSubmissionData.losIdentifier,
      primaryRoleUserId: this.processSubmissionData.primaryRoleUserId ? this.processSubmissionData.primaryRoleUserId : undefined,
      additionalAlignmentRoleUsers: this.processSubmissionData.additionalAlignmentRoleUsers ? this.processSubmissionData.additionalAlignmentRoleUsers : undefined,
      scenarioId: this.scenarioId,
      keyDateValues: this.processSubmissionData.keyDateValues
        ? this.processSubmissionData.keyDateValues
        : undefined,
      loanFees: this.processSubmissionData.loanFees
        ? this.processSubmissionData.loanFees
        : undefined,
    };

    this._newApplicationService.processSubmission(this.processSubmissionData.importMismo, this.processSubmissionData.submissionViaFile, data)
      .subscribe((response) => {
        this.newAppCreatedSuccessfully.emit();
        this.closeDrawer.emit();
        this._notifyService.showSuccess('New File Submitted Successfully', 'Submit Success');
        if (!this.processSubmissionData.submissionViaFile || this.processSubmissionData.sanitizeLoanData) {
          return this._finishProcesss(response);
        }
        var loanDoc = {
          loanDocId: null,
          applicationId: response.applicationId,
          borrowerID: null,
          documentTypeId: 0,
          description: 'New submission ' + (this.processSubmissionData.importMismo ? 'MISMO' : 'DU') + ' file',
          note: 'New submission ' + (this.processSubmissionData.importMismo ? 'MISMO' : 'DU') + ' file',
          expirationDate: null,
          retask: false,
          active: true,
          docFiles: null
        };
        this._loanDocService.upsertLoanDoc(loanDoc).subscribe((loanDocResponse) => {
          const promises = [];

          if (this.duFiles) {
            Array.from(this.duFiles).forEach(file => {
              promises.push(this._newApplicationService.uploadFileForLoanDoc(file, loanDocResponse.loanDocId, false, false, this.useDynamicCompression));
            });
          }

          if (promises.length === 0) {
            this._finishProcesss(response);
          } else {
            forkJoin(promises).subscribe(() => {
              this._notifyService.showSuccess('Loan docs saved successfully.', 'Success');
              this._finishProcesss(response);
            }, (error) => {
              this._spinner.hide();
              this._notifyService.showError(error ? error.message || error : 'Errors have been detected.', 'Failure');
            });
          }
        }, (error) => {
          this._spinner.hide();
          this._notifyService.showError(error ? error.message || error : 'An error occurred saving loan docs.', 'Failure');
        });
      }, (error) => {
        this._spinner.hide();
        const errorMessage = (error && error.message) ? error.message : 'An error occurred while submitting new file.';
        this._notifyService.showError(errorMessage, 'Error!');
      });

  };

  private _filterCharacteristicsByChannel = (channel: string, type: 'Loan' | 'Borrower'): Array<any> => {
    if (type === 'Loan') {
      if (this.isTpoUser) {
        return this.applicationContext.globalConfig.loanCharacteristics
          .filter(lc => lc.tpoEnabled && lc.newSubmissionEnabled && lc.enabledChannels && lc.enabledChannels.includes(channel))
          .map(lc => ({ ...lc, isSelected: false }));
      } else {
        return this.applicationContext.globalConfig.loanCharacteristics
          .filter(lc => lc.newSubmissionEnabled && lc.enabledChannels && lc.enabledChannels.includes(channel))
          .map(lc => ({ ...lc, isSelected: false }));
      }
    }
    if (type === 'Borrower') {
      if (this.isTpoUser) {
        return this.applicationContext.globalConfig.borrowerCharacteristics
          .filter(bc => bc.tpoEnabled && bc.newSubmissionEnabled && bc.enabledChannels && bc.enabledChannels.includes(channel));
      } else {
        return this.applicationContext.globalConfig.borrowerCharacteristics
          .filter(bc => bc.newSubmissionEnabled && bc.enabledChannels && bc.enabledChannels.includes(channel));
      }
    }
    return [];
  };

  private _finishProcesss = (appDetails: { applicationId: number, loanStatusId: number, primaryBorrowerId: number }) => {
    this._checkListService.getChecklistQuestionsForApplicationAndStatus(appDetails.loanStatusId, appDetails.applicationId, true)
      .subscribe({
        next: (questions) => {
          if (questions && questions.length > 0) {
            this.showCheckListEvalModal(appDetails.applicationId, appDetails.primaryBorrowerId, questions);
            this._spinner.hide();
          } else {
            this.navigateToApp(appDetails.applicationId);
          }
        }, error: (err) => {
          this._notifyService.showError(err ? err.message : 'Unable to get checklist.', 'Error!');
          this.navigateToApp(appDetails.applicationId);//continue with the flow if checklist fails.
        }
      });

  };

  private navigateToApp(applicationId: number) {
    if (this.isTpoUser) {
      this._spinner.hide();
      this._navigationService.navigateToPath(`/tpo/app-details/${applicationId}/loan-summary`);
    } else {
      this._spinner.hide();
      this._navigationService.navigateToPath(`/admin/app-details/${applicationId}`);
    }
  }

  private showCheckListEvalModal = (applicationId: number, primaryBorrowerId: number, checklistQuestions: Array<CheckListQuestion>) => {
    let modalRef = this._modalService.open(CheckListItemEvalDialogComponent, {
      ...Constants.modalOptions.large
    });
    const borrowerNamesById = new Map<number, string>();
    borrowerNamesById.set(primaryBorrowerId, '');
    modalRef.componentInstance.checklistQuestions = checklistQuestions;
    modalRef.componentInstance.applicationId = applicationId;
    modalRef.componentInstance.borrowerNameById = borrowerNamesById;
    modalRef.componentInstance.isEvalTest = false;
    modalRef.result.then((allQuestionAnswered: boolean) => {
      if (allQuestionAnswered) {
        this.navigateToApp(applicationId);
      }
    }, () => {
    });
  }

  private _getTpoConfig = () => {
    this._spinner.show();

    this._adminService.getTpoConfiguration().subscribe(result => {
      this.isImportFromAusAllowed = this.isTpoUser && result.isImportFromAusAllowed;
      this.isCorrSearchForCreditOnSubmissionEnabled = !!result.corrSearchForCreditOnSubmissionEnabled;
    }).add(() => this._spinner.hide());

  }
}
