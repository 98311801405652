import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Observable, Observer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { ContactList, ContactListColumnDefinition, ContactListType } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ExpressionBuilderDialog } from 'src/app/modules/expression-builder/expression-builder-dialog.component';
import { ExpressionBuilderDialogService } from 'src/app/modules/expression-builder/services/expression-builder-dialog.service';
import { ExpressionService } from 'src/app/modules/expression-builder/services/expression.service';
import { ContactListService } from 'src/app/services/contact-list.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';

@Component({
  selector: 'fee-template-details',
  templateUrl: './fee-template-details.component.html',
  styleUrls: ['./fee-template-details.component.scss'],
  viewProviders: [formViewProvider],
})
export class FeeTemplateDetailsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  feeTemplate: FeeTemplate;

  selectedRoleChannels: EnumerationItem[] = [];
  enabledChannels: EnumerationItem[] = [];

  multiSelectSettings: IDropdownSettings = {
    idField: 'name',
    textField: 'name',
    allowSearchFilter: true,
    clearSearchFilter: true,
    enableCheckAll: true
  }

  globalConfig: GlobalConfig;

  isLoading: boolean = false;
  expressionHTML: string;
  expressionGroup: ExpressionGroup;
  expressionColumns: ContactListColumnDefinition[] = [];
  selectedContactListId: number;
  companyId: number;

  constructor(
    private readonly _feeService: FeeService,
    private readonly _expressionService: ExpressionBuilderDialogService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _contactListService: ContactListService,
    private readonly _enumsService: EnumerationService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getLoadedRoleChannels();

    this.applicationContextService.context.subscribe(context => {
      this.globalConfig = context.globalConfig;
      this.companyId = context.userPermissions.companyId;
      this.enabledChannels = context.globalConfig.enabledChannels;
      this.getExpressionData(context.globalConfig);
    });
  }

  getExpressionData = (globalConfig: GlobalConfig) => {
    if (this.feeTemplate.expressionId) {
      this.isLoading = true;
      return this._expressionService.getExpressionGroup(this.feeTemplate.expressionId)
        .pipe(
          mergeMap(group => {
            this.expressionGroup = group;
            this.selectedContactListId = group.contactListId;
            return this._feeService.getContactListColumnsForExpressionBuilder()
          }),
          finalize(() => {
            this.isLoading = false;
          }))
        .subscribe({
          next: (columns) => {
            this.expressionColumns = columns;
            this.expressionHTML = new ExpressionService(globalConfig, this._enumsService).getExpressionHtml(this.expressionGroup, this.expressionColumns);
          },
          error: ({ error }) => {
            this._notifyService.showError(
              error?.message || "Couldn't get expression field list",
              'Expression Groups'
            )
          }
        })
    } else {
      this.isLoading = true;
      this._contactListService.getContactList().subscribe({
        next: (response: ContactList[]) => {
          const contactList = response.find(contact => contact.contactListType === ContactListType.Application && contact.isCustom === false);
          this.selectedContactListId = contactList?.contactListId;
          this.expressionColumns = this._contactListService.setContactListColumns(contactList?.columns);
        },
        error: ({ error }) => {
          this._notifyService.showError(
            error?.message || "Couldn't get expression field list",
            'Expression Groups'
          )
        }
      }).add(() => this.isLoading = false)
    }
  }

  getLoadedRoleChannels = () => {
    let loaded = this.feeTemplate.enabledChannels ? this.feeTemplate.enabledChannels.trim() : "";

    let channels = [];
    if (loaded && loaded != "None") {
      let parts = loaded.split(",");
      parts.forEach(enumValue => {
        enumValue = enumValue.trim();
        let enumName = this.getEnumName(enumValue);
        if (enumName && enumName != "Disabled") {
          channels.push({ name: enumName, value: enumValue });
        }
      })
    }

    this.selectedRoleChannels = channels;
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" ? String(enumValue.trim()) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  onRoleChannelChanged = () => {
    this.feeTemplate.enabledChannels = this.selectedRoleChannels.length > 0 ? this.selectedRoleChannels.map(c => c.name).join() : "Disabled";
  }

  onEditExpressionClicked = () => {
    const modalRef = this._modalService.open(ExpressionBuilderDialog, {
      size: 'xl',
      backdrop: 'static',
      centered: true
    });

    modalRef.componentInstance.expressionBuilderData = {
      expressionGroup: this.expressionGroup,
      fields: this.expressionColumns,
      expressionGroupType: 'include',
      allowExpressionChecker: true,
      isCustomContactList: false,
      contactListId: this.selectedContactListId
    };
    modalRef.componentInstance.companyId = this.companyId;

    modalRef.componentInstance.saveExpression.subscribe((newExpressionGroup) => {
      if (!newExpressionGroup) {
        this.expressionGroup = null;
        this.expressionHTML = "";
        modalRef.close();
      } else {
        this.expressionGroup = newExpressionGroup;
        this.expressionHTML = new ExpressionService(this.globalConfig, this._enumsService).getExpressionHtml(this.expressionGroup, this.expressionColumns);
        modalRef.close();
      }
    });

    modalRef.componentInstance.deleteExpression.subscribe(() => {
      if (!this.expressionGroup?.expressionGroupId) {
        modalRef.close();
        return;
      }

      this.expressionGroup = null;
      this.expressionHTML = "";

      modalRef.close();
    })
  }

  public saveExpression = (): Observable<any> => {
    return this._expressionService.saveExpressionGroups(this.expressionGroup);
  }

  public deleteExpression = (expressionGroupId: number): Observable<any> => {
    return this._expressionService.deleteExpression(expressionGroupId);
  }

  public setExpressionHtml = (expression: ExpressionGroup) => {
    this.expressionHTML = new ExpressionService(this.globalConfig, this._enumsService).getExpressionHtml(expression, this.expressionColumns);
  }
}
