<div class="modal-header">
  <h5 class="modal-title">QuickInsured Order</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" id="quickInsuredOrder">
  <form id="quickInsuredOrderFrom" name="quickInsuredOrderForm" class="form-horizontal">
    <div class="row section alert alert-danger" style="margin-bottom: 10px" *ngIf="errors.length">
      <div class="col-md-12">
        <div class="row head">
          <div class="col-md-12">Error(s)</div>
          <div class="col-md-12">
            <hr style="margin: 3px" />
          </div>
        </div>
        <div class="row details" *ngFor="let error of errors">
          <div class="col-md-12">{{error}}</div>
        </div>
      </div>
    </div>
    <fieldset>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Home Construction Materials</label>
        <div class="col-md-6">
          <select class="form-select" name="homeConstructionMaterials" required
            [(ngModel)]="order.homeConstructionMaterials">
            <option value="Frame">Frame</option>
            <option value="Masonry">Masonry</option>
            <option value="Masonry Veneer">Masonry Veneer</option>
            <option value="Fire Resistant">Fire Resistant</option>
            <option value="Log">Log</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Roofing Type</label>
        <div class="col-md-6">
          <select class="form-select" name="roofingType" required [(ngModel)]="order.roofingType">
            <option value="Composition Shingle" selected>Composition Shingle</option>
            <option value="Architectural Shingle">Architectural Shingle</option>
            <option value="Composition over Wood">Composition over Wood</option>
            <option value="Metal">Metal</option>
            <option value="Slate">Slate</option>
            <option value="Plexiglass">Plexiglass</option>
            <option value="Wood">Wood</option>
            <option value="Tile">Tile</option>
            <option value="Tar and Gravel">Tar and Gravel</option>
            <option value="Roll">Roll</option>
            <option value="Rubber">Rubber</option>
            <option value="Plastic">Plastic</option>
            <option value="Fiberglass">Fiberglass</option>
            <option value="Foam">Foam</option>
            <option value="All Other">All Other</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Purchase Month</label>
        <div class="col-md-6">
          <input type="number" numeric [allowNegative]="false" name="purchaseMonth" class="form-control numeric-without-arrows" [(ngModel)]="order.purchaseMonth" required>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Purchase Year</label>
        <div class="col-md-6">
          <input type="number" numeric [allowNegative]="false" name="purchaseYear" class="form-control numeric-without-arrows" [(ngModel)]="order.purchaseYear" required>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Year Built</label>
        <div class="col-md-6">
          <input type="number" numeric [allowNegative]="false" name="yearBuilt" class="form-control numeric-without-arrows" [(ngModel)]="order.yearBuilt" required>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Number of Families</label>
        <div class="col-md-6">
          <input type="number" numeric [allowNegative]="false" name="numberOfFamilies" class="form-control numeric-without-arrows" [(ngModel)]="order.numberOfFamilies"
            required>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Living Area</label>
        <div class="col-md-6">
          <input type="number" numeric [allowNegative]="false" name="livingArea" class="form-control numeric-without-arrows" [(ngModel)]="order.livingArea">
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-4 form-label mb-lg-0 text-end align-self-center">Number of Stories</label>
        <div class="col-md-6">
          <input type="number" numeric [allowNegative]="false" name="numberOfStories" class="form-control numeric-without-arrows" [(ngModel)]="order.numberOfStories">
        </div>
      </div>
    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" style="text-decoration: none;"
    (click)="activeModal.close('cancel')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="createOrder()">Submit</button>
</div>
