import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2,
  inject,
} from '@angular/core';

@Directive({
  selector: '[ellipsis]'
})
export class EllipsisDirective implements AfterViewInit, OnDestroy {
  private readonly _renderer = inject(Renderer2);
  private readonly _element: ElementRef<HTMLElement> = inject(ElementRef);

  private _resizeObserver: ResizeObserver;

  @HostListener('window:resize')
  onResize() {
    this._applyEllipsis();
  }

  constructor() {
    this._resizeObserver = new ResizeObserver(() => this._applyEllipsis());
  }

  ngAfterViewInit(): void {
    this._applyEllipsis();
    this._resizeObserver.observe(this._element.nativeElement);
  }

  ngOnDestroy(): void {
    this._resizeObserver.disconnect();
  }

  private _applyEllipsis() {
    const element = this._element.nativeElement as HTMLElement;

    if (element.scrollWidth > element.clientWidth) {
      this._setEllipsisStyling(element);
    } else {
      this._removeEllipsisStyling(element);
    }
  }

  private _setEllipsisStyling(element: HTMLElement) {
    this._renderer.setStyle(element, 'white-space', 'nowrap');
    this._renderer.setStyle(element, 'text-overflow', 'ellipsis');
    this._renderer.setStyle(element, 'overflow', 'hidden');

    element.setAttribute('title', element.innerText);
  }

  private _removeEllipsisStyling(element: HTMLElement) {
    this._renderer.removeStyle(element, 'white-space');
    this._renderer.removeStyle(element, 'text-overflow');
    this._renderer.removeStyle(element, 'overflow');

    element.removeAttribute('title');
  }
}
