<div class="account-body accountbg">
  <div class="container">
    <div class="row vh-100 d-flex justify-content-center">
      <div class="col-12 align-self-center">
        <div class="row">
          <div class="col-lg-5 mx-auto">
            <div class="card">
              <div class="card-body p-0 auth-header-box">
                <div class="text-center p-3">
                  <a href="index.html" class="logo logo-admin">
                    <img src="assets/images/loda-logo.png" height="50" alt="logo" class="auth-logo">
                  </a>
                </div>
              </div>
              <div class="card-body company-name-container">
                <div class="tab-content">
                  <div class="d-flex justify-content-center mt-4">
                    <h4 class="mt-0 font-15 text-center">Switching to Company {{company.companyName}}, please wait...</h4>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="text-center">
                      <i class="la la-cog la-spin progress-icon-spin" style="font-size: 56px;"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body bg-light-alt text-center">
                <span class="text-muted d-none d-sm-inline-block">Lodasoft ©
                  <script>
                    { { document.write(new Date().getFullYear()) } }
                  </script>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
