import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drawing-pad-dialog',
  templateUrl: 'drawing-pad-dialog.component.html',
  styleUrls: ['drawing-pad-dialog.component.scss'],
})
export class DrawingPadDialogComponent implements AfterViewInit {

  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @Input() title: string = "E-Signature";

  public context: CanvasRenderingContext2D;

  // last known position
  pos = { x: 0, y: 0 };

  constructor(public activeModal: NgbActiveModal) {

  }

  ngAfterViewInit(): void {
    this.context = this.canvas.nativeElement.getContext('2d');
    this.resize();
  }

  mousemove = (e) => {
    this.draw(e);
  }

  mousedown = (e) => {
    this.setPosition(e);
  }

  mouseenter = (e) => {
    this.setPosition(e);
  }

  draw = (e) => {
    // mouse left button must be pressed
    if (e.buttons !== 1) return;

    this.context.beginPath(); // begin

    this.context.lineWidth = 20;
    this.context.lineCap = 'round';
    this.context.strokeStyle = 'black';

    this.context.moveTo(this.pos.x, this.pos.y); // from
    this.setPosition(e);
    this.context.lineTo(this.pos.x, this.pos.y); // to

    this.context.stroke(); // draw it!
  }

  resize = () => {
    this.context.canvas.width = window.innerWidth;
    this.context.canvas.height = window.innerHeight;
  }

  setPosition(e) {

    let rect = this.canvas.nativeElement.getBoundingClientRect();

    let scaleX = this.canvas.nativeElement.width / rect.width;
    let scaleY = this.canvas.nativeElement.height / rect.height;

    this.pos.x = (e.clientX - rect.left) * scaleX;
    this.pos.y = (e.clientY - rect.top) * scaleY;
  }

  accept = () => {
    let base64 = this.canvas.nativeElement.toDataURL();
    this.activeModal.close(base64);
  }

  clear = () => {
    this.context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

}
