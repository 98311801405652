<div class="list-group-item mb-2 tree-status hover-terminal">
  <div class="hover-starter"><strong>{{status.displayName}}</strong></div>
    <div id="status_{{status.statusId}}" class="status-sub"
        [ngClass]="{'status-lvl-0--margin': status.level === 0 && status.subordinates.length > 0}"
        [sortablejs]="status.subordinates" [sortablejsOptions]="optionsCopy">
        <div class="list-item sub-status_{{sub.statusId}}" *ngFor="let sub of status.subordinates">
            <tree-sortable-lead-status [options]="optionsCopy" [status]="sub">
            </tree-sortable-lead-status>
            <span
                class="text-danger remove__icon"
                (click)="removeFromTree.emit({ statusId: sub.statusId, parentStatusId: status.statusId })"
            >
                <i class="far fa-trash-alt"></i>
            </span>
        </div>
    </div>
</div>
