import { Injectable } from '@angular/core';
import { EmailService } from 'src/app/services/email.service';
import { LoanService } from 'src/app/services/loan';
import { VoiceService } from 'src/app/services/voice.service';
import { DialerService } from '../../dialer/services/dialer.service';
import { LeadsService } from '../../leads/services/leads.service';

@Injectable({
  providedIn: 'root'
})
export class LoanActivityService {

  constructor(
    private readonly _loanService: LoanService,
    private readonly _emailService: EmailService,
    private readonly _dialerService: DialerService,
    private readonly _leadsService: LeadsService,
    private readonly _voiceService: VoiceService,
    ) { }

  getActivityLogs = (appId: number, losEnabled: boolean) => {
    return this._loanService.getLoanActivityLogs(appId, losEnabled);
  }

  getEmailsSentForApp = (appId: number) => {
    return this._emailService.getEmailsSentForApp(appId);
  }

  postRecordHistory = (data: any) => {
    return this._dialerService.searchDialRecordHistory(data, false);
  }

  getLeadEvents = (leadId: number) => {
    return this._leadsService.getLeadEvents(leadId);
  }

  getLeadRouteHistory = (leadId: number) => {
    return this._leadsService.getLeadRouteHistories(leadId);
  }

  getAllLoanDocs = (appId: number) => {
    return this._loanService.getAllLoanDocsFiltered(appId)
  }

  getEmailDetail = (id: number) => {
    return this._emailService.getEmailDetail(id);
  }

  getRecording = (voiceHistoryId: number) => {
    return this._voiceService.getCallRecording(voiceHistoryId);
  }

  downloadURI = (url) => {
    var request = new XMLHttpRequest();

    request.open('GET', url);
    request.responseType = "arraybuffer";
    request.onload = function (data) {
      var contentDisposition = this.getResponseHeader('content-disposition');
      var contentType = this.getResponseHeader('content-type');
      var filename;
      var file = new Blob([this.response], { type: contentType });
      // For Internet Explorer and Edge
      if ('msSaveOrOpenBlob' in window.navigator) {
        // window.navigator.msSaveOrOpenBlob(file, filename);
      }
      // For Firefox and Chrome
      else {
        // Bind blob on disk to ObjectURL
        let data = URL.createObjectURL(file);
        var a = document.createElement("a");
        a.href = data;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          // Release resource on disk after triggering the download
          window.URL.revokeObjectURL(data);
        }, 100);
      }
    };
    request.send();
  }
}
