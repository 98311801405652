import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, forkJoin } from 'rxjs';
import { MortgageBorrower } from 'src/app/models';
import { BorrowerType } from 'src/app/modules/leads/models/lead-credit.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { BorrowerCreditScores } from '../../models/borrower-credit-scores.model';
import { CreditInquiryDetails } from '../../models/credit-inquiry-details.model';
import { CreditService } from '../../services/credit.service';
import { BorrowerCreditInquiryComponent } from '../borrower-credit-inquiry/borrower-credit-inquiry.component';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'credit-inquiry',
  templateUrl: './credit-inquiry.component.html',
  styleUrls: ['./credit-inquiry.component.scss']
})
export class CreditInquiryComponent implements OnInit {

  @ViewChildren('borrowerCreditInquiry') borrowerComponents: QueryList<BorrowerCreditInquiryComponent> | undefined;

  @Input() mortgage: UrlaMortgage;
  @Output() closewindow: EventEmitter<void> = new EventEmitter<void>();

  borrowers: CreditBorrower[] = [];
  creditScores: BorrowerCreditScores[] = [];

  constructor(
    private readonly _creditService: CreditService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) {
  }

  ngOnInit() {

    if (this.mortgage.borrowers.length) {
      let mainBorrower = this.mortgage.borrowers.filter(b => b.typeOfBorrower == BorrowerType.PrimaryBorrower);
      let coBorrowers = this.mortgage.borrowers.filter(b => b.typeOfBorrower == BorrowerType.CoBorrower);

      this.borrowers = [...mainBorrower, ...coBorrowers];
    }

    this._spinner.show();

    this._creditService.getCreditReportHistory(true, this.mortgage.applicationId).subscribe({
      next: (response) => {
        let allBorrowerCreditScores: BorrowerCreditScores[] = [];
        let scores = response.filter(r => r.refNumber != null).sort((a, b) =>
          (a.refNumber && b.refNumber && Number(a.refNumber) < Number(b.refNumber)) ? 1 : -1);
        this.mortgage.borrowers.forEach(borrower => {
          const borrowerScores = scores.filter(b => b.borrowerId === borrower.contactId);
          if (borrowerScores && borrowerScores.length) {
            allBorrowerCreditScores.push(borrowerScores[0] as unknown as BorrowerCreditScores);
          }
        });
        this.creditScores = allBorrowerCreditScores;
        this.getInquiries();
      },
      error: () => {
        this._spinner.hide();
      }
    });
  }

  close = () => {
    this.closewindow.emit();
  }

  save() {
    let valid = true;
    if (this.borrowerComponents) {
      for (let borrowerComponent of this.borrowerComponents) {
        valid = borrowerComponent.validate() && valid;
      }
    }
    if (valid) {
      // TODO: Here is the POST that Sean wants for document creation
      let calls: Observable<any>[] = [];
      for (let i = 0; i <= this.borrowers.length - 1; i++) {
        if (this.borrowers[i].creditScore) {
          let borrowerCreditInquirySaveCall = this._creditService.saveInquiries(this.borrowers[i].creditInquiry!);
          calls.push(borrowerCreditInquirySaveCall);
        }
      }
      if (calls.length) {
        this._spinner.show();

        forkJoin(calls).subscribe({
          next: (results) => {
            this._spinner.hide();
            this._notificationService.showSuccess("A Credit Inquiries Letter of Explanation has been successfully generated and uploaded.", "Success");
          },
          error: (err) => {
            this._spinner.hide();
            this._notificationService.showError(err.message || err.error || err, "Error");
          }
        });
      }

    }
  }

  haveNotAllBorrowersCreditInquiries = () => {
    return this.borrowers.filter(b => !b.creditInquiry || !b.creditInquiry?.entries.length).length == this.borrowers.length;
  }

  private getInquiries() {
    let calls: Observable<any>[] = [];
    for (let borrowerScore of this.creditScores) {
      if (borrowerScore && borrowerScore.creditID) {
        let getInquiriesCall = this._creditService.getInquiries(borrowerScore.creditID!);
        calls.push(getInquiriesCall);
      }
    }
    if (calls.length) {
      forkJoin(calls).subscribe({
        next: (results) => {
          for (let i = 0; i <= this.mortgage.borrowers.length - 1; i++) {
            const borrowerResult = results[i];
            if (borrowerResult) {
              this.borrowers[i].creditInquiry = borrowerResult;
            }
          }
          this._spinner.hide();
        },
        error: (err) => {
          this._spinner.hide();
          this._notificationService.showError(err.message || err, "Error");
        }
      });
    }
    else {
      this._spinner.hide();
    }
  }

}

export class CreditBorrower extends MortgageBorrower {
  creditInquiry?: CreditInquiryDetails | undefined;
}

