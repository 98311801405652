<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title">Adjustments </h5>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row mb-4" *ngIf="pointAdjustments.length">
        <p-table [value]="pointAdjustments" styleClass="p-datatable-gridlines p-datatable-sm">
          <ng-template pTemplate="caption">
            <h5>Point Adjustments</h5>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Reason</th>
              <th class="amount">Amount</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>
                {{rowData.reason || rowData.description}}
              </td>
              <td class="amount">
                {{rowData.amount | number : '1.0-3'}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td></td>
              <td class="amount">{{pointTotal | number : '1.0-3'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="row" *ngIf="rateAdjustments.length">
        <p-table [value]="rateAdjustments" styleClass="p-datatable-gridlines p-datatable-sm">
          <ng-template pTemplate="caption">
            <h5>Rate Adjustments</h5>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Reason</th>
              <th class="amount">Amount</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>
                {{rowData.reason || rowData.description}}
              </td>
              <td class="amount">
                {{rowData.amount | number : '1.0-3'}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td></td>
              <td class="amount">{{rateTotal | number : '1.0-3'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
    </button>
  </div>
</div>
