import { BorrowerVerificationReportType } from "./borrower-verification-document"

export class GenerateBorrowerVerificationReportRequest {
  thirdPartyOrderId: string;
  daysBack: number = null;
  harvestNewData: boolean;
  reportType: BorrowerVerificationReportType = BorrowerVerificationReportType.Assets;
  thirdPartyAccountIdFilter: string[] = [];
  applicationId: number;
  leadId: number;
  borrowerId: number;
}
