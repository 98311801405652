<div class="modal-body" id="editTaskDialog">
    <div class="row text-end">
        <a href="javascript: void(0);" (click)="toggleTaskView()" *ngIf="task.loanDocTaskId > 0">Show Task
            {{historyVisible
            ? "Details" :
            "History"}}</a>
        <hr>
    </div>
    <form #editTaskForm="ngForm" id="editTaskForm" name="editTaskForm">
        <div [hidden]="!historyVisible">
            <p-table #dt [value]="taskTrackingInfo" [paginator]="true" [rows]="25" *ngIf="taskTrackingInfo"
                [rowsPerPageOptions]="[10,25,50]"
                [globalFilterFields]="['actionValue','dateCreated','firstName','lastName','userName']"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control"
                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                (ngModelChange)="dt.first=0"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="'dateCreated'">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-taskTrackingInfo>
                    <tr>
                        <td> {{taskTrackingInfo.actionValue}} </td>
                        <td> {{taskTrackingInfo.dateCreated | date: 'MM/dd/yyyy h:mma'}} </td>
                        <td>
                            <span
                                *ngIf="taskTrackingInfo.by!=null && (taskTrackingInfo.userProfile.firstName != null || taskTrackingInfo.userProfile.lastName != null)">
                                {{taskTrackingInfo.userProfile.firstName}} {{taskTrackingInfo.userProfile.lastName}}
                            </span>
                            <span
                                *ngIf="taskTrackingInfo.by!=null && taskTrackingInfo.userProfile.firstName == null && taskTrackingInfo.userProfile.lastName == null">
                                {{taskTrackingInfo.userProfile.userName}}
                            </span>
                            <span *ngIf="taskTrackingInfo.borrowerId!=null">
                                {{taskTrackingInfo.borrower.firstName}} {{taskTrackingInfo.borrower.lastName}}
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3" class="text-center"> No history found. </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </form>
    <form #gatherTaskForm="ngForm" id="gatherTaskForm" name="gatherTaskForm">
        <div class="form-group">
            <h4 class="offset-md-1 col-md-10 mb-3">Enter Your Payment Information</h4>
            <div class="offset-md-1 col-md-10 pb-2" style="border-bottom: 1px solid lightgray;">
                {{task.description}}
            </div>
        </div>
        <div class="form-group">
            <h4 class="offset-md-1 col-md-10 mb-3 pb-3" style="border-bottom: 1px solid lightgray">Payment Amount:
                <b>{{gatherTask.taskBalance | currency}}</b>
            </h4>
        </div>
        <div [hidden]="paymentError">
            <div class="form-group mb-3 row">
                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">Account number</label>
                <div class="col-md-9">
                    <input id="accountNumber" credit-card maxlength="19" class="form-control"
                        placeholder="Account Number" name="AccountNumber" [(ngModel)]="gatherTask.accountNumber"
                        required #accountNumber="ngModel"
                        [ngClass]="{'is-invalid' : accountNumber && accountNumber.touched && accountNumber.invalid}">
                    <div class="invalid-feedback">Account number is required.</div>
                </div>
            </div>
            <div class="form-group mb-3 row">
                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">Name</label>
                <div class="col-md-4">
                    <input class="form-control" placeholder="Name" name="Name" type="text"
                        [(ngModel)]="gatherTask.billingName" required #billingName="ngModel"
                        [ngClass]="{'is-invalid' : billingName && billingName.touched && billingName.invalid}">
                    <div class="invalid-feedback">Name is required.</div>
                </div>
                <label class="col-md-1 form-label mb-lg-0 text-end align-self-center">Expiration Date</label>
                <div class="col-md-4">
                    <input id="GatherTask_ExpirationDate" class="form-control" name="GatherTask_ExpirationDate"
                        minlength="6" placeholder="XX/XXXX" type="text"
                        [(ngModel)]="gatherTask.creditCardExpirationDate" mask="99/9999" required
                        #creditCardExpirationDate="ngModel" [ngClass]="{'is-invalid' : creditCardExpirationDate
                && creditCardExpirationDate.touched && creditCardExpirationDate.value.length < 6}">
                    <div class="invalid-feedback">Expiration Date is required.</div>
                </div>
            </div>
            <div class="form-group mb-3 row">
                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">Billing Address</label>
                <div class="col-md-9">
                  <address-autocomplete-input
                    #billingAddress='ngModel'
                    (addressChange)='handleAddressChange($event)'
                    [(ngModel)]='gatherTask.billingAddress'
                    [class.is-invalid]='billingAddress && billingAddress.touched &&
                     billingAddress.invalid'
                    [required]='true'
                    name='Address'
                    placeholder='Billing Address'
                  ></address-autocomplete-input>

                  <div class="invalid-feedback">Address is required.</div>
                </div>
            </div>
            <div class="form-group mb-3 row">
                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">Address 2</label>
                <div class="col-md-4">
                    <input class="form-control" placeholder="Address2" name="Address2" type="text"
                        [(ngModel)]="gatherTask.billingAddress2">
                </div>
                <label class="col-md-1 form-label mb-lg-0 text-end align-self-center">State</label>
                <div class="col-md-4">
                    <select id="state" class="form-select" name="State" [(ngModel)]="gatherTask.billingState" required
                        #billingState="ngModel"
                        [ngClass]="{'is-invalid' : billingState && billingState.touched && billingState.invalid}">
                        <option [ngValue]="null">-- Select One --</option>
                        <option *ngFor="let state of states" [ngValue]="state.value">
                            {{ state.name }}
                        </option>
                    </select>
                    <div class="invalid-feedback">State is required.</div>
                </div>
            </div>
            <div class="form-group mb-3 row">
                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">City</label>
                <div class="col-md-4">
                    <input class="form-control" placeholder="City" name="City" type="text"
                        [(ngModel)]="gatherTask.billingCity" required #billingCity="ngModel"
                        [ngClass]="{'is-invalid' : billingCity && billingCity.touched && billingCity.invalid}">
                    <div class="invalid-feedback">City is required.</div>
                </div>
                <label class="col-md-1 form-label mb-lg-0 text-end align-self-center">Zip Code</label>
                <div class="col-md-4">
                    <zip-code-input [(ngModel)]="gatherTask.billingZip" [name]="'zipCode'" [required]="true"
                        (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
                    </zip-code-input>
                </div>
            </div>
            <div class="form-group mb-3 row">
                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">Phone Number</label>
                <div class="col-md-4">
                    <input id="PhoneNumber" type="text" class="form-control" name="Phone" required mask="(000) 000-0000"
                        [showMaskTyped]="true" [(ngModel)]="gatherTask.billingPhoneNumber" #billingPhoneNumber="ngModel"
                        [ngClass]="{'is-invalid' : billingPhoneNumber && billingPhoneNumber.touched && billingPhoneNumber.invalid}">
                    <div class="invalid-feedback">Phone number is required.</div>
                </div>
                <label class="col-md-1 form-label mb-lg-0 text-end align-self-center">Email</label>
                <div class="col-md-4">
                    <input class="form-control" placeholder="example@domain.com" name="Email" type="email" trim
                        [(ngModel)]="gatherTask.billingEmail" email required #billingEmail="ngModel"
                        [ngClass]="{'is-invalid' : billingEmail && billingEmail.touched && billingEmail.invalid}">
                    <div class="invalid-feedback">Email is required.</div>
                </div>
            </div>
        </div>
        <div [hidden]="!paymentError" class="form-group">
            <h4 class="mb-3">An error has occured...</h4>
        </div>
    </form>
</div>

<div class="modal-footer form-footer" [ngClass]="{'justify-content-center': !hostedInModal}">
    <button type="button" *ngIf="hostedInModal" class="btn btn-secondary" (click)="activeModal.dismiss(isRefreshed)">
        Cancel
    </button>
    <button type="button" class="btn btn-primary" *ngIf="task.taskStatus === 'ConditionImportPending'"
        [disabled]="(taskReadonly || !isValid())" (click)="task.taskStatus='Pending'; saveTask()"><i
            class="fa fa-save"></i> Save & Activate</button>
    <button type="button" class="btn btn-primary" *ngIf="task.taskStatus === 'ConditionImportPending'"
        [disabled]="(taskReadonly || !isValid())" (click)="task.taskStatus='Completed'; saveTask()"><i
            class="fa fa-save"></i> Save & Complete</button>
    <button type="button" class="btn btn-primary" [disabled]="taskReadonly" (click)="submitGatherTask()"> <i
            class="fa fa-save"></i> Submit
    </button>
</div>
