import { Injectable, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/core/services/data.service';
import { Observable, of } from 'rxjs';
import { Borrower } from 'src/app/models';
import { BorrowerFull } from '../../app-details/models/full-borrower.model';
import { isNullOrUndefined } from 'src/utils/isNullOrUndefined';
import { BorrowerNote } from '../models/borrower-note.model';


@Injectable()
export class BorrowersService {

  constructor(
    private http: HttpClient,
    private readonly _dataService: DataService
  ) {}

  getBorrowerAddress = (applicationId: number, borrowerId: number): Observable<any> => {
    const url = `api/Loan/${applicationId}/GetFirstBorrowerAddress/${borrowerId}`;
    return this._dataService.get(url);
  };

  getBorrowersNotOwnedByLoan = (applicationId: number): Observable<Borrower[]> => {
    const url = `api/Loan/${applicationId}/BorrowersNotOwnedByLoan`;
    return this._dataService.get(url);
  }

  upsertBorrower = (fullBorrower: BorrowerFull) : Observable<BorrowerFull> => {
    const url = `api/Borrower/UpsertBorrower/true`;
    return this._dataService.post(url, fullBorrower);
  }

  duplicateBorrower = (borrower: BorrowerFull): Observable<Borrower[]> => {
    return this._dataService.post("api/Borrower/duplicate", borrower);
  }

  duplicateEmail = (email: string): Observable<Borrower[]> => {
    return this._dataService.post("api/Borrower/DuplicateEmail", {email: email});
  }

  inviteBorrower = (borrowerId: number, loanId?: number): Observable<any> => {
    const url = `api/Borrower/InviteBorrower/${borrowerId}${isNullOrUndefined(loanId) ? '' : '?applicationId=' + loanId}`;
    return this._dataService.post(url, {});
  }

  selectBorrower = (loanId: number, borrowerId: number): Observable<any> => {
    const url = `api/Loan/${loanId}/SelectBorrower/${borrowerId}`;
    return this._dataService.post(url, {});
  }

  getBorrower = (borrowerId: number, isAdmin: boolean): Observable<BorrowerFull> => {
    const url = `api/Borrower/${borrowerId}/${isAdmin}`;
    return this._dataService.get(url);
  }

  deleteBorrower = (borrowerId: number): Observable<any> => {
    return this._dataService.delete(`api/Borrower/${borrowerId}`);
  }

  postBorrowerNote = (borrowerId: number, note: BorrowerNote) => {
    const url = 'api/Borrower/' + borrowerId + '/note';
    return this._dataService.post(url, note);
  }

  getBorrowerNotes = (borrowerId: number): Observable<BorrowerNote[]> => {
    const url = 'api/Borrower/' + borrowerId + '/notes';
    return this._dataService.get(url);
  }

  getBorrowerEConsentHistory = (borrowerId: number): Observable<any[]> => {
    const url = 'api/Borrower/' + borrowerId + '/e-consent-history';
    return this._dataService.get(url);
  }
}