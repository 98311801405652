<div class="modal-header">
    <h5 class="modal-title"> Request Lock Extension <span *ngIf="daysToExtend && currentStep === 2">({{daysToExtend}} days)</span></h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <ng-container *ngIf="currentStep === 1">
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label" for="questionType">Select Lock Extension:</label>
            <select class="form-select" name="days-extention" [(ngModel)]="daysToExtend">
                <option [ngValue]="0">--Select One--</option>
                <option *ngFor="let ext of extensionDaysList" [ngValue]="ext.value">
                    {{ext.name}}
                </option>
            </select>
          </div>
        </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 2">
        <div class="row">
            <div class="col-md-12">
              <table class="table table-striped align-items-center align-middle mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Current Lock</th>
                    <th>Lock After Extension</th>
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td><strong>Lock Period (days)</strong></td>
                    <td>{{currentApplication?.productPricing?.lockTerm}}</td>
                    <td>{{response?.lockTerm}}</td>
                  </tr>
                  <tr >
                    <td><strong>Lock Expiration</strong></td>
                    <td>{{currentApplication?.productPricing?.lockExpirationDate | date: 'fullDate'}}</td>
                    <td>{{response?.lockExpirationDate | date: 'fullDate'}}</td>
                  </tr>
                  <tr >
                    <td><strong>Extension Fee</strong></td>
                    <td></td>
                    <td>
                      {{discountRebatePercent | number:'0.3-3'}} ({{discountRebateDollar | currency}})
                    </td>
                  </tr>
                  <tr >
                    <td><strong>Final Price</strong></td>
                    <td>{{this.currentApplication.productPricing.price | number: '1.3-3'}}</td>
                    <td>{{response?.price | number: '1.3-3'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="isLoading" class="btn btn-secondary" (click)="activeModal.dismiss()"
        data-bs-dismiss="modal"> Cancel
    </button>
    <button *ngIf="currentStep === 1" [disabled]="!daysToExtend || isLoading" type="button" class="btn btn-primary"
        (click)="onNextButtonClick()">
        <span class="spinner-border spinner-border-sm" role="status" *ngIf="isLoading"></span>
        Next
    </button>
    <button *ngIf="currentStep === 2" [disabled]="isLoading" type="button" class="btn btn-secondary"
        (click)="currentStep = 1"> Back
    </button>
    <button *ngIf="currentStep === 2" [disabled]="isLoading" type="button" class="btn btn-primary"
        (click)="onRequestLockExtension()">
        <span class="spinner-border spinner-border-sm" role="status" *ngIf="isLoading"></span>
        Confirm Lock
    </button>
</div>
