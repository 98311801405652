import { Component, Input, OnInit } from '@angular/core';
import { ThirdPartyCredential, ThirdPartyCredentialArray, ThirdPartyCredentialType } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OCRVendorPickerDialogComponent } from './ocr-vendor-picker-dialog/ocr-vendor-picker-dialog.component';
import { Constants } from 'src/app/services/constants';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { CredentialModalComponent } from '../shared/credential-modal/credential-modal.component';
import { DdaVendorEditorDialogComponent } from './dda-vendor-editor-dialog/dda-vendor-editor-dialog.component';
import { cloneDeep } from 'lodash';
import { VisionetVendorEditorDialogComponent } from './visionet-vendor-editor-dialog/visionet-vendor-editor-dialog.component';


@Component({
    selector: 'ocr-integration',
    templateUrl: './ocr.component.html'
})
export class OcrComponent implements OnInit {
    @Input() ocrEnabled: Configuration;
    @Input() ocrCredentials: ThirdPartyCredentialArray;

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService) {
    }

    ngOnInit(): void { }

    openVendorPicker = () => {
        const modalRef = this._modalService.open(OCRVendorPickerDialogComponent, { ...Constants.modalOptions.medium, scrollable: false });

        modalRef.componentInstance.vendors = this._thirdPartyCredentialsService
            .getVendors(new ThirdPartyCredential(ThirdPartyCredentialType.OcrVendor));

        modalRef.result.then((selectedVendor: string) => {
            const ModalComponent = selectedVendor === 'DDA'
                ? DdaVendorEditorDialogComponent
                : VisionetVendorEditorDialogComponent
            const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.large, scrollable: false });
            const ocrCreds = new ThirdPartyCredential(ThirdPartyCredentialType.OcrVendor, selectedVendor);
            modalRef.componentInstance.credential = cloneDeep(ocrCreds);
            modalRef.result.then(
                (result: ThirdPartyCredential) => {
                    this.ocrCredentials.push(result);
                },
                () => { }
            );
        },
            () => { }
        );
    }

    openVendorEditor = (e: ThirdPartyCredential) => {
        const ModalComponent = e.vendorName === 'DDA'
            ? DdaVendorEditorDialogComponent
            : VisionetVendorEditorDialogComponent
        const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.large, scrollable: false });
        modalRef.componentInstance.credential = cloneDeep(e);

        modalRef.result.then(
            (result: ThirdPartyCredential) => {
                let existingVendorIndex = this.ocrCredentials.findIndex(v => v.credentialId == result.credentialId);
                if (existingVendorIndex >= 0) {
                    this.ocrCredentials[existingVendorIndex] = result;
                }
            },
            () => { }
        );
    }
}

export class DDAImportedFields {
    siteId: string;
    gatewayBaseURL: string;
    uploadBaseURL: string;
    docExplorerURL: string;
    downloadBaseURL: string;
}

export class DDAKeyValuePairs extends DDAImportedFields {
    applicationSecret: string;
    siteAddress: string;
    cabinetId: string;
}


