<div class="modal-header">
    <h5 class="modal-title">Appraisal Note</h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <label class="form-label" for="recipient">Recipient</label>
            <ng-multiselect-dropdown name="recipient" [placeholder]="'-- Select --'" [settings]="dropdownSettings"
                [data]="noteDeliveryGroups" [(ngModel)]="selectedNote.deliveryGroups">
            </ng-multiselect-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label class="form-label" for="note">Note</label>
            <textarea rows="8" class="form-control" name="note" [(ngModel)]="selectedNote.note" #appraisalNote="ngModel"
                autocomplete="off">
                </textarea>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary right"
        [disabled]="!selectedNote.deliveryGroups.length  || !selectedNote.note" (click)="ok()">OK</button>
</div>