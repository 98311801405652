<div class="row">
  <ng-container *ngFor="let taskStatsGroup of taskStatsGroups">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl" *ngIf="taskStatsGroup.isVisible">
      <task-stats-card #statsCard [taskStatsGroup]="taskStatsGroup" (statSelected)="onStatSelected($event)"></task-stats-card>
    </div>
  </ng-container>
</div>
<div class="row" *ngIf="taskStatsGroups.length === 0">
  <div class="col-md-12">
    <loading-indicator [loadingMessage]="'Loading dasboard tasks...'"></loading-indicator>
  </div>
</div>
