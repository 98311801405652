import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'esignature-cancel-order-dialog',
  templateUrl: './esignature-cancel-order-dialog.component.html',
})
export class EsignatureCancelOrderDialogComponent {
reason:string;

  constructor(public activeModal: NgbActiveModal) {}

  confirmDelete(): void {
   const result = {reason: this.reason, action: true}
    this.activeModal.close(result);
  }
}
