<div class="modal-header">
  <h5 class="modal-title">Borrower Characteristics History</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="btn-group mb-2">
    <button #all class="btn btn-md btn-soft-primary" (click)="onAllBorrowersClicked()">
      All
    </button>
    <button *ngFor="let borrower of borrowers" class="btn btn-md btn-soft-primary" (click)="onBorrowerClicked(borrower.borrowerId)">
      {{(borrower.firstName ? borrower.firstName : '') + ' ' + (borrower.lastName ? borrower.lastName : '')}}
    </button>
  </div>
  <div class="row">
    <p-table #dt1 [value]="history" [paginator]="true" [rows]="25" *ngIf="history"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Borrower Characteristic</th>
          <th>By</th>
          <th>Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-historyItem>
        <tr>
          <td>{{historyItem.actionValue}}</td>
          <td>{{historyItem.userProfile ? historyItem.userProfile.firstName + ' ' + historyItem.userProfile.lastName : '--'}}</td>
          <td>{{historyItem.dateInserted | date:'short'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')">Close</button>
      </div>
    </div>
  </div>
</div>
