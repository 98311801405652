<form id="appraisalsForm" #appraisalsForm="ngForm" class="row">
    <div *ngIf="errorMsg" class="alert alert-danger my-2" role="alert">
        <button type="button" class="btn-close float-end" (click)="showHideErrorContainer(false)"
            aria-label="Close"></button>
        <strong>Error!</strong>
        <div id="error-container">{{errorMsg}}</div>
    </div>
    <div class="row mt-2">
        <div *ngIf="!createNewTabSelected && recentOrder" class="col-md-6">
            <div class="card p-3">
                <div class="card-header">
                    <div class="card-title">
                        Order Details
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" *ngIf="recentOrder.thirdPartyOrderId">
                        <div class="col-sm-12">
                            <strong>Order #: </strong>
                            {{recentOrder.thirdPartyOrderId}}
                        </div>
                    </div>
                    <div class="row" *ngIf="recentOrder.orderStatus">
                        <div class="col-sm-12">
                            <strong>Order Status: </strong>
                            {{recentOrder.orderStatus}}
                        </div>
                    </div>
                    <div class="row" *ngIf="recentOrder.orderStatus !== 'Cancelled'">
                        <div class="col-md-12" class="mx-0 my-2">
                            <button class="btn btn-secondary action-button-100 me-2" type="button"
                                (click)="refreshAppraisalOrder(recentOrder.appraisalOrderId)"
                                *ngIf="recentOrder.orderStatus !== 'Hold'" [disabled]="refreshigOrder">
                                <span *ngIf="!refreshigOrder">Refresh</span>
                                <span *ngIf="refreshigOrder" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                            </button>
                            <button class="btn btn-info action-button-110 me-2" type="button"
                                (click)="placeAppraisalOrderOnHold(recentOrder.appraisalOrderId)"
                                *ngIf="recentOrder.orderStatus !== 'Hold' && recentOrder.orderStatus !== 'Completed'"
                                [disabled]="orderHoldStateChanging">
                                <span *ngIf="!orderHoldStateChanging">Put on Hold</span>
                                <span *ngIf="orderHoldStateChanging" class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                            </button>
                            <button class="btn btn-info action-button-120 me-2" type="button"
                                (click)="resumeAppraisalOrderFromHold(recentOrder.appraisalOrderId)"
                                *ngIf="recentOrder.orderStatus === 'Hold'" [disabled]="orderHoldStateChanging">
                                <span *ngIf="!orderHoldStateChanging">Resume Order </span>
                                <span *ngIf="orderHoldStateChanging" class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                            </button>
                            <button class="btn btn-warning action-button-110 me-2" type="button"
                                *ngIf="recentOrder.orderStatus !== 'Completed'"
                                (click)="cancelAppraisalOrder(recentOrder.appraisalOrderId)"
                                [disabled]="cancelingAppraisalOrder">
                                <span *ngIf="!cancelingAppraisalOrder">Cancel Order</span>
                                <span *ngIf="cancelingAppraisalOrder" class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="card p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="checkbox my-4">
                                <input type="checkbox" class="form-check-input" id="isRushOrderInput"
                                    name="isRushOrderInput" [(ngModel)]="appraisalRequest.isRushOrder" />
                                <label for="isRushOrderInput">Rush Order</label>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <label>Appraisal Form Types</label>
                            <ng-select width="100%" name="appraisalFormType" bindLabel="name" bindValue="id"
                                class="custom-ng-select" placeholder="Select Form Types" [closeOnSelect]="false"
                                multiple="true" #aFormTypeField="ngModel" [items]="appraisalFormTypes" required
                                [ngClass]="{'is-invalid': aFormTypeField && aFormTypeField.touched && aFormTypeField.invalid}"
                                [(ngModel)]="appraisalRequest.appraisalForms">
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div *ngIf="appraisalRequest.appraisalForms.length >= 1" class="ng-values-list">
                                        <span class="ng-value-label">{{ appraisalRequest.appraisalForms.length }}
                                            item(s) selected</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Target Due Date</label>
                            <input type="date" autocomplete="off" class="form-control" name="targetDueDate"
                                [ngModel]="appraisalRequest.targetDueDate | date:'yyyy-MM-dd'"
                                (ngModelChange)="appraisalRequest.targetDueDate = $event">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="card p-3">
                <div class="card-header">
                    <div class="card-title">
                        Third-Party Contact
                        <select class="form-select contact-dropdown" name="thridPartyContacts"
                            (change)="populateContactInfo($event.target.value)">
                            <option value="">-- Select Existing Contact --</option>
                            <optgroup *ngIf="hasBorrowers" label="Borrowers">
                                <option
                                    *ngFor="let contact of borrowersAndExternalContacts | filter: { type: 'Borrower' } "
                                    [value]="contact.id">
                                    {{contact.firstName}} {{contact.lastName}}
                                </option>
                            </optgroup>
                            <optgroup *ngIf="hasExternalContacts" label="External Contacts">
                                <option
                                    *ngFor="let contact of borrowersAndExternalContacts | filter: { type: 'ExternalContact' } "
                                    [value]="contact.id">
                                    {{contact.firstName}} {{contact.lastName}}
                                </option>
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div class="card-body">
                    <div class="alert alert-primary border-0">
                        The best person to contact on the order. Whomever the appraiser needs to contact in order to
                        gain access to the property for inspection.
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">First Name</label>
                            <input class="form-control" #firstName="ngModel" name="firstName"
                                [required]="!isThirdPartyContactAllFalsy()"
                                [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}"
                                [(ngModel)]="appraisalRequest.thirdPartyContact.firstName" />
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Last Name</label>
                            <input class="form-control" #lastName="ngModel" name="lastName"
                                [required]="!isThirdPartyContactAllFalsy()"
                                [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}"
                                [(ngModel)]="appraisalRequest.thirdPartyContact.lastName" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">Role/Title</label>
                            <input class="form-control" #roleTitle="ngModel" name="roleTitle"
                                [(ngModel)]="appraisalRequest.thirdPartyContact.role" />
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Email</label>
                            <input type="email" class="form-control" #emailAddress="ngModel" name="emailAddress"
                                [required]="!isThirdPartyContactAllFalsy()" email
                                [ngClass]="{'is-invalid' : emailAddress && emailAddress.touched && emailAddress.invalid}"
                                [(ngModel)]="appraisalRequest.thirdPartyContact.email"
                                placeholder="example@domain.com" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">Home Phone Number</label>
                            <input class="form-control" #homePhone="ngModel" name="homePhone" [mask]="'(000) 000-0000'"
                                [showMaskTyped]="true" [(ngModel)]="appraisalRequest.thirdPartyContact.homePhoneNumber"
                                placeholder="(999) 999-9999" />
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">Other Phone Number</label>
                            <input class="form-control" #otherPhone="ngModel" name="otherPhone"
                                [mask]="'(000) 000-0000'" [showMaskTyped]="true"
                                [(ngModel)]="appraisalRequest.thirdPartyContact.otherPhoneNumber"
                                placeholder="(999) 999-9999" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <appraisal-docs [appId]="appId" [loanDocsByCategory]="loanDocsByCategory" [isReadonly]="!createNewTabSelected && !isActiveOrder"
                [appraisalDocTypes]="appraisalDocTypes" [selectedDocuments]="appraisalRequest.documents"
                (updateDocuments)="onDocumentsUpdated($event)">
            </appraisal-docs>
            <appraisal-notes [noteDeliveryGroups]="noteDeliveryGroups" [notes]="appraisalRequest.notes"
                [isReadonly]="!createNewTabSelected && !isActiveOrder" (updateNotes)="onNotessUpdated($event)">
            </appraisal-notes>
        </div>
    </div>
    <div class="row" *ngIf="!createNewTabSelected && recentOrder">
        <appraisal-conditions [conditionCategories]="conditionCategories" [isReadonly]="!createNewTabSelected && !isActiveOrder"
            [appraisalOrderId]="recentOrder.appraisalOrderId"></appraisal-conditions>
    </div>
    <div>
        <button *ngIf="createNewTabSelected" class="btn btn-primary float-end me-3" [disabled]="submitting"
            (click)="submitAppraisal()">
            <span *ngIf="!submitting">Order Appraisal</span>
            <span *ngIf="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <button *ngIf="!createNewTabSelected && isActiveOrder" class="btn btn-primary float-end me-3" (click)="submitAppraisal()"
            [disabled]="submitting">
            <span *ngIf="!submitting"> Update Appraisal</span>
            <span *ngIf="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</form>