export class CopyFormApp {
    loanTypeId: number;
    loanPurposeId: number;
    copyReason: string;
    docGuidsToCopy: string[];
    reoId: number;
    alertUserOfNewFile: boolean = false;

    constructor(loanTypeId: number, loanPurposeId: number, copyReason: string) {
        this.loanTypeId = loanTypeId;
        this.loanPurposeId = loanPurposeId;
        this.copyReason = copyReason;
    }
}