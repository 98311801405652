<p-table #messageHistoryTable [value]="history" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
  [resizableColumns]="true" [globalFilterFields]="globalFilterFields" sortField="dateInserted" sortMode="single"
  [columns]="[{field: 'to'}, {field: 'subject'}, {field: 'status'}]" [sortOrder]='-1'
  responsiveLayout="scroll" [scrollable]="true" [scrollHeight]="scrollHeight"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption">
    <div class="p-d-flex" style="align-items: center">
      <div *ngIf="showMessageFilter" class="btn-group" role="group" aria-label="Message Filters">
        <input type="radio" class="btn-check radio-button-primary" name="all" id="all" value="All" [(ngModel)]="filter"
          autocomplete="off" (ngModelChange)="onFilterChanged()" />
        <label class="btn btn btn-md btn-soft-primary" for="all">All</label>

        <input type="radio" class="btn-check radio-button-primary" name="sms" id="sms" value="SMS" [(ngModel)]="filter"
          autocomplete="off" (ngModelChange)="onFilterChanged()" />
        <label class="btn btn btn-md btn-soft-primary" for="sms">SMS</label>

        <input type="radio" class="btn-check radio-button-primary" name="email" id="email" value="Email"
          [(ngModel)]="filter" autocomplete="off" (ngModelChange)="onFilterChanged()" />
        <label class="btn btn btn-md btn-soft-primary" for="email">Email</label>
      </div>

      <button class="btn btn-primary" (click)="messageHistoryTable.exportCSV()"> Export To CSV</button>
      <span class="p-input-icon-right ms-2 p-ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" class="form-control" (input)="
            messageHistoryTable.filterGlobal($event.target.value, 'contains')
          " placeholder="Search keyword" (ngModelChange)="messageHistoryTable.first=0" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="text-center">View / Type</th>
      <th [pSortableColumn]="'to'" pResizableColumn>
        <i class="fa fa-user font-12 text-secondary" style="padding-right: 5px"></i>To / From
        <p-sortIcon [field]="'to'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'subject'" pResizableColumn>
        Subject / Campaign
        <p-sortIcon [field]="'subject'"></p-sortIcon>
      </th>
      <th #statusHeader (click)='onClickSortStatus(statusHeader)' [pSortableColumn]="'status'" pResizableColumn>
        Status / Date
        <p-sortIcon [field]="'status'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-historyItem>
    <tr>
      <td>
        <span class="text-cell-primary btn-link"><a (click)="onViewDetailsClicked(historyItem)">View Detail</a></span>
        <br />
        <span class="text-muted">{{ historyItem.delivery }}</span>
      </td>
      <td>
        <span class="text-cell-primary">{{ (historyItem.delivery && historyItem.delivery == 'SMS') ? (historyItem.to |
          phone) : historyItem.to }}</span>
        <br />
        <span class="text-muted">{{ (historyItem.delivery && historyItem.delivery == 'SMS') ? (historyItem.from | phone)
          : historyItem.from }}</span>
      </td>
      <td>
        <span class="text-cell-primary two-line-truncate cell-mw250">
          <span *ngIf="historyItem['subjectTruncated']?.length > 62; else original"
            title="{{historyItem['subjectOriginal']}}">
            {{ historyItem['subjectTruncated'] }}
          </span>
          <ng-template #original>
            <span> {{ historyItem['subjectTruncated'] }} </span>
          </ng-template>
        </span>
        <br />
        <span class="text-muted text-ellipsis cell-mw250">{{ historyItem.campaignName }}</span>
      </td>
      <td>
        <span class="text-cell-primary">

          <ng-template [ngIf]="historyItem.status === 'Success'">
            <span class="text-success">{{ historyItem['statusDisplayText'] }}</span>
          </ng-template>

          <ng-template
            [ngIf]="historyItem.status !== 'Success' && historyItem.status !== 'Failure' && historyItem.status !== 'FailureSmsInvalidRecepient' && historyItem.status !== 'Paused' && historyItem.status !== 'Unprocessed'">
            <span class="text-info">{{ historyItem['statusDisplayText'] }}</span>
          </ng-template>

          <ng-template [ngIf]="historyItem.status == 'Failure'">
            <span class="emailRetry">
              <span class="noHoverText text-danger">Error</span>
              <span class="hoverText text-success" (click)="resendEmail(historyItem.id)">Resend</span>
            </span>
          </ng-template>

          <ng-template [ngIf]="historyItem.status == 'FailureSmsInvalidRecepient'">
            <span class="noHoverText text-danger">Invalid Recepient</span>
          </ng-template>

          <ng-template [ngIf]="historyItem.status == 'Paused'">
            <span class="emailRetry">
              <span class="noHoverText text-danger">Paused</span>
              <span class="hoverText text-success" (click)="requeuePausedEmailSms(historyItem.id)">Send
              </span>
            </span>
          </ng-template>
          <ng-template [ngIf]="historyItem.status === 'Unprocessed'">
            <span class="noHoverText text-danger">Unprocessed</span>
            <span class="error-detail text-danger">{{historyItem.errorDetail}}</span>
          </ng-template>
        </span>
        <br />
        <span class="text-muted">
          {{ historyItem['actionDate'] | date: 'short' }}
        </span>
        <br />
        <!-- sentDate - MCAI, dateSent - loan -->
        <span *ngIf="historyItem.statusDisplayText !== 'Sent' && (historyItem['dateSent'] || historyItem['sentDate'])"
          class="text-muted" style="font-size: smaller">
          Sent: {{ (historyItem['dateSent'] || historyItem['sentDate']) | date: 'short' }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8" class="text-center">No history items found</td>
    </tr>
  </ng-template>
</p-table>
