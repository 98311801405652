<div class="modal-body d-flex align-items-center justify-content-center">
    <form id="documentGenerationForm" class="mh-100" name="documentGenerationForm" style="overflow-y: auto; overflow-x: hidden;">
        <div class="form-group" *ngIf="currentPageIndex === -1">
            <label class="form-label mb-0">Which document do you want to generate?</label>
            <select class="form-select " id="documentType" [(ngModel)]="selectedTemplateId"
                name="documentType" (ngModelChange)="selectedTemplateChanged()" [disabled]="shouldDcoTemplateBeDisabled" required>
                <option value="">-- Select One --</option>
                <option *ngFor="let documentTemplate of globalConfig.documentTemplates | sort: 'asc': 'order'"
                    value="{{documentTemplate.documentTemplateId}}"
                    [selected]="documentTemplate.documentTemplateId == selectedTemplateId">
                    {{documentTemplate.documentTemplateName}}
                </option>
            </select>
        </div>

        <div *ngIf="noInput" class="text-center p-3" role="alert">
            <h4>
                No input fields present for selected document template.
            </h4>

            <button
                type="button"
                class="btn btn-primary btn-xl"
                [disabled]="isLoading"
                (click)="save(false)">
                Finish & Generate Doc
            </button>
        </div>

        <div class="row" *ngIf="currentPage">
            <h4 class="col-md-8 offset-2 mb-0">{{currentPage.title}}</h4>
            <div class="col-md-8 offset-2" *ngFor="let field of currentPage.fields | filter : { 'visible' : true } | sort: 'asc':'pageOrder'">
                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Unspecified' || field.fieldType === 'Text'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <input class="form-control" type="text" [(ngModel)]="field.defaultValue"
                        name="field_{{field.documentTemplateFieldId}}">
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Textarea'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <textarea class="form-control" style="overflow: hidden" [(ngModel)]="field.defaultValue"
                        name="field_{{field.documentTemplateFieldId}}"></textarea>
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'CheckBox'">
                    <div class="form-check form-switch form-switch-success">
                        <input name="field_{{field.documentTemplateFieldId}}"
                            id="field_{{field.documentTemplateFieldId}}" class="form-check-input" type="checkbox"
                            [(ngModel)]="field.defaultValue">
                        <label class="form-check-label" for="field_{{field.documentTemplateFieldId}}">
                            {{field.displayName}}
                        </label>
                    </div>
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'RadioButton'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <input class="form-control" type="radio" [(ngModel)]="field.defaultValue"
                        name="field_{{field.documentTemplateFieldId}}">
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Email'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <input class="form-control" type="email" trim [(ngModel)]="field.defaultValue"
                        name="field_{{field.documentTemplateFieldId}}">
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Phone'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <input class="form-control" type="text" [(ngModel)]="field.defaultValue" mask="(999) 999-9999"
                        name="field_{{field.documentTemplateFieldId}}">
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Currency'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <currency-input class="form-control" [(ngModel)]="field.defaultValue"
                        name="field_{{field.documentTemplateFieldId}}"></currency-input>
                </div>

                <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Percent'">
                    <label class="form-label mb-0">{{field.displayName}}</label>
                    <percent-input class="form-control" [(ngModel)]="field.defaultValue"
                        name="field_{{field.documentTemplateFieldId}}"></percent-input>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div style="width: 80px"></div>

    <div class="d-flex">
        <button
            *ngIf="currentPageIndex === -1 && !noInput"
            type="button"
            class="btn btn-primary mx-1"
            [disabled]="isLoading || !selectedTemplateId"
            (click)="start()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"></span>
            Start <i class="fa fa-chevron-right"></i>
        </button>
        <button
            *ngIf="currentPageIndex > 0"
            type="button"
            class="btn btn-primary mx-1"
            [disabled]="isLoading"
            (click)="onBackClicked()">
            <i class="fa fa-chevron-left"></i> Back
        </button>
        <strong *ngIf="currentPageIndex > -1 && pages.length > 0" class="mx-1 d-flex align-items-center">{{currentPageIndex + 1}} / {{pages.length}}</strong>
        <button
            *ngIf="currentPageIndex > -1 && (currentPageIndex + 1) < pages.length && !isLoading && !noInput"
            type="button"
            class="btn btn-primary mx-1"
            (click)="onNextClicked()">
            Next <i class="fa fa-chevron-right"></i>
        </button>
    </div>


    <div class="d-flex">
        <button class="btn btn-secondary" (click)="onCancel.emit(false)" style="width: 80px">
            Cancel
        </button>
        <button
            *ngIf="currentPageIndex > -1 && pages.length === (currentPageIndex + 1) && !noInput"
            type="button"
            class="btn btn-primary mx-1"
            [disabled]="isLoading"
            (click)="save(false)">
            Finish & Generate Doc
        </button>
    </div>
</div>