<div class="modal-header">
    <h5 class="modal-title"> Please Provide The Following Required Information To Complete Your Lock Request </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form #requestLockForm="ngForm" novalidate id="requestLockForm" name="requestLockForm">
            <div class="card">
                <div class="card-header">
                    Borrower Information
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end mt-2" for="firstName">First Name</label>
                                <div class="col-sm-6">
                                    <input class="form-control" name="firstName" required #firstName="ngModel"
                                        [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}"
                                        [(ngModel)]="request.borrowerInformation.firstName" />
                                    <div class="invalid-feedback">This field is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end mt-2" for="lastName">Last Name</label>
                                <div class="col-sm-6">
                                    <input class="form-control" name="lastName" required #lastName="ngModel"
                                        [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}"
                                        [(ngModel)]="request.borrowerInformation.lastName" />
                                    <div class="invalid-feedback">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end mt-2" for="propertyStreetAddress">Subject Property Address</label>
                                <div class="col-sm-6">
                                    <input class="form-control" name="propertyStreetAddress" required #propertyStreetAddress="ngModel"
                                        [ngClass]="{'is-invalid' : propertyStreetAddress && propertyStreetAddress.touched && propertyStreetAddress.invalid}"
                                        [(ngModel)]="request.propertyInformation.propertyStreetAddress" />
                                    <div class="invalid-feedback">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end mt-2" for="propertyStreetAddress">Subject Property City</label>
                                <div class="col-sm-6">
                                    <input class="form-control" name="propertyCity" required #propertyCity="ngModel"
                                        [ngClass]="{'is-invalid' : propertyCity && propertyCity.touched && propertyCity.invalid}"
                                        [(ngModel)]="request.propertyInformation.city" />
                                    <div class="invalid-feedback">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Subject Property State: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.state | uppercase}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Subject Property Zip: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.zipCode}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Borrower Citizenship: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.borrowerInformation.citizenship}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Borrower DTI Ratio: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.borrowerInformation.debtToIncomeRatio |
                                            percent:'1.3-3'}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Borrower FICO: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.borrowerInformation.fico}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Borrower First Time Buyer: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.borrowerInformation.firstTimeHomeBuyer == true ? 'Yes' :
                                            request.borrowerInformation.firstTimeHomeBuyer == false ? 'No' :
                                            ''}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">NonOccupant CoBorrower: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.nonOccupyingCoBorrower == true ? 'Yes' :
                                            request.loanInformation.nonOccupyingCoBorrower == false ? 'No' :
                                            ''}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Borrower SelfEmployed: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.borrowerInformation.selfEmployed == true ? 'Yes' :
                                            request.borrowerInformation.selfEmployed == false ? 'No' :
                                            ''}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Months of Reserves: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.borrowerInformation.monthsReserves}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
              <div class="card-header">
                Contact Information
              </div>
              <div class="card-body">
                <div class="row">
                  <role-based-internal-contacts
                    [selectedCompanyId]="selectedCompanyId"
                    [selectedChannel]="selectedChannel"
                    [roleUsers]="availableRoleUsers"
                    [firstRole]="firstRole"
                    [secondaryRoles]="avaliableSecondaryRoles"
                    [isTpoUser]="isTpoUser"
                    [loadingUsers]="false"
                    [primaryRoleUserId]="primaryRoleUserId"
                    [primaryContactIsRequired]="true"
                    (primaryRoleContactChanged)="onPrimaryContactChanged($event)"
                    (secondaryRoleContactChanged)="onSecondaryContactChanged($event)">
                  </role-based-internal-contacts>
                </div>
              </div>
            </div>
            <div class="card">
                <div class="card-header">
                    Lock Information
                </div>
                <div class="card-body">
                    <div class="row">

                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Program Name: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{product.productName}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Rate: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{price.rate/100 | percent:'1.3-3' }}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Price Term: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{price.price/100 | percent:'1.3-3' }}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">P&I: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{price.principalAndInterest | currency}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Lock Term: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{price.lockPeriod}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Term: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{product.loanTerm}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Type: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{product.loanType}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Amortization Type: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{product.amortizationType}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan AUS (UW Risk Assess Type): </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.automatedUnderwritingSystem}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    Loan Information
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Number: </label>
                                <div class="col-sm-6">
                                    <span><strong>--</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Amount(base): </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.baseLoanAmount | currency}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Cashout Amount: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.cashOutAmount | currency}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Second Lien Amount: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.secondLienAmount |
                                            currency}}</strong></span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan HELOC Line Amount: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.helocLineAmount | currency}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan HELOC Draw Amount: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.helocDrawnAmount |
                                            currency}}</strong></span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan LTV: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.ltv | percent:'1.3-3'}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan CLTV: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.cltv | percent:'1.3-3'}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Lien Position: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.lienType}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Impounds: </label>
                                <div class="col-sm-6">
                                    <span><strong>*</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Purpose: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.loanPurpose}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="request.loanInformation.loanPurpose === 'Refinance'">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Refinance Purpose: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.refinancePurpose}}</strong></span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row" *ngIf="request.loanInformation.loanPurpose !== 'Refinance'">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Purchase Price Amount: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.salesPrice |
                                            currency}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan MI Paid By Borrower: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.borrowerPaidMi == true ? 'Yes' :
                                            request.loanInformation.borrowerPaidMi == false ? 'No' :
                                            ''}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Loan Relocation Flag: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.corporateRelocation == true ? 'Yes' :
                                            request.propertyInformation.corporateRelocation == false ? 'No' :
                                            ''}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Current Servicer:
                                </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.loanInformation.currentServicer}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    Property Information
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Address State: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.state | uppercase}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Address Zip: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.zipCode}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Units: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.numberOfUnits}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Appraised Value: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.appraisedValue |
                                            currency}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Occupancy: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.occupancy}}</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Stories: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.numberOfStories}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="col-sm-6 form-label text-end">Property Units: </label>
                                <div class="col-sm-6">
                                    <span><strong>{{request.propertyInformation.numberOfUnits}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer justify-content-center" *ngIf="isRequestLockEnabled">
    <button class="btn btn-primary" type="button" (click)="requestForwardLockClicked()">
        Request Lock
    </button>
</div>
