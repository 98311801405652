import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'tpo-page-template',
  templateUrl: 'tpo-page-template.component.html',
  styleUrls: ['tpo-page-template.component.scss']
})
export class TpoPageTemplateComponent implements OnInit, OnDestroy {

  @Input()
  pageMainContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarLeftContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarMiddleContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarRightContentTemplate!: TemplateRef<any>;

  constructor() {
  }

  ngOnInit() { }

  ngOnDestroy() { }
}

