import { Component, Injector, Input, OnInit } from '@angular/core';
import { EnumerationService } from '../../../../services/enumeration-service';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DocumentTemplate } from '../../../../models/config/global-config.model';
import { finalize } from 'rxjs/operators';
import { SystemLevelService } from '../../../../services/system-level.service';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevel } from 'src/app/models';
import { orderBy } from 'lodash';
import { OnlineApplicationSettings } from 'src/app/models/config/online-application-settings.model';

@Component({
  selector: 'online-app-settings',
  templateUrl: './online-app-settings.component.html'
})
export class OnlineAppSettingsComponent extends ApplicationContextBoundComponent implements OnInit {
  onlineApplicationSettings: OnlineApplicationSettings = {} as OnlineApplicationSettings;
  creditVendorOptions: EnumerationItem[];
  eConsentDocumentTemplates: DocumentTemplate[];
  creditAuthDocumentTemplates: DocumentTemplate[];
  isSaving: boolean;
  currentTab: string = 'general-settings';
  isLoading: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
    this.eConsentDocumentTemplates = orderBy(
      this.applicationContext.globalConfig.documentTemplates.filter(el => el.documentTemplateCategory === 'EConsent'),
      ['order'],
      ['asc']
    );
    this.creditAuthDocumentTemplates = orderBy(
      this.applicationContext.globalConfig.documentTemplates.filter(el => el.documentTemplateCategory === 'CreditAuth'),
      ['order'],
      ['asc']
    );
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
      const systemLevel = JSON.parse(JSON.stringify(data));
      this.onlineApplicationSettings = systemLevel.onlineApplicationSettings || {};
    }).add(() => this.isLoading = false);
    this._enumsService.getCreditVendorEnumerations().subscribe(enums => {
      // We currently ONLY support Meridian, get rid of the filter once that is not the case.
      this.creditVendorOptions = enums.CreditVendor;
    });
  }

  save(): void {
    this.isSaving = true;
    this._systemLevelService.saveOnlineApplicationSettings(this.onlineApplicationSettings).pipe(finalize(() => {
      this.isSaving = false;
    })).subscribe({
      next: res => {
        this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      }
    });
  }
}
