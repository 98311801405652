<div class="row">
  <div class="col-md-12">
    <div class="fieldset-title">
      <div class="row">
        <div class="col-md-6">
          {{title}}
          <span class="badge font-14 {{titleLabelBg}}" *ngIf="titleLabel">{{titleLabel}}</span>
        </div>
        <div class="col-md-6 text-end" *ngIf="showDeleteCredentials && !enableMessageShow">
          <delete-credentials
            [thirdPartyCredential]="thirdPartyCredential"
            [additionalProperties]="credentialAdditionalProperties"
          ></delete-credentials>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12" *ngIf="!enableMessageShow">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Url</label>
      </div>
      <div class="col-md-10">
        <input type="text" class="form-control" [(ngModel)]="thirdPartyCredential.url" [disabled]="disabled">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end" *ngIf="!hideUsername">
        <label class="custom-control-label">Username</label>
      </div>
      <div class="col-md-4" *ngIf="!hideUsername">
        <input type="text" class="form-control" [(ngModel)]="thirdPartyCredential.userName" [disabled]="disabled">
      </div>
      <div class="col-md-1 text-end" *ngIf="!hidePassword">
        <label class="custom-control-label">Password</label>
      </div>
      <div class="col-md-5" *ngIf="!hidePassword">
        <password-input [(ngModel)]="thirdPartyCredential.password" [name]="title" [disabled]="disabled"></password-input>
      </div>
    </div>
  </div>
  <div class="col-md-12" *ngIf="enableMessageShow">
    <enable-message>{{enableMessage}}</enable-message>
  </div>
</div>


