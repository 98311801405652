<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm"
  (ngSubmit)="saveCredential()" novalidate>
  <div class="modal-header">
    <h5 class="modal-title">Credential</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <credential-modal-basic *ngIf="appraisalVendors"
          [credential]="credential" 
          [scope]="scope"
          [vendors]="appraisalVendors">
        </credential-modal-basic>
      </div>
      <div class="col-md-12 mt-1">
        <label class="custom-control-label">Url</label>
        <input type="text" class="form-control" name="url" [(ngModel)]="credential.url">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Login</label>
        <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
      </div>
      <div class="col-md-6 mt-1" *ngIf="credential.vendorName === 'Reggora'">
        <label for="password" class="custom-control-label">Password</label>
        <div class="input-group">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" name="password"
            [(ngModel)]="credential.password">
          <div class="input-group-append">
            <i class="fa password-eye-icon password-toggle" [ngClass]="{
                      'fa-eye-slash': showPassword,
                      'fa-eye': !showPassword
                      }" (click)="showPassword = !showPassword"></i>
          </div>
        </div>
      </div>
      <ng-container *ngIf="credential.vendorName === 'Reggora'">
        <div class="col-md-6 mt-1">
          <label class="custom-control-label">Operation Mode</label>
          <ng-select name="reggora-operation-mode" class="custom-ng-select" bindLabel="name" bindValue="value"
            placeholder="Select Operation Mode" [(ngModel)]="reggoraKeyValuePairs.reggoraOperationMode"
            [multiple]="false" [closeOnSelect]="true" [items]="operationModes"></ng-select>
        </div>
        <div class="col-md-6 mt-1">
          <label class="custom-control-label" for="apiKey">API Key</label>
          <div class="input-group">
            <input [type]="showApiKey ? 'text' : 'password'" class="form-control" id="apiKey" name="apiKey"
              [(ngModel)]="keyValuePairs.apiKey.value">
            <div class="input-group-append">
              <i class="fa password-eye-icon password-toggle" [ngClass]="{
                        'fa-eye-slash': showApiKey,
                        'fa-eye': !showApiKey
                        }" (click)="showApiKey = !showApiKey"></i>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="credential.vendorName === 'Reggora'">
        <div class='col-md-12 mt-1'>
          <label class="custom-control-label">Webhook Token</label>
          <input type="text" class="form-control" name="reggora-webhook-token"
            [(ngModel)]="reggoraKeyValuePairs.reggoraWebhookToken" disabled />
        </div>
      </ng-container>
      <div *ngIf="credential.vendorName === 'Reggora'" class='col-md-12 mt-1'>
        <label class='custom-control-label'>Lender URL</label>
        <input [(ngModel)]='reggoraKeyValuePairs.reggoraLenderUrl' class='form-control' name='reggora-lender-url'>
      </div>
      <div class="col-md-6 mt-1" *ngIf="credential.vendorName !== 'Reggora'">
        <label class="custom-control-label">Token</label>
        <div class="input-group">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="token"
            [(ngModel)]="keyValuePairs.token.value">
          <div class="input-group-append">
            <i class="fa password-eye-icon password-toggle" [ngClass]="{
                          'fa-eye-slash': showPassword,
                          'fa-eye': !showPassword
                          }" (click)="showPassword = !showPassword"></i>
          </div>
        </div>
      </div>
      <ng-container *ngIf="credential.vendorName !== 'Reggora'">
        <div class="col-md-12 mt-1">
          <label class="custom-control-label">Additional Contact Roles</label>
          <ng-select width="100%" name="additionalContactRoles" bindLabel="roleName" bindValue="roleId" appendTo="body"
            placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" [(ngModel)]="selectedRoleIds"
            [items]="roles">
          </ng-select>
        </div>
        <div class="col-md-6 mt-1">
          <label class="custom-control-label" for="partyId">Party / Location ID</label>
          <input type="text" class="form-control" name="partyId" [(ngModel)]="keyValuePairs.partyId.value">
        </div>
        <div class="col-md-6 mt-4">
          <div class="form-group">
            <div class="form-check form-check-inline mt-2">
              <input id="hideLenderNameOnAppraiserAssignmentSheet" name="hideLenderNameOnAppraiserAssignmentSheet"
                class="form-check-input" type="checkbox"
                [(ngModel)]="keyValuePairs.hideLenderNameOnAppraiserAssignmentSheet.value" ToggleValue [true]="'1'"
                [false]="'0'">
              <label class="form-check-label" for="hideLenderNameOnAppraiserAssignmentSheet">
                Hide Lender Name On Appraiser Assignment Sheet
              </label>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</form>
