import { Component, Input, OnInit, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { IdentifyingDocument, MortgageBorrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StoredFileService } from 'src/app/services/stored-file.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { AddEditIdentifyingDocumentDialogComponent } from '../add-edit-identifying-document-dialog/add-edit-identifying-document-dialog.component';
import { Constants } from 'src/app/services/constants';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'identifying-documents-table',
  templateUrl: './identifying-documents-table.component.html',
  styleUrls: ['./identifying-documents-table.component.scss']
})
export class IdentifyingDocumentsTableComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  borrower: MortgageBorrower;

  columns: any[];

  identityDocumentTypes: EnumerationItem[];
  identityDocumentIssuedByTypes: EnumerationItem[];
  states: EnumerationItem[];

  loading: boolean = true;

  constructor(private readonly injector: Injector,
    private readonly _enumerationService: EnumerationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    private readonly _storedFileService: StoredFileService,
    private readonly _modalService: NgbModal) {
    super(injector);
   }

  ngOnInit(): void {
    if ( !this.borrower.identifyingDocuments ) {
      this.borrower.identifyingDocuments = [];
    }
    this.columns = [
      { field: 'borrowerId', header: 'Action', sortable: false },
      { field: 'documentType', header: 'Document Type', sortable: true },
      { field: 'documentTypeOtherDescription', header: 'Document Type Description', sortable: true },
      { field: 'identifier', header: 'ID#', sortable: true },
      { field: 'issuedByType', header: 'Issued By', sortable: true },
      { field: 'issuedByAgency', header: 'Issued By Agency', sortable: true },
      { field: 'issueDate', header: 'Iss./Exp. Date', sortable: true },
      { field: 'docFileId', header: 'Doc File', sortable: false },
    ];

    this.states = this._enumerationService.states;
    this._enumerationService.getDocumentEnumeration().subscribe(enums => {
      this.identityDocumentTypes = enums.IdentityDocumentType;
      this.identityDocumentIssuedByTypes = enums.IdentityDocumentIssuedByType;
      this.getDocumentTypeName();
      this.getIsuedByTypeName();
      this.loading = false;
    });

  }

  getBorrowerName = () => {
    this.borrower.identifyingDocuments.forEach(document => {
      let borrower = this.applicationContext.borrowers.find(b => b.borrowerId == document.borrowerId);
      if (borrower) {
        document['borrowerName'] = Utils.getPersonsFullName(borrower);
      }
    });
  }

  getDocumentTypeName = () => {
    this.borrower.identifyingDocuments.forEach(document => {
      let documentType = this.identityDocumentTypes.find(dt => dt.value == document.documentType);
      if (documentType) {
        document['documentTypeName'] = documentType.name;
      }
    });
  }

  getIsuedByTypeName = () => {
    this.borrower.identifyingDocuments.forEach(document => {
      let documentIssuedByType = this.identityDocumentIssuedByTypes.find(dt => dt.value == document.issuedByType);
      if (documentIssuedByType) {
        document['issuedByTypeName'] = documentIssuedByType.name;
      }
    });
  }

  viewFile = (docFileId: number, document: IdentifyingDocument) => {
    if (document.file?.fileData) {
      const decodedData = window.atob(document.file.fileData);
      const uInt8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }
      const blob = new Blob([uInt8Array], { type: document.file.mimeType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } else {
      this._spinner.show();
      this._loanDocsService.viewDocFileContent(this.applicationContext.application.applicationId, 
        this.applicationContext.userPermissions.userId, docFileId).subscribe({
        next: (data) => {
          const blob = new Blob([data], { type: data['type'] });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: (error) => {}
      }).add(() => this._spinner.hide());
    }  
  }

  addEditDocument = (document?: IdentifyingDocument) => {
    let doc: IdentifyingDocument;
    if (document) {
      doc = document;
    } else {
      doc = new IdentifyingDocument();
      doc.borrowerId = this.borrower.borrowerId;
      doc.identifyingDocumentId = Utils.getUniqueId();
    }
    let modalOptions = Constants.modalOptions.xlarge;
    modalOptions.scrollable = false;
    const modalRef = this._modalService.open(AddEditIdentifyingDocumentDialogComponent, modalOptions);
    modalRef.componentInstance.document = _.cloneDeep(doc);
    modalRef.componentInstance.identityDocumentTypes = this.identityDocumentTypes.filter(dt => !this.borrower.identifyingDocuments.some(id => id.documentType == dt.value) || dt.value == 'Other' || dt.value == document?.documentType)
    modalRef.componentInstance.identityDocumentIssuedByTypes = this.identityDocumentIssuedByTypes
    modalRef.componentInstance.states = this.states;

    modalRef.result.then((result) => {
      if (result) {
        let documentType = this.identityDocumentTypes.find(dt => dt.value == result.documentType);
        if (documentType) {
          result['documentTypeName'] = documentType.name;
        }
        let documentIssuedByType = this.identityDocumentIssuedByTypes.find(dt => dt.value == result.issuedByType);
        if (documentIssuedByType) {
          result['issuedByTypeName'] = documentIssuedByType.name;
        }
        if (document) {
          let index = this.borrower.identifyingDocuments.findIndex(id => id.identifyingDocumentId == document.identifyingDocumentId);
          this.borrower.identifyingDocuments[index] = result;
          this.borrower.identifyingDocuments = [...this.borrower.identifyingDocuments]
        } else {
          this.borrower.identifyingDocuments.push(result);
          this.borrower.identifyingDocuments = [...this.borrower.identifyingDocuments];
        }
      }
    });
  }

  deleteDocument = (document: IdentifyingDocument) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Identifying Document?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      const index = this.borrower.identifyingDocuments.findIndex(id => id.identifyingDocumentId == document.identifyingDocumentId);
      if (index > -1) {
        this.borrower.identifyingDocuments.splice(index, 1);
        this.borrower.identifyingDocuments = [...this.borrower.identifyingDocuments];
      }
    });
  }

}

