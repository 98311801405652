import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { LoanPurpose } from "src/app/models/config/loan-purpose.model";
import { ApplicationContextService } from "src/app/services/application-context.service";
import { Constants } from "src/app/services/constants";
import { EnumerationService } from "src/app/services/enumeration-service";
import { Lead } from "../models/lead.model";

@Injectable({
  providedIn: 'root',
})
export class LeadUtilsService {

  private _loanPurposeRefiEnumValue: string;
  private _loanPurposePurchaseEnumValue: string;

  private _loanPurposes: LoanPurpose[] = [];

  private _contextSubscription: Subscription;

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _enumsService: EnumerationService,
  ) {
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this._loanPurposeRefiEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
      this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
    });

    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this._loanPurposes = context.globalConfig.loanPurpose;
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  public isPurchaseTransaction = (loanPurposeId: number, defaultWhenUnset: boolean) => {
    if (!loanPurposeId) {
      return defaultWhenUnset;
    }
    var lp = this._loanPurposes.find(lp => lp.loanPurposeId === loanPurposeId);
    if (!lp) {
      return defaultWhenUnset;
    }
    return lp.mortgageLoanPurpose === this._loanPurposePurchaseEnumValue;
  }

  public isRefiTransaction = (loanPurposeId: number, defaultWhenUnset: boolean) => {
    if (!loanPurposeId) {
      return defaultWhenUnset;
    }
    var lp = this._loanPurposes.find(lp => lp.loanPurposeId === loanPurposeId);
    if (!lp) {
      return defaultWhenUnset;
    }
    return lp.mortgageLoanPurpose === this._loanPurposeRefiEnumValue;
  }

  public calculateHomeValue = (lead: Lead, presentValue: number = 0) => {
    let presentValueToUse = lead.appraisedValue ?? presentValue ?? 0;
    let purchasePriceAmount = lead.purchasePrice ?? 0;

    const isPurchase = this.isPurchaseTransaction(lead.loanPurposeId, false);
    if (isPurchase) {
      var salesPrice = purchasePriceAmount ? purchasePriceAmount : Number.MAX_VALUE;
      var appraisedValue = presentValueToUse ? presentValueToUse : Number.MAX_VALUE;
      var min = Math.min(salesPrice, appraisedValue);
      return min != Number.MAX_VALUE ? min : 0;
    } else {
      // Refinance
      return presentValueToUse || 0;
    }
  }

  public calculateLtvAndCltv = (lead: Lead): {ltv: number, cltv: number} => {
    let total = lead.loanAmount;
    const homeValue = this.calculateHomeValue(lead);
    if (homeValue) {
      const ltv = total / homeValue;
      const cltv = ltv;
      return { ltv, cltv };
    }
    return { ltv: 0, cltv: 0 };
  }
}
