<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <div class='d-flex align-items-stretch gap-1'>
    <button
      (click)='showImportProductsModal()'
      *ngIf='canImportProducts'
      class='btn btn-soft-primary'
      type='button'
    >
      <i class='fas fa-file-import'></i>
      Import Products from Vendor
    </button>

    <button type='button' class='btn btn-primary' [disabled]='superAdmin'
            (click)='showUpsertDrawer()'>
      <i class='fas fa-plus'></i> Add New
    </button>
  </div>
</ng-template>

<ng-template #pageActionBarMiddle>
  <div class="h-100 d-flex align-items-center">
    <generic-filter class="ms-2" [label]="'All Categories'" [filterHeader]="'Categories'"
      [filterOptions]="categoriesForFiltering" [type]="'singleSelect'"
      (filtersChanged)="onCategoryFilterChanged($event)">
    </generic-filter>
  </div>
</ng-template>

<ng-template #mainContent>
  <p-table #dt1 [columns]="columns" [value]="products" [paginator]="true" [rows]="25" responsiveLayout="scroll"
    name="lender-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="globalFilterFields"
    [autoLayout]="true" [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" styleClass="p-datatable-gridlines"
    sortMode="single" [sortField]="defaultSortingField" scrollable="true" [scrollHeight]="scrollHeight">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">
        <span class="ms-auto">
          <span class="me-2">
            <a class="text-primary" (click)="openProductCategoryOrderDrawer()">Categories Order Setter</a>
          </span>

          <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl
            defaultLabel="Choose Columns"></p-multiSelect>

          <span class="p-input-icon-right ms-2 h-100">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0" placeholder="Search keyword" />
          </span>
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 7rem; text-align: center;">
          <span>Action</span>
        </th>
        <th *ngFor="let col of getSortedColumns()" [pSortableColumn]="col.field">
          <span>
            <a *ngIf="!superAdmin && col.field == 'sortOrder'; else colHeaderText" class="text-primary"
              (click)="openProductsOrderDrawer(); $event.stopPropagation()">{{ col.header }}</a>
            <ng-template #colHeaderText>
              <span>{{ col.header }}</span>
            </ng-template>
          </span>
          <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center w-100">
            <span>
              <i class="fa fa-pencil-alt cursor text-primary" (click)="showUpsertDrawer(rowData)" title="Edit"></i>
            </span>

            <span class="mx-2"> | </span>

            <span>
              <i *ngIf="!isDeleteClicked[rowData.productId]" class="fa fa-trash-alt cursor text-danger"
                (click)="isDeleteClicked[rowData.productId] = true" title="Delete"></i>

              <i *ngIf="isDeleteClicked[rowData.productId]" class="fa fa-times cursor text-danger me-2"
                (click)="isDeleteClicked[rowData.productId] = false" title="Cancel"></i>

              <i *ngIf="isDeleteClicked[rowData.productId]" class="fa fa-check-circle cursor text-danger"
                (click)="onDeleteProductClicked(rowData.productId)" title="Delete"></i>
            </span>
          </span>
        </td>
        <td *ngFor="let col of getSortedColumns()" [ngSwitch]="col.field">
          <span *ngSwitchCase="'enabledChannels'">
            {{
            rowData.enabledChannels && rowData.enabledChannels != 'Disabled'
            ? rowData.enabledChannels
            : ''
            }}
          </span>

          <span *ngSwitchCase="'active'">
            {{ rowData.active | yesNo}}
          </span>

          <span *ngSwitchCase="'pricingVendor'">
            {{ getEnumName(rowData.pricingVendor) }}
          </span>

          <span *ngSwitchCase="'pricingVendorProductId'">
            {{
            getPricingProductName(
            rowData.pricingVendor,
            rowData.pricingVendorProductId
            )
            }}
          </span>

          <span *ngSwitchCase="'losVendor'">
            {{ getLosVendorName(rowData.losVendor) }}
          </span>

          <span *ngSwitchCase="'losVendorProductId'">
            {{
            getLosVendorProductName(
            rowData.losVendor,
            rowData.losVendorProductId
            )
            }}
          </span>

          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="11" class="text-center">
          {{ !isLoading ? 'There are no products configured.' : 'Loading...' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight"
  [pageActionBarMiddleContentTemplate]="pageActionBarMiddle">
</admin-page-template>

<drawer #lenderProductsOrderDrawer [title]="'Product Order'" [name]="'lenderProductsOrderDrawer'"
  [templateRef]="lenderProductsOrderRef" [options]="orderDrawerOptions">
</drawer>
<ng-template #lenderProductsOrderRef>
  <rearrange-order #lenderProductsOrder [itemList]="itemsToOrder" (close)="onCloseProductsOrderDrawer($event)"
    (save)="onProductsOrderDrawerSave($event)"></rearrange-order>
</ng-template>

<drawer #lenderProductsCategoryOrderDrawer [title]="'Product Category Order'"
  [name]="'lenderProductsCategoryOrderDrawer'" [templateRef]="lenderProductsCategoryOrderRef"
  [options]="orderDrawerOptions">
</drawer>
<ng-template #lenderProductsCategoryOrderRef>
  <rearrange-order #lenderProductsCategoryOrder [itemList]="itemsToOrder"
    (close)="onCloseProductsCategoryOrderDrawer($event)" (save)="onProductsCategoryOrderDrawerSave($event)">
  </rearrange-order>
</ng-template>
<drawer #upsertLenderProductDrawer [title]="currentLenderProduct?.productId ? 'Edit Product' : 'Add Product'"
  [name]="'upsertLenderProductDrawer'" [templateRef]="upsertLenderProductRef"
  [options]="upsertLenderProductDrawerOptions">
</drawer>
<ng-template #upsertLenderProductRef>
  <upsert-lender-product [product]="currentLenderProduct" [categories]="allCategories" [pricingVendors]="pricingVendors"
    [losVendors]="losVendors" [fields]="columns" [enabledChannels]="enabledChannels"
    (close)="onUpsertLenderProductDrawerClose($event)">
  </upsert-lender-product>
</ng-template>
