import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'system-level',
  templateUrl: './system-level.component.html',
  styleUrls: ['./system-level.component.scss']
})
export class SystemLevelComponent extends ApplicationContextBoundComponent implements OnInit {

  currentTab: string;

  constructor(
    private readonly injector: Injector,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this._getInitTab();
    })
  }

  private _getInitTab = () => {
    const splitUrl = this._router.url.split("/");

    if (splitUrl.length !== 4) {
      this.currentTab = 'email-configuration';
    } else {
      let pageType = splitUrl[splitUrl.length - 1];
      const urlContainsHash = pageType.includes('#');
      if (urlContainsHash) {
        pageType = pageType.split('#')[0];
      }
      this.currentTab = pageType ? pageType : 'email-configuration';
    }
  }
}
