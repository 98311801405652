import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../services/notification.service';
import { CalendarOptions } from '@fullcalendar/core';
import { CalendarDaysService } from 'src/app/services/calendar-days.service';
import { CalendarDayType, GlobalCalendarDay, MergedCalendarDay, OtherCalendarDay } from 'src/app/models/calendar-days.model';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { cloneDeep } from 'lodash';
import { Utils } from 'src/app/core/services/utils';

@Component({
    selector: 'business-days-calendar',
    templateUrl: './business-days-calendar.component.html',
    styleUrls: ['./business-days-calendar.component.scss']
})
export class BusinessDaysCalendarComponent implements OnInit {
    drawerOptions: DrawerOptions = {
        size: DrawerSize.Medium,
        containerWrapperId: null
    }
    currentView: 'ListView' | 'CalendarView' = 'CalendarView';
    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        events: [],
        customButtons: {
            showListView: {
                text: 'Show List View',
                click: () => this.switchToListView()
            }
        },
        headerToolbar: {
            left: 'title',
            center: 'prev next today',
            right: 'showListView'
        },
        eventBorderColor: 'transparent',
        eventDisplay: 'background',
        editable: false,
        droppable: false,
        dateClick: (dateClickEvent) => {
            this.setCurrentHolidayEvent(new Date(dateClickEvent.dateStr).toISOString());
            this._drawerService.show("businessCalendarEventDrawer", 100);
        },
        eventClick: (selectedEvent) => {
            this.setCurrentHolidayEvent(selectedEvent.event._def.extendedProps.dateStr);
            this._drawerService.show("businessCalendarEventDrawer", 100);
        },
    };
    isLoading: boolean;
    globalHolidays: GlobalCalendarDay[];
    otherHolidays: OtherCalendarDay[];
    currentHolidayEvent: Partial<MergedCalendarDay>;
    mergedCalendarDays: MergedCalendarDay[];

    ngOnInit(): void {
        this.isLoading = true;
        combineLatest([
            this._calendarDaysService.getGlobalHolidays(),
            this._calendarDaysService.getOtherHolidays()
        ]).pipe(finalize(() => this.isLoading = false))
            .subscribe({
                next: ([globalHolidays, otherHolidays]) => {
                    this.globalHolidays = globalHolidays;
                    this.otherHolidays = otherHolidays;
                    this.updateCalendar();
                }, error: err => {
                    this._notificationService.showError(err?.message || 'unable to load calendar days', "Error!");
                }
            });
    }

    private setCurrentHolidayEvent(holidayEventDate: string) {
        const matchingCalendarDay = this.mergedCalendarDays.find(d => d.dateStr === holidayEventDate);
        if (matchingCalendarDay) {
            this.currentHolidayEvent = cloneDeep(matchingCalendarDay);
            return;
        }
        this.currentHolidayEvent = {
            id: undefined,
            title: '',
            type: CalendarDayType.Holiday,
            dateStr: holidayEventDate
        };
    }

    private switchToListView() {
        this.currentView = 'ListView';
    }

    private updateCalendar() {
        this.calendarOptions.eventSources = [];
        this.mergedCalendarDays = this.otherHolidays.map(h => ({
            id: h.calendarDayId,
            title: h.title,
            date: Utils.parseAsLocal(h.date),
            dateStr: h.date,
            type: h.type,
            allDay: true,
            isGlobal: false,
            override: false,
            backgroundColor: h.type === CalendarDayType.None ? '#ffc7c7' : '#d4ffcc',
            className: h.type === CalendarDayType.None ? 'disabled-global-event' : 'hoilday-event'
        }));
        this.globalHolidays.forEach(oh => {
            const matchingCalendarDay = this.mergedCalendarDays.find(d => d.dateStr === oh.date);
            if (matchingCalendarDay) {
                matchingCalendarDay.override = true;
            } else {
                this.mergedCalendarDays.push({
                    id: oh.globalCalendarDayId,
                    title: oh.title,
                    date: Utils.parseAsLocal(oh.date),
                    dateStr: oh.date,
                    type: oh.type,
                    allDay: true,
                    isGlobal: true,
                    override: false,
                    backgroundColor: '#d4ffcc',
                    className: 'hoilday-event'
                });
            }
        });
        this.calendarOptions.eventSources.push(this.mergedCalendarDays as any);
    }

    onDrawerClosed = (args: { action: string; response: OtherCalendarDay }) => {
        this._drawerService.hide("businessCalendarEventDrawer", 100);
        if (!args) {
            return;
        }
        if (args.response) {
            const matchingrecordIndex = this.otherHolidays.findIndex(oh => oh.calendarDayId === args.response.calendarDayId);
            if (matchingrecordIndex === -1 && args.action === 'Delete') {
                return;
            }
            if (args.action === 'Delete') {
                this.otherHolidays.splice(matchingrecordIndex, 1);
            } else {
                if (matchingrecordIndex > -1) {
                    this.otherHolidays[matchingrecordIndex] = args.response;
                } else {
                    this.otherHolidays.push(args.response);
                }
            }
        }
        this.updateCalendar();
    }

    onListViewEventClick(selectedEvent: MergedCalendarDay) {
        this.setCurrentHolidayEvent(selectedEvent.dateStr);
        this._drawerService.show("businessCalendarEventDrawer", 100);
    }

    constructor(
        private readonly _notificationService: NotificationService,
        private readonly _calendarDaysService: CalendarDaysService,
        private readonly _drawerService: DrawerService
    ) { }
}