import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableCheckbox, TableHeaderCheckbox } from 'primeng/table';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { ExportFiles } from '../../models/export-files.model';
import { ExportLoanDocFiles } from '../../models/export-loan-doc-files.model';
import { ExportLoanDocsFilter } from '../../models/export-loan-docs-filter.model';

@Component({
    selector: 'export-loan-docs-accordion',
    templateUrl: 'export-loan-docs-accordion.component.html',
    styleUrls: ['export-loan-docs-accordion.component.scss'],
})

export class ExportLoanDocsAccordionComponent implements OnChanges {

    @ViewChildren("rowCheckBox")
    rowCheckBoxes: QueryList<TableCheckbox>;

    @ViewChildren('rowHeaderCheckBox')
    rowHeaderCheckBox: QueryList<TableHeaderCheckbox>;

    @Input()
    set files({ docFiles, documentTypeId, documentTypeName }: ExportFiles) {
        this.filteredFiles = [...docFiles];
        this._filteredFiles = [...this.filteredFiles];
        this.documentTypeId = `${documentTypeId}`;
        this.documentTypeName = documentTypeName;
    }

    @Input()
    set isSelectAllClicked(isClicked: boolean) {
        this.setRowCheckBoxStatus(isClicked);
    }

    @Input()
    set filter(filter: ExportLoanDocsFilter) {
        this.doFilter(filter);
    }

    @Input() allExpanded: boolean = false;

    documentTypeId: String;

    documentTypeName: String;

    filteredFiles: ExportLoanDocFiles[] = [];

    selectedRows: any[];

    dateFormat: string = 'MM/dd/yyyy h:mma';

    @Output()
    filesUpdated: EventEmitter<ExportLoanDocFiles> = new EventEmitter<ExportLoanDocFiles>();

    @Output()
    filesArrayUpdated: EventEmitter<ExportLoanDocFiles[]> = new EventEmitter<ExportLoanDocFiles[]>();

    private _filteredFiles: ExportLoanDocFiles[] = [];

    private _isSelectAllClicked: boolean = false;
    private _isSelectAllTasksClicked: boolean = false;
    private _isSelectAllConditionsClicked: boolean = false;

    constructor(private readonly _loanDocsService: LoanDocsService,
        private readonly _spinner: NgxSpinnerService) { }

    ngOnChanges(_: SimpleChanges): void {
        this.selectedRows = this.filteredFiles.filter(({ checked }) => checked);
    }

    onToggleCheckboxClicked = (file: ExportLoanDocFiles, checked: boolean) => {
        this.filesUpdated.emit({ ...file, checked });
    }

    onSelectAllByDocumentClicked = (isChecked: boolean) => {
        this.filteredFiles = this.filteredFiles.map(file => ({ ...file, checked: isChecked }));
        this.ngOnChanges(null);
        this.filesArrayUpdated.emit(this.filteredFiles);
    }

    onViewDocFileClicked = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.viewLoanDocContent(file.guid).subscribe((data: any) => {
            this._spinner.hide();
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        }, error => {
            this._spinner.hide();
        })
    }

    onDownloadDocumentClicked = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.getLoanDocContent(file.guid).subscribe(data => {
            this._spinner.hide();
            const blob = new Blob([data]);
            let downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            let fileName = file.fileName;
            downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }, error => {
            this._spinner.hide();
        })
    }

    private doFilter = (filter: ExportLoanDocsFilter) => {
        if (filter.filter === 'all') {
            this.filteredFiles = this._filteredFiles.filter(file => (!filter.excludeNa || file.taskStatus != 'NotApplicable') && (!filter.excludeCleared || (file.conditionStatus != 'Cleared' && file.conditionStatus != 'ConditionCleared')));
        }
        else if (filter.filter === 'task') {
            this.filteredFiles = this._filteredFiles.filter(file => !file.condition && file.taskStatus && (!filter.excludeNa || file.taskStatus != 'NotApplicable'));
        } else {
            this.filteredFiles = this._filteredFiles.filter(file => file.condition && (!filter.excludeCleared || (file.conditionStatus != 'Cleared' && file.conditionStatus != 'ConditionCleared')) && (!filter.excludeNa || file.taskStatus != 'NotApplicable'));
        }
    }

    private setRowCheckBoxStatus = (isChecked: boolean) => {
        if (this.filteredFiles.length > 0) {
            this.onAllSelected(isChecked);
        }
    }

    private onAllSelected = (isChecked: boolean) => {
        this._isSelectAllClicked = isChecked;
        this.filteredFiles = this._filteredFiles.map((file) => ({ ...file, checked: isChecked }));
    }
}
