<div>
  <div class="fieldset-title">Show EConsent Verbiage</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="form-check-label" for="showEConsentVerbiage">In Online Application</label>
      </div>
      <div class="col-md-10">
        <div class="form-check form-check-inline">
          <input class="form-check-input" id="showEConsentVerbiage" type="checkbox"
            [(ngModel)]="onlineApplicationSettings.showEConsentVerbiage">
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">EConsent Text To Use</label>
      </div>
      <div class="col-md-8">
        <rich-text-editor [name]="'eConsentText'" [(ngModel)]="onlineApplicationSettings.eConsentText">
        </rich-text-editor>
      </div>
    </div>
  </div>

  <div class="fieldset-title mt-5">Enable EConsent Document Generation</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="form-check-label" for="enableEConsentDocumentGeneration">For Borrower</label>
      </div>
      <div class="col-md-10">
        <div class="form-check form-check-inline">
          <input class="form-check-input" id="enableEConsentDocumentGeneration" type="checkbox"
            [(ngModel)]="onlineApplicationSettings.enableEConsentDocumentGeneration">
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Document Template To Use</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" [(ngModel)]="onlineApplicationSettings.eConsentDocumentTemplateId">
          <option [ngValue]="">-- Select One --</option>
          <option *ngFor="let item of eConsentDocumentTemplates" value={{item.documentTemplateId}}>
            {{item.documentTemplateName}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>