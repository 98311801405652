import { Component, Injector, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { Lead, PropertyAttachmentType } from 'src/app/modules/leads/models/lead.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { State } from '../lead-general-info/lead-general-info.component';
import { AddressLookupResult } from 'src/app/models/address-lookup-result.model';

@Component({
  selector: 'lead-subject-property',
  templateUrl: './lead-subject-property.component.html',
  styleUrls: ['./lead-subject-property.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadSubjectPropertyComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  lead: Lead;

  @Input()
  isDrawer: boolean = false;

  @Input()
  isSubjectPropertyAddressHidden: boolean = false;

  states: State[] = [];

  constructionMethodOptions: EnumerationItem[] = [];
  projectTypeOptions: EnumerationItem[] = [];
  attachmentTypeOptions: EnumerationItem[] = [];
  propertyWillBeOptions: EnumerationItem[] = [];

  constructor(
    private readonly _enumerationService: EnumerationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  // TODO system level can be moved to parent component
  ngOnInit(): void {

    this.applicationContextService.context.subscribe(context => {
      this.states = Object.keys(context.globalConfig.states).map(key => new State({ code: key, name: context.globalConfig.states[key] }));
    });

    this._enumerationService.getMortgageEnumerations().subscribe((enums) => {
      this.constructionMethodOptions = enums[Constants.mortgageEnumerations.constructionMethodType];
      this.projectTypeOptions = enums[Constants.mortgageEnumerations.projectType];
      this.attachmentTypeOptions = enums[Constants.mortgageEnumerations.attachmentType];
      this.propertyWillBeOptions = enums[Constants.mortgageEnumerations.propertyWillBeType];
    });

    this.initDefaultValues();
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    if (zipCode) {
      this.lead[modelNamePrefix + "State"] = zipCode.state.toLowerCase();
      this.lead[modelNamePrefix + "City"] = Utils.toTitleCase(zipCode.city);
      this.lead[modelNamePrefix + "Zip"] = zipCode.zipcode;
    }
  }

  copyAddressFromTo = (from: string, to: string) => {
    this.lead[to + 'Address1'] = this.lead[from + 'Address1'];
    this.lead[to + 'Address2'] = this.lead[from + 'Address2'];
    this.lead[to + 'City'] = this.lead[from + 'City'];
    this.lead[to + 'State'] = this.lead[from + 'State'];
    this.lead[to + 'Zip'] = this.lead[from + 'Zip'];
  }

  onAddressChanged = (e: AddressLookupResult) => {
    this.lead.subjectPropertyAddress1 = null;

    if (!e) {
      this.lead.subjectPropertyCity = null;
      this.lead.subjectPropertyState = null;
      this.lead.subjectPropertyZip = null;
      return;
    }

    this.lead.subjectPropertyAddress1 = e.address;
    this.lead.subjectPropertyCity = e.city;
    this.lead.subjectPropertyState = e.state;
    this.lead.subjectPropertyZip = e.zipCode;
  }

  onAttachmentTypeChanged = () => {
    if (this.lead.attachmentType !== PropertyAttachmentType.Attached) {
      this.lead.projectType = null;
    }
  }

  private initDefaultValues = () => {
    if (!this.lead.propertyWillBe) {
      this.lead.propertyWillBe = null;
    }
    if (!this.lead.propertyUse) {
      this.lead.propertyUse = null;
    }
    if (!this.lead.projectType) {
      this.lead.projectType = null;
    }
    if (!this.lead.attachmentType) {
      this.lead.attachmentType = PropertyAttachmentType.Detached;
    }
    if (!this.lead.constructionMethod) {
      this.lead.constructionMethod = null;
    }
    if (!this.lead.noOfUnits) {
      this.lead.noOfUnits = 1;
    }
  }
}
