import {FeeCalculatedValues, LoanFee} from './fees.model';
import {splitCamelCaseAndCapitals, toProperCase} from '../../core/services/string-utils';
import {type KeyOfType} from '../../../utils/types';
import {safeNumber, subtract, sum} from '../../shared/utils/math-utils';

export enum FeePayerType {
  Seller = 'Seller',
  Other = 'Other',
  Borrower = 'Borrower',
  ThirdParty = 'ThirdParty',
}

export interface FeePayerFieldAccessorProps {
  fee: LoanFee;
  dollarKey: KeyOfType<LoanFee, number>;
  percentKey?: KeyOfType<LoanFee, number>;
  calculatedDollarKey?: KeyOfType<FeeCalculatedValues, number>;
}

export class FeePayerFieldAccessor {
  protected readonly _fee: LoanFee;
  protected readonly _dollarKey: KeyOfType<LoanFee, number>;
  protected readonly _percentKey?: KeyOfType<LoanFee, number>;
  protected readonly _calculatedDollarKey?: KeyOfType<FeeCalculatedValues, number>;

  constructor(props: FeePayerFieldAccessorProps) {
    this._fee = props.fee;
    this._dollarKey = props.dollarKey;
    this._percentKey = props.percentKey;
    this._calculatedDollarKey = props.calculatedDollarKey;

    if (props.percentKey) {
      Object.defineProperty(this, 'percent', {
        get: () => {
          return this._fee[this._percentKey!];
        },
        set: (value: number) => {
          this.setKeyValue(value, this._percentKey!, this._dollarKey);
        },
      });
    }

    if (props.calculatedDollarKey) {
      Object.defineProperty(this, 'calculatedDollar', {
        get: () => {
          return this._fee.calculatedValues[this._calculatedDollarKey!];
        },
      });
    }
  }

  public get dollar(): number {
    return this._fee[this._dollarKey];
  }

  public set dollar(value: number) {
    this.setKeyValue(value, this._dollarKey, this._percentKey);
  }

  public get percent(): number {
    console.warn(`Trying to get percent value on a field that does not support
     it. Returning 0.`);

    return 0;
  }

  public set percent(value: number) {
    console.warn(`Trying to set percent value (${value}) on a field that does
     not support it.`);
  }

  public get calculatedDollar(): number {
    console.warn(`Trying to get calculated dollar on a field that does not
     support it. Returning 0.`);

    return 0;
  }

  public equals(other: FeePayerFieldAccessor): boolean {
    return this.dollar == other.dollar && this.percent == other.percent;
  }

  protected setKeyValue(
    value: number,
    key: KeyOfType<LoanFee, number>,
    keyToReset?: KeyOfType<LoanFee, number>
  ): void {
    if (this._fee[key] === value) {
      return;
    }

    this._fee[key] = value;
    if (keyToReset) {
      this._fee[keyToReset] = 0;
    }
  }
}

export interface FeePayerAtClosingAccessorProps extends FeePayerFieldAccessorProps {
  beforeClosingDollarKey: KeyOfType<LoanFee, number>;
}

export class FeePayerAtClosingAccessor extends FeePayerFieldAccessor {
  private readonly _beforeClosingDollarKey: KeyOfType<LoanFee, number>;

  constructor(props: FeePayerAtClosingAccessorProps) {
    super(props);

    this._beforeClosingDollarKey = props.beforeClosingDollarKey;
  }

  set dollar(value: number) {
    if (value === this.dollar) {
      return;
    }

    const fee = this._fee;
    fee[this._dollarKey] = sum(value, fee[this._beforeClosingDollarKey]);

    const percentKey = this._percentKey;
    if (percentKey) {
      this._fee[percentKey] = 0;
    }
  }

  get dollar(): number {
    return this.percent > 0
      ? 0
      : subtract(this._fee[this._dollarKey], this._fee[this._beforeClosingDollarKey]);
  }
}

export interface FeePayerBeforeClosingAccessorProps extends FeePayerFieldAccessorProps {
  totalDollarKey: KeyOfType<LoanFee, number>;
  totalPercentKey: KeyOfType<LoanFee, number>;
}

export class FeePayerBeforeClosingAccessor extends FeePayerFieldAccessor {
  private readonly _totalDollarKey: KeyOfType<LoanFee, number>;
  private readonly _totalPercentKey: KeyOfType<LoanFee, number>;

  constructor(props: FeePayerBeforeClosingAccessorProps) {
    super(props);

    this._totalDollarKey = props.totalDollarKey;
    this._totalPercentKey = props.totalPercentKey;

    if (props.percentKey) {
      console.warn('Before closing fee does not support percent value.');
    }
  }

  set dollar(value: number) {
    if (value === this.dollar) {
      return;
    }

    if (!this._fee[this._totalPercentKey]) {
      this.adaptTotalDollar(value);
    }

    this._fee[this._dollarKey] = value;
  }

  get dollar(): number {
    return safeNumber(super.dollar);
  }

  private adaptTotalDollar(value: number) {
    const atClosing = subtract(this._fee[this._totalDollarKey], this._fee[this._dollarKey]);
    this._fee[this._totalDollarKey] = sum(value, atClosing);
  }
}

/**
 * This class is used to encapsulate the logic of setting and getting the fee values of a specific
 * payer.
 */
export abstract class FeePayer {
  public readonly type: FeePayerType;
  public readonly name: string;
  public readonly abbrev: string;
  protected readonly fee: LoanFee;

  private readonly _atClosingAccessor: FeePayerFieldAccessor;
  private readonly _beforeClosingAccessor: FeePayerFieldAccessor;
  private readonly _totalAccessor: FeePayerFieldAccessor;

  protected constructor(props: {
    type: FeePayerType;
    name?: string;
    abbrev?: string;
    fee: LoanFee;
    atClosingAccessor: FeePayerFieldAccessor;
    beforeClosingAccessor: FeePayerFieldAccessor;
    total: FeePayerFieldAccessor;
  }) {
    this.type = props.type;
    this.name = props.name || toProperCase(splitCamelCaseAndCapitals(props.type));
    this.abbrev = props.abbrev || this.name[0];
    this.fee = props.fee;
    this._atClosingAccessor = props.atClosingAccessor;
    this._beforeClosingAccessor = props.beforeClosingAccessor;
    this._totalAccessor = props.total;
  }

  get atClosing(): FeePayerFieldAccessor {
    return this._atClosingAccessor;
  }

  get beforeClosing(): FeePayerFieldAccessor {
    return this._beforeClosingAccessor;
  }

  get total(): FeePayerFieldAccessor {
    return this._totalAccessor;
  }

  equals(other: FeePayer): boolean {
    return (
      this.type === other.type &&
      this.name === other.name &&
      this.abbrev === other.abbrev &&
      // && this.fee === other.fee // We don't care the fee reference, only the values.
      this._atClosingAccessor.equals(other._atClosingAccessor) &&
      this._beforeClosingAccessor.equals(other._beforeClosingAccessor) &&
      this._totalAccessor.equals(other._totalAccessor)
    );
  }
}

// Payer classes are defined below.
class SellerFeePayer extends FeePayer {
  constructor(fee: LoanFee) {
    super({
      type: FeePayerType.Seller,
      fee,
      atClosingAccessor: new FeePayerAtClosingAccessor({
        fee,
        dollarKey: 'sellerFeeDollar',
        percentKey: 'sellerFeePercent',
        calculatedDollarKey: 'sellerPaidAtClosing',
        beforeClosingDollarKey: 'paidOutsideClosingSellerAmount',
      }),
      beforeClosingAccessor: new FeePayerBeforeClosingAccessor({
        fee,
        dollarKey: 'paidOutsideClosingSellerAmount',
        totalDollarKey: 'sellerFeeDollar',
        totalPercentKey: 'sellerFeePercent',
      }),
      total: new FeePayerFieldAccessor({
        fee,
        dollarKey: 'sellerFeeDollar',
        percentKey: 'sellerFeePercent',
        calculatedDollarKey: 'sellerTotal',
      }),
    });
  }
}

class OtherFeePayer extends FeePayer {
  constructor(fee: LoanFee) {
    super({
      type: FeePayerType.Other,
      name: 'Lender',
      fee,
      atClosingAccessor: new FeePayerAtClosingAccessor({
        fee,
        dollarKey: 'lenderFeeDollar',
        percentKey: 'lenderFeePercent',
        calculatedDollarKey: 'lenderPaidAtClosing',
        beforeClosingDollarKey: 'paidOutsideClosingLenderAmount',
      }),
      beforeClosingAccessor: new FeePayerBeforeClosingAccessor({
        fee,
        dollarKey: 'paidOutsideClosingLenderAmount',
        totalDollarKey: 'lenderFeeDollar',
        totalPercentKey: 'lenderFeePercent',
      }),
      total: new FeePayerFieldAccessor({
        fee,
        dollarKey: 'lenderFeeDollar',
        percentKey: 'lenderFeePercent',
        calculatedDollarKey: 'lenderTotal',
      }),
    });
  }
}

class BorrowerFeePayer extends FeePayer {
  constructor(fee: LoanFee) {
    super({
      type: FeePayerType.Borrower,
      fee,
      atClosingAccessor: new FeePayerAtClosingAccessor({
        fee,
        dollarKey: 'borrowerFeeDollar',
        percentKey: 'borrowerFeePercent',
        calculatedDollarKey: 'borrowerPaidAtClosing',
        beforeClosingDollarKey: 'paidOutsideClosingBorrowerAmount',
      }),
      beforeClosingAccessor: new FeePayerBeforeClosingAccessor({
        fee,
        dollarKey: 'paidOutsideClosingBorrowerAmount',
        totalDollarKey: 'borrowerFeeDollar',
        totalPercentKey: 'borrowerFeePercent',
      }),
      total: new FeePayerFieldAccessor({
        fee,
        dollarKey: 'borrowerFeeDollar',
        percentKey: 'borrowerFeePercent',
        calculatedDollarKey: 'borrowerTotal',
      }),
    });
  }
}

class ThirdPartyFeePayer extends FeePayer {
  constructor(fee: LoanFee) {
    super({
      type: FeePayerType.ThirdParty,
      name: '3rd Party',
      abbrev: '3P',
      fee,
      atClosingAccessor: new FeePayerAtClosingAccessor({
        fee,
        dollarKey: 'thirdPartyFeeDollar',
        percentKey: 'thirdPartyFeePercent',
        calculatedDollarKey: 'thirdPartyPaidAtClosing',
        beforeClosingDollarKey: 'paidOutsideClosingThirdPartyAmount',
      }),
      beforeClosingAccessor: new FeePayerBeforeClosingAccessor({
        fee,
        dollarKey: 'paidOutsideClosingThirdPartyAmount',
        totalDollarKey: 'thirdPartyFeeDollar',
        totalPercentKey: 'thirdPartyFeePercent',
      }),
      total: new FeePayerFieldAccessor({
        fee,
        dollarKey: 'thirdPartyFeeDollar',
        percentKey: 'thirdPartyFeePercent',
        calculatedDollarKey: 'thirdPartyTotal',
      }),
    });
  }
}

function createFeePayer(fee: LoanFee, type: FeePayerType): FeePayer {
  switch (type) {
    case FeePayerType.Seller:
      return new SellerFeePayer(fee);
    case FeePayerType.Other:
      return new OtherFeePayer(fee);
    case FeePayerType.Borrower:
      return new BorrowerFeePayer(fee);
    case FeePayerType.ThirdParty:
      return new ThirdPartyFeePayer(fee);
    default:
      throw new Error(`Unsupported fee payer type: ${type}`);
  }
}

export function createAllFeePayers(fee: LoanFee): FeePayer[] {
  return Array.from(Object.values(FeePayerType), (type: FeePayerType) => {
    return createFeePayer(fee, type);
  });
}
