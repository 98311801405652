import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NotificationService} from 'src/app/services/notification.service';
import {ProfileService} from '../../../profile.service';
import {DrawerOptions, DrawerService, DrawerSize} from '../../../../../shared/services/drawer.service';
import { Profile } from 'src/app/models';
import { SendSmsRequest } from 'src/app/models/send-sms-request.model';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { TelephonyServiceModel } from 'src/app/modules/sms-configuration/models';

@Component({
    selector: 'profile-sms-settings',
    templateUrl: './profile-sms-settings.component.html',
    styleUrls: ['./profile-sms-settings.component.scss']
})
export class ProfileSmsSettingsComponent implements OnInit {

    @ViewChild('smsSettingsForm')
    smsSettingsForm: NgForm;

    @Input()
    userProfile: Profile;

    @Input()
    smsAlertsEnabled: boolean;

    protected isLoading = false;

    protected phoneNumberToTestSmsWith: string;

    setupPhoneNumberDrawerOptions: DrawerOptions = {
        size: DrawerSize.XXLarge,
        containerWrapperId: null
    };

    activeTelephonyService: TelephonyServiceModel;

    constructor(
        private _service: ProfileService, private _notify: NotificationService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _drawerService: DrawerService
    ) {
    }

    ngOnInit(): void {
      if(!this.userProfile.telephonyServices?.length){
        this.userProfile.telephonyServices = [new TelephonyServiceModel()];
      }

      this.activeTelephonyService = Utils.getActiveTelephonyService(this.userProfile.telephonyServices);
    }

    onSendTestSMS() {
        if (this.smsSettingsForm) {
          this.smsSettingsForm.form.markAllAsTouched();
          if (!this.smsSettingsForm.form.valid) {
            return;
          }
        }
        this._spinner.show();
        const request: SendSmsRequest = {
          serviceType: this.activeTelephonyService.serviceType,
          fromPhoneNumber: this.activeTelephonyService.fromPhoneNumber,
          accountIdentifier: this.activeTelephonyService.accountIdentifier,
          authToken: this.activeTelephonyService.authToken,
          toPhoneNumber: this.phoneNumberToTestSmsWith,
          message: 'This is a test SMS.',
          companyId: this.activeTelephonyService.companyId
        };
        this._service.sendTestSMS(request).subscribe(res => {
                this._notify.showSuccess(
                    'Message sent successfuly',
                    'Profile Service'
                );
            },
            (error) => {
                this._notify.showError(
                    error ? error.message : 'Unable to send test sms',
                    'Profile Service'
                );
            }).add(() => {
            this._spinner.hide();
        });
    }

    setupPhoneNumber() {
        this._drawerService.show('setupPhoneNumberDrawer', 100);
    }
}
