import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { chain } from "lodash";
import { MergedCalendarDay } from "src/app/models/calendar-days.model";

@Component({
    selector: 'business-days-list-view',
    templateUrl: './business-days-list-view.component.html',
    styleUrls: ['./business-days-list-view.component.scss']
})
export class BusinessDaysListViewComponent implements OnInit, OnChanges {
    @Input() holidayEvents: MergedCalendarDay[];
    @Output() onShowCalendarViewClicked: EventEmitter<void> = new EventEmitter();
    @Output() onEditEventClicked: EventEmitter<MergedCalendarDay> = new EventEmitter();
    filteredHolidayEvents: MergedCalendarDay[] = [];
    selectedYear: number = new Date().getFullYear();
    constructor() {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.holidayEvents.currentValue && !changes.holidayEvents.firstChange) {
            this.setFilteredHolidayEvents();
        }
    }
    ngOnInit(): void {
        this.setFilteredHolidayEvents();
    }

    setFilteredHolidayEvents() {
        this.filteredHolidayEvents = chain(this.holidayEvents)
            .filter(e => e.date.getFullYear() === this.selectedYear)
            .orderBy(e => e.date)
            .cloneDeep()
            .value();
    }

    previousYearClicked() {
        this.selectedYear = this.selectedYear - 1;
        this.setFilteredHolidayEvents();
    }

    nextYearClicked() {
        this.selectedYear = this.selectedYear + 1;
        this.setFilteredHolidayEvents();
    }

    editEvent(selectedEvent: MergedCalendarDay) {
        this.onEditEventClicked.emit(selectedEvent);
    }
}