<select
  class="form-select d-inline-block"
  [ngModel]="selectedRecord.subStatusId"
  (ngModelChange)="selectedRecord.subStatusId = $event; updateStatus()"
>
  <option [ngValue]="null">-- Select --</option>
  <option *ngFor="let status of subStatuses" [ngValue]="status.subStatusId">
    {{ status.subStatusName }}
  </option>
</select>
