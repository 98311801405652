<div class="modal-body modal-body--scroll" id="purchaseAdviceEditor">
  <form #purchaseAdviceForm="ngForm" class="row m-3 mb-0" *ngIf="!loadingContacts && !loadingKeyDates; else spinner">
    <div class="col-md-12" style="background-color: #424242;">
      <h5 class="text-white"> CORRESPONDENT PURCHASE ADVICE </h5>
    </div>
    <div class="col-md-12 my-2">
      <div class="row card-body border">
        <div class="col-md-9">
          <div class="mb-3">
            <span class="fw-bold"> Loan Number: </span>
            <span> {{application.lodaLoanNumber || '--'}} </span>
          </div>
          <div class="mb-3">
            <span class="fw-bold"> Borrower Name(s): </span>
            <span> {{borrowerNames || '--'}} </span>
          </div>
          <div class="mb-3">
            <span class="fw-bold"> Property Address: </span>
            <span> {{application.mortgageLoan?.subjectProperty ? application.mortgageLoan.subjectProperty.address1 + ' '
              + application.mortgageLoan.subjectProperty.city + ', ' + (application.mortgageLoan.subjectProperty.state |
              uppercase) + ' ' + application.mortgageLoan.subjectProperty.zipCode : '--'}} </span>
          </div>
          <div class="mb-3">
            <span class="fw-bold"> Title Company: </span>
            <span> {{titleCompanyName}} </span>
          </div>
          <div>
            <span class="fw-bold"> Correspondent: </span>
            <span class="fw-bold text-danger"> TBD </span>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <span class="fw-bold"> Purchase Date: </span>
            <!-- <date-input class="d-inline-block" [(ngModel)]="purchaseDate" name="purchaseDate" [editorMode]="editorMode"
              [placeholder]="'Purchase Date'"></date-input> -->
            <span class="fw-bold text-danger"> {{purchaseDate}} </span>
          </div>
          <div>
            <span class="fw-bold"> Closing Date: </span>
            <date-input class="d-inline-block" [(ngModel)]="closingDate" name="closingDate" [editorMode]="editorMode"
              [placeholder]="'Closing Date'"></date-input>
          </div>
          <div>
            <span class="fw-bold"> Disbursement Date: </span>
            <date-input class="d-inline-block" [(ngModel)]="disbursementDate" name="disbursementDate"
              [editorMode]="editorMode" [placeholder]="'Disbursement Date'"></date-input>
          </div>
          <div>
            <span class="fw-bold"> First Payment Date: </span>
            <date-input class="d-inline-block" [(ngModel)]="firstPaymentDate" name="firstPaymentDate"
              [editorMode]="editorMode" [placeholder]="'First Payment Date'"></date-input>
          </div>
          <div>
            <span class="fw-bold"> Lock Expiration Date: </span>
            <date-input class="d-inline-block" [(ngModel)]="lockExpirationDate" name="lockExpirationDate"
              [editorMode]="editorMode" [placeholder]="'Lock Expiration Date'"></date-input>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 ps-0">
      <div class="card" style="min-height: 21.2rem;">
        <div class="card-header">
          <h4 class="card-title">
            <span> Net Funding Breakdown </span>
          </h4>
        </div>
        <div class="card-body">
          <div>
            <span> Loan Amt or Unpaid Principal Bal </span>
            <currency-input [(ngModel)]="application.loanAmount" name="loanAmtValue" class="float-end"
              [editorMode]="editorMode" (blur)="onNetFundingBreakdownValueChanged()"
              [placeholder]="'Loan Amt/Unpaid Principal Bal Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Net SRP </span>
            <!-- <currency-input [(ngModel)]="netSRPValue" name="netSRPValue" class="float-end" [editorMode]="editorMode"
              (blur)="onNetFundingBreakdownValueChanged()" [placeholder]="'Net SRP Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> TBD </span>
          </div>
          <div class="mt-3">
            <span> Less Net Amount Due to UWM </span>
            <!-- <currency-input [(ngModel)]="lessNetAmtValue" name="lessNetAmtValue" class="float-end"
              [editorMode]="editorMode" (blur)="onNetFundingBreakdownValueChanged()"
              [placeholder]="'Less Net Amount Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> TBD </span>
          </div>
          <div class="mt-3">
            <span> Less Escrow Balance Due UWM </span>
            <currency-input [(ngModel)]="lessEscrowBalanceValue" name="lessEscrowBalanceValue" class="float-end"
              [editorMode]="editorMode" (blur)="onNetFundingBreakdownValueChanged()"
              [placeholder]="'Less Escrow Balance Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Interest Paid or Deducted </span>
            <!-- <currency-input [(ngModel)]="interestPaidValue" name="interestPaidValue" class="float-end"
              [editorMode]="editorMode" (blur)="onNetFundingBreakdownValueChanged()"
              [placeholder]="'Interest Paid/Deducted Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> TBD </span>
          </div>
          <div class="mt-3">
            <span> Less Net Mortgage Insurance </span>
            <currency-input [(ngModel)]="lessNetMortgageInsuranceValue" name="lessNetMortgageInsuranceValue"
              class="float-end" [editorMode]="editorMode" (blur)="onNetFundingBreakdownValueChanged()"
              [placeholder]="'Less Net Mortgage Insurance Value'"></currency-input>
          </div>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div>
              <span class="text-uppercase"> Total Wire/Net Funding Amount </span>
              <span class="float-end"> {{(totalWireFundingAmt || 0) | currency }}
              </span>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-6 pe-0">
      <div class="card" style="min-height: 21.2rem;">
        <div class="card-header">
          <h4 class="card-title">
            <span> Net SRP Breakdown <span class="fw-bold text-danger"> (TBD) </span> </span>
          </h4>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <span> Base SRP from Lock </span>
            <percent-input [(ngModel)]="baseSRPFromLockRate" name="baseSRPFromLockRate" [digitsInfo]="'1.3-3'"
              [editorMode]="editorMode" [placeholder]="'Base SRP from Lock Rate'"
              (blur)="onNetSRPBreakdownRateChanged()"></percent-input>
            <currency-input [(ngModel)]="baseSRPFromLockValue" name="baseSRPFromLockValue" [editorMode]="editorMode"
              (blur)="onNetSRPBreakdownValueChanged()" [placeholder]="'Base SRP from Lock Value'"></currency-input>
          </div>
          <div class="d-flex justify-content-between">
            <span> Costs </span>
            <percent-input [(ngModel)]="costsRate" name="costsRate" [digitsInfo]="'1.3-3'" [editorMode]="editorMode"
              style="margin-left: 4rem;" [placeholder]="'Costs Rate'"
              (blur)="onNetSRPBreakdownRateChanged()"></percent-input>
            <currency-input [(ngModel)]="costsValue" name="costsValue" [editorMode]="editorMode"
              (blur)="onNetSRPBreakdownValueChanged()" [placeholder]="'Costs Value'"></currency-input>
          </div>
          <div class="d-flex justify-content-between">
            <span> Discounts </span>
            <percent-input [(ngModel)]="discountsRate" name="discountsRate" [digitsInfo]="'1.3-3'"
              style="margin-left: 2.4rem;" [editorMode]="editorMode" [placeholder]="'Discounts Rate'"
              (blur)="onNetSRPBreakdownRateChanged()"></percent-input>
            <currency-input [(ngModel)]="discountsValue" name="discountsValue" [editorMode]="editorMode"
              (blur)="onNetSRPBreakdownValueChanged()" [placeholder]="'Discounts Value'"></currency-input>
          </div>
          <div class="d-flex justify-content-between">
            <span> Holdback </span>
            <percent-input [(ngModel)]="holdbackRate" name="holdbackRate" [digitsInfo]="'1.3-3'"
              style="margin-left: 2.6rem;" [editorMode]="editorMode" [placeholder]="'Holdback Rate'"
              (blur)="onNetSRPBreakdownRateChanged()"></percent-input>
            <currency-input [(ngModel)]="holdbackValue" name="holdbackValue" [editorMode]="editorMode"
              (blur)="onNetSRPBreakdownValueChanged()" [placeholder]="'Holdback Value'"></currency-input>
          </div>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div class="d-flex justify-content-between">
              <span class="text-uppercase"> Net SRP % </span>
              <span style="margin-left: 3.5rem;"> {{(totalSRPRate / 100) | percent:'1.3-3' }} </span>
              <span> {{(netSRPValue || 0) | currency }} </span>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-6 ps-0">
      <div class="card" style="min-height: 14.5rem;">
        <div class="card-header">
          <h4 class="card-title">
            <span> Credits From UWM <span class="fw-bold text-danger"> (TBD) </span> </span>
          </h4>
        </div>
        <div class="card-body">
          <span> - </span>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div>
              <span class="text-uppercase"> Credits From UWM </span>
              <span class="float-end"> {{(totalCredits || 0) | currency }}
              </span>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-6 pe-0">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            <span> Amounts Due to UWM <span class="fw-bold text-danger"> (TBD) </span> </span>
          </h4>
        </div>
        <div class="card-body">
          <div>
            <span> Tax Service </span>
            <currency-input [(ngModel)]="taxServiceValue" name="taxServiceValue" class="float-end"
              [editorMode]="editorMode" (blur)="onAmountsDueToUWMChanged()"
              [placeholder]="'Tax Service Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Flood Certification </span>
            <currency-input [(ngModel)]="floodCertificationValue" name="floodCertificationValue" class="float-end"
              [editorMode]="editorMode" (blur)="onAmountsDueToUWMChanged()"
              [placeholder]="'Flood Certification Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Underwriting Fee </span>
            <currency-input [(ngModel)]="underwritingFeeValue" name="underwritingFeeValue" class="float-end"
              [editorMode]="editorMode" (blur)="onAmountsDueToUWMChanged()"
              [placeholder]="'Underwriting Fee Value'"></currency-input>
          </div>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div>
              <span class="text-uppercase"> Due To UWM </span>
              <span class="float-end"> {{(lessNetAmtValue || 0) | currency }}
              </span>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-6 ps-0">
      <div class="card" style="min-height: 31.4rem;">
        <div class="card-header">
          <h4 class="card-title">
            <span> Escrow Breakdown </span>
          </h4>
        </div>
        <div class="card-body">
          <div>
            <span> Hazard Insurance </span>
            <!-- <currency-input [(ngModel)]="hazardInsurance" name="hazardInsurance" class="float-end"
              [editorMode]="editorMode" (blur)="onEscrowBreakdownValueChanged()"
              [placeholder]="'Hazard Insurance Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> TBD </span>
          </div>
          <div class="mt-3">
            <span> Flood Insurance </span>
            <currency-input [(ngModel)]="application.mortgageLoan.proposedHousingExpense.floodInsurance"
              name="floodInsurance" class="float-end" [editorMode]="editorMode" (blur)="onEscrowBreakdownValueChanged()"
              [placeholder]="'Flood Insurance Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Wind & Hail Insurance </span>
            <currency-input [(ngModel)]="application.mortgageLoan.proposedHousingExpense.hailInsurance"
              name="hailInsurance" class="float-end" [editorMode]="editorMode" (blur)="onEscrowBreakdownValueChanged()"
              [placeholder]="'Wind & Hail Insurance Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Mortgage Insurance </span>
            <currency-input [(ngModel)]="application.mortgageLoan.proposedHousingExpense.mortgageInsurance"
              name="mortgageInsurance" class="float-end" [editorMode]="editorMode"
              (blur)="onEscrowBreakdownValueChanged()" [placeholder]="'Mortgage Insurance Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> City Taxes </span>
            <currency-input [(ngModel)]="application.mortgageLoan.proposedHousingExpense.cityPropertyTax"
              name="cityPropertyTax" class="float-end" [editorMode]="editorMode"
              (blur)="onEscrowBreakdownValueChanged()" [placeholder]="'City Taxes Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> County Taxes </span>
            <currency-input [(ngModel)]="application.mortgageLoan.proposedHousingExpense.countyPropertyTax"
              name="countyPropertyTax" class="float-end" [editorMode]="editorMode"
              (blur)="onEscrowBreakdownValueChanged()" [placeholder]="'County Taxes Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Village Taxes </span>
            <currency-input [(ngModel)]="application.mortgageLoan.proposedHousingExpense.villagePropertyTax"
              name="villagePropertyTax" class="float-end" [editorMode]="editorMode"
              (blur)="onEscrowBreakdownValueChanged()" [placeholder]="'Village Taxes Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> MUD Taxes </span>
            <!-- <currency-input [(ngModel)]="mudTaxes" name="mudTaxes" class="float-end" [editorMode]="editorMode"
              (blur)="onEscrowBreakdownValueChanged()" [placeholder]="'MUD Taxes Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> TBD </span>
          </div>
          <div class="mt-3">
            <span> Aggregate Taxes </span>
            <!-- <currency-input [(ngModel)]="aggregateTaxes" name="aggregateTaxes" class="float-end"
              [editorMode]="editorMode" (blur)="onEscrowBreakdownValueChanged()"
              [placeholder]="'Aggregate Taxes Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> TBD </span>
          </div>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div>
              <span class="text-uppercase"> Net Escrows </span>
              <span class="float-end"> {{(lessEscrowBalanceValue || 0) | currency }}
              </span>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="col-md-6 pe-0">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            <span> Interest Adjustment <span class="fw-bold text-danger"> (TBD) </span> </span>
          </h4>
        </div>
        <div class="card-body">
          <div>
            <span> From Date: </span>
            <date-input class="d-inline-block float-end" [(ngModel)]="fromDate" name="fromDate"
              [editorMode]="editorMode" [placeholder]="'From Date'"></date-input>
          </div>
          <div class="mt-3">
            <span> To Date: </span>
            <date-input class="d-inline-block float-end" [(ngModel)]="toDate" name="toDate" [editorMode]="editorMode"
              [placeholder]="'To Date'"></date-input>
          </div>
          <div class="mt-3">
            <span> Total Days </span>
            <number-input [shiftInputToLeftWhenEditingInline]="true" class="float-end" [(ngModel)]="totalDays"
              name="totalDays" [editorMode]="editorMode" [min]="1"></number-input>
          </div>
          <div class="mt-3">
            <span> Interest per Day: </span>
            <currency-input [(ngModel)]="interestPaidValue" name="interestPaidValue" class="float-end"
              [editorMode]="editorMode" (blur)="onInterestAdjustmentValueChanged()"
              [placeholder]="'Hazard Insurance Value'"></currency-input>
          </div>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div>
              <span class="text-uppercase"> Net Interest Adjustment </span>
              <span class="float-end"> {{(interestPaidValue || 0) | currency }}
              </span>
            </div>
          </h4>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            <span> Mortgage Insurance </span>
          </h4>
        </div>
        <div class="card-body">
          <div>
            <span> Months Prepaid </span>
            <number-input [shiftInputToLeftWhenEditingInline]="true" class="float-end"
              [(ngModel)]="application.mortgageLoan.mortgageInsuranceDetail.monthsPrepaid" name="monthsPrepaid"
              [editorMode]="editorMode" [min]="1"></number-input>
          </div>
          <div class="mt-3">
            <span> MI Prepaid </span>
            <currency-input [(ngModel)]="application.mortgageLoan.mortgageInsuranceDetail.prepaidPremiumTotal"
              name="miPrepaid" [editorMode]="editorMode" class="float-end" (blur)="onMortgageInsuranceChanged()"
              [placeholder]="'MI Prepaid Value'"></currency-input>
          </div>
          <div class="mt-3">
            <span> Costs </span>
            <!-- <currency-input [(ngModel)]="miOther" name="miOther" [editorMode]="editorMode" class="float-end"
              (blur)="onMortgageInsuranceChanged()" [placeholder]="'MI Other Value'"></currency-input> -->
            <span class="fw-bold text-danger float-end"> (TBD) </span>
          </div>
        </div>
        <div class="card-footer">
          <h4 class="card-title text-primary">
            <div>
              <span class="text-uppercase"> Net Mortgage Ins </span>
              <span class="float-end"> {{(lessNetMortgageInsuranceValue || 0) | currency }}
              </span>
            </div>
          </h4>
        </div>
      </div>
    </div>

    <div class="text-end mx-3">
      <button class="btn btn-secondary me-1" type="button" (click)="onCloseClicked()">
        Close
      </button>
      <button class="btn btn-primary mx-1" type="button" (click)="onSaveClicked()">
        <i class="fas fa-save"></i>
        Save
      </button>
    </div>
  </form>

  <ng-template #spinner>
    <div class="offset-lg-0 offset-xxl-1 offset-xxxl-2 col-lg-12 col-xxl-10 col-xxxl-8" style="margin-top: 19rem;">
      <loading-indicator loadingMessage="Loading purchase advice data..." [customClass]="'no-shadow'">
      </loading-indicator>
    </div>
  </ng-template>
</div>