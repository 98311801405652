import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeOwner } from 'src/app/models/quick-insured/home-owner.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { QuickInsuredService } from 'src/app/services/quick-insured.service';
import { QuickInsuredOrderDialogComponent } from './quick-insured-order-dialog/quick-insured-order-dialog.component';

@Component({
  selector: 'task-order-hoi',
  templateUrl: './task-order-hoi.component.html',
  styleUrls: ['./task-order-hoi.component.scss']
})
export class TaskOrderHoiComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  loanDocTaskId: number;

  orders: HomeOwner[];

  constructor(private readonly _quickInsuredService: QuickInsuredService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if (this.loanDocTaskId) {
      this._quickInsuredService.getOrderHistory(this.loanDocTaskId).subscribe((response) => {
        this.orders = response
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : '', 'Error');
      });
    }
  }

  createOrder = () => {
    const modalRef = this._modalService.open(QuickInsuredOrderDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.appId = this.appId;
    modalRef.componentInstance.loanDocTaskId = this.loanDocTaskId;

    modalRef.result.then((result) => {
      if (result != 'cancel') {
        this.orders.unshift(result);
      }
    })
  }

  downloadFile = (pdfBase64) => {
    const newWindow = window.open("");
    setTimeout(() => {
      newWindow.document.write(`
        <html
          <head>
            <title>${moment().format('MM-DD-YYYY-hh-mm-ss')}.pdf</title>
            <style>body{margin: 0px;}iframe{border-width: 0px;}</style>
          </head>`);

      newWindow.document.write(`
          <body>
            <embed
              width='100%'
              height='100%'
              src='data:application/pdf;base64,${pdfBase64}'>
            </embed>
          </body>
        </html>`);
    }, 100)
  }

}
