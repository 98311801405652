<form #upsertKeyDateForm="ngForm" ngbAutofocus class="row">
    <div class="p-3">
        <div class="mb-3">
            <label class="control-label">Display Name</label>
            <input class="form-control"
                [ngClass]="{ 'is-invalid' : displayName && displayName.touched && displayName.invalid }"
                name="displayName" [(ngModel)]="keyDate.displayName" #displayName="ngModel" required />
            <div class="invalid-feedback">Display name is required.</div>
        </div>

        <div class="mb-3">
            <label class="control-label">Key Date Type</label>
            <ng-select width="100%" name="keyDateType" bindLabel="name" bindValue="value" placeholder="-- Select One --"
                [closeOnSelect]="true" [(ngModel)]="keyDate.keyDateType" [items]="filteredKeyDateTypes"></ng-select>
        </div>
        <div class="mb-3" *ngIf="lookupData.enabledChannels.length > 0">
            <label class="control-label">Channels</label>
            <ng-multiselect-dropdown name="channels" required [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
              [data]="multiselectChannels" [(ngModel)]="multiselectChannelsModel"  #keyDateChannel="ngModel"
              [ngClass]="{'is-invalid': keyDateChannel && keyDateChannel.touched && keyDateChannel.invalid}"
              (ngModelChange)="channelSelectionChanged()">
            </ng-multiselect-dropdown>
            <div class="invalid-feedback">Channels are required.</div>
        </div>
        <div class="mb-3">
            <label class="control-label">Update Status To</label>
            <ng-select width="100%" name="updateStatusTo" bindLabel="name" bindValue="value"
              placeholder="-- Select One --" [closeOnSelect]="true" [(ngModel)]="keyDate.updateToLoanStatusId"
                [items]="availableLoanStatusForChannel"></ng-select>
        </div>
        <div class="mb-3">
            <div class="form-check form-switch form-switch-success">
                <input class="form-check-input" type="checkbox" name="allowOverwrites" id="allowOverwrites"
                    [(ngModel)]="keyDate.allowAutomatedOverwrites">
                <label class="form-check-label" for="allowOverwrites">
                    Allow Key Dates to be Automatically Overwritten by System
                </label>
            </div>
        </div>
        <div class="mb-3">
            <label class="control-label">Edit By Role</label>
            <ng-multiselect-dropdown name="editByRole" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
              [data]="multiselectEditByRole" [(ngModel)]="multiselectEditByRoleModel">
            </ng-multiselect-dropdown>
        </div>
        <div class="mb-3">
            <div class="form-check form-switch form-switch-success">
                <input class="form-check-input" type="checkbox" name="showOnPipeline" id="showOnPipeline"
                    [(ngModel)]="keyDate.showOnPipeline">
                <label class="form-check-label" for="showOnPipeline">
                    Show on Pipeline
                </label>
            </div>
        </div>
        <div class="mb-3" *ngIf="keyDate.showOnPipeline">
            <div class="form-check form-switch form-switch-success">
                <input class="form-check-input" type="checkbox" name="showAsMonthYear" id="showAsMonthYear"
                    [(ngModel)]="showAsMonthYear" (change)="pipelineDateFormatChanged()">
                <label class="form-check-label" for="showAsMonthYear">
                    Show as Month, Year
                </label>
            </div>
        </div>
        <h5 *ngIf="!keyDate.expressionGroupId">Key Date Triggers</h5>
        <div class="mb-3" *ngIf="!keyDate.expressionGroupId">
            <label class="control-label">Loan Status</label>
            <ng-select width="100%" name="loanStatusId" bindLabel="name" bindValue="value"
                placeholder="-- Select One --" [closeOnSelect]="true" [(ngModel)]="keyDate.loanStatusId"
                [items]="availableLoanStatusForChannel" (change)="handleLoanStatusChange()">
            </ng-select>
        </div>
        <div class="mb-3" *ngIf="!keyDate.expressionGroupId">
            <label class="control-label">
                Task
            </label>
            <ng-select width="100%" name="taskId" bindLabel="taskName"
                bindValue="taskId" placeholder="-- Select One --" [closeOnSelect]="true" [(ngModel)]="keyDate.taskId"
                (change)="handleTaskChange()" [items]="lookupData.tasks | sort: 'asc':'taskName'"></ng-select>
        </div>
        <div class="mb-3" *ngIf="!keyDate.expressionGroupId">
            <label class="control-label">
                System Task Type
            </label>
            <ng-select width="100%" name="systemTaskType" bindLabel="name" bindValue="value"
                placeholder="-- Select One --" [closeOnSelect]="true" [(ngModel)]="keyDate.systemTaskType"
                (change)="handleSystemTaskTypeChange()"
                [items]="lookupData.allSystemTaskTypes | sort: 'asc':'name'"></ng-select>
        </div>
        <div class="mb-3" *ngIf="!keyDate.expressionGroupId">
            <label class="control-label">Task Status</label>
            <ng-select width="100%" name="taskStatus" bindLabel="name" bindValue="value" placeholder="-- Select One --"
                [closeOnSelect]="true" [disabled]="!keyDate.taskId && !keyDate.systemTaskType" [(ngModel)]="keyDate.taskStatus"
                [items]="lookupData.taskStatuses">
            </ng-select>
        </div>

        <div *ngIf="editMode">
            <div class="d-flex" *ngIf="!keyDate.expressionGroupId">
                <div class="m-1">
                    <button type="button" class="btn btn-soft-danger btn-sm" (click)="convertToExpression()"
                        style="width: 170px" [disabled]="converting">
                        <span *ngIf="!converting">Convert To Expressions</span>
                        <span class="spinner-border spinner-border-sm" role="status" *ngIf="converting"></span>
                    </button>
                </div>
            </div>
            <div class="d-flex" *ngIf="keyDate.expressionGroupId">
                <div class="m-1">
                    <button type="button" class="btn btn-soft-secondary btn-sm" (click)="onExpressionClick();"
                        [disabled]="converting || loadingExpression">
                        <span *ngIf="loadingExpression">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </span>
                        <span *ngIf="!loadingExpression">Expressions</span>
                    </button>
                </div>
                <div class="m-1">
                    <button type="button" class="btn btn-soft-danger btn-sm" (click)="revertExpressionConversion()"
                        style="width: 210px" [disabled]="converting">
                        <span *ngIf="!converting">Revert Expression Conversion</span>
                        <span class="spinner-border spinner-border-sm" role="status" *ngIf="converting"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary me-2" (click)="close()">
                Close
            </button>
            <button *ngIf="!editMode" type="button" class="btn btn-secondary me-2"
                (click)="saveAndConvertToExpression()" [disabled]="saving">
                <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
                Save And Convert To An Expression
            </button>
            <button type="button" class="btn btn-primary me-2" (click)="save()" [disabled]="saving">
                <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
                Save
            </button>
        </div>
    </div>
</form>
