<div class="row">

  <div class="card-body">
    <lead-borrower-income [borrower]="employmentBorrower" (grandTotalIncomeChanged)="grandTotalIncomeChanged()">
    </lead-borrower-income>
  </div>

  <div class="card-body" *ngIf="employmentCoBorrower.firstName || employmentCoBorrower.lastName">
    <lead-borrower-income [borrower]="employmentCoBorrower" (grandTotalIncomeChanged)="grandTotalIncomeChanged()">
    </lead-borrower-income>
  </div>

  <div class="card-body">
    <div class="table-responsive" style="border-bottom: none;">
      <table class="table mb-0">
        <tbody>
          <tr>
            <td class="w-45">
              <span class="fw-bold"> Total Monthly Household Income </span>
            </td>
            <td> <span class="fw-bold"> {{grandTotalIncome | currency}} </span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>