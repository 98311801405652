import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { CacheService } from 'src/app/core/services/cache-service';
import { DataService } from 'src/app/core/services/data.service';
import { HttpRequestCache } from 'src/app/core/services/http-request-cache.decorator';
import { RecentAgent, RecentLead } from 'src/app/models';
import { LeadPipelineMetrics } from '../../reports/pipeline-metrics/models/lead-pipeline-metrics.model';
import { PipelineMetrics } from '../../reports/pipeline-metrics/models/pipeline-metrics.model';
import { LeadPipelineBreakdownKpiData, PipelineBreakdownKpiData } from '../../reports/reporting-dashboard/models/kpi-data.model';
import { KpiDetail } from '../../reports/reporting-dashboard/models/kpi-detail.model';
import { KpiDateGroupBy, KpiGroupByDateBucket, KpiGroupByEnum } from '../../reports/reporting-dashboard/models/kpi-group-by.model';
import { FutureTimeFrame, KpiRequest, TimeFrame } from '../../reports/reporting-dashboard/models/kpi-request.model';
import { AvgDaysBetweenKpiBaseConfig } from '../configuration/models/avg-days-between-kpis-widget-config.model';
import { DashboardConfig } from '../models/dashboard-config.model';
import { DashboardKpiConfiguration } from '../models/dashboard-kpi-config.model';
import { KpiGeneralMetrics } from '../models/kpi-general-metrics.model';
import { RecentApplication } from '../models/recent-application.model';
import { KpiPullThroughRequest, KpiPullThroughResponse } from '../models/kpi-pullthrough.model';
import { KpiTurnTimeRequest, KpiTurnTimeResponse } from '../models/kpi-turntime.model';
import { GoalRecord, GoalRecordResponse, goalRecordFromResponse } from '../models/dashboard-goal-config.model';
import { PipelineBreakdownRequest } from '../../reports/reporting-dashboard/models/pipeline-breakdown-request.model';
import { HttpRequestAutoCancelable } from 'src/app/core/services/http-request-auto-cancelable.decorator';

@Injectable()
export class DashboardV2Service {

  leadBeingEdited = new Subject();

  leadStoppedBeingEdited = new Subject();

  constructor(private readonly _dataService: DataService,
    private readonly _cache: CacheService) { }

  private readonly _refreshSubject = new Subject();

  getKpiGeneralMetrics = (timeFrame: TimeFrame, loanStage?: string): Observable<KpiGeneralMetrics[]> => {
    let url = `api/dashboard/v2/get-kpi-general-data/${timeFrame}`;
    if (loanStage != null) {
      url = url + '&loanStage=' + loanStage;
    }
    return this._dataService.get(url);
  }

  getRecentApplications = (): Observable<RecentApplication[]> => {
    const url = 'api/dashboard/v2/recent/applications';
    return this._dataService.get(url);
  }

  getRecentLeads = (): Observable<RecentLead[]> => {
    const url = 'api/dashboard/v2/recent/leads';
    return this._dataService.get(url);
  }

  getRecentAgents = (): Observable<RecentAgent[]> => {
    const url = 'api/dashboard/v2/recent/agents';
    return this._dataService.get(url);
  }

  @HttpRequestCache<DashboardV2Service>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject
    };
  })
  getRecentApplicationsCached(): Observable<RecentApplication[]> {
    const url = 'api/dashboard/v2/recent/applications';
    return this._dataService.get(url);
  }

  getRecentActivity = (): Observable<any> => {
    const url = 'api/dashboard/v2/recent/activity';
    return this._dataService.get(url);
  }

  getImportantDates = (detailTimeFrameFilter: FutureTimeFrame | null = null): Observable<any> => {
    const filter = detailTimeFrameFilter ? `?detailTimeFrameFilter=${detailTimeFrameFilter}` : '';
    const url = 'api/dashboard/v2/get-upcoming-important-dates' + filter;
    return this._dataService.get(url);
  }

  getLoansByRole = (roleId: number, loanStatusIds: number[], taskIds: number[], branchId?: number | null | undefined): Observable<PipelineMetrics[]> => {
    let url = 'api/dashboard/v2/get-loans-by-role/' + roleId;
    const request = { filteredLoanStatusIds: loanStatusIds || [], filteredTaskIds: taskIds || [] };
    if (branchId) {
      url += `?branchId=${branchId}`;
    }
    return this._dataService.post(url, request);
  }

  deleteDashboard = (dashboardConfigId: number): Observable<any> => {
    const url = 'api/admin/dashboards/' + dashboardConfigId;
    return this._dataService.delete(url);
  }

  createDashboard = (dashboardConfig: DashboardConfig): Observable<DashboardConfig> => {
    const url = 'api/admin/dashboards';
    return this._dataService.post(url, dashboardConfig);
  }

  updateDashboard = (dashboardConfigId: number, dashboardConfig: DashboardConfig): Observable<DashboardConfig> => {
    const url = 'api/admin/dashboards/' + dashboardConfigId;
    return this._dataService.post(url, dashboardConfig);
  }

  getAllDashboards = (forCurrentUser: boolean = false): Observable<DashboardConfig[]> => {
    const url = 'api/admin/dashboards/all?forCurrentUser=' + forCurrentUser;
    return this._dataService.get(url);
  }

  @HttpRequestCache<DashboardV2Service>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject
    };
  })
  getDetailedKpi(request: KpiRequest): Observable<KpiDetail> {
    const url = 'api/dashboard/v2/get-detailed-kpi';
    return this._dataService.post(url, request);
  }

  @HttpRequestCache<DashboardV2Service>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject
    };
  })
  getKpiPullThrough(request: KpiPullThroughRequest): Observable<KpiPullThroughResponse> {
    const url = `api/dashboard/v2/get-kpi-pull-through`;
    return this._dataService.post(url, request);
  }

  getKpiTurnTime = (request: KpiTurnTimeRequest): Observable<KpiTurnTimeResponse> => {
    const url = `api/dashboard/v2/get-kpi-turn-time`;
    return this._dataService.post(url, request);
  }

  getKpiDetailExtended = (request: DashboardKpiConfiguration): Observable<PipelineMetrics[]> => {
    const url = `api/dashboard/v2/get-kpi-detail-extended`;
    return this._dataService.post(url, request);
  }

  @HttpRequestCache<DashboardV2Service>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject
    };
  })
  getKpiGrouped(request: KpiRequest, groupBy: KpiGroupByEnum, groupByTimeFrame?: KpiDateGroupBy): Observable<KpiGroupByDateBucket> {
    let url = 'api/dashboard/v2/get-kpi-grouped?groupBy=' + groupBy;
    if (groupByTimeFrame)
      url += "&dateGroupBy=" + groupByTimeFrame;
    return this._dataService.post(url, request);
  }

  getKpiGroupedExtended = (request: KpiRequest, groupBy?: KpiGroupByEnum, groupByFilterValue?: string, groupByTimeFrame?: KpiDateGroupBy, dateGroupByFilterValue?: number): Observable<PipelineMetrics[]> => {
    let url = `api/dashboard/v2/get-kpi-grouped-extended?`;
    if (groupBy) {
      url += "&groupBy=" + groupBy;
    }

    if (groupByTimeFrame) {
      url += "&dateGroupBy=" + groupByTimeFrame;
    }

    if (groupByFilterValue) {
      url += "&groupByFilterValue=" + groupByFilterValue;
    }

    if (dateGroupByFilterValue) {
      url += "&dateGroupByFilterValue=" + dateGroupByFilterValue;
    }

    return this._dataService.post(url, request);
  }

  getUserGoals(...userIds: readonly string[]): Observable<GoalRecord[]> {
    const url = 'api/dashboard/v2/get-user-goals';
    const data = { userCompanyGuids: userIds };
    return this._dataService.post(url, data).pipe(
      map((goals: GoalRecordResponse[]) =>
        goals.map(goalRecordFromResponse)),
    );
  }

  @HttpRequestCache<DashboardV2Service>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject
    };
  })
  getLoanPipelineBreakdown(request: PipelineBreakdownRequest): Observable<PipelineBreakdownKpiData[]> {
    let url = 'api/dashboard/v2/get-loan-pipeline-breakdown';
    return this._dataService.post(url, request);
  }

  getLoanPipelineBreakdownDetail = (loanStatusId: number, branchId?: number | null | undefined): Observable<PipelineMetrics[]> => {
    let url = 'api/dashboard/v2/get-loan-pipeline-breakdown-detail/' + loanStatusId;
    return this._dataService.post(url, (branchId ? { branchId: branchId } : {}));
  }

  getLeadPipelineBreakdown(leadStatusIds: number[]): Observable<LeadPipelineBreakdownKpiData[]> {
    let url = 'api/dashboard/v2/get-lead-pipeline-breakdown';
    return this._dataService.post(url, { leadStatuses: leadStatusIds });
  }

  getLeadPipelineBreakdownDetail = (leadStatusId: number): Observable<LeadPipelineMetrics[]> => {
    let url = 'api/dashboard/v2/get-lead-pipeline-breakdown-detail/' + leadStatusId;
    return this._dataService.post(url, null);
  }

  @HttpRequestCache<DashboardV2Service>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject
    };
  })
  getDaysBetweenKpis(initialKpiDuringTimeframe: TimeFrame, startKpi: AvgDaysBetweenKpiBaseConfig, endKpi: AvgDaysBetweenKpiBaseConfig): Observable<PipelineMetrics[]> {
    let url = 'api/dashboard/v2/get-days-between-kpis';
    return this._dataService.post(url, { initialKpiDuringTimeframe: initialKpiDuringTimeframe, startKpi: startKpi, endKpi: endKpi });
  }

  setAsUsersDefault = (userId: string, dashboardConfigId: number) => {
    let url = `api/admin/users/${userId}/default-dashboard/${dashboardConfigId}`;
    return this._dataService.post(url, {});
  }

  leadOpenedForEdit = () => {
    this.leadBeingEdited.next(null);
  }

  leadEditingEnded = () => {
    this.leadStoppedBeingEdited.next(null);
  }
}
