import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ReggoraService {

  constructor(private readonly _dataService: DataService) { }

  public getReggoraToken = (applicationId: number): Observable<string> => {
    return this._dataService.post(`api/reggora/sso-token?applicationId=${applicationId}`, null, null, {
      responseType: "text"
    });
  }

  public getReggoraIframeURL = (applicationId: number): Observable<string> => {
    return this._dataService.post(`api/reggora/iframe-url?applicationId=${applicationId ? applicationId : ''}`,null, null, {
      responseType: "text"
    });
  }
}
