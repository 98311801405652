<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" id="docType">
    <div class="row">
        <div class="form-group row">
            <label class="form-label align-self-center mb-lg-0">
                Document Type:
            </label>
            <div>
                <select class="form-select" name="docType" [(ngModel)]="selectedDocTypeId"
                    (change)="onSelectionChanged(selectedDocTypeId)">
                    <option value="-1"> -- Select Doc Type -- </option>
                    <option *ngFor="let docType of docTypes" value="{{docType.documentTypeId}}"
                        selected="selectedDocTypeId === docType.documentTypeId">
                        {{docType.documentTypeName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
    <button type="button" class="btn btn-primary right" (click)="onSaveClicked()" [disabled]="selectedDocTypeId == -1">
        Save </button>
</div>