<div [ngClass]="
    {   'ng-invalid-requested' : urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'form-input' : showBorder,
        'no-border' : !showBorder,
        'bg-grey' : urlaFieldsConfig[fieldBeingEdited]?.hidden || urlaFieldsConfig[fieldBeingEdited]?.readonly 
    }"
  >
    <span class="input-label" style="display: inline;" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>

    <div>
        <span class="label" style="display: inline;" title="">
            <!-- <a 
                
                data-bs-container="body" data-bs-toggle="popover" placement="bottom"
                [popover]="urlaFieldsConfigTemplate" data-trigger="click" [outsideClick]="true"
            >
                {{urlaFieldsConfig[fieldBeingEdited].required | titlecase }}
            </a> -->

            <button class="btn btn-link" type="button" #urlaFieldsConfigPopover
                data-bs-container="body" data-bs-toggle="popover" placement="bottom"
                [popover]="urlaFieldsConfigTemplate" data-trigger="click" [outsideClick]="true"
                [adaptivePosition]="false" style="color: #303e67;">
                {{urlaFieldsConfig[fieldBeingEdited].hidden ? 'Hidden' :  urlaFieldsConfig[fieldBeingEdited].readonly ?
                'Readonly' : urlaFieldsConfig[fieldBeingEdited].required | titlecase }}
            </button>

            <ng-template #urlaFieldsConfigTemplate>
                <div style="min-width: 200px;">
                    <div class="d-flex align-items-center mb-2">
                        <label class="me-3" style="min-width: 4rem;">Hidden: </label>
                        <div class="form-check form-switch form-switch-success ms-xs-0">
                            <input id="editModeToggle" class="form-check-input" [(ngModel)]="urlaFieldsConfig[fieldBeingEdited].hidden" (ngModelChange)="onHiddenToggled()"
                            type="checkbox" />
                        </div>
                        
                    </div>

                    <div class="d-flex align-items-center mb-2">
                        <label class="me-3" style="min-width: 4rem;">Readonly: </label>
                        <div class="form-check form-switch form-switch-success">
                            <input id="editModeToggle" class="form-check-input" [(ngModel)]="urlaFieldsConfig[fieldBeingEdited].readonly" (ngModelChange)="onReadonlyToggled()"
                            type="checkbox" />
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <label class="me-3">Require: </label>
                        <select class="form-select" [(ngModel)]="urlaFieldsConfig[fieldBeingEdited].required" name="{{name}}_Config"
                            [disabled]="urlaFieldsConfig[fieldBeingEdited].readonly || urlaFieldsConfig[fieldBeingEdited].hidden">
                            <option value="none">None</option>
                            <option value="requested">Requested</option>
                            <option value="required">Required</option>
                        </select>
                    </div>
                </div>
            </ng-template>
        </span>
    </div>
</div>