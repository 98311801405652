import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, filter, map, Observable, of, ReplaySubject, startWith, switchMap } from 'rxjs';
import { User } from 'src/app/models/user/user.model';
import { Constants } from 'src/app/services/constants';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { EscalationLoanDocTask } from '../../models/escalation-loan-doc-task.model';
import { EscalationTaskDialogComponent } from './escalation-task-dialog/escalation-task-dialog.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'escalation-history',
  templateUrl: './escalation-history.component.html',
  styleUrls: ['./escalation-history.component.scss']
})
export class EscalationHistoryComponent extends ApplicationContextBoundComponent implements OnInit {

  history: Array<EscalationLoanDocTask> = [];

  private _allUsers: User[] = [];
  private _loanId: number;

  private get _loanInfo$(): Observable<{ loanId: number }> {
    const appId = this.applicationContext?.application?.applicationId;

    const contextService = this.applicationContextService;

    // If available, use the current application context.
    // Otherwise, get if from the service.
    const context$ = appId != null
      ? of({ application: { applicationId: appId } })
      : contextService.context;

    return context$.pipe(
      switchMap(context => contextService.loanInfoChanges.pipe(
        startWith(context),
      ),
      ),
      map((context) => {
        if (context.application) {
          return { loanId: context.application.applicationId };
        }
        return { loanId: null };
      }),
    );
  }

  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly _taskService: TaskService,
    private readonly _modalService: NgbModal,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initLoanInfo();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();

    super.ngOnDestroy();
  }

  private initLoanInfo(): void {
    this._loanInfo$.pipe(
      takeUntil(this._destroyed$),
    ).subscribe((loanInfo) => {
      if (loanInfo.loanId) {
        this._loanId = loanInfo.loanId;
        this.getEscalationHistoryData();
      }
    });
  }

  getEscalationHistoryData = () => {
    const globalConfig = this.applicationContext.globalConfig;
    this._allUsers = globalConfig.usersAll.concat(globalConfig.tpoUsers);

    const taskService = this._taskService;
    const loanId = this._loanId;

    taskService.escalationsChanged$.pipe(
      takeUntil(this._destroyed$),
      startWith({ appId: loanId }), // Trigger initial load.
      filter(({ appId }) => appId === loanId),
      switchMap(() => taskService.getAllEscalations(loanId)),
      catchError((err) => {
        console.error('Error loading escalation history', err);
        return of(undefined);
      }),
    ).subscribe(history => {
      this.history = history || [];
      this.history.forEach(task => {
        task.nameOfDecisionBy = this.getNameOfDecisionBy(task.updatedBy);
      });
    });
  }

  getNameOfDecisionBy = (userId: string) => {
    let user = this._allUsers.find(user => user.userCompanyGuid === userId);
    return user.firstName + " " + user.lastName;
  }

  viewDetailModal = (task: EscalationLoanDocTask) => {
    this._taskService.getTaskDashboardViewById(task.loanDocTaskId).pipe(
      takeUntil(this._destroyed$),
      catchError((err) => {
        console.error('Error loading task dashboard view', err);
        return of(undefined);
      }),
      filter((response) => response != null),
    ).subscribe((response) => {
      const modalRef = this._modalService
        .open(EscalationTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;

      modalRef.result.then((result: EscalationLoanDocTask | null) => {
        if (result == null) {
          return;
        }

        const index = this.history.findIndex(
          (task) => task.loanDocTaskId === result.loanDocTaskId);

        if (index >= 0) {
          this.history[index] = result;
        }
      });
    });
  }
}
