import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * Automatically focuses the element when it is rendered.
 */
@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
  @Input('appAutofocus') public autofocus: boolean = true;

  constructor(private readonly _elementRef: ElementRef) { }

  ngOnInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this._elementRef.nativeElement.focus();
      });
    }
  }
}
