import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { Borrower, DisclosureHistory } from 'src/app/models';
import { LoanFeeHistory } from 'src/app/models/loan/loan-fee-history.model';
import { User } from 'src/app/models/user/user.model';
import { Constants } from 'src/app/services/constants';
import { FeeService } from 'src/app/services/fee.service';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { ChangeOfCircumstanceDetail, DisclosureTracking, DisclosureTrackingBorrower } from '../../models/disclosure-tracking.model';
import { DisclosureTrackingService } from '../../services/disclosure-tracking.service';
import { LoanFeeHistoriesDialogComponent } from './loan-fee-histories-dialog/loan-fee-histories-dialog.component';
import { MortgageModelFieldsConfig } from 'src/app/shared/services/mortgage-field-validation-utils';

@Component({
  selector: 'disclosure-tracking-history',
  templateUrl: 'disclosure-tracking-history.component.html',
  styleUrls: ['disclosure-tracking-history.component.scss']
})
export class DisclosureTrackingHistoryComponent implements OnInit {

  @Input()
  historyItems: DisclosureTracking[] = [];

  @Input()
  appId: number;

  @Input()
  borrowers: Borrower[];

  @Input()
  usersAll: User[];

  @Input()
  urlaFieldsConfig: MortgageModelFieldsConfig;

  columns: any[];

  disclosureTrackingItem: DisclosureTracking;

  primaryBorrower: Borrower;

  user: User;

  disclosureAuditResultsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: "drawer-wrapper"
  }

  disclosureHistory: DisclosureHistory;

  constructor(private _notifsService: NotificationService,
    private _spinnerService: NgxSpinnerService,
    private _disclosureTrackingService: DisclosureTrackingService,
    private readonly _loanServicesService: LoanServicesService,
    private _loanFeeService: FeeService,
    private _modalService: NgbModal,
    private readonly _drawerService: DrawerService,
    ) { }

  ngOnInit() {
    this.columns = [
      { field: 'disclosureSent', header: 'Sent Date', sortable: true },
      { field: 'method', header: 'Method', sortable: false },
      { field: 'by', header: 'By', sortable: false },
      { field: 'leSent', header: 'LE Sent?', sortable: false },
      { field: 'cdSent', header: 'CD Sent?', sortable: false },
      { field: 'safeHarborSent', header: 'Safe Harbor Sent?', sortable: false },
      { field: 'providerListSent', header: 'Provider List Sent?', sortable: false },
      { field: 'borrowers', header: 'Borrowers', sortable: false },
      { field: 'includedInTimeline', header: 'Included in Timeline', sortable: false },
      { field: 'disclosureType', header: 'Disclosure Type', sortable: false },
      { field: 'intent', header: 'Intent', sortable: false },
      { field: 'brokerDisclosed', header: 'Broker Disclosed?', sortable: false },
      { field: 'disclosedFees', header: 'Disclosed Fees', sortable: false },
      { field: 'disclosureHistoryId', header: 'Audit Results', sortable: false },
    ];
  }

  showHistoryItemDetailsPanel = (item: DisclosureTracking): void => {
    if(!item.changeOfCircumstanceDetail){
      item.changeOfCircumstanceDetail = new ChangeOfCircumstanceDetail();
      item.changeOfCircumstanceDetail.feeDetail = [];
    }

    this.disclosureTrackingItem = _.cloneDeep(item);
  }

  showBorrowersFullName = (borrowers: DisclosureTrackingBorrower[]): string => {
    if (borrowers.length === 1) {
      const borrowerId = borrowers[0].borrowerId;
      const borrower = this.borrowers.find(b => b.borrowerId === borrowerId);
      if (borrower) {
        this.primaryBorrower = borrower;
        return Utils.getPersonsFullName(borrower);
      }
    } else if (borrowers.length > 1) {
      const borrowerNames: string[] = [];
      this.primaryBorrower = this.borrowers[0];
      borrowers.forEach((b: DisclosureTrackingBorrower) => {
        const findBorrower = this.borrowers.find(borrower => borrower.borrowerId === b.borrowerId);
        if (findBorrower) {
          borrowerNames.push(Utils.getPersonsFullName(findBorrower));
        }
      });
      return borrowerNames.join(",");
    } else {
      return '-';
    }

  }

  getUserName = (history: DisclosureTracking): string => {
    if (history.requestingUserId) {
      const user = this.usersAll.find(u => u.userCompanyGuid === history.requestingUserId);
      if (user) {
        this.user = user;
        return Utils.getPersonsFullName(user);
      }
    }
  }

  save = () => {
    this.disclosureTrackingItem.borrowers.forEach(borrower => {
      borrower.presumedReceivedDate = this.disclosureTrackingItem.disclosureSent;
    });

    this._spinnerService.show();
    this._disclosureTrackingService.updateDisclosure(this.disclosureTrackingItem)
      .subscribe({
        next: (response: DisclosureTracking) => {
          const indexOfEditedItem = this.historyItems.findIndex(item => item.disclosureHistoryId === response.disclosureHistoryId);
          if (indexOfEditedItem >= 0) {
            this.historyItems[indexOfEditedItem] = response;
          }
          this.disclosureTrackingItem = _.cloneDeep(response);
          this._notifsService.showSuccess('Disclosure successfully updated.', 'Success!');
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to update disclosure for application.', 'Error!');
        }
      }).add(() => this._spinnerService.hide());
  }

  viewFeesClicked = (history: DisclosureTracking) => {
    this._spinnerService.show();
    this._loanFeeService.getLoanFeesHistory(history.disclosedFees.map(f => f.loanFeeHistoryId))
    .subscribe({
      next: (loanFeeHistories: LoanFeeHistory[]) => {
        this._spinnerService.hide();
        // open modal
        let modalRef = this._modalService.open(LoanFeeHistoriesDialogComponent, Constants.modalOptions.large);
        modalRef.componentInstance.histories = loanFeeHistories || [];
        modalRef.result.then(() => {
        }, () => {
        });

      },
      error: (err) => {
        this._spinnerService.hide();
        this._notifsService.showError(err.error.message || err.error, "Fetching Loan Fees Error!");
      }
    })
  }

  showAuditsClicked = (disclosureHistoryId: number) => {
    this._loanServicesService.getDisclosureHistoryById(disclosureHistoryId).subscribe({
      next: (history: DisclosureHistory) => {
        if(history && history.disclosureResponse && history.disclosureResponse.auditMessages && history.disclosureResponse.auditMessages.length){
          this.disclosureHistory = history;
          this._drawerService.show("disclosureAuditResultsDrawer", 10);
        }
        else {
          this._notifsService.showWarning("No data found", "No data!");
        }
      },
      error: (err) => {
        this._notifsService.showError(err.message || 'Unable to fetch disclosure history by id.',"Error!")
      }
    });
  }

  disclosureAuditResultsDrawerClosed = () => {
    this._drawerService.hide("disclosureAuditResultsDrawer", 10);
  }
}
