<div class="modal-header">
  <h5 class="modal-title"> Vendor Picker </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #vendorPickerForm="ngForm" novalidate id="vendorPickerForm">
    <div class="row mt-3">
      <div class="col-md-4 text-end">
        <label class="custom-control-label"> {{type}} Vendor </label>
      </div>
      <div class="col-md-8">
        <select class="form-select" [(ngModel)]="selectedVendor.value" #vendorSelector="ngModel" name="vendorSelector"
          id="vendorSelector"
          [ngClass]="{'is-invalid': (vendorSelector && vendorSelector.touched && vendorSelector.invalid) || vendorSelector.value == 'null'}"
          required>
          <option [value]="null">-- Select One --</option>
          <ng-container *ngFor="let vendor of vendors">
            <option [value]="vendor.value"> {{vendor.name}} </option>
          </ng-container>
        </select>
        <div class="invalid-feedback"> This field is required </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="onOkClicked()">
    <span> Go </span>
  </button>
</div>
