<ng-template #pageActionBarLeft>
    <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
    <button class="btn btn-primary me-2" (click)="openUpsertGroupDrawer()">
        <i class="fa fa-plus"></i>
        Add New Group
    </button>
    <button class="btn btn-primary" (click)="openUpsertKeyDateDrawer()">
        <i class="fa fa-plus"></i>
        Add New Key Date
    </button>
</ng-template>

<ng-template #mainContent>
    <ng-template [ngIf]="loadingData" [ngIfElse]="content">
        <loading-indicator [loadingMessage]="'Loading key dates, please wait...'"></loading-indicator>
    </ng-template>
    <ng-template #content>
        <div class="row">
            <div class="col-md-9">
                <div class="card mb-1">
                    <div class="row px-2 key-date__colummn--group">
                        <div id="leftKeyDateGroupColumn" class="col-md-6 col-sm-6" [sortablejs]="leftKeyDateGroups"
                            [sortablejsOptions]="keyDatesGroupSortableOptions">
                            <div class="card my-2" *ngFor="let group of leftKeyDateGroups">
                                <key-date-group
                                    [keyDateGroup]="group"
                                    [keyDateGroups]="keyDateGroups"
                                    [keyDateTypes]="lookupData.allKeyDateTypes"
                                    #keyDateGroup
                                    [companyId]="companyId"
                                    [column]="1"
                                    [baseMessageTitle]="baseMessageTitle"
                                    [keyDateSortableOptions]="keyDateSortableOptions"
                                    [unassignedKeyDates]="unassignedKeyDates"
                                    (onEditGroup)="openUpsertGroupDrawer($event)"
                                    (onEditKeyDate)="openUpsertKeyDateDrawer($event)"
                                    (keyDateGroupDeleted)="onKeyDateGroupDeleted($event)"
                                ></key-date-group>
                            </div>
                        </div>
                        <div id="rightKeyDateGroupColumn" class="col-md-6 col-sm-6" [sortablejs]="rightKeyDateGroups"
                            [sortablejsOptions]="keyDatesGroupSortableOptions">
                            <div class="card my-2" *ngFor="let group of rightKeyDateGroups">
                                <key-date-group
                                    [keyDateGroup]="group"
                                    [keyDateGroups]="keyDateGroups"
                                    [keyDateTypes]="lookupData.allKeyDateTypes"
                                    #keyDateGroup
                                    [companyId]="companyId"
                                    [column]="2"
                                    [baseMessageTitle]="baseMessageTitle"
                                    [keyDateSortableOptions]="keyDateSortableOptions"
                                    [unassignedKeyDates]="unassignedKeyDates"
                                    (onEditGroup)="openUpsertGroupDrawer($event)"
                                    (onEditKeyDate)="openUpsertKeyDateDrawer($event)"
                                    (keyDateGroupDeleted)="onKeyDateGroupDeleted($event)"
                                ></key-date-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ">
                <div class="card">
                    <div class="">
                        <div class="py-2 px-2 card-title">
                            Unassigned Key Dates
                        </div>
                    </div>
                    <div class="row key-date__colummn--unassigned" [sortablejs]="unassignedKeyDates" *ngIf="!loadingData"
                        [sortablejsOptions]="keyDateSortableOptions">
                        <ng-container *ngFor="let keyDate of unassignedKeyDates">
                            <key-date-box [keyDate]="keyDate" [unassignedKeyDates]="unassignedKeyDates"
                                [companyId]="companyId" [baseMessageTitle]="baseMessageTitle"
                                (onEdit)="openUpsertKeyDateDrawer($event)" [keyDateTypes]="lookupData.allKeyDateTypes" (keyDateDeleted)="onKeyDateDeleted($event)">
                            </key-date-box>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>
<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
    [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #upsertKeyDateGroupDrawer
    [title]="currentKeyDateGroup?.keyDateGroupId ? 'Edit Key Date Group' : 'Add Key Date Group'"
    [name]="'upsertKeyDateGroupDrawer'" [templateRef]="upsertKeyDateGroupRef"
    [options]="upsertKeyDateGroupDrawerOptions">
</drawer>
<ng-template #upsertKeyDateGroupRef>
    <upsert-key-date-group [keyDateGroup]="currentKeyDateGroup" [companyId]="companyId"
        [baseMessageTitle]="baseMessageTitle" (close)="onUpsertKeyDateGroupDrawerClose($event)"></upsert-key-date-group>
</ng-template>

<drawer #upsertKeyDateDrawer [title]="currentKeyDate?.keyDateConfigurationId ? 'Edit Key Date' : 'Add Key Date'"
    [name]="'upsertKeyDateDrawer'" [templateRef]="upsertKeyDateRef" [options]="upsertKeyDateDrawerOptions">
</drawer>
<ng-template #upsertKeyDateRef>
    <upsert-key-date [keyDate]="currentKeyDate" [lookupData]="lookupData" [expressionFields]="expressionFields"
        [companyId]="companyId" (onSave)="onUpsertKeyDateDrawerSave($event)" [baseMessageTitle]="baseMessageTitle"
        (onClose)="onUpsertKeyDateDrawerClose()" (onKeyDateConvertedToExpressionAndSaved)="onKeyDateUpsert($event)"
        (onKeyDateChangeConvertDirection)="onEdit($event)"></upsert-key-date>
</ng-template>