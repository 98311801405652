<div class="ftc">
  <div class="d-flex justify-content-between">
    <h4 class="card-title flex-grow-1">
      <strong> Funds To Close </strong>
    </h4>
    <div>
      <ng-container *ngIf="loanFees">
        <a class="text-primary mx-1" style="font-size: small;" (click)="onLoanQuoteClicked()">
          <span> Loan Quote </span>
        </a>
        |
        <a class="text-primary mx-1" style="font-size: small;" (click)="onRefreshClicked()" >
          <i class="fas fa-redo-alt fa-rotate-180 text-primary me-1"></i> Refresh
        </a>
      </ng-container>
      <i *ngIf="!loanFees" class="d-block task-value fas fa-sync-alt fa-spin"
        style="font-size: 1rem; margin-top: 4px; margin-bottom: 4px;">
      </i>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12 mb-2">
      <span class="fs-5"> <strong> Due from Borrower(s) </strong> </span>
    </div>

    <div class="col-md-12 fs-6">
      <span class="me-3"> A. </span>
      <span> Sales Contract Price </span>
      <span class="float-end"> {{ (ftc?.salesContractPrice_A | currency ) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> B. </span>
      <span> Improvements, Renovations, and Repairs </span>
      <span class="float-end"> {{ (ftc?.improvementsRenovationsRepairs_B | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> C. </span>
      <span> Land Value </span>
      <span class="float-end"> {{ (ftc?.landValue_C | currency) || '--' }} </span>
    </div>
    <hr>
    <ng-container *ngIf="isRefinance">
      <div class="col-md-12 fs-6">
        <span class="me-3"> D. </span>
        <span> For Refinance: Balance of Mortgage Loans on the Property to be paid off in the Transaction </span>
        <span class="float-end"> {{ (ftc?.totalPaidOffForRefinance_D | currency) || '--' }} </span>
      </div>
      <hr>
    </ng-container>
    <div class="col-md-12 fs-6">
      <span class="me-3"> E. </span>
      <span> Credit Cards and Other Debts Paid off </span>
      <span class="float-end"> {{ (ftc?.debtsPaidOff_E | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> E2. </span>
      <span> Price of Personal Property Included in Sale </span>
      <span class="float-end"> {{ (ftc?.priceOfPersonalPropertyIncludedInSale | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> E3. </span>
      <span> Adjustments Due from Borrower at Closing </span>
      <span class="float-end"> {{ (ftc?.adjustmentsDueFromBorrowerAtClosing | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> F. </span>
      <span> Borrower Closing Costs (including Prepaid and Initial Escrow Payments) </span>
      <span class="float-end"> {{ (ftc?.borrowerClosingCostsTotal_F | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> Estimated Prepaid Items </span>
      <span class="float-end"> {{ (ftc?.estimatedPrepaidItems | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> Estimated Escrow Items </span>
      <span class="float-end"> {{ (ftc?.estimatedEscrowItems | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> Estimated Closing Costs </span>
      <span class="float-end"> {{ (ftc?.estimatedClosingCosts | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> PMI, MIP, Funding Fee </span>
      <span class="float-end"> {{ (ftc?.pmiMipFundingFee | currency) || '--' }} </span>
    </div>
    <hr>
    <ng-container *ngIf="ftc?.toleranceCurePrincipalReductionTotal && ftc?.toleranceCurePrincipalReductionTotal > 0">
      <div class="col-md-12 fs-6" >
        <span class="me-5"> </span>
        <span> Tolerance Cures by Principal Reduction </span>
        <span class="float-end"> {{ ((ftc?.toleranceCurePrincipalReductionTotal * -1) | currency) || '--' }} </span>
      </div>
      <hr>
    </ng-container>
    <div class="col-md-12 fs-6">
      <span class="me-3"> G. </span>
      <span> Discount Points </span>
      <span class="float-end"> {{ (ftc?.discountPoints_G | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> H. </span>
      <span class="fw-bold"> Total due from borrower(s) (total of A through G) </span>
      <span class="fw-bold float-end"> {{ (ftc?.totalDueFromBorrowerAThroughG_H | currency) || '--' }} </span>
    </div>
    <div class="col-md-12 mt-4 mb-2">
      <span class="fs-5"> <strong> Mortgage Loan Details </strong> </span>
    </div>
    <div class="col-md-12 fs-6">
      <span class="me-3"> I. </span>
      <span> Loan Amount </span>
      <span class="float-end"> {{ (ftc?.loanAmount_I | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> Loan Amount Excluding Financed Mortgage Insurance (or Mortgage Insurance Equivalent) * </span>
      <span class="float-end"> {{ (ftc?.loanAmountExcludingFinancedMip | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> Financed Mortgage Insurance (or Mortgage Insurance Equivalent) Amount </span>
      <span class="float-end"> {{ (ftc?.financedMip | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> J. </span>
      <span> Other New Mortgage Loans on the Property the Borrower(s) is Buying or Refinancing </span>
      <span class="float-end"> {{ (ftc?.otherNewMortgageLoansOnSubjectProperty_J | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> K. </span>
      <span class="fw-bold"> Total mortgage loans (total of I and J) </span>
      <span class="fw-bold float-end"> {{ (ftc?.totalMortgageLoansIThroughJ_K | currency) || '--' }} </span>
    </div>
    <div class="col-md-12 mt-4 mb-2">
      <span class="fs-5"> <strong> Credit Details </strong> </span>
    </div>
    <div class="col-md-12 fs-6">
      <span class="me-3"> L. </span>
      <span> Seller Credits </span>
      <span class="float-end"> {{ (ftc?.sellerCredits_L | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-3"> M. </span>
      <span> Other Credits </span>
      <span class="float-end"> {{ (ftc?.otherCredits_M | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6">
      <span class="me-5"> </span>
      <span> Lender Credit (if any) <span *ngIf="ftc?.toleranceCureLenderCreditTotal && ftc?.toleranceCureLenderCreditTotal > 0">(Includes {{ (ftc?.toleranceCureLenderCreditTotal | currency) }} tolerance cures)</span></span>
      <span class="float-end"> {{ (ftc?.lenderCredits | currency) || '--' }} </span>
    </div>
    <hr>
    <div class="col-md-12 fs-6" *ngIf="!isRefinance && ftc.emd">
      <span class="me-5"> </span>
      <span> EMD (if any) </span>
      <span class="float-end"> {{ (ftc.emd | currency) || '--' }} </span>
    </div>
    <hr *ngIf="!isRefinance && ftc.emd">
    <div class="col-md-12 fs-6">
      <span class="me-3"> N. </span>
      <span class="fw-bold"> Total credits (total of L and M) </span>
      <span class="fw-bold float-end"> {{ (ftc?.totalCreditsLThroughM_N | currency) || '--' }} </span>
    </div>
  </div>
</div>
<div class="col-md-12 ftc-container-footer">
  <div class="col-md-12" style="border-bottom: 1px solid gainsboro;">
    <span class="fs-5"> <strong> Calculated Totals </strong> </span>
  </div>
  <div class="col-md-12 mb-1 mt-2 fs-6">
    <span> Total due from borrower(s) (Line H) </span>
    <span class="fw-bold float-end"> {{ (ftc?.totalDueFromBorrowerAThroughG_H | currency) || '--' }} </span>
  </div>
  <div class="col-md-12 mb-2 fs-6">
    <span> Less total mortgage loans and total credits (N + K) </span>
    <span class="fw-bold float-end"> {{ (ftc?.totalMortgageLoansAndTotalCreditsNAndK | currency) || '--' }} </span>
  </div>
  <div style="border-bottom: 1px solid gainsboro;"></div>
  <div class="col-md-12 mt-2 mb-3 fs-5">
    <span class="me-3"> <strong> Cash from/to the borrower (H - N - K) </strong>
      <span class="ms-1" [ngClass]="{'text-success': isExceedFtc, 'text-danger': !isExceedFtc}">
        <i class="fa" [ngClass]="{'fa-check': isExceedFtc, 'fa-times-circle': !isExceedFtc}"></i>
        {{ isExceedFtc ? 'Assets exceed FTC' : 'Insufficient Assets' }}
      </span>
    </span>
    <span class="float-end" style="color: #189e64;"> <strong> {{ (ftc?.cashFromToBorrower | currency) || '--' }} </strong>
    </span>
  </div>
</div>
