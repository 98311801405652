import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { clone } from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { SecurityCodeDialog } from 'src/app/shared/components';

@Component({
  selector: 'app-tpo-settings',
  templateUrl: './tpo-settings.component.html',
  styleUrls: ['./tpo-settings.component.scss']
})
export class TpoSettingsComponent implements OnInit {
  countries: Array<Select2OptionData> = [];
  countriesList = [];

  countriesOptions = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
  };
  setting = {
    phone: null,
    areaCode: null,
    isTwoFactor: null
  }
  currentPhone: string;

  updatingPhoneNumber: boolean = false;

  constructor(
    private _applicationContextService: ApplicationContextService,
    private _notificationService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly _authService: AuthService,
    private _service: ProfileService,
  ) { }

  ngOnInit(): void {
    this._applicationContextService.context.subscribe(
      (result) => {
        this.currentPhone = result.currentlyLoggedInUser.phoneNumber;
        this.setting.isTwoFactor = result.currentlyLoggedInUser.twoFactor;
      }, ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to load data',
          'Error!'
        );
      }
    )
    this.countries = this._enumsService.countries.map((val: any) => ({
      id: val.areaCode,
      text: `(${val.areaCode}) ${val.name}`,
      code: val.areaCode
    }))
  }

  updatePhone() {
    this.updatingPhoneNumber = true;
    this._service.updatePhoneNumber(this.setting.phone, this.setting.areaCode)
      .pipe(finalize(() => this.updatingPhoneNumber = false))
      .subscribe({
        next: (res) => {
          const modalRef = this._modalService.open(SecurityCodeDialog, Constants.modalOptions.medium)
          modalRef.componentInstance.confirmOperation.subscribe((securityCode: string) => {

            modalRef.componentInstance.isSending = true;
            this._authService.confirmPhoneNumber(this.setting.phone, this.setting.areaCode, securityCode)
              .pipe(finalize(() => modalRef.componentInstance.isSending = false))
              .subscribe({
                next: () => {
                  this.currentPhone = clone(this.setting.phone);
                  modalRef.close();
                  this._notificationService.showSuccess(
                    'Phone successfuly updated',
                    'Success!'
                  );
                },
                error: (error) => {
                  if (error?.code === "InvalidToken") {
                    this._notificationService.showError(error.description, "Error");
                  } else {
                    this._notificationService.showError(error?.message || "Couldn't process the confirmation code", "Error");
                  }
                }
              })
          })
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'Unable to update phone number',
            'Profile Service'
          );
        }
      });
  }

  changeTwoFactor() {
    this._spinner.show();
    this._service.updateTwoFactor(this.setting.isTwoFactor)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Two factor authentication setting successfuly updated',
            'Success!'
          );
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'An error occurred updating two factor authentication setting',
            'Error!'
          );
        }
      })
  }

  // TODO verify
  onNewPhoneNumberChanged = () => {
    if (!this.setting.phone && !this.currentPhone) {
      this.setting.isTwoFactor = false;
      this.changeTwoFactor();
    }
  }
}
