import { Directive } from "@angular/core";
import { AbstractControl, ValidationErrors, NG_VALIDATORS } from "@angular/forms";

export function requireNonAplhanumericValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const regexp = /[^a-zA-Z0-9]/g;
    const isValid = !control.value || regexp.test(control.value);
    return isValid ? null : { requiresNonAplhaNumeric: { value: control.value } };
  }
}
@Directive({
  selector: '[requiresNonAplhaNumeric]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequireNonAplhanumericValidatorDirective,
      multi: true,
    },
  ],
})
export class RequireNonAplhanumericValidatorDirective {
  validate(control: AbstractControl): ValidationErrors | null {
    return requireNonAplhanumericValidator()(control);
  }
}