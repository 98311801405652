import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentType,
  Key,
  ThirdPartyCredential,
  ThirdPartyKeyValue,
  ThirdPartyKeyValueArray
} from '../../../../../../models';
import {ChannelService} from '../../../../../../services/channel.service';
import {ThirdPartyCredentialsService} from '../../../../../../services/third-party-credentials.service';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {SystemLevelService} from '../../../../../../services/system-level.service';
import {NotificationService} from '../../../../../../services/notification.service';
import {ApplicationContextBoundComponent} from '../../../../../../shared/components';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'form-free-asset-verification-modal',
  templateUrl: './form-free-asset-verification-modal.component.html'
})
export class FormFreeAssetVerificationModal extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  credential: ThirdPartyCredential;
  documentTypes: Array<DocumentType>;

  options = {
    CreateVoaRequestType: [],
    AccountMonitoringInterval: [],
    AutoUpgradeAfterLiteReport: []
  };
  keys: Key[];
  keyValuePairs = {
    savedDocumentTypeId: null,
    voaReportDocumentTypeId: null,
    voiReportDocumentTypeId: null,
    createVoaRequestType: null,
    accountMonitoringInterval: null,
    autoUpgradeAfterLiteReport: null
  };
  additionalConfigs: ThirdPartyKeyValueArray = [];
  isSaving: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _notificationService: NotificationService,
    public activeModal: NgbActiveModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keyValuePairs.savedDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SavedDocumentTypeId') || new ThirdPartyKeyValue('SavedDocumentTypeId', "");
    this.keyValuePairs.voaReportDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'VOAReportDocumentTypeId') || new ThirdPartyKeyValue('VOAReportDocumentTypeId', "");
    this.keyValuePairs.voiReportDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'VOIReportDocumentTypeId') || new ThirdPartyKeyValue('VOIReportDocumentTypeId', "");
    this.additionalConfigs = this.credential.thirdPartyKeyValuePairs.filter(el => ['CreateVoaRequestType', 'AccountMonitoringInterval', 'AutoUpgradeAfterLiteReport'].includes(el.key));
    this.keys = [
      { key: 'CreateVoaRequestType', displayText: 'Create VOA Request Type' },
      { key: 'AccountMonitoringInterval', displayText: 'Account Monitoring Interval' },
      { key: 'AutoUpgradeAfterLiteReport', displayText: 'Auto Upgrade After Lite Report' },
    ];

    this.loadKeys();
  }

  addConfig(): void {
    this.additionalConfigs.push({
      key: 'CreateVoaRequestType',
      value: ''
    })
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.keyValuePairs.createVoaRequestType = this.additionalConfigs.find(el => el.key === 'CreateVoaRequestType') || {};
    this.keyValuePairs.accountMonitoringInterval = this.additionalConfigs.find(el => el.key === 'AccountMonitoringInterval') || {};
    this.keyValuePairs.autoUpgradeAfterLiteReport = this.additionalConfigs.find(el => el.key === 'AutoUpgradeAfterLiteReport') || {};
    this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);
    this.isSaving = true;
    this.credential.companyId = this.applicationContext.userPermissions.companyId;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }

  loadKeys(): void {
    combineLatest([
      this._loanServicesService.getFormFreeRequestTypeOptions(),
      this._loanServicesService.getFormFreeAccountMonitoringOptions(),
      this._loanServicesService.getFormFreeReportUpgradeOptions(),
    ]).subscribe(
      ([requestTypeOptions, accountMonitoringOptions, reportUpgradeOptions]) => {
        this.options.CreateVoaRequestType = Object.keys(requestTypeOptions).map(key => ({
          key: requestTypeOptions[key],
          displayText: key
        }));
        this.options.AccountMonitoringInterval = Object.keys(accountMonitoringOptions).map(key => ({
          key: accountMonitoringOptions[key],
          displayText: key
        }));
        this.options.AutoUpgradeAfterLiteReport = Object.keys(reportUpgradeOptions).map(key => ({
          key: reportUpgradeOptions[key],
          displayText: key
        }));
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to load FormFree options',
          'VOA Loan Service'
        );
      }
    );
  }
}
