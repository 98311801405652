<div class="row mb-2">
  <div class="col-3 label">Status:</div>
  <div class="col-9">
    <div [ngSwitch]="message.status">
      <span *ngSwitchCase="'Success'">
        <label class="text-success form-label" *ngIf="message.mostRecentOpenDate">
          <i class="far fa-check-circle "></i>
          {{'Opened ' + (message.mostRecentOpenDate | date:'short') }}
        </label>
        <label class="text-success form-label" *ngIf="!message.mostRecentOpenDate">
          <i class="far fa-check-circle"></i>
          Sent
        </label>
      </span>
      <div *ngSwitchCase="'Failure'">
        <label class="text-danger form-label">
          <i class="far fa-times-circle"></i>
          Error
        </label>
      </div>
      <span *ngSwitchCase="'InProcess'" class="fas fa-circle text-info">
        Processing
      </span>
      <span *ngSwitchCase="'Unprocessed'" class="fas fa-circle text-info">
        In Queue
      </span>
      <span *ngSwitchCase="'FailureSmsInvalidRecepient'" class="far fa-times-circle text-danger">
        Invalid Recepient
      </span>
      <span *ngSwitchDefault class="fas fa-circle text-info">
        {{message.status}}
      </span>
    </div>
    <div *ngIf="message.errorDetail" class="text-danger mt-1">{{message.errorDetail}}</div>
  </div>
</div>
<div class="row" *ngIf="message.extendedDetail">
  <div class="col-3 label">Details:</div>
  <div class="col-9">
    <label class="form-label">{{message.extendedDetail}}</label>
  </div>
</div>