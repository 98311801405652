<div class="card-body" *ngIf="processSubmissionData" style="min-height:200px;">
  <form class="form-horizontal auth-form" #keyDatesForm="ngForm" novalidate id="keyDatesForm" name="keyDatesForm"
    action="#">
    <div class="row justify-content-center">
      <div class="ps-3 pe-3">
        <div class="grid-layout-row">
          <div *ngFor="let keyDate of keyDates" style="margin: 5px 0">
            <div class="col-md-12 d-flex text-center justify-content-between align-items-center mx-auto">
              <label [title]='keyDate.displayName' class='text-start form-label'>
                {{keyDate.displayName}}
              </label>

              <date-input type="date" autocomplete="off" id="keyDate-{{keyDate.name}}" name="keyDate-{{keyDate.name}}"
                [(ngModel)]="keyDate.value" (blur)="onKeyDateChanged(keyDate)"
                (selectedDate)="onKeyDateChanged(keyDate)">
              </date-input>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- <div class="text-center">
  <button class="btn btn-primary me-2" (click)="back()">Back</button>
  <button id="new-app-loan-char-proceed-btn" class="btn btn-primary" (click)="proceed()">
    Proceed To {{ showMissingInfo ?
    'Missing Information' : allowedLoanCharacterstics.length > 0 ?
    'Loan Characteristics' : allowedBorrowerCharacterstics.length > 0 ?
    'Borrower Characteristics' : 'Final Review' }}
  </button>
</div> -->
