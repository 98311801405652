import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { DefinedSmsTemplate } from '../../models';
import { finalize } from 'rxjs/operators';
import { SystemLevelService } from '../../../../services/system-level.service';
import { NotificationService } from '../../../../services/notification.service';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { MergeFieldContextMenuComponent } from '../../../../shared/components/merge-field-context-menu/merge-field-context-menu.component';
import { MergeFieldsService } from '../../../../services/merge-fields.service';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { DataService } from 'src/app/core/services/data.service';
import { Configuration } from 'src/app/models/configuration.model';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'sms-templates',
    templateUrl: './sms-templates.component.html'
})

export class SmsTemplatesComponent extends ApplicationContextBoundComponent implements OnInit {
    @ViewChild(MergeFieldContextMenuComponent) mergeFieldContextMenu: MergeFieldContextMenuComponent;

    @Input()
    definedSmsTemplate: DefinedSmsTemplate;

    @Input()
    smsAlertsEnabled: boolean;

    @Input()
    type: string;

    @Input()
    reminderSmsEnabled: Configuration;

    @Input()
    reminderSmsInterval: Configuration;

    isSaving: boolean;
    availableMergeFields: EnumerationItem[];
    defaultSmsTemplates = [];

    constructor(
        private readonly injector: Injector,
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService,
        private readonly _dataService: DataService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.availableMergeFields = this.applicationContextService.getMergeFieldsByTypes(['app', 'agent', 'borrower']);
        this.loadSmsTemplates();
    }

    save(): void {
        this.isSaving = true;
        combineLatest([
            this._systemLevelService.saveDefinedSmsTemplate(this.definedSmsTemplate),
            this._systemLevelService.saveConfiguration(this.reminderSmsEnabled),
            this._systemLevelService.saveConfiguration(this.reminderSmsInterval),
        ]).pipe(finalize(() => this.isSaving = false))
            .subscribe({
                next: () => {
                    this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
                },
                error: error => {
                    this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
                }
            });
    }

    onContextMenu($event: MouseEvent, target: DefinedSmsTemplate, targetProperty: string): void {
        this.mergeFieldContextMenu.show(
            $event,
            target,
            targetProperty,
            null
        );
        $event.preventDefault();
        $event.stopPropagation();
    }

    populateDefaultSmsTemplate(isChecked: boolean, templateType: string, type: string, forceReload: boolean = false): void {
        if (!isChecked || (!forceReload && this.definedSmsTemplate[type])) {
            return;
        }

        const matchingTemplate = this.defaultSmsTemplates.find(template => template.templateType === templateType);
        if (matchingTemplate) {
            this.definedSmsTemplate[type] = matchingTemplate.message;
        }
    }

    private loadSmsTemplates(): void {
        this._dataService.get('api/Admin/AllGlobalSmsTemplates').subscribe({
            next: res => {
                this.defaultSmsTemplates = res;
            },
            error: (error) => {
                this._notificationService.showError(error?.message || "Couldn't get sms templates.", "System Level");
            }
        });
    }
}
