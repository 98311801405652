<div class="d-flex justify-content-center" *ngIf="isCalculatingFees">
  <div class="d-flex">
    <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
    <h5 class="font-18 text-muted me-4">Calculating Loan Fees...</h5>
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="feesCalculated || !autoCalculateLoanFees">
  <div class="d-flex">
    <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></span>
    <h5 class="font-18 text-muted me-4">Loan Fees Calculated Successfully!</h5>
  </div>
</div>
