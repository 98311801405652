import { Component, Input } from '@angular/core';
import { LicenseValidationResult } from '../../../models/license-validation.result.model';
import { LicenseInfoStatusBadgeVariant } from '../license-info-badge/license-info-badge.component';


@Component({
  selector: 'license-info-status-badge',
  templateUrl: './license-info-status-badge.component.html',
  styleUrls: ['./license-info-status-badge.component.scss'],
})
export class LicenseInfoStatusBadgeComponent {
  protected content: BadgeContent | undefined;

  @Input()
  set status(value: LicenseValidationResult) {
    this.content = getContentForStatus(value);
  }
}

interface BadgeContent {
  variant: LicenseInfoStatusBadgeVariant,
  iconClassName?: string;
  label: string;
}

function getContentForStatus(status: LicenseValidationResult): BadgeContent | undefined {
  switch (status) {
    case LicenseValidationResult.Pass:
      return {
        variant: 'success',
        iconClassName: 'fa fa-check-circle',
        label: 'Pass',
      };
    case LicenseValidationResult.Fail:
      return {
        variant: 'error',
        iconClassName: 'fa fa-times-circle',
        label: 'Fail',
      };
    case LicenseValidationResult.NotApplicable:
      return {
        variant: 'neutral',
        label: 'N/A',
      };
    default:
      console.error(`Unknown license validation result: ${status}`);
      return undefined;
  }
}
