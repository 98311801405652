<div class="card">
  <div class="card-header">
    <h4 class="card-title fee-section-header">
      <div>
        <i [hidden]="isValidForm" class="fas fa-exclamation-triangle alert-icon text-danger align-self-center me-1"></i>
        H. OTHER 1300. Additional Settlement Charges
      </div>
      <fee-totals [fees]="fees"></fee-totals>
    </h4>
  </div>
  <div class="card-body">
    <a class="btn btn-sm btn-soft-primary me-2" (click)="openAddFeeModal()" role="button">
      <i class="fas fa-plus me-2"></i>Add Fee
    </a>
    <br><br>
    <form #otherForm="ngForm">
      <default-fees
        [fees]="fees"
        (openFeeEditor)="onFeeEditorOpened($event)"
        (onFeeRemoved)="removeFee($event)"
        (onFeeValueUpdated)="updateFee($event)"
      ></default-fees>
    </form>
  </div>
</div>
