<div class="row">
  <div class="card">
    <div class="card-body">
      <div class="fieldset-title pt-3">Document Stacking Order</div>
      <div class="pt-3 pb-3">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Stacking Type</label>
              <div class="col-sm-10">
                <select class="form-select" [(ngModel)]="profile.stackOrderId">
                  <option value="0">-- ALL --</option>
                  <option *ngFor="let item of docTypes" value={{item.stakingOrderId}}>
                    {{item.docType}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fieldset-title pt-3">Dashboards</div>
      <div class="pt-3 pb-3">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 row">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Default Dashboard</label>
              <div class="col-sm-10">
                <select class="form-select" [(ngModel)]="profile.defaultDashboardConfigId">
                  <option [ngValue]="0">-- Select One --</option>
                  <option *ngFor="let dashboard of dashboards" [ngValue]='dashboard.dashboardConfigId'>
                    {{dashboard.displayName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fieldset-title pt-3">Overrides</div>
      <div class="pt-3 pb-3">
        <div class="row">
          <div class="form-group col-md-12">
            <div class="form-check form-switch form-switch-success">
              <input id="aclOverride" class="form-check-input" type="checkbox" name="aclOverride"
                [(ngModel)]="allowCopyLoanOverride.value" ToggleValue [true]="1" [false]="0">
              <label class="form-check-label" for="aclOverride">
                Allow Copy Application Override
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <div class="form-check form-switch form-switch-success">
              <input id="adtOverride" class="form-check-input" type="checkbox" name="adtOverride"
                [(ngModel)]="allowDeleteTaskOverride.value" ToggleValue [true]="1" [false]="0">
              <label class="form-check-label" for="adtOverride">
                Allow Delete Task Override
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <div class="form-check form-switch form-switch-success">
              <input id="aceOverride" class="form-check-input" type="checkbox" name="aceOverride"
                [(ngModel)]="allowCancelEsignOverride.value" ToggleValue [true]="1" [false]="0">
              <label class="form-check-label" for="aceOverride">
                Allow Cancel eSign Order Override
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
