<div class="modal-header">
  <h5 class="modal-title">Reset To Default Mappings</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <span>
        Are you sure you'd want to reset to default mappings?
      </span>

      <div class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3" role="alert">
        <div class="media">
          <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
          <div class="media-body align-self-center">
            <span>This operation will delete all existing mappings and replace them with default ones.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close(true)">Reset</button>
</div>
