import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[numeric]'
})

export class NumericDirective {

  @Input()
  decimals: number | null = 0;

  @Input()
  allowNegative: boolean = true;

  @Input()
  max: number | null = null;

  @Input()
  min: number | null = null;

  @Input()
  inputMaxLength: number | null = null;

  private _specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
  ];

  private _ctrlDown: boolean = false;

  constructor(private el: ElementRef) {
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    let pastedValue = (event.clipboardData || window.Clipboard).getData('text');
    if (!this.checkIfValid(pastedValue)) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._specialKeys.indexOf(event.key) !== -1) {
      return;
    } else if (event.which === 17) {
      // ctrl key or command key on a Mac
      this._ctrlDown = true;
    } else if (this._ctrlDown && [86, 67].indexOf(event.which) > -1) {
      // ctrl+c or ctrl+v
      return;
    } else {
      let current: string = this.el.nativeElement.value;
      // console.log(current);
      let next: string = current.concat(event.key);

      const selection = document.getSelection().toString();
      if (selection && selection === current) {
        next = event.key;
      }
      // console.log(next);
      if (!this.checkIfValid(next)) {
        event.preventDefault();
      }
    }
  }

  private checkIfValid = (value: any): boolean => {
    if (value) {
      if (!this.check(value, this.decimals)) {
        return false;
      } else {
        if (!this.allowNegative) {
          if (value.includes('-')) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private check(value: string, decimals: number) {
    if (decimals === null || decimals === undefined) {
      return "";
    }
    if (this.max !== null && Number(value) > this.max) {
      return false;
    }
    if (this.min !== null && Number(value) < this.min) {
      return false;
    }
    if (this.allowNegative && value === '-') {
      return String(value).match(new RegExp(/-/));
    }
    if (decimals <= 0) {
      const maxLength = this.inputMaxLength ?? 9;
      return String(value).match(new RegExp("^[-]?[0-9]{1," + maxLength + "}?$")); //^-?\d+$    ^-?\d+$
    } else {
      var regExpString = "^[-]?\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$"
      return String(value).match(new RegExp(regExpString));
    }
  }
}
