<div class="modal-header">
    <h5 class="modal-title">
        <div class="d-flex justify-content-between">
            <div class="align-self-center"> Custom Field Options
            </div>

        </div>
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
    <form #customFieldListOptionForm="ngForm">
        <div class="row pt-4">
            <div class="col-md-12 ">
                <div class="fieldset-title">
                    <div class="row">
                        <div class="col-md-4 text-start">
                            <button class="btn btn-outline-primary" (click)="addCustomFieldOption()">
                                Add Custom Field Option
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table *ngIf="customField.options.length" class="table mb-0">
            <thead class="table-light">
                <tr>
                    <th class="col-3"> Value</th>
                    <th class="col-8"> Description </th>
                    <th class="col-1"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of customField.options; let i = index;  trackBy: trackByIndex">
                    <td class="col-3">
                        <input type="text" class="form-control" name="custom-field-value-{{i}}" [(ngModel)]="item.value"
                            #customFieldOptionValueField="ngModel"
                            [ngClass]="{'is-invalid': customFieldOptionValueField && customFieldOptionValueField.touched && customFieldOptionValueField.invalid}"
                            required />
                    </td>
                    <td class="col-4">
                        <input type="text" class="form-control" name="custom-field-desc-{{i}}"
                            [(ngModel)]="item.description" #customFieldOptionDescField="ngModel"
                            [ngClass]="{'is-invalid': customFieldOptionDescField && customFieldOptionDescField.touched && customFieldOptionDescField.invalid}"
                            required />
                    </td>
                    <td class="col-1">
                        <confirm-delete [list]="customField.options" [index]="i"></confirm-delete>
                    </td>
            </tbody>
        </table>
    </form>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
        Cancel
    </button>

    <button type="button" class="btn btn-primary" (click)="ok()">OK</button>
</div>