<div class="modal-header">
  <h5 class="modal-title"> Switching to - {{companyName}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="mb-3 row mx-2">

    <label class="form-label" for="company">Please pick a role</label>
    <select class="form-select" name="company" id="company" [(ngModel)]="selectedRoleId"
      placeholder="Select a Role">
      <option [ngValue]="-1">--Select One--</option>
          <option *ngFor="let role of roles; let i = index" [ngValue]="role.roleIId">
            {{role.userType}}
          </option>
    </select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-primary float-end" type="button" [disabled]="selectedRoleId === -1"
    (click)="onSwitchClicked()">
    <span *ngIf="isSwitching" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ isSwitching ? 'Logging in...' : 'Login'}}
  </button>
</div>
