<div class="modal-header">
  <h5 class="modal-title">Loan Amount Calculator</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #loanAmountForm="ngForm" novalidate id="loanAmountForm" name="loanAmountForm">
    <div class="mb-3">
      Loan Amount: <b> {{ loanAmountLocal | currency }} </b>
    </div>

    <div class="w-100">
      <label class="form-label" for="downPaymentRatio"> Downpayment Percentage </label>
      <div class="d-flex flex-row flex-wrap">
        <div class="radio radio-info form-check-inline align-self-center" *ngFor="let ratio of predefinedRatios" style="flex-basis: 25%;">
          <input type="radio" id="option{{ratio * 100}}" [value]="ratio" name="downPaymentRatio" [(ngModel)]="downPaymentRatio"
            (ngModelChange)="onDownPaymentRatioChanged()" />
          <label for="option{{ratio * 100}}" class="form-label align-self-center mb-lg-0">
            {{ratio | percent:'1.0-2'}}
          </label>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap align-items-center mb-1 mt-2">
      <div style="flex-basis: 25%;">
        <div class="radio radio-info form-check-inline">
          <input type="radio" id="optionOther" [value]="null" name="downPaymentRatio" [(ngModel)]="downPaymentRatio"
            (ngModelChange)="onDownPaymentRatioChanged()" />
          <label for="optionOther" class="form-label mb-lg-0">
            Other
          </label>
        </div>
      </div>
      <div style="flex-basis: 50%;">
        <div class="w-100 d-flex">
          <label class="form-label align-self-center" for="optionOther">%</label>
          <percent-input class="d-inline-block ms-2" [(ngModel)]="customDownPaymentRatio"
            [readonly]="downPaymentRatio != null" (blur)="onDownPaymentRatioChanged()" name="optionOther"
            [digitsInfo]="'1.3-3'" [rateIsTrueToTextValue]="false">
          </percent-input>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap align-items-center">
      <div style="flex-basis: 25%;"></div>
      <div style="flex-basis: 50%;">
        <div class="w-100 d-flex">
          <label class="form-label align-self-center" for="downpaymentAmount">$</label>
          <currency-input class="d-inline-block ms-2" [(ngModel)]="downPayment" name="downpaymentAmount"
            [allowDecimals]="false" [placeholder]="'Downpayment Amount'" (blur)="onDownpaymentAmountChanged()">
          </currency-input>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-between">
  <div>
    <span>
      Purchase Price: <b> {{ purchasePrice | currency }}</b>
    </span>
  </div>
  <div class="button-items">
    <button type="button" class="btn btn-primary right" (click)="onOkClicked()"
      [disabled]="customDownPaymentRatio && customDownPaymentRatio > 1">
      Ok
    </button>
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
      Cancel
    </button>
  </div>
</div>
