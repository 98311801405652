import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { KeyDatesByType } from 'src/app/models';
import { EscrowDisbursement, EscrowFeeSchedule } from 'src/app/models/fee/escrow-fee-schedule.model';
import { EscrowSchedule } from 'src/app/models/fee/escrow-schedule.model';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { UpdateFeesFromEscrowScheduleRequest } from 'src/app/models/fee/update-fees-from-escrow-schedule-request.model';
import { FeeService } from 'src/app/services/fee.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { FeeUtils } from '../services/fee-utils.service';

@Component({
  selector: 'escrow-fees-schedule',
  templateUrl: './escrow-fees-schedule.component.html',
  styleUrls: ['./escrow-fees-schedule.component.scss']
})
export class EscrowFeesScheduleComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  fees: LoanFee[];

  @Input()
  appId: number;

  @Output()
  escrowScheduleUpdated = new EventEmitter<any>();

  escrowSchedule: EscrowSchedule = new EscrowSchedule();

  monthYears: any[] = [];

  loading: boolean = true;

  firstPaymentDate: Date = null;

  lockedRows: any[] = [];

  constructor(
    private readonly _feeService: FeeService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _loanService: LoanService,
    private readonly _feeUtils: FeeUtils,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.getKeyDatesByType();
  }

  reloadData = () => {
    this.getKeyDatesByType();
  }

  onFirstPaymentDateChanged = (date: string) => {
    this.firstPaymentDate = date ? new Date(date) : null;

    if (this.firstPaymentDate) {
      this.populateMonthYears();
    }

    this.saveFirstPaymentKeyDate();
    this.onFieldChanged();
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" && enumValue != "Unknown" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  onFieldChanged = () => {
    this.updateFeeSchedules();
  }

  onDueDateChanged = (feeSchedule: EscrowFeeSchedule, index: number, date: string) => {

    if (date && new Date(date) < new Date(this.firstPaymentDate)) {
      return;
    }

    feeSchedule.disbursements[index].disbursementDate = date ? Utils.parseAsLocal(date) : null;
    feeSchedule.disbursements[index].amount = date ? (feeSchedule.disbursements[index].amount || 0) : null;

    if (date) {
      // clear date if already exist for same month/year
      let dateFormatted = this.getFormattedMonthYearText(date);

      let sameMonthExistIndex = feeSchedule.disbursements.findIndex(d => this.getFormattedMonthYearText(d.disbursementDate) == dateFormatted)
      if (sameMonthExistIndex > -1 && sameMonthExistIndex != index) {
        feeSchedule.disbursements[index].disbursementDate = null;
        feeSchedule.disbursements[index].amount = 0;
      }

      // clear before values
      this.monthYears.forEach(my => {
        Object.keys(my.values).forEach(feeType => {
          if (feeType == feeSchedule.feeType) {
            let val = my.values[feeType];
            val.amount = 0;
            val.months = 0
          }
        });
      })
    }

    // calculate amount/months values
    let filledDates = feeSchedule.disbursements.filter(d => !!d.disbursementDate);

    feeSchedule.disbursements.forEach(d => {
      let matchedMonthYear = this.getMatchedDisbursementMonthYear(d.disbursementDate);
      if (matchedMonthYear) {
        matchedMonthYear.values[feeSchedule.feeType].months = filledDates.length ? (12 / filledDates.length) : 0;

        matchedMonthYear.values[feeSchedule.feeType].amount = (feeSchedule.monthlyEscrowDeposit || 0) * (matchedMonthYear.values[feeSchedule.feeType].months || 0);
        d.amount = matchedMonthYear.values[feeSchedule.feeType].amount;
      }
    })

    this.onFieldChanged();
  }

  onMonthsChanged = (feeSchedule: EscrowFeeSchedule, monthYearRow: any) => {

    // recalculate amounts
    monthYearRow.values[feeSchedule.feeType].amount = (feeSchedule.monthlyEscrowDeposit || 0) * (monthYearRow.values[feeSchedule.feeType].months || 0);

    feeSchedule.disbursements.forEach(d => {
      if (!!d.disbursementDate) {
        if (this.getFormattedMonthYearText(d.disbursementDate) == monthYearRow.monthYear) {
          d.amount = monthYearRow.values[feeSchedule.feeType].amount;
        }
      }
    })

    // set due date if not exist
    let existDueDates = feeSchedule.disbursements.filter(d => {
      if (!!d.disbursementDate) {
        let dateFormatted = this.getFormattedMonthYearText(d.disbursementDate);
        if (monthYearRow.monthYear == dateFormatted) {
          return true
        }
      }
    });

    if (existDueDates.length == 0) {
      let dateParts = monthYearRow.monthYear.split("/");
      let firstEmptyIndex = feeSchedule.disbursements.findIndex(d => !d.disbursementDate);
      feeSchedule.disbursements[firstEmptyIndex].disbursementDate = new Date(Number(dateParts[1]), Number(dateParts[0]) - 1, 1);

      monthYearRow.values[feeSchedule.feeType].amount = (feeSchedule.monthlyEscrowDeposit || 0) * (monthYearRow.values[feeSchedule.feeType].months || 0);
      feeSchedule.disbursements[firstEmptyIndex].amount = monthYearRow.values[feeSchedule.feeType].amount;
    }
    else {
      if (!monthYearRow.values[feeSchedule.feeType].months) {
        monthYearRow.values[feeSchedule.feeType].amount = 0;
        monthYearRow.values[feeSchedule.feeType].months = 0;
        existDueDates[0].disbursementDate = null;
        existDueDates[0].amount = 0;
      }
    }

    this.onFieldChanged();
  }

  isAllDueDateFilled = (feeSchedule: EscrowFeeSchedule): boolean => {
    return feeSchedule.disbursements.filter(d => !!d.disbursementDate).length == 4;
  }

  private getKeyDatesByType = () => {
    this.loading = true;

    this._loanService.getKeyDatesByType(this.appId)
      .subscribe({
        next: (keyDates: KeyDatesByType) => {
          this.firstPaymentDate = keyDates["firstPayment"]?.eventDate ? new Date(keyDates["firstPayment"]?.eventDate) : null;
          this.getFeeSchedules();
        },
        error: () => {
        }
      })
      .add(() => {
        this.loading = false;
      })
  }

  private saveFirstPaymentKeyDate = () => {
    const request = {
      "FirstPayment": this.firstPaymentDate ? new Date(this.firstPaymentDate).toISOString() : null
    }

    this._loanService.saveKeyDatesByType(request, this.appId).subscribe({
      next: () => {
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Unable to save first payment key date", "Error");
      }
    })
  }

  private getFormattedMonthYearText = (date: Date | string): string => {
    if (!date) {
      return null;
    }

    let dt = new Date(date);
    return (dt.getUTCMonth() + 1 + "/" + dt.getUTCFullYear()).padStart(7, "0");
  }

  private getMatchedDisbursementMonthYear = (date: Date): any => {
    return this.monthYears.find(my => {
      if (date) {
        return this.getFormattedMonthYearText(date) == my.monthYear;
      }
    })
  }

  private getFeeSchedules = () => {
    this._feeService.getEscrowFeeSchedules(this.fees)
      .subscribe({
        next: schedule => {
          this.escrowSchedule = { ...schedule } || new EscrowSchedule();
          this.populateMissingDisbursements();
          this.populateMonthYears();

          // populate amounts
          this.monthYears.forEach(my => {
            this.escrowSchedule.feeSchedules.forEach(fs => {
              fs.disbursements.forEach(dis => {
                if (dis.disbursementDate && this.getFormattedMonthYearText(dis.disbursementDate) == my.monthYear) {
                  my.values[fs.feeType] = {
                    amount: dis.amount || 0,
                    months: dis.amount ? (dis.amount / (fs.monthlyEscrowDeposit || 1)) : 0
                  }
                }
              })
            })
          });

          if (!this.escrowSchedule.feeSchedules.length) {
            this._notificationService.showWarning("Add impounded fees to section G in the fees list", "Missing Fees");
            return;
          }

          // populate frozen data
          let lockedRows = [];
          lockedRows.push({ name: "Cushion", values: {} });
          lockedRows.push({ name: "Due Date 1", values: {} });
          lockedRows.push({ name: "Due Date 2", values: {} });
          lockedRows.push({ name: "Due Date 3", values: {} });
          lockedRows.push({ name: "Due Date 4", values: {} });

          this.escrowSchedule.feeSchedules.forEach(s => {
            lockedRows[0].values[s.feeType] = { value: s.cushionMonths || 0 };

            s.disbursements.forEach((d, index) => {
              lockedRows[index + 1].values[s.feeType] = { value: d.disbursementDate || null };
            })
          })

          this.lockedRows = lockedRows;
        },
        error: (err) => {
          this._notificationService.showError(err.message || err, "Error");
        }
      })
      .add(() => {
        this.loading = false;
      })
  }

  private updateFeeSchedules = () => {
    let req = new UpdateFeesFromEscrowScheduleRequest();
    req.firstPaymentDate = new Date(this.firstPaymentDate);

    let schedule = _.cloneDeep(this.escrowSchedule);
    schedule.feeSchedules.forEach(s => {
      s.disbursements = s.disbursements.filter(d => !!d.disbursementDate && !!d.amount);
    });

    req.schedule = schedule;
    req.fees = this.fees;

    this._spinnerService.show();

    this._feeService.updateEscrowFeeSchedules(req)
      .subscribe({
        next: res => {
          this.escrowSchedule = { ...res.schedule };
          this.populateMissingDisbursements();

          res.fees.forEach(fee => {
            if (!fee.calculatedValues) {
              const originalFeeIndex = this.fees.findIndex(f => f.loanFeeId === fee.loanFeeId);
              if (originalFeeIndex >= 0) {
                fee.calculatedValues = this.fees[originalFeeIndex].calculatedValues;
              }
            }
          });

          let sendedFees = res.fees.map(fee => {
            fee = this._feeUtils.initMissingFeeValues(fee);

            if (fee.escrowDisbursements.length) {
              fee.escrowDisbursements = fee.escrowDisbursements.filter(d => !!d.disbursementDate && !!d.amount);
            }

            return fee;
          });

          this.escrowScheduleUpdated.emit(sendedFees);
        },
        error: (err) => {
          this._notificationService.showError(err.message || err, "Error");
        }
      })
      .add(() => {
        this._spinnerService.hide();
      })
  }

  private populateMissingDisbursements = () => {
    if (this.escrowSchedule.feeSchedules.length > 0) {
      this.escrowSchedule.feeSchedules.forEach(feeSchedule => {
        let existDisbursementsItemsLength = feeSchedule.disbursements.length;
        if (existDisbursementsItemsLength < 4) {
          for (let i = 0; i < 4 - existDisbursementsItemsLength; i++) {
            feeSchedule.disbursements.push(new EscrowDisbursement())
          }
        }
        this.sortDisbursementDates();
      })
    }
  }

  private sortDisbursementDates = () => {
    this.escrowSchedule.feeSchedules.forEach(schedule => {
      schedule.disbursements = _.sortBy(schedule.disbursements, ['disbursementDate'], ['asc']);
    })
  }

  private populateMonthYears = () => {

    if (!this.firstPaymentDate) {
      return;
    }

    this.monthYears = [];

    for (let i = 0; i < 24; i++) {
      let nextMonth = DateTime.fromJSDate(new Date(this.firstPaymentDate)).plus({ months: i }).toJSDate();
      let monthsFormatted = this.getFormattedMonthYearText(nextMonth);

      let vals = {};
      this.escrowSchedule.feeSchedules.forEach(s => {
        vals[s.feeType] = {
          amount: 0,
          months: 0
        }
      });

      this.monthYears.push({
        monthYear: monthsFormatted,
        values: vals
      });
    }

  }

}
