import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanOfficerBranchDialogComponent } from '../loan-officer-branch-dialog/loan-officer-branch-dialog.component';

@Component({
  selector: 'internal-task-dialog',
  templateUrl: './internal-task-dialog.component.html',
  styleUrls: ['./internal-task-dialog.component.scss']
})
export class InternalTaskDialogComponent implements OnInit {

  promptBranchUpdate:boolean;

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal) { }

  ngOnInit(): void {
  }

  confirmReassign = (assignmentStatus:string) => {
    if (!this.promptBranchUpdate) {
      this.activeModal.close([assignmentStatus, 'false']);
      return;
    }

    let loanOfficerBranchModalRef = this._modalService.open(LoanOfficerBranchDialogComponent);
    loanOfficerBranchModalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.activeModal.close([assignmentStatus, result]);
      }
    }, (err) => {
      this.activeModal.dismiss();
    });
  }

}
