import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ForgotPasswordModel } from '../../models';
import { ErrorMessage } from '../../shared/models/error.model';
import { AuthService } from '../../services/auth.service';
import { Constants } from '../../services/constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { debounce } from 'lodash';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @ViewChild('forgotPasswordForm') forgotPasswordForm: NgForm | undefined;

  formData: ForgotPasswordModel;

  error: ErrorMessage | undefined;
  resetDone: boolean = false;

  possibleLogoUrls: string[] = [];
  availableCompanies: AuthenticationUserCompany[] = [];

  currentYear: number = new Date().getFullYear();

  private readonly _activatedRouteSubscription: Subscription;

  constructor(
    private readonly _authService: AuthService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly _spinnerService: NgxSpinnerService,
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    this.formData = new ForgotPasswordModel('', '', null);
    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      const scope = params[Constants.authorization.scope];
      const userName = params[Constants.authorization.email];

      if (scope) {
        this.formData.scope = scope;
      }

      if (userName) {
        this.formData.email = userName;
      }
    });
  }

  ngOnInit(): void {
    if (this.formData.email) {
      this.getAvailableCompanies();
    }
  }

  ngOnDestroy(): void {
    this._activatedRouteSubscription?.unsubscribe();
  }

  onSendMailClicked = () => {
    this.forgotPasswordForm.form.markAllAsTouched();
    if (!this.forgotPasswordForm.form.valid) return;

    this.error = undefined;
    this._authService.recoverPassword(this.formData).subscribe({
      next: () => {
        this.resetDone = true;
      },
      error: (error) => {
        this.resetDone = false;
        this.error = new ErrorMessage(
          'Unable to reset password!',
          error.error
        );
      }
    });
  };

  onEmailChangedDebounce = debounce(this.onEmailChanged, 300);

  private onEmailChanged() {
    if (!this.forgotPasswordForm.valid) {
      this.formData.userCompanyGuid = null;
      return;
    }

    this.getAvailableCompanies();
  }

  private getAvailableCompanies = () => {
    this._spinnerService.show();
    this._authService.getAvailableCompanies(this.formData.email).subscribe({
      next: (response) => {
        this.availableCompanies = response || [];

        if (this.availableCompanies.length === 1) {
          this.formData.userCompanyGuid = this.availableCompanies[0].userCompanyGuid;
        }
      },
      error: (error) => {
        this.error = new ErrorMessage(
          'Unable to get companies for a provided email',
          error.message
        );
      }
    }).add(() => {
      this._spinnerService.hide();
    });
  }
}
