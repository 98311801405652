import { Component, Input, OnInit } from '@angular/core';
import { ApplicationTableConfig } from 'src/app/modules/applications/application-table/application-table-config.model';
import { NotificationService } from 'src/app/services/notification.service';
import { RowHeight } from 'src/app/shared/models/table-config.model';
import { AgentsService } from '../../services/agents.service';

@Component({
  selector: 'agent-applications',
  templateUrl: './agent-applications.component.html',
})
export class AgentApplicationsComponent implements OnInit {

  @Input()
  agentId: number;

  applications = [];
  isLoading = false;
  error: any;
  columns: any[] = [];
  selectedRows: any[] = [];
  selectedColumns: any[] = [];
  globalFilterFields: string[] = [];

  applicationTableConfig: ApplicationTableConfig;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _agentsService: AgentsService,
  ) { }

  ngOnInit(): void {
    this.applicationTableConfig = {
      columns: [
        { field: 'applicationId', header: 'ID / Purpose', order: 1, visible: true },
        { field: 'lastName', header: 'Borrower / Property', order: 2, visible: true },
        { field: 'loanStatusName', header: 'Loan Status', order: 3, visible: true },
        { field: 'mobilePhone', header: 'Contact Info', order: 4, visible: true },
        { field: 'loanAmount', header: 'Loan Amount', order: 5, visible: true },
        { field: 'interestRate', header: 'Interest Rate', order: 6, visible: true },
        { field: 'primaryRoleContact', header: 'Loan Contact', order: 7, visible: true },
        { field: 'appCreateDate', header: 'Date Created', order: 8, visible: true },
        { field: 'refNumber', header: 'Ref#', order: 9, visible: false }
      ],
      extraGlobalFilterFields: ['firstName', 'email', 'homePhone', 'channel', 'mailingStreet', 'mailingCity', 'mailingState', 'mailingZip', 'applicationIdWithPadding'],
      isAddNewButtonVisible: false,
      isShowArchiveButtonVisible: false,
      isAddToDialListButtonVisible: false,
      rowHeight: RowHeight['80px'],
      scrollOffset: 308,
      scrollable: true
    }

    this.selectedColumns = [];
    this.applicationTableConfig.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });

    this.loadApplications();
  }

  private loadApplications = () => {
    this.isLoading = true;
    this._agentsService.getAllApplicationInfo(this.agentId).subscribe({
      next: (response) => {
        this.applications = response;
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to get applications.',
          'Error!'
        );
      }
    }).add(() => this.isLoading = false);
  }
}
