import { Adjustment } from "./pricing-quote.model";
import { ProductSearchRequest } from "./product-search-request-info.model";

export class PricingScenario {
  pricingScenarioId: number;
  priceDate: string;
  rateSheetDate: string;
  name: string;
  applicationId: number;
  pricingVendor: string;
  autoRefreshEnabled: boolean;
  pricingRequestCallInfo: string;
  pricingRequestPayload: ProductSearchRequest;
  productId: string;
  productCode: string;
  productName: string;
  investorId: string;
  investor: string;
  isEligible: boolean;
  lockTerm: number;
  baseRate: number;
  basePrice: number;
  adjustedRate: number;
  adjustedPrice: number;
  totalPriceAdjustment: number;
  totalRateAdjustment: number;
  totalMarginAdjustment: number;
  totalSrpAdjustment: number;
  apr: number;
  pricingLastUpdated: string;
  margin: number;
  principalAndInterest: number;
  monthlyMi: number;
  monthlyInsurance: number;
  monthlyTaxes: number;
  totalPayment: number;
  loCompensationDollars: number;
  loCompensationPercent: number;
  borrowerPaidClosingCostDollars: number;
  currentFeeCreditDollar: number;
  totalClosingCost: number;
  totalFeesDollars: number;
  totalFeesPercent: number;
  discountDollars: number;
  discountPercent: number;
  rebateDollars: number;
  rebatePercent: number;
  originationDollars: number;
  originationPercent: number;
  lenderFeesDollars: number;
  lenderFeesPercent: number;
  thirdPartyFeesDollars: number;
  thirdPartyFeesPercent: number;
  qmStatus: String[];
  adjustments: Adjustment[];
  stipulations: string[];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  history: PriceHistoryItem[] = [];
  shareWithApplicationBorrowers: boolean;
  borrowerNotes: string;

  monthlyHoa: number;

  constructor(applicationId: number, rateSheetDate: string, productId: string, productName: string, investorId: string, investor: string, lockTerm: number, adjustedRate: number, adjustedPrice: number, margin: number, principalAndInterest: number, monthlyMi: number, totalPayment: number, totalClosingCost: number, discountDollars: number, discountPercent: number, baseRate: number, basePrice: number, totalPriceAdjustment: number, totalRateAdjustment: number, totalMarginAdjustment: number, totalSrpAdjustment: number, apr: number, monthlyInsurance: number, monthlyTaxes: number, loCompensationDollars: number, loCompensationPercent: number, borrowerPaidClosingCostDollars: number, currentFeeCreditDollar: number, totalFeesDollars: number, totalFeesPercent: number, originationDollars: number, originationPercent: number, lenderFeesDollars: number, lenderFeesPercent: number, thirdPartyFeesDollars: number, thirdPartyFeesPercent: number, qmStatus: String[], adjustments: Adjustment[], pricingLastUpdated: string, rebatePercent: number, rebateDollars: number) {
    this.applicationId = applicationId;
    this.rateSheetDate = rateSheetDate;
    this.productId = productId;
    this.productName = productName;
    this.investorId = investorId;
    this.investor = investor;
    this.lockTerm = lockTerm;
    this.adjustedRate = adjustedRate;
    this.adjustedPrice = adjustedPrice;
    this.margin = margin;
    this.principalAndInterest = principalAndInterest;
    this.monthlyMi = monthlyMi;
    this.totalPayment = totalPayment;
    this.totalClosingCost = totalClosingCost;
    this.discountDollars = discountDollars;
    this.discountPercent = discountPercent;
    this.rebateDollars = rebateDollars;
    this.rebatePercent = rebatePercent;
    this.baseRate = baseRate;
    this.basePrice = basePrice;
    this.totalPriceAdjustment = totalPriceAdjustment;
    this.totalRateAdjustment = totalRateAdjustment;
    this.totalMarginAdjustment = totalMarginAdjustment;
    this.totalSrpAdjustment = totalSrpAdjustment;
    this.apr = apr;
    this.monthlyInsurance = monthlyInsurance;
    this.monthlyTaxes = monthlyTaxes;
    this.loCompensationDollars = loCompensationDollars;
    this.loCompensationPercent = loCompensationPercent;
    this.borrowerPaidClosingCostDollars = borrowerPaidClosingCostDollars;
    this.currentFeeCreditDollar = currentFeeCreditDollar;
    this.totalFeesDollars = totalFeesDollars;
    this.totalFeesPercent = totalFeesPercent;
    this.originationDollars = originationDollars;
    this.originationPercent = originationPercent;
    this.lenderFeesDollars = lenderFeesDollars;
    this.lenderFeesPercent = lenderFeesPercent;
    this.thirdPartyFeesDollars = thirdPartyFeesDollars;
    this.thirdPartyFeesPercent = thirdPartyFeesPercent;
    this.qmStatus = qmStatus;
    this.adjustments = adjustments;
    this.pricingLastUpdated = pricingLastUpdated;
  }
}

export class PriceHistoryItem {
  date: Date | string;
  lockTerm: number;
  baseRate: number;
  basePrice: number;
  adjustedRate: number;
  adjustedPrice: number;
}

