<p-table
  #dt2
  [value]="info.failedDocs"
  styleClass="p-datatable-gridlines p-datatable-sm"
  [paginator]="true"
  [rows]="25"
  [rowsPerPageOptions]="[25, 50, 100]"
  [autoLayout]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  scrollable="true"
  [scrollHeight]="'400px'"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Document</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-doc>
    <tr>
      <td>
        {{ doc }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length" class="text-center">
        No failed docs found.
      </td>
    </tr>
  </ng-template>
</p-table>
