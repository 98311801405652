<form #upsertAdverseReasonForm="ngForm" ngbAutofocus class="row">
    <div class="p-3">
        <div class="mb-3">
            <label class="control-label">Adverse Reason</label>
            <input class="form-control"
                [ngClass]="{ 'is-invalid' : adverseReasonName && adverseReasonName.touched && adverseReasonName.invalid }"
                name="adverseReasonName" [(ngModel)]="adverseReason.adverseReasonName" #adverseReasonName="ngModel"
                required />
            <div class="invalid-feedback">Adverse reason name is required.</div>
        </div>

        <div class="text-end">
            <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
                Close
            </button>
            <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" [disabled]="saving">
                <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
                Save
            </button>
        </div>
    </div>
</form>