import { Pipe } from "@angular/core";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { EnumerationService } from "src/app/services/enumeration-service";

@Pipe({
  name: "cocReason"
})
export class CocReasonPipe {
  private _reasons: EnumerationItem[] = [];

  constructor(private readonly _enumerationService: EnumerationService) {
    this._reasons = this._enumerationService.disclosureReasons;
  }

  transform(value: any): any {
    if (!value) return "";

    const splittedCocDetailsReasons: string[] = value.replaceAll(" ", "").split(",");

    return splittedCocDetailsReasons.map(cocReason => {
      if (cocReason === "Other") return cocReason;
      return this._reasons.find(r => r.value === cocReason)?.name
    }).join(", ");
  }
}
