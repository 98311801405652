export class DocumentTemplatePage {
  documentTemplatePageId: number;
  documentTemplateId: number;
  title: string;
  fields: DocumentTemplateField[] = [];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class DocumentTemplateField {
  documentTemplateFieldId: number;
  documentTemplateId: number;
  fieldKey: string;
  fieldType: DocumentTemplatePageFieldType;
  displayName: string;
  globalMergeFieldKey: string;
  defaultValue: string;
  visible: boolean;
  editable: boolean;
  documentTemplatePageId: number;
  pageOrder: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum DocumentTemplatePageFieldType {
  Text = 'Text',
  CheckBox = 'CheckBox',
  RadioButton = 'RadioButton',
  Email = 'Email',
  Phone = 'Phone',
  Currency = 'Currency',
  Percent = 'Percent'
}
