import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { ApplicationContext } from 'src/app/models';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';
import { DialerEvent, DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';

@Component({
  selector: 'admin-page-template',
  templateUrl: 'admin-page-template.component.html',
  styleUrls: ['admin-page-template.component.scss']
})

export class AdminPageTemplateComponent implements OnDestroy {

  @Input()
  pageMainContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarLeftContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarMiddleContentTemplate!: TemplateRef<any>;

  @Input()
  pageActionBarRightContentTemplate!: TemplateRef<any>;

  @Input()
  useActionBarSections: boolean = false;

  @Input()
  hasPageActionBar: boolean = true;

  @Input()
  isNiceInContactVisible: boolean = false;

  companyName: string = "";

  isSuperAdmin: boolean = false;
  hasSecondaryPageActionBar: boolean = false;

  private _subscriptionToApplicationContext: any;
  private _subscriptionToDialerService: any;
  private _subscriptionToApplicationContextChanges: any;
  private _subscriptionTologgedInUserChanges: any;

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _dialerService: DialerService
  ) {

    this._subscriptionToApplicationContext = this._applicationContextService.context.subscribe(context => {
      this.initializeFromContext(context);
    });

    this._subscriptionTologgedInUserChanges = this._applicationContextService.loggedInUserChanges.subscribe(context => {
      this.initializeFromContext(context);
    });

    this._subscriptionToApplicationContextChanges = this._applicationContextService.callControlPanelStatChanges.subscribe(context => {
      this.initializeFromContext(context);
    });

    this._subscriptionToDialerService = this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }
      switch (e.eventType) {
        case DialerEventType.loadCallPanel:
          this.hasSecondaryPageActionBar = true;
          break;
        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    if (this._subscriptionToApplicationContext) {
      this._subscriptionToApplicationContext.unsubscribe();
    }
    if (this._subscriptionToApplicationContextChanges) {
      this._subscriptionToApplicationContextChanges.unsubscribe();
    }
    if (this._subscriptionToDialerService) {
      this._subscriptionToDialerService.unsubscribe();
    }
    if (this._subscriptionTologgedInUserChanges) {
      this._subscriptionTologgedInUserChanges.unsubscribe();
    }
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.hasSecondaryPageActionBar = context.isCallControlPanelOpen;
    this.companyName = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId).companyName;
    this.isSuperAdmin = context.userPermissions.superAdmin;
  }
}
