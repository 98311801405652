import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Goal } from '../../../../../admin/goal/models/goal.model';
import { NotificationService } from '../../../../../../services/notification.service';
import { catchError, map, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { GoalTableAction } from '../../../../../admin/goal/components/goal-table/goal-table.component';
import { GoalService } from '../../../../../admin/goal/services/goal.service';
import { UserGoalService, UserGoalServiceBoundToUser } from '../../services/user-goal.service';

@Component({
  selector: 'user-goals',
  templateUrl: './user-goals.component.html',
  styleUrls: ['./user-goals.component.scss'],
})
export class UserGoalsComponent implements OnInit, AfterViewInit, OnDestroy {
  protected userGoals: Goal[] = [];
  protected goals: Goal[] = [];
  protected selectedUserGoals: Goal[] = [];
  protected selectedGoals: Goal[] = [];

  private _unfilteredGoals: readonly Goal[] = [];
  private _userGoalIds: Set<number> = new Set();

  protected actions: readonly GoalTableAction[];

  protected get isRemoveGoalsFromUserButtonEnabled(): boolean {
    return !this.loading && this.selectedUserGoals.length > 0;
  }

  protected get isAddGoalsToUserButtonEnabled(): boolean {
    return !this.loading && this.selectedGoals.length > 0;
  }

  private _userBoundGoalService?: UserGoalServiceBoundToUser;

  private _loadingUserGoals: boolean = true;
  private _loadingGoals: boolean = true;

  protected get loading(): boolean {
    return this._loadingUserGoals || this._loadingGoals;
  }

  private _destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _goalService: GoalService,
    private readonly _userGoalService: UserGoalService,
    private readonly _notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToGoalChanges();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  private subscribeToGoalChanges(): void {
    this._goalService.goalChanges$.pipe(
      takeUntil(this._destroyed$),
      catchError((error) => {
        const message = error?.message ||
          'An unknown error occurred while loading goals.';
        console.error(message, error);
        this._notificationService.showError(message, 'Error');

        return of([]);
      }),
      tap((goals) => {
        this._unfilteredGoals = goals;
        this._loadingGoals = false;
      }),
      switchMap(() => this.initUserGoals()),
    ).subscribe(() => this.resetGoals(this._unfilteredGoals));
  }

  private initUserGoals(): Observable<void> {
    this._loadingUserGoals = true;

    return this._userGoalService.forCurrentUser().pipe(
      takeUntil(this._destroyed$),
      switchMap((userGoalService) => {
        this._userBoundGoalService = userGoalService;
        return userGoalService.getGoals();
      }),
      finalize(() => this._loadingUserGoals = false),
      catchError((error) => {
        const message = error?.message ||
          'An unknown error occurred while loading user goals.';
        console.error(message, error);
        this._notificationService.showError(message, 'Error');

        return of([]);
      }),
      tap((goals) => {
        this._userGoalIds = new Set(goals.map(({ id }) => id));
        this.userGoals = [...goals];
      }),
      map(() => undefined),
    );
  }

  private resetGoals(goals: readonly Goal[]): void {
    this.goals = goals.filter(({ id }) => !this._userGoalIds.has(id));
  };

  private setHeight(): void {
    const excludedSelectors = [
      'admin-header',
      '.page-action-bar-sticky',
    ];
    const excludedElements = excludedSelectors.map((s) =>
      document.querySelector(s),
    ).filter((e) => e instanceof HTMLElement) as HTMLElement[];
    const excludedHeight = excludedElements.reduce((acc, e) =>
      acc + e.offsetHeight, 0);

    const nativeElement = this._elementRef.nativeElement as HTMLElement;
    const style = nativeElement.style;
    style.setProperty(
      'height',
      `calc(100vh - ${excludedHeight}px - 1.3rem)`,
    );
  }

  private setLoadingAll(loading: boolean): void {
    this._loadingUserGoals = loading;
    this._loadingGoals = loading;
  }

  /**
   * Run a process that changes the goals.
   * @param observable The observable that will change the goals.
   */
  private changeGoalsWith(observable: Observable<void>): void {
    // Don't clear the loading state because it will be cleared by the goal
    // changes subscription.
    this.setLoadingAll(true);

    observable.pipe(
      takeUntil(this._destroyed$),
    ).subscribe();
  }

  protected onClickRemoveGoalsFromUser(): void {
    const service = this._userBoundGoalService;
    const selectedGoals = this.selectedUserGoals;
    const ids = selectedGoals.map(({ id }) => id);
    const removeGoals$ = service!.removeGoals(...ids).pipe(
      tap(() => {
        // The selection must be reset manually so that the removed ones do not
        // remain selected.
        this.selectedUserGoals = [];
      }),
      map(() => undefined),
    );

    this.changeGoalsWith(removeGoals$);
  }

  protected onClickAddGoalsToUser(): void {
    const service = this._userBoundGoalService;
    const selectedGoals = this.selectedGoals;
    const addGoals$ = service!.addGoals(...selectedGoals).pipe(
      tap(() => {
        // The selection must be reset manually so that the removed ones do not
        // remain selected.
        this.selectedGoals = [];
      }),
      map(() => undefined),
    );

    this.changeGoalsWith(addGoals$);
  }
}
