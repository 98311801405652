import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExportLoanDialogComponent } from './components/export-loan-dialog/export-loan-dialog.component';
import { ExportLoanDocsAccordionComponent } from './components/export-loan-docs-accordion/export-loan-docs-accordion.component';
import { ExportLoanDocsComponent } from './components/export-loan-docs.component';
import { ExportLoanDocsRoutingModule } from './export-loan-docs-routing.module';
import { ExportFileService } from './services/export-file.service';

@NgModule({
    imports: [
        ExportLoanDocsRoutingModule,
        SharedModule,
        TableModule
    ],
    declarations: [ExportLoanDocsComponent, ExportLoanDocsAccordionComponent, ExportLoanDialogComponent],
    exports: [ExportLoanDocsComponent, ExportLoanDocsAccordionComponent, ExportLoanDialogComponent],
    providers: [ExportFileService]
})
export class ExportLoanDocsModule {
    constructor() { }
}
