<div class="inline-editor" *ngIf="editorMode != 'InlineReadOnly' && !disabled" [ngClass]="{
    'input-group': editorMode == 'Inline',
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }" [hidden]="editorMode === 'Inline' && !isEditActive && !(model && model.touched && model.invalid) && !disabled">
  <input class="form-control" type="text" numeric [allowNegative]="allowNegative" [decimals]="decimals"
    [(ngModel)]="value" #control #model="ngModel" name="{{ name }}" id="{{ name }}" placeholder="{{ placeholder}}"
    [required]="required" [readonly]="readonly" [disabled]="disabled" (blur)="onBlurred($event)"
    (focus)="isEditActive = true" [ngClass]="{
      'is-invalid': model && model.touched && model.invalid
    }" />
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Apply" type="button"
    *ngIf="editorMode == 'Inline' && !disabled" (mousedown)="onApplyClicked($event)">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Cancel" type="button"
    *ngIf="editorMode == 'Inline' && !disabled" (mousedown)="onCancelClicked($event)">
    <i class="fa fa-times-circle text-danger"></i>
  </button>

  <div *ngIf="model && model.touched && model.errors && model.errors.required" class="invalid-feedback">
    This field is required
  </div>
  <div *ngIf="model && model.touched && model.errors && model.errors.invalid" class="invalid-feedback">
    This field is invalid
  </div>
  <div *ngIf="model && model.touched && model.errors && model.errors.max" class="invalid-feedback">
    This field cannot be greater than {{ max }}
  </div>
  <div *ngIf="model && model.touched && model.errors && model.errors.min" class="invalid-feedback">
    This field cannot be smaller than {{ min }}
  </div>
</div>
<span [ngClass]="{
    'empty-field': value == null || value == undefined
  }" class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" title="Click to Edit"
  *ngIf="editorMode == 'Inline' && !isEditActive && !(model && model.touched && model.invalid) && !disabled"
  (click)="onEditModeToggledOn()">
  <span class="val" *ngIf="value !== null && value !== undefined">{{value}}</span>
  <span class="placeholder" *ngIf="value === null || value === undefined">--</span>
</span>
<span [ngClass]="{
  'empty-field': value == null || value == undefined
}" class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{
  inlineTextClass
}}" *ngIf="editorMode == 'InlineReadOnly' || disabled">
<span class="val" *ngIf="value !== null && value !== undefined">{{value}}</span>
<span class="placeholder" *ngIf="value === null || value === undefined">--</span>
</span>