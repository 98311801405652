import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { MeridianLinkKeyValuePairs } from '../pricing.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';
import { cloneDeep, isArray, orderBy } from 'lodash';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { combineLatest } from 'rxjs';
import { Constants } from 'src/app/services/constants';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { PricingCustomFieldSectionsComponent } from '../pricing-custom-field-sections/pricing-custom-field-sections.component';

@Component({
  selector: 'meridian-vendor-editor-dialog',
  templateUrl: './meridian-vendor-editor-dialog.component.html',
  styleUrls: ['./meridian-vendor-editor-dialog.component.scss']
})
export class MeridianVendorEditorDialogComponent implements OnInit {

  @ViewChild("meridianForm")
  meridianForm: NgForm;

  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  scope: string;

  @Input()
  userCompanyGuid: string;

  @Input()
  inEditMode: boolean = true;

  @Input()
  availableBusinessChannels: ThirdPartyKeyValue[] = [];

  @Input()
  branchId?: number;

  customFields: CustomField[] = [];
  meridianLinkKeyValuePairs: MeridianLinkKeyValuePairs = new MeridianLinkKeyValuePairs();
  loadingCustomFields: boolean;
  orginalCustomFields: CustomField[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _pricingCustomFieldService: PricingCustomFieldService) { }

  ngOnInit() {
    this.populateKeyValuePairs();
  }

  saveCredential = () => {
    this.meridianForm.form.markAllAsTouched();
    if (!this.meridianForm.form.valid) {
      return;
    }
    if (this.scope === 'User' || this.scope === 'TpoUser') {
      this.vendor.userId = this.userCompanyGuid;
    } else if (this.scope === 'Branch' || this.scope === 'ExternalCompanyBranch') {
      this.vendor.branchId = String(this.branchId);
    }

    this.populateKeyValuePairs(true);

    const observer = {
      next: ((result: any) => {
        this.activeModal.close(isArray(result) ? result[0] : result);
        this._spinner.hide();
        this._notificationService.showSuccess(
          'Vendor successfuly updated',
          'Success!'
        );
      }),
      error: ((error: any) => {
        this._spinner.hide();
        this._notificationService.showError(
          error?.message || 'Unable to update Vendor',
          'Failure!'
        );
      })
    }
    this._spinner.show();
    const obs: any = [
      this._systemLevelService.saveCredential(this.vendor)
    ];
    if (!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(this.scope)) {
      this.customFields.forEach((cf: CustomField, index: number) => {
        cf.sortOrder = index;
        if (cf.options?.length > 0 && cf.type !== 'List') {
          cf.options = [];
        }
        if (cf.pricingCustomFieldId) {
          obs.push(this._pricingCustomFieldService.updateCustomField(cf));
        } else {
          obs.push(this._pricingCustomFieldService.insertCustomField(cf));
        }
      });
      const deletedCustomFields = this.orginalCustomFields.filter(o => !this.customFields.map(c => c.pricingCustomFieldId).includes(o.pricingCustomFieldId));
      deletedCustomFields.forEach(del => obs.push(this._pricingCustomFieldService.deleteCustomField(del?.pricingCustomFieldId)));
    }
    combineLatest(obs).subscribe(observer);
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.MeridianLink;
  }

  trackByIndex(index: number): number {
    return index;
  }

  onCustomFieldsMappingClicked = () => {
    const modalRef = this._modalService.open(PricingCustomFieldSectionsComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = this.vendor.vendorName;
  }

  private populateKeyValuePairs = (isSave: boolean = false) => {
    let kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'AppCode');
    if (kvp) {
      if(isSave){
        kvp.value = this.meridianLinkKeyValuePairs.appCode;
      }
      else {
        this.meridianLinkKeyValuePairs.appCode = kvp.value;
      }
    }
    else if(this.meridianLinkKeyValuePairs.appCode) {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("AppCode", this.meridianLinkKeyValuePairs.appCode));
    }

    kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientId');
    if (kvp) {
      if(isSave){
        kvp.value = this.meridianLinkKeyValuePairs.clientId;
      }
      else {
        this.meridianLinkKeyValuePairs.clientId = kvp.value;
      }
    }
    else if(this.meridianLinkKeyValuePairs.clientId){
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("ClientId", this.meridianLinkKeyValuePairs.clientId));
    }

    kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientSecret');
    if (kvp) {
      if(isSave){
        kvp.value = this.meridianLinkKeyValuePairs.clientSecret;
      }
      else {
        this.meridianLinkKeyValuePairs.clientSecret = kvp.value;
      }
    }
    else if(this.meridianLinkKeyValuePairs.clientSecret){
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("ClientSecret", this.meridianLinkKeyValuePairs.clientSecret));
    }

    kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'AuthUrl');
    if (kvp) {
      if(isSave){
        kvp.value = this.meridianLinkKeyValuePairs.authUrl;
      }
      else {
        this.meridianLinkKeyValuePairs.authUrl = kvp.value;
      }
    }
    else if(this.meridianLinkKeyValuePairs.authUrl){
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("AuthUrl", this.meridianLinkKeyValuePairs.authUrl));
    }

    kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'PriceGroup_Correspondent');
    if (kvp) {
      if(isSave) {
        kvp.value = this.meridianLinkKeyValuePairs.priceGroupCorrespondent;
      }
      else {
        this.meridianLinkKeyValuePairs.priceGroupCorrespondent = kvp.value;
      }
    }
    else if(this.meridianLinkKeyValuePairs.priceGroupCorrespondent){
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("PriceGroup_Correspondent", this.meridianLinkKeyValuePairs.priceGroupCorrespondent));
    }

    kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'PriceGroup_NonDelegatedCorrespondent');
    if (kvp) {
      if (isSave) {
        kvp.value = this.meridianLinkKeyValuePairs.priceGroupNondelegatedCorrespondent;
      }
      else {
        this.meridianLinkKeyValuePairs.priceGroupNondelegatedCorrespondent = kvp.value;
      }
    }
    else if (this.meridianLinkKeyValuePairs.priceGroupNondelegatedCorrespondent){
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("PriceGroup_NonDelegatedCorrespondent", this.meridianLinkKeyValuePairs.priceGroupNondelegatedCorrespondent));
    }

    kvp = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'PriceGroup_Wholesale');
    if (kvp) {
      if (isSave) {
        kvp.value = this.meridianLinkKeyValuePairs.priceGroupWholesale;
      }
      else {
        this.meridianLinkKeyValuePairs.priceGroupWholesale = kvp.value;
      }
    }
    else if (this.meridianLinkKeyValuePairs.priceGroupWholesale) {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("PriceGroup_Wholesale", this.meridianLinkKeyValuePairs.priceGroupWholesale));
    }
  }

}
