<div class="modal-header">
    <h5 class="modal-title">{{message.delivery === "Email" ? "Email" : "SMS"}} Preview</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row d-lex justify-content-center" *ngIf="message">
        <div class="col-md-9">
            <div class="message-info">
                <label>Status: </label>
                <span [innerHTML]="statusHtml"></span>
            </div>
            <div class="message-info" *ngIf="message.extendedDetail">
                <label>Detail: </label>
                <span>{{message.extendedDetail}}</span>
            </div>
            <hr>

            <div class="message-info">
                <label>To: </label>
                <span>{{message.to}}</span>
            </div>
            <div class="message-info">
                <label>From: </label>
                <span>{{message.from}}</span>
            </div>
            <div class="message-info" *ngIf="message.delivery == 'Email'">
                <label>Cc: </label>
                <span>{{message.cc}}</span>
            </div>
            <div class="message-info" *ngIf="message.delivery == 'Email'">
                <label>Bcc: </label>
                <span>{{message.bcc}}</span>
            </div>
            <div class="message-info" *ngIf="message.delivery == 'Email'">
                <label>Subject: </label>
                <span>{{message.subject}}</span>
            </div>
            <hr>

            <div class="message-info">
              <div [innerHTML]="message.body" *ngIf="message.delivery !== 'Email'"></div>
              <iframe *ngIf="message.delivery === 'Email'" style="width:100%; height: 100%; border:1px solid #e3ebf6; border-radius: 0.25rem;" id="emailPreviewPanel"></iframe>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">Cancel</button>
</div>
