import { Component, Input, OnInit } from '@angular/core';
import { DocumentSigningRecipient } from 'src/app/modules/loan-docs/models/document-signing-recipient.model';

@Component({
  selector: 'esignature-recipients-table',
  templateUrl: 'esignature-recipients-table.component.html'
})

export class EsignatureRecipientsTableComponent implements OnInit {

  @Input()
  recipients: DocumentSigningRecipient[] = [];

  showCaption: boolean = false;

  constructor() { }

  ngOnInit() { }
}
