import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { UserProfile } from 'src/app/models';
import { TimeZone } from 'src/app/models/timezone.model';
import { DefinedEmailTemplate } from 'src/app/modules/email-configuration/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawingPadDialogComponent } from 'src/app/shared/components/drawing-pad-dialog/drawing-pad-dialog.component';
import { StripoEmailEditorComponent, StripoEditorSaveClickedEvent } from 'src/app/shared/components/stripo-email-editor/stripo-email-editor.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import * as ClassicEditor from 'src/app/shared/utils/ckeditor5/classic/build/ckeditor';

@Component({
  selector: 'app-basic-profile',
  templateUrl: './basic-profile.component.html',
  styleUrls: ['./basic-profile.component.scss']
})
export class BasicProfileComponent implements OnInit {
  public Editor = ClassicEditor;

  @ViewChild("editEmailSignatureTemplateDrawer")
  editEmailTemplateDrawer: DrawerComponent;

  @Input() profile: UserProfile;
  @Input() timezones: TimeZone[] = [];

  @Input() userImage: string;
  @Input() signatureImage: string;

  imageChangedEvent: any = null;
  croppedImage: string = null;
  isImageSelected: boolean = false;
  defaultImage: string = 'assets/images/male.png';
  image: string;

  maxDate: Date = new Date();
  minDate: Date = new Date("1/1/1900");

  definedEmailTemplate: DefinedEmailTemplate;

  ckEditorConfig = Constants.defaultCkEditorConfig;
  urlPattern: string = Utils.urlPattern;

  protected editEmailSignatureTemplateDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  };

  private _dynamicEventSubscriptions: Subscription[] = [];

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _drawerService: DrawerService,
    private readonly _notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.image = this.userImage || this.defaultImage;
    this.addMissingCkEditorProperties();
    this.loadEmailSignatureTemplatePreview();
  }

  ngOnDestroy(): void {
    this._dynamicEventSubscriptions.forEach(s => s?.unsubscribe());
  }

  onEditorReady(editor: ClassicEditor) {
    //this.customizeEditorEditor(editor);
    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on('insert:table', (evt, data, conversionApi) => {
        const table = data.item;
        // The table from the model is mapped to the widget element: <figure>.
        const viewFigure = conversionApi.mapper.toViewElement(table);
        conversionApi.writer.removeClass('table', viewFigure);
        conversionApi.writer.setStyle({
          margin: '0'
        }, viewFigure);
      });
    });
  }

  onEditTemplateClicked = () => {
    this._drawerService.hide("editEmailSignatureTemplateDrawer");
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = StripoEmailEditorComponent;
    dynamicComponentInfo.parameters.set('html', this.profile.emailSignature);
    dynamicComponentInfo.parameters.set('documentId', this.profile.userCompanyGuid);
    this._drawerService.show("editEmailSignatureTemplateDrawer", 100, "Edit Email Signature Template", dynamicComponentInfo).then(() => {
      const subscription = this.editEmailTemplateDrawer.componentInstance.saveClicked.subscribe((saveInfo: StripoEditorSaveClickedEvent) => {
        this._notificationService.showSuccess(`Settings saved successfully.`, 'Profile');
        this.profile.emailSignature = saveInfo.html;
        this.loadEmailSignatureTemplatePreview();
        this._drawerService.hide("editEmailSignatureTemplateDrawer", 100);
      });
      const subscription2 = this.editEmailTemplateDrawer.componentInstance.cancelClicked.subscribe(() => {
        this._drawerService.hide("editEmailSignatureTemplateDrawer", 100);
      });
      this._dynamicEventSubscriptions.push(subscription);
      this._dynamicEventSubscriptions.push(subscription2);
    });
  }

  private customizeEditorEditor = (editor: ClassicEditor) => {
    editor.conversion.for('downcast').elementToElement({
      model: 'tableCell',
      view: (cell, { writer }) => {

        if (!cell) {
          return;
        }

        if ((cell._children._nodes.length === 1 && cell._children._nodes[0].name === 'paragraph' &&
          cell._children._nodes[0]._children.length === 0) || cell._children.length === 0) {
          return writer.createRawElement('td', { id: 'foo-1234' }, function (domElement) {
            domElement.innerHTML = '';
          });
        } else {
          return;
        }
      },
      converterPriority: 'high',
    });
  }

  onDisabledRemoveButton = () => {
    return this.image == this.defaultImage;
  }

  onFileChanged = (event: any): void => {
    this.imageChangedEvent = event;
    if (this.imageChangedEvent.target.files.length > 0) {
      this.isImageSelected = true;
    }
  }

  onESignatureFileChanged = (files: File[]): void => {
    if (files.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]); // read file as data url
      reader.onload = (event) => {
        this.profile.signatureImageData = event.target.result as string;
        this.profile.signatureId = null;
        this.profile.signatureImage = null;
      };
    }
  }

  onImageCropped = (event: ImageCroppedEvent) => {
    this.croppedImage = event.base64;
  }

  onSelectCroppedImage = () => {
    this.image = this.croppedImage;
    this.isImageSelected = false;

    this.profile.avatarId = null;
    this.profile.userImageData = this.image;
  }

  onRemoveImage = () => {
    this.image = this.defaultImage;

    this.profile.avatarId = null;
    this.profile.userImageData = null;
  }

  onCloseImage = () => {
    this.isImageSelected = false;
  }

  openDrawingPad = () => {
    const modalRef = this._modalService.open(DrawingPadDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.title = "E-Signature";

    modalRef.result.then((imageBase64: string) => {
      this.profile.signatureImageData = imageBase64;
      this.profile.signatureId = null;
      this.profile.signatureImage = null;
    }, () => {
    });
  }

  private addMissingCkEditorProperties = () => {
    if (!this.profile.emailSignature) {
      this.profile.emailSignature = "";
    }
  }

  private loadEmailSignatureTemplatePreview = () => {
    setTimeout(() => {
      let iframe = document.getElementById(`emailSignatureIframe`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      doc.open();
      doc.write(this.profile.emailSignature);
      doc.close();
    });
  }

}
