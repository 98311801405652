<div class="modal-header">
    <h5 class="modal-title w-100">
        Search Loan Application
    </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-md-11">
            <div class="row">
                <div class="col-md-3">
                    <div class="mb-3">
                        <label class="form-label" for="searchBy">Search By</label>
                        <select class="form-select" id="searchBy" [(ngModel)]="searchCriteria.type"
                            (change)="searchTypeChanged()" required>
                            <option value="AppNo"> Application No </option>
                            <option value="GlobalSearch"> Global Search </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="mb-3">
                        <label class="form-label" for="appId">Application #</label>
                        <input type="number" class="form-control" id="appId" [(ngModel)]="searchCriteria.applicationId"
                            [disabled]="searchCriteria.type === 'GlobalSearch'">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="searchText">Search Text</label>
                        <input type="text" class="form-control" id="searchText" [(ngModel)]="searchCriteria.searchText"
                            [disabled]="searchCriteria.type == 'AppNo'">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="mt-4">
                <button type="button" class="btn btn-primary btn-sm mt-2" (click)="searchApp()"
                    [disabled]="isSearching">
                    <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSearching"></span>
                    Search
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h3 *ngIf="loanList && loanList.length === 0">There are no loans matching the search criteria.</h3>
                <div class="table-responsive" style="max-height: 500px;">
                    <table class="table mb-0 table-centered" *ngIf="loanList">
                        <thead>
                            <tr>
                                <td>Select</td>
                                <td>Loan Number</td>
                                <td>Borrower First</td>
                                <td>Borrower Last</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sr of loanList">
                                <td>
                                    <input type="radio" name="search" [checked]="sr.applicationId == selectedLoanId"
                                        (click)="setSelectedLoan(sr.applicationId)" />
                                </td>
                                <td>{{sr.applicationId}}</td>
                                <td>{{sr.firstName}}</td>
                                <td>{{sr.lastName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-end">
        <button type="button" class="btn btn-soft-secondary" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button type="button" class="btn btn-soft-primary" [disabled]="!selectedLoanId || borrowersLoading"
            (click)="ok()">
            <span *ngIf="borrowersLoading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            OK
        </button>
    </div>