<div class="modal-header">
    <h5 class="modal-title w-100">Identifying Document</h5>

    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="card-body">
        <form #identifyingDocumentForm="ngForm" id="identifyingDocumentForm">
            <div class="row">
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="docType_{{document.identifyingDocumentId}}"> Document Type </label>
                    <select class="form-select" [(ngModel)]="document.documentType"
                        name="docType_{{document.identifyingDocumentId}}" required #documentType="ngModel"
                        [ngClass]="{'is-invalid' : documentType && documentType.touched && documentType.invalid}">
                        <option [ngValue]="null">-- Select One --</option>
                        <option *ngFor="let identityDocumentType of identityDocumentTypes"
                            [ngValue]="identityDocumentType.value">
                            {{ identityDocumentType.name }}
                        </option>
                    </select>
                    <div class="invalid-feedback">Please select document type</div>
                </div>
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="docTypeOtherDescription_{{document.identifyingDocumentId}}"> Document Type Other
                        Description</label>
                    <input id="docTypeOtherDescription_{{document.identifyingDocumentId}}"
                        [disabled]="document.documentType != 'Other'"
                        name="docTypeOtherDescription_{{document.identifyingDocumentId}}" type="text"
                        class="form-control" [(ngModel)]="document.documentTypeOtherDescription">
                </div>
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="identifier_{{document.identifyingDocumentId}}"> ID# </label>
                    <input id="identifier_{{document.identifyingDocumentId}}"
                        name="identifier_{{document.identifyingDocumentId}}" type="text" class="form-control"
                        [(ngModel)]="document.identifier">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="issuedByType_{{document.identifyingDocumentId}}"> Issued By Type </label>
                    <select class="form-select" [(ngModel)]="document.issuedByType" (change)="onIssuedByTypeChanged()"
                        name="issuedByType_{{document.identifyingDocumentId}}" required #issuedByType="ngModel"
                        [ngClass]="{'is-invalid' : issuedByType && issuedByType.touched && issuedByType.invalid}">
                        <option [ngValue]="null">-- Select One --</option>
                        <option *ngFor="let identityDocumentIssuedByType of identityDocumentIssuedByTypes"
                            [ngValue]="identityDocumentIssuedByType.value">
                            {{ identityDocumentIssuedByType.name }}
                        </option>
                    </select>
                    <div class="invalid-feedback">Please select issued by type</div>
                </div>
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="issuedByTypeOtherDescription_{{document.identifyingDocumentId}}"> Issued By Type Other
                        Description</label>
                    <input id="issuedByTypeOtherDescription_{{document.identifyingDocumentId}}"
                        [disabled]="document.issuedByType != 'Other'"
                        name="issuedByTypeOtherDescription_{{document.identifyingDocumentId}}" type="text"
                        class="form-control" [(ngModel)]="document.issuedByTypeOtherDescription">
                </div>
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="issuedByAgency_{{document.identifyingDocumentId}}"> Issued By Agency </label>
                    <input id="issuedByAgency_{{document.identifyingDocumentId}}"
                        name="issuedByAgency_{{document.identifyingDocumentId}}" type="text" class="form-control"
                        [(ngModel)]="document.issuedByAgency">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xs-12 col-sm-2">
                    <label for="issuedByCountry_{{document.identifyingDocumentId}}"> Issued By Country </label>
                    <input id="issuedByCountry_{{document.identifyingDocumentId}}" [disabled]="document.issuedByType !== 'Country'"
                        name="issuedByCountry_{{document.identifyingDocumentId}}" type="text" class="form-control"
                        [(ngModel)]="document.issuedByCountry">
                </div>
                <div class="form-group col-xs-12 col-sm-2">
                    <label for="issuedByStateCode_{{document.identifyingDocumentId}}"> Issued By State Code</label>
                    <select class="form-select" [(ngModel)]="document.issuedByStateCode" [disabled]="document.issuedByType !== 'StateOrProvince'"
                        name="issuedByStateCode_{{document.identifyingDocumentId}}">
                        <option [ngValue]="null">-- Select One --</option>
                        <option *ngFor="let state of states" [ngValue]="state.value">
                            {{ state.name }}
                        </option>
                    </select>

                </div>
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="issueDate_{{document.identifyingDocumentId}}"> Issue Date </label>
                    <date-input name="issueDate_{{document.identifyingDocumentId}}"
                        id="issueDate_{{document.identifyingDocumentId}}" [(ngModel)]="document.issueDate">
                    </date-input>
                </div>
                <div class="form-group col-xs-12 col-sm-4">
                    <label for="expirationDate_{{document.identifyingDocumentId}}"> Expiration Date </label>
                    <date-input name="expirationDate_{{document.identifyingDocumentId}}"
                        id="expirationDate_{{document.identifyingDocumentId}}" [(ngModel)]="document.expirationDate">
                    </date-input>
                </div>
            </div>
            <div class="row">
                <div *ngIf="!document.file && !document.docFileId">
                    <button class="btn btn-primary" (click)="fileInput.click()">
                        Add File
                    </button>
                    <input id="file-input" #fileInput style="display:none" type="file"
                        (change)="handleFileUpload($event)" />
                </div>
                <div *ngIf="document.file" class="d-flex justify-content-between">
                    <a (click)="viewFile()">
                        {{document.file.fileName}}
                    </a>
                    <button class="btn btn-danger" (click)="removeFile()">
                        Remove
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
        <i class="fa fa-close"></i> Cancel
    </button>

    <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="save();">
        <span><i class="fa fa-save me-2"></i>Save</span>
    </button>
</div>