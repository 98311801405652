import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HousingExpense, SubjectProperty} from 'src/app/models';
import * as _ from 'lodash';
import {UrlaMortgage} from '../../models/urla-mortgage.model';
import {SubjectPropertyAndProposedHousingExpenses} from '../supplemental-property-insurance-breakdown-dialog/supplemental-property-insurance-breakdown-dialog.component';
import {sum} from '../../../../shared/utils/math-utils';

@Component({
  selector: 'property-tax-breakdown-dialog',
  templateUrl: 'property-tax-breakdown-dialog.component.html',
  styleUrls: ['../breakdown.scss'],
})
export class PropertyTaxBreakdownDialogComponent {
  @Input()
  isEscrowWaived: boolean;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this.subjectProperty = _.cloneDeep(mortgage.subjectProperty);
    this.proposedHousingExpenses = _.cloneDeep(mortgage.proposedHousingExpense);
  }

  protected proposedHousingExpenses: HousingExpense;
  protected subjectProperty: SubjectProperty;

  constructor(public activeModal: NgbActiveModal) {}

  protected onOkClicked(): void {
    const subjectPropertyAndProposedHousingExpenses =
      new SubjectPropertyAndProposedHousingExpenses();
    subjectPropertyAndProposedHousingExpenses.proposedHousingExpense = this.proposedHousingExpenses;
    subjectPropertyAndProposedHousingExpenses.subjectProperty = this.subjectProperty;
    this.activeModal.close(subjectPropertyAndProposedHousingExpenses);
  }

  protected onCancelClicked(): void {
    this.activeModal.close();
  }

  protected onBreakdownItemChanged(): void {
    this.proposedHousingExpenses.realEstateTax = sum(
      this.proposedHousingExpenses.statePropertyTax,
      this.proposedHousingExpenses.countyPropertyTax,
      this.proposedHousingExpenses.districtPropertyTax,
      this.proposedHousingExpenses.boroughPropertyTax,
      this.proposedHousingExpenses.cityPropertyTax,
      this.proposedHousingExpenses.townPropertyTax,
      this.proposedHousingExpenses.villagePropertyTax,
      this.proposedHousingExpenses.schoolPropertyTax
    );
  }
}
