import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { DocumentType, ThirdPartyCredential, ThirdPartyCredentialType } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { Constants } from "../../../../../services/constants";
import { VendorPickerModalComponent } from '../vendor-picker-modal/vendor-picker-modal.component';
import { FormFreeAssetVerificationModal } from "./form-free-asset-verification-modal/form-free-asset-verification-modal.component";
import { TruvAssetVerificationModal } from './truv-asset-verification-modal/truv-asset-verification-modal.component';

@Component({
  selector: 'asset-verification',
  templateUrl: './asset-verification.component.html'
})
export class AssetVerificationComponent implements OnInit {
  @Input() documentTypes: Array<DocumentType>;
  @Input() digitalAssetVerificationEnabled: Configuration;
  @Input() assetVerificationCredentials: Array<ThirdPartyCredential>;

  constructor(
    private readonly _modalService: NgbModal,
     private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
  ) { }

  ngOnInit(): void {
  }

  openCredentialModal(credential: ThirdPartyCredential): void {
    let modalComponent;

    if (credential.vendorName == "FormFree") {
      modalComponent = FormFreeAssetVerificationModal;
    }
    else if (credential.vendorName == "Truv") {
      modalComponent = TruvAssetVerificationModal;
    }

    const modalRef = this._modalService.open(modalComponent, Constants.modalOptions.large);

    if (credential.vendorName == "FormFree") {
      modalRef.componentInstance.documentTypes = this.documentTypes;
    }

    modalRef.componentInstance.credential = credential;
    modalRef.result.then(
      (result) => {
        const index = this.assetVerificationCredentials.findIndex(el => el.credentialId === result.credentialId);
        if (index > -1) {
          this.assetVerificationCredentials[index] = result;
        } else {
          this.assetVerificationCredentials.push(result);
        }
      },
      () => { }
    );
  }

  openVendorPicker = (credential: ThirdPartyCredential) => {
    const modalRef = this._modalService.open(VendorPickerModalComponent, { ...Constants.modalOptions.medium, scrollable: false });
    modalRef.componentInstance.type = "VOA";
    modalRef.componentInstance.vendors = this._thirdPartyCredentialsService.getVendors(credential);

    modalRef.result.then(
      (selectedVendor: string) => {
        credential.vendorName = selectedVendor;
        this.openCredentialModal(credential);
      },
      () => { }
    );
  }
}
