import { Injectable } from '@angular/core';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { CurrencyPipe, PercentPipe } from '@angular/common';

@Injectable()
export class FeeUtils {

  constructor(private readonly _currencyPipe: CurrencyPipe,
    private readonly _percentPipe: PercentPipe) {
  }

  getSinglePayer = (loanFee: LoanFee): Payer => {
    if ((loanFee.borrowerFeeDollar != null && loanFee.borrowerFeeDollar > 0) ||
      (loanFee.borrowerFeePercent != null && loanFee.borrowerFeePercent > 0)) {
      return Payer.Borrower;
    }
    if ((loanFee.lenderFeeDollar != null && loanFee.lenderFeeDollar > 0) ||
      (loanFee.lenderFeePercent != null && loanFee.lenderFeePercent > 0)) {
      return Payer.Lender;
    }
    if ((loanFee.sellerFeeDollar != null && loanFee.sellerFeeDollar > 0) ||
      (loanFee.sellerFeePercent != null && loanFee.sellerFeePercent > 0)) {
      return Payer.Seller;
    }
    if ((loanFee.thirdPartyFeeDollar != null && loanFee.thirdPartyFeeDollar > 0) ||
      (loanFee.thirdPartyFeePercent != null && loanFee.thirdPartyFeePercent > 0)) {
      return Payer.ThirdParty;
    }
  }

  getSplitPayerInfo = (loanFee: LoanFee): Array<Payer> => {
    const payers = [];
    if ((loanFee.borrowerFeeDollar != null && loanFee.borrowerFeeDollar > 0) ||
      (loanFee.borrowerFeePercent != null && loanFee.borrowerFeePercent > 0)) {
      payers.push(Payer.Borrower);
    }
    if ((loanFee.sellerFeeDollar != null && loanFee.sellerFeeDollar > 0) ||
      (loanFee.sellerFeePercent != null && loanFee.sellerFeePercent > 0)) {
      payers.push(Payer.Seller);
    }
    if ((loanFee.lenderFeeDollar != null && loanFee.lenderFeeDollar > 0) ||
      (loanFee.lenderFeePercent != null && loanFee.lenderFeePercent > 0)) {
      payers.push(Payer.Lender);
    }
    if ((loanFee.thirdPartyFeeDollar != null && loanFee.thirdPartyFeeDollar > 0) ||
      (loanFee.thirdPartyFeePercent != null && loanFee.thirdPartyFeePercent > 0)) {
      payers.push(Payer.ThirdParty);
    }
    return payers;
  }

  getSplitAmountAndPayerDisplayTextForAllPossiblePayers = (loanFee: LoanFee, omitPayerIndicator = false): string => {
    let displayText = "";
    displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.borrowerFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.borrowerFeeDollar || 0, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(B)/' : '/'));
    displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.sellerFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.sellerFeeDollar || 0, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(S)/' : '/'));
    displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.lenderFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.lenderFeeDollar || 0, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(L)/' : '/'));
    displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.thirdPartyFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.thirdPartyFeeDollar || 0, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(3rd)' : ''));
    return displayText;
  }

  getSplitAmountAndPayerDisplayText = (loanFee: LoanFee, omitPayerIndicator = false): string => {
    const payers = this.getSplitPayerInfo(loanFee);
    let displayText = "";
    payers.forEach(payer => {
      switch (payer) {
        case Payer.Borrower:
          displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.borrowerFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.borrowerFeeDollar, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(B)/' : '/'));
          break;
        case Payer.Seller:
          displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.sellerFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.sellerFeeDollar, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(S)/' : '/'));
          break;
        case Payer.Lender:
          displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.lenderFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.lenderFeeDollar, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(L)/' : '/'));
          break;
        case Payer.ThirdParty:
          displayText += ((loanFee.feePercentOf ? (this._percentPipe.transform((loanFee.thirdPartyFeePercent || 0) / 100, '1.1-3')) : (this._currencyPipe.transform(loanFee.thirdPartyFeeDollar, 'USD', 'symbol', '1.2-2'))) + (!omitPayerIndicator ? '(3rd)/' : '/'));
          break;
        default:
          return "";
      }
    })
    if (displayText) {
      displayText = displayText.slice(0, -1);
    }
    return displayText;
  }

  getSingleAmountAndMultiPayerDisplayText = (fee: LoanFee): string => {
    const amount = fee.calculatedValues?.totalFee ?? 0;
    const amountFormatted = this._currencyPipe.transform(amount, 'USD', 'symbol', '1.2-2');
    const payers = this.getSplitPayerInfo(fee);
    let payerDisplayText = "";
    payers.forEach(payer => {
      payerDisplayText += this.getPayerDisplayText(payer);
    });
    return `${amountFormatted} (${payerDisplayText})`;
  }

  getSingleAmountAndPayerDisplayText = (fee: LoanFee): string => {
    const amount = fee.calculatedValues?.totalFee ?? 0;
    const amountFormatted = this._currencyPipe.transform(amount, 'USD', 'symbol', '1.2-2');
    const payer = this.getSinglePayer(fee);
    const payerDisplayText = this.getPayerDisplayText(payer);
    return `${amountFormatted} (${payerDisplayText})`;
  }

  initMissingFeeValues = (fee: LoanFee): LoanFee => {
    if (fee.paidOutsideClosing === null) {
      fee.paidOutsideClosing = 0;
    }
    if (!fee.calculatedValues) {
      return fee;
    }
    if (!fee.calculatedValues.totalFeePercent) {
      fee.calculatedValues.totalFeePercent = 0;
    }
    if (!fee.calculatedValues.totalFee) {
      fee.calculatedValues.totalFee = 0;
    }
    if (!fee.calculatedValues.sellerConcessions) {
      fee.calculatedValues.sellerConcessions = 0;
    }
    if (!fee.calculatedValues.sellerTotal) {
      fee.calculatedValues.sellerTotal = 0;
    }
    if (!fee.calculatedValues.borrowerTotal) {
      fee.calculatedValues.borrowerTotal = 0;
    }
    if (!fee.calculatedValues.monthlyFee) {
      fee.calculatedValues.monthlyFee = 0;
    }

    return fee;
  }

  private getPayerDisplayText = (payer: Payer): string => {
    switch (payer) {
      case Payer.Borrower:
        return "B";
      case Payer.Seller:
        return "S";
      case Payer.Lender:
        return "L";
      case Payer.ThirdParty:
        return "3rd";
      default:
        return "";
    }
  }
}

export enum Payer {
  Borrower = 'Borrower',
  Lender = 'Lender',
  Seller = 'Seller',
  ThirdParty = 'ThirdParty'
}
