<div class="modal-header">
  <h5 class="modal-title">Reassign Agent(s) Contact</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div class="row">
    <label class="col-md-3 col-form-label" for="agentContact"
      >Reassign To:</label
    >
    <div class="col-md-9">
      <div class="d-flex">
        <div class="flex-grow-1">
          <p-dropdown
            styleClass="w-100"
            name="agentContact"
            [options]="users"
            optionLabel="userFullName"
            optionValue="userCompanyGuid"
            [(ngModel)]="selectedUser"
            placeholder="Select Contact"
            [virtualScroll]="true"
            [filter]="true"
            [showClear]="true"
            [itemSize]="20"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close me-2"></i>Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary"
    [disabled]="isSaving || !selectedUser"
    (click)="save()"
  >
    <span *ngIf="!isSaving">Reassign</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
