<p-table
  #dt2
  [value]="info.parties"
  styleClass="p-datatable-gridlines p-datatable-sm"
  [paginator]="true"
  [rows]="25"
  [rowsPerPageOptions]="[25, 50, 100]"
  [autoLayout]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  scrollable="true"
  [scrollHeight]="'400px'"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns">{{ col.header }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-party>
    <tr>
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'emailAddress'">
          <div class="contact-info">
            <a [href]="'mailto:' + party.emailAddress | safeUrl" class="me-1">
              {{ party.emailAddress }}
            </a>
          </div>
        </span>

        <span *ngSwitchCase="'secondaryEmailAddress'">
          <div class="contact-info">
            <a
              [href]="'mailto:' + party.secondaryEmailAddress | safeUrl"
              class="me-1"
            >
              {{ party.secondaryEmailAddress }}
            </a>
          </div>
        </span>

        <span *ngSwitchCase="'homePhoneNumber'">
          <div class="contact-info">
            <a [href]="'tel:' + party.homePhoneNumber | safeUrl" class="me-1">
              {{ party.homePhoneNumber | phone }}
            </a>
          </div>
        </span>

        <span *ngSwitchCase="'cellPhoneNumber'">
          <div class="contact-info">
            <a [href]="'tel:' + party.cellPhoneNumber | safeUrl" class="me-1">
              {{ party.cellPhoneNumber | phone }}
            </a>
          </div>
        </span>

        <span *ngSwitchCase="'businessPhoneNumber'">
          <div class="contact-info">
            <a
              [href]="'tel:' + party.businessPhoneNumber | safeUrl"
              class="me-1"
            >
              {{ party.businessPhoneNumber | phone }}
            </a>
          </div>
        </span>

        <span *ngSwitchDefault>
          {{ party[col.field] }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length" class="text-center">
        No parties found.
      </td>
    </tr>
  </ng-template>
</p-table>
