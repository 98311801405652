import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { CocDetailsModule } from '../coc-details/coc-details.module';
import { DisclosureTrackingConfigComponent } from './components/disclosure-tracking-config/disclosure-tracking-config.component';
import { DisclosureTrackingDetailsComponent } from './components/disclosure-tracking-history/disclosure-tracking-details/disclosure-tracking-details.component';
import { DisclosureTrackingEdisclosureComponent } from './components/disclosure-tracking-history/disclosure-tracking-edisclosure/disclosure-tracking-edisclosure.component';
import { DisclosureTrackingHistoryComponent } from './components/disclosure-tracking-history/disclosure-tracking-history.component';
import { DisclosureTrackingReasonsComponent } from './components/disclosure-tracking-history/disclosure-tracking-reasons/disclosure-tracking-reasons.component';
import { LoanFeeHistoriesDialogComponent } from './components/disclosure-tracking-history/loan-fee-histories-dialog/loan-fee-histories-dialog.component';
import { DisclosureTrackingComponent } from './components/disclosure-tracking.component';
import { DisclosureTrackingService } from './services/disclosure-tracking.service';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    CocDetailsModule
  ],
  declarations: [
    DisclosureTrackingComponent,
    DisclosureTrackingConfigComponent,
    DisclosureTrackingDetailsComponent,
    DisclosureTrackingEdisclosureComponent,
    DisclosureTrackingHistoryComponent,
    DisclosureTrackingReasonsComponent,
    LoanFeeHistoriesDialogComponent
  ],
  exports: [
    DisclosureTrackingComponent,
    DisclosureTrackingConfigComponent,
    DisclosureTrackingDetailsComponent,
    DisclosureTrackingEdisclosureComponent,
    DisclosureTrackingHistoryComponent,
    DisclosureTrackingReasonsComponent,
  ],
  providers: [
    DisclosureTrackingService
  ]
})
export class DisclosureTrackingModule {
  constructor() { }
}
