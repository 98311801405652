<form class="form-horizontal" id="serviceForm" name="serviceForm" #serviceForm="ngForm" (ngSubmit)="saveService()" novalidate>
    <div class="modal-header">
        <h5 class="modal-title">Global SMS</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <label class="custom-control-label">Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    [ngClass]="{'is-invalid': name && name.touched && name.invalid}"
                    #name="ngModel"
                    placeholder="Name"
                    [(ngModel)]="service.name"
                    required>
                <div class="invalid-feedback">Name is required.</div>
            </div>
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Server Type</label>
                <select
                    class="form-control"
                    id="serviceType"
                    name="serviceType"
                    [ngClass]="{'is-invalid': serviceType && serviceType.touched && serviceType.invalid}"
                    #serviceType="ngModel"
                    [(ngModel)]="service.serviceType"
                    required
                >
                    <option value="">-- Select One --</option>
                    <option value="Twilio">Lodasoft</option>
                </select>
                <div class="invalid-feedback">Server type is required.</div>
            </div>
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Phone #</label>
                <input
                  type="text"
                  class="form-control"
                  name="fromPhoneNumber"
                  placeholder="Phone Number"
                  #phoneNumber="ngModel"
                  [validation]="true"
                  [patterns]="phoneNumberPattern"
                  [ngClass]="{'is-invalid': phoneNumber && phoneNumber.touched && phoneNumber.invalid}"
                  prefix="+1"
                  mask="NNNNNNNNNN"
                  [(ngModel)]="service.fromPhoneNumber">
                <div class="invalid-feedback">Enter a valid phone number.</div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'Twilio'">
                <label class="custom-control-label">Account SID</label>
                <input type="text" class="form-control" name="accountIdentifier" placeholder="Account SID" [(ngModel)]="service.accountIdentifier">
            </div>
            <div class="col-md-12 mt-1" *ngIf="service.serviceType === 'Twilio'">
                <label class="custom-control-label">Auth Token</label>
                <input type="text" class="form-control" name="authToken" placeholder="Auth Token" [(ngModel)]="service.authToken">
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button
            type="button"
            class="btn btn-secondary right"
            (click)="activeModal.dismiss()"
        >
            Cancel
        </button>
        <button
            type="submit"
            class="btn btn-primary right"
        >
            <span *ngIf="saving" class="spinner-border spinner-border-sm"></span>
            <span *ngIf="!saving">Save</span>
        </button>
    </div>

</form>
