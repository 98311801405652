import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { DocumentType, LoanApplication, LoanDoc, Role, UserProfile } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { LoanDocsService } from '../../loan-docs/services/loan-docs.service';
import { InternalContactsService } from '../../internal-contacts/services/internal-contacts.service';
import { InternalContact } from '../../internal-contacts/models/internal-contact.model';
import { ApplicationService } from '../services/applications.service';
import { CopyFormApp } from '../models/copy-form-app-model';
import { Constants } from 'src/app/services/constants';
import { NextCopyAppDialogComponent } from '../next-copy-application-dialog/next-copy-application-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { Observable, Observer, Subscription, forkJoin, map, of } from 'rxjs';

@Component({
  selector: 'copy-application-dialog',
  templateUrl: 'copy-application-dialog.component.html',
  styleUrls: ['copy-application-dialog.component.scss']
})
export class CopyApplicationDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  title: string;

  documentTypes: DocumentType[];

  loanTypes: LoanType[];

  loanPurposes: LoanPurpose[];

  borrowerNamesWithMissingSSN: string[] = [];

  loanDocs: LoanDoc[] = [];

  selectedStakingOrderId: number;

  contactRoles: Role[];

  contactUserIdsByRole: object;

  losAligmentModel: object = {};

  isSelectAllClicked: boolean = false;

  copyFormApp: CopyFormApp;

  externalCompanyId: number;
  branchUsers: UserProfile[] = [];
  externalCompanyUsers: UserProfile[] = [];

  protected disableNextButton: boolean = true;

  private _internalContacts: InternalContact[];
  private _currentApplication: LoanApplication;

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _applicationService: ApplicationService,
    private readonly _modalService: NgbModal,
    private _notifyService: NotificationService) {
    super(injector);
  }

  ngOnInit() {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this._currentApplication = context.application;

        if (this._currentApplication) {
          this.externalCompanyId = this._currentApplication.externalCompanyId;
          this.loadMortgageDetails();
        }
      });
    } else {
      this._currentApplication = this.applicationContext.application;

      this.externalCompanyId = this._currentApplication.externalCompanyId;
      this.loadMortgageDetails();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onCancelClicked = () => {
    this.activeModal.dismiss();
  }

  onNextClicked = () => {
    let docGuidsToCopy: string[] = [];
    this.loanDocs.forEach(loanDoc => {
      if (loanDoc && loanDoc.docFiles) {
        loanDoc.docFiles.forEach(file => {
          if (file && file['isSelected']) {
            docGuidsToCopy.push(file.guid);
          }
        })
      }
    })
    this.copyFormApp.docGuidsToCopy = docGuidsToCopy;
    this.activeModal.close();
    this.openNextCopyAppDialog(docGuidsToCopy);
  }

  onSelectAllClicked = (isSelected: boolean): void => {
    this.documentTypes.forEach(docType => docType['selectAll'] = !isSelected);
    this.loanDocs.forEach(loanDoc => {
      if (loanDoc.docFiles) {
        loanDoc.docFiles.forEach(docFile => docFile['isSelected'] = !isSelected);
        loanDoc['checkAll'] = !isSelected;
      }
    });
  }

  showDoctype = (docType: LoanDoc) => {
    return !!this.loanDocs.find(loanDoc => loanDoc.documentTypeId === docType.documentTypeId);
  }

  private loadMortgageDetails = (): void => {
    this.documentTypes = this.applicationContext.globalConfig.documentType;
    this.loanTypes = this.applicationContext.globalConfig.loanType;
    this.loanPurposes = this.applicationContext.globalConfig.loanPurpose;
    this.copyFormApp = new CopyFormApp(this.applicationContext.application.loanTypeId, this.applicationContext.application.loanPurposeId, '');
    this.borrowerNamesWithMissingSSN = [];

    if (this.loanPurposes.find(x => x.loanPurposeId == this.applicationContext.application.loanPurposeId).transactionType == 'Mortgage') {
      if (this.applicationContext.currentMortgage) {
        const filteredBorrowers = this.applicationContext.currentMortgage.borrowers.filter(borrower => !borrower.socialSecNum);
        if (filteredBorrowers) {
          this.borrowerNamesWithMissingSSN = filteredBorrowers.map(borrower => `${borrower.lastName}, ${borrower.firstName}`);
        }
      } else {
        this.borrowerNamesWithMissingSSN = [];
      }
    }
    this.contactRoles = this.getContactRoles(this.applicationContext.currentMortgage.channel);

    const observables = {
      loadInternalContacts: this.loadInternalContacts(),
      loadLoanDocs: this.loadLoanDocs(),
      loadLosAlignmentModel: this.loadLosAlignmentModel(),
      externalCompanyUsers: this.externalCompanyId && this.externalCompanyId > 0 ? this.loadExternalCompanyUsers() : of(null)
    };

    const observer: Observer<any> = {
      next: (value: any): void => {
      },
      error: (error: any): void => {
        this._notifyService.showError(
          error?.message || 'An error occurred loading mortgage info to copy.',
          'Error!'
        );
      },
      complete: (): void => {
      }
    }
    this._spinner.show();
    this.disableNextButton = true;
    forkJoin(observables).subscribe(observer).add(() => {
      this._spinner.hide();
      this.disableNextButton = false;
    });
  }

  private loadLoanDocs = (): Observable<void> => {
    return this._loanDocsService.getLoanDocs(this.applicationContext.application.applicationId, false).pipe(map(results => {
      this.loanDocs = results;
      this.loanDocs.forEach(loanDoc => loanDoc['checkAll'] = false);
    }))
  }

  private getContactRoles = (channel: string) => {
    let finalRoles = [];
    const userPermissions = this.applicationContext.userPermissions;

    if (userPermissions.enabledChannels != null && channel && channel != '') {
      this.applicationContext.globalConfig.channelRoles[channel.toLowerCase()].forEach((role: Role) => {
        if (role.isLoanContact) {
          const roleChannel = role.roleChannels.find(role => role.channel === channel);
          role.order = roleChannel.order || null;
          finalRoles.push(role);
        }
      });
    } else {
      this.applicationContext.globalConfig.roles.forEach(role => {
        if (role.isLoanContact) {
          finalRoles.push(role);
        }
      });
    }

    finalRoles = _.orderBy(finalRoles, ['order'], ['asc']);
    return finalRoles;
  }

  private loadInternalContacts = (): Observable<void> => {
    return this._internalContactsService.getInternalContacts(this.applicationContext.application.applicationId).pipe(map(results => {
      this.contactUserIdsByRole = {};
      this._internalContacts = results;

      if (Array.isArray(this._internalContacts)) {
        this._internalContacts.forEach(iC => {
          this.contactUserIdsByRole[iC.roleId] = iC.userId;
        })
      }
    }))
  }

  private loadExternalCompanyUsers = () => {
    return this._internalContactsService.getExternalCompanyUsers(this._currentApplication.companyId, this.externalCompanyId).pipe(map(result => {
      this.externalCompanyUsers = result;
      this.branchUsers = this.externalCompanyUsers.filter(el => el.branchId == this._currentApplication.branchId);
    }));
  }

  private loadLosAlignmentModel = (): Observable<void> => {
    return this._applicationService.getAlignmentsByChannelAndCompanyId(this.applicationContext.userPermissions.companyId,
      this.applicationContext.currentMortgage.channel).pipe(map(results => {
        let userAlignment = results.filter(alignment => alignment[1] == this.applicationContext.userPermissions.userId)[0];
        if (!userAlignment) {
          return;
        }
        this.contactRoles.map((role, key) => {
          this.losAligmentModel[role.roleId] = userAlignment[key + 1];
        })
      }))
  }

  private openNextCopyAppDialog = (docGuidsToCopy: string[]) => {
    const globalConfig = this.applicationContext.globalConfig;
    const modalRef = this._modalService.open(NextCopyAppDialogComponent, Constants.modalOptions.xlarge)
    modalRef.componentInstance.title = 'Copy original internal contacts OR Auto Assign the Alignment based on?';
    modalRef.componentInstance.docGuidsToCopy = docGuidsToCopy;
    modalRef.componentInstance.contactRoles = this.contactRoles;
    modalRef.componentInstance.contactUserIdsByRole = this.contactUserIdsByRole;
    modalRef.componentInstance.users = globalConfig.users;
    modalRef.componentInstance.usersAll = globalConfig.usersAll;
    modalRef.componentInstance.alignments = globalConfig.alignment;
    modalRef.componentInstance.copyFormApp = this.copyFormApp;
    modalRef.componentInstance.losAligmentModel = this.losAligmentModel;
    modalRef.componentInstance.appId = this.applicationContext.application.applicationId;
    modalRef.componentInstance.companyId = this.applicationContext.userPermissions.companyId;
    modalRef.componentInstance.branchUsers = this.branchUsers;
    modalRef.componentInstance.externalCompanyUsers = this.externalCompanyUsers;
    modalRef.result.then((result) => {

    }, (res) => {

    });
  }
}
