import { LosVendor } from "./los-vendor.model";

export enum PassLevel {
  None = 'None',
  Create = 'Create',
  Update = 'Update',
  Admin = 'Admin',
}

export class AllowedField {
  readonly allowedFieldId?: number;
  losVendor?: LosVendor;
  component: string = "";
  propertyName: string = "";
  passLevel: PassLevel = PassLevel.Update;
  readonly errors?: string;
  readonly losInstanceId: string;
}

export type AllowedFieldsArray = Array<AllowedField>;