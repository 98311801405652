import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'video-recorder-dialog',
  templateUrl: 'video-recorder-dialog.component.html',
  styleUrls: ['video-recorder-dialog.component.scss']
})

export class VideoRecorderDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }

  onVideoSelectedForUse = (recordedBlobs: Blob[]) => {
    this.activeModal.close(recordedBlobs);
  }
}
