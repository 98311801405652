import { Component, Input, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/models';
import { LeadRouteType } from 'src/app/modules/leads/models/lead-route-history.model';
import { LeadRouteService } from 'src/app/services/lead-route.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs';
import { LeadRouteGroup } from 'src/app/modules/leads/models/lead-route-group.model';

@Component({
  selector: 'fetch-tab',
  templateUrl: './fetch-tab.component.html',
  styleUrls: ['./fetch-tab.component.scss']
})
export class FetchTabComponent implements OnInit {
  @Input() userProfile: UserProfile;
  leadRouteType: LeadRouteType = LeadRouteType.Fetch;
  isLoading: boolean = true;
  leadRouteGroups: LeadRouteGroup[] = [];

  constructor(private readonly _leadRouteService: LeadRouteService,
    private readonly _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getLeadGroups();
  }

  getLeadGroups = () => {
    this.isLoading = true;
    this._leadRouteService.getLeadRouteGroups(this.leadRouteType, true, false, false, false, false)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (leadRouteGroups) => {
          this.leadRouteGroups = leadRouteGroups;
        },
        error: (err) => {
          this._notificationService.showError(err.message || err, "Error!");
        }
      });
  }
}
