import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs';
import { CalendarDayType, MergedCalendarDay, OtherCalendarDay } from 'src/app/models/calendar-days.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CalendarDaysService } from 'src/app/services/calendar-days.service';
import { NotificationService } from 'src/app/services/notification.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
    selector: 'upsert-calendar-event',
    templateUrl: './upsert-calendar-event.component.html',
})
export class UpsertCalendarEventComponent implements OnInit {
    @ViewChild('upsertHolidayForm') upsertHolidayForm: NgForm | undefined;
    @Input() holidayEvent: Partial<MergedCalendarDay>;
    @Output() close: EventEmitter<{ action: string; response: Partial<OtherCalendarDay> }> = new EventEmitter();
    holidayTypes: EnumerationItem[] = [];
    restrictedTypes: CalendarDayType[] = [
        CalendarDayType.None
    ]
    saveInProgress: boolean;
    deleteInProgress: boolean;
    ngOnInit(): void {
        this.holidayTypes = Object.keys(CalendarDayType).map(k => ({
            name: k,
            value: k
        })
        ).filter(h => !this.restrictedTypes.includes(h.name as CalendarDayType));
    }

    insertNewHolidayEvent(insertDisabledEvent: boolean = false): void {
        if (insertDisabledEvent) {
            this.deleteInProgress = true;
        } else {
            this.saveInProgress = true;
        }
        const request: OtherCalendarDay = this.getUpsertRequest();
        this._calendarDaysService.insertHoliday(request)
            .pipe(finalize(() => insertDisabledEvent ? this.deleteInProgress = false : this.saveInProgress = false))
            .subscribe({
                next: (res) => {
                    this._notificationService.showSuccess(insertDisabledEvent
                        ? 'Holiday event disabled successfully'
                        : 'New holiday event created successfully', 'Success');
                    this.close.emit({ action: 'Insert', response: res });
                }, error: err => {
                    this._notificationService.showError(err?.message || 'unable to insert new holiday event', "Error!");
                }
            });
    }

    updateHolidayEvent(): void {
        this.saveInProgress = true;
        const request: OtherCalendarDay = this.getUpsertRequest(this.holidayEvent.id);
        this._calendarDaysService.updateHoliday(request)
            .pipe(finalize(() => this.saveInProgress = false))
            .subscribe({
                next: (res) => {
                    this._notificationService.showSuccess('Holiday event updated successfully', 'Success');
                    this.close.emit({ action: 'Update', response: res });
                }, error: err => {
                    this._notificationService.showError(err?.message || 'Unable to update holiday event', "Error!");
                }
            });
    }

    deleteHolidayEvent(): void {
        this.deleteInProgress = true;
        this._calendarDaysService.deleteHoliday(this.holidayEvent.id)
            .pipe(finalize(() => this.deleteInProgress = false))
            .subscribe({
                next: (res) => {
                    this._notificationService.showSuccess(this.holidayEvent.type === CalendarDayType.None
                        ? 'Holiday event enabled successfully'
                        : 'Holiday event deleted successfully', 'Success');
                    this.close.emit({
                        action: 'Delete', response: { calendarDayId: this.holidayEvent.id }
                    });
                }, error: err => {
                    this._notificationService.showError(err?.message || 'Unable to delete holiday event', "Error!");
                }
            });
    }

    onSave(): void {
        this.upsertHolidayForm.form.markAllAsTouched();
        if (!this.upsertHolidayForm.form.valid) {
            return;
        }
        this.holidayEvent.id
            ? this.updateHolidayEvent()
            : this.insertNewHolidayEvent()
    }

    onClose(): void {
        this.close.emit(null)
    }

    onDelete(): void {
        Swal.fire({
            title: this.deleteButtonText,
            text: 'Are you sure you want to continue?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result: SweetAlertResult) => {
            if (!result.value) {
                return;
            }
            if (this.holidayEvent.isGlobal) {
                this.holidayEvent.type = CalendarDayType.None;
                this.insertNewHolidayEvent(true)
                return;
            }
            this.deleteHolidayEvent();
        });
    }

    private getUpsertRequest(id: number = undefined) {
        return {
            calendarDayId: id,
            title: this.holidayEvent.title,
            type: this.holidayEvent.type,
            date: this.holidayEvent.dateStr
        }
    }

    get isEditingDisabled(): boolean {
        if (!this.holidayEvent) {
            return false;
        }
        return this.holidayEvent.isGlobal || this.holidayEvent.override;
    }

    get deleteButtonText(): string {
        if (!this.holidayEvent) {
            return '';
        }
        return this.holidayEvent.isGlobal
            ? 'Disable Event'
            : this.holidayEvent.override
                ? 'Enable Event'
                : 'Delete'
    }

    constructor(
        private readonly _notificationService: NotificationService,
        private readonly _calendarDaysService: CalendarDaysService
    ) { }
}