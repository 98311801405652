<div>
  <div class="dialpad">
    <div class="dialpad__row">
      <button class="dialpad__button" (click)="onDialpadNumberClick('1')">1</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('2')">2</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('3')">3</button>
    </div>
    <div class="dialpad__row">
      <button class="dialpad__button" (click)="onDialpadNumberClick('4')">4</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('5')">5</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('6')">6</button>
    </div>
    <div class="dialpad__row">
      <button class="dialpad__button" (click)="onDialpadNumberClick('7')">7</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('8')">8</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('9')">9</button>
    </div>
    <div class="dialpad__row">
      <button class="dialpad__button" (click)="onDialpadNumberClick('*')">*</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('0')">0</button>
      <button class="dialpad__button" (click)="onDialpadNumberClick('#')">#</button>
    </div>
    <div class="dialpad__row">
      <button class="dialpad__button" (click)="onDialpadBackspaceClick()">
        <i class="fa fa-backspace"></i>
      </button>
      <button
        class="dialpad__button"
        [ngClass]="{'dialpad__button--sms': callButtonEnabled,
                    'dialpad__button--call--disabled': !callButtonEnabled}"
        (click)="onDialpadSmsClick()">
        <i class="fas fa-sms"></i>
      </button>
      <button
        class="dialpad__button"
        [ngClass]="{'dialpad__button--call': callButtonEnabled,
                    'dialpad__button--call--disabled': !callButtonEnabled}"
        (click)="onDialpadCallClick()">
        <i class="fa fa-phone"></i>
      </button>
    </div>
  </div>
</div>
