<div class="card-body">
    <p-table #dt1 [value]="borrower.identifyingDocuments" *ngIf="borrower && !loading" 
        [resizableColumns]="true" [autoLayout]="true"
        styleClass="p-datatable-gridlines p-datatable-sm">
        <ng-template pTemplate="caption">
            <div class="d-flex justify-content-end">
                <button class="btn btn-sm btn-primary" (click)="addEditDocument()">Add Document</button>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : ''"
                    style="min-width: 100px;">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index>
            <tr>
                <td class="text-center">
                    <a href="javascript:void(0)" (click)="addEditDocument(rowData)">
                        <i class="fa fa-pencil-alt"></i>
                    </a>
                    <a href="javascript:void(0)" (click)="deleteDocument(rowData)" class="ms-2">
                        <i class="fa fa-trash-alt"></i>
                    </a>
                </td>
                <td>
                    <span class="text-cell-primary cell-mw125">
                        {{ rowData['documentTypeName'] }}
                    </span>
                    
                </td>
                <td>
                    {{ rowData.documentTypeOtherDescription }}
                </td>
                <td> 
                    <span class="text-cell-primary">
                       {{rowData.identifier}} 
                    </span>
                </td>
                <td>
                    <span class="text-cell-primary cell-mw1">
                        {{ rowData['issuedByTypeName'] }}
                    </span>
                    
                    <br>
                    <span *ngIf="rowData.issuedByType == 'Other' "
                        class="text-muted text-ellipsis cell-mw125"
                        title="rowData.issuedByTypeOtherDescription">
                        {{rowData.issuedByTypeOtherDescription}}
                    </span>
                    <span *ngIf="rowData.issuedByType == 'Country' "
                        class="text-muted text-ellipsis cell-mw125"
                        title="rowData.issuedByTypeOtherDescription">
                        {{rowData.issuedByCountry}}
                    </span>
                    <span *ngIf="rowData.issuedByType == 'StateOrProvince' "
                        class="text-muted text-ellipsis cell-mw125" 
                        title="rowData.issuedByTypeOtherDescription">
                        {{rowData.issuedByStateCode | uppercase}}
                    </span>
                    
                    
                </td>
                <td> 
                    <span class="text-cell-primary text-ellipsis">
                        {{rowData.issuedByAgency}}
                    </span>
                </td>
                <td class="text-center"> 
                    <span class="text-cell-primary">
                        {{rowData.issueDate | date: 'MM-dd-yyyy' }} 
                    </span>
                    <hr class="my-1">
                    <span class="text-cell-primary">
                        {{rowData.expirationDate | date: 'MM-dd-yyyy' }}
                    </span>
                </td>
                <td class="text-center">
                    <a href="javascript:void(0)" (click)="viewFile(rowData.docFileId, rowData)" *ngIf="rowData.docFileId || rowData.file">
                        <i class="fa fa-lg fa-eye"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="12" class="text-center"> No identifying document found. </td>
            </tr>
        </ng-template>
    </p-table>
</div>