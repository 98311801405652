<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>
<ng-template #mainContent>
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4 col-xxxl-3 p-2 pb-3">
            <div class="card">
                <div class="card-body">
                    <div class="alert alert-danger border-0" role="alert" *ngIf="error">
                        <strong>{{error.header}}</strong> {{error.message}}
                    </div>
                    <div class="alert alert-success border-0" role="alert" *ngIf="changeDone">
                        <strong>Success!</strong>
                        Change password success.
                    </div>
                    <form
                        [formGroup]="changePasswordForm"
                        class="form-horizontal auth-form"
                        novalidate
                        id="changePasswordForm"
                        name="changePasswordForm"
                    >
                        <div class="form-group mb-2">
                            <div class="input-group mb-2">
                                <input
                                    type="password"
                                    class="form-control"
                                    id="oldPassword"
                                    name="oldPassword"
                                    formControlName="oldPassword"
                                    [ngClass]="{'is-invalid' : oldPassword && oldPassword.touched && oldPassword.invalid}"
                                    [(ngModel)]="changePasswordRequest.oldPassword"
                                    placeholder="Current Password"
                                    required
                                >
                                <div *ngIf="oldPassword.errors?.['required']" class="invalid-feedback">Please enter your current password.</div>
                            </div>
                            <div class="input-group mb-2">
                                <input
                                    type="password"
                                    class="form-control"
                                    id="newPassword"
                                    name="newPassword"
                                    formControlName="newPassword"
                                    [ngClass]="{'is-invalid' : newPassword && newPassword.touched && newPassword.invalid}"
                                    [(ngModel)]="changePasswordRequest.newPassword"
                                    placeholder="New Password"
                                    requiresDigit
                                    requiresNonAplhaNumeric
                                    required
                                    minlength="8"
                                    [caseSize]="'both'"
                                    required
                                >
                                <div *ngIf="newPassword.errors?.['required']" class="invalid-feedback">Please enter your new password.</div>
                                <div *ngIf="newPassword.errors?.['minlength']" class="invalid-feedback">New password must be at least 8 characters long.<br/></div>
                                <div *ngIf="newPassword.errors?.['uppercase']" class="invalid-feedback">New password must contain an uppercase character.<br/></div>
                                <div *ngIf="newPassword.errors?.['lowercase']" class="invalid-feedback">New password must contain a lowercase character.<br/></div>
                                <div *ngIf="newPassword.errors?.['requiresDigit']" class="invalid-feedback">New password must contain at least one digit.<br/></div>
                                <div *ngIf="newPassword.errors?.['requiresNonAplhaNumeric']" class="invalid-feedback">New password must contain non-alphanumeric character.<br/></div>
                            </div>
                            <div class="input-group mb-2">
                                <input
                                    type="password"
                                    class="form-control"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    formControlName="confirmPassword"
                                    [ngClass]="{'is-invalid' : confirmPassword && confirmPassword.touched && confirmPassword.invalid || changePasswordForm.hasError('stringDoesntMatch') }"
                                    [(ngModel)]="changePasswordRequest.confirmPassword"
                                    placeholder="Confirm New Password"
                                    required
                                >
                                <div *ngIf="confirmPassword.errors?.['required']" class="invalid-feedback">Please confirm your new password.</div>
                                <div *ngIf="changePasswordForm.hasError('stringDoesntMatch')" class="invalid-feedback">Confirm password doesn't match your new password.<br/></div>
                            </div>
                        </div>
                        <!--end form-group-->

                        <div class="mt-3 text-end">
                            <button class="btn btn-primary waves-effect waves-light" type="button"
                                [disabled]="newPassword.invalid || confirmPassword.invalid || changePasswordForm.hasError('stringDoesntMatch')"
                                (click)="onChangePasswordClicked()">
                                <span *ngIf="!changingPassword">Change Password</span>
                                <span *ngIf="changingPassword">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Saving
                                </span>
                            </button>
                        </div>
                    </form>
                    <!--end form-->
                </div>
            </div>
        </div>
    </div>
</ng-template>

<admin-page-template
[useActionBarSections]="true"
[pageActionBarLeftContentTemplate]="pageActionBarLeft"
[pageMainContentTemplate]="mainContent">
</admin-page-template>
