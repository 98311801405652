<ckeditor
  [editor]="functionEditor"
  [config]="editorConfig"
  #functionEditorComponent
  id="functionBody"
  name="functionBody"
  (ready)="onEditorReady($event)"
  data="{{functionExpression}}"
  [(ngModel)]="functionExpression"
  (ngModelChange)="onFunctionChanged()">
</ckeditor>
