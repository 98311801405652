export class CharacteristicMergeValue {
  characteristicMergeValueId: number;
  characteristicFieldId: number;
  borrowerCharacteristicId: number;
  loanCharacteristicId: number;
  mergeValue: string;

  constructor(characteristicFieldId: number) {
    this.characteristicFieldId = characteristicFieldId;
  }
}
