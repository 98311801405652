import { Component, EventEmitter, Output } from "@angular/core";
import { Utils } from "src/app/core/services/utils";
import { LosEventSource, LosEventDirection, LosEventOperation, LosEventStatus } from "src/app/models";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";

@Component({
  selector: "los-lde-transaction-history-filters",
  templateUrl: "los-lde-transaction-history-filters.component.html"
})
export class LosLdeTransactionHistoryFiltersComponent {
  @Output()
  filterChanged = new EventEmitter();

  eventSourceFilter: LosEventSource = null;
  directionFilter: LosEventDirection = null;
  operationFilter: LosEventOperation = null;
  statusFilter: LosEventStatus = null;

  eventSources: EnumerationItem[] = [];
  eventDirections: EnumerationItem[] = [];
  eventOperations: EnumerationItem[] = [];
  eventStatuses: EnumerationItem[] = [];

  constructor () {
    this.eventSources = this.getEnumItemFromEnum(LosEventSource);
    this.eventDirections = this.getEnumItemFromEnum(LosEventDirection);
    this.eventOperations = this.getEnumItemFromEnum(LosEventOperation);
    this.eventStatuses = this.getEnumItemFromEnum(LosEventStatus);
  }

  onFilterChanged() {
    this.filterChanged.emit({
      eventSourceFilter: this.eventSourceFilter,
      directionFilter: this.directionFilter,
      operationFilter: this.operationFilter,
      statusFilter: this.statusFilter,
    });
  }

  private getEnumItemFromEnum(input): EnumerationItem[] {
    return Object.values(input).map(value => ({
      value: value,
      name: Utils.splitCamelCaseString(value as string),
    }))
  }
}