import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { chain, cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { CocReason } from 'src/app/modules/disclosure-tracking/models/disclosure-tracking.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import {
  ActionButtonOption,
  GridActionButtonType,
} from 'src/app/shared/models/action-button-options.model';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CocReasonsConfigService } from './coc-reasons-config.service';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'app-coc-reasons-config',
  templateUrl: './coc-reasons-config.component.html',
  styleUrls: ['./coc-reasons-config.component.scss']
})
export class CocReasonsConfigComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild(Table)
  cocReasonTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('cocReasonOrderDrawer')
  cocReasonOrderDrawer: DrawerComponent;

  @ViewChild('cocReasonOrder')
  cocReasonOrder: RearrangeOrderComponent

  actionButtonOptions: Array<ActionButtonOption>;
  cocReasonColumns = [];
  cocReasons: Array<CocReason>;
  disclosureReasons: EnumerationItem[] = [];
  reasonCategories: EnumerationItem[] = [];

  allowAddNew: boolean;

  currentCoCReason: Partial<CocReason>;
  upsertCoCReasonDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  constructor(
    private readonly injector: Injector,
    private readonly _cocReasonService: CocReasonsConfigService,
    private readonly _enumsService: EnumerationService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
  ) {
    super(injector);
    this.allowAddNew = !this.applicationContext.userPermissions.superAdmin;
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.reasonCategories = enums[Constants.mortgageEnumerations.changeOfCircumstanceReasonCategory];
      this.disclosureReasons = enums[Constants.mortgageEnumerations.disclosureReason];
    });
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.cocReasonColumns = [
      { field: 'title', header: 'Title', sortable: true },
      { field: 'disclosureReason', header: 'Disclosure Reason', sortable: true },
      { field: 'reasonCategoryDisplayName', header: 'Reason Category', sortable: true },
      { field: 'defaultComments', header: 'Default Comments', sortable: true }
    ];
    this.setActionButtonOptions();
    this.populateCoCReasons();
  }

  showUpsertDialog(row?: CocReason) {
    this.currentCoCReason = cloneDeep(row) || new CocReason();
    this._drawerService.show("upsertCoCReasonDrawer", 100);
  }

  onUpsertCoCReasonDrawerClose(updatedCoCReason: CocReason) {
    if (!updatedCoCReason) {
      this._drawerService.hide("upsertCoCReasonDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertCoCReasonDrawer", 100);
    this.updateTable(updatedCoCReason);
  }

  showDeleteDialog(rowData: CocReason) {

    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete the CoC reason "${rowData.title}"?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this.deleteRow(rowData);
    });

  }

  closeOrderDrawer() {
    this._drawerService.hide("cocReasonOrderDrawer", 100);
  }

  private setActionButtonOptions(): void {
    this.actionButtonOptions = [
      {
        type: GridActionButtonType.SearchInput,
        searchInputPlaceholder: 'Search...',
        onSearch: (value: string) => this.cocReasonTable.filterGlobal(value, 'contains'),
      },
    ];
  }

  private populateCoCReasons() {
    this._spinner.show();
    this._cocReasonService
      .getCoCReasons()
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this.cocReasons = res;

          this.cocReasons.forEach(r => {
            let disclosureReason = this.disclosureReasons.find(dr => dr.value == r.disclosureReason);
              r["disclosureReasonDisplayName"] = disclosureReason ? disclosureReason.name : null;
            let reasonCategory = this.reasonCategories.find(dr => dr.value == r.reasonCategory);
              r["reasonCategoryDisplayName"] = reasonCategory ? reasonCategory.name : null;
          })
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't load adverse reasons.",
            'Adverse Reason'
          );
        }
      });
  }

  private updateTable(result: CocReason) {
    const index = this.cocReasons.findIndex(ls => ls.changeOfCircumstanceReasonId === result.changeOfCircumstanceReasonId);
    if (index === -1) {
      this.cocReasons.push(result);
    } else {
      this.cocReasons[index] = result;
    }
    this.cocReasons = [...this.cocReasons];
  }

  private deleteRow(rowData: CocReason) {
    this._cocReasonService.deleteCocReason(rowData.changeOfCircumstanceReasonId)
      .subscribe({
        next: (res) => {
          const index = this.cocReasons.findIndex(
            (ls) => ls.changeOfCircumstanceReasonId === rowData.changeOfCircumstanceReasonId
          );
          this.cocReasons.splice(index, 1);
          this.cocReasons = [...this.cocReasons];
          this._notificationService.showSuccess(
            'CoC reason removed succesfully.',
            'CoC Reason'
          );
        },
        error: (err) =>
          this._notificationService.showError(
            err?.message || "Couldn't delete CoC reason",
            'CoC Reason'
          )
      });
  }

  private setCoCReasonItems() {
    return chain(this.cocReasons)
      .orderBy((ls) => ls.order)
      .map((ls) => ({
        name: ls.disclosureReason,
        value: ls.changeOfCircumstanceReasonId,
      }))
      .value();
  }
}
