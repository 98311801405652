import { FeeTypeEnum } from "./fee-type.enum";

export class EscrowFeeSchedule {
  feeType: FeeTypeEnum;
  cushionMonths: number;
  monthlyEscrowDeposit: number;
  upFrontMonthsRequired: number;
  disbursements: EscrowDisbursement[];
}

export class EscrowDisbursement {
  amount: number;
  disbursementDate: Date;
  disbursedDate: Date;
}
