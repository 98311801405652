import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { SmsConfigurationComponent } from './components/sms-configuration.component';
import { SmsSettingsComponent } from './components/sms-settings/sms-settings.component';
import { SmsTemplatesComponent } from './components/sms-templates/sms-templates.component';
import { SmsConfigRoutingModule } from './sms-configuration-routing.module';

@NgModule({
    imports: [
        SharedModule,
        SmsConfigRoutingModule,
        CorrespondenceModule
    ],
    declarations: [
        SmsTemplatesComponent,
        SmsSettingsComponent,
        SmsConfigurationComponent
    ],
    exports: [
        SmsConfigurationComponent
    ],
    providers: []
})
export class SmsConfigModule {
    constructor() { }
}
