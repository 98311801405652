<div *ngIf="scenario">
  <div class="row">
    <label class="col-sm-6 col-form-label" for="interest"
      >Principal And Interest</label
    >
    <div class="col-sm-6">
      <currency-input
        [disabled]="true"
        [name]="'principalAndInterest'"
        [(ngModel)]="scenario.principalAndInterest"
      >
      </currency-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="monthlyMi">Monthly MI</label>
    <div class="col-sm-6">
      <currency-input
        [disabled]="true"
        [name]="'monthlyMi'"
        [(ngModel)]="scenario.monthlyMi"
        (blur)="onMonthlyMiChanged()"
      >
      </currency-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="monthlyInsurance"
      >Monthly Insurance</label
    >
    <div class="col-sm-6">
      <currency-input
        [name]="'monthlyInsurance'"
        [(ngModel)]="scenario.monthlyInsurance"
        (blur)="onMonthlyInsuranceChanged()"
      >
      </currency-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="monthlyTaxes"
      >Monthly Taxes</label
    >
    <div class="col-sm-6">
      <currency-input
        [name]="'monthlyTaxes'"
        [(ngModel)]="scenario.monthlyTaxes"
        (blur)="onMonthlyTaxesChanged()"
      >
      </currency-input>
    </div>
  </div>
  <div class="row mt-1">
    <label class="col-sm-6 col-form-label" for="totalPayment"
      >Total Payment</label
    >
    <div class="col-sm-6">
      <currency-input
        [disabled]="true"
        [name]="'totalPayment'"
        [(ngModel)]="scenario.totalPayment"
      >
      </currency-input>
    </div>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <button
      type="button"
      class="btn btn-sm btn-outline-secondary me-1"
      (click)="onCancelClicked()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-sm btn-outline-primary"
      (click)="onApplyClicked()"
    >
      Apply
    </button>
  </div>
</div>
