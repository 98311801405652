import { Component, Injector, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ApplicationContext, CustomData, KeyDatesByType, LoanApplication } from 'src/app/models';
import { LoanProduct } from 'src/app/models/config/product.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { UrlaMortgage } from '../urla/models/urla-mortgage.model';

@Component({
  selector: 'app-edit-pricing',
  templateUrl: './edit-pricing.component.html',
  styleUrls: ['./edit-pricing.component.scss']
})
export class EditPricingComponent extends ApplicationContextBoundComponent implements OnInit {

  mortgage: UrlaMortgage;
  application: LoanApplication;

  loanProducts: LoanProduct[] = [];

  appKeyDatesByType: KeyDatesByType;
  customData: CustomData;

  private _loanInfoChangesSubscription: Subscription;
  private _keyDatesSubscription: Subscription;

  constructor(private readonly injector: Injector) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.initialize(context);
      }
    });

    this._keyDatesSubscription = this.applicationContextService.keyDatesChanges.subscribe(() => {
      this.appKeyDatesByType = this.applicationContext.applicationKeyDatesByType;
    });
  }

  ngOnInit(): void {
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this._loanInfoChangesSubscription?.unsubscribe();
    this._keyDatesSubscription?.unsubscribe();
  }

  private initialize = (context: ApplicationContext) => {
    this.mortgage = context.currentMortgage;
    this.application = context.application;

    this.appKeyDatesByType = context.applicationKeyDatesByType;
    this.customData = context.customData;

    if (context.globalConfig.product) {
      this.loanProducts = (_.cloneDeep(context.globalConfig.product)).sort((a, b) => a.sortOrder - b.sortOrder) || [];
    }
  }

}
