import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-portal-invite',
  templateUrl: './confirm-portal-invite.component.html',
  styleUrls: ['./confirm-portal-invite.component.scss']
})
export class ConfirmPortalInviteComponent implements OnInit {

  title: string;

  text: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
