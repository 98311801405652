import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'branch-reassignment-confirmation-dialog',
    templateUrl: 'branch-reassignment-confirmation-dialog.component.html'
})

export class BranchReAssignmentConfirmationDialogComponent implements OnInit {

    @Input()
    title: string;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() { }

    onConfirmUpdateClicked = (response: string) => {
        this.activeModal.close(response);
    }

    onCancelClicked = () => {
        this.activeModal.close('cancel');
    }
}