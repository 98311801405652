<div class="d-flex">

  <textarea *ngIf="record.recordType !== 'Application'" class="form-control" placeholder="Enter notes" name="note" [(ngModel)]="note" rows="5"></textarea>

  <div style="min-width:300px" *ngIf="record.recordType === 'Application'">
    <message-editor-with-mentions #messageEditor [mentionables]="usersThatCanBeMentioned" (enterHit)="onEnterHit()"
       (messageChanged)="onMessageChanged($event)">
    </message-editor-with-mentions>
  </div>

  <button title="Save" type="button" class="btn p-0 border-0 bg-transparent ms-2" (click)="saveNote()">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button title="Cancel" type="button" class="btn p-0 border-0 bg-transparent ms-2" (click)="closePopover()">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
</div>
