import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ContactList } from 'src/app/models';
import { Alignment } from 'src/app/modules/internal-contacts/models/alignment.model';

@Injectable()
export class AlignmentService {
  constructor(private readonly _dataService: DataService) {}

  getAlignmentsByChannelAndCompanyId = ({
    externalCompanyId,
    selectedChannel,
    selectedUser
  }: {
    externalCompanyId?: number;
    selectedChannel?: string;
    selectedUser: string
  }): Observable<Alignment[]> => {
    let url = `api/admin/AllAlignmentModel`;
    let query = '';

    if (externalCompanyId) {
      query +=
        query.length > 0
          ? `&externalCompanyId=${externalCompanyId}`
          : `?externalCompanyId=${externalCompanyId}`;
    }

    if (selectedChannel) {
      query +=
        query.length > 0
          ? `&channel=${selectedChannel}`
          : `?channel=${selectedChannel}`;
    }

    if (selectedUser) {
      query +=
        query.length > 0
          ? `&userCompanyGuid=${selectedUser}`
          : `?userCompanyGuid=${selectedUser}`;
    }

    url += query;

    return this._dataService.get(url);
  };

  insertAlignment = (
    newAlignment: Alignment,
    externalCompanyId?: number
  ): Observable<Alignment> => {
    if (externalCompanyId) {
      newAlignment.externalCompanyId = externalCompanyId;
    }

    const url = `api/admin/InsertAlignmentModel`;
    return this._dataService.post(url, newAlignment);
  };

  updateAlignment = (
    alignment: Alignment,
    companyId: number
  ): Observable<Alignment> => {
    const url = `api/admin/UpdateAlignmentModel?companyId=${companyId}`;
    return this._dataService.post(url, alignment);
  };

  deleteAlignment = (
    alignmentId: number,
    companyId: number
  ): Observable<void> => {
    let url = `api/admin/DeleteAlignmentModel/${alignmentId}?companyId=${companyId}`;
    return this._dataService.delete(url);
  };
}
