import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AudioFileType, UserVoicemail } from 'src/app/models/user/user-voicemail.model';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'upsert-voicemail',
  templateUrl: './upsert-voicemail.component.html',
  styleUrls: ['./upsert-voicemail.component.scss']
})
export class UpsertVoicemailComponent implements OnInit {
  @Input() userCompanyGuid: string;
  @Input() voicemail: UserVoicemail;
  @ViewChild('voicemailForm') voicemailForm: NgForm | undefined;

  @Output() close: EventEmitter<UserVoicemail> = new EventEmitter<UserVoicemail>()

  saveInProgress: boolean;
  uploadedFiles: File[] = [];
  fileRequiredError: boolean;
  isLoading: boolean;

  constructor(
    private readonly _profileService: ProfileService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    if (!this.voicemail.userVoicemailId) {
      return;
    }
    this.getVoicemailFile();
  }

  private getVoicemailFile() {
    this.uploadedFiles = [];
    this.isLoading = true;
    this._profileService.getUserVoicemailFile(this.voicemail.userVoicemailId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (blob) => {
          const fileName = `${this.voicemail.name}.${this.voicemail.fileType.toLowerCase()}`;
          const voicemailFile = new File([blob], fileName, { type: `audio/${this.voicemail.fileType.toLowerCase()}` });
          this.uploadedFiles.push(voicemailFile);
        }
      })
  }

  save() {
    this.voicemailForm.form.markAllAsTouched();
    if (!this.voicemail.file) {
      this.fileRequiredError = true;
    }

    if (!this.voicemailForm.form.valid || this.fileRequiredError) {
      return;
    }

    this.saveInProgress = true;
    this.voicemail.userVoicemailId
      ? this.updateVoicemail()
      : this.insertVoicemail();
  }

  private insertVoicemail() {
    const formData = new FormData();

    formData.append("file", this.voicemail.file);
    formData.append("userId", this.userCompanyGuid);
    formData.append("fileType", this.voicemail.fileType);
    formData.append("name", this.voicemail.name);
    this._profileService.addNewUserVoicemail(formData)
      .pipe(finalize(() => {
        this.saveInProgress = false;
      }))
      .subscribe({
        next: (response) => {
          this._notifyService.showSuccess('New voicemail added successfully', 'Add New Voicemail');
          this.close.emit(response)
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to add new voicemail', 'Add New Voicemail');
        }
      });
  }

  private updateVoicemail() {
    const formData = new FormData();

    formData.append("file", this.voicemail.file);
    formData.append("userVoicemailId", this.voicemail.userVoicemailId.toString());
    formData.append("file", this.voicemail.file);
    formData.append("userId", this.userCompanyGuid);
    formData.append("fileType", this.voicemail.fileType);
    formData.append("name", this.voicemail.name);

    this._profileService.updateUserVoicemail(this.voicemail.userVoicemailId, formData)
      .pipe(finalize(() => {
        this.saveInProgress = false;
      }))
      .subscribe({
        next: (response) => {
          this._notifyService.showSuccess('Voicemail updated successfully', 'Update Voicemail');
          this.close.emit(response)
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to add update voicemail', 'Update Voicemail');
        }
      });
  }

  cancel() {
    this.close.emit();
  }

  onFileUploadChanged() {
    if (this.uploadedFiles.length === 0) {
      this.voicemail.file = null;
      this.voicemail.fileType = null;
      return;
    }
    this.fileRequiredError = false;
    const file = this.uploadedFiles[0];
    const ext = file.name.split(".").pop();
    const lastDot = file.name.lastIndexOf('.');

    const fileName = file.name.substring(0, lastDot);
    this.voicemail.file = file;
    this.voicemail.fileType = AudioFileType[ext.toUpperCase()] || AudioFileType.Unknown;
    if (!this.voicemail.name) {
      this.voicemail.name = fileName;
    }
  }
}
