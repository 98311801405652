import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { LoanType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanTypeService } from '../../../services';

@Component({
  selector: 'upsert-loan-type',
  templateUrl: 'upsert-loan-type-dialog.component.html',
})
export class UpsertLoanTypeDialog implements OnInit {
  @Input() loanType: LoanType;
  @Input() companyId: number;
  @Input() mortgageAppliedForTypes: Array<EnumerationItem>;
  @Output() close: EventEmitter<LoanType> = new EventEmitter<LoanType>();

  @ViewChild('upsertLoanTypeForm') upsertLoanTypeForm: NgForm | undefined;

  saving: boolean;
  editMode: boolean;
  constructor(
    private readonly _loanTypeService: LoanTypeService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.editMode = this.loanType.loanTypeId ? true : false;
    if (!this.loanType.mortgageAppliedForType) {
      this.loanType.mortgageAppliedForType = '';
    }
  }

  onClose() {
    this.close.emit();
  }

  save() {
    this.upsertLoanTypeForm.form.markAllAsTouched();
    if (!this.upsertLoanTypeForm.form.valid) {
      return;
    }

    this.saving = true;
    if (this.editMode) {
      this._loanTypeService.updateLoanType(this.companyId, this.loanType)
        .pipe(finalize(() => this.saving = false))
        .subscribe({
          next: () => {
            this._notificationService.showSuccess(
              'Loan type updated successfully',
              'Loan Type'
            );
            this.close.emit(this.loanType);
          },
          error: (err) => {
            this._notificationService.showError(
              err?.message || "Couldn't update selected loan type",
              'Loan Type'
            );
          }
        });
    } else {
      this._loanTypeService
        .insertLoanType(this.companyId, this.loanType)
        .pipe(finalize(() => this.saving = false))
        .subscribe({
          next: (res) => {
            this._notificationService.showSuccess(
              'Loan type added successfully',
              'Loan Type'
            );
            this.close.emit(res);
          },
          error: (err) => {
            this._notificationService.showError(
              err?.message || "Couldn't insert new loan type",
              'Loan Type'
            );
          }
        });
    }
  }
}
