<div class="accordion-item" *ngIf="docType">
    <h5 class="accordion-header m-0" id="heading{{docType.documentTypeId}}">
        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse'+docType.documentTypeId" aria-expanded="true"
            [attr.aria-controls]="'#collapse'+docType.documentTypeId">
            {{docType.documentTypeName}}
        </button>
    </h5>
    <div id="collapse{{docType.documentTypeId}}" class="accordion-collapse collapse"
        aria-labelledby="heading{{docType.documentTypeId}}" data-bs-parent="#accordion">
        <div class="accordion-body">
            <p-table #dt1 [value]="filteredDocs" [paginator]="true" [rows]="25" *ngIf="filteredDocs"
                [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['description','note', 'linked']"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control"
                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                (ngModelChange)="dt1.first=0"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : ''"
                            style="min-width: 100px;">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-loanDoc let-index>
                    <tr
                        [ngClass]="{'row-warning': loanDoc.loanDocId && loanDoc.losLoanDocId, 'row-light-grey': !loanDoc.loanDocId && loanDoc.losLoanDocId}">
                        <td *ngIf="!isTpoUser">
                            <div class="d-flex justify-content-around">
                                <a href="javascript:void(0);" (click)="editLoanDoc(loanDoc)">
                                    <i class="fas fa-pencil-alt"></i>
                                </a>
                                <span class="text-muted mx-1">|</span>
                                <a href="javascript:void(0);"
                                    *ngIf="(!(activeDeletionIndex === index) && loanDoc.documentTypeId > -1)"
                                    (click)="onDeleteLoanDocClicked(index)">
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                                <a *ngIf="activeDeletionIndex === index" class="table-icon-button mx-2"
                                    href="javascript:void(0);" (click)="onDeleteLoanDocCancelClicked()">
                                    <i class="fa fa-times danger"></i>
                                </a>
                                <a *ngIf="activeDeletionIndex === index" class="table-icon-button"
                                    href="javascript:void(0);" (click)="onDeleteLoanDocConfirmClicked(loanDoc)">
                                    <i class="fa fa-check-circle danger"></i>
                                </a>
                                <span *ngIf="loanDoc.documentTypeId > -1" class="text-muted mx-1">|</span>
                                <span *ngIf="showESignTaskOption">
                                    <a href="javascript:void(0);" title="Send for Signing"
                                        (click)="addNewTask(false, false, true, loanDoc)">
                                        <i class='fa fa-lg fa-file-signature'></i>
                                    </a>
                                </span>
                                <span *ngIf="loanDoc.documentTypeId > -1 && !loanDoc.losLoanDocId"
                                    class="text-muted mx-1">|</span>
                                <a *ngIf="loanDoc.documentTypeId > -1 && !loanDoc.losLoanDocId"
                                    href="javascript:void(0);" title="Sync with LOS"
                                    [ngClass]="{'default-cursor': isLosInfoMissing()}"
                                    (click)="onSyncLoanDocWithLosClicked(loanDoc)">
                                    <i class="fas fa-sync-alt me-1" [ngClass]="{'text-muted': isLosInfoMissing()}"></i>
                                </a>
                                <span *ngIf="isMergeVisible(loanDoc)" class="text-muted mx-1">|</span>
                                <a *ngIf="isMergeVisible(loanDoc)" class="badge text-info fw-bold hoverBadge"
                                    href="javascript:void(0);" (click)="onMergeFilesClicked(loanDoc)"> MERGE
                                </a>

                            </div>
                        </td>
                        <td> {{loanDoc.description}}
                            <div [hidden]="loanDoc.documentTypeId != -2" [ngClass]="{'mt-2': loanDoc.description}">
                                <a class="text-primary" (click)="onAddMappingForVendorClicked(loanDoc)">
                                    Add mapping for '{{loanDoc.title}}'
                                </a>
                            </div>
                        </td>
                        <td> {{loanDoc.note}} </td>
                        <td> {{loanDoc['linked']}} </td>
                        <td>
                            <ng-container *ngFor="let file of loanDoc.docFiles">
                                <doc-files (loanDocUpdated)="onLoanDocUpdated($event)" [file]="file" [loanDoc]="loanDoc"
                                    [icOcrEnabled]="icOcrEnabled" [isTpoUser]="isTpoUser" [editLoanDocs]="editLoanDocs"
                                    [appId]="currentApplication.applicationId" [globalConfig]="globalConfig"
                                    [downloadUserId]="userPermissions.userId">
                                </doc-files>
                            </ng-container>
                        </td>
                        <td> {{loanDoc.expirationDate | date: dateFormat}} </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="isTpoUser ? 5 : 6" class="text-center"> No document found. </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>