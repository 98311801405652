import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CreditBorrower } from '../../models/credit-borrower.model';
import { ImportLiability } from '../../models/import-liability.model';
import { CreditService } from '../../services/credit.service';

@Component({
  selector: 'import-liabilities',
  templateUrl: 'import-liabilities.component.html',
  styleUrls: ['./import-liabilities.component.scss'],
})
export class ImportLiabilitiesComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  borrowers: CreditBorrower[];

  @Input()
  creditInfoId: number;

  @Input()
  applicationId: number;

  @Input()
  isLoanReadOnly: boolean = false;

  selectedBorrowers: CreditBorrower[] = [];

  liabilityTypes: EnumerationItem[] = [];

  @Input()
  liabilitiesHistory: ImportLiability[];

  loadingLiabilities: boolean;

  isLiabilityHistoryError: boolean;

  importingLiabilities: boolean;

  errorMessage: string;

  message: string;

  isSubmitError: boolean;

  isSubmitSuccess: boolean;

  deleteExistingUnmatchedLiabilitiesFromLoan: boolean = false;

  constructor(private readonly injector: Injector,
    private readonly _creditService: CreditService,
    private readonly _enumsService: EnumerationService,
    private readonly _spinner: NgxSpinnerService) {
    super(injector);
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.liabilityTypes = enums[Constants.enumerations.liabilityTypes];
    });
  }

  ngOnInit() {
    this.loadingLiabilities = true;

    const keys = Object.keys(this.liabilitiesHistory);
    keys.forEach(key => {
      this.liabilitiesHistory[key].forEach((item: ImportLiability) => {
        const liability = this.liabilityTypes.find(lt => lt.value == item.typeOfLiability)
        if (liability) {
          item.displayName = liability.name;
          item.isChecked = item.unpaidBalance > 0;
        }
      })
    })
    keys.forEach(key => {
      const borrower = this.borrowers.find(borrower => borrower.last4Ssn == key.substring(key.length - 4))
      if (borrower) {
        this.selectedBorrowers.push(borrower);
      }
    })
    this.isLiabilityHistoryError = false;
    this.loadingLiabilities = false;
  }

  getLiabilitiesByBorrower = (borrower: CreditBorrower) => {
    if (!this.liabilitiesHistory) {
      return [];
    }
    const keys = Object.keys(this.liabilitiesHistory);
    const keyForBorrower = keys.find(k => k.substring(k.length - 4) == borrower.last4Ssn);
    if (keyForBorrower) {
      return this.liabilitiesHistory[keyForBorrower];
    }
    return [];
  }

  onImportLiabilitesClicked = () => {
    this.closeErrors();
    let importData: any = { ...this.liabilitiesHistory };
    const keys = Object.keys(importData);

    let importedLiabilitesCount: number = 0;

    keys.forEach(key => {
      importData[key] = importData[key].filter((item: ImportLiability) => item.isChecked);
      importedLiabilitesCount += importData[key].length;
    })
    this.importingLiabilities = true;

    this._spinner.show();
    this._creditService.importLiabilites(this.applicationId,
      this.deleteExistingUnmatchedLiabilitiesFromLoan,
      {
        liabilitiesBySocialSecurityNumber: importData
      }).subscribe(result => {
        this._spinner.hide();
        this.isSubmitSuccess = true;
        this.importingLiabilities = false;
        this.message = '';

        if (importedLiabilitesCount === 1) {
          this.message += `1 liability successfully imported`;
        } else if (importedLiabilitesCount > 1) {
          this.message += importedLiabilitesCount + ' liabilities successfully imported.';
        }
        this.applicationContextService.updateLoanInfo(this.applicationId).subscribe();
      }, error => {
        this._spinner.hide();
        this.importingLiabilities = false;
        this.showError(error.errorMessage);
      })
  }

  private showError = (message: string) => {
    this.isSubmitError = true;
    this.errorMessage = message;
  }

  closeErrors = () => {
    this.isSubmitSuccess = false;
    this.isSubmitError = false;
  }
}
