<div *ngIf="!isLoading; else loading">

  <div class="alert alert-danger border-0" role="alert" *ngIf="error">
    <strong>ERROR:</strong> Error encountered while loading the lead metrics
  </div>

  <ng-container *ngIf="baseMilestonesReport">
    <lead-milestones [milestonesReport]="baseMilestonesReport"></lead-milestones>
    <lead-milestones [milestonesReport]="filterMilestonesReport"></lead-milestones>
    <lead-milestones [milestonesReport]="totalMilestonesReport"></lead-milestones>
  </ng-container>
  <ng-container *ngIf="milestoneDataToDisplay">
    <div *ngFor="let report of milestoneDataToDisplay">
      <lead-milestones [milestonesReport]="report"></lead-milestones>
    </div>
  </ng-container>

  <div class="card report-card">
    <h3 class="card-header border-bottom-0">
      Metrics for
      {{ leadlistName ? "'" + leadlistName + "' Leads " : 'All Leads ' }}
    </h3>
    <div class="card-body d-flex justify-content-between flex-wrap align-items-stretch">
      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">New Leads Received</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.newLeadsReceived, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Total Contact Attempts</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.contactAttemptsCount, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Total Contacts Made</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.contactSuccessCount, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Unique Leads Contacted</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.uniqueLeadsContactedCount, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Emails Sent</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.emailsSentCount, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-tag align-self-center text-muted icon-sm">
                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                <line x1="7" y1="7" x2="7.01" y2="7"></line>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Sms Sent</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.smsSentCount, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-tag align-self-center text-muted icon-sm">
                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                <line x1="7" y1="7" x2="7.01" y2="7"></line>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Leads Converted</p>
            <h3 class="m-0">
              {{ formatNumber(performanceReport.leadsConvertedCount, 2) }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-chart *ngIf="!error && isChartVisible" id="detailChart" type="bar" [data]="detailReportChart.data"
      [options]="detailReportChart.options"></p-chart>
  </div>
</div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading Reports..."></loading-indicator>
</ng-template>