<div class="row">
  <div class="form-group">
    <label for="name">Template Name</label>
    <input id="name" type="text" name="name" class="form-control" [(ngModel)]="feeTemplate.name" #nameInput="ngModel"
      [ngClass]="{
        'is-invalid': nameInput && nameInput.touched && nameInput.invalid
      }" required />
    <div class="invalid-feedback">This field is required</div>
  </div>
</div>

<div *ngIf="enabledChannels.length > 0" class="row">
  <div class="form-group mb-0">
    <label for="enabledChannels">Channels</label>
    <ng-multiselect-dropdown
      required
      [ngClass]="{'is-invalid' : channels && channels.touched && channels.invalid}"
      name="enabledChannels"
      [placeholder]="'-- Select --'"
      [settings]="multiSelectSettings"
      [data]="enabledChannels"
      [(ngModel)]="selectedRoleChannels"
      #channels="ngModel"
      (ngModelChange)="onRoleChannelChanged()">
    </ng-multiselect-dropdown>
  </div>
  <label *ngIf="channels && channels.touched && channels.invalid" class="generic-error-label">Please select channels.</label>
</div>

<div *ngIf="feeTemplate.feeTemplateId" class="row">
  <div class="form-group">
    <label for="expressionId">Expression</label>
    <div class="mt-2">
      <div *ngIf="!isLoading && feeTemplate; else loading" class="w-100 d-flex justify-content-between">
        <div *ngIf="expressionHTML" [innerHTML]="expressionHTML"></div>
        <i *ngIf="!expressionHTML">No expression definition</i>
        <i class="fa fa-pencil-alt cursor text-primary" (click)="onEditExpressionClicked()" title="Edit"></i>
      </div>
      <ng-template #loading class="w-100">
        <loading-indicator #loading loadingMessage="Loading..."></loading-indicator>
      </ng-template>
    </div>
  </div>
</div>
