<ng-template [ngIf]="loading" [ngIfElse]="content">
  <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
</ng-template>
<ng-template #content>
  <h5 style="text-align: center;" *ngIf="loanStatusType === 'notAssociated'">
    This application is not linked with {{lenderName || 'LDE'}} yet.
  </h5>
  <h5 style="text-align: center;" *ngIf="loanStatusType === 'referenced'">
    This application is referencing LDE loan <b>{{application.ldeRefNumber}}</b>. 
  </h5>
  <h5 style="text-align: center;" *ngIf="loanStatusType === 'linked'">
    This application is linked to <b>{{selectedLdeVendor}}</b> loan
    <b>{{application.ldeRefNumber ? application.ldeRefNumber : "[Ln# not specified]"}}</b> at <b>{{lenderName || application.ldeVendor}}</b>. <a *ngIf="application.externalFileLink" href="{{application.externalFileLink}}">Open In EASE</a>
    <br/>
    <br/>
    Sync direction: <b>{{ldeSyncDirection}}</b>.
  </h5>

  <div style="text-align: center;">
    <button type="button" class="btn btn-link" (click)="showTransactionHistory()" [disabled]="areButtonsDisabled()">
      Show transaction history
    </button>
  </div>

  <br>

  <div class="button-group" style="text-align: center;">
    <button type="button" class="btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()"
      (click)="referenceLdeLoan(false)" *ngIf="loanStatusType === 'notAssociated'">
      Reference LDE Loan
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()" (click)="linkLdeLoan()"
      *ngIf="ldeEnabled === true && loanStatusType === 'notAssociated'">
      Link LDE Loan
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()"
      (click)="referenceLdeLoan(true)" *ngIf="loanStatusType === 'referenced'">
      Edit LDE reference
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1"
      [disabled]="areButtonsDisabled()" (click)="pullFromLde()"
      *ngIf="loanStatusType === 'linked' && (application.ldeSyncDir === 'LosToLoda' || application.ldeSyncDir === 'Both')">
      <span *ngIf="pullingFromLde" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Pull from {{lenderName || 'LDE'}}
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()" (click)="pushToLde()"
      *ngIf="loanStatusType === 'linked' && application.ldeSyncDir === 'Both'">
      <span *ngIf="pushingToLde" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Push to {{lenderName || 'LDE'}}
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1" (click)="syncDocuments()"
      *ngIf="loanStatusType === 'linked' && docSyncEnabled" [disabled]="areButtonsDisabled()">
      Sync Documents
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1" [disabled]="areButtonsDisabled()" (click)="unlinkLde()"
      *ngIf="loanStatusType === 'linked'">
      <span *ngIf="unlinkingFromLde" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Unlink Loan from {{lenderName || 'LDE'}}
    </button>
    <button type="button" class="btn btn-secondary ms-3 mx-1" (click)="changeSyncDirection()"
      *ngIf="loanStatusType === 'linked'" [disabled]="areButtonsDisabled()">
      Change Sync Direction
    </button>
  </div>
</ng-template>