import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { isArray } from 'lodash';
import { TreeDragDropService } from 'primeng/api';
import { combineLatest, finalize } from 'rxjs';
import { LookupType } from 'src/app/models/question.model';
import { CheckListQuestion, CheckListService, QuestionSummary, SubmittedAnswer } from 'src/app/services/check-list.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'check-list-item-eval',
  templateUrl: './check-list-item-eval-dialog.component.html',
  styleUrls: ['./check-list-item-eval-dialog.component.scss'],
  providers: [TreeDragDropService],
})
export class CheckListItemEvalDialogComponent implements OnInit {
  @ViewChild('questionEvalForm') questionEvalForm: NgForm | undefined;

  @Input() checklistQuestions: Array<CheckListQuestion> = [];
  @Input() isEvalTest: boolean;
  @Input() applicationId: number;
  @Input() borrowerNameById: Map<number, string>;

  allQuestions: QuestionSummary[] = [];
  currentQuestion: QuestionSummary;
  questionQueue: Array<Partial<QuestionSummary>> = [];
  questionsLoading: boolean;
  summary: QuestionSummary[] = [];
  showSummary: boolean;
  savingAnswer: boolean;
  totalQuestionsCount = 0;

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _notifyService: NotificationService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.questionsLoading = true;
    this._checkListService.getAllQuestionsWithAnswers()
      .pipe(finalize(() => this.questionsLoading = false))
      .subscribe({
        next: (allQuestions) => {
          this.allQuestions = allQuestions || [];
          this.questionQueue = [];
          this.checklistQuestions.forEach(q => {
            if (isArray(q.validForBorrowerIds)) {
              q.validForBorrowerIds
                .map(borrowerId => this.questionQueue.push(this.convertToQueueQuestion(q, borrowerId)));
            } else {
              this.questionQueue.push(this.convertToQueueQuestion(q));
            }
          });
          this.totalQuestionsCount = this.questionQueue.length;
          this.setCurrentQuestion();
        }, error: err => {
          this._notifyService.showError(err?.message || 'unable to load all questions', "Error!");
        }
      });
  }

  private convertToQueueQuestion(q: CheckListQuestion, borrowerId: number = undefined): Partial<QuestionSummary> {
    return {
      questionId: q.questionId,
      versionId: q.versionId,
      checklistId: q.checklistId,
      borrowerId,
      borrowerDisplayName: borrowerId ? this.borrowerNameById.get(borrowerId) : '',
      submittedAnswers: isArray(q.submittedAnswers)
        ? q.submittedAnswers.filter(a => (!borrowerId && !a.borrowerId) || a.borrowerId == borrowerId)
        : []
    }
  }

  private getQuestionDetails(questionId: number) {
    return this.allQuestions.find(q => q.questionId === questionId);
  }

  private getAnswerDetails(questionAnswerId: number) {
    return this.allQuestions.flatMap(q => q.answers).find(a => a.questionAnswerId === questionAnswerId);
  }

  saveAnswerAndProceedToNextQuestion() {
    if (this.showSummary) {
      this.activeModal.close(true);
      return;
    }
    this.questionEvalForm.form.markAllAsTouched();
    if (!this.questionEvalForm.form.valid) {
      return;
    }
    this.summary.push(this.currentQuestion);
    if (this.isEvalTest) {
      this.nextQuestion();
    } else {
      this.saveAnswer();
    }
  }

  private saveAnswer() {
    this.savingAnswer = true;
    const reqArray = [];
    if (isArray(this.currentQuestion.userAnswer)) {
      const existingAnswerIds = this.currentQuestion.submittedAnswers
        .map(a => a.questionAnswerId);
      this.currentQuestion.userAnswer.filter(answer => !existingAnswerIds.includes(answer))
        .forEach(answer => {
          reqArray.push(
            this._checkListService.insertAnswerToChecklist({
              answerText: null,
              questionAnswerId: answer,
              applicationId: this.applicationId,
              questionId: this.currentQuestion.questionId,
              checklistVersionId: this.currentQuestion.versionId,
              borrowerId: this.currentQuestion.borrowerId
            }, this.currentQuestion.checklistId)
          );
        });
      const deletedAnswers = this.currentQuestion.submittedAnswers
        .filter(answer => !(this.currentQuestion.userAnswer as number[]).includes(answer.questionAnswerId))
      deletedAnswers.forEach(deletedAnswer => {
        reqArray.push(
          this._checkListService.deleteAnswerInChecklist(this.currentQuestion.checklistId, deletedAnswer.checklistAnswerId)
        );
      });
    } else {
      const alreadyHasAnswer = isArray(this.currentQuestion.submittedAnswers) && this.currentQuestion.submittedAnswers.length > 0;
      if (alreadyHasAnswer) {
        const existingAnswer = this.currentQuestion.questionType === 'Value'
          ? this.currentQuestion.submittedAnswers[0].answerText
          : this.currentQuestion.submittedAnswers[0].questionAnswerId;
        const answerChanged = this.currentQuestion.userAnswer != existingAnswer;
        if (answerChanged) {
          const answerToUpdate = this.currentQuestion.submittedAnswers.map(a => ({
            applicationId: a.applicationId,
            checklistAnswerId: a.checklistAnswerId,
            checklistVersionId: a.checklistVersionId,
            questionAnswerId: a.questionAnswerId,
            borrowerId: this.currentQuestion.borrowerId,
            answerText: a.answerText,
            questionId: a.questionId
          }))[0];
          answerToUpdate.answerText = this.currentQuestion.questionType === 'Value' ? this.currentQuestion.userAnswer.toString() : null;
          answerToUpdate.questionAnswerId = this.currentQuestion.questionType !== 'Value' ? Number(this.currentQuestion.userAnswer) : null;
          reqArray.push(
            this._checkListService.updateAnswerInChecklist(
              this.currentQuestion.checklistId,
              answerToUpdate.checklistAnswerId,
              answerToUpdate)
          );
        }
      } else {
        reqArray.push(
          this._checkListService.insertAnswerToChecklist({
            answerText: this.currentQuestion.questionType === 'Value' ? this.currentQuestion.userAnswer : null,
            questionAnswerId: this.currentQuestion.questionType !== 'Value' ? this.currentQuestion.userAnswer : null,
            applicationId: this.applicationId,
            questionId: this.currentQuestion.questionId,
            checklistVersionId: this.currentQuestion.versionId,
            borrowerId: this.currentQuestion.borrowerId
          }, this.currentQuestion.checklistId)
        );
      }
    }
    if (reqArray.length === 0) {
      this.savingAnswer = false;
      this.nextQuestion();
      return;
    }
    const combined = combineLatest(reqArray)
    combined
      .pipe(finalize(() => this.savingAnswer = false))
      .subscribe({
        next: () => {
          this.nextQuestion();
        }, error: err => {
          this._notifyService.showError(err?.message || 'unable to save the answer', "Error!");
        }
      });
  }

  private nextQuestion = () => {
    if (this.currentQuestion.questionType === 'Value') {
      this.setCurrentQuestion();
      return;
    }
    this.searchAndSetNextQuestion(this.currentQuestion.questionId, this.checklistQuestions);
  }

  setCurrentQuestion() {
    if (this.questionQueue.length === 0) {
      if (this.summary.length === 0) {
        this.activeModal.close(true);
        return;
      }
      this.showSummary = true;
      return;
    }

    const question = this.getQuestionDetails(this.questionQueue[0].questionId);
    if (question.questionType === LookupType.TriggerOnly) {
      this.handleTriggerOnlyQuestion(question);
      return;
    }
    this.currentQuestion = {
      questionId: question.questionId,
      questionText: question?.questionText || '',
      questionType: question?.questionType,
      checklistId: this.questionQueue[0].checklistId,
      versionId: this.questionQueue[0].versionId,
      borrowerId: this.questionQueue[0].borrowerId,
      borrowerDisplayName: this.questionQueue[0].borrowerDisplayName,
      submittedAnswers: this.questionQueue[0].submittedAnswers,
      userAnswer: this.getFormattedAnswer(this.questionQueue[0].submittedAnswers, question.questionType),
      answers: _.sortBy(question?.answers.map(a => ({
        questionAnswerId: a.questionAnswerId,
        answerText: this.getAnswerDetails(a.questionAnswerId)?.answerText || ''
      })), ["answerText"], ["asc"])
    }
    this.questionQueue.splice(0, 1);
  }

  private handleTriggerOnlyQuestion(question: QuestionSummary): void {
    if (this.isEvalTest) {
      this.summary.push({
        questionId: question.questionId,
        questionText: question?.questionText || '',
        questionType: question?.questionType,
        checklistId: this.questionQueue[0].checklistId,
        versionId: this.questionQueue[0].versionId,
        borrowerId: this.questionQueue[0].borrowerId,
        borrowerDisplayName: this.questionQueue[0].borrowerDisplayName,
        submittedAnswers: [],
        userAnswer: null,
        answers: null
      });
      this.questionQueue.splice(0, 1);
      this.setCurrentQuestion();
      return;
    }
    if (this.questionQueue[0].submittedAnswers?.length === 0) {
      this._checkListService.insertAnswerToChecklist({
        answerText: null,
        questionAnswerId: null,
        applicationId: this.applicationId,
        questionId: question.questionId,
        checklistVersionId: this.questionQueue[0].versionId,
        borrowerId: this.questionQueue[0].borrowerId
      }, this.questionQueue[0].checklistId).subscribe();
    }
    this.questionQueue.splice(0, 1);
    this.setCurrentQuestion();
  }

  private getFormattedAnswer(submittedAnswers: Array<SubmittedAnswer>, questionType: LookupType) {
    if (!submittedAnswers || submittedAnswers.length === 0) {
      return null;
    }
    switch (questionType) {
      case LookupType.MultiSelect:
        return submittedAnswers.map(a => a.questionAnswerId);
      case LookupType.SelectList:
        return isArray(submittedAnswers) && submittedAnswers.length > 0 ? submittedAnswers[0].questionAnswerId : null;
      case LookupType.Value:
        return isArray(submittedAnswers) && submittedAnswers.length > 0 ? submittedAnswers[0].answerText : null;
    }
  }

  private searchAndSetNextQuestion(questionId: number, questions: Array<CheckListQuestion>) {
    const matchingQuestion = questions.find(q => q.questionId === questionId);
    if (!matchingQuestion) {
      const moreQuestions = questions.flatMap(q => q.answers).flatMap(a => a.questions);
      this.searchAndSetNextQuestion(questionId, moreQuestions);
      return;
    }
    let matchingAnswers;
    if (isArray(this.currentQuestion.userAnswer)) {
      const answerIds: Array<number> = this.currentQuestion.userAnswer;
      matchingAnswers = matchingQuestion.answers.filter(a => answerIds.includes(a.questionAnswerId));
    } else {
      matchingAnswers = matchingQuestion.answers.filter(a => Number(this.currentQuestion.userAnswer) == a.questionAnswerId);
    }
    if (matchingAnswers) {
      const newQuestions = [];
      matchingAnswers
        .flatMap(q => q.questions)
        .filter(q => q.isValid)
        .map(q => {
          if (isArray(q.validForBorrowerIds)) {
            q.validForBorrowerIds
              .map((borrowerId: number) => newQuestions.push(this.convertToQueueQuestion(q, borrowerId)));
          } else {
            newQuestions.push(this.convertToQueueQuestion(q));
          }
        });
      //remove already answered questions.
      const alreadyAnsweredQuestionsIdBorrowerIds = [];
      newQuestions.forEach(nq => {
        const questionAlreadyAsked = this.summary.find(s => s.questionId == nq.questionId && s.borrowerId == nq.borrowerId);
        if (questionAlreadyAsked) {
          alreadyAnsweredQuestionsIdBorrowerIds.push({ questionId: questionAlreadyAsked.questionId, borrowerId: questionAlreadyAsked.borrowerId });
        }
      })
      const newQuestionsFiltered = newQuestions
        .filter(q => !alreadyAnsweredQuestionsIdBorrowerIds.some(x => x.questionId == q.questionId && x.borrowerId == q.borrowerId));

      this.questionQueue = newQuestionsFiltered.concat(this.questionQueue);
      this.totalQuestionsCount += newQuestionsFiltered.length;
    }
    this.setCurrentQuestion();
  }

  getAnswerText(question) {
    if (question.questionType === 'Value') {
      return question.userAnswer;
    }
    if (question.questionType === 'SelectList') {
      const matchingAnswer = this.getAnswerDetails(Number(question.userAnswer));
      return matchingAnswer?.answerText || ''
    }
    if (question.questionType === 'MultiSelect') {
      let answerText = [];
      question.userAnswer.forEach(answerId => {
        const matchingAnswer = this.getAnswerDetails(answerId);
        answerText.push(matchingAnswer?.answerText || '')
      });

      return answerText.join(', ');
    }
  }

  get currentQuestionCount() {
    return this.totalQuestionsCount >= (this.summary.length + 1)
      ? this.summary.length + 1
      : this.summary.length;
  }
}
