import { AutoCompleteItem } from "./auto-complete-item.model";
import { Operator } from "./operator.model";

export class ExpressionConfig {
  operators: Operator[];
  fields: AutoCompleteItem[];

  constructor(operators: Operator[], fields: AutoCompleteItem[]) {
    this.operators = operators;
    this.fields = fields;
  }
}
