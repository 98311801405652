export class TitleOrderTrackingHistory {
  integrationOrderTrackingId: number;
  integrationProvider: ThirdPartyIntegrationProviderEnum;
  integration: ThirdPartyIntegrationServiceEnum;
  eventType: IntegrationOrderTrackingEventTypeEnum;
  orderId: number;
  thirdPartyOrderId: string;
  eventData: string; // TitleOrder
  documentEventData: IntegrationOrderTrackingDocumentEventData;

  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum ThirdPartyIntegrationProviderEnum {
  Connexions = "Connexions",
  DocMagic = "DocMagic",
  Ernst = "Ernst",
  StatesTitle = "StatesTitle",
  MicrosoftGraph = "MicrosoftGraph",
  Ayrshare = "Ayrshare",
  Sisense = "Sisense",
  FormFree = "FormFree",
  DocuSign = "DocuSign",
  MeridianLink = "MeridianLink",
  TurboInsurance = "TurboInsurance",
  FannieMae = "FannieMae",
  FreddieMac = "FreddieMac",
  UnitedWholesaleMortgage = "UnitedWholesaleMortgage"
}

export enum ThirdPartyIntegrationServiceEnum {
  AppraisalVendor = "AppraisalVendor",
  Disclosure = "Disclosure",
  Fees = "Fees",
  Title = "Title",
  CloudPlatform = "CloudPlatform",
  SocialMedia = "SocialMedia",
  Reporting = "Reporting",
  VOA = "VOA",
  VOI = "VOI",
  DocumentSigning = "DocumentSigning",
  LenderDataExchange = "LenderDataExchange",
  CreditVerificationProvider = "CreditVerificationProvider",
  HOI_TurboInsurance = "HOI_TurboInsurance",
  AUS = "AUS",
  AUSOriginator = "AUSOriginator",
  AUSCasefileAccess = "AUSCasefileAccess"
}

export enum IntegrationOrderTrackingEventTypeEnum {
  OrderCreated = "OrderCreated",
  OrderUpdated = "OrderUpdated",
  OrderDeleted = "OrderDeleted",
  OrderCancelled = "OrderCancelled",
  DocumentCreated = "DocumentCreated",
  DocumentUpdated = "DocumentUpdated",
  DocumentDeleted = "DocumentDeleted"
}

export class IntegrationOrderTrackingDocumentEventData {
  docFileGuid: string;
  thirdPartyDocumentId: string;
  fileName: string;
}
