import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Mortgage } from 'src/app/models';
import { HomeOwner } from 'src/app/models/quick-insured/home-owner.model';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuickInsuredService } from 'src/app/services/quick-insured.service';

@Component({
  selector: 'quick-insured-order-dialog',
  templateUrl: './quick-insured-order-dialog.component.html',
  styleUrls: ['./quick-insured-order-dialog.component.scss']
})
export class QuickInsuredOrderDialogComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  loanDocTaskId: number;

  mortgage: Mortgage;

  order: HomeOwner = new HomeOwner();

  errors: string[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _mortgageService: MortgageService,
    private readonly _quickInsuredService: QuickInsuredService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.order.applicationId = this.appId;
    this.order.homeConstructionMaterials = 'Frame';
    this.order.roofingType = 'Composition Shingle';

    this._spinner.show();
    this._mortgageService.getMortgage(this.appId).subscribe((response) => {
      this.mortgage = response;

      if (response.subjectProperty && response.subjectProperty.purposeOfLoan == 'Purchase') {
        let todaysDate = new Date();
        this.order.purchaseMonth = todaysDate.getMonth() + 1;
        this.order.purchaseYear = todaysDate.getFullYear();
      }
      this.order.yearBuilt = response.subjectProperty && response.subjectProperty.yearBuilt;
      this.order.numberOfFamilies = 1;
      this.order.livingArea = 1700;
      this.order.numberOfStories = 2;
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err ? err.message || err : '', 'Error')
    });

  }

  createOrder = () => {
    this._spinner.show();
    this._quickInsuredService.postOrder(this.loanDocTaskId, this.order).subscribe((response) => {
      this._spinner.hide();
      this.activeModal.close(response);
    }, (err) =>{
      this._spinner.hide();
      this.errors = err ? err.message.split('\r\n') || err : 'Unable to create order';
    });
  }

}
