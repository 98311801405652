import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { AuthenticationRequest } from 'src/app/models';
import { AuthData } from 'src/app/models/auth/auth-data.model';
import { AuthenticationResponse, AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { LoginStatus } from 'src/app/models/auth/login-status.enum';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'login-company-picker-dialog',
  templateUrl: 'login-company-picker-dialog.component.html',
  styleUrls: ['login-company-picker-dialog.component.scss']
})
export class LoginCompanyPickerDialogComponent {

  @Input()
  availableCompanies: AuthenticationUserCompany[] = [];

  @ViewChild('switchCompanyForm')
  switchCompanyForm: NgForm;

  isLoggingIn: boolean = false;

  loginInfo: AuthenticationRequest = new AuthenticationRequest();
  userCompanyGuid: string = null;
  password: string;

  companyToGoTo: AuthenticationUserCompany | null = null;

  title: string = "Pick Company to Login to";

  errorMessage: string = null;

  protected needToEnterMfaCode: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _notifyService: NotificationService,
    private _navigationService: NavigationService
  ) { }

  onLoginClicked = () => {
    this.errorMessage = null;
    this.switchCompanyForm.form.markAllAsTouched();
    if (!this.switchCompanyForm.form.valid) {
      return;
    }

    this.isLoggingIn = true;

    this.loginInfo.username = this._localStorageService.authorizationData.userName;
    this.loginInfo.rememberMe = false;

    if (!this.needToEnterMfaCode) {
      this.loginInfo.twoFactorCode = null;
    }

    this._authService.signIn(this.loginInfo).subscribe({
      next: (response) => {
        if (response.loginStatus === LoginStatus.Success) {
          this.persistAuthData(response);
          const companyToGoTo = this.availableCompanies.find((c) => c.companyGuid === this.loginInfo.companyGuid && c.userType === this.loginInfo.scope);
          this._navigationService.navigateToCompanySwitchUrl(companyToGoTo);
        } else if (response.loginStatus === LoginStatus.TwoFactorAuthenticationRequired) {
          this.needToEnterMfaCode = true;
        } else {
          if (response.loginStatus === LoginStatus.Error) {
            this.errorMessage = response.errorMessage;
            this.needToEnterMfaCode = false;
            return;
          }
        }
      },
      error: (error) => {
        this._notifyService.showError('Unable to login at the moment, please try again later.', 'Error');
      }
    }).add(() => {
      this.isLoggingIn = false;
    })
  }

  onAvailableCompanyPickedForLogin() {
    if (this.userCompanyGuid) {
      this.loginInfo.scope = null;
      this.loginInfo.companyGuid = null;
      return;
    }

    const companyThatIsPicked = this.availableCompanies.find(company => company.userCompanyGuid == this.loginInfo.userCompanyGuid);
    if (!companyThatIsPicked) {
      return;
    }

    this.loginInfo.scope = companyThatIsPicked.userType;
    this.loginInfo.companyGuid = companyThatIsPicked.companyGuid;
  }

  private persistAuthData = (authResponse: AuthenticationResponse): void => {

    const jwtPayload = Utils.parseJwt(authResponse.jwtToken);

    const authData: AuthData = {
      companyGuid: this.loginInfo.companyGuid,
      userCompanyGuid: this.loginInfo.userCompanyGuid,
      token: authResponse.jwtToken,
      userName: authResponse.userName,
      refreshToken: authResponse.refreshToken,
      companies: this.availableCompanies,
      rememberMe: this.loginInfo.rememberMe,
      expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
    };

    this._localStorageService.authorizationData = authData;
  }
}
