import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { StoredFileModel } from '../models/stored-file.model';

@Injectable({
  providedIn: 'root'
})
export class StoredFileService {

  constructor(private readonly _dataService: DataService) { }

  storedFileUpload = (file: IStoredFileUploadReq): Observable<StoredFileModel> => {
    const url = `api/stored-file`;
    return this._dataService.post(url, file);
  }

  getStoredFile = (fileGuid: string): Observable<BlobPart> => {
    const url = `api/stored-file/${fileGuid}`;
    return this._dataService.downloadFile(url);
  }
}

interface IStoredFileUploadReq {
  fileName: string,
  mimeType: string,
  base64FileData: string,
}

