<div *ngIf="customData; else loading" class="pt-2">
  <custom-data-info [customData]="customData"></custom-data-info>

  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn btn-soft-primary"
      [disabled]="isSaving"
      (click)="save()"
    >
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Saving
      </span>
    </button>
  </div>
</div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading..."></loading-indicator>
</ng-template>
