import {Injectable, OnDestroy} from '@angular/core';
import {FeeSystemDetails, FeeType, LoanFee, UpdateFeesFromLoanRequest} from '../fees.model';
import {
  concatMap,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  take,
} from 'rxjs';
import {DataService} from '../../../core/services/data.service';
import {ApplicationContextService} from '../../../services/application-context.service';
import {ApplicationContext} from '../../../models';
import {FeeUtils} from '../utils/utils';
import {tap} from 'rxjs/operators';
import findSummaryFeeTypes = FeeUtils.findSummaryFeeTypes;

@Injectable({
  providedIn: 'root',
})
export class FeesV2Service implements OnDestroy {
  private _context$ = new ReplaySubject<ApplicationContext>(1);
  private _contextSubscription?: Subscription;

  private _feeSystemDetailsState$ = new ReplaySubject<FeeSystemDetailsState>(1);
  private _feeSystemDetailsState?: FeeSystemDetailsState;
  private _feeSystemDetailsStateSubscription?: Subscription;

  private _initialized$ = new ReplaySubject<void>(1);
  private _initializedSubscription?: Subscription;

  constructor(
    private readonly _dataService: DataService,
    private readonly _applicationContextService: ApplicationContextService
  ) {
    this.initInitialized();
    this.subscribeToContext();
    this.initFeeSystemDetailsState();
  }

  ngOnDestroy(): void {
    this._initializedSubscription?.unsubscribe();
    this._feeSystemDetailsStateSubscription?.unsubscribe();

    this._contextSubscription?.unsubscribe();
    this._context$.complete();

    this._feeSystemDetailsState$.complete();
    this._initialized$.complete();
  }

  private initInitialized(): void {
    this._initializedSubscription?.unsubscribe();
    this._initializedSubscription = forkJoin([
      this._context$.pipe(take(1)),
      this._feeSystemDetailsState$,
    ])
      .pipe(map(() => undefined))
      .subscribe(this._initialized$);
  }

  private subscribeToContext(): void {
    this._contextSubscription?.unsubscribe();
    this._contextSubscription = this._applicationContextService.context.subscribe(this._context$);
  }

  private initFeeSystemDetailsState(): void {
    this._feeSystemDetailsState = undefined;
    this._feeSystemDetailsStateSubscription?.unsubscribe();
    this._feeSystemDetailsStateSubscription = this.fetchFeeSystemDetails()
      .pipe(
        map(feeSystemDetails => ({
          feeSystemDetails,
          summaryFeeTypeSet: findSummaryFeeTypes(feeSystemDetails),
        })),
        tap(state => (this._feeSystemDetailsState = state))
      )
      .subscribe(this._feeSystemDetailsState$);
  }

  private withContext<T>(project: (context: ApplicationContext) => Observable<T>): Observable<T> {
    return this._context$.pipe(take(1)).pipe(
      concatMap(context => {
        const applicationId = context?.application?.applicationId;
        if (applicationId == null) {
          throw new Error('Application ID is missing');
        }

        return project(context);
      })
    );
  }

  public awaitInitialization(): Promise<void> {
    return firstValueFrom(this._initialized$);
  }

  public updateFeesFromLoan(request: UpdateFeesFromLoanRequest): Observable<LoanFee[]> {
    return this.withContext(context => {
      const applicationId = context.application.applicationId;
      return this._dataService.post(`api/loan/${applicationId}/fees/loanValues/update`, request);
    });
  }

  private fetchFeeSystemDetails(): Observable<FeeSystemDetails> {
    return this._dataService.get('api/fees/system-details');
  }

  public getFeeSystemDetails(): Observable<FeeSystemDetails> {
    if (this._feeSystemDetailsState != null) {
      return of(this._feeSystemDetailsState.feeSystemDetails);
    }

    return this._feeSystemDetailsState$.pipe(map(state => state.feeSystemDetails));
  }

  public isSummaryFee(fee: {feeType: FeeType}): boolean {
    return this.isSummaryFeeType(fee.feeType);
  }

  public isSummaryFeeType(feeType: FeeType): boolean {
    return (
      this._feeSystemDetailsState?.summaryFeeTypeSet.has(feeType) ??
      (() => {
        console.error('Fee system details not initialized');
        return false;
      })()
    );
  }
}

interface FeeSystemDetailsState {
  readonly feeSystemDetails: Readonly<FeeSystemDetails>;
  readonly summaryFeeTypeSet: Readonly<Set<FeeType>>;
}
