import { Component, Injector, Input, OnInit } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UserProfile } from 'src/app/models/user/user-profile.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  profile: Partial<UserProfile>;

  @Input()
  states: {};

  statesList: EnumerationItem[] = [];

  dialerEnabled: boolean = false;

  constructor(private readonly injector: Injector) {
    super(injector);
   }

  ngOnInit(): void {

    if (!this.profile.mailingState) {
      this.profile.mailingState = ""
    }

    this.statesList = Object.entries(this.states).map(state => ({
      name: <string>state[1],
      value: state[0],
      alternateValue: state[0].toUpperCase()
    }));

    this.applicationContextService.context.subscribe(context => {
      this.dialerEnabled = context.userPermissions.dialerEnabled;
    });
  }
}
