import { PricedProductRow } from "./priced-product-row.model";

export class PricingRunResult {
  eligibleProducts: PricedProductRow[] = []
  ineligibleProducts: PricedProductRow[] = [];
  searchId: string;
  pricingVendor: string;

  constructor(eligibleProducts: PricedProductRow[], ineligibleProducts: PricedProductRow[], searchId, pricingVendor: string) {
    this.eligibleProducts = eligibleProducts;
    this.ineligibleProducts = ineligibleProducts;
    this.searchId = searchId;
    this.pricingVendor = pricingVendor;
  }
}
