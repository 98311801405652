<div class="modal-header">
  <h5 class="modal-title"> Attach Loan Documents </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="loanDocsByCategory.length > 0">
    <div class="accordion" id="docsAccordion" class="loan-app">
      <div class="accordion-item" *ngFor="let loanDocs of loanDocsByCategory; let i = index">
        <h5 class="accordion-header m-0" id="heading{{i}}">
          <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
            {{loanDocs.documentType}}
          </button>
        </h5>
        <div id="collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="heading{{i}}">
          <div class="accordion-body">
            <ng-container *ngFor="let document of loanDocs.documents">
              <div class="custom-control custom-switch switch-success"
                *ngFor="let file of document.docFiles; let j = index">
                <input type="checkbox" class="custom-control-input" id="file{{file.docFileId}}"
                  name="file{{file.docFileId}}" [(ngModel)]="file.isSelectedToAttach">
                <label style="padding-left: 4px;" class="form-label" for="file{{file.docFileId}}">
                  <span>{{file.fileName}}</span> - <span>Created on: {{file.dateInserted | date:'MM/dd/yyyy
                    h:mma'}}</span>
                </label>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="loanDocsByCategory.length === 0">
    <em>No loan document available.</em>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button *ngIf="loanDocsByCategory.length > 0" type="button" class="btn btn-primary" (click)="onOkClicked()"
    data-bs-dismiss="modal"> OK
  </button>
</div>