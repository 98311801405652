<div class="fieldset-title pt-4">Email Sending Weekly Schedule</div>
<div class="pt-3">
  <form #weeklyScheduleForm="ngForm" novalidate>
    <div class="d-flex justify-content-end align-items-center mb-2">
      <span class="me-2">
        <div class="row">
          <label class="col col-form-label text-nowrap me-1" for="selectedTimezone">Time Zone</label>
          <div class="col-auto">
            <select [(ngModel)]="selectedTimezoneType" class="form-select" id="selectedTimezone" name="selectedTimezone" (change)="onTypezoneChanged()">
              <option *ngFor="let type of timezoneTypes" [ngValue]="type.value">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
      </span>

      <button type="button" class="btn btn-primary" (click)="setToDefaults()">
        Set to Defaults
      </button>
    </div>

    <div *ngFor="let day of daysOfWeek">
      <correspondence-daily-schedule [delivery]="delivery" [dayOfWeek]="day" [timeZoneType]="selectedTimezoneType"
        [range]="getRangeByDay(day)" (rangeChanged)="onRangesChanged($event)">
      </correspondence-daily-schedule>
    </div>
    <div class="text-end mt-2">
      <button type="button" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
        <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
        <span *ngIf="isSaving">
          <span class="spinner-border spinner-border-sm"></span> Saving
        </span>
      </button>
    </div>
  </form>
</div>