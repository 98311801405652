import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'mcto-provider-editor-dialog',
  templateUrl: 'mcto-provider-editor-dialog.component.html',
  styleUrls: ['./mcto-provider-editor-dialog.component.scss']
})

export class MortgageCTOProviderEditorDialogComponent implements OnInit {

  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  inEditMode: boolean = true;

  protected clientEnvironmentId: string;
  protected clientChannelId: string;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notifyService: NotificationService,
  ) { }

  ngOnInit() {
    const pairForClientEnvironmentId = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientEnvironmentId');
    if (pairForClientEnvironmentId) {
      this.clientEnvironmentId = pairForClientEnvironmentId.value;
    }

    const pairForClientChannelId = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientChannelId');
    if (pairForClientChannelId) {
      this.clientChannelId = pairForClientChannelId.value;
    }
  }

  saveCredential = () => {
    this._spinner.show();

    this.setKeyValuePair('ClientEnvironmentId', this.clientEnvironmentId);
    this.setKeyValuePair('ClientChannelId', this.clientChannelId);

    this.vendor.thirdPartyKeyValuePairs.find
    this._systemLevelService.saveCredential(this.vendor).subscribe({
      next: (result) => {
        this._notifyService.showSuccess("Credentials saved successfully.", "Success");
        this.activeModal.close(result);
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't save credentials.", "Error!");
      }
    }).add(() => this._spinner.hide());
  }

  private setKeyValuePair = (key: string, value: string) => {
    const pair1 = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === key);
    if (pair1) {
      pair1.value = value;
    } else {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue(key, value))
    }
  }
}
