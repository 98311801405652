import { Component, Input, Output, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ConversationService } from '../../services/conversation.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { NotificationService } from 'src/app/services/notification.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChatMedium, Conversation, ParticipantType } from '../../models/conversation.model';
import { CommonService } from 'src/app/services/common.service';
import { ConversationPreferences, WebPreferences } from 'src/app/models/web-preferences.model';

@Component({
  selector: 'conversation-list',
  templateUrl: 'conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
})
export class ConversationListComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  set conversations(conversations: Conversation[]) {
    this._conversations = _.orderBy(conversations, x => x.mostRecentMessageDate, ['desc']);
    this._originalConversations = [...this._conversations];
    this.filterConversations();
    // const selectedOne = this._conversations.find(item => item.isActive);
    // if (selectedOne) {
    //   this.conversationSelected.emit(selectedOne);
    // }
  }

  get conversations(): Conversation[] {
    return this._conversations || [];
  }

  @Input()
  set borrowerId(borrowerId: number | undefined) {
    this._borrowerId = borrowerId;
    if (borrowerId) {
      const borrowerConversation = this._conversations.find(c => c.borrowerId == borrowerId);
      if (borrowerConversation) {
        this.onConversationSelected(borrowerConversation);
      }
    }
  }

  @Input()
  set agentId(agentId: number | undefined) {
    this._agentId = agentId;
    if (agentId) {
      const agentConversation = this._conversations.find(c => c.agentId == agentId);
      if (agentConversation) {
        this.onConversationSelected(agentConversation);
      }
    }
  }

  @Output()
  conversationSelected: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Output()
  impersonatedUserChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  showArchiveChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected searchControl = new FormControl();
  private readonly _searchSubscription: Subscription;
  protected get hasNoSearchResult(): boolean {
    return this.searchControl.value && this.conversations.length < 1;
  }

  isLoadingMore: boolean = false;
  reOrdering: boolean = false;

  chatMediums: EnumerationItem[] = [
    new EnumerationItem("SMS", ChatMedium.Sms),
    new EnumerationItem("Internal Message", ChatMedium.InternalMessage),
    new EnumerationItem("Portal Chat", ChatMedium.BorrowerMessage),
    new EnumerationItem("Group SMS", ChatMedium.GroupMessage)
  ]

  selectedChatMediums: EnumerationItem[] = [];

  allRecordsHaveBeenLoaded: boolean = false;
  usersReportingTo;
  impersonatedUser: string = '';

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    allowSearchFilter: true
  }

  webPreferences: WebPreferences;

  private _originalConversations: Conversation[] = [];
  private _conversations: Conversation[] = [];

  private _currentPageNumber: number = 1;

  protected showArchive: boolean = false;

  private _borrowerId: number | undefined;
  private _agentId: number | undefined;

  constructor(private injector: Injector,
    private readonly _conversationService: ConversationService,
    private readonly _notifsService: NotificationService,
    private readonly _commonService: CommonService) {
    super(injector);

    this._searchSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(this.filterConversations);
  }

  ngOnInit(): void {
    if (this.applicationContext.globalConfig.usersReportingTo.length > 0)
      this.usersReportingTo = this.applicationContext.globalConfig.usersReportingTo;
    if (this._borrowerId || this._agentId) {
      return;
    }
    this._commonService.getWebPreferences().subscribe(response => {
      this.webPreferences = response || new WebPreferences();
      if (this.webPreferences.conversationPreferences?.selectedChatMediumFilters) {
        this.selectedChatMediums = this.chatMediums.filter(c => this.webPreferences.conversationPreferences?.selectedChatMediumFilters.includes(c.value));
      }
      if (this.webPreferences.conversationPreferences?.showArchived) {
        this.showArchive = this.webPreferences.conversationPreferences?.showArchived;
        if (this.showArchive) {
          this.showArchiveChanged.emit(this.showArchive);
        }
      }
      this.filterConversations();
      let selectedConversation = this._conversations.find(convo => convo.isActive);
      if (!selectedConversation) {
        selectedConversation = (this._conversations.length > 0 ? this._conversations[0] : null)
      }
      this.onConversationSelected(selectedConversation);
    });
  }

  ngOnDestroy(): void {
    this._searchSubscription.unsubscribe();
  }

  onConversationSelected = (conversation: Conversation) => {
    if (conversation && !conversation.isActive) {
      this._originalConversations.forEach(c => c.isActive = false);
      conversation.isActive = true;
    }
    const others = this.conversations.filter(c => c !== conversation);
    others.forEach(i => i.isActive = false);
    this.conversationSelected.emit(conversation);
  }

  onImpersonatedUserChanged = (userId: string) => {
    if (!this.impersonatedUser || this.impersonatedUser == '')
      this.impersonatedUserChanged.emit(null);
    else
      this.impersonatedUserChanged.emit(this.impersonatedUser);
  }

  onScroll = () => {
    this.loadMoreConversations();
  }

  filterConversations = () => {
    const searchTerm = this.searchControl.value || '';
    const chatMediums = this.selectedChatMediums.map(cm => cm.value);
    this._conversations = this._originalConversations
      .filter(convo => chatMediums.length === 0 || chatMediums.includes(convo.chatMedium))
      .filter(e => {
        let externalParticipants = e.participants.filter(p => p.participantType === ParticipantType.External);
        return [
          e.externalName,
          e.mostRecentMessagePreview,
          ...externalParticipants.map(p => p.phoneNumber)
        ].filter(Boolean).some((s) => s.toLowerCase().includes(searchTerm.toLowerCase()));
      })
      .filter(c => this.showArchive || !c.isHidden);
  }

  onSelectedChatMediumsChanged = () => {
    if (this.webPreferences) {
      if (!this.webPreferences.conversationPreferences) {
        this.webPreferences.conversationPreferences = new ConversationPreferences();
      }
      this.webPreferences.conversationPreferences.selectedChatMediumFilters = this.selectedChatMediums.map(cm => cm.value);
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { });
    }
    if (!this._borrowerId && !this._agentId) {
      this.filterConversations();
    }
  }

  onShowArchiveChanged = () => {
    if (this.webPreferences) {
      if (!this.webPreferences.conversationPreferences) {
        this.webPreferences.conversationPreferences = new ConversationPreferences();
      }
      this.webPreferences.conversationPreferences.showArchived = this.showArchive;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { });
    }
    this.filterConversations();
    this.showArchiveChanged.emit(this.showArchive);
  }

  private loadMoreConversations = () => {
    if (this.isLoadingMore) return;
    if (this.allRecordsHaveBeenLoaded) return;

    this._currentPageNumber++;
    this.isLoadingMore = true;
    this._conversationService.getConversationsForUser(this.applicationContext.currentlyLoggedInUser.userCompanyGuid,
      this._currentPageNumber, 1000).subscribe({
        next: (moreConversations) => {
          if (moreConversations.count < 1000) {
            // All read mentions are loaded - stop!
            this.allRecordsHaveBeenLoaded = true;
          } else {
            const newConversations = moreConversations.records.map(
              c => c
            );
            this.conversations = this._originalConversations.concat(newConversations);
          }
        },
        error: (err) => {
          this._notifsService.showError(err?.message || "Couldn't load more conversations.", "Error!");
        }
      }).add(() => this.isLoadingMore = false);
  }
}


