import { Component, Input, OnInit } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  selector: 'password-input',
  templateUrl: 'password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [MakeProvider(PasswordInputComponent)],
  viewProviders: [formViewProvider]
})

export class PasswordInputComponent extends AbstractValueAccessor implements OnInit {

  @Input()  readonly: boolean;

  @Input() disabled: boolean;

  @Input() name: string;

  @Input() required: boolean;

  @Input() placeholder: string;

  @Input() validationFieldName: string = "Password";

  showPassword: boolean;

  ngOnInit(): void {
    this.name = (this.name || '') + Math.floor(Math.random() * Date.now());
    if (!this.placeholder) {
      this.placeholder = "";
    }
  }
}
