import { Component, Injector, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { AppraisalService } from 'src/app/services/appraisal.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { AppraisalOrderView } from 'src/app/models/appraisal/appraisal-order-view.model';
import { AppraisalFormType } from 'src/app/models/appraisal/appraisal-form-type.model';
import { LoanDoc } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { assign, cloneDeep } from 'lodash';
import { finalize } from 'rxjs';

@Component({
  selector: 'appraisal-management',
  templateUrl: './appraisal-management.component.html',
  styleUrls: ['./appraisal-management.component.scss']
})
export class AppraisalManagementComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  isPipelineView: boolean = false;

  loadingData: boolean;
  appId: number;
  appraisals: Array<AppraisalOrderView> = [];
  appraisalFormTypes: Array<AppraisalFormType> = [];
  loanDocs: Array<LoanDoc> = [];
  noteDeliveryGroups: Array<EnumerationItem> = [];
  appraisalDocTypes: Array<EnumerationItem> = [];
  conditionCategories: Array<EnumerationItem> = [];
  recentOrder: AppraisalOrderView;
  selectedTab: string;

  constructor(private readonly injector: Injector,
    private readonly _notifyService: NotificationService,
    private readonly _appraisalService: AppraisalService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _enumerationService: EnumerationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.appId = this.isPipelineView ? null : this.applicationContext.application?.applicationId;
    this.initialize();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initialize() {
    this.loadingData = true;
    const apiCalls = [
      this._appraisalService.getAppraisalHistory(this.appId),
      this._appraisalService.getAppraisalFormTypes(),
      this._loanDocsService.getLoanDocs(this.appId, true),
      this._enumerationService.getAppraisalEnumerations()
    ];

    forkJoin(apiCalls)
      .pipe(finalize(() => this.loadingData = false))
      .subscribe({
        next: ([orders, formTypes, loanDocs, appraisalEnums]: [
          Array<AppraisalOrderView>,
          Array<AppraisalFormType>,
          Array<LoanDoc>,
          Array<EnumerationItem>
        ]) => {
          this.appraisals = orders || [];
          this.setMostRecentAppraisal();
          this.appraisalFormTypes = formTypes;
          this.loanDocs = loanDocs;

          this.noteDeliveryGroups = appraisalEnums['NoteDeliveryGroup'];
          this.appraisalDocTypes = appraisalEnums['AppraisalDocumentType'];
          this.conditionCategories = appraisalEnums['AppraisalConditionCategory'];

          this.selectedTab = this.appraisals.length
            ? 'appraisals'
            : 'newAppraisal';
        },
        error: (err) => {
          this._notifyService.showError(err ? err.message || err : "Couldn't load required data", "Error!");
        }
      });
  }

  switchTab(tab: string, appraisal: AppraisalOrderView = null) {
    this.selectedTab = tab;
    if (!appraisal) {
      this.setMostRecentAppraisal();
    } else {
      this.recentOrder = cloneDeep(appraisal);
    }
  }

  private setMostRecentAppraisal() {
    this.recentOrder = this.appraisals.length > 0
      ? cloneDeep(this.appraisals[0])
      : null;
  }

  onNewOrderCreated(newOrder: AppraisalOrderView) {
    this.appraisals.unshift(newOrder);
    this.recentOrder = newOrder;
    this.switchTab('appraisals', newOrder);
  }

  onOrderUpdated(updatedOrder: AppraisalOrderView) {
    let matchingAppraisal = this.appraisals.find(record => record.appraisalOrderId === updatedOrder.appraisalOrderId);
    if (matchingAppraisal) {
      assign(matchingAppraisal, updatedOrder);
    }
    assign(this.recentOrder, updatedOrder);
    this.switchTab('appraisals', updatedOrder)
  }
}
