<div class="modal-header">
    <h6 class="modal-title">Import Conditions From Lender</h6>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <ng-wizard [config]="config" (stepChanged)="stepChanged($event)">
        <ng-wizard-step [title]="'Search for the loan from your LOS'">
            <div *ngIf="!loading" class="los p-2">
                <div class="row">
                    <div class="col-md-3 form-group">
                        <label for="ldeVendor">LOS Provider</label>
                        <select class="form-select" id="ldeVendor" name="ldeVendor" [(ngModel)]="losCredentialId"
                            (change)="changeLosCredential(losCredentialId)">
                            <option [ngValue]="null">--Select One--</option>
                            <ng-container *ngFor="let item of ldeProviders">
                                <option value="{{item.credentialId}}">
                                    {{item.losVendorName}}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-md-2 form-group">
                        <label for="ldeRefNumber">Lender Loan #</label>
                        <input class="form-control" id="ldeRefNumber" name="ldeRefNumber" type="text"
                            [(ngModel)]="ldeRefNumber" [disabled]="!losCredentialId">
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="borrowerFirst">Borrower First</label>
                        <input class="form-control" id="borrowerFirst" name="borrowerFirst" type="text"
                            [(ngModel)]="borrowerFirst"
                            [disabled]="!losCredentialId || selectedVendor?.borrowerDisabled">
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="borrowerLast">Borrower Last</label>
                        <input class="form-control" id="borrowerLast" name="borrowerLast" type="text"
                            [(ngModel)]="borrowerLast"
                            [disabled]="!losCredentialId || selectedVendor?.borrowerDisabled">
                    </div>
                    <div class="col-md-1" style="margin-top: 28px;">
                        <a *ngIf="!isSearching" id="search" name="search" (click)="search()" href="javascript: void(0);"
                            [ngStyle]="{ 'pointer-events': losCredentialId && (ldeRefNumber || borrowerFirst || borrowerLast) ? 'auto' : 'none' }">
                            <i class="fa fa-lg fa-search"></i>
                        </a>
                        <i class="fa fa-lg fa-sync fa-spin" *ngIf="isSearching"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 *ngIf="loanSearchList && !loanSearchList.length">
                            There are no loans matching the search criteria.
                        </h3>
                        <p-table *ngIf="loanSearchList" #dt [value]="loanSearchList" [paginator]="true" [rows]="25"
                            [showCurrentPageReport]="true" [autoLayout]="true" [globalFilterFields]="globalFilterFields"
                            [resizableColumns]="true" responsiveLayout="scroll"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="caption">
                                <div class="d-flex justify-content-end">
                                    <span class="p-input-icon-right ms-2">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" class="form-control h-100"
                                            (ngModelChange)="dt.first=0"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search keyword" />
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [class]="col.cssClass"
                                        *ngFor="let col of loanSearchListColumns | filter: {visible: true}"
                                        [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
                                        <div *ngIf="col.field !== 'order'">
                                            {{ col.header }}
                                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                        </div>

                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-loan>
                                <tr>
                                    <td>
                                        <input type="radio" name="search" [(ngModel)]="selectedLoanId"
                                            value="{{loan.losIdentifier}}"
                                            (click)="setSelectedLoan(loan.loanNumber == undefined ? '' : loan.loanNumber, loan.losIdentifier)" />
                                    </td>
                                    <td>{{loan.loanNumber}}</td>
                                    <td>{{loan.borrowerFirstName}}</td>
                                    <td>{{loan.borrowerLastName}}</td>
                                    <td>{{loan.subjectPropertyAddress}}</td>
                                    <td>{{loan.subjectPropertyCity}}, {{loan.subjectPropertyState}}
                                        {{loan.subjectPropertyZip}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- <div class="table-responsive">
                            <table class="table mb-0 table-centered" *ngIf="loanSearchList">
                                <thead>
                                    <tr>
                                        <td>Select</td>
                                        <td>Loan Number</td>
                                        <td>Borrower First</td>
                                        <td>Borrower Last</td>
                                        <td>Subject Property Address</td>
                                        <td>Subject Property CSZ</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let loan of loanSearchList">
                                        <td>
                                            <input type="radio" name="search" [(ngModel)]="selectedLoanId"
                                                value="{{loan.losIdentifier}}"
                                                (click)="setSelectedLoan(loan.loanNumber == undefined ? '' : loan.loanNumber, loan.losIdentifier)" />
                                        </td>
                                        <td>{{loan.loanNumber}}</td>
                                        <td>{{loan.borrowerFirstName}}</td>
                                        <td>{{loan.borrowerLastName}}</td>
                                        <td>{{loan.subjectPropertyAddress}}</td>
                                        <td>{{loan.subjectPropertyCity}}, {{loan.subjectPropertyState}}
                                            {{loan.subjectPropertyZip}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                    </div>
                </div>
            </div>
            <ng-template [ngIf]="loading">
                <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
            </ng-template>
        </ng-wizard-step>

        <ng-wizard-step [title]="'Search and Import Conditions'">
            <div class="row" *ngIf="!loadingConditions && conditionList.length > 0">
                <div class="col-sm-9 mt-3">
                    <div class="d-flex justify-content-around">
                        <div style="display:inline-block;" class="btn-group">
                          <button class="btn btn-secondary" [ngClass]="{'active': printFilter == 'printInternally'}" (click)="printFilter = 'printInternally'">Internal</button>
                          <button class="btn btn-secondary" [ngClass]="{'active': printFilter == 'printExternally'}" (click)="printFilter = 'printExternally'">External</button>
                          <button class="btn btn-secondary" (click)="printFilter = ''">All</button>
                        </div>

                        <div style="display:inline-block;" class="btn-group">
                            <button class="btn btn-secondary" [ngClass]="{'active': rowFilter == 'Approval'}" (click)="rowFilter = 'Approval'">Approval</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': rowFilter == 'Docs'}" (click)="rowFilter = 'Docs'">Docs</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': rowFilter == 'Funding'}" (click)="rowFilter = 'Funding'">Funding</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': rowFilter == 'Closing'}" (click)="rowFilter = 'Closing'">Closing</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': rowFilter == 'Purchase'}" (click)="rowFilter = 'Purchase'">Purchase</button>
                            <button class="btn btn-secondary" (click)="rowFilter = ''">All</button>
                        </div>
                    </div>
                    <div class="text-center" style="padding: 10px 0;">
                        <div style="display:inline-block;" class="btn-group">
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'FileStarter'}" (click)="ownerRole = 'FileStarter'">File Starter</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'LoanOfficer'}" (click)="ownerRole = 'LoanOfficer'">Loan Officer</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'Intake'}" (click)="ownerRole = 'Intake'">Intake</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'Processor'}" (click)="ownerRole = 'Processor'">Processor</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'Underwritier'}" (click)="ownerRole = 'Underwritier'">Underwriter</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'Closer'}" (click)="ownerRole = 'Closer'">Closer</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'Funder'}" (click)="ownerRole = 'Funder'">Funder</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'PostCloser'}" (click)="ownerRole = 'PostCloser'">Post Closer</button>
                            <button class="btn btn-secondary" [ngClass]="{'active': ownerRole == 'Shipper'}" (click)="ownerRole = 'Shipper'">Shipper</button>
                            <button class="btn btn-secondary" (click)="ownerRole = ''">All</button>
                        </div>
                    </div>
                    <div class="text-center" *ngIf="conditionList.length === 0">
                        <div class="alert alert-heading alert-info"><i class="fa fa-info-circle"></i>
                            There are no unlinked conditions in LOS.
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <table class="table table-striped table-bordered table-hover no-footer dataTable" style="clear:none"
                        role="grid">
                        <thead>
                            <tr role="row">
                                <th class="sorting_disabled" rowspan="1" colspan="1" style="width: auto;"><i
                                        class="fa fa-fw fa-user text-muted hidden-md hidden-sm hidden-xs"></i>Portal
                                    Status</th>
                                <th class="sorting_disabled" rowspan="1" colspan="1" style="width: auto;"><i
                                        class="fa fa-fw fa-user text-muted hidden-md hidden-sm hidden-xs"></i>Name
                                </th>
                                <th class="sorting_disabled" rowspan="1" colspan="1" style="width: auto;">Portal
                                    Merge</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let borr of borrowers; let index = index" role="row"
                                [ngClass]="{'odd' : index % 2 == 1}">
                                <td>
                                    <span *ngIf="!borr.email" class="label label-info"
                                        href="javascript:void(0);">Missing Email Address</span>
                                    <span *ngIf="borr.isRegister" class="label label-success"
                                        href="javascript:void(0);">Live</span>
                                    <span *ngIf="!borr.isRegister && borr.isInvite" class="label label-warning">Not
                                        Confirmed</span>
                                    <span *ngIf="borr.email && !borr.isRegister && !borr.isInvite"
                                        class="label label-danger">Not Invited</span>
                                </td>
                                <td><a href="admin/borrowers/details/{{borr.borrowerId}}"
                                        target="_top">{{borr.lastName}},
                                        {{borr.firstName}}</a></td>
                                <td><span style="margin-top:5px;" class="label label-success">Primary</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" *ngIf="!loadingConditions && conditionList.length > 0">
                <div class="col-sm-12">
                    <table class="table table-striped table-bordered table-hover no-footer dataTable"
                        *ngIf="conditionList" style="margin-top:15px;">
                        <thead>
                            <tr>
                                <td style="width:5%">Select</td>
                                <td style="width:45%">Condition</td>
                                <td>Link To Task</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cond of conditionList; let index = index"
                                [hidden]="!((rowFilter == '' || rowFilter == cond.priorTo) && (ownerRole == '' || ownerRole == cond.ownerRole) && 
                                ( printFilter == '' || cond[printFilter] ))">

                                <td class="text-center">
                                    <input type="checkbox" name="search"
                                        (click)="toggleSelectedCondition(cond.losConditionRefId)" />
                                </td>
                                <td>
                                    <strong>Description: </strong>{{cond.title}} <br />
                                    <strong>Condition Text: </strong>{{cond.description}}<br />
                                    <strong>Status: </strong>{{cond.status}} -
                                    <i>{{cond.statusDate | date:'MM/dd/yyyy @ h:mma'}}</i><br />
                                    <strong>Prior To: </strong>{{cond.priorTo}}<br />
                                    <strong>Owner Role: </strong>{{cond.ownerRoles?.join(', ')}}
                                </td>
                                <td>
                                    <div class="form-horizontal">
                                        <div class="form-group">

                                            <label class="col-sm-3 control-label">Task</label>
                                            <div class="col-sm-9">
                                                <select class="form-select" *ngIf="cond.import"
                                                    [(ngModel)]="cond.loanDocTaskId" name="task_{{index}}"
                                                    (change)="setTaskIdForCondition(cond)">
                                                    <option value="">-- Select One --</option>
                                                    <option value="0"> (+) Create New Task</option>
                                                    <option *ngFor="let task of taskList"
                                                        value="{{task.loanDocTaskId}}">
                                                        {{task.description}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <label class="col-sm-3 control-label">Description</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text"
                                                    [(ngModel)]="cond.selectedTask.description"
                                                    [disabled]="cond.loanDocTaskId > 0">
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <label class="col-sm-3 control-label">Responsible Party</label>
                                            <div class="col-sm-9">
                                                <select class="form-select" [(ngModel)]="cond.selectedTask.userId"
                                                    [disabled]="cond.loanDocTaskId > 0">
                                                    <option value="">-- Select One --</option>
                                                    <optgroup *ngFor="let user of internalContacts"
                                                        label="{{user.roleName}}">
                                                        <option value="{{user.id}}"
                                                            [selected]="cond.selectedTask && cond.selectedTask.userId == user.id">
                                                            {{user.lastName + ", " + user.firstName}}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <label class="col-sm-3 control-label">Notify Party</label>
                                            <div class="col-sm-9">
                                                <select class="form-select"
                                                    [(ngModel)]="cond.selectedTask.notifyPartyId"
                                                    [disabled]="cond.loanDocTaskId > 0">
                                                    <option value="">-- Select One --</option>
                                                    <optgroup *ngFor="let user of internalContacts"
                                                        label="{{user.roleName}}">
                                                        <option value="{{user.id}}"
                                                            [selected]="cond.selectedTask && cond.selectedTask.notifyPartyId == user.id">
                                                            {{user.lastName + ", " + user.firstName}}
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <label class="col-sm-3 control-label">Document Type</label>
                                            <div class="col-sm-9">
                                                <ng-select [disabled]="cond.loanDocTaskId > 0" width="100%"
                                                    bindLabel="documentTypeName" bindValue="documentTypeId"
                                                    placeholder="-- Select One --" [closeOnSelect]="true"
                                                    [(ngModel)]="cond.selectedTask.documentTypeId"
                                                    [items]="documentTypes"></ng-select>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <label class="col-sm-3 control-label">Borrower</label>
                                            <div class="col-sm-9">
                                                <select class="form-select" [(ngModel)]="cond.selectedTask.borrowerId"
                                                    [disabled]="cond.loanDocTaskId > 0">
                                                    <option value="">-- Select One --</option>
                                                    <option *ngFor="let borrower of borrowers"
                                                        value="{{borrower.borrowerId}}"
                                                        [selected]="cond.selectedTask && cond.selectedTask.borrowerId == borrower.borrowerId">
                                                        {{borrower.firstName + " " + borrower.lastName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <div class="row">
                                                <label class="col-sm-4 control-label">Request From Borrower</label>
                                                <div class="col-sm-8">
                                                    <input class="form-check-input" type="checkbox" id="requestBorrower"
                                                        name='requestBorrower'
                                                        [(ngModel)]="cond.mappedLoanDocTask.requestBorrower">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId >= 0">
                                            <label class="col-sm-3 control-label">Notes</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text"
                                                    [(ngModel)]="cond.selectedTask.note"
                                                    [disabled]="cond.loanDocTaskId > 0">
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="cond.import && cond.loanDocTaskId == 0">
                                            <label class="col-sm-3 control-label">Borrower Facing Note</label>
                                            <div class="col-sm-9">
                                                <input class="form-control" type="text"
                                                    [(ngModel)]="cond.selectedTask.borrowerFacingNote">
                                            </div>
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" *ngIf="!loadingConditions && conditionList.length === 0">
                <span class="alert alert-warning">There are no new conditions to import.</span>
            </div>
            <ng-template [ngIf]="loadingConditions">
                <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
            </ng-template>
        </ng-wizard-step>
    </ng-wizard>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
        (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" *ngIf="step == 1" (click)="selectLoan()"
        [disabled]="!selectedLoan || !selectedLoan.ldeIdentifier.length">Select Loan</button>
    <button type="button" class="btn btn-primary" *ngIf="step > 1 && !ldeVendorStr && !submitting"
        (click)="showPreviousStep()">Back</button>
    <button type="button" class="btn btn-primary" *ngIf="step > 1" [disabled]="submitting"
        (click)="importLoanConditions()">Import</button>
</div>