import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'rearrange-order',
  templateUrl: './rearrange-order.component.html',
  styleUrls: ['./rearrange-order.component.scss']
})
export class RearrangeOrderComponent {

  @Input()
  title: string;

  @Input()
  itemList: EnumerationItem[];

  @Input()
  hideFooter: boolean = false;

  @Output()
  save = new EventEmitter<EnumerationItem[]>();

  @Output()
  close = new EventEmitter<undefined>();

  saveInProgress: boolean = false;

  constructor() {
  }

  onSave(): void {
    this.save.emit(this.itemList);
  }

  onClose() {
    this.close.emit();
  }

}
