<p-table
  #table
  [value]='items'
  [columns]='columns'
  [selection]='selectedItems'
  (selectionChange)='onSelectedItemsChange($event)'
  [autoLayout]='true'
  [paginator]='true'
  [loading]='loading'
  [rowsPerPageOptions]='[10, 25, 50]'
  [rows]='25'
  [scrollable]='true'
  [showCurrentPageReport]='true'
  currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
  scrollHeight='flex'
  styleClass='p-datatable-gridlines p-datatable-sm'
>
  <ng-template pTemplate='caption'>
    <div class='d-flex justify-content-between align-items-center'>
      <div class='fw-bold' *ngIf='title'>
        {{title}}
      </div>

      <div class='d-flex gap-2 justify-content-end flex-grow-1'>
        <!-- Search Input -->
        <div class='p-input-icon-right'>
          <i class='pi pi-search'></i>
          <input
            (input)='onSearch($event, table)'
            class='form-control'
            placeholder='Search Keyword'
            type='text'
          >
        </div>

        <ng-container *ngIf='tableActions?.length > 0'>
          <div class='table-actions'>
            <ng-container *ngFor='let action of tableActions'>
                <span class='table-action' (click)='onTableAction(action)'>
                  <ng-container *ngTemplateOutlet='action.template'></ng-container>
                </span>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate='header'>
    <tr>
      <th *ngIf='selectable' class='selection-header'>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>

      <th *ngIf='hasInvoicingActions' class='actions-header'>
        Actions
      </th>

      <th
        *ngFor='let col of columns'
        [pSortableColumn]='col.field'
        [pSortableColumnDisabled]='!col.isSortable'
      >
        {{col.header}}

        <p-sortIcon
          *ngIf='col.isSortable'
          [field]='col.field'
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate='body' let-item>
    <tr>
      <!-- Selection Checkbox -->
      <td *ngIf='selectable'>
        <p-tableCheckbox [value]='item'></p-tableCheckbox>
      </td>

      <!-- Actions -->
      <td *ngIf='hasInvoicingActions'>
        <div class='invoicing-actions'>
          <ng-container *ngFor='let action of invoicingActions; let last = last'>
            <span (click)='onInvoicingAction(action, item)'>
              <ng-container
                *ngTemplateOutlet='action.template'
              >
              </ng-container>
            </span>

            <span
              *ngIf='!last'
              class='vertical-divider'
            ></span>
          </ng-container>
        </div>
      </td>

      <!-- Data -->
      <td *ngFor='let col of columns'>
        {{ col.field === 'amount' ? (item[col.field] | currency) : item[col.field]}}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate='emptymessage'>
    <tr>
      <td [attr.colspan]='totalColumns' class='text-center'>
        No invoices found.
      </td>
    </tr>
  </ng-template>
</p-table>
