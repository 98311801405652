import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { EditorMode } from '../currency-input/currency-input.component';

@Component({
  selector: 'zip-code-input',
  templateUrl: './zip-code-input.component.html',
  styleUrls: ['./zip-code-input.component.scss'],
  providers: [MakeProvider(ZipCodeInputComponent)],
  viewProviders: [formViewProvider]
})
export class ZipCodeInputComponent extends AbstractValueAccessor implements OnInit {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('control')
  input: ElementRef<HTMLInputElement>;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Input()
  name: string;

  @Input()
  use9digits: boolean = true;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  inlinePlaceholder: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  id: string;
  placeHolder: string;
  mask: string;
  pattern: string;

  @Output() selectionChanged = new EventEmitter<ZipCodeLookupResult>();

  suggestions: ZipCodeLookupResult[] = [];
  loadingZipcodes: boolean = false;

  isEditActive: boolean = false;

  private _originalValue: any;

  constructor(private readonly _zipCodeService: ZipCodeService) {
    super();
  }

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());

    this.placeHolder = this.use9digits ? 'XXXXX-XXXX' : 'XXXXX';
    this.mask = this.use9digits ? '00000?-0000' : '00000';
    this.pattern = this.use9digits ? '^[0-9]{5}(?:-[0-9]{4})?$' : '^[0-9]{5}?$';
  }

  writeValue(value: any) {
    if (value) {
      if (!this.use9digits && value.length > 5) {
        value = value.slice(0, 5);
      }
    }
    this._value = value;
    // warning: comment below if only want to emit on user intervention
    this.onChange(value);
    this._originalValue = value;
  }

  searchZipcode = (selectionsDropdown: NgbDropdown) => {
    this.loadingZipcodes = true;
    this.suggestions = [];

    if (!this.value) {
      this.loadingZipcodes = false;
      return;
    }

    this._zipCodeService.lookupZipCode(this.value, true)
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.loadingZipcodes = false;
        selectionsDropdown.open();
      });
  }

  onBlur = (e: any) => {
    this.blur.emit(e);
    if (this.isEditActive) {
      if ((this.editorMode === 'Inline' && e.relatedTarget && e.relatedTarget.id == 'search-zip-code-btn') || this.editorMode === 'Classic') {
        return;
      }
      this.apply();
    }
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
    this.isEditActive = true;
  }

  selectZipcode = (suggestion: ZipCodeLookupResult) => {
    this.value = suggestion.zipcode;

    if (this.editorMode == EditorMode.Inline) {
      this.apply();
    }

    this.sendSelectionChanges(suggestion);
  }

  onCancelClicked = (event: any) => {
    this.isEditActive = false;
    event.preventDefault();
    this.value = this._originalValue;
  }

  onApplyClicked = (event: any) => {
    event.preventDefault();
    this.model.control.markAsTouched();
    this.apply();
  }

  private sendSelectionChanges = (suggestion: ZipCodeLookupResult) => {
    if (suggestion) {
      this.selectionChanged.emit(suggestion);
    } else {
      this.selectionChanged.emit(null);
    }
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;

      this.sendSelectionChanges(this.value);
    }
  }
}
