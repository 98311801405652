<form class="form-horizontal auth-form" #importDuForm="ngForm" novalidate id="importDuForm" name="importDuForm"
    action="#">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Please upload a valid DU 3.2 file to start your submission</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-3 row">
                        <input type="file" id="input-file-fnm" class="dropify" accept=".fnm" files="duFiles"
                            (change)="handleFileInput($event.target.files)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="duFiles && duFiles.length > 0 && enabledChannels.length > 0">
        <div class="card-header">
            <h4 class="card-title">Choose the Channel</h4>
        </div>
        <div class="card-body">

            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Channel</label>
                        <div class="col-sm-10">
                            <select class="form-select" [(ngModel)]="selectedChannel"
                                (change)="handleChannelChange(selectedChannel)"
                                [ngClass]="{'is-invalid' : channel && channel.touched && channel.invalid}"
                                #channel="ngModel" name="selectedChannel" required>
                                <option value="" selected> -- Select One -- </option>
                                <option *ngFor="let channel of enabledChannels" value="{{channel.name}}">
                                    {{channel.name == 'Wholesale' ? 'Brokered' : channel.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="duFiles && duFiles.length > 0 && selectedChannel === 'Wholesale'">
                    <div class="mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Company</label>
                        <div class="col-sm-10">
                            <p-dropdown id="import-du-company" name="import-du-company"
                                [options]="externalCompanies" [(ngModel)]="selectedCompanyId"
                                [showClear]="true" [filter]="true" optionLabel="name" optionValue="externalCompanyId"
                                (onChange)="handleCompanyChange(selectedCompanyId)"
                                [required]="selectedChannel === 'Wholesale'" placeholder="-- Select One --">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="firstRole && duFiles && duFiles.length > 0">
        <div class="card-header">
            <h4 class="card-title">Choose the Contacts</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <role-based-internal-contacts [selectedCompanyId]="selectedCompanyId"
                        [selectedChannel]="selectedChannel" [roleUsers]="availableRoleUsers" [firstRole]="firstRole"
                        [secondaryRoles]="avaliableSecondaryRoles" [isTpoUser]="isTpoUser" [loadingUsers]="false"
                        [primaryRoleUserId]="primaryRoleUserId" [primaryContactIsRequired]="isTpoUser"
                        (primaryRoleContactChanged)="primaryRoleUserId = $event;"
                        (secondaryRoleContactChanged)="tpoAlignmentRoleUsers = $event;">
                    </role-based-internal-contacts>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <button class="btn btn-primary" [disabled]="!duFiles || (duFiles && duFiles.length === 0)"
            (click)="processFile()">Process DU File</button>
    </div>
</form>