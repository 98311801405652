export class HomeOwner {
    applicationId: number;
    homeConstructionMaterials: string;
    roofingType: string;
    purchaseMonth: number;
    purchaseYear: number;
    yearBuilt: number;
    livingArea: number;
    numberOfStories: number;
    numberOfFamilies: number;
}