import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isNullOrUndefined } from 'src/utils/isNullOrUndefined';
import { DataService } from '../core/services/data.service';
import { UserProfile } from '../models';
import { User } from '../models/user/user.model';
import { ConfigurationIssue } from '../modules/admin/configuration-issues/models/configuration-issue.model';

export enum ServiceType {
  CreditHardPull = "CreditHardPull",
  CreditSoftPull = "CreditSoftPull",
  Los = "Los",
  Voa = "Voa",
  Voi = "Voi",
  Du = "Du",
  Lpa = "Lpa",
  Disclosures = "Disclosures",
  Do = "Do",
}

export enum AdminEventType {
  LeadsOnToggle = "LeadsOnToggle",
  CallsOnToggle = "CallsOnToggle",
}

export interface AdminEvent {
  eventType: AdminEventType;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private _eventTracker = new BehaviorSubject<AdminEvent>(null);

  constructor(private readonly _dataService: DataService) { }

  get events(): BehaviorSubject<AdminEvent> {
    return this._eventTracker;
  }

  publish(param: AdminEvent): void {
    this._eventTracker.next(param);
  }

  getTpoConfiguration = () => {
    const url = 'api/Admin/tpo-configuration';
    return this._dataService.get(url);
  };

  postGeneratedDocuments = (data) => {
    const url = 'api/Admin/generated-documents';
    return this._dataService.post(url, data);
  }

  getDialListByCompany = (companyId) => {
    return this._dataService.get(`api/admin/dial-list?companyId=${companyId}`)
  }

  getCompanyUsers = (externalCompanyId?: number): Observable<User[]> => {
    let url = 'api/Admin/AllUserModel';
    if (externalCompanyId) {
      url += `?externalCompanyId=${externalCompanyId}`
    }
    return this._dataService.get(url);
  }

  getIsThirdPartyServiceAllowed = (
    loanPurposeId: number,
    loanStatusId: number,
    isLeadStatus?: boolean,
    serviceType?: ServiceType,
    channel?: string) => {
    let url = "api/Admin/IsThirdPartyServiceAllowed/" + loanPurposeId + "/" + loanStatusId;

    if (!isNullOrUndefined(isLeadStatus) || !isNullOrUndefined(serviceType) || !isNullOrUndefined(channel)) {

      let params = {
        serviceType: isNullOrUndefined(serviceType) ? undefined : serviceType,
        isLeadStatus: isNullOrUndefined(isLeadStatus) ? undefined : isLeadStatus.toString(),
        channel: isNullOrUndefined(channel) ? undefined : channel
      }

      url = url + "?" + Object.keys(params).filter(key => !isNullOrUndefined(params[key])).map(key => key + "=" + params[key]).join("&");
    }


    return this._dataService.get(url);
  }

  getUserDialerConfig = (userId: string): Observable<any> => {
    return this._dataService.get(`api/Admin/users/${userId}/dialer-config`);
  }

  saveUserLeadsOnStatus = (userCompanyGuid: string): Observable<UserProfile> => {
    return this._dataService.post(`api/Admin/users/${userCompanyGuid}/leadson`, {});
  }

  saveUserLeadsOffStatus = (userCompanyGuid: string): Observable<UserProfile> => {
    return this._dataService.post(`api/Admin/users/${userCompanyGuid}/leadsoff`, {});
  }

  saveUserCallsOnStatus = (userCompanyGuid: string): Observable<UserProfile> => {
    return this._dataService.post(`api/Admin/users/${userCompanyGuid}/callson`, {});
  }

  saveUserCallsOffStatus = (userCompanyGuid: string): Observable<UserProfile> => {
    return this._dataService.post(`api/Admin/users/${userCompanyGuid}/callsoff`, {});
  }

  getConfigurationIssues = (): Observable<ConfigurationIssue[]> => {
    return this._dataService.get(`api/Admin/ConfigurationIssues`);
  }

  checkRegister = (data: { userName: string, token: string }) => {
    return this._dataService.post(`api/Admin/CheckRegister?v2=true`, data);
  }

  getTpoRegistrationLink = (): Observable<{ companyGuid: string, companyName: string, tpoUserRegistrationURL: string }> => {
    return this._dataService.get(`api/Admin/company/TPOUserRegistrationURL`)
  }

}
