<p-table #dt1
  [value]="feesToCompare" [paginator]="true" [rows]="25" *ngIf="feesToCompare"
  dataKey="loanFeeId"
  responsiveLayout="scroll"
  autoLayout="true"
  (onRowSelect)="onRowSelectionChanged()"
  (onRowUnselect)="onRowSelectionChanged()"
  [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines" sortField="hudNumber" [sortOrder]="-1">
  <ng-template pTemplate="header">
    <tr>
      <th colspan="4" class="text-center border-between-old-and-new"><h4>Existing Loan Fees</h4></th>
      <th colspan="4" class="text-center"><h4>{{ newFeesSectionHeader }}</h4></th>
    </tr>
    <tr>
      <th>HUD#</th>
      <th>Name</th>
      <th>Amount</th>
      <th class="border-between-old-and-new">Source</th>
      <th>HUD#</th>
      <th>Name</th>
      <th>Amount</th>
      <th>Source</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-fee>
    <tr>
      <td>
        <span class="form-check form-switch form-switch-success" *ngIf="fee.status !== 'OnlyInNewSet'">
          <input *ngIf="!fee.canNotDeselectNew" class="form-check-input" type="checkbox" name="keep_existing_{{fee.loanFeeId}}" id="keep_existing_{{fee.loanFeeId}}"
              [(ngModel)]="fee.willKeepOld" (ngModelChange)="onExistingFeeSelectionChanged(fee)">
          {{ fee.hudNumber }}
        </span>
      </td>
      <td>{{ fee.status === 'OnlyInNewSet' ? '' : fee.oldName}}</td>
      <td>
        <ng-container *ngIf="fee.status !== 'OnlyInNewSet'" [ngSwitch]="fee.oldPayer">
          <span>{{ fee.existingAmountAndPayer }} </span>
        </ng-container>
        <span *ngIf="fee.status === 'OnlyInNewSet'"></span>
      </td>
      <td class="border-between-old-and-new">{{ fee.status === 'OnlyInNewSet' ? '' : fee.oldSource}}</td>
      <td>
        <span class="form-check form-switch form-switch-success" *ngIf="fee.status !== 'OnlyInExistingSet'">
          <input [disabled]="fee.canNotDeselectNew" class="form-check-input" type="checkbox" name="keep_new_{{fee.loanFeeId}}" id="keep_new_{{fee.loanFeeId}}"
              [(ngModel)]="fee.willKeepNew" (ngModelChange)="onNewFeeSelectionChanged(fee)">
          {{ fee.hudNumber }}
        </span>
      </td>
      <td>{{ fee.status === 'OnlyInExistingSet' ? '' : fee.newName}}</td>
      <td>
        <ng-container *ngIf="fee.status !== 'OnlyInExistingSet'" [ngSwitch]="fee.newPayer">
          <span>{{ fee.newAmountAndPayer }} </span>
        </ng-container>
        <span *ngIf="fee.status === 'OnlyInExistingSet'"></span>
      </td>
      <td>{{ fee.status === 'OnlyInExistingSet' ? '' : fee.newSource}}</td>
    </tr>
  </ng-template>
</p-table>
