import { Component, Input, OnInit } from '@angular/core';
import { ProductPricing } from 'src/app/models';

@Component({
  selector: 'program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {

  @Input()
  productPricing: ProductPricing;

  @Input()
  isCompTypeHidden: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
