<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
  <button
    type="button"
    class="btn btn-primary me-2"
    (click)="openUpsertDrawer()"
  >
    <i class="fas fa-plus"></i> Add New
  </button>
</ng-template>

<ng-template #mainContent>
  <h5>Lead Statuses - {{ loanPurposeName }}</h5>

  <p-table
    #leadStatusTable
    [columns]="leadStatusColumns"
    [value]="leadStatusAssociations"
    [paginator]="true"
    [rows]="25"
    [resizableColumns]="true"
    responsiveLayout="scroll"
    scrollable="true"
    [scrollHeight]="scrollHeight"
    name="lead-status-table-v2"
    [rowsPerPageOptions]="[10, 25, 50]"
    [autoLayout]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['loanStatusName', 'disallowedThirdPartyServices']"
    styleClass="p-datatable-gridlines"
    [selectionPageOnly]="true"
    sortMode="single"
  >
    <ng-template pTemplate="caption">
      <grid-top-action-bar
        #gridHeaderActionBar
        [options]="actionButtonOptions"
      ></grid-top-action-bar>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="justify-content-left width-100p">
          <span>Action</span>
        </th>
        <th
          [class]="col.cssClass"
          *ngFor="let col of leadStatusColumns"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="!col.sortable"
        >
          <div *ngIf="col.field !== 'order'">
            {{ col.header }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </div>
          <div *ngIf="col.field === 'order'">
            <button
              *ngIf="allowAddNew"
              class="btn link-primary p-0 m-0 sort-col-header-text"
              (click)="openOrderDrawer(); $event.stopPropagation()"
            >
              Sort
            </button>
            <span *ngIf="!allowAddNew">Sort</span>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td class="width-80p">
          <span class="d-flex align-items-center justify-content-center">
            <i
              class="fa fa-pencil-alt cursor text-primary"
              (click)="openUpsertDrawer(row)"
              title="Edit Agent Type"
            ></i>
            <span class="mx-2"> | </span>
            <i
              class="fa fa-trash-alt cursor text-danger"
              (click)="showDeleteDialog(row)"
              title="Delete Agent Type"
            ></i>
          </span>
        </td>
        <td>{{ row.loanStatusName }}</td>
        <td>{{ getDisallowedServices(row.disallowedThirdPartyServices) }}</td>
        <td>{{ row.order }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5" class="text-center">
          There are no lead status associations.
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight"
>
</admin-page-template>

<drawer
  #leadStatusAssociationOrderDrawer
  [title]="'Lead Status Association Order'"
  [name]="'leadStatusAssociationOrderDrawer'"
  [templateRef]="leadStatusAssociationOrderRef"
  [options]="orderDrawerOptions"
>
</drawer>
<ng-template #leadStatusAssociationOrderRef>
  <rearrange-order
    #leadStatusAssociationOrder
    [itemList]="itemsToOrder"
    (close)="closeOrderDrawer($event)"
    (save)="onOrderDrawerSave($event)"
  ></rearrange-order>
</ng-template>

<drawer
  #leadStatusAssociationUpsertDrawer
  [title]="upsertDrawerTitle"
  [name]="'leadStatusAssociationUpsertDrawer'"
  [templateRef]="leadStatusAssociationUpsertRef"
  [options]="upsertDrawerOptions"
></drawer>
<ng-template #leadStatusAssociationUpsertRef>
  <upsert-lead-status-association
    [leadStatusAssociation]="leadStatusAssociationToUpsert"
    [loanPurposes]="loanPurposes"
    [permissions]="permissions"
    [leadStatuses]="upsertDrawerLeadStatuses"
    (onSave)="onUpsertDrawerSave($event)"
    (onClose)="onUpsertDrawerClose()"
  ></upsert-lead-status-association>
</ng-template>
