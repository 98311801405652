<div class="accordion-item" *ngIf="documentTypeId">
    <h5 class="accordion-header m-0" id="heading{{documentTypeId}}">
        <button class="accordion-button {{allExpanded ? '' : 'collapsed'}} fw-semibold" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse'+documentTypeId" aria-expanded="true"
            [attr.aria-controls]="'#collapse'+documentTypeId">
            {{documentTypeName}}
        </button>
    </h5>
    <div id="collapse{{documentTypeId}}" class="accordion-collapse collapse {{ allExpanded ? 'show' : '' }}"
        aria-labelledby="heading{{documentTypeId}}">
        <div class="accordion-body">
            <p-table #dt1 [value]="filteredFiles" [paginator]="true" [rows]="25" *ngIf="documentTypeId"
                [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
                [globalFilterFields]="['fileName','taskStatus', 'conditionStatus','conditionId','note','dateInserted','exportDate']"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines" [(selection)]="selectedRows">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control"
                                (ngModelChange)="dt1.first=0"
                                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">
                            <p-tableHeaderCheckbox
                                (click)="onSelectAllByDocumentClicked(rowHeaderCheckBox.checked)"
                                #rowHeaderCheckBox>
                            </p-tableHeaderCheckbox>
                        </th>
                        <th [pSortableColumn]="'fileName'">
                            File Name
                            <p-sortIcon [field]="'fileName'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'taskStatus'">
                            Task Status
                            <p-sortIcon [field]="'taskStatus'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'condition'" class="text-center">
                            Condition
                        </th>
                        <th [pSortableColumn]="'conditionStatus'">
                            Condition Status
                            <p-sortIcon [field]="'conditionStatus'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'conditionId'">
                            Condition Id
                            <p-sortIcon [field]="'conditionId'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'note'">
                            Note
                            <p-sortIcon [field]="'note'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'dateInserted'">
                            Created Date
                            <p-sortIcon [field]="'dateInserted'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'exportDate'">
                            Exported Date
                            <p-sortIcon [field]="'exportDate'"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-docFile let-rowIndex="rowIndex">
                    <tr [ngClass]="{'color-export': docFile.exportDate != null}">
                        <td class="text-center">
                            <p-tableCheckbox [value]="docFile" [id]="docFile.loanDocId + '_' + rowIndex"
                                (click)="onToggleCheckboxClicked(docFile, rowCheckBox.checked)" #rowCheckBox>
                            </p-tableCheckbox>
                        </td>
                        <td>
                            <a (click)="onViewDocFileClicked(docFile)" title="Show the Document" class="mx-2">
                                {{docFile.fileName}}
                            </a>
                            <a (click)="onDownloadDocumentClicked(docFile)" title="Download">
                                <i class="fas fa-download"></i>
                            </a>
                        </td>
                        <td> {{docFile.taskStatus}} </td>
                        <td>
                            {{docFile.condition ? 'Yes' : 'No'}}
                        </td>
                        <td> {{docFile.conditionStatus}} </td>
                        <td [ngClass]="{'text-center': !docFile.conditionId}">
                            {{docFile.condition ? docFile.conditionId: '-'}} </td>
                        <td> {{docFile.note}} </td>
                        <td> {{docFile.dateInserted | date: dateFormat}} </td>
                        <td> {{docFile.exportDate | date: dateFormat}} </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9" class="text-center"> No document found. </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
