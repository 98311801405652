<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="form-group">
            <label class="mb-2"><b> Select an Alignment </b></label>
            <select class="form-select" id="alignment" [(ngModel)]="selectedAlignmentId"
                (change)="showAlignmentDetails()">
                <option [ngValue]="null">-- Select One --</option>
                <option *ngFor="let alignment of availableAlignments" [ngValue]="alignment.alignmentId">
                    {{selectedUser?.lastName}}, {{selectedUser?.firstName}} - {{alignment.shortDescription}}
                </option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="selectedAlignment">
        <div class="alignment-selection">
            <div class="alignment-option col-md-12">
                <fieldset>
                    <div class="form-group" *ngFor="let role of roles">
                        <div class="row">
                            <label class="col-md-4 control-label mb-2"> {{role.roleName}} </label>
                            <div class="col-md-8">
                                <select *ngIf="role.order == 1" [(ngModel)]="roleAssignmentToUser[role.roleId]"
                                    class="form-select" id="{{role.roleId}}">
                                    <option value="0">-- Select One --</option>
                                    <ng-container *ngFor="let user of users">
                                        <option *ngIf="user.roleId == role.roleId"
                                            [selected]="user.userCompanyGuid == roleAssignmentToUser[role.roleId]"
                                            value="{{user.userCompanyGuid}}">
                                            {{user.lastName + ", " + user.firstName}} <span *ngIf="isTpo && !user.active">( Inactive )</span>
                                        </option>
                                    </ng-container>
                                </select>
                                <select *ngIf="role.order != 1" [(ngModel)]="roleAssignmentToUser[role.roleId]"
                                    class="form-select" id="{{role.roleId}}">
                                    <option value="0">-- Select One --</option>
                                    <optgroup label="All Users">
                                        <ng-container *ngFor="let user of users">
                                            <option *ngIf="isTpo ? true : user.active"
                                                [selected]="user.userCompanyGuid == roleAssignmentToUser[role.roleId]"
                                                value="{{user.userCompanyGuid}}">
                                                {{user.lastName + ", " + user.firstName}} <span *ngIf="isTpo && !user.active">( Inactive )</span>
                                            </option>
                                        </ng-container>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()"> Cancel </button>
  <button type="button" class="btn btn-primary right" (click)="onOkClicked()"> OK </button>
</div>
