import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
  ActionButtonOption,
  GridActionButtonType,
} from '../../models/action-button-options.model';

@Component({
  selector: 'grid-top-action-bar',
  templateUrl: './grid-top-action-bar.component.html',
  styleUrls: ['./grid-top-action-bar.component.scss'],
})
export class GridTopActionBarComponent implements OnInit {
  @Input() options: Array<ActionButtonOption> = [];
  @ViewChild('search', { static: false }) search: any;
  @ViewChild('select', { static: false }) select: any

  public get gridActionButtonType(): typeof GridActionButtonType {
    return GridActionButtonType;
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.options) {
      return;
    }
    const searchInputOptions = this.options.find(
      (opt) => opt.type === GridActionButtonType.SearchInput
    );
    if (!searchInputOptions) {
      return;
    }
    fromEvent(this.search.nativeElement, 'keydown')
      .pipe(
        debounceTime(550),
        map((x) => x['target']['value'])
      )
      .subscribe((value) => {
        searchInputOptions.onSearch(value);
      });
  }

  isButtonDisabled(option: ActionButtonOption) {
    if (!option) {
      return false;
    }
    return (
      (typeof option.isLoading === 'function' && option.isLoading()) ||
      (typeof option.isDisabled === 'function' && option.isDisabled())
    );
  }

  showLabel(label) {
    if (!label) return;
    return typeof label === 'string' ? label : label();
  }
}
