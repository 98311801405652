<section class="doc-file-header" *ngIf="selectedDocFiles.length > 0">
  <div class="d-flex justify-content-between">
    <span class="text-primary"> {{selectedDocFiles.length}} File(s) Selected </span>
    <div class="doc-file-menu">
      <a *ngIf="showESignTaskOption" class="pe-3" (click)="addNewMultipleTask(false, false, true, selectedDocFiles)"> <span> Send For Signature </span> </a>
      <a *ngIf="selectedDocFiles.length > 1 && isAvailableDocFilesForMerge" class="pe-3" (click)="onMergeFilesClicked()"> <span> Merge </span> </a>
      <a class="pe-3" (click)="onMultipleDownloadClicked()"> <span> Download </span> </a>
      <a (click)="onMultipleDeleteClicked()"> <span> Delete </span> </a>
      <span class="vertical-divider">
        <span></span>
      </span>
      <a class="ps-1" (click)="onCancelClicked()"> <span> Cancel </span> </a>
    </div>
  </div>
</section>
<section class="grid-table">
  <div class="table-header">
    <div class="column leading-col"></div>
    <div class="column name-col"> <span> File Name </span> </div>
    <div class="column linked-col"> <span> Linked </span> </div>
    <div class="column created-by-col"> <span> Created By </span> </div>
    <div class="column created-at-col"> <span>Date Created </span> </div>
    <!-- <div class="column expiration-col"> <span> Expiration Date </span> </div> -->
    <div class="column task-col"> <span> Task </span> </div>
    <div class="column actions-col"></div>
  </div>
</section>
<p-tree [value]="nodes" *ngIf="!isRenderingTableNodes" [loading]="loading">
  <ng-template let-node pTemplate="default">
    <div class="d-flex"
      documentTypeId="{{node.data.documentTypeId}}"
      dndDropzone
      dndAllowExternal="true"
      [dndDisableIf]="node.data.documentTypeId == -1"
      (dndDragover)="onDragover($event)"
      (dndDrop)="onDrop($event)">
      <i class="far fa-folder ms-1 pt-2 text-primary" style="font-size: 18px;"></i>
      <span class="document-type-name ms-2 pt-2"> {{node.data.documentTypeName}} </span>
      <span class="badge rounded-pill counter-badge ms-2" style="margin-top: 0.3rem;">
        {{getfilteredDocsLength(node.data.documentTypeId)}}
      </span>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="loanDoc">
    <div class="drop-area-for-loan-doc"
      loanDocId="{{node.data.loanDocId}}"
      dndDropzone
      dndDragoverClass="drag-over-for-loandoc"
      (dndDrop)="onDrop($event)">
      <!-- optional placeholder element for dropzone, will be removed from DOM on init-->
      <div class="text-center"
       *ngIf="node.data.borrowerId"
        dndPlaceholderRef>
        <span class="badge bg-primary text-uppercase"> {{getBorrowerNameForLoanDocsPlaceHolder(node.data.borrowerId)}} 
        </span>
      </div>
      <div class="drop-area-for-merge p-1" *ngFor="let docFile of node.data.docFiles" 
        [ngClass]="{'nodeChecked': docFile.selectedForAction}"
        dndDropzone
        dndDragoverClass="drag-over-for-merge"
        (dndDrop)="onDrop($event)"
        docFileId="{{docFile.docFileId}}">
        <!-- <div class="place-holder-for-merge text-end"
          dndPlaceholderRef>
          <span class="badge bg-primary"> Merge with Document 
          </span>
        </div> -->
        <span class="drag-area me-2" 
          [ngClass]="{'cursor-move': !(docFile.selectedForAction || node.data.documentTypeId === -1) && !docFile['shouldEdit'] && !(selectedDocFiles.length > 0)}"
          [dndDraggable]="draggable.data"  
          [dndEffectAllowed]="draggable.effectAllowed"
          (dndStart)="onDragStart($event)"
          docFileId="{{docFile.docFileId}}"
          [dndDisableDragIf]="selectedDocFiles.length > 0 || (node.data.documentTypeId === -1) || docFile['shouldEdit']">
            <span *ngIf="node.data.documentTypeId !== -1" 
            [ngClass]="{'display-on-hover': !docFile.selectedForAction, 'd-none': (docFile.selectedForAction || docFile['shouldEdit'] || 
            selectedDocFiles.length > 0), 'hover-padding': docFile['shouldEdit']}">
            <div class="d-flex">
              <i class="fas fa-ellipsis-v" style="color: #828c96;"></i>
              <i class="fas fa-ellipsis-v" style="color: #828c96;"></i>
            </div>
          </span>
        </span>
        
        <input type="checkbox" class="custom-control-input" [id]="docFile.docFileId" [disabled]="node.data.documentTypeId === -1"
          [(ngModel)]="docFile.selectedForAction" (click)="onNodeChecked(docFile)">
        <i class="far fa-file-alt fs-4 text-primary ps-3" [ngClass]="{'pt-2': docFile['shouldEdit']}"></i>
        <a [contextMenu]="basicMenu" [contextMenuSubject]="docFile" *ngIf="!docFile['shouldEdit']" (dblclick)="onViewFileClicked(docFile)" class="px-2 text-ellipsis" 
          [title]="'Double Click for View: ' + docFile.fileName">  {{docFile.fileName}} </a>
        <div class="d-flex px-2" *ngIf="docFile['shouldEdit']">
          <input style="width: 10rem;" type="text" class="form-control" name="docFile{{docFile.docFileId}}"
            id="docFile{{docFile.docFileId}}" [(ngModel)]="docFile['fileNameWithoutExtension']" />
          <div class="ms-2 mt-1">
            <a (click)="onFileRenameConfirmed(docFile)">
              <span> <i class="fa fa-check-circle text-success" style="font-size: small;"></i>
              </span>
            </a>
            <a (click)="onFileRenameCancelled(docFile)">
              <span class="ms-2"> <i class="fa fa-times text-danger" style="font-size: small;"></i> </span>
            </a>
          </div>
        </div>
        <span *ngIf="docFile.linked !== 'Loda'" class="pe-1 h-none" style="font-weight: 600;"> {{docFile.linked}} </span>
        <img *ngIf="docFile.linked === 'Loda'" class="h-none" [src]="'assets/images/loda-mobile-logo.png'" alt="Loda" style="height: 1.3rem;">
        <span class="pe-1 h-none" style="font-weight: 600;"> {{getInsertedBy(docFile.insertedBy)}} </span>
        <span style="font-weight: 600;" class="h-none"> {{docFile.dateInserted | date:'short'}} </span>
        <!-- <span> Expiration Date </span> -->
        <span *ngIf="node.data.task" class="text-ellipsis" style="max-width: 10rem;">
         <a *ngIf="node.data.task && node.data.task.taskType !== 'GatherPayment' && node.data.task.taskType !== 'RequestAppraisal'
         && node.data.task.taskType !== 'QuickClientUpdateReferralLead' && node.data.task.taskType !== 'QuickClientUpdatePreApproved'
         && node.data.task.taskType !== 'QuickClientUpdateInProcess' && !(node.data.taskType == 'EsignDocument' || node.data.taskType == 'LosEsign')" 
         (click)="onTaskStatusClicked(node.data.task)" class="h-none" [disabled]="selectedDocFiles.length > 0"> <span class="badge rounded-pill badge-outline-primary"> {{node.data.task.description}} </span> </a> 
        </span>
        <span *ngIf="!node.data.task"> </span>
        <span class="h-none">
          <a [disabled]="selectedDocFiles.length > 0" class="dropdown-toggle ms-2"
            [ngClass]="{'cursor-not-allowed': selectedDocFiles.length > 0}" data-bs-toggle="dropdown"
            aria-haspopup="false" aria-expanded="false" href="#" role="button">
            <i class="fas fa-ellipsis-v" style="color: #828c96;"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" (click)="onViewFileClicked(docFile)">View Document</a>
            <a class="dropdown-item" (click)="onModifyPagesClicked(docFile)">Modify Pages</a>
            <a class="dropdown-item" (click)="onFileRenameClicked(docFile)">Rename</a>
            <a class="dropdown-item" (click)="onDownloadDocumentClicked(docFile)">Download</a>
            <a *ngIf="showESignTaskOption" class="dropdown-item" (click)="addNewSingleTask(false, false, true, docFile, node.data)">Send For Signature</a>
            <a *ngIf="node.data.documentTypeId !== -1" class="dropdown-item" (click)="onSingleDeleteDocFileClicked(docFile)">Delete</a>
          </div>
        </span>
        <section>
          <span class="h-block"></span>
          <span class="h-block"></span>
          <span class="h-block"></span>
          <span class="h-block"></span>
          <span class="h-block">
            <span class="badge bg-primary"> Merge with Document 
            </span>
          </span> 
        </section>
      </div>
    </div>
  </ng-template>
</p-tree>

<context-menu>
  <ng-template contextMenuItem let-item (execute)="onFileRenameClicked($event.item)">
    Rename
  </ng-template>
</context-menu>