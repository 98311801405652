<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <i [hidden]="isValidForm" class="fas fa-exclamation-triangle alert-icon text-danger align-self-center me-1"></i>
      F. PREPAID 900. Items Required by Lender to Be Paid in Advance<span style="float: right;">Total : {{feesTotal | currency}}</span>
    </h4>
  </div>
  <div class="card-body">
    <a class="btn btn-sm btn-soft-primary me-2" (click)="openAddFeeModal()" role="button">
      <i class="fas fa-plus me-2"></i>Add Fee
    </a>
    <br><br>
    <form #prepaidForm="ngForm" novalidate id="prepaidForm" name="prepaidForm">

        <div *ngFor="let fee of oddDaysInterestFees">
          <odd-days-interest [fee]="fee" (onFeeValueUpdated)="updateFeeValue($event)"></odd-days-interest>
        </div>

        <generic-fees
          [fees]="prepaidFeesOtherThanOddDaysInterest"
          (onFeeUpdated)="updateFeeValue($event)"
          [deleteAllowed]="false"
          [allowLockStatusChange]="false"
          (openFeeEditor)="onFeeEditorOpened($event)">
        </generic-fees>

    </form>
  </div>
</div>
