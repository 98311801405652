<div class="modal-header">
    <h5 class="modal-title">Create Escalation</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <form #escalationForm="ngForm" novalidate id="escalationForm" name="escalationForm">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Description</label>
                    <div class="col-sm-10">
                        <input class="form-control" placeholder="Description" id="escalationDescription"
                            name="escDescription" type="text" [(ngModel)]="description" required
                            [ngClass]="{'is-invalid' : escalationDescription && escalationDescription.touched && escalationDescription.invalid}"
                            #escalationDescription="ngModel">
                        <div class="invalid-feedback">Please enter a description</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3 row">
                    <label for="notes" class="col-sm-2 form-label mb-lg-0 text-end">Notes</label>
                    <div class="col-sm-10">
                        <textarea class="form-control" placeholder="Add a note" id="notes" name="note" [(ngModel)]="notes"
                            type="text" rows="4"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3 row">
                    <label for="files" class="col-sm-2 form-label mb-lg-0 text-end">Upload/View</label>
                    <div class="col-sm-10">
                        <file-upload [(ngModel)]="uploadedFiles" multiple="true" name="files"></file-upload>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
  <button type="button" class="btn btn-primary right" (click)="onOkClicked()">Save</button>
</div>
