import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { filter, fromEvent, Subscription, take } from 'rxjs';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'simple-merge-field-context-menu',
  templateUrl: './simple-merge-field-context-menu.component.html',
  styleUrls: ['./simple-merge-field-context-menu.component.scss'],
})

export class SimpleMergeFieldContextMenuComponent implements OnInit {
  @ViewChild('search') search: ElementRef;
  @ViewChild('contextMenu') mergeFieldsMenu: TemplateRef<any>;

  @Input()
  menuItems: Array<EnumerationItem>;

  @Output()
  mergeFieldSelected: EventEmitter<string> = new EventEmitter<string>();

  searchMergeFieldText: string;

  private _overlayRef: OverlayRef | null;
  private _subscription: Subscription;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

  show = (event: MouseEvent) => {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this._overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this._overlayRef.attach(
      new TemplatePortal(this.mergeFieldsMenu, this.viewContainerRef)
    );

    this._subscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this._overlayRef &&
            !this._overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
    setTimeout(() => {
      this.search.nativeElement.focus();
    }, 200);
  }

  close() {
    this.searchMergeFieldText = '';
    this._subscription && this._subscription.unsubscribe();
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  mergeFieldClicked = (selectedMergeField: string) => {
    this.close();
    this.mergeFieldSelected.emit('%' + selectedMergeField + '%');
  }
}
