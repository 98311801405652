import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BasicPropertyDetail } from 'src/app/modules/pricing/models/pricing/basic-property-detail.model';
import { PricingConfiguration } from 'src/app/modules/pricing/models/pricing/pricing-configuration.model';
import { PricingFieldAccess, PricingFieldSpec } from 'src/app/modules/pricing/models/pricing/pricing-field-spec.model';
import { PricingConfigurationService } from 'src/app/modules/pricing/services/pricing-configuration.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reprice-fields-dialog',
  templateUrl: './reprice-fields-dialog.component.html',
  styleUrls: ['./reprice-fields-dialog.component.scss']
})
export class RepriceFieldsDialogComponent implements OnInit {

  credentialId: number;
  pricingConfig: PricingConfiguration;
  lodaFields: BasicPropertyDetail[] = [];
  lodaFieldsEnumList: EnumerationItem[] = [];
  fieldAccessOptions: EnumerationItem[] = [
    new EnumerationItem("Read/Write", PricingFieldAccess.ReadWrite),
    new EnumerationItem("Readonly", PricingFieldAccess.ReadOnly),
    new EnumerationItem("Hidden", PricingFieldAccess.Hidden),
  ];

  vendor: PricingVendor = null;

  activeDeletionIndex: number = -1;

  fieldOptionsMap: Map<string, EnumerationItem[]> = new Map();
  selectedOptionsMap: Map<string, string[]> = new Map();
  fieldTypeMap: Map<string, string> = new Map();

  definedTypes: string[] = ["System.String", "System.Int32", "System.Decimal", "System.Boolean", "System.DateTimeOffset"];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _enumerationService: EnumerationService,
    private readonly pricingConfigService: PricingConfigurationService
  ) {
  }

  ngOnInit(): void {
    this.getLodaFields(() => {
      this.getConfigInfo();
    });
  }

  saveConfig = () => {
    this.pricingConfig.fieldSpecs.forEach(s => {
      delete s["id"];
    });

    this.pricingConfig.fieldSpecs = this.pricingConfig.fieldSpecs.filter(s => !!s.fieldName);

    this._spinner.show();
    this.pricingConfigService.updateConfig(this.credentialId, this.pricingConfig).subscribe({
      next: (config) => {
        this._notificationService.showSuccess("Pricing Config saved successfully", "Pricing Config!");
        this.activeModal.close(config);
      },
      error: (err) => {
        this._notificationService.showError(err.message || err, "Pricing Config Error!");
      }
    })
      .add(() => {
        this._spinner.hide();
      })
  }

  deleteConfig = () => {
    const self = this;

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this config?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        self._spinner.show();
        self.pricingConfigService.deleteConfig(self.pricingConfig.pricingConfigurationId).subscribe({
          next: () => {
            self._notificationService.showSuccess("Pricing Config deleted successfully", "Pricing Config!");
            self.activeModal.close();
          },
          error: (err) => {
            self._notificationService.showError(err.message || err, "Pricing Config Error!");
          }
        })
          .add(() => {
            self._spinner.hide();
          })
      }
    })

  }

  addFieldSpec = () => {
    const newSpec = new PricingFieldSpec();
    newSpec.fieldAccess = PricingFieldAccess.ReadWrite;
    newSpec.repriceFieldAccess = PricingFieldAccess.ReadWrite;
    newSpec["id"] = Utils.getUniqueId();

    if (this.pricingConfig.fieldSpecs.length) {
      this.pricingConfig.fieldSpecs.push(newSpec);
    }
    else {
      this.pricingConfig.fieldSpecs = [newSpec];
    }
  }

  onFieldNameChanged = (spec: PricingFieldSpec) => {

    if (!spec.fieldName) { // clearing
      spec.defaultValue = null;
      spec.fieldAccess = PricingFieldAccess.ReadWrite;
      spec.repriceFieldAccess = PricingFieldAccess.ReadWrite;
      spec.hiddenEnumOptions = [];
      return;
    }

    let field = this.lodaFields.find(f => f.name == spec.fieldName);

    if (field && field.isEnum) {

      spec.defaultValue = spec.defaultValue ?? null;

      if (this.fieldOptionsMap.has(spec.fieldName)) {
        return;
      }

      this._spinner.show();
      this._enumerationService.getEnumerationsByType(field.typeName).subscribe({
        next: (enums) => {
          this.fieldOptionsMap.set(field.name, enums.filter(this.filterBySupportedVendor));

          if (field.isList) {
            this.selectedOptionsMap.set(field.name, spec.defaultValue ? spec.defaultValue.split(",") : []);
          }

        },
        error: (err) => {
          this._notificationService.showError(err.message || err, "Enumerations Error!");
        }
      })
        .add(() => {
          this._spinner.hide();
        });

    }
    else if (spec.hiddenEnumOptions.length) {
      spec.hiddenEnumOptions = [];
    }

  }

  multiSelectChanged = (options: string[], spec: PricingFieldSpec) => {
    this.selectedOptionsMap.set(spec.fieldName, options);
    spec.defaultValue = options.join(",");
  }

  onDeleteConfirmClicked = (spec: PricingFieldSpec) => {
    this.activeDeletionIndex = -1;

    const index = this.pricingConfig.fieldSpecs.indexOf(spec);
    if (index >= 0) {
      this.pricingConfig.fieldSpecs.splice(index, 1);
    }
  }

  onDeleteButtonClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onDeleteCancelled = () => {
    this.activeDeletionIndex = -1;
  }

  private filterBySupportedVendor = (item: EnumerationItem) => {
    if (!item.supportedPricingVendors) {
      return true;
    }
    const supportedVendors = item.supportedPricingVendors.split(',').map(v => v.trim());
    return supportedVendors.includes('All') || supportedVendors.includes(this.vendor);
  }

  private getConfigInfo = () => {
    this._spinner.show();
    this.pricingConfigService.getConfig(this.credentialId).subscribe({
      next: (config) => {
        if (config) {
          this.pricingConfig = config || new PricingConfiguration(this.credentialId);
          this.pricingConfig.fieldSpecs.forEach((s) => {
            s["id"] = Utils.getUniqueId();
            this.onFieldNameChanged(s);
          })
        }
        else {
          this.pricingConfig = new PricingConfiguration(this.credentialId);
        }
      },
      error: (err) => {
        this._notificationService.showError(err.message || err, "Pricing Config Error!");
      }
    })
      .add(() => {
        this._spinner.hide();
      })
  }

  private getLodaFields = (cb: Function) => {
    this._spinner.show();
    this.pricingConfigService.getFields().subscribe({
      next: (fields) => {
        this.lodaFields = fields || [];
        this.lodaFieldsEnumList = this.lodaFields.map(f => f.name)
          .sort((a, b) => a.localeCompare(b))
          .map(f => new EnumerationItem(f, f));

        this.lodaFields.forEach(f => {
          let isDefinedType = this.definedTypes.some(type => f.typeName.includes(type));
          this.fieldTypeMap.set(f.name, f.isEnum ? (f.isList ? 'List' : 'Enum') : (isDefinedType ? f.typeName : null));
        });

        cb();
      },
      error: (err) => {
        this._notificationService.showError(err.message || err, "Pricing Config Error!");
      }
    })
      .add(() => {
        this._spinner.hide();
      })
  }

}
