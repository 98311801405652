import { Component, Injector } from '@angular/core';
import { MortgageService } from 'src/app/services/mortgage.service';
import { MortgageModelFieldsConfig } from '../services/mortgage-field-validation-utils';

@Component({
  template: ''
})
export abstract class QuickApplyFieldsConfigBoundComponent {

  protected quickApplyFieldsConfig: MortgageModelFieldsConfig;

  protected _mortgageService: MortgageService;

  constructor(private readonly _injector: Injector) {
    this._mortgageService = this._injector.get(MortgageService);
    this.quickApplyFieldsConfig = this._mortgageService.quickApplyFieldsConfig;
  }
}
