<div class="row">
    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label for="firstName{{borrower.borrowerId}}">First Name</label>
      <input id="firstName{{borrower.borrowerId}}" type="text" name="firstName{{borrower.borrowerId}}" class="form-control" placeholder="First name"
        [(ngModel)]="borrower.firstName" #firstNameInput="ngModel" />
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-2">
      <label for="middleName{{borrower.borrowerId}}">Middle Name</label>
      <input id="middleName{{borrower.borrowerId}}" name="middleName{{borrower.borrowerId}}" type="text" class="form-control" placeholder="Middle name"
        [(ngModel)]="borrower.middleName" />
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label for="lastName{{borrower.borrowerId}}">Last Name</label>
      <input id="lastName{{borrower.borrowerId}}" name="lastName{{borrower.borrowerId}}" type="text" class="form-control" placeholder="Last name"
        [(ngModel)]="borrower.lastName" #lastNameInput="ngModel" />
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-1">
      <label for="suffix{{borrower.borrowerId}}"> Suffix </label>
      <select id="suffix{{borrower.borrowerId}}" name="suffix{{borrower.borrowerId}}" class="form-select"
        [(ngModel)]="borrower.nameSuffix" #suffix="ngModel">
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
          {{ opt.name }}
        </option>
      </select>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label for="signingRole{{borrower.borrowerId}}"> Signing Role </label>
      <select id="signingRole{{borrower.borrowerId}}" name="signingRole{{borrower.borrowerId}}" class="form-select"
        [(ngModel)]="borrower.signingRole" #signingRole="ngModel" (change)="onSigningRoleChanged()">
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let opt of signingRoleOptions" [ngValue]="opt.value">
          {{ opt.name }}
        </option>
      </select>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label for="mobilePhone{{borrower.borrowerId}}">Mobile Number &nbsp;&nbsp;
        <a [hidden]="!borrower.mobilePhone" *ngIf="!dialerEnabled"
          [href]="'tel:' + borrower.mobilePhone | safeUrl"><i class="fa fa-fw fa-phone text-muted"></i>
        </a>
        <a [hidden]="!borrower.mobilePhone" *ngIf="dialerEnabled"
          (click)="dial(borrower.mobilePhone, 'mobile', false)"><i class="fa fa-fw fa-phone text-muted"></i>
        </a>
      </label>
      <input #borrowerMobilePhone="ngModel" class="form-control" id="mobilePhone{{borrower.borrowerId}}" name="mobilePhone{{borrower.borrowerId}}" type="text" [(ngModel)]="mobilePhone"
        [ngClass]="{
          'is-invalid':
          borrowerMobilePhone &&
          borrowerMobilePhone.touched &&
          borrowerMobilePhone.invalid
        }"
        (ngModelChange)="onPhoneChanged($event, 'mobilePhone')" mask="(000) 000-0000" [showMaskTyped]="true" />
        <div for="mobilePhone{{borrower.borrowerId}}" class="invalid-feedback">
          Please enter a valid phone number
        </div>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label for="phone{{borrower.borrowerId}}">Home Number &nbsp;&nbsp;
        <a [hidden]="!borrower.homePhone" *ngIf="!dialerEnabled" [href]="'tel:' + borrower.homePhone | safeUrl">
          <i class="fa fa-fw fa-phone text-muted"></i>
        </a>
        <a [hidden]="!borrower.homePhone" *ngIf="dialerEnabled" (click)="dial(borrower.homePhone, 'home', false)">
          <i class="fa fa-fw fa-phone text-muted"></i>
        </a>
        <a *ngIf="borrower.mobilePhone" title="Copy from mobile phone" (click)="copyFromMobile()">
          <i class="far fa-fw fa-copy text-muted"></i>
        </a>
      </label>
      <input class="form-control" #borrowerHomePhone="ngModel" id="homePhone{{borrower.borrowerId}}" name="homePhone{{borrower.borrowerId}}" type="text" [(ngModel)]="homePhone"
        [ngClass]="{
          'is-invalid':
          borrowerHomePhone &&
          borrowerHomePhone.touched &&
          borrowerHomePhone.invalid
        }"
        (ngModelChange)="onPhoneChanged($event, 'homePhone')" mask="(000) 000-0000" [showMaskTyped]="true" />
        <div for="homePhone{{borrower.borrowerId}}" class="invalid-feedback">
          Please enter a valid phone number
        </div>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label for="workPhone{{borrower.borrowerId}}">Work Number &nbsp;&nbsp;
        <a [hidden]="!borrower.workPhone" *ngIf="!dialerEnabled"
          [href]="'tel:' + borrower.workPhone | safeUrl"><i class="fa fa-fw fa-phone text-muted"></i>
        </a>
        <a [hidden]="!borrower.workPhone" *ngIf="dialerEnabled"
          (click)="dial(borrower.workPhone, 'work', false)"><i class="fa fa-fw fa-phone text-muted"></i>
        </a>
      </label>
      <input #borrowerWorkPhone="ngModel" id="workPhone{{borrower.borrowerId}}" class="form-control" name="workPhone{{borrower.borrowerId}}" type="text" [(ngModel)]="workPhone"
        [ngClass]="{
          'is-invalid':
          borrowerWorkPhone &&
          borrowerWorkPhone.touched &&
          borrowerWorkPhone.invalid
        }"
        (ngModelChange)="onPhoneChanged($event, 'workPhone')" mask="(000) 000-0000" [showMaskTyped]="true" />
        <div for="workPhone{{borrower.borrowerId}}" class="invalid-feedback">
          Please enter a valid phone number
        </div>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label for="dateOfBirth{{borrower.borrowerId}}">Date of Birth</label>
      <date-input [name]="'dateOfBirth' + borrower.borrowerId" [max]="maxDate" [min]="minDate" [id]="'dateOfBirth' + borrower.borrowerId"
        [(ngModel)]="borrower.dateOfBirth">
      </date-input>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label for="email{{borrower.borrowerId}}">Email Address</label>
      <input id="email{{borrower.borrowerId}}" type="email" class="form-control" name="email{{borrower.borrowerId}}" placeholder="example@domain.com"
        email trim
        [(ngModel)]="borrower.primaryEmail" #emailInput="ngModel" [ngClass]="{
          'is-invalid':
            emailInput &&
            emailInput.touched &&
            (!emailFormatValidate(borrower.primaryEmail))
        }" />
      <!-- <div for="email" class="invalid-feedback" *ngIf="isEqualsEmailValidate(borrower.email, borrower.coEmail)">
        Borrower and Co-Borrower must have different email
      </div> -->

      <div for="email{{borrower.borrowerId}}" class="invalid-feedback" *ngIf="!emailFormatValidate(borrower.primaryEmail)">
        Please enter a valid email address
      </div>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label for="creditScore{{borrower.borrowerId}}">Credit Score</label>
      <input type="number" id="creditScore{{borrower.borrowerId}}" class="form-control numeric-without-arrows" name="creditScore{{borrower.borrowerId}}" numeric [allowNegative]="false"
        [(ngModel)]="borrower.creditScore" [max]="900" [readonly]="loanBorrower?.scoreFromCreditReport" />
    </div>
</div>

<div class="row">
    <div *ngIf="!isSsnHidden" class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-4">
      <label for="ssn{{borrower.borrowerId}}">SSN</label>
      <input id="ssn{{borrower.borrowerId}}" class="form-control" name="ssn{{borrower.borrowerId}}" type="text" [(ngModel)]="borrower.socialSecNum"
        mask="000-00-0000" placeHolderCharacter="_" [showMaskTyped]="true" [conceal]='true' />
    </div>
</div>

<div class="row">
    <div class="form-group col-xs-12">
      <label class="form-check form-check-inline form-check-solid me-5 mx-2">
        <input class="form-check-input" name="hasServedInMilitary{{borrower.borrowerId}}" type="checkbox"
          [(ngModel)]="borrower.hasServedInMilitary" />
        <span class="fs-6"><strong> Is Veteran / Surviving Spouse</strong></span>
      </label>
    </div>
</div>

<div *ngIf="borrower.hasServedInMilitary">
    <div class="row px-2 mb-2">
      <label class="form-check form-check-inline form-check-solid me-5 mx-2">
        <input class="form-check-input" name="firstUseOfVaBenefit1{{borrower.borrowerId}}" type="checkbox"
          [(ngModel)]="borrower.isFirstUseOfVaBenefit" />
        <span class="fs-6"> First time use </span>
      </label>
    </div>
    <div class="row px-2 mb-2">
      <label class="form-check form-check-inline form-check-solid me-5 mx-2">
        <input class="form-check-input" name="vaFundingFeeExempt1{{borrower.borrowerId}}" type="checkbox"
          [(ngModel)]="borrower.isVaFundingFeeExempt" />
        <span class="fs-6"> Exempt from funding fee </span>
      </label>
    </div>
    <div class="row px-2 mb-2">
        <label class="form-check form-check-inline form-check-solid me-5 mx-2">
          <input class="form-check-input" name="survivingSpouse1{{borrower.borrowerId}}" type="checkbox"
            [(ngModel)]="borrower.isSurvivingSpouseOfMilitary" />
          <span class="fs-6">Surviving spouse</span>
        </label>
    </div>
</div>

<div class="row pt-2">
    <div class="col-md-6 py-2">
      <div class="border py-2" style="padding: 15px 10px 5px 10px;">
        <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
          Present Address
        </span>
        <div class="row" style="padding-top: 6px;">
          <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': coBorr}">
            <label for="presentStreet{{borrower.borrowerId}}">Street Address
              <a (click)="copyAddressFromTo('mailingAddress', 'presentAddress')" class="ms-2">Copy from Mailing</a></label>
            <address-autocomplete-input
              (addressChange)='handlePresentAddress1Change($event)'
              [(ngModel)]='presentAddress.address.address1'
              id='presentStreet{{borrower.borrowerId}}'
              name='presentStreet{{borrower.borrowerId}}'
              placeholder=''
            ></address-autocomplete-input>
          </div>
          <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': coBorr}">
            <label for="presentPOBox{{borrower.borrowerId}}">Unit/Suite</label>
            <input id="presentPOBox{{borrower.borrowerId}}" name="presentPOBox{{borrower.borrowerId}}" type="text" class="form-control"
              [(ngModel)]="presentAddress.address.address2" />
          </div>
          <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': coBorr}">
            <label for="presentCity{{borrower.borrowerId}}">City</label>
            <input id="presentCity{{borrower.borrowerId}}" name="presentCity{{borrower.borrowerId}}" type="text" class="form-control"
              [(ngModel)]="presentAddress.address.city" #presentCityInput="ngModel" />
            <div class="invalid-feedback">This field is required</div>
          </div>
          <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': coBorr}">
            <div class="row">
              <div class="col">
                <label for="presentState{{borrower.borrowerId}}">State</label>
                <select id="presentState{{borrower.borrowerId}}" name="presentState{{borrower.borrowerId}}" class="form-select" placeholder="State"
                  [(ngModel)]="presentAddress.address.state">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let state of states" [ngValue]="state.value">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label for="presentZip{{borrower.borrowerId}}">Zip Code</label>
                <zip-code-input [(ngModel)]="presentAddress.address.zipCode" [name]="'presentZip' + borrower.borrowerId" [required]="false"
                  [disabled]="false" [readonly]="false" [use9digits]="false"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event, presentAddress)"></zip-code-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 py-2">
      <div class="border py-2" style="padding: 15px 10px 5px 10px;">
        <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
          Mailing Address
        </span>
        <div class="row" style="padding-top: 6px;">
          <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': coBorr}">
            <label for="mailingStreet{{borrower.borrowerId}}">Street Address
              <a (click)="copyAddressFromTo('presentAddress', 'mailingAddress')" class="ms-2">Copy from Present</a></label>
            <address-autocomplete-input
              (addressChange)='handleMailingAddress1Change($event)'
              [(ngModel)]='mailingAddress.address.address1'
              id='mailingStreet{{borrower.borrowerId}}'
              name='mailingStreet{{borrower.borrowerId}}'
              placeholder=''
            ></address-autocomplete-input>
          </div>
          <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': coBorr}">
            <label for="mailingPOBox{{borrower.borrowerId}}">Unit/Suite</label>
            <input id="mailingPOBox{{borrower.borrowerId}}" name="mailingPOBox{{borrower.borrowerId}}" type="text" class="form-control"
              [(ngModel)]="mailingAddress.address.address2" />
          </div>
          <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': coBorr}">
            <label for="mailingCity{{borrower.borrowerId}}">City</label>
            <input id="mailingCity{{borrower.borrowerId}}" name="mailingCity{{borrower.borrowerId}}" type="text" class="form-control"
              [(ngModel)]="mailingAddress.address.city" #mailingCityInput="ngModel" />
          </div>
          <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': coBorr}">
            <div class="row">
              <div class="col">
                <label for="mailingState{{borrower.borrowerId}}">State</label>
                <select id="mailingState{{borrower.borrowerId}}" name="mailingState{{borrower.borrowerId}}" class="form-select" placeholder="State"
                  [(ngModel)]="mailingAddress.address.state">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let state of states" [ngValue]="state.value">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label for="mailingZip{{borrower.borrowerId}}">Zip Code</label>
                <zip-code-input [(ngModel)]="mailingAddress.address.zipCode" [name]="'mailingZip' + borrower.borrowerId" [required]="false"
                  [disabled]="false" [readonly]="false" [use9digits]="false"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event, mailingAddress)"></zip-code-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
