import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableModule } from 'primeng/table';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationComponent } from './components/registration.component';
import { TermsAndConditionsDialogComponent } from './components/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintElementModule } from "ngx-print-element";

@NgModule({
  declarations: [
    RegistrationComponent,
    TermsAndConditionsDialogComponent
  ],
  imports: [
    CommonModule,
    RegistrationRoutingModule,
    SharedModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintElementModule
  ],
  providers: [

  ],
  exports: [
    RegistrationComponent
  ]
})
export class RegistrationModule { }
