<div class="modal-header">
  <h5 class="modal-title w-100">
    {{ title }}
  </h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <div class="pt-2 mb-2" *ngIf="error">
    <div class="alert alert-danger border-0 mb-0" role="alert">
      <strong>
        <div>{{error.header}}</div>
      </strong>
      {{error.message}}
    </div>
  </div>
  <form id="feeTemplateForm" #feeTemplateForm="ngForm" name="feeTemplateForm">
    <fee-template-details #feeTemplateDetailComponent [feeTemplate]="feeTemplate"></fee-template-details>
  </form>
</div>

<div class="modal-footer justify-content-end">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i> Cancel
  </button>

  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="save()">
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Saving
    </span>
  </button>
</div>
