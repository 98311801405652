import { Component, Input, OnInit } from '@angular/core';
import { CreditBorrower } from 'src/app/modules/loan-services/credit/models/credit-borrower.model';
import { CreditService } from 'src/app/modules/loan-services/credit/services/credit.service';
import { LoanService } from 'src/app/services/loan';
import { NavigationService } from 'src/app/services/navigation.service';
import * as _ from 'lodash';
import { CreditReport } from 'src/app/models';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'loan-fico-summary',
  templateUrl: 'loan-fico-summary.component.html',
  styleUrls: ['./loan-fico-summary.component.scss'],
})

export class LoanFicoSummaryComponent implements OnInit {

  @Input()
  isTpo: boolean = false;

  @Input()
  borrowers: CreditBorrower[] = [];

  @Input()
  applicationId: number;

  primaryBorrower: CreditBorrower;

  isLoading: boolean = false;

  constructor(private readonly _navigationService: NavigationService,
    private _loanService: LoanService,
    private _creditService: CreditService) { }

  ngOnInit() {
    this.populateCreditInfo(this.applicationId);
  }

  orderCreditClicked = () => {
    let root = this.isTpo ? 'tpo' : 'admin';
    this._navigationService.navigateToPath(`${root}/app-details/${this.applicationId}/credit-reports`);
  }

  private populateCreditInfo = (applicationId: number) => {
    this.isLoading = true;
    this._loanService.getBorrowers(applicationId).subscribe((borrowers) => {
      this.borrowers = borrowers as CreditBorrower[];
      this._creditService.getCreditReportHistory(true, applicationId).subscribe(result => {
        result.forEach((history) => {
          history['creationDate'] = (history.dateUpdated ? new Date(history.dateUpdated) : new Date(history.dateInserted));
        });
        const creditHistory = _.orderBy(result, ['creationDate', 'creditID', 'joint'], ['desc', 'desc', 'desc']);

        if (this.borrowers != null) {
          this.primaryBorrower = this.borrowers.find(b => b.isPrimary);
          this.borrowers.forEach((borrower) => {
            const borrowerHistories = creditHistory.filter(history => history.borrowerId === borrower.borrowerId);
            if (borrowerHistories && borrowerHistories.length > 0) {
              borrower.latestCreditReport = borrowerHistories[0];
            } else {
              borrower.latestCreditReport = new CreditReport()
              borrower.latestCreditReport.borrowerName = Utils.getPersonsDisplayName(borrower);
            }
          });
        }
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      })
    }), error => {
      this.isLoading = false;
    };
  }
}

