import { Component, Input, OnInit } from '@angular/core';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { TransactionPartyType } from '../../models/transaction-party-type.enum';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DocPrep } from '../../models/doc-prep.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'document-preparation-sellers-v2',
  templateUrl: 'document-preparation-sellers-v2.component.html',
  styleUrls: ['./document-preparation-sellers-v2.component.scss']
})

export class DocumentPreparationSellersV2Component implements OnInit {

  @Input()
  docPrep: DocPrep;

  @Input()
  sellerTypes: EnumerationItem[] = [];

  @Input()
  suffixes: EnumerationItem[] = [];

  @Input()
  entityTypes: EnumerationItem[] = [];

  @Input()
  maritalStatuses: EnumerationItem[] = [];
  
  constructor() { }

  ngOnInit() { }

  protected onAddSellerClicked = () => {
    const newSeller = new ExternalContact();
    newSeller.mortgagePartyType = TransactionPartyType.Individual;
    newSeller.applicationId = this.docPrep.applicationId;
    this.docPrep.sellers.push(newSeller);
  }

  protected onDeleteSellerClicked = (seller: ExternalContact) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this seller?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self.docPrep.sellers.indexOf(seller);
        if (index >= 0) {
          self.docPrep.sellers.splice(index, 1);
        }
      }
    });
  }
}