import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ExportDocFilesRequest } from '../models/export-doc-files-request.model';
import { ExportFiles } from '../models/export-files.model';

@Injectable({
    providedIn: 'root'
})
export class ExportFileService {
    constructor(private readonly _dataService: DataService) { }

    getExportDocFiles = (loanId: number): Observable<ExportFiles[]> => {
        return this._dataService.get(`api/File/GetFileForExport/${loanId}`);
    }

    exportDocFiles = (exportPdf: boolean, docs: ExportDocFilesRequest): Observable<any> => {
        const url = `api/File/ExportFile/${exportPdf}`;
        return this._dataService.downloadBinaryFileWithPost(url, docs);
    }
}
