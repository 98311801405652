<ng-template #pageActionBarLeft>
  <bread-crum class="tpo-bread-crumb"></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row">
    <appraisal-management [isPipelineView]="true"></appraisal-management>
  </div>
</ng-template>

<tpo-page-template [pageMainContentTemplate]="mainContent" [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</tpo-page-template>
