import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { TelephonyPool } from "../../admin/dialer-config/models/telephony.model";
import { TelephonyService } from "../modules/telephony-service.model";

@Injectable({
  providedIn: 'root',
})
export class VoiceSmsSettingsService {
  constructor(private _dataService: DataService) { }

  purchasePhoneNumber = (phoneNumber: string, friendlyName: string, messagingServiceId: string): Observable<any> => {
    return this._dataService.post(`api/voice/purchase-phone-number/${phoneNumber}`, { phoneNumber: phoneNumber, friendlyName: friendlyName, messagingServiceId: messagingServiceId });
  }

  setFriendlyName = (phoneNumber: string, friendlyName: string): Observable<any> => {
    return this._dataService.post(`api/voice/set-phone-number-friendlyname/number/${phoneNumber}/name/${friendlyName}`, {});
  }

  // saveCallForwardingPhoneNumber = (phoneNumber: string, callForwardPhoneNumber: string): Observable<any> => {
  //   return this._dataService.post(`api/voice/set-phone-number-call-forwarding/number/${phoneNumber}/companyguid/${callForwardPhoneNumber}`, {});
  // }

  saveSmsForwardingPhoneNumber = (phoneNumber: string, companyGUID: string): Observable<any> => {
    return this._dataService.post(`api/voice/set-phone-number-sms-forwarding/number/${phoneNumber}/companyguid/${companyGUID}`, {});
  }

  sendVerificationCode = (callForwardPhoneNumber: string): Observable<any> => {
    return this._dataService.post(`api/voice/send-verification-code/${callForwardPhoneNumber}`, {}, null, { responseType: 'text' });
  }

  verifyVerificationCode = (callForwardPhoneNumber: string, verificationCode: string, serviceSid: string): Observable<any> => {
    return this._dataService.post(`api/voice/check-verification-code/${callForwardPhoneNumber}/${verificationCode}/${serviceSid}`, {}, null, { responseType: 'text' });
  }

  addOutgoingCallerId = (friendlyName: string, phoneNumber: string): Observable<any> => {
    return this._dataService.post(`api/voice/add-outgoing-caller-id/${phoneNumber}/${friendlyName}`, {}, null, { responseType: 'text'});
  }

  releasePhoneNumber = (data: TelephonyService): Observable<any> => {
    return this._dataService.delete(`api/Admin/service-model/telephony/${data.telephonyServiceId}/release-number/${data.fromPhoneNumber}`, {});
  }

  getTelephonyList = (branchId?: number): Observable<TelephonyService[]> => {
    let query = ""
    if (branchId) {
      query += `branchId=${branchId}&`
    }
    const url = `api/Admin/service-model/telephony?${query}`;
    return this._dataService.get(url);
  };

  getTelephonyListForUser = (userGuid: string): Observable<TelephonyService[]> => {
    const url = `api/Admin/service-model/telephony/for-user/${userGuid}`;
    return this._dataService.get(url);
  };

  getVerifiedPhoneNumberList = (): Observable<string[]> => {
    return this._dataService.get("api/Admin/service-model/telephony/verified");
  }

  updateTelephony = (data: TelephonyService): Observable<TelephonyService> => {
    const url = `api/Admin/service-model/telephony/${data.telephonyServiceId}`;
    return this._dataService.post(url, data);
  };

  createTelephony = (data: TelephonyService): Observable<TelephonyService> => {
    const url = `api/Admin/service-model/telephony`;
    return this._dataService.post(url, data);
  };

  deleteTelephony = (id: number): Observable<any> => {
    return this._dataService.delete(`api/Admin/service-model//telephony/${id}`);
  }

  getTelephonyPools = (): Observable<TelephonyPool[]> => {
    const url = `api/Admin/service-model/telephonyPool`;
    return this._dataService.get(url);
  };

  createTelephonyPool = (data: TelephonyPool): Observable<TelephonyPool> => {
    const url = `api/Admin/service-model/telephonyPool`;
    return this._dataService.post(url, data);
  };

  updateTelephonyPool = (data: TelephonyPool): Observable<TelephonyPool> => {
    const url = `api/Admin/service-model/telephonyPool/${data.telephonyPoolId}`;
    return this._dataService.post(url, data);
  };

  deleteTelephonyPool = (id: number): Observable<any> => {
    return this._dataService.delete(`api/Admin/service-model/telephonyPool/${id}`);
  }

}
