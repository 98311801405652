<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarMiddle>
  <generic-filter [label]="'All Tags'" [icon]="'fa fa-filter'" class="ms-4 hide-on-mobile"
    [filterHeader]="'Agent Tags'" [filterOptions]="tagsForFiltering" (filtersChanged)="onTagsFilterChanged($event)">
  </generic-filter>
</ng-template>

<ng-template #pageActionBarRight>
  <generic-filter [label]="'All Tags'" [icon]="'fa fa-filter'" class="ms-4 ms-xs-0 hide-on-non-mobile"
    [filterHeader]="'Agent Tags'" [filterOptions]="tagsForFiltering" (filtersChanged)="onTagsFilterChanged($event)">
  </generic-filter>
  <button class="btn btn-primary" (click)="onAddNewClicked()">
    <i *ngIf="agentTableConfig.forAgent" class="fas fa-plus me-2"></i>
    {{ !agentTableConfig.forAgent ? 'Add' : 'New' }} Agent
  </button>
</ng-template>

<ng-template #mainContent>
  <agent-table
    *ngIf="agents"
    [agents]="agents"
    [agentTableConfig]="agentTableConfig" #agentTable
    (editClicked)="onEditClicked($event)"
    (viewApplicationsClicked)="onApplicationsClicked($event)">
  </agent-table>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarMiddleContentTemplate]="pageActionBarMiddle"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer [title]="'New Agent'"
  #addAgentEditor
  [name]="'addAgentEditorDrawer'"
  [templateRef]="addAgentEditorDrawerContentRef"
  [options]="addAgentsDrawerOptions"
  (closed)="onAgentEditorDrawerClosed()">
</drawer>
<ng-template #addAgentEditorDrawerContentRef>
  <upsert-agent
    [agentId]="agentId"
    [isDrawer]="true"
    [tab]="editAgentTab"
    (closeDrawer)="onAgentEditorDrawerClosed()">
  </upsert-agent>
</ng-template>

<drawer [title]="'Agent'"
  #editAgentEditor
  [name]="'editAgentEditorDrawer'"
  [templateRef]="editAgentEditorDrawerContentRef"
  [options]="editAgentsDrawerOptions"
  (closed)="onAgentEditorDrawerClosed()">
</drawer>
<ng-template #editAgentEditorDrawerContentRef>
  <upsert-agent
    [agentId]="agentId"
    [isDrawer]="true"
    [tab]="editAgentTab"
    (closeDrawer)="onAgentEditorDrawerClosed()">
  </upsert-agent>
</ng-template>
