<div class="row mt-1">
    <div class="col-md-12" *ngIf="scope === 'Company'">
      <div class="me-3">
        <toggle-configuration
          [title]="'Enable VOI/VOE'"
          [id]="'digitalEmploymentVerificationEnabled'"
          [configuration]="digitalEmploymentVerificationEnabled"
        ></toggle-configuration>
      </div>
    </div>

    <div class="col-md-12 mt-1">
      <hr class="mt-3 mb-0" />
    </div>
    <div class="d-flex align-content-end flex-wrap" *ngIf="digitalEmploymentVerificationEnabled.value">
      <div class="mt-4 me-2" *ngFor="let credential of incomeEmploymentVerificationCredentials">
        <credential-item
          [credential]="credential"
          [credentials]="incomeEmploymentVerificationCredentials"
          (openCredentialModal)="openCredentialModal($event)">
        </credential-item>
      </div>
      <div class="mt-4 me-2">
        <create-credential
          [credentialType]="'VOE/VOI'"
          [credentials]="incomeEmploymentVerificationCredentials"
          (openCredentialModal)="openVendorPicker($event)">
        </create-credential>
      </div>
    </div>
    <div class="col-md-12">
      <enable-message *ngIf="digitalEmploymentVerificationEnabled.value === 0">Enable this feature above!</enable-message>
    </div>
    <div class="col-md-12 pt-4">
        <credentials-basic
            [title]="'Meridian Smart API'"
            [titleLabel]="'Coming soon!'"
            [titleLabelBg]="'badge-soft-warning'"
            [disabled]="true"
            [enableMessageShow]="digitalEmploymentVerificationEnabled.value === 0"
            [enableMessage]="'Enable this feature above!'"
        ></credentials-basic>
    </div>
</div>
