<div class="modal-header">
  <h5 class="modal-title w-100">
    Warm Transfer
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div class="w-100 text-center">
    <span style="color: #729d73;">A CALLER IS ON THE LINE</span>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-soft-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-soft-primary"
    (click)="acceptWarmTransferLead()"
    [disabled]="acceptClicked == true"
  >
  <i class="fa fa-phone"></i> Accept
  </button>
</div>

