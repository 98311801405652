import {Component, Input, OnInit} from '@angular/core';
import {DocumentTemplate} from "../../../../../models/config/global-config.model";
import * as ClassicEditor from 'src/app/shared/utils/ckeditor5/classic/build/ckeditor';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'credit-auth-settings',
  templateUrl: './credit-auth-settings.component.html'
})
export class CreditAuthSettingsComponent implements OnInit {

  @Input() onlineApplicationSettings: any;
  @Input() creditAuthDocumentTemplates: DocumentTemplate[];

  ckEditorConfig = Constants.defaultCkEditorConfig;

  Editor = ClassicEditor;

  constructor() {}

  ngOnInit(): void {}
}
