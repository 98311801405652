import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { MortgageLanguage } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DisclosureHistory, DisclosureRequestData } from 'src/app/models/third-party/disclosure/disclosure-history.model';
import { HighCostTestValidationDetail } from 'src/app/modules/disclosure-tracking/models/disclosure-tracking.model';
import { DisclosureTrackingService } from 'src/app/modules/disclosure-tracking/services/disclosure-tracking.service';
import { ProductPriceValidation } from 'src/app/modules/pricing/models/pricing/product-price-validation.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'le-cd-validation',
  templateUrl: 'le-cd-validation.component.html',
  styleUrls: ['./le-cd-validation.component.scss']
})

export class LEandCDValidationComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  languagePreference: MortgageLanguage;

  protected isLoading: boolean = false;
  protected disclosureValidation: ProductPriceValidation = null;
  protected highCostTestValidations: HighCostTestValidationDetail[] = [];

  private _predisclosureEnumValue: string;
  private _redisclosuresEnumValue: string;

  private recentDisclosure: DisclosureHistory = new DisclosureHistory();
  private documentSets: EnumerationItem[] = [];

  constructor(
    private readonly _disclosureTrackingService: DisclosureTrackingService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _ctxService: ApplicationContextService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _enumerationService: EnumerationService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit() {
    this.getDisclosureValidations();
    this.getDisclosureDocuments();
  }

  reRunTestsClicked = () => {
    this.recentDisclosure.requestData.isAudit = true;
    this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = false;
    this.recentDisclosure.requestData.isDocumentPreview = false;
    this.requestDisclosure();
  }

  private getDisclosureValidations = () => {
    this.isLoading = true;
    this._disclosureTrackingService.checkIfDisclosureIsValid(this.appId)
      .subscribe({
        next: (response) => {
          this.disclosureValidation = {
            validity: response?.validity,
            items: response?.items || [],
          };

          this.highCostTestValidations = (response?.highCostDetail || []).map(d => {
            d["status"] = d.isHighCostLoan == undefined ? "na" : (d.isHighCostLoan ? "fail" : "pass");
            return d;
          });
          this.highCostTestValidations.sort((a, b) => a.order - b.order);
        },
        error: (err) => {
          let errorMessage = "An error occurred while getting disclosure validations.";
          if (err && err.message) {
            errorMessage = err.message;
          }
          this._notifyService.showError(errorMessage, "Error!");
        }
      }).add(() => this.isLoading = false);
  }

  private getDisclosureDocuments = () => {
    forkJoin({
      allEnums: this._enumerationService.getMortgageDocumentSetEnumerations(),
      documentSets: this._loanServicesService.getDisclosureDocumentSets(this.appId)
    })
      .subscribe({
        next: ({ allEnums, documentSets }) => {
          this._predisclosureEnumValue = this._enumerationService.getEnumValue(Constants.enumerationValueNames.MortgageDocumentSet.Predisclosure);
          this._redisclosuresEnumValue = this._enumerationService.getEnumValue(Constants.enumerationValueNames.MortgageDocumentSet.Redisclosure);

          let allDocSetEnums = allEnums[Constants.documentSetEnumerations.mortgageDocumentSet] || [];
          this.documentSets = documentSets && documentSets.length ? allDocSetEnums.filter(e => documentSets.findIndex((le => le == e.value)) > -1) : [];
          const initialDisclosures = this.documentSets.find(ds => ds.value === this._predisclosureEnumValue);
          if (!initialDisclosures) {
            const redisclosure = allDocSetEnums.find(e => e.value === this._redisclosuresEnumValue);
            if (redisclosure) {
              this.documentSets.push(redisclosure);
            }
          }

          this.getRecentDisclosure();
        },
        error: (err) => {
          this._notifyService.showError(
            err?.message || "Couldn't load documents",
            'Disclosure'
          );
        }
      })
  }


  private getRecentDisclosure = () => {
    this._loanServicesService.getDisclosureHistory(this.appId, true)
      .subscribe({
        next: (history: DisclosureHistory[]) => {
          this.recentDisclosure = history[0] || this.initDefaultDisclosure();

          if (!this.recentDisclosure.requestData.preferredLanguage) {
            this.recentDisclosure.requestData.preferredLanguage = this.languagePreference;
          }

          this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = !this.recentDisclosure.requestData.isAudit;
        },
        error: (err) => {
          this.recentDisclosure = this.initDefaultDisclosure();
          this._notifyService.showError(
            err?.message || "Couldn't load most recent disclosure",
            'Disclosures'
          );
        }
      });
  }

  requestDisclosure() {

    const requestData: DisclosureRequestData = {
      applicationId: this.appId,
      ...this.recentDisclosure.requestData
    };

    this._spinnerService.show();
    this._loanServicesService
      .requestDisclosure(requestData)
      .subscribe({
        next: (res) => {
          this._spinnerService.hide();

          this.recentDisclosure = res?.item1 || this.initDefaultDisclosure();

          if(this.recentDisclosure.disclosureResponse?.errors?.length){
            return;
          }

          if (this.appId) {
            this._ctxService.updateLoanTasks();
          }

          this._notifyService.showSuccess(
            "Tests ran successfully",
            'Disclosure'
          );

          this.getDisclosureValidations();
        },
        error: (err) => {
          this._spinnerService.hide();
          this._notifyService.showError(
            err?.message || "Couldn't request disclosure",
            'Disclosure'
          );
        }
      });
  }

  private initDefaultDisclosure(): DisclosureHistory {
    const initialDisclosures = this.documentSets.find(ds => ds.value === this._predisclosureEnumValue);
    const defaultDisclosures = initialDisclosures ? initialDisclosures.value : this._redisclosuresEnumValue;
    return <DisclosureHistory>{
      requestData: {
        isAudit: true,
        shouldSendESignLinkToBorrowers: true,
        documentSet: defaultDisclosures,
        loanPlanCode: '',
        preferredLanguage: this.languagePreference,
        floodCertificationType: '',
        sendNotificationToRequestor: false,
        includeLoanAnalysisReports: false,
      } as unknown as DisclosureRequestData,
    };
  }

}
