import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { ErrorMessage } from 'src/app/shared/models/error.model';
import { NavigationService } from '../../services/navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  error: ErrorMessage | undefined;

  possibleLogoUrls: string[] = [];

  backgroundImageUrl: string = null;
  currentYear: number = new Date().getFullYear();

  private _refreshInterval: NodeJS.Timeout;

  private _returnUrl: string | null = '';

  private _activatedRouteSubscription: Subscription;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router,
    private readonly _environment: EnvironmentService
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    this.backgroundImageUrl = this._environment.apiInfo.apiBaseUrl + 'company/co/login';
    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      this._returnUrl = params['returnUrl'];
    });

    const error = this._router.getCurrentNavigation().extras.state;
    if (error && error.currentPath) {
      this._returnUrl = error.currentPath;
    }
  }

  ngOnInit() {
    this._refreshInterval = setInterval(() => {
      this.retry();
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this._refreshInterval) {
      clearInterval(this._refreshInterval)
    }
    this._activatedRouteSubscription?.unsubscribe();
  }

  onTestBackgroundImageErrored = () => {
    setTimeout(() => {
      this.backgroundImageUrl = "./assets/images/background-temp.png";
    })
  }

  private retry = () => {
    let returnUrl = "";
    if (this._returnUrl) {
      returnUrl = this._returnUrl;
      if (!this._returnUrl.includes('admin') && !this._returnUrl.includes('tpo')) {
        returnUrl = '/admin/' + this._returnUrl;
      }
    }
    this._navigationService.navigateToPath(returnUrl);
  };
}
