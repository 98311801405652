import { Component, Injector, Input, OnInit } from '@angular/core';
import { ApplicationContext } from 'src/app/models/application-context.model';
import { MessageHistoryItem } from 'src/app/models/message.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CorrespondenceService } from '../../services/correspondence.service';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewSentEmailDialogComponent } from '../preview-sent-email-dialog/preview-sent-email-dialog.component';
import { Subscription } from "rxjs";

@Component({
  selector: 'correspondence-history',
  templateUrl: 'correspondence-history.component.html'
})

export class CorrespondenceHistoryComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  history: MessageHistoryItem[] = null;

  @Input()
  scrollOffsetInPixels: number = 324;

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _correspondenceService: CorrespondenceService) {
    super(injector);
  }

  ngOnInit() {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onViewDetailsClicked = (historyItem: MessageHistoryItem) => {
    const modalRef = this._modalService.open(PreviewSentEmailDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.data = historyItem;
  }

  refreshHistoryTable = (data: any) => {
    this.ngOnInit();
  }

  private initialize = (context: ApplicationContext) => {
    const loanId = context.application.applicationId;
    this._correspondenceService.getSentLoanMessages(loanId).subscribe(history => {
      this.history = history;
    })
  }
}
