import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { CalculatorHousingParams } from '../housing-calculator/housing-calculator.component';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'reduce-term-calculator',
  templateUrl: './reduce-term-calculator.component.html'
})
export class ReduceTermCalculatorComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  proposedParameters = new CalculatorHousingParams();

  totalCumulativeInterest = {
    currentLoan : 0,
    proposed : 0,
    interestSaving: 0
  };

  imageData: SafeResourceUrl;

  constructor(private readonly _utilityService: SharedUtilitiesService) {
    this._utilityService.getImageFileContentAsBase64("assets/images/reduce-term.png", (result) => {
      this.imageData = result;
    });
  }

  ngOnInit(): void {
    this.totalCumulativeInterest.currentLoan = 341.723;
    this.totalCumulativeInterest.proposed = 99.424;
    this.calculateInterestSaving();
  }

  onParametersChanged = (newParams: CalculatorHousingParams) => {
    this.proposedParameters = {...newParams};

  }

  calculateInterestSaving = () => {
    this.totalCumulativeInterest.interestSaving = this.totalCumulativeInterest.currentLoan - this.totalCumulativeInterest.proposed;
  }

}
