<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-money me-2"></i> Employment /
          Income
          Verification
        </h4>
      </div>
      <div class="card-body">
        <div *ngIf="providers && providers.length > 1">
          <form class="form-horizontal auth-form" #providerForm="ngForm" novalidate id="providerForm"
            name="providerForm">
            <div class="form-group mb-2">
              <label class="form-label" style="margin-bottom: 0;" for="provider">Provider:</label>
              <select class="form-select" style="width: 300px;"
                [ngClass]="{'is-invalid' : providerValue && providerValue.touched && providerValue.invalid}"
                #providerValue="ngModel" name="provider" id="provider" [(ngModel)]="selectedProvider"
                (change)="onProviderChanged()" [required]="true">
                <option [ngValue]="null">--Select One--</option>
                <option *ngFor="let provider of providers" [ngValue]="provider.value">{{provider.name}}</option>
              </select>
              <div class="invalid-feedback">Please select a provider.</div>
            </div>
          </form>
        </div>

        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist" *ngIf="selectedProvider">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#orderVoiVoe" role="tab" aria-selected="true"> ORDER
              VOI/VOE
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#voiVoeHistory" role="tab" aria-selected="false">
              VOI/VOE History
            </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <ng-template [ngIf]="!loadingData" [ngIfElse]="loading">
            <div class="tab-pane p-3 active" id="orderVoiVoe" role="tabpanel">
              <order-voi-voe *ngIf="selectedProvider == 'TWN' ? mortgage : true" [isLoanReadOnly]="isLoanReadOnly" [mortgage]="mortgage" [borrowers]="borrowers"
                [twnHistories]="twnHistories" [twnHistoriesExcludedData]="twnHistoriesExcludedData" [loanId]="loanId"
                [orders]="orders" [isVoePullDisabled]="isVoePullDisabled" [provider]="selectedProvider"
                [credentials]="voieCredentials" [allowedProductOptions]="allowProductOpts" [orderStatusOptions]="orderStatusOpts"
                (loadHistory)="loadHistory($event)" (prepareOrdersHistoryToView)="prepareOrdersHistoryToView($event)">
              </order-voi-voe>
            </div>
            <div class="tab-pane p-3" id="voiVoeHistory" role="tabpanel">
              <voi-voe-history [mortgage]="mortgage" [provider]="selectedProvider"
                [twnHistoriesExcludedData]="twnHistoriesExcludedData" [orderHistory]="orderHistory">
              </voi-voe-history>
            </div>
          </ng-template>
          <ng-template #loading>
            <loading-indicator></loading-indicator>
          </ng-template>
        </div>
      </div>
      <!--end card-body-->
    </div>
    <!--end card-->
  </div>
  <!--end col-->
</div>
<!--end row-->
