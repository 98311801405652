import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'test-credential-results-modal',
    templateUrl: './test-credential-results-modal.component.html',
})
export class TestCredentialResultsModalComponent {
    @Input()
    testLosUser: any;

    @Output()
    closeDialog: EventEmitter<never> = new EventEmitter<never>();


    constructor(
        public activeModal: NgbActiveModal
    ) {}


    close() {
        if (this.closeDialog.observed) {
            this.closeDialog.emit();
        }
        this.activeModal.close();
    }
}
