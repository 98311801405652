<div class="card border-top border-0 border-4 border-white">
    <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-users me-2"></i> Conditions </h4>
        <div class="float-end button-items">

            <button class="btn btn-sm btn-outline-primary dropdown-toggle mx-2 fs-6" type="button"
                id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style="padding-top: 2px;">
                Export ...</button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="exportConditions('Open')">All Open</a>
                <a class="dropdown-item" (click)="exportConditions('Submitted')">All Submitted</a>
                <a class="dropdown-item" (click)="exportConditions('Cleared')">All Cleared</a>
                <a class="dropdown-item" (click)="exportConditions('All')">All Conditions</a>
            </div>
            <!--
            <button class="btn btn-sm btn-outline-primary mx-2 fs-6" (click)="importFromLosModal()"> Import From LOS</button>

            <button class="btn btn-sm btn-outline-primary mx-2 fs-6" (click)="importFromLdeModal()"> Import From Lender</button>

            <button class="btn btn-sm btn-outline-primary mx-2 fs-6" (click)="importFromBulkModal()"> Import Bulk Conditions</button> -->
            <button class="btn btn-sm btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                data-bs-toggle="dropdown" aria-expanded="false" style="padding-top: 2px;">
                Import...
                <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="importFromLosModal()">From LOS</a>
                <a class="dropdown-item" (click)="importFromLdeModal()">From Lender</a>
                <a class="dropdown-item" (click)="importFromBulkModal()">Bulk Conditions</a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div *ngFor="let bucket of conditionBuckets" class="mb-3">
            <p-table #dt [value]="filteredList[bucket]" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
                [globalFilterFields]="globalFilterFields" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines" [resizableColumns]="true" columnResizeMode="expand">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <h4 class="d-flex me-5 m-0" style="font-size: 14px;"><i
                                class="fas fa-paper-plane me-2 m-auto"></i>
                            <span class="m-auto">{{bucket}} Conditions</span>
                            <span *ngIf="applicationState[bucket] != 'All'"
                                class="m-auto ms-4">({{filteredList[bucket].length}} of {{conditionCount[bucket]}}
                                {{applicationState[bucket]}})</span>
                        </h4>
                        <div class="btn-group ms-auto" role="group">
                            <input type="radio" class="btn-check fs-6" name="btnradio_{{bucket}}" id="open_{{bucket}}"
                                [(ngModel)]="applicationState[bucket]" value="Open" autocomplete="off"
                                (click)="filterConditions(bucket,'Open')"
                                [checked]="applicationState[bucket] == 'Open'">
                            <label class="btn btn-secondary" for="open_{{bucket}}">Open</label>
                            <input type="radio" class="btn-check fs-6" name="btnradio_{{bucket}}"
                                id="submitted_{{bucket}}" [(ngModel)]="applicationState[bucket]" value="Submitted"
                                autocomplete="off" (click)="filterConditions(bucket,'Submitted')"
                                [checked]="applicationState[bucket] == 'Submitted'">
                            <label class="btn btn-secondary" for="submitted_{{bucket}}">Submitted</label>
                            <input type="radio" class="btn-check fs-6" name="btnradio_{{bucket}}"
                                id="cleared_{{bucket}}" [(ngModel)]="applicationState[bucket]" value="Cleared"
                                autocomplete="off" (click)="filterConditions(bucket,'Cleared')"
                                [checked]="applicationState[bucket] == 'Cleared'">
                            <label class="btn btn-secondary" for="cleared_{{bucket}}">Cleared</label>
                            <input type="radio" class="btn-check fs-6" name="btnradio_{{bucket}}" id="na_{{bucket}}"
                                [(ngModel)]="applicationState[bucket]" value="NA" autocomplete="off"
                                (click)="filterConditions(bucket,'NA')" [checked]="applicationState[bucket] == 'NA'">
                            <label class="btn btn-secondary" for="na_{{bucket}}">N/A</label>
                            <input type="radio" class="btn-check fs-6" name="btnradio_{{bucket}}" id="all_{{bucket}}"
                                [(ngModel)]="applicationState[bucket]" value="All" autocomplete="off"
                                (click)="filterConditions(bucket,'All')" [checked]="applicationState[bucket] == 'All'">
                            <label class="btn btn-secondary" for="all_{{bucket}}">All</label>
                        </div>
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control" (ngModelChange)="dt.first=0"
                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <!-- <ng-template pTemplate="header" *ngIf="conditionsTableData && conditionsTableData.length > 0">
                    <tr>
                        <th [pSortableColumn]="'description'" pResizableColumn>
                            Condition Text
                            <p-sortIcon [field]="'description'"></p-sortIcon>
                        </th>

                        <th [pSortableColumn]="'note'">
                            Note
                            <p-sortIcon [field]="'note'"></p-sortIcon>
                        </th>

                        <th [pSortableColumn]="'taskStatus'">
                            Task Info
                            <p-sortIcon [field]="'taskStatus'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'conditionStatus'">
                            Condition Status
                            <p-sortIcon [field]="'conditionStatus'"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template> -->
                <ng-template pTemplate="body" let-row>
                    <tr>
                        <td>
                            <div class="d-flex justify-content-between">
                                <div class="wrap" style="max-width: 76%;">
                                    <span *ngIf="row.docTypeName" class="text-cell-primary">{{row.docTypeName}} -
                                    </span>
                                    <span *ngIf="row.description != row.conditionText"
                                        class="text-cell-primary">{{row.description}}</span>
                                    <span *ngIf="row.description == row.conditionText"
                                        class="text-cell-primary">Condition</span>
                                </div>
                                <div>
                                    <span class="text-muted pull-right">
                                        <i>
                                            Added on: {{row.requestDate | date:'MM/dd/yyyy h:mm:ss a'}}
                                        </i>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="ms-3 border-4">
                                    <a href="javascript:void(0);" (click)="editConditionTaskModal(row.loanDocTaskId)"><i
                                            class="fa fa-eye me-1"></i></a>
                                    <span *ngIf="row.taskStatus == 'Submitted'"
                                        class="label label-success">Submitted<strong> {{
                                            row.submittedDate | date: 'MM/dd/yyyy'}}</strong></span>
                                    <span *ngIf="row.taskStatus == 'Pending'" class="label label-info">Pending</span>
                                    <span *ngIf="row.taskStatus == 'Rejected'"
                                        class="label label-danger">Rejected</span>
                                    <span *ngIf="row.taskStatus == 'Approved'"
                                        class="label label-success">Approved<strong>
                                            {{
                                            row.completeDate | date: 'MM/dd/yyyy'}}</strong></span>
                                    <span *ngIf="row.taskStatus == 'Requested'"
                                        class="label label-info">Requested</span>
                                    <span *ngIf="row.taskStatus == 'Completed'"
                                        class="label label-success">Complete<strong>
                                            {{
                                            row.completeDate | date: 'MM/dd/yyyy'}}</strong></span>
                                    <span *ngIf="row.taskStatus == 'NotApplicable'" class="label label-info">Not
                                        Applicable<strong>
                                            {{ row.completeDate | date: 'MM/dd/yyyy'}}</strong></span>
                                    <span *ngIf="row.taskStatus == 'ReviewReady'" class="label label-info">Review
                                        Ready</span>
                                    <span *ngIf="row.taskStatus == 'ConditionImportPending'"
                                        class="label label-danger">Condition
                                        Import Pending</span>
                                    <br />
                                    <span *ngIf="row.userName" class="text-muted">Responsible Party:
                                        {{row.userName}}</span><br *ngIf="row.reviewPartyName" />
                                    <span *ngIf="row.reviewPartyName" class="text-muted">Review Party:
                                        {{row.reviewPartyName}}</span>
                                </div>
                            </div>
                            <div class="my-1">
                                <span class="wrap" style='font-style: italic'>Condition Text:
                                    {{row.conditionText}}</span>
                            </div>
                            <div class="ms-3">
                                <div
                                    *ngIf="!row.loanDocModel || !row.loanDocModel.docFiles || row.loanDocModel.docFiles.length == 0">
                                    <div class="text-muted">
                                        There are no files attached to this condition.
                                    </div>
                                </div>
                                <div
                                    *ngIf="row.loanDocModel && row.loanDocModel.docFiles && row.loanDocModel.docFiles.length > 3 && !row.showAllDocs">
                                    <div>
                                        There are {{row.loanDocModel.docFiles.length}} files. <span
                                            (click)="row.showAllDocs = true;" class="routerLink">Show All</span>
                                    </div>
                                </div>
                                <div
                                    *ngIf="row.loanDocModel && row.loanDocModel.docFiles && ((row.loanDocModel.docFiles.length > 0 && row.loanDocModel.docFiles.length <= 3) || row.showAllDocs)">
                                    <div *ngFor="let file of docFilesFilter(row); let index = index"
                                        [ngClass]="{'b-top': index != 0}">
                                        <view-file [allowDelete]="false" [allowMergeSelect]="false"
                                            [displaySingleRow]="true" [file]="file"
                                            (fileDeleted)="onFileDeleted($event, row)" [appId]="row.applicationId"
                                            [globalConfig]="globalConfig" (delete)="deleteFile($event, row)"
                                            (viewDocFile)="onViewFileClicked($event)">
                                        </view-file>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td style="width: 100px;">
                            <button *ngIf="row.conditionStatus === 'ConditionPending'"
                                class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                                data-offset="-90">
                                Pending<i class="mdi mdi-chevron-down"></i>
                            </button>
                            <button *ngIf="row.conditionStatus === 'ConditionCleared'"
                                class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn"
                                data-bs-toggle="dropdown" data-offset="-90">
                                Cleared<i class="mdi mdi-chevron-down"></i>
                            </button>
                            <button *ngIf="row.conditionStatus === 'ConditionReOpened'"
                                class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn"
                                data-bs-toggle="dropdown" data-offset="-90">
                                Re-opened<i class="mdi mdi-chevron-down"></i>
                            </button>
                            <button *ngIf="row.conditionStatus === 'ConditionNotApplicable'"
                                class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                                data-offset="-90">
                                Not Applicable<i class="mdi mdi-chevron-down"></i>
                            </button>
                            <button *ngIf="row.conditionStatus === 'ConditionSubmitted'"
                                class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                                data-offset="-90">
                                Submitted<i class="mdi mdi-chevron-down"></i>
                            </button>

                            <div class="dropdown-menu fixed-pos-ddl-menu">
                                <div class="list-group">
                                    <a *ngIf="row.conditionStatus !== 'ConditionPending'"
                                        class="list-group-item list-group-item-action"
                                        (click)="setConditionStatus(row.loanDocTaskId, 'ConditionPending')">Pending</a>
                                    <a *ngIf="row.conditionStatus !== 'ConditionCleared'"
                                        class="list-group-item list-group-item-action"
                                        (click)="setConditionStatus(row.loanDocTaskId, 'ConditionCleared')">Cleared</a>
                                    <a *ngIf="row.conditionStatus !== 'ConditionReOpened'"
                                        class="list-group-item list-group-item-action"
                                        (click)="setConditionStatus(row.loanDocTaskId, 'ConditionReOpened')">Re-opened</a>
                                    <a *ngIf="row.conditionStatus !== 'ConditionNotApplicable'"
                                        class="list-group-item list-group-item-action"
                                        (click)="setConditionStatus(row.loanDocTaskId, 'ConditionNotApplicable')">Not
                                        Applicable</a>
                                    <a *ngIf="row.conditionStatus !== 'ConditionSubmitted'"
                                        class="list-group-item list-group-item-action"
                                        (click)="setConditionStatus(row.loanDocTaskId, 'ConditionSubmitted')">Submitted</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="4" style="height:200px" class="text-center">
                            There
                            are no conditions/tasks </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>