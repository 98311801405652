import { Component, Input, OnInit } from '@angular/core';
import { DocPrep } from '../../models/doc-prep.model';

@Component({
  selector: 'document-preparation-renewal-and-extension',
  templateUrl: 'document-preparation-renewal-and-extension.component.html'
})
export class DocumentPreparatioRenewalAndExtensionComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  constructor() { }

  ngOnInit() { }

}
