<div class="card mt-2" *ngIf="selectedPrice">
  <a href="javascript:;" class="delete-button-position z-0" (click)="onDeleteClicked()"><i
      class="mdi mdi-close-circle text-danger"></i> </a>
  <div class="card-header"
    [ngClass]="{'d-flex justify-content-between bg-pinned': selectedPrice.isPinned, 'bg-notPinned': !selectedPrice.isPinned}">
    <h4 class="card-title text-white">
      <strong>{{selectedPrice.rate }}%</strong> | {{selectedPrice.lockPeriod}} Day | {{isRebate ? 'Rebate:' :
      'Discount:'}} ({{!!!selectedPrice.discountPercent
      ? selectedPrice.rebatePercent : selectedPrice.discountPercent}}) Points / ({{!!!selectedPrice.discountDollars ?
      (selectedPrice.rebateDollars | currency) : (selectedPrice.discountDollars | currency)}})
    </h4>
    <span *ngIf="selectedPrice.isPinned">
      <i class="dripicons-pin green-pin"></i>
    </span>
  </div>
  <div class="card-body">
    <ul class="list-group">
      <li class="list-group-item"><strong>Total Loan Amount:</strong>
        {{((upfrontCosts && upfrontCosts.totalLoanAmount) ? upfrontCosts.totalLoanAmount : selectedPrice.totalLoanAmount) | currency}}
      </li>
      <li class="list-group-item"><strong>Monthly P & I: </strong>{{selectedPrice.principalAndInterest | currency }}
      </li>
      <li class="list-group-item"><strong>PMI / MIP: </strong>{{selectedPrice.monthlyMi | currency}}</li>
      <li class="list-group-item"><strong>Taxes & Insurance: </strong>{{selectedPrice.taxesAndInsurance | currency}}
      </li>
      <ng-container *ngIf="upfrontCosts">
        <li class="list-group-item"><strong>Upfront PMI/MIP/FFGF Amount: </strong>{{upfrontCosts.pmiMipFfGfAmount |
          currency}}</li>
        <li class="list-group-item"><strong>Upfront PMI/MIP/FFGF Financed Amount:
          </strong>{{upfrontCosts.pmiMipFfGfFinancedAmount | currency }}
        </li>
        <li class="list-group-item"><strong>Upfront PMI/MIP/FFGF Paid In Cash:
          </strong>{{upfrontCosts.pmiMipFfGfPaidinCash | currency}}</li>
        <li class="list-group-item"><strong>Upfront PMI/MIP/FFGF Percent: </strong>{{pmiMipFfGfPercent | percent: '1.3-3'}}
        </li>
      </ng-container>

      <li class="list-group-item" *ngIf="selectedPrice.adjustments && selectedPrice.adjustments.length">
        <a class="adjustments" (click)="onAdjustmentsClicked()">Adjustments</a>
      </li>
    </ul>
  </div>
</div>
