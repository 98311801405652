import { Injectable } from '@angular/core';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { DataService } from 'src/app/core/services/data.service';
import { Observable, debounceTime, share, Subject } from 'rxjs';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { DashboardTaskFilters } from '../models/dashboard-task-filters.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  private _debouncedTaskCountServiceCall: any;

  private _debouncedTaskCountPerUserServiceCalls: Map<string, any> = new Map<string, any>();

  documentDeleted = new Subject();

  constructor(private readonly _dataService: DataService) {
    this._debouncedTaskCountServiceCall = this.getDashboardTaskCounts().pipe(debounceTime(250), share());
  }

  getDashboardTaskCounts = (): Observable<DashboardTaskCounts> => {
    const url = 'api/dashboard/gettaskcounts';
    return this._dataService.get(url);
  }

  getTaskCountsByFilter = (filters: DashboardTaskFilters): Observable<DashboardTaskCounts> => {
    const url = 'api/Dashboard/GetTaskCountsbyFilterCriteria'
    return this._dataService.post(url, filters);
  }

  getTasksByFilter = (filterId: number, filters: DashboardTaskFilters): Observable<LoanDocDashboardTask[]> => {
    const url = 'api/Dashboard/GetTaskByFilterbyFilterCriteria/' + filterId;
    return this._dataService.post(url, filters);
  }

  getTaskByFilter = (filterId: number, appId?: number, loanDocTaskId?: number): Observable<LoanDocDashboardTask[]> => {
    const url = 'api/dashboard/getTaskByFilter/' + filterId + (appId ? '?appId=' + appId : '') + (loanDocTaskId ? '&loanDocTaskId=' + loanDocTaskId : '');
    return this._dataService.get(url);
  }

  getTaskByFilterInLead = (filterId: number, leadId: number, impersonateUserId: string): Observable<LoanDocDashboardTask[]> => {
    let url = `api/dashboard/getTaskByFilterInLead/${filterId}/${leadId}`;
    if (impersonateUserId) {
      url += `?impersonateUserId=${impersonateUserId}`;
    }
    return this._dataService.get(url);
  }

  getDashboardTaskCountsDebounced = (): Observable<DashboardTaskCounts> => {
    return this._debouncedTaskCountServiceCall;
  }

  getDashboardTaskCountsForUserDebounced = (userId: string, branchId?: number | null | undefined): Observable<DashboardTaskCounts> => {
    const cachedCall = this._debouncedTaskCountPerUserServiceCalls.get(userId);
    if (cachedCall) {
      return cachedCall;
    }
    const filters: DashboardTaskFilters = new DashboardTaskFilters([], [], [], userId, null, null);
    if (branchId) {
      filters.branchId = branchId.toString();
    }
    const debouncedTaskCountServiceCall = this.getTaskCountsByFilter(filters).pipe(debounceTime(250), share());
    this._debouncedTaskCountPerUserServiceCalls.set(userId, debouncedTaskCountServiceCall);
    return debouncedTaskCountServiceCall;
  }

  sendEventDocumentDeleted = () => {
    this.documentDeleted.next(null);
  }
}
