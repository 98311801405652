<div *ngIf="scenario">
  <div class="form-group">
    <label class="form-label" for="notes">Notes</label>
    <textarea
      id="textarea"
      class="form-control"
      style="height: 95px"
      rows="2"
      [placeholder]="'Add Note'"
      [(ngModel)]="scenario.borrowerNotes"
    ></textarea>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <button
      type="button"
      class="btn btn-sm btn-outline-secondary me-1"
      (click)="onCancelClicked()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-sm btn-outline-primary"
      (click)="onApplyClicked()"
    >
      Apply
    </button>
  </div>
</div>
