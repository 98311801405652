<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #loanPassCredsForm="ngForm" novalidate id="loanPassCredsForm">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Url</label>
      </div>
      <div class="col-md-10">
        <input name="url" type="text" class="form-control" [(ngModel)]="vendor.url">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Username </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="vendor.userName" #userNameInput="ngModel" name="userName"
          id="userName" [ngClass]="{'is-invalid': userNameInput && userNameInput.touched && userNameInput.invalid}"
          required>
        <div class="invalid-feedback"> This field is required </div>
      </div>
      <ng-container>
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Password </label>
        </div>
        <div class="col-md-4">
          <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName" required></password-input>
        </div>
      </ng-container>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">iFrame Url</label>
      </div>
      <div class="col-md-10">
        <input name="iframeURL" type="text" class="form-control" [(ngModel)]="iframeURL">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class='custom-control-label'>API Key</label>
      </div>
      <div class='col-md-10'>
        <password-input [(ngModel)]='apiKey' [name]="'loanpass_iframe_api_key'"></password-input>
      </div>
    </div>
    <div class='row mt-3'>
      <div class='col-md-2 text-end'>
        <label class='custom-control-label'>Default Pricing Profile</label>
      </div>
      <div class='col-md-10'>
        <ng-select [(ngModel)]='defaultPricingProfileId' name="defaultPricingProfileId" [clearable]='pricingProfileOptions != null'
          [items]='pricingProfileOptions' [loading]='pricingProfileOptions == null' bindLabel='name'
          bindValue='id'></ng-select>
      </div>
    </div>
    <ng-container *ngIf="vendor?.credentialId && !['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)">
      <div class="row mt-3">
        <div class="col-md-2 d-flex align-items-center justify-content-end">
          <label class='custom-control-label'>Mappings</label>
        </div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-3">
              <h5>To LoanPass</h5>
              <div>
                <button type="button" class="btn btn-primary" (click)="onFieldsMappingClicked('ToExecuteRequest')">
                  Loanpass Pricing Request
                </button>
              </div>
            </div>
            <div class="col-md-9">
              <h5>To Lodasoft</h5>
              <div>
                <button type="button" class="btn btn-primary me-2" (click)="onFieldsMappingClicked('ToProductSearchRequest')">
                  Lodasoft Pricing Request
                </button>
                <button type="button" class="btn btn-primary me-2" (click)="onFieldsMappingClicked('ToProduct')">
                  Product
                </button>
                <button type="button" class="btn btn-primary" (click)="onFieldsMappingClicked('ToQuote')">
                  Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='row mt-3'>
        <div class='col-md-2 text-end'>
          <label class='custom-control-label'>Custom Fields</label>
        </div>
        <div class='col-md-10'>
          <button type="button" class="btn btn-primary" (click)="onCustomFieldsMappingClicked()">
            Manage
          </button>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
