import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "lead-escrow-calculator",
  templateUrl: "./lead-escrow-calculator.component.html",
})
export class LeadEscrowCalculator implements OnInit {
  @Input()
  monthlyEscrow: number = 0;

  @Output()
  save: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  close: EventEmitter<number> = new EventEmitter<number>();

  annuallyEscrow: number = 0;
  calculatedMonthlyEscrow: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.annuallyEscrow = Number(this.monthlyEscrow) * 12;
    this.calculatedMonthlyEscrow = this.monthlyEscrow;
  }

  calculateAnnualToMonth() {
    this.calculatedMonthlyEscrow = this.annuallyEscrow / 12;
  }

  onSave() {
    this.save.emit(this.calculatedMonthlyEscrow);
  }

  onClose() {
    this.close.emit();
  }
}