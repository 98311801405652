import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ThirdPartyCredential, ThirdPartyCredentialType } from '../../../../../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CredentialModalComponent } from '../credential-modal/credential-modal.component';
import { Constants } from '../../../../../../services/constants';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import * as _ from 'lodash';
import { ScopeType } from '../../integrations.component';

@Component({
	selector: 'credential-item',
	templateUrl: './credential-item.component.html',
	styleUrls: ['./credential-item.component.scss']
})
export class CredentialItemComponent extends ApplicationContextBoundComponent implements OnInit {
	@Input() credential: ThirdPartyCredential;
	@Input() credentials: ThirdPartyCredential[];
	@Input() isSoftDelete: boolean = false;
	@Input() isFail: boolean = false;

	@Input()
	disableDelete: boolean = false;

	@Input()
	scope: ScopeType;

	@Output() openCredentialModal?: EventEmitter<ThirdPartyCredential> = new EventEmitter<ThirdPartyCredential>();
	@Output() onCredentialSucessfullyDeleted?: EventEmitter<ThirdPartyCredential> = new EventEmitter<ThirdPartyCredential>();

	credentialTitle: string;
	isDefaultForOnlineApp: boolean = false;

	constructor(
		private readonly injector: Injector,
		private readonly _modalService: NgbModal,
		private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
		private readonly _systemLevelService: SystemLevelService,
		private readonly _notificationService: NotificationService,
		private readonly _spinner: NgxSpinnerService
	) {
		super(injector);
	}

	ngOnInit(): void {
		this.setCredentialTitle();
		this.checkIsDefaultForOnlineApp();
	}

	credentialModal(): void {
		if (this.openCredentialModal.observed) { // open custom modal
			this.openCredentialModal.emit(this.credential);
		} else { // open default modal
			const modalRef = this._modalService.open(CredentialModalComponent, Constants.modalOptions.large);
			modalRef.componentInstance.scope = this.scope;
			modalRef.componentInstance.credential = _.cloneDeep(this.credential);
			modalRef.result.then(
				(result) => {
					this.credential = result;
					this.setCredentialTitle();
				},
				() => { }
			);
		}
	}

	setCredentialTitle(): void {
		const vendor = this._thirdPartyCredentialsService
			.getVendors(this.credential)
			.find(el => el.value === this.credential.vendorName);
		this.credentialTitle = vendor ? vendor.name : this.credential.vendorName;
	}

	deleteCredentialsConfirmation(): void {
		Swal.fire({
			title: 'Are you sure?',
			text: `Are you sure you want to delete credentials for ${this.credential.credentialType} - ${this.credential.vendorName}?`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			reverseButtons: true
		}).then((result: SweetAlertResult) => {
			if (!result.value) {
				return;
			}

			this._spinner.show();
			this._systemLevelService.deleteCredential(this.credential)
				.pipe(finalize(() => {
					this._spinner.hide();
				}))
				.subscribe({
					next: res => {
						if (this.credentials) {
							const index = this.credentials.findIndex(el => el.credentialId === this.credential.credentialId);
							if (index > -1) {
								this.credentials.splice(index, 1);
								if (this.credential.userId && (this.credential.userId === this.applicationContext.currentlyLoggedInUserProfile.userProfile.userCompanyGuid)) {
									this.applicationContextService.updateUserProfileThirdPartyCredentials(this.credentials, this.credential.credentialType as ThirdPartyCredentialType);
								}
							}
						}
						this._notificationService.showSuccess(`Credential deleted successfully.`, 'Credential');
						this.onCredentialSucessfullyDeleted.emit(this.credential);
					},
					error: error => {
						this._notificationService.showError(`${error ? error.message : 'Unable to delete.'}`, 'Credential');
					}
				});
		});
	}

	private checkIsDefaultForOnlineApp() {
		const kvp = this.credential.thirdPartyKeyValuePairs?.find(kvp => kvp.key === "DefaultForOnlineApp")
		this.isDefaultForOnlineApp = kvp?.value === "true";
	}
}
