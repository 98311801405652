<div class="modal-header">
    <h5 class="modal-title">Notes</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" id="addNotes">
    <form id="addNotesFrom" name="addNotesFrom" class="form-horizontal">
        <table class="table table-striped table-bordered table-hover no-footer">
            <thead>
                <tr>
                    <th class="col-md-5">Note</th>
                    <th class="col-md-5">Delivery Groups</th>
                    <th class="col-md-2">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let note of newNotes">
                    <td><input class="form-control" value="{{note.note}}" title="{{note.note}}" disabled /></td>
                    <td><input class="form-control" value="{{note.deliveryGroups}}" title="{{note.deliveryGroups}}" disabled /></td>
                    <td>
                        <button class="btn btn-danger" (click)="removeNote(note.id)" style="width: 70px">Delete</button>
                    </td>
                </tr>
                <tr style="background: white;">
                    <td>
                        <input class="form-control" type="text" [(ngModel)]="newNote" name="newNote" id="new-note--note" required/>
                    </td>
                    <td>
                        <select class="form-control" id="new-note--deliveryGroup" name="deliveryGroups" [(ngModel)]="deliveryGroups" required>
                            <option value="">-- Select One --</option>
                            <option value="{{group.value}}" 
                                *ngFor="let group of deliveryGroupsEnum">
                                {{group.name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <button class="btn btn-success" (click)="addNote()" style="width: 70px">Add</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>