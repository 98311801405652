export class DocFileList {
    docFileGuid: string;
    docFileName: string;
    documentTypeId: number;

    constructor(docFileGuid: string, docFileName: string, documentTypeId: number) {
        this.docFileGuid = docFileGuid;
        this.docFileName = docFileName;
        this.documentTypeId = documentTypeId;
    }
}