import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { map, Observable, throwError } from 'rxjs';
import { FeeDefinition } from '../models/doc-prep/fee-definition.model';

@Injectable({
  providedIn: 'root',
})
export class DocPrepService {
  constructor(private readonly _dataService: DataService) {
  }

  upsertFeeDefinition(feeDefinition: FeeDefinition, externalCompanyId?: number): Observable<FeeDefinition> {
    externalCompanyId ??= feeDefinition.externalCompanyId;
    const errorMessage = 'An error occurred while upserting the fee definition.';
    if (externalCompanyId == null) {
      return throwError(() => {
        console.error('Either feeDefinition.externalCompanyId or externalCompanyId must be provided.');
        return new Error(errorMessage);
      });
    }
    if (feeDefinition.externalCompanyId != null && externalCompanyId !== feeDefinition.externalCompanyId) {
      return throwError(() => {
        console.error('feeDefinition.externalCompanyId and externalCompanyId must be consistent if both are provided.');
        return new Error(errorMessage);
      });
    }

    const url = `api/doc-prep/fee-definitions/${externalCompanyId}`;
    return this._dataService.post(url, feeDefinition).pipe(
      // FIXME: This mapping should be removed once the API is updated to return only the new value.
      // item1 is the previous value, item2 is the new value. If it is a new value, it will not have item1.
      map((response) => {
        if (Object.prototype.hasOwnProperty.call(response, 'item2')){
          return (response as { item2: FeeDefinition }).item2;
        } else {
          console.warn(
            'The response from the API did not contain the expected property "item2". The API may have been updated.' +
            ' In that case, please remove this mapping in doc-prep.service.ts as this is a temporary workaround.');
          return response as FeeDefinition;
        }
      }),
    )
  }

  getFeeDefinitions(externalCompanyId: number): Observable<FeeDefinition[]> {
    const url = `api/doc-prep/fee-definitions/${externalCompanyId}`;
    return this._dataService.get(url);
  }

  deleteFeeDefinition(feeDefinition: FeeDefinition): Observable<FeeDefinition> {
    const url = 'api/doc-prep/fee-definitions';
    return this._dataService.delete(url, feeDefinition);
  }
}
