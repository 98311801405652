<div class="modal-header" *ngIf="!inEmail">
  <h5 class="modal-title"></h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>
<div id="sectionConvertedToPDF" class="modal-body style-0">
  <div class="container-fluid loan-quote style-1">
    <div class="row style-2">
      <div class="worksheet-header d-flex style-3">
        <div class="col-md-8 d-flex mb-2 style-4">
          <div class="col-md-2 ps-4 style-5">
            <img
              [src]="imageProfile"
              alt="Profile Image"
              class="thumb-xxl style-6"
              (error)="onProfileImageDownloadFailed()"
            />
          </div>
          <div class="col-md-10 style-7">
            <div class="personal-info style-8">
              <span class="fs-5 style-9">
                <strong *ngIf="appId" class="style-10">
                  {{ user ? user.firstName + ' ' + user.lastName : '-' }}
                </strong>
                <strong *ngIf="!appId" class="style-10">
                  {{
                    loggedInUser
                      ? loggedInUser.userProfile.firstName +
                        ' ' +
                        loggedInUser.userProfile.lastName
                      : '-'
                  }}
                </strong>
              </span>
              <div class="style-11">
                <span *ngIf="appId" class="style-12">
                  NMLS {{ user ? user.nmls : ' - ' }}, License Number:
                  {{ licenseNo ? licenseNo : '-' }}
                </span>
                <span *ngIf="!appId" class="style-12">
                  NMLS
                  {{ loggedInUser ? loggedInUser.userProfile.nmls : ' - ' }},
                  License Number: -
                </span>
              </div>
              <div class="mt-1 style-13">
                <i class="fas fa-phone-alt style-14"></i>
                <conditional-link
                  *ngIf="appId"
                  [href]="userCellPhone ? 'tel:' + userCellPhone : null"
                >
                  <span class="ms-1 style-15">
                    {{ userCellPhone ? (userCellPhone | phone) : '-' }}
                  </span>
                </conditional-link>

                <conditional-link
                  *ngIf="!appId"
                  [href]="
                    loggedInUserCellPhone
                      ? 'tel:' + loggedInUserCellPhone
                      : null
                  "
                >
                  <span class="ms-1 style-15">
                    {{
                      loggedInUserCellPhone
                        ? (loggedInUserCellPhone | phone)
                        : '-'
                    }}
                  </span>
                </conditional-link>

                <i class="far fa-envelope ms-4 style-16"></i>
                <conditional-link
                  *ngIf="appId"
                  [href]="userEmail ? 'mailto:' + userEmail : null"
                >
                  <span class="ms-1 style-17">
                    {{ userEmail ?? '-' }}
                  </span>
                </conditional-link>

                <conditional-link
                  *ngIf="!appId"
                  [href]="
                    loggedInUserEmail ? 'mailto:' + loggedInUserEmail : null
                  "
                >
                  <span class="ms-1 style-17">
                    {{ loggedInUserEmail ?? '-' }}
                  </span>
                </conditional-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center style-18">
          <span class="text-primary fs-3 style-19">
            <strong
              style="vertical-align: -webkit-baseline-middle"
              class="style-20"
            >
              LOAN COMPARISON
            </strong>
          </span>
        </div>
      </div>
      <div class="text-center mt-2 d-flex style-21">
        <span class="fs-5 flex-grow-1 style-22">
          <strong class="style-23">
            Your actual rate, payment and costs could be higher. Get an official
            Loan Estimate before choosing a loan.
          </strong>
        </span>
        <div
          style="margin-right: 2rem"
          class="style-24"
          *ngIf="!inEmail && !!appId"
        >
          <button
            type="button"
            id="buttonMenu"
            class="btn btn-outline-primary style-25"
            style="padding: 4px 6px 0"
            data-bs-container="body"
            data-bs-toggle="popover"
            placement="bottom"
            [popover]="templateMenu"
            data-trigger="click"
            [outsideClick]="false"
            [containerClass]="'templateMenu'"
          >
            <i class="ti-more-alt style-26"></i>
          </button>
          <ng-template #templateMenu>
            <div class="popover-menu">
              <a class="dropdown-item" (click)="onSendAsHtmlInEmailClicked()">
                Send Email
              </a>
              <!-- <a class="dropdown-item" id="subButtonMenu" data-bs-container="body" data-bs-toggle="popover"
                placement="bottom" [popover]="templateSubMenu" data-trigger="click" [outsideClick]="false"
                [containerClass]="'templateSubMenu'"> Send Email </a>
              <ng-template #templateSubMenu>
                <a class="dropdown-item" (click)="onSendAsHtmlInEmailClicked()"> Send As HTML </a>
                <a class="dropdown-item" (click)="onSendAsImageInEmailClicked()"> Send As Image </a>
                <a class="dropdown-item" (click)="onSendAsEmailClicked()"> Send As PDF </a>
              </ng-template>
              <a class="dropdown-item" (click)="onSendAsTextClicked()"> Send SMS </a> -->
            </div>
          </ng-template>
        </div>
      </div>
      <div class="card-body-header mt-2 style-27">
        <span class="style-28">
          Quote Number: <span class="text-danger style-29"> TBD </span>
        </span>
        <span class="style-30">
          Preparation Date: {{ currentDate | date : 'MMM d, y h:mm:ss a' }}
        </span>
      </div>
      <div class="card-body border mt-2 style-31">
        <div class="row style-32">
          <div class="col-md-4 style-33">
            <div class="style-34">
              <span class="style-35"> Loan Purpose: </span>
              <span style="margin-left: 29px" class="style-36">
                <strong class="style-37">
                  {{ loanPurpose ? loanPurpose : '-' }}
                </strong>
              </span>
            </div>
            <div class="mt-1 style-38">
              <span class="style-39"> Property Type: </span>
              <span style="margin-left: 29px" class="style-40">
                <strong class="style-41">
                  {{ propertyType ? propertyType : '-' }}
                </strong>
              </span>
            </div>
            <div class="mt-1 style-42">
              <span class="style-43"> Estimated FICO: </span>
              <span style="margin-left: 19px" class="style-44">
                <strong class="style-45"> {{ fico ? fico : '-' }} </strong>
              </span>
            </div>
          </div>
          <div class="col-md-4 style-50">
            <div *ngIf="isPurchase" class="style-51">
              <span class="style-52"> Purchase Price: </span>
              <span style="margin-left: 29px" class="style-53">
                <strong class="style-54">
                  {{ purchasePrice ? (purchasePrice | currency) : '-' }}
                </strong>
              </span>
            </div>
            <div *ngIf="isRefi" class="style-51">
              <span class="style-52"> Appraised Value: </span>
              <span style="margin-left: 22px" class="style-53">
                <strong class="style-54">
                  {{ appraisedValue ? (appraisedValue | currency) : '-' }}
                </strong>
              </span>
            </div>
            <div class="mt-1 style-55">
              <span class="style-56"> Occupancy: </span>
              <span style="margin-left: 52px" class="style-57">
                <strong class="style-58">
                  {{ occupancy ? occupancy : '-' }}
                </strong>
              </span>
            </div>
            <div class="mt-1 style-59">
              <span class="style-60"> ZIP / State: </span>
              <span style="margin-left: 55px" class="style-61">
                <strong class="style-62">
                  {{ zipCode ? zipCode : '-' }} / {{ state ? state : '-' }}
                </strong>
              </span>
            </div>
          </div>
          <div class="col-md-4 style-67">
            <div class="style-68">
              <span class="style-69"> Loan Amount: </span>
              <span style="margin-left: 29px" class="style-70">
                <strong class="style-71">
                  {{ loanAmount ? (loanAmount | currency) : '-' }}
                </strong>
              </span>
            </div>
            <div class="mt-1 style-72">
              <span class="style-73"> No. of Units: </span>
              <span style="margin-left: 38px" class="style-74">
                <strong class="style-75"> {{ noOfUnits }} </strong>
              </span>
            </div>
            <div class="mt-1 style-76">
              <span class="style-77"> Escrow: </span>
              <span style="margin-left: 64px" class="style-78">
                <strong class="style-79">
                  {{ waiveEscrows ? 'Waived' : 'Not Waived' }}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row cards-section mt-4 style-999">
        <table class="table table-borderless style-84">
          <thead class="style-85">
            <tr style="border-bottom: 2px solid black" class="style-86">
              <th class="fw-bold style-87">Loan Info</th>
              <th class="style-88">Loan</th>
              <th class="style-{{89 + i}}" *ngFor="let scenario of scenarios; let i = index">
                {{ scenario.name ? scenario.name : 'Scenario ' + (i + 1)}}
              </th>
            </tr>
          </thead>
          <tbody class="style-92">
            <tr class="style-93" *ngIf="isRefi">
              <td class="fw-bold style-94">Appraised Value</td>
              <td class="style-95">{{ appraisedValue
                  ? (appraisedValue | currency)
                  : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{96 + i}}">
                {{
                  scenario.pricingRequestPayload &&
                  scenario.pricingRequestPayload.propertyInformation
                    ? (scenario.pricingRequestPayload.propertyInformation
                        .appraisedValue | currency)
                    : ''
                }}
              </td>
            </tr>
            <tr class="style-93" *ngIf="isPurchase">
              <td class="fw-bold style-94">Purchase Price</td>
              <td class="style-95">{{ purchasePrice
                  ? (purchasePrice | currency)
                  : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{96 + i}}">
                {{
                  scenario.pricingRequestPayload &&
                  scenario.pricingRequestPayload.propertyInformation
                    ? (scenario.pricingRequestPayload.propertyInformation
                        .salesPrice | currency)
                    : ''
                }}
              </td>
            </tr>
            <tr class="style-99" [hidden]="isRefi">
              <td class="fw-bold style-100">Down Payment</td>
              <td class="style-101">{{
                downPayment
                  ? (downPayment | currency)
                  : ''
              }}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{102 + i}}">
                {{ getDownPaymentFromScenario(scenario) | currency }}
              </td>
            </tr>
            <tr class="style-105">
              <td class="fw-bold style-106">Loan Amount</td>
              <td class="style-107">{{
                loanAmount ? (loanAmount | currency) : ''}}
              </td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{108 + i}}">
                {{
                  scenario.pricingRequestPayload &&
                  scenario.pricingRequestPayload.loanInformation
                    ? (scenario.pricingRequestPayload.loanInformation
                        .baseLoanAmount | currency)
                    : ''
                }}
              </td>
            </tr>
            <tr class="style-111">
              <td class="fw-bold style-112">Interest Rate</td>
              <td class="style-113">{{rate ? ((rate / 100) |
                percent:'1.3-3'):
                ''}}</td>

              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{114 + i}}">
                {{scenario.adjustedRate ? ((scenario.adjustedRate / 100) |
                percent:'1.3-3'):
                ''}}
              </td>
            </tr>
            <tr class="style-117">
              <td class="fw-bold style-118">APR</td>
              <td class="style-119">{{apr ? ((apr/100) | percent:'1.3-3') : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{120 + i}}">
                {{scenario.apr ? ((scenario.apr / 100) | percent:'1.3-3') : ''}}
              </td>
            </tr>
            <tr class="style-123">
              <td class="fw-bold style-124">Lock Term</td>
              <td class="style-125">{{application?.productPricing?.lockTerm}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{126 + i}}">
                {{scenario.lockTerm}}
              </td>
            </tr>
            <tr style="border-bottom: 1px solid black" class="style-129">
              <td class="fw-bold style-130">Housing Expenses</td>
              <td class="style-131"></td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{132 + i}}">
              </td>
            </tr>
            <tr class="style-135">
              <td class="ps-4 style-136">First Mortgage (P&I)</td>
              <td class="style-137">{{mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest ?
                (mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest | currency) : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{138 + i}}">
                {{scenario.principalAndInterest ? (scenario.principalAndInterest | currency) : ''}}
              </td>
            </tr>
            <tr class="style-141">
              <td class="ps-4 style-142">Homeowners Insurance</td>
              <td class="style-143">{{mortgage.proposedHousingExpense.homeownersInsurance ?
                (mortgage.proposedHousingExpense.homeownersInsurance | currency) : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{144 + i}}">
                {{scenario.monthlyInsurance ?
                (scenario.monthlyInsurance | currency) : mortgage.proposedHousingExpense.homeownersInsurance | currency}}
              </td>
            </tr>
            <tr class="style-147">
              <td class="ps-4 style-148">Property Taxes</td>
              <td class="style-149">{{mortgage.proposedHousingExpense.realEstateTax ?
                (mortgage.proposedHousingExpense.realEstateTax | currency) : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{150 + i}}">
                {{scenario.monthlyTaxes ?
                  (scenario.monthlyTaxes | currency) : ''}}
              </td>
            </tr>
            <tr class="style-153">
              <td class="ps-4 style-154">Mortgage Insurance</td>
              <td class="style-155">{{mortgage.proposedHousingExpense.mortgageInsurance ?
                (mortgage.proposedHousingExpense.mortgageInsurance | currency) : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{156 + i}}">
                {{scenario.monthlyMi ?
                  (scenario.monthlyMi | currency) : ''}}
              </td>
            </tr>
            <tr class="style-159">
              <td class="ps-4 style-160">Association/Project Dues (Condo, Co-Op, PUD)</td>
              <td class="style-161">{{mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees ?
                (mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees | currency) : ''}}</td>

              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{162 + i}}">
                {{scenario.monthlyHoa ?
                (scenario.monthlyHoa | currency) : ''}}
              </td>
            </tr>
            <tr class="style-165">
              <td class="ps-4 style-166">Other</td>
              <td class="style-167">{{mortgage.proposedHousingExpense.otherHousingExpense ?
                (mortgage.proposedHousingExpense.otherHousingExpense | currency) : ''}}</td>
              <td class="style-168"></td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{169 + i}}">
              </td>
            </tr>
            <tr class="style-165">
              <td class="ps-4 style-166">Other Financing (P&I)</td>
              <td class="style-167">{{mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest ?
                (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest | currency) : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{169 + i}}">
                {{mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest ?
                  (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest | currency) : ''}}
              </td>
            </tr>
            <tr style="border-bottom: 1px solid black" class="style-171">
              <td class="ps-4 style-172">Total</td>
              <td class="style-173">{{mortgage.calculatedStats.proposedMonthlyPaymentTotal ?
                (mortgage.calculatedStats.proposedMonthlyPaymentTotal | currency) : ''}}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{174 + i}}">
                  {{scenario.totalPayment ?
                  (scenario.totalPayment | currency) : ''}}
              </td>
            </tr>
            <tr class="style-177">
              <td class="fw-bold style-178">
                PMI, MIP, Funding Fee
              </td>
              <td class="style-179">
                {{ (ftcSectionAmounts['F']['pmi'] | currency) || '' }}
              </td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{180 + i}}">
                {{ scenario.pricingRequestPayload &&
                  scenario.pricingRequestPayload.loanInformation
                    ? (scenario.pricingRequestPayload.loanInformation
                        .upfrontPmiMipFfGfAmount | currency)
                    : ''}}
              </td>
            </tr>
            <!--
            <tr style="border-bottom: 1px solid black" class="style-183">
              <td class="fw-bold style-184">Total Cash to Close</td>
              <td class="style-185"> {{ (ftcSectionAmounts['allTotal'] | currency) || '' }}</td>
              <td *ngFor="let scenario of scenarios; let i = index" class="style-{{186 + i}}">
                {{scenario.borrowerPaidClosingCostDollars ? (scenario.borrowerPaidClosingCostDollars | currency) : ''}}
              </td>
            </tr>
            -->
          </tbody>
        </table>
      </div>
      <div class="style-998">
        <div class="cards-section mt-2 style-214" style="font-size: smaller">
          <span class="style-215">
            This estimate is provided for illustrative and informational purposes
            only based on the initial basic loan scenario provided. This is not a
            loan approval or commitment to lend.
            <span *ngIf="updatedDate" class="style-216">
              Rates effective as of {{ updatedDate | date : 'MM/dd/yyyy' }} and
              are subject to change.
            </span>
            <span *ngIf="!updatedDate" class="style-216">
              Currently, there is no pricing information for the loan.
            </span>
            Annual Percentage Rate APR is an estimate based on criteria provided.
            Until you lock your rate, APR and terms are subject to change or may
            not be available at commitment or closing.
          </span>
        </div>
        <div class="mt-3 d-flex style-217" style="border-top: 1px dashed #d3ddeb">
          <div class="col-md-8 d-flex style-218">
            <div class="col-md-3 ps-4 mt-4 style-219">
              <img
                [src]="imageLogo"
                (error)="onCompanyLogoDownloadFailed()"
                alt="Company Logo"
                [ngClass]="{
                  'img-size': !isFailed,
                  'default-img-size': isFailed
                }"
                style="width: 105px"
                class="style-220"
              />
            </div>
            <div class="col-md-9 mt-3 style-221">
              <div class="personal-info style-222">
                <span class="fs-5 style-223">
                  {{ company ? company.companyName : '-' }}
                </span>
                <div class="style-224">
                  <span class="style-225">
                    Company NMLS {{ company ? company.nmls : '-' }}
                  </span>
                </div>
                <div class="style-226">
                  <a class="style-227">
                    <span class="style-228"> {{ companyUrl }} </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-end style-229">
            <button class="btn btn-primary me-4 style-230" hidden>
              Apply Now
            </button>
            <img
              [src]="imageApplyNow"
              alt="profile-user"
              class="thumb-xxl mt-2 style-231"
              style="margin-right: 2.5rem"
              hidden
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!inEmail">
  <button class="btn btn-secondary mx-2" [print]="printConfig">
    Export to PDF
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="activeModal.dismiss()"
    data-bs-dismiss="modal"
  >
    Close
  </button>
</div>
