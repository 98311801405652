import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';

@Injectable()
export class LoanPurposeService {
  constructor(private readonly _dataService: DataService) {}

  getAllLoanPurposes(companyId: number): Observable<Array<LoanPurpose>> {
    return this._dataService.get(
      `api/Admin/AllLoanPurposeModel?companyId=${companyId}`
    );
  }

  insertLoanPurpose(data: LoanPurpose): Observable<LoanPurpose> {
    return this._dataService.post(
      `api/Admin/InsertLoanPurposeModel`,
      data
    );
  }

  updateLoanPurpose(data: LoanPurpose): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateLoanPurposeModel`,
      data
    );
  }

  updateLoanPurposeOrder(
    sortedIds: Array<{ id: number }>,
    companyId: number
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateLoanPurposeOrder?companyId=${companyId}`,
      sortedIds
    );
  }

  deleteLoanPurpose(
    loanPurposeId: number,
    companyId: number,
    assignedLoanPurposeId?: number
  ): Observable<undefined> {
    let url = `api/Admin/DeleteLoanPurposeModel/${loanPurposeId}?companyId=${companyId}`;

    if (assignedLoanPurposeId) {
      url += `&replaceWithLoanPurposeId=${assignedLoanPurposeId}`;
    }

    return this._dataService.delete(url);
  }
}
