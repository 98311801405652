import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { BorrowerNote } from '../../models/borrower-note.model';
import { NotificationService } from 'src/app/services/notification.service';
import { BorrowersService } from '../../services/borrowers.service';

interface IBorrowerNote extends BorrowerNote {
  groupDate: Date;
  groupDateStr: string;
}

@Component({
  selector: 'borrower-notes',
  templateUrl: './borrower-notes.component.html',
  styleUrls: ['./borrower-notes.component.scss']
})
export class BorrowerNotesComponent implements OnInit {

  @Input()
  borrowerId: number;

  @ViewChild('contactNoteForm')
  contactNoteForm: NgForm | undefined;

  noteslist: BorrowerNote[];
  groupedData: { groupDate: Date, groupDateStr: string, notes: IBorrowerNote[] }[] = [];

  noteContent: BorrowerNote = new BorrowerNote();

  loadingNotes: boolean;
  submitting: boolean;

  constructor(private readonly _contactsService: BorrowersService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    this._getNotes(true);
  }

  saveContactNotes = () => {
    if (!this.contactNoteForm) return;
    this.contactNoteForm.form.markAllAsTouched();
    if (!this.contactNoteForm.form.valid) return;

    this.submitting = true;
    this._contactsService.postBorrowerNote(this.borrowerId, this.noteContent).subscribe((response) => {
      this.noteContent.content = "";
      this.contactNoteForm.form.markAsUntouched();

      this._getNotes()
      this._scrollToCurrentConversation();

      this._notifyService.showSuccess('Your information has been saved successfully.', 'Success!');
      this.submitting = false;
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : '', 'Error');
      this.submitting = false;
    });
  }

  private _scrollToCurrentConversation = () => {

    const element = document.getElementById(`notes-container`);
    if (!element) return;

    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 250);
  }

  private _getFormattedGroupDate = (gDate: Date): string => {
    return gDate ? new Date(gDate).toLocaleDateString('en-US') : undefined;
  }

  private _groupNotesByGroupDate = (notes: IBorrowerNote[]): void => {
    let groupedData = _.chain([...notes])
      .groupBy(('groupDateStr'))
      .map((value, key) => ({ groupDateStr: key, groupDate: new Date(key), notes: value }))
      .value();

    let sortedGroups = _.orderBy(groupedData, ['groupDate'], ['desc']);

    sortedGroups.forEach((group) => {
      group.notes = _.orderBy(group.notes, ['dateUpdated'], ['desc']);
    });

    this.groupedData = sortedGroups;
  };

  private _getNotes = (isInitLoad?: boolean) => {
    this.loadingNotes = isInitLoad && true;
    this._contactsService.getBorrowerNotes(this.borrowerId).subscribe((response) => {
      this.noteslist = response;
      this.loadingNotes = false;

      let notes = this.noteslist.map((note: IBorrowerNote) => {
        let date = note.dateUpdated || note.dateInserted;
        note.dateUpdated = date;
        note.groupDate = date;
        note.groupDateStr = this._getFormattedGroupDate(date);

        return note;
      });

      this._groupNotesByGroupDate(notes);

    }, (err) => {
      this._notifyService.showError(err ? err.message || err : '', 'Error');
      this.loadingNotes = false;
    });
  }

}
