import { AfterViewInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Borrower } from '../../../../models';
import { ConditionsTask } from '../../../../models/task/conditons-task.model';
import { document } from 'ngx-bootstrap/utils';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';

const maxWidth = '30rem';

@Component({
  selector: 'task-email-sender-modal',
  templateUrl: './task-email-sender-modal.component.html',
  styleUrls: ['./task-email-sender-modal.component.scss'],
})
export class TaskEmailSenderModalComponent implements AfterViewInit {

  @Input() 
  borrower: Borrower | BorrowerDto;

  @Input() 
  task: ConditionsTask | LoanDocDashboardTask;

  @Input() 
  forceEmailUpdate: boolean;

  constructor(
    private readonly _activeModal: NgbActiveModal,
  ) {
  }

  ngAfterViewInit(): void {
    this.initModalElement();
  }

  protected onSend(): void {
    this._activeModal.close();
  }

  protected onCancel(): void {
    this._activeModal.dismiss();
  }

  private initModalElement(): void {
    const modalElement = document.querySelector('ngb-modal-window');
    if (modalElement == null) {
      throw new Error('Could not find modal element.');
    }

    const modalStyleInitializer = new ModalStyleInitializer(modalElement);
    modalStyleInitializer.initModalView();
  }
}

class ModalStyleInitializer {
  private modalElement: Element;

  constructor(modalWindow: Element) {
    this.modalElement = modalWindow;
  }

  public initModalView(): void {
    this.setModalMaxWidth();
    this.hideModalOverflow();
  }

  /**
   * This is a hack to set the max-width of the modal.
   * @private
   */
  private setModalMaxWidth(): void {
    const modalDialog = this.modalElement?.querySelector('.modal-dialog') as HTMLElement | null;
    if (modalDialog == null) {
      throw new Error('Could not find modal dialog element.');
    }

    modalDialog.style.maxWidth = maxWidth;
  }

  /**
   * This is a hack to hide the overflow of the modal.
   * @private
   */
  private hideModalOverflow() {
    const modalContent = this.modalElement?.querySelector('.modal-content') as HTMLElement | null;
    if (modalContent == null) {
      throw new Error('Could not find modal content element.');
    }

    modalContent.style.overflow = 'hidden';
  }
}
