import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Mortgage } from 'src/app/models/mortgage.model';
import { PricingScenarioFieldChange } from '../../models/pricing/pricing-scenario-field-change.model';
import { PricingRateCreditApplicationMethod } from '../../models/pricing/rate.model';
import { ChannelEnum, LoanApplication } from 'src/app/models';

@Component({
  selector: 'app-pricing-scenario-changes-dialog',
  templateUrl: './pricing-scenario-changes-dialog.component.html',
  styleUrls: ['./pricing-scenario-changes-dialog.component.scss']
})
export class PricingScenarioChangesDialogComponent implements OnInit {

  @Input()
  changes: PricingScenarioFieldChange[];

  @Input()
  currentMortgage: Mortgage;

  @Input()
  set currentApplication(application: LoanApplication) {
    this._currentApplication = application;
    this.isWholesaleLoan = this._currentApplication.channel === ChannelEnum.Wholesale;
    if (this.isWholesaleLoan) {
      this.rateCreditApplicationMethod = PricingRateCreditApplicationMethod.ApplyCreditToLenderCredit;
    }
  }

  get currentApplication(): LoanApplication {
    return this._currentApplication;
  }

  @Input()
  hasLenderCredit: boolean;

  protected isWholesaleLoan: boolean = false;

  private _currentApplication: LoanApplication;

  rateCreditApplicationMethod: PricingRateCreditApplicationMethod = PricingRateCreditApplicationMethod.DoNotApplyCredit;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }
}
