<div class="row mt-1">
    <div class="col-md-12">
        <toggle-configuration
            [title]="'Enable Enhanced Reporting'"
            [id]="'enhancedReportingEnabled'"
            [configuration]="enhancedReportingEnabled"
        ></toggle-configuration>
    </div>
    <div class="col-md-12">
        <hr>
    </div>
</div>
