import { MergeDocFilesInstruction } from "./merge-doc-files-instruction.model";

export class MergeDocFilesRequest {
    newSourceDocFileBase64Content: string | object;
    mergeInstructions: MergeDocFilesInstruction[];
    originalFileGuid: string;
    newFileName: string;
    note: string;
    setOcrProcessedFlag: boolean;

    constructor(newSourceDocFileBase64Content: string | object, mergeInstructions: MergeDocFilesInstruction[], originalFileGuid: string, newFileName: string, note: string, setOcrProcessedFlag?: boolean) {
        this.newSourceDocFileBase64Content = newSourceDocFileBase64Content;
        this.mergeInstructions = mergeInstructions;
        this.newFileName = newFileName;
        this.originalFileGuid = originalFileGuid;
        this.note = note;
        this.setOcrProcessedFlag = setOcrProcessedFlag;
    }
}