<ng-template #pageActionBarLeft>
  <bread-crum class="tpo-bread-crumb"></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row">
    <pricing-search-v3
      [pricingScenarios]="scenarios"
      [requestLockEnabled]="requestLockEnabled"
      [enableChannelSelection]="false"
      (onRefreshAllFinished)="atLeastOneScenarioIsRefreshing = false;"
      [showScenarios]="true"
      (scenariosSectionOpeningChanged)="isScenariosSectionOpen = $event;">
    </pricing-search-v3>
  </div>
</ng-template>

<tpo-page-template [pageMainContentTemplate]="mainContent" [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</tpo-page-template>
