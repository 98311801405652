<div class="w-100 h-100 p-2" *ngIf="record.recordType && !['Lead', 'Agent'].includes(record.recordType)">
  <div class="w-100 d-inline-flex mb-2 px-2" *ngIf="record.recordType == 'Application'">
    <div class="d-inline">
      <label class="control-label me-2" for="status">Status: </label>
      <lead-status
        class="d-inline-block"
        *ngIf="record.loanPurpose"
        [selectedRecord]="record"
        [currentDialOfRecord]="selectedDialList"
        (recordStatusChanged)="onRecordStatusChanged($event)"
      >
      </lead-status>
      <em *ngIf="!record.loanPurpose">Missing Loan Purpose</em>
    </div>

    <div class="d-inline ms-4">
      <label class="control-label me-2" for="subStatus">Sub Status: </label>
      <application-substatus
        class="d-inline-block"
        *ngIf="record.loanPurpose"
        [selectedRecord]="record"
        (statusChanged)="onSubStatusChanged($event)"
      >
      </application-substatus>
    </div>

    <div class="d-inline ms-auto">
      <button
        (click)="openApplication(record)"
        class="btn btn-primary"
      >
        Open Application
        <i class="fas fa-link"></i>
      </button>
    </div>
  </div>

  <ul ngbNav [(activeId)]="selectedTab" class="nav-tabs pe-0" #nav="ngbNav">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Detail</a>
      <ng-template ngbNavContent>

        <ng-template #loading>
          <loading-indicator
            loadingMessage="Loading Details..."
          ></loading-indicator>
        </ng-template>

        <div *ngIf="!loadingRecordDetails; else loading" class="row">

          <div *ngIf="record.recordType == 'Application'">
            <loan-summary-mortgage
              [applicationOutsideOfContext]="recordDetails"
              [isUsedAsChildComponent]="true"
              (closeDrawer)="closeDrawerClicked()">
            </loan-summary-mortgage>
          </div>

          <div *ngIf="record.recordType == 'Borrower'">
            <borrower-details
              [borrowerId]="record.borrowerId"
              [isManualDial]="false"
              (savedBorrower)="onAfterSavedBorrower()"
              (closeDrawer)="closeDrawerClicked()"
              (dialClicked)="
                onBorrowerNumberClickedForDial(
                  $event.phoneNumber,
                  $event.phoneType,
                  $event.firstName,
                  $event.lastName
                )
              "
            ></borrower-details>
          </div>
        </div>
      </ng-template>
    </li>

    <ng-container *ngIf="record.recordType == 'Application'">
      <li [ngbNavItem]="2">
        <a ngbNavLink>Activity</a>
        <ng-template ngbNavContent>
          <loan-activity [appId]="record.recordTypeId">
          </loan-activity>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Appointments</a>
        <ng-template ngbNavContent>
          <appointments
            [recordType]="record.recordType"
            [recordTypeId]="record.recordTypeId"
          ></appointments>
        </ng-template>
      </li>
    </ng-container>

    <ng-container *ngIf="record.recordType == 'Borrower'">
      <li [ngbNavItem]="4">
        <a ngbNavLink>Email{{ smsEnabled ? '/SMS' : '' }}</a>
        <ng-template ngbNavContent>
          <common-send-email-sms
            [recordType]="record.recordType"
            [recordId]="record.recordTypeId"
            [record]="recordDetails"
            [showEmailTemplates]="false"
            (emailSent)="emailSent($event)"
            (smsSent)="smsSent($event)"
          ></common-send-email-sms>
        </ng-template>
      </li>

      <li [ngbNavItem]="5">
        <a ngbNavLink>Notes</a>
        <ng-template ngbNavContent>
          <div>
            <contact-notes [contactId]="recordDetails.borrower.borrowerId"></contact-notes>
          </div>
        </ng-template>
      </li>
    </ng-container>

    <ng-container *ngIf="record.recordType == 'Application'">
      <li ngbDropdown class="nav-item">
        <a href (click)="(false)" class="nav-link" ngbDropdownToggle
          >Actions
          <i class="mdi mdi-chevron-down" style="font-size: 13px !important"></i
        ></a>

        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="sendEmailClicked()">
            Send Email{{ smsEnabled ? '/SMS' : '' }}
          </button>
          <button ngbDropdownItem (click)="markManualTransfer()">
            Mark Manual Transfer
          </button>
        </div> 
      </li>

      <li [ngbNavItem]="5">
        <a ngbNavLink>Custom Data</a>
        <ng-template ngbNavContent>
          <loan-custom-data [applicationId]="record.recordTypeId">
          </loan-custom-data>
        </ng-template>
      </li>
    </ng-container>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2 px-2"></div>
</div>

<div *ngIf="record.recordType == 'Lead'" class="w-100 h-100 p-2" style="margin-top: -10px">
  <app-view-lead-drawer
    [leadId]="record.recordTypeId"
    [isDrawer]="true"
    [isManualDial]="false"
    (phoneClicked)="onBorrowerNumberClickedForDial($event.phoneNumber, $event.phoneType, $event.firstName, $event.lastName)"
    (leadUpdated)="onLeadUpdated($event)">
  </app-view-lead-drawer>
</div>

<div *ngIf="record.recordType == 'Agent'" class="w-100 h-100 p-2" style="margin-top: -10px">
  <upsert-agent
    [agentId]="record.recordTypeId"
    [isDrawer]="true"
    [isManualDial]="false"
    [tab]="'agentInfo'"
    (onDialClicked)="onBorrowerNumberClickedForDial(
      $event.phoneNumber,
      $event.phoneType,
      $event.firstName,
      $event.lastName
    )"
    (closeDrawer)="closeDrawerClicked()"
    (onAddEditAgent)="savedAgentClicked($event)"
    >
  </upsert-agent>
</div>
