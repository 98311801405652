<div class="modal-header">
  <h5 class="modal-title"> Provider Picker </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #providerPickerForm="ngForm" novalidate id="providerPickerForm">
    <div class="row mt-3">
      <div class="col-md-4 text-end">
        <label class="custom-control-label"> Fee Provider </label>
      </div>
      <div class="col-md-8">
        <select class="form-select" [(ngModel)]="selectedProvider.value" #providerSelector="ngModel"
          name="providerSelector" id="providerSelector"
          [ngClass]="{'is-invalid': (providerSelector && providerSelector.touched && providerSelector.invalid) || providerSelector.value == 'null'}"
          required>
          <option [value]="null">-- Select One --</option>
          <ng-container *ngFor="let provider of missingFeeProviders">
            <option [value]="provider.value"> {{provider.name}} </option>
          </ng-container>
        </select>
        <div class="invalid-feedback"> This field is required </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="onOkClicked()">
    <span> Go </span>
  </button>
</div>
