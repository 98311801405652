export class PrequalDetail {
  prequalDetailId: number;
  applicationId: number;
  loanProgramDescription: string;
  maxPurchasePrice: number;
  downPaymentPercent: number;
  maxDownPaymentPercent: number;
  interestRate: number;
  contingencyDescription: string;

  lpaHistoryDocumentId: number;
  lpaDocFileId: number;
  lpaDocFileName: string;

  duHistoryDocumentId: number;
  duDocFileId: number;
  duDocFileName: string;

  proofOfFundsDocFileId: number;
  proofOfFundsDocFileName: string;

  companyId: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
}
