<br>
<div *ngIf='!hideTitle' class='row text-dark mb-2 mx-1'>
  ODD DAYS INTEREST
</div>
<div class="row">
  <div class="col-md-2">
    <label class="form-label" for="totalFee">Total Fee</label>
    <currency-input id="totalFee" name="totalFee" [(ngModel)]="fee.calculatedValues.totalFee"
      [disabled]="!fee.calculatedValues.isChargeUserEditable" [required]="true"></currency-input>
  </div>
  <div class="col-md-2">
    <label class="form-label" for="sellerConcessions">Seller Concessions</label>
    <currency-input id="sellerConcessions" name="sellerConcessions" [(ngModel)]="fee.calculatedValues.sellerConcessions"
      [disabled]="!fee.calculatedValues.isChargeUserEditable" [required]="true"></currency-input>
  </div>
  <div class="col-md-2">
    <label class="form-label" for="borrowerTotal">Borrower Paid</label>
    <currency-input id="borrowerTotal" name="borrowerTotal" [(ngModel)]="fee.calculatedValues.borrowerTotal"
      [disabled]="!fee.calculatedValues.isChargeUserEditable" [required]="true"></currency-input>
  </div>
</div>
<br>

<div class="row">
  <div class="col-md-2">
    <label class="form-label" for="estimatedClosingDate">Estimated Closing</label>
    <date-input [id]="'estimatedClosingDate'" [name]="'estimatedClosingDate'"
      [(ngModel)]="fee.calculatedValues.estimatedClosingDate" (blur)="onEstimatedClosingDateChange()" (selectedDate)="onEstimatedClosingDateChange()"
      [required]="true"></date-input>
  </div>

  <div class="col-md-2">
    <label class="form-label" for="paidThroughDate">Paid Through</label>
    <date-input [id]="'paidThroughDate'" [name]="'paidThroughDate'"
      [(ngModel)]="fee.calculatedValues.paidThroughDate"
      [readonly]="true"></date-input>
  </div>
  <!-- <div class="col-md-2">
    <label class="form-label" for="disbursementDate">Disbursement Date</label>
    <date-input [id]="'disbursementDate'" [name]="'disbursementDate'"
      [(ngModel)]="fee.calculatedValues.disbursementDate"
      (ngModelChange)="onDisbursementDateChange()" [required]="true"></date-input>
  </div>
  <div class="col-md-2">
    <label class="form-label" for="firstPaymentDate">First Payment</label>
    <date-input [id]="'firstPaymentDate'" [name]="'firstPaymentDate'"
      [(ngModel)]="fee.calculatedValues.firstPaymentDate"
      (ngModelChange)="onFirstPaymentChange()" [required]="true"></date-input>
  </div> -->
  <div class="col-md-2">
    <label class="form-label" for="oddDays">Number of Days</label>
    <input class="form-control" numeric [allowNegative]="false" id="oddDays" name="oddDays"
      [(ngModel)]="fee.calculatedValues.oddDays" autocomplete="off"
      [required]="true" disabled>
  </div>
  <div class="col-md-2">
    <label class="form-label" for="CalculationMethod">Calculation Method</label>
    <select class="form-select" numeric [allowNegative]="false" id="CalculationMethod" name="CalculationMethod"
      [(ngModel)]="fee.calculatedValues.calculationMethod" autocomplete="off" (change)="onCalculationMethodChange()"
      [required]="true">
      <option *ngFor="let method of calculationMethods" [ngValue]="method.value">{{method.name}}</option>
    </select>
  </div>
  <!-- <div class="col-md-2">
    <label class="form-label" for="interestCredit">
      Interest Credit
      <i class="fa fa-info-circle text-primary ms-1" placement="top"
        ngbTooltip="A mortgage interest credit only applies to a mortgage that closes/funds in the first 5 business days of the month."></i>
    </label>
    <input type="checkbox" class="form-check-input ms-2" id="interestCredit" name="interestCredit"
      [(ngModel)]="fee.calculatedValues.interestCredit" [disabled]="!interestCreditEnabled" [required]="true">
  </div> -->
</div>
<br>
