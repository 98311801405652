<div class="alert border-0 alert-dismissible fade show py-2" *ngIf="alert">
  <div class="d-flex align-items-center">
    <div class="font-35 text-white"><i class="bx"
      [ngClass]="{
        'color-red': alert.type === 2,
        'color-green': alert.type === 3,
        'bx-message-square-x': alert.type === 2,
        'bx-message-square-error': alert.type === 1,
        'bx-comment-detail': alert.type === 0,
        'bx-message-square-check': alert.type === 3
      }"></i>
    </div>
    <div class="ms-3">
      <h6 class="mb-0 text-white">{{alert.title}}</h6>
      <div class="text-white">{{alert.message}}</div>
    </div>
  </div>
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
