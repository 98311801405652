<div class="modal-header">
  <h5 class="modal-title"> HOA Dues & Condo Fees Breakdown </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #breakdownForm="ngForm" novalidate id="breakdownForm" name="breakdownForm" style="padding-left: 60px;">
    <div class="loan-app">
      <div class="grid-layout edit-area">
        <div class="grid-layout-row breakdown-row">
          <div class="form-check col-5">
            <input class="form-check-input" type="radio" name="enterTotalValue" id="enterTotalValue" [value]="false"
              (ngModelChange)="onIsBrokenDownChanged()"
              [(ngModel)]="isBrokenDown">
            <label class="form-check-label radio-label" for="enterTotalValue">
              Enter HOA Dues & Condo Fees:
            </label>
          </div>
          <urla-currency-input class="col-6 breakdown-input" *ngIf="!isBrokenDown"
              name="homeownersAssociationDuesAndCondominiumFees" [label]="''" [required]="true"
              [(ngModel)]="proposedHousingExpenses.homeownersAssociationDuesAndCondominiumFees"
              (ngModelChange)="onHomeownersAssociationDuesAndCondominiumFeesChanged()">
            </urla-currency-input>
          <span class="col-6 breakdown-readonly" *ngIf="isBrokenDown">
            {{ !proposedHousingExpenses.homeownersAssociationDuesAndCondominiumFees ? '--' : (proposedHousingExpenses.homeownersAssociationDuesAndCondominiumFees | currency :'USD':'symbol':'1.2-2') }}
          </span>
        </div>
        <div class="grid-layout-row">
          <div class="form-check col-4">
            <input class="form-check-input" type="radio" name="breakItDown" id="breakItDown" [value]="true"
            (ngModelChange)="onIsBrokenDownChanged()"
            [(ngModel)]="isBrokenDown">
            <label class="form-check-label radio-label" for="breakItDown">
              Or Break It Down
            </label>
          </div>
          <div class="breakdown-section">
            <div class="row breakdown-row">
              <div class="col-5">
                Condominium Association Dues
              </div>
              <div class="col-6">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="condominiumAssociationDues"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.condominiumAssociationDues" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-5">
                Cooperative Association Dues
              </div>
              <div class="col-6">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="cooperativeAssociationDues"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.cooperativeAssociationDues" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-5">
                Homeowners Association Dues
              </div>
              <div class="col-6">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="homeownersAssociationDues"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.homeownersAssociationDues" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      Total: <b>{{ proposedHousingExpenses.homeownersAssociationDuesAndCondominiumFees | currency :'USD':'symbol':'1.2-2' }}</b>
    </div>
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
        <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
