import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgentInfoModule } from '../agent-info/agent-info.module';
import { ApplicationsModule } from '../applications/applications.module';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { LeadTableModule } from '../lead-table/lead-table.module';
import { AgentsRoutingModule } from './agents-routing.module';
import { AgentsComponent } from './agents.component';
import { AgentApplicationsComponent } from './components/agent-applications/agent-applications.component';
import { AgentCompanyInfoComponent } from './components/agent-company-info/agent-company-info.component';
import { AgentLeadsComponent } from './components/agent-leads/agent-leads.component';
import { AgentNotesComponent } from './components/agent-notes/agent-notes.component';
import { AgentTableComponent } from './components/agent-table/agent-table.component';
import { AgentsBulkAssignContactDialogComponent } from './components/agents-bulk-assign-contact-dialog/agents-bulk-assign-contact-dialog.component';
import { InsertAgentForExternalContactComponent } from './components/insert-agent-for-external-contact/insert-agent-for-external-contact.component';
import { UpsertAgentComponent } from './components/upsert-agent/upsert-agent.component';

@NgModule({
  declarations: [
    AgentTableComponent,
    AgentsComponent,
    UpsertAgentComponent,
    AgentNotesComponent,
    InsertAgentForExternalContactComponent,
    AgentApplicationsComponent,
    AgentLeadsComponent,
    AgentsBulkAssignContactDialogComponent,
    AgentCompanyInfoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgentsRoutingModule,
    TableModule,
    MultiSelectModule,
    ApplicationsModule,
    CorrespondenceModule,
    LeadTableModule,
    FormsModule,
    ReactiveFormsModule,
    AgentInfoModule,
    PopoverModule.forRoot(),
    DropdownModule
  ],
  exports: [
    AgentTableComponent,
    UpsertAgentComponent,
    AgentNotesComponent
  ]
})
export class AgentsModule { }
