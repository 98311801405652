import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { Borrower, LoanApplication } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Application } from 'src/app/modules/app-details/models/application.model';
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';

import { ChannelService } from 'src/app/services/channel.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { BorrowersService } from '../../services/borrowers.service';

@Component({
  selector: 'app-upsert-application',
  templateUrl: './upsert-application.component.html',
  styleUrls: ['./upsert-application.component.scss']
})
export class UpsertApplicationComponent extends ApplicationContextBoundComponent implements OnInit {

  externalCompanies;

  duplicateBorrowers: Borrower[] = [];

  borrowersContact: Borrower[] = []

  fullBorrower: BorrowerFull = new BorrowerFull();

  borrowerId: number;

  newBorrower: number;

  smsEnabled: boolean;

  currentPortalUserName: string;

  stateOptions: EnumerationItem[] = [];

  loanPurposeOptions: EnumerationItem[] = [];

  loanTypeOptions: EnumerationItem[] = [];

  enabledChannels: EnumerationItem[] = [];

  availableUsers: any[] = [];

  globalConfig: any;

  sendInvite: boolean;

  allApplicants: Application;

  application: LoanApplication;

  newAppWithoutExistingBorrower: boolean = true;

  data: any = [];

  channels: any = [];

  private _contextSubscription: Subscription;

  private _loanId: number;

  constructor(private readonly injector: Injector,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _borrowersService: BorrowersService,
    private readonly _notifsService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _channelService: ChannelService,
    private route:ActivatedRoute) {
      super(injector);
      this.application = new LoanApplication();
      this.stateOptions = this._enumsService.states;
      this.sendInvite = true;
      this._contextSubscription = this.applicationContextService.context.subscribe(context => {
        this.enabledChannels = context.globalConfig.enabledChannels;
        this._loanId = context.application.applicationId;
        this.useBorrowerAddress();
      });
    }

    ngOnDestroy() {
      super.ngOnDestroy();
      if (this._contextSubscription)
        this._contextSubscription.unsubscribe();
    }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.borrowerId = params.borrowerId;
        this.getFullBorrower(this.borrowerId);
      });
      this._enumsService.getMortgageEnumerations().subscribe(enums => {
        this.loanPurposeOptions = enums[Constants.mortgageEnumerations.loanPurpose];
        this.loanTypeOptions = enums[Constants.mortgageEnumerations.mortgageAppliedForType];
      });
    }

    changeSelectedChannel = (newVal) => {
      if (newVal == 'Wholesale') {
        this.availableUsers = [];
        return;
      }

      this.availableUsers = _.chain(this.globalConfig.users)
        .filter(user => user.roleId == this.globalConfig.firstRole.roleId)
        .sortBy(user => [user.lastName, user.firstName])
        .value();
    }

    getFullBorrower = (borrowerId: number) => {
      if (borrowerId) {
          this._appDetailsService.getFullBorrower(borrowerId).subscribe(response => {
            if (response.borrower == null) {
              this.borrowerId = 0;
              this.fullBorrower.borrower.alertViaSms = this.smsEnabled;
              this.newBorrower = null;
            } else {
              this.currentPortalUserName = response.portalUserName;
              this.fullBorrower = response;
              this.newBorrower = this.fullBorrower.borrower.borrowerId;
              }
            },
          (error) => {
            this._notifsService.showError(
            error ? error.message : 'Unable to get Borrower',
            'Error!'
            );
          });
      }
    }

    useBorrowerAddress = () => {
      this._borrowersService.getBorrowerAddress(this._loanId, this.borrowerId).subscribe(response => {
        this.fullBorrower.borrower.mailingStreet = response.mailingStreet;
        this.fullBorrower.borrower.mailingCity = response.mailingCity;
        this.fullBorrower.borrower.mailingState = response.mailingState;
        this.fullBorrower.borrower.mailingZip = response.mailingZip;
      })
    }

    useCoBorrowerAddress = (isBorrower: boolean) => {
      this._borrowersService.getBorrowerAddress(this._loanId, this.borrowerId).subscribe(res => {
        if (res) {
          if (!isBorrower) {
            this.data.coBorrower = { ...this.data.coBorrower, ...res };
          } else {
            this.data.application = { ...this.data.application, ...res };
          }
        }
      })
    }
  }
