<div id="keyDate_{{keyDate.keyDateConfigurationId}}" class="dd-handle">
    <span [ngClass]="{'handle': !deleting}"> ::: </span>
    {{keyDate.displayName}} <span *ngIf="keyDate.keyDateType" class="ms-1 badge bg-success">{{keyDate.keyDateTypeDisplayName}}</span>
    <span style="float: right;">
        <div class="d-flex flex-row">
            <div class="me-1">
                <i class="far fa-edit cursor font-12 text-primary" (click)="editKeyDate()" title="Edit key date"></i>
            </div>
            <div class="me-1">
                <i *ngIf="!deleting" class="fa fa-trash-alt cursor font-12 text-danger"
                    (click)="openDeleteConfirmDialog()" title="Delete key date"></i>
                <span *ngIf="deleting" class="spinner-border spinner-border-sm font-12 text-danger"
                    role="status"></span>
            </div>
        </div>
    </span>
</div>