<form class="smart-form">
    <fieldset>
        <section *ngIf="loading">
            <loading-indicator></loading-indicator>
        </section>
        <section *ngIf="!loading && assets.length > 0">
            <div style="display: flex;">
                <label style="color: #3276b1">
                  {{order.firstName + ' ' + order.lastName + ' (' + order.last4Ssn +')'}}
                </label>
            </div>
        </section>
        <section *ngIf="!loading && assets.length > 0">
            <table class="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th style="min-width: 200px;">Borrowers</th>
                        <th>Owner Name</th>
                        <th>Account Type</th>
                        <th>Institution</th>
                        <th>Account #</th>
                        <th>Cash/Market Value</th>
                        <th>Import</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let asset of assets">
                        <td>
                            <ng-select width="100%" name="{{asset.accountId}}_borrowers" bindLabel="text" bindValue="id" #borrowers="ngModel"
                                [ngClass]="{ 'is-invalid' : borrowers && borrowers.touched && borrowers.invalid }"
                                class="custom-ng-select" placeholder="Select Borrowers" [closeOnSelect]="false"
                                [(ngModel)]="selectedBorrowers[asset.accountId]" [multiple]="true" [items]="mulstiSelectData" required>
                            </ng-select>
                        </td>
                        <td style="vertical-align: middle;">{{asset.accountOwnerNames}}</td>
                        <td style="vertical-align: middle;">{{asset.accountType}}</td>
                        <td style="vertical-align: middle;">{{asset.depository}}</td>
                        <td style="vertical-align: middle;">{{asset.accountNumber}}</td>
                        <td style="vertical-align: middle;">${{asset.cashMarketValue}}</td>
                        <td style="text-align: center;">
                            <label class="toggle">
                                <input type="checkbox" [(ngModel)]="asset.isChecked"
                                    [ngModelOptions]="{standalone: true}" [checked]="asset.isChecked">
                                <i data-swchon-text="Yes" data-swchoff-text="No"></i>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        <div *ngIf="!loading && assets.length > 0">
            <button (click)="importAssets()" class="btn btn-primary" style="width: 100px; margin: 0 auto;"
                [disabled]="!isImportEnabled() || importingAssets">
                <span *ngIf="!importingAssets">Import</span>
                <span *ngIf="importingAssets" class="spinner-border spinner-border-sm align-middle"
                    role="status"></span>
            </button>
        </div>
        <div *ngIf="!loading && assets.length === 0" style="margin-bottom: 0px;">
            <h4>There are no assets to import.</h4>
        </div>
    </fieldset>
</form>
