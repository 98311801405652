import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { EnumerationItem } from '../../../../../../models/simple-enum-item.model';
import { Branch, ThirdPartyCredential } from '../../../../../../models';
import { formViewProvider } from '../../../../../../core/services/form-view.provider';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { ChannelService } from '../../../../../../services/channel.service';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'credential-modal-basic',
  templateUrl: './credential-modal-basic.component.html',
  styleUrls: ['./credential-modal-basic.component.scss'],
  viewProviders: [formViewProvider],
})
export class CredentialModalBasicComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  credential: ThirdPartyCredential;

  @Input()
  vendors: EnumerationItem[];

  @Input()
  scope: ScopeType;

  @Input()
  supportsChannelConfig: boolean = true;

  @Input()
  vendorSelectionDisabled: boolean = false;

  @Input()
  isLosCredentials: boolean = false;

  @Input()
  disabledChannel: boolean = false;

  @Output()
  onChangeVendor?: EventEmitter<{ vendorName: string, companyCredentialId?: number }> = new EventEmitter<{ vendorName: string, companyCredentialId?: number }>();

  areChannelsEnabled: boolean = false;
  channels: EnumerationItem[] = [];
  selectedChannels: string[];
  selectedVendorId?: number;
  selectedVendor?: string;
  branches: Branch[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _channelService: ChannelService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.vendors) {
      this.vendors = this._thirdPartyCredentialsService.getVendors(this.credential);
      if(this.applicationContext.isCompanyDeepHaven && this.vendors.filter(x=>x.value == "EncompassCredit").length > 0)
      {
        this.vendors = this.vendors.filter(x=>x.value == "EncompassCredit");
      }
    }

    if (this.scope != 'Company' && this.isLosCredentials) {
      if (this.credential.credentialId) {
        const matched = this.vendors.find(v => v.id == this.credential.credentialId);
        if (matched) {
          this.selectedVendorId = matched.id;
        }
      }
    } else {
      if (this.credential.vendorName) {
        const matched = this.vendors.find(v => v.value == this.credential.vendorName);
        if (matched) {
          this.selectedVendor = matched.value;
        }
      }
    }

    if (this.supportsChannelConfig) {
      this.areChannelsEnabled = this.applicationContext.userPermissions.enabledChannels?.length > 0
      if (this.areChannelsEnabled) {
        this.channels = this._channelService.getChannelsListForDropdown(this.applicationContext.userPermissions.enabledChannels);
        this.selectedChannels = this._channelService
          .getChannelsListForDropdown(this.credential.channels)
          .map(channel => channel.name);
      }
    }

    if (this.scope == 'Branch') {
      this.branches = this.applicationContext.globalConfig.branches;
    }
  }

  setCredentialChannels(): void {
    this.credential.channels = this.selectedChannels.length ? this.selectedChannels.join(', ') : '';
  }

  changeVendor = () => {
    if (this.scope !== 'Company' && this.isLosCredentials) {
      const vendor = this.vendors.find(v => v.id == this.selectedVendorId);
      this.credential.vendorName = vendor.value;
      this.onChangeVendor.emit({ vendorName: vendor.value, companyCredentialId: this.selectedVendorId });
    } else {
      this.credential.vendorName =  this.selectedVendor;
      this.onChangeVendor.emit({ vendorName: this.selectedVendor, companyCredentialId: null });
    }
  }
}
