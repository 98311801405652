import { Component, Input, OnInit } from '@angular/core';
import { DocPrep } from '../../models/doc-prep.model';

@Component({
  selector: 'document-preparation-custom-info',
  templateUrl: 'document-preparation-custom-info.component.html',
  styleUrls: ['./document-preparation-custom-info.component.scss']
})

export class DocumentPreparationCustomInfoComponent implements OnInit {

  @Input() docPrep: DocPrep;

  constructor() { }

  ngOnInit() { 
  }
}