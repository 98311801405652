import { ChangeOfCircumstanceDetail } from 'src/app/modules/disclosure-tracking/models/disclosure-tracking.model';
import {
  DisclosureDocumentSet,
  MortgageDisclosureCashToCloseItemType,
} from '.';
import { IntegrationError, NotificationRecipient } from '..';
import { FloodCertificationTypeEnum, MortgageCalculationType, MortgageLanguage } from '../..';

export interface AuditMessage {
  readonly statusCode: string;
  readonly status: string;
  readonly statusDescription: string;
  readonly statusConditionDescription: string;
}

interface CashToCloseItem {
  itemType: MortgageDisclosureCashToCloseItemType;
  estimatedAmount?: number;
  finalAmount?: number;
}

type EscrowPaymentType =
  | 'Assessment'
  | 'CityPropertyTax'
  | 'CountyPropertyTax'
  | 'EarthquakeInsurance'
  | 'FloodInsurance'
  | 'HazardInsurance'
  | 'MortgageInsurance'
  | 'Other'
  | 'SchoolPropertyTax'
  | 'TownPropertyTax'
  | 'USDAAnnualFee'
  | 'VillagePropertyTax'
  | 'WindstormInsurance';

interface EscrowDisclosure {
  currentBalance?: number;
  disbursementMonth?: number;
  disbursementYear?: number;
  paymentType?: EscrowPaymentType;
  paymentTypeOtherDescription: string;
  paymentFromEscrowAccount?: number;
  paymentToEscrowAccount?: number;
}

interface EscrowInformation {
  accountInitialBalance?: number;
  accountMinimumBalance?: number;
  aggregateAccountingAdjustment?: number;
  cushionMonths?: number;
  disclosures?: Array<EscrowDisclosure>;
}

interface FeeSummary {
  apr?: number;
  totalAmountFinanced?: number;
  totalAprFees?: number;
  totalDepositedReserves?: number;
  totalFinanceCharge?: number;
  totalLoanOriginationFees?: number;
  totalNetProceedsForFunding?: number;
  totalNonAprFees?: number;
  totalOfAllPayments?: number;
  totalPaidToOthers?: number;
  totalPrepaidFinanceCharge?: number;
}

interface PaymentScheduleItem {
  paymentAmount?: number;
  paymentsCount?: number;
  startDate?: Date;
}

interface PaymentSchedule {
  items: Array<PaymentScheduleItem>;
}

interface PrepaidFee {
  prepaidItemActualTotalAmount?: number;
  prepaidItemPaidThroughDate?: Date;
  prepaidItemPerDiemAmount?: number;
}

interface DisclosureResponse {
  thirdPartyRecordId?: string;
  thirdPartyAccountId?: string;
  integrationHistoryId?:string;
  disclosureGeneratedDate?:Date;
  appraisalOrderId?: number;
  lateChargeGracePeriodDays?: number;
  lateChargeRate?: number;
  loanMaturityDate?: Date;
  ltv?: number;
  totalPaymentsAndLoanCosts?: number;
  losLoanId?:string;
  callbackToken?:string;
  cashToCloseInformation: Array<CashToCloseItem>;
  feeSummary: FeeSummary;
  prepaids: Array<PrepaidFee>;
  escrowInformation: EscrowInformation;
  paymentSchedule: PaymentSchedule;
  errors: Array<IntegrationError>;
  auditMessages: Array<AuditMessage>;
  documentSets: Array<DisclosureDocumentSet>;
  borrowerLabelsByBorrowerId?: any;
}

export class DisclosureHistory {
  readonly applicationId: number;
  readonly companyId: number;
  readonly dateInserted: string;
  readonly dateUpdated: string;
  disclosureHistoryId: number;
  disclosureResponse: DisclosureResponse;
  readonly insertedBy: string;
  integrationHistoryId: number;
  loanDocId?:number;
  documentSigningOrderId?:number;
  thirdPartyOrderId?: string;
  thirdPartyTransactionId?: string;
  thirdPartyAccountId?: string;
  requestData: DisclosureRequestData;
  readonly updatedBy: string;
}

export interface DisclosureRequestData {
  thirdPartyRecordId?: string;
  applicationId: number;
  estimatedIssueDate?: Date;
  documentSet: string;
  isAudit: boolean;
  loanPlanCode: string;
  preferredLanguage: MortgageLanguage;
  includeLoanAnalysisReports: boolean;
  floodCertificationType: FloodCertificationTypeEnum;
  includeFormFields?: boolean;
  combineResponseDocuments?: boolean;
  shouldSendESignLinkToBorrowers?: boolean;
  sendNotificationToRequestor?: boolean;
  eSignNonBorrowerNotificationRecipients: NotificationRecipient[];
  documentAvailabilityNotificationRecipients: NotificationRecipient[];
  documentRetrievalPassword: string;
  documentRetrievalPasswordHint: string;
  usePrintFulfillment: boolean;
  printFulfillmentDeliverToName?: string;
  printFulfillmentAttentionToName?: string;
  deliveryMethod: DocumentDeliveryMethod;
  deliveryMethodOtherDescription?: string;
  disclosureReason: DisclosureResason;
  disclosureReasonOtherDescription?: string;
  disclosureReasonAdditionalDescription?:string;
  disclosureComments?:string;
  isFeeLevelDisclosure?:boolean;
  hasChangeOfCircumstance?: boolean;
  changesOfCircumstanceReceivedDate?: Date;
  changeOfCircumstanceReason: ChangeOfCircumstanceReasonType;
  submitMersRegistrationRequest: boolean;
  requestedCalculations: MortgageCalculationType[];
  isDocumentPreview: boolean;
  changeOfCircumstanceEntries?: ChangeOfCircumstanceDetail[];
}

export enum MortgageDocumentSet {
  Adverse = "Adverse",
  LoanApplication = "LoanApplication",
  Appraisal = "Appraisal",
  Closing = "Closing",
  ComplianceReview = "ComplianceReview",
  ClosingDisclosure = "ClosingDisclosure",
  FloodCertification = "FloodCertification",
  Predisclosure = "Predisclosure",
  LoanApproval = "LoanApproval",
  LoanEstimate = "LoanEstimate",
  LoanModification = "LoanModification",
  CustomDocumentList = "CustomDocumentList",
  PointOfSale = "PointOfSale",
  PostClosing = "PostClosing",
  Preclosing = "Preclosing",
  Prequalification = "Prequalification",
  Processing = "Processing",
  RateLock = "RateLock",
  Redisclosure = "Redisclosure",
  ServicingTransfer = "ServicingTransfer",
  Underwriting = "Underwriting"
}

export enum DocumentDeliveryMethod{
  CourierDeliveryService = "CourierDeliveryService",
  ElectronicDelivery = "ElectronicDelivery",
  InPerson = "InPerson",
  Other = "Other",
  USPSFirstClassMail = "USPSFirstClassMail"
}

export enum DisclosureResason {
  Item24HourAdvancedPreview = "Item24HourAdvancedPreview",
  AdditionOfPrepaymentPenalty = "AdditionOfPrepaymentPenalty",
  APR = "APR",
  ClericalErrorCorrection = "ClericalErrorCorrection",
  DelayedSettlementDate = "DelayedSettlementDate",
  Eligibility = "Eligibility",
  Expiration = "Expiration",
  InterestRateDependentCharges = "InterestRateDependentCharges",
  LoanProduct = "LoanProduct",
  Other = "Other",
  RevisionsRequestedByBorrower = "RevisionsRequestedByBorrower",
  SettlementCharges = "SettlementCharges",
  ToleranceCure = "ToleranceCure"
}


export enum ChangeOfCircumstanceReasonType {
  LoanAmountChange = "LoanAmountChange",
  LoanTypeChange = "LoanTypeChange",
  LoanProgramChange = "LoanProgramChange",
  BorrowerIncomeNotVerifiedOrVerifiedAtDifferentAmount = "BorrowerIncomeNotVerifiedOrVerifiedAtDifferentAmount",
  AppraisedValueDifferentFromEstimatedValue = "AppraisedValueDifferentFromEstimatedValue",
  AdditionalServiceIsNecessaryBasedOnTitleReport = "AdditionalServiceIsNecessaryBasedOnTitleReport",
  RecordingFeesIncreasedFromUnanticipatedDocuments = "RecordingFeesIncreasedFromUnanticipatedDocuments",
  BorrowerTakingTitleHasChanged = "BorrowerTakingTitleHasChanged",
  BorrowerAddedOrDroppedFromLoan = "BorrowerAddedOrDroppedFromLoan",
  LoanLocked = "LoanLocked",
  LoanLockExtended = "LoanLockExtended",
  ChangeInSettlementFee = "ChangeInSettlementFee",
  ClericalError = "ClericalError",
  Other = "Other"
}
