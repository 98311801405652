<div class='alert alert-danger border-0' role='alert' *ngIf='error'>
  <strong>ERROR:</strong> An error occurred while loading the applications
</div>

<application-table
  *ngIf='!isLoading else loadingApplications'
  [applications]='applications'
  [applicationTableConfig]='applicationTableConfig'
  [autoAdaptHeight]='true'
  (addNewClicked)='onAddNewApplicationModalOpened()'>
</application-table>

<ng-template #loadingApplications>
  <loading-indicator loadingMessage='Loading applications...'>
  </loading-indicator>
</ng-template>
