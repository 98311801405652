import { Component, Input, OnInit } from '@angular/core';
import { isArray, uniq } from 'lodash';
import { combineLatest, finalize } from 'rxjs';
import { ChecklistAnswer } from 'src/app/models/check-list-answer.model';
import { LookupType } from 'src/app/models/question.model';
import { CheckListAnswer, CheckListQuestion, CheckListService, QuestionSummary, SubmittedAnswer } from 'src/app/services/check-list.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'loan-app-checklists',
  templateUrl: './app-checklists.component.html',
  styleUrls: ['./app-checklists.component.scss']
})
export class AppChecklistsComponent implements OnInit {
  @Input() applicationId: number;
  @Input() borrowerNameById: Map<number, string>;
  checklistsForApp: any[];
  isLoading: boolean;
  availableQuestions: QuestionSummary[];
  constructor(
    private readonly _notifyService: NotificationService,
    private readonly _checkListService: CheckListService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    combineLatest([
      this._checkListService.getAllQuestionsWithAnswers(),
      this._checkListService.getChecklistsDetailsByApplicationId(this.applicationId)
    ]).pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: ([allQuestions, checklistWithQuestions]) => {
          this.availableQuestions = allQuestions;
          this.checklistsForApp = [];
          checklistWithQuestions.forEach(checklist => {
            this.checklistsForApp.push({
              id: checklist.id,
              name: checklist.name,
              questions: []
            })
            checklist.questions.forEach(chklistQuestion => {
              this.addQuestionToCheckList(chklistQuestion, checklist.id)
            })
          });

        }, error: err => {
          this._notifyService.showError(err?.message || 'unable to load checklists', "Error!");
        }
      });
  }

  private flatMapAnswerQuestion(question: CheckListQuestion, checklistId: number, borrowerId: number = null): void {
    if (!isArray(question.submittedAnswers) || question.submittedAnswers.length === 0) {
      return;
    }
    question.submittedAnswers
      .filter(a => (!borrowerId && !a.borrowerId) || a.borrowerId == borrowerId)
      .forEach((subAnswer: ChecklistAnswer) => {
        if (!subAnswer.questionAnswerId) {
          return;
        }
        this.findAnswerQuestions(subAnswer.questionAnswerId, question.answers, checklistId)
      })
  }

  private addQuestionToCheckList(question: CheckListQuestion, checklistId: number): void {
    if (!question.isValid) {
      return;
    }
    const matchingCheckList = this.checklistsForApp.find(chklist => chklist.id === checklistId)
    if (!matchingCheckList) {
      return;
    }
    const questionDetails = this.availableQuestions.find(q => q.questionId === question.questionId);
    if (questionDetails.questionType === LookupType.TriggerOnly) {
      return;
    }
    const validForBorrowerIds = this.getBorrowerIdsFromSubmittedAnswers(question.submittedAnswers)
    if (validForBorrowerIds.length > 0) {
      const borrQuestions = validForBorrowerIds.map(borrowerId => ({
        borrowerDisplayName: this.borrowerNameById.get(borrowerId) || '',
        text: questionDetails.questionText,
        type: questionDetails.questionType,
        submittedAnswerText: this.getFormattedAnswerText(question.submittedAnswers.filter(a => a.borrowerId == borrowerId), questionDetails.questionType)
      }))
      matchingCheckList.questions.push(...borrQuestions);
      validForBorrowerIds.forEach(borrowerId => {
        this.flatMapAnswerQuestion(question, checklistId, borrowerId);
      });
      return;
    }
    matchingCheckList.questions.push({
      borrowerDisplayName: null,
      text: questionDetails.questionText,
      type: questionDetails.questionType,
      submittedAnswerText: this.getFormattedAnswerText(
        question.submittedAnswers.filter(a => !a.borrowerId), questionDetails.questionType)
    });
    this.flatMapAnswerQuestion(question, checklistId);
  }

  private getBorrowerIdsFromSubmittedAnswers(submittedAnswers: SubmittedAnswer[]): number[] {
    if (!isArray(submittedAnswers) || submittedAnswers.length === 0) {
      return [];
    }
    return uniq(submittedAnswers.map(a => a.borrowerId));
  }


  private findAnswerQuestions(questionAnswerId: number, answers: Array<CheckListAnswer>, checklistId: number): void {
    const answerQuestions = answers.find(q => q.questionAnswerId === questionAnswerId)?.questions;
    if (!isArray(answerQuestions) || answerQuestions.length === 0) {
      return;
    }
    const additionalQuestions = answerQuestions.filter(q => q.isValid);
    additionalQuestions.forEach(addQuestion => {
      this.addQuestionToCheckList(addQuestion, checklistId);
    });
  }

  private getFormattedAnswerText(
    submittedAnswers: Array<SubmittedAnswer>,
    questionType: LookupType,
    borrowerId: number = null
  ): string {
    if (questionType === LookupType.TriggerOnly || !isArray(submittedAnswers) || submittedAnswers.length === 0) {
      return '';
    }
    if (questionType === LookupType.Value) {
      return submittedAnswers[0].answerText || '';
    }
    if (questionType === LookupType.SelectList) {
      return this.availableQuestions.flatMap(q => q.answers)
        .find(ans => ans.questionAnswerId === submittedAnswers[0].questionAnswerId)?.answerText || '';
    }
    if (questionType === LookupType.MultiSelect) {
      let answerText = [];
      submittedAnswers.forEach(ans => {
        const matchingAnswer = this.availableQuestions.flatMap(q => q.answers)
          .find(qa => qa.questionAnswerId === ans.questionAnswerId);
        answerText.push(matchingAnswer?.answerText || '')
      });
      return answerText.length > 0 ? answerText.join(', ') : '';
    }
  }
}
