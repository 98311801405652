import { NgModule } from "@angular/core";
import { PipelineFilterComponent } from "./pipeline-filter.component";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PipelineFilterComponent
  ],
  exports: [
    PipelineFilterComponent
  ],
  providers: [
  ]
})
export class PipelineFilterModule {
  constructor() {
  }
}


