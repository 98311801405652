<div class="modal-header">
  <h5 class="modal-title w-100">
    {{getTitle()}}
  </h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"
    [disabled]="disabledDialog"></button>
</div>

<div class="modal-body">
  {{getText()}}
</div>

<div class="modal-footer justify-content-end">
  <button type="button" class="btn btn-soft-secondary" (click)="activeModal.dismiss()" [disabled]="disabledDialog">
    <i class="fa fa-close"></i> Cancel
  </button>

  <button type="button" class="btn btn-soft-primary" [disabled]="disabledDialog"
    (click)="acceptAndDialFreeForAllLead()">
    {{model.contactListId === 8 ? 'View' : 'Accept'}}
  </button>
</div>