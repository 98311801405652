<div class="card tpo-report-card">
    <div class="card-header">
        <h4 class="card-title"> <i class="far fa-file-alt"></i> Closing Info </h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.estimatedClosingDate ? (application.estimatedClosingDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">Estimated Closing Date</span>
                <!-- <label for="estimatedClosingDate">Estimated Closing Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="estimatedClosingDate" type="text"
                    disabled> -->
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.closingDate ? (application.closingDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">Closing Date</span>
                <!-- <label for="closingDate">Closing Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="closingDate" type="datetime"
                    disabled> -->
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.lastPaymentDate ? (application.firstPaymentDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">First Payment Date</span>
                <!-- <label for="lastPaymentDate">Last Payment Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="lastPaymentDate" type="text"
                    disabled> -->
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.payoffExpirationDate ? (application.payoffExpirationDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">Payoff Expiration Date</span>
                <!-- <label for="payoffExpirationDate">Payoff Expiration Date</label>
                <input data-mask="99/99/9999" class="form-control" date-picker id="payoffExpirationDate" type="text"
                    disabled> -->
            </div>
            <div class="form-group col-md-4 col-xs-12">
                <h5 class="mt-0 mb-1">{{application.fundingDate ? (application.fundingDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">Funding Date</span>
                <!-- <label for="fundingDate">Funding Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="fundingDate" type="text" disabled> -->
            </div>
        </div>
    </div>
</div>
