import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TasksModule } from 'src/app/modules/tasks/tasks.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoanDocsTableViewComponent } from './components/loan-docs-table-view.component';
import { TreeModule } from 'primeng/tree';
import { LoanDocsSignatureDialogComponent } from '../loan-docs-signature-dialog/loan-docs-signature-dialog.component';
import { ContextMenuModule } from 'ngx-contextmenu';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    TasksModule,
    ButtonModule,
    TreeModule,
    ContextMenuModule
  ],
  declarations: [
    LoanDocsTableViewComponent,
    LoanDocsSignatureDialogComponent
  ],
  exports: [
    LoanDocsTableViewComponent
  ],
  providers: []
})
export class LoanDocsTableViewModule {
  constructor() { }
}
