import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from 'src/app/services/task.service';
import { FileService } from 'src/app/services/file.service';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import { TaskMessage } from 'src/app/models/message/task-message.model';
import * as moment from 'moment';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'escalation-task-dialog',
  templateUrl: './escalation-task-dialog.component.html',
  styleUrls: ['./escalation-task-dialog.component.scss'],
})
export class EscalationTaskDialogComponent implements OnInit {
  task: LoanDocDashboardTask;

  internalTaskMessages: Array<TaskMessage> = [];

  uploadedFiles: Array<File> = [];

  private _autoTransition: boolean = true;
  private _autoConvertToPdf: boolean = false;
  private _useDynamicCompression: boolean = true;

  protected isSaving: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _taskService: TaskService,
    private readonly _fileService: FileService,
    private readonly _messageService: MessageService
  ) {}

  ngOnInit(): void {
    this._messageService
      .getInternalTaskMessages(this.task.loanDocTaskId)
      .subscribe((response) => {
        this.internalTaskMessages = response;
      });
  }

  saveEscalationTask = () => {
    // No need to set this back to false since the modal will be closed.
    this.isSaving = true;

    let docFiles = [];
    this.uploadedFiles.forEach((file) => {
      docFiles.push({
        guid: null,
        file: file,
        fileName: file.name,
        loanDocId: this.task.loanDocId,
        note: '',
        borrowerNote: '',
        active: true,
      });
    });
    this.task.docFiles.concat(docFiles);

    const data = {
      loanDocTask: this.task,
      multipleBorrower: null,
      numFiles: this.task.docFiles.length,
      daysToPostpone: this.task.followUpDate
        ? moment(this.task.followUpDate, 'MM/DD/YYYY').diff(
            moment().startOf('day'),
            'days'
          )
        : 0,
    };
    this._taskService.upsertLoanDocTask(data).pipe(
      switchMap((responseTask) => {
        const results: Observable<boolean>[] = this.task.docFiles
          .reduce((results, file) => {
            let result: Observable<boolean>;
            if (file.guid == null) {
              file.loanDocId = responseTask.loanDocId
                ? responseTask.loanDocId
                : 0;

              result = this._fileService.uploadFileAndSingleModel(
                [file],
                'api/File/UpsertFileFromLoanDoc/' +
                responseTask.loanDocId +
                '?autoTransition=' +
                this._autoTransition +
                '&autoConvertToPdf=' +
                this._autoConvertToPdf +
                '?autoConvertToPdf=false&useDynamicCompression=' +
                this._useDynamicCompression,
                file,
              );
            } else if (file.active === false) {
              result = this._fileService.removeFile(file.guid);
            }

            if (result != null) {
              results.push(result);
            }
            return results;
          }, [] as Observable<boolean>[]);

        const resultObservable = results.length > 0
          ? forkJoin(results).pipe(
            tap((responses) => {
                const numBugs = responses.filter((r) => r == false);
                if (numBugs.length > 0) {
                  console.error(`There are ${numBugs.length} bugs occurred` +
                    ' while uploading files.');
                }
              },
            ),
          )
          : of(null);

        return resultObservable.pipe(
          map(() => responseTask),
        );
      }),
    ).subscribe({
      next: (responseTask) => {
        this.activeModal.close(responseTask);
      },
      error: (err) => {
        console.error('Error saving escalation task', err);
        this.activeModal.close(null);
      }
    });
  };

  onOkClicked = () => {
    this.saveEscalationTask();
  };

  onCancelClicked = () => {
    this.activeModal.close('cancel');
  };
}
