import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LoginCompanyPickerDialogComponent } from 'src/app/components/login-company-picker-dialog/login-company-picker-dialog.component';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { RecentApp, ResourceLink, UserData, UserProfile, WizardUrl } from 'src/app/models';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { ChannelService } from 'src/app/services/channel.service';
import { Constants } from 'src/app/services/constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { copyToClipboard } from 'src/utils';

@Component({
  selector: 'tpo-header',
  templateUrl: './tpo-header.component.html',
  styleUrls: ['./tpo-header.component.scss']
})
export class TpoHeaderComponent extends ApplicationContextBoundComponent implements OnInit {

  currentlyLoggedInUser: UserData;
  currentlyLoggedInUserProfile: UserProfile;
  recentApps: Array<RecentApp> = [];
  resourceLinks: Array<ResourceLink> = [];
  companyLogoUrl: string = "";
  userAvatarUrl: string;
  isNewLeadReferralEnabled: boolean;
  isNewApplicationEnabled: boolean;
  companies: AuthenticationUserCompany[] = [];
  wizardUrls: WizardUrl[] = [];
  currentUserIsPrimaryRoleUser: boolean = false;
  isApprisalDisabled: boolean = false;

  isNewClicked: boolean = false;

  protected isCompanyDeepHaven: boolean = false;

  private _contextChangesSubscription: Subscription;
  private _activatedRouteEventsSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _authService: AuthService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environmentService: EnvironmentService,
    private readonly _modalService: NgbModal,
    private readonly _localStorageService: LocalStorageService,
    private readonly _notifyService: NotificationService,
    private readonly _channelService: ChannelService
  ) {
    super(injector);
    this.isCompanyDeepHaven = this.applicationContext.isCompanyDeepHaven;
    this._contextChangesSubscription = this.applicationContextService.changes.subscribe(context => {
      if (context) {
        this.currentlyLoggedInUser = context.currentlyLoggedInUser;
        this.currentlyLoggedInUserProfile = context.currentlyLoggedInUserProfile.userProfile;
        this.recentApps = context.userPermissions.recentApplications;
      }
    })
  }

  ngOnInit(): void {
    const authData = this._localStorageService.authorizationData;
    this.companies = authData.companies;

    this._activatedRouteEventsSubscription = this._activatedRoute.data.subscribe((data) => {
      this.companyLogoUrl = this._environmentService.apiInfo.apiBaseUrl + `company/br/logo/${this.applicationContext.userPermissions.companyId}`;
      this.currentlyLoggedInUser = this.applicationContext.currentlyLoggedInUser;
      this.currentlyLoggedInUserProfile = this.applicationContext.currentlyLoggedInUserProfile.userProfile;
      this.wizardUrls = this.applicationContext.userPermissions.onlineApplications?.wizardUrls.filter(x => x.isDefault) ?? [];
      this.currentUserIsPrimaryRoleUser = true; // Hack for demo this.applicationContext.globalConfig.firstRole?.roleId == this.applicationContext.userPermissions.roleId;
      this.isNewApplicationEnabled = this.applicationContext.userPermissions.showNewSubmissionForm;
      this.isNewLeadReferralEnabled = this.applicationContext.userPermissions.companyId == 170; // for loda and solar

      if (!this.currentlyLoggedInUser.avatar) {
        this.userAvatarUrl = 'assets/images/male.png';
      } else {
        this.userAvatarUrl = this.currentlyLoggedInUser.avatar;
      }
      // ?? can we look for loan Id here maybe?
    });

    this.recentApps = this.applicationContext.userPermissions.recentApplications;
    this.resourceLinks = new Array<ResourceLink>();

    if (this.applicationContext.isCompanyPRMG) {
      this.resourceLinks.push({ displayText: "Training Materials & Webinar Link", url: "https://eprmg.net/Training/Videos/tpo.html" });
      this.resourceLinks.push({ displayText: "WS Resource Center", url: "https://www.eprmg.net/ResourceCenter/WholesaleRC.pdf" });
      this.resourceLinks.push({ displayText: "WS Product Profiles", url: "https://www.eprmg.net/guidelines/PRMGProductProfileLinksWholesale.pdf" });
      this.resourceLinks.push({ displayText: "Request System Access", url: "https://www.prmg.net/online-sign-up-request" });
      this.resourceLinks.push({ displayText: "Subscribe to Rate Sheet", url: "https://www.prmg.net/rate-sheet-form/" });
      this.resourceLinks.push({ displayText: "VA Payment", url: "https://leo.prmg.net/tpo/va-payment" });
    }
    else if (this.applicationContext.isCompanyOpenWholesale) {
      this.resourceLinks.push({ displayText: "Resource Center", url: "https://www.openwholesale.com/resource-center" });
    }
    else if(this.applicationContext.isCompanyDeepHaven){
      this.resourceLinks.push({ displayText: "Resource Center", url: "https://deephavenmortgage.com/thehavenwholesaleresources/" });
    }

    if (this.applicationContext && this.applicationContext.userPermissions.enabledChannels !== "Disabled") {
      const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this.applicationContext.userPermissions.enabledChannels);
      this.isApprisalDisabled = enabledChannels.some(e => ["Correspondent","NonDelegatedCorrespondent"].includes(e.name));
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._contextChangesSubscription?.unsubscribe();
    this._activatedRouteEventsSubscription?.unsubscribe();
  }

  onSwitchCompanyClicked = () => {
    const modalRef = this._modalService.open(
      LoginCompanyPickerDialogComponent,
      Constants.modalOptions.medium
    );
    modalRef.componentInstance.availableCompanies = this.companies;
  };

  copyLink = (wizardUrl: WizardUrl) => {
    copyToClipboard(wizardUrl.url);
    this._notifyService.showInfo("Link copied to clipboard", "Info");
  }

  onLogoutClicked = () => {
    this._spinner.show();
    this._authService.logout().subscribe(() => {
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
    });
  };

  onNewClicked = () => {
    this.isNewClicked = !this.isNewClicked;
  }

  onNewApplicationClicked = () => {
    this.isNewClicked = false;
  }

  onNewLeadReferralClicked = () => {
    this.isNewClicked = false;
  }
}


