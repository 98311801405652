import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileModule } from '../profile/profile.module';
import { AlignmentComponent } from './components/alignment.component';
import { UpsertAlignmentModalComponent } from './components/upsert-alignment-modal/upsert-alignment-modal.component';
import { ReassignAlignmentUsersDialogComponent } from './components/reassign-alignment-users-dialog/reassign-alignment-users-dialog.component';
import { AlignmentService } from './services/alignment.service';

@NgModule({
    imports: [
        SharedModule,
        ProfileModule,
        TableModule,
    ],
    declarations: [
        AlignmentComponent,
        UpsertAlignmentModalComponent,
        ReassignAlignmentUsersDialogComponent
    ],
    exports: [
        AlignmentComponent,
    ],
    providers: [
        AlignmentService
    ]
})
export class AlignmentModule {
  constructor() { }
}
