<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-file-alt me-2"></i>
          ATR / QM Management
        </h4>
      </div>
      <div class="card-body">
        <loading-indicator *ngIf="!mortgage"
          [loadingMessage]="'Loading atr / qm management, please wait...'">
        </loading-indicator>
        <div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
          *ngIf="mortgage">
          <div class="button-items">
            <div class="float-end">
              <button type="button" class="btn btn-primary me-1" (click)="onSaveClicked()">
                <i class="fas fa-save me-1"></i>
                Save
              </button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="mortgage">
          <div class="col-xl-12">
            <apr-apor
              [regulationZDetail]="mortgage.regulationZDetail"
              [mortgageTerm]="mortgage.mortgageTerm"
              [qualifyingRateTypes]="qualifyingRateTypes"
              [qualifiedMortgageTemporaryGSETypes]="qualifiedMortgageTemporaryGSETypes">
            </apr-apor>
          </div>
          <div class="col-xl-12">
            <points-and-fees
              [regulationZDetail]="mortgage.regulationZDetail">
            </points-and-fees>
          </div>
          <div class="col-xl-12">
            <atr-qm-recommendation
              [regulationZDetail]="mortgage.regulationZDetail"
              [abilityToRepayMethodTypes]="abilityToRepayMethodTypes"
              [qualifiedMortgageTypes]="qualifiedMortgageTypes"
              [presumptionOfComplianceTypes]="presumptionOfComplianceTypes">
            </atr-qm-recommendation>
          </div>
          <div class="col-xl-12">
            <atr-qm-exemption-eligibility
              [regulationZDetail]="mortgage.regulationZDetail"
              [abilityToRepayExemptionCreditorOrganizationTypes]="abilityToRepayExemptionCreditorOrganizationTypes"
              [abilityToRepayExemptionLoanProgramTypes]="abilityToRepayExemptionLoanProgramTypes">
            </atr-qm-exemption-eligibility>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>