<div class="flex-grow-1">
  <div *ngIf="error" class="card" style="height:90vh; width: 100%;">
    <div class="card-body">
      <div class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
        <div class="media">
          <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
          <div class="media-body align-self-center">
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center h-100 my-auto" *ngIf="preparingForPricing">
    <div class="d-flex">
      <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
      <h5 *ngIf="loanSyncAttempts <= 1" class="font-18 text-muted me-4">
        {{isQuickPricer ? 'Loading' : 'Preparing your loan' }}, please wait...
      </h5>
      <h5 *ngIf="loanSyncAttempts > 1" class="font-18 text-muted me-4">
        Retry attempt {{loanSyncAttempts - 1}}. Preparing your loan, please wait...
      </h5>
    </div>
  </div>

  <div style="height:90vh; width: 100%;" *ngIf="lenderPricePricerUrl">
    <iframe sandbox="allow-same-origin allow-forms allow-scripts"
      [src]="lenderPricePricerUrl" width="100%" height="100%" #iFrame>
    </iframe>
  </div>
</div>