<div class="row">
  <div class="card-body">
    <table class="table mb-0">
      <thead>
        <tr>
          <th class="w-33 table-header"> Household Expenses </th>
          <th class="table-header"> Mo. Payment(s) </th>
          <th class="table-header"> Balance(s) </th>
          <th class="w-50 table-header"> Payoff(s) </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="proposedHousingExpenses">
          <td>
            <span> {{proposedHousingExpenses.description}} </span>
          </td>
          <td>
            <span> {{proposedHousingExpenses.monthlyPayment | currency}} </span>
          </td>
          <td> --
          </td>
          <td class="d-flex justify-content-between"> --
          </td>
        </tr>
        <tr class="bg-striped">
          <td>
            <span [ngClass]="{'fw-bold': showMortgagesExpenses}"> {{mortgageLoansExpenses.description}} </span>
            <i (click)="showMortgagesExpenses = !showMortgagesExpenses" title="Show the Detail"
              class="text-muted cursor ms-1 fas"
              [ngClass]="{'fa-eye': !showMortgagesExpenses, 'fa-eye-slash': showMortgagesExpenses}"></i>
          </td>
          <td>
            <span [ngClass]="{'fw-bold': showMortgagesExpenses}">
              {{mortgageLoansExpenses.monthlyPayment |
              currency}}
            </span>
          </td>
          <td>
            <span class="mt-1" [ngClass]="{'fw-bold': showMortgagesExpenses}">{{mortgageLoansExpenses.unpaidBalance |
              currency}}
            </span>
          </td>
          <td class="d-flex justify-content-between">
            <span class="mt-1" [ngClass]="{'fw-bold': showMortgagesExpenses}">{{mortgageLoansExpenses.payoffBalance |
              currency}}
            </span>
            <button class="btn btn-sm btn-soft-primary"
              (click)="onAddLiabilityClicked(mortgageLoansExpenses.description)"> Add
              Liability </button>
          </td>
        </tr>
        <ng-container *ngIf="showMortgagesExpenses">
          <tr>
            <td colspan="4">
              <liabilities-in-detail #mortgageDetails [liabilityTypeOptions]="mortgageLiabilityTypes"
                [mortgage]="mortgage" (liabilitiesChanged)="refresh()"></liabilities-in-detail>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>
            <span [ngClass]="{'fw-bold': showInstallmentLoansExpenses}"> {{installmentLoansExpenses.description}}
            </span>
            <i (click)="showInstallmentLoansExpenses = !showInstallmentLoansExpenses" title="Show the Detail"
              class="text-muted cursor ms-1 fas"
              [ngClass]="{'fa-eye': !showInstallmentLoansExpenses, 'fa-eye-slash': showInstallmentLoansExpenses}"></i>
          </td>
          <td>
            <span [ngClass]="{'fw-bold': showInstallmentLoansExpenses}">
              {{installmentLoansExpenses.monthlyPayment | currency}}
            </span>
          </td>
          <td>
            <span class="mt-1" [ngClass]="{'fw-bold': showInstallmentLoansExpenses}">
              {{installmentLoansExpenses.unpaidBalance | currency}}
            </span>
          </td>
          <td class="d-flex justify-content-between">
            <span class="mt-1" [ngClass]="{'fw-bold': showInstallmentLoansExpenses}">
              {{installmentLoansExpenses.payoffBalance | currency}}
            </span>
            <button class="btn btn-sm btn-soft-primary"
              (click)="onAddLiabilityClicked(installmentLoansExpenses.description)"> Add Liability </button>
          </td>
        </tr>
        <ng-container *ngIf="showInstallmentLoansExpenses">
          <tr>
            <td colspan="4">
              <liabilities-in-detail #installmentDetails [liabilityTypeOptions]="installmentLiabilityTypes"
                [mortgage]="mortgage" (liabilitiesChanged)="refresh()"></liabilities-in-detail>
            </td>
          </tr>
        </ng-container>
        <tr class="bg-striped">
          <td>
            <span [ngClass]="{'fw-bold': showRevolvingExpenses}"> {{revolvingLoansExpenses.description}}
            </span>
            <i (click)="showRevolvingExpenses = !showRevolvingExpenses" title="Show the Detail"
              class="text-muted cursor ms-1 fas"
              [ngClass]="{'fa-eye': !showRevolvingExpenses, 'fa-eye-slash': showRevolvingExpenses}"></i>
          </td>
          <td>
            <span [ngClass]="{'fw-bold': showRevolvingExpenses}">
              {{revolvingLoansExpenses.monthlyPayment |currency}}
            </span>
          </td>
          <td>
            <span [ngClass]="{'fw-bold': showRevolvingExpenses}">
              {{revolvingLoansExpenses.unpaidBalance |currency}}
            </span>
          </td>
          <td class="d-flex justify-content-between">
            <span class="mt-1" [ngClass]="{'fw-bold': showRevolvingExpenses}">
              {{revolvingLoansExpenses.payoffBalance | currency}}
            </span>
            <button class="btn btn-sm btn-soft-primary"
              (click)="onAddLiabilityClicked(revolvingLoansExpenses.description)"> Add
              Liability </button>
          </td>
        </tr>
        <ng-container *ngIf="showRevolvingExpenses">
          <tr>
            <td colspan="4">
              <liabilities-in-detail #revolvingDetails [liabilityTypeOptions]="revolvingLiabilityTypes"
                [mortgage]="mortgage" (liabilitiesChanged)="refresh()"></liabilities-in-detail>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>
            <span class="text-danger" [ngClass]="{'fw-bold': showCollectionExpenses}">
              {{collectionExpenses.description}} </span>
            <i (click)="showCollectionExpenses = !showCollectionExpenses" title="Show the Detail"
              class="text-muted cursor ms-1 fas"
              [ngClass]="{'fa-eye': !showCollectionExpenses, 'fa-eye-slash': showCollectionExpenses}"></i>
          </td>
          <td class="text-danger">
            <span [ngClass]="{'fw-bold': showCollectionExpenses}"> --
            </span>
          </td>
          <td class="text-danger">
            <span class="mt-1">
              {{collectionExpenses.unpaidBalance | currency}}
            </span>
          </td>
          <td class="d-flex justify-content-between">
            <span class="mt-1">
              {{collectionExpenses.payoffBalance | currency}}
            </span>
            <button class="btn btn-sm btn-soft-primary" (click)="onAddLiabilityClicked(collectionExpenses.description)">
              Add Liability </button>
          </td>
        </tr>
        <ng-container *ngIf="showCollectionExpenses">
          <tr>
            <td colspan="4">
              <liabilities-in-detail #collectionDetails [liabilityTypeOptions]="collectionLiabilityTypes"
                [mortgage]="mortgage" (liabilitiesChanged)="refresh()"></liabilities-in-detail>
            </td>
          </tr>
        </ng-container>
        <tr class="bg-striped">
          <td>
            <span [ngClass]="{'fw-bold': showOtherExpenses}"> {{otherExpenses.description}} </span>
            <i (click)="showOtherExpenses = !showOtherExpenses" title="Show the Detail"
              class="text-muted cursor ms-1 fas"
              [ngClass]="{'fa-eye': !showOtherExpenses, 'fa-eye-slash': showOtherExpenses}"></i>
          </td>
          <td>
            <span [ngClass]="{'fw-bold': showOtherExpenses}">
              {{otherExpenses.monthlyPayment | currency}}
            </span>
          </td>
          <td>
            <span class="mt-1" [ngClass]="{'fw-bold': showOtherExpenses}">
              {{otherExpenses.unpaidBalance | currency}}
            </span>
          </td>
          <td class="d-flex justify-content-between">
            <span class="mt-1" [ngClass]="{'fw-bold': showOtherExpenses}">
              {{otherExpenses.payoffBalance | currency}}
            </span>
            <button class="btn btn-sm btn-soft-primary" (click)="onAddLiabilityClicked(otherExpenses.description)"> Add
              Liability </button>
          </td>
        </tr>
        <ng-container *ngIf="showOtherExpenses">
          <tr>
            <td colspan="4">
              <liabilities-in-detail #otherLiabilities [liabilityTypeOptions]="otherLiabilityTypes"
                [mortgage]="mortgage" (liabilitiesChanged)="refresh()"></liabilities-in-detail>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td class="text-end">
            <span class="fw-bold me-2"> {{totalExpenses.description}} </span>
          </td>
          <td> 
            <span class="fw-bold"> {{(totalExpenses.monthlyPayment + totalExpenses.payoffPayment) | currency}} </span>
          </td>
          <td colspan="2">
            <span class="fw-bold"> {{(totalExpenses.unpaidBalance + totalExpenses.payoffBalance) | currency}} </span>
          </td>
        </tr>
        <tr class="bg-striped">
          <td class="text-end">
            <span class="fw-bold me-2"> Less Payoffs </span>
          </td>
          <td>
            <span class="fw-bold text-success"> ({{totalExpenses.payoffPayment | currency}}) </span>
          </td>
          <td colspan="2">
            <span class="fw-bold text-success">
              ({{totalExpenses.payoffBalance | currency}}) </span>
          </td>
        </tr>
        <tr>
          <td class="text-end">
            <span class="fw-bold me-2"> Post Closing </span>
          </td>
          <td> 
            <span class="fw-bold"> {{totalExpenses.monthlyPayment | currency}} </span>
          </td>
          <td colspan="2">
            <span class="fw-bold"> {{totalExpenses.unpaidBalance |
              currency}} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>