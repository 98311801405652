import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanApplication } from 'src/app/models';
import { GlobalConfig, TaskCategory } from 'src/app/models/config/global-config.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ChannelService } from 'src/app/services/channel.service';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { CharacteristicMergeValue, LoanCharacteristic } from 'src/app/models/characteristics';

import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CharacteristicsQuantitiesDialogComponent } from '../characteristics-quantities-dialog/characteristics-quantities-dialog.component';
import { CharacteristicsPreviewDialogComponent } from '../characteristics-preview-dialog/characteristics-preview.dialog.component';
import { LoanCharacteristicFactory } from '../characteristics-quantities-dialog/loan-characteristic.factory';
import { LoanCharacteristicsHistoryDialogComponent } from '../loan-characteristics-history-dialog/loan-characteristics-history-dialog.component';

@Component({
  selector: 'loan-characteristics-card',
  templateUrl: 'loan-characteristics-card.component.html',
  styleUrls: ['loan-characteristics-card.component.scss']
})
export class LoanCharacteristicsCardComponent implements OnInit, OnChanges {

  @Output() taskCategoriesChanged = new EventEmitter<TaskCategory[]>();

  @Input()
  application: LoanApplication;

  @Input()
  loanCharacteristics: LoanCharacteristic[] = [];

  @Output()
  loanCharacteristicsSaved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  isPullingHistory: boolean = false;

  taskCategories: TaskCategory[] = [];

  isSavingCharacteristics: boolean = false;

  thereAreChanges: boolean = false;

  id: string;

  private _initialCharacteristics: LoanCharacteristic[] = [];

  private _nextCharacteristicId: number = -1;
  private _hasBeenInitializedOnce: boolean = false;
  private _globalConfig: GlobalConfig;

  constructor(private readonly _applicationContextService: ApplicationContextService,
    private readonly _loanService: LoanService,
    private readonly _channelService: ChannelService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal) {
    this.id = uuidv4();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._hasBeenInitializedOnce) {
      // Re-initialize on change...
      this.initialize();
    }
  }

  ngOnInit() {
    this._hasBeenInitializedOnce = true;
    this._applicationContextService.context.subscribe(context => {
      this._globalConfig = context.globalConfig;
      this.initialize();
    });
  }

  onViewHistoryClicked = () => {
    this._spinner.show();
    this._loanService.getLoanCharacteristicsHistory(this.application.applicationId).subscribe(history => {
      const modalRef = this._modalService.open(LoanCharacteristicsHistoryDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.history = history;
    }, error => {
      this._notificationService.showError(
        error ? error.message : 'Unable to load loan characteristics history.',
        'Error - Loan Characteristics!'
      );
    }).add(() => this._spinner.hide());
  }

  onCharacteristicCheckChanged = (characteristicId: number) => {
    let index = [];
    for (let i = 0; i < this.loanCharacteristics.length; i++) {
      if (this.loanCharacteristics[i].characteristicId === characteristicId) {
        index.push(i);
      }
    }
    if (index.length === 0) {
      const newOne = new LoanCharacteristic(this._nextCharacteristicId--, this.application.applicationId, characteristicId);
      this.loanCharacteristics.push(newOne);

      if (this.taskCategories.filter(x => x.taskCategoryId == characteristicId)[0].taskOption === 'RequestQuantity')
        this.openEditCharacteristicDialog(characteristicId, true);
    }
    else {
      index.forEach(i => {
        this.loanCharacteristics[i].pendingDeletion = this.loanCharacteristics[i].pendingDeletion === true ? false : true;
      });
    }
    this.thereAreChanges = !_.isEqual(this.loanCharacteristics, this._initialCharacteristics);
  }

  onSaveClicked = () => {
    this.isSavingCharacteristics = true;
    const onesToSave = this.loanCharacteristics.filter(c => !c.pendingDeletion);
    this._loanService.saveLoanCharacteristicsPreview(this.application.applicationId, onesToSave).subscribe((loanDocTasks: LoanDocTask[]) => {
      this.publishSavedTaskCategories();
      if (loanDocTasks && loanDocTasks.length > 0) {
        this.showCharacteristicsPreviewDialog(onesToSave, loanDocTasks);
      } else {
        this.saveLoanCharacteristics(onesToSave, []);
      }
    }, error => {
      this.isSavingCharacteristics = false;
      this._notificationService.showError(
        error ? error.message : 'Unable to save loan characteristics preview.',
        'Error - Loan Characteristics!'
      );
    });
  }

  onCancel() {
    if (this.thereAreChanges) {
      this.showUnsavedChangesDialog();
      return;
    }
    this.onClose.emit();
  }

  private showUnsavedChangesDialog() {
    Swal.fire({
      title: 'Unsaved Changes?',
      text: 'Do you want to discard unsaved changes?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this.onClose.emit();
    });
  }

  publishSavedTaskCategories = () => {
    let selectedCategories = this.taskCategories.filter(c => this.isCharacteristicCheckedForLoan(c.taskCategoryId));
    this.taskCategoriesChanged.emit(selectedCategories);
  }

  openEditCharacteristicDialog = (characteristicId: number, addingForTheFirstTime: boolean) => {
    const category = this.taskCategories.find(c => c.taskCategoryId == characteristicId);
    let existingCharacteristics = this.loanCharacteristics.filter(x => x.characteristicId == characteristicId);
    if (existingCharacteristics.length === 0) {
      let newOne = new LoanCharacteristic(this._nextCharacteristicId--, this.application.applicationId, characteristicId);
      this.loanCharacteristics.push(newOne);
      existingCharacteristics = this.loanCharacteristics.filter(x => x.characteristicId == characteristicId);
      addingForTheFirstTime = true;
    }
    existingCharacteristics.forEach(c => {
      if (!c.characteristicMergeValues || c.characteristicMergeValues.length === 0) {
        c.characteristicMergeValues = [];
        category.fields.forEach(f => {
          c.characteristicMergeValues.push(new CharacteristicMergeValue(f.characteristicFieldId));
        });
      }
    });

    const modalRef = this._modalService.open(CharacteristicsQuantitiesDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.taskCategory = this.taskCategories.find(c => c.taskCategoryId === characteristicId);
    modalRef.componentInstance.nextCharacteristicId = this._nextCharacteristicId;
    modalRef.componentInstance.characteristics = existingCharacteristics;
    modalRef.componentInstance.characteristicFactory = new LoanCharacteristicFactory();
    modalRef.componentInstance.applicationId = this.application.applicationId;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        let remaining = this.loanCharacteristics.filter(x => x.characteristicId !== characteristicId);
        if (result != undefined) {
          result.forEach(characteristic => {
            remaining.push(characteristic);
          });
        }
        this.loanCharacteristics = remaining;
        this.thereAreChanges = !_.isEqual(this.loanCharacteristics, this._initialCharacteristics);
      } else {
        if (addingForTheFirstTime) {
          this.loanCharacteristics = this.loanCharacteristics.filter(c => c.characteristicId !== characteristicId);
        }
      }
    }, (err) => {
      if (addingForTheFirstTime) {
        this.loanCharacteristics = this.loanCharacteristics.filter(c => c.characteristicId !== characteristicId);
      }
    });
  }

  isCharacteristicCheckedForLoan = (characteristicId: number): boolean => {
    const quantity = this.getCharacteristicQuantity(characteristicId);
    return quantity > 0;
  }

  getCharacteristicQuantity = (characteristicId: number): number => {
    var bcs = this.loanCharacteristics.filter(c => c.characteristicId == characteristicId && !c.pendingDeletion);
    if (bcs && bcs.length > 0) {
      return bcs.length;
    }
    return 0;
  }

  private initialize = () => {
    this.taskCategories = this._globalConfig.taskCategory.filter(t => t.characteristicType === 'LoanCharacteristic');
    this.filterCategoriesByChannel();
    this._initialCharacteristics = _.cloneDeep(this.loanCharacteristics);
    this.publishSavedTaskCategories();
  }

  private showCharacteristicsPreviewDialog = (characteristicsToSave: LoanCharacteristic[], tasks: LoanDocTask[]) => {
    this.isSavingCharacteristics = false;
    const modalRef = this._modalService.open(CharacteristicsPreviewDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.characteristicType = 2;
    modalRef.componentInstance.tasksPreview = tasks;
    modalRef.componentInstance.taskCategories = this.taskCategories;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.saveLoanCharacteristics(characteristicsToSave, result);
      } else {
        this._notificationService.showWarning(
          'Borrower characteristics save operation has been cancelled.',
          'Warning!'
        );
      }
    }, (err) => {
      this._notificationService.showWarning(
        'Borrower characteristics save operation has been cancelled.',
        'Warning!'
      );
    });
  }

  private saveLoanCharacteristics = (characteristics: LoanCharacteristic[], tasks: LoanDocTask[]) => {
    this._loanService.saveLoanCharacteristics(this.application.applicationId, characteristics, tasks).subscribe(result => {
      this.isSavingCharacteristics = false;
      this.thereAreChanges = false;
      this.loanCharacteristics = result;
      this._initialCharacteristics = _.cloneDeep(this.loanCharacteristics);
      this._notificationService.showSuccess(
        'Successfully saved loan characteristics!',
        'Success!'
      );
      this.loanCharacteristicsSaved.emit();
    }, error => {
      this.isSavingCharacteristics = false;
      this._notificationService.showError(
        error ? error.message : 'Unable to save loan characteristics.',
        'Error - Loan Characteristics!'
      );
    });
  }

  private filterCategoriesByChannel = () => {
    let channel = this.getChannelForFiltering(this.application);
    if (channel) {
      this.taskCategories = this.taskCategories.filter(c => this.checkIfChannelEnabledForCategory(channel, c));
    }
  }

  private checkIfChannelEnabledForCategory = (channel: string, category: TaskCategory): boolean => {
    const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(category.enabledChannels);
    if (enabledChannels && enabledChannels.length > 0) {
      const channelFoundAmongEnabledOnes = enabledChannels.find(c => c.name === channel);
      return channelFoundAmongEnabledOnes != null;
    }
    return false;
  }

  private getChannelForFiltering = (application: LoanApplication): string => {
    let channel = application.channel;
    if (channel) {
      return channel;
    }
    const company = this._globalConfig.company.find(c => c.companyId = this.application.companyId);
    if (company) {
      const channels = this._channelService.getChannelsFromCommaDelimitedString(company.enabledChannels);
      if (channels.length > 0) {
        channel = channels[0].name;
      }
    }
    return channel;
  }
}
