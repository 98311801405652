<div style="text-align:center">
  <div class="d-flex ms-3" *ngIf="countingDownBeforeRecording && !error">
    <h4>Recording will start in: </h4>
    <h2 class="text-danger mt-1 ms-2">{{counter}}</h2>
  </div>

  <div class="d-flex ms-3" *ngIf="isRecording && !error">
    <h4>Recording now... </h4>
  </div>

  <div *ngIf="error" class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
    <div class="media">
      <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
      <div class="media-body">
        <div>
          <span>Unable to create a video recording session.</span>
        </div>
        <div class="mt-3" style="text-align: left !important;">
          <span><strong>Error Details:</strong> {{error}}</span>
        </div>
      </div>
    </div>
  </div>

  <video [hidden]="inPreviewMode" class="video mb-1" style="width:100% !important;" #video autoplay controls
    onloadstart="this.volume=0"></video>
  <video [hidden]="!inPreviewMode" class="video mb-1" style="width:100% !important;" #recordedVideo controls
    onloadstart="this.volume=0.5"></video>
  <button class="btn btn-primary btn-lg" *ngIf="countingDownBeforeRecording" (click)="onCancelClicked()">Cancel</button>
  <button class="btn btn-primary btn-lg me-1" *ngIf="inPreviewMode" (click)="onVideoSelectedForUse()">
    <span><i class="fa fa-check me-2"></i> Use This</span></button>
  <button class="btn btn-primary btn-lg" *ngIf="inPreviewMode" (click)="goBackToRecording()">
    <span><i class="fa fa-rotate-right me-2"></i> Start Over</span>
  </button>
  <button class="btn btn-primary btn-lg"
    *ngIf="!inPreviewMode && !isRecording && !countingDownBeforeRecording && loadComplete"
    (click)="onStartRecordingCicked()">
    <span><i class="fa fa-video me-2"></i> Start Recording</span>
  </button>
  <button class="btn btn-warning btn-lg" *ngIf="!inPreviewMode && isRecording" (click)="stopRecording()">
    <span><i class="fa fa-stop me-2"></i> Stop Recording</span>
  </button>
</div>
