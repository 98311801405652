<form #emailServiceForm="ngForm">
  <div class="fieldset-title">Email Server Settings</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Server Type</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" #serviceType="ngModel" name="serviceType" [ngClass]="{
            'is-invalid':
              serviceType && serviceType.touched && serviceType.invalid
          }" [(ngModel)]="emailService.serviceType" required>
          <option value="">-- Select One --</option>
          <option value="Smtp">SMTP</option>
          <option value="ExchangeWebServices">Exchange</option>
          <option value="SendGrid">Lodasoft</option>
          <option value="ExchangeMicrosoftGraph">
            Exchange (Azure/Modern Authentication)
          </option>
          <option value="AmazonSes" *ngIf="type === 'system-level'">Amazon SES (Simple Email Service)</option>
        </select>
        <div class="invalid-feedback">Please select server type.</div>
      </div>
    </div>
    <div class="row mb-3"
      *ngIf="emailService.serviceType !== 'ExchangeMicrosoftGraph' && emailService.serviceType !== 'AmazonSes'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">User</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="User Name" autocomplete="new-password" name="userName"
          #userName="ngModel" [ngClass]="{
            'is-invalid': userName && userName.touched && userName.invalid
          }" [(ngModel)]="emailService.userName" required />
        <div class="invalid-feedback">Please enter the user name.</div>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Password</label>
      </div>
      <div class="col-md-4">
        <input type="password" class="form-control" placeholder="User Password" name="password"
          autocomplete="new-password" #password="ngModel" [ngClass]="{
            'is-invalid': password && password.touched && password.invalid
          }" [(ngModel)]="emailService.password" required />
        <div class="invalid-feedback">Please enter the user password.</div>
      </div>
      <div class="offset-2 col-md-2 mt-2 text-end" *ngIf="emailService.serviceType == 'Smtp'">
        <div class="form-check text-start pt-2">
          <input class="form-check-input" type="checkbox" name="isSecure" id="isSecure"
            [(ngModel)]="emailService.isSecure" />
          <label class="form-check-label" for="isSecure">HTTPS</label>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="emailService.serviceType == 'Smtp'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Server</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Server name/ip" #server="ngModel" name="server" [ngClass]="{
            'is-invalid': server && server.touched && server.invalid
          }" [(ngModel)]="emailService.server" required />
        <div class="invalid-feedback">Please enter the server name/ip.</div>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Port</label>
      </div>
      <div class="col-md-2">
        <input type="text" class="form-control" placeholder="Port Number" #port="ngModel"
          [ngClass]="{ 'is-invalid': port && port.touched && port.invalid }" name="port" [(ngModel)]="emailService.port"
          required />
        <div class="invalid-feedback">Please enter the port number.</div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="emailService.serviceType == 'ExchangeWebServices'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Service Url</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control mb-1" placeholder="Exchange web services url" #servicesUrl="ngModel"
          [ngClass]="{
            'is-invalid':
              servicesUrl && servicesUrl.touched && servicesUrl.invalid
          }" [(ngModel)]="emailService.servicesUrl" name="servicesUrl" required />
        <div class="invalid-feedback ms-1">Please enter the services url.</div>
        <a href="javascript: void(0)" class="ms-1" (click)="autoDiscoverExchangeUrl()">
          <span>Auto Discover Service Url</span>
        </a>
      </div>
    </div>
    <div class="row mb-3" *ngIf="autoDiscoverSuccess === true || autoDiscoverSuccess === false">
      <div class="col-md-2 text-end"></div>
      <div class="col-md-8">
        <div *ngIf="autoDiscoverSuccess === true" class="alert alert-success">
          <span><i class="fa fa-check-circle"></i>Exchange UrlDiscovered
            Successfully.</span>
        </div>
        <div *ngIf="autoDiscoverSuccess === false" class="alert alert-danger">
          <span><i class="fa fa-times-circle"></i>{{ autoDiscoverErrorMessage }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="sendTestEmailSuccess === true || sendTestEmailSuccess === false">
      <div class="col-md-2 text-end"></div>
      <div class="col-md-8">
        <div *ngIf="sendTestEmailSuccess === true" class="alert alert-success">
          <span><i class="fa fa-check-circle"></i>&nbsp;Test Email Sent
            Successfully.</span>
        </div>
        <div *ngIf="sendTestEmailSuccess === false" class="alert alert-danger">
          <span><i class="fa fa-times-circle"></i>&nbsp;{{
            sendTestEmailErrorMessage
            }}</span>
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="emailService.serviceType === 'ExchangeMicrosoftGraph'">
      <ng-container *ngIf="tenantKvp?.value">
        <div class="col-md-2 mt-3 text-end">
          <label class="custom-control-label">Company-wide Default Email Sender</label>
        </div>

        <div class="col-md-4 mt-3" *ngIf="users">
          <p-dropdown *ngIf="emailServiceUserNameToDisplay"
              id="emailServiceUserNameToDisplay"
              name="emailServiceUserNameToDisplay"
              [options]="users"
              [(ngModel)]="emailServiceUserNameToDisplay"
              (ngModelChange)="handleUserChange(emailServiceUserNameToDisplay)"
              [showClear]="true"
              [filter]="true"
              optionLabel="displayName" optionValue="lowerCaseEmail"
              placeholder="-- Select One --">
          </p-dropdown>

          <p-dropdown *ngIf="!emailServiceUserNameToDisplay"
              id="userNameToDisplay"
              name="userNameToDisplay"
              [options]="users"
              [(ngModel)]="userNameToDisplay"
              (ngModelChange)="handleUserChange(userNameToDisplay)"
              [showClear]="true"
              [filter]="true"
              optionLabel="displayName" optionValue="lowerCaseEmail"
              placeholder="-- Select One --">
          </p-dropdown>
        </div>

        <div class="col-md-6"></div>
        <div class="col-md-2 mt-3 text-end">
          <label class="custom-control-label">Tenant ID</label>
        </div>
        <div class="col-md-4 mt-3">
          <input type="text" class="form-control" placeholder="Tenant ID" name="tenantId" [(ngModel)]="tenantKvp.value"
            disabled />
        </div>
        <div class="col-md-6"></div>
      </ng-container>

      <ng-container>
        <div class="col-md-4 offset-2 mt-3">
          <button class="btn btn-outline-primary" (click)="consentEmailImpersonation()">
            Link New Microsoft Account
          </button>
        </div>
      </ng-container>
    </div>

    <div class="row mb-3" *ngIf="emailService.serviceType === 'AmazonSes'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Default From Email<i class="fa fa-info-circle text-primary ms-1"
            placement="top" ngbTooltip="Please ensure that this email address has been verified."></i></label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Enter verified email address" autocomplete="off"
          name="defaultEmailAddress" #defaultEmail="ngModel" [ngClass]="{
            'is-invalid': defaultEmail && defaultEmail.touched && defaultEmail.invalid
          }" [(ngModel)]="emailService.userName" required email />
        <div class="invalid-feedback">Please enter verified email address.</div>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Application ID</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Application ID" #applicationId="ngModel"
          [ngClass]="{'is-invalid': applicationId && applicationId.touched && applicationId.invalid }"
          name="applicationId" [(ngModel)]="amazonWebServices.userName" required />
        <div class="invalid-feedback">Please enter application id.</div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="emailService.serviceType === 'AmazonSes'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Application Secret</label>
      </div>
      <div class="col-md-4">
        <input type="password" class="form-control" placeholder="Application Secret" #applicationSecret="ngModel"
          autocomplete="off"
          [ngClass]="{'is-invalid': applicationSecret && applicationSecret.touched && applicationSecret.invalid }"
          name="applicationSecret" [(ngModel)]="amazonWebServices.password" required />
        <div class="invalid-feedback">Please enter application secret.</div>
      </div>

      <div class="col-md-2 text-end mt-2">
        <label class="custom-control-label">Region</label>
      </div>
      <div class="col-md-4 mt-2">
        <select type="text" class="form-control" #region="ngModel"
          [ngClass]="{'is-invalid': region && region.touched && region.invalid }" name="region"
          [(ngModel)]="awsRegionKvp.value" required>
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let region of awsRegions | sort: 'desc':'name'" value="{{region.value}}">
            {{region.name}}
          </option>
        </select>
        <div class="invalid-feedback">Please select a region.</div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="
        emailService.serviceType !== 'ExchangeMicrosoftGraph' ||
        (emailService.serviceType === 'ExchangeMicrosoftGraph' && emailService.userName && tenantKvp?.value)
      ">
      <div class="{{
          emailService.serviceType === 'ExchangeMicrosoftGraph'
            ? 'col-md-2'
            : 'col-md-8'
        }}"></div>
      <div class="col-md-2">
        <button class="btn btn-outline-primary" (click)="sendTestEmail(emailService)">
          <span *ngIf="sendingTestEmail" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!sendingTestEmail">Send Test Email</span>
        </button>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
  <div class="fieldset-title pt-4" *ngIf="allowEmailImpersonation">
    Email Impersonation
  </div>
  <div class="pt-3" *ngIf="allowEmailImpersonation">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="form-check-label" for="allowEmailImpersonation">Allow Email Impersonation</label>
      </div>
      <div class="col-md-4">
        <input class="form-check-input" type="checkbox" name="allowEmailImpersonation" id="allowEmailImpersonation"
          ToggleValue [true]="'1'" [false]="'0'" [(ngModel)]="allowEmailImpersonation.valueStr" />
      </div>
    </div>
  </div>
  <div class="fieldset-title pt-4">
    Restrict Email Template User Overrides
  </div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="form-check-label" for="restrictedUserEmailTemplateOverrides">Restricted Types</label>
      </div>
      <div class="col-md-4">
        <ng-select width="100%" name="restrictedUserEmailTemplateOverrides" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" (change)="setRestrictedEmails()"
              [(ngModel)]="selectedEmails" [items]="emailTemplateTypes" appendTo="body">
            </ng-select>
      </div>
    </div>
  </div>
  <div class="text-end" *ngIf="type === 'system-level'">
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</form>

