import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { TaskStatsCardComponent } from 'src/app/modules/tasks/components/task-stats-card/task-stats-card.component';
import { TaskStats, TaskStatsGroup } from 'src/app/modules/tasks/models/task-stats-group.model';

@Component({
  selector: 'app-tasks',
  templateUrl: 'app-tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppTasksComponent implements OnInit {

  @ViewChildren("statsCard")
  statsCards: QueryList<TaskStatsCardComponent>;

  private _taskStatsGroups: TaskStatsGroup[] = [];

  selectedStatId: number | null;

  @Input()
  set taskCounts(taskCounts: DashboardTaskCounts) {
    if (taskCounts) {
      this._taskStatsGroups[0].stats[0].value = taskCounts.mt_l2.toString();
      this._taskStatsGroups[0].stats[1].value = taskCounts.mt_g2.toString();

      this._taskStatsGroups[1].stats[0].value = taskCounts.bt_p.toString();
      this._taskStatsGroups[1].stats[1].value = taskCounts.bt_s.toString();
      this._taskStatsGroups[1].stats[2].value = taskCounts.bt_c.toString();

      if (taskCounts.tpoHp || taskCounts.tpoSub) {
        this._taskStatsGroups[2].isVisible = true;
        this._taskStatsGroups[2].stats[0].value = taskCounts.tpoHp.toString();
        this._taskStatsGroups[2].stats[1].value = taskCounts.tpoSub.toString();
      } else {
        this._taskStatsGroups[2].isVisible = false;
      }

      if (taskCounts.cpa) {
        this._taskStatsGroups[3].isVisible = true;
        this._taskStatsGroups[3].stats[0].value = taskCounts.cpa.toString();
      } else {
        this._taskStatsGroups[3].isVisible = false;
      }

      this._taskStatsGroups[4].stats[0].value = taskCounts.ot_l2.toString();
      this._taskStatsGroups[4].stats[1].value = taskCounts.ot_g2.toString();

      this._taskStatsGroups[5].stats[0].value = taskCounts.ct.toString();
    } else {
      this.resetTaskCounts();
    }
  }

  get taskStatsGroups(): TaskStatsGroup[] {
    return this._taskStatsGroups;
  }

  @Output()
  statSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this._taskStatsGroups.push(new TaskStatsGroup("My Tasks",
      [
        new TaskStats("Due Today", null, 1),
        new TaskStats("Due Later", null, 2)
      ], "#2ea8e2"));

    this._taskStatsGroups.push(new TaskStatsGroup("Borrower Tasks",
      [
        new TaskStats("Outstanding", null, 5),
        new TaskStats("Pending", null, 6),
        new TaskStats("Completed", null, 7)
      ], "#9c47a5"));

    const tpoReviewTaskGroup = new TaskStatsGroup("TPO Review",
      [
        new TaskStats("Escalation & COC", null, 18),
        new TaskStats("Condition Review", null, 17)
      ], "#d4cb53")
    tpoReviewTaskGroup.isVisible = false;
    this.taskStatsGroups.push(tpoReviewTaskGroup);

    const conditionImportPendingTaskGroup = new TaskStatsGroup("Condition Import Pending",
      [
        new TaskStats("Due Now", null, 16),
      ], "#2a778d")
    conditionImportPendingTaskGroup.isVisible = false;
    this.taskStatsGroups.push(conditionImportPendingTaskGroup);

    this._taskStatsGroups.push(new TaskStatsGroup("Other Tasks",
      [
        new TaskStats("Due Today", null, 3),
        new TaskStats("Due Later", null, 4)
      ], "#41ac88"));

    this._taskStatsGroups.push(new TaskStatsGroup("Completed Tasks",
      [
        new TaskStats("Completed", null, 10)
      ], "#584c45"));
  }

  ngOnInit() {
    this.resetTaskCounts();
  }

  onStatSelected = (statId: number) => {
    if (this.selectedStatId == statId) {
      this.selectedStatId = null;
      this.unselectAll();
    } else {
      this.selectedStatId = statId;
      this.statsCards.toArray().forEach(c => {
        if (!c.containsStat(statId)) {
          c.unselectAll();
        }
      });
    }
    this.statSelected.emit(this.selectedStatId);
  }

  unselectAll = () => {
    this.selectedStatId = null;
    this.statsCards.toArray().forEach(c => {
      c.unselectAll();
    });
  }

  zeroAllTaskCounts = () => {
    this._taskStatsGroups.forEach(group => {
      group.stats.forEach(stat => {
        stat.value = '0';
      })
    });
  }

  private resetTaskCounts = () => {
    this._taskStatsGroups.forEach(group => {
      group.stats.forEach(stat => {
        stat.value = null;
      })
    });
  }
}
