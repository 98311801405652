<div class="topbar nextgen">
  <nav class="navbar-custom d-flex">
    <ul class="list-unstyled topbar-nav mb-0">
      <li>
        <img
          src="assets/images/loda-logo.png"
          style="height: 26px"
          alt="config.name"
          class="topbar-logo logo-sm"
        />
      </li>
    </ul>
    <ul class="list-unstyled mx-md-auto mb-0">
      <li>
        <form action="#" method="get">
          <div class="input-group mt-2">
            <input type="search" class="form-control" id="lodasoft" [(ngModel)]="searchText" placeholder="Search" aria-label="Recipient's username"
              name="searchText" aria-describedby="basic-addon2">
              <i class="fas fa-search"></i>
              <input type="submit" (click)="search()" hidden>
          </div>
        </form>
      </li>
    </ul>
    <header-actions [user]="user" (mentionsClicked)="onMentionsClicked($event)" (niceInContactClicked)="onNiceInContactClicked($event)"
        (notificationsClicked)="onNotificationsClicked($event)" (alertsCreated)="onAlertsCreated($event)"
        (conversationsToggled)="onConversationsToggled()" [unreadConversations]="unreadConversations">
    </header-actions>

    <!--end topbar-nav-->
  </nav>
  <!-- end navbar-->
</div>
