import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LdeTransactionHistoryComponent } from './lde-transaction-history/lde-transaction-history.component';
import { ApplicationContextBoundComponent } from '../../shared/components';
import { LdeLoanReferenceComponent } from './lde-loan-reference/lde-loan-reference.component';
import { LdeLoanLinkComponent } from './lde-loan-link/lde-loan-link.component';
import {
  LdeAppOpreationResult,
  LdeTransactionHistory,
  LoanApplication,
} from '../../models';
import { NotificationService } from '../../services/notification.service';
import { AppDetailsService } from '../app-details/services/app-details.service';
import { LdeSyncDirectionComponent } from './lde-sync-direction/lde-sync-direction.component';
import { Constants } from 'src/app/services/constants';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { VendorsList } from '../loan-docs/models/vendors-list-model';
import { LdeService } from 'src/app/services/lde.service';
import { SyncLodaDocWithLdeDialogComponent } from '../loan-docs/components/sync-loda-doc-with-lde-dialog/sync-loda-doc-with-lde-dialog.component';

@Component({
  selector: 'lde-info',
  templateUrl: 'lde-info.component.html',
  styleUrls: ['./lde-info.component.scss'],
})
export class LdeInfoComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output()
  syncInfoUpdated: EventEmitter<any> = new EventEmitter<any>();

  pullingFromLde: boolean = false;
  pushingToLde: boolean = false;
  unlinkingFromLde: boolean = false;
  loading: boolean = false;
  buttonPressed: boolean = false;
  ldeEnabled: boolean = false;
  docSyncEnabled: boolean = false;

  ldeVendors;
  globalConfig: GlobalConfig;
  transationHistory: LdeTransactionHistory;
  application: LoanApplication;

  lenderName: string;
  ldeSyncDirection: string = '';
  selectedLdeVendor: string = '';
  loanStatusType: string;

  private vendorsList: VendorsList;

  private readonly _syncDirections = {
    None: 'No Data Flow',
    LosToLoda: 'LDE to Loda Only',
    Both: 'Bi-directional Data Flow',
  };

  constructor(
    private readonly injector: Injector,
    private modalService: NgbModal,
    private readonly _ldeService: LdeService,
    private readonly _notificationService: NotificationService,
    private readonly _appService: AppDetailsService,
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.loading = true;
    this.applicationContextService.context.subscribe((context) => {
      this.ldeEnabled = context.userPermissions.ldeEnabled;
      this.application = context.application;
      this.globalConfig = context.globalConfig;
      this.getVendors();
      this.lenderName = context.globalConfig.lender.find(x=> x.lenderId === this.application.lenderId)?.name;
    });
    if (this.applicationContext.application) {
      this.application = this.applicationContext.application;
    }

    this.updateUi(false);

    this._ldeService.getLdeCredentialsForLoan(this.application.applicationId).subscribe({
      next: (vendors) => {
        this.ldeVendors = vendors;
        this.ldeVendors.forEach((vendor) => {
          if (this.application.ldeVendor === vendor.ldeVendor) {
            this.selectedLdeVendor = vendor.ldeVendorName;
          }

          if (vendor.vendorFeatures.indexOf('Lookup') > -1) {
            vendor.borrowerDisabled = true;
          }
          if (vendor.vendorFeatures.indexOf('GetDocuments') > -1) {
            this.docSyncEnabled = true;
          }
          if (vendor.vendorFeatures.indexOf('SyncMortgage') > -1) {
            vendor.dataSyncEnabled = true;
          }
        });
        this.loading = false;
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to get LDE credentials',
          'LDE Credentials'
        );
        this.loading = false;
      }
    });
  }

  referenceLdeLoan(isEditable: boolean) {
    const modalRef = this.modalService.open(LdeLoanReferenceComponent, {
      centered: true,
    });
    modalRef.componentInstance.applicationId = this.application.applicationId;
    if (isEditable) {
      modalRef.componentInstance.ldeRefNumber = this.application.ldeRefNumber;
    }

    modalRef.result.then(
      (result: string) => {
        this.application.ldeRefNumber = result;

        this.applicationContextService.refreshApplicationAfterLosLdeRefChange(this.application);

        this.setLoanStatusType();
      },
      () => { }
    );
  }

  linkLdeLoan() {
    this._ldeService.getLosCredentialsForLinkLoanWithLde(this.application.applicationId).subscribe({
      next: (res) => {
        this.openLinkLoanWithLdeDialog(res);
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load LDE vendors", "Error");
      }
    })    
  }

  pullFromLde() {
    this.pullingFromLde = true;
    this.buttonPressed = true;

    this._ldeService.pullFromLde(this.application.applicationId).subscribe(
      (result: LdeAppOpreationResult | null) => {
        if (result) {
          this.application.ldeIdentifier = result.ldeIdentifier;
          this.application.ldeRefNumber = result.ldeRefNumber;
          this.application.ldeSyncDir = result.ldeSyncDir;
          
          this.applicationContextService.updateLoanAfterLosLdeChange(result)
        }

        this.updateUi(true);

        this._notificationService.showSuccess('Pulled from LDE', 'Success');
        this.pullingFromLde = false;
        this.buttonPressed = false;
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
        this.pullingFromLde = false;
        this.buttonPressed = false;
      }
    );
  }

  pushToLde() {
    this.pushingToLde = true;
    this.buttonPressed = true;

    this._ldeService.pushToLde(this.application.applicationId).subscribe(
      () => {
        this._notificationService.showSuccess('Pushed to LDE', 'Success');
        this.pushingToLde = false;
        this.buttonPressed = false;
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
        this.pushingToLde = false;
        this.buttonPressed = false;
      }
    );
  }

  syncDocuments() {
    const modalRef = this.modalService.open(SyncLodaDocWithLdeDialogComponent, Constants.modalOptions.xlarge)
    modalRef.componentInstance.title = 'Synchronize Documents With LDE';
    modalRef.componentInstance.appId = this.application.applicationId;
    modalRef.componentInstance.userId = this.application.userId;
    modalRef.componentInstance.companyId = this.application.companyId;
    modalRef.componentInstance.docTypes = this.globalConfig.documentType.filter(doc => doc.documentTypeId > -1);
    modalRef.componentInstance.ldeVendor = this.filteredVendors(this.vendorsList, this.application.ldeVendor);
    modalRef.result.then((result) => {
      if (result === 'cancel') {
        return;
      }

    }, (res) => {
    });
  }

  showTransactionHistory() {
    const modalRef = this.modalService.open(LdeTransactionHistoryComponent, Constants.modalOptions.ninetyPercentOfScreenWidth)
    modalRef.componentInstance.applicationId = this.application.applicationId;

    modalRef.result.then((result) => {
      if (result === 'cancel') return;
    }, (res) => {
    });
  }

  private getVendors = () => {
    this._ldeService.getLdeVendors()
      .subscribe({
        next: (result) => {
          this.vendorsList = result.values;
        },
        error: (err) => {
          this._notificationService.showError(err ? err.error : "Couldn't get vendor list!", 'Failure');
        }
      });
  }

  private filteredVendors = (vendorsList: VendorsList, ldeVendor: string) => {
    if (vendorsList.values.length === 0) {
      return "";
    }
    const index = vendorsList.values.findIndex(value => value === ldeVendor);
    if (index === -1) {
      return "";
    }
    return vendorsList.names[index];
  }

  unlinkLde() {
    this.unlinkingFromLde = true;
    this.buttonPressed = true;

    this._ldeService.unlink(this.application.applicationId).subscribe(
      () => {
        this.application.ldeIdentifier = null;
        this.application.ldeRefNumber = null;
        this.application.ldeVendor = null;
        this.application.ldeSyncDir = null;
        this.application.ldeInstanceId = null;

        this.applicationContextService.updateLoanAfterLosLdeUnlinkChange(this.application);

        this.updateUi(true);

        this._notificationService.showSuccess('Loan Unlinked from LDE', 'Success');
        this.unlinkingFromLde = false;
        this.buttonPressed = false;
      },
      (error) => {
        this._notificationService.showError(
          error ? error.message || error : 'Error',
          'Error'
        );
        this.unlinkingFromLde = false;
        this.buttonPressed = false;
      }
    );
  }

  changeSyncDirection() {
    const modalRef = this.modalService.open(LdeSyncDirectionComponent, {
      centered: true,
    });
    modalRef.componentInstance.applicationId = this.application.applicationId;
    modalRef.componentInstance.syncDirection = this.application.ldeSyncDir;

    modalRef.result.then((result) => {
      this.application.ldeSyncDir = result;

      this.applicationContextService.updateApplicationAfterLosLdeSyncChange(this.application);

      this.setSyncDirectionDisplayValue();
    });
  }

  areButtonsDisabled(): boolean {
    return this.unlinkingFromLde || this.pullingFromLde || this.pushingToLde;
  }

  private updateUi(syncInfoUpdated: boolean) {
    this.setLoanStatusType();
    this.setSyncDirectionDisplayValue();
    this.setSelectedLosVendor();

    if (syncInfoUpdated) {
      this.syncInfoUpdated.emit();
    }
  }

  private setLoanStatusType() {
    const { ldeRefNumber, ldeVendor, ldeIdentifier } = this.application;
    if (ldeRefNumber && !ldeVendor && !ldeIdentifier) {
      this.loanStatusType = 'referenced';
    } else if (ldeVendor && ldeIdentifier) {
      this.loanStatusType = 'linked';
    } else {
      this.loanStatusType = 'notAssociated';
    }
  }

  private setSyncDirectionDisplayValue() {
    this.ldeSyncDirection =
      this._syncDirections[this.application.ldeSyncDir] ||
      this._syncDirections['None'];
  }

  private setSelectedLosVendor() {
    this.selectedLdeVendor = this.application?.ldeVendor || null;
  }

  private openLinkLoanWithLdeDialog(ldeVendors) {
    const modalRef = this.modalService.open(LdeLoanLinkComponent, {
      size: 'xl',
      centered: true
    });
    modalRef.componentInstance.context = this.applicationContext;
    modalRef.componentInstance.ldeVendors = ldeVendors.filter(
      (vendor) =>
        (vendor.vendorFeatures.indexOf('Search') > -1 && vendor.vendorFeatures.indexOf('SyncMortgage') > -1) ||
        vendor.vendorFeatures.indexOf('Lookup') > -1
    );

    modalRef.result.then(
      (result: LdeAppOpreationResult | null) => {
        if (result) {
          this.application.ldeIdentifier = result.ldeIdentifier;
          this.application.ldeRefNumber = result.ldeRefNumber;
          this.application.ldeVendor = result.ldeVendor;
          this.application.ldeSyncDir = result.ldeSyncDir;
          this.application.ldeInstanceId = result.ldeInstanceId;
  
          this.applicationContextService.updateLoanAfterLosLdeChange(result);
        }

        this.updateUi(true);
      },
      () => { }
    );
  }
}
