export class DocuViewareConfig {
  SessionId: string;
  ControlId: string;
  EnableLoadFromUriButton: boolean = false;
  EnableSaveButton: boolean = false;
  AllowPrint: boolean = false;
  EnablePrintButton: boolean = false;
  AllowUpload: boolean = true;
  EnableFileUploadButton: boolean = false;
  CollapsedSnapIn: boolean = false;
  ShowAnnotationsSnapIn: boolean = true;
  EnableRotateButtons: boolean = true;
  EnableZoomButtons: boolean = true;
  EnablePageViewButtons: boolean = true;
  EnableMultipleThumbnailSelection: boolean = true;
  EnableMouseModeButtons: boolean = true;
  EnableFormFieldsEdition: boolean = true;
  EnableTwainAcquisitionButton: boolean = false;
  EnableThumbnailDragDrop: boolean = true;
  FileDownloadCustomHeaders: FileDownloadCustomHeaders;
  constructor() {
    this.SessionId = Math.floor((Math.random() * 1000000) + 1).toString();
  }
}

export class FileDownloadCustomHeaders {
  Authorization: string;
}