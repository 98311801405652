<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <fee-application-comparison
      #feeComparator
      [existingFees]="existingFees"
      [newFeesSectionHeader]="newFeesSectionHeader"
      [newFees]="newFees">
    </fee-application-comparison>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary" (click)="onApplyFeesClicked()"> Apply
  </button>
</div>
