export class ExternalCompanyContact {
    companyId: number;
    dateInserted: Date;
    dateUpdated: Date;
    email: string;
    readonly externalCompanyContactId: number;
    externalCompanyId: number;
    insertedBy: string;
    name: string;
    order: number;
    phoneNumber: string;
    title: string;
    updatedBy: string;
    userId: string = "";
    avatarId: string;
    constructor() {}
}