<div class="col-md-12" *ngIf="!isTitleOrderingEnabled">
    <enable-message>Call to Enable this feature!</enable-message>
</div>
<div *ngIf="isTitleOrderingEnabled" class="row mt-1">
    <div class="col-md-3">
        <toggle-configuration [title]="'Enable Title Ordering'" [id]="'titleOrderingEnabled'"
            [configuration]="titleOrderingEnabled"></toggle-configuration>
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="d-flex align-content-end flex-wrap" *ngIf="titleOrderingEnabled.value">
                <div class="mt-4 me-2" *ngFor="let credential of titleCredentials">
                    <credential-item [credential]="credential" [credentials]="titleCredentials"
                        (openCredentialModal)="openCredentialModal($event)"></credential-item>
                </div>
                <div class="mt-4 me-2">
                    <create-credential [credentialType]="'Title'" [credentials]="titleCredentials"
                        (openCredentialModal)="openCredentialModal($event)"></create-credential>
                </div>
            </div>
            <div class="col-md-12">
                <enable-message *ngIf="titleOrderingEnabled.value === 0">
                    Enable this feature above!
                </enable-message>
            </div>
        </div>
    </div>
</div>