<div class="modal-header">
    <h5 class="modal-title">Task Information</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="task">
    <form #escalationTaskForm="ngForm" novalidate id="escalationTaskForm" name="escalationTaskForm">
        <div class="row">
            <div class="col-md-7">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mb-3 row">
                            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Description</label>
                            <div class="col-sm-10">
                                <input class="form-control" placeholder="Description" id="escalationDescription"
                                    name="escDescription" type="text" [(ngModel)]="task.description" disabled>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mb-3 row">
                            <label for="notes" class="col-sm-2 form-label mb-lg-0 text-end">Notes</label>
                            <div class="col-sm-10">
                                <textarea *ngIf="task.taskStatus === 'EscalationPending'" class="form-control" placeholder="Add a note" id="notes" name="note" [(ngModel)]="task.note"
                                    type="text" rows="4"></textarea>

                                <ul *ngIf="internalTaskMessages && internalTaskMessages.length > 0">
                                    <li *ngFor="let note of internalTaskMessages">
                                        <span class="note-pre-wrap">{{note.content}}</span>
                                        <div>{{note.senderFirstName}} {{note.senderLastName}}
                                            ({{note.postTime | date:'MMM d, y h:mm:ss a'}})</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mb-3 row">
                            <label for="files" class="col-sm-2 form-label mb-lg-0 text-end">Upload/View</label>
                            <div class="col-sm-10">
                                <file-upload [(ngModel)]="uploadedFiles" multiple="true" name="files"></file-upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer form-footer">
    <button (click)='onOkClicked()' [disabled]='isSaving' class='btn btn-primary right d-flex align-items-center gap-2'
            type='button'>
        Ok
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
</div>
