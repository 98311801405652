<div class="modal-header modal-header--sticky">
  <h5 class="modal-title"> New Application </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body mt-5 mb-3">
  <new-application [scenarioId]="scenarioId" (newAppCreatedSuccessfully)="newAppCreatedSuccessfully($event)">
  </new-application>
</div>
<div class="modal-footer modal-footer--sticky">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
  </button>
</div>