<div class="row mt-1">
    <div class="col-md-12">
        <toggle-configuration [title]="'Enable OCR / Document Processing Integration'" [id]="'ocrEnabled'"
            [configuration]="ocrEnabled"></toggle-configuration>
    </div>
    <div class="col-md-12 mt-1">
        <hr class="mt-3 mb-0" />
    </div>
    <div class="d-flex align-content-end flex-wrap" *ngIf="ocrEnabled.value">
        <div class="mt-4 me-2" *ngFor="let credential of ocrCredentials">
            <credential-item [credential]="credential" [credentials]="ocrCredentials"
                (openCredentialModal)="openVendorEditor($event)"></credential-item>
        </div>
        <div class="mt-4 me-2">
            <create-credential [credentialType]="'OcrVendor'" [credentials]="ocrCredentials"
                (openCredentialModal)="openVendorPicker()"></create-credential>
        </div>
    </div>
    <div class="col-md-12">
        <enable-message *ngIf="ocrEnabled.value === 0">Enable this feature above!</enable-message>
    </div>
</div>