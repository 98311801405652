<!--
 Attention: The nav-item li elements shouldn't be conditionally rendered with *ngIf as it overrides the expansion
  behaviour and they will be always expanded when they are rendered. Instead, use the [hidden] attribute to hide
  the nav-item elements.
-->

<div
  *ngIf="isAppMenuLoading"
  class="d-flex flex-column gap-2 align-items-center justify-content-center"
  style="height: 20rem"
>
  <span
    class="spinner-border spinner-border-md"
    role="status"
  ></span>
  <span class="text-muted">Loading Application Menu...</span>
</div>

<ul
  [hidden]="isAppMenuLoading"
  [ngClass]="{'sticky-nav-tabs-open-dialer': secondPageActionBarVisible}"
  [vertical]="true"
  class="main-sidenav-menu metismenu left-sidenav-menu sticky nav nav-tabs"
  id="metismenu"
>
  <!-- Loan/HEA Summary -->
  <li
    (click)="onMenuItemClicked($event, 'loan-summary')"
    *ngLet="getMenuItem(ApplicationMenuItemDb.LoanSummary) as menuItem"
    [hidden]="!menuItem"
    [ngClass]="{'mm-active': tab === 'loan-summary', 'mm-sub-active': tab === 'qa-borrower-info' || tab === 'qa-financial-info' || tab === 'qa-loan-info' || tab === 'qa-fha-info' || tab === 'qa-loan-info'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.LoanSummary.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [ngClass]="{'pe-none': tab === 'loan-summary'}"
      aria-disabled="true"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/loan-summary',
      ]"
    >
      <span>
        {{
          loanPurposeTransactionType && loanPurposeTransactionType == 'HomeEquityInvestment'
            ? 'HEA'
            : 'Loan'
        }}
        Summary
      </span>
    </a>
    <ng-container *ngIf="getMenuItem(ApplicationMenuItemDb.LoanSummary.QuickApply)">
      <ng-container
        *ngLet="
          tab === 'loan-summary' ||
          tab === 'qa-borrower-info' ||
          tab === 'qa-financial-info' ||
          tab === 'qa-loan-info' ||
          tab === 'qa-fha-info' ||
          tab === 'qa-va-info' as isExpanded
        "
      >
        <ul
          [ngClass]="{'mm-show': isExpanded, 'mm-collapse': !isExpanded}"
          aria-expanded="false"
          class="nav-second-level metismenu left-sidenav-menu left-sidenav-menu-padding"
          id="metismenu"
        >
          <qa-menu
            [applicationMode]="applicationMode"
            [application]="application"
            [mortgageType]="mortgageType"
            [mortgage]="this.applicationContext.currentMortgage"
            [tab]="tab"
          >
          </qa-menu>
        </ul>
      </ng-container>
    </ng-container>
  </li>

  <!-- Deal Structure -->
  <li
    (click)="tab = 'deal-structure'"
    *ngLet="getMenuItem(ApplicationMenuItemDb.DealStructure) as menuItem"
    [hidden]="
      (loanPurposeTransactionType && loanPurposeTransactionType !== 'Mortgage') || !menuItem
    "
    [ngClass]="{'mm-active': tab === 'deal-structure'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.DealStructure.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/deal-structure',
      ]"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
    </a>
  </li>

  <!-- URLA -->
  <!-- Solar -->
  <ng-container *ngLet="getMenuItem(ApplicationMenuItemDb.Urla) as menuItem">
    <li
      (click)="tab = 'urla'"
      *ngLet="getMenuItem(ApplicationMenuItemDb.Urla) as menuItem"
      [hidden]="
          application.isMismo34Application || loanPurposeTransactionType === 'Solar' || !menuItem
        "
      [ngClass]="{'mm-active': tab === 'urla'}"
      [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.Urla.id]"
      class="nav-item"
    >
      <a
        [routerLink]="[
            '/' + applicationMode + '/app-details/' + application.applicationId + '/urla',
          ]"
        class="nav-link"
      >
        <span> URLA </span>
      </a>
    </li>

    <!-- 2020/2023 -->
    <li
      (click)="tab = 'urla'"
      *ngLet="getMenuItem(ApplicationMenuItemDb.Urla) as menuItem"
      [hidden]="loanPurposeTransactionType === 'Solar' || !menuItem"
      [ngClass]="{'mm-active': tab === 'urla'}"
      [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.Urla.id]"
      class="nav-item"
    >
      <a
        [hidden]="!application.isMismo34Application"
        [routerLink]="[
            '/' + applicationMode + '/app-details/' + application.applicationId + '/urla',
          ]"
        aria-expanded="false"
        class="nav-link"
        href="#"
      >
          <span>
            {{ loanPurposeTransactionType === 'HomeEquityInvestment' ? 'URHA 2023' : 'URLA 2020' }}
          </span>
        <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
      </a>
      <ul
        *ngIf="application.isMismo34Application"
        [ngClass]="{'mm-show': tab === 'urla', 'mm-collapse': tab !== 'urla'}"
        aria-expanded="false"
        class="nav-second-level p-0"
      >
        <li>
          <urla-menu
            *ngIf="tab === 'urla'"
            (menuItemClicked)="onUrlaMenuItemClicked($event)"
          ></urla-menu>
        </li>
      </ul>
      <a
        (click)="confirmConvertToMismo34Application()"
        *ngIf="!application.isMismo34Application"
        class="nav-link"
      >
        <span> Convert to URLA 2020 </span>
      </a>
    </li>
  </ng-container>

  <!-- Loan/HEA Docs -->
  <li
    (click)="tab = 'loan-docs'"
    *ngLet="getMenuItem(ApplicationMenuItemDb.LoanDocs) as menuItem"
    [hidden]="!menuItem"
    [ngClass]="{'mm-active': tab === 'loan-docs'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.LoanDocs.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/loan-docs',
      ]"
    >
      <span>
        {{ loanPurposeTransactionType === 'HomeEquityInvestment' ? 'HEA' : 'Loan' }} Docs
      </span>
    </a>
  </li>

  <!-- Loan Docs V2 -->
  <li
    (click)="tab = 'loan-docs-v2'"
    *ngLet='getMenuItem(ApplicationMenuItemDb.LoanDocsV2) as menuItem'
    [hidden]='companyId !== 2 || !menuItem'
    [ngClass]="{'mm-active': tab === 'loan-docs-v2'}"
    [style.order]='idToAppMenuItemOrder[ApplicationMenuItemDb.LoanDocsV2.id]'
    class='nav-item'
  >
    <a
      class="nav-link"
      [routerLink]="[
          '/' + applicationMode + '/app-details/' + application.applicationId + '/loan-docs-v2',
        ]"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
    </a>
  </li>

  <!-- Pricing -->
  <li
    (click)="onMenuItemClicked($event, 'pricing')"
    *ngLet="getMenuItem(ApplicationMenuItemDb.Pricing) as menuItem"
    [hidden]="!permissions.pricingEnabled || loanPurposeTransactionType === 'Solar' || !menuItem"
    [ngClass]="{'mm-active': tab === 'pricing', 'mm-sub-active': tab === 'pricing-history' || tab === 'amortization' || tab === 'atr-qm-management' || tab === 'application-pricing-scenarios'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.Pricing.id]"
    class="nav-item"
  >
    <a
      [ngClass]="{'pe-none': tab === 'pricing'}"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/pricing',
      ]"
      aria-disabled="true"
      class="nav-link"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
    </a>
    <!-- Pricing Sub Menu -->
    <ul
      [ngClass]="{'mm-show': tab === 'pricing' || tab === 'pricing-history' || tab === 'amortization' || tab === 'atr-qm-management' || tab === 'application-pricing-scenarios'}"
      [ngStyle]="
        (tab === 'pricing' ||
          tab === 'pricing-history' ||
          tab === 'amortization' ||
          tab === 'atr-qm-management' ||
          tab === 'application-pricing-scenarios') && {height: 'none'}
      "
      aria-expanded="false"
      class="nav-second-level"
    >
      <!-- Pricing History -->
      <li
        (click)="tab = 'pricing-history'"
        [ngClass]="{'mm-active': tab === 'pricing-history'}"
        class="nav-item"
        *ngIf="!pricingVendorsAreOnlyIframes"
      >
        <a
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/pricing/pricing-history',
          ]"
          class="nav-link"
        >
          <span> Pricing History </span>
        </a>
      </li>
      <!-- Pricing Scenarios -->
      <li
        (click)="tab = 'application-pricing-scenarios'"
        [ngClass]="{'mm-active': tab === 'application-pricing-scenarios'}"
        class="nav-item"
        *ngIf="!pricingVendorsAreOnlyIframes"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/pricing/application-pricing-scenarios',
          ]"
        >
          <span> Pricing Scenarios </span>
        </a>
      </li>
      <!-- Amortization Table -->
      <li
        (click)="tab = 'amortization'"
        [hidden]="!isAmortizationTableMenuItemVisible"
        [ngClass]="{'mm-active': tab === 'amortization'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/pricing/amortization',
          ]"
        >
          <span> Amortization Table </span>
        </a>
      </li>

      <!-- ATR / QM Management -->
      <li
        (click)="tab = 'atr-qm-management'"
        [hidden]="!getMenuItem(ApplicationMenuItemDb.Pricing.AtrQmManagement)"
        [ngClass]="{'mm-active': tab === 'atr-qm-management'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/pricing/atr-qm-management',
          ]"
        >
          <span> ATR / QM Management </span>
        </a>
      </li>
    </ul>
  </li>

  <!-- Fees -->
  <li
    (click)="tab = 'fees'; onMenuItemClicked($event, 'fees')"
    *ngLet="getMenuItem(ApplicationMenuItemDb.Fees) as menuItem"
    [hidden]="loanPurposeTransactionType === 'Solar' || !menuItem"
    [ngClass]="{'mm-active': tab === 'fees'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.Fees.id]"
    class="nav-item"
  >
    <a
      [attr.aria-expanded]="tab == 'fees'"
      [routerLink]="['/' + applicationMode + '/app-details/' + application.applicationId + '/fees']"
      class="nav-link"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
    </a>
  </li>

  <!-- Conditions -->
  <li
    (click)="tab = 'conditions'"
    *ngLet="getMenuItem(ApplicationMenuItemDb.Conditions) as menuItem"
    [hidden]="!menuItem"
    [ngClass]="{'mm-active': tab === 'conditions'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.Conditions.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/conditions',
      ]"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
    </a>
  </li>

  <!-- Services -->
  <li
    *ngLet="getMenuItem(ApplicationMenuItemDb.Services) as menuItem"
    [hidden]="loanPurposeTransactionType === 'Solar' || !menuItem"
    [ngClass]="{'mm-active': tab === 'credit-reports' || tab === 'digital-emp-income-verification' || tab === 'digital-asset-verification' || tab === 'aus' || tab === 'disclosures' || tab === 'disclosure-wizard' || tab === 'appraisal'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.Services.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [attr.aria-expanded]="
        tab == 'credit-reports' ||
        tab === 'digital-emp-income-verification' ||
        tab === 'digital-asset-verification' ||
        tab === 'aus' ||
        tab === 'disclosures' ||
        tab === 'disclosure-wizard' ||
        tab === 'appraisal'
      "
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
      <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
    </a>

    <!-- Services Sub Menu -->
    <ul
      class="nav-second-level"
      [attr.aria-expanded]="false"
      [ngStyle]="
        (
          tab === 'credit-reports' ||
          tab === 'digital-emp-income-verification' ||
          tab === 'digital-asset-verification' ||
          tab === 'aus' ||
          tab === 'disclosure' ||
          tab === 'disclosure-wizard' ||
          tab === 'appraisal'
        ) && {height: 'none'}
      "
      [ngClass]="{'mm-show': tab === 'credit-reports' || tab === 'digital-emp-income-verification' || tab === 'digital-asset-verification' || tab === 'aus' || tab === 'disclosures' || tab === 'disclosure-wizard' || tab === 'appraisal'}"
    >
      <!-- Credit Reports -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.Services.Credit) as menuItem"
        [hidden]="!permissions.creditReportingEnabled || !menuItem"
        [ngClass]="{'mm-active': tab === 'credit-reports'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' + applicationMode + '/app-details/' + application.applicationId + '/credit-reports',
          ]"
          (click)="tab = 'credit-reports'"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- VOI/VOE -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.Services.VoiVoe) as menuItem"
        [hidden]="!permissions.digitalEmploymentVerificationEnabled || !menuItem"
        [ngClass]="{'mm-active': tab === 'digital-emp-income-verification'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/digital-emp-income-verification',
          ]"
          (click)="tab = 'digital-emp-income-verification'"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- VOA -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.Services.Voa) as menuItem"
        [hidden]="!permissions.digitalAssetVerificationEnabled || !menuItem"
        [ngClass]="{'mm-active': tab === 'digital-asset-verification'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/digital-asset-verification',
          ]"
          (click)="tab = 'digital-asset-verification'"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- AUS -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.Services.Aus) as menuItem"
        [hidden]="
          (!permissions.desktopUnderwriterEnabled &&
            !permissions.lpaEnabled &&
            !permissions.desktopOriginatorEnabled) ||
          !menuItem
        "
        [ngClass]="{'mm-active': tab === 'aus'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' + applicationMode + '/app-details/' + application.applicationId + '/aus',
          ]"
          (click)="tab = 'aus'"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- Disclosures -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.Services.Disclosures) as menuItem"
        [hidden]="!permissions.docMagicEnabled || !menuItem"
        [ngClass]="{'mm-active': tab === 'disclosures'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' + applicationMode + '/app-details/' + application.applicationId + '/disclosures',
          ]"
          (click)="tab = 'disclosure-wizard'"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- Appraisal -->
      <ng-container *ngLet="getMenuItem(ApplicationMenuItemDb.Services.Appraisal) as menuItem">
        <li
          [hidden]="!permissions.appraisalOrderingEnabled || !menuItem"
          [ngClass]="{'mm-active': tab === 'appraisal'}"
          class="nav-item"
        >
          <a
            class="nav-link"
            [routerLink]="[
              '/' + applicationMode + '/app-details/' + application.applicationId + '/appraisal',
            ]"
            (click)="tab = 'appraisal'"
          >
            <span *ngIf="menuItem?.label as label"> {{ label }} </span>
          </a>
        </li>
      </ng-container>

      <!-- Title -->
      <!-- <li  *ngLet='getMenuItem(ApplicationMenuItemDb.Services.Title) as menuItem' [ngClass]="{'mm-active': tab === 'title'}" class='nav-item'>
        <a class='nav-link'
          [routerLink]="['/' + applicationMode + '/app-details/' + application.applicationId + '/reggora']"
          (click)="tab = 'title'">
          <span> {{label}} </span>
        </a>
      </li> -->
    </ul>
  </li>

  <!-- File Contacts -->
  <li
    *ngLet="getMenuItem(ApplicationMenuItemDb.FileContacts) as menuItem"
    [hidden]="!menuItem"
    [ngClass]="{'mm-active': tab === 'internal-contacts' || tab === 'external-contacts'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.FileContacts.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [attr.aria-expanded]="tab == 'internal-contacts' || tab === 'external-contacts'"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
      <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
    </a>

    <ul
      class="nav-second-level"
      [attr.aria-expanded]="false"
      [ngStyle]="(tab === 'internal-contacts' || tab === 'external-contacts') && {height: 'none'}"
      [ngClass]="{'mm-show': tab === 'internal-contacts' || tab === 'external-contacts'}"
    >
      <!-- Internal -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.FileContacts.Internal) as menuItem"
        [hidden]="!menuItem"
        (click)="tab = 'internal-contacts'"
        [ngClass]="{'mm-active': tab === 'internal-contacts'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/internal-contacts',
          ]"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- External -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.FileContacts.External) as menuItem"
        [hidden]="!menuItem"
        (click)="tab = 'external-contacts'"
        [ngClass]="{'mm-active': tab === 'external-contacts'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/external-contacts',
          ]"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>
    </ul>
  </li>

  <!-- Doc Preparation -->
  <li
    (click)="tab = 'doc-preparation'"
    *ngLet="getMenuItem(ApplicationMenuItemDb.DocPreparation) as menuItem"
    [hidden]="!menuItem"
    [ngClass]="{'mm-active': tab === 'doc-preparation'}"
    [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.DocPreparation.id]"
    class="nav-item"
  >
    <a
      class="nav-link"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/doc-preparation',
      ]"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
    </a>
  </li>

  <!-- Disclosure Documents -->
  <li
    *ngLet='getMenuItem(ApplicationMenuItemDb.DisclosureDocuments) as menuItem'
    [hidden]='!permissions.docMagicEnabled || !menuItem'
    [ngClass]="{'mm-active': tab === 'coc-details' || tab === 'tax-transcripts'}"
    [style.order]='idToAppMenuItemOrder[ApplicationMenuItemDb.DisclosureDocuments.id]'
    class='nav-item'
  >
    <a
      class="nav-link"
      [attr.aria-expanded]="tab == 'coc-details' || tab === 'tax-transcripts'"
    >
      <span *ngIf="menuItem?.label as label"> {{ label }} </span>
      <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
    </a>

    <ul
      class="nav-second-level"
      [attr.aria-expanded]="false"
      [ngStyle]="(tab === 'coc-details' || tab === 'tax-transcripts') && {height: 'none'}"
      [ngClass]="{'mm-show': tab === 'coc-details' || tab === 'tax-transcripts'}"
    >
      <!-- Tax Transcripts -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.DisclosureDocuments.TaxTranscripts) as menuItem"
        [hidden]="!menuItem"
        (click)="tab = 'tax-transcripts'"
        [ngClass]="{'mm-active': tab === 'tax-transcripts'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/tax-transcripts',
          ]"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- Coc Details -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.DisclosureDocuments.CocReasons) as menuItem"
        [hidden]="!menuItem"
        (click)="tab = 'coc-details'"
        [ngClass]="{'mm-active': tab === 'coc-details'}"
        [style.order]="idToAppMenuItemOrder[ApplicationMenuItemDb.DisclosureDocuments.id]"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' + applicationMode + '/app-details/' + application.applicationId + '/coc-details',
          ]"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>

      <!-- Tolerance Cures -->
      <li
        *ngLet="getMenuItem(ApplicationMenuItemDb.DisclosureDocuments.ToleranceCures) as menuItem"
        [hidden]="!menuItem"
        (click)="tab = 'tolerance-cures'"
        [ngClass]="{'mm-active': tab === 'tolerance-cures'}"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLink]="[
            '/' +
              applicationMode +
              '/app-details/' +
              application.applicationId +
              '/tolerance-cures',
          ]"
        >
          <span *ngIf="menuItem?.label as label"> {{ label }} </span>
        </a>
      </li>
    </ul>
  </li>

  <!-- Submission DeepHaven ONLY -->
  <li
    (click)="tab = 'submission'"
    *ngIf="isCompanyDeepHaven && application.channel === 'Wholesale' && !isLoanTpoSubmitted"
    [ngClass]="{'mm-active': tab === 'submission'}"
    class="nav-item"
  >
    <a
      class="nav-link"
      [routerLink]="[
        '/' + applicationMode + '/app-details/' + application.applicationId + '/submission',
      ]"
    >
      <span> Submission </span>
    </a>
  </li>
</ul>
