/// <reference lib="webworker" />

import { Timer, TimerResult } from '../../../utils/timer';
import { Subscription } from 'rxjs';

let timer: Timer;

const messageListener = ({ data }: MessageEvent) => {
  const type = data.type as TimerWorkerMessageType;

  let timeoutSubscription: Subscription | undefined;
  let playSubscription: Subscription | undefined;
  let tickSubscription: Subscription | undefined;

  switch (type) {
    case TimerWorkerMessageType.init:
      if (timer != null) {
        throw new Error('The timer is already set');
      }

      const { duration } = data;
      timer = new Timer(duration);

      timeoutSubscription = timer.subscribe((result) => {
        postMessage(new Timeout$Message(result));
      });

      playSubscription = timer.play$.subscribe((from) => {
        postMessage(new Play$Message(from));
      });

      tickSubscription = timer.tick$.subscribe((elapsed) => {
        postMessage(new Tick$Message(elapsed));
      });
      break;
    case TimerWorkerMessageType.timeout$:
      break;
    case TimerWorkerMessageType.play$:
      break;
    case TimerWorkerMessageType.tick$:
      break;
    case TimerWorkerMessageType.play:
      const { from } = data;
      timer.play(from);
      break;
    case TimerWorkerMessageType.pause:
      timer.pause();
      break;
    case TimerWorkerMessageType.close:
      timeoutSubscription?.unsubscribe();
      playSubscription?.unsubscribe();
      tickSubscription?.unsubscribe();

      timer.close();
      break;
  }
};

addEventListener('message', messageListener);

export enum TimerWorkerMessageType {
  init = 'timerWorkerSetInit',
  timeout$ = 'timerWorkerTimeout$',
  play$ = 'timerWorkerPlay$',
  tick$ = 'timerWorkerTick$',
  play = 'timerWorkerPlay',
  pause = 'timerWorkerPause',
  close = 'timerWorkerClose',
}

export abstract class TimerWorkerMessage {
  protected constructor(public readonly type: TimerWorkerMessageType) {
  }
}

export class InitMessage extends TimerWorkerMessage {
  constructor(public readonly duration: number) {
    super(TimerWorkerMessageType.init);
  }
}

export class Timeout$Message extends TimerWorkerMessage {
  constructor(public readonly result: TimerResult) {
    super(TimerWorkerMessageType.timeout$);
  }
}

export class Play$Message extends TimerWorkerMessage {
  constructor(public readonly from: number) {
    super(TimerWorkerMessageType.play$);
  }
}

export class Tick$Message extends TimerWorkerMessage {
  constructor(public readonly elapsed: number) {
    super(TimerWorkerMessageType.tick$);
  }
}

export class PlayMessage extends TimerWorkerMessage {
  constructor(public readonly from?: number) {
    super(TimerWorkerMessageType.play);
  }
}

export class PauseMessage extends TimerWorkerMessage {
  constructor() {
    super(TimerWorkerMessageType.pause);
  }
}

export class CloseMessage extends TimerWorkerMessage {
  constructor() {
    super(TimerWorkerMessageType.close);
  }
}
