<form #searchForm="ngForm" class="pricer-form mt-2" novalidate id="searchForm" name="searchForm"
  *ngIf="productSearchRequest">
  <div class="row">
    <div class="col-xs-12">
      <div class="d-inline-flex align-items-center gap-2">
        <span>Horizon of borrower interest:</span>
        <span>
          <input class="form-control numeric-without-arrows" name="interest" id="interest" section="general-info"
            type="number" required numeric [disabled]="false"
            [(ngModel)]="productSearchRequest.loanInformation.interestOnlyTerm" #interest="ngModel"
            [ngClass]="{'is-invalid' : interest && interest.touched && interest.invalid}" />
          <div class="invalid-feedback">This field is required</div>
        </span>
        <span>
          months (<a class="x" (click)="onBorrowerInterestExplainClicked()">explain</a>)
        </span>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-xs-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': currentTab === 'applications'}"
            (click)="currentTab = 'applications'">
            Applications (1)
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': currentTab === 'property-and-loan-info'}"
            (click)=" currentTab ='property-and-loan-info'">
            Property & Loan Info
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active': currentTab === 'pml-options'}" (click)="currentTab = 'pml-options'">
            PML Options
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="p-3" *ngIf="currentTab === 'applications'">
          <div class="row">
            <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Applications</div>
                <div class="col-8">
                  <select class="form-select" id="selectedApp" name="selectedApp" [name]="'selectedApp'"
                    [(ngModel)]="selectedApplicationId">
                    <option [ngValue]="null" [disabled]="true">--Select One--</option>
                    <option [ngValue]="option.value" *ngFor="let option of applicationOptions">
                      {{option.name}}</option>
                  </select>
                </div>
              </div>
              <h3 class="card-title text-warning">Applicant Info</h3>
              <hr class="mt-0">
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">First Name</div>
                <div class="col-8">
                  <input class="form-control" name="firstName" [disabled]="true"
                    [(ngModel)]="productSearchRequest.borrowerInformation.firstName" />
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Middle Name</div>
                <div class="col-8">
                  <input class="form-control" name="middleName" [disabled]="true"
                    [(ngModel)]="primaryBorrower.middleName" />
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Last Name</div>
                <div class="col-8">
                  <input class="form-control" name="lastName" [disabled]="true"
                    [(ngModel)]="productSearchRequest.borrowerInformation.lastName" />
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Suffix</div>
                <div class="col-8">
                  <select class="form-select" id="suffix" name="suffix" [disabled]="true"
                    [(ngModel)]="primaryBorrower.nameSuffix">
                    <option [value]="null">--Select One--</option>
                    <ng-container *ngFor="let option of suffixes">
                      <option [ngValue]="option.value">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('BorrowerInformation.Citizenship')">
                <div class="col-4 align-self-center text-end">Citizenship</div>
                <div class="col-8">
                  <select class="form-select" id="citizenship" name="citizenship" section="applications-info"
                    [(ngModel)]="productSearchRequest.borrowerInformation.citizenship"
                    [disabled]="readonlyFields.includes('BorrowerInformation.Citizenship')">
                    <option [value]="null">--Select One--</option>
                    <ng-container *ngFor="let option of citizenshipOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('BorrowerInformation.Citizenship')?.includes(option.alternateValue)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="showMonthlyIncome && !hiddenFields.includes('BorrowerInformation.MonthlyIncome')">
                <div class="col-4 align-self-center text-end">Monthly Income</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <currency-input [name]="'monthlyIncome'" [required]="true" [allowNegative]="false"
                        section="applications-info" [readonly]="false"
                        [disabled]="readonlyFields.includes('BorrowerInformation.MonthlyIncome')" [min]="0"
                        [(ngModel)]="productSearchRequest.borrowerInformation.monthlyIncome">
                      </currency-input>
                    </span>
                    <span>
                      <a class="x" (click)="onBorrowerMonthlyIncomeExplainClicked()">explain</a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('BorrowerInformation.SelfEmployed')">
                <div class="col-4 align-self-center text-end">Self Employed?</div>
                <div class="col-8 d-flex align-items-center">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="selfEmployed" type="checkbox" section="applications-info"
                      [disabled]="readonlyFields.includes('BorrowerInformation.SelfEmployed')"
                      [(ngModel)]="productSearchRequest.borrowerInformation.selfEmployed" />
                    <span class="fs-6"> {{productSearchRequest.borrowerInformation.selfEmployed ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Is Eligible for VA Loan?</div>
                <div class="col-8 d-flex align-items-center">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <label class="form-check form-check-inline">
                        <input class="form-check-input" name="isVALoan" type="checkbox" [disabled]="true"
                          [(ngModel)]="isVALoan" />
                        <span class="fs-6"> {{isVALoan ? 'Yes' : 'No'}}</span>
                      </label>
                    </span>
                    <span>
                      <a class="x" (click)="isEligibleforVALoanDetermineClicked()">Determine</a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('BorrowerInformation.FirstTimeHomeBuyer')">
                <div class="col-4 align-self-center text-end">First Time Home Buyer?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="firstTimeHomeBuyer" type="checkbox" [required]="false"
                      [disabled]="readonlyFields.includes('BorrowerInformation.FirstTimeHomeBuyer')"
                      section="applications-info"
                      [(ngModel)]="productSearchRequest.borrowerInformation.firstTimeHomeBuyer" />
                    <span class="fs-6"> {{productSearchRequest.borrowerInformation.firstTimeHomeBuyer ? 'Yes' :
                      'No'}}</span>
                  </label>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <ng-container *ngIf="isVALoan">
                <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.ExemptFromVaFundingFee')">
                  <div class="col-4 align-self-center text-end">Is VA Funding Fee Exempt?</div>
                  <div class="col-8 d-flex align-items-center">
                    <label class="form-check form-check-inline">
                      <input class="form-check-input" name="exemptFromVaFundingFee" type="checkbox"
                        #exemptFromVaFundingFee="ngModel" section="applications-info"
                        [(ngModel)]="productSearchRequest.loanInformation.exemptFromVaFundingFee"
                        (change)="onExemptFromFundingFeeChanged()" required
                        [disabled]="readonlyFields.includes('LoanInformation.ExemptFromVaFundingFee')"
                        [ngClass]="{'is-invalid' : exemptFromVaFundingFee && exemptFromVaFundingFee.touched && exemptFromVaFundingFee.invalid}" />
                      <span class="fs-6"> {{productSearchRequest.loanInformation.exemptFromVaFundingFee ? 'Yes' :
                        'No'}}</span>
                      <span class="fs-6 invalid-feedback d-inline">This field is required</span>
                    </label>
                  </div>
                </div>
                <div class="row pb-1" *ngIf="!hiddenFields.includes('BorrowerInformation.VaFirstTimeUse')">
                  <div class="col-4 align-self-center text-end">Is VA First Time Use?</div>
                  <div class="col-8">
                    <label class="form-check form-check-inline">
                      <input class="form-check-input" name="vaFirstTimeUse" type="checkbox" required
                        [disabled]="readonlyFields.includes('BorrowerInformation.VaFirstTimeUse')"
                        [(ngModel)]="productSearchRequest.borrowerInformation.vaFirstTimeUse"
                        (ngModelChange)="onVaFirstTimeUseChanged()" section="applications-info" />
                      <span class="fs-6"> {{productSearchRequest.borrowerInformation.vaFirstTimeUse ? 'Yes' :
                        'No'}}</span>
                      <span class="fs-6 invalid-feedback d-inline">This field is required</span>
                    </label>
                  </div>
                </div>
                <div class="row pb-1"
                  *ngIf="!visibleFeatureFields.includes('Borrower_DisableVeteranType') && !hiddenFields.includes('BorrowerInformation.TypeOfVeteran')">
                  <div class="col-4 align-self-center text-end">Va Veteran Type/History</div>
                  <div class="col-8">
                    <select class="form-select" name="typeOfVeteran" id="typeOfVeteran" #typeOfVeteran="ngModel"
                      [(ngModel)]="productSearchRequest.borrowerInformation.typeOfVeteran" section="applications-info"
                      [disabled]="readonlyFields.includes('BorrowerInformation.TypeOfVeteran')"
                      [ngClass]="{'is-invalid' : typeOfVeteran && typeOfVeteran.touched && typeOfVeteran.invalid}"
                      [required]="true">
                      <option [ngValue]="null"> --Select One-- </option>
                      <ng-container *ngFor="let option of typeOfVeteranOptions">
                        <option value="{{option.alternateValue}}"
                          *ngIf="!hiddenEnumOptionsByField.get('BorrowerInformation.TypeOfVeteran')?.includes(option.alternateValue)">
                          {{option.name}}
                        </option>
                      </ng-container>
                    </select>
                    <div class="invalid-feedback">This field is required</div>
                  </div>
                </div>
              </ng-container>

              <div class="row pb-1" *ngIf="!hiddenFields.includes('BorrowerInformation.MonthsReserves')">
                <div class="col-4 align-self-center text-end">Months of Reserves</div>
                <div class="col-8">
                  <input class="form-control" name="monthsOfReserves" required mask="separator.3"
                    section="applications-info" [(ngModel)]="productSearchRequest.borrowerInformation.monthsReserves"
                    #monthsOfReserves="ngModel"
                    [disabled]="readonlyFields.includes('BorrowerInformation.MonthsReserves')"
                    [ngClass]="{'is-invalid' : monthsOfReserves && monthsOfReserves.touched && monthsOfReserves.invalid}" />
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('RepresentativeCreditScore')">
                <div class="col-4 align-self-center text-end">FICO</div>
                <div class="col-8">
                  <input class="form-control numeric-without-arrows" name="fico" id="fico" section="applications-info"
                    type="number" [disabled]="disableCreditScore" required numeric
                    [disabled]="readonlyFields.includes('RepresentativeCreditScore')"
                    [(ngModel)]="productSearchRequest.representativeCreditScore" #fico="ngModel"
                    [ngClass]="{'is-invalid' : fico && fico.touched && fico.invalid}" />
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Credit Scores</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span title="Experian">
                      XP:
                    </span>
                    <span>
                      <input class="form-control numeric-without-arrows" name="creditScore_Experian"
                        section="applications-info" type="number" numeric [disabled]="true"
                        [(ngModel)]="primaryBorrower.creditScore_Experian" />
                    </span>
                    <span title="TransUnion">
                      TU:
                    </span>
                    <span>
                      <input class="form-control numeric-without-arrows" name="creditScore_TransUnion"
                        section="applications-info" type="number" numeric [disabled]="true"
                        [(ngModel)]="primaryBorrower.creditScore_TransUnion" />
                    </span>
                    <span title="Equifax">
                      EF:
                    </span>
                    <span>
                      <input class="form-control numeric-without-arrows" name="creditScore_Equifax"
                        section="applications-info" type="number" numeric [disabled]="true"
                        [(ngModel)]="primaryBorrower.creditScore_Equifax" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Has Co-Applicant</div>
                <div class="col-8 d-flex align-items-center">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="hasCoApplicant" type="checkbox" [disabled]="true"
                      [(ngModel)]="hasCoApplicant" />
                    <span class="fs-6"> {{hasCoApplicant ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
              <h3 class="card-title text-warning">Financial Info</h3>
              <hr class="mt-0">
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Total Payment</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <currency-input [name]="'totalPayment'" section="applications-info" [readonly]="false"
                        [(ngModel)]="totalPayment">
                      </currency-input>
                    </span>
                    <span>
                      / month
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="visibleFeatureFields.includes('Borrower_TotalLiquidAssets') && !hiddenFields.includes('BorrowerInformation.TotalLiquidAssets')">
                <div class="col-4 align-self-center text-end">Total Liquid Assets</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <currency-input [name]="'totalLiquidAssets'"
                        [disabled]="readonlyFields.includes('BorrowerInformation.TotalLiquidAssets')"
                        [(ngModel)]="productSearchRequest.borrowerInformation.totalLiquidAssets">
                      </currency-input>
                    </span>
                    <span>
                      <a class="x" (click)="totalLiquidAssetsExplainClicked()">explain</a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Negative Cash Flow from Other Properties</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <currency-input [name]="'negativeCashFlow'" section="applications-info" [readonly]="false"
                        [(ngModel)]="negativeCashFlow">
                      </currency-input>
                    </span>
                    <span>
                      <a class="x" (click)="negativeCashFlowExplainClicked()">explain</a>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Credit Report Type</div>
                <div class="col-8">
                  <div class="ms-2">
                    <div class="radio radio-info form-check" *ngFor="let option of creditReportTypeOptions">
                      <input type="radio" id="{{option.name}}" class="form-check-input" [value]="option.value"
                        name="{{option.name}}" [(ngModel)]="creditReportType">
                      <label for="{{option.name}}" class="form-check-label"> {{option.name}} </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Credit Provider</div>
                <div class="col-8">
                  <select class="form-select" name="creditProvider" [name]="'creditProvider'"
                    #creditProviderInput="ngModel" [(ngModel)]="creditProvider"
                    [ngClass]="{'is-invalid' : creditProviderInput && creditProviderInput.touched && creditProviderInput.invalid}"
                    [required]="false">
                    <option [ngValue]="null">--Select One--</option>
                    <option [ngValue]="option.value" *ngFor="let option of creditProviders">
                      {{option.name}}</option>
                  </select>
                  <div class="invalid-feedback">This field is required</div>

                  <div>
                    <a class="x" (click)="isMyCreditProviderSupportedClicked()">Is my credit provider supported?</a>
                  </div>
                </div>
              </div>

              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Report ID</div>
                <div class="col-8">
                  <input class="form-control numeric-without-arrows" name="reportId" id="reportId"
                    section="applications-info" type="number" numeric [disabled]="true" [(ngModel)]="reportId" />
                  <div>

                    <button type="button" class="btn btn-outline-secondary loan-btn mt-2" placement="bottom"
                      [popover]="ficoPopoverTemplate" containerClass="fico" #ficoPopover="bs-popover"
                      (onShown)="onShownPopover(ficoPopover)" (onHidden)="onHiddenPopover()">
                      <span class="fw-bolder button-item-text">
                        View Credit Info
                      </span>
                      <i class="fas fa-angle-down ms-2"></i>
                    </button>
                    <ng-template #ficoPopoverTemplate>
                      <loan-fico-summary [isTpo]="isTpo" [applicationId]="applicationContext.application.applicationId"
                        [borrowers]="creditBorrowers"></loan-fico-summary>
                    </ng-template>

                    <!-- <a class="x" (click)="viewCreditReportClicked()">View Credit Report</a> -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="p-3" *ngIf="currentTab === 'property-and-loan-info'">
          <div class="row">
            <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
              <h3 class="card-title text-warning">Property Information</h3>
              <hr class="mt-0">
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Street Address</div>
                <div class="col-8">
                  <address-autocomplete-input #address='ngModel'
                    [(ngModel)]='productSearchRequest.propertyInformation.propertyStreetAddress' [required]="true"
                    (addressChange)='handleAddressChange($event)' class='custom-input-left-side'
                    id='propertyStreetAddress' name='propertyStreetAddress' placeholder=''
                    type='text'></address-autocomplete-input>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.ZipCode')">
                <div class="col-4 align-self-center text-end">Zip Code</div>
                <div class="col-8">
                  <zip-code-input [(ngModel)]="productSearchRequest.propertyInformation.zipCode" [required]="true"
                    (blur)="onZipCodeBlur($event)" style="width:100%" [name]="'propertyZIPfield'"
                    [disabled]="readonlyFields.includes('PropertyInformation.ZipCode')"
                    (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
                  </zip-code-input>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.State')">
                <div class="col-4 align-self-center text-end">State</div>
                <div class="col-8">
                  <select class="form-select" id="stateField" name="stateField" [name]="'stateField'"
                    #stateField="ngModel" [(ngModel)]="productSearchRequest.propertyInformation.state"
                    [disabled]="readonlyFields.includes('PropertyInformation.State')"
                    [ngClass]="{'is-invalid' : stateField && stateField.touched && stateField.invalid}" required>
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of stateOptions">
                      <option value="{{option.value}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.State')?.includes(option.value)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.County')">
                <div class="col-4 align-self-center text-end">County</div>
                <div class="col-8">
                  <input class="form-control" name="countyField" required #countyField="ngModel"
                    [ngClass]="{'is-invalid' : countyField && countyField.touched && countyField.invalid}"
                    [disabled]="readonlyFields.includes('PropertyInformation.County')"
                    (typeaheadOnSelect)="onCountySelect($event)"
                    [(ngModel)]="productSearchRequest.propertyInformation.county" [typeahead]="countyList"
                    typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="10" />
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.City')">
                <div class="col-4 align-self-center text-end">City</div>
                <div class="col-8">
                  <input id="cityField" name="cityField" type="text" class="form-control"
                    [disabled]="readonlyFields.includes('PropertyInformation.City')"
                    [(ngModel)]="productSearchRequest.propertyInformation.city" />
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="visibleFeatureFields.includes('Property_IsInRuralArea') && !hiddenFields.includes('PropertyInformation.IsInRuralArea')">
                <div class="col-4 align-self-center text-end">In Rural Area?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isInRuralArea" type="checkbox"
                      [disabled]="readonlyFields.includes('PropertyInformation.IsInRuralArea')"
                      [(ngModel)]="productSearchRequest.propertyInformation.isInRuralArea" />
                    <span class="fs-6"> {{productSearchRequest.propertyInformation.isInRuralArea ? 'Yes' : 'No'}}</span>
                  </label>
                  <span class="ms-2">
                    <a class="x" (click)="isInRuralAreaExplainClicked()">explain</a>
                  </span>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.Occupancy')">
                <div class="col-4 align-self-center text-end">Occupancy</div>
                <div class="col-8">
                  <select class="form-select" name="occupancy" id="occupancy"
                    [(ngModel)]="productSearchRequest.propertyInformation.occupancy"
                    [disabled]="readonlyFields.includes('PropertyInformation.Occupancy')">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of occupancyOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.Occupancy')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.ConstructionMethod')">
                <div class="col-4 align-self-center text-end">Construction Method</div>
                <div class="col-8">
                  <select class="form-select" id="constructionMethod" name="constructionMethod"
                    #constructionMethod="ngModel"
                    [(ngModel)]="productSearchRequest.propertyInformation.constructionMethod"
                    [disabled]="readonlyFields.includes('PropertyInformation.ConstructionMethod')">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of constructionMethodOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ConstructionMethod')?.includes(option.alternateValue)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.AttachmentType')">
                <div class="col-4 align-self-center text-end">Attachment Type</div>
                <div class="col-8">
                  <select class="form-select" id="propertyAttachmentType" name="propertyAttachmentType"
                    #propertyAttachmentType="ngModel"
                    [(ngModel)]="productSearchRequest.propertyInformation.attachmentType"
                    [disabled]="readonlyFields.includes('PropertyInformation.AttachmentType')">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of attachmentTypeOptions">
                      <option
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.AttachmentType')?.includes(option.alternateValue)"
                        value="{{option.alternateValue}}">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="!hiddenFields.includes('PropertyInformation.ManufacturedHomeWidthType') && isConstructionTypeManufactured()">
                <div class="col-4 align-self-center text-end">Home Width Type</div>
                <div class="col-8">
                  <select class="form-select" name="manufacturedHomeWidthType" id="manufacturedHomeWidthType"
                    [disabled]="readonlyFields.includes('PropertyInformation.ManufacturedHomeWidthType')"
                    [(ngModel)]="productSearchRequest.propertyInformation.manufacturedHomeWidthType">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of manufacturedHomeWidthTypeOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ManufacturedHomeWidthType')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.ProjectType')">
                <div class="col-4 align-self-center text-end">Project Type</div>
                <div class="col-8">
                  <select class="form-select" name="projectType" id="projectType"
                    [disabled]="readonlyFields.includes('PropertyInformation.ProjectType')"
                    [(ngModel)]="productSearchRequest.propertyInformation.projectType">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of projectTypeOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ProjectType')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.ProjectDesignType')">
                <div class="col-4 align-self-center text-end">Project Design Type</div>
                <div class="col-8">
                  <select class="form-select" name="projectDesignType" id="projectDesignType"
                    [disabled]="readonlyFields.includes('PropertyInformation.ProjectDesignType')"
                    [(ngModel)]="productSearchRequest.propertyInformation.projectDesignType">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of projectDesignTypeOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ProjectDesignType')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="showNonWarrantableProjectCheckBox() && !hiddenFields.includes('PropertyInformation.IsNonWarrantableProject')">
                <div class="col-4 align-self-center text-end">Non Warrantable Project?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input id="isNonWarrantableProject" type="checkbox" name="isNonWarrantableProject"
                      class="form-check-input"
                      [disabled]="readonlyFields.includes('PropertyInformation.IsNonWarrantableProject')"
                      [(ngModel)]="productSearchRequest.propertyInformation.isNonWarrantableProject">
                    <span class="fs-6"> {{productSearchRequest.propertyInformation.isNonWarrantableProject ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="isProjectTypeCondominium() && visibleFeatureFields.includes('Property_Condotel') && !hiddenFields.includes('PropertyInformation.IsCondotel')">
                <div class="col-4 align-self-center text-end">Is Condotel?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input id="isCondotel" type="checkbox" name="isCondotel" class="form-check-input"
                      [disabled]="readonlyFields.includes('PropertyInformation.IsCondotel')"
                      [(ngModel)]="productSearchRequest.propertyInformation.isCondotel">
                    <span class="fs-6"> {{productSearchRequest.propertyInformation.isCondotel ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.IsPlannedUnitDevelopment')">
                <div class="col-4 align-self-center text-end">Planned Unit Development (PUD)?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input id="isPlannedUnitDevelopment" type="checkbox" name="isPlannedUnitDevelopment"
                      class="form-check-input"
                      [disabled]="readonlyFields.includes('PropertyInformation.IsPlannedUnitDevelopment')"
                      [(ngModel)]="productSearchRequest.propertyInformation.isPlannedUnitDevelopment">
                    <span class="fs-6"> {{productSearchRequest.propertyInformation.isPlannedUnitDevelopment ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.NumberOfUnits')">
                <div class="col-4 align-self-center text-end">Number of Units</div>
                <div class="col-8">
                  <select class="form-select" name="numberOfUnits" id="numberOfUnits"
                    [(ngModel)]="productSearchRequest.propertyInformation.numberOfUnits"
                    [disabled]="readonlyFields.includes('PropertyInformation.NumberOfUnits')">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of numberOfUnitsOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.NumberOfUnits')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.NumberOfStories')">
                <div class="col-4 align-self-center text-end">Number of Stories</div>
                <div class="col-8">
                  <input numeric class="form-control" id="numberOfStories" name="numberOfStories" mask="separator.3"
                    [disabled]="readonlyFields.includes('PropertyInformation.NumberOfStories')"
                    [(ngModel)]="productSearchRequest.propertyInformation.numberOfStories">
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="visibleFeatureFields.includes('Property_IsSeasonalProperty') && !hiddenFields.includes('PropertyInformation.IsSeasonalProperty')">
                <div class="col-4 align-self-center text-end">In Seasonal Property?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isSeasonalProperty" type="checkbox"
                      [disabled]="readonlyFields.includes('PropertyInformation.IsSeasonalProperty')"
                      [(ngModel)]="productSearchRequest.propertyInformation.isSeasonalProperty" />
                    <span class="fs-6"> {{productSearchRequest.propertyInformation.isSeasonalProperty ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">New Construction?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isNewConstruction" type="checkbox" [disabled]="false"
                      [(ngModel)]="isNewConstruction" />
                    <span class="fs-6"> {{isNewConstruction ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Additional Monthly Housing Expenses</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center">
                    <span>
                      <currency-input [name]="'areaMedianIncome'" section="loan-and-property-info" [readonly]="true"
                        [(ngModel)]="proposedMonthlyPaymentTotal">
                      </currency-input>
                    </span>
                    <span class="ms-2">
                      <a class="x" (click)="monthlyHousingExpensesCalculateClicked()">calculate</a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="visibleFeatureFields.includes('Property_AreaMedianIncome') && !hiddenFields.includes('PropertyInformation.AreaMedianIncome')">
                <div class="col-4 align-self-center text-end">Area Median Income</div>
                <div class="col-8">
                  <currency-input [name]="'areaMedianIncome'" section="loan-and-property-info"
                    [readonly]="readonlyFields.includes('PropertyInformation.AreaMedianIncome')"
                    [(ngModel)]="productSearchRequest.propertyInformation.areaMedianIncome">
                  </currency-input>
                </div>
              </div>
            </div>
            <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
              <h3 class="card-title text-warning">Loan Information</h3>
              <hr class="mt-0">
              <div class="row pb-1"
                *ngIf="multipleLoanTypesVisible === false && !hiddenFields.includes('LoanInformation.LoanType')">
                <div class="col-4 align-self-center text-end">Loan Type</div>
                <div class="col-8">
                  <select class="form-select" name="loanType" id="loanType"
                    [disabled]="readonlyFields.includes('LoanInformation.LoanType')"
                    [(ngModel)]="productSearchRequest.loanInformation.loanType" (ngModelChange)="onLoanTypeChanged()">
                    <option [ngValue]="null"> --Select One-- </option>
                    <ng-container *ngFor="let option of filteredLoanTypeOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.LoanType')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.LoanPurpose')">
                <div class="col-4 align-self-center text-end">Loan Purpose</div>
                <div class="col-8">
                  <select class="form-select" id="loanPurposeField" section="loan-and-property-info"
                    name="loanPurposeField" #loanPurposeField="ngModel"
                    [ngClass]="{'is-invalid' : loanPurposeField && loanPurposeField.touched && loanPurposeField.invalid}"
                    [(ngModel)]="productSearchRequest.loanInformation.loanPurpose" required
                    (ngModelChange)="onLoanPurposeChanged()" section="loan-and-property-info"
                    [disabled]="readonlyFields.includes('LoanInformation.LoanPurpose')">
                    <option [ngValue]="null">--Select One--</option>
                    <option *ngFor="let option of loanPurposeOptions" value="{{option.alternateValue}}">
                      {{option.name}}</option>
                  </select>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="['RefinanceCashOut', 'Refinance', 'StreamlineRefinance'].includes(productSearchRequest.loanInformation.loanPurpose) && !hiddenFields.includes('LoanInformation.RefinancePurpose')">
                <div class="col-4 align-self-center text-end">Refinance Purpose</div>
                <div class="col-8">
                  <select class="form-select" id="refinancePurposeField" section="loan-and-property-info"
                    name="refinancePurposeField" #refinancePurposeField="ngModel"
                    [ngClass]="{'is-invalid' : refinancePurposeField && refinancePurposeField.touched && refinancePurposeField.invalid}"
                    [(ngModel)]="productSearchRequest.loanInformation.refinancePurpose"
                    [disabled]="readonlyFields.includes('LoanInformation.RefinancePurpose')"
                    (ngModelChange)="onRefiPurposeChanged()" required>
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of refinancePurposeOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.RefinancePurpose')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>

                  </select>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="productSearchRequest.loanInformation.loanPurpose === 'Purchase' && !hiddenFields.includes('PropertyInformation.SalesPrice')">
                <div class="col-4 align-self-center text-end">Purchase Price</div>
                <div class="col-8">
                  <currency-input [name]="'purchasePrice'" [required]="true" [isZeroValid]="false"
                    [allowNegative]="false" [allowDecimals]="false" section="loan-and-property-info"
                    [disabled]="readonlyFields.includes('PropertyInformation.SalesPrice')"
                    [(ngModel)]="productSearchRequest.propertyInformation.salesPrice" (blur)="onPurchasePriceChanged()">
                  </currency-input>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="visibleFeatureFields.includes('Loan_IsStudentLoanCashout') && productSearchRequest.loanInformation.refinancePurpose === 'CashOutOther' && !hiddenFields.includes('LoanInformation.IsStudentLoanCashout')">
                <div class="col-4 align-self-center text-end">Is Student Loan Cashout</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isStudentLoanCashout" type="checkbox"
                      section="loan-and-property-info"
                      [(ngModel)]="productSearchRequest.loanInformation.isStudentLoanCashout"
                      [disabled]="readonlyFields.includes('LoanInformation.IsStudentLoanCashout')" />
                    <span class="fs-6"> {{productSearchRequest.loanInformation.isStudentLoanCashout ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">New Loan is Texas 50(a)(6)?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isNewLoanIstexas50a6" type="checkbox" [disabled]="false"
                      [(ngModel)]="isNewLoanIstexas50a6" />
                    <span class="fs-6"> {{isNewLoanIstexas50a6 ? 'Yes' : 'No'}}</span>
                  </label>
                  <span class="ms-2">
                    <a class="x" (click)="isNewLoanIstexas50a6ExplainClicked()">explain</a>
                  </span>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Prior Loan is Texas 50(a)(6)?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isPriorLoanIstexas50a6" type="checkbox" [disabled]="false"
                      [(ngModel)]="isPriorLoanIstexas50a6" />
                    <span class="fs-6"> {{isPriorLoanIstexas50a6 ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Current Loan P&I Payment</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center">
                    <span>
                      <currency-input [name]="'areaMedianIncome'" section="loan-and-property-info" [readonly]="true"
                        [(ngModel)]="piTiPaymentInfo.pi">
                      </currency-input>
                    </span>
                    <span class="ms-2">
                      <a class="x" (click)="currentLoanPAndIPaymentModifyClicked()">Modify</a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Current Mip/Month</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center">
                    <span>
                      <currency-input [name]="'currentMipMonth'" section="loan-and-property-info" [readonly]="false"
                        [(ngModel)]="currentMipMonth">
                      </currency-input>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.WaiveEscrows')">
                <div class="col-4 align-self-center text-end">Waive Escrows?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="waiveEscrows" type="checkbox" [disabled]="false"
                      [disabled]="readonlyFields.includes('LoanInformation.WaiveEscrows')" required
                      [(ngModel)]="productSearchRequest.loanInformation.waiveEscrows" />
                    <span class="fs-6"> {{productSearchRequest.loanInformation.waiveEscrows ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="visibleFeatureFields.includes('Loan_DocumentationType') && !hiddenFields.includes('LoanInformation.DocumentationType')">
                <div class="col-4 align-self-center text-end">Documentation Type</div>
                <div class="col-8">
                  <select class="form-select" id="documentationType" name="documentationType"
                    #documentationType="ngModel" section="loan-and-property-info"
                    [disabled]="readonlyFields.includes('LoanInformation.DocumentationType')"
                    [ngClass]="{'is-invalid' : documentationType && documentationType.touched && documentationType.invalid}"
                    [(ngModel)]="productSearchRequest.loanInformation.documentationType" required>
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of filteredDocumentationTypes">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.DocumentationType')?.includes(option.alternateValue)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.LienType')">
                <div class="col-4 align-self-center text-end">Lien Type</div>
                <div class="col-8">
                  <select class="form-select" id="lienPositionType" section="loan-and-property-info"
                    name="lienPositionType" #lienPositionType="ngModel"
                    [disabled]="readonlyFields.includes('LoanInformation.LienType')"
                    [ngClass]="{'is-invalid' : lienPositionType && lienPositionType.touched && lienPositionType.invalid}"
                    [(ngModel)]="productSearchRequest.loanInformation.lienType" (ngModelChange)="onLienTypeChanged()"
                    required>
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of lienPositionTypeOptions">
                      <option value="{{option.value}}"
                        *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.LienType')?.includes(option.value)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="productSearchRequest.loanInformation.lienType == 'FirstLien' &&
              isHomeEquityLoanType === false && helocOrMortgageLoanType == 'Mortgage' &&
              !hiddenFields.includes('LoanInformation.SecondLienAmount')">
                <div class="col-4 align-self-center text-end">Second Lien Amount</div>
                <div class="col-8">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <currency-input [name]="'secondLienAmount'"
                        [disabled]="true && readonlyFields.includes('LoanInformation.SecondLienAmount')"
                        [required]="true" section="loan-and-property-info"
                        [(ngModel)]="productSearchRequest.loanInformation.secondLienAmount"
                        (blur)="onSecondLienAmountChanged()">
                      </currency-input>
                      <div class="invalid-feedback">This field is required</div>
                    </div>
                    <a class="x" (click)="onSecondLienAmountCalculatorClicked()"><i
                        class="fa fa-calculator ms-2 mt-2"></i></a>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="productSearchRequest.loanInformation.lienType == 'FirstLien' &&
              isHomeEquityLoanType === false &&
              helocOrMortgageLoanType == 'HELOC' && !hiddenFields.includes('LoanInformation.HelocDrawnAmount')">
                <div class="col-4 align-self-center text-end">HELOC Drawn Amount</div>
                <div class="col-8">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <currency-input [name]="'helocDrawnAmount'"
                        [disabled]="true && readonlyFields.includes('LoanInformation.HelocDrawnAmount')"
                        [required]="true" section="loan-and-property-info"
                        [(ngModel)]="productSearchRequest.loanInformation.helocDrawnAmount"
                        (blur)="onSecondLienAmountChanged()">
                      </currency-input>
                      <div class="invalid-feedback">This field is required</div>
                    </div>
                    <a class="x" (click)="onSecondLienAmountCalculatorClicked()"><i
                        class="fa fa-calculator ms-2 mt-2"></i></a>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="productSearchRequest.loanInformation.lienType == 'FirstLien' &&
              isHomeEquityLoanType === false &&
            helocOrMortgageLoanType == 'HELOC' && !hiddenFields.includes('LoanInformation.HelocLineAmount')">
                <div class="col-4 align-self-center text-end">HELOC Line Amount</div>
                <div class="col-8">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <currency-input [name]="'helocLineAmount'"
                        [disabled]="true && readonlyFields.includes('LoanInformation.HelocLineAmount')"
                        [required]="true" section="loan-and-property-info"
                        [(ngModel)]="productSearchRequest.loanInformation.helocLineAmount"
                        (blur)="onSecondLienAmountChanged()">
                      </currency-input>
                      <div class="invalid-feedback">This field is required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.BaseLoanAmount')">
                <div class="col-4 align-self-center text-end">Base Loan Amount</div>
                <div class="col-8">
                  <currency-input [name]="'loanAmount'" [required]="true" [isZeroValid]="false" [allowDecimals]="false"
                    [disabled]="readonlyFields.includes('LoanInformation.BaseLoanAmount')"
                    section="loan-and-property-info" [(ngModel)]="productSearchRequest.loanInformation.baseLoanAmount"
                    (blur)="onBaseLoanAmountChanged()">
                  </currency-input>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="productSearchRequest.loanInformation.lienType != 'FirstLien' && !hiddenFields.includes('LoanInformation.FirstLienAmount')">
                <div class="col-4 align-self-center text-end">First Lien Amount</div>
                <div class="col-8">
                  <currency-input [name]="'firstLienAmount'" [required]="true" [allowNegative]="false"
                    section="loan-and-property-info" [(ngModel)]="productSearchRequest.loanInformation.firstLienAmount"
                    [disabled]="readonlyFields.includes('LoanInformation.FirstLienAmount')"
                    (blur)="onFirstLienAmountChanged()">
                  </currency-input>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('PropertyInformation.AppraisedValue')">
                <div class="col-4 align-self-center text-end">Appraised Value</div>
                <div class="col-8">
                  <currency-input [name]="'appraisedValue'" [required]="true" [allowNegative]="false"
                    [allowDecimals]="false" section="loan-and-property-info"
                    [(ngModel)]="productSearchRequest.propertyInformation.appraisedValue"
                    [disabled]="readonlyFields.includes('PropertyInformation.AppraisedValue')"
                    (blur)="onAppraisedValueChanged()">
                  </currency-input>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.CashOutAmount')">
                <div class="col-4 align-self-center text-end">Cash-Out Amount</div>
                <div class="col-8">
                  <currency-input [name]="'cashOutAmount'" [required]="!isPurchase" section="loan-and-property-info"
                    [disabled]="readonlyFields.includes('LoanInformation.CashOutAmount')" [allowDecimals]="false"
                    [(ngModel)]="productSearchRequest.loanInformation.cashOutAmount">
                  </currency-input>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.Ltv')">
                <div class="col-4 align-self-center text-end">LTV</div>
                <div class="col-8">
                  <percent-input [name]="'ltvAmount'" [required]="true" section="loan-and-property-info"
                    [rateIsTrueToTextValue]="false" [(ngModel)]="productSearchRequest.loanInformation.ltv"
                    [disabled]="readonlyFields.includes('LoanInformation.Ltv')" #ltvAmount="ngModel"
                    [ngClass]="{'is-invalid' : ltvAmount && ltvAmount.touched && ltvAmount.invalid}"
                    (keydown)="onLtvKeyDown($event)" (blur)="onLtvValueChanged()">
                  </percent-input>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.Cltv')">
                <div class="col-4 align-self-center text-end">CLTV</div>
                <div class="col-8">
                  <percent-input [name]="'cltvAmount'" [required]="true" section="loan-and-property-info"
                    [rateIsTrueToTextValue]="false" [(ngModel)]="productSearchRequest.loanInformation.cltv"
                    #cltvAmount="ngModel" [disabled]="readonlyFields.includes('LoanInformation.Cltv')"
                    [ngClass]="{'is-invalid' : (cltvAmount && cltvAmount.touched && cltvAmount.invalid) || productSearchRequest.loanInformation.ltv > productSearchRequest.loanInformation.cltv}"
                    (keydown)="onCltvKeyDown($event)" (blur)="onCltvValueChanged()">
                  </percent-input>
                  <div *ngIf="productSearchRequest.loanInformation.ltv > productSearchRequest.loanInformation.cltv"
                    class="invalid-feedback"> Cltv value must be equal or greater than Ltv </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.TaxesAndInsuranceMonthly')">
                <div class="col-4 text-end">Taxes and Insurance (mo)</div>
                <div class="col-8">
                  <currency-input [name]="'taxesAndInsurance'"
                    [disabled]="readonlyFields.includes('LoanInformation.TaxesAndInsuranceMonthly')"
                    [(ngModel)]="productSearchRequest.loanInformation.taxesAndInsuranceMonthly">
                  </currency-input>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Home Value</div>
                <div class="col-8">
                  <currency-input [name]="'homeValue'" section="loan-and-property-info" [readonly]="false"
                    [(ngModel)]="homeValue" (ngModelChange)="onHomeValueChanged()">
                  </currency-input>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Equity</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <percent-input [name]="'equityPercent'" [required]="true" section="loan-and-property-info"
                        [rateIsTrueToTextValue]="false" [(ngModel)]="equityPercent"
                        (ngModelChange)="onEquityPercentChanged()">
                      </percent-input>
                    </span>
                    <span>
                      <currency-input [name]="'equity'" section="loan-and-property-info" [readonly]="false"
                        [(ngModel)]="equity" (ngModelChange)="onEquityAmountChanged()">
                      </currency-input>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="isFirstLienVisible">
                <div class="col-4 align-self-center text-end">First Lien</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <percent-input [name]="'firstLienPercent'" [required]="false" section="loan-and-property-info"
                        [rateIsTrueToTextValue]="false" [(ngModel)]="firstLienPercent"
                        [disabled]="readonlyFields.includes('LoanInformation.FirstLienAmount')"
                        (ngModelChange)="onFirstLienPercentChanged()">
                      </percent-input>
                    </span>
                    <span>
                      <currency-input [name]="'firstLienAmount'" [required]="true" [allowNegative]="false"
                        section="loan-and-property-info" [readonly]="false"
                        [(ngModel)]="productSearchRequest.loanInformation.firstLienAmount"
                        [disabled]="readonlyFields.includes('LoanInformation.FirstLienAmount')"
                        (ngModelChange)="onFirstLienAmountChanged()">
                      </currency-input>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="isSecondLienVisible">
                <div class="col-4 align-self-center text-end">Second Lien</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <percent-input [name]="'secondLienPercent'" [required]="false" section="loan-and-property-info"
                        [rateIsTrueToTextValue]="false" [(ngModel)]="secondLienPercent"
                        [disabled]="readonlyFields.includes('LoanInformation.FirstLienAmount')"
                        (ngModelChange)="onSecondLienPercentChanged()">
                      </percent-input>
                    </span>
                    <span>
                      <currency-input [name]="'secondLienAmount'" [required]="true" [allowNegative]="false"
                        section="loan-and-property-info" [readonly]="false"
                        [(ngModel)]="productSearchRequest.loanInformation.secondLienAmount"
                        [disabled]="readonlyFields.includes('LoanInformation.SecondLienAmount')"
                        (ngModelChange)="onSecondLienAmountChanged()">
                      </currency-input>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 text-end">2nd Financing?</div>
                <div class="col-8">
                  <div class="ms-2 d-inline-flex align-items-center">
                    <div class="radio radio-info form-check-inline">
                      <input type="radio" id="yes" [value]="true" name="yes" [(ngModel)]="secondFinancing">
                      <label for="yes"> Yes </label>
                    </div>
                    <div class="radio radio-info form-check-inline ms-3">
                      <input type="radio" id="no" [value]="false" name="no" [(ngModel)]="secondFinancing">
                      <label for="no"> No </label>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 ms-n2' : ''}}">
              <h3 class="card-title text-warning">Other Information</h3>
              <hr class="mt-0">
              <div class="row pb-1">
                <div class="col-4 text-end">Loan Originator is Paid By</div>
                <div class="col-8">
                  <div class="ms-2 d-inline-flex align-items-center">
                    <div class="radio radio-info form-check-inline">
                      <input type="radio" id="lender" [value]="'Lender'" name="lender" [(ngModel)]="paidBy">
                      <label for="lender"> Lender </label>
                    </div>
                    <div class="radio radio-info form-check-inline ms-3">
                      <input type="radio" id="borrower" [value]="'Borrower'" name="borrower" [(ngModel)]="paidBy">
                      <label for="borrower"> Borrower </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-12">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <percent-input [name]="'paidByPercent'" [required]="false" section="loan-and-property-info"
                        [rateIsTrueToTextValue]="false" [(ngModel)]="paidByPercent" [disabled]="false"
                        (ngModelChange)="onPaidByPercentChanged()">
                      </percent-input>
                    </span>
                    <span>
                      of
                    </span>
                    <span>
                      <select class="form-select" id="paidByBaseOption" section="loan-and-property-info"
                        name="paidByBaseOption" [(ngModel)]="selectedPaidByBaseOption"
                        (change)="onSelectedPaidByBaseChanged()">
                        <option [ngValue]="null">--Select One--</option>
                        <option [ngValue]="option.value" *ngFor="let option of paidByBaseOptions">
                          {{option.name}}
                        </option>
                      </select>
                    </span>
                    <span>
                      +
                    </span>
                    <span>
                      <currency-input [name]="'paidByAmount'" [required]="true" [allowNegative]="false"
                        section="loan-and-property-info" [readonly]="false" [(ngModel)]="paidByAmount"
                        (ngModelChange)="onPaidByAmountChanged()">
                      </currency-input>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Lender Fee Buyout Requested?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isLenderFeeBuyoutRequested" type="checkbox"
                      [(ngModel)]="isLenderFeeBuyoutRequested" />
                    <span class="fs-6"> {{isLenderFeeBuyoutRequested ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Expected AUS Response</div>
                <div class="col-8">
                  <select class="form-select" id="selectedExpectedAusResponse" section="loan-and-property-info"
                    name="selectedExpectedAusResponse" [(ngModel)]="selectedExpectedAusResponse">
                    <option [ngValue]="null">--Select One--</option>
                    <option [ngValue]="option.value" *ngFor="let option of expectedAusResponseOptions">
                      {{option.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Is DU Refi Plus?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="isDuRefiPlus" type="checkbox" [(ngModel)]="isDuRefiPlus" />
                    <span class="fs-6"> {{isDuRefiPlus ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Number of Financed Properties</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between">
                    <span>
                      <input class="form-control numeric-without-arrows" name="numberOfFinancedProperties"
                        id="numberOfFinancedProperties" section="loan-and-property-info" type="number" numeric
                        [disabled]="false"
                        [(ngModel)]="productSearchRequest.borrowerInformation.numberOfFinancedProperties"
                        #numberOfFinancedProperties="ngModel"
                        [ngClass]="{'is-invalid' : numberOfFinancedProperties && numberOfFinancedProperties.touched && numberOfFinancedProperties.invalid}" />
                    </span>
                    <span class="ms-2">
                      <a class="x" (click)="numberOfFinancedPropertiesExplainClicked()">explain</a>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanLevelDebtToIncomeRatio')">
                <div class="col-4 align-self-center text-end">DTI Ratio</div>
                <div class="col-8">
                  <percent-input name="loanLevelDebtToIncomeRatio" [rateIsTrueToTextValue]="false"
                    [disabled]="readonlyFields.includes('LoanLevelDebtToIncomeRatio')"
                    [(ngModel)]="productSearchRequest.loanLevelDebtToIncomeRatio" #loanLevelDebtToIncomeRatio="ngModel">
                  </percent-input>
                </div>
              </div>

              <div class="row pb-1"
                *ngIf="isHomeEquityLoanType === false && productSearchRequest.loanInformation.lienType !== 'SecondLien' && !hiddenFields.includes('LoanInformation.CalculateTotalLoanAmount')">
                <div class="col-4 align-self-center text-end">Manually enter PMI/MIP/FF/G Fee Detail</div>
                <div class="col-8 d-inline-flex align-items-center">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="manuallyEnterPmiMipFfGFeeDetails" type="checkbox"
                      [(ngModel)]="manuallyEnterPmiMipFfGFeeDetails" />
                    <span class="fs-6"> {{manuallyEnterPmiMipFfGFeeDetails ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>

              <ng-container *ngIf="manuallyEnterPmiMipFfGFeeDetails">
                <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfPercent')">
                  <div class="col-4 align-self-center text-end">PMI/MIP/FF/G Fee %</div>
                  <div class="col-8">
                    <percent-input name="upfrontPmiMipFfGfPercent"
                      [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent"
                      [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfPercent')"
                      [rateIsTrueToTextValue]="false" (blur)="onUpfrontPmiMipFfGfPercentChanged()"
                      #upfrontPmiMipFfGfPercent="ngModel">
                    </percent-input>
                  </div>
                </div>
                <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfAmount')">
                  <div class="col-4 align-self-center text-end">PMI/MIP/FF/G Fee Amount</div>
                  <div class="col-8">
                    <currency-input [name]="'upfrontPmiMipFfGfAmount'"
                      [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfAmount"
                      [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfAmount')"
                      (ngModelChange)="onUpfrontPmiMipFfGfAmountChanged()">
                    </currency-input>
                  </div>
                </div>
                <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfPaidinCash')">
                  <div class="col-4 align-self-center text-end">PMI/MIP/FF/G Fee Paid in Cash</div>
                  <div class="col-8">
                    <currency-input [name]="'upfrontPmiMipFfGfPaidinCash'"
                      [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfPaidinCash"
                      [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfPaidinCash')"
                      (ngModelChange)="onUpfrontPmiMipFfGfPaidinCashChanged()" [disabled]="financeEntireAmount">
                    </currency-input>
                  </div>
                </div>
                <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfFinancedAmount')">
                  <div class="col-4 align-self-center text-end">PMI/MIP/FF/G Fee Financed</div>
                  <div class="col-8">
                    <currency-input [name]="'upfrontPmiMipFfGfFinancedAmount'"
                      [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount"
                      [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfFinancedAmount')"
                      (ngModelChange)="onUpfrontPmiMipFfGfFinancedAmountChanged()">
                    </currency-input>
                  </div>
                </div>
                <ng-container *ngIf="isFHA">
                  <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.FhaCaseAssigned')">
                    <div class="col-4 align-self-center text-end">FHA Case Assigned Date</div>
                    <div class="col-8">
                      <date-input [name]="'fhaCaseAssigned'" [id]="'fhaCaseAssigned'"
                        [disabled]="readonlyFields.includes('LoanInformation.FhaCaseAssigned')"
                        [(ngModel)]="productSearchRequest.loanInformation.fhaCaseAssigned">
                      </date-input>
                    </div>
                  </div>
                  <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.FhaCaseEndorsement')">
                    <div class="col-4 align-self-center text-end">FHA Endorsement Date</div>
                    <div class="col-8">
                      <date-input [name]="'fhaCaseEndorsement'" [id]="'fhaCaseEndorsement'"
                        [disabled]="readonlyFields.includes('LoanInformation.FhaCaseEndorsement')"
                        [(ngModel)]="productSearchRequest.loanInformation.fhaCaseEndorsement">
                      </date-input>
                    </div>
                  </div>
                </ng-container>

              </ng-container>


              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Finance Entire Amount</div>
                <div class="col-8 d-inline-flex align-items-center">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" id="financeEntireAmount" [value]="financeEntireAmount"
                      type="checkbox" (change)="onChangeFinanceEntireAmount()" name="financeEntireAmount"
                      [(ngModel)]="financeEntireAmount">
                    <span class="fs-6"> {{financeEntireAmount ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>

              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.PrepaymentPenalty')">
                <div class="col-4 align-self-center text-end">Prepayment Penalty</div>
                <div class="col-8">
                  <select class="form-select" id="prepaymentPenalty" [name]="'prepaymentPenalty'"
                    [(ngModel)]="productSearchRequest.loanInformation.prepaymentPenalty"
                    section="loan-and-property-info"
                    [disabled]="readonlyFields.includes('LoanInformation.PrepaymentPenalty')">
                    <ng-container *ngFor="let option of prepaymentPenaltyOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.PrepaymentPenalty')?.includes(option.alternateValue)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('DesiredPrice')">
                <div class="col-4 align-self-center text-end">Desired Price</div>
                <div class="col-8">
                  <input name="'desiredPrice'" numeric [decimals]="3" class="form-control"
                    [disabled]="readonlyFields.includes('DesiredPrice')"
                    [(ngModel)]="productSearchRequest.desiredPrice" />
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('DesiredRate')">
                <div class="col-4 align-self-center text-end">Desired Rate</div>
                <div class="col-8">
                  <percent-input name="desiredRate" [(ngModel)]="productSearchRequest.desiredRate"
                    [disabled]="readonlyFields.includes('DesiredRate')" [rateIsTrueToTextValue]="false"
                    #desiredRate="ngModel">
                  </percent-input>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('DesiredLockPeriod')">
                <div class="col-4 text-end pt-2">Desired Lock Period</div>
                <div class="col-8">
                  <div class="d-inline-flex align-items-center justify-content-between gap-2">
                    <span>
                      <select class="form-select" id="desiredLockPeriod" [name]="'desiredLockPeriod'"
                        [disabled]="readonlyFields.includes('DesiredLockPeriod')"
                        [(ngModel)]="productSearchRequest.desiredLockPeriod">
                        <option [ngValue]="null">--Select One--</option>
                        <ng-container *ngFor="let option of desiredLockPeriodsList">
                          <option [ngValue]="option.value"
                            *ngIf="!hiddenEnumOptionsByField.get('DesiredLockPeriod')?.includes(option.value)">
                            {{option.value}}</option>
                        </ng-container>
                      </select>
                    </span>
                    <span>
                      days
                    </span>
                  </div>
                  <div class="text-muted mt-1">
                    Desired Lock Expiration Date: {{getExpirationDate() | date: 'shortDate'}} (Assumes a
                    {{productSearchRequest.desiredLockPeriod}}-day lock.)
                  </div>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('InterestOnly')">
                <div class="col-4 align-self-center text-end">Interest Only?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="interestOnly" type="checkbox"
                      [(ngModel)]="productSearchRequest.interestOnly" />
                    <span class="fs-6"> {{productSearchRequest.interestOnly ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('BuyDown')">
                <div class="col-4 align-self-center text-end">BuyDown</div>
                <div class="col-8">
                  <select class="form-select" id="buydown" [name]="'buydown'" [(ngModel)]="productSearchRequest.buydown"
                    [disabled]="readonlyFields.includes('Buydown')">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of filteredBuyDownOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('Buydown')?.includes(option.alternateValue)">
                        {{option.name}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.BorrowerPaidMi')">
                <div class="col-4 align-self-center text-end">Borrower Pays MI <small>(if required)</small></div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="borrowerPaidMi" type="checkbox"
                      [(ngModel)]="productSearchRequest.loanInformation.borrowerPaidMi" />
                    <span class="fs-6"> {{productSearchRequest.loanInformation.borrowerPaidMi ? 'Yes' : 'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1" *ngIf="!hiddenFields.includes('LoanInformation.AutomatedUnderwritingSystem')">
                <div class="col-4 align-self-center text-end">Automated U/W System</div>
                <div class="col-8">
                  <select class="form-select" id="automatedUnderwritingSystem" [name]="'automatedUnderwritingSystem'"
                    [disabled]="readonlyFields.includes('LoanInformation.AutomatedUnderwritingSystem')"
                    [(ngModel)]="productSearchRequest.loanInformation.automatedUnderwritingSystem">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of automatedUnderwritingSystemOptions">
                      <option value="{{option.alternateValue}}"
                        [disabled]="readonlyFields.includes('LoanInformation.AutomatedUnderwritingSystem')">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="applicationChannel !== 'Correspondent' && !hiddenFields.includes('LoanInformation.IncludeFeesInBasePrice')">
                <div class="col-4 align-self-center text-end">Fees In?</div>
                <div class="col-8">
                  <label class="form-check form-check-inline">
                    <input class="form-check-input" name="includeFeesInBasePrice" type="checkbox"
                      [(ngModel)]="productSearchRequest.loanInformation.includeFeesInBasePrice" />
                    <span class="fs-6"> {{productSearchRequest.loanInformation.includeFeesInBasePrice ? 'Yes' :
                      'No'}}</span>
                  </label>
                </div>
              </div>
              <div class="row pb-1"
                *ngIf="applicationChannel !== 'Correspondent' && !hiddenFields.includes('CompInformation.IncludeLoCompensationInPricing')">
                <div class="col-4 align-self-center text-end">Compensation</div>
                <div class="col-8">
                  <select class="form-select" id="lenderPaidCompensation" [name]="'lenderPaidCompensation'"
                    [(ngModel)]="productSearchRequest.compInformation.includeLoCompensationInPricing"
                    [ngClass]="{'is-invalid' : lenderPC && lenderPC.touched && lenderPC.invalid}" #lenderPC="ngModel"
                    required section="product-search-criteria"
                    [disabled]="readonlyFields.includes('CompInformation.IncludeLoCompensationInPricing')">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let option of includeLoCompensationInPricingOptions">
                      <option value="{{option.alternateValue}}"
                        *ngIf="!hiddenEnumOptionsByField.get('CompInformation.IncludeLoCompensationInPricing')?.includes(option.alternateValue)">
                        {{option.name}}</option>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback">This field is required</div>
                </div>
              </div>
              <ng-container
                *ngIf="productSearchRequest.compInformation.includeLoCompensationInPricing === 'BorrowerPaid'">
                <div class="row pb-1"
                  *ngIf="visibleFeatureFields.includes('Comp_Percent') && !hiddenFields.includes('CompInformation.Percent')">
                  <div class="col-4 align-self-center text-end">Compensation %</div>
                  <div class="col-8">
                    <percent-input name="compPercent" [(ngModel)]="productSearchRequest.compInformation.percent"
                      [disabled]="readonlyFields.includes('CompInformation.Percent')" [rateIsTrueToTextValue]="false"
                      #compPercent="ngModel">
                    </percent-input>
                  </div>
                </div>
                <div class="row pb-1"
                  *ngIf="visibleFeatureFields.includes('Comp_PercentBasedOn') && !hiddenFields.includes('CompInformation.PercentBasedOn')">
                  <div class="col-4 align-self-center text-end">% Based On</div>
                  <div class="col-8">
                    <select class="form-select" id="percentBasedOn" [name]="'percentBasedOn'"
                      [disabled]="readonlyFields.includes('CompInformation.PercentBasedOn')"
                      [(ngModel)]="productSearchRequest.compInformation.percentBasedOn">
                      <option [ngValue]="null">--Select One--</option>
                      <ng-container *ngFor="let option of compensationPercentBasedOnOptions">
                        <option value="{{option.alternateValue}}"
                          *ngIf="!hiddenEnumOptionsByField.get('CompInformation.PercentBasedOn')?.includes(option.alternateValue)">
                          {{option.name}}</option>
                      </ng-container>
                    </select>
                    <div class="invalid-feedback">This field is required</div>
                  </div>
                </div>
              </ng-container>
              <div class="row pb-1">
                <div class="col-4 align-self-center text-end">Pricing Profile:</div>
                <div class="col-8">
                  <select class="form-select" id="pricingProfile" name="pricingProfile" #pricingProfileInput="ngModel"
                    [(ngModel)]="selectedProfileChannel" (ngModelChange)="onPollyLenderPriceChannelChanged($event)"
                    required
                    [ngClass]="{'is-invalid' : pricingProfileInput && pricingProfileInput.touched && pricingProfileInput.invalid}">
                    <option [ngValue]="null">--Select One--</option>
                    <ng-container *ngFor="let profile of groupedVendorProfiles">
                      <optgroup *ngIf="profile.externalCompanyName else noCompanyGroup"
                        label="{{profile.externalCompanyName}}">
                        <ng-container *ngFor="let compCred of profile.externalCompanyCreds">
                          <ng-container *ngIf="compCred.branchName else noBranchGroup">
                            <option disabled class="option-group">
                              {{compCred.branchName}}
                            </option>
                            <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred">
                              &nbsp;&nbsp;{{cred.name}}</option>
                          </ng-container>
                          <ng-template #noBranchGroup>
                            <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred">
                              {{cred.name}}</option>
                          </ng-template>
                        </ng-container>
                      </optgroup>
                      <ng-template #noCompanyGroup>
                        <option *ngFor="let cred of profile.externalCompanyCreds" [ngValue]="cred">
                          {{cred.name}}</option>
                      </ng-template>
                    </ng-container>
                  </select>
                  <div class="invalid-feedback">Please, pick a pricing profile</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12"
              *ngFor="let section of customFieldSections | sort: 'asc':'displayOrder'; let i = index;">
              <div *ngIf="!section.name" class="row">
                <div *ngFor="let field of section.customFields | sort:'sortOrder' " class="col-md-4 p-2 me-1"
                  [hidden]="field.isHidden">
                  <div class="row pb-1">
                    <div class="col-4 align-self-center text-end">{{field.displayName}}</div>
                    <div class="col-8">
                      <ng-container *ngIf="field.type === 'List'">
                        <select class="form-select" name="'{{field.name}}'" #customFieldSelect="ngModel"
                          [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]"
                          (ngModelChange)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                          [ngClass]="{'is-invalid' : customFieldSelect && customFieldSelect.touched && customFieldSelect.invalid}"
                          [required]="field.isRequired" section="{{section.name}}">
                          <option [ngValue]="null">--Select One--</option>
                          <option value="{{opt.value}}" *ngFor="let opt of field.options">
                            {{opt.description}}
                          </option>
                        </select>
                      </ng-container>
                      <ng-container *ngIf="field.type === 'Text'">
                        <input class="form-control" type="text"
                          (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                          section="{{section.name}}" [required]="field.isRequired" name="'{{field.name}}'"
                          #customFieldTextInput="ngModel"
                          [ngClass]="{'is-invalid' : customFieldTextInput && customFieldTextInput.touched && customFieldTextInput.invalid}"
                          [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
                      </ng-container>
                      <ng-container *ngIf="field.type === 'Number'">
                        <input class="form-control" type="number"
                          (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                          section="{{section.name}}" [required]="field.isRequired" name="'{{field.name}}'"
                          #customFieldNumberInput="ngModel"
                          [ngClass]="{'is-invalid' : customFieldNumberInput && customFieldNumberInput.touched && customFieldNumberInput.invalid}"
                          [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
                      </ng-container>
                      <ng-container *ngIf="field.type === 'Checkbox'">
                        <label class="form-check form-check-inline">
                          <input class="form-check-input" name="'{{field.name}}'" type="checkbox"
                            [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]"
                            (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)" />
                        </label>
                      </ng-container>
                      <ng-container *ngIf="field.type === 'Date'">
                        <date-input class="form-control"
                          (onChange)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                          section="{{section.name}}" [required]="field.isRequired" name="'{{field.name}}'"
                          #customFieldDateInput="ngModel"
                          [ngClass]="{'is-invalid' : customFieldDateInput && customFieldDateInput.touched && customFieldDateInput.invalid}"
                          [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
                        </date-input>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!!section.name" class="row">
                <div class="col-md-12 g-0 accordion mb-1" [hidden]="isHiddenSection(section)">
                  <div class="accordion-item">
                    <h5 class="accordion-header m-0" id="expandedSection-{{i}}">
                      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#section-'+ i" aria-expanded="true"
                        [attr.aria-controls]="'section-' + i">
                        {{section.name}}
                      </button>
                    </h5>
                    <div id="section-{{i}}" class="accordion-collapse" aria-labelledby="expandedSection-{{i}}">
                      <div class="accordion-body">
                        <div *ngFor="let field of section.customFields | sort:'sortOrder' " class="col-md-4 p-2 me-1"
                          [hidden]="field.isHidden">
                          <div class="row pb-1">
                            <div class="col-4 align-self-center text-end">{{field.displayName}}</div>
                            <div class="col-8">
                              <ng-container *ngIf="field.type === 'List'">
                                <select class="form-select" name="'{{field.name}}'" #customFieldSelect="ngModel"
                                  [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]"
                                  (ngModelChange)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                                  [ngClass]="{'is-invalid' : customFieldSelect && customFieldSelect.touched && customFieldSelect.invalid}"
                                  [required]="field.isRequired" section="{{section.name}}">
                                  <option [ngValue]="null">--Select One--</option>
                                  <option value="{{opt.value}}" *ngFor="let opt of field.options">
                                    {{opt.description}}
                                  </option>
                                </select>
                              </ng-container>
                              <ng-container *ngIf="field.type === 'Text'">
                                <input class="form-control" type="text"
                                  (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                                  section="{{section.name}}" [required]="field.isRequired" name="'{{field.name}}'"
                                  #customFieldTextInput="ngModel"
                                  [ngClass]="{'is-invalid' : customFieldTextInput && customFieldTextInput.touched && customFieldTextInput.invalid}"
                                  [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
                              </ng-container>
                              <ng-container *ngIf="field.type === 'Number'">
                                <input class="form-control" type="number"
                                  (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                                  section="{{section.name}}" [required]="field.isRequired" name="'{{field.name}}'"
                                  #customFieldNumberInput="ngModel"
                                  [ngClass]="{'is-invalid' : customFieldNumberInput && customFieldNumberInput.touched && customFieldNumberInput.invalid}"
                                  [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
                              </ng-container>
                              <ng-container *ngIf="field.type === 'Checkbox'">
                                <label class="form-check form-check-inline">
                                  <input class="form-check-input" name="'{{field.name}}'" type="checkbox"
                                    [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]"
                                    (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)" />
                                </label>
                              </ng-container>
                              <ng-container *ngIf="field.type === 'Date'">
                                <date-input class="form-control"
                                  (onChange)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
                                  section="{{section.name}}" [required]="field.isRequired" name="'{{field.name}}'"
                                  #customFieldDateInput="ngModel"
                                  [ngClass]="{'is-invalid' : customFieldDateInput && customFieldDateInput.touched && customFieldDateInput.invalid}"
                                  [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
                                </date-input>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12 g-0 accordion mb-1">
              <div class="accordion-item">
                <h5 class="accordion-header m-0" id="expandedGuidelines">
                  <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#guidelines" aria-expanded="true" aria-controls="guidelines">
                    Expanded Guidelines
                  </button>
                </h5>
                <div id="guidelines" class="accordion-collapse collapse" aria-labelledby="expandedGuidelines">
                  <div class="accordion-body">
                    <div class="row">
                      <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
                        <div class="row">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_TradelineType') && !hiddenFields.includes('ExpandedGuidelines.TradelineType')">
                            <div class="mb-3">
                              <label class="form-label" for="tradelineType">Tradeline Type</label>
                              <select class="form-select" id="tradelineType" [name]="'tradelineType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.tradelineType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.TradelineType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of tradelineType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.TradelineType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_BankruptcyType') && !hiddenFields.includes('ExpandedGuidelines.BankruptcyType')">
                            <div class="mb-3">
                              <label class="form-label" for="bankruptcyType">Bankruptcy Type</label>
                              <select class="form-select" id="bankruptcyType" [name]="'bankruptcyType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcyType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of bankruptcyType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankruptcyType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsBankruptcy') && productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType != 'None' && !hiddenFields.includes('ExpandedGuidelines.BankruptcyMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="bankruptcyMonths">Bankruptcy(mon)</label>
                              <input class="form-control numeric-without-arrows" name="bankruptcyMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcyMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcyMonths" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_BankruptcyOutcome') && productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType != 'None' && !hiddenFields.includes('ExpandedGuidelines.BankruptcyOutcome')">
                            <div class="mb-3">
                              <label class="form-label" for="bankruptcyOutcome">Bankruptcy Outcome</label>
                              <select class="form-select" id="bankruptcyOutcome" [name]="'bankruptcyOutcome'"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcyOutcome')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcyOutcome"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of bankruptcyOutcome">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankruptcyOutcome')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_BankruptcySeasoning') && productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType != 'None' && !hiddenFields.includes('ExpandedGuidelines.BankruptcySeasoning')">
                            <div class="mb-3">
                              <label class="form-label" for="bankruptcySeasoning">Bankruptcy Seasoning</label>
                              <select class="form-select" id="bankruptcySeasoning" [name]="'bankruptcySeasoning'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcySeasoning"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcySeasoning')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of bankruptcySeasoning">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankruptcySeasoning')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_HousingEventType') && !hiddenFields.includes('ExpandedGuidelines.HousingEventType')">
                            <div class="mb-3">
                              <label class="form-label" for="housingEventType">Housing Event Type</label>
                              <select class="form-select" id="housingEventType" [name]="'housingEventType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.housingEventType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.HousingEventType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of housingEventType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.HousingEventType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_HousingEventSeasoning') && productSearchRequest.loanInformation.expandedGuidelines.housingEventType != 'NotApplicable' && !hiddenFields.includes('ExpandedGuidelines.HousingEventSeasoning')">
                            <div class="mb-3">
                              <label class="form-label" for="housingEventSeasoning">Housing Event Seasoning</label>
                              <select class="form-select" id="housingEventSeasoning" [name]="'housingEventSeasoning'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.housingEventSeasoning"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.HousingEventSeasoning')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of housingEventSeasoning">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.HousingEventSeasoning')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="visibleFeatureFields.includes('Expanded_IncomeVerificationMethod') && !hiddenFields.includes('ExpandedGuidelines.IncomeVerificationMethod')">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label class="form-label" for="incomeVerificationMethod">Income Verification
                                Method</label>
                              <select class="form-select" id="incomeVerificationMethod"
                                [name]="'incomeVerificationMethod'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.IncomeVerificationMethod')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of incomeVerificationMethod">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.IncomeVerificationMethod')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'BankStatement'">
                          <div class="col-md-12"
                            *ngIf="visibleFeatureFields.includes('Expanded_BankStatementExpenseMethod') && !hiddenFields.includes('ExpandedGuidelines.BankStatementExpenseMethod')">
                            <div class="mb-3">
                              <label class="form-label" for="bankStatementExpenseMethod">Bank Stmt. Expense
                                Method</label>
                              <select class="form-select" id="bankStatementExpenseMethod"
                                [name]="'bankStatementExpenseMethod'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankStatementExpenseMethod"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.BankStatementExpenseMethod')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of bankStatementExpenseMethod">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankStatementExpenseMethod')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsPersonalBankStatements') && !hiddenFields.includes('ExpandedGuidelines.MonthsPersonalBankStatements')">
                            <div class="mb-3">
                              <label class="form-label" for="mo">Months Personal Bank Statement</label>
                              <select class="form-select" id="monthsPersonalBankStatements"
                                [name]="'monthsPersonalBankStatements'"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsPersonalBankStatements')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsPersonalBankStatements"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of months1224">
                                  <option value="{{option.value}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsPersonalBankStatements')?.includes(option.value)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsBusinessBankStatements') && !hiddenFields.includes('ExpandedGuidelines.MonthsBusinessBankStatements')">
                            <div class="mb-3">
                              <label class="form-label" for="mo">Months Business Bank Statement</label>
                              <select class="form-select" id="monthsBusinessBankStatements"
                                [name]="'monthsBusinessBankStatements'"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsBusinessBankStatements')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsBusinessBankStatements"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of months1224">
                                  <option value="{{option.value}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsBusinessBankStatements')?.includes(option.value)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'VOE'">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_VOEAmount') && !hiddenFields.includes('ExpandedGuidelines.VoeAmount')">
                            <div class="mb-3">
                              <label class="form-label" for="voeAmount">VOE Amount</label>
                              <input class="form-control numeric-without-arrows" name="voeAmount"
                                section="expanded-guidelines"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.VoeAmount')" type="number"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.voeAmount" />
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'AssetQualification'">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_AssetQualificationAmount') && !hiddenFields.includes('ExpandedGuidelines.AssetAmount')">
                            <div class="mb-3">
                              <label class="form-label" for="assetAmount">Asset Amount</label>
                              <input class="form-control numeric-without-arrows" name="assetAmount"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.AssetAmount')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.assetAmount" />
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="visibleFeatureFields.includes('Expanded_IncomeVerificationType') && !hiddenFields.includes('ExpandedGuidelines.IncomeVerificationType')">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label class="form-label" for="incomeVerificationType">Income Verification Type</label>
                              <select class="form-select" id="incomeVerificationType" [name]="'incomeVerificationType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.IncomeVerificationType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of incomeVerificationType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.IncomeVerificationType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'Method1099'">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_Months1099') && !hiddenFields.includes('ExpandedGuidelines.Months1099')">
                            <div class="mb-3">
                              <label class="form-label" for="months1099">1099 Months</label>
                              <select class="form-select" id="months1099" [name]="'months1099'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.months1099"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.Months1099')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of months1224">
                                  <option value="{{option.value}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.Months1099')?.includes(option.value)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="(productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationType == 'InvestorDscr'
                          || productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'DSCR')">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_DebtServiceCoverageRatio') && !hiddenFields.includes('ExpandedGuidelines.DebtServiceCoverageRatio')">
                            <div class="mb-3">
                              <label class="form-label" for="debtServiceCoverageRatio">DSCR %</label>
                              <percent-input name="debtServiceCoverageRatio"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.DebtServiceCoverageRatio')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.debtServiceCoverageRatio"
                                [rateIsTrueToTextValue]="true" #debtServiceCoverageRatio="ngModel">
                              </percent-input>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_InvestorExperience') && !hiddenFields.includes('ExpandedGuidelines.InvestorExperience')">
                            <div class="mb-3">
                              <label class="form-label" for="investorExperience">Investor Experience</label>
                              <select class="form-select" id="investorExperience" [name]="'investorExperience'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.investorExperience"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.InvestorExperience')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of investorExperience">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.InvestorExperience')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'CPAPAndL'">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsCpaPandL') && !hiddenFields.includes('ExpandedGuidelines.MonthsCpaPandL')">
                            <div class="mb-3">
                              <label class="form-label" for="monthsCpaPandL">CPA P&L Months</label>
                              <select class="form-select" id="monthsCpaPandL" [name]="'monthsCpaPandL'"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsCpaPandL')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsCpaPandL"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of months1224">
                                  <option value="{{option.value}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsCpaPandL')?.includes(option.value)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row"
                          *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'FullDocument'">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsFullDocMethod') && !hiddenFields.includes('ExpandedGuidelines.MonthsFullDoc')">
                            <div class="mb-3">
                              <label class="form-label" for="monthsFullDoc">Full Doc (mon)</label>
                              <select class="form-select" id="monthsFullDoc" [name]="'monthsFullDoc'"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsFullDoc')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsFullDoc"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of months1224">
                                  <option value="{{option.value}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsFullDoc')?.includes(option.value)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
                        <div class="row">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsChargeOff') && !hiddenFields.includes('ExpandedGuidelines.ChargeOffMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="chargeOffMonths">Charge Off (mon)</label>
                              <input class="form-control numeric-without-arrows" name="chargeOffMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ChargeOffMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.chargeOffMonths" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsDeedInLieu') && !hiddenFields.includes('ExpandedGuidelines.DeedinLieuMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="deedinLieuMonths">Deed in Lieu (mon)</label>
                              <input class="form-control numeric-without-arrows" name="deedinLieuMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.DeedinLieuMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.deedinLieuMonths" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsNoticeOfDefault') && !hiddenFields.includes('ExpandedGuidelines.DefaultNoticeMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="defaultNoticeMonths">Default Notice (mon)</label>
                              <input class="form-control numeric-without-arrows" name="defaultNoticeMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.DefaultNoticeMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.defaultNoticeMonths" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsForeclosure') && !hiddenFields.includes('ExpandedGuidelines.ForeclosureMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="foreclosureMonths">Foreclosure (mon)</label>
                              <input class="form-control numeric-without-arrows" name="foreclosureMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ForeclosureMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.foreclosureMonths" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsLoanModification') && !hiddenFields.includes('ExpandedGuidelines.LoanModificationMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="loanModificationMonths">Loan Modification (mon)</label>
                              <input class="form-control numeric-without-arrows" name="loanModificationMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.LoanModificationMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.loanModificationMonths" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_MonthsShortSale') && !hiddenFields.includes('ExpandedGuidelines.ShortSaleMonths')">
                            <div class="mb-3">
                              <label class="form-label" for="shortSaleMonths">Short Sale (mon)</label>
                              <input class="form-control numeric-without-arrows" name="shortSaleMonths"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ShortSaleMonths')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.shortSaleMonths" />
                            </div>
                          </div>

                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_AssetDepletionAmount') && !hiddenFields.includes('ExpandedGuidelines.AssetDepletionAmount')">
                            <div class="mb-3">
                              <label class="form-label" for="assetDepletionAmount">Asset Depletion Amount</label>
                              <input class="form-control numeric-without-arrows" name="assetDepletionAmount"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.AssetDepletionAmount')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.assetDepletionAmount" />
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_PropertiesOwned') && !hiddenFields.includes('ExpandedGuidelines.PropertiesOwned')">
                            <div class="mb-3">
                              <label class="form-label" for="propertiesOwned">Properties Owned</label>
                              <input class="form-control numeric-without-arrows" name="propertiesOwned"
                                section="expanded-guidelines" type="number"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.PropertiesOwned')"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.propertiesOwned" />
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="visibleFeatureFields.includes('Expanded_DebtConsolidation') && !hiddenFields.includes('ExpandedGuidelines.DebtConsolidation')">
                          <div class="col-md-12">
                            <div class="form-check form-check-inline">
                              <div class="checkbox">
                                <input name="debtConsolidation" id="debtConsolidation"
                                  [value]="productSearchRequest.loanInformation.expandedGuidelines.debtConsolidation"
                                  type="checkbox"
                                  [disabled]="readonlyFields.includes('ExpandedGuidelines.DebtConsolidation')"
                                  [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.debtConsolidation">
                                <label for="debtConsolidation">
                                  Debt Consolidation
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="visibleFeatureFields.includes('Expanded_UniqueProperty') && !hiddenFields.includes('ExpandedGuidelines.UniqueProperty')">
                          <div class="col-md-12">
                            <div class="form-check form-check-inline">
                              <div class="checkbox">
                                <input name="uniqueProperty" id="uniqueProperty"
                                  [value]="productSearchRequest.loanInformation.expandedGuidelines.uniqueProperty"
                                  type="checkbox"
                                  [disabled]="readonlyFields.includes('ExpandedGuidelines.UniqueProperty')"
                                  [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.uniqueProperty">
                                <label for="uniqueProperty">
                                  Unique Property
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-md-12">
                            <div class="form-check form-check-inline">
                              <div class="checkbox">
                                <input name="hasMortgageLates" id="hasMortgageLates" [value]="hasMortgageLates"
                                  type="checkbox" [(ngModel)]="hasMortgageLates"
                                  (ngModelChange)="onHasMortgageLatesChanged()">
                                <label for="hasMortgageLates">
                                  Has Mortgage Lates
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="hasMortgageLates">
                          <div class="border p-2 position-relative"
                            *ngIf="visibleFeatureFields.includes('Expanded_MortgageLates')">
                            <span class="group-label"
                              style="background-color: #fff; padding: 3px; margin-top: -22px; position: absolute;"> Mtg
                              Lates
                              (0-12 months)
                            </span>
                            <div class="row">
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx3012Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml30">Mtg Lates x 30</label>
                                  <input class="form-control numeric-without-arrows" name="ml30" id="ml30"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx3012Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx3012Mos" />
                                </div>
                              </div>
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx6012Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml60">Mtg Lates x 60</label>
                                  <input class="form-control numeric-without-arrows" name="ml60" id="ml60"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx6012Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx6012Mos" />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx9012Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml90">Mtg Lates x 90</label>
                                  <input class="form-control numeric-without-arrows" name="ml90" id="ml90"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx9012Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx9012Mos" />
                                </div>
                              </div>
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12012Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml120">Mtg Lates x 120</label>
                                  <input class="form-control numeric-without-arrows" name="ml120" id="ml120"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx12012Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx12012Mos" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="border p-2 position-relative" style="margin-top:8px; margin-bottom: 8px;"
                            *ngIf="visibleFeatureFields.includes('Expanded_MortgageLates')">
                            <span class="group-label"
                              style="background-color: #fff; padding: 3px; margin-top: -22px; position: absolute;"> Mtg
                              Lates
                              (13-24 months)
                            </span>
                            <div class="row">
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx3013To24Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml30-1324">Mtg Lates x 30</label>
                                  <input class="form-control numeric-without-arrows" name="ml30-1324" id="ml30-1324"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx3013To24Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx3013To24Mos" />
                                </div>
                              </div>
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx6013To24Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml60-1324">Mtg Lates x 60</label>
                                  <input class="form-control numeric-without-arrows" name="ml60-1324" id="ml60-1324"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx6013To24Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx6013To24Mos" />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx9013To24Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml90-1324">Mtg Lates x 90</label>
                                  <input class="form-control numeric-without-arrows" name="ml90-1324" id="ml90-1324"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx9013To24Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx9013To24Mos" />
                                </div>
                              </div>
                              <div class="col-md-6"
                                *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12013To24Mos')">
                                <div class="mb-3">
                                  <label class="form-label" for="ml120-1324">Mtg Lates x 120</label>
                                  <input class="form-control numeric-without-arrows" name="ml120-1324" id="ml120-1324"
                                    section="expanded-guidelines" type="number"
                                    [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx12013To24Mos')"
                                    [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx12013To24Mos" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 ms-n2' : ''}}">
                        <div class="row">
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_DeedInLieuType') && !hiddenFields.includes('ExpandedGuidelines.DeedInLieuType')">
                            <div class="mb-3">
                              <label class="form-label" for="deedInLieuType">Deed In Lieu Type</label>
                              <select class="form-select" id="deedInLieuType" [name]="'deedInLieuType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.deedInLieuType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.DeedInLieuType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of deedInLieuType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.DeedInLieuType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_ChareOffType') && !hiddenFields.includes('ExpandedGuidelines.ChareOffType')">
                            <div class="mb-3">
                              <label class="form-label" for="chareOffType">Chare Off Type</label>
                              <select class="form-select" id="chareOffType" [name]="'chareOffType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.chareOffType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ChareOffType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of chareOffType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.ChareOffType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_ForeclosureType') && !hiddenFields.includes('ExpandedGuidelines.ForeclosureType')">
                            <div class="mb-3">
                              <label class="form-label" for="foreclosureType">Foreclosure Type</label>
                              <select class="form-select" id="foreclosureType" [name]="'foreclosureType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.foreclosureType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ForeclosureType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of foreclosureType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.ForeclosureType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_ShortSaleType') && !hiddenFields.includes('ExpandedGuidelines.ShortSaleType')">
                            <div class="mb-3">
                              <label class="form-label" for="shortSaleType">Short Sale Type</label>
                              <select class="form-select" id="shortSaleType" [name]="'shortSaleType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.shortSaleType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ShortSaleType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of shortSaleType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.ShortSaleType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-6"
                            *ngIf="visibleFeatureFields.includes('Expanded_ForbearanceType') && !hiddenFields.includes('ExpandedGuidelines.ForbearanceType')">
                            <div class="mb-3">
                              <label class="form-label" for="forbearanceType">Forbearance Type</label>
                              <select class="form-select" id="forbearanceType" [name]="'forbearanceType'"
                                [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.forbearanceType"
                                [disabled]="readonlyFields.includes('ExpandedGuidelines.ForbearanceType')"
                                section="expanded-guidelines">
                                <ng-container *ngFor="let option of forbearanceType">
                                  <option value="{{option.alternateValue}}"
                                    *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.ForbearanceType')?.includes(option.alternateValue)">
                                    {{option.name}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3" *ngIf="currentTab === 'pml-options'">
          <div class="row">
            <div class="col-xs-12 {{showMultipleColumns ? 'border p-2' : ''}}">
              <h3 class="card-title text-warning">Loan Program Options</h3>
              <hr class="mt-0">
              <div class="row">
                <div class="col-xs-12">
                  <h6>Loan Term(s)</h6>
                  <div class="row ps-2">
                    <label class="col-md-3 form-check form-check-inline"
                      *ngFor="let option of loanTermOptions; let i = index">
                      <input class="form-check-input" name="loanTerms-{{i}}" type="checkbox" [disabled]="false"
                        [ngModel]="selectedLoanTermsById.get(option.value)"
                        (ngModelChange)="selectedLoanTermsChanged(option.value, $event)" />
                      <span class="fs-6"> {{option.name}}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="!hiddenFields.includes('AmortizationType')">
                <div class="col-xs-12">
                  <h6>Amortization Type(s)</h6>
                  <div class="row ps-2">
                    <label class="col-md-3 form-check form-check-inline"
                      *ngFor="let option of amortizationTypeOptions; let i = index">
                      <input class="form-check-input" name="amortTypes-{{i}}" type="checkbox"
                        [disabled]="readonlyFields.includes('AmortizationType')"
                        [ngModel]="selectedAmortizationTypesById.get(option.value)"
                        (ngModelChange)="selectedAmortizationTypeChanged(option.value, $event)" />
                      <span class="fs-6"> {{option.name}}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="productSearchRequest.amortizationTypes.includes('ARM')">
                <div class="col-xs-12">
                  <h6>ARM Fixed Term(s)</h6>
                  <div class="row ps-2">
                    <label class="col-md-3 form-check form-check-inline"
                      *ngFor="let option of armFixedTermOptions; let i = index">
                      <input class="form-check-input" name="armFixedTerms-{{i}}" type="checkbox" [disabled]="false"
                        [ngModel]="selectedArmTermsById.get(option.value)"
                        (ngModelChange)="selectedArmFixedTermsChanged(option.value, $event)" />
                      <span class="fs-6"> {{option.name}}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mt-2"
                *ngIf="multipleLoanTypesVisible && !hiddenFields.includes('LoanInformation.LoanType')">
                <div class="col-xs-12">
                  <h6>Loan Type(s)</h6>
                  <div class="row ps-2">
                    <label class="col-md-3 form-check form-check-inline"
                      *ngFor="let option of filteredLoanTypeOptions; let i = index">
                      <input class="form-check-input" name="productTypes-{{i}}" type="checkbox"
                        [disabled]="readonlyFields.includes('LoanInformation.LoanType')"
                        [ngModel]="selectedLoanTypesById.get(option.value)"
                        (ngModelChange)="selectedLoanTypesChanged(option.value, $event)" />
                      <span class="fs-6"> {{option.name}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<loading-indicator [customClass]="'no-shadow'" *ngIf="!productSearchRequest"
  [loadingMessage]="'Loading parameters, please wait...'">
</loading-indicator>
