import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/operators';
import { LoanApplication } from 'src/app/models/loan/loan-info.model';
import { SendEmailSmsDialogComponent } from 'src/app/modules/correspondence/components/send-email-sms-dialog/send-email-sms-dialog.component';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FileAttachment } from 'src/app/shared/components/send-mms/send-mms.component';

export enum BenefitCalculatorType {
  LowerPayment = 1,
  DebtManagement,
  CashOut,
  ReduceTerm,
  RefiBenefits
}

@Component({
  selector: 'benefit-calculators',
  templateUrl: './benefit-calculators.component.html',
  styleUrls: ['./benefit-calculators.component.scss']
})
export class BenefitCalculatorsComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  currentLoanInterestRate: number;

  @Input()
  currentLoanTerm: number;

  @Input()
  application: LoanApplication;

  @Input()
  isRefinance: boolean = false;

  @ViewChild('lowerPayment', { read: ElementRef }) lowerPaymentCalcComponent: ElementRef | undefined;
  @ViewChild('debtManagement', { read: ElementRef }) debtManagementCalcComponent: ElementRef | undefined;
  @ViewChild('cashOut', { read: ElementRef }) cashOutCalcComponent: ElementRef | undefined;
  @ViewChild('reduceTerm', { read: ElementRef }) reduceTermCalcComponent: ElementRef | undefined;
  @ViewChild('refiBenefits', { read: ElementRef }) refiBenefitsCalcComponent: ElementRef | undefined;
  

  selectedCalculatorType: BenefitCalculatorType;

  isDetailShowing: boolean = false;

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _loanDocService: LoanDocService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  selectCalculator = (type: BenefitCalculatorType) => {
    this.selectedCalculatorType = type;
    this.isDetailShowing = true;
  }

  goBack = () => {
    this.isDetailShowing = false;
    this.selectedCalculatorType = null;
  }

  onSendAsHtmlInEmailClicked = () => {

    this._spinnerService.show();

    this.getHtmlContent()
      .subscribe({
        next: (htmlAndTitle) => {

          this._spinnerService.hide();

          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.body = htmlAndTitle.html;
          modalRef.componentInstance.isShowOnlyEmail = true;
          modalRef.result.then(() => {

          }, err => { });

        },
        error: (err) => {
          this._spinnerService.hide();

          this._notificationService.showError(err.message || err, "Error!");
        }
      });

  }

  onSendAsPdfInEmailClicked = () => {
    this._spinnerService.show();

    this.getHtmlContent()
      .pipe(
        switchMap(htmlAndTitle => this._loanDocService.renderHtmlToLoanDoc(htmlAndTitle.html, "PDF", htmlAndTitle.title, this.application.applicationId, this.application.primaryBorrowerId)),
        switchMap(loanDoc => this._loanDocService.getLoanDoc(loanDoc.loanDocId))
      )
      .subscribe({
        next: (loanDoc) => {

          this._spinnerService.hide();

          let docFile = new DocFile();
          docFile.guid = loanDoc.docFiles[0].guid;

          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.attachments = [docFile];
          modalRef.componentInstance.isShowOnlyEmail = true;
          modalRef.result.then(() => {

          }, err => { });

        },
        error: (err) => {
          this._spinnerService.hide();

          this._notificationService.showError(err.message || err, "Error!");
        }
      });
  }

  onSendAsImageInEmailClicked = () => {
    this._spinnerService.show();

    this.getHtmlContent()
      .pipe(
        switchMap(htmlAndTitle => this._loanDocService.renderHtmlToFile(htmlAndTitle.html, "JPG", htmlAndTitle.title)),
      )
      .subscribe({
        next: (response) => {

          this._spinnerService.hide();

          const file = new Blob([response.body], { type: 'image/jpeg' });

          const url = window.URL.createObjectURL(file);
          window.open(url);

          let reader = new FileReader();
          reader.readAsDataURL(file); // read file as data url
          reader.onload = (event) => {
            const image = "<img src='" + event.target.result as string + "'>";
            const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
            modalRef.componentInstance.body = image;
            modalRef.componentInstance.isShowOnlyEmail = true;
            modalRef.result.then(() => {

            }, err => { });

          }
        },
        error: (err) => {
          this._spinnerService.hide();

          this._notificationService.showError(err.message || err, "Error!");
        }
      });
  }

  onSendInTextClicked = () => {
    this._spinnerService.show();
    let fileTitle;

    this.getHtmlContent()
      .pipe(
        switchMap(htmlAndTitle => {
          fileTitle = htmlAndTitle.title;
          return this._loanDocService.renderHtmlToFile(htmlAndTitle.html, "JPG", htmlAndTitle.title);
        }),
      )
      .subscribe({
        next: (response) => {

          this._spinnerService.hide();

          const file = new File([response.body], fileTitle + '.jpeg', { type: 'image/jpeg' });

          const url = window.URL.createObjectURL(file);
          window.open(url);

          let reader = new FileReader();
          reader.readAsDataURL(file); // read file as data url
          reader.onload = (event) => {
            const base64 = event.target.result;
            let attachment = new FileAttachment();
            attachment.base64 = base64;
            attachment.file = file;
            const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
            modalRef.componentInstance.fileAttachments = [attachment];
            modalRef.componentInstance.isShowOnlyMMS = true;
            modalRef.componentInstance.sendEmail = false;
            modalRef.result.then(() => {

            }, err => { });

          }
        },
        error: (err) => {
          this._spinnerService.hide();

          this._notificationService.showError(err.message || err, "Error!");
        }
    });
  }

  private getHtmlContent = (): Observable<{ html: string, title: string }> => {
    let html: string;
    let cssFilePath: string;
    let fileTitle: string;

    switch (this.selectedCalculatorType) {
      case BenefitCalculatorType.LowerPayment:
        html = this.lowerPaymentCalcComponent.nativeElement.innerHTML;
        cssFilePath = "assets/css/lower-payment-calculator-print.css";
        fileTitle = "Benefits of Lower Payment";
        break;
      case BenefitCalculatorType.DebtManagement:
        html = this.debtManagementCalcComponent.nativeElement.innerHTML;
        cssFilePath = "assets/css/debt-management-calculator-print.css";
        fileTitle = "Benefits of Debt Management";
        break;
      case BenefitCalculatorType.CashOut:
        html = this.cashOutCalcComponent.nativeElement.innerHTML;
        cssFilePath = "assets/css/cash-out-calculator-print.css";
        fileTitle = "Benefits of Cash Out";
        break;
      case BenefitCalculatorType.ReduceTerm:
        html = this.reduceTermCalcComponent.nativeElement.innerHTML;
        cssFilePath = "assets/css/reduce-term-calculator-print.css";
        fileTitle = "Benefits of Reduce Term";
        break;
      case BenefitCalculatorType.RefiBenefits:
        html = this.refiBenefitsCalcComponent.nativeElement.innerHTML;
        cssFilePath = "assets/css/refi-benefits-calculator.css";
        fileTitle = "Benefits of Refinance";
        break;
      default:
        break;
    }

    return this._httpClient.get(cssFilePath, { responseType: "text" })
      .pipe(
        map(cssContent => {
          let fullHtml = `<html><head style="max-width: 607px !important;"><style>${cssContent}</style></head><body style="max-width: 607px !important;"><br><br>${html}</body></html>`;
          fullHtml = fullHtml
            .replace(/\r?\n|\r/gm, "") // remove line breaks
          // .replace(/\x3C/gm,"<");

          return { html: fullHtml, title: fileTitle };
        })
      )
  }

}
