import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanApplication, Role } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import * as _ from 'lodash';
import { LoanService } from 'src/app/services/loan';
import { Observer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

@Component({
  selector: 'mark-manual-transfer-dialog',
  templateUrl: 'mark-manual-transfer-dialog.component.html',
  styleUrls: ['mark-manual-transfer-dialog.component.scss'],
})
export class MarkManualTransferDialogComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Input()
  allRoles: Role[] = [];

  protected selectedUserId: string = null;

  protected users: User[] = [];

  protected issue: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _loanService: LoanService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService
  ) {}

  ngOnInit() {
    this._applicationContextService.context.subscribe((context) => {

      let channelRoles = [...this.allRoles];
      if (this.application.channel) {
        channelRoles = this.allRoles.filter((role) =>
          role.roleChannels
            .map((roleChannel) => roleChannel.channel)
            .includes(this.application.channel)
        );
      }

      const primaryRole = channelRoles.find((role) => role.order === 1);
      if (!primaryRole) {
        this.issue = `No primary role found for this application's channel.`;
        return;
      }
      let users = context.globalConfig.usersAll.filter(u => u.active && u.roleId === primaryRole.roleId);
      users.forEach((user) => {
        user['fullName'] = context.globalConfig.getUserFullName(user.userCompanyGuid);
      });
      this.users = _.orderBy(users, ['firstName'], ['asc']);
    });
  }

  onSaveClicked() {
    const observer: Observer<any> = {
      next: (value: any) => {
        this._notifsService.showSuccess('Loan transfer marked successfully.', 'Success!');
        this.activeModal.close();
      },
      error: (err: any) => {
        this._notifsService.showError(
          getErrorMessageOrDefault(err, {
            defaultMessage: 'An error occurred while marking loan transfer.',
          }),
          'Error!'
        );
      },
      complete: () => {
        
      }
    }
    this._spinner.show();
    this._loanService.markManualLoanTransfer(this.application.applicationId, this.selectedUserId).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }
}
