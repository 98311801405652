export function calculateLerpRatio(min: number, max: number): (value: number) => number | undefined {
  if (isNaN(min) || isNaN(max)) {
    console.error('Value, min and max should be numbers');
    return undefined;
  }

  return (value: number) => {
    if (isNaN(value)) {
      console.error('Value should be a number');
      return undefined;
    }

    if (value < min || value > max) {
      console.error('Current should be between min and max (inclusive)');
      return undefined;
    }

    // Prevent possible division by zero
    if (min === max) {
      return 1;
    }

    return (value - min) / (max - min);
  };
}

/**
 * Returns the sum of the numbers in the array.
 * @param {number[]} values - The numbers to sum.
 * @returns {number} - The sum of the numbers in the array.
 * @example
 * sum(1, 2, 3) // 6
 * sum(1) // 1
 * sum(1, undefined, NaN, 2) // 3
 * @remarks Non-numeric values are ignored.
 * Returns 0 if the array is empty.
 */
export function sum(...values: number[]): number {
  return values.reduce((total, value) => total + safeNumber(value), 0);
}

/**
 * Subtracts one or more numbers from the first one.
 * @param {number[]} values - The first number to subtract from the rest of the numbers in the
 * array.
 * @returns {number} - The result of the subtraction.
 * @example
 * subtract(2, 1) // 1
 * subtract(1, 2, 3) // -4
 * subtract(1) // 1
 * subtract(1, undefined, NaN, 2) // -1
 * @remarks Non-numeric values are ignored.
 * Returns 0 if the array is empty.
 */
export function subtract(...values: number[]): number {
  const [first, ...rest] = values;
  return rest.reduce((total, value) => total - safeNumber(value), safeNumber(first));
}

export function roundTo(value: number, numberOfDecimalPlaces: number): number {
  return (
    Math.round(value * Math.pow(10, numberOfDecimalPlaces)) / Math.pow(10, numberOfDecimalPlaces)
  );
}

export function safeNumber(value?: any, defaultValue = 0): number {
  return Number(value) || defaultValue;
}

export function areNumbersEqual(a: number, b: number, tolerance = 0.0001): boolean {
  return Math.abs(a - b) < tolerance;
}
