import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { CalculatedFeeTypeEnum } from 'src/app/models/fee/calculated-fee-type.enum';
import { CreateFeeModel } from 'src/app/models/fee/create-fee.model';
import { FeeCalculatedValues } from 'src/app/models/fee/fee-calculated-values.model';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { FeeValidationModel } from 'src/app/models/fee/fee-validation.model';
import { FeeSources, FeesToUpdateRequest, LoanFee } from 'src/app/models/fee/fee.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { EscrowFeesComponent } from '../escrow-fees/escrow-fees.component';
import { OriginationChargesComponent } from '../origination-charges/origination-charges.component';
import { OtherFeesComponent } from '../other-fees/other-fees.component';
import { PrepaidFeesComponent } from '../prepaid-fees/prepaid-fees.component';
import { RealEstateCommissionComponent } from '../real-estate-commission/real-estate-commission.component';
import { ServicesBorrowerCanShopForComponent } from '../services-borrower-can-shop-for/services-borrower-can-shop-for.component';
import { ServicesBorrowerNotShoppedForComponent } from '../services-borrower-not-shopped-for/services-borrower-not-shopped-for.component';
import { FeeCalculationService } from '../services/fee-calculation.service';
import { FeeUtils } from '../services/fee-utils.service';
import { TaxesOtherGovernmentFeesComponent } from '../taxes-other-government-fees/taxes-other-government-fees.component';
import { FeeTypeEnum } from 'src/app/models/fee/fee-type.enum';

@Component({
  selector: 'fees-editor',
  templateUrl: 'fees-editor.component.html',
  styleUrls: ['fees-editor.component.scss']
})
export class FeesEditorComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('feesEditorForm') feesEditorForm: NgForm | undefined;

  @Input()
  templates: FeeTemplate[];

  @Input()
  set fees(fees: LoanFee[]) {
    this._fees = fees;
    this.initFees();
  }

  @Input()
  isScenario: boolean = false;

  @Output()
  openFeeEditor = new EventEmitter<LoanFee>();

  @Output()
  feeAdded = new EventEmitter<LoanFee[]>();

  @Output()
  feeUpdated = new EventEmitter<LoanFee>(); // pricing scenario

  @Output()
  feesUpdated = new EventEmitter<LoanFee[]>();

  @Output()
  feeRemoved = new EventEmitter<LoanFee>();

  @Output()
  escrowScheduleOpeningRequested = new EventEmitter<any>();

  @ViewChild('prepaidFees')
  prepaidFees: PrepaidFeesComponent;

  @ViewChild('realEstateCommissions')
  realEstateCommissions: RealEstateCommissionComponent;

  @ViewChild('originationCharges')
  originationCharges: OriginationChargesComponent;

  @ViewChild('servicesBorrowerNotShoppedFor')
  servicesBorrowerNotShoppedFor: ServicesBorrowerNotShoppedForComponent;

  @ViewChild('servicesBorrowerCanShopFor')
  servicesBorrowerCanShopFor: ServicesBorrowerCanShopForComponent;

  @ViewChild('taxesAndGovernmentFees')
  taxesAndGovernmentFees: TaxesOtherGovernmentFeesComponent;

  @ViewChild('escrowFeesComp')
  escrowFeesComp: EscrowFeesComponent;

  @ViewChild('otherFeesComp')
  otherFeesComp: OtherFeesComponent

  get fees(): LoanFee[] {
    return this._fees;
  }

  realEstateFees: LoanFee[] = [];
  originationFees: LoanFee[] = [];
  servicesNoShopFees: LoanFee[] = [];
  servicesFees: LoanFee[] = [];
  governmentTaxesAndFees: LoanFee[] = [];
  prepaidsFees: LoanFee[] = [];
  escrowFees: LoanFee[] = [];
  otherFees: LoanFee[] = [];

  selectedTemplate: FeeTemplate;

  isRealEstateValid = true;
  isOriginationValid = true;
  isNoShopValid = true;
  isShopValid = true;
  isTaxesOtherGovernmentValid = true;
  isPrepaidValid = true;
  isEscrowValid = true;
  isOtherValid = true;

  feeSystemDetails: FeeSystemDetails;

  private _fees: LoanFee[] = [];

  get isAllValid(): boolean {
    return this.isRealEstateValid &&
      this.isOriginationValid &&
      this.isNoShopValid &&
      this.isShopValid &&
      this.isTaxesOtherGovernmentValid &&
      this.isPrepaidValid &&
      this.isEscrowValid &&
      this.isOtherValid;
  }

  constructor(
    private readonly injector: Injector,
    private readonly _feeService: FeeService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _feeCalculationService: FeeCalculationService,
    private readonly _feeUtilsService: FeeUtils
  ) {
    super(injector);
  }

  scrollToFirstInvalidControl = () => {
    if (!this.isRealEstateValid) {
      this.realEstateCommissions.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isOriginationValid) {
      this.originationCharges.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isNoShopValid) {
      this.servicesBorrowerNotShoppedFor.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isShopValid) {
      this.servicesBorrowerCanShopFor.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isTaxesOtherGovernmentValid) {
      this.taxesAndGovernmentFees.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isPrepaidValid) {
      this.prepaidFees.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isEscrowValid) {
      this.escrowFeesComp.scrollToFirstInvalidControl();
      return;
    }
    if (!this.isOtherValid) {
      this.otherFeesComp.scrollToFirstInvalidControl();
      return;
    }
  }

  ngOnInit(): void {
    const observer = {
      next: (systemDetails => {
        this.feeSystemDetails = systemDetails;
      }),
      error: (error => {
        this._notifsService.showError(
          error.error && error.error.message
            ? error.error.message
            : 'An error occurred while getting fee system details.',
          'Error!'
        );
      })
    }
    this._spinner.show();
    this._feeService.getFeeSystemDetails().subscribe(observer).add(() => { this._spinner.hide(); });

    const loanAmount = this.applicationContext?.application?.loanAmount ?? 0;
    const totalLoanAmount = this.applicationContext?.currentMortgage?.mortgageTerm?.totalLoanAmount ?? 0;
    const purchasePrice = this.applicationContext?.currentMortgage?.transactionDetail?.purchasePriceAmount ?? 0;
    const propertyAppraisedValue = this.applicationContext?.currentMortgage?.mortgageTerm?.appraisedValue ?? 0;

    this._fees?.filter(fee => !fee.borrowerFeeDollar && fee.feePercentOf && fee.borrowerFeePercent).forEach(fee => {
      this._feeCalculationService.calculateBorrowerFeeDollar(fee, loanAmount, propertyAppraisedValue, purchasePrice, totalLoanAmount);
    })
  }

  addFee = (fee: CreateFeeModel): void => {
    const newFee = new LoanFee();
    newFee.loanFeeId = Utils.getUniqueId();
    newFee.hudNumber = fee.hudNumber;
    newFee.name = fee.feeName;
    newFee.feeType = fee.feeType;
    newFee.feeSection = fee.feeSection;
    newFee.calculatedFeeType = CalculatedFeeTypeEnum[fee.calculatedFeeType] || CalculatedFeeTypeEnum.Default;
    newFee.calculatedValues = new FeeCalculatedValues();
    newFee.calculatedValues.isChargeUserEditable = true;
    newFee.calculatedValues.calculatedFeeType = newFee.calculatedFeeType;
    newFee.borrowerFeeDollar = null;
    newFee.feeSource = FeeSources.Manual;
    newFee.latestFeeValueSource = FeeSources.Manual;

    if (fee.feeType) {
      newFee.feeType = fee.feeType;
    }

    this._fees.push(newFee);

    if (this.isScenario) {
      // TODO this should call similar endpoint to updateLoanFeeFromLoanValues but for scenarios (requires backend implementation)
      this.feeAdded.emit([newFee]);
      return;
    }

    if (this.applicationContext.application) {
      this._spinner.show();

      const request = new FeesToUpdateRequest();
      request.feesToUpdate = [newFee];
      request.existingLoanFees = this._fees.filter(fee => fee.feeSection === newFee.feeSection && fee.loanFeeId !== newFee.loanFeeId);
      request.updateCharges = false;

      this._feeService.updateLoanFeeFromLoanValues(this.applicationContext.application.applicationId, request).subscribe({
        next: (updatedLoanFees => {
          this.feeAdded.emit(updatedLoanFees);
        }),
        error: (error => {
          this._spinner.hide();
          this._notifsService.showError(
            error.error && error.error.message
              ? error.error.message
              : 'Error adding a new fee.',
            'Error!'
          );
        })
      });
    } else {
      this.initFees();
    }
  }

  onEscrowScheduleOpenRequested = () => {
    this.escrowScheduleOpeningRequested.emit();
  }

  updateFeeValue = (editedFee: LoanFee): void => {
    if (this.isScenario) {
      this.feeUpdated.emit(editedFee);
      return;
    }

    const observer = {
      next: (updatedLoanFees => {
        this.onAfterFeesUpdatedFromLoanValues(updatedLoanFees);
      }),
      error: (error => {
        this._notifsService.showError(
          error.error && error.error.message
            ? error.error.message
            : 'Error updating calculated fee totals.',
          'Error!'
        );
      })
    }
    if (this.applicationContext.application) {
      this._spinner.show();
      const request = new FeesToUpdateRequest();
      let currFee = this.fees.find(x => (editedFee.loanFeeId && editedFee.loanFeeId == x.loanFeeId) || (x.feeSection == editedFee.feeSection && editedFee.name && editedFee.hudNumber && x.name == editedFee.name && x.hudNumber == editedFee.hudNumber));
      currFee = _.assign(currFee, editedFee);

      if(currFee.feeSection == FeeSectionEnum.Prepaids && currFee.feeType){
        const feeTypeMapped = currFee.feeType + "Reserve" as FeeTypeEnum;

        let mappedEscrowFee = this.fees.find(x => x.feeType == feeTypeMapped);
        if(mappedEscrowFee){
          if(mappedEscrowFee.calculatedValues.monthlyFee != currFee.calculatedValues.monthlyFee){
            mappedEscrowFee.calculatedValues.monthlyFee = currFee.calculatedValues.monthlyFee;

            const request2 = new FeesToUpdateRequest();
            request2.feesToUpdate = [mappedEscrowFee];
            request2.existingLoanFees = this._fees.filter(x => x.feeSection == editedFee.feeSection);
            request2.updateCharges = false;
            this._feeService.updateLoanFeeFromLoanValues(this.applicationContext.application.applicationId, request2)
              .subscribe(observer)
              .add(() => this._spinner.hide());
          }
        }
        else {

          mappedEscrowFee = _.cloneDeep(currFee) as LoanFee;
          mappedEscrowFee.loanFeeId = undefined;
          mappedEscrowFee.feeType = feeTypeMapped;
          mappedEscrowFee.feeSection = FeeSectionEnum.Escrow;
          mappedEscrowFee.name = mappedEscrowFee.name + " Reserve";

          const request2 = new FeesToUpdateRequest();
          request2.feesToUpdate = [mappedEscrowFee];
          request2.existingLoanFees = this._fees.filter(fee => fee.feeSection === mappedEscrowFee.feeSection);
          request2.updateCharges = true;

          this._feeService.updateLoanFeeFromLoanValues(this.applicationContext.application.applicationId, request2).subscribe({
            next: (updatedLoanFees => {
              let match = updatedLoanFees.find(f => f.feeType == feeTypeMapped);
              match.calculatedValues.monthlyFee = currFee.calculatedValues.monthlyFee;

              this.feeAdded.emit(updatedLoanFees);
            }),
            error: (error => {
              this._spinner.hide();
              this._notifsService.showError(
                error.error && error.error.message
                  ? error.error.message
                  : 'Error adding a new fee.',
                'Error!'
              );
            })
          });
        }
      }

      request.feesToUpdate = [currFee];
      request.existingLoanFees = this._fees.filter(x => x.feeSection == editedFee.feeSection);
      request.updateCharges = false;
      this._feeService.updateLoanFeeFromLoanValues(this.applicationContext.application.applicationId, request)
        .subscribe(observer)
        .add(() => this._spinner.hide());
    } else {
      this.initFees();
    }
  }

  removeFee = (deletedFee: LoanFee): void => {
    const index = this._fees.findIndex(f => f.loanFeeId === deletedFee.loanFeeId || (f.hudNumber === deletedFee.hudNumber && f.feeType === deletedFee.feeType));
    if (index === -1) {
      return;
    }

    this._fees.splice(index, 1);
    this.feeRemoved.emit(deletedFee);

    if (deletedFee.sumInHudNumber) {
      const otherFeesUnderSameGroup = this._fees.filter(fee => fee.sumInHudNumber === deletedFee.sumInHudNumber && fee.feeSection === deletedFee.feeSection);
      if (!otherFeesUnderSameGroup.length) {
        const groupFeeToDeleteIndex = this._fees.findIndex(fee =>
          fee.hudNumber?.toString() === deletedFee.sumInHudNumber.toString() && fee.feeSection === deletedFee.feeSection);
        if (groupFeeToDeleteIndex >= 0) {
          this._fees.splice(groupFeeToDeleteIndex, 1);
        }
      }
    }

    if (this.applicationContext.application) {
      this._spinner.show();

      const request = new FeesToUpdateRequest();
      request.feesToUpdate = this._fees.filter(x => x.feeSection == deletedFee.feeSection);
      request.existingLoanFees = this._fees.filter(x => x.feeSection == deletedFee.feeSection);
      request.updateCharges = false;

      this._feeService.updateLoanFeeFromLoanValues(this.applicationContext.application.applicationId, request).subscribe({
        next: (updatedLoanFees => {
          this.onAfterFeesUpdatedFromLoanValues(updatedLoanFees);
        }),
        error: (error => {
          this._notifsService.showError(
            error?.error?.message || 'Error updating calculated fee totals.',
            'Error!'
          );
        })
      }).add(() => this._spinner.hide());
    } else {
      this.initFees();
    }
  }

  changeValidationStatus = (validation: FeeValidationModel) => {
    if (validation.section != null) {
      switch (validation.section) {
        case FeeSectionEnum.Origination:
          this.isOriginationValid = validation.isValid;
          break;
        case FeeSectionEnum.ServicesNoShop:
          this.isNoShopValid = validation.isValid;
          break;
        case FeeSectionEnum.Services:
          this.isShopValid = validation.isValid
          break;
        case FeeSectionEnum.GovernmentTaxesAndFees:
          this.isTaxesOtherGovernmentValid = validation.isValid;
          break;
        case FeeSectionEnum.Prepaids:
          this.isPrepaidValid = validation.isValid;
          break;
        case FeeSectionEnum.Escrow:
          this.isEscrowValid = validation.isValid;
          break;
        case FeeSectionEnum.Other:
          this.isOtherValid = validation.isValid;
          break;
      }
    }
    else if (validation.calculatedType != null) {
      switch (validation.calculatedType) {
        case CalculatedFeeTypeEnum.RealEstateCommission:
          this.isRealEstateValid = validation.isValid;
          break;
      }
    }
  }

  onFeeEditorOpened = (data: LoanFee) => {
    this.openFeeEditor.emit(data);
  }

  private onAfterFeesUpdatedFromLoanValues = (updatedLoanFees: LoanFee[]) => {
    updatedLoanFees.forEach(ulf => {
      this._feeUtilsService.initMissingFeeValues(ulf);
      let currentFee = this.fees.find(x => (ulf.loanFeeId && ulf.loanFeeId == x.loanFeeId) || (x.feeSection == ulf.feeSection && ulf.name && ulf.hudNumber && x.name == ulf.name && x.hudNumber == ulf.hudNumber));
      if (currentFee) {
        currentFee = _.assign(currentFee, ulf);

        if(currentFee.feeSection == FeeSectionEnum.Prepaids && currentFee.feeType){
          const feeTypeMapped = currentFee.feeType + "Reserve" as FeeTypeEnum;

          let mappedEscrowFee = this.fees.find(x => x.feeType == feeTypeMapped);
          if(mappedEscrowFee){
            mappedEscrowFee.calculatedValues.monthlyFee = currentFee.calculatedValues.monthlyFee;
          }
          else {
            mappedEscrowFee = _.cloneDeep(currentFee) as LoanFee;
            mappedEscrowFee.loanFeeId = undefined;
            mappedEscrowFee.feeType = feeTypeMapped;
            mappedEscrowFee.feeSection = FeeSectionEnum.Escrow;
            mappedEscrowFee.name = mappedEscrowFee.name + " Reserve";
          }

          this.updateFeeValue(mappedEscrowFee);
        }
      }
      else {
        this._fees.push(ulf);
      }
    })
    this.initFees();
    this.feesUpdated.emit(this.fees);
  }

  private initFees = (): void => {
    this.realEstateFees = [];
    this.originationFees = [];
    this.servicesNoShopFees = [];
    this.servicesFees = [];
    this.governmentTaxesAndFees = [];
    this.prepaidsFees = [];
    this.escrowFees = [];
    this.otherFees = [];

    const groupedBySumInHudNumber = _.groupBy(this._fees, 'sumInHudNumber');
    _.forEach(groupedBySumInHudNumber, (group) => {
      group.sort((a, b) => (a.hudNumber || "").localeCompare(b.hudNumber || ""));
    });
    const fees = [];
    Object.keys(groupedBySumInHudNumber).forEach(hudNumber => {
      if (hudNumber === 'undefined') {
        groupedBySumInHudNumber[hudNumber].forEach(fee => {
          const existing = fees.find(f => !!fee.hudNumber && f.hudNumber === fee.hudNumber);
          if (!existing) {
            this.initFeeAndPutInAppropriateSection(fee);
            fees.push(fee);
          }
        })
      } else {
        const sumFee = this._fees.find(fee => fee.hudNumber === hudNumber);
        if (sumFee) {
          this.initFeeAndPutInAppropriateSection(sumFee);
          fees.push(sumFee);
          groupedBySumInHudNumber[hudNumber].forEach(fee => {
            this.initFeeAndPutInAppropriateSection(fee);
            fees.push(fee);
          })
        }
      }
    })
    this._fees.length = 0;
    this._fees.push(...fees);

    this._fees = [...this._fees];
  }

  private initFeeAndPutInAppropriateSection(fee: LoanFee) {
    if (!fee.sumInHudNumber) {
      fee.sumInHudNumber = undefined;
    }
    fee = this._feeUtilsService.initMissingFeeValues(fee);

    if (this._feeService.isRealEstateFee(fee)) {
      this.realEstateFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.Origination) {
      this.originationFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.ServicesNoShop) {
      this.servicesNoShopFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.Services) {
      this.servicesFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.Prepaids) {
      this.prepaidsFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.GovernmentTaxesAndFees) {
      this.governmentTaxesAndFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.Escrow) {
      this.escrowFees.push(fee);
    } else if (fee.feeSection === FeeSectionEnum.Other) {
      this.otherFees.push(fee);
    }
  }
}
