import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleList, ThirdPartyCredential, ThirdPartyKeyValue } from '../../../../../../models';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'appraisal-credential-modal',
  templateUrl: './appraisal-credential-modal.component.html',
  styleUrls: ['./appraisal-credential-modal.component.scss']
})
export class AppraisalCredentialModalComponent extends ApplicationContextBoundComponent implements OnInit {
  
  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  @Input()
  scope: ScopeType;

  showPassword: boolean;
  showApiKey: boolean;
  roles: RoleList;
  selectedRoleIds: Number[];
  credential: ThirdPartyCredential;
  reggoraKeyValuePairs: ReggoraKvp = new ReggoraKvp();
  keyValuePairs = {
    token: null,
    additionalContactRoleIds: null,
    hideLenderNameOnAppraiserAssignmentSheet: null,
    partyId: null,
    apiKey: null
  }
  isSaving: boolean;

  appraisalVendors: EnumerationItem[] = null;

  operationModes: EnumerationItem[] = [
    { name: 'Lodasoft', value: 'Lodasoft' },
    { name: 'Los', value: 'Los' },
  ]

  constructor(
    injector: Injector,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    public activeModal: NgbActiveModal,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _enumerationService: EnumerationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    const modeKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ReggoraOperationMode');
    this.reggoraKeyValuePairs.reggoraOperationMode = modeKeyValuePair?.value || null;

    const webhookTokenValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ReggoraWebhookToken');
    this.reggoraKeyValuePairs.reggoraWebhookToken = webhookTokenValuePair?.value || '';

    const lenderUrlValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ReggoraLenderUrl');
    this.reggoraKeyValuePairs.reggoraLenderUrl = lenderUrlValuePair?.value || '';

    this.keyValuePairs.token = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'Token') || {};
    this.keyValuePairs.additionalContactRoleIds = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'AdditionalContactRoleIds') || {};
    this.keyValuePairs.hideLenderNameOnAppraiserAssignmentSheet = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'HideLenderNameOnAppraiserAssignmentSheet') || {};
    this.keyValuePairs.partyId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'PartyId') || {};
    this.keyValuePairs.apiKey = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'ApiKey') || {};

    this.selectedRoleIds = this.keyValuePairs.additionalContactRoleIds.value ?
      this.keyValuePairs.additionalContactRoleIds.value.split(',').map(el => {
        return Number(el)
      }) : [];

    this._enumerationService.getAppraisalEnumerations().subscribe(enums => {
      this.appraisalVendors = enums[Constants.enumerations.appraisalProvider];
    });
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;
    this.keyValuePairs.additionalContactRoleIds.value = this.selectedRoleIds.join();
    if (this.credential.vendorName === 'Reggora') {
      this.setReggoraThirdPartyKeyValuePairs();
    } else {
      this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);
    }
    this.isSaving = true;
    this.credential.companyId = this.applicationContext.userPermissions.companyId;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => {
        this.isSaving = false;
      })).subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }

  private setReggoraThirdPartyKeyValuePairs() {
    let modeKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ReggoraOperationMode');
    if (!modeKeyValuePair) {
      modeKeyValuePair = new ThirdPartyKeyValue("ReggoraOperationMode", this.reggoraKeyValuePairs.reggoraOperationMode);
      this.credential.thirdPartyKeyValuePairs.push(modeKeyValuePair);
    } else {
      modeKeyValuePair.value = this.reggoraKeyValuePairs.reggoraOperationMode;
    }

    let webhookTokenValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ReggoraWebhookToken');
    if (!webhookTokenValuePair) {
      webhookTokenValuePair = new ThirdPartyKeyValue("ReggoraWebhookToken", this.reggoraKeyValuePairs.reggoraWebhookToken);
      this.credential.thirdPartyKeyValuePairs.push(webhookTokenValuePair);
    } else {
      webhookTokenValuePair.value = this.reggoraKeyValuePairs.reggoraWebhookToken;
    }

    let lenderUrlValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ReggoraLenderUrl');
    if (!lenderUrlValuePair) {
      lenderUrlValuePair = new ThirdPartyKeyValue('ReggoraLenderUrl', this.reggoraKeyValuePairs.reggoraLenderUrl);
      this.credential.thirdPartyKeyValuePairs.push(lenderUrlValuePair);
    } else {
      lenderUrlValuePair.value = this.reggoraKeyValuePairs.reggoraLenderUrl;
    }

    let apiKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ApiKey');
    if (!apiKeyValuePair) {
      apiKeyValuePair = new ThirdPartyKeyValue("ApiKey", this.keyValuePairs.apiKey.value || '');
      this.credential.thirdPartyKeyValuePairs.push(apiKeyValuePair);
    } else {
      apiKeyValuePair.value = this.keyValuePairs.apiKey.value || '';
    }
  }
}

class ReggoraKvp {
  reggoraOperationMode: string;
  reggoraWebhookToken: string;
  reggoraLenderUrl: string;
}
