<ng-template [ngIf]="loadingNotes" [ngIfElse]="content">
  <loading-indicator loadingMessage="Loading notes..."></loading-indicator>
</ng-template>

<ng-template #content>
  <div class="row justify-content-center px-2 activities-list" style="height: 100%">
    <div class="d-flex flex-column" style="height: 100%">
      <div class="col-lg-12 card d-flex align-items-center justify-content-center" style="height: 100px"
        *ngIf="noteslist?.length === 0">
        There are no notes for this contact.
      </div>

      <div *ngIf="noteslist?.length > 0" class="col-lg-12 card flex-grow-1" style="height: 75%">
        <div class="analytic-dash-activity" data-simplebar style="height: 100%">
          <div class="activity">
            <div style="padding: 10px; overflow-y: auto" id="notes-container">
              <div *ngFor="let group of groupedData">
                <!-- icon -->
                <div class="my-2 text-center" *ngIf="
                      group.groupDateStr != 'undefined' && group.notes.length > 0
                    ">
                  <span class="badge rounded-pill bg-info">
                    {{ group.groupDateStr }}</span>
                </div>

                <div class="activity-info" *ngFor="let note of group.notes">
                  <div class="icon-info-activity">
                    <i class="las la-book bg-soft-primary"></i>
                  </div>

                  <!-- message -->
                  <div class="activity-info-text mt-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="w-100">
                        <p class="mb-0 font-13 w-75">
                          A note was added to the account at
                          <strong>{{
                            note.dateInserted | date: 'MMM d, y h:mm a'
                            }}</strong>
                          by
                          <strong>{{ note.userFirstName }}
                            {{ note.userLastName }}</strong>.
                        </p>
                        <p [hidden]="!note.content" class="mb-0 font-13 w-75">
                          <strong>Note: </strong><span>{{ note.content }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <form class="form-horizontal" id="ContactNotes" novalidate="novalidate" name="contactNotes"
          #contactNoteForm="ngForm">
          <div class="row">
            <div class="form-group">
              <label class="form-label" for="contactMessage">Notes:</label>
              <textarea class="form-control" rows="5" id="contactMessage" name="contactMessage" placeholder="Add a note"
                [(ngModel)]="noteContent.content" #noteInput="ngModel" [ngClass]="{
                    'is-invalid':
                      noteInput && noteInput.touched && noteInput.invalid
                  }" required></textarea>

              <div class="invalid-feedback">This field is required.</div>
            </div>
          </div>
          <div class="row">
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-primary" [disabled]="submitting || !noteContent.content"
                (click)="saveContactNotes()">
                <span *ngIf="!submitting"><i class="fa fa-save me-2"></i> Save</span>
                <span *ngIf="submitting">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Saving
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>