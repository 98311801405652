import { Component, OnInit } from '@angular/core';

declare const loda: any;

@Component({
  selector: 'lenderprice-mock',
  templateUrl: 'lenderprice-mock.component.html'
})
export class LenderPriceMockComponent implements OnInit {

  protected action: string;

  protected output: string;

  constructor() {
  }

  ngOnInit() { }

  runAction() {
    if (this.action === 'get-tpo-loan') {
      loda.script.getObject('tpoApplication').then((tpoApplication: any) => {
        this.output = JSON.stringify(tpoApplication, null, 2);
      });
    }

    if (this.action === 'get-tpo-loan-data') {
      loda.script.getObject('tpoApplication').then((tpoApplication: any) => {
        tpoApplication.getLoanData().then(data => {
          this.output = JSON.stringify(data, null, 2);
        })
      });
    }

    if (this.action === 'get-loan-summary') {
      loda.script.getObject('tpoApplication').then((tpoApplication: any) => {
        const loanSummary = tpoApplication.getLoanSummary();
        this.output = JSON.stringify(loanSummary, null, 2);
      });
    }
  }
}
