import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface IDuplicateItem {
  id: number;
  firstName: string;
  lastName: string;
  email?: string
  dateInserted: Date;
  borrowerContact?: string;
}

@Component({
  selector: 'duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.scss']
})
export class DuplicateDialogComponent{

  @Input()
  items: IDuplicateItem[] = [];

  @Input()
  userType: string; // 'lead' | 'borrower';

  @Output()
  selectedIdChanged = new EventEmitter<number>();

  selectedId: number;

  constructor(public activeModal: NgbActiveModal) { }

  changed = (id: number ) => {
    this.selectedId = id;
    this.selectedIdChanged.emit(id);
  }

}

