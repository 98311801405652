import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { ActivityType, ActivityTypeCategory, ApplicationContext, Company, LoanApplication, UserType } from 'src/app/models';
import { LoanDoc } from 'src/app/models/loan/loan-doc.model';
import { Message } from 'src/app/models/message/message.model';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user/user.model';
import { ChannelService } from 'src/app/services/channel.service';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { MentionsService } from 'src/app/services/mentions.service';
import { MessageService } from 'src/app/services/message.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { v4 as uuidv4 } from 'uuid';
import { BorrowerDto } from '../contacts/models/borrower-dto.model';
import { InternalContact } from '../internal-contacts/models/internal-contact.model';
import { InternalContactsService } from '../internal-contacts/services/internal-contacts.service';
import { EmailSmsPreviewDialogComponent } from './email-sms-preview-dialog/email-sms-preview-dialog.component';
import { UserWithRoleName } from './models/user-with-role-name.model';
import { LoanActivityService } from './services/loan-activity.service';

@Component({
  selector: 'loan-activity',
  templateUrl: 'loan-activity.component.html',
  styleUrls: ['./loan-activity.component.scss'],
})
export class LoanActivityComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('messageEditor') messageEditorComponent: any;

  @Input() appId: number;
  @Input() leadId: number;
  @Input() showFilters: boolean = true;
  @Input() activityTab: string = 'All';
  @Input() showBorrowerLink: boolean = true;

  @Input()
  showOnlyActivityLog: boolean = false;

  @Input()
  sendWhenHitEnter: boolean = false;

  @Input()
  refreshMentions: boolean = false;

  @Input()
  mentionTrackingGuid: string;

  @Input()
  set isSendingWhenHitEnterConfigurable(configurable: boolean) {
    if (!configurable) {
      this.sendWhenHitEnter = false;
    }
    this._isSendingWhenHitEnterConfigurable = configurable;
  }

  get isSendingWhenHitEnterConfigurable(): boolean {
    return this._isSendingWhenHitEnterConfigurable;
  }

  losEnabled: boolean;

  users: Array<UserWithRoleName>;

  contactRoles: Array<Role>;

  borrowers: Array<BorrowerDto>;

  internalMessage: Message = new Message();

  activityLogs: Array<any> = [];

  docs: Array<LoanDoc> = [];

  loanFiles = {};

  notifyPartyLoanContacts: Array<any> = [];

  messageToSend: string;

  usersThatCanBeMentioned: Mentionable[] = [];

  allUsers: User[] = [];

  application: LoanApplication;

  componentId: string;

  loanActivitySpinner: string = "loanActivitySpinner";

  userId: string;

  urlForLoan: string;

  singleMentionTrackingUrl: string;

  @Input()
  idOfMessageToScrollTo: number | null = null;

  private _isSendingWhenHitEnterConfigurable: boolean = true;

  private _loanInfoChangesSubscription: Subscription;

  tabCounts = {
    LoanChangeStatus: 0,
    BorrowerMessage: 0,
    InternalMessage: 0,
    Lead: 0,
    DocumentFile: 0,
    Paused: 0,
    Email: 0,
    Sms: 0,
    Voice: 0,
    LosMessage: 0,
  };

  activityTabs = {
    LoanChangeStatus: ['LoanChangeStatus', 'LeadChangeStatus'],
    BorrowerMessage: ['BorrowerMessage'],
    InternalMessage: ['InternalMessage'],
    Lead: ['Lead', 'route'],
    DocumentFile: ['DocumentFile'],
    // EmailSmsVoice: ['EmailSmsVoice', 'Email', 'sms', 'phoneSuccess', 'phoneAttempt', 'route', 'call', 'Call', 'PauseEmailSms'],
    Email: ['Email'],
    Sms: ['sms', 'Sms'],
    Rvm: ['rvm', 'Rvm'],
    Voice: ['Voice', 'call', 'Call'],
    Paused: ['PauseEmailSms'],
    LosMessage: ['LosMessage'],
  }

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  };

  primaryBorrower: BorrowerDto;
  primaryBorrowerFullName: string;
  primaryBorrowerLoading: boolean = false;

  isTpo: boolean = false;

  private _internalContacts: InternalContact[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _loanActivityService: LoanActivityService,
    private readonly _modalService: NgbModal,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _messageService: MessageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocService: LoanDocService,
    private readonly _notifyService: NotificationService,
    private readonly _channelService: ChannelService,
    private readonly _loanService: LoanService,
    private readonly _environment: EnvironmentService,
    private readonly _mentionsService: MentionsService,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router
  ) {
    super(injector);
    this.componentId = uuidv4();
    this.loanActivitySpinner += this.componentId;
  }

  ngOnInit() {
    if (this.appId) {
      this._spinner.show(this.loanActivitySpinner);
      this._loanService.getApplicationModel(this.appId, false).subscribe({
        next: (appModel) => {
          this.application = appModel;
          this.primaryBorrowerLoading = true;
          this._loanService.getBorrowers(this.appId).subscribe({
            next: (borrowers) => {
              this.borrowers = borrowers;
              this.primaryBorrower = borrowers.find(b => b.isPrimary);
              this.primaryBorrowerFullName = Utils.getPersonsFullName(this.primaryBorrower);
              this.primaryBorrowerLoading = false;
              this.initialize(this.applicationContext);
              this._spinner.hide(this.loanActivitySpinner);
            },
            error: (error) => {
              this._notifyService.showError(error?.message || "Couldn't load application borrowers.", "Error!");
              this.primaryBorrowerLoading = false;
              this._spinner.hide(this.loanActivitySpinner);
            }
          })
        },
        error: (error) => {
          // if user doesnt have access to application, mark mention as read
          if (typeof error?.message === "string" && error?.message?.toLowerCase() === `no access to app ${this.appId}` && this.mentionTrackingGuid) {
            this.singleMentionTrackingUrl = `${this._environment.apiInfo.apiBaseUrl}mentions/pixel/${this.userId}/${this.mentionTrackingGuid}`;
            this._mentionsService.publish({ type: "reload" })
          }
          this._notifyService.showError(error?.message || "Couldn't load application.", "Error!");
          this._spinner.hide(this.loanActivitySpinner);
        }
      })
    } else {
      if (this.applicationContext.application) {
        this.initializeFromContext(this.applicationContext);
      } else {
        this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
          this.initializeFromContext(context);
        });
      }
    }
  }

  ngOnDestroy() {
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  getUrlForLink = () => {
    let urlPrefix;
    if (this._navigationService.applicationMode == ApplicationMode.Classic) {
      urlPrefix = "/admin/app-details/";
    } else {
      urlPrefix = "/loda-nextgen/app-details/";
    }
    const url = this._router.serializeUrl(
      this._router.createUrlTree([urlPrefix + this.primaryBorrower?.applicationId])
    );
    this.urlForLoan = url;
  }

  onMessageChanged = (message: string) => {
    this.internalMessage.content = message;
  }

  loadActivityLogs = () => {
    this._spinner.show(this.loanActivitySpinner);

    this._loanActivityService.getActivityLogs(this.application.applicationId, this.losEnabled).subscribe((response) => {
      response.forEach(log => {
        log["userName"] = this.getUserFullName(log.userId);
        if (log.activityType === 'InternalMessage') {
          log['mediaFiles'] = [];
          const activityLog = this.setImageIfExist(log);
          log = activityLog || log;
          log.displayText = MentionsUtils.generateDisplayHtmlWithMentions(log.displayText);
          log['mentionTrackingUrl'] = this._environment.apiInfo.apiBaseUrl + 'mentions/pixel/' +
            this.applicationContext.userPermissions.userId + '/' + log.mentionTrackingGuid;
          log['avatarUrl'] = this.getUserAvatarUrl(log.userId);
        }
      });

      response.forEach(log => {
        if (log.category == ActivityTypeCategory.EmailSmsVoice) {
          if (log.activityType == 'Sms') {
            log.category = ActivityTypeCategory.Sms
          }
          else if (log.activityType == 'Voice') {
            log.category = ActivityTypeCategory.Voice
          }
          else {
            log.category = ActivityTypeCategory.Email
          }
        }
      });

      if (this.isTpo) {
        response = response.filter(log => !["sms", "Sms", "email", "Email"].includes(log.category));
      }

      this.activityLogs = response;
      this.convertNewLinesToHtmlBr();
      if (this.activityLogs.length > 1) {
        setTimeout(() => {
          const messages = _.orderBy(response, ['dateCreated'], ['asc']);

          const { messageId } = messages[messages.length - 1]
          if (messageId) {
            this.scrollToMessage(messageId);
          } else {
            this.scrollToMessage(this.idOfMessageToScrollTo); // scroll to latest self mention message
          }
        }, 300);
      }

      const apiCalls = [];

      if (!this.showOnlyActivityLog) {
        apiCalls.push(this._loanActivityService.getEmailsSentForApp(this.application.applicationId));
        if (!this.isTpo) {
          apiCalls.push(this._loanActivityService.postRecordHistory({ applicationId: this.application.applicationId }));
          if (this.application.leadId)
            apiCalls.push(this._loanActivityService.postRecordHistory({ leadId: this.application.leadId }));

          if (this.leadId) {
            apiCalls.push(this._loanActivityService.getLeadEvents(this.leadId));
            apiCalls.push(this._loanActivityService.getLeadRouteHistory(this.leadId));
            apiCalls.push(this._loanActivityService.postRecordHistory({ leadId: this.leadId }));
          }
        }
      }

      // refresh mentions from alerts
      if (this.refreshMentions) {
        this._mentionsService.publish({ type: "reload" });
      }

      if (apiCalls.length) {
        forkJoin(apiCalls).subscribe((responses: any) => {
          responses[0].forEach(emailActivity => {
            switch (emailActivity.delivery.toLowerCase()) {
              case "email":
                emailActivity.category = 'Email';
                break;
              case "sms":
                emailActivity.category = 'sms';
                break;
              case 'rvm':
                emailActivity.category = 'Rvm';
                break;
            }
            emailActivity.dateCreated = emailActivity.dateSent ?? emailActivity.dateUpdated ?? emailActivity.dateInserted;
            emailActivity.userName = this.getUserFullName(emailActivity.fromUserId)
          });
          this.activityLogs = this.activityLogs.concat(responses[0]);

          if (!this.isTpo) {
            responses[1].forEach(record => {
              record.userName = this.getUserFullName(record.recordHistory.insertedBy);
              record.category = 'Call';
              record.dateCreated = record.recordHistory.dateUpdated || record.recordHistory.dateInserted;
            });
            this.activityLogs = this.activityLogs.concat(responses[1]);

            if (this.leadId) {
              responses[2].forEach(leadEvent => {
                leadEvent.userName = this.getUserFullName(leadEvent.createdByUserId);
                leadEvent.category = leadEvent.type;
                if (leadEvent.type == 'ChangeStatus') {
                  leadEvent.category = 'LeadChangeStatus';
                  leadEvent.displayText = MentionsUtils.generateDisplayHtmlWithMentions(leadEvent.note);
                }
                leadEvent.dateCreated = leadEvent.dateUpdated || leadEvent.dateInserted;
              });
              this.activityLogs = this.activityLogs.concat(responses[2].filter(leadEvent => leadEvent.category != 'email'));

              responses[3].forEach(leadRoute => {
                leadRoute.userName = this.getUserFullName(leadRoute.userId);
                leadRoute.category = 'route';
                leadRoute.dateCreated = leadRoute.dateInserted;
              });
              this.activityLogs = this.activityLogs.concat(responses[3]);

              responses[4].forEach(leadRecord => {
                leadRecord.userName = this.getUserFullName(leadRecord.recordHistory.insertedBy);
                leadRecord.category = 'call';
                leadRecord.dateCreated = leadRecord.recordHistory.dateUpdated || leadRecord.recordHistory.dateInserted;
              });
              this.activityLogs = this.activityLogs.concat(responses[4]);
            }
          }
          else {
            this.activityLogs = this.activityLogs.filter(log => !["sms", "Sms", "email", "Email"].includes(log.category));
          }
          this.calculateTabCounts();
          this._spinner.hide(this.loanActivitySpinner);
        }, (err) => {
          this._notifyService.showError(err.message, 'Error');
        }).add(() => this._spinner.hide(this.loanActivitySpinner));
      }

    }, (err) => {
      this._notifyService.showError(err.message, 'Error');
      this._spinner.hide(this.loanActivitySpinner);
    });
  }

  convertNewLinesToHtmlBr = () => {
    this.activityLogs.forEach(msg => {
      if (msg.category !== ActivityType.InternalMessage) {
        return;
      }
      if (msg.displayText) {
        msg.displayText = msg.displayText.replaceAll('\n', '<br/>');
      }
    });
  }

  loadInternalContacts = () => {
    this._internalContactsService.getInternalContacts(this.application.applicationId).subscribe(internalContacts => {
      if (internalContacts && internalContacts.length > 0) {
        this._internalContacts = internalContacts;
        this.initializeMentionables();
        let user = null;
        let role = null;
        internalContacts.forEach(internalContact => {
          user = this.users.find(user => user.userCompanyGuid == internalContact.userId);
          role = this.contactRoles.find(role => role.roleId == internalContact.roleId);
          if (user) {
            this.notifyPartyLoanContacts.push({
              id: internalContact.userId,
              roleId: internalContact.roleId,
              firstName: user.firstName,
              lastName: user.lastName,
              roleName: role.roleName,
              order: role.order
            });
          }
        });
      }
    });
    this.users.forEach(user => {
      let role = this.contactRoles.find(role => role.roleId == user.roleId);
      if (role && role.roleName) {
        user.roleName = role.roleName;
      }
    })
  }

  onEnterHit = () => {
    if (this.sendWhenHitEnter) {
      this.submitNewInternalMessage();
    }
  }

  submitNewInternalMessage = () => {
    if (this.internalMessage.content.trim() == "") {
      return false;
    }
    this._spinner.show();
    this.internalMessage.applicationId = this.application.applicationId;
    let notifyPartyIds = [];
    if (this.internalMessage.notifyPartyId) {
      this.internalMessage.notifyPartyId.forEach((item) => {
        if (item.length > 20)
          notifyPartyIds.push(item);
        else {
          let user = this.notifyPartyLoanContacts.find(x => x.roleId == item);
          if (user)
            notifyPartyIds.push(user.id);
        }
      })
      this.internalMessage.notifyPartyId = notifyPartyIds;
    }
    this._messageService.postInternalMessage(this.internalMessage).subscribe((response) => {
      if (response !== null && response !== undefined) {
        let newActivityLog = {
          userName: this.getUserFullName(response.userId),
          userId: response.userId,
          category: "InternalMessage" as ActivityTypeCategory,
          activityType: "InternalMessage" as ActivityType,
          displayText: this.internalMessage.content.replaceAll('\n', '<br/>'),
          dateCreated: response.dateInserted,
          avatarUrl: this.getUserAvatarUrl(this.userId),
          messageId: response.messageId,
        }
        newActivityLog.displayText = MentionsUtils.generateDisplayHtmlWithMentions(newActivityLog.displayText);
        newActivityLog['mediaFiles'] = [];
        newActivityLog = this.setImageIfExist(newActivityLog);
        this.activityLogs.push(newActivityLog);

        if (this.isTpo) {
          this.activityLogs = this.activityLogs.filter(log => !["sms", "Sms", "email", "Email"].includes(log.category));
        }

        this.activityLogs = [...this.activityLogs];

        this.internalMessage.content = "";
        this.messageEditorComponent.reset();

        this.calculateTabCounts();
        setTimeout(() => {
          this.scrollToMessage(newActivityLog.messageId);
        }, 300)

        this._notifyService.showSuccess("Message Sent Successfully", 'Success!');
      }
      this._spinner.hide();
    }, (err) => {
      this._notifyService.showError(err.message, 'Error');
      this._spinner.hide();
    });
  }

  loadLoanDocs = (cb: Function) => {
    this._loanActivityService.getAllLoanDocs(this.application.applicationId).subscribe((response) => {
      if (typeof cb === 'function') {
        cb(response);
      }
    });
  };

  setLoanFiles = (docs) => {
    if (Array.isArray(docs)) {
      const loanFiles = _.flow([
        _.map,
        _.flatten,
        fileArray => _.keyBy(fileArray, 'guid')
      ])(docs, 'docFiles');
      this.loanFiles = loanFiles;
    }
  }

  getUserFullName = (userId: string) => {
    let userFullName = this.applicationContext.globalConfig.getUserFullName(userId);
    if (userFullName) {
      return userFullName;
    } else {
      if (this.borrowers) {
        let borrowerFound = this.borrowers.find(borrower => borrower.userId === userId);
        if (borrowerFound) {
          userFullName = Utils.getPersonsFullName(borrowerFound);
        } else {
          userFullName = "Unknown";
        }
      }
    }
    return userFullName;
  }

  showEmailSmsDetails = (data) => {
    this._spinner.show();
    // TODO this request's name makes no sense if we get sms detail
    this._loanActivityService.getEmailDetail(data.id).subscribe((response) => {
      this._spinner.hide();
      const modalRef = this._modalService.open(EmailSmsPreviewDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.message = response;
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err.message, 'Error');
    });
  }

  loadRecordingDetails = (voiceHistoryId: number) => {
    this._spinner.show();
    this._loanActivityService.getRecording(voiceHistoryId).subscribe({
      next: (response) => {
        this._spinner.hide();
        window.open(response.mp3RecordingUri, '_blank');
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message, 'Error');
      }
    });
  }

  downloadRecordingDetails = (voiceHistoryId: number) => {
    this._spinner.show();
    this._loanActivityService.getRecording(voiceHistoryId).subscribe({
      next: (response) => {
        this._spinner.hide();
        this._loanActivityService.downloadURI(response.mp3RecordingUri);
      }, error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message, 'Error');
      }
    });
  }

  viewDocument = (loanDocId: number) => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(result => {
      if (result && result.docFiles.length) {
        const fileGuid = result.docFiles[0].guid;
        const mimeType = result.docFiles[0].mimeType;
        this._loanDocService.viewFile(fileGuid).subscribe(data => {
          this._spinner.hide();
          const blob = new Blob([data], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      } else {
        this._spinner.hide();
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    })
  }

  downloadDocument = (loanDocId: number) => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(result => {
      if (result && result.docFiles.length) {
        let fileGuid = result.docFiles[0].guid;
        let mimeType = result.docFiles[0].mimeType;
        this._loanDocService.downloadFile(fileGuid, mimeType).subscribe(data => {
          this._spinner.hide();
          const blob = new Blob([data], { type: 'application/pdf', });
          let downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          let fileName = result.docFiles[0].fileName;
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      } else {
        this._spinner.hide();
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    })
  }

  secondsToHms = (seconds: number): string => {
    return Utils.secondsToHms(seconds);
  }

  setImageIfExist = (log) => {
    if (!log.displayText) {
      return;
    }
    if (
      log.displayText.includes('data:image/png;base64') ||
      log.displayText.includes('data:image/jpg;base64') ||
      log.displayText.includes('data:image/jpeg;base64')
    ) {
      log['hasMediaFiles'] = true;
      const base64Img = log.displayText.substring(log.displayText.indexOf('[') + 1, log.displayText.indexOf(']'));
      log['mediaFiles'].push(base64Img);
      log.displayText = log.displayText.replace(`[${base64Img}]`, '');
      return this.setImageIfExist(log);
    } else {
      return log;
    }
  };

  viewImage = (base64Image) => {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uInt8Array], { type: imageType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.application = context.application;
    this.borrowers = context.borrowers;
    this.primaryBorrower = context.borrowers.find(b => b.isPrimary);
    this.primaryBorrowerFullName = Utils.getPersonsFullName(this.primaryBorrower);
    this.initialize(context);
  }

  private initialize = (context: ApplicationContext) => {
    this.losEnabled = context.userPermissions.losEnabled;
    this.userId = context.userPermissions.userId;

    if (this.application.externalCompanyId > 0) {
      this.allUsers = context.globalConfig.usersAll.concat(context.globalConfig.tpoUsers.filter(u => u.branchId === this.application.branchId));
    } else {
      this.allUsers = context.globalConfig.usersAll;
    }

    this.isTpo = context.isTpo;
    this.users = context.globalConfig.users;
    this.contactRoles = context.globalConfig.roles;
    if (!this.showOnlyActivityLog) {
      this.loadLoanDocs(this.setLoanFiles);
    }
    this.getUrlForLink();
    this.loadActivityLogs();
    this.loadInternalContacts();
    this.internalMessage.content = "";
    this.internalMessage.applicationId = 0;
    this.internalMessage.userId = "0";
    this.internalMessage.notifyPartyId = [];
  }

  private initializeMentionables = () => {
    const usersThatCanBeMentioned = MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl, this.allUsers);
    const company = this.applicationContext.globalConfig.company.find(c => c.companyId == this.applicationContext.userPermissions.companyId);
    const rolesThatCanBeMentioned = this.prepareContactRoleMentionables(
      this.application.channel,
      company,
      this._internalContacts, this.allUsers);
    this.usersThatCanBeMentioned = usersThatCanBeMentioned.concat(rolesThatCanBeMentioned);
    this.usersThatCanBeMentioned.push(MentionsUtils.prepareInternalContactsMentionable());
    this.usersThatCanBeMentioned.push(MentionsUtils.prepareHereMentionable());
  }

  private calculateTabCounts = () => {
    this.activityLogs.forEach((activityLog) => {
      switch (activityLog.category) {
        case 'route':
        case 'Lead':
          this.tabCounts['Lead']++;
          break;
        case 'InternalMessage':
          this.tabCounts['InternalMessage']++;
          break;
        case 'BorrowerMessage':
          this.tabCounts['BorrowerMessage']++;
          break;
        case 'DocumentFile':
          this.tabCounts['DocumentFile']++;
          break;
        case 'LoanChangeStatus':
        case 'LeadChangeStatus':
          this.tabCounts['LoanChangeStatus']++;
          break;
        case 'Email':
          this.tabCounts['Email']++;
          break;
        case 'sms':
        case 'Sms':
          this.tabCounts['Sms']++;
          break;
        case 'rvm':
        case 'Rvm':
          this.tabCounts['Rvm']++;
          break;
        case 'Voice':
        case 'call':
        case 'Call':
          this.tabCounts['Voice']++;
          break;
        case 'PauseEmailSms':
          this.tabCounts['Paused']++;
          break;
        case 'LosMessage':
          this.tabCounts['LosMessage']++;
          break;
      }
    });
  }

  private prepareContactRoleMentionables = (channelName: string, company: Company, internalContacts: InternalContact[], allUsers: User[]): Mentionable[] => {
    let mentionables: Mentionable[] = [];

    const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(company.enabledChannels);
    const enabledChannel = enabledChannels.find(c => c.value === channelName);

    if (enabledChannel) {
      mentionables = MentionsUtils.prepareContactRoleMentionables(
        enabledChannel.value,
        this.applicationContext.globalConfig.channelRoles,
        internalContacts,
        allUsers);
    }
    return mentionables;
  }

  private scrollToMessage = (messageId: number) => {
    if (!messageId) {
      return;
    }
    const element = document.getElementById(messageId.toString());
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 250);
    }
  }

  private getUserAvatarUrl = (userId: string): string => {
    if (!userId) {
      return 'assets/images/male.png';
    }
    const user = this.allUsers.find(user => user.userCompanyGuid === userId);
    if (user) {
      return user.avatarId ? this._environment.apiInfo.apiBaseUrl + 'avatars/' + user.avatarId : 'assets/images/male.png';
    } else {
      return 'assets/images/male.png';
    }
  }

  // private scrollToConversationEnd = () => {
  //   const element = document.getElementById("conversation-" + chat.applicationId);

  //   if (element) {
  //     setTimeout(() => {
  //       element.scrollIntoView({ behavior: 'smooth', block: "end", inline: 'nearest' });
  //     }, 250);
  //   }
  // }
}
