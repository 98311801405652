import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { EditorMode } from '../currency-input/currency-input.component';
import { Address } from '../../../models';
import { AddressAutocompleteInputComponent } from '../address-autocomplete-input/address-autocomplete-input.component';

@Component({
  selector: 'address-input',
  templateUrl: 'address-input.component.html',
  providers: [MakeProvider(AddressInputComponent)],
  viewProviders: [formViewProvider]
})
export class AddressInputComponent extends AbstractValueAccessor implements OnInit, OnDestroy {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('control')
  input: AddressAutocompleteInputComponent;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  name: string;

  @Input()
  required: boolean;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  inlineTextClass: string;

  @Input()
  placeholder: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  addressChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  canceled: EventEmitter<any> = new EventEmitter<any>();

  id: string;

  isEditActive: boolean = false;

  private _originalValue: any;

  states: EnumerationItem[] = [];

  constructor(
    private readonly _enumService: EnumerationService,
  ) {
    super();
    this.states = this._enumService.states;
  }

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());
  }

  ngOnDestroy(): void {
    this.checkAndRemoveGooglePlacesElements();
  }

  override writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this._originalValue = value; // for initializing
  }

  onCancelClicked = (event: any) => {
    event.preventDefault();
    this.isEditActive = false;
    this.value = this._originalValue;
    this.blur.emit();
  }

  onApplyClicked = (event: any) => {
    event.preventDefault();
    this.model.control.markAsTouched();
    this.apply();
  }

  onBlur = (e: any) => {
    let elements: any = $('.pac-item');
    const keys = Object.keys(elements);
    let needsEventTrigerring = true;
    keys.forEach(key => {
      if (!isNaN(key as any) && elements[key].matches(':hover')) {
        e.preventDefault();
        needsEventTrigerring = false;
        return;
      }
    })
    if (needsEventTrigerring) {
      this.blur.emit(e);
    }
    if (this.isEditActive) {
      this.apply();
    }
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.focus();
    });
    this.isEditActive = true;
  }

  protected onAddressChanged(e: Partial<Address>): void {
    setTimeout(() => {
      // FIXME: This is a hack to fix the issue where the address is removed after assigning it.
      //        Investigate why this is happening.
      setTimeout(() => {
        this.writeValue(e.address1);
      });

      this.addressChanged.emit(e);
    }, 200);
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;

      if (!this.value) {
        this.addressChanged.emit(null);
      }
    }
  }

  private checkAndRemoveGooglePlacesElements = (): void => {
    const elements: any = document.querySelectorAll('.pac-container');
    for (const e of elements) {
      e.remove();
    }
  }
}
