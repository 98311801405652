<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-users me-2"></i>
                    {{agentType ? ('Select ' + agentType?.agentTypeName + ' to Associate') : 'Associate File Contact'}}
                </h4>
                <span>
                  <button *ngIf="!isTpoUser" type="button" class="btn btn-primary pull-right" (click)="onCancelClicked()">
                    Cancel
                  </button>
                </span>
            </div>
            <div class="card-body">
                <loading-indicator *ngIf="loadingContacts" [loadingMessage]="'Loading contacts, please wait...'">
                </loading-indicator>

                <div class="row" *ngIf="!loadingContacts">
                    <agent-table #agentTable
                        [agents]="agents"
                        [agentTableConfig]="agentTableConfig"
                        [agentTypeId]="agentType?.agentTypeId"
                        (addNewClicked)="onNewAgentEditorOpened($event)"
                        (viewSelectAgentTypeModal)="viewSelectAgentTypeModal($event)"
                        (selectAssociatedAgent)="onSelectAssociatedAgent($event)"
                        (backClicked)="onBackClicked()"
                    ></agent-table>
                </div>
            </div>
        </div>
    </div>
</div>

<drawer [title]="'New Agent'"
    #addAgentEditor
    [name]="'addAgentEditorDrawer'"
    [templateRef]="addAgentEditorDrawerContentRef"
    [options]="addAgentsDrawerOptions"
    (closed)="onNewAgentEditorClosed()">
</drawer>
<ng-template #addAgentEditorDrawerContentRef>
    <upsert-agent
      [agentId]="agentId"
      [isDrawer]="true"
      [tab]="'agentInfo'"
      (onAddEditAgent)="onAgentEditedOrUpdated($event)"
      (closeDrawer)="onNewAgentEditorClosed()">
    </upsert-agent>
 </ng-template>
