import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { ApplicationContextService } from "src/app/services/application-context.service";

@Injectable({
  providedIn: 'root'
})
export class CanActivateAdminRoutesGuard implements CanActivate, CanActivateChild {

  constructor(private readonly _applicationContextService: ApplicationContextService,
    private readonly _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // simply verify if its invalid register link
    if (state.url.includes('#/register')) {
      const url = state.url.replace('#', '');
      const urlParts = url.split('?');
      const queryParamsString = urlParts[1];

      if (!queryParamsString) {
        this._router.navigateByUrl('login');
      }
  
      const queryParams = {};
      const searchParams = new URLSearchParams(queryParamsString);
      searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });
  
      this._router.navigate(['register'], { queryParams });
  
      return true;
    }

    return this._applicationContextService.context.pipe(map(context => {
      if (context.isTpo) {
        this._router.navigateByUrl('tpo/dashboard');
        return false;
      }
      return true;
    }))
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
}
