<div class="modal-header">
  <h5 class="modal-title w-100">
    New Lead {{ notifyOnly ? 'Assigned' : 'Available' }}!
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div class="add-to-list mb-2">
    <em *ngIf="!leadModel.phone" class="d-inline-block mt-2">Lead doesn't have a phone number.</em>
    <ng-container *ngIf="leadModel.phone">
      <div class="row mb-2">
        <div class="col-md-4">
          <select
            class="form-control pull-right"
            [(ngModel)]="dialListModel"
            id="addSelectList"
          >
            <option value="">-- Select One --</option>
            <option *ngFor="let dial of dialLists" [ngValue]="dial.dialListId">
              {{ dial.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <button
            class="btn btn-primary w-100"
            id="addtolist"
            type="button"
            *ngIf="!isModalOpen"
            (click)="confirmDialList(dialListModel, true)"
            [disabled]="!dialListModel"
          >
            Add to Dial List & Dial
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-primary w-100"
            id="addtolist"
            type="button"
            (click)="confirmDialList(dialListModel, false)"
            style="margin-right: 10px"
            [disabled]="!dialListModel"
          >
            Add to Dial List
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-primary w-100"
            id="addtolist"
            type="button"
            *ngIf="isModalOpen"
            (click)="addToDialListAndDial(true)"
          >
            Dial Now
          </button>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-primary w-100"
            id="addtolist"
            type="button"
            *ngIf="isModalOpen"
            (click)="addToDialListAndDial(false)"
          >
            Add to current Dial List
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row" *ngIf="leadModel">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <a (click)="editLead()">Edit Lead</a>
      <lead-informations
        [lead]="leadModel"
        #leadInformations
      ></lead-informations>
    </div>
  </div>
</div>

<div class="modal-footer justify-content-end" *ngIf="!notifyOnly">
  <button
    type="button"
    class="btn btn-soft-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button type="button" class="btn btn-soft-primary" (click)="acceptLead()">
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Accept</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
