<div class="accordion-item" *ngIf="docSet && docSet.documents">
  <h5 class="accordion-header m-0" id="heading{{docSet.documentSetId}}">
    <button class="accordion-button {{!accordionOpened ? 'collapsed' : ''}} fw-semibold" type="button"
      data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+docSet.documentSetId" aria-expanded="true"
      [attr.aria-controls]="'#collapse'+docSet.documentSetId">
      <i class="fas fa-file-pdf-o me-2"></i> {{docSet.documentSetUsageDescription}}
    </button>
  </h5>

  <div id="collapse{{docSet.documentSetId}}" class="accordion-collapse collapse {{accordionOpened ? 'show' : ''}}"
    aria-labelledby="heading{{docSet.documentSetId}}" data-bs-parent="#disclosureDocSetAccordion">
    <div class="accordion-body">
      <p-table #dt2 [value]="docSet.documents" styleClass="p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of columns">{{col.header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-doc>
          <tr>
            <td>{{doc.description}}</td>
            <td>
              <a class="cursor-pointer" *ngIf="doc.documentData" (click)="viewDocument(doc)">
                <span class="me-2">{{doc.name}}</span>
                <i *ngIf="doc.mimeType === 'text/html'" class='far fa-lg fa-file-code'></i>
                <i *ngIf="doc.mimeType === 'application/pdf'" class='fa fa-file-pdf-o'></i>
              </a>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2" class="text-center"> No documents found. </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
