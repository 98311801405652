<div class="modal-header">
    <h5 class="modal-title">Confirm Status</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row mb-3">

        <!-- ****************Pending**************** -->
        <div class="col-md-12" *ngIf="selectedTasks['Pending'] && ['Pending'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['Pending'].length}}</i>
                task{{selectedTasks['Pending'].length > 1 ? 's' : ''}} in "Pending" status.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="status"
                (change)="setTaskStatusBulk(selectedTasks['Pending'], status)">
                <option [ngValue]="null">-- Select One --</option>
                <option *ngIf="!requestBorrower" value="Requested">Requested</option>
                <option *ngIf="!requestBorrower" value="Completed">Completed</option>
                <option *ngIf="requestBorrower" value="Submitted">Submitted</option>
                <option *ngIf="requestBorrower" value="Approved">Approved</option>
                <option value="NotApplicable">Not Applicable</option>
            </select>
        </div>

        <div class="col-md-12" *ngIf="selectedTasks['PendingRequiresReview'] && selectedTasks['PendingRequiresReview'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['PendingRequiresReview'].length}}</i>
                task{{selectedTasks['PendingRequiresReview'].length > 1 ? 's' : ''}} in "Pending" status that Require Review..
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select" [(ngModel)]="reviewStatus"
                (change)="setTaskStatusBulk(selectedTasks['PendingRequiresReview'], reviewStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option *ngIf="!requestBorrower" value="Requested">Requested</option>
                <option *ngIf="requestBorrower" value="Submitted">Submitted</option>
                <option value="ReviewReady">Review Ready</option>
            </select>
        </div>

        <!-- ************Requested*************** -->

        <div class="col-md-12" *ngIf="selectedTasks['Requested'] && selectedTasks['Requested'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['Requested'].length}}</i>
                task{{selectedTasks['Requested'].length > 1 ? 's' : ''}} in "Requested" status.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="requestedStatus"
                (change)="setTaskStatusBulk(selectedTasks['Requested'], requestedStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="NotApplicable">Not Applicable</option>
            </select>
        </div>
        <div class="col-md-12"
            *ngIf="selectedTasks['RequestedRequiresReview'] && selectedTasks['RequestedRequiresReview'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['RequestedRequiresReview'].length}}</i>
                task{{selectedTasks['RequestedRequiresReview'].length > 1 ? 's' : ''}} in "Requested" status that Require Review.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="requestedRequiresReviewStatus"
                (change)="setTaskStatusBulk(selectedTasks['RequestedRequiresReview'], requestedRequiresReviewStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Pending">Pending</option>
                <option value="ReviewReady">Review Ready</option>
            </select>
        </div>

        <!-- ************Rejected*********** -->

        <div class="col-md-12" *ngIf="selectedTasks['Rejected'] && selectedTasks['Rejected'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['Rejected'].length}}</i>
                task{{selectedTasks['Rejected'].length > 1 ? 's' : ''}} in "Rejected" status.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="rejectedStatus"
                (change)="setTaskStatusBulk(selectedTasks['Rejected'], rejectedStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Submitted">Submitted</option>
                <option value="Approved">Approved</option>
                <option value="NotApplicable">Not Applicable</option>
            </select>
        </div>
        <div class="col-md-12"
            *ngIf="selectedTasks['RejectedRequiresReview'] && selectedTasks['RejectedRequiresReview'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['RejectedRequiresReview'].length}}</i>
                task{{selectedTasks['RejectedRequiresReview'].length > 1 ? 's' : ''}} in "Rejected" status that Require Review.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="rejectedRequiresReviewStatus"
                (change)="setTaskStatusBulk(selectedTasks['RejectedRequiresReview'], rejectedRequiresReviewStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Submitted">Submitted</option>
                <option value="ReviewReady">Review Ready</option>
            </select>
        </div>

        <!-- ************Submitted************ -->

        <div class="col-md-12" *ngIf="selectedTasks['Submitted'] && selectedTasks['Submitted'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['Submitted'].length}}</i>
                task{{selectedTasks['Submitted'].length > 1 ? 's' : ''}} in "Submitted" status.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="submittedStatus"
                (change)="setTaskStatusBulk(selectedTasks['Submitted'], submittedStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Rejected">Rejected</option>
                <option value="Approved">Approved</option>
                <option value="NotApplicable">Not Applicable</option>
            </select>
        </div>
        <div class="col-md-12"
            *ngIf="selectedTasks['SubmittedRequiresReview'] && selectedTasks['SubmittedRequiresReview'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['SubmittedRequiresReview'].length}}</i>
                task{{selectedTasks['SubmittedRequiresReview'].length > 1 ? 's' : ''}} in "Submitted" status that Require Review.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select" [(ngModel)]="submittedRequiresReviewStatus"
                (change)="setTaskStatusBulk(selectedTasks['SubmittedRequiresReview'], submittedRequiresReviewStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Rejected">Rejected</option>
                <option value="ReviewReady">Review Ready</option>
            </select>
        </div>

        <!-- **********Approved********* -->

        <div class="col-md-12" *ngIf="selectedTasks['Approved'] && selectedTasks['Approved'].length > 0">
            <p style="margin: 15px 0 0;">
                You have selected
                <i>{{selectedTasks['Approved'].length}}</i>
                task{{selectedTasks['Approved'].length > 1 ? 's' : ''}} in "Approved" status.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="approveStatus"
                (change)="setTaskStatusBulk(selectedTasks['Approved'], approveStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Pending">Pending</option>
                <option value="Submitted">Submitted</option>
                <option value="Rejected">Rejected</option>
            </select>
        </div>
        <div class="col-md-12"
            *ngIf="selectedTasks['ApprovedRequiresReview'] && selectedTasks['ApprovedRequiresReview'].length > 0">
            <p style="margin: 0;">
                You have selected
                <i>{{selectedTasks['ApprovedRequiresReview'].length}}</i>
                task{{selectedTasks['ApprovedRequiresReview'].length > 1 ? 's' : ''}} in "Approved" status that Require Review.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select" [(ngModel)]="approveRequiresReviewStatus"
                (change)="setTaskStatusBulk(selectedTasks['ApprovedRequiresReview'], approveRequiresReviewStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Pending">Pending</option>
                <option value="Submitted">Submitted</option>
                <option value="Rejected">Rejected</option>
                <option value="ReviewReady">Review Ready</option>
            </select>
        </div>

        <!-- ***********NotApplicable************ -->

        <div class="col-md-12" *ngIf="selectedTasks['NotApplicable'] && selectedTasks['NotApplicable'].length > 0">
            <p style="margin: 0;">
                You have selected
                <i>{{selectedTasks['NotApplicable'].length}}</i>
                task{{selectedTasks['NotApplicable'].length > 1 ? 's' : ''}} in "Not Applicable" status.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="notApplicableStatus"
                ng-change="setTaskStatusBulk(selectedTasks['NotApplicable'], notApplicableStatus)">
                <option [ngValue]="null">-- Select One --</option>
                <option value="Pending">Pending</option>
                <option *ngIf="!requestBorrower" value="Requested">Requested</option>
            </select>
        </div>
        <div class="col-md-12"
            *ngIf="selectedTasks['NotApplicableRequiresReview'] && selectedTasks['NotApplicableRequiresReview'].length > 0">
            <p style="margin: 0;">
                You have selected
                <i>{{selectedTasks['NotApplicableRequiresReview'].length}}</i>
                task{{selectedTasks['NotApplicableRequiresReview'].length > 1 ? 's' : ''}} in "Not Applicable" status that Require Review.
            </p>
            <label class="control-label" style="font-weight: bold; text-align: right;">
                New Status:
            </label>
            <select name="" id="" class="form-select " [(ngModel)]="notApplicableRequiresReviewStatus"
                ng-change="setTaskStatusBulk(selectedTasks['NotApplicableRequiresReview'], notApplicableRequiresReviewStatus)">
                <option [ngValue]="null">-- Select --</option>
                <option value="Pending">Pending</option>
            </select>
        </div>
    </div>

    <p class="mt-2 text-center">Do you want continue?</p>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">No</button>
    <button type="button" class="btn btn-primary right" (click)="activeModal.close(selectedTasks)" [disabled]="!canSave">Yes</button>
</div>