import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ThirdPartyCredential } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'app-lpa-credential-editor-dialog',
  templateUrl: './lpa-credential-editor-dialog.component.html',
  styleUrls: ['./lpa-credential-editor-dialog.component.scss']
})
export class LpaCredentialEditorDialogComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;
  @Output() onCredentialSaved?= new EventEmitter();
  @Input() credential: ThirdPartyCredential;
  @Input() userId: string;
  @Input() companyId: number;
  @Input() channels: EnumerationItem[];
  @Input() scope: ScopeType;
  @Input() branchId?: number;

  lpaCredential: ThirdPartyCredential = null;
  selectedChannels: string[];

  lpaKeyValuePairs: any = {
    sellerServicerNumber: null,
    lpaPassword: null,
    fhaLenderId: null,
    fhaSponsorId: null,
    thirdPartyOriginatorId: null,
    thirdPartyOriginatorIsCorrespondentLender: null,
    nonOriginatingThirdPartyId: null,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _channelService: ChannelService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
  ) { }

  ngOnInit(): void {
    if (!this.credential) {
      this.lpaCredential = {
        credentialType: 'AusVendor',
        vendorName: 'LPA',
        thirdPartyKeyValuePairs: [
          { key: '', value: '', alias: '', userName: '', password: '' }
        ],
        alias: '',
        url: '',
        userName: '',
        password: '',
        userId: this.userId,
        companyId: this.companyId
      };

      if (this.scope === 'Branch') {
        this.lpaCredential.branchId = this.lpaCredential.branchId || String(this.branchId);
      }
    }
    else {
      this.lpaCredential = _.cloneDeep(this.credential);
      this.lpaCredential.userId = this.lpaCredential.userId || this.userId;
      if (this.scope === 'Branch') {
        this.lpaCredential.branchId = this.lpaCredential.branchId || String(this.branchId);
      }
      this.lpaCredential.companyId = this.lpaCredential.companyId || this.companyId;
    }

    if (this.channels.length > 0) {
      this.selectedChannels = this._channelService
        .getChannelsListForDropdown(this.credential.channels)
        .map(channel => channel.name);
    }

    this.lpaKeyValuePairs.sellerServicerNumber = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'SellerServicerNumber') || {};
    this.lpaKeyValuePairs.lpaPassword = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'LpaPassword') || {};
    this.lpaKeyValuePairs.fhaLenderId = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'FhaLenderId') || {};
    this.lpaKeyValuePairs.fhaSponsorId = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'FhaSponsorId') || {};
    this.lpaKeyValuePairs.thirdPartyOriginatorId = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'ThirdPartyOriginatorId') || {};
    this.lpaKeyValuePairs.thirdPartyOriginatorIsCorrespondentLender = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'ThirdPartyOriginatorIsCorrespondentLender') || {};
    this.lpaKeyValuePairs.nonOriginatingThirdPartyId = this.lpaCredential.thirdPartyKeyValuePairs.find(el => el.key === 'NonOriginatingThirdPartyId') || {};
  }

  setCredentialChannels(): void {
    this.lpaCredential.channels = this.selectedChannels.length ? this.selectedChannels.join(', ') : '';
  }

  saveCredential = () => {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) {
      return;
    }
    this.lpaCredential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.lpaCredential, this.lpaKeyValuePairs);
    this.activeModal.close(this.lpaCredential);
  }
}
