import { Component, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from "src/app/core/services/local-storage.service";

@Component({
  templateUrl: './lead-interval-config-dialog.component.html',
})
export class LeadIntervalConfigDialogComponent {
  @Input()
  config: any;

  @ViewChild('intervalConfigForm')
  intervalConfigForm: NgForm | undefined;

  constructor(
    readonly activeModal: NgbActiveModal, 
    private readonly _localStorageService: LocalStorageService,
  ) {}

  save() {
    if (!this.intervalConfigForm) return;
    this.intervalConfigForm.form.markAllAsTouched();
    if (!this.intervalConfigForm.valid) return;

    this._localStorageService.setItem("leads-interval-config", this.config);
    this.activeModal.close(this.config);
  }
}