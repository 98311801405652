import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { DataService } from "../core/services/data.service";
import { CustomField } from "../modules/pricing/models/pricing/custom-fields.model";
import { BaseRequest } from "../modules/pricing/models/pricing/base-request.model";

@Injectable()
export class PricingCustomFieldService {

    constructor(private readonly _dataService: DataService) {
    }

    getCustomFields = (vendor: string): Observable<CustomField[]> => {
        let url = `api/admin/pricing/custom-fields?vendor=${vendor}`;
        return this._dataService.get(url).pipe(
            map(customFields => customFields.map(cf => ({
                ...cf,
                tempUniquId: Math.floor(Math.random() * Date.now() + cf.pricingCustomFieldId)
            })))
        );
    }

    insertCustomField = (customField: CustomField): Observable<CustomField> => {
        let url = 'api/admin/pricing/custom-fields';
        return this._dataService.post(url, customField)
    }

    updateCustomField = (customField: CustomField): Observable<CustomField> => {
        let url = `api/admin/pricing/custom-fields/${customField.pricingCustomFieldId}`;
        return this._dataService.post(url, customField)
    }

    deleteCustomField = (id: number): Observable<void> => {
        let url = `api/admin/pricing/custom-fields/${id}`;
        return this._dataService.delete(url)
    }
}