import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReggoraComponent } from './components/reggora/reggora.component';
import { ReggoraService } from './services/reggora.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    ReggoraComponent
  ],
  exports: [ReggoraComponent],
  providers: [ReggoraService]
})
export class ReggoraModule {
  constructor() { }
}
