<div class="card">
    <div class="card-header">
        <h4 class="card-title">Email Server Settings</h4>
    </div>
    <div class="card-body">
        <loading-indicator *ngIf="isLoading" [loadingMessage]="'Sending Email...'"></loading-indicator>
        <form #emailSettingsForm="ngForm" class="my-4" *ngIf="!isLoading">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Service Type</label>
                        <div class="col-sm-8">
                            <select class="form-select"
                                [ngClass]="{'is-invalid': serviceType && serviceType.touched && serviceType.invalid}"
                                [(ngModel)]="profile.emailService.serviceType" name="serviceType" #serviceType="ngModel"
                                (ngModelChange)="onServiceTypeChanged()"
                                required>
                                <option value="">-- Select One --</option>
                                <option value="Smtp">SMTP</option>
                                <option value="ExchangeWebServices">Exchange</option>
                                <option value="SendGrid">Lodasoft</option>
                                <option value="ExchangeMicrosoftGraph" *ngIf="tenantKvp?.value">Exchange (Azure/Modern Authentication)</option>
                                <option value="AmazonSes">Amazon SES (Simple Email Service)</option>
                            </select>
                            <div class="invalid-feedback">Please select service type.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="profile.emailService.serviceType === 'Smtp'">
                <div class="col-md-6">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Username</label>
                        <div class="col-sm-8">
                            <input class="form-control" placeholder="User Name" type="text"
                                [(ngModel)]="profile.emailService.userName" name="userName" required>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Server</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Server Name/IP"
                                [(ngModel)]="profile.emailService.server" name="smtp_server">
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-8">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox"
                                    [(ngModel)]="profile.emailService.isSecure" id="smtp_isSecure" name="smtp_isSecure">
                                <label class="form-check-label" for="smtp_isSecure">HTTPS</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Password</label>
                        <div class="col-sm-8">
                            <password-input [(ngModel)]="profile.emailService.password" [name]="'smtp_password'"
                                [placeholder]="'User Password'"></password-input>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Port</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Port Number"
                                [(ngModel)]="profile.emailService.port" name="smtp_port">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="profile.emailService.serviceType === 'ExchangeWebServices'">
                <div class="col-md-6">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">User</label>
                        <div class="col-sm-8">
                            <input class="form-control" placeholder="User Name" type="text"
                                [(ngModel)]="profile.emailService.userName" name="ews_userName" required>
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">
                            Service Url
                        </label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="exchange web services url"
                                [(ngModel)]="profile.emailService.servicesUrl" name="ews_servicesUrl"
                                autocomplete="false">
                            <a href="javascript: void(0)" (click)="autoDiscoverExchangeUrl()">
                                <span >Auto Discover Service Url</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Password</label>
                        <div class="col-sm-8">
                            <password-input [(ngModel)]="profile.emailService.password" [name]="'ews_password'"
                                [placeholder]="'User password'"></password-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="profile.emailService.serviceType === 'SendGrid'">
                <div class="col-md-6">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Auth Key</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Auth Key (provided by Lodasoft)"
                                [(ngModel)]="profile.emailService.apiKey" name="sg_apiKey">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                </div>
            </div>
            <div class="row" *ngIf="profile.emailService.serviceType === 'ExchangeMicrosoftGraph'">
                <ng-template [ngIf]="loadingUsersForMG" [ngIfElse]="exchangeMGContent">
                    <loading-indicator></loading-indicator>
                </ng-template>
                <ng-template #exchangeMGContent>
                    <div class="row">
                        <div class="col-md-6">
                            <ng-template [ngIf]="emailServiceUserNameToDisplay" [ngIfElse]="exchangeMGUserNameContent">
                                <div class="form-group mb-3 row">
                                    <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Email
                                        Sender</label>
                                    <div class="col-sm-8">
                                        <ng-select *ngIf="showMGselect" width="100%"
                                            name="emailSender" placeholder="Select email sender"
                                            bindLabel="displayName" bindValue="email"
                                            [multiple]="false" [closeOnSelect]="true" [(ngModel)]="emailServiceUserNameToDisplay"
                                            (change)="handleUserChange(emailServiceUserNameToDisplay)" [items]="users | sort: 'asc':'displayName'"
                                        >
                                            <ng-template ng-option-tmp let-item="item">
                                                {{ item.displayName }} <smaller>({{item.email}})</smaller>
                                            </ng-template>
                                        </ng-select>

                                        <input *ngIf="!showMGselect" type="text" class="form-control"
                                            name="emailServiceUserNameToDisplay" [ngModel]="emailServiceUserNameToDisplay"
                                            disabled>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-8">
                                        <p *ngIf="!displayMicrosoftGraphInfoForEmailServiceUser">Your configured sender for
                                            email impersonation '{{emailServiceUserNameToDisplay}}' was not found in the
                                            Azure tenant linked to your Lodasoft account and will not be used for emails
                                            sent from Lodasoft. Please contact an admin to resolve this.</p>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #exchangeMGUserNameContent>
                                <div class="form-group mb-3 row">
                                    <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Email
                                        Sender</label>
                                    <div class="col-sm-8">
                                        <ng-select *ngIf="showMGselect" width="100%"
                                            name="emailSender" placeholder="Select email sender"
                                            bindLabel="displayName" bindValue="email"
                                            [multiple]="false" [closeOnSelect]="true" [(ngModel)]="userNameToDisplay"
                                            (change)="handleUserChange(userNameToDisplay)" [items]="users | sort: 'asc':'displayName'"
                                        >
                                            <ng-template ng-option-tmp let-item="item">
                                                {{ item.displayName }} <smaller>({{item.email}})</smaller>
                                            </ng-template>
                                        </ng-select>

                                        <input *ngIf="!showMGselect" type="text" class="form-control"
                                            name="userNameToDisplay" [ngModel]="userNameToDisplay" disabled>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-8">
                                        <p *ngIf="!displayMicrosoftGraphInfoForUser">Your user name was not found in the
                                            Azure account linked to your company Lodasoft account. Please contact an admin
                                            to resolve this.</p>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-md-6"></div>
                    </div>
                    
                    <div class="row">
                        <ng-container *ngIf="
                            microsoftGraph?.userName
                            && initMicrosoftGraphUserName === microsoftGraph?.userName"
                        >
                            <div class="col-md-2"></div>
                            <div class="col-md-10 mb-3">
                                <div class="form-check form-check-inline" *ngIf="connectToOutlookCalendarKvp">
                                    <input class="form-check-input"
                                        type="checkbox"
                                        ToggleValue
                                        [true]="'1'"
                                        [false]="'0'"
                                        [(ngModel)]="connectToOutlookCalendarKvp.value"
                                        id="connectToOutlookCalendar"
                                        name="connectToOutlookCalendar"
                                        [disabled]="!microsoftGraph.userName"
                                        (change)="toggleConnectOutlookCalendar()"
                                    />
                                    <label class="form-check-label" for="connectToOutlookCalendar">Connect Outlook Calendar with Lodasoft</label>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="microsoftGraph.userName && initMicrosoftGraphUserName !== microsoftGraph.userName">
                            <div class="col-md-2"></div>
                            <div class="col-md-10">
                                <p class="badge badge-soft-warning p-2">
                                    Please save user name to get outlook calendars.
                                </p>
                            </div>
                        </ng-container>

                        <div *ngIf="
                            connectToOutlookCalendarKvp?.value == 1
                            && microsoftGraph?.userName
                            && initMicrosoftGraphUserName === microsoftGraph?.userName
                        " class="col-md-12">
                            <div class="form-group row" *ngIf="syncedCalendarIdKvp">
                                <label class="col-md-2 form-label align-self-center mb-lg-0 text-end">Synchronized Calendar</label>
                                <div class="col-md-4">
                                    <select type="text" class="form-control" #syncedCalendarId="ngModel"
                                        [ngClass]="{'is-invalid': syncedCalendarId && syncedCalendarId.touched && syncedCalendarId.invalid }" name="syncedCalendarId"
                                        [(ngModel)]="syncedCalendarIdKvp.value" required>
                                        <option [ngValue]="null">--Select One--</option>
                                        <option *ngFor="let calendar of outlookCalendars | sort: 'desc':'name'"
                                            value="{{calendar.thirdPartyCalendarId}}">
                                            {{calendar.name}}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">Please select a calendar.</div>
                                </div>
                            </div>
                            <div class="row" *ngIf="initMicrosoftGraphCalendarCheckboxValue != connectToOutlookCalendarKvp.value && syncedCalendarIdKvp.value">
                                <div class="col-md-2"></div>
                                <div class="col-md-10">
                                    <p class="badge badge-soft-info p-2">
                                        Please save to make this calendar effect.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="row" *ngIf="profile.emailService.serviceType === 'AmazonSes' && amazonWebServices">
                <div class="col-md-6">
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Default From Email<i
                                class="fa fa-info-circle text-primary ms-1" placement="top"
                                ngbTooltip="Please ensure that this email address has been verified."></i></label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="Enter verified email address"
                                autocomplete="off" name="defaultEmailAddress" #defaultEmail="ngModel" [ngClass]="{
                        'is-invalid': defaultEmail && defaultEmail.touched && defaultEmail.invalid
                      }" [(ngModel)]="profile.emailService.userName" required email />
                            <div class="invalid-feedback">Please enter verified email address.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Application ID</label>
                        <div class="col-sm-8">
                            <!-- autocomplete="cc-family-name" prevent from autocompleting -->
                            <input type="text" class="form-control" placeholder="Application ID"
                                #applicationId="ngModel" autocomplete="cc-family-name"
                                [ngClass]="{'is-invalid': applicationId && applicationId.touched && applicationId.invalid }"
                                name="applicationId" [(ngModel)]="amazonWebServices.userName" required />
                            <div class="invalid-feedback">Please enter application id.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Application
                            Secret</label>
                        <div class="col-sm-8">
                            <!-- autocomplete="cc-family-name" prevent from autocompleting -->
                            <input type="password" class="form-control" placeholder="Application Secret"
                                #applicationSecret="ngModel" autocomplete="cc-family-name"
                                [ngClass]="{'is-invalid': applicationSecret && applicationSecret.touched && applicationSecret.invalid }"
                                name="applicationSecret" [(ngModel)]="amazonWebServices.password" required />
                            <div class="invalid-feedback">Please enter application secret.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row mb-3">
                        <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Region</label>
                        <div class="col-md-8">
                            <select type="text" class="form-control" #region="ngModel"
                                [ngClass]="{'is-invalid': region && region.touched && region.invalid }" name="region"
                                (change)="updateThirdPartyCredsForRegion()" [(ngModel)]="awsRegionKvp.value" required>
                                <option [ngValue]="null">--Select One--</option>
                                <option *ngFor="let region of awsRegions | sort: 'desc':'name'"
                                    value="{{region.value}}">
                                    {{region.name}}
                                </option>
                            </select>
                            <div class="invalid-feedback">Please select a region.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-2">
                <button type="button" class="btn btn-outline-warning" (click)="sendTestEmail()" style="float: right;">
                    Send Test Email
                </button>
            </div>
        </form>
    </div>
</div>