<div class="modal-header">
  <h6 class="modal-title">Please search for the loan from your LDE</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
    (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div *ngIf="!loading else spinner">
    <div class="row row border-bottom">
      <div class="col-md-3 form-group">
        <label for="ldeVendor">LDE Vendor</label>
        <select class="form-select" id="ldeVendor" name="ldeVendor" [(ngModel)]="ldeCredentialId"
          (change)="changeLdeCredential(ldeCredentialId)">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let item of ldeVendors" value="{{item.credentialId}}"
            selected="{{ldeCredentialId === item.credentialId}}">
            {{item.losVendorName}}
          </option>
        </select>
      </div>
      <div class="col-md-2 form-group">
        <label for="ldeReference">LDE Reference</label>
        <input class="form-control" id="ldeReference" name="ldeReference" type="text" [(ngModel)]="ldeReference"
          [disabled]="!ldeCredentialId">
      </div>
      <div class="col-md-3 form-group">
        <label for="borrowerFirst">Borrower First</label>
        <input class="form-control" id="borrowerFirst" name="borrowerFirst" type="text" [(ngModel)]="borrowerFirst"
          [disabled]="!ldeCredentialId || selectedVendor.borrowerDisabled">
      </div>
      <div class="col-md-3 form-group">
        <label for="borrowerLast">Borrower Last</label>
        <input class="form-control" id="borrowerLast" name="borrowerLast" type="text" [(ngModel)]="borrowerLast"
          [disabled]="!ldeCredentialId || selectedVendor.borrowerDisabled">
      </div>
      <div class="col-md-1" style="margin-top: 28px;">
        <a *ngIf="!isSearching" id="search" name="search" (click)="search()" href="javascript: void(0);"
          [ngStyle]="{ 'pointer-events': ldeCredentialId && (ldeReference || borrowerFirst || borrowerLast) ? 'auto' : 'none' }">
          <i class="fa fa-lg fa-search"></i>
        </a>
        <i class="fa fa-lg fa-sync fa-spin" *ngIf="isSearching"></i>
      </div>
    </div>
    <div class="row scrollable-content">
      <div class="col-md-12">
        <h3 *ngIf="loanSearchList && !loanSearchList.length">
          There are no loans matching the search criteria.
        </h3>

        <div class="table-responsive">
          <table class="table mb-0 table-centered" *ngIf="loanSearchList">
            <thead>
              <tr>
                <td>Select</td>
                <td>Loan Number</td>
                <td>Loan Status</td>
                <td>Borrower First</td>
                <td>Borrower Last</td>
                <td>Subject Property Address</td>
                <td>Subject Property CSZ</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let loan of loanSearchList">
                <td>
                  <input type="radio" name="search" [(ngModel)]="selectedLoanId" value="{{loan.losIdentifier}}"
                    (click)="setSelectedLoan(loan.loanNumber == undefined ? '' : loan.loanNumber, loan.losIdentifier)" />
                </td>
                <td>{{loan.loanNumber}}</td>
                <td>{{loan.loanStatus}}</td>
                <td>{{loan.borrowerFirstName}}</td>
                <td>{{loan.borrowerLastName}}</td>
                <td>{{loan.subjectPropertyAddress}}</td>
                <td>{{loan.subjectPropertyCity}}, {{loan.subjectPropertyState}} {{loan.subjectPropertyZip}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #spinner>
    <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
  </ng-template>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="link()"
          [disabled]="isLinking || !selectedLoan || !selectedLoan.ldeIdentifier.length">
          <span *ngIf="isLinking" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Link with LDE Loan
        </button>
      </div>
    </div>
  </div>
</div>