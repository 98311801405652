import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  template: `
  <span class="nav-user-name  fw-semibold px-1">{{user?.firstName || 'John Doe' }}</span>
            <img [src]="user?.userImage || 'assets/images/users/user-5.jpg'" alt="profile-user" class="rounded-circle thumb-sm">
            `
})
export class UserAvatarComponent implements OnInit {

  @Input() 
  user = {firstName:'John Doe', userImage:'assets/images/users/user-5.jpg'};

  constructor() { }

  ngOnInit(): void {
  }
}
