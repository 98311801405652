<div class="d-flex justify-content-between align-items-center">
    <div class="list-view-title">
        <h2>Jan - Dec {{selectedYear}}</h2>
    </div>
    <div>
        <button type="button" (click)="previousYearClicked()" title="Previous Year" class="list-view-header-button">
            <span class="fc-icon fc-icon-chevron-left"></span>
        </button><button type="button" (click)="nextYearClicked()" title="Next Year"
            class="list-view-header-button ms-1">
            <span class="fc-icon fc-icon-chevron-right"></span>
        </button>
    </div>
    <div>
        <button type="button" title="Show Calendar View" (click)="onShowCalendarViewClicked.emit()"
            class="list-view-header-button">
            Show Calendar View
        </button>
    </div>
</div>
<ul *ngIf="filteredHolidayEvents?.length" class="list-view-details mt-3">
    <ng-container *ngFor="let holidayEvent of filteredHolidayEvents">
        <li class="highlight">
            <div class="d-flex justify-content-between align-items-center px-3 py-2">
                <span>{{holidayEvent.dateStr | date: 'MMMM dd YYYY'}}</span>
                <span>{{holidayEvent.dateStr | date: 'EEEE'}}</span>
            </div>
        </li>
        <li (click)="editEvent(holidayEvent)">
            <div class="d-flex justify-content-left align-items-center px-3 py-2">
                <span class="event-dot me-2" [class]="holidayEvent.override ? 'text-danger' : 'text-success'"></span>
                <span [ngClass]="{'text-decoration-line-through': holidayEvent.override}">{{holidayEvent.title}}</span>
            </div>
        </li>
    </ng-container>
</ul>
<div class="mt-3 empty-holiday-list" *ngIf="!filteredHolidayEvents?.length">
    There are no holiday entries for the selected year.
</div>