import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { AgentType, AgentTypeList } from '../models/agent-type.model';

@Injectable()
export class AgentTypeService {
  constructor(private readonly _dataService: DataService) { }

  getAllAgentTypesByCompanyId = (companyId: number): Observable<AgentTypeList> => {
    const url = `api/admin/AllAgentType?companyId=${companyId}`;
    return this._dataService.get(url);
  };

  insertAgentType = (newAgentType: AgentType, companyId: number): Observable<AgentType> => {
    const url = `api/admin/InsertAgentType?companyId=${companyId}`;
    return this._dataService.post(url, newAgentType);
  };

  updateAgentType = (agentType: AgentType, companyId: number): Observable<void> => {
    const url = `api/admin/UpdateAgentType?companyId=${companyId}`;
    return this._dataService.post(url, agentType);
  };

  deleteAgentType = (agentTypeId: number, replaceWithAgentTypeId: number, companyId: number): Observable<void> => {
    let url = `api/admin/DeleteAgentType/${agentTypeId}?companyId=${companyId}`;
    if (replaceWithAgentTypeId) {
      url += `&replaceWithAgentTypeId=${replaceWithAgentTypeId}`;
    }
    return this._dataService.delete(url);
  };

  updateAgentTypeOrder = (sortedAgentTypeIds: Array<{id: number}>, companyId: number): Observable<void> => {
    let url = `api/Admin/UpdateAgentTypeOrder?companyId=${companyId}`;
    return this._dataService.post(url, sortedAgentTypeIds);
  };
}
