import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { ManualDialParams } from 'src/app/modules/dialer/models/manual-dial-params.model';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CreditPullDialogComponent } from '../dialogs/credit-pull-dialog/credit-pull-dialog.component';
import { Address } from '../../../../../models';
import { formatDate } from '@angular/common';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

export class State {
  code: string;
  name: string;
  zipRangeMin?: number;
  zipRangeMax?: number;

  constructor(state?: State) {
    if (state) {
      this.code = state.code;
      this.name = state.name;
      this.zipRangeMin = state.zipRangeMin;
      this.zipRangeMax = state.zipRangeMax;
    }
  }
}
@Component({
  selector: 'lead-general-info',
  templateUrl: './lead-general-info.component.html',
  styleUrls: ['./lead-general-info.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadGeneralInfoComponent extends ApplicationContextBoundComponent implements OnChanges, OnInit {

  @Input() lead: Lead;
  @Input() originalLeadData: Lead;
  @Input() isDrawer: boolean = false;
  @Input() isManualDial: boolean = true;
  @Input() isSsnHidden: boolean = false;
  @Input() isSubjectPropertyAddressHidden: boolean = false;
  @Input() isAppraisedValueHidden: boolean = false;
  @Input() isPullCreditEnabled: boolean = false;
  @Input() isSolar: boolean = false;
  
  @Input() 
  isBankruptcy: boolean = false;

  @Input() 
  coIsBankruptcy: boolean = false;

  @Input() 
  bankruptcyTypeList: EnumerationItem[] = [];
  
  @Input() 
  bestTimeToContactList: EnumerationItem[] = [];

  @Output() dialClicked = new EventEmitter<any>();
  @Output() leadReloaded = new EventEmitter<never>();

  states: State[] = [];
  
  showBorrowersBody: boolean = true;
  showCoBo: boolean = false;
  dialerEnabled: boolean;

  ltv: number = 0;
  cltv: number = 0;
  totalLoanAmount: number = 0;

  mobilePhone: string;
  phone: string;
  workPhone: string;
  coMobilePhone: string;
  coPhone: string;
  coWorkPhone: string;

  maxDate: Date = new Date();
  minDate: Date = new Date("1/1/1900");

  suffixes: EnumerationItem[] = [];
  authorizationMethods: EnumerationItem[] = [];
  protected selectedBestTimes: EnumerationItem[] = [];
  protected selectedCoBestTimes: EnumerationItem[] = [];
  protected multiSelectSettings: IDropdownSettings = {};

  readonly options: any = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' }
  }
  protected handlePresentAddressChange: (e: Partial<Address>) => void;
  protected handleMailingAddressChange: (e: Partial<Address>) => void;
  protected handleCoPresentAddressChange: (e: Partial<Address>) => void;
  protected handleCoMailingAddressChange: (e: Partial<Address>) => void;

  constructor(
    injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _dialerService: DialerService,
    private readonly _enumerationService: EnumerationService,
    private readonly _conversationService: ConversationService
  ) {
    super(injector);
    this.multiSelectSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    const leadChange = changes.lead;
    if (leadChange != null) {
      this.initAddressChangeHandlers();
    }
    if (this.lead && this.lead.bestTimeToContact) { 
      this.populateSelectedTimes(this.lead.bestTimeToContact.toString(), this.selectedBestTimes);
    }
    if (this.lead && this.lead.coBestTimeToContact) {
        this.populateSelectedTimes(this.lead.coBestTimeToContact.toString(), this.selectedCoBestTimes);
    }
  }

  ngOnInit(): void {

    this.showCoBo = !!this.lead.coFirstName || !!this.lead.coLastName;

    this.applicationContextService.context.subscribe(context => {
      this.states = Object.keys(context.globalConfig.states).map(key => new State({ code: key, name: context.globalConfig.states[key] }));
      this.dialerEnabled = context.userPermissions.dialerEnabled;
    });

    this.mobilePhone = Utils.cleanPhone(this.lead.mobilePhone);
    this.phone = Utils.cleanPhone(this.lead.phone);
    this.workPhone = Utils.cleanPhone(this.lead.workPhone);

    // coborrower
    this.coMobilePhone = Utils.cleanPhone(this.lead.coMobilePhone);
    this.coPhone = Utils.cleanPhone(this.lead.coPhone);
    this.coWorkPhone = Utils.cleanPhone(this.lead.coWorkPhone);

    this._enumerationService.getMortgageEnumerations().subscribe((result) => {
      this.suffixes = result[Constants.enumerations.suffix];
    });

    this.authorizationMethods = this._enumerationService.authorizationMethods;
    const index = this.authorizationMethods.findIndex(x => x.value == 'Internet');
    if (index >= 0) {
      this.authorizationMethods.splice(index, 1);
    }
  }
  
  private initAddressChangeHandlers(): void {
    this.handlePresentAddressChange = createAddressChangeHandler(this.lead, {
      address1: 'presentAddress1',
      city: 'presentCity',
      state: 'presentState',
      zipCode: 'presentZip',
    });
    this.handleMailingAddressChange = createAddressChangeHandler(this.lead, {
      address1: 'mailingAddress1',
      city: 'mailingCity',
      state: 'mailingState',
      zipCode: 'mailingZip',
    });
    this.handleCoPresentAddressChange = createAddressChangeHandler(this.lead, {
      address1: 'coPresentAddress1',
      city: 'coPresentCity',
      state: 'coPresentState',
      zipCode: 'coPresentZip',
    });
    this.handleCoMailingAddressChange = createAddressChangeHandler(this.lead, {
      address1: 'coMailingAddress1',
      city: 'coMailingCity',
      state: 'coMailingState',
      zipCode: 'coMailingZip',
    });
  }
  
  isEqualsEmailValidate = (email: string, email2: string): boolean => {
    return email && email2 && email.trim() == email2.trim();
  }

  emailFormatValidate = (email: string) => {
    return !email ||
      (
        email
        &&
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
  }

  onCopyFromMobileNumberClicked = (): void => {
    if (this.lead.mobilePhone) {
      this.phone = this.mobilePhone;
      this.lead.phone = this.lead.mobilePhone;
    } else return;
  }

  onCopyFromCoMobileNumberClicked = (): void => {
    if (this.lead.coMobilePhone) {
      this.coPhone = this.coMobilePhone;
      this.lead.coPhone = this.lead.coMobilePhone;
    } else {
      return;
    }
  }

  copyAddressFromTo = (from: string, to: string) => {
    this.lead[to + 'Address1'] = this.lead[from + 'Address1'];
    this.lead[to + 'Address2'] = this.lead[from + 'Address2'];
    this.lead[to + 'City'] = this.lead[from + 'City'];
    this.lead[to + 'State'] = this.lead[from + 'State'];
    this.lead[to + 'Zip'] = this.lead[from + 'Zip'];
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    if (zipCode) {
      this.lead[modelNamePrefix + "State"] = zipCode.state.toLowerCase();
      this.lead[modelNamePrefix + "City"] = Utils.toTitleCase(zipCode.city);
      this.lead[modelNamePrefix + "Zip"] = zipCode.zipcode;
    }
  }

  showCreditModal = () => {
    const filterOutNullish = (obj) => _.pickBy(obj, (e) => e != null);

    // Nullish valued fields may not match, so they are excluded from the comparison.
    const lead = filterOutNullish(this.lead);
    const originalLeadData = filterOutNullish(this.originalLeadData);

    if (!_.isEqual(lead, originalLeadData)) {
      this._notifyService.showWarning("Please save lead before running credit ", "Warning!");
      return;
    }

    const modalRef = this._modalService.open(CreditPullDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.leadId = lead.leadId;

    modalRef.result.then(() => {
      this.leadReloaded.emit(); // get credit report score
    }, () => {
      this.leadReloaded.emit(); // get credit report score
    });
  }

  onAuthorizationCheckChanged = () => {
    if (!this.lead.dateAuthorizedCreditCheck) {
      this.lead.dateAuthorizedCreditCheck = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
    }
  }

  onCoAuthorizationCheckChanged = () => {
    if (!this.lead.coDateAuthorizedCreditCheck) {
      this.lead.coDateAuthorizedCreditCheck = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
    }
  }

  onPhoneChanged = (value: string, type: string) => {
    let prefix = ""

    if (this.originalLeadData[type]) {
      if (this.originalLeadData[type].indexOf("+1") > -1) {
        prefix = '+1';
      } else if (this.originalLeadData[type].length > 10 && this.originalLeadData[type][0] === "1") {
        prefix = '1';
      }
    }

    this.lead[type] = prefix + value;
  }

  dial = (phoneNumber: string, phoneType: PhoneType, coBorr: boolean): void => {

    if (this.isManualDial) {

      let data = {
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: !coBorr ? this.lead.firstName : this.lead.coFirstName,
        lastName: !coBorr ? this.lead.lastName : this.lead.coLastName,
        recordType: RecordType.Lead,
        recordId: this.lead.leadId
      } as ManualDialParams;

      this._dialerService.openCallControlPanel(undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, data)
    } else {
      this.dialClicked.emit({
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: !coBorr ? this.lead.firstName : this.lead.coFirstName,
        lastName: !coBorr ? this.lead.lastName : this.lead.coLastName,
        recordType: RecordType.Lead,
        recordId: this.lead.leadId
      })
    }
  }

  onOpenSmsChat = (phone: string, isCoBorrower?: boolean) => {
    const userName = isCoBorrower ? `${this.lead.coFirstName} ${this.lead.coLastName}` : `${this.lead.firstName} ${this.lead.lastName}`;
    this._conversationService.openSmsChat({
      userPhone: phone,
      userName: userName,
      openDrawer: true,
      openChat: true,
      conversationTargetPersonContext: {
        leadId: this.lead.leadId
      }
    })
  }

  protected onBestTimeChange(selectedItems: EnumerationItem[]) {
    this.lead.bestTimeToContact = selectedItems.reduce((acc, item) => acc | item.value, 0);
  }
  protected onCoBestTimeChange(selectedItems: EnumerationItem[]) {
    this.lead.coBestTimeToContact = selectedItems.reduce((acc, item) => acc | item.value, 0);
  }

  private populateSelectedTimes(bestTimeString: string, targetArray: EnumerationItem[]) {
    const bestTimeArray = bestTimeString.split(',').map(time => time.trim());
    targetArray.length = 0;
    this.bestTimeToContactList.forEach(item => {
        if (bestTimeArray.includes(item.name)) {
            targetArray.push(item);
        }
    });
  }
}

function createAddressChangeHandler<T extends { [key: string]: any }>(
  target: T,
  targetMapper: { [k in keyof Partial<Address>]: keyof T },
): (e: Partial<Address>) => void {
  return (e: Partial<Address>) => {
    target[targetMapper.address1] = '' as T[keyof T]; // to reset the last populated address.

    setTimeout(() => {
      target[targetMapper.address1] = e.address1 as T[keyof T];
      target[targetMapper.city] = e.city as T[keyof T];
      target[targetMapper.state] = e.state as T[keyof T];
      target[targetMapper.zipCode] = e.zipCode as T[keyof T];
    }, 200);
  };
}

