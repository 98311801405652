<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h4 class="card-title">Disclosure Path</h4>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <label class="form-label" for="disclosuresWillBeSent">Who is sending disclosures</label>
                        <select class="form-select mb-3" name="disclosuresWillBeSent" id="disclosuresWillBeSent"
                            [disabled]="disclosuresWillBeSentReadOnly" [(ngModel)]="disclosurePath">
                            <option *ngFor="let pathPermissions of disclosurePathPermissionsOptions"
                                [ngValue]="pathPermissions.value">{{pathPermissions.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-9 col-md-6 col-sm-12" *ngIf="!tridAppDate">
                        <label class="mt-3">
                            <p><b>There is no key date for TRID Application Date</b></p>
                        </label>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="tridAppDate">
                        <label class="form-label" for="tridApplicationDate">TRID Application Date</label>
                        <date-input name="tridApplicationDate" [id]="'tridApplicationDate'"
                            [(ngModel)]="tridAppDate.eventDate"
                            [disabled]="disclosurePath == 'Lender' || disclosurePath == 'Tbd'"></date-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>