import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { MessageDeliveryEnum } from "src/app/models";
import { Message, MessageHistoryItem } from "src/app/models/message.model";
import { EmailTemplate } from "../models/email-template.model";
import { MessageAllowedSendRange, TimeZoneType } from "../models/message-allowed-send-range.model";

@Injectable()
export class CorrespondenceService {

  constructor(private readonly _dataService: DataService) {
  }

  sendLoanEmail = (email: Message, appendEmailSignature: boolean, sendImmediately?: boolean,
      impersonationFrom?: string, impersonationFromUserId?: string): Observable<any> => {
    let url = `api/message/InsertLoanEmailIntoQueue/${appendEmailSignature}`;
    if (sendImmediately != null) {
      url += `?sendImmediately=${sendImmediately}`
    }
    if (impersonationFrom) {
      url += (sendImmediately != null ? '&': '?') + `impersonationFrom=${impersonationFrom}`;
    }
    if (impersonationFromUserId) {
      url += ((sendImmediately != null || impersonationFrom) ? '&' : '?') + `impersonationFromUserId=${impersonationFromUserId}`;
    }
    return this._dataService.post(url, email);
  }

  sendLeadEmail = (email: Message, appendEmailSignature: boolean): Observable<any> => {
    const url = `api/message/InsertLeadEmailIntoQueue/${appendEmailSignature}`;
    return this._dataService.post(url, email);
  }

  sendAgentEmail = (email: Message, appendEmailSignature: boolean): Observable<any> => {
    const url = `api/message/InsertAgentEmailIntoQueue/${appendEmailSignature}`;
    return this._dataService.post(url, email);
  }

  sendContactEmail = (email: Message, appendEmailSignature: boolean): Observable<any> => {
    const url = `api/message/InsertContactEmailIntoQueue/${appendEmailSignature}`;
    return this._dataService.post(url, email);
  }

  sendLoanSms = (sms: Message, appendSmsSignature: boolean): Observable<any> => {
    const url = `api/message/InsertLoanSmsIntoQueue/${appendSmsSignature}`;
    return this._dataService.post(url, sms);
  }

  sendLeadSms = (sms: Message, appendSmsSignature: boolean): Observable<any> => {
    const url = `api/message/InsertLeadSmsIntoQueue/${appendSmsSignature}`;
    return this._dataService.post(url, sms);
  }

  sendAgentSms = (sms: Message, appendSmsSignature: boolean): Observable<any> => {
    const url = `api/message/InsertAgentSmsIntoQueue/${appendSmsSignature}`;
    return this._dataService.post(url, sms);
  }

  sendContactSms = (sms: Message, appendSmsSignature: boolean): Observable<any> => {
    const url = `api/message/InsertContactSmsIntoQueue/${appendSmsSignature}`;
    return this._dataService.post(url, sms);
  }

  getCampaignEmailTemplates = (): Observable<EmailTemplate[]> => {
    const url = `api/admin/email-campaigns/templates`;
    return this._dataService.get(url);
  }

  getCampaignEmailTemplate = (emailTemplateId: number): Observable<EmailTemplate> => {
    const url = `api/admin/email-campaigns/templates/${emailTemplateId}`;
    return this._dataService.get(url);
  }

  getLoanEmailTemplates = (userId: string): Observable<EmailTemplate[]> => {
    const url = `api/admin/email-campaigns/manual-templates?emailTemplateType=Loan&userGuid=${userId}`;
    return this._dataService.get(url);
  }

  getLeadEmailTemplates = (): Observable<EmailTemplate[]> => {
    const url = `api/admin/email-campaigns/manual-templates?emailTemplateType=Lead`;
    return this._dataService.get(url);
  }

  getEmailTemplateForLoan = (emailtemplateId: number, loanId: number): Observable<EmailTemplate> => {
    const url = `api/admin/email-campaigns/manual-templates/${emailtemplateId}?loanId=${loanId}`;
    return this._dataService.get(url);
  }

  getEmailTemplateForLead = (emailtemplateId: number, leadId: number): Observable<EmailTemplate> => {
    const url = `api/admin/email-campaigns/manual-templates/${emailtemplateId}?leadId=${leadId}`;
    return this._dataService.get(url);
  }

  getSentLoanMessages = (loanId: number): Observable<MessageHistoryItem[]> => {
    const url = `api/Email/GetEmailsSentForApp/${loanId}`;
    return this._dataService.get(url);
  }

  getMessagesSentToBorrower = (borrowerId: number): Observable<MessageHistoryItem[]> => {
    const url = `api/Email/GetEmailsSentToBorrower/${borrowerId}`;
    return this._dataService.get(url);
  }

  getMessagesSentToAgent = (agentId: number): Observable<MessageHistoryItem[]> => {
    const url = `api/Email/GetEmailsSentToAgent/${agentId}`;
    return this._dataService.get(url);
  }

  getMessagesSentForLead = (leadId: number): Observable<MessageHistoryItem[]> => {
    const url = `api/Email/GetEmailsSentForLead/${leadId}`;
    return this._dataService.get(url);
  }

  // scheduled email/sms send ranges
  insertAllowedSendRangeForDelivery = (scheduleType: TimeZoneType, delivery: MessageDeliveryEnum, ranges: MessageAllowedSendRange): Observable<MessageAllowedSendRange[]> => {
    const url = `api/Admin/InsertMessageAllowedSendRangeForDelivery/${scheduleType}/${delivery}`;
    return this._dataService.post(url, ranges);
  }

  insertAllowedSendRangeForDeliveryDefaults = (scheduleType: TimeZoneType, delivery: MessageDeliveryEnum): Observable<MessageAllowedSendRange[]> => {
    const url = `api/Admin/InsertMessageAllowedSendRangeForDelivery/${scheduleType}/${delivery}/defaults`;
    return this._dataService.post(url, {});
  }

  insertAllowedSendRange = (range: MessageAllowedSendRange): Observable<MessageAllowedSendRange> => {
    const url = `api/Admin/InsertMessageAllowedSendRange`;
    return this._dataService.post(url, range);
  }

  getAllowedSendRanges = (scheduleType: TimeZoneType, delivery: MessageDeliveryEnum): Observable<MessageAllowedSendRange[]> => {
    const url = `api/Admin/GetAllMessageAllowedSendRange/${scheduleType}/${delivery}`;
    return this._dataService.get(url);
  }

  updateAllowedSendRange = (range: MessageAllowedSendRange): Observable<MessageAllowedSendRange> => {
    const url = `api/Admin/UpdateMessageAllowedSendRange`;
    return this._dataService.post(url, range);
  }

  deleteAllowedSendRange = (rangeId: number): Observable<any> => {
    const url = `api/Admin/DeleteMessageAllowedSendRange/${rangeId}`;
    return this._dataService.delete(url);
  }

}
