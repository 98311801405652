<div class="modal-header">
  <h5 class="modal-title">Quick Guide for {{lenderInfo.name}}</h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="modal-body">

    <h5>Account Executive</h5>
    <strong><span class="control-label">Name:</span></strong> {{lenderInfo.aeName}} <br />
    <strong><span class="control-label">Phone #:</span></strong> {{lenderInfo.aePhone | phone}} <br />
    <strong><span class="control-label">Mobile #:</span></strong> {{lenderInfo.aeMobile | phone}} <br />
    <strong><span class="control-label">Fax #:</span></strong> {{lenderInfo.aeFax| phone}} <br />
    <strong><span class="control-label">Email:</span></strong> {{lenderInfo.aeEmail}} <br />
    <strong><span class="control-label">Website:</span></strong><a *ngIf="lenderInfo.website"
      href="{{lenderInfo.website}}" target="_new"> {{lenderInfo.website}}</a> <br /><br />
    <h5 ng-if="lenderInfo.mortgageeClause || lenderInfo.titleClause">Clauses</h5>
    <strong><span class="control-label" ng-if="lenderInfo.mortgageeClause">Mortgagee:</span></strong>
    {{lenderInfo.mortgageeClause}} <br /><br />
    <strong><span class="control-label" ng-if="lenderInfo.titleClause">Title:</span></strong> {{lenderInfo.titleClause}}

  </div>
</div>
<div class="modal-footer">

  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()"> Done </button>
</div>
