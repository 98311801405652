import { NgModule } from '@angular/core';
import { ExternalContactsComponent } from './components/external-contacts/external-contacts.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableModule } from 'primeng/table';
import { RemainContactModule } from '../remain-contact/remain-contact.module';
import { AgentsModule } from '../agents/agents.module';

@NgModule({
  declarations: [
    ExternalContactsComponent
  ],
  imports: [
    RemainContactModule,
    SharedModule,
    TableModule,
    AgentsModule
  ],
  exports: [
    ExternalContactsComponent
  ],
})
export class ExternalContactsModule {
  constructor() {}
}
