<form id="duForm" #duForm="ngForm" name="duForm">
    <div class="modal-header">
        <h5 class="modal-title"> Desktop Underwriter (DU) </h5>
        <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger border-0" role="alert" *ngIf="!duParams.isSuccessful && duParams.status">
            {{ duParams.status }}
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <label class="mb-2">
                            <b> Credit Provider </b>
                        </label>
                        <select class="form-select" id="creditVendor" name="creditVendor"
                            [(ngModel)]="duParams.creditVendor" (change)="creditProviderChanged()"
                            [ngClass]="{ 'is-invalid': creditVendor && creditVendor.touched && creditVendor.invalid }"
                            #creditVendor="ngModel" required>
                            <option value="">-- Select One --</option>
                            <optgroup *ngFor="let optionGrp of creditVendorList" label="{{optionGrp.grpName}}">
                                <option *ngFor="let itemOption of optionGrp.options" value="{{itemOption.value}}">
                                    {{itemOption.name}}</option>
                            </optgroup>
                        </select>
                        <div class="invalid-feedback"
                            *ngIf="creditVendor.touched && creditVendor.invalid && creditVendor.errors?.['required']">
                            Credit vendor is required.
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-6" *ngIf="creditVendorList.length && duParams.creditVendor">
                        <label class="mb-2 d-flex align-items-center" for="duUsername">
                            <b>Username</b>
                            <span *ngIf="selectedCreditProviderHasCreds" (click)="creditCredsOverrideClicked()"
                                class="text-primary text-decoration-underline cursor ms-3">
                                {{ !creditCredsOverrideEnabled ? 'Override Credentials' : 'Use Default' }}
                            </span>
                            <div *ngIf="creditCredsOverrideEnabled" class="form-check ms-2">
                                <input class="form-check-input" type="checkbox" name="overrideCreds" id="overrideCreds"
                                    [(ngModel)]="duParams.writeCreditCredsToUserProfile">
                                <label class="form-check-label" for="overrideCreds">
                                    Save to user profile
                                </label>
                            </div>
                        </label>
                        <input *ngIf="!creditCredsOverrideEnabled" type="password" class="form-control"
                            [value]="randomText" readonly />
                        <input *ngIf="creditCredsOverrideEnabled" type="text" class="form-control" name="duUsername"
                            [(ngModel)]="duParams.creditVendorUserName" autocomplete="new-username"
                            [ngClass]="{ 'is-invalid': duCreditVendorUserNameField && duCreditVendorUserNameField.touched && duCreditVendorUserNameField.invalid }"
                            #duCreditVendorUserNameField="ngModel" required>
                    </div>
                    <div class="col-sm-6 position-relative" *ngIf="creditVendorList.length && duParams.creditVendor">
                        <label class="mb-2 d-flex align-items-center" for="duPassword">
                            <b>Password</b>
                        </label>
                        <input *ngIf="!creditCredsOverrideEnabled" type="password" class="form-control"
                            [value]="randomText" readonly />
                        <input *ngIf="creditCredsOverrideEnabled" type="{{showPassword ? 'text' : 'password'}}"
                            class="form-control" name="duPassword" [(ngModel)]="duParams.creditVendorPassword" autocomplete="new-password"
                            [ngClass]="{ 'is-invalid': duCreditVendorPasswordField && duCreditVendorPasswordField.touched && duCreditVendorPasswordField.invalid }"
                            #duCreditVendorPasswordField="ngModel" required>
                        <i *ngIf="creditCredsOverrideEnabled" (click)="showPassword = !showPassword"
                            class="fa fa-eye toggle-password mt-2"></i>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12 mt-3" *ngFor="let creditRequest of duParams.creditRequests; let i = index">
                        <div class="border p-2 position-relative">
                            <span class="group-label panel-title">
                                {{ creditRequest.groupTitle }}
                            </span>
                            <div class="row pt-2">
                                <label for="borrower_{{i}}" class="mb-1">
                                    <b> {{ creditRequest.borrowerName }} </b>
                                </label>
                                <div class="row">
                                    <div class="col-sm-3">
                                        <select class="form-select" id="issueType_{{i}}" name="issueType_{{i}}"
                                            [(ngModel)]="creditRequest.issueType">
                                            <option value="New" *ngIf="!isTPOUser">New</option>
                                            <option value="Reissue">Reissue</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-9" *ngIf="creditRequest.issueType === 'Reissue'">
                                        <input class="form-control borrower__form-input" type="text"
                                            placeholder="Credit Reference" id="borrower_{{i}}"
                                            [(ngModel)]="creditRequest.creditReferenceNumber" name="borrower_{{i}}"
                                            [ngClass]="{ 'is-invalid': refNumber && refNumber.touched && refNumber.invalid }"
                                            autocomplete="do-not-autofill" #refNumber="ngModel" required>
                                        <div class="invalid-feedback"
                                            *ngIf="refNumber.touched && refNumber.invalid && refNumber.errors?.['required']">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title"> Desktop Underwriter </h4>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label class="mb-2">
                            <b> Submission Type </b>
                        </label>
                        <select class="form-select" [(ngModel)]="duParams.submissionType"
                            [ngModelOptions]="{ standalone: true }">
                            <option value="">-- Select One --</option>
                            <option value="New">New</option>
                            <option value="Resubmit">Resubmit</option>
                        </select>
                    </div>
                    <div class="col-md-6" *ngIf="duParams.submissionType === 'Resubmit'">
                        <label for="casefileId" class="mb-2">
                            <b> DU Casefile ID </b>
                        </label>
                        <input id="casefileId" class="form-control" type="text" placeholder="Du Casefile ID"
                            name="duCasefileId" [(ngModel)]="duParams.duCasefileId">
                    </div>
                </div>
                <div class="row" *ngIf="!isTPOUser">
                    <div class="col-md-5">
                        <label for="duOverrideUsername" class="mb-2 d-flex align-items-center">
                            <b> UserName </b>
                            <span (click)="duCredsOverrideClicked()"
                                class="text-primary text-decoration-underline cursor ms-3">
                                {{ !duCredsOverrideEnabled ? 'Override Credentials' : 'Use Default' }}</span>
                            <div *ngIf="duCredsOverrideEnabled" class="form-check ms-2">
                                <input class="form-check-input" type="checkbox" name="duOverrideCreds"
                                    id="duOverrideCreds" [(ngModel)]="duParams.writeAusCredsToUserProfile">
                                <label class="form-check-label" for="duOverrideCreds">
                                    Save to user profile
                                </label>
                            </div>
                        </label>
                        <input *ngIf="!duCredsOverrideEnabled" type="password" class="form-control" [value]="randomText"
                            readonly />
                        <input *ngIf="duCredsOverrideEnabled" class="form-control" type="text" name="duOverrideUsername"
                            [(ngModel)]="duParams.duUsernameOverride" autocomplete="new-username"
                            [ngClass]="{ 'is-invalid': duUsernameField && duUsernameField.touched && duUsernameField.invalid }"
                            #duUsernameField="ngModel" required>
                    </div>
                    <div class="col-md-5 position-relative">
                        <label class="mb-2">
                            <b> Password </b>
                        </label>
                        <input *ngIf="!duCredsOverrideEnabled" type="password" class="form-control" [value]="randomText"
                            readonly />
                        <input *ngIf="duCredsOverrideEnabled" type="{{showDuPassword ? 'text' : 'password'}}" autocomplete="new-password"
                            class="form-control" name="duOverridePassword" [(ngModel)]="duParams.duPasswordOverride"
                            [ngClass]="{ 'is-invalid': duPasswordField && duPasswordField.touched && duPasswordField.invalid }"
                            #duPasswordField="ngModel" required>
                        <i *ngIf="duCredsOverrideEnabled" (click)="showDuPassword = !showDuPassword"
                            class="fa fa-eye toggle-password mt-2"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            (click)="activeModal.dismiss()">Close</button>
        <button [disabled]="duParams.wasImported" class="btn btn-primary me-2" (click)="submitRequest()">
            Run DU
        </button>
        <button *ngIf="duParams.brokerLenderId" [disabled]="releasingCasefile" class="btn btn-secondary me-2"
            (click)="releaseCaseFile()">
            <span *ngIf="releasingCasefile" class="spinner-border spinner-border-sm me-2" role="status"
                aria-hidden="true"></span>
            Release Casefile
        </button>
        <button *ngIf="duParams.duCasefileId && duParams.duCasefileId !== originalCasefileId"
            [disabled]="isLoanReadOnly || linkingCasefile" class="btn btn-secondary me-2" (click)="linkCaseFile()">
            <span *ngIf="linkingCasefile" class="spinner-border spinner-border-sm me-2" role="status"
                aria-hidden="true"></span>
            {{ isLoanReadOnly ? 'Link Casefile (Readonly)' : 'Link Casefile' }}
        </button>
    </div>
