import { IDateRange } from "src/app/shared/components/date-range-filter/date-range-filter.component";

export class LeadFilters {
  dateRange: IDateRange;
  campaignId: number[] = null;
  branchId: number = null;
  showUserId: string = null;
  state: string[] = null;
  leadListId: number = null;
  leadStatusIds: number = null;
  showArchived: boolean = false;
  firstName: string = null;
  lastName: string = null;
}

export class LeadFiltersText {
  dateRange: string = null;
  campaignId: string = null;
  branchId: string = null;
  showUserId: string = null;
  state: string = null;
  leadListId: string = null;
}
