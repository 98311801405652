<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> Loan Activity </h4>
      </div>
      <div class="card-body">
        <loan-activity
          [appId]="appId"
          [leadId]="leadId">
        </loan-activity>
      </div>
    </div>
  </div>
</div>
