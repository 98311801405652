import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicingEditorComponent } from './invoicing-editor/invoicing-editor.component';
import { InvoicingEditorDrawerComponent } from './invoicing-editor-drawer/invoicing-editor-drawer.component';
import { InvoicingTableComponent } from './invoicing-table/invoicing-table.component';
import { InvoicingComponent } from './invoicing.component';
import { SharedModule } from '../../shared/shared.module';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [
    InvoicingComponent,
    InvoicingEditorComponent,
    InvoicingEditorDrawerComponent,
    InvoicingTableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
  ],
  exports: [
    InvoicingTableComponent,
    InvoicingComponent,
  ],
})
export class InvoicingModule { }
