import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { CountryEnumerationItem, EnumerationService } from 'src/app/services/enumeration-service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { Utils } from 'src/app/core/services/utils';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { NotificationService } from 'src/app/services/notification.service';
import { Address } from '../../../../models';
import { AgentsService } from 'src/app/modules/agents/services/agents.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'basic-information',
  templateUrl: 'basic-information.component.html',
  styleUrls: ['./basic-information.component.scss']
})

export class BasicInformationComponent implements OnInit {

  @Output()
  cancelClicked = new EventEmitter<any>();

  @Output()
  saveClicked = new EventEmitter<EditedExternalContact>();

  @Input()
  externalContact: ExternalContact;

  copyOfExternalContact: ExternalContact;

  protected states: EnumerationItem[] = [];
  protected maritalStatuses: EnumerationItem[] = [];
  protected suffixes: EnumerationItem[] = [];
  protected countries: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _notificationService: NotificationService,
    private readonly _agentsService: AgentsService,
    private readonly _spinner: NgxSpinnerService,
    ){
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.maritalStatuses = enums[Constants.enumerations.maritalStatuses];
      this.suffixes = enums[Constants.enumerations.suffix];
    });
    this.states = this._enumsService.states;
  }

  ngOnInit() {
    this.countries = this._enumsService.countries;
    this.copyOfExternalContact = _.cloneDeep(this.externalContact);
    if (!this.copyOfExternalContact.mailingCountry) {
      this.copyOfExternalContact.mailingCountry = null;
    }
    if (!this.copyOfExternalContact.companyCountry) {
      this.copyOfExternalContact.companyCountry = null;
    }
    this.getAgent();
  }

  onCancelClicked = () => {
    this.cancelClicked.emit();
  }

  onSaveClicked = (copyToAgent: boolean) => {
    delete this.copyOfExternalContact['expanded'];
    delete this.copyOfExternalContact['agentTypeName'];
    const editedExternalContact = new EditedExternalContact();
    editedExternalContact.externalContact = this.copyOfExternalContact;
    editedExternalContact.copyToAgent = copyToAgent;
    this.saveClicked.emit(editedExternalContact);
  }

  protected onCopyFromAgentClicked = () => {
    Object.entries(this.copyOfExternalContact.agent).forEach(([key, value]) => {
      if (Object.hasOwnProperty.call(this.copyOfExternalContact, key)) {
        this.copyOfExternalContact[key] = value;
      }
    });
    this._notificationService.showSuccess('The copying operation was successful.', 'Success!');
  }

  protected onCountryForAgentChanged = () => {
    const selectedCounty = this.copyOfExternalContact.mailingCountry;
    if (selectedCounty !== 'us') {
      this.copyOfExternalContact.mailingState = null;
      this.copyOfExternalContact.mailingZip = null;
      this.copyOfExternalContact.stateLicenseState = null;
    }
  }

  protected onCountryForCompanyChanged = () => {
    const selectedCounty = this.copyOfExternalContact.companyCountry;
    if (selectedCounty !== 'us') {
      this.copyOfExternalContact.companyState = null;
      this.copyOfExternalContact.companyZip = null;
      this.copyOfExternalContact.companyStateLicenseState = null;
    }
  }

  protected handleAddressChangeForAgent(e: Partial<Address>): void {
    const contact = this.copyOfExternalContact;
    contact.mailingStreet = ''; // to reset the last populated address.

    setTimeout(() => {
      contact.mailingStreet = e.address1;
      contact.mailingCity = e.city;
      contact.mailingState = e.state;
      contact.mailingZip = e.zipCode;
      contact.mailingCountry = 'us';
    }, 200);
  }

  protected handleAddressChangeForCompany(e: Partial<Address>): void {
    const contact = this.copyOfExternalContact;
    contact.companyAddress1 = ''; // to reset the last populated address.

    setTimeout(() => {
      contact.companyAddress1 = e.address1;
      contact.companyCity = e.city;
      contact.companyState = e.state;
      contact.companyZip = e.zipCode;
    }, 200);
  }

  protected onZipCodeRelatedInfoChangedForAgent = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.copyOfExternalContact.mailingState = zipCode.state.toLowerCase();
      this.copyOfExternalContact.mailingCity = Utils.toTitleCase(zipCode.city);
      this.copyOfExternalContact.mailingZip = zipCode.zipcode;
      this.copyOfExternalContact.mailingCountry = 'us';
    }
  }

  protected onZipCodeRelatedInfoChangedForCompany = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.copyOfExternalContact.companyState = zipCode.state.toLowerCase();
      this.copyOfExternalContact.companyCity = Utils.toTitleCase(zipCode.city);
      this.copyOfExternalContact.companyZip = zipCode.zipcode;
      this.copyOfExternalContact.companyCountry = 'us';
    }
  }

  protected emailFormatValidate = (email): boolean => {
    return !email ||
      (
        email
        &&
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
  }

  private getAgent = () => {
    if (this.copyOfExternalContact.agentId) {
      this._spinner.show();
      this._agentsService.getAgent(this.copyOfExternalContact.agentId).subscribe({
        next: (response) => {
          this.copyOfExternalContact.assistantName = response.agent.assistantName;
          this.copyOfExternalContact.assistantEmail = response.agent.assistantEmail;
          this.copyOfExternalContact.assistantPhone = response.agent.assistantPhone;

        }, error: (error) => {
          const fallbackMessage = 'An error occurred while retrieving Agent';
          const message = error?.message || fallbackMessage;
          console.error(fallbackMessage, error);

          this._notificationService.showError(message, 'Error');
        }
      }).add(() => this._spinner.hide());
    }
  }
}

export class EditedExternalContact {
  externalContact: ExternalContact;
  copyToAgent: boolean;
}
