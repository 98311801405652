import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExternalContactsService } from '../external-contacts/services/external-contacts.service';
import { InternalContactsService } from '../internal-contacts/services/internal-contacts.service';
import { AddAttachmentDialogComponent } from './components/add-attachment-dialog/add-attachment-dialog.component';
import { AddContactsToEmailDialogComponent } from './components/add-contacts-to-email-dialog/add-contacts-to-email-dialog.component';
import { SendEmailSmsDialogComponent } from './components/send-email-sms-dialog/send-email-sms-dialog.component';
import { SendEmailComponent } from './components/send-email/send-email.component';
import { SendSmsComponent } from './components/send-sms/send-sms.component';
import { CorrespondenceHistoryTableComponent } from './components/correspondence-history-table/correspondence-history-table.component';
import { CorrespondenceService } from './services/correspondence.service';
import { CorrespondenceRoutingModule } from './correspondence-routing.module';
import { TableModule } from 'primeng/table';
import { CorrespondenceHistoryComponent } from './components/correspondence-history/correspondence-history.component';
import { PreviewSentEmailDialogComponent } from './components/preview-sent-email-dialog/preview-sent-email-dialog.component';
import { MessageStatusComponent } from './components/message-status/message-status.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CorrespondenceDailyScheduleComponent } from './components/correspondence-daily-schedule/correspondence-daily-schedule.component';
import { CorrespondenceWeeklyScheduleComponent } from './components/correspondence-weekly-schedule/correspondence-weekly-schedule.component';

@NgModule({
    imports: [
        SharedModule,
        CorrespondenceRoutingModule,
        TableModule,
        NgSelectModule
    ],
    declarations: [
        SendEmailComponent,
        SendSmsComponent,
        SendEmailSmsDialogComponent,
        AddContactsToEmailDialogComponent,
        AddAttachmentDialogComponent,
        CorrespondenceHistoryComponent,
        PreviewSentEmailDialogComponent,
        CorrespondenceHistoryTableComponent,
        MessageStatusComponent,
        CorrespondenceDailyScheduleComponent,
        CorrespondenceWeeklyScheduleComponent
    ],
    exports: [
        SendSmsComponent,
        SendEmailComponent,
        SendEmailSmsDialogComponent,
        AddContactsToEmailDialogComponent,
        AddAttachmentDialogComponent,
        CorrespondenceHistoryTableComponent,
        CorrespondenceHistoryComponent,
        MessageStatusComponent,
        CorrespondenceDailyScheduleComponent,
        CorrespondenceWeeklyScheduleComponent
    ],
    providers: [
        InternalContactsService,
        ExternalContactsService,
        CorrespondenceService
    ]
})
export class CorrespondenceModule {
  constructor() { }
}
