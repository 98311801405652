import { Component, Injector, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ThirdPartyKeyValue } from 'src/app/models';
import { Configuration } from 'src/app/models/configuration.model';
import { ThirdPartyCredential } from 'src/app/models/third-party/third-party-creds.model';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { RockConnectionConfigEditorComponent } from '../rock-connection/rock-connection-config-editor.component';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransUnionConfigEditorComponent } from '../trans-union/trans-union-config-editor.component';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'cloud-platform-config-editor',
  templateUrl: './cloud-platform-config-editor.component.html',
  styleUrls: ['./cloud-platform-config-editor.component.scss'],
})
export class CloudPlatformConfigEditorComponent extends ApplicationContextBoundComponent {

  @ViewChild('rockConnectionComponent')
  rockConnectionComponent: RockConnectionConfigEditorComponent;

  @ViewChild('transUnionComponent')
  transUnionComponent: TransUnionConfigEditorComponent;

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _route: ActivatedRoute,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(injector);
  }

  @Input() rockConnection: ThirdPartyCredential;

  @Input() transUnionConfig: TransUnionConfiguration = null;

  @Input() scope: ScopeType;

  isSaving: boolean;

  ngOnInit(): void {
    if (!this.transUnionConfig) {
      this.transUnionConfig = new TransUnionConfiguration();
      this.transUnionConfig.transunionImportEnabled = new Configuration();
      this.transUnionConfig.transunionImportEnabled.key = "TransUnionEnabled";
      this.transUnionConfig.transunionImportEnabled.value = 1;
    }
    if (!this.rockConnection) {
      this.rockConnection = new ThirdPartyCredential();
      this.rockConnection.credentialType = "CloudPlatform";
      this.rockConnection.vendorName = "RockConnection";
    }
  }

  onSaveClicked = () => {
    if (this.rockConnectionComponent) {
      const valid = this.rockConnectionComponent.validate();
      if (!valid) {
        return;
      }
      this.prepareExpressionKeyValuePairsForSave();
    }
    if (this.transUnionComponent) {
      const valid = this.transUnionComponent.validate();
      if (!valid) {
        return;
      }
    }
    this.save();
  };

  private prepareExpressionKeyValuePairsForSave = () => {
    const expressions = this.rockConnectionComponent.expressions;
    const rockConnectionConfig: ThirdPartyCredential = this.rockConnectionComponent.rockConnection;

    let count = rockConnectionConfig.thirdPartyKeyValuePairs.length;
    for (let i = count - 1; i >= 0; i--) {
      const pair = rockConnectionConfig.thirdPartyKeyValuePairs[i];
      if (pair.key === 'Expression') {
        rockConnectionConfig.thirdPartyKeyValuePairs.splice(i, 1);
      }
    }

    expressions.forEach((expression) => {
      const value =
        (!expression.expressionType ? '' : expression.expressionType) +
        '|' +
        (!expression.expressionGroupId ? '' : expression.expressionGroupId) +
        '|' +
        (!expression.campaignName ? '' : expression.campaignName);
      const pair = new ThirdPartyKeyValue('Expression', value);
      rockConnectionConfig.thirdPartyKeyValuePairs.push(pair);
    });
  }

  private save = () => {
    this.isSaving = true;
    this._spinner.show();
    if (this.scope === 'User' || this.scope === 'TpoUser') {
      const userCompanyGuid =
        this._route.snapshot.params.id ||
        this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
      this.rockConnection.userId = userCompanyGuid;
    }
    this.rockConnection.companyId =
      this.applicationContext.userPermissions.companyId;

    let requests: any = [
      this._systemLevelService.saveCredential(this.rockConnection),
    ];
    if (this.scope === 'Company') {
      requests = [
        ...requests,
        this._systemLevelService.saveConfiguration(this.transUnionConfig.transunionImportEnabled),
        this._systemLevelService.saveConfiguration(this.transUnionConfig.transunionImportLeadCampaignId),
        this._systemLevelService.saveConfiguration(this.transUnionConfig.transunionImportLeadChannel),
        this._systemLevelService.saveConfiguration(this.transUnionConfig.transunionImportLeadLoanTypeId),
        this._systemLevelService.saveConfiguration(this.transUnionConfig.transunionImportLeadLoanPurposeId),
      ];
    }
    combineLatest(requests)
      .pipe(
        finalize(() => {
          this.isSaving = false;
          this._spinner.hide();
        })
      )
      .subscribe({
        next: (res: Array<any>) => {
          this._notificationService.showSuccess(
            `Settings saved successfully.`,
            'System Level'
          );
        },
        error: (error) => {
          const defaultErrorMessage = "An error occurred while saving system level configuration."
          const errorMessage = error ? (error.message || (error.error ? error.error.title : defaultErrorMessage)) : defaultErrorMessage;
          this._notificationService.showError(
            `${errorMessage}`,
            'System Level Error!'
          );
        },
      });
  };
}

export class TransUnionConfiguration {
  transunionImportEnabled: Configuration;
  transunionImportLeadLoanPurposeId: Configuration;
  transunionImportLeadLoanTypeId: Configuration;
  transunionImportLeadCampaignId: Configuration;
  transunionImportLeadChannel:	Configuration;
}
