import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { NotificationService } from 'src/app/services/notification.service';
import { AgentTypeEnum, AgentList } from '../../agents/models/agent-list.model';
import { AgentsService } from '../../agents/services/agents.service';

@Component({
  selector: 'agent-lists',
  templateUrl: './agent-lists.component.html',
  styleUrls: ['./agent-lists.component.scss']
})
export class AgentListsComponent implements OnInit {

  @Input()
  agent: Agent;

  @Input()
  agentType?: AgentTypeEnum;

  @Input()
  isAgentTypeRequired?: boolean;

  @Input()
  agentListIdsControl: UntypedFormControl

  agentLists: AgentList[] = [];
  allAgentsList: AgentList[];

  newListTag: string;

  multiSelectSettings: IDropdownSettings = {
    idField: 'agentListId',
    textField: 'name',
    allowSearchFilter: true,
    clearSearchFilter: true,
    noDataAvailablePlaceholderText: "No tags available",
    closeDropDownOnSelection: true,
    enableCheckAll: false
  }

  constructor(
    private readonly _agentsService: AgentsService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    this._agentsService.getLists().subscribe((response) => {
      this.allAgentsList = this.agentType ? response.filter(a => a.agentType?.toLowerCase() === this.agentType.toLowerCase()) : response;

      const controlInitValue = [];
      this.agent.agentListIds?.forEach(id => {
        const type = this.allAgentsList.find(a => a.agentListId === id);
        if (type) {
          controlInitValue.push({ agentListId: type.agentListId, name: type.name })
        }
      })
      this.agentListIdsControl.setValue([...controlInitValue]);

      this.generateSelectedAgentList();
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "Error fetching lists!", "Error");
    });
  }

  generateSelectedAgentList = () => {
    if (_.isEmpty(this.allAgentsList) || _.isEmpty(this.agent) || _.isNil(this.agent.agentListIds)) {
      return;
    }

    this.agent.agentListIds.forEach(id => {
      let matchedList = this.allAgentsList.find(list => list.agentListId == id);
      if (matchedList) {
        this.agentLists = [...this.agentLists, matchedList];
      }
    });
  }

  addNewTag = () => {

    if(this.checkIfListNameAlreadyExists(this.newListTag)){
      this._notifyService.showWarning("This tag has already exist in the list", "Warning");
      return
    }

    this._agentsService.postList(this.newListTag).subscribe((response) => {
      this.allAgentsList = [...this.allAgentsList, response];
      this.agentLists = [...this.agentListIdsControl.value, response];
      this.agent.agentListIds = [...this.agentLists.map(l => l.agentListId)];
      this.agentListIdsControl.setValue([...this.agentLists]);
      this.newListTag = null;
    }, (err) => {
      this._notifyService.showError(err.message || err, 'Error!');
    });

  }

  checkIfListNameAlreadyExists = (newTag: string) => {
    return _.some(this.allAgentsList, list => list.name.toLowerCase() === newTag.toLowerCase());
  }

}
