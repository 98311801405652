<div class="modal-header">
  <h5 class="modal-title">Address Detail View</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body h-100">
  <div class="w-100 h-100" id="map" >
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close me-2"></i>Cancel
  </button>
</div>

