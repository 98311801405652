import { Type } from "@angular/core";
import { GridsterItem } from "angular-gridster2";
import { WidgetConfigBase } from "../configuration/models/widget-config-base.model";

export class DashboardItemConfig {
  widgetConfig: WidgetConfig;
  gridConfig: GridsterItem;
}

export class DashboardItem {
  widget: IWidget;
  gridConfig: GridsterItem;
}

export enum WidgetType {
  KpiSingle = "Kpi-Single",
  KpiMulti = "Kpi-Multi",
  LenderSpread = "LenderSpread",
  PipelineBreakdown = "PipelineBreakdown",
  LeadPipelineBreakdown = "LeadPipelineBreakdown",
  ImportantDays = "ImportantDays",
  RecentActivity = "RecentActivity",
  RecentCommunication = "RecentCommunication",
  RecentLoan = "RecentLoan",
  TaskCounter = "TaskCounter",
  UpcomingEvents = "UpcomingEvents",
  LocksExpiring = "LocksExpiring",
  Placeholder = "Placeholder",
  StatsByPerson = "StatsByPerson",
  AvgDaysBetweenKpis = "AvgDaysBetweenKpis",
  ClassicDashboard = "ClassicDashboard",
  LeadCampaignPerformanceReport = "LeadCampaignPerformanceReport",
  PerformanceReport = "PerformanceReport",
  PullThrough = "PullThrough",
  TurnTime = "TurnTime",
  Goals = "Goals",
  KpiMultiWithRankUserDetails = "KpiMultiWithRankUserDetails",
  CommunicationsReport = "CommunicationsReport"
}

export class WidgetConfig {
  widgetType: WidgetType;
  config: any;
}

export interface IWidget {
  id: string;
  header: string;
  data: any;
  component: Type<any>;
  config: WidgetConfigBase | null;
}

