import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { PricingCustomFieldListOptionsComponent } from '../pricing-custom-field-list-options/pricing-custom-field-list-options.component';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'pricing-custom-fields-v2',
  templateUrl: './pricing-custom-fields-v2.component.html',
  styleUrls: ['./pricing-custom-fields-v2.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PricingCustomFieldsV2Component implements OnInit {
  @Input() customFields: CustomField[];
  @Input() dependsOnCustomFields: CustomField[] = [];
  
  yesNoOptions: EnumerationItem[] = [];

  @Output() customFieldNameChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService
  ) { }

  ngOnInit(): void {
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    if (this.customFields.every(customField => customField.sortOrder === 0 || !customField.sortOrder)) {
      this.customFields.forEach(customField => {
        customField.sortOrder = this.customFields.indexOf(customField);
      });
    }
    this.customFields = this.customFields.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  openListOptionsModal(customField: CustomField) {
    let modalRef = this._modalService.open(PricingCustomFieldListOptionsComponent, {
      ...Constants.modalOptions.large,
    });
    modalRef.componentInstance.customField = cloneDeep(customField);
    modalRef.result.then(
      (updatedCustomField: CustomField) => {
        customField.options = updatedCustomField.options
      },
      () => { }
    );
  }

  onCustomFieldNameChanged = () => {
    this.customFieldNameChanged.emit();
  }

  onDependsOnCustomFieldChange = (customField: CustomField) => {
    customField.dependsOnValues = [];
    customField.defaultValue  = null;
    customField.dependsOnCustomFieldName = customField['dependsOnCustomField'].name;
  }

  onDisplayModeChange = (customField: CustomField) => {
    if (customField.displayMode === 'AlwaysShow') {
      customField['dependsOnCustomField'] = null;
      customField.dependsOnValues = [];
      customField.dependsOnValue  = null;
    } else if (customField.displayMode === 'DependsOnAnyValue') {
      customField.dependsOnValues = [];
      customField.dependsOnValue  = null;
    }
  }

  onRowReorder(event){
    this.customFields.forEach(customField => {
      customField.sortOrder = this.customFields.indexOf(customField);
    });
  }

}
