<div class="d-flex justify-content-between bottom-header-breadcrumb">
  <ol class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last;" class="breadcrumb-item">
      <a *ngIf="!breadcrumb.isDisabled && breadcrumb.url" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active"
        [class.active]="i==last">
        {{breadcrumb.label}}
      </a>
      <span *ngIf="breadcrumb.isDisabled || !breadcrumb.url">
        {{breadcrumb.label}}
      </span>
    </li>
  </ol>
</div>
