<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title">Notes and Advisories </h5>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <table class="table table-striped table-bordered">
        <tbody>
            <tr *ngFor="let noteAndAdvisory of notesAndAdvisories">
                <td>
                  {{noteAndAdvisory}}
                </td>
            </tr>
        </tbody>
    </table>
    </div>
  </div>
 </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close </button>
  </div>
</div>
