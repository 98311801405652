import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskStatsGroup } from '../../models/task-stats-group.model';

@Component({
  selector: 'task-stats-card',
  templateUrl: 'task-stats-card.component.html',
  styleUrls: ['task-stats-card.component.scss']
})

export class TaskStatsCardComponent implements OnInit {

  @Input()
  set taskStatsGroup(taskStatGroup: TaskStatsGroup) {
    this._taskStatGroup = taskStatGroup;
    this.unselectAll();
  }

  get taskStatsGroup(): TaskStatsGroup {
    return this._taskStatGroup;
  }

  @Output()
  statSelected: EventEmitter<number> = new EventEmitter<number>();

  unselectAll = () => {
    if (!this._taskStatGroup) {
      return;
    }
    this._taskStatGroup.stats.forEach(stat => {
      this._statSelectionStatus.set(stat.id, false);
    });
  }

  containsStat = (statId: number): boolean => {
    if (!this._taskStatGroup) {
      return false;
    }
    return this._taskStatGroup.stats.filter(s => s.id == statId).length > 0;
  }

  private _statSelectionStatus: Map<number, boolean> = new Map<number, boolean>();

  private _taskStatGroup: TaskStatsGroup;

  constructor() { }

  ngOnInit() { }

  getStatSelectionStatus = (statId: number): boolean => {
    return this._statSelectionStatus.get(statId);
  }

  onStatSelected = (statId: number): void => {
    this._taskStatGroup.stats.forEach(stat => {
      this._statSelectionStatus.set(stat.id, statId === stat.id);
    });
    this.statSelected.emit(statId);
  }
}
