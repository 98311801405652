import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MessageDeliveryEnum } from 'src/app/models';
import { DayOfWeek } from 'src/app/modules/admin/lead-config/models/lead-route-day-schedule.model';
import { MessageAllowedSendRange, TimeZoneType } from '../../models/message-allowed-send-range.model';

enum TimeType {
  start = "start",
  end = "end",
}

@Component({
  selector: 'correspondence-daily-schedule',
  templateUrl: './correspondence-daily-schedule.component.html',
  styleUrls: ['./correspondence-daily-schedule.component.scss'],
  viewProviders: [formViewProvider],
})
export class CorrespondenceDailyScheduleComponent implements OnInit {

  @Input() delivery: MessageDeliveryEnum;
  @Input() dayOfWeek: DayOfWeek;
  @Input() timeZoneType: TimeZoneType;

  @Input()
  set range(value: MessageAllowedSendRange) {
    this._range = value;
    if (value) {
      this.onInitialize(value);
    }
  }

  get range(): MessageAllowedSendRange {
    return this._range;
  }

  @Output() rangeChanged = new EventEmitter<MessageAllowedSendRange>();

  startTimeStr: string; // HH:MM
  endTimeStr: string;

  private _range: MessageAllowedSendRange;

  constructor() { }

  ngOnInit(): void {
  }

  onInitialize = (range: MessageAllowedSendRange) => {
    this.startTimeStr = this.mergeTimeParts(range.startHour, range.startMinute);
    this.endTimeStr = this.mergeTimeParts(range.endHour, range.endMinute);
  }

  onFieldChanged = (fieldName: "startTimeStr" | "endTimeStr" | "enabledAllDay") => {
    if (fieldName === 'enabledAllDay') {
      this.startTimeStr = null;
      this.endTimeStr = null;
      this.range.startHour = null;
      this.range.startMinute = null;
      this.range.endHour = null;
      this.range.endMinute = null;
    } else if (fieldName.includes("Time")) {
      let type = fieldName.replace("TimeStr", "") as TimeType;
      this.onModelTimeChanged(this[fieldName], type);
    }
    this.rangeChanged.emit(this.range);
  }

  clearPickers = () => {
    this.startTimeStr = null;
    this.endTimeStr = null;

    this.range.startHour = null;
    this.range.startMinute = null;
    this.range.endHour = null;
    this.range.endMinute = null;
    this.range.enabledAllDay = null;
  }

  getTimeString = (timeStr: string) => {
    if (!timeStr) {
      return null;
    }

    const [hours, minutes] = timeStr.split(':');

    let resultStr = (parseInt(hours, 10) % 12).toString().padStart(2, "0") +
      ":" +
      (parseInt(minutes, 10)).toString().padStart(2, "0") +
      " " +
      this.getMeridian(timeStr);
    return resultStr;
  }

  private getMeridian = (timeStr: string): 'AM' | 'PM' => {
    const [hours, minutes] = timeStr.split(':');
    return parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
  }

  private mergeTimeParts = (hour: number, minute: number): string => {
    if (hour != undefined && minute != undefined) {
      return hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0');
    }
    else {
      return null;
    }
  }

  private onModelTimeChanged = (e: string, timeType: TimeType) => {
    let parts = e?.split(":");

    this.range[timeType + "Hour"] = e ? parseInt(parts[0], 10) : null;
    this.range[timeType + "Minute"] = e ? parseInt(parts[1], 10) : null;
  }

}
