import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: "security-code-dialog.component.html",
  styleUrls: ['./security-code-dialog.component.scss']
})
export class SecurityCodeDialog {

  @Output()
  confirmOperation: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('securityForm')
  securityForm: NgForm | undefined;

  securityCode: string;

  isSending: boolean;

  constructor(public activeModal: NgbActiveModal) { }

  onOperationConfirmed() {
    if (!this.securityForm) return;
    this.securityForm.form.markAllAsTouched();
    if (!this.securityForm.form.valid) return;

    this.confirmOperation.emit(this.securityCode);
  }
}
