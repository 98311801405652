import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ContactListType } from 'src/app/models';

@Injectable()
export class DrawerService {

  get openDrawer$(): Subject<any> {
    return this._openDrawer;
  }

  openDrawer(info: { contactListType: ContactListType, recordId: number }): void {
    this._openDrawer.next(info);
  }

  private _openDrawer = new Subject<any>();

  drawerObservable = new BehaviorSubject<DrawerAction>(new DrawerAction());

  private _largestSizeInStackOfOpenDrawers: DrawerSize | null = DrawerSize.Small;

  private _drawersByName: Map<string, Drawer> = new Map<string, Drawer>();
  private _openDrawersByName: Map<string, Drawer> = new Map<string, Drawer>();

  private _optionsForDrawerToBeOpened: DrawerOptions;

  constructor() { }

  getDrawer = (drawer: Drawer): Observable<DrawerAction> => {
    return this.drawerObservable.asObservable().pipe(tap(
      action => {
        if (!this._drawersByName.has(drawer.name)) {
          this._drawersByName.set(drawer.name, drawer);
        }
      }
    ),
      filter((action: DrawerAction) => action && action.name === drawer.name));
  }

  openDrawerCount = (): number => {
    return this._openDrawersByName.size;
  }

  show = (name: string, debounce: number = 10, title?: string, component?: DynamicComponentInfo<any>, options?: DrawerOptions, hasShrinkExpandButton?: boolean): Promise<boolean> => {
    const drawer = this._drawersByName.get(name);
    let requestedToOpenAlreadyOpenDrawer = false;
    const previousLargestSizeInStackOfOpenDrawers = this._largestSizeInStackOfOpenDrawers;
    this._optionsForDrawerToBeOpened = options ? options : drawer.options;
    if (drawer) {
      if (!this._openDrawersByName.has(name)) {
        this._openDrawersByName.set(name, drawer);
      } else {
        // The same drawer is already open and there was a request to open it again
        requestedToOpenAlreadyOpenDrawer = true;
      }
      this._largestSizeInStackOfOpenDrawers = this.calculateLargestSizeAmongOpenDrawers(this._optionsForDrawerToBeOpened);
    } else {
      return new Promise((resolve, _reject) => {
        resolve(true);
      });
    }
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        if (this._optionsForDrawerToBeOpened.containerWrapperId) {
          const wrapperElement = document.getElementById(this._optionsForDrawerToBeOpened.containerWrapperId);
          if (!wrapperElement) {
            return;
          }
          if (requestedToOpenAlreadyOpenDrawer) {
            const sizeDependentClassNameForContainer = this.getSizeDependentClassName(previousLargestSizeInStackOfOpenDrawers);
            wrapperElement.classList.remove(sizeDependentClassNameForContainer);
          }
          const sizeDependentClassNameForContainer = this.getSizeDependentClassName(this._largestSizeInStackOfOpenDrawers);
          if (!wrapperElement.classList.contains(sizeDependentClassNameForContainer)) {
            wrapperElement.classList.add(sizeDependentClassNameForContainer);
          }
          wrapperElement.classList.remove("rightnav-drawing-closed");
        }
        this.drawerObservable.next({ name: name, show: true, dynamicComponentInfo: component, title: title, options: this._optionsForDrawerToBeOpened, hasShrinkExpandButton: hasShrinkExpandButton });
        resolve(true);
      }, debounce);
    });
  }

  hideAll = () => {
    const names = Array.from(this._openDrawersByName.keys());
    names.forEach(name => {
      this.hide(name);
    });
    this._largestSizeInStackOfOpenDrawers = DrawerSize.Small;
  }

  hide = (name: string, debounce: number = 10): Promise<boolean> => {
    const drawer = this._openDrawersByName.get(name);
    if (drawer) {
      this._openDrawersByName.delete(name);
    } else {
      return new Promise((resolve, _reject) => {
        resolve(true);
      });
    }
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        this.drawerObservable.next({ name: name, show: false });
        const options = drawer.options || this._optionsForDrawerToBeOpened;
        if (options.containerWrapperId) {
          const wrapperElement = document.getElementById(options.containerWrapperId);
          if (!wrapperElement) {
            return;
          }
          const sizeDependentClassNameForContainer = this.getSizeDependentClassName(options.size);
          wrapperElement.classList.remove(sizeDependentClassNameForContainer);
          this._largestSizeInStackOfOpenDrawers = this.calculateLargestSizeAmongOpenDrawers();
          if (!this._largestSizeInStackOfOpenDrawers) {
            wrapperElement.classList.add("rightnav-drawing-closed");
          } else {
            const sizeDependentClassNameForContainer = this.getSizeDependentClassName(this._largestSizeInStackOfOpenDrawers);
            if (!wrapperElement.classList.contains(sizeDependentClassNameForContainer)) {
              wrapperElement.classList.add(sizeDependentClassNameForContainer);
            }
          }
        }
        resolve(true);
      }, debounce);
    });
  }

  private getSizeDependentClassName = (size: DrawerSize): string => {
    switch (size) {
      case DrawerSize.Small:
        return "rightnav-drawing-open-sm";
      case DrawerSize.Medium:
        return "rightnav-drawing-open-md";
      case DrawerSize.Large:
        return "rightnav-drawing-open-lg";
      case DrawerSize.XLarge:
        return "rightnav-drawing-open-xlg";
      case DrawerSize.XXLarge:
        return "rightnav-drawing-open-xxlg";
      case DrawerSize.XXXLarge:
        return "rightnav-drawing-open-xxxlg";
      case DrawerSize.XXXXLarge:
        return "rightnav-drawing-open-xxxxlg";
      default:
        return "rightnav-drawing-open-md";
    }
  }

  private calculateLargestSizeAmongOpenDrawers = (options?: DrawerOptions): DrawerSize | null => {
    const sizes = Array.from(this._openDrawersByName.values()).filter(d => d.options.containerWrapperId != null)
      .map(d => d.options ? d.options.size : DrawerSize.Small);
    if (options) {
      sizes.push(options.size);
    }
    if (!sizes.length) {
      return null;
    }
    return Math.max(...sizes);
  }
}

export class DrawerAction {
  name: string = "";
  title?: string = "";
  show: boolean | null = null;
  dynamicComponentInfo?: DynamicComponentInfo<any> | undefined = undefined;
  options?: DrawerOptions | undefined = undefined;
  hasShrinkExpandButton?: boolean = false;
}

export class Drawer {
  name: string;
  options: DrawerOptions;
  isOpened: boolean = false;

  constructor() {
    this.name = "",
      this.options = {
        containerWrapperId: null,
        size: DrawerSize.Medium
      }
  }
}

export class DrawerOptions {
  size: DrawerSize;
  containerWrapperId: string = null;
  width?: number = null;
}

export enum DrawerSize {
  Small = 1,
  Medium = 2,
  Large = 3,
  XLarge = 4,
  XXLarge = 5,
  XXXLarge = 6,
  XXXXLarge = 7,
  Custom = 8
}

export class DynamicComponentInfo<T> {
  componentType: Type<T>;
  data: any;

  parameters: Map<string, any> = new Map<string, any>();

  parameters2: any = {};
}
