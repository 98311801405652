<form class="form-horizontal" #upsertApplicationForm="ngForm" novalidate id="upsertApplicationForm" name="upsertApplicationForm">
<h5>Information</h5>
<hr>
<div class="row">
  <!-- <div class="col-md-6 mb-3">
    <label class="control-label" for="channel">Channel</label>
    <select class="form-select col-md-6" id="channel" name="channel" #channel="ngModel"
    (change)="changeSelectedChannel(application.channel)"
      [(ngModel)]="application.channel">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let channel of channels" [ngValue]="channel.value">
        {{channel.name}}
      </option>
    </select>
  </div> -->
  <!-- <div class="col-md-6 mb-3">
    <label class="control-label" for="externalCompany">External Company</label>
    <select class="form-select col-md-6" id="externalCompany" name="externalCompany" #externalCompany="ngModel"
      [(ngModel)]="application.externalCompanyId">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let company of externalCompanies" [ngValue]="company.externalCompanyId">
        {{company.name}}
      </option>
    </select>
  </div> -->
</div>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="loanpurpose">Internal Contact</label>
    <select class="form-select" [(ngModel)]="data.primaryRoleUserId" name="internalContact">
      <option value=""> -- Select One -- </option>
      <option *ngFor="let item of availableUsers" value="{{item.id}}" [selected]="item.id == data.primaryRoleUserId">
        {{item.lastName}}, {{item.firstName}} </option>
    </select>
  </div>
  <div class="col-md-6 mb-3">
    <label class="control-label" for="loanpurpose">Loan Purpose</label>
    <select class="form-select col-md-6" id="loanpurpose" name="loanpurpose" [(ngModel)]="application.loanPurposeId">
      <option [ngValue]="null">--Select One--</option>
      <option [ngValue]="option.value" *ngFor="let option of loanPurposeOptions">
        {{option.name}}</option>
    </select>
  </div>
</div>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="loantype">Loan Type</label>
    <select class="form-select col-md-6" id="loantype" name="loantype" [(ngModel)]="application.loanTypeId">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let option of loanTypeOptions" value="{{option.value}}">
        {{option.name}}</option>
    </select>
  </div>
</div>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="loantype">Loan Status</label>
    <input class="form-control" name="firstName" [(ngModel)]="fullBorrower.borrower.firstName">
  </div>
</div>

<h5>Borrower Information</h5>
<hr>
<div *ngIf="newAppWithoutExistingBorrower">
  <div class="row">
    <div class="col-md-6 mb-3">
      <label class="control-label" for="firstName">First Name</label>
      <input class="form-control" name="firstName" [(ngModel)]="fullBorrower.borrower.firstName">
    </div>
    <div class="col-md-6 mb-3">
      <label class="control-label" for="middleName">Middle Name</label>
      <input class="form-control" name="middleName" [(ngModel)]="fullBorrower.borrower.middleName">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label class="control-label" for="lastName">Last Name</label>
      <input class="form-control" name="lastName" [(ngModel)]="fullBorrower.borrower.lastName">
    </div>
    <div class="col-md-6 mb-3">
      <label class="control-label" for="email">Email</label>
      <input class="form-control" name="email" [(ngModel)]="fullBorrower.borrower.email">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label class="control-label" for="birthDate">Birth Date</label>
      <input class="form-control" name="birthDate" mask="99/99/9999" [(ngModel)]="fullBorrower.borrower.birthDate">
    </div>
    <div class="col-md-6 mb-3">
      <label class="control-label" for="homePhone">Home Phone</label>
      <input class="form-control" name="homePhone" mask="(999) 999-9999" [(ngModel)]="fullBorrower.borrower.homePhone">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label class="control-label" for="mobilePhone">Mobile Phone</label>
      <input class="form-control" name="mobilePhone" mask="(999) 999-9999"
        [(ngModel)]="fullBorrower.borrower.mobilePhone">
    </div>
    <div class="col-md-6 mb-3">
      <label class="control-label" for="mobilePhone">Work Phone</label>
      <input class="form-control" name="mobilePhone" mask="(999) 999-9999"
        [(ngModel)]="fullBorrower.borrower.mobilePhone">
    </div>
  </div>
</div>
<div *ngIf="!newAppWithoutExistingBorrower">
  <h5>Co-borrower's Address Information</h5>
  <hr>
  <button type="button" class="btn btn-primary me-1" (click)="useBorrowerAddress();">Copy from Borrower's Address</button>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label class="control-label" for="mailingStreet">Address</label>
      <input class="form-control" name="mailingStreet" required [(ngModel)]="fullBorrower.borrower.mailingStreet">
    </div>
    <div class="col-md-6 mb-3">
      <label class="control-label" for="mailingZip">ZIP Code</label>
      <input class="form-control" name="mailingZip" required [(ngModel)]="fullBorrower.borrower.mailingZip">
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label class="control-label" for="mailingCity">City</label>
      <input class="form-control" name="mailingCity" required [(ngModel)]="fullBorrower.borrower.mailingCity">
    </div>
    <div class="col-md-6 mb-3">
      <label class="control-label" for="mailingCity">State</label>
      <select class="form-select" id="stateField" name="stateField" [name]="'stateField'" #stateField="ngModel"
        [(ngModel)]="fullBorrower.borrower.mailingState">
        <option [ngValue]="null">--Select One--</option>
        <option *ngFor="let option of stateOptions" value="{{option.value}}">
          {{option.name}}</option>
      </select>
    </div>
  </div>
</div>
<h5>Address Information</h5>
<hr>
<button type="button" class="btn btn-primary me-1" (click)="useBorrowerAddress();">Copy from Borrower's Address</button>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingStreet">Address</label>
    <input class="form-control" name="mailingStreet" required [(ngModel)]="fullBorrower.borrower.mailingStreet">
  </div>
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingZip">ZIP Code</label>
    <input class="form-control" name="mailingZip" required [(ngModel)]="fullBorrower.borrower.mailingZip">
  </div>
</div>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingCity">City</label>
    <input class="form-control" name="mailingCity" required [(ngModel)]="fullBorrower.borrower.mailingCity">
  </div>
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingCity">State</label>
    <select class="form-select" id="stateField" name="stateField" [name]="'stateField'" #stateField="ngModel"
      [(ngModel)]="fullBorrower.borrower.mailingState">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let option of stateOptions" value="{{option.value}}">
        {{option.name}}</option>
    </select>
  </div>
</div>
<p><strong>Address Information</strong></p>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingStreet">Address</label>
    <input class="form-control" name="mailingStreet" required [(ngModel)]="fullBorrower.borrower.mailingStreet">
  </div>
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingZip">ZIP Code</label>
    <input class="form-control" name="mailingZip" required [(ngModel)]="fullBorrower.borrower.mailingZip">
  </div>
</div>
<div class="row">
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingCity">City</label>
    <input class="form-control" name="mailingCity" required [(ngModel)]="fullBorrower.borrower.mailingCity">
  </div>
  <div class="col-md-6 mb-3">
    <label class="control-label" for="mailingCity">State</label>
    <select class="form-select" id="stateField" name="stateField" [name]="'stateField'" #stateField="ngModel"
      [(ngModel)]="fullBorrower.borrower.mailingState">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let option of stateOptions" value="{{option.value}}">
        {{option.name}}</option>
    </select>
  </div>
</div>
</form>
