import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ReferralSource } from "src/app/models/referral-source.model";
import { ISaveAgentEvent } from "src/app/modules/agent-info/agent-info.component";
import { AgentsService } from "src/app/modules/agents/services/agents.service";
import { AgentFull } from "src/app/modules/app-details/models/agent.model";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: 'upsert-referral-source',
  templateUrl: './upsert-referral-source.component.html',
  styleUrls: ['./upsert-referral-source.component.scss']
})
export class UpsertReferralSourceComponent {
  @Input()
  agentFull: AgentFull;

  @Input()
  agents: ReferralSource[] = [];

  @Input()
  title: string = "Add Referral Source";

  @Input()
  agentType?: string;

  @Input()
  isAgentTypeRequired?: boolean;

  @Input()
  isDrawer?: boolean;

  @Output()
  onSave: EventEmitter<{ agent: AgentFull, closeAfterSave: boolean }> = new EventEmitter<{ agent: AgentFull, closeAfterSave: boolean }>();

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  lastSavedAgent: AgentFull;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _agentsService: AgentsService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifyService: NotificationService) {
  }

  savedAgent = (data: ISaveAgentEvent) => {
    this._spinnerService.show();

    this._agentsService.saveAgent(data.agent, data.suppressInvite).subscribe((response) => {
      this.lastSavedAgent = response;
      const index = this.agents.findIndex(a => a.agentId === response.agent.agentId);
      if (index > -1) {
        this.agents[index] = response.agent;
      } else {
        this.agents.push(response.agent);
      }

      this._spinnerService.hide();
      this._notifyService.showSuccess("Agent saved successfully.", "Success!");

      if (this.isDrawer) {
        this.onSave.emit({ agent: response, closeAfterSave: data.isClosedAfterSave });
      } else {
        if (data.isClosedAfterSave) {
          this.activeModal.close(response);
        }
      }
    }, (err) => {
      this._spinnerService.hide();
      const errorMessage = err ? err.message : "An error occurred while saving agent."
      this._notifyService.showError(errorMessage, 'Error!');
    });
  }

  closedDialog = () => {
    if (this.isDrawer) {
      this.onClose.emit(true);
    } else {
      if (this.lastSavedAgent) {
        this.activeModal.close(this.lastSavedAgent);
      } else {
        this.activeModal.dismiss();
      }
    }
  }
}
