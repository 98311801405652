<form class="form-horizontal" #configForm="ngForm" novalidate id="configForm" *ngIf="rockConnection">
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Partner Vendor ID</label>
    </div>
    <div class="col-md-4">
      <input type="text" name="partner-vendor-id" class="form-control" [(ngModel)]="rockConnection.userName">
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 pt-1">
      <h5>Expressions</h5>
    </div>
    <div class="col-md-10 text-end">
      <button class="btn btn-outline-primary" (click)="addExpression()">
        Add Expression
      </button>
    </div>
    <hr class="dashed">
  </div>
  <div class="row mt-3" *ngIf="fieldsForExpressionEditor">
    <div class="col-md-4" *ngFor="let expression of expressions; let index = index">
      <div class="card card-hover-style">
        <div class="card-body">
          <span class="close hover-close-style" (click)="onExpressionDeleteClicked(expression)"><i id="close-icon"
              class="fas fa-times-circle"></i></span>
          <cloud-platform-expression-editor
            #expressionEditor
            [contactListFields]="fieldsForExpressionEditor"
            [globalConfig]="globalConfig"
            [expression]="expression"
            [userPermissions]="userPermissions">
          </cloud-platform-expression-editor>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 pt-1">
      <h5>Additional Configs</h5>
    </div>
    <div class="col-md-10 text-end">
      <button class="btn btn-outline-primary" (click)="addAdditionalConfig()">
        Add Additional Config
      </button>
    </div>
    <hr class="dashed">

  </div>

  <div class="row mt-3 mb-3" *ngFor="let additionalKeyValuePair of otherKeyValuePairs; let index = index">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-1 text-end">
          <label class="custom-control-label">Key</label>
        </div>
        <div class="col-md-4">
          <input type="text" required name="key_{{index}}" class="form-control" #configKey="ngModel"
            [ngClass]="{'is-invalid' : configKey && configKey.touched && configKey.invalid}"
            [(ngModel)]="additionalKeyValuePair.key">
            <div class="invalid-feedback">Please enter a key.
            </div>
        </div>
        <div class="col-md-1 text-end">
          <label class="custom-control-label">Value</label>
        </div>
        <div class="col-md-4 d-flex justify-content-between">
          <div class="flex-grow-1">
            <input type="text" required name="value_{{index}}" class="form-control" #configValue="ngModel"
            [ngClass]="{'is-invalid' : configValue && configValue.touched && configValue.invalid}"
            [(ngModel)]="additionalKeyValuePair.value"/>
          <div class="invalid-feedback">Please enter a value.
          </div>
          </div>
          <button class="btn btn-link" (click)="onAdditionalConfigDeleteClicked(additionalKeyValuePair)">
            <i class="fa fa-trash-alt me-0 text-danger"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
