import { Component, EventEmitter, Input, Output } from '@angular/core';
import { startCase, toLower } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map, switchMap } from 'rxjs';
import { AddressLookupResult } from 'src/app/models/address-lookup-result.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import {
  AgentCompany,
  AgentFull,
} from 'src/app/modules/app-details/models/agent.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { AgentsService } from '../../services/agents.service';

@Component({
  selector: 'agent-company-info',
  templateUrl: 'agent-company-info.component.html',
  styleUrls: ['./agent-company-info.component.scss'],
})
export class AgentCompanyInfoComponent {
  @Input()
  set agentFull(agentFull: AgentFull) {
    this._agentFull = agentFull || new AgentFull();
    if (!this._agentFull.agent.agentCompany) {
      this._agentFull.agent.agentCompany = new AgentCompany();
    }
  }

  get agentFull(): AgentFull {
    return this._agentFull;
  }

  @Output()
  agentCompanySaved = new EventEmitter<AgentCompany>();

  states: EnumerationItem[] = [];

  private _agentFull: AgentFull;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _agentsService: AgentsService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) {
    this.states = this._enumsService.states || [];
  }

  onAddressChanged(address: AddressLookupResult) {
    this.agentFull.agent.agentCompany.companyAddress1 = address.address;
    this.agentFull.agent.agentCompany.companyCity = address.city;
    this.agentFull.agent.agentCompany.companyState = address.state;
    this.agentFull.agent.agentCompany.companyZip = address.zipCode;
  }

  onZipCodeRelatedAddressChanged(zipCode: ZipCodeLookupResult) {
    if (!zipCode) return;

    this.agentFull.agent.agentCompany.companyState = zipCode.state.toLowerCase();
    this.agentFull.agent.agentCompany.companyCity = startCase(toLower(zipCode.city));
    this.agentFull.agent.agentCompany.companyZip = zipCode.zipcode;
  }

  saveAgentCompany() {
    this._spinner.show();

    if (
      !this.agentFull.agent.agentCompanyId ||
      !this.agentFull.agent.agentCompany.agentCompanyId
    ) {
      this._agentsService
        .saveAgentCompany(this.agentFull.agent.agentCompany)
        .pipe(
          switchMap((agentCompany) =>
            this._agentsService.associateAgentToAgentCompant(
              this.agentFull.agent.agentId,
              agentCompany.agentCompanyId
            ).pipe(map(() => agentCompany))
          ),
          catchError((error) => {
            throw error;
          })
        )
        .subscribe({
          next: (agentCompany) => {
            this.agentCompanySaved.emit(agentCompany);
            this._notifyService.showSuccess(
              'Agent company data saved successfully',
              'Success'
            );
          },
          error: (error) => {
            this._notifyService.showError(
              error?.message || 'An error occurred while saving agent company',
              'Error'
            );
          },
        })
        .add(() => this._spinner.hide());
    } else {
      this._agentsService
        .saveAgentCompany(this.agentFull.agent.agentCompany)
        .subscribe({
          next: (agentCompany) => {
            this.agentCompanySaved.emit(agentCompany);
            this._notifyService.showSuccess(
              'Agent company data saved successfully',
              'Success'
            );
          },
          error: (error) => {
            this._notifyService.showError(
              error?.message || 'An error occurred while saving agent company',
              'Error'
            );
          },
        })
        .add(() => this._spinner.hide());
    }
  }
}
