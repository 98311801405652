import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDataConfig } from '../../../../../models';
import { EnumItem } from '../../../../../models/enum-item.model';
import { Lookup } from '../../../../app-details/models/lookup.model';
import { AppDetailsService } from '../../../../app-details/services/app-details.service';

@Component({
  selector: 'custom-data-items-modal',
  templateUrl: './custom-data-items-modal.component.html',
})
export class CustomDataItemsModalComponent implements OnInit {

  customDataConfigModel: CustomDataConfig;
  multiLookupType: string;
  number: number;
  listTypes: EnumItem[];

  lookupType: string;
  loading: boolean;
  lookupItems: Lookup[];

  constructor(
    private readonly _appService: AppDetailsService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.getLookupItems();
  }

  getLookupItems(): void {
    this.lookupItems = [];
    if (!this.customDataConfigModel[`multiLookupType${this.number}`]) {
      return;
    }
    this.loading = true;
    const request = this.customDataConfigModel[`multiLookupType${this.number}`] === 'Custom' ?
      this._appService.getCustomLookUps(this.number) :
      this._appService.getLookUps(this.customDataConfigModel[`multiLookupType${this.number}`]);

    request.subscribe((response) => {
      this.lookupItems = response || [];

      if (this.customDataConfigModel[`multiLookupType${this.number}`] === 'Custom' && this.customDataConfigModel[`customDropDownOptions${this.number}`] && !this.lookupItems.length) {
        this.lookupItems = JSON.parse(this.customDataConfigModel[`customDropDownOptions${this.number}`]);
      }

      this.loading = false;
    });
  }

}
