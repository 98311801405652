import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { formViewProvider } from "src/app/core/services/form-view.provider";
import { Lender } from "src/app/models/config/lender.model";
import { NotificationService } from "src/app/services/notification.service";
import { LenderConfigService } from "../../services/lender-config.service";
import { InvestorProductList } from "src/app/modules/pricing/models/pricing/pricing-investor-product.model";
import { Product } from "src/app/modules/pricing/models/pricing/product-search-result.model";

@Component({
  selector: 'app-lender-details-dialog',
  templateUrl: './lender-details-dialog.component.html',
  styleUrls: ['./lender-details-dialog.component.scss'],
  viewProviders: [formViewProvider],
})
export class LenderDetailsDialogComponent implements OnInit {

  @ViewChild("lenderForm") lenderForm: NgForm | undefined;

  get lender(): Lender {
    return this._lender;
  }

  @Input()
  set lender(value: Lender) {
    this._lender = value;
    if (value) {
      this.lenderData = _.cloneDeep(value);
    }
  }

  @Input() pricingVendors: { name: string, value: string, products: InvestorProductList[], flatProducts: Product[] }[] = [];

  isSaving: boolean = false;
  lenderData: Lender = new Lender();
  ldeEnabled: boolean = false;

  private _lender: Lender;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _lenderService: LenderConfigService,
    private readonly _notifyService: NotificationService
  ) {
  }

  ngOnInit(): void {

  }

  save = () => {

    if (this.lenderForm) {

      this.lenderForm.form.markAllAsTouched();

      if (this.lenderForm.form.valid) {
        this.isSaving = true;

        if (this.lenderData.lenderId) { // isUpdate
          this._lenderService.updateLender(this.lenderData)
            .subscribe(() => {
              this.isSaving = false;
              this._notifyService.showSuccess("Lender updated Successfully", "Success");
              this.activeModal.close();
            }, (err) => {
              this.isSaving = false;
              this._notifyService.showError(err?.message || err.error.message, "Error");
            })
        }
        else {
          this._lenderService.insertLender(this.lenderData)
            .subscribe(() => {
              this.isSaving = false;
              this._notifyService.showSuccess("Lender inserted Successfully", "Success");
              this.activeModal.close();
            }, (err) => {
              this.isSaving = false;
              this._notifyService.showError(err?.message || err.error.message, "Error");
            })
        }
      }
    }
  }
}
