import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeApplicationComparisonComponent } from '../fee-application-comparison/fee-application-comparison.component';

@Component({
  selector: 'fee-application-comparison-dialog',
  templateUrl: 'fee-application-comparison-dialog.component.html'
})
export class FeeApplicationComparisonDialogComponent implements OnInit {

  @ViewChild("feeComparator")
  feeComparator: FeeApplicationComparisonComponent;

  @Input()
  existingFees: LoanFee[] = [];

  @Input()
  newFees: LoanFee[] = [];

  @Input()
  newFeesSectionHeader: string;

  @Input()
  hasCloseButton: boolean = true;

  @Input()
  title: string = "Apply Fees to Loan";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onApplyFeesClicked = () => {
    this.activeModal.close(this.feeComparator.feesToApply);
  }
}


