<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit License Validation Vendor {{(formCredential.vendorName) ? ' : ' +
    formCredential.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create License Validation Vendor {{(formCredential.vendorName) ? ' : '
    +
    formCredential.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <section>
    <form #ngForm='ngForm'>
      <!-- URL -->
      <div *ngLet='id("url") as controlId' class='form-group'>
        <label [for]='controlId' class='custom-control-label'>
          URL
        </label>

        <input [(ngModel)]='formCredential.url' [id]='controlId' class='form-control' name='url' type='url'>
      </div>

      <!-- Client ID -->
      <div *ngLet='id("client-id") as controlId' class='form-group'>
        <label [for]='controlId' class='custom-control-label'>
          Client ID
        </label>

        <input [(ngModel)]='formCredential.userName' [id]='controlId' class='form-control' name='clientId' type='text'>
      </div>

      <!-- Client Secret -->
      <div *ngLet='id("client-secret") as controlId' class='form-group'>
        <label [for]='controlId' class='custom-control-label'>
          Client Secret
        </label>

        <password-input [(ngModel)]='formCredential.password' [id]='controlId' name='clientSecret'></password-input>
      </div>

      <!-- Auth URL -->
      <div *ngLet='id("auth-url") as controlId' class='form-group'>
        <label [for]='controlId' class='custom-control-label'>
          Auth URL
        </label>

        <input [(ngModel)]='authUrlItem.value' [id]='controlId' class='form-control' name='authUrl' type='url'>
      </div>

      <!-- Auth Scope -->
      <div *ngLet='id("auth-scope") as controlId' class='form-group'>
        <label [for]='controlId' class='custom-control-label'>
          Auth Scope
        </label>

        <input [(ngModel)]='authScopeItem.value' [id]='controlId' class='form-control' name='authScope' type='text'>
      </div>

      <!-- Validate Endpoint Path -->
      <div *ngLet='id("validate-endpoint-path") as controlId' class='form-group'>
        <label [for]='controlId' class='custom-control-label'>
          Validate Endpoint Path
        </label>

        <input [(ngModel)]='validateEndpointPathItem.value' [id]='controlId' class='form-control'
          name='validateEndpointPath' type='text'>
      </div>
    </form>
  </section>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <!-- Save Button -->
  <button (click)='save()' [disabled]='isSaving' class='btn btn-primary btn-save' type='submit'>
    <ng-container *ngIf='!isSaving; else saving'>
      <i class='fa fa-save'></i>
      Save
    </ng-container>

    <ng-template #saving>
      <span *ngIf="isSaving">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Saving
      </span>
    </ng-template>
  </button>
</div>
