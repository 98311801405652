import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MeridianDisclosureWizardComponent } from './components/meridian-disclosure-wizard/meridian-disclosure-wizard.component';
import { TableModule } from 'primeng/table';
import { MeridianDisclosurePreviewPdfComponent } from './components/meridian-disclosure-preview-pdf/meridian-disclosure-preview-pdf.component';
import { MeridianDisclosureInfoComponent } from './components/meridian-disclosure-info/meridian-disclosure-info.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';

@NgModule({
  declarations: [
    MeridianDisclosureWizardComponent,
    MeridianDisclosurePreviewPdfComponent,
    MeridianDisclosureInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
  ],
  exports: [
    MeridianDisclosureWizardComponent
  ]
})
export class MeridianDisclosureWizardModule { }
