import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanFeeHistory } from 'src/app/models/loan/loan-fee-history.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'app-loan-fee-histories-dialog',
  templateUrl: './loan-fee-histories-dialog.component.html',
  styleUrls: ['./loan-fee-histories-dialog.component.scss']
})
export class LoanFeeHistoriesDialogComponent implements OnInit {

  @Input() histories: LoanFeeHistory[];

  feeTypes: EnumerationItem[] = [];

  constructor(public activeModal: NgbActiveModal, private _enumsService: EnumerationService) { }

  ngOnInit(): void {
    this._enumsService.getFeeEnumerations().subscribe(enums => {
      this.feeTypes = enums["FeeType"] || [];
    });
  }

  getEnumName = (enumValue: string): string => {
    return enumValue ? this.feeTypes.find(t => t.value == enumValue)?.name : null;
  }

}
