import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmsConfigurationComponent } from './components/sms-configuration.component';

const routes: Routes = [
  {path : '', component : SmsConfigurationComponent},
  
];
  
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmsConfigRoutingModule { }