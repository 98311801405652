import { Component, Injector, OnInit } from '@angular/core';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { PricingService } from '../../services/pricing.service';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { NotificationService } from 'src/app/services/notification.service';
import { Observer, Subscription } from 'rxjs';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { PricingUtils } from '../../pricing-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingScenarioChangesDialogComponent } from '../pricing-scenario-changes-dialog/pricing-scenario-changes-dialog.component';
import { Constants } from 'src/app/services/constants';
import { PricingRateCreditApplicationMethod } from '../../models/pricing/rate.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScenarioComparisonSelectDialogComponent } from '../scenario-comparison-select-dialog/scenario-comparison-select-dialog.component';
import { ScenarioComparisonWorksheetDialogComponent } from '../scenario-comparison-worksheet-dialog/scenario-comparison-worksheet-dialog.component';
import { ApplicationContext, LoanApplication } from 'src/app/models';
import { LosService } from 'src/app/services/los.service';

@Component({
  selector: 'application-pricing-scenarios',
  templateUrl: 'application-pricing-scenarios.component.html',
  styleUrls: ['./application-pricing-scenarios.component.scss']
})

export class ApplicationPricingScenariosComponent extends ApplicationContextBoundComponent implements OnInit {

  application: LoanApplication;
  pricingScenarios: PricingScenario[] = [];
  isLoading: boolean = false;

  protected isLoanReadOnly: boolean = false;
  protected isTpoUser: boolean = false;
  protected isPRMG: boolean = false;

  private _mortgage: UrlaMortgage;

  private _loanInfoChangesSubscription: Subscription;

  constructor(private injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _losService: LosService) {
    super(injector);
    this.applicationContextService.context.subscribe(context => {
      this.initializeFromContext(context);
    });
  }

  ngOnInit() {
    this.applicationContextService.loanInfoChanges.subscribe(appContext => {
      if (appContext.application) {
        this.initializeFromContext(appContext);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onLoanComparisonClicked = () => {
    const modalRef = this._modalService.open(ScenarioComparisonSelectDialogComponent, { ...Constants.modalOptions.large, scrollable: false });
    modalRef.componentInstance.scenarios = this.pricingScenarios;
    modalRef.result.then((selections: PricingScenario[]) => {
      const modalRef = this._modalService.open(ScenarioComparisonWorksheetDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.scenarios = selections;
      modalRef.componentInstance.appId = this.application.applicationId;
      modalRef.result.then(() => {
      }, err => { });
    },
      () => { }
    );
  }

  onApplyScenarioToLoanClicked = (scenario: PricingScenario) => {
    this._pricingService.getMortgagePricingRequest(this._mortgage.mortgageId).subscribe(productSearchRequest => {

      if(this.isTpoUser && this.isPRMG && this.application?.channel == "NonDelegatedCorrespondent"){
        if (!this.application.losIdentifier) {
          const observer: Observer<any> = {
            next: (value: any) => {
              this.applyScenarioToLoan(scenario, PricingRateCreditApplicationMethod.DoNotApplyCredit);
            },
            error: (err: any) => {
              this._notifyService.showError(err.error || "An error occurred while creating LOS loan.", "Error!");
            },
            complete: () => {
            }
          }
          this._spinnerService.show();
          this._losService.autoCreateLosLoan(this.application.applicationId).subscribe(observer).add(() => this._spinnerService.hide());
        } else {
          this.applyScenarioToLoan(scenario, PricingRateCreditApplicationMethod.DoNotApplyCredit);
        }

        return;
      }

      const changes = PricingUtils.findMortgageModelChanges(scenario.pricingRequestPayload, productSearchRequest.request);

      const modalRef = this._modalService.open(
        PricingScenarioChangesDialogComponent,
        Constants.modalOptions.large
      );
      modalRef.componentInstance.changes = changes;
      modalRef.componentInstance.hasLenderCredit = scenario.adjustedPrice > 100;
      modalRef.result.then(
        (selectedCreditMethod: PricingRateCreditApplicationMethod) => {
          if (this.applicationContext.isTpo && !this.application.losIdentifier) {
            const observer: Observer<any> = {
              next: (value: any) => {
                this.applyScenarioToLoan(scenario, selectedCreditMethod);
              },
              error: (err: any) => {
                this._notifyService.showError(err.error || "An error occurred while creating LOS loan.", "Error!");
              },
              complete: () => {
              }
            }
            this._spinnerService.show();
            this._losService.autoCreateLosLoan(this.application.applicationId).subscribe(observer).add(() => this._spinnerService.hide());
          } else {
            this.applyScenarioToLoan(scenario, selectedCreditMethod);
          }
        },
        () => { }
      );
    })
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.isLoanReadOnly = context.applicationIsReadOnly;
    this.application = context.application;
    this._mortgage = context.currentMortgage;
    this.isTpoUser = context.isTpo;
    this.isPRMG = context.isCompanyPRMG;
    this.getPricingScenarios();
  }

  private applyScenarioToLoan = (scenario: PricingScenario, selectedCreditMethod: PricingRateCreditApplicationMethod) => {
    this._spinnerService.show();
    this._pricingService.applyScenarioToLoan(this.application.applicationId, scenario.pricingScenarioId,
      selectedCreditMethod || PricingRateCreditApplicationMethod.DoNotApplyCredit)
      .subscribe({
        next: (result) => {
          this.applicationContextService.reloadApplicationAndMortgagePostAction(this.application.applicationId).subscribe(() => {
            const path = this.applicationContext.isTpo ? "tpo" : "admin";
            this._navigationService.navigateToPath(`/${path}/app-details/${this.application.applicationId}/pricing`);
          });
        }, error: err => {
          this._notifyService.showError(err.error || "An error occurred while applying scenario to loan.", "Error!");
        }
      }).add(() => this._spinnerService.hide());
  }

  private getPricingScenarios = () => {
    this.isLoading = true;
    this._pricingService.getPricingScenarios(this.application.applicationId)
      .subscribe({
        next: (response) => {
          this.pricingScenarios = response;
          this.isLoading = false;
        },
        error: (err) => {
          this._notificationService.showError(err?.error.message || 'Unable to load pricing scenarios', 'Error!');
        }
      })
  }
}
