import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ScopeType } from 'src/app/modules/system-level/components/integrations/integrations.component';
import { Constants } from 'src/app/services/constants';

@Component({
	selector: 'ext-auth-callback',
	templateUrl: 'ext-auth-callback.component.html'
})
export class ExternalAuthCallbackComponent implements OnInit {

	private _activatedRouteQueryParamsSubscription: Subscription;
	private _activatedRouteParamsSubscription: Subscription;

	private _token: string;

	private _queryParams: string = "";

	private _returnUrl: string = "";

	constructor(private readonly _activatedRoute: ActivatedRoute
	) { }

	ngOnInit() {
		this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
			this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
        let scope = "Admin";
				Object.keys(queryParams).forEach(key => {
          if (key === 'token') {
            this._token = queryParams[key];
          } else if (key === 'r_url') {
            this._returnUrl = queryParams[key];
          } else if (key === 'scope') {
            scope = queryParams[key];
          } else {
            this._queryParams += `${key}=${queryParams[key]}&`;
          }
				});
				this.persistAuthData();
				let urlToRedirectTo = this._returnUrl;
        if (!urlToRedirectTo) {
          if (scope === 'TPO') {
            urlToRedirectTo = '/tpo/dashboard';
          } else {
            urlToRedirectTo = '/admin/dashboardv2';
          }
        }

				setTimeout(() => {
					const urlToGoTo = `${window.location.protocol}//${window.location.host}${urlToRedirectTo}?${this._queryParams}`;
					window.location.href = urlToGoTo;
				}, 2000);
			});
		});
	}

	ngOnDestroy(): void {
		this._activatedRouteQueryParamsSubscription?.unsubscribe();
		this._activatedRouteParamsSubscription?.unsubscribe();
	}

	private persistAuthData = () => {
		let jwtPayload = Utils.parseJwt(this._token);

		localStorage.setItem(Constants.authorization.authorizationDataKey, JSON.stringify({
			token: this._token,
			userName: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
			userId: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
			scope: 'Borrower',
			companyGuid: jwtPayload.CompanyGuid,
			expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
		}));
	}
}
