import { Pipe } from "@angular/core";

@Pipe({name: 'splitCamelcase'})
export class SplitCamelCasePipe {

  constructor(){}

  transform(value: any, convertToUpper?: any): any {
    if (!value) return "";
    return value.split(/(?=[A-Z])/).map((s: string) => convertToUpper ? s.toUpperCase() : s).join(' ');
  }
}
