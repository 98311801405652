<div class="account-body accountbg">
  <div class="container">
    <div class="row vh-100 d-flex justify-content-center align-self-center">
      <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-10 mx-auto align-self-center">
        <div class="card">
          <div class="card-body p-0 login-header">
            <div class="text-center p-3">
              <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
              <h4 class="mb-1" style="color: steelblue; margin-top: 0;">{{userActive ? "Link Account" : "Registration"}}
              </h4>
              <p class="text-muted  mb-0">Complete your {{userActive ? "link by logging in to your existing account" :
                "registration by setting up your password below" }}.
              </p>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="ab-content">
              <div class="px-3 pt-2" *ngIf="errorMessage">
                <div class="alert alert-danger border-0 mb-0" role="alert">
                  {{errorMessage}}
                </div>
              </div>

              <div class="px-3 pt-2" *ngIf="isInvite">
                <div class="alert alert-{{companyExist}} border-0 mb-0 alert-dismissible" role="alert">
                  <i class="{{companyExistMessageIcon}}"></i> <strong> {{companyExistMessage}}</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
              </div>

              <div class="px-3 pt-2" *ngIf="done">
                <div class="alert alert-success border-0 mb-0" role="alert">
                  <strong>
                    <div>Success!</div>
                  </strong>
                  <div><i class="fa txt-color-green fa-check"></i> {{ !userActive ? 'Thank you for your registration!' : 'Thank you for linking your account!'}}</div>
                  <div [hidden]="registerToCompany"> <i class="fa txt-color-green fa-check"></i> Please confirm account
                    in your email</div>
                </div>
              </div>

              <div class="p-3">
                <form class="form-horizontal auth-form" [formGroup]="registerForm" autocomplete="off"
                  name="registerForm">

                  <ng-container *ngIf="!twoFactorCodeRequired">
                    <div class="form-group mb-2">
                      <label class="form-label" for="email">Email Address</label>
                      <div class="input-group">
                        <input type="email" autocomplete="off" trim class="form-control" name="email"
                          formControlName="email" placeholder="Enter email address"
                          [ngClass]="{'is-invalid': email && email.touched && email.invalid}"
                          [(ngModel)]="registrationInfo.email" email readonly required>
                        <div class="invalid-feedback">Please enter your email address.</div>
                      </div>
                    </div>

                    <ng-container *ngIf="!errorCheckingRegistrationLink">
                      <div class="form-group mb-2">
                        <label class="form-label" for="password">Password</label>
                        <div class="input-group">
                          <input [readonly]="errorCheckingRegistrationLink" type="password" autocomplete="off"
                            class="form-control" name="password" formControlName="password" placeholder="Enter password"
                            [(ngModel)]="registrationInfo.password"
                            [ngClass]="{'is-invalid': password && password.touched && password.invalid}" requiresDigit
                            requiresNonAplhaNumeric required minlength="8" [caseSize]="'both'">
                          <div *ngIf="password?.errors?.['required']" class="invalid-feedback">Please enter your password.
                          </div>
                          <div *ngIf="password?.errors?.['minlength']" class="invalid-feedback">Password must be at least
                            8 characters long.<br /></div>
                          <div *ngIf="password?.errors?.['uppercase']" class="invalid-feedback">Password must contain an
                            uppercase character.<br /></div>
                          <div *ngIf="password?.errors?.['lowercase']" class="invalid-feedback">Password must contain a
                            lowercase character.<br /></div>
                          <div *ngIf="password?.errors?.['requiresDigit']" class="invalid-feedback">Password must contain
                            at least one digit.<br /></div>
                          <div *ngIf="password?.errors?.['requiresNonAplhaNumeric']" class="invalid-feedback">Password
                            must contain non-alphanumeric character.<br /></div>
                        </div>
                      </div>

                      <div class="form-group mb-2" *ngIf="!userActive">
                        <label class="form-label" for="confirmPassword">Confirm Password</label>
                        <div class="input-group">
                          <input [readonly]="errorCheckingRegistrationLink" type="password" autocomplete="off"
                            class="form-control" name="confirmPassword" formControlName="confirmPassword"
                            placeholder="Enter confirm password"
                            [ngClass]="{'is-invalid' : confirmPassword && (confirmPassword.touched && confirmPassword.invalid)
                              || registerForm?.hasError('stringDoesntMatch')}"
                            [(ngModel)]="registrationInfo.confirmPassword" required>
                          <div *ngIf="confirmPassword?.errors?.['required']" class="invalid-feedback">Please enter confirm
                            password.</div>
                          <div *ngIf="registerForm?.hasError('stringDoesntMatch')" class="invalid-feedback">Confirm
                            password doesn't match password.<br /></div>
                        </div>
                      </div>

                      <div class="form-group row my-3">
                        <div class="input-group justify-content-center">
                          <input type="checkbox" [readonly]="errorCheckingRegistrationLink" class="custom-control-input"
                            name="termsAndConditions" formControlName="termsAndConditions"
                            [ngClass]="{'is-invalid' : termsAndConditions && termsAndConditions.touched && !termsAndConditions.value}"
                            [(ngModel)]="termsAndConditionsConfirmation" required>
                          <label style="padding-left: 4px;" class="form-label text-muted m-0"
                            for="termsAndConditionsConfirmation">
                            I agree with the <a href="javascript:void(0);" (click)="termsAndConditionsModal()">Terms and
                              Conditions</a>
                          </label>
                          <div class="invalid-feedback text-center">You must agree with Terms and Conditions.</div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="twoFactorCodeRequired">
                    <div class="center mt-4">
                      <h4 class="card-title mb-2">We need to confirm it's really you</h4>
                      <p class="text-muted mb-2">Please, enter the code we sent you.</p>
                      <p class="text-muted mb-2">
                        Need a new code? <a *ngIf="!loading"
                          (click)="onSendCodeClicked()"> Resend Code </a></p>

                      <div class="mt-2">
                        <input type="text" autocomplete="off" class="form-control" name="twoFactorCode"
                          formControlName="twoFactorCode"
                          id="twoFactorCode" [disabled]="loading" placeholder="Enter Code"
                          [(ngModel)]="loginInfo.twoFactorCode" required
                          [ngClass]="{'is-invalid': twoFactorCode && twoFactorCode.touched && twoFactorCode.invalid}"
                          (keyup.enter)="onRegisterClicked()"
                          autofocus="autofocus"
                          #twoFactorCodeInput>
                        <div *ngIf="twoFactorCode?.errors?.['required']" class="invalid-feedback">Incorrect code. Please re-enter the code we sent you.</div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="mt-3">
                    <button *ngIf="!loading" class="btn btn-primary waves-effect waves-light w-100" type="button"
                      [disabled]="errorCheckingRegistrationLink || !termsAndConditionsConfirmation"
                      (click)="onRegisterClicked()">{{ registrationButtonLabel }}
                    </button>

                    <button class="btn btn-primary waves-effect waves-light w-100" type="button" disabled=""
                      *ngIf="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Please wait...
                    </button>
                  </div>

                  <div class="mt-3" *ngIf="alreadyRegistered">
                    <button *ngIf="!loading" class="btn btn-primary waves-effect waves-light w-100" type="button"
                      (click)="onGotoClicked()">Go to Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
