<p-steps [model]="steps" [(activeIndex)]="activeStepIndex"></p-steps>
<form class="form-horizontal auth-form ernstForm" #ernstForm="ngForm" novalidate id="ernstForm" name="ernstForm">
  <ng-container *ngIf="activeStepIndex === 0">
    <div class="d-flex justify-content-center" *ngIf="selectedVendorName === 'Lodestar' && loadingTitleAgents">
      <div class="d-flex">
        <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
        <h5 class="font-18 text-muted me-4">Loading Title Agents, please wait...</h5>
      </div>
    </div>
    <div class="row" *ngIf="selectedVendorName === 'Lodestar' && titleAgents && titleAgents.length">
      <div class="col-md-6 form-group">
        <label for="selectTitleAgent">Title Agent</label>
        <select class="form-select" id="selectTitleAgent" name="selectTitleAgent"
                [(ngModel)]="thirdPartyFeeOptions.options.titleFeesRequestOptions.titleAgentId"
                (change)="onTitleAgentChanged()"
                [ngClass]="{ 'is-invalid' : selectTitleAgent && selectTitleAgent.touched && selectTitleAgent.invalid }"
                #selectTitleAgent="ngModel" required>
          <option value="">-- Select One --</option>
          <option *ngFor="let item of titleAgents" [ngValue]="item.thirdPartyServiceProviderId">
            {{ item.companyName }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required.</div>
      </div>
      <div class="col-md-6" *ngIf="titleAgent && titleAgent.thirdPartyServiceProviderId">
        <div>
          <span>
            <label>Address: </label>
          </span>
          <span>
            {{titleAgent.address1}}, {{titleAgent.city}}, {{titleAgent.state}}, {{titleAgent.zipCode}}
          </span>
        </div>
        <div>
          <span>
            <label>Email: </label>
          </span>
          <span>
            {{titleAgent.email || '-'}}
          </span>
        </div>
        <div>
          <span>
            <label>Phone Number: </label>
          </span>
          <span>
            {{titleAgent.phone | phone}}
          </span>
        </div>
        <div>
          <span>
            <label>Website: </label>
          </span>
          <span>
            {{titleAgent.website || '-'}}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activeStepIndex === 1">
    <div class="row">
      <div class="col-md-6 pt-2">
        <div class="form-check form-check-inline">
          <input id="requestPropertyTax" name="requestPropertyTax" class="form-check-input" type="checkbox" [(ngModel)]="thirdPartyFeeOptions.options.requestPropertyTax">
          <label class="form-check-label" for="requestPropertyTax">Request Property Tax</label>
        </div>
      </div>
      <div class="col-md-6 pt-2">
        <div class="form-check form-check-inline">
          <input id="requestAppraisal" name="requestAppraisal" class="form-check-input" type="checkbox" [(ngModel)]="thirdPartyFeeOptions.options.requestAppraisal">
          <label class="form-check-label" for="requestAppraisal">Request Appraisal</label>
        </div>
      </div>
    </div>
    <h5 class="modal-title mt-3">Page 1 - Questions Regarding Recording</h5>
    <br>
    <div>
      <h4 class="card-title"> Number of pages in Deed? </h4>
      <input type="number" class="form-control" id="numberOfPagesInDeed" name="numberOfPagesInDeed"
             [ngClass]="{ 'is-invalid' : numberOfPagesInDeed && numberOfPagesInDeed.touched && numberOfPagesInDeed.invalid }"
             [(ngModel)]="thirdPartyFeeOptions.options.recordingFeesRequestOptions.deed.pages"
             #numberOfPagesInDeed="ngModel" required />
      <div class="invalid-feedback">Please answer the question</div>
    </div>
    <div class="mt-3">
      <h4 class="card-title"> Number of pages in Mortgage? </h4>
      <input type="number" class="form-control" id="numberOfPagesInMortgage" name="numberOfPagesInMortgage"
             [ngClass]="{ 'is-invalid' : numberOfPagesInMortgage && numberOfPagesInMortgage.touched && numberOfPagesInMortgage.invalid }"
             [(ngModel)]="thirdPartyFeeOptions.options.recordingFeesRequestOptions.mortgageDeedOfTrust.pages"
             #numberOfPagesInMortgage="ngModel" required />
      <div class="invalid-feedback">Please answer the question</div>
    </div>
    <div class="mt-3" *ngFor="let question of thirdPartyFeeOptions.options.recordingFeesRequestOptions.questions; let index = index;">
      <h4 class="card-title">{{question.description}}</h4>
      <div *ngIf="question.dataType === 'Boolean'">
        <div class="form-group mb-3 row ms-0">
          <div class="radio form-check-inline col-sm-2 text-start align-self-center">
            <input type="radio" id="yes_{{index}}" value="true" name="yes_{{index}}" [(ngModel)]="question.answer">
            <label for="yes_{{index}}" class="form-label align-self-center mb-lg-0 text-end">Yes</label>
          </div>
          <div class="radio form-check-inline col-sm-2 text-start align-self-center">
            <input type="radio" id="no_{{index}}" value="false" name="no_{{index}}" [(ngModel)]="question.answer">
            <label for="no_{{index}}" class="form-label align-self-center mb-lg-0 text-end">No</label>
          </div>
        </div>
      </div>
      <div *ngIf="question.dataType === 'Integer'">
        <input type="number" class="form-control" id="intQuestion_{{index}}" name="intQuestion_{{index}}"
               [ngClass]="{ 'is-invalid' : intQuestion && intQuestion.touched && intQuestion.invalid }"
               [(ngModel)]="question.answer" #intQuestion="ngModel" required />
        <div class="invalid-feedback">Please enter a value</div>
      </div>
      <div *ngIf="question.dataType === 'Decimal'">
        <input type="number" class="form-control numeric-without-arrows" id="decimalQuestion_{{index}}" name="decimalQuestion_{{index}}"
          numeric [decimals]="3"
          [ngClass]="{ 'is-invalid' : decimalQuestion && decimalQuestion.touched && decimalQuestion.invalid }"
          [(ngModel)]="question.answer" #decimalQuestion="ngModel" required />
        <div class="invalid-feedback">Please enter a value</div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activeStepIndex === 2">
    <h5 class="modal-title">Page 2 - Questions Regarding Inspections</h5>
    <br>
    <div>
      <h4 class="card-title"> Lot size? </h4>
      <input type="number" class="form-control" id="lotSize" name="lotSize"
             [ngClass]="{ 'is-invalid' : lotSize && lotSize.touched && lotSize.invalid }"
             [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.property.lotSize" #lotSize="ngModel"
             required />
      <div class="invalid-feedback">Please answer the question</div>
    </div>
    <div class="mt-3">
      <h4 class="card-title"> Do you require any of these inspections? </h4>
      <div>
        <div class="row">
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="pest" name="pest"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.pest">
            <label style="padding-left: 4px;" class="form-label" for="pest"> Pest </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="radon" name="radon"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.radon">
            <label style="padding-left: 4px;" class="form-label" for="radon"> Radon </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="roof" name="roof"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.roof">
            <label style="padding-left: 4px;" class="form-label" for="roof"> Roof </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="septic" name="septic"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.septic">
            <label style="padding-left: 4px;" class="form-label" for="septic"> Septic </label>
          </div>
        </div>
        <div class="row">
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="smoke" name="smoke"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.smoke">
            <label style="padding-left: 4px;" class="form-label" for="smoke"> Smoke </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="standard" name="standard"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.standard">
            <label style="padding-left: 4px;" class="form-label" for="standard"> Standard </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="structural" name="structural"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.structural">
            <label style="padding-left: 4px;" class="form-label" for="structural"> Structural </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="survey" name="survey"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.survey">
            <label style="padding-left: 4px;" class="form-label" for="survey"> Survey </label>
          </div>
        </div>
        <div class="row">
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="water" name="water"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.water">
            <label style="padding-left: 4px;" class="form-label" for="water"> Water </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="well" name="well"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.well">
            <label style="padding-left: 4px;" class="form-label" for="well"> Well </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="asbestos" name="asbestos"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.asbestosInspectionRequestOptions.request">
            <label style="padding-left: 4px;" class="form-label" for="asbestos"> Asbestos </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="drywall" name="drywall"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.drywallInspectionRequestOptions.request">
            <label style="padding-left: 4px;" class="form-label" for="drywall"> Drywall </label>
          </div>
        </div>
        <div class="row">
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="lead" name="lead"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.leadInspectionRequestOptions.request">
            <label style="padding-left: 4px;" class="form-label" for="lead"> Lead </label>
          </div>
          <div class="custom-control custom-switch switch-success col-3">
            <input type="checkbox" class="custom-control-input" id="mold" name="mold"
                   [(ngModel)]="thirdPartyFeeOptions.options.inspectionFeesRequestOptions.moldInspectionRequestOptions.request">
            <label style="padding-left: 4px;" class="form-label" for="mold"> Mold </label>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template>
    <loading-indicator [loadingMessage]="'Requesting fees, please wait...'"></loading-indicator>
  </ng-template>
</form>
<div class="d-flex justify-content-center mb-2">
  <button type="button" class="btn btn-outline-secondary me-1" *ngIf="(selectedVendorName === 'Lodestar' && activeStepIndex > 0) || (selectedVendorName === 'Ernst' && activeStepIndex > 1)" (click)="previousPage()">
    < Previous Page
  </button>
  <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="activeStepIndex >= 0 && activeStepIndex < 2" (click)="nextPage()" [disabled]="loadingTitleAgents">
    Next Page >
  </button>
  <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="activeStepIndex === 2" (click)="requestFees()">Request Fees</button>
</div>
