import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs/internal/observable/of';
import { LoanDoc } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { TitleDocumentTypeEnum } from 'src/app/modules/app-details/components/title-history/models/title-order.model';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-title-order-documents-dialog',
  templateUrl: './title-order-documents-dialog.component.html',
  styleUrls: ['./title-order-documents-dialog.component.scss']
})
export class TitleOrderDocumentsDialogComponent implements OnInit {

  @ViewChild('titleOrderDocForm') titleOrderDocForm: NgForm | undefined;

  @Input() titleOrderId: number;
  @Input() documentTypes: EnumerationItem[];
  @Input() task: LoanDocDashboardTask;

  documentType: TitleDocumentTypeEnum = null;

  docFile: DocFile = null;
  uploadedNewFiles: File[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _loanDocsService: LoanDocsService
  ) { }

  ngOnInit(): void {
  }

  onDocFilesChanged = (event: any) => {
    if (event.target?.files.length) {
      const file = event.target.files[0] as File;
      let docFile = new DocFile();
      docFile.fileName = file.name;
      docFile.file = file;
      docFile.applicationId = this.task.applicationId;
      docFile.guid = null;
      docFile.active = true;
      this.docFile = docFile;
    }
  }

  onFileUploadChanged = (uploadedFiles: File[]) => {
    if (uploadedFiles.length > 0) {
      const file = uploadedFiles[0];
      let docFile = new DocFile();
      docFile.fileName = file.name;
      docFile.file = file;
      docFile.applicationId = this.task.applicationId;
      docFile.guid = null;
      docFile.active = true;
      this.docFile = docFile;
    }
    else {
      this.docFile = null;
    }
  }

  removeDocFile = () => {
    this.docFile = null;
    if (this.uploadedNewFiles.length) {
      this.uploadedNewFiles = [];
    }
  }

  onSaveClicked = () => {
    if (this.titleOrderDocForm) {
      this.titleOrderDocForm.form.markAllAsTouched();
      if (this.titleOrderDocForm.form.valid) {
        this.saveLoanDoc();
      }
    }
  }

  private saveLoanDoc = () => {
    let loanDocObservable;
    let isTaskRefreshRequired: boolean = false;

    if (this.task.loanDocId) {
      loanDocObservable = of({ loanDocId: this.task.loanDocId } as LoanDoc);
    }
    else {
      const newLoanDoc = new LoanDoc(this.task.applicationId);
      loanDocObservable = this._loanDocsService.upsertLoanDoc(newLoanDoc);
      isTaskRefreshRequired = true;
    }

    this._spinner.show();

    loanDocObservable.subscribe({
      next: (result: LoanDoc) => {

        this._loanDocsService.uploadFileForLoanDoc(this.docFile.file, this.docFile, result.loanDocId, false, false, false).subscribe({
          next: (fileDto) => {
            this._spinner.hide();
            this.activeModal.close({ refreshRequired: isTaskRefreshRequired, docType: this.documentType, fileDto: fileDto, loanDocId: result.loanDocId });
          }, error: (err) => {
            this._spinner.hide();
            this._notifyService.showError(err.error || 'Failed to save the order doc!', 'Failure');
          }
        })
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || "Failed to save the doc file!", "Failure");
      }
    })

  }
}
