import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { set } from 'lodash';
import { Message, TaskMessage } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { LoanDocTaskUpsert } from 'src/app/models/borrower/loan-doc-task-upsert.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { UserWithRoleName } from 'src/app/modules/loan-activity/models/user-with-role-name.model';
import { LoanService } from 'src/app/services/loan';
import { MentionsService } from 'src/app/services/mentions.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { FileAttachment } from 'src/app/shared/components/send-mms/send-mms.component';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { TaskImageProcessStatus } from '../task-editor/task-editor.component';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { MessageEditorWithMentionsComponent } from 'src/app/shared/components/message-editor-with-mentions/message-editor-with-mentions.component';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';

export enum TaskNoteTypeEnum {
  InternalMessage = "InternalMessage",
  BorrowerMessage = "BorrowerMessage"
}

@Component({
  selector: 'task-notes',
  templateUrl: './task-notes.component.html',
  styleUrls: ['./task-notes.component.scss']
})
export class TaskNotesComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('messageEditor')
  messageEditor: MessageEditorWithMentionsComponent;

  @Input()
  task: LoanDocDashboardTask;

  @Input()
  isAddingNewNoteActive: boolean = false;

  @Input()
  isEditorDisabled: boolean = false;

  @Input()
  mentionables: Mentionable[] = [];

  @Input()
  refreshMentions: boolean = false;

  @Input()
  taskNoteType: TaskNoteTypeEnum;

  @Input()
  noteFieldName: string;

  @Input()
  actionButtonsEnabled: boolean = true;

  @Output()
  afterSendNote: EventEmitter<Message> = new EventEmitter<Message>();

  notes: TaskMessage[] = [];

  noteAttachments: Array<FileAttachment> = [];

  fakeData: string = "";
  accept: string = "image/*";
  isSendingNoteMessage: boolean = false;
  isLoadingNotes: boolean = false;

  internalNoteImageProcessStatuses: Map<string, TaskImageProcessStatus> = new Map<string, TaskImageProcessStatus>();

  users: Array<UserWithRoleName>;
  userId: string;
  borrowers: Array<BorrowerDto>;

  constructor(
    private readonly injector: Injector,
    private readonly _taskService: TaskService,
    private readonly _messageService: MessageService,
    private readonly _notifyService: NotificationService,
    private readonly _mentionsService: MentionsService,
    private readonly _utilityService: SharedUtilitiesService,
    private readonly _loanService: LoanService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.userId = this.applicationContext.userPermissions.userId;

    this.loadNotes();

    if(this.task.applicationId){
      this._loanService.getBorrowers(this.task.applicationId).subscribe({
        next: (borrowers) => {
          this.borrowers = borrowers;
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldn't load application borrowers.", "Error!");
        }
      });
    }

  }

  onMessageChanged = (message: string) => {
    set(this.task, this.noteFieldName, message);
  }

  deleteNoteAttachment = (attachment) => {
    let index = this.noteAttachments.indexOf(attachment);
    this.noteAttachments.splice(index, 1);
    this.fakeData = "";
  }

  handleFileUpload = (event) => {
    event.target.files.forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        let data = new FileAttachment();
        data.file = file;
        data.base64 = event.target.result;
        this.noteAttachments.push(data);
      };
    });
  }

  getUserFullName = (userId: string) => {
    let allUsers = this.users.concat(this.applicationContext.globalConfig.tpoUsers);
    let userFullName = 'Unknown';
    let userFound = allUsers.find(user => user.userCompanyGuid === userId);
    if (userFound) {
      userFullName = userFound.firstName + ' ' + userFound.lastName;
    } else {
      if (this.borrowers) {
        let borrowerFound = this.borrowers.find(borrower => borrower.userId === userId);
        if (borrowerFound) {
          userFullName = borrowerFound.firstName + ' ' + borrowerFound.lastName;
        }
      }
    }
    return userFullName;
  }

  submitNewMessage = () => {

    if (this.task[this.noteFieldName]?.trim() === '') {
      return;
    }

    let mediaFiles = [];
    if (this.noteAttachments) {
      this.noteAttachments.forEach(attachment => {
        mediaFiles.push({
          fileName: attachment.file.name,
          data: attachment.base64.toString().split(',')[1],
          mimeType: attachment.file.type
        });
      });
    }

    this.isSendingNoteMessage = true;

    let upsertData = {
      loanDocTask: this.task as unknown as LoanDocTask
    } as LoanDocTaskUpsert

    this._taskService.upsertLoanDocTask(upsertData).subscribe((messageModel) => {
      this.isSendingNoteMessage = false;

      this.messageEditor.reset();
      this.afterSendNote.emit();

      this.loadNotes();

    }, (err) => {
      this.isSendingNoteMessage = false;
      this._notifyService.showError(err ? (err.error ? err.error.message : '') : '', 'Error');
    });


  }

  private loadNotes = () => {
    if (!this.task.loanDocTaskId) {
      return;
    }

    let observable;
    if (this.taskNoteType == TaskNoteTypeEnum.InternalMessage) {
      observable = this._messageService.getInternalTaskMessages(this.task.loanDocTaskId);
    }
    else if (this.taskNoteType == TaskNoteTypeEnum.BorrowerMessage) {
      observable = this._messageService.getBorrowerTaskMessage(this.task.loanDocTaskId);
    }

    if (observable) {
      observable.subscribe((notesArr) => {
        this.isLoadingNotes = false;
        this.notes = notesArr;

        const originalInternalNotes = _.cloneDeep(this.notes);

        for (let i = 0; i <= this.notes.length - 1; i++) {
          const note = this.notes[i];
          note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
          note['uniqueId'] = uuidv4();
          originalInternalNotes[i]['uniqueId'] = note['uniqueId'];
          const internalNotesImgCount = this.replaceImagesWithLoaderIcons(note);
          const taskImageProcessingStatus: TaskImageProcessStatus = { numberOfImagesLeftToProcess: internalNotesImgCount, processedContent: note.content }
          this.internalNoteImageProcessStatuses.set(note['uniqueId'], taskImageProcessingStatus);
        }

        originalInternalNotes.forEach(note => {
          note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
          this.generateDisplayHtmlWithImages(note.content, note);
        });

        // refresh mentions from alerts
        if (this.refreshMentions) {
          this._mentionsService.publish({ type: "reload" });
        }

      }, (err) => {
        this.isLoadingNotes = false;
        this._notifyService.showError(err ? (err.error ? err.error.message : '') : '', 'Error');
      });
    }

  }

  private replaceImagesWithLoaderIcons = (note): number => {

    const imageTags = note.content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);

    if (imageTags && imageTags.length) {
      imageTags.forEach(img => {
        note.content = note.content.replace(
          img,
          `<i class="fas fa-sync-alt fa-spin mx-1 mt-1" style="font-size: 1rem; margin-top: 4px; margin-bottom: 4px;"></i>`
        );
      });
      return imageTags.length;
    }
    return 0;
  }

  private generateDisplayHtmlWithImages = (displayText: string, note) => {

    const regexp = new RegExp(
      /<img class="task-note-image" src="(([\w]|[^a-zA-Z0-9\">])+)/g
    );
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      note.content = displayText;
      return displayText;
    }

    this._utilityService.getImageFileContentAsBase64(results[1], (response) => {
      results[1] = response.changingThisBreaksApplicationSecurity;

      const displayHtml = displayText.replace(
        results[0],
        `<img class="note-image" src="${results[1]}`
      );

      let imageProcessingStatus = this.internalNoteImageProcessStatuses.get(note.uniqueId);
      if (imageProcessingStatus) {
        if (imageProcessingStatus.numberOfImagesLeftToProcess) {
          imageProcessingStatus.numberOfImagesLeftToProcess--;
          if (imageProcessingStatus.numberOfImagesLeftToProcess === 0) {
            imageProcessingStatus.processedContent = displayHtml;
            return;
          }
        }
      }
      this.generateDisplayHtmlWithImages(displayHtml, note);
    })
  };


}
