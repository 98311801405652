import { Injectable } from "@angular/core";
import { deleteToken, Messaging } from "firebase/messaging";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";

type RegisterDeviceResponse = {
    deviceAlreadyRegistered: boolean
    newRegistrationIdAdded: boolean
    oldRegistrationIdRevoked: boolean
}

@Injectable()
export class FirebaseServicesService {
    constructor(private readonly _dataService: DataService) {}

    registerDevice(data: {
        NewRegistrationId: string,
        ClientType: number
    }): Observable<RegisterDeviceResponse> {
        const url = "api/auth/RegisterDevice";
        return this._dataService.post(url, data);
    }

    getReportContent(data): Observable<string> {
        const url = `api/integration-history/${data}/document`;
        const headers = { Accept: 'text/html' };
        return this._dataService.get(url, headers);
    }

    logSignalRAck(messageName: string, traceId: string): Observable<any> {
        const url = `api/log/signalr/${messageName}/ack`;
        const headers = { 'LE-ReqTraceID': traceId };
        return this._dataService.post(url, {}, headers);
    }

    viewReleaseNotes(version: string): Observable<any> {
        const url = `api/admin/ViewedReleaseNotes?releaseNotesVersion=${version}`
        return this._dataService.post(encodeURI(url), {});
    }

    disconnect(messaging: Messaging, registrationId: string) {
        deleteToken(messaging).then(res => {
            const url = `api/auth/RevokeDevice?registrationId=${registrationId}`
            this._dataService.post(url, {});
        })
    }
}
