import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Alignment } from '../../internal-contacts/models/alignment.model';
import { LinkLoanToLeadRequest } from '../models/link-as-converted-from-lead.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  constructor(private _service: DataService) { }

  getApplications(isArchive) {
    return this._service.get(`api/Pipeline/GetAllApplicationsForInternalContact?archive=${isArchive}`);
  }

  getAllApplications = (isArchive: boolean) => {
    const url = 'api/Pipeline/GetAllApplicationsForInternalContact?archive=' + isArchive;
    return this._service.get(url);
  }

  getAlignmentsByChannelAndCompanyId = (companyId: number, selectedChannel: string = null): Observable<Alignment[]> => {
    let url = `api/admin/AllAlignmentModel?companyId=${companyId}`;
    if (selectedChannel) {
      url += `&channel=${selectedChannel}`;
    }
    return this._service.get(url);
  };

  getInternalContacts(applicationid: number) {
    return this._service.get(`api/Loan/${applicationid}/InternalContacts`)
  }

  linkToLead = (data: LinkLoanToLeadRequest) => {
    const url = `api/Loan/${data.loanId}/link-as-coverted-from-lead?leadId=${data.leadId}&copyLeadCampaignInfoFromLead=${data.copyLeadCampaignInfoFromLead}&copyLeadActivityFromLead=${data.copyLeadActivityFromLead}&copyCustomDataFromLead=${data.copyCustomDataFromLead}&copyReferralSourceInfo=${data.copyReferralSourceInfo}`;
    return this._service.post(url, data);
  }

  delete(id, deleteLeads) {
    return this._service.delete(`api/Loan/DeActivateLoan?applicationId=${id}&deleteLeads=${deleteLeads}`)
  }
}
