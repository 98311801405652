/**
 * @see https://gist.github.com/AustinMatherne/659f0aed22efa094d8a55f31aebfe0d4#file-let-directive-ts
 */

import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

interface LetContext<T> {
  ngLet: T;
}

/**
 * The `ngLet` directive is a structural directive useful for rendering a
 * template with a context value exposed as a local variable. Unlike `ngIf`,
 * `ngLet` does not remove the element from the DOM when the value is falsy.
 * @example
 * <ng-container *ngLet="value$ | async as value">
 *   <div>Value: {{ value }}</div>
 * </ng-container>
 */
@Directive({
  selector: '[ngLet]'
})
export class LetDirective<T> {
  private _context: LetContext<T> = {ngLet: null};

  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
    _viewContainer.createEmbeddedView(_templateRef, this._context);
  }

  @Input()
  set ngLet(value: T) {
    this._context.ngLet = value;
  }
}
