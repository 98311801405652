
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditMcrAuditDataComponent } from './edit-mcr-audit-data/edit-mcr-audit-data.component';
import { McrDataAuditRoutingModule } from './mcr-data-audit-routing.module';
import { SummaryMcrAuditDataComponent } from './summary-mcr-audit-data/summary-mcr-audit-data.component';
import { TableModule } from 'primeng/table';

@NgModule({
    imports: [
        SharedModule,
        McrDataAuditRoutingModule,
        TableModule
    ],
    declarations: [
      EditMcrAuditDataComponent,
      SummaryMcrAuditDataComponent
    ],
    exports: [
      EditMcrAuditDataComponent,
      SummaryMcrAuditDataComponent
    ],
    providers: []
})
export class McrDataAuditModule {
  constructor() {}
}


