<section class='card'>
  <logo [logoUrlsToTry]='possibleLogoUrls' isLinkDisabled='true'></logo>

  <ng-container [ngSwitch]='state'>
    <ng-container *ngSwitchCase='"email"'>
      <ng-container *ngTemplateOutlet='emailState'></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase='"preferences"'>
      <ng-container *ngTemplateOutlet='preferencesState'></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #emailState>
    <div class='email-state'>
      <div class='state-header'>
        <h4>Manage Your E-mail Preferences</h4>
        <p class='text-muted'>
          Please enter your e-mail address and click the button below.
        </p>
      </div>

      <form #emailForm='ngForm' (ngSubmit)='onSubmitEmail(emailForm)'>
        <div class='form-group'>
          <div class='input-group'>
            <label for='email' class='form-label'>E-mail address</label>
            <input
              id='email'
              type='email'
              name='email'
              email
              required
              #emailInput='ngModel'
              [(ngModel)]='email'
              class='form-control'
              [ngClass]=
                '{"is-invalid": emailForm.submitted && emailInput.invalid}'
              placeholder='example@domain.com'
            >

            <span class='invalid-feedback'>
              Please enter an e-mail address.
          </span>
          </div>
        </div>

        <button
          type='submit'
          [disabled]='!areQueryParamsValid'
          class='btn btn-primary waves-effect waves-light'
        >
          Manage My Preferences
        </button>
      </form>
    </div>
  </ng-template>

  <ng-template #preferencesState>
    <div class='preferences-state'>
      <div class='state-header'>
        <h4>Manage Your E-mail Preferences</h4>
        <p class='text-muted'>
          Manage your subscription preferences for campaigns.
        </p>
      </div>

      <div class='no-campaign' *ngIf='subscriptions.length == 0'>
        <p>
          There are no campaigns for the user.
        </p>
      </div>

      <div class='preferences' *ngIf='subscriptionGroups.length > 0'>
        <div class='campaigns'>
          <label class='campaign' *ngFor='let subscriptionGroup of subscriptionGroups'>
            <div class='campaign__name'>{{subscriptionGroup.preferencesCenterCampaignName}}</div>

            <span class='form-switch campaign__toggle'>
              <input
                type='checkbox'
                class='form-check-input'
                [ngModel]='!subscriptionGroup.unsubscribed'
                (ngModelChange)='subscriptionGroup.unsubscribed = !$event'
                (click)='onClickCampaignToggle($event, subscriptionGroup)'
              >
            </span>
          </label>
        </div>

        <button
          class='btn btn-primary waves-effect waves-light'
          [disabled]='!hasAnySubscribed'
          (click)='onClickUnsubscribeAll()'
        >
          Unsubscribe From All
        </button>
      </div>
    </div>
  </ng-template>
</section>
