import { CreditVendor } from "src/app/models";

export class LeadCredit {
  leadCreditId: number;
  leadId: number;
  loanDocId: number;
  borrowerType: BorrowerType;
  creditVendor: CreditVendor;
  integrationHistoryId: number;
  creditRunDate: Date;
  experianRequested: boolean;
  experianScore: number;
  equifaxRequested: boolean;
  equifaxScore: number;
  transUnionRequested: boolean;
  transUnionScore: number;
  isJoint: boolean;
  referenceNumber: string;
  notes: string;
  successful: boolean;
  failureReason: string;
  creditTechnicalAffiliateId: number;
  aus: MortgageAus;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string
  dateUpdated: Date;

  constructor(leadId: number){
    this.leadId = leadId;
  }

}

export enum MortgageAus{
  Du = "Du",
  Lpa = "Lpa"
}

export enum BorrowerType{
  PrimaryBorrower = "PrimaryBorrower",
  CoBorrower = "CoBorrower"
}

