<p-table #dt [value]="filteredAgents" [columns]="selectedColumns" [paginator]="true" [rows]="25"
  [resizableColumns]="true" responsiveLayout="scroll" name="agents" [rowsPerPageOptions]="[10, 25, 50, 100]"
  [globalFilterFields]="globalFilterFields" [autoLayout]="true" [(selection)]="selectedRows" [scrollable]="true"
  [scrollHeight]="scrollHeight" [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  styleClass="p-datatable-gridlines p-datatable-sm" stateStorage="local" stateKey="agents-table-state"
  sortMode="multiple" [multiSortMeta]="!tableState ? [{field: 'dateCreated', order: -1}] : tableState.multiSortMeta">
  <ng-template pTemplate="caption">
    <div class="row d-flex justify-content-between">
      <div class="col-md-4 col-12 hide-on-mobile" *ngIf="agentTableConfig.isButtonsVisible">
        <button *ngIf="agentTableConfig.forRemainContact" class="btn btn-primary ms-2" (click)="onAddNewClicked()">
          New Agent
        </button>
      </div>

      <div class="col-md-4 col-12 d-flex justify-content-center mb-xs-2"
        [ngClass]="[!agentTableConfig.isButtonsVisible ? 'offset-md-4' : '']">
        <app-add-to-dial-list
          *ngIf="agentTableConfig.forAgent && agentTableConfig.isButtonsVisible"
          [selectedRows]="selectedRows"
          [contactListType]="'Agent'"
          [idFieldName]="'agentId'"
          (onAddedToDialList)="addedToDialList($event)"
        ></app-add-to-dial-list>
        <button *ngIf="!isTpoUser" [disabled]="!selectedRows.length" class="btn btn-primary mx-2" (click)="openAssignContactDialog()">Assign Contact</button>
        <button *ngIf="!isTpoUser" class="btn btn-primary mx-2 hide-on-mobile" (click)="dt.exportCSV()"> Export To CSV</button>
      </div>

      <div class="col-md-4 col-12 d-flex justify-content-end justify-content-xs-between">
        <!-- mobile -->
        <div class="btn-group hide-on-non-mobile">
          <button
            *ngIf="agentTableConfig.isButtonsVisible && agentTableConfig.forRemainContact"
            type="button"
            class="btn text-primary"
            title="Add New Agent"
            (click)="onAddNewClicked()"
          >
            <i class="fas fa-user-plus"></i>
          </button>

          <button
            *ngIf="!isTpoUser"
            type="button"
            class="btn text-primary"
            title="Export To CSV"
            (click)="dt.exportCSV()"
          >
            <i class="fas fa-file-csv"></i>
          </button>

          <button
            type="button"
            class="btn text-primary"
            data-bs-container="body"
            data-bs-toggle="popover"
            data-trigger="click"
            placement="bottom"
            (click)="openColumnSelectorForMobile()"
            [popover]="mobileColumnSelectorPopover"
            [outsideClick]="true"
            [containerClass]="'mobile-col-selector-popover'"
          >
            <i class="fa fa-cog"></i>
          </button>
          <ng-template #mobileColumnSelectorPopover>
            <p-multiSelect
              #mobileColumnSelector
              class="d-inline-block"
              [options]="agentTableConfig.columns"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              name="selectedColumns"
              ngDefaultControl
              defaultLabel="Choose Columns"
            ></p-multiSelect>
          </ng-template>
        </div>

        <!-- desktop and mobile -->
        <p-multiSelect
          class="hide-on-mobile"
          [options]="agentTableConfig.columns"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          selectedItemsLabel="{0} columns selected"
          name="selectedColumns"
          ngDefaultControl
          defaultLabel="Choose Columns"
        ></p-multiSelect>

        <span class="p-input-icon-right ms-2">
          <i class="pi pi-search"></i>
          <input pInputText #searchText type="text" class="form-control h-100" [(ngModel)]="globalSearchString"
            (ngModelChange)="onGlobalSearchStringChanged(); dt.setFirst(1)" placeholder="Search keyword" />
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Action</th>
      <th *ngFor="let col of getSortedColumns()" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr class="table-row-height--{{agentTableConfig.rowHeight}}">
      <td>
        <span *ngIf="agentTableConfig.forAgent">
          <span class="text-cell-primary">
            <span *ngIf="agentTableConfig.isButtonsVisible">
              <p-tableCheckbox [value]="rowData">
              </p-tableCheckbox>
              <span class="mx-1">|</span>
            </span>
            <a (click)="onEditClicked(rowData.agentId)">
              <i class="fas fa-pencil-alt cursor text-info" title="Edit"></i>
            </a>
            <span class="mx-1">|</span>
            <i class="fa fa-trash-alt cursor text-danger" (click)="deleteAgent(rowData.agentId)" title="Delete"></i>
          </span>
          <br />
          <span class="text-muted">
            <a class="text-muted" *ngIf="(rowData.leadCount && rowData.leadCount > 0)"
              (click)="onViewLeadsClicked(rowData.agentId)">
              Leads
            </a>
            <span
              *ngIf="(rowData.leadCount && rowData.leadCount > 0) && (rowData.applicationCount && rowData.applicationCount > 0)">
              - </span>

            <a class="text-muted" *ngIf="(rowData.applicationCount && rowData.applicationCount > 0)"
              (click)="onViewApplicationsClicked(rowData.agentId)">
              Applications
            </a>
            <span
              *ngIf="!(rowData.leadCount && rowData.leadCount > 0) && !(rowData.applicationCount && rowData.applicationCount > 0)">&nbsp;</span>

          </span>
        </span>
        <span *ngIf="agentTableConfig.forRemainContact && !agentTypeId" class="d-flex align-items-center justify-content-center w-100">
          <a class="btn btn-sm btn-primary" href="javascript: void(0)" (click)="onAddAsClicked(rowData.agentId)">
            Add As...
          </a>
        </span>
        <span *ngIf="agentTableConfig.forRemainContact && agentTypeId" class="d-flex align-items-center justify-content-center w-100">
          <a class="btn btn-sm btn-primary" href="javascript: void(0)" (click)="onAssociateClicked(rowData.agentId)">
            Associate
          </a>
        </span>
      </td>
      <td *ngFor="let col of getSortedColumns()" [ngSwitch]="col.field">
        <span *ngSwitchCase="'displayName'">
          <span class="text-cell-primary">{{ rowData.displayName}}</span>
          <br>
          <span class="text-muted">{{ rowData.title || '--'}}</span>
        </span>
        <span *ngSwitchCase="'portalStatus'">
          <span
            class="text-cell-primary label label-{{rowData.portalStatus === 'Live' ? 'success' : (rowData.portalStatus === 'Not Confirmed' ? 'warning' : 'info')}}"
            href="javascript:void(0);">{{rowData.portalStatus}}</span>
          <br>
          <span class="text-muted">{{rowData.portalStatus === 'Live' ? ("Last Login " + (rowData.lastLoginActivity ||
            '--')) : '--'}}</span>
        </span>
        <span *ngSwitchCase="'agentListNames'">
          <ul *ngIf="rowData.agentListNames && rowData.agentListNames.length > 0" class="lead-pills">
            <li *ngFor="let agentListName of rowData.agentListNames" class="pill">
              {{ agentListName }}
            </li>
          </ul>
        </span>
        <span *ngSwitchCase="'contactInfo'">
          <table-cell-contact-info [contactInfo]="rowData" [recordType]="'Agent'" [recordId]="rowData.agentId"
              [dialerEnabled]="dialerEnabled"></table-cell-contact-info>
        </span>

        <span *ngSwitchCase="'lastAttemptedContact'">
          <span> {{ rowData['lastAttemptedContact'] | date: 'short' }} </span>
        </span>

        <span *ngSwitchCase="'lastSuccessfulContact'">
          <span class="text-cell-primary">{{ rowData['lastAttemptedContact'] | date: 'short' }}</span>
          <br />
          <span class="text-muted">{{ rowData['lastSuccessfulContact'] ? (rowData['lastSuccessfulContact'] | date:
            'short') : '--' }}</span>
        </span>

        <span *ngSwitchCase="'assistantName'">
          <span class="text-cell-primary">
            {{ rowData.assistantName }}
          </span>
          <br />
          <span class="text-muted" *ngIf="rowData.assistantName">
            <a href="mailto:{{ rowData.assistantPhone }}"><i class="fas fa-phone"></i> M: {{
              rowData.assistantPhone || '--'| phone
              }}</a>
          </span>
          <br />
          <span class="text-muted" *ngIf="rowData.assistantName">
            <a [href]="'mailto:' + rowData.assistantEmail | safeUrl" class="me-1"><i class="fas fa-envelope"></i>
              {{ rowData.assistantEmail || '--' }}
            </a>
          </span>
        </span>

        <span *ngSwitchCase="'agentContactUserName'">
          <span class="text-cell-primary">
            <div class="img-group">
              <a class="user-avatar user-avatar-group">
                <img src="{{baseAvatarUrl}}avatars/user-profile/{{rowData.agentContactUserId}}"
                  onerror="this.src='assets/images/male.png'" alt="user" class="rounded-circle thumb-xs">
              </a>
            </div>
            {{ rowData.agentContactUserName}}
          </span>
          <br />
          <span class="text-muted">
            <span *ngIf="rowData.agentVisibility == 'Public'" class="label label-success">PUBLIC</span>
            <span *ngIf="rowData.agentVisibility == 'Private'" class="label label-warning">PRIVATE</span>
          </span>
        </span>
        <span *ngSwitchCase="'dateInserted'">
          <span class="text-cell-primary">{{ rowData.dateInserted | date: 'short' }} </span>
        </span>
        <span *ngSwitchCase="'mailingState'">
          <span class="text-cell-primary">{{ rowData?.mailingStateDescription }} </span>
        </span>
        <span *ngSwitchDefault>
          <span class="text-cell-primary">{{ rowData[col.field] }}</span>
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="agentTableConfig.columns.length + 1" class="text-center">
        There are no agents
      </td>
    </tr>
  </ng-template>
</p-table>
