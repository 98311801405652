<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #mainContent>
  <p-table #dt1 dataKey="dialListId" [value]="dialLists" responsiveLayout="scroll" [scrollable]="true"
    [scrollHeight]="scrollHeight" name="dial-list-table-v2" [autoLayout]="true" [showCurrentPageReport]="true"
    [globalFilterFields]="['name']" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [expandedRowKeys]="expandedRowKeys">
    <ng-template pTemplate="caption">
      <div class="d-flex flex-xs-wrap justify-content-between">
        <div class="d-flex justify-content-xs-center w-xs-100">

          <div class="d-flex align-items-center me-2">
            <label for="dialUsing" class="w-100">Dial using</label>
            <select name="dialUsing" class="form-select ms-2" style="min-width: 180px;"
              [(ngModel)]="dialUsingTelephonyPoolId" (change)="dialUsingChanged($event)">
              <option [ngValue]="null">Work Phone</option>
              <option *ngFor="let item of telephonyPools" [ngValue]="item.telephonyPoolId">{{item.name}}</option>
            </select>
          </div>

          <div>
            <button type="button" class="btn btn-primary me-2 mb-xs-2 mx-xs-0" (click)="fetchNextAvailableLead()">
              Fetch Next Available Lead
              <i class="fa fa-sync" id="fetchNextButton"></i>
            </button>
          </div>

        </div>

        <div class="d-flex justify-content-xs-between w-xs-100">
          <div class="d-flex align-items-center me-2">
            <label for="dialUsing" class="w-100">Strike out if called in past</label>
            <select name="strikeOutHours" class="form-select ms-2" style="min-width: 180px;"
              [(ngModel)]="strikeOutHours" (change)="onStrikeOutHoursChanged()">
              <option *ngFor="let hour of hourOptionsForStrikeOut" [ngValue]="hour">{{hour}} hour{{ hour === 1 ? '' : 's'}}</option>
            </select>
          </div>
          <div>
            <!-- desktop -->
            <div class="d-flex align-items-center hide-on-mobile">
              <label for="timezoneFilter" class="me-2">
                Filter By Timezone:
              </label>

              <button class="btn btn-outline-primary" type="button" (click)="$event.stopPropagation(); myDrop.open()">
                {{
                selectedTimeZones.length > 0
                ? selectedTimeZones.length + ' checked'
                : 'Select'
                }}
                <i class="fa fa-caret-down"></i>
              </button>
            </div>

            <!-- mobile -->
            <div class="hide-on-non-mobile">
              <button type="button" class="btn text-primary" (click)="myDrop.open()">
                <i class="fa fa-clock"></i>
              </button>
            </div>

            <!-- desktop and mobile -->
            <div ngbDropdown #myDrop="ngbDropdown" class="w-100">
              <a ngbDropdownToggle style="display: none"></a>
              <div ngbDropdownMenu class="overflow-auto"
                style="max-height: 400px !important; width: max-content !important;">
                <div *ngFor="let list of timezones; let i = index" class="mx-2">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [ngModel]="list.checked"
                      (ngModelChange)="list.checked = $event; timeZoneFiltersChanged()" id="listName_{{ i }}" />
                    <label class="form-check-label" for="listName_{{ i }}">
                      {{ list.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center ms-2">
            <div class="form-check form-switch form-switch-success">
              <label for="hideTimezoneRestrictedRecords" class="form-check-label">Hide Timezone Restricted
                Records</label>
              <input id="hideTimezoneRestrictedRecords" class="form-check-input"
                [(ngModel)]="hideTimezoneRestrictedRecords" (ngModelChange)="timeZoneFiltersChanged()"
                type="checkbox" />
            </div>
          </div>

          <div class="p-input-icon-right ms-2">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control h-100"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0"
              placeholder="Search keyword" />
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3rem"></th>
        <th pSortableColumn="name">
          Name<p-sortIcon field="price"></p-sortIcon>
        </th>
        <th style="width: 200px">Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-expanded="expanded">
      <tr [ngClass]="{ expand: expanded }">
        <td>
          <button type="button chevronBtn" pButton pRipple [pRowToggler]="rowData"
            class="p-button-text p-button-rounded p-button-plain" 
            [disabled]="dialListRefreshStatusesById.get(rowData.dialListId)"
            [icon]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
            (click)="selectDialListClicked(rowData)"></button>
        </td>

        <td>
          <span *ngIf="!rowData.adminManaged" class="me-2">
            <i class="fa fa-trash-alt cursor text-danger" (click)="onDeleteDialListClicked(rowData.dialListId)"
              title="Delete"></i>
          </span>
          {{ rowData.name + ' (' + rowData.records.length + ')' }}
        </td>
        <td>
          <a style="pointer-events: none !important;">
            {{
            autoRefreshTimers[rowData.dialListId]
            ? 'Refreshing in: ' + autoRefreshTimers[rowData.dialListId]
            : 'Auto Refresh: ' + (rowData.autoRefreshInterval ? 'On' : 'Off')
            }}
          </a>

          <i #refreshBtn title="Refresh" [ngClass]="{
              'fa-spin': dialListRefreshStatusesById.get(rowData.dialListId)
            }" class="fas fa-sync ms-2" id="{{ rowData.dialListId }}" style="cursor: pointer"
            (click)="onRefreshClicked(rowData.dialListId)"></i>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData>
      <tr>
        <ng-container *ngIf="rowData.records.length > 0">
          <td colspan="3">
            <dial-list-records-table 
              #dialList [loanOfficers]="loanOfficers" 
              [selectedDialList]="rowData"
              [selectedRecord]="selectedRecord" 
              [predefinedNotes]="predefinedNotes"
              [selectedRecordForCall]="selectedRecordForCall" 
              [dialLists]="dialLists"
              [strikeOutHours]="strikeOutHours"
              [sortOnInit]="!this.selectedRecordForCall" 
              [manualOpenCallPanel]="manualOpenCallPanelData"
              [dialListRefreshStatus]="dialListRefreshStatusesById.get(rowData.dialListId)"
              (onPhoneNumberClicked)="onPhoneNumberClicked($event)" 
              (onPageChanged)="onRecordTablePageChanged($event)"
              (editRecordClicked)="onEditRecordClicked($event)">
            </dial-list-records-table>
          </td>
        </ng-container>
        <ng-container *ngIf="rowData.records.length == 0">
          <td colspan="3" class="text-center">
            <span class="text-uppercase fw-bold"> There are no records. </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3" class="text-center">
          {{ !isLoading ? 'There are no dial list item.' : 'Loading...' }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <drawer #dialRecordEditorDrawer [title]="'Edit Dial Record'" [name]="'dialRecordEditorDrawer'"
    [templateRef]="dialRecordContentRef" [options]="dialRecordDrawerOptions">
  </drawer>

  <ng-template #dialRecordContentRef>
    <dial-list-record-details [ngClass]="{'record-details': selectedRecord.recordType !== 'Agent'}"
      *ngIf="selectedRecord" #recordDetails [record]="selectedRecord" [dialLists]="dialLists"
      [selectedDialList]="selectedDialList" (triggerOpenCallActionsPanel)="setManaulOpenDialPanelData($event)"
      (close)="closeRecordDetailsDrawerClicked();" (afterSaved)="onAfterSaveRecordDetails($event)"
      (afterStatusChanged)="onAfterStatusChanged($event)"></dial-list-record-details>
  </ng-template>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</admin-page-template>