import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LeadStatusAssociation } from '../../models';

@Injectable()
export class LeadStatusAssociationService {
  constructor(private readonly _dataService: DataService) {}

  getAllLeadStatusAssociations(
    id: number,
    companyId: number
  ): Observable<Array<LeadStatusAssociation>> {
    return this._dataService.get(
      `api/Admin/GetAllLeadStatusAssociationsByLoanPurpose/${id}?companyId=${companyId}`
    );
  }

  insertLeadStatusAssociation(data: LeadStatusAssociation): Observable<LeadStatusAssociation> {
    return this._dataService.post(
      `api/Admin/InsertLeadStatusAssociation`,
      data
    );
  }

  updateLeadStatusAssociation(data: LeadStatusAssociation): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateLeadStatusAssociation`,
      data
    );
  }

  updateLeadStatusAssociationOrder(
    sortedIds: Array<{ id: number }>,
    loanPurposeId: number,
    companyId: number
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateLeadStatusFlowOrder/${loanPurposeId}?companyId=${companyId}`,
      sortedIds
    );
  }

  deleteLeadStatusAssociation(
    id: number,
    companyId: number
  ): Observable<undefined> {
    return this._dataService.delete(
      `api/Admin/DeleteLeadStatusAssociation/${id}?companyId=${companyId}`
    );
  }
}
