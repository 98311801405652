<div class="row mb-1">
  <div class="col-md-4">
    <div class="title">
      Request Title
    </div>
  </div>
  <div class="col-md-8 d-flex" *ngIf="task.taskType !== 'RequestAppraisal' && !orderNumber">
    <select [disabled]="loadingHistory" class="form-select" [(ngModel)]="selectedVendorCredential"
      (change)="onSelectedVendorCredentialChanged()">
      <option [ngValue]="null">-- Select One --</option>
      <option [ngValue]="cred" *ngFor="let cred of titleVendorCredentials">
        {{cred.vendorName }} - {{cred.credentialName}}
      </option>
    </select>

    <button class="btn btn-primary get-quote ms-2" (click)="getQuote()"
      *ngIf="selectedVendorCredential?.vendorName == 'StatesTitle'">Get Quote</button>

  </div>
</div>
<div class="row section">
  <div class="col-md-4">Loan Type:</div>
  <div class="col-md-8">{{loanType ? loanType.loanTypeName : '-'}}</div>

  <div class="col-md-4">Loan Purpose:</div>
  <div class="col-md-8">{{loanPurpose ? loanPurpose.loanPurposeName : '-'}}</div>

  <div class="col-md-4">Loan Amount:</div>
  <div class="col-md-8">{{(mortgage.mortgageTerm.amount || 0) | currency}}</div>

  <div class="col-md-4">Property Address:</div>
  <div class="col-md-8">{{mortgage.subjectProperty.address1 || '-'}}</div>

  <div class="col-md-4">Property City/St/Zip:</div>
  <div class="col-md-8">
    {{
    (mortgage.subjectProperty.city ? mortgage.subjectProperty.city +', ' : '') +
    (mortgage.subjectProperty.state ? mortgage.subjectProperty.state.toUpperCase() : '') + ' ' +
    (mortgage.subjectProperty.zipCode || '')
    }}
  </div>

  <ng-container
    *ngIf="selectedVendorCredential && (!orderNumber || ['Cancelled','Archived','Rejected'].includes(titleOrderStatus))">
    <div class="col-md-4 my-1">Order Type:</div>
    <div class="col-md-8 my-1">
      <select [disabled]="loadingHistory" class="form-select" [(ngModel)]="orderType">
        <option [ngValue]="null">-- Select One --</option>
        <option [ngValue]="type.value" *ngFor="let type of orderTypes">
          {{type.name }}
        </option>
      </select>
    </div>

    <div class="col-md-4 my-1">Product Type:</div>
    <div class="col-md-8 my-1">
      <select [disabled]="loadingHistory" class="form-select" [(ngModel)]="productType">
        <option [ngValue]="null">-- Select One --</option>
        <option [ngValue]="type.value" *ngFor="let type of productTypes">
          {{type.name }}
        </option>
      </select>
    </div>

    <div class="col-md-4 my-1">Notes:</div>
    <div class="col-md-8 my-1">
      <textarea name="notes" rows="5" class="form-control" [(ngModel)]="notes"></textarea>
    </div>
  </ng-container>

  <div *ngIf="titleOrderId && selectedVendorCredential?.vendorName == 'StatesTitle'" class="row">
    <div class="col-md-4 mt-2">Lender:</div>
    <div class="col-md-5">
      <select [disabled]="loadingHistory" class="form-select" [(ngModel)]="selectedLender.thirdPartyLenderId">
        <option value="">-- Select One --</option>
        <option value="{{lender.thirdPartyLenderId}}" *ngFor="let lender of lenderList">
          {{lender.lenderName }}
        </option>
      </select>
    </div>
    <div class="col-md-3 no-padding">
      <button type="button" class="btn btn-secondary" (click)="updateLender()"
        [disabled]="!selectedLender || !selectedLender.thirdPartyLenderId || isLenderSaving || loadingHistory">
        <span *ngIf="isLenderSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{isLenderSaving ? 'Please wait...' : 'Update Lender'}}
      </button>
    </div>
  </div>
</div>
<div class="row section" style="margin-top: 10px" *ngIf="quoteErrors && quoteErrors.length">
  <div class="col-md-12">
    <div class="title">Errors</div>
  </div>
  <div class="col-md-12">
    <div class="row head">
      <div class="col-md-4">Field</div>
      <div class="col-md-8">Error</div>
      <div class="col-md-12">
        <hr />
      </div>
    </div>
    <div class="row details" *ngFor="let error of quoteErrors">
      <div class="col-md-4" style="text-transform: capitalize">{{error.field}}</div>
      <div class="col-md-8">{{error.message}}</div>
    </div>
  </div>
</div>
<div class="row section" style="margin-top: 10px" *ngIf="quote && !quoteErrors.length">
  <div class="col-md-12">
    <div class="title">Quote Details</div>
  </div>

  <div class="col-md-12">
    <div class="row head">
      <div class="col-md-3">Fee</div>
      <div class="col-md-3">Fee $</div>
      <div class="col-md-2">Paid By</div>
      <div class="col-md-2">APR</div>
      <div class="col-md-2">POC</div>
      <div class="col-md-12">
        <hr />
      </div>
    </div>
    <div class="row details" *ngFor="let fee of quote.fees">
      <div class="col-md-3">{{fee.label}}</div>
      <div class="col-md-3">{{(fee.amount || 0) | currency}}</div>
      <div class="col-md-2">{{fee.paidBy}}</div>
      <div class="col-md-2">{{fee.isAprFee ? 'Yes' : 'No'}}</div>
      <div class="col-md-2">{{fee.isPaidOutsideClosing ? 'Yes' : 'No'}}</div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="title" style="margin-bottom: 5px">Agent</div>
      </div>

      <div class="col-md-12">
        <div class="row details">
          <div class="col-md-4">Contact:</div>
          <div class="col-md-8">
            {{quote.settlementAgent.contactFirstName + ' ' + quote.settlementAgent.contactLastName}}
          </div>

          <div class="col-md-4">Company:</div>
          <div class="col-md-8">{{quote.settlementAgent.companyName}}</div>

          <div class="col-md-4">Email:</div>
          <div class="col-md-8">{{quote.settlementAgent.email}}</div>

          <div class="col-md-4">Phone:</div>
          <div class="col-md-8">{{quote.settlementAgent.phone | phone}}</div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <ng-container *ngTemplateOutlet="orderDetails"></ng-container>
      </div>
      <div class="col-md-6 text-end">
        <button class="btn btn-primary" (click)="syncQuoteFeesToLoan()" *ngIf="titleQuoteHistoryId && !orderNumber"
          style="width: 175px">Sync
          Quote Fees to Loan</button>
        <button class="btn btn-primary mx-2"
          *ngIf="!orderNumber || ['Cancelled','Archived','Rejected'].includes(titleOrderStatus)" (click)="submitOrder()"
          style="width: 105px">Submit Order</button>
      </div>
    </div>

  </div>
</div>

<div class="row section" style="margin-top: 10px"
  *ngIf="selectedVendorCredential?.vendorName == 'SoftPro' && (!orderNumber || ['Cancelled','Archived','Rejected'].includes(titleOrderStatus))">
  <div class="col-md-12 text-end">
    <button type="button" class="btn btn-primary" (click)="submitOrder()" style="width: 105px">Submit Order</button>
  </div>
</div>

<div class="row section" style="margin-top: 10px" *ngIf="orderNumber">
  <div class="col-md-12 mb-1">
    <h5 class="my-2 text-center">Order Details</h5>
    <div class="row">
      <div class="col-md-6">
        <ng-container *ngTemplateOutlet="orderDetails"></ng-container>
      </div>
      <div class="col-md-6 d-flex align-items-center justify-content-center flex-column">
        <button type="button" class="btn btn-primary m-2" (click)="refreshEvents()">Refresh Events</button>
        <button type="button" class="btn btn-danger" (click)="deleteOrder()">Delete Order</button>
      </div>
    </div>
  </div>
</div>

<div class="row section" style="margin-top: 10px">
  <div class="col-md-12 mb-1">
    <h5 class="my-2 text-center">Order Documents</h5>
    <p-table [value]="titleOrderDocuments" [paginator]="false">
      <ng-template pTemplate="caption" *ngIf="(orderNumber && ['Accepted'].includes(titleOrderStatus)) || !orderNumber">
        <button type="button" class="btn btn-primary btn-sm waves-effect waves-light" (click)="onAddDocClicked()">
          <i class="fas fa-plus me-1"></i> Add Document
        </button>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th> File Name </th>
          <th style="width: 15rem"> Actions </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-doc>
        <tr>
          <td>
            {{doc.fileName}}
          </td>
          <td>
            <a (click)="viewLoanDocument(doc)" title="View" class="mx-2 align-text-bottom">
              <i class='far fa-eye'></i>
            </a>
            <a title="Download" class="align-text-bottom" (click)="downloadDocument(doc)">
              <i class='fas fa-download'></i>
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" class="text-center"> There are no documents. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="row section" style="margin-top: 10px" *ngIf="orderNumber">
  <div class="col-md-12 mb-1">
    <h5 class="my-2 text-center">Order Events</h5>
    <p-table [value]="titleOrderEvents" [paginator]="false">
      <ng-template pTemplate="header">
        <tr>
          <th>Name </th>
          <th>Comments </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-event>
        <tr>
          <td>
            {{event.eventName}}
          </td>
          <td>
            {{event.comments}}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" class="text-center"> There are no events. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-template #orderDetails>
  <div class="row" *ngIf="orderNumber">
    <div class="col-md-6 key">Order #:</div>
    <div class="col-md-6">{{orderNumber}}</div>
  </div>
  <div class="row" *ngIf="orderStatus">
    <div class="col-md-6 key">Order Status:</div>
    <div class="col-md-6">{{orderStatus}}</div>
  </div>
  <div class="row" *ngIf="titleOrderStatus">
    <div class="col-md-6 key">Loda Order Status:</div>
    <div class="col-md-6">{{descriptions?.orderStatus || '--'}}</div>
  </div>
  <div class="row" *ngIf="orderType">
    <div class="col-md-6 key">Order Type:</div>
    <div class="col-md-6">{{descriptions?.orderType || '--'}}</div>
  </div>
  <div class="row" *ngIf="productType">
    <div class="col-md-6 key">Product Type:</div>
    <div class="col-md-6">{{descriptions?.productType || '--'}}</div>
  </div>
  <div class="row" *ngIf="integrationProvider">
    <div class="col-md-6 key">Integration Provider:</div>
    <div class="col-md-6">{{integrationProvider}}</div>
  </div>
</ng-template>
