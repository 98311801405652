export enum LosEventSource {
  Event = 'Event',
  Manual = 'Manual',
}

export enum LosEventDirection {
  LosToLoda = 'LosToLoda',
  LodaToLos = 'LodaToLos'
}

export enum LosEventOperation {
  LoanCreate = 'LoanCreate',
  LoanSync = 'LoanSync',
  MilestoneSync = 'MilestoneSync',
  ConditionSync = 'ConditionSync',
  DocumentSync = 'DocumentSync',
  IntUserSync = 'IntUserSync',
  ExtUserSync = 'ExtUserSync',
  // extended
  LoanLink = 'LoanLink',
  LoanUnlink = 'LoanUnlink'
}

export enum LosEventStatus {
  Started = 'Started',
  Completed = 'Completed',
  Failed = 'Failed',
  Ignored = 'Ignored',
  Pending = 'Pending'
}