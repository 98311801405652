import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EmploymentTypeEnum, Income, TypeOfIncomeEnum} from 'src/app/models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {LeadEmployment} from 'src/app/modules/leads/models/lead-employment.model';
import {Constants} from 'src/app/services/constants';
import {LeadIncomeDialogComponent} from '../dialogs/lead-income-dialog/lead-income-dialog.component';
import Swal from 'sweetalert2';
import {LeadsService} from 'src/app/modules/leads/services/leads.service';
import {NotificationService} from 'src/app/services/notification.service';
import {getErrorMessageOrDefault} from '../../../../../shared/utils/error-utils';
import {sum} from '../../../../../shared/utils/math-utils';
import { PayPeriod } from 'src/app/shared/utils/pay-period/pay-period.utils';

@Component({
  selector: 'lead-employment-income-in-detail',
  templateUrl: 'lead-employment-income-in-detail.component.html'
})
export class LeadEmploymentIncomeInDetailComponent implements OnInit {
  @Input()
  readonly employments: LeadEmployment[] = [];

  @Output()
  readonly employmentsChange = new EventEmitter<LeadEmployment[]>();

  @Output()
  readonly employmentChange = new EventEmitter<LeadEmployment>();

  @Input()
  readonly totalMonthlyIncome: number;

  @Input()
  readonly employmentIncomeTypes: EnumerationItem[] = [];

  @Input()
  readonly yesNoOptions: EnumerationItem[] = [];

  @Input()
  readonly ownershipShareTypes: EnumerationItem[] = [];

  @Input()
  readonly leadId: number;

  private _editingIx: number = -1;

  isSelfEmployed: boolean = false;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _leadsService: LeadsService,
    private readonly _notifyService: NotificationService,
  ) { }

  ngOnInit() {
  }

  selfEmployedChanged = () => {
    this.checkSelfEmployed();
  }

  public addEmployment(): void {
    let employment = new LeadEmployment();
    employment.isSelfEmployed = false;
    employment.employmentType = EmploymentTypeEnum.CurrentEmployer;
    employment.calculatedStats = {
      monthlyIncome: 0,
    };

    this.employmentsChange.emit([...this.employments, employment]);
  }

  protected onCalculatorClicked(employment: LeadEmployment): void {
    const modalRef = this._modalService.open(LeadIncomeDialogComponent, Constants.modalOptions.large);

    type CustomIncome = Income & {monthlyValue: number};
    const createIncome = (props: Partial<CustomIncome>): Partial<CustomIncome> => {
      const {amount, monthlyValue, ...rest} = props;

      return ({
        payPeriod: PayPeriod.Monthly,
        hoursPerWeek: '' as any,
        amount: amount || 0,
        monthlyValue: monthlyValue || 0,
        ...rest,
      });
    };

    modalRef.componentInstance.incomes = [
      createIncome({
        typeOfIncome: TypeOfIncomeEnum.Base,
        amount: employment.monthlyIncome,
        monthlyValue: employment.monthlyIncome,
      }),
      createIncome({
        typeOfIncome: TypeOfIncomeEnum.Bonus,
        amount: employment.bonusIncome,
        monthlyValue: employment.bonusIncome,
      }),
      createIncome({
        typeOfIncome: TypeOfIncomeEnum.Commissions,
        amount: employment.commissionsIncome,
        monthlyValue: employment.commissionsIncome,
      }),
      createIncome({
        typeOfIncome: TypeOfIncomeEnum.Overtime,
        amount: employment.overtimeIncome,
        monthlyValue: employment.overtimeIncome,
      }),
    ];

    modalRef.componentInstance.title = 'Total Gross Income';
    modalRef.result.then((result) => {
      if (result === 'cancel') {
        return;
      }

      employment.monthlyIncome = result.find(r => r.typeOfIncome === TypeOfIncomeEnum.Base)?.monthlyValue || 0;
      employment.bonusIncome = result.find(r => r.typeOfIncome === TypeOfIncomeEnum.Bonus)?.monthlyValue || 0;
      employment.commissionsIncome = result.find(r => r.typeOfIncome === TypeOfIncomeEnum.Commissions)?.monthlyValue || 0;
      employment.overtimeIncome = result.find(r => r.typeOfIncome === TypeOfIncomeEnum.Overtime)?.monthlyValue || 0;

      this.calculateTotalIncome(employment);
      this.employmentChange.emit(employment);
    }, () => {
    });
  }

  protected onSelfEmployedIncomeOrLossChanged(employment: LeadEmployment): void {
    this.calculateTotalIncome(employment);
    this.employmentChange.emit(employment);
  }

  protected async deleteEmploymentClicked(deleteIx: number): Promise<void> {
    const employment = this.employments[deleteIx];
    if (employment == null) {
      console.error('Employment not found at index', deleteIx);
      this._notifyService.showError('Couldn\'t delete income.', 'Error!');
      return;
    }

    const emitEmploymentsChange = () => {
      this.employmentsChange.emit(
        this.employments.filter((_, ix) => ix !== deleteIx),
      );
    };

    if (!employment.leadEmploymentId) {
      emitEmploymentsChange();
      return;
    }

    const answer = await Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });

    if (!answer.isConfirmed) {
      return;
    }

    if (deleteIx === this._editingIx) {
      this._editingIx = -1;
    }

    this._leadsService
      .deleteLeadEmployment(employment.leadEmploymentId)
      .subscribe({
        next: () => {
          emitEmploymentsChange();

          this._notifyService.showSuccess(
            'Income deleted successfully',
            'Success!',
          );
        },
        error: error => {
          this._notifyService.showError(
            getErrorMessageOrDefault(error, {
              defaultMessage: 'Couldn\'t delete income.',
            }),
            'Error!',
          );
        },
      });
  }

  private calculateTotalIncome(employment: LeadEmployment): void {
    employment.calculatedStats.monthlyIncome = sum(
      employment.monthlyIncome,
      employment.bonusIncome,
      employment.commissionsIncome,
      employment.overtimeIncome,
    );
  }

  private checkSelfEmployed = () => {
    if (this.employments) {
      this.isSelfEmployed = this.employments.some(employment => employment.isSelfEmployed);
    }
  }
}
