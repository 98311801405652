import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentSigningOrder } from 'src/app/modules/loan-docs/models/document-signing-order.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ESignatureService } from '../../services/esignature.service';

@Component({
  selector: 'esignature-orders-table',
  templateUrl: 'esignature-orders-table.component.html',
  styleUrls: ['esignature-orders-table.component.scss']
})

export class EsignatureOrdersTableComponent implements OnInit {

  @Input()
  orders: DocumentSigningOrder[] = [];

  @Output()
  orderSelected: EventEmitter<DocumentSigningOrder> = new EventEmitter<DocumentSigningOrder>();

  docLoadingStatuses: Map<number, boolean> = new Map<number, boolean>();

  globalFilterFields: string[] = ['thirdPartyOrderId', 'status', 'dateInserted', 'signingLinkSentInEmail', 'senderViewUrl'];

  selectedOrderId: number = null;

  constructor(private readonly _esignService: ESignatureService,
    private readonly _notifService: NotificationService,
    private readonly _spinner: NgxSpinnerService) { }

  ngOnInit() { }

  onSelectOrderClicked = (order: DocumentSigningOrder) => {
    this.selectedOrderId = order.documentSigningOrderId;
    this.orderSelected.emit(order);
  }

  onViewSignedDocClicked = (order: DocumentSigningOrder) => {
    this.docLoadingStatuses.set(order.documentSigningOrderId, true);
    const loanDocTaskId = order.loanDocTasks[0].loanDocTaskId;
    this._esignService.downloadSignedDocument(loanDocTaskId)
      .subscribe({
        next: (data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: (err) => {
          var decodedString = String.fromCharCode.apply(null, new Uint8Array(err));
          var errorObject = JSON.parse(decodedString);
          this._notifService.showError(errorObject && errorObject.message ? errorObject.message :
            'Error occurred while viewing signed document.', "Error!");
        }
      }).add(() => {
        this.docLoadingStatuses.set(order.documentSigningOrderId, false);
        this._spinner.hide();
      });
  }
}
