
  <li class="notification-list margin-top-icon ms-3">
    <button class="btn btn-sm btn-outline-primary calls-on-button-mobile d-flex align-items-center" style="padding-left:6px!important" (click)="leadsOff()" *ngIf="isLeadsOn"
      ><i class="fa fa-lg fa-fw fa-flag me-1"></i><span class="remove-on-mobile">Leads On</span></button>
    <button class="btn btn-sm btn-outline-danger calls-on-button-mobile d-flex align-items-center" style="padding-left:6px!important" (click)="leadsOn()" *ngIf="!isLeadsOn"
      ><i class="fa fa-lg fa-fw fa-flag me-1"></i><span class="remove-on-mobile">Leads Off</span></button>
  </li>

  <li class="notification-list margin-top-icon ms-2">
    <button class="btn btn-sm btn-outline-primary calls-on-button-mobile d-flex align-items-center" style="padding-left:6px!important" (click)="callsOff()" *ngIf="isCallsOn"
      ><i class="fa fa-phone me-1" aria-hidden="true"></i><span class="remove-on-mobile">Calls On</span></button>
    <button class="btn btn-sm btn-outline-danger calls-on-button-mobile d-flex align-items-center" style="padding-left:6px!important" (click)="callsOn()" *ngIf="!isCallsOn"
      ><i class="fa fa-phone me-1" aria-hidden="true"></i><span class="remove-on-mobile">Calls Off</span></button>
  </li>

