import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class QuickInsuredService {

  constructor(private readonly _dataService: DataService) { }

  getOrderHistory = (loanDocTaskId: number) => {
    const url = 'api/quick-insured/orders/history/' + loanDocTaskId;
    return this._dataService.get(url);
  }

  postOrder = (loanDocTaskId: number, data: any) => {
    const url = 'api/quick-insured/orders/' + loanDocTaskId;
    return this._dataService.post(url, data);
  }

}
