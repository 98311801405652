<div class="row mb-2">
  <div class="table-responsive" style="border-bottom: none;">
    <table class="table mb-0">
      <thead class="table-light">
        <tr>
          <th class="col-2"> Income Type</th>
          <th class="col-2"> Amount </th>
          <th class="col-2"> Pay Period </th>
          <th class="col-2"> Hrs/Week </th>
          <th class="col-2"> Monthly </th>
          <th class="col-1 text-center"> Action </th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor='let income of effectiveIncomes; let index = index;' style='height: 50px;'>

          <td class="col-2">
            <select id="incomeType_{{income.incomeId}}" name="incomeType_{{income.incomeId}}" class="form-select"
              [(ngModel)]="income.typeOfIncome">
              <option [ngValue]="null"></option>
              <option *ngFor="let type of nonEmploymentIncomeTypes" [ngValue]="type.value">
                {{ type.name }}
              </option>
            </select>
          </td>
          <td class="col-2">
            <currency-input name="incomeAmount_{{income.incomeId}}" [(ngModel)]="income.amount"
              (ngModelChange)="onAmountChanged(income)">
            </currency-input>
          </td>
          <td class="col-2">
            <select id="incomePayPeriod_{{income.incomeId}}" name="incomePayPeriod_{{income.incomeId}}"
              class="form-select" [(ngModel)]="income.payPeriod" (ngModelChange)="onAmountChanged(income)">
              <option [ngValue]="null"></option>
              <option *ngFor="let period of incomePayPeriods" [ngValue]="period.value">
                {{ period.name }}
              </option>
            </select>
          </td>
          <td class="col-2">
            <input id="hoursPerWeek_{{income.incomeId}}" name="hoursPerWeek_{{income.incomeId}}" numeric
              [allowNegative]="false" type="text" class="form-control" [(ngModel)]="income.hoursPerWeek"
              [readonly]="income.payPeriod !== PayPeriod.Hourly" [required]="income.payPeriod === PayPeriod.Hourly"
              (ngModelChange)="onAmountChanged(income)" />
          </td>
          <td class="col-2">
            <currency-input name="incomeMonthly_{{income.incomeId}}" [(ngModel)]="income.monthlyIncome"
              [readonly]="true">
            </currency-input>
          </td>
          <td class="col-1 text-center">
            <a href='javascript: void(0);' (click)='deleteIncomeClicked(index)'><i
                class="fa fa-trash-alt mx-2"></i></a>
          </td>
        </tr>
        <tr>
          <td class="col-2">
          </td>
          <td class="col-2">
          </td>
          <td class="col-1">
          </td>
          <td class="col-1 text-end">
            <b>Total</b>
          </td>
          <td class="col-1">
            <b><span class="ps-2"> {{totalMonthlyIncome | currency}} / Monthly </span></b>
          </td>
          <td class="col-1">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
