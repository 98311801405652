import { AlertType } from "./alert-type.enum";

export class CustomAlert {
  title: string;
  message: string;
  type: AlertType = AlertType.Info;

  constructor(title: string, message: string, type: AlertType) {
    this.title = title;
    this.message = message;
    this.type = type;
  }
}
