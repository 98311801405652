import { Component, Input, OnInit } from '@angular/core';
import { LosDisclosureInfo } from '../../../request-models/los-disclosure-gen-job';

@Component({
  selector: 'encompass-disclosure-parties',
  templateUrl: './encompass-disclosure-parties.component.html',
  styleUrls: ['./encompass-disclosure-parties.component.scss']
})
export class EncompassDisclosurePartiesComponent implements OnInit {

  @Input() info: LosDisclosureInfo;

  columns: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.columns = [
      { field: 'fullName', header: 'Name' },
      { field: 'emailAddress', header: 'Email' },
      { field: 'secondaryEmailAddress', header: 'Secondary Email' },
      { field: 'homePhoneNumber', header: 'Home Phone' },
      { field: 'cellPhoneNumber', header: 'Mobile Phone' },
      { field: 'businessPhoneNumber', header: 'Business Phone' },
    ];
  }

}
