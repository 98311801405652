<form #upsertKeyDateGroupForm="ngForm" ngbAutofocus class="row">
    <div class="p-3">
        <div class="mb-3">
            <label class="control-label">Group Name</label>
            <input class="form-control"
                [ngClass]="{ 'is-invalid' : keyDateGroupName && keyDateGroupName.touched && keyDateGroupName.invalid }"
                name="keyDateGroupName" [(ngModel)]="keyDateGroup.keyDateGroupName" #keyDateGroupName="ngModel"
                required />
            <div class="invalid-feedback">Group name is required.</div>
        </div>
        <div class="mb-3" *ngIf="!editMode">
            <label class="control-label">Column</label>
            <select class="form-control" [ngClass]="{ 'is-invalid' : column && column.touched && column.invalid }"
                name="column" [(ngModel)]="keyDateGroup.column" #column="ngModel" required>
                <option value="">-- Select One --</option>
                <option value="1">1</option>
                <option value="2">2</option>
            </select>
            <div class="invalid-feedback">Column is required.</div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
                Close
            </button>
            <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" [disabled]="saving">
                <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
                Save
            </button>
        </div>
    </div>
</form>