<div class="card">
  <div class="card-header">
    <h4 class="card-title"> Fulfillment </h4>
    <!-- <button class="btn btn-secondary"> Print Documents and Manually Fulfill </button> -->
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-8">
        <div class="d-flex align-items-center">
          <label class="col-sm-3 control-label" for="fulfilledby"> Fulfilled by </label>
          <div class="col-sm-4">
            <input class="form-control" id="fulfilledby" name="fulfilledby" [(ngModel)]="fulfilledBy" readonly />
          </div>
        </div>
      </div>
      <div class="col-md-8 mt-2">
        <div class="d-flex align-items-center">
          <label class="col-sm-3 control-label" for="dateTimeFulfilled"> Date/Time Fulfilled </label>
          <div class="col-sm-4">
            <date-input [id]="'dateTimeFulfilled'" [name]="'dateTimeFulfilled'"
              [(ngModel)]="disclosureTrackingItem.manuallyFulfilledDate" readonly="true"></date-input>
          </div>
        </div>
      </div>
      <div class="col-md-8 mt-2">
        <div class="d-flex align-items-center">
          <label class="col-sm-3 control-label" for="fulfillmentMethod"> Fulfillment Method </label>
          <div class="col-sm-4">
            <input class="form-control" id="fulfillmentMethod" name="fulfillmentMethod"
              [(ngModel)]="disclosureTrackingItem.fulfillmentMethod" readonly />
          </div>
        </div>
      </div>
      <div class="col-md-8 mt-2">
        <div class="d-flex align-items-center">
          <label class="col-sm-3 control-label" for="manualFulfillmentPresumedReceivedDate"> Presumed Received Date
          </label>
          <div class="col-sm-4">
            <input class="form-control" id="manualFulfillmentPresumedReceivedDate"
              name="manualFulfillmentPresumedReceivedDate"
              [(ngModel)]="disclosureTrackingItem.manualFulfillmentPresumedReceivedDate" readonly />
          </div>
        </div>
      </div>
      <div class="col-md-8 mt-2">
        <div class="d-flex align-items-center">
          <label class="col-sm-3 control-label" for="manualFulfillmentActualReceivedDate"> Actual Received Date </label>
          <div class="col-sm-4">
            <input class="form-control" id="manualFulfillmentActualReceivedDate"
              name="manualFulfillmentActualReceivedDate"
              [(ngModel)]="disclosureTrackingItem.manualFulfillmentActualReceivedDate" readonly />
          </div>
        </div>
      </div>
      <!-- <div class="col-md-8 mt-2">
        <div class="d-flex align-items-center">
          <label class="col-sm-3 control-label" for="comments"> Comments </label>
          <div class="col-sm-6">
            <textarea id="comments" name="comments" class="form-control mb-2" rows="4"
              [(ngModel)]="disclosureTrackingItem.disclosureComments" style="width: 400px;" readonly>
                    </textarea>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>