import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LoanDoc, DocumentType, DocFile, Borrower, Role } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { DndDropEvent } from 'ngx-drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Constants } from 'src/app/services/constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'src/app/services/file.service';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import * as _ from 'lodash';
import { DocFilesEditDialogComponent } from 'src/app/modules/loan-docs/components/doc-files-edit-dialog/doc-files-edit-dialog.component';
import { Utils } from 'src/app/core/services/utils';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { TaskService } from 'src/app/services/task.service';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { MergeDocFilesRequest } from 'src/app/modules/loan-docs/models/merge-files/merge-doc-files-request.model';

@Component({
  selector: 'loan-docs-table-view',
  templateUrl: 'loan-docs-table-view.component.html',
  styleUrls: ['loan-docs-table-view.component.scss'],
})

export class LoanDocsTableViewComponent implements OnChanges, OnInit {

  @ViewChild(ContextMenuComponent)
  basicMenu: ContextMenuComponent;

  @Output()
  loanDocUpdated: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  taskUpdated: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  uploadFilesClicked: EventEmitter<LoanDocUploadFile> = new EventEmitter<LoanDocUploadFile>();

  @Input()
  set loanDocTasks(loanDocTasks: LoanDocTask[]) {
    this._loanDocTasks = loanDocTasks;
  }

  @Input()
  set loanDocs(loanDocs: LoanDoc[]) {
    this._loanDocs = loanDocs;
  }

  @Input()
  set filteredDocumentTypes(filteredDocumentTypes: DocumentType[]) {
    this._filteredDocumentTypes = filteredDocumentTypes;
  }

  get loanDocTasks(): LoanDocTask[] {
    return this._loanDocTasks;
  }

  get loanDocs(): LoanDoc[] {
    return this._loanDocs;
  }

  get filteredDocumentTypes(): DocumentType[] {
    return this._filteredDocumentTypes;
  }

  @Input()
  borrowers: Borrower[] = [];

  @Input()
  usersAll: User[] = [];

  @Input()
  roles: Role[] = [];

  @Input()
  appId: number;

  @Input()
  userId: string;

  @Input()
  showESignTaskOption: boolean;

  @Input()
  isDocumentSigningRequiredReview: boolean;

  @Input()
  loading: boolean;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  globalFilterFields: any;

  filteredDocTypes: DocumentType[] = [];
  filteredDocs: LoanDoc[] = [];

  modalOptions: NgbModalOptions;

  protected openForEdit: boolean;
  protected docCountByDocTypeId: Map<number, number>;
  protected docFileBeingDragged: DocFile;
  protected nodes: TableNode[];
  protected isNodeChecked: boolean;

  protected isAvailableDocFilesForMerge: boolean;
  protected selectedDocFiles: DocFile[] = [];
  protected isRenderingTableNodes: boolean;

  protected draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  private _filteredDocumentTypes: DocumentType[] = [];
  private _loanDocs: LoanDoc[] = [];
  private _loanDocTasks: LoanDocTask[] = [];

  constructor(
    private readonly zone: NgZone,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _fileService: FileService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _taskService: TaskService,
  ) {
    this.modalOptions = {
      windowClass: 'modal-full-width',
      backdrop: 'static',
      centered: true,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const loanDocTasksChange = changes.loanDocTasks;
    const loanDocsChange = changes.loanDocs;
    const filteredDocumentTypesChange = changes.filteredDocumentTypes;

    if (loanDocTasksChange || loanDocsChange || filteredDocumentTypesChange) {
      this.resetTableNodes();
      this.setInitialFileNameForEdit();
    }
  }

  ngOnInit() {
    if (this.nodes == null) {
      this.resetTableNodes();
    }
  }

  getfilteredDocsLength = (id: number) => {
    return this.docCountByDocTypeId.get(id) || 0;
  }

  onDragStart(event: DragEvent) {
    const docFileId = (event.currentTarget as any).docFileId;
    this.draggable.data = docFileId;
  }

  onDrop(event: DndDropEvent | any) {
    if (event.isExternal) {
      const files: File[] = event.event.dataTransfer.files;
      const docTypeId: number = event.event.currentTarget.documentTypeId;
      const fileToUpload: LoanDocUploadFile = { documentTypeId: docTypeId, files: files };
      this.uploadFilesClicked.emit(fileToUpload);
      return;
    }

    const documentTypeId = Number((event.event.currentTarget as any).documentTypeId);
    if (documentTypeId) {
      this.onCreateNewLoanDoc(documentTypeId, Number(this.draggable.data));
      return;
    }

    const loanDocId = Number((event.event.currentTarget as any).loanDocId);
    if (loanDocId) {
      this.onMoveToLoanDocByDragging(loanDocId, Number(this.draggable.data));
    } else {
      const docFileId = Number((event.event.currentTarget as any).docFileId);
      if (docFileId) {
        this.onMergeFileByDragging(docFileId);
      }
    }
  }

  onCreateNewLoanDoc = (documentTypeId: number, docFileIdToCreated: number) => {
    const filteredLoanDocsByDocumentTypeId = this._loanDocs.filter(d => d.documentTypeId === documentTypeId);
    const existingLoanDocs: LoanDoc[] = [];
    filteredLoanDocsByDocumentTypeId.forEach(f => {
      f.docFiles.forEach(d => {
        if (d.docFileId === docFileIdToCreated) {
          existingLoanDocs.push(f);
        }
      })
    })

    const existingSingularLoanDoc = existingLoanDocs.some(e => {
      return e.docFiles.length == 1;
    })

    if (existingSingularLoanDoc) {
      console.log("Don't add!");
      return;
    }

    this._spinner.show();
    const docFiles = this._loanDocs?.map(loanDoc => loanDoc.docFiles).flat();
    const docFileToBeCreated = docFiles.find(f => f.docFileId === docFileIdToCreated);
    // const copyOfDocFile = _.cloneDeep(docFileToBeCreated);
    const newLoanDoc = new LoanDoc(this.appId);
    newLoanDoc.documentTypeId = documentTypeId;
    this._loanDocsService.upsertLoanDoc(newLoanDoc).subscribe({
      next: (result: LoanDoc) => {
        docFileToBeCreated.loanDocId = result.loanDocId;
        this._loanDocsService.upsertFile(docFileToBeCreated).subscribe({
          next: (result: DocFile) => {
            this.clearLoanDocsWithoutDocFile();
            this._notifyService.showSuccess('Successfully moved document!', 'Success');
            this.loanDocUpdated.emit();
          },
          error: (err) => {
            this._notifyService.showError(err.message || err.error || 'Error while moving document!', 'Failure');
          }
        }).add(() => this._spinner.hide());
      },
      error: (err) => {
        this._notifyService.showError(err.message || err.error || 'Error while moving document!', 'Failure');
      }
    }).add(() => this._spinner.hide());
  }

  onViewFileClicked = (docFile: DocFile) => {
    if (!docFile.guid) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(docFile.file);
      reader.onload = (event) => {
        const blob = new Blob([reader.result], { type: docFile.file.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      };
      return;
    }

    this._spinner.show();
    this._loanDocsService.viewLoanDocContent(docFile.guid).subscribe({
      next: data => {
        const blob = new Blob([data], { type: data['type'] });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (error) => { }
    }).add(() => this._spinner.hide());
  }

  onMultipleDownloadClicked = () => {
    this.selectedDocFiles.forEach(file => {
      file.selectedForAction = false;
      this.onDownloadDocumentClicked(file);
    });

    this.selectedDocFiles = [];
  }

  onDownloadDocumentClicked = (file: DocFile) => {
    this._spinner.show();
    this._loanDocsService.getLoanDocContent(file.guid).subscribe({
      next: (blobData) => {
        const mimeType = file.mimeType;
        const blob = new Blob([blobData], { type: mimeType });
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        let fileName = file.fileName;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this._notifyService.showSuccess('Document successfully downloaded', 'Success!');
      },
      error: () => {
        this._notifyService.showError('Document could not be saved', 'Error!');
      }
    }).add(() => this._spinner.hide());
  }

  onDeleteClicked = (files: DocFile[]) => {
    this._spinner.show();

    this._fileService.removeFiles(files).subscribe({
      next: () => {
        this.clearLoanDocsWithoutDocFile();
        this._spinner.hide();
        this._notifyService.showSuccess("Moved file to trash", "Success");
        this.selectedDocFiles = [];
        this.loanDocUpdated.emit();
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err ? (err.data ? err.data.message : 'Unable to move file to trash') : "Unable to move file to trash", 'Error');
      }
    });
  }

  onMultipleDeleteClicked = () => {
    this.onDeleteClicked(this.selectedDocFiles);
  }

  onSingleDeleteDocFileClicked = (file: DocFile) => {
    this.onDeleteClicked([file]);
  }

  onCancelClicked = () => {
    this.selectedDocFiles.forEach(file => {
      file.selectedForAction = false;
    })

    this.selectedDocFiles = [];
  }

  addNewMultipleTask = (condition: boolean, requestBorrower: boolean, eSignTask: boolean, docFiles: DocFile[]) => {
    this.addNewTask(condition, requestBorrower, eSignTask, docFiles, undefined)
  }

  addNewSingleTask = (condition: boolean, requestBorrower: boolean, eSignTask: boolean, docFile: DocFile, loanDoc: LoanDoc) => {
    this.addNewTask(condition, requestBorrower, eSignTask, [docFile], loanDoc)
  }

  addNewTask = (condition: boolean, requestBorrower: boolean, eSignTask: boolean, docFiles: DocFile[], loanDoc: LoanDoc) => {
    const loanDocument = loanDoc ? loanDoc : this._loanDocs.find(l => l.loanDocId === docFiles[0].loanDocId);

    let task = new LoanDocDashboardTask();

    task.documentTypeId = 0;
    task.applicationId = this.appId;
    task.description = '';
    task.note = '';
    task.loanDocTaskId = 0;
    task.taskType = 'RequestDocument';
    task.dueDays = 0;
    task.taskPriority = 'Normal';
    task.userId = this.userId;
    task.condition = condition;
    task.requestBorrower = requestBorrower;
    task.multipleBorrower = [];

    if (eSignTask) {
      task.taskType = 'EsignDocument';
      task.requiresReview = this.isDocumentSigningRequiredReview;
      if (task.requiresReview) {
        task.reviewPartyId = task.userId;
      }
    }
    task.docFiles = docFiles as any;
    task.loanDocId = loanDocument.loanDocId;
    if (eSignTask) {
      task.documentTypeId = loanDocument.documentTypeId;
      task.description = loanDocument.description;
      task.note = loanDocument.note;
    }

    let modalOptions: NgbModalOptions = {
      windowClass: 'modal-w-90',
      backdrop: 'static',
      centered: true,
    }

    const modalRef = this._modalService.open(EditTaskDialogComponent, modalOptions);
    modalRef.componentInstance.task = _.cloneDeep(task);
    modalRef.result.then(result => {
      if (result) {
        cleaningSelectedDocFileActions();
        this.taskUpdated.emit(result);
      }
    }, () => {
      // cleaningSelectedDocFileActions();
    },
    );

    const cleaningSelectedDocFileActions = () => {
      docFiles.forEach(file => {
        file.selectedForAction = false;
      })
      this.selectedDocFiles = [];
    }
  }

  getInsertedBy = (id: string) => {
    const findInserter = this.usersAll.find(u => u.userCompanyGuid == id);
    return Utils.getBorrowerFullName(findInserter);
  }

  onTaskStatusClicked = (task: LoanDocTask) => {
    this._spinner.show();
    this._taskService.getTaskDashboardViewById(task.loanDocTaskId).subscribe({
      next: (response) => {
        const modalOptions = _.cloneDeep(Constants.modalOptions.large);
        if (task.applicationId) {
          modalOptions.windowClass = 'modal-w-90';
        }
        const modalRef = this._modalService.open(EditTaskDialogComponent, modalOptions);
        modalRef.componentInstance.task = response;
        modalRef.componentInstance.borrowers = this.borrowers;

        modalRef.result.then((result) => {
          if (result) {
            this.taskUpdated.emit();
            // this._loanDocsService.sendEventDocumentUploaded();
          }
        }, (isRefreshed: boolean) => {
          if (isRefreshed) {
            this.taskUpdated.emit();
          }
        });
      },
      error: () => {

      }
    }).add(() => this._spinner.hide());
  }

  onModifyPagesClicked = (file: DocFile) => {
    this.cleanupUiSpecificFieldsOnFileBeforeSave(file);

    const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
    const modalRef = this._modalService.open(DocFilesEditDialogComponent, this.modalOptions)
    modalRef.componentInstance.title = 'Document File Editor';
    modalRef.componentInstance.file = file;
    modalRef.componentInstance.fileExtension = extension;
    modalRef.componentInstance.appId = this.appId;
    modalRef.componentInstance.loanDocs = this._loanDocs;
    modalRef.componentInstance.globalConfig = this.globalConfig;
    modalRef.result.then((result) => {

    }, (res) => {
    });
  }


  onMergeFilesClicked = () => {
    const docGuids = this.selectedDocFiles.map(x => x.guid);
    this._spinner.show();
    this._loanDocsService.setMergeFiles(this.appId, this.selectedDocFiles[0].loanDocId, docGuids)
      .subscribe({
        next: (response: any) => {
          this.clearLoanDocsWithoutDocFile();
          this._spinner.hide();
          this._notifyService.showSuccess(
            'Successfully merged documents!',
            'Success'
          );
          this.selectedDocFiles = [];
          this.loanDocUpdated.emit();
        },
        error: (err) => {
          this._spinner.hide();
          this._notifyService.showError(err.message || err || 'Error while merging documents!', 'Failure');
        }
      });
  };

  onMergeFileByDragging = (targetDocFileId: number) => {
    const docFiles = this._loanDocs?.map(loanDoc => loanDoc.docFiles).flat();
    const targetDocFile = docFiles.find(file => file.docFileId === targetDocFileId);
    const sourceDocFile = docFiles.find(dF => dF.docFileId === Number(this.draggable.data));

    if (sourceDocFile.documentTypeId !== -1) {
      if (sourceDocFile.docFileId === targetDocFileId) {
        this._notifyService.showInfo("The document cannot be merged with itself!", 'Info');
        return;
      }
      this._spinner.show();
      this._loanDocsService.setMergeFiles(this.appId, targetDocFile.loanDocId, [targetDocFile.guid, sourceDocFile.guid])
        .subscribe({
          next: (result: any) => {
            this.clearLoanDocsWithoutDocFile();
            this._spinner.hide();
            this._notifyService.showSuccess(
              'Successfully merged documents!',
              'Success'
            );
            this.loanDocUpdated.emit();
          },
          error: (err) => {
            this._spinner.hide();
            this._notifyService.showError(err.message || err || 'Error while merging documents!', 'Failure');
          }
        });
    }
  }

  onDragover(event: DragEvent) {
    const source = event.currentTarget as any;
    if (source.documentTypeId && source.documentTypeId !== '-1') {
      const nodeToExpand = this.nodes.find(n => n.data.documentTypeId === Number(source.documentTypeId));
      if (nodeToExpand) {
        nodeToExpand.expanded = true;
      }
    }
  }

  onMoveToLoanDocByDragging = (targetLoanDocId: number, docFileIdToBeMoved: number) => {
    const docFiles = this._loanDocs?.map(loanDoc => loanDoc.docFiles).flat();
    const docFileToBeDeletedFromLoanDocAndAddToAnotherLoanDoc = docFiles.find(f => f.docFileId === docFileIdToBeMoved);

    if (targetLoanDocId === docFileToBeDeletedFromLoanDocAndAddToAnotherLoanDoc.loanDocId) {
      this._notifyService.showInfo("Already in the current loan document!", 'Info');
      return;
    }
    docFileToBeDeletedFromLoanDocAndAddToAnotherLoanDoc.loanDocId = targetLoanDocId;

    docFiles.forEach(f => this.cleanupUiSpecificFieldsOnFileBeforeSave(f));

    this._spinner.show();
    this._loanDocsService.upsertFile(docFileToBeDeletedFromLoanDocAndAddToAnotherLoanDoc).subscribe({
      next: (result) => {
        if (result) {
          this.clearLoanDocsWithoutDocFile();
          this._spinner.hide();
          this._notifyService.showSuccess('Successfully moved document!', 'Success');
          this.loanDocUpdated.emit();
        }
      },
      error: (err) => {
        this._spinner.hide();
        this._notifyService.showError(err.message || err.error || 'Error while merging loan document!', 'Failure');
      }
    });
  }

  onNodeChecked = (docFile: DocFile) => {
    if (!docFile.selectedForAction) {
      this.selectedDocFiles.push(docFile);
    } else {
      const index = this.selectedDocFiles.findIndex(d => d.docFileId === docFile.docFileId);
      if (index >= 0) {
        this.selectedDocFiles.splice(index, 1);
      }
    }
    this.isAvailableDocFilesForMerge = this.checkIfDocFilesAreAvailableForMerge();
  }

  onFileRenameClicked = (file: DocFile) => {
    file['shouldEdit'] = true;
  }

  onFileRenameConfirmed = (file: DocFile) => {
    this._spinner.show();
    const { fileName, extension } = Utils.getFileNameAndExtension(file['originalFileName']);
    file.fileName = file['fileNameWithoutExtension'] + "." + extension;
    file['shouldEdit'] = false;

    this.cleanupUiSpecificFieldsOnFileBeforeSave(file);
    let request = new MergeDocFilesRequest(null, null, file.guid, file.fileName, null, false);
    this._loanDocsService.mergeDocFiles(request, file.loanDocId).subscribe({
      next: () => {
        this._notifyService.showSuccess('Successfully saved document!', 'Success');
      },
      error: (err) => {
        this._notifyService.showError(err.message || err.error || 'Error while saving document!', 'Failure');
      }
    }).add(() => {
      this.selectedDocFiles?.forEach(file => {
        file.selectedForAction = false;
      });
      this.selectedDocFiles = [];
      this._spinner.hide();
      this.loanDocUpdated.emit();
    });
  }

  onFileRenameCancelled = (file: DocFile) => {
    const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
    file['fileNameWithoutExtension'] = fileName;
    file['shouldEdit'] = false;
  }

  getBorrowerNameForLoanDocsPlaceHolder = (borrowerId: number) => {
    const borrowersNameToBeDisplayed = this.borrowers.find(b => b.borrowerId === borrowerId);
    return Utils.getBorrowerDisplayName(borrowersNameToBeDisplayed);
  }

  private cleanupUiSpecificFieldsOnFileBeforeSave = (file: DocFile) => {
    delete file['shouldEdit'];
    delete file['originalFileName'];
    delete file['fileNameWithoutExtension'];
  }

  private checkIfDocFilesAreAvailableForMerge(): boolean {
    const checkedDocFiles = this.selectedDocFiles.filter(d => d.selectedForAction);
    if (checkedDocFiles.length > 0) {
      const sampleLoanDocId = this.selectedDocFiles[0].loanDocId;
      const hasDifferentLoanDocId = this.selectedDocFiles.every(d => d.loanDocId === sampleLoanDocId);
      return hasDifferentLoanDocId;
    }
    return true;
  }

  private resetTableNodes(): void {
    this.isRenderingTableNodes = true;
    const documentTypes = this.filteredDocumentTypes || [];
    const loanDocs = this.loanDocs || [];

    const previousRootNodesById = new Map<number, TableNode>(
      (this.nodes ?? []).map((node) => [
        node.data.documentTypeId,
        node,
      ]),
    );

    const docTypeNodesById = new Map<number, TableNode>(
      documentTypes.map((docType) => {
        const previousNode = previousRootNodesById.get(docType.documentTypeId);

        return [
          docType.documentTypeId,
          {
            label: docType.documentTypeName,
            data: docType,
            children: [],
            expanded: previousNode?.expanded,
          },
        ];
      },
      ),
    );

    const nodes = new Set<TableNode>();

    loanDocs.forEach((loanDoc) => {
      if (loanDoc.docFiles.length == 0) {
        return;
      }

      this.loanDocTasks.forEach(task => {
        (loanDoc as any).task = this.getLoanDocTask(loanDoc, task);
      });

      const docTypeNode = docTypeNodesById.get(loanDoc.documentTypeId);
      if (docTypeNode == null) {
        // console.error(`Cannot find document type with id ${loanDoc.documentTypeId}`);
        return;
      }

      nodes.add(docTypeNode);
      docTypeNode.children!.push({
        type: 'loanDoc',
        data: loanDoc,
      });

      loanDoc.docFiles.forEach((docFile) => {
        (docFile as any).linked = this.getLoanDocLinked(loanDoc);
      });
    });

    this.isRenderingTableNodes = false;
    this.nodes = Array.from(nodes);

    const orderedNodes = _.orderBy(this.nodes, [
      node => (node.label === "General" ? 0 : 1),
      node => (node.label === "Trash" ? Infinity : 1),
    ]);

    this.nodes = orderedNodes;

    // Calculate the doc file counts
    this.docCountByDocTypeId = this.nodes.reduce((map, node) => {
      const documentTypeId = (node.data as DocumentType).documentTypeId;
      const totalDocFiles = node.children!.reduce((sum, loanDocNode: TableNode) =>
        sum + (loanDocNode.data as LoanDoc).docFiles.length,
        0
      );

      map.set(documentTypeId, totalDocFiles);

      return map;
    }, new Map<number, number>());
  }

  private clearLoanDocsWithoutDocFile = () => {
    this._loanDocs.forEach(loanDoc => {
      if (loanDoc.docFiles?.length === 0) {
        this._loanDocsService.deleteLoanDoc(loanDoc.loanDocId).subscribe();
      }
    });
  }

  private getLoanDocLinked = (loanDoc: LoanDoc) => {
    if (loanDoc.loanDocId && loanDoc.losLoanDocId) {
      return 'Loda/LOS';
    } else if (loanDoc.loanDocId) {
      return 'Loda';
    } else if (loanDoc.losLoanDocId) {
      return 'LOS';
    } else {
      return '';
    }
  }

  private getLoanDocTask = (loanDoc: LoanDoc, task: LoanDocTask): LoanDocTask => {
    if (task.loanDocId === loanDoc.loanDocId) {
      return task;
    }
  }

  private setInitialFileNameForEdit = () => {
    const docFiles = this._loanDocs?.map(loanDoc => loanDoc.docFiles).flat();
    docFiles.forEach(file => {
      if (file.fileName) {
        file['shouldEdit'] = false;
        const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
        file['originalFileName'] = file.fileName;
        file['fileNameWithoutExtension'] = fileName;
      } else {
        file['originalFileName'] = '';
      }
    })
  }
}

interface TableNode {
  type?: 'loanDoc';
  children?: TableNode[];
  data: DocumentType | LoanDoc;
  label?: string;
  expanded?: boolean;
}

export interface LoanDocUploadFile {
  documentTypeId: number;
  files: File[];
}