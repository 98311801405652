export class InternalContactHistoryItemViewModel {
    actionValue: string;
    insertedBy: string;
    dateInserted: string;

    constructor(actionValue: string, insertedBy: string, dateInserted: string) {
        this.actionValue = actionValue;
        this.insertedBy = insertedBy;
        this.dateInserted = dateInserted;
    }
}