import { Attachment } from "src/app/models/attachment.model";
import { MessageDelivery } from "./alert.model";
import { MessageDeliveryEnum, MessagePriorityEnum, MessageStatusEnum, MessageTypeEnum } from "./message";
import { VoiceDisposition } from "../modules/reports/pipeline-metrics/models/pipeline-metrics.model";

//TODO - message and message history item below look very similar, but they are separate view models on the server, so they have been created as separate models fr now.
export class Message {
  id: number;
  campaignId: number;
  automatedEmailId: number;
  applicationId: number;
  borrowerId: number;
  agentId: number;
  eventId: number;
  leadId: number;
  contactCampaignId: number;
  campaignActionHistoryId: number;
  fromUserId: string;
  serviceModelId: number;
  rvmFileId: number;
  rvmFileIdType: RvmFileIdTypeEnum;
  msgType: MessageTypeEnum;
  delivery: MessageDelivery;
  priority: MessagePriorityEnum;
  status: MessageStatusEnum;
  to: string;
  from: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  attachmentGuids: string;
  imageAttachments: Attachment[] = [];
  parameters: ContactHttpParameter[] = [];
  campaignName: string;
  trackingGuid: string;
  originalOpenDate: Date;
  mostRecentOpenDate: Date;
  retryCount: number;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum RvmFileIdTypeEnum {
  UserVoiceMail = "UserVoiceMail",
  CampaignVoicemailAction = "CampaignVoicemailAction"
}

export class ContactHttpParameter {
  type: ContactHttpParameterTypeEnum;
  name: string;
  value: string;
}

export enum ContactHttpParameterTypeEnum {
  GetOrPost = "GetOrPost",
  UrlSegment = "UrlSegment",
  HttpHeader = "HttpHeader",
  RequestBody = "RequestBody",
  QueryString = "QueryString",
  QueryStringWithoutEncode = "QueryStringWithoutEncode"
}
export class MessageHistoryItem {
  extendedDetail: string;
  id: number;
  campaignId: number;
  automatedEmailId: number;
  applicationId: number;
  borrowerId: number;
  agentId: number;
  eventId: number;
  leadId: number;
  campaignActionHistoryId: number;
  fromUserId: string;
  serviceModelId: number;
  rvmFileIdType?: RvmFileIdTypeEnum;
  msgType: string;
  delivery: MessageDeliveryEnum;
  priority: string;
  status: string;
  to: string;
  from: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  attachmentGuids: string;
  imageAttachments: Attachment[]
  currentEmailStatus: string;
  replyDate: string;
  campaignName: string;
  trackingGuid: string;
  originalOpenDate: Date;
  mostRecentOpenDate: Date;
  retryCount: number;
  companyId: number;
  insertedBy: string;
  dateSent: Date;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
  clickDate: Date;
  messageModelId: number;
  errorDetail:string;
  parameters?: {
    type: string;
    name: string;
    value: string;
  }[]
}

export class VoiceMessageHistoryItem {
  id: number;
  startDate: string;
  endDate: string;
  disposition: VoiceDisposition;
  isRecorded: boolean;
  from: string;
  to: string;
}