import { Component, Input, OnInit } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DocPrep } from '../../models/doc-prep.model';

@Component({
  selector: 'document-preparation-deed',
  templateUrl: 'document-preparation-deed.component.html',
  styleUrls: ['./document-preparation-deed.component.scss']
})

export class DocumentPreparationDeedComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  @Input()
  deedTypes: EnumerationItem[] = [];

  constructor() { }

  ngOnInit() { }
}