import {Injectable} from '@angular/core';
import {DataService} from '../core/services/data.service';
import {Observable} from 'rxjs';
import {AmortizationModel} from '../modules/amortization/models/amortization.model';

@Injectable()
export class AmortizationService {

  constructor(private readonly _dataService: DataService) { }

  getAmortizationInfo = (mortgageId: number, initialRateChange: number, subsequentRateChange: number, subsequentRateChangeFrequency: number )
    : Observable<AmortizationModel> => {
    let url = `api/pricing/tpo-amortization?mortgageId=${mortgageId}`;
    if (initialRateChange) {
      url += `&initialRateChange=${initialRateChange}`;
    }
    if (subsequentRateChange) {
      url += `&subsequentRateChange=${subsequentRateChange}`;
    }
    if (subsequentRateChangeFrequency) {
      url += `&subsequentRateChangeFrequency=${subsequentRateChangeFrequency}`;
    }

    return this._dataService.get(url);
  }
}
