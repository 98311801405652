// service-model/telephony
export class TelephonyService {
  telephonyServiceId: number = null;
  userId: string = null;
  branchId: number = null;
  name: string = null;
  serviceType: string = null;
  fromPhoneNumber: string = null;
  accountIdentifier: string = null;
  authToken: string = null;
  apiKeySid: string = null;
  apiKeySecret: string = null;
  isGlobal: boolean = null;
  isUserPrimaryNumber: boolean = null;
  disableAllOutOfTheBoxCommunication: boolean = null;
  disableVoiceRecording: boolean = null;
  forwardCallToUserId: string = null;
  forwardCallToPhoneNumber: string = null;
  incomingCallInitialTextGreeting: string = null;
  incomingCallConnectingMessage: string = null;
  incomingCallAudioFileId: number = null;
  incomingCallPromptForVM: boolean = null;
  voiceLeadRouteGroupId: number = null;
  incomingSmsLeadRouteGroupId: number = null;
  longitude: number = null;
  latitude: number = null;
  city: string = null;
  state: string = null;
  postalCode: string = null;
  pools: string[] = [];
  voicemailEnabled: boolean = null;
  voicemailAudoFileId: number = null;
  voicemailMessage: string = null;
  routingTimeInSecondsBeforeVoicemail: number = null;
  voicemailNotifyUserIds: string[] = null;
}
