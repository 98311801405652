import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@microsoft/signalr";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { WireRequestModel } from "../models/wire-request.model";

@Injectable()
export class WireRequestService {
  constructor(
    private readonly _dataService: DataService
  ) { }

  getWireRequestData = (applicationId: number): Observable<WireRequestModel> => {
    const url = `api/wire-request/${applicationId}`;
    return this._dataService.get(url);
  }

  exportCsv = (applicationId: number, wireRequest: WireRequestModel) => {
    const url = `api/wire-request/export-csv/${applicationId}`;
    return this._dataService.downloadBinaryFileWithPost(url, wireRequest);
  }

  exportExcel = (applicationId: number, wireRequest: WireRequestModel) => {
    const url = `api/wire-request/export-excel/${applicationId}`;
    return this._dataService.downloadBinaryFileWithPost(url, wireRequest);
  }
}