import { Component, Input, OnInit } from '@angular/core';
import { RegulationZDetail } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'atr-qm-recommendation',
  templateUrl: 'atr-qm-recommendation.component.html',
  styleUrls: ['./atr-qm-recommendation.component.scss']
})

export class AtrQmRecommendationComponent implements OnInit {

  @Input()
  regulationZDetail: RegulationZDetail;

  @Input()
  abilityToRepayMethodTypes: EnumerationItem[];

  @Input()
  qualifiedMortgageTypes: EnumerationItem[];

  @Input()
  presumptionOfComplianceTypes: EnumerationItem;

  constructor() { }

  ngOnInit() { }
}