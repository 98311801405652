<!-- m prefixes used to avoid name conflicts with global css -->

<div class='m-header'>
  <h4 class='m-0'>Proposed Monthly Payment</h4>
</div>

<div *ngLet='form.controls as controls' class='m-table'>
  <div class='table-header'>
    <div class='m-col'>Parameters</div>
    <div class='m-col'>Factor</div>
    <div class='m-col'>Value</div>
    <div class='m-col'>Monthly</div>
  </div>

  <div *ngFor='let payment of controls.payments.controls' class='table-row'>
    <ng-container *ngLet='payment.controls as controls'>
      <div
        *ngLet='controls.name.value as name'
        [title]='name'
        class='m-col'
      >
        <span class='text-ellipsis'>{{ name }}</span>
      </div>

      <div class='m-col highlighted-if-empty'>
        <ng-container *ngIf='controls.factorDescription; else nonDescription'>
          <ng-container
            *ngLet='controls.factorDescription as factorDescription'>
            <input
              (blur)='emitSaveIfFocusValueChanged(factorDescription)'
              (focusin)='saveFocusValue(factorDescription)'
              [formControl]='factorDescription'
              class='form-control'
              placeholder='Description'
              type='text' />
          </ng-container>
        </ng-container>

        <ng-template #nonDescription>
          <div
            *ngIf='controls.factor?.controls as controls'
            class='d-flex justify-content-center gap-2'
          >
            <!-- Calculate Button -->
            <button
              (click)='onCalculate?.value?.()'
              *ngLet='controls.onCalculate as onCalculate'
              [style.visibility]='onCalculate != null && onCalculate.enabled ? "visible" : "hidden"'
              class='btn btn-link p-0'
              title='Calculate'
            >
              <i class='fa fa-calculator'></i>
            </button>

            <!-- Pay period dropdown -->
            <ng-container *ngLet='controls.payPeriod as payPeriod'>
              <select
                (focusin)='saveFocusValue(payPeriod)'
                [formControl]='payPeriod'
                class='form-select w-auto'>
                <option
                  *ngFor='let payPeriod of payPeriodOptions'
                  [ngValue]='payPeriod.value'>
                  {{ payPeriod.name }}
                </option>
              </select>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <div class='m-col highlighted-if-empty'>
        <ng-container *ngIf='controls.value as value'>
          <ng-container *ngIf='!value.disabled; else templateReadOnlyValue'>
            <currency-input
              (blur)='emitSaveIfFocusValueChanged(value)'
              (focusin)='saveFocusValue(value)'
              (ngModelChange)='value.setValue($event)'
              [ngModelOptions]='{standalone: true}'
              [ngModel]='value.value'></currency-input>
          </ng-container>

          <ng-template #templateReadOnlyValue>
            <span class='highlighted-content'>{{ value.value | currency }}</span>
          </ng-template>
        </ng-container>
      </div>
      <div class='m-col highlighted'>{{ controls.monthly.value | currency }}</div>
    </ng-container>
  </div>
</div>

<div class='m-footer'>
  <span>Total</span>
  <span>{{ form.controls.total.value | currency }}</span>
</div>
