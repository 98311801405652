import { NgModule } from '@angular/core';
import { ExternalContactsV2Component } from './components/external-contacts-v2/external-contacts-v2.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableModule } from 'primeng/table';
import { RemainContactModule } from '../remain-contact/remain-contact.module';
import { AgentsModule } from '../agents/agents.module';
import { BasicInformationComponent } from './components/basic-information/basic-information.component';
import { CorrespondenceModule } from '../correspondence/correspondence.module';

@NgModule({
  declarations: [
    ExternalContactsV2Component,
    BasicInformationComponent
  ],
  imports: [
    RemainContactModule,
    SharedModule,
    TableModule,
    AgentsModule,
    CorrespondenceModule
  ],
  exports: [
    ExternalContactsV2Component,
    BasicInformationComponent
  ],
})
export class ExternalContactsV2Module {
  constructor() { }
}
