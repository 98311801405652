import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { DataService } from "../core/services/data.service";
import { CustomFieldSection } from "../modules/pricing/models/pricing/custom-field-section.model";
import { CustomField } from "../modules/pricing/models/pricing/custom-fields.model";
import { BaseRequest } from "../modules/pricing/models/pricing/base-request.model";

@Injectable()
export class PricingCustomFieldSectionService {

    constructor(private readonly _dataService: DataService) {
    }

    getCustomFieldSections = (vendor: string): Observable<CustomFieldSection[]> => {
        let url = `api/admin/pricing/custom-fields/sections/${vendor}`;
        return this._dataService.get(url);
    }

    insertCustomField = (CustomFieldSection: CustomFieldSection): Observable<CustomFieldSection> => {
        let url = 'api/admin/pricing/custom-fields/sections';
        return this._dataService.post(url, CustomFieldSection)
    }

    updateCustomField = (CustomFieldSection: CustomFieldSection): Observable<CustomFieldSection> => {
        let url = `api/admin/pricing/custom-fields/sections/${CustomFieldSection.pricingCustomFieldSectionId}`;
        return this._dataService.post(url, CustomFieldSection)
    }

    deleteCustomField = (id: number): Observable<void> => {
        let url = `api/admin/pricing/custom-fields/sections/${id}`;
        return this._dataService.delete(url)
    }

    getCustomFields = (vendor: string, request: BaseRequest): Observable<CustomField[]> => {
        let url = `api/pricing/vendor/${vendor}/custom-fields/v2`;
        return this._dataService.post(url, request)
    }
}