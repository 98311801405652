import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Lender } from 'src/app/models/config/lender.model';

@Component({
  selector: 'lender-info-dialog',
  templateUrl: './lender-info-dialog.component.html',
  styleUrls: ['./lender-info-dialog.component.scss']
})
export class LenderInfoDialogComponent implements OnInit {

  @Input()
  lenderInfo: Lender;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(){
  }

}
