import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationRequest } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'delete-app-password-dialog',
  templateUrl: 'delete-app-password-dialog.component.html'
})
export class DeleteAppPasswordDialogComponent implements OnInit {

  @ViewChild('deleteAppPasswordsDialogForm')
  deleteAppPasswordsDialogForm: NgForm;

  @Input()
  currentlyLoggedInUsername: string;

  @Input()
  clientId: string;

  password: string;

  constructor(public activeModal: NgbActiveModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _authService: AuthService,
  ) { }

  ngOnInit() { }

  revoke() {
    this.deleteAppPasswordsDialogForm.form.markAllAsTouched();
    if (!this.deleteAppPasswordsDialogForm.form.valid) return;

    this._spinnerService.show();
    const loginInfo = new AuthenticationRequest();
    loginInfo.username = this.currentlyLoggedInUsername;
    loginInfo.password = this.password;
    this._authService.signIn(loginInfo).subscribe({
      next: (result) => {
        if (result?.loginStatus === 'Error') {
          this._notifyService.showError("Password doesn't match.", "Error");
          return;
        }

        this._authService.revokeSecret(this.clientId).subscribe({
          next: () => {
           this.activeModal.close(true);
          },
          error: (err) => {
            this._notifyService.showError(err?.message || "Couldn't delete app password", "Error");
          }
        }).add(() => this._spinnerService.hide())
      },
      error: () => {
        this._notifyService.showError("Password doesn't match.", "Error");
      }
    }).add(() => this._spinnerService.hide())
  }
}