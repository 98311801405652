<div style="margin: 20px; height: 380px;">
  <div class="mb-3" style="max-width: 200px">
    <label class="form-label" for="user-role">Action</label>
    <select name="do-action" class="form-select standard" [(ngModel)]="action">
      <option [ngValue]="null">-- Select One --</option>
      <option value="get-tpo-loan">Get TPO Loan</option>
      <option value="get-tpo-loan-data">Get TPO Loan Data</option>
      <option value="get-loan-summary">Get TPO Loan Summary</option>
    </select>
  </div>
  <button type="button" class="btn btn-primary mr-1" (click)="runAction()"
    style="margin-right: 6px; margin-bottom: 3px">
    Run
  </button>
  <textarea style="height: 360px" class="form-control" rows="10" [(ngModel)]="output" readonly></textarea>
</div>
