<div class="modal-header">
  <h2 class="modal-title">{{isReprice ? 'Reprice' : 'Program Change'}} Request</h2>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped align-items-center align-middle mb-0">
          <thead>
            <tr>
              <th></th>
              <th>Current Lock</th>
              <th>Lock After {{isReprice ? 'Reprice' : 'Program Change'}}</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td><strong>Confirmed</strong></td>
              <td>{{currentApplication?.productPricing?.assignDate | date: 'MM/dd/yyyy h:mm a'}}</td>
              <td>{{currentApplication?.productPricing?.assignDate | date: 'MM/dd/yyyy h:mm a'}}</td>
            </tr>
            <tr >
              <td><strong>Product</strong></td>
              <td>{{currentApplication?.productPricing?.productName}}</td>
              <td>{{selection?.product?.product?.productName}}</td>
            </tr>
            <tr >
              <td><strong>Loan Term (months)</strong></td>
              <td>{{currentApplication?.productPricing?.term}}</td>
              <td>{{selection?.product?.product?.loanTerm}}</td>
            </tr>
            <tr >
              <td><strong>Note Rate</strong></td>
              <td>{{(currentApplication?.productPricing?.rate / 100) | percent:'1.3-3'}}</td>
              <td>{{(selection?.price?.rate / 100) | percent:'1.3-3'}}</td>
            </tr>
            <tr >
              <td><strong>Final Price</strong></td>
              <td>{{currentApplication?.productPricing?.price | number: '1.3-3'}}</td>
              <td>{{data?.price | number: '1.3-3'}}</td>
            </tr>
            <tr >
              <td><strong>Credit/Cost</strong></td>
              <td>
                <span class="text-muted {{currentApplication?.productPricing?.price >= 100 ? 'price-rebate': 'price-discount'}}">{{discountRebatePercent | number:'0.3-3'}} ({{(currentApplication?.productPricing?.discountRebateDollars || 0) | currency}})</span>
              </td>
              <td>
                <span *ngIf="selection.price.price < 100" class="text-muted price-discount">
                  {{selection?.price?.discountPercent | number:'0.3-3'}} ({{selection?.price.discountDollars | currency}})
                </span>
                <span *ngIf="selection.price.price >= 100" class="text-muted price-rebate">
                  {{selection?.price?.rebatePercent | number:'0.3-3'}} ({{selection?.price?.rebateDollars | currency}})
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 text-center">
        <p class="text-alert"><strong>{{ data.autoLockConfirmed ? '**This reprice will be auto approved.' : '**This reprice will be approved by secondary.' }}</strong></p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 text-center">
        <p class="text-alert">Are you sure you want to request a {{isReprice ? 'reprice' : 'program change'}} with these changes?</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Cancel
  </button>
  <button type="button" class="btn btn-primary pull-right ng-scope" (click)="activeModal.close()"
    style="margin-left: 5px;">
    Confirm
  </button>
</div>
