<div style="max-width: 759px; margin: auto;">
  <table id="personal-info" width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr style="border-bottom: 2px solid #246cea;">
      <td style="display: block;text-align: center;">
        <div class="info" style="padding-left: 14px;">
          <img style="width: 80px;height: 80px;border-radius: 50%;margin-right: 10px;" [src]="imageProfile"
            alt="Profile Image" (error)="onProfileImageDownloadFailed()">
          <div class="personal-info">
            <span *ngIf="appId" style="font-size: 0.9375rem;font-weight: bolder;">
              {{user ? (user.firstName + ' ' + user.lastName) : '-'}}</span>
            <span *ngIf="!appId" style="font-size: 0.9375rem;font-weight: bolder;">
              {{loggedInUser ? (loggedInUser.userProfile.firstName + ' ' +
              loggedInUser.userProfile.lastName) : '-'}}</span>
            <br>
            <span *ngIf="appId" style="font-size: 16px;">NMLS {{user ? user.nmls : ' - '}}, License Number:
              {{licenseNo ? licenseNo : '-'}}</span>
            <span *ngIf="!appId" style="font-size: 16px;">NMLS {{loggedInUser ? loggedInUser.userProfile.nmls : ' -
              '}}, License Number: - </span>
            <br>
            <span *ngIf="appId" style="font-size: 16px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 24 24">
                <path
                  d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
              </svg> <a [href]="userCellPhone ? 'tel:' + userCellPhone : null"
                style="color: #35aaef; margin-left: 0.25rem;">{{userCellPhone ? (userCellPhone | phone) :
                '-'}}</a></span>
            <span *ngIf="!appId" style="font-size: 16px;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 24 24">
                <path
                  d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
              </svg><a [href]="loggedInUserCellPhone ? 'tel:' + loggedInUserCellPhone : null"
                style="color: #35aaef; margin-left: 0.25rem;">{{loggedInUserCellPhone ? (loggedInUserCellPhone |
                phone) : '-'}}</a></span>
            <br>
            <span *ngIf="appId" style="font-size: 16px;"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                width="16" height="16" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-mail icon">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </svg> <a style="color: #35aaef; margin-left: 0.25rem;"
                [href]="userEmail ? 'mailto:' + userEmail : null">{{userEmail ?? '-'}}</a></span>
            <span *ngIf="!appId" style="font-size: 16px;"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" width="16" height="16" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-mail icon">
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </svg> <a style="color: #35aaef; margin-left: 0.25rem;"
                [href]="loggedInUserEmail ? 'mailto:' + loggedInUserEmail : null">{{loggedInUserEmail ??
                '-'}}</a></span>
          </div>
        </div>
      </td>
      <td style="width: 21rem; width: auto;display: block;text-align: center; padding: 10px 0;">
        <div>
          <span class="worksheet-title"
            style="color: #1761fd; font-size: 1.5rem; vertical-align: -webkit-baseline-middle; font-weight: bold;">
            INITIAL FEES WORKSHEET</span>
          <div *ngIf="forBorrowers" style="text-align: center;">
            for
            <ul style="  display: inline;list-style-type: none;padding: 0;margin: 0;">
              <li *ngFor="let borrower of forBorrowers" style="display: inline;color: #303e67;">
                <b>{{borrower}}</b>
              </li>
            </ul>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <div style="text-align: center;margin-top: 0.5rem;">
          <span style="font-size: 0.9375rem;font-weight: bolder;">
            Your actual rate, payment and costs could be higher. Get an official Loan Estimate before choosing a loan.
          </span>
        </div>
      </td>
    </tr>
  </table>
  <div style="padding: 1rem;">
    <span style="display: flex; justify-content: space-between;font-size: 14px;">
      <span> Quote Number: <span style="color: #f5325c;"> TBD </span> </span>
      <span> Preparation Date: {{currentDate | date: 'MMM d, y h:mm:ss a'}} </span>
    </span>
    <table id="table-values" width="100%" style="border: 1px solid #eaf0f9;font-size: 14px;">
      <tr>
        <td style="padding: 1rem 1rem 0rem 1rem;">
          <span> Loan Purpose: </span>
          <span style="font-weight: bolder;">
            {{loanPurpose ? loanPurpose : '-'}}
          </span>
        </td>
        <td *ngIf="loanPurpose === 'Purchase'" style="padding: 1rem 1rem 0rem 1rem;">
          <span> Purchase Price: </span>
          <span style="font-weight: bolder;">
            {{purchasePrice ? (purchasePrice | currency) : '-'}}
          </span>
        </td>
        <td *ngIf="loanPurpose === 'Refinance'" style="padding: 1rem 1rem 0rem 1rem;">
          <span> Appraised Value: </span>
          <span style="font-weight: bolder;">
            {{appraisedValue ? (appraisedValue | currency) : '-'}}
          </span>
        </td>
        <td style="padding: 1rem 1rem 0rem 1rem;">
          <span> Loan Amount: </span>
          <span style="font-weight: bolder;">
            {{loanAmount ? (loanAmount | currency) : '-'}}
            <span> (Total: {{totalLoanAmount && totalLoanAmount != loanAmount ? (totalLoanAmount
              | currency) : '-'}}) </span>
          </span>
        </td>
      </tr>
      <tr>
        <td style="padding: 0rem 1rem;">
          <span> Property Type: </span>
          <span style="font-weight: bolder;">
            {{propertyType ? propertyType : '-'}}
          </span>
        </td>
        <td style="padding: 0rem 1rem;">
          <span> Occupancy: </span>
          <span style="font-weight: bolder;">
            {{occupancy ? occupancy : '-'}}
          </span>
        </td>
        <td style="padding: 0rem 1rem;">
          <span> No. of Units: </span>
          <span style="font-weight: bolder;">
            {{noOfUnits ? noOfUnits : '-'}}
          </span>
        </td>
      </tr>
      <tr>
        <td style="padding: 0rem 1rem;">
          <span> Estimated FICO: </span>
          <span style="font-weight: bolder;">
            {{fico ? fico : '-'}}
          </span>
        </td>
        <td style="padding: 0rem 1rem;">
          <span> ZIP / State: </span>
          <span style="font-weight: bolder;">
            {{zipCode ? zipCode : '-'}} / {{state ? state : '-'}}
          </span>
        </td>
        <td style="padding: 0rem 1rem;">
          <span> Escrow: </span>
          <span style="font-weight: bolder;">
            {{waiveEscrows ? 'Waived' : 'Not Waived'}}
          </span>
        </td>
      </tr>
      <tr>
        <td id="productName" style="padding: 0rem 1rem 1rem 1rem;">
          <span> Product: </span>
          <span style="font-weight: bolder;">
            {{productName ? productName : '-'}}
          </span>
        </td>
        <td style="padding: 0rem 1rem 1rem 1rem;">
          <span> Rate / APR: </span>
          <span style="font-weight: bolder;">
            {{rate ? ((rate / 100) | percent:'1.3-3'): '-'}} / {{apr ? ((apr / 100) |
            percent:'1.3-3') :
            '-'}}
          </span>
        </td>
        <td style="padding: 0rem 1rem 1rem 1rem;">
          <span> Lock Period: </span>
          <span style="font-weight: bolder;">
            {{lockTerm ? lockTerm + ' Days' : '-'}}
          </span>
        </td>
      </tr>
    </table>
    <table id="card-table" width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr>
        <td class="table-column" style="vertical-align: top;">
          <table id="originationFeesCard" class="sub-card-table" width="100%" border="0" cellspacing="0"
            cellpadding="10"
            style="border-collapse: collapse; margin-top: 16px;box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);border-radius: 6px;font-size: 14px;">
            <tr style="border-bottom: 1px solid #e3ebf6;height: 47px;">
              <td class="sub-table-td" colspan="2" style="text-align: left;">
                <h4 style="text-transform: capitalize;letter-spacing: .02em;margin: 0;color: #7081b9;
                  text-shadow: 0 0 1px rgba(241, 245, 250, .1);">
                  Origination Fees
                  <span style="float: right; color: #1761fd;">{{signedCurrencyToString(totalOriginationFees)}}</span>
                </h4>
              </td>
            </tr>
            <tr *ngFor="let originationFee of originationFees">
              <td>{{originationFee.name ? getFeeNameWithFeePercent(originationFee) : '-'}}</td>
              <td style="text-align: right;">{{ getBorrowerTotalAsString(originationFee) }}</td>
            </tr>
            <tr *ngIf="originationFees.length == 0">
              <td colspan="2">-</td>
            </tr>
          </table>
        </td>
        <td class="table-column" style="vertical-align: top;">
          <table id="taxesAndOtherGovernmentCard" class="sub-card-table" width="100%" border="0" cellspacing="0"
            cellpadding="10"
            style="border-collapse: collapse; margin-top: 16px;box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);border-radius: 6px;font-size: 14px;">
            <tr style="border-bottom: 1px solid #e3ebf6;height: 47px;">
              <td class="sub-table-td" colspan="2" style="text-align: left;">
                <h4 style="text-transform: capitalize;letter-spacing: .02em;margin: 0;color: #7081b9;
                  text-shadow: 0 0 1px rgba(241, 245, 250, .1);">
                  Taxes and Other Government Fees
                  <span style="float: right; color: #1761fd;">{{totalOtherAndGovernmentFees | currency}}</span>
                </h4>
              </td>
            </tr>
            <tr *ngFor="let item of governmentTaxesAndFees">
              <td>{{item.name ? getFeeNameWithFeePercent(item) : '-'}}</td>
              <td style="text-align: right;">{{ item.calculatedValues.borrowerTotal | currency }}</td>
            </tr>
            <tr *ngIf="governmentTaxesAndFees.length == 0">
              <td colspan="2">-</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="table-column" style="vertical-align: top;">
          <table id="thirdPartyFeesCard" class="sub-card-table" width="100%" border="0" cellspacing="0" cellpadding="10"
            style="border-collapse: collapse; margin-top: 16px;box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);border-radius: 6px;font-size: 14px;">
            <tr style="border-bottom: 1px solid #e3ebf6;height: 47px;">
              <td class="sub-table-td" colspan="2" style="text-align: left;">
                <h4 style="text-transform: capitalize;letter-spacing: .02em;margin: 0;color: #7081b9;
                  text-shadow: 0 0 1px rgba(241, 245, 250, .1);">
                  Other Fees
                  <span style="float: right; color: #1761fd;">{{totalOtherFees | currency}}</span>
                </h4>
              </td>
            </tr>
            <tr>
              <td style="padding: 0.75rem; overflow: auto;">
                <div *ngFor='let item of otherFees'>
                  <span style="display: inline-block; width: 50%;"> {{item.name ? getFeeNameWithFeePercent(item) :
                    '-'}}
                  </span>
                  <span style="display: inline-block; width: 50%;text-align: right;"> {{
                    item.calculatedValues.borrowerTotal |
                    currency }} </span>
                </div>
                <div *ngIf="otherFees.length == 0" style="display: block;">
                  <span style="display: inline-block; width: 100%;"> - </span>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td class="table-column" style="vertical-align: top;">
          <table id="prepaidAndInitialCard" class="sub-card-table" width="100%" border="0" cellspacing="0"
            cellpadding="10"
            style="border-collapse: collapse; margin-top: 16px;box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);border-radius: 6px;font-size: 14px;">
            <tr style="border-bottom: 1px solid #e3ebf6;height: 47px;">
              <td class="sub-table-td" style="text-align: left;font-size: 14px;">
                <h4 style="text-transform: capitalize;letter-spacing: .02em;margin: 0;color: #7081b9;
                  text-shadow: 0 0 1px rgba(241, 245, 250, .1);"> Prepaids and Initial
                  Escrow Payment at Closing
                  <span style="float: right; color: #1761fd;"> {{totalPrepaidsAndEscrow | currency}} </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td style="overflow: auto;">
                <span> Prepaids </span>
                <div *ngFor="let prepaid of prepaids" style="margin-top: 5px;">
                  <span style="margin-left: 20px;"> {{prepaid.name ? getFeeNameWithFeePercent(prepaid) : '-'}} </span>
                  <span style="float: right;"> {{ prepaid.calculatedValues.borrowerTotal | currency }} </span>
                </div>
                <div *ngIf="prepaids.length == 0" style="margin-top: 5px;">
                  <span style="margin-left: 20px;"> - </span>
                </div>
                <hr style="border-color: #dee2e6; margin-top: 10px; margin-bottom: 10px;">
                <div style="margin-top: 10px;">
                  <span> Initial Escrow Payment at Closing </span>
                </div>
                <div *ngFor="let esc of escrow" style="margin-top: 5px;">
                  <span style="margin-left: 20px;"> {{esc.name ? getFeeNameWithFeePercent(esc) : '-'}} </span>
                  <span style="float: right;"> {{ esc.calculatedValues.borrowerTotal | currency }} </span>
                </div>
                <div *ngIf="escrow.length == 0" style="margin-top: 5px;">
                  <span style="margin-left: 20px;"> - </span>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="overflow: auto;">
        <td class="table-column" style="vertical-align: top;">
          <table class="sub-card-table" width="100%" border="0" cellspacing="0" cellpadding="10"
            style="border-collapse: collapse; margin-top: 16px;box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);border-radius: 6px;font-size: 14px;">
            <tr style="border-bottom: 1px solid #e3ebf6;height: 47px;">
              <td class="sub-table-td" colspan="2" style="text-align: left;">
                <h4 style="text-transform: capitalize;letter-spacing: .02em;
                font-size: 14px;margin: 0;color: #7081b9;
                text-shadow: 0 0 1px rgba(241, 245, 250, .1);">
                  Estimated Proposed Monthly Housing Expense
                </h4>
              </td>
            </tr>
            <tr *ngIf="appId">
              <td>First Mortgage P&I</td>
              <td style="text-align: right;">{{scenario ? (scenario.principalAndInterest | currency) :
                mortgage && mortgage.proposedHousingExpense ?
                (mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest | currency) : '-'}}</td>
            </tr>
            <tr *ngIf="!appId">
              <td>Monthly P&I</td>
              <td style="text-align: right;">{{scenario.principalAndInterest ?
                (scenario.principalAndInterest | currency) : '-'}}</td>
            </tr>
            <tr *ngIf="appId">
              <td>Other Financing P&I</td>
              <td style="text-align: right;">{{mortgage && mortgage.proposedHousingExpense ?
                (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest | currency) : '-'}}</td>
            </tr>
            <tr *ngIf="!appId">
              <td>Taxes and Insurance</td>
              <td style="text-align: right;">{{scenario.productSearchRequest &&
                scenario.productSearchRequest.loanInformation ?
                (scenario.productSearchRequest.loanInformation.taxesAndInsuranceMonthly | currency) : '-'}}</td>
            </tr>
            <tr>
              <td>Homeowner’s Insurance</td>
              <td *ngIf="appId" style="text-align: right;"> {{mortgage && mortgage.proposedHousingExpense
                ?
                (mortgage.proposedHousingExpense.homeownersInsurance | currency) : '-'}} </td>
              <td *ngIf="!appId" style="text-align: right;"> {{scenario.monthlyInsurance ?
                (scenario.monthlyInsurance |
                currency) :
                '-'}} </td>
            </tr>
            <tr>
              <td>Property Taxes</td>
              <td *ngIf="appId" style="text-align: right;"> {{mortgage &&
                mortgage.proposedHousingExpense ?
                (mortgage.proposedHousingExpense.realEstateTax | currency) : '-'}} </td>
              <td *ngIf="!appId" style="text-align: right;"> {{scenario.monthlyTaxes ?
                (scenario.monthlyTaxes |
                currency) :
                '-'}} </td>
            </tr>
            <tr>
              <td>Mortgage Insurance</td>
              <td *ngIf="appId" style="text-align: right;"> {{mortgage && mortgage.proposedHousingExpense
                ?
                (mortgage.proposedHousingExpense.mortgageInsurance | currency) : '-'}} </td>
              <td *ngIf="!appId" style="text-align: right;"> {{scenario.monthlyMi ?
                (scenario.monthlyMi | currency) : '-'}} </td>
            </tr>
            <tr>
              <td>HOA Dues</td>
              <td *ngIf="appId" style="text-align: right;"> {{mortgage && mortgage.proposedHousingExpense
                ?
                (mortgage.proposedHousingExpense.homeownersAssociationDues | currency) : '-'}} </td>
              <td *ngIf="!appId" style="text-align: right;"> {{scenario.monthlyHoa ?
                (scenario.monthlyHoa | currency) : '-'}} </td>
            </tr>
            <tr style="border-bottom: 1px solid #e3ebf6; height: 47px;">
              <td colspan="2" style="text-align: left;font-size: 14px;">
                <h4 style="text-transform: uppercase; margin: 0; color: #1761fd;">
                  TOTAL APPROXIMATED MONTHLY PAYMENT
                  <span style="float: right; color: #1761fd;">{{(totalApproxMonthlyPayment ? totalApproxMonthlyPayment
                    :
                    0) | currency}}</span>
                </h4>
              </td>
            </tr>
          </table>
        </td>
        <td class="table-column" style="vertical-align: top;">
          <table class="sub-card-table" width="100%" border="0" cellspacing="0" cellpadding="10"
            style="border-collapse: collapse; margin-top: 16px; box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15); border-radius: 6px;font-size: 14px;">
            <tr style="border-bottom: 1px solid #e3ebf6; height: 47px;">
              <td colspan="2" style="text-align: left;">
                <h4
                  style="text-transform: capitalize; letter-spacing: .02em;margin: 0; color: #7081b9; text-shadow: 0 0 1px rgba(241, 245, 250, .1);">
                  Estimated Funds to Close
                </h4>
              </td>
            </tr>
            <tr>
              <td>{{ isRefinance ? 'Loan Amount' : 'Downpayment/Funds from Borrower' }}</td>
              <td style="text-align: right;">{{downpaymentFunds | currency}}</td>
            </tr>
            <tr *ngIf="totalSubordinateLiens">
              <td>Other New Mortgage Loans on Subject Property</td>
              <td style="text-align: right;">{{-totalSubordinateLiens | currency}}</td>
            </tr>
            <tr *ngIf="improvementsRenovationsAndRepairs">
              <td>Improvements, Renovations and Repairs</td>
              <td style="text-align: right;">{{improvementsRenovationsAndRepairs | currency}}</td>
            </tr>
            <tr>
              <td>Origination Fees</td>
              <td style="text-align: right;">{{signedCurrencyToString(totalOriginationFees)}}</td>
            </tr>
            <tr>
              <td>Third Party Fees</td>
              <td style="text-align: right;">{{totalOtherFees | currency}}</td>
            </tr>
            <tr>
              <td>Taxes and Other Government Fees</td>
              <td style="text-align: right;">{{totalOtherAndGovernmentFees | currency}}</td>
            </tr>
            <tr>
              <td>Prepaids and Initial Escrow</td>
              <td style="text-align: right;">{{totalPrepaidsAndEscrow | currency}}</td>
            </tr>
            <tr *ngIf="nonFinancedPmiMipFundingFee">
              <td>UFMIP / Funding Fee (Excluding Financed Amount)</td>
              <td style="text-align: right;">{{nonFinancedPmiMipFundingFee | currency}}</td>
            </tr>
            <tr *ngIf="estimatedTotalPayOffs">
              <td>Estimated Total Payoffs</td>
              <td style="text-align: right;">{{(estimatedTotalPayOffs ? estimatedTotalPayOffs : 0) |
                currency}}</td>
            </tr>
            <tr *ngIf="isRefinance">
              <td>For Refinance: Balance of Mtg. Loans on the Prop. to be paid off</td>
              <td style="text-align: right;">{{(refiMortgagePayoffs ? refiMortgagePayoffs : 0) |
                currency}}</td>
            </tr>
            <tr>
              <td colspan="2" style="border-bottom: 1px dashed #d3ddeb;"></td>
            </tr>
            <tr>
              <td><span style="font-weight: bolder;">Funds Due from Borrower (A)</span></td>
              <td style="text-align: right;"><span style="font-weight: bolder;">{{totalFundsDueFromBorr |
                  currency}}</span></td>
            </tr>
            <tr>
              <td colspan="2">
                <hr>
              </td>
            </tr>
            <tr>
              <td>Lender Credits</td>
              <td style="text-align: right;">{{(lenderCredits ? lenderCredits : 0) | currency}}</td>
            </tr>
            <tr>
              <td>Seller Credits</td>
              <td style="text-align: right;">{{(sellerCredits ? sellerCredits : 0) | currency}}</td>
            </tr>
            <tr>
              <td>Other Credits</td>
              <td style="text-align: right;">{{(otherCredits ? otherCredits : 0) | currency}}</td>
            </tr>
            <tr>
              <td colspan="2" style="border-bottom: 1px dashed #d3ddeb;"></td>
            </tr>
            <tr>
              <td><span style="font-weight: bolder;">Total Credits Applied (B)</span></td>
              <td style="text-align: right;"><span style="font-weight: bolder;">{{(totalCreditsApplied ?
                  totalCreditsApplied : 0) |
                  currency}}</span></td>
            </tr>
            <tr style="border-bottom: 1px solid #e3ebf6; height: 47px;font-size: 14px;">
              <td colspan="2" style="text-align: left;">
                <h4 style="text-transform: uppercase;margin: 0; color: #1761fd;">
                  ESTIMATED CASH FROM BORROWER (A-B)
                  <span style="float: right; color: #1761fd;">{{(estimatedCashFromBorr ? estimatedCashFromBorr : 0) |
                    currency}}</span>
                </h4>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin-top: 16px;">
      <tr>
        <td>
          <span style="font-size: smaller;">This estimate is provided for illustrative and
            informational purposes only based on the initial basic loan
            scenario provided. This is not a loan approval or commitment to lend.
            <span *ngIf="updatedDate"> Rates effective as of {{updatedDate | date: 'MM/dd/yyyy'}} and are subject to
              change.
            </span>
            <span *ngIf="!updatedDate"> Currently, there is no pricing information for the loan. </span>
            Annual Percentage Rate APR is an estimate based on criteria provided. Until you
            lock your rate, APR and terms are subject to change or may not be available at commitment or closing.
          </span>
        </td>
      </tr>
    </table>
    <table width="100%" border="0" cellspacing="0" cellpadding="0"
      style="border-top: 1px dashed #d3ddeb; margin-top: 16px;">
      <tr>
        <td style="width: 7%;padding-top: 16px;">
          <img src="{{imageLogo}}" alt="Company Logo" style="width: 105px;">
        </td>
        <td style="padding: 16px;">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td style="font-size: 18px; font-weight: bold;">
                {{entityName ? entityName : '-'}}
              </td>
            </tr>
            <tr>
              <td style="padding-top: 10px; font-size: 14px;">
                Company NMLS {{entityNmls ? entityNmls : '-'}}
              </td>
            </tr>
            <tr>
              <td style="padding-top: 10px; font-size: 14px;">
                <a href="{{companyUrl}}" style="color: #1761fd; text-decoration: none;">{{companyUrl}}</a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

  </div>