export class Instruction {
    documentName: string;
    documentTypeId: number;
    description: string;
    mergeWithExistingLocation: string;
    mergeWithExistingGuid: string;
    pages: string;
    loanDocTaskId: number;
    removePagesFromSource: boolean;
    filter: string = "newFile";
    note: string;
    fileName: string;

    constructor(documentName: string, documentTypeId: number, mergeWithExistingLocation: string, pages: string) {
        this.documentName = documentName;
        this.documentTypeId = documentTypeId;
        this.mergeWithExistingLocation = mergeWithExistingLocation;
        this.pages = pages;
    }
}