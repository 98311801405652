import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactListType } from 'src/app/models';
import { DrawerService } from 'src/app/shared/services/drawer.service';

@Component({
  selector: 'app-new-lead-assigned-dialog',
  templateUrl: './new-lead-assigned-dialog.component.html',
  styleUrls: ['./new-lead-assigned-dialog.component.scss']
})
export class NewLeadAssignedDialogComponent {

  @Input()
  leadModel: any;

  @Output()
  closeDialog: EventEmitter<never> = new EventEmitter<never>();

  warmTransferAudio = new Audio('assets/sound/WarmTransferAlert.mp3');

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _drawerService: DrawerService,
  ) {
  }

  openNewLeadDrawer() {
    this._drawerService.openDrawer({
      contactListType: ContactListType.Lead,
      recordId: this.leadModel.leadId
    })

    this.activeModal.close();
  }
}
