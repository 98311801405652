import { Component, Injector, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Branch } from "src/app/models";
import { LeadCampaign } from "src/app/models/config/global-config.model";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { IDateRange, ThisMonth } from "src/app/shared/components/date-range-filter/date-range-filter.component";
import { LeadFilters } from "../../../models/lead-filters.model";
import { LeadList } from "../../../models/lead-list.model";
import { LeadsService } from "../../../services/leads.service";

@Component({
  selector: 'lead-filter-dialog',
  templateUrl: './lead-filter-dialog.component.html',
  styleUrls: ['./lead-filter-dialog.component.scss']
})
export class LeadFilterDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  filters: LeadFilters = new LeadFilters();

  states: EnumerationItem[] = [];
  branches: Branch[] = [];
  users: any[] = [];

  leadLists: LeadList[] = [];
  leadCampaigns: LeadCampaign[] = [];

  constructor(
    private readonly _leadService: LeadsService,
    private readonly injector: Injector,
    public activeModal: NgbActiveModal
  ) {
    super(injector);
    this.filters.dateRange = new ThisMonth();
  }

  ngOnInit(): void {

    this.getAllLeadLists();

    this.applicationContextService.context.subscribe(context => {

      this.leadCampaigns = context.globalConfig.leadCampaigns;
      this.branches = context.globalConfig.branches;
      this.states = Object.keys(context.globalConfig.states)
        .map(key => new EnumerationItem(context.globalConfig.states[key], key));

      let users = context.globalConfig.accessibleUsersForLeads || [];
      let loggedInAdminUserId = context.currentlyLoggedInUser.userCompanyGuid;
      let adminUser = context.globalConfig.usersAll.filter(user => user.userCompanyGuid == loggedInAdminUserId)[0];

      if (adminUser && !users.some(el => el.userCompanyGuid == adminUser.userCompanyGuid)) {
        users.push({ userCompanyGuid: adminUser.userCompanyGuid, firstName: adminUser.firstName, lastName: adminUser.lastName, userType: adminUser.userType });
      }

      this.users = users
        .sort((a, b) => a.lastName.localeCompare(b.lastName)) // lastName asc
        .sort((a, b) => a.firstName.localeCompare(b.firstName)); // firstName asc

    });
  }

  onOkClicked = (): void => {
    this.activeModal.close({ filters: this.filters, filterString: this.filterToString() });
  }

  clearFilters = (): void => {
    this.filters = new LeadFilters();
  }

  dateRangeChanged = (selectedDateRange: IDateRange): void => {
    this.filters.dateRange = selectedDateRange;
  }

  filterToString = (): string => {
    let cloneFilters = JSON.parse(JSON.stringify(this.filters)) as LeadFilters; // deep clone

    if (cloneFilters.dateRange) {

      cloneFilters["Date"] = this.filters.dateRange.displayText;

      delete cloneFilters.dateRange;
    }

    if (cloneFilters.state) {
      const selected = this.states
        .filter(state => state.value == cloneFilters.state)[0];

      cloneFilters["State"] = selected.name;
      delete cloneFilters.state;
    }

    if (cloneFilters.branchId) {
      const selected = this.branches
        .filter(branch => branch.branchId == cloneFilters.branchId)[0];

      cloneFilters["Branch"] = selected.branchName;
      delete cloneFilters.branchId;
    }

    if (cloneFilters.campaignId) {
      const selected = this.leadCampaigns
        .filter(campaign => cloneFilters.campaignId.indexOf(campaign.leadCampaignId) > -1)[0];

      cloneFilters["Campaign"] = selected.name;
      delete cloneFilters.campaignId;
    }

    if (cloneFilters.showUserId) {
      const selected = this.users
        .filter(user => user.userCompanyGuid == cloneFilters.showUserId)[0];

      cloneFilters["User"] = selected.firstName + " " + selected.lastName;
      delete cloneFilters.showUserId;
    }

    if (cloneFilters.leadListId) {
      const selected = this.leadLists
        .filter(leadList => leadList.leadListId == cloneFilters.leadListId)[0];

      cloneFilters["Lead Tag"] = selected.name;
      delete cloneFilters.leadListId;
    }

    let selectedFilterItems = Object.keys(cloneFilters)
      .filter(key => cloneFilters[key]);

    if (selectedFilterItems.length == 0) {
      return null;
    }

    let str = selectedFilterItems.map(key => `<b>` + key + " = </b>" + '"' + cloneFilters[key] + '"')
      .join(`<b>` + " and " + `</b>`);

    return str;
  }

  private getAllLeadLists = () => {
    this._leadService.getLeadLists().subscribe(lists => {
      this.leadLists = lists.filter(l => l.name != null && l.name.length > 0).sort((a, b) => a.name.localeCompare(b.name)); // name asc
    });
  }
}
