<div class="card">
  <div class="card-header">
    <h4 class="card-title"><i class="fas fa-rocket me-2"></i>  Escalation History </h4>
  </div>
  <div class="card-body">
    <p-table #dt1 [value]="history" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25,50,100]"
      styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Actions</th>
          <th>Description</th>
          <th>Notes</th>
          <th>Status</th>
          <th>Request Date</th>
          <th>Decision By</th>
          <th>Decision Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-task>
        <tr>
          <td><center><a href="javascript: void(0);" (click)="viewDetailModal(task)">View Details</a></center></td>
          <td>{{task.description}}</td>
          <td>{{task.note}}</td>
          <td>{{task.taskStatus}}</td>
          <td>{{task.requestDate | date:'MM/dd/yyyy'}}</td>
          <td>{{task.nameOfDecisionBy}}</td>
          <td>{{task.dateInserted == task.dateUpdated ? "-" :
            task.dateUpdated | date:'MM/dd/yyyy' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="7" class="text-center">
            No records found
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>