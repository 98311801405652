<select
  class="form-select d-inline-block"
  [ngModel]="selectedRecord.statusId"
  (ngModelChange)="selectedRecord.statusId = $event; updateStatus()"
  (click)="populateAvailableStatuses()"
>
  <option value="">-- Select --</option>
  <option *ngFor="let status of availableStatuses" [ngValue]="status.statusId">
    {{ status.statusName }}
  </option>
</select>
