import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { ContactList, ContactListColumnDefinition, ContactListType } from '../models';
import { Utils } from '../core/services/utils';

@Injectable()
export class ContactListService {
  constructor(private readonly _dataService: DataService) { }

  getContactList = (): Observable<ContactList[]> => {
    return this._dataService.get('api/contact-list');
  }

  getContactListMapping = (contactListId: number, recordId: number, conflictResolutionOption: string) => {
    const url = 'api/contact-list/' + contactListId + '/record/' + recordId + '/map/' + conflictResolutionOption;
    return this._dataService.get(url);
  }

  getContactListById = (contactListId: number): Observable<ContactList> => {
    const url = `api/contact-list/${contactListId}`;
    return this._dataService.get(url);
  };

  getContactListsByType(type: ContactListType): Observable<ContactList[]> {
    return this.getContactList()
      .pipe(
        catchError((error) => of(error)),
        map((contactLists: ContactList[]) => {
          return contactLists.filter(contactList => contactList.contactListType === type);
        })
      );
  }

  /**
   * Merge columns from filtered selected contactlist with system columns
   */
  mergeContactListColumns = (contactList: ContactList[], filterFn: (contact: ContactList) => void): ContactListColumnDefinition[] => {
    const columns = contactList.find(filterFn)?.columns || [];
    const systemColumns = contactList.find(contact => contact.contactListType === ContactListType.System)?.columns || [];
    const mergedColumns = this.setContactListColumns(columns.concat(systemColumns));

    return mergedColumns;
  }

  /**
   * Format nameToShow based on column definition name
   */
  setContactListColumns(columns: ContactListColumnDefinition[]): ContactListColumnDefinition[] {
    if (!columns) return [];

    return columns.map(col => ({
      nameToShow: Utils.spinalTapCaseString(col.columnDefinition.name),
      ...col
    }));
  }
}
