import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { clone } from 'lodash';
import { finalize } from 'rxjs';
import { CreditVendorSelectOption } from 'src/app/models/aus/du-providers.model';
import { LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'desktop-underwriter',
  templateUrl: 'aus-du.component.html',
  styleUrls: ['aus-du.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class AusDuComponent implements OnInit, AfterViewInit {
  @ViewChild('duForm') duForm: NgForm | undefined;
  @Input() duParams: any;
  @Input() creditVendorList: Array<CreditVendorSelectOption>;
  @Input() isTPOUser: boolean;
  @Input() isLoanReadOnly: boolean = false;

  selectedCreditProviderHasCreds: boolean;
  creditCredsOverrideEnabled: boolean;
  duCredsOverrideEnabled: boolean;
  randomText = Math.random().toString(30);
  showPassword: boolean = false;
  showDuPassword: boolean = false;
  releasingCasefile: boolean;
  linkingCasefile: boolean;
  originalCasefileId: string;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.originalCasefileId = clone(this.duParams.duCasefileId);
    this.creditProviderChanged();
    this.duParams.duUsernameOverride = '';
    this.duParams.duPasswordOverride = '';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.duForm) {
        this.duForm.form.markAllAsTouched();
        const isValid = this.duForm.form.valid;
      }
    });
  }

  creditProviderChanged() {
    this.duParams.creditVendorUserName = '';
    this.duParams.creditVendorPassword = '';
    const matchingCreditVendor = this.creditVendorList.flatMap(vendor => vendor.options)
      .find(vendor => vendor.value == this.duParams.creditVendor);
    this.selectedCreditProviderHasCreds = !matchingCreditVendor
      ? false
      : matchingCreditVendor.userName != '' && matchingCreditVendor.password != '';
    this.creditCredsOverrideEnabled = !this.selectedCreditProviderHasCreds;
  }

  releaseCaseFile() {
    this.releasingCasefile = true;
    this._loanServicesService.releaseCasefile(this.duParams.duCasefileId)
      .pipe(finalize(() => this.releasingCasefile = false))
      .subscribe({
        next: (duHistory) => {
          this._notificationService.showSuccess('Casefile released successfully.', 'Release Casefile');
          this.activeModal.dismiss(duHistory);
        },
        error: (err) => {
          this._notificationService.showError(err?.message || 'Unable to release Casefile', 'Release Casefile');
        }
      });
  }

  linkCaseFile() {
    this.linkingCasefile = true;
    this._loanServicesService.linkCasefile(this.duParams.applicationId, this.duParams.duCasefileId)
      .pipe(finalize(() => this.linkingCasefile = false))
      .subscribe({
        next: (duHistory) => {
          this._notificationService.showSuccess('Casefile linked successfully.', 'Link Casefile');
          this.activeModal.dismiss(duHistory);
        },
        error: (err) => {
          this._notificationService.showError(err?.message || 'Unable to link Casefile', 'Link Casefile');
        }
      });
  }

  creditCredsOverrideClicked() {
    this.creditCredsOverrideEnabled = !this.creditCredsOverrideEnabled
    if (!this.creditCredsOverrideEnabled) {
      this.duParams.creditVendorUserName = '';
      this.duParams.creditVendorPassword = '';
      this.duParams.writeCreditCredsToUserProfile = false;
    } else {
      this.duParams.writeCreditCredsToUserProfile = true;
    }
  }

  duCredsOverrideClicked() {
    this.duCredsOverrideEnabled = !this.duCredsOverrideEnabled
    if (!this.duCredsOverrideEnabled) {
      this.duParams.writeAusCredsToUserProfile = false;
      this.duParams.duUsernameOverride = '';
      this.duParams.duPasswordOverride = '';
    } else {
      this.duParams.writeAusCredsToUserProfile = true;
    }
  }

  submitRequest() {
    if (this.duForm) {
      this.duForm.form.markAllAsTouched();

      if (!this.duForm.form.valid) {
        return;
      }
      this.activeModal.close(this.duParams);
    }
  }
}
