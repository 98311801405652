import { Component, EventEmitter, OnDestroy, OnInit, Output, Injector, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer, Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, LoanApplication, RelatedMortgage, TransactionDetail } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2';

@Component({
  selector: 'proposed-mortgage-info',
  templateUrl: './proposed-mortgage-info.component.html',
  styleUrls: ['./proposed-mortgage-info.component.scss']
})
export class ProposedMortgageInfoComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (!mortgage) {
      return;
    }
    this._mortgage = mortgage;
    this.initializeMortgageRelatedInfo();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  set mortgageCalculations(calcs: MortgageCalculationDetails) {
    this.ltv = calcs?.ltv?.ltv;
    this.cltv = calcs?.ltv?.cltv;
    this.totalLoanAmount = calcs?.ltv?.totalLoanAmount;
  }

  @Input()
  application: LoanApplication;

  isAppraisedValueHidden: boolean = false;
  isEstimatedValueHidden: boolean = false;
  loanPurposes: LoanPurpose[] = [];

  yesNoOptions: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  giftGrantSources: EnumerationItem[] = [];
  amortizationTypes: EnumerationItem[] = [];
  loanPurposeOptions: EnumerationItem[] = [];
  mortgageAppliedForTypes: EnumerationItem[] = [];

  isHeloc: boolean = false;

  totalHousingPayment: number = 0;

  showOtherReo: boolean = false;

  currentInterestRate: number | null = null;

  isRefinance: boolean = false;
  isHomesiteHack: boolean = false;

  mip: number = 0; // Where does this go on the mortgage??

  protected totalLoanAmount: number | null = 0;
  protected ltv: number | null = 0;
  protected cltv: number | null = 0;
  protected downPayment: number | null = 0;

  private _applicationContextSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  private _mortgage: UrlaMortgage;

  @Output()
  changedvalue: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly injector: Injector,
    private readonly _configurationService: ConfigurationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService,
    private readonly _notificationService: NotificationService) {
    super(injector);
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.yesNoOptions = this._enumsService.getYesNoEnumItems();
      this.lienPositionTypes = enums[Constants.enumerations.lienPositionType];
      this.giftGrantSources = enums[Constants.enumerations.giftGrantSource];
      this.amortizationTypes = enums[Constants.mortgageEnumerations.amortizationType];
      this.loanPurposeOptions = enums[Constants.enumerations.loanPurposes];
      this.mortgageAppliedForTypes = enums[Constants.mortgageEnumerations.mortgageAppliedForType];
    });
    this._configurationService.getCompanyConfiguration('LoanHiddenFields').subscribe(loanHiddenFields => {
      let hiddenFields = loanHiddenFields ? loanHiddenFields['valueStr'] : '';
      this.isAppraisedValueHidden = hiddenFields.indexOf('Appraised Value') > -1;
      this.isEstimatedValueHidden = hiddenFields.indexOf('Estimated Value') > -1;
    });
  }

  ngOnInit() {
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.initializeContextRelatedInfo(context);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();
  }

  onBaseLoanAmountChanged = () => {
    this.downPayment = this._calculationService.calculateDownPayment(this._mortgage);
    this.recalculateLtvAndRelatedValues();
  }

  onDownpaymentAmountChanged = () => {
    const homeValue = this._calculationService.calculateHomeValue(this._mortgage);
    if (homeValue > 0) {
      this._mortgage.mortgageTerm.amount = homeValue - this.downPayment;
    }
    this.recalculateLtvAndRelatedValues();
  }

  onInterestRateChanged = () => {
    this.recalculateLtvAndRelatedValues();
  }

  onMortgageTermChanged = () => {
    this.recalculateLtvAndRelatedValues();
  }

  onEstimatedValueOrPurchasePriceChanged = (e: any) => {
    if (!this.isRefinance) {
      const homeValue = this._calculationService.calculateHomeValue(this._mortgage);
      if (this._mortgage.mortgageTerm.amount > 0) {
        this.downPayment = homeValue - this._mortgage.mortgageTerm.amount;
      } else if (this.downPayment > 0) {
        this._mortgage.mortgageTerm.amount = homeValue - this.downPayment;
      }
    }
    this.recalculateLtvAndRelatedValues();
  }

  onAppraisedValueChanged = () => {
    this.recalculateLtvAndRelatedValues();
  }

  onLoanAmountChanged = () => {
    if (!this.isRefinance) {
      const homeValue = this._calculationService.calculateHomeValue(this._mortgage);
      if (homeValue > 0) {
        this.downPayment = homeValue - this._mortgage.mortgageTerm.amount;
      } else if (this.downPayment > 0) {
        if (this._mortgage.transactionDetail.purchasePriceAmount <= this._mortgage.subjectProperty.presentValue) {
          this._mortgage.transactionDetail.purchasePriceAmount = this.downPayment + this._mortgage.mortgageTerm.amount;
        } else {
          this._mortgage.subjectProperty.presentValue = this.downPayment + this._mortgage.mortgageTerm.amount;
        }
      }
    }
    this.recalculateLtvAndRelatedValues();
  }

  onMortgagePurposeOfLoanChanged = () => {
    if (this.mortgage.subjectProperty && this.mortgage.subjectProperty.purposeOfLoan) {
      const loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
      const loanPurposeRefiEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);

      let isPurchase = this.mortgage.subjectProperty.purposeOfLoan == loanPurposePurchaseEnumValue;
      let isRefi = this.mortgage.subjectProperty.purposeOfLoan == loanPurposeRefiEnumValue;
      if (!isPurchase) {
        this.resetValuesForNotPurchase();
      }
      if (!isRefi) {
        this.resetValuesForNotRefinance();
      }
      this._calculationService.calculateMortgageStatistics(this.mortgage);
    }
  }

  productTypeChanged = () => {
    if (!['FHA', 'VA', 'USDA'].includes(this.mortgage.mortgageTerm.mortgageAppliedFor)) {
      this.mortgage.governmentLoanDetail.sectionOfActType = null;
    }
  }

  calculateTotalHousingPayment = () => {
    setTimeout(() => {
      let subTotal = 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest) || 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.homeownersInsurance) || 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.realEstateTax) || 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.mortgageInsurance) || 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees) || 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest) || 0;
      subTotal += Number(this._mortgage.proposedHousingExpense.supplementalPropertyInsurance) || 0;

      this.totalHousingPayment = subTotal;
    }, 100);
  }

  deleteRelatedMortgage = (removeIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self.mortgage.relatedMortgages.indexOf(self.mortgage.relatedMortgages[removeIx]);
        if (index >= 0) {
          self.mortgage.relatedMortgages.splice(index, 1);
        }
      }
    });
  }

  addRelatedMortgage = () => {
    let relatedMortgage = new RelatedMortgage();
    relatedMortgage.relatedMortgageId = Utils.getUniqueId();
    this._mortgage.relatedMortgages.push(relatedMortgage);
  }

  private resetValuesForNotPurchase = () => {
    if (this.mortgage.transactionDetail) {
      this.mortgage.transactionDetail.purchasePriceAmount = null;
      this.mortgage.transactionDetail.sellerPaidClosingCostsAmount = null;
    }
  }

  private resetValuesForNotRefinance = () => {
    this.mortgage.subjectProperty.originalCostYear = null;
    this.mortgage.subjectProperty.originalCost = null;
    this.mortgage.subjectProperty.amountExistingLiens = null;
    this.mortgage.subjectProperty.improvementCost = null;
    this.mortgage.subjectProperty.refiPurpose = null;
    this.mortgage.subjectProperty.describeImprovement = null;
    this.mortgage.subjectProperty.improvementStatus = null;
    this.mortgage.calculatedStats.totalPaidOffForRefinance = 0;
  }

  private initializeContextRelatedInfo = (context: ApplicationContext) => {
    this.isHomesiteHack = context.userPermissions.companyId == 158;
    this.loanPurposes = context.globalConfig.loanPurpose;
  }

  private initializeMortgageRelatedInfo = () => {

    if (this._mortgage.mortgageTerm && this._mortgage.mortgageTerm.amount) {
      this._mortgage.mortgageTerm.amount = Math.floor(this._mortgage.mortgageTerm.amount);
    }
    this.isRefinance = this._calculationService.isPurposeOfLoanRefinance(this._mortgage);

    if (this._mortgage.mortgageTerm.interestRate) {
      this.currentInterestRate = this._mortgage.mortgageTerm.interestRate / 100;
    }

    this.showOtherReo = this._mortgage.relatedMortgages?.length > 0

    this.downPayment = this._calculationService.calculateDownPayment(this._mortgage);

    this.calculateTotalHousingPayment();

    if (!this._mortgage.transactionDetail) {
      this._mortgage.transactionDetail = new TransactionDetail();
    }
    if (!this._mortgage.transactionDetail.purchasePriceAmount) {
      this._mortgage.transactionDetail.purchasePriceAmount = 0;
    }
  }

  private recalculateLtvAndRelatedValues = () => {

    const observer: Observer<MortgageCalculationDetails> = {
      next: (result: MortgageCalculationDetails) => {
        this.totalLoanAmount = result.ltv.totalLoanAmount;
        this.ltv = result.ltv.ltv;
        this.cltv = result.ltv.cltv;

        this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest = 
          result.proposedExpenses.firstMortgagePrincipalAndInterest;
        this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
          result.proposedExpenses.otherMortgageLoanPrincipalAndInterest;

        if (this.currentInterestRate != null) {
          this._mortgage.mortgageTerm.interestRate = this.currentInterestRate * 100;
        }

        this.calculateTotalHousingPayment();
        this.changedvalue.emit();
      },
      error: (err: any) => {
        this._spinner.hide();
        this._notificationService.showError(
          err ? err.message : 'Unable to calculate LTV and related values.',
          'Error!',
        );
      },
      complete: () => {
        this._spinner.hide();
      }
    }
    this._spinner.show();
    this._mortgageService.redoMortgageCalculationDetails(this._mortgage).subscribe(observer);
  }
}
