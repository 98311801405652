import { Component, Injector, OnInit } from '@angular/core';
import { ApplicationContext, HighCostMortgageDetail, RegulationZDetail } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atr-qm-management',
  templateUrl: 'atr-qm-management.component.html',
  styleUrls: ['./atr-qm-management.component.scss']
})

export class AtrQmManagementComponent extends ApplicationContextBoundComponent implements OnInit {

  mortgage: UrlaMortgage;

  protected qualifyingRateTypes: EnumerationItem[] = [];
  protected qualifiedMortgageTemporaryGSETypes: EnumerationItem[] = [];
  protected abilityToRepayMethodTypes: EnumerationItem[] = [];
  protected qualifiedMortgageTypes: EnumerationItem[] = [];
  protected presumptionOfComplianceTypes: EnumerationItem[] = [];
  protected abilityToRepayExemptionCreditorOrganizationTypes: EnumerationItem[] = [];
  protected abilityToRepayExemptionLoanProgramTypes: EnumerationItem[] = [];

  private _loanInfoChangesSubscription: Subscription;
  private _loanInfoUpdateSubscription: Subscription;

  constructor(injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService,
    private readonly _notifsService: NotificationService) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.initialize(context);
      }
    });
  }

  ngOnInit() {
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this.qualifyingRateTypes = enums[Constants.mortgageEnumerations.qualifyingRateType];
      this.qualifiedMortgageTemporaryGSETypes = enums[Constants.mortgageEnumerations.qualifiedMortgageTemporaryGSEType];
      this.abilityToRepayMethodTypes = enums[Constants.mortgageEnumerations.abilityToRepayMethodType];
      this.qualifiedMortgageTypes = enums[Constants.mortgageEnumerations.qualifiedMortgageType];
      this.presumptionOfComplianceTypes = enums[Constants.mortgageEnumerations.presumptionOfComplianceType];
      this.abilityToRepayExemptionCreditorOrganizationTypes = enums[Constants.mortgageEnumerations.abilityToRepayExemptionCreditorOrganizationType];
      this.abilityToRepayExemptionLoanProgramTypes = enums[Constants.mortgageEnumerations.abilityToRepayExemptionLoanProgramType];
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._loanInfoUpdateSubscription) {
      this._loanInfoUpdateSubscription.unsubscribe();
    }
  }

  onSaveClicked = () => {
    this._mortgageService.saveMortgage(this.mortgage).subscribe({
      next: (mortgage: UrlaMortgage) => {
        this._loanInfoUpdateSubscription = this.applicationContextService.updateLoanInfo(mortgage.applicationId).subscribe();
        this._notifsService.showSuccess('Mortgage has been saved successfully.', 'Success');
      },
      error: (error) => {
        this._notifsService.showError(error.message || 'Unable to save mortgage', 'Error');
      }
    });
  }

  private initialize = (context: ApplicationContext) => {
    if (!context.application) {
      return;
    }
    this.mortgage = context.currentMortgage;

    if (!this.mortgage.regulationZDetail) {
      this.mortgage.regulationZDetail = new RegulationZDetail();
    }

    if(!this.mortgage.regulationZDetail.highCostMortgageDetail?.length){
      this.mortgage.regulationZDetail.highCostMortgageDetail = [new HighCostMortgageDetail()];
    }
  }
}
