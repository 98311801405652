import { Injectable, Output, EventEmitter } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { WebPreferences } from '../models/web-preferences.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private readonly _dataService: DataService) { }

  @Output()
  webPreferencesChanged: EventEmitter<WebPreferences> = new EventEmitter<WebPreferences>();

  getWebPreferences = () => {
    const url = 'api/Common/get-web-preferences';
    return this._dataService.get(url);
  }

  saveWebPreferences = (webPreferences) => {
    const data = {
      preferencesBlob: JSON.stringify(webPreferences)
    }
    const url = 'api/Common/save-web-preferences';
    return this._dataService.post(url, data);
  }

  changeWebPreferences = (webPreferences: WebPreferences) => {
    this.webPreferencesChanged.emit(webPreferences);
  }
}
