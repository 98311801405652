import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Price } from '../../models/pricing/priced-product-row.model';
import { ForwardLockRequest, ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';
import { PricingService } from '../../services/pricing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { PricingEngineVendor } from 'src/app/modules/admin/tpo-config/models/configuration-product.model';
import { Router } from '@angular/router';
import { BaseProduct } from '../../models/pricing/product-search-result.model';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/models/user/user.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';
import { ApplicationContext, Role } from 'src/app/models';
import { ChannelService } from 'src/app/services/channel.service';
import { AlignmentRoleUsers } from 'src/app/modules/new-application/models/process-submission.model';

@Component({
  selector: 'request-lock-dialog',
  templateUrl: './request-lock-dialog.component.html',
  styleUrls: ['./request-lock-dialog.component.scss']
})
export class RequestLockDialogComponent implements OnInit, OnDestroy{

  @Input()
  product: BaseProduct;

  @Input()
  request: ProductSearchRequest;

  @Input()
  price: Price;

  @Input()
  vendorName: PricingEngineVendor;

  @Input()
  isTpoUser: boolean;

  @Input()
  isRequestLockEnabled: boolean

  @ViewChild('requestLockForm')
  requestLockForm: NgForm;

  isRequestForwardLockEnabled: boolean;

  protected availableRoleUsers: User[] = [];
  protected selectedCompanyId: number;
  protected selectedChannel: string;
  protected firstRole: Role;
  protected avaliableSecondaryRoles: Role[] = [];
  protected primaryRoleUserId: string;

  private _alignmentRoleUsers: AlignmentRoleUsers = {};

  private _contextSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _pricingService: PricingService,
    private readonly _router: Router,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _channelService: ChannelService
  ) {
  }

  ngOnInit(): void {
    this._contextSubscription = this._applicationContextService.context.subscribe((context) => {
      this.initializeContactRelatedData(context);
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  requestForwardLockClicked() {
    this.requestLockForm.form.markAllAsTouched();
    if (!this.requestLockForm.form.valid) {
      return;
    }

    this._spinner.show();

    // TODO: uncomment and properly assign values
    const request: ForwardLockRequest = {
      request: this.fixPercentageValues(this.request),
      // losCredentialId: this.request.credentialId,
      // losOptions?: null,
      pricingEngineVendor: this.vendorName,
      productId: this.product.productId,
      rate: this.price.rate,
      price: this.price.price,
      miQuoteDetail: this.price.selectedMiQuote,
      lockTerm: this.request.desiredLockPeriod,
      primaryRoleUserId: this.primaryRoleUserId,
      additionalAlignmentRoleUsers: this._alignmentRoleUsers
      // rateCreditApplicationMethod: null,
    } as any

    this._pricingService.requestForwardLock(request, this.vendorName).subscribe({
      next: ({ applicationId, errorMessage, success }: any) => {
        if (!success) {
          this._notificationService.showError(errorMessage || "Unable to complete lock request at this time", "Error!");
        }
        if (applicationId > 0) {
          if (this.isTpoUser) {
            this._router.navigate([`tpo/app-details/${applicationId}/pricing`]);
          } else {
            this._router.navigate([`admin/app-details/${applicationId}/pricing`]);
          }
        }
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Unable to complete lock request at this time", "Error!");
      }
    }).add(() => {
      this._spinner.hide();
    })
  }

  onPrimaryContactChanged = (userId: string) => {
    this.primaryRoleUserId = userId;
  }

  onSecondaryContactChanged = (alignmentUsers: AlignmentRoleUsers) => {
    this._alignmentRoleUsers = alignmentUsers;
  }

  private fixPercentageValues = (productSearchRequest: ProductSearchRequest): ProductSearchRequest => {
    // ** NEED to make a copy here, if not UI values bound to the source object will show wrong numbers
    let request = { ...productSearchRequest };
    let borrowerInformation = { ...productSearchRequest.borrowerInformation };
    let loanInformation = { ...productSearchRequest.loanInformation };
    request.borrowerInformation = borrowerInformation;
    request.loanInformation = loanInformation;
    if (request.desiredRate) {
      request.desiredRate = parseFloat((productSearchRequest.desiredRate * 100).toFixed(3));
    }
    request.borrowerInformation.debtToIncomeRatio = parseFloat((productSearchRequest.borrowerInformation.debtToIncomeRatio * 100).toFixed(3));
    request.loanLevelDebtToIncomeRatio = parseFloat((productSearchRequest.loanLevelDebtToIncomeRatio * 100).toFixed(3));
    request.loanInformation.upfrontPmiMipFfGfPercent = parseFloat((productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent * 100).toFixed(3));
    return request;
  }

  private initializeContactRelatedData = (context: ApplicationContext) => {
    if (this.isTpoUser) {
      let externalCompanyId: number;
      const user = context.globalConfig.tpoUsers.find(u => u.userCompanyGuid === context.currentlyLoggedInUser.userCompanyGuid);

      if (user) {
        this.primaryRoleUserId = user.userCompanyGuid;
        const branch = context.globalConfig.branches.find(b => b.branchId === user.branchId);
        externalCompanyId = branch?.externalCompanyId || user.externalCompanyId;
        let availableUsers = context.globalConfig.tpoUsers.filter(x => x.externalCompanyId === externalCompanyId);
        if (branch) {
          availableUsers = availableUsers.filter(x => context.userPermissions.branchIds.indexOf(x.branchId) > -1);
        }
        this.availableRoleUsers = availableUsers.filter(x => x.externalCompanyId == externalCompanyId);
        this.selectedCompanyId = externalCompanyId;
      }

      let wholesaleEnabled = false;
      let nonDelCorrespondentEnabled = false;
      const externalCompanies = context.globalConfig.externalCompanies.filter(x => x.externalCompanyId === externalCompanyId);
      if (externalCompanies.length > 0) {
        const availableChannelNames = this._channelService
          .getChannelsFromCommaDelimitedString(externalCompanies[0]?.enabledChannels)
          .map(c => c.name);
        const enabledChannels = context.globalConfig.enabledChannels.filter(c => availableChannelNames.includes(c.name));
        wholesaleEnabled = enabledChannels.filter(x => x.name == 'Wholesale').length > 0;
        nonDelCorrespondentEnabled = enabledChannels.filter(x => x.name == 'NonDelegatedCorrespondent').length > 0;
      }
      this.selectedCompanyId = externalCompanyId;
      this.selectedChannel = wholesaleEnabled ? "Wholesale" : nonDelCorrespondentEnabled ? "NonDelegatedCorrespondent" : "Correspondent";
    }

    this.firstRole = this.getFirstRoleByChannel(context, this.selectedChannel);

    if (this.isTpoUser && this.firstRole) {
      const tpoEnabledRoles = context.globalConfig.roles.filter(r => r.tpoEnabled);
      this.avaliableSecondaryRoles = tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    ($('.dropify') as any).dropify();
  }

  private getFirstRoleByChannel = (context: ApplicationContext, channel: string) => {
    if (!channel) {
      return context.globalConfig.firstRole;
    }
    return context.globalConfig.channelRoles[channel.toLowerCase()][0];
  }
}
