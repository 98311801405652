<p-table
  #table
  [value]='goals'
  [columns]='columns'
  [loading]='loading'
  [scrollable]='true'
  [paginator]='true'
  [autoLayout]='true'
  [selectionMode]='selectionMode'
  [selection]='selection'
  (selectionChange)='onSelectionChange($event)'
  scrollHeight='flex'
  [rowsPerPageOptions]='[10, 25, 50]'
  [rows]='25'
  [showCurrentPageReport]='true'
  currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
  styleClass='p-datatable-gridlines p-datatable-sm'
>
  <ng-template pTemplate='caption'>
    <div class='d-flex justify-content-between align-items-center'>
      <div class='fw-bold' *ngIf='title'>
        {{ title }}
      </div>

      <div class='d-flex gap-2 justify-content-end flex-grow-1'>
        <div class='p-input-icon-right'>
          <i class='pi pi-search'></i>
          <input
            type='text'
            (input)='onInputSearch($event, table)'
            class='form-control'
            placeholder='Search Keyword'
          >
        </div>

        <ng-container *ngIf='hasActions'>
          <div class='actions'>
            <ng-container *ngFor='let action of actions'>
              <span class='action' (click)='onClickAction(action)'>
                <ng-container *ngTemplateOutlet='action.template'>
                </ng-container>
              </span>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate='header'>
    <tr>
      <th
        *ngIf='selectable'
        class='selection-header'
      >
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>

      <th
        *ngIf='hasGoalActions'
        class='goal-actions-header'
      >
        Actions
      </th>

      <th
        *ngFor='let col of columns'
        [pSortableColumn]='col.field'
        [pSortableColumnDisabled]='!isColumnSortable(col)'
      >
        {{ col.header }}

        <p-sortIcon
          *ngIf='isColumnSortable(col)'
          [field]='col.field'
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate='body' let-goal let-columns='columns'>
    <tr>
      <td *ngIf='selectable'>
        <p-tableCheckbox [value]='goal'></p-tableCheckbox>
      </td>

      <td *ngIf='hasGoalActions'>
        <div class='goal-actions'>
          <ng-container *ngFor='let action of goalActions; let last = last'>
            <span (click)='onClickGoalAction(action, goal)'>
              <ng-container
                *ngTemplateOutlet='action.template'
              >
              </ng-container>
            </span>

            <span
              *ngIf='!last'
              class='vertical-divider'
            ></span>
          </ng-container>
        </div>
      </td>

      <td *ngFor='let col of columns;'>
        {{ goal[col["field"]] }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate='emptymessage'>
    <tr>
      <td
        [attr.colspan]='columnCount'
        class='text-center'
      >
        No records found
      </td>
    </tr>
  </ng-template>
</p-table>
