import { PricingFieldSpec } from "./pricing-field-spec.model";

export class PricingConfiguration {
  pricingConfigurationId: number;
  credentialId: number;
  fieldSpecs: PricingFieldSpec[] = [];

  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;

  constructor(credentialId: number){
    this.credentialId = credentialId;
  }
}

