import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {SystemLevelService} from '../../../../../../services/system-level.service';
import {NotificationService} from '../../../../../../services/notification.service';
import { DataService } from 'src/app/core/services/data.service';
import { ThirdPartyCredential } from 'src/app/models';

@Component({
    selector: 'global-email-modal',
    templateUrl: './global-email-modal.component.html'
})
export class GlobalEmailModalComponent implements OnInit {
    @Input() service: any;

    @Input()
    microsoftGraph?: ThirdPartyCredential;

    @ViewChild('serviceForm') serviceForm: NgForm | undefined;

    saving: boolean;

    users: any = [];
    tenantKvp: any;

    emailServiceUserNameToDisplay: string;

    constructor(
        public activeModal: NgbActiveModal,
        private readonly _dataService: DataService,
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        if (!this.service.userName) {
            this.service.userName = null;
        }
        this.emailServiceUserNameToDisplay = this.service.userName;
        if (this.microsoftGraph) {
            this.tenantKvp = this.microsoftGraph.thirdPartyKeyValuePairs?.find(kvp => kvp.key === 'TenantId');
            if (!this.tenantKvp?.value) {
                return;
            }
            this._getUsers();
        }
    }

    handleUserChange(value): void {
        this.service.userName = value;
        this.emailServiceUserNameToDisplay = JSON.parse(JSON.stringify(value.toLowerCase()));
    }

    saveService(): void {
        this.serviceForm.form.markAllAsTouched();
        if (!this.serviceForm.form.valid) return;

        this.service.isGlobal = true;
        this.saving = true;
        this._systemLevelService.saveGlobalService(
            this.service.id ? `email/${this.service.id}` : 'email',
            this.service
        ).subscribe({
            next: res => {
                this.saving = false;
                this.activeModal.close(res);
            },
            error: err => {
                this.saving = false;
                this._notificationService.showError(
                    err ? err.message : 'Unable to save global email.',
                    'Global Email'
                );
            }
        })
    }

    private _getUsers(): void {
        if (!this.tenantKvp) return;
        if (!this.tenantKvp.value) return;

        this._dataService.get(`api/Email/Impersonation/Users?tenantId=${this.tenantKvp.value}`)
            .subscribe({
                next: res => {
                    this.users = res;
                }
            })
    }
}
