<div class="row">
    <div class="col-md-12" *ngIf="scope === 'Company'">
        <div class="row">
            <div class="col-md-12 mt-1">
                <toggle-configuration [title]="'Enable Credit Reporting'" [id]="'creditReportingEnabled'"
                    [configuration]="creditReportingEnabled"></toggle-configuration>
            </div>
            <div class="col-md-12 mt-3" *ngIf="creditReportingEnabled.value === 1 && applicationContext.userPermissions.losEnabled">
                <toggle-configuration [title]="'Require LOS Loan Link to Allow Credit Report Pull'"
                    [id]="'requireLosLoanLinkForCreditReporting'"
                    [configuration]="requireLosLoanLinkForCreditReporting"></toggle-configuration>
            </div>
            <div class="col-md-12 mt-3" *ngIf="creditReportingEnabled.value === 1">
                <toggle-configuration [title]="'Require Soft Pull before Hard Pull'"
                    [id]="'requireSoftPullBeforeHardPull'"
                    [configuration]="requireSoftPullBeforeHardPull"></toggle-configuration>
            </div>
            <div class="col-md-12 my-1">
                <hr />
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="custom-control-label">Document Type</label>
                    </div>
                    <div class="col-md-4">
                        <select class="form-select" [(ngModel)]="creditReportDocumentType.value" (ngModelChange)="onDocTypeChanged()">
                            <option value="null">-- Select One --</option>
                            <option *ngFor="let item of documentTypes" value={{item.documentTypeId}}>
                                {{item.documentTypeName}}
                            </option>
                        </select>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    </div>
    <div class="d-flex align-content-end flex-wrap"
        *ngIf="scope === 'User' || scope === 'Branch' || (scope === 'Company' && creditReportingEnabled.value === 1)">
        <div class="mt-4 me-2" *ngFor="let credential of creditCredentials">
            <credential-item [scope]="scope" [credential]="credential" [credentials]="creditCredentials"
                (openCredentialModal)="openCredentialModal($event)"></credential-item>
        </div>
        <div class="mt-4 me-2">
            <create-credential [scope]="scope" [credentialType]="'CreditVendor'" [credentials]="creditCredentials"
                (openCredentialModal)="openCredentialModal($event)"></create-credential>
        </div>
    </div>
    <div class="col-md-12" *ngIf="scope === 'Company'">
        <enable-message *ngIf="creditReportingEnabled.value === 0">
            Enable this feature above!
        </enable-message>
    </div>
</div>
