<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm" (ngSubmit)="saveCredential()" novalidate>

    <div class="modal-header">
        <h5 class="modal-title">Credential</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <credential-modal-basic
                    [scope]="scope"
                    [credential]="credential"
                    (onChangeVendor)="onChangeVendor()"
                >
                </credential-modal-basic>
            </div>
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Url</label>
                <input type="text" class="form-control" name="url"  [(ngModel)]="credential.url">
            </div>
            <div class="mt-1" [ngClass]="{'col-md-12': hidePassword, 'col-md-6': !hidePassword}" *ngIf="!hideUsername">
                <label class="custom-control-label">Username</label>
                <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
            </div>
            <div class="col-md-6 mt-1" *ngIf="!hidePassword">
                <label class="custom-control-label">Password</label>
                <password-input [name]="'password'" [(ngModel)]="credential.password"></password-input>
            </div>
            <div class="col-md-12 mt-1 d-flex justify-content-end" *ngIf="credential.vendorName === 'EncompassApi' || credential.vendorName == 'OpenClose' || credential.vendorName == 'MortgageDirector'">
                <button class="btn btn-primary" type="button" (click)="onTestCredentials()" [disabled]="isTestSaving || isTestingCredential">
                    <span *ngIf="!isTestingCredential">Test</span>
                    <span *ngIf="isTestingCredential">
                        <span class="spinner-border spinner-border-sm"></span> Testing
                    </span>
                </button>
                <button class="btn btn-primary ms-2" type="button" (click)="saveTestCredential()" [disabled]="isTestSaving || isTestingCredential">
                    <span *ngIf="!isTestSaving"><i class="fa fa-save me-2"></i> Save</span>
                    <span *ngIf="isTestSaving">
                        <span class="spinner-border spinner-border-sm"></span> Saving
                    </span>
                </button>
            </div>
        </div>

        <div class="row" *ngIf="!hideAdditionalConfig">
            <div class="col-md-12">
                <div class="fieldset-title mt-4">
                    <div class="row">
                        <div class="col-md-6 pt-2">Additional Config</div>
                        <div class="col-md-6 pt-2 text-end">
                            <a href="javascript: void(0);" (click)="addConfig()">
                                <i class="fa fa-plus"></i>
                                Add Config
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mt-1" *ngFor="let item of credential.thirdPartyKeyValuePairs; let index = index">
                    <div class="col-md-6">
                        <label class="custom-control-label">Key</label>
                        <select class="form-control" name="key_{{index}}" [(ngModel)]="item.key">
                            <option *ngFor="let item of keys" value={{item.key}}>
                                {{item.displayText}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label class="custom-control-label">Value</label>
                        <input type="text" class="form-control" name="value_{{index}}"  [(ngModel)]="item.value"
                               *ngIf="additionalConfigValueFieldType === 'Text'">
                        <password-input
                            name="value_{{index}}"
                            [(ngModel)]="item.value"
                            *ngIf="additionalConfigValueFieldType === 'Password'"
                        ></password-input>
                    </div>
                    <div class="col-md-1 pt-4 mt-1">
                        <confirm-delete
                            [list]="credential.thirdPartyKeyValuePairs"
                            [index]="index"
                        ></confirm-delete>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button
            type="submit"
            class="btn btn-primary right"
            [disabled]="isSaving"
        >
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>

</form>
