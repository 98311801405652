<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title"> {{(product.investor || '') + product.productName}} Ineligibility Reasons </h5>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <p></p>
        <div *ngFor="let reason of product.ineligibleReason" class="alert alert-outline-danger" role="alert">
          <strong>{{reason}}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close </button>
  </div>
</div>
