import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ExternalContact } from '../../loan-docs/models/external-contact.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalContactsV2Service {

  constructor(private readonly _dataService: DataService) { }

  getExternalContacts = (appId: number): Observable<ExternalContact[]> => {
    const url = 'api/Loan/' + appId + '/ExternalContacts';
    return this._dataService.get(url);
  }

  saveExternalContact = (appId: number, copyToAgent: boolean, externalContact: ExternalContact): Observable<ExternalContact> => {
    const url = `api/Loan/${appId}/ExternalContacts?copyToAgent=${copyToAgent}`;
    return this._dataService.post(url, externalContact);
  }

  removeExternalContact = (appId: number, externalContactId: number) => {
    const url = 'api/Loan/' + appId + '/ExternalContacts/' + externalContactId;
    return this._dataService.delete(url);
  }
}