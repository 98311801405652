<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <p-table #dt1 [value]="keyDatesHistory" [paginator]="true" [rows]="25" *ngIf="keyDatesHistory"
        [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['eventDate','insertedBy','dateInserted']"
        sortMode="single" [sortField]="'dateInserted'"
        [sortOrder]="-1"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        styleClass="p-datatable-gridlines">
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-right p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="form-control" (ngModelChange)="dt1.first=0"
                        (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-keyDateHistory>
            <tr>
                <td> {{keyDateHistory.eventDate | date: dateFormatShort}} </td>
                <td> {{keyDateHistory.insertedBy || 'Service Account'}} </td>
                <td> {{keyDateHistory.dateInserted | date: dateFormat}} </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3" class="text-center"> No history found. </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
</div>