import { MortgageDuAusRecommendation, MortgageLpaAusRecommendation, PricingEngineVendor } from "src/app/modules/admin/tpo-config/models/configuration-product.model";
import { Channel } from "src/app/modules/leads/models/lead.model";
import { LockStatus } from "src/app/modules/pricing/models/pricing/loan-product-pricing.model";
import { ProductSearchRequest } from "src/app/modules/pricing/models/pricing/product-search-request-info.model";

export class ProductPricing {
  loanProductPricingId: number;
  productId: string;
  pricingVendorProductId: string;
  pricingVendorProductCode: string;
  pricingVendorLoanId: string;
  productName: string;
  productCode: string;
  investor: string;
  investorId: string;
  armIndex: string;
  armIndexValue: number;
  armFixedTermInMonths: number;
  armAdjustmentPeriodInMonths: number;
  armFirstCap: number;
  armLifetimeCap: number;
  armPeriodicCap: number;
  armRelativeCap: number;
  armMargin: number;
  armFullyIndexedRate: number;
  productNotesAndAdvisories: string[] = [];
  rate: number;
  term: number;
  price: number;
  discountRebateDollars: number;
  baseRate: number;
  basePrice: number;
  apr: number;
  lockTerm: number;
  compType: CompensationTypeEnum;
  comp: number;
  compDollars: number;
  margin: number;
  buyoutUnderwritingFee: boolean;
  altOption1Rate: number;
  altOption1Price: number;
  altOption1Dollar: number;
  altOption2Rate: number;
  altOption2Price: number;
  altOption2Dollar: number;
  altOption3Rate: number;
  altOption3Price: number;
  altOption3Dollar: number;
  lockDate: string;
  lockExpirationDate: string;
  lockRequstUserId: string;
  lockStatus: LockStatus;
  pricingVendor: PricingEngineVendor;
  assignDate: string;
  assignedSearchId: string;
  pricingRequestCallInfo: string;
  pricingRequestPayload: string;
  rateSheetId: string;
  priceDate: string;
  pollyEncompassTpoPriceGroup: string;
  assignedProductSearchRequest: ProductSearchRequest;
  product: ConfigurationProduct;
  pmlTemplateId: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum CompensationTypeEnum {
  Lender = "Lender",
  Borrower = "Borrower",
  Correspondent = "Correspondent"
}

export class ConfigurationProduct {
  productId: number;
  pricingVendorProductId: string;
  pricingVendorProductCode: string;
  productName: string;
  productCategory: string;
  term: number;
  sortOrder: number;
  active: boolean;
  pricingVendor: PricingEngineVendor;
  enabledChannels: Channel;
  isTpoSubmissionAllowed: boolean;
  duAllowedRecommendations: MortgageDuAusRecommendation;
  lpaAllowedRecommendations: MortgageLpaAusRecommendation;
  duTpoSubmissionAllowed: MortgageDuAusRecommendation;
  lpaTpoSubmissionAllowed: MortgageLpaAusRecommendation;
  tpoCreditRequired: boolean;
  losVendor: string;
  losVendorProductId: number;
  loanTypeId: number;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}
