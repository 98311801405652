<div class="card p-3">
    <div class="card-header">
        <div class="card-title">
            Notes:
            <a *ngIf="!isReadonly" class="add-note-btn" (click)="openAddNoteDialog()">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Add Note
            </a>
        </div>
    </div>
    <div class="card-body">
        <ul class="notes">
            <li *ngFor="let note of notes">
                <div class="note-header">
                    Recipient: {{note.deliveryGroups}}
                    <div class="pull-right">
                        <i *ngIf="!isReadonly" (click)="editNote(note.thirdPartyNoteId)" title="Edit Note"
                            class="fa fa-edit me-1 text-primary cursor"></i>
                        <i *ngIf="!isReadonly" (click)="removeNote(note.thirdPartyNoteId)" title="Remove Note"
                            class="fa fa-trash text-danger cursor"></i>
                    </div>
                </div>
                <div class="p1">
                    <em>{{note.note}}</em>
                </div>
            </li>
            <li *ngIf="notes.length === 0">
                <div class="empty-notes">
                    <em>No note Found. Add new note by clicking "Add Note" button.</em>
                </div>
            </li>
        </ul>
    </div>
</div>