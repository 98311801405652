export class PageUtils {

  static addFavicon(faviconUrl: string, companyGuid?: string): void {
    const favicon: HTMLLinkElement | null = document.querySelector('#customerFavicon');
    if (favicon) {
      const links = Array.from(document.getElementsByClassName('dynamic-favicon'));
      links.forEach(link => link.remove());
      favicon.href = faviconUrl + (companyGuid || "");
    }
  }
}
