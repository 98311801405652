<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-usd me-2"></i> Pricing Details
        </h4>
      </div>
      <div class="card-body">
        <edit-pricing-details [currentMortgage]="mortgage" [application]="application" [products]="loanProducts"
          [applicationKeyDatesByType]="appKeyDatesByType" [customData]="customData">
        </edit-pricing-details>
      </div>
    </div>
  </div>
</div>
