<div class="modal-header">
    <h5 class="modal-title">How you would like '{{agentFull ? agentFull.agent.firstName : ''}} {{agentFull ? agentFull.agent.lastName : ''}}' associated
        to this loan</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <form class="form-horizontal">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3 row">
                    <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Type to link as</label>
                    <div class="col-sm-8">
                        <select class="form-select" name="AgentType" [(ngModel)]="associateAgentAs">
                            <option [ngValue]="null">-- Select One --</option>
                            <option *ngFor="let agentType of agentTypes" value="{{agentType.agentTypeId}}">
                                {{agentType.agentTypeName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')">Cancel</button>
    <button *ngIf="selectingForConversion" type="button" class="btn btn-primary" (click)="submit(false)">
        Don't Associate
    </button>
    <button *ngIf="showRedirectToLoanButton" type="button" class="btn btn-primary" (click)="submit(true)">
        Associate and return to loan
    </button>
    <button type="button" class="btn btn-primary" (click)="submit(false)">
        {{showRedirectToLoanButton ? 'Associate and add more' : 'Associate to Loan'}}
    </button>
</div>