<form #applyFeeTemplateForm="ngForm" novalidate id="applyFeeTemplateForm" name="applyFeeTemplateForm">
  <div class="d-flex justify-content-center" *ngIf="!templateFeesLoading && !templateFeesLoaded">
    <div class="d-flex flex-column">
      <h4 class="card-title mb-3">Please, select a fee template to apply to your loan</h4>
      <div class="d-flex justify-content-center">
        <select style="max-width: 250px" id="feeTemplateSelect" name="feeTemplateSelect" class="form-select"
          #templateToApply="ngModel"
          [ngClass]="{'is-invalid' : templateToApply && templateToApply.touched && templateToApply.invalid}"
          [(ngModel)]="selectedTemplate" (ngModelChange)="onTemplateSelectionChanged()">
          <option [ngValue]="null">--Select a Template--</option>
          <option *ngFor="let template of templates" [ngValue]="template">{{template.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="templateFeesLoading">
    <div class="d-flex">
      <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
      <h5 class="font-18 text-muted me-4">Getting Template Fees...</h5>
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="templateFeesLoaded">
    <div class="d-flex">
      <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></span>
      <h5 class="font-18 text-muted me-4">Template Fees Loaded Successfully!</h5>
    </div>
  </div>
</form>
