<div class="modal-header">
  <h5 class="modal-title"> Invite Parameters </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <label class="form-label" for="allowedProductOptions">Allowed Products:</label>
    <ng-multiselect-dropdown name="allowedProducts" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
      [data]="allowedProductOptions" [(ngModel)]="selectedOptions">
    </ng-multiselect-dropdown>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" [disabled]="!selectedOptions.length" class="btn btn-primary" (click)="activeModal.close(selectedOptions)" data-bs-dismiss="modal">
    Save
  </button>
</div>
