<p-table
  [value]="mcrAuditSummaries"
  styleClass="p-datatable-gridlines p-datatable-sm"
>
  <ng-template pTemplate="caption">
    <button
      class="btn btn-primary ms-2"
      type="button"
      [outsideClick]="false"
      [popover]="yearQuarterTemplate"
      #pop="bs-popover"
      data-bs-container="body"
      data-bs-toggle="popover"
      placement="bottom"
    >
      <i class="fas fa-plus"></i> Add New
    </button>
    <ng-template #yearQuarterTemplate>
      <form
        #newYearQuarterForm="ngForm"
        novalidate
        id="newYearQuarterForm"
        name="newYearQuarterForm"
      >
        <div class="form-group">
          <label for="newYearIn">Year</label>
          <input
            class="form-control"
            #newYearInput="ngModel"
            required
            type="number"
            min="1900"
            step="1"
            [(ngModel)]="newYear"
            name="newYearIn"
            [ngClass]="{
              'is-invalid':
                newYearInput && newYearInput.touched && newYearInput.invalid
            }"
          />
          <div class="invalid-feedback">Please select a year.</div>
        </div>
        <div class="form-group">
          <label for="newQuarterIn">Quarter</label>
          <select
            class="form-select"
            [ngClass]="{
              'is-invalid':
                newQuarterInput &&
                newQuarterInput.touched &&
                newQuarterInput.invalid
            }"
            name="newQuarterIn"
            #newQuarterInput="ngModel"
            required
            [(ngModel)]="newQuarter"
          >
            <option *ngFor="let yq of yearQuarters" [ngValue]="yq.value">
              {{ yq.name }}
            </option>
          </select>
          <div class="invalid-feedback">Please select a quarter.</div>
        </div>
      </form>

      <div class="row mb-3">
        <div class="col-md-12 text-end">
          <button class="btn btn-primary btn-sm me-2" (click)="onAddNew(pop)">
            Save
          </button>
          <button class="btn btn-danger btn-sm" (click)="onCancel(pop)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th class="text-center" style="width: 100px !important;">Actions</th>
      <th style="width: 100px !important;" class="text-center">Year</th>
      <th class="text-center">Quarter</th>
      <th class="text-center">State</th>
      <th style="width: 80px !important;" class="text-center">Excluded</th>
      <th style="width: 80px !important;" class="text-center">Synced</th>
      <th class="text-center">Channel</th>
      <th class="text-center">Pipeline Positions</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <div class="d-flex align-items-center justify-content-center">
          <span>
            <i
              class="fa fa-pencil-alt cursor text-info"
              (click)="onEditClicked(rowData)"
              title="Edit"
            ></i>
          </span>
          <span>
            <span class="mx-1"> | </span>
            <i
              class="fa fa-trash-alt cursor text-danger"
              (click)="onDeleteClicked(rowData.mcrAuditDataId, rowData.year, rowData.quarter)"
              title="Delete"
              id="btnMcrSummaryDelete"
            ></i>
          </span>
        </div>
      </td>
      <td style="width: 100px !important;" class="text-center">{{ rowData.year }}</td>
      <td class="text-center">{{ getEnumName(rowData.quarter) }}</td>
      <td class="text-center">{{ getSelectedStateName(rowData.state) }}</td>
      <td style="width: 80px !important;" class="text-center">
        {{rowData.excludeFromReport ? "Yes" : "No"}}
      </td>
      <td style="width: 80px !important;" class="text-center">
        {{rowData.syncFromApplication ? "Yes" : "No"}}
      </td>
      <td class="text-center">{{ rowData.mcrChannel }}</td>
      <td>{{ getSelectedPipelinePositions(rowData.pipelinePositions) }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="8" class="text-center">
        There are no MCR Audit Data
      </td>
    </tr>
  </ng-template>
</p-table>

<drawer
  #mcrAuditDataEditorDrawer
  [title]="'Editing MCR Audit Data'"
  [name]="'mcrAuditDataEditorDrawer'"
  [templateRef]="mcrAuditDataEditorContentRef"
  [options]="mcrDataAuditDrawerOptions"
>
</drawer>
<ng-template #mcrAuditDataEditorContentRef>
  <div class="m-2 p-2">
    <edit-mcr-audit-data
      [mcrAuditData]="mcrFullAuditData"
      [year]="currentYear"
      [quarter]="currentQuarter"
      (saveFinished)="finishedMcrAuditDataSaving($event)"
      (exit)="onExitClicked()"
    >
    </edit-mcr-audit-data>
  </div>
</ng-template>
