import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/core/services/utils';
import { AddAppraisalNote } from 'src/app/models/appraisal/appraisal-note.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'appraisal-note-dialog',
  templateUrl: './add-appraisal-note-dialog.component.html',
  styleUrls: ['./add-appraisal-note-dialog.component.scss']
})
export class AddAppraisalNoteDialogComponent implements OnInit {
  @Input() selectedNote: AddAppraisalNote | null;
  @Input() noteDeliveryGroups: Array<EnumerationItem>;

  dropdownSettings = {
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 4
  };
  constructor(public readonly activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (!this.selectedNote) {
      this.selectedNote = {
        id: Utils.getUniqueId().toString(),
        note: '',
        deliveryGroups: [],
        deliveryGroupsCsv: ''
      }
    }
  }
  ok(): void {
    this.selectedNote.deliveryGroupsCsv = this.selectedNote.deliveryGroups.map(item => item.value).join();
    this.activeModal.close({
      thirdPartyNoteId: this.selectedNote.id,
      note: this.selectedNote.note,
      deliveryGroups: this.selectedNote.deliveryGroupsCsv
    });
  }
}
