<div class="modal-header">
    <h5 class="modal-title">Confirm Snooze</h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row mb-3 text-center">
        <p>You are changing <i>{{count}}</i> task{{count > 1 ? 's' : ''}}!</p>

        <div class="row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end"><b>Snooze:</b></label>
            <div class="col-sm-8">
                <select class="form-select" [(ngModel)]="snoozeDurationInMinutes" name="snoozeDropdown">
                    <option [ngValue]="15">15 mins</option>
                    <option [ngValue]="30">30 mins</option>
                    <option [ngValue]="45">45 mins</option>
                    <option [ngValue]="60">1 hour</option>
                    <option [ngValue]="120">2 hours</option>
                    <option [ngValue]="180">3 hours</option>
                    <option [ngValue]="240">4 hours</option>
                </select>
            </div>
        </div>

        <p class="mt-2">Do you want continue?</p>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">No</button>
    <button type="button" class="btn btn-primary right" [disabled]="!snoozeDurationInMinutes" (click)="activeModal.close(snoozeDurationInMinutes)">Yes</button>
</div>
