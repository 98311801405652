import { Component, Injector, Input, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { ThirdPartyCredentialType } from 'src/app/models';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'appraisal',
  templateUrl: './appraisal.component.html',
  styleUrls: ['./appraisal.component.scss']
})
export class AppraisalComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  isPipelineView: boolean = false;

  loadingData: boolean;
  appId: number;

  viewType: "management" | "reggora";

  constructor(private readonly injector: Injector,
    private readonly _notifyService: NotificationService,
    private readonly _systemLevelService: SystemLevelService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.appId = this.isPipelineView ? null : this.applicationContext.application?.applicationId;
    this.viewTypeControl();
  }

  private viewTypeControl = () => {
    this.loadingData = true;
    this.haveThirdPartyCredentialsForReggora().subscribe({
      next: (haveCred: boolean) => {
        if (haveCred) {
          this.viewType = "reggora";
        } else {
          this.viewType = "management";
        }
        this.loadingData = false;
      },
      error: (err) => {
        this.loadingData = false;
        this._notifyService.showError(err.message || "Could not fetch credentials", "Error!")
      }
    });
  }

  private haveThirdPartyCredentialsForReggora = (): Observable<boolean> => {
    return this._systemLevelService.getSystemLevel().pipe(map(systemLevel => {
      return systemLevel.thirdPartyCredentials.filter(cred => cred.credentialType == ThirdPartyCredentialType.AppraisalVendor && cred.vendorName == "Reggora" && !!cred.active).length > 0;
    }))
  }
}
