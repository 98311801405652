import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  PurchaseCredit,
  PurchaseCreditSourceType,
  PurchaseCreditType,
} from '../../../models';
import {
  autoId,
  enumLikeValueToDisplayName,
} from '../../../core/services/utils';
import { NotificationService } from '../../../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, NgForm } from '@angular/forms';
import { EnumerationService } from '../../../services/enumeration-service';
import { EnumerationItem } from '../../../models/simple-enum-item.model';
import { Constants } from '../../../services/constants';
import { Subscription } from 'rxjs';

type DeepWritable<T> = {
  -readonly [K in keyof T]: DeepWritable<T[K]>;
};

const sourceTypeToCreditTypeMap: Readonly<Map<PurchaseCreditSourceType, PurchaseCreditType>> = Object.freeze(new Map([
  [PurchaseCreditSourceType.Lender, PurchaseCreditType.LenderCredit],
  [PurchaseCreditSourceType.PropertySeller, PurchaseCreditType.SellerCredit],
  [PurchaseCreditSourceType.Other, PurchaseCreditType.Other],
]));

const excludedCreditTypeOptions: Readonly<Set<PurchaseCreditType>> = Object.freeze(new Set([
  PurchaseCreditType.LenderCredit,
  PurchaseCreditType.SellerCredit,
]));

const defaultCancelButtonLabel = 'Cancel';
const defaultOkButtonLabel = 'Ok';

@Component({
  templateUrl: './calculate-credits-dialog.component.html',
  styleUrls: ['./calculate-credits-dialog.component.scss'],
})
export class CalculateCreditsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) formElement: NgForm;
  protected title: string = '';
  protected creditItems: CreditItem[] = [];
  protected creditTypeOptions: EnumerationItem<PurchaseCreditType>[] = [];
  protected totalAmount: number = 0;
  private _canHaveMainCredit: boolean;
  private _sourceType: PurchaseCreditSourceType;
  private _creditTypeOfSourceType: PurchaseCreditType;
  private _credits: PurchaseCredit[] = [];
  private _initEnumerationsSubscription?: Subscription;
  private readonly _id: string = autoId();

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _notificationService: NotificationService,
    private readonly _enumerationService: EnumerationService
  ) {}

  @Input() set props({ credits, sourceType }: CalculateCreditsDialogProps) {
    this._credits = credits.filter(
      (credit) => credit.sourceType === sourceType
    );

    this._sourceType = sourceType;
    this._canHaveMainCredit = sourceType !== PurchaseCreditSourceType.Other;
    this._creditTypeOfSourceType = sourceTypeToCreditTypeMap.get(
      sourceType
    ) as PurchaseCreditType;
    if (!this._creditTypeOfSourceType && this._canHaveMainCredit) {
      console.error('Cannot determine credit type for source type', sourceType);
      this._notificationService.showError(
        'An error occurred while initializing credits.',
        'Error'
      );
      return;
    }
    this.title = enumLikeValueToDisplayName(sourceType) + ' Credits';

    this.invalidateCreditItems();
  }

  protected effectiveCancelButtonLabel: string = defaultCancelButtonLabel;

  @Input() set cancelButtonLabel(value: string | undefined) {
    this.effectiveCancelButtonLabel = value ?? defaultCancelButtonLabel;
  }

  protected effectiveOkButtonLabel: string = defaultOkButtonLabel;

  @Input() set okButtonLabel(value: string | undefined) {
    this.effectiveOkButtonLabel = value ?? defaultOkButtonLabel;
  }

  ngOnInit() {
    this.initEnumerations();
  }

  ngOnDestroy() {
    this._initEnumerationsSubscription?.unsubscribe();
  }

  private get _form(): FormGroup {
    return this.formElement.form;
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }

  protected onClickAddCredit(): void {
    this._credits.push(this.createOtherCredit());
    this.invalidateCreditItems();
  }

  protected onClickDeleteCredit(index: number): void {
    this._credits.splice(index, 1);
    this.invalidateCreditItems();
  }

  protected onChangeCreditType(
    creditItem: CreditItem,
    creditType: PurchaseCreditType
  ): void {
    creditItem.creditType = creditType;
    creditItem.isDescriptionReadonly = creditType !== PurchaseCreditType.Other;
    if (creditItem.isDescriptionReadonly) {
      // Unlink the description from the credit object and clear it
      delete creditItem.description;
      creditItem.description = '';
    } else {
      // Link the description to the credit object for editing
      const credit = creditItem.credit as DeepWritable<PurchaseCredit>;

      const descriptionAccessor = {
        get description(): string {
          return credit.purchaseCreditTypeOtherDescription;
        },
        set description(value: string) {
          credit.purchaseCreditTypeOtherDescription = value;
        },
      };

      Object.defineProperties(
        creditItem,
        Object.getOwnPropertyDescriptors(descriptionAccessor)
      );
    }
  }

  protected onChangeAmount(creditItem: CreditItem, amount: number): void {
    creditItem.amount = amount;
    this.invalidateTotalAmount();
  }

  protected onClickCancel(): void {
    this._activeModal.dismiss();
  }

  protected onClickConfirm(): void {
    const form = this._form;
    form.markAllAsTouched();
    if (form.invalid) {
      return;
    }

    this.clearOtherTypeDescriptions();

    const validCredits = this.getValidCredits();
    this.confirm(validCredits);
  }

  private getValidCredits(): PurchaseCredit[] {
    return this._credits.filter((credit) => credit.purchaseCreditAmount > 0);
  }

  private clearOtherTypeDescriptions(): void {
    if (this._canHaveMainCredit) {
      return;
    }

    for (const credit of this._credits) {
      if (credit.purchaseCreditType !== PurchaseCreditType.Other) {
        delete credit.purchaseCreditTypeOtherDescription;
      }
    }
  }

  private invalidateCreditItems(): void {
    if (this._canHaveMainCredit) {
      this.invalidateWithMainCredit();
    } else {
      this.invalidateWithoutMainCredit();
    }

    this.invalidateTotalAmount();
  }

  private invalidateTotalAmount() {
    this.totalAmount = this.creditItems.reduce(
      (acc, item) => acc + (Number(item.amount) || 0),
      0
    );
  }

  private invalidateWithMainCredit(): void {
    const sourceType = this._sourceType;
    const creditType = this._creditTypeOfSourceType;

    let { mainCreditItem, otherCreditItems } = this._credits.reduce(
      (acc, credit) => {
        if (credit.purchaseCreditType === creditType) {
          if (acc.mainCreditItem) {
            console.error(
              'Multiple main credits found for source type',
              sourceType
            );
            this._notificationService.showError(
              'An error occurred while initializing credits.',
              'Error'
            );
            return acc;
          }

          acc.mainCreditItem = this.createMainCreditItem(credit);
        } else {
          acc.otherCreditItems.push(this.createOtherCreditItem(credit));
        }
        return acc;
      },
      {
        mainCreditItem: undefined as CreditItem | undefined,
        otherCreditItems: [] as CreditItem[],
      }
    );

    if (!mainCreditItem) {
      const credit = this.createMainCredit();
      this._credits.unshift(credit);
      mainCreditItem = this.createMainCreditItem(credit);
    }

    if (otherCreditItems.length) {
      otherCreditItems[otherCreditItems.length - 1].isLastInSection = true;
    }

    this.creditItems = [mainCreditItem, ...otherCreditItems];
  }

  private invalidateWithoutMainCredit(): void {
    const items = this._credits.map((credit) => {
      return this.createOtherCreditItem(credit);
    });

    if (items.length) {
      items[items.length - 1].isLastInSection = true;
    }

    this.creditItems = items;
  }

  private initEnumerations(): void {
    this._initEnumerationsSubscription?.unsubscribe();
    this._initEnumerationsSubscription = this._enumerationService
      .getMortgageEnumerations()
      .subscribe((enumerations) => {
        const enumsKey = Constants.enumerations;

        this.creditTypeOptions = (
          enumerations[
            enumsKey.purchaseCreditTypes
          ] as EnumerationItem<PurchaseCreditType>[]
        ).filter((item) => !excludedCreditTypeOptions.has(item.value));
      });
  }

  private createMainCredit(): PurchaseCredit {
    return Object.assign(new PurchaseCredit(), {
      sourceType: this._sourceType,
      purchaseCreditType: this._creditTypeOfSourceType,
      purchaseCreditAmount: 0,
    });
  }

  private createMainCreditItem(credit: PurchaseCredit): CreditItem {
    return this.assignCreditItemAccessorProperties(
      {
        credit,
        isZeroAmountValid: true,
        description: `Non-Specific ${enumLikeValueToDisplayName(this._creditTypeOfSourceType)}`,
        isDescriptionReadonly: true,
        isLastInSection: true,
      },
      credit
    );
  }

  private createOtherCredit(): PurchaseCredit {
    return Object.assign(new PurchaseCredit(), {
      sourceType: this._sourceType,
      purchaseCreditType: this._canHaveMainCredit
        ? PurchaseCreditType.Other
        : null,
      purchaseCreditAmount: undefined,
    });
  }

  private createOtherCreditItem(credit: PurchaseCredit): CreditItem {
    const item: CreditItem = this.assignCreditItemAccessorProperties(
      {
        credit,
        isZeroAmountValid: false,
        get description(): string {
          return credit.purchaseCreditTypeOtherDescription || '';
        },
        set description(value: string) {
          credit.purchaseCreditTypeOtherDescription = value;
        },
        isDescriptionReadonly: false,
        isLastInSection: false,
        canDelete: true,
      },
      credit
    );

    if (!this._canHaveMainCredit) {
      item.canSetCreditType = true;

      const creditTypeAccessor = {
        get creditType(): PurchaseCreditType {
          return credit.purchaseCreditType as PurchaseCreditType;
        },
        set creditType(value: PurchaseCreditType) {
          credit.purchaseCreditType = value;
        },
      };

      Object.defineProperties(
        item,
        Object.getOwnPropertyDescriptors(creditTypeAccessor)
      );

      this.onChangeCreditType(
        item,
        credit.purchaseCreditType as PurchaseCreditType
      );
    }

    return item;
  }

  private assignCreditItemAccessorProperties<T>(
    creditItem: T,
    credit: PurchaseCredit
  ): T & { amount: number } {
    const result = {
      get amount(): number {
        return credit.purchaseCreditAmount;
      },
      set amount(value: number) {
        credit.purchaseCreditAmount = value;
      },
    };

    Object.defineProperties(
      result,
      Object.getOwnPropertyDescriptors(creditItem)
    );

    return result as T & { amount: number };
  }

  private confirm(result: PurchaseCredit[]): void {
    this._activeModal.close(result);
  }
}

export interface CalculateCreditsDialogProps {
  credits: PurchaseCredit[];
  sourceType: PurchaseCreditSourceType;
}

interface CreditItem {
  readonly credit: Readonly<PurchaseCredit>;
  amount: number;
  isZeroAmountValid: boolean;
  description: string;
  isDescriptionReadonly: boolean;
  isLastInSection: boolean;
  canDelete?: boolean;
  creditType?: PurchaseCreditType;
  canSetCreditType?: boolean;
}
