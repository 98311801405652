import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { clone } from 'lodash';
import { catchError, firstValueFrom, retry, switchMap } from 'rxjs';
import { LoanApplication, UserType } from 'src/app/models';
import { LoanService } from 'src/app/services/loan';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import { LoanValidationResponse, LosRequiredValuesReport } from '../../../request-models/loan-validation-response.model';
import { MenuItem } from 'primeng/api';
import { LosSetFieldRequest } from '../../models/los-set-field-request';
import { NgForm } from '@angular/forms';
import { LosDisclosureGenJob, LosDisclosureGenOptions, LosDisclosureInfo, LosDisclosureJobStatus, LosDisclosureStatus, LosDisclosureType } from '../../../request-models/los-disclosure-gen-job';
import { NotificationService } from 'src/app/services/notification.service';
import { Utils } from 'src/app/core/services/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Router } from '@angular/router';
import { SignalRService } from 'src/app/services/signalr.service';

enum LosDisclosureJobTypeEnum {
	audit = "audit",
	queue = "queue",
}

@Component({
	selector: 'encompass-disclosure-wizard-streamlined',
	templateUrl: 'encompass-disclosure-wizard-streamlined.component.html',
	styleUrls: ['encompass-disclosure-wizard-streamlined.component.scss']
})
export class EncompassDisclosureWizardStreamlinedComponent extends ApplicationContextBoundComponent implements OnInit {

	@ViewChild('disclosuresForm')
	disclosuresForm: NgForm | undefined;

	@Input()
	hasCancelButton: boolean = false;

	@Input()
	credentialId: number;

	@Output()
	canceled = new EventEmitter<any>();

	protected skipSyncBeforeAudit: boolean = false;

	protected preventDisclosures: boolean = false;

	protected preventDisclosuresErrorMessage: string = '';

	protected loanIsSubmitted: boolean = false;
	protected disclosuresAlreadyProcessed: boolean = false;
	protected disclosuresSentForManualProcessing: boolean = false;

	protected steps: MenuItem[] = [
		{ label: 'Validate Loan Data' },
		{ label: 'Audit Loan Data' },
		{ label: 'Review & Approve Loan Estimate' }
	];

	protected activeStepIndex: number = 0;
	protected nextButtonLabel: string = "Skip >";

	protected atFirstStep: boolean = true;
	protected isWizardCompleted: boolean = false;

	protected loadingData = false;
	protected correctingData = false;

	protected generatingProformaLe: boolean = false;

	protected isLoanDataValid = false;
	protected loanValidationResponse: LoanValidationResponse = null;
	protected invalidPackageConditions = [];
	protected errors: string | null = null;
	protected isStepRunning: boolean = false;
	protected generatedJob = null;
	protected validateStepSkipped: boolean = false;
	protected sendForManualDisclosure: boolean = false;
	protected selectedDisclosureType: LosDisclosureType = null;

	protected jobType: LosDisclosureJobTypeEnum = null;

	protected loanIsTbd: boolean = false;

	protected incorrectValues: LosRequiredValuesReport[] = [];
	protected correctedValues: { [fieldId: string]: LosSetFieldRequest } = {};
	protected auditPassedNoRecommendations: boolean = false;

	protected leDocumentUrlToView: any = null;

	protected disclosuresHaveBeenQueued: boolean = false;

	protected get activeStepIndexWithOffset() {
		return this.steps.length === 3
			? this.activeStepIndex + 1
			: this.activeStepIndex;
	}

	protected isTpoUser: boolean = false;

	protected proformaLegenerationFailed: boolean = false;

	protected disclosureTypes: EnumerationItem[] = [
		new EnumerationItem("Initial", "Initial")
		// ,new EnumerationItem("Closing", "Closing")
	];

	private _lockId: string = null;

	private _disclosureOptions: LosDisclosureGenOptions;

	constructor(private readonly injector: Injector,
		private readonly _loanService: LoanService,
		private readonly _signalRService: SignalRService,
		private readonly _losService: LosService,
		private readonly _notificationService: NotificationService,
		private readonly _sanitizer: DomSanitizer,
		private readonly _router: Router
	) {
		super(injector);
	}

	async ngOnInit() {
		const application = this.applicationContext.application;
		this.isTpoUser = this.applicationContext.userPermissions.userType == UserType.Tpo;
		if (this.isTpoUser) {
			let canRunDisclosures = await this.doesLoanPassPreDisclosureExecutionChecks(application);
			if (canRunDisclosures) {
				await this.runDisclosureProcess(application);
			}
		} else {
			await this.runDisclosureProcess(application);
		}
	}

	ngOnDestroy(): void {
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.setNextButtonLabel();
		});
	}

	onBackClicked = () => {
		this.navigateBackward();
	}

	onNextClicked = () => {
		this.navigateForward().then();
	}

	onCancelClicked = () => {
		if (this.hasCancelButton) {
			this.canceled.emit();
		}
		else {
			this.activeStepIndex = 0;
			this.updateCurrentStepRelatedState();
			this.ngOnInit();
		}
	}

	protected isRunning = (): boolean => {
		return !!this._lockId;
	}

	protected onDisclosureTypeChanged = () => {
		if (this.selectedDisclosureType) {
			this.auditLoanJob(this.applicationContext.application);
		}
	}

	protected thereAreRequiredFieldValuesInAudits = (res: LosDisclosureGenJob): boolean => {
		return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => x.severity == 'Required').length);
	}

	protected thereAreRecommendedFieldValuesInAudits = (res: LosDisclosureGenJob): boolean => {
		return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => x.severity == 'Recommended').length);
	}

	protected thereAreCorrectableFieldValues = (res: LosDisclosureGenJob): boolean => {
		return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => !!x.losFieldId).length);
	}

	protected correctAndReAudit = async () => {
		await this.correctData(this.applicationContext.application);
	}

	protected skipAndGenerate = () => {
		this.goToNextStep();
	}

	protected onProceedToLoanSubmission = () => {
		this._router.navigate([`tpo/app-details/${this.applicationContext.application.applicationId}/submission`]);
	}

	protected onRetryJob = async (type: LosDisclosureJobTypeEnum) => {
		if (type == LosDisclosureJobTypeEnum.audit) {
			this.errors = null;
			this.auditLoanJob(this.applicationContext.application);
		} else if (type == LosDisclosureJobTypeEnum.queue) {
			await this.queueDisclosuresStreamlined(this.applicationContext.application);
		}
	}

	protected onRetryStartDisclosureProcess = async () => {
		this.runDisclosureProcess(this.applicationContext.application);
	}

	protected onRetryGenerateProformaLe = async () => {
		await this.generateProformaLe(this.applicationContext.application);
	}

	private doesLoanPassPreDisclosureExecutionChecks = async (application: LoanApplication): Promise<boolean> => {
		let errorMessageContact: string = 'Please reach out to your AE if you feel you are seeing this message in error.';

		if (this.applicationContext.isCompanyPRMG) {
			this.skipSyncBeforeAudit = true;
			errorMessageContact = 'Please contact TPOSupport@prmg.net if you feel you are seeing this message in error.';
		}

		let errorMsg = '';

		try {
			const keyDates = await firstValueFrom(this._loanService.getKeyDatesByType(application.applicationId));

			if (keyDates.tpoSubmission?.eventDate) {
				this.loanIsSubmitted = true;
			}

			if (keyDates.initialDisclosureSent?.eventDate) {
				this.disclosuresAlreadyProcessed = true;
			} else if (keyDates.tpoManualDisclosureRequested?.eventDate) {
				this.disclosuresSentForManualProcessing = true;
			} else if (this.applicationContext.currentMortgage.subjectProperty?.address1?.toLowerCase() == "tbd") {
				this.preventDisclosures = true;
				errorMsg += "Loans with a 'TBD' subject property address are not eligible for disclosures.";
				this.preventDisclosuresErrorMessage += "The following step(s) must be resolved before you can generate disclosures:<br/><br/><br/>" + errorMsg + "<br/><br/>" + errorMessageContact;
			} else {

				if (!keyDates.ausCompleted?.eventDate
					&& this.applicationContext.currentMortgage.mortgageTerm.mortgageAppliedFor != "USDA"
					&& this.applicationContext.currentMortgage.subjectProperty.refiPurpose != "NoCashOutStreamlinedRefinance"
					&& this.applicationContext.currentMortgage.subjectProperty.refiPurpose != "NoCashOutFHAStreamlinedRefinance") {
					this.preventDisclosures = true;
					errorMsg += "Step 2 - AUS MUST be run on the loan.<br/>";
				}

				if (!this.applicationContext.application.productPricing.pricingVendor) {
					this.preventDisclosures = true;
					errorMsg += "Step 3 - Pricing MUST be applied to the loan.<br/>";
				}

				if (!keyDates.feeWizardRun?.eventDate) {
					this.preventDisclosures = true;
					errorMsg += "Step 4 - Fees MUST be run on the loan.<br/>";
				} else if (keyDates.feeWizardRun.eventDate
					&& this.applicationContext.application.productPricing.assignDate
					&& this.applicationContext.tpoConfig.requireFeesAfterPricingAssignment
					&& new Date(keyDates.feeWizardRun.dateUpdated || keyDates.feeWizardRun.dateInserted) < new Date(this.applicationContext.application.productPricing.assignDate)
				) {
					this.preventDisclosures = true;
					errorMsg += "Step 4 - Fees MUST be re-run on the loan. Pricing was changed after fees were applied.<br/>";
				}

				if (this.preventDisclosures) {
					this.preventDisclosuresErrorMessage += "The following step(s) must be resolved before you can generate disclosures:<br/><br/><br/>" + errorMsg + "<br/><br/>" + errorMessageContact;
				}
				return !this.preventDisclosures;
			}
		} catch (error) {
			this.preventDisclosures = true;
			errorMsg += getErrorMessageOrDefault(error, {
				defaultMessage: 'Error getting the key dates for loan.',
			}),
				this.preventDisclosuresErrorMessage += "The following step(s) must be resolved before you can generate disclosures:<br/><br/><br/>" + errorMsg + "<br/><br/>" + errorMessageContact
		}
	}

	private runDisclosureProcess = async (application: LoanApplication) => {
		if (!this.skipSyncBeforeAudit) {
			await this.syncLosLoanControl(application);
		} else {
			const isLoanValid = await this.validateLoan(application);
			if (isLoanValid) {
				await this.goToNextStep();
			}
		}
	}

	private auditLoanJob = (application: LoanApplication) => {
		this.jobType = LosDisclosureJobTypeEnum.audit;

		this.isStepRunning = true;
		this.loadingData = true;
		this.generatedJob = null;
		this.correctedValues = {};

		this._disclosureOptions = new LosDisclosureGenOptions();
		this._disclosureOptions.disclosureType = this.selectedDisclosureType;
		this._disclosureOptions.losLoanId = application.losIdentifier;

		this._losService.auditDisclosures(this.credentialId, this._disclosureOptions, application.applicationId, this._lockId)
			.subscribe({
				next: async (job: LosDisclosureGenJob) => {
					this.generatedJob = job;
					this.loadingData = false;
					this.isStepRunning = false;

					if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
						const auditJobFinished = await this.checkJobStatus(LosDisclosureJobTypeEnum.audit);
						if (auditJobFinished) {
							this.goToNextStep();
						}
					}
				},
				error: (err) => {
					this.loadingData = false;
					this.isStepRunning = false;
					this.errors = err?.message || "Couldn't get audit disclosure";
				}
			})
	}

	private syncLosLoanControl = async (application: LoanApplication): Promise<void> => {
		this.errors = null;

		if (!application.losIdentifier) {
			this.errors = this.isTpoUser
				? "You must import fees before you can generate disclosures."
				: "You must create an LOS loan before you can generate disclosures.";
			return Promise.resolve();
		} else {
			if (this.isTpoUser) {
				this.loadingData = true;
				this.isStepRunning = true;

				if (this.skipSyncBeforeAudit) {
					return Promise.resolve();
				} else {
					try {
						await firstValueFrom(
							this._losService.autoSyncLosLoan(application.applicationId, true, true)
								.pipe(
									retry({ count: 2, delay: 1000 }),
									catchError((err) => {
										this.errors = err?.message || "Couldn't auto sync Los loan";
										this.loadingData = false;
										this.isStepRunning = false;
										throw err;
									})
								)
						);
						return Promise.resolve();
					} catch (err) {
						return Promise.reject(err);
					}
				}
			} else {
				return Promise.resolve();
			}
		}
	}

	private generateProformaLe = async (application: LoanApplication) => {
		this.loadingData = true;
		this.generatingProformaLe = true;
		this.proformaLegenerationFailed = false;
		this.generatedJob = null;
		this.errors = null;
		try {
			const loanEstimate = await firstValueFrom(this._loanService.generateProformaLe(application.applicationId));

			const doc = loanEstimate?.documents?.[0];
			if (doc == null) {
				throw new Error('No document found in the response.');
			}

			const pdf = doc?.pdf;
			if (!pdf) {
				throw new Error('PDF is missing in the response.');
			}
			if (!pdf.content) {
				throw new Error('PDF content is missing in the response.');
			}

			const urlToViewFile = Utils.generateFileUrlFromBase64(pdf.content);
			this.leDocumentUrlToView = this._sanitizer.bypassSecurityTrustResourceUrl(urlToViewFile);
		} catch (error) {
			this.errors = getErrorMessageOrDefault(error, {
				defaultMessage: 'Error generating Proforma LE.',
			});
			this.proformaLegenerationFailed = true;
		} finally {
			this.loadingData = false;
			this.generatingProformaLe = false;
		}
	}

	private validateLoan = (application: LoanApplication): Promise<boolean> => {

		this.resetLoanValidationRelatedControlState();

		let observable;

		if (!this._lockId) {
			observable = this._losService.lockApplication(this.credentialId, application.applicationId)
				.pipe(
					switchMap((res) => {
						this._lockId = res;
						return this._losService.validateLoanForDisclosureGen(this.credentialId, application.applicationId, this._lockId, true);
					})
				);
		} else {
			observable = this._losService.validateLoanForDisclosureGen(this.credentialId, application.applicationId, this._lockId, true);
		}

		// Return a Promise and resolve/reject based on the observable's outcome
		return new Promise<boolean>(async (resolve, reject) => {
			observable.subscribe({
				next: async (res: LoanValidationResponse) => {
					this.loanValidationResponse = clone(res);
					// Update lock ID if needed
					if (this.loanValidationResponse.updateLosLockId) {
						this._lockId = this.loanValidationResponse.updateLosLockId;
					}

					this.loadingData = false;
					this.isStepRunning = false;

					this.setNextButtonLabel();

					this.invalidPackageConditions = this.loanValidationResponse.packageConditionReports?.filter(r => !r.passed) || [];

					this.isLoanDataValid = this.allDataValid(this.loanValidationResponse);

					if (!this.isLoanDataValid) {
						// Handle validation errors
						if (!this.loanValidationResponse.elibilityCheckRuleStatus &&
							!this.loanValidationResponse.packageConditionReports &&
							!this.loanValidationResponse.planCodeConflicts &&
							!this.loanValidationResponse.validationRulePassReports) {
							this.errors = this.loanValidationResponse.errorMsg;
						}

						if (this.thereAreHardStops(this.loanValidationResponse)) {
							if (this._lockId) {
								await firstValueFrom(this._losService.unlockApplication(this.credentialId, application.applicationId, this._lockId));
							}
							this.markFileForManualDisclosures(application);
						} else if (this.thereAreInvalidFieldValues(this.loanValidationResponse)) {
							this.collectIncorrectValues(this.loanValidationResponse);
						}
						resolve(false); // Loan is not valid
					} else if (!this.loanValidationResponse.passed) {
						this.errors = this.loanValidationResponse.errorMsg;
						resolve(false); // Loan validation didn't pass
					} else {
						resolve(true); // Loan is valid
					}
				},
				error: (err) => {
					this.loadingData = false;
					this.isStepRunning = false;
					this.errors = err?.message || JSON.parse(err?.error)?.message || "Couldn't validate loan";

					if (this.errors.indexOf("Service Unavailable") > -1) {
						this.markFileForManualDisclosures(application);
					}

					this.validateStepSkipped = true;
					reject(err); // Reject the promise with the error
				}
			});
		});
	}

	private goToNextStep = async () => {
		this.updateCurrentStepRelatedState();

		this.isStepRunning = true;
		setTimeout(async () => {
			this.isStepRunning = false;
			await this.navigateForward();
		}, 2000);
	}

	private navigateForward = async () => {

		if (!this.validateFields()) {
			return;
		}

		this.errors = null;

		if (this.activeStepIndexWithOffset == 1) {
			this.selectedDisclosureType = null;
			this.generatedJob = null;

			if (
				(this.loanValidationResponse && this.thereAreInvalidFieldValues(this.loanValidationResponse)) ||
				this.incorrectValues.length
			) {
				const isOK = await this.correctData(this.applicationContext.application);
				if (!isOK) {
					return;
				}
			} else {
				this.selectedDisclosureType = LosDisclosureType.Initial;
				this.onDisclosureTypeChanged();
			}
		}

		if (this.activeStepIndexWithOffset == 2) {
			await this.generateProformaLe(this.applicationContext.application);
			if (this.proformaLegenerationFailed) {
				return;
			}
		}

		if (this.activeStepIndexWithOffset == 3) {
			await this.queueDisclosuresStreamlined(this.applicationContext.application);
		}

		if (this.steps.length - 1 > this.activeStepIndex) {
			this.activeStepIndex++;
			this.updateCurrentStepRelatedState();
		}
	};

	private checkJobStatus = async (type: LosDisclosureJobTypeEnum): Promise<boolean> => {
		let stopped = false;

		this.errors = null;
		this.loadingData = true;

		let isOk = true;

		try {

			while (!stopped) {
				this.generatedJob = await firstValueFrom(this._losService.checkDisclosureAuditStatus(this.credentialId, this.generatedJob, this._lockId)) as LosDisclosureGenJob;
				this._lockId = this.generatedJob.lockId || this._lockId;
				if (
					this.generatedJob.jobStatus != LosDisclosureJobStatus.Pending ||
					this.generatedJob.disclosureInfos.every(dis => dis.status != this.getDisclosurePendingStatus(type))
				) {
					stopped = true;

					if (this.isTpoUser) {
						let found = false;
						this.generatedJob.disclosureInfos.forEach(disInfo => {
							disInfo.dataAuditRuleMessages?.forEach(msg => {
								if (msg.losFieldId == 'LE1.X1') {
									msg.severity = 'Required';
									found = true;
									disInfo.status = LosDisclosureStatus.Failed;
									if (!disInfo.errors)
										disInfo.errors = 'Fatal, business rule violations';
								}
							});
						});
						if (found) {
							this.generatedJob.jobStatus = LosDisclosureJobStatus.Failed;
							this.generatedJob.errors = this.generatedJob.errors || "Please Save & Re-Audit";
						}
					}

					this.generatedJob.disclosureInfos.forEach(disInfo => {
						this.collectIncorrectValuesFromAuditRules(disInfo);
					});

					this.auditPassedNoRecommendations = !this.thereAreRecommendedFieldValuesInAudits(this.generatedJob) &&
						!this.thereAreRequiredFieldValuesInAudits(this.generatedJob) &&
						this.generatedJob.jobStatus != "Failed";

					this.loadingData = false;

					return this.auditPassedNoRecommendations;
				}
				await new Promise(resolve => setTimeout(resolve, 1000)); // wait 1 sec
			}
		} catch (error) {
			this.errors = error.message;
			this.loadingData = false;
			isOk = false;
		}

		return isOk;
	}

	private queueDisclosuresStreamlined = async (application: LoanApplication): Promise<boolean> => {
		this.isStepRunning = true;
		this.loadingData = true;
		this.errors = null;
		this.leDocumentUrlToView = null;

		try {
			this.jobType = LosDisclosureJobTypeEnum.queue;
			const res = await firstValueFrom(this._losService.auditGenerateAndSendDisclosures(this.credentialId, application.applicationId, this._lockId,
				{
					options: this._disclosureOptions,
					job: this.generatedJob
				}));
			this.isWizardCompleted = true;
			this.disclosuresHaveBeenQueued = true;
			this._signalRService.connection.invoke("RegisterForLoanEvents", application.applicationId);
			this.applicationContextService.updateApplicationTrackingStatuses();
			const urlRoot = this.applicationContext.isTpo ? 'tpo' : 'admin';
			this._router.navigate([`/${urlRoot}/app-details/${application.applicationId}/disclosures`], {
				state: {
				},
				queryParams: {
					disclosuresPending: true,
					v: Date.now()
				}
			});
			return true;
		} catch (error) {
			this.errors = getErrorMessageOrDefault(error, {
				defaultMessage: 'Error queueing disclosures.',
			});
		} finally {
			this.loadingData = false;
			this.isStepRunning = false;
		}
	}

	private navigateBackward = async () => {
		if (this.activeStepIndex > 0) {
			this.activeStepIndex--;
		}

		this.errors = null;

		if (this.activeStepIndexWithOffset == 1) {
			const isLoanValid = await this.validateLoan(this.applicationContext.application);
			if (isLoanValid) {
				await this.goToNextStep();
				return
			}
		}

		if (this.activeStepIndexWithOffset == 2) {
			this.selectedDisclosureType = null;
			this.generatedJob = null;
		}

		if (this.activeStepIndexWithOffset == 3) {
			await this.queueDisclosuresStreamlined(this.applicationContext.application);
		}

		this.updateCurrentStepRelatedState();
	}

	private allDataValid = (res: LoanValidationResponse): boolean => {
		let w = 0;
		let x = 0;
		let y = 0;
		let z = 0;

		w = res.elibilityCheckRuleStatus?.filter(x => !x.passed).length || 0;
		x = res.packageConditionReports?.filter(r => !r.passed).length || 0;
		y = res.validationRulePassReports?.filter(r => !r.passed).length || 0;
		z = res?.planCodePassed ? 0 : 1;

		return (w + x + y + z) == 0;
	}

	private updateCurrentStepRelatedState = () => {
		this.atFirstStep = this.activeStepIndex === 0;
		this.setNextButtonLabel();
	}

	private setNextButtonLabel = () => {
		if (this.activeStepIndexWithOffset === 1) {
			this.nextButtonLabel = this.loanValidationResponse && this.thereAreInvalidFieldValues(this.loanValidationResponse) ? "Correct Incorrect Values" : "Next >";
		} else if (this.activeStepIndexWithOffset === 2) {
			this.nextButtonLabel = "Review Loan Estimate";
		} else if (this.activeStepIndexWithOffset === 3) {
			this.nextButtonLabel = "Approve and Send";
		}
	}

	private thereAreInvalidFieldValues = (res: LoanValidationResponse): boolean => {
		const failed = !!res.validationRulePassReports?.filter(r => !r.passed).length;
		if (failed) {
			return res.validationRulePassReports.some(r => r.incorrectValues.length);
		}
		return false;
	}

	private collectIncorrectValuesFromAuditRules = (res: LosDisclosureInfo): void => {
		res.dataAuditRuleMessages?.forEach(val => {
			if (val.losFieldId) {
				let req = new LosSetFieldRequest();
				req.fieldId = val.losFieldId;
				req.format = val.dataFormat;
				req.type = val.dataType;
				req.requestedValue = null;
				req.borrowerPairId = res.metadata?.borrowerPairId;

				this.correctedValues[val.losFieldId] = req;
			}
		});
	}

	private markFileForManualDisclosures = (application: LoanApplication) => {
		this.errors = "This file requires a second review validation, disclosures can't be processed automatically and have been flagged to be sent out by our Disclosure Team manually.";
		this.sendForManualDisclosure = true;
		const request = {
			"TpoManualDisclosureRequested": new Date().toISOString()
		}

		//TPO Submit loan
		this._losService.submitTpoLoan(this.credentialId, application.applicationId, this._lockId).subscribe().add(() => {
			this._loanService.saveKeyDatesByType(request, application.applicationId).subscribe(x => {
				this._losService.autoSyncLosLoan(application.applicationId, true, true).subscribe().add(() => {
					this.applicationContextService.updateApplicationTrackingStatuses();
				});
			});
		})
	}

	private collectIncorrectValues = (res: LoanValidationResponse): void => {
		let arr = [];
		res.validationRulePassReports?.forEach(r => {
			arr = arr.concat(...r.incorrectValues);
		});
		this.incorrectValues = arr;

		this.incorrectValues.forEach(val => {
			let req = new LosSetFieldRequest();
			req.fieldId = val.fieldId;
			req.format = val.format;
			req.type = val.type;
			req.requestedValue = null;

			this.correctedValues[val.fieldId] = req;
		});
	}

	private thereAreHardStops = (res: LoanValidationResponse): boolean => {
		const failed = !!res.elibilityCheckRuleStatus?.filter(r => !r.passed).length;
		if (failed) {
			return res.elibilityCheckRuleStatus.filter(r => !r.passed).some(x => ['BrokerAffiliatedWithEscrow',
				'LoanIsTBD',
				'LoanTypeNotSupported',
				'UnsupportedDisclosureType',
				'LoanIsDPA',
				'ItemizationFeesIssue',
				'UnsupportedPropertyState',
				'MissingBorrowerEmails',
				'LoanHasLockVariance',
				'LoanMissingBrokerInformation',
				'LoanHasCompensationConflict',
				'LoanProgramNotSupported',
				'NmlsComplianceReviewFailed',
				'BorrowerMissingValidPhoneNumber',
				'AmortizationTypeNotSupported',
				'ManualLocksNotSupported',
				'LoanHasBuyDownOption',
				'UspsVerificationHasRun'].indexOf(x.eligibilityFaultType) > -1);
		}
		return false;
	}

	private validateFields = (): boolean => {
		if (this.activeStepIndexWithOffset == 2) {
			if (this.disclosuresForm) {
				this.disclosuresForm.form.markAllAsTouched();
				if (!this.disclosuresForm.form.valid) {
					return false;
				}
			}
		}
		return true;
	}

	private getDisclosurePendingStatus = (type: LosDisclosureJobTypeEnum) => {
		let pendingStatus: LosDisclosureStatus;
		switch (type) {
			case LosDisclosureJobTypeEnum.audit:
				pendingStatus = LosDisclosureStatus.AuditPending;
				break;
			default:
				break;
		}
		return pendingStatus;
	}

	private resetLoanValidationRelatedControlState = () => {
		this.loadingData = true;
		this.isLoanDataValid = false;
		this.loanValidationResponse = null;
		this.invalidPackageConditions = [];
		this.errors = null;
		this.isStepRunning = true;
		this.generatedJob = null;
		this.validateStepSkipped = false;
		this.correctedValues = {};
	}

	private correctData = async (application: LoanApplication): Promise<boolean> => {

		let filledFields = Object.keys(this.correctedValues).filter(fieldId => !!this.correctedValues[fieldId].requestedValue).map(fieldId => this.correctedValues[fieldId]);

		if (!filledFields.length) {
			return Promise.resolve(true);
		}

		this.loadingData = true;
		this.generatedJob = null;
		this.correctingData = true;

		let isOK = await firstValueFrom(this._losService.updateIncorrectLoanFields(this.credentialId, filledFields, application.applicationId, this._lockId))
			.then(() => {

				this.loadingData = false;
				this.correctingData = false;

				if (this.activeStepIndexWithOffset == 1) {
					this.validateLoan(application);
				}
				else {
					this.auditLoanJob(application); // re-audit
				}
				return true;
			})
			.catch((err) => {
				this.loadingData = false;
				this.correctingData = false;

				this._notificationService.showError(
					err?.message || "Couldn't correct data",
					'Disclosure'
				);
				return false;
			})

		return isOK;
	}
}
