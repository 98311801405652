import {Component, Input, OnInit} from '@angular/core';
import {Configuration} from '../../../../../models/configuration.model';
import {DataService} from '../../../../../core/services/data.service';
import {NotificationService} from '../../../../../services/notification.service';
import {finalize} from 'rxjs/operators';
import {ThirdPartyCredential} from '../../../../../models';
import { ScopeType } from '../integrations.component';

@Component({
    selector: 'social-media',
    templateUrl: './social-media.component.html'
})
export class SocialMediaComponent implements OnInit {
    @Input() scope: ScopeType;
    @Input() socialMediaIntegrationEnabled: Configuration;
    @Input() ayrshare: ThirdPartyCredential;

    keyValuePairs = {
        profileRefId: null
    };
    socialMediaProfileLoading: boolean;

    constructor(
        private readonly _dataService: DataService,
        private readonly _notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        if (!this.ayrshare) {
            this.ayrshare = {
                credentialType: 'SocialMedia',
                vendorName: 'Ayrshare',
                thirdPartyKeyValuePairs: [],
                alias: '',
                url: '',
                userName: '',
                password: '',
            };
        }

        this.keyValuePairs.profileRefId = this.ayrshare.thirdPartyKeyValuePairs.find(el => el.key === 'ProfileRefId') || {key: 'ProfileRefId'};
    }

    linkSocialMediaAccounts(): void {
        this.socialMediaProfileLoading = true;
        this._dataService.post(`api/social-media/profile?setAsCompanyDefault=true`, {})
            .subscribe({
                next: res => {
                    this.openSocialMediaConsent();
                },
                error: (err) => {
                    let message = 'Unable to get social media profile';
                    if (err && err.message && this.isJson(err.message)) {
                        const jsonMessage = JSON.parse(err.message);
                        if (jsonMessage.message) {
                            message = jsonMessage.message;
                        }
                    }
                    this._notificationService.showError(message, 'Error');
                    this.socialMediaProfileLoading = false;
                }
            });
    }

    openSocialMediaConsent(): void {
        const redirectUrl = encodeURIComponent(`${window.location.origin}/admin/${this.scope === 'User' ? 'profile' : 'system-level'}`);
        this._dataService.get(`api/social-media/consent-url?redirectUrl=${redirectUrl}`, {responseType: 'text'})
            .pipe(finalize(() => {
                this.socialMediaProfileLoading = false;
            }))
            .subscribe({
                next: res => {
                    window.open(res, '_blank');
                },
                error: (err) => {
                    this._notificationService.showError('Unable to get the consent url.', 'Error');
                }
            });
    }

    isJson(str): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
