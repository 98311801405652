import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication, LoanStatus } from 'src/app/models';

@Component({
  selector: 'tpo-loan-status',
  templateUrl: 'tpo-loan-status.component.html',
  styleUrls: ['./tpo-loan-status.component.scss']
})
export class TpoLoanStatusComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Input()
  loanStatus: LoanStatus;

  @Input()
  isTpoSubmitted: boolean = false;

  constructor() { }

  ngOnInit() { }
}
