<div class="row">
  <div class="card-body" *ngFor="let borrower of mortgage.borrowers">
    <borrower-income [borrower]="borrower" (totalIncomeChanged)="onTotalIncomeChanged($event)">
    </borrower-income>
  </div>
  <div class="card-body">
    <div class="table-responsive" style="border-bottom: none;">
      <table class="table mb-0">
        <tbody>
          <tr>
            <td class="w-33 text-end">
              <span class="fw-bold me-2"> Total Monthly Household Income </span>
            </td>
            <td> <span class="fw-bold"> {{totalHouseholdIncome | currency}} </span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
