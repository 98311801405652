import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExpressionBuilderDialog } from './expression-builder-dialog.component';
import { ExpressionBuilderDialogService } from './services/expression-builder-dialog.service';
import { CustomDataService } from './services/custom-data.service';
import { CustomDataFieldComponent } from './components';
import { NgSelectModule } from '@ng-select/ng-select';
import { FunctionalExpressionService } from './services/functional-expression.service';

@NgModule({
    imports: [SharedModule, NgSelectModule],
    declarations: [ExpressionBuilderDialog, CustomDataFieldComponent],
    exports: [ExpressionBuilderDialog],
    providers: [ExpressionBuilderDialogService, CustomDataService, FunctionalExpressionService]
})
export class ExpressionBuilderModule {
  constructor() {}
}
