<div class="row mb-2">
  <div class="table-responsive" style="border-bottom: none;">
    <table class="table mb-0">
      <thead class="table-light">
        <tr>
          <th class="col-2"> Employer</th>
          <th class="col-1"> Self Employed </th>
          <th class="col-2" *ngIf="isSelfEmployed"> Ownership Share </th>
          <th class="col-2" *ngIf="isSelfEmployed"> Monthly Income/Loss </th>
          <th class="col-2" *ngIf="!isSelfEmployed"> </th>
          <th class="col-2" *ngIf="!isSelfEmployed"> </th>
          <th class="col-2"> Monthly </th>
          <th class="col-1 text-center"> Action </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let employment of employments; let index = index;" style="height: 50px;">

          <td class="col-2">
            <input class="form-control" name="companyName" [(ngModel)]="employment.companyName">
          </td>

          <td class="col-1">
            <select id="employment_{{employment.employmentId}}" name="employment_{{employment.employmentId}}"
              class="form-select" [(ngModel)]="employment.isSelfEmployed"
              (ngModelChange)="selfEmployedChanged()">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of yesNoOptions" [ngValue]="option.value">
                {{ option.name }}
              </option>
            </select>
          </td>
          <td class="col-2" *ngIf="employment.isSelfEmployed">
            <select id="ownershipShare_{{employment.employmentId}}" name="ownershipShare_{{employment.employmentId}}"
              class="form-select" [(ngModel)]="employment.borrowerOwnershipShare">
              <option [ngValue]="null"></option>
              <option *ngFor="let type of ownershipShareTypes" [ngValue]="type.value">
                {{ type.name }}
              </option>
            </select>
          </td>
          <td class="col-2" *ngIf="!employment.isSelfEmployed"></td>
          <td class="col-2" *ngIf="!employment.isSelfEmployed"></td>
          <td class="col-2" *ngIf="employment.isSelfEmployed">
            <currency-input
              (change)='onSelfEmployedIncomeOrLossChanged(employment)'
              [(ngModel)]='employment.selfEmploymentMonthlyIncomeOrLoss'
              name='selfEmploymentMonthlyIncomeOrLoss_{{employment.employmentId}}'>
            </currency-input>
          </td>
          <td class="col-2">
            <div class="d-flex">
              <div class="flex-grow-1">
                <currency-input
                  [(ngModel)]='employment.calculatedStats.monthlyIncome'
                  [readonly]='true'
                  name='monthlyIncome{{employment.employmentId}}'>
                </currency-input>
              </div>
              <a href="javascript: void(0);" class="mt-2" (click)="onCalculatorClicked(employment)"><i
                  class="fa fa-calculator ms-2"></i> </a>
            </div>
          </td>
          <td class="col-1 text-center">
            <a href='javascript: void(0);' (click)='deleteEmploymentClicked(index)'><i
                class="fa fa-trash-alt mx-2"></i></a>
          </td>
        </tr>
        <tr>
          <td class="col-2">
          </td>
          <td class="col-2">
          </td>
          <td class="col-1">
          </td>
          <td class="col-1 text-end">
            <b>Total</b>
          </td>
          <td class="col-1">
            <b><span class="ps-2"> {{totalMonthlyIncome | currency}} </span></b>
          </td>
          <td class="col-1">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>