<p-table
  *ngIf="!isLoading; else loading"
  #dt1
  [columns]="selectedColumns"
  [value]="appointments"
  [paginator]="true"
  [rows]="25"
  [resizableColumns]="true"
  responsiveLayout="scroll"
  name="appointment-list-table-v2"
  [rowsPerPageOptions]="[10, 25, 50]"
  [globalFilterFields]="globalFilterFields"
  [(selection)]="selectedRows"
  [autoLayout]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  styleClass="p-datatable-gridlines"
  [selectionPageOnly]="true"
  [rowSelectable]="isRowSelectable"
>
  <ng-template pTemplate="caption">
    <div class="d-flex justify-content-between">
      <span>
        <button
          class="btn btn-primary"
          (click)="setAppointmentClicked()"
          *ngIf="recordType != 'Agent'"
        >
          Set Appointment
        </button>
      </span>
      <span>
        <p-multiSelect
          [options]="columns"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          selectedItemsLabel="{0} columns selected"
          name="selectedColumns"
          ngDefaultControl
          defaultLabel="Choose Columns"
        ></p-multiSelect>

        <span class="p-input-icon-right ms-2 h-100">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            class="form-control h-100"
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="text-center">Action</th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td class="text-center">
        <span class="d-flex align-items-center justify-content-center w-100">
          <span>
            <i
              class="fa fa-pencil-alt cursor text-primary"
              (click)="editAppointment(rowData)"
              title="Edit"
            ></i>
          </span>

          <span class="mx-2"> | </span>

          <span>
            <i
              *ngIf="!isDeleteClicked[rowData.eventId]"
              class="fa fa-trash-alt cursor text-danger"
              (click)="isDeleteClicked[rowData.eventId] = true"
              title="Delete"
            ></i>

            <i
              *ngIf="isDeleteClicked[rowData.eventId]"
              class="fa fa-times cursor text-danger me-2"
              (click)="isDeleteClicked[rowData.eventId] = false"
              title="Cancel"
            ></i>

            <i
              *ngIf="isDeleteClicked[rowData.eventId]"
              class="fa fa-check-circle cursor text-danger"
              (click)="deleteAppointment(rowData)"
              title="Delete"
            ></i>
          </span>
        </span>
      </td>
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'dateStart'">
          {{ rowData['dateStart'] | date: 'short' }}
        </span>

        <span *ngSwitchCase="'dateEnd'">
          {{ rowData['dateEnd'] | date: 'short' }}
        </span>

        <span *ngSwitchDefault>
          {{ rowData[col.field] }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="selectedColumns.length + 1" class="text-center">
        There are no appointments
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading..."></loading-indicator>
</ng-template>
