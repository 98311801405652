<div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4 form-group">
        <label for="signerName_{{sellerBorrowerType.source}}_{{index}}"> Signer Name </label>
        <input type="text" class="form-control" id="signerName_{{sellerBorrowerType.source}}_{{index}}"
          name="signerName_{{sellerBorrowerType.source}}_{{index}}" [(ngModel)]="signer.name"
          [disabled]="sellerBorrowerType.type == 'Entity' && signer.noSignerInformation">
      </div>
      <div class="col-md-4 form-group">
        <label for="title_{{sellerBorrowerType.source}}_{{index}}"> Title </label>
        <input type="text" class="form-control" id="title_{{sellerBorrowerType.source}}_{{index}}"
          name="title_{{sellerBorrowerType.source}}_{{index}}" [(ngModel)]="signer.title"
          [disabled]="sellerBorrowerType.type == 'Entity' && signer.noSignerInformation">
      </div>
      <div class="col-md-4" *ngIf="sellerBorrowerType.type == 'Entity'">
        <div class="d-inline-flex mt-4">
          <div class="form-check">
            <label for="trusteeCheck_{{sellerBorrowerType.source}}_{{index}}" class="form-check-label"> No signer
              information </label>
            <input id="trusteeCheck_{{sellerBorrowerType.source}}_{{index}}"
              name="trusteeCheck_{{sellerBorrowerType.source}}_{{index}}" class="form-check-input"
              [(ngModel)]="signer.noSignerInformation" type="checkbox" (change)="noSignerInfoClicked()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1">
    <div class="text-end mt-4 me-3">
      <a class="text-danger" (click)="onDeleteClicked(index)" title="Delete signer"><i class="fas fa-trash-alt"></i></a>
    </div>
  </div>
</div>