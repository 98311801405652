import { MortgageDuAusRecommendation, MortgageLpaAusRecommendation } from "../modules/admin/tpo-config/models/configuration-product.model";
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from "../modules/app-details/components/title-history/models/title-order.model";
import { RefinancePrimaryPurposeTypeEnum, RefinancePurposeTypeEnum } from "../modules/disclosure-tracking/models/disclosure-tracking.model";
import { ProjectType, PropertyAttachmentType, PropertyConstructionMethod } from "../modules/leads/models/lead.model";
import { AmortizationType } from "../modules/reports/mortgage-call/models/mcr-audit-data.model";
import { ChannelEnum } from "./channels.model";

export class MiQuoteSearchRequest {
  credentialId: number;
  borrowerInformation: MiQuoteSearchBorrowerInformation;
  loanInformation: MiQuoteSearchLoanInformation;
  propertyInformation: MiQuoteSearchPropertyInformation;
  searchCriteria: MiQuoteSearchCriteria;
  requestorInformation: MiQuoteSearchRequestorInformation;

  constructor() {
    this.borrowerInformation = new MiQuoteSearchBorrowerInformation();
    this.loanInformation = new MiQuoteSearchLoanInformation();
    this.propertyInformation = new MiQuoteSearchPropertyInformation();
    this.searchCriteria = new MiQuoteSearchCriteria();
    this.requestorInformation = new MiQuoteSearchRequestorInformation();
  }
}

export class MiQuoteSearchBorrowerInformation {
  housingExpenseRatioPercent: number;
  debtToIncomeRatio: number;
  borrowerDetails: MiQuoteSearchBorrowerInfo[] = [];
}

export class MiQuoteSearchBorrowerInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  creditScore: number;
  bankruptcy: boolean;
  forclosure: boolean;
  isFirstTimeHomeBuyer: boolean;
  isSelfEmployed: boolean;
  socialSecurityNumber: string;
}

export class MiQuoteSearchLoanInformation {
  loanNumber: string;
  loanPurpose: LoanPurposeTypeEnum;
  refinancePurpose: RefinancePurposeTypeEnum;
  refinancePrimaryPurpose: RefinancePrimaryPurposeTypeEnum;
  baseLoanAmount: number;
  loanTerm: number;
  amortizationTerm: number;
  interestRate: number;
  amortizationType: AmortizationType;
  armFixedTermMonths: number;
  armSubsequentChangePeriodMonths: number;
  buydown: BuydownEnum;
  specialLoanProgram: MiSpecialLoanProgramEnum;
  loanProgramOtherDescription: string;
  duAusRecommendation: MortgageDuAusRecommendation;
  lpaAusRecommendation: MortgageLpaAusRecommendation;
  isCorporateRelocation: boolean;
  isRehabilitation: boolean;
}

export class MiQuoteSearchPropertyInformation {
  address: string;
  city: string;
  county: string;
  countyFips: string;
  state: string;
  zipCode: string;
  appraisedValue: number;
  salesPrice: number;
  isConstruction: boolean;
  numberOfStories: number;
  numberOfUnits: NumberOfUnitsEnum;
  occupancy: OccupancyEnum;
  attachmentType: PropertyAttachmentType;
  constructionMethod: PropertyConstructionMethod;
  manufacturedHomeWidthType: ManufacturedHomeWidthTypeEnum;
  projectType: ProjectType;
  projectDesignType: ProjectDesignTypeEnum;
  isPlannedUnitDevelopment: boolean;
}

export class MiQuoteSearchRequestorInformation {
  email: string;
  nmlsId: string;
  channel: ChannelEnum;
}

export class MiQuoteSearchCriteria {
  coveragePercentage: number;
}

export enum MiSpecialLoanProgramEnum {
  HFAAdvantage = "HFAAdvantage",
  HFAPreferred = "HFAPreferred",
  HFAPreferredRiskSharing = "HFAPreferredRiskSharing",
  HomePossibleAdvantage = "HomePossibleAdvantage",
  HomePossibleMortgage = "HomePossibleMortgage",
  HomeReady = "HomeReady",
  Other = "Other",
  RefiNow = "RefiNow",
  RefiPossible = "RefiPossible"
}

export enum BuydownEnum {
  OneOne = "OneOne",
  OneZero = "OneZero",
  ThreeTwoOne = "ThreeTwoOne",
  TwoOne = "TwoOne",
  OneOneOne = "OneOneOne"
}

export enum NumberOfUnitsEnum {
  FourUnits = "FourUnits",
  OneUnit = "OneUnit",
  ThreeUnits = "ThreeUnits",
  TwoUnits = "TwoUnits"
}

export enum OccupancyEnum {
  InvestmentProperty = "InvestmentProperty",
  PrimaryResidence = "PrimaryResidence",
  SecondHome = "SecondHome"
}

export enum ManufacturedHomeWidthTypeEnum {
  SingleWide = "SingleWide",
  MultiWide = "MultiWide"
}

export enum ProjectDesignTypeEnum {
  GardenProject = "GardenProject",
  HighriseProject = "HighriseProject",
  MidriseProject = "MidriseProject",
  Other = "Other",
  TownhouseRowhouse = "TownhouseRowhouse"
}
