import { Component, Input } from "@angular/core";
import { WireRequestModel } from "../../../models/wire-request.model";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";

@Component({
  templateUrl: "create-wire-request-coborrower.component.html",
  selector: "create-wire-request-coborrower",
})
export class CreateWireRequestCoBorrower {
  @Input()
  wireRequest: WireRequestModel;

  @Input()
  ethnicities: EnumerationItem[];

  @Input()
  races: EnumerationItem[];

  @Input()
  sexTypes: EnumerationItem[];

  @Input()
  isCoBorrowerDisabled: boolean;

  constructor() {}
}