<h3 class="align-items-start flex-column">
  <span class="text-muted mt-1 fw-bold fs-7"
    >{{
      borrower.creditInquiry?.entries.length ? borrower.creditInquiry?.entries.length : 'There are no'
    }}
    inquiries</span
  >
</h3>
<div class="table-responsive" *ngIf="borrower.creditInquiry?.entries.length">
  <form
    #borrowerInfoForm="ngForm"
    novalidate
    id="borrowerInfoForm"
    name="borrowerInfoForm"
  >
    <table
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-150px">Inquirer</th>
          <th class="min-w-140px">Date</th>
          <th class="min-w-100px">Reason For Inquiry</th>
          <th class="min-w-100px">Was New Debt Acquired</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of borrower.creditInquiry?.entries; let i = index">
          <td>
            <span class="text-dark fw-bold d-block fs-7">{{ entry.fullName }}</span>
          </td>
          <td>
            <span class="text-dark d-block fs-7">{{ entry?.creditInquiryDate | date:'MMM dd, yyyy':'GMT' }}</span>
          </td>
          <td>
            <select
              class="form-select form-select-solid"
              [(ngModel)]="entry!.reason"
              data-control="select2"
              id="reason{{ borrower.borrowerId }}{{ i }}"
              name="reason{{ borrower.borrowerId }}{{ i }}"
              [ngClass]="{
                'is-invalid': reason && reason.touched && reason.invalid
              }"
              #reason="ngModel"
            >
              <option value="">Select one...</option>
              <option value="AutoLoan">Auto Loan</option>
              <option value="CreditCard">Credit Card</option>
              <option value="MortgageShopping">Mortgage Shopping</option>
            </select>
          </td>
          <td>
            <select
              class="form-select form-select-solid"
              [(ngModel)]="entry!.newDebtAcquired"
              data-control="select2"
              id="newDebtAcquired{{ borrower.borrowerId }}{{ i }}"
              name="newDebtAcquired{{ borrower.borrowerId }}{{ i }}"
              [ngClass]="{
                'is-invalid':
                  newDebtAcquired &&
                  newDebtAcquired.touched &&
                  newDebtAcquired.invalid
              }"
              #newDebtAcquired="ngModel"
            >
              <option value="">Select one...</option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
