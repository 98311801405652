export class Message {
    messageId?: number;
    applicationId: number;
    loanDocTaskId?: number;
    borrowerId?: number;
    userId: string;
    content: string;
    flagInternalMessage?: boolean;
    isLosMessage?: boolean;
    postTime?: string;
    senderId?: string;
    senderFirstName?: string;
    senderLastName?: string;
    senderDisplayName?: string;
    senderAvatar?: string;
    notifyPartyId?: Array<string>;
    companyId?: number;
    insertedBy?: string;
    dateInserted?: string;
    updatedBy?: string;
    dateUpdated?: string;
    openConversationId?: string
}