import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { finalize } from 'rxjs';
import { ChangePasswordRequest } from 'src/app/models/auth/change-password-request.model';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorMessage } from 'src/app/shared/models/error.model';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  changePasswordRequest: ChangePasswordRequest;
  changePasswordForm: UntypedFormGroup = new UntypedFormGroup({});

  error: ErrorMessage | undefined;

  changeDone: boolean = false;
  changingPassword: boolean = false;

  constructor(private readonly _authService: AuthService) {
    this.changePasswordRequest = new ChangePasswordRequest('', '', '');
    this.changePasswordForm = new UntypedFormGroup({
      oldPassword: new UntypedFormControl(this.changePasswordRequest.oldPassword),
      newPassword: new UntypedFormControl(this.changePasswordRequest.newPassword),
      confirmPassword: new UntypedFormControl(this.changePasswordRequest.confirmPassword)
    }, { validators: (group: AbstractControl) => this._matchStringValidator(group, 'newPassword', 'confirmPassword') });
  }

  // form controls
  get oldPassword() { return this.changePasswordForm.get('oldPassword'); }
  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get confirmPassword() { return this.changePasswordForm.get('confirmPassword'); }

  ngOnInit(): void {
  }

  onChangePasswordClicked = () => {

    this.changePasswordForm.markAllAsTouched();
    if (!this.changePasswordForm.valid) return;

    this.error = null;
    this.changingPassword = true;
    this._authService.changePassword(this.changePasswordRequest)
      .pipe(finalize(() => {
        this.changingPassword = false;
      }))
      .subscribe((response) => {
        this.changeDone = true;
      }, (err) => {
        this.changeDone = false;
        this.error = new ErrorMessage(
          'Unable to change password!',
          err.error
        );
      });
  }

  private _matchStringValidator = (group: AbstractControl, nameOne: string, nameTwo: string): ValidationErrors | null => {
    const firstValue = group.get(nameOne)?.value;
    const secondValue = group.get(nameTwo)?.value;
    return !firstValue || !secondValue || firstValue === secondValue ? null : { stringDoesntMatch: true }
  }
}
