import { Component, Input, OnInit } from '@angular/core';
import { LosDisclosureInfo } from '../../../request-models/los-disclosure-gen-job';

@Component({
  selector: 'encompass-disclosure-failed-docs',
  templateUrl: './encompass-disclosure-failed-docs.component.html',
  styleUrls: ['./encompass-disclosure-failed-docs.component.scss']
})
export class EncompassDisclosureFailedDocsComponent implements OnInit {

  @Input() info: LosDisclosureInfo;

  columns: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.columns = [
      { field: 'name', header: 'Name' },
    ];
  }

}
