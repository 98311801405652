import { Component, Injector, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Company, CompanyExtended, ConfigurationCompany, SystemLevel } from 'src/app/models';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ZipCodeLookupResult } from '../../../../models/zipcode-lookup-result.model';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevelService } from '../../../../services/system-level.service';
import { State } from '../../../leads/components/lead-editor/lead-general-info/lead-general-info.component';
import { forkJoin } from 'rxjs';
import { CompanyService } from 'src/app/modules/global-admin/company/services/company.service';
import { cloneDeep } from 'lodash';
import { DiffChecker } from 'src/utils/diff-checker';

@Component({
  selector: 'company-profile-ext',
  templateUrl: './company-profile-ext.component.html'
})
export class CompanyProfileExtComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input() companyExtendedModel: CompanyExtended;

  states: State[] = [];
  isSaving: boolean;
  isSunLivingHack: boolean = false;
  isLoading: boolean;

  companyModel: Company;

  companyExtendedModelClone: CompanyExtended;
  companyModelClone: Company;

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _companyService: CompanyService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.companyExtendedModel) {
      const observables = [
        this._systemLevelService.getSystemLevel(),
        this._companyService.getCompanyBasic()
      ];
      this.isLoading = true;
      forkJoin(observables)
      .subscribe((results) => {
        const systemLevel = JSON.parse(JSON.stringify(results[0]));
        this.companyExtendedModel = systemLevel.companyExtendedModel || new CompanyExtended();
        this.companyExtendedModelClone = cloneDeep(this.companyExtendedModel);

        this.companyModel = results[1] || new Company();
        this.companyModelClone = cloneDeep(this.companyModel);

      }).add(() => this.isLoading = false);
    } else {
      this.isLoading = true;
      this._companyService.getCompanyBasic().subscribe((result) => {
        this.companyModel = result || new Company();

      }).add(() => this.isLoading = false);
    }
    this.states = Object.keys(this.applicationContext.globalConfig.states)
      .map(key => new State({ code: key, name: this.applicationContext.globalConfig.states[key] }));

    this.isSunLivingHack = this.applicationContext.userPermissions.companyId == 170;
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    this.companyExtendedModel.mainPOCCity = zipCode.city;
    this.companyExtendedModel.mainPOCState = zipCode.state.toLocaleLowerCase();
  };

  save(): void {
    let observables = [];
    const companyExtendedModelDiffChecker = new DiffChecker(
      this.companyExtendedModel,
      this.companyExtendedModelClone,
      'companyExtendedModel'
    );
    const companyModelDiffChecker = new DiffChecker(
      this.companyModel,
      this.companyModelClone,
      'companyModel'
    );
    if (companyExtendedModelDiffChecker.calculateDiff()) {
      observables.push(this._systemLevelService.saveCompanyExtended(this.companyExtendedModel));
    }
    if (companyModelDiffChecker.calculateDiff()) {
      observables.push(this._companyService.updateCompanyBasic(this.companyModel));
    }

    if (observables.length > 0) {
      this.isSaving = true;
      forkJoin(observables).pipe(finalize(() => {
        this.isSaving = false;
      })).subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
    } else {
      this._notificationService.showWarning('Any field not changed.', 'System Level');
    }
    
  }
}
