import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, Borrower, LoanApplication, LoanStatus } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'loan-summary-card-admin',
  templateUrl: 'loan-summary-card-admin.component.html',
  styleUrls: ['./loan-summary-card-admin.component.scss']
})
export class LoanSummaryCardAdminComponent implements OnInit {

  @Input()
  set application(application: LoanApplication) {
    this._application = application;
  }

  get application(): LoanApplication {
    return this._application;
  }

  @Input()
  set borrowers(borrowers: Borrower[]) {
    this._borrowers = borrowers;
    this._borrowers.forEach(borrower => {
      borrower['fullName'] = Utils.getPersonsFullName(borrower);
    })
  }

  get borrowers(): Borrower[] {
    return this._borrowers;
  }

  protected loanPurposeName: string = "";
  protected applicationIdWithPadding: string = "";
  protected loanTypeName: string = "";

  private _contextSubscription: Subscription;

  private _loanTypes: LoanType[] = [];
  private _loanPurposes: LoanPurpose[] = [];
  private _loanStatuses: LoanStatus[] = [];

  private _borrowers: Borrower[] = [];
  private _application: LoanApplication;
  private _appContext: ApplicationContext;

  protected disableBorrowerPortalInviteLink: boolean = false;

  constructor(
    private _applicationContextService: ApplicationContextService,
    private _spinnerService: NgxSpinnerService,
    private _notificationService: NotificationService,
    private _configurationService: ConfigurationService
  ) {
    this._contextSubscription = this._applicationContextService.context.subscribe((context) => {
      this._appContext = context;

      this._loanTypes = context.globalConfig.loanType;
      this._loanPurposes = context.globalConfig.loanPurpose;
      this._loanStatuses = context.globalConfig.loanStatus;
    });
  }

  ngOnInit() {
    this.populateLoanInfo(this._application);
    this.populateDisabledBorrowerInviteChannels();
  }

  private populateLoanInfo = (application: LoanApplication) => {

    this._application = application;

    this.applicationIdWithPadding = 1 + Utils.padLeft(this._application.applicationId + '', '0', 9);

    const loanPurpose = this._loanPurposes.find(
      (lp) =>
        lp.loanPurposeId ===
        application.loanPurposeId,
    );
    if (loanPurpose) {
      this.loanPurposeName = loanPurpose.loanPurposeName;
    }

    const loanType = this._loanTypes.find(
      (lt) =>
        lt.loanTypeId === application.loanTypeId,
    );
    if (loanType) {
      this.loanTypeName = loanType.loanTypeName;
    }

    const loanStatus = this._loanStatuses.find(ls => ls.loanStatusId === application.loanStatusId);
    if (loanStatus) {
      //this.loanStatus = loanStatus.loanStatusName;
    }
  }

  private populateDisabledBorrowerInviteChannels = () => {
    this._spinnerService.show();
    this._configurationService.getCompanyConfiguration('BorrowerInviteDisabledChannels').subscribe({
      next: (borrowerInviteDisabledChannels) => {
        this._spinnerService.hide();

        if (borrowerInviteDisabledChannels?.valueStr?.trim()) {
          const channels = borrowerInviteDisabledChannels.valueStr?.split(',');
          if (!channels) {
            return;
          }

          let disabledBorrowerInviteChannels = [];
          channels.forEach(disabledChannel => {
            const companyChannels = this._appContext.globalConfig.enabledChannels;

            const channel = companyChannels.find(c => c.name == disabledChannel);
            if (channel) {
              disabledBorrowerInviteChannels.push(channel.name);
            }
          });

          if (disabledBorrowerInviteChannels.length && disabledBorrowerInviteChannels.includes(this.application.channel)) {
            this.disableBorrowerPortalInviteLink = true;
          }
        }
      },
      error: (err) => {
        this._spinnerService.hide();
        this._notificationService.showError(err.message || 'Unable to get system level.','Error!');
      }
    })

  }
}
