import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { MiPaymentType, MiQuoteDetail, MiQuoteSearchResult } from 'src/app/models/mi-quote-search-result.model';
import { MiQuoteSearchDialogComponent, SelectedMiQuoteInfo } from '../mi-quote-search-dialog/mi-quote-search-dialog.component';

@Component({
  selector: 'mi-quote-search-results',
  templateUrl: './mi-quote-search-results.component.html',
  styleUrls: ['./mi-quote-search-results.component.scss']
})
export class MiQuoteSearchResultsComponent implements OnInit {

  @Input() result: MiQuoteSearchResult;
  @Input() showReRunSearch: boolean = false;
  @Input() isTPO: boolean = false;
  @Input() interestRate: number;
  @Output() quoteSelected: EventEmitter<SelectedMiQuoteInfo> = new EventEmitter();
  @Output() quoteSelectedAfterReRun: EventEmitter<SelectedMiQuoteInfo> = new EventEmitter();

  filteredResults: MiQuoteDetail[] = [];
  tab: MiPaymentType = MiPaymentType.PeriodicMonthly;
  statusFilter: string = "Success";

  allResults: MiQuoteDetail[] = [];
  selectedQuote: MiQuoteDetail = null;

  constructor(private readonly _modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.allResults = _.cloneDeep(this.result.results);
    this.onStatusFilterChanged();
  }

  filterByPaymentType = (paymentType: MiPaymentType) => {
    this.filteredResults = this.allResults.filter(r => r.status == this.statusFilter && r.paymentType == paymentType);

    if (this.tab != MiPaymentType.Unknown) {
      this.allResults.forEach(r => {
        if (r['isExpanded']) {
          r['isExpanded'] = false;
        }
      });
    };
  }

  onStatusFilterChanged = () => {
    this.filteredResults = this.allResults.filter(r => r.status == this.statusFilter);
    this.filterByPaymentType(this.tab);
  }

  showQuoteDetails = (detail: MiQuoteDetail) => {
    window.open(detail.quoteDetailUrl, '_blank').focus();
  }

  onSelectQuote = (detail: MiQuoteDetail) => {
    this.selectedQuote = detail;
    this.quoteSelected.emit({ selectedMiQuote: detail, quoteResults: this.allResults });
  }

  onReRunSearch = () => {
    const modalRef = this._modalService.open(MiQuoteSearchDialogComponent, {
      windowClass: 'modal-w-90',
      backdrop: 'static',
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.interestRate = this.interestRate;
    modalRef.result.then((data: SelectedMiQuoteInfo) => {
      this.selectedQuote = data.selectedMiQuote;
      this.quoteSelectedAfterReRun.emit(data);
    }, () => {
    });
  }

}
