export class MentionsResponse {
  mentionCount: number;
  mentions: Mention[];
  unreadMentionCount: number;
  readMentionCount: number;
}

export class Mention {
  id: number;
  messageId: number;
  messageModel: MentionMessage
  mentionedUserId: string;
  fromUserId: string;
  trackingGuid: string;
  openDate: string;
  companyId: number;
  insertedB: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class MentionMessage {
  messageId: number;
  leadId: number;
  applicationId	: number;
  loanDocTaskId	: number;
  borrowerId	: number;
  agentId	: number;
  userId: string;
  content: string;
  flagInternalMessage: boolean;
  isLosMessage: boolean;
  postTime: string;
  senderId: string;
  senderFirstName: string;
  senderLastName: string;
  senderAvatar: string;
  notifyPartyId: number[] = [];
  mentionTrackingGuid: string;
  messageScope: MentionMessageScope;
  companyId	: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum MentionMessageScope {
  Unknown = "Unknown",
  InternalMessage = "InternalMessage",
  BorrowerMessage = "BorrowerMessage",
  AgentMessage = "AgentMessage",
  AgentNote = "AgentNote",
  TaskNote = "TaskNote"
}
