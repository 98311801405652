import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable } from 'rxjs';
import { CheckList } from '../models/checklist.model';
import { LookupType, Question } from '../models/question.model';
import { map } from 'rxjs/operators';
import { ChecklistAnswer } from '../models/check-list-answer.model';
import { QuestionAnswer } from '../models/question-answer.model';

export class QuestionSummary {
  questionId: number;
  questionText: string;
  questionType: LookupType;
  checklistId: number;
  versionId: number;
  borrowerId: number;
  borrowerDisplayName: string;
  answers: QuestionAnswerSummary[]
  submittedAnswers: SubmittedAnswer[];
  userAnswer?: string | number | Array<number>;
}

export class QuestionAnswerSummary {
  questionAnswerId: number
  answerText: string;
}

export interface QuestionConfiguration {
  questionId: number;
  answers: Array<AnswerConfiguration>
}

export interface AnswerConfiguration {
  questionAnswerId: number,
  questions: Array<QuestionConfiguration>
}
export class QuestionType {
  companyId: number
  id: number
  lookupDescription: string;
  lookupGroup: "QuestionType"
  lookupOrder: number;
  lookupTypeID: number;
  lookupValue: string;
}

export interface CheckListQuestion {
  answers?: Array<CheckListAnswer>;
  questionId: number;
  isValid: boolean;
  checklistId: number;
  versionId: number;
  validForBorrowerIds: number[];
  submittedAnswers?: SubmittedAnswer[];
}

export interface SubmittedAnswer {
  checklistAnswerId: number;
  answerText?: string;
  borrowerId?: number;
  questionAnswerId?: number;
  applicationId: number;
  checklistVersionId: number;
  questionId: number;
}

export interface CheckListAnswer {
  questionAnswerId: number;
  submittedAnswers: SubmittedAnswer[];
  questions: Array<CheckListQuestion>
}

export interface CheckListWithQuestions {
  id: number;
  name: string;
  questions: CheckListQuestion[]
}

@Injectable()
export class CheckListService {

  constructor(private readonly _dataService: DataService) { }

  // question CRUDs
  getChecklistQuestions = (checkListId: number, applicationId: number): Observable<CheckListQuestion[]> => {
    const url = `api/checklist/${checkListId}/for-app/${applicationId}`;
    return this._dataService.get(url).pipe(
      map(checklist => {
        return checklist.questions
          .filter(q => q.isValid)
          .map((q: CheckListQuestion) => {
            this.extendQuestion(q, checklist.currentVersion.checklistVersionId, checklist.checklistId);
            return q;
          })
      })
    );
  }

  getChecklistQuestionsForApplicationAndStatus = (loanStatusId: number, applicationId: number, includeQuestions: boolean): Observable<CheckListQuestion[]> => {
    let url = `api/checklist/for-app/${applicationId}/status/${loanStatusId}`;
    if (includeQuestions) {
      url += '?includeQuestions=true'
    }
    const questions: CheckListQuestion[] = [];
    return this._dataService.get(url).pipe(
      map(checklists => {
        checklists.forEach(checklist => {
          const extendedQuestions = checklist.questions.map((q: CheckListQuestion) => {
            this.extendQuestion(q, checklist.currentVersion.checklistVersionId, checklist.checklistId);
            return q;
          });
          questions.push(...extendedQuestions);
        });
        return questions.filter(q => q.isValid);
      })
    );
  }

  getChecklistQuestionsForApplicationOnUrlaCompletion = (applicationId: number, includeQuestions: boolean): Observable<CheckListQuestion[]> => {
    let url = `api/checklist/for-app/${applicationId}/on-urla-completion`;
    if (includeQuestions) {
      url += '?includeQuestions=true'
    }
    const questions: CheckListQuestion[] = [];
    return this._dataService.get(url).pipe(
      map(checklists => {
        checklists.forEach(checklist => {
          const extendedQuestions = checklist.questions.map((q: CheckListQuestion) => {
            this.extendQuestion(q, checklist.currentVersion.checklistVersionId, checklist.checklistId);
            return q;
          });
          questions.push(...extendedQuestions);
        });
        return questions.filter(q => q.isValid);
      })
    );
  }

  getChecklistQuestionsForApplicationOnAppSubmission = (applicationId: number, includeQuestions: boolean): Observable<CheckListQuestion[]> => {
    let url = `api/checklist/for-app/${applicationId}/on-app-submit`;
    if (includeQuestions) {
      url += '?includeQuestions=true'
    }
    const questions: CheckListQuestion[] = [];
    return this._dataService.get(url).pipe(
      map(checklists => {
        checklists.forEach(checklist => {
          const extendedQuestions = checklist.questions.map((q: CheckListQuestion) => {
            this.extendQuestion(q, checklist.currentVersion.checklistVersionId, checklist.checklistId);
            return q;
          });
          questions.push(...extendedQuestions);
        });
        return questions.filter(q => q.isValid);
      })
    );
  }

  getChecklistsDetailsByApplicationId = (applicationId: number): Observable<CheckListWithQuestions[]> => {
    const url = `api/checklist/for-app/${applicationId}?includeQuestions=true`;
    const checklistWithQuestions: CheckListWithQuestions[] = [];
    return this._dataService.get(url).pipe(
      map(checklists => {
        checklists.forEach(checklist => {
          const extendedQuestions = checklist.questions.map((q: CheckListQuestion) => {
            this.extendQuestion(q, checklist.currentVersion.checklistVersionId, checklist.checklistId);
            return q;
          });
          checklistWithQuestions.push({
            name: checklist.checkListName,
            id: checklist.checklistId,
            questions: extendedQuestions.filter(q => q.isValid)
          });
        });
        return checklistWithQuestions;
      })
    );
  }

  private extendQuestion(question: CheckListQuestion, versionId: number, checklistId: number) {
    question.versionId = versionId;
    question.checklistId = checklistId;
    question.answers.forEach(a => {
      a.questions.forEach(q => {
        this.extendQuestion(q, versionId, checklistId);
      })
    });
  }

  getAllQuestionsWithAnswers = (): Observable<QuestionSummary[]> => {
    const url = 'api/admin/checklist-config/question-answer/group-by-question';
    return this._dataService.get(url);
  }

  getAllQuestions = (): Observable<Question[]> => {
    const url = `api/admin/checklist-config/question/all`;
    return this._dataService.get(url);
  }

  insertQuestion = (question: Question): Observable<number> => {
    const url = 'api/admin/checklist-config/question';
    return this._dataService.post(url, question);
  }

  updateQuestion = (question: Question): Observable<any> => {
    const url = `api/admin/checklist-config/question/${question.questionId}`;
    return this._dataService.post(url, question);
  }

  deleteQuestion = (questionId: number): Observable<any> => {
    const url = `api/admin/checklist-config/question/${questionId}`;
    return this._dataService.delete(url);
  }

  getQuestionTypes = (): Observable<QuestionType[]> => {
    const url = `api/Admin/PageResource/Question`;
    return this._dataService.get(url)
      .pipe(map((result) => {
        return result.questionType
      }));
  }

  // question answer CRUDs
  getAllQuestionAnswers = (questionId: number): Observable<QuestionAnswer[]> => {
    const url = `api/admin/checklist-config/question-answer/all?questionId=${questionId}`;
    return this._dataService.get(url)
  }

  insertQuestionAnswer = (questionAnswer: QuestionAnswer): Observable<any> => {
    const url = 'api/admin/checklist-config/question-answer';
    return this._dataService.post(url, questionAnswer);
  }

  updateQuestionAnswer = (question: QuestionAnswer): Observable<any> => {
    const url = `api/admin/checklist-config/question-answer/${question.questionAnswerId}`;
    return this._dataService.post(url, question);
  }

  deleteQuestionAnswer = (questionAnswerId: number): Observable<any> => {
    const url = `api/admin/checklist-config/question-answer/${questionAnswerId}`;
    return this._dataService.delete(url);
  }

  getAllChecklists = (): Observable<CheckList[]> => {
    const url = `api/admin/checklist-config/checklist/all`;
    return this._dataService.get(url);
  };

  getChecklistById = (checklistId: number): Observable<CheckList> => {
    const url = `api/admin/checklist-config/checklist/${checklistId}`;
    return this._dataService.get(url);
  }

  insertChecklistItem = (ChecklistItem: CheckList): Observable<any> => {
    const url = 'api/admin/checklist-config/checklist';
    return this._dataService.post(url, ChecklistItem);
  }

  updateChecklistItem = (ChecklistItem: CheckList): Observable<any> => {
    const url = `api/admin/checklist-config/checklist/${ChecklistItem.checklistId}`;
    return this._dataService.post(url, ChecklistItem);
  }

  deleteChecklistItem = (itemId: number): Observable<any> => {
    const url = `api/admin/checklist-config/checklist/${itemId}`;
    return this._dataService.delete(url);
  }

  getMortgageFunctions = (): Observable<string[]> => {
    const url = `api/admin/checklist-config/mortgage-functions`;
    return this._dataService.get(url);
  }

  getBorrowerFunctions = (): Observable<string[]> => {
    const url = `api/admin/checklist-config/borrower-functions`;
    return this._dataService.get(url);
  }

  getMortgageFunctionsTest = (expression: string): Observable<boolean> => {
    const url = `api/admin/checklist-config/mortgage-functions/test`;
    return this._dataService.post(url, `'${expression}'`);
  }

  getBorrowerFunctionsTest = (expression: string): Observable<boolean> => {
    const url = `api/admin/checklist-config/borrower-functions/test`;
    return this._dataService.post(url, `'${expression}'`);
  }

  // checklist answer CRUDs
  getAllChecklistAnswers = (checklistId: number, loanId?: number): Observable<ChecklistAnswer[]> => {
    return this._dataService.get(`api/checklist/${checklistId}/answers${loanId ? '?loanId=' + loanId : ''}`);
  }

  insertAnswerToChecklist = (answer: any, checklistId: number): Observable<ChecklistAnswer> => {
    return this._dataService.post(`api/checklist/${checklistId}/answers`, answer);
  }

  updateAnswerInChecklist = (checklistId: number, answerId: number, answer: Partial<SubmittedAnswer>): Observable<ChecklistAnswer> => {
    return this._dataService.post(`api/checklist/${checklistId}/answers/${answerId}`, answer);
  }

  deleteAnswerInChecklist = (checklistId: number, answerId: number): Observable<ChecklistAnswer> => {
    const url = `api/checklist/${checklistId}/answers/${answerId}`;
    return this._dataService.delete(url);
  }
}
