import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { PrequalDetail } from '../models/prequal-detail.model';

@Injectable({
    providedIn: 'root'
})
export class PrequalDetailService {
    constructor(private readonly _dataService: DataService) { }

    getPrequalDetail = (appId: number) => {
        const url = `api/admin/prequal-detail?applicationId=${appId}`;
        return this._dataService.get(url);
    }

    postPrequalDetail = (data: PrequalDetail): Observable<PrequalDetail> => {
        const url = `api/admin/prequal-detail`;
        return this._dataService.post(url, data);
    };
}
