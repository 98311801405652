import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MortgageBorrower } from 'src/app/models';

@Component({
  selector: 'app-new-mortgage-borrower-dialog',
  templateUrl: './new-mortgage-borrower-dialog.component.html',
  styleUrls: ['./new-mortgage-borrower-dialog.component.scss']
})
export class NewMortgageBorrowerDialogComponent implements OnInit {

  @Input() mortgageId: number;
  @Input() loanId: number;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onSavedBorrowerFinished = (newBorrower: MortgageBorrower) => {
    this.activeModal.close(newBorrower);
  }
}
