<div class="modal-header">
    <h5 class="modal-title text-center">Reassign Tasks?</h5>
</div>
<div class="modal-body">
    <div class="row m-3 text-center">
        <span>
            Would you like to reassign all "auto generated" tasks currently assigned to the old internal contact to the new
            internal contact?
        </span>
    </div>
</div>
<div class="modal-footer form-footer text-center">
    <button type="button" class="btn btn-secondary" (click)="confirmReassign('false')">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmReassign('true')">Yes</button>
</div>