import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocumentGenerateComponent } from './components/edit-task-dialog/components/document-generate/document-generate.component';
import { DocumentSigningDialogComponent } from './components/edit-task-dialog/components/document-signing-dialog/document-signing-dialog.component';
import { EsignDocumentDialogComponent } from './components/edit-task-dialog/components/esign-document-dialog/esign-document-dialog.component';
import { LinkExistingDocumentsDialogComponent } from './components/edit-task-dialog/components/link-existing-documents-dialog/link-existing-documents-dialog.component';
import { QuickInsuredOrderDialogComponent } from './components/edit-task-dialog/components/task-order/task-order-hoi/quick-insured-order-dialog/quick-insured-order-dialog.component';
import { TaskOrderHoiComponent } from './components/edit-task-dialog/components/task-order/task-order-hoi/task-order-hoi.component';
import { AddDocumentsDialogComponent } from './components/edit-task-dialog/components/task-order/task-order-title/appraisal-task-order/add-documents-dialog/add-documents-dialog.component';
import { AddNotesDialogComponent } from './components/edit-task-dialog/components/task-order/task-order-title/appraisal-task-order/add-notes-dialog/add-notes-dialog.component';
import { AppraisalTaskOrderComponent } from './components/edit-task-dialog/components/task-order/task-order-title/appraisal-task-order/appraisal-task-order.component';
import { QuotesTaskOrderComponent } from './components/edit-task-dialog/components/task-order/task-order-title/quotes-task-order/quotes-task-order.component';
import { TaskOrderTitleComponent } from './components/edit-task-dialog/components/task-order/task-order-title/task-order-title.component';
import { EditTaskDialogComponent } from './components/edit-task-dialog/edit-task-dialog.component';
import { TaskEditorComponent } from './components/task-editor/task-editor.component';
import { TaskNoteDisplayComponent } from './components/task-note-display/task-note-display.component';
import { TaskNotesComponent } from './components/task-notes/task-notes.component';
import { TaskStatsCardComponent } from './components/task-stats-card/task-stats-card.component';
import { AddRejectionNotesDialogComponent } from './components/task-table/add-rejection-notes-dialog/add-rejection-notes-dialog.component';
import { ConfirmPostponeDialogComponent } from './components/task-table/confirm-postpone-dialog/confirm-postpone-dialog.component';
import { ConfirmResponsiblePartyChangeDialogComponent } from './components/task-table/confirm-responsible-party-change-dialog/confirm-responsible-party-change-dialog.component';
import { ConfirmSnoozeDialogComponent } from './components/task-table/confirm-snooze-dialog/confirm-snooze-dialog.component';
import { ConfirmStatusDialogComponent } from './components/task-table/confirm-status-dialog/confirm-status-dialog.component';
import { FileTrackingDialogComponent } from './components/task-table/file-tracking-dialog/file-tracking-dialog.component';
import { TaskTableComponent } from './components/task-table/task-table.component';
import { ViewFileComponent } from './components/task-table/view-file/view-file.component';
import { TaskTableV2Component } from './components/task-tablev2/task-tablev2.component';
import { TaskEditorQCUComponent } from './components/task-editor-qcu/task-editor-qcu.component';
import { TaskEditorGatherPaymentComponent } from './components/task-editor-gather-payment/task-editor-gather-payment.component';
import { TaskEditorEsignComponent } from './components/task-editor-esign/task-editor-esign.component';
import { TaskEditorEscalationComponent } from './components/task-editor-escalation/task-editor-escalation.component';
import { TaskEditorCocComponent } from './components/task-editor-coc/task-editor-coc.component';
import { TaskEditorDocumentComponent } from './components/task-editor-document/task-editor-document.component';
import { TaskEditorOtherComponent } from './components/task-editor-other/task-editor-other.component';
import { TaskLoanDocPreviewModule } from './components/task-table/task-loan-doc-preview-v2/task-loan-doc-preview-v2.module';
import { ChecklistsModule } from '../admin/checklists/checklists.module';
import { TitleOrderDocumentsDialogComponent } from './components/edit-task-dialog/components/task-order/task-order-title/title-order-documents-dialog/title-order-documents-dialog.component';
import { TaskEditorCocV2Component } from './components/task-editor-coc-v2/task-editor-coc-v2.component';
import { CocDetailsModule } from '../coc-details/coc-details.module';

@NgModule({
  declarations:
    [
      TaskStatsCardComponent,
      TaskTableComponent,
      TaskTableV2Component,
      ViewFileComponent,
      FileTrackingDialogComponent,
      ConfirmResponsiblePartyChangeDialogComponent,
      ConfirmPostponeDialogComponent,
      ConfirmSnoozeDialogComponent,
      ConfirmStatusDialogComponent,
      EditTaskDialogComponent,
      DocumentGenerateComponent,
      LinkExistingDocumentsDialogComponent,
      EsignDocumentDialogComponent,
      DocumentSigningDialogComponent,
      TaskOrderTitleComponent,
      TaskOrderHoiComponent,
      QuickInsuredOrderDialogComponent,
      QuotesTaskOrderComponent,
      AppraisalTaskOrderComponent,
      AddNotesDialogComponent,
      AddDocumentsDialogComponent,
      TaskEditorComponent,
      TaskEditorQCUComponent,
      AddRejectionNotesDialogComponent,
      TaskNoteDisplayComponent,
      TaskNotesComponent,
      TaskEditorEsignComponent,
      TaskEditorCocComponent,
      TaskEditorCocV2Component,
      TaskEditorDocumentComponent,
      TaskEditorOtherComponent,
      TaskEditorGatherPaymentComponent,
      TaskEditorEscalationComponent,
      TitleOrderDocumentsDialogComponent,
    ],
  imports: [
    SharedModule,
    TableModule,
    CalendarModule,
    PopoverModule.forRoot(),
    NgSelectModule,
    TaskLoanDocPreviewModule,
    ChecklistsModule,
    CocDetailsModule
  ],
  exports: [
    TaskStatsCardComponent,
    TaskTableComponent,
    TaskTableV2Component,
    EditTaskDialogComponent,
    TaskEditorComponent,
    TaskEditorQCUComponent,
    TaskNoteDisplayComponent,
    TaskEditorGatherPaymentComponent,
    TaskEditorEsignComponent,
    TaskEditorEscalationComponent,
    TaskEditorCocComponent,
    TaskEditorCocV2Component,
    TaskEditorDocumentComponent,
    TaskEditorOtherComponent,
    ViewFileComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class TasksModule { }
