import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LdeService } from 'src/app/services/lde.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'lde-sync-direction',
  templateUrl: 'lde-sync-direction.component.html'
})

export class LdeSyncDirectionComponent implements OnInit {

  @Input()
  applicationId: number;

  @Input()
  syncDirection: string = "";

  isSaving = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _ldeService: LdeService,
    private readonly _notificationService: NotificationService) {
  }

  ngOnInit(): void {

  }

  save() {
    this.isSaving = true;
    this._ldeService.syncDirection(this.applicationId, this.syncDirection).subscribe(() => {
      this.isSaving = false;
      this._notificationService.showInfo('Sync direction changed.', 'Success');
      this.activeModal.close(this.syncDirection);
    }, error => {
      this.isSaving = false;
      this._notificationService.showError(error ? error.message : 'Unable to change sync direction.', 'Error');
    });
  }
}
