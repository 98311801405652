import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'infinite-scroll',
  template: `<ng-content></ng-content><div style="height: {{height}}" #anchor>&nbsp;</div>`,
})
export class InfiniteScrollComponent implements AfterViewInit, OnDestroy {

  @Input()
  options = {};

  @Input()
  height = "30px"

  @Output()
  scrolled = new EventEmitter();

  @ViewChild('anchor')
  anchor: ElementRef<HTMLElement>;

  private observer: IntersectionObserver;

  constructor(private readonly _host: ElementRef) { }

  ngAfterViewInit(): void {
    const options = {
      root: this.isHostScrollable() ? this._host.nativeElement : null,
      ...this.options
    };

    this.observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && this.scrolled.emit();
    }, options);

    this.observer.observe(this.anchor.nativeElement);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private get element() {
    return this._host.nativeElement;
  }

  private isHostScrollable() {
    const style = window.getComputedStyle(this.element);

    return style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll';
  }
}
