import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address-detail-view-dialog',
  templateUrl: './address-detail-view-dialog.component.html',
  styleUrls: ['./address-detail-view-dialog.component.scss']
})
export class AddressDetailViewDialogComponent implements OnInit {

  @Input() googlePlaceId: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.loadStreetView();
  }

  loadStreetView = () => {

    let mapElement = document.getElementById('map') as HTMLElement;

    const map = new google.maps.Map(mapElement);

    let neighbourhoodService = new google.maps.places.PlacesService(map);

    neighbourhoodService.getDetails({ placeId: this.googlePlaceId }, (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {

      console.log(result);

      let mapProp: google.maps.MapOptions = {
        center: result.geometry.location,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        tilt: 0
      };

      let maxZoomService = new google.maps.MaxZoomService();

      maxZoomService.getMaxZoomAtLatLng(result.geometry.location, (result: google.maps.MaxZoomResult) => {
        if (result.status == google.maps.MaxZoomStatus.OK) {
          mapProp.zoom = result.zoom;

          map.setOptions(mapProp);

        }

      });

      let marker = new google.maps.Marker({
        position: result.geometry.location,
        map: map,
        icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|FFFF00',
        label: { text: result.name, color: "white", fontWeight: "bold", fontSize: "large" ,className: "property-address-marker" }
      });

      google.maps.event.trigger(map, "resize");

    })
  }

}
