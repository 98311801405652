import {Component, Input, OnInit} from '@angular/core';
import {TableColumn} from '../../../../../shared/models/tale-column.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {SystemLevelService} from '../../../../../services/system-level.service';
import {Constants} from '../../../../../services/constants';
import {GlobalSmsModalComponent} from './global-sms-modal/global-sms-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../../../../services/notification.service';
import { FullSizeScrollableTableComponent } from 'src/app/shared/components/full-size-scrollable-table-base.component';

@Component({
    selector: 'global-sms',
    templateUrl: './global-sms.component.html'
})
export class GlobalSmsComponent extends FullSizeScrollableTableComponent implements OnInit {
    globalFilterFields: string[] = ['name', 'serviceType'];
    columns: TableColumn[] = [];
    services: any[] = [];
    isLoading: boolean = false;
    isDeleteClicked = {};

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _spinner: NgxSpinnerService,
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService
    ) {
        super();
        this.scrollOffset = 400;
    }

    ngOnInit(): void {
        this.columns = [
            {field: 'name', header: 'Name', visible: true},
            {field: 'serviceType', header: 'Service Type', visible: true}
        ];
        this.fetchAll();
    }

    private fetchAll() {
        this._systemLevelService
            .getGlobalServices('sms')
            .subscribe({
                next: res => {
                    this.services = res;
                }
            });
    }

    public onAddEdit(row?): void {
        const modalRef = this._modalService.open(GlobalSmsModalComponent, Constants.modalOptions.medium);
        modalRef.componentInstance.service = row ? Object.assign({}, row) : { serviceType: '' };
        modalRef.result.then(
            (result) => {
                if (row) {
                    this.services = this.services.map(el => el.telephonyServiceId === row.telephonyServiceId ? result : el);
                } else {
                    this.services = [
                        ...this.services,
                        ...[result]
                    ];
                }
            },
            () => {}
        );
    }

    public onDelete(row): void {
        this._spinner.show();
        this._systemLevelService.deleteGlobalService('sms', row.telephonyServiceId)
            .subscribe({
                next: res => {
                    this.services = this.services.filter(el => el.telephonyServiceId !== row.telephonyServiceId);
                    this._spinner.hide();
                },
                error: err => {
                    this._spinner.hide();
                    this._notificationService.showError(
                        err ? err.message : 'Unable to save global sms.',
                        'Global SMS'
                    );
                }
            });
    }
}
