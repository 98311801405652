import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { cloneDeep, concat, orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  ContactListColumnDefinition,
  ContactListType,
  KeyDateConfig,
  KeyDateGroup,
  LoanStatus,
  Role,
} from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Task } from 'src/app/models/task/task.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ContactListService } from 'src/app/services/contact-list.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { KeyDateGroupComponent } from '..';

@Component({
  templateUrl: 'key-date.component.html',
  styleUrls: ['key-date.component.scss'],
})
export class KeyDatesComponent
  extends ApplicationContextBoundComponent
  implements OnInit {

  @ViewChildren('keyDateGroup')
  keyDateGroupComponents: QueryList<KeyDateGroupComponent> | undefined;

  companyId: number;
  loadingData: boolean;
  unassignedKeyDates: Array<KeyDateConfig> = [];
  keyDateGroups: Array<KeyDateGroup> = [];
  leftKeyDateGroups: Array<KeyDateGroup> = [];
  rightKeyDateGroups: Array<KeyDateGroup> = [];
  expressionFields: ContactListColumnDefinition[] = [];
  lookupData: {
    roles: Array<Role>;
    enabledChannels: Array<EnumerationItem>;
    loanStatuses: Array<LoanStatus>;
    taskStatuses: Array<EnumerationItem>;
    tasks: Array<Task>;
    allKeyDateTypes: Array<EnumerationItem>;
    usedKeyDateTypes: Array<{ keyDateConfigurationId: number, keyDateType: string; channels: string }>;
    allSystemTaskTypes: Array<EnumerationItem>;
  };
  currentKeyDate: Partial<KeyDateConfig>;
  upsertKeyDateDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  currentKeyDateGroup: Partial<KeyDateGroup>;
  upsertKeyDateGroupDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  keyDatesGroupSortableOptions = {
    group: {
      name: 'keyDateGroup',
    },
    animation: 150,
    fallbackOnBody: true,
    invertSwap: true,
    emptyInsertThreshold: 20,
    handle: '.handle',
    onEnd: (evt) => {
      if (evt.from.id === evt.to.id && evt.newIndex === evt.oldIndex) {
        return;
      }

      this.onGroupDropped();
    },
  };

  keyDateSortableOptions = {
    group: {
      name: 'keyDate',
    },
    animation: 150,
    fallbackOnBody: true,
    invertSwap: true,
    emptyInsertThreshold: 20,
    handle: '.handle',
    onEnd: (evt) => {
      if (evt.from.id === evt.to.id && evt.newIndex === evt.oldIndex) {
        return;
      }

      const keyDateConfigurationId: number = parseInt(
        evt.item.firstChild.id.split('_')[1]
      );
      if (!keyDateConfigurationId) return;

      let keyDate: KeyDateConfig = null;
      if (evt.to.classList.contains('key-date__group--body')) {
        // to group
        const newKeyDateGroupId: number = parseInt(evt.to.id.split('_')[1]);
        if (!newKeyDateGroupId) return;

        keyDate = this.onKeyDateMovedToGroup(
          keyDateConfigurationId,
          newKeyDateGroupId
        );
        if (!keyDate) return;
      } else {
        // to unassigned
        keyDate = this.onKeyDateMovedToUnassigned(keyDateConfigurationId);
        if (!keyDate) return;
      }

      this.onKeyDateDropped(keyDate);
    },
  };

  readonly baseMessageTitle = 'Key Dates';

  constructor(
    private readonly injector: Injector,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _notificationService: NotificationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _enumerationService: EnumerationService,
    private readonly _contactListService: ContactListService,
    private readonly _drawerService: DrawerService,
    private readonly _spinner: NgxSpinnerService,
  ) {
    super(injector);
    this.companyId = this.applicationContext.userPermissions.companyId;

    this._applicationContextService.context.subscribe((res) => {
      this.lookupData = {
        roles: res.globalConfig.roles,
        enabledChannels: res.globalConfig.enabledChannels,
        loanStatuses: res.globalConfig.loanStatus,
        taskStatuses: this._enumerationService.taskStatuses,
        tasks: res.globalConfig.tasks,
        allKeyDateTypes: [],
        usedKeyDateTypes: [],
        allSystemTaskTypes: []
      };
      this.populateKeyDateTypes();
      this.populateSystemTaskTypes();
    });
  }

  ngOnInit(): void {
    this.getData();
    this._contactListService.getContactList().subscribe({
      next: (response) => {
        const contactList = response.find(contact => contact.contactListType === ContactListType.Application && contact.isCustom === false);
        this.expressionFields = this._contactListService.setContactListColumns(contactList?.columns);
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || "Couldn't get expression field list",
          this.baseMessageTitle
        )
      }
    });
  }

  openUpsertGroupDrawer(keyDateGroup: KeyDateGroup) {
    this.currentKeyDateGroup = cloneDeep(keyDateGroup) || {};
    this._drawerService.hideAll()
    this._drawerService.show("upsertKeyDateGroupDrawer", 100);
  }

  onKeyDateDeleted = (keyDate: KeyDateConfig) => {
    const groupIndex = this.keyDateGroups.findIndex(g => g.keyDateGroupId === keyDate.keyDateGroupId);
    if (groupIndex > -1 && this.keyDateGroups[groupIndex].keyDateConfigurations) {
      const indexToRemove = this.keyDateGroups[groupIndex].keyDateConfigurations.findIndex(k => k.keyDateConfigurationId === keyDate.keyDateConfigurationId);
      if (indexToRemove >= 0) {
        this.keyDateGroups[groupIndex].keyDateConfigurations.splice(indexToRemove, 1);
        this.keyDateGroups = [...this.keyDateGroups];
      }
    } else {
      const indexToRemove = this.unassignedKeyDates.findIndex(k => k.keyDateConfigurationId === keyDate.keyDateConfigurationId);
      if (indexToRemove >= 0) {
        this.unassignedKeyDates.splice(indexToRemove, 1);
        this.unassignedKeyDates = [...this.unassignedKeyDates];
      }
    }
    this.setUsedKeyDateTypes();
  }

  onKeyDateGroupDeleted = ({ keyDateGroupId, column }) => {
    if (column === 1) {
      const index = this.leftKeyDateGroups.findIndex(g => g.keyDateGroupId === keyDateGroupId);
      if (index > -1) {
        if (this.leftKeyDateGroups[index].keyDateConfigurations?.length > 0) {
          this.unassignedKeyDates = this.unassignedKeyDates
            .concat(this.leftKeyDateGroups[index].keyDateConfigurations.map(k => {
              delete k.keyDateGroupId;
              return k;
            }))
          this.unassignedKeyDates = [...this.unassignedKeyDates];
        }
        this.leftKeyDateGroups.splice(index, 1);
      }
    }
    if (column === 2) {
      const index = this.rightKeyDateGroups.findIndex(g => g.keyDateGroupId === keyDateGroupId);
      if (index > -1) {
        if (this.rightKeyDateGroups[index].keyDateConfigurations?.length > 0) {
          this.unassignedKeyDates = this.unassignedKeyDates
            .concat(this.rightKeyDateGroups[index].keyDateConfigurations.map(k => {
              delete k.keyDateGroupId;
              return k;
            }));
        }
        this.rightKeyDateGroups.splice(index, 1);
      }
    }

    this.sortGroups();
    this.unassignedKeyDates = [...this.unassignedKeyDates];
    this.keyDateGroups = [...this.leftKeyDateGroups, ...this.rightKeyDateGroups]
  }

  onUpsertKeyDateGroupDrawerClose(updatedKeyDateGroup: KeyDateGroup) {
    if (!updatedKeyDateGroup) {
      this._drawerService.hide("upsertKeyDateGroupDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertKeyDateGroupDrawer", 100);
    this.onGroupSave(updatedKeyDateGroup);
  }

  openUpsertKeyDateDrawer(keyDate: KeyDateConfig) {
    this.currentKeyDate = cloneDeep(keyDate) || {};
    this._drawerService.hideAll()
    this._drawerService.show("upsertKeyDateDrawer", 100);
  }

  onUpsertKeyDateDrawerClose() {
    this._drawerService.hide("upsertKeyDateDrawer", 100);
  }

  onUpsertKeyDateDrawerSave(keyDate: KeyDateConfig) {
    this.onKeyDateUpsert(keyDate);
    this._drawerService.hide("upsertKeyDateDrawer", 100);
  }

  sortKeyDatesByColumns() {
    this.leftKeyDateGroups = this.keyDateGroups.filter((g) => g.column === 1);
    this.rightKeyDateGroups = this.keyDateGroups.filter((g) => g.column === 2);

    this.sortGroups();
  }

  private populateKeyDateTypes() {
    this._enumerationService.getKeyDateTypeEnumeration()
      .subscribe({
        next: (keyDateTypes: EnumerationItem[]) => {
          this.lookupData.allKeyDateTypes = orderBy(keyDateTypes, ['name']);
        },
        error: (err) => {
          this._notificationService.showError(err || "Couldn't load key date types", this.baseMessageTitle);
        }
      });
  }

  private populateSystemTaskTypes() {
    this._enumerationService.getSystemTaskTypeEnumeration()
      .subscribe({
        next: (systemTaskTypes: EnumerationItem[]) => {
          this.lookupData.allSystemTaskTypes = orderBy(systemTaskTypes, ['name']);
        },
        error: (err) => {
          this._notificationService.showError(err || "Couldn't load system task types", this.baseMessageTitle);
        }
      });
  }

  private getData() {
    this.loadingData = true;
    const combined = combineLatest([
      this._keyDatesService.getKeyDatesGroups(this.companyId),
      this._keyDatesService.getUnasignedKeyDates(this.companyId),
    ]);

    combined
      .pipe(
        finalize(() => {
          this.loadingData = false;
        })
      )
      .subscribe({
        next: ([groups, unassignedKeyDates]) => {
          this.unassignedKeyDates = unassignedKeyDates;
          this.keyDateGroups = groups;
          this.setUsedKeyDateTypes();
          this.sortKeyDatesByColumns();
        },
        error: (err) => {
          this._notificationService.showError(
            err || "Couldn't load key date groups",
            this.baseMessageTitle
          );
        }
      });
  }

  private setUsedKeyDateTypes() {
    this.lookupData.usedKeyDateTypes = concat(
      this.unassignedKeyDates,
      this.keyDateGroups.flatMap(kdg => kdg.keyDateConfigurations)
    )
    .filter(kd => kd.keyDateType)
    .map(kd => ({ keyDateConfigurationId: kd.keyDateConfigurationId , keyDateType: kd.keyDateType, channels: kd.enabledChannels }));
  }

  private onGroupSave(group: KeyDateGroup) {
    const matchGroupIndex = this.keyDateGroups.findIndex(grp => grp.keyDateGroupId === group.keyDateGroupId);
    if (matchGroupIndex > -1) {
      this.keyDateGroups[matchGroupIndex] = group;
    } else {
      this.keyDateGroups.push(group);
    }
    this.sortKeyDatesByColumns();
  }

  private onKeyDateUpsert(keyDate: KeyDateConfig) {
    if (keyDate.keyDateGroupId) {
      const groupIndex = this.keyDateGroups.findIndex(grp => grp.keyDateGroupId === keyDate.keyDateGroupId);
      if (groupIndex > -1) {
        const index = this.keyDateGroups[groupIndex]?.keyDateConfigurations.findIndex(ukd => ukd.keyDateConfigurationId === keyDate.keyDateConfigurationId);
        if (index === -1) {
          this.keyDateGroups[groupIndex].keyDateConfigurations.push(keyDate);
        } else {
          this.keyDateGroups[groupIndex].keyDateConfigurations[index] = keyDate;
        }
        this.keyDateGroups = [...this.keyDateGroups];
      }
      this.setUsedKeyDateTypes();
      return;
    }
    const index = this.unassignedKeyDates.findIndex(
      (ukd) => ukd.keyDateConfigurationId === keyDate.keyDateConfigurationId
    )
    if (index === -1) {
      this.unassignedKeyDates.push(keyDate);
    } else {
      this.unassignedKeyDates[index] = keyDate;
    }
    this.setUsedKeyDateTypes();
    this.unassignedKeyDates = [...this.unassignedKeyDates];
  }

  private onKeyDateMovedToUnassigned(
    keyDateConfigurationId: number
  ): KeyDateConfig | undefined {
    const keyDate = this.unassignedKeyDates.find(
      (kd) => kd.keyDateConfigurationId === keyDateConfigurationId
    );
    if (!keyDate) return;

    keyDate.keyDateGroupId = null;
    return keyDate;
  }

  private onKeyDateMovedToGroup(
    keyDateConfigurationId: number,
    newKeyDateGroupId: number
  ): KeyDateConfig | undefined {
    const group = [...this.leftKeyDateGroups, ...this.rightKeyDateGroups].find(
      (group) => group.keyDateGroupId === newKeyDateGroupId
    );
    if (!group) return;

    const keyDate = group.keyDateConfigurations.find(
      (kd) => kd.keyDateConfigurationId === keyDateConfigurationId
    );
    if (!keyDate) return;

    keyDate.keyDateGroupId = newKeyDateGroupId;
    return keyDate;
  }

  private getGroupOrder(): Array<Array<number>> {
    let order: Array<Array<number>>;
    if (this.leftKeyDateGroups.length > this.rightKeyDateGroups.length) {
      order = this.leftKeyDateGroups.map((lg, index) => {
        return [
          lg.keyDateGroupId,
          this.rightKeyDateGroups[index]?.keyDateGroupId,
        ];
      });
    } else {
      order = this.rightKeyDateGroups.map((rg, index) => {
        return [
          this.leftKeyDateGroups[index]?.keyDateGroupId,
          rg.keyDateGroupId,
        ];
      });
    }

    return order;
  }

  private onGroupDropped() {
    this._spinner.show();
    const newOrder = this.getGroupOrder();
    this._keyDatesService
      .moveKeyDateGroup(this.companyId, newOrder)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Group moved successfully',
            `${this.baseMessageTitle} Group`
          );

          this.leftKeyDateGroups = this.leftKeyDateGroups.map((g, index) => ({ ...g, order: index + 1}));
          this.rightKeyDateGroups = this.rightKeyDateGroups.map((g, index) => ({ ...g, order: index + 1}));

          this.sortGroups();

          this.keyDateGroups = [...this.leftKeyDateGroups, ...this.rightKeyDateGroups];
        },
        error: (error) => {
          this._notificationService.showError(
            `${error?.message || "Couldn't move key date group"}`,
            `${this.baseMessageTitle} Group`
          );
        }
      });
  }

  private onKeyDateDropped(keyDate: KeyDateConfig) {
    this._spinner.show();

    this._keyDatesService.updateKeyDate(keyDate.keyDateConfigurationId, keyDate)
      .subscribe({
        next: () => {
          if (!keyDate.keyDateGroupId) {
            this._spinner.hide();
            this._notificationService.showSuccess(
              'Key date moved successfully',
              this.baseMessageTitle
            );
            this.keyDateGroups = [...this.leftKeyDateGroups, ...this.rightKeyDateGroups];
            return;
          }

          const index = this.keyDateGroups.findIndex(g => g.keyDateGroupId === keyDate.keyDateGroupId);
          const orderedKeyDatesIds = index > -1
            ? this.keyDateGroups[index].keyDateConfigurations.map(k => k.keyDateConfigurationId)
            : [];

          this._keyDatesService
            .reorderKeyDate(keyDate.keyDateGroupId, orderedKeyDatesIds)
            .pipe(finalize(() => this._spinner.hide()))
            .subscribe({
              next: (res) => {
                this._notificationService.showSuccess(
                  'Key date moved successfully',
                  this.baseMessageTitle
                );

                const group = this.keyDateGroups[index];
                const newOrderedKeyDates = orderedKeyDatesIds.map((id, index) => {
                  const keyDate = group.keyDateConfigurations.find(kd => kd.keyDateConfigurationId === id);
                  return { ...keyDate, order: index + 1 };
                })

                this.keyDateGroups[index].keyDateConfigurations = [...newOrderedKeyDates];

                this.keyDateGroups = [...this.keyDateGroups];
              },
              error: ({ error }) => {
                this._notificationService.showError(
                  `${error?.message || "Couldn't move key date"}`,
                  this.baseMessageTitle
                );
              }
            });
        },
        error: (error) => {
          this._notificationService.showError(
            `${error?.message || "Couldn't move key date"}`,
            this.baseMessageTitle
          );
          this._spinner.hide();
        }
      });
  }

  private sortGroups() {
    this.leftKeyDateGroups = this.leftKeyDateGroups.sort((a,b) => a.order > b.order ? 1 : -1);
    this.rightKeyDateGroups = this.rightKeyDateGroups.sort((a,b) => a.order > b.order ? 1 : -1);
  }

  onEdit(keyDate: KeyDateConfig) {
    const index = this.unassignedKeyDates.findIndex(
      (ukd) => ukd.keyDateConfigurationId === keyDate.keyDateConfigurationId
    )
    if (index === -1) return;

    this.unassignedKeyDates[index] = keyDate;
    this.unassignedKeyDates = [...this.unassignedKeyDates];
  }
}
