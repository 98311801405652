export enum QuarterEnum {
  QuarterOne = "QuarterOne",
  QuarterTwo = "QuarterTwo",
  QuarterThree = "QuarterThree",
  QuarterFour = "QuarterFour"
}

/**
 * Get the display name for a quarter enum value (e.g., QuarterOne => Q1).
 * @param {QuarterEnum} quarterEnum The quarter enum value.
 * @returns {string} The display name for the quarter enum value.
 * Returns an empty string if the quarter enum value is not recognized.
 */
export function getQuarterEnumDisplayName(quarterEnum: QuarterEnum): string {
  switch (quarterEnum) {
    case QuarterEnum.QuarterOne:
      return "Q1";
    case QuarterEnum.QuarterTwo:
      return "Q2";
    case QuarterEnum.QuarterThree:
      return "Q3";
    case QuarterEnum.QuarterFour:
      return "Q4";
    default:
      return "";
  }
}

/**
 * Get the quarter enum value for a display name (e.g., Q1 => QuarterOne).
 * @param {string} displayName The display name for the quarter enum value.
 * @returns {QuarterEnum} The quarter enum value.
 * Returns undefined if the display name is not recognized.
 */
export function getQuarterEnumValue(displayName: string): QuarterEnum | undefined {
  switch (displayName) {
    case "Q1":
      return QuarterEnum.QuarterOne;
    case "Q2":
      return QuarterEnum.QuarterTwo;
    case "Q3":
      return QuarterEnum.QuarterThree;
    case "Q4":
      return QuarterEnum.QuarterFour;
    default:
      return undefined;
  }
}
