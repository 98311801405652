import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-currency-input',
  templateUrl: 'urla-currency-input.component.html',
  styleUrls: ['urla-currency-input.component.scss'],
  providers: [MakeProvider(UrlaCurrencyInputComponent)],
})
export class UrlaCurrencyInputComponent extends BaseUrlaInputComponent {

  @ViewChild('control') input: ElementRef<HTMLInputElement>;

  @Input()
  suffix: string;

  @Input()
  hasCalculator: boolean;

  @Input()
  customIconClass: string = null;

  @Input()
  isEditableEvenIfItHasACalculator: boolean = false;

  @Input()
  isZeroValid: boolean = true;

  @Input()
  isOmitted: boolean = false;

  @Input()
  set allowNegative(allowNegative: boolean) {
    this._allowNegative = allowNegative;
    this.setRegexForValidation();
  }

  get allowNegative(): boolean {
    return this._allowNegative;
  }

  @Input()
  set allowDecimals(allowDecimals: boolean) {
    this._allowDecimals = allowDecimals;
    this.setRegexForValidation();
  }

  get allowDecimals(): boolean {
    return this._allowDecimals;
  }

  @Input()
  allowEmpty: boolean = true;

  @Output()
  calculatorClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  customIconClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  private _isFocused: boolean = false;

  private _caretPositionToMoveToInEditMode: number = 0;

  private _negativeOrPositiveIntegersRegex = /^-?[1-9]\d*$/;

  private _negativeOrPositiveDecimalsRegex = /^[-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

  private _positiveDecimalsRegex = /^[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

  private _positiveIntegersRegex = /^[1-9]\d*$/;

  private _regexForSanitation = /[^\d|\-+|\.+]/;

  private _regexForValidation: RegExp = null;

  private _allowNegative = true;

  private _allowDecimals = true;

  private _ctrlDown: boolean = false;

  constructor(private readonly _renderer2: Renderer2,
    private readonly _currencyPipe: CurrencyPipe) {
    super();
    this._regexForValidation = this._negativeOrPositiveDecimalsRegex;
  }

  ngOnInit(): void {
    this.setRegexForValidation();
  }

  override writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    if (this.input && !this._isFocused) {
      const formattedValue = this._currencyPipe.transform(this._value, 'USD', 'symbol', '1.2-2');
      setTimeout(() => {
        this._renderer2.setProperty(this.input.nativeElement, 'value', formattedValue);
      })
    }
  }

  override registerOnChange(fn: (_: number | null) => void): void {
    this.onChange = (value) => {
      let numericValue: number | null = null;
      if (!value) {
        if (value === 0 || !this.allowEmpty) {
          numericValue = 0;
        }
      } else {
        numericValue = parseFloat(value);
      }
      fn(numericValue);
    };
  }

  get failsValidationWithZeroValue(): boolean {
    if (this.isZeroValid) {
      return false;
    } else {
      return Number(this._value) == 0;
    }
  }

  onCalculatorClicked = () => {
    this.calculatorClicked.emit();
  }

  onCustomIconClicked = () => {
    this.customIconClicked.emit();
  }

  onPaste = (event: any) => {
    let pastedValue = (event.clipboardData || window.Clipboard).getData('text');
    let sanitized = pastedValue.replace(this._regexForSanitation, '');
    sanitized = Number(sanitized).toFixed(2);
    if (!this.allowDecimals) {
      sanitized = Math.floor(Number(sanitized));
    }
    let passes = this._regexForValidation.test(Number(sanitized).toString());
    if (!passes) {
      event.preventDefault();
    }
  }

  onKeyDown = (event: any) => {
    if (event.key === 'Tab' || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'F12'
      || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    } else if (event.which === 17) {
      // ctrl key or command key on a Mac
      this._ctrlDown = true;
    } else if (this._ctrlDown && [86, 67].indexOf(event.which) > -1) {
      // ctrl+c or ctrl+v
      return;
    }
    const enteredValue = event.key ? event.key : "";
    let valueToTest = this._value != null ? this._value.toString() : '';
    if (document.activeElement) {
      const activeelement: any = document.activeElement;
      valueToTest = valueToTest.substr(0, activeelement.selectionStart) + valueToTest.substr(activeelement.selectionEnd);
      valueToTest = [valueToTest.slice(0, activeelement.selectionStart), enteredValue, valueToTest.slice(activeelement.selectionStart)].join('');
    }
    if (valueToTest === '-' || valueToTest === '0' || valueToTest.charAt(valueToTest.length - 1) === '.') {
      valueToTest += '1';
    }
    let passes = this._regexForValidation.test(Number(valueToTest).toString());
    if (!passes) {
      event.preventDefault();
    }
  }

  onFocused = (event: any) => {
    this._isFocused = true;
    if (this.disabled || this.readonly || (this.hasCalculator && !this.isEditableEvenIfItHasACalculator)) {
      return;
    }
    let currentvalue = this._value != null ? this._value.toString() : '';
    if (!currentvalue && this.allowEmpty) {
      return;
    }

    let sanitized = currentvalue.replace(this._regexForSanitation, '');
    sanitized = currentvalue != 0 ? Number(sanitized).toFixed(2) : null;
    const formattedValue = this.input.nativeElement.value;

    setTimeout(() => {
      const activeelement: any = document.activeElement;
      if (activeelement) {
        const caretPositionWhenClicked = activeelement.selectionStart;
        this._caretPositionToMoveToInEditMode = caretPositionWhenClicked;
        for (let i = 0; i <= caretPositionWhenClicked - 1; i++) {
          if (['$', ','].includes(formattedValue.charAt(i))) {
            this._caretPositionToMoveToInEditMode -= 1;
          }
        }
      }
      this._renderer2.setProperty(this.input.nativeElement, 'value', sanitized);
      setTimeout(() => {
        this.moveCaret();
      })
    })
  }

  onBlurred = (event: any) => {
    this._isFocused = false;
    this.blur.emit(event);
    if (this.input) {
      let currentvalue = this._value != null ? this._value.toString() : '';
      if (!currentvalue && !this.allowEmpty) {
        currentvalue = "0";
      }
      let sanitized = currentvalue.replace(this._regexForSanitation, '');
      if (sanitized) {
        sanitized = Number(sanitized).toFixed(2);
        this._value = Number(sanitized);
        if (!this._allowDecimals) {
          this._value = Math.floor(this._value);
        }
      }
      setTimeout(() => {
        const formattedValue = this._currencyPipe.transform(this._value, 'USD', 'symbol', '1.2-2');
        this._renderer2.setProperty(this.input.nativeElement, 'value', formattedValue);
      })
    }
  }

  private moveCaret = () => {
    if (this.input.nativeElement.setSelectionRange) {
      this.input.nativeElement.setSelectionRange(this._caretPositionToMoveToInEditMode,
        this._caretPositionToMoveToInEditMode);
    }
  }

  private setRegexForValidation = () => {
    if (this._allowNegative) {
      if (this._allowDecimals) {
        this._regexForValidation = this._negativeOrPositiveDecimalsRegex;
      } else {
        this._regexForValidation = this._negativeOrPositiveIntegersRegex;
      }
    } else {
      if (this._allowDecimals) {
        this._regexForValidation = this._positiveDecimalsRegex;
      } else {
        this._regexForValidation = this._positiveIntegersRegex;
      }
    }
  }
}
