import { LoanProduct } from "src/app/models/config/product.model";

export class LoanProductPricing {
  loanProductPricingId: number;
  pricingVendorLoanId: string;
  productId: number;
  pricingVendorProductId: string;
  productName: string;
  productCode: string;
  investor: string;
  investorId: string;
  armIndex: string;
  armIndexValue: number;
  armLifetimeCap: number;
  armPeriodicCap: number;
  armRelativeCap: number;
  productNotesAndAdvisories: string[] = [];
  rate: number;
  term: number;
  price: number;
  baseRate: number;
  basePrice: number;
  apr: number;
  lockTerm: number;
  compType: string;
  comp: number;
  margin: number;
  lockDate: string;
  lockExpirationDate: string;
  lockRequstUserId: string;
  lockStatus: string;
  pricingVendor: string;
  assignDate: string;
  assignedSearchId: string;
  pricingRequestCallInfo: string;
  pricingRequestPayload: string;
  rateSheetId: string;
  product: LoanProduct;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum LockStatus {
  Requested = "Requested",
  Accepted = "Accepted",
  Rejected = "Rejected",
  CancelRequested = "CancelRequested",
  Canceled = "Canceled"
}
