import { Component, Injector, Input, ViewChild } from '@angular/core';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { ThirdPartyCredential } from 'src/app/models/third-party/third-party-creds.model';
import { Key, ThirdPartyKeyValue } from 'src/app/models/third-party/third-party-key-value.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CloudPlatformExpression, ContactListFields } from '../cloud-platform-config-editor/cloud-platform-expression-editor/cloud-platform-expression-editor.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { ContactListService } from 'src/app/services/contact-list.service';
import { ContactList, ContactListType, UserPermissions } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { isArray } from 'lodash';

@Component({
  selector: 'rock-connection-config-editor',
  templateUrl: './rock-connection-config-editor.component.html',
  styleUrls: ['./rock-connection-config-editor.component.scss'],
})
export class RockConnectionConfigEditorComponent extends ApplicationContextBoundComponent {

  @ViewChild('configForm')
  configForm: NgForm;

  @Input() rockConnection: ThirdPartyCredential;

  otherKeyValuePairs: ThirdPartyKeyValue[] = [];
  expressions: CloudPlatformExpression[] = [];

  globalConfig: GlobalConfig = null;
  keys: Array<Key>;

  fieldsForExpressionEditor: ContactListFields = null;

  userPermissions: UserPermissions;

  constructor(private readonly injector: Injector,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificatioonService: NotificationService,
    private readonly _contactListService: ContactListService) {
    super(injector);
    this.applicationContextService.context.subscribe((context) => {
      this.globalConfig = context.globalConfig;
      this.userPermissions = context.userPermissions;
    });
  }

  ngOnInit(): void {
    if (!this.rockConnection) {
      this.rockConnection = new ThirdPartyCredential();
    }
    if (!isArray(this.rockConnection.thirdPartyKeyValuePairs)) {
      this.rockConnection.thirdPartyKeyValuePairs = [];
    }
    this.getExpressionFields();
    const expressionThirdPartyKeyValuePairs =
      this.rockConnection.thirdPartyKeyValuePairs.filter(
        (el) => el.key === 'Expression'
      );
    expressionThirdPartyKeyValuePairs.forEach(
      (expressionThirdPartyKeyValuePair) => {
        const values = expressionThirdPartyKeyValuePair.value.split('|');
        const expression = new CloudPlatformExpression();
        expression.expressionType = values[0];
        expression.expressionGroupId = isNaN(values[1] as any) ? null : Number(values[1]);
        expression.campaignName = values[2];
        this.expressions.push(expression);
      }
    );
    this.otherKeyValuePairs =
      this.rockConnection.thirdPartyKeyValuePairs.filter(
        (el) => el.key !== 'Expression'
      );
  }

  validate = (): boolean => {
    if (this.configForm) {
      this.configForm.form.markAllAsTouched();
      return this.configForm.form.valid;
    }
    return false;
  }

  addExpression = () => {
    const expression = new CloudPlatformExpression();
    expression.expressionType = '1';
    this.expressions.push(expression);
  };

  addAdditionalConfig = () => {
    const keyValuePair = new ThirdPartyKeyValue();
    this.rockConnection.thirdPartyKeyValuePairs.push(keyValuePair);
    this.otherKeyValuePairs =
      this.rockConnection.thirdPartyKeyValuePairs.filter(
        (el) => el.key !== 'Expression'
      );
  };

  onExpressionDeleteClicked = (expression: CloudPlatformExpression) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this expression?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      const index = self.expressions.findIndex((e) => e === expression);
      if (index >= 0) {
        self.expressions.splice(index, 1);
      }
    });
  };

  onAdditionalConfigDeleteClicked = (keyValuePair: ThirdPartyKeyValue) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this configuration?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      const indexOfPairToDelete =
        self.rockConnection.thirdPartyKeyValuePairs.findIndex(
          (pair) => pair === keyValuePair
        );
      if (indexOfPairToDelete >= 0) {
        self.rockConnection.thirdPartyKeyValuePairs.splice(
          indexOfPairToDelete,
          1
        );
        self.otherKeyValuePairs =
          self.rockConnection.thirdPartyKeyValuePairs.filter(
            (el) => el.key !== 'Expression'
          );
      }
    });
  };

  private getExpressionFields() {
    this._spinner.show();
    this._contactListService.getContactList()
      .subscribe({
        next: (response: Array<ContactList>) => {
          const appContactList = response.find(contact => contact.contactListType === ContactListType.Application && contact.isCustom === false);
          const applicationContactListFields = this._contactListService.setContactListColumns(appContactList?.columns);

          const leadContactList = response.find(contact => contact.contactListType === ContactListType.Lead && contact.isCustom === false);
          const leadContactListFields = this._contactListService.setContactListColumns(leadContactList?.columns);

          this.fieldsForExpressionEditor = new ContactListFields(appContactList.contactListId, applicationContactListFields, leadContactList.contactListId, leadContactListFields);
        }, error: (err) => {
          this._notificatioonService.showError(err?.message || 'Unable to load contact list fields.', "Error!");
        }
      }).add(() => {
        this._spinner.hide();
      });
  }
}
