<div class="card mt-3" [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}">
  <ngx-spinner bdColor="rgba(51,51,51,0.3)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="false"
    [name]="spinnerName">
    <p style="font-size: 16px; color: white">Refreshing...</p>
  </ngx-spinner>
  <a class="top-right-close link" (mouseover)="mouseCursorOnDeleteButton=true"
    (mouseout)="mouseCursorOnDeleteButton=false" (click)="onDeleteScenarioClicked(scenario.pricingScenarioId)">
    <i class="fa fa-times-circle"></i>
  </a>
  <div class="card-header bg-primary" [title]="scenario.productName">
    <h4 class="card-title text-white"> {{scenario.productName}} </h4>
  </div>
  <!--end card-header-->
  <div class="card-body">
    <div class="text-muted text-scenario">
      <span class="card-text">
        <a class="inline-edit-link" *ngIf="!editClicked" (click)="onEditScenarioNameClicked()"
          title="Change Scenario Name">
          <span class="fs-larger"> {{scenario.name ? scenario.name : 'Scenario ' + (index)}}
          </span>
        </a>
        <div *ngIf="editClicked" class="d-flex justify-content-center">
          <input type="text" class="form-control fit-content" [(ngModel)]="scenario.name" />
          <div class="mt-2">
            <a (click)="onSaveScenarioNameClicked(scenario)" class="align-middle">
              <span class="ms-2"> <i class="fa fa-check-circle text-success"></i> </span>
            </a>
            <a (click)="onCancelScenarioNameClicked()" class="align-middle">
              <span class="ms-2"> <i class="fa fa-times text-danger"></i> </span>
            </a>
          </div>
        </div>
      </span>
    </div>
    <hr />
    <div class="text-muted">
      <span> {{scenario.pricingRequestPayload
        && scenario.pricingRequestPayload.loanInformation ?
        (scenario.pricingRequestPayload.loanInformation.baseLoanAmount | currency) : ''}}
      </span>
    </div>
    <div>
      <span class="card-text text-muted"><strong>LOAN AMOUNT</strong></span>
    </div>
    <hr />
    <div class="text-muted">
      <span>{{scenario.pricingRequestPayload &&
        scenario.pricingRequestPayload.propertyInformation ?
        (scenario.pricingRequestPayload.propertyInformation.appraisedValue |
        currency) : ''}}
      </span>
    </div>
    <div>
      <span class="card-text text-muted"><strong>APPRAISED VALUE</strong></span>
    </div>
    <hr />
    <div class="text-info">
      <span>{{scenario.adjustedRate ? ((scenario.adjustedRate / 100) |
        percent:'1.3-3'):
        'Unknown'}} / {{scenario.apr ? ((scenario.apr / 100) | percent:'1.3-3') : 'Unknown'}} <i
          class="fas fa-pencil-alt ms-1" (click)="onRateAprEditClicked(scenario)"></i>
      </span>
    </div>
    <div class="mt-1">
      <span class="pt-1 card-text text-info"><strong> RATE / APR</strong></span>
    </div>
    <div class="mt-1">
      <div class="form-check form-switch form-switch-success d-inline-block">
        <label for="autoRefreshToggle" class="form-check-label text-muted"> Auto Refresh </label>
        <input id="autoRefreshToggle" class="form-check-input" [(ngModel)]="scenario.autoRefreshEnabled"
          (ngModelChange)="onAutoRefreshToggled(scenario)" type="checkbox" checked />
        <button type="button" class="btn btn-sm btn-outline-primary btn-refresh-rate" (click)="onRefreshClicked()"><i
            aria-hidden="true" class="mdi mdi-refresh"></i>
        </button>
      </div>
    </div>
    <hr />
    <div class="mt-1">
      <div class="form-check form-switch form-switch-success d-inline-block">
        <label for="shareWithBorrowersToggle" class="form-check-label text-muted" title="Share with Borrower"> Share with Borr. </label>
        <input id="shareWithBorrowersToggle" class="form-check-input" [(ngModel)]="scenario.shareWithApplicationBorrowers" (ngModelChange)="onShareWithBorrowerChanged()" type="checkbox" checked />
        <button type="button" class="btn btn-sm btn-outline-primary btn-share-with-borrower" (click)="opTemplateNotes.toggle($event)"><i
            aria-hidden="true" class="ti-more-alt"></i>
        </button>
        <p-overlayPanel #opTemplateNotes>
          <ng-template pTemplate>
            <scenario-notes-editor [scenario]="scenario"
              (applied)="opTemplateNotes.hide(); onNotesApplied($event)"
              (cancelled)="opTemplateNotes.hide();">
            </scenario-notes-editor>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>
    <hr/>
    <div class="mt-2 chart-width widget-center" *ngIf="scenario.history.length > 0 && displayExpensesOverlay" id="{{chartId}}" style="min-height: 120px">
      <ng-container *ngIf="!isDrawingChart; else loading">
        <p-chart
          type="line"
          width="{{chartWidth}}"
          height="{{chartHeight}}"
          [data]="history.data"
          [options]="history.options">
        </p-chart>
      </ng-container>
      <ng-template #loading>
        <div class="spinner-border thumb-xl text-primary" role="status"></div>
      </ng-template>
    </div>
    <hr *ngIf="scenario.history.length > 0" />
    <div class="text-info">
      <span *ngIf="scenario.adjustedPrice >= 100.00" class="text-muted price-rebate">{{scenario.rebatePercent}} / {{(scenario.rebateDollars | currency)}}</span>
      <span *ngIf="scenario.adjustedPrice < 100.00" class="text-muted price-discount">{{scenario.discountPercent}} / {{(scenario.discountDollars | currency)}}</span>
    </div>
    <div>
      <span class="card-text text-info"><strong> POINTS </strong> </span>
    </div>
    <hr />
    <div class="text-info" *ngIf="displayExpensesOverlay">
      <span>{{scenario.principalAndInterest ?
        (scenario.principalAndInterest | currency) : 'Unknown'}} / {{scenario.totalPayment ?
        (scenario.totalPayment | currency) : 'Unknown'}} <i class="fas fa-info-circle ms-1" (click)="opTemplateTaxes.toggle($event)"></i>
      </span>
      <p-overlayPanel #opTemplateTaxes>
        <ng-template pTemplate>
          <scenario-taxes-editor [scenario]="scenario"
            (applied)="opTemplateTaxes.hide(); onTaxesApplied($event)"
            (cancelled)="opTemplateTaxes.hide();">
          </scenario-taxes-editor>
        </ng-template>
      </p-overlayPanel>
    </div>
    <div>
      <span class="card-text text-info"><strong>MONTHLY P&I / PI(TI)</strong> </span>
    </div>
    <hr />
    <div class="text-info">
       <span class="text-danger"> {{feesTotal | currency}} <strong><i class="text-info fas fa-pencil-alt ms-1" title="Edit Scenario Fees"
            (click)="onFeesEditClicked(scenario)"></i><i *ngIf="applicationId" class="text-info fa fa-copy ms-1" title="Copy From Loan"
            (click)="onFeesCopyFromLoanClicked(scenario)"></i>
          </strong>
      </span>
    </div>
    <div>
      <span class="card-text text-info"><strong> SCENARIO FEES </strong></span>
    </div>
    <hr />
    <div>
      <a (click)="onLoanQuoteClicked(scenario)">
        <span class="card-text text-info inline-edit-link loan-quote-text"> <strong> LOAN QUOTE </strong> </span>
      </a>
    </div>
    <hr />
    <div class="text-info">
      <span class="text-danger">{{prepaidFeesTotal | currency}}<strong>  </strong> </span>
    </div>

    <div>
      <span class="card-text text-info"><strong> PREPAIDS </strong></span>
    </div>
    <!-- <hr />
    <div class="text-muted">
      <span>{{scenario.totalClosingCost ? (scenario.totalClosingCost | currency) : 'Unknown'}}</span>
    </div>
    <div>
      <span class="card-text text-muted"><strong> CASH TO CLOSE </strong></span>
    </div> -->
  </div>
  <div class="card-footer bg-light-alt m-0">
    <button *ngIf="!applicationId" type="button" class="btn btn-outline-primary"
      (click)="onCreateLoanClicked(scenario.pricingScenarioId)">
      Create Loan </button>
    <button *ngIf="applicationId" [disabled]="isLoanReadOnly" type="button" class="btn btn-outline-primary"
      (click)="onApplyToLoanClicked(scenario)">
      Apply to Loan
    </button>
  </div>
</div>
