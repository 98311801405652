import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGoalsComponent } from './components/user-goals/user-goals.component';
import { GoalModule } from '../../../admin/goal/goal.module';
import { SharedModule } from '../../../../shared/shared.module';


@NgModule({
  declarations: [
    UserGoalsComponent,
  ],
  imports: [
    CommonModule,
    GoalModule,
    SharedModule,
  ],
  exports: [
    UserGoalsComponent,
  ],
})
export class UserGoalModule {
}
