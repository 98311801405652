import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppDetailsService} from '../../../../app-details/services/app-details.service';
import {FullSizeScrollableTableComponent} from "../../../../../shared/components/full-size-scrollable-table-base.component";
import {TableColumn} from "../../../../../shared/models/tale-column.model";
import {finalize, mergeMap} from "rxjs/operators";
import {ExpressionBuilderDialogService} from "../../../../expression-builder/services/expression-builder-dialog.service";
import {NotificationService} from "../../../../../services/notification.service";
import {ExpressionGroup} from "../../../../../models/expressions/expression-group.model";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'custom-data-usages-modal',
  templateUrl: './custom-data-usages-modal.component.html',
  styleUrls: ['./custom-data-usages-modal.component.scss']
})
export class CustomDataUsagesModal extends FullSizeScrollableTableComponent  implements OnInit {

  expressionGroupUsages: any;
  number: number;

  columns: TableColumn[] = [];
  globalFilterFields: string[] = [];
  data: {
    id: number;
    name: string;
    expressionGroupId: number;
  }[] = [];
  isDeleteClicked = {};

  constructor(
    private readonly _appService: AppDetailsService,
    private readonly _expressionService: ExpressionBuilderDialogService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.columns = [
      { field: 'usageArea', header: 'Usage Area', visible: true },
      { field: 'name', header: 'Name', visible: true }
    ];
    this.globalFilterFields = this.columns.map(c => c.field);
    this.setCustomDataUsages();
  }

  setCustomDataUsages(): void {
    const usages = this.expressionGroupUsages;
    this.data = [
      ...(usages.alignments && usages.alignments.length ? usages.alignments.map(el => ({...el, usageArea: 'Alignments', url: `/admin/company/alignment` })) : []),
      ...(usages.dialLists && usages.dialLists.length ? usages.dialLists.map(el => ({...el, usageArea: 'Dial Lists', url: `/admin/dialer-config/dial-lists` })) : []),
      ...(usages.feeDefinitions && usages.feeDefinitions.length ? usages.feeDefinitions.map(el => ({...el, usageArea: 'Fee Definitions', url: `/admin/lender-config/fee-templates` })) : []),
      ...(usages.feeTemplateFees && usages.feeTemplateFees.length ? usages.feeTemplateFees.map(el => ({...el, usageArea: 'Fee Template Fees', url: `/admin/lender-config/fee-templates` })) : []),
      ...(usages.feeTemplates && usages.feeTemplates.length ? usages.feeTemplates.map(el => ({...el, usageArea: 'Fee Templates', url: `/admin/lender-config/fee-templates` })) : []),
      ...(usages.keyDateConfigurations && usages.keyDateConfigurations.length ? usages.keyDateConfigurations.map(el => ({...el, usageArea: 'Key Date Configurations', url: `/admin/loan-config/key-dates` })) : []),
      ...(usages.leadRouteGroups && usages.leadRouteGroups.length ? usages.leadRouteGroups.map(el => ({...el, usageArea: 'Lead Route Groups', url: `/admin/lead-config/lead-routings` })) : []),
      ...(usages.leadRoutes && usages.leadRoutes.length ? usages.leadRoutes.map(el => ({...el, usageArea: 'Lead Routes', url: `/admin/lead-config/lead-routings` })) : []),
      ...(usages.losCreateFilters && usages.losCreateFilters.length ? usages.losCreateFilters.map(el => ({...el, usageArea: 'LOS Create Filters', url: `/admin/los-config/field-filtering` })) : []),
      ...(usages.mcaiCampaigns && usages.mcaiCampaigns.length ? usages.mcaiCampaigns.map(el => ({...el, usageArea: 'MCAI Campaigns', url: `/admin/campaigns/${el.id}` })) : []),
      ...(usages.questions && usages.questions.length ? usages.questions.map(el => ({...el, usageArea: 'Questions', url: `/admin/checklists/questions` })) : []),
      ...(usages.tasks && usages.tasks.length ? usages.tasks.map(el => ({...el, usageArea: 'Tasks', url: `/admin/task-config/loan-task-management` })) : []),
      ...(usages.unusedExpressionGroupIds && usages.unusedExpressionGroupIds.length ? usages.unusedExpressionGroupIds.map((el, i) => ({id: i + 1, name: '-', expressionGroupId: el, usageArea: 'Expression Unused' })) : [])
    ];
  }

  removeCustomDataFromExpression(id: number, expressionGroupId: number): void {
    this._spinner.show()
    this._expressionService.getExpressionGroup(expressionGroupId)
      .subscribe({
        next: (expressionGroup: ExpressionGroup) => {
          expressionGroup.expressions.forEach(expression => {
            expression.expressionTerms = expression.expressionTerms.filter(el => {
              return el.virtualFieldName ? !(el.virtualFieldName.includes(`CustomData${this.number}`)) : true;
            });
          });
          this._expressionService.saveExpressionGroups(expressionGroup)
            .pipe(finalize(() => this._spinner.hide()))
            .subscribe({
              next: (res) => {
                this.data = this.data.filter(el => !(el.id === id && el.expressionGroupId === expressionGroupId))
              },
              error: (error) => {
                this._notifyService.showError(
                  error?.message || "Couldn't remove custom data from expression.",
                  'Expression Groups'
                );
              }
            });
        },
        error: ({ error }) => {
          this._spinner.hide();
          this._notifyService.showError(
            error?.message || "Couldn't remove custom data from expression.",
            'Expression Groups'
          )
        }
      })
  }
}
