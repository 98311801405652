<div class="modal-header">
  <h5 class="modal-title">Credit Reporting</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.close()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <credit-reports
    [leadId]="leadId"
    (creditRunCompleted)="onCreditRunCompleted($event)"
  ></credit-reports>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-soft-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Close
  </button>
</div>
