<div class="alert alert-danger border-0" role="alert" *ngIf="error">
  <strong>ERROR:</strong> An error occurred while loading the leads.
  <br />
  <br />
  <span>{{error.message}}</span>

</div>

<ng-container *ngIf="!error">
  <lead-table *ngIf="!isLoading else spinner" [leads]="leads" [leadTableConfig]="leadTableConfig"  [webPreferences]="webPreferences">
  </lead-table>
</ng-container>
<ng-template #spinner>
  <loading-indicator loadingMessage="Loading leads...">
  </loading-indicator>
</ng-template>
