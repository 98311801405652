import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'fee-provider-picker-dialog',
  templateUrl: 'fee-provider-picker-dialog.component.html',
  styleUrls: ['./fee-provider-picker-dialog.component.scss']
})

export class FeeProviderPickerDialogComponent implements OnInit {

  @ViewChild("providerPickerForm")
  providerPickerForm: NgForm;

  @Input()
  missingFeeProviders: EnumerationItem[] = [];

  selectedProvider: EnumerationItem = new EnumerationItem("-- Select One --", null);

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.missingFeeProviders.length == 1) {
      this.selectedProvider = this.missingFeeProviders[0];
    }
  }

  onOkClicked = () => {
    this.providerPickerForm.form.markAllAsTouched();
    if (this.providerPickerForm.form.valid) {
      const selectedFeeProvider = this.missingFeeProviders.find(vendor => vendor.value == this.selectedProvider.value);
      this.activeModal.close(selectedFeeProvider.name);
    }
  }
}
