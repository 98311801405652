<div class="modal-header">
    <h5 class="modal-title">Reassign Internal Contacts</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="!isLoading">
    <div class="row mb-3">
        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Role</label>
        <div class="col-sm-8">
            <select class="form-select" name="role" [(ngModel)]="roleId"
            (ngModelChange)="roleChanged($event)">
                <option selected value="0"> -- Select One -- </option>
                <option *ngFor="let role of roles | filter: { isLoanContact: true}"
                value="{{role.roleId}}">
                {{role.roleName}}
                </option>
            </select>
        </div>
    </div>
    <div class="row mb-3">
        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Contacts</label>
        <div class="col-sm-8">
            <select class="form-select" name="contacts" [(ngModel)]="selectedInternalContact.userId"
            *ngIf="selectedInternalContact.role.order == 1">
                <option selected value=''> -- Select One -- </option>
                <option *ngFor="let user of usersInCompanyForContacts | filter: { roleId: selectedInternalContact.role.roleId }"
                value="{{user.userCompanyGuid}}">
                {{user.lastName + ", " + user.firstName}}
                </option>
            </select>
            <select class="form-select" name="allContacts" [(ngModel)]="selectedInternalContact.userId" *ngIf="selectedInternalContact.role.order != 1">
                <option selected value=''> -- Select One -- </option>

                <optgroup label="{{users[selectedInternalContact.role.roleId][0].roleName || ''}}"
                    *ngIf="users[selectedInternalContact.role.roleId]">
                    <ng-container *ngFor="let user of users[selectedInternalContact.role.roleId] | sort : 'lastName'">
                        <option *ngIf="(user.active == 1) || (user.userCompanyGuid == selectedInternalContact.userId)"
                            value="{{user.userCompanyGuid}}">
                            {{user.lastName + ", " + user.firstName}} {{(user.active == 1) ? "" : " - (Inactive) "}}
                        </option>
                    </ng-container>
                    
                </optgroup>

                <optgroup label="All Users">
                    <option *ngFor="let user of usersInCompanyForContacts"
                    value="{{user.userCompanyGuid}}">
                    
                    {{user.lastName + ", " + user.firstName}}
                    </option>
                </optgroup>
            </select>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">Cancel</button>
    <button type="button" class="btn btn-primary right" (click)="assignInternalContacts()">Assign</button>
</div>
