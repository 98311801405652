import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
    selector: 'export-loan-dialog',
    templateUrl: 'export-loan-dialog.component.html',
    styleUrls: ['export-loan-dialog.component.scss'],
})

export class ExportLoanDialogComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    selectedFormat: string;

    @Input()
    docFormats: EnumerationItem[];

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {

    }

    onCancelClicked = () => {
        this.activeModal.dismiss();
    }

    onSaveClicked = () => {
        this.activeModal.close(this.selectedFormat);
    }

    onSelectionChanged = (selectedFormat: string) => {
        this.selectedFormat = selectedFormat;
    }
}