import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { AUSBroker, Key, ThirdPartyCredential } from 'src/app/models';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from "../../../../../../services/system-level.service";
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'app-do-credential-editor-dialog',
  templateUrl: './do-credential-editor-dialog.component.html',
  styleUrls: ['./do-credential-editor-dialog.component.scss']
})
export class DoCredentialEditorDialogComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  @Output() onCredentialSaved = new EventEmitter();
  
  @Input() credential: ThirdPartyCredential;
  @Input() userId: string;
  @Input() companyId: number;
  @Input() channels: EnumerationItem[];
  @Input() scope: ScopeType;
  @Input() branchId?: number;

  doCredential: ThirdPartyCredential = null;

  doCreditProviders: Array<Key> = [];

  doKeyValuePairs: any = {
    defaultCreditProvider: { key: 'DefaultCreditProvider', value: '', alias: '', userName: '', password: '' },
    creditProviders: [],
    institutions: []
  };

  loadingDoAgencies: boolean = false;
  doFannieMaeLoadingError: boolean = false;
  isSaving: boolean = false;
  agenciesRequested: boolean = false;

  doLenders: Array<AUSBroker> = [];
  selectedChannels: string[];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _channelService: ChannelService,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService
  ) { }

  ngOnInit(): void {

    if (!this.credential) {
      this.doCredential = {
        credentialType: 'AUSOriginator',
        vendorName: 'FannieMae',
        thirdPartyKeyValuePairs: [
          { key: 'DefaultCreditProvider', value: '', alias: '', userName: '', password: '' }
        ],
        alias: '',
        url: '',
        userName: '',
        password: '',
        userId: this.userId,
        companyId: this.companyId
      };

      if (this.scope === 'Branch') {
        this.doCredential.branchId = this.doCredential.branchId || String(this.branchId);
      }
    } else {
      this.doCredential = _.cloneDeep(this.credential);
      this.doCredential.userId = this.doCredential.userId || this.userId;
      if (this.scope === 'Branch') {
        this.doCredential.branchId = this.doCredential.branchId || String(this.branchId);
      }
      this.doCredential.companyId = this.doCredential.companyId || this.companyId;
    }

    if (this.channels.length > 0) {
      this.selectedChannels = this._channelService
        .getChannelsListForDropdown(this.credential.channels)
        .map(channel => channel.name);
    }

    const defaultCreditProvider = this.doCredential.thirdPartyKeyValuePairs.find(el => el.key === 'DefaultCreditProvider');
    if (defaultCreditProvider) {
      this.doKeyValuePairs.defaultCreditProvider = defaultCreditProvider;
    }
    this.doKeyValuePairs.creditProviders = this.doCredential.thirdPartyKeyValuePairs
      .filter(el => el.key?.includes('CreditProvider:'))
      .map(el => ({ ...el, key: el.key.replace('CreditProvider:', '') }));
    this.doKeyValuePairs.defaultInstitution = this.doCredential.thirdPartyKeyValuePairs.find(el => el.key === 'SubmittingInstitutionId') || {
      value: '',
      key: 'SubmittingInstitutionId'
    };

    if (this.doCredential && this.doCredential.credentialId && this.doCredential.active) {
      this.loadDoCreditProviders();
      this.loadDoLenders();
    } else {
      this.agenciesRequested = true;
    }

  }

  loadDoCreditProviders = () => {
    if (!this.doCredential.userName || !this.doCredential.password) {
      this.doFannieMaeLoadingError = true;
      return;
    }
    this.loadingDoAgencies = true;
    this._loanServicesService.getDOAgencies(true, this.doCredential.credentialId)
      .pipe(finalize(() => this.loadingDoAgencies = false))
      .subscribe({
        next: (result) => {
          this.doFannieMaeLoadingError = false;
          this.agenciesRequested = true;
          this.isSaving = false;
          if (!result.length) {
            this._notificationService.showError('No credit providers found', 'Credit Agencies');
          }
          this.doCreditProviders = _.orderBy(
            result.map(el => ({ key: el.name, displayText: el.name })),
            ['displayText', 'asc']
          );
        },
        error: (err) => {
          this.doCreditProviders = [];
          this.doFannieMaeLoadingError = true;
          this.agenciesRequested = true;
          this.isSaving = false;
          this._notificationService.showError(err?.message || 'Unable to load credit agencies', 'Credit Agencies');
        }
      });
  }

  loadDoLenders = () => {
    if (!this.doCredential.userName || !this.doCredential.password) {
      this.doFannieMaeLoadingError = true;
      return;
    }
    this._loanServicesService.getDOLenders(this.doCredential.credentialId)
      .subscribe({
        next: (result) => {
          if (!result.length) {
            this._notificationService.showError('No DO lenders found', 'Lenders');
          }
          this.doLenders = result ? _.orderBy(result, ['name', 'asc']) : [];
          if (!this.doKeyValuePairs.defaultInstitution.value) {
            const defaultValue = this.doLenders.find(l => l.isDefaultInstitution === true)?.thirdPartyInstitutionId;
            if (defaultValue) {
              this.doKeyValuePairs.defaultInstitution.value = defaultValue;
            }
          }
        },
        error: (err) => {
          this.doLenders = [];
          this._notificationService.showError(err?.message || 'Unable to load DO lenders', 'DO Lenders');
        }
      });
  }

  addCreditProvider = () => {
    this.doKeyValuePairs.creditProviders.push({
      key: '',
      value: '',
      alias: '',
      userName: '',
      password: ''
    });
  }

  addInstitution = () => {
    this.doKeyValuePairs.institutions.push({
      key: 'Institution',
      name: '',
      institutionId: '',
    });
  }

  saveCredential = () => {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) {
      return;
    }
    this.setCredentialActiveIfChanged();
    this.doCredential.thirdPartyKeyValuePairs = [
      this.doKeyValuePairs.defaultCreditProvider,
      ...this.doKeyValuePairs.creditProviders.map(el => ({
        ...el,
        key: `CreditProvider:${el.key}`,
        value: `${el.userName},${el.password}`
      })),
      this.doKeyValuePairs.defaultInstitution,
    ];
    this.activeModal.close(this.doCredential);
  }

  saveAndLoadCreditProviders = () => {
    this.isSaving = true;
    this.setCredentialActiveIfChanged();
    this._systemLevelService.saveCredential(this.doCredential).subscribe({
      next: (response) => {
        this.doCredential = response;
        this.onCredentialSaved.next(response);
        this.loadDoCreditProviders();
        this.loadDoLenders();
      },
      error: (err) => {
        this.isSaving = false;
        this._notificationService.showError(err?.message || "Couldn't save credential", "Credential");
      }
    });
  }

  setCredentialChannels(): void {
    this.doCredential.channels = this.selectedChannels.length ? this.selectedChannels.join(', ') : '';
  }

  setCredentialActiveIfChanged = () => {
    if (
      this.doCredential.userName !== this.credential.userName ||
      this.doCredential.password !== this.credential.password
    ) {
      this.doCredential.active = true;
    }
  };

}

