import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { clone } from "lodash";
import { Lead } from "../../leads/models/lead.model";
import { LeadsService } from "../../leads/services/leads.service";
import { NotificationService } from "src/app/services/notification.service";

@Component({
  selector: 'edit-lead-note',
  templateUrl: './edit-lead-note.component.html',
})
export class EditLeadNoteComponent implements OnInit {
  @Input()
  lead: Lead;

  @Output()
  close: EventEmitter<never> = new EventEmitter<never>();

  note: string;

  constructor(
    private readonly _leadService: LeadsService,
    private readonly _notyfiService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.note = clone(this.lead.mostRecentNote);
  }

  saveNote() {
    const event: any = {
      leadId: this.lead.leadId,
      leadStatusId: this.lead.leadStatusId,
      note: this.note,
      type: "note",
    }

    this._spinner.show();
    this._leadService.addLeadEvent(event).subscribe({
      next: (res) => {
        this._leadService.sendEventUpdatedLead(this.lead.leadId, this.note);
        this.close.emit();
      },
      error: (error) => {
        this._notyfiService.showError(error?.message || "Couldnt change lead note.", "Error!");
      }
    }).add(() => this._spinner.hide());
  }

  closePopover() {
    this.close.emit();
  }
}
