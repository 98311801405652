<p-table
  [value]="allFeeSectionDefinitions"
  responsiveLayout="scroll"
  styleClass="p-datatable-sm p-datatable-gridlines"
  sortField="feeDefinitionId"
  [paginator]="true"
  [rows]="25"
  [resizableColumns]="true"
  responsiveLayout="scroll"
  name="fee-definition-summary-list-table-v2"
  [rowsPerPageOptions]="[10, 25, 50]"
  [(selection)]="selectedRows"
  [autoLayout]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [selectionPageOnly]="true"
  [rowSelectable]="isRowSelectable"
  dataKey="feeDefinitionId"
  (onRowSelect)="onRowSelect()"
  (onRowUnselect)="onRowUnselect()"
  (onHeaderCheckboxToggle)="onAllRowsSelected()"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem; text-align: center">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th>HUD#</th>
      <th>Name</th>
      <th>APR</th>
      <th>Split</th>
      <th>Default Paid By</th>
      <th>Fee % of</th>
      <th>Seller Concessions</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td style="width: 3rem; text-align: center">
        <p-tableCheckbox
          [value]="rowData"
          [disabled]="hasAlreadyAdded(rowData.feeDefinitionId)"
        >
        </p-tableCheckbox>
      </td>
      <td>
        {{ rowData.hudNumber }}
        <span
          class="text-danger ms-1"
          *ngIf="hasAlreadyAdded(rowData.feeDefinitionId)"
          >(Already added)</span
        >
      </td>
      <td>{{ rowData.name }}</td>
      <td>{{ rowData.isAprFee ? 'Yes' : 'No' }}</td>
      <td>{{ rowData.allowSplit ? 'Yes' : 'No' }}</td>
      <td>{{ getEnumName(rowData.defaultPaidBy) || '---' }}</td>
      <td>{{ getEnumName(rowData.feePercentOf) || '---' }}</td>
      <td>{{ rowData.canSellerConcessionsApply ? 'Yes' : 'No' }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="14" class="text-center">
        {{ !isLoading ? 'There are no definition.' : 'Loading...' }}
      </td>
    </tr>
  </ng-template>
</p-table>
