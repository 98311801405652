import {Component, Input, OnInit} from '@angular/core';
import {Configuration} from '../../../../../models/configuration.model';
import {DocumentType, ThirdPartyCredential, ThirdPartyCredentialArray} from '../../../../../models';
import {Constants} from '../../../../../services/constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DisclosuresCredentialModalComponent} from './disclosures-credential-modal/disclosures-credential-modal.component';

@Component({
    selector: 'disclosures-integration',
    templateUrl: './disclosures.component.html'
})
export class DisclosuresIntegrationComponent implements OnInit {
    @Input() documentTypes: Array<DocumentType>;
    @Input() docMagicEnabled: Configuration;
    @Input() disclosureCredentials: ThirdPartyCredentialArray;

    constructor(
        private readonly _modalService: NgbModal,
    ) {}

    ngOnInit(): void {}

    openCredentialModal(credential: ThirdPartyCredential): void {
        const modalRef = this._modalService.open(DisclosuresCredentialModalComponent, Constants.modalOptions.large);
        modalRef.componentInstance.credential = credential;
        modalRef.componentInstance.documentTypes = this.documentTypes;
        modalRef.result.then(
            (result) => {
                const index = this.disclosureCredentials.findIndex(el => el.credentialId === result.credentialId);
                if (index > -1) {
                    this.disclosureCredentials[index] = result;
                } else {
                    this.disclosureCredentials.push(result);
                }
            },
            () => {}
        );
    }
}
