import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { KeyDateGroup, KeyDate, BaseKeyDate, KeyDateConfig } from '../models';
import { ExpressionGroup } from '../models/expressions/expression-group.model';

@Injectable()
export class KeyDatesService {
  constructor(private readonly _dataService: DataService) {}

  //#region loan

  public getKeyDates = (loanId: number): Observable<BaseKeyDate[]> => {
    return this._dataService.get(`api/Loan/${loanId}/GetKeyDates`);
  };

  public getKeyDatesHistory = (
    loanId: number,
    keyDateId: number
  ): Observable<any> => {
    return this._dataService.get(
      `api/Loan/${loanId}/GetKeyDateHistory/${keyDateId}`
    );
  };

  public postUpsertKeyDates = (
    data: KeyDateConfig[],
    loanId: number
  ): Observable<KeyDate[]> => {
    const url = `api/Loan/${loanId}/UpsertKeyDates`;
    return this._dataService.post(url, data);
  };

  //#endregion

  //#region admin

  public getKeyDatesGroups = (
    companyId: number
  ): Observable<Array<KeyDateGroup>> => {
    return this._dataService.get(
      `api/Admin/key-date-group?companyId=${companyId}`
    ).pipe(
      map(groups => {
        groups.forEach(group => group.keyDateConfigurations?.sort((a,b) => a.order > b.order ? 1 : -1))
        return groups;
      })
    );
  };

  public getUnasignedKeyDates = (
    companyId: number
  ): Observable<Array<KeyDateConfig>> => {
    return this._dataService.get(
      `api/Admin/key-date-configuration/unassigned?companyId=${companyId}`
    );
  };

  insertKeyDateGroup = (
    companyId: number,
    group: KeyDateGroup
  ): Observable<KeyDateGroup> => {
    return this._dataService.post(
      `api/Admin/key-date-group?companyId=${companyId}`,
      group
    );
  };

  updateKeyDateGroup = (
    companyId: number,
    group: KeyDateGroup
  ): Observable<KeyDateGroup> => {
    return this._dataService.post(
      `api/Admin/key-date-group/${group.keyDateGroupId}?companyId=${companyId}`,
      group
    );
  };

  deleteKeyDateGroup = (
    companyId: number,
    keyDateGroupId: number
  ): Observable<undefined> => {
    return this._dataService.delete(
      `api/Admin/key-date-group/${keyDateGroupId}?companyId=${companyId}`
    );
  };

  reorderKeyDate = (keyDateGroupId: number, items: Array<number>) => {
    return this._dataService.post(`api/Admin/key-date-configuration/reorder?keyDateGroupId=${keyDateGroupId}`, items);
  }

  moveKeyDateGroup = (companyId: number, order: Array<Array<number>>) => {
    return this._dataService.post(
      `api/Admin/key-date-group/reorder?companyId=${companyId}`,
      order
    );
  };

  insertKeyDate = (
    companyId: number,
    keyDate: KeyDateConfig
  ): Observable<KeyDateConfig> => {
    return this._dataService.post(
      `api/Admin/key-date-configuration?companyId=${companyId}`,
      keyDate
    );
  };

  // please note that this method is used both to update key date properties and when key date is moved
  updateKeyDate = (
    companyId: number,
    keyDate: KeyDateConfig
  ): Observable<KeyDateConfig> => {
    return this._dataService.post(
      `api/Admin/key-date-configuration/${keyDate.keyDateConfigurationId}?companyId=${companyId}`,
      keyDate
    );
  };

  deleteKeyDate = (
    companyId: number,
    keyDateConfigurationId: number
  ): Observable<undefined> => {
    return this._dataService.delete(
      `api/Admin/key-date-configuration/${keyDateConfigurationId}?companyId=${companyId}`
    );
  };

  convertKeyDateToExpression = (
    companyId: number,
    keyDateConfigurationId: number
  ): Observable<KeyDateConfig> => {
    //todo check response
    return this._dataService.post(
      `api/Admin/ConvertKeyDateConfigurationToUseExpression/${keyDateConfigurationId}?companyId=${companyId}`,
      {}
    );
  };

  revertExpressionConversion = (companyId: number, keyDateConfigurationId: number) => {
    //todo check response
    return this._dataService.post(
      `api/Admin/ConvertKeyDateConfigurationToNotUseExpression/${keyDateConfigurationId}?companyId=${companyId}`,
      {}
    );
  };

  getExpressiosForKeyDate = (expressionGroupId: number): Observable<ExpressionGroup> => {
    return this._dataService.get(`api/expressions/groups/${expressionGroupId}`);
  };

  //#endregion
}
