import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EnumerationService} from '../../../../services/enumeration-service';
import {EnumerationItem} from '../../../../models/simple-enum-item.model';
import {NgForm} from '@angular/forms';
import {SubjectProperty} from '../../../../models';
import {ZipCodeLookupResult} from '../../../../models/zipcode-lookup-result.model';
import * as _ from 'lodash';

@Component({
  selector: 'address-update-modal',
  templateUrl: 'address-update-modal.component.html'
})

export class AddressUpdateModalComponent implements OnInit {

  @ViewChild('addressUpdateForm') addressUpdateForm: NgForm | undefined;

  @Input() subjectProperty: SubjectProperty;
  stateOptions: EnumerationItem[];

  constructor(public activeModal: NgbActiveModal,
              private readonly _enumsService: EnumerationService) {
    this.stateOptions = this._enumsService.states;
  }

  ngOnInit() {

  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    if (zipCode) {
      this.subjectProperty.state = zipCode.state.toLowerCase();
      this.subjectProperty.city = _.startCase(_.toLower(zipCode.city));
      this.subjectProperty.zipCode = zipCode.zipcode;
      this.subjectProperty.county = _.startCase(_.toLower(zipCode.county));
      this.subjectProperty.country = 'us';
    }
  }

  apply() {
    this.addressUpdateForm.form.markAllAsTouched();
    if (this.addressUpdateForm.form.valid) {
      this.activeModal.close(this.subjectProperty);
    }
  }
}
