/**
 * Deep freeze the enumerable (object, array, etc.).
 * @template T
 * @param {T} o - The enumerable to deep freeze.
 * @returns {T} - The given value as deep-frozen.
 * @remarks This function assumes any previously frozen properties as
 * deep-frozen even if they are shallowly frozen. This is because of the is
 * frozen check to prevent `Maximum call stack size exceeded` error in case of
 * circular references.
 */
export function deepFreeze<T>(o: T): T {
  function freeze(value: any) {
    if (!Object.isFrozen(value)) {
      recursive(value);
    }
  }

  function recursive(o: any) {
    Object.freeze(o);
    Object.values(o).forEach(freeze);
  }

  recursive(o);
  return o;
}

/**
 * Assign the default properties to the missing (undefined) properties of the
 * target object.
 * @template T
 * @param {T} target - The target object to assign the default properties.
 * @template U
 * @param {U} defaults - The default properties to assign to the target.
 * @returns {T} - The target object with the default properties assigned.
 * @remarks This function modifies the target object in place.
 */
export function assignDefaultProps<T = any, U = any>(
  target: T,
  defaults: U,
): T & U {
  Object.keys(defaults).forEach(key => {
    const defaultKey = key as keyof T;
    if (target[defaultKey] === undefined) {
      // @ts-ignore
      target[defaultKey] = defaults[defaultKey] as T[keyof T];
    } else if (
      // @ts-ignore
      typeof defaults[defaultKey] === 'object' &&
      // @ts-ignore
      defaults[defaultKey] !== null &&
      // @ts-ignore
      !Array.isArray(defaults[defaultKey])
    ) {
      target[defaultKey] = assignDefaultProps(
        target[defaultKey] || ({} as T[keyof T]),
        // @ts-ignore
        defaults[defaultKey] as T[keyof T],
      );
    }
  });
  // @ts-ignore
  return target;
}

/**
 * Sets the value at the nested path of an object if the path is undefined.
 * Creates nested objects as necessary.
 *
 * @param obj - The object to set the value on.
 * @param path - An array of keys representing the nested path.
 * @param value - The value to set if the path is undefined.
 */
export function setNestedPathIfUndefined(obj: Record<string, any>, path: (string | number)[], value: any): void {
  if (!Array.isArray(path) || path.length === 0) {
    throw new Error('Path must be a non-empty array of strings or numbers.');
  }

  let current = obj;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (i === path.length - 1) {
      if (current[key] === undefined) {
        current[key] = value;
      }
    } else {
      if (current[key] === undefined || typeof current[key] !== 'object') {
        current[key] = {};
      }
      current = current[key];
    }
  }
}
