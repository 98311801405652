import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckList } from 'src/app/models/checklist.model';
import { AnswerConfiguration, CheckListService, QuestionConfiguration, QuestionSummary } from 'src/app/services/check-list.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { finalize } from 'rxjs';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { LoanStatus } from 'src/app/models';
import { Utils } from 'src/app/core/services/utils';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { cloneDeep } from 'lodash';
import { Question } from 'src/app/models/question.model';
import Swal, { SweetAlertResult } from 'sweetalert2';
import * as _ from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'check-list-item-treelist',
  templateUrl: './check-list-item-treelist-dialog.component.html',
  styleUrls: ['./check-list-item-treelist-dialog.component.scss'],
  providers: [TreeDragDropService],
})
export class CheckListItemTreelistDialogComponent implements OnInit {

  @Input() checklistId: number;
  @Input() companyId: number;
  @Input() globalConfig: GlobalConfig;
  @Input() loanStatuses: LoanStatus[] = [];

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  treeViewData: TreeNode[] = [];
  questions: QuestionSummary[] = [];
  questionNodes: TreeNode[] = [];
  masterListOfQuestionNodes: TreeNode[] = [];
  allQuestionsLoading: boolean;
  isSaving: boolean = false;
  hasError: boolean;
  checklistLoading: boolean;
  questionTypeOptions: EnumerationItem[] = [];
  selectedTypes = [];

  checkListItem: CheckList;

  editQuestion: QuestionSummary;

  upsertChecklistQuestionDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _notifyService: NotificationService,
    private readonly _drawerService: DrawerService
  ) { }

  ngOnInit(): void {
    this.getQuestions();
  }

  onClose() {
    this.close.emit();
  }

  getQuestions = () => {
    this.allQuestionsLoading = true;
    this.checklistLoading = true;
    this._checkListService.getAllQuestionsWithAnswers()
      .pipe(finalize(() => this.allQuestionsLoading = false))
      .subscribe({
        next: (questions) => {
          this.questions = questions || [];
          this.getCheckListById()
          this.masterListOfQuestionNodes = this.questionNodes;
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'unable to load all questions', "Error!");
        }
      });
  }

  populateQuestionsList(node?: TreeNode) {
    let questionNodes = [];
    let questionTypes = Object.keys(_.groupBy(this.questions, 'questionType'));
    this.questionTypeOptions = questionTypes.map(qt => new EnumerationItem(Utils.splitCamelCaseString(qt), qt))
    _.orderBy(questionTypes, x => x).forEach(qt => {
      if (qt == 'TriggerOnly') {
        let existingQuestions;
        if (node) {
          existingQuestions = [node.key]
        } else {
          existingQuestions = this.treeViewData.map(x => x.key);
        }
        
        questionNodes.push(
          {
            key: qt,
            data: {},
            label: Utils.splitCamelCaseString(qt),
            expanded: this.questionNodes?.find(x => x.key == qt)?.expanded || false,
            children: _.orderBy(this.questions, x => x.questionText).filter(q => q.questionType == qt && existingQuestions.indexOf("question-" + q.questionId) < 0).map(q => {
              return {
                key: "question-" + q.questionId,
                data: q,
                label: q.questionText + " (" + q.questionType + ")",
                children: [],
                questionType: Utils.splitCamelCaseString(q.questionType)
              } as TreeNode
            })
          }
        )
      }
      else {
        questionNodes.push(
          {
            key: qt,
            data: {},
            label: Utils.splitCamelCaseString(qt),
            expanded: this.questionNodes?.find(x => x.key == qt)?.expanded || false,
            children: _.orderBy(this.questions, x => x.questionText).filter(q => q.questionType == qt).map(q => {
              return {
                key: "question-" + q.questionId,
                data: q,
                label: q.questionText + " (" + q.questionType + ")",
                children: [],
                questionType: Utils.splitCamelCaseString(q.questionType)
              } as TreeNode
            })
          }
        )
      }
    });
    this.questionNodes = questionNodes;
  }

  getCheckListById = () => {
    this._checkListService.getChecklistById(this.checklistId)
      .pipe(finalize(() => this.checklistLoading = false))
      .subscribe({
        next: (checklist) => {
          this.checkListItem = checklist
          let questions: Array<QuestionConfiguration> = [];
          try {
            questions = JSON.parse(checklist.questionConfiguration);
          } catch (e) {
            questions = [];
          }

          let treeViewData = [];
          questions.forEach(question => {
            const newNode = this.addQuestionNode(question);
            if (newNode) {
              treeViewData.push(newNode);
            }
          });

          this.treeViewData = treeViewData.filter(x => x.data.questionType != 'TriggerOnly');

          _.orderBy(treeViewData.filter(x => x.data.questionType == 'TriggerOnly'), x => x.label).forEach(node => {
            this.treeViewData.push(node);
          });

          this.populateQuestionsList();
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to load checklist', "Error!");
        }
      });
  }

  onTypeFilterChanged = () => {
    this.questionNodes = this.masterListOfQuestionNodes.filter(qn => this.selectedTypes.includes(qn.key) || this.selectedTypes.length == 0);
  }

  private getChildren(children: Array<AnswerConfiguration | QuestionConfiguration>, type: 'Question' | 'Answer') {
    return children.map(child => {
      if (type === 'Answer') {
        const answerDetails = this.getAnswerDetails((child as AnswerConfiguration).questionAnswerId)
        return {
          key: "answer-" + answerDetails.questionAnswerId,
          data: answerDetails,
          label: answerDetails.answerText,
          children: this.getChildren((child as AnswerConfiguration).questions, 'Question')
        } as TreeNode
      } else if (type === 'Question') {
        const questionDetails = this.getQuestionDetails((child as QuestionConfiguration).questionId);
        if (!questionDetails) {
          return null;
        }
        return {
          key: "question-" + questionDetails.questionId,
          data: questionDetails,
          label: questionDetails.questionText + " (" + questionDetails.questionType + ")",
          children: this.getChildren((child as QuestionConfiguration).answers, 'Answer')
        }
      }
    });
  }

  private addQuestionNode(question: QuestionConfiguration) {
    const matchingQuestion = this.getQuestionDetails(question.questionId);
    if (!matchingQuestion) {
      return null;
    }
    return {
      key: "question-" + matchingQuestion.questionId,
      data: matchingQuestion,
      label: matchingQuestion.questionText + " (" + matchingQuestion.questionType + ")",
      children: this.getChildren(question.answers, 'Answer')
    }
  }

  editQuestionClicked = (question: QuestionSummary) => {
    this.editQuestion = cloneDeep(question);
    this._drawerService.show('upsertChecklistQuestionDrawer', 100);
  }

  addNewQuestionClicked = () => {
    this._drawerService.show('upsertChecklistQuestionDrawer', 100);
  }

  onUpsertQuestionDrawerClose = (question: Question): void => {
    this._drawerService.hide("upsertChecklistQuestionDrawer", 100);
    if (question) {
      this.getQuestions();
    }
    this.editQuestion = null;
  }

  save = () => {
    this.isSaving = true;
    const questionConfig = [];
    this.treeViewData.forEach(question => {
      questionConfig.push({
        questionId: question.data.questionId,
        answers: this.iterateChildren(question.children)
      })
    });
    this.checkListItem.questionConfiguration = questionConfig ? JSON.stringify(questionConfig) : null;
    this._checkListService.updateChecklistItem(this.checkListItem)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: () => {
          this._notifyService.showSuccess("Save done successfully", "Success");
          this.close.emit(true);

        }, error: (err) => {
          this._notifyService.showError(err.error.message, "Error");
        }
      });
  }

  expandAll() {
    this.treeViewData.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.treeViewData.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  onDropOnTreeView = (event: { dragNode: TreeNode, dropNode: TreeNode, accept}) => {
    let originalTreeViewData = _.cloneDeep(this.treeViewData);
    event.accept();
    this.validateTree();
    if (!this.hasError) { 
      event.dragNode.children = event.dragNode.data.answers.map(a => {
        return {
          key: "answer-" + a.questionAnswerId,
          data: a,
          label: a.answerText,
          children: []
        } as TreeNode
      });
      this.hasError = false;
      
      if (!event.dropNode) {
        this.populateQuestionsList(event.dragNode);
      } else {
        let treeViewData = _.clone(this.treeViewData);
        this.treeViewData = treeViewData.filter(x => x.data.questionType != 'TriggerOnly');

        _.orderBy(treeViewData.filter(x => x.data.questionType == 'TriggerOnly'), x => x.label).forEach(node => {
          this.treeViewData.push(node);
        });
        this.populateQuestionsList();
      }
      
    } else {
      this.treeViewData = _.cloneDeep(originalTreeViewData);
      this.hasError = false;
      this.populateQuestionsList();
      this._notifyService.showError('A question cannot have a question as a child.', 'Invalid Drop!')
    }
  }

  validateNode(node: TreeNode) {
    if (node.key.startsWith('question-')) {
      this.hasError = node.children.some(c => c.key.startsWith('question-'));
      if (this.hasError) {
        return;
      }
      node.children.forEach(node => {
        this.validateNode(node);
      });
    }
  }

  private validateTree = () => {
    this.treeViewData.forEach(node => {
      if (this.hasError) {
        return;
      }
      this.validateNode(node)
    });
  }

  removeNode = (node: TreeNode) => {
    let matchedIndex = this.treeViewData.findIndex(n => n == node);
    if (matchedIndex > -1) {
      this.treeViewData.splice(matchedIndex, 1);
    } else {
      matchedIndex = node.parent.children.findIndex(n => n == node);
      if (matchedIndex > -1) {
        node.parent.children.splice(matchedIndex, 1);
      }
    }
    this.populateQuestionsList();
    this.hasError = false;
    this.validateTree();
  }

  onDeleteQuestionClicked = (question: QuestionSummary) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Lead Campaign?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this._checkListService.deleteQuestion(question.questionId)
        .subscribe({
          next: () => {
            this._notifyService.showSuccess("Question removed succesfully", "Success");
            this.getQuestions();
          }, error: (err) => {
            this._notifyService.showError(err?.message || "Error encountered while deleting question", "Error!");
          }
        });

    });
  }

  private iterateChildren(children) {
    return children.map(child => {
      if (child.key.startsWith('answer-')) {
        return {
          questionAnswerId: child.data.questionAnswerId,
          questions: this.iterateChildren(child.children)
        }
      } else if (child.key.startsWith('question-')) {
        return {
          questionId: child.data.questionId,
          answers: this.iterateChildren(child.children)
        }
      }
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  private getQuestionDetails(questionId: number) {
    const question = this.questions.find(q => q.questionId === questionId);
    return question;
  }

  private getAnswerDetails(questionAnswerId: number) {
    const answer = this.questions.flatMap(q => q.answers).find(a => a.questionAnswerId === questionAnswerId);
    return answer;
  }

  private getQuestionKeys(node: TreeNode, usedQuestionsKeys: Array<string>) {
    if (node.key.startsWith('question-')) {
      usedQuestionsKeys.push(node.key);
    }
    node.children.forEach(childNode => {
      return this.getQuestionKeys(childNode, usedQuestionsKeys);
    });
  }
}
