import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { BaseKeyDate } from 'src/app/models';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'disclosure-tracking-config',
  templateUrl: 'disclosure-tracking-config.component.html',
  styleUrls: ['./disclosure-tracking-config.component.scss'],
})

export class DisclosureTrackingConfigComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  timeZone: string;

  keyDateValues: any = {};

  constructor(private _loanService: LoanService,
    private _notifsService: NotificationService,
    private _spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.keyDateValues["ApplicationReceived"] = null;
    this.keyDateValues["LEDue"] = null;
    this.keyDateValues["IntentToProceedReceived"] = null;
    this.keyDateValues["EarliestFeeCollection"] = null;
    this.keyDateValues["EarliestClosing"] = null;
    this.keyDateValues["EstimatedClosing"] = null;
    this.keyDateValues["LEIssued"] = null;
    this.keyDateValues["LESigned"] = null;
    this.keyDateValues["LERevisedIssued"] = null;
    this.keyDateValues["LERevisedSigned"] = null;
    this.keyDateValues["SafeHarborSent"] = null;
    this.keyDateValues["CDIssued"] = null;
    this.keyDateValues["CDSigned"] = null;
    this.keyDateValues["CDRevisedIssued"] = null;
    this.keyDateValues["CDRevisedSigned"] = null;
    this.keyDateValues["PostConsummationDisclosureSent"] = null;
    this.keyDateValues["PostConsummationDisclosureSigned"] = null;
    this.keyDateValues["AffiliatedBusinessDisclosureSent"] = null;
    this.keyDateValues["CharmBookletSent"] = null;
    this.keyDateValues["SpecialInfoBookletSent"] = null;
    this.keyDateValues["HelocBrochureSent"] = null;
    this.keyDateValues["FirstAppraisalSent"] = null;
    this.keyDateValues["SubsequentAppraisalSent"] = null;
    this.keyDateValues["AvmSent"] = null;
    this.keyDateValues["HomeCounselingDisclosureSent"] = null;
    this.keyDateValues["HighCostDisclosureSent"] = null;
    this.keyDateValues["SettlementServiceProviderListSent"] = null;
    this.getKeyDates();
  }

  save = () => {
    const formattedKeyDateValues = Array.from(Object.entries(this.keyDateValues), ([key, value]: any) => [key, this.formatISO(value)]);

    const newKeyDateValues = Object.fromEntries(formattedKeyDateValues);

    this._spinnerService.show();
    this._loanService.saveKeyDatesByType(newKeyDateValues, this.appId)
      .subscribe({
        next: (response: BaseKeyDate) => {
          console.log(response);
          this._notifsService.showSuccess('Key Dates successfully saved.', 'Success!');
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to save key dates for application.', 'Error!');
        }
      }).add(() => this._spinnerService.hide());
  }

  private formatISO = (date: string): string => {
    if (date) {
      const dateParts = date.split('/');
      return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}T00:00:00.000Z`;
    }
    return null;
  }

  private getKeyDates = () => {
    this._spinnerService.show();
    this._loanService.getKeyDatesByType(this.appId)
      .subscribe({
        next: (response) => {
          Object.keys(this.keyDateValues).forEach(key => {
            const keyOnResponse = key.charAt(0).toLowerCase() + key.charAt(1).toLowerCase() + key.slice(2);
            if (response[keyOnResponse]) {
              this.keyDateValues[key] = response[keyOnResponse].eventDate;
            }
          })
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to get key dates for application.', 'Error!');
        }
      }).add(() => this._spinnerService.hide());
  }
}