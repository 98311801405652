import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LeadRouteGroup } from 'src/app/modules/leads/models/lead-route-group.model';
import { LeadRouteType } from 'src/app/modules/leads/models/lead-route-history.model';
import { LeadHistory } from '../models/lead-routes-history.model';
import { LeadRoutes } from '../models/lead-routes.model';

@Injectable()
export class LeadRoutingService {
  constructor(private readonly _dataService: DataService) { }

  getLeadRoutes(filterType: LeadRouteType = LeadRouteType.Route,
    includeLeadRouteDetail: boolean = false,
    includeExpressions: boolean = true,
    filterByContactList: boolean = false,
    includeRouteCounts: boolean = false): Observable<Array<LeadRouteGroup>> {
    let url = `api/leadroutes/groups?filterType=${filterType}&includeLeadRouteDetail=${includeLeadRouteDetail}&includeExpressions=${includeExpressions}&includeLeadRouteCounts=${includeRouteCounts}`;
    if (filterByContactList) {
      url += '&filterByContactList=true';
    }
    return this._dataService.get(url);
  }

  getLeadRoutesByGroupId(groupId: Number): Observable<LeadRoutes[]> {
    if (!groupId) return of([]);
    const url = `api/leadroutes/groups/${groupId}/routes?includeUserStateSettings=false&includeUserRouteCounts=false`;
    return this._dataService.get(url);
  }

  getLeadRoutesGroupByGroupId(groupId: Number,
    includeLeadRouteDetail: boolean = true,
    includeExpressions: boolean = true,
    loadRouteCounts: boolean = true,
    includeUserRouteCounts: boolean = true,
    includeUserStateSettings: boolean = true
  ): Observable<LeadRouteGroup> {
    const url = `api/leadroutes/groups/${groupId}?includeLeadRouteDetail=${includeLeadRouteDetail}&includeExpressions=${includeExpressions}&loadRouteCounts=${loadRouteCounts}&includeUserRouteCounts=${includeUserRouteCounts}&includeUserStateSettings=${includeUserStateSettings}`;
    return this._dataService.get(url);
  }

  getLeadRoutingHistory(groupId: Number): Observable<LeadHistory[]> {
    const url = `api/leadroutes/groups/${groupId}/history`;
    return this._dataService.get(url);
  }

  addLeadRoute(payload): Observable<LeadRoutes> {
    return this._dataService.post(`api/leadroutes`, payload);
  }

  getLeadRouteById(leadRouteId: number): Observable<LeadRoutes> {
    let url = `api/leadroutes/${leadRouteId}`;
    return this._dataService.get(url);
  }

  updateLeadRoute(payload): Observable<LeadRoutes> {
    return this._dataService.post(
      `api/leadroutes/${payload.leadRouteId}`,
      payload
    );
  }
  update(payload): Observable<LeadRoutes> {
    return this._dataService.post(
      `api/leadroutes/groups/${payload.leadRouteGroupId}`,
      payload
    );
  }

  add(payload): Observable<LeadRouteGroup> {
    return this._dataService.post(`api/leadroutes/groups`, payload);
  }

  delete(leadRouteGroupId: number): Observable<any> {
    let url = `api/leadroutes/groups/${leadRouteGroupId}`;
    return this._dataService.delete(url);
  }

  deleteRoute(leadGroupId: number): Observable<any> {
    let url = `api/leadroutes/${leadGroupId}`;
    return this._dataService.delete(url);
  }

  getContactList() {
    return this._dataService.get(`api/contact-list`);
  }

  getUserLicensing() {
    return this._dataService.get(`api/Admin/user-licensing`);
  }

  updateGroupOrder(sortedIds: Array<any>): Observable<Array<any>> {
    return this._dataService.post(`api/leadroutes/groups/reorder`, sortedIds);
  }

  updateRouteOrder(
    sortedIds: Array<any>,
    groupId: number
  ): Observable<Array<any>> {
    return this._dataService.post(
      `api/leadroutes/groups/${groupId}/routes/reorder`,
      sortedIds
    );
  }

  getAllUsers() {
    return this._dataService.get(`api/Admin/AllUserModel`);
  }
}
