import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { LosProviderFeature } from 'src/app/models';
import {LosService} from '../../../services/los.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'los-loan-link',
  templateUrl: 'los-loan-link.component.html',
  styleUrls: ['los-loan-link.component.scss']

})

export class LosLoanLinkComponent implements OnInit {

  @Input() applicationId: number;
  @Input() losVendors;

  isSearching = false;
  isLinking = false;

  loanSearchList;
  selectedVendor;

  selectedLoanId;
  selectedLoan;

  losCredentialId: number = null;
  losReference: string;
  borrowerFirst: string;
  borrowerLast: string;

  constructor(public activeModal: NgbActiveModal,
    private readonly _losService: LosService,
    private readonly _notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.losVendors?.forEach(vendor => {
      vendor['borrowerDisabled'] = vendor.vendorFeatures.indexOf(LosProviderFeature.Lookup) > -1
    })

    if (this.losVendors?.length === 1) {
      this.startWithOneVendor();
    }
  }

  changeLosCredential(losCredentialId) {
    this.losCredentialId = +losCredentialId || null;
    this.selectedVendor = this.losVendors.find((provider) => provider.credentialId === Number(this.losCredentialId)) || {};
    if (this.selectedVendor.borrowerDisabled) {
      this.borrowerFirst = '';
      this.borrowerLast = '';
    }
  }

  search() {
    this.isSearching = true;

    this.selectedLoanId = undefined;
    this.selectedLoan = undefined;

    let filter = this.losCredentialId + '?';
    if (this.losReference && this.losReference.length > 0)
      filter += 'loanNumber=' + this.losReference + '&';
    if (this.borrowerFirst && this.borrowerFirst.length > 0)
      filter += 'borrowerFirstName=' + this.borrowerFirst + '&';
    if (this.borrowerLast && this.borrowerLast.length > 0)
      filter += 'borrowerLastName=' + this.borrowerLast + '&';

    this._losService.searchLos(filter).subscribe({
      next: (response) => {
        this.loanSearchList = response;
        this.isSearching = false;
      },
      error: (err) => {
        this.isSearching = false;
        this.loanSearchList = undefined;
        this._notificationService.showError(err?.message || "Unable to search results...", "Error")
      }
  });
  }

  setSelectedLoan(loanNumber, losIdentifier) {
    this.selectedLoanId = losIdentifier;
    this.selectedLoan = {
      loanNumber: loanNumber,
      losIdentifier: losIdentifier,
    };
  }

  link() {
    this.isLinking = true;

    this._losService.link(this.applicationId, this.losCredentialId, this.selectedLoan.losIdentifier, this.selectedLoan.loanNumber)
      .pipe(finalize(() => this.isLinking = false))
      .subscribe({
        next: (res) => {
          this.activeModal.close(res);
        },
        error: (error) => {
          this._notificationService.showError(error?.message || "Link Error", 'Link Error');
        }
      });
  }

  private startWithOneVendor = () => {
    this.changeLosCredential(this.losVendors[0].credentialId);
  }
}
