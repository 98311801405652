<div class="input-group">
  <input
    [type]="showPassword ? 'text' : 'password'"
    class="form-control"
    [(ngModel)]="value"
    #model="ngModel"
    name="{{ name }}"
    [readonly]="readonly"
    [required]="required"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [ngClass]="{'is-invalid' : model && model.touched && model.invalid}"
    autocomplete="new-password"
  />
  <div class="input-group-append">
    <i class="fa password-eye-icon"
       [ngClass]="{
          'fa-eye-slash': showPassword,
          'fa-eye': !showPassword
          }"
       (click)="showPassword = !showPassword"
    ></i>
  </div>
  <div class="invalid-feedback">{{validationFieldName}} is required.</div>
</div>
