import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Company, RoleList, ThirdPartyCredential, ThirdPartyCredentialArray } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { Constants } from '../../../../../services/constants';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { LenderConfigService } from '../../../../admin/lender-config/services/lender-config.service';
import { TitleOrderingModalComponent } from './title-ordering-modal/title-ordering-modal.component';

@Component({
    selector: 'title-ordering',
    templateUrl: './title-ordering.component.html'
})
export class TitleOrderingComponent extends ApplicationContextBoundComponent implements OnInit {
    @Input() company: Company;
    @Input() isTitleOrderingEnabled: boolean;
    @Input() titleOrderingEnabled: Configuration;
    @Input() titleCredentials: ThirdPartyCredentialArray;

    roles: RoleList;
    lodasoftLenders: any[];
    titleLenders: any[];
    loadingError: boolean = false;
    constructor(
        private readonly injector: Injector,
        private readonly _modalService: NgbModal,
        private readonly _lenderService: LenderConfigService,
        private readonly _notificationService: NotificationService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (!this.isTitleOrderingEnabled) {
            return
        }

        this.roles = this.applicationContext.globalConfig.roles;
        combineLatest([
            this._lenderService.getAllLenders(),
            this._lenderService.getTitleLenders(),
        ]).subscribe({
            next: res => {
                this.lodasoftLenders = res[0].map(el => ({ lenderId: el.lenderId, lenderName: el.name }));
                this.titleLenders = res[1].map(el => ({ lenderId: el.thirdPartyLenderId, lenderName: el.lenderName }));
            },
            error: (err) => {
                this.loadingError = true;
                this._notificationService.showError(err?.message || 'Unable to load lenders', 'Lenders');
            }
        });
    }

    openCredentialModal(credential: ThirdPartyCredential): void {
        const modalRef = this._modalService.open(TitleOrderingModalComponent, Constants.modalOptions.large);
        modalRef.componentInstance.credential = credential;
        modalRef.componentInstance.roles = this.roles;
        modalRef.componentInstance.lodasoftLenders = this.lodasoftLenders;
        modalRef.componentInstance.titleLenders = this.titleLenders;
        modalRef.componentInstance.showLenderLoadingError = this.loadingError;
        modalRef.result.then(
            (result) => {
                const index = this.titleCredentials.findIndex(el => el.credentialId === result.credentialId);
                if (index > -1) {
                    this.titleCredentials[index] = result;
                } else {
                    this.titleCredentials.push(result);
                }
            },
            () => { }
        );
    }
}
