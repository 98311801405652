import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { MessageHistoryItem } from 'src/app/models/message.model';
import { Constants } from 'src/app/services/constants';
import { EmailService } from 'src/app/services/email.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FullSizeScrollableTableComponent } from 'src/app/shared/components/full-size-scrollable-table-base.component';
import { PreviewSentEmailDialogComponent } from '../preview-sent-email-dialog/preview-sent-email-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'correspondence-history-table',
  templateUrl: 'correspondence-history-table.component.html',
  styleUrls: ['correspondence-history-table.component.scss']
})
export class CorrespondenceHistoryTableComponent extends FullSizeScrollableTableComponent {

  @ViewChild("messageHistoryTable")
  table: Table;

  @Input()
  set history(value: MessageHistoryItem[]) {
    this.prepareDisplayColumns(value);
    this._history = value || [];
  }

  get history(): MessageHistoryItem[] {
    return this._history;
  }

  @Input()
  showMessageFilter: boolean = true;

  @Output()
  historyChanged = new EventEmitter<any>();

  filter: string = "All";

  globalFilterFields: string[] = ['delivery', 'to', 'from', 'subject', 'statusDisplayText', 'campaignName', 'actionDate'];

  private _history: MessageHistoryItem[] = [];

  constructor(private readonly _modalService: NgbModal,
    private readonly _messageService: MessageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _emailService: EmailService,
    private readonly _notifyService: NotificationService) {
    super();
  }

  onFilterChanged = () => {
    if (this.filter === 'All') {
      this.table.reset();
      return;
    }
    this.table.filter(this.filter, 'delivery', 'equals');
  }

  onViewDetailsClicked = (message: MessageHistoryItem) => {
    this._spinner.show();
    this._emailService.getEmailDetail(message.id).subscribe(details => {
      const modalRef = this._modalService.open(PreviewSentEmailDialogComponent, Constants.modalOptions.xlarge);
      const email = this._history.find(email => email.id === details.id);
      //if details does not have error detail but list does then add it to the details.
      if (message.errorDetail && !details.errorDetail) {
        details.errorDetail = message.errorDetail;
      }
      if (email) {
        details.extendedDetail = email.extendedDetail;
      }
      modalRef.componentInstance.message = details;
    }, err => {
      this._notifyService.showError(err ? err.message || err : 'Unable to get details of message', "Error");
    }).add(() => this._spinner.hide());
  }

  requeuePausedEmailSms = (messageId: number) => {
    this._messageService.requeuePausedEmailSms(messageId)
      .subscribe(() => {
        this.historyChanged.emit();
        this._notifyService.showSuccess('Email queued for sending', 'Success');
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : 'Unable to queue email for sending', "Error");
      });
  }

  resendEmail(messageId: number) {
    this._emailService.setEmailPriorityToRetry(messageId)
      .subscribe(history => {
        this.historyChanged.emit();
        this._notifyService.showSuccess('Email queued for retry', 'Success');
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : 'Unable to queue email for retry', "Error");
      });
  }

  customSort(event: SortEvent) {
    if (event?.field !== 'status') {
      return;
    }

    const fields: string[] = [
      'statusDisplayText',
      'actionDate',
    ];
    const order = event.order > 0 ? 'asc' : 'desc';
    const orders = Array.from(
      { length: fields.length },
      () => order,
    ) as ['asc' | 'desc'];

    this._history = _.orderBy(this._history, fields, orders);
  }

  protected onClickSortStatus(el: HTMLTableCellElement): void {
    const currentOrder = el.getAttribute('aria-sort') === 'ascending' ? 1 : -1;
    const order = currentOrder * -1;
    const field = 'status';
    const event = { field, order };

    this.customSort(event);
  }

  private prepareDisplayColumns = (history) => {
    history.forEach(h => {
      // lastStatusDate - MCAI, dateInserted - loan
      let actionDate: Date | null = h.dateInserted || h.lastStatusDate;
      let status = "";

      switch (h.status) {
        case 'Success':
          if (h.mostRecentOpenDate) {
            status = 'Opened';
            actionDate = h.mostRecentOpenDate;
          } else {
            status = 'Sent';
            // sentDate - MCAI, dateSent - loan
            actionDate = h.dateSent || h.sentDate || actionDate;
          }
          break;
        case 'Failure':
          status = 'Error';
          break;
        case 'FailureSmsInvalidRecepient':
          status = 'Invalid Recipient';
          break;
        case 'InProcess':
          status = 'Processing';
          break;
        case 'Unprocessed':
          status = 'Failed';
          break;
        case 'Paused':
          status = 'Paused';
          break;
        default:
          status = h.status;
      }

      h['statusDisplayText'] = status;

      if (actionDate) {
        h['actionDate'] = new Date(actionDate);
      }

      if (h.subject) {
        this.getRestrictedSubject(h);
      }
    })
  }

  private getRestrictedSubject = (history: MessageHistoryItem) => {
    const fieldsCount = history.subject.length;
    history["fields"] = `Field Count: (${fieldsCount || "0"})`;

    const historySubjectDisplay = this.historySubjectDisplay(history);
    history["subjectTruncated"] = historySubjectDisplay.truncated;
    history["subjectOriginal"] = historySubjectDisplay.all;
  }

  private historySubjectDisplay = (history: MessageHistoryItem): any => {
    const truncatedSubjectString = history.subject.length > 62
      ? _.take(history.subject, 62).join('') + `...`
      : history.subject;

    const originalSubject = history.subject;
    return { all: originalSubject, truncated: truncatedSubjectString };
  }
}
