<div class="w-100">
  <div class="justify-content-center" *ngIf="notes && notes.length > 0" style="height: 100%">
    <div class="card" style="height: 100%">
      <div class="task-notes-activity activities-list" data-simplebar>
        <div class="activity">
          <div class="activity-info" *ngFor="let note of notes | sort : 'desc': 'dateInserted'">
            <div class="icon-info-activity">
              <i class="las la-comments bg-soft-primary"></i>
            </div>
            <div class="activity-info-text mt-2">
              <div class="d-flex justify-content-between">
                <div class="w-75">
                  <p [hidden]="!note.content" class="mb-0 font-13 w-75"
                    [innerHTML]="internalNoteImageProcessStatuses.get(note['uniqueId']).processedContent | safeHtml">
                  </p>
                  <small class="text-muted">- <span class="text-muted" *ngIf="note.userId == userId">you</span>
                    <span class="text-muted"
                      *ngIf="note.userId != userId">{{note.senderFirstName}} {{note.senderLastName}}
                    </span>
                    </small>
                </div>
                <span class="text-muted d-block"><i>{{note.postTime | date : 'short' }}</i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div>
      <label class="form-label" for="message" *ngIf="isAddingNewNoteActive && notes && notes.length > 0">Add a
        Note</label>
      <div class="footer-features px-2">
        <message-editor-with-mentions *ngIf="isAddingNewNoteActive" #messageEditor [disabled]="isEditorDisabled"
          [mentionables]="mentionables" (messageChanged)="onMessageChanged($event)">
        </message-editor-with-mentions>
        <div class="row pb-2" *ngIf="actionButtonsEnabled">
          <div class="d-flex flex-wrap col-sm-10" name="attachments" id="attachments">
            <div class="d-flex ms-2 mb-2" *ngFor="let attachment of noteAttachments; let index = index">
              <img [src]="attachment.base64 | safeUrl" [height]="30" (error)="onImageError(attachment)"
                class=" rounded" />
              <i class="fa fa-trash-alt cursor text-danger mt-2 ms-1" (click)="deleteNoteAttachment(attachment)"></i>
            </div>
          </div>
          <div class="bottom-features col-sm-2 text-end">
            <input type="file" [accept]="accept" style="display:none" #addAttachment [(ngModel)]="fakeData"
              (change)="handleFileUpload($event)" multiple>
            <a href="javascript:void(0)" class="me-2" (click)="addAttachment.click()" *ngIf="false"><i
                class="fas fa-paperclip"></i></a>
            <a href="javascript:void(0)" *ngIf="!isSendingNoteMessage" (click)="submitNewMessage()"><i
                class="far fa-paper-plane"></i></a>
            <a href="javascript:void(0)" *ngIf="isSendingNoteMessage"><i class="fas fa-sync-alt fa-spin"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
