import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { MergeFieldContextMenuComponent } from '../merge-field-context-menu/merge-field-context-menu.component';
@Component({
  selector: 'send-mms',
  templateUrl: './send-mms.component.html',
  styleUrls: ['./send-mms.component.scss'],
  providers: [MakeProvider(SendMmsComponent)]
})
export class SendMmsComponent extends AbstractValueAccessor implements OnInit {

  @ViewChild(MergeFieldContextMenuComponent)
  mergeFieldContextMenu: MergeFieldContextMenuComponent;

  private _body: string = "";

  @Input()
  set body(body: string) {
    this._body = body;
  }

  get body(): string {
    return this._body;
  }

  @Input()
  allowedTypes: FileTypes[] = null;

  @Input()
  placeholder: string = "";

  @Input()
  rows: number = 7;

  @Input()
  cols: number = 12;

  @Input()
  width: number = 120;

  @Input()
  height: number = 120;

  @Input()
  attachments: FileAttachment[] = [];

  @Input()
  required: boolean = false;

  @Input()
  availableMergeFields: EnumerationItem[] = [];

  accept: string = "";

  hiddenFirstFile: boolean = false;

  fakeData: string = "";
  mergeFieldsMentionConfig: any;

  constructor(private _notify: NotificationService) {
    super();
  }

  ngOnInit(): void {

    if (this.availableMergeFields && this.availableMergeFields.length > 0) {
      this.mergeFieldsMentionConfig = {
        items: this.availableMergeFields,
        triggerChar: '%',
        labelKey: 'value',
        mentionSelect: (el) => `%${el.value}%`
      }
    }

    if (!this.attachments) {
      this.attachments = [];
    }
    if (this.attachments.length > 0) {
      this.hiddenFirstFile = true;
    }

    if (this.allowedTypes) {
      this.accept = this.convertToAcceptDescriptor(this.allowedTypes);
    }

    const el = document.getElementById("mmsBody");
    el.addEventListener("paste", (event) => {
      this.populateAttachments(event.clipboardData.items);
    });

    el.addEventListener("drop", (event) => {
      event.preventDefault();
      this.populateAttachments(event.dataTransfer.items);
    });

    const att = document.getElementById("attachments");
    att.addEventListener("drop", (event) => {
      event.preventDefault();
      this.populateAttachments(event.dataTransfer.items);
    });

    att.addEventListener("dragover", (event) => {
      event.preventDefault();
    });
  }

  onImageError = (attachment) => {
    attachment.base64 = "";
  }

  deleteAttachment = (attachment) => {
    let index = this.attachments.indexOf(attachment);
    this.attachments.splice(index, 1);
    this.fakeData = "";
  }

  handleFileUpload = (event) => {
    event.target.files.forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        let data = new FileAttachment();
        data.file = file;
        data.base64 = event.target.result;
        this.attachments.push(data);
      };
    });
  }

  onContextMenu($event: MouseEvent, targetProperty: string): void {
    this.mergeFieldContextMenu.show(
      $event,
      this,
      targetProperty,
      null
    );
    $event.preventDefault();
    $event.stopPropagation();
  }

  private convertToAcceptDescriptor = (allowedTypes: FileTypes[]): string => {
    let accept = "";
    if (allowedTypes.includes(FileTypes.Image)) {
      accept += "image/*"
    }
    if (allowedTypes.includes(FileTypes.Application)) {
      accept += (accept ? ", application/*" : "application/*");
    }
    if (allowedTypes.includes(FileTypes.Pdf)) {
      accept += (accept ? ", application/pdf" : "application/pdf");
    }
    if (allowedTypes.includes(FileTypes.Xml)) {
      accept += (accept ? ", application/xml" : "application/xml");
    }
    if (allowedTypes.includes(FileTypes.Doc)) {
      let doc = ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      accept += (accept ? ", " + doc : doc);
    }
    if (allowedTypes.includes(FileTypes.Text)) {
      accept += (accept ? ", text/plain" : "text/plain");
    }

    return accept;
  }

  private populateAttachments = (items: DataTransferItemList) => {
    for (let i = 0; i < items.length; i++) {
      let data = new FileAttachment();
      data.file = items[i].getAsFile();

      if (this.checkFileType(data.file.type)) {
        let fr = new FileReader;

        fr.readAsDataURL(data.file);

        fr.onloadend = (e) => {
          data.base64 = e.target.result;
          this.attachments.push(data);
        };
      }
      else {
        this._notify.showError('The type of file is not allowed', '');
      }
    }
  }

  private checkFileType = (type: string) => {
    if (this.accept == "") {
      return true;
    }
    if (type.startsWith('image/') && this.allowedTypes.includes(FileTypes.Image)) {
      return true;
    }
    else if (type.startsWith('application/') && this.allowedTypes.includes(FileTypes.Application)) {
      return true;
    }
    else if (this.accept.includes(type)) {
      return true;
    }
    return false;
  }
}

export class FileAttachment {
  file: File;
  base64: string | ArrayBuffer;
}

export enum FileTypes {
  Image = 'image',
  Pdf = "pdf",
  Xml = "xml",
  Doc = "doc",
  Application = "application",
  Text = "text/plain",
  Video = 'video'
}
