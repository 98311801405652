import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { PrequalLetterSettingsComponent } from './components/prequal-letter-settings.component';
import { PrequalLetterSettingsRoutingModule } from './prequal-letter-settings-routing.module';

@NgModule({
    imports: [
      SharedModule,
      PrequalLetterSettingsRoutingModule
    ],
    declarations: [
      PrequalLetterSettingsComponent,
    ],
    exports: [
      PrequalLetterSettingsComponent,
    ],
    providers: []
})
export class PrequalLetterSettingsModule {
  constructor() { }
}
