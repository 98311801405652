import { LoanApplication } from "src/app/models";
import { BorrowerCharacteristic, LoanCharacteristic } from "src/app/models/config/characteristics.model";
import { LoanPurpose } from "src/app/models/config/loan-purpose.model";
import { LoanType } from "src/app/models/config/loan-type.model";
import { LoanFee } from "src/app/models/fee/fee.model";
import { InternalContact } from "../../internal-contacts/models/internal-contact.model";
import { ExternalContact } from "../../loan-docs/models/external-contact.model";
import { LoanCredit } from "../../loan-services/credit/models/loan-credit.model";
import { UrlaMortgage } from "../../urla/models/urla-mortgage.model";

interface PartialLoanCharacteristics extends Partial<LoanCharacteristic> {
  isSelected?: boolean
}

interface PartialBorrowerCharacteristics extends Partial<BorrowerCharacteristic> {
  borrowerId?: string;
  selectedCharacteristics?: number[];
}

export interface AlignmentRoleUsers {
  [roleId: number]: string
}

export class ProcessSubmissionModel {
  mortgageViewModel: UrlaMortgage = new UrlaMortgage();
  loanNumber: string;
  losIdentifier: string;
  credentialId: string;
  linkedBorrowers: any;
  loanCharacteristics: Array<PartialLoanCharacteristics>
  borrowerCharacteristics: Array<PartialBorrowerCharacteristics>
  loanPurposeId: number | string;
  loanTypeId: number | string;
  primaryRoleUserId: string;
  additionalAlignmentRoleUsers: AlignmentRoleUsers;
  channel: string;
  importMismo: boolean;
  sanitizeLoanData: boolean;
  submissionViaFile: boolean;
  suggestedBorr: any;
  scenarioId: number | null;
  keyDateValues: any;
  loanFees: Array<LoanFee>;
  referralSource: number | null;
  secondaryReferralSource: number | null;
  referralSourceType: number | null;
  secondaryReferralSourceType: number | null;
  referredTo: number | null;
  insideReferralSource: string | null;
  availableLoanTypes: Array<LoanType>;
  availableLoanPurposes: Array<LoanPurpose>;

  loanData?: LoanData;
}

export type LoanData = {
  mortgage: UrlaMortgage,
  application: LoanApplication,
  extraData: any,
  fees: LoanFee[],
  creditInfo: LoanCredit[],
  keyDateValues: any,
  internalContacts: InternalContact[],
  externalContacts: ExternalContact[]
}
