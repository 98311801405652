import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LeadStatus } from 'src/app/models';
import { LeadStatusAssociation } from '../../models';

@Injectable()
export class LeadStatusFlowService {
  constructor(private readonly _dataService: DataService) {}

  getAllLeadStatusAssociations(
    statusType: string,
    loanPurposeId: number,
    companyId: number
  ): Observable<Array<LeadStatusAssociation>> {
    return this._dataService.get(
      `api/Admin/GetAll${statusType}StatusAssociationsByLoanPurpose/${loanPurposeId}?companyId=${companyId}`
    );
  }

  getAllLeadStatuses(
    statusType: string,
    companyId: number
  ): Observable<Array<LeadStatus>> {
    return this._dataService.get(
      `api/Admin/All${statusType}StatusModel?companyId=${companyId}`
    );
  }

  updateLeadStatusFlowTree(
    statusType,
    loanPurposeId: number,
    statusId: number,
    associated: string,
    companyId: number
  ): Observable<LeadStatus> {
    let url = `api/Admin/SaveAssociate${statusType}Status/${loanPurposeId}/${statusId}`;
    url += `/${associated ? associated : '0'}`;

    return this._dataService.post(`${url}?companyId=${companyId}`, {});
  }
}
