import { Component, HostListener, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgWizardConfig, NgWizardService, NgWizardStep, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, finalize, from, Observable, of, map } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { Profile, SystemLevel, ThirdPartyCredential, ThirdPartyCredentialArray, ThirdPartyCredentialType, ThirdPartyKeyValue } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { TimeZone } from 'src/app/models/timezone.model';
import { GlobalConfigService } from 'src/app/services/global-config.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2';
import { ProfileService } from '../../profile.service';

@Component({
  selector: 'setup-profile',
  templateUrl: './setup-profile.component.html',
  styleUrls: ['./setup-profile.component.scss']
})
export class SetupProfileComponent extends ApplicationContextBoundComponent implements OnInit, ComponentCanDeactivate {

  currentStep: NgWizardStep = {
    index: 0,
    title: '',
    description: '',
    component: undefined,
    componentRef: undefined,
    canEnter: false,
    canExit: false,
    hidden: false
  };

  configs: GlobalConfig;
  userProfile: Profile;
  systemLevel: SystemLevel;
  tenantKvp: ThirdPartyKeyValue;

  timezones: TimeZone[] = [];
  isLoggedInUserProfile: boolean = false;
  loading: boolean = false;

  amazonWebServices: ThirdPartyCredential;
  docuSign: ThirdPartyCredential;
  ayrshare: ThirdPartyCredential;

  losCredentials: ThirdPartyCredentialArray = [];
  creditCredentials: ThirdPartyCredentialArray = [];
  du: ThirdPartyCredentialArray = [];
  do: ThirdPartyCredentialArray = [];
  lpa: ThirdPartyCredentialArray = [];

  isDesktopUnderwriterEnabledForCompany: boolean;
  isDesktopOriginatorEnabledForCompany: boolean;
  isLpaEnabledForCompany: boolean;
  isCreditEnabledForCompany: boolean;
  isLosEnabledForCompany: boolean;
  saveInProgress: boolean;

  skipStep: boolean = false;

  editEmailTemplates = false;
  editSMSTemplates = false;
  editVoicemailTemplates = false;

  companyCredentialId?: number

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  configSetupWizard: NgWizardConfig = {
    selected: 0,
    anchorSettings: {
      anchorClickable: true,
    },
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    }
  };

  constructor(private readonly injector: Injector,
    private readonly _ngWizardService: NgWizardService,
    private readonly _profileService: ProfileService,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _globalConfigService: GlobalConfigService,
    private readonly route: ActivatedRoute,
    private readonly _navigationService: NavigationService) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = true;
    this.getTimezones();
    this.applicationContextService.context.subscribe(context => {
      this.configs = context.globalConfig;
      this.isDesktopUnderwriterEnabledForCompany = context.userPermissions.desktopUnderwriterEnabled;
      this.isDesktopOriginatorEnabledForCompany = context.userPermissions.desktopOriginatorEnabled;
      this.isLpaEnabledForCompany = context.userPermissions.lpaEnabled;
      this.isCreditEnabledForCompany = context.userPermissions.creditReportingEnabled;
      this.isLosEnabledForCompany = context.userPermissions.losEnabled;;

      this.isLoggedInUserProfile = !this.route.snapshot.params.id || (context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid === this.route.snapshot.params.id);
      const combined = combineLatest([this._systemLevelService.getSystemLevel(), this._profileService.getUserProfile(this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid)])

      combined
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (response: Array<any>) => {
            this.systemLevel = response[0];
            this.userProfile = response[1];
            if (!this.userProfile.allowCancelEsignOverride) {
              this.userProfile.allowCancelEsignOverride = {
                key: "AllowCancelEsignOverride",
                id: 0,
                value: 0,
                userId: this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid
              }
            }
            if (!this.userProfile.allowCopyLoanOverride) {
              this.userProfile.allowCopyLoanOverride = {
                key: "AllowCopyLoanOverride",
                id: 0,
                value: 0,
                userId: this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid
              }
            }
            if (!this.userProfile.allowDeleteTaskOverride) {
              this.userProfile.allowDeleteTaskOverride = {
                key: "AllowDeleteTaskOverride",
                id: 0,
                value: 0,
                userId: this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid
              }
            }
            this.creditCredentials = this.userProfile.thirdPartyCredentials.filter(el => el.credentialType === 'CreditVendor');
            this.docuSign = this.userProfile.thirdPartyCredentials.find(el => el.vendorName === 'DocuSign');
            this.du = this.userProfile.thirdPartyCredentials.filter(el => el.vendorName === 'DU');
            this.do = this.userProfile.thirdPartyCredentials.filter(el => el.vendorName === 'FannieMae' && el.credentialType === 'AUSOriginator');
            this.lpa = this.userProfile.thirdPartyCredentials.filter(el => el.vendorName === 'LPA');
            this.losCredentials = this.userProfile.thirdPartyCredentials.filter(el => el.credentialType === 'LosVendor');
            this.ayrshare = this.userProfile.thirdPartyCredentials.find(el => el.vendorName === 'Ayrshare');
            this.initAmazonWebServices(this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid);

            const emailCred = this.systemLevel.thirdPartyCredentials.find(cred => cred.credentialType === "Email");
            this.companyCredentialId = this.systemLevel.thirdPartyCredentials.find(cred => cred.vendorName === "MicrosoftGraph")?.credentialId;
            this.tenantKvp = emailCred?.thirdPartyKeyValuePairs?.find(kvp => kvp.key === "TenantId");
          },
          error: (error) => {
            this._notificationService.showError(
              error?.message || 'Unable to load data',
              'Profile Service'
            );
          }
        });
    })
  }

  private getTimezones = () => {
    this._globalConfigService.getTimeZones().subscribe((res: TimeZone[]) => {
      this.timezones = res;
    })
  }

  showPreviousStep(event?: Event) {
    this._ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this._ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this._ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this._ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    this.skipStep = false;
    this.currentStep = args.step;
    if (this.currentStep.index == 2 || this.currentStep.index == 4 || this.currentStep.title === 'Finish') {
      this.skipStep = true;
    }
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }

  nextStep = () => {
    if (this.skipStep || (!this.editEmailTemplates && this.currentStep.index == 6 ) || 
      (!this.editSMSTemplates && this.currentStep.index == 7 ) || (!this.editVoicemailTemplates && this.currentStep.index == 8 ) ) {
      this._ngWizardService.next();
    } else {
      this.saveInfo();
    }
  }

  saveInfo = () => {
    if (this.userProfile.userProfile.stackOrderId == 0) {
      this.userProfile.userProfile.stackOrderId = null;
    }
    this.saveInProgress = true;
    this._spinner.show();
    this._profileService.saveUserProfile(this.userProfile)
      .pipe(finalize(() => {
        this._spinner.hide();
        this.saveInProgress = false;
      }))
      .subscribe({
        next: (savedProfile) => {
          this._ngWizardService.next();
          
          if (!savedProfile.userProfile.stackOrderId) {
            savedProfile.userProfile.stackOrderId = 0;
            this.userProfile.userProfile.stackOrderId = 0;
          }
          if (this.isLoggedInUserProfile) {
            this.applicationContextService.updateUserProfile(savedProfile.userProfile);
          }
          this.userProfile = {
            ...this.userProfile,
            ...savedProfile,
            emailService: {
              ...savedProfile.emailService,
              serviceType: savedProfile.emailService.serviceType || null
            }
          };
        },
        error: (err) => {
          this._ngWizardService.next();
          this._notificationService.showError(
            err?.message || 'Unable to update user profile',
            'Profile Service'
          );
        }
      });
  }

  gotoDashboard = () => {
    this._navigationService.navigateToPath('admin/dashboardv2');
  }

  @HostListener('window:beforeunload')
  canDeactivate = (): boolean | Observable<boolean> => {
    return false;
  }

  confirm = (): boolean | Observable<boolean> => {

    return from(Swal.fire({
      showDenyButton: true,
      title: 'Are you sure want leave this page?',
      text: `You can edit it at any time from the profile tab at the top right.`,
      icon: 'question',
      
    }))
      .pipe(

        map(result => {

          return result.value;
        })
      );
  }

  private initAmazonWebServices = (userId: string) => {
    this.amazonWebServices = this.userProfile.thirdPartyCredentials.find(el => el.vendorName === 'AmazonWebServices');
    if (!this.amazonWebServices) {
      this.amazonWebServices = new ThirdPartyCredential(ThirdPartyCredentialType.CloudPlatform, 'AmazonWebServices', userId);
      this.userProfile.thirdPartyCredentials.push(this.amazonWebServices);
    }
  }

}
