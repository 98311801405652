<table class="lp-style-17">
  <thead class="lp-style-18">
    <tr class="lp-style-19">
      <th colspan="2" class="lp-style-20">{{ title }}</th>
    </tr>
  </thead>
  <tbody class="lp-style-21">
    <tr class="lp-style-22">
      <td class="lp-style-23">Interest Rate</td>
      <td class="lp-style-24">
        <div class="lp-style-25">
          <span class="form-control" readonly>
            {{(parameters.interestRate || 0) | percent: '1.3-3'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="lp-style-28">
      <td class="lp-style-29">Term</td>
      <td class="lp-style-30">
        <span class="form-control lp-style-31" readonly>
          {{(parameters.term || 0)}}
        </span>
      </td>
    </tr>
    <tr class="lp-style-32">
      <td class="lp-style-33">P & I</td>
      <td class="lp-style-34">
        <div class="lp-style-35">
          <span class="form-control" readonly>
            {{(parameters.pi || 0) | currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="lp-style-41">
      <td class="lp-style-42">Hazard</td>
      <td class="lp-style-43">
        <div class="lp-style-44">
          <span class="form-control" readonly>
            {{(parameters.hazard || 0) | currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="lp-style-50">
      <td class="lp-style-51">Taxes</td>
      <td class="lp-style-52">
        <div class="lp-style-53">
          <span class="form-control" readonly>
            {{(parameters.taxes || 0) | currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="lp-style-59">
      <td class="lp-style-60">P(MI)</td>
      <td class="lp-style-61">
        <div class="lp-style-62">
          <span class="form-control" readonly>
            {{(parameters.pmi || 0)| currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="lp-style-68">
      <td class="lp-style-69">PITI</td>
      <td class="lp-style-70">
        <div class="lp-style-71">
          <span class="form-control" readonly>
            {{(parameters.piTi || 0) | currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="py-4 lp-style-77">
      <td class="lp-style-78" >HOA</td>
      <td class="lp-style-79">
        <div class="lp-style-80">
          <span class="form-control" readonly>
            {{(parameters.hoa || 0) | currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
    <tr class="lp-style-86">
      <td class="lp-style-87">{{ title === 'Today' ? 'Current' : 'Total' }} Housing</td>
      <td class="lp-style-88">
        <div class="lp-style-89">
          <span class="form-control" readonly>
            {{(parameters.totalHousing || 0) | currency: 'USD':'symbol':'.2':'en-US'}}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
