import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoanApplication } from 'src/app/models';
import { CreditBorrower } from 'src/app/modules/loan-services/credit/models/credit-borrower.model';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'loan-pricing-summary',
  templateUrl: './loan-pricing-summary.component.html',
  styleUrls: ['./loan-pricing-summary.component.scss']
})
export class LoanPricingSummaryComponent implements OnInit {

  @Input()
  application: LoanApplication

  @Input()
  applicationId: number;

  @Input()
  borrowers: CreditBorrower[] = [];

  @Input()
  isTpo: boolean = false;

  @Input()
  isPRMG: boolean = false;

  @Output()
  repriceLoanClicked: EventEmitter<any> = new EventEmitter<any>();

  primaryBorrower: CreditBorrower;

  constructor(private readonly _navigationService: NavigationService,) { }

  ngOnInit(): void {
  }

  onRePriceLoanClicked = () => {
    this.repriceLoanClicked.emit();
    let root: string = this.isTpo ? 'tpo' : 'admin';
    this._navigationService.navigateToPath(`${root}/app-details/${this.applicationId}/pricing`);
  }

  getPercentNumber = (number: number) => {
    return number / 100;
  }
}
