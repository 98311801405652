import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MiQuoteSearchResult } from 'src/app/models/mi-quote-search-result.model';
import { SelectedMiQuoteInfo } from '../mi-quote-search-dialog/mi-quote-search-dialog.component';

@Component({
  selector: 'mi-quote-select-dialog',
  templateUrl: './mi-quote-select-dialog.component.html',
  styleUrls: ['./mi-quote-select-dialog.component.scss']
})
export class MiQuoteSelectDialogComponent implements OnInit {

  interestRate: number;
  searchResult: MiQuoteSearchResult;
  selectedMiQuoteInfo: SelectedMiQuoteInfo = null;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onQuoteSelected = (data: SelectedMiQuoteInfo) => {
    this.selectedMiQuoteInfo = data;
  }

  onQuoteSelectedAfterReRun = (data: SelectedMiQuoteInfo) => {
    this.selectedMiQuoteInfo = data;
    this.activeModal.close(data);
  }

}
