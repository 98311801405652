import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { CustomData, KeyDatesByType, LoanApplication } from 'src/app/models';
import { Lender } from 'src/app/models/config/lender.model';
import { LoanProduct } from 'src/app/models/config/product.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LenderConfigService } from '../../admin/lender-config/services/lender-config.service';
import { AppDetailsService } from '../../app-details/services/app-details.service';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';
import { MortgageCalculationService } from '../../urla/services/mortgage-calculation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LenderInfoDialogComponent } from '../../app-details/components/loan-info/loan-details/lender-info-dialog/lender-info-dialog.component';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'edit-pricing-details',
  templateUrl: './edit-pricing-details.component.html',
  styleUrls: ['./edit-pricing-details.component.scss']
})
export class EditPricingDetailsComponent implements OnInit {

  @ViewChild("editPricingDetailsForm")
  editPricingDetailsForm: NgForm;

  @Input()
  currentMortgage: UrlaMortgage;

  @Input()
  application: LoanApplication;

  @Input()
  products: LoanProduct[] = [];

  @Input()
  customData: CustomData;

  @Input()
  set applicationKeyDatesByType(value: KeyDatesByType) {
    this._applicationKeyDatesByType = value;
    this.updateKeyDateBasedPricingFields(false);
  }

  get applicationKeyDatesByType(): KeyDatesByType {
    return this._applicationKeyDatesByType;
  }

  lenders: Lender[] = [];

  isSaving: boolean = false;

  hasRateLockExpirationKeyDate: boolean = false;

  compTypes: any = [
    { name: 'Lender Paid', value: 'Lender' },
    { name: 'Borrower Paid', value: 'Borrower' },
    { name: 'Correspondent', value: 'Correspondent' }
  ];

  private _applicationKeyDatesByType: KeyDatesByType;

  constructor(
    private readonly _appDetailsService: AppDetailsService,
    private readonly _lenderService: LenderConfigService,
    private readonly _mortgageService: MortgageService,
    private readonly _modalService: NgbModal,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _appCtxService: ApplicationContextService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    if (this.application.productPricing) {
      if(!this.application.productPricing.productId){
        this.application.productPricing.productId = null;
      }
      if(!this.application.productPricing.compType){
        this.application.productPricing.compType = null;
      }
    }

    if (!this.application.lenderId) {
      this.application.lenderId = null;
    }

    this.loadLenders();
    this.updateKeyDateBasedPricingFields(true);
  }

  saveLoanInfo = () => {
    this.editPricingDetailsForm.form.markAllAsTouched();

    if (this.application.productPricing && this.application.productPricing.productId) {
      const product = this.products.find(el => el.productId.toString() == this.application.productPricing.productId);
      if (product) {
        this.application.productPricing.productName = product.productName;
      }
    }

    this.adjustCustomData();

    const payLoad = new LoanDetailsInfo();
    payLoad.application = _.cloneDeep(this.application);
    payLoad.customData = _.cloneDeep(this.customData);
    payLoad.application.mortgageLoan = _.cloneDeep(this.currentMortgage);

    this._spinner.show();
    this._appDetailsService.saveLoanInfo(this.application.applicationId, payLoad).subscribe({
      next: (loanDetailsInfo) => {
        this._spinner.hide();

        if (loanDetailsInfo) {
          this.application = loanDetailsInfo.application;
          this.currentMortgage = loanDetailsInfo.application.mortgageLoan || this.currentMortgage; // compatibilty: admin is deployed before api

          this._appCtxService.updateMortgageAndApplication(this.currentMortgage, this.application, this.customData);

          this._notifyService.showSuccess(
            'Your loan has been saved successfully.',
            'Success!',
          );

        }
      },
      error: (e) => {
        this._spinner.hide();
        this._notifyService.showError(
          e.message ?? 'Unable to save loan info',
          'Error!',
        );
      }
    })
  }

  selectProduct(productId: string) {
    if (!productId) {
      return;
    }

    this._appDetailsService.getProductById(Number(productId)).subscribe((response) => {
      if (response.productId == Number(productId)) {
        this.application.productPricing.term = response.term;
        if (this.currentMortgage.mortgageTerm)
          this.currentMortgage.mortgageTerm.noOfMonths = response.term;
      } else {
        this.application.productPricing.term = null;
      }
    },
      (error) => {
        this._notifyService.showError(
          error ? error.message : 'Unable to get Product',
          'Error!'
        );
      });
  }

  onProductPricingRateChanged = () => {
    this.currentMortgage.mortgageTerm.interestRate = this.application.productPricing.rate;
    this.recalculateFirstMortgagePAndI();
  }

  showLenderInfoDialog = () => {
    const modalRef = this._modalService.open(LenderInfoDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.lenderInfo = this.lenders.find(x => x.lenderId == this.application.lenderId);
  }

  private adjustCustomData() {
    if (Array.isArray(this.customData.customData1)) {
      this.customData.customData1 = JSON.stringify(this.customData.customData1)
    }
    if (Array.isArray(this.customData.customData2)) {
      this.customData.customData2 = JSON.stringify(this.customData.customData2)
    }
    if (Array.isArray(this.customData.customData3)) {
      this.customData.customData3 = JSON.stringify(this.customData.customData3)
    }
    if (Array.isArray(this.customData.customData4)) {
      this.customData.customData4 = JSON.stringify(this.customData.customData4)
    }
    if (Array.isArray(this.customData.customData5)) {
      this.customData.customData5 = JSON.stringify(this.customData.customData5)
    }
    if (Array.isArray(this.customData.customData6)) {
      this.customData.customData6 = JSON.stringify(this.customData.customData6)
    }
    if (Array.isArray(this.customData.customData7)) {
      this.customData.customData7 = JSON.stringify(this.customData.customData7)
    }
    if (Array.isArray(this.customData.customData8)) {
      this.customData.customData8 = JSON.stringify(this.customData.customData8)
    }
    if (Array.isArray(this.customData.customData9)) {
      this.customData.customData9 = JSON.stringify(this.customData.customData9)
    }
    if (Array.isArray(this.customData.customData10)) {
      this.customData.customData10 = JSON.stringify(this.customData.customData10)
    }
  }

  private recalculateFirstMortgagePAndI = () => {
    this._spinner.show();

    this._mortgageService.redoMortgageCalculationDetails(this.currentMortgage).subscribe({
      next: (calculationDetails) => {
        this._spinner.hide();

        this.currentMortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
          calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;

        this.currentMortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
          calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;

        this.currentMortgage.calculatedStats.proposedMonthlyPaymentTotal = this._calculationService.calculateHousingExpenseTotal(this.currentMortgage.proposedHousingExpense);

      },
      error: (e) => {
        this._spinner.hide();

        this._notifyService.showError(
          e?.message || 'Unable to recalculate LTV and related values',
          'Error!'
        );
      }
    })

  }

  private loadLenders = async (): Promise<void> => {
    this._lenderService.getAllLenders().subscribe({
      next: (lenders) => {
        this.lenders = lenders.filter((e) => e.active)
          .sort((a, b) => a.sortOrder - b.sortOrder);
      },
      error: () => {

      }
    });
  }

  private updateKeyDateBasedPricingFields = (updateOnlyIfNotSet: boolean): void => {
    const keyDatesByType = this.applicationKeyDatesByType;
    this.hasRateLockExpirationKeyDate = keyDatesByType ? !!keyDatesByType['rateLockExpiration'] : false;

    if (this.application.productPricing && keyDatesByType) {
      const needToUpdateRateLockDate = !updateOnlyIfNotSet || (updateOnlyIfNotSet && !this.application.productPricing.lockDate);
      if (needToUpdateRateLockDate && keyDatesByType['rateLock'] && keyDatesByType['rateLock'].eventDate) {
        this.application.productPricing.lockDate = Utils.formatISODateWithoutTime(keyDatesByType['rateLock'].eventDate);
      }

      const needToUpdateRateLockExpirationDate = !updateOnlyIfNotSet || (updateOnlyIfNotSet && !this.application.productPricing.lockExpirationDate);
      if (needToUpdateRateLockExpirationDate && keyDatesByType['rateLockExpiration'] && keyDatesByType['rateLockExpiration'].eventDate) {
        this.application.productPricing.lockExpirationDate = Utils.formatISODateWithoutTime(keyDatesByType['rateLockExpiration'].eventDate);
      }
    }
  }
}
