<div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
  [ngClass]="{'sticky-nav-tabs-open-dialer': secondPageActionBarVisible }">
  <div class="button-items">
    <div class="float-start">
      <div class="d-inline-flex ms-3 mt-2" style="padding-top: 2px;">
      </div>
    </div>
    <div class="float-end">
      <button type="button" class="btn nav nav-link me-2 d-inline" [print]="[
      'loan-summary-pdf',
      {
        printMode: 'template-popup',
        pageTitle: getPersonFullNames([borrowers[0]?.borrowerId])
      }
    ]">
        Print as PDF
      </button>

      <button type="button" class="btn btn-primary" (click)="saveLoanInfo()">
        <i class="fas fa-save"></i>
        Save
      </button>
    </div>
  </div>
</div>
<div id="loan-summary-pdf">
  <form #loanForm="ngForm" novalidate id="loanForm" name="loanForm">
    <loan-borrowers
      [actionButtonsVisible]="true"
      [title]="'Customer(s)'"
      (deleted)="onBorrowerDeleted($event)"
      (updated)="onBorrowerUpdated($event)"
      (updatedPrimaryBorrower)="onUpdatedPrimaryBorrower($event)">
    </loan-borrowers>

    <div class="row" id="propertyInformation">
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-map-marked me-2"></i> Property Information </h4>
          </div>
          <div class="card-body">
            <div class="w-100 mt-2" style="height: 50% !important" #mapElementContainer *ngIf="!isSubjectPropertyAddressHidden">
              <div *ngIf="!application.solar.address || haveMapError"
                class="h-100 w-100 bg-info d-flex align-items-center justify-content-center text-white fw-bold">
                {{ application.solar.address || 'No Address Selected' }}
              </div>

              <div id="propertyAddressMap{{ mapId }}" #mapElement style="width: 100%; height: 100%"
                [hidden]="!application.solar.address || haveMapError"></div>
            </div>
            <div class="row" style="height: 50% !important;">
              <div class="col-md-6" *ngIf="!isSubjectPropertyAddressHidden">
                <div class="row py-1">
                  <div class="col-6">Address</div>
                  <div class="col-6">
                    <address-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.address"
                      name="ls_address" [editorMode]="editorMode" (addressChanged)="onHandleAddressChange($event)"
                      [placeholder]="'Address'">
                    </address-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">City</div>
                  <div class="col-6">
                    <text-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.city"
                      name="ls_city" [editorMode]="editorMode" [placeholder]="'City'"></text-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">State</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.state"
                      [options]="states" name="ls_state" [editorMode]="editorMode"
                      [placeholder]="'State'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Zip</div>
                  <div class="col-6">
                    <zip-code-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.zip"
                      [name]="'ls_zipCode'" [editorMode]="editorMode"
                      (selectionChanged)="onZipCodeRelatedInfoChanged($event)" [inlinePlaceholder]="'Zip'">
                    </zip-code-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Year Built</div>
                  <div class="col-6">
                    <number-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.yearBuilt"
                      name="ls_yearBuilt" [editorMode]="editorMode" [decimals]="0"
                      [placeholder]="'Year Built'"></number-input>
                  </div>
                </div>
              </div>
              <div class="col-md-{{!isSubjectPropertyAddressHidden ? '6' : '12'}}">
                <div class="row py-1" *ngIf="isSubjectPropertyAddressHidden">
                  <div class="col-6">Year Built</div>
                  <div class="col-6">
                    <number-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.yearBuilt"
                      name="ls_yearBuilt" [editorMode]="editorMode" [decimals]="0"
                      [placeholder]="'Year Built'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Utility Provider</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="application.solar.utilityProviderId" [options]="utilityProviders"
                      name="ls_utilityProviderId" [editorMode]="editorMode"
                      [placeholder]="'Utility Provider'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Project Type</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.projectType"
                      [options]="projectTypes" name="ls_projectType" [editorMode]="editorMode"
                      [placeholder]="'Project Type'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Property Type</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="application.solar.propertyType" [options]="propertyTypes" name="ls_propertyType"
                      [editorMode]="editorMode" [placeholder]="'Property Type'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Primary Title</div>
                  <div class="col-6">
                    <text-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.primaryTitle"
                      name="ls_primaryTitle" [editorMode]="editorMode" [placeholder]="'Primary Title'"></text-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Secondary Title</div>
                  <div class="col-6">
                    <text-input [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="application.solar.secondaryTitle" name="ls_secondaryTitle" [editorMode]="editorMode"
                      [placeholder]="'Secondary Title'"></text-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-stretch flex-column">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-credit-card me-2"></i> Credit Info </h4>
          </div>
          <div class="card-body">
            <div class="row card-body-summary mb-3" #creditInfoDetails>
              <table class="table table-hover table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th style="width: 40%" class="text-secondary">Name</th>
                    <th class="text-secondary">Experian</th>
                    <th class="text-secondary">Trans Union</th>
                    <th class="text-secondary">Equifax</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let borrower of borrowers">
                    <td>{{ getPersonFullNames([borrower.borrowerId]) }}</td>
                    <td>{{ borrower.creditScore_Experian || '--' }}</td>
                    <td>{{ borrower.creditScore_TransUnion || '--' }}</td>
                    <td>{{ borrower.creditScore_Equifax || '--' }}</td>
                  </tr>
                  <tr *ngIf="borrowers.length == 0">
                    <td colspan="4" style="height: 50px; text-align: center">
                      <a>No Credit Reports</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-bolt me-2"></i> Usage Info - <span class="text-success">{{
                getUsageTotal() }} kWh</span>
              <button type="button" class="btn btn-outline-primary btn-sm pull-right"
                (click)="onAddAnnualUsageClicked()">
                Add Annual Usage
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Jan</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.janUsage" name="ls_usage_january"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'January Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Feb</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.febUsage" name="ls_usage_february"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'February Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Mar</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.marUsage" name="ls_usage_march"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'March Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Apr</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.aprUsage" name="ls_usage_april"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'April Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">May</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.mayUsage" name="ls_usage_may" [editorMode]="editorMode"
                      [decimals]="0" [placeholder]="'May Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Jun</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.junUsage" name="ls_usage_june"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'June Usage'"></number-input>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Jul</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.julUsage" name="ls_usage_july"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'July Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Aug</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.augUsage" name="ls_usage_august"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'August Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Sep</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.sepUsage" name="ls_usage_september"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'September Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Oct</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.octUsage" name="ls_usage_october"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'October Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Nov</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.novUsage" name="ls_usage_november"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'November Usage'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Dec</div>
                  <div class="col-6">
                    <number-input [(ngModel)]="application.solar.decUsage" name="ls_usage_december"
                      [editorMode]="editorMode" [decimals]="0" [placeholder]="'December Usage'"></number-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" id="projectInfo">
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-solar-panel me-2"></i> Project Info </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Loan Purpose</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.loanPurposeId"
                      [options]="loanPurposeOptions" name="ls_loanPurpose" [editorMode]="editorMode"
                      [placeholder]="'Loan Purpose'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Payment Method</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="application.solar.paymentMethod" [options]="paymentMethods" name="ls_paymentMethod"
                      [editorMode]="editorMode" [placeholder]="'Payment Method'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">System Size</div>
                  <div class="col-6">
                    <number-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.systemSize"
                      name="ls_systemSize" [editorMode]="editorMode" [decimals]="1"
                      [placeholder]="'System Size'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Price Per kWh</div>
                  <div class="col-6">
                    <number-input [shiftInputToLeftWhenEditingInline]="true"
                      [(ngModel)]="application.solar.pricePerWatt" name="ls_pricePerWatt" [editorMode]="editorMode"
                      [decimals]="2" [placeholder]="'Price Per kWh'"></number-input>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Loan Type</div>
                  <div class="col-6">
                    <select-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.loanTypeId"
                      [options]="loanTypeOptions" name="ls_loanType" [editorMode]="editorMode"
                      [placeholder]="'Loan Type'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Gross EPC</div>
                  <div class="col-6">
                    <number-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.grossEPC"
                      name="ls_grossEPC" [editorMode]="editorMode" [decimals]="2"
                      [placeholder]="'Gross EPC'"></number-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Net EPC</div>
                  <div class="col-6">
                    <number-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="application.solar.netEPC"
                      name="ls_netEPC" [editorMode]="editorMode" [decimals]="2"
                      [placeholder]="'Net EPC'"></number-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-key me-2"></i> Proposals
              <button type="button" *ngIf="application" class="btn btn-outline-primary btn-sm pull-right">
                Create New Proposal
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div class="row m-2">
              <div style="text-align: center">
                No Proposals Yet
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" id="customInfo">
      <div class="card-header">
        <h4 class="card-title"><i class="fas fa-key me-2"></i> Custom Info </h4>
      </div>
      <div class="card-body">
        <custom-data-info
          [application]="application"
          [customData]="customData"
          [isInClassicEditMode]="editModeToggle"
          [labelAlignment]="'left'">
        </custom-data-info>
      </div>
    </div>
    <div class="row" id="channel">
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-building me-2"></i> {{enabledChannels.length ? 'Channel /' : ''}} Branch Info
            </h4>
          </div>
          <div class="card-body">
            <div class="row py-1" *ngIf="enabledChannels.length">
              <div class="col-6">Channel</div>
              <div class="col-6">
                <select-input [(ngModel)]="application.channel" [optionValueKey]="'name'" [options]="enabledChannels"
                  name="ls_channel" [editorMode]="editorMode" [placeholder]="'Channel'"
                  (change)="onChannelSelect(application.channel);"></select-input>
              </div>
            </div>
            <div class="row py-1"
              [hidden]="!application.channel || application.channel == 'Retail' || application.channel == 'Broker'">
              <div class="col-6">External Company</div>
              <div class="col-6">
                <select-input [(ngModel)]="application.externalCompanyId" [optionValueKey]="'externalCompanyId'"
                  [optionNameKey]="'name'" [options]="externalCompanies" name="ls_externalCompany"
                  [editorMode]="editorMode" [placeholder]="'External Company'"
                  (change)="onExternalCompanySelect(application.externalCompanyId)"></select-input>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-6">Branch</div>
              <div class="col-6">
                <select-input [(ngModel)]="application.branchId" [optionValueKey]="'branchId'"
                  [optionNameKey]="'branchName'" [options]="externalCompanyBranchesFiltered" name="ls_branch"
                  [editorMode]="editorMode" [placeholder]="'Branch'"></select-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-stretch">
        <div class="card w-100">
          <div class="card-header">
            <h4 class="card-title"><i class="fas fa-user me-2"></i> Referral Info
              <button type="button" class="btn btn-outline-primary btn-sm pull-right"
                (click)="onAddHomeInsuranceDialog()">
                Add Referral
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Referral Source</div>
                  <div class="col-6">
                    <select-input-vscroll [(ngModel)]="application.referralSource" [optionValueKey]="'agentId'"
                      [optionNameKey]="'agentFullName'" [options]="agents" name="ls_referralSource"
                      [editorMode]="editorMode" [placeholder]="'Referral Source'"></select-input-vscroll>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Referred To</div>
                  <div class="col-6">
                    <select-input-vscroll [(ngModel)]="application.referredTo" [optionValueKey]="'agentId'"
                      [optionNameKey]="'agentFullName'" [options]="agents" name="ls_referredTo"
                      [editorMode]="editorMode" [placeholder]="'Referred To'"></select-input-vscroll>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Inside Referral Source</div>
                  <div class="col-6">
                    <select-input [(ngModel)]="application.insideReferralSource" [optionValueKey]="'userCompanyGuid'"
                      [optionNameKey]="'userFullName'" [options]="users" name="ls_insideReferralSource"
                      [editorMode]="editorMode" [placeholder]="'Inside Referral Source'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Secondary Referral Source</div>
                  <div class="col-6">
                    <select-input-vscroll [(ngModel)]="application.secondaryReferralSource" [optionValueKey]="'agentId'"
                    [optionNameKey]="'agentFullName'" [options]="agents" name="ls_secondaryReferralSource"
                    [editorMode]="editorMode" [placeholder]="'Secondary Referral Source'"></select-input-vscroll>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row py-1">
                  <div class="col-6">Lead Source</div>
                  <div class="col-6">
                    <text-input [(ngModel)]="application.leadSource" name="ls_leadSource" [editorMode]="editorMode"
                      [placeholder]="'Lead Source'"></text-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Lead Campaign</div>
                  <div class="col-6">
                    <select-input [(ngModel)]="application.leadCampaignId" [optionValueKey]="'leadCampaignId'"
                      [optionNameKey]="'name'" [options]="leadCampaigns" name="ls_leadCampaign"
                      [editorMode]="editorMode" [placeholder]="'Lead Campaign'"></select-input>
                  </div>
                </div>
                <div class="row py-1">
                  <div class="col-6">Lead Ref Id</div>
                  <div class="col-6">
                    <text-input [(ngModel)]="application.leadRefId" name="ls_leadRefId" [editorMode]="editorMode"
                      [placeholder]="'Lead Ref Id'"></text-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
