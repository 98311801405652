import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Borrower } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { BorrowersService } from '../../../modules/borrower/services/borrowers.service';

@Component({
  selector: 'portal-status-indicator',
  templateUrl: 'portal-status-indicator.component.html',
  styleUrls: ['portal-status-indicator.component.scss']
})

export class PortalStatusIndicatorComponent implements OnInit {

  private _borrower: Borrower;

  status: PortalStatus = new PortalStatus();

  actionLinkVisible: boolean = false;

  @Output()
  inviteBorrowerRequested: EventEmitter<number> = new EventEmitter<number>()

  @Input()
  set borrower(borrower: Borrower) {
    this._borrower = borrower;
    if (!this._borrower) {
      return;
    }
    this.setStatus(this._borrower);
  }

  @Input() applicationId: number;
  @Input() disabledInviteLink: boolean = false;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _borrowersService: BorrowersService
  ) { }

  ngOnInit() { }

  onAction = () => {
    if(this.disabledInviteLink){
      return;
    }

    if (this.status.actionName === "Resend Invite" || this.status.actionName === "Send Invite") {
      this.inviteBorrowerRequested.emit(this._borrower.borrowerId);
      this.sendBorrowerInvite(this._borrower);
    }
  }

  sendBorrowerInvite = (borrower: Borrower) => {
    this._spinner.show();
    this._borrowersService.inviteBorrower(borrower.borrowerId, this.applicationId).subscribe(() => {
      borrower.isInvited = true;
      this.setStatus(borrower);
      this._spinner.hide();
      this._notifsService.showSuccess(
        'Invitation has been sent succesfully.',
        'Success!'
      );
    },
      (err) => {
        this._spinner.hide();
        this._notifsService.showError(
          err?.message || 'Unable to send invite',
          'Error!'
        );
      });
  }

  private setStatus = (borrower: Borrower) => {
    if (borrower?.isRegister == true) {
      this.status.name = "LIVE";
      this.status.type = PortalStatusType.Success;
    } else if (!borrower.email) {
      this.status.name = "Missing Email Address";
      this.status.type = PortalStatusType.Error;
    } else if (borrower.isInvited) {
      this.status.name = "Not Confirmed";
      this.status.actionName = "Resend Invite";
      this.status.type = PortalStatusType.Warning;
    } else {
      this.status.name = "Not Invited";
      this.status.actionName = "Send Invite";
      this.status.type = PortalStatusType.Error;
    }
  }
}

export class PortalStatus {
  name: string = "";
  actionName: string = "";
  type: PortalStatusType
}

export enum PortalStatusType {
  Info = 0,
  Success = 1,
  Warning = 2,
  Error = 3
}
