import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanProductPricing } from '../../models/pricing/loan-product-pricing.model';

@Component({
  selector: 'view-notes-advisories-dialog',
  templateUrl: './view-notes-advisories-dialog.component.html',
  styleUrls: ['./view-notes-advisories-dialog.component.scss']
})
export class ViewNotesAdvisoriesDialogComponent implements OnInit {
  @Input() notesAndAdvisories: LoanProductPricing;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
