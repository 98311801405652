import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FormGroup, NgForm } from '@angular/forms';
import { finalize, Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyKeyValue, ThirdPartyKeyValueArray } from 'src/app/models/third-party/third-party-key-value.model';
import { ThirdPartyCredential } from 'src/app/models/third-party/third-party-creds.model';
import { autoId } from 'src/app/core/services/utils';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

const LICENSE_VALIDATION_TYPE = 'LicenseValidation';
const VENDOR_NAME = 'Custom';
const AUTH_URL_TYPE = 'AuthUrl';
const AUTH_SCOPE_TYPE = 'AuthScope';
const VALIDATE_ENDPOINT_PATH_TYPE = 'ValidateEndpointPath';

@Component({
  selector: 'license-validation-vendor-editor-dialog',
  templateUrl: './license-validation-vendor-editor-dialog.component.html',
  styleUrls: ['./license-validation-vendor-editor-dialog.component.scss'],
})
export class LicenseValidationVendorEditorDialogComponent implements OnDestroy {
  @Output() readonly credentialChange = new EventEmitter<ThirdPartyCredential>();
  @ViewChild('ngForm', { static: true }) ngForm: NgForm;
  protected authUrlItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(AUTH_URL_TYPE);
  protected authScopeItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(AUTH_SCOPE_TYPE);
  protected validateEndpointPathItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(VALIDATE_ENDPOINT_PATH_TYPE);
  protected isSaving: boolean = false;
  protected inEditMode: boolean = false;
  private readonly _id: string = autoId();
  private _saveSubscription?: Subscription;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    public activeModal: NgbActiveModal
  ) {
  }

  private _credential: ThirdPartyCredential = createDefaultCredential();

  @Input() set credential(value: ThirdPartyCredential) {
    this.inEditMode = !!value;
    this._credential = value != null ? cloneDeep(value) : createDefaultCredential();

    const initItem = findOrAddThirdPartyItem(this._credential?.thirdPartyKeyValuePairs ?? []);
    this.authUrlItem = initItem(AUTH_URL_TYPE);
    this.authScopeItem = initItem(AUTH_SCOPE_TYPE);
    this.validateEndpointPathItem = initItem(VALIDATE_ENDPOINT_PATH_TYPE);
  }

  protected get form(): FormGroup {
    return this.ngForm.form;
  }

  protected get formCredential(): ThirdPartyCredential {
    return this._credential;
  }

  private static deleteEmptyThirdPartyItems(thirdPartyKeyValuePairs: any[]) {
    const deleteEmptyItem = deleteEmptyThirdPartyItem(thirdPartyKeyValuePairs);
    deleteEmptyItem(AUTH_URL_TYPE);
    deleteEmptyItem(AUTH_SCOPE_TYPE);
    deleteEmptyItem(VALIDATE_ENDPOINT_PATH_TYPE);
  }

  ngOnDestroy() {
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }

  protected save(): void {
    this._saveSubscription?.unsubscribe();

    const thirdPartyKeyValuePairs = [...(this._credential?.thirdPartyKeyValuePairs ?? [])];
    LicenseValidationVendorEditorDialogComponent.deleteEmptyThirdPartyItems(thirdPartyKeyValuePairs);
    const credential = { ...this._credential, thirdPartyKeyValuePairs };

    this.isSaving = true;
    this.form.disable();

    this._systemLevelService.saveCredential(credential).pipe(
      finalize(() => {
        this.isSaving = false;
        this.form.enable();
      }),
    ).subscribe({
      next: (value) => {
        this.activeModal.close(value);
        this._notificationService.showSuccess(
          'License validation settings saved successfully.',
          'System Level',
        );
      },
      error: (error) => {
        const message = 'An error occurred while saving license validation settings';
        console.error(message, error);

        this._notificationService.showError(message, 'Error');
      },
    });
  }
}

// FIXME: Duplicated from loanpass-vendor-editor-dialog.component.ts
function findOrAddThirdPartyItem(thirdPartyKeyValuePairs: ThirdPartyKeyValueArray) {
  return function (key: ThirdPartyKeyValue['key']) {
    let item = thirdPartyKeyValuePairs.find(x => x.key === key);
    if (item == null) {
      item = new ThirdPartyKeyValue(key);
      thirdPartyKeyValuePairs.push(item);
    }
    return item;
  };
}

function deleteEmptyThirdPartyItem(thirdPartyKeyValuePairs: ThirdPartyKeyValueArray) {
  return function (key: ThirdPartyKeyValue['key']) {
    const index = thirdPartyKeyValuePairs.findIndex(x => x.key === key);
    if (index < 0) {
      return;
    }

    const item = thirdPartyKeyValuePairs[index];
    const value = item?.value;
    if (value == null || value === '') {
      thirdPartyKeyValuePairs.splice(index, 1);
    }
  };
}

function createDefaultCredential(): ThirdPartyCredential {
  return new ThirdPartyCredential(
    LICENSE_VALIDATION_TYPE as any,
    VENDOR_NAME,
  );
}
