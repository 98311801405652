<p-table [value]="borrowers" responsiveLayout="scroll" [resizableColumns]="true">
  <ng-template pTemplate="header">
    <tr>
      <th class="text-start"> Borrower </th>
      <th> Experian </th>
      <th> TransUnion </th>
      <th> Equifax </th>
      <th> Credit File No. </th>
      <th> Report Generated On </th>
      <th> Status </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-borrower>
    <tr *ngIf="borrower.latestCreditReport">
      <td class="text-start">{{borrower.latestCreditReport.borrowerName}}</td>
      <td>{{borrower.latestCreditReport.experian ? borrower.latestCreditReport.experian : '--' }}</td>
      <td>{{borrower.latestCreditReport.transUnion ? borrower.latestCreditReport.transUnion : '--'}}</td>
      <td>{{borrower.latestCreditReport.equifax ? borrower.latestCreditReport.equifax : '--'}}</td>
      <td>{{borrower.latestCreditReport.refNumber ? borrower.latestCreditReport.refNumber : '--'}}
        <a (click)="creditFileNoClicked(borrower.latestCreditReport.refNumber)" *ngIf="borrower.latestCreditReport.refNumber">
          <i class="far fa-clone text-primary ms-1"></i>
        </a>
      </td>
      <td>{{borrower.latestCreditReport.dateInserted | date:'short'}}
        <ng-container *ngIf="borrower.latestCreditReport.dateInserted">
          <a *ngIf="!!borrower.latestCreditReport.integrationHistoryId"
            (click)="reportGeneratedClicked(borrower.latestCreditReport.integrationHistoryId)">
            <i class="far fa-eye text-primary ms-1"></i>
          </a>
          <i *ngIf="!borrower.latestCreditReport.integrationHistoryId" class="fas fa-info-circle text-primary ms-1"
            placement="top" ngbTooltip="Credit report can be viewed in AUS results"></i>
        </ng-container>
      </td>
      <td>
        <span class="text-success" *ngIf="borrower.latestCreditReport.dateInserted && borrower.latestCreditReport.successful" >
          Success
          <i class="fas fa-check"></i>
        </span>

        <span *ngIf="!borrower.latestCreditReport.dateInserted" class="text-warning">Not Run</span>

        <a *ngIf="borrower.latestCreditReport.dateInserted && !borrower.latestCreditReport.successful"
            #popoverCreditError="bs-popover"
            id="creditErrorLink{{borrower.latestCreditReport.creditID}}"
            container="body"
            adaptivePosition="true"
            boundariesElement="window"
            [popover]="creditErrorPopover"
            [outsideClick]="true">
          <i class="fas fa-exclamation-triangle text-danger"></i>
        </a>
        <ng-template #creditErrorPopover>
          <span class="text-danger">{{borrower.latestCreditReport.failureReason}}</span>
        </ng-template>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="7" class="text-center">
        There is no credit score information.
      </td>
    </tr>
  </ng-template>
</p-table>
