<div *ngIf="!isLoading; else loading" class="row">
  <div class="col-md-12">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active': currentTab === 'sms-settings' }"
          (click)="currentTab = 'sms-settings'">
          Sms Settings
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active': currentTab === 'sms-templates' }"
          (click)="currentTab = 'sms-templates'">
          Sms Templates
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active': currentTab === 'sms-scheduling' }"
          (click)="currentTab = 'sms-scheduling'">
          SMS Scheduling
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <div *ngIf="currentTab === 'sms-settings'" class="pt-3">
        <sms-settings [smsAllowedSendTimeRange]="smsAllowedSendTimeRange" [telephonyService]="telephonyService"
          [smsAlertsEnabled]="smsAlertsEnabled" [type]="type" (onConfigSave)="getSystemLevel()"
          [enableSmsAlertsByDefault]="enableSmsAlertsByDefault"></sms-settings>
      </div>
      <div *ngIf="currentTab === 'sms-templates'" class="pt-3">
        <sms-templates [definedSmsTemplate]="definedSmsTemplate" [smsAlertsEnabled]="smsAlertsEnabled"
          [reminderSmsEnabled]="reminderSmsEnabled" [reminderSmsInterval]="reminderSmsInterval" [type]="type">
        </sms-templates>
      </div>
      <div *ngIf="currentTab === 'sms-scheduling'" class="pt-3">
        <correspondence-weekly-schedule [delivery]="'SMS'"></correspondence-weekly-schedule>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <loading-indicator loadingMessage="Loading SMS Settings..." [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>