import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SharedUtilitiesService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _domSanitizer: DomSanitizer
  ) { }

  getUniqueId = () => {
    const uniqueId = Date.now() & 0xffffffff
    return uniqueId < 0 ? uniqueId : (uniqueId * -1);
  };

  /*
   * Compare two objects by reducing an array of keys in obj1, having the
   * keys in obj2 as the intial value of the result. Key points:
   *
   * - All keys of obj2 are initially in the result.
   *
   * - If the loop finds a key (from obj1, remember) not in obj2, it adds
   *   it to the result.
   *
   * - If the loop finds a key that are both in obj1 and obj2, it compares
   *   the value. If it's the same value, the key is removed from the result.
   */
  getObjectDiff(obj1: any, obj2: any) {
    obj1 = this.omitFalsyValues(obj1);
    obj2 = this.omitFalsyValues(obj2);
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isMatchWith(obj1[key], obj2[key], this.customizer)) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    return diff;
  }

  // return isDirty
  logDiffs(namespace, orig, dirty) {
    if (orig == undefined || dirty == undefined || orig == null || dirty == null)
      return false;
    const diffs = this.getObjectDiff(orig, dirty);
    if (diffs.length == 0 || typeof orig !== 'object' || typeof dirty !== 'object')
      return false;
    diffs.forEach(diff => {
      if (!this.logDiffs(namespace + '.' + diff, orig[diff], dirty[diff])) {
        console.log(namespace + '.' + diff, orig[diff], dirty[diff]);
      }
    });
    return true;
  }

  getImageFileContentAsBase64 = (sourcePath: string, doneCallback: Function) => {
    return this._httpClient.get(sourcePath, { responseType: "blob" })
      .subscribe(response => {
        const file = new Blob([response], { type: response.type });
        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => {
          let base64 = event.target.result as string
          let resourceURL = this._domSanitizer.bypassSecurityTrustResourceUrl(base64);
          doneCallback(resourceURL);
        };
      })
  }

  private customizer = function (objValue: any, srcValue: any): boolean {
    if (typeof objValue === 'number' || typeof srcValue === 'number') {
      if ((objValue == null && srcValue != null) || (srcValue == null && objValue != null)) {
        return false;
      }
      return objValue.toString() === srcValue.toString();
    } else {
      return _.isEqual(objValue, srcValue);
    }
  }

  private omitFalsyValues = function (input: object): object {
    const excludes = ['', null, undefined];
    return Object.entries(input).reduce((nextInput, [key, value]) => {
      if (excludes.includes(value)) {
        return nextInput
      }

      if (_.isArray(value)) {
        const arrValue = value
        const nextValue = arrValue.map((arrItem) => {
          if (typeof arrItem === 'object') {
            return this.omitFalsyValues(arrItem);
          }
          return arrItem
        })
        nextInput[key] = nextValue;
        return nextInput;
      } else if (typeof value === 'object') {
        nextInput[key] = this.omitFalsyValues(value);
        return nextInput;
      }

      nextInput[key] = value;
      return nextInput;
    }, {})
  }
}
