export class LoanPassEnumMapping {
  loanPassEnumMappingId: number;
  sourceValue: string;
  destinationVariantId: string;
  destinationVariantName: string;
  useAsDefault: boolean;
  customExpression: string;
  customExpressionSql: string;
  companyId: number;
  modelGuid: string;
  insertedByUserId: string;
  dateInserted: string;
  updatedByUserId: string;
  dateUpdated: string
}
