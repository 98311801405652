import { Component, Input, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationService } from '../../../../services/enumeration-service';
import { NgForm } from '@angular/forms';
import { ThirdPartyFeeOptions } from 'src/app/models/fee/third-party-fees-options.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import {FeeService} from "../../../../services/fee.service";
import {NotificationService} from "../../../../services/notification.service";
import {TitleAgent} from "../../../../models/fee/title-agent.model";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'fees-vendor-questionnaire',
  templateUrl: 'fees-vendor-questionnaire.component.html',
  styleUrls: ['./fees-vendor-questionnaire.component.scss']
})
export class FeesVendorQuestionnaireComponent implements OnInit {

  @ViewChild('ernstForm') ernstForm: NgForm | undefined;
  @ViewChild('ernstPage2Form') ernstPage2Form: NgForm | undefined;

  @Input()
  thirdPartyFeeOptions: ThirdPartyFeeOptions;

  @Input()
  applicationId: string;

  @Input()
  selectedVendorName: string;

  @Output()
  questionnaireCompleted: EventEmitter<ThirdPartyFeeOptions> = new EventEmitter<ThirdPartyFeeOptions>();

  yesNoOptions: EnumerationItem[] = [];
  pageNo: number;
  titleAgents: TitleAgent[];
  titleAgent: TitleAgent;
  steps: MenuItem[];

  isRequestingFees: boolean = false;
  loadingTitleAgents: boolean = false;
  activeStepIndex: number = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumerationService,
    private readonly _feesService: FeeService,
    private readonly _notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.steps = [
      { label: 'Select Title Agent' },
      { label: 'Recording Questions' },
      { label: 'Inspection Questions' }
    ];
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this.pageNo = 1;

    this.thirdPartyFeeOptions.options.recordingFeesRequestOptions.questions.forEach(question => {
      // if (question.answer == null) {
      //   question.answer = question.defaultAnswer;
      // }
    });
    if (this.selectedVendorName === 'Lodestar') {
      this.loadTitleAgents();
    } else {
      this.activeStepIndex = 1;
    }
  }

  nextPage() {
    this.ernstForm.form.markAllAsTouched();
    if (this.ernstForm.form.valid) {
      this.activeStepIndex++;
    }
  }

  previousPage() {
    this.activeStepIndex--;
  }

  requestFees() {
    this.ernstForm.form.markAllAsTouched();
    if (this.ernstForm.form.valid) {
      this.questionnaireCompleted.emit(this.thirdPartyFeeOptions);
    }
  }

  loadTitleAgents() {
    this.loadingTitleAgents = true;
    this._feesService.getTitleAgents({
      feeProvider: this.selectedVendorName,
      serviceType: 'Fees',
      getContactInfo: true,
      applicationId: this.applicationId
    }).subscribe({
      next: (result) => {
        this.titleAgents = result;
        this.loadingTitleAgents = false;
      },
      error: (err) => {
        this._notificationService.showError(err.message || err, "Error");
        this.loadingTitleAgents = false;
      }
    });
  }

  onTitleAgentChanged() {
    this.titleAgent = this.titleAgents.find(
      el => el.thirdPartyServiceProviderId == this.thirdPartyFeeOptions.options.titleFeesRequestOptions.titleAgentId
    );
    if (this.titleAgent) {
      this.thirdPartyFeeOptions.options.titleFeesRequestOptions.titleAgentOfficeId = this.titleAgent.thirdPartyServiceProviderOfficeId;
    }
  }
}
