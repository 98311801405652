import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AdverseReason } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { AdverseReasonService } from '../../../services';

@Component({
  selector: 'upsert-adverse-reason',
  templateUrl: 'upsert-adverse-reason-dialog.component.html',
})
export class UpsertAdverseReasonDialog implements OnInit {
  @Input() adverseReason: AdverseReason;
  @Input() companyId: number;

  @Output() close: EventEmitter<AdverseReason> = new EventEmitter<AdverseReason>();

  @ViewChild('upsertAdverseReasonForm') upsertAdverseReasonForm: NgForm | undefined;

  saving: boolean;
  editMode: boolean;

  constructor(
    private readonly _adverseReasonService: AdverseReasonService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.editMode = this.adverseReason.adverseReasonId ? true : false;
  }

  private update() {
    this._adverseReasonService.updateAdverseReason(this.companyId, this.adverseReason)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: () => {
        this._notificationService.showSuccess(
          'Adverse reason updated successfully',
          'Adverse Reason'
        );
        this.close.emit(this.adverseReason);
      }, 
      error: (err) => {
        this._notificationService.showError(
          err?.message || "Couldn't update selected adverse reason",
          'Adverse Reason'
        );
      }
    });
  }

  onClose() {
    this.close.emit();
  }

  private insert() {
    this._adverseReasonService.insertAdverseReason(this.companyId, this.adverseReason)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Adverse reason added successfully',
            'Adverse Reason'
          );
          this.close.emit(res);
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't insert new adverse reason",
            'Adverse Reason'
          );
        }
      });
  }

  save() {
    if (this.upsertAdverseReasonForm) {
      this.upsertAdverseReasonForm.form.markAllAsTouched();
      if (!this.upsertAdverseReasonForm.form.valid) return;
    }

    this.saving = true;
    this.editMode
      ? this.update()
      : this.insert();
  }
}
