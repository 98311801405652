<div class="account-body accountbg">
    <!-- Recover-pw page -->
    <div class="container">
        <div class="row vh-100 d-flex justify-content-center">
            <div class="col-10 align-self-center">
                <div class="row">
                    <div class="col-lg-5 mx-auto">
                        <div class="card">
                            <div class="card-body p-0">
                                <div class="text-center p-3">
                                    <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
                                    <h4 class="mt-3" style="color: steelblue;">Forgot Password?</h4>
                                    <p class="text-muted  mb-0">Please enter the email address used for your account
                                        below.</p>
                                    <p class="text-muted  mb-0">Check your email for a link to reset your password.</p>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="alert alert-danger border-0" role="alert" *ngIf="error">
                                    <strong>{{error.header}}</strong> {{error.message}}
                                </div>
                                <div class="alert alert-success border-0" role="alert" *ngIf="resetDone">
                                    <strong>Success!</strong>
                                    Please check your email to reset your password. Note that it may take up to 20 minutes for the email to be sent.
                                </div>
                                <form *ngIf="!resetDone" class="form-horizontal auth-form" #forgotPasswordForm="ngForm" novalidate id="forgotPasswordForm" name="forgotPasswordForm" action="index.html">
                                    <div class="form-group mb-2">
                                        <label class="form-label" for="username">Email</label>
                                        <div class="input-group">
                                            <input type="email" class="form-control" id="email" name="email" trim
                                                [ngClass]="{'is-invalid' : userEmail && userEmail.touched && userEmail.invalid}"
                                                required [(ngModel)]="formData.email" #userEmail="ngModel" placeholder="Email Address" (change)="onEmailChangedDebounce()">
                                            <div class="invalid-feedback">Please enter your email address.</div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2" *ngIf="formData.email">
                                        <label class="form-label" for="userCompanyGuid">Company</label>
                                        <select class="form-select" name="userCompanyGuid" id="userCompanyGuid" [(ngModel)]="formData.userCompanyGuid">
                                            <option [ngValue]="null">--Select One--</option>
                                            <ng-container *ngFor="let companies of availableCompanies | groupBy: 'companyGuid'">
                                                <optgroup label="{{companies.value[0].companyName}}">
                                                    <option *ngFor="let company of companies.value; let i = index" value="{{company.userCompanyGuid}}">
                                                        {{company.userType}}
                                                    </option>
                                                </optgroup>
                                            </ng-container>
                                        </select>
                                    </div>
                                    <!--end form-group-->

                                    <div class="form-group mb-0 row">
                                        <div class="col-12 mt-2">
                                            <button class="btn btn-primary w-100 waves-effect waves-light"
                                                type="button" (click)="onSendMailClicked()" [disabled]="!formData.email || !formData.userCompanyGuid">Send Email</button>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end form-group-->
                                </form>
                                <!--end form-->
                                <p class="text-muted text-center mb-0 mt-3">Already have an account?<a [routerLink]="['/login']"
                                        class="text-primary ms-2">Log in</a></p>
                            </div>
                            <div class="card-body bg-light-alt text-center login-footer">
                                <span class="text-muted d-none d-sm-inline-block">Lodasoft © {{currentYear}}
                                    <script>
                                        document.write(new Date().getFullYear())
                                    </script>
                                </span>
                            </div>
                        </div>
                        <!--end card-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
    <!-- End Recover-pw page -->
</div>
