import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EditorMode } from '../currency-input/currency-input.component';

@Component({
  selector: 'text-input',
  templateUrl: 'text-input.component.html',
  providers: [MakeProvider(TextInputComponent)],
  viewProviders: [formViewProvider]
})
export class TextInputComponent extends AbstractValueAccessor implements OnInit {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('control')
  input: ElementRef<HTMLInputElement>;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  name: string;

  @Input()
  required: boolean;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  inlineTextClass: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Input()
  placeholder: string;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  id: string;

  isEditActive: boolean = false;

  private _originalValue: any;

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());
  }

  override writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this._originalValue = value; // for initializing
  }

  onBlur = ($event: any) => {
    this.blur.emit($event);
    if (this.isEditActive) {
      this.apply();
    }
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
    this.isEditActive = true;
  }

  onCancelClicked = (event: any) => {
    event.preventDefault();
    this.isEditActive = false;
    this.value = this._originalValue;
  }

  onApplyClicked = (event: any) => {
    this.model.control.markAsTouched();
    event.preventDefault();
    this.apply();
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;
    }
  }
}
