import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { AgentInfoModule } from "../agent-info/agent-info.module";
import { UpsertReferralSourceComponent } from "./components/upsert-referral-source/upsert-referral-source.component";

@NgModule({
  declarations: [
    UpsertReferralSourceComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgentInfoModule
  ],
  exports: [
    UpsertReferralSourceComponent,
  ]
})
export class ReferralSourceModule {}