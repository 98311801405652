import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { ManufacturedHomeWidthTypeEnum, MiQuoteSearchRequest, NumberOfUnitsEnum, OccupancyEnum } from '../models/mi-quote-search-request.model';
import { MiQuoteSearchResult } from '../models/mi-quote-search-result.model';
import { PropertyAttachmentType, PropertyConstructionMethod } from '../modules/leads/models/lead.model';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../modules/app-details/components/title-history/models/title-order.model';
import { RefinancePrimaryPurposeTypeEnum, RefinancePurposeTypeEnum } from '../modules/disclosure-tracking/models/disclosure-tracking.model';
import { AmortizationType } from '../modules/reports/mortgage-call/models/mcr-audit-data.model';
import { ChannelEnum } from '../models/channels.model';

@Injectable({
  providedIn: 'root'
})
export class MiQuoteService {

  constructor(private readonly _dataService: DataService) { }

  getRequest = (mortgageId: number): Observable<MiQuoteSearchRequest> => {
    const url = `api/mi/request/${mortgageId}`;
    return this._dataService.get(url);
  }

  search = (request: MiQuoteSearchRequest): Observable<MiQuoteSearchResult> => {
    const url = "api/mi/search";
    return this._dataService.post(url, request);
  }

  searchByVendor = (request: MiQuoteSearchRequest, vendor: string = "PmiRatePro"): Observable<MiQuoteSearchResult> => {
    const url = `api/mi/vendor/${vendor}/search`;
    return this._dataService.post(url, request);
  }

  getDefaultQuoteSearchRequest = (): MiQuoteSearchRequest => {
    let request = new MiQuoteSearchRequest();

    request.borrowerInformation.borrowerDetails = [{
      firstName: "John",
      middleName: "",
      lastName: "Doe",
      creditScore: 720,
      isSelfEmployed: false,
      bankruptcy: false,
      forclosure: false,
      isFirstTimeHomeBuyer: false,
      socialSecurityNumber: "123456789"
    }];

    request.borrowerInformation.debtToIncomeRatio = 0.2;
    request.borrowerInformation.housingExpenseRatioPercent = 0.1;

    request.loanInformation.baseLoanAmount = 420000.00;
    request.loanInformation.loanTerm = 60;
    request.loanInformation.amortizationTerm = 360;
    request.loanInformation.isCorporateRelocation = false;
    request.loanInformation.isRehabilitation = false;
    request.loanInformation.interestRate = 0.05;
    request.loanInformation.buydown = null;
    request.loanInformation.armFixedTermMonths = 60;
    request.loanInformation.armSubsequentChangePeriodMonths = 12;
    request.loanInformation.amortizationType = AmortizationType.FixedRate;
    request.loanInformation.loanPurpose = LoanPurposeTypeEnum.Purchase;
    request.loanInformation.refinancePurpose = RefinancePurposeTypeEnum.NoCashOutOther;
    request.loanInformation.refinancePrimaryPurpose = RefinancePrimaryPurposeTypeEnum.Other;
    request.loanInformation.loanNumber = "QUICKPRICE";
    request.loanInformation.specialLoanProgram = null;
    request.loanInformation.loanProgramOtherDescription = null;
    request.loanInformation.duAusRecommendation = null;
    request.loanInformation.lpaAusRecommendation = null;

    request.propertyInformation.appraisedValue = 500000.00;
    request.propertyInformation.salesPrice = 500000.00;
    request.propertyInformation.isPlannedUnitDevelopment = false;
    request.propertyInformation.city = "OVERLAND PARK";
    request.propertyInformation.isConstruction = false;
    request.propertyInformation.county = "JOHNSON";
    request.propertyInformation.countyFips = "05071";
    request.propertyInformation.numberOfStories = 1;
    request.propertyInformation.occupancy = OccupancyEnum.PrimaryResidence;
    request.propertyInformation.projectType = null;
    request.propertyInformation.projectDesignType = null;
    request.propertyInformation.address = "12345 College Blvd";
    request.propertyInformation.state = "ks";
    request.propertyInformation.zipCode = "66210";
    request.propertyInformation.constructionMethod = PropertyConstructionMethod.SiteBuilt;
    request.propertyInformation.attachmentType = PropertyAttachmentType.Detached;
    request.propertyInformation.numberOfUnits = NumberOfUnitsEnum.OneUnit;
    request.propertyInformation.manufacturedHomeWidthType = ManufacturedHomeWidthTypeEnum.SingleWide;

    request.requestorInformation.channel = ChannelEnum.Retail;
    request.requestorInformation.nmlsId = "123345"
    request.requestorInformation.email = "test@gmail.com";

    request.searchCriteria.coveragePercentage = 0.35;

    return request;
  }
}
