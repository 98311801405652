import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuditMessage } from 'src/app/models';

@Component({
  selector: 'disclosure-audit-results',
  templateUrl: 'disclosure-audit-results.component.html',
  styleUrls: ['disclosure-audit-results.component.scss']
})
export class DisclosureAuditResultsComponent implements OnInit {
  @Input()
  set messages(messages: Array<AuditMessage>) {
    this._messages = messages;
    this.filteredMessages = [...this._messages];
    this.categories = [...new Set(messages.filter(a => !!(a.statusConditionDescription.trim())).map(a => a.statusConditionDescription.trim()))];
  }

  get messages(): Array<AuditMessage> {
    return this._messages;
  }

  @Input() selectedCategories: string[] = [];

  @Output()
  refreshAudits = new EventEmitter<any>();

  filteredMessages: Array<AuditMessage> = [];

  categories: Array<string> = [];

  private _messages: Array<AuditMessage>;

  constructor() { }

  ngOnInit(): void {
    this.filterByStatus(this.selectedCategories);
  }

  filterByStatus = (selectedCategoryItems: string[]) => {
    if(!selectedCategoryItems.length || selectedCategoryItems.length == this.categories.length){
      this.filteredMessages = [...this.messages];
    }
    else {
      this.filteredMessages = this.messages.filter(m => selectedCategoryItems.includes(m.statusConditionDescription));
    }
  }

  refreshClicked = () => {
    this.refreshAudits.emit(this.selectedCategories);
  }
}
