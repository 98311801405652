import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Adjustment, AdjustmentValueType } from '../../models/pricing/pricing-quote.model';

@Component({
  selector: 'view-adjustments-dialog',
  templateUrl: './view-adjustments-dialog.component.html',
  styleUrls: ['./view-adjustments-dialog.component.scss']
})
export class ViewAdjustmentsDialogComponent implements OnInit {

  @Input() adjustments: Adjustment[];

  rateAdjustments: Adjustment[] = [];
  pointAdjustments: Adjustment[] = [];

  rateTotal: number = 0;
  pointTotal: number = 0;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.rateAdjustments = this.adjustments.filter(a => !a.isHidden && a.valueType == AdjustmentValueType.Rate);
    this.pointAdjustments = this.adjustments.filter(a => !a.isHidden && a.valueType == AdjustmentValueType.Point);

    this.rateTotal = this.rateAdjustments.reduce((acc, current) => acc + current.amount, 0);
    this.pointTotal = this.pointAdjustments.reduce((acc, current) => acc + current.amount, 0);
  }
}
