export class Configuration {
  id: number;
  key: string
  value?: number
  valueStr?: string;
  userId?: string;
  companyId?: number;
  branchId?: number;
  insertedBy?: string;
  dateInserted?: Date;
  updatedBy?: string;
  dateUpdated?: Date;

  constructor(key?: string, value?: number, valueStr?: string) {
    this.value = value;
    this.key = key
  }
}
