import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LiabilitiesInDetailComponent } from '../liabilities-in-detail/liabilities-in-detail.component';

@Component({
  selector: 'loan-liabilities',
  templateUrl: 'loan-liabilities.component.html',
  styleUrls: ['./loan-liabilities.component.scss']
})

export class LoanLiabilitiesComponent implements OnInit {

  @ViewChild("mortgageDetails") mortgageDetails: LiabilitiesInDetailComponent;
  @ViewChild("installmentDetails") installmentDetails: LiabilitiesInDetailComponent;
  @ViewChild("revolvingDetails") revolvingDetails: LiabilitiesInDetailComponent;
  @ViewChild("collectionDetails") collectionDetails: LiabilitiesInDetailComponent;
  @ViewChild("otherLiabilities") otherLiabilityDetails: LiabilitiesInDetailComponent;

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    this.calculateExpenses();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Output()
  liabilitiesChanged: EventEmitter<any> = new EventEmitter<any>();

  incomes: any[] = [];
  proposedHousingExpenses = new HouseholdExpenses("Proposed Housing Expenses", 0, 0);
  mortgageLoansExpenses = new HouseholdExpenses("Mortgage(s)", 0, 0);
  installmentLoansExpenses = new HouseholdExpenses("Installment Loans", 0, 0);
  revolvingLoansExpenses = new HouseholdExpenses("Revolving Debt(s)", 0, 0);
  collectionExpenses = new HouseholdExpenses("Charge offs/Collections", null, 0);
  otherExpenses = new HouseholdExpenses("Other", 0, 0);
  totalExpenses = new HouseholdExpenses("Total Expenses", 0, 0);
  a_thru_f: number = 0;
  c_thru_f: number = 0;

  incomeTotal: number = 0;
  totalNetHouseholdIncome: number = 0;
  totalMonthlyExpenses: number = 0;
  nonMortgageExpenses: number = 0;
  residualIncome: number = 0;
  incomeTaxRate: number = 0.3;
  ratioOfResidualIncomeToTotalNetIncome: number;
  ratioOfTotalMonthlyExpensesToTotalNetIncome: number;
  ratioOfNonMortgageExpensesToTotalNetIncome: number;

  showMortgagesExpenses: boolean = false;
  showInstallmentLoansExpenses: boolean = false;
  showRevolvingExpenses: boolean = false;
  showCollectionExpenses: boolean = false;
  showOtherExpenses: boolean = false;

  mortgageLiabilityTypes: EnumerationItem[] = [];
  installmentLiabilityTypes: EnumerationItem[] = [];
  revolvingLiabilityTypes: EnumerationItem[] = [];
  collectionLiabilityTypes: EnumerationItem[] = [];
  otherLiabilityTypes: EnumerationItem[] = [];

  constructor(private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _enumsService: EnumerationService) { }

  ngOnInit() {
    this.initialCalculateMortgageExpenses();

    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      const liabilityTypes = enums[Constants.enumerations.liabilityTypes];

      this.otherLiabilityTypes = liabilityTypes.filter(liability =>
        liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan) &&
        liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Revolving) &&
        liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Installment) &&
        liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC) &&
        liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CollectionsJudgementsAndLiens)
      );

      this.mortgageLiabilityTypes = liabilityTypes.filter(liability =>
        liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan) ||
        liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC)
      );

      this.installmentLiabilityTypes = liabilityTypes.filter(liability =>
        liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Installment)
      );

      this.revolvingLiabilityTypes = liabilityTypes.filter(liability =>
        liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Revolving)
      );
      this.collectionLiabilityTypes = liabilityTypes.filter(liability =>
        liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CollectionsJudgementsAndLiens)
      );
    });
  }

  calculateIncomes = () => {
    this.totalNetHouseholdIncome = this.incomeTotal * (1 - this.incomeTaxRate);
    this.residualIncome = this.totalNetHouseholdIncome - this.a_thru_f;
    this.ratioOfTotalMonthlyExpensesToTotalNetIncome = parseFloat((this.a_thru_f / this.totalNetHouseholdIncome).toFixed(2));
    this.ratioOfNonMortgageExpensesToTotalNetIncome = parseFloat((this.nonMortgageExpenses / this.totalNetHouseholdIncome).toFixed(2));
    this.ratioOfResidualIncomeToTotalNetIncome = parseFloat((this.residualIncome / this.totalNetHouseholdIncome).toFixed(2));
  }

  onAddLiabilityClicked = (description: string) => {
    if (description == 'Mortgage(s)') {
      const type = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);
      this.showMortgagesExpenses = true;
      setTimeout(() => {
        this.mortgageDetails.addLiability(type);
      });
    } else if (description == 'Installment Loans') {
      const type = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Installment);
      this.showInstallmentLoansExpenses = true;
      setTimeout(() => {
        this.installmentDetails.addLiability(type);
      });
    } else if (description == 'Revolving Debt(s)') {
      const type = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Revolving);
      this.showRevolvingExpenses = true;
      setTimeout(() => {
        this.revolvingDetails.addLiability(type);
      });
    } else if (description == 'Charge offs/Collections') {
      const type = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CollectionsJudgementsAndLiens);
      this.showCollectionExpenses = true;
      setTimeout(() => {
        this.collectionDetails.addLiability(type);
      });
    } else {
      const type = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense);
      this.showOtherExpenses = true;
      setTimeout(() => {
        this.otherLiabilityDetails.addLiability(type);
      });
    }
  }

  refresh = () => {
    this.liabilitiesChanged.emit();
    this.calculateExpenses();
  }

  private initialCalculateMortgageExpenses = () => {
    if (this._mortgage.liabilities) {
      this._mortgage.liabilities.forEach(liability => {
        if (liability.payoffType != 'Full') {
          this.totalMonthlyExpenses += liability.monthlyPayment;
          if (liability.typeOfLiability !== 'Mortgage(s)') {
            this.nonMortgageExpenses += liability.monthlyPayment;
          }
        }
      })
      this.residualIncome = this.totalNetHouseholdIncome - this.totalMonthlyExpenses;
    }
  }

  private calculateExpenses = () => {
    this.resetCalculation();

    this.incomes = [];

    this.mortgage.borrowers.forEach(borr => {
      let borrIncome: number = this._mortgageCalculationsService.calculateBorrowerMonthlyIncome(borr);
      this.incomeTotal += borrIncome;
      this.incomes.push({
        description: `${borr.firstName} ${borr.lastName}`,
        amount: borrIncome
      });
    });
    this.incomes.push(
      {
        description: 'Total Monthly Income',
        amount: this.incomeTotal
      });
    let pitiInfo = this._mortgageCalculationsService.getPiTiPaymentInfo(this.mortgage);
    this.proposedHousingExpenses.monthlyPayment = pitiInfo.pi + pitiInfo.ti + pitiInfo.other;

    let totalExpensesMOPayment = 0;
    let totalExpensesUnpaidBalance = 0;

    this._mortgage.liabilities.forEach(liability => {

      totalExpensesMOPayment += liability.monthlyPayment || 0;
      totalExpensesUnpaidBalance += liability.unpaidBalance || 0;

      if (liability.payoffType != 'Full') {
        if (liability.typeOfLiability == "MortgageLoan" || liability.typeOfLiability == 'HELOC') {
          this.mortgageLoansExpenses.monthlyPayment += liability.monthlyPayment || 0;
          this.mortgageLoansExpenses.unpaidBalance += liability.unpaidBalance || 0;
          this.mortgageLoansExpenses.payoffBalance += liability.partialPayoffAmount || 0;
        }
        else if (liability.typeOfLiability == "Installment") {
          this.installmentLoansExpenses.monthlyPayment += liability.monthlyPayment || 0;
          this.installmentLoansExpenses.unpaidBalance += liability.unpaidBalance || 0;
          this.installmentLoansExpenses.payoffBalance += liability.partialPayoffAmount || 0;
        }
        else if (liability.typeOfLiability == "Revolving") {
          this.revolvingLoansExpenses.monthlyPayment += liability.monthlyPayment || 0;
          this.revolvingLoansExpenses.unpaidBalance += liability.unpaidBalance || 0;
          this.revolvingLoansExpenses.payoffBalance += liability.partialPayoffAmount || 0;
        }
        else if (liability.typeOfLiability == "CollectionsJudgementsAndLiens") {
          this.collectionExpenses.unpaidBalance += liability.unpaidBalance || 0;
          this.collectionExpenses.payoffBalance += liability.partialPayoffAmount || 0;
        }
        else {
          this.otherExpenses.monthlyPayment += liability.monthlyPayment || 0;
          this.otherExpenses.unpaidBalance += liability.unpaidBalance || 0;
          this.otherExpenses.payoffBalance += liability.partialPayoffAmount || 0;
        }
      }
      else {
        if (liability.typeOfLiability == "MortgageLoan" || liability.typeOfLiability == 'HELOC') {
          if (liability.payoffType == 'Full')
            this.mortgageLoansExpenses.payoffPayment += liability.monthlyPayment || 0;
          this.mortgageLoansExpenses.payoffBalance += liability.unpaidBalance || 0;
        }
        else if (liability.typeOfLiability == "Installment") {
          if (liability.payoffType == 'Full')
            this.installmentLoansExpenses.payoffPayment += liability.monthlyPayment || 0;
          this.installmentLoansExpenses.payoffBalance += liability.unpaidBalance || 0;
        }
        else if (liability.typeOfLiability == "Revolving") {
          if (liability.payoffType == 'Full')
            this.revolvingLoansExpenses.payoffPayment += liability.monthlyPayment || 0;
          this.revolvingLoansExpenses.payoffBalance += liability.unpaidBalance || 0;
        }
        else if (liability.typeOfLiability == "CollectionsJudgementsAndLiens") {
          this.collectionExpenses.payoffBalance += liability.unpaidBalance || 0;
        }
        else {
          if (liability.payoffType == 'Full')
            this.otherExpenses.payoffPayment += liability.monthlyPayment || 0;
          this.otherExpenses.payoffBalance += liability.unpaidBalance || 0;
        }
      }
    })
    this.a_thru_f = this.totalMonthlyExpenses + this.mortgageLoansExpenses.monthlyPayment + this.proposedHousingExpenses.monthlyPayment;
    this.c_thru_f = this.totalMonthlyExpenses;

    this.totalExpenses.monthlyPayment = this.otherExpenses.monthlyPayment + this.mortgageLoansExpenses.monthlyPayment + this.installmentLoansExpenses.monthlyPayment + this.revolvingLoansExpenses.monthlyPayment + this.proposedHousingExpenses.monthlyPayment;
    this.totalExpenses.unpaidBalance = this.otherExpenses.unpaidBalance + this.mortgageLoansExpenses.unpaidBalance + this.installmentLoansExpenses.unpaidBalance + this.revolvingLoansExpenses.unpaidBalance + this.collectionExpenses.unpaidBalance;
    this.totalExpenses.payoffPayment = this.otherExpenses.payoffPayment + this.mortgageLoansExpenses.payoffPayment + this.installmentLoansExpenses.payoffPayment + this.revolvingLoansExpenses.payoffPayment;
    this.totalExpenses.payoffBalance = this.otherExpenses.payoffBalance + this.mortgageLoansExpenses.payoffBalance + this.installmentLoansExpenses.payoffBalance + this.revolvingLoansExpenses.payoffBalance + this.collectionExpenses.payoffBalance;
    this.calculateIncomes();
  }

  private resetCalculation = () => {
    this.proposedHousingExpenses.monthlyPayment = 0;
    this.mortgageLoansExpenses.monthlyPayment = 0;
    this.installmentLoansExpenses.monthlyPayment = 0;
    this.revolvingLoansExpenses.monthlyPayment = 0;
    this.otherExpenses.monthlyPayment = 0;
    this.totalExpenses.monthlyPayment = 0;
    this.mortgageLoansExpenses.unpaidBalance = 0;
    this.installmentLoansExpenses.unpaidBalance = 0;
    this.revolvingLoansExpenses.unpaidBalance = 0;
    this.collectionExpenses.unpaidBalance = 0;
    this.otherExpenses.unpaidBalance = 0;
    this.totalExpenses.unpaidBalance = 0;

    this.mortgageLoansExpenses.payoffPayment = 0;
    this.installmentLoansExpenses.payoffPayment = 0;
    this.revolvingLoansExpenses.payoffPayment = 0;
    this.collectionExpenses.payoffPayment = 0;
    this.otherExpenses.payoffPayment = 0;
    this.totalExpenses.payoffPayment = 0;
    this.mortgageLoansExpenses.payoffBalance = 0;
    this.installmentLoansExpenses.payoffBalance = 0;
    this.revolvingLoansExpenses.payoffBalance = 0;
    this.collectionExpenses.payoffBalance = 0;
    this.otherExpenses.payoffBalance = 0;
    this.totalExpenses.payoffBalance = 0;
    this.incomeTotal = 0;
  }
}

export class HouseholdExpenses {
  description: string;
  monthlyPayment: number;
  unpaidBalance: number;
  payoffPayment: number;
  payoffBalance: number;

  constructor(description: string, monthlyPayment: number, unpaidBalance: number) {
    this.description = description;
    this.monthlyPayment = monthlyPayment;
    this.unpaidBalance = unpaidBalance;
    this.payoffPayment = 0;
    this.payoffBalance = 0;
  }
}
