import { Component, Input } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-digits-input',
  templateUrl: 'urla-digits-input.component.html',
  providers: [MakeProvider(UrlaDigitsInputComponent)],
})
export class UrlaDigitsInputComponent extends BaseUrlaInputComponent {

  @Input()
  maxLength: number | null = null;

  @Input()
  max: number | null = null;

  @Input()
  min: number | null = null;

  @Input()
  allowedNegative: boolean = true;

  constructor() {
    super();
  }

  onBlurred = (e: any) => {
    this.blur.emit(e);
  }
}
