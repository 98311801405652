import { Borrower } from "src/app/models/loan/borrower.model";

export class BorrowerFull {
  portalUserName: string;
  borrower: Borrower;
  isEdit: boolean;
  isRegister: boolean;
  isInvited: boolean;
  accountStatus: BorrowerAccountStatus;

  constructor() {
    this.borrower = new Borrower();
  }
}

export enum BorrowerAccountStatus {
  NotInvited = "NotInvited",
  Invited = "Invited",
  Live = "Live"
}
