import { Income } from '../../models';
import { enumLikeValueToDisplayName } from './utils';

/**
 * Compares two incomes by their type of income alphabetically.
 * @param {Income} a The first income to compare.
 * @param {Income} b The second income to compare.
 * @returns {number} A negative number if a comes before b, a positive number
 * if b comes before a or 0 if they are equal.
 */
export function compareNonEmploymentIncomes(
  a: Omit<Income, 'employmentId'>,
  b: Omit<Income, 'employmentId'>,
): number {
  const aType = a.typeOfIncome;
  const bType = b.typeOfIncome;

  if (aType === bType) {
    return 0;
  }

  if (aType == null) {
    return -1;
  }

  if (bType == null) {
    return 1;
  }

  const aDisplayName = toDisplayName(aType);
  const bDisplayName = toDisplayName(bType);

  return aDisplayName.localeCompare(bDisplayName, 'en-US');
}

function toDisplayName(value: any): string {
  return enumLikeValueToDisplayName(String(value));
}
