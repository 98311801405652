<div class='user-input-wrapper'>
  <input
    #autocompleteInput
    #control='ngModel'
    (onAddressChange)='handleAddressChange($event)'
    *ngIf='!autocompleteDisabled'
    [(ngModel)]='value'
    [class.is-invalid]='control.invalid && control.touched'
    [disabled]='disabled'
    [id]='inputId'
    [ngClass]='inputClass'
    [options]='effectiveAddressLookupOptions'
    [placeholder]='placeholder ?? ""'
    [readonly]='readonly'
    class='user-input'
    ngx-google-places-autocomplete
    type='text'
  >

  <input
    #control='ngModel'
    #plainInput
    *ngIf='autocompleteDisabled'
    [(ngModel)]='value'
    [class.is-invalid]='control.invalid && control.touched'
    [disabled]='disabled'
    [id]='inputId'
    [ngClass]='inputClass'
    [placeholder]='placeholder ?? ""'
    [readonly]='readonly'
    class='user-input'
    type='text'
  >
</div>
