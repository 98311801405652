import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentType } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SyncDocFile } from '../../models/sync-loan-docs/sync-doc-files.model';
import { SyncLoanDoc } from '../../models/sync-loan-docs/sync-loan-doc.model';
import { SyncLodaDoc } from '../../models/sync-loan-docs/sync-loda-doc-with-los-lde.model';
import { LoanDocsService } from '../../services/loan-docs.service';

@Component({
    selector: 'sync-loda-doc-with-los-dialog',
    templateUrl: 'sync-loda-doc-with-los-dialog.component.html',
    styleUrls: ['sync-loda-doc-with-los-dialog.component.scss']
})

export class SyncLodaDocWithLosDialogComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    appId: number;

    @Input()
    companyId: number;

    @Input()
    losVendor: string;

    @Input()
    docTypes: DocumentType[];

    type: string;

    docFilter: string;

    loadingDocList: boolean;

    syncingDoc: boolean = false;

    docSyncList: SyncLodaDoc[] = [];

    countUp: number;

    numberOfDocumentsToSync: number;

    isClickedSelectAll: boolean = false;

    docFilterTypes = [
        {
            name: "All",
            value: "All",
            type: "both"
        },
        {
            name: "Push To LOS",
            value: "PushToLos",
            type: "loda"
        },
        {
            name: "Pull From LOS",
            value: "PullFromLos",
            type: "los"
        },
        {
            name: "Synced",
            value: "Synced",
            type: "both"
        },
        {
            name: "Unattached",
            value: "Unattached",
            type: "los"
        },
        {
            name: "Unmapped",
            value: "Unmapped",
            type: "los" 
        },
        {
            name: "Deleted",
            value: "Deleted",
            type: "loda"
        },
    ]

    constructor(
        public activeModal: NgbActiveModal,
        private readonly _loanDocsService: LoanDocsService,
        private readonly _notifyService: NotificationService,
        private readonly _spinner: NgxSpinnerService
    ) { }

    ngOnInit() {
        this.type = 'loda';
        this.loadData(this.type);
    }

    onCancelClicked = () => {
        this.activeModal.close('cancel');
    }

    onSyncDocClicked = () => {
        const loanDocs: SyncLoanDoc[] = this.getLoanDocsWithSelectedFiles();
        if (loanDocs.length === 0) {
            this._notifyService.showInfo('Please select at least one file', 'Info');
        }
        this.syncingDoc = true;
        this.countUp = 0;

        this.numberOfDocumentsToSync = loanDocs.length;

        loanDocs.forEach(doc => {
            const loanDocDisplayId: string = doc.loanDocDisplayId;

            this._loanDocsService.setSyncDocs(this.appId, doc).subscribe(result => {
                result['loanDocDisplayId'] = loanDocDisplayId;

                result.docFiles.forEach((file: SyncDocFile) => {
                    file.doSync = false;
                    if (file.sync === 'Synced' || file.sync === 'Unmapped' || file.sync === 'Unattached' || file.sync === 'Deleted') {
                        file.disabled = true;
                    }
                });
                this.replaceLoanDoc(result);
                this.countUp++;
                if (this.countUp == this.numberOfDocumentsToSync) {
                    setTimeout(() => {
                        this.docSyncList.forEach(doc => {
                            this.setIsDocFilterTypeForEachDocument(doc);
                        })

                        this.isClickedSelectAll = false;
                        this.syncingDoc = false;
                    }, 800);
                }
            }, err => {
                this.countUp++;
                this._notifyService.showError(err.message || 'Unable to sync document!', 'Failure');
                if (this.countUp === this.numberOfDocumentsToSync) {
                    setTimeout(() => {
                        this.syncingDoc = false;
                    }, 800);
                }
            })
        })
    }

    getProgressValue = (counter: number): number => {
        return Math.ceil((counter / this.numberOfDocumentsToSync) * 100);
    }

    isAnyFileSelected = () => {
        return this.docSyncList.some(doc => doc.loanDocs.some(ld => ld.docFiles.some(file => file.checked)));
    }

    switchFilter = (filterName: string) => {
        this.docFilter = filterName;
        if (this.docFilter !== 'PushToLos') {
            this.isClickedSelectAll = false;
            return this.docSyncList.forEach(doc => doc.loanDocs.forEach(ld => ld.docFiles.forEach(file => file.checked = false)));
        }
    }

    onSelectAllByDocFilterClicked = () => {
        this.isClickedSelectAll = !this.isClickedSelectAll;
    }

    loadData = (type: string) => {
        this._spinner.show();

        this.type = type;
        this.loadingDocList = true;

        if (type === 'loda') {
            this.docFilter = 'PushToLos';
        } else {
            this.docFilter = 'PullFromLos';
        }

        this._loanDocsService.getSyncLodaDocWithLos(this.appId, this.type).subscribe(result => {
            result.forEach((doc: SyncLodaDoc) => {
                doc['docDisplayId'] = this.generateUniqueDisplayId("docList");
                doc.loanDocs.forEach((lDoc: SyncLoanDoc) => {
                    lDoc['loanDocDisplayId'] = this.generateUniqueDisplayId("loanDoc");
                    lDoc.docFiles.forEach(file => {
                        if (file.sync === 'Synced' || file.sync === 'Unmapped' || file.sync === 'Unattached' || file.sync === 'Deleted') {
                            file.disabled = true;
                        }
                    })
                })

                this.setIsDocFilterTypeForEachDocument(doc);
            });
            this.docSyncList = result;
            this._spinner.hide();
        }, err => {
            this._spinner.hide();
            this.activeModal.close('cancel');
            this._notifyService.showError(err ? err.error.message : 'Unable to load doc sync list!', 'Failure');
        })
    }

    hasFilteredFile = (loanDocs: SyncLoanDoc[], docFilter): boolean => {
        return loanDocs.some(ld => ld.docFiles.some(file => file.sync === docFilter));
    }

    private replaceLoanDoc = (loanDoc: SyncLoanDoc) => {
        this.docSyncList.forEach(doc => {
            const index = doc.loanDocs.findIndex(lDoc => lDoc.loanDocDisplayId === loanDoc.loanDocDisplayId);
            if (index > -1) {
                doc.loanDocs[index] = loanDoc;
            }
        })
    }

    private getLoanDocsWithSelectedFiles = () => {
        const loanDocs: SyncLoanDoc[] = [];
        this.docSyncList.forEach(docType => {
            docType.loanDocs.forEach(doc => {
                doc.docFiles.forEach(file => {
                    const isPushed = loanDocs.some(el => el.loanDocDisplayId === doc.loanDocDisplayId);

                    if (file.checked) {
                        file.doSync = true;
                        if (!file.disabled && !isPushed) {
                            loanDocs.push(doc);
                        }
                    }
                })
            })
        })
        return loanDocs;
    }

    private generateUniqueDisplayId = (type: string) => {
        return `${type}_` + Math.random().toString(36).substring(2, 9);
    }

    private setIsDocFilterTypeForEachDocument = (doc: SyncLodaDoc) => {
        this.docFilterTypes.forEach(docType => {
            if(docType.value === "All") return;
            doc[docType.value] = this.hasFilteredFile(doc.loanDocs, docType.value);
        })
    }
}
