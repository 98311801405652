import { ExpressionGroup } from "./expressions/expression-group.model";

export class Question {
  questionId: number;
  questionText: string;
  questionType: LookupType;
  mortgageFunctionExpression: string;
  borrowerFunctionExpression: string;
  expressionGroupId: number;
  expressionGroup: ExpressionGroup;
  expressionHtml?: string;
}

export enum LookupType {
  Immediately = "Immediately",
  OnLoanStatus = "OnLoanStatus",
  CreateInternalContact = "CreateInternalContact",
  UpdateInternalContact = "UpdateInternalContact",
  Private = "Private",
  Group = "Group",
  Public = "Public",
  Event = "Event",
  ToDo = "ToDo",
  Appointment = "Appointment",
  Al = "Al",
  AutoApply = "AutoApply",
  Confirm = "Confirm",
  RequestQuantity = "RequestQuantity",
  LoanCharacteristic = "LoanCharacteristic",
  BorrowerCharacteristic = "BorrowerCharacteristic",
  LessThan = "LessThan",
  LessThanOrEqualTo = "LessThanOrEqualTo",
  EqualTo = "EqualTo",
  GreatherThan = "GreatherThan",
  GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
  NullOrEmpty = "NullOrEmpty",
  NotNullOrEmpty = "NotNullOrEmpty",
  StartsWith = "StartsWith",
  Contains = "Contains",
  DoesNotContain = "DoesNotContain",
  In = "In",
  NotIn = "NotIn",
  MultiSelect = "MultiSelect",
  SelectList = "SelectList",
  Value = "Value",
  Docvalue = "Docvalue",
  DataValidation = "DataValidation",
  DocumentValidation = "DocumentValidation",
  ManualValidation = "ManualValidation",
  YesNo = "YesNo",
  TrueFalse = "TrueFalse",
  OnOff = "OnOff",
  Text = "Text",
  Integer = "Integer",
  Decimal = "Decimal",
  Pdf = "Pdf",
  Doc = "Doc",
  Txt = "Txt",
  Excel = "Excel",
  Csv = "Csv",
  Img = "Img",
  ApplyToLoan = "ApplyToLoan",
  ApplyToPrimaryBorrower = "ApplyToPrimaryBorrower",
  ApplyToSelectBorrowers = "ApplyToSelectBorrowers",
  ChangeStatus = "ChangeStatus",
  Uploadfile = "Uploadfile",
  DeleteFile = "DeleteFile",
  DownloadFile = "DownloadFile",
  ExportFile = "ExportFile",
  FileNoteUpdated = "FileNoteUpdated",
  BorrowerNoteUpdated = "BorrowerNoteUpdated",
  DeleteTask = "DeleteTask",
  RemoveFileFromTrash = "RemoveFileFromTrash",
  ConditionStatusChange = "ConditionStatusChange",
  CreateTask = "CreateTask",
  RestoreTask = "RestoreTask",
  MergeFile = "MergeFile",
  PostponeTask = "PostponeTask",
  ChangeFollowUpDate = "ChangeFollowUpDate",
  ChangeDueDate = "ChangeDueDate",
  ViewFile = "ViewFile",
  FileNameChanged = "FileNameChanged",
  ChangeConditionFlag = "ChangeConditionFlag",
  ChangeDescription = "ChangeDescription",
  ChangeNotifyParty = "ChangeNotifyParty",
  ChangePriority = "ChangePriority",
  ChangeResponsibleParty = "ChangeResponsibleParty",
  ChangeReviewRequired = "ChangeReviewRequired",
  AddCharacteristic = "AddCharacteristic",
  ChangeCharacteristic = "ChangeCharacteristic",
  RemoveCharacteristic = "RemoveCharacteristic",
  ChangeSubStatusOnly = "ChangeSubStatusOnly",
  ChangeEscalationUser = "ChangeEscalationUser",
  ESignStatusChange = "ESignStatusChange",
  TriggerOnly = "TriggerOnly"
}
