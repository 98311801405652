import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable, forkJoin } from 'rxjs';
import { DocFile } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private readonly _dataService: DataService) { }

  uploadFileToUrl = (url: string, files: FileList) => {
    var formData = new FormData();

    if(files != null){
      for(let i=0; i < files.length; i++){
        formData.append("file" + i, files[i]);
      }
    }
    return this._dataService.postFormData(url, formData);
  }

  uploadFileAndSingleModel = (files, url, data) => {
    var formData = new FormData();
    if(files != null){
      for(let i=0; i < files.length; i++){
        formData.append("file" + i, files[i]);
      }
    }
    if (data) {
      formData.append("model", JSON.stringify(data));
    }
    return this._dataService.postFormData(url, formData);
  }

  removeFile = (guid: string) => {
    const url = 'api/File/RemoveFile/' + guid;
    return this._dataService.post(url, {});
  }

  removeFiles(files: readonly DocFile[]): Observable<any[]> {
    return forkJoin(
      files.map((file) => this.removeFile(file.guid)),
    );
  }

  linkFileToTask = (loanDocTaskId, guid, autoTransition) => {
    const url = 'api/File/LinkFileToTask/' + loanDocTaskId + '/' + guid + '?autoTransition=' + autoTransition;
    return this._dataService.post(url, {});
  }

  getFileTracking = (guid: string) => {
    const url = 'api/File/TrackingFile/' + guid;
    return this._dataService.get(url);
  }

  convertToPdf = (fileGuid: string) => {
    const url = 'api/File/convert-to-pdf/' + fileGuid;
    return this._dataService.post(url, {}, null, {responseType: 'text'});
  }

  linkLoanDocToFile = (loanDocId: number, fileGuid: string) => {
    const url = 'api/File/LinkFileToLoanDoc/' + loanDocId + '/' + fileGuid;
    return this._dataService.post(url, {});
  }

  getDocFile = (guid: string) => {
    const url = 'api/File/GetDocFile/' + guid;
    return this._dataService.get(url);
  }

  exportConditions = (appId: number, data) => {
    const url = 'api/File/ExportConditions/' + appId;
    return this._dataService.downloadBinaryFileWithPost(url, data);
  }
}
