import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { ProcessSubmissionModel } from '../../models/process-submission.model';

@Component({
  selector: 'borrower-characteristics',
  templateUrl: './borrower-characteristics.component.html',
  styleUrls: ['./borrower-characteristics.component.scss']
})
export class BorrowerCharacteristicsComponent implements OnInit {

  @Input()
  processSubmissionData: ProcessSubmissionModel;

  @Input()
  context;

  @Input()
  allowedBorrowerCharacterstics;

  @Output()
  nextStep: EventEmitter<{ processSubmissionData: ProcessSubmissionModel }> = new EventEmitter<any>();

  @Output()
  previousStep: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.allowedBorrowerCharacterstics = _.orderBy(this.allowedBorrowerCharacterstics, [['order'], ['asc']]);
  }

  toggleBorrowerCharacteristic = (taskCategoryId, newBorrowerId) => {
    let found = false;

    this.processSubmissionData.borrowerCharacteristics.forEach(bc => {
      if (!found && bc.borrowerId == newBorrowerId) {
        found = true;
        const index = bc.selectedCharacteristics.indexOf(taskCategoryId);
        if (index > -1) {
          bc.selectedCharacteristics.splice(index, 1);
        } else {
          bc.selectedCharacteristics.push(taskCategoryId);
        }
      }
    });

    if (!found) {
      const newCharacteristic = { borrowerId: newBorrowerId, selectedCharacteristics: [taskCategoryId] };
      this.processSubmissionData.borrowerCharacteristics.push(newCharacteristic);
    }
  }

  isBorrowerCharacteristicCheckedForBorrower = (taskCategoryId, borrowerId) => {
    if (this.processSubmissionData.borrowerCharacteristics) {
      return this.processSubmissionData.borrowerCharacteristics.some(bc =>
        bc.borrowerId == borrowerId && bc.selectedCharacteristics?.indexOf(taskCategoryId) > -1)
    }
    return false;
  }

  isCharacteristicCheckedForAnyBorrower = (taskCategoryId) => {
    if (this.processSubmissionData.borrowerCharacteristics) {
      return this.processSubmissionData.borrowerCharacteristics.some(bc => bc.selectedCharacteristics?.indexOf(taskCategoryId) > -1)
    }
    return false;
  }

  back = () => {
    this.previousStep.emit();
  }

  proceed = () => {
    this.nextStep.emit({ processSubmissionData: this.processSubmissionData });
  }
}
