import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { FeeDetailsEditorComponent } from "./fee-details-editor.component";
import { DropdownModule } from 'primeng/dropdown';
import { OddDaysInterestComponent } from '../odd-days-interest-fees/odd-days-interest.component';

@NgModule({
  imports: [
    SharedModule,
    DropdownModule,
  ],
  declarations: [
    FeeDetailsEditorComponent,
    OddDaysInterestComponent,
  ],
  exports: [
    FeeDetailsEditorComponent,
    OddDaysInterestComponent,
  ],
  providers: [
  ]
})
export class FeeDetailsEditorModule {
  constructor() {
  }
}


