<div id="rightSidenav" class="rightnav box-shadow">
  <div class="notification-header">
    <div class="row align-items-center">
        <div class="col">
            <h4 class="notification-title">Threads & Mentions</h4>
        </div><!--end col-->
        <div class="col-auto">
          <a href="javascript:void(0)" class="closebtn" (click)="closeSideMenu()">&times;</a>
        </div><!--end col-->
    </div>  <!--end row-->
</div>
  <ul class="list-group custom-list-group mb-n1">
    <a (click)="openSubMenu()">
    <li class="list-group-item align-items-center d-flex justify-content-between">

        <div class="media">
            <div class="media-body align-self-center">
                <h6 class="mt-0 mb-1">Michael Scott</h6>
                <ul class="list-inline mb-0 text-muted">
                  <li class="list-inline-item me-2">Scranton, Philadelphia</li>
                </ul>
            </div><!--end media-body-->
        </div><!--end media-->

      <span class="right-nav-badge badge bg-danger">2</span>
    </li>
  </a>
    <li class="list-group-item align-items-center d-flex justify-content-between">
      <a (click)="openSubMenu()">
        <div class="media">
            <div class="media-body align-self-center">
                <h6 class="mt-0 mb-1">Michael Scott</h6>
                <ul class="list-inline mb-0 text-muted">
                  <li class="list-inline-item me-2">Scranton, Philadelphia</li>
                </ul>
            </div><!--end media-body-->
        </div><!--end media-->
      </a>
      <span class="right-nav-badge badge bg-danger">2</span>
    </li>
    <li class="list-group-item align-items-center d-flex justify-content-between">
      <a (click)="openSubMenu()">
        <div class="media">
            <div class="media-body align-self-center">
                <h6 class="mt-0 mb-1">Michael Scott</h6>
                <ul class="list-inline mb-0 text-muted">
                  <li class="list-inline-item me-2">Scranton, Philadelphia</li>
                </ul>
            </div><!--end media-body-->
        </div><!--end media-->
      </a>
      <span class="right-nav-badge badge bg-danger">2</span>
    </li>
    <li class="list-group-item align-items-center d-flex justify-content-between">
      <a (click)="openSubMenu()">
        <div class="media">
            <div class="media-body align-self-center">
                <h6 class="mt-0 mb-1">Michael Scott</h6>
                <ul class="list-inline mb-0 text-muted">
                  <li class="list-inline-item me-2">Scranton, Philadelphia</li>
                </ul>
            </div><!--end media-body-->
        </div><!--end media-->
      </a>
      <span class="right-nav-badge badge bg-danger">2</span>
    </li>
</ul>
</div>
