<div class="modal-header">
  <h5 class="modal-title w-100">
    {{ questionAnswerData.questionAnswerId ? 'Edit' : 'New' }} Answer
  </h5>

  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>

<div class="modal-body">
  <form id="questionAnswerForm" #questionAnswerForm="ngForm" name="questionAnswerForm">
    <div class="mb-3">
      <label class="form-label" for="answerText">Answer Text</label>
      <textarea id="answerText" name="answerText" class="form-control" style="height: 95px" maxlength="255" rows="2"
        [ngClass]="{'is-invalid': answerTextField && answerTextField.touched && answerTextField.invalid}"
        #answerTextField="ngModel" [(ngModel)]="questionAnswerData.answerText" required></textarea>
    </div>
    <div class="mb-3">
      <label class="form-label" for="tasks">Tasks</label>
      <ng-select width="100%" name="tasks" bindLabel="name" bindValue="value" class="custom-ng-select"
        placeholder="Select one or more Tasks" [closeOnSelect]="false" [items]="tasks"
        [(ngModel)]="questionAnswerData.taskIds" multiple="true" appendTo="body">
      </ng-select>
    </div>
    <div class="mb-3">
      <label class="form-label" for="loanCharacteristic">Loan Characteristic</label>
      <ng-select width="100%" name="loanCharacteristic" bindLabel="name" bindValue="value" class="custom-ng-select"
        placeholder="Select one or more Loan Characteristic" [closeOnSelect]="false" [items]="loanCharacteristicList"
        [(ngModel)]="questionAnswerData.loanCharacteristicIds" multiple="true" appendTo="body">
      </ng-select>
    </div>
    <div class="mb-3">
      <label class="form-label" for="borrCharacteristic">Borrower Characteristic</label>
      <ng-select width="100%" name="borrCharacteristic" bindLabel="name" bindValue="value" class="custom-ng-select"
        placeholder="Select one or more Borrower Characteristic" [closeOnSelect]="false"
        [items]="borrowerCharacteristicList" [(ngModel)]="questionAnswerData.borrowerCharacteristicIds" multiple="true"
        appendTo="body">
      </ng-select>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-end">
  <span *ngIf="channelsEnabled" class="flex-grow-1">
    <i class="fas fa-info-circle text-primary fs-5"></i>
    Tasks/Characteristics trigger will depend on the channel of the loan application.
  </span>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
    <i class="fa fa-close"></i> Cancel
  </button>

  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="save()">
    <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i>
      {{ questionId? 'Save' : 'Add' }}</span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Saving
    </span>
  </button>
</div>