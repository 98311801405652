import { ICharacteristic } from "./characterictic-interface";
import { CharacteristicMergeValue } from "./characteristic-merge-value.model";

export class LoanCharacteristic implements ICharacteristic {
    loanCharacteristicId: number;
    applicationId: number;
    borrowerId: number;
    characteristicId: number;
    characteristicMergeValues: Array<CharacteristicMergeValue>;
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;
    enabledChannels: string;

    pendingDeletion: boolean = false;

    constructor(loanCharacteristicId: number, applicationId: number, characteristicId: number) {
      this.loanCharacteristicId = loanCharacteristicId;
      this.applicationId = applicationId;
      this.characteristicId = characteristicId;
      this.characteristicMergeValues = [];
    }
}
