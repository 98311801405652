import {Component, Injector, OnInit} from '@angular/core';
import {CocHistoryModel} from '../../../models/coc/coc-history.model';
import {CocService} from '../../../services/coc.service';
import {ActivatedRoute} from '@angular/router';
import {EnumerationItem} from '../../../models/simple-enum-item.model';
import {LookupService} from '../../../services/lookup.service';
import {User} from '../../../models/user/user.model';
import {ApplicationContextBoundComponent} from '../../../shared/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CocTaskDialogComponent } from './coc-task-dialog/coc-task-dialog.component';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'coc-history',
  templateUrl: 'coc-history.component.html'
})

export class CocHistoryComponent extends ApplicationContextBoundComponent implements OnInit {

  isLoading: boolean = true;
  applicationId: string;
  histories: CocHistoryModel[] = [];
  taskStatuses: EnumerationItem[] = [];
  allUsers: User[];

  constructor(private readonly injector: Injector,
              private readonly activatedRoute: ActivatedRoute,
              private readonly _cocService: CocService,
              private readonly _lookupService: LookupService,
              private readonly _modalService: NgbModal
              ) {
    super(injector);
    this.applicationId = activatedRoute.parent.snapshot.url[1].path;
  }

  ngOnInit() {
    this.isLoading = true;
    this.allUsers = this.applicationContext.globalConfig.usersAll.concat(this.applicationContext.globalConfig.tpoUsers);

    this._cocService.getAllCoc(this.applicationId).subscribe((result) => {
      this.histories = result;
      this.histories.sort((val1, val2)=>
        {return new Date(val2.requestDate).getTime() - new Date(val1.requestDate).getTime()});

      this.histories.forEach((history) => {
        let user: User = this.allUsers.find(i => i.userCompanyGuid == history.updatedBy);
        if(user) {
          history.updatedName = user.firstName + ' ' + user.lastName;
        }
      });

      this.isLoading = false;
    });
  }

  openTaskInformationModal = (history: CocHistoryModel) => {
    const modalRef = this._modalService.open(CocTaskDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.task = history;
  }
}
