import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoanApplication } from 'src/app/models';
import { BorrowerCharacteristic, LoanCharacteristic } from 'src/app/models/characteristics';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'characteristics',
  templateUrl: 'characteristics.component.html',
  styleUrls: ['characteristics.component.scss']
})

export class CharacteristicsComponent implements OnInit {

  @Input() application: LoanApplication;
  borrowerCharacteristics: BorrowerCharacteristic[] = [];
  loanCharacteristics: LoanCharacteristic[] = [];

  @Output() characteristicsSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<LoanCharacteristic[] | BorrowerCharacteristic[]> = new EventEmitter<LoanCharacteristic[] | BorrowerCharacteristic[]>();
  characteristicType: string = '1';
  loading: boolean;

  constructor(private readonly _notificationService: NotificationService,
    private readonly _loanService: LoanService) { }

  ngOnInit(): void {
    this.loading = true;
    this.loadLoanCharacteristics(this.application.applicationId);
    this.loadBorrowerCharacteristics(this.application.applicationId);
  }

  onHistoryClicked = () => {

  }

  private loadBorrowerCharacteristics = (applicationId: number) => {
    this._loanService.getBorrowerCharacteristics(applicationId)
      .subscribe({
        next: (characteristics) => {
          this.borrowerCharacteristics = characteristics;
        },
        error: (error) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to get borrower characteristics.',
            'Error - Borrower Characteristics!'
          );
        }
      });
  }

  private loadLoanCharacteristics(applicationId: number) {
    this._loanService.getLoanCharacteristics(applicationId)
      .subscribe({
        next: characteristics => {
          this.loanCharacteristics = characteristics;
          this.loading = false;
        },
        error: (error) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to get loan characteristics.',
            'Error - Loan Characteristics!'
          );
          this.loading = false;
        }
      });
  }

  onCharacteristicsSaved = () => {
    this.characteristicsSaved.emit();
  }

  onPopoverClosed() {
    this.onClose.emit()
  }

}
