import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-annual-usage-dialog',
  templateUrl: './annual-usage-dialog.component.html',
  styleUrls: ['./annual-usage-dialog.component.scss'],
})
export class AnnualUsageDialogComponent {
  annualUsage: number;

  constructor(public activeModal: NgbActiveModal) {
  }

  onApply = () => {
    if (this.annualUsage >= 0) {
      this.activeModal.close(this.annualUsage);
    }
    this.activeModal.dismiss();
  };
}
