<div class="modal-body d-flex align-items-center justify-content-center">
  <form class="row w-100 mh-100" style="overflow-y: auto;">
    <!-- doc template selection -->
    <div class="form-group col-md-8 offset-2" *ngIf="currentPageIndex === -1">
      <label class="form-label mb-0">Which document do you want to generate?</label>
      <select class="form-select " id="documentType" [(ngModel)]="selectedDocumentTemplateId"
        name="documentType" (ngModelChange)="onDocumentTemplateChanged(selectedDocumentTemplateId)" required>
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let documentTemplate of documentTemplates | sort: 'asc':'order'"
          value="{{documentTemplate.documentTemplateId}}"
          [selected]="documentTemplate.documentTemplateId == selectedDocumentTemplateId"
        >
          {{documentTemplate.documentTemplateName}}
        </option>
      </select>
    </div>

    <!-- no inputs found for selected doc template -->
    <div *ngIf="noInput" class="col-md-8 offset-2 text-center p-3" role="alert">
      <h4 style="color: #7081b9">
        No input fields present for selected document template.
      </h4>

      <button class="btn btn-primary mx-1" type="button"
        [disabled]="isLoading" (click)="onGenerateDocClicked(false)">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ isLoading ? 'Please wait...' : 'Finish & Generate Doc'}}
      </button>
    </div>

    <!-- question pages -->
    <ng-container *ngIf="currentPage">
      <h4 class="mb-0 col-md-8 offset-2">{{currentPage.title}}</h4>
      <div class="col-md-8 offset-2" *ngFor="let field of currentPage.fields | filter: { 'visible': true } | sort: 'asc':'pageOrder'">
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Unspecified' || field.fieldType === 'Text'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <input class="form-control" type="text" name="field_{{field.documentTemplateFieldId}}"
            [(ngModel)]="field.defaultValue"/>
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Unspecified' || field.fieldType === 'Textarea'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <textarea class="form-control" name="field_{{field.documentTemplateFieldId}}" style="overflow: hidden"
            [(ngModel)]="field.defaultValue"></textarea>
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'CheckBox'">
          <div class="form-check form-switch form-switch-success">
            <input name="field_{{field.documentTemplateFieldId}}" id="field_{{field.documentTemplateFieldId}}"
              class="form-check-input" type="checkbox" [(ngModel)]="field.defaultValue">
            <label class="form-check-label" for="field_{{field.documentTemplateFieldId}}">
              {{field.displayName}}
            </label>
          </div>
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'RadioButton'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <input class="form-check-input" type="radio" name="field_{{field.documentTemplateFieldId}}"
            [(ngModel)]="field.defaultValue">
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Email'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <input class="form-control" type="email" name="field_{{field.documentTemplateFieldId}}"
            [(ngModel)]="field.defaultValue">
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Phone'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <input class="form-control" type="text" [mask]="'(000) 000-0000'" name="field_{{field.documentTemplateFieldId}}"
            [placeholder]="'(###) ###-####'" [(ngModel)]="field.defaultValue">
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Currency'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <currency-input [(ngModel)]="field.defaultValue" [name]="'field_' + field.documentTemplateFieldId">
          </currency-input>
        </div>
        <div class="form-group mt-3 mb-0" *ngIf="field.fieldType === 'Percent'">
          <label class="form-label mb-0">{{field.displayName}}</label>
          <percent-input [(ngModel)]="field.defaultValue" [name]="'field_' + field.documentTemplateFieldId">
          </percent-input>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<div class="modal-footer d-flex justify-content-center">
  <div class="d-flex">
    <button
      *ngIf="currentPageIndex > -1"
      type="button"
      class="btn btn-primary mx-1"
      [disabled]="isLoading"
      (click)="onBackClicked()">
      <i class="fa fa-chevron-left"></i> Back
    </button>
    <!-- -1 to include generate step -->
    <strong *ngIf="currentPageIndex > -1 && pages.length > 0" class="mx-1 d-flex align-items-center">{{currentPageIndex + 1}} / {{pages.length}}</strong>
    <button
      *ngIf="currentPageIndex > -1 && (currentPageIndex + 1) < pages.length && !isLoading && !noInput"
      type="button"
      class="btn btn-primary mx-1"
      [disabled]="isLoading"
      (click)="onNextClicked()">
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Next <i class="fa fa-chevron-right"></i>
    </button>

    <button
      *ngIf="currentPageIndex === -1 && !noInput"
      type="button"
      class="btn btn-primary mx-1"
      [disabled]="!selectedDocumentTemplateId || isLoading"
      (click)="onStartClicked()">
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Start <i class="fa fa-chevron-right"></i>
    </button>

    <button *ngIf="currentPageIndex > -1 && pages.length === (currentPageIndex + 1) && !noInput"
      class="btn btn-primary mx-1" type="button"
      [disabled]="isLoading" (click)="onGenerateDocClicked(false)">
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ isLoading ? 'Please wait...' : 'Finish & Generate Doc'}}
    </button>
  </div>
</div>