<loading-indicator *ngIf="isLoading"></loading-indicator>
<div class="card-body" [hidden]="isLoading">
  <div class="table-responsive">
    <table class="table mb-0 table-centered text-center">
      <thead class="table-light">
        <tr>
          <th>Borrower Name</th>
          <td>Order Type</td>
          <td>Report Days Back</td>
          <td>Monitoring</td>
          <td>Task Status</td>
          <td>Order Status</td>
          <td>Date</td>
          <td>SSO Link</td>
          <td>Transfer Link</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orders">
          <td>
            <span>{{order.firstName + ' ' + order.lastName}}</span><span *ngIf="order.last4Ssn">
              ({{order.last4Ssn}})</span>
          </td>
          <td>
            <span *ngIf="order.voaRequestType"
              class="label label-{{order.voaRequestType == 'lite' ? 'warning' : 'success'}} label-order">
              {{order.voaRequestType}}
            </span>
          </td>
          <td>
            <select class="form-select" *ngIf="!order.formFreeHistoryId" [name]="'daysBackOptions'"
              [(ngModel)]="order.daysBack">
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let item of daysBackOptions" [ngValue]="item">
                {{item}}
              </option>
            </select>

            <span *ngIf="order.formFreeHistoryId" class="label">
              {{order.daysBack}}
            </span>
          </td>
          <td>
            <select *ngIf="!order.formFreeHistoryId" class="form-select" [name]="'reportDays_'+ order.formFreeHistoryId"
              [(ngModel)]="accountMonitoringInterval[order.borrowerId.toString()]">
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let item of accountMonitoringOptions" [ngValue]=item.value>
                {{item.name}}
              </option>
            </select>

            <span *ngIf="order.formFreeHistoryId" class="label">
              {{order.accountMonitoringInterval}}
            </span>
          </td>
          <td>
            {{order.taskStatus}}
          </td>
          <td>
            {{order['statusDescription']}}
          </td>
          <td>
            {{order.dateInserted | date: 'short'}}
          </td>
          <td>
            <button class="btn btn-sm btn-outline-primary actionBtn" *ngIf="order.loginUrl" (click)="copySSOLink(order.loginUrl)">
              Copy to Clipboard
            </button>
          </td>
          <td>
            <button class="btn btn-sm btn-outline-primary actionBtn"
              *ngIf="order.transactionId && order.integrationProvider == 'FormFree'"
              (click)="copyTransferLink(order.transactionId)">
              Generate and Copy
            </button>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-center gap-1">
              <button class="btn btn-sm btn-outline-primary actionBtn"
                *ngIf="order.latestReportId && !refreshing && !repulling" (click)="getReport(order.transactionId)">
                View Report
              </button>
              <button class="btn btn-sm btn-outline-primary actionBtn" *ngIf="order.transactionId"
                (click)="getAccountInfo(order.transactionId)">View Account</button>
              <button class="btn btn-sm btn-outline-primary"
                *ngIf="order.accountChekOrderId && order.latestReportId" (click)="openImportAssetsDialog(order)">Import
                Assets</button>

              <button class="btn btn-sm btn-outline-primary actionBtn"
                *ngIf="order.voaRequestType == 'lite' && !order.isRepullReport && order.status != 'canceled'"
                (click)="upgradeOrder(order)"
                [disabled]="!order.daysBack || !accountMonitoringInterval[order.borrowerId.toString()]">Upgrade
                Order</button>

              <button class="btn btn-sm btn-outline-primary actionBtn"
                *ngIf="order.voaRequestType == 'full' && !order.isRepullReport && order.status != 'canceled' && !!order.transactionId"
                (click)="generateReport(order)">Generate Report</button>

              <button class="btn btn-sm btn-outline-primary actionBtn" *ngIf="order.isRepullReport"
                (click)="repullReport(order.accountChekOrderId, order.reportId)"
                [disabled]="!order.reportId || !order.accountChekOrderId">Repull Report</button>

              <button *ngIf="!order.formFreeHistoryId actionBtn" class="btn btn-small btn-soft-primary"
                (click)="inviteBorrower(order)"
                [disabled]="order['inviting'] || !order.daysBack || !accountMonitoringInterval[order.borrowerId.toString()]">
                <span *ngIf="order['inviting']">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Inviting...
                </span>
                <span *ngIf="!order['inviting']">Invite To Complete</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template [ngIf]="accountInfo && loadingAccountInfo" [ngIfElse]="accountInfoPanel">
      <loading-indicator></loading-indicator>
    </ng-template>
    <ng-template #accountInfoPanel>
      <h4 *ngIf="accountInfo && accountInfo.length === 0">No Account Info Found</h4>
      <div *ngIf="accountInfo && accountInfo.length !== 0" class="accordion" id="accountDetailsAccordion">
        <h4>Account Details</h4>
        <div class="accordion-item" *ngFor="let item of accountInfo | groupBy: 'accountHolder'; let index = index">
          <h5 class="accordion-header m-0" id="heading-{{index}}">
            <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse-panel-' + index" aria-expanded="true"
              [attr.aria-controls]="'collapse-panel-' + index">
              <span>
                <b>{{item.key}}</b>
                ({{item.value.length}} Linked Account{{item.value.length === 1 ? "" : "s"}})
              </span>
            </button>
          </h5>
          <div id="collapse-panel-{{index}}" class="accordion-collapse collapse show"
            aria-labelledby="heading-{{index}}" data-bs-parent="#accountDetailsAccordion">
            <div class="accordion-body">
              <div class="accordion-sub-headers">
                <div class="accordion-sub-header">
                  <span><b>Institution Name</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Account Type</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Account #</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Account Balance</b></span>
                </div>
                <div class="accordion-sub-header">
                  <span><b>Balance Date</b></span>
                </div>
              </div>
              <div *ngFor="let val of item.value">
                <div class="accordion-sub-headers">
                  <div class="accordion-sub-header">
                    <span>{{val.fiName}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val['accountTypeDescription']}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val.accountNumber}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val.balance}}</span>
                  </div>
                  <div class="accordion-sub-header">
                    <span>{{val.balanceDate | date:'MM/dd/yyyy h:mma' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
