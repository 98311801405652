<div class="card-body" *ngIf="processSubmissionData" style="min-height:400px;">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <p class="card-subtitle font-20 my-3">Please select all the loan characteristics that apply</p>
            <div class="form-check" *ngFor="let lc of allowedLoanCharacterstics; let index = index">
                <input
                    id="lc{{index}}"
                    class="form-check-input"
                    type="checkbox"
                    value="{{lc.taskCategoryId}}"
                    (click)="toggleLoanCharacteristic(lc.taskCategoryId)"
                    [checked]="processSubmissionData.loanCharacteristics.indexOf(lc.taskCategoryId) > -1"
                    [disabled]="lc.taskOption === 'RequestQuantity'">
                <label class="form-check-label" for="lc{{index}}">
                    {{lc.taskCategoryName}}
                </label>
            </div>
        </div>
    </div>
</div>
<div class="text-center">
    <button class="btn btn-primary me-2" (click)="back()">Back</button>
    <button id="new-app-loan-char-proceed-btn" class="btn btn-primary" (click)="proceed()">
        Proceed To {{ allowedBorrowerCharacterstics.length > 0 ?
                    'Borrower Characteristics' : 'Final Review' }}
    </button>
</div>