import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { UploadResponse } from "src/app/models/upload-response.model";
import { ContactListSearchExpressionParams, ContactListSearchResult } from "../models/contact-list-search-result.model";
import { ContactList, SearchCriteria } from "src/app/models";

@Injectable()
export class ContactListsService {

  constructor(private readonly _dataService: DataService) { }

  public getContactList = (contactListId: number): Observable<ContactList> => {
    return this._dataService.get(`api/contact-list/${contactListId}`);
  };

  public getContactLists = (): Observable<ContactList[]> => {
    return this._dataService.get(`api/contact-list`);
  };

  public createContactList = (contactList: ContactList, isDuplication?: boolean): Observable<ContactList> => {
    let url = "api/contact-list?";

    if (isDuplication) {
      url += 'isDuplication=true&';
    }

    return this._dataService.post(url, contactList);
  };

  public updateContactList = (contactList: ContactList): Observable<ContactList> => {
    return this._dataService.post(`api/contact-list/${contactList.contactListId}`, contactList);
  };

  public deleteContactList = (contactListId: number): Observable<any> => {
    return this._dataService.delete(`api/contact-list/${contactListId}`);
  };

  public getContactListPropertiesByType = (type: string): Observable<string[]> => {
    return this._dataService.get(`api/contact-list/type/${type}/properties`);
  }

  public uploadContactListDefinitionFile = (formData: FormData): Observable<ContactList> => {
    const url = "api/contact-list/parse";
    return this._dataService.postFormData(url, formData);
  }

  public uploadContactListFile = (formData: FormData, contactListId: number, leadCampaignId?: number, userCompanyGuid?: string): Observable<any> => {
    const url = `api/contact-list/${contactListId}/upload?`;
    let query = ""

    if (leadCampaignId) {
      query += `leadCampaignId=${leadCampaignId}&`;
    }
    if (userCompanyGuid) {
      query += `assignedToUserCompanyGuid=${userCompanyGuid}&`;
    }

    return this._dataService.postFormData(url + query, formData);
  }

  public uploadContactListFileForValidation = (formData: FormData, contactListId: number, leadCampaignId?: number): Observable<UploadResponse> => {
    const url = 'api/contact-list/' + contactListId + '/upload/validate?';
    let query = "";

    if (leadCampaignId) {
      query += `leadCampaignId=${leadCampaignId}`;
    }

    return this._dataService.postFormData(url + query, formData);
  }

  public searchContactListByKeyword = (searchCriteriaRequest: SearchCriteria, contactListId: number): Observable<ContactListSearchResult> => {
    return this._dataService.post(`api/contact-list/search/${contactListId}`, searchCriteriaRequest);
  };

  public getContactListRecordsTestExpression = (contactListId: number, pageNumber: number, pageLength: number,
    expressionParams: ContactListSearchExpressionParams,
    sortField: string = null, sortOrder: number = null
  ): Observable<ContactListSearchResult> => {
    let queryString = `pageNumber=${pageNumber}&pageSize=${pageLength}`;
    if (expressionParams) {
      if (expressionParams.ignoreUserFilter) {
        queryString += `&ignoreUserFilter=${expressionParams.ignoreUserFilter}`;
      }
    }
    if (sortField) {
      queryString += `&orderBy=${sortField}&orderDirection=${sortOrder === -1 ? 'desc' : 'asc'}`;
    }
    const url = `api/contact-list/${contactListId}/records-test?${queryString}`;
    return this._dataService.post(url, expressionParams);
  }
}
