import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { RecentAgent, RecentLead } from 'src/app/models';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { HeaderMenuItem } from '../models/header-menu.model';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent extends ApplicationContextBoundComponent {

  @Input()
  menu: HeaderMenuItem[] = [];

  menuItemStatuses: Map<string, boolean> = new Map<string, boolean>();

  tab: string = '';
  companyId: number;

  userHasAdminPermission: boolean = false;
  userHasSuperAdminPermission: boolean = false;
  userIsImpersonating: boolean = false;
  allowMortgageCallReports: boolean = false;
  branchesEnabled: boolean = false;
  pricingEnabled: boolean = false;
  externalCompanyEnabled: boolean = false;
  leadManagementEnabled: boolean = false;
  broadcastManagerEnabled: boolean = false;

  @Output()
  newLeadClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newAgentClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newContactClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newApplicationClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewLeadClicked: EventEmitter<RecentLead> = new EventEmitter<RecentLead>();

  @Output()
  viewAgentClicked: EventEmitter<RecentAgent> = new EventEmitter<RecentAgent>();

  @Output()
  menuItemClicked: EventEmitter<HeaderMenuItem> = new EventEmitter<HeaderMenuItem>();

  readonly allowedTabs = {
    ['Dashboard']: 'dashboardv2',
    ['Pipeline']: 'pipeline',
    ['Calendar']: 'calendar',
  }

  constructor(
    private readonly injector: Injector,
  ) {
    super(injector);

    this.userHasAdminPermission = this.applicationContext.userPermissions.admin;
    this.userHasSuperAdminPermission = this.applicationContext.userPermissions.superAdmin;
    this.allowMortgageCallReports = this.applicationContext.userPermissions.allowMortgageCallReports;
    this.leadManagementEnabled = this.applicationContext.userPermissions.leadManagementEnabled;
    this.userIsImpersonating = this.userHasSuperAdminPermission && this.applicationContext.userPermissions.companyId != 1;
    this.companyId = this.applicationContext.userPermissions.companyId;
    this.broadcastManagerEnabled = this.applicationContext.userPermissions.broadcastManagerEnabled;
      
    this.branchesEnabled = this.applicationContext.userPermissions.branchesEnabled;
    this.pricingEnabled = this.applicationContext.userPermissions.pricingEnabled;
    this.externalCompanyEnabled = this.applicationContext.globalConfig.company.find(x=>x.companyId == this.applicationContext.userPermissions.companyId)?.externalCompanyEnabled ?? false;
    this.menu = this.menu.filter(item => !item.requireAdminPermission || this.userHasAdminPermission);
  }

  getRouterLink = (menuItem: HeaderMenuItem): string => {
    return !menuItem.event ? menuItem.link : undefined;
  }

  onMenuToggled = (id: string, menuItem: HeaderMenuItem, level?: number) => {
    if (!menuItem?.children?.length) {
      this.menu.forEach(item => {
        this.resetSelectedItems(item);
      })
      if (this.allowedTabs[menuItem.text] && level === 1) {
        menuItem.selected = true;
      } else if (level > 1) {
        menuItem.selected = true;
      }
    }

    let currentStatus = this.menuItemStatuses.get(id);
    if (!currentStatus) {
      this.menuItemStatuses.set(id, true);
    } else {
      this.menuItemStatuses.set(id, false);
    }

    this.menuItemClicked.emit(menuItem);

    this.triggerEvent(menuItem);
  }

  openNewLeadDrawer = () => {
    this.newLeadClicked.emit();
  }

  openNewAgentDrawer = () => {
    this.newAgentClicked.emit();
  }

  openNewContactDrawer = () => {
    this.newContactClicked.emit();
  }

  openNewApplicationDrawer = () => {
    this.newApplicationClicked.emit();
  }

  openLeadDrawer = (menuItem: HeaderMenuItem) => {
    this.viewLeadClicked.emit(menuItem.data.lead);
  }

  openAgentDrawer = (menuItem: HeaderMenuItem) => {
    this.viewAgentClicked.emit(menuItem.data.agent);
  }

  openKpiDashboard = () => {
    let url = "/admin/kpi-dashboard"
    window.open(url, '_blank');
  }

  private triggerEvent = (menuItem: HeaderMenuItem) => {
    if (menuItem.event && this[menuItem.event]) {
      this[menuItem.event](menuItem);
    }
  }

  private resetSelectedItems = (item) => {
    item.selected = false;

    if (!item.children?.length) {
      return;
    }

    item.children.forEach(child => {
      this.resetSelectedItems(child);
    })
  }
}
