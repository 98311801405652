import { Injectable, OnDestroy } from '@angular/core';
import { Goal, GoalTimeFrame } from '../models/goal.model';
import { BehaviorSubject, filter, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { DataService } from '../../../../core/services/data.service';
import { KeyDatesService } from '../../../../services/key-dates.service';
import { KeyDateGroup } from '../../../../models';
import { ApplicationContextService } from '../../../../services/application-context.service';
import { User } from '../../../../models/user/user.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoalService implements OnDestroy {
  private _goalChangesSubscription: Subscription | null = null;
  private readonly _goalChangesSubject$ =
    new BehaviorSubject<readonly Goal[] | null>(null);

  get goalChanges$(): Observable<readonly Goal[]> {
    return this._goalChangesSubject$.asObservable().pipe(
      tap((goals) => {
        if (goals == null) {
          this._goalChangesSubscription?.unsubscribe();
          this._goalChangesSubscription = this.getGoals().subscribe();
        }
      }),
      filter((goals) => goals != null),
    );
  }

  private readonly _goalDependenciesSubject$ =
    new BehaviorSubject<GoalDependencies | null>(null);

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _dataService: DataService,
    private readonly _keyDatesService: KeyDatesService,
  ) {
  }

  ngOnDestroy(): void {
    this._goalChangesSubscription?.unsubscribe();
  }

  createGoal(goal: Goal): Observable<Goal> {
    const url = 'api/Admin/InsertGoalModel';
    return this._dataService.post(url, goalToRequest(goal)).pipe(
      map((response: GoalResponse) => goalFromResponse(response)),
      tap(() => this.invalidateGoals()),
    );
  }

  getGoals(): Observable<Goal[]> {
    const cachedGoals = this._goalChangesSubject$.getValue();
    if (cachedGoals != null) {
      return of([...cachedGoals]);
    }

    const url = 'api/Admin/AllGoalModel';
    return this._dataService.get(url).pipe(
      map((response: GoalResponse[]) => response.map(goalFromResponse)),
      tap((goals) => this._goalChangesSubject$.next(goals)),
    );
  }

  /**
   * Gets all the data needed to display the goal editor.
   */
  getGoalDependencies(): Observable<GoalDependencies> {
    const cachedDependencies = this._goalDependenciesSubject$.getValue();
    if (cachedDependencies != null) {
      return of(cachedDependencies);
    }

    return this._applicationContextService.context.pipe(
      switchMap(context => {
          return this._keyDatesService.getKeyDatesGroups(
            context.userPermissions.companyId,
          ).pipe(
            map((keyDateGroups) => ({
              keyDateGroups,
              users: context.globalConfig.users,
            })),
          );
        },
      ),
      map(({ keyDateGroups, users }) => new GoalDependencies({
          keyDateGroups,
          users,
        }),
      ),
      tap((dependencies) => this._goalDependenciesSubject$.next(dependencies)),
    );
  }

  updateGoal(goal: Goal): Observable<Goal> {
    const url = 'api/Admin/UpdateGoalModel';
    return this._dataService.post(url, goalToRequest(goal)).pipe(
      map((response: GoalResponse) => goalFromResponse(response)),
      tap(() => this.invalidateGoals()),
    );
  }

  deleteGoal(id: number): Observable<void> {
    const url = `api/Admin/DeleteGoalModel/${id}`;
    return this._dataService.delete(url).pipe(
      tap(() => this.invalidateGoals()),
    );
  }

  private invalidateGoals(): void {
    this._goalChangesSubject$.next(null);
  }
}

function goalFromResponse(response: GoalResponse): Goal {
  return new Goal({
    id: response.goalId,
    name: response.name,
    timeFrame: response.timeframe as GoalTimeFrame,
    keyDateConfigurationId: response.keyDateConfigurationId,
    targetUnits: response.targetUnits,
    targetVolume: response.targetVolume,
    targetProfit: response.targetProfit,
    userIds: response.goalUsers,
  });
}

function goalToRequest(goal: Goal): Partial<GoalResponse> {
  return {
    goalId: goal.id,
    name: goal.name,
    timeframe: goal.timeFrame as string,
    keyDateConfigurationId: goal.keyDateConfigurationId,
    targetUnits: goal.targetUnits,
    targetVolume: goal.targetVolume,
    targetProfit: goal.targetProfit,
    goalUsers: Array.from(goal.userIds ?? []),
  };
}

export interface GoalResponse {
  readonly goalId: number;
  readonly name: string;
  readonly timeframe: string;
  readonly keyDateConfigurationId: number;
  readonly targetUnits: number;
  readonly targetVolume: number;
  readonly targetProfit: number;
  readonly goalUsers: readonly string[];
  readonly companyId: number;
  readonly insertedBy: string;
  readonly updatedBy: string;
  readonly dateInserted: string;
  readonly dateUpdated: string;
}

export class GoalDependencies {
  readonly keyDateGroups: readonly KeyDateGroup[];
  readonly users: readonly User[];

  constructor({ keyDateGroups, users }: {
    keyDateGroups: readonly KeyDateGroup[],
    users: readonly User[],
  }) {
    this.keyDateGroups = keyDateGroups;
    this.users = users;
  }
}
