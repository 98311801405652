<p-table #tasksTable
  [value]="tasks"
  [resizableColumns]="true"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="'description'" pResizableColumn>Description
        <p-sortIcon [field]="'description'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'taskStatus'" pResizableColumn>Status
        <p-sortIcon [field]="'taskStatus'"></p-sortIcon>
      </th>
      <th>View Task
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-task>
    <tr>
      <td>{{task.description}}</td>
      <td style="white-space: break-spaces;">{{task.taskStatus}} - Updated By {{task.updatedBy}}</td>
      <td>
        <button class="btn btn-sm btn-soft-primary"
        type="button"
          (click)="onViewTaskClicked(task)">
          <span
            *ngIf="isLoadingTask"
            class="spinner-border spinner-border-xs"
            role="status"
            aria-hidden="true">
          </span>
          {{ isLoadingTask ? 'Please wait...' : 'View Task'}}
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center"> There are no tasks. </td>
    </tr>
  </ng-template>
</p-table>
