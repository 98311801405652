<ng-container *ngIf="!isLoading; else loadingContent">
  <div class="fieldset-title mb-3">App Passwords</div>
  <p-table #dt1 [value]="tableData" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
    styleClass="p-datatable-gridlines" sortField="dateInserted" [scrollHeight]="scrollHeight" [scrollable]="true">
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary me-2" (click)="addNewAppPasswordConfig()">
          <i class="fas fa-plus"></i> Add New
        </button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 150px;"> Action</th>
        <th [pSortableColumn]="'displayName'">App Name
          <p-sortIcon [field]="'displayName'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'clientId'">Client ID
          <p-sortIcon [field]="'clientId'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td> <a class="text-danger" (click)="removeAppPassword(item)"><i class="fas fa-trash-alt"></i></a> </td>
        <td>{{item.displayName}}</td>
        <td>{{item.clientId}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3" class="text-center"> There are no App Passwords. </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- <div class="col-md-12 my-1">
    <hr />
    <button type="submit" class="btn btn-primary" style="float: right;" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div> -->
</ng-container>
<ng-template #loadingContent>
  <loading-indicator loadingMessage="Loading App Passwords Table..."
    [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>