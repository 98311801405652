import { CustomField } from "src/app/modules/pricing/models/pricing/custom-fields.model";
import { ThirdPartyCredential } from "../third-party";
import { LoanPassEnumMapping } from "./loan-pass-enum-mapping.model";
import { LoanPassMappingType } from "src/app/services/loan-pass-field-mappings.service";

export class LoanPassMapping {
  loanPassFieldMappingId: number;
  loanPassFieldId: string;
  mappingType: LoanPassMappingType;
  destinationFieldPath: string;
  loanPassFieldType: string;
  loanPassDurationType: string;
  loanPassEnumTypeId: string;
  loanPassEnumTypeName: string;
  lodasoftEnumFullName: string;
  expression: string;
  pricingCustomFieldId: number;
  credentialId: number;
  pricingCustomField: CustomField;
  thirdPartyCredentials: ThirdPartyCredential;
  enumMappings: LoanPassEnumMapping[] = [];
  companyId: number;
  insertedByUserId: string;
  dateInserted: string;
  updatedByUserId: string;
  dateUpdated: string;
}
