import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CreateFeeModalComponent } from '../modals/create-fee-modal/create-fee-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateFeeModel } from '../../../models/fee/create-fee.model';
import { NgForm } from '@angular/forms';
import { FeeValidationModel } from '../../../models/fee/fee-validation.model';
import { FeeSectionEnum } from '../../../models/fee/fee-section.enum';
import { CalculatedFeeTypeEnum } from '../../../models/fee/calculated-fee-type.enum';
import { Constants } from 'src/app/services/constants';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'services-borrower-not-shopped-for',
  templateUrl: 'services-borrower-not-shopped-for.component.html',
  styleUrls: ['services-borrower-not-shopped-for.component.scss']
})

export class ServicesBorrowerNotShoppedForComponent implements AfterViewInit {
  @ViewChild('servicesNoShopForm') servicesNoShopForm: NgForm;

  @Input()
  set fees(fees: LoanFee[]) {
    this._fees = fees;
  }

  get fees(): LoanFee[] {
    return this._fees;
  }

  @Input()
  feeSystemDetails: FeeSystemDetails;

  @Output() onFeeAdded = new EventEmitter<CreateFeeModel>();
  @Output() onFeeValueUpdated = new EventEmitter<LoanFee>();
  @Output() onFeeRemoved = new EventEmitter<LoanFee>();
  @Output() onValidationChanged = new EventEmitter<FeeValidationModel>();
  @Output() openFeeEditor = new EventEmitter<LoanFee>();

  feeSection = FeeSectionEnum.ServicesNoShop;
  feesTotal = 0;
  isValidForm = true;

  private _fees: LoanFee[] = [];

  constructor(private modalService: NgbModal) {
  }

  ngAfterViewInit(): void {
    if (this.servicesNoShopForm) {
      this.servicesNoShopForm.statusChanges.subscribe(status => this.onFormValidationStatusChanged(status));
    }
  }

  scrollToFirstInvalidControl() {
    Utils.validateAndScrollToFirstInvalidControl(this.servicesNoShopForm);
  }

  onFormValidationStatusChanged = (status: string) => {
    this.isValidForm = status !== "INVALID";
    this.onValidationChanged.next({ section: this.feeSection, calculatedType: null, isValid: this.isValidForm });
    if (!this.isValidForm) {
      this.servicesNoShopForm.form.markAllAsTouched();
    }
  }

  openAddFeeModal() {
    const modalRef = this.modalService.open(CreateFeeModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.feeSystemDetails = this.feeSystemDetails;
    modalRef.componentInstance.hudNumbersToExclude = [...new Set(this._fees.map(fee => fee.hudNumber))].filter(hud => hud);
    modalRef.componentInstance.feeTypesToExclude = [...new Set(this._fees.map(fee => fee.feeType))].filter(feeType => feeType);
    modalRef.componentInstance.title = 'Create Services Borrower Did Not Shop For Fee';
    modalRef.componentInstance.allowFreeformFeeEntry = false;
    modalRef.componentInstance.feeSection = this.feeSection;
    modalRef.componentInstance.calculatedFeeType = CalculatedFeeTypeEnum.Default;

    modalRef.result.then(
      (data: CreateFeeModel) => {
        this.onFeeAdded.next(data);
      }, () => { });
  }

  removeFee = (fee: LoanFee) => {
    this.onFeeRemoved.next(fee);
  }

  updateFee = (fee: LoanFee) => {
    this.onFeeValueUpdated.emit(fee);
  }

  onFeeEditorOpened = (fee: LoanFee) => {
    this.openFeeEditor.emit(fee);
  }
}
