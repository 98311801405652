export class LeadEvent {
  leadEventId: number;
  leadId: number;
  leadStatusId: number;
  dialListRecordHistoryId: number;
  createdByUserId: string;
  type: string;
  note: string;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum LeadRouteEventType {  
  WarmTransferLeadAvailable = "WarmTransferLeadAvailable",
  FreeForAllLeadTaken = "FreeForAllLeadTaken",
  FreeForAllLeadAvailable = "FreeForAllLeadAvailable",
  NewAssignedLeadPosted = "NewAssignedLeadPosted",
  CancelWarmTransferLeadAvailable = "CancelWarmTransferLeadAvailable",
  WarmTransferReceived = "WarmTransferReceived",
}
