import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocuSignEnvelope } from '../../models/docusign-envelope.model';

@Component({
  selector: 'docusign-data',
  templateUrl: 'docusign-data.component.html'
})

export class DocuSignDataComponent implements OnInit {

  @Input()
  set docuSignData(docuSignData: DocuSignEnvelope) {
    this._docuSignData = docuSignData;
    if (!this._docuSignData) {
      this.messageWhenThereAreNoDocuments = "DocuSign data needs to be loaded...";
      this.messageWhenThereAreNoRecipients = "DocuSign data needs to be loaded...";
    }
  }

  get docuSignData(): DocuSignEnvelope {
    return this._docuSignData;
  }
  
  @Output()
  viewDocuSignDocumentClicked: EventEmitter<number> = new EventEmitter<number>();

  messageWhenThereAreNoDocuments: string = "There are no documents.";
  messageWhenThereAreNoRecipients: string = "There are no recipients.";

  isLoadingDocuSignData: boolean = false;

  private _docuSignData: DocuSignEnvelope = null;

  constructor() { }

  ngOnInit() { }

  onViewDocumentClicked = (documentId: number) => {
    this.viewDocuSignDocumentClicked.emit(documentId);
  }
}
