import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { ApplicationTableComponent } from './application-table/application-table.component';
import { ApplicationsRoutingModule } from './applications-routing.module';
import { ApplicationsComponent } from './applications.component';
import { LinkToLeadDialogComponent } from './link-to-lead-dialog/link-to-lead-dialog.component';
import { NextCopyAppDialogComponent } from './next-copy-application-dialog/next-copy-application-dialog.component';
import { ApplicationService } from './services/applications.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { LeadFirstLastNameSearchDialogComponent } from './link-to-lead-dialog/lead-first-last-name-search-dialog/lead-first-last-name-search-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ApplicationsRoutingModule,
    TableModule,
    MultiSelectModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true
    }),
    PopoverModule.forRoot(),
  ],
  declarations: [
    ApplicationsComponent,
    ApplicationTableComponent,
    NextCopyAppDialogComponent,
    LinkToLeadDialogComponent,
    LeadFirstLastNameSearchDialogComponent
  ],
  providers: [ApplicationService],
  exports: [ApplicationTableComponent],
})
export class ApplicationsModule {}
