export class TrackingInfoHistory {
    actionValue: string;
    fullName: string;
    dateCreated: string;

    constructor(actionValue: string, fullName: string, dateCreated: string) {
        this.actionValue = actionValue;
        this.fullName = fullName;
        this.dateCreated = dateCreated;
    }
}