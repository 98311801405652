<div class="modal-header">
  <h5 class="modal-title">Custom Data Usages</h5>
</div>
<div class="modal-body">

  <div>
    <span class="badge badge-soft-danger delete-message">
      <div>
        Unable to clear Lead and Application data for this CustomData field. It is currently used as a term in
        expressions, please remove it from the following places and try again.
      </div>
      <div>
        Click delete icon to remove the custom data usage from the expression.
      </div>
    </span>
  </div>
  <p-table #dt [value]="data" [paginator]="true" [rows]="25" [showCurrentPageReport]="true" [autoLayout]="true"
           [globalFilterFields]="globalFilterFields" [resizableColumns]="true" responsiveLayout="scroll" scrollable="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" sortField="executionOrder"
           [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines p-datatable-sm">
    <ng-template pTemplate="caption">
      <div class="d-flex">
                <span class=" p-ml-auto">
                    <span class="p-input-icon-right">
                        <i class="pi pi-search"></i>
                        <input pInputText #searchText type="text" class="form-control" (ngModelChange)="dt.first=0"
                               (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                    </span>
                </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:100px">
          <span>Action</span>
        </th>
        <th [class]="col.cssClass" *ngFor="let col of columns | filter: {visible: true}"
            [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
          <div *ngIf="col.field !== 'executionOrder'">
            {{ col.header }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>
          <span class="d-flex align-items-center justify-content-center">
            <i
              *ngIf="!isDeleteClicked[row.id + '_' + row.expressionGroupId]"
              class="fa fa-trash-alt cursor text-danger"
              (click)="isDeleteClicked[row.id + '_' + row.expressionGroupId] = true"
              title="Delete"
            ></i>
            <i
              *ngIf="isDeleteClicked[row.id + '_' + row.expressionGroupId]"
              class="fa fa-times cursor text-danger me-2"
              (click)="isDeleteClicked[row.id + '_' + row.expressionGroupId] = false"
              title="Cancel"
            ></i>
            <i
              *ngIf="isDeleteClicked[row.id + '_' + row.expressionGroupId]"
              class="fa fa-check-circle cursor text-danger"
              (click)="removeCustomDataFromExpression(row.id, row.expressionGroupId)"
              title="Delete"
            ></i>
          </span>
        </td>
        <td>
          <a [routerLink]="[row.url]" target="_blank" *ngIf="row.url">
            {{row.usageArea}}
          </a>
          <span [hidden]="row.url">
            {{row.usageArea}}
          </span>
        </td>
        <td>{{ row.name }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="modal-footer form-footer">
  <button
    type="button"
    class="btn btn-secondary right"
    (click)="activeModal.dismiss()"
  >
    Close
  </button>
</div>
