import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { LoanStatus } from 'src/app/models';

@Injectable()
export class LoanStatusService {
  constructor(private readonly _dataService: DataService) {}

  getAllLoanStatuses(companyId: number): Observable<Array<LoanStatus>> {
    return this._dataService.get(
      `api/Admin/AllLoanStatusModel?companyId=${companyId}`
    );
  }

  insertLoanStatus(companyId: number, data: LoanStatus): Observable<LoanStatus> {
    return this._dataService.post(
      `api/Admin/InsertLoanStatusModel?companyId=${companyId}`,
      data
    );
  }

  updateLoanStatus(companyId: number, data: LoanStatus): Observable<undefined> {
    return this._dataService.post(
      `api/Admin/UpdateLoanStatusModel?companyId=${companyId}`,
      data
    );
  }

  updateLoanStatusOrder(
    sortedIds: Array<{ id: number }>
  ): Observable<Array<{ id: number }>> {
    return this._dataService.post(
      `api/Admin/UpdateLoanStatusOrder`,
      sortedIds
    );
  }

  deleteLoanStatus(
    loanStatusId: number,
    companyId: number,
    assignedLoanStatusId?: number
  ): Observable<undefined> {
    let url = `api/Admin/DeleteLoanStatusModel/${loanStatusId}?companyId=${companyId}`;

    if (assignedLoanStatusId) {
      url += `&replaceWithLoanStatusId=${assignedLoanStatusId}`;
    }

    return this._dataService.delete(url);
  }
}
