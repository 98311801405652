import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, StepValidationArgs, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, defer, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { ApplicationContext, Borrower, LdeCredential, LdeCredentialsArray, LdeProviderFeature, LdeVendor } from 'src/app/models';
import { Lender } from 'src/app/models/config/global-config.model';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { LdeService } from 'src/app/services/lde.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { Table } from 'primeng/table';
import { LdeCondition } from '../../../../models/lde/lde-condition.model';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'import-conditions-dialog-lde',
  templateUrl: './import-conditions-dialog-lde.component.html',
  styleUrls: ['./import-conditions-dialog-lde.component.scss']
})
export class ImportConditionsDialogLDEComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  context: ApplicationContext;

  @Output()
  conditionsImported: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(Table) leadTagsTable: Table;

  globalFilterFields: string[] = ['loanNumber', 'borrowerFirstName', 'borrowerLastName'];


  ldeVendorStr: string;

  ldeProviders: LdeCredentialsArray = [];

  conditionList = [];

  mappedLosConditionRefIds;

  taskList = [];

  internalContacts = [];

  documentTypes = [];

  loanSearchList;

  selectedVendor;

  selectedLoanId: string;
  selectedLoan: any;

  borrowers: Borrower[] = [];

  loanDocTaskId: number;

  ownerRole: string = '';

  rowFilter: string = '';

  printFilter: string = '';

  isSearching: boolean = false;

  submitting: boolean = false;

  step: number = 1;

  losCredentialId: number = null;
  ldeRefNumber: string;
  borrowerFirst: string;
  borrowerLast: string;
  loading: boolean = true;
  loadingConditions: boolean = true;
  loanSearchListColumns;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: false,
    },
  };
  lenders: Lender[];

  constructor(private readonly _ldeService: LdeService,
    public activeModal: NgbActiveModal,
    private readonly _ngWizardService: NgWizardService,
    private readonly _taskService: TaskService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _notifyService: NotificationService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.documentTypes = this.context.globalConfig.documentType;
    this.lenders = this.context.globalConfig.lender;
    this.initWizard().subscribe();

    this.getLoanTasks();
    this.getBorrowers();
    this.setInternalContacts();

    this.loanSearchListColumns = [
      { field: 'selection', header: 'Select', visible: true, sortable: false },
      { field: 'loanNumber', header: 'Loan Number', visible: true, sortable: true },
      { field: 'borrowerFirstName', header: 'Borrower First', visible: true, sortable: true },
      { field: 'borrowerLastName', header: 'Borrower Last', visible: true, sortable: true },
      { field: 'subjectPropertyAddress', header: 'Subject Property Address', visible: true, sortable: true },
      { field: 'subjectPropertyCity', header: 'Subject Property City', visible: true, sortable: true },
    ];
    this.loanSearchListColumns.forEach(column => {
      this.globalFilterFields.push(column.field);
    });
  }

  private initWizard(): Observable<void> {
    const application = this.context.application;
    const ldeVendor = application.ldeVendor as LdeVendor;
    this.setLdeVendor(ldeVendor);

    // FIXME: Check if looking up ldeRefNumber, ldeIdentifier, lenderId
    //        properties of the application is necessary here.
    //        See BUG-15111 and revision at commit 35e15559 for more details.

    // If the application has an LDE vendor, then we can skip the first step.
    const init$ = !ldeVendor
      ? this.initWithStep1()
      : this.initWithStep2();

    return defer(() => {
      this.loading = true;

      return from(this._spinner.show()).pipe(
        switchMap(() => init$),
        switchMap(() => this._spinner.hide()),
        finalize(() => this.loading = false),
        map(() => void 0),
      );
    });
  }

  private setLdeVendor(ldeVendor: LdeVendor): void {
    this.ldeVendorStr = ldeVendor;
    this.ldeProviders =
      this.ldeProviders.filter(provider => provider.ldeVendor == ldeVendor);
    if (this.ldeProviders.length === 1) {
      this.changeLosCredential(this.ldeProviders[0].credentialId);
    }
  }

  /**
   * If the application has no LDE vendor, then we need to show the first step.
   */
  private initWithStep1(): Observable<void> {
    return this._ldeService.getLdeVendorsForImportConditions(this.appId).pipe(
      catchError((error) => {
        const fallbackMessage = 'Couldn\'t get LDE vendors.';
        const message = error?.error?.message || error?.message
          || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
        return of([] as LdeCredential[]);
      }),
      tap((ldeVendors) => {
        const vendors = ldeVendors || [];
        vendors.forEach((vendor) => {
          // To disable first and last name input fields
          vendor['borrowerDisabled'] =
            vendor.vendorFeatures.indexOf(LdeProviderFeature.Lookup) > -1;
        });
        this.ldeProviders = vendors;
      }),
      map(() => void 0),
    );
  }

  /**
   * If the application has an LDE vendor, then we can skip the first step.
   */
  private initWithStep2(): Observable<void> {
    return defer(() => {
      this.loadingConditions = true;

      this.showNextStep();

      const application = this.context.application;
      return this._ldeService.getLdeConditions(application.applicationId).pipe(
        catchError((error) => {
          const fallbackMessage = 'Couldn\'t get LDE conditions.';
          const message = error?.error?.message || error?.message
            || fallbackMessage;
          console.error(fallbackMessage, error);
          this._notifyService.showError(message, 'Error');
          return of([]);
        }),
        tap(this.setLdeConditions.bind(this)),
        finalize(() => this.loadingConditions = false),
      );
    }).pipe(map(() => void 0));
  }

  private setLdeConditions(ldeConditions: LdeCondition[]): void {
    ldeConditions = ldeConditions || [];
    this.conditionList = ldeConditions.filter(x =>
      this.mappedLosConditionRefIds.indexOf(x.losConditionRefId) < 0);
  }

  showPreviousStep(event?: Event) {
    this._ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this._ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this._ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this._ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    this.step = args.step.index + 1;
  }

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  changeLosCredential(losCredentialId: number) {
    this.losCredentialId = losCredentialId;
    this.selectedVendor = this.ldeProviders.find((provider) => provider.credentialId === Number(this.losCredentialId)) || {};
    if (this.selectedVendor.borrowerDisabled) {
      this.borrowerFirst = '';
      this.borrowerLast = '';
    }
  }

  search() {
    this.isSearching = true;

    this.selectedLoanId = undefined;
    this.selectedLoan = undefined;

    let filter = this.losCredentialId + '?';
    if (this.ldeRefNumber && this.ldeRefNumber.length > 0)
      filter += 'loanNumber=' + this.ldeRefNumber + '&';
    if (this.borrowerFirst && this.borrowerFirst.length > 0)
      filter += 'borrowerFirstName=' + this.borrowerFirst + '&';
    if (this.borrowerLast && this.borrowerLast.length > 0)
      filter += 'borrowerLastName=' + this.borrowerLast + '&';

    this._ldeService.searchLde(filter).pipe(
      finalize(() => this.isSearching = false),
    ).subscribe({
      next: (response) => {
        this.loanSearchList = response;
        this.isSearching = false;
      },
      error: (error) => {
        const fallbackMessage = 'Couldn\'t search for loans.';
        const message = error?.error?.message || error?.message
          || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
        return of([]);
      },
    });
  }

  setSelectedLoan(loanNumber: string, ldeIdentifier: string) {
    this.selectedLoanId = ldeIdentifier;
    this.selectedLoan = {
      loanNumber: loanNumber,
      ldeIdentifier: ldeIdentifier,
    };
  }

  selectLoan = () => {
    from(this._spinner.show()).pipe(
      switchMap(() => {
        this.loadingConditions = true;

        return this._ldeService.getLdeConditionsUsingCredentialId(
          this.losCredentialId,
          this.selectedLoanId,
        );
      }),
      catchError((error) => {
        const fallbackMessage = 'Couldn\'t get LDE conditions.';
        const message = error?.error?.message || error?.message
          || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
        return of([] as LdeCondition[]);
      }),
      tap((ldeConditions) => {
        this.setLdeConditions(ldeConditions);
        if (ldeConditions.length > 0) {
          this.showNextStep();
        }
      }),
      switchMap(() => {
        this.loadingConditions = false;
        return this._spinner.hide();
      }),
      map(() => void 0),
    ).subscribe();
  }

  getLoanTasks() {
    this._taskService.getTaskByLoan(this.appId).subscribe((response) => {
      this.taskList = response.filter(x => !x.ldeConditionRefId);
      this.mappedLosConditionRefIds = response.filter(x => x.ldeConditionRefId != null).map(x => x.ldeConditionRefId);
    });
  }

  getBorrowers = () => {
    this._appDetailsService.getBorrowers(this.appId).subscribe((response) => {
      this.borrowers = response;
    })
  }

  importLoanConditions = () => {
    const [
      importCalls,
      linkCalls,
    ] = this.conditionList.reduce((acc, cond) => {
      if (!cond.import || !cond.loanDocTaskId) {
        return acc;
      }

      const selectedTask = cond.selectedTask;
      if (cond.loanDocTaskId == '0') {
        if (selectedTask.documentTypeId == 0) {
          selectedTask.documentTypeId = null;
        }
        selectedTask.ldeConditionRefId = cond.losConditionRefId;
        selectedTask.ldeConditionStatus = cond.losConditionStatus;
        selectedTask.applicationId = this.appId;
        selectedTask.active = true;
        selectedTask.requestBorrower = cond.mappedLoanDocTask.requestBorrower;

        const data = {
          loanDocTask: selectedTask,
          multipleBorrower: null,
        };

        if (selectedTask.borrowerId && selectedTask.borrowerId != '') {
          data.multipleBorrower = [selectedTask.borrowerId];
        }
        acc[0].push(this._taskService.upsertLoanDocTask(data));
        return acc;
      }

      const data = {
        loanDocTaskId: cond.loanDocTaskId,
        ldeConditionRefId: cond.losConditionRefId,
        ldeConditionStatus: cond.losConditionStatus,
        conditionId: cond.conditionId,
        condition: true,
        conditionText: selectedTask.description,
        conditionType: selectedTask.priorTo,
        userId: selectedTask.userId,
      };
      acc[1].push(this._taskService.linkToLosCondition(data));
      return acc;
    }, [[], []] as [Observable<never>[], Observable<never>[]]);

    if (importCalls.length === 0 && linkCalls.length === 0) {
      this._notifyService.showWarning('No conditions selected.', 'Warning');
      return;
    }

    const import$ = importCalls.length > 0
      ? forkJoin(importCalls).pipe(
        tap(() => this._notifyService.showSuccess(
          `Imported ${importCalls.length} conditions successfully.`,
          'Success',
        )),
        catchError((error) => {
          const fallbackMessage = 'Couldn\'t import conditions.';
          const message = error?.error?.message || error?.message
            || fallbackMessage;
          console.error(fallbackMessage, error);
          this._notifyService.showError(message, 'Error');
          return of([]);
        }),
      )
      : of([]);

    const link$ = linkCalls.length > 0
      ? forkJoin(linkCalls).pipe(
        tap(() => this._notifyService.showSuccess(
          `Linked ${linkCalls.length} conditions successfully.`,
          'Success',
          )),
        catchError((error) => {
            const fallbackMessage = 'Couldn\'t link conditions.';
            const message = error?.error?.message || error?.message
              || fallbackMessage;
            console.error(fallbackMessage, error);
            this._notifyService.showError(message, 'Error');
            return of([]);
          },
        ),
      )
      : of([]);

    this.submitting = true;
    forkJoin([
      import$,
      link$,
    ]).pipe(
      finalize(() => this.submitting = false),
    ).subscribe(() => {
      this.activeModal.close(importCalls.length + linkCalls.length);
    });
  }

  toggleSelectedCondition = (losConditionRefId) => {
    var cond = this.conditionList.find(x => x.losConditionRefId == losConditionRefId);
    if (cond.import)
      cond.import = false;
    else
      cond.import = true;
  }

  setTaskIdForCondition = (cond) => {
    let selectedTask = this.taskList.find(x => x.loanDocTaskId == cond.loanDocTaskId);
    cond.selectedTask = selectedTask ? selectedTask : cond.mappedLoanDocTask;
    if (!cond.selectedTask.documentTypeId) {
      cond.selectedTask.documentTypeId = null;
    }
  }

  getRoleContact = (channel) => {
    let retVal = [];
    if (this.context.globalConfig.enabledChannels.length > 0 && channel && channel != '') {
      this.context.globalConfig.channelRoles[channel.toLowerCase()].forEach(role => {
        if (role.isLoanContact) {
          const roleChannel = role.roleChannels.find(el => el.channel === channel);
          role.orderByChannel = roleChannel.order || null;
          role.order = role.orderByChannel;
          retVal.push(role);
        }
      });
      // retVal = $filter('orderBy')(retVal, 'orderByChannel');
    } else {
      this.context.globalConfig.roles.forEach(function (role) {
        if (role.isLoanContact) {
          retVal.push(role);
        }
      });
      // retVal = $filter('orderBy')(retVal, 'order');
    }
    return retVal;
  }

  setInternalContacts = () => {
    this._internalContactsService.getInternalContacts(this.appId).subscribe((response) => {
      const roleContacts = this.getRoleContact(this.context.application.channel);
      roleContacts.forEach(rc => {
        var internalContact = response.find(el => el.roleId === rc.roleId);
        if (internalContact && internalContact.userId) {
          var user = this.context.globalConfig.users.find(el => el.userCompanyGuid === internalContact.userId);
          if (!user)
            user = this.context.globalConfig.tpoUsers.find(el => el.userCompanyGuid === internalContact.userId)
          if (user) {
            this.internalContacts.push({
              id: user.userCompanyGuid,
              roleId: rc.roleId,
              firstName: user.firstName,
              lastName: user.lastName,
              roleName: rc.roleName,
              order: rc.order
            });
          }
        }
      });
    });
  }

}
