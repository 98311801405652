import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-boolean-checkbox',
  templateUrl: './urla-boolean-checkbox.component.html',
  providers: [MakeProvider(UrlaBooleanCheckboxComponent)]
})
export class UrlaBooleanCheckboxComponent extends BaseUrlaInputComponent {

  @ViewChild('editButton') editButton: ElementRef;

  @Input()
  layout: string;

  @Input()
  options: EnumerationItem[] = [];

  @Input()
  hasEditButton = false;

  @Input()
  editButtonId: string = 'urlaCheckboxEditButtonId';

  @Input()
  editButtonEnabledEvenWhenReadOnly: boolean = false;

  @Input()
  editIcon: string = "fas fa-pencil-alt";

  @Output()
  editClickEvent: EventEmitter<any> = new EventEmitter<any>();

}
