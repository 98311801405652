export class DocumentType {
  companyId: number;
  documentTypeId: number;
  documentTypeName: string;
  order: number;
  permittedAgentTypes: string | null;
  restrictedToRoles: string | null;
  showOnBorrowerPortal: boolean;
  showOnTPOPortal: boolean;
  allowTpoUpload: boolean;
  dateInserted: Date | null;
  dateUpdated: Date | null;
  insertedBy: string | null;
  updatedBy: string | null;
}
