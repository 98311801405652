import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocuSignRecipient } from '../../models/docusign-envelope.model';

@Component({
  selector: 'docusign-recipients-table',
  templateUrl: 'docusign-recipients-table.component.html'
})

export class DocuSignRecipientsTableComponent implements OnInit {

  @Input()
  recipients: DocuSignRecipient[] = [];

  @Input()
  showCaption: boolean = false;

  @Output()
  viewDocumentClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  emptyMessage: string = "There are no recipients.";

  constructor() { }

  ngOnInit() { }

  onViewDocumentClicked = (documentId: string) => {
    this.viewDocumentClicked.emit(documentId);
  }
}
