import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { Message } from '../models/message.model';
import { Message as AdminMessage } from '../models/message/message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private readonly _dataService: DataService) { }

  getInternalTaskMessages = (loanDocTaskId: number) => {
    const url = 'api/Message/GetInternalTaskMessages/' + loanDocTaskId;
    return this._dataService.get(url);
  }

  getBorrowerTaskMessage = (loanDocTaskId: number) => {
    const url = 'api/Message/GetBorrowerTaskMessages/' + loanDocTaskId;
    return this._dataService.get(url);
  }

  postInternalMessage = (internalMessage: AdminMessage, setContactAttempt: boolean = false, isContactAttemptSuccessful: boolean = false) => {
    const url = `api/Message/PostInternalMessage?setContactAttempt=${setContactAttempt}&isContactAttemptSuccessful=${isContactAttemptSuccessful}`;
    return this._dataService.post(url, internalMessage);
  }

  postBorrowerMessage = (borrowerMessage: AdminMessage) => {
    const url = 'api/Message/PostBorrowerMessage/';
    return this._dataService.post(url, borrowerMessage);
  }

  postAgentMessage = (agentMessage: AdminMessage) => {
    const url = 'api/Message/PostAgentMessage/';
    return this._dataService.post(url, agentMessage);
  }

  requeuePausedEmailSms = (messageId: number) => {
    const url = 'api/Message/RequeueMessage/' + messageId;
    return this._dataService.post(url, {});
  }

  insertSmsIntoQueue = (data: Message, isLead: boolean, appendSMSSignature: boolean, sendImmediately?: boolean): Observable<Message> => {
    const willSendImmediately = sendImmediately ? true : false;
    const url = `api/Message/${isLead ? 'InsertLeadSmsIntoQueue' : 'InsertLoanSmsIntoQueue'}/${appendSMSSignature}?sendImmediately=${willSendImmediately}`;
    return this._dataService.post(url, data);
  }
}
