import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DisclosureTrackingService } from '../services/disclosure-tracking.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DisclosureTracking } from '../models/disclosure-tracking.model';
import { ApplicationContext, Borrower } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';
import { MortgageModelFieldsConfig } from 'src/app/shared/services/mortgage-field-validation-utils';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'disclosure-tracking',
  templateUrl: 'disclosure-tracking.component.html',
  styleUrls: ['disclosure-tracking.component.scss']
})
export class DisclosureTrackingComponent implements OnInit, OnDestroy {

  @Input()
  appId: number;

  @Input()
  mortgageId: number;

  @Input()
  borrowers: Borrower[];

  @Input()
  usersAll: User[];

  @Input()
  timeZone: string;

  @Input()
  urlaFieldsConfig: MortgageModelFieldsConfig;

  get refreshHistory(): boolean {
    return this._refreshHistory;
  }

  @Input()
  set refreshHistory(value: boolean) {
    this._refreshHistory = value;
    if (value) {
      this.getHistoryItems();
    }
  }

  historyItems: DisclosureTracking[] = [];

  private _refreshHistory: boolean;

  private _loanChangesSubscription: Subscription;

  private _contextSubscription: Subscription;

  constructor(
    private readonly _disclosureTrackingService: DisclosureTrackingService,
    private readonly _notifsService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _urlaValidationService: UrlaValidationService) { }

  ngOnInit() {
    this._contextSubscription = this._applicationContextService.context.subscribe((context) => {
      if (context.application) {
        this.initialize(context);
      }
    });
    this._loanChangesSubscription = this._applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.initialize(context);
      }
    });
  }

  ngOnDestroy(): void {
    this._loanChangesSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
  }

  private initialize = (context: ApplicationContext) => {
    this.mortgageId = context.currentMortgage.mortgageId;
    this.appId = context.application.applicationId;
    this.urlaFieldsConfig = this._urlaValidationService.getUrlaFieldsConfigForMortgage(context.currentMortgage, 
      !!context.application.losIdentifier);
    this.getHistoryItems();
  }

  private getHistoryItems = () => {
    this._spinnerService.show();
    this._disclosureTrackingService.getDisclosureTrackingHistory(this.mortgageId)
      .subscribe({
        next: (response: DisclosureTracking[]) => {
          this.historyItems = response;
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to get disclosure tracking history for the application.', 'Error!');
        }
      }).add(() => this._spinnerService.hide());
  }
}
