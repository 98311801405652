import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Utils } from 'src/app/core/services/utils';
import {Profile, SystemLevel, ThirdPartyCredentialType, ThirdPartyKeyValue, UserType} from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { TimeZone } from 'src/app/models/timezone.model';
import { PrequalGenerationSettings } from 'src/app/modules/prequal-letter-settings/models';
import { GlobalConfigService } from 'src/app/services/global-config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ProfileService } from '../../profile.service';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { BreadcrumbService } from 'src/app/shared/services/bread-crumb.service';
import { User } from 'src/app/models/user/user.model';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @ViewChild("emailTemplates") emailTemplates: EmailTemplatesComponent;

  configs: GlobalConfig;
  userProfile: Profile;
  systemLevel: SystemLevel;
  tenantKvp: ThirdPartyKeyValue;

  timezones: TimeZone[] = [];
  availableMergeFields: EnumerationItem[] = [];

  loading: boolean = false;
  saveInProgress: boolean = false;
  isLoggedInUserProfile: boolean = false;
  externalCompanyId?: number;
  companyCredentialId?: number

  isAdmin: boolean;

  prequalGenerationSettings: PrequalGenerationSettings;
  userCompanyGuid: string;

  private _urlHasUserId: boolean = false;

  constructor(
    injector: Injector,
    private readonly _profileService: ProfileService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _globalConfigService: GlobalConfigService,
    private readonly route: ActivatedRoute,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _breadcrumbService: BreadcrumbService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
  ) {
    super(injector);

    this.route.queryParams.subscribe((params) => {
      this.externalCompanyId = params.externalCompanyId && parseInt(params.externalCompanyId);
    })
  }

  ngOnInit() {
    this.loading = true;
    this.getTimezones();
    this.applicationContextService.context.subscribe(context => {
      this.configs = context.globalConfig;
      this.isAdmin = context.userPermissions.admin;

      this.availableMergeFields = this.applicationContextService.getMergeFieldsByTypes(['app', 'agent', 'borrower']);

      // when undefined then we edit our profile
      this.isLoggedInUserProfile = !this.route.snapshot.params.id || (context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid === this.route.snapshot.params.id);

      this.userCompanyGuid = this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid;
      this._urlHasUserId = !!this.route.snapshot.params.id;

      const user = context.globalConfig.usersAll.find(u => u.userCompanyGuid === this.route.snapshot.params.id);
      
      const apiCalls = [
        this._systemLevelService.getSystemLevel(),
        this._profileService.getUserProfile(this.userCompanyGuid),
        this._profileService.getUserPrequalGenerationSettings(this.userCompanyGuid)
      ];

      if (user && user.branchId) {
        apiCalls.push(this._thirdPartyCredentialsService.getThirdPartyCredentialsForBranch(user.branchId, ThirdPartyCredentialType.Email));
      }

      const combined = combineLatest(apiCalls)

      combined
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (response: Array<any>) => {
            this.systemLevel = response[0];
            this.userProfile = response[1];
            this.prequalGenerationSettings = response[2] ?? new PrequalGenerationSettings();

            if (!this.userProfile.allowCancelEsignOverride) {
              this.userProfile.allowCancelEsignOverride = {
                key: "AllowCancelEsignOverride",
                id: 0,
                value: 0,
                userId: this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid
              }
            }
            if (!this.userProfile.allowCopyLoanOverride) {
              this.userProfile.allowCopyLoanOverride = {
                key: "AllowCopyLoanOverride",
                id: 0,
                value: 0,
                userId: this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid
              }
            }
            if (!this.userProfile.allowDeleteTaskOverride) {
              this.userProfile.allowDeleteTaskOverride = {
                key: "AllowDeleteTaskOverride",
                id: 0,
                value: 0,
                userId: this.route.snapshot.params.id || context.currentlyLoggedInUserProfile.userProfile.userCompanyGuid
              }
            }

            let emailCred = this.systemLevel.thirdPartyCredentials.find(cred => cred.credentialType === "Email");
            if (response[3]) {
              const branchLevelEmailCred = response[3].find(el => el.vendorName === 'MicrosoftGraph');
              if (branchLevelEmailCred) {
                emailCred = branchLevelEmailCred;
              }
            }
            
            this.companyCredentialId = this.systemLevel.thirdPartyCredentials.find(cred => cred.vendorName === "MicrosoftGraph")?.credentialId;
            this.tenantKvp = emailCred?.thirdPartyKeyValuePairs?.find(kvp => kvp.key === "TenantId");
          },
          error: (error) => {
            this._notificationService.showError(
              error?.message || 'Unable to load data',
              'Profile Service'
            );
          }
        });
    })
  }

  ngAfterViewInit() {
    if (this._urlHasUserId) {
      this._breadcrumbService.overrideWith([
        { label: 'Home', url: '/admin/dashboardv2' },
        { label: 'Users', url: '/admin/company/users' },
        { label: 'Profile', url: `/admin/profile/${this.userCompanyGuid}` }
      ]);
    }
  }

  saveInfo = () => {
    if (this.userProfile.userProfile.stackOrderId == 0) {
      this.userProfile.userProfile.stackOrderId = null;
    }
    if (this.userProfile.userProfile.calendarLink && !Utils.isValidUrl(this.userProfile.userProfile.calendarLink)) {
      return;
    }
    if (this.userProfile.userProfile.websiteLink && !Utils.isValidUrl(this.userProfile.userProfile.websiteLink)) {
      return;
    }
    this.saveInProgress = true;
    this._spinner.show();
    this._profileService.saveUserProfile(this.userProfile)
      .pipe(finalize(() => {
        this._spinner.hide();
        this.saveInProgress = false;
      }))
      .subscribe({
        next: (savedProfile) => {
          this._notificationService.showSuccess(
            'User profile updated successfuly.',
            'Profile Service'
          );
          if (!savedProfile.userProfile.stackOrderId) {
            savedProfile.userProfile.stackOrderId = 0;
            this.userProfile.userProfile.stackOrderId = 0;
          }
          if (this.isLoggedInUserProfile) {
            this.applicationContextService.updateUserProfile(savedProfile.userProfile);
          }
          this.userProfile = {
            ...this.userProfile,
            ...savedProfile,
            emailService: {
              ...savedProfile.emailService,
              serviceType: savedProfile.emailService.serviceType || null
            }
          };
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to update user profile',
            'Profile Service'
          );
        }
      });
  }

  private getTimezones = () => {
    this._globalConfigService.getTimeZones().subscribe((res: TimeZone[]) => {
      this.timezones = res;
    })
  }
  protected readonly UserType = UserType;
}
