export class PricingFieldSpec {
  fieldName:	string;
  defaultValue:	string;
  fieldAccess:	PricingFieldAccess;
  repriceFieldAccess:	PricingFieldAccess;
  hiddenEnumOptions: string[] = [];
}

export enum PricingFieldAccess {
  ReadWrite = "ReadWrite",
  ReadOnly = "ReadOnly",
  Hidden = "Hidden"
}

