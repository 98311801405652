import { Component, Input, OnInit } from '@angular/core';
import { LosDisclosureGenJob } from '../../../request-models/los-disclosure-gen-job';

@Component({
  selector: 'encompass-disclosure-info',
  templateUrl: './encompass-disclosure-info.component.html',
  styleUrls: ['./encompass-disclosure-info.component.scss']
})
export class EncompassDisclosureInfoComponent implements OnInit {

  @Input() type: string;
  @Input() job: LosDisclosureGenJob;

  @Input() documentsVisible: boolean = false;

  @Input() correctedValues: any;

  @Input() isTpoUser: boolean = false;

  selectedTab: string = 'previewLE';

  constructor() { }

  ngOnInit(): void {
  }

}
