import { Component, Injector, Input, OnInit } from "@angular/core";
import { forkJoin, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { LeadCampaign } from "src/app/models/config/global-config.model";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { LeadList } from "../../models/lead-list.model";
import { LeadMilestonesReport, LeadMilestonesReportData, LeadPerformanceDetailReport, LeadPerformanceReport, LeadReportFilterData } from "../../models/lead-reports.model";
import { LeadsService } from "../../services/leads.service";
import * as _ from "lodash";

@Component({
  selector: 'lead-metrics',
  templateUrl: './lead-metrics.component.html',
  styleUrls: ['./lead-metrics.component.scss']
})
export class LeadMetricsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input('filters')
  set filters(filters: LeadReportFilterData) {
    this._filters = filters;
    this.getReports(filters);
  }

  get filters(): LeadReportFilterData { return this._filters; }

  @Input() isChartVisible: boolean = true;

  isLoading: boolean = false;

  leadLists: LeadList[];
  leadCampaigns: LeadCampaign[];

  performanceReport: LeadPerformanceReport;
  performanceDetailReport: LeadPerformanceDetailReport;
  milestonesReport: LeadMilestonesReport;

  milestoneDataToDisplay: LeadMilestonesReportData[];

  baseMilestonesReport: LeadMilestonesReportData;
  filterMilestonesReport: LeadMilestonesReportData;
  totalMilestonesReport: LeadMilestonesReportData;

  leadlistName: string;
  campaignName: string;

  detailReportChart: any;
  error: any;

  private _filters: LeadReportFilterData;

  constructor(
    private readonly _leadService: LeadsService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.leadCampaigns = this.applicationContext.globalConfig.leadCampaigns;
  }

  ngOnInit(): void {

    this.getAllLeadLists();
  }

  formatNumber = (value: number, fractionDigit: number): number => {
    return value ? Number(value.toFixed(fractionDigit).replace("/\.0{" + fractionDigit + ",}$/", '')) : 0;
  }

  private getAllLeadLists = () => {
    this._leadService.getLeadLists().subscribe(lists => {
      this.leadLists = lists.filter(l => l.name != null && l.name.length > 0).sort((a, b) => a.name.localeCompare(b.name)); // name asc
    });
  }

  private getReports = (filters: LeadReportFilterData): void => {
    this.isLoading = true;
    this.milestoneDataToDisplay = [];
    this.error = null; // reset error
    var requests = new Array<any>;
    var requestFilters = new Array<LeadReportFilterData>;

    requests.push(this._leadService.getLeadMilestonesReports(filters));
    requestFilters.push(filters);

    let showMySummary = !filters.campaignId || filters.campaignId.length == 0;

    if (filters.campaignId && filters.campaignId.length > 0) {
      filters.campaignId.forEach(cId => {
        var f = _.clone(filters);
        f.campaignId = new Array<number>;
        f.campaignId.push(cId);
        f.filterDisplayName = `Leads from Campaign '${this.leadCampaigns.find(x => x.leadCampaignId == cId)?.name}' ${f.dateRange ? `matching filter received '${f.dateRange.displayText}'` : ''}`;
        requests.push(this._leadService.getLeadMilestonesReports(f))
        requestFilters.push(f);
      });
    }

    forkJoin(requests).pipe(
      catchError((error) => of(error)),
      switchMap((results) => {
        if (results && results.length) {
          for (var i = 0; i < results.length; i++) {
            if (i == 0) {
              if (!showMySummary) {
                continue;
              }
              this.milestoneDataToDisplay.push(new LeadMilestonesReportData(
                "My Leads matching filter received '" + requestFilters[i].dateRange.displayText + "'",
                results[i].speedToContactAttemptSeconds,
                results[i].speedToContactSeconds,
                results[i].leads,
                results[i].activeLeads,
                results[i].convertedLeads,
                results[i].contactRate,
                results[i].conversionRate
              ));
              this.milestoneDataToDisplay.push(new LeadMilestonesReportData(
                "All Leads matching filter received '" + requestFilters[i].dateRange.displayText + "'",
                results[i].filterSpeedToContactAttemptSeconds,
                results[i].filterSpeedToContactSeconds,
                results[i].filterTotalLeads,
                results[i].filterTotalActiveLeads,
                results[i].filterTotalConvertedLeads,
                results[i].filterTotalContactRate,
                results[i].filterTotalConversionRate
              ));
            } else {
              this.milestoneDataToDisplay.push(new LeadMilestonesReportData(
                "My " + requestFilters[i].filterDisplayName,
                results[i].speedToContactAttemptSeconds,
                results[i].speedToContactSeconds,
                results[i].leads,
                results[i].activeLeads,
                results[i].convertedLeads,
                results[i].contactRate,
                results[i].conversionRate
              ));
              this.milestoneDataToDisplay.push(new LeadMilestonesReportData(
                "All " + requestFilters[i].filterDisplayName,
                results[i].filterSpeedToContactAttemptSeconds,
                results[i].filterSpeedToContactSeconds,
                results[i].filterTotalLeads,
                results[i].filterTotalActiveLeads,
                results[i].filterTotalConvertedLeads,
                results[i].filterTotalContactRate,
                results[i].filterTotalConversionRate
              ));
            }
          }

          this.milestoneDataToDisplay.push(new LeadMilestonesReportData(
            "All Leads received",
            results[0].totalSpeedToContactAttemptSeconds,
            results[0].totalSpeedToContactSeconds,
            results[0].totalLeads,
            results[0].totalActiveLeads,
            results[0].totalConvertedLeads,
            results[0].totalContactRate,
            results[0].totalConversionRate
          ));
        }

        return this._leadService.getLeadPerformanceReports(this.filters)
      }))
      .pipe(
        catchError((error) => of(error)),
        switchMap(performanceReport => {
          this.performanceReport = performanceReport;
          return this._leadService.getLeadPerformanceDetailReports(this.filters)
        })
      )
      .subscribe((performanceDetailReport: LeadPerformanceDetailReport) => {
        this.performanceDetailReport = performanceDetailReport;

        this.populateChartOptions();

        // populate lead list name
        if (this.filters.leadListId) {
          let matched = this.leadLists.filter(list => list.leadListId == Number(this.filters.leadListId))[0];
          this.leadlistName = matched ? matched.name : "";
        }

        // populate campaign name
        if (this.filters.campaignId) {
          let matched = this.leadCampaigns.filter(list => list.leadCampaignId == Number(this.filters.campaignId))[0];
          this.campaignName = matched ? matched.name : "";
        }

        this.isLoading = false;

      }, (error) => {
        this.isLoading = false;
        this.error = error;
      });
  }

  private populateChartOptions = (): void => {

    this.detailReportChart = {
      data: {
        labels: this.performanceDetailReport.labels || [],
        datasets: [
          {
            type: "bar",
            label: "Leads Received",
            backgroundColor: 'rgb(255, 99, 132)',
            data: this.performanceDetailReport.newLeadsReceived || []
          },
          {
            type: "bar",
            label: "Contact Attempts",
            backgroundColor: 'rgb(255, 159, 64)',
            data: this.performanceDetailReport.contactAttemptsCount || []
          },
          {
            type: "bar",
            label: "Successfully Made Contact",
            backgroundColor: 'rgb(255, 205, 86)',
            data: this.performanceDetailReport.contactSuccessCount || []
          },
          {
            type: "bar",
            label: "Unique Leads Contacted",
            backgroundColor: 'rgb(75, 192, 192)',
            data: this.performanceDetailReport.uniqueLeadsContactedCount || []
          },
          {
            type: "bar",
            label: "Emails Sent",
            backgroundColor: 'rgb(54, 162, 235)',
            data: this.performanceDetailReport.emailsSentCount || []
          },
          {
            type: "bar",
            label: "Sms Sent",
            backgroundColor: 'rgb(3, 254, 176)',
            data: this.performanceDetailReport.smsSentCount || []
          },
          {
            type: "bar",
            label: "Leads Converted",
            backgroundColor: 'rgb(153, 102, 255)',
            data: this.performanceDetailReport.leadsConvertedCount || []
          },
        ]

      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scaleStartValue: 0,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        plugins: {
          tooltips: {
            mode: 'index',
            intersect: false
          },
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
              drawBorder: false,
              display: false
            }
          },
          y: {
            stacked: true,
            ticks: {
              color: '#495057',
              beginAtZero: true
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      }
    };
  }
}

