import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'right-nav',
  templateUrl: './right-nav.component.html',
  styleUrls: ['./right-nav.component.scss']
})
export class RightNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
  openSubMenu() {
    var element = document.getElementById("rightSubnav");
    element.classList.toggle("right-sub-nav-open");
  }
  closeSideMenu() {
    document.getElementById("rightSidenav").style.right = "-340px";
  }
}
