<ul class="nav nav-tabs">
    <li class="nav-item">
        <a class="nav-link" (click)="handleAppraisalViewChange('appraisalStatus')"
            [ngClass]="{'active': tab == 'appraisalStatus'}" data-bs-toggle="tab">Appraisal Status/Order</a>
    </li>
    <li [hidden]="!(activeAppraisal && activeAppraisal.appraisalOrderId)" class="nav-item">
        <a class="nav-link" (click)="handleAppraisalViewChange('appraisalNotes')"
            [ngClass]="{'active': tab == 'appraisalNotes'}" data-bs-toggle="tab">Notes</a>
    </li>
    <li [hidden]="!(activeAppraisal && activeAppraisal.appraisalOrderId)" class="nav-item">
        <a class="nav-link" (click)="handleAppraisalViewChange('appraisalDocuments')"
            [ngClass]="{'active': tab == 'appraisalDocuments'}" data-bs-toggle="tab">Documents</a>
    </li>
    <li [hidden]="!(activeAppraisal && activeAppraisal.appraisalOrderId)" class="nav-item">
        <a class="nav-link" (click)="handleAppraisalViewChange('appraisalConditions')"
            [ngClass]="{'active': tab == 'appraisalConditions'}" data-bs-toggle="tab">Conditions</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" (click)="handleAppraisalViewChange('orderHistory')"
            [ngClass]="{'active': tab == 'orderHistory'}" data-toggle="tab">Order History</a>
    </li>
</ul>
<div id="myTabContent" class="tab-content">
    <div *ngIf="tab === 'appraisalStatus'">
        <div [hidden]="!(allOrdersCancelled() || (appraisals && appraisals.length === 0))">
            <div class="row mt-2">
                <div class="col-md-{{!!task.thirdPartyIntegrationProvider ? '3' : '4'}}">
                    <div class="title">
                        Request Appraisal
                    </div>
                </div>
                <div class="col-md-{{!!task.thirdPartyIntegrationProvider ? '9' : '8'}}">
                    <div class="row">
                        <div class="col-md-{{!!task.thirdPartyIntegrationProvider ? '6' : '4'}}">
                            <button class="btn btn-primary submit-appraisal-order" *ngIf="!submittingAppraisalOrder"
                                [disabled]="!(selectedAppraisalFormTypes && selectedAppraisalFormTypes.length)"
                                (click)="submitAppraisalOrder()">
                                Create Order
                            </button>

                            <button *ngIf="task.thirdPartyIntegrationProvider == 'Reggora'" class="btn btn-primary ms-1" [routerLink]="['/admin/app-details/' + mortgage.applicationId + '/appraisal']">
                              Go To Reggora</button>
                        </div>
                        <div class="col-md-{{!!task.thirdPartyIntegrationProvider ? '6' : '8'}}">
                            <ng-select width="100%" name="appraisalFormTypes" bindLabel="name" bindValue="id" class="custom-ng-select"
                                placeholder="Select Form Types" [closeOnSelect]="false" multiple="true"
                                #aFormTypeField="ngModel" [items]="appraisalFormTypes" required
                                [ngClass]="{'is-invalid': aFormTypeField && aFormTypeField.touched && aFormTypeField.invalid}"
                                [(ngModel)]="selectedAppraisalFormTypes">
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div *ngIf="selectedAppraisalFormTypes.length >= 1" class="ng-values-list">
                                        <span class="ng-value-label">{{ selectedAppraisalFormTypes.length }}
                                            item(s) selected</span>
                                    </div>
                                </ng-template>
                            </ng-select>

                        </div>
                    </div>
                </div>
            </div>

            <div class='row section mt-2' style="max-height: 50vh; overflow-y: auto;">
                <div class="col-md-12 my-1 pb-2">
                    <div class="row">
                        <div class="col-md-4">Loan Type:</div>
                        <div class="col-md-8">{{loanType ? loanType.name : '-'}}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">Loan Purpose:</div>
                        <div class="col-md-8">{{loanPurpose ? loanPurpose.name : '-'}}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">Selling Price:</div>
                        <div class="col-md-8">{{(mortgage.mortgageTerm?.amount || 0) | currency}}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">Property Address:</div>
                        <div class="col-md-8">{{mortgage.subjectProperty?.address1 || '-'}}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">Property City/St/Zip:</div>
                        <div class="col-md-8">
                            {{
                            (mortgage.subjectProperty?.city ? mortgage.subjectProperty.city +', ' : '') +
                            (mortgage.subjectProperty?.state ? mortgage.subjectProperty.state.toUpperCase() : '') + ' ' +
                            (mortgage.subjectProperty?.zipCode || '')
                            }}
                        </div>
                    </div>
                </div>
                <hr>
                <div class="col-md-12 mt-2">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="rushOrder" id="rushOrder" [(ngModel)]="isRushOrder">
                        <label class="form-check-label" for="rushOrder">Rush Order</label>
                    </div>
                </div>
                <div class="mt-2 col-md-4" title="The latest date the lender needs the appraisal report sent">
                    <label class="form-label">Target Due Date:</label>
                    <input autocomplete="off" id="targetDueDate" name="targetDueDate" class="form-control" id="TargetDueDate" type="date"
                        [ngModel]="targetDueDate" (ngModelChange)="targetDueDate = $event"/>
                </div>
                <div class="col-md-8"></div>
                <div class="mt-2 col-md-12">
                    <a href="javascript:void(0);" (click)="openNotesPopup();">Notes</a>
                </div>
                <div class="col-md-12">
                    <a href="javascript:void(0);" (click)="openDocumentsPopup();">Documents</a>
                </div>
                <div class="col-md-12">
                    <a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#Collapse" aria-expanded="false" aria-controls="Collapse">
                        Third-Party Contact: <em>(optional)</em>
                    </a>
                    <div class="collapse mt-1" id="Collapse">
                        <div class="alert alert-info">
                            The best person to contact on the order. Whoever the appraiser needs to contact in order to
                            gain access to the property for inspection.
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="form-label mb-0">First Name</label>
                                <input class="form-control" [(ngModel)]="thirdPartyContact.firstName" />
                            </div>
                            <div class="col-md-4">
                                <label class="form-label mb-0">Last Name</label>
                                <input class="form-control" [(ngModel)]="thirdPartyContact.lastName" />
                            </div>
                            <div class="col-md-4">
                                <label class="form-label mb-0">Role/Title</label>
                                <input class="form-control" [(ngModel)]="thirdPartyContact.role" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="form-label mb-0">Email</label>
                                <input class="form-control" [(ngModel)]="thirdPartyContact.email"
                                    placeholder="example@domain.com" type="email" trim/>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label mb-0">Home Phone Number</label>
                                <input id="HomePhoneNumber" class="form-control" [mask]="'(000) 000-0000'"
                                [showMaskTyped]="true" placeHolderCharacter="#" [(ngModel)]="thirdPartyContact.homePhoneNumber"/>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label mb-0">Other Phone Number</label>
                                <input id="OtherPhoneNumber" class="form-control" [mask]="'(000) 000-0000'"
                                [showMaskTyped]="true" placeHolderCharacter="#" [(ngModel)]="thirdPartyContact.otherPhoneNumber" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeAppraisal">
            <div *ngIf="!allOrdersCancelled() && appraisals.length > 0">
                <div class="row section mt-2">
                    <div class="col-md-12">
                        <div class="title">Order Details</div>
                    </div>
                    <div class="col-md-12">
                        <div class="row order-details">
                            <div class="col-md-8">
                                <div class="row" *ngIf="activeAppraisal.thirdPartyOrderId">
                                    <div class="col-md-6 key">Order #:</div>
                                    <div class="col-md-6">{{activeAppraisal.thirdPartyOrderId}}</div>
                                </div>
                                <div class="row" *ngIf="activeAppraisal.orderStatus">
                                    <div class="col-md-6 key">Order Status:</div>
                                    <div class="col-md-6">{{activeAppraisal.orderStatus}}</div>
                                </div>
                            </div>
                            <div class="mt-2 row">
                                <button class="btn btn-warning mx-1"
                                    (click)="cancelAppraisalOrder(activeAppraisal.appraisalOrderId)"
                                    [disabled]="cancelingAppraisalOrder" style="width: 120px">
                                    <span *ngIf="!cancelingAppraisalOrder">Cancel Order</span>
                                </button>
                                <button class="btn btn-info mx-1"
                                    (click)="placeAppraisalOrderOnHold(activeAppraisal.appraisalOrderId)"
                                    *ngIf="activeAppraisal.orderStatus !== 'Hold'"
                                    [disabled]="orderHoldStateChanging" style="width: 150px">
                                    <span *ngIf="!orderHoldStateChanging">Place Order on Hold</span>
                                </button>
                                <button class="btn btn-secondary mx-1"
                                    (click)="refreshAppraisalOrder(activeAppraisal.appraisalOrderId)"
                                    *ngIf="activeAppraisal.orderStatus !== 'Hold'" [disabled]="refreshigOrder"
                                    style="width: 200px">
                                    <span *ngIf="!refreshigOrder">Refresh order from loan</span>
                                </button>
                                <button class="btn btn-info mx-1"
                                    (click)="resumeAppraisalOrderFromHold(activeAppraisal.appraisalOrderId)"
                                    *ngIf="activeAppraisal.orderStatus === 'Hold'"
                                    [disabled]="orderHoldStateChanging" style="width: 180px">
                                    <span *ngIf="!orderHoldStateChanging">Resume Order from Hold</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row section mt-2" *ngIf="appraisalErrors && appraisalErrors.length > 0">
            <div class="col-md-12">
                <div class="title">Errors</div>
            </div>

            <div class="col-md-12">
                <div class="row head">
                    <div class="col-md-4">Field</div>
                    <div class="col-md-8">Error</div>
                    <div class="col-md-12">
                        <hr />
                    </div>
                </div>
                <div class="row details" *ngFor="let error of appraisalErrors">
                    <div class="col-md-4" style="text-transform: capitalize">{{error.field}}</div>
                    <div class="col-md-8">{{error.message}}</div>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="tab === 'orderHistory'">
        <div class="row col-md-12">
            <div class="mt-3">
                <div class="col-md-12" style="max-height: 200px; overflow-y: auto;">
                    <table class="table table-bordered table-hover no-footer">
                        <thead>
                            <th>Order #</th>
                            <th>Order Status</th>
                            <th>Request Date</th>
                            <th>Messages</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let appraisal of appraisals">
                                <td>{{appraisal.thirdPartyOrderId}}</td>
                                <td>{{appraisal.orderStatus}}</td>
                                <td>{{appraisal.requestedDate | date:'MM/dd/yy hh:mm a'}}</td>
                                <td><span *ngFor="let msg of appraisal.messages">{{msg}}, </span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="tab === 'appraisalNotes'">
        <div class="row col-md-12">
            <form class="mt-3">
                <table class="table table-bordered table-hover no-footer">
                    <thead>
                        <tr>
                            <th class="col-md-5">Note</th>
                            <th class="col-md-5">Delivery Groups</th>
                            <th class="col-md-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let note of activeAppraisal.notes">
                            <td><input class="form-control" value="{{note.note}}" title="{{note.note}}" disabled /></td>
                            <td><input class="form-control" value="{{note.deliveryGroups}}"
                                    title="{{note.deliveryGroups}}" disabled /></td>
                            <td>
                                <button class="btn btn-danger" (click)="removeAppraisalNote(note.id)"
                                    style="width: 70px">Delete</button>
                            </td>
                        </tr>
                        <tr style="background: white;">
                            <td>
                                <input class="form-control" type="text" [(ngModel)]="newNote" name="appraisalNote" id="apprisal-note--note" required />
                            </td>
                            <td>
                                <select class="form-control" id="apprisal-note--deliveryGroup" name="deliveryGroups" [(ngModel)]="deliveryGroups" required>
                                    <option value="">-- Select One --</option>
                                    <option value="{{group.value}}"
                                        *ngFor="let group of deliveryGroupsEnum">
                                        {{group.name}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button (click)="addAppraisalNote()" class="btn btn-success" style="width: 70px">Add</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
            <div>
                <button class="btn btn-secondary" (click)="updateOrder()">Update Order</button>
            </div>
        </div>
    </div>

    <div *ngIf="tab === 'appraisalDocuments'">
        <div class="row col-md-12">
            <form class="mt-3">
                <table class="table table-bordered table-hover no-footer">
                    <thead>
                        <tr>
                            <th class="col-md-5">Loan Doc</th>
                            <th class="col-md-5">Appraisal Doc Type</th>
                            <th class="col-md-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of activeAppraisal.documents">
                            <td><input class="form-control" value="{{doc.fileName}}" title="{{doc.fileName}}"
                                    disabled /></td>
                            <td><input class="form-control" value="{{doc.appraisalDocumentType}}"
                                    title="{{doc.appraisalDocumentType}}" disabled /></td>
                            <td>
                                <button class="btn btn-danger" (click)="removeAppraisalDoc(doc.id)"
                                    style="width: 70px">Delete</button>
                            </td>
                        </tr>
                        <tr style="background: white;">
                            <td>
                                <select id="appraisal-loan-doc" class="form-control" name="newLoanDoc" [(ngModel)]="newLoanDocId" required>
                                    <option value="">-- Select One --</option>
                                    <option value="{{doc.loanDocId}}" *ngFor="let doc of loanDocs"
                                        title="{{doc.description}}">{{doc.description}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-control" id="appraisal-appraisal-doc" name="newAppraisalDoc" [(ngModel)]="newAppraisalDocType" required>
                                    <option value="">-- Select One --</option>
                                    <option value="{{type.value}}"
                                        *ngFor="let type of appraisalDocTypesEnum"
                                        title="{{type.name}}">
                                        {{type.name}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <button class="btn btn-success" (click)="addAppraisalDoc()" style="width: 70px">Add</button>
                            </td>
                    </tbody>
                </table>
            </form>
        </div>
    </div>

    <div *ngIf="tab === 'appraisalConditions'">
        <div class="row col-md-12">
            <form class="mt-3" *ngIf="!conditionsLoading">
                <table class="table table-bordered table-hover no-footer">
                    <thead>
                        <tr>
                            <th class="col-md-5">Status</th>
                            <th class="col-md-5">Category</th>
                            <th class="col-md-5">Value</th>
                            <th class="col-md-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let condition of conditions">
                            <td>
                                <input class="form-control" value="{{condition.status}}" title="{{condition.status}}" disabled />
                            </td>
                            <td>
                                <input class="form-control" value="{{condition.conditionCategoryName}}" title="{{condition.conditionCategoryName}}" disabled />
                            </td>
                            <td>
                                <input class="form-control" value="{{condition.conditionText}}" title="{{condition.conditionText}}" disabled />
                            </td>
                            <td class="text-center">
                                <a *ngIf="(condition.thirdPartyConditionId < 0 ||
                                    (condition.status === 'Pending' || condition.status === 'Requested')) &&
                                    actionInProgressForId != condition.thirdPartyConditionId"
                                    class="dropdown-toggle" data-bs-toggle="dropdown">
                                    <i class="menu fa fa-ellipsis-v fa-lg" aria-hidden="true"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" (click)="approveCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId > 0 &&
                                            (condition.status === 'Pending' || condition.status === 'Requested')">
                                        Approve
                                    </a>
                                    <a class="dropdown-item" (click)="denyCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId > 0 &&
                                            (condition.status === 'Pending' || condition.status === 'Requested')">
                                        Deny
                                    </a>
                                    <a class="dropdown-item" (click)="cancelCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId > 0 &&
                                            (condition.status === 'Pending' || condition.status === 'Requested')">
                                        Cancel
                                    </a>
                                    <a class="dropdown-item" (click)="removeCondition(condition.thirdPartyConditionId)"
                                        *ngIf="condition.thirdPartyConditionId < 0">
                                        Remove
                                    </a>
                                </div>
                                <div class="dropdown" *ngIf="actionInProgressForId === condition.thirdPartyConditionId">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </div>
                            </td>
                        </tr>
                        <tr style="background: white;">
                            <td>
                                <input class="form-control" value="New" title="'New'" disabled />
                            </td>
                            <td>
                                <select class="form-control" [(ngModel)]="newCondition.conditionCategory" name="conditionCategory">
                                    <option value="">-- Select One --</option>
                                    <option value="{{category.value}}"
                                        *ngFor="let category of appraisalCategoriesEnum">
                                        {{category.name}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <input class="form-control" [(ngModel)]="newCondition.conditionText" name="newCondition" type="text"/>
                            </td>
                            <td>
                                <button class="btn btn-success" (click)="addAppraisalCondition(newCondition)"
                                    style="width: 70px">Add</button>
                            </td>
                    </tbody>
                </table>
            </form>
            <div *ngIf="!conditionsLoading">
                <button class="btn btn-primary" type="button" (click)="saveAppraisalCondition()"
                        [disabled]="savingAppraisalCondition || !hasNewConditions">
                        <span *ngIf="!savingAppraisalCondition">Save</span>
                        <span *ngIf="savingAppraisalCondition" class="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true">
                        </span>
                    </button>
            </div>
            <div *ngIf="conditionsLoading">
                <loading-indicator></loading-indicator>
            </div>
        </div>
    </div>

</div>
