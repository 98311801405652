<ngx-spinner bdColor="rgba(51,51,51,0.3)" size="large" color="#fff" type="ball-scale-multiple"
  [fullScreen]="false" [name]="alertsSpinner">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>

<div class="container-fluid box-shadow">
  <div class="row d-flex align-items-center">
    <div class="col-md-12 p-2 " style="border-bottom:1px solid #d3ddeb!important">
      <ng-multiselect-dropdown
        name="filters"
        [placeholder]="'-- Select Filters --'"
        [settings]="filtersMultiSelectSettings"
        [data]="availableBucketsForFiltering"
        [(ngModel)]="selectedAlertBuckets"
        (ngModelChange)="onFilterChanged()">
      </ng-multiselect-dropdown>
    </div>
  </div>
</div>
  <div class="notification-menu inset-box-shadow emails-list p-1" data-simplebar="init" [class.n-overflow]="!modal" (scroll)="onScroll($event)">
    <div class="px-2 pt-1 d-flex justify-content-end" *ngIf="notifications?.length > 0">
      <button (click)="clearAll()" class="btn btn-link text-danger" [disabled]="cleaningAll">
        <span *ngIf="!cleaningAll; else clearingSpinner">Clear All</span>
        <ng-template #clearingSpinner>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Clearing...
        </ng-template>
      </button>
    </div>
    <div class="p-2" *ngIf="notifications">
        <div class="card email-notification-card" *ngFor="let notification of notifications">
          <div class="card-header">
              <div class="d-flex justify-content-between align-items-end pt-0 px-0 mx-0 mt-0">
                <h5 class="mt-0 mb-1" [innerHtml]="notification.alertText"></h5>
                <div class="">
                  <button type="button" *ngIf="notification.alertType === 'FailedEmailAlert'"
                    class="btn btn-soft-primary btn-sm mr-1" (click)="resendEmail(notification)"
                    style="margin-right: 6px;">
                    Resend
                  </button>
                  <button type="button" *ngIf="notification.alertType === 'FailedEmailAlert' || notification.alertType === 'FailedSmsAlert'"
                    class="btn btn-link btn-sm" title="Show Details" (click)="openDetailsDialog(notification)">
                    <i class="fas fa-eye text-info font-14 icon"></i>
                  </button>
                  <button type="button" *ngIf="notification.alertType !== 'FailedEmailAlert'"
                    class="btn btn-link btn-sm" title="Go To" (click)="gotoDetails(notification)">
                    <i class="fas fa-share text-success font-14 icon"></i>
                  </button>
                  <button type="button" class="btn btn-link btn-sm" title="Remove" (click)="deleteAlert(notification, false)" [disabled]="notification['deleting'] || cleaningAll">
                    <ng-container *ngIf="!notification['deleting']; else notifyDeleteSpinner">
                      <i class="fa fa-trash-alt cursor btn-danger-icon text-danger" title="Delete"></i>
                    </ng-container>
                    <ng-template #notifyDeleteSpinner>
                      <span class="text-danger spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </ng-template>
                  </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-inline">
              <p class="text-muted mb-1"><i class="far fa-user me-2 text-primary font-14 icon"></i> <strong>Borrower: </strong>{{notification.borrowerName}}</p>
            </div>
            <div class="d-inline">
              <p class="text-muted mb-1"><i class="fas fa-map-marker-alt me-2 text-success font-14 icon"></i><strong>Property Address: </strong>{{notification.propertyAddress}}</p>
            </div>
            <div class="d-inline">
              <p class="text-muted mb-1"><i class="far fa-file me-2 text-success font-14 icon"></i><strong>Current Loan Status: </strong>{{notification.applicationStatus}}</p>
            </div>
          </div>
          <div class="card-footer">
            <p class="text-muted mb-1">By <b>{{notification.userName}}</b> at <small class="text-muted">{{notification.createDate | date:'short' }}</small></p>
          </div>
        </div>
    </div>
    <div class="card-body border-bottom-dashed" *ngIf="!notifications.length">
      <div class="earning-data text-center">
        <div class="text-center my-2">
          <p class="text-muted font-15 mb-4">No data found</p>
        </div>
      </div>
    </div>
  </div>
  <!-- All-->
  <!--<div class="modal-footer justify-content-center">
            <a href="javascript:void(0);" (click)="viewAll()" *ngIf="!modal" class="py-2">
                View all <i class="fi-arrow-right"></i>
            </a>
        </div>-->
