import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoanCreditComponent } from './components/loan-credit/loan-credit.component';
import { LoanCreditRunComponent } from './components/loan-credit-run/loan-credit-run.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreditModule } from '../credit/credit.module';

@NgModule({
  declarations: [
    LoanCreditComponent,
    LoanCreditRunComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CreditModule
  ],
  exports: [
    LoanCreditComponent
  ],
})
export class LoanCreditModule { }
