import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddBorrowerComponent } from './components/add-borrower/add-borrower.component';
import { BorrowerDetailsComponent } from './components/borrower-details/borrower-details.component';
import { BorrowerEditorComponent } from './components/borrower-editor/borrower-editor.component';
import { SelectBorrowerComponent } from './components/select-borrower/select-borrower.component';
import { UpsertApplicationComponent } from './components/upsert-application/upsert-application.component';

const routes: Routes = [
  {
    path: '',
    component: BorrowerEditorComponent,
  },
  {
    path: 'details/:borrowerId',
    component: BorrowerEditorComponent
  },
  {
    path: ':borrowerId',
    component: BorrowerDetailsComponent,
  },
  {
    path: 'upsert-application',
    component: UpsertApplicationComponent,
  },
  {
    path: 'select-borrower/:borrowerId',
    component: SelectBorrowerComponent,
  },
  {
    path: 'add-borrower',
    component: AddBorrowerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BorrowerRoutingModule { }
