import { Component, Input, Output, Injector, EventEmitter, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LoanApplication } from 'src/app/models';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { ThirdPartyFeeOptions } from 'src/app/models/fee/third-party-fees-options.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FeeComparisonComponent, FeeCompilation } from './fee-comparison/fee-comparison.component';
import { FinalFeeSummaryComponent } from './final-fee-summary/final-fee-summary.component';
import { ImportFeesFromVendorComponent } from './import-fees-from-vendor/import-fees-from-vendor.component';
import { ImportLoanCalculatedFeesComponent } from './import-loan-calculated-fees/import-loan-calculated-fees.component';
import { SelectFeeTemplateComponent } from './select-fee-template/select-fee-template.component';

@Component({
  selector: 'fee-wizard',
  templateUrl: 'fee-wizard.component.html',
  styleUrls: ['fee-wizard.component.scss']
})
export class FeeWizardComponent extends ApplicationContextBoundComponent implements AfterViewInit, OnInit {

  @ViewChild('feeComparisonTable')
  feeComparisonTable: FeeComparisonComponent;

  @ViewChild('selectTemplateStep')
  selectTemplateStep: SelectFeeTemplateComponent;

  @ViewChild('calculateLoanFeesStep')
  calculateLoanFeesStep: ImportLoanCalculatedFeesComponent;

  @ViewChild('getFeesFromVendorStep')
  getFeesFromVendorStep: ImportFeesFromVendorComponent;

  @ViewChild('feeSummary')
  feeSummary: FinalFeeSummaryComponent;

  @Input()
  feeTemplates: FeeTemplate[] = [];

  @Input()
  supportedVendorNames: string[] = [];

  @Input()
  set existingLoanFees(existingLoanFees: LoanFee[]) {
    this.allFees.existingFees = existingLoanFees.filter(lf => !lf.isSummaryFee);
  }

  @Output()
  navigationStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  wizardCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  wizardCompleted: EventEmitter<LoanFee[]> = new EventEmitter<LoanFee[]>();

  application: LoanApplication;

  steps: MenuItem[];

  activeStepIndex: number = 0;

  atFirstStep: boolean = true;

  atLastStep: boolean = false;

  nextButtonLabel: string = "Skip >";

  autoCalculateLoanFees: boolean = true;

  isStepRunning: boolean = false;

  allFees: FeeCompilation;
  selectedFees: LoanFee[] = null;

  isWizardCompleted: boolean = false;

  selectTemplateStepIndex = 0;
  calculateLoanFeesStepIndex = 1;
  getVendorFeesStepIndex = 2;
  reviewStepIndex = 3;
  summaryAndApplyStepIndex = 4;

  isVendorFeeQuestionnaireRunning: boolean = false;

  disableNextButton: boolean = false;

  isVendorFeesSkipVisible = true;

  protected selectedVendorName: string;

  constructor(
    private readonly injector: Injector
  ) {
    super(injector);
    this.allFees = new FeeCompilation();
    this.applicationContextService.context.subscribe((context) => {
      this.application = context.application;
    });
  }

  ngOnInit() {
    this.steps = [
      { label: 'Select Fee Template' },
      { label: 'Calculate Fees from Loan' },
      { label: 'Get Fees from Vendor' },
      { label: 'Review & Pick Fees' },
      { label: 'Summary' },
    ];

    if (
      !this.feeTemplates ||
      !this.feeTemplates.length
    ) {
      this.steps.splice(0, 1);
      this.selectTemplateStepIndex = null;
      this.calculateLoanFeesStepIndex = 0;
      this.getVendorFeesStepIndex = 1;
      this.reviewStepIndex = 2;
      this.summaryAndApplyStepIndex = 3;
    }
    this.updateCurrentStepRelatedState();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setNextButtonLabel();
    });
  }

  onCancelClicked = () => {
    this.wizardCancelled.emit();
  }

  navigateForward = () => {
    if (this.activeStepIndex < 4) {
      this.activeStepIndex++;
    }
    this.updateCurrentStepRelatedState();
  };

  navigateBackward = () => {
    this.autoCalculateLoanFees = true;
    if (this.activeStepIndex > 0) {
      if (this.activeStepIndex === this.getVendorFeesStepIndex) {
        this.autoCalculateLoanFees = false;
      }
      this.activeStepIndex--;
    }
    this.updateCurrentStepRelatedState();
  };

  onBackClicked = () => {
    // KAAN - Not sure if the line below is necessary
    //this.isVendorFeesSkipVisible = true;
    this.selectedVendorName = null;
    this.isVendorFeeQuestionnaireRunning = false;
    this.disableNextButton = false;
    this.navigateBackward();
  }

  onNextClicked = () => {
    if (this.activeStepIndex === this.selectTemplateStepIndex) {
      this.selectedFees = null;
      this.allFees.templateFees = [];
      if (this.selectTemplateStep.selectedTemplate) {
        this.isStepRunning = true;
        this.selectTemplateStep.getTemplateFees();
        return;
      }
    }
    if (this.activeStepIndex === this.calculateLoanFeesStepIndex) {
      this.isStepRunning = true;
      this.calculateLoanFeesStep.calculateFeesForLoan();
      return;
    }
    if (this.activeStepIndex === this.getVendorFeesStepIndex) {
      this.selectedFees = null;
      this.allFees.vendorFees = [];
      if (this.getFeesFromVendorStep.selectedVendorName) {
        if (!this.isVendorFeeQuestionnaireRunning) {
          this.isVendorFeeQuestionnaireRunning = true;
          this.nextButtonLabel = "Skip >";
          this.getFeesFromVendorStep.startVendorQuestionnaire();
          return;
        }
      }
    }
    if (this.activeStepIndex === this.reviewStepIndex) {
      this.selectedFees = [...this.feeComparisonTable.selectedFees];
    }
    if (this.activeStepIndex === this.summaryAndApplyStepIndex) {
      this.isStepRunning = true;
      this.feeSummary.applySelectedFeesToLoan();
      return;
    }
    this.navigateForward();
  }

  onNavigationStatusChanged = () => {
    this.updateCurrentStepRelatedState();
  }

  onCloseClicked = () => {

  }

  onStepExecutionStarted = () => {
    this.isStepRunning = true;
  }

  onStepExecutionCompleted = () => {
    this.isStepRunning = false;
    this.nextButtonLabel = "Skip >";
    if (this.activeStepIndex === this.getVendorFeesStepIndex) {
      this.isVendorFeesSkipVisible = false;
    }
  }

  onMakeVendorFeesSkipVisible = () => {
    this.isVendorFeesSkipVisible = true;
  }

  onFeeTemplateSelected = (templateFees: LoanFee[]) => {
    this.selectedFees = null;
    this.allFees.templateFees = templateFees;
    this.allFees = { ...this.allFees };
    this.navigateForward();
  };

  onLoanFeesCalculated = (calculatedLoanFees: LoanFee[]) => {
    this.allFees.calculatedFees = calculatedLoanFees;
    this.allFees = { ...this.allFees };
    this.navigateForward();
  };

  onVendorFeesRetrieved = (vendorFees: LoanFee[]) => {
    this.allFees.vendorFees = vendorFees;
    this.allFees = { ...this.allFees };
    this.disableNextButton = false;
    this.navigateForward();
  }

  onVendorQuestionsRetrieved = (questions: ThirdPartyFeeOptions) => {
    this.isStepRunning = false;
    this.disableNextButton = true;
  }

  onFeesAppliedToLoan = (appliedFees: LoanFee[]) => {
    this.isStepRunning = false;
    this.isWizardCompleted = true;
    this.wizardCompleted.emit(appliedFees);
  }

  onFeeTemplateSelectionChanged = (selectedTemplate: FeeTemplate) => {
    if (selectedTemplate) {
      this.nextButtonLabel = "Select Template and Continue >";
    } else {
      this.nextButtonLabel = "Skip >";
    }
  }

  onVendorSelectionChanged = (selectedVendorName: string) => {
    this.selectedVendorName = selectedVendorName;
    this.isVendorFeeQuestionnaireRunning = false;
    this.setNextButtonLabel();
  }

  private updateCurrentStepRelatedState = () => {
    this.atFirstStep = this.activeStepIndex === 0;
    this.atLastStep = this.activeStepIndex === this.summaryAndApplyStepIndex;
    this.setNextButtonLabel();
    this.navigationStatusChanged.emit();
  }

  private setNextButtonLabel = () => {
    if (this.activeStepIndex === this.selectTemplateStepIndex) {
      if (this.selectTemplateStep && this.selectTemplateStep.selectedTemplate) {
        this.nextButtonLabel = "Select Template and Continue >";
      } else {
        this.nextButtonLabel = "Skip >";
      }
    }

    if (this.activeStepIndex === this.calculateLoanFeesStepIndex) {
      this.nextButtonLabel = "Next >";
    }

    if (this.activeStepIndex === this.getVendorFeesStepIndex) {
      if (this.selectedVendorName) {
        this.nextButtonLabel = `Get Fees from ${this.selectedVendorName} >`;
      } else {
        this.nextButtonLabel = "Skip >";
      }
    }

    if (this.activeStepIndex === this.reviewStepIndex) {
      this.nextButtonLabel = "Next >"
    }

    if (this.activeStepIndex === this.summaryAndApplyStepIndex) {
      this.nextButtonLabel = "Apply Selected Fees & Finish";
    }
  }
}
