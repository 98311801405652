import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as ClassicEditor from 'src/app/shared/utils/ckeditor5/classic/build/ckeditor';
import { Mentionable } from './mentionable.model';

@Component({
  selector: 'message-editor-with-mentions',
  templateUrl: 'message-editor-with-mentions.component.html',
  styleUrls: ['message-editor-with-mentions.component.scss']
})

export class MessageEditorWithMentionsComponent implements OnInit, AfterViewInit {

  @ViewChild('messageEditorComponent')
  messageEditorComponent: any;

  @Output()
  messageChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  enterHit: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled: boolean = false;

  @Input()
  mentionables: Mentionable[] = [];

  @Input()
  placeholder: string = 'To notify a user, simply type @ and select them from the list.';

  messageToSend: string = "";

  messageEditor = ClassicEditor;

  messageEditorConfig: any = {};

  constructor(private readonly _elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.messageEditorConfig = {
      placeholder: this.placeholder,
      mention: {
        feeds: [
          {
            marker: '@',
            itemRenderer: this.customItemRenderer,
            feed: this.getFeedItems
          }
        ]
      }
    }
  }

  reset = () => {
    this.messageToSend = "";
  }

  private customizeMessageEditor = (editor: ClassicEditor) => {
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'a',
        key: 'data-mention',
        classes: 'mention',
        attributes: {
          'data-user-id': true,
        },
      },
      model: {
        key: 'mention',
        value: (viewItem) => editor.plugins.get('Mention').toMentionAttribute(viewItem),
      },
      converterPriority: 'high',
    });

    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue, { writer }) => {

        if (!modelAttributeValue) {
          return;
        }

        //eslint-disable-next-line consistent-return
        let anchor = writer.createAttributeElement('a', {
          class: 'mention',
          'data-user-id': modelAttributeValue.mentionCode
        }, {
          priority: 20,
          id: modelAttributeValue.name,
        });
        return anchor;
      },
      converterPriority: 'high',
    });
  }

  onEditorReady(editor: ClassicEditor) {
    const dom: HTMLElement = this._elementRef.nativeElement;
    const ckToolbar = dom.querySelector('.ck-toolbar') as HTMLElement;
    ckToolbar.style.display = "none";
    this.customizeMessageEditor(editor);
    if (this.disabled) {
      editor.enableReadOnlyMode('message-editor');
    } else {
      editor.disableReadOnlyMode('message-editor');
    }
    const self = this;
    editor.editing.view.document.on('keydown', (e, data) => {
      if (data.keyCode === 13) {
        self.enterHit.emit();
      }
    })
  }

  onMessageToSendChanged = () => {
    if (!this.messageEditorComponent?.editorInstance) {
      return;
    }
    //replace the users mentions from data-user-id attrib to tackle users with duplicate names.
    const placeholderEl = document.createElement('div');
    placeholderEl.innerHTML = this.messageEditorComponent.editorInstance.getData();
    const allAnchors = placeholderEl.querySelectorAll('a');
    allAnchors.forEach(x => {
      x.innerHTML = x.getAttribute('data-user-id');
    });
    const { convert } = require('html-to-text');
    let plainTextMessage: string = convert(placeholderEl.innerHTML);
    this.messageChanged.emit(plainTextMessage);
  }

  private getFeedItems = (queryText: string): Mentionable[] => {
    const itemsToDisplay = this.mentionables
      // Filter out the full list of all items to only those matching the query text.
      .filter(isItemMatching)
      // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
      .slice(0, 10);
    return itemsToDisplay;

    function isItemMatching(item: Mentionable) {
      // Make the search case-insensitive.
      const searchString = queryText.toLowerCase();

      return (
        item.name.toLowerCase().includes(searchString) ||
        item.id.toLowerCase().includes(searchString)
      );
    }
  }

  private customItemRenderer = (mentionable: Mentionable) => {
    const itemElement = document.createElement('span');
    const avatar = document.createElement('img');
    const userNameElement = document.createElement('span');
    const fullNameElement = document.createElement('span');

    itemElement.classList.add('mention__item');

    avatar.src = mentionable.avatarUrl;
    avatar.classList.add('rounded-circle');
    avatar.classList.add('thumb-sm');

    userNameElement.classList.add('mention__item__user-name');
    userNameElement.textContent = mentionable.name;

    fullNameElement.classList.add('mention__item__full-name');
    fullNameElement.textContent = mentionable.id;

    itemElement.appendChild(avatar);
    itemElement.appendChild(userNameElement);
    itemElement.appendChild(fullNameElement);

    return itemElement;
  }
}
