<div class="modal-header">
  <h5 class="modal-title"> Pricing Validity Check </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <p-table #dt1 [value]="pricingValidation.items" [paginator]="true" [rows]="25" *ngIf="pricingValidation.items"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th> Pricing Field </th>
          <th> Loan Value </th>
          <th> Assigned Pricing Value </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-validationItem>
        <tr>
          <td> {{validationItem.pricingField}} </td>
          <td> {{validationItem.loanValue}} </td>
          <td [ngClass]="{'highlight-pricing': validationItem.hasChanged}"> {{validationItem.assignedValue}} </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
      </div>
    </div>
  </div>
</div>