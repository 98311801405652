export class AuthenticationRequest {
  companyGuid: string = "";
  username: string = "";
  password: string = "";
  rememberMe: boolean = false;
  twoFactorCode: string;
  scope: string;
  userCompanyGuid: string = "";
  usernameValidationOnly: boolean = false;
}
