<div class="modal-body modal-body--scroll" id="lead-editor-main">
  <form [formGroup]="agentInfoForm">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="card-title">Basic Information</h4>
        <enable-sms-alerts-toggle
          [type]="'Agent'"
          [phone]="agentFull.agent.mobilePhone"
          [enableSms]="agentFull.agent.alertViaSms"
          (enableSmsToggled)="onEnableSmsToggled($event)"
        ></enable-sms-alerts-toggle>
      </div>
      <div class="card-body">

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="firstName">First Name</label>
              <div class="col-md-9">
                <input class="form-control" name="firstName" maxlength="100"
                  [ngClass]="{'is-invalid': firstNameControl && firstNameControl.touched && firstNameControl.invalid}"
                  [formControl]="firstNameControl" required />
                <div class="invalid-feedback" for="firstName">This field is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="lastName">Last Name</label>
              <div class="col-md-9">
                <input class="form-control" name="lastName" maxlength="100"
                  [ngClass]="{'is-invalid': lastNameControl && lastNameControl.touched && lastNameControl.invalid}"
                  [formControl]="lastNameControl" required>
                <div class="invalid-feedback" for="lastName">This field is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-1 col-md-3 col-form-label">Mobile Phone
              </label>
              <div class="col-sm-11 col-md-9">
                <div class="d-flex">
                  <input class="form-control flex-grow-1" placeholder="mobilePhone" [mask]="'(000) 000-0000'"
                    [showMaskTyped]="true" name="mobilePhone" [(ngModel)]="agentFull.agent.mobilePhone"
                    [ngModelOptions]="{standalone: true}" />
                  <a href="tel:{{ agentFull.agent.mobilePhone }}" class="btn btn-outline-secondary"
                    style="margin-left: 3px;" *ngIf="!dialerEnabled">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <a class="btn btn-outline-secondary" style="margin-left: 3px;" *ngIf="dialerEnabled"
                    (click)="dial(agentFull.agent.mobilePhone, 'mobile')">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <button class="btn btn-outline-secondary" style="margin-left: 3px;"
                    *ngIf="applicationContext.userPermissions.smsAlertsEnabled"
                    (click)="onOpenSmsChat(agentFull.agent, agentFull.agent.mobilePhone)">
                    <i class="float-end far fa-comment-dots fa-comment-style"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-1 col-md-3 col-form-label">Work Phone
              </label>
              <div class="col-sm-8 col-md-6">
                <div class="d-flex">
                  <input class="form-control flex-grow-1" placeholder="workPhone" [mask]="'(000) 000-0000'"
                    [showMaskTyped]="true" name="workPhone" [(ngModel)]="agentFull.agent.workPhone"
                    [ngModelOptions]="{standalone: true}" />
                  <a href="tel:{{ agentFull.agent.workPhone }};{{ agentFull.agent.extPhone }}"
                    class="btn btn-outline-secondary" style="margin-left: 3px;" *ngIf="!dialerEnabled">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <a class="btn btn-outline-secondary" style="margin-left: 3px;" *ngIf="dialerEnabled"
                    (click)="dial(agentFull.agent.workPhone, 'work')">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <button class="btn btn-outline-secondary" style="margin-left: 3px;"
                    *ngIf="applicationContext.userPermissions.smsAlertsEnabled"
                    (click)="onOpenSmsChat(agentFull.agent, agentFull.agent.workPhone)">
                    <i class="float-end far fa-comment-dots fa-comment-style"></i>
                  </button>
                </div>
              </div>
              <div class="col-sm-3 col-md-3">
                <input class="form-control" placeholder="Ext" name="extPhone" [(ngModel)]="agentFull.agent.extPhone"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="faxPhone">Fax Phone</label>
              <div class="col-md-9">
                <input class="form-control" placeholder="Fax" [mask]="'(000) 000-0000'" [showMaskTyped]="true"
                  name="fax" [(ngModel)]="agentFull.agent.fax" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="email">E-Mail</label>
              <div class="col-md-9">
                <div class="d-flex">
                  <input class="form-control" name="email" type="email" trim
                    [ngClass]="{'is-invalid': emailControl && emailControl.touched && emailControl.errors?.email}"
                    [formControl]="emailControl" email />
                  <a class="btn btn-outline-secondary" style="margin-left: 3px;"
                    [href]="'mailto:' + agentFull.agent.email | safeUrl">
                    <i class="float-end far fa-envelope fa-email-style"></i>
                  </a>
                </div>
                <div class="invalid-feedback d-block"
                  *ngIf="emailControl && emailControl.touched && emailControl.errors?.email">
                  Please enter a valid email address
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="agentContact">Agent Contact</label>
              <div class="col-md-9">
                <select class="form-select" id="agentContact" name="agentContact" #agentContactUserId="ngModel"
                  [(ngModel)]="agentFull.agent.agentContactUserId" [ngModelOptions]="{standalone: true}">
                  <option [ngValue]="null">--Select One--</option>
                  <option [ngValue]="user.userCompanyGuid" *ngFor="let user of users">
                    {{ user.lastName }}, {{ user.firstName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="agentVisibility">Agent Visibility</label>
              <div class="col-md-9">
                <select class="form-select col-md-6" id="agentVisibility" name="agentVisibility"
                  #agentVisibility="ngModel" [(ngModel)]="agentFull.agent.agentVisibility"
                  [ngModelOptions]="{standalone: true}">
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row" *ngIf="agentFull.agent.dateUpdated">
              <label class="col-md-3 col-form-label" for="dateUpdated">Last Modified Date</label>
              <label class="col-md-9 col-form-label text-start" name="dateUpdated">{{agentFull.agent.dateUpdated |
                date:'MMM d, y h:mm:ss a'}}</label>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="birthDate">Date of Birth</label>
              <div class="col-md-9">
                <date-input
                  [(ngModel)]='birthDate' [name]="'agent_birthDate'"
                  [id]="'agent_birthDate'" placeholder='mm/dd/yyyy'
                  [ngModelOptions]="{standalone: true}"
                ></date-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="nmlsId">Agent License #</label>
              <div class="col-md-9">
                <input class="form-control" placeholder="Agent License #" name="nmlsId" maxlength="40"
                  [(ngModel)]="agentFull.agent.nmlsId" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="portalUserName">Portal Status</label>
              <div class="col-md-3">
                <p class="col-form-label label-info pt-0" style="font-weight: bolder; text-align: left"
                  *ngIf="!agentFull.portalUserName && !agentFull.agent?.agentId">
                  Missing Username
                </p>
                <p class="col-form-label label-info pt-0" style="font-weight: bolder; text-align: left"
                  *ngIf="agentFull.agent?.agentId && agentFull.accountStatus === 'NotInvited'">
                  Not Invited
                  <span *ngIf="!currentPortalUserName" class="ms-1 cursor--help" placement="top"
                    [ngbTooltip]="missingUserNameTooltip" container="body">
                    <i class="fas fa-question-circle text-info"></i>
                  </span>
                  <ng-template #missingUserNameTooltip>
                    To send an invite please make sure that portal username exists and then save.
                  </ng-template>
                </p>
                <ng-container
                  *ngIf="currentPortalUserName && currentPortalUserName == agentFull.portalUserName && agentFull.accountStatus !== 'NotInvited'">
                  <p class="col-form-label label-warning" style="font-weight: bolder; text-align: left"
                    *ngIf="agentFull.accountStatus == 'Invited' && agentFull.portalUserName">
                    Not Confirmed
                  </p>
                  <p class="col-form-label label-success" style="font-weight: bolder; text-align: left"
                    *ngIf="agentFull.accountStatus == 'Live'  && agentFull.portalUserName">
                    Live
                  </p>
                </ng-container>
                <p class="col-form-label label-info pt-0" style="font-weight: bolder; text-align: left"
                  *ngIf="agentFull.portalUserName && currentPortalUserName != agentFull.portalUserName">
                  Save Change Before Sending Invite
                </p>
              </div>
              <div class="col-md-6 text-end">
                <button type="button" class="btn btn-primary"
                  [disabled]="agentFull.portalUserName == agentFull.agent.email" (click)="onCopyFromEmailClicked()">
                  Copy From Email
                </button>
                <button type="button" class="btn btn-primary ms-1"
                  [disabled]="sendingInvite || agentFull.accountStatus != 'Invited' || currentPortalUserName != agentFull.portalUserName"
                  (click)="sendInviteAgent(agentFull.agent.agentId)">
                  <span *ngIf="sendingInvite" class="spinner-border spinner-border-sm me-1" role="status"
                    aria-hidden="true"></span>
                  Resend Invite
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="agentBranch">Branch</label>
              <div class="col-md-9">
                <select class="form-select" id="agentBranch" name="agentBranch"
                  [(ngModel)]="agentFull.agent.branchId" [ngModelOptions]="{standalone: true}">
                  <option [ngValue]="null">--Select One--</option>
                  <option [ngValue]="branch.branchId" *ngFor="let branch of branches">
                    {{ branch.branchName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label"></label>
              <div class="col-sm-9">
                <h5>Tags</h5>
                <agent-lists [agent]="agentFull.agent" [agentType]="agentType"
                  [isAgentTypeRequired]="isAgentTypeRequired" [agentListIdsControl]="agentListIdsControl"></agent-lists>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Additional Information</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Title</label>
              <div class="col-sm-9">
                <input class="form-control" name="title" [(ngModel)]="agentFull.agent.title"
                  [ngModelOptions]="{standalone: true}" maxlength="100"/>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Department</label>
              <div class="col-sm-9">
                <input class="form-control" name="department" maxlength="100"
                  [(ngModel)]="agentFull.agent.department" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Company Name</label>
              <div class="col-sm-9">
                <input class="form-control" name="orgName" maxlength="100"
                  [(ngModel)]="agentFull.agent.orgName" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Assistant Name</label>
              <div class="col-sm-9">
                <input class="form-control" name="assistantName" maxlength="100"
                  [(ngModel)]="agentFull.agent.assistantName" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Assistant Email</label>
              <div class="col-sm-9">
                <input class="form-control" type="email" name="assistantEmail"
                  #assistantEmail="ngModel" trim
                  [ngClass]="{'is-invalid': assistantEmail && assistantEmail.touched && assistantEmail.invalid}" email
                  [(ngModel)]="agentFull.agent.assistantEmail" [ngModelOptions]="{standalone: true}" />
                <div class="invalid-feedback d-block"
                  *ngIf="assistantEmail && assistantEmail.touched && assistantEmail.errors && assistantEmail.errors.email">
                  Please enter a valid email address
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Assistant Phone</label>
              <div class="col-sm-9">
                <input class="form-control" [mask]="'(000) 000-0000'"
                  [showMaskTyped]="true" name="assistantPhone" [(ngModel)]="agentFull.agent.assistantPhone"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Do Not Email</label>
              <div class="col-sm-9 d-flex align-items-center">
                <input class="form-check-input" type="checkbox" name="doNotEmail"
                  [(ngModel)]="agentFull.agent.doNotEmail" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-3 col-form-label">Do Not Call</label>
              <div class="col-sm-9 d-flex align-items-center">
                <input class="form-check-input" type="checkbox" name="doNotCall" [(ngModel)]="agentFull.agent.doNotCall"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Address Information</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingStreet">Address</label>
              <div class="col-md-9">
                <address-autocomplete-input
                  #address='ngModel'
                  (addressChange)='onAddressChange($event)'
                  [(ngModel)]='agentFull.agent.mailingStreet'
                  [ngModelOptions]='{standalone: true}'
                  name='mailingStreet'
                  placeholder='Number and Street'
                ></address-autocomplete-input>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingPOBox">Address 2</label>
              <div class="col-md-9">
                <input class="form-control" name="mailingPOBox" #address="ngModel"
                  [(ngModel)]="agentFull.agent.mailingPOBox" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingCity">City</label>
              <div class="col-md-9">
                <input class="form-control" name="mailingCity" [(ngModel)]="agentFull.agent.mailingCity"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingCity">State</label>
              <div class="col-md-9">
                <select class="form-select" id="stateField" name="stateField" [name]="'stateField'"
                  #stateField="ngModel" [(ngModel)]="agentFull.agent.mailingState"
                  [ngModelOptions]="{standalone: true}">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let state of states" value="{{ state.value }}">
                    {{ state.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingZip">ZIP Code</label>
              <div class="col-md-9">
                <zip-code-input [(ngModel)]="agentFull.agent.mailingZip" [name]="'mailingZip'"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'mailing')"
                  [ngModelOptions]="{standalone: true}">
                </zip-code-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer w-100">
  <div class="d-flex justify-content-end">
    <button class="btn btn-secondary me-2" (click)="closeWindow()">
      Cancel
    </button>
    <button *ngIf="!agentFull.agent?.agentId" class="btn btn-primary me-2" type="button"
      (click)="onSaveAndInviteAgent()" [disabled]="!agentFull.agent?.email || emailControl.errors?.email">
      <i class="fa fa-save"></i>
      Save & Invite
    </button>
    <button class="btn btn-primary me-2" type="button" (click)="saveAgentData(false)">
      <i class="fa fa-save"></i>
      Save
    </button>
    <button *ngIf="!hideSaveCloseButton" class="btn btn-primary me-2" type="button" (click)="saveAgentData(true)">
      <i class="fa fa-save"></i>
      Save & Close
    </button>
  </div>
</div>
