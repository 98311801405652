import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { Company } from "src/app/models";
import { CompanyLicense } from "../models/company-license.model";
import { CompanySettings } from "../models/company-settings.model";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private readonly _dataService: DataService) { }

  getAllCompanies = (): Observable<Company[]> => {
    const url = `api/Admin/AllCompanyModel`
    return this._dataService.get(url)
  }

  deleteCompany = (companyId: number): Observable<any> => {
    const url = `api/Admin/DeleteCompanyModel/${companyId}`;
    return this._dataService.delete(url);
  }

  insertCompanySettings = (data: CompanySettings): Observable<Company> => {
    const url = `api/Admin/InsertCompanyModel`;
    return this._dataService.post(url, data)
  }

  updateCompanySettings = (data: CompanySettings): Observable<CompanySettings> => {
    const url = `api/Admin/UpdateCompanyModel`;
    return this._dataService.post(url, data)
  }

  getCompanySettings = (companyId: number): Observable<CompanySettings> => {
    const url = `api/Admin/CompanySettings/${companyId}`
    return this._dataService.get(url)
  }

  // company licencing
  getAllCompanyLicenses = (): Observable<CompanyLicense[]> => {
    // const url = `api/${userType}/-licensing` //  uncomment once system level is supported
    const url = `api/admin/company-licensing`
    return this._dataService.get(url)
  }

  deleteCompanyLicense = (companyLicenseId: number): Observable<any> => {
    const url = `api/admin/company-licensing/${companyLicenseId}`;
    return this._dataService.delete(url);
  }

  insertCompanyLicense = (data: CompanyLicense): Observable<CompanyLicense> => {
    const url = `api/admin/company-licensing`;
    return this._dataService.post(url, data)
  }

  updateCompanyLicense = (data: CompanyLicense): Observable<any> => {
    const url = `api/admin/company-licensing/${data.companyLicenseId}`;
    return this._dataService.post(url, data)
  }

  updateCompanyBasic = (data: Company): Observable<any> => {
    const url = `api/Admin/UpdateCompanyBasic`;
    return this._dataService.post(url, data)
  }

  getCompanyBasic = (): Observable<any> => {
    const url = `api/Admin/GetCompanyBasic`;
    return this._dataService.get(url)
  }

}
