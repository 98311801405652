import {Component, Input, OnInit} from '@angular/core';
import {Configuration} from '../../../../../models/configuration.model';

@Component({
    selector: 'reporting-integration',
    templateUrl: './reporting.component.html'
})
export class ReportingComponent implements OnInit {
    @Input() enhancedReportingEnabled: Configuration;

    constructor() {}

    ngOnInit(): void {}
}
