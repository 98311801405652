import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeeDefinitionModel } from 'src/app/models/fee/fee-definition.model';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-fee-template-from-library-dialog',
  templateUrl: './fee-template-from-library-dialog.component.html',
  styleUrls: ['./fee-template-from-library-dialog.component.scss']
})
export class FeeTemplateFromLibraryDialogComponent implements OnInit {

  @Input() title: string;
  @Input() feeSection: FeeSectionEnum;

  @Input() fees: FeeTemplateFee[];
  @Input() feeTemplate: FeeTemplate;

  isSaving: boolean = false;

  private selectedFeeDefinitions: FeeDefinitionModel[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _feeService: FeeService,
    private readonly _notifyService: NotificationService
  ) {
  }


  ngOnInit(): void {
  }

  save = () => {
    const newFees = this.fees.concat(this.selectedFeeDefinitions as FeeTemplateFee[]);

    this.feeTemplate.fees = newFees.reduce((pV, cV) => {
      cV.feeTemplateId = this.feeTemplate.feeTemplateId;
      if (cV.feeSection !== this.feeSection) {
        return pV
      }
      const index = this.feeTemplate.fees.findIndex(f => f.feeDefinitionId === cV.feeDefinitionId);
      if (index > -1) {
        return pV;
      }
      return [...pV, cV];
    }, [...this.feeTemplate.fees])

    this.isSaving = true;
    this._feeService.updateFeeTemplate(this.feeTemplate.feeTemplateId, this.feeTemplate)
      .subscribe((result) => {
        this.isSaving = false;
        this._notifyService.showSuccess("Fee Template updated Successfully", "Success");
        this.activeModal.close(result.fees);
      }, (err) => {
        this.isSaving = false;
        this._notifyService.showError(err?.message || err.error, "Error!");
      })
  }

  feesChanged = (selectedFees: FeeDefinitionModel[]) => {
    this.selectedFeeDefinitions = selectedFees;
  }

}

