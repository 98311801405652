<div class="outstanding-conditions-card">
    <div class="outstanding-conditions-card-section1">
        <div class="outstanding-conditions-card-header">
            <span>
                <b> Outstanding {{isTpoSubmitted ? 'Conditions'  : 'Tasks'}} </b>
            </span>
        </div>
        <div>
            <a [routerLink]="['/tpo/app-details/' + appId + '/conditions']">
                <span class="outstanding-conditions-card-body">
                    <b> {{outstandingConditionsCount}} </b>
                </span>
            </a>
        </div>
    </div>
</div>
