<ng-template #pageActionBarLeft>
    <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
    <button *ngIf="allowAddNew" class="btn btn-primary" (click)="showUpsertDialog()">
        <i class="fa fa-plus"></i>
        Add New
    </button>
</ng-template>

<ng-template #mainContent>
    <form #agentTypeForm="ngForm">
        <p-table #subStatusTable [columns]="subStatusColumns" columnResizeMode="fit" [value]="subStatuses"
            [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll"
            name="sub-status-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true"
            [showCurrentPageReport]="true" scrollable="true" [scrollHeight]="scrollHeight"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single"
            [globalFilterFields]="['subStatusName']">
            <ng-template pTemplate="caption">
                <grid-top-action-bar #gridHeaderActionBar [options]="actionButtonOptions"></grid-top-action-bar>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of subStatusColumns" [pSortableColumn]="col.field"
                        [pSortableColumnDisabled]="!col.sortable">
                        <div *ngIf="col.field !== 'order'">
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </div>
                        <div *ngIf="col.field === 'order'">
                            <button *ngIf="allowAddNew" id="sub-status-order-link" class="btn link-primary p-0 m-0"
                                (click)="openOrderDrawer();$event.stopPropagation()">Sort</button>
                            <span *ngIf="!allowAddNew">Sort</span>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td>
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="fa fa-pencil-alt cursor text-primary" (click)="showUpsertDialog(row)"
                                title="Edit Role"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(row)"
                                title="Delete Role"></i>
                        </span>
                    </td>
                    <td>{{ row.subStatusName }}</td>
                    <td class="wrap-long-text">{{ getRoleNameCsv(row.selectByRole) }}</td>
                    <td>{{ row.isSuspenseSubStatus | yesNo }}</td>
                    <td>{{ row.hideFromCounter | yesNo }}</td>
                    <td>{{ row.order }}</td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
    [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #subStatusOrderDrawer [title]="'Sub Status Order'" [name]="'subStatusOrderDrawer'"
    [templateRef]="subStatusOrderRef" [options]="orderDrawerOptions">
</drawer>
<ng-template #subStatusOrderRef>
    <rearrange-order #subStatusOrder [itemList]="itemsToOrder" (close)="closeOrderDrawer($event)"
        (save)="onOrderDrawerSave($event)"></rearrange-order>
</ng-template>
<drawer #upsertSubStatusDrawer [title]="currentSubStatus?.subStatusId ? 'Edit Sub Status' : 'Add Sub Status'"
    [name]="'upsertSubStatusDrawer'" [templateRef]="upsertSubStatusRef" [options]="upsertSubStatusDrawerOptions">
</drawer>
<ng-template #upsertSubStatusRef>
    <upsert-sub-status [subStatus]="currentSubStatus" [roles]="roles" [companyId]="companyId"
        (close)="onUpsertLoanStatusDrawerClose($event)"></upsert-sub-status>
</ng-template>
