<div class="modal-header">
  <h5 class="modal-title">
    {{ title }}
  </h5>

  <button
    (click)="activeModal.dismiss()"
    aria-label="Close"
    class="btn-close"
    type="button"
  ></button>
</div>

<div class="modal-body">
  <form #incomeForm="ngForm" id="incomeForm" name="incomeForm" novalidate>
    <div class="loan-app main">
      <div class="grid-layout">
        <div
          *ngFor="let income of incomes; let i = index"
          class="grid-layout-row"
        >
          <!-- Income Type -->
          <urla-dropdown
            [(ngModel)]="income.typeOfIncome"
            (ngModelChange)="onTypeChanged(income)"
            [label]="i == 0 ? 'Income Type' : null"
            [options]="incomeTypes"
            [required]="true"
            class="col"
            name="incomeType{{ i }}"
          ></urla-dropdown>

          <!-- Amount -->
          <urla-currency-input
            [(ngModel)]="income.amount"
            (ngModelChange)="onAmountChanged(income)"
            [allowNegative]="false"
            [label]="i == 0 ? 'Amount' : null"
            [required]="true"
            class="col"
            name="amount{{ i }}"
          ></urla-currency-input>

          <!-- Pay Period -->
          <urla-dropdown
            [(ngModel)]="income.payPeriod"
            (ngModelChange)="onAmountChanged(income)"
            [label]="i == 0 ? 'Pay Period' : null"
            [options]="incomePayPeriods"
            [required]="true"
            class="col"
            name="payPeriod{{ i }}"
          ></urla-dropdown>

          <!-- Hours Per Week -->
          <urla-digits-input
            [(ngModel)]="income.hoursPerWeek"
            (ngModelChange)="onAmountChanged(income)"
            [label]="i == 0 ? 'Hrs/Week' : null"
            [readonly]="income.payPeriod !== PayPeriod.Hourly"
            [required]="income.payPeriod === PayPeriod.Hourly"
            class="col"
            name="hoursPerWeek{{ i }}"
          ></urla-digits-input>

          <!-- Monthly Income -->
          <urla-currency-input
            [(ngModel)]="income.monthlyIncome"
            [isOmitted]="income.isOmitted"
            [label]="i == 0 ? 'Monthly' : null"
            [ngStyle]="{
              'padding-top': i != 0 ? '10px' : '0px',
              'padding-left': i != 0 ? '10px' : '0px'
            }"
            [readonly]="true"
            [showBorder]="false"
            class="col"
            name="monthlyIncome{{ i }}"
          ></urla-currency-input>

          <!-- Include MCC Income as Tax Credit -->
          <urla-dropdown
            *ngIf="hasMCCTypedIncome(income)"
            [(ngModel)]="income.mccIncomeIsMappedAsTaxCredit"
            [label]="i == 0 ? 'Include MCC as Tax Credit' : null"
            [options]="yesNoOptions"
            class="col"
            name="mccIncomeIsMappedAsTaxCredit{{ i }}"
          ></urla-dropdown>

          <div
            class="col"
            *ngIf="haveMCCTypedIncomesControl() && !hasMCCTypedIncome(income)"
          ></div>

          <!-- Verification Status -->
          <urla-dropdown
            [(ngModel)]="income.verificationStatus"
            [label]="i == 0 ? 'Verification Status' : null"
            [options]="verificationStatusTypes"
            class="col"
            name="verificationStatus{{ i }}"
          ></urla-dropdown>

          <div
            [ngClass]="{ 'pt-4': i == 0 }"
            class="col-auto d-flex align-items-center flex-row"
          >
            <!-- Delete Income Button -->
            <a
              (click)="onDeleteIncomeClicked(i)"
              *ngIf="activeDeletionIndex !== i && !editOnly"
              class="table-icon-button"
            >
              <i class="fa fa-trash-alt ms-2"></i>
            </a>

            <!-- Confirm Delete Income Button -->
            <a
              (click)="onDeleteIncomeConfirmClicked(income)"
              *ngIf="activeDeletionIndex === i"
              class="table-icon-button hoverSuccess"
            >
              <i class="fa fa-check ms-2"></i>
            </a>

            <!-- Abort Delete Income Button -->
            <a
              (click)="onDeleteIncomeAbortClicked()"
              *ngIf="activeDeletionIndex === i"
              class="table-icon-button hoverDanger"
            >
              <i class="fa fa-times ms-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Income Button -->
    <a
      (click)="onAddIncomeClicked()"
      *ngIf="!editOnly"
      class="add-button"
      role="button"
      ><i class="fas fa-plus me-2"></i>Add Income</a
    >
  </form>
</div>

<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      Total monthly income: <b>{{ totalIncome | currency }}</b>
    </div>

    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right">
        <!-- Cancel Button -->
        <button
          (click)="activeModal.dismiss()"
          class="btn btn-secondary right"
          type="button"
        >
          Cancel
        </button>

        <!-- OK Button -->
        <button
          (click)="onOkClicked()"
          class="btn btn-primary right"
          type="button"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
