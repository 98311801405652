import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { CreditReport, ThirdPartyCredential } from "src/app/models";
import { isNullOrUndefined } from "src/utils";
import { CreditRequest } from "../models/credit-request.model";
import { CreditResponse } from "../models/credit-response.model";
import { CreditInquiryDetails } from "../models/credit-inquiry-details.model";
import { clone } from "lodash";
import { CreditUpgradeRequest } from "../../loan-credit/models/credit-upgrade-request.model";

@Injectable()
export class CreditService {

  constructor(private readonly _dataService: DataService) { }

  public pullReport = (data: CreditRequest): Observable<CreditResponse> => {
    const request = clone(data);

    // null paymentInfo if nothing is provided
    if (
      request.paymentInfo
      && !request.paymentInfo.ccExpirationDate
      && !request.paymentInfo.ccType
      && !request.paymentInfo.payerAccount
      && !request.paymentInfo.payerName
      && request.paymentInfo.amount == 0
    ) {
      request.paymentInfo = null;
    }

    const url = 'api/CreditInfo/pull-report';
    return this._dataService.post(url, request);
  }

  public getReportContent = (integrationHistoryId: number): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/integration-history/${integrationHistoryId}/document?documentNameContains=credit`);
  }

  public getLoanDocs = (loanDocId: number): Observable<BlobPart> => {
    return this._dataService.downloadFile(`api/LoanDoc/${loanDocId}?includeDocFiles=true`);
  }

  public reissueCreditReport = (integrationHistoryId: number): Observable<any> => {
    let url = 'api/CreditInfo/reissue-report/';
    if (integrationHistoryId) {
      url += `${integrationHistoryId}`;
    }
    return this._dataService.post(url, integrationHistoryId);
  }

  public getLiabilitiesHistory = (creditInfoId: number): Observable<any> => {
    return this._dataService.get(`api/CreditInfo/${creditInfoId}/liabilities`);
  }

  public importLiabilites = (loanId: number, deleteExistingUnmatchedLiabilitiesFromLoan: boolean, importData: any): Observable<any> => {
    let parameterName = isNullOrUndefined(deleteExistingUnmatchedLiabilitiesFromLoan) ? '' : "?deleteExistingUnmatchedLiabilitiesFromLoan=" + deleteExistingUnmatchedLiabilitiesFromLoan;
    let url = `api/CreditInfo/loan/${loanId}/merge-liabilities${parameterName}`;
    return this._dataService.post(url, importData);
  }

  public getCreditVendorsForLead = (leadId: number): Observable<ThirdPartyCredential[]> => {
    return this._dataService.get(`api/CreditInfo/creditVendors/lead/${leadId}`);
  }

  public getCreditVendorsForLoan = (loanId: number): Observable<ThirdPartyCredential[]> => {
    return this._dataService.get(`api/CreditInfo/creditVendors/loan/${loanId}`);
  }

  public getCreditReportHistory = (isForApplication: boolean, id: number): Observable<CreditReport[]> => {
    let parameterName = isForApplication ? "applicationId" : "leadId";
    return this._dataService.get("api/creditinfo/history?" + parameterName + "=" + id);
  }

  public getInquiries = (creditId: number): Observable<CreditInquiryDetails> => {
    return this._dataService.get(`api/CreditInfo/inquiries${creditId ? '?creditInfoId=' + creditId : ''}`);
  }

  public saveInquiries = (creditInquiries: CreditInquiryDetails) => {
    const url = `api/online-app/credit/inquiries?submitESignRequest=true`;
    return this._dataService.post(url, creditInquiries);
  }

  public upgradeReport = (data: CreditUpgradeRequest): Observable<CreditResponse> => {
    const request = clone(data);
    // null paymentInfo if nothing is provided
    if (
      request.paymentInfo
      && !request.paymentInfo.ccExpirationDate
      && !request.paymentInfo.ccType
      && !request.paymentInfo.payerAccount
      && !request.paymentInfo.payerName
      && request.paymentInfo.amount == 0
    ) {
      request.paymentInfo = null;
    }

    const url = 'api/CreditInfo/upgrade-report';
    return this._dataService.post(url, request);
  }
    
}
