<div>
    <div class="flex-grow-1">
        <textarea [required]="required && attachments.length == 0" name="mmsBody" #mmsBodyToSend="ngModel" class="form-control"
            [ngClass]="{'is-invalid': mmsBodyToSend && mmsBodyToSend.touched && mmsBodyToSend.invalid}"
            [cols]="cols" [rows]="rows" style="resize: none" id="mmsBody" [(ngModel)]="value"
            [placeholder]="placeholder" [mentionConfig]="mergeFieldsMentionConfig || {}" 
            (contextmenu)="mergeFieldsMentionConfig ? onContextMenu($event, 'body') : '' ">
      </textarea>
        <div class="invalid-feedback">Body field is required.</div>
        <span class="text-muted" *ngIf="mergeFieldsMentionConfig">
          You can use mouse right click button to select and insert a merge a
          field.
        </span>
    </div>
</div>

<div class="d-flex mt-2">
    <div>
        <input type="file" [accept]="accept" style="display:none" #addAttachment [(ngModel)]="fakeData"
            (change)="handleFileUpload($event)" multiple>
        <button class="btn btn-primary" (click)="addAttachment.click()">
            Add <i class="fas fa-paperclip ms-1"></i>
        </button>
    </div>

    <div class="d-flex flex-wrap" name="attachments" id="attachments">
        <div class="d-flex ms-1 mb-2" *ngFor="let attachment of attachments; let index = index">
            <img [src]="attachment.base64 | safeUrl" [height]="height" [width]="width" (error)="onImageError(attachment)"
                class=" rounded" />
            <i class="fa fa-trash-alt cursor text-danger mt-2 ms-1" (click)="deleteAttachment(attachment)" [hidden]="hiddenFirstFile && index == 0"></i>
        </div>
    </div>
</div>
