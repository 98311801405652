import { LpaCreditRequest } from "./lpa-credit-request.model";

export class FreddieMacUnderwriteRequest {
  applicationId: number;
  keyIdentifier: string = '';
  loanIdentifier: string = '';
  authUserId: string;
  authPassword: string;
  branchId: string;
  loanStatus: string;
  verification: LpaVerification[] = [];
  creditRequest: LpaCreditRequest;
  getMergedCreditReport: boolean;
  writeAusCredsToUserProfile: boolean;

  ausUsernameOverride: string;
  ausPasswordOverride: string;

  isSuccessful?: boolean;
  status?: boolean;

  constructor() {
    this.creditRequest = new LpaCreditRequest();
  }
}

export class LpaVerification {
  borrowerIds: number[];
  company: string = '';
  verificationType: string;
  reportNumber: string = '';
}

