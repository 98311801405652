import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { DocumentTemplate } from '../models/config/document-template.model';
import { DocumentTemplateField, DocumentTemplatePage } from '../models/document-template-page.model';
import { GlobalEmailMergeField } from '../models/user/global-email-merge-field.model';

@Injectable()
export class DocumentTemplateService {
  constructor(private readonly _dataService: DataService) { }

  getDocumentTemplatePages = (documentTemplateId: number): Observable<DocumentTemplatePage[]> => {
    const url = `api/configuration/document-templates/templates/${documentTemplateId}/pages`;
    return this._dataService.get(url);
  };

  upsertDocumentTemplatePages = (documentTemplateId: number, pages: DocumentTemplatePage[]): Observable<DocumentTemplate> => {
    const url = `api/configuration/document-templates/templates/${documentTemplateId}/pages`;
    return this._dataService.post(url, { pages: pages });
  };

  deleteDocumentTemplatePage = (documentTemplatePageId: number): Observable<any> => {
    const url = `api/configuration/document-templates/pages/${documentTemplatePageId}/delete`;
    return this._dataService.delete(url);
  };

  getDocumentTemplates = (): Observable<DocumentTemplate[]> => {
    const url = `api/configuration/document-templates/templates`;
    return this._dataService.get(url);
  };

  getDocumentTemplate = (documentTemplateId: number): Observable<DocumentTemplate> => {
    const url = `api/configuration/document-templates/templates/${documentTemplateId}`;
    return this._dataService.get(url);
  };

  insertDocumentTemplate = (documentTemplate: DocumentTemplate, file?: File): Observable<DocumentTemplate> => {
    let formData = new FormData();

    if (file) {
      formData.append("file0", file);
    }

    if (documentTemplate.restrictedUserRoles && documentTemplate.restrictedUserRoles.length === 0) {
      documentTemplate.restrictedUserRoles = null;
    }
    formData.append("model", JSON.stringify(documentTemplate));

    const url = `api/configuration/document-templates/templates`;
    return this._dataService.postFormData(url, formData);
  };

  updateDocumentTemplate = (templateId: number, documentTemplate: DocumentTemplate , file?: File): Observable<any> => {
    let formData = new FormData();

    if (file) {
      formData.append("file0", file);
    }
    if (documentTemplate.restrictedUserRoles && documentTemplate.restrictedUserRoles.length === 0) {
      documentTemplate.restrictedUserRoles = null;
    }
    formData.append("model", JSON.stringify(documentTemplate));

    const url = `api/configuration/document-templates/templates/${templateId}`;
    return this._dataService.postFormData(url, formData);
  };

  deleteDocumentTemplate = (templateId: number): Observable<any> => {
    const url = `api/configuration/document-templates/templates/${templateId}`;
    return this._dataService.delete(url);
  };

  reOrderDocumentTemplates = (templateIds: Array<{ id: number }>): Observable<any> => {
    const url = `api/Admin/UpdateDocumentTemplateOrder`;
    return this._dataService.post(url, templateIds);
  };

  updateDocumentTemplateField = (templateFieldId: number, documentTemplateField: DocumentTemplateField): Observable<any> => {
    const url = `api/configuration/document-templates/fields/${templateFieldId}/update`;
    return this._dataService.post(url, documentTemplateField);
  };

  mergeAppGlobalFieldKeys = (): Observable<string[]> => {
    const url = `api/configuration/document-templates/app-global-merge-field-keys`;
    return this._dataService.get(url).pipe(map(result => [...result, 'TaskUpdateSection']));
  }

  mergeLeadGlobalFieldKeys = (): Observable<string[]> => {
    const url = `api/configuration/document-templates/lead-global-merge-field-keys`;
    return this._dataService.get(url);
  }

  mergeEmailGlobalFields = (): Observable<GlobalEmailMergeField[]> => {
    const url = `api/Admin/AllGlobalEmailMergeFieldsModel`;
    return this._dataService.get(url);
  }

}
