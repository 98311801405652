import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mortech-vendor-editor-dialog',
  templateUrl: 'mortech-vendor-editor-dialog.component.html',
  styleUrls: ['./mortech-vendor-editor-dialog.component.scss'],
})

export class MortechVendorEditorDialogComponent implements OnInit {



  constructor() { }

  ngOnInit() { }
}