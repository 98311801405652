import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LoanLtvSummaryComponent } from "./components/loan-ltv-summary/loan-ltv-summary.component";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LoanLtvDonutChartComponent } from "./components/loan-ltv-donut-chart/loan-ltv-donut-chart.component";

@NgModule({
  imports: [
    SharedModule,
    NgCircleProgressModule.forRoot({
      outerStrokeGradient: true,
      outerStrokeWidth: 5,
      innerStrokeWidth: 5,
      title: "",
      titleFontSize: '25',
      subtitleFontWeight: '500',
      animation: true,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      clockwise: true,
      startFromZero: true
    }),
  ],
  declarations: [
    LoanLtvSummaryComponent,
    LoanLtvDonutChartComponent
  ],
  exports: [
    LoanLtvSummaryComponent
  ],
  providers: [
  ]
})
export class LoanLtvModule {
  constructor() {
  }
}
