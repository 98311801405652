<div
  [ngClass]="{
    'ng-invalid-required': required && value == null,
    'form-input': layout == 'single',
    '': layout == 'multiple',
    'bg-transparent': readonly || disabled
  }"
>
  <!--Single layout takes "form-input" class in order to be encircled within borders,
    while multiple should not take form-input class since it will
  be used within urla-grid-cell -->
  <form #radioGroupForm="ngForm">
    <div class="boolean-checkbox"
      [ngClass]="{'boolean-checkbox-vertical': layout == 'vertical', 'boolean-checkbox-horizontal': layout == 'horizontal'}">
      <label class="boolean-checkbox-item" [for]="readonly ? '' : name">
        <input type="checkbox" name="{{name}}" [id]="name" [(ngModel)]="value" [value]="value" [ngModelOptions]="{standalone: true}"
          [required]="required" [disabled]="disabled" [readonly]="readonly"/>
        {{label}}
      </label>
      <a (click)="editClickEvent.emit()"
        id="{{editButtonId}}"
        class="float-end"
        #editButton
        [hidden]="!hasEditButton || (readonly && !editButtonEnabledEvenWhenReadOnly) || (disabled && !editButtonEnabledEvenWhenReadOnly)">
        <i class="{{editIcon}}"></i>
      </a>
    </div>
  </form>
</div>
