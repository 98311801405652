import { Component, OnInit, Input } from '@angular/core';
import { DisclosureTracking } from '../../../models/disclosure-tracking.model';
import { User } from 'src/app/models/user/user.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'disclosure-tracking-edisclosure',
  templateUrl: 'disclosure-tracking-edisclosure.component.html',
  styleUrls: ['disclosure-tracking-edisclosure.component.scss']
})

export class DisclosureTrackingEdisclosureComponent implements OnInit {

  @Input()
  disclosureTrackingItem: DisclosureTracking;

  @Input()
  usersAll: User[] = [];

  fulfilledBy: string;

  constructor() { }

  ngOnInit() {
    if (this.disclosureTrackingItem.fulfilledByUserId) {
      const user = this.usersAll.find(u => u.userCompanyGuid === this.disclosureTrackingItem.fulfilledByUserId);
      if (user) {
        this.fulfilledBy = Utils.getPersonsFullName(user);
      }
    }
  }
}