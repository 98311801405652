<div class="fieldset-title pt-3">Stikkum</div>
<div class="col-md-12" *ngIf="!isCreditMonitoringEnabled">
    <enable-message>Call to Enable this feature!</enable-message>
</div>
<div *ngIf="isCreditMonitoringEnabled">
    <div class="users" *ngIf="stikkumEnrollmentStatus === 'Approved'">
        <table class="table table-striped table-hover no-footer">
            <thead>
                <tr>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>{{user.firstName}} {{user.lastName}} {{user.active ? '' : '(inactive)'}}</td>
                    <td>{{user.email}}</td>
                    <td>
                        <span *ngIf="usersEnrolled[user.userCompanyGuid] && user.nmls">Enrolled</span>
                        <span *ngIf="!user.nmls">Missing NMLS </span>
                        <button *ngIf="!usersEnrolled[user.userCompanyGuid] && user.nmls" class="btn btn-primary"
                            type="button" (click)="enrollUser(user)">
                            <span *ngIf="!usersEnrolling[user.userCompanyGuid]">Enroll User</span>
                            <span *ngIf="usersEnrolling[user.userCompanyGuid]"
                                class="spinner-border spinner-border-sm"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="loader text-center" *ngIf="stikkumEnrollmentStatus === 'None'" class="center col-md-12">
        <loading-indicator></loading-indicator>
    </div>

    <div class="text-center pt-4" *ngIf="stikkumEnrollmentStatus === 'NotStarted'">
        <h4>
            To enroll for <em>Company Retention</em>, please click the button below and once you are registered,
            you will be provided a screen where you can complete your enrollment.
        </h4>
        <button class="btn btn-primary enroll mt-4" (click)="enrollForRetention()">
            ENROLL FOR RETENTION
        </button>
    </div>

    <form id="stikkumEnrollmentForm" name="stikkumEnrollmentForm" class="form-horizontal retention"
        novalidate="novalidate" *ngIf="stikkumEnrollmentStatus !== 'None'">
        <div *ngIf="stikkumEnrollmentStatus === 'InProgress'" class="row">
            <div class="col-md-12">
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">First Name </label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="personal_first_name"
                            [(ngModel)]="enrollmentData.personal_first_name" required>
                    </div>
                    <div class="col-md-1 text-end">
                        <label class="custom-control-label">Last Name</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control" name="personal_last_name"
                            [(ngModel)]="enrollmentData.personal_last_name">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">Title</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="personal_title"
                            [(ngModel)]="enrollmentData.personal_title">
                    </div>
                    <div class="col-md-1 text-end">
                        <label class="custom-control-label">Phone #</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control" name="personal_phone" [mask]="'(000) 000-0000'"
                            [showMaskTyped]="true" placeholder="(999) 999-9999"
                            [(ngModel)]="enrollmentData.personal_phone" required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">Mobile #</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="personal_cell_phone" [mask]="'(000) 000-0000'"
                            [showMaskTyped]="true" placeholder="(999) 999-9999"
                            [(ngModel)]="enrollmentData.personal_cell_phone">
                    </div>
                    <div class="col-md-1 text-end">
                        <label class="custom-control-label">Email</label>
                    </div>
                    <div class="col-md-5">
                        <input type="email" class="form-control" name="personal_email"
                            [(ngModel)]="enrollmentData.personal_email" required email trim>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">NMLS #</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="personal_nmls"
                            [(ngModel)]="enrollmentData.personal_nmls">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">Address</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" placeholder="Address"
                            [(ngModel)]="personalAddress.mailingStreet">
                    </div>
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">City</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" placeholder="City"
                            [(ngModel)]="personalAddress.mailingCity">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">State</label>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control" [(ngModel)]="personalAddress.mailingState">
                            <option [ngValue]=""></option>
                            <option *ngFor="let state of states" [ngValue]="state.code">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">Zip Code</label>
                    </div>
                    <div class="col-md-4">
                        <zip-code-input [(ngModel)]="personalAddress.mailingZip" [name]="'subjectPropertyZip'"
                            [required]="false" [disabled]="false" [readonly]="false"
                            (selectionChanged)="onZipCodeRelatedInfoChanged($event, '')"></zip-code-input>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
                <company-profile-ext [companyExtendedModel]="companyExtendedModel"></company-profile-ext>
            </div>
        </div>
        <div class="stikkum-wrapper" *ngIf="stikkumEnrollmentStatus === 'Pending'">
            <div class="wrapper-inner">
                <div class="iframe-wrapper">
                    <iframe id="stikkum_iframe" [src]="iframeLinkToEnroll" frameborder="0"></iframe>
                </div>
            </div>
        </div>

        <div class="row mt-3" *ngIf="stikkumEnrollmentStatus == 'InProgress'">
            <div class="col-md-12 text-end">
                <button type="button" class="btn btn-secondary me-2">
                    Cancel
                </button>
                <button class="btn btn-primary width-100p" type="submit" (click)="!enrollingClient && enrollClient()">
                    <span *ngIf="!enrollingClient">Save</span>
                    <span *ngIf="enrollingClient" class="spinner-border spinner-border-sm"></span>
                </button>
            </div>
        </div>
    </form>
</div>