export interface AlertModel {
  alertId: number;
  applicationId: number;
  leadId: number;
  taskId: number;
  loanDocId: number;
  messageModelId: number;
  mentionId: number;
  messageDelivery: MessageDelivery;
  userId: string;
  notifyPartyId: string;
  alertType: AlertType,
  alertText: string;
  createDate: string;
  isRead: boolean;
  applicationStatus: string;
  propertyAddress: string;
  userName: string;
  taskText: string;
  taskNote: string;
  notifyPartyName: string;
  borrowerId: number;
  borrowerName: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum MessageDelivery {
  Unspecified = "Unspecified",
  Email = "Email",
  SMS = "SMS",
  Toaster = "Toaster",
  Rvm = "Rvm",
  API = "API"
}

export enum AlertType {
  BorrowerMessageAlert = "BorrowerMessageAlert",
  TaskStatusAlert = "TaskStatusAlert",
  GeneralAlert = "GeneralAlert",
  LoanStatusAlert = "LoanStatusAlert",
  FailedEmailAlert = "FailedEmailAlert",
  FailedSmsAlert = "FailedSmsAlert",
  NewLeadAlert = "NewLeadAlert",
  Mention = "Mention",
  BorrowerGeneratedPrequal = "BorrowerGeneratedPrequal",
  AgentGeneratedPrequal = "AgentGeneratedPrequal",
  OCRCompleted_Success = "OCRCompleted_Success",
  OCRCompleted_Failure = "OCRCompleted_Failure",
  TridHasBeenTriggered = "TridHasBeenTriggered",
}
