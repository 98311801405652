import { Component, Input, OnInit } from '@angular/core';
import { finalize, Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { ExtendedFormFreeReportHistory, Mortgage, TwnHistoriesArray } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'voi-voe-history',
  templateUrl: 'voi-voe-history.component.html',
  styleUrls: ['./voi-voe-history.component.scss'],
})
export class VoiVoeHistoryComponent implements OnInit {

  @Input() 
  mortgage: Mortgage;
  @Input() 
  provider: string;

  @Input('twnHistoriesExcludedData') 
  twnHistoriesExcludedData: TwnHistoriesArray;

  @Input('orderHistory') 
  orderHistory: ExtendedFormFreeReportHistory[];

  loadingIntegrationHistoryId: number;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _dataService: DataService,
    private readonly _documentService: DocumentService,
  ) { }

  ngOnInit() {
  }

  getBorrowerNameById = (borrowerId: number) => {
    if (!this.mortgage) {
      return '--';
    }
    const matchingBorrower = this.mortgage.borrowers.find(
      (borrower) => borrower.contactId === borrowerId
    );
    if (!matchingBorrower) {
      return '--';
    }
    return `${matchingBorrower.firstName} ${matchingBorrower.lastName}`;
  };

  openDoc(integrationHistoryId: number) {
    if (this.loadingIntegrationHistoryId == integrationHistoryId) {
      return;
    }
    this.openFile(integrationHistoryId);
  }

  downloadReport = (documentData) => {
    const fileUrl = this._documentService.convertBase64IntoPdf(documentData);
    window.open(fileUrl);
  };

  private openFile(integrationHistoryId: number) {
    this.loadingIntegrationHistoryId = integrationHistoryId;
    this._dataService.downloadFile(`api/integration-history/${integrationHistoryId}/document`)
      .pipe(finalize(() => this.loadingIntegrationHistoryId = null))
      .subscribe({
        next: (fileBuffer) => {
          if (!fileBuffer) {
            return this._notificationService.showError(`File doesnt exist for transaction: ${integrationHistoryId}`, "Error");
          }
          const fileURL = this._documentService.convertBlobIntoUrl(fileBuffer, 'application/pdf')
          const newWindow = window.open(fileURL);
          newWindow.document.title = `${DateTime.now().toFormat('MM-dd-yyyy-hh-mm-ss')}.pdf`;
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'Unable to get twn history document',
            'VOI/VOE Loan Service'
          );
        }
      });
  }

}
