<div class="modal-body pt-0" id="editTaskDialog" style="height: 90%">
  <div class="row">
    <div class="text-end">
      <a href="javascript: void(0);" (click)="toggleTaskView()" *ngIf="task.loanDocTaskId > 0">Show Task {{historyVisible
        ? "Details" :
        "History"}}</a>
    </div>
    <hr>
  </div>
  <form #editTaskForm="ngForm" id="editTaskForm" name="editTaskForm">

    <loading-indicator *ngIf="!currentApplication && !historyVisible" [loadingMessage]="'Loading referral info, please wait...'">
    </loading-indicator>
    <div class="col-md-12 d-flex align-items-stretch" *ngIf="currentApplication && !historyVisible">
      <div class="card w-100">
        <div class="card-header">
          <h4 class="card-title"><i class="fas fa-user me-2"></i> Referral Info </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="d-none d-sm-block">
              <table class="table mb-0 table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th style="width: 22%" class="text-secondary">Referral Source</th>
                    <th style="width: 28%" class='text-secondary'>Secondary Referral Source</th>
                    <th style="width: 22%" class="text-secondary">Referral Company</th>
                    <th style="width: 28%" class="text-secondary">Secondary Referral Company</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>{{referralSourceName}}</span>
                    </td>
                    <td>
                      <span>{{secondaryReferralSourceName}}</span>
                    </td>
                    <td>
                      <span>{{realtorCompanyName}}</span>
                    </td>
                    <td>
                      <span>{{secondaryReferralCompanyName}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!historyVisible" class="d-flex flex-column">
      <div>
        <loan-borrowers [borrowers]="borrowers" [actionButtonsVisible]="false"
          (deleted)="onBorrowerDeleted($event)" (updated)="onBorrowerUpdated($event)"
          (updatedPrimaryBorrower)="onUpdatedPrimaryBorrower($event)">
        </loan-borrowers>
      </div>
      <div class="form-group mb-3 " *ngIf="task.leadId">
        <label>Assigned To</label>
        <select class="form-select" id="assignedTo" [(ngModel)]="task.userId" name="assignedTo"
          [disabled]="taskReadonly">
          <option *ngFor="let user of users | sort: 'asc':'displayName'" value="{{user.userCompanyGuid}}">
            {{user.lastName + ", " + user.firstName}}
          </option>
        </select>
      </div>
      <div class="form-group mb-3 row">
        <div class="col-12 col-sm-6">
          <div class="row mb-2">
            <div class="col-6">
              <label for="dueDayOrDate2"> Due Date </label>
              <input id="dueDate" class="form-control" name="dueDate" type="date" [disabled]="true"
                [ngModel]="dueDate | date:'yyyy-MM-dd'">
            </div>
            <div class="col-6">
              <div *ngIf="task.loanDocTaskId">
                <div class="d-flex align-items-center" style="margin-top: 19px">
                  <div class="button-items btn-group-left" [hidden]="taskReadonly || dueDayOrDate == 'dateInput'">
                    <button class="btn btn-primary" type="button" #snoozeButton data-bs-container="body"
                      data-bs-toggle="popover" placement="right" [popover]="templateSnooze" data-trigger="click"
                      [outsideClick]="true" [adaptivePosition]="false" [disabled]="taskReadonly">
                      Snooze <i class="far fa-clock ms-1"></i>
                    </button>
                    <i class="ms-1 text-info" *ngIf="willBeSnoozed">Will snooze for {{
                      getSnoozeDurationTime(snoozeDurationInMinutes) }}</i>
                    <ng-template #templateSnooze>
                      <div class="minutePicker">
                        <div class="w-100 text-end">
                          <button type="button" class="close" (click)="snoozeButton.click()"> <span
                              aria-hidden="true">x</span></button>
                        </div>
                        <div class="minute_picker_innser">
                          <h3>Snooze Duration</h3>
                          <div class="minut_select_wrap">
                            <select class="form-select" [(ngModel)]="snoozeDurationInMinutes" name="snoozeDropdown">
                              <option [ngValue]="null">-- Select One --</option>
                              <option [ngValue]="15">15 mins</option>
                              <option [ngValue]="30">30 mins</option>
                              <option [ngValue]="45">45 mins</option>
                              <option [ngValue]="60">1 hour</option>
                              <option [ngValue]="120">2 hours</option>
                              <option [ngValue]="180">3 hours</option>
                              <option [ngValue]="240">4 hours</option>
                            </select>
                          </div>
                          <div class="w-100 text-center my-2">
                            <button class="btn btn-primary" (click)="snoozeTask(); snoozeButton.click()">Save</button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <label for="FollowUpDate"> Follow Up</label>
                  <input id="requestDate" class="form-control" placeholder="Follow Up" name="FollowUpDate" type="date"
                    [ngModel]="task.followUpDate | date:'yyyy-MM-dd'" (ngModelChange)="task.followUpDate = $event"
                    [disabled]="taskReadonly">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="row">
            <div class="col-12 mb-2" *ngIf="task.applicationId">
              <label for="responsibleUser"> Responsible Party</label>
              <select class="form-select" id="responsibleUser" [(ngModel)]="task.userId" name="responsibleUser"
                [disabled]="taskReadonly || isEsignDocumentWithNoLoanDocTaskId || isLoggedInUserTpo">
                <option value="">-- Select One --</option>
                <ng-container *ngFor="let user of notifyPartyLoanContacts">
                  <optgroup *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0" label="{{user.roleName}}">
                    <option class="user-select" value="{{user.userId}}" [selected]="user.userId == task.userId">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </optgroup>
                </ng-container>
                <optgroup label="All Branch Users" *ngIf="!isLoanTpo">
                  <ng-container *ngFor="let user of branchUsers">
                    <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == task.userId && !isInNotifyPartyList(task.userId)">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </ng-container>
                </optgroup>
                <optgroup label="All Users" *ngIf="!isLoanTpo">
                  <ng-container *ngFor="let user of users">
                    <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == task.userId && !isInNotifyPartyList(task.userId)">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </ng-container>
                </optgroup>
              </select>
            </div>

            <div class="col-12">
              <label for="notifyParty"> CC Party</label>
              <select class="form-select" id="notifyParty" [(ngModel)]="task.notifyPartyId" name="notifyParty"
                [disabled]="taskReadonly">
                <option value="">-- Select One --</option>
                <ng-container *ngFor="let user of notifyPartyLoanContacts">
                  <optgroup *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0" label="{{user.roleName}}">
                    <option class="user-select" value="{{user.userId}}" [selected]="user.userId == task.notifyPartyId">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </optgroup>
                </ng-container>
                <optgroup label="TPO All Users" *ngIf="isLoanTpo">
                  <option class="user-select" *ngFor="let user of externalCompanyUsers"
                    [selected]="user.userCompanyGuid == task.notifyPartyId" value="{{user.userCompanyGuid}}">
                    {{user.lastName + ", " + user.firstName}}
                  </option>
                </optgroup>
                <optgroup label="All Users">
                  <ng-container *ngFor="let user of users">
                    <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == task.notifyPartyId && !isInNotifyPartyList(task.notifyPartyId)">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </ng-container>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group my-3">
        <label for="notes" class="form-label mb-lg-0">Notes</label>
        <task-notes [task]="task" [taskNoteType]="'InternalMessage'" [noteFieldName]="'note'"
          [mentionables]="mentionables" [refreshMentions]="refreshMentions" [isEditorDisabled]="taskReadonly"
          [isAddingNewNoteActive]="true">
        </task-notes>
      </div>
      <div class="text-end w-100">
        <button type="button" class="btn btn-secondary mx-1" (click)="onCancelClicked()">
          Cancel
        </button>

        <button type="button" class="btn btn-primary" *ngIf="!historyVisible" (click)="saveTask()"
          [disabled]="(taskReadonly || !isValid())">
          <i class="fa fa-save me-2"></i>Save
        </button>
      </div>
    </div>
    <div *ngIf="historyVisible">
      <p-table #dt [value]="taskTrackingInfo" [paginator]="true" [rows]="25" *ngIf="taskTrackingInfo"
        [rowsPerPageOptions]="[10,25,50]"
        [globalFilterFields]="['actionValue','dateCreated','firstName','lastName','userName']"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        styleClass="p-datatable-gridlines">
        <ng-template pTemplate="caption">
          <div class="p-d-flex">
            <span class="p-input-icon-right p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" class="form-control" (ngModelChange)="dt.first=0"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="'dateCreated'">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-taskTrackingInfo>
          <tr>
            <td> {{taskTrackingInfo.actionValue}} </td>
            <td> {{taskTrackingInfo.dateCreated | date: 'MM/dd/yyyy h:mma'}} </td>
            <td>
              <span
                *ngIf="taskTrackingInfo.by!=null && (taskTrackingInfo.userProfile.firstName != null || taskTrackingInfo.userProfile.lastName != null)">
                {{taskTrackingInfo.userProfile.firstName}} {{taskTrackingInfo.userProfile.lastName}}
              </span>
              <span
                *ngIf="taskTrackingInfo.by!=null && taskTrackingInfo.userProfile.firstName == null && taskTrackingInfo.userProfile.lastName == null">
                {{taskTrackingInfo.userProfile.userName}}
              </span>
              <span *ngIf="taskTrackingInfo.borrowerId!=null">
                {{taskTrackingInfo.borrower.firstName}} {{taskTrackingInfo.borrower.lastName}}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" class="text-center"> No history found. </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </form>
</div>

