<ng-container [ngSwitch]="true" *ngIf="values[object[fieldId]]">
  <ng-container *ngSwitchCase="object[type] == 'String'">
    <input *ngIf="!object?.valueOptions?.length; else selectInput " type="text" class="form-control"
      [(ngModel)]="values[object[fieldId]].requestedValue" [ngModelOptions]="{ standalone: true }" />

    <ng-template #selectInput>
      <select class="form-control" [(ngModel)]="values[object[fieldId]].requestedValue"
        [ngModelOptions]="{ standalone: true }">
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let opt of object.valueOptions" [ngValue]="opt.value">{{opt.key}}</option>
      </select>
    </ng-template>
  </ng-container>

  <input *ngSwitchCase="
      ['None', 'Double', 'Enum'].includes(object[type])
    " type="text" class="form-control" [(ngModel)]="values[object[fieldId]].requestedValue"
    [ngModelOptions]="{ standalone: true }" />

  <number-input *ngSwitchCase="['Integer', 'Decimal'].includes(object[type])" [allowNegative]="false"
    [(ngModel)]="values[object[fieldId]].requestedValue" [decimals]="object[type] == 'Decimal' ? 3 : 0"
    [ngModelOptions]="{ standalone: true }">
  </number-input>

  <currency-input *ngSwitchCase="object[type] == 'Currency'" class="form-control text-left"
    [(ngModel)]="values[object[fieldId]].requestedValue" [ngModelOptions]="{ standalone: true }">
  </currency-input>

  <percent-input *ngSwitchCase="object[type] == 'Rate'" class="form-control text-left" type="text"
    [(ngModel)]="values[object[fieldId]].requestedValue" [ngModelOptions]="{ standalone: true }">
  </percent-input>

  <ng-container *ngSwitchCase="object[type] == 'Boolean'">
    <input type="checkbox" *ngIf="fieldFormat != 'YN'" [(ngModel)]="values[object[fieldId]].requestedValue"
      [ngModelOptions]="{ standalone: true }" ToggleValue [true]="'1'" [false]="'0'" />
    <input type="checkbox" *ngIf="fieldFormat == 'YN'" [(ngModel)]="values[object[fieldId]].requestedValue"
      [ngModelOptions]="{ standalone: true }" ToggleValue [true]="'Y'" [false]="'N'" />
  </ng-container>

  <date-input *ngSwitchCase="object[type] == 'DateTime'" [(ngModel)]="values[object[fieldId]].requestedValue"
    [disabled]="readOnly">
  </date-input>

  <input *ngSwitchCase="object[type] == 'Email'" class="form-control" type="email"
    [(ngModel)]="values[object[fieldId]].requestedValue" [ngModelOptions]="{ standalone: true }" trim />

  <input *ngSwitchCase="
      ['MobilePhone', 'LandLinePhone', 'FaxPhone'].includes(object[type])
    " class="form-control" type="text" data-mask="(999) 999-9999" [(ngModel)]="values[object[fieldId]].requestedValue"
    [ngModelOptions]="{ standalone: true }" />

  <textarea *ngSwitchCase="object[type] == 'Multiline'" class="form-control" cols="30" rows="5"
    [(ngModel)]="values[object[fieldId]].requestedValue" style="resize: none">
  </textarea>

  <input *ngSwitchDefault type="text" class="form-control" [(ngModel)]="values[object[fieldId]].requestedValue"
    [ngModelOptions]="{ standalone: true }" />
</ng-container>