import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Borrower, SendSms } from 'src/app/models';
import { Message } from 'src/app/models/message.model';
import { FileAttachment } from 'src/app/shared/components/send-mms/send-mms.component';

@Component({
  selector: 'send-sms',
  templateUrl: 'send-sms.component.html'
})

export class SendSmsComponent implements OnInit {

  @ViewChild("sendSmsForm")
  sendSmsForm: NgForm;

  toPhoneNumber: string = "";
  body: string = "";

  phoneNumbers: PhoneNumber[] = [];

  appendSmsSignature: boolean = false;

  @Input()
  isShowOnlyMMS: boolean = false;

  isMMS: boolean = false;

  @Input()
  loanId: number;

  @Input()
  attachments: FileAttachment[] = [];

  @Input()
  set borrowers(borrowers: Borrower[]) {
    borrowers.forEach(b => {
      const name = ((b.firstName ? b.firstName : '') + ' ' + (b.lastName ? b.lastName : '')).trim();
      if (b.homePhone) {
        const homePhone: PhoneNumber = {
          borrower: name,
          type: 'H',
          number: b.homePhone
        }
        this.phoneNumbers.push(homePhone);
      }
      if (b.mobilePhone) {
        const mobilePhone: PhoneNumber = {
          borrower: name,
          type: 'M',
          number: b.mobilePhone
        }
        this.phoneNumbers.push(mobilePhone);
      }
    });
    const primaryBorrower = borrowers.find(b => b.isPrimary);
    if (primaryBorrower) {
      this.toPhoneNumber = primaryBorrower.mobilePhone;
    }
  }

  constructor() { }

  ngOnInit() {
    if (this.isShowOnlyMMS) {
      this.isMMS = true;
    }
   }

  getSmsToSend = (): SendSms => {
    let mediaFiles = [];
    if (this.attachments) {
      this.attachments.forEach(attachment => {
        mediaFiles.push({ 
          fileName: attachment.file.name,
          data: attachment.base64.toString().split(',')[1],
          mimeType: attachment.file.type
        });
      });
    }
    let sms = new SendSms();
    sms.applicationId = this.loanId;
    sms.to = this.toPhoneNumber;
    sms.body = this.body;
    sms.mediaFiles = mediaFiles;
    sms.appendSmsSignature = this.appendSmsSignature;
    return sms;
  }

  validate = (): boolean => {
    if (this.sendSmsForm) {
      this.sendSmsForm.form.markAllAsTouched();
      return this.sendSmsForm.form.valid;
    }
  }

  switch = () => {
    this.isMMS = !this.isMMS;
    this.attachments = [];
  }
}

export class PhoneNumber {
  borrower: string;
  type: string;
  number: string;
}
