import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { LodestarKeyValuePairs } from '../fees.component';

@Component({
  selector: 'lodestar-provider-editor-dialog-name',
  templateUrl: 'lodestar-provider-editor-dialog.component.html'
})

export class LodestarProviderEditorDialogComponent implements OnInit {

  @ViewChild("lodestarProviderForm")
  lodestarProviderForm: NgForm;

  @Input()
  vendor: ThirdPartyCredential

  @Input()
  inEditMode: boolean = true;

  @Input()
  documentTypes: DocumentType[] = [];

  lodestarKeyValuePairs: LodestarKeyValuePairs = new LodestarKeyValuePairs();

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _systemLevelService: SystemLevelService
  ) { }

  ngOnInit() {
    this.initKvp();
  }

  saveCredential = () => {
    this.lodestarProviderForm.form.markAllAsTouched();
    if (!this.lodestarProviderForm.form.valid) {
      return;
    }

    this._spinner.show();
    this._systemLevelService.saveCredential(this.vendor).subscribe({
      next: ((result: any) => {
        this.activeModal.close(result);
        this._notifyService.showSuccess("Credentials saved successfully.", "Success");
      }),
      error: ((error: any) => {
        this._notifyService.showError(error?.message || "Couldn't save credentials.", "Error!");
      })
    }).add(() => this._spinner.hide());
  }

  private initKvp = () => {
    if (!this.vendor.thirdPartyKeyValuePairs) {
      this.vendor.thirdPartyKeyValuePairs = [];
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("ClientName", null));
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("SavedDocumentTypeId", null));
    }

    const clientNameKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientName');
    if (!clientNameKeyValuePair) {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("ClientName", null));
    }

    const savedDocumentTypeId = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key === "SavedDocumentTypeId");
    if (!savedDocumentTypeId) {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("SavedDocumentTypeId", null));
    }

    const importSellerPaidFees = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key === "ImportSellerPaidFees");
    if (!importSellerPaidFees) {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("ImportSellerPaidFees", null));
    }

    const autoSelectRequestPropertyTax = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key === "AutoSelectRequestPropertyTax");
    if (!autoSelectRequestPropertyTax) {
      this.vendor.thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue("AutoSelectRequestPropertyTax", '1'));
    }

    // lodestar init kvp values
    this.lodestarKeyValuePairs.clientName = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientName');
    this.lodestarKeyValuePairs.savedDocumentTypeId = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key === "SavedDocumentTypeId");
    this.lodestarKeyValuePairs.importSellerPaidFees = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key === "ImportSellerPaidFees");
    this.lodestarKeyValuePairs.autoSelectRequestPropertyTax = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key === "AutoSelectRequestPropertyTax");
  }
}