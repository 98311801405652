<ng-template #loading>
  <loading-indicator [customClass]="'no-shadow'"  loadingMessage="Loading Associated Answers..."></loading-indicator>
</ng-template>
<div class="card">
  <div class="card-header">
    <h4 class="card-title"> Associated Answers </h4>
  </div>
  <div class="card-body">
    <p-table #dt1 *ngIf="!isLoadingAnswers; else loading" [columns]="selectedColumns" [value]="answers"
      [paginator]="true" [rows]="25" responsiveLayout="scroll" name="answer-list-table-v2"
      [rowsPerPageOptions]="[10, 25, 50]" [globalFilterFields]="globalFilterFields" [autoLayout]="true"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      styleClass="p-datatable-gridlines p-datatable-sm" sortMode="single" sortField="answerText">
      <ng-template pTemplate="caption">
        <div class="d-flex justify-content-between">
          <span>
            <button type="button" class="btn btn-primary me-2" [disabled]="superAdmin" (click)="addNewQuestionAnswer()">
              <i class="fas fa-plus"></i> Add New
            </button>
          </span>

          <span class="ms-auto">
            <span class="p-input-icon-right ms-2 h-100">
              <i class="pi pi-search"></i>
              <input pInputText type="text" class="form-control h-100"
                (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0" placeholder="Search keyword" />
            </span>
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 7rem">
            <span>Action</span>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>
            <span class="d-flex align-items-center justify-content-center w-100">
              <span>
                <i class="fa fa-pencil-alt cursor text-primary" (click)="onEditQuestionAnswerClicked(rowData)"
                  title="Edit"></i>
              </span>

              <span class="mx-2"> | </span>

              <span>
                <i *ngIf="!isDeleteClicked[rowData.questionAnswerId]" class="fa fa-trash-alt cursor text-danger"
                  (click)="isDeleteClicked[rowData.questionAnswerId] = true" title="Delete"></i>

                <i *ngIf="isDeleteClicked[rowData.questionAnswerId]" class="fa fa-times cursor text-danger me-2"
                  (click)="isDeleteClicked[rowData.questionAnswerId] = false" title="Cancel"></i>

                <i *ngIf="isDeleteClicked[rowData.questionAnswerId]" class="fa fa-check-circle cursor text-danger"
                  (click)="onDeleteQuestionAnswerClicked(rowData.questionAnswerId)" title="Delete"></i>
              </span>
            </span>
          </td>
          <td *ngFor="let col of columns">
            <span> {{ rowData[col.field] }} </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="17" class="text-center"> There are no answers associated with this question. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
