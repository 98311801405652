import { Observable, Subject, Subscriber } from 'rxjs';

export interface ITimer extends Observable<TimerResult> {
  readonly tick$: Observable<number>;
  readonly play$: Observable<number>;
  readonly duration: number;
  readonly elapsed: number;
  readonly remaining: number;
  readonly isExpired: boolean;

  play(from?: number): void;

  pause(): void;

  close(): void;
}

export interface TimerResult {
  readonly isCancelled: boolean,
}

export class Timer extends Observable<TimerResult> implements ITimer {
  private _interval: NodeJS.Timeout | undefined;
  private _subscriber?: Subscriber<TimerResult>;

  private _play$ = new Subject<number>();
  get play$(): Observable<number> {
    return this._play$.asObservable();
  }

  private _tick$ = new Subject<number>();
  get tick$(): Observable<number> {
    return this._tick$.asObservable();
  }

  private _elapsed: number = 0;
  get elapsed(): number {
    return this._elapsed;
  }

  get remaining(): number {
    return this.duration - this._elapsed;
  }

  get isExpired(): boolean {
    return this._elapsed >= this.duration;
  }

  /**
   * @param duration The duration in seconds.
   * When this duration is reached, the timer observable will be completed.
   */
  constructor(
    public readonly duration: number,
  ) {
    if (!Number.isSafeInteger(duration) || duration <= 0) {
      throw new Error('[seconds] must be a positive integer');
    }

    super((subscriber) => {
      this._subscriber = subscriber;

      return () => this.close();
    });
  }

  /**
   * Starts the timer or can resume it if there is a valid {@link elapsed}.
   *
   * @param {number} [from] - The initial elapsed time in seconds. If supplied,
   * counting starts from here. If not supplied and there is a valid
   * {@link elapsed}, it will continue from where it left off. Otherwise, it
   * starts from 0.
   */
  play(from?: number): void {
    const getEffectiveFrom = () => {
      if (from == null) {
        return this._elapsed;
      }

      if (!Number.isSafeInteger(from) || from < 0) {
        throw new Error('[from] must be zero or a positive integer');
      }
      if (from > this.duration) {
        throw new Error('[from] must not be greater than [duration]');
      }

      return from;
    };
    const effectiveFrom = getEffectiveFrom();
    this._elapsed = effectiveFrom;

    this.pause();

    if (this.isExpired) {
      this.timeout();
      return;
    }

    this._interval = setInterval(() => {
      const elapsed = ++this._elapsed;
      this._tick$.next(elapsed);

      if (this.isExpired) {
        this.timeout();
      }
    }, 1000);

    this._play$.next(effectiveFrom);
  }

  private timeout() {
    this.pause();
    this._subscriber?.next({ isCancelled: false });
  }

  pause(): void {
    if (this._interval == null) {
      return;
    }

    clearInterval(this._interval);
    this._interval = undefined;
  }

  close(): void {
    if (this._interval != null) {
      this.pause();
      this._subscriber?.next({ isCancelled: true });
    }
    this._tick$.complete();
    this._play$.complete();
    this._subscriber?.complete();
  };
}
