import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ContentGenerationDialogComponent } from 'src/app/modules/campaign/Components/content-generation-dialog/content-generation-dialog.component';
import { SendTestEmailComponent } from 'src/app/modules/campaign/Components/send-test-email/send-test-email.component';
import { EmailTemplate } from 'src/app/modules/correspondence/models/email-template.model';
import { Constants } from 'src/app/services/constants';
import * as ClassicEditor from 'src/app/shared/utils/ckeditor5/classic/build/ckeditor';

export class EmailInfo {
  to: string;
  subject: string;
  body: string;
  bcc: string;
  cc: string;
  appendEmailSignature: boolean;
}

@Component({
  selector: 'common-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class CommonSendEmailComponent implements OnInit {

  @ViewChild("sendEmailForm")
  sendEmailForm: NgForm;

  htmlEditor = ClassicEditor;

  @Input() emailTemplates: EmailTemplate[] = [];

  @Input() subject: string;
  @Input() body: string;
  @Input() to: string;
  @Input() recipientVisible: boolean = true;

  @Input() availableMergeFields: EnumerationItem[] = [];
  @Input() loggedInUserEmailAddress: string;

  @Input() emailGenerationEnabled: boolean = false;

  @Input() showTemplates: boolean = true;

  cc: string;
  bcc: string;

  ckEditorConfig = Constants.defaultCkEditorConfig;

  @Output() selectedEmailTemplateChanged = new EventEmitter<EmailTemplate>();

  appendEmailSignature: boolean = false;
  willAddCcOrBcc: boolean = false;
  useTemplateEmail: boolean = false;
  numberOfFilesAttached: number;
  selectedEmailTemplateId: number = null;
  validationDone: boolean = false;

  htmlEditorConfig: any = {};
  emailMergeFieldsWithUnSubLink: EnumerationItem[];
  unsubscribeLinkMergeField = 'Campaign_UnsubscribeLink';

  constructor(private readonly _modalService: NgbModal) {

    this.htmlEditorConfig = {
      toolbar: {
        shouldNotGroupWhenFull: true
      }
    };

  }

  ngOnInit() {

    if (this.availableMergeFields && this.availableMergeFields.length > 0) {
      this.emailMergeFieldsWithUnSubLink = _.concat(
        this.availableMergeFields,
        {
          name: this.unsubscribeLinkMergeField,
          value: this.unsubscribeLinkMergeField,
          groupName: 'Email'
        });
    }

  }

  getEmailInfo = (): EmailInfo => {
    return {
      to: this.to,
      subject: this.subject,
      body: this.body,
      bcc: this.bcc,
      cc: this.cc,
      appendEmailSignature: this.appendEmailSignature
    }
  }

  onSelectedEmailTemplateChanged = () => {
    const selectedEmailTemplate = this.emailTemplates.find(t => t.emailTemplateId == this.selectedEmailTemplateId);
    this.selectedEmailTemplateChanged.emit(selectedEmailTemplate);
  }

  onReady(editor: any): void {
    // editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    //   return new Base64UploadAdapter(loader);
    // };
  }

  validate = (): boolean => {
    this.validationDone = true;
    if (this.sendEmailForm) {
      this.sendEmailForm.form.markAllAsTouched();
      return this.sendEmailForm.form.valid;
    }
  }

  openSendTestEmailModal() {
    const modalRef = this._modalService.open(SendTestEmailComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.body = this.body;
    modalRef.componentInstance.emailAddress = this.loggedInUserEmailAddress;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  openEmailGenerationModal() {
    const modalRef = this._modalService.open(ContentGenerationDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.generationType = 'Email';
    modalRef.result.then((suggestedBody: string) => {
      this.body = suggestedBody;
    }, () => {
    });
  }

}
