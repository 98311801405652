import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MortgageFieldConfig, MortgageFieldRequiredState } from 'src/app/shared/services/mortgage-field-validation-utils';

@Component({
	selector: 'edit-mode-input',
	templateUrl: 'edit-mode-input.component.html',
	styleUrls: ['./edit-mode-input.component.scss'],
	providers: [MakeProvider(EditModeInputComponent)]
})
export class EditModeInputComponent implements OnInit {

	@ViewChild("urlaFieldsConfigPopover")
	urlaFieldsConfigPopover: NgbPopover;

	@Input()
	label: string;

	@Input()
	name: string;

	@Input()
	fieldBeingEdited: string = '';

	@Input()
	urlaFieldsConfig: {};

	@Input()
	showBorder: boolean = true;

	constructor(private elRef: ElementRef) {
	}

	ngOnInit(): void {
		if (!this.urlaFieldsConfig[this.fieldBeingEdited]) {
			this.urlaFieldsConfig[this.fieldBeingEdited] = new MortgageFieldConfig(MortgageFieldRequiredState.None);
		}
	}

	onReadonlyToggled = () => {
		if (this.urlaFieldsConfig[this.fieldBeingEdited].readonly) {
			this.urlaFieldsConfig[this.fieldBeingEdited].hidden = false;
			this.urlaFieldsConfig[this.fieldBeingEdited].required = MortgageFieldRequiredState.None;
		}
	}

	onHiddenToggled = () => {
		if (this.urlaFieldsConfig[this.fieldBeingEdited].hidden) {
			this.urlaFieldsConfig[this.fieldBeingEdited].readonly = false;
			this.urlaFieldsConfig[this.fieldBeingEdited].required = MortgageFieldRequiredState.None;
		}
	}
}