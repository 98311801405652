<p-table
  #dt2
  [value]="info.documents"
  styleClass="p-datatable-gridlines p-datatable-sm"
  [paginator]="true"
  [rows]="25"
  [rowsPerPageOptions]="[25, 50, 100]"
  [autoLayout]="true"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  scrollable="true"
  [scrollHeight]="'400px'"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns">{{ col.header }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-doc>
    <tr>
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'fileName'">
          <a
            (click)="viewDocument(doc)"
            title="View"
            class="mx-2"
            *ngIf="doc.downloadedContent && !isTpoUser"
          >
            <i class="far fa-eye"></i>
          </a>
          <a
            (click)="downloadDocument(doc)"
            title="Download"
            class="mx-2"
            *ngIf="doc.downloadedContent && !isTpoUser"
          >
            <i class="fas fa-download"></i>
          </a>

          {{ doc.fileName }}
        </span>
        <span *ngSwitchDefault>
          {{ doc[col.field] }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns.length" class="text-center">
        No documents found.
      </td>
    </tr>
  </ng-template>
</p-table>
