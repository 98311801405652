import { Component, Input, OnInit } from '@angular/core';
import { EnumerationItem } from '../../../../../models/simple-enum-item.model';
import { CustomCssControlsConfig } from '../../../../admin/config-shared/components/custom-css-controls/custom-css-controls.component';

@Component({
  selector: 'general-settings',
  templateUrl: './general-settings.component.html'
})
export class GeneralSettingsComponent implements OnInit {
  @Input() onlineApplicationSettings: any;
  @Input() creditVendorOptions: EnumerationItem[];

  protected readonly customCssControlsConfig = CustomCssControlsConfig.OnlineApp();

  constructor() {}

  ngOnInit(): void {
    if (!this.onlineApplicationSettings.defaultCreditVendor) {
      this.onlineApplicationSettings.defaultCreditVendor = null;
    }
  }
}
