import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { filter, Subscription } from 'rxjs';
import { MentionMessageScope } from 'src/app/models/mention.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ApplicationContext, Chat, LoanStatus, UserData } from 'src/app/models';
import { TaskService } from 'src/app/services/task.service';
import { MentionViewModel } from 'src/app/shared/components/mentions/mention-item/mention-item.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { Notifications } from 'src/app/layout/admin/header/header-actions/header-actions.component';
import { NavigationStart, Router } from '@angular/router';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ChatService } from 'src/app/services/chat.service';
import { FirebaseService } from 'src/app/services/firebase';
import { NotificationService } from 'src/app/services/notification.service';
import { SignalRService } from 'src/app/services/signalr.service';
import { AlertModel } from 'src/app/models/alert.model';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { ChatMedium, Conversation, ConversationParticipant, ParticipantType } from 'src/app/modules/conversations/models/conversation.model';
import { phoneToPlainString, removeUsPhonePrefix, urlify } from 'src/utils';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'nextgen-layout',
  templateUrl: './nextgen-layout.component.html',
  styleUrls: ['./nextgen-layout.component.scss'],
})
export class NextGenLayoutComponent implements OnInit, OnDestroy {

  user = {};
  userId: string = '';
  conversationsUserId: string = '';
  loanStatus: LoanStatus[] = [];
  avatarUrl: string;

  numberOfLoadedMentions: number = 0;
  mentionApplicationId: number | null = null;
  mentionLoanDocTask: LoanDocTask | null = null;

  notifications: Notifications = null;

  numberOfLoadedEmails: number = 0;

  mentionMessageScope: MentionMessageScope;

  messageId: number | null = null;

  insideLoanContext: boolean = false;

  currentUserPhoneNumber: string;

  currentlyLoggedInUser: UserData;

  currentCompanyGuid: string;

  taskMentionTrackingUrl: string;

  showLoanSummary: boolean;

  allConversations: Conversation[] = [];

  loadingConversations: boolean = false;

  private _mentionsDrawerOpened: boolean = false;
  private _notificationsDrawerOpened: boolean = false;
  private _conversationsDrawerOpened: boolean = false;

  mentionsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'dashboard-wrapper',
  };

  loanActivityDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'dashboard-wrapper',
  };

  taskEditorDrawerOptions: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: 'dashboard-wrapper',
  };

  conversationsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: 'dashboard-wrapper',
  };

  private _loanInfoChangesSubscription: Subscription;
  private _contextChangesSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _routerEventsSubscription: Subscription;
  private _borrowerChatRequestedSubscription: Subscription;
  private _smsChatRequestedSubscription: Subscription;

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _signalRService: SignalRService,
    private readonly _firebaseService: FirebaseService,
    private readonly _taskService: TaskService,
    private readonly _drawerService: DrawerService,
    private readonly _router: Router,
    private readonly _environment: EnvironmentService,
    private readonly _chatService: ChatService,
    private readonly _conversationService: ConversationService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _notifyService: NotificationService
  ) {
    this._routerEventsSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (!event.url) {
          return;
        }
        if (
          !(
            event.url.includes('app-details') || event.url.includes('borrowers')
          )
        ) {
          this._applicationContextService.resetLoanInfo();
        }
      });
  }

  ngAfterViewInit(): void { }

  ngOnInit() {
    this._borrowerChatRequestedSubscription = this._conversationService.borrowerChatRequested.subscribe(borrowerChat => {

      let convo = this.allConversations.find(chat => chat.borrowerId == borrowerChat.borrowerId);

      if (!convo) {
        //todo: call api to register conversation
        var newConversation = new Conversation();
        newConversation.assignedTo = this.userId;
        newConversation.applicationId = borrowerChat.appId;
        newConversation.borrowerId = borrowerChat.borrowerId;
        newConversation.chatMedium = ChatMedium.BorrowerMessage;
        newConversation.mostRecentIncomingMessageDate = new Date()
        newConversation.externalName = borrowerChat.borrMessage?.senderDisplayName || `Borrower (${borrowerChat.borrowerId})`;

        convo = newConversation;

        this.allConversations.forEach(x => x.isActive = false);
        this.allConversations.unshift(convo);
      }

      convo.isActive = borrowerChat.openDrawer;

      if (!this._conversationsDrawerOpened && borrowerChat.openDrawer) {
        this._openConversationDrawer();
      }
    })

    this._smsChatRequestedSubscription = this._conversationService.smsChatRequested.subscribe(smsChat => {
      const internalPhone = smsChat.betweenPhone || this.currentUserPhoneNumber;
      let convo = this.allConversations.find(chat => {
        let internalParticipant = chat.participants.find(p => p.participantType === ParticipantType.Internal && p.phoneNumber === internalPhone);
        let externalParticipant = chat.participants.find(p => p.participantType === ParticipantType.External && p.phoneNumber.includes(smsChat.userPhone));
        return phoneToPlainString(removeUsPhonePrefix(externalParticipant.phoneNumber)) === phoneToPlainString(removeUsPhonePrefix(smsChat.userPhone)) &&
          phoneToPlainString(removeUsPhonePrefix(internalParticipant.phoneNumber)) === phoneToPlainString(removeUsPhonePrefix(internalPhone))
      });

      if (!convo) {
        //todo: call api to register conversation
        var newConversation = new Conversation();
        newConversation.assignedTo = this.userId;
        var externalParticipant = new ConversationParticipant();
        var internalParticipant = new ConversationParticipant();

        externalParticipant.participantType = ParticipantType.External;
        internalParticipant.participantType = ParticipantType.Internal;
        externalParticipant.phoneNumber = smsChat.userPhone;
        internalParticipant.phoneNumber = internalPhone;
        newConversation.chatMedium = ChatMedium.Sms;
        newConversation.mostRecentIncomingMessageDate = new Date();
        newConversation.externalName = smsChat.userName;

        newConversation.participants.push(externalParticipant);
        newConversation.participants.push(internalParticipant);

        convo = newConversation;

        this.allConversations.forEach(x => x.isActive = false);
        this.allConversations.unshift(convo);
      }

      convo.isActive = smsChat.openDrawer;

      if (!this._conversationsDrawerOpened && smsChat.openDrawer) {
        this._openConversationDrawer();
      }
    });


    this._loanInfoChangesSubscription = this._applicationContextService.loanInfoChanges.subscribe((context) => {
      this.insideLoanContext = false;
      if (context.application) {
        this.insideLoanContext = true;
      }
    });

    this._contextChangesSubscription = this._applicationContextService.changes.subscribe((context) => {
      this.initializeFromContext(context);
    });

    this._contextSubscription = this._applicationContextService.context.subscribe((context) => {
      this.initializeFromContext(context);
      this.checkUserFromNumber(context);

      this._signalRService.run(context);
      this._firebaseService.run(context);
    });
    this.getConversations();
    this.showLoanSummary = true;
  }

  ngOnDestroy() {
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._contextChangesSubscription) {
      this._contextChangesSubscription.unsubscribe();
    }
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
    if (this._routerEventsSubscription) {
      this._routerEventsSubscription.unsubscribe();
    }
    if (this._borrowerChatRequestedSubscription) {
      this._borrowerChatRequestedSubscription.unsubscribe();
    }
    if (this._smsChatRequestedSubscription) {
      this._smsChatRequestedSubscription.unsubscribe();
    }
  }

  onMentionClicked = (mention: MentionViewModel) => {
    this.mentionMessageScope = mention.messageScope;
    this.messageId = mention.messageId;
    if (this.mentionMessageScope === MentionMessageScope.InternalMessage) {
      this.mentionApplicationId = mention.entityId;
      this._drawerService.hide('taskEditorDrawer_NextGen', 10);
      this._drawerService.show('loanActivityDrawer_NextGen', 10);
    } else if (this.mentionMessageScope === MentionMessageScope.TaskNote) {
      this._taskService.getTaskDashboardViewById(mention.entityId).subscribe(
        (task) => {
          this.mentionLoanDocTask = task;
          if (mention.trackingGuid) {
            this.taskMentionTrackingUrl =
              this._environment.apiInfo.apiBaseUrl +
              'mentions/pixel/' +
              this.userId +
              '/' +
              mention.trackingGuid;
          }
          this._drawerService.hide('loanActivityDrawer_NextGen', 10);
          this._drawerService.show('taskEditorDrawer_NextGen', 10);
        },
        (error) => {
          this._notifyService.showError(error.error, 'Error!');
        }
      );
    }
  };

  onAlertsCreated = (alerts: AlertModel[]) => {
    alerts.forEach((a) => {
      const bucketThatAlertNeedsToGoTo = this.notifications?.alertBuckets?.find(
        (b) => b.typeId.toString() == a.alertType
      );
      if (bucketThatAlertNeedsToGoTo) {
        bucketThatAlertNeedsToGoTo.alerts.splice(0, 0, a);
      }
      const updatedNotifications = { ...this.notifications };
      this.notifications = updatedNotifications as Notifications;
    });
  };

  onNotificationsToggled = (notifications: Notifications) => {
    this.notifications = notifications;
    if (notifications && !this._notificationsDrawerOpened) {
      if (this._mentionsDrawerOpened) {
        this._drawerService.hide('mentionsDrawer_NextGen', 10);
      }
      if (this._conversationsDrawerOpened) {
        this._drawerService.hide('conversationsDrawer_NextGen', 10);
      }
      this._drawerService.show('alertsDrawer_NextGen', 10);
      this._notificationsDrawerOpened = true;
    } else {
      this._drawerService.hide('alertsDrawer_NextGen', 10);
    }
  };

  onMentionsStreamToggled = (open: boolean) => {
    if (this._notificationsDrawerOpened) {
      this._drawerService.hide('alertsDrawer_NextGen', 10);
    }
    if (this._conversationsDrawerOpened) {
      this._drawerService.hide('conversationsDrawer_NextGen', 10);
    }
    if (open && !this._mentionsDrawerOpened) {
      this._drawerService.show('mentionsDrawer_NextGen', 10);
      this._mentionsDrawerOpened = true;
    } else {
      this._drawerService.hide('mentionsDrawer_NextGen', 10);
    }
  };

  onNiceInContactToggled = (open: boolean) => {
    if (this._notificationsDrawerOpened) {
      this._drawerService.hide("alertsDrawer", 10);
    }
    if (this._conversationsDrawerOpened) {
      this._drawerService.hide("conversationsDrawer", 10);
    }
    if (open && !this._mentionsDrawerOpened) {
      this._drawerService.show("mentionsDrawer", 10);
      this._mentionsDrawerOpened = true;
    } else {
      this._drawerService.hide("mentionsDrawer", 10);
    }
  }

  onConversationsToggled = (open: boolean) => {
    open = true;
    if (this._notificationsDrawerOpened) {
      this._drawerService.hide('alertsDrawer_NextGen', 10);
    }
    if (this._mentionsDrawerOpened) {
      this._drawerService.hide('mentionsDrawer_NextGen', 10);
    }
    if (open && !this._conversationsDrawerOpened) {
      this._drawerService.show('conversationsDrawer_NextGen', 10);
      this._conversationsDrawerOpened = true;
    } else {
      this._drawerService.hide('conversationsDrawer_NextGen', 10);

    }
  };

  checkUnreadConversations = () => {
    return this.allConversations.some((c) => c.isUnread);
  };

  onNotificationsDrawerClosed = () => {
    this._notificationsDrawerOpened = false;
  };

  onMentionsDrawerClosed = () => {
    this.numberOfLoadedMentions = 0;
    this._mentionsDrawerOpened = false;
  };

  onMentionDetailsDrawerClosed = () => { };

  onConversationsDrawerClosed = () => {
    this._conversationsDrawerOpened = false;
  };

  onMentionsLoaded = (numberOfRecordsLoaded: number) => {
    this.numberOfLoadedMentions += numberOfRecordsLoaded;
  };

  onEmailsLoaded = (numberOfRecordsLoaded: number) => {
    this.numberOfLoadedMentions += numberOfRecordsLoaded;
  };

  onImpersonatedUserChanged = (userId: string) => {
    //reload chats
    this.getConversations(userId);
  }

  private _openConversationDrawer = () => {
    this._drawerService.show("conversationsDrawer", 10);
    this._conversationsDrawerOpened = true;
  }

  private getConversations = (userId?: string) => {
    this.conversationsUserId = userId || this.userId;
    this.allConversations = [];
    this._conversationService.getConversationsForUser(this.conversationsUserId, 1, 1000).subscribe({
      next: (chats) => {
        if (chats?.records?.length > 0) {
          this.allConversations = this.prepareConversationItemsForList(chats?.records);
        }
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load conversations", "Error");
      }
    });
  }


  private prepareConversationItemsForList = (conversations: Array<Conversation>): Array<Conversation> => {
    const conversationItems: Conversation[] = [];
    conversations.forEach(conversation => {
      conversationItems.push(conversation);
    });
    if (conversationItems.length && !conversationItems.find(x => x.isActive)) {
      conversationItems[0].isActive = true;
    }
    return conversationItems;
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.currentlyLoggedInUser = context.currentlyLoggedInUser;
    const company = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId);
    this.currentCompanyGuid = company.companyGUID;
    this.avatarUrl = context.currentlyLoggedInUserProfile.userProfile.avatarId
      ? `${this._environment.apiInfo.apiBaseUrl}avatars/${context.currentlyLoggedInUserProfile.userProfile.avatarId}`
      : context.currentlyLoggedInUser.avatar;

    this.userId = context.currentlyLoggedInUser.userCompanyGuid;
    this.loanStatus = context.globalConfig.loanStatus;
    this.user = {
      firstName: context.currentlyLoggedInUserProfile.userProfile.firstName,
      lastName: context.currentlyLoggedInUserProfile.userProfile.lastName,
      lastTimeLogin: context.currentlyLoggedInUser.lastTimeLogin,
      userImage: this.avatarUrl,
    };
  };

  private checkUserFromNumber = (context: ApplicationContext) => {
    this.currentUserPhoneNumber = Utils.getActiveTelephonyService(context.currentlyLoggedInUserProfile.telephonyServices)?.fromPhoneNumber;
    if (!this.currentUserPhoneNumber) {
      this._notifyService.showWarning("User phone number was not defined", "Warning");
    }
  }
}
