import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Borrower, Declarations, Demographics, MortgageBorrower } from 'src/app/models';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';

@Component({
  selector: 'app-add-mortgage-borrower-dialog',
  templateUrl: './add-mortgage-borrower-dialog.component.html',
  styleUrls: ['./add-mortgage-borrower-dialog.component.scss']
})
export class AddMortgageBorrowerDialogComponent implements OnInit {

  @Input() mortgage: UrlaMortgage;

  constructor(public activeModal: NgbActiveModal,
    private readonly _mortgageService: MortgageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService) { }

  ngOnInit(): void {
  }

  selectedBorrowerFinished = (borrower: Borrower) => {
    let selectedBorrower = new MortgageBorrower();

    selectedBorrower = Object.assign(selectedBorrower, borrower);

    selectedBorrower.borrowerId = null;
    selectedBorrower.primaryEmail = borrower.email;
    selectedBorrower.contactId = borrower.borrowerId;
    selectedBorrower.declarations = new Declarations();
    selectedBorrower.governmentMonitors = new Demographics();

    this._spinner.show();
    this._mortgageService.insertBorrowerToMortgage(this.mortgage.mortgageId, selectedBorrower)
      .subscribe({
        next: (res) => {
          this._spinner.hide();
          this._notifsService.showSuccess(
            'Borrower has been successfully added to the mortgage.',
            'Success!'
          );

          if (Array.isArray(res.declarations)) {
            res.declarations = res.declarations[0];
          }

          if (Array.isArray(res.currentHousingExpenses)) {
            res.declarations = res.currentHousingExpenses[0];
          }

          if (Array.isArray(res.governmentMonitors)) {
            res.governmentMonitors = res.governmentMonitors[0];
          }

          this.activeModal.close(res);
        },
        error: (error) => {
          this._notifsService.showError(
            error ? error.message : 'Unable to add borrower to mortgage.',
            'Error!'
          );
          this._spinner.hide();
        }
      })
  }

  savedBorrowerFinished = (newBorrower: MortgageBorrower) => {
    this.activeModal.close(newBorrower);
  }
}
