import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserProfile } from 'src/app/models';

@Component({
  selector: 'user-lead-routing',
  templateUrl: './user-lead-routing.component.html',
  styleUrls: ['./user-lead-routing.component.scss']
})
export class UserLeadRoutingComponent implements OnInit {
  @Input() userProfile: UserProfile;
  tabId: string;
  constructor() { }

  ngOnInit(): void {
    this.tabId = "routing";
  }
}
