import { NgModule } from '@angular/core';
import { AusComponent } from './components/aus/aus.component';
import { AusDuComponent } from './components/aus-du/aus-du.component';
import { AusLpaComponent } from './components/aus-lpa/aus-lpa.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AusDoComponent } from './components/aus-do/aus-do.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [SharedModule, NgSelectModule],
    declarations: [
        AusComponent,
        AusLpaComponent,
        AusDuComponent,
        AusDoComponent,
    ],
    exports: [AusComponent, AusLpaComponent, AusDuComponent],
    providers: []
})
export class AusModule {
  constructor() {}
}
