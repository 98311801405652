<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <i class='fa fa-money me-2'></i> Fee Wizard
      <a type="button" class="btn btn-outline-primary pull-right" (click)="onCancelClicked()">
        Cancel
      </a>
    </h4>
  </div>
  <div class="card-body">
    <p-steps [model]="steps" [(activeIndex)]="activeStepIndex"></p-steps>

    <div class="py-5">
      <select-fee-template
        #selectTemplateStep
        [templates]="feeTemplates"
        [applicationId]="application.applicationId"
        (executionStarted)="onStepExecutionStarted()"
        (executionCompleted)="onStepExecutionCompleted()"
        (feeTemplateSelected)="onFeeTemplateSelected($event)"
        (templateSelectionChanged)="onFeeTemplateSelectionChanged($event)"
        (autoProgressCalled)="onNextClicked()"
        *ngIf="activeStepIndex === selectTemplateStepIndex">
      </select-fee-template>
      <import-loan-calculated-fees
        #calculateLoanFeesStep
        [autoCalculateLoanFees]="autoCalculateLoanFees"
        *ngIf="activeStepIndex === calculateLoanFeesStepIndex"
        (executionStarted)="onStepExecutionStarted()"
        (executionCompleted)="onStepExecutionCompleted()"
        (loanFeesCalculated)="onLoanFeesCalculated($event)"
        [applicationId]="application.applicationId">
      </import-loan-calculated-fees>
      <import-fees-from-vendor
        [applicationId]="application.applicationId"
        #getFeesFromVendorStep
        [supportedVendorNames]="supportedVendorNames"
        (executionStarted)="onStepExecutionStarted()"
        (executionCompleted)="onStepExecutionCompleted()"
        (makeVendorFeesSkipVisible)="onMakeVendorFeesSkipVisible()"
        (vendorQuestionsRetrieved)="onVendorQuestionsRetrieved($event)"
        (vendorFeesRetrieved)="onVendorFeesRetrieved($event)"
        (vendorSelectionChanged)="onVendorSelectionChanged($event)"
        *ngIf="activeStepIndex === getVendorFeesStepIndex">
      </import-fees-from-vendor>
      <fee-comparison
        #feeComparisonTable
        [selectedFees]="selectedFees"
        [allFees]="allFees"
        *ngIf="activeStepIndex === reviewStepIndex">
      </fee-comparison>
      <final-fee-summary
        #feeSummary
        [fees]="selectedFees"
        [applicationId]="application.applicationId"
        (feesAppliedToLoan)="onFeesAppliedToLoan($event)"
        (executionCompleted)="onStepExecutionCompleted()"
        *ngIf="activeStepIndex === summaryAndApplyStepIndex">
      </final-fee-summary>
    </div>

    <div class="d-flex justify-content-center mb-2" *ngIf="!isWizardCompleted && !(disableNextButton && activeStepIndex === getVendorFeesStepIndex)">
      <!-- <button type="button" class="btn btn-secondary me-1" (click)="onCloseClicked()" data-bs-dismiss="modal"> Close
      </button> -->
      <button type="button" [disabled]="atFirstStep || isStepRunning" class="btn btn-primary me-1" (click)="onBackClicked()"> &lt; Back
      </button>
      <ng-container *ngIf="!(activeStepIndex == getVendorFeesStepIndex && !selectedVendorName)">
        <button class="btn btn-primary me-1" type="button" [disabled]="isStepRunning || disableNextButton" (click)="onNextClicked()">
          <span *ngIf="isStepRunning" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ isStepRunning ? 'Please wait...' : nextButtonLabel }}
        </button>
      </ng-container>
      <button class="btn btn-primary me-1" type="button" *ngIf="activeStepIndex === getVendorFeesStepIndex && isVendorFeesSkipVisible" (click)="navigateForward()">
        <span *ngIf="isStepRunning" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ isStepRunning ? 'Please wait...' : 'Skip >' }}
      </button>
    </div>

  </div>
</div>



