import { Component, Injector, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription } from 'rxjs';
import { combineLatestWith, finalize } from 'rxjs/operators';
import { ApplicationContext, Company, CustomDataConfig, SystemLevel } from 'src/app/models';
import { Configuration } from 'src/app/models/configuration.model';
import { Utils } from '../../../../core/services/utils';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { EnumerationService } from '../../../../services/enumeration-service';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevelService } from '../../../../services/system-level.service';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { CompanyService } from 'src/app/modules/global-admin/company/services/company.service';

@Component({
  selector: 'application-settings',
  templateUrl: './application-settings.component.html',
})
export class ApplicationSettingsComponent extends ApplicationContextBoundComponent implements OnInit {
  restrictCreateNewOption: Configuration;
  newUserProfileWizardDisabled: Configuration;
  sessionTimeout: Configuration;
  autoConvertToPdf: Configuration;
  useScribanForHtmlDocGenRendering: Configuration;
  useDynamicCompression: Configuration;
  taskUpdateExcludeAccepted: Configuration;
  disableBorrowerInviteChannelsConfiguration: Configuration;
  loanHiddenFields: Configuration;
  broadcastManagerRolesWithAccessConfiguration: Configuration;
  broadcastManagerUsersWithAccessConfiguration: Configuration;
  twoFactorAuthenticationRequiredForUserTypesConfiguration: Configuration;
  customDataConfigModel: CustomDataConfig;
  creditReportingScript: Configuration;

  loadingSystemData: boolean;
  dropdownSettings: IDropdownSettings = {};
  hiddenFields = [
    { name: 'Subject Property', value: 'Subject Property' },
    { name: 'Social Security Number', value: 'Social Security Number' },
    { name: 'Appraised Value', value: 'Appraised Value' },
    { name: 'Estimated Value', value: 'Estimated Value' },
  ];
  hiddenFieldsModel = [];
  fieldTypeOptions: EnumerationItem[] = [];
  editorTypeOptions: EnumerationItem[] = [];
  listTypes: EnumerationItem[] = [];
  isSaving: boolean;
  isDataLoaded: boolean = false;

  protected accessSelectionSettings: IDropdownSettings = Object.freeze({
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  });

  protected usersWithAccessToBroadcastManager: EnumerationItem[] = [];
  protected usersWithAccessOptions: EnumerationItem[] = [];

  protected companyEnabledChannels: EnumerationItem[] = [];
  protected disabledBorrowerInviteChannels: EnumerationItem[] = [];

  protected rolesWithAccessToBroadcastManager: EnumerationItem[] = [];
  protected rolesWithAccessOptions: EnumerationItem[] = [];
  protected userTypesForWhichMfaSetupIsRequired: EnumerationItem[] = [];

  protected userTypeOptions: EnumerationItem[] = [
    new EnumerationItem('Admin', 1),
    new EnumerationItem('Borrower', 2),
    new EnumerationItem('Agent', 3),
    new EnumerationItem('TPO', 4)
  ];

  protected company: Company;

  private _applicationContextSubscription: Subscription | null = null;

  constructor(
    injector: Injector,
    private readonly _notificationService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _companyService: CompanyService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadingSystemData = true;
    this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
      const systemLevel = JSON.parse(JSON.stringify(data));
      this.restrictCreateNewOption = systemLevel.restrictCreateNewOption || {};
      this.newUserProfileWizardDisabled = systemLevel.newUserProfileWizardDisabled || new Configuration('NewUserProfileWizardDisabled');
      this.disableBorrowerInviteChannelsConfiguration = systemLevel.disableBorrowerInviteChannelsConfiguration
        || new Configuration('BorrowerInviteDisabledChannels');
      this.broadcastManagerUsersWithAccessConfiguration = systemLevel.broadcastManagerUsersWithAccessConfiguration
        || new Configuration('BroadcastManagerUsersWithAccess');
      this.broadcastManagerRolesWithAccessConfiguration = systemLevel.broadcastManagerRolesWithAccessConfiguration
        || new Configuration('BroadcastManagerRolesWithAccess');
      this.twoFactorAuthenticationRequiredForUserTypesConfiguration = systemLevel.twoFactorAuthenticationRequiredForRolesConfiguration
        || new Configuration('TwoFactorAuthenticationRequiredForRoles');
      this.sessionTimeout = systemLevel.sessionTimeout || {};
      this.autoConvertToPdf = systemLevel.autoConvertToPdf || {};
      this.useScribanForHtmlDocGenRendering = systemLevel.useScribanForHtmlDocGenRendering
        || new Configuration('UseScribanForHtmlDocGenRendering');
      this.useScribanForHtmlDocGenRendering.companyId = this.applicationContext.userPermissions.companyId;
      this.useDynamicCompression = systemLevel.useDynamicCompression || {};
      this.taskUpdateExcludeAccepted = systemLevel.taskUpdateExcludeAccepted || {};
      this.loanHiddenFields = systemLevel.loanHiddenFields || {};
      this.customDataConfigModel = systemLevel.customDataConfigModel || {};
      this.creditReportingScript = systemLevel.creditReportingScript || {};

      this.dropdownSettings = {
        idField: 'value',
        textField: 'name',
        itemsShowLimit: 2,
      };

      if (this.loanHiddenFields?.valueStr) {
        this.hiddenFieldsModel = this.loanHiddenFields.valueStr.split(',').map(el => ({ name: el, value: el }))
      } else {
        this.hiddenFieldsModel = [];
      }

      if (!this.creditReportingScript.valueStr) {
        this.creditReportingScript.valueStr = "";
      }

      this._enumsService.getCustomDataEnumerations().subscribe((enums) => {
        this.fieldTypeOptions = enums.CustomDataFieldType;
        this.editorTypeOptions = enums.CustomDataEditorType;
      });
      this._enumsService.getExpressionEnumerations().subscribe((enums) => {
        this.listTypes = enums.FieldType.filter((el, idx) => idx > 4);
      });
      this.subscribeToApplicationContext(systemLevel);
      this.isDataLoaded = true;

    }).add(() => this.loadingSystemData = false);
  }

  ngOnDestroy() {
    this._applicationContextSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  private subscribeToApplicationContext(systemLevel: SystemLevel): void {
    this._applicationContextSubscription?.unsubscribe();

    this._applicationContextSubscription = this.applicationContextService
      .context.subscribe({
        next: (context: ApplicationContext) => {
          const companyId = context.userPermissions.companyId;
          this.company = context.globalConfig.company.find(c => c.companyId == companyId);
          this.initApplicationContext(context, systemLevel);
        },
        complete: () => {
          this._applicationContextSubscription = null;
        },
      });
  }

  private initApplicationContext = (context: ApplicationContext, systemLevel: SystemLevel): void => {
    const companyId = context.userPermissions.companyId;

    this.companyEnabledChannels = context.globalConfig.enabledChannels;

    const getUsersWithAccessOptions = (): EnumerationItem[] => {
      const companyUsers = context.globalConfig.usersAll.filter(
        u => u.companyId === companyId && (u.firstName || u.lastName) && u.active,
      );
      return companyUsers.map(u => new EnumerationItem(
        Utils.getPersonsDisplayName(u),
        u.userCompanyGuid,
      ));
    };
    this.usersWithAccessOptions = getUsersWithAccessOptions();

    const getRolesWithAccessOptions = (): EnumerationItem[] => {
      const companyRoles = context.globalConfig.roles.filter(
        r => r.companyId === companyId,
      );
      return companyRoles.map(r => new EnumerationItem(
        r.roleName,
        r.roleId,
      ));
    };
    this.rolesWithAccessOptions = getRolesWithAccessOptions();

    this.populateDisabledBorrowerInviteChannels(context, systemLevel);
    this.populateRolesWithAccessToBroadcastManager(context, systemLevel);
    this.populateUsersWithAccessToBroadcastManager(context, systemLevel);
    this.populateUserTypesForWhichMfaSetupIsRequired(context, systemLevel);
  }

  private populateDisabledBorrowerInviteChannels = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.borrowerInviteDisabledChannels) {
      const channels = systemLevel.borrowerInviteDisabledChannels.valueStr?.split(',');
      if (!channels) {
        return;
      }
      channels.forEach(disabledChannel => {
        const companyChannels = context.globalConfig.enabledChannels;

        const channel = companyChannels.find(c => c.name == disabledChannel);
        if (channel) {
          this.disabledBorrowerInviteChannels.push(channel);
        }
      });
    }
  }

  private populateRolesWithAccessToBroadcastManager = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.broadcastManagerRolesWithAccess) {
      const roleIds = systemLevel.broadcastManagerRolesWithAccess.valueStr?.split(',').filter(rid => !!rid);
      if (!roleIds) {
        return;
      }
      roleIds.forEach(rid => {
        const companyRoles = context.globalConfig.roles.filter(
          r => r.companyId === context.userPermissions.companyId,
        );
        const role = companyRoles.find(r => r.roleId == Number(rid.trim()));
        if (role) {
          const selectedRole = new EnumerationItem(role.roleName, role.roleId);
          this.rolesWithAccessToBroadcastManager.push(selectedRole);
        }
      });
    }
  }

  private populateUserTypesForWhichMfaSetupIsRequired = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.twoFactorAuthenticationRequiredForRoles) {
      const roleIds = systemLevel.twoFactorAuthenticationRequiredForRoles.valueStr?.split(',').filter(rid => !!rid);
      if (!roleIds) {
        return;
      }
      roleIds.forEach(rid => {
        const userType = this.userTypeOptions.find(r => r.value == Number(rid.trim()));
        if (userType) {
          const selectedUserType = new EnumerationItem(userType.name, userType.value);
          this.userTypesForWhichMfaSetupIsRequired.push(selectedUserType);
        }
      });
    }
  }

  private populateUsersWithAccessToBroadcastManager = (context: ApplicationContext, systemLevel: SystemLevel) => {
    if (systemLevel.broadcastManagerUsersWithAccess) {
      const userIds = systemLevel.broadcastManagerUsersWithAccess.valueStr?.split(',').filter(uid => !!uid);
      if (!userIds) {
        return;
      }
      userIds.forEach(uid => {
        const user = context.globalConfig.usersAll.find(u => u.userCompanyGuid == uid.trim());
        if (user) {
          const selectedUser = new EnumerationItem(Utils.getPersonsDisplayName(user), user.userCompanyGuid);
          this.usersWithAccessToBroadcastManager.push(selectedUser);
        }
      });
    }
  }

  save(): void {
    this.isSaving = true;
    this.loanHiddenFields.valueStr = this.hiddenFieldsModel.map(el => el.value).join();

    this.disableBorrowerInviteChannelsConfiguration.valueStr = this.disabledBorrowerInviteChannels.map(c => c.name).join(",");
    this.broadcastManagerRolesWithAccessConfiguration.valueStr = this.rolesWithAccessToBroadcastManager.map(r => r.value).join(",");
    this.broadcastManagerUsersWithAccessConfiguration.valueStr = this.usersWithAccessToBroadcastManager.map(r => r.value).join(",");
    this.twoFactorAuthenticationRequiredForUserTypesConfiguration.valueStr = this.userTypesForWhichMfaSetupIsRequired.map(r => r.value).join(",");

    this._companyService.updateCompanyBasic(this.company)
      .pipe(
        combineLatestWith([
          this._systemLevelService.saveConfiguration(this.restrictCreateNewOption),
          this._systemLevelService.saveConfiguration(this.newUserProfileWizardDisabled),
          this._systemLevelService.saveConfiguration(this.disableBorrowerInviteChannelsConfiguration),
          this._systemLevelService.saveConfiguration(this.broadcastManagerRolesWithAccessConfiguration),
          this._systemLevelService.saveConfiguration(this.broadcastManagerUsersWithAccessConfiguration),
          this._systemLevelService.saveConfiguration(this.twoFactorAuthenticationRequiredForUserTypesConfiguration),
          this._systemLevelService.saveConfiguration(this.sessionTimeout),
          this._systemLevelService.saveConfiguration(this.autoConvertToPdf),
          this._systemLevelService.saveConfiguration(this.useScribanForHtmlDocGenRendering),
          this._systemLevelService.saveConfiguration(this.useDynamicCompression),
          this._systemLevelService.saveConfiguration(this.taskUpdateExcludeAccepted),
          this._systemLevelService.saveConfiguration(this.loanHiddenFields),
          this._systemLevelService.saveConfiguration(this.creditReportingScript),
          this._systemLevelService.saveCustomDataConfig(this.customDataConfigModel),
        ]),
        finalize(() => {
          this.isSaving = false;
          this.applicationContextService.reloadCompanies().subscribe();
        })
      )
      .subscribe({
        next: res => {
          this.applicationContextService.updateUserPermission('restrictCreateNewOption', this.restrictCreateNewOption.value === 1);
          this.applicationContextService.updateUserPermission('autoConvertToPdf', this.autoConvertToPdf.value === 1);
          this.applicationContextService.updateUserPermission('useDynamicCompression', this.useDynamicCompression.value === 1);
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}
