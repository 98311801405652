<div class="modal-header">
  <h5 class="modal-title"> LenderPrice - Products & Pricing </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <lenderprice-pricer (pricingCompleted)="onPricingCompleted($event)"></lenderprice-pricer>
  </div>
</div>
<div class="modal-footer modal-footer--sticky">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()" data-bs-dismiss="modal"> OK </button>
</div>
