<div class="modal-header">
  <h5 class="modal-title">Mortgage Insurance Information Calculation</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Upfront Mortgage Insurance Premium/Funding/Guarantee Fee</h4>
    </div>
    <div class="card-body">
      <div class="loan-app">
        <div class="grid-layout edit-area">
          <div class="grid-layout-row">
            <urla-currency-input
              class="col-sm-6 col-xs-6 negative-margin-5 negative-margin-5"
              [name]="'miCompanyNameTypeOtherDescription'"
              [(ngModel)]="mortgage.mortgageTerm.amount"
              [label]="'Base Loan Amount'"
              (ngModelChange)="onBaseLoanAmountChanged()">
            </urla-currency-input>
          </div>
          <div class="grid-layout-row">
            <urla-boolean-checkbox class="col-sm-4 col-xs-4 negative-margin-5" layout="single" [label]="'Locked'" [name]="'locked'"
              [(ngModel)]="locked">
            </urla-boolean-checkbox>
          </div>
          <div class="grid-layout-row">
            <urla-percentage-input
              class="col-sm-6 col-xs-6 negative-margin-5"
              [name]="'miOrFundingFeeTotalPercent'"
              [label]="'MIP / Funding / Guarantee'"
              [formElement]="true"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent"
              (ngModelChange)="onMiOrFundingFeeTotalPercentChanged()"
              [disabled]="locked">
            </urla-percentage-input>
            <urla-currency-input
              class="col-sm-6 col-xs-6 negative-margin-5"
              [name]="'miOrFundingFeeTotalAmount'"
              [disabled]="!locked"
              [label]="'MIP / Funding / Guarantee'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount"
              (ngModelChange)="onMiOrFundingFeeTotalAmountChanged()">
            </urla-currency-input>
          </div>
          <div class="grid-layout-row" *ngIf="locked">
            <urla-currency-input
              class="col-sm-6 col-xs-6 negative-margin-5"
              [name]="'miOrFundingFeeUpFrontAmount'"
              [label]="'Amount Paid in Cash'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.miOrFundingFeeUpFrontAmount"
              (ngModelChange)="onMiOrFundingFeeUpFrontAmountChanged()">
            </urla-currency-input>
          </div>
          <div class="grid-layout-row">
            <div class='field-with-checkbox col-12 col-lg-6 negative-margin-5'>
              <urla-currency-input
                (ngModelChange)='onMiOrFundingFeeFinancedAmountChanged($event)'
                [disabled]='mortgage.mortgageInsuranceDetail.financeEntireMiOrFundingFee'
                [ngModel]='miOrFundingFeeAmount'
                label='Upfront MIP/Funding/Guarantee Fee Financed'
                name='miOrFundingFeeFinancedAmount'>
              </urla-currency-input>
              <finance-entire-fee-checkbox
                [mortgage]='mortgage'
                (valueChange)='onFinanceAllChanged()'
                class='align-self-center ms-3'>
              </finance-entire-fee-checkbox>
            </div>
            <urla-currency-input class='col negative-margin-5' [name]="'totalLoanAmount'"
              [label]="'Loan Amount with Upfront MIP/Funding Fee'" [(ngModel)]="mortgage.mortgageTerm.totalLoanAmount">
            </urla-currency-input>
          </div>
          <div class="grid-layout-row">
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single"
              [label]="'Refund Prorated Unearned Up Front'" [name]="'refundProratedUnearnedUpFront'"
              [(ngModel)]="mortgage.governmentLoanDetail.refundProratedUnearnedUpFront">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single" [label]="'Round to Nearest $50'"
              [name]="'roundToNearest50Dollars'" [(ngModel)]="mortgage.governmentLoanDetail.roundToNearest50Dollars">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single"
              [label]="'Changes for the Insurance are Added to Your Loan Payments'"
              [name]="'insuranceChargesIncludedInLoanPayments'"
              [(ngModel)]="mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single"
              [label]="'Changes for the Insurance are Collected Upfront at Loan Closing'"
              [name]="'insuranceChargesCollectedAtClosing'"
              [(ngModel)]="mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single" [label]="'Lender Paid Mortgage Insurance'"
              [name]="'lenderPaidMortgageInsurance'" [(ngModel)]="mortgage.governmentLoanDetail.lenderPaidMortgageInsurance">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single" [label]="' MI Factor Field Locked'"
              [name]="' MI Factor Field Locked'" [(ngModel)]="miFactorFieldLocked">
            </urla-boolean-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Monthly Mortgage Insurance</h4>
    </div>
    <div class="card-body">
      <div class="loan-app">
        <div class="grid-layout edit-area">
          <div class="grid-layout-row">
            <urla-dropdown class="col-sm-6 col-xs-6 negative-margin-5" [options]="calculationBasedOnOptions" [name]="'calculationBasedOnOptions'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.calculationBasedOn" [label]="'Calculated Based On'"></urla-dropdown>
            <div class="col-sm-3">
              <button class="btn btn-primary btn btn-primary mx-2 mt-4" (click)="calculateMi()">Calculate MI</button>
            </div>
          </div>
          <div class="grid-layout-row">
            <urla-grid-cell class="negative-margin-5">
              <span class="month-position">1.</span>
            </urla-grid-cell>
            <urla-percentage-input class="col-xs-6 col-sm-2" [(ngModel)]="mortgage.mortgageInsuranceDetail.level1Percent"
              [name]="'level1Percent'">
            </urla-percentage-input>
            <urla-digits-input class="col-sm-2 col-xs-6" [name]="'level1Months'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.level1Months">
            </urla-digits-input>
            <urla-grid-cell class="negative-margin-5">
              <span class="month-position">Months</span>
            </urla-grid-cell>
          </div>
          <div class="grid-layout-row">
            <urla-grid-cell>
              <span class="month-position">2.</span>
            </urla-grid-cell>
            <urla-percentage-input class="col-xs-6 col-sm-2" [(ngModel)]="mortgage.mortgageInsuranceDetail.level2Percent"
              [name]="'level2Percent'">
            </urla-percentage-input>
            <urla-digits-input class="col-sm-2 col-xs-6" [name]="'level2Months'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.level2Months">
            </urla-digits-input>
            <urla-grid-cell>
              <span class="month-position">Months</span>
            </urla-grid-cell>
          </div>
          <div class="grid-layout-row">
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single"
              [label]="'Calculate Based on Remaining Balance'" [name]="'calculateBasedOnRemainingBalance'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.calculateBasedOnRemainingBalance">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single" [label]="'Midpoint Payment Cancelation'"
              [name]="'midpointPaymentCancellation'" [(ngModel)]="mortgage.mortgageInsuranceDetail.midpointPaymentCancellation">
            </urla-boolean-checkbox>
            <urla-boolean-checkbox class="col-sm-12 col-xs-12 negative-margin-5" layout="single"
              [label]="'Declining Renewals'"
              [name]="'decliningRenewals'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.decliningRenewals">
            </urla-boolean-checkbox>
          </div>
          <div class="grid-layout-row">
            <urla-digits-input class="col-sm-4 col-xs-6 negative-margin-5" [name]="'monthsPrepaid'"
              [label]="'Number of Months MI being Collected'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.monthsPrepaid">
            </urla-digits-input>
            <urla-boolean-checkbox class="col-sm-4 col-xs-6 negative-margin-5 align-items-center" layout="single" [label]="'Prepaid'" [name]="'isMIPrepaid'"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.isMIPrepaid"
              (ngModelChange)="onIsPrepaidChanged()">
            </urla-boolean-checkbox>
            <urla-currency-input class="col-sm-4 col-xs-6 negative-margin-5" [name]="'prepaidPremiumTotal'"
              [label]="'Prepaid Amount'"
              [disabled]="!mortgage.mortgageInsuranceDetail.isMIPrepaid"
              [(ngModel)]="mortgage.mortgageInsuranceDetail.prepaidPremiumTotal">
            </urla-currency-input>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer form-footer">
    <div class="row">
      <div class="col-xs-6 text-left">
        <div class="button-items" style="float: right;">
          <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
          <button type="button" class="btn btn-primary" (click)="onOkClicked()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
