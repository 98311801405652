
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DialList } from 'src/app/modules/dialer/models/dial-list.model';
import { ContactListType, ContactList } from 'src/app/models';
import { ContactListService } from 'src/app/services/contact-list.service';

@Component({
  templateUrl: './new-dial-list-dialog.component.html',
})
export class NewDialListDialogComponent implements OnInit {

  @Input() contactListType: ContactListType = ContactListType.Borrower;

  @ViewChild('dialListForm') dialListForm: NgForm;

  contactLists: ContactList[] = [];
  data: DialList = new DialList();
  isSaving: boolean;
  isLoading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private _notificationService: NotificationService,
    private _contactListService: ContactListService,
    private _dialerService: DialerService,
    private _ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.isLoading = true;

    this._contactListService.getContactListsByType(this.contactListType)
      .subscribe({
        next: (res) => {
          this.contactLists = res.filter(cl => !cl.isCustom);
        },
        error: (error) => {
          this._notificationService.showError(error?.message || "Couldn't get contact list.", "Error");
        }
      }).add(() => this.isLoading = false);
  }

  ngAfterViewChecked(): void {
    this._ref.detectChanges();
  }

  save() {
    this.dialListForm.form.markAllAsTouched();
    if (!this.dialListForm.form.valid) return;

    this.isSaving = true;
    this._dialerService
      .saveDialList(this.data)
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Dial list added successfully.',
            'Dial List'
          );
          this.activeModal.close(res);
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't add the new dial list.",
            'Dial List'
          );
        }
      }).add(() => this.isSaving = false);
  }
}

