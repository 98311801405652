import { argbFromHex, Hct, hexFromArgb, lerp, TonalPalette } from '@material/material-color-utilities';

export module ColorUtils {
  /**
   * Hashes given value and generates a 6-digit colour code
   *
   * @see https://stackoverflow.com/a/16348977
   * @param value any string value
   * @returns {string} 6-digit color code (e.g. "#FF0000")
   */
  export function colorFromString(value: string): string {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
  }

  /**
   * Converts very light or dark colors to suitable colors.
   * @param color 6-digit color code (e.g. "#FF0000")
   * @returns {string} 6-digit color code (e.g. "#FF0000")
   */
  export function normalizeColor(color: string): string {
    const hct = Hct.fromInt(argbFromHex(color))
    const hue = hct.hue;
    const chroma = hct.chroma;
    const palette = TonalPalette.fromHueAndChroma(
      hue,
      Math.max(48, chroma),
    );

    return hexFromArgb(palette.tone(40));
  }

  /**
   * Generates a color with {@link colorFromString} then passes it to
   * {@link normalizeColor}
   * @param value 6-digit color code (e.g. "#FF0000"),
   * @returns {string} 6-digit color code (e.g. "#FF0000")
   */
  export function normalizedColorFromString(value: string): string {
    const color = colorFromString(value);
    return normalizeColor(color);
  }

  export function lerpColor(color1: string, color2: string, ratio: number): string {
    const c1 = parseInt(color1, 16);
    const c2 = parseInt(color2, 16);
    return Math.round(lerp(c1, c2, ratio)).toString(16).padStart(6, '0');
  }

  export function getLuminance(color: string): number {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  }

  /**
   * Determines if the given color is a light or dark color.
   * @param {string} color 6-digit color code (e.g. "#FF0000")
   * @param {object} [options] The option object.
   * @param {number} [options.threshold=0.5] The threshold to determine if the color is light or dark.
   */
  export function isColorLight(color: string, options?: { threshold?: number }): boolean {
    return getLuminance(color) > (options?.threshold ?? 0.5);
  }
}
