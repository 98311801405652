import { Component, Input, OnInit } from '@angular/core';
import { DocPrep } from '../../models/doc-prep.model';

@Component({
  selector: 'document-preparation-lender-info',
  templateUrl: 'document-preparation-lender-info.component.html'
})

export class DocumentPreparationLenderInfoComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  constructor() { }

  ngOnInit() { }

  protected emailFormatValidate = (email): boolean => {
    return !email ||
      (
        email
        &&
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      )
  }
}