export class AppraisalThirdPartyContact {
    unparsedName?: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    homePhoneNumber: string;
    otherPhoneNumber: string;

    constructor () {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.role = "";
        this.homePhoneNumber = "";
        this.otherPhoneNumber = "";
    }
}