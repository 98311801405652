<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title d-flex align-items-center justify-content-between">
          <span><i class="fas fa-file-pdf-o me-2"></i> Disclosures Wizard</span>
          <span>
            <button *ngIf="(!atFirstStep && !isWizardCompleted) || hasCancelButton" type="button"
              class="btn btn-primary" (click)="onCancelClicked()">
              Cancel
            </button>
          </span>
        </h4>
      </div>
      <div class="card-body">
        <p-steps [model]="steps" [(activeIndex)]="activeStepIndex"></p-steps>


        <div *ngIf="generatedJob?.jobStatus == 'Failed' && [1, 2].includes(activeStepIndexWithOffset)"
          class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <span>Please correct the fatal and critical errors below and return to this page to trigger another audit.
                {{ 'Retry' }}.</span>
            </div>
          </div>
        </div>

        <div *ngIf="errors" class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3"
          role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <span>{{ errors }}</span>
            </div>
          </div>
        </div>

        <div *ngIf="disclosuresAlreadyProcessed"
          class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <span>Disclosures have already been sent on this file. Please reach out to your AE if you feel you are
                seeing this message in error.</span>
            </div>
          </div>
        </div>

        <div *ngIf="activeStepIndexWithOffset == 1" class="d-flex align-items-center justify-content-center"
          [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
          <div *ngIf="loadingData" class="d-flex justify-content-center align-items-center">
            <div class="d-flex">
              <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
              <h5 class="font-18 text-muted me-4">
                'Syncing Loan Data' - {{ generatedJob?.jobStatus || 'Pending' }} ...
              </h5>
            </div>
          </div>
          <div *ngIf="auditingLoan" class="d-flex justify-content-center align-items-center">
            <div class="d-flex">
              <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
              <h5 class="font-18 text-muted me-4">
                'Running Loan Audits' - {{ generatedJob?.jobStatus || 'Pending' }} ...
              </h5>
            </div>
          </div>

          <div *ngIf="!loadingData && !auditingLoan">
            <form id="disclosuresForm" #disclosuresForm="ngForm" class="w-100" *ngIf="!selectedDisclosureType">
              <div>
                <h4 class="card-title mb-3">Disclosure Type</h4>
                <select [(ngModel)]="selectedDisclosureType" (ngModelChange)="onDisclosureTypeChanged()" [ngClass]="{
                    'is-invalid':
                      disclosureType &&
                      disclosureType.touched &&
                      disclosureType.invalid
                  }" name="disclosureType" #disclosureType="ngModel" required class="form-select">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let disclosureType of disclosureTypes" [ngValue]="disclosureType.value">
                    {{ disclosureType.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Disclosure Type is required.</div>
              </div>
            </form>
            <div class="d-flex justify-content-center align-items-center"
              [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}"
              *ngIf="selectedDisclosureType && generatedJob && auditPassedNoRecommendations">
              <div class="d-flex">
                <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></span>
                <h5 class="font-18 text-muted me-4">
                  Loan Data Audit Passed.
                </h5>
              </div>
            </div>
            <div class="row mt-4" *ngIf="selectedDisclosureType && generatedJob && !auditPassedNoRecommendations"
              class="d-flex justify-content-center align-items-center"
              [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
              <div class="col-md-12">
                <meridian-disclosure-info [type]="'audit'" [job]="generatedJob"
                  [documentsVisible]="activeStepIndexWithOffset == 2"
                  [showOnlyRecommendedValidations]="activeStepIndexWithOffset == 1">
                </meridian-disclosure-info>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="activeStepIndexWithOffset == 2" class="d-flex align-items-center justify-content-center"
        [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
          <div *ngIf="loadingData" class="d-flex justify-content-center align-items-center">
            <div class="d-flex">
              <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
              <h5 class="font-18 text-muted me-4">
                'Generating Disclosures' - {{ generatedJob?.jobStatus || 'Pending' }} ...
              </h5>
            </div>
          </div>
          <div *ngIf="!loadingData">
            <div *ngIf="generatedJob?.jobStatus == 'Completed' && !isStepRunning">
              <div class="d-flex">
                <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></span>
                <h5 class="font-18 text-muted me-4">
                  Disclosures have been generated successfully.
                </h5>
              </div>
            </div>
            <div class="row mt-4" *ngIf="selectedDisclosureType && generatedJob && !loadingData"
              [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
              <div class="col-md-12">
                <div class="alert alert-info">
                  <i class="fa fa-info-circle me-2"></i>You <b>MUST</b> click 'Send Disclosures' at the bottom of the
                  screen to deliver these disclosures to the LO and Borrower(s).
                </div>
              </div>
              <div class="col-md-12">
                <meridian-disclosure-info [type]="'generate'" [job]="generatedJob" [isTpoUser]="isTpoUser"
                  [documentsVisible]="activeStepIndexWithOffset == 2"
                  [showOnlyRecommendedValidations]="activeStepIndexWithOffset == 1">
                </meridian-disclosure-info>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="activeStepIndexWithOffset == 3" class="d-flex justify-content-center align-items-center" [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
          <div *ngIf="loadingData" >
            <div class="d-flex">
              <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
              <h5 class="font-18 text-muted me-4" *ngIf="generatedJob?.jobStatus != 'Completed'">
                'Sending Disclosures' - {{ generatedJob?.jobStatus || 'Pending' }} ...
              </h5>
              <h5 class="font-18 text-muted me-4" *ngIf="generatedJob?.jobStatus == 'Completed'">
                'Syncing Loan File' ...
              </h5>
            </div>
          </div>
          <div *ngIf="isWizardCompleted && !isStepRunning">
            <div class="d-flex">
              <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-check-circle">
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg></span>
              <h5 class="font-18 text-muted me-4">
                Disclosures have been sent successfully.
              </h5>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center my-2">
          <button type="button" *ngIf="isWizardCompleted && isTpoUser" class="btn btn-primary me-1"
            (click)="onProceedToLoanSubmission()">
            Proceed to Loan Submission
          </button>
          <button type="button" *ngIf="!isWizardCompleted" [disabled]="
              atFirstStep || loadingData || isStepRunning
            " class="btn btn-primary me-1" (click)="onBackClicked()">
            &lt; Back
          </button>
          <button class="btn btn-primary me-1" type="button" (click)="onNextClicked()" *ngIf="!isWizardCompleted &&
              ((
                generatedJob?.jobStatus != 'Failed' &&
                (activeStepIndexWithOffset != 1 ||
                  (activeStepIndexWithOffset == 1 && generatedJob &&
                    !thereAreRequiredFieldValuesInAudits(generatedJob) &&
                    !thereAreRecommendedFieldValuesInAudits(generatedJob))
              )) || (loadingData && !generatedJob && [1, 2, 3].includes(activeStepIndexWithOffset)))"
            [disabled]="loadingData || invalidPackageConditions.length || errors">
            <span *ngIf="isStepRunning ||
            ( loadingData && [1, 2, 3].includes(activeStepIndexWithOffset) && (generatedJob?.jobStatus != 'Completed' || !generatedJob))
            ; else nextBtnLabel">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Please wait...
            </span>
            <ng-template #nextBtnLabel>
              {{nextButtonLabel}}
            </ng-template>
          </button>

          <button type="button" class="btn btn-primary" (click)="skipAndGenerate()"
            [disabled]="loadingData || isStepRunning" *ngIf="
              activeStepIndexWithOffset == 1 &&
              generatedJob &&
              !thereAreRequiredFieldValuesInAudits(generatedJob) &&
              thereAreRecommendedFieldValuesInAudits(generatedJob)
            "> Generate >
          </button>

          <button type="button" class="btn btn-primary" (click)="onRetryJob(LosDisclosureJobTypeEnum.audit)"
            *ngIf="activeStepIndexWithOffset == 1 && errors">
            Retry
          </button>

          <button type="button" class="btn btn-primary" (click)="onBackClicked()"
            *ngIf="activeStepIndexWithOffset == 2 && (generatedJob?.jobStatus == 'Failed' || errors)">
            Back
          </button>

          <button type="button" class="btn btn-primary" (click)="onRetryJob(LosDisclosureJobTypeEnum.send)"
            *ngIf="activeStepIndexWithOffset == 3 && (generatedJob?.jobStatus == 'Failed' || errors)">
            Retry
          </button>
        </div>
      </div>
    </div>
  </div>
</div>