export class LeadRouteHistory {
  leadRouteHistoryId: number;
  leadId: number;
  routeDate: Date;
  leadRouteType: LeadRouteType;
  distributionMode: DistributionMode;
  routedBy: string;
  state: string;
  routeAcceptedDate: Date;
  routeAcceptedUserId: string;
  applicationId: number;
  agentId: number;
  borrowerId: number;
  externalCompanyId: number;
  creditMonitoringDataId: number;
  contactListId: number;
  contactListRecordId: number;
  leadRouteId: number;
  userId: string;
  leadFirstName: string;
  leadLastName: string;
  leadRouteName: string;
  leadRefId: string;
  leadCampaignId: number;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum LeadRouteType{
  Route = "Route",
  Redistribution = "Redistribution",
  WarmTransfer = "WarmTransfer",
  Fetch = "Fetch",
  InboundCallRouting = "InboundCallRouting"
}

export enum DistributionMode {
  FreeForAll = "FreeForAll",
  Assign = "Assign",
  Unassign = "Unassign"
}
