<div class="modal-header">
  <h5 class="modal-title">Credential</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #credentialForm="ngForm">
    <div class="container">
      <div class="row pt-3" *ngIf="channels.length > 0">
        <div class="col-md-2 text-end">
          <label class="custom-control-label" for="channels-dropdown">Channel</label>
        </div>
        <div class="col-md-4">
          <ng-select width="100%" name="channels-dropdown" bindLabel="name" bindValue="value"
            placeholder="Select Channels" [closeOnSelect]="false" [multiple]="true" (change)="setCredentialChannels()"
            [(ngModel)]="selectedChannels" [items]="channels" appendTo="body">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice:0:2">
                <span class="ng-value-label">{{item.name}}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 2">
                <span class="ng-value-label">{{items.length - 2}} more...</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row pt-3" *ngIf="scope != 'TpoUser'">
        <div class="col-md-2 text-end">
          <label class="custom-control-label" for="duUserName">Username</label>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" name="duUserName" [(ngModel)]="duCredential.userName"
            [ngClass]="{'is-invalid' : duUserNameField && duUserNameField.touched && duUserNameField.invalid}"
            #duUserNameField="ngModel" required />
        </div>
        <div class="col-md-2 text-end">
          <label class="custom-control-label" for="duPassword">Password</label>
        </div>
        <div class="col-md-4">
          <password-input [name]="'duPassword'" [(ngModel)]="duCredential.password" required="true"></password-input>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-md-12">
          <div class="fieldset-title">
            <div class="row">
              <div class="col-md-2 pt-2 ps-2">Credit Providers</div>
              <div *ngIf="!duFannieMaeLoadingError && duCreditProviders.length && scope != 'TpoUser'" class="col-md-3 text-start">
                <ng-container *ngTemplateOutlet="saveAndLoadButton"></ng-container>
              </div>
              <div *ngIf="!duFannieMaeLoadingError" class="col-md-4 text-start">
                <button class="btn btn-outline-primary" (click)="addCreditProvider()"
                  [hidden]="!(duCredential.userName && duCredential.password) && scope != 'TpoUser'">
                  Add Credit Provider
                </button>
              </div>
              <div class="alert alert-danger border-0 fs-6 mt-3 mb-3"
                *ngIf="duFannieMaeLoadingError && scope != 'TpoUser'" role="alert">
                <strong><i class="la la-exclamation-circle text-danger"></i></strong> DU Authentication failed. Please
                Update your DU
                credentials.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!duFannieMaeLoadingError">
        <div class="row pt-3" *ngIf="duKeyValuePairs.creditProviders.length">
          <div class="col-md-2 text-end">
            <label class="custom-control-label" for="defaultCreditProvider">Default Credit Provider</label>
          </div>
          <div class="col-md-4">
            <select class="form-select" name="defaultCreditProvider"
              [(ngModel)]="duKeyValuePairs.defaultCreditProvider.value">
              <option value="">-- Select One --</option>
              <option *ngFor="let item of duKeyValuePairs.creditProviders" [ngValue]="item.key">
                {{ item.key }}
              </option>
            </select>
          </div>
        </div>
        <div class="row pt-3" *ngIf="!duCreditProviders.length && scope != 'TpoUser'">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <ng-container *ngTemplateOutlet="saveAndLoadButton"></ng-container>
          </div>
        </div>

        <div class="row pt-3" *ngFor="
              let item of duKeyValuePairs.creditProviders;
              let index = index
            ">
          <div class="col-md-2 text-end">
            <label class="custom-control-label" for="duCreditProv_{{index}}">Credit Provider Name</label>
          </div>
          <div class="col-md-2 position-relative">
            <select name="duCreditProv_{{index}}" class="form-select" [(ngModel)]="item.key">
              <option value="">-- Select One --</option>
              <option *ngFor="let ele of duCreditProviders; trackBy: trackByKey" [ngValue]="ele.key">
                {{ ele.displayText }}
              </option>
            </select>
            <span *ngIf="loadingDuAgencies" id="duAgenciesSpinner" class="spinner-border spinner-border-sm"></span>
          </div>
          <div class="col-md-1 text-end">
            <label class="custom-control-label" for="duProviderAlias_{{index}}">Alias</label>
          </div>
          <div class="col-md-1">
            <input type="text" class="form-control" name="duProviderAlias_{{index}}" [(ngModel)]="item.alias" />
          </div>
          <div class="col-md-1 text-end">
            <label class="custom-control-label" for="duProviderUserName_{{index}}">Username</label>
          </div>
          <div class="col-md-1">
            <input type="text" class="form-control" name="duProviderUserName_{{index}}" [(ngModel)]="item.userName" />
          </div>
          <div class="col-md-1 text-end">
            <label class="custom-control-label" for="duCreditProvPassword_{{index}}">Password</label>
          </div>
          <div class="col-md-2">
            <password-input [name]="'duCreditProvPassword_' + index" [(ngModel)]="item.password">
            </password-input>
          </div>
          <div class="col-md-1">
            <confirm-delete [list]="duKeyValuePairs.creditProviders" [index]="index"></confirm-delete>
          </div>
        </div>
      </div>
      <div *ngIf="scope != 'TpoUser'">
        <div class="row pt-4">
          <div class="col-md-12">
            <div class="fieldset-title">
              <div class="row">
                <div class="col-md-2 pt-2 ps-2">Institutions</div>
                <div class="col-md-4 text-start">
                  <button class="btn btn-outline-primary" (click)="addInstitution()">
                    Add Institutions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-3" *ngFor="let item of duKeyValuePairs.institutions; let index = index">
          <div class="col-md-2 text-end">
            <label class="custom-control-label" for="duInstituationName-{{index}}">Name</label>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control" name="duInstituationName-{{index}}" [(ngModel)]="item.name" />
          </div>
          <div class="col-md-1 text-end">
            <label class="custom-control-label" for="duInstituationId-{{index}}">Institution Id</label>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control" name="duInstituationId-{{index}}"
              [(ngModel)]="item.institutionId" />
          </div>
          <div class="col-md-1">
            <confirm-delete [list]="duKeyValuePairs.institutions" [index]="index"></confirm-delete>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save</span>
  </button>
</div>
<ng-template #saveAndLoadButton>
  <button class="btn btn-outline-primary" *ngIf="agenciesRequested"
    [disabled]="!(duCredential.userName && duCredential.password)" (click)="saveAndLoadCreditProviders()"
    style="width: 215px">
    <span *ngIf="!isSaving">
      {{(!duFannieMaeLoadingError && duCreditProviders.length) ? 'Reload' : 'Save & Load'}} Credit Providers
    </span>
    <span *ngIf="isSaving">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </span>
  </button>
</ng-template>