<div class="multi-borrower-fico">
  <div class="d-flex justify-content-between">
    <h4 class="card-title">
      <strong> Loan FICO</strong>
    </h4>
    <span>
      <a (click)="orderCreditClicked()" class="text-primary" style="font-size: small;"><i class="fas fa-check me-1"
          style="color: #3aaf80"></i> Order Credit </a>
    </span>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12">
      <multi-borrower-fico-info *ngIf="!isLoading; else loading" [borrowers]="borrowers"></multi-borrower-fico-info>
      <ng-template #loading>
        <loading-indicator></loading-indicator>
      </ng-template>
    </div>
  </div>
</div>
