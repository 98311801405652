<div class="card">
    <div class="card-header d-flex align-items-center justify-content-center">
        <h4 class="card-title flex-grow-1">State Licensing</h4>
        <button class="btn btn-primary" (click)="onUpsertStateLicensingDrawerOpened()">
            <i class="fas fa-plus"></i> Add New
        </button>
    </div>
    <div class="card-body">
        <p-table #stateLicensingTable [columns]="stateLicensingColumns" columnResizeMode="fit"
            [value]="stateLicensingList" [paginator]="true" [rows]="25" [resizableColumns]="true"
            responsiveLayout="scroll" scrollable="true" [scrollHeight]="scrollHeight" name="state-licensing-table-v2"
            [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single" sortField="nmls"
            [globalFilterFields]="['state', 'nmls', 'notes', 'issueDate', 'expirationDate', 'active']">
            <ng-template pTemplate="caption">
                <div class="d-flex justify-content-end">
                    <span>
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText #searchText type="text" class="form-control"
                                (input)="stateLicensingTable.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search State Licensing" />
                        </span>
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of stateLicensingColumns | filter: {visible: true}"
                        [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
                        {{ col.header }}
                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td>
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="fa fa-pencil-alt cursor text-primary"
                                (click)="onUpsertStateLicensingDrawerOpened(row)" title="Edit State Licensing"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger"
                                (click)="showDeleteConfirmationDialog(row.userLicenseId)"
                                title="Delete State Licensing"></i>
                        </span>
                    </td>
                    <td>{{ row.state }}</td>
                    <td>{{ row.nmls }}</td>
                    <td>{{ row.issueDate }}</td>
                    <td>{{ row.expirationDate }}</td>
                    <td class="wrap-notes">{{ row.notes }}</td>
                    <td>{{ row.active | yesNo }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7" class="text-center"> There are no state licensing records. </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<drawer #upsertStateLicensingDrawer [name]="'upsertStateLicensingDrawer'" [templateRef]="upsertStateLicensingRef"
    [options]="upsertStateLicensingDrawerOptions">
</drawer>
<ng-template #upsertStateLicensingRef>
    <upsert-state-licensing [userCompanyGuid]="userCompanyGuid" [stateLicensing]="selectedstateLicensingRecord"
        [states]="states" [existingStates]="existingStates" (close)="upsertStateLicensingDrawerClose($event)">
    </upsert-state-licensing>
</ng-template>