import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { ContactListColumnDefinition } from "../models";
import { ConfigurationResponse } from "../models/loan-pass/configuration-response.model";
import { LoanPassExecuteRequest } from "../models/loan-pass/loan-pass-execute-request.model";
import { LoanPassMapping } from "../models/loan-pass/loan-pass-mapping.model";
import { ProductSearchRequest } from "../modules/pricing/models/pricing/product-search-request-info.model";
import { LoanpassPipelineRecord } from "../models/loan-pass/loanpass-pipeline-record.model";
import { LoanPassCreditApplicationField } from "../models/loan-pass/loanpass-credit-application.field.model";
import { BasicPropertyDetail } from "../modules/pricing/models/pricing/basic-property-detail.model";
import { EnumDropdownInfo } from "../models/loan-pass/enum-dropdown-info.model";

export enum LoanPassMappingType {
  toExecuteRequest = "ToExecuteRequest",
  toProductSearchRequest = "ToProductSearchRequest",
  toProduct = "ToProduct",
  toQuote = "ToQuote"
}

@Injectable()
export class LoanPassFieldMappingsService {

  constructor(private readonly _dataService: DataService) {
  }

  getExpressionColumns = (credentialId: number, mappingType: LoanPassMappingType): Observable<ContactListColumnDefinition[]> => {
    let url = `api/loan-pass/${credentialId}/expression-columns/${mappingType}`;
    return this._dataService.get(url);
  }

  getLoanPassFields = (credentialId: number): Observable<ConfigurationResponse> => {
    let url = `api/loan-pass/${credentialId}/api-configuration`;
    return this._dataService.get(url);
  }

  getDefaultMappings = (credentialId: number, mappingType: LoanPassMappingType): Observable<LoanPassMapping[]> => {
    let url = `api/loan-pass/${credentialId}/default-mapping-models/${mappingType}`;
    return this._dataService.get(url)
  }

  // deletes all existing MappingModels. inserts the default mappings.
  updateDefaultMappings = (credentialId: number, mappingType: LoanPassMappingType, deleteExisting: boolean = true): Observable<LoanPassMapping> => {
    let url = `api/loan-pass/${credentialId}/default-mapping-models/${mappingType}?deleteExisting=${deleteExisting}`;
    return this._dataService.post(url, {});
  }

  deleteFieldMapping = (credentialId: number, mappingId: number): Observable<void> => {
    let url = `api/loan-pass/${credentialId}/mapping-model/${mappingId}`;
    return this._dataService.delete(url);
  }

  getFieldMappings = (credentialId: number, mappingType: LoanPassMappingType): Observable<LoanPassMapping[]> => {
    let url = `api/loan-pass/${credentialId}/mapping-models/${mappingType}`;
    return this._dataService.get(url);
  }

  insertFieldMappings = (credentialId: number, mappingType: LoanPassMappingType, fieldMappings: LoanPassMapping[]): Observable<LoanPassMapping[]> => {
    let url = `api/loan-pass/${credentialId}/mapping-models/${mappingType}`;
    return this._dataService.post(url, fieldMappings);
  }

  convertExecuteRequest = (credentialId: number, req: LoanPassExecuteRequest): Observable<ProductSearchRequest> => {
    let url = `api/loan-pass/${credentialId}/convert-execute-request`;
    return this._dataService.post(url, req);
  }

  getExternalPipelineRecord = (credentialId: number, applicationId: number): Observable<LoanpassPipelineRecord> => {
    let url = `api/loan-pass/${credentialId}/external-pipeline-record/${applicationId}`;
    return this._dataService.post(url, null);
  }

  getDefaultLoanPassCreditApplicationFields = (credentialId: number, productSearchRequest: ProductSearchRequest): Observable<LoanPassCreditApplicationField[]> => {
    let url = `api/loan-pass/${credentialId}/convert/to-credit-application-fields`;
    return this._dataService.post(url, productSearchRequest);
  }

  getLoanPassCreditApplicationFieldsForApplication = (credentialId: number, applicationId: number): Observable<LoanPassCreditApplicationField[]> => {
    let url = `api/loan-pass/${credentialId}/get-credit-application-fields/${applicationId}`;
    return this._dataService.post(url, {});
  }

  deleteConfig = (credentialId: number): Observable<LoanPassMapping> => {
    let url = `api/loan-pass/${credentialId}/reset-everything`;
    return this._dataService.post(url, {});
  }

  getDestinationFieldPaths = (credentialId: number, mappingType: LoanPassMappingType): Observable<BasicPropertyDetail[]> => {
    let url = `api/loan-pass/${credentialId}/destination-field-paths/${mappingType}`;
    return this._dataService.get(url);
  }

  getLodaEnumsForLoanPassPricingRequest = (): Observable<EnumDropdownInfo[]> => {
    let url = `api/loan-pass/enums/product-search-request`;
    return this._dataService.get(url);
  }
}
