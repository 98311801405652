import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication } from 'src/app/models/loan/loan-info.model';

@Component({
    selector: 'tpo-closing-info',
    templateUrl: 'tpo-closing-info.component.html'
})

export class TpoClosingInfoComponent implements OnInit {

    private _application: LoanApplication;

    @Input()
    set application(application: LoanApplication) {
        this._application = application;
    }

    get application(): LoanApplication {
        return this._application;
    }
    constructor() { }

    ngOnInit() { }
}