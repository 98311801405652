import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateLicensing } from 'src/app/models/user/state-licensing.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ProfileService } from '../../../profile.service';
import { finalize } from 'rxjs';
import { cloneDeep } from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'state-licensing',
  templateUrl: './state-licensing.component.html',
  styleUrls: ['./state-licensing.component.scss']
})
export class StateLicensingComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  userCompanyGuid: string;

  @ViewChild('upsertStateLicensingDrawer')
  upsertStateLicensingDrawer: DrawerComponent;

  stateLicensingColumns = [];
  states: EnumerationItem[] = [];
  existingStates: string[];
  stateLicensingList: Array<StateLicensing> = [];
  upsertStateLicensingDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  selectedstateLicensingRecord: StateLicensing;

  constructor(
    private readonly injector: Injector,
    private readonly _drawerService: DrawerService,
    private readonly _profileService: ProfileService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService) {
    super(injector)
    super.scrollOffset = 240;
  }

  ngOnInit(): void {
    this.states = [{ name: "All States", value: "US" }]
      .concat(Object.keys(this.applicationContext.globalConfig.states).map(key => ({
        name: this.applicationContext.globalConfig.states[key],
        value: key.toUpperCase(),
      })));

    this.loadStateLicensings();
    this.stateLicensingColumns = [
      { field: 'state', header: 'State', visible: true, sortable: true },
      { field: 'nmls', header: ' Lic/Reg#', visible: true, sortable: true },
      { field: 'issueDate', header: 'Issue Date', visible: true, sortable: true },
      { field: 'expirationDate', header: 'Expiration Date', visible: true, sortable: true },
      { field: 'notes', header: 'Notes', visible: true, sortable: true },
      { field: 'active', header: 'Is Active', visible: true, sortable: true },
    ];
  }

  showDeleteConfirmationDialog(userLicenseId: number) {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      self._spinner.show();
      self._profileService.deleteStateLicensing(userLicenseId)
        .pipe(finalize(() => self._spinner.hide()))
        .subscribe({
          next: () => {
            self._notifyService.showSuccess(
              'State licensing deleted successfuly',
              'State Licensing'
            );
            self.removeStateLicensingFromGrid(userLicenseId);
          },
          error: (err) => {
            self._notifyService.showError(
              err?.message || 'Unable to delete state licensing',
              'State Licensing'
            );
          },
        });
    });
  }

  removeStateLicensingFromGrid(userLicenseId: number) {
    this.stateLicensingList = this.stateLicensingList.filter(vm => vm.userLicenseId !== userLicenseId);
  }

  loadStateLicensings() {
    this._profileService.getStateLicensing(this.userCompanyGuid)
      .subscribe({
        next: (response) => {
          this.stateLicensingList = response
            .map(usl => {
              let state = "";

              if (usl.state) {
                if (usl.state === "US") {
                  state = "All States";
                } else {
                  state = usl.state.toUpperCase();
                }
              }

              return {
                ...usl,
                state: state
              }
            })
        },
        error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to fetch state licensing list ', 'State Licensing');
        }
      });
  }

  upsertStateLicensingDrawerClose(stateLicensing: StateLicensing) {
    this._drawerService.hide("upsertStateLicensingDrawer", 10);

    if (!stateLicensing) {
      return;
    }

    if (stateLicensing.state) {
      if (stateLicensing.state === "US") {
        stateLicensing.state = "All States"
      } else {
        stateLicensing.state = stateLicensing.state.toUpperCase();
      }
    }

    const index = this.stateLicensingList.findIndex(usl => usl.userLicenseId === stateLicensing.userLicenseId);
    if (index === -1) {
      this.stateLicensingList.push(stateLicensing);
    } else {
      this.stateLicensingList[index] = stateLicensing;
    }

    this.stateLicensingList = [...this.stateLicensingList];
  }

  onUpsertStateLicensingDrawerOpened(updatedStateLicensingRecord = null) {
    this.upsertStateLicensingDrawer.title = updatedStateLicensingRecord
      ? 'Edit State Licensing Info'
      : 'Add State Licensing Info';

    this.selectedstateLicensingRecord = cloneDeep(updatedStateLicensingRecord) || {
      userId: this.userCompanyGuid,
      state: ''
    };

    this.existingStates = this.stateLicensingList
      .filter(s => this.selectedstateLicensingRecord.state !== s.state)
      .map(s => s.state);

    this._drawerService.show("upsertStateLicensingDrawer", 10);
  }
}
