import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'right-sub-nav',
  templateUrl: './right-sub-nav.component.html',
  styleUrls: ['./right-sub-nav.component.scss']
})
export class RightSubNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  closeNav() {
    var element = document.getElementById("rightSubnav");
    element.classList.toggle("right-sub-nav-open");
  }
}
