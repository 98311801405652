import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { DocumentTemplateField, DocumentTemplatePage, DocumentTemplatePageFieldType } from 'src/app/models/document-template-page.model';
import { LoanField } from 'src/app/models/loan/loan-field.model';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { DocumentGenerationRequest } from '../../models/document-generation-request.model';
import { LoanDocsService } from '../../services/loan-docs.service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DocumentTemplate } from 'src/app/models/config/global-config.model';

@Component({
  selector: 'generate-document-drawer',
  templateUrl: 'generate-document-drawer.component.html',
  styleUrls: ['generate-document-drawer.component.scss'],
})
export class GenerateDocumentDrawerComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  applicationId: number;

  @Input()
  loanDocTaskId: number | null;

  @Input()
  documentTemplates: DocumentTemplate[] = [];

  @Input()
  showOnlyPrequalLetterDocs: boolean = false;

  @Output()
  onClose: EventEmitter<never> = new EventEmitter<never>();

  steps: MenuItem[] = [];
  pages: DocumentTemplatePage[] = [];

  selectedDocumentTemplateId: number | null = null;
  currentPageIndex: number = -1;

  isLoading: boolean = false;
  noInput: boolean = false;

  currentPage: DocumentTemplatePage;

  private _contextSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _documentTemplateService: DocumentTemplateService,
    private readonly _loanService: LoanService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.showOnlyPrequalLetterDocs) {
      this.documentTemplates = this.documentTemplates.filter(t => t.documentTemplateCategory == 'PreQual');
    }
    this.initSteps();
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  onGenerateDocClicked = (useOldGenerator: boolean) => {
    const fields: DocumentTemplateField[] = [];
    this.pages.forEach(p => {
      fields.push(...p.fields);
    });
    var replacementValues = {};
    fields.forEach(function (field) {
      if (!field.defaultValue) {
        return;
      }

      if (field.fieldType == DocumentTemplatePageFieldType.Currency) {
        replacementValues[field.fieldKey] = this.formatCurrency(field.defaultValue);
      } else if (field.fieldType == DocumentTemplatePageFieldType.Percent) {
        replacementValues[field.fieldKey] = this.formatPercentage(field.defaultValue);
      } else {
        replacementValues[field.fieldKey] = field.defaultValue;
      }
    });
    const request: DocumentGenerationRequest = {
      documentTemplateId: this.currentPage ? this.currentPage.documentTemplateId : this.selectedDocumentTemplateId,
      applicationId: this.applicationId,
      replacementValues: replacementValues,
      loanDocTaskId: this.loanDocTaskId,
      useOldGenerator: useOldGenerator
    }
    this.isLoading = true;
    this._loanDocsService.generateDocument(request).subscribe(() => {
      this._notificationService.showSuccess("Document generated successfully.", "Success!");
      this.onClose.emit();
    }, error => {
      this._notificationService.showError(error ? error.message : "An error occurred while generating the document.", "Error!");
    }).add(() => this.isLoading = false);
  }

  onStartClicked = () => {
    this.isLoading = true;
    this._documentTemplateService.getDocumentTemplatePages(this.selectedDocumentTemplateId)
      .subscribe({
        next: (pages) => {
          this._loanService.mergeFieldValues(this.applicationId).subscribe({
            next: (loanFields) => {
              pages = pages.filter(p => !!p.fields?.length);

              if (pages.length == 0) {
                this.currentPageIndex++;
                this.noInput = true;
              } else {
                this.initSteps(pages);
                this.loadPages(pages, loanFields);
              }
            },
            error: (error) => {
              this._notificationService.showError(error?.message || "Couldn't load merge fields.", "Error!");
            }
          }).add(() => this.isLoading = false);
        },
        error: (error) => {
          this._notificationService.showError(error?.message || "Couldn't load pages.", "Error!");
          this.isLoading = false;
        }
      });
  }

  onDocumentTemplateChanged = () => {
    this.currentPage = null;
    this.noInput = false;
    this.pages = [];
    this.initSteps();
  }

  onNextClicked = () => {
    this.currentPageIndex++;
    this.currentPage = this.pages[this.currentPageIndex];
  }

  onBackClicked = () => {
    this.currentPageIndex--;

    if (this.currentPageIndex === -1) {
      this.noInput = false;
      this.currentPage = null;
    } else {
      this.currentPage = this.pages[this.currentPageIndex]
    }
  }

  private loadPages = (pages: DocumentTemplatePage[], loanFields: LoanField[]) => {
    pages.forEach(page => {
      page.fields.forEach(pageField => {
        if (!pageField.globalMergeFieldKey) {
          return;
        }

        const fields = pageField.globalMergeFieldKey.split(',');
        const fieldValue = loanFields.filter(e => fields.includes(e.fieldKey)).map(x => x.value);
        if (fieldValue) {
          pageField.defaultValue = fieldValue.join(" ");
        }
      });
    });

    this.pages = pages;

    if (this.pages.length > 0) {
      this.currentPageIndex = 0;
      this.currentPage = this.pages[this.currentPageIndex];
    }
  }

  private initSteps = (pages?: any) => {
    this.steps = [{ label: 'Select Document Template' }];

    if (!!pages?.length) {
      pages.forEach(p => this.steps.push({ label: p.title }));
    }
  }
}
