import { TitleOrderDocument } from "src/app/modules/app-details/components/title-history/models/title-order.model";

export class CreateTitleOrderRequest {
  credentialId: number;
  loanDocTaskId: number;
  orderType: TitleOrderTypeEnum;
  productType: TitleProductTypeEnum;
  closingAgentContact: string;
  documents: TitleOrderDocument[];
  notes: TitleOrderNote[];
  applicationId: number;
  leadId: number;
  borrowerId: number;
}

export enum TitleOrderTypeEnum {
  TitleOnly = "TitleOnly",
  Escrow = "Escrow",
  TitleAndEscrow = "TitleAndEscrow"
}

export enum TitleProductTypeEnum {
  LoanPolicy = "LoanPolicy",
  OwnersPolicy = "OwnersPolicy",
  Commitment  = "Commitment"
}

export class TitleOrderNote {
  text: string;
}
