export class LosSetFieldRequest {
  fieldId: string;
  type: string;
  format: string;
  requestedValue: string;
  borrowerPairId: string;
}

export class LosSetFieldResponse {
  fieldId: string;
  requestedValue: string;
  description: string;
  isSuccess: boolean;
  setValue: any;
}
