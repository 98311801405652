import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../core/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class SigningService {

  constructor(private readonly _dataService: DataService) { }

  openSignerView = (loanDocTaskId: number, data) => {
    const url = 'api/Signing/LoanDocTask/' + loanDocTaskId + '/OpenSignerView';
    return this._dataService.post(url, data);
  }

  openSenderView = (loanDocId: number, data) => {
    const url = 'api/Signing/LoanDoc/' + loanDocId + '/OpenSenderView';
    return this._dataService.post(url, data);
  }

  openCorrectView = (documentSigningOrderId: number, data) => {
    const url = 'api/Signing/order/' + documentSigningOrderId + '/open-view/correct';
    return this._dataService.post(url, data);
  }

  openEditView = (documentSigningOrderId: number, data) => {
    const url = 'api/Signing/order/' + documentSigningOrderId + '/open-view/edit';
    return this._dataService.post(url, data);
  }

  onSenderViewSubmitted = (urlParams: string) => {
    let url = `api/Signing/OnSenderViewSubmitted/${urlParams}`;
    return this._dataService.post(url, {});
  }

  OnSignerViewSubmitted = (urlParams: string) => {
    let url = `api/Signing/OnSignerViewSubmitted/${urlParams}`;
    return this._dataService.post(url, {});
  }
}
