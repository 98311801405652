<div class="card">
  <div class="card-header">{{ isEdit ? 'Edit' : 'Add' }} Borrower</div>
  <div class="card-body">
    <form #additionalBorrowerForm="ngForm" novalidate id="additionalBorrowerForm" name="additionalBorrowerForm" class="w-100">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="firtName{{copyBorrower['id']}}">First Name</label>
            <input class="form-control" type="text" section="borrower-information" [required]="true" name="firtName{{copyBorrower['id']}}"
              #firstNameInput="ngModel" [disabled]="false"
              [ngClass]="{'is-invalid' : firstNameInput && firstNameInput.touched && firstNameInput.invalid}"
              [(ngModel)]="copyBorrower.firstName">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="middleName{{copyBorrower['id']}}">Middle Name</label>
            <input class="form-control" type="text" section="borrower-information" [required]="false" name="middleName{{copyBorrower['id']}}"
              #middleNameInput="ngModel" [disabled]="false"
              [ngClass]="{'is-invalid' : middleNameInput && middleNameInput.touched && middleNameInput.invalid}"
              [(ngModel)]="copyBorrower.middleName">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="lastName{{copyBorrower['id']}}">Last Name</label>
            <input class="form-control" type="text" section="borrower-information" [required]="true" name="lastName{{copyBorrower['id']}}"
              #lastNameInput="ngModel" [disabled]="false"
              [ngClass]="{'is-invalid' : lastNameInput && lastNameInput.touched && lastNameInput.invalid}"
              [(ngModel)]="copyBorrower.lastName">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="ssn{{copyBorrower['id']}}">SSN</label>
            <input class="form-control" type="text" section="borrower-information" [required]="false" name="ssn{{copyBorrower['id']}}"
              #ssnInput="ngModel" [disabled]="false" mask="000-00-0000" placeHolderCharacter="#" [showMaskTyped]="true"
              [ngClass]="{'is-invalid' : ssnInput && ssnInput.touched && ssnInput.invalid}"
              [(ngModel)]="copyBorrower.socialSecurityNumber">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="creditScore{{copyBorrower['id']}}">Credit Score</label>
            <input class="form-control numeric-without-arrows" type="number" section="borrower-information" [required]="true" name="creditScore{{copyBorrower['id']}}"
              #creditScoreInput="ngModel" [disabled]="false" numeric
              [ngClass]="{'is-invalid' : creditScoreInput && creditScoreInput.touched && creditScoreInput.invalid}"
              [(ngModel)]="copyBorrower.creditScore">
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="isSelfEmployed{{copyBorrower['id']}}">Self Employed</label>
            <div class='form-switch'>
              <input name="isSelfEmployed{{copyBorrower['id']}}" id="isSelfEmployed{{copyBorrower['id']}}" type='checkbox' class='form-check-input'
                [(ngModel)]="copyBorrower.isSelfEmployed" [required]="false"
                #isSelfEmployedInput="ngModel" [disabled]="false" section="borrower-information"
                [ngClass]="{'is-invalid' : isSelfEmployedInput && isSelfEmployedInput.touched && isSelfEmployedInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="firstTimeHomeBuyer{{copyBorrower['id']}}">First Time Home Buyer</label>
            <div class='form-switch'>
              <input name="firstTimeHomeBuyer{{copyBorrower['id']}}" id="firstTimeHomeBuyer{{copyBorrower['id']}}" type='checkbox' class='form-check-input'
                [(ngModel)]="copyBorrower.isFirstTimeHomeBuyer" [required]="false"
                #firstTimeHomeBuyerInput="ngModel" [disabled]="false" section="borrower-information"
                [ngClass]="{'is-invalid' : firstTimeHomeBuyerInput && firstTimeHomeBuyerInput.touched && firstTimeHomeBuyerInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="bankruptcy{{copyBorrower['id']}}">Bankruptcy</label>
            <div class='form-switch'>
              <input name="bankruptcy{{copyBorrower['id']}}" id="bankruptcy{{copyBorrower['id']}}" type='checkbox' class='form-check-input'
                [(ngModel)]="copyBorrower.bankruptcy" [required]="false"
                #bankruptcyInput="ngModel" [disabled]="false" section="borrower-information"
                [ngClass]="{'is-invalid' : bankruptcyInput && bankruptcyInput.touched && bankruptcyInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="forclosure{{copyBorrower['id']}}">Foreclosure</label>
            <div class='form-switch'>
              <input name="forclosure{{copyBorrower['id']}}" id="forclosure{{copyBorrower['id']}}" type='checkbox' class='form-check-input'
                [(ngModel)]="copyBorrower.forclosure" [required]="false"
                #forclosureInput="ngModel" [disabled]="false" section="borrower-information"
                [ngClass]="{'is-invalid' : forclosureInput && forclosureInput.touched && forclosureInput.invalid}">
            </div>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>

      </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-end align-items-stretch gap-2">
    <button type="button" class="btn btn-secondary" (click)="onClose()">
      <i class="fa fa-close"></i> Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onSave()">
      <span>OK</span>
    </button>
  </div>
</div>

<ng-template #formContent> </ng-template>

