import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanDoc } from 'src/app/models';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';

@Component({
    selector: 'add-attachment-dialog',
    templateUrl: 'add-attachment-dialog.component.html',
    styleUrls: ['add-attachment-dialog.component.scss']
})
export class AddAttachmentDialogComponent implements OnInit {

  @Input()
  loanDocsByCategory: LoanDocsByCategory[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onOkClicked = () => {
    const selectedFiles = this.getSelectedDocFiles();
    this.activeModal.close(selectedFiles);
  }

  private getSelectedDocFiles = (): DocFile[] => {
    let result: DocFile[] = [];
    this.loanDocsByCategory.forEach(ld => {
      ld.documents.forEach(d => {
        d.docFiles.forEach(f => {
          if ((<EmailDocFile>f).isSelectedToAttach) {
            result.push(f);
          }
        })
      })
    });
    return result;
  }
}

export class LoanDocsByCategory {
  documentType: string;
  documents: LoanDoc[] = [];
}

export class EmailDocFile extends DocFile {
  isSelectedToAttach: boolean = false;
}
