<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title">Select Scenarios For Comparison</h5>
    <button
      type="button"
      class="btn-close"
      (click)="activeModal.dismiss()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form class="form-horizontal auth-form" #scenarioSelectionForm="ngForm" novalidate id="scenarioSelectionForm">
        <div>
          <label class="form-label" for="selectedScenarios">Scenarios</label>
          <ng-select
            [name]="'selectedScenarios'"
            [bindLabel]="'name'"
            [bindValue]="'pricingScenarioId'"
            class="custom-ng-select"
            [multiple]="true"
            #scenariosControl="ngModel"
            [ngClass]="{'is-invalid' : scenariosControl && scenariosControl.touched && scenariosControl.invalid}"
            [(ngModel)]="selectedScenarios"
            [maxSelectedItems]="3"
            [items]="scenarios"
            required
          >
          </ng-select>
          <div class="invalid-feedback">Please select scenario(s).</div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary me-2"
      (click)="activeModal.dismiss()"
      data-bs-dismiss="modal"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSelectClicked()"
    >
      Select
    </button>
  </div>
</div>
