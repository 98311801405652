import { Component, Output, Input, EventEmitter } from '@angular/core';
import { LoanFee } from 'src/app/models/fee/fee.model';
import * as _ from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { FeePercentOfFieldEnum } from 'src/app/models/fee/fee-percent-of-field.enum';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'fee-split-editor',
  templateUrl: 'fee-split-editor.component.html',
  styleUrls: ['./fee-split-editor.component.scss']
})
export class FeeSplitEditorComponent {

  @Input()
  set fee(fee: LoanFee) {
    this._originalFee = fee;
    this._fee = _.cloneDeep(fee);
  }

  @Output()
  applied: EventEmitter<LoanFee> = new EventEmitter<LoanFee>();

  @Output()
  cancelled: EventEmitter<LoanFee> = new EventEmitter<LoanFee>();

  get fee(): LoanFee {
    return this._fee;
  }

  feePercentOfOptions: EnumerationItem[] = [];

  private _fee: LoanFee;

  private _originalFee: LoanFee;

  constructor() {
    for (let enumMember in FeePercentOfFieldEnum) {
      let enumName = this.getEnumName(FeePercentOfFieldEnum[enumMember]);
      if (enumName) {
        this.feePercentOfOptions.push({ name: enumName, value: FeePercentOfFieldEnum[enumMember] });
      }
    }
  }

  onApplyClicked = () => {
    this.applied.emit(this._fee);
  }

  onCancelClicked = () => {
    this.cancelled.emit(this._originalFee);
  }

  private getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }
}
