import { ExpressionGroup } from "src/app/models/expressions/expression-group.model";

export class TpoConfiguration {
  tpoConfigurationId: number;
  dashboardPrimaryColor: string;
  dashboardSecondaryColor: string;
  dashboardHeaderColor: string;
  dashboardFooterColor: string;
  headerMergedHtml: string;
  footerMergedHtml: string;
  dashboardStat1Name: string;
  dashboardStat1IconClass: string;
  dashboardStat1IconColor: string;
  dashboardStat2Name: string;
  dashboardStat2IconClass: string;
  dashboardStat2IconColor: string;
  dashboardStat3Name: string;
  dashboardStat3IconClass: string;
  dashboardStat3IconColor: string;
  order: number[];
  creditPermissions: TpoCreditPermissions;
  corrSearchForCreditOnSubmissionEnabled: boolean;
  ausPermissions: string; // MortgageAus;
  disclosurePathPermissions: TpoDisclosurePathPermissions;
  isVOAAllowed: boolean;
  isVOIVOEAllowed: boolean;
  isAppraisalOrderAllowed: boolean;
  isDisclosureGenerationAllowed: boolean;
  columnSelectorEnabled: boolean;
  tpoCocRoleId: number;
  tpoEscalationRoleId: number;
  wholesaleFeeVendors: string;
  customTasksRequiredForSubmission: string;
  tasksRequiredForSubmission: string;
  corrCustomTasksRequiredForSubmission: string;
  corrTasksRequiredForSubmission: string;
  nonDelCustomTasksRequiredForSubmission: string;
  nonDelTasksRequiredForSubmission: string;
  dashboardStatExpressionId1: number;
  dashboardStatExpressionGroup1: ExpressionGroup;
  dashboardStatExpressionId2: number;
  dashboardStatExpressionGroup2: ExpressionGroup;
  dashboardStatExpressionId3: number;
  dashboardStatExpressionGroup3: ExpressionGroup;
  updateLoanStatusId: number;
  updateKeyDateId: number;
  corrUpdateLoanStatusId: number;
  corrUpdateKeyDateId: number;
  kD_AppDateId: number;
  kD_RequestedClosingDateId: number;
  kD_FirstPaymentDateId: number;
  kD_CommitmentDateId: number;
  kD_CloseOfEscrowId: number;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
  nonDelPizzaTrackerEnabled: boolean;
  corrPizzaTrackerEnabled: boolean;
  wholesalePizzaTrackerEnabled: boolean;
  pricingAction_LockEnabled: string;
  pricingAction_CancelLockEnabled: string;
  pricingAction_RepriceEnabled: string;
  pricingAction_ProgramChangeEnabled: string;
  pricingAction_LockExtensionEnabled: string;
  licenseValidationEnabledChannels: string;
  useV2Menu: boolean;
  requireFeesAfterPricingAssignment: boolean;
  bypassDocUploadApprovalStatusIds: Array<number> = [];
  nonDelBypassDocUploadApprovalStatusIds: Array<number> = [];
  corrBypassDocUploadApprovalStatusIds: Array<number> = [];
}

export class TpoCssConfiguration {
  tpoCssConfigurationModelId: number;
  fileContent: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum TpoCreditPermissions {
  None = "None",
  ReissueOnly = "ReissueOnly",
  PullAndReissue = "PullAndReissue"
}

export enum TpoDisclosurePathPermissions {
  Tbd = "Tbd",
  Broker = "Broker",
  Lender = "Lender",
  BrokerAndLender = "BrokerAndLender"
}

