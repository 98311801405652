<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm"
    (ngSubmit)="saveCredential()" novalidate>

    <div class="modal-header">
        <h5 class="modal-title">Visionnet Credential</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div *ngIf="channels.length > 0" class="col-md-6 mt-1">
                    <label class="custom-control-label">Channel</label>
                    <ng-select width="100%" name="channels-dropdown" bindLabel="name" bindValue="value"
                        placeholder="Select Channels" [closeOnSelect]="false" [multiple]="true"
                        (change)="setCredentialChannels()" [(ngModel)]="selectedChannels" [items]="channels"
                        appendTo="body">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items | slice:0:2">
                                <span class="ng-value-label">{{item.name}}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 2">
                                <span class="ng-value-label">{{items.length - 2}} more...</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Alias</label>
                <input type="text" class="form-control" name="alias"
                    [ngClass]="{'is-invalid': alias && alias.touched && alias.invalid}" #alias="ngModel"
                    [(ngModel)]="credential.alias" required>
                <div class="invalid-feedback">Alias is required.</div>
            </div>
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Url</label>
                <input type="text" class="form-control" name="url" [(ngModel)]="credential.url">
            </div>
        </div>
        <div class="row">
            <div class="mt-1 col-md-6">
                <label class="custom-control-label">Username</label>
                <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
            </div>
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Password</label>
                <password-input [name]="'password'" [(ngModel)]="credential.password"></password-input>
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button type="submit" class="btn btn-primary right" [disabled]="isSaving">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>

</form>