import { NgModule } from '@angular/core';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';

import { VoaModule } from './voa/voa.module';
import { AusModule } from './aus/aus.module';
import { CreditModule } from './credit/credit.module';
import { TwnModule } from './twn/twn.module';
import { DisclosuresModule } from './disclosure';
import { AppraisalModule } from './appraisal/appraisal.module';
import { LoanCreditModule } from './loan-credit/loan-credit.module';

@NgModule({
    imports: [AusModule, CreditModule, TwnModule, VoaModule, DisclosuresModule, AppraisalModule, LoanCreditModule],
    declarations: [],
    exports: [],
    providers: [LoanServicesService]
})
export class LoanServicesModule {
  constructor() {}
}
