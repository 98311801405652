import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'urla-grid-cell',
  templateUrl: 'urla-grid-cell.component.html'
})

export class UrlaGridCellComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  disabled: boolean = false;

  @Input()
  hasEditButton: boolean = false;

  @Input()
  readonly: boolean;

  @Output()
  editClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }
}
