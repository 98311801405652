import {FeeSectionType, FeeType, LoanFee} from './fees.model';
import {FeeSection, FeeSectionViewType} from './fee-section.model';

/**
 * Separates fees into sections based on their type and returns an array of FeeSection objects.
 * @param {readonly LoanFee[]} fees - The array of LoanFee objects to be separated into sections.
 */
export function createFeeSections(fees: readonly LoanFee[]): FeeSection[] {
  const feeSectionsByViewType = createEmptyFeeSectionsByViewType();

  fees.forEach(fee => {
    const type = getFeeViewType(fee);
    if (!type) {
      return;
    }

    const section = feeSectionsByViewType.get(type);
    // @ts-ignore - Ignore the readonly error while creating the section.
    section.fees.push(fee);
  });

  return Array.from(feeSectionsByViewType.values());
}

function createEmptyFeeSectionsByViewType(): Map<FeeSectionViewType, FeeSection> {
  return Object.values(FeeSectionViewType).reduce((acc, type) => {
    acc.set(type, {
      type,
      fees: [],
    });
    return acc;
  }, new Map<FeeSectionViewType, FeeSection>());
}

function getFeeViewType(fee: LoanFee): FeeSectionViewType {
  if (isRealEstateFee(fee)) {
    return FeeSectionViewType.RealEstateCommission;
  }

  if (fee.hudNumber?.indexOf('8') === 0) {
    return FeeSectionViewType.Origination;
  }

  if (
    fee.hudNumber?.indexOf('11') === 0 ||
    fee.feeSection === FeeSectionType.Services ||
    fee.feeSection === FeeSectionType.ServicesNoShop
  ) {
    return FeeSectionViewType.TitleCharges;
  }

  const type = fee.feeSection;
  if (type) {
    return type as unknown as FeeSectionViewType;
  }

  console.error('Fee section type is invalid', fee);
  return undefined;
}

let realEstateFeeTypesSet: ReadonlySet<FeeType>;

/**
 * For lazy initialization of the real estate fee types set.
 */
function createOrGetRealEstateFeeTypesSet(): ReadonlySet<FeeType> {
  if (realEstateFeeTypesSet != null) {
    return realEstateFeeTypesSet;
  }

  return (realEstateFeeTypesSet = Object.freeze(
    new Set([
      FeeType.BuyerAgentRealEstateCommission,
      FeeType.SellerAgentRealEstateCommission,
      FeeType.RealEstateCommissionTotal,
      FeeType.RealEstateCommissionBuyersBroker,
      FeeType.RealEstateCommissionSellersBroker,
    ])
  ));
}

function isRealEstateFee(fee: LoanFee): boolean {
  return (
    createOrGetRealEstateFeeTypesSet().has(fee.feeType) ||
    (fee.hudNumber && fee.hudNumber.startsWith('7'))
  );
}
