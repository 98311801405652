import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  DisclosurePathComponent,
  FinalNotesComponent,
  FinalSubmissionInfoComponent,
  ItemsRequiredForSubmissionComponent,
  ProgramComponent,
  SubmissionComponent,
  SubmissionDataValidationComponent,
} from '.';
import { ConditionsTasksModule } from '../conditions-tasks/conditions-tasks.module';
import { LicenseInfoComponent } from './components/license-info/license-info.component';
import { LicenseInfoBadgeComponent } from './components/license-info/license-info-badge/license-info-badge.component';
import { LicenseInfoStatusBadgeComponent } from './components/license-info/license-info-status-badge/license-info-status-badge.component';
import { ExternalContactsV2Module } from '../external-contacts-v2/external-contacts-v2.module';

@NgModule({
    imports: [
        SharedModule,
        ConditionsTasksModule,
        ExternalContactsV2Module
    ],
    declarations: [
        SubmissionComponent,
        DisclosurePathComponent,
        FinalNotesComponent,
        FinalSubmissionInfoComponent,
        ProgramComponent,
        ItemsRequiredForSubmissionComponent,
      SubmissionDataValidationComponent,
      LicenseInfoComponent,
      LicenseInfoBadgeComponent,
      LicenseInfoStatusBadgeComponent,
    ],
    exports: [
        SubmissionComponent,
        DisclosurePathComponent,
        FinalNotesComponent,
        FinalSubmissionInfoComponent,
        ProgramComponent,
        ItemsRequiredForSubmissionComponent,
        SubmissionDataValidationComponent
    ],
    providers: []
})
export class SubmissionModule {
  constructor() {
  }
}
