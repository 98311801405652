import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LoanStatusSummaryComponent } from "./components/loan-status-summary/loan-status-summary.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    LoanStatusSummaryComponent
  ],
  exports: [
    LoanStatusSummaryComponent
  ],
  providers: [
  ]
})
export class LoanStatusModule {
  constructor() {
  }
}


