<div class="modal-body">
  <div class="row">
    <div class="pe-0 text-end">
      <button class="btn btn-secondary me-2" (click)="addTask()">
        <i class="fa fa-plus"></i>
        Add Task
      </button>
      <button type="button" class="btn btn-secondary" [disabled]="convertingToLoan" (click)="convertToLoan()">
        <span *ngIf="convertingToLoan">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Converting...
        </span>
        <span *ngIf="!convertingToLoan">Convert to Loan <i class="fa fa-directions"></i></span>
      </button>
    </div>
  </div>

  <div class="row h-100">
    <ng-container *ngIf="!alreadyConverted else takeMeThere;">
      <div class="col-xs-12 col-md-12 h-100">
        <lead-actions class="h-100" #leadActions [lead]="lead" [leadTasksCounts]="leadTasksCounts"
          [isManualDial]="isManualDial" (tasksCountersUpdated)="reloadLeadTaskCounts()" [isDrawer]="true"
          [impersonateUserId]="impersonateUserId" (leadUpdated)="onLeadUpdated($event)"
          (closeDrawer)="onLeadEditorDrawerClosed()" (dialPhoneClicked)="onDialClicked($event)"></lead-actions>
      </div>
    </ng-container>
    <ng-template #takeMeThere>
      <h5>This lead was converted to an application.</h5>
      <button class="btn btn-primary" [routerLink]="['/admin/app-details/' + lead.applicationId]">Take Me to Application</button>
    </ng-template>
  </div>
</div>
