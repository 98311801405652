import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { EmailCampaignConfigService } from 'src/app/modules/admin/email-campaign-config/services/email-campaign-config.service';
import { NavigationService } from 'src/app/services/navigation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss']
})
export class EmailUnsubscribeComponent implements OnInit {
  @ViewChild('unsubscribeForm') unsubscribeForm: NgForm | undefined;
  errorMessage: string;
  submitting: boolean = false;
  possibleLogoUrls: string[] = [];
  backgroundImageUrl: string = null;
  campaignGuid: string;
  emailAddress: string;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _environment: EnvironmentService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _emailCampaignConfigService: EmailCampaignConfigService
  ) {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    this.backgroundImageUrl = this._environment.apiInfo.apiBaseUrl + 'company/co/login';
  }

  ngOnInit(): void {
    this.campaignGuid = this._activatedRoute.snapshot.queryParams.campaign
    if (!this.campaignGuid) {
      this.errorMessage = 'could not locate the compaign guid.';
    }
  }

  onTestBackgroundImageErrored = () => {
    this.backgroundImageUrl = "./assets/images/background-temp.png";
  }

  onUnsubscribeClicked = () => {
    if (!this.campaignGuid) {
      return;
    }
    if (!this.unsubscribeForm) {
      return;
    }
    this.unsubscribeForm.form.markAllAsTouched();
    if (!this.unsubscribeForm.form.valid) {
      return;
    }
    this.submitting = true;
    this._emailCampaignConfigService.emailCampaignUnsubscribe(this.campaignGuid, this.emailAddress)
      .pipe(finalize(() => this.submitting = false))
      .subscribe({
        next: () => {
          this.errorMessage = '';
          this.emailAddress = '';
          this.unsubscribeForm.controls.emailAddress.markAsUntouched();
          Swal.fire(
            'Unsubscribe',
            'You have been unsubscribed',
            'success'
          )
        }, error: (err) => {
          this.submitting = false;
          this.errorMessage = err?.message || "unable to unsubscribe please try again";
        }
      });
  }
}
