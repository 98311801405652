<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <i [hidden]="isValidForm"  class="fas fa-exclamation-triangle alert-icon text-danger align-self-center me-1"></i>
      G. INITIAL ESCROW PAYMENT AT CLOSING 1000. Reserves Deposited with Lender
      <span class="pull-right d-flex align-items-center">
        <a class="btn btn-sm btn-soft-primary me-2" (click)="openEscrowSchedule()" role="button">
          Disbursement Schedule
        </a>
        <span>Total : {{feesTotal | currency}}</span>
      </span>
    </h4>
  </div>
  <div class="card-body">
    <a class="btn btn-sm btn-soft-primary me-2" (click)="openAddFeeModal()" role="button">
      <i class="fas fa-plus me-2"></i>Add Fee
    </a>

    <form #escrowForm="ngForm">
        <generic-fees
          [showImpound]="true"
          [fees]="fees"
          (onFeeRemoved)="removeFee($event)"
          (onFeeUpdated)="updateFee($event)"
          [allowLockStatusChange]="false"
          (openFeeEditor)="onFeeEditorOpened($event)">
        </generic-fees>
    </form>
  </div>
</div>

