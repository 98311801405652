<div class="h-100">
  <div class="d-flex flex-column h-100">
    <div class="mb-1 mt-2 dial-pad-container">
      <label class="control-label">Number to Call</label>
      <div class="d-flex">
        <input
          type="text"
          name="numberToCall"
          [(ngModel)]="numberToCall"
          mask="(000) 000-0000"
          [showMaskTyped]="true"
          class="form-control flex-grow-1"
        />
        <a class="ms-1 clear-button" (click)="onClearNumberClicked()"><span>Clear</span></a>
      </div>
    </div>
    <dialpad
      (numberClicked)="onDialpadNumberClicked($event)"
      (backspaceClicked)="onDialpadBackspaceClicked()"
      (callClicked)="onCallClicked()"
      (smsClicked)="onSmsClicked()"
      [callButtonEnabled]="numberToCall && numberToCall.length === 10"
    >
    </dialpad>
    <div class="mx-4 my-2 flex-grow-1 overflow-hidden">
      <dial-call-history
        (manualDialClicked)="numberToCall = $event; onCallClicked()"
      ></dial-call-history>
    </div>
  </div>
</div>
