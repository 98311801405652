<div *ngIf='!initialValidationErrorMessage'>
  <div
    *ngIf='!questionnaireStarted'
    class='d-flex justify-content-center'
  >
    <div class='d-flex flex-column'>
      <h4 class='card-title mb-3'>Please, select a vendor to get fees from</h4>

      <div class='d-flex justify-content-center'>
        <select
          #vendorToImportFrom='ngModel'
          [(ngModel)]='selectedVendorName'
          (ngModelChange)='onVendorSelectionChanged()'
          [ngClass]="{'is-invalid' : vendorToImportFrom && vendorToImportFrom.touched && vendorToImportFrom.invalid}"
          class='form-select'
          id='feeTemplateSelect'
          name='feeTemplateSelect'
          style='max-width: 250px'
        >
          <option [ngValue]='null'>--Select a Vendor--</option>

          <option
            *ngFor='let vendorName of supportedVendorNames'
            [ngValue]='vendorName'
          >
            {{vendorName}}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div
    *ngIf='thirdPartyFeeOptions && !questionnaireRunning'
    class='d-flex justify-content-center'
  >
    <div style='width: 600px'>
      <fees-vendor-questionnaire
        (questionnaireCompleted)='onVendorQuestionnaireCompleted($event)'
        [applicationId]='applicationId'
        [selectedVendorName]='selectedVendorName'
        [thirdPartyFeeOptions]='thirdPartyFeeOptions'
      ></fees-vendor-questionnaire>
    </div>
  </div>

  <div
    *ngIf='questionnaireRunning'
    class='d-flex justify-content-center'
  >
    <div class='d-flex'>
      <div
        class='spinner-border thumb-xxs text-primary me-2 mt-2'
        role='status'
      ></div>

      <h5 class='font-18 text-muted me-4'>{{progressMessage}}</h5>
    </div>
  </div>

  <div
    *ngIf='acquiredFeesFromVendor'
    class='d-flex justify-content-center'
  >
    <div class='d-flex'>
        <span class='mt-2 me-2 text-success'>
          <svg
            class='feather feather-check-circle'
            fill='none'
            height='24'
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='2'
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>

            <polyline points='22 4 12 14.01 9 11.01'></polyline>
          </svg>
        </span>

      <h5 class='font-18 text-muted me-4'>Vendor Fees Loaded Successfully!</h5>
    </div>
  </div>
</div>

<div
  *ngIf='initialValidationErrorMessage'
  class='d-flex justify-content-center'
>
  <div class='d-flex'>
      <span class='mt-2 me-2 text-danger'>
        <svg
          class='feather feather-x-circle icon-dual text-danger'
          fill='none'
          height='24'
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='12' cy='12' r='10'></circle>

          <line x1='15' y1='9' x2='9' y2='15'></line>

          <line x1='9' y1='9' x2='15' y2='15'></line>
        </svg>
      </span>

    <h5
      [innerHtml]='initialValidationErrorMessage'
      class='font-18 text-muted me-4'
    ></h5>
  </div>
</div>

<form
  #invalidFieldsForm='ngForm'
  (ngSubmit)='onSubmitInvalidFieldsForm()'
  *ngIf='initialValidationErrorMessage'
  class='row justify-content-center mt-4'
>
  <ng-container *ngIf='!fieldValidationConfig.isAddressValid'>
    <!-- Address 1 input -->
    <div class='form-group col-lg-4'>
      <label for='address1'>Address 1</label>

      <address-autocomplete-input
        (addressChange)='onChangeAddress($event)'
        [(ngModel)]='currentMortgage.subjectProperty.address1'
        [class.is-invalid]='invalidFieldsForm.controls.address1?.invalid
         && invalidFieldsForm.controls.address1?.touched'
        [required]='!isSubjectPropertyAddressTbd'
        id='address1'
        name='address1'
      ></address-autocomplete-input>

      <div class="invalid-feedback">This field is required</div>
    </div>

    <!-- Address 2 input -->
    <div class='form-group col-lg-4'>
      <label for='address2'>Unit/Suite</label>

      <input
        [(ngModel)]='currentMortgage.subjectProperty.address2'
        class='form-control'
        id='address2'
        name='address2'
      />
    </div>

    <!-- City input -->
    <div class='form-group col-lg-4'>
      <label for='city'>City</label>

      <text-input
        [(ngModel)]='currentMortgage.subjectProperty.city'
        id='city'
        name='city'
        [required]='!isSubjectPropertyAddressTbd'
      ></text-input>
    </div>

    <!-- State input -->
    <div class='form-group col-lg-4'>
      <label for='state'>State</label>

      <select-input
        [(ngModel)]='currentMortgage.subjectProperty.state'
        [options]='stateOptions'
        id='state'
        name='state'
        [required]='true'
      ></select-input>
    </div>

    <!-- zip code input -->
    <div class='form-group col-lg-4'>
      <label for='zip-code'>Zip Code</label>

      <zip-code-input
        (selectionChanged)='onChangeZipCode($event)'
        [(ngModel)]='currentMortgage.subjectProperty.zipCode'
        [disabled]='invalidFieldsForm.controls.zipCode?.disabled'
        [required]='true'
        class='border-radius-0'
        id='zip-code'
        name='zipCode'
      ></zip-code-input>
    </div>

    <!-- County input -->
    <div class='form-group col-lg-4'>
      <label for='county'>County</label>

      <text-input
        [(ngModel)]='currentMortgage.subjectProperty.county'
        id='county'
        name='county'
      ></text-input>
    </div>
  </ng-container>

  <!-- break to new row -->
  <div class='w-100'></div>

  <!-- Purpose of Loan -->
  <div
    *ngIf='!fieldValidationConfig.purposeOfLoan'
    class='form-group col-lg-4'
  >
    <label for='purpose-of-loan'>Purpose of Loan</label>

    <select-input
      [(ngModel)]='currentMortgage.subjectProperty.purposeOfLoan'
      (ngModelChange)='onChangeLoanPurpose($event)'
      [options]='loanPurposeOptions'
      id='purpose-of-loan'
      name='purposeOfLoan'
      required
    ></select-input>
  </div>

  <!-- Mortgage Applied For -->
  <div
    *ngIf='!fieldValidationConfig.mortgageAppliedFor'
    class='form-group col-lg-4'
  >
    <label for='mortgage-applied-for'>Mortgage Applied For</label>

    <select-input
      [(ngModel)]='currentMortgage.mortgageTerm.mortgageAppliedFor'
      [options]='mortgageAppliedForOptions'
      id='mortgage-applied-for'
      name='mortgageAppliedFor'
      required
    ></select-input>
  </div>

  <!-- Purchase Price Amount -->
  <div
    *ngIf='!fieldValidationConfig.purchasePriceAmount'
    class='form-group col-lg-4'
  >
    <label for='purchase-price-amount'>Purchase Price Amount</label>

    <currency-input
      [(ngModel)]='currentMortgage.transactionDetail.purchasePriceAmount'
      id='purchase-price-amount'
      name='purchasePriceAmount'
      required
    ></currency-input>
  </div>

  <!-- Amount -->
  <div
    *ngIf='!fieldValidationConfig.amount'
    class='form-group col-lg-4'
  >
    <label for='amount'>Loan Amount</label>

    <currency-input
      [(ngModel)]='currentMortgage.mortgageTerm.amount'
      id='amount'
      name='amount'
      required
    ></currency-input>
  </div>

  <!-- break to new row -->
  <div class='w-100'></div>

  <!-- Save and Continue button -->
  <button
    [disabled]='!canSaveLoanInfo'
    class='btn btn-secondary col-auto d-flex align-items-baseline gap-2'
  >
    <div
      *ngIf='!canSaveLoanInfo'
      style='transform: translateY(0.1rem)'
    >
        <span
          aria-hidden='true'
          class='spinner-border spinner-border-sm'
          role='status'
        ></span>
    </div>

    <i
      *ngIf='canSaveLoanInfo'
      class='fa fa-check-circle'
      slot='icon'
    ></i>

    <span>Save and Continue</span>
  </button>
</form>
