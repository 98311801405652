import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CredentialModalComponent} from '../credential-modal/credential-modal.component';
import {Constants} from '../../../../../../services/constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ThirdPartyCredentialsService} from '../../../../../../services/third-party-credentials.service';
import {ThirdPartyCredential} from '../../../../../../models';
import * as _ from 'lodash';

@Component({
    selector: 'create-credential',
    templateUrl: './create-credential.component.html',
    styleUrls: ['./create-credential.component.scss']
})
export class CreateCredentialComponent implements OnInit {
    @Input() credentialType: string;
    @Input() credentials: ThirdPartyCredential[];
    @Input() userCompanyGuid?: string;
    @Output() openCredentialModal?: EventEmitter<ThirdPartyCredential> = new EventEmitter<ThirdPartyCredential>();

    constructor(
        private readonly _modalService: NgbModal,
        private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService
    ) {}

    ngOnInit(): void {}

    credentialModal(): void {
        const credential = this._thirdPartyCredentialsService.getNewCredential(this.credentialType);
        if (this.openCredentialModal.observed) { // open custom modal
            this.openCredentialModal.emit(credential);
        } else { // open default modal
            const modalRef = this._modalService.open(CredentialModalComponent, Constants.modalOptions.large);
            modalRef.componentInstance.userCompanyGuid = this.userCompanyGuid;
            modalRef.componentInstance.credential = _.cloneDeep(credential);
            modalRef.result.then(
                (result) => {
                    this.credentials.push(result);
                },
                () => {}
            );
        }
    }
}
