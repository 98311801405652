<div class="row mt-1">
  <div class="row" *ngIf="scope === 'Company'">
    <div class="col-md-3">
      <div class="form-check form-check-inline">
        <input id="documentSigningEnabled" class="form-check-input" type="checkbox"
          [(ngModel)]="documentSigningEnabled.value" ToggleValue [true]="1" [false]="0">
        <label class="form-check-label" for="documentSigningEnabled">Enable Document Signing</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-check form-check-inline">
        <input id="losDocumentSigningEnabled" class="form-check-input" type="checkbox"
          [(ngModel)]="losDocumentSigningEnabled.value" ToggleValue [true]="1" [false]="0">
        <label class="form-check-label" for="losDocumentSigningEnabled">Enable LOS Document Signing</label>
      </div>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-12 my-1">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 pt-3"
      *ngIf="scope === 'User' || (scope === 'Company' && (documentSigningEnabled.value || losDocumentSigningEnabled.value))">
      <div class="row">
        <div class="d-flex align-content-end flex-wrap">
          <div class="mt-4 me-2" *ngFor="let vendor of vendors">
            <credential-item [credential]="vendor" [credentials]="vendors"
              (openCredentialModal)="openVendorEditor($event)">
            </credential-item>
          </div>
          <div class="mt-4 me-2">
            <create-credential [scope]="scope" [credentialType]="'DocumentSigning'" [credentials]="vendors"
              (openCredentialModal)="openVendorEditor($event)"></create-credential>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12"
      *ngIf="scope === 'Company' && !documentSigningEnabled.value && !losDocumentSigningEnabled.value">
      <enable-message>Enable this feature above!</enable-message>
    </div>
  </div>
  <div class="col-md-12" *ngIf="scope === 'Company'">
    <div class="text-end">
      <div class="col-md-12 my-1">
        <hr />
      </div>
      <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
        <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
        <span *ngIf="isSaving">
          <span class="spinner-border spinner-border-sm"></span> Saving
        </span>
      </button>
    </div>
  </div>
</div>
