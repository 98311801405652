import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SigningService } from 'src/app/services/signing.service';

@Component({
  template: `<div class="document-signing-request-confirmation-page">
              <div class="center-container alert alert-success" style="padding: 20px;">
                  <h1>Lodasoft</h1>
                  <p>Esigned Document!</p>
              </div>
            </div>`
})
export class DocumentESigningRequestConfirmationComponent implements OnInit {

  constructor(private readonly _signingService: SigningService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    this.sendConfirmation();
  }

  sendConfirmation = () => {
    let urlParams = location.search;
    urlParams = urlParams.replace('event', 'action');

    this._signingService.OnSignerViewSubmitted(urlParams).subscribe({
      next: (response) => {
        window.parent.postMessage({
          shouldCloseEsignDocIframe: true,
          response: response
        }, '*');
      },
      error: (err) => {
        this._notifyService.showError(err ? err.message || err : "Send eSigning Document for Signing", "Error");
      }
    });
  }

}