<div class="modal-header">
  <h5 class="modal-title">Mark Manual Transfer</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <div *ngIf="issue" class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3" role="alert">
      <div class="media">
        <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
        <div class="media-body align-self-center">
          <h5>{{issue}}</h5>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!issue">
      <label class="form-label">Who was this file transferred to?</label>
      <p-dropdown styleClass="w-100" name="transferredTo" [options]="users" optionLabel="fullName"
        optionValue="userCompanyGuid" [(ngModel)]="selectedUserId" placeholder="Select User"
        [filter]="true" [showClear]="true" [itemSize]="20"></p-dropdown>
    </ng-container>

  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">Cancel</button>
  <button [disabled]="!selectedUserId" type="button" class="btn btn-primary right" (click)="onSaveClicked()">Save</button>
</div>
