import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomField, CustomFieldOption } from 'src/app/modules/pricing/models/pricing/custom-fields.model';

@Component({
  selector: 'pricing-custom-field-list-options',
  templateUrl: './pricing-custom-field-list-options.component.html',
  styleUrls: ['./pricing-custom-field-list-options.component.scss']
})
export class PricingCustomFieldListOptionsComponent implements OnInit {
  @ViewChild("customFieldListOptionForm") customFieldListOptionForm: NgForm;
  @Input() customField: CustomField;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (!this.customField.options) {
      this.customField.options = [];
    }
    if (this.customField.options.length === 0) {
      this.addCustomFieldOption();
    }
  }

  ok() {
    this.customFieldListOptionForm.form.markAllAsTouched();
    if (!this.customFieldListOptionForm.form.valid) {
      return;
    }
    this.activeModal.close(this.customField);
  }

  addCustomFieldOption() {
    this.customField.options.push(new CustomFieldOption());
  }

}
