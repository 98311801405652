import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanApplication } from 'src/app/models/loan/loan-info.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { LockExtensionRequest } from '../../models/pricing/lock-extension-request.model';
import { LockExtensionResponse } from '../../models/pricing/lock-extension-response.model';
import { PricingService } from '../../services/pricing.service';

@Component({
  selector: 'app-lock-extension-modal',
  templateUrl: './lock-extension-modal.component.html',
  styleUrls: ['./lock-extension-modal.component.scss']
})
export class LockExtensionModalComponent implements OnInit {
  currentApplication: LoanApplication;
  applicationId: number;

  extensionDaysList: EnumerationItem[] = [];
  daysToExtend: number = 0;

  currentStep: number = 1;
  discountRebatePercent: number;
  discountRebateDollar: number;
  isLoading: boolean;
  response: LockExtensionResponse;

  constructor(
    private readonly _pricingService: PricingService,
    private readonly _notifsService: NotificationService,
    public activeModal: NgbActiveModal
  ) {
    Array.from({ length: 30 }, (_, i) => {
      this.extensionDaysList.push({ value: i + 1, name: `${i + 1} Day Extension` });
    });
  }

  ngOnInit(): void {
  }

  onNextButtonClick() {
    this.onRequestLockExtension(true);
  }

  onRequestLockExtension = (lockPreview: boolean = false) => {
    this.isLoading = true;

    let request = new LockExtensionRequest();
    request.applicationId = this.applicationId;
    request.previewChanges = lockPreview;
    request.daysToExtend = this.daysToExtend;
    request.losIdentifier = this.currentApplication.losIdentifier;

    this._pricingService
      .requestLockExtension(this.applicationId, request)
      .subscribe({
        next: (res) => {

          if (res.success) {
            this.response = res;

            if (this.currentStep === 1) {
              this.currentStep = 2;
              this.discountRebatePercent = this.response?.price - this.currentApplication.productPricing?.price;
              this.discountRebateDollar = this.discountRebatePercent * this.currentApplication?.loanAmount / 100;
            }
            else if (this.currentStep === 2) {
              this.activeModal.close(res);
            }
          }
          else {
            if (!this.isJsonString(res.errorMessage)) {
              this._notifsService.showError(res.errorMessage, 'Lock extension request failed!');
              return;
            }
            else {
              const messageContent = JSON.parse(res.errorMessage);

              if (messageContent.error == "bad_request") {
                this._notifsService.showError(
                  messageContent.detail,
                  'Error!'
                );
                return;
              }

              console.log(messageContent);
              this._notifsService.showError('Unable to Extend lock', 'Lock extension request failed!');
            }
          }
        },
        error: (error) => {
          this._notifsService.showError(error ? error.message : 'Unable to Extend lock', 'Lock extension request failed!');
        }
      }
      ).add(() => this.isLoading = false);
  }

  private isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
