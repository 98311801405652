<div class="row">
    <div class="col-lg-12">                            
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">                      
                        <h4 class="card-title">Final Notes</h4>                      
                    </div>
                </div>                                 
            </div>
            <div class="card-body">
                <div class="row">
                    <textarea class="form-control" id="finalNotes" cols="12" rows="7" style="resize: none;"></textarea>
                </div> 
            </div>
        </div>
    </div>
</div>