<div class="modal-header">
    <h5 class="modal-title"> Configure Credit Credentials </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <credit-credentials-configuration (credentialsSaved)="onCredentialsSaved()"></credit-credentials-configuration>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
    </button>
</div>
