import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EncompassDisclosureWizardComponent } from './components/encompass-disclosure-wizard/encompass-disclosure-wizard.component';
import { TableModule } from 'primeng/table';
import { LosPackageConditionReportsComponent } from './components/los-package-condition-reports/los-package-condition-reports.component';
import { LosPlanCodeConflictsComponent } from './components/los-plan-code-conflicts/los-plan-code-conflicts.component';
import { EncompassDisclosureDocumentsComponent } from './components/encompass-disclosure-documents/encompass-disclosure-documents.component';
import { EncompassDisclosurePartiesComponent } from './components/encompass-disclosure-parties/encompass-disclosure-parties.component';
import { EncompassDisclosureFailedDocsComponent } from './components/encompass-disclosure-failed-docs/encompass-disclosure-failed-docs.component';
import { EncompassDisclosuresInputEditorsComponent } from './components/encompass-disclosures-input-editors/encompass-disclosures-input-editors.component';
import { EncompassDisclosureInfoComponent } from './components/encompass-disclosure-info/encompass-disclosure-info.component';
import { EncompassDisclosureLoanValidationResultsComponent } from './components/encompass-disclosure-loan-validation-results/encompass-disclosure-loan-validation-results.component';
import { EncompassDisclosurePreviewLeComponent } from './components/encompass-disclosure-preview-le/encompass-disclosure-preview-le.component';
import { EncompassDisclosurePreviewPdfComponent } from './components/encompass-disclosure-preview-pdf/encompass-disclosure-preview-pdf.component';
import { EncompassDisclosureWizardStreamlinedComponent } from './components/encompass-disclosure-wizard-streamlined/encompass-disclosure-wizard-streamlined.component';

@NgModule({
  declarations: [
    EncompassDisclosureWizardComponent,
    EncompassDisclosureWizardStreamlinedComponent,
    LosPackageConditionReportsComponent,
    LosPlanCodeConflictsComponent,
    EncompassDisclosureDocumentsComponent,
    EncompassDisclosurePartiesComponent,
    EncompassDisclosureFailedDocsComponent,
    EncompassDisclosuresInputEditorsComponent,
    EncompassDisclosureInfoComponent,
    EncompassDisclosureLoanValidationResultsComponent,
    EncompassDisclosurePreviewLeComponent,
    EncompassDisclosurePreviewPdfComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableModule
  ],
  exports: [
    EncompassDisclosureWizardComponent,
    EncompassDisclosureWizardStreamlinedComponent
  ]
})
export class EncompassDisclosureWizardModule { }
