export enum LosProviderFeature {
  // search
  Lookup = "Lookup",
  Search = "Search",

  // loan
  CreateMortgage = "CreateMortgage",
  GetMortgage = "GetMortgage",
  SyncMortgage = "SyncMortgage",
  UpdateMortgage = "UpdateMortgage",
  CustomFields = "CustomFields",

  // documents
  GetDocuments = "GetDocuments",
  CreateDocument = "CreateDocument",
  GetDocument = "GetDocument",
  UpdateDocument = "UpdateDocument",
  GetFileContent = "GetFileContent",

  // conditions
  GetConditions = "GetConditions",
  UpdateCondition = "UpdateCondition",

  // other
  ConversationLogs = "ConversationLogs",
  Milestones = "Milestones",
  InternalContacts = "InternalContacts",
  ExternalContacts = "ExternalContacts",
  GetProducts = "GetProducts",
  Events = "Events",
  Fees = "Fees",
  KeyDates = "KeyDates",

  // onboarding
  FieldFiltering = "FieldFiltering",
  UserImport = "UserImport",
  BranchImport = "BranchImport",

  // extensions not to be published to the user (docusign, etc.)
  ResolveBorrowers = "ResolveBorrowers",

  DisclosureGen = "DisclosureGen",
  DisclosureTracking = "DisclosureTracking",
  BusinessRules = "BusinessRules",
  QueryModified = "QueryModified",
  ExternalOrgImport = "ExternalOrgImport",
  BackgroundUpload = "BackgroundUpload",
  IdIndex = "IdIndex "
}
