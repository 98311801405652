<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        ATR / QM Exemption Eligibility
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #atrQmRecommendationForm="ngForm" id="atrQmRecommendationForm">
      <div class="row">
        <div class="form-group col-md-12">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="isAbilityToRepayRequirements"
              name="isAbilityToRepayRequirements" [(ngModel)]="isAbilityToRepayRequirements"
              (ngModelChange)="onAbilityToRepayRequirementsClicked()">
            <label class="form-check-label" for="isAbilityToRepayRequirements">
              Transaction is exempt from Reg. Z Ability-to-Repay requirements based on: </label>
          </div>
        </div>
      </div>
      <div class="row ms-3">
        <div class="form-group col-md-2">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="creditorTypeCheckbox" name="creditorTypeCheckbox"
              [(ngModel)]="creditorTypeChecked" (ngModelChange)="onCreditorTypeCheckboxClicked()"
              [disabled]="!isAbilityToRepayRequirements || !(loanProgramChecked || regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits || regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose)">
            <label class="form-check-label mt-2" for="creditorTypeCheckbox">
              Creditor Type </label>
          </div>
        </div>
        <div class="form-group col-md-5">
          <select id="abilityToRepayExemptionCreditorOrganizationType"
            name="abilityToRepayExemptionCreditorOrganizationType" class="form-select"
            (ngModelChange)="onCreditorTypeChanged()"
            [(ngModel)]="regulationZDetail.abilityToRepayExemptionCreditorOrganizationType"
            [disabled]="!(isAbilityToRepayRequirements && creditorTypeChecked)">
            <option *ngFor="let type of abilityToRepayExemptionCreditorOrganizationTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-5"
          *ngIf="regulationZDetail.abilityToRepayExemptionCreditorOrganizationType === 'Other'">
          <input type="text" class="form-control" id="abilityToRepayExemptionCreditorOrganizationTypeOtherDescription"
            name="abilityToRepayExemptionCreditorOrganizationTypeOtherDescription"
            [(ngModel)]="regulationZDetail.abilityToRepayExemptionCreditorOrganizationTypeOtherDescription"
            placeholder="Description">
        </div>
      </div>

      <div class="row ms-3">
        <div class="form-group col-md-2">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="loanProgramCheckbox" name="loanProgramCheckbox"
              [(ngModel)]="loanProgramChecked" (ngModelChange)="onLoanProgramCheckboxClicked()"
              [disabled]="!isAbilityToRepayRequirements || !(creditorTypeChecked || regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits || regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose)">
            <label class="form-check-label mt-2" for="loanProgramCheckbox">
              Loan Program </label>
          </div>
        </div>
        <div class="form-group col-md-5">
          <select id="abilityToRepayExemptionLoanProgramType" name="abilityToRepayExemptionLoanProgramType"
            (ngModelChange)="onLoanProgramChanged()" class="form-select"
            [(ngModel)]="regulationZDetail.abilityToRepayExemptionLoanProgramType"
            [disabled]="!(isAbilityToRepayRequirements && loanProgramChecked)">
            <option *ngFor="let type of abilityToRepayExemptionLoanProgramTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-5" *ngIf="regulationZDetail.abilityToRepayExemptionLoanProgramType === 'Other'">
          <input type="text" class="form-control" id="abilityToRepayExemptionLoanProgramTypeOtherDescription"
            name="abilityToRepayExemptionLoanProgramTypeOtherDescription"
            [(ngModel)]="regulationZDetail.abilityToRepayExemptionLoanProgramTypeOtherDescription"
            placeholder="Description">
        </div>
      </div>
      <div class="row ms-3">
        <div class="form-group col-md-2">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits"
              name="abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits"
              [(ngModel)]="regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits"
              (ngModelChange)="onLoanProgramCheckboxClicked()"
              [disabled]="!isAbilityToRepayRequirements || !(creditorTypeChecked || loanProgramChecked || regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose)">
            <label class="form-check-label" for="abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits">
              Unit Count </label>
          </div>
        </div>
        <div class="form-group col-md-10">
          <span> Subject Property has more than 4 units. </span>
        </div>
      </div>
      <div class="row ms-3">
        <div class="form-group col-md-2">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="abilityToRepayExemptionIsBasedOnCreditPurpose"
              name="abilityToRepayExemptionIsBasedOnCreditPurpose"
              [(ngModel)]="regulationZDetail.abilityToRepayExemptionIsBasedOnCreditPurpose"
              (ngModelChange)="onLoanProgramCheckboxClicked()"
              [disabled]="!isAbilityToRepayRequirements || !(creditorTypeChecked || loanProgramChecked || regulationZDetail.abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits)">
            <label class="form-check-label" for="abilityToRepayExemptionIsBasedOnCreditPurpose">
              Credit Type </label>
          </div>
        </div>
        <div class="form-group col-md-10">
          <span> Credit being extended is not primarily for personal, family or household purposes. </span>
        </div>
      </div>
    </form>
  </div>
</div>