<div>
  <div style="margin-left: 50rem">
    <div class="form-check form-switch form-switch-success">
      <input id="loanEstimate" class="form-check-input" [ngClass]="{'cursor': !isReadonly}" type="checkbox"
        [(ngModel)]="disclosureTrackingItem.brokerDisclosed" [readonly]="isReadonly"/>
      <label for="loanEstimate" class="form-check-label" [ngClass]="{'cursor': !isReadonly, 'pe-none': isReadonly}">
        Loan Estimate Disclosed by Broker
      </label>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="d-flex align-items-center">
                  <label class="col-sm-3 control-label" for="disclosureType"> Disclosure Type </label>
                  <div class="col-sm-6">
                    <select class="form-select" id="disclosureType" name="disclosureType"
                      [(ngModel)]="disclosureTrackingItem.disclosureVersion" [disabled]="isReadonly">
                      <option value="0">-- Select One --</option>
                      <option *ngFor="let item of disclosureTypes" value={{item.value}}>
                        {{item.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-8 mt-2">
                <div class="d-flex align-items-center">
                  <label class="col-sm-3 control-label" for="sentDateDetails"> Sent Date </label>
                  <div class="col-sm-6">
                    <date-input [id]="'sentDateDetails'" [name]="'sentDateDetails'"
                      [(ngModel)]="disclosureTrackingItem.disclosureSent" [readonly]="isReadonly">
                    </date-input>
                  </div>
                </div>
              </div>
              <div class="col-md-8 mt-2">
                <div class="d-flex align-items-center">
                  <label class="col-sm-3 control-label" for="by"> By </label>
                  <div class="col-sm-6">
                    <input class="form-control" id="by" name="by" [(ngModel)]="userBy" [readonly]="isReadonly" />
                  </div>
                </div>
              </div>
              <div class="col-md-8 mt-2">
                <div class="d-flex align-items-center">
                  <label class="col-sm-3 control-label" for="sentMethod"> Sent Method </label>
                  <div class="col-sm-6">
                    <input class="form-control" id="sentMethod" name="sentMethod" [(ngModel)]="sentMethod" [readonly]="isReadonly" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="form-check form-switch form-switch-success cursor">
                <input id="intentToProceed" class="form-check-input cursor" type="checkbox"
                  [(ngModel)]="isIntentToProceed" (click)="onIntentToProceedChanged()" />
                <label for="intentToProceed" class="form-check-label cursor">
                  Intent to Proceed
                </label>
              </div>
              <div class="col-md-6" [ngClass]="{'bg-color-shadow': !isIntentToProceed}">
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="date"> Date </label>
                  <div class="col-sm-8">
                    <date-input [id]="'date'" [name]="'date'"
                      [(ngModel)]="disclosureTrackingItem.intentToProceedReceived"
                      [readonly]="!isIntentToProceed"></date-input>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="receivedBy"> Received By </label>
                  <div class="col-sm-8">
                    <input class="form-control" id="receivedBy" name="intentToProceedReceivedByUserId"
                      [(ngModel)]="disclosureTrackingItem.intentToProceedReceivedByUserId" [readonly]="isReadonly" />
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="intentToProceedReceivedMethod"> Received
                    Method </label>
                  <div class="col-sm-8">
                    <select class="form-select" id="intentToProceedReceivedMethod" name="intentToProceedReceivedMethod"
                      [(ngModel)]="disclosureTrackingItem.intentToProceedReceivedMethod" [disabled]="isReadonly">
                      <option value="0">-- Select One --</option>
                      <!-- <option *ngFor="let item of ItemsList" value={{item.value}}>
                                            {{item.name}}
                                        </option> -->
                    </select>

                  </div>
                </div>
                <label> Comments </label>
                <textarea id="intentToProceedComments" name="intentToProceedComments" class="form-control mb-2" rows="4"
                  [(ngModel)]="disclosureTrackingItem.intentToProceedComments" [readonly]="isReadonly">
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="min-height: 482px;">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <span> <strong> Borrower - {{primaryBorrower.firstName + ' ' +
                    primaryBorrower.lastName}} </strong> </span>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center mt-3">
                  <label class="col-sm-4 control-label" for="borrowerRecievedMethod"> Received Method
                  </label>
                  <div class="col-sm-6">
                    <input class="form-control" id="borrowerRecievedMethod" name="borrowerRecievedMethod"
                      [(ngModel)]="disclosureTrackingItem.borrowers[0].receivedMethod" [readonly]="isReadonly" />
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="borrowerPresumedReceivedDate">
                    Presumed Received Date
                  </label>
                  <div class="col-sm-6">
                    <date-input [id]="'borrowerPresumedReceivedDate'" [name]="'borrowerPresumedReceivedDate'"
                      [(ngModel)]="disclosureTrackingItem.borrowers[0].presumedReceivedDate"
                      [readonly]="isReadonly"></date-input>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="borrowerActualReceivedDate">
                    Actual Received Date
                  </label>
                  <div class="col-sm-6">
                    <date-input [id]="'borrowerActualReceivedDate'" [name]="'borrowerActualReceivedDate'"
                      [(ngModel)]="disclosureTrackingItem.borrowers[0].actualReceivedDate" [readonly]="isReadonly"></date-input>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="borrowerMessageViewedDate">
                    Message Viewed Date
                  </label>
                  <div class="col-sm-6">
                    <date-input [id]="'borrowerMessageViewedDate'" [name]="'borrowerMessageViewedDate'"
                      [(ngModel)]="disclosureTrackingItem.borrowers[0].messageViewedDate" [readonly]="isReadonly"></date-input>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="borrowerSignedDate">
                    Signed Date
                  </label>
                  <div class="col-sm-6">
                    <date-input [id]="'borrowerSignedDate'" [name]="'borrowerSignedDate'"
                      [(ngModel)]="disclosureTrackingItem.borrowers[0].signedDate" [readonly]="isReadonly"></date-input>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center mt-2">
                  <label class="col-sm-4 control-label" for="borrowerSignedFromIp">
                    Signed From Ip
                  </label>
                  <div class="col-sm-6">
                    <input class="form-control" id="borrowerSignedFromIp" name="borrowerSignedFromIp"
                    [(ngModel)]="disclosureTrackingItem.borrowers[0].signedFromIp" [readonly]="isReadonly" />
                  </div>
                </div>
              </div>
              <ng-container *ngIf="coBorrowers.length > 0">
                <div class="col-md-12 mt-4 mb-2">
                  <span> <strong> Co-Borrowers </strong> </span>
                </div>
                <div *ngFor="let borrower of coBorrowers">
                  <div class="accordion accordion-flush" id="accordionTrackingCoBorrowers_{{borrower.borrowerId}}">
                    <div class="accordion-item">
                      <h5 class="accordion-header m-0" id="accordion-coBorrowers">
                        <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse'+ borrower.borrowerId"
                          [attr.aria-controls]="'collapse' + borrower.borrowerId" aria-expanded="false">
                          {{getCoBorrowerName(borrower)}}
                        </button>
                      </h5>
                      <div [id]="'collapse' + borrower.borrowerId" class="accordion-collapse collapse"
                        aria-labelledby="accordion-coBorrowers"
                        data-bs-parent="#accordionTrackingCoBorrowers_{{borrower.borrowerId}}">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-8">
                              <div class="d-flex align-items-center mt-2">
                                <label class="col-sm-4 control-label"
                                  for="coBorrowerRecievedMethod_{{borrower.borrowerId}}">
                                  Received Method
                                </label>
                                <div class="col-sm-6">
                                  <input class="form-control" id="coBorrowerRecievedMethod_{{borrower.borrowerId}}"
                                    name="coBorrowerRecievedMethod_{{borrower.borrowerId}}"
                                    [(ngModel)]="borrower.receivedMethod" [readonly]="isReadonly" />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="d-flex align-items-center mt-2">
                                <label class="col-sm-4 control-label"
                                  for="coBorrowerPresumedReceivedDate_{{borrower.borrowerId}}">
                                  Presumed Received Date
                                </label>
                                <div class="col-sm-6">
                                  <date-input [id]="'coBorrowerPresumedReceivedDate_{{borrower.borrowerId}}'"
                                    [name]="'coBorrowerPresumedReceivedDate_{{borrower.borrowerId}}'"
                                    [(ngModel)]="borrower.presumedReceivedDate" [readonly]="isReadonly">
                                  </date-input>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-8">
                              <div class="d-flex align-items-center mt-2">
                                <label class="col-sm-4 control-label"
                                  for="coBorrowerActualReceivedDate_{{borrower.borrowerId}}">
                                  Actual Received Date
                                </label>
                                <div class="col-sm-6">
                                  <date-input id="coBorrowerActualReceivedDate_{{borrower.borrowerId}}"
                                    name="coBorrowerActualReceivedDate_{{borrower.borrowerId}}"
                                    [(ngModel)]="borrower.actualReceivedDate" [readonly]="isReadonly"></date-input>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="d-flex align-items-center mt-2">
                                <label class="col-sm-4 control-label"
                                  for="coBorrowerMessageViewedDate_{{borrower.borrowerId}}">
                                  Message View Date
                                </label>
                                <div class="col-sm-6">
                                  <date-input id="coBorrowerMessageViewedDate_{{borrower.borrowerId}}"
                                    name="coBorrowerMessageViewedDate_{{borrower.borrowerId}}"
                                    [(ngModel)]="borrower.messageViewedDate" [readonly]="isReadonly"></date-input>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="d-flex align-items-center mt-2">
                                <label class="col-sm-4 control-label"
                                  for="coBorrowerSignedDate_{{borrower.borrowerId}}">
                                  Signed Date
                                </label>
                                <div class="col-sm-6">
                                  <date-input id="coBorrowerSignedDate_{{borrower.borrowerId}}"
                                    name="coBorrowerSignedDate_{{borrower.borrowerId}}"
                                    [(ngModel)]="borrower.signedDate" [readonly]="isReadonly"></date-input>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="d-flex align-items-center mt-2">
                                <label class="col-sm-4 control-label"
                                  for="coBorrowerSignedFromIp_{{borrower.borrowerId}}">
                                  Signed Date
                                </label>
                                <div class="col-sm-6">
                                  <input class="form-control" id="coBorrowerSignedFromIp_{{borrower.borrowerId}}"
                                  name="coBorrowerSignedFromIp_{{borrower.borrowerId}}"
                                  [(ngModel)]="borrower.signedFromIp" [readonly]="isReadonly" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="accordion accordion-flush" id="accordionLoanDoc">
        <div class="accordion-item">
          <h5 class="accordion-header m-0" id="accordion-loanDoc">
            <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseLoanDoc" aria-expanded="true" aria-controls="flush-collapseLoanDoc">
              Documents Sent ({{disclosureTrackingItem?.documents?.length ? disclosureTrackingItem.documents.length : '0'}})
            </button>
          </h5>
          <div id="flush-collapseLoanDoc" class="accordion-collapse collapse show" aria-labelledby="accordion-loanDoc"
            data-bs-parent="#accordionLoanDoc">
            <div class="accordion-body">
              <div class="row">
                <p-table #dt1 [value]="disclosureTrackingItem.documents | sort: 'asc':'dateInserted'" *ngIf="disclosureTrackingItem?.documents?.length"
                  styleClass="p-datatable-gridlines">
                  <ng-template pTemplate="header">
                    <tr>
                      <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : ''"
                        style="min-width: 100px;">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-doc>
                    <tr>
                      <td>
                        <div class="d-flex">
                          {{doc.title || "[No Title]"}}
                          <a (click)="onViewDocumentClicked(doc)" title="View Document" class="ms-2 align-text-bottom">
                            <i class="far fa-eye"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="3" class="text-center"> No document found. </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
