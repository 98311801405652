import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'final-submission-info',
  templateUrl: './final-submission-info.component.html',
  styleUrls: ['./final-submission-info.component.scss']
})
export class FinalSubmissionInfoComponent implements OnInit {

  @Input()
  data: any;

  isPurchase: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
