import { Component, Input, OnInit } from '@angular/core';
import { AppraisalOrderView } from 'src/app/models/appraisal/appraisal-order-view.model';

@Component({
  selector: 'appraisal-history-tab',
  templateUrl: './appraisal-history.component.html',
  styleUrls: ['./appraisal-history.component.scss']
})
export class AppraisalHistoryComponent implements OnInit {
  @Input() appraisalHistory: Array<AppraisalOrderView>;

  constructor() { }

  ngOnInit(): void {
  }

}
