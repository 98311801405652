<div class="card" *ngIf="profile">
  <div class="card-header">
    <h4 class="card-title">Profile Info</h4>
  </div>
  <div class="card-body">
    <div class="my-4">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">User Name</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="User Name" type="text" disabled [(ngModel)]="profile.userName"
                required />
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Date of Birth</label>
            <div class="col-sm-10">
              <date-input [name]="'birthDate'" [max]="maxDate" [min]="minDate" [id]="'birthDate'"
                [(ngModel)]="profile.dob">
              </date-input>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <!-- <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Email Signature</label>
            <div class="col-sm-10">
              <rich-text-editor
                [name]="'emailSignature'"
                [height]="'30rem'"
                [(ngModel)]="profile.emailSignature">
              </rich-text-editor>
            </div> -->
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <label class="email-signature-label form-label">Email Signature </label>
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked()">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="emailSignatureIframe"></iframe>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">E-Signature</label>
            <div class="col-sm-10">
              <img class="mb-2" [src]="(profile.signatureImageData || signatureImage) | safeUrl"
                *ngIf="profile.signatureImageData || signatureImage" width="110" />
              <input type="file" #uploadESignatureFile (change)="onESignatureFileChanged($event.target.files)"
                class="form-control" accept="image/*" style="display: none" />
              <div>
                <button type="button" class="btn btn-primary me-1" (click)="openDrawingPad()">
                  Draw
                </button>
                <button type="button" class="btn btn-primary" (click)="uploadESignatureFile.click()">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">First Name</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="First Name" type="text" [(ngModel)]="profile.firstName" />
            </div>
          </div>

          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Middle Name</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Middle Name" [(ngModel)]="profile.middleName" />
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Last Name</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Last Name" [(ngModel)]="profile.lastName" />
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">SMS Signature</label>
            <div class="col-sm-10">
              <textarea class="form-control" [(ngModel)]="profile.smsSignature" maxlength="100"></textarea>
              <i class="text-secondary">
                {{ 100 - (profile.smsSignature ? profile.smsSignature.length : 0) }} character(s) left
              </i>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Calendar Link</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Calendar Link" [(ngModel)]="profile.calendarLink"
                [ngClass]="{'is-invalid' : calendarLinkInput && calendarLinkInput.touched && calendarLinkInput.invalid}"
                pattern="{{urlPattern}}" #calendarLinkInput="ngModel" />
              <div class="invalid-feedback d-block" *ngIf="calendarLinkInput.errors?.['pattern']">Please enter a valid
                url</div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Website Link</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Website Link" [(ngModel)]="profile.websiteLink"
                [ngClass]="{'is-invalid' : websiteLinkInput && websiteLinkInput.touched && websiteLinkInput.invalid}"
                pattern="{{urlPattern}}" #websiteLinkInput="ngModel" />
              <div class="invalid-feedback d-block" *ngIf="websiteLinkInput.errors?.['pattern']">Please enter a valid
                url</div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Review Link</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Review Link" [(ngModel)]="profile.reviewLink"
                [ngClass]="{'is-invalid' : reviewLinkInput && reviewLinkInput.touched && reviewLinkInput.invalid}"
                pattern="{{urlPattern}}" #reviewLinkInput="ngModel" />
              <div class="invalid-feedback d-block" *ngIf="reviewLinkInput.errors?.['pattern']">Please enter a valid
                url</div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">#NMLS</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="#NMLS" [(ngModel)]="profile.nmls" />
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Title</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" placeholder="Title" [(ngModel)]="profile.title" />
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Timezone</label>
            <div class="col-sm-10">
              <select class="form-select" [(ngModel)]="profile.timeZone">
                <option value="">-- Select One --</option>
                <option *ngFor="let item of timezones" [ngValue]="item.id">
                  {{ item.displayName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Avatar</label>
            <div class="col-sm-10">
              <img [src]="image || userImage" alt="Admin" class="rounded-circle p-1" width="110" />

              <input type="file" #uploadAvatarFile (change)="onFileChanged($event)" class="form-control"
                accept="image/*" style="display: none" />

              <div class="mt-1">
                <button class="btn btn-primary me-1" (click)="uploadAvatarFile.click()">
                  Select Image
                </button>

                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#avatarRemoveModal"
                  [disabled]="onDisabledRemoveButton()">
                  Remove Image
                </button>
              </div>

              <!-- Modal -->
              <div class="modal fade" id="avatarRemoveModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" aria-labelledby="avatarRemoveModalLabel" aria-hidden="true" style="z-index: 9999">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="avatarRemoveModalLabel">
                        Are you sure ?
                      </h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      If you continue with this process, you will also lose your
                      existing profile photo.
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button type="button" class="btn btn-primary" (click)="onRemoveImage()" data-bs-dismiss="modal">
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isImageSelected">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                  [aspectRatio]="4 / 4" format="png" (imageCropped)="onImageCropped($event)">
                </image-cropper>
                <button class="btn btn-primary float-end mt-2 ms-1" (click)="onSelectCroppedImage()">
                  Add Image
                </button>
                <button type="button" class="btn btn-primary float-end mt-2" (click)="onCloseImage()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<drawer #editEmailSignatureTemplateDrawer [name]="'editEmailSignatureTemplateDrawer'"
  [options]="editEmailSignatureTemplateDrawerOptions">
</drawer>
