<div class="row" *ngIf="fees && !applyingFees && !feesApplied">
  <div class="col-12 d-flex justify-content-center">
    <h3 class="">Total Fees: {{totalFeeAmount | currency}}</h3>
  </div>
  <div class="col-12 col-md-6" *ngFor="let section of sections; let index = index">
    <fee-summary-card
      [feeSection]="section"
      [fees]="feesGroupedBySection[section]"
      [index]="index"
      [cardHeights]="cardHeights">
    </fee-summary-card>
  </div>
</div>

<div class="d-flex justify-content-center" *ngIf="applyingFees">
  <div class="d-flex">
    <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
    <h5 class="font-18 text-muted me-4">Applying Fees to Loan...</h5>
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="feesApplied">
  <div class="d-flex">
    <span class="mt-2 me-2 text-success">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></span>
    <h5 class="font-18 text-muted me-4">Fees Successfully Applied to Loan!</h5>
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="errorMessage">
  <div class="d-flex">
    <span class="mt-2 me-2 text-danger"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle icon-dual text-danger"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg></span>
    <h5 class="font-18 text-muted me-4">{{errorMessage}}</h5>
  </div>
</div>
