<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h4 class="card-title">Data Validation</h4>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div *ngIf="errors.length == 0 && warnings.length == 0">
                    <h4><i class="fa fa-check-circle green me-2"></i>Passed</h4>
                </div>
                <div *ngFor="let error of errors">
                    <h4 style="cursor: pointer;" [routerLink]="['/tpo/app-details/' + application.applicationId + '/' + error.link]"  title="Click here to go there"><i class="fa fa-times-circle red me-2"></i><span [innerHtml]="error.message | safeHtml"></span></h4>
                </div>
                <div *ngFor="let warning of warnings">
                    <h4 style="cursor: pointer;" [routerLink]="['/tpo/app-details/' + application.applicationId + '/' + warning.link]" title="Click here to go to pricing"><i class="fa fa-exclamation-circle yellow me-2"></i><span [innerHtml]="warning.message | safeHtml"></span></h4>
                </div>
            </div>
        </div>
    </div>
</div>