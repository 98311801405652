import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskNote } from 'src/app/services/task.service';

@Component({
  selector: 'add-rejection-notes-dialog',
  templateUrl: './add-rejection-notes-dialog.component.html',
  styleUrls: ['./add-rejection-notes-dialog.component.scss']
})
export class AddRejectionNotesDialogComponent implements OnInit {

  @Input()
  borrowerFacingApplicable: boolean = false;

  taskNote: TaskNote = new TaskNote();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
