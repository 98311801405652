import { Component, EventEmitter, Injector, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { formViewProvider } from '../../../../core/services/form-view.provider';

@Component({
  selector: 'default-fees',
  templateUrl: 'default-fees.component.html',
  styleUrls: ['../../loan-fees.component.scss'],
  viewProviders: [formViewProvider]
})
export class DefaultFeesComponent implements OnInit {

  @Input()
  set fees(fees: LoanFee[]) {
    this._fees = fees;
  }

  get fees(): LoanFee[] {
    return this._fees;
  }

  @Input() deleteAllowed: boolean = true;

  @Output() onFeeUpdated = new EventEmitter<LoanFee>();
  @Output() onFeeRemoved = new EventEmitter<LoanFee>();
  @Output() openFeeEditor = new EventEmitter<LoanFee>();

  @ViewChildren("defaultFeeDetailsEditorPopover")
  defaultFeeDetailsEditorPopover: QueryList<PopoverDirective>;

  pendingDeleteIndex: number = -1;

  selectedFeeForEditingDetails: LoanFee = null;

  private _fees: LoanFee[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  removeFee(fee: LoanFee) {
    this.pendingDeleteIndex = -1;
    this.onFeeRemoved.next(fee);
  }

  onPercentChanged = (fee: LoanFee, type: 'borrower' | 'seller') => {
    switch (type) {
      case 'borrower':
        fee.borrowerFeeDollar = 0;
        break;
      case 'seller':
        fee.sellerFeeDollar = 0;
        break;
      default:
        break;
    }

    this.onFeeUpdated.emit(fee);
  }

  onDollarChanged = (fee: LoanFee, type: 'borrower' | 'seller') => {
    switch (type) {
      case 'borrower':
        fee.borrowerFeePercent = 0;
        break;
      case 'seller':
        fee.sellerFeePercent = 0;
        break;
      default:
        break;
    }

    this.onFeeUpdated.emit(fee);
  }

  onFeeEditorOpened = (fee: LoanFee) => {
    this.openFeeEditor.emit(fee);
  }
}
