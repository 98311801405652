import { SafeUrl } from "@angular/platform-browser";

export interface UserVoicemail {
    userVoicemailId: number;
    userId: string;
    fileGuid: string;
    name: string;
    file: File;
    fileType: AudioFileType;
    playableFileUrl?: SafeUrl;
}

export enum AudioFileType {
    Unknown = 'Unknown',
    WAV = 'WAV',
    MP3 = 'MP3',
    M4A = 'M4A'
}