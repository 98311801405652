<div class="modal-header">
  <h5 class="modal-title">Manage Borrowers</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div cdkDropListGroup>
    <div class="container" *ngFor="let application of applications; let appIndex = index;">
      <h5>Application {{appIndex + 1}}</h5>
      <div cdkDropList
           [cdkDropListData]="application"
           class="list"
           (cdkDropListDropped)="drop($event)"
           [cdkDropListEnterPredicate]="dropListEnterPredicate()">
        <div class="box" *ngFor="let borrower of application; let borrowerIndex = index;" [cdkDragData]="borrower" cdkDrag>
          <span>{{borrower.firstName}} {{borrower.lastName}}</span>
          <div>
            <span class="ms-1 me-1 badge {{signingRoleNames.get(borrower.borrowerId) === 'Borrower' ? 'bg-success' : 'bg-warning'}}">{{signingRoleNames.get(borrower.borrowerId)}}</span>
            <button type="button" class="btn btn-outline-info btn-icon-circle btn-icon-circle-sm"
            (click)="deleteBorrower(appIndex, borrowerIndex)">
              <i class="fa fa-times"></i>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="button-items">
    <button type="button" class="btn btn-primary right" (click)="save()">Save</button>
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">Cancel</button>
  </div>
</div>

