import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadStatus, LoanStatus } from 'src/app/models';
import { LeadStatusAssociation } from 'src/app/models/config/lead-status-association.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanStatusAssociation } from 'src/app/models/config/loan-status-association.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DialListBasic } from '../../models/dial-list-basic.model';
import { DialListRecordBasic, RecordType } from '../../models/dial-list-record-basic.model';

@Component({
  selector: 'lead-status',
  templateUrl: './lead-status.component.html',
  styleUrls: ['./lead-status.component.scss']
})
export class LeadStatusComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output() statusChanged = new EventEmitter<DialListBasic>();
  @Output() recordStatusChanged = new EventEmitter<number>();

  @Input() selectedRecord: DialListRecordBasic;
  @Input() currentDialOfRecord: DialListBasic;

  loanPurpose: LoanPurpose[] = [];
  leadStatus: LeadStatus[] = [];
  loanStatus: LoanStatus[] = [];
  loanStatusAssociation: LoanStatusAssociation[] = [];
  leadStatusAssociation: LeadStatusAssociation[] = [];
  availableStatuses: { statusId: number, statusName: string }[] = [];

  constructor(
    private readonly _leadsService: LeadsService,
    private readonly _loanService: LoanService,
    private readonly _notifyService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.selectedRecord.statusId) {
      this.selectedRecord.statusId = null;
    }
    this.applicationContextService.context.subscribe(context => {

      this.loanPurpose = context.globalConfig.loanPurpose;
      this.leadStatus = context.globalConfig.leadStatus;
      this.loanStatus = context.globalConfig.loanStatus;
      this.loanStatusAssociation = context.globalConfig.loanStatusAssociation;
      this.leadStatusAssociation = context.globalConfig.leadStatusAssociation;

      this.availableStatuses = this.getInitialStatuses(this.selectedRecord);

      this.prepareStatuses();

    });
  }

  prepareStatuses = () => {
    let leadStatus = this.selectedRecord.statusId ?
      this.leadStatus.find(ls => ls.loanStatusId == this.selectedRecord.statusId) :
      this.leadStatus.find(ls => ls.loanStatusName == 'New Lead'); // some records has null, to fix, default it to 'New Lead'.
    if (leadStatus) {
      this.availableStatuses = [{ statusId: leadStatus.loanStatusId, statusName: leadStatus.loanStatusName }];
    }
  }

  updateStatus = () => {
    if (!this.selectedRecord.statusId) {
      return;
    }
    let observable: Observable<any>;
    if (this.selectedRecord.recordType == RecordType.Lead) {
      observable = this._leadsService.updateLeadStatus(this.selectedRecord.leadId, this.selectedRecord.statusId);
    } else {
      observable = this._loanService.saveLoanStatus(this.selectedRecord.applicationId, this.selectedRecord.statusId, this.selectedRecord.subStatusId);
    }

    observable.subscribe({
      next: () => {
        this.statusChanged.emit(this.currentDialOfRecord);
        this.recordStatusChanged.emit(this.selectedRecord.statusId);
        this._notifyService.showSuccess("Status changed successfully.", "Status");
      },
      error: (err) => {
        this._notifyService.showError(err.error.message || err.error, "Error!");
      }
    });
  }

  populateAvailableStatuses = () => {
    if (this.selectedRecord.recordType == RecordType.Lead) {
      this.getAvailableStatuses(this.selectedRecord, true);
    } else {
      this.getAvailableStatuses(this.selectedRecord, false);
    }
  }

  getAvailableStatuses = (record: DialListRecordBasic, isLead: boolean) => {
    if (!record.loanPurpose || !record.statusId) {
      return;
    }
    let loanPurpose = this.loanPurpose.find(lp => lp.loanPurposeName == record.loanPurpose);
    if (!loanPurpose) {
      return;
    }

    let channel = '';
    if (this.selectedRecord.channelName) {
      if (this.selectedRecord.channelName.toLowerCase() !== 'none') {
        channel = this.selectedRecord.channelName;
      }
    }
    let observable: Observable<LeadStatus[] | LoanStatus[]> = isLead
      ? this._leadsService.getLeadStatusesForLoanPurpose(loanPurpose.loanPurposeId, record.statusId)
      : this._loanService.getLoanStatusesForLoanPurpose(loanPurpose.loanPurposeId, record.statusId, this.selectedRecord.applicationId, channel);

    observable.subscribe((statuses) => {
      if (statuses.length > 0) {
        this.availableStatuses = statuses.map(r => {
          return { statusId: r.loanStatusId, statusName: r.loanStatusName };
        });
      }
    });
  }

  getInitialStatuses = (record: DialListRecordBasic) => {
    let initialStatuses = [];
    if (record.recordType === RecordType.Lead) {
      initialStatuses = this.getInitialLeadStatuses(record);
    } else {
      initialStatuses = this.getInitialLoanStatuses(record);
    }
    return initialStatuses;
  }

  getInitialLeadStatuses = (record: DialListRecordBasic) => {
    if (!record.statusId) {
      // If there is no lead status set, you can initialize with everything applicable for the loan purpose
      let availableStatuses = this.getAllAvailableLeadStatuses(record);
      return availableStatuses;
    } else {
      // Now, since there is a status set, just initialize a single item array
      // with the current status, and defer the actual population on click
      let leadStatus = this.leadStatus.find(s => s.loanStatusId === record.statusId);
      if (!leadStatus) {
        return [];
      }
      let initialSingleItemStatusList = [];
      initialSingleItemStatusList.push({ statusId: record.statusId, statusName: leadStatus.loanStatusName });
      return initialSingleItemStatusList;
    }
  }

  getAllAvailableLeadStatuses = (record: DialListRecordBasic) => {
    return this.getAllAvailableStatuses(record, this.leadStatusAssociation, this.leadStatus);
  }

  getAllAvailableStatuses = (record: DialListRecordBasic, purposeToStatusAssociation: (LeadStatusAssociation | LoanStatusAssociation)[], statusLookup: (LeadStatus | LoanStatus)[]) => {
    if (!record.loanPurpose) {
      return [];
    }
    let loanPurpose = this.loanPurpose.find(lp => lp.loanPurposeName === record.loanPurpose);
    if (!loanPurpose) {
      return [];
    }
    let statuses = purposeToStatusAssociation.filter(s => s.loanPurposeId === loanPurpose.loanPurposeId)
      .map(ls => {
        let statusId = ls.loanStatusId;
        let status = statusLookup.find(s => s.loanStatusId === statusId);
        if (!status) {
          return null;
        }
        return { statusId: statusId, statusName: status.loanStatusName }
      });
    return statuses.filter(s => s !== null);
  }

  getInitialLoanStatuses = (record: DialListRecordBasic) => {
    if (!record.statusId) {
      // If there is no loan status set, you can initialize with everything applicable for the loan purpose
      let availableStatuses = this.getAllAvailableLoanStatuses(record);
      return availableStatuses;
    } else {
      // Now, since there is a status set, just initialize a single item array
      // with the current status, and defer the actual population on click
      let loanStatus = this.loanStatus.find(s => s.loanStatusId === record.statusId);
      if (!loanStatus) {
        return [];
      }
      let initialSingleItemStatusList = [];
      initialSingleItemStatusList.push({ statusId: record.statusId, statusName: loanStatus.loanStatusName });
      return initialSingleItemStatusList;
    }
  }

  getAllAvailableLoanStatuses = (record: DialListRecordBasic) => {
    return this.getAllAvailableStatuses(record, this.loanStatusAssociation, this.loanStatus);
  }

}
