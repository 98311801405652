<div class="characteristics">
  <div class="d-flex justify-content-between">
    <h4 class="card-title mt-2">
      <strong> Characteristics </strong>
    </h4>
    <div class="special-radio-btn-group">
      <div class="btn-group btn-group-left" role="group">
        <input type="radio" id="app" name="btnCharType" class="btn-check" value="1" autocomplete="off"
          [(ngModel)]="characteristicType">
        <label class="btn btn-outline-secondary" for="app"> Application </label>
        <input type="radio" id="borrower" name="btnCharType" class="btn-check" value="2" autocomplete="off"
          [(ngModel)]="characteristicType">
        <label class="btn btn-outline-secondary" for="borrower"> Borrower </label>
      </div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12" class="characteristic-container" *ngIf="!loading else spinner">
      <loan-characteristics-card *ngIf="characteristicType === '1'" class="loan-characteristic-container"
        (loanCharacteristicsSaved)="onCharacteristicsSaved($event)" [application]="application"
        [loanCharacteristics]="loanCharacteristics" (onClose)="onPopoverClosed()">
      </loan-characteristics-card>
      <borrower-characteristics-card *ngIf="characteristicType === '2'" [application]="application"
        [borrowerCharacteristics]="borrowerCharacteristics"
        (borrowerCharacteristicsSaved)="onCharacteristicsSaved($event)" (onClose)="onPopoverClosed()">
      </borrower-characteristics-card>
    </div>
  </div>
  <ng-template #spinner>
    <loading-indicator loadingMessage="Loading characteristics...">
    </loading-indicator>
  </ng-template>
</div>