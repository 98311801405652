import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ErrorMessage} from 'src/app/shared/models/error.model';
import {ApplicationContextBoundComponent} from "../../../../../shared/components";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})
export class SupportComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild('changePasswordForm') changePasswordForm: NgForm | undefined;
  @ViewChild('changeUsernameForm') changeUsernameForm: NgForm | undefined;
  @Input() userCompanyGuid : string;
  newPassword: string;
  confirmNewPassword: string;
  submittingPassword: boolean;
  username: string;
  submittingUsername: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _authService: AuthService,
    private readonly _notifyService: NotificationService) {
    super(injector);
  }

  ngOnInit(): void {
    this.intializeRquest();
  }

  intializeRquest() {
    if (this.changePasswordForm && this.changePasswordForm.form) {
      this.newPassword = null;
      this.confirmNewPassword = null;
      this.changePasswordForm.form.markAsUntouched();
    }
  }

  onChangePasswordClicked() {
    this.changePasswordForm.form.markAllAsTouched();
    if (!this.changePasswordForm.form.valid) {
      return;
    }
    this.submittingPassword = true;
    this._authService.changeProfileUserPassword(this.userCompanyGuid, {
      newPassword: this.newPassword,
      confirmPassword: this.confirmNewPassword
    })
      .pipe(finalize(() => this.submittingPassword = false))
      .subscribe({
        next: (response) => {
          this._notifyService.showSuccess('Password changed successfully.',
            'Password Changed');
          this.intializeRquest();
        }, error: (err) => {
          this._notifyService.showError(err.error || 'Unable to change password.',
            'Password Change');
        }
      });
  }

  onChangeUsernameClicked() {
    this.changeUsernameForm.form.markAllAsTouched();
    if (!this.changeUsernameForm.form.valid) {
      return;
    }
    this.submittingUsername = true;
    this._authService.changeProfileUsername(this.userCompanyGuid, this.username)
      .pipe(finalize(() => this.submittingUsername = false))
      .subscribe({
        next: (response) => {
          this._notifyService.showSuccess('Username changed successfully.',
            'Password Changed');
          this.intializeRquest();
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to change username.',
            'Username Change');
        }
      });
  }
}
