export enum CalendarDayType {
    None = 'None',
    Holiday = 'Holiday',
    FederalHoliday = 'FederalHoliday',
    RegionalHoliday = 'RegionalHoliday',
    ReligiousHoliday = 'ReligiousHoliday',
    Other = 'Other'
}

export interface GlobalCalendarDay extends CalendarDay {
    globalCalendarDayId: number;
}

export interface OtherCalendarDay extends CalendarDay {
    calendarDayId: number;
}

export interface CalendarDay {
    title: string;
    date: string;
    type: CalendarDayType;
    isGlobal?: boolean;
}

export interface MergedCalendarDay {
    id: number;
    title: string;
    date: Date;
    dateStr: string;
    type: CalendarDayType;
    isGlobal: boolean;
    allDay: boolean;
    override: boolean;
    backgroundColor: string;
    className: string;
}
