import { Component, Injector, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SystemLevelService } from '../../../../services/system-level.service';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevel } from 'src/app/models';
import { Configuration } from 'src/app/models/configuration.model';

@Component({
    selector: 'document-export-format',
    templateUrl: './document-export-format.component.html'
})
export class DocumentExportFormatComponent implements OnInit {
    documentExportFormat: Configuration = {} as Configuration;
    isLoading: boolean;
    isSaving: boolean;

    constructor(
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
            const systemLevel = JSON.parse(JSON.stringify(data));
            this.documentExportFormat = systemLevel.documentExportFormat || {};
        }).add(() => this.isLoading = false);
    }

    save(): void {
        this.isSaving = true;
        this._systemLevelService.saveDocumentExportFormat(this.documentExportFormat).pipe(finalize(() => {
            this.isSaving = false;
        })).subscribe({
            next: res => {
                this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
            },
            error: error => {
                this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
            }
        });
    }
}
