export enum CalculatedFeeTypeEnum {
  Default = "Default",
  PropertyTaxUpFrontMonthlyPremium = "PropertyTaxUpFrontMonthlyPremium",
  OddDaysInterest = "OddDaysInterest",
  RealEstateCommission = "RealEstateCommission",
  MonthlyPaymentPremium = "MonthlyPaymentPremium",
  UpFrontSinglePaymentPremium = "UpFrontSinglePaymentPremium", // api does not inlude this
  UpFrontMonthlyPremium = "UpFrontMonthlyPremium"
}

