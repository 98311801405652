import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from "src/app/shared/shared.module";
import { UrlaDigitsInputComponent } from "./components/urla-digits-input/urla-digits-input.component";
import { UrlaCurrencyInputComponent } from "./components/urla-currency-input/urla-currency-input.component";
import { UrlaDropdownComponent } from "./components/urla-dropdown/urla-dropdown.component";
import { UrlaMenuComponent } from "../urla-menu/urla-menu.component";

@NgModule({
  imports: [
    SharedModule,
    NgSelectModule,
  ],
  declarations: [
    UrlaDropdownComponent,
    UrlaDigitsInputComponent,
    UrlaCurrencyInputComponent,
    UrlaMenuComponent
  ],
  exports: [
    UrlaDropdownComponent,
    UrlaDigitsInputComponent,
    UrlaCurrencyInputComponent,
    UrlaMenuComponent
  ],
  providers: []
})
export class UrlaCommonsModule {
}


