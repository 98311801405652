import { Injectable } from "@angular/core";
import { DataService } from "src/app/core/services/data.service";
import { ChangeOfCircumstanceDetail } from "../disclosure-tracking/models/disclosure-tracking.model";
import { Observable } from "rxjs";

@Injectable()
export class CocReasonsService {
  constructor(private readonly _dataService: DataService) {
  }

  getCocDetails = (mortgageId: number): Observable<ChangeOfCircumstanceDetail[]> => {
    const url = `api/change-of-circumstance/${mortgageId}`;
    return this._dataService.get(url);
  }

  upsertCocDetails = (mortgageId: number, request: ChangeOfCircumstanceDetail): Observable<ChangeOfCircumstanceDetail> => {
    const url = `api/change-of-circumstance/${mortgageId}`;
    return this._dataService.post(url, request);
  }

  deleteCocDetails = (changeOfCircumstanceId: number) => {
    const url = `api/change-of-circumstance/${changeOfCircumstanceId}`;
    return this._dataService.delete(url);
  }

  getCocDetailsByTask = (loanDocTaskId: number): Observable<ChangeOfCircumstanceDetail[]> => {
    const url = `api/change-of-circumstance/task/${loanDocTaskId}`;
    return this._dataService.get(url);
  }
}
