export class Attachment {
  cid: string;
  fileName: string;
  fileContent: string;

  constructor(cid: string, fileName: string, fileContent: string) {
    this.cid = cid;
    this.fileName = fileName;
    this.fileContent = fileContent;
  }
}
