<div class="col-md-12" *ngIf="!isHomeOwnersInsuranceEnabled">
    <enable-message>Call to Enable this feature!</enable-message>
</div>
<div *ngIf="isHomeOwnersInsuranceEnabled" class="row mt-1">
    <div class="col-md-3">
        <toggle-configuration [title]="'Enable Home Owners Insurance (HOI)'" [id]="'homeOwnersInsuranceEnabled'"
            [configuration]="homeOwnersInsuranceEnabled"></toggle-configuration>
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 pt-3" *ngIf="homeOwnersInsuranceEnabled.value">
                <div class="fieldset-title">
                    <div class="row">
                        <div class="col-md-6">QuickInsured</div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="homeOwnersInsuranceEnabled.value">
                <div class="row mt-3">
                    <div class="col-md-2 text-end">
                        <label class="custom-control-label">NMLS ID</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="company.nmls">
                    </div>
                    <div class="col-md-1">
                        <button type="submit" class="btn btn-primary w-100">
                            Save
                        </button>
                    </div>
                    <div class="offset-2 col-md-10 pt-2">
                        <p>
                            The QuickInsured (QIS) integration requires that all company, branch, and originator NMLS
                            IDs are
                            entered and correct. Failure to do so could result in incorrect company information being
                            sent to
                            the borrower.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <enable-message *ngIf="homeOwnersInsuranceEnabled.value === 0">
                    Enable this feature above!
                </enable-message>
            </div>
        </div>
    </div>
</div>