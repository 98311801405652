import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-credit-pull-dialog',
  templateUrl: './credit-pull-dialog.component.html',
  styleUrls: ['./credit-pull-dialog.component.scss']
})
export class CreditPullDialogComponent {
  @Input() leadId: number;

  constructor(public activeModal: NgbActiveModal) { }

  onCreditRunCompleted = (isSuccess: boolean) => {
    if (isSuccess) {
      //this.activeModal.close();
    }
  }
}
