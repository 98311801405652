<button
  style="border-radius: 15px"
  type="button"
  class="btn btn-outline-primary tippy-btn"
  data-bs-container="body"
  data-bs-toggle="popover"
  [popover]="filterTemplate"
  data-trigger="click"
  [outsideClick]="true"
  [ngClass]="{ 'selected-filter': selected, 'unselected-filter': !selected }"
  (click)="toggleSelection()"
  [containerClass]="'genericDateRangeFilter'"
  [placement]="popoverLocation"
>
  <div class="d-flex">
    <span *ngIf="icon" class="fw-bolder button-item-text flex-grow-1">
      <i class="{{ icon }}"></i>
    </span>
    <span
      class="fw-bolder button-item-text filter-label flex-grow-1 ms-1"
    >
      {{ labelLocal || 'Date Ranges' }}
    </span>
    <i class="fas fa-angle-down ms-2 mt-1"></i>
  </div>
</button>

<ng-template #filterTemplate>
  <div class="d-flex flex-column m-2">
    <div class="d-flex filter-header-container">
      <h4 class="filter-header flex-grow-1">Date Range</h4>
      <a style="margin-top: 3px" class="me-1" (click)="onClearClicked()" *ngIf="clearVisible"
        >Clear</a
      >
    </div>
    <div class="row p-2">
      <date-range-filter
        [showLabel]="false"
        [selectedRange]="dateRange"
        (rangeChanged)="dateRangeChanged($event)"
      ></date-range-filter>
    </div>
  </div>
</ng-template>
