<div class="task-order-title" *ngIf="mortgage">
  <div *ngIf="task.taskType === 'RequestAppraisal'">
    <appraisal-task-order [task]="task" [appId]="appId" [mortgage]="mortgage"></appraisal-task-order>
  </div>

  <div *ngIf="task.taskType === 'RequestTitle'">
    <quotes-task-order [task]="task" [appId]="appId" [mortgage]="mortgage"
      [globalConfig]="globalConfig"></quotes-task-order>
  </div>
</div>
