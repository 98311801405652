<form #form='ngForm'>
  <div class='m-header'>
    <h5>{{ effectiveFee.name }}</h5>

    <!-- Close Button -->
    <button
      (click)='onCancel()'
      aria-label='Close'
      class='btn btn-icon close-btn'
      title='Close'
      type='button'
    >
      <i class='fas fa-times'></i>
    </button>
  </div>

  <div class='m-body'>
    <!-- Fee Amount Input -->
    <div class='m-row' *ngLet='getId("fee-amount") as id'>
      <label [for]='id'>Fee amount</label>

      <ng-container *ngLet='{value: 0} as state'>
        <currency-input
          (blur)='onFieldChange(state.value, calculatedValues.monthlyFee)'
          (focusin)='state.value = calculatedValues.monthlyFee'
          [(ngModel)]='calculatedValues.monthlyFee'
          [id]='id'
          name='feeAmount'
          placeholder='$0.00'
        ></currency-input>
      </ng-container>

      <ng-container *ngLet='{
          id: getId("fee-amount-duration"),
          value: 0
        } as state'>
          <span class='suffixed-input months-input'>
            <input
              (blur)='onFieldChange(state.value, calculatedValues.months)'
              (focusin)='state.value = calculatedValues.months'
              [(ngModel)]='calculatedValues.months'
              [allowNegative]='false'
              [id]='state.id'
              [inputMaxLength]='4'
              class='left-input'
              name='feeAmountDuration'
              numeric
              placeholder='0'
              type='number'
            >
          </span>
      </ng-container>
    </div>

    <!-- Seller Concessions Input -->
    <div class='m-row'>
      <ng-container *ngLet='getId("seller-concessions") as id'>
        <label [for]='id'>Seller Concessions</label>

        <currency-input
          *ngLet='{value: 0} as state'
          (blur)='onFieldChange(state.value, calculatedValues.sellerConcessions)'
          (focusin)='state.value = calculatedValues.sellerConcessions'
          [(ngModel)]='calculatedValues.sellerConcessions'
          [id]='id'
          class='col-span-2'
          name='sellerConcessions'
          placeholder='$0.00'
        ></currency-input>
      </ng-container>
    </div>

    <!-- Impound Switch -->
    <div *ngIf='canBeImpound' class='m-row'>
      <ng-container *ngLet='getId("impound") as id'>
        <label [for]='id'>Impound</label>

        <span class='form-check form-switch col-span-2'>
          <input
            (ngModelChange)='effectiveFee.fee.isImpound = $event; onImpoundChange()'
            [id]='id'
            [ngModel]='effectiveFee.fee.isImpound'
            class='form-check-input'
            name='impound'
            type='checkbox'
          >
        </span>
      </ng-container>
    </div>

    <div class='m-divider'></div>

    <!-- Borrower Paid -->
    <div class='m-row' *ngLet='getId("borrower-paid") as id'>
      <label [for]='id'>Borrower Paid</label>

      <ng-container *ngIf='canEditBorrowerPaid; else nonEditable'>
        <currency-input
          (blur)='onFieldChange(state.value, effectiveFee.fee.borrowerFeeDollar)'
          (focusin)='state.value = effectiveFee.fee.borrowerFeeDollar'
          *ngLet='{value: 0} as state'
          [(ngModel)]='effectiveFee.fee.borrowerFeeDollar'
          [id]='id'
          class='col-span-2'
          name='borrowerPaid'
          placeholder='$0.00'
        ></currency-input>
      </ng-container>

      <ng-template #nonEditable>
        <span [id]='id' class='col-span-2'>
          {{ effectiveFee.fee.borrowerFeeDollar || 0 | currency }}
        </span>
      </ng-template>
    </div>

    <!-- Total Fee -->
    <div class='m-row'>
      <label>Total Fee</label>

      <span class='col-span-2'>
        {{ calculatedValues.totalFee | currency }}
      </span>
    </div>
  </div>

  <div class='m-footer'>
    <ng-container *ngIf='editable; else nonEditable'>
      <!-- Cancel button -->
      <button
        (click)='onCancel()'
        aria-label='Cancel'
        class='btn btn-outline-light'
        title='Cancel'
        type='button'
      >
        Cancel
      </button>

      <!-- Save button -->
      <button
        (click)='onSave()'
        aria-label='Save'
        class='btn btn-primary'
        title='Save'
        type='button'
      >
        Save
      </button>
    </ng-container>

    <ng-template #nonEditable>
      <!-- Close button -->
      <button
        (click)='onCancel()'
        aria-label='Close'
        class='btn btn-primary'
        title='Close'
        type='button'
      >
        Close
      </button>
    </ng-template>
  </div>
</form>
