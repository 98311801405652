import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';

@Component({
  selector: 'edit-task-dialog',
  templateUrl: './edit-task-dialog.component.html',
  styleUrls: ['./edit-task-dialog.component.scss'],
})
export class EditTaskDialogComponent {
  
  @Input()
  task: LoanDocDashboardTask;

  @Input()
  filterId: number;

  @Input()
  readonly: boolean = false;

  @Input()
  borrowers?: BorrowerDto[] = [];

  @Output()
  reloadTasks: EventEmitter<never> = new EventEmitter<never>();

  openFileUpload: boolean = false;

  startDocumentGeneration: boolean = false;

  startESign: boolean = false;

  isRefreshed: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  onReloadTasks = () => {
    this.reloadTasks.emit();
  }

  onCancelClicked = (isRefreshed: boolean) => {
    this.isRefreshed = isRefreshed;
    this.activeModal.dismiss(isRefreshed);
  }

  onSaveClicked = () => {
    this.activeModal.close('save');
  }

  onRefreshClicked = () => {
    this.isRefreshed = true;
  }
}
