import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { cloneDeep, isArray } from 'lodash';
import { User } from 'src/app/models/user/user.model';
import { LeadRouteGroup } from 'src/app/modules/leads/models/lead-route-group.model';
import { LeadRoutingService } from 'src/app/modules/admin/lead-config/services/lead-routing.service';
import { LeadRouteType } from 'src/app/modules/leads/models/lead-route-history.model';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { TelephonyService } from 'src/app/modules/voice-sms-settings/modules/telephony-service.model';
 import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'incoming-actions',
  templateUrl: 'incoming-actions.component.html'
})

export class IncomingActionsComponent implements OnInit {

  @ViewChild('telephonyForm') telephonyForm: NgForm | undefined;

  @Input()
  users: User[] = [];

  @Input()
  set telephony(value: TelephonyService) {
    if (value) {
      this._telephony = cloneDeep(value);
      if (!this._telephony.forwardCallToUserId) {
        this._telephony.forwardCallToUserId = null;
      }
      if (!this._telephony.voiceLeadRouteGroupId) {
        this._telephony.voiceLeadRouteGroupId = null;
      }
      if (!this._telephony.incomingSmsLeadRouteGroupId) {
        this._telephony.incomingSmsLeadRouteGroupId = null;
      }
      this.isForwardCallToUser = !!this._telephony.forwardCallToUserId || !!this._telephony.forwardCallToPhoneNumber;
      this.isCallForward = this._telephony.forwardCallToPhoneNumber && !this._telephony.forwardCallToUserId;
      this.callForwardingPhoneNumber = this._telephony.forwardCallToPhoneNumber
    }
  }

  get telephony(): TelephonyService {
    return this._telephony;
  }

  filtersMultiSelectSettings: IDropdownSettings = {
    idField: 'userCompanyGuid',
    textField: 'formattedName',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };

  isForwardCallToUser: boolean;
  voiceLeadRouteGroupId: number;
  smsLeadRouteGroupId: number;
  isCallForward: boolean;
  callForwardingPhoneNumber: string;
  userPhoneType: "customPhone" | "workPhone" | "cellPhone" | "homePhone" = "workPhone";
  uploadedFile: File = null;
  voicemailAudio: File = null;

  smsRoutes: LeadRouteGroup[] = [];
  voiceRoutes: LeadRouteGroup[] = [];
  voicemailNotifyUsers: string[] = [];

  private _telephony: TelephonyService;

  constructor(private readonly _leadRoutingservice: LeadRoutingService,
    private readonly _notifsService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.getLeadRoutes();
    this.users = this.users.map(u => ({ formattedName: `${u.lastName}, ${u.firstName}`, ...u }));
  }

  public getTelephonyItemToSave = (): TelephonyItem => {
    return {
      telephony: this._telephony,
      incomingCallAudio: isArray(this.uploadedFile) ? this.uploadedFile[0] : this.uploadedFile,
      voicemailAudio: isArray(this.voicemailAudio) ? this.voicemailAudio[0] : this.voicemailAudio,
      callForwardingPhoneNumber: this.callForwardingPhoneNumber == this._telephony.forwardCallToPhoneNumber ? null : this._telephony.forwardCallToPhoneNumber
    }
  }

  public isFormValid = (): boolean => {
    this.telephonyForm?.form?.markAllAsTouched();
    return !!this.telephonyForm?.form?.valid;
  }

  //for testing file object's field
  test = (data: File) => {
    console.log(data);
  }

  onUserPhoneTypeChanged = () => {
    this.autoPopulateUserPhoneNumber();
  }

  onForwardToUserChange = () => {
    if (this.isForwardCallToUser) {
      this._telephony.voiceLeadRouteGroupId = null;
      this._telephony.voicemailEnabled = false;
      this._telephony.voicemailMessage = null;
      this._telephony.voicemailAudoFileId = null;
      this._telephony.voicemailNotifyUserIds = [];
      this._telephony.routingTimeInSecondsBeforeVoicemail = null;
      this.autoPopulateUserPhoneNumber();
    } else {
      this._telephony.forwardCallToUserId = null;
      this._telephony.forwardCallToPhoneNumber = null;
    }
  }

  onIsForwardCallToUserChanged = () => {
    if (this.isForwardCallToUser) {
      this._telephony.voiceLeadRouteGroupId = null;
      this._telephony.voicemailEnabled = false;
      this._telephony.voicemailMessage = null;
      this._telephony.voicemailAudoFileId = null;
      this._telephony.voicemailNotifyUserIds = [];
      this._telephony.routingTimeInSecondsBeforeVoicemail = null;
    } else {
      this._telephony.forwardCallToUserId = null;
      this._telephony.forwardCallToPhoneNumber = null;
    }
  }

  onCallForwardChange = () => {
    if (this._telephony.forwardCallToPhoneNumber == '') {
      this.isCallForward = false;
    } else {
      this.isCallForward = true;
    }
  }

  private getLeadRoutes = () => {
    this._leadRoutingservice.getLeadRoutes(LeadRouteType.Route, false, false)
      .subscribe({
        next: (res) => {
          this.smsRoutes = res.filter(route => route.contactListId == 8);
        },
        error: (err) => {
          this._notifsService.showError(err.message || "Couldn't load lead routing.", 'Error!');
        }
      }).add(() => this._spinnerService.hide());
      this._leadRoutingservice.getLeadRoutes(LeadRouteType.InboundCallRouting, false, false)
      .subscribe({
        next: (res) => {
          this.voiceRoutes = res.filter(route => route.type === LeadRouteType.InboundCallRouting);
        },
        error: (err) => {
          this._notifsService.showError(err.message || "Couldn't load lead routing.", 'Error!');
        }
      }).add(() => this._spinnerService.hide());
  }

  private autoPopulateUserPhoneNumber = () => {
    if (!this._telephony.forwardCallToUserId) return;
    if (!this.userPhoneType) return;

    if (this.userPhoneType === "customPhone") {
      this._telephony.forwardCallToPhoneNumber = null;
    } else {
      const user = this.users.find(u => u.userCompanyGuid === this.telephony.forwardCallToUserId);
      if (user) {
        this._telephony.forwardCallToPhoneNumber = user[`${this.userPhoneType}`] || null;
      } else {
        this._telephony.forwardCallToPhoneNumber = null;
      }
    }
  }
}

export class TelephonyItem {
  telephony: TelephonyService;
  incomingCallAudio: File;
  voicemailAudio: File;
  callForwardingPhoneNumber: string;
}
