import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ReggoraService } from '../../services/reggora.service';

@Component({
  selector: 'reggora',
  templateUrl: './reggora.component.html',
  styleUrls: ['./reggora.component.scss']
})
export class ReggoraComponent implements OnInit {

  @Input()
  applicationId: number;

  @Input()
  isTpoUser: boolean = false;

  isLoading: boolean;

  iframeURL: string;
  safeUrl: SafeUrl;

  errors: string = null;

  height: string;

  constructor(
    private readonly _domSanitizer: DomSanitizer,
    private readonly _reggoraService: ReggoraService
  ) {
  }

  ngOnInit(): void {

    this.getIframeURL();

    this.adjustHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustHeight();
  }

  getIframeURL() {
    this.isLoading = true;
    this.errors = null;

    this._reggoraService.getReggoraIframeURL(this.applicationId)
      .subscribe({
        next: (url) => {
          this.iframeURL = url;
          this.safeUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(url);
        },
        error: (err) => {
          let message;
          try {
            if (err?.message) {
              message = err.message;
            } else if (err?.error) {
              let error = JSON.parse(err.error);
              if (error.message) {
                message = error.message;
              } else {
                message = JSON.parse(error.message).error;
              }
            } else {
              message = 'Unable to get URL';
            }
          } catch (e) {
            message = 'Unable to get URL';
          }
          this.errors = message;
        }
      }).add(() => this.isLoading = false);
  }

  private adjustHeight = () => {
    const offset = this.isTpoUser ? 150 : 250;
    this.height = "calc(100vh - " + offset + "px)";
  }
}
