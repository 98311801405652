import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ContactsService } from '../../services/contacts.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { AdminService } from 'src/app/services/admin.service';
import { NewApplicationRequest } from '../../models/new-application-request.model';
import { Address, Borrower, Role } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { User } from 'src/app/models/user/user.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { ConfigurationApplication } from 'src/app/models/config/configuration-application.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { BranchService } from 'src/app/modules/admin/company/services/branch.service';

@Component({
  selector: 'add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss'],
})
export class AddApplicationComponent implements OnInit {
  @Input() id: number;

  @ViewChild('startNewAppForm')
  startNewAppForm: NgForm | undefined;

  data: NewApplicationRequest = new NewApplicationRequest();

  currentLoanStatusName: String = '';

  availableUsers: User[] = [];

  enabledchannels: EnumerationItem[] = [];

  _states: any[] = [];

  enabledCoBorrower: boolean = false;

  globalConfig: GlobalConfig;

  loading: boolean = false;

  isSaving: boolean = false;

  firstRole: Role;

  availableLoanTypes: LoanType[] = [];

  availableLoanPurpose: LoanPurpose[] = [];

  constructor(
    private _globalService: ApplicationContextService,
    private _service: ContactsService,
    private _adminService: AdminService,
    private route: ActivatedRoute,
    private _spinner: NgxSpinnerService,
    private _notify: NotificationService,
    public readonly activeModal: NgbActiveModal,
    private readonly _branchService: BranchService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (!this.id) {
        this.id = params.id; // --> Name must match wanted parameter
      }
    });
    this.data.application.primaryBorrowerId = this.id;
    this.data.application.loanPurposeId = undefined;
    this.loading = true;
    this._globalService.context.subscribe((result) => {
      this.globalConfig = result.globalConfig;
      this.data.primaryRoleUserId = result.currentlyLoggedInUser.userCompanyGuid;
      this.data.application.userId = result.currentlyLoggedInUser.userCompanyGuid;
      this.enabledchannels = result.globalConfig.enabledChannels;
      this.data.application.companyId = result.userPermissions.companyId;
      this.firstRole = this.globalConfig.firstRole;
      this.loading = false;
      this._states = Object.entries(result.globalConfig['states']);
      this.populateLoanOptions();
    });
  }

  fillLoanStatus = (loanPurposeId) => {
    if (!loanPurposeId) {
      this.currentLoanStatusName = '';
      return;
    }

    const topMostStatusByLoanPurposeAndChannel = _.chain(this.globalConfig.loanStatusAssociation)
      .filter(lsa => lsa.loanPurposeId == loanPurposeId &&
        (!this.data.application.channel ||
          (lsa.channel && lsa.channel == this.data.application.channel))
      )
      .head()
      .value();
    if (!topMostStatusByLoanPurposeAndChannel) {
      this.currentLoanStatusName = '';
      return;
    }

    this.data.application.loanStatusId = topMostStatusByLoanPurposeAndChannel.loanStatusId;

    const matchingStatus = this.globalConfig.loanStatus.find(ls => ls.loanStatusId == topMostStatusByLoanPurposeAndChannel.loanStatusId);
    this.currentLoanStatusName = matchingStatus ? matchingStatus.loanStatusName : '';

  }

  changeSelectedChannel = (selectedChannel) => {
    this.fillLoanStatus(undefined);
    this.firstRole = this.getFirstRoleByChannel(selectedChannel);
    if (selectedChannel == 'Wholesale') {
      this.availableUsers = [];
      this.data.application.companyId = null;
      this.resetPrimaryRoleUser();
      return;
    }
    this.availableUsers = _.chain(this.globalConfig.users)
      .filter(user => user.roleId == this.firstRole.roleId)
      .sortBy(user => [user.lastName, user.firstName])
      .value();
    this.data.application.channel = selectedChannel;
    this.resetPrimaryRoleUser();
  }

  loadExternalCompanyUsers = (externalCompanyId: number) => {
    if (externalCompanyId) {
      this.availableUsers = this.globalConfig.users.filter(x => x.externalCompanyId == externalCompanyId);
    }

    this._spinner.show();
    this._adminService.getCompanyUsers(externalCompanyId).subscribe((response) => {
      this.availableUsers = response;
      this.resetPrimaryRoleUser();
      this._spinner.hide();
    }, (error) => {
      this._spinner.hide();
    });
  }

  addCoBorr() {
    this.enabledCoBorrower = true;
  }

  removeCoBorr() {
    this.enabledCoBorrower = false;
  }

  useBorrowerAddress(isBorrower) {
    this._service.getBorrowerAddress(this.id).subscribe(res => {
      if (res) {
        if (!isBorrower) {
          this.data.coBorrower = { ...this.data.coBorrower, ...res };
        } else {
          this.data.application = { ...this.data.application, ...res };
        }
      }
    })
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, borrowerType: string) => {
    if (zipCode) {
      if (borrowerType == 'borrower') {
        this.data.application.mailingState = zipCode.state.toLowerCase();
        this.data.application.mailingCity = _.startCase(_.toLower(zipCode.city));
        this.data.application.mailingZip = zipCode.zipcode.toLowerCase();
      }
      if (borrowerType == 'coBorrower') {
        this.data.coBorrower.mailingState = zipCode.state.toLowerCase();
        this.data.coBorrower.mailingCity = _.startCase(_.toLower(zipCode.city));
        this.data.coBorrower.mailingZip = zipCode.zipcode.toLowerCase();
      }
    }
  }

  protected handleAddressChange(e: Partial<Address>, target: ConfigurationApplication | Borrower): void {
    target.mailingStreet = ''; // to reset the last populated address.

    setTimeout(() => {
      target.mailingStreet = e.address1;
      target.mailingCity = e.city;
      target.mailingState = e.state;
      target.mailingZip = e.zipCode;
    }, 200);
  }

  save = () => {
    this.startNewAppForm.form.markAllAsTouched();
    if (this.startNewAppForm.form.valid) {
      if (!this.enabledCoBorrower) {
        this.data.coBorrower = new Borrower();
      }
      this.isSaving = true;
      this._spinner.show();
      this._service.saveApplication(this.id, this.data).pipe(finalize(() => {
        this.isSaving = false;
      })).subscribe(
        (res) => {
          this._spinner.hide();
          this._notify.showSuccess(
            `Application saved successfuly.`,
            'Application'
          );
          this.activeModal.close(res);
        },
        (err) => {
          this._spinner.hide();
          this._notify.showError(
            `There is an error saving application ${err.message}`,
            'Application'
          );
        })
    }
  }

  private resetPrimaryRoleUser = () => {
    if (!this.availableUsers.some(u => u.userCompanyGuid == this.data.primaryRoleUserId)) {
      this.data.primaryRoleUserId = '';
    }
    this.populateLoanOptions();
  }

  private getFirstRoleByChannel = (channel) => {
    if (!channel) {
      return this.globalConfig.firstRole;
    }
    return this.globalConfig.channelRoles[channel.toLowerCase()][0];
  }

  private populateLoanOptions = () => {
    if ((this.globalConfig.enabledChannels.length > 0 && !this.data.application.channel) ||
      !this.data.primaryRoleUserId) {
      this.availableLoanTypes = [];
      this.availableLoanPurpose = [];
      this.data.application.loanPurposeId = null;
      this.data.application.loanTypeId = null;
      return;
    }
    if (this.globalConfig.enabledChannels.length === 0 || this.data.application.channel != 'Wholesale') {
      this.availableLoanTypes = this.globalConfig.loanType;
      this.availableLoanPurpose = this.globalConfig.loanPurpose;
      return;
    }

    this._spinner.show();
    const matchingUser = this.availableUsers.find(u => u.userCompanyGuid == this.data.primaryRoleUserId);
    if (matchingUser && matchingUser.branchId) {
      this._branchService.getExternalBranchLoanOptions(matchingUser.branchId).subscribe((res) => {
        const availableLoanTypeIds = (res && res.allowedLoanTypes) ? res.allowedLoanTypes.split(',') : [];
        this.availableLoanTypes = this.globalConfig.loanType.filter(l => availableLoanTypeIds.includes(l.loanTypeId.toString()));
        const availableLoanPurposeIds = (res && res.allowedLoanPurposes) ? res.allowedLoanPurposes.split(',') : [];
        this.availableLoanPurpose = this.globalConfig.loanPurpose.filter(l => availableLoanPurposeIds.includes(l.loanPurposeId.toString()));
        this.data.application.loanPurposeId = null;
        this.data.application.loanTypeId = null;
        this._spinner.hide();
      }, function () {
        this._spinner.hide();
      });
      return;
    }
    this.availableLoanTypes = [];
    this.availableLoanPurpose = [];
    this.data.application.loanPurposeId = null;
    this.data.application.loanTypeId = null;
    this._spinner.hide();
  }
}
