import {FeeCalculatedValues, LoanFee} from '../fees.model';

export module FeeModelUtils {
  export function createEmptyFeeCalculatedValues(): FeeCalculatedValues {
    return {
      isChargeUserEditable: false,
      calculatedFeeType: undefined,
      totalFee: 0,
      totalFeePercent: 0,
      borrowerTotal: 0,
      lenderTotal: 0,
      sellerTotal: 0,
      thirdPartyTotal: 0,
      borrowerPaidAtClosing: 0,
      lenderPaidAtClosing: 0,
      sellerPaidAtClosing: 0,
      thirdPartyPaidAtClosing: 0,
      totalPaidAtClosing: 0,
      prorationAmount: 0,
      prorationType: undefined,
      prorationStartDate: '',
      prorationEndDate: '',
      percentOfAmount: 0,
      sellerConcessions: 0,
      months: 0,
      monthlyFee: 0,
      oddDays: 0,
      estimatedClosingDate: '',
      paidThroughDate: '',
      calculationMethod: undefined,
    };
  }

  export function createEmptyLoanFee(): LoanFee {
    return {
      loanFeeId: 0,
      applicationId: 0,
      pricingScenarioId: 0,
      externalContactId: 0,
      hudNumber: '',
      name: '',
      feeSection: undefined,
      feeType: undefined,
      feeTypeOtherDescription: '',
      feeSource: undefined,
      feeSourceIntegration: undefined,
      latestFeeValueSource: undefined,
      sumInHudNumber: 0,
      isSummaryFee: false,
      isImpound: false,
      isChargeUserEditable: false,
      effectiveDate: '',
      escrowDisbursements: [],
      calculatedFeeType: undefined,
      calculatedValues: createEmptyFeeCalculatedValues(),
      allowSplit: false,
      canSellerConcessionsApply: false,
      canSetPaidTo: false,
      isAprFee: false,
      feePercentOf: undefined,
      borrowerFeeDollar: 0,
      borrowerFeePercent: 0,
      sellerFeeDollar: 0,
      sellerFeePercent: 0,
      brokerFeeDollar: 0,
      lenderFeeDollar: 0,
      lenderFeePercent: 0,
      thirdPartyFeeDollar: 0,
      thirdPartyFeePercent: 0,
      isChargeOverridden: false,
      financedBorrowerAmount: 0,
      paidOutsideClosingBorrowerAmount: 0,
      paidOutsideClosingSellerAmount: 0,
      paidOutsideClosingBrokerAmount: 0,
      paidOutsideClosingLenderAmount: 0,
      paidOutsideClosingThirdPartyAmount: 0,
      paidToType: undefined,
      paidTo: '',
      borrowerSelectedServiceProvider: false,
      allowShopOrCannotShopSelection: false,
      canShopLoanEstimate: false,
      didShopClosingDisclosure: false,
      keepOriginalFeeSectionWhenShopping: false,
      note: '',
      showOnHUD: false,
      includeInRegulationZPointsAndFeesCalculations: false,
      includeInJurisdictionPointsAndFeesCalculations: false,
      originalTotalFee: 0,
      sourceFeeTemplateId: 0,
      isOptionalCost: false,
      isLoanCredit: false,
      isReimbursable: false,
      escrowPaymentFrequency: undefined,
      escrowPremiumPaymentType: undefined,
      escrowInsurancePolicyId: '',
      isBorrowerChosenProvider: false,
      isIncludedInWireAmount: false,
      hasRequiredServiceProvider: false,
      companyId: 0,
      insertedBy: '',
      updatedBy: '',
      dateInserted: '',
      dateUpdated: '',
      refundable: false,
      refundableAmount: 0,
      refundableConditions: undefined
    };
  }
}
