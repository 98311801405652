<p-table #documentsTable
  [value]="documents"
  [resizableColumns]="true"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption" *ngIf="showCaption">
    <div class="p-d-flex" style="align-items:center">
      <h5 style="margin: 0">Documents ({{documents.length}})</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="'title'" pResizableColumn>Title
        <p-sortIcon [field]="'title'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'hasPdfFormFields'" pResizableColumn>Has Pdf Form Fields
        <p-sortIcon [field]="'hasPdfFormFields'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'isWetSign'" pResizableColumn>Wet Sign
        <p-sortIcon [field]="'isWetSign'"></p-sortIcon>
      </th>
      <th pResizableColumn>View Document
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-doc>
    <tr>
      <td>{{doc.title}}</td>
      <td>{{doc.hasPdfFormFields == true || doc.hasPdfFormFields == 'true' ? 'Yes' : 'No' }}</td>
      <td>{{doc.isWetSign == true || doc.isWetSign == 'true' ? 'Yes' : 'No' }}</td>
      <td><a (click)="onViewDocumentClicked(doc.documentId)">View Document</a></td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center"> {{emptyMessage}} </td>
    </tr>
  </ng-template>
</p-table>
