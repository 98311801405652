<div class="left-sidenav tpo-report-card" id="{{uniqueId}}">
  <div class="menu-content h-100" data-simplebar>
    <ul class="metismenu left-sidenav-menu sticky" id="metismenu">
      <li (click)="onListItemClicked($event, 'loan-summary')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'loan-summary'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/loan-summary']">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Loan Summary</span>
        </a>
      </li>
      <li (click)="onListItemClicked($event, 'urla')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'urla'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/urla']">
          <span [ngClass]="{'readonlyIcon': isUrlaReadOnly}">
            <ng-container *ngIf="!isUrlaReadOnly || stepStatuses.submission != 'success'">
              <ng-container
                *ngTemplateOutlet="statusIndicator; context: {status: !isUrlaReadOnly ? stepStatuses.urla : 'success'}">
              </ng-container>
            </ng-container>
            {{ showStepLabels ? 'Step ' + stepOrder['urla'] + ': ' : '' }}URLA 2020</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>
        <ul class="nav-second-level" aria-expanded="false" style="padding-left: 19px!important;"
          [ngClass]="{'mm-show': tab === 'urla'}">
          <urla-menu *ngIf="tab === 'urla'" #urlaMenu (menuItemClicked)='onMenuItemClicked($event)'></urla-menu>
        </ul>
      </li>
      <li [hidden]="!featurePermissions.isServicesEnabled || channel == 'Correspondent' || isCompanyPulseProcessing" (click)="setExpandedItems()"
        [ngClass]="{'mm-active': tab === 'credit-reports' || tab === 'digital-emp-income-verification' || tab === 'digital-asset-verification' || tab === 'aus'}">
        <a href="javascript: void(0);">
          <span>
            <ng-container *ngTemplateOutlet="statusIndicator; context: {status: stepStatuses.services}"></ng-container>
            {{ showStepLabels ? 'Step ' + stepOrder['services'] + ': ' : '' }}Services</span>
          <span class="menu-arrow">
            <i class="mdi mdi-chevron-right"></i>
          </span>
        </a>
        <ul class="nav-second-level" aria-expanded="false" style="padding-left: 19px!important;" [ngClass]="{'mm-show':
            tab === 'credit-reports' ||
            tab === 'digital-emp-income-verification' ||
            tab === 'digital-asset-verification' ||
            tab === 'aus'}" [ngStyle]="(
              tab === 'credit-reports' ||
              tab === 'digital-emp-income-verification' ||
              tab === 'digital-asset-verification' ||
              tab === 'aus') && {'height': 'none'}">
          <menu-item [hidden]="!featurePermissions.isCreditAllowed" [label]="'Credit'"
            (menuItemClicked)="onMenuItemClicked($event)" [link]="'/tpo/app-details/' + loanId + '/credit-reports'"
            [status]="'None'" [id]="'credit'" [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isVoiVoeAllowed" [label]="'VOI/VOE'"
            (menuItemClicked)="onMenuItemClicked($event)"
            [link]="'/tpo/app-details/' + loanId + '/digital-emp-income-verification'" [status]="'None'"
            [id]="'voi-voe'" [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isVoaAllowed" [label]="'VOA'"
            (menuItemClicked)="onMenuItemClicked($event)"
            [link]="'/tpo/app-details/' + loanId + '/digital-asset-verification'" [status]="'None'" [id]="'voa'"
            [groupId]="'services'">
          </menu-item>
          <menu-item [hidden]="!featurePermissions.isAusAllowed" [label]="'AUS'"
            (menuItemClicked)="onMenuItemClicked($event)" [link]="'/tpo/app-details/' + loanId + '/aus'"
            [status]="'None'" [id]="'aus'" [groupId]="'services'">
          </menu-item>
        </ul>
      </li>

      <li [hidden]="!isManualPricingEntryAvailable" (click)="onListItemClicked($event, 'pricing')"
        class="tpo-parent-menu-item" [ngClass]="{'mm-active': tab === 'pricing'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/edit-pricing']">
          <ng-container *ngTemplateOutlet="statusIndicator; context: {status: stepStatuses.pricing}"></ng-container>
          <span>{{ showStepLabels ? 'Step ' + stepOrder['pricing'] + ': ' : '' }}Pricing</span></a>
      </li>

      <li class="tpo-parent-menu-item" (click)="onListItemClicked($event, 'pricing')"
        [ngClass]="{'mm-active': tab === 'pricing'}" [hidden]="!featurePermissions.pricingEnabled || isManualPricingEntryAvailable">

        <a [routerLink]="['/tpo/app-details/' + loanId + '/pricing']">
          <span>
            <ng-container *ngTemplateOutlet="statusIndicator; context: {status: stepStatuses.pricing}"></ng-container>
            {{ showStepLabels ? 'Step ' + stepOrder['pricing'] + ': ' : '' }}Pricing</span>
          <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
        </a>
        <ul class="nav-second-level" [attr.aria-expanded]="false"
          [ngStyle]="(tab === 'pricing' || tab === 'pricing-history' || tab === 'application-pricing-scenarios' || tab === 'amortization') && {'height': 'none'}"
          [ngClass]="{'mm-show': tab === 'pricing' || tab === 'pricing-history' || tab === 'application-pricing-scenarios' || tab === 'amortization'}">
          <li class="nav-item" [ngClass]="{'mm-active': tab === 'pricing-history'}" *ngIf="!pricingVendorsAreOnlyIframes">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/pricing-history']">
              <i class="status-indicator fas fa-question-circle text-warning"></i>
              <span> Pricing History </span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'mm-active': tab === 'application-pricing-scenarios'}" *ngIf="!pricingVendorsAreOnlyIframes">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/application-pricing-scenarios']">
              <i class="status-indicator fas fa-question-circle text-warning"></i>
              <span> Pricing Scenarios </span>
            </a>
          </li>
          <li *ngIf="featurePermissions.isAmortizationTableMenuItemVisible" class="nav-item"
            [ngClass]="{'mm-active': tab === 'amortization'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/amortization']">
              <i class="status-indicator fas fa-question-circle text-warning"></i>
              <span> Amortization Table </span>
            </a>
          </li>
        </ul>
      </li>

      <li [hidden]="!featurePermissions.isFeesEnabled || channel == 'Correspondent' || isCompanyPulseProcessing" (click)="onListItemClicked($event, 'fees')"
        class="tpo-parent-menu-item" [ngClass]="{'mm-active': tab === 'fees'}">

        <a [routerLink]="['/tpo/app-details/' + loanId + '/fees']">
          <span>
            <ng-container *ngTemplateOutlet="statusIndicator; context: {status: stepStatuses.fees}"></ng-container>
            {{ showStepLabels ? 'Step ' + stepOrder['fees'] + ': ' : '' }}Fees</span></a>
      </li>


      <li [hidden]="!featurePermissions.isDisclosureGenerationAllowed || isBrokerDisclosedAndSubmitted || channel == 'Correspondent'"
        (click)="onListItemClicked($event, 'disclosures')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'disclosures' && !disclosuresPending}">

        <a [routerLink]="['/tpo/app-details/' + loanId + '/disclosures']" [ngClass]="{'disabled-link': disclosuresPending}">
          <span>
            <ng-container
              *ngTemplateOutlet="statusIndicator; context: {status: stepStatuses.disclosures, tab: 'disclosures'}"></ng-container>
            {{ showStepLabels ? 'Step ' + stepOrder['disclosures'] + ': ' : '' }}Disclosures</span></a>
      </li>

      <li *ngIf="stepStatuses.submission != 'success'" class="tpo-parent-menu-item"
        (click)="onListItemClicked($event, 'submission')" [ngClass]="{'mm-active': tab === 'submission'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/submission']">
          <span [ngClass]="{'menu-span-padding': false}">
            <ng-container
              *ngTemplateOutlet="statusIndicator; context: {status: stepStatuses.submission}"></ng-container>
            {{ showStepLabels ? 'Step ' + stepOrder['submission'] + ': ' : '' }}Submission</span>
        </a>
      </li>

      <li class="tpo-parent-menu-item" (click)="onListItemClicked($event, 'conditions')"
        [ngClass]="{'mm-active': tab === 'conditions'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/conditions']">
          <span [ngClass]="{'menu-span-padding': false}">Conditions</span>
        </a>
      </li>

      <li [hidden]="!featurePermissions.isAppraisalOrderAllowed || (isPRMG && ['Correspondent','NonDelegatedCorrespondent'].includes(applicationContext?.application?.channel))" (click)="onListItemClicked($event, 'appraisal')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'appraisal'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/appraisal']">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Appraisal</span></a>
      </li>

      <li *ngIf="isPRMG && stepStatuses.submission == 'success'" (click)="onListItemClicked($event, 'cdRequest')" class="tpo-parent-menu-item"
        [ngClass]="{'mm-active': tab === 'appraisal'}">
        <a [routerLink]="['/tpo/app-details/' + loanId + '/cd-request']">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>CD Request</span></a>
      </li>

      <li class="tpo-parent-menu-item" (click)="setExpandedItems()"
        [ngClass]="{'mm-active': tab === 'change-of-circumstance' || tab === 'escalation-history' || tab === 'loan-docs' || tab === 'internal-contacts' || tab === 'key-dates' || tab === 'loan-activity'}">
        <a href="javascript: void(0);">
          <i class="status-indicator fas fa-question-circle text-warning"></i>
          <span>Others</span>
          <span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
        </a>
        <ul class="nav-second-level" style="list-style: none;" aria-expanded="false"
          [ngClass]="{'mm-show': tab === 'change-of-circumstance' || tab === 'escalation-history' || tab === 'loan-docs' || tab === 'internal-contacts' || tab === 'key-dates' || tab === 'loan-activity'}">
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" *ngIf="!(isPRMG && ['Correspondent'].includes(applicationContext?.application?.channel))"
            [ngClass]="{'mm-active': tab === 'change-of-circumstance'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/change-of-circumstance']">
              Change of Circumstance</a>
          </li>
          <li *ngIf="!isPRMG" class="nav-item" (click)="onLinkSubMenuItemClicked()"
            [ngClass]="{'mm-active': tab === 'escalation-history'}"><a class="nav-link"
              [routerLink]="['/tpo/app-details/' + loanId + '/escalation-history']">Escalation
              History</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" [ngClass]="{'mm-active': tab === 'loan-docs'}"><a
              class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/loan-docs']">Loan
              Docs</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()"
            [ngClass]="{'mm-active': tab === 'internal-contacts'}"><a class="nav-link"
              [routerLink]="['/tpo/app-details/' + loanId + '/internal-contacts']">Internal
              Contacts</a></li>
          <li class="nav-item" (click)="onLinkSubMenuItemClicked()" [ngClass]="{'mm-active': tab === 'key-dates'}"><a
              class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/key-dates']">Key
              Dates</a></li>
          <li *ngIf="!isPRMG" class="nav-item" (click)="onLinkSubMenuItemClicked()" [ngClass]="{'mm-active': tab === 'loan-activity'}">
            <a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/loan-activity']">Loan
              Activity</a>
          </li>
          <!-- <li class="nav-item"><a class="nav-link" href="javascript: void(0);"><i
                class="ti-control-record"></i>Email/SMS History</a></li> -->
          <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/tpo/app-details/' + loanId + '/prequal-detail']"><i class="ti-control-record"></i>Prequal
              Letter</a></li> -->
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- end left-sidenav-->

<ng-template #statusIndicator let-status='status' let-tab="tab">
  <i class="status-indicator fas d-inline" [ngClass]="{
    'fa': ['Success','success'].includes(status),
    'fa-check-circle': ['Success','success','Requested','requested'].includes(status) || (disclosuresPending && tab == 'disclosures'),
    'text-success': ['Success','success'].includes(status),

    'text-info': ['Requested','requested'].includes(status),

    'fa-question-circle': ['Pending','pending'].includes(status),
    'text-warning': ['Pending','pending'].includes(status),

    'fa-times-circle': ['Error','error'].includes(status),
    'text-danger': ['Error','error'].includes(status),

    'text-muted': disclosuresPending && tab == 'disclosures',

    'fa-circle-notch': ['Loading','loading'].includes(status),
    'fa-spin': ['Loading','loading'].includes(status)
  }"></i>
</ng-template>
