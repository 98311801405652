import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'esignature-tasks-table',
  templateUrl: 'esignature-tasks-table.component.html'
})

export class EsignatureTasksTableComponent implements OnInit {

  @Input()
  tasks: LoanDocTask[] = [];

  @Output()
  reloadTasks: EventEmitter<any> = new EventEmitter<any>();

  isLoadingTask: boolean = false;

  constructor(
    private readonly _taskService: TaskService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
  ) { }

  ngOnInit() { }

  onViewTaskClicked = (task: LoanDocTask) => {
    this.isLoadingTask = true;
    this._taskService.getTaskDashboardViewById(task.loanDocTaskId)
    .subscribe({
      next: (response) => {
        this.isLoadingTask = false;
        let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
        modalRef.componentInstance.task = response;
        modalRef.componentInstance.startDocumentGeneration = false;
        modalRef.result.then((result) => {
          if (result) {
            this.reloadTasks.emit();
          }
        }, err => { });
      },
      error: (error) => {
        this.isLoadingTask = false;
        this._notificationService.showError(error?.message || error?.error?.message || 'View task failed', "Error!");
      }
    });
  }
}
