import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ErrorMessage} from 'src/app/shared/models/error.model';
import {ApplicationContextBoundComponent} from '../../../../shared/components';

@Component({
    selector: 'support-tools',
    templateUrl: './support-tools.component.html',
    styleUrls: ['./support-tools.component.scss']
})
export class SupportToolsComponent extends ApplicationContextBoundComponent implements OnInit {
    @ViewChild('changePasswordForm') changePasswordForm: NgForm | undefined;
    @Input() companyId: number;
    changePasswordRequest;
    submitting: boolean;

    constructor(
        private readonly injector: Injector,
        private readonly _authService: AuthService,
        private readonly _notifyService: NotificationService) {
        super(injector);
    }

    ngOnInit(): void {
        this.intializeRquest();
    }

    intializeRquest() {
        this.changePasswordRequest = {
            borrowerEmail: '',
            newPassword: '',
            confirmPassword: ''
        };
        if (this.changePasswordForm && this.changePasswordForm.form) {
            this.changePasswordForm.form.markAsUntouched();
        }
    }

    onChangePasswordClicked() {
        this.changePasswordForm.form.markAllAsTouched();
        if (!this.changePasswordForm.form.valid) {
            return;
        }
        this.submitting = true;
        this._authService.changeBorrowerPassword({
            email: this.changePasswordRequest.borrowerEmail,
            newPassword: this.changePasswordRequest.newPassword,
            companyId: this.applicationContext.userPermissions.companyId
        })
            .pipe(finalize(() => this.submitting = false))
            .subscribe({
                next: (response) => {
                    this._notifyService.showSuccess('Borrower Password changed successfully.',
                        'Password Changed');
                    this.intializeRquest();
                }, error: (err) => {
                    this._notifyService.showError(err.error || 'Unable to change Borrower Password.',
                        'Password Change');
                }
            });
    }
}
