import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HousingExpense } from 'src/app/models';
import * as _ from 'lodash';

@Component({
  selector: 'hoa-dues-condo-fees-breakdown-dialog',
  templateUrl: 'hoa-dues-condo-fees-breakdown-dialog.component.html',
  styleUrls: ['../breakdown.scss']
})

export class HoaDuesCondoFeesBreakdownDialogComponent implements OnInit {

  @Input()
  set proposedHousingExpenses(expenses: HousingExpense) {
    this._proposedHousingExpenses = _.cloneDeep(expenses);
    this.isBrokenDown = (expenses.condominiumAssociationDues && Number(expenses.condominiumAssociationDues) > 0) ||
      (expenses.homeownersAssociationDues != null && Number(expenses.homeownersAssociationDues) > 0 && (expenses.homeownersAssociationDues != expenses.homeownersAssociationDuesAndCondominiumFees)) ||
      (expenses.cooperativeAssociationDues != null && Number(expenses.cooperativeAssociationDues) > 0);
  }

  get proposedHousingExpenses(): HousingExpense {
    return this._proposedHousingExpenses;
  }

  isBrokenDown: boolean = false;

  realEstateTax: number;

  private _proposedHousingExpenses: HousingExpense;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }

  onOkClicked = () => {
    this.activeModal.close(this._proposedHousingExpenses);
  }

  onCancelClicked = () => {
    this.activeModal.close();
  }

  onBreakdownItemChanged = () => {
    const sum: number =
      (this._proposedHousingExpenses.condominiumAssociationDues != null ? Number(this._proposedHousingExpenses.condominiumAssociationDues) : 0) +
      (this._proposedHousingExpenses.cooperativeAssociationDues != null ? Number(this._proposedHousingExpenses.cooperativeAssociationDues) : 0) +
      (this._proposedHousingExpenses.homeownersAssociationDues != null ? Number(this._proposedHousingExpenses.homeownersAssociationDues) : 0);
    this._proposedHousingExpenses.homeownersAssociationDuesAndCondominiumFees = sum;
  }

  onIsBrokenDownChanged = () => {
    this._proposedHousingExpenses.condominiumAssociationDues = 0;
    this._proposedHousingExpenses.cooperativeAssociationDues = 0;
    this._proposedHousingExpenses.homeownersAssociationDues = 0;
  }

  onHomeownersAssociationDuesAndCondominiumFeesChanged = () => {
    if (!this.isBrokenDown) {
      this._proposedHousingExpenses.homeownersAssociationDues = this._proposedHousingExpenses.homeownersAssociationDuesAndCondominiumFees;
    }
  }
}
