import { Component, Injector, Input, OnInit } from "@angular/core";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { LeadCampaign } from "src/app/models/config/global-config.model";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { LeadList } from "../../models/lead-list.model";
import { LeadMilestonesReport, LeadMilestonesReportData, LeadPerformanceDetailReport, LeadPerformanceReport, LeadReportFilterData } from "../../models/lead-reports.model";
import { LeadsService } from "../../services/leads.service";

@Component({
  selector: 'lead-milestones',
  templateUrl: './lead-milestones.component.html',
  styleUrls: ['./lead-milestones.component.scss']
})
export class LeadMilestonesComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  set milestonesReport(milestonesReport: LeadMilestonesReportData) {
    this._milestonesReport = milestonesReport;
  }

  get milestonesReport(): LeadMilestonesReportData { return this._milestonesReport; }

  private _milestonesReport: LeadMilestonesReportData;

  constructor(
    private readonly _leadService: LeadsService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {

  }

  formatNumber = (value: number, fractionDigit: number): number => {
    return value ? Number(value.toFixed(fractionDigit).replace("/\.0{" + fractionDigit + ",}$/", '')) : 0;
  }

}

