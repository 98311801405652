import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { isNullOrUndefined } from 'src/utils/isNullOrUndefined';
import { IntegrationOrderTrackingEventTypeEnum, TitleOrderTrackingHistory } from '../models/title-order-tracking-history';
import { TitleOrder } from '../models/title-order.model';

interface OrderChanges {
  key: string,
  keyName: string,
  newValue: any,
  oldValue: any,
  dataType?: 'number' | 'date' | 'currency' | 'string',
  changeType: 'updated' | 'deleted' | 'created',
  isArray: boolean,
  arrayChanges: OrderChanges[]
}
@Component({
  selector: 'title-history-detail',
  templateUrl: './title-history-detail.component.html',
  styleUrls: ['./title-history-detail.component.scss']
})
export class TitleHistoryDetailComponent implements OnInit {

  @Input() history: TitleOrderTrackingHistory;
  @Input() allHistories: TitleOrderTrackingHistory[] = [];

  orderChanges: OrderChanges[] = [];

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.calculateOrderChanges();
  }

  isDocumentType = (eventType: IntegrationOrderTrackingEventTypeEnum): boolean => {
    return [
      IntegrationOrderTrackingEventTypeEnum.DocumentCreated,
      IntegrationOrderTrackingEventTypeEnum.DocumentUpdated,
      IntegrationOrderTrackingEventTypeEnum.OrderDeleted
    ].includes(eventType);
  }

  isCurrencyField = (fieldName: string): boolean => {
    return ["loanAmount"].includes(fieldName);
  }

  calculateOrderChanges = () => {

    if (this.isDocumentType(this.history.eventType)) {
      return;
    }

    let index = this.allHistories.findIndex(h => h.integrationOrderTrackingId == this.history.integrationOrderTrackingId);

    let allOtherTypeHistories = this.allHistories.filter((h, ind) => index < ind && !this.isDocumentType(h.eventType) && h.integrationOrderTrackingId != this.history.integrationOrderTrackingId);
    let mostRecentHistory = _.orderBy(allOtherTypeHistories, ["dateInserted"], ["desc"])[0];

    let baseOrder = (mostRecentHistory ? JSON.parse(mostRecentHistory.eventData) : {}) as TitleOrder;
    let order = (mostRecentHistory ? JSON.parse(this.history.eventData) : JSON.parse(this.history.eventData)) as TitleOrder;

    this.orderChanges = this.findOrderChanges(baseOrder, order);
  }

  findOrderChanges = (baseOrder: TitleOrder, order: TitleOrder): OrderChanges[] => {
    let orderChangesArr: OrderChanges[] = [];

    let acceptedFieldsDict = [
      { field: "orderStatus", name: "Order Status" },
      { field: "thirdPartyOrderStatus", name: "Third Party Order Status" },
      { field: "thirdPartyOrderStatusDescription", name: "Third Party Order Status Description" },
      { field: "loanPurpose", name: "Loan Purpose" },
      { field: "mortgageType", name: "Mortgage Type" },
      { field: "propertyUsage", name: "Property Usage" },
      { field: "loanAmount", name: "Loan Amount" },
      { field: "loanNumber", name: "Loan Number" },
      { field: "streetAddress", name: "Street Address" },
      { field: "unit", name: "Unit" },
      { field: "city", name: "City" },
      { field: "state", name: "State" },
      { field: "zipCode", name: "Zip Code" },
      { field: "borrowers", name: "Borrowers" },
      { field: "contacts", name: "Contacts" },
      { field: "documents", name: "Documents" },
    ];

    // backend is sending uppercase fields !!!
    Object.keys(baseOrder).forEach(key => {
      let val = baseOrder[key];
      let key2 = key.charAt(0).toLowerCase() + key.slice(1);

      delete baseOrder[key];
      baseOrder[key2]= val;
    });

    Object.keys(order).forEach(key => {
      let val = order[key];
      let key2 = key.charAt(0).toLowerCase() + key.slice(1);

      delete order[key];
      order[key2]= val;
    })

    //

    acceptedFieldsDict.map(r => r.field).forEach(field => {

      field = field.charAt(0).toLowerCase() + field.slice(1);

      let oldValue = baseOrder[field] as any[];
      let newValue = order[field] as any[];

      if (oldValue == undefined && newValue == undefined) {
        return;
      }

      let fieldName = acceptedFieldsDict.find(r => r.field == field).name;

      if (["borrowers", "contacts", "documents"].includes(field)) {

        let acceptedFieldsInnerDict = [
          { field: "documentType", name: "Document Type" },
          { field: "fileName", name: "File Name" },
          { field: "extension", name: "Extension" },
          { field: "contentUrl", name: "Content URL" },
          { field: "firstName", name: "First Name" },
          { field: "lastName", name: "Last Name" },
          { field: "email", name: "Email" },
          { field: "phone", name: "Phone" },
          { field: "isPrimary", name: "Is Primary" },
          { field: "middleName", name: "Middle Name" },
          { field: "homePhone", name: "Home Phone" },
          { field: "cellPhone", name: "Cell Phone" },
          { field: "workPhone", name: "Work Phone" }
        ];

        if (!_.isEqual(oldValue, newValue)) {

          let changes: OrderChanges[] = [];

          if(!isNullOrUndefined(oldValue)){
            for (let index = 0; index < oldValue.length; index++) {
              let item = oldValue[index];

              if (_.some(newValue, item)) {
                continue;
              }

              let matchedNewItem = newValue ? newValue[index] : null; // ignored swap items

              let fieldChanges: OrderChanges[] = [];

               for (let k of Object.keys(item)) {
                let innerFieldName = acceptedFieldsInnerDict.find(r => r.field == k).name;

                if (matchedNewItem && isNullOrUndefined(matchedNewItem[k]) && isNullOrUndefined(item[k])) {
                  continue;
                }

                if (matchedNewItem && !isNullOrUndefined(matchedNewItem[k]) && !isNullOrUndefined(item[k]) && matchedNewItem[k] == item[k]) {
                  continue;
                }

                let changeType;

                if (isNullOrUndefined(matchedNewItem) || (isNullOrUndefined(matchedNewItem[k]) && !isNullOrUndefined(item[k]))) {
                  changeType = "deleted";
                }
                else if (isNullOrUndefined(item[k]) && !isNullOrUndefined(matchedNewItem[k])) {
                  changeType = "created";
                }
                else {
                  changeType = "updated";
                }

                fieldChanges.push({
                  key: field + "_" + index + "_" + k,
                  keyName: innerFieldName,
                  oldValue: item[k],
                  newValue: matchedNewItem ? matchedNewItem[k] : null,
                  dataType: this.getDataType(item[k]),
                  changeType: changeType,
                  isArray: false,
                  arrayChanges: []
                })


              }

              let objectChangeType;

              let fieldCount = Object.keys(item).length;
              let createdCount = fieldChanges.filter(c => c.changeType == 'created').length;
              let deletedCount = fieldChanges.filter(c => c.changeType == 'deleted').length;
              if (fieldCount == createdCount) {
                objectChangeType = "created";
              }
              else if (fieldCount == deletedCount) {
                objectChangeType = "deleted";
              }
              else {
                objectChangeType = "updated";
              }

              if (fieldChanges.length > 0) {
                changes.push({
                  key: field + "_" + index,
                  keyName: fieldName + "_" + (index + 1),
                  oldValue: null,
                  newValue: null,
                  changeType: objectChangeType,
                  isArray: true,
                  arrayChanges: fieldChanges
                })
              }

            }
          }

          if(!isNullOrUndefined(newValue)){
            for (let index = 0; index < newValue.length; index++) {

              let item = newValue[index];

              if (_.some(oldValue, item)) {
                continue;
              }

              let matchedOldItem = oldValue ? oldValue[index] : null; // ignored swap items

              let fieldChanges: OrderChanges[] = [];

              for (let k of Object.keys(item)) {

                let innerFieldName = acceptedFieldsInnerDict.find(r => r.field == k).name;

                if (matchedOldItem && isNullOrUndefined(matchedOldItem[k]) && isNullOrUndefined(item[k])) {
                  continue;
                }

                if (matchedOldItem && !isNullOrUndefined(matchedOldItem[k]) && !isNullOrUndefined(item[k]) && matchedOldItem[k] == item[k]) {
                  continue;
                }

                let changeType;

                if (isNullOrUndefined(matchedOldItem) || (isNullOrUndefined(matchedOldItem[k]) && !isNullOrUndefined(item[k]))) {
                  changeType = "created";
                }
                else if (isNullOrUndefined(item[k]) && !isNullOrUndefined(matchedOldItem[k])) {
                  changeType = "deleted";
                }
                else {
                  changeType = "updated";
                  continue;
                }

                fieldChanges.push({
                  key: field + "_" + index + "_" + k,
                  keyName: innerFieldName,
                  oldValue: matchedOldItem ? matchedOldItem[k] : null,
                  newValue: item[k],
                  dataType: this.getDataType(item[k]),
                  changeType: changeType,
                  isArray: false,
                  arrayChanges: []
                })


              }

              let objectChangeType;

              let fieldCount = Object.keys(item).length;
              let createdCount = fieldChanges.filter(c => c.changeType == 'created').length;
              let deletedCount = fieldChanges.filter(c => c.changeType == 'deleted').length;
              if (fieldCount == createdCount) {
                objectChangeType = "created";
              }
              else if (fieldCount == deletedCount) {
                objectChangeType = "deleted";
              }
              else {
                objectChangeType = "updated";
              }

              if (fieldChanges.length > 0) {
                changes.push({
                  key: field + "_" + index,
                  keyName: fieldName + "_" + (index + 1),
                  oldValue: null,
                  newValue: null,
                  changeType: objectChangeType,
                  isArray: true,
                  arrayChanges: fieldChanges
                })
              }

            }

          }

          if (changes.length > 0) {
            orderChangesArr.push({
              key: field,
              keyName: fieldName,
              oldValue: null,
              newValue: null,
              changeType: "updated",
              isArray: true,
              arrayChanges: changes
            })
          }
        }

      }
      else {

        if (isNullOrUndefined(oldValue)) {

          orderChangesArr.push({
            key: field,
            keyName: fieldName,
            oldValue: oldValue,
            newValue: newValue,
            changeType: "created",
            dataType: this.isCurrencyField(field) ? 'currency' : this.getDataType(newValue),
            isArray: false,
            arrayChanges: []
          })
        }
        else if (isNullOrUndefined(newValue)) {
          orderChangesArr.push({
            key: field,
            keyName: fieldName,
            oldValue: oldValue,
            newValue: newValue,
            changeType: "deleted",
            dataType: this.isCurrencyField(field) ? 'currency' : this.getDataType(newValue),
            isArray: false,
            arrayChanges: []
          })
        }
        else {
          if (oldValue != newValue) {
            orderChangesArr.push({
              key: field,
              keyName: fieldName,
              oldValue: oldValue,
              newValue: newValue,
              changeType: "updated",
              dataType: this.isCurrencyField(field) ? 'currency' : this.getDataType(newValue),
              isArray: false,
              arrayChanges: []
            })
          }
        }


      }
    })



    return orderChangesArr;
  }

  getDataType = (value: any): 'number' | 'date' | 'string' => {
    if (this.isDate(value)) {
      return "date";
    }
    else if (typeof value == "number") {
      return "number";
    }
    else {
      return "string";
    }
  }

  isDate = (x) => {
    return Object.prototype.toString.call(x) === '[object Date]';
  }

}
