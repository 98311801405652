<form #upsertHolidayForm="ngForm" novalidate id="upsert-holiday-form" name="upsertHolidayForm">
    <div class="m-3">
        <div class="mb-3">
            <label class="form-label" for="holiday-title">Title</label>
            <input [disabled]="isEditingDisabled" type="text" class="form-control" name="holiday-title"
                [(ngModel)]="holidayEvent.title" #holidayInput="ngModel" autocomplete="off" required
                [ngClass]="{'is-invalid' : holidayInput && holidayInput.touched && holidayInput.invalid}">
        </div>
        <div class="mb-3">
            <label class="form-label" for="holiday-type">Type</label>
            <select [disabled]="isEditingDisabled" class="form-select standard" name="holiday-type"
                [ngClass]="{'is-invalid' : holidayTypeInput && holidayTypeInput.touched && holidayTypeInput.invalid}"
                [(ngModel)]="holidayEvent.type" #holidayTypeInput="ngModel" required>
                <option value="">-- Select One --</option>
                <option *ngFor="let hType of holidayTypes" value={{hType.value}}>
                    {{hType.name | splitCamelcase}}
                </option>
            </select>
        </div>
        <p *ngIf="holidayEvent.isGlobal" class="text-danger">Global Holidays cannot be edited, but you can disable it if
            you
            want.</p>
        <p *ngIf="holidayEvent.override" class="text-danger">This Holidays event is disabled. Please click Enable button
            to enable the event.</p>
        <div *ngIf="holidayEvent.id" class="float-start">
            <button [disabled]="saveInProgress || deleteInProgress" type="button" class="btn btn-danger me-2"
                (click)="onDelete()">
                <span *ngIf="deleteInProgress" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="!deleteInProgress">
                    {{ deleteButtonText }}</span>
            </button>

        </div>
        <div class="text-end">
            <button [disabled]="saveInProgress || deleteInProgress" type="button" class="btn btn-secondary me-2"
                (click)="onClose()">Close</button>
            <button [disabled]="saveInProgress || deleteInProgress || isEditingDisabled" type="button"
                class="btn btn-primary" (click)="onSave()">
                <span *ngIf="saveInProgress" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="!saveInProgress">Save</span>
            </button>
        </div>
    </div>
</form>