import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {autoId} from '../../../core/services/utils';
import {FeeViewModel} from '../fee-view-model';
import {FeeCalculatedValues, PerDiemCalculationMethodType} from '../fees.model';
import {cloneDeep} from 'lodash';
import {EnumerationService} from '../../../services/enumeration-service';
import {concatMap, filter, map, Observable, ReplaySubject, Subscription, take} from 'rxjs';
import {EnumerationItem} from '../../../models/simple-enum-item.model';
import {Constants} from '../../../services/constants';
import {FeeSection} from '../fee-section.model';
import {FeeUpdater, FeeUpdaterService} from '../services/fee-updater.service';
import {FormGroup, NgForm} from '@angular/forms';
import * as moment from 'moment/moment';

@Component({
  selector: 'prepaid-interest-editor',
  templateUrl: './prepaid-interest-editor.component.html',
  styleUrls: ['./prepaid-interest-editor.component.scss'],
  providers: [FeeUpdaterService],
})
export class PrepaidInterestEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fee: FeeViewModel;
  @Input() feeSection: FeeSection;

  @Input() editable: boolean = true;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<FeeViewModel>();

  @ViewChild('form')
  protected ngForm: NgForm;
  private get _form(): FormGroup | undefined {
    return this.ngForm?.form;
  }

  protected get effectiveFee(): FeeViewModel {
    return this._feeUpdater?.fee ?? this._fallbackFee;
  }
  protected get calculatedValues(): FeeCalculatedValues {
    return this.effectiveFee?.fee.calculatedValues ?? {};
  }
  protected calculationMethodOptions: readonly EnumerationItem<PerDiemCalculationMethodType>[] = [];

  private _feeUpdater?: FeeUpdater;

  // Placeholder for the input field binding
  private _fallbackFee = FeeViewModel.empty();

  private _id: string = autoId();

  private _resetCalculatedValuesSubscription?: Subscription;

  /**
   * Initialization of `calculatedValues` is delayed until enumerations are loaded as it depends on
   * them.
   * @private
   */
  private _initEnumerations$: ReplaySubject<void> = new ReplaySubject(1);
  private _initEnumerationsSubscription?: Subscription;

  private _updateFeesSubscription?: Subscription;

  private _saveSubscription: Subscription | null = null;
  protected get isSaving(): boolean {
    return this._saveSubscription != null;
  }

  constructor(
    private readonly _enumerationService: EnumerationService,
    private readonly _feeUpdaterService: FeeUpdaterService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('fee' in changes) {
      this.initFeeUpdater();
    }

    if ('editable' in changes) {
      this.invalidateUpdatingFeesSubscriptionState();
    }
  }

  ngOnInit(): void {
    this.initEnumerations();
    if (this._updateFeesSubscription == null) {
      this.invalidateUpdatingFeesSubscriptionState();
    }
  }

  ngOnDestroy(): void {
    this._initEnumerationsSubscription?.unsubscribe();
    this._resetCalculatedValuesSubscription?.unsubscribe();
    this._updateFeesSubscription?.unsubscribe();
    this._saveSubscription?.unsubscribe();
    this._feeUpdater?.destroy();
  }

  private initFeeUpdater(): void {
    this._resetCalculatedValuesSubscription?.unsubscribe();

    this._resetCalculatedValuesSubscription = this._initEnumerations$.subscribe({
      complete: () => {
        this._feeUpdater?.destroy();
        this._feeUpdater = this._feeUpdaterService.createUpdater(this.fee);

        this.effectiveFee.fee.calculatedValues = cloneDeep(this.fee?.fee?.calculatedValues) ?? {};
        this.calculatedValues.calculationMethod ??= PerDiemCalculationMethodType.Item365;
      },
    });
  }

  private initEnumerations(): void {
    this._initEnumerationsSubscription?.unsubscribe();

    this._initEnumerationsSubscription = this._enumerationService
      .getFeeEnumerations()
      .subscribe(feeEnums => {
        this.calculationMethodOptions =
          feeEnums[Constants.feeEnumerations.perDiemCalculationMethodType] || [];

        this._initEnumerations$.next();
        this._initEnumerations$.complete();
      });
  }

  private invalidateUpdatingFeesSubscriptionState(): void {
    if (this.editable) {
      this.subscribeToUpdatingFees();
    } else {
      this._updateFeesSubscription?.unsubscribe();
    }

    setTimeout(() => {
      this.setFormEnabled(this.editable);
    });
  }

  private subscribeToUpdatingFees(): void {
    this._updateFeesSubscription?.unsubscribe();
    this._updateFeesSubscription = this._feeUpdater.updatingState$.subscribe(isUpdating => {
      this.setFormEnabled(!isUpdating);
    });
  }

  private setFormEnabled(enabled: boolean): void {
    if (enabled) {
      this._form?.enable();
    } else {
      this._form?.disable();
    }
  }

  private waitForFeesToUpdate(): Observable<void> {
    return this._feeUpdater.updatingState$.pipe(
      filter(isUpdating => !isUpdating),
      take(1),
      map(() => undefined)
    );
  }

  protected onClosingDateChange(previousValue?: string): void {
    const estimatedClosingDate = this.calculatedValues.estimatedClosingDate;

    if (previousValue != null && estimatedClosingDate === previousValue) {
      return;
    }

    this.determineNumberOfDays(estimatedClosingDate);

    this.updateFees();
  }

  private determineNumberOfDays(estimatedClosingDate: string): void {
    const calculate = () => {
      if (!isDateValid(estimatedClosingDate)) {
        return 0;
      }

      const currentMonthDay = formatDate(estimatedClosingDate);
      const nextMonthFirstDay = formatDate(
        moment(estimatedClosingDate).add(1, 'M').startOf('month').format('MM/DD/YYYY')
      );

      return calculateOddDays(currentMonthDay, nextMonthFirstDay);
    };

    this.calculatedValues.oddDays = calculate();
  }

  protected updateFees(): void {
    this._feeUpdater.update();
  }

  protected getId(elementId: string): string {
    return `${elementId}-${this._id}`;
  }

  protected onCancel(): void {
    this.cancel.emit();
  }

  protected onSave(): void {
    this._saveSubscription?.unsubscribe();

    this._saveSubscription = this.waitForFeesToUpdate()
      .pipe(
        // Fees should already be updated on blur. Still, we want to make sure that the fees are
        // updated before we save the fee.
        concatMap(() => this._feeUpdater.updateWithResult())
      )
      .subscribe(result => {
        this.save.emit(result);
      });
  }
}

function isDateValid(date: string): boolean {
  return moment(date, "MM/DD/YYYY", true).isValid();
}

function formatDate(date: string): string {
  return moment(date).format('MM/DD/YYYY');
}

function calculateOddDays(startDate: string, endDate: string): number {
  return moment(endDate).diff(moment(startDate).startOf('day'), 'days');
}
