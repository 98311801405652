import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ProductSearchRequest } from 'src/app/modules/pricing/models/pricing/product-search-request-info.model';
import { PricingParametersBase } from '../pricing-parameters-base.component';
import { NgForm } from '@angular/forms';
import { Address } from 'src/app/models/address.models';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Utils } from 'src/app/core/services/utils';
import { BorrowerType } from 'src/app/modules/leads/models/lead-credit.model';
import { MortgageBorrower } from 'src/app/models/mortgage.model';
import { Borrower } from 'src/app/models';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'meridian-link-pricing-parameters',
  templateUrl: './meridian-link-pricing-parameters.component.html',
  styleUrls: ['./meridian-link-pricing-parameters.component.scss']
})
export class MeridianLinkPricingParametersComponent extends PricingParametersBase implements OnInit {

  @ViewChild('searchForm')
  searchForm: NgForm;

  @Input()
  set productSearchRequest(request: ProductSearchRequest) {
    this._productSearchRequest = request;
  }

  private _productSearchRequest: ProductSearchRequest;

  get productSearchRequest(): ProductSearchRequest {
    return this._productSearchRequest;
  }

  @Input() isTpo: boolean;

  isApplicationsSectionInvalid: boolean = false;
  isLoanAndPropertySectionInvalid: boolean = false;
  isPmlOptionsSectionInvalid: boolean = false;
  isGeneralSectionInvalid: boolean = false;

  currentTab: string = "applications";

  selectedLoanTermsById: Map<number, boolean> = new Map<number, boolean>();
  selectedAmortizationTypesById: Map<string, boolean> = new Map<string, boolean>();
  selectedArmTermsById: Map<string, boolean> = new Map<string, boolean>();
  selectedLoanTypesById: Map<string, boolean> = new Map<string, boolean>();

  isNewConstruction: boolean = false;
  proposedMonthlyPaymentTotal: number = 0;

  isNewLoanIstexas50a6: boolean = false;
  isPriorLoanIstexas50a6: boolean = false;
  piTiPaymentInfo: { pi: number, ti: number, other: number } = {pi: 0, ti: 0, other: 0};
  currentMipMonth: number = 0;
  homeValue: number = 0;
  equity: number = 0;
  equityPercent: number = 0;
  firstLienPercent: number = 0;
  secondLienPercent: number = 0;
  secondFinancing: boolean = false;

  paidBy: "Borrower" | "Lender" = "Borrower";
  paidByPercent: number = 0;
  paidByAmount: number = 0;
  paidByBaseOptions: EnumerationItem[] = [
    new EnumerationItem("Loan Amount", "LoanAmount")
  ];
  selectedPaidByBaseOption: string = "LoanAmount";
  isLenderFeeBuyoutRequested: boolean = false;
  expectedAusResponseOptions: EnumerationItem[] = [
    new EnumerationItem("DU Approve/Eligible", "duApproveEligible")
  ];
  selectedExpectedAusResponse: string = "duApproveEligible";
  isDuRefiPlus: boolean = false;
  numberOfFinancedProperties: number = 1;

  applicationOptions: EnumerationItem[] = [];
  selectedApplicationId: number;
  borrowers: MortgageBorrower[] = [];

  primaryBorrower: MortgageBorrower = new MortgageBorrower();
  hasCoApplicant: boolean = false;
  totalPayment: number = 0;
  negativeCashFlow: number = 0;
  creditReportType: string = "useCreditReportOnFile";
  creditReportTypeOptions: EnumerationItem[] = [
    new EnumerationItem("Use Credit Report on File","useCreditReportOnFile"),
    new EnumerationItem("Order New Credit Report","orderNewCreditReport"),
    new EnumerationItem("Re-Issue Credit Report","reIssueCreditReport"),
    new EnumerationItem("Upgrade Existing Credit Report to Tri-Merge Report","upgradeExisitingCreditReport"),
    new EnumerationItem("Manually Enter Credit Report","manuallyEnterCreditReport")
  ];

  creditProvider: string = null;
  reportId: number = null;

  creditBorrowers: Borrower[] = [];

  private _shownPopover: PopoverDirective | undefined;

  constructor(private readonly injector: Injector,
    private readonly _mortgageCalcService: MortgageCalculationService
  ) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this._productSearchRequest = await this.getRequest();
    // If I have more vendor specific things I need to do with the request, I do here

    if (this.applicationContext.currentMortgage) {
      const mortgage = this.applicationContext.currentMortgage;

      this.proposedMonthlyPaymentTotal = this._mortgageCalcService.calculateHousingExpenseTotal(mortgage.proposedHousingExpense);
      this.piTiPaymentInfo = this._mortgageCalcService.getPiTiPaymentInfo(mortgage);
      this.homeValue = this._mortgageCalcService.calculateHomeValue(mortgage);

      this.borrowers = mortgage.borrowers || [];

      const primaryBorrower = this.borrowers.find(b => b.typeOfBorrower == BorrowerType.PrimaryBorrower);
      if (primaryBorrower) {
        this.primaryBorrower = primaryBorrower;

        this.applicationOptions = [
          new EnumerationItem(Utils.getBorrowerDisplayName(this.primaryBorrower), mortgage.applicationId)
        ];
        this.selectedApplicationId = this.applicationOptions[0].value;

        if (!this.primaryBorrower.nameSuffix) {
          this.primaryBorrower.nameSuffix = null;
        }
      }
      this.hasCoApplicant = mortgage.borrowers.length > 1;
    }

    this.creditBorrowers = this.applicationContext.borrowers || [];

    this.creditBorrowers.forEach(b => {
      b.fullName = Utils.getBorrowerFullName(b);
    });
  }

  onShownPopover(ref: PopoverDirective) {
    const setRef = () => this._shownPopover = ref;

    if (this._shownPopover) {
      this._shownPopover.hide();
      setTimeout(setRef);
    } else {
      setRef();
    }
  }

  onHiddenPopover() {
    this._shownPopover = undefined;
  }

  protected handleAddressChange(e: Partial<Address>): void {
    setTimeout(() => {
      this.productSearchRequest.propertyInformation.propertyStreetAddress = e.address1;
      this.productSearchRequest.propertyInformation.city = e.city;
      this.productSearchRequest.propertyInformation.state = e.state;
      this.productSearchRequest.propertyInformation.zipCode = e.zipCode;
      this.productSearchRequest.propertyInformation.county = e.county;
    }, 200);
  }

  onFirstLienPercentChanged = () => {
    this.productSearchRequest.loanInformation.firstLienAmount = this.homeValue * this.firstLienPercent;
    this.equity = this.homeValue - this.productSearchRequest.loanInformation.firstLienAmount;
    this.equityPercent = 1 - this.firstLienPercent;
  }

  onSecondLienPercentChanged = () => {
    this.productSearchRequest.loanInformation.secondLienAmount = this.homeValue * this.secondLienPercent;
    this.equity = this.homeValue - this.productSearchRequest.loanInformation.secondLienAmount;
    this.equityPercent = 1 - this.secondLienPercent;
  }

  onEquityPercentChanged = () => {
    this.equity = this.homeValue * this.equityPercent;
    if (this.isFirstLienVisible) {
      this.productSearchRequest.loanInformation.firstLienAmount = this.homeValue - this.equity;
      this.firstLienPercent = 1 - this.equityPercent;
    }
    else if (this.isSecondLienVisible) {
      this.productSearchRequest.loanInformation.secondLienAmount = this.homeValue - this.equity;
      this.secondLienPercent = 1 - this.equityPercent;
    }
  }

  onEquityAmountChanged = () => {
    if (this.isFirstLienVisible) {
      this.homeValue = this.equity + this.productSearchRequest.loanInformation.firstLienAmount;
    }
    else if (this.isSecondLienVisible) {
      this.homeValue = this.equity + this.productSearchRequest.loanInformation.secondLienAmount;
    }
  }

  onHomeValueChanged = () => {
    this.equity = this.homeValue * this.equityPercent;
    if (this.isFirstLienVisible) {
      this.productSearchRequest.loanInformation.firstLienAmount = this.homeValue * this.firstLienPercent;
    }
    else if (this.isSecondLienVisible) {
      this.productSearchRequest.loanInformation.secondLienAmount = this.homeValue * this.secondLienPercent;
    }
  }

  get isFirstLienVisible(): boolean {
    return this.productSearchRequest.loanInformation.lienType != 'FirstLien' && !this.hiddenFields.includes('LoanInformation.FirstLienAmount');
  }

  get isSecondLienVisible(): boolean {
    return this.productSearchRequest.loanInformation.lienType == 'FirstLien' &&
      this.isHomeEquityLoanType === false && this.helocOrMortgageLoanType == 'Mortgage' &&
      !this.hiddenFields.includes('LoanInformation.SecondLienAmount');
  }

  onPaidByPercentChanged = () => {
  }

  onSelectedPaidByBaseChanged = () => {
  }

  onPaidByAmountChanged = () => {
  }

  getExpirationDate = (): Date => {
    const date = new Date();
    return new Date(
      date.setDate(date.getDate() + this.productSearchRequest.desiredLockPeriod)
    )
  }


  validate = (): boolean => {
    this.isApplicationsSectionInvalid = false;
    this.isLoanAndPropertySectionInvalid = false;
    this.isPmlOptionsSectionInvalid = false;
    this.isGeneralSectionInvalid = false;

    let firstInvalidOneId: string = null;
    if (this.searchForm) {
      this.searchForm.form.markAllAsTouched();
      const isValid = this.searchForm.form.valid;
      if (!isValid) {
        for (var key in this.searchForm.form.controls) {
          if (this.searchForm.form.controls.hasOwnProperty(key)) {
            if (this.searchForm.form.controls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
      }
      if (firstInvalidOneId) {
        this.scrollToElement(firstInvalidOneId);
      }
      return isValid;
    }
    return false;
  };

  private scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const section = element.getAttribute('section');
      setTimeout(() => {
        this.isApplicationsSectionInvalid = section === 'applications-info';
        this.isLoanAndPropertySectionInvalid = section === 'loan-and-property-info';
        this.isPmlOptionsSectionInvalid = section === 'pml-options-info';
        this.isGeneralSectionInvalid = section === 'general-info';
        setTimeout(() => {
          const parentElement = element.parentElement;
          let el = parentElement ? parentElement : element;
          if (el.innerText === "This field cannot be zero") {
            el = el.parentElement.parentElement ? el.parentElement.parentElement : el;
          }
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }, 150);
      }, 250);
    }
  };

  protected onAfterRequestCreated(request: ProductSearchRequest) {
    super.onAfterRequestCreated(request);
    // Extra meridian related modifications

    this.loanTermOptions.forEach(lto => {
      this.selectedLoanTermsById.set(lto.value, this.selectedLoanTerms.map(t => t.value).includes(lto.value));
    });

    this.amortizationTypeOptions.forEach(lto => {
      this.selectedAmortizationTypesById.set(lto.value, this.selectedAmortizationTypes.map(t => t.value).includes(lto.value));
    });

    this.armFixedTermOptions.forEach(lto => {
      this.selectedArmTermsById.set(lto.value, this.selectedArmFixedTerms.map(t => t.value).includes(lto.value));
    });

    this.loanTypeOptions.forEach(lto => {
      this.selectedLoanTypesById.set(lto.value, this.selectedLoanTypes.map(t => t.value).includes(lto.value));
    });

  }

  onBorrowerInterestExplainClicked = () => { }
  isInRuralAreaExplainClicked = () => { }
  monthlyHousingExpensesCalculateClicked = () => { }
  isNewLoanIstexas50a6ExplainClicked = () => { }
  currentLoanPAndIPaymentModifyClicked = () => { }
  numberOfFinancedPropertiesExplainClicked = () => { }
  onBorrowerMonthlyIncomeExplainClicked = () => { }
  isEligibleforVALoanDetermineClicked = () => { }
  totalLiquidAssetsExplainClicked = () => { }
  negativeCashFlowExplainClicked = () => { }
  isMyCreditProviderSupportedClicked = () => { }

  viewCreditReportClicked = () => {

  }

  selectedLoanTermsChanged = (id: number, newValue: boolean) => {
    this.selectedLoanTermsById.set(id, newValue);

    this.selectedLoanTerms = this.loanTermOptions.filter(o => !!this.selectedLoanTermsById.get(o.value));
    this.loanTermsChanged();
  }

  selectedAmortizationTypeChanged = (id: string, newValue: boolean) => {
    this.selectedAmortizationTypesById.set(id, newValue);

    this.selectedAmortizationTypes = this.amortizationTypeOptions.filter(o => !!this.selectedAmortizationTypesById.get(o.value));

    if (!this.selectedAmortizationTypes.map(t => t.value).includes("ARM") && this.selectedArmFixedTerms.length) {
      this.selectedArmFixedTerms = [];
    }

    this.amortizationTypeChanged();
  }

  selectedArmFixedTermsChanged = (id: string, newValue: boolean) => {
    this.selectedArmTermsById.set(id, newValue);

    this.selectedArmFixedTerms = this.armFixedTermOptions.filter(o => !!this.selectedArmTermsById.get(o.value));
    this.armFixedTermChanged();
  }

  selectedLoanTypesChanged = (id: string, newValue: boolean) => {
    this.selectedLoanTypesById.set(id, newValue);

    this.selectedLoanTypes = this.loanTypeOptions.filter(o => !!this.selectedLoanTypesById.get(o.value));
    this.onMultipleLoanTypesChanged();
  }
}
