<div *ngIf="!isLoading; else loading">

    <div class="row">
      <div class="col-md-6" *ngIf="customDataConfig.editor1Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField1"
          [dataType]="customDataConfig.field1Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
      <div class="col-md-6" *ngIf="customDataConfig.editor2Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField2"
          [dataType]="customDataConfig.field2Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6" *ngIf="customDataConfig.editor3Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField3"
          [dataType]="customDataConfig.field3Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
      <div class="col-md-6" *ngIf="customDataConfig.editor4Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField4"
          [dataType]="customDataConfig.field4Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" *ngIf="customDataConfig.editor5Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField5"
          [dataType]="customDataConfig.field5Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
      <div class="col-md-6" *ngIf="customDataConfig.editor6Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField6"
          [dataType]="customDataConfig.field6Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" *ngIf="customDataConfig.editor7Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField7"
          [dataType]="customDataConfig.field7Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
      <div class="col-md-6" *ngIf="customDataConfig.editor8Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField8"
          [dataType]="customDataConfig.field8Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6" *ngIf="customDataConfig.editor9Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField9"
          [dataType]="customDataConfig.field9Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
      <div class="col-md-6" *ngIf="customDataConfig.editor10Type">
        <custom-field-editor [model]="customData" [editConfig]="editConfigForField10"
          [dataType]="customDataConfig.field10Type" [isInClassicEditMode]="isInClassicEditMode" [templateType]="templateType" [labelAlignment]="labelAlignment">
        </custom-field-editor>
      </div>
    </div>
  </div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading..."></loading-indicator>
</ng-template>
