<div class="modal-header">
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <span>
            <p> This process can take approximately 15 minutes.. </p>
        </span>
        <span>
            <p> You will be notified once it's finished. </p>
        </span>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Close </button>
</div>
