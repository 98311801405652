import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss']
})
export class TermsAndConditionsDialogComponent implements OnInit {

  hostUrl = window.location.host;

  protected printConfig: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.printConfig = [
      'sectionConvertedToPDF', {
        printMode: 'template-popup',
        pageTitle: 'Terms & Conditions',
      }
    ];
  }

  iAgreeClicked = () => {
    this.activeModal.close();
  }

}
