<div class="modal-header">
  <h5 class="modal-title w-100">{{ title }}</h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body w-100 h-100">
  <canvas
    id="canvas"
    #canvas
    (mousemove)="mousemove($event)"
    (mousedown)="mousedown($event)"
    (mouseenter)="mouseenter($event)"
    style="width: 100%; height: 100%; border: 1px solid black;"
  ></canvas>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-soft-danger" (click)="clear()">
    Clear
  </button>

  <button
    type="button"
    class="btn btn-soft-secondary ms-auto"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>

  <button type="submit" class="btn btn-soft-primary" (click)="accept()">
    Accept
  </button>
</div>
