<div class="modal-header">
    <h5 class="modal-title">Cancel Order? </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="mb-3">
        <label class="form-label">Are you sure you want to cancel your eSign request? This cannot be un-done?</label>
    </div>
    <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">Cancellation Reason(Optional):</label>
        <textarea class="form-control" cols="4" rows="5" class="form-control" [(ngModel)]="reason"
            placeholder="Cancellation Reason(Optional)"></textarea>
    </div>
    <div>

    </div>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary center" (click)="activeModal.dismiss()">Cancel</button>

        <button type="button" (click)="confirmDelete()" class="btn btn-danger center">Yes</button>
    </div>
</div>