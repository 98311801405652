import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter, NgZone, ElementRef, Injector, Renderer2 } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import * as _ from 'lodash';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { forkJoin } from 'rxjs';
import { ApplicationContextBoundComponent } from '../application-context-bound.component';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';

declare global {
  interface Window {
    Stripo: any;
    StripoApi: any;
  }
}

@Component({
  selector: 'stripo-email-editor',
  templateUrl: 'stripo-email-editor.component.html',
  styleUrls: ['./stripo-email-editor.component.scss']
})
export class StripoEmailEditorComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  documentId: string;

  @Input()
  set html(html: string) {
    this._html = html;
    this._originalHtml = html;
  }

  @Input()
  set css(css: string) {
    this._css = css;
    this._originalCss = css;
  }

  get html(): string {
    return this._html;
  }

  get css(): string {
    return this._css;
  }

  @Input()
  mergeFields: EnumerationItem[] = [];

  @Input()
  saveCallback: (html: string) => void;

  @Input()
  useStripoEditor: boolean = false;

  @Output()
  templateSaved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saveClicked: EventEmitter<StripoEditorSaveClickedEvent> = new EventEmitter<StripoEditorSaveClickedEvent>();

  @Output()
  cancelClicked: EventEmitter<any> = new EventEmitter<any>();

  // savedTemplateOptions: FilterOption[] = [];
  // inFullScreenWidgetDetailsMode: boolean = false;

  protected isPreviewMode: boolean = false;

  private _stripoPluginId: string = "2af790b4fad743229d97d3abdfc7de4e";
  private _stripoSecretKey: string = "9154bd659f784d76aa748c3e089e6203";

  private _initCalled: boolean = false;

  private _originalHtml: string;
  private _html: string;
  private _originalCss: string;
  private _css: string;
  private _companyGuid: number;

  constructor(private readonly _zone: NgZone,
    private readonly injector: Injector,
    private readonly _environment: EnvironmentService,
    private readonly _elementRef: ElementRef,
    private readonly _httpClient: HttpClient,
    private renderer: Renderer2) {
    super(injector);
    this.documentId = uuidv4();
  }

  ngAfterViewInit(): void {
    if (this.useStripoEditor && !this.html || this.html == "") {
      this.onStartWithStripDefaultClicked();
    }
    
    setTimeout(() => {
      const thisElement = this._elementRef.nativeElement;
      const parentElement = thisElement.parentElement;
      if (parentElement) {
        parentElement.style.padding = '0';
        parentElement.style.height = 'calc(100% - 43px)';
      }

      const elements = document.querySelectorAll('div.ng-select-container');
      elements.forEach((element) => {
        this.renderer.setStyle(element, 'z-index', '0');
      });
    });

    if (!this._initCalled) {
      this._zone.run(() => {
        this.initStripoPlugin();
      });
      this._initCalled = true;
    }
  }

  ngOnInit() {
    const company = this.applicationContext.globalConfig.company.find(c => c.companyId == this.applicationContext.userPermissions.companyId);
    this._companyGuid = company.companyId;
  }

  ngOnDestroy(): void {
    window.StripoApi.stop();
  }

  getCompiledHtmlFromStripo = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!window.StripoApi) {
        reject('Stripo API not available');
      }
      window.StripoApi.compileEmail((error, html, ampHtml, ampErrors) => {
        const editedHtml = html;
        resolve(editedHtml);
      });
    });
  }

  onStripoEditorToggled = async (e: any) => {
    if (e.target.checked) {
      this.initStripoPlugin();
      this.useStripoEditor = true;
    } else {
      const self = this;
      try {
        this.getCompiledHtmlFromStripo().then(html => {
          window.StripoApi.stop();
          self._html = html;
          this.useStripoEditor = false;
        });
      } catch (error) {
        // swallow
      }
    }
  }

  onPreviewModeToggled = async (e: any) => {
    const self = this;
    if (e.target.checked) {
      if (this.useStripoEditor) {
        this.getCompiledHtmlFromStripo().then(html => {
          self._html = html;
          this.loadEmailTemplatePreview();
        });
      } else {
        this.loadEmailTemplatePreview();
      }
    } else {
      this.isPreviewMode = false;
    }
  }

  onStartWithStripDefaultClicked = () => {
    const stripoDefaultHtml: string = '/assets/html/stripo-default-v2.html';
    const stripoDefaultCss: string = '/assets/css/stripo-default-v2.css';
    forkJoin({
      css: this._httpClient.get(stripoDefaultCss, { responseType: 'text' }),
      html: this._httpClient.get(stripoDefaultHtml, { responseType: 'text' }),
    }).subscribe({
      next: ({ css, html }) => {
        const apiBaseUrl = this._environment.apiInfo.apiBaseUrl;
        if (!apiBaseUrl.endsWith('/')) {
          apiBaseUrl.concat('/');
        }
        const logoUrl = `${apiBaseUrl}company/co/logo/${this._companyGuid}`;
        this._html = html.replace("{logoUrl}", logoUrl);
        this._css = css;
        if (window.StripoApi) {
          window.StripoApi.stop();
        }
        this.initStripoPlugin();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  onSaveClicked = () => {
    if (!this.useStripoEditor) {
      this.saveClicked.emit(new StripoEditorSaveClickedEvent(this._html, false));
      return;
    }
    this.getCompiledHtmlFromStripo().then(html => {
      this.saveClicked.emit(new StripoEditorSaveClickedEvent(html, true));
      if (this.saveCallback) {
        this.saveCallback(html);
      }
    });
  }

  onCancelClicked = () => {
    if (!this.useStripoEditor) {
      this.cancelClicked.emit();
      return;
    }

    this.getCompiledHtmlFromStripo().then(html => {
      if (this._originalHtml !== html) {
        Swal.fire({
          title: 'There are unsaved changes',
          text: 'There are unsaved changes. Do you still want to leave?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then((result: SweetAlertResult) => {
          if (result.value) {
            this.cancelClicked.emit();
          }
        });
      } else {
        this.cancelClicked.emit();
      }
    });
  }

  private initStripoPlugin = () => {
    const self = this;

    const mergeFieldsForStripo: EnumerationItem[] = [];
    this.mergeFields.forEach(mf => {
      const updatedMergeField = new EnumerationItem(mf.name, `%${mf.value}%`);
      mergeFieldsForStripo.push(updatedMergeField);
    })

    const mergeTagEntries = mergeFieldsForStripo.map(mf => { return { label: mf.name, value: mf.value }; });

    try {
      window.Stripo.init({
        settingsId: 'stripoSettingsContainer' + self.documentId,
        previewId: 'stripoPreviewContainer' + self.documentId,
        codeEditorButtonId: 'viewCodeButton' + self.documentId,
        undoButtonId: 'undoButton' + self.documentId,
        redoButtonId: 'redoButton' + self.documentId,
        locale: 'en',
        html: self._html,
        css: self._css,
        notifications: {
          info: message => console.log(message),
          error: message => console.error(message),
          success: message => console.log(message),
          warn: message => console.log(message),
          loader: message => console.log(message),
          hide: message => console.log(message)
        },
        mergeTags: [
          {
            category: "Personalization",
            entries: mergeTagEntries
          }
        ],
        apiRequestData: {
          emailId: self.documentId,
          companyId: self._companyGuid,
        },
        onDataChanged: function () {
          console.log('data changed')
        },
        versionHistory: {
          changeHistoryLinkId: 'historyButton' + self.documentId,
          onInitialized: function (lastChangeInfoText) {
            $('#changeHistoryContainer' + self.documentId).show();
          },
          onChange: (label) => console.log(label)
        },
        getAuthToken: function (callback) {
          self.authenticate('POST', 'https://plugins.stripo.email/api/v1/auth',
            JSON.stringify({
              pluginId: self._stripoPluginId,
              secretKey: self._stripoSecretKey
            }),
            function (data) {
              callback(JSON.parse(data).token);
            });
        }
      });
    } catch (error) {
      // swallow
    }
  }

  private onAuthSucceeded(token: string) {

  }

  private onAuthErrored(error: any) {

  }

  private loadEmailTemplatePreview = () => {
    setTimeout(() => {
      let iframe = document.getElementById(`htmlPreviewPanel`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      setTimeout(() => {
        doc.open();
        doc.write(this._html);
        doc.close();
        this.isPreviewMode = true;
      }, 100)
    });
  }

  authenticate = (method, url, data, callback) => {
    var req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        callback(req.responseText);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.error('Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values');
      }
    };
    req.open(method, url, true);
    if (method !== 'GET') {
      req.setRequestHeader('content-type', 'application/json');
    }
    req.send(data);
  }
}

export class StripoEditorSaveClickedEvent {
  html: string;
  savingUsingStripo: boolean;

  constructor(html: string, savingUsingStripo: boolean) {
    this.html = html;
    this.savingUsingStripo = savingUsingStripo;
  }
}
