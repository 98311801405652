export class AgentNote {
    messageId: number;
    agentId: number;
    userId: string;
    content: string;
    postTime: string;
    userFirstName: string;
    userLastName: string;
    userAvatar: string;
    companyId: number;
    insertedBy: string;
    dateInserted: Date;
    updatedBy: string;
    dateUpdated: Date;
}
