import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { ProcessSubmissionModel } from '../../models/process-submission.model';
import { ApplicationContext, ChannelEnum } from 'src/app/models';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'key-dates',
  templateUrl: './key-dates.component.html',
  styleUrls: ['./key-dates.component.scss'],
})
export class KeyDatesComponent implements OnInit {
  @ViewChild('keyDatesForm') keyDatesForm: NgForm | undefined;

  @Input()
  processSubmissionData: ProcessSubmissionModel;

  @Input()
  context: ApplicationContext;

  @Input()
  showMissingInfo: boolean;

  @Input()
  allowedLoanCharacterstics;

  @Input()
  allowedBorrowerCharacterstics;

  @Output()
  nextStep: EventEmitter<{ processSubmissionData: ProcessSubmissionModel }> =
    new EventEmitter<any>();

  @Output()
  previousStep: EventEmitter<any> = new EventEmitter<any>();

  keyDates: { displayName: string; name: string; value: string }[] = [];

  constructor() {}

  ngOnInit(): void {
    Object.keys(this.processSubmissionData.loanData.keyDateValues).forEach(
      (keyDateValue) => {
        let keyDate = this.context.globalConfig.keyDates.find(
          (kd) => kd.keyDateType?.toLowerCase() == keyDateValue.toLowerCase()
        );
        if (keyDate) {
          if (
            this.processSubmissionData.channel === ChannelEnum.Wholesale &&
            (this.context.isCompanyDeepHaven) &&
            keyDate.keyDateType != 'ApplicationReceived'
          ) {
            //skip these
          } else {
            const newDate = new Date();
            this.keyDates.push({
              displayName: keyDate.displayName,
              name: keyDateValue,
              value:
                (this.context.isCompanyDeepHaven && keyDate.keyDateType == 'ApplicationReceived') ?
                  new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -1) : 
                  this.processSubmissionData.loanData.keyDateValues[keyDateValue],
            });
          }
        } else {
          if (
            this.processSubmissionData.channel === ChannelEnum.Wholesale &&
            (this.context.isCompanyDeepHaven) &&
            keyDate.keyDateType != 'ApplicationReceived'
          ) {
            //skip these
          } else {
            let name = keyDateValue.replace(/([A-Z])/g, ' $1').trim();
            const newDate = new Date();
            this.keyDates.push({
              displayName: name[0].toUpperCase() + name.slice(1),
              name: keyDateValue,
              value:
                (this.context.isCompanyDeepHaven && keyDate.keyDateType == 'ApplicationReceived') ?
                  new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -1) : 
                  this.processSubmissionData.loanData.keyDateValues[keyDateValue],
            });
          }
        }
      }
    );
  }

  onKeyDateChanged = (keyDate) => {
    this.processSubmissionData.loanData.keyDateValues[keyDate.name] =
      keyDate.value && keyDate.value.length == 10
        ? new Date(keyDate.value + ' GMT-000').toISOString()
        : '';
  };

  back = () => {
    this.previousStep.emit();
  };

  proceed = () => {
    this.keyDatesForm.form.markAllAsTouched();
    if (this.keyDatesForm.form.valid) {
      this.nextStep.emit({ processSubmissionData: this.processSubmissionData });
    }
  };
}
