import { NgModule } from '@angular/core';
import { RemainContactComponent } from './components/remain-contact/remain-contact.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RemainContactRoutingModule } from './remain-contact-routing.module';
import { AgentsModule } from '../agents/agents.module';
import { SelectAgentTypeDialogComponent } from './components/remain-contact/select-agent-type-dialog/select-agent-type-dialog.component';



@NgModule({
  declarations: [
    RemainContactComponent,
    SelectAgentTypeDialogComponent
  ],
  imports: [
    SharedModule,
    RemainContactRoutingModule,
    AgentsModule
  ],
  exports:[
    RemainContactComponent,
    SelectAgentTypeDialogComponent
  ]

})
export class RemainContactModule { }
