<div class="card tpo-report-card">
  <div class="card-body">
    <div class="panel-group">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-6" [ngClass]="{'col-md-12': !application}">
          <loan-status-card
            [loanStatusName]="loanStatus ? loanStatus.loanStatusName : 'Unknown'">
          </loan-status-card>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 border-end border-right-loan-status" *ngIf="application">
          <outstanding-conditions-card
            [appId]="application.applicationId"
            [isTpoSubmitted]="isTpoSubmitted"
            [primaryBorrowerId]="application.primaryBorrowerId">
          </outstanding-conditions-card>
        </div>
      </div>
    </div>
  </div>
</div>
