import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RunDisclosuresComponent } from './run-disclosures.component';
import { PendingChangesGuard } from 'src/app/core/route-guards/pending-changes.guard';

const routes: Routes = [
  {
    path: '',
    canDeactivate: [ PendingChangesGuard ],
    component: RunDisclosuresComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RunDisclosuresRoutingModule { }
