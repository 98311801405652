import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable } from 'rxjs';
import { User } from '../models/user/user.model';
import { LeadStatus, LoanStatus } from '../models';
import { Channel } from '../modules/leads/models/lead.model';
import * as _ from 'lodash';

@Injectable()
export class LookupService {
  constructor(private readonly _dataService: DataService) { }

  getAllUsers = (): Observable<User[]> => {
    const url = `api/Lookup/getAllUsers`;
    return this._dataService.get(url);
  };

  getUserById(userId: string) {
    this.getAllUsers().subscribe((result) => {
      let allUsers = result;
      if (allUsers) {
        return allUsers.find(i => i.userCompanyGuid === userId);
      }
    });
  };

  // notice: API returns Loan Status array
  getLeadStatusForLoanPurpose(loanPurposeId: number, loanStatusId: number): Observable<LeadStatus[]> {
    return this._dataService.get("api/Lookup/GetLeadStatusForLoanPurpose/" + loanPurposeId + "/" + loanStatusId);
  }

  getLoanStatusForLoanPurpose(loanPurposeId: number, loanStatusId: number, applicationId?: number, channel?: string): Observable<LoanStatus[]> {

    let url  = "api/Lookup/GetLoanStatusForLoanPurpose/" + loanPurposeId + "/" + loanStatusId;

    if (applicationId || channel) {
      url += '?' + _.compact([
        applicationId ? `applicationId=${applicationId}` : '',
        channel ? `channel=${channel}` : ''
      ]).join('&');
    }
    return this._dataService.post(url, {});
  }
}
