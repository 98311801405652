import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SigningService } from 'src/app/services/signing.service';

@Component({
  selector: 'document-signing-request-confirmation',
  templateUrl: './document-signing-request-confirmation.component.html',
  styleUrls: ['./document-signing-request-confirmation.component.scss']
})
export class DocumentSigningRequestConfirmationComponent implements OnInit {

  constructor(private readonly _signingService: SigningService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    this.sendConfirmation();
  }

  sendConfirmation = () => {
    let urlParams = location.search;

    urlParams = urlParams.replace('?t', '?token');
    urlParams = urlParams.replace('event', 'action');

    this._signingService.onSenderViewSubmitted(urlParams).subscribe((response) => {
      window.parent.postMessage({
        shouldCloseDocSignIframe: true,
        response: response
      }, '*');
    }, (err) => {
      this._notifyService.showError(err.message || err || "Send Document for Signing", "Error");
    })
  }

}
