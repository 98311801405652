import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Configuration } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private readonly _dataService: DataService) { }

  getPages = (templateId: number) => {
    const url = 'api/configuration/document-templates/templates/' + templateId + '/pages';
    return this._dataService.get(url);
  }

  getCompanyConfiguration = (configurationKey: string): Observable<Configuration> => {
    const url = 'api/configuration/company?key=' + configurationKey;
    return this._dataService.get(url);
  }

  getBranchConfiguration = (branchId: number, configurationKey: string): Observable<Configuration> => {
    const url = `api/configuration/branch/${branchId}?key=` + configurationKey;
    return this._dataService.get(url);
  }

  upsertCompanyConfiguration = (configuration: Configuration): Observable<Configuration> => {
    const url = 'api/configuration/company';
    return this._dataService.post(url, configuration);
  }

  upsertBranchConfiguration = (configuration: Configuration): Observable<Configuration> => {
    const url = `api/configuration/branch`;
    return this._dataService.post(url, configuration);
  }
}
