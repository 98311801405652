
<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      {{sectionDisplayName}}
    </h4>
    <span>Total: {{totalAmount | currency}}</span>
  </div>
  <div class="card-body" style="max-height:340px; overflow-y: scroll;" [style.height.px]="cardHeights[index]">
    <div [id]="'fees' + index">
      <div class="d-flex" *ngFor="let fee of fees">
        <div class="p-2">{{fee.hudNumber}}</div>
        <div class="p-2">{{fee.name}}</div>
        <div class="ml-auto p-2 flex-grow-1"><span class="pull-right">{{(fee.calculatedValues?.totalFee || 0) | currency}}</span></div>
      </div>
    </div>
  </div>
</div>



