import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { TableColumn } from 'src/app/shared/models/tale-column.model';
import {
  CopyFeeTemplatePurpose, EditFeeTemplatePurpose,
  FeeTemplateDetailsDialogComponent,
} from '../../dialogs/fee-template-details-dialog/fee-template-details-dialog.component';

@Component({
  selector: 'app-lender-config-fee-templates',
  templateUrl: './lender-config-fee-templates.component.html',
  styleUrls: ['./lender-config-fee-templates.component.scss']
})
export class LenderConfigFeeTemplatesComponent extends ApplicationContextBoundComponent implements OnInit {

  feeTemplates: FeeTemplate[];

  selectedColumns: any[] = [];
  columns: TableColumn[] = [];
  globalFilterFields: string[] = [];
  enabledChannels: EnumerationItem[] = [];
  isLoading: boolean = false;

  globalConfig: GlobalConfig;
  isDeleteClicked = {};

  selectedFeeTemplate: FeeTemplate = null;

  expressionHtmls: { html: string, expressionGroupId: number }[] = [];

  constructor(
    private readonly _feeService: FeeService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.scrollOffset = 475;
  }

  ngOnInit(): void {
    this.applicationContextService.context.subscribe(context => {
      this.globalConfig = context.globalConfig;

      this.enabledChannels = context.globalConfig.enabledChannels;
      this.columns = [
        { field: 'name', header: 'Template Name', order: 1, visible: true }
      ];
      if (this.enabledChannels.length > 0) {
        this.columns.push({ field: 'enabledChannels', header: 'Channels', order: 2, visible: true });
      }
      this.columns.push({ field: 'expressionId', header: 'Expression', order: 3, visible: true });

      this.selectedColumns = [];
      this.globalFilterFields = [];

      this.columns.forEach(column => {
        this.globalFilterFields.push(column.field);
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });
      this.getTemplates(context.globalConfig);
    })
  }

  onUpdate = () => {
    this.applicationContextService.context.subscribe(context => {
      this.getTemplates(context.globalConfig);
    })
  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  getTemplates = (globalConfig: GlobalConfig) => {
    this._spinner.show();
    this._feeService.getConfigFeeTemplates().subscribe((response) => {
      this.feeTemplates = response;
      this._spinner.hide();

      this.getTemplateExpressionHTMLs(response, globalConfig);

    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err ? err.error.message || err.message : '', "Error");
    });
  }

  getTemplateExpressionHTMLs = (templates: FeeTemplate[], globalConfig: GlobalConfig) => {

    this._spinner.show();
    this._feeService.getExpressionHTMLs(globalConfig, templates.filter(t => t.expressionId).map(t => t.expressionId))
      .subscribe(htmls => {
        this.expressionHtmls = htmls;
        this._spinner.hide();
      }, (err) => {
        this._spinner.hide();
        this._notifyService.showError(err ? err.error.message || err.message : '', "Error");
      });

  }

  getExpressionHTMLById = (expressionId: number) => {
    let html;

    let matched = this.expressionHtmls.find(h => h.expressionGroupId == expressionId);
    if (matched) {
      html = matched.html;
    }

    return html
  }

  copyFeeTemplate(feeTemplate: FeeTemplate): void {
    let modalRef = this._modalService.open(FeeTemplateDetailsDialogComponent, {
      size: 'md',
      backdrop: 'static',
      centered: true
    });

    modalRef.componentInstance.purpose = new CopyFeeTemplatePurpose(feeTemplate);
    modalRef.componentInstance.allFeeTemplates = this.feeTemplates;
    modalRef.componentInstance.getTemplateExpressionHTMLs.subscribe((templates: FeeTemplate[]) => {
      this.getTemplateExpressionHTMLs(templates, this.globalConfig)
    })

    modalRef.result.then(() => {
      this.onUpdate();
    }, () => {
    });
  }

  selectFeeTemplate = (feeTemplate: FeeTemplate) => {
    if (this.selectedFeeTemplate && this.selectedFeeTemplate.feeTemplateId == feeTemplate.feeTemplateId) {
      return;
    }
    this.selectedFeeTemplate = feeTemplate;
  }

  onEditTemplateClicked = (feeTemplate: FeeTemplate) => {
    let modalRef = this._modalService.open(FeeTemplateDetailsDialogComponent, {
      size: 'md',
      backdrop: 'static',
      centered: true
    });

    modalRef.componentInstance.purpose = new EditFeeTemplatePurpose(feeTemplate);
    modalRef.componentInstance.allFeeTemplates = this.feeTemplates;
    modalRef.componentInstance.getTemplateExpressionHTMLs.subscribe((templates: FeeTemplate[]) => {
      this.getTemplateExpressionHTMLs(templates, this.globalConfig)
    })

    modalRef.result.then(() => {
      this.onUpdate();
    }, () => {
    });
  }

  addNewTemplate = () => {
    let modalRef = this._modalService.open(FeeTemplateDetailsDialogComponent, {
      size: 'md',
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.allFeeTemplates = this.feeTemplates;
    modalRef.componentInstance.getTemplateExpressionHTMLs.subscribe((templates: FeeTemplate[]) => {
      this.getTemplateExpressionHTMLs(templates, this.globalConfig)
    })

    modalRef.result.then(() => {
      this.onUpdate();
    }, () => {
    });
  }

  onDeleteTemplateClicked = (feeTemplateId: number) => {
    this._feeService.deleteFeeTemplate(feeTemplateId)
      .subscribe(() => {
        this.selectedFeeTemplate = null;
        this._notifyService.showSuccess("Template removed succesfully", "Success");
        this.onUpdate();
      }, (err) => {
        this._notifyService.showError(err.error.message || "Error encountered while deleting template", "Error!");
      });
  }

  templateFeesChanged = (fees: FeeTemplateFee[]) => {
    if (!this.selectedFeeTemplate) return;

    const index = this.feeTemplates.findIndex(fee => fee.feeTemplateId === this.selectedFeeTemplate.feeTemplateId);
    if (index === -1) return;

    this.feeTemplates[index].fees = [...fees];
    this.selectedFeeTemplate = { ...this.selectedFeeTemplate };
  }
}
