import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'create-scenario-name-dialog',
  templateUrl: 'create-scenario-name-dialog.component.html',
  styleUrls: ['./create-scenario-name-dialog.component.scss']
})
export class CreateScenarioNameDialogComponent {

  @ViewChild('createScenarioNameForm') form: NgForm | undefined;

  @Input()
  scenarioName: string;

  originalCopyOfScenarioName: string;

  constructor(public activeModal: NgbActiveModal) { }

  onSaveClicked = () => {
    if (this.form) {
      this.form.form.markAllAsTouched();
      if (!this.form.form.valid) {
        return;
      } else {
        this.activeModal.close(this.scenarioName);
      }
    } else return;
  }
}
