import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { finalize } from 'rxjs/operators';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanPurposeService } from '../../../services';

@Component({
  selector: "upsert-loan-purpose",
  templateUrl: 'upsert-loan-purpose.component.html',
})
export class UpsertLoanPurpose implements OnInit {
  @Input()
  loanPurpose: LoanPurpose;

  @Input()
  transactionTypes: Array<EnumerationItem>;

  @Input()
  mortgageLoanPurposeTypes: Array<EnumerationItem>;

  @Input()
  enabledChannels: EnumerationItem[];

  @Output()
  onSave: EventEmitter<LoanPurpose> = new EventEmitter<LoanPurpose>()
  
  @Output()
  onClose: EventEmitter<never> = new EventEmitter<never>()

  @ViewChild('upsertLoanPurposeForm')
  upsertLoanPurposeForm: NgForm | undefined;

  saving: boolean;
  editMode: boolean;

  multiselectChannels: Array<Select2OptionData> = [];
  multiselectChannelsModel = [];

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _loanPurposeService: LoanPurposeService,
    private readonly _channelService: ChannelService,
  ) { }

  ngOnInit(): void {
    this.editMode = this.loanPurpose.loanPurposeId ? true : false;
    if (!this.loanPurpose.mortgageLoanPurpose) {
      this.loanPurpose.mortgageLoanPurpose = '';
    }
    this.setMultiSelectData();
  }

  save() {
    if (!this.upsertLoanPurposeForm) return;
    this.upsertLoanPurposeForm.form.markAllAsTouched();
    if (!this.upsertLoanPurposeForm.form.valid) return;

    this.loanPurpose.enabledChannels = this.enabledChannels.length > 0
      ? this.multiselectChannelsModel?.join(", ")
      : null;
    this.saving = true;
    this.editMode
      ? this.update()
      : this.insert();
  }

  private update() {
    this._loanPurposeService.updateLoanPurpose(this.loanPurpose)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Loan purpose updated successfully',
            'Loan Purpose'
          );
          this.onSave.emit(this.loanPurpose);
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't update selected loan purpose",
            'Loan Purpose'
          );
        }
      })
  }

  private insert() {
    this._loanPurposeService.insertLoanPurpose(this.loanPurpose)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Loan purpose added successfully',
            'Loan Purpose'
          );
          this.onSave.emit(res);
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't insert new loan purpose",
            'Loan Purpose'
          );
        }
      })
  }

  private setMultiSelectData() {
    this.multiselectChannels = this.enabledChannels?.map((channel) => ({
      id: channel.value,
      text: channel.name,
    }));
    this.multiselectChannelsModel = this._channelService
      .getChannelsFromCommaDelimitedString(this.loanPurpose.enabledChannels)
      .map((ch) => ch.name);
  }
}
