<div class="row">
    <div class="col-md-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link" (click)="tabId = 'routing'" [ngClass]="{'active': tabId === 'routing'}">
                    Routing
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="tabId = 'redistribution'"
                    [ngClass]="{'active': tabId === 'redistribution'}">
                    Redistribution
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="tabId = 'fetch'" [ngClass]="{'active': tabId === 'fetch' }">
                    Fetch
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="tabId = 'warm-transfer'"
                    [ngClass]="{'active': tabId === 'warm-transfer' }">
                    Warm Transfer
                </a>
            </li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane p-3" [ngClass]="{'active': tabId === 'routing' }">
                <routing-tab *ngIf="tabId === 'routing'" [userProfile]="userProfile"></routing-tab>
            </div>
            <div class="tab-pane p-3" [ngClass]="{'active': tabId === 'redistribution'}">
                <redistribution-tab *ngIf="tabId === 'redistribution'" [userProfile]="userProfile"></redistribution-tab>
            </div>
            <div class="tab-pane p-3" [ngClass]="{'active': tabId === 'fetch'}">
                <fetch-tab *ngIf="tabId === 'fetch'" [userProfile]="userProfile"></fetch-tab>
            </div>
            <div class="tab-pane px-3 pt-3" [ngClass]="{'active': tabId === 'warm-transfer'}">
                <warm-transfer-tab *ngIf="tabId === 'warm-transfer'" [userProfile]="userProfile"></warm-transfer-tab>
            </div>
        </div>
    </div>
</div>