import { Component, Injector, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Subscription } from 'rxjs';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user/user.model';
import { UserWithRoleName } from 'src/app/modules/loan-activity/models/user-with-role-name.model';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { SortPipe } from 'src/app/shared/pipes';
import { InternalTaskDialogComponent } from '../internal-task-dialog/internal-task-dialog.component';

@Component({
  selector: 'reassign-internal-contacts-dialog',
  templateUrl: './reassign-internal-contacts-dialog.component.html',
  styleUrls: ['./reassign-internal-contacts-dialog.component.scss']
})
export class ReassignInternalContactsDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  isLoading: boolean = true;

  selectedRows: Array<PipelineItem>;

  channelFilter: string;

  roles: Array<Role>;

  roleId: number = 0;

  usersInCompanyForContacts: Array<User>;

  usersAll: Array<UserWithRoleName>;

  users;

  selectedInternalContact: {
    userId: string,
    role: Role,
  } = {
      userId: '',
      role: new Role()
    };

  private _contextLoanInfoChangesSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _loanService: LoanService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _sortPipe: SortPipe,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    var filter = null;
    if (this.channelFilter == "1")
      filter = 'Retail';
    else if (this.channelFilter == "2")
      filter = 'Wholesale';
    else if (this.channelFilter == "4")
      filter = 'Broker';
    else if (this.channelFilter == "8")
      filter = 'Correspondent';
    else if (this.channelFilter == "16")
      filter = 'NonDelegatedCorrespondent';

    if (!this.applicationContext?.globalConfig) {
      this._contextLoanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.roles = context.globalConfig.roles.filter(x => filter == null || x.roleChannels.filter(y => y.channel == filter).length > 0);
        console.log(this.roles);
        this.usersInCompanyForContacts = context.globalConfig.users;
        this.usersAll = context.globalConfig.usersAll.filter(user => user.roleId);
        this.usersAll.forEach(user => {
          let role = this.roles.find(role => role.roleId == user.roleId);
          if (role && role.roleName) {
            user.roleName = role.roleName;
          }
        });
        this.users = _.groupBy(this.usersAll, 'roleId');
        this.isLoading = false;
      }, (err) => {
        console.log(err);
      }
      );
    } else {
      this.roles = this.applicationContext.globalConfig.roles.filter(x => filter == null || x.roleChannels.filter(y => y.channel == filter).length > 0);
      console.log(this.roles);
      this.usersInCompanyForContacts = this.applicationContext.globalConfig.users;
      this.usersAll = this.applicationContext.globalConfig.usersAll.filter(user => user.roleId);
      this.usersAll.forEach(user => {
        let role = this.roles.find(role => role.roleId == user.roleId);
        if (role && role.roleName) {
          user.roleName = role.roleName;
        }
      });
      this.users = _.groupBy(this.usersAll, 'roleId');
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._contextLoanInfoChangesSubscription) {
      this._contextLoanInfoChangesSubscription.unsubscribe();
    }
  }

  roleChanged = (event: any) => {
    this.selectedInternalContact.userId = '';
    let role = this.roles.find(role => role.roleId == event)
    this.selectedInternalContact.role = role;
    this.usersInCompanyForContacts = this.usersInCompanyForContacts.map((user) => {
      if (user.roleId && user.roleId == event) {
        user['order'] = 1;
      } else {
        user['order'] = 2;
      }
      return user;
    })
    this.usersInCompanyForContacts = this._sortPipe.transform(this.usersInCompanyForContacts, "asc", "lastName");
    this.usersInCompanyForContacts = this._sortPipe.transform(this.usersInCompanyForContacts, "asc", "order");
  }

  assignInternalContacts = () => {
    let internalTaskModalRef = this._modalService.open(InternalTaskDialogComponent, Constants.modalOptions.large);
    internalTaskModalRef.componentInstance.promptBranchUpdate =
      this.selectedInternalContact.role.roleName.toLowerCase() == 'loan officer';

    internalTaskModalRef.result.then((results) => {
      let promises = [];
      this.selectedRows.forEach(row => {
        promises.push(this._loanService.updateInternalContactForRole(row.applicationId,
          this.selectedInternalContact.role.roleId, this.selectedInternalContact.userId,
          results[0], results[1]));
      });

      this._spinner.show();
      forkJoin(promises).subscribe((response) => {
        this._notifyService.showSuccess("Internal contacts saved successfully.", "Success!");
        this.selectedInternalContact.userId = '';
        this.selectedInternalContact.role = new Role();
        this.activeModal.close();
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : "Error saving internal contacts.", "Error!");
      }).add(() => this._spinner.hide());
    }, (err) => {
      this.activeModal.dismiss();
    });
  }
}
