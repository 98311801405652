import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { DDAImportedFields, DDAKeyValuePairs } from '../ocr.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dda-vendor-editor-dialog',
  templateUrl: './dda-vendor-editor-dialog.component.html',
  styleUrls: ['./dda-vendor-editor-dialog.component.scss']
})
export class DdaVendorEditorDialogComponent implements OnInit {
  @ViewChild('credentialForm') credentialForm: NgForm | undefined;
  @Input() credential: ThirdPartyCredential;

  isSaving: boolean;
  importing: boolean;
  ddaKeyValuePairs: DDAKeyValuePairs = new DDAKeyValuePairs();
  ddaImportSuccess: boolean;

  constructor(
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (!this.credential.url) {
      this.credential.url = 'https://ws.docvelocity2.net';
    }
    const appSecretKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ApplicationSecret');
    this.ddaKeyValuePairs.applicationSecret = appSecretKeyValuePair?.value || '';

    const siteAddressKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'SiteAddress');
    this.ddaKeyValuePairs.siteAddress = siteAddressKeyValuePair?.value || '';

    const cabinetIdKeyValuePairs = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'CabinetId');
    this.ddaKeyValuePairs.cabinetId = cabinetIdKeyValuePairs?.value || '';

    const siteIdKeyValuePairs = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'SiteId');
    this.ddaKeyValuePairs.siteId = siteIdKeyValuePairs?.value || '';

    const gatewayBaseURLKeyValuePairs = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'GatewayBaseURL');
    this.ddaKeyValuePairs.gatewayBaseURL = gatewayBaseURLKeyValuePairs?.value || '';

    const uploadBaseURLKeyValuePairs = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'UploadBaseURL');
    this.ddaKeyValuePairs.uploadBaseURL = uploadBaseURLKeyValuePairs?.value || '';

    const docExplorerURLKeyValuePairs = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'DocExplorerURL');
    this.ddaKeyValuePairs.docExplorerURL = docExplorerURLKeyValuePairs?.value || '';

    const downloadBaseURLKeyValuePairs = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'DownloadBaseURL');
    this.ddaKeyValuePairs.downloadBaseURL = downloadBaseURLKeyValuePairs?.value || '';
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;
    if (!this.ddaImportSuccess && !this.credential.credentialId) {
      Swal.fire({
        title: 'Import Required',
        text: 'Cannot save credentials without import. Please click the import button to import data',
        icon: 'warning'
      });
      return;
    }
    this.isSaving = true;
    this.setThirdPartyKeyValuePairs();
    this._systemLevelService.saveCredential(this.credential)
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      }).add(() => this.isSaving = false);
  }

  import() {
    this.ddaImportSuccess = false;
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.importing = true;
    this._systemLevelService.importDDAData(
      this.credential.userName,
      this.ddaKeyValuePairs.applicationSecret,
      this.ddaKeyValuePairs.siteAddress
    ).subscribe({
      next: res => {
        this.ddaKeyValuePairs.siteId = res.siteId;
        this.ddaKeyValuePairs.gatewayBaseURL = res.gatewayBaseURL;
        this.ddaKeyValuePairs.uploadBaseURL = res.uploadBaseURL;
        this.ddaKeyValuePairs.docExplorerURL = res.docExplorerURL;
        this.ddaKeyValuePairs.downloadBaseURL = res.downloadBaseURL;
        this.ddaImportSuccess = true;
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to import from DDA .'}`, 'System Level');
      }
    }).add(() => this.importing = false);
  }

  private setThirdPartyKeyValuePairs() {
    let applicationSecretKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'ApplicationSecret');
    if (!applicationSecretKeyValuePair) {
      applicationSecretKeyValuePair = new ThirdPartyKeyValue("ApplicationSecret", this.ddaKeyValuePairs.applicationSecret);
      this.credential.thirdPartyKeyValuePairs.push(applicationSecretKeyValuePair);
    } else {
      applicationSecretKeyValuePair.value = this.ddaKeyValuePairs.applicationSecret;
    }

    let siteAddressKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'SiteAddress');
    if (!siteAddressKeyValuePair) {
      siteAddressKeyValuePair = new ThirdPartyKeyValue("SiteAddress", this.ddaKeyValuePairs.siteAddress);
      this.credential.thirdPartyKeyValuePairs.push(siteAddressKeyValuePair);
    } else {
      siteAddressKeyValuePair.value = this.ddaKeyValuePairs.siteAddress;
    }

    let cabinetIdKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'CabinetId');
    if (!cabinetIdKeyValuePair) {
      cabinetIdKeyValuePair = new ThirdPartyKeyValue("CabinetId", this.ddaKeyValuePairs.cabinetId);
      this.credential.thirdPartyKeyValuePairs.push(cabinetIdKeyValuePair);
    } else {
      cabinetIdKeyValuePair.value = this.ddaKeyValuePairs.cabinetId;
    }

    let siteIdKeyValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'SiteId');
    if (!siteIdKeyValuePair) {
      siteIdKeyValuePair = new ThirdPartyKeyValue("SiteId", this.ddaKeyValuePairs.siteId);
      this.credential.thirdPartyKeyValuePairs.push(siteIdKeyValuePair);
    } else {
      siteIdKeyValuePair.value = this.ddaKeyValuePairs.siteId;
    }

    let gatewayBaseURLValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'GatewayBaseURL');
    if (!gatewayBaseURLValuePair) {
      gatewayBaseURLValuePair = new ThirdPartyKeyValue("GatewayBaseURL", this.ddaKeyValuePairs.gatewayBaseURL);
      this.credential.thirdPartyKeyValuePairs.push(gatewayBaseURLValuePair);
    } else {
      gatewayBaseURLValuePair.value = this.ddaKeyValuePairs.gatewayBaseURL;
    }

    let uploadBaseURLValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'UploadBaseURL');
    if (!uploadBaseURLValuePair) {
      uploadBaseURLValuePair = new ThirdPartyKeyValue("UploadBaseURL", this.ddaKeyValuePairs.uploadBaseURL);
      this.credential.thirdPartyKeyValuePairs.push(uploadBaseURLValuePair);
    } else {
      uploadBaseURLValuePair.value = this.ddaKeyValuePairs.uploadBaseURL;
    }

    let docExplorerURLValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'DocExplorerURL');
    if (!docExplorerURLValuePair) {
      docExplorerURLValuePair = new ThirdPartyKeyValue("DocExplorerURL", this.ddaKeyValuePairs.docExplorerURL);
      this.credential.thirdPartyKeyValuePairs.push(docExplorerURLValuePair);
    } else {
      docExplorerURLValuePair.value = this.ddaKeyValuePairs.docExplorerURL;
    }

    let downloadBaseURLValuePair = this.credential.thirdPartyKeyValuePairs.find(p => p.key === 'DownloadBaseURL');
    if (!downloadBaseURLValuePair) {
      downloadBaseURLValuePair = new ThirdPartyKeyValue("DownloadBaseURL", this.ddaKeyValuePairs.downloadBaseURL);
      this.credential.thirdPartyKeyValuePairs.push(downloadBaseURLValuePair);
    } else {
      downloadBaseURLValuePair.value = this.ddaKeyValuePairs.downloadBaseURL;
    }
  }
}
