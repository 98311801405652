<loading-indicator
  *ngIf="loadingLoanSummary"
  [loadingMessage]="'Loading loan summary, please wait...'"
>
</loading-indicator>

<div [hidden]='loadingLoanSummary'>
  <div class="card" *ngIf="!inDrawer; else summaryInfo">
    <div class="card-body">
      <ng-container *ngTemplateOutlet="summaryInfo"></ng-container>
    </div>
  </div>

  <ng-template #summaryInfo>
    <ng-container *ngIf="loanPurposeTransactionType">
      <loan-summary-mortgage
        *ngIf="loanPurposeTransactionType != 'Solar' && loanPurposeTransactionType != 'HomeEquityInvestment'"
        (loadingStateChange)='onLoadingLoanStateChange($event)'
        #loanSummaryMortgage
      >
      </loan-summary-mortgage>
      <loan-summary-solar
        *ngIf="loanPurposeTransactionType == 'Solar'"
        (loadingStateChange)='onLoadingLoanStateChange($event)'
      ></loan-summary-solar>
      <loan-summary-hea
        *ngIf="loanPurposeTransactionType == 'HomeEquityInvestment'"
        (loadingStateChange)='onLoadingLoanStateChange($event)'
      ></loan-summary-hea>
    </ng-container>
  </ng-template>
</div>
