import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CustomData } from 'src/app/models';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { DiffChecker } from 'src/utils/diff-checker';

@Component({
  selector: 'lead-custom-data',
  templateUrl: './lead-custom-data.component.html',
  styleUrls: ['./lead-custom-data.component.scss']
})
export class LeadCustomDataComponent implements OnInit {

  @Input() lead: Lead;

  loading: boolean = false;
  isSaving: boolean = false;
  customData: CustomData = new CustomData();
  originalCustomData: CustomData = new CustomData();

  constructor(
    private _leadsService: LeadsService,
    private _notifyService: NotificationService
  ) {

  }

  ngOnInit(): void {
    if (!this.lead.leadId) return;

    this.loading = true
    this._leadsService.getCustomLeadData(this.lead.leadId).subscribe({
      next: (customData) => {
        this.customData = customData;
        this.originalCustomData = _.cloneDeep(customData);
      },
      error: (err) => {
        this._notifyService.showError("Error encountered while loading custom data of the lead", "Error!");
      }
    }).add(() => this.loading = false);
  }

  public isDirty = (): boolean => {

    const preparedCustomData = this.prepareArrayFields();

    const diffChecker = new DiffChecker(
      this.originalCustomData,
      preparedCustomData,
      'customData',
    );

    const ignoredFields = [
      'dateInserted', 'dateUpdated', 'insertedBy', 'updatedBy',
    ]

    if (!Object.keys(this.originalCustomData).includes(Constants.dirtyCheckIgnoreFieldsMetaDataField)) {
      Object.defineProperty(this.originalCustomData, Constants.dirtyCheckIgnoreFieldsMetaDataField, {
        value: ignoredFields,
        writable: true,
        configurable: true
      });
    }

    if (!Object.keys(preparedCustomData).includes(Constants.dirtyCheckIgnoreFieldsMetaDataField)) {
      Object.defineProperty(preparedCustomData, Constants.dirtyCheckIgnoreFieldsMetaDataField, {
        value: ignoredFields,
        writable: true,
        configurable: true
      });
    }

    return diffChecker.calculateDiff(true) != null;
  }

  public prepareArrayFields = (): CustomData => {
    const customData: CustomData = this.customData || {} as CustomData;

    this.prepareCustomData(customData, "customData1");
    this.prepareCustomData(customData, "customData2");
    this.prepareCustomData(customData, "customData3");
    this.prepareCustomData(customData, "customData4");
    this.prepareCustomData(customData, "customData5");
    this.prepareCustomData(customData, "customData6");
    this.prepareCustomData(customData, "customData7");
    this.prepareCustomData(customData, "customData8");
    this.prepareCustomData(customData, "customData9");
    this.prepareCustomData(customData, "customData10");

    return customData;
  }

  private prepareCustomData(customData: CustomData, type: "customData1" | "customData2" | "customData3" | "customData4" | "customData5" | "customData6" | "customData7" | "customData8" | "customData9" | "customData10") {
    if (Array.isArray(customData[type])) {
      customData[type] = JSON.stringify(customData[type])
    } else {
      customData[type] = customData[type];
    }
  }
}
