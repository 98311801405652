import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { User } from 'src/app/models/user/user.model';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { Constants } from 'src/app/services/constants';
import { FileService } from 'src/app/services/file.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ImportConditionsDialogBulkComponent } from '../import-conditions-dialog-bulk/import-conditions-dialog-bulk.component';
import { ImportConditionsDialogLDEComponent } from '../import-conditions-dialog-lde/import-conditions-dialog-lde.component';
import { ImportConditionsDialogComponent } from '../import-conditions-dialog/import-conditions-dialog.component';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'admin-condition-table',
  templateUrl: './admin-condition-table.component.html',
  styleUrls: ['./admin-condition-table.component.scss']
})
export class AdminConditionTableComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {


  @Input()
  appId: number;

  @Input()
  users: User[];

  @Input()
  context: ApplicationContext;

  globalConfig: GlobalConfig;

  filteredList: any = {};

  userCompanyGuid: string;
  applicationState: any = {};
  conditionCount: any = {};
  loanChannel: string;
  conditionBuckets: string[];
  conditionsTableData: any = {};
  loading = false;

  externalCompanyId: number;
  branchId: number;

  globalFilterFields = [
    'conditionType',
    'description',
    'conditionId',
    'requestDate',
    'note',
    'userName',
    'taskStatus',
    'conditionStatus',
    'reviewPartyName',
    'docTypeName',
  ]

  private _loanTaskChangesSubscription: Subscription;

  constructor(
    private readonly _taskService: TaskService,
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _fileService: FileService
  ) {
    super(injector);
    this.applicationState['Generic'] = 'Open';
    this.applicationState['Approval'] = 'Open';
    this.applicationState['Docs'] = 'Open';
    this.applicationState['Closing'] = 'Open';
    this.applicationState['Funding'] = 'Open';
    this.applicationState['Purchase'] = 'Open';
  }

  ngOnInit(): void {
    this.userCompanyGuid = this.context.currentlyLoggedInUser.userCompanyGuid;
    this.loanChannel = this.context.application.channel;
    this.branchId = this.context.application.branchId;
    this.externalCompanyId = this.context.application.externalCompanyId;
    this.globalConfig = this.context.globalConfig;

    this.reloadConditionTasks();

    this._loanTaskChangesSubscription = this.applicationContextService.loanTasksChanges.subscribe((context) => {
      this.reloadConditionTasks();
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanTaskChangesSubscription) {
      this._loanTaskChangesSubscription.unsubscribe();
    }
  }

  onViewFileClicked = (url: string) => {
    window.open(url);
  }

  reloadConditionTasks = () => {
    this.loading = true;
    this._taskService.getAllConditions(this.appId).subscribe({
      next: (response) => {
        this.conditionsTableData = response;
        this.conditionsTableData?.forEach(condition => {
          const user = this.users.find(user => user.userCompanyGuid == condition.userId);
          const reviewParty = this.users.find(user => user.userCompanyGuid == condition.reviewPartyId);
          const docType = this.globalConfig.documentType.find(dt => dt.documentTypeId === condition.documentTypeId);

          condition.userName = Utils.getPersonsDisplayName(user);
          condition.reviewPartyName = Utils.getPersonsDisplayName(reviewParty);
          condition.docTypeName = docType?.documentTypeName || "";
        });
        let cts = response.map(x => x.conditionType || 'Generic').reduce((a, b) => {
          if (!a.includes(b)) {
            a.push(b);
          }
          return a;
        }, []);


        this.conditionBuckets = [];
        this.conditionCount = {};
        if (cts.indexOf('Generic') > -1) {
          this.conditionBuckets.push('Generic');
          this.conditionCount['Generic'] = this.getConditionCountWithoutType();
        }
        if (cts.indexOf('Approval') > -1) {
          this.conditionBuckets.push('Approval');
          this.conditionCount['Approval'] = this.getConditionCountByType('Approval');
        }
        if (cts.indexOf('Docs') > -1){
          this.conditionBuckets.push('Docs');
          this.conditionCount['Docs'] = this.getConditionCountByType('Docs');
        }
        if (cts.indexOf('Closing') > -1){
          this.conditionBuckets.push('Closing');
          this.conditionCount['Closing'] = this.getConditionCountByType('Closing');
        }
        if (cts.indexOf('Funding') > -1){
          this.conditionBuckets.push('Funding');
          this.conditionCount['Funding'] = this.getConditionCountByType('Funding');
        }
        if (cts.indexOf('Purchase') > -1){
          this.conditionBuckets.push('Purchase');
          this.conditionCount['Purchase'] = this.getConditionCountByType('Purchase');
        }

        this.initialize();
      },
      error: (err) => { },
      complete: () => this.loading = false
    });
  }

  private getConditionCountByType = (type: string) => {
    return this.conditionsTableData?.filter(x => x.conditionType == type).length ?? 0;
  }

  private getConditionCountWithoutType = () => {
    return this.conditionsTableData?.filter(x => !x.conditionType).length ?? 0;
  }

  initialize(): void {
    this.conditionBuckets.forEach(key => {
      this.filterConditions(key, this.applicationState[key]);
    });
  }

  filterConditions = (key: string, filter: string) => {
    this.applicationState[key] = filter;
    this.filteredList[key] = [];

    switch (filter) {
      case "All":
        this.filteredList[key] = this.conditionsTableData?.filter(x => (x.conditionType ?? 'Generic') == key);
        break;
      case "Open":
        this.conditionsTableData?.filter(x => (x.conditionType || 'Generic') == key).forEach((item) => {
          if (item.conditionStatus === 'ConditionPending' || item.conditionStatus === 'ConditionReOpened')
            this.filteredList[key].push(item);
        });
        break;
      case "Cleared":
        this.conditionsTableData?.filter(x => (x.conditionType ?? 'Generic') == key).forEach((item) => {
          if (item.conditionStatus === 'ConditionCleared')
            this.filteredList[key].push(item);
        });
        break;
      case "NA":
        this.conditionsTableData?.filter(x => (x.conditionType ?? 'Generic') == key).forEach((item) => {
          if (item.conditionStatus === 'ConditionNotApplicable')
            this.filteredList[key].push(item);
        });
        break;
      case "Submitted":
        this.conditionsTableData?.filter(x => (x.conditionType ?? 'Generic') == key).forEach((item) => {
          if (item.conditionStatus === 'ConditionSubmitted')
            this.filteredList[key].push(item);
        });
        break;
    }

  }

  docFilesFilter = (task) => {
    return task.loanDocModel.docFiles.filter(x => !this.shouldFilter(task) || x.docFileType !== 'Normal')
  }

  shouldFilter = (task) => {
    return task.taskStatus !== 'Pending' && (task.taskType === 'EsignDocument' || task.taskType === 'LosEsign');
  }

  setConditionStatus = (loanDocTaskId: number, conditionStatus: string) => {
    this._taskService.setConditionStatus(loanDocTaskId, conditionStatus).subscribe((response) => {
      const index = this.conditionsTableData.findIndex(condition => condition.loanDocTaskId == loanDocTaskId);
      this.conditionsTableData[index] = Object.assign(this.conditionsTableData[index], response);

      Object.keys(this.conditionBuckets).forEach(key => this.filterConditions(key, this.applicationState[key]));
      this.reloadTasks();
    })
  }

  editConditionTaskModal = (loanDocTaskId: number) => {
    this._taskService.getTaskDashboardViewById(loanDocTaskId).subscribe((response) => {
      let modalOptions = _.cloneDeep(Constants.modalOptions.xlarge);
      modalOptions.windowClass = 'modal-w-90';
      const modalRef = this._modalService.open(EditTaskDialogComponent, modalOptions);
      modalRef.componentInstance.task = response;

      modalRef.result.then((result) => {
        if (result) {
          this.reloadTasks();
        }
      })
    });
  }

  exportConditions = (typeFilter: string) => {
    let data = {
      filter: typeFilter.toLowerCase(),
      exportFormat: 'conditionexportfilename'
    };
    this._fileService.exportConditions(this.appId, data).subscribe((response) => {

      let contentType = Constants.mimeTypes.octetStream;
      let blob = new Blob([response.body], { type: contentType });

      let urlCreator = window.URL || window.webkitURL;
      let url = urlCreator.createObjectURL(blob);

      let anchor = document.createElement('a');
      anchor.setAttribute('href', url);

      anchor.setAttribute('download', "Download.zip");

      let ev = document.createEvent("MouseEvents");
      ev.initMouseEvent("click", true, false, self, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      anchor.dispatchEvent(ev);
    }, (err) => {
      console.log(err);
    })
  }

  importFromLosModal = () => {
    const modal = this._modalService.open(ImportConditionsDialogComponent, Constants.modalOptions.xlarge);
    modal.componentInstance.appId = this.appId;
    modal.componentInstance.context = this.context;
    modal.result.then((result) => {
      console.log(result);
      if (result && result > 0) {
        //trigger task reload
        this.applicationContextService.updateLoanTasks();
      }
    }, (err) => {

    });
  }

  importFromLdeModal = () => {
    const modal = this._modalService.open(ImportConditionsDialogLDEComponent, Constants.modalOptions.xlarge);
    modal.componentInstance.appId = this.appId;
    modal.componentInstance.context = this.context;
    modal.result.then((result) => {
      if (result && result > 0) {
        //trigger task reload
        console.log("Triggering task Update");
        this.applicationContextService.updateLoanTasks();
      }
    }, (err) => {

    });
  }

  importFromBulkModal = () => {
    const modal = this._modalService.open(ImportConditionsDialogBulkComponent, Constants.modalOptions.fullScreen);
    modal.componentInstance.users = this.users?.filter(u => u.active) || [];
    modal.componentInstance.userCompanyGuid = this.userCompanyGuid;
    modal.componentInstance.appId = this.appId;
    modal.componentInstance.branchId = this.branchId;
    modal.componentInstance.externalCompanyId = this.externalCompanyId;
    modal.componentInstance.loanChannel = this.loanChannel;
    modal.componentInstance.globalConfig = this.globalConfig;
    modal.componentInstance.userPermissions = this.context.userPermissions;

    modal.result.then((result) => {
      this.applicationContextService.updateLoanTasks();
    }, (err) => {

    });
  }

  reloadTasks = () => {
    this.applicationContextService.updateLoanTasks();
  }

  onTaskNoteHovered = (task: LoanDocDashboardTask) => {
    this.conditionsTableData.forEach(row => {
      if (row.loanDocTaskId !== task.loanDocTaskId) {
        row['notesPopoverOpened'] = false;
      }
    });
  }
}
