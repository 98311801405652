<div class="modal-header">
  <h6 class="modal-title">Import Conditions</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
    (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body" style="max-height: 80vh;">
  <!-- paste area -->
  <form class="mb-2" #pasteAreaForm="ngForm">
    <div class="row mb-2">
      <div class="col-md-3">
        <label class="form-label">
          Special Character
          <span class="cursor--help" placement="top" [ngbTooltip]="specialCharacterTooltip" container="body">
            <i class="fas fa-question-circle text-info"></i>
          </span>
          <ng-template #specialCharacterTooltip>
            Special Character points a row that contains description.
          </ng-template>
        </label>
        <select
          class="form-control"
          name="specialCharacter"
          [(ngModel)]="specialCharacter"
          #specialCh="ngModel"
          [ngClass]="{'is-invalid' : specialCh && specialCh.touched && specialCh.invalid}"
          required
        >
          <option value="">-- Select One --</option>
          <option value="_">Lodash (_)</option>
          <option value=":">Colon (:)</option>
          <option value="-">Dash (-)</option>
        </select>
        <div class="invalid-feedback">Please select special character.</div>
      </div>
      <div class="col-md-3 d-flex align-items-end">
        <div class="form-check form-switch form-switch-success cursor">
          <input id="isSeparator" class="form-check-input cursor" type="checkbox" (change)="isSeparatorChecked = !isSeparatorChecked" />
          <label for="isSeparator" class="form-check-label cursor">Is separator</label>
          <span class="ms-1 cursor--help" placement="top" [ngbTooltip]="isSeparatorTooltip" container="body">
            <i class="fas fa-question-circle text-info"></i>
          </span>
          <ng-template #isSeparatorTooltip>
            When 'Is Separator' is checked a condition description and condition text will be considered to be separated by selected 'Special Character'.
            Otherwise the description will start from the 'Special Character'.
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-3">
        <label class="form-label">
          Condition Description Length (max)
          <span class="cursor--help" placement="top" [ngbTooltip]="maxDescriptionTooltip" container="body">
            <i class="fas fa-question-circle text-info"></i>
          </span>
          <ng-template #maxDescriptionTooltip>
            Max characters in a condition description. Omitted when zero.
          </ng-template>
        </label>
        <input id="maxDescriptionLength" numeric [allowNegative]="false" name="maxDescriptionLength" class="form-control col-md-5" [(ngModel)]="maxDescriptionLength" />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12">
        <textarea #textArea class="form-control" placeholder="Paste copied conditions here..." style="white-space: pre-wrap; min-height: 25vh; max-height: 60vh;"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-info" (click)="formatCopiedConditions()">Prepare Conditions</button>
      </div>
    </div>
  </form>

  <!-- info -->
  <div class="mb-2" *ngIf="conditionList && conditionList.length > 0">
    <div class="col-md-12 alert alert-dismissible d-flex-row align-items-center alert-outline-info" role="alert">
      <span>
        <strong>Info:</strong><br/>
        Please verify all your conditions to ensure that everything is correct before import.
        Expecially if selected text includes new pages or titles that are not a part of a condition.
      </span>
    </div>
  </div>

  <!-- error -->
  <div class="mb-2 col-md-12 alert alert-dismissible d-flex-row align-items-center alert-outline-danger"
    *ngIf="errorMessage && errorMessage.length > 0" role="alert">
    <strong>Errors:</strong>
    <br/>
    <span [innerHtml]="errorMessage.join('')"></span>
    <strong>Please make sure that all pasted conditions are correct.</strong>
  </div>

  <!-- table with conditions -->
  <div class="mb-2" *ngIf="conditionList && conditionList.length > 0">
    <div class="col-md-2 d-inline-block mb-2">
      <div class="form-check form-switch form-switch-success me-2 cursor">
        <label for="deselectedRows" class="form-check-label">
          Show Deselected Rows
        </label>
        <input
          id="deselectedRows"
          class="form-check-input"
          [(ngModel)]="areDeselectedRowsShown"
          (change)="toggleDeselectedRows()"
          type="checkbox"
        />
      </div>
    </div>
    <div class="col-md-2 d-inline-block mb-2">
      <div class="form-check form-switch form-switch-success me-2 cursor">
        <label for="enableEdit" class="form-check-label">
          Enable Table Edit
        </label>
        <input
          id="enableEdit"
          class="form-check-input"
          [(ngModel)]="isEditTableEnabled"
          type="checkbox"
        />
      </div>
    </div>

    <div class="col-sm-12">
      <form #tableDataForm="ngForm">
        <table class="table table-striped table-bordered table-hover no-footer dataTable" style="margin-top:15px;">
          <thead>
            <tr>
              <td style="width: 5%"><b>Select</b></td>
              <td style="width: 10%"><b>Description</b></td>
              <td style="width: 20%"><b>Condition Text</b></td>
              <td style="width: 15%"><b>Doc Type</b></td>
              <td style="width: 10%"><b>Responsible Party</b></td>
              <td style="width: 5%"><b>Review Required</b></td>
              <td style="width: 10%"><b>Review Party</b></td>
              <td style="width: 5%"><b>Request From Borrower</b></td>
              <td style="width: 10%"><b>Borrower Facing Note</b></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cond of filteredConditionList; let index = index" [ngClass]="{'text-danger': cond.isPotentialInvalid}">
              <!-- select -->
              <td class="text-center">
                <input type="checkbox" name="isSelected" (click)="toggleSelectedCondition(cond.description)" [checked]="cond.isSelected == true" />
              </td>

              <!-- description -->
              <td>
                <input class="form-control" *ngIf="isEditTableEnabled; else descriptionContent" name="description" [ngClass]="{'is-invalid': cond.isPotentialInvalid}" [(ngModel)]="cond.description" />
                <ng-template #descriptionContent>
                  <span class="text-ellipsis d-block" style="width: 80%" placement="top" [ngbTooltip]="description" container="body">
                    {{cond.description}}
                  </span>
                  <ng-template #description>
                    {{cond.description}}
                  </ng-template>
                </ng-template>
              </td>

              <!-- condition text -->
              <td>
                <textarea *ngIf="isEditTableEnabled; else textContent"
                  class="form-control"
                  name="conditionText"
                  [ngClass]="{'is-invalid': cond.isPotentialInvalid}"
                  [(ngModel)]="cond.conditionText"
                  style="height: 1em"
                ></textarea>
                <ng-template #textContent>
                  <span class="text-ellipsis d-block" style="width: 80%" placement="top" [ngbTooltip]="conditionText" container="body">
                    {{cond.conditionText}}
                  </span>
                  <ng-template #conditionText>
                    {{cond.conditionText}}
                  </ng-template>
                </ng-template>
              </td>

              <!-- doc type -->
              <td>
                <ng-select *ngIf="isEditTableEnabled; else docTypeContent" id="documentTypeId"
                  class="document-type"
                  bindLabel="documentTypeName" bindValue="documentTypeId" name="documentTypeId"
                  [(ngModel)]="cond.documentTypeId" [items]="documentTypes" placeholder="Select Doc Type"
                  appendTo="body" (ngModelChange)="onDocTypeChanged(cond)">
                </ng-select>
                <ng-template #docTypeContent>
                  <span class="text-ellipsis d-block" style="width: 80%" placement="top" [ngbTooltip]="displayDocTypeName" container="body">
                    {{cond.displayDocTypeName}}
                  </span>
                  <ng-template #displayDocTypeName>
                    {{cond.displayDocTypeName}}
                  </ng-template>
                </ng-template>
              </td>

              <!-- responsible party -->
              <td>
                <select *ngIf="isEditTableEnabled; else responsiblePartyContent" class="form-select" id="responsibleParty" [(ngModel)]="cond.userId" name="responsibleParty" (change)="onResponsiblePartyChanged(cond)">
                  <ng-container *ngFor="let user of notifyPartyLoanContacts">
                    <optgroup *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0" label="{{user.roleName}}">
                      <option class="user-select" value="{{user.userId}}" [selected]="user.userId == cond.userId">
                        {{user.lastName + ", " + user.firstName}}
                      </option>
                    </optgroup>
                  </ng-container>
                  <optgroup label="TPO All Users" *ngIf="isLoanTpo && externalCompanyUsers && externalCompanyUsers.length > 0">
                    <option class="user-select" *ngFor="let user of externalCompanyUsers"
                      [selected]="user.userCompanyGuid == cond.userId" value="{{user.userCompanyGuid}}">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </optgroup>
                  <optgroup label="All Branch Users" *ngIf="branchUsers && branchUsers.length > 0">
                    <option *ngFor="let user of branchUsers" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == cond.userId && !isInNotifyPartyList(cond.userId)">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </optgroup>
                  <optgroup label="All Users">
                    <option *ngFor="let user of users" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == cond.userId && !isInNotifyPartyList(cond.userId)">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </optgroup>
                </select>
                <ng-template #responsiblePartyContent>
                  <span class="text-ellipsis d-block" style="width: 80%" placement="top" [ngbTooltip]="displayUserName" container="body">
                    {{cond.displayUserName}}
                  </span>
                  <ng-template #displayUserName>
                    {{cond.displayUserName}}
                  </ng-template>
                </ng-template>
              </td>

              <!-- requiresReview -->
              <td>
                <input *ngIf="isEditTableEnabled; else requiresReviewContent"
                  id="requiresReview"
                  name="requiresReview"
                  class="form-check-input align-self-center m-0"
                  type="checkbox"
                  [(ngModel)]="cond.requiresReview"
                  (change)="onReviewRequiredChanged(cond)"
                />
                <ng-template #requiresReviewContent>
                  {{ cond.requiresReview | yesNo }}
                </ng-template>
              </td>
              <td>
                <select *ngIf="isEditTableEnabled && cond.requiresReview; else reviewPartyContent"
                  class="form-select"
                  id="reviewParty"
                  name="reviewParty"
                  [(ngModel)]="cond.reviewPartyId"
                  (change)="onReviewPartyChanged(cond)"
                  #reviewPartyId="ngModel"
                  [ngClass]="{'is-invalid' : reviewPartyId && reviewPartyId.touched && reviewPartyId.invalid}"
                  required
                >
                  <ng-container *ngFor="let user of notifyPartyLoanContacts">
                    <optgroup *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0"
                      label="{{user.roleName}}">
                      <option class="user-select" value="{{user.userId}}" [selected]="user.userCompanyGuid == cond.reviewPartyId">
                        {{user.lastName + ", " + user.firstName}}
                      </option>
                    </optgroup>
                  </ng-container>
                  <optgroup label="All Users">
                    <option *ngFor="let user of users | filter: {active: true}" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == cond.reviewPartyId && !isInNotifyPartyList(cond.reviewPartyId)">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </optgroup>
                </select>
                <div class="invalid-feedback">Review party is required.</div>
                <ng-template #reviewPartyContent>
                  <span class="text-ellipsis d-block" style="width: 80%" placement="top" [ngbTooltip]="displayReviewPartyName" container="body">
                    {{cond.displayReviewPartyName}}
                  </span>
                  <ng-template #displayReviewPartyName>
                    {{cond.displayReviewPartyName}}
                  </ng-template>
                </ng-template>
              </td>

              <!-- requestBorrower -->
              <td>
                <input *ngIf="isEditTableEnabled; else requestBorrowerContent"
                  id="requestBorrower"
                  name="requestBorrower"
                  class="form-check-input align-self-center m-0"
                  type="checkbox"
                  [(ngModel)]="cond.requestBorrower"
                  (change)="onRequestBorrowerChanged(cond)"
                />
                <ng-template #requestBorrowerContent>
                  {{ cond.requestBorrower | yesNo }}
                </ng-template>
              </td>
              <td>
                <textarea class="form-control" *ngIf="isEditTableEnabled && cond.requestBorrower; else borrowerFacingNoteContent" [(ngModel)]="cond.borrowerFacingNote" style="height: 1em;"></textarea>
                <ng-template #borrowerFacingNoteContent>
                  <span class="text-ellipsis d-block" style="width: 80%" placement="top" [ngbTooltip]="borrowerFacingNote" container="body">
                    {{cond.borrowerFacingNote}}
                  </span>
                  <ng-template #borrowerFacingNote>
                    {{cond.borrowerFacingNote}}
                  </ng-template>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
    (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="importConditions()" [disabled]="filteredConditionList.length === 0 || importing">
    <span *ngIf="importing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Import
  </button>
</div>
