<div class="modal-header">
  <h5 class="modal-title"> Security Code has been sent. Please enter it below... </h5>
</div>
<div class="modal-body">
  <form #securityForm="ngForm" class="row">
    <div class="col-md-12 form-group">
      <label class="form-label" for="company">Security Code</label>
      <label class="form-control" [ngClass]="{'is-invalid': code && code.touched && code.invalid}" style="position: relative;">
        <input
          class="security-code-input"
          type="text"
          name="securityCode"
          [(ngModel)]="securityCode"
          #code="ngModel"
          required
        />

        <div class="lock-icon">
          <span
            *ngIf="code && ((code.touched && !code.invalid )|| !code.touched)"
            style="cursor: pointer"
            placement="top"
            [ngbTooltip]="tooltipContent"
            container="body"
          >
            <i class="icon-append fa fa-lock"></i>
          </span>
  
          <ng-template #tooltipContent>
            Enter your Security Code
          </ng-template>
        </div>
      </label>
      <div class="invalid-feedback">Security code is required.</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-primary float-end" type="button" [disabled]="!securityCode" (click)="onOperationConfirmed()">
    <span *ngIf="isSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ isSending ? 'Sending...' : 'Submit'}}
  </button>
</div>
