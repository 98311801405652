import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, switchMap } from 'rxjs';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { TitleService } from 'src/app/services/title.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { IntegrationOrderTrackingDocumentEventData, IntegrationOrderTrackingEventTypeEnum, TitleOrderTrackingHistory } from './models/title-order-tracking-history';
import { TitleOrder, TitleOrderStatusEnum } from './models/title-order.model';

@Component({
  selector: 'title-history',
  templateUrl: './title-history.component.html',
  styleUrls: ['./title-history.component.scss']
})
export class TitleHistoryComponent implements OnInit {

  @Input() applicationId: number;

  histories: TitleOrderTrackingHistory[] = [];
  isLoading: boolean = false;

  globalFilterFields: string[] = ['thirdPartyOrderId', 'eventType', 'dateInserted'];

  eventTypes: EnumerationItem[] = [];
  selectedHistory: TitleOrderTrackingHistory = null;

  viewTitleHistoryDetailDrawerOptions: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: null
  }

  constructor(private readonly _titleService: TitleService,
    private readonly _enumerationService: EnumerationService,
    private readonly _drawerService: DrawerService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocsService: LoanDocsService) {
  }

  ngOnInit(): void {

    this.eventTypes = this._enumerationService.titleHistoryEventTypes;

    this.getOrderHistory();
  }

  getOrderHistory = () => {
    this.isLoading = true;
    this._titleService.getOrders(this.applicationId)
      .pipe(
        switchMap((orders: TitleOrder[]) => {
          let selectedOrder = null;

          orders = orders.filter(o => ![
            TitleOrderStatusEnum.Deleted,
            TitleOrderStatusEnum.Cancelled,
            TitleOrderStatusEnum.Archived
          ]
            .includes(o.orderStatus))

          if (orders.length > 0) {
            let mostRecentOrder = _.orderBy(orders, ["dateInserted"], ["desc"])[0];
            selectedOrder = mostRecentOrder;
          }

          if (selectedOrder) {
            return this._titleService.getTitleOrderHistory(selectedOrder.titleOrderId)
          }
          else {
            return of([]);
          }
        }))
      .subscribe({
        next: (histories: TitleOrderTrackingHistory[]) => {
          this.histories = histories;
        },
        error: () => {

        }
      })
      .add(() => {
        this.isLoading = false;
      })

  }

  getEventTypeName = (value: IntegrationOrderTrackingEventTypeEnum) => {
    return value ? this.eventTypes.find(t => t.value == value).name : null;
  }

  addDays = (selectedDate: Date, days: number): Date => {
    let result = new Date(selectedDate);
    result.setDate(result.getDate() + days);
    return result;
  }

  onViewDetailsClicked = (historyItem: TitleOrderTrackingHistory) => {
    this.selectedHistory = historyItem;
    this._drawerService.show("viewTitleHistoryDetailDrawer", 200)
  }

  isDocumentType = (eventType: IntegrationOrderTrackingEventTypeEnum): boolean => {
    return [
      IntegrationOrderTrackingEventTypeEnum.DocumentCreated,
      IntegrationOrderTrackingEventTypeEnum.DocumentUpdated,
      IntegrationOrderTrackingEventTypeEnum.OrderDeleted
    ].includes(eventType);
  }

  downloadDocument = (documentData: IntegrationOrderTrackingDocumentEventData) => {
    this._spinner.show();
    this._loanDocsService.getLoanDocContent(documentData.docFileGuid)
      .subscribe({
        next: (data) => {
          this._spinner.hide();
          const mimeType = "application/pdf";
          const blob = new Blob([data], { type: mimeType, });
          let downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          let fileName = documentData.fileName;
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: () => {
          this._spinner.hide();
        }

      })
  }

  viewDocument = (documentData: IntegrationOrderTrackingDocumentEventData) => {
    this._spinner.show();

    this._loanDocsService.getLoanDocContent(documentData.docFileGuid)
      .subscribe({
        next: (data) => {
          this._spinner.hide();
          const mimeType = "application/pdf";
          const blob = new Blob([data], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: () => {
          this._spinner.hide();
        }

      });

  }

}
