import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { DataService } from '../../../../core/services/data.service';
import { ThirdPartyCredential, ThirdPartyKeyValue } from '../../../../models';
import { Configuration } from '../../../../models/configuration.model';
import { NotificationService } from '../../../../services/notification.service';
import { EmailServiceModel } from '../../models';
import { DrawerOptions, DrawerSize } from 'src/app/shared/services/drawer.service';
import * as _ from 'lodash';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {

  @Input()
  emailService: EmailServiceModel;

  @Input()
  microsoftGraph?: ThirdPartyCredential;

  @Input()
  amazonWebServices?: ThirdPartyCredential;

  @Input()
  allowEmailImpersonation?: Configuration;

  @Input()
  restrictedUserEmailTemplateOverrides?: Configuration;

  @Input()
  type: 'branch' | 'system-level';

  @Input()
  companyId: number;

  @Output()
  settingsUpdated: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("emailServiceForm")
  emailServiceForm: NgForm | null

  tenantKvp: ThirdPartyKeyValue;
  awsRegionKvp: ThirdPartyKeyValue;

  emailServiceUserNameToDisplay: string;
  userNameToDisplay: string;
  users: any[] | null = null;
  awsRegions: EnumerationItem[] = [];
  childEmailImpersonationWindow: any;

  sendingTestEmail: boolean;
  discoveringUrl: boolean;
  autoDiscoverSuccess: boolean;
  sendTestEmailSuccess: boolean;
  sendTestEmailErrorMessage: string;
  isSaving: boolean;
  selectedEmails: string[] = [];

  editTemplateDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  };

  emailTemplateTypes: Array<any> = [
    { name: 'Borrower Invite', value: 'BorrowerInvite' },
    { name: 'Agent Invite', value: 'AgentInvite' },
    { name: 'Task Request', value: 'TaskRequest' },
    { name: 'Task Reminder', value: 'TaskReminder' },
    { name: 'OnlineAppRegistration', value: 'OnlineAppRegistration' }
  ];
  constructor(
    private readonly _dataService: DataService,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _enumService: EnumerationService,
    private readonly _profileService: ProfileService,
    private readonly _spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    if (!this.emailService) {
      this.emailService = new EmailServiceModel();
    }
    if (!this.emailService.serviceType) {
      this.emailService.serviceType = '';
    }
    this.emailServiceUserNameToDisplay = this.emailService.userName || '';
    this.userNameToDisplay = this.emailService.userName || '';;
    if (this.microsoftGraph) {
      this.tenantKvp = this.microsoftGraph.thirdPartyKeyValuePairs.find(kvp => kvp.key === 'TenantId');
      if (this.tenantKvp && !this.tenantKvp.userName) {
        this.tenantKvp.userName = this.emailService.userName;
      }
      this.getUsers();
    }
    this.setAmazonWebServices();
    if (this.restrictedUserEmailTemplateOverrides?.valueStr?.length > 0)
      this.selectedEmails = this.restrictedUserEmailTemplateOverrides?.valueStr.split(", ");
  }

  getUsers(): void {
    if (!this.tenantKvp?.value) {
      return;
    }
    this._dataService.get(`api/Email/Impersonation/Users?tenantId=${this.tenantKvp.value}`)
      .subscribe({
        next: res => {
          let userOptions = res;
          userOptions.forEach(u => u['lowerCaseEmail'] = u.email.toLowerCase());
          userOptions = _.orderBy(userOptions, ['email'], ['asc']);
          this.users = userOptions;
        }
      })
  }

  consentEmailImpersonation() {
    this._dataService.get(`api/Email/Impersonation/ConsentUrl?redirectUri=${window.location.origin}/microsoft-login-redirect`)
      .subscribe({
        next: res => {
          if (!res) return;
          this.childEmailImpersonationWindow = window.open(res.consentUrl, 'microsoftLoginRedirect');
          const interval = setInterval(() => {
            if (this.childEmailImpersonationWindow.closed) {
              clearInterval(interval);
              this.redirectWindowAfterClose();
            }
          }, 2000);
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to get consent url'}`, 'System Level');
        }
      })
  }

  redirectWindowAfterClose() {
    this.childEmailImpersonationWindow = null;
    const tenantGUID = window['tenantGUID']
    if (!tenantGUID) return;

    if (!this.microsoftGraph) {
      this.microsoftGraph = {
        vendorName: "MicrosoftGraph",
        credentialType: "Email",
        thirdPartyKeyValuePairs: [],
        userName: "",
        active: true,
        companyId: this.companyId
      }
    }
    this.tenantKvp = this.microsoftGraph.thirdPartyKeyValuePairs.find(kvp => kvp.key === "TenantId");
    if (!this.tenantKvp) {
      this.microsoftGraph.thirdPartyKeyValuePairs.push({ alias: '', password: '', userName: '', key: 'TenantId', value: tenantGUID });
      this.tenantKvp = this.microsoftGraph.thirdPartyKeyValuePairs.find(kvp => kvp.key === "TenantId");
    }
    this.tenantKvp.value = tenantGUID;
    this.getUsers();
    delete window['tenantGUID'];

    this._notificationService.showInfo("Please press save to persist all the changes", "Info");
  }

  handleUserChange(value): void {
    this.microsoftGraph.userName = value;
    this.emailService.userName = value;
    this.emailServiceUserNameToDisplay = JSON.parse(JSON.stringify(value.toLowerCase()));
  }

  sendTestEmail(emailServiceModel): void {
    let data = emailServiceModel;
    if (emailServiceModel.serviceType === 'ExchangeMicrosoftGraph') {
      data = {
        serviceType: 'ExchangeMicrosoftGraph',
        userName: emailServiceModel.userName
      };
    }

    let testingObserver;
    if (this.emailService.serviceType === "AmazonSes") {
      if (!this.amazonWebServices.userName || !this.amazonWebServices.password || !this.awsRegionKvp.value) {
        this._notificationService.showError("Please provide Account ID, Account Secret, Region and save", "Error");
        return;
      }
      testingObserver = this._systemLevelService.sendTestEmailForThirdParty(this.amazonWebServices, "AmazonSes", this.emailService.userName);
    } else {
      testingObserver = this._systemLevelService.sendTestEmail(data);
    }

    this.sendingTestEmail = true;
    testingObserver.pipe(finalize((() => this.sendingTestEmail = false)))
      .subscribe({
        next: () => {
          this.sendTestEmailSuccess = true;
          this.sendTestEmailErrorMessage = null;
        },
        error: (error) => {
          this.sendTestEmailSuccess = false;
          this.sendTestEmailErrorMessage = error.message;
        }
      });
  }

  save(): void {
    if (!this.emailServiceForm) return;
    this.emailServiceForm.form.markAllAsTouched();
    if (!this.emailServiceForm.form.valid) return;

    this.isSaving = true;
    const observables: any[] = [
      this._systemLevelService.saveEmailService(this.emailService),
      this._systemLevelService.saveConfiguration(this.allowEmailImpersonation),
      this._systemLevelService.saveConfiguration(this.restrictedUserEmailTemplateOverrides)
    ];
    if (this.emailService.serviceType === "ExchangeMicrosoftGraph" && this.microsoftGraph) {
      observables.push(this._systemLevelService.saveCredential(this.microsoftGraph));
    }
    if (this.emailService.serviceType === "AmazonSes" && this.amazonWebServices) {
      this.updateThirdPartyCredsForRegion();
      observables.push(this._systemLevelService.saveCredential(this.amazonWebServices));
    }

    forkJoin(observables)
      .pipe(finalize((() => this.isSaving = false)))
      .subscribe({
        next: res => {
          this.settingsUpdated.emit();
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }

  setRestrictedEmails(): void {
    this.restrictedUserEmailTemplateOverrides.valueStr = this.selectedEmails.length ? this.selectedEmails.join(', ') : '';
  }

  autoDiscoverExchangeUrl = () => {
    this._spinner.show();
    this._profileService.autoDiscoverExchangeUrl(this.emailService)
      .subscribe({
        next: (res) => {
          this._spinner.hide();
          this.emailService.servicesUrl = res.replace('"', '').replace('"', '');
          this._notificationService.showSuccess('Exchange url discovered successfully', 'Discover Success');
        },
        error: (error) => {
          this._spinner.hide();
          this._notificationService.showError(error ? error.message : 'Unable to discover exchange url', 'Discover Fail');
        }
      })
  }

  private updateThirdPartyCredsForRegion() {
    const matchingKvp = this.amazonWebServices.thirdPartyKeyValuePairs.find(kvp => kvp.key === 'AwsRegion');
    if (!matchingKvp) {
      this.amazonWebServices.thirdPartyKeyValuePairs.push(this.awsRegionKvp);
    } else {
      matchingKvp.value = this.awsRegionKvp.value;
    }
  }

  private setAmazonWebServices = () => {
    if (!this.amazonWebServices) return;

    if (!this.amazonWebServices.thirdPartyKeyValuePairs) {
      this.awsRegionKvp = new ThirdPartyKeyValue("AwsRegion", null)
      this.amazonWebServices.thirdPartyKeyValuePairs = [this.awsRegionKvp];
    } else {
      this.awsRegionKvp = this.amazonWebServices.thirdPartyKeyValuePairs.find(kvp => kvp.key === 'AwsRegion');
      if (!this.awsRegionKvp) {
        this.awsRegionKvp = new ThirdPartyKeyValue("AwsRegion", null);
        this.amazonWebServices.thirdPartyKeyValuePairs.push(this.awsRegionKvp);
      } else {
        this.awsRegionKvp.value = this.awsRegionKvp.value || null; // set default value
      }
    }
    this.awsRegions = this._enumService.awsRegions;
  }
}
