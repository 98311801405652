import { NewAppBorrowerModel } from "./new-app-borrower.model";

export class NewAppMortgageModel {
    mortgageId = 0;
    channel: string;
    applicationId = 0;
    subjectProperty  = {};
    mortgageTerm = {};
    transactionDetail = {};
    originatorInformation = {};
    extension = {};
    ausData = {};
    borrowers : Array<NewAppBorrowerModel> = [new NewAppBorrowerModel()];
}