import { Address } from '../app/models';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { merge } from 'lodash';

export interface AddressLookup {
  name: string;
  address_components: readonly AddressComponent[];
}

export interface AddressLookupResult {
  name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface AddressLookupOptions {
  /**
   * The properties to parse from the lookup.
   * This will be merged with the default properties.
   * So, the default properties will be overwritten by the properties passed in.
   * If no properties are passed in, the {@link defaultAddressLookupProperties}
   * will be used.
   */
  properties?: Partial<AddressLookupProperties>;

  /**
   * The fallback country to use if the country is not set, but the state is set. The component
   * should be restricted to a single country to get consistent results.
   */
  fallbackCountry?: string;
}

export enum AddressLookupProperty {
  Address1 = 'address1',
  Address2 = 'address2',
  City = 'city',
  County = 'county',
  State = 'state',
  ZipCode = 'zipCode',
  Country = 'country',
}

export interface AddressLookupProperties {
  [AddressLookupProperty.Address1]: string,
  [AddressLookupProperty.Address2]: string,
  [AddressLookupProperty.City]: string,
  [AddressLookupProperty.County]: string,
  [AddressLookupProperty.State]: string,
  [AddressLookupProperty.ZipCode]: string,
  [AddressLookupProperty.Country]: string,
}

export const defaultAddressLookupProperties: AddressLookupProperties =
  Object.freeze({
    address1: 'address1',
    address2: 'address2',
    city: 'city',
    county: 'county',
    state: 'state',
    zipCode: 'zipCode',
    country: 'country',
  });

interface AddressLookupParserParams {
  states: readonly string[];
  options?: AddressLookupOptions;
}

export class AddressLookupParser {
  private readonly _componentPatchers: Map<string, PatchAddressComponent>;

  constructor({ states, options }: AddressLookupParserParams) {
    this._componentPatchers = createAddressComponentPatchers({
      states,
      options: merge({}, {
        properties: defaultAddressLookupProperties,
      }, options),
    });
  }

  parseAddressLookup(lookup: AddressLookup): Partial<AddressLookupResult> {
    const addressComponents = lookup?.address_components;
    if (addressComponents == null) {
      console.error('Address components are missing.');
      return {};
    }

    const result = addressComponents.reduce((address, component) => {
      component.types.forEach((type) => {
        const patch = this._componentPatchers.get(type);
        if (patch != null) {
          patch(address, component);
        }
      });

      return address;
    }, {} as Partial<AddressLookupResult>);

    result.name = lookup.name;

    return result;
  }
}

type PatchAddressComponent = (
  address: Partial<Address>,
  component: AddressComponent,
) => void;

function createAddressComponentPatchers(
  {
    states,
    options,
  }: AddressLookupParserParams,
): Map<string, PatchAddressComponent> {
  const properties = options?.properties;

  const isValidProperty = (property: string): boolean => {
    return defaultAddressLookupProperties.hasOwnProperty(property);
  }

  const validateProperties = () => {
    Object.keys(properties).forEach((property) => {
      if (!isValidProperty(property)) {
        throw new Error(`Invalid property: ${property}`);
      }
    });
  }
  validateProperties();

  const patchLocality = (
    address: Partial<Address>,
    component: AddressComponent,
  ) => {
    address[properties.city] = component.long_name;
  };

  const statesSet = new Set(states.map((state) => state.toLowerCase()));

  const result = new Map<string, PatchAddressComponent>([
    [
      'street_number',
      (address: Partial<Address>, component: AddressComponent) => {
        address[properties.address1] = component.short_name;
      },
    ],
    [
      'route',
      (address: Partial<Address>, component: AddressComponent) => {
        address[properties.address1] = address[properties.address1] ?
          `${address[properties.address1]} ${component.short_name}` :
          component.short_name;
      },
    ],
    [
      'subpremise',
      (address: Partial<Address>, component: AddressComponent) => {
        address[properties.address2] = component.short_name;
      },
    ],
    ['locality', patchLocality],
    ['sublocality', patchLocality],
    [
      'administrative_area_level_1',
      (address: Partial<Address>, component: AddressComponent) => {
        const state = component.short_name.toLowerCase();
        if (statesSet.has(state)) {
          address[properties.state] = state;
        }
      },
    ],
    [
      'administrative_area_level_2',
      (address: Partial<Address>, component: AddressComponent) => {
        address[properties.county] = component.long_name;
      },
    ],
    [
      'country',
      (address: Partial<Address>, component: AddressComponent) => {
        address[properties.country] = component.short_name.toLowerCase();
      },
    ],
    [
      'postal_code',
      (address: Partial<Address>, component: AddressComponent) => {
        address[properties.zipCode] = component.long_name;
      },
    ],
  ]);

  // If the 'state' is set, but the 'country' is not, then assign the fallbackCountry (if it
  // exists).
  const fallbackCountry = options?.fallbackCountry;
  if (result.has(properties.state)
    && !result.has(properties.country)
    && fallbackCountry != null
  ) {
    result[properties.country] = fallbackCountry;
  }

  return result;
}
