<div class="row h-100">
    <ng-template [ngIf]="isRefinance" [ngIfElse]="notAplicableContent">
        <div class="col-sm-12 mb-3">
            <div class="row">
                <div class="form-group col-xs-12">
                    <label for="mortgagePandI">Mortgage P&I</label>
                    <currency-input name="mortgagePandI" (ngModelChange)="calculateTotalHousingPayment()"
                        [(ngModel)]="mortgage.currentHousingExpense.firstMortgagePrincipalAndInterest" (blur)="propertyOnBlur('firstMortgagePrincipalAndInterest')">
                    </currency-input>
                </div>
                <div class="form-group col-xs-12">
                    <label for="hoi">HOI</label>
                    <currency-input name="hoi" (ngModelChange)="calculateTotalHousingPayment()"
                        [(ngModel)]="mortgage.currentHousingExpense.homeownersInsurance" (blur)="propertyOnBlur('homeownersInsurance')">
                    </currency-input>
                </div>
                <div class="form-group col-xs-12">
                    <label for="propertyTaxes">Property Taxes</label>
                    <currency-input name="propertyTaxes" (ngModelChange)="calculateTotalHousingPayment()" (blur)="propertyOnBlur('realEstateTax')"
                        [(ngModel)]="mortgage.currentHousingExpense.realEstateTax">
                    </currency-input>
                </div>
                <div class="form-group col-xs-12">
                    <label for="mtgInsurance">Mtg Insurance</label>
                    <currency-input name="mtgInsurance" (ngModelChange)="calculateTotalHousingPayment()"
                        [(ngModel)]="mortgage.currentHousingExpense.mortgageInsurance" (blur)="propertyOnBlur('mortgageInsurance')">
                    </currency-input>
                </div>
                <div class="form-group col-xs-12">
                    <label for="hoa">HOA</label>
                    <currency-input name="hoa" (ngModelChange)="calculateTotalHousingPayment()"
                        [(ngModel)]="mortgage.currentHousingExpense.homeownersAssociationDuesAndCondominiumFees" (blur)="propertyOnBlur('homeownersAssociationDuesAndCondominiumFees')">
                    </currency-input>
                </div>
                <div class="form-group col-xs-12">
                    <label for="otherMortgagePandI">Other Mortgage P&I</label>
                    <currency-input name="otherMortgagePandI" (ngModelChange)="calculateTotalHousingPayment()"
                        [(ngModel)]="mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest" (blur)="propertyOnBlur('otherMortgageLoanPrincipalAndInterest')">
                    </currency-input>
                </div>
                <div class="form-group col-xs-12">
                    <label for="totalHousingPayment">Total Current Payment</label>
                    <currency-input name="totalHousingPayment" [readonly]="true" [(ngModel)]="totalHousingPayment">
                    </currency-input>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #notAplicableContent>
      <div class="d-flex justify-content-center align-items-center">
        <div class="alert alert-primary border-0 b-round" role="alert">
          Not applicable for this loan type!
        </div>
      </div>
    </ng-template>
</div>
