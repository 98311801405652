<div class="modal-header">
  <h5 class="modal-title"> Real Estate Tax Breakdown </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form novalidate id='breakdownForm' name='breakdownForm'>
    <div class="loan-app" style="padding-left: 60px;">
      <div class="grid-layout edit-area">
        <div class="grid-layout-row">
          <div class='col'>
            <div class='row breakdown-row'>
              <div class='col-3'>
                State Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='statePropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.statePropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                County Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='countyPropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.countyPropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                District Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='districtPropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.districtPropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                Borough Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='boroughPropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.boroughPropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                City Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='cityPropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.cityPropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                Town Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='townPropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.townPropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'></urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                Village Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='villagePropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.villagePropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
            <div class='row breakdown-row'>
              <div class='col-3'>
                School Property Tax
              </div>
              <div class='col-6'>
                <urla-currency-input class='breakdown-input' name='schoolPropertyTax'
                                     [label]="''" [(ngModel)]='proposedHousingExpenses.schoolPropertyTax'
                                     (ngModelChange)='onBreakdownItemChanged()'>
                </urla-currency-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2" *ngIf="!isEscrowWaived">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Taxes
          </h4>
        </div>
        <div class="card-body">
          <div class="loan-app">
            <div class="grid-layout">
              <div class="grid-layout-row">
                <urla-percentage-input class="col-sm-6 col-xs-6 negative-margin-5"
                  label="Real Estate Tax Assessed Total" [(ngModel)]="subjectProperty.realEstateTaxAssessedTotal"
                  [name]="'realEstateTaxAssessedTotal'">
                </urla-percentage-input>
                <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5"
                  [name]="'realEstateTaxAuthorityAccountBillIdentifier'" [label]="'Real Estate Tax Authority Acc...'"
                  [(ngModel)]="subjectProperty.realEstateTaxAuthorityAccountBillIdentifier">
                </urla-text-input>
                <urla-text-input class="col-sm-6 col-xs-6 negative-margin-5" [name]="'realEstateTaxContractIdentifier'"
                  [label]="'Real Estate Tax Contract Identifier'"
                  [(ngModel)]="subjectProperty.realEstateTaxContractIdentifier">
                </urla-text-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      Total: <b>{{ proposedHousingExpenses.realEstateTax | currency :'USD':'symbol':'1.2-2' }}</b>
    </div>
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
        <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
</div>
