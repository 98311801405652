import {
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  ProductSearchRequest
} from '../../../../models/pricing/product-search-request-info.model';
import { PricingParametersBase } from '../pricing-parameters-base.component';

@Component({
  selector: 'pricing-parameters-v2',
  templateUrl: './pricing-parameters-v2.component.html',
  styleUrls: ['./pricing-parameters-v2.component.scss'],
})
export class PricingParametersV2Component extends PricingParametersBase implements OnInit {

  @ViewChild('searchForm')
  searchForm: NgForm;

  @Input()
  set productSearchRequest(request: ProductSearchRequest) {
    this._productSearchRequest = request;
  }

  private _productSearchRequest: ProductSearchRequest;

  get productSearchRequest(): ProductSearchRequest {
    return this._productSearchRequest;
  }

  isLoanInfoSectionInvalid: boolean = false;
  isBorrowerInfoSectionInvalid: boolean = false;
  isPropertyInfoSectionInvalid: boolean = false;
  isProductSearchCriteriaSectionInvalid: boolean = false;
  isAdditionalInfoSectionInvalid: boolean = false;
  isCustomSectionInvalid: boolean = false;

  constructor(private readonly injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();

    this._productSearchRequest = await this.getRequest();
  }

  validate = (): boolean => {
    this.isLoanInfoSectionInvalid = false;
    this.isBorrowerInfoSectionInvalid = false;
    this.isPropertyInfoSectionInvalid = false;
    this.isProductSearchCriteriaSectionInvalid = false;
    this.isAdditionalInfoSectionInvalid = false;
    this.isCustomSectionInvalid = false;
    let firstInvalidOneId: string = null;
    if (this.searchForm) {
      this.searchForm.form.markAllAsTouched();
      const isValid = this.searchForm.form.valid;
      if (!isValid) {
        for (var key in this.searchForm.form.controls) {
          if (this.searchForm.form.controls.hasOwnProperty(key)) {
            if (this.searchForm.form.controls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
      }
      if (firstInvalidOneId) {
        this.scrollToElement(firstInvalidOneId);
      }
      return isValid;
    }
    return false;
  };

  private scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const section = element.getAttribute('section');
      setTimeout(() => {
        this.isLoanInfoSectionInvalid = section === 'loan-information';
        this.isBorrowerInfoSectionInvalid = section === 'borrower-info';
        this.isPropertyInfoSectionInvalid = section === 'property-info';
        this.isProductSearchCriteriaSectionInvalid =
          section === 'product-search-criteria';
        this.isAdditionalInfoSectionInvalid = section === 'additional-info';
        this.isCustomSectionInvalid = section === 'custom';
        setTimeout(() => {
          const parentElement = element.parentElement;
          let el = parentElement ? parentElement : element;
          if (el.innerText === "This field cannot be zero") {
            el = el.parentElement.parentElement ? el.parentElement.parentElement : el;
          }
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }, 150);
      }, 250);
    }
  };

}


