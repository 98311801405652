import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Address, ApplicationContext, MortgageBorrower, ResidencyAddress, ResidencyType } from 'src/app/models';
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { BorrowerType } from 'src/app/modules/leads/models/lead-credit.model';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { BorrowersService } from '../../services/borrowers.service';

@Component({
  selector: 'add-borrower',
  templateUrl: './add-borrower.component.html',
  styleUrls: ['./add-borrower.component.scss']
})
export class AddBorrowerComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() isMortgage: boolean = false;

  @Input() loanId: number | null = null;
  @Input() mortgageId: number | null = null;

  @Output() savedBorrower = new EventEmitter<MortgageBorrower>();

  companyId: number | null = null;

  private _loaninfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _borrowerService: BorrowersService,
    private readonly _mortgageService: MortgageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _router: Router) {
    super(injector);

    this._loaninfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      this.initialize(context);
    });

    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._loaninfoChangesSubscription) {
      this._loaninfoChangesSubscription.unsubscribe();
    }
  }

  onBorrowerSaved = (borrower: BorrowerFull) => {
    // When the borrower is created and saved, add that borrower to the loan IFF loanId is NOT null
    if (!this.loanId) {
      return;
    }

    if (this.isMortgage) {

      let newMortgageBorrower = new MortgageBorrower();

      // TODO by Kaan: This is screwed up!! How can you convert a contact to a mortgage borrower like this?
      // There are several other fields on the UI that is used that is NOT translated to the morgtgage borrower.
      // This is bad design...
      newMortgageBorrower = Object.assign(newMortgageBorrower, borrower.borrower);
      newMortgageBorrower.primaryEmail = borrower.borrower.email;
      newMortgageBorrower.dateOfBirth = borrower.borrower.birthDate?.toString();
      newMortgageBorrower.typeOfBorrower = borrower.borrower.isPrimary ? BorrowerType.PrimaryBorrower : BorrowerType.CoBorrower;

      if (borrower.borrower.mailingStreet) {
        newMortgageBorrower.residencyAddresses = [new ResidencyAddress()];
        newMortgageBorrower.residencyAddresses[0].residencyType = ResidencyType.MailingAddress;
        newMortgageBorrower.residencyAddresses[0].address = new Address();
        newMortgageBorrower.residencyAddresses[0].address.address1 = borrower.borrower.mailingStreet;
        newMortgageBorrower.residencyAddresses[0].address.city = borrower.borrower.mailingCity;
        newMortgageBorrower.residencyAddresses[0].address.state = borrower.borrower.mailingState;
        newMortgageBorrower.residencyAddresses[0].address.zipCode = borrower.borrower.mailingZip;
        newMortgageBorrower.residencyAddresses[0].address.county = borrower.borrower.mailingCounty;
      }

      this._spinner.show();
      this._mortgageService.insertBorrowerToMortgage(this.mortgageId, newMortgageBorrower).subscribe((res) => {
        this._spinner.hide();
        this._notifsService.showSuccess(
          'Borrower has been successfully added to the mortgage.',
          'Success!'
        );

        if (Array.isArray(res.declarations)) {
          res.declarations = res.declarations[0];
        }

        if (Array.isArray(res.currentHousingExpenses)) {
          res.declarations = res.currentHousingExpenses[0];
        }

        if (Array.isArray(res.governmentMonitors)) {
          res.governmentMonitors = res.governmentMonitors[0];
        }

        this.savedBorrower.emit(res);

      }, error => {

        this._notifsService.showError(
          error ? error.message : 'Unable to add borrower to mortgage.',
          'Error!'
        );
        this._spinner.hide();
      });
    }
    else {
      this._spinner.show();
      this._borrowerService.selectBorrower(this.loanId, borrower.borrower.borrowerId).subscribe(() => {
        this._spinner.hide();
        this._notifsService.showSuccess(
          'Borrower has been successfully added to the loan.',
          'Success!'
        );
        this.applicationContextService.updateBorrowers().subscribe(result => {
          this._router.navigate(['admin/app-details/' + this.loanId]);
        });
      }, error => {

        this._notifsService.showError(
          error ? error.message : 'Unable to add borrower to loan.',
          'Error!'
        );
        this._spinner.hide();
      });
    }
  }

  private initialize = (context: ApplicationContext) => {
    if (!this.isMortgage) {
      this.loanId = context.application.applicationId;
    }
    this.companyId = context.application.companyId;
  }
}
