import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { LoanType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { DeleteWithReplacementConfirmationDialogComponent } from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import { LoanTypeService } from '../../services';
import { Constants } from 'src/app/services/constants';

@Component({
  templateUrl: 'loan-type.component.html',
})
export class LoanTypeComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  @ViewChild(Table)
  loanTypeTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('loanTypeOrderDrawer')
  loanTypeOrderDrawer: DrawerComponent;

  @ViewChild('loanTypeOrder')
  loanTypeOrder: RearrangeOrderComponent

  loanTypeColumns = [];
  loanTypes: Array<LoanType>;
  mortgageAppliedForTypes: Array<EnumerationItem>;
  itemsToOrder: Array<{ name: string, value: number }> = [];

  companyId: number;

  allowAddNew: boolean;

  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }
  currentLoanType: Partial<LoanType>;
  upsertLoanTypeDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  constructor(
    private readonly injector: Injector,
    private readonly _loanTypeService: LoanTypeService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _enumerationService: EnumerationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.loanTypeColumns = [
      { field: 'loanTypeName', header: 'Loan Type', sortable: true },
      { field: 'showInOnlineApp', header: 'Show In Online App', sortable: true },
      { field: 'defaultForOnlineApp', header: 'Default For Online App', sortable: true },
      { field: 'mortgageAppliedForType', header: 'Mortgage Applied For Type', sortable: true },
      { field: 'order', header: 'Sort', sortable: true },
    ];
    this.applicationContextService.context.subscribe((res) => {
      this.companyId = res.userPermissions.companyId;
      this.allowAddNew = res.userPermissions.superAdmin ? false : true;
      this.populateLoanTypes();
    });

    this._enumerationService.getMortgageEnumerations().subscribe((result) => {
      this.mortgageAppliedForTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
    });
  }

  showUpsertDialog(row: LoanType) {
    this.currentLoanType = cloneDeep(row) || {};
    this._drawerService.show("upsertLoanTypeDrawer", 100);
  }

  onUpsertLoanTypeDrawerClose(updateLoanType: LoanType) {
    if (!updateLoanType) {
      this._drawerService.hide("upsertLoanTypeDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertLoanTypeDrawer", 100);
    this.updateTableAfterUpsert(updateLoanType);
  }

  showDeleteDialog(rowData: LoanType) {
    const modalRef = this._modalService.open(
      DeleteWithReplacementConfirmationDialogComponent
    );
    modalRef.componentInstance.ItemsList = this.loanTypes
      .filter((ls) => rowData.loanTypeId !== ls.loanTypeId)
      .map((ls) => ({
        value: ls.loanTypeId,
        name: ls.loanTypeName,
      }));
    modalRef.componentInstance.itemType = 'loan type';
    modalRef.result.then((assignedLoanTypeId: string) => {
      this.deleteRow(rowData, parseInt(assignedLoanTypeId));
    },
      () => { }
    );
  }

  openOrderDrawer() {
    if (!this.loanTypes.length) {
      return;
    }

    this.itemsToOrder = this.setLoanTypesItems();
    this._drawerService.show("loanTypeOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedLoanTypeses: Array<EnumerationItem>) {
    this.loanTypeOrder.saveInProgress = true;

    const sortedLoanTypeIds = sortedLoanTypeses.map((r) => ({
      id: r.value,
    }));

    this._loanTypeService
      .updateLoanTypeOrder(sortedLoanTypeIds, this.companyId)
      .pipe(finalize(() => {
        this.loanTypeOrder.saveInProgress = false;
        this.closeOrderDrawer();
      })
      )
      .subscribe({
        next: () => {
          this.loanTypeTable.sortField = "";
          this.loanTypeTable.sortOrder = 0;
          this.loanTypeTable.reset();
          this.gridHeaderActionBar.search.nativeElement.value = '';
          this.populateLoanTypes();

          this._notificationService.showSuccess(
            'Loan type order updated successfully',
            'Loan Type'
          );
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to update loan type order ',
            'Loan Type'
          );
        }
      });
  }

  closeOrderDrawer() {
    this._drawerService.hide("loanTypeOrderDrawer", 100);
  }

  displayAppliedMortgageForType(value: string): string {
    return this.mortgageAppliedForTypes.find((m) => m.value === value)?.name;
  }

  private populateLoanTypes() {
    this._spinner.show();
    this._loanTypeService.getAllLoanTypes(this.companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this.loanTypes = res;
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error ? error.message : "Couldn't load loan statuses.",
            'Loan Status'
          );
        }
      });
  }

  private updateTableAfterUpsert(result: LoanType) {
    const index = this.loanTypes.findIndex((ls) => ls.loanTypeId === result.loanTypeId);
    if (index === -1) {
      this.loanTypes.push(result);
    } else {
      this.loanTypes[index] = result;
    }
    this.loanTypes = [...this.loanTypes];
  }

  private deleteRow(rowData: LoanType, assignedLoanTypeId: number) {
    this._loanTypeService.deleteLoanType(rowData.loanTypeId, this.companyId, assignedLoanTypeId)
      .subscribe({
        next: (res) => {
          const index = this.loanTypes.findIndex((ls) => ls.loanTypeId === rowData.loanTypeId);
          this.loanTypes.splice(index, 1);
          this.loanTypes = [...this.loanTypes];
          this._notificationService.showSuccess(
            'Loan status removed succesfully.',
            'Loan Status'
          );
        },
        error: (err) =>
          this._notificationService.showError(
            err?.message || "Couldn't delete loan status",
            'Loan Status'
          )
      });
  }

  private setLoanTypesItems() {
    return chain(this.loanTypes)
      .orderBy((ls) => ls.order)
      .map((ls) => ({
        name: ls.loanTypeName,
        value: ls.loanTypeId,
      }))
      .value();
  }
}
