<form #upsertCocReasonForm="ngForm" ngbAutofocus class="row">
  <div class="p-3">
    <div class="mb-3">
      <label class="control-label">Title</label>
      <input class="form-control" [ngClass]="{ 'is-invalid' : title && title.touched && title.invalid }" name="title"
        [(ngModel)]="cocReason.title" #title="ngModel" required />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="mb-3">
      <label class="control-label">Disclosure Reason</label>
      <select class="form-select"
        [ngClass]="{ 'is-invalid' : disclosureReason && disclosureReason.touched && disclosureReason.invalid }"
        name="disclosureReason" [(ngModel)]="cocReason.disclosureReason" #disclosureReason="ngModel" required>
        <option [ngValue]="null"> -- Select One -- </option>
        <option *ngFor="let opt of disclosureReasons" [ngValue]="opt.value">{{opt.name}}</option>
      </select>
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="mb-3" *ngIf="cocReason?.disclosureReason == 'Other'">
      <label class="control-label">Disclosure Reason Other Description</label>
      <input class="form-control"
        [ngClass]="{ 'is-invalid' : otherDescription && otherDescription.touched && otherDescription.invalid }"
        name="otherDescription" [(ngModel)]="cocReason.disclosureReasonOtherDescription"
        #otherDescription="ngModel" required />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="mb-3">
      <label class="control-label">Reason Category</label>
      <select id="reasonType" name="reasonType" class="form-select" [(ngModel)]="cocReason.reasonCategory"
        [ngClass]="{'is-invalid': category && category.touched && category.invalid}"
        #category="ngModel" required>
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let reasonCategory of reasonCategories" [ngValue]="reasonCategory.value">
          {{ reasonCategory.name }}
        </option>
      </select>
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="mb-3">
      <label class="control-label">Default Comments</label>
      <textarea rows="3" id="defaultComments" name="defaultComments" class="form-control"
        [(ngModel)]="cocReason.defaultComments" #defaultCommentsInput="ngModel">
      </textarea>
      <div class="invalid-feedback">This field is required.</div>
    </div>

    <div class="text-end">
      <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
        Close
      </button>
      <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" [disabled]="saving">
        <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
        Save
      </button>
    </div>
  </div>
</form>
