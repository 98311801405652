<div class="card">
  <div class="card-header">
    <h4 class="card-title">Wire Request Info</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_requestedDate">Requested Date</label>
        <date-input
          [name]="'wireReq_requestedDate'"
          [id]="'wireReq_requestedDate'"
          [(ngModel)]="wireRequest.requestedDate"
          [required]="true"
        ></date-input>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_warehousePrincipal">Warehouse Principal</label>
        <currency-input
          id="wireReq_warehousePrincipal"
          name="wireReq_warehousePrincipal"
          [(ngModel)]="wireRequest.warehousePrincipal"
          [allowNegative]="false"
          [required]="true"
        ></currency-input>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_fundsToSendAmount">Funds To Send Amount</label>
        <currency-input
          id="wireReq_fundsToSendAmount"
          name="wireReq_fundsToSendAmount"
          [(ngModel)]="wireRequest.fundsToSendAmount"
          [allowNegative]="false"
          [required]="true"
        ></currency-input>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_investorName">Investor Name</label>
        <select
          name="wireReq_investorName"
          id="wireReq_investorName"
          class="form-select"
          [ngClass]="{'is-invalid': investorName && investorName.touched && investorName.invalid}"
          [(ngModel)]="wireRequest.investorName"
          #investorName="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let investorName of investorNames" value="{{investorName.value}}">{{investorName.name | spinalTapCasePipe}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_noteDate">Note Date</label>
        <date-input
          [name]="'wireReq_noteDate'"
          [id]="'wireReq_noteDate'"
          [(ngModel)]="wireRequest.noteDate"
          [required]="true"
        ></date-input>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_noteAmount">Note Amount</label>
        <input
          id="wireReq_noteAmount"
          name="wireReq_noteAmount"
          type="number"
          class="form-control"
          [ngClass]="{'is-invalid': noteAmount && noteAmount.touched && noteAmount.invalid}"
          [(ngModel)]="wireRequest.noteAmount"
          #noteAmount="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_noteTermInMonths">Note Term In Months</label>
        <input
          id="wireReq_noteTermInMonths"
          name="wireReq_noteTermInMonths"
          type="number"
          class="form-control"
          [ngClass]="{'is-invalid': noteTermInMonths && noteTermInMonths.touched && noteTermInMonths.invalid}"
          [(ngModel)]="wireRequest.noteTermInMonths"
          #noteTermInMonths="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_noteRate">Note Rate</label>
        <percent-input
          [digitsInfo]="'1.3-3'"
          id="wireReq_noteRate"
          name="wireReq_noteRate"
          [(ngModel)]="wireRequest.noteRate"
          [required]="true"
        ></percent-input>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_takeoutExpirationDate">Takeout Expiration Date</label>
        <date-input
          [name]="'wireReq_takeoutExpirationDate'"
          [id]="'wireReq_takeoutExpirationDate'"
          [(ngModel)]="wireRequest.takeoutExpirationDate"
          [required]="true"
        ></date-input>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_mersNumber">MERS</label>
        <input
          id="wireReq_mersNumber"
          name="wireReq_mersNumber"
          type="number"
          class="form-control"
          [ngClass]="{'is-invalid': mersNumber && mersNumber.touched && mersNumber.invalid}"
          [(ngModel)]="wireRequest.mersNumber"
          #mersNumber="ngModel"
          inputMaxLength="18"
          numeric
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-2 mt-3">
        <label for="wireReq_eNote">eNote</label>
        <select
          name="wireReq_eNote"
          id="wireReq_eNote"
          class="form-select"
          [ngClass]="{'is-invalid': eNote && eNote.touched && eNote.invalid}"
          [(ngModel)]="wireRequest.eNote"
          #eNote="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option [ngValue]="true">Yes</option>
          <option [ngValue]="false">No</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_closingAgentPrimaryBankAbaRoutingNumber">
          Agent Primary Bank Routing
        </label>
        <input
          id="wireReq_closingAgentPrimaryBankAbaRoutingNumber"
          name="wireReq_closingAgentPrimaryBankAbaRoutingNumber"
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': closingAgentPrimaryBankAbaRoutingNumber && closingAgentPrimaryBankAbaRoutingNumber.touched && closingAgentPrimaryBankAbaRoutingNumber.invalid}"
          [(ngModel)]="wireRequest.closingAgentPrimaryBankAbaRoutingNumber"
          #closingAgentPrimaryBankAbaRoutingNumber="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_closingAgentFinalBankAccountNumber">
          Agent Final Bank Account
        </label>
        <input
          id="wireReq_closingAgentFinalBankAccountNumber"
          name="wireReq_closingAgentFinalBankAccountNumber"
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': closingAgentFinalBankAccountNumber && closingAgentFinalBankAccountNumber.touched && closingAgentFinalBankAccountNumber.invalid}"
          [(ngModel)]="wireRequest.closingAgentFinalBankAccountNumber"
          #closingAgentFinalBankAccountNumber="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
  
      <div class="col-sm-6 col-md-4 col-lg-3 mt-3">
        <label for="wireReq_closingAgentOrderNumber">
          Agent Order Number
        </label>
        <input
          id="wireReq_closingAgentOrderNumber"
          name="wireReq_closingAgentOrderNumber"
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': closingAgentOrderNumber && closingAgentOrderNumber.touched && closingAgentOrderNumber.invalid}"
          [(ngModel)]="wireRequest.closingAgentOrderNumber"
          #closingAgentOrderNumber="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
  </div>
</div>