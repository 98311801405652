<h4 *ngIf="!smsAlertsEnabled">Call to enable this feature!</h4>
<div class="accordion" *ngIf="smsAlertsEnabled">
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-borrower-invite" aria-expanded="true" aria-controls="collapse1">
        Borrower Invite Template <span *ngIf="definedSmsTemplate.inviteOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="sms-borrower-invite" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-invite-override" type="checkbox"
                [(ngModel)]="definedSmsTemplate.inviteOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.inviteOverride, 'BorrowerInvite', 'inviteSms')">
              <label class="form-check-label" for="sms-invite-override">Override Borrower Invite Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.inviteOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.inviteOverride, 'BorrowerInvite', 'inviteSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.inviteSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'inviteSms')"> </textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${RegistrationLink} to auto merge the borrower registration link into a custom button.'">
            </div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-agent-invite" aria-expanded="true" aria-controls="collapse1">
        Agent Invite Template <span *ngIf="definedSmsTemplate.inviteAgentOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="sms-agent-invite" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-invite-agent-override" type="checkbox"
                [(ngModel)]="definedSmsTemplate.inviteAgentOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.inviteAgentOverride, 'AgentInvite', 'inviteAgentSms')">
              <label class="form-check-label" for="sms-invite-agent-override">Override Agent Invite Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.inviteAgentOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.inviteAgentOverride, 'AgentInvite', 'inviteAgentSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.inviteAgentSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'inviteAgentSms')"> </textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${RegistrationLink} to auto merge the agent registration link into a custom button.'">
            </div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-task-request" aria-expanded="true" aria-controls="collapse1">
        Task Request / Status Update Template <span *ngIf="definedSmsTemplate.requestOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="sms-task-request" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-request-override" type="checkbox"
                [(ngModel)]="definedSmsTemplate.requestOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.requestOverride, 'TaskRequestStatusUpdate', 'requestSms')">
              <label class="form-check-label" for="sms-request-override">Override Request Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.requestOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.requestOverride, 'TaskRequestStatusUpdate', 'requestSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.requestSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'requestSms')"> </textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${RegistrationLink} to auto merge the borrower registration link into a custom button.'">
            </div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-task-reminder" aria-expanded="true" aria-controls="collapse1">
        Task Reminder Template <span *ngIf="definedSmsTemplate.reminderOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="sms-task-reminder" class="accordion-collapse collapse">
      <div class="accordion-body">
        <ng-container *ngIf="type === 'system-level'">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input class="form-check-input" id="reminder-sms-enabled" type="checkbox" name="reminderSmsEnabled"
                  ToggleValue [true]="1" [false]="0" [(ngModel)]="reminderSmsEnabled.value">
                <label class="form-check-label" for="reminder-sms-enabled">Enable Reminder Sms</label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row" *ngIf="1">
            <div class="form-group mb-3 row" *ngIf="reminderSmsEnabled.value == 1">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Reminder Interval (in days)</label>
              <div class="col-sm-10">
                <input class="form-control" placeholder="Reminder Interval (in days)" type="number"
                  name="reminderSmsInterval" numeric [allowNegative]="false" [(ngModel)]="reminderSmsInterval.value">
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-override-reminder" type="checkbox"
                [(ngModel)]="definedSmsTemplate.reminderOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.reminderOverride, 'TaskReminder', 'reminderSms')">
              <label class="form-check-label" for="sms-override-reminder">Override Reminder Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.reminderOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.reminderOverride, 'TaskReminder', 'reminderSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.reminderSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'reminderSms')"> </textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${RegistrationLink} to auto merge the borrower registration link into a custom button.'">
            </div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-agent-esign-template" aria-expanded="true" aria-controls="collapse1">
        Agent eSign Template <span *ngIf="definedSmsTemplate.agentEsignOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="sms-agent-esign-template" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-agent-esign" type="checkbox"
                [(ngModel)]="definedSmsTemplate.agentEsignOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.agentEsignOverride, 'AgentEsign', 'agentEsignSms')">
              <label class="form-check-label" for="sms-agent-esign">Override Agent eSign Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.agentEsignOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.agentEsignOverride, 'AgentEsign', 'agentEsignSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.agentEsignSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'agentEsignSms')"></textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${EsignLink} to auto merge the agent eSign link into a custom button.'"></div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-borrower-esign-template" aria-expanded="true" aria-controls="collapse1">
        Borrower eSign Template <span *ngIf="definedSmsTemplate.borrowerEsignOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="sms-borrower-esign-template" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-borrower-esign" type="checkbox"
                [(ngModel)]="definedSmsTemplate.borrowerEsignOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.borrowerEsignOverride, 'BorrowerEsign', 'borrowerEsignSms')">
              <label class="form-check-label" for="sms-borrower-esign">Override Borrower eSign Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.borrowerEsignOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.borrowerEsignOverride, 'BorrowerEsign', 'borrowerEsignSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.borrowerEsignSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'borrowerEsignSms')"></textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${EsignLink} to auto merge the borrower eSign link into a custom button.'">
            </div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#sms-internal-esign-template" aria-expanded="true" aria-controls="collapse1">
        Internal Users eSign Template <span *ngIf="definedSmsTemplate.internalEsignOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="sms-internal-esign-template" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="sms-internal-esign" type="checkbox"
                [(ngModel)]="definedSmsTemplate.internalEsignOverride"
                (change)="populateDefaultSmsTemplate(definedSmsTemplate.internalEsignOverride, 'InternalEsign', 'internalEsignSms')">
              <label class="form-check-label" for="sms-internal-esign">Override Internal Users eSign Template</label>
            </div>
          </div>
        </div>
        <div class="form-group mb-3 row" *ngIf="definedSmsTemplate.internalEsignOverride">
          <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Sms Message Text</label>
          <div class="col-sm-10">
            <!-- <a class="link text-end d-block" title="Reload Default"
              (click)="populateDefaultSmsTemplate(definedSmsTemplate.internalEsignOverride, 'InternalEsign', 'internalEsignSms', true)">Reload
              Default</a> -->
            <textarea rows="10" class="form-control" [(ngModel)]="definedSmsTemplate.internalEsignSms"
              (contextmenu)="onContextMenu($event, definedSmsTemplate, 'internalEsignSms')"></textarea>
          </div>
          <div class="col-sm-10 offset-2">
            <div class="text-info text-center mt-2"
              [innerHTML]="'You can use ${EsignLink} to auto merge the Internal Users eSign link into a custom button.'">
            </div>
            <div class="text-info text-center mt-1">
              You can use mouse right click button to select and insert a merge field.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-end" *ngIf="type === 'system-level'">
    <div class="col-md-12 my-1">
      <hr />
    </div>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</div>

<merge-field-context-menu [menuItems]="availableMergeFields">
</merge-field-context-menu>