import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ApplicationContext, Configuration, LoanApplication, LoanStatus, StandardLoanStage } from 'src/app/models';
import { TpoConfiguration, TpoCreditPermissions } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { LoanSummaryBarOptions } from 'src/app/modules/loan-summary-bar/components/loan-summary-bar.component';
import { Constants } from 'src/app/services/constants';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { MenuService } from 'src/app/services/menu.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { PizzaTrackerStep } from 'src/app/shared/components/pizza-tracker/pizza-tracker.component';
import { MenuItemStatus } from '../../models/enums/menu-item-status.enum';
import { CreateEscalationDialogComponent } from '../create-escalation-dialog/create-escalation-dialog.component';
import { LoanSummary } from '../loan-summary-card/loan-summary.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LoanPurposeTypeEnum } from 'src/app/modules/app-details/components/title-history/models/title-order.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { MortgageService } from 'src/app/services/mortgage.service';

declare const CobrowseIO: any;

@Component({
  selector: 'tpo-app-details',
  templateUrl: './tpo-app-details.component.html',
  styleUrls: ['./tpo-app-details.component.scss'],
})
export class TpoAppDetailsComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  loanId: number;
  companyId: number;

  loanSummary: LoanSummary;

  isLoadingApplicationDetails: boolean = false;

  trackerSteps: PizzaTrackerStep[] = [];
  activeTrackerStepOrder: number;
  loanSubmissionCompleted: boolean = true;

  isUrlaReadOnly: boolean = false;
  isPRMG: boolean = false;
  useV2Menu: boolean = false;

  protected application: LoanApplication;
  protected mortgage: UrlaMortgage;
  protected loanStatus: LoanStatus;

  protected isPizzaTrackerEnabled: boolean = false;
  protected isBrokerDisclosedAndSubmitted: boolean = false;

  private _loanPurposes: LoanPurpose[];

  private _subscription: Subscription;
  private _routeSubscription1: Subscription;
  private _routeSubscription2: Subscription;
  private _loanInfoLoadErrorSubscription: Subscription;
  private _menuItemStatusSubscription: Subscription;
  private _menuItemClickedSubscription: Subscription;
  private _urlaStatusSubscription: Subscription;
  private _applicationStatusTrackingChangesSubscription: Subscription;
  private _routerEventsSubscription: Subscription;

  protected loanSummaryOptions: LoanSummaryBarOptions = {
    showCharacteristicsSummary: false,
    showLosLdeSummary: false,
    showStatusSummary: false
  }

  protected stepStatuses: any = {
    urla: MenuItemStatus.Pending,
    services: MenuItemStatus.None,
    pricing: MenuItemStatus.None,
    fees: MenuItemStatus.None,
    disclosures: MenuItemStatus.None,
    submission: MenuItemStatus.None
  }

  protected permissions: TpoFeaturePermissions = new TpoFeaturePermissions();

  constructor(
    private readonly injector: Injector,
    private readonly _menuService: MenuService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _router: Router,
    private readonly _loanService: LoanService,
    private readonly _mortgageService: MortgageService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _appService: AppDetailsService
  ) {
    super(injector);

    this.isPRMG = this.applicationContext.isCompanyPRMG;

    this.useV2Menu = this.applicationContext.tpoConfig.useV2Menu;
    this.isLoadingApplicationDetails = true;
    this._subscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (!context.application) {
        return;
      }
      const borrower = context.borrowers[0];
      this.application = context.application;
      this.mortgage = context.currentMortgage;
      this.loanId = this.application.applicationId;
      this.initializeCobrowse(this.loanId);
      this.loanSummary = new LoanSummary();
      this.loanSummary.borrowerName = borrower.firstName + " " + borrower.lastName;
      this.loanSummary.city = context.application.mailingCity;
      this.loanSummary.streetAddress = context.application.mailingStreet;
      this.loanSummary.channel = context.application.channel;
      this.loanSummary.loanNumber = context.application.refNumber;
      this.companyId = context.userPermissions.companyId;

      this._loanPurposes = context.globalConfig.loanPurpose;

      this.permissions.pricingEnabled = context.userPermissions.pricingEnabled;
      this.permissions.isFeesEnabled = context.userPermissions.feesEnabled;
      this.permissions.isAmortizationTableMenuItemVisible = !!context.application?.productPricing?.rate;
      this.permissions.isMersEnabled = context.userPermissions?.mersEnabled;
      this.permissions.isRequiredLoanAndCaseNumbers = context.application.channel !== 'Wholesale' || !context.isTpo;

      this.isBrokerDisclosedAndSubmitted = context.application?.disclosurePath == "Broker";

      if (!context.isCompanyPulseProcessing && ((this.application.channel == 'Wholesale' && context.tpoConfig.wholesalePizzaTrackerEnabled) ||
        (this.application.channel == 'Correspondent' && context.tpoConfig.corrPizzaTrackerEnabled) ||
        (this.application.channel == 'NonDelegatedCorrespondent' && context.tpoConfig.nonDelPizzaTrackerEnabled))) {
        this.isPizzaTrackerEnabled = true;
      }
      this.loanStatus = context.globalConfig.loanStatus.find(s => s.loanStatusId == context.application.loanStatusId);
      if (this.loanStatus) {
        this.loanSummary.loanStatus = this.loanStatus.loanStatusName;
      }
      this.isLoadingApplicationDetails = false;
      this.populateTrackerSteps(context);
      this.checkLoanPurpose();
    });

    this._appService.getLoanHiddenFields().subscribe((response) => {
      const hiddenFields = this.getHiddenFields(response);

      this.permissions.isSubjectPropertyAddressHidden = hiddenFields.findIndex(f => f === "Subject Property") > -1;
      this.permissions.isAppraisedValueHidden = hiddenFields.findIndex(f => f === "Appraised Value") > -1;
    });

    this._loanInfoLoadErrorSubscription = this.applicationContextService.loanInfoLoadError.subscribe(error => {
      this._notificationService.showError(error, "Access Error!");
      setTimeout(() => {
        this._router.navigateByUrl('/tpo/dashboard');
      }, 2000)
    });

    this._urlaStatusSubscription = this._menuService.urlaStatusChanged.subscribe(status => {
      const step = this.trackerSteps.find(step => step.id === 'urla');
      if (step && status) {
        step.status = status;
      }
    });

    this._applicationStatusTrackingChangesSubscription = this.applicationContextService.applicationTrackingStatusesChangedEvent.subscribe((statuses) => {
      this.updateMenuItemAndPizzaTrackerStatuses(statuses);
    });

    this._routerEventsSubscription = this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setActiveTrackerStepOrder();
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    this._routeSubscription1 = this._activatedRoute.queryParams.subscribe(queryParams => {
      this._routeSubscription2 = this._activatedRoute.params.subscribe(routeParams => {
        const loanId = Number(this._activatedRoute.snapshot.paramMap.get('id'));
        if (loanId && !isNaN(loanId) && loanId != this.loanId) {
          this.loanId = loanId;
          this.isLoadingApplicationDetails = true;
          this.loanSummary = null;
          this.applicationContextService.updateLoanInfo(this.loanId).subscribe(result => {
            this.isLoadingApplicationDetails = false;
          });
        }
      }, error => {
        this.isLoadingApplicationDetails = false;
        this._notificationService.showError(
          error ? error.message : 'Unable update loan info.',
          'Error!'
        );
      });
    });

    this.getPermissions();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this._routeSubscription1) {
      this._routeSubscription1.unsubscribe();
    }
    if (this._routeSubscription2) {
      this._routeSubscription2.unsubscribe();
    }
    if (this._loanInfoLoadErrorSubscription) {
      this._loanInfoLoadErrorSubscription.unsubscribe();
    }
    if (this._menuItemStatusSubscription) {
      this._menuItemStatusSubscription.unsubscribe();
    }
    if (this._menuItemClickedSubscription) {
      this._menuItemClickedSubscription.unsubscribe();
    }
    if (this._urlaStatusSubscription) {
      this._urlaStatusSubscription.unsubscribe();
    }
    if (this._routerEventsSubscription) {
      this._routerEventsSubscription.unsubscribe();
    }
    if (this._applicationStatusTrackingChangesSubscription) {
      this._applicationStatusTrackingChangesSubscription.unsubscribe();
    }
  }

  createEscalationDialogModal = () => {
    this._modalService.open(CreateEscalationDialogComponent, Constants.modalOptions.xlarge);
  }

  private initializeCobrowse = (applicationId: number) => {
    const authDataJson = localStorage.getItem(Constants.authorization.authorizationDataKey);
    if (authDataJson) {
      const authData = JSON.parse(authDataJson);
      if (authData) {
        CobrowseIO.customData = {
          user_id: authData.userId,
          user_name: authData.userName,
          user_email: authData.userName,
          device_id: applicationId,
          device_name: authData.userName,
          application_id: applicationId
        };
      }
    }
  }

  private populateTrackerSteps = (applicationContext: ApplicationContext) => {
    const keyDates = applicationContext.applicationKeyDatesByType;
    if (keyDates?.leIssued?.eventDate) {
      this.isUrlaReadOnly = true;
    }

    this.updateMenuItemAndPizzaTrackerStatuses(applicationContext.tpoMenuStatuses);
  }

  private updateMenuItemAndPizzaTrackerStatuses = (statuses: any) => {
    if (!statuses) {
      return;
    }
    const statusItem = statuses[Constants.menu.servicesMenuItems.submission];
    const tpoSubmitted = statusItem && statusItem.toLowerCase() == MenuItemStatus.Success;
    if (tpoSubmitted) {
      this.isUrlaReadOnly = true;
    }
    this.populateInitialMenuItemStatuses(statuses);
    if (this.isPizzaTrackerEnabled) {
      this.populateInitialPizzaTrackerStatuses(statuses);
    }
  }

  private populateInitialMenuItemStatuses = (statuses: any) => {

    Object.keys(statuses).forEach(k => {
      statuses[k] = statuses[k].toLowerCase();
    });

    let status = statuses[Constants.menu.servicesMenuItems.credit];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.credit, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.voiVoe];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.voiVoe, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.voie];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.voie, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.voa];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.voa, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.aus];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.aus, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.appraisal];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.appraisal, status ?? MenuItemStatus.None);
    this._menuService.setStatus(Constants.menu.servicesMenuItems.appraisal + "-management", status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.disclosure];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.disclosure, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.pricing];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.pricing, status ?? MenuItemStatus.None);

    status = statuses[Constants.menu.servicesMenuItems.submission];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.submission, status ?? MenuItemStatus.None);
    this.loanSubmissionCompleted = (status == MenuItemStatus.Success);

    status = statuses[Constants.menu.servicesMenuItems.fees];
    this._menuService.setStatus(Constants.menu.servicesMenuItems.fees, status ?? MenuItemStatus.None);

    Object.keys(Constants.menu.servicesMenuItems).forEach(k => {
      const trackerStep = this.trackerSteps.find(step => step.id === k);
      const status = statuses[k] ?? MenuItemStatus.None
      if (trackerStep) {
        trackerStep.status = status;
      }

      this.setStepStatus(Constants.menu.servicesMenuItems[k], status, statuses);
    });
  }

  private populateInitialPizzaTrackerStatuses = (items?: { [menuItemKey: string]: MenuItemStatus }) => {

    const item = items ? items[Constants.menu.servicesMenuItems.submission] : null;

    // populate first tracker
    const hasSubmission = item && item == MenuItemStatus.Success;
    if (!hasSubmission) {

      let steps = [];
      // For urla, we need to look at stepStatuses
      let urlaStatus = MenuItemStatus.Pending;
      if (this.stepStatuses.urla) {
        urlaStatus = this.stepStatuses.urla;
      }
      steps.push({ id: 'urla', name: "URLA", order: 1, status: urlaStatus, navigateToPath: `/tpo/app-details/${this.loanId}/urla`, hiddenIcon: this.isUrlaReadOnly });

      if (items[Constants.menu.servicesMenuItems.credit] && this.permissions.isCreditAllowed && this.loanSummary.channel != "Correspondent") {
        steps.push({ id: Constants.menu.servicesMenuItems.credit, name: "CREDIT", order: 2, status: items[Constants.menu.servicesMenuItems.credit] ?? MenuItemStatus.None, navigateToPath: `/tpo/app-details/${this.loanId}/credit-reports` })
      }

      if (items[Constants.menu.servicesMenuItems.aus] && this.permissions.isAusAllowed && this.loanSummary.channel != "Correspondent") {
        steps.push({ id: Constants.menu.servicesMenuItems.aus, name: "AUS", order: 3, status: items[Constants.menu.servicesMenuItems.aus] ?? MenuItemStatus.None, navigateToPath: `/tpo/app-details/${this.loanId}/aus` })
      }

      if (items[Constants.menu.servicesMenuItems.pricing] && this.permissions.pricingEnabled) {
        steps.push({ id: Constants.menu.servicesMenuItems.pricing, name: "PRICING", order: 4, status: items[Constants.menu.servicesMenuItems.pricing] ?? MenuItemStatus.None, navigateToPath: `/tpo/app-details/${this.loanId}/pricing` })
      }

      if (items[Constants.menu.servicesMenuItems.fees] && this.permissions.isFeesEnabled && this.loanSummary.channel != "Correspondent") {
        steps.push({ id: Constants.menu.servicesMenuItems.fees, name: "FEES", order: 5, status: items[Constants.menu.servicesMenuItems.fees] ?? MenuItemStatus.None, navigateToPath: `/tpo/app-details/${this.loanId}/fees` })
      }

      if (items[Constants.menu.servicesMenuItems.disclosure] && !(!this.permissions.isDisclosureGenerationAllowed || this.isBrokerDisclosedAndSubmitted) && this.loanSummary.channel != "Correspondent") {
        steps.push({ id: Constants.menu.servicesMenuItems.disclosure, name: "DISCLOSURES", order: 6, status: items[Constants.menu.servicesMenuItems.disclosure] ?? MenuItemStatus.None, navigateToPath: `/tpo/app-details/${this.loanId}/disclosures` })
      }

      if (items[Constants.menu.servicesMenuItems.submission]) {
        steps.push({ id: Constants.menu.servicesMenuItems.submission, name: "SUBMIT TO UW", order: 7, status: items[Constants.menu.servicesMenuItems.submission] ?? MenuItemStatus.None, navigateToPath: `/tpo/app-details/${this.loanId}/submission` })
      }

      this.trackerSteps = [...steps] as PizzaTrackerStep[];
    } else {
      this.populateOtherPizzaTrackerSteps(this.loanStatus);
    }
  };

  private populateOtherPizzaTrackerSteps = (loanStatus: LoanStatus) => {
    if (!loanStatus || !loanStatus.loanStage || !this.loanId) {
      return;
    }

    var statuses = [];

    if ([StandardLoanStage.Application, StandardLoanStage.Origination, StandardLoanStage.Setup, StandardLoanStage.Processing, StandardLoanStage.Underwriting, StandardLoanStage.Approval].indexOf(loanStatus.loanStage) > -1) {
      statuses = this.applicationContext.globalConfig.loanStatus.filter(ls => ls.enabledChannels?.indexOf(this.application.channel) > -1 && [StandardLoanStage.Application, StandardLoanStage.Origination, StandardLoanStage.Setup, StandardLoanStage.Processing, StandardLoanStage.Underwriting, StandardLoanStage.Approval].indexOf(ls.loanStage) > -1);
    } else if ([StandardLoanStage.ClearToClose, StandardLoanStage.Completed].indexOf(loanStatus.loanStage) > -1) {
      statuses = this.applicationContext.globalConfig.loanStatus.filter(ls => ls.enabledChannels?.indexOf(this.application.channel) > -1 && [StandardLoanStage.ClearToClose, StandardLoanStage.Completed].indexOf(ls.loanStage) > -1);
    } else {
      return;
    }

    this._loanService.getPizzaMenuStatuses(this.loanId).subscribe({
      next: (menuStatuses) => {

        const prefix = "lS_";

        this.trackerSteps = statuses.map((s, index) => {
          return {
            name: s.loanStatusName,
            order: index + 1,
            status: menuStatuses[prefix + s.loanStatusId]
          } as PizzaTrackerStep;
        });
      },
      error: () => {

      }
    });
  }

  private setActiveTrackerStepOrder = () => {
    const currentURL = this._router.url;

    if (currentURL.includes("/urla")) {
      this.activeTrackerStepOrder = 1;
    }
    else if (currentURL.includes("/credit-reports")) {
      this.activeTrackerStepOrder = 2;
    }
    else if (currentURL.includes("/aus")) {
      this.activeTrackerStepOrder = 3;
    }
    else if (currentURL.includes("pricing") || currentURL.includes("amortization")) {
      this.activeTrackerStepOrder = 4;
    }
    else if (currentURL.includes("/fees")) {
      this.activeTrackerStepOrder = 5;
    }
    else if (currentURL.includes("/disclosures")) {
      this.activeTrackerStepOrder = 6;
    }
    else if (currentURL.includes("/submission")) {
      this.activeTrackerStepOrder = 7;
    }
  }

  private setStepStatus = (menuItemId: string, status: MenuItemStatus, allStatuses: any) => {

    const servicesSubMenuIds = [
      this.permissions.isCreditAllowed ? Constants.menu.servicesMenuItems.credit : null,
      this.permissions.isAusAllowed ? Constants.menu.servicesMenuItems.aus : null,
      this.permissions.isVoaAllowed ? Constants.menu.servicesMenuItems.voa : null,
      this.permissions.isVoiVoeAllowed ? Constants.menu.servicesMenuItems.voiVoe : null
    ].filter(s => !!s);

    const servicesStatuses = Object.keys(allStatuses).filter(k => servicesSubMenuIds.includes(k)).map(k => allStatuses[k])

    if (servicesSubMenuIds.includes(menuItemId)) {
      this.stepStatuses.services = this.determineDominantStatus([...servicesStatuses, status]);
    } else if (menuItemId == Constants.menu.servicesMenuItems.pricing) {
      this.stepStatuses.pricing = status;
    } else if (menuItemId == Constants.menu.servicesMenuItems.fees) {
      this.stepStatuses.fees = status;
    } else if (menuItemId == Constants.menu.servicesMenuItems.disclosure) {
      this.stepStatuses.disclosures = status;
    } else if (menuItemId == Constants.menu.servicesMenuItems.submission) {
      this.stepStatuses.submission = status;
    }
  }

  private determineDominantStatus = (statuses: MenuItemStatus[]): MenuItemStatus => {
    let arr = statuses.map(s => s.toLowerCase());

    if (arr.includes("loading")) {
      return MenuItemStatus.Loading;
    } else if (arr.includes("error")) {
      return MenuItemStatus.Error;
    } else if (arr.includes("pending")) {
      return MenuItemStatus.Pending;
    } else {
      return MenuItemStatus.Success;
    }
  }

  private getPermissions = () => {
    this._loanServicesService.getTpoConfiguration().subscribe((result: TpoConfiguration) => {
      this.permissions.isServicesEnabled = result.creditPermissions !== TpoCreditPermissions.None || result.ausPermissions !== TpoCreditPermissions.None || result.isVOAAllowed || result.isVOIVOEAllowed;
      this.permissions.isCreditAllowed = result.creditPermissions !== TpoCreditPermissions.None;
      this.permissions.isVoiVoeAllowed = result.isVOIVOEAllowed;
      this.permissions.isVoaAllowed = result.isVOAAllowed;
      this.permissions.isAusAllowed = result.ausPermissions !== TpoCreditPermissions.None;
      this.permissions.isAppraisalOrderAllowed = result.isAppraisalOrderAllowed;
      this.permissions.columnSelectorEnabled = result.columnSelectorEnabled;
      this.permissions.isDisclosureGenerationAllowed = result.isDisclosureGenerationAllowed;
    });
  }

  private getHiddenFields = (loanHiddenFields?: Configuration): Array<string> => {
    if (!loanHiddenFields?.valueStr) return [];
    return loanHiddenFields.valueStr?.split(",").map(el => el.trim());
  }

  private checkLoanPurpose = () => {
    if (!this.mortgage?.subjectProperty || !this.application.loanPurposeId) return;

    const loanPurposeName = this._loanPurposes.find(lp => lp.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose;
    if (loanPurposeName && loanPurposeName !== this.mortgage.subjectProperty.purposeOfLoan) {
      Swal.fire({
        title: `The loan purpose on the URLA shows '${this.mortgage.subjectProperty.purposeOfLoan}', while the application loan purpose is '${loanPurposeName}'.`,
        text: 'Would you like to update the URLA to match?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result: SweetAlertResult) => {
        if (!result.value) {
          return;
        }
        this.mortgage.subjectProperty.purposeOfLoan = loanPurposeName;
        this._mortgageService.loanPurpose =
          loanPurposeName as LoanPurposeTypeEnum;
      });
    }
  }
}

export class TpoFeaturePermissions {
  pricingEnabled: boolean;
  isFeesEnabled: boolean;
  isCreditAllowed: boolean;
  isVoiVoeAllowed: boolean;
  isVoaAllowed: boolean;
  isAusAllowed: boolean;
  isAppraisalOrderAllowed: boolean;
  isServicesEnabled: boolean;
  columnSelectorEnabled: boolean;
  isAmortizationTableMenuItemVisible: boolean;
  isMersEnabled: boolean;
  isRequiredLoanAndCaseNumbers: boolean;
  isAppraisedValueHidden: boolean;
  isSubjectPropertyAddressHidden: boolean;
  isDisclosureGenerationAllowed: boolean;
}
