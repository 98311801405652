export class Rate {
  lockPeriod: number;
  price: number;
  rate: number;
  principalAndInterest: number;
  isCustom: boolean;

  constructor(rate: number, lockPeriod: number, price: number, principalAndInterest: number) {
    this.rate = rate;
    this.lockPeriod = lockPeriod;
    this.price = price;
    this.principalAndInterest = principalAndInterest;
  }
}

export enum PricingRateCreditApplicationMethod {
  ApplyCreditToDiscountFee = "ApplyCreditToDiscountFee",
  ApplyCreditToLenderCredit = "ApplyCreditToLenderCredit",
  DoNotApplyCredit = "DoNotApplyCredit",
}
