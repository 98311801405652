import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentService } from "../core/services/environment/environment.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import { DocuViewareConfig, FileDownloadCustomHeaders } from "../models/docuvieware/docuvieware-config.model";

declare const DocuViewareAPI: any;

type PostCustomServerActionCallback = (result: string) => void;

@Injectable()
export class DocuViewareService {

    private _docuViewareControlId: string = 'MergeDocDisplay';

    private _httpClient: HttpClient;

    constructor(private readonly _environment: EnvironmentService,
        private readonly _localStorage: LocalStorageService,
        private _handler: HttpBackend) {
        this._httpClient = new HttpClient(this._handler);
    }

    getSelectedThumbnailItems = (): number[] => {
        var pages = DocuViewareAPI.GetSelectedThumbnailItems(this._docuViewareControlId);
        return pages;
    }

    getDocuViewareMarkup = (): Observable<any> => {
        let config = new DocuViewareConfig();
        config.ControlId = this._docuViewareControlId;
        const authData = this._localStorage.authorizationData;
        if (authData && authData.token) {
            const authHeader = new FileDownloadCustomHeaders();
            authHeader.Authorization = 'Bearer ' + authData.token
            config.FileDownloadCustomHeaders = authHeader;
        }
        const url = this._environment.apiInfo.docuViewareApiBaseUrl + "api/docuvieware/generate";
        return this._httpClient.post(url, config);
    }

    isLoaded = () => {
        return DocuViewareAPI.IsLoaded(this._docuViewareControlId);
    }

    setThumbnailSelected = (page: number, isVal: boolean) => {
        return DocuViewareAPI.SetThumbnailSelected(this._docuViewareControlId, page, isVal);
    }

    registerOnThumbnailsOrderChanged = (isVal: boolean) => {
        return DocuViewareAPI.RegisterOnThumbnailsOrderChanged(this._docuViewareControlId, isVal);
    }

    saveDocument = (successCallback: PostCustomServerActionCallback, errorCallback: PostCustomServerActionCallback) => {
        return DocuViewareAPI.PostCustomServerAction(this._docuViewareControlId, true, "SaveAsPdf", null, successCallback, errorCallback);
    }

    postCustomServerAction = (rotateOrSave: string, rotation: object) => {
        return DocuViewareAPI.PostCustomServerAction(this._docuViewareControlId, true, rotateOrSave, rotation);
    }

    loadFromByteArray = (data: any): void => {
        new Response(data).arrayBuffer()
            .then(arrayBuffer => {
                DocuViewareAPI.LoadFromByteArray(this._docuViewareControlId, arrayBuffer, data.type, null);
            });
    }
}
