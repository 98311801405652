import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {EnumerationItem} from '../../../models/simple-enum-item.model';
import {FeeSystemDetailsFeeTypeDetails, FeeType, LoanFee} from '../fees.model';
import {autoId, setControlErrors} from '../../../core/services/utils';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {EnumerationService} from '../../../services/enumeration-service';
import {Constants} from '../../../services/constants';
import {combineLatestWith, Subscription} from 'rxjs';
import {NotificationService} from '../../../services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {IConfig} from 'ngx-mask';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize} from 'rxjs/operators';
import {FeesV2Service} from '../services/fees-v2.service';
import {FeeContextService} from '../services/fee-context.service';

@Component({
  templateUrl: './add-fee-modal.component.html',
  styleUrls: ['./add-fee-modal.component.scss'],
})
export class AddFeeModalComponent implements OnInit, AfterViewInit, OnDestroy {
  // TODO: Move this to the constructor when fees and fees-v2 are merged.
  //  Since this service is provided in the fees module and this modal is not in
  //  the module or in the same hierarchy, the service cannot be injected in the
  //  constructor.
  @Input() set feeContextService(value: FeeContextService) {
    this._feeContextService = value;
  }

  private _feeContextService: FeeContextService;

  protected isLoadingOptions: boolean = true;

  protected existingFeeTypes: Readonly<Set<FeeType>> = new Set();
  protected existingHudNumbers: Readonly<Set<string>> = new Set();

  @ViewChild('modalHeader', {static: true}) modalHeader: ElementRef<HTMLDivElement>;
  @ViewChild('form', {static: true}) formElement: NgForm;

  protected feeTypeOptions: EnumerationItem<FeeType>[] = [];
  protected selectedFeeTypeOption?: EnumerationItem<FeeType>;
  protected hudNumber?: string;
  protected feeName?: string;
  /** Fee name that is written by the user. */
  private _customFeeName?: string;

  protected isNonSpecificFee: boolean = false;
  protected isFeeNameFromType: boolean = true;

  protected hudNumberInputConfig: InputConfig = {
    mask: 'AA',
    minlength: 4,
    patterns: {A: {pattern: new RegExp('[0-9]')}},
    prefix: '18',
  };

  protected isAddingFee: boolean = false;

  private get _form(): FormGroup {
    return this.formElement.form;
  }

  private _initFeeSystemDetailsSubscription?: Subscription;
  private _initEnumerationsSubscription?: Subscription;
  private _addFeeSubscription?: Subscription;

  private readonly _id: string = autoId();

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _enumerationService: EnumerationService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _feeService: FeesV2Service
  ) {}

  ngOnInit(): void {
    this.initFeeTypeOptions();
    this.initExistingFeeFields();
    this.onChangeIsFeeNameFromType(this.isFeeNameFromType);
  }

  ngAfterViewInit() {
    this.allowModalOverflow();
  }

  ngOnDestroy() {
    this._initFeeSystemDetailsSubscription?.unsubscribe();
    this._initEnumerationsSubscription?.unsubscribe();
    this._addFeeSubscription?.unsubscribe();
  }

  private initFeeTypeOptions() {
    this.isLoadingOptions = true;

    this._initEnumerationsSubscription?.unsubscribe();
    this._initEnumerationsSubscription = this._enumerationService
      .getFeeEnumerations()
      .pipe(combineLatestWith(this._feeService.getFeeSystemDetails()))
      .subscribe(([enumerations, feeSystemDetails]) => {
        const feeDetailsByType = feeSystemDetails.feeTypes.reduce((acc, feeType) => {
          acc.set(feeType.feeType, feeType);
          return acc;
        }, new Map<FeeType, FeeSystemDetailsFeeTypeDetails>());

        this.feeTypeOptions = enumerations[Constants.feeEnumerations.feeType].filter(
          (item: EnumerationItem<FeeType>) => {
            const details = feeDetailsByType.get(item.value);
            if (details == null) {
              console.error(`Fee type details not found for fee type: ${item.value}`);
              return false;
            }

            return !this._feeService.isSummaryFeeType(details.feeType);
          }
        );
        this.isLoadingOptions = false;
      });
  }

  private initExistingFeeFields() {
    const fees = this._feeContextService.fees;

    const {existingFeeTypes, existingHudNumbers} = fees.reduce(
      (acc, fee) => {
        acc.existingFeeTypes.add(fee.feeType);
        acc.existingHudNumbers.add(fee.hudNumber);
        return acc;
      },
      {
        existingFeeTypes: new Set<FeeType>(),
        existingHudNumbers: new Set<string>(),
      }
    );
    this.existingFeeTypes = existingFeeTypes;
    this.existingHudNumbers = existingHudNumbers;
  }

  /**
   * Allow modal to overflow its container. It's necessary for the dropdowns to be displayed
   * correctly.
   */
  private allowModalOverflow() {
    const ngComponentHost = this.modalHeader.nativeElement.closest('ng-component');
    if (ngComponentHost instanceof HTMLElement) {
      const modalContent = ngComponentHost.parentElement;
      ngComponentHost.style.overflow = 'visible';
      modalContent.style.overflow = 'visible';
    } else {
      console.warn('Could not find ng-component host for modal overflow fix');
    }
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }

  private static setConflictError(control: AbstractControl, value: boolean) {
    setControlErrors(control, {conflict: value});
  }

  protected onChangeFeeTypeOption(item: EnumerationItem<FeeType>): void {
    this.selectedFeeTypeOption = item;
    const value = item?.value;
    const hasConflict = value != null ? this.existingFeeTypes.has(value) : false;
    const control = this._form.get('feeType');

    AddFeeModalComponent.setConflictError(control, hasConflict);
    this.invalidateFeeName();
  }

  protected onChangeHudNumber(value: string): void {
    this.hudNumber = value;
    const hasConflict = this.existingHudNumbers.has(value);
    const control = this._form.get('hudNumber');

    AddFeeModalComponent.setConflictError(control, hasConflict);
  }

  protected onChangeIsNonSpecificFee(value: boolean): void {
    this.isNonSpecificFee = value;
    if (value) {
      this.feeName = this._customFeeName;
    } else {
      this.invalidateFeeName();
    }
  }

  protected onChangeIsFeeNameFromType(value: boolean): void {
    this.isFeeNameFromType = value;
    if (value) {
      this._customFeeName = this.feeName;
    }

    this.invalidateFeeName();
  }

  private invalidateFeeName(): void {
    if (this.isFeeNameFromType) {
      const selectedFeeTypeName = this.selectedFeeTypeOption?.name;
      this.feeName = selectedFeeTypeName ?? '';
    } else {
      this.feeName = this._customFeeName;
    }
  }

  protected onClickCancel(): void {
    this._activeModal.dismiss();
  }

  protected onClickAdd(): void {
    const form = this._form;
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }

    this.addFee();
  }

  private addFee(): void {
    this._spinnerService.show().then();
    this.isAddingFee = true;

    const value: Partial<LoanFee> = {};
    if (!this.isFeeNameFromType || this.isNonSpecificFee) {
      value.name = this.feeName;
    }
    if (this.isNonSpecificFee) {
      value.hudNumber = this.hudNumber;
    } else {
      value.feeType = this.selectedFeeTypeOption?.value;
    }

    this._addFeeSubscription?.unsubscribe();
    this._addFeeSubscription = this._feeContextService
      .createFee(value)
      .pipe(
        finalize(() => {
          this._spinnerService.hide().then();
          this.isAddingFee = false;
        })
      )
      .subscribe({
        next: () => {
          this._notificationService.showSuccess('The fee was added successfully.', 'Success');
          this._activeModal.close();
        },
        error: error => {
          const defaultMessage = 'An error occurred while adding the fee.';
          console.error(defaultMessage, error);
          const message = error?.message || error?.error?.message || defaultMessage;

          this._notificationService.showError(message, 'Error');
        },
      });
  }
}

interface InputConfig {
  mask?: string;
  minlength?: number;
  patterns?: IConfig['patterns'];
  prefix?: IConfig['prefix'];
}
