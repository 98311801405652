import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable } from 'rxjs';
import { ExpressionGroup } from '../models/expressions/expression-group.model';

@Injectable({ providedIn: 'root' })
export class ExpressionsService {
  constructor(
    private readonly _dataService: DataService
  ) { }

  createExpressionGroup = (expressionGroup: ExpressionGroup): Observable<ExpressionGroup> => {
    const url = `api/expressions/groups`;
    return this._dataService.post(url, expressionGroup);
  }

 updateExpressionGroup = (expressionGroup: ExpressionGroup): Observable<ExpressionGroup> => {
    const url = `api/expressions/groups/${expressionGroup.expressionGroupId}`;
    return this._dataService.post(url, expressionGroup);
  }

  deleteExpression(expressionGroupId: number): Observable<undefined> {
    return this._dataService.delete(
      `api/expressions/groups/${expressionGroupId}`
    );
  }
}
