<div
  class="form-input z-auto"
  *ngIf="id && !inEditMode"
  [ngClass]="
    {
      'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
      'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
      'ng-invalid-date' : model && model.invalid && model.errors && !model.errors?.required,
      'form-input' : showBorder || (!showBorder && label),
      'no-border' : !showBorder && !label,
      'bg-transparent' : readonly
    }"
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
  <span class="input-label" title="" *ngIf="label">
    <span class="input-label-overflow">{{label}}</span>
  </span>

    <div class="input-group">
      <input
        [hidden]="initialLoad && errorPreviewNotClickedYet && model && model.invalid && !model.errors?.required"
        class="form-control"
        placeholder="mm/dd/yyyy"
        [(ngModel)]="date"
        [name]="name + nameSuffix"
        id="{{id}}"
        maxlength="10"
        style="border:0; padding:0;"
        [maxDate]="maxDate ? maxDate : {year: 2150, month: 1, day: 1}"
        [minDate]="minDate ? minDate : {year: 1900, month: 1, day: 1}"
        ngbDatepicker
        #dateInput
        #d="ngbDatepicker"
        #model="ngModel"
        (keyup)="applyDateMask($event)"
        [required]="required"
        (ngModelChange)="onDateChange()"
        (blur)="onBlurred($event)"
        [readonly]="readonly"
        [ngClass]="
          { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
            'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
            'ng-invalid-date' : model && model.invalid && model.errors && !model.errors?.required
          }">
      <span class="form-control ng-invalid-pattern" (click)="onErrorPreviewClicked()" style="border: 0; padding:0;"
        *ngIf="initialLoad && errorPreviewNotClickedYet && model && model.invalid && !model.errors?.required"
        [ngClass]="{'disabled bg-transparent': readonly}">{{value}}</span>
      <div class="input-group-append" style="margin-left: 5px;">
        <a class="table-icon-button" [hidden]="readonly"
          (click)="onErrorPreviewClicked(); d.toggle();"><i class="fa fa-calendar"></i>
        </a>
      </div>
    </div>
</div>
<div class="text-danger" *ngIf="model?.errors?.max">
  {{label}} must be less than {{maxDate}}
</div>
<div class="text-danger" *ngIf="model?.errors?.min">
  {{label}} must be greater than {{minDate}}
</div>
<edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
</edit-mode-input>
