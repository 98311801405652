import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentSigningVendor } from 'src/app/modules/loan-docs/models/document-signing-vendor.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SigningService } from 'src/app/services/signing.service';

@Component({
  selector: 'document-signing-dialog',
  templateUrl: './document-signing-dialog.component.html',
  styleUrls: ['./document-signing-dialog.component.scss']
})
export class DocumentSigningDialogComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  loanDocId: number;
  
  @Input()
  isCorrectView: boolean;

  @Input()
  isEditView: boolean;

  @Input()
  documentSigningOrderId: number;

  @Input()
  documentSigningVendor: DocumentSigningVendor = DocumentSigningVendor.DocuSign;

  @Input()
  recipientBorrowerContactIds: string[];

  @Input()
  recipientInternalContactIds: string[];

  @Input()
  recipientExternalContactIds: string[];

  @Input()
  hasCondition: boolean;

  isShowDocSignIframe: boolean;

  isLoading: boolean = true;

  senderViewUrl: SafeUrl;

  shouldCloseDocSignIframe: boolean;

  constructor(public activeModal: NgbActiveModal,
    private readonly _signingService: SigningService,
    private readonly _notifyService: NotificationService,
    private readonly _domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const data = {
      returnUrl: location.protocol + '//' + location.host + '/iframe-confirmation/document-signing',
      recipientBorrowerContactIds: this.recipientBorrowerContactIds,
      recipientInternalContactIds: this.recipientInternalContactIds,
      recipientExternalContactIds: this.recipientExternalContactIds,
      applicationId: this.appId,
      isTaskFlaggedCondition: this.hasCondition
    };

    if (!this.documentSigningOrderId) {
      this.openSenderView(data);
      return;
    }
  
    if (this.isCorrectView) {
      this.openCorrectView(data);
    } else if (this.isEditView) {
      this.openEditView(data);
    }
  }

  private openSenderView = (data) => {
    this.isLoading = true;
    this._signingService.openSenderView(this.loanDocId, data).subscribe({
      next: (response) => {
        this.handleOpenViewResponse(response);
      },
      error: (err) => {
        this._notifyService.showError(err.message || err || "Send Document for Signing", 'Error');
      }
    }).add(() => this.isLoading = false);
  }

  private openCorrectView = (data) => {
    this.isLoading = true;
    this._signingService.openCorrectView(this.documentSigningOrderId, {
      returnUrl: data.returnUrl,
      applicationId: data.applicationId
    }).subscribe({
      next: (response) => {
        this.handleOpenViewResponse(response);
      },
      error: (err) => {
        this._notifyService.showError(err.message || err || "Correct E-Sign Order", 'Error');
      }
    }).add(() => this.isLoading = false);
  }

  private openEditView = (data) => {
    this.isLoading = true;
    this._signingService.openEditView(this.documentSigningOrderId, {
      returnUrl: data.returnUrl,
      applicationId: data.applicationId
    }).subscribe({
      next: (response) => {
        this.handleOpenViewResponse(response);
      },
      error: (err) => {
        this._notifyService.showError(err.message || err || "Correct E-Sign Order", 'Error');
      }
    }).add(() => this.isLoading = false);
  }

  private handleOpenViewResponse = (response) => {
    this.senderViewUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(response.senderViewUrl);
    this.isShowDocSignIframe = true;
    window.addEventListener('message', event => {
      if (event.data.shouldCloseDocSignIframe) {
        this.activeModal.close(event.data.response);
      }
    }, false);
  };
}
