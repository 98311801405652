import { MessageDeliveryEnum } from "src/app/models"
import { DayOfWeek } from "../../admin/lead-config/models/lead-route-day-schedule.model";

export class MessageAllowedSendRange {
  messageAllowedSendRangeId: number;
  delivery: MessageDeliveryEnum;
  dayOfWeek: DayOfWeek;
  scheduleType: TimeZoneType;
  enabledAllDay: boolean;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  companyId: number;
  insertedBy: string
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum TimeZoneType {
  local = "LocalTimezone",
  unknown = "UnknownTimezone",
}
