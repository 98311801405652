import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { NotificationService } from '../../../../../../services/notification.service';

@Component({
  selector: 'global-sms-modal',
  templateUrl: './global-sms-modal.component.html'
})
export class GlobalSmsModalComponent implements OnInit {
  @ViewChild('serviceForm') serviceForm: NgForm | undefined;

  service: any;
  saving: boolean;

  protected phoneNumberPattern = {
    'A': { pattern: new RegExp('^[+]{1}1$') },
    'N': { pattern: new RegExp('\\d') }
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void { }

  saveService(): void {
    this.serviceForm.form.markAllAsTouched();
    if (!this.serviceForm.form.valid) return;
    this.service.isGlobal = true;
    this.saving = true;
    this._systemLevelService.saveGlobalService(
      this.service.telephonyServiceId ? `sms/${this.service.telephonyServiceId}` : 'sms',
      this.service
    ).subscribe({
      next: res => {
        this.saving = false;
        this.activeModal.close(res);
      },
      error: err => {
        this.saving = false;
        this._notificationService.showError(
          err ? err.message : 'Unable to save global sms.',
          'Global SMS'
        );
      }
    })
  }
}
