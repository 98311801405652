import { Component, Injector, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-import-lead-from-du-dialog',
  templateUrl: './import-lead-from-du-dialog.component.html',
  styleUrls: ['./import-lead-from-du-dialog.component.scss']
})
export class ImportLeadFromDuDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  message: string = null;
  isSaving: boolean = false;
  fileToUpload: File | null = null;

  constructor(public activeModal: NgbActiveModal,
    private readonly injector: Injector,
    private readonly _leadsService: LeadsService,
    private readonly _notifyService: NotificationService) {
    super(injector)
  }

  ngOnInit(): void {
  }

  importLeadDuFile = () => {

    this.isSaving = true;

    this._leadsService.uploadLoanFile(this.fileToUpload)
      .subscribe((response) => {
        this.isSaving = false;
        this.activeModal.close(response);
        this._notifyService.showSuccess('File imported successfully', '')

      }, (err) => {
        let errorMessage = err.error ? err.error.message : err.message;
        this._notifyService.showError(errorMessage, 'Error')
        this.isSaving = false;
      });

  }

  validateDuFile = (files: File[]) => {

    let file = files[0];
    if (!file) {
      this.message = 'Please upload a MISMO 3.4 or DU 3.2 file.';
      return;
    }

    this.message = null;
    this.fileToUpload = file;
  }

}
