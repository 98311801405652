<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="text-center">
            <span>
                Would you like to reassign this application to the branch the loan officer is assigned to?
            </span>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
    <div style="flex: auto;">
        <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
    </div>
    <button type="button" class="btn btn-primary right" (click)="onConfirmUpdateClicked('yes')"> Yes </button>
    <button type="button" class="btn btn-secondary right" (click)="onConfirmUpdateClicked('no')"> No </button>
</div>