import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FeeDefinition } from '../../../models/doc-prep/fee-definition.model';
import { splitCamelCase, toProperCase } from '../../../core/services/string-utils';
import { Table } from 'primeng/table';

@Component({
  selector: 'invoicing-table',
  templateUrl: './invoicing-table.component.html',
  styleUrls: ['./invoicing-table.component.scss']
})
export class InvoicingTableComponent implements OnChanges {
  @Input() items: readonly FeeDefinition[];
  @Input() title?: string;
  @Input() tableActions?: readonly InvoicingTableAction[];
  @Input() invoicingActions?: readonly InvoicingTableItemAction[];
  @Input() loading: boolean = false;
  @Input() selectable: boolean = false;
  @Input() set selection(value: FeeDefinition[]) {
    this.selectedItems = value;
  }
  @Output() selectionChange = new EventEmitter<FeeDefinition[]>();

  protected selectedItems: FeeDefinition[] = [];
  protected hasInvoicingActions: boolean = false;

  private _fields: readonly TableField[] = Object.freeze([
    {
      name: 'name',
      isSortable: true,
    },
    {
      name: 'description',
    },
    {
      name: 'amount',
      isSortable: true,
    },
  ]);

  protected readonly columns: readonly TableColumn[] = Object.freeze(
    this._fields.map(({ isSortable, name }, i) => ({
      field: name,
      header: toProperCase(splitCamelCase(name)),
      order: i + 1,
      isSortable: isSortable,
    })),
  );
  protected totalColumns: number = this.columns.length;

  ngOnChanges(changes: SimpleChanges) {
    const invoicingActionsChange = changes.invoicingActions;
    if (invoicingActionsChange != null) {
      this.hasInvoicingActions = invoicingActionsChange.currentValue?.length > 0;
      this.resetTotalColumns();
    }

    const selectableChange = changes.selectable;
    if (selectableChange != null) {
      this.resetTotalColumns();
    }
  }

  private resetTotalColumns(): void {
    this.totalColumns = this.columns.length
      + (this.invoicingActions?.length ?? 0)
      + (this.selectable ? 1 : 0);
  }

  protected onSelectedItemsChange(items: FeeDefinition[]): void {
    this.selectedItems = [...items];
    this.selectionChange.emit(items);
  }

  protected onSearch(event: Event, table: Table): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    table.filterGlobal(value, 'contains');
  }

  protected onTableAction(action: InvoicingTableAction): void {
    action.onAction();
  }

  protected onInvoicingAction(action: InvoicingTableItemAction, item: FeeDefinition): void {
    action.onAction(item);
  }
}

interface TableField {
  readonly name: string;
  readonly isSortable?: boolean;
}

interface TableColumn {
  readonly field: string;
  readonly header: string;
  readonly order: number;
  readonly isSortable?: boolean;
}

export class InvoicingTableAction {
  readonly template: TemplateRef<any>;
  readonly onAction: () => void;

  constructor({template, onAction}: InvoicingTableAction) {
    this.template = template;
    this.onAction = onAction;
  }
}

export class InvoicingTableItemAction {
  readonly template: TemplateRef<any>;
  readonly onAction: (item: FeeDefinition) => void;

  constructor({template, onAction}: InvoicingTableItemAction) {
    this.template = template;
    this.onAction = onAction;
  }
}
