<div class='card'>
  <div class='card-header'>
    <h4 class='card-title'>
      Invoicing
    </h4>
  </div>

  <div class='card-body'>
    <form #docPreInvoicingForm="ngForm" id="docPreInvoicingForm">
      <div class="row">
        <div class="col-md-4 form-group">
          <label for="invoicePaidAmount"> Amount Paid </label>
          <currency-input [(ngModel)]="docPrep.invoicePaidAmount" name="invoicePaidAmount"></currency-input>
        </div>
        <div class="col-md-4 form-group">
          <label for="closingDate"> Date Paid </label>
          <date-input name="invoicePaidDate" [id]="'invoicePaidDate'"
            [(ngModel)]="docPrep.invoicePaidDate"></date-input>
        </div>
        <div class="col-md-4 form-group">
          <label for="invoiceTotal"> Invoice Total </label>
          <currency-input [(ngModel)]="docPrep.invoiceTotal" name="invoiceTotal" readonly="true"></currency-input>
        </div>
      </div>
    </form>
    <invoicing-table
      [items]='items'
      title='Fees'
      [loading]='loading'
      [selectable]='true'
      [selection]='selection'
      (selectionChange)='onSelectionChange($event)'
    ></invoicing-table>
  </div>
</div>
