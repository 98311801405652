<div class="modal-body modal-body--scroll" id="lead-editor-main">
  <div class="card" *ngIf="agentFull?.agent?.agentCompany">
    <div class="card-body m-2">
      <div class="row">
        <div class="form-group col-md-3">
          <label class="form-label" for="companyName"> Company Name </label>
          <input
            class="form-control"
            id="companyName"
            name="companyName"
            [(ngModel)]="agentFull.agent.agentCompany.companyName"
          />
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="website"> Company Website </label>
          <input
            class="form-control"
            id="website"
            name="website"
            [(ngModel)]="agentFull.agent.agentCompany.website"
          />
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="companyStateLicenseState">
            Company State License State
          </label>
          <select
            class="form-select"
            id="companyStateLicenseState"
            name="companyStateLicenseState"
            [(ngModel)]="agentFull.agent.agentCompany.companyStateLicenseState"
          >
            <option value="null">-- Select One --</option>
            <option *ngFor="let s of states" value="{{ s.value }}">
              {{ s.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="companyStateLicenseIssueDate">
            Company State License Issue Date
          </label>
          <date-input
            [name]="'companyStateLicenseIssueDate'"
            [id]="'companyStateLicenseIssueDate'"
            [(ngModel)]="
              agentFull.agent.agentCompany.companyStateLicenseIssueDate
            "
          ></date-input>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-3">
          <label class="form-label" for="companyStateLicenseId"> Company State License ID# </label>
          <input
            class="form-control"
            id="companyStateLicenseId"
            name="companyStateLicenseId"
            [(ngModel)]="agentFull.agent.agentCompany.companyStateLicenseId"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-3">
          <label class="form-label" for="companyAddress1">
            Company Address
          </label>
          <address-input
            [name]="'companyAddress1'"
            [(ngModel)]="agentFull.agent.agentCompany.companyAddress1"
            (addressChanged)="onAddressChanged($event)"
          ></address-input>
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="companyCity">Company City</label>
          <input
            class="form-control"
            id="companyCity"
            name="companyCity"
            [(ngModel)]="agentFull.agent.agentCompany.companyCity"
          />
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="companyState">Company State</label>
          <select
            class="form-select"
            id="companyState"
            name="companyState"
            [(ngModel)]="agentFull.agent.agentCompany.companyState"
          >
            <option value="null">-- Select One --</option>
            <option *ngFor="let state of states" value="{{ state.value }}">
              {{ state.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="companyZip">Company Zip</label>
          <zip-code-input
            [(ngModel)]="agentFull.agent.agentCompany.companyZip"
            [name]="'companyZip'"
            (selectionChanged)="onZipCodeRelatedInfoChanged($event)"
          ></zip-code-input>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-3">
          <label class="form-label" for="lenderOwnershipShare">
            Lender Ownership Share
          </label>
          <percent-input
            name="lenderOwnershipShare"
            [(ngModel)]="agentFull.agent.agentCompany.lenderOwnershipShare"
            [maxValue]="100"
          ></percent-input>
        </div>
        <div class="form-group col-md-3">
          <label class="form-label" for="natureOfRelationshipWithLender">
            Nature of Relationship with Lender
          </label>
          <input
            class="form-control"
            id="natureOfRelationshipWithLender"
            name="natureOfRelationshipWithLender"
            [(ngModel)]="
              agentFull.agent.agentCompany.natureOfRelationshipWithLender
            "
            maxlength="100"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer w-100">
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-primary me-2"
      type="button"
      (click)="saveAgentCompany()"
    >
      <i class="fa fa-save"></i>
      Save
    </button>
  </div>
</div>
