<p-table [value]="conditionReports" [paginator]="false">
  <ng-template pTemplate="header">
    <tr>
      <th>Package Type</th>
      <th>Errors</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-conditionReport>
    <tr>
      <td>{{conditionReport['packageTypeName']}}</td>
      <td>
        <ul class="mb-0">
          <li *ngFor="let error of conditionReport.errors">
            {{error}}
          </li>
        </ul>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="2" class="text-center">
        There are no report item
      </td>
    </tr>
  </ng-template>
</p-table>
