<div class="modal-header" *ngIf="!isDrawer">
  <h5 class="modal-title">{{title}}</h5>
  <button
    type="button"
    class="btn-close"
    (click)="closedDialog()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <agent-info [agentFull]="agentFull" [agentType]="agentType" [isAgentTypeRequired]="isAgentTypeRequired" (savedAgent)="savedAgent($event)" (closed)="closedDialog()"></agent-info>
</div>
