import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CocDetailsRoutingModule } from './coc-details-routing.module';
import { CocDetailsComponent } from './coc-details/coc-details.component';
import { CocReasonsComponent } from './coc-reasons/coc-reasons.component';
import { TableModule } from 'primeng/table';
import { CocReasonsService } from './coc-details.service';
import { CocReasonPipe } from './coc-reasons/coc-reasons.pipe';


@NgModule({
  declarations: [
    CocReasonsComponent,
    CocDetailsComponent,
    CocReasonPipe
  ],
  imports: [
    CocDetailsRoutingModule,
    SharedModule,
    TableModule,
  ],
  exports: [
    CocReasonsComponent
  ],
  providers: [
    CocReasonsService
  ]
})
export class CocDetailsModule { }
