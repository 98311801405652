import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GovernmentLoanDetail, MortgageInsuranceDetail, MortgageTerm } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageInsuranceCalculationDialogComponent } from '../mortgage-insurance-calculation-dialog/mortgage-insurance-calculation-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'mortgage-insurance-dialog',
  templateUrl: './mortgage-insurance-dialog.component.html',
  styleUrls: ['./mortgage-insurance-dialog.component.scss']
})
export class MortgageInsuranceDialogComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = _.cloneDeep(mortgage);
    if (!this._mortgage.governmentLoanDetail) {
      this._mortgage.mortgageInsuranceDetail = new MortgageInsuranceDetail();
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  premiumRefundableTypeOptions: EnumerationItem[] = [];
  renewalTypeOptions: EnumerationItem[] = [];
  renewalCalculationTypeOptions: EnumerationItem[] = [];
  premiumRatePlanTypeOptions: EnumerationItem[] = [];
  paymentFrequencyOptions: EnumerationItem[] = [];
  durationTypeOptions: EnumerationItem[] = [];
  premiumSourceOptions: EnumerationItem[] = [];
  constructor(private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    public activeModal: NgbActiveModal) { }

  private _mortgage: UrlaMortgage;

  ngOnInit(){
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.premiumRefundableTypeOptions = result[Constants.mortgageEnumerations.premiumRefundableType];
      this.renewalTypeOptions = result[Constants.mortgageEnumerations.renewalType];
      this.renewalCalculationTypeOptions = result[Constants.mortgageEnumerations.renewalCalculationType];
      this.premiumRatePlanTypeOptions = result[Constants.mortgageEnumerations.premiumRatePlanType];
      this.paymentFrequencyOptions = result[Constants.mortgageEnumerations.paymentFrequencyType];
      this.durationTypeOptions = result[Constants.mortgageEnumerations.durationType];
      this.premiumSourceOptions = result[Constants.mortgageEnumerations.premiumSourceType];
    });
  }

  onIsPrepaidChanged = () => {
    if (!this._mortgage.mortgageInsuranceDetail.isMIPrepaid) {
      this._mortgage.mortgageInsuranceDetail.prepaidPremiumTotal = null;
    }
  }

  onOkClicked = () => {
    this.activeModal.close(this._mortgage);
  }

  onMonthlyAmountCalculatorClicked = () => {
    const modalRef = this._modalService.open(MortgageInsuranceCalculationDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.mortgage = this._mortgage;
    modalRef.result.then((mortgage: UrlaMortgage) => {
      this._mortgage.mortgageInsuranceDetail = mortgage.mortgageInsuranceDetail;
      this._mortgage.governmentLoanDetail = mortgage.governmentLoanDetail;
      this._mortgage.mortgageTerm = mortgage.mortgageTerm;
    }, error => {})
  }
}
