import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddApplicationDialogComponent } from '../applications/add-application-dialog/add-application-dialog.component';
import { ApplicationsModule } from '../applications/applications.module';
import { BorrowerModule } from '../borrower/borrower.module';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { AddApplicationComponent } from './components/add-application/add-application.component';
import { ApplicationDialogComponent } from './components/application-dialog/application-dialog.component';
import { NewContactComponent } from './components/new-contact/new-contact.component';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ContactsComponent } from './contacts.component';
import { ContactNotesComponent } from './components/contact-notes/contact-notes.component';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ContactsRoutingModule,
    TableModule,
    MultiSelectModule,
    ApplicationsModule,
    CorrespondenceModule,
    BorrowerModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
    ContactsComponent,
    AddApplicationComponent,
    AddApplicationDialogComponent,
    NewContactComponent,
    ApplicationDialogComponent,
    ContactNotesComponent
  ],
  exports: [
    ContactsComponent,
    NewContactComponent,
    AddApplicationComponent,
    ApplicationDialogComponent,
    ContactNotesComponent
  ]
})

export class ContactsModule {}
