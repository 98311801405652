<form #digitsInputForm="ngForm">
  <div *ngIf="!inEditMode"
    class="form-input"
    [ngClass]="
      {
        'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested') || (required && !fieldBeingEdited)),
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'no-border' : !showBorder,
        'bg-transparent' : readonly
      }"
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
    <span class="input-label" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>
    <input
      type="text"
      numeric
      [allowNegative]="allowedNegative"
      [(ngModel)]="value"
      #model="ngModel"
      name="{{name}}"
      [required]="required"
      [placeholder]="placeholder ? placeholder : ''"
      [ngClass]="
        {
          'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested') || (required && !fieldBeingEdited)),
          'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
          'no-border' : !showBorder
        }"
      [max]="max"
      [min]="min"
      [attr.maxLength]="maxLength ? maxLength.toString() : null"
      [disabled]="disabled"
      class="input-element"
      (blur)="onBlurred($event)"
      [readonly]="readonly"/>
  </div>
  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
