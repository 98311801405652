import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TableModule } from "primeng/table";
import { SharedModule } from 'src/app/shared/shared.module';
import { LeadTableComponent } from "./lead-table.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { EditLeadNoteComponent } from "./edit-lead-note/edit-lead-note.component";

@NgModule({
  declarations: [
    LeadTableComponent,
    EditLeadNoteComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    PopoverModule.forRoot(),
  ],
  exports: [
    LeadTableComponent
  ]
})
export class LeadTableModule { }
