import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loan-status-card',
    templateUrl: 'loan-status-card.component.html',
    styleUrls: ['./loan-status-card.component.scss']
})

export class LoanStatusCardComponent implements OnInit {
    constructor() { }

    @Input() loanStatusName: string;

    ngOnInit() {
    }
}