import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LoanSummaryBarComponent } from "./components/loan-summary-bar.component";
import { LoanFicoModule } from "./modules/loan-fico/loan-fico.module";
import { LoanLtvModule } from "./modules/loan-ltv/loan-ltv.module";
import { LoanPricingModule } from "./modules/loan-pricing/loan-pricing.module";
import { LoanDtiModule } from "./modules/loan-dti/loan-dti.module";
import { LoanFtcModule } from "./modules/loan-ftc/loan-ftc.module";
import { LoanStatusModule } from "./modules/loan-status/loan-status.module";
import { LoanLosLdeModule } from "./modules/loan-los-lde/loan-los-lde.module";
import { CharacteristicsModule } from "../app-details/modules/characteristics/characteristics.module";
import { LEandCDValidationComponent } from "./components/le-cd-validation/le-cd-validation.component";
import { TableModule } from "primeng/table";

@NgModule({
  imports: [
    TableModule,
    SharedModule,
    LoanFicoModule,
    LoanLtvModule,
    LoanPricingModule,
    LoanDtiModule,
    LoanFtcModule,
    LoanStatusModule,
    LoanLosLdeModule,
    CharacteristicsModule
  ],
  declarations: [
    LoanSummaryBarComponent,
    LEandCDValidationComponent
  ],
  exports: [
    LoanSummaryBarComponent
  ],
  providers: [
  ]
})
export class LoanSummaryBarModule {
  constructor() {
  }
}


