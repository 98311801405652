import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContext, Borrower, MortgageBorrower } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { MortgageService } from 'src/app/services/mortgage.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { BorrowersService } from '../../services/borrowers.service';
import { NewMortgageBorrowerDialogComponent } from './new-mortgage-borrower-dialog/new-mortgage-borrower-dialog.component';
import { Subscription } from "rxjs";

@Component({
  selector: 'select-borrower',
  templateUrl: './select-borrower.component.html',
  styleUrls: ['./select-borrower.component.scss']
})
export class SelectBorrowerComponent extends ApplicationContextBoundComponent implements OnInit {

  unownedBorrowers: Borrower[] = null;
  borrowerId: number;

  @Input() loanId: number | null = null;
  @Input() isMortgage: boolean = false;
  @Input() mortgageId: number;

  @Output() selectedBorrower = new EventEmitter<Borrower>();
  @Output() savedBorrower = new EventEmitter<MortgageBorrower>();

  applicationMode: string;

  private _loanInfoChangesSubscription: Subscription;
  private _activatedRouteQueryParamsSubscription: Subscription;

  private _comingFromLoanSummary: boolean = false;

  constructor(private readonly injector: Injector,
    private readonly _borrowerService: BorrowersService,
    private readonly _mortgageService: MortgageService,
    private readonly _notifsService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly _modalService: NgbModal) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.initialize(context);
      }
    });
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
      this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
        if (queryParams.s) {
          this._comingFromLoanSummary = true;
        }
      })
    }
  }

  ngOnInit() {
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    if (this.isMortgage) {
      if (this.mortgageId) {
        this.loadMortgageBorrowers(this.mortgageId);
      }
    }
    else {
      if (this.loanId) {
        this.loadUnownedBorrowers(this.loanId);
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._activatedRouteQueryParamsSubscription) {
      this._activatedRouteQueryParamsSubscription.unsubscribe();
    }
  }

  loadUnownedBorrowers = (loanId: number) => {
    this._borrowerService.getBorrowersNotOwnedByLoan(loanId).subscribe(result => {
      this.unownedBorrowers = result;
    }, error => {

    });
  }

  loadMortgageBorrowers = (mortgageId: number) => {
    this._borrowerService.getBorrowersNotOwnedByLoan(this.loanId)
      .subscribe({
        next: (borrowers: Borrower[]) => {

          this._mortgageService.getMortgageBorrowers(mortgageId)
            .subscribe({
              next: (mortgageBorrowers: MortgageBorrower[]) => {
                this.unownedBorrowers = borrowers.filter(b => !mortgageBorrowers.map(mb => mb.borrowerId).includes(b.borrowerId));
              },
              error: () => {

              }
            });
        },
        error: () => {
        }
      })
  }

  onCreateBorrowerClicked = () => {
    if (this.isMortgage) {
      const modalRef = this._modalService.open(NewMortgageBorrowerDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.mortgageId = this.mortgageId;
      modalRef.componentInstance.loanId = this.loanId;

      modalRef.result.then((newBorrower: MortgageBorrower) => {
        this.savedBorrower.emit(newBorrower);
      }, error => { })
    }
    else {

      if (this._navigationService.applicationMode == ApplicationMode.Classic) {
        this._router.navigate([`admin/app-details/${this.applicationContext.application.applicationId}/add-borrower`]);
      }
      if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
        this._router.navigate(["/loda-nextgen/borrower/add-borrower"]);
      }

    }
  }

  onSelectClicked = (borrowerId: number) => {
    if (this.isMortgage) {
      let selected = this.unownedBorrowers.find(b => b.borrowerId == borrowerId);
      this.selectedBorrower.emit(selected);
    } else {
      this._spinner.show();
      this._borrowerService.selectBorrower(this.loanId, borrowerId).subscribe({
        next: () => {
          this._notifsService.showSuccess(
            'Selection has been sent succesfully.',
            'Success!'
          );

          this.applicationContextService.updateBorrowers().subscribe({
            next: () => {
              let urlToNavigateTo = 'admin/app-details/' + this.loanId;
              if (this._comingFromLoanSummary) {
                urlToNavigateTo += "/loan-summary";
              }

              this._router.navigate([urlToNavigateTo]);
            },
            error: (error) => {
              this._notifsService.showError(
                error ? error.message : 'Unable to update borrower!',
                'Error!'
              );
            }
          }).add(() => this._spinner.hide());
        },
        error: (error) => {
          this._notifsService.showError(
            error ? error.message : 'Unable to select Loan!',
            'Error!'
          );
          this._spinner.hide();
        }
      });
    }
  }

  private initialize = (context: ApplicationContext) => {
    this.loanId = context.application.applicationId;
    this.borrowerId = context.application.primaryBorrowerId;
  }
}
