<p-table #dt1 [value]="filteredApplications" [columns]="selectedColumns" [paginator]="true" [rows]="25"
  [resizableColumns]="true" responsiveLayout="scroll" name="application-table" [rowsPerPageOptions]="[10, 25, 50, 100]"
  [globalFilterFields]="globalFilterFields" [autoLayout]="true" [(selection)]="selectedRows"
  [scrollable]="applicationTableConfig && applicationTableConfig.scrollable" [scrollHeight]="scrollHeight"
  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  styleClass="p-datatable-sm p-datatable-gridlines" stateStorage="local" stateKey="applications-table-state" sortMode="multiple"
  [multiSortMeta]="!tableState ? [{field: 'appCreateDate', order: -1}] : tableState.multiSortMeta">
  <ng-template pTemplate="caption">
    <div class="table-toolbar">
      <div class="toolbar__add-new-btn" *ngIf="applicationTableConfig.isAddNewButtonVisible">
        <button class="btn btn-primary me-2"
          (click)="onAddNewClicked()">
          <i class="fas fa-plus"></i> Add New
        </button>
      </div>

      <div class="toolbar__add-to-dial-btn"
        style='margin-right: -0.35rem'>
        <app-add-to-dial-list *ngIf="applicationTableConfig.isAddToDialListButtonVisible"
          [selectedRows]="selectedRows"
          [contactListType]="contactListType"
          [idFieldName]="'applicationId'"
          (onAddedToDialList)="addedToDialList($event)"
        ></app-add-to-dial-list>
      </div>

      <div class="toolbar__search">

        <!-- mobile -->
        <div class="btn-group hide-on-non-mobile">
          <button
            type="button"
            class="btn text-primary"
            data-bs-container="body"
            data-bs-toggle="popover"
            data-trigger="click"
            placement="bottom"
            (click)="openColumnSelectorForMobile()"
            [popover]="mobileColumnSelectorPopover"
            [outsideClick]="true"
            [containerClass]="'mobile-col-selector-popover'"
          >
            <i class="fa fa-cog"></i>
          </button>
          <ng-template #mobileColumnSelectorPopover>
            <p-multiSelect #mobileColumnSelector
              [options]="applicationTableConfig.columns"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              (ngModelChange)="onSelectedColumnsChanged()"
              selectedItemsLabel="{0} columns selected"
              name="selectedColumns"
              ngDefaultControl
              defaultLabel="Choose Columns"
            ></p-multiSelect>
          </ng-template>
        </div>

        <!-- desktop -->
        <p-multiSelect
          [options]="applicationTableConfig.columns"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          (ngModelChange)="onSelectedColumnsChanged()"
          selectedItemsLabel="{0} columns selected"
          name="selectedColumns"
          ngDefaultControl
          defaultLabel="Choose Columns"
          class="hide-on-mobile"
        ></p-multiSelect>

        <!-- desktop and mobile -->
        <span class="p-input-icon-right ms-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="form-control" [(ngModel)]="globalSearchString" (ngModelChange)="onGlobalSearchStringChanged($event)"
            placeholder="Search keyword" />
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn>
        {{ col.header }}
        <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr class="table-row-height--{{applicationTableConfig.rowHeight}}">
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'applicationId'">
          <span *ngIf="applicationTableConfig.isAddToDialListButtonVisible">
            <p-tableCheckbox [value]="rowData">
            </p-tableCheckbox>
            <span class="me-2">|</span>
          </span>
          <span *ngIf="applicationContext.userPermissions.admin && !applicationTableConfig.isHideDeleteButton">
            <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(rowData)" title="Delete"></i>
            <span class="mx-2">|</span>
          </span>
          <a class="text-cell-primary" [routerLink]="[url + rowData.applicationId]" (click)="onViewLoanDetailsClicked(rowData)">{{ rowData['applicationIdWithPadding'] }}</a>
          <br />
          <span class="text-muted text-ellipsis cell-mw150"
            title="{{rowData.loanPurposeName}} {{rowData.loanTypeName ? ('- ' + rowData.loanTypeName) : ''}}">{{rowData.loanPurposeName}}
            {{rowData.loanTypeName ? ('- ' + rowData.loanTypeName) : ''}}</span>
        </span>

        <span *ngSwitchCase="'lastName'">
          <span class="text-cell-primary text-ellipsis cell-mw225" title="{{ rowData.displayName}}" >{{ rowData.displayName}}</span>
          <br />
          <span class="text-muted text-ellipsis cell-mw225"
            title="{{ rowData.mailingStreet || '--' }} {{rowData.mailingCity}}{{rowData.mailingCity ? ',' : ''}} {{rowData.mailingState ? rowData.mailingState.toUpperCase() : ''}} {{rowData.mailingZip}}">{{
            rowData.mailingStreet || '--' }}
            {{rowData.mailingCity}}{{rowData.mailingCity ? ',' : ''}}
            {{rowData.mailingState ? rowData.mailingState.toUpperCase() : ''}} {{rowData.mailingZip}}</span>
        </span>

        <span *ngSwitchCase="'mobilePhone'">
          <table-cell-contact-info [contactInfo]="rowData" [recordType]="'Application'"
            [recordId]="rowData.applicationId" [dialerEnabled]="dialerEnabled"></table-cell-contact-info>
        </span>
        <span *ngSwitchCase="'loanAmount'">
          <span class="text-cell-primary">{{ rowData.loanAmount | currency }}</span>
          <br>
          <span class="text-muted">{{ rowData.ltv ? 'LTV ' + (rowData.ltv/100 | percent: '1.2-2') : '--'}}</span>
        </span>
        <span *ngSwitchCase="'loanStatusName'">
          <span class="text-cell-primary text-ellipsis cell-mw200" title="{{ rowData['loanStatusName'] }} {{
            rowData['subStatusName'] && rowData['subStatusName'].length > 0
            ? ('- ' + rowData['subStatusName'])
            : ''
            }}">{{ rowData['loanStatusName'] }} {{
            rowData['subStatusName'] && rowData['subStatusName'].length > 0
            ? ('- ' + rowData['subStatusName'])
            : ''
            }}</span>
          <br />
          <span class="text-muted">
            {{ rowData['enteredLoanStatus'] }}
          </span>
        </span>
        <span *ngSwitchCase="'appCreateDate'">
          <span class="text-cell-primary" *ngIf="rowData.appCreateDate">{{ rowData.appCreateDate | date: 'short' }}</span>
          <br>
          <span class="text-muted" *ngIf="rowData.lastUpdated">Last Updated {{rowData.lastUpdated}}</span>
        </span>
        <span *ngSwitchCase="'interestRate'">
          <span class="text-cell-primary">{{ rowData.interestRate | percent: '1.3-3' }}</span>
        </span>
        <span *ngSwitchCase="'primaryRoleContact'" (click)="onShowLoanTeamClicked($event, rowData)">
          <div class="d-flex " *ngIf="rowData.primaryRoleContact">
            <div class="img-group">
              <a class="user-avatar user-avatar-group">
                <img src="{{baseAvatarUrl}}avatars/user-profile/{{rowData.primaryRoleContactId}}"
                  onerror="this.src='assets/images/male.png'" alt="user" class="rounded-circle thumb-xs">
              </a>
              <a class="avatar-box thumb-xs align-self-center">
                <span
                  class="avatar-title bg-soft-info rounded-circle font-13 font-weight-normal ms-1">+{{rowData.internalContactCount-1}}</span>
              </a>
            </div>
            <span style="padding-left: 10px;"
              class="align-self-center text-cell-primary">{{rowData.primaryRoleContact}}</span>
          </div>
        </span>

        <span *ngSwitchDefault>
          <span class="text-cell-primary">{{ rowData[col.field] }}</span>
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="selectedColumns ? selectedColumns.length + 1 : null" class="text-center">
        There are no applications
      </td>
    </tr>
  </ng-template>
</p-table>

<context-menu class="list-group shadow-lg" #contextMenuContacts style="pointer-events:all">

  <ng-template contextMenuItem [subMenu]="subMenu" *ngFor="let player of loanTeam" class="ngcontext-menu-first-level">
    <div (mouseenter)="setCurrentLoanContact(player)" style="margin:0px;">
      <i class="fa fa-user pr-1"></i><span class="mx-2 text-cell-primary">{{player.label}} <span
          class="text-muted mx-1 fw-normal">({{player.role}})</span></span>
    </div>
  </ng-template>
  <ng-template *ngIf="!loanTeam" contextMenuItem>
    <loading-indicator></loading-indicator>
  </ng-template>
  <context-menu #subMenu class="shadow-lg">
    <ng-template contextMenuItem>
      <div *ngIf="currentLoanContact">
        <div style="text-align: center">
          <img [src]="currentLoanContact.user.userImage" onerror="this.src='assets/images/male.png'"
            class="rounded-circle" alt="..." width="100px" height="100px">
          <div class="card-body text-center border-0">
            <h5 class="card-title">{{currentLoanContact.name}}</h5>
            <p class="text-muted">{{currentLoanContact.role}}</p>
          </div>
        </div>
        <ul>
          <li class="list-group-item text-primary" *ngIf="currentLoanContact.user.homePhone">
            <a href="tel:{{currentLoanContact.user.homePhone}}" (click)="$event.stopPropagation()">
              <i class='fa fa-phone mx-1'></i>
              H: {{currentLoanContact.user.homePhone ? currentLoanContact.user.homePhone : '- '}}</a>
          </li>
          <li class="list-group-item text-primary" *ngIf="currentLoanContact.user.cellPhone">
            <a href="tel:{{currentLoanContact.user.cellPhone}}" (click)="$event.stopPropagation()">
              <i class='fa fa-phone mx-1'></i>
              M: {{currentLoanContact.user.cellPhone?currentLoanContact.user.cellPhone : '- '}}</a>
          </li>
          <li class="list-group-item text-primary" *ngIf="currentLoanContact.user.email">
            <a href="mailto:{{currentLoanContact.user.email}}" (click)="$event.stopPropagation()">
              <i class='fa fa-envelope mx-1'></i>
              {{currentLoanContact.user.email}}
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </context-menu>
</context-menu>
