<div class="lower-payment">
  <h3 class="lp-style-10">
    <span class="lp-style-11">Lower Payment</span>
  </h3>

  <div class="lp-style-12">
    <img
      [src]="imageData"
      style="width: auto; height: 120px; margin: auto"
      class="lp-style-13"
    />
  </div>

  <div class="lp-style-14">
    <div class="lp-style-15">
      <housing-calculator
        class="lp-style-16"
        [mortgage]="mortgage"
        [parameters]="todayParameters"
        [title]="'Today'"
        [currentLoanInterestRate]="currentLoanInterestRate"
        [currentLoanTerm]="currentLoanTerm"
        [readOnlyAll]="true"
        (parametersChanged)="onParametersChanged($event, true)"
      >
      </housing-calculator>
    </div>
    <div class="lp-style-95">
      <housing-calculator
        class="lp-style-96"
        [mortgage]="mortgage"
        [parameters]="proposedParameters"
        [readOnlyAll]="true"
        (parametersChanged)="onParametersChanged($event)"
      ></housing-calculator>
    </div>
  </div>

  <div class="lp-style-175">
    <div class="lp-style-176">
      <table class="lp-style-177">
        <tbody class="lp-style-178">
          <tr class="lp-style-179">
            <td class="lp-style-180">
              PAYMENT
              {{
                paymentReductionResuls.monthlySavings >= 0
                  ? 'INCREASE'
                  : 'REDUCTION'
              }}
            </td>
            <td class="lp-style-181">
              <div class="lp-style-182">
                <span class="form-control" readonly>
                  {{paymentReductionResuls.monthlySavings | currency: 'USD':'symbol':'.2':'en-US'}}
                </span>
              </div>
            </td>
          </tr>
          <tr class="lp-style-188">
            <td class="lp-style-189">3 Months Savings</td>
            <td class="lp-style-190">
              <div class="lp-style-191">
                <span class="form-control" readonly>
                  {{paymentReductionResuls.threeMonthsSavings | currency: 'USD':'symbol':'.2':'en-US'}}
                </span>
              </div>
            </td>
          </tr>
          <tr class="lp-style-197">
            <td class="lp-style-198">6 Months Savings</td>
            <td class="lp-style-199">
              <div class="lp-style-200">
                <span class="form-control" readonly>
                  {{paymentReductionResuls.sixMonthsSavings | currency: 'USD':'symbol':'.2':'en-US'}}
                </span>
              </div>
            </td>
          </tr>
          <tr class="lp-style-206">
            <td class="lp-style-207">Annual Savings</td>
            <td class="lp-style-208">
              <div class="lp-style-209">
                <span class="form-control" readonly>
                  {{paymentReductionResuls.annualSavings | currency: 'USD':'symbol':'.2':'en-US'}}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="lp-style-215">
      <div class="lp-style-216">
        <img
          [src]="imageData2"
          style="width: auto; height: 120px; margin: auto"
          class="lp-style-217"
        />
      </div>
    </div>
  </div>
</div>
