import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ExpressionGroup } from 'src/app/models/expressions/expression-group.model';
import { ExpressionTerm } from 'src/app/models/expressions/expression-term.model';
import { Expression } from 'src/app/models/expressions/expression.model';

@Injectable()
export class ExpressionBuilderDialogService {
  constructor(private readonly _dataService: DataService) {}

  getFieldOperators(expressionTerm: ExpressionTerm): Observable<Array<string>> {
    return this._dataService.get(
      `api/campaign/operators/${expressionTerm.field.fieldType}/${expressionTerm.field.dataType}`
    );
  }

  saveExpressionGroups(data: ExpressionGroup): Observable<ExpressionGroup> {
    let url = 'api/expressions/groups';
    if (data.expressionGroupId) {
      url += `/${data.expressionGroupId}`;
    }

    return this._dataService.post(url, data);
  }

  getExpressionGroup = (expressionGroupId: number): Observable<ExpressionGroup>  => {
    let url = `api/expressions/groups/${expressionGroupId}`;
    return this._dataService.get(url);
  }

  getExpression = (expressionId: number): Observable<Expression>  => {
    let url = `api/expressions/${expressionId}`;
    return this._dataService.get(url);
  }

  deleteExpression(expressionGroupId: number): Observable<undefined> {
    if (expressionGroupId == -1) {
      return throwError(() => Error("You can't remove default expression."));
    }

    return this._dataService.delete(
      `api/expressions/groups/${expressionGroupId}`
    );
  }
}
