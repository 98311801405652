import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'set-friendly-name',
    templateUrl: './set-friendly-name.component.html',
})
export class SetFriendlyNameComponent implements OnInit {

    @Output() friendlyNameSet: EventEmitter<any> = new EventEmitter<any>();

    friendlyName: string;

    constructor() {}

    ngOnInit(): void {}
}
