import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Constants } from 'src/app/services/constants';
import { DocumentType, ThirdPartyCredential } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { NotificationService } from '../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../services/system-level.service';
import { ScopeType } from '../integrations.component';
import { DocusignCredentialsEditorDialogComponent } from './docusign-credentials-editor-dialog/docusign-credentials-editor-dialog.component';

@Component({
  selector: 'e-signing',
  templateUrl: './e-signing.component.html'
})
export class ESigningComponent implements OnInit {
  @Input()
  scope: ScopeType;

  @Input()
  documentTypes: Array<DocumentType>;

  @Input()
  documentSigningEnabled: Configuration;

  @Input()
  losDocumentSigningEnabled: Configuration;

  @Input()
  vendors: ThirdPartyCredential[] = [];

  isSaving: boolean;

  constructor(
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
  }

  openVendorEditor = (e: ThirdPartyCredential) => {
    const modalRef = this._modalService.open(DocusignCredentialsEditorDialogComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
    modalRef.componentInstance.credential = _.cloneDeep(e);
    modalRef.componentInstance.scope = this.scope;
    modalRef.componentInstance.documentTypes = this.documentTypes;

    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        let existingVendorIndex = this.vendors.findIndex(v => v.credentialId == result.credentialId);
        if (existingVendorIndex > -1) {
          this.vendors[existingVendorIndex] = result;
        }
        else {
          this.vendors.push(result);
        }
      },
      () => { }
    );
  }

  save(): void {

    this.isSaving = true;
    combineLatest([
      this._systemLevelService.saveConfiguration(this.documentSigningEnabled),
      this._systemLevelService.saveConfiguration(this.losDocumentSigningEnabled),
    ]).pipe(finalize(() => {
      this.isSaving = false;
    })).subscribe({
      next: () => {
        this._notificationService.showSuccess(`General purposed settings saved successfully.`, this.scope === 'User' ? 'Profile' : 'System Level');
      },
      error: (err) => {
        this._notificationService.showError(`${err ? err.message : 'Unable to save.'}`, this.scope === 'User' ? 'Profile' : 'System Level');
      }
    });
  }
}
