<div class="modal-body modal-body--scroll" id="createWireEditor">
  <form #createWireForm="ngForm" class="row m-3 mb-0" *ngIf="!isLoading; else spinner">
    <div class="col-md-12">
      <create-wire-request-info
        [wireRequest]="wireRequest"
        [investorNames]="investorNames"
      ></create-wire-request-info>
    </div>
    <div class="col-sm-12 col-md-6">
      <create-wire-request-borrower
        [wireRequest]="wireRequest"
        [ethnicities]="ethnicities"
        [races]="races"
        [sexTypes]="sexTypes"
        (borrowerNamesChange)="onBorrowerNamesChanged()"
      ></create-wire-request-borrower>
    </div>
    <div class="col-sm-12 col-md-6">
      <create-wire-request-coborrower
        [wireRequest]="wireRequest"
        [ethnicities]="ethnicities"
        [races]="races"
        [sexTypes]="sexTypes"
        [isCoBorrowerDisabled]="isCoBorrowerDisabled"
      ></create-wire-request-coborrower>
    </div>
    <div class="col-md-12">
      <create-wire-request-address [wireRequest]="wireRequest"></create-wire-request-address>
    </div>
    <div class="col-md-12">
      <create-wire-request-loan
        [wireRequest]="wireRequest"
        [amortizationTypes]="amortizationTypes"
        [appraisalTypes]="appraisalTypes"
        [documentationTypes]="documentationTypes"
        [loanTypes]="loanTypes"
        [propertyTypes]="propertyTypes"
        [rateTypes]="rateTypes"
        [occupancies]="occupancies"
        [lienPositionTypes]="lienPositionTypes"
      ></create-wire-request-loan>
    </div>
    <div class="text-end mx-3">
      <button class="btn btn-secondary mx-1" type="button" [disabled]="isExporting" (click)="onCloseClicked()">
        Close
      </button>
      <button class="btn btn-primary mx-1" type="button" [disabled]="isExporting" (click)="onExportToCsvClicked()">
        <span *ngIf="isExporting" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
        <span *ngIf="!isExporting" class="me-1"><i class="fas fa-file-csv"></i></span>
        Export to CSV
      </button>
      <button type="button" class="btn btn-primary mx-1" [disabled]="isExporting" (click)="onExportToExcelClicked()">
        <span *ngIf="isExporting" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
        <span *ngIf="!isExporting" class="me-1"><i class="fas fa-file-excel"></i></span>
        Export to Excel
      </button>
    </div>
  </form>
  
  <ng-template #spinner>
    <loading-indicator loadingMessage="Loading wire request data..." [customClass]="'no-shadow'">
    </loading-indicator>
  </ng-template>
</div>
