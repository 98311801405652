<div class="debt-management">
  <h3 class="dm-style-1">
    <span class="dm-style-2">Debt Management</span>
  </h3>

  <div class="dm-style-12">
    <img
      class="dm-style-13"
      [src]="imageData"
      style="width: auto; height: 150px; margin: auto"
    />
  </div>

  <div class="dm-style-14">
    <div class="dm-style-15" style="border-bottom: none">
      <table class="dm-style-16">
        <thead class="dm-style-17">
          <tr class="dm-style-18">
            <th class="dm-style-19">Creditor</th>
            <th class="dm-style-20">Balance</th>
            <th class="dm-style-21">Payment</th>
          </tr>
        </thead>
        <tbody class="dm-style-22">
          <tr *ngFor="let liability of liabilities" class="dm-style-23">
            <td class="dm-style-78">
              <span class="dm-style-79"> {{ liability.holderName }} </span>
            </td>
            <td class="dm-style-80">
              <div class="dm-style-81">
                <span class="form-control" readonly>
                  {{liability.unpaidBalance| currency: 'USD':'symbol':'.2':'en-US'}}
                </span>
              </div>
            </td>
            <td class="dm-style-82">
              <div class="dm-style-83">
                <span class="form-control" readonly>
                  {{liability.monthlyPayment| currency: 'USD':'symbol':'.2':'en-US'}}
                </span>
              </div>
            </td>
          </tr>

          <tr *ngIf="liabilities.length == 0" class="dm-style-23">
            <td colspan="3" class="dm-style-24">
              There are no debts to be paid off
            </td>
          </tr>

          <tr class="dm-style-25">
            <td colspan="2" class="dm-style-26">
              <span class="dm-style-27"> TOTAL DEBT CONSOLIDATED</span>
            </td>
            <td class="dm-style-28">
              <span class="dm-style-29">
                {{ totalDebtConsolidated | currency: 'USD':'symbol':'.2':'en-US' }}
              </span>
            </td>
          </tr>
          <tr class="dm-style-30">
            <td colspan="2" class="dm-style-31">
              <span class="dm-style-32"> TOTAL DEBT PAYMENT</span>
            </td>
            <td class="dm-style-33">
              <span class="dm-style-34"> {{ totalDebtPayments | currency: 'USD':'symbol':'.2':'en-US' }} </span>
            </td>
          </tr>
          <tr class="dm-style-35" *ngIf="isRefinance">
            <td colspan="2" class="dm-style-36">
              <span class="dm-style-37"> CURRENT P&I</span>
            </td>
            <td class="dm-style-38">
              <span class="dm-style-39"> {{ currentPandI | currency: 'USD':'symbol':'.2':'en-US' }} </span>
            </td>
          </tr>
          <tr class="dm-style-40">
            <td colspan="2" class="dm-style-41">
              <span class="dm-style-42"> NEW P&I</span>
            </td>
            <td class="dm-style-43">
              <span class="dm-style-44"> {{ proposedPandI | currency: 'USD':'symbol':'.2':'en-US' }} </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="dm-style-45">
    <img class="dm-style-46"
      [src]="imageData2"
      style="width: auto; height: 150px; margin: auto"
    />
  </div>
  <div class="dm-style-47">
    <div class="dm-style-48" style="border-bottom: none">
      <table class="dm-style-49">
        <tr class="dm-style-50">
          <td class="dm-style-51">
            <span class="dm-style-52"> MONTHLY RESIDUAL INCOME INCREASE </span>
          </td>
          <td class="dm-style-53">
            <div class="dm-style-54">
              <div class="dm-style-55">
                <div class="dm-style-56">
                  <span class="form-control" readonly>
                    {{monthlyResidualIncomeIncrease| currency: 'USD':'symbol':'.2':'en-US'}}
                  </span>
                </div>
              </div>
              <div class="dm-style-62">
                <span class="form-control" readonly>
                  {{percentOfIncome | percent: '1.3-3'}}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="dm-style-65">
    <div class="dm-style-66">
      <table class="dm-style-67">
        <thead class="dm-style-68">
          <tr class="dm-style-69">
            <th
              colspan="3"
              class="dm-style-70 {{
                monthlyResidualIncomeIncrease < 0 ? 'bg-danger' : 'bg-success'
              }}"
            >
              Savings Over Time
            </th>
          </tr>
        </thead>
        <tbody class="dm-style-71">
          <tr *ngFor="let saving of savings" class="dm-style-72">
            <td class="dm-style-73">
              <span class="dm-style-74">
                {{
                  saving.date &&
                  saving.date.toDateString() != today.toDateString()
                    ? (saving.date | date)
                    : 'Current'
                }}
              </span>
            </td>
            <td class="dm-style-75">
              <span class="dm-style-76"> {{ saving.amount | currency: 'USD':'symbol':'.2':'en-US' }} </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
