import { Component, Injector, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Configuration, DocumentType, SystemLevel } from 'src/app/models';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import * as DocumentTemplateModels from '../../../models/config/document-template.model';
import { DocumentTemplate } from '../../../models/config/global-config.model';
import { DocumentTemplateService } from '../../../services/document-template.service';
import { NotificationService } from '../../../services/notification.service';
import { SystemLevelService } from '../../../services/system-level.service';
import { PrequalGenerationSettings } from '../models';
import { ProfileService } from '../../profile/profile.service';

@Component({
  selector: 'prequal-letter-settings',
  templateUrl: './prequal-letter-settings.component.html'
})
export class PrequalLetterSettingsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() prequalGenerationSettings: PrequalGenerationSettings = {} as PrequalGenerationSettings;
  @Input() type: 'branch' | 'system-level' | 'user';
  @Input() branchId: number;
  @Input() userCompanyGuid: string;

  documentTypes: DocumentType[];
  documentTemplates: DocumentTemplate[];
  documentTemplate: DocumentTemplateModels.DocumentTemplate;
  proofOfFundsDocTypeId?: Configuration = {} as Configuration;

  originalPofValue?: number;

  isSaving: boolean;
  isLoading: boolean;

  companyId: number;

  constructor(
    private readonly injector: Injector,
    private readonly _documentTemplateService: DocumentTemplateService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _configurationService: ConfigurationService,
    private readonly _userProfileService: ProfileService
  ) {
    super(injector);
    this.documentTemplates = _.orderBy(
      this.applicationContext.globalConfig.documentTemplates.filter(el => el.documentTemplateCategory === 'PreQual'),
      ['order'],
      ['asc']
    );
    this.documentTypes = this.applicationContext.globalConfig.documentType;
    this.companyId = this.applicationContext.userPermissions.companyId;

  }

  ngOnInit(): void {

    if (!this.type) {
      this.type = 'system-level';
    }

    if (this.type == 'system-level') {
      this.getSystemLevel();
      return;
    }

    if (this.prequalGenerationSettings?.documentTemplateId) {
      this.getDocumentTemplate();
    }

  }

  private getSystemLevel = () => {
    this.isLoading = true;
    this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
      const systemLevel = JSON.parse(JSON.stringify(data));
      this.prequalGenerationSettings = systemLevel.prequalGenerationSettings || {};
      if (!this.prequalGenerationSettings.documentTemplateId) {
        this.prequalGenerationSettings.documentTemplateId = null;
      }
      if (this.prequalGenerationSettings.documentTemplateId) {
        this.getDocumentTemplate();
      }

      this.proofOfFundsDocTypeId = systemLevel.proofOfFundsDocTypeId || new Configuration("ProofOfFundsDocType");
      this.originalPofValue = this.proofOfFundsDocTypeId?.value;
    }).add(() => this.isLoading = false);
  }

  getDocumentTemplate(): void {
    this.isLoading = true;
    this._documentTemplateService.getDocumentTemplate(this.prequalGenerationSettings.documentTemplateId).subscribe(res => {
      this.documentTemplate = res;
    }).add(() => this.isLoading = false);
  }

  save(): void {
    this.isSaving = true;

    let prequalObservable;
    if (this.type != "user") {
      prequalObservable = this._systemLevelService.savePrequalGenerationSettings(this.prequalGenerationSettings)
    }
    else {
      if (!this.prequalGenerationSettings.companyId) {
        this.prequalGenerationSettings.companyId = this.companyId;
      }

      prequalObservable = this._userProfileService.upsertUserPrequalGenerationSettings(this.userCompanyGuid, this.prequalGenerationSettings);
    }

    let requests: any = {
      prequalGeneration: prequalObservable
    }

    if (this.originalPofValue != this.proofOfFundsDocTypeId.value) {
      this.proofOfFundsDocTypeId.companyId = this.companyId;

      if (this.type == "branch") {
        this.proofOfFundsDocTypeId.branchId = this.branchId;
      }
      else if (this.type == "user") {
        this.proofOfFundsDocTypeId.userId = this.userCompanyGuid;
      }

      requests.companyConfig = this._configurationService.upsertCompanyConfiguration(this.proofOfFundsDocTypeId);
    }

    forkJoin(requests)
      .pipe(finalize(() => {
        this.isSaving = false;
      }))
      .subscribe({
        next: ({ prequalGeneration, companyConfig }) => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'Prequal Letter Settings');
          if (companyConfig) {
            this.proofOfFundsDocTypeId = companyConfig;
            this.originalPofValue = this.proofOfFundsDocTypeId?.value;
          }
        },
        error: (err) => {
          this._notificationService.showError(`${err ? err.message : 'Unable to save.'}`, 'Prequal Letter Settings');
        }
      })

  }
}
