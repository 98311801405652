<span class='form-group'>
  <label>
    <input
      [(ngModel)]='canShopLe'
      class='form-check-input'
      name='canShopLe'
      type='checkbox'
    >
    Borrower can shop for
  </label>
</span>

<span class='divider'></span>

<span>
  Loan Estimate Section
</span>

<span>
  {{ leSectionName }}
</span>

<span class='form-group'>
  <label>
    <input
      [(ngModel)]='didShopCd'
      class='form-check-input'
      name='didShopCd'
      type='checkbox'
    >
    Borrower did shop for
  </label>
</span>

<span class='divider'></span>

<span>
  Closing Disclosure Section
</span>

<span>
  {{ cdSectionName }}
</span>
