
<div class="row mt-2 mb-3">
  <div class="col-md-4 text-end">
    <label class="custom-control-label mt-2">Expression Type</label>
  </div>
  <div class="col-md-8 mt-2">
    <select id="expressionType" name="expressionType" class="form-select"
      [(ngModel)]="expression.expressionType" (ngModelChange)="onExpressionTypeChanged()">
      <option value="1">Application</option>
      <option value="4">Lead</option>
    </select>
  </div>

  <div class="col-md-4 text-end">
    <label class="custom-control-label mt-2">Campaign Name</label>
  </div>
  <div class="col-md-8 mt-2">
    <input type="text" id="campaignName" name="campaignName" class="form-control" [(ngModel)]="expression.campaignName">
  </div>

  <div class="col-md-4 text-end mt-2">
    <label class="custom-control-label mt-2">Expression</label>
  </div>
  <div class="col-md-8 mt-2">
    <expression-input [expression]="expressionConfig" [companyId]="companyId"
      (onExpressionChanged)="onExpressionChanged($event)">
    </expression-input>
  </div>
</div>
