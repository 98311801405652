import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoalTableComponent } from './components/goal-table/goal-table.component';
import { TableModule } from 'primeng/table';
import { GoalConfigComponent } from './components/goal-config/goal-config.component';
import { SharedModule } from '../../../shared/shared.module';
import { GoalEditorComponent } from './components/goal-editor/goal-editor.component';
import { GoalEditorDrawerComponent } from './components/goal-editor-drawer/goal-editor-drawer.component';


@NgModule({
  declarations: [
    GoalTableComponent,
    GoalConfigComponent,
    GoalEditorComponent,
    GoalEditorDrawerComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    SharedModule,
  ],
  exports: [
    GoalTableComponent,
  ],
})
export class GoalModule {
}
