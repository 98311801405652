import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { TpoColumnConfiguration } from '../models/tpo-column-configuration.model';
import { TpoConfiguration, TpoCssConfiguration } from '../models/tpo-configuration.model';

@Injectable()
export class TpoConfigService {
  constructor(private readonly _dataService: DataService) { }

  private readonly _TPO_CONFIG_API_URL = 'api/Admin/tpo-configuration';

  // tpo config
  getTpoConfigs = (): Observable<TpoConfiguration> => {
    return this._dataService
      .get(this._TPO_CONFIG_API_URL)
  }

  saveTpoConfig = (configId: number, tpoConfig: TpoConfiguration): Observable<TpoConfiguration> => {
    let url = `${this._TPO_CONFIG_API_URL}/${configId}`;

    return this._dataService.post(url, tpoConfig);
  }

  uploadOverrideCss = (formData: FormData): Observable<TpoCssConfiguration> => {
    let url = 'api/admin/tpo-configuration/css';
    return this._dataService.postFormData(url, formData);
  }

  deleteOverrideCss = (): Observable<any> => {
    let url = 'api/admin/tpo-configuration/css';
    return this._dataService.delete(url);
  }

  // order
  saveTpoConfigOrder = (configId: number, orders: number[]): Observable<any> => {
    let url = `${this._TPO_CONFIG_API_URL}/${configId}/order`;

    return this._dataService.post(url, orders);
  }

  // columns
  getTpoConfigColumns = (): Observable<TpoColumnConfiguration[]> => {
    let url = `${this._TPO_CONFIG_API_URL}/columns`;

    return this._dataService.get(url)
  }

  saveTpoConfigColumns = (columnConfigs: TpoColumnConfiguration[]): Observable<TpoColumnConfiguration[]> => {
    let url = `${this._TPO_CONFIG_API_URL}/columns`;

    return this._dataService.post(url, columnConfigs);
  }

  saveTpoConfigColumn = (columnId: number, columnConfig: TpoColumnConfiguration): Observable<TpoColumnConfiguration> => {
    let url = `${this._TPO_CONFIG_API_URL}/columns/${columnId}`;

    return this._dataService.post(url, columnConfig);
  }

  deleteTpoConfigColumn = (columnId: number): Observable<TpoColumnConfiguration> => {
    let url = `${this._TPO_CONFIG_API_URL}/columns/${columnId}`;

    return this._dataService.delete(url);
  }

  updateTpoUserPassword = (branchId: number, branchUserId: number, request: any): Observable<TpoColumnConfiguration[]> => {
    let url = `api/tpoconfiguration/branches/${branchId}/branch-users/${branchUserId}/set-password`;
    return this._dataService.post(url, request);
  }

  updateTpoUserName = (branchId: number, branchUserId: number, newEmail: string): Observable<TpoColumnConfiguration[]> => {
    let url = `api/tpoconfiguration/branches/${branchId}/branch-users/${branchUserId}/set-username?newEmail=${newEmail}`;
    return this._dataService.post(url, null);
  }

}
