import { CallStatus } from "./conference-participant.model";
import { PhoneType } from "./phone-type.model";

export class DialListRecordBasic {
  dialListRecordId: number;
  applicationId: number;
  leadId: number;
  agentId: number;
  borrowerId: number;
  creditMonitoringDataId: number;
  externalCompanyId: number;
  contactListRecordId: number;
  dialListId: number;
  firstName: string;
  lastName: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  readonly timeZone: string;
  loanPurpose: string;
  interestRate: number;
  loanOfficerUserId: string;
  loanAmount: number;
  leadSource: string;
  statusId: number;
  lastAttemptedContact: Date;
  lastSuccessfulContact: Date;
  lastNotes: string[];
  userId: string;
  active: boolean;
  leadCampaignName?: string;
  branchName?: string;
  lastMessageContent: string;

  recordType?: RecordType;
  recordTypeId?: number;
  recordTypeStatusId?: number;
  visible?: boolean;
  needsToBeStruckOut?: boolean;
  availableStatuses?: { statusId: number, statusName: string }[];
  callStatus?: CallStatus;
  phoneNumberToCall?: string;
  selectedPhoneType?: PhoneType;
  isFetched?: boolean;
  conferenceId?: string;
  conferenceLineId?: number;
  subStatus?: string;
  subStatusId?: number;
  channelName?:string;
  timezoneRestricted?: boolean;
}

export enum RecordType {
  Lead = "Lead",
  Application = "Application",
  Agent = "Agent",
  Borrower = "Borrower"
}
