<div class="alert alert-dismissible d-flex-row align-items-center mb-0"
    *ngIf="isSubmitSuccess || isSubmitError || validationErrors.length"
    [ngClass]="{'alert-outline-success': isSubmitSuccess, 'alert-outline-danger': isSubmitError || validationErrors.length}"
    role="alert">
    <div *ngIf="isSubmitSuccess">
        <span *ngIf="request.creditRequestType == 'Reissue'; else submit">
            <strong>Success:</strong> Credit re-issued successfully.
        </span>
        <ng-template #submit>
            <span>
                <strong>Success:</strong> Credit report generated successfully
            </span>
        </ng-template>
    </div>
    <div *ngIf="isSubmitError">
        <div *ngFor="let error of errorMessages">
            <strong>Error:</strong> {{error? error : 'Credit Request Failed.'}}
        </div>
    </div>

    <ng-container *ngIf="validationErrors.length">
        <div *ngFor="let validationError of validationErrors">
            <div><strong>Error:</strong> {{validationError}}</div>
        </div>
    </ng-container>

    <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeErrors()" aria-label="Close"></button>
</div>
<form #creditRunForm="ngForm" novalidate id="creditRunForm" name="creditRunForm">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table mb-0 table-centered">
                <thead class="table-light">
                    <tr>
                        <th> Pull? </th>
                        <th> Borrower Name </th>
                        <th> SSN </th>
                        <th class="text-center"> Birth Date </th>
                        <th class="text-center"> Scores </th>
                        <th class="text-center"> Loan Doc </th>
                        <th class="text-center"> Date </th>
                        <th> Ref # </th>
                        <th class="text-center"> Actions </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let borrower of borrowers">
                        <td>
                            <div class="form-check form-switch">
                                <input [(ngModel)]="borrower.willPullCredit" class="form-check-input" type="checkbox"
                                    id="pullSwitchCheck" name="pullSwitchCheck" (change)="onPullCreditToggleChanged()"
                                    [checked]="borrower.pull && isPullEnabled && isLinkedToLos"
                                    [disabled]="!isPullEnabled">
                            </div>
                        </td>
                        <td> {{borrower.firstName + ' '}} {{borrower.lastName ? borrower.lastName : ' '}} </td>
                        <td> {{borrower.last4Ssn}} </td>
                        <td class="text-center"> {{borrower.birthDate | date}} </td>
                        <td class="text-center">
                            <ng-template [ngIf]="leadId" [ngIfElse]="loanScoreEntries">
                                <span
                                    *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful && borrower.latestCreditReport.equifaxScore)">
                                    Equifax <b> {{borrower.latestCreditReport.equifaxScore}} </b>
                                </span>
                                <span
                                    *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful && borrower.latestCreditReport.experianScore)">
                                    Experian <b> {{borrower.latestCreditReport.experianScore}} </b>
                                </span>
                                <span
                                    *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful && borrower.latestCreditReport.transUnionScore)">
                                    TransUnion <b> {{borrower.latestCreditReport.transUnionScore}} </b>
                                </span>
                            </ng-template>
                            <ng-template #loanScoreEntries>
                                <span
                                    *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful && borrower.latestCreditReport.equifax)">
                                    Equifax <b> {{borrower.latestCreditReport.equifax}} </b>
                                </span>
                                <span
                                    *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful && borrower.latestCreditReport.experian)">
                                    Experian <b> {{borrower.latestCreditReport.experian}} </b>
                                </span>
                                <span
                                    *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful && borrower.latestCreditReport.transUnion)">
                                    TransUnion <b> {{borrower.latestCreditReport.transUnion}} </b>
                                </span>
                            </ng-template>

                            <span
                                *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful === false)"
                                class="label-danger">
                                Credit Pull Failed
                            </span>
                            <span
                                *ngIf="(!borrower.latestCreditReport || (borrower.latestCreditReport && borrower.latestCreditReport.successful === null))"
                                class="label-danger">
                                No Credit Record
                            </span>
                        </td>
                        <td class="text-center">
                            <a *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.loanDocId)"
                                (click)="openLoanDoc(borrower.latestCreditReport.loanDocId)" title="Loan Doc">
                                <i class="fa fa-lg far fa-file-pdf"></i>
                            </a>
                        </td>
                        <td class="text-center">
                            <span *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.date)">
                                {{borrower.latestCreditReport.date | date: 'MM/dd/yyyy h:mma'}}
                            </span>
                        </td>
                        <td>
                            <span *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.refNumber)">
                                {{borrower.latestCreditReport.refNumber}}
                            </span>
                        </td>
                        <td class="text-center">
                            <a *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.refNumber)"
                                title="Re-issue"
                                (click)="onReissueClicked(borrower.latestCreditReport.integrationHistoryId)">
                                <i class='fas fa-undo-alt'></i>
                            </a>
                            <a *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.successful === true)"
                                class="mx-2" title="Report"
                                (click)="openReport(borrower.latestCreditReport.integrationHistoryId)">
                                <i class='fa-lg far fa-file-pdf'></i>
                            </a>
                            <span
                                *ngIf="(borrower.latestCreditReport && borrower.latestCreditReport.showImport && borrower.latestCreditReport.successful != false)"
                                [ngClass]="{'ms-2': !borrower.latestCreditReport.successful}"
                                [rowSpan]="numberOfReportsJointWith(borrower.latestCreditReport)">
                                <a title="Import Liabilities"
                                    (click)="openImportLiabilitiesTab(borrower.latestCreditReport.creditID)">
                                    <i class='fa fa-lg fa-file-import'></i>
                                </a>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-md-12">
                <div *ngIf="warnMessage" class="alert alert-warning warning-mesages" style="margin-top: 15px;">
                    <span>{{ warnMessage }}</span>
                </div>
            </div>
        </div>
    </div>
    <br><br>
    <div *ngIf="showRunCredit">
        <div class="card-body row">
            <div class="col-md-6">
                <label class="mb-2">
                    <b> Credit Vendor </b>
                </label>
                <select class="form-select" [(ngModel)]="request.credentialId" name="creditVendor"
                    style="width: 100%; height:36px;" #creditVendor="ngModel" required
                    [ngClass]="{'is-invalid' : creditVendor && creditVendor.touched  && creditVendor.invalid}"
                    (ngModelChange)="onCreditVendorChanged()" #creditVendorComponent>
                    <option [ngValue]="null"> Select </option>
                    <option [ngValue]="-1">+ Add New Credit Credentials</option>
                    <option *ngFor="let vendor of creditVendors" [ngValue]="vendor.credentialId">
                        {{vendor.vendorDisplayName}}
                    </option>
                </select>
                <div class="invalid-feedback px-3">Please select a Credit Vendor.</div>
            </div>
            <div class="col-md-6">
                <label class="mb-2">
                    <b> Pull Type </b>
                </label>
                <select class="form-select" [(ngModel)]="request.pullType" name="pullType"
                    (ngModelChange)="onPullTypeChanged(request.pullType)" style="width: 100%; height:36px;"
                    #pullType="ngModel" required
                    [ngClass]="{'is-invalid' : pullType && pullType.touched  && pullType.invalid}">
                    <option value=""> -- Select -- </option>
                    <option *ngFor="let type of requestTypes" value="{{type}}">
                        {{type}}
                    </option>
                </select>
                <div class="invalid-feedback px-3"> Please select a Pull Type. </div>
            </div>
            <div class="col-md-12 mt-4" *ngIf="showSoftPullReportType">
                <div class="col-md-6">
                    <label class="mb-2">
                        <b> Report Type </b>
                    </label>
                    <select class="form-control" [(ngModel)]="request.reportType" name="reportType"
                        style="width: 100%; height:36px;" #pullType="ngModel" required
                        [ngClass]="{'is-invalid' : reportType && reportType.touched  && reportType.invalid}">
                        <option [ngValue]="null"> -- Select -- </option>
                        <option value="Merge"> Merge Infile (Hard Pull) </option>
                        <option value="SoftPull"> Soft Pull </option>
                        <option *ngIf="showSoftPullReportType" value="Streamline"> Mortgage Only </option>
                    </select>
                    <div class="invalid-feedback px-3"> Please select a Request Type. </div>
                </div>
                <div class="col-md-6" *ngIf="request.reportType && request.reportType != 'SoftPull' && !reissue">
                    <div class="alert alert-warning mt-2">
                        FYI: This is going to result in a Hard Credit Inquiry on the consumer.
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body row">
            <div class="col-md-6">
                <label class="mb-2">
                    <b> Choose Bureaus </b>
                </label>
                <div [ngClass]="{'invalid-border': creditRunClicked && (!request.experian && !request.equifax && !request.transUnion)}">
                    <div class="form-check checkbox-primary form-check-inline">
                        <input type="checkbox" class="form-check-input" name="experian" id="experian"
                            [(ngModel)]="request.experian" [checked]="request.experian"
                            [required]="!request.experian && !request.equifax && !request.transUnion">
                        <label for="experian"> Experian </label>
                    </div>
                    <div class="form-check checkbox-primary form-check-inline">
                        <input type="checkbox" class="form-check-input" name="equifax" id="equifax"
                            [(ngModel)]="request.equifax" [checked]="request.equifax"
                            [required]="!request.experian && !request.equifax && !request.transUnion">
                        <label for="equifax"> Equifax </label>
                    </div>
                    <div class="form-check checkbox-primary form-check-inline">
                        <input type="checkbox" class="form-check-input" name="transUnion" id="transUnion"
                            [(ngModel)]="request.transUnion" [checked]="request.transUnion"
                            [required]="!request.experian && !request.equifax && !request.transUnion">
                        <label for="transUnion"> TransUnion </label>
                    </div>
                </div>
                <div *ngIf="creditRunClicked && (!request.experian && !request.equifax && !request.transUnion)">
                    <div class="invalid-message px-3"> Please select at least one Bureau. </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="companyId == 176">
                <label class="mb-2">
                    <b>Payment Info</b>
                </label>

                <div class="form-group">
                    <label for="credit_payerName">Name on the Card</label>
                    <input id="credit_payerName" name="credit_payerName" type="text"
                        class="form-control"
                        #payerName="ngModel"
                        [ngClass]="{'is-invalid' : payerName && payerName.touched  && payerName.invalid}"
                        [(ngModel)]="request.paymentInfo.payerName"
                        [required]="payerAccount?.value || ccType?.value || ccExpirationDate?.value || paymentAmount?.value > 0"
                    />
                    <div class="invalid-feedback"> Name on the Card is required. </div>
                </div>
                <div class="form-group">
                    <label for="credit_cardNumber">Card Number</label>
                    <input id="credit_cardNumber" name="credit_cardNumber" type="text"
                        class="form-control"
                        #payerAccount="ngModel"
                        [mask]="creditCardMask"
                        [ngClass]="{'is-invalid' : payerAccount && payerAccount.touched && payerAccount.invalid}"
                        [(ngModel)]="request.paymentInfo.payerAccount"
                        (change)="onCardNumberChanged()"
                        [required]="payerName?.value || ccType?.value || ccExpirationDate?.value || paymentAmount?.value > 0"
                    />
                    <div class="invalid-feedback"> Card Number is required. </div>
                </div>
                <div class="form-group">
                    <label for="credit_cardType">Card Type</label>
                    <select id="credit_cardType" name="credit_cardType"
                        class="form-select"
                        #ccType="ngModel"
                        [ngClass]="{'is-invalid' : ccType && ccType.touched && ccType.invalid}"
                        [(ngModel)]="request.paymentInfo.ccType"
                        (change)="onCardTypeChanged()"
                        [required]="payerAccount?.value || payerName?.value || ccExpirationDate?.value || paymentAmount?.value > 0"
                    >
                        <option [ngValue]="null">-- Select One --</option>
                        <option value="AmericanExpress">AmericanExpress</option>
                        <option value="Discover">Discover</option>
                        <option value="MasterCard">MasterCard</option>
                        <option value="Visa">Visa</option>
                    </select>
                    <div class="invalid-feedback"> Card Type is required. </div>
                </div>
                <div class="form-group">
                    <label for="credit_ccExpirationDate">Expiration Date</label>
                    <input id="credit_ccExpirationDate" name="credit_ccExpirationDate" type="text"
                        class="form-control"
                        #ccExpirationDate="ngModel"
                        [ngClass]="{'is-invalid' : ccExpirationDate && ccExpirationDate.touched && ccExpirationDate.invalid}"
                        maxlength="7"
                        [(ngModel)]="request.paymentInfo.ccExpirationDate"
                        (blur)="onExpirationDateBlurred()"
                        (ngModelChange)="onExpirationDateChanged()"
                        placeholder="mm/yyyy"
                        [required]="payerName?.value || payerAccount?.value || ccType?.value || paymentAmount?.value > 0"
                    />
                    <div class="invalid-feedback" *ngIf="ccExpirationDate?.touched && ccExpirationDate?.errors?.invalid"> Expiration Date is invalid. </div>
                    <div class="invalid-feedback" *ngIf="ccExpirationDate?.touched && ccExpirationDate?.errors?.required"> Expiration Date is required. </div>
                </div>
                <div class="form-group">
                    <label for="credit_amount">Amount</label>
                    <currency-input
                        name="credit_amount"
                        #paymentAmount="ngModel"
                        [(ngModel)]="request.paymentInfo.amount"
                        [readonly]="false"
                        [disabled]="false"
                        [required]="false"
                    ></currency-input>
                </div>
            </div>
        </div>
        <div class="card-body row">
            <div class="col-md-12">
                <label>
                    <b>Re-issue?</b>
                </label>
                <div class="form-check form-switch">
                    <input (click)="onReissueToggleChanged()" class="form-check-input" type="checkbox" [(ngModel)]="reissue"
                        [checked]="reissue" id="reissue" name="reissue" [disabled]="isReissueOnly">
                    <ng-container *ngIf="reissue && request.pullType == 'Individual'">
                        <div *ngFor="let borrower of borrowers; let index = index" class="mx-4 mb-2 col-md-4">
                            <ng-container *ngIf="borrower.willPullCredit">
                                <label for="creditReferenceNumber" class="mb-2">
                                    <b> {{borrower.firstName + ' '}} {{borrower.lastName ? borrower.lastName : ' '}} -
                                        Credit
                                        Reference # </b> </label>
                                <input *ngIf="reissue" class="form-control" id="creditReferenceNumber_{{index}}" numeric
                                    [allowNegative]="false" [(ngModel)]="borrowerCreditRefNumbers[borrower.borrowerId]"
                                    name="creditReferenceNumber_{{index}}"
                                    [ngClass]="{'is-invalid' : creditRefNo && creditRefNo.touched  && creditRefNo.invalid}"
                                    #creditRefNo="ngModel" required>
                                <div class="invalid-feedback px-3">Please enter a Credit Reference Number.</div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="reissue && request.pullType != 'Individual'">
                        <div class="mx-4 mb-2 col-md-4">
                            <label for="creditReferenceNumber" class="mb-2">
                                <b> Credit Reference # </b> </label>
                            <input *ngIf="reissue" class="form-control" id="creditReferenceNumber" numeric
                                [allowNegative]="false" [(ngModel)]="request.creditReferenceNumber"
                                name="creditReferenceNumber"
                                [ngClass]="{'is-invalid' : creditRefNo && creditRefNo.touched  && creditRefNo.invalid}"
                                #creditRefNo="ngModel" required>
                            <div class="invalid-feedback px-3">Please enter a Credit Reference Number.</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button type="button" class="btn btn-primary" (click)="onPullCreditClicked()"
                [ngShow]="!pullingCreditReport" [disabled]="pullingCreditReport">
                <span class="spinner-border spinner-border-sm" role="status" *ngIf="pullingCreditReport">
                </span>
                {{!pullingCreditReport ? actionButtonLabel : 'Running...'}}
            </button>
        </div>
    </div>
</form>
