import { Component, Injector, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { LoanApplication } from 'src/app/models';
import { MenuItemComponent } from 'src/app/modules/tpo/components/menu-item/menu-item.component';
import { MenuService } from 'src/app/services/menu.service';
import { Subscription } from 'rxjs';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { QuickApplyFieldsConfigBoundComponent } from 'src/app/shared/components/quick-apply-fields-config-bound.component';

@Component({
  selector: 'qa-menu',
  templateUrl: './qa-menu.component.html',
  styleUrls: ['./qa-menu.component.scss']
})
export class QaMenuComponent extends QuickApplyFieldsConfigBoundComponent implements OnInit, OnDestroy {

  @ViewChildren(MenuItemComponent) menuItems:
    | QueryList<MenuItemComponent>
    | undefined;

  @Input()
  tab: string;

  @Input()
  mortgageType: string;

  @Input()
  application: LoanApplication;

  @Input()
  applicationMode: string;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (!mortgage) {
      return;
    }
    this._mortgage = mortgage;
    this.determineIfVaOrFhaLoan(mortgage);
  }

  protected companyId: number;

  protected readonly MenuItemStatus = MenuItemStatus;

  protected isFhaLoan: boolean = false;
  protected isVaLoan: boolean = false;

  private _menuItemStatusSubscription: Subscription;
  private _loanInfoChangeSubscription: Subscription;

  private _mortgage: UrlaMortgage;

  constructor(
    private readonly _menuService: MenuService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _mortgageCalculationService: MortgageCalculationService,
    injector: Injector,
  ) {
    super(injector);

    this._applicationContextService.context.subscribe(context => {
      this.companyId = context.userPermissions.companyId;
    });

    this._menuItemStatusSubscription = this._menuService.menuItemStatusChanged.subscribe(e => {
      const menuItem = this.menuItems.find(mi => mi.id === e.id);
      if (menuItem) {
        menuItem.status = e.status;
      }
    });

    this._loanInfoChangeSubscription = this._applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.currentMortgage) {
        this.determineIfVaOrFhaLoan(context.currentMortgage);
      }
    })
  }

  ngOnInit(): void {
    this._menuService.setStatus('qaBorrowerInfo', this._menuService.getStatusForBorrowerInfo(this._mortgage));
    this._menuService.setStatus('qaFinancialInfo', this._menuService.getStatusForFinancialInfo(this._mortgage));
    this._menuService.setStatus('qaLoanInfo', this._menuService.getStatusForLoanInfo(this._mortgage));
    //this._menuService.setStatus('qaFhaInfo', this._menuService.getStatusForFinancialInfo(this.mortgage));
    //this._menuService.setStatus('qaVaInfo', this._menuService.getStatusForFinancialInfo(this.mortgage));
  }

  ngOnDestroy(): void {
    this._menuItemStatusSubscription?.unsubscribe();
    this._loanInfoChangeSubscription?.unsubscribe();
  }

  private determineIfVaOrFhaLoan = (mortgage: UrlaMortgage) => {
    this.isFhaLoan = this._mortgageCalculationService.isFhaLoan(mortgage);
    this.isVaLoan = this._mortgageCalculationService.isVaLoan(mortgage);
  }
}
