<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Title Company
      </div>
    </h4>
  </div>
  <div class="card-body" *ngIf="docPrep.titleCompany">
    <form #docPreTitleCompanyForm="ngForm" id="docPreTitleCompanyForm">
      <div class="row">
        <div class="col-md-3 form-group">
          <label for="titleCompany"> Title Company </label>
          <input type="text" class="form-control" id="titleCompany" name="titleCompany"
            [(ngModel)]="docPrep.titleCompany.companyName">
        </div>
        <div class="col-md-3 form-group">
          <label for="contactFirstName"> Contact First </label>
          <input type="text" class="form-control" [(ngModel)]="docPrep.titleCompany.firstName" id="contactFirstName"
            name="contactFirstName">
        </div>
        <div class="col-md-3 form-group">
          <label for="contactLastName"> Contact Last </label>
          <input type="text" class="form-control" [(ngModel)]="docPrep.titleCompany.lastName" id="contactLastName"
            name="contactLastName">
        </div>
        <div class="col-md-3 form-group">
          <label for="contactEmail"> Contact Email </label>
          <input type="email" trim class="form-control" [(ngModel)]="docPrep.titleCompany.email" id="contactEmail"
            name="contactEmail" #emailInput="ngModel" [ngClass]="{
              'is-invalid':
                emailInput &&
                emailInput.touched &&
                (!emailFormatValidate(docPrep.titleCompany.email))
            }">
          <div for="contactEmail" class="invalid-feedback" *ngIf="!emailFormatValidate(docPrep.titleCompany.email)">
            Please enter a valid email address
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="titleCompanyAddress1"> Company Address </label>
            <address-autocomplete-input
              #address='ngModel'
              (addressChange)="handleAddressChange($event, 'Company')"
              [(ngModel)]='docPrep.titleCompany.companyAddress1'
              id='titleCompanyAddress1'
              name='titleCompanyAddress1'
              placeholder=''
            ></address-autocomplete-input>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="form-group col-xs-6 col-sm-4 pe-0">
              <label for="companyCity"> City </label>
              <input id="companyCity" name="companyCity" type="text" class="form-control custom-input-left-side"
                [(ngModel)]="docPrep.titleCompany.companyCity" />
            </div>
            <div class="form-group col-xs-6 col-sm-2 px-0">
              <label for="companyState">State</label>
              <select id="companyState" name="companyState"
                class="form-select custom-input-left-side custom-input-right-side"
                [(ngModel)]="docPrep.titleCompany.companyState">
                <option [ngValue]="null"></option>
                <option *ngFor="let state of states" [ngValue]="state.value">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-xs-6 col-sm-3 px-0">
              <label for="companyZipCode">Zip Code</label>
              <zip-code-input class="custom-input-left-side custom-input-right-side"
                [(ngModel)]="docPrep.titleCompany.companyZip" id='companyZipCode'
                name="companyZipCode" [required]="false"
                (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'Company')">
              </zip-code-input>
            </div>
            <div class="form-group col-xs-6 col-sm-3 ps-0">
              <label for="companyPhoneNumber">Phone</label>
              <input id="companyPhoneNumber" name="companyPhoneNumber" mask="(000) 000-0000" type="text" placeHolderCharacter="X"
                class="form-control custom-input-right-side" [(ngModel)]="docPrep.titleCompany.companyPhone"
                [showMaskTyped]="true" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="titleMailingAddress1"> Mailing Address </label>
              <span class="text-primary"> <a (click)="onCopyAddressClicked()"> Copy from Company </a> </span>
            </div>
            <address-autocomplete-input
              #address='ngModel'
              (addressChange)="handleAddressChange($event, 'Mailing')"
              [(ngModel)]='docPrep.titleCompany.mailingStreet'
              id='titleMailingAddress1'
              name='titleMailingAddress1'
              placeholder=''
            ></address-autocomplete-input>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="form-group col-xs-6 col-sm-4 pe-0">
              <label for="mailingCity"> City </label>
              <input id="mailingCity" name="mailingCity" type="text" class="form-control custom-input-left-side"
                [(ngModel)]="docPrep.titleCompany.mailingCity" />
            </div>
            <div class="form-group col-xs-6 col-sm-2 px-0">
              <label for="mailingState">State</label>
              <select id="mailingState" name="mailingState"
                class="form-select custom-input-left-side custom-input-right-side"
                [(ngModel)]="docPrep.titleCompany.mailingState">
                <option [ngValue]="null"></option>
                <option *ngFor="let state of states" [ngValue]="state.value">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-xs-6 col-sm-3 px-0">
              <label for="mailingZipCode">Zip Code</label>
              <zip-code-input class="custom-input-left-side custom-input-right-side"
                [(ngModel)]="docPrep.titleCompany.mailingZip" id="mailingZipCode"
                name='mailingZipCode' [required]="false"
                (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'Mailing')">
              </zip-code-input>
            </div>
            <div class="form-group col-xs-6 col-sm-3 ps-0">
              <label for="mobilePhoneNumber">Phone</label>
              <input id="mobilePhoneNumber" name="mobilePhoneNumber" mask="(000) 000-0000" type="text" placeHolderCharacter="X"
                class="form-control custom-input-right-side" [(ngModel)]="docPrep.titleCompany.mobilePhone"
                [showMaskTyped]="true" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
