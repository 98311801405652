import { Injectable } from '@angular/core';
import { EnumerationService } from './enumeration-service';
import { AddressLookup, AddressLookupOptions, AddressLookupParser, AddressLookupResult } from '../../utils/address-lookup-parser';
import { Address } from '../models';
import { ZipCodeLookupResult } from '../models/zipcode-lookup-result.model';
import { ZipCodeLookupParser } from '../../utils/zip-code-lookup-parser';

@Injectable({
  providedIn: 'root',
})
export class AddressLookupService {
  constructor(
    private readonly _enumerationService: EnumerationService,
  ) {
  }

  private get states(): string[] {
    return this._enumerationService.states.map((state) => state.name);
  }

  /**
   * Parses an address lookup into an address.
   * @param lookup The address lookup to parse.
   * @param options The options to use when parsing the address lookup.
   * @returns The parsed address. Contains only the fields that were found in
   * the lookup.
   */
  parseAddressLookup(
    lookup: AddressLookup,
    options?: AddressLookupOptions,
  ): Partial<AddressLookupResult> {
    const parser = new AddressLookupParser({
      states: this.states,
      options,
    });
    return parser.parseAddressLookup(lookup);
  }

  /**
   * Parses a zip code lookup into an address.
   * @param lookup The zip code lookup to parse.
   * @returns The parsed address. Contains only the fields that were found in
   * the lookup.
   */
  parseZipCodeLookup(
    lookup: ZipCodeLookupResult,
  ): Partial<Address> {
    const parser = new ZipCodeLookupParser();
    return parser.parseZipCodeLookup(lookup);
  }
}
