import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentsService } from 'src/app/modules/agents/services/agents.service';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DialListRecordBasic, RecordType } from '../../../models/dial-list-record-basic.model';
import { AgentNote } from 'src/app/modules/agents/models/agent-note.model';
import { BorrowersService } from 'src/app/modules/borrower/services/borrowers.service';
import { BorrowerNote } from 'src/app/modules/borrower/models/borrower-note.model';
import { Message } from 'src/app/models/message/message.model';
import { MessageService } from 'src/app/services/message.service';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user/user.model';
import { ApplicationContext, Company } from 'src/app/models';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';
import { ChannelService } from 'src/app/services/channel.service';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { LoanService } from 'src/app/services/loan';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

@Component({
  selector: 'edit-record-note',
  templateUrl: './edit-record-note.component.html',
  styleUrls: ['./edit-record-note.component.scss']
})
export class EditRecordNoteComponent implements OnInit, OnDestroy {

  @Input()
  record: DialListRecordBasic;

  @Output()
  close: EventEmitter<string> = new EventEmitter<string>();

  protected note: string;

  protected usersThatCanBeMentioned: Mentionable[] = [];

  private _contextSubscription: Subscription;

  private _allUsers: User[] = [];

  constructor(
    private readonly _leadService: LeadsService,
    private readonly _agentService: AgentsService,
    private readonly _contactsService: BorrowersService,
    private readonly _messageService: MessageService,
    private readonly _notyfiService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environment: EnvironmentService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _channelService: ChannelService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _loanService: LoanService
  ) {
  }

  ngOnInit(): void {
    this._contextSubscription = this._applicationContextService.context.subscribe((context) => {
      this._allUsers = context.globalConfig.usersAll;
      if (this.record.recordType == RecordType.Application) {
        this.initializeMentionables(context, this.record);
      }
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  onMessageChanged = (message: string) => {
    this.note = message;
  }

  onEnterHit = () => {

  }

  saveNote() {
    if (this.note.trim() == "") {
      return;
    }

    if (this.record.recordType == RecordType.Lead) {

      const event: any = {
        leadId: this.record.leadId,
        leadStatusId: this.record.recordTypeStatusId,
        note: this.note,
        type: "note",
      }

      this._spinner.show();
      this._leadService.addLeadEvent(event).subscribe({
        next: () => {
          this._leadService.sendEventUpdatedLead(this.record.leadId, this.note);
          this.close.emit(this.note);
        },
        error: (error) => {
          this._notyfiService.showError(error?.message || "Couldnt change lead note.", "Error!");
        }
      }).add(() => this._spinner.hide());

    } else if (this.record.recordType == RecordType.Borrower) {

      let borrowerNote = new BorrowerNote();
      borrowerNote.content = this.note;

      this._spinner.show();
      this._contactsService.postBorrowerNote(this.record.borrowerId, borrowerNote).subscribe({
        next: () => {
          this.close.emit(this.note);
        },
        error: (error) => {
          this._notyfiService.showError(error?.message || "Couldnt change borrower note.", "Error!");
        }
      }).add(() => this._spinner.hide());

    } else if (this.record.recordType == RecordType.Agent) {
      let agentNote = new AgentNote();
      agentNote.content = this.note;

      this._spinner.show();
      this._agentService.postAgentNote(this.record.agentId, agentNote).subscribe({
        next: () => {
          this.close.emit(this.note);
        },
        error: (error) => {
          this._notyfiService.showError(error?.message || "Couldnt change agent note.", "Error!");
        }
      }).add(() => this._spinner.hide());

    } else if (this.record.recordType == RecordType.Application) {

      let internalMessage = new Message();
      internalMessage.applicationId = this.record.applicationId;
      internalMessage.content = this.note;

      this._spinner.show();
      this._messageService.postInternalMessage(internalMessage).subscribe({
        next: (res) => {
          this.close.emit(this.note);
        },
        error: (error) => {
          this._notyfiService.showError(error?.message || "Couldnt change application note.", "Error!");
        }
      }).add(() => this._spinner.hide());
    }
  }

  closePopover() {
    this.close.emit(this.record.lastMessageContent);
  }

  private initializeMentionables = async (context: ApplicationContext, record: DialListRecordBasic) => {

    try {
      const application = await firstValueFrom(this._loanService.getApplicationModel(record.applicationId, false));

      const internalContacts = await firstValueFrom(this._internalContactsService.getInternalContacts(record.applicationId));

      const usersThatCanBeMentioned = MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl, this._allUsers);
      const company = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId);
      const rolesThatCanBeMentioned = this.prepareContactRoleMentionables(context,
        application.channel,
        company,
        internalContacts, this._allUsers);
      this.usersThatCanBeMentioned = usersThatCanBeMentioned.concat(rolesThatCanBeMentioned);
      this.usersThatCanBeMentioned.push(MentionsUtils.prepareInternalContactsMentionable());
      this.usersThatCanBeMentioned.push(MentionsUtils.prepareHereMentionable());
    } catch (error) {
      const errorMessage = getErrorMessageOrDefault(error, { defaultMessage: "Could not load mentionables." });
      this._notyfiService.showError(errorMessage, "Error!");
    } finally {
    }
  }

  private prepareContactRoleMentionables = (context: ApplicationContext, channelName: string, company: Company,
    internalContacts: InternalContact[], allUsers: User[]): Mentionable[] => {
    let mentionables: Mentionable[] = [];

    const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(company.enabledChannels);
    const enabledChannel = enabledChannels.find(c => c.value === channelName);

    if (enabledChannel) {
      mentionables = MentionsUtils.prepareContactRoleMentionables(
        enabledChannel.value,
        context.globalConfig.channelRoles,
        internalContacts,
        allUsers);
    }
    return mentionables;
  }
}
