<div class="mb-1 tracker-container">
  <div class="steps-container">
    <div *ngFor="let step of steps | sort : 'order'; let i = index"
      class="steps"
      [ngClass]="{
        'active': (activeStepOrder && activeStepOrder == step.order) || (!activeStepOrder && i == 0),
        'navigated-step' : !!step.navigateToPath
      }"
      (click)="navigateToMenu(step.navigateToPath)">
      <span><i class="status-indicator fas" *ngIf="!step.hiddenIcon" [ngClass]="{
        'fa': ['Success','success'].includes(step.status),
        'fa-check-circle': ['Success','success','Requested','requested'].includes(step.status),
        'text-success': ['Success','success'].includes(step.status),

        'text-info': ['Requested','requested'].includes(step.status),

        'fa-question-circle': ['Pending','pending'].includes(step.status),
        'text-warning': ['Pending','pending'].includes(step.status),

        'fa-times-circle': ['Error','error'].includes(step.status),
        'text-danger': ['Error','error'].includes(step.status),

        'fa-circle-notch': ['Loading','loading'].includes(step.status),
        'fa-spin': ['Loading','loading'].includes(step.status)
      }"></i>
      {{ step.name }}</span>
    </div>
  </div>
</div>

