import { FileDto } from "src/app/models/borrower/file-dto.model";
import { IFile } from "src/app/models/file.interface";

export class DocFile implements IFile {
    docFileId: number;
    losDocFileId: string;
    guid: string;
    loanDocId: number;
    applicationId: number;
    borrowerId: number;
    documentTypeId: number;
    note: string;
    borrowerNote: string;
    condition: boolean;
    replacementValuesJson: string;
    title: string;
    fileName: string;
    mimeType: string;
    fileData: string;
    storageIndex: string;
    storageProvider: string;
    exportDate: string;
    active: boolean;
    ocrProcessed: boolean;
    docFileType: string;
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;
    status?: number;
    convertedToPdf?: boolean;
    file: File;
    selectedForAction?: boolean;
}

export class DocFileUploadResponse {
  uploadStatus: DocFileUploadStatus;
  statusMessage: string;
  fileDto: FileDto;
}

export enum DocFileUploadStatus {
  Success = "Success",
  PasswordRequired = "PasswordRequired",
  PasswordIncorrect = "PasswordIncorrect",
  Error = "Error"
}
