import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { ChatAgent, ChatBorrower, ChatLead, RelatedEntities } from '../../models/related-entities.model';

@Component({
  selector: 'related-entities',
  templateUrl: 'related-entities.component.html',
  styleUrls: ['./related-entities.component.scss'],
})
export class RelatedEntitiesComponent implements OnInit {

  @Output()
  editBorrower: EventEmitter<ChatBorrower> = new EventEmitter<ChatBorrower>();

  @Output()
  editLead: EventEmitter<ChatLead> = new EventEmitter<ChatLead>();

  @Input()
  set relatedEntities(relatedEntities: RelatedEntities) {
    if (relatedEntities) {
      relatedEntities.agents = relatedEntities.agents ?? [];
      relatedEntities.borrowers = relatedEntities.borrowers ?? [];
      relatedEntities.leads = relatedEntities.leads ?? [];
    }
    this._relatedEntities = relatedEntities;
  }

  get relatedEntities() {
    return this._relatedEntities;
  }
  _relatedEntities: RelatedEntities;

  applicationMode: string;

  defaultOpen: string;

  constructor(private readonly _navigationService: NavigationService) { }

  ngOnInit() {
    if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
      this.applicationMode = 'loda-nextgen'
    } else {
      this.applicationMode = 'admin'
    }
    if (this.relatedEntities?.agents.length > 0) {
      this.defaultOpen = 'Agent';
      return;
    } else if (this.relatedEntities?.borrowers.length > 0) {
      this.defaultOpen = 'Borrower';
      return;
    } else if (this.relatedEntities?.leads.length > 0) {
      this.defaultOpen = 'Lead';
    }
  }

  onEditAgentClicked = (agent: ChatAgent) => {
  }

  onEditBorrowerClicked = (borrower: ChatBorrower) => {
    this.editBorrower.emit(borrower);
  }

  onEditLeadClicked = (lead: ChatLead) => {
    this.editLead.emit(lead);
  }
}
