export class TelephonyServiceModel {
    telephonyServiceId: number;
    userId: string;
    branchId: number;
    name: string;
    serviceType: string = "";
    fromPhoneNumber: string;
    accountIdentifier: string;
    authToken: string;
    isGlobal: true;
    isUserPrimaryNumber: boolean = null;
    disableAllOutOfTheBoxCommunication: true;
    disableVoiceRecording: true;
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;

    constructor(branchId?: number) {
        this.branchId = branchId
    }
}

export class RvmServiceModel {
    authToken: string;
    companyId: number;
    isGlobal: boolean;
    name: string;
    rvmServiceId: number;
    serviceType: string;
}
