<div *ngIf="message">
  <div class="modal-header">
    <h6 class="modal-title">Preview {{message.delivery}}</h6>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="activeModal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div class="card px-4 py-4">
      <message-status [message]="message"></message-status>
      <div *ngIf="message.delivery !== 'API'; else apiSection">
        <div class="row mb-2">
        </div>
        <div class="row mb-2 separator">
        </div>
        <div class="row mb-2">
          <div class="col-3 label">To:</div>
          <div class="col-9">
            {{message.to}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 label">From:</div>
          <div class="col-9">
            {{message.from}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 label">CC:</div>
          <div class="col-9">
            {{message.cc}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 label">BCC:</div>
          <div class="col-9">
            {{message.bcc}}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-3 label">Subject:</div>
          <div class="col-9">
            {{message.subject}}
          </div>
        </div>
        <div class="row mb-2 separator">
        </div>
        <div *ngIf="message.delivery !== 'Email'" class="px-1 my-2" [innerHTML]="message.body | safeHtml"></div>
        <iframe *ngIf="message.delivery === 'Email'" style="width:100%; height: 48vh; border:1px solid #e3ebf6; border-radius: 0.25rem;" id="emailPreviewPanel"></iframe>
      </div>
      <ng-template #apiSection>
        <div class="row mb-2">
        </div>
        <div class="row mb-2 separator">
        </div>
        <p-table #apiCallParametersTable [value]="message.parameters" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
                 [resizableColumns]="true" sortMode="single"
                 [columns]="[{field: 'type'}, {field: 'name'}, {field: 'value'}]"
                 [sortOrder]="-1" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]"
                 styleClass="p-datatable-gridlines">
          <ng-template pTemplate="caption">
            <div class="p-d-flex" style="align-items: center">
              <span class="p-input-icon-right ms-2 p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="form-control" (ngModelChange)="apiCallParametersTable.first=0" (input)="apiCallParametersTable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword"/>
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th [pSortableColumn]="'msgType'" pResizableColumn>
                Type
                <p-sortIcon [field]="'msgType'"></p-sortIcon>
              </th>
              <th [pSortableColumn]="'priority'" pResizableColumn>
                Key
                <p-sortIcon [field]="'priority'"></p-sortIcon>
              </th>
              <th [pSortableColumn]="'status'" pResizableColumn>
                Value
                <p-sortIcon [field]="'status'"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>
                <span>{{ item.type }}</span>
              </td>
              <td>
                <span>{{ item.name }}</span>
              </td>
              <td>
                <span>{{ item.value }}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" class="text-center">No parameters found</td>
            </tr>
          </ng-template>
        </p-table>
        <div class="row my-2 separator">
        </div>
        <div class="row px-1 my-2">
          <div class="col-3 label">Body:</div>
          <div class="col-9">
            <div [innerHTML]="message.body | safeHtml"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
      (click)="activeModal.dismiss()">Close</button>
  </div>
</div>
