import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ApplicationContext } from "src/app/models/application-context.model";
import { ApplicationContextService } from "src/app/services/application-context.service";

@Injectable({ providedIn: 'root' })
export class ApplicationContextResolver implements Resolve<ApplicationContext> {
  constructor(private readonly _applicationContext: ApplicationContextService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this._applicationContext.context;
  }
}
