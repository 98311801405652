import { Pipe } from "@angular/core";
import { Utils } from "src/app/core/services/utils";

@Pipe({name: 'spinalTapCasePipe'})
export class SpinalTapCasePipe {

  constructor(){}

  transform(value: any): any {
    if (!value) return "";
    return Utils.spinalTapCaseString(value);
  }
}
