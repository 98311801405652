import { Component, Input, OnInit } from '@angular/core';
import { DocPrep } from '../../models/doc-prep.model';
import { FeeDefinition } from '../../../../../models/doc-prep/fee-definition.model';
import { DocPrepService } from '../../../../../services/doc-prep.service';
import { catchError, of, Subscription } from 'rxjs';
import { NotificationService } from '../../../../../services/notification.service';
import { DocPrepFee } from '../../models/doc-prep-fee.model';

@Component({
  selector: 'document-preparation-invoicing',
  templateUrl: './document-preparation-invoicing.component.html',
  styleUrls: ['./document-preparation-invoicing.component.scss']
})
export class DocumentPreparationInvoicingComponent implements OnInit {
  @Input() docPrep: DocPrep;

  protected items: FeeDefinition[] = [];
  protected selection: FeeDefinition[] = [];
  protected loading: boolean = false;

  private _initItemsSubscription: Subscription | null = null;

  constructor(
    private readonly _docPrepService: DocPrepService,
    private readonly _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.fetchItems();

    if (this.docPrep.invoicePaidDate) {
      const date = new Date(this.docPrep.invoicePaidDate);
      if (isNaN(date.getTime())) {
        this.docPrep.invoicePaidDate = null;
      } else {
        this.docPrep.invoicePaidDate = date;
      }
    } else {
      this.docPrep.invoicePaidDate = null;
    }
  }

  protected fetchItems(): void {
    const externalCompanyId = this.docPrep.externalCompanyId;
    if (externalCompanyId == null) {
      this._notificationService.showError('An error occurred while retrieving the invoicing entries.', 'Error');
      console.error('Invoicing table cannot be initialized because externalCompanyId is null.');
      return
    }

    this._initItemsSubscription?.unsubscribe();
    this.loading = true;
    this._initItemsSubscription = this._docPrepService.getFeeDefinitions(externalCompanyId).pipe(
      catchError((error) => {
        const message = error.message ||
          'An error occurred while retrieving the invoicing entries.';
        console.error(message, error);
        this._notificationService.showError(message, 'Error');

        return of([] as FeeDefinition[]);
      }),
    ).subscribe((items) => {
      this.items = items;
      this.resetSelection();
      this.loading = false;
    });
  }

  private resetSelection(): void {
    const docPrepFeeIdSet = new Set(this.docPrep.docPrepFees.map(item => item.docPrepFeeDefinitionId));

    this.selection = this.items.filter(item => docPrepFeeIdSet.has(item.docPrepFeeDefinitionId));
  }

  protected onSelectionChange(selection: FeeDefinition[]): void {
    this.selection = [...selection];

    this.docPrep.invoiceTotal = this.selection.reduce((sum, invoice) => sum + invoice.amount, 0);

    this.docPrep.docPrepFees = selection.map(item => ({
      docPrepFeeDefinitionId: item.docPrepFeeDefinitionId,
      companyId: item.companyId,
    } as DocPrepFee));
  }
}
