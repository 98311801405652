import { FeePercentOfFieldEnum } from "src/app/models/fee/fee-percent-of-field.enum";
import { LoanFee } from "src/app/models/fee/fee.model";

export class FeeCalculationService {
  calculateBorrowerFeeDollar(fee: LoanFee, loanAmount: number, propertyAppraisedValue: number, purchasePrice: number, totalLoanAmount: number) {
    if (!fee.feePercentOf) return;

    switch (fee.feePercentOf) {
      case FeePercentOfFieldEnum.LoanAmount:
        fee.borrowerFeeDollar = ((fee.borrowerFeePercent / 100) * loanAmount) - (fee.sellerFeeDollar ?? 0);
        break;
      case FeePercentOfFieldEnum.PropertyAppraisedValueAmount:
        fee.borrowerFeeDollar = ((fee.borrowerFeePercent / 100) * propertyAppraisedValue) - (fee.sellerFeeDollar ?? 0);
        break;
      case FeePercentOfFieldEnum.PurchasePriceAmount:
        fee.borrowerFeeDollar = ((fee.borrowerFeePercent / 100) * purchasePrice) - (fee.sellerFeeDollar ?? 0);
        break;
      case FeePercentOfFieldEnum.TotalLoanAmount:
        fee.borrowerFeeDollar = ((fee.borrowerFeePercent / 100) * totalLoanAmount) - (fee.sellerFeeDollar ?? 0);
        break;
    }
  }

  calculateFeePercent(fee: LoanFee, loanAmount: number, propertyAppraisedValue: number, purchasePrice: number, totalLoanAmount: number) {
    if (!fee.feePercentOf) return;

    switch (fee.feePercentOf) {
      case FeePercentOfFieldEnum.LoanAmount:
        fee.borrowerFeePercent = ((fee.calculatedValues.totalFee ?? 0) / loanAmount) * 100;
        break;
      case FeePercentOfFieldEnum.PropertyAppraisedValueAmount:
        fee.borrowerFeePercent = ((fee.calculatedValues.totalFee ?? 0) / propertyAppraisedValue) * 100;
        break;
      case FeePercentOfFieldEnum.PurchasePriceAmount:
        fee.borrowerFeePercent = ((fee.calculatedValues.totalFee ?? 0) / purchasePrice) * 100;
        break;
      case FeePercentOfFieldEnum.TotalLoanAmount:
        fee.borrowerFeePercent = ((fee.calculatedValues.totalFee ?? 0) / totalLoanAmount) * 100;
        break;
    }

    fee.calculatedValues.totalFeePercent = fee.borrowerFeePercent ?? 0;
  }

  calculateBorrowerAmountPaid(fee: LoanFee) {
    fee.calculatedValues.borrowerTotal = (fee.borrowerFeeDollar ?? 0) - (fee.lenderFeeDollar ?? 0) - (fee.thirdPartyFeeDollar ?? 0);
  }

  calculateTotalFee(fee: LoanFee) {
    fee.calculatedValues.totalFee = (fee.calculatedValues.borrowerTotal ?? 0) + (fee.lenderFeeDollar ?? 0) + (fee.thirdPartyFeeDollar ?? 0) + (fee.sellerFeeDollar ?? 0);
  }
}