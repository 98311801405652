<div class="modal-header">
  <h5 class="modal-title">Filters</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body overflow-hidden">
  <date-range-filter
    class="mb-2"
    [selectedRange]="filters.dateRange"
    (rangeChanged)="dateRangeChanged($event)">
  </date-range-filter>

  <div class="form-group mb-2">
    <label for="campaign" class="form-label">Campaign</label>

    <select
      class="form-select"
      id="campaign"
      name="campaign"
      [(ngModel)]="filters.campaignId">
      <option [ngValue]="null">-- Select One --</option>
      <option
        *ngFor="let campaign of leadCampaigns"
        [ngValue]="campaign.leadCampaignId">
        {{ campaign.name }}
      </option>
    </select>
  </div>

  <div class="form-group mb-2">
    <label for="branch" class="form-label">Branch</label>
    <select
      id="branch"
      name="branch"
      [(ngModel)]="filters.branchId"
      class="form-select"
    >
      <option [ngValue]="null">-- Select One --</option>
      <option [ngValue]="branch.branchId" *ngFor="let branch of branches">
        {{ branch.branchName }}
      </option>
    </select>
  </div>

  <div class="form-group mb-2">
    <label for="user" class="form-label">User</label>
    <select
      id="user"
      name="user"
      [(ngModel)]="filters.showUserId"
      class="form-select"
    >
      <option [ngValue]="null">-- Select One --</option>
      <option [ngValue]="user.userCompanyGuid" *ngFor="let user of users">
        {{ user.lastName + ', ' + user.firstName }}
      </option>
    </select>
  </div>

  <div class="form-group mb-2">
    <label for="state" class="form-label">State</label>
    <select
      id="state"
      name="state"
      [(ngModel)]="filters.state"
      class="form-select"
    >
      <option [ngValue]="null">-- Select One --</option>
      <option [ngValue]="state.value" *ngFor="let state of states">
        {{ state.name }}
      </option>
    </select>
  </div>

  <div class="form-group mb-2">
    <label for="leadTag" class="form-label">Lead Tag</label>
    <select
      id="leadTag"
      name="leadTag"
      [(ngModel)]="filters.leadListId"
      class="form-select"
    >
      <option [ngValue]="null">-- Select One --</option>
      <option
        [ngValue]="leadList.leadListId"
        *ngFor="let leadList of leadLists"
      >
        {{ leadList.name }}
      </option>
    </select>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-danger "
    (click)="clearFilters()"
  >
    <i class="fa fa-clear"></i> Clear All
  </button>

  <button
    type="submit"
    class="btn btn-primary "
    (click)="onOkClicked()"
  >
    <i class="fa fa-check"></i> Apply
  </button>
</div>
