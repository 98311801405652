export class EmailService {
    apiKey: string = null;
    branchId: number;
    companyId: number;
    dateInserted: string;
    dateUpdated: string;
    disabledReason: string;
    id: number;
    insertedBy: string;
    isDisabled: boolean
    isGlobal: boolean
    isSecure: boolean = null;
    name: string;
    password: string = null;
    port: number = null;
    server: string = null;
    serviceType: ServiceType = "";
    servicesUrl: string = null;
    updatedBy: string;
    userId: string;
    userName: string = null;

    constructor(args: Partial<EmailService> = {}, emailService: Partial<EmailService> = {}) {
        Object.keys(args).forEach(key => {
            this[key] = args[key];
        })

        this.companyId = emailService.companyId ?? null;
        this.id = emailService.id ?? null;
        this.userId = emailService.userId || null;
        this.dateInserted = emailService.dateInserted || null;
        this.dateUpdated = emailService.dateUpdated || null;
        this.insertedBy = emailService.insertedBy || null;
        this.updatedBy = emailService.updatedBy || null;
        this.isDisabled = !!emailService.isDisabled;
        this.isGlobal = !!emailService.isGlobal;
    }
}

type ServiceType = "Smtp" | "ExchangeWebServices" | "SendGrid" | "ExchangeMicrosoftGraph" | "AmazonSes" | ""