import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formViewProvider } from '../../../../core/services/form-view.provider';
import * as moment from 'moment';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { max } from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { PerDiemCalculationMethodType } from 'src/app/models/fee/fee-calculated-values.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'odd-days-interest',
  templateUrl: 'odd-days-interest.component.html',
  styleUrls: ['../../loan-fees.component.scss'],
  viewProviders: [formViewProvider]
})

export class OddDaysInterestComponent implements OnInit {

  @Input()
  fee: LoanFee;

  @Input()
  hideTitle: boolean = false;

  @Output()
  onFeeValueUpdated = new EventEmitter<LoanFee>();

  @Output()
  onFeeRemoved = new EventEmitter<LoanFee>();

  pendingDelete = false;
  interestCreditEnabled = false;

  calculationMethods: EnumerationItem[] = [];

  constructor(
    private readonly _enumerationService: EnumerationService
  ) { }

  ngOnInit() {
    this._enumerationService.getFeeEnumerations().subscribe(feeEnums => {
      this.calculationMethods = feeEnums[Constants.feeEnumerations.perDiemCalculationMethodType] || [];
    })

    this.setInterestCredit();

    if (!this.fee.calculatedValues.calculationMethod) {
      this.setInitCalculationMethod();
    }
  }

  attemptRemoveFee = () => {
    this.pendingDelete = true;
  }

  removeFee = (fee: LoanFee) => {
    this.pendingDelete = false;
    this.onFeeRemoved.next(fee);
  }

  abortRemoveFee = () => {
    this.pendingDelete = false;
  }

  onEstimatedClosingDateChange = () => {
    const isEstimatedClosingDateValid = moment(this.fee.calculatedValues.estimatedClosingDate, "MM/DD/YYYY", true).isValid();
    if (!isEstimatedClosingDateValid) return;

    this.calculateNumberOfDays();
    this.onFeeValueUpdated.emit(this.fee);
  }

  onCalculationMethodChange = () => {
    this.onFeeValueUpdated.emit(this.fee);
  }

  private setInitCalculationMethod = () => {
    this.fee.calculatedValues.calculationMethod = PerDiemCalculationMethodType.Item365;
  }

  private setInterestCredit = () => {
    if (!moment(this.fee.calculatedValues.estimatedClosingDate).isValid()) {
      return;
    }
    const lastDateForInterestCredit = this.getLastDateForInterestCredit();
    this.interestCreditEnabled = lastDateForInterestCredit >= moment(this.fee.calculatedValues.estimatedClosingDate).date();
    if (!this.interestCreditEnabled) {
      this.fee.calculatedValues.interestCredit = false;
    }
  }

  private getLastDateForInterestCredit() {
    const currentDate = moment(this.fee.calculatedValues.estimatedClosingDate).startOf('month')
    const businessDays: number[] = [];

    while (businessDays.length < 5) {
      if (currentDate.isoWeekday() <= 5) {
        businessDays.push(currentDate.date());
      }
      currentDate.add(1, 'day');
    }

    return max(businessDays);
  }

  private calculateNumberOfDays = () => {
    if (!moment(this.fee.calculatedValues.estimatedClosingDate, "MM/DD/YYYY", true).isValid()) {
      this.fee.calculatedValues.oddDays = 0;
      return;
    }

    const currentMonthDay = moment(this.fee.calculatedValues.estimatedClosingDate).format('MM/DD/YYYY');
    const nextMonthFirstDay = moment(this.fee.calculatedValues.estimatedClosingDate).add(1, 'M').startOf('month').format('MM/DD/YYYY');

    this.fee.calculatedValues.oddDays = moment(nextMonthFirstDay).diff(moment(currentMonthDay).startOf('day'), 'days');
  }

}
