<div class='table-header'>
  <div class='leading-column'></div>

  <div class='title-column'>
    Fee
  </div>

  <div class='amount-column'>
    <div class='at-closing-column'>At Closing</div>
    <div class='before-closing-column'>Before Closing</div>
  </div>

  <div class='payer-column'>
    Paid By
  </div>

  <div class='actions-column'></div>
</div>

<fees-review-section
  (showFeeDetails)='onShowFeeDetails($event)'
  *ngFor='let feeSection of feeSections; let i = index'
  [actions]='sectionActions?.get(feeSection.type)'
  [editable]='editable'
  [feeSectionType]='feeSection.type'
></fees-review-section>
