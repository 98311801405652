import { LockPriceField } from "src/app/modules/pricing/models/pricing/loan-pass-iframe-lock-price-message.model";

export class LoanPassExecuteRequest {
  productId: string;
  pricingProfileId: string;
  currentTime: string;
  creditApplicationFields: LockPriceField[];
  outputFieldsFilter: {
    type: 'All' | 'Only' | 'None',
    fieldIds: string[]
  };
}
