import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ExternalCompanyBranchLicence } from '../../external-company-management/models';
import { BranchLoanOptions } from 'src/app/modules/app-details/models/branch-loan-options.model';
import {
  BranchSettings,
  BranchUser,
  BranchUsers,
  CompanyAsBranch,
  NewBranchUsers,
} from '../models/branch.model';
import { Branch, ThirdPartyCredential, ThirdPartyCredentialType } from 'src/app/models';

@Injectable()
export class BranchService {
  constructor(private readonly _dataService: DataService) { }

  getCompany(companyId: number): Observable<CompanyAsBranch> {
    return this._dataService.get(
      `api/Admin/GetBranchModel?branchId=-1&companyId=${companyId}`
    );
  }

  getBranchesForCompany(
    activeOnly = true
  ): Observable<Branch[]> {
    let url = 'api/Admin/AllBranchModel';
    url += `?activeOnly=${activeOnly}`

    return this._dataService.get(url);
  }

  getAllBranchLoanOptions = (
    branchId: number,
    companyId: number
  ): Observable<BranchLoanOptions> => {
    const url = `api/Admin/GetAllBranchLoanOptions/${branchId}?companyId=${companyId}`;
    return this._dataService.get(url);
  };

  insertBranch(data: any) {
    return this._dataService.post(
      `api/Admin/InsertBranchModel`,
      data
    );
  }

  updateBranch(data: Branch) {
    return this._dataService.post(
      `api/Admin/UpdateBranchModel?companyId=${data.companyId}`,
      data
    );
  }

  deleteBranch(branchId: number, action: "Delete" | "Archive", query?: string) {
    let url = `api/Admin/${action}BranchModel/${branchId}`;
    if (query) url += `?${query}`;

    return this._dataService.delete(url);
  }

  //#region branch settings
  getBranchSettigs(branchId: number): Observable<BranchSettings> {
    return this._dataService.get(
      `api/Admin/GetBranchSettings?branchId=${branchId}`
    );
  }

  upsertBranchSettings(data: BranchSettings, branchId: number): Observable<BranchSettings> {
    return this._dataService.post(
      `api/Admin/UpsertBranchSettings?branchId=${branchId}`,
      data
    );
  }

  //#endregion

  //#region branch users
  getBranchUsers(branchId: number): Observable<BranchUsers> {
    return this._dataService.get(
      `api/Admin/AllBranchUserModel?branchId=${branchId}`
    );
  }

  insertBranchUsers(
    users: NewBranchUsers,
  ): Observable<BranchUsers> {
    return this._dataService.post(
      `api/Admin/InsertBranchUserModels`,
      users
    );
  }

  updateBranchUsers(
    user: BranchUser,
  ): Observable<BranchUser> {
    return this._dataService.post(
      `api/Admin/UpdateBranchUserModel`,
      user
    );
  }

  deleteBranchUser(branchUserId: number): Observable<any> {
    return this._dataService.delete(
      `api/Admin/DeleteBranchUserModel/${branchUserId}`
    );
  }

  moveBranchUser(users: any, companyId: number): Observable<BranchUser> {
    return this._dataService.post(
      `api/Admin/MoveBranchUserModel?companyId=${companyId}`,
      users
    );
  }
  //#endregion

  //#region branch online apps
  getOnlineApplications(branchId: number): Observable<any> {
    return this._dataService.get(
      `api/Admin/GetBranchOnlineApps?branchId=${branchId}`,
    );
  }
  //#endregion


  getExternalBranchLicences(branchId: number): Observable<Array<ExternalCompanyBranchLicence>> {
    return this._dataService.get(
      `api/branch/${branchId}/license`,
    );
  }

  insertBranchLicence(branchId: number, licence: ExternalCompanyBranchLicence): Observable<ExternalCompanyBranchLicence> {
    return this._dataService.post(
      `api/branch/${branchId}/license`,
      licence
    );
  }

  updateBranchLicence(branchId: number, branchLicenseId: number, licence: ExternalCompanyBranchLicence): Observable<ExternalCompanyBranchLicence> {
    return this._dataService.put(
      `api/branch/${branchId}/license/${branchLicenseId}`,
      licence
    );
  }

  deleteBranchLicence(branchId: number, branchLicenseId: number): Observable<null> {
    return this._dataService.delete(
      `api/branch/${branchId}/license/${branchLicenseId}`,
    );
  }

  getExternalBranchLoanOptions(branchId: number): Observable<BranchLoanOptions> {
    return this._dataService.get(
      `api/Admin/GetAllBranchLoanOptions/${branchId}`,
    );
  }

  updateExternalBranchLoanOptions(data: any): Observable<any> {
    return this._dataService.post(
      `api/Admin/UpsertBranchLoanOptionsModel`,
      data
    );
  }

  moveUser(data: BranchUser): Observable<BranchUser> {
    return this._dataService.post(
      `api/Admin/MoveBranchUserModel`,
      data
    );
  }
}
