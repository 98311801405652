import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/services/constants';
import { AppPasswordResult, AppPasswordsDialogComponent, AppPassword } from './app-passwords-dialog/app-passwords-dialog.component';
import { NotificationService } from 'src/app/services/notification.service';
import { DeleteAppPasswordDialogComponent } from './delete-app-password-dialog/delete-app-password-dialog.component';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationContext } from 'src/app/models';

@Component({
  selector: 'app-passwords',
  templateUrl: 'app-passwords.component.html'
})

export class AppPasswordsComponent extends ApplicationContextBoundComponent implements OnInit {

  protected isLoading: boolean = true;
  protected isSaving: boolean;
  protected tableData: AppPassword[] = [];
  protected currentlyLoggedInUsername: string;

  private _applicationContextSubscription: Subscription | null = null;

  constructor(injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _authService: AuthService,
  ) {
    super(injector);
    this.scrollOffset = 475;
  }

  ngOnInit() {
    this.subscribeToApplicationContext();
  }

  ngOnDestroy() {
    this._applicationContextSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  save = () => {

  }

  addNewAppPasswordConfig = () => {
    const modalRef = this._modalService.open(AppPasswordsDialogComponent, Constants.modalOptions.medium);

    modalRef.result.then((result: AppPasswordResult) => {
      if (!result) {
        return
      }
      let as = new AppPassword();
      as.clientId = result.generatedClientId;
      as.displayName = result.appName;

      this.tableData.push(as);
      this.tableData = [...this.tableData];
    }, () => {
    });
  }

  removeAppPassword = (appPassword: AppPassword) => {
    const modalRef = this._modalService.open(DeleteAppPasswordDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.currentlyLoggedInUsername = this.currentlyLoggedInUsername;
    modalRef.componentInstance.clientId = appPassword.clientId;

    modalRef.result.then((response: string) => {
      if (!response) {
        this._notifyService.showError("Password doesn't match.", "Error");
        return;
      }

      const index = this.tableData.findIndex(el => el.clientId === appPassword.clientId);
      
      if (index > -1) {
        this.tableData.splice(index, 1);
        this._notifyService.showSuccess("App password deleted successfully.", "Success");
        this.tableData = [...this.tableData];
      } else {
        this._notifyService.showError("App password not found.", "Error");
      }  
    }, () => { });
  }

  private subscribeToApplicationContext(): void {
    this._applicationContextSubscription?.unsubscribe();

    this._applicationContextSubscription = this.applicationContextService
      .context.subscribe({
        next: (context: ApplicationContext) => {
          this._authService.getAllAppServices().subscribe({
            next: (response) => {
              this.tableData = response;
            },
            error: () => {

            }
          }).add(() => this.isLoading = false);

          this.currentlyLoggedInUsername = context.currentlyLoggedInUser.email;
        },
        complete: () => {
          this._applicationContextSubscription = null;
          this.isLoading = false;
        },
      });
  }
}