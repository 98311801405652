import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { PricingScenario } from '../../../models/pricing/pricing-scenario.model';

@Component({
  selector: 'scenario-taxes-editor',
  templateUrl: './scenario-taxes-editor.component.html',
  styleUrls: ['./scenario-taxes-editor.component.scss']
})
export class ScenarioTaxesEditorComponent implements OnInit {

  @Input()
  set scenario(scenario: PricingScenario) {
    this._originalScenario = scenario;
    this._scenario = _.cloneDeep(scenario);
  }

  @Output()
  applied: EventEmitter<PricingScenario> = new EventEmitter<PricingScenario>();

  @Output()
  cancelled: EventEmitter<PricingScenario> = new EventEmitter<PricingScenario>();

  get scenario(): PricingScenario {
    return this._scenario;
  }

  private _scenario: PricingScenario;

  private _originalScenario: PricingScenario;

  constructor() {
  }

  ngOnInit(): void {

  }

  onApplyClicked = () => {
    this.applied.emit(this._scenario);
  }

  onCancelClicked = () => {
    this.cancelled.emit(this._originalScenario);
  }

  onMonthlyInsuranceChanged = () => {
    const changeInValue = (this._scenario.monthlyInsurance || 0)  - (this._originalScenario.monthlyInsurance || 0);
    this._scenario.totalPayment += changeInValue;
  }

  onMonthlyMiChanged = () => {
    const changeInValue = (this._scenario.monthlyMi || 0) - (this._originalScenario.monthlyMi || 0);
    this._scenario.totalPayment += changeInValue;
  }

  onMonthlyTaxesChanged = () => {
    const changeInValue = (this._scenario.monthlyTaxes || 0) - (this._originalScenario.monthlyTaxes || 0);
    this._scenario.totalPayment += changeInValue;
  }
}

