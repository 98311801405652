<div class="modal-header">
    <h5 class="modal-title">Change Responsible Party</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="row mb-3 text-center">
        <p>You are changing <i>{{count}}</i> task{{count > 1 ? 's' : ''}}!</p>

        <div class="row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end"><b>Responsible Party:</b></label>
            <div class="col-sm-8">
                <select class="form-select" name="contacts" [(ngModel)]="responsibilityPartyUserId">
                    <option selected value=''> -- Select One -- </option>
                    <ng-container *ngIf="branchUsers && branchUsers.length > 0">
                        <ng-container *ngFor="let bUsers of branchUsers | groupBy: 'roleName'">
                            <optgroup label="{{bUsers.key}}">
                                <ng-container *ngFor="let user of bUsers.value">
                                    <option [ngValue]="user.userCompanyGuid">
                                        {{user.lastName + ", " + user.firstName}}
                                    </option>
                                </ng-container>
                            </optgroup>
                        </ng-container>
                    </ng-container>
                        
                    <optgroup label="All Users">
                        <option *ngFor="let user of users" [selected]="user.userCompanyGuid == responsibilityPartyUserId"
                            value="{{user.userCompanyGuid}}">
                            {{user.lastName + ", " + user.firstName}}
                        </option>
                    </optgroup>
                </select>
            </div>
        </div>

        <p class="mt-2">Do you want continue?</p>
    </div>
</div>
<div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">No</button>
    <button type="button" class="btn btn-primary right" [disabled]="!responsibilityPartyUserId" (click)="activeModal.close(responsibilityPartyUserId)">Yes</button>
</div>