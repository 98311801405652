import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactsService } from '../../services/contacts.service';

@Component({
  selector: 'new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.scss']
})
export class NewContactComponent implements OnInit {

  @Input()
  isDrawer: boolean = false;

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  contactCreated: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly _contactsService: ContactsService,
  ) { }

  ngOnInit(): void {
  }

  onCloseDrawer = () => {
    this.closeDrawer.emit();
  }

  onBorrowerSaved = (fullBorrower) => {
    this._contactsService.updateContactsTable(fullBorrower.borrower);
    this.contactCreated.emit(fullBorrower.borrower);
  }
}
