<div class="row my-2" #activityHeader>
  <div class=" justify-content-end">
    <div class="input-group align-items-center justify-content-end">
      <label class="align-self-center me-2">View: </label>
      <div ngbDropdown #myDrop="ngbDropdown" display="dynamic" placement="bottom-right">
        <button class="btn btn-outline-primary" ngbDropdownToggle>
          {{ selectedViewTypes.length }} selected
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div ngbDropdownMenu class="w-100 overflow-auto" style="max-height: 400px !important">
          <div *ngFor="let view of views; let i = index" class="mx-2">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [ngModel]="view.checked"
                (ngModelChange)="view.checked = $event; changedView()" name="listName{{ i }}" id="listName{{ i }}" />
              <label class="form-check-label" for="listName{{ i }}">
                {{ view.type }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="container" style="height: calc(100% - 2rem)">
  <ngx-spinner *ngIf="isLoading" bdColor="rgba(0,0,0,0.0)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="false">
    <p style="color: white"> </p>
  </ngx-spinner>

  <div class="row justify-content-center px-2 activities-list activities-list-height" style="height: 100%">
    <div class="d-flex flex-column" style="height: 100%">
      <div class="col-lg-12 card flex-grow-1 activities-list-height" style="height: 50%">
        <div class="analytic-dash-activity" data-simplebar style="height: 100%">
          <div class="activity">

            <div style="padding: 10px; overflow-y: auto" id="activityContent" #scrollMe
              [scrollTop]="scrollMe.scrollHeight">
              <div *ngFor="let group of groupedData">

                <!-- icon -->
                <div class="my-2 text-center" *ngIf="group.groupDate != 'undefined' && (group.activities | filter: 'typeDisplayName').length">
                  <span class="badge rounded-pill bg-info"> {{ group.groupDate }}</span>
                </div>

                <div class="activity-info" *ngFor="let leadActivity of group.activities | filter: 'typeDisplayName'">
                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Voice Mails'">
                    <i class="las la-phone bg-soft-primary"></i>
                  </div>

                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Route'">
                    <i class="las la-phone bg-soft-primary"></i>
                  </div>
                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Calls'">
                    <i class="las la-phone bg-soft-primary"></i>
                  </div>
                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Notes'">
                    <i class="las la-book bg-soft-primary"></i>
                  </div>
                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Sms'">
                    <i class="las la-comments bg-soft-primary"></i>
                  </div>

                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Status Changes'">
                    <i class="las la-arrows-alt-h bg-soft-primary"></i>
                  </div>

                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Borrower Messages'">
                    <i class="las la-comments bg-soft-primary"></i>
                  </div>
                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Internal Messages'">
                    <i class="las la-comments bg-soft-primary"></i>
                  </div>

                  <div class="icon-info-activity" *ngIf="leadActivity['typeDisplayName'] === 'Emails'">
                    <i class="las la-envelope bg-soft-primary"></i>
                  </div>

                  <!-- message -->
                  <div class="activity-info-text mt-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div *ngIf="leadActivity['typeDisplayName'] === 'Calls'" class="w-100">
                        <p [hidden]="leadActivity.activity.recordHistory" class="mb-0 font-13 w-75">
                          A call was placed.
                        </p>
                        <p [hidden]="!leadActivity.activity.recordHistory">A call was placed lasting <span>
                            {{secondsToHms(leadActivity.activity.recordHistory.durationInSeconds)}}.</span>
                        </p>
                        <p
                          [hidden]="!leadActivity.activity.recordHistory || leadActivity.activity.recordHistory.callRecorded">
                          The call was not recorded.
                        </p>
                        <p
                          [hidden]="!leadActivity.activity.recordHistory || !leadActivity.activity.recordHistory.callRecorded">
                          <a (click)="listenToRecording(leadActivity.activity.recordHistory.voiceHistoryId)"
                            class="btn btn-xs btn-soft-primary me-1" href="javascript: void(0);">
                            <i class="fa fa-play-circle" aria-hidden="true"></i>&nbsp;
                            Play Recording
                          </a>
                          <a (click)="downloadRecording(leadActivity.activity.recordHistory.voiceHistoryId)"
                            class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
                            <i class="fa fa-download" aria-hidden="true"></i>&nbsp;
                            Download Recording
                          </a>
                        </p>
                        <p>
                          <span class="text-muted">by: {{ leadActivity.userFullName }} ~ {{
                            leadActivity.dateInserted | date:'MMM d, y h:mm a'}}</span>
                        </p>
                      </div>

                      <div *ngIf="leadActivity['typeDisplayName'] === 'Voice Mails'" class=" w-100">
                        <ng-container *ngIf="leadActivity['activityType'] !== 'Rvm'">
                          <p *ngIf="leadActivity.activity.type === 'phoneSuccess'" class="mb-0 font-13 w-75">
                            A <strong>successful</strong> phonecall was made.
                          </p>
                          <p *ngIf="leadActivity.activity.type === 'phoneAttempt'" class="mb-0 font-13 w-75">
                            A phonecall was <strong>attempted</strong>.
                          </p>
                        </ng-container>

                        <ng-container *ngIf="leadActivity['activityType'] === 'Rvm'">
                          <p *ngIf="leadActivity.activity.type === 'Success'" class="mb-0 font-13 w-75">
                            Ringless voicemail <strong>was sent</strong>.
                          </p>
                          <p *ngIf="leadActivity.activity.type === 'Failure'" class="mb-0 font-13 w-75">
                            Ringless voicemail attempt <strong>failed</strong>.
                          </p>
                          <p *ngIf="leadActivity.activity.type !== 'Success' && leadActivity.activity.type !== 'Failure'" class="mb-0 font-13 w-75">
                            {{ leadActivity.activity.type | splitCamelcase }}
                          </p>
                        </ng-container>

                        <p [hidden]="!leadActivity.activity.note" class="mb-0 font-13 w-75">
                          <i><span>{{ leadActivity.activity.note }}</span></i>
                        </p>
                        <p>
                          <span class="text-muted">
                            by: {{ leadActivity.userFullName }} ~ {{ leadActivity.dateInserted | date:'MMM d, y h:mm a' }}
                          </span>
                        </p>
                      </div>
                      <div *ngIf="leadActivity['typeDisplayName'] === 'Sms'" class="w-100">
                        <strong>SMS sent to:</strong> {{leadActivity.messageTo ? (leadActivity.messageTo | phone) :
                        "--"}}
                        <br>
                        <strong>From:</strong> {{leadActivity.messageFrom ? (leadActivity.messageFrom | phone) : "--"}}
                        <p>
                          <a class="btn btn-xs btn-soft-primary" (click)="showEmailDetails(leadActivity.activity)"
                            href="javascript: void(0);">
                            <i class="fa fa-eye"></i> View
                          </a>
                        </p>
                        <p>
                          <span class="text-muted">by: {{ leadActivity.userFullName }} ~ {{
                            leadActivity.dateInserted | date:'MMM d, y h:mm a'}}</span>
                        </p>
                      </div>
                      <div class="w-100" *ngIf="leadActivity['typeDisplayName'] === 'Status Changes'">

                        <p class="mb-0 font-13 w-75" class="w-75">
                          Lead Status changed to
                          <strong>{{ leadActivity.activity.note}}</strong>
                        </p>
                        <p>
                          <span class="text-muted">by: {{ leadActivity.userFullName }} ~ {{
                            leadActivity.activity.dateInserted | date:'MMM d, y h:mm a'}}</span>
                        </p>
                      </div>
                      <div *ngIf="leadActivity['typeDisplayName'] === 'Route'" class="w-100">
                        <p class="mb-0 font-13 w-75" class="w-100">
                          The lead was routed to <strong>{{ leadActivity.userFullName}}</strong> at
                          <strong>{{ leadActivity.activity.dateInserted | date:'MMM d, y h:mm a'}}</strong>
                          {{ leadActivity.activity.leadRouteType == 'Redistribution' ? ' via Redistribution ' : ' '
                          }}
                          {{ leadActivity.activity.distributionMode == 'FreeForAll' ? '(Free For All)' : ''}}
                          {{ leadActivity.activity.leadRouteName ? ' on route: ' + leadActivity.activity.leadRouteName
                          :
                          ''
                          }}
                          {{ '. Accepted - ' + ((leadActivity.dateInserted)
                          |
                          date:'MMM
                          d, y
                          h:mm a')}}
                        </p>
                      </div>
                      <div
                        *ngIf="leadActivity['typeDisplayName'] === 'Notes' || leadActivity['typeDisplayName'] === 'Internal Messages'"
                        class="w-100">
                        <p class="mb-0 font-13 w-75">
                          A note was added:
                        </p>
                        <p [hidden]="!leadActivity.activity.note" class="mb-0 font-13 w-75">
                          <i><span [innerHTML]="leadActivity.activity.note | safeHtml"></span></i>
                          <i class="fa fa-trash-alt btn btn-xs btn-soft-danger font-13 ms-2"
                            (click)="deleteNoteEvent(leadActivity.activity)" title="delete note"></i>
                        </p>
                        <!--
                        <div class="d-flex flex-wrap" *ngIf="leadActivity.activity['hasMediaFiles']">
                          <div class="img-container" *ngFor="let mediaFile of leadActivity.activity['mediaFiles']">
                            <i *ngIf="!mediaFile" class="d-block task-value fas fa-sync-alt fa-spin mx-1 mt-1"
                              style="font-size: 25px; margin-top: 4px; margin-bottom: 4px;"></i>
                            <ng-container *ngIf="mediaFile">
                              <img [src]="mediaFile" height="150" border="0" style="margin: 8px 0; cursor: pointer;" (click)="viewImage(mediaFile)">
                            </ng-container>
                          </div>
                        </div>
                      -->
                        <p [hidden]="!leadActivity.activity.displayText" class="mb-0 font-13 w-75">
                          <i><span>{{ leadActivity.activity.displayText }}</span></i>
                        </p>
                        <p>
                          <span class="text-muted">by: {{ leadActivity.userFullName }} ~ {{
                            leadActivity.dateInserted | date:'MMM d, y h:mm a'}}</span>
                        </p>

                      </div>
                      <div *ngIf="leadActivity['typeDisplayName'] === 'Borrower Messages'" class="w-75">
                        <p class="mb-0 font-13 w-75">
                          Borrower message from
                          <span>{{ leadActivity.userId === userId ? 'you' : (leadActivity.userFullName
                            ||
                            'Unknown')
                            }}</span>.
                        </p>
                        <p [hidden]="!leadActivity.activity.displayText" class="mb-0 font-13 w-75">
                          {{ leadActivity.activity.displayText }}
                        </p>
                        <small>{{leadActivity.activity.dateCreated | date : 'short' }}</small>
                      </div>

                      <div *ngIf="leadActivity['typeDisplayName'] === 'Emails'" class="w-75">
                        <strong>Email sent to:</strong> {{leadActivity.messageTo}}
                        <br>
                        <strong>Subject:</strong> {{leadActivity.subject | slice:0:300}}

                        <p>
                          <a class="btn btn-xs btn-soft-primary" (click)="showEmailDetails(leadActivity.activity)"
                            href="javascript: void(0);">
                            <i class="fa fa-eye"></i> View
                          </a>
                        </p>
                        <p>
                          <span class="text-muted">by: {{ leadActivity.userFullName }} ~ {{
                            leadActivity.dateInserted | date:'MMM d, y h:mm a'}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <label class="form-label" for="leadNote">Note</label>
          <message-editor-with-mentions #messageEditor [mentionables]="[]" (enterHit)="onEnterHit()"
            [placeholder]="'Enter your note'" (messageChanged)="onMessageChanged($event)">
          </message-editor-with-mentions>
        </div>
        <div class="d-flex mb-5">
          <span class="flex-grow-1">{{4000 - note.length}} remaining</span>
          <button type="submit" class="btn btn-primary mx-2" [disabled]="note.length === 0 || note.length > 4000"
            (click)="onSaveClicked()">
            Add Note
          </button>
        </div>

      </div>
    </div>

  </div>
</div>
