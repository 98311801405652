import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounce } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { LoanApplication, UserType } from 'src/app/models';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { PricingService } from '../../services/pricing.service';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'lenderprice-pricer',
  templateUrl: 'lenderprice-pricer.component.html',
  styleUrls: ['./lenderprice-pricer.component.scss']
})
export class LenderPricePricerComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  isQuickPricer: boolean = false;

  @Output()
  pricingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pricingCompleted: EventEmitter<LoanApplication> = new EventEmitter<LoanApplication>();

  preparingForPricing: boolean = false;
  losSyncCompleted: boolean = false;
  loanCreateAttempts: number = 0;
  loanSyncAttempts: number = 0;
  error: string = null;

  protected application: LoanApplication;

  protected lenderPricePricerUrl: SafeResourceUrl;
  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _pricingService: PricingService,
    private readonly _environment: EnvironmentService,
    private readonly _localStorage: LocalStorageService
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.application = context.application;
        this.initializeDebounce();
      }
    });
  }

  async ngOnInit() {
    if (this.applicationContext?.application?.applicationId) {
      this.application = this.applicationContext.application;
      this.initializeDebounce();
    }
    else if (this.isQuickPricer)
      this.initializeDebounce();

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  initializeDebounce = debounce(this.initialize, 1000);

  private async initialize() {
    if (this.isQuickPricer) {
      await this.getLenderPricePricerUrl();
      return;
    }

    if (this.applicationContext.userPermissions.userType == UserType.Tpo) {
      if (!this.losSyncCompleted) {
        if (!this.applicationContext.application.losIdentifier) {
          while (this.loanCreateAttempts < 3 && !this.losSyncCompleted) {
            this.loanCreateAttempts++;
            await this.autoCreateLosLoan();
          }
        }
        else {
          await this.getLenderPricePricerUrl();
        }
      }
      else {
        await this.getLenderPricePricerUrl();
      }
    } else {
      await this.getLenderPricePricerUrl();
    }
  }

  private getLenderPricePricerUrl = async () => {
    this.preparingForPricing = true;
    try {
      const url = await firstValueFrom(
        this._pricingService.getLenderPricePricerUrl(this.application?.applicationId),
      );

      this.error = undefined;

      let decodedUrl = decodeURIComponent(url);
      const alreadyHasQueryParams = decodedUrl.includes('?');
      const queryParamsStartingChar = alreadyHasQueryParams ? '&' : '?';
      decodedUrl = decodedUrl + `${queryParamsStartingChar}baseUrl=${this._environment.apiInfo.apiBaseUrl}&token=${this._localStorage.authorizationData.token}&appId=${this.application?.applicationId || 0}`;
      this.lenderPricePricerUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(decodedUrl);

      if (!this.isQuickPricer) {
        // window.addEventListener('message', event => {
        //   console.log("window.message", event);
        //   window.removeAllListeners();
        //   this.pricingCompleted.emit(this.application);
        // }, false);
      }

    } catch (e) {
      this.error = e.message?.replace('{' + this.application?.losIdentifier + '}', this.application?.refNumber) || "There was an error attempting to load Lender Price IFrame.";
    } finally {
      this.preparingForPricing = false;
    }
  }

  private autoCreateLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.mortgage, losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for pricing. Please contact your AE.";
      this.preparingForPricing = false;
    }
  }

  private autoSyncLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoSyncLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.mortgage, losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for pricing. Please contact your AE.";
      this.preparingForPricing = false;
    }
  }

  onCancelClicked() {
    this.pricingCancelled.emit();
  }
}
