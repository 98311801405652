<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        APR / APOR
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #aprAporForm="ngForm" id="aprAporForm">
      <div class="row">
        <div class="col-md-3 form-group">
          <label for="qualifyingRateType"> Qualifying Rate Type </label>
          <select id="qualifyingRateType" name="qualifyingRateType" class="form-select"
            [(ngModel)]="regulationZDetail.qualifyingRateType" (change)="onQualifyingRateTypeChanged()">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of qualifyingRateTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 form-group" *ngIf="regulationZDetail.qualifyingRateType === 'Other'">
          <label for="qualifyingRateTypeOtherDescription"> Qualifying Rate Type Other Desc. </label>
          <input type="text" class="form-control" id="qualifyingRateTypeOtherDescription"
            name="qualifyingRateTypeOtherDescription"
            [(ngModel)]="regulationZDetail.qualifyingRateTypeOtherDescription">
        </div>
        <div class="col-md-3 form-group">
          <label for="qualifyingRatePercent"> Qualifying Rate % </label>
          <percent-input name="qualifyingRatePercent"
            [(ngModel)]="mortgageTerm.qualifyingRate"></percent-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 form-group">
          <label for="summaryInterestedPartyContributionsPercent">
            Summary Interested Party Contributions %
          </label>
          <percent-input name="summaryInterestedPartyContributionsPercent"
            [(ngModel)]="regulationZDetail.summaryInterestedPartyContributionsPercent"></percent-input>
        </div>
        <div class="col-md-3 form-group">
          <label for="qualifiedMortgageTemporaryGSEType"> Qualified Mortgage Temporary GSE Type </label>
          <select id="qualifiedMortgageTemporaryGSEType" name="qualifiedMortgageTemporaryGSEType" class="form-select"
            [(ngModel)]="regulationZDetail.qualifiedMortgageTemporaryGSEType">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of qualifiedMortgageTemporaryGSETypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>
