<div class="row mt-1">
    <div class="col-md-12">
      <div class="me-3">
        <toggle-configuration
          [title]="'Enable Digital Asset Verification'"
          [id]="'digitalAssetVerificationEnabled'"
          [configuration]="digitalAssetVerificationEnabled"
        ></toggle-configuration>
      </div>
    </div>
    <div class="col-md-12 mt-1">
      <hr class="mt-3 mb-0" />
    </div>
    <div class="d-flex align-content-end flex-wrap" *ngIf="digitalAssetVerificationEnabled.value">
      <div class="mt-4 me-2" *ngFor="let credential of assetVerificationCredentials">
        <credential-item [scope]="'Company'" [credential]="credential" [credentials]="assetVerificationCredentials"
                         (openCredentialModal)="openCredentialModal($event)"></credential-item>
      </div>
      <div class="mt-4 me-2">
        <create-credential [scope]="'Company'" [credentialType]="'VOA'" [credentials]="assetVerificationCredentials"
                           (openCredentialModal)="openVendorPicker($event)"></create-credential>
      </div>
    </div>
    <div class="col-md-12">
      <enable-message *ngIf="digitalAssetVerificationEnabled.value === 0">
        Enable this feature above!
      </enable-message>
    </div>
    <div class="col-md-12 pt-4">
      <credentials-basic
        [title]="'Meridian Smart API'"
        [titleLabel]="'Coming soon!'"
        [titleLabelBg]="'badge-soft-warning'"
        [disabled]="true"
        [enableMessageShow]="digitalAssetVerificationEnabled.value === 0"
        [enableMessage]="'Enable this feature above!'"
      ></credentials-basic>
    </div>
</div>
