import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { DataService } from "../core/services/data.service";
import { ZipCodeLookupResult } from "../models/zipcode-lookup-result.model";
import { EnumerationItem } from "../models/simple-enum-item.model";

@Injectable()
export class ZipCodeService {

  constructor(private readonly _dataService: DataService) { }

  lookupZipCode = (zipCode: string, isUspsVerified: boolean): Observable<ZipCodeLookupResult[]> => {
    const url = `api/Zipcode/Lookup/${zipCode}?showOnlyUSPSRecognized=${isUspsVerified}`;
    return this._dataService.get(url);
  }

  getCountiesByState = (state: string): Observable<EnumerationItem[]> => {
    const url = `api/ZipCode/Lookup/state/${state}/counties`
    return this._dataService.get(url).pipe(
      map(items => items.map(i => ({
        name: i.county,
        value: i.countyFips
      })))
    );
  }
}
