import { Component, OnInit, Input, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { result } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { IdentifyingDocument, LoanDoc } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { StoredFileService } from 'src/app/services/stored-file.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'add-edit-identifying-document-dialog',
  templateUrl: './add-edit-identifying-document-dialog.component.html',
  styleUrls: ['./add-edit-identifying-document-dialog.component.scss']
})
export class AddEditIdentifyingDocumentDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('identifyingDocumentForm') identifyingDocumentForm: NgForm | undefined;

  @Input()
  document: IdentifyingDocument;

  @Input()
  identityDocumentTypes: EnumerationItem[] = [];

  @Input()
  identityDocumentIssuedByTypes: EnumerationItem[] = [];

  @Input()
  states: EnumerationItem[] = [];

  isSaving: boolean = false;

  constructor(readonly activeModal: NgbActiveModal,
    private readonly injector: Injector,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _spinner: NgxSpinnerService) {
      super(injector);
     }

  ngOnInit(): void {
    if (this.document.docFileId) {
      this._loanDocsService.getDocFiles(this.applicationContext.application.applicationId).subscribe({
        next: (loanDocs) => {
          let loanDoc = loanDocs.find(ld => ld.docFileId);
          this.document.file = loanDoc;
        },
        error: (error) => {}
      }).add(() => this._spinner.hide());
    }
  }

  handleFileUpload = (event) => {
    event.target.files.forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.document.file = {
          fileName: file.name,
          fileData: event.target.result.toString().split(';base64,')[1],
          mimeType: file.type,
          file: file,
          guid: null,
          active: true,
          isNewFile: true
        };
      };
    });
  }

  onIssuedByTypeChanged = () => {
    if (this.document.issuedByType == 'Country' ) {
      this.document.issuedByTypeOtherDescription = '';
      this.document.issuedByStateCode = null;
    }
    if (this.document.issuedByType == 'StateOrProvince' ) {
      this.document.issuedByTypeOtherDescription = '';
      this.document.issuedByCountry = null;
    }
    if (this.document.issuedByType == 'Other' ) {
      this.document.issuedByStateCode = null;
      this.document.issuedByCountry = null;
    }
  }

  save = () => {
    if (this.identifyingDocumentForm) {
      this.identifyingDocumentForm.form.markAllAsTouched();
      if (!this.identifyingDocumentForm.form.valid) {
        return;
      }
      this.activeModal.close(this.document);
    }
  }

  viewFile = () => {
    if (this.document.file.fileData) {
      const decodedData = window.atob(this.document.file.fileData);
      const uInt8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }
      const blob = new Blob([uInt8Array], { type: this.document.file.mimeType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } else {
      this._spinner.show();
      this._loanDocsService.viewDocFileContent(this.applicationContext.application.applicationId, 
        this.applicationContext.userPermissions.userId, this.document.docFileId).subscribe({
        next: (data) => {
          const blob = new Blob([data], { type: data['type'] });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: (error) => {}
      }).add(() => this._spinner.hide());
    }
    
  }

  removeFile = () => {
    this.document.file = null;
    this.document.docFileId = null;
  }

}
