import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MessageDeliveryEnum } from 'src/app/models';
import { MessageHistory } from 'src/app/models/message/message-history.model';
import { MessageStatusEnum } from 'src/app/models/message/message-status.model';

@Component({
  selector: 'email-sms-preview-dialog',
  templateUrl: './email-sms-preview-dialog.component.html',
  styleUrls: ['./email-sms-preview-dialog.component.scss']
})
export class EmailSmsPreviewDialogComponent implements OnInit {

  message: MessageHistory;

  statusHtml: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    let retVal = "<span class='text text-info'>Unknown</span>";
    switch (this.message.status) {
      case MessageStatusEnum.Success:
        if (this.message.mostRecentOpenDate != null)
          retVal = "<span class='text text-success'>Opened " + moment(this.message.mostRecentOpenDate).format('MM/DD/YYYY, h:mm a') + "</span>";
        else
          retVal = "<span class='text text-success'>Sent</span>";
        break;
      case MessageStatusEnum.Failure:
        retVal = '<span class="text text-danger emailRetry" messageId="' + this.message.id + '">Error</span>';
        break;
      case MessageStatusEnum.InProcess:
        retVal = "<span class='text text-info'>Processing</span>";
        break;
      case MessageStatusEnum.Unprocessed:
        retVal = "<span class='text text-info'>In Queue</span>";
        break;
      case MessageStatusEnum.Paused:
        retVal = "<span class='text text-warning'>Paused</span>";
        break;
    }
    this.statusHtml = retVal;

    while (this.message.body.indexOf('\n') > 0) {
      this.message.body = this.message.body.replace('\n', '<br/> ');
    }
    if (this.message.delivery === MessageDeliveryEnum.Email) {
      this.loadEmailTemplatePreview();
    }
  }

  private loadEmailTemplatePreview = () => {
    setTimeout(() => {
      let iframe = document.getElementById(`emailPreviewPanel`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      setTimeout(() => {
        doc.open();
        doc.write(this.message.body);
        doc.close();
      }, 100)
    });
  }
}
