<div class="modal-header">
  <h5 class="modal-title"> Add Loan Contacts to Email </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body" id="addContact">
  <div class="row mb-3">
    <label class="control-label">Select Internal Contacts</label>
    <ng-select width="100%" name="internalContacts" bindLabel="formattedName" bindValue="email"
      placeholder="Select Internal Contacts" [closeOnSelect]="false" [multiple]="true" groupBy="roleName"
      [(ngModel)]="selectedContacts.selectedInternalContactEmails" [items]="extendedInternalContacts" appendTo="body">
    </ng-select>
  </div>
  <div class="row">
    <label class="control-label">Select External Contacts</label>
    <ng-select width="100%" name="externalContacts" bindLabel="formattedName" bindValue="email"
      placeholder="Select External Contacts" [closeOnSelect]="false" [multiple]="true" groupBy="agentType"
      [(ngModel)]="selectedContacts.selectedExternalContactEmails" [items]="extendedExternalContacts" appendTo="body">
    </ng-select>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.close(selectedContacts)" data-bs-dismiss="modal">
    OK
  </button>
</div>