<task-editor
  [task]="task"
  [filterId]="filterId"
  [taskReadonly]="readonly"
  [openFileUpload]="openFileUpload"
  [hostedInModal]="true"
  [startDocumentGeneration]="startDocumentGeneration"
  [startESign]="startESign"
  [borrowers]="borrowers"
  (reloadTasks)="onReloadTasks()"
  (closeClicked)="onCancelClicked($event)"
  (saveClicked)="onSaveClicked()"
  (refreshClicked)="onRefreshClicked()">
</task-editor>
