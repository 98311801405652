<div class="card">
  <div class="card-header">
    <h4 class="card-title"> Disclosure History ({{historyItems ? historyItems.length : '0'}}) </h4>
  </div>
  <div class="card-body">
    <p-table #dt1 [value]="historyItems" sortField="disclosureSent" [sortOrder]="-1" *ngIf="historyItems" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.sortable ? col.field : ''" style="min-width: 100px;">
            {{col.header}}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history let-index>
        <tr class="history-table-body cursor" (click)="showHistoryItemDetailsPanel(history)">
          <td> {{history.disclosureSent | date:'short'}} </td>
          <td> {{history.borrowers[0] ? history.borrowers[0].receivedMethod : '-'}} </td>
          <td> {{history.requestingUserId ? getUserName(history) : '-'}} </td>
          <!-- <td> </td> -->
          <td> {{history.leSent ? 'Yes' : 'No'}} </td>
          <td> {{history.cdSent ? 'Yes' : 'No'}} </td>
          <td> {{history.safeHarborSent ? 'Yes' : 'No'}} </td>
          <td> {{history.settlementServiceProviderListSent ? 'Yes' : 'No'}} </td>
          <td> {{showBorrowersFullName(history.borrowers)}} </td>
          <td> {{history.includeInTimeline ? 'Yes' : 'No'}} </td>
          <td> {{history.disclosureVersion}} </td>
          <td> {{history.intentToProceedReceived ? (history.intentToProceedReceived | date:'short') : '-'}} </td>
          <td> {{history.brokerDisclosed ? 'Yes' : 'No'}} </td>
          <td class="text-center">
            <button *ngIf="history.disclosedFees.length" class="button btn btn-primary btn-sm" (click)="viewFeesClicked(history)">View Fees</button>
          </td>
          <td class="text-center">
            <button class="button btn btn-primary btn-sm" *ngIf="history.disclosureHistoryId" (click)="showAuditsClicked(history.disclosureHistoryId)">Show Audits</button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="12" class="text-center"> No history found. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="accordion accordion-flush" id="accordionTrackingHistory" *ngIf="disclosureTrackingItem">
  <div class="accordion-item">
    <h5 class="accordion-header m-0" id="accordion-details">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#flush-collapseDetails" aria-expanded="true" aria-controls="flush-collapseDetails">
        Disclosure Details
      </button>
    </h5>
    <div id="flush-collapseDetails" class="accordion-collapse collapse show" aria-labelledby="accordion-details"
      data-bs-parent="#accordionTrackingHistory">
      <div class="accordion-body">
        <div class="row">
          <disclosure-tracking-details [disclosureTrackingItem]="disclosureTrackingItem" [appId]="appId"
            [primaryBorrower]="primaryBorrower" [borrowers]="borrowers" [user]="user"></disclosure-tracking-details>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0" id="accordion-reasons">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#flush-collapseReasons" aria-expanded="false" aria-controls="flush-collapseReasons">
        Reasons for Disclosure
      </button>
    </h5>
    <div id="flush-collapseReasons" class="accordion-collapse collapse" aria-labelledby="accordion-reasons"
      data-bs-parent="#accordionTrackingHistory">
      <div class="accordion-body">
        <div class="row">
          <disclosure-tracking-reasons 
            [disclosureTrackingItem]="disclosureTrackingItem"
            [urlaFieldsConfig]="urlaFieldsConfig">
          </disclosure-tracking-reasons>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0" id="accordion-eDisclosureTracking">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#flush-collapseEDT" aria-expanded="false" aria-controls="flush-collapseEDT">
        eDisclosure Tracking
      </button>
    </h5>
    <div id="flush-collapseEDT" class="accordion-collapse collapse" aria-labelledby="accordion-eDisclosureTracking"
      data-bs-parent="#accordionTrackingHistory">
      <div class="accordion-body">
        <div class="row">
          <disclosure-tracking-edisclosure [disclosureTrackingItem]="disclosureTrackingItem" [usersAll]="usersAll">
          </disclosure-tracking-edisclosure>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="text-end" *ngIf="disclosureTrackingItem">
  <button type="button" class="btn btn-outline-primary" (click)="save()">
    Save
  </button>
</div>

<drawer
  [title]="'Audit Results'"
  #disclosureAuditResultsDrawer
  [name]="'disclosureAuditResultsDrawer'"
  [templateRef]="disclosureAuditResultsDrawerContentRef"
  [options]="disclosureAuditResultsDrawerOptions"
  (closed)="disclosureAuditResultsDrawerClosed()"
>
</drawer>
<ng-template #disclosureAuditResultsDrawerContentRef>
  <div class="m-2">
    <disclosure-audit-results
      [messages]="disclosureHistory.disclosureResponse.auditMessages"
    ></disclosure-audit-results>
  </div>
</ng-template>
