import { LoanProductPricing } from "src/app/modules/pricing/models/pricing/loan-product-pricing.model";

export class ConfigurationApplication {
    applicationId: number;
    userId: string;
    primaryBorrowerId: number;
    loanStatusId: number;
    subStatusId: number;
    adverseReasonId: number;
    loanTypeId: number;
    loanPurposeId: number;
    customDataId: number;
    productPricingId: number;
    existingLenderId: number;
    hoiAgentId: number;
    titleAgentId: number;
    escrowAgentId: number;
    lenderId: number;
    leadId: number;
    leadCampaignId: number;
    leadRefId: string;
    leadSource: string;
    onlineApplicationName: string;
    onlineApplicationVersion: string;
    pauseMessages: boolean;
    branchId: number;
    duCasefuleId: string;
    refNumber: string;
    losIdentifier: string;
    losVendor: string;
    losSyncDir: string;
    loanAmount: number;
    mipAmount: number;
    cashOutAmount: number;
    subordinateLienAmount: number;
    purchasePrice: number;
    presentValue: number;
    downAmount: number;
    estimatedClosingDate: string;
    closingDate: string;
    fundingDate: string;
    cdIssueDate: string;
    cdSignedDate: string;
    originationDate: string;
    payoffExpirationDate: string;
    lastPaymentDate: string;
    disbursementDate: string;
    firstPaymentDate: string;
    mailingStreet: string;
    mailingPOBox: string;
    mailingCity: string;
    mailingState: string;
    mailingZip: string;
    referralSource: number;
    firstAttemptedContact: string;
    firstSuccessfulContact: string;
    lastAttemptedContact: string;
    lastSuccessfulContact: string;
    redistributionCount: number;
    lastRedistributionDate: string;
    fetchCount: number;
    lastFetchDate: string;
    contactAttempts: number;
    isMismo34Application: boolean;
    channel: string;
    disclosurePath: string;
    externalCompanyId: number;
    contactListId: number;
    contactListRecordId: number;
    copiedFromApplicationId: number;
    copyReason: string;
    removeFromReporting: boolean;
    hasBeenCopied: boolean;
    currentFeeTemplateId: number;
    productPricing: LoanProductPricing
    tpoSubmissionDate: string;
    fees: any[];
    companyId: number;
    insertedBy: string;
    dateInserted: string;
    updatedBy: string;
    dateUpdated: string;
}