<div class="modal-header">
  <h5 class="modal-title">Loan Characteristics History</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <p-table #dt1 [value]="history" [paginator]="true" [rows]="25" *ngIf="history"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Loan Characteristic</th>
          <th>By</th>
          <th>Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-historyItem>
        <tr>
          <td>{{historyItem.actionValue}}</td>
          <td>{{historyItem.userProfile ? historyItem.userProfile.firstName + ' ' + historyItem.userProfile.lastName : '--'}}</td>
          <td>{{historyItem.dateInserted | date:'short'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Close</button>
      </div>
    </div>
  </div>
</div>
