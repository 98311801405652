<goal-table
  [goalsOverride]='userGoals'
  [selectable]='true'
  [(selectedGoals)]='selectedUserGoals'
  [loadingOverride]='loading'
  title='User Goals'
>
</goal-table>

<div class='control-buttons'>
  <button
    [disabled]='!isRemoveGoalsFromUserButtonEnabled'
    (click)='onClickRemoveGoalsFromUser()'
    class='btn btn-primary control-button'
  >
    <i class='fas fa-angle-right'></i>
  </button>

  <button
    [disabled]='!isAddGoalsToUserButtonEnabled'
    (click)='onClickAddGoalsToUser()'
    class='btn btn-primary control-button'
  >
    <i class='fas fa-angle-left'></i>
  </button>
</div>

<goal-table
  [goalsOverride]='goals'
  [selectable]='true'
  [(selectedGoals)]='selectedGoals'
  [loadingOverride]='loading'
  title='All Goals'
></goal-table>
