<form class="form-horizontal auth-form" #questionForm="ngForm" novalidate id="questionForm2" name="questionForm2">
  <div class="form-group mb-2 mt-2">

    <div *ngIf="answeredAllQuestions" class="alert alert-success border-0" role="alert">
      <strong>Success!</strong>
      You've answered all the questions.
    </div>

    <div class="mb-3">
      <i class="fa fa-info-circle text-primary me-1"></i>
      <span class="text-muted font-size-10">
        Total number of questions is subject to change as per selected answer.
      </span>
    </div>

    <div *ngFor="let questionToBeAnswered of questionsToBeAnswered; index as i" class="mb-4">
      <ng-container *ngIf="questionToBeAnswered.questionMetadata?.questionType !== 'TriggerOnly'">
        <div class="mb-1">
          <strong>Q-{{i + 1}}: </strong>
          <strong>{{questionToBeAnswered.borrowerDisplayName ? (questionToBeAnswered.borrowerDisplayName + " - ") :
            ''}}</strong>
          {{questionToBeAnswered.questionMetadata?.questionText}}
        </div>

        <div [ngSwitch]="questionToBeAnswered.questionMetadata?.questionType">

          <div [ngClass]="'w-100'" *ngIf="questionToBeAnswered.questionMetadata?.questionType === 'MultiSelect'">

            <ng-multiselect-dropdown name="answer{{questionToBeAnswered.questionMetadata.questionId}}"
              required
              #answerGiven="ngModel"
              [placeholder]="'-- Select --'"
              [settings]="multiSelectSettings"
              [ngClass]="{'is-invalid': answerGiven && answerGiven.touched && answerGiven.invalid}"
              [data]="questionToBeAnswered.questionMetadata?.answers | orderBy: 'answerText'"
              [(ngModel)]="questionToBeAnswered.answersGivenByUser.choicesSelected"
              (ngModelChange)="onAnswerChanged(questionToBeAnswered)">
            </ng-multiselect-dropdown>

            <div class="invalid-feedback">Please answer the question.</div>
          </div>

          <div [ngClass]="'w-100'" *ngIf="questionToBeAnswered.questionMetadata?.questionType === 'SelectList'">
            <select name="answer{{questionToBeAnswered.questionMetadata.questionId}}" class="form-select"
              [ngClass]="{'is-invalid': answerGiven && answerGiven.touched && answerGiven.invalid}"
              [(ngModel)]="questionToBeAnswered.answersGivenByUser.choiceSelected"
              (change)="onAnswerChanged(questionToBeAnswered)" #answerGiven="ngModel" required>
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let answer of questionToBeAnswered.questionMetadata?.answers | orderBy: 'answerText'"
                [ngValue]="answer.questionAnswerId">
                {{ answer.answerText }}
              </option>
            </select>
            <div class="invalid-feedback">Please answer the question.</div>
          </div>

          <div [ngClass]="'w-100'" *ngIf="questionToBeAnswered.questionMetadata?.questionType === 'Value'">
            <input type="text" class="form-control" name="answer{{questionToBeAnswered.questionMetadata.questionId}}"
              [ngClass]="{'is-invalid': answerGiven && answerGiven.touched && answerGiven.invalid}"
              [(ngModel)]="questionToBeAnswered.answersGivenByUser.singleAnswer"
              (blur)="onAnswerChanged(questionToBeAnswered)" #answerGiven="ngModel" required />
            <div class="invalid-feedback">Please answer the question.</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>
