import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ThirdPartyCredential } from '../../../../../models';
import { cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { LicenseValidationVendorEditorDialogComponent } from './license-validation-vendor-editor-dialog/license-validation-vendor-editor-dialog.component';

const LICENSE_VALIDATION_TYPE = 'LicenseValidation';
const VENDOR_NAME = 'Custom';

@Component({
  selector: 'license-validation',
  templateUrl: './license-validation.component.html',
  styleUrls: ['./license-validation.component.scss'],
})
export class LicenseValidationComponent implements OnDestroy {

  @Output() readonly credentialChange = new EventEmitter<ThirdPartyCredential>();

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal
  ) {
  }

  private _credential: ThirdPartyCredential;

  @Input() set credential(value: ThirdPartyCredential) {
    this._credential = value;
  }

  protected get formCredential(): ThirdPartyCredential {
    return this._credential;
  }

  ngOnDestroy() {
  }

  onCredentialDeleted = () => {
    this._credential = null;
    this.credentialChange.emit(null);
  }

  openCredentialModal = (createNew: boolean = false) => {
    const modalRef = this._modalService.open(LicenseValidationVendorEditorDialogComponent, Constants.modalOptions.large);
    let credentialToEdit = createNew ? createDefaultCredential() : this._credential;
    modalRef.componentInstance.credential = JSON.parse(JSON.stringify(credentialToEdit));
    modalRef.result.then(
      (result) => {
        this.credential = result;
        this.credentialChange.emit(result);

        this._notificationService.showSuccess(
          'License validation settings saved successfully.',
          'System Level',
        );
      },
      () => { }
    );
  }
}

function createDefaultCredential(): ThirdPartyCredential {
  return new ThirdPartyCredential(
    LICENSE_VALIDATION_TYPE as any,
    VENDOR_NAME,
  );
}
