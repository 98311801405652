import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { FeePaidByEnum } from 'src/app/models/fee/fee-paid-by.enum';
import { FeePercentOfFieldEnum } from 'src/app/models/fee/fee-percent-of-field.enum';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { FeeTypeEnum } from 'src/app/models/fee/fee-type.enum';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import * as _ from 'lodash';
import { EnumerationService } from '../../../../../../services/enumeration-service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fee-definition-details',
  templateUrl: './fee-definition-details.component.html',
  styleUrls: ['./fee-definition-details.component.scss'],
  viewProviders: [formViewProvider],
})
export class FeeDefinitionDetailsComponent implements OnInit {

  @Input() feeDefinition: FeeTemplateFee;
  @Output() feePercentChanged = new EventEmitter<boolean>();

  feeTypes: EnumerationItem[] = [];
  feePercentOfs: EnumerationItem[] = [];
  feeDefaultPaidBys: EnumerationItem[] = [];
  feePaidTos: EnumerationItem[] = [];

  feePercentAllocationsNotAddingUpTo100: boolean = false;

  borrowerFeePercent: number = 0;
  sellerFeePercent: number = 0;
  lenderFeePercent: number = 0;
  thirdPartyFeePercent: number = 0;
  totalFeePercent: number = 0;

  constructor(
    private readonly _enumerationService: EnumerationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    const enumsResponse: _EnumerationsResponse = await firstValueFrom(
      this._enumerationService.getFeeEnumerations()
    );

    this.getFeeTypes();
    this.getFeePercentOfs();
    this.getFeeDefaultPaidBys(enumsResponse);
    this.getFeePaidTos(enumsResponse);

    this.totalFeePercent = this.getTotalFeePercent();
    this.calculatePercentAllocationsForUi();
  }

  getFeeTypes = () => {
    for (let enumMember in FeeTypeEnum) {
      let enumName = this.getEnumName(FeeTypeEnum[enumMember]);
      if (enumName) {
        this.feeTypes.push({ name: enumName, value: FeeTypeEnum[enumMember] });
      }
    }
    this.feeTypes = _.orderBy(this.feeTypes, ['name'], ['asc']);
  }

  getFeePercentOfs = () => {
    for (let enumMember in FeePercentOfFieldEnum) {
      let enumName = this.getEnumName(FeePercentOfFieldEnum[enumMember]);
      if (enumName) {
        this.feePercentOfs.push({ name: enumName, value: FeePercentOfFieldEnum[enumMember] });
      }
    }
  }

  normalizeEnumerationName = (item: EnumerationItem) => {
    const name = this.getEnumName(item.name);
    return { ...item, name }
  }

  getFeeDefaultPaidBys = (enumerationsResponse: _EnumerationsResponse) => {
    const paidByEnums = enumerationsResponse['FeePaidBy'];
    console.assert(paidByEnums != null);

    const excludedValues = new Set([
      FeePaidByEnum.Other,
      FeePaidByEnum.Investor,
    ]);

    this.feeDefaultPaidBys = paidByEnums
      .filter(({ value }) => !excludedValues.has(value))
      .map(this.normalizeEnumerationName);
  }

  getFeePaidTos = (enumerationsResponse: _EnumerationsResponse) => {
    const paidToEnums = enumerationsResponse['FeePaidTo'];
    console.assert(paidToEnums != null);

    this.feePaidTos = paidToEnums.map(this.normalizeEnumerationName);
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  onFeePercentOfChanged = () => {
    this.onDefaultPaidByChanged();
  }

  onDefaultPaidByChanged = () => {

    if (!this.feeDefinition.allowSplit) {

      this.feePercentAllocationsNotAddingUpTo100 = false;

      if (this.feeDefinition.defaultPaidBy == FeePaidByEnum.Borrower) {
        this.feeDefinition.borrowerFeePercent = this.totalFeePercent;
        this.feeDefinition.sellerFeeDollar = 0;
        this.feeDefinition.sellerFeePercent = 0;
        this.feeDefinition.lenderFeeDollar = 0;
        this.feeDefinition.lenderFeePercent = 0;
        this.feeDefinition.thirdPartyFeeDollar = 0;
        this.feeDefinition.thirdPartyFeePercent = 0;
      }
      if (this.feeDefinition.defaultPaidBy == FeePaidByEnum.Seller) {
        this.feeDefinition.sellerFeePercent = this.totalFeePercent;
        this.feeDefinition.borrowerFeeDollar = 0;
        this.feeDefinition.borrowerFeePercent = 0;
        this.feeDefinition.lenderFeeDollar = 0;
        this.feeDefinition.lenderFeePercent = 0;
        this.feeDefinition.thirdPartyFeeDollar = 0;
        this.feeDefinition.thirdPartyFeePercent = 0;
      }
      if (this.feeDefinition.defaultPaidBy == FeePaidByEnum.Lender) {
        this.feeDefinition.lenderFeePercent = this.totalFeePercent;
        this.feeDefinition.sellerFeeDollar = 0;
        this.feeDefinition.sellerFeePercent = 0;
        this.feeDefinition.borrowerFeeDollar = 0;
        this.feeDefinition.borrowerFeePercent = 0;
        this.feeDefinition.thirdPartyFeeDollar = 0;
        this.feeDefinition.thirdPartyFeePercent = 0;
      }
      if (this.feeDefinition.defaultPaidBy == FeePaidByEnum.ThirdParty ||
        this.feeDefinition.defaultPaidBy == FeePaidByEnum.Investor ||
        this.feeDefinition.defaultPaidBy == FeePaidByEnum.Other) {
        this.feeDefinition.thirdPartyFeePercent = this.totalFeePercent;
        this.feeDefinition.sellerFeeDollar = 0;
        this.feeDefinition.sellerFeePercent = 0;
        this.feeDefinition.lenderFeeDollar = 0;
        this.feeDefinition.lenderFeePercent = 0;
        this.feeDefinition.borrowerFeeDollar = 0;
        this.feeDefinition.borrowerFeePercent = 0;
      }

      this.calculatePercentAllocationsForUi();
      this.calculatePercentAllocationsForApi();

    }
  }

  percentChanged = (): void => {
    this.calculatePercentAllocationsForApi();
    this.feePercentAllocationsNotAddingUpTo100 = !this.validateFeePercentAllocations();
    this.feePercentChanged.emit(!this.feePercentAllocationsNotAddingUpTo100);
  }

  validateFeePercentAllocations = (): boolean => {
    const totalFeePercent = this.getTotalFeePercentOnUI();

    if (this.feeDefinition.feePercentOf && this.feePercentOfs.length > 0) {
      if (Number(totalFeePercent.toFixed(3)) !== 100) {
        return false;
      }
    }
    return true;
  }

  getTotalFeeDollar = (): number => {

    let totalDollar = (this.feeDefinition.borrowerFeeDollar != null ? Number(this.feeDefinition.borrowerFeeDollar) : 0) +
      (this.feeDefinition.sellerFeeDollar != null ? Number(this.feeDefinition.sellerFeeDollar) : 0) +
      (this.feeDefinition.lenderFeeDollar != null ? Number(this.feeDefinition.lenderFeeDollar) : 0) +
      (this.feeDefinition.thirdPartyFeeDollar != null ? Number(this.feeDefinition.thirdPartyFeeDollar) : 0);

    return this.feeDefinition.feePercentOf == null || (this.feeDefinition.feePercentOf != null && this.feePercentOfs.length === 0) ? totalDollar : null
  }

  // percents as [0,1] for UI
  calculatePercentAllocationsForUi = (): void => {

    if (this.totalFeePercent > 0) {
      if (this.feeDefinition.borrowerFeePercent != null) {
        this.borrowerFeePercent = (Number(this.feeDefinition.borrowerFeePercent) / Number(this.totalFeePercent)) * 100;
      }
      if (this.feeDefinition.sellerFeePercent != null) {
        this.sellerFeePercent = (Number(this.feeDefinition.sellerFeePercent) / Number(this.totalFeePercent)) * 100;
      }
      if (this.feeDefinition.lenderFeePercent != null) {
        this.lenderFeePercent = (Number(this.feeDefinition.lenderFeePercent) / Number(this.totalFeePercent)) * 100;
      }
      if (this.feeDefinition.thirdPartyFeePercent != null) {
        this.thirdPartyFeePercent = (Number(this.feeDefinition.thirdPartyFeePercent) / Number(this.totalFeePercent)) * 100;
      }
    } else {
      if (this.feeDefinition.defaultPaidBy === FeePaidByEnum.Borrower) {
        this.borrowerFeePercent = 100;
      } else if (this.feeDefinition.defaultPaidBy === FeePaidByEnum.Seller) {
        this.sellerFeePercent = 100;
      } else if (this.feeDefinition.defaultPaidBy === FeePaidByEnum.Lender) {
        this.lenderFeePercent = 100;
      } else if (this.feeDefinition.defaultPaidBy === FeePaidByEnum.ThirdParty) {
        this.thirdPartyFeePercent = 100;
      }
    }
    this.feePercentAllocationsNotAddingUpTo100 = !this.validateFeePercentAllocations();
  }

  onTotalFeePercentChanged = () => {
    this.calculatePercentAllocationsForApi();
  }

  onCanBeSplitChanged = () => {
    this.onDefaultPaidByChanged();
  }

  // percents as [0,100] for API
  calculatePercentAllocationsForApi = () => {

    this.feeDefinition.borrowerFeePercent = 0;
    this.feeDefinition.sellerFeePercent = 0;
    this.feeDefinition.lenderFeePercent = 0;
    this.feeDefinition.thirdPartyFeePercent = 0;

    if (this.borrowerFeePercent != null) {
      this.feeDefinition.borrowerFeePercent = (Number(this.totalFeePercent) * Number(this.borrowerFeePercent)) / 100;
    }
    if (this.sellerFeePercent != null) {
      this.feeDefinition.sellerFeePercent = (Number(this.totalFeePercent) * Number(this.sellerFeePercent)) / 100;
    }
    if (this.lenderFeePercent != null) {
      this.feeDefinition.lenderFeePercent = (Number(this.totalFeePercent) * Number(this.lenderFeePercent)) / 100;
    }
    if (this.thirdPartyFeePercent != null) {
      this.feeDefinition.thirdPartyFeePercent = (Number(this.totalFeePercent) * Number(this.thirdPartyFeePercent)) / 100;
    }
  }

  private getTotalFeePercentOnUI = (): number => {
    const totalFeePercent = ((this.borrowerFeePercent != null ? Number(this.borrowerFeePercent) : 0) +
      (this.sellerFeePercent != null ? Number(this.sellerFeePercent) : 0) +
      (this.lenderFeePercent != null ? Number(this.lenderFeePercent) : 0) +
      (this.thirdPartyFeePercent != null ? Number(this.thirdPartyFeePercent) : 0));
    return totalFeePercent;
  }

  private getTotalFeePercent = (): number => {
    const totalFeePercent = ((this.feeDefinition.borrowerFeePercent != null ? this.feeDefinition.borrowerFeePercent : 0) +
      (this.feeDefinition.sellerFeePercent != null ? this.feeDefinition.sellerFeePercent : 0) +
      (this.feeDefinition.lenderFeePercent != null ? this.feeDefinition.lenderFeePercent : 0) +
      (this.feeDefinition.thirdPartyFeePercent != null ? this.feeDefinition.thirdPartyFeePercent : 0));
    return totalFeePercent;
  }
}

interface _EnumerationsResponse {
  [k: string]: EnumerationItem[];
}
