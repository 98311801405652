<div *ngIf="task.note" class="notes-section note-pre-wrap" id="note-{{task.loanDocTaskId}}"
  loanDocTaskId="{{task.loanDocTaskId}}" data-bs-container="body" placement="bottom" triggers=""
  [popover]="notesTemplate" [outsideClick]="true" [containerClass]="'notesPopover'" style="cursor: pointer;word-break:break-word;"
  [isOpen]="task['notesPopoverOpened']" (mouseenter)="onNoteHover(task); task['notesPopoverOpened'] = true;"
  (onHidden)="task['notesPopoverOpened'] = false;">
  <span [innerHtml]="noteImageProcessStatuses.get(note['uniqueId']).processedContent | safeHtml"></span>
</div>
<ng-template #notesTemplate>
  <ng-template [ngIf]="task['loadingNotes']" [ngIfElse]="taskNotesContent">
    <loading-indicator [customClass]="'loadingNotesIndicator'"></loading-indicator>
  </ng-template>
  <ng-template #taskNotesContent>
    <ul *ngIf="internalNotes?.length > 0" class="mt-3 ps-0" style="list-style: none; font-size: 1.1em;"
      (mouseenter)="task['notesPopoverOpened'] = true;" (mouseleave)="task['notesPopoverOpened'] = false;">
      <li *ngFor="let note of internalNotes" class="card p-2">
        <span class="note-pre-wrap" [innerHtml]="noteImageProcessStatuses.get(note['uniqueId']).processedContent | safeHtml"></span>
        <div>{{note.senderFirstName}} {{note.senderLastName}} ({{note.postTime | date:'MMM d, y h:mm:ss a'}})</div>
      </li>
    </ul>
  </ng-template>
</ng-template>
