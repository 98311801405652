<div class="card">
  <div class="card-header">
    <h4 class="card-title">Loan Info</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_originatorProductCode">Originator Product Code</label>
        <input
          id="wireReq_originatorProductCode"
          name="wireReq_originatorProductCode"
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': originatorProductCode && originatorProductCode.touched && originatorProductCode.invalid}"
          [(ngModel)]="wireRequest.originatorProductCode"
          #originatorProductCode="ngModel"
          [required]="true"
        />
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_amortizationType">Amortization Type</label>
        <select
          id="wireReq_amortizationType"
          name="wireReq_amortizationType"
          class="form-select"
          [ngClass]="{'is-invalid': amortizationType && amortizationType.touched && amortizationType.invalid}"
          [(ngModel)]="wireRequest.amortizationType"
          #amortizationType="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let amortizationType of amortizationTypes" value="{{amortizationType.value}}">
            {{amortizationType.name}}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_documentationType">Documentation Type</label>
        <select
          id="wireReq_documentationType"
          name="wireReq_documentationType"
          class="form-select"
          [ngClass]="{'is-invalid': documentationType && documentationType.touched && documentationType.invalid}"
          [(ngModel)]="wireRequest.documentationType"
          #documentationType="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let documentationType of documentationTypes" value="{{documentationType.value}}">
            {{documentationType.name | splitCamelcase}}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_loanType">Loan Type</label>
        <select
          id="wireReq_loanType"
          name="wireReq_loanType"
          class="form-select"
          [ngClass]="{'is-invalid': loanType && loanType.touched && loanType.invalid}"
          [(ngModel)]="wireRequest.loanType"
          #loanType="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let loanType of loanTypes" value="{{loanType.value}}">
            {{loanType.name | splitCamelcase}}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_occupancy">Occupancy</label>
        <select
          id="wireReq_occupancy"
          name="wireReq_occupancy"
          class="form-select"
          [ngClass]="{'is-invalid': occupancy && occupancy.touched && occupancy.invalid}"
          [(ngModel)]="wireRequest.occupancy"
          #occupancy="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let occupancy of occupancies" value="{{occupancy.value}}">
            {{occupancy.name | splitCamelcase }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_appraisedValue">Appraised Value</label>
        <currency-input
          id="wireReq_appraisedValue"
          name="wireReq_appraisedValue"
          [(ngModel)]="wireRequest.appraisedValue"
          [allowNegative]="false"
          [required]="true"
        ></currency-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_rateType">Rate Type</label>
        <select
          id="wireReq_rateType"
          name="wireReq_rateType"
          class="form-select"
          [ngClass]="{'is-invalid': rateType && rateType.touched && rateType.invalid}"
          [(ngModel)]="wireRequest.rateType"
          #rateType="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let rateType of rateTypes" value="{{rateType.value}}">
            {{rateType.name}}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_lienPosition">Lien Position</label>
        <select
          id="wireReq_lienPosition"
          name="wireReq_lienPosition"
          class="form-select"
          [ngClass]="{'is-invalid': lienPosition && lienPosition.touched && lienPosition.invalid}"
          [(ngModel)]="wireRequest.lienPosition"
          #lienPosition="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let lienPoisition of lienPositionTypes" value="{{lienPoisition.value}}">
            {{lienPoisition.name | splitCamelcase }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_propertyType">Property Type</label>
        <select
          id="wireReq_propertyType"
          name="wireReq_propertyType"
          class="form-select"
          [ngClass]="{'is-invalid': propertyType && propertyType.touched && propertyType.invalid}"
          [(ngModel)]="wireRequest.propertyType"
          #propertyType="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let propertyType of propertyTypes" value="{{propertyType.value}}">
            {{propertyType.name}}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_salesPrice">Sales Price</label>
        <currency-input
          id="wireReq_salesPrice"
          name="wireReq_salesPrice"
          [(ngModel)]="wireRequest.salesPrice"
          [allowNegative]="false"
          [allowEmpty]="false"
          [required]="true"
        ></currency-input>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_otherLiens">Other Liens</label>
        <currency-input
          id="wireReq_otherLiens"
          name="wireReq_otherLiens"
          [(ngModel)]="wireRequest.otherLiens"
          [allowNegative]="false"
          [allowEmpty]="false"
          [required]="true"
        ></currency-input>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_grossIncome">Gross Income</label>
        <currency-input
          id="wireReq_grossIncome"
          name="wireReq_grossIncome"
          [(ngModel)]="wireRequest.grossIncome"
          [allowNegative]="false"
          [required]="true"
        ></currency-input>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_appraisalDate">Appraisal Date</label>
        <date-input
          [name]="'wireReq_appraisalDate'"
          [id]="'wireReq_appraisalDate'"
          [(ngModel)]="wireRequest.appraisalDate"
          [required]="true"
        ></date-input>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_appraisalType">Appraisal Type</label>
        <select
          id="wireReq_appraisalType"
          name="wireReq_appraisalType"
          class="form-select"
          [ngClass]="{'is-invalid': appraisalType && appraisalType.touched && appraisalType.invalid}"
          [(ngModel)]="wireRequest.appraisalType"
          #appraisalType="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let appraisalType of appraisalTypes" value="{{appraisalType.value}}">
            {{appraisalType.name}}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>

      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_creditScore">Credit Score</label>
        <input
          id="wireReq_creditScore"
          name="wireReq_creditScore"
          type="number"
          class="form-control"
          [ngClass]="{'is-invalid': creditScore && creditScore.touched && creditScore.invalid}"
          [(ngModel)]="wireRequest.creditScore"
          #creditScore="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
  </div>
</div>