import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocFilesEditDialogComponent } from './doc-files-edit-dialog.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    DocFilesEditDialogComponent
  ],
  exports: [
    DocFilesEditDialogComponent
  ],
  providers: [

  ]
})
export class DocFilesEditDialogModule {
  constructor() {

  }
}
