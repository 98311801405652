<ng-template #pageActionBarLeft>
  <bread-crum class="tpo-bread-crumb"></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Phone Number</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-3 row" *ngIf="currentPhone">
                <h6 class="col-sm-2  align-self-center mb-lg-0 text-end">Current</h6>
                <div class="col-sm-10">
                  <label class="text-primary mb-0">
                    {{currentPhone}}
                  </label>
                </div>
              </div>
              <div class="form-group mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">New</label>
                <div class="col-sm-2">
                  <ng-select2 class="select2 mb-3 select2-multiple" name="phoneNumber" [options]="countriesOptions"
                    [(ngModel)]="setting.areaCode" [data]="countries">
                  </ng-select2>
                </div>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="setting.phone" [mask]="'(000) 000-0000'"
                    (input)="onNewPhoneNumberChanged()" [placeholder]="'(###) ###-####'" type="text">
                </div>
                <div class="col-sm-12 col-md-4">
                  <button type="button" class="btn btn-primary" (click)="updatePhone()"
                    [disabled]="updatingPhoneNumber || !setting.phone">
                    <span *ngIf="updatingPhoneNumber">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Updating...
                    </span>
                    <span *ngIf="!updatingPhoneNumber">Update Phone Number</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Two Factor Authentication is currently {{setting.isTwoFactor?'enabled':'disabled'}}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group mb-3 row ms-3">
            <div class="form-check form-switch  form-switch-success">
              <input class="form-check-input form-check-input-lg" type="checkbox" name="isTwoFactor" id="isTwoFactor"
                [disabled]="!currentPhone" [(ngModel)]="setting.isTwoFactor" (change)="changeTwoFactor()">
              <label class="form-check-label" for="flagAdmin">
                {{setting.isTwoFactor ? 'Enabled' : 'Disabled'}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<tpo-page-template [pageMainContentTemplate]="mainContent" [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</tpo-page-template>
