import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocFile, DocumentType, LoanDoc } from 'src/app/models';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'link-existing-documents-dialog',
  templateUrl: './link-existing-documents-dialog.component.html',
  styleUrls: ['./link-existing-documents-dialog.component.scss']
})
export class LinkExistingDocumentsDialogComponent implements OnInit {

  task: LoanDocDashboardTask;

  loanDocs: LoanDoc[];

  globalConfig: GlobalConfig;

  constructor(public activeModal: NgbActiveModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanDocService: LoanDocService) { }

  ngOnInit(): void {
    this._spinner.show();
    this._loanDocsService.getLoanDocs(this.task.applicationId, null).subscribe((response) => {
      this.loanDocs = response;
      this._spinner.hide();
    }, (err) => {
      this._notifyService.showError('Error ' + err ? err.message || err : '', 'Link Documents');
      this._spinner.hide();
    });
  }

  showDocumentType = (docType: DocumentType) => {
    if (this.loanDocs) {
      let found = this.loanDocs.find(doc => doc.documentTypeId == docType.documentTypeId);
      return !!found;
    }
  }

  isDocumentLinked = (file: FileDto) => {
    let found = this.task.docFiles.find(docFile => docFile.guid === file.guid);
    return !!found;
  }

  linkDocument = (file: DocFile) => {
    let index = this.task.docFiles.findIndex(docFile => docFile.guid === file.guid);
    if (index === -1) {
      let fileDto = new FileDto();
      fileDto.guid = file.guid;
      fileDto.loanDocId = file.loanDocId;
      fileDto.losDocFileId = file.losDocFileId;
      fileDto.fileName = file.fileName;
      fileDto.note = file.note;
      fileDto.borrowerNote = file.borrowerNote;
      fileDto.condition = file.condition;
      fileDto.docFileType = file.docFileType;
      fileDto.active = file.active;
      fileDto.selectedForAction = false;
      fileDto.linkWithTask = true;
      this.task.docFiles.push(fileDto);
    } else {
      this.task.docFiles.splice(index, 1);
    }
  }

  viewFile = (file: DocFile) => {
    this._spinner.show();
    const fileGuid = file.guid;
    const mimeType = file.mimeType;
    this._loanDocService.viewFile(fileGuid).subscribe(data => {
      this._spinner.hide();
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    });
  }

}
