import { Component, Input, OnInit } from '@angular/core';
import { MortgageService } from 'src/app/services/mortgage.service';
import { DisclosureTracking } from '../../../models/disclosure-tracking.model';
import { MortgageModelFieldsConfig } from 'src/app/shared/services/mortgage-field-validation-utils';

@Component({
  selector: 'disclosure-tracking-reasons',
  templateUrl: 'disclosure-tracking-reasons.component.html',
  styleUrls: ['disclosure-tracking-reasons.component.scss']
})
export class DisclosureTrackingReasonsComponent implements OnInit {

  @Input()
  disclosureTrackingItem: DisclosureTracking;

  @Input()
  urlaFieldsConfig: MortgageModelFieldsConfig;

  constructor(private readonly _mortgageService: MortgageService) {
  }

  ngOnInit(): void {
  }
}
