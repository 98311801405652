export class CustomData {
  customDataId: number;
  leadId: number;
  applicationId: number;
  customData1: string;
  customData2: string;
  customData3: string;
  customData4: string;
  customData5: string;
  customData6: string;
  customData7: string;
  customData8: string;
  customData9: string;
  customData10: string;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}
