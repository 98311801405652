import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { chain, cloneDeep, orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoanStatus } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  GridTopActionBarComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import {
  ActionButtonOption,
  GridActionButtonType,
} from 'src/app/shared/models/action-button-options.model';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LoanStatusAssociation } from '../../../models';
import { LoanStatusAssociationService } from '../../../services';

@Component({
  templateUrl: 'loan-status-association.component.html',
  styleUrls: ['loan-status-association.component.scss'],
})
export class LoanStatusAssociationComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild(Table)
  loanStatusTable: Table;

  @ViewChild('gridHeaderActionBar')
  gridHeaderActionBar: GridTopActionBarComponent;

  @ViewChild('loanStatusAssociationOrderDrawer')
  loanStatusAssociationOrderDrawer: DrawerComponent;

  @ViewChild('loanStatusAssociationOrder')
  loanStatusAssociationOrder: RearrangeOrderComponent

  actionButtonOptions: Array<ActionButtonOption> = [];
  loanStatusColumns = [];
  filteredLoanStatusAssociations: Array<LoanStatusAssociation> = [];
  loanStatusAssociations: Array<LoanStatusAssociation>;
  loanStatuses: Array<LoanStatus> = [];
  enabledChannels: EnumerationItem[] = [];
  associationsToOrder: Array<{ name: string, value: number }> = [];
  upsertDrawerLoanPurposes: Array<LoanPurpose> = [];
  upsertDrawerLoanStatuses: Array<LoanStatus> = [];

  selectedChannelNameForFilter: string;
  upsertDrawerTitle: string;

  allowAddNew: boolean;
  channelsEnabled: boolean;

  loanStatusAssociationOrderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  upsertDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  permissions: any = {};

  loanPurpose: LoanPurpose;
  loanStatusAssociationToUpsert: LoanStatusAssociation;
  loanPurposeName: string;

  private _isCreditReportingEnabled: boolean;
  private _isLosEnabled: boolean;
  private _isVoaEnabled: boolean;
  private _isVoiEnabled: boolean;
  private _isDuEnabled: boolean;
  private _isLpaEnabled: boolean;
  private _companyId: number;
  private _loanPurposeId: number;
  private _haveDefaultChannelSelection: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _loanStatusAssociationService: LoanStatusAssociationService,
    private readonly _notificationService: NotificationService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _channelService: ChannelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
    private readonly _localStorageService: LocalStorageService
  ) {
    super(injector);
    //super.scrollOffset = 290;
    this._activatedRoute.params.subscribe((params) => {
      this._loanPurposeId = params['loanPurposeId'];
      this.loanPurposeName = params['loanPurposeName'];
    });
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.applicationContextService.context.subscribe(res => {
      this.allowAddNew = res.userPermissions.superAdmin ? false : true;
      this._companyId = res.userPermissions.companyId;
      this._isCreditReportingEnabled = res.userPermissions.creditReportingEnabled;
      this._isLosEnabled = res.userPermissions.losEnabled;
      this._isVoaEnabled = res.userPermissions.digitalAssetVerificationEnabled;
      this._isVoiEnabled = res.userPermissions.digitalEmploymentVerificationEnabled;
      this._isDuEnabled = res.userPermissions.desktopUnderwriterEnabled;
      this._isLpaEnabled = res.userPermissions.lpaEnabled;
      this.loanStatuses = res.globalConfig.loanStatus;
      this.loanPurpose = res.globalConfig.loanPurpose.find(ls => ls.loanPurposeId == this._loanPurposeId);
      this.enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this.loanPurpose?.enabledChannels);
      this.channelsEnabled = !!this.enabledChannels.length;
      this.selectedChannelNameForFilter = 'All';
      this.loanStatusColumns = [
        { field: 'loanPurposeName', header: 'Loan Purpose', visible: true, sortable: false },
        { field: 'channel', header: 'Channels', visible: this.channelsEnabled, sortable: true },
        { field: 'loanStatusName', header: 'Loan Status', visible: true, sortable: true },
        { field: 'disallowedThirdPartyServices', header: 'Disallowed Services', visible: true, sortable: true },
        { field: 'supressEmailCampaignNotifications', header: 'Suppress Email & SMS Campaigns', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'supressSystemGeneratedNotifications', header: 'Suppress System Generated Communication', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'tpoStatusAlias', header: 'TPO Status Alias', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'borrowerFriendlyStatus', header: 'Borrower Friendly Status', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'borrowerFriendlyBlurb', header: 'Borrower Friendly Blurb', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'hideFromPortals', header: 'Hide From Portals', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'loanAmountHiddenOnPortals', header: 'Loan Amount Hidden On Portals', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'interestRateHiddenOnPortals', header: 'Interest Rate Hidden On Portals', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'cautionAfterDaysInStatus', header: 'Caution After Days In Status', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'warnAfterDaysInStatus', header: 'Warn After Days In Status', visible: true, sortable: true, cssClass: 'wrap-text' },
        { field: 'order', header: 'Sort', visible: true, sortable: true }
      ];

      this.setActionButtonOptions();
      this.populateLoanStatusAssociations();

      this.permissions = {
        isCreditReportingEnabled: this._isCreditReportingEnabled,
        isLosEnabled: this._isLosEnabled,
        isVoaEnabled: this._isVoaEnabled,
        isVoiEnabled: this._isVoiEnabled,
        isDuEnabled: this._isDuEnabled,
        isLpaEnabled: this._isLpaEnabled,
      };
    });
  }

  showDeleteDialog(rowData: LoanStatusAssociation) {
    Swal.fire({
      title: 'Loan Status Association',
      text: 'Are you sure you want to delete this loan status association?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) return;
      this.deleteRow(rowData);
    });
  }

  showUpsertDrawer(rowData?: LoanStatusAssociation) {
    this._drawerService.hideAll();
    let channel = this.selectedChannelNameForFilter;
    if (!this.channelsEnabled || channel === "All") {
      channel = null;
    }

    this.loanStatusAssociationToUpsert = rowData
      ? _.cloneDeep(rowData) :
      { channel: channel } as LoanStatusAssociation

    this.upsertDrawerTitle = rowData ? "Update Loan Status Association" : "Add New Loan Status Association";
    this.upsertDrawerLoanPurposes = [this.loanPurpose];
    this.upsertDrawerLoanStatuses = this.loanStatuses.filter(ls =>
      !this.loanStatusAssociations.some(a => a.loanStatusId === ls.loanStatusId && (this.selectedChannelNameForFilter === 'All' || a.channel === this.selectedChannelNameForFilter)) ||
      rowData?.loanStatusId === ls.loanStatusId
    );

    this._drawerService.show("loanStatusAssociationUpsertDrawer");
  }

  onUpsertDrawerSave(res: LoanStatusAssociation) {
    this.updateTableAfterUpsert(res);
  }

  onUpsertDrawerClose() {
    this._drawerService.hide("loanStatusAssociationUpsertDrawer");
  }

  showOrderDrawer() {
    if (!this.loanStatusAssociations.length) {
      return;
    }

    this.associationsToOrder = this.setItemsOrder();
    this._drawerService.show("loanStatusAssociationOrderDrawer", 100);
  }

  closeOrderDrawer() {
    this._drawerService.hide("loanStatusAssociationOrderDrawer", 100);
  }

  onOrderSave(sortedLoanStatuses: Array<EnumerationItem>) {
    this.loanStatusAssociationOrder.saveInProgress = true;

    const sortedIds = sortedLoanStatuses.map(r => ({ id: r.value }));

    this._loanStatusAssociationService.updateLoanStatusAssociationOrder(sortedIds, this._loanPurposeId, this._companyId)
      .pipe(finalize(() => {
        this.loanStatusAssociationOrder.saveInProgress = false;
        this.closeOrderDrawer();
      }))
      .subscribe({
        next: () => {
          this.loanStatusTable.sortField = "";
          this.loanStatusTable.sortOrder = 0;
          this.loanStatusTable.reset();
          this.gridHeaderActionBar.search.nativeElement.value = '';
          this.populateLoanStatusAssociations();
          this._notificationService.showSuccess(
            'Loan purpose order updated successfully',
            'Loan Purpose'
          );
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to update loan purpose order',
            'Loan Purpose'
          );
        }
      });
  }

  sortLoanStatusAssociationsByOrder(
    loanStatusAssociations: Array<LoanStatusAssociation>,
    forceSortByOrderColumn: boolean = false
  ): Array<LoanStatusAssociation> {
    if (this.loanStatusTable.sortField && !forceSortByOrderColumn) {
      return cloneDeep(this.loanStatusAssociations);
    }
    return orderBy(loanStatusAssociations, item => item.order);
  }

  getLoanPurposeName(): string {
    return this.loanPurpose?.loanPurposeName || '';
  }

  getLoanStatusName(value: number): string {
    return this.loanStatuses.find((status) => status.loanStatusId == value)?.loanStatusName || '';
  }

  getDisallowedServices(value: string): string {
    let result = '';
    const arr = value?.split(',') || [];
    arr.forEach((el) => {
      if (el === 'CreditHardPull') {
        if (result.length) result += ', ';
        result += 'Credit - Hard Pull';
      }
      if (el === 'CreditSoftPull') {
        if (result.length) result += ', ';
        result += 'Credit - Soft Pull';
      }
      if (el === 'Los') {
        if (result.length) result += ', ';
        result += 'LOS';
      }
      if (el === 'Voa') {
        if (result.length) result += ', ';
        result += 'VOA - Verification of Assets';
      }
      if (el === 'Voi') {
        if (result.length) result += ', ';
        result += 'VOI - Verification of Income/Employment';
      }
      if (el === 'Du') {
        if (result.length) result += ', ';
        result += 'DU - Desktop Underwriter';
      }
      if (el === 'Lpa') {
        if (result.length) result += ', ';
        result += 'LPA - Loan Product Advisor';
      }
    });
    return result;
  }

  private filterLoanStatusAssociationsByChannel(): void {
    if (this.selectedChannelNameForFilter === 'All') {
      const filteredLoanStatusAssociations = this.loanStatusAssociations;
      this.filteredLoanStatusAssociations = this.sortLoanStatusAssociationsByOrder(filteredLoanStatusAssociations);
      return;
    }
    const filteredLoanStatusAssociations = this.loanStatusAssociations
      .filter((lsa: LoanStatusAssociation) => lsa.channel === this.selectedChannelNameForFilter);
    this.filteredLoanStatusAssociations = this.sortLoanStatusAssociationsByOrder(filteredLoanStatusAssociations);
  }

  private setActionButtonOptions(): void {
    this.actionButtonOptions = [
      {
        type: GridActionButtonType.SearchInput,
        searchInputPlaceholder: 'Search...',
        onSearch: (value) => this.loanStatusTable.filterGlobal(value, 'contains'),
      },
    ];
    if (this.channelsEnabled) {
      let selectedChannels = this._localStorageService.getItem("loanPurposeChannel") as string;

      this.actionButtonOptions.push({
        type: GridActionButtonType.ChannelDropDown,
        label: 'Filter By Role Channel',
        showSelectOneOption: false,
        defaultSelection: selectedChannels ? selectedChannels: 'All',
        enabledChannels: [{
          value: 'All',
          name: 'All'
        }, ...this.enabledChannels
        ],
        onSelectionChanged: (selectedChannel: string) => {
          this.selectedChannelNameForFilter = selectedChannel;
          this._localStorageService.setItem("loanPurposeChannel", selectedChannel);
          this.filterLoanStatusAssociationsByChannel();
        }
      });

      if(selectedChannels){
        this.selectedChannelNameForFilter = selectedChannels;
      }
    }
  }

  private populateLoanStatusAssociations() {
    this._spinner.show();
    this._loanStatusAssociationService
      .getAllLoanStatusAssociations(this._loanPurposeId, this._companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe((res) => {
        this.loanStatusAssociations = res.map(lsa => ({
          ...lsa,
          loanPurposeName: this.getLoanPurposeName(),
          loanStatusName: this.getLoanStatusName(Number(lsa.loanStatusId)),
        }));
        this.filterLoanStatusAssociationsByChannel();
      });
  }

  private updateTableAfterUpsert(result: LoanStatusAssociation) {
    const index = this.loanStatusAssociations.findIndex(ls => ls.id === result.id);
    if (index === -1) {
      this.loanStatusAssociations.push(result);
    } else {
      this.loanStatusAssociations[index] = result;
    }
    this.filterLoanStatusAssociationsByChannel();
    this.onUpsertDrawerClose();
  }

  private deleteRow(rowData: LoanStatusAssociation) {
    this._loanStatusAssociationService.deleteLoanStatusAssociation(rowData.id, this._companyId)
      .subscribe({
        next: (res) => {
          const index = this.loanStatusAssociations.findIndex(ls => ls.id === rowData.id);
          this.loanStatusAssociations.splice(index, 1);
          this._notificationService.showSuccess(
            'Loan status association removed succesfully.',
            'Loan Status Association'
          );
          this.populateLoanStatusAssociations();
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't delete loan status association",
            'Loan Status Association'
          );
        }
      });
  }

  private setItemsOrder() {
    const filteredAssociations = this.selectedChannelNameForFilter === "All" ?
      this.loanStatusAssociations :
      this.loanStatusAssociations.filter(ls => ls.channel === this.selectedChannelNameForFilter);

    return chain(filteredAssociations)
      .orderBy(ls => ls.order)
      .map(ls => ({
        name: this.getLoanStatusName(Number(ls.loanStatusId)),
        value: ls.id,
      }))
      .value();
  }
}
