import { EventEmitter, Injectable, Output } from '@angular/core';
import { MenuItemStatus } from '../modules/tpo/models/enums/menu-item-status.enum';
import { MenuItemStatusChangedEvent } from '../modules/tpo/models/menu-item-status-changed-event.model';
import { UrlaMortgage } from '../modules/urla/models/urla-mortgage.model';
import { Constants } from './constants';
import { EnumerationService } from './enumeration-service';
import { Address, MortgageBorrower, ResidencyAddress, ResidencyType } from '../models';
import { MortgageService } from './mortgage.service';
import { ChangeOfCircumstanceDetail } from '../modules/disclosure-tracking/models/disclosure-tracking.model';
import { MortgageFieldRequiredState, MortgageModelFieldsConfig } from '../shared/services/mortgage-field-validation-utils';

@Injectable()
export class MenuService {

  @Output()
  menuItemStatusChanged: EventEmitter<MenuItemStatusChangedEvent> = new EventEmitter<MenuItemStatusChangedEvent>();

  @Output()
  urlaStatusChanged: EventEmitter<MenuItemStatus> = new EventEmitter<MenuItemStatus>();

  @Output()
  menuItemChangedToActive: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  menuItemClicked: EventEmitter<string> = new EventEmitter<string>();

  private _mortgageFieldsConfig: MortgageModelFieldsConfig;

  private _isTouched: boolean | null = null;

  set isTouched(value: boolean) {
    if (this._isTouched === value) {
      return;
    }

    this._isTouched = value;
  }

  get isTouched(): boolean {
    return this._isTouched;
  }

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService) {
    this._mortgageFieldsConfig = this._mortgageService.quickApplyFieldsConfig;
  }

  setStatus = (menuItemId: string, status: MenuItemStatus) => {
    setTimeout(() => {
      this.menuItemStatusChanged.emit(new MenuItemStatusChangedEvent(menuItemId, status));
    });
  }

  setUrlaStatus = (status: MenuItemStatus) => {
    setTimeout(() => {
      this.urlaStatusChanged.emit(status);
    });
  }

  setActiveMenuItem = (menuItemId: string) => {
    setTimeout(() => {
      this.menuItemChangedToActive.emit(menuItemId);
    });
  }

  onMenuItemClicked = (menuItemId: string) => {
    this.menuItemClicked.emit(menuItemId);
  }

  private isFilteredAsset = (assetType, assetInsertionType) => {
    if (assetInsertionType == 1) return true;

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount);
  }

  getStatusForFinancialInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;
    if (mortgage.assets && mortgage.assets.length > 0) {
      mortgage.assets.forEach(asset => {
        if ((this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          this._mortgageFieldsConfig['mortgage.asset.assetType'] == MortgageFieldRequiredState.Required && !(!!asset.assetType) ||
          this._mortgageFieldsConfig['mortgage.asset.depository'] == MortgageFieldRequiredState.Required && !(!!asset.depository) ||
          this._mortgageFieldsConfig['mortgage.asset.accountNumber'] == MortgageFieldRequiredState.Required && !(!!asset.accountNumber) ||
          this._mortgageFieldsConfig['mortgage.asset.cashMarketValue'] == MortgageFieldRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        ) {
          state = MenuItemStatus.Error;
          return state;
        }
        if ((this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          this._mortgageFieldsConfig['mortgage.asset.assetType'] == MortgageFieldRequiredState.Requested && !(!!asset.assetType) ||
          this._mortgageFieldsConfig['mortgage.asset.depository'] == MortgageFieldRequiredState.Requested && !(!!asset.depository) ||
          this._mortgageFieldsConfig['mortgage.asset.accountNumber'] == MortgageFieldRequiredState.Requested && !(!!asset.accountNumber) ||
          this._mortgageFieldsConfig['mortgage.asset.cashMarketValue'] == MortgageFieldRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        ) {
          state = MenuItemStatus.Pending;
        }
      });
    }
    if (mortgage.liabilities && mortgage.liabilities.length > 0) {
      mortgage.liabilities.forEach(liability => {
        if (
          (this._mortgageFieldsConfig['mortgage.liability.typeOfLiability'] == MortgageFieldRequiredState.Required && !(!!liability.typeOfLiability)) ||
          (this._mortgageFieldsConfig['mortgage.liability.unpaidBalance'] == MortgageFieldRequiredState.Required && (liability.unpaidBalance == null || liability.unpaidBalance == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.liability.partialPayoffAmount'] == MortgageFieldRequiredState.Required && !!liability.payoffType && liability.payoffType !== 'None' && (liability.partialPayoffAmount == null || liability.partialPayoffAmount == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.liability.reoId'] == MortgageFieldRequiredState.Required && (liability.reoId == null || liability.reoId == undefined) &&
            (liability.typeOfLiability == 'MortgageLoan' || liability.typeOfLiability == 'HELOC')) ||
          (this._mortgageFieldsConfig['mortgage.liability.mortgageType'] == MortgageFieldRequiredState.Required && !(!!liability.mortgageType) &&
            (liability.typeOfLiability == 'MortgageLoan')) ||
          (this._mortgageFieldsConfig['mortgage.liability.lienPosition'] == MortgageFieldRequiredState.Required && !(!!liability.lienPosition) &&
            (liability.typeOfLiability == 'MortgageLoan'))
        ) {
          state = MenuItemStatus.Error;
          return state;
        }
        if (
          (this._mortgageFieldsConfig['mortgage.liability.typeOfLiability'] == MortgageFieldRequiredState.Requested && !(!!liability.typeOfLiability)) ||
          (this._mortgageFieldsConfig['mortgage.liability.unpaidBalance'] == MortgageFieldRequiredState.Requested && (liability.unpaidBalance == null || liability.unpaidBalance == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.liability.partialPayoffAmount'] == MortgageFieldRequiredState.Requested && !!liability.payoffType && liability.payoffType !== 'None' && (liability.partialPayoffAmount == null || liability.partialPayoffAmount == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.liability.reoId'] == MortgageFieldRequiredState.Requested && (liability.reoId == null || liability.reoId == undefined) &&
            (liability.typeOfLiability == 'MortgageLoan' || liability.typeOfLiability == 'HELOC')) ||
          (this._mortgageFieldsConfig['mortgage.liability.mortgageType'] == MortgageFieldRequiredState.Requested && !(!!liability.mortgageType) &&
            (liability.typeOfLiability == 'MortgageLoan')) ||
          (this._mortgageFieldsConfig['mortgage.liability.lienPosition'] == MortgageFieldRequiredState.Requested && !(!!liability.lienPosition) &&
            (liability.typeOfLiability == 'MortgageLoan'))
        ) {
          state = MenuItemStatus.Pending;
        }
      });
    }
    if (mortgage.realEstateOwned && mortgage.realEstateOwned.length > 0) {
      mortgage.realEstateOwned.forEach(reo => {
        if (
          this._mortgageFieldsConfig['mortgage.reo.address1'] == MortgageFieldRequiredState.Required && !(!!reo.address1) ||
          this._mortgageFieldsConfig['mortgage.reo.city'] == MortgageFieldRequiredState.Required && !(!!reo.city) ||
          this._mortgageFieldsConfig['mortgage.reo.state'] == MortgageFieldRequiredState.Required && !(!!reo.state) ||
          this._mortgageFieldsConfig['mortgage.reo.zipCode'] == MortgageFieldRequiredState.Required && !(!!reo.zipCode) ||
          this._mortgageFieldsConfig['mortgage.reo.borrowerPicker'] == MortgageFieldRequiredState.Required && (reo.owningBorrowerIds == null || reo.owningBorrowerIds == undefined || reo.owningBorrowerIds.length == 0) ||
          this._mortgageFieldsConfig['mortgage.reo.currentUsageType'] == MortgageFieldRequiredState.Required && !(!!reo.currentUsageType) ||
          this._mortgageFieldsConfig['mortgage.reo.dispositionStatus'] == MortgageFieldRequiredState.Required && !(!!reo.dispositionStatus) ||
          this._mortgageFieldsConfig['mortgage.reo.intendedUsageType'] == MortgageFieldRequiredState.Required && !(!!reo.intendedUsageType) ||
          this._mortgageFieldsConfig['mortgage.reo.isSubjectProperty'] == MortgageFieldRequiredState.Required && (reo.isSubjectProperty == null || reo.isSubjectProperty == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.typeOfProperty'] == MortgageFieldRequiredState.Required && !(!!reo.typeOfProperty) ||
          this._mortgageFieldsConfig['mortgage.reo.marketValue'] == MortgageFieldRequiredState.Required && (reo.marketValue == null || reo.marketValue == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.monthlyMiscExpenses'] == MortgageFieldRequiredState.Required && (reo.monthlyMiscExpenses == null || reo.monthlyMiscExpenses == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.percentOwned'] == MortgageFieldRequiredState.Required && (reo.percentOwned == null || reo.percentOwned == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.vacancyFactor'] == MortgageFieldRequiredState.Required && (reo.vacancyFactor == null || reo.vacancyFactor == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.grossRentalIncome'] == MortgageFieldRequiredState.Required && (reo.grossRentalIncome == null || reo.grossRentalIncome == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.netRentalIncome'] == MortgageFieldRequiredState.Required && (reo.netRentalIncome == null || reo.netRentalIncome == undefined)
        ) {
          state = MenuItemStatus.Error;
          return state;
        }
        if (
          this._mortgageFieldsConfig['mortgage.reo.address1'] == MortgageFieldRequiredState.Requested && !(!!reo.address1 == null || reo.address1 == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.city'] == MortgageFieldRequiredState.Requested && !(!!reo.city == null || reo.city == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.state'] == MortgageFieldRequiredState.Requested && !(!!reo.state == null || reo.state == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.zipCode'] == MortgageFieldRequiredState.Requested && !(!!reo.zipCode == null || reo.zipCode == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.borrowerPicker'] == MortgageFieldRequiredState.Requested && (reo.owningBorrowerIds == null || reo.owningBorrowerIds == undefined || reo.owningBorrowerIds.length == 0) ||
          this._mortgageFieldsConfig['mortgage.reo.currentUsageType'] == MortgageFieldRequiredState.Requested && !(!!reo.currentUsageType) ||
          this._mortgageFieldsConfig['mortgage.reo.dispositionStatus'] == MortgageFieldRequiredState.Requested && !(!!reo.dispositionStatus) ||
          this._mortgageFieldsConfig['mortgage.reo.intendedUsageType'] == MortgageFieldRequiredState.Requested && !(!!reo.intendedUsageType) ||
          this._mortgageFieldsConfig['mortgage.reo.isSubjectProperty'] == MortgageFieldRequiredState.Requested && (reo.isSubjectProperty == null || reo.isSubjectProperty == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.typeOfProperty'] == MortgageFieldRequiredState.Requested && !(!!reo.typeOfProperty) ||
          this._mortgageFieldsConfig['mortgage.reo.marketValue'] == MortgageFieldRequiredState.Requested && (reo.marketValue == null || reo.marketValue == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.monthlyMiscExpenses'] == MortgageFieldRequiredState.Requested && (reo.monthlyMiscExpenses == null || reo.monthlyMiscExpenses == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.percentOwned'] == MortgageFieldRequiredState.Requested && (reo.percentOwned == null || reo.percentOwned == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.vacancyFactor'] == MortgageFieldRequiredState.Requested && (reo.vacancyFactor == null || reo.vacancyFactor == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.grossRentalIncome'] == MortgageFieldRequiredState.Requested && (reo.grossRentalIncome == null || reo.grossRentalIncome == undefined) ||
          this._mortgageFieldsConfig['mortgage.reo.netRentalIncome'] == MortgageFieldRequiredState.Requested && (reo.netRentalIncome == null || reo.netRentalIncome == undefined)
        ) {
          state = MenuItemStatus.Pending;
        }
      });
    }

    return state;

  }

  getStatusForBorrowerBasicDetails = (borrower: MortgageBorrower, unmarriedAddendum: boolean = false) => {
    let state = MenuItemStatus.Success;
    if (
      this._mortgageFieldsConfig['mortgage.borrower.firstName'] == MortgageFieldRequiredState.Required && !(!!borrower.firstName) ||
      this._mortgageFieldsConfig['mortgage.borrower.middleName'] == MortgageFieldRequiredState.Required && !(!!borrower.middleName) ||
      this._mortgageFieldsConfig['mortgage.borrower.lastName'] == MortgageFieldRequiredState.Required && !(!!borrower.lastName) ||
      this._mortgageFieldsConfig['mortgage.borrower.nameSuffix'] == MortgageFieldRequiredState.Required && !(!!borrower.nameSuffix) ||
      this._mortgageFieldsConfig['mortgage.borrower.primaryEmail'] == MortgageFieldRequiredState.Required && !(!!borrower.primaryEmail) ||
      this._mortgageFieldsConfig['mortgage.borrower.mobilePhone'] == MortgageFieldRequiredState.Required && !(!!borrower.mobilePhone) ||
      this._mortgageFieldsConfig['mortgage.borrower.homePhone'] == MortgageFieldRequiredState.Required && !(!!borrower.homePhone) ||
      this._mortgageFieldsConfig['mortgage.borrower.socialSecNum'] == MortgageFieldRequiredState.Required && !(!!borrower.socialSecNum) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateOfBirth'] == MortgageFieldRequiredState.Required && !(!!borrower.dateOfBirth) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.residenceStatus'] == MortgageFieldRequiredState.Required && !(!!borrower.declarations.residenceStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.authorizationMethod'] == MortgageFieldRequiredState.Required && borrower.authorizedCreditCheck && !(!!borrower.authorizationMethod) ||
      this._mortgageFieldsConfig['mortgage.borrower.maritalStatus'] == MortgageFieldRequiredState.Required && !(!!borrower.maritalStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == MortgageFieldRequiredState.Required && borrower.authorizedCreditCheck && !(!!borrower.dateAuthorizedCreditCheck) ||
      this._mortgageFieldsConfig['mortgage.borrower.domesticRelationshipType'] == MortgageFieldRequiredState.Required && unmarriedAddendum && !(!!borrower.domesticRelationshipType) ||
      this._mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] == MortgageFieldRequiredState.Required && unmarriedAddendum && !(!!borrower.unmarriedRelationshipState)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      this._mortgageFieldsConfig['mortgage.borrower.firstName'] == MortgageFieldRequiredState.Requested && !(!!borrower.firstName) ||
      this._mortgageFieldsConfig['mortgage.borrower.middleName'] == MortgageFieldRequiredState.Requested && !(!!borrower.middleName) ||
      this._mortgageFieldsConfig['mortgage.borrower.lastName'] == MortgageFieldRequiredState.Requested && !(!!borrower.lastName) ||
      this._mortgageFieldsConfig['mortgage.borrower.nameSuffix'] == MortgageFieldRequiredState.Requested && !(!!borrower.nameSuffix) ||
      this._mortgageFieldsConfig['mortgage.borrower.primaryEmail'] == MortgageFieldRequiredState.Requested && !(!!borrower.primaryEmail) ||
      this._mortgageFieldsConfig['mortgage.borrower.mobilePhone'] == MortgageFieldRequiredState.Requested && !(!!borrower.mobilePhone) ||
      this._mortgageFieldsConfig['mortgage.borrower.homePhone'] == MortgageFieldRequiredState.Requested && !(!!borrower.homePhone) ||
      this._mortgageFieldsConfig['mortgage.borrower.socialSecNum'] == MortgageFieldRequiredState.Requested && !(!!borrower.socialSecNum) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateOfBirth'] == MortgageFieldRequiredState.Requested && !(!!borrower.dateOfBirth) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.residenceStatus'] == MortgageFieldRequiredState.Requested && !(!!borrower.declarations.residenceStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.authorizationMethod'] == MortgageFieldRequiredState.Requested && borrower.authorizedCreditCheck && !(!!borrower.authorizationMethod) ||
      this._mortgageFieldsConfig['mortgage.borrower.maritalStatus'] == MortgageFieldRequiredState.Requested && !(!!borrower.maritalStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == MortgageFieldRequiredState.Requested && borrower.authorizedCreditCheck && !!(!borrower.dateAuthorizedCreditCheck) ||
      this._mortgageFieldsConfig['mortgage.borrower.domesticRelationshipType'] == MortgageFieldRequiredState.Requested && unmarriedAddendum && !(!!borrower.domesticRelationshipType) ||
      this._mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] == MortgageFieldRequiredState.Requested && unmarriedAddendum && !(!!borrower.unmarriedRelationshipState)
    ) {
      state = MenuItemStatus.Pending;
    }

    borrower.residencyAddresses.forEach(residencyAddress => {
      if (!residencyAddress.address) {
        residencyAddress.address = new Address();
      }

      const inErrorStateForResidencyAddress = this.isBorrowerResidencyAddressInvalid(residencyAddress, MortgageFieldRequiredState.Required);
      if (inErrorStateForResidencyAddress) {
        state = MenuItemStatus.Error;
        return state;
      }

      const has2YearResidencyHistory = this.has2YearResidencyHistory(borrower);
      if (!has2YearResidencyHistory) {
        state = MenuItemStatus.Error;
        return state;
      }

      const inPendingStateForResidencyAddress = this.isBorrowerResidencyAddressInvalid(residencyAddress, MortgageFieldRequiredState.Requested);
      if (inPendingStateForResidencyAddress) {
        state = MenuItemStatus.Pending;
        return state;
      }
    })
    return state;
  }

  getStatusForBorrowerDemographics = (borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;
    if (
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] == MortgageFieldRequiredState.Required && !(!!borrower.governmentMonitors.ethnicity) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.sex'] == MortgageFieldRequiredState.Required && !(!!borrower.governmentMonitors.sex) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.race'] == MortgageFieldRequiredState.Required && !(!!borrower.governmentMonitors.race) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] == MortgageFieldRequiredState.Required && !(!!borrower.governmentMonitors.applicationTakenMethod) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] == MortgageFieldRequiredState.Required && (borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null || borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] == MortgageFieldRequiredState.Required && (borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null || borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] == MortgageFieldRequiredState.Required && (borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null || borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == undefined)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] == MortgageFieldRequiredState.Requested && !(!!borrower.governmentMonitors.ethnicity) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.sex'] == MortgageFieldRequiredState.Requested && !(!!borrower.governmentMonitors.sex) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.race'] == MortgageFieldRequiredState.Requested && !(!!borrower.governmentMonitors.race) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] == MortgageFieldRequiredState.Requested && !(!!borrower.governmentMonitors.applicationTakenMethod) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] == MortgageFieldRequiredState.Requested && (borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null || borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] == MortgageFieldRequiredState.Requested && (borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null || borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] == MortgageFieldRequiredState.Requested && (borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null || borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == undefined)
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  isBorrowerResidencyAddressInvalid = (residencyAddress: ResidencyAddress, requiredStateToCheckFor: MortgageFieldRequiredState): boolean => {

    const streetAddressIsInvalid = this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.address1'] == requiredStateToCheckFor && !(!!residencyAddress.address.address1);
    const cityIsInvalid = this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.city'] == requiredStateToCheckFor && !(!!residencyAddress.address.city);
    const stateIsInvalid = this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.state'] == requiredStateToCheckFor && !(!!residencyAddress.address.state);
    const zipCodeIsInvalid = this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] == requiredStateToCheckFor && !(!!residencyAddress.address.zipCode);
    const residencyBasisIsInvalid = residencyAddress.residencyType !== 'MailingAddress' && this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] == requiredStateToCheckFor && !(!!residencyAddress.residencyBasis);
    const rentIsInvalid = residencyAddress.residencyType !== 'MailingAddress' && residencyAddress.residencyBasis === 'Rent' && this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.rent'] == requiredStateToCheckFor && !(!!residencyAddress.rent);
    const durationMonthsIsValid = residencyAddress.residencyType !== 'MailingAddress' && this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.durationMonths'] == requiredStateToCheckFor && residencyAddress.durationYears == null;
    const durationYearsIsValid = residencyAddress.residencyType !== 'MailingAddress' && this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.durationYears'] == requiredStateToCheckFor && residencyAddress.durationMonths == null;

    const isInvalid = streetAddressIsInvalid || cityIsInvalid || stateIsInvalid || zipCodeIsInvalid || residencyBasisIsInvalid || rentIsInvalid || durationMonthsIsValid || durationYearsIsValid;
    return isInvalid;
  }

  getStatusForBorrowerDeclarations = (borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;
    if (
      this._mortgageFieldsConfig['mortgage.borrower.declarations.occupyProperty'] == MortgageFieldRequiredState.Required && (borrower.declarations.occupyProperty == null || borrower.declarations.occupyProperty == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] == MortgageFieldRequiredState.Required && borrower.declarations.occupyProperty && (borrower.declarations.havePropertiesOwnership == null || borrower.declarations.havePropertiesOwnership == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] == MortgageFieldRequiredState.Required && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.typeOfProperty) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] == MortgageFieldRequiredState.Required && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.heldTitleHow) ||
      //this only applies to purchases
      //this._mortgageFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] == MortgageFieldRequiredState.Requested && borrower.declarations.occupyProperty && (borrower.declarations.isFirstTimeHomeBuyer == null || borrower.declarations.isFirstTimeHomeBuyer == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] == MortgageFieldRequiredState.Required && (borrower.declarations.haveRelationshipWithSeller == null || borrower.declarations.haveRelationshipWithSeller == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] == MortgageFieldRequiredState.Required && (borrower.declarations.borrowedDownPayment == null || borrower.declarations.borrowedDownPayment == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] == MortgageFieldRequiredState.Required && borrower.declarations.borrowedDownPayment && (borrower.declarations.undisclosedMoneyAmount == null || borrower.declarations.undisclosedMoneyAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] == MortgageFieldRequiredState.Required && (borrower.declarations.applyingForOtherMortgage == null || borrower.declarations.applyingForOtherMortgage == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] == MortgageFieldRequiredState.Required && (borrower.declarations.applyingForNonMortgageCredit == null || borrower.declarations.applyingForNonMortgageCredit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] == MortgageFieldRequiredState.Required && (borrower.declarations.propertyHasLienWithCleanEnergyProgram == null || borrower.declarations.propertyHasLienWithCleanEnergyProgram == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] == MortgageFieldRequiredState.Required && (borrower.declarations.coSignedLoan == null || borrower.declarations.coSignedLoan == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveJudgement'] == MortgageFieldRequiredState.Required && (borrower.declarations.haveJudgement == null || borrower.declarations.haveJudgement == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] == MortgageFieldRequiredState.Required && (borrower.declarations.haveTaxDebt == null || borrower.declarations.haveTaxDebt == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] == MortgageFieldRequiredState.Required && (borrower.declarations.haveLawsuit == null || borrower.declarations.haveLawsuit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] == MortgageFieldRequiredState.Required && (borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null || borrower.declarations.haveConveyedTitleInLieuOfForeclosure == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] == MortgageFieldRequiredState.Required && (borrower.declarations.haveCompletedShortSale == null || borrower.declarations.haveCompletedShortSale == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.beenForclosed'] == MortgageFieldRequiredState.Required && (borrower.declarations.beenForclosed == null || borrower.declarations.beenForclosed == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] == MortgageFieldRequiredState.Required && (borrower.declarations.declaredBankruptcy == null || borrower.declarations.declaredBankruptcy == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] == MortgageFieldRequiredState.Required && borrower.declarations.declaredBankruptcy && !(!!borrower.declarations.bankruptcyType)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      this._mortgageFieldsConfig['mortgage.borrower.declarations.occupyProperty'] == MortgageFieldRequiredState.Requested && (borrower.declarations.occupyProperty == null || borrower.declarations.occupyProperty == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] == MortgageFieldRequiredState.Requested && borrower.declarations.occupyProperty && (borrower.declarations.havePropertiesOwnership == null || borrower.declarations.havePropertiesOwnership == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] == MortgageFieldRequiredState.Requested && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.typeOfProperty) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] == MortgageFieldRequiredState.Requested && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.heldTitleHow) ||
      //this only applies to purchases
      //this._mortgageFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] == MortgageFieldRequiredState.Requested && borrower.declarations.occupyProperty && (borrower.declarations.isFirstTimeHomeBuyer == null || borrower.declarations.isFirstTimeHomeBuyer == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] == MortgageFieldRequiredState.Requested && (borrower.declarations.haveRelationshipWithSeller == null || borrower.declarations.haveRelationshipWithSeller == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] == MortgageFieldRequiredState.Requested && (borrower.declarations.borrowedDownPayment == null || borrower.declarations.borrowedDownPayment == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] == MortgageFieldRequiredState.Requested && (borrower.declarations.undisclosedMoneyAmount == null || borrower.declarations.undisclosedMoneyAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] == MortgageFieldRequiredState.Requested && (borrower.declarations.applyingForOtherMortgage == null || borrower.declarations.applyingForOtherMortgage == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] == MortgageFieldRequiredState.Requested && (borrower.declarations.applyingForNonMortgageCredit == null || borrower.declarations.applyingForNonMortgageCredit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] == MortgageFieldRequiredState.Requested && (borrower.declarations.propertyHasLienWithCleanEnergyProgram == null || borrower.declarations.propertyHasLienWithCleanEnergyProgram == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] == MortgageFieldRequiredState.Requested && (borrower.declarations.coSignedLoan == null || borrower.declarations.coSignedLoan == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveJudgement'] == MortgageFieldRequiredState.Requested && (borrower.declarations.haveJudgement == null || borrower.declarations.haveJudgement == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] == MortgageFieldRequiredState.Requested && (borrower.declarations.haveTaxDebt == null || borrower.declarations.haveTaxDebt == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] == MortgageFieldRequiredState.Requested && (borrower.declarations.haveLawsuit == null || borrower.declarations.haveLawsuit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] == MortgageFieldRequiredState.Requested && (borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null || borrower.declarations.haveConveyedTitleInLieuOfForeclosure == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] == MortgageFieldRequiredState.Requested && (borrower.declarations.haveCompletedShortSale == null || borrower.declarations.haveCompletedShortSale == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.beenForclosed'] == MortgageFieldRequiredState.Requested && (borrower.declarations.beenForclosed == null || borrower.declarations.beenForclosed == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] == MortgageFieldRequiredState.Requested && (borrower.declarations.declaredBankruptcy == null || borrower.declarations.declaredBankruptcy == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] == MortgageFieldRequiredState.Requested && borrower.declarations.declaredBankruptcy && !(!!borrower.declarations.bankruptcyType)
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForBorrowerInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let demoState = this.getStatusForBorrowerDemographics(borrower);
        if (demoState == MenuItemStatus.Error || demoState == MenuItemStatus.Pending) {
          state = demoState;
          return;
        }
        let decState = this.getStatusForBorrowerDeclarations(borrower);
        if (decState == MenuItemStatus.Error || decState == MenuItemStatus.Pending) {
          state = decState;
          return;
        }
        let basicState = this.getStatusForBorrowerBasicDetails(borrower);
        if (basicState == MenuItemStatus.Error || basicState == MenuItemStatus.Pending) {
          state = basicState;
          return;
        }
      });
    }
    return state;
  }

  getStatusForCocDetails = (coc: ChangeOfCircumstanceDetail) => {
    let state = MenuItemStatus.Success;
    if (
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureReason'] == MortgageFieldRequiredState.Required && !(!!coc.disclosureReason) ||
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] == MortgageFieldRequiredState.Required && !(!!coc.disclosureReasonOtherDescription) ||
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] == MortgageFieldRequiredState.Required && !(!!coc.disclosureReasonAdditionalDescription) ||
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureComments'] == MortgageFieldRequiredState.Required && !(!!coc.disclosureComments) ||
      this._mortgageFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] == MortgageFieldRequiredState.Required && !(!!coc.changesOfCircumstanceReceivedDate) ||
      this._mortgageFieldsConfig['changeOfCircumstance.changeOfCircumstanceReason'] == MortgageFieldRequiredState.Required && !(!!coc.changeOfCircumstanceReason)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureReason'] == MortgageFieldRequiredState.Requested && (coc.disclosureReason == null || coc.disclosureReason == undefined) ||
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] == MortgageFieldRequiredState.Requested && (coc.disclosureReasonOtherDescription == null || coc.disclosureReasonOtherDescription == undefined) ||
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] == MortgageFieldRequiredState.Requested && (coc.disclosureReasonAdditionalDescription == null || coc.disclosureReasonAdditionalDescription == undefined) ||
      this._mortgageFieldsConfig['changeOfCircumstance.disclosureComments'] == MortgageFieldRequiredState.Requested && (coc.disclosureComments == null || coc.disclosureComments == undefined) ||
      this._mortgageFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] == MortgageFieldRequiredState.Requested && (coc.changesOfCircumstanceReceivedDate == null || coc.changesOfCircumstanceReceivedDate == undefined) ||
      this._mortgageFieldsConfig['changeOfCircumstance.changeOfCircumstanceReason'] == MortgageFieldRequiredState.Requested && (coc.changeOfCircumstanceReason == null || coc.changeOfCircumstanceReason == undefined)
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForLoanEstimateClosingDisclosures = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (mortgage.extension && mortgage.extension.loanEstimateDocumentDetail && mortgage.extension.loanEstimateDocumentDetail.changeOfCircumstanceDetail) {
      let demoState = this.getStatusForCocDetails(mortgage.extension.loanEstimateDocumentDetail.changeOfCircumstanceDetail);
      if (demoState == MenuItemStatus.Error || demoState == MenuItemStatus.Pending) {
        state = demoState;
        return;
      }
    }

    if (mortgage.extension && mortgage.extension.closingDisclosureDocumentDetail && mortgage.extension.closingDisclosureDocumentDetail.changeOfCircumstanceDetail) {
      let demoState = this.getStatusForCocDetails(mortgage.extension.closingDisclosureDocumentDetail.changeOfCircumstanceDetail);
      if (demoState == MenuItemStatus.Error || demoState == MenuItemStatus.Pending) {
        state = demoState;
        return;
      }
    }

    return state;
  }

  private has2YearResidencyHistory = (borrower: MortgageBorrower) => {
    if (!borrower.residencyAddresses || borrower.residencyAddresses.length == 0) return false;

    const residencyAddresses = borrower.residencyAddresses.filter(a => a.residencyType !== ResidencyType.MailingAddress);

    let numberOfMonths = 0;
    let numberOfYears = 0;

    residencyAddresses.forEach(address => {
      numberOfMonths += Number(address.durationMonths) | 0;
      numberOfYears += Number(address.durationYears) | 0;
    })

    let months: number = numberOfYears * 12 + numberOfMonths;
    numberOfYears = Math.floor(months / 12);
    numberOfMonths = months - numberOfYears * 12;

    return (numberOfYears * 12 + numberOfMonths) >= 24;
  }

  getStatusForLoanInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      this._mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.purposeOfLoan) ||
      mortgage.subjectProperty.purposeOfLoan != 'Refinance' && this._mortgageFieldsConfig['mortgage.subjectProperty.presentValue'] == MortgageFieldRequiredState.Required &&
      (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined) ||
      mortgage.subjectProperty.purposeOfLoan != 'Refinance' && mortgage.subjectProperty.purposeOfLoan != 'Purchase' && this._mortgageFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == MortgageFieldRequiredState.Required &&
      (mortgage.subjectProperty.lotOriginalCost == null || mortgage.subjectProperty.lotOriginalCost == undefined) ||
      mortgage.subjectProperty.purposeOfLoan != 'Refinance' && mortgage.subjectProperty.purposeOfLoan != 'Purchase' && this._mortgageFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] == MortgageFieldRequiredState.Required &&
      (mortgage.subjectProperty.lotAppraisedValue == null || mortgage.subjectProperty.lotAppraisedValue == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue'] == MortgageFieldRequiredState.Required && !!!mortgage.mortgageTerm.appraisedValue ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.amount'] == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.interestRate'] == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.interestRate == null || mortgage.mortgageTerm.interestRate == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.qualifyingRate'] == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.qualifyingRate == null || mortgage.mortgageTerm.qualifyingRate == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.noOfMonths'] == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.noOfMonths == null || mortgage.mortgageTerm.noOfMonths == undefined) ||
      this._mortgageFieldsConfig['mortgage.extension.interestOnlyTermMonths'] == MortgageFieldRequiredState.Required && mortgage.extension.isInterestOnly &&
      (mortgage.extension.interestOnlyTermMonths == null || mortgage.extension.interestOnlyTermMonths == undefined) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose'] == MortgageFieldRequiredState.Required && mortgage.subjectProperty.purposeOfLoan == 'Refinance' && !(!!mortgage.subjectProperty.refiPurpose) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose'] == MortgageFieldRequiredState.Required && mortgage.subjectProperty.purposeOfLoan == 'Refinance' && !(!!mortgage.subjectProperty.refinancePrimaryPurpose) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.refinanceProgram'] == MortgageFieldRequiredState.Required && mortgage.subjectProperty.purposeOfLoan == 'Refinance' && !(!!mortgage.subjectProperty.refinanceProgram) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] == MortgageFieldRequiredState.Required && mortgage.subjectProperty.purposeOfLoan == 'Purchase' && !(!!mortgage.subjectProperty.communityLendingProductType) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.mortgageAppliedFor) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition'] == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.lienPosition) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType'] == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.amortizationType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.address1'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.address1) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.city'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.city) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.state'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.state) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.zipCode'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.zipCode) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.county'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.county) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.lotAcquiredDate) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.attachmentType'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.attachmentType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits'] == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.constructionMethod) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType'] == MortgageFieldRequiredState.Required && !mortgage.subjectProperty.isPropertyNotInAProject && !(!!mortgage.subjectProperty.projectDesignType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.improvementCost'] == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.improvementCost == null || mortgage.subjectProperty.improvementCost == undefined) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.mannerTitleWillBeHeld) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.nativeAmericanLandsType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.titleWillBeHeldNames) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.estate'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.estate) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe'] == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.propertyWillBe)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      this._mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.purposeOfLoan) ||
      mortgage.subjectProperty.purposeOfLoan != 'Refinance' && this._mortgageFieldsConfig['mortgage.subjectProperty.presentValue'] == MortgageFieldRequiredState.Requested &&
      (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined) ||
      mortgage.subjectProperty.purposeOfLoan != 'Refinance' && mortgage.subjectProperty.purposeOfLoan != 'Purchase' && this._mortgageFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == MortgageFieldRequiredState.Requested &&
      (mortgage.subjectProperty.lotOriginalCost == null || mortgage.subjectProperty.lotOriginalCost == undefined) ||
      mortgage.subjectProperty.purposeOfLoan != 'Refinance' && mortgage.subjectProperty.purposeOfLoan != 'Purchase' && this._mortgageFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] == MortgageFieldRequiredState.Requested &&
      (mortgage.subjectProperty.lotAppraisedValue == null || mortgage.subjectProperty.lotAppraisedValue == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue'] == MortgageFieldRequiredState.Requested && !!!mortgage.mortgageTerm.appraisedValue ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.amount'] == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.interestRate'] == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.interestRate == null || mortgage.mortgageTerm.interestRate == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.qualifyingRate'] == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.qualifyingRate == null || mortgage.mortgageTerm.qualifyingRate == undefined) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.noOfMonths'] == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.noOfMonths == null || mortgage.mortgageTerm.noOfMonths == undefined) ||
      this._mortgageFieldsConfig['mortgage.extension.interestOnlyTermMonths'] == MortgageFieldRequiredState.Requested && mortgage.extension.isInterestOnly &&
      (mortgage.extension.interestOnlyTermMonths == null || mortgage.extension.interestOnlyTermMonths == undefined) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose'] == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.purposeOfLoan == 'Refinance' && !(!!mortgage.subjectProperty.refiPurpose) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose'] == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.purposeOfLoan == 'Refinance' && !(!!mortgage.subjectProperty.refinancePrimaryPurpose) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.refinanceProgram'] == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.purposeOfLoan == 'Refinance' && !(!!mortgage.subjectProperty.refinanceProgram) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.purposeOfLoan == 'Purchase' && !(!!mortgage.subjectProperty.communityLendingProductType) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.mortgageAppliedFor) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition'] == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.lienPosition) ||
      this._mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType'] == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.amortizationType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.address1'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.address1) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.city'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.city) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.state'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.state) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.zipCode'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.zipCode) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.county'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.county) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.lotAcquiredDate) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.attachmentType'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.attachmentType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits'] == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.constructionMethod) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType'] == MortgageFieldRequiredState.Requested && !mortgage.subjectProperty.isPropertyNotInAProject && !(!!mortgage.subjectProperty.projectDesignType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.improvementCost'] == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.improvementCost == null || mortgage.subjectProperty.improvementCost == undefined) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.mannerTitleWillBeHeld) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.nativeAmericanLandsType) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.titleWillBeHeldNames) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.estate'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.estate) ||
      this._mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe'] == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.propertyWillBe)
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

}
