import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ApplicationContext } from 'src/app/models';
import { TransactionType } from 'src/app/models/config/loan-purpose.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { LoanSummaryMortgageComponent } from './loan-summary-mortgage/loan-summary-mortgage.component';
import { ComponentCanDeactivate } from '../../../../core/route-guards/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'admin-loan-summary',
  templateUrl: 'admin-loan-summary.component.html',
  styleUrls: ['./admin-loan-summary.component.scss'],
})
export class AdminLoanSummaryComponent extends ApplicationContextBoundComponent implements OnInit, ComponentCanDeactivate {

  @Input()
  inDrawer: boolean = false;

  protected loadingLoanSummary: boolean = true;

  loanPurposeTransactionType: TransactionType;

  @ViewChild("loanSummaryMortgage")
  loanSummaryMortgage: LoanSummaryMortgageComponent;

  constructor(private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.applicationContextService.context.subscribe(appContext => {
      if (appContext.application) {
        this.initialize(appContext);
      }
    });

    this.applicationContextService.loanInfoChanges.subscribe(appContext => {
      if (appContext.application) {
        this.initialize(appContext);
      }
    });
  }

  initialize = (appContext: ApplicationContext) => {
    this.loanPurposeTransactionType = appContext.globalConfig.loanPurpose.find(x => x.loanPurposeId == appContext.application.loanPurposeId)?.transactionType;
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.loanSummaryMortgage?.canDeactivate() ?? true;
  }

  confirm(): boolean | Observable<boolean> {
    return this.loanSummaryMortgage.confirm();
  }

  onLoadingLoanStateChange(value: boolean): void {
    this.loadingLoanSummary = value;
  }
}
