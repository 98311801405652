import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/core/services/utils';
import {
  CreditReportArray,
  LpaProperty,
  Mortgage,
  ThirdPartyCredential,
} from 'src/app/models';
import { EnumItem } from 'src/app/models/enum-item.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { FreddieMacUnderwriteRequest, LpaVerification } from '../../models/freddie-mac-underwrite-request.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'loan-product-advisor',
  templateUrl: 'aus-lpa.component.html',
  styleUrls: ['aus-lpa.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class AusLpaComponent implements OnInit {
  @ViewChild('lpaForm') lpaForm: NgForm | undefined;

  @Input() lpaParams: FreddieMacUnderwriteRequest;
  @Input() mortgage: Mortgage;
  @Input() isTPOUser: boolean;

  @Input() creditReportingHistory: CreditReportArray;
  @Input() lpaVendor: ThirdPartyCredential;
  @Input() lpaProperties: LpaProperty;
  @Input() lpaVerificationCompanies: Array<EnumerationItem>;

  technicalAffiliates: Array<any>;
  technicalAffiliateCodes: Array<any>;
  borrowersList: Array<EnumerationItem> = [];
  borrowerNames: string[] = [];

  overrideLpaCredentials: boolean = false;

  hasCreditCredentials: boolean = true;

  enableAssetIncomeVerificationPanel: boolean = false;

  showPassword: boolean = false;

  isFHALoan: boolean = false;

  randomText = Math.random().toString(30);

  creditProviders: EnumItem[] = [];

  orderCredit: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumerationService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.borrowersList = this.mortgage.borrowers.map(borr => ({
      value: borr.contactId,
      name: `${borr.firstName} ${borr.lastName}`
    }));

    for (let i = 0; i <= this.lpaParams.creditRequest.jointBorrowerCreditRequests.length - 1; i++) {
      const creditRequest = this.lpaParams.creditRequest.jointBorrowerCreditRequests[i];
      let borrowerName = "";
      creditRequest.borrowerIds.forEach(borrowerId => {
        const borrower = this.mortgage.borrowers.find(b => b.contactId === borrowerId);
        if (borrower) {
          const nameToAdd = Utils.getPersonsFullName(borrower);
          borrowerName = (borrowerName ? (borrowerName + ` & ${nameToAdd}`) : nameToAdd);
        }
      })
      this.borrowerNames.push(borrowerName);
    }
    this.enableAssetIncomeVerificationPanel = this.lpaParams.verification?.length > 0 && !this.isTPOUser;

    this._enumsService.getCreditEnumerations().subscribe(enums => {
      this.creditProviders = enums['lE.Lodasoft.Common.Enums.Credit.CreditVendor'].filter(i => i.supportedAus &&
        i.supportedAus.toLowerCase() === 'lpa');
      if (this.lpaParams.creditRequest?.creditVendor) {
        this.loadTechnicalAffiliates();
      }
    });

    this.isFHALoan = this.checkIfFHALoan();
    this.lpaParams.writeAusCredsToUserProfile = false;
  }

  overrideLpaCredsClicked = () => {
    this.overrideLpaCredentials = !this.overrideLpaCredentials;
    this.lpaParams.ausUsernameOverride = '';
    this.lpaParams.ausPasswordOverride = '';
    if (!this.overrideLpaCredentials) {
      this.lpaParams.writeAusCredsToUserProfile = false;
    }
  }

  onCreditRequestTypeChanged = (creditRequest: any) => {
    if (creditRequest.creditRequestType === 'Order') {
      creditRequest.creditReferenceNumber = null;
    }
  }

  loadTechnicalAffiliates = () => {
    this.technicalAffiliates = [];

    if (!this.lpaParams.creditRequest.creditVendor) return;

    this._loanServicesService
      .getTechnicalAffiliates(this.lpaParams.creditRequest.creditVendor)
      .subscribe({
        next: this.onGotTechnicalAffiliatesSuccessfully,
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to load technical affiliates', 'AUS Loan Service');
        }
      });
  };

  submitRequest() {
    if (this.lpaForm) {
      this.lpaForm.form.markAllAsTouched();
      if (!this.lpaForm.form.valid) {
        return;
      }
      const lpaParams = cloneDeep(this.lpaParams);
      if (!this.overrideLpaCredentials) {
        delete lpaParams.ausUsernameOverride;
        delete lpaParams.ausPasswordOverride;
      }
      this.activeModal.close(lpaParams);
    }
  }

  trackByIndex = (index: number): number => {
    return index;
  };

  removeIncomeVerificationRecord(index: number) {
    if (index >= 0) {
      this.lpaParams.verification.splice(index, 1);
    }
  }

  addAssetIncomeVerificationRecord() {
    const verification = new LpaVerification();
    verification.borrowerIds = this.borrowersList.length === 1 ? this.borrowersList.map(b => b.value) : [];
    this.lpaParams.verification.push(verification);
  }

  private checkIfFHALoan(): boolean {
    if (!this.mortgage?.mortgageTerm?.mortgageAppliedFor) {
      return false;
    }
    return this.mortgage.mortgageTerm.mortgageAppliedFor === 'FHA';
  }

  private onGotTechnicalAffiliatesSuccessfully = (result) => {
    this.technicalAffiliates = result.filter((a) => a.code);
    this.technicalAffiliateCodes = this.technicalAffiliates.map((a) => a.code);
    if (!this.lpaParams.creditRequest.technicalAffiliateCode)
      this.lpaParams.creditRequest.technicalAffiliateCode = '';

    if (!this.mortgage?.borrowers?.length) return;

    const primaryBorrowerCreditReports = this.creditReportingHistory.filter(
      (ele) => ele.borrowerId === this.mortgage.borrowers[0].contactId
    );
    if (!primaryBorrowerCreditReports.length) return;

    const creditTechnicalAffiliateId =
      primaryBorrowerCreditReports[0]
        .creditTechnicalAffiliateId;
    if (!creditTechnicalAffiliateId) return;

    const technicalAffiliate = this.technicalAffiliates.find(
      (el) => el.creditTechnicalAffiliateId === creditTechnicalAffiliateId
    );

    if (!technicalAffiliate) return;

    this.lpaParams.creditRequest.technicalAffiliateCode =
      technicalAffiliate.code;
    this.lpaParams.creditRequest.technicalAffiliateName = technicalAffiliate.name;
  };

}

