import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import {
  KeyDateConfig,
  KeyDateGroup,
} from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'key-date-group',
  templateUrl: 'key-date-group.component.html',
  styleUrls: ['key-date-group.component.scss'],
})
export class KeyDateGroupComponent implements OnInit {

  @Input() keyDateGroup: KeyDateGroup;
  @Input() keyDateTypes?: EnumerationItem[];

  @Input() companyId: number;
  @Input() baseMessageTitle: string;
  @Input() keyDateGroups: Array<KeyDateGroup>;
  @Input() keyDateSortableOptions: any;
  @Input() unassignedKeyDates: Array<KeyDateConfig> = [];
  @Input() column: number

  @Output() onEditGroup: EventEmitter<KeyDateGroup> = new EventEmitter<KeyDateGroup>();
  @Output() onEditKeyDate: EventEmitter<KeyDateConfig> = new EventEmitter<KeyDateConfig>();
  @Output() keyDateGroupDeleted: EventEmitter<{keyDateGroupId:number, column: number}>  = new EventEmitter<{keyDateGroupId:number, column: number}>();

  deleting = false;

  loaded: boolean = true;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void { }

  keyDateGroupEditClicked() {
    this.onEditGroup.emit(this.keyDateGroup);
  }

  keyDateEditClicked(keyDate: KeyDateConfig) {
    this.onEditKeyDate.emit(keyDate);
  }

  onKeyDateDeletedFromGroup = (keyDate: KeyDateConfig) => {
    const index = this.keyDateGroups.findIndex(g => g.keyDateGroupId === keyDate.keyDateGroupId);
    if (index === -1) return;

    if (this.keyDateGroups[index].keyDateConfigurations) {
      const indexToRemove = this.keyDateGroups[index].keyDateConfigurations.findIndex(k => k.keyDateConfigurationId === keyDate.keyDateConfigurationId);
      if (indexToRemove >= 0) {
        this.keyDateGroups[index].keyDateConfigurations.splice(indexToRemove, 1);
        this.keyDateGroups[index].keyDateConfigurations = [...this.keyDateGroups[index].keyDateConfigurations];
      }
    }
  }

  openDeleteConfirmDialog() {
    const modalRef = this._modalService.open(ConfirmModalComponent);

    modalRef.componentInstance.title = 'Key Date Group';
    modalRef.componentInstance.text =
      'Are you sure you want to delete this key date group?';

    modalRef.result.then(
      (result) => {
        if (result === 'cancel') return;
        this.delete();
      },
      () => { }
    );
  }

  private delete() {
    this.deleting = true;
    this._keyDatesService
      .deleteKeyDateGroup(this.companyId, this.keyDateGroup.keyDateGroupId)
      .pipe(finalize(() => {
        this.deleting = false;
      }))
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Key date group removed successfully',
            `${this.baseMessageTitle} Group`
          );
          this.keyDateGroupDeleted.emit({ keyDateGroupId: this.keyDateGroup.keyDateGroupId, column: this.column });
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error?.message || "Couldn't delete key date group",
            `${this.baseMessageTitle} Group`
          );
        }
      });
  }
}
