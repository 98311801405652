import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { VideoRecorderComponent } from "./video-recorder.component";
import { VideoRecorderDialogComponent } from "./video-recorder-dialog/video-recorder-dialog.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    VideoRecorderComponent,
    VideoRecorderDialogComponent
  ],
  exports: [
    VideoRecorderComponent,
    VideoRecorderDialogComponent
  ],
  providers: [
  ]
})
export class VideoRecorderModule {
  constructor() {
  }
}
