<div class="modal-header">
  <h2 class="modal-title">Apply Product Confirmation</h2>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row" *ngIf="hasLenderCredit && !isWholesaleLoan && currentApplication?.productPricing?.compType !=='Correspondent'">
      <div class="col-md-12">
        <p class="text-alert mb-1">How would you like to apply rate credit to the loan?</p>
        <div class="card no-border">
          <div class="card-body">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="rateToDiscount" id="rateToDiscount"
                value="ApplyCreditToDiscountFee" [(ngModel)]="rateCreditApplicationMethod">
              <label class="form-check-label fw-bold text-dark" for="rateToDiscount">
                Apply credit from the rate to discount fee
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="rateToLender" id="rateToLender"
                value="ApplyCreditToLenderCredit" [(ngModel)]="rateCreditApplicationMethod">
              <label class="form-check-label fw-bold text-dark" for="rateToLender">
                Apply credit from the rate to lender credit
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="noRateToLoan" id="noRateToLoan"
                value="DoNotApplyCredit" [(ngModel)]="rateCreditApplicationMethod">
              <label class="form-check-label fw-bold text-dark" for="noRateToLoan">
                Do not apply credit from the rate to loan
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="changes.length > 0">
        <table class="table table-striped align-items-center align-middle mb-0">
          <thead>
            <tr>
              <th>Changing Field</th>
              <th>Previous Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let change of changes">
              <td>
                <strong>{{change.fieldName}}</strong>
              </td>
              <td>
                {{change.fieldName?.toLowerCase() === 'state' ? (change.oldvalue | uppercase) : change.oldvalue }}
              </td>
              <td>
                {{change.fieldName?.toLowerCase() === 'state' ? (change.newvalue | uppercase) : change.newvalue }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr *ngIf="(changes?.length > 0) || (hasLenderCredit && !isWholesaleLoan && currentApplication?.productPricing?.compType !=='Correspondent')">
    <div class="row">
      <div class="col-md-12">
        <p *ngIf="changes.length === 0" class="text-alert">You have not changed any loan data - click Apply to apply
          product/pricing to the loan.</p>
        <p *ngIf="changes.length > 0" class="text-alert">Above are the changes you made to the loan - click Apply to
          apply product/pricing to the loan.</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">

  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary pull-right ng-scope" (click)="activeModal.close(rateCreditApplicationMethod)"
    style="margin-left: 5px;">
    Apply
  </button>
</div>
