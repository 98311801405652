<div class="row" *ngIf="data.kD_RequestedClosingDate || data.kD_FirstPaymentDate || data.kD_CommitmentDate || (data.kD_CloseOfEscrow && isPurchase)">
    <div class="col-lg-12">                            
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">                      
                        <h4 class="card-title">Key Date Info</h4>                      
                    </div>
                </div>                                 
            </div>
            <div class="card-body">
                <div class="row" *ngIf="data">
                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="data.kD_RequestedClosingDate">
                        <label class="form-label" for="closingDate">{{data.kD_RequestedClosingDate.displayName}}</label>
                        <date-input name="closingDate" [id]="'closingDate'" [(ngModel)]="data.kD_RequestedClosingDate.eventDate"></date-input>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-3" *ngIf="!data.kD_RequestedClosingDate">
                        <label class="form-label"><b>There is no key date for Requested Closing</b></label>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="data.kD_FirstPaymentDate">
                        <label class="form-label" for="firstPaymentDate">{{data.kD_FirstPaymentDate.displayName}}</label>
                        <date-input name="firstPaymentDate" [id]="'firstPaymentDate'" [(ngModel)]="data.kD_FirstPaymentDate.eventDate"></date-input>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-3" *ngIf="!data.kD_FirstPaymentDate">
                        <label class="form-label"><b>There is no key date for First Payment</b></label>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="data.kD_CommitmentDate">
                        <label class="form-label" for="loanContingency">{{data.kD_CommitmentDate.displayName}}</label>
                        <date-input name="loanContingency" [id]="'loanContingency'" [(ngModel)]="data.kD_CommitmentDate.eventDate"></date-input>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-3" *ngIf="!data.kD_CommitmentDate">
                        <label class="form-label"><b>There is no key date for Commitment</b></label>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="data.kD_CloseOfEscrow && isPurchase">
                        <label class="form-label" for="closeOfEscrow">{{data.kD_CloseOfEscrow.displayName}}</label>
                        <date-input name="closeOfEscrow" [id]="'closeOfEscrow'" [(ngModel)]="data.kD_CloseOfEscrow.eventDate"></date-input>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 mt-3" *ngIf="!data.kD_CloseOfEscrow && isPurchase">
                        <label class="form-label"><b>There is no key date for Close Of Escrow</b></label>
                    </div>

                </div> 
            </div>
        </div>
    </div>
</div>