import { Component, OnInit, Input } from '@angular/core';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';

@Component({
  selector: 'cash-flow-analysis',
  templateUrl: 'cash-flow-analysis.component.html',
  styleUrls: ['./cash-flow-analysis.component.scss']
})
export class CashFlowAnalysisComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    this.calculateExpenses();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  incomes: any[] = [];
  a_thru_f: number = 0;
  c_thru_f: number = 0;

  incomeTotal: number = 0;
  totalNetHouseholdIncome: number = 0;
  totalMonthlyExpenses: number = 0;
  nonMortgageExpenses: number = 0;
  mortgageExpenses: number = 0;
  proposedHousingExpenses: number = 0;
  residualIncome: number = 0;
  incomeTaxRate: number = 0.3;
  ratioOfResidualIncomeToTotalNetIncome: number;
  ratioOfTotalMonthlyExpensesToTotalNetIncome: number;
  ratioOfNonMortgageExpensesToTotalNetIncome: number;

  private _mortgage: UrlaMortgage;

  constructor(
    private readonly _mortgageCalculationsService: MortgageCalculationService) { }

  ngOnInit() {

  }

  calculateIncomes = () => {
    this.totalNetHouseholdIncome = this.incomeTotal * (1 - this.incomeTaxRate);
    this.residualIncome = this.totalNetHouseholdIncome - this.a_thru_f;
    this.ratioOfTotalMonthlyExpensesToTotalNetIncome = parseFloat((this.a_thru_f / this.totalNetHouseholdIncome).toFixed(2));
    this.ratioOfNonMortgageExpensesToTotalNetIncome = parseFloat((this.nonMortgageExpenses / this.totalNetHouseholdIncome).toFixed(2));
    this.ratioOfResidualIncomeToTotalNetIncome = parseFloat((this.residualIncome / this.totalNetHouseholdIncome).toFixed(2));
  }

  refresh = () => {
    this.calculateExpenses();
  }

  private calculateExpenses = () => {
    this.resetCalculation();

    this.incomes = [];

    this.mortgage.borrowers.forEach(borr => {
      let borrIncome: number = this._mortgageCalculationsService.calculateBorrowerTotalMonthlyIncome(borr);
      this.incomeTotal += borrIncome;
      this.incomes.push({
        description: `${borr.firstName} ${borr.lastName}`,
        amount: borrIncome
      });
    });
    this.incomes.push(
      {
        description: 'Total Monthly Income',
        amount: this.incomeTotal
      });
    let pitiInfo = this._mortgageCalculationsService.getPiTiPaymentInfo(this.mortgage);
    this.proposedHousingExpenses = (pitiInfo.pi + pitiInfo.ti + pitiInfo.other) || 0;

    this._mortgage.liabilities.forEach(liability => {

      if (liability.typeOfLiability == "MortgageLoan" || liability.typeOfLiability == 'HELOC') {
        if (liability.payoffType != 'Full') {
          this.mortgageExpenses += liability.monthlyPayment || 0;
        }
      }
      else {
        if (liability.payoffType != 'Full') {
          this.nonMortgageExpenses += liability.monthlyPayment || 0;
        }
      }
    })
    this.totalMonthlyExpenses = (this.mortgageExpenses + this.nonMortgageExpenses) || 0;
    this.a_thru_f = this.totalMonthlyExpenses + this.proposedHousingExpenses;
    this.c_thru_f = this.totalMonthlyExpenses;

    this.calculateIncomes();
  }

  resetCalculation = () => {
    this.totalMonthlyExpenses = 0;
    this.incomeTotal = 0;
    this.nonMortgageExpenses = 0;
    this.mortgageExpenses = 0;
  }
}