import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDataInfoComponent } from './custom-data-info.component';
import { CustomFieldEditor } from './custom-field-editor/custom-field-editor.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    CustomDataInfoComponent,
    CustomFieldEditor,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PopoverModule.forRoot(),
  ],
  exports: [
    CustomDataInfoComponent,
    CustomFieldEditor,
  ]
})
export class CustomDataInfoModule { }
