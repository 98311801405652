import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FeeViewModel} from '../fee-view-model';
import {enumLikeValueToDisplayName} from '../../../core/services/utils';
import {FeeSectionType, LoanFee} from '../fees.model';
import {FeeContextService} from '../services/fee-context.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'fee-shop-config-editor',
  templateUrl: './fee-shop-config-editor.component.html',
  styleUrls: ['./fee-shop-config-editor.component.scss'],
})
export class FeeShopConfigEditorComponent implements OnInit, OnDestroy {
  @Input() set fee(value: FeeViewModel) {
    this._fee = value;

    this.setLeSectionName(value);
    this.setCdSectionName(value);
  }

  private _fee: FeeViewModel;

  protected leSectionName: string;
  protected cdSectionName: string;

  protected get canShopLe(): boolean {
    return this._fee.fee.canShopLoanEstimate;
  }

  protected set canShopLe(value: boolean) {
    this._fee.fee.canShopLoanEstimate = value;
    this.setLeSectionName(this._fee);

    this.invalidateFeeChanges();
  }

  protected get didShopCd(): boolean {
    return this._fee.fee.didShopClosingDisclosure;
  }

  protected set didShopCd(value: boolean) {
    this._fee.fee.didShopClosingDisclosure = value;
    this.setCdSectionName(this._fee);

    if (value && !this.canShopLe) {
      this.canShopLe = true;
    } else {
      this.invalidateFeeChanges();
    }
  }

  private readonly _invalidateFeeChangesSubject = new Subject<void>();

  private _invalidateFeeChangesSubscription?: Subscription;

  constructor(private readonly _feeContextService: FeeContextService) {}

  ngOnInit() {
    if (this._fee == null) {
      this.fee = FeeViewModel.empty();
    }

    this.subscribeToInvalidateFeeChanges();
  }

  ngOnDestroy() {
    this._invalidateFeeChangesSubscription?.unsubscribe();
  }

  private subscribeToInvalidateFeeChanges(): void {
    this._invalidateFeeChangesSubscription?.unsubscribe();
    this._invalidateFeeChangesSubscription = this._invalidateFeeChangesSubject
      .pipe(debounceTime(300))
      .subscribe(() => {
        this._feeContextService.checkChanges({ignoreCache: true});
      });
  }

  private setLeSectionName(feeView: FeeViewModel): void {
    this.leSectionName = this.getEffectiveSectionName(feeView.fee, this.canShopLe);
  }

  private setCdSectionName(feeView: FeeViewModel): void {
    this.cdSectionName = this.getEffectiveSectionName(feeView.fee, this.didShopCd);
  }

  private getEffectiveSectionName(fee: LoanFee, shopCondition: boolean): string {
    return enumLikeValueToDisplayName(this.getEffectiveSectionType(fee, shopCondition));
  }

  private getEffectiveSectionType(fee: LoanFee, shopCondition: boolean): FeeSectionType {
    if (fee.keepOriginalFeeSectionWhenShopping) {
      return fee.feeSection;
    }

    return shopCondition ? FeeSectionType.Services : FeeSectionType.ServicesNoShop;
  }

  private invalidateFeeChanges(): void {
    this._invalidateFeeChangesSubject.next();
  }
}
