import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbDateStruct, NgbInputDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, EMPTY, forkJoin, from, Subscription } from 'rxjs';
import { Utils, handleNonErrorDismissals } from 'src/app/core/services/utils';
import { ApplicationContext, DocumentType } from 'src/app/models';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { BorrowerEditorComponent } from '../../../borrower/components/borrower-editor/borrower-editor.component';
import { BorrowersService } from '../../../borrower/services/borrowers.service';
import { ViewLeadDrawerComponent } from 'src/app/modules/leads/components/dialogs/view-lead-drawer/view-lead-drawer.component';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { Constants } from 'src/app/services/constants';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskNote, TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { TaskStats } from '../../models/task-stats-group.model';
import { EditTaskDialogComponent } from '../edit-task-dialog/edit-task-dialog.component';
import { AddRejectionNotesDialogComponent } from './add-rejection-notes-dialog/add-rejection-notes-dialog.component';
import { ConfirmPostponeDialogComponent } from './confirm-postpone-dialog/confirm-postpone-dialog.component';
import {
  ConfirmResponsiblePartyChangeDialogComponent,
} from './confirm-responsible-party-change-dialog/confirm-responsible-party-change-dialog.component';
import { ConfirmSnoozeDialogComponent } from './confirm-snooze-dialog/confirm-snooze-dialog.component';
import { ConfirmStatusDialogComponent } from './confirm-status-dialog/confirm-status-dialog.component';
import { TaskEditorQCUComponent } from '../task-editor-qcu/task-editor-qcu.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { TaskLoanDocPreviewComponentV2 } from './task-loan-doc-preview-v2/task-loan-doc-preview-v2.component';
import { TaskEmailSenderModalComponent } from 'src/app/modules/conditions-tasks/components/task-email-sender-modal/task-email-sender-modal.component';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.scss']
})
export class TaskTableComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('qcuTaskEditorDrawer')
  qcuTaskEditor: DrawerComponent;

  @Input()
  set tasks(tasks: LoanDocDashboardTask[]) {
    this._tasks = tasks;
    this._tasks.forEach(t => {
      t.note = MentionsUtils.generateDisplayHtmlWithMentions(t.note);
      this.generateDisplayHtmlWithImages(t.note, t, null);
      if (this.documentTypes) {
        const documentType = this.documentTypes.find(dt => dt.documentTypeId === t.documentTypeId);
        t['documentTypeName'] = documentType ? documentType.documentTypeName : "";
      }

      if (t.dueDate) {
        t['shortDueDate'] = moment(t.dueDate).format('M/D/YY');
      }

      if (t.taskPriority) {
        switch (t.taskPriority) {
          case 'Immediate': {
            t['urgency'] = 1;
            break;
          }
          case 'High': {
            t['urgency'] = 2;
            break;
          }
          case 'Normal': {
            t['urgency'] = 3;
            break;
          }
          case 'Low': {
            t['urgency'] = 4;
            break;
          }
          default: {
            break;
          }
        }
      }

      if (t.applicationId) {
        t['applicationIdWithPadding'] = 1 + Utils.padLeft(t.applicationId + '', '0', 9);
      }

      if (t.applicationName) {
        t['applicationNameWithoutComma'] = t.applicationName.replace(", ", " ");
      }

      t.snoozeDate = t.snoozeDate ? t.snoozeDate : t.dueDate;
    });
  }

  @Output()
  dataChanged: EventEmitter<any> = new EventEmitter<any>();

  get tasks(): LoanDocDashboardTask[] {
    return this._tasks;
  }

  @Input()
  filterId: number;

  @Input()
  impersonateUserId: string;

  @Output()
  reloadTasks: EventEmitter<any> = new EventEmitter();

  @Output()
  changeStat: EventEmitter<TaskStats> = new EventEmitter();

  @Input()
  appId: number;

  @Input()
  scrollable: boolean = false;

  @Input()
  expandToVisibleScreen: boolean = true;

  @Input()
  showLeadAndBorrowerNamesAsLinks = true;

  @Input()
  showFilters: boolean = false;

  @Input()
  showViewFiles: boolean = false;

  @Input()
  taskStats: TaskStats[];

  @Input()
  borrowers?: BorrowerDto[] = [];

  @ViewChild('taskTableDynamicDrawer')
  taskTableDynamicDrawer: DrawerComponent;

  @ViewChildren("postponeBtn")
  postponeBtns: QueryList<PopoverDirective>;

  @ViewChildren("snoozeButton")
  snoozeButtons: QueryList<PopoverDirective>;

  filteredTasks: any[] = [];

  documentTypes: DocumentType[];

  today: NgbDateStruct;

  snoozeDurationInMinutes: number = 15;

  followUp: NgbDateStruct;

  editFileGuid: string = "";

  tempFile: FileDto;

  canDelete: boolean;

  allowBulkActions: boolean;

  readOnlyTasks: boolean;

  userId: string;

  selectedRows: LoanDocDashboardTask[];

  activeDeletionId: number = -1;

  globalConfig: GlobalConfig;

  applicationMode: string;

  globalSearchString: string;

  editLeadDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  editQcuTaskDrawerOptions: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: null
  }

  taskTableDynamicDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  }

  element;

  globalFilterFields = [
    'leadId',
    'fullName',
    'applicationName',
    "applicationNameWithoutComma",
    'applicationId',
    'applicationIdWithPadding',
    'responsibleParty',
    'documentTypeName',
    'description',
    'note',
    'taskPriority',
    'shortDueDate',
  ];

  private _tasks: LoanDocDashboardTask[] = [];

  private _loanInfoChangesSubscription: Subscription;
  private _dynamicEventSubscriptions: any[] = [];

  private _taskEmailSenderModalSubscription?: Subscription;

  constructor(
    injector: Injector,
    config: NgbInputDatepickerConfig,
    private readonly _modalService: NgbModal,
    private readonly _taskService: TaskService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _loanDocService: LoanDocService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _navigationService: NavigationService,
    private readonly _drawerService: DrawerService,
    private readonly _borrowersService: BorrowersService,
    private readonly _utilityService: SharedUtilitiesService,
    private el: ElementRef
  ) {
    super(injector);
    config.autoClose = 'outside';
    const date = new Date();
    this.today = { year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate() };
    config.minDate = this.today;
    this.onTableScroll();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  private onTableScroll = () => {
    this.postponeBtns?.forEach(btn => btn.hide())
    this.snoozeButtons?.forEach(btn => btn.hide())
  }

  ngOnInit(): void {
    if (!this.applicationContext?.globalConfig) {
      // KAAN: Not sure why this is listening to loan info changes - scared to change it now...
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.initialize(this.applicationContext);
    }
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    this.filteredTasks = this._tasks;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._taskEmailSenderModalSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();

    this._dynamicEventSubscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  onDragEnter = (event) => {
    event.preventDefault();
    this.element = event.currentTarget;
    this.element.style.height = this.element.offsetHeight.toString() + 'px';
    this.element.classList.add('visibleDragDrop');
  }

  onDragLeave = (event) => {
    event.preventDefault();
    if (event.currentTarget != this.element) {
      event.currentTarget.classList.remove('visibleDragDrop');
    }

    if (!event.relatedTarget || event.relatedTarget.classList.contains('p-paginator')) {
      this.element.classList.remove('visibleDragDrop');
    }
  }

  onDragOver = (event) => {
    event.preventDefault();
  }

  onDrop = (event, task) => {
    event.preventDefault();
    this.element.classList.remove('visibleDragDrop');
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      this.editTaskModal(task, 'uploadedFiles', files);
    }
  }

  onDragEnterHeader = () => {
    event.preventDefault();
    this.element.classList.remove('visibleDragDrop');
  }

  ngAfterViewInit() {
    const tableBody2 = document.querySelector(".p-datatable-wrapper") as HTMLElement;
    if (tableBody2) {
      tableBody2.onscroll = this.onTableScroll;
    }
  }

  onFileCheckStatusChanged = (checked: boolean, file: FileDto) => {
    file.selectedForAction = checked;
  }

  editTaskModal = (task: LoanDocDashboardTask, initControls?: string, files?) => {
    this._taskService.getTaskDashboardViewById(task.loanDocTaskId).subscribe((response) => {
      if (initControls === 'uploadedFiles') {
        files.forEach((targetFile) => {
          const file = targetFile;
          let docFile = new FileDto();
          docFile.fileName = file.name;
          docFile.loanDocId = response.loanDocId;
          docFile.note = '';
          docFile.borrowerNote = '';
          docFile.guid = null;
          docFile.active = true;
          docFile.file = file;
          response.docFiles.push(docFile);
        });
      }

      // qcu task
      if (task.taskType === 'QuickClientUpdatePreApproved' || task.taskType === 'QuickClientUpdateInProcess' || task.taskType === 'QuickClientUpdateReferralLead') {
        const dynamicComponentInfo = new DynamicComponentInfo();
        dynamicComponentInfo.componentType = TaskEditorQCUComponent;
        dynamicComponentInfo.parameters.set("task", response);
        dynamicComponentInfo.parameters.set("taskReadonly", false);
        dynamicComponentInfo.parameters.set("borrowers", this.borrowers);
        this._drawerService.show("qcuTaskEditorDrawer", 100, "Editing Task", dynamicComponentInfo)
          .then(() => {
            const subscription = this.qcuTaskEditor.componentInstance.saveClicked.subscribe(() => {
              this.reloadTasks.emit();
              this._loanDocsService.sendEventDocumentUploaded();
              this._drawerService.hide("qcuTaskEditorDrawer", 100);
            });
            const subscription2 = this.qcuTaskEditor.componentInstance.cancelClicked.subscribe((isRefreshed: boolean) => {
              if (isRefreshed) {
                this.reloadTasks.emit();
              }
              this._drawerService.hide("qcuTaskEditorDrawer", 100);
            });
            this._dynamicEventSubscriptions.push(subscription);
            this._dynamicEventSubscriptions.push(subscription2);
          });
      }
      else {
        const modalOptions = _.cloneDeep(Constants.modalOptions.large);
        if (task.applicationId) {
          if (initControls === 'docGen') {
            modalOptions.windowClass = 'modal-w-50';
          } else {
            modalOptions.windowClass = 'modal-w-90';
          }
        }
        const modalRef = this._modalService.open(EditTaskDialogComponent, modalOptions);
        modalRef.componentInstance.task = response;
        modalRef.componentInstance.filterId = this.filterId;
        modalRef.componentInstance.borrowers = this.borrowers;

        if (initControls === 'upload') {
          modalRef.componentInstance.openFileUpload = true;
        } else if (initControls === 'eSign') {
          modalRef.componentInstance.startESign = true;
        } else if (initControls === 'docGen') {
          modalRef.componentInstance.startDocumentGeneration = true;
        }

        modalRef.result.then((result) => {
          if (result) {
            this.reloadTasks.emit();
            this._loanDocsService.sendEventDocumentUploaded();
          }
        }, (isRefreshed: boolean) => {
          if (isRefreshed) {
            this.reloadTasks.emit();
          }
        });
      }
    });
  }

  syncLoanDocWithLos = (loanDocId: number, task: LoanDocDashboardTask) => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe((loanDoc) => {
      this._loanDocsService.syncLoanDocWithLos(loanDoc).subscribe(() => {

        this._taskService.getTaskDashboardViewById(task.loanDocTaskId).subscribe((response) => {
          _.assign(task, response);
          this.reloadTasks.emit();
        }, (err) => {
          this._spinner.hide();
          this._notifyService.showError('Error ' + err ? err.message || err : 'Unable to fetch the task.', 'Loan Docs')
        })
        this._notifyService.showSuccess('Pushed to los successfully.', 'Loan Docs')
      }, (err) => {
        this._spinner.hide();
        this._notifyService.showError('Error ' + err ? err.message || err : 'Unable to push to los.', 'Loan Docs')
      })
    }, (err) => {
      this._spinner.hide();
    })
  }

  updateFollowUp = (task: LoanDocDashboardTask) => {
    let todaysDate = moment(new Date(this.today.year, this.today.month - 1, this.today.day, 0, 0, 0, 0));
    let followUpDate = moment(new Date(this.followUp.year, this.followUp.month - 1, this.followUp.day, 0, 0, 0, 0));
    let diffDays = followUpDate.diff(todaysDate, 'days');
    this._taskService.updateFollowUpDate(task.loanDocTaskId, diffDays).subscribe((response) => {
      task.followUpDate = response.followUpDate;
      this._notifyService.showSuccess('Upload Follow-Up', 'Success');
      this.reloadTasks.emit();
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "Upload Follow-Up", 'Error')
    });
  }

  updateSnooze = (task: LoanDocDashboardTask) => {
    this._spinner.show();
    this._taskService.updateSnoozeDate(task.loanDocTaskId, this.snoozeDurationInMinutes).subscribe((response) => {
      task.snoozeDate = response.snoozeDate;
      this._notifyService.showSuccess('The task has been snoozed successfully.', 'Success!');
      this.reloadTasks.emit();
      this.snoozeDurationInMinutes = 15;
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "An error occurred while snoozing the task.", 'Error!');
    }).add(() => this._spinner.hide());
  }

  onEditFileName = (file: FileDto) => {
    this.editFileGuid = file.guid;
    this.tempFile = _.cloneDeep(file);
  }

  cancelLoanDocEdit = (file: FileDto) => {
    file = this.tempFile;
    this.editFileGuid = "";
  }

  canDeleteTasks(): boolean {
    if (!this.canDelete || !this.selectedRows || !_.isArray(this.selectedRows)) {
      return false;
    }
    return this.selectedRows.filter(r =>
      r.taskType !== 'QuickClientUpdateReferralLead' &&
      r.taskType !== 'QuickClientUpdatePreApproved' &&
      r.taskType !== 'QuickClientUpdateInProcess').length > 0
  }

  deleteTasks = () => {
    if (!this.canDeleteTasks()) {
      return;
    }
    const deleteableRows = this.selectedRows.filter(r => r.taskType !== 'QuickClientUpdateReferralLead' && r.taskType !== 'QuickClientUpdatePreApproved' && r.taskType !== 'QuickClientUpdateInProcess');
    const modalRef = this._modalService.open(ConfirmModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.title = "Confirm Deletion";
    modalRef.componentInstance.text = `You are about to delete <i>${deleteableRows.length}</i> task${deleteableRows.length > 1 ? 's' : ''}. Do you want to continue?`;

    modalRef.result.then((result) => {
      if (result === 'cancel') return;
      const data = deleteableRows.map(row => row.loanDocTaskId);
      this._taskService.deleteMultiTasks(data).subscribe({
        next: (response) => {
          this._notifyService.showSuccess('Delete task(s) successful', 'Success');
          this.selectedRows = [];
          this.reloadTasks.emit();
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Delete task(s) failed", 'Failure')
        }
      });
    }, (err) => { });
  }

  reassignTasks = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      const modalRef = this._modalService.open(ConfirmResponsiblePartyChangeDialogComponent, Constants.modalOptions.medium);
      modalRef.componentInstance.count = this.selectedRows.length;
      modalRef.componentInstance.users = this.applicationContext.globalConfig.users;
      modalRef.componentInstance.application = this.applicationContext.application;
      modalRef.componentInstance.roles = this.applicationContext.globalConfig.roles;

      modalRef.result.then((result) => {
        if (result != 'cancel') {
          let data = [];
          this.selectedRows.forEach(row => {
            data.push(row.loanDocTaskId);
          });
          this._taskService.changeResponsibleMultiTasks(result, data).subscribe((response) => {
            this._notifyService.showSuccess('Reassign task(s) successful', 'Success');
            this.reloadTasks.emit();
          }, (err) => {
            this._notifyService.showError(err ? err.message || err : "Reassign task(s) failed", "Failure");
          });
        }
      }, (err) => {
        this._notifyService.showError('Reassign task(s) failed', 'Failure')
      });
    }
  }

  postponeTasks = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      const modalRef = this._modalService.open(ConfirmPostponeDialogComponent,
        {
          size: 'md',
          backdrop: 'static',
          centered: true,
        });
      modalRef.componentInstance.count = this.selectedRows.length;

      modalRef.result.then((result) => {
        if (result != 'cancel') {
          let data = [];
          this.selectedRows.forEach(row => {
            data.push(row.loanDocTaskId);
          });
          this._taskService.updateFollowUpBulk(result, data).subscribe((response) => {
            this._notifyService.showSuccess('Postpone task(s) successful', 'Success');
            this.reloadTasks.emit();
          }, (err) => {
            this._notifyService.showError(err ? err.message || err : "Postpone task(s) failed", "Failure");
          });
        }
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : "Postpone task(s) failed", "Failure");
      });
    }
  }

  snoozeTasks = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      const modalRef = this._modalService.open(ConfirmSnoozeDialogComponent, Constants.modalOptions.medium);
      modalRef.componentInstance.count = this.selectedRows.length;

      modalRef.result.then((snoozeDurationInMinutes: number) => {
        if (snoozeDurationInMinutes) {
          let data = [];
          this.selectedRows.forEach(row => {
            data.push(row.loanDocTaskId);
          });
          this._taskService.updateSnoozeBulk(snoozeDurationInMinutes, data).subscribe((response) => {
            this.reloadTasks.emit();
            this._notifyService.showSuccess('Tasks snoozed successfully.', 'Success!')
          }, (err) => {
            this._notifyService.showError(err ? err.message || err : "An error occurred while snoozing tasks.", "Error!");
          })
        }
      }, (err) => {
      });
    }
  }

  changeStatus = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      const modalRef = this._modalService.open(ConfirmStatusDialogComponent, Constants.modalOptions.medium);

      let editedTasks = {};
      let requestBorrower = undefined;
      this.selectedRows.forEach(task => {
        if (this.isNullOrUndefine(editedTasks[task.taskStatus + "RequiresReview"])) {
          editedTasks[task.taskStatus + "RequiresReview"] = [];
        }
        if (this.isNullOrUndefine(editedTasks[task.taskStatus])) {
          editedTasks[task.taskStatus] = [];
        }
        if (requestBorrower === undefined) {
          requestBorrower = task.requestBorrower;
        }
        if (task.requiresReview) {
          editedTasks[task.taskStatus + "RequiresReview"].push(task);
        } else {
          editedTasks[task.taskStatus].push(task);
        }
      });

      modalRef.componentInstance.selectedTasks = editedTasks;
      modalRef.componentInstance.requestBorrower = requestBorrower;
      modalRef.result.then((result) => {
        if (result != 'cancel') {
          let allTasks = [];
          this.addToList(allTasks, result.ReviewReadyRequiresReview);
          this.addToList(allTasks, result.ReviewReady);
          this.addToList(allTasks, result.Pending);
          this.addToList(allTasks, result.PendingRequiresReview);
          this.addToList(allTasks, result.Requested);
          this.addToList(allTasks, result.RequestedRequiresReview);
          this.addToList(allTasks, result.Rejected);
          this.addToList(allTasks, result.RejectedRequiresReview);
          this.addToList(allTasks, result.Submitted);
          this.addToList(allTasks, result.SubmittedRequiresReview);
          this.addToList(allTasks, result.Approved);
          this.addToList(allTasks, result.ApprovedRequiresReview);
          this.addToList(allTasks, result.NotApplicable);
          this.addToList(allTasks, result.NotApplicableRequiresReview);

          let promises = [];

          allTasks.forEach(task => {
            promises.push(this._taskService.setTaskStatus(task.loanDocTaskId, task.updatedStatus))
          })

          forkJoin(promises).subscribe((response) => {
            this._notifyService.showSuccess("Success", "Update");
            this.reloadTasks.emit();
          }, (err) => {
            this._notifyService.showError(err ? err.message || err : "Failure", "Update");
          });
        }
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : "Failure", "Update");
      });
    }
  }

  setTaskStatus = (type: string, task: LoanDocDashboardTask) => {
    if (type == "Rejected") {
      const modalRef = this._modalService.open(AddRejectionNotesDialogComponent, Constants.modalOptions.medium);
      modalRef.componentInstance.borrowerFacingApplicable = true;

      modalRef.result.then((result: TaskNote | 'cancel') => {
        if (result != 'cancel') {
          this._taskService.addTaskNote(task.loanDocTaskId, result as TaskNote)
            .subscribe({
              next: () => {
                this.postTaskStatusUpdate(type, task);
              },
              error: (err) => {
                this._notifyService.showError(err.message || err, "Error!");
              }
            });
        }
      });
    }
    else {
      this.postTaskStatusUpdate(type, task);
    }
  }

  postTaskStatusUpdate = (type: string, task: LoanDocDashboardTask) => {
    this._taskService.setTaskStatus(task.loanDocTaskId, type).subscribe((response) => {
      task.taskStatus = type;
      this.reloadTasks.emit();
      this._notifyService.showSuccess("Success", "Update");
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "Failure", "Update");
    });
  }

  onClosedLeadOrBorrowerEditorDrawer = () => {
    this.reloadTasks.emit(); // for refresh
  }

  onClosedQcuTaskEditorDrawer = () => {
    this.reloadTasks.emit(); // for refresh
  }

  onClosedTaskTableDynamicDrawerDrawer = () => {
    this.reloadTasks.emit(); // for refresh
  }

  protected onResendEsignNotificationClicked = (task: LoanDocDashboardTask) => {
    this._spinner.show();
    this._borrowersService.getBorrower(task.borrowerId, true).subscribe(borrower => {
      if (!borrower) {
        console.error('Borrower not found for task', task);
        this._notifyService.showError('Borrower not found for task', 'Error');
        return;
      }

      const modalRef = this._modalService.open(TaskEmailSenderModalComponent, Constants.modalOptions.medium);
      const instance = modalRef.componentInstance as TaskEmailSenderModalComponent;
      instance.borrower = borrower.borrower;
      instance.task = task;

      this._taskEmailSenderModalSubscription?.unsubscribe();
      this._taskEmailSenderModalSubscription = from(modalRef.result).pipe(
        catchError((error) => {
          handleNonErrorDismissals(error);
          return EMPTY;
        }),
      ).subscribe(_ => {
        this._notifyService.showSuccess('Reminder successfully sent.', 'Success');
        this.reloadTasks.emit();
      });
    }, (error => {
      this._notifyService.showError(
        getErrorMessageOrDefault(error, {
          defaultMessage: 'An error occurred while getting the borrower for the task.',
        }),
        'Error!'
      );
    })).add(() => this._spinner.hide());
  }

  private addToList = (list, items) => {
    if (this.isNullOrUndefine(items))
      return;
    items.forEach((item) => {
      list.push(item);
    });
  }

  private isNullOrUndefine = (value) => {
    return value === undefined || value === null;
  }

  private initialize = (context: ApplicationContext) => {
    this.globalConfig = context.globalConfig;
    this.documentTypes = context.globalConfig.documentType;
    this.allowBulkActions = context.userPermissions.bulkEditTasks;
    this.canDelete = context.userPermissions.allowDeleteTask;
     const parentElement = this.el.nativeElement.parentElement;
    if (parentElement.firstChild && parentElement.firstChild.tagName.toLowerCase() === 'dashboard-tasks') {
      this.scrollOffset = 370;
    }
    this.getScreenSize();
    this.userId = context.userPermissions.userId;
    this.readOnlyTasks = context.userPermissions.readOnlyTasks;

    this._tasks.forEach(t => {
      const documentType = this.documentTypes.find(dt => dt.documentTypeId === t.documentTypeId);
      t['documentTypeName'] = documentType ? documentType.documentTypeName : "";
    });
  }

  isSnoozed = (task: LoanDocDashboardTask): boolean => {
    return !!task.snoozeDate && (new Date(task.snoozeDate) > new Date()) && task.snoozeDate != task.dueDate;
  }

  isOverdue = (task: LoanDocDashboardTask): boolean => {
    return !task.completeDate && task.dueDate && (new Date(task.dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0));
  }

  deleteFile = (file: FileDto, task: LoanDocDashboardTask) => {
    task.docFiles = task.docFiles.filter(docFile => docFile !== file);
  }

  confirmDeleteTask = (task: LoanDocDashboardTask) => {
    this._spinner.show();
    this._taskService.deleteTask(task.loanDocTaskId).subscribe((response) => {
      this.reloadTasks.emit();
      this._notifyService.showSuccess('Task has been successfully deleted.', 'Success');
    }, (err) => {
      this._notifyService.showError(err ? err.error.message || err : '', 'Error');
    }).add(() => {
      this._spinner.hide();
    })
  }

  isMergeVisible = (task: LoanDocDashboardTask) => {
    return task.docFiles && task.docFiles.filter(x => x.selectedForAction == true).length > 1;
  }

  onFileDeleted = (file: FileDto, task: LoanDocDashboardTask) => {
    const indexOfFileToRemove = task.docFiles.findIndex(f => f.guid === file.guid);
    if (indexOfFileToRemove >= 0) {
      task.docFiles.splice(indexOfFileToRemove, 1);
    }
  }

  onMergeFilesClicked = (task: LoanDocDashboardTask) => {
    this._spinner.show();
    let docGuids = task.docFiles.filter(x => x.selectedForAction == true).map(x => x.guid);
    this._loanDocsService.setMergeFiles(task.applicationId, task.loanDocId, docGuids).subscribe(result => {
      this._spinner.hide();
      task.docFiles = result.docFiles;
      this._notifyService.showSuccess('Successfully merged ' + docGuids.length + ' documents!', 'Success');
    }, err => {
      this._spinner.hide();
      this._notifyService.showError(err.message || err || 'Error while merging documents!', 'Failure');
    })
  }

  onBorrowerClicked = (borrowerId: number, applicationId: number) => {
    this._borrowersService.getBorrower(borrowerId, true).subscribe(borrower => {
      const dynamicComponentInfo = new DynamicComponentInfo();
      dynamicComponentInfo.componentType = BorrowerEditorComponent;
      dynamicComponentInfo.parameters.set("loanId", applicationId);
      dynamicComponentInfo.parameters.set("borrowerId", borrowerId);
      const fullName = Utils.getPersonsDisplayName(borrower.borrower);
      this._drawerService.show("editLeadOrBorrowerEditorDrawerInTasksV2", 100, "Editing Borrower - " + fullName, dynamicComponentInfo);
    });
  }

  onLeadClicked = (leadId: number) => {
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = ViewLeadDrawerComponent;
    dynamicComponentInfo.parameters.set("leadId", leadId);
    dynamicComponentInfo.parameters.set("isDrawer", true);
    if (this.impersonateUserId && this.isLeadTaskFilter) {
      dynamicComponentInfo.parameters.set("impersonateUserId", this.impersonateUserId);
    }
    this._drawerService.show("editLeadOrBorrowerEditorDrawerInTasksV2", 100, "Editing Lead", dynamicComponentInfo);
  }

  viewDocClicked = (url: string, task: LoanDocDashboardTask, file: FileDto) => {
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = TaskLoanDocPreviewComponentV2;
    dynamicComponentInfo.parameters.set("url", url);
    dynamicComponentInfo.parameters.set("task", task);
    dynamicComponentInfo.parameters.set("file", file);


    this._drawerService.show("taskTableDynamicDrawer", 100, "View Doc", dynamicComponentInfo, undefined, false).then(() => {
      const cancelledEventSubscription = this.taskTableDynamicDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("taskTableDynamicDrawer");
        this.drawerHandler();
      });
      this._dynamicEventSubscriptions.push(cancelledEventSubscription);

      const changesApprovedEventSubscription = this.taskTableDynamicDrawer.componentInstance.changesApproved.subscribe((isClosed: boolean) => {
        if (isClosed) {
          this._drawerService.hide("taskTableDynamicDrawer").then(() => {
            this.drawerHandler();
            this._loanDocsService.sendEventDocumentUploaded();
          });
        }
      });
      this._dynamicEventSubscriptions.push(changesApprovedEventSubscription);

      const changesApprovedAndNextRequestedEventSubscription = this.taskTableDynamicDrawer.componentInstance.changesApprovedAndNextRequested.subscribe((dashboardTask: LoanDocDashboardTask) => {
        this._loanDocsService.sendEventDocumentUploaded();
        const updatedTaskIndex = this.tasks.findIndex(t => t.loanDocTaskId === dashboardTask.loanDocTaskId);
        if (updatedTaskIndex >= 0) {
          this.tasks[updatedTaskIndex] = dashboardTask;
        }
        const nextTaskToEdit = this.findNextAvailableTaskForDocEditing(dashboardTask);
        if (!nextTaskToEdit) {
          this._notifyService.showInfo("There are no other tasks with documents requested that are waiting for approval.", "Info");
        } else {
          this.taskTableDynamicDrawer.componentInstance.task = nextTaskToEdit
        }
      });
      this._dynamicEventSubscriptions.push(changesApprovedAndNextRequestedEventSubscription);
    });
  }

  handleHeaderCheckboxToggle() {
    //unselect rows which does not have a selection checkbox in the row.
    this.selectedRows = this.selectedRows.filter(t => !['EsignDocument', 'LosEsign'].includes(t.taskType));
  }

  get isLeadTaskFilter() {
    return this.filterId === 11 || this.filterId === 12;
  }

  docFilesFilter = (task: LoanDocDashboardTask) => {
    return task.docFiles.filter(x => !this.shouldFilter(task) || x.docFileType !== 'Normal')
  }

  shouldFilter = (task) => {
    return task.taskStatus !== 'Pending' && (task.taskType === 'EsignDocument' || task.taskType === 'LosEsign');
  }

  changeFilter = (stat: TaskStats) => {
    this.filterId = stat.id;
    this.changeStat.emit(stat);
  }

  onGlobalSearchStringChanged = () => {
    const globalSearchCache: any = {};
    globalSearchCache.globalSearchString = this.globalSearchString;
    this.filteredTasks = Utils.filter(this.globalFilterFields, this.globalSearchString, this._tasks);
  }

  onTaskNoteHovered = (task: LoanDocDashboardTask) => {
    this.tasks.forEach(row => {
      if (row.loanDocTaskId !== task.loanDocTaskId) {
        row['notesPopoverOpened'] = false;
      }
    });
  }

  private findNextAvailableTaskForDocEditing = (currentTask: LoanDocDashboardTask) => {
    let currentIndex = this.tasks.findIndex(t => t.loanDocTaskId === currentTask.loanDocTaskId);
    if (currentIndex >= 0) {
      const availableTask = this.findAvailableTask(currentIndex, currentIndex);
      if (availableTask) {
        return availableTask
      }
      return false;
    }
  }

  private generateDisplayHtmlWithImages = (displayText: string, task, note) => {
    const regexp = new RegExp(
      /<img class="task-note-image" src="(([\w]|[^a-zA-Z0-9\">])+)/g
    );
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      if (task) {
        task.note = displayText;
      }
      if (note) {
        note.content = displayText;
      }
      return;
    }

    this._utilityService.getImageFileContentAsBase64(results[1], (response) => {
      results[1] = response.changingThisBreaksApplicationSecurity;

      const displayHtml = displayText.replace(
        results[0],
        `<img class="note-image" src="${results[1]}`
      );

      this.generateDisplayHtmlWithImages(displayHtml, task, note);
    })
  };

  private drawerHandler = (): void => {
    const drawerElement = this.taskTableDynamicDrawer.componentInstance.findExistingDrawer();
    if (drawerElement && drawerElement.classList.contains('full-screen-drawer')) {
      drawerElement.classList.remove("full-screen-drawer");
    }
  }

  private findAvailableTask = (index: number, startingIndex: number): LoanDocDashboardTask => {
    let newIndex = index + 1;
    if (newIndex <= this.tasks.length - 1) {
      if (newIndex === startingIndex) {
        return null;
      }
      if (this.tasks[newIndex].taskStatus === 'Submitted' && this.tasks[newIndex].taskType === 'RequestDocument' && this.tasks[newIndex].requestBorrower) {
        return this.tasks[newIndex];
      } else {
        return this.findAvailableTask(newIndex, startingIndex);
      }
    } else {
      newIndex = -1;
      return this.findAvailableTask(newIndex, startingIndex);
    }
  }
}
