import { Component, Injector, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { Constants } from 'src/app/services/constants';
import { ThirdPartyCredential, ThirdPartyCredentialType } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { NotificationService } from '../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../services/system-level.service';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { ScopeType } from '../integrations.component';
import { DoCredentialEditorDialogComponent } from './do-credential-editor-dialog/do-credential-editor-dialog.component';
import { DuCredentialEditorDialogComponent } from './du-credential-editor-dialog/du-credential-editor-dialog.component';
import { LpaCredentialEditorDialogComponent } from './lpa-credential-editor-dialog/lpa-credential-editor-dialog.component';
import { RolesFinalizeDoFindingsModalComponent } from "./do-credential-editor-dialog/roles-finalize-do-findings-modal/roles-finalize-do-findings-modal.component";
import { ChannelService } from 'src/app/services/channel.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'aus',
  templateUrl: './aus.component.html',
  styles: []
})
export class AusComponent extends ApplicationContextBoundComponent {
  @Input() scope: ScopeType;
  @Input() credentialsForDu: ThirdPartyCredential[];
  @Input() credentialsForDo: ThirdPartyCredential[];
  @Input() credentialsForLpa: ThirdPartyCredential[];
  @Input() desktopUnderwriterConfig: Configuration;
  @Input() desktopOriginatorConfig: Configuration;
  @Input() lpaConfig: Configuration;
  @Input() isDesktopUnderwriterEnabledForCompany: boolean;
  @Input() isDesktopOriginatorEnabledForCompany: boolean;
  @Input() isLpaEnabledForCompany: boolean;
  @Input() allowedRolesToFinalizeDOFindingsConfig: Configuration;
  @Input() branchId?: number;
  @Input() externalCompanyId?: number;
  @Input() companyId: number;
  @Input() documentTypes: Array<DocumentType>;

  test = "Enable this feature under <a [routerLink]='['/admin/system-level']' fragment='integrations#aus'>system level settings</a>"

  @Output()
  credentalsUpdated: EventEmitter<any> = new EventEmitter<any>();

  get showAddDUCredsButton(): boolean {
    return this.credentialsForDu?.length === 0;
  }
  get showAddDOCredsButton(): boolean {
    return this.credentialsForDo?.length === 0;
  }
  get showAddLPACredsButton(): boolean {
    return this.credentialsForLpa?.length === 0;
  }

  isTpo: boolean = false;
  channels: EnumerationItem[];

  ausConfiguration: Configuration;
  selectedDocumentTypeId: number;

  private ausConfigKey = "Integration_AUS";
  private ausDocTypeConfigKey = "SavedDocumentTypeId";

  constructor(
    private readonly injector: Injector,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _channelService: ChannelService,
    private readonly _configurationService: ConfigurationService,
    private readonly route: ActivatedRoute,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.isTpo = this.applicationContext.isTpo;
    this.channels = this._channelService.getChannelsListForDropdown(this.applicationContext.userPermissions.enabledChannels);

    if (this.scope == 'Company') {
      this._spinner.show();
      this._configurationService.getCompanyConfiguration(this.ausConfigKey).subscribe({
        next: (config) => {
          this.ausConfiguration = config || new Configuration(this.ausConfigKey, undefined);
          this.selectedDocumentTypeId = this.parseSetDocTypeId(this.ausConfiguration.valueStr);

          if (!this.ausConfiguration.companyId) {
            this.ausConfiguration.companyId = this.companyId;
          }
          if (!this.ausConfiguration.branchId) {
            this.ausConfiguration.branchId = this.branchId;
          }
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't fetch AUS configuration", "System Level");
        }
      })
        .add(() => {
          this._spinner.hide();
        })
    }
  }

  doDuSelectionChanged(type: VendorDoDuType) {
    if (type === 'FannieMae' && this.desktopUnderwriterConfig.value == 1) {
      this.desktopUnderwriterConfig.value = 0;
      this.saveConfiguration(this.desktopUnderwriterConfig);
    } else if (type === 'DU' && this.desktopOriginatorConfig.value == 1) {
      this.desktopOriginatorConfig.value = 0;
      this.saveConfiguration(this.desktopOriginatorConfig);
    }
  }

  openCredentialModal = (e: ThirdPartyCredential, type: VendorType) => {
    const userCompanyGuid = this.route.snapshot.params.id || this.applicationContext.currentlyLoggedInUser.userCompanyGuid;

    e.vendorName = e.vendorName || type;

    const ModalComponent = this.getVendorModalComponent(type);
    const modalRef = this._modalService.open(ModalComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credential = e;
    modalRef.componentInstance.userId = (this.scope === 'TpoUser' || this.scope === 'User') ? userCompanyGuid : null;
    modalRef.componentInstance.branchId = this.branchId || null;
    modalRef.componentInstance.scope = this.externalCompanyId > 0 ? 'TpoUser' : this.scope;
    modalRef.componentInstance.companyId = this.applicationContext.userPermissions.companyId;
    modalRef.componentInstance.channels = this.channels;
    modalRef.componentInstance.onCredentialSaved.subscribe((result) => {
      this.onCredentialSaved(result, type);
    });

    modalRef.result.then(
      (result) => {
        this.saveCredential(result, type);
      },
      () => { }
    );
  }

  openRolesFinalizeDoFindingsModal = () => {
    const modalRef = this._modalService.open(RolesFinalizeDoFindingsModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.allowedRolesToFinalizeDOFindingsConfig = JSON.parse(JSON.stringify(this.allowedRolesToFinalizeDOFindingsConfig));
    modalRef.componentInstance.scope = this.scope;
    modalRef.componentInstance.branchId = this.branchId;

    modalRef.result.then(
      (result) => {
        this.allowedRolesToFinalizeDOFindingsConfig = result;
      },
      () => { }
    );
  }

  onDocTypeChanged = (docTypeId: number) => {
    let configVal = this.ausConfiguration.valueStr ? JSON.parse(this.ausConfiguration.valueStr) : {};
    configVal[this.ausDocTypeConfigKey] = docTypeId;
    this.ausConfiguration.valueStr = JSON.stringify(configVal);

    this._spinner.show();
    this._configurationService.upsertCompanyConfiguration(this.ausConfiguration).subscribe({
      next: (config) => {
        this.ausConfiguration = config || new Configuration(this.ausConfigKey, undefined);
        this.selectedDocumentTypeId = this.parseSetDocTypeId(this.ausConfiguration.valueStr);
      },
      error: (err) => {
        this._notificationService.showError(err?.message || "Couldn't update AUS configuration", "System Level");
      }
    })
      .add(() => {
        this._spinner.hide();
      })
  }

  private parseSetDocTypeId = (valueStr: string): number => {
    if (!valueStr) {
      return null;
    }

    const configs = JSON.parse(valueStr);
    const docTypeId = configs[this.ausDocTypeConfigKey];

    return docTypeId != null ? Number(docTypeId) : null;
  }

  private saveConfiguration(config: Configuration) {
    this._spinner.show();
    this._systemLevelService.saveConfiguration(config)
      .subscribe({
        next: () => {
          // dont need to display successful message that either DU or DO was changed. its already done in toggle-configuration
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't update configuration", "System Level");
        }
      }).add(() => this._spinner.hide());
  }

  private saveCredential(credential, type: VendorType) {
    this._spinner.show();
    this._systemLevelService.saveCredential(credential).subscribe({
      next: (response) => {
        this.onCredentialSaved(response, type);
        this._notificationService.showSuccess(`Credential saved successfully.`, 'Credential');
      },
      error: (err) => {
        this._notificationService.showError(err?.message || "Couldn't save credential", "Credential");
      }
    }).add(() => this._spinner.hide());
  }

  private onDUCredentialSaved(credential) {
    const index = this.credentialsForDu.findIndex(el => el.credentialId === credential.credentialId);
    if (index > -1) {
      this.credentialsForDu[index] = credential;
    } else {
      credential.credentialId = credential.credentialId ?? Utils.getUniqueId();
      this.credentialsForDu.push(credential);
    }
  }

  private onDOCredentialSaved(credential) {
    const index = this.credentialsForDo.findIndex(el => el.credentialId === credential.credentialId);
    if (index > -1) {
      this.credentialsForDo[index] = credential;
    } else {
      credential.credentialId = credential.credentialId ?? Utils.getUniqueId();
      this.credentialsForDo.push(credential);
    }
  }

  private onLPACredentialSaved(credential) {
    const index = this.credentialsForLpa.findIndex(el => el.credentialId === credential.credentialId);
    if (index > -1) {
      this.credentialsForLpa[index] = credential;
    } else {
      credential.credentialId = credential.credentialId ?? Utils.getUniqueId();
      this.credentialsForLpa.push(credential);
    }
  }

  private getVendorModalComponent(type: VendorType) {
    if (type === "DU") return DuCredentialEditorDialogComponent
    if (type === "FannieMae") return DoCredentialEditorDialogComponent
    if (type === "LPA") return LpaCredentialEditorDialogComponent
  }

  private onCredentialSaved(credential, type: VendorType) {
    if (type === "DU") {
      this.onDUCredentialSaved(credential);
    }
    if (type === "FannieMae") {
      this.onDOCredentialSaved(credential);
    }
    if (type === "LPA") {
      this.onLPACredentialSaved(credential);
    }
    const allAusCredentials = [...this.credentialsForDu, ...this.credentialsForDo, ...this.credentialsForLpa];
    this.applicationContextService.updateUserProfileThirdPartyCredentials(allAusCredentials, ThirdPartyCredentialType.AusVendor);
  }
}

type VendorType = "DU" | "FannieMae" | "LPA";
type VendorDoDuType = Exclude<VendorType, "LPA">;
