export class CustomField {
  pricingCustomFieldId: number;
  pricingCustomFieldSectionId: number;
  pricingVendor: string;
  thirdPartyCustomFieldId: string;
  name: string;
  displayName: string;
  description: string;
  type: string = 'List';
  isRequired: boolean = true;
  defaultValue?: string;
  sortOrder: number;
  displayOnly: boolean;
  displayMode: string = 'AlwaysShow';
  dependsOnCustomFieldName: string;
  dependsOnValues: Array<string> = []
  tempUniquId: number;
  options: Array<CustomFieldOption> = []
  pricingCustomFieldSectionName?: string;
  pricingCustomFieldSectionDisplayOrder?: number;
  dependsOnCustomField?: CustomField;
  dependsOnValue?: any;
  isHidden?: boolean;
  constructor(vendor: 'Polly' | 'LenderPrice' | 'MeridianLink' | 'LoanPass' | 'LoanPassIframe'){
    this.pricingVendor = vendor;
    this.tempUniquId = Math.floor(Math.random() * Date.now())
  }
}

export class CustomFieldOption {
  value: number;
  description: string;
}
