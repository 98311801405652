import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user/user.model';
import { Alignment } from '../../models/alignment.model';

@Component({
  selector: 'available-alignments-dialog',
  templateUrl: 'available-alignments-dialog.component.html',
  styleUrls: ['./available-alignments-dialog.component.scss']
})

export class AvailableAlignmentsDialogComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  roles: Role[];

  @Input()
  users: User[];

  @Input()
  selectedUser: User;

  @Input()
  availableAlignments: Alignment[];

  @Input()
  isTpo: boolean = false;

  roleAssignmentToUser: any = {};

  selectedAlignment: Alignment;

  selectedAlignmentId: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (!this.selectedAlignmentId) {
      this.selectedAlignmentId = null;
    }
  }

  onOkClicked = () => {
    this.activeModal.close(this.roleAssignmentToUser);
  }

  showAlignmentDetails = () => {
    if (!this.selectedAlignmentId) {
      this.selectedAlignmentId = null;
      return;
    }

    this.selectedAlignment = this.availableAlignments.find(alignment => alignment.alignmentId === +this.selectedAlignmentId);
    let rolesWithUserId = this.selectedAlignment.usersInAlignment.map(align => [align.roleId, align.userId])
    this.roleAssignmentToUser = Object.fromEntries(rolesWithUserId);
  }
}
