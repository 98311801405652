import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'final-notes',
  templateUrl: './final-notes.component.html',
  styleUrls: ['./final-notes.component.scss']
})
export class FinalNotesComponent implements OnInit {

  @Input()
  internalMessage;

  constructor() { }

  ngOnInit(): void {
  }

}
