import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocPrep } from '../../models/doc-prep.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'document-preparation-loan-info',
  templateUrl: 'document-preparation-loan-info.component.html',
  styleUrls: ['./document-preparation-loan-info.component.scss']
})
export class DocumentPreparationLoanInfoComponent implements OnInit {

  @Input()
  docPrep: DocPrep;

  @Output()
  loanPurposeChanged: EventEmitter<string> = new EventEmitter<string>();

  protected loanPurposeOptions: EnumerationItem[] = [];
  protected loanTypeOptions: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService) {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.loanPurposeOptions = enums[Constants.mortgageEnumerations.loanPurpose];
      this.loanTypeOptions = enums[Constants.mortgageEnumerations.mortgageAppliedForType];
    })
  }

  ngOnInit() {
  }

  onLoanPurposeChanged = () => {
    this.loanPurposeChanged.emit(this.docPrep.loanPurposeType);
  }
}
