import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoanStatus } from 'src/app/models';
import { CheckList } from 'src/app/models/checklist.model';
import { CheckListQuestion, CheckListService } from 'src/app/services/check-list.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { CheckListItemEvalDialogComponent } from '../../dialogs/check-list-item-eval-dialog/check-list-item-eval-dialog.component';
import { finalize, Subscription } from 'rxjs'
import { SearchAppComponent } from 'src/app/shared/components/search-app/search-app.component';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
@Component({
  selector: 'app-checklists-checklist',
  templateUrl: './checklists-checklist.component.html',
  styleUrls: ['./checklists-checklist.component.scss']
})
export class ChecklistsChecklistComponent extends ApplicationContextBoundComponent implements OnInit {

  items: CheckList[] = [];
  selectedColumns: any[] = [];
  columns: any[] = [];
  globalFilterFields: string[] = [];
  loanStatuses: LoanStatus[] = [];
  currentCheckListRecord: CheckList;
  isDeleteClicked = {};
  companyId: number;
  globalConfig: GlobalConfig;
  isLoading: boolean = false;
  superAdmin: boolean = false;
  companyName: string;

  upsertCheckListDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  checkListTreeViewDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _checkListService: CheckListService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector,
    private readonly _drawerService: DrawerService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();

    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.superAdmin = context.userPermissions.superAdmin;
      this.companyId = context.userPermissions.companyId;
      this.globalConfig = context.globalConfig;
      let matchedCompany = context.globalConfig.company.find(c => c.companyGUID == context.currentlyLoggedInUser.userCompanyGuid)
      this.companyName = matchedCompany ? matchedCompany.companyName : null;

      this.loanStatuses = context.globalConfig.loanStatus;

    });

    this.columns = [
      { field: 'checkListName', header: 'Checklist Name', order: 1, visible: true },
      { field: 'loanStatusId', header: 'Loan Status', order: 2, visible: true }
    ]

    this.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });

    this.getCheckListItems();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  onDrawerClose(checklist: CheckList) {
    this._drawerService.hide("upsertCheckListDrawer", 100);
    if (checklist) {
      this.getCheckListItems();
    }
  }

  onTreeViewDrawerClose(checklist: CheckList) {
    this._drawerService.hide("checkListTreeViewDrawer", 100);
    if (checklist) {
      this.getCheckListItems();
    }
  }

  openUpsertDrawer = (rowData: CheckList): void => {
    this.currentCheckListRecord = cloneDeep(rowData || {} as CheckList);
    this._drawerService.show("upsertCheckListDrawer", 100);
  }

  showItemTreeViewDrawer = (item: CheckList) => {
    if (!item) {
      return;
    }
    this._drawerService.hideAll();
    this.currentCheckListRecord = cloneDeep(item);
    this._drawerService.show("checkListTreeViewDrawer", 100);
  }

  showCheckListEvalModal = (checklistQuestions: Array<CheckListQuestion>, applicationId: number, borrowerNameById: Map<number, string>) => {
    if (checklistQuestions.length === 0) {
      this._notifyService.showError('Checklist does not have any valid questions.', 'Eval Failed!');
      return;
    }
    let modalRef = this._modalService.open(CheckListItemEvalDialogComponent, {
      ...Constants.modalOptions.large,
      windowClass: 'myCustomModalClass',
    });
    modalRef.componentInstance.checklistQuestions = checklistQuestions;
    modalRef.componentInstance.applicationId = applicationId;
    modalRef.componentInstance.borrowerNameById = borrowerNameById;
    modalRef.componentInstance.isEvalTest = true;
    modalRef.result.then(() => {
    }, () => {
    });
  }


  showSearchApplicationModal = (item: CheckList) => {
    let modalRef = this._modalService.open(SearchAppComponent, {
      ...Constants.modalOptions.xlarge
    });

    modalRef.result.then((response) => {
      if (response.appId) {
        this.getCheckListQuestions(item.checklistId, response.appId, response.borrowerNameById);
      }
    }, () => {
    });

  }

  getCheckListItems = (): void => {
    this._spinner.show();
    this._checkListService.getAllChecklists()
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (result) => {
          this.items = result;
        }, error: (err) => {
          this._notifyService.showError(err?.message || err.error, "Error!");
        }
      });
  }

  private getCheckListQuestions = (checklistId: number, appId: number, borrowerNameById: Map<number, string>) => {
    this._spinner.show();
    this._checkListService.getChecklistQuestions(checklistId, appId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (checklistQuestions) => {
          this.showCheckListEvalModal(checklistQuestions, appId, borrowerNameById)
        }, error: (err) => {
          this._notifyService.showError(
            err ? err.message : 'Unable to get checklist questions.',
            'Error!'
          );
        }
      });
  }

  onDeleteItemClicked = (questionId: number) => {

    this._checkListService.deleteChecklistItem(questionId)
      .subscribe(() => {
        this._notifyService.showSuccess("Checklist item removed succesfully", "Success");
        this.getCheckListItems();
      }, (err) => {
        this._notifyService.showError(err.error.message || "Error encountered while deleting check list item", "Error!");
      });

  }

  getSelectedLoanStatus = (id: number): LoanStatus => {
    return id ? this.loanStatuses.find(s => s.loanStatusId == id) : null;
  }
}
