<!-- m prefixes used to avoid name conflicts with global css -->

<div class='m-header'>
  <h4 class='m-0'>Calculating Cash to Close</h4>
</div>

<div class='m-table'>
  <div class='table-header'>
    <div class='m-col'>Description</div>
    <div class='m-col'>Amount</div>
  </div>

  <!-- Empty state -->
  <div class='table-row' *ngIf='cashToCloseItems?.length < 1'>
    <div class='m-col'>No items to display</div>
    <div class='m-col'></div>
  </div>

  <!-- Items -->
  <div
    *ngFor='let item of cashToCloseItems; let i = index'
    [class.highlighted]='item.highlighted'
    class='table-row'>
    <div
      class='m-col'
      [class.justify-content-between]='item.templateAction != null'>
      <div class='d-flex align-items-baseline gap-2'>
        <label
          *ngIf='
            tryCastToEditableCashToCloseItem(item);
            else templateDescription
          '
          [for]="id + '-amount-' + i">
          <ng-container *ngTemplateOutlet='templateDescription'></ng-container>
        </label>

        <ng-template #templateDescription>
          {{ item.description }}
        </ng-template>

        <ng-container
          *ngTemplateOutlet='
            templateTooltip;
            context: {$implicit: item.tooltip}
          '>
        </ng-container>
      </div>

      <ng-container *ngIf='editable'>
        <ng-container *ngTemplateOutlet='item.templateAction'></ng-container>
      </ng-container>
    </div>

    <div class='m-col highlighted-if-empty'>
      <currency-input
        (blur)='item.finishEditing()'
        (focusin)='item.startEditing()'
        *ngIf='tryCastToEditableCashToCloseItem(item) as item; else nonEditable'
        [(ngModel)]='item.amount'
        [id]="id + '-amount-' + i"
        [ngModelOptions]='{standalone: true}'
        [readonly]='!editable || !item.editable'></currency-input>

      <ng-template #nonEditable>
        <span class='highlighted-content'>{{ item.amount | currency }}</span>
      </ng-template>
    </div>
  </div>
</div>

<div class='m-footer'>
  <span>
    {{ totalCashToCloseItem.description }}

    <ng-container
      *ngTemplateOutlet='
        templateTooltip;
        context: {$implicit: totalCashToCloseItem.tooltip}
      '>
    </ng-container>
  </span>

  <span>{{ totalCashToCloseItem.amount | currency }}</span>
</div>

<ng-template #templateTooltip let-tooltip>
  <i
    *ngIf='tooltip'
    [ngbTooltip]='tooltip'
    class='fa fa-info-circle text-muted'></i>
</ng-template>

<ng-template #templateCalculateCredits let-sourceType>
  <button
    *ngIf='!(application.channel == ChannelEnum.Wholesale && sourceType == PurchaseCreditSourceType.Lender)'
    (click)='openCalculateCreditsDialog(sourceType)'
    class='btn btn-link p-0'
    title='Calculate Credits'
    type='button'>
    <i class='fa fa-calculator'></i>
  </button>
</ng-template>

<ng-template #templateCalculateLenderCredits>
  <ng-container
    *ngTemplateOutlet='
      templateCalculateCredits;
      context: {$implicit: PurchaseCreditSourceType.Lender}
    '></ng-container>
</ng-template>

<ng-template #templateCalculateSellerCredits>
  <ng-container
    *ngTemplateOutlet='
      templateCalculateCredits;
      context: {$implicit: PurchaseCreditSourceType.PropertySeller}
    '></ng-container>
</ng-template>

<ng-template #templateCalculateOtherCredits>
  <ng-container
    *ngTemplateOutlet='
      templateCalculateCredits;
      context: {$implicit: PurchaseCreditSourceType.Other}
    '></ng-container>
</ng-template>

<ng-template #templateFinanceAll>
  <finance-entire-fee-checkbox
    (valueChange)='onFinanceAllChange($event)'
    [mortgage]='mortgage'>
  </finance-entire-fee-checkbox>
</ng-template>
