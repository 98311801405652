<div class="modal-header modal-header--sticky">
  <h5 class="modal-title"> Products & Pricing </h5>

  <div class="button-group">
    <button type="button" class="btn btn-primary margin-left-5" (click)="viewNotesAndAdvisories()"> View
      Notes/Advisories </button>
    <button type="button" class="btn-close margin-left-5" (click)="activeModal.close()" aria-label="Close"></button>
  </div>

</div>
<div class="modal-body margin-top-4 mb-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9 table-container">
        <table class="table table-striped align-items-center align-middle mb-0">
          <thead>
            <tr>
              <th class="text-center" scope="col">Rate</th>
              <th class="text-center" *ngFor="let lockPeriod of lockPeriods" scope="col">{{lockPeriod + ' day'}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rate of rates">
              <th class="text-center" scope="row">{{rate.toFixed(3)}}</th>
              <td class="text-center" *ngFor="let price of priceViewModelsByRate.get(rate)">
                <div [ngClass]="{'checkbox': price.price}">
                  <input *ngIf="price.price" [(ngModel)]="price.selected"
                    (ngModelChange)="onPriceSelectionChanged(price)" type="checkbox"
                    name="check-{{price.rate + '_' + price.lockPeriod}}"
                    id="check-{{price.rate + '_' + price.lockPeriod}}">
                  <label for="check-{{price.rate + '_' + price.lockPeriod}}">
                    <strong *ngIf="price.price"> {{price.value}} - {{price.payment | currency}}</strong>
                    <strong *ngIf="!price.price"> {{price.value}} </strong>
                    <br/>
                    <span *ngIf="price.price >= 100.00" class="text-muted price-rebate">{{price.rebatePercent}} / {{(price.rebateDollars | currency)}}</span>
                      <span *ngIf="price.price < 100.00" class="text-muted price-discount">({{price.discountPercent}}) / ({{(price.discountDollars | currency)}})</span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-3 price-card-container">
        <selected-price-card #priceCard [upfrontCosts]="upfrontCosts" (deleteRequested)="onSelectedPriceRemovalRequested($event)"
          *ngFor="let selectedPrice of selectedPrices" [selectedPrice]="selectedPrice"></selected-price-card>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer modal-footer--sticky">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()"> OK </button>
</div>
