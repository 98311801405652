import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { LoanDocDashboardTask } from '../models/borrower/loan-doc-dashboard-task.model';
import { ConditionsTask } from '../models/task/conditons-task.model';
import { CustomTask } from '../models/task/custom-task.model';
import { GatherTask } from '../models/task/gather-task.model';
import { Task } from '../models/task/task.model';
import { LoanDocTaskUpsert } from '../models/borrower/loan-doc-task-upsert.model';
import { EscalationLoanDocTask } from '../modules/tpo/models/escalation-loan-doc-task.model';
import { tap } from 'rxjs/operators';

export class TaskNote {
  note?: string;
  borrowerNote?: string;
}

export enum TpoTaskFilterEnum {
  All = "All",
  Outstanding = "Outstanding",
  PendingApproval = "PendingApproval",
  Completed = "Completed"
}

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private readonly _escalationsChanged$ = new Subject<{ appId: number }>();
  public readonly escalationsChanged$ =
    this._escalationsChanged$.asObservable();

  constructor(private readonly _dataService: DataService) { }

  createEscalation = (appId: number, description: string, note: string) => {
    const url = 'api/Task/create-escalation/' + appId;
    return this._dataService.post(url, { note: note, description: description })
      .pipe(
        tap(() => this._escalationsChanged$.next({ appId: appId })),
      );
  }

  getAllEscalations = (appId: number): Observable<EscalationLoanDocTask[]> => {
    const url = 'api/Task/get-all-escalation/' + appId;
    return this._dataService.get(url);
  }

  getTaskDashboardViewById = (loanDocTaskId: number): Observable<LoanDocDashboardTask> => {
    const url = 'api/Task/' + loanDocTaskId + '/GetTaskDashboardViewById';
    return this._dataService.get(url);
  }

  upsertLoanDocTask = (data: LoanDocTaskUpsert): Observable<LoanDocDashboardTask> => {
    const url = 'api/Task/UpsertLoanDocTask/';
    return this._dataService.post(url, data);
  }

  getTaskByLoan = (appId: number) => {
    const url = 'api/Task/get-by-loan/' + appId;
    return this._dataService.get(url);
  }

  getAllTpoTask = (appId: number, state: TpoTaskFilterEnum) => {
    const url = 'api/Task/get-all-tpo/' + appId + '/' + state;
    return this._dataService.get(url);
  }

  getAllConditions = (appId: number): Observable<ConditionsTask[]> => {
    const url = `api/Loan/${appId}/GetAllLoanDocTaskConditions`;
    return this._dataService.get(url);
  }

  getTaskTracking = (loanDocTaskId: number) => {
    const url = 'api/Task/tracking/' + loanDocTaskId;
    return this._dataService.get(url);
  }

  deleteMultiTasks = (data: number[]) => {
    const url = 'api/Task/DeleteMultiTasks/';
    return this._dataService.post(url, data);
  }

  deleteTask = (loanDocTaskId: number) => {
    const url = 'api/Task/' + loanDocTaskId + '/DeleteLoanDocTask';
    return this._dataService.delete(url);
  }

  changeResponsibleMultiTasks = (selectedResponsiblePartyUserId: string, data: number[]) => {
    const url = 'api/Task/ChangeResponsibleMultiTasks/' + selectedResponsiblePartyUserId;
    return this._dataService.post(url, data);
  }

  updateFollowUpBulk = (postponeDate: string, data: number[]) => {
    const url = 'api/Task/UpdateFollowUpBulk?followup=' + postponeDate;
    return this._dataService.post(url, data);
  }

  updateSnoozeBulk = (durationInMinutes: number, data: number[]) => {
    const url = 'api/Task/UpdateSnoozeBulk?minutesToSnooze=' + durationInMinutes;
    return this._dataService.post(url, data);
  }

  setTaskStatus = (taskId: number, type: string) => {
    const url = 'api/Task/' + taskId + '/SetTaskStatus/' + type;
    return this._dataService.post(url, {});
  }

  setConditionStatus = (taskId: number, type: string) => {
    const url = 'api/Task/' + taskId + '/SetConditionStatus/' + type;
    return this._dataService.post(url, {});
  }

  getTpoTaskCount = (appId: number, tpoTaskFilter: string) => {
    const url = 'api/Task/get-count-tpo/' + appId + '/' + tpoTaskFilter;
    return this._dataService.get(url);
  }

  updateFollowUpDate = (loanDocTaskId: number, daysToPostpone: number) => {
    const url = 'api/Task/' + loanDocTaskId + '/Postpone/' + daysToPostpone;
    return this._dataService.post(url, {});
  }

  updateSnoozeDate = (loanDocTaskId: number, minutesToSnooze: number) => {
    const url = 'api/Task/' + loanDocTaskId + '/Snooze/' + minutesToSnooze;
    return this._dataService.post(url, {});
  }

  getGatherPaymentTaskBalance = (loanDocTaskId: number) => {
    const url = 'api/orderPayment/balance?loanDocTaskId=' + loanDocTaskId;
    return this._dataService.get(url);
  }

  postGatherPayment = (loanDocTaskId: number, gatherTask: GatherTask) => {
    const url = 'api/orderPayment?loanDocTaskId=' + loanDocTaskId;
    return this._dataService.post(url, gatherTask);
  }

  getSigningOrder = (loanDocTaskId: number) => {
    return this._dataService.get(`api/Signing/order/${loanDocTaskId}`);
  }

  refreshSigningOrder = (loanDocTaskId: number) => {
    const url = `api/Signing/order/${loanDocTaskId}/refresh`;
    return this._dataService.post(url, {});
  }

  getSigningOrderCombinedContent = (loanDocTaskId: number) => {
    return this._dataService.downloadFile(`api/Signing/order/${loanDocTaskId}/documents/combined/content/view`);
  }

  esignCancelOrder(loanDocTaskId: number, reason: string) {
    const queryParam = reason ? `?cancelReason=${reason}` : '';
    return this._dataService.delete(`api/Signing/tasks/${loanDocTaskId}/order${queryParam}`)
  }

  addReviewRequiredUser = (loanDocTaskId: number, reviewPartyId: string) => {
    const url = 'api/task/' + loanDocTaskId + '/add-review-required-user/' + reviewPartyId;
    return this._dataService.post(url, {});
  }

  linkToLosCondition = (linkRequest) => {
    const url = 'api/task/' + linkRequest.loanDocTaskId + '/link-to-los-condition/';
    return this._dataService.post(url, linkRequest);
  }

  getCustomTaskModelsByType = (characteristicType: string): Observable<CustomTask[]> => {
    const url = `api/Admin/GetCustomTaskModelByType/${characteristicType}`;
    return this._dataService.get(url);
  }

  getAllTasks = (): Observable<Task[]> => {
    const url = `api/Admin/AllTaskModel`;
    return this._dataService.get(url);
  }

  addTaskNote = (loanDocTaskId: number, request: TaskNote) => {
    const url = `api/task/${loanDocTaskId}/addtasknote`;
    return this._dataService.post(url, request);
  }

  sendEsignEmail = (
    condition: ConditionsTask | LoanDocDashboardTask,
    options?: {
      recipientEmailOverride?: string;
      writeOverrideEmailToBorrower?: boolean;
    },
  ) => {
    const params = ([
      ['recipientEmailOverride', options?.recipientEmailOverride],
      ['writeOverrideEmailToBorrower', options?.writeOverrideEmailToBorrower],
    ] as [string, any][]).reduce((acc, [key, value]) => {
      if (value != null) {
        acc.set(key, String(value));
      }
      return acc;
    }, new URLSearchParams()).toString();

    let url = `api/Signing/tasks/${condition.loanDocTaskId}/send-esign-email`;
    if (params) {
      url += '?' + params;
    }
    return this._dataService.post(url, condition);
  }

}
