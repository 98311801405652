import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer, Subscription } from 'rxjs';
import { ShortLinkService } from 'src/app/modules/admin/short-links/services/short-link.service';
import { NotificationService } from 'src/app/services/notification.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

@Component({
  template: ''
})
export class ShortLinkRedirectComponent implements OnInit, OnDestroy {

  private _activatedRouteQueryParamsSubscription: Subscription;

  private _activatedRouteParamsSubscription: Subscription;

  constructor(private readonly _activatedRoute: ActivatedRoute,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _shortLinksService: ShortLinkService) { }

  ngOnInit() {
    this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
      this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
        const shortCode = this._activatedRoute.snapshot.paramMap.get('shortCode');
        if (shortCode) {
          let queryString = "";
          if (queryParams && Object.keys(queryParams).length > 0) {
            queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
          }
          this.getExpandedUrlAndRedirect(shortCode, queryString ? encodeURIComponent(`?${queryString}`) : "");
        }
      });
    });
  }

  private getExpandedUrlAndRedirect(shortCode: string, queryString: string) {
    const observer: Observer<any> = {
      next: (redirectResponse: { url: string }) => {
        window.location.href = redirectResponse.url;
      },
      error: (error: Error) => {
        this._notificationService.showError(
          getErrorMessageOrDefault(error, {
            defaultMessage: 'Error getting the original URL from the short link.',
          }),
          'Error!'
        );
      },
      complete: () => {
      }
    }

    this._spinner.show();
    this._shortLinksService.getShortLinkRedirectUrl(shortCode, queryString).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }

  ngOnDestroy(): void {
    this._activatedRouteParamsSubscription?.unsubscribe();
    this._activatedRouteQueryParamsSubscription?.unsubscribe();
  }
}
