<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="loanDocBeingEdited" id="editLoanDoc">
  <div class="row">
    <form #loanDocForm="ngForm" novalidate id="loanDocForm" name="loanDocForm">
      <div class="col-md-12">
        <div class="alert alert-warning warning-mesages" style="margin-top: 15px;">
          <span> Please note... Adding a document here will <b>not</b> complete the required
            task. If this document corresponds to a task, please close this window and upload it to the task
            directly.
          </span>
        </div>
      </div>
      <div class="col-md-12 px-3">
        <div class="form-group row">
          <label class="form-label align-self-center mb-lg-0"> Doc Type </label>
          <div>
            <ng-select2 class="select2 mb-3 custom-select" name="docType"
              [(ngModel)]="loanDocBeingEdited.documentTypeId" [options]="optionsSelect" [allowClear]="true">
              <option *ngFor="let docType of filteredDocumentTypesForEdit" value="{{docType.documentTypeId}}"
                [selected]="loanDocBeingEdited.documentTypeId == docType.documentTypeId">
                {{docType.documentTypeName}}
              </option>
            </ng-select2>
          </div>
        </div>
        <div class="form-group row">
          <label class="form-label align-self-center mb-lg-0"> Description </label>
          <div>
            <input class="form-control" placeholder="Description" type="text" #description="ngModel"
              [(ngModel)]="loanDocBeingEdited.description" name="description" [required]="false"
              [ngClass]="{'is-invalid' : description && description.touched  && description.invalid}">
            <div class="invalid-feedback px-3"> Please enter a description. </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="form-label mb-lg-0"> Note </label>
          <div>
            <textarea class="form-control" placeholder="Add a note" id="note" name="note"
              [(ngModel)]="loanDocBeingEdited.note" type="text" rows="4"></textarea>
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="form-label align-self-center mb-lg-0"> Expiration Date </label>
          <div class="col-sm-6">
            <input type="date" class="form-control" autocomplete="off" id="expirationDate" name="expirationDate"
              [ngModel]="loanDocBeingEdited.expirationDate | date: dateFormat"
              (ngModelChange)="loanDocBeingEdited.expirationDate = $event" min="{{currentDate | date: dateFormat}}">
          </div>
        </div> -->
        <div class="form-group row">
          <label class="form-label mb-lg-0"> Files </label>
          <div class="col-sm-12 d-flex">
            <div class="col-sm-3">
              <input type="file" id="loanDocFile" name="loanDocFile" style="display:none"
                (change)="onDocFilesChanged($event)" multiple="true" />
              <button class="btn btn-primary" onclick="$('#loanDocFile').click()">Add file</button>
              <span class="ms-4"> OR </span>
            </div>
            <div class="form-group row col-sm-9" style="border: 2px dashed #889988">
              <file-upload [(ngModel)]="uploadedNewFiles" multiple="true" name="files"
                (ngModelChange)="onFileUploadChanged(uploadedNewFiles)">
              </file-upload>
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="loanDocBeingEdited.docFiles.length == 0 && isCheckedAfterSaveProcess">
          <div class="alert alert-danger">
            <span> Please make sure you add at least one file.
            </span>
          </div>
        </div>
        <div class="form-group row" *ngIf="loanDocBeingEdited.docFiles.length">
          <table class="tblFile text-center">
            <tr>
              <th> File name </th>
              <th> Note </th>
              <th> Borrower Note </th>
              <th> Create Date </th>
              <th> Action </th>
            </tr>
            <tr *ngFor="let docFile of loanDocBeingEdited.docFiles; let index = index">
              <td>
                <a (click)="onOpenLoanDocClicked(docFile)" [ngClass]="{'text-decoration-line-through': !docFile.active}"
                  title="Show the Document"> {{docFile.fileName}}
                </a>
              </td>
              <td>
                <input *ngIf="docFile.active" class="form-control" name="note_{{index}}" [(ngModel)]="docFile.note"
                  type="text">
                <span *ngIf="!docFile.active"> {{docFile.note}} </span>
              </td>
              <td> {{docFile.borrowerNote}} </td>
              <td> {{docFile.createDate | date:'MM/dd/yyyy h:mma'}} </td>
              <td>
                <div *ngIf="docFile.active">
                  <span *ngIf="docFile.guid != null">
                    <a href="javascript:void(0);" (click)="onDownloadDocumentClicked(docFile)" title="Download">
                      <i class="fas fa-download"></i>
                    </a>
                    <span class="text-muted mx-1">|</span>
                    <a href="javascript:void(0);" (click)="onDeleteFileClicked(index)"
                      *ngIf="activeDeletionIndex != index">
                      <i class="fas fa-trash-alt"></i>
                    </a>
                    <a *ngIf="activeDeletionIndex === index" href="javascript:void(0);" class="table-icon-button mx-2"
                      (click)="onDeleteFileCancelClicked()">
                      <i class="fa fa-times danger"></i>
                    </a>
                    <a *ngIf="activeDeletionIndex === index" href="javascript:void(0);" class="table-icon-button"
                      (click)="onDeleteFileConfirmClicked(docFile)">
                      <i class="fa fa-check-circle danger"></i>
                    </a>
                  </span>
                  <span *ngIf="docFile.guid == null">
                    <a class="btn btn-danger" (click)="removeFileFormLoanDoc(loanDocBeingEdited.docFiles, index)">
                      Remove
                    </a>
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()"> Cancel </button>
  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()"> <i
      class="fas fa-save me-1 text-white"></i> Save
  </button>
</div>