import { Component, Injector, Input, OnInit } from '@angular/core';
import { ThirdPartyCredential, ThirdPartyCredentialArray, ThirdPartyCredentialSchemaReadonlyArray, ThirdPartyCredentialType } from '../../../../../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../../../../../services/constants';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import Swal, {SweetAlertResult } from 'sweetalert2';
import { NotificationService } from '../../../../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import * as _ from 'lodash';
import { CredentialModalV2Component } from '../credential-modal-v2/credential-modal-v2.component';
import { ScopeType } from '../../integrations.component';

@Component({
    selector: 'credential-item-v2',
    templateUrl: './credential-item-v2.component.html',
    styleUrls: ['./credential-item-v2.component.scss']
})
export class CredentialItemV2Component extends ApplicationContextBoundComponent implements OnInit {
    
    @Input()
    credential: ThirdPartyCredential;

    @Input()
    credentials: ThirdPartyCredentialArray;

    @Input()
    isFail: boolean = false;

    @Input()
    scope: ScopeType;

    @Input()
    credentialSchema: ThirdPartyCredentialSchemaReadonlyArray = [];

    @Input()
    companyCredentials?: ThirdPartyCredentialArray = []; // user type
    
    @Input()
    userCompanyGuid?: string;

    @Input()
    isTpoUser: boolean | null;
  
    @Input()
    saveAndTest: boolean | null;

    credentialTitle: string;

    constructor(
        private readonly injector: Injector,
        private readonly _modalService: NgbModal,
        private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
        private readonly _notificationService: NotificationService,
        private readonly _spinner: NgxSpinnerService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.setCredentialTitle();
    }

    credentialModal(): void {
        const modalRef = this._modalService.open(CredentialModalV2Component, Constants.modalOptions.large);
        modalRef.componentInstance.credential = _.cloneDeep(this.credential);
        modalRef.componentInstance.credentialSchema = _.cloneDeep(this.credentialSchema);
        modalRef.componentInstance.companyCredentials = _.cloneDeep(this.companyCredentials);
        modalRef.componentInstance.userCompanyGuid = _.clone(this.userCompanyGuid);
        modalRef.componentInstance.scope = this.scope;
        modalRef.componentInstance.isTpoUser = this.isTpoUser;
        modalRef.componentInstance.saveAndTest = this.saveAndTest;
        modalRef.componentInstance.onCredentialUpserted.subscribe(result => {
            this.onCredentialUpdated(result);
        })
        
        modalRef.result.then(
            (result) => {
                this.onCredentialUpdated(result);
            },
            () => {}
        );
    }

    setCredentialTitle(): void {
        this.credentialTitle = this._thirdPartyCredentialsService.getVendorDisplayName(this.credential.vendorName);
    }

    deleteCredentialsConfirmation(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete credentials for ${this.credential.credentialType} - ${this.credential.vendorName}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result: SweetAlertResult) => {
            if (!result.value) {
                return;
            }

            this.deleteCredential();
        });
    }

    private deleteCredential() {
        this._spinner.show();

        const observer = this.scope === "Company" ?
            this._thirdPartyCredentialsService.deleteCredentialsForCompany(this.credential.credentialId) :
            this._thirdPartyCredentialsService.deleteCredentialsForUser(this.credential.credentialId);

        observer.subscribe({
            next: res => {
                this.onCredentialDeleted(this.credential);
                this._notificationService.showSuccess(`Credential deleted successfully.`, 'Credential');
            },
            error: error => {
                this._notificationService.showError(`${error ? error.message : 'Unable to delete.'}`, 'Credential');
            }
        }).add(() => this._spinner.hide());
    }

    private onCredentialUpdated(credential: ThirdPartyCredential) {
        this.credential = _.cloneDeep(credential);
        this.setCredentialTitle();
    }

    private onCredentialDeleted(credential: ThirdPartyCredential) {
        const index = this.credentials.findIndex(c => c.credentialId === credential.credentialId);
        if (index === -1) return;

        this.credentials.splice(index, 1);

        if (credential.userId && (credential.userId === this.applicationContext.currentlyLoggedInUserProfile.userProfile.userCompanyGuid)) {
            this.applicationContextService.updateUserProfileThirdPartyCredentials(this.credentials, this.credential.credentialType as ThirdPartyCredentialType);
        }
    }
}
