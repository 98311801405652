<div class="modal-header">
  <h6 class="modal-title">Update Address Info</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <form class="form-horizontal auth-form" #addressUpdateForm="ngForm" novalidate id="addressUpdateForm" name="addressUpdateForm">
    <div class="row">
      <span>It appears some parts of the address info required to pull Ernst Fees is missing or invalid.</span>
      <span>Please update the subject property address information.</span>
    </div>
    <br>
    <div class="row">
      <div class="col-sm">
        <label class="control-label">City</label>
        <input type="text" class="form-control" id="city" name="city"
               [ngClass]="{ 'is-invalid' : city && city.touched && city.invalid }"
               [(ngModel)]="subjectProperty.city" #city="ngModel" required />
        <div class="invalid-feedback">City is required</div>
      </div>
      <div class="col-sm">
        <label class="control-label">State</label>
        <select class="form-control " id="state" [(ngModel)]="subjectProperty.state" name="state">
          <option [ngValue]="null"></option>
          <option *ngFor="let option of stateOptions" value="{{ option.value }}">
            {{ option.name }}
          </option>
        </select>
        <div class="invalid-feedback">State is required</div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm">
        <label class="control-label">County</label>
        <input type="text" class="form-control" id="county" name="county"
               [ngClass]="{ 'is-invalid' : county && county.touched && county.invalid }"
               [(ngModel)]="subjectProperty.county" #county="ngModel" required />
        <div class="invalid-feedback">County is required</div>
      </div>
      <div class="col-sm">
        <label class="control-label">Zip Code</label>
        <zip-code-input
          [(ngModel)]="subjectProperty.zipCode" [name]="'subjectPropertyZip'"
          [required]="true" [disabled]="false" [readonly]="false"
          (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'subjectProperty')"></zip-code-input>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="apply()">Apply</button>
</div>
