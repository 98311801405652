import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { ApplicationContextService } from "src/app/services/application-context.service";
import { Subscription } from "rxjs";

@Component({
  selector: "enable-sms-alerts-toggle",
  templateUrl: "enable-sms-alerts-toggle.component.html",
  styleUrls: ["enable-sms-alerts-toggle.component.scss"],
})
export class EnableSmsAlertsToggleComponent implements OnInit, OnDestroy {
  @Input()
  phone: number;

  @Input()
  enableSms: boolean;

  @Input()
  type: string; //ex. 'Agent' or 'Borrower'

  @Output()
  enableSmsToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  smsEnabledForUser: boolean;

  private _applicationContextSubscription: Subscription;

  constructor(private readonly _applicationContext: ApplicationContextService) { }

  ngOnInit(): void {
    this._applicationContextSubscription = this._applicationContext.context.subscribe((context) => {
      this.smsEnabledForUser = context.userPermissions?.smsAlertsEnabled;
    });
  }

  ngOnDestroy(): void {
    this._applicationContextSubscription?.unsubscribe();
  }

  onEnableSmsToggled() {
    this.enableSmsToggled.emit(this.enableSms);
  }
}
