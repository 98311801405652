import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { PollyKeyValuePairs } from '../pricing.component';
import { chain, cloneDeep, isArray, orderBy } from 'lodash';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { combineLatest } from 'rxjs';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { Constants } from 'src/app/services/constants';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { PricingCustomFieldSectionsComponent } from '../pricing-custom-field-sections/pricing-custom-field-sections.component';

@Component({
  selector: 'polly-vendor-editor-dialog',
  templateUrl: 'polly-vendor-editor-dialog.component.html',
  styleUrls: ['./polly-vendor-editor-dialog.component.scss'],
})

export class PollyVendorEditorDialogComponent implements OnInit {

  @ViewChild("pollyForm")
  pollyForm: NgForm;

  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  scope: 'User' | 'Branch' | 'TpoUser' | 'ExternalCompanyBranch';

  @Input()
  userCompanyGuid: string

  @Input()
  inEditMode: boolean = true;

  @Input()
  availableBusinessChannels: ThirdPartyKeyValue[] = [];

  @Input()
  branchId?: number;

  customFields: CustomField[] = [];
  pollyKeyValuePairs: PollyKeyValuePairs = new PollyKeyValuePairs();
  businessChannels: ThirdPartyKeyValue[] = [];
  loadingCustomFields: boolean;
  orginalCustomFields: CustomField[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _pricingCustomFieldService: PricingCustomFieldService) { }

  ngOnInit() {
    if (!this.vendor.url) {
      this.vendor.url = ['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(this.scope) ? '' : 'https://api.prod.polly.io';
    }
    const clientIdKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientId');
    if (clientIdKeyValuePair) {
      this.pollyKeyValuePairs.clientId = clientIdKeyValuePair.value;
    }
    const clientSecretKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientSecret');
    if (clientSecretKeyValuePair) {
      this.pollyKeyValuePairs.clientSecret = clientSecretKeyValuePair.value;
    }
    const tickerSymbolKeyValuePairs = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'TickerSymbol');
    this.pollyKeyValuePairs.tickerSymbol = tickerSymbolKeyValuePairs?.value || '';

    this.businessChannels = chain(this.vendor.thirdPartyKeyValuePairs)
      .cloneDeep()
      .filter(el => el.key.startsWith('Channel:'))
      .map(el => {
        el.key = el.key.replace('Channel:', '');
        return el;
      })
      .value();
  }

  saveCredential = () => {
    this.pollyForm.form.markAllAsTouched();
    if (!this.pollyForm.form.valid) {
      return;
    }
    if (this.scope === 'User' || this.scope === 'TpoUser') {
      this.vendor.userId = this.userCompanyGuid;
    } else if (['Branch', 'ExternalCompanyBranch'].includes(this.scope)) {
      this.vendor.branchId = String(this.branchId);
    } else {
      let clientIdKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientId');
      if (!clientIdKeyValuePair) {
        clientIdKeyValuePair = new ThirdPartyKeyValue("ClientId", this.pollyKeyValuePairs.clientId);
        this.vendor.thirdPartyKeyValuePairs.push(clientIdKeyValuePair);
      } else {
        clientIdKeyValuePair.value = this.pollyKeyValuePairs.clientId;
      }

      let clientSecretKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ClientSecret');
      if (!clientSecretKeyValuePair) {
        clientSecretKeyValuePair = new ThirdPartyKeyValue("ClientSecret", this.pollyKeyValuePairs.clientSecret);
        this.vendor.thirdPartyKeyValuePairs.push(clientSecretKeyValuePair);
      } else {
        clientSecretKeyValuePair.value = this.pollyKeyValuePairs.clientSecret;
      }

      let tickerSymbolKeyValuePairs = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'TickerSymbol');
      if (!tickerSymbolKeyValuePairs) {
        const tickerSym = this.pollyKeyValuePairs.tickerSymbol ? this.pollyKeyValuePairs.tickerSymbol.toUpperCase() : '';
        tickerSymbolKeyValuePairs = new ThirdPartyKeyValue("TickerSymbol", tickerSym);
        this.vendor.thirdPartyKeyValuePairs.push(tickerSymbolKeyValuePairs);
      } else {
        tickerSymbolKeyValuePairs.value = this.pollyKeyValuePairs.tickerSymbol;
      }
    }
    //remove all Channel Key Value Pairs first then add those from local array.
    this.vendor.thirdPartyKeyValuePairs = this.vendor.thirdPartyKeyValuePairs.filter(kvp => !kvp.key.startsWith('Channel:'));

    this.businessChannels.forEach(bc => {
      this.vendor.thirdPartyKeyValuePairs.push({
        ...bc,
        key: `Channel:${bc.key}`,
        value: ['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(this.scope) ? '1' : bc.value
      });
    });
    const observer = {
      next: ((result: any) => {
        this.activeModal.close(isArray(result) ? result[0] : result);
        this._spinner.hide();
        this._notificationService.showSuccess(
          'Vendor successfuly updated',
          'Success!'
        );
      }),
      error: ((error: any) => {
        this._spinner.hide();
        this._notificationService.showError(
          error?.message || 'Unable to update Vendor',
          'Failure!'
        );
      })
    }
    this._spinner.show();
    const obs: any = [
      this._systemLevelService.saveCredential(this.vendor)
    ];
    if (!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(this.scope)) {
      this.customFields.forEach((cf: CustomField, index: number) => {
        cf.sortOrder = index;
        if (cf.options?.length > 0 && cf.type !== 'List') {
          cf.options = [];
        }
        if (cf.pricingCustomFieldId) {
          obs.push(this._pricingCustomFieldService.updateCustomField(cf));
        } else {
          obs.push(this._pricingCustomFieldService.insertCustomField(cf));
        }
      });
      const deletedCustomFields = this.orginalCustomFields.filter(o => !this.customFields.map(c => c.pricingCustomFieldId).includes(o.pricingCustomFieldId));
      deletedCustomFields.forEach(del => obs.push(this._pricingCustomFieldService.deleteCustomField(del?.pricingCustomFieldId)));
    }
    combineLatest(obs).subscribe(observer);
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.Polly;
  }

  addBusinessChannel(): void {
    this.businessChannels.push({
      key: '',
      value: '',
      alias: '',
      userName: '',
      password: ''
    });
  }

  trackByIndex(index: number): number {
    return index;
  };

  onCustomFieldsMappingClicked = () => {
    const modalRef = this._modalService.open(PricingCustomFieldSectionsComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = this.vendor.vendorName;
  }
}
