<div class="h-100 d-flex flex-column">
  <div class="row border py-3" [hidden]="isDrawer">
    <div class="col-md-6">
      <h5>Tags</h5>

      <ng-multiselect-dropdown [placeholder]="'-- Select --'" [settings]="multiSelectSettings" [data]="allLeadLists"
        [(ngModel)]="leadLists" (onSelect)="selectTag($event)" (onDeSelect)="deSelectTag($event)">
      </ng-multiselect-dropdown>

      <div class="input-group mt-2">
        <input type="text" class="form-control" placeholder="New Tag" [(ngModel)]="newListTag" #model="ngModel" />
        <span class="input-group-append">
          <button class="btn btn-soft-primary border" type="button" [disabled]="!newListTag" (click)="addNewTag()">
            Add New Tag
          </button>
        </span>
      </div>

    </div>

    <div class="col-md-6">
      <task-stats-card [taskStatsGroup]="taskGroup" (statSelected)="statSelected($event)"></task-stats-card>
    </div>
  </div>

  <div class="flex-grow-1">
    <div class="row pt-2 h-100 d-flex flex-column" *ngIf="lead">
      <ul ngbNav [(activeId)]="selectedTab" class="nav-tabs pe-0" #nav="ngbNav" (navChange)="navChange($event)">
        <li [ngbNavItem]="1" *ngIf="isDrawer">
          <a ngbNavLink>Details</a>
          <ng-template ngbNavContent>
            <lead-editor #leadEditor [lead]="lead" [isManualDial]="isManualDial" (leadUpdated)="onLeadUpdated($event)"
              (closeDrawer)="onLeadEditorDrawerClosed()" (phoneClicked)="dialClicked($event)">
            </lead-editor>
          </ng-template>
        </li>

        <li [ngbNavItem]="6">
          <a ngbNavLink>Monster Insights <span class="badge badge-soft-success ms-auto">New</span>
          </a>
          <ng-template ngbNavContent>
            <div>
              <button class="btn btn-sm btn-outline-primary pull-right mb-1" (click)="generateMonsterInsightsUrl()">{{lead.monsterInsightsUrl ? 'Regenerate' : 'Generate'}} Insights Url</button>
            </div>
            <div style="height: 100vh;" *ngIf="lead.monsterInsightsUrl">
              <iframe height="100%" width="100%" loading="lazy" allowfullscreen 
                referrerpolicy="no-referrer-when-downgrade" [src]="lead.monsterInsightsUrl | safeUrl">
              </iframe>
            </div>
            <div class="center-message" style="height: 100vh; text-align: center;" *ngIf="!lead.monsterInsightsUrl">
              <h4>Monster Insights is not available for this address at this time.
              </h4>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="isDrawer ? '2' : '1'">
          <a ngbNavLink>Activity</a>
          <ng-template ngbNavContent>
            <lead-activity [recordTypeId]="lead.leadId" [recordTypeStatusId]="lead.leadStatusId"
              [mobilePhone]="lead.mobilePhone" [isReloadRequired]="isActivityAdded"></lead-activity>
          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink>Email/SMS History</a>
          <ng-template ngbNavContent>
            <lead-email-history [lead]="lead" [isReloadRequired]="isActivityAdded"></lead-email-history>
          </ng-template>
        </li>

        <li [ngbNavItem]="4">
          <a ngbNavLink>Appointments</a>
          <ng-template ngbNavContent>
            <appointments [recordType]="'Lead'" [recordTypeId]="lead.leadId"></appointments>
          </ng-template>
        </li>

        <li [ngbNavItem]="5">
          <a ngbNavLink>Tasks ({{taskGroup.stats[0].value}}/{{taskGroup.stats[1].value}}/{{taskGroup.stats[2].value}})
          </a>
          <ng-template ngbNavContent>
            <lead-tasks #leadTasks [lead]="lead" [impersonateUserId]="impersonateUserId"
              [filterId]="selectedTaskFilterId" [taskStats]="taskGroup.stats"
              (taskStatusUpdated)="taskStatusUpdatedTrigger($event)"></lead-tasks>
          </ng-template>
        </li>

        <li ngbDropdown class="nav-item">
          <a href (click)="(false)" class="nav-link" ngbDropdownToggle>Actions
            <i class="mdi mdi-chevron-down" style="font-size: 13px !important"></i></a>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="sendEmailClicked()">
              Send Email{{ smsEnabled ? '/SMS' : '' }}
            </button>
            <button ngbDropdownItem (click)="logAttemptedContactClicked()">
              Log Attempted Contact
            </button>
          </div>
        </li>

        <li class="ms-auto">
          <div class="input-group align-items-center">
            <label for="leadStatus" class="align-self-center me-2">Lead Status:</label>
            <select id="leadStatus" name="leadStatus" class="form-select me-2" [(ngModel)]="lead.leadStatusId"
              [disabled]="!lead.leadId">
              <option [ngValue]="null">-- Select One --</option>
              <option *ngFor="let leadStatus of leadStatuses" [ngValue]="leadStatus.loanStatusId">
                {{ leadStatus.loanStatusName }}
              </option>
            </select>

            <div class="input-group-append">
              <button class="btn btn-soft-primary" (click)="updateLeadStatus()"
                [disabled]="updatingStatus || !lead.leadId || !lead.leadStatusId">
                <span *ngIf="!updatingStatus"><i class="fa fa-save me-2"></i> Save Status</span>
                <span *ngIf="updatingStatus">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Saving
                </span>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2 px-0"></div>
    </div>
  </div>

</div>
