import { IHaveName } from "src/app/models/have-name.interface";
import { Agent } from "../../app-details/models/agent.model";
import { AgentType } from "../../admin/company/models/agent-type.model";

export class ExternalContact implements IHaveName {
  externalContactId: number;
  applicationId: number;
  agentId: number;
  agentTypeId: number;
  agentType: AgentType;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  workPhone: string;
  extPhone: string;
  companyName: string;
  title: string;
  email: string;
  orgName: string;
  fax: string;
  fullName?: string;
  mailingStreet: string;
  mailingPOBox: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  mailingCountry: string;
  assistantName: string;
  assistantPhone: string;
  assistantEmail: string;
  companyAddress1: string;
  companyAddress2: string;
  companyCity: string;
  companyState: string;
  companyZip: string;
  companyCountry: string;
  companyNMLSId: string;
  companyPhone: string;
  suffix: string;
  mortgagePartyType: string;
  trustName: string;
  trustDate: string;
  entityName: string;
  mortgageEntityType: string;
  signers: MortgageSigner[];
  powerOfAttorney: boolean;
  powerOfAttorneyName: string;

  userId: string;
  agent: Agent;
  maritalStatus: string;
  department: string;
  nmlsId: string;
  stateLicenseId: string;
  stateLicenseState: string;
  stateLicenseIssueDate: string;
  stateLicenseExpirationDate: string;
  companyStateLicenseId: string;
  companyStateLicenseState: string;
  companyStateLicenseIssueDate: string;
  companyStateLicenseExpirationDate: string;
  companyExtPhone: string;
  companyFax: string;
  companyEmail: string;
  companyWebsite: string;
  companyEmployerIdentificationNumber: string;
  lenderOwnershipShare: number;
  natureOfRelationshipWithLender: string;
  extraData: any;

  ivesParticipantIdentifier: string;
  ivesSecuredObjectRepositoryIdentifier: string;
  appraisalSubCommitteeAppraiserIdentifier: string;
  fhaAgentCompanyIdentifier: string;
  fhaAgentIdentifier: string;

  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;
}

export class MortgageSigner {
  name: string;
  title: string;
  noSignerInformation: boolean;
}
