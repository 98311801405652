import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ExternalCompany, Role } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { AlignmentRoleUsers } from '../../../models/process-submission.model';

@Component({
  selector: 'import-fannie',
  templateUrl: './import-fannie.component.html',
  styleUrls: ['./import-fannie.component.scss']
})
export class ImportFannieComponent implements OnInit {
  @ViewChild('importfannieForm') importfannieForm: NgForm | undefined;

  @Input() globalConfig: GlobalConfig;
  @Input() enabledChannels: Array<EnumerationItem>;
  @Input() externalCompanies: ExternalCompany[];
  @Input() availableUsers: User[];
  @Input() selectedChannel: string;
  @Input() selectedCompanyId: number | '';
  @Input() firstRole: Role;
  @Input() userCompanyGuid: string;
  @Input() processingFannie: boolean;
  @Input() isTpoUser: boolean;
  @Input() tpoEnabledRoles: Role[] = [];

  @Output() processFannie = new EventEmitter();

  casefileId: string;
  primaryRoleUserId: string = '';
  avaliableSecondaryRoles: Role[] = [];
  tpoAlignmentRoleUsers: AlignmentRoleUsers = {};
  availableRoleUsers: User[] = [];

  constructor() { }

  ngOnInit(): void {
    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';

    this.firstRole = this.getFirstRoleByChannel(this.selectedChannel);

    if (this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    this.availableRoleUsers = cloneDeep(this.availableUsers);
  }

  importFannie() {
    this.importfannieForm.form.markAllAsTouched();
    if (this.importfannieForm.form.valid) {
      var data = {
        channel: this.selectedChannel,
        casefileId: this.casefileId,
        primaryRoleUserId: this.primaryRoleUserId,
        additionalAlignmentRoleUsers: this.tpoAlignmentRoleUsers
      }
      this.processFannie.emit(data);
    }
  }

  handleChannelChange = (newVal: string) => {
    this.selectedChannel = newVal;
    this.selectedCompanyId = '';

    if (newVal == 'Wholesale' || newVal == 'Correspondent' || newVal == 'NonDelegatedCorrespondent') {
      this.availableRoleUsers = [];
      this.primaryRoleUserId = '';
    }

    this.firstRole = this.getFirstRoleByChannel(newVal);

    if (this.isTpoUser && this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }
    
    if (newVal != 'Wholesale')
      this.availableRoleUsers = cloneDeep(this.availableUsers);

    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
  }



  handleCompanyChange = (externalCompanyId: number) => {
    this.selectedCompanyId = externalCompanyId;
    if (this.selectedCompanyId) {
      this.availableRoleUsers = this.availableUsers.filter(x => x.externalCompanyId == this.selectedCompanyId);
    } else {
      this.availableRoleUsers = [];
    }
    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
  }

  private getFirstRoleByChannel = (channel: string) => {
    if (!channel) {
      return this.globalConfig.firstRole;
    }
    return this.globalConfig.channelRoles[channel.toLowerCase()][0];
  }
}
