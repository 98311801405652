export class Address {
  addressId?: null | number;
  address1?: null | string;
  address2?: null | string;
  city?: null | string;
  state?: null | string;
  zipCode?: null | string;
  country?: null | string;
  county?: null | string;
  active?: null | boolean;
  companyId?: null | number;
  insertedBy?: null | string;
  dateInserted?: null | string;
  updatedBy?: null | string;
  dateUpdated?: null | string;

  constructor() {
  }
}

export class ResidencyAddress {
  residencyAddressId?: null | number;
  borrowerId?: null | number;
  addressId?: null | number;
  residencyBasis?: null | ResidencyBasis;
  residencyType?: null | ResidencyType;
  durationYears?: null | number;
  durationMonths?: null | number;
  fromCreditReport?: null | boolean;
  address?: null | Address;
  companyId?: null | number;
  insertedBy?: null | string;
  dateInserted?: null | string;
  updatedBy?: null | string;
  dateUpdated?: null | string;
  rent?: number | null;

  constructor() {
    this.address = new Address();
  }
}

export enum ResidencyBasis {
  Own = 'Own',
  Rent = 'Rent',
  LivingRentFree = 'residencyBasis',
  Unknown = 'Unknown',
}

export enum ResidencyType {
  PresentAddress = "PresentAddress",
  FormerAddress = "FormerAddress",
  MailingAddress = "MailingAddress",
}
