import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { PricingConfiguration } from '../models/pricing/pricing-configuration.model';
import { BasicPropertyDetail } from '../models/pricing/basic-property-detail.model';
import { PricingEngineVendor } from '../../admin/tpo-config/models/configuration-product.model';

@Injectable()
export class PricingConfigurationService {
  constructor(private readonly _dataService: DataService) { }

  getConfig = (credentialId: number): Observable<PricingConfiguration> => {
    let url = `api/admin/pricing/config/${credentialId}`;
    return this._dataService.get(url);
  }

  updateConfig = (credentialId: number, config: PricingConfiguration): Observable<PricingConfiguration> => {
    const url = `api/admin/pricing/config/${credentialId}`;
    return this._dataService.post(url, config);
  }

  getFields = (): Observable<BasicPropertyDetail[]> => {
    let url = `api/admin/pricing/config/fields`;
    return this._dataService.get(url);
  }

  deleteConfig = (pricingConfigId: number): Observable<any> => {
    let url = `api/admin/pricing/config/${pricingConfigId}`;
    return this._dataService.delete(url);
  }

  getConfigByVendor = (vendor: PricingEngineVendor): Observable<PricingConfiguration> => {
    let url = `api/admin/pricing/config/vendor/${vendor}`;
    return this._dataService.get(url);
  }

}
