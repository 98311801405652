import { PriceCell } from "./price-cell.model";
import { Adjustment } from "./pricing-quote.model";

export class SelectedPriceCardViewModel {
  principalAndInterest: number;
  monthlyMi: number;
  taxesAndInsurance: number;
  discountDollars: number;
  discountPercent: number;
  rebateDollars: number;
  rebatePercent: number;
  lockPeriod: number;
  rate: number;
  totalLoanAmount: number;
  price: number;
  adjustments: Adjustment[];
  isPinned?: boolean;

  constructor(price: PriceCell, totalLoanAmount: number,
    taxesAndInsurance: number) {
    this.totalLoanAmount = totalLoanAmount;
    this.principalAndInterest = price.payment;
    this.monthlyMi = price.monthlyMi;
    this.taxesAndInsurance = taxesAndInsurance;
    this.rate = price.rate;
    this.price = price.price;
    this.lockPeriod = price.lockPeriod;
    this.discountDollars = price.discountDollars;
    this.discountPercent = price.discountPercent;
    this.rebateDollars = price.rebateDollars;
    this.rebatePercent = price.rebatePercent;
    this.isPinned = price.isPinned;
    this.adjustments = price.adjustments;
  }
}
