<div class="modal-header">
  <h5 class="modal-title"> Supplemental Insurance Breakdown </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #breakdownForm="ngForm" novalidate id="breakdownForm" name="breakdownForm" style="padding-left: 60px;">
    <div class="loan-app">
      <div class="grid-layout edit-area">
        <div class="grid-layout-row breakdown-row">
          <div class="form-check col-5">
            <input class="form-check-input" type="radio" name="enterTotalValue" id="enterTotalValue" [value]="false"
              [(ngModel)]="isBrokenDown"
              (ngModelChange)="onIsBrokenDownChanged()">
            <label class="form-check-label radio-label" for="enterTotalValue">
              Enter Supplemental Insurance Premium Total:
            </label>
          </div>
          <urla-currency-input class="col-6 breakdown-input" *ngIf="!isBrokenDown"
              name="supplementalPropertyInsurance" [label]="''" [required]="true"
              [(ngModel)]="proposedHousingExpenses.supplementalPropertyInsurance"
              (ngModelChange)="onSupplementalPropertyInsuranceChanged()">
            </urla-currency-input>
          <span class="col-6 breakdown-readonly" *ngIf="isBrokenDown">
            {{ !proposedHousingExpenses.supplementalPropertyInsurance ? '--' : (proposedHousingExpenses.supplementalPropertyInsurance | currency :'USD':'symbol':'1.2-2') }}
          </span>
        </div>
        <div class="grid-layout-row">
          <div class="form-check col-4">
            <input class="form-check-input" type="radio" name="breakItDown" id="breakItDown" [value]="true"
            [(ngModel)]="isBrokenDown"
            (ngModelChange)="onIsBrokenDownChanged()">
            <label class="form-check-label radio-label" for="breakItDown">
              Or Break It Down
            </label>
          </div>
          <div class="form-check col-8" *ngIf="isBrokenDown">
            <div class="d-flex">
              <label class="form-check-label" style="margin-left: 1.8rem;">
                Policy Expiration Date
              </label>
              <label class="form-check-label" style="margin-left: 2.5rem;">
                Next Premium Due Date
              </label>
              <label class="form-check-label" style="margin-left: 1.7rem;">
                Policy Cancellation Date
              </label>
              <label class="form-check-label" style="margin-left: 1.8rem;">
                Policy Identifier
              </label>
            </div>
          </div>
          <div class="breakdown-section">
            <div class="row breakdown-row">
              <div [ngClass]="{'col-2': isBrokenDown, 'col-7': !isBrokenDown}">
                Earthquake Insurance
              </div>
              <div [ngClass]="{'col-2': isBrokenDown, 'col-4': !isBrokenDown}">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="earthquakeInsurance"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.earthquakeInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
              <ng-container *ngIf="isBrokenDown">
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'expirationDateEarthquake'" 
                  [label]="''"
                  required 
                  [(ngModel)]="earthquakeInsurance.expirationDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'nextPremiumDueDateEarthquake'" 
                  [label]="''"
                  required 
                  [(ngModel)]="earthquakeInsurance.nextPremiumDueDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyCancellationDateEarthquake'" 
                  [label]="''"
                  required 
                  [(ngModel)]="earthquakeInsurance.policyCancellationDate">
                </urla-date-input>
                <urla-text-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyIdentifierEarthquake'"
                  [label]="''"
                  [(ngModel)]="earthquakeInsurance.policyIdentifier">
                </urla-text-input>
              </ng-container>
            </div>
            <div class="row breakdown-row">
              <div [ngClass]="{'col-2': isBrokenDown, 'col-7': !isBrokenDown}">
                Flood Insurance
              </div>
              <div [ngClass]="{'col-2': isBrokenDown, 'col-4': !isBrokenDown}">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="floodInsurance"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.floodInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
              <ng-container *ngIf="isBrokenDown">
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'expirationDateFlood'" 
                  [label]="''"
                  required 
                  [(ngModel)]="floodInsurance.expirationDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'nextPremiumDueDateFlood'" 
                  [label]="''"
                  required 
                  [(ngModel)]="floodInsurance.nextPremiumDueDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyCancellationDateFlood'" 
                  [label]="''"
                  required 
                  [(ngModel)]="floodInsurance.policyCancellationDate">
                </urla-date-input>
                <urla-text-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyIdentifierFlood'"
                  [label]="''"
                  [(ngModel)]="floodInsurance.policyIdentifier">
                </urla-text-input>
              </ng-container>
            </div>
            <div class="row breakdown-row">
              <div [ngClass]="{'col-2': isBrokenDown, 'col-7': !isBrokenDown}">
                Hail Insurance
              </div>
              <div [ngClass]="{'col-2': isBrokenDown, 'col-4': !isBrokenDown}">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="hailInsurance"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.hailInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
              <ng-container *ngIf="isBrokenDown">
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'expirationDateHail'" 
                  [label]="''"
                  required 
                  [(ngModel)]="hailInsurance.expirationDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'nextPremiumDueDateHail'" 
                  [label]="''"
                  required 
                  [(ngModel)]="hailInsurance.nextPremiumDueDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyCancellationDateHail'" 
                  [label]="''"
                  required 
                  [(ngModel)]="hailInsurance.policyCancellationDate">
                </urla-date-input>
                <urla-text-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyIdentifierHail'"
                  [label]="''"
                  [(ngModel)]="hailInsurance.policyIdentifier">
                </urla-text-input>
              </ng-container>
            </div>
            <div class="row breakdown-row">
              <div [ngClass]="{'col-2': isBrokenDown, 'col-7': !isBrokenDown}">
                Volcano Insurance
              </div>
              <div [ngClass]="{'col-2': isBrokenDown, 'col-4': !isBrokenDown}">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="volcanoInsurance"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.volcanoInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
              <ng-container *ngIf="isBrokenDown">
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'expirationDateVolcano'" 
                  [label]="''"
                  required 
                  [(ngModel)]="volcanoInsurance.expirationDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'nextPremiumDueDateVolcano'" 
                  [label]="''"
                  required 
                  [(ngModel)]="volcanoInsurance.nextPremiumDueDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyCancellationDateVolcano'" 
                  [label]="''"
                  required 
                  [(ngModel)]="volcanoInsurance.policyCancellationDate">
                </urla-date-input>
                <urla-text-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyIdentifierVolcano'"
                  [label]="''"
                  [(ngModel)]="volcanoInsurance.policyIdentifier">
                </urla-text-input>
              </ng-container>
            </div>
            <div class="row breakdown-row">
              <div [ngClass]="{'col-2': isBrokenDown, 'col-7': !isBrokenDown}">
                Wind & Storm Insurance
              </div>
              <div [ngClass]="{'col-2': isBrokenDown, 'col-4': !isBrokenDown}">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="windAndStormInsurance"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.windAndStormInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
              <ng-container *ngIf="isBrokenDown">
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'expirationDateWindAndStorm'" 
                  [label]="''"
                  required 
                  [(ngModel)]="windAndStormInsurance.expirationDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'nextPremiumDueDateWindAndStorm'" 
                  [label]="''"
                  required 
                  [(ngModel)]="windAndStormInsurance.nextPremiumDueDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyCancellationDateWindAndStorm'" 
                  [label]="''"
                  required 
                  [(ngModel)]="windAndStormInsurance.policyCancellationDate">
                </urla-date-input>
                <urla-text-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyIdentifierWindAndStorm'"
                  [label]="''"
                  [(ngModel)]="windAndStormInsurance.policyIdentifier">
                </urla-text-input>
              </ng-container>
            </div>
            <div class="row breakdown-row">
              <div [ngClass]="{'col-2': isBrokenDown, 'col-7': !isBrokenDown}">
                Other Supplemental Property Insurance
              </div>
              <div [ngClass]="{'col-2': isBrokenDown, 'col-4': !isBrokenDown}">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="otherSupplementalPropertyInsurance"
                  [label]="''" [(ngModel)]="proposedHousingExpenses.otherSupplementalPropertyInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="col-6 breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
              <ng-container *ngIf="isBrokenDown">
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'expirationDateOther'" 
                  [label]="''"
                  required 
                  [(ngModel)]="otherSupplementalPropertyInsurance.expirationDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'nextPremiumDueDateOther'" 
                  [label]="''"
                  required 
                  [(ngModel)]="otherSupplementalPropertyInsurance.nextPremiumDueDate">
                </urla-date-input>
                <urla-date-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyCancellationDateOther'" 
                  [label]="''"
                  required 
                  [(ngModel)]="otherSupplementalPropertyInsurance.policyCancellationDate">
                </urla-date-input>
                <urla-text-input 
                  class="col-sm-2 col-xs-2 negative-margin-5 breakdown-input" 
                  [name]="'policyIdentifierOther'"
                  [label]="''"
                  [(ngModel)]="otherSupplementalPropertyInsurance.policyIdentifier">
                </urla-text-input>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      Total: <b>{{ proposedHousingExpenses.supplementalPropertyInsurance | currency :'USD':'symbol':'1.2-2' }}</b>
    </div>
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
        <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
