<loading-indicator
  *ngIf="isLoading"
  [loadingMessage]="'Loading submission, please wait...'"
></loading-indicator>
<div class="row" *ngIf="!isLoading">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <items-required-for-submission
          [data]="itemsRequiredForSubmissionData"
          [optionalConditions]="itemsRequestedForSubmission"
          [borrowers]="borrowers"
          (onTaskUpdated)="fetchValidateTasks()"
        ></items-required-for-submission>
        <submission-data-validation
          [isUrlaInvalid]="isUrlaInvalid"
          [application]="_application"
          [validationResults]="otherValidations"
        ></submission-data-validation>
        <license-info
          *ngIf='licenseValidationData'
          [data]='licenseValidationData'
        ></license-info>
        <disclosure-path
          *ngIf="!isCompanyPulseProcessing && !(isPRMG && ['Correspondent'].includes(_application?.channel))"
          [disclosurePath]="_application.disclosurePath"
          [disclosurePathPermissions]="disclosurePathPermissions"
          [tridAppDate]="tridAppDate"
        ></disclosure-path>
        <program [productPricing]="productPricing" [isCompTypeHidden]="isPRMG && ['Correspondent', 'NonDelegatedCorrespondent'].includes(_application?.channel)"></program>
        <final-submission-info
          [data]="finalSubmissionInfoData"
        ></final-submission-info>
        <external-contacts-v2 *ngIf="showExternalContacts"></external-contacts-v2>
        <final-notes [internalMessage]="internalMessage"></final-notes>
        <div class="row">
          <div class="col-sm-12 text-end mb-3">
            <button
              [disabled]="isUrlaInvalid && (isDeephaven || (isPRMG && _application.channel === 'Correspondent'))"
              type="submit"
              class="btn btn-primary px-4"
              (click)="onSubmitLoanClicked()"
            >
              Submit Loan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
