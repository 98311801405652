<form #sendSmsForm="ngForm" ngbAutofocus>
  <div class="mb-3" *ngIf="recipientsVisible">
    <label class="control-label" for="to">To</label>
    <select *ngIf="recipients.length > 0" class="form-select" name="to" id="to" [(ngModel)]="toPhoneNumber" required
      #sendTo="ngModel" [ngClass]="{ 'is-invalid': sendTo && sendTo.touched && sendTo.invalid }"
      placeholder="Select a Recipient">
      <option [ngValue]="null">--Select a Recipient--</option>

      <optgroup [label]="recipient.firstName + ' ' + recipient.lastName" *ngFor="let recipient of recipients">
        <option [ngValue]="phoneNumber.number" *ngFor="let phoneNumber of recipient.phoneNumbers">
          {{ phoneNumber.type.charAt(0).toUpperCase() }}:
          {{ phoneNumber.number | phone }}
        </option>
      </optgroup>
    </select>

    <input *ngIf="recipients.length == 0" class="form-control" name="to" [(ngModel)]="toPhoneNumber" id="to"
      #sendTo="ngModel" [ngClass]="{ 'is-invalid': sendTo && sendTo.touched && sendTo.invalid }"
      [mask]="'(000) 000-0000'" [showMaskTyped]="true" />

    <div class="invalid-feedback" for="to">To field is required.</div>
  </div>
  <div class="mb-3">
    <label class="control-label" for="body">Body
      <span *ngIf="smsGenerationEnabled" class="ms-2 text-primary fw-bold cursor"
        (click)="openSmsGenerationModal()">Need an idea? Click here...</span></label>
    <a class="float-end" (click)="switch()">Switch to {{ isMMS ? 'SMS' : 'MMS' }}</a>
    <div class="d-flex" *ngIf="!isMMS">
      <div class="flex-grow-1">
        <textarea required name="body" #bodyToSend="ngModel" class="form-control" [ngClass]="{
            'is-invalid': bodyToSend && bodyToSend.touched && bodyToSend.invalid
          }" cols="12" rows="7" style="resize: none" id="body" [(ngModel)]="body"
          [mentionConfig]="mergeFieldsMentionConfig || {}" 
          (contextmenu)="mergeFieldsMentionConfig ? onContextMenu($event, 'body') : '' ">
        </textarea>
        <div class="invalid-feedback">Body field is required.</div>
        <span class="text-muted" *ngIf="mergeFieldsMentionConfig">
          You can use mouse right click button to select and insert a merge a
          field.
        </span>
      </div>
    </div>

    <div *ngIf="isMMS">
      <send-mms [type]="'image'" [(ngModel)]="body" [allowedTypes]="['image']" name="sendMMS"
        [attachments]="attachments" [availableMergeFields]="availableMergeFields"></send-mms>
    </div>
  </div>
  <div class="custom-control custom-switch switch-success">
    <input type="checkbox" class="custom-control-input" id="appendSmsSignature" name="appendSmsSignature"
      [(ngModel)]="appendSmsSignature" />
    <label style="padding-left: 4px" class="form-label" for="appendSmsSignature">Append SMS Signature</label>
  </div>
</form>

<merge-field-context-menu [menuItems]="availableMergeFields" *ngIf="availableMergeFields">
</merge-field-context-menu>
