<div id="rightSubnav" class="right-sub-nav box-shadow">
  <div class="activity-header">
    <div class="row align-items-center">
        <div class="col-auto">
          <i class="right-sidenav-close-icon mdi mdi-arrow-left" (click)="closeNav()"></i>
        </div>
        <div class="col-auto text-center">
            <h4 class="activity-title">55 Amethyst Drive</h4>
        </div><!--end col-->
        <div class="col-auto">
          <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
        </div><!--end col-->
    </div>  <!--end row-->
</div>
  <div class="activity">
    <div class="activity-info">
        <div class="icon-info-activity">
            <i class="las la-user-clock bg-soft-primary"></i>
        </div>
        <div class="activity-info-text">
            <div class="d-flex justify-content-between align-items-center">
                <p class="mb-0 font-13 w-75"><span>Michael Scott</span><small class="text-muted float-end">10 Min ago</small></p>

            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0 font-13 w-75">Please send over the Document we discussed earlier</p>
          </div>
        </div>
    </div>

    <div class="activity-info">
        <div class="icon-info-activity">
            <i class="mdi mdi-timer-off bg-soft-primary"></i>
        </div>
        <div class="activity-info-text">
          <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0 font-13 w-75"><span>Lucy Peterson</span><small class="text-muted float-end">10 Min ago</small></p>

          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-0 font-13 w-75"><a href="">@Jim</a> can you please find out more information?</p>
        </div>
      </div>
    </div>
  </div>
</div>
