<!-- Loan & Property -->
<menu-item [groupId]="'quick-apply'" [id]="'qaLoanInfo'" [isActive]="tab === 'qa-loan-info'" [label]="'Loan & Property'"
           [link]="'/' + applicationMode + '/app-details/' + application.applicationId + '/quick-apply/qa-loan-info'"
           [status]='MenuItemStatus.Pending'>
</menu-item>

<!-- Borrower Info -->
<menu-item [groupId]="'quick-apply'" [id]="'qaBorrowerInfo'" [isActive]="tab === 'qa-borrower-info'"
           [label]="'Borrower Info'"
           [link]="'/' + applicationMode + '/app-details/' + application.applicationId + '/quick-apply/qa-borrower-info'"
           [status]="MenuItemStatus.Pending">
</menu-item>

<!-- Financial Info -->
<menu-item [groupId]="'quick-apply'" [id]="'qaFinancialInfo'" [isActive]="tab === 'qa-financial-info'"
           [label]="'Financial Info'"
           [link]="'/' + applicationMode + '/app-details/' + application.applicationId + '/quick-apply/qa-financial-info'"
           [status]='MenuItemStatus.Pending'>
</menu-item>

<!-- FHA Info -->
<menu-item *ngIf="isFhaLoan && companyId == 2" [groupId]="'quick-apply'" [id]="'qaFhaInfo'" [isActive]="tab === 'qa-fha-info'" [label]="'FHA Info'"
           [link]="'/' + applicationMode + '/app-details/' + application.applicationId + '/quick-apply/qa-fha-info'"
           [status]="MenuItemStatus.Pending">
</menu-item>

<!-- VA Info -->
<menu-item *ngIf="isVaLoan && companyId == 2" [groupId]="'quick-apply'" [id]="'qaVaInfo'" [isActive]="tab === 'qa-va-info'" [label]="'VA Info'"
           [link]="'/' + applicationMode + '/app-details/' + application.applicationId + '/quick-apply/qa-va-info'"
           [status]="MenuItemStatus.Pending">
</menu-item>
