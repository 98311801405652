import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { AppraisalCondition } from '../models/appraisal/appraisal-condition.model';
import { AppraisalFormType } from '../models/appraisal/appraisal-form-type.model';
import { AppraisalOrderView } from '../models/appraisal/appraisal-order-view.model';
import { AppraisalVendor } from '../models/appraisal/appraisal-vendor.model';

@Injectable({
  providedIn: 'root'
})
export class AppraisalService {

  constructor(private readonly _dataService: DataService) { }

  getAppraisalFormTypes = (): Observable<AppraisalFormType[]> => {
    const url = 'api/appraisal/formTypes';
    return this._dataService.get(url);
  }

  getAppraisalOrders = (loanDocTaskId: number, includeClosedOrders: boolean) => {
    const url = 'api/appraisal/orders/loanDocTask/' + loanDocTaskId + '?includeClosedOrders=' + includeClosedOrders;
    return this._dataService.get(url);
  }

  getAppraisalConditions = (appraisalOrderId: number) => {
    const url = `api/appraisal/orders/${appraisalOrderId}/conditions`;
    return this._dataService.get(url);
  }

  saveAppraisalConditions = (appraisalOrderId: number, request: Array<Partial<AppraisalCondition>>) => {
    const url = 'api/appraisal/orders/' + appraisalOrderId + '/conditions';
    return this._dataService.post(url, request);
  }

  approveAppraisalCondition = (appraisalOrderId: number, data: any[]) => {
    const url = `api/appraisal/orders/${appraisalOrderId}/conditions/approve`;
    return this._dataService.post(url, data);
  }

  denyAppraisalCondition = (appraisalOrderId: number, data: any[]) => {
    const url = `api/appraisal/orders/${appraisalOrderId}/conditions/deny`;
    return this._dataService.post(url, data);
  }

  cancelAppraisalCondition = (appraisalOrderId: number, data: any[]) => {
    const url = `api/appraisal/orders/${appraisalOrderId}/conditions/cancel`;
    return this._dataService.post(url, data);
  }

  postAppraisalOrders = (data: any) => {
    const url = 'api/appraisal/orders';
    return this._dataService.post(url, data);
  }

  createAppraisalOrder = (data: any) => {
    const url = 'api/appraisal/orders';
    return this._dataService.post(url, data);
  }

  updateAppraisalOrder = (appraisalOrderId: number, request: Partial<AppraisalOrderView>) => {
    const url = `api/appraisal/orders/${appraisalOrderId}/update`;
    return this._dataService.post(url, request);
  }

  cancelAppraisalOrder = (appraisalOrderId: number) => {
    const url = 'api/appraisal/orders/' + appraisalOrderId + '/cancel';
    return this._dataService.post(url, {});
  }

  placeAppraisalOrderOnHold = (appraisalOrderId: number) => {
    const url = 'api/appraisal/orders/' + appraisalOrderId + '/placeOnHold';
    return this._dataService.post(url, {});
  }

  resumeAppraisalOrderFromHold = (appraisalOrderId: number) => {
    const url = 'api/appraisal/orders/' + appraisalOrderId + '/resumeFromHold';
    return this._dataService.post(url, {});
  }

  refreshAppraisalOrder = (appraisalOrderId: number) => {
    const url = 'api/appraisal/orders/' + appraisalOrderId + '/updateFromLoan';
    return this._dataService.post(url, {});
  }

  getAppraisalHistory = (applicationId: number): Observable<AppraisalOrderView[]> => {
    const url = `api/appraisal/orders/application/${applicationId}`;
    return this._dataService.get(url);
  }

  getAppraisalVendors = (applicationId: number): Observable<AppraisalVendor[]> => {
    const url = `api/appraisal/vendors/${applicationId}`;
    return this._dataService.get(url);
  }
}
