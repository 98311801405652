import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { LeadStatusFlow } from '../../../../models';

@Component({
  selector: 'tree-sortable-lead-status',
  templateUrl: 'tree-sortable-lead-status.component.html',
  styleUrls: ['tree-sortable-lead-status.component.scss']
})
export class TreeSortableLeadStatusComponent implements OnInit {
  @Input() status: LeadStatusFlow;
  @Input() options;
  @Output() removeFromTree = new EventEmitter<{
    statusId: number;
    parentStatusId: number;
  }>();

  optionsCopy: any;

  constructor() {}

  ngOnInit(): void {
    this.optionsCopy = _.cloneDeep(this.options);
    if (this.status.level > 0) {
      this.optionsCopy.group.put = false;
    }
  }
}
