import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication, Mortgage } from 'src/app/models';
import { Channel } from 'src/app/modules/leads/models/lead.model';
import { ProductSearchRequestInfo } from 'src/app/modules/pricing/models/pricing/product-search-request-info.model';

@Component({
  selector: 'product-and-pricing',
  templateUrl: './product-and-pricing.component.html',
  styleUrls: ['./product-and-pricing.component.scss']
})
export class ProductAndPricingComponent implements OnInit {

  @Input()
  currentApplication: LoanApplication | null;

  @Input()
  applicationChannel: Channel | null;

  @Input()
  currentMortgage: Mortgage;

  @Input()
  isLoanReadOnly: boolean = false

  @Input()
  enableChannelSelection: boolean = false;

  productSearchRequestInfo: ProductSearchRequestInfo = new ProductSearchRequestInfo();

  constructor() { }

  ngOnInit(): void {

  }
}
