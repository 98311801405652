import { Adjustment } from "./pricing-quote.model";

export class PriceCell {
  rate: number;
  price: number;
  lockPeriod: number;
  payment: number;
  monthlyMi: number;
  discountDollars: number;
  discountPercent: number;
  totalPayment: number;
  totalClosingCost: number;
  rebateDollars: number;
  rebatePercent: number;
  selected: boolean;
  isPinned: boolean = false;
  adjustments: Adjustment[] = [];

  constructor(rate: number, price: number, lockPeriod: number, payment: number, monthlyMi: number, discountDollars: number, discountPercent: number, 
    rebateDollars: number, rebatePercent: number, totalClosingCost: number, totalPayment: number, adjustments: Adjustment[]) {
    this.rate = rate;
    this.price = price;
    this.lockPeriod = lockPeriod;
    this.payment = payment;
    this.monthlyMi = monthlyMi;
    this.discountDollars = discountDollars;
    this.discountPercent = discountPercent;
    this.totalClosingCost = totalClosingCost;
    this.totalPayment = totalPayment;
    this.adjustments = adjustments;
    this.rebateDollars = rebateDollars;
    this.rebatePercent = rebatePercent;
    this.isPinned = false;
  }
}
