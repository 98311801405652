import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dialpad',
  templateUrl: 'dialpad.component.html',
  styleUrls: ['dialpad.component.scss'],
})
export class DialpadComponent implements OnInit {

  @Output()
  numberClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  backspaceClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  callClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  smsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clearClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  callButtonEnabled: boolean = true;

  constructor() { }

  ngOnInit() { }

  onDialpadNumberClick(number: string) {
    this.numberClicked.emit(number);
  }

  onDialpadBackspaceClick() {
    this.backspaceClicked.emit();
  }

  onDialpadCallClick() {
    this.callClicked.emit();
  }

  onDialpadSmsClick() {
    this.smsClicked.emit();
  }

  onDialpadClearClick() {
    this.clearClicked.emit();
  }
}
