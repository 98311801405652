<ng-container *ngFor="let borrower of borrowers">
  <div class="card m-2">
    <div class="card-header">
      <h3 class="card-title">
        {{ borrower.firstName + ' ' + borrower.lastName }}
      </h3>
    </div>
    <div class="card-body">
      <borrower-credit-inquiry
        #borrowerCreditInquiry
        [borrower]="borrower"
        [showOnlyLast4Months]="true"
      ></borrower-credit-inquiry>
    </div>
  </div>
</ng-container>

<div class="modal-footer w-100">
  <div class="d-flex justify-content-end">
    <button class="btn btn-secondary me-2" (click)="close()">Cancel</button>
    <button class="btn btn-primary me-2" type="button" (click)="save()" [disabled]="haveNotAllBorrowersCreditInquiries()">
      <i class="fa fa-save"></i>
      Save
    </button>
  </div>
</div>
