import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'select-fee-template',
  templateUrl: 'select-fee-template.component.html'
})
export class SelectFeeTemplateComponent implements OnInit {

  @Input()
  templates: FeeTemplate[] = [];

  @Input()
  applicationId: number;

  @Output()
  feeTemplateSelected: EventEmitter<LoanFee[]> = new EventEmitter<LoanFee[]>();

  @Output()
  templateSelectionChanged: EventEmitter<FeeTemplate> = new EventEmitter<FeeTemplate>();

  @Output()
  executionStarted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  executionCompleted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  autoProgressCalled: EventEmitter<any> = new EventEmitter<any>();

  selectedTemplate: FeeTemplate = null;

  templateFeesLoading: boolean = false;

  templateFeesLoaded: boolean = false;

  constructor(private readonly _feesService: FeeService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService) { }

  ngOnInit() {
    if (this.templates.length === 1) {
      this.selectedTemplate = this.templates[0];
      this.templateSelectionChanged.emit(this.selectedTemplate);
      setTimeout(() => { this.autoProgressCalled.emit(); }, 500);
    }
  }

  onTemplateSelectionChanged = () => {
    this.templateSelectionChanged.emit(this.selectedTemplate);
  }

  getTemplateFees = () => {
    const observer = {
      next: ((templateFeesToApply: LoanFee[]) => {
        this.templateFeesLoading = false;
        this.templateFeesLoaded = true;
        //this._spinner.hide();
        templateFeesToApply = templateFeesToApply.filter(fee => !fee.isSummaryFee);
        setTimeout(() => {
          this.executionCompleted.emit();
          this.feeTemplateSelected.emit(templateFeesToApply);
        }, 2000);
      }),
      error: (error => {
        this.executionCompleted.emit();
        this.templateFeesLoading = false;
        //this._spinner.hide();
        this._notifsService.showError(error.error ? error.error.message : "An error occurred while getting template fees.", "Error!");
      })
    }
    //this._spinner.show();
    this.templateFeesLoading = true;
    this.templateFeesLoaded = false;
    this.executionStarted.emit();
    this._feesService.getTemplateFeesForApplication(this.applicationId, this.selectedTemplate.feeTemplateId).subscribe(observer);
  }
}
