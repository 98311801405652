import { Component, Input, TemplateRef } from '@angular/core';
import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [
    createExpandCollapseAnimation(),
  ],
})
export class ExpansionPanelComponent {
  /**
   * The content to be rendered inside the expansion panel if it is expanded.
   */
  @Input() content: TemplateRef<any> | null = null;
  /**
   * Determines whether the expansion panel is expanded or not. The
   * {@link content} is only rendered if the panel is expanded.
   */
  @Input() isExpanded: boolean = false;
}

function createExpandCollapseAnimation(): AnimationTriggerMetadata {
  const collapsedStyle = style({
    height: '0px',
    overflow: 'hidden',
    opacity: 0,
  });
  const expandedStyle = style({
    height: '*',
    overflow: 'hidden',
    opacity: 1,
  });
  const duration = '225ms';
  const expandTiming = `${duration} ease-out`;
  const collapseTiming = `${duration} ease-out`;

  return trigger('expandCollapse', [
    transition(':enter', [
      collapsedStyle,
      animate(expandTiming, expandedStyle),
    ]),
    transition(':leave', [
      expandedStyle,
      animate(collapseTiming, collapsedStyle),
    ]),
  ]);
}
