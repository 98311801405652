<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Deed
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #docPreDeedForm="ngForm" id="docPreDeedForm">
      <div class="row" *ngIf="docPrep">
        <div class="col-md-6 form-group">
          <label for="deedType"> Deed Type </label>
          <select id="deedType" name="deedType" class="form-select" [(ngModel)]="docPrep.deedType">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of deedTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group">
          <label for="trustee"> Trustee </label>
          <input type="text" class="form-control" id="trustee" name="trustee" [(ngModel)]="docPrep.trustee">
        </div>
        <div class="col-md-6 form-group">
          <label for="sellerVestingForDeed"> Seller Vesting For Deed </label>
          <input type="text" class="form-control" id="sellerVestingForDeed" name="sellerVestingForDeed"
            [(ngModel)]="docPrep.sellerVestingForDeed">
        </div>
        <div class="col-md-6 form-group">
          <label for="borrowerVestingForDeed"> Borrower Vesting For Deed </label>
          <input type="text" class="form-control" id="borrowerVestingForDeed" name="borrowerVestingForDeed"
            [(ngModel)]="docPrep.borrowerVestingForDeed">
        </div>
        <div class="col-md-6 form-group">
          <label for="deedReserv"> Deed Reservations </label>
          <textarea id="deedReserv" name="deedReserv" class="form-control" rows="4"
            [(ngModel)]="docPrep.deedReservations">
          </textarea>
        </div>
      </div>
    </form>
  </div>
</div>