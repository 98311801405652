import { Component, Input, OnInit } from '@angular/core';
import { LosPlanCodeConflict } from '../../../request-models/loan-validation-response.model';

@Component({
  selector: 'los-plan-code-conflicts',
  templateUrl: './los-plan-code-conflicts.component.html',
  styleUrls: ['./los-plan-code-conflicts.component.scss']
})
export class LosPlanCodeConflictsComponent implements OnInit {

  @Input() conflicts: LosPlanCodeConflict[];

  constructor() { }

  ngOnInit(): void {
  }

}
