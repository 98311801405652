<div class="reduce-term">
  <h3 class="rt-style-1">
    <span class="rt-style-2">Reduce Term</span>
  </h3>

  <div class="rt-style-3">
    <div class="rt-style-4">
      <div class="rt-style-12">
        <img
          class="rt-style-13"
          [src]="imageData"
          style="width: 300px; height: auto"
        />
      </div>
    </div>
    <div class="rt-style-14">
      <housing-calculator class="rt-style-15"
        [mortgage]="mortgage"
        [parameters]="proposedParameters"
        [readOnlyAll]="true"
        (parametersChanged)="onParametersChanged($event)"
      ></housing-calculator>

      <div class="rt-style-65">
        <h5 class="rt-style-66">
          Total Cumulative Interest
        </h5>
        <table class="rt-style-67">
          <thead class="rt-style-68">
            <tr class="rt-style-69">
              <th class="rt-style-70">Current Loan</th>
              <th class="rt-style-71">Proposed</th>
            </tr>
          </thead>
          <tbody class="rt-style-72">
            <tr class="rt-style-73">
              <td class="rt-style-74">
                <div class="rt-style-75">
                  <span class="form-control" readonly>
                    {{totalCumulativeInterest.currentLoan| currency: 'USD':'symbol':'.2':'en-US'}}
                  </span>
                </div>
              </td>
              <td class="rt-style-81">
                <div class="rt-style-82">
                  <span class="form-control" readonly>
                    {{totalCumulativeInterest.proposed| currency: 'USD':'symbol':'.2':'en-US'}}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="rt-style-88">
            <tr class="rt-style-89">
              <td class="rt-style-90">Interest Saving</td>
              <td class="rt-style-91">
                <div class="rt-style-92">
                  <span class="form-control" readonly>
                    {{totalCumulativeInterest.interestSaving| currency: 'USD':'symbol':'.2':'en-US'}}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
