<div class="card">
  <div class="card-body">
    <form #loanFormV2="ngForm" class="loan-info-form-v2" novalidate id="loanFormV2" name="loanFormV2"
      *ngIf="application">
      <div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
        [ngClass]="{'sticky-nav-tabs-open-dialer': secondPageActionBarVisible }">
        <div class="button-items">
          <!-- <div class="float-start">
                <button type="button" class="btn btn-outline-primary">
                  <i class="fas fa-file-import"></i> Add Buttons here
                </button>
              </div> -->
          <div class="float-end">
            <button type="button" class="btn btn-primary"
              [disabled]="isLoanReadOnly"
              (mousedown)="save()">
              <i class="fas fa-save"></i>
              {{isLoanReadOnly ? 'Save (Readonly)' : 'Save'}}
            </button>
          </div>
        </div>
      </div>
      <div class="card" id="dealStructure">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-user me-2"></i> Transaction /
            Referral Info </h4>
          <a class="link" (click)="showReferralCardBody = !showReferralCardBody">{{showReferralCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showReferralCardBody">
          <loan-referral-info [application]="application" (loanPurposeChanged)="onLoanPurposeChanged($event)">
          </loan-referral-info>
        </div>
      </div>

      <div class="card" id="borrowerInformation">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-users me-2"></i> Borrower
            Information </h4>
          <a class="link" (click)="showBorrowerCardBody = !showBorrowerCardBody">{{showBorrowerCardBody
            ? 'Hide' : 'Show'}} Details</a>
          <div class="float-end button-items" *ngIf="!isLoanReadOnly">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddNewBorrowerClicked()">
              Add Borrower
            </button>
            <button type="button" class="btn btn-sm btn-outline-primary right" (click)="onManageBorrowersClicked()">
              <i class="fas fa-users"></i> Manage Borrowers
            </button>
          </div>
        </div>
        <div class="card-body" *ngIf="showBorrowerCardBody">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" *ngFor="let borrowers of borrowerGroups; let i = index">
              <a class="nav-link" [ngClass]="{ active: tab == borrowers[0].borrowerId }"
                (click)="selectTab(borrowers[0].borrowerId)" data-bs-toggle="tab" href="javascript: void(0)" role="tab">
                <span *ngFor="let borrower of borrowers; let j = index">
                  {{j == 0 ? '' : ' / '}}{{getBorrowerDisplayName(borrower)}}
                </span>
              </a>
            </li>
          </ul>

          <div *ngFor="let borrowers of borrowerGroups" id="applications" class="tab-pane"
            [ngClass]="{ active: tab == borrowers[0].borrowerId }" role="tabpanel">
            <ng-container *ngIf="tab == borrowers[0].borrowerId">
              <div class="row mt-3">
                <div *ngFor="let borrower of borrowers" class="col-sm-12"
                  [ngClass]="{'col-md-6' : borrowers.length > 1 }">
                  <div class="card">
                    <div class="card-body">
                      <loan-borrower-info [borrower]="borrower" [loanBorrower]="loanBorrowersMap.get(borrower.borrowerId)" [coBorr]="borrowers.length > 1">
                      </loan-borrower-info>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="card" id="creditReporting">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-flag-checkered me-2"></i> Credit
            Reporting </h4>
          <a class="link"
            (click)="showCreditReportingCardBody = !showCreditReportingCardBody">{{showCreditReportingCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showCreditReportingCardBody">
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="row">
                <loan-credit 
                  [embedded]="true">
                </loan-credit>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" id="liabilitiesAtAGlance">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-credit-card me-2"></i>
            Liabilities at a Glance
          </h4>
          <a class="link"
            (click)="showLiabilitiesAtGlanceCardBody = !showLiabilitiesAtGlanceCardBody">{{showLiabilitiesAtGlanceCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showLiabilitiesAtGlanceCardBody">
          <loan-liabilities *ngIf="mortgage && !isMortgageDataInitializing" [mortgage]="mortgage" #loanLiabilities
            (liabilitiesChanged)="onLiabilitiesChanged('loanLiabilities')">
          </loan-liabilities>
          <loading-indicator *ngIf="isMortgageDataInitializing" [loadingMessage]="'Loading liabilities...'"
            [customClass]="'no-shadow'"> </loading-indicator>
        </div>
      </div>

      <div class="card" id="incomeAnalysis">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-money me-2"></i> Income Analysis
          </h4>
          <a class="link"
            (click)="showIncomeAnalysisCardBody = !showIncomeAnalysisCardBody">{{showIncomeAnalysisCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showIncomeAnalysisCardBody">
          <income-analysis #incomeAnalysis *ngIf="mortgage" [mortgage]="mortgage"></income-analysis>
        </div>
      </div>

      <div class="card" id="cashflowAnalysis" *ngIf="!isHomesiteHack">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-gears me-2"></i> Cashflow
            Analysis </h4>
          <a class="link"
            (click)="showCashflowAnalysisCardBody = !showCashflowAnalysisCardBody">{{showCashflowAnalysisCardBody
            ? 'Hide' : 'Show'}} Details</a>
          <button type="button" class="btn btn-sm btn-outline-primary pull-right" (click)="onCalculatorsClicked()">
            Benefit Summaries
          </button>
        </div>
        <div class="card-body" *ngIf="showCashflowAnalysisCardBody">
          <cash-flow-analysis #cashFlowAnalysis *ngIf="mortgage" [mortgage]="mortgage"></cash-flow-analysis>
        </div>
      </div>

      <div class="card" id="assetsAndReserves">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-university me-2"></i> Assets &
            Reserves</h4>
          <a class="link"
            (click)="showAssetsInDetailCardBody = !showAssetsInDetailCardBody">{{showAssetsInDetailCardBody
            ? 'Hide' : 'Show'}} Details</a>
          <div class="d-flex justify-content-between float-end">
            <span [ngClass]="{'text-success': isExceedFtc, 'text-danger': !isExceedFtc}">
              <i class="fa" [ngClass]="{'fa-check': isExceedFtc, 'fa-times-circle': !isExceedFtc}"></i>
              {{ isExceedFtc ? 'Assets exceed FTC' : 'Insufficient Assets' }}

              <button class="btn btn-sm btn-outline-primary ms-1" (click)="addAsset()">
                Add Asset
              </button>
            </span>
          </div>
        </div>
        <div class="card-body" *ngIf="showAssetsInDetailCardBody">
          <asset-and-cash-flow #loanAsset *ngIf="mortgage" [mortgage]="mortgage"
            (changedvalue)="calculateIfTotalAssetsExceedsFtc($event)"></asset-and-cash-flow>
        </div>
      </div>

      <div class="card" id="liabilitiesInDetail">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;">
            <i class="fas fa-credit-card me-2"></i> Liabilities in Detail
          </h4><a class="link"
            (click)="showLiabilitiesInDetailCardBody = !showLiabilitiesInDetailCardBody">{{showLiabilitiesInDetailCardBody
            ? 'Hide' : 'Show'}} Details</a>
          <button class="btn btn-sm btn-outline-primary float-end" (click)="addLiability()">
            Add Liability
          </button>
        </div>
        <div class="card-body" *ngIf="showLiabilitiesInDetailCardBody">
          <liabilities-in-detail #liabitiesInDetails *ngIf="mortgage" [liabilityTypeOptions]="liabilityTypes"
            [mortgage]="mortgage" (liabilitiesChanged)="onLiabilitiesChanged()"></liabilities-in-detail>
        </div>
      </div>

      <div class="card" id="propertyInformation">
        <div class="card-header d-flex justify-content-between">
          <h4 class="card-title"><i class="fas fa-home me-2"></i> Property Information </h4>
          <div class="form-check" *ngIf="!hasApplicationReceivedKeyDate">
            <input type="checkbox" class="form-check-input" id="tbd-pre-approval" name="tbd-pre-approval"
              [(ngModel)]="isSubjectPropertyAddressTbd">
            <label class="form-check-label" for="tbd-pre-approval">TBD Pre-approval</label>
          </div>
        </div>
        <div class="card-body">
          <property-info *ngIf="mortgage"
            [mortgage]="mortgage"
            [isSubjectPropertyAddressTbd]="isSubjectPropertyAddressTbd && !hasApplicationReceivedKeyDate">
          </property-info>
        </div>
      </div>

      <div class="row" id="currentPayment">
        <div class="col-md-4">
          <div class="card" [ngClass]="mortgage ? 'card-equal-height' : ''">
            <div class="card-header">
              <h4 class="card-title"><i class="fas fa-hand-holding-usd me-2"></i> Current Payment </h4>
            </div>
            <div class="card-body">
              <current-mortgage-info *ngIf="mortgage" [mortgage]="mortgage" [context]="context">
              </current-mortgage-info>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card" [ngClass]="mortgage ? 'card-equal-height' : ''">
            <div class="card-header">
              <h4 class="card-title"><i class="fas fa-hand-holding-usd me-2"></i> Proposed Mortgage Information </h4>
            </div>
            <div class="card-body">
              <proposed-mortgage-info
                *ngIf="mortgage"
                [mortgage]="mortgage"
                [mortgageCalculations]="mortgageCalculations"
                [application]="application"
                (changedvalue)="calculateIfTotalAssetsExceedsFtc()">
              </proposed-mortgage-info>
            </div>
          </div>
        </div>
      </div>

      <div class="card" id="aus"
        *ngIf="(desktopUnderwriterEnabled || lpaEnabled || desktopOriginatorEnabled) && !isHomesiteHack">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-list-ul me-2"></i> Automated Underwriting System (AUS)</h4>
          <a class="link" (click)="showAUSCardBody = !showAUSCardBody">{{showAUSCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showAUSCardBody">
          <automated-underwriting-system *ngIf="context" [loanId]="context.application.applicationId" [branchId]="context.application.branchId" [embedded]="true">
          </automated-underwriting-system>
        </div>
      </div>

      <div class="card" id="productAndPricing" *ngIf="pricingEnabled && !isHomesiteHack">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-usd me-2"></i> Product and Pricing </h4>
          <a class="link" (click)="showPricingCardBody = !showPricingCardBody">{{showPricingCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showPricingCardBody">
          <product-and-pricing *ngIf="context"
            [isLoanReadOnly]="isLoanReadOnly"
            [currentApplication]="context.application"
            [currentMortgage]="context.currentMortgage"
            [applicationChannel]="context.application.channel">
          </product-and-pricing>
        </div>
      </div>
    </form>
  </div>
</div>

<drawer [title]="'Benefit Summaries'" [name]="'benefitCalculatorsDrawer'" [templateRef]="benefitCalculatorsContentRef"
  [options]="benefitCalculatorsDrawerOptions">
</drawer>
<ng-template #benefitCalculatorsContentRef>
  <div class="m-2 p-2">
    <benefit-calculators [mortgage]="mortgage" [currentLoanInterestRate]="context.application.currentLoanInterestRate"
      [currentLoanTerm]="context.application.currentLoanTerm"></benefit-calculators>
  </div>
</ng-template>
