import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListColumnDefinition } from 'src/app/models/contact-list.model';
import { Operator } from 'src/app/models/expressions/operator.model';
import { ConfigurationResponse } from 'src/app/models/loan-pass/configuration-response.model';
import { LoanPassMapping } from 'src/app/models/loan-pass/loan-pass-mapping.model';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanPassFieldMappingsService, LoanPassMappingType } from 'src/app/services/loan-pass-field-mappings.service';
import { NotificationService } from 'src/app/services/notification.service';
import Swal from 'sweetalert2';
import { ResetToDefaultMappingsDialogComponent } from './reset-to-default-mappings-dialog/reset-to-default-mappings-dialog.component';

@Component({
  selector: 'app-field-mappings-dialog',
  templateUrl: './field-mappings-dialog.component.html',
  styleUrls: ['./field-mappings-dialog.component.scss']
})
export class FieldMappingsDialogComponent implements OnInit {

  @Input() credentialId: number;
  @Input() mappingType: LoanPassMappingType;
  @Input() vendor: PricingVendor.LoanPass | PricingVendor.LoanPassIframe = PricingVendor.LoanPass;

  fieldsConfig: ConfigurationResponse;

  expressionFields: ContactListColumnDefinition[] = [];

  mappings: LoanPassMapping[] = [];
  mappingsOriginal: LoanPassMapping[] = [];

  operators: Operator[] = [];

  isLoadingCompleted: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _loanPassFieldMappingService: LoanPassFieldMappingsService,
    private readonly _enumarationService: EnumerationService
  ) {
    this._enumarationService.getAllOperators().subscribe(operators => {
      this.operators = operators || [];
    });
  }

  ngOnInit(): void {
    this._spinnerService.show();
    this.isLoadingCompleted = false;
    this._loanPassFieldMappingService.getLoanPassFields(this.credentialId).subscribe({
      next: (result) => {
        this.fieldsConfig = result;
        this._loanPassFieldMappingService.getExpressionColumns(this.credentialId, this.mappingType).subscribe({
          next: (fields) => {
            this.expressionFields = fields || [];
            this._loanPassFieldMappingService.getFieldMappings(this.credentialId, this.mappingType).subscribe({
              next: (mappings) => {
                this._spinnerService.hide();
                this.mappings = mappings || [];
                this.mappingsOriginal = _.cloneDeep(mappings);
                this.isLoadingCompleted = true;
              },
              error: (err2) => {
                this._spinnerService.hide();
                this._notificationService.showError(err2?.message || "An error occurred while getting field mappings.", "Error!");
                this.isLoadingCompleted = true;
              }
            })
          },
          error: (err) => {
            this._spinnerService.hide();
            this._notificationService.showError(err.message || "An error occurred while getting expression fields.", "Error!");
            this.isLoadingCompleted = true;
          }
        })
      },
      error: (error) => {
        this._spinnerService.hide();
        this._notificationService.showError(error?.message || "An error occurred while getting LoanPass fields.", "Error!");
        this.isLoadingCompleted = true;
      }
    });
  }

  onSaveClicked = () => {
    const mappingsCopy = _.cloneDeep(this.mappings);
    mappingsCopy.forEach(m => {
      m.enumMappings.forEach(em => {
        if(em.loanPassEnumMappingId < 0){
          em.loanPassEnumMappingId = null;
        }
      })
    });

    this._spinnerService.show();
    this._loanPassFieldMappingService.insertFieldMappings(this.credentialId, this.mappingType, mappingsCopy).subscribe({
      next: () => {
        this._spinnerService.hide();
        this.mappingsOriginal = _.cloneDeep(this.mappings);
        Swal.fire('Success!', 'Mappings have been saved successfully.', 'success');
      },
      error: (err) => {
        this._spinnerService.hide();
        this._notificationService.showError(err?.message || "An error occurred while saving mappings.", "Error!");
      }
    });
  }

  onCancelClicked = () => {
    this.mappings = _.cloneDeep(this.mappingsOriginal);
    this.activeModal.dismiss();
  }

  onResetToDefaultMappings = () => {
    const modalRef = this._modalService.open(ResetToDefaultMappingsDialogComponent, Constants.modalOptions.medium);
    modalRef.result.then(deleteExisting => {
      this._spinnerService.show();
      this._loanPassFieldMappingService.updateDefaultMappings(this.credentialId, this.mappingType, deleteExisting).subscribe({
        next: () => {
          this._notificationService.showSuccess("This operation was completed successfully.", "Success!");
          this._spinnerService.hide();
          this.ngOnInit();
        },
        error: (error) => {
          this._spinnerService.hide();
          this._notificationService.showError(error.message || "An error was encountered during this process.", "Error!");
        }
      });
    })
  }
}
