import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Borrower } from 'src/app/models';
import { BorrowerCharacteristicsHistoryItem } from 'src/app/models/loan/borrower-characteristics-history-item.model';

@Component({
  selector: 'borrower-characteristics-history-dialog',
  templateUrl: 'borrower-characteristics-history-dialog.component.html'
})

export class BorrowerCharacteristicsHistoryDialogComponent implements OnInit {

  @Input()
  set history(history: BorrowerCharacteristicsHistoryItem[]) {
    this._originalHistory = history;
    this._filteredHistory = [...history];
  }

  get history(): BorrowerCharacteristicsHistoryItem[] {
    return this._filteredHistory;
  }

  @Input()
  borrowers: Borrower[] = [];

  private _originalHistory: BorrowerCharacteristicsHistoryItem[] = [];
  private _filteredHistory: BorrowerCharacteristicsHistoryItem[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onAllBorrowersClicked = () => {
    this._filteredHistory = [...this._originalHistory];
  }

  onBorrowerClicked = (borrowerId: number) => {
    this._filteredHistory = this._originalHistory.filter(h => h.borrowerId == borrowerId);
  }
}
