<div class="modal-header">
  <h5 class="modal-title w-100">
    New Lead Assigned
  </h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div class="w-100 text-center">
    <span style="color: #729d73;">A new lead has been assigned to you.</span>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-soft-secondary"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-soft-primary"
    (click)="openNewLeadDrawer()"
  >
  <i class="fas fa-flag"></i> View
  </button>
</div>

