import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CustomData, LoanApplication } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { SendEmailSmsDialogComponent } from 'src/app/modules/correspondence/components/send-email-sms-dialog/send-email-sms-dialog.component';
import { LeadEvent } from 'src/app/modules/leads/models/lead-event.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EmailInfo } from 'src/app/shared/components/common-send-email/send-email.component';
import { SmsInfo } from 'src/app/shared/components/common-send-sms/send-sms.component';
import { DialListBasic } from '../../models/dial-list-basic.model';
import { DialListRecordBasic, RecordType } from '../../models/dial-list-record-basic.model';
import { DialerService } from '../../services/dialer.service';
import { MarkManualTransferDialogComponent } from '../mark-manual-transfer-dialog/mark-manual-transfer-dialog.component';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

@Component({
  selector: 'dial-list-record-details',
  templateUrl: './dial-list-record-details.component.html',
  styleUrls: ['./dial-list-record-details.component.scss']
})
export class DialListRecordDetailsComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input() record: DialListRecordBasic = null;
  @Input() selectedDialList: DialListBasic;
  @Input() dialLists: DialListBasic[] = [];

  @Output() triggerOpenCallActionsPanel = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() afterSaved = new EventEmitter<any>();
  @Output() afterStatusChanged = new EventEmitter<any>();

  recordDetails: LoanApplication | Lead | BorrowerFull | AgentFull = null;
  selectedTab: number = 1;
  selectedRecordForCall: DialListRecordBasic = null;
  isActivityAdded: boolean = false;
  smsEnabled: boolean = false;
  dialerEnabled: boolean = false;
  users: User[] = [];

  currentLoanStatuses: any[] = [];

  protected loadingRecordDetails: boolean = false;

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _dialersService: DialerService,
    private readonly _leadsService: LeadsService,
    private readonly _loanService: LoanService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router,
    private readonly _cdref: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {

      this.smsEnabled = context.userPermissions.smsAlertsEnabled;
      this.dialerEnabled = context.userPermissions.dialerEnabled;
      this.users = context.globalConfig.usersAll;

      this.loadingRecordDetails = true;
      this._dialersService.getRecordDetails(this.record, context.userPermissions.admin)
        .subscribe(details => {
          this.recordDetails = details;
          if (this.record.recordType == RecordType.Lead) {
            this.record.recordTypeStatusId = (details as Lead).leadStatusId;
          } else if (this.record.recordType == RecordType.Application) {
            this.record.recordTypeStatusId = (details as LoanApplication).loanStatusId;
            this.record.subStatusId = (details as LoanApplication).subStatusId;
          }
        }, error => {
          this._notifyService.showError(
            getErrorMessageOrDefault(error, {
              defaultMessage: 'Error while fetching record details.',
            }),
            'Error!'
          );
        }).add(() => {
          this.loadingRecordDetails = false;
        });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    this._cdref.detectChanges();
  }

  onRecordStatusChanged = (statusId: number) => {
    this.afterStatusChanged.emit({isSubStatus: false, statusId: statusId, dialListId: this.record.dialListId});
  }

  onSubStatusChanged = (statusId: number) => {
    this.afterStatusChanged.emit({isSubStatus: true, statusId: statusId, dialListId: this.record.dialListId});
  }

  onBorrowerNumberClickedForDial = (phoneNumber: string, phoneType: string, firstName: string, lastName: string): void => {
    if (!this.selectedRecordForCall || this.selectedRecordForCall.dialListRecordId != this.record.dialListRecordId) {
      this.selectedRecordForCall = this.record;
    }

    let recordToCall = {
      phoneType: phoneType,
      firstName: firstName,
      lastName: lastName,
      phoneNumberToCall: phoneNumber,
      selectedPhoneType: phoneType,
      dialListRecordId: this.record.dialListRecordId,
      mobilePhone: phoneType === "mobile" ? phoneNumber : undefined,
      homePhone: phoneType === "home" ? phoneNumber : undefined,
      workPhone: phoneType === "work" ? phoneNumber : undefined
    };

    let selectedDialList = this.dialLists.find(dl => dl.dialListId === this.record.dialListId);
    if (selectedDialList) {
      this.triggerOpenCallActionsPanel.emit({ selectedDialList: selectedDialList, selectedRecord: recordToCall, callImmediate: true, phoneType: phoneType, selectedRecordForCall: this.selectedRecordForCall })
    }
  }

  onNumberClickedForDial = (phoneType: string): void => {
    if (!this.selectedRecordForCall || this.selectedRecordForCall.dialListRecordId != this.record.dialListRecordId) {
      this.selectedRecordForCall = this.record;
    }

    let selectedDialList = this.dialLists.find(dl => dl.dialListId === this.record.dialListId);
    if (selectedDialList) {
      this.triggerOpenCallActionsPanel.emit({ selectedDialList: selectedDialList, selectedRecord: this.record, callImmediate: true, phoneType: phoneType, selectedRecordForCall: this.selectedRecordForCall });
    }
  }

  openApplication = (record: DialListRecordBasic) => {
    if (!record.applicationId) {
      this._notifyService.showError('Application details not found. Please contact your administrator.', 'Error');
    }

    this._loanService.getBorrowers(record.applicationId)
      .subscribe({
        next: () => {
          let appUrl;
          if (this._navigationService.applicationMode == ApplicationMode.Classic) {
            appUrl = "/admin/app-details/" + record.applicationId;
          }
          if (this._navigationService.applicationMode == ApplicationMode.NextGen) {
            appUrl = "/loda-nextgen/app-details/" + record.applicationId;
          }
          window.open(appUrl, '_blank');

        },
        error: (err) => {
          if (err.error.message) {
            this._notifyService.showError(err.error.message, 'Error');
          }
        }
      });

  }

  emailSent = (info: EmailInfo) => {
    if (this.record.recordType == RecordType.Lead) {
      this.isActivityAdded = false;

      let event = {
        leadId: (this.recordDetails as Lead).leadId,
        type: "email",
        note: 'Sent an email to ' + info.to + '. Subject: "' + info.subject + '".'
      } as LeadEvent;

      // add email/sms event
      this._leadsService.addLeadEvent(event)
        .subscribe(newEvent => {
          this.isActivityAdded = true;

          this._notifyService.showSuccess("Event added succussfully", "Successful");
        }, () => {
          this._notifyService.showError("Error encountered while adding event", "Error!");
        });
    }
  }

  smsSent = (info: SmsInfo) => {
    if (this.record.recordType == RecordType.Lead) {
      this.isActivityAdded = false;

      let event = {
        leadId: (this.recordDetails as Lead).leadId,
        type: "sms",
        note: 'Sent sms to ' + info.toPhoneNumber + '. Message: "' + info.body + "."
      } as LeadEvent

      // add email/sms event
      this._leadsService.addLeadEvent(event)
        .subscribe(newEvent => {
          this.isActivityAdded = true;

          this._notifyService.showSuccess("Event added succussfully", "Successful");
        }, () => {
          this._notifyService.showError("Error encountered while adding event", "Error!");
        });
    }
  }

  sendEmailClicked = () => {
    const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.applicationId = this.record.recordTypeId;
  }

  markManualTransfer = () => {
    const modalRef = this._modalService.open(MarkManualTransferDialogComponent, {...Constants.modalOptions.large, scrollable: false});
    modalRef.componentInstance.application = this.recordDetails as LoanApplication;
    modalRef.componentInstance.allRoles = this.applicationContext.globalConfig.roles;
  }

  onLeadUpdated = () => {
    this.ngOnInit(); // for refresh
    this.afterSaved.emit(this.record.dialListId);
  }

  closeDrawerClicked = () => {
    this.close.emit();
  }

  savedAgentClicked = () => {
    this.afterSaved.emit(this.selectedDialList.dialListId);
  }

  onAfterSavedBorrower = () => {
    this.afterSaved.emit(this.selectedDialList.dialListId);
  }
}
