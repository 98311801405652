import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LenderConfigService } from './services/lender-config.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { LenderConfigRoutingModule } from './lender-config-routing.module';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { LenderConfigLendersComponent } from './components/lender-config-lenders/lender-config-lenders.component';
import { LenderConfigProductsComponent } from './components/lender-config-products/lender-config-products.component';
import { LenderConfigFeesLibraryComponent } from './components/lender-config-fees-library/lender-config-fees-library.component';
import { LenderConfigFeeTemplatesComponent } from './components/lender-config-fee-templates/lender-config-fee-templates.component';
import { LenderDetailsDialogComponent } from './dialogs/lender-details-dialog/lender-details-dialog.component';
import { LenderInfoComponent } from './dialogs/lender-details-dialog/lender-info/lender-info.component';
import { LenderAeInfoComponent } from './dialogs/lender-details-dialog/lender-ae-info/lender-ae-info.component';
import { LenderPayoffInfoComponent } from './dialogs/lender-details-dialog/lender-payoff-info/lender-payoff-info.component';
import { FeesModule } from '../../fees/fees.module';
import { FeeDefinitionDetailsDialogComponent } from './dialogs/fee-definition-details-dialog/fee-definition-details-dialog.component';
import { FeeDefinitionDetailsComponent } from './dialogs/fee-definition-details-dialog/fee-definition-details/fee-definition-details.component';
import { FeeTemplateDetailsDialogComponent } from './dialogs/fee-template-details-dialog/fee-template-details-dialog.component';
import { FeeTemplateDetailsComponent } from './dialogs/fee-template-details-dialog/fee-template-details/fee-template-details.component';
import { FeeTemplateFromLibraryDialogComponent } from './dialogs/fee-template-from-library-dialog/fee-template-from-library-dialog.component';
import { FeeDefinitionSummaryTableComponent } from './dialogs/fee-template-from-library-dialog/fee-definition-summary-table/fee-definition-summary-table.component';
import { UpsertLenderProductComponent } from './dialogs/upsert-lender-product/upsert-lender-product.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { FeesLibraryComponent } from './components/fees-library/fees-library.component';
import { FeeDefinitionTableComponent } from './components/fees-library/fee-definition-table/fee-definition-table.component';

@NgModule({
  declarations: [
    LenderConfigLendersComponent,
    LenderConfigProductsComponent,
    FeesLibraryComponent,
    LenderConfigFeesLibraryComponent,
    LenderConfigFeeTemplatesComponent,
    LenderDetailsDialogComponent,
    LenderInfoComponent,
    LenderAeInfoComponent,
    LenderPayoffInfoComponent,
    UpsertLenderProductComponent,
    FeeDefinitionTableComponent,
    FeeDefinitionDetailsDialogComponent,
    FeeDefinitionDetailsComponent,
    FeeTemplateDetailsDialogComponent,
    FeeTemplateDetailsComponent,
    FeeTemplateFromLibraryDialogComponent,
    FeeDefinitionSummaryTableComponent
  ],
  exports: [
    LenderPayoffInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LenderConfigRoutingModule,
    TableModule,
    MultiSelectModule,
    FeesModule,
    NgSelectModule
  ],
  providers: [
    LenderConfigService
  ]
})
export class LenderConfigModule { }
