<section *ngLet='id + "-body" as bodyId'>
  <div
    *ngLet='feeSection.fees.length > 0 as expansible'
    [class.collapsed]='isCollapsed'
    class='header'
  >
    <!-- To wrap something with a collapse button -->
    <ng-template #collapseButton let-templateContent let-center='center'>
      <button
        (click)='isCollapsed = !isCollapsed'
        [attr.aria-controls]='bodyId'
        [attr.aria-expanded]='!isCollapsed'
        [class.justify-content-center]='center'
        [disabled]='!expansible'
        class='btn p-0 w-100 h-100 d-flex align-items-center border-0'
        type='button'
      >
        <ng-container *ngTemplateOutlet='templateContent'></ng-container>
      </button>
    </ng-template>

    <!-- Wrap both the leading and title templates with the collapse button -->
    <ng-template #templateLeading>
      <div class='leading-column m-col'>
        <div
          *ngIf='expansible'
          class='icon-container'
        >
          <i
            class='fa f-text-primary'
            [class.fa-minus]='!isCollapsed'
            [class.fa-plus]='isCollapsed'
          >
          </i>
        </div>
      </div>
    </ng-template>
    <ng-container
      *ngTemplateOutlet='
        collapseButton;
        context: { $implicit: templateLeading, center: true }'
    ></ng-container>

    <ng-template #templateTitle>
      <h5 class='title-column m-col text-start ps-2'>
        {{ title }}
      </h5>
    </ng-template>
    <ng-container
      *ngTemplateOutlet='collapseButton; context: { $implicit: templateTitle }'
    ></ng-container>

    <div class='amount-column m-col'>{{ totalFeeAmount | currency }}</div>

    <div class='actions-column m-col' style='grid-column: span 2'>
      <button
        *ngFor='let action of (inputActions ?? [])'
        (click)='action.onClick()'
        class='btn btn-link btn-sm'
        title='{{ action.label }}'
      >
        <i [class]='action.iconClass'></i>

        <span>{{ action.label }}</span>
      </button>
    </div>
  </div>

  <expansion-panel
    [content]='templateBody'
    [isExpanded]='!isCollapsed'
  ></expansion-panel>

  <ng-template #templateBody>
    <div
      [collapse]='isCollapsed'
      [id]='bodyId'
      class='body'
    >
      <div
        *ngFor='let feeView of feeViewModels; let i = index;'
        class='f-body'
      >
        <ng-container *ngIf='feeView.hasSubFees; else nonGroupFee'>
          <div class='row-parent'>
            <!-- Placeholder -->
            <span class='leading-column m-col'></span>

            <span
              *ngLet='feeView.name || "\u2014" as name'
              [title]='name'
              class='title-column m-col'
            >
            {{ name }}
          </span>
          </div>

          <ng-container *ngFor='let subFeeView of feeView.subFees'>
            <ng-container
              *ngTemplateOutlet='feeRow; context: { $implicit: subFeeView, isChild: true }'
            ></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #nonGroupFee>
          <ng-container
            *ngTemplateOutlet='feeRow; context: { $implicit: feeView }'
          ></ng-container>
        </ng-template>

        <ng-template let-value let-isChild='isChild' #feeRow>
          <div
            *ngIf='castToFeeViewModel(value) as fee'
            class='f-row'
            [class.child-row]='isChild'
          >
            <div class='leading-column m-col'>
              <!-- APR -->
              <ng-container *ngIf='fee.canBeApr'>
                <div
                  *ngIf='fee.fee as fee'
                  class='apr-toggle'
                >
                  <div
                    *ngIf='editable'
                    class='apr-switch form-check form-switch p-0'
                  >
                    <input
                      (ngModelChange)='
                        fee.isAprFee = $event; invalidateFeeChanges()
                      '
                      [ngModel]='fee.isAprFee'
                      class='form-check-input m-0'
                      id='is-apr-{{ fee.hudNumber }}'
                      type='checkbox'
                    />

                    <label
                      class='form-check label p-0'
                      for='is-apr-{{fee.hudNumber}}'
                    >APR</label>
                  </div>

                  <div
                    *ngIf='fee.isAprFee'
                    [class.apr-banner--always-visible]='!editable'
                    class='apr-banner'
                  >
                    APR
                  </div>
                </div>
              </ng-container>
            </div>

            <div class='title-column m-col'>
              <span
                *ngLet='fee.name || "\u2014" as name'
                [title]='name'
                class='text-ellipsis title-column__name'
              >
                {{ name }}
              </span>

              <!-- Shop Button -->
              <ng-container
                *ngIf='fee.fee.allowShopOrCannotShopSelection; else templateDisallowShop'
              >
                <ng-container *ngLet='{ isOpen: false } as state'>
                  <button
                    (onHidden)='state.isOpen = false'
                    (onShown)='state.isOpen = true'
                    [class.always-visible]='state.isOpen'
                    [outsideClick]='true'
                    [popover]='popover'
                    class='btn btn-link btn-sm visible-on-hover'
                    title='Shop Fee'
                  >
                    <i class='fa fa-shopping-cart'></i>
                  </button>

                  <ng-template #popover>
                    <fee-shop-config-editor [fee]='fee'>
                    </fee-shop-config-editor>
                  </ng-template>
                </ng-container>
              </ng-container>
              <ng-template #templateDisallowShop>
                <!-- Column placeholder -->
                <span></span>
              </ng-template>

              <!-- Paid To -->
              <ng-container *ngIf='feeView.fee.canSetPaidTo; else cannotSetPaidTo'>
                <span *ngLet='{editing: false} as inputState'>
                    <ng-container *ngLet='feeView.fee.paidTo as paidTo'>
                      <ng-container *ngIf='editable; else nonEditable'>
                          <a
                            (click)='inputState.editing = true'
                            *ngIf='!inputState.editing'
                            [attr.title]='paidTo || null'
                            [class.display-on-hover]='!paidTo'
                            class='input-link text-nowrap'
                            role='button'
                          >
                            to {{ paidTo }}
                          </a>

                        <input
                          (blur)='inputState.editing = false'
                          (ngModelChange)='
                            feeView.fee.paidTo = $event; invalidateFeeChanges()
                          '
                          *ngIf='inputState.editing'
                          [appAutofocus]='true'
                          [ngModelOptions]='{standalone: true}'
                          [ngModel]='feeView.fee.paidTo'
                          class='input-light text-nowrap'
                          type='text'
                        />
                      </ng-container>

                      <ng-template #nonEditable>
                        <span
                          *ngIf='paidTo'
                          class='f-text-light text-nowrap'
                        >
                          to {{ paidTo }}
                        </span>
                      </ng-template>
                    </ng-container>
                </span>
              </ng-container>
              <ng-template #cannotSetPaidTo>
                <!-- Column placeholder -->
                <span></span>
              </ng-template>

              <ng-container *ngIf='feeView.durationType; else nonDurationType'>
                <!-- Months -->
                <ng-container *ngIf='feeView.durationType === "months"'>
                <span style='text-align: right;'>
                  <ng-container *ngLet='{
                    monthlyFee: feeView.fee.calculatedValues.monthlyFee,
                    months: feeView.fee.calculatedValues.months || 0,
                    isOpen: false
                  } as state'>
                    <ng-container *ngIf='editable || state.months || state.monthlyFee'>
                        <a
                          #pop='bs-popover'
                          (onHidden)='state.isOpen = false'
                          (onShown)='state.isOpen = true'
                          [class.always-visible]='state.isOpen'
                          [class.display-on-hover]='!state.months && !state.monthlyFee'
                          [outsideClick]='true'
                          [popover]='popover'
                          class='primary-on-hover text-nowrap'
                          role='button'
                        >
                          {{ (state.monthlyFee || 0) | currency }} &times; {{ state.months }} months
                      </a>

                      <ng-template #popover>
                        <fee-period-editor
                          (cancel)='pop.hide()'
                          (save)='emitFeeUpdate($event); pop.hide()'
                          [editable]='editable'
                          [feeSection]='feeSection'
                          [fee]='fee'
                        ></fee-period-editor>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </span>
                </ng-container>

                <!-- Days -->
                <ng-container *ngIf='feeView.durationType === "days"'>
                <span style='text-align: right;'>
                  <ng-container *ngLet='{
                    days: feeView.fee.calculatedValues.oddDays || 0,
                    isOpen: false
                  } as state'>
                    <ng-container *ngIf='editable || state.days'>
                      <a
                        #pop='bs-popover'
                        (onHidden)='state.isOpen = false'
                        (onShown)='state.isOpen = true'
                        [class.always-visible]='state.isOpen'
                        [class.display-on-hover]='!state.days'
                        [outsideClick]='true'
                        [popover]='popover'
                        class='primary-on-hover text-nowrap'
                        role='button'
                      >
                        {{ state.days }} days
                      </a>

                      <ng-template #popover>
                        <prepaid-interest-editor
                          (cancel)='pop.hide()'
                          (save)='emitFeeUpdate($event); pop.hide()'
                          [editable]='editable'
                          [feeSection]='feeSection'
                          [fee]='fee'
                        ></prepaid-interest-editor>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </span>
                </ng-container>
              </ng-container>
              <ng-template #nonDurationType>
                <!-- Column placeholder -->
                <span></span>
              </ng-template>
            </div>

            <div
              *ngLet='fee.activePayerOrDefaultNonPercent as activePayer'
              class='amount-column m-col'>
              <div class='at-closing-column'>
                <ng-container
                  *ngIf='editable && !feeView.durationType; else templateAtClosingTotal'
                >
                  <currency-input
                    *ngIf='
                      activePayer && !activePayer.atClosing.percent;
                      else templateAtClosingTotal
                    '
                    (change)='updateFee(fee)'
                    [(ngModel)]='activePayer.atClosing.dollar'
                    [ngModelOptions]='{standalone: true}'
                  ></currency-input>
                </ng-container>

                <ng-template #templateAtClosingTotal>
                  {{ fee.atClosingTotal | currency }}
                </ng-template>
              </div>

              <!-- Divider -->
              <div class='divider'></div>

              <div class='before-closing-column'>
                <ng-container
                  *ngIf='editable && !feeView.durationType; else templateBeforeClosingTotal'
                >
                  <currency-input
                    (change)='updateFee(fee)'
                    *ngIf='activePayer; else templateBeforeClosingTotal'
                    [(ngModel)]='activePayer.beforeClosing.dollar'
                    [ngModelOptions]='{standalone: true}'
                    placeholder='$0.00'
                  ></currency-input>
                </ng-container>

                <ng-template #templateBeforeClosingTotal>
                  {{ fee.beforeClosingTotal | currency }}
                </ng-template>
              </div>
            </div>

            <div class='payer-column m-col'>
              <ng-container *ngIf='fee.canBeSplit'>
                <!-- Split Fee Button -->
                <button
                  [outsideClick]='true'
                  [popover]='templateFeeSplitEditor'
                  class='btn btn-link btn-sm'
                  title='Split Fee'
                  #pop='bs-popover'
                >
                  <i class='fa fa-bars'></i>
                </button>

                <ng-template #templateFeeSplitEditor>
                  <fee-split-editor
                    (cancel)='pop.hide()'
                    (save)='emitFeeUpdate($event); pop.hide()'
                    [editable]='editable'
                    [fee]='fee'
                  ></fee-split-editor>
                </ng-template>
              </ng-container>

              <span
                *ngIf='fee.validPayers as payers'
                class='payer-names ms-1'
              >
              <ng-container *ngFor='let payer of payers; let last = last;'>
                <a
                  [outsideClick]='true'
                  [popover]='popover'
                  [title]='payer.name'
                  class='primary-on-hover'
                  role='button'
                >
                  {{ payer.abbrev }}
                </a>

                <ng-template #popover>
                  <div class='p-2 d-flex flex-column align-items-center gap-1'>
                    <b>{{ payer.name }}</b>

                    <span>{{ payer.total.calculatedDollar | currency }}</span>
                  </div>
                </ng-template>

                <div
                  *ngIf='!last'
                  class='divider'
                ></div>
              </ng-container>
            </span>
            </div>

            <div class='actions-column m-col'>
              <ng-container *ngIf='editable'>
                <!-- Edit -->
                <button
                  (click)='onEditFeeClick(fee)'
                  class='edit-button btn btn-link btn-sm'
                  title='Edit Fee'
                >
                  <i class='fa fa-pencil-alt'></i>
                </button>

                <!-- Delete -->
                <button
                  #pop='bs-popover'
                  *ngIf='fee.deletable'
                  [class.always-visible]='pop.isOpen'
                  [outsideClick]='true'
                  [popover]='confirmDeletePopover'
                  class='delete-button btn btn-link btn-sm'
                  title='Delete Fee'
                >
                  <i class='fa fa-trash-alt'></i>

                  <ng-template #confirmDeletePopover>
                    <div class='p-2 d-flex flex-column align-items-center gap-1'>
                      <b>Are you sure?</b>

                      <div class='d-flex gap-1'>
                        <button
                          (click)='pop.hide()'
                          class='btn btn-secondary btn-sm'
                        >
                          No
                        </button>

                        <button
                          (click)='onDeleteFeeClick(fee); pop.hide()'
                          class='btn btn-danger btn-sm'
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </button>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</section>
