<div class="row pt-3">
    <div class="col-sm-4">
        <div class="form-group mb-3">
            <label class="form-label mb-lg-0 text-start">Area Code</label>
            <div *ngIf="!isTollFree">
                <input class="form-control" placeholder="Area Code" name="area-code" type="text"
                       [(ngModel)]="areaCode"/>
            </div>
            <div *ngIf="isTollFree">
              <select id="area-code-toll-free" name="area-code-toll-free" class="form-select" [(ngModel)]="areaCode">
                <option value=""> -- Select Toll Free Area Code -- </option>
                <option [ngValue]="800">800</option>
                <option [ngValue]="888">888</option>
                <option [ngValue]="877">877</option>
                <option [ngValue]="866">866</option>
                <option [ngValue]="855">855</option>
                <option [ngValue]="844">844</option>
                <option [ngValue]="833">833</option>
              </select>
            </div>
            <div class="form-check ml-3">
              <input class="form-check-input ml-2" name="toll-free" type="checkbox" [(ngModel)]="isTollFree">
              <label class="form-check-label mb-lg-0 text-start">Toll Free</label>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="form-group" style="padding-top: 19px">
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="isFetchingNumbers"
                (click)="getPhoneList()"
            >
                <span *ngIf="!isFetchingNumbers">Get List</span>
                <span *ngIf="isFetchingNumbers">
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                </span>
            </button>
        </div>
    </div>
    <div class="col-sm-4" >
        <div class="form-group">
            <label for="messagingServices">Messaging Services</label>
            <select class="form-select" name="messagingServices" id="messagingServices"
            [(ngModel)]="messagingService" (ngModelChange)="this.messagingServiceSelected.emit(messagingService)" required>
                <option *ngFor="let service of messagingServices" [value]="service.id" >
                  {{service.name}}
                </option>
            </select>
        </div>
    </div>
    <div class="col-sm-12" *ngIf="phoneNumbers.length">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Please select a phone number and messaging service then click next</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="radio" *ngFor="let item of phoneNumbers; index as i">
                            <input type="radio" id="phoneNumber{{i}}" name="phoneNumber{{i}}" [value]="item"
                                   [(ngModel)]="phoneNumber" (ngModelChange)="this.phoneNumberSelected.emit(phoneNumber)">
                            <label for="phoneNumber{{i}}"> {{item.phoneNumber}} ({{item.locality ? item.locality + ", " + item.region : item.region}}{{item.postalCode ? " " + item.postalCode : ""}})</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
