<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Renewal & Extension
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #docPrepRenewalAndExtensionForm="ngForm" id="docPrepRenewalAndExtensionForm">
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="lienDescription"> Lien Description </label>
          <textarea id="lienDescription" name="lienDescription" class="form-control"
            [(ngModel)]="docPrep.lienDescription" rows="4">
          </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" name="lenderWillPrepareExhibit"
              id="lenderWillPrepareExhibit" [(ngModel)]="docPrep.lenderWillPrepareExhibit">
            <label class="form-check-label" for="lenderWillPrepareExhibit">Lender Will Prepare Exhibit</label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
