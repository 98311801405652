<div class="modal-body">
    <form #upsertLoanStatusAssociationForm="ngForm" ngbAutofocus class="row">
        <div *ngIf="enabledChannels.length > 0" class="mb-3">
            <label class="control-label">Channel</label>
            <select class="form-select" name="channel" [(ngModel)]="loanStatusAssociation.channel"
                [ngClass]="{ 'is-invalid' : channelField && channelField.touched && channelField.invalid }"
                #channelField="ngModel" [disabled]="enabledChannels.length === 1" required>
                <option value="null">-- Select One --</option>
                <option *ngFor="let c of enabledChannels" value="{{c.value}}">{{c.name}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label class="control-label">Loan Status</label>
            <select class="form-select"
                [ngClass]="{ 'is-invalid' : loanStatus && loanStatus.touched && loanStatus.invalid }" name="loanStatus"
                (change)="onStatusChanged(loanStatusAssociation.loanStatusId)"
                [(ngModel)]="loanStatusAssociation.loanStatusId" #loanStatus="ngModel" required>
                <option value="">-- Select One --</option>
                <option *ngFor="let status of loanStatuses" value="{{status.loanStatusId}}">
                    {{status.loanStatusName}}
                </option>
            </select>
            <div class="invalid-feedback">Loan status is required.</div>
        </div>
        <div class="mb-3">
            <label class="control-label">Disallowed Services</label>
            <ng-select width="100%" name="disallowedServices" bindLabel="name" bindValue="value"
                placeholder="Select Services" [multiple]="true" [closeOnSelect]="false"
                [(ngModel)]="disallowedServicesModel" [items]="disallowedThirdPartyServices">
            </ng-select>
        </div>

        <div class="mb-3">
          <label class="control-label">Readonly For Roles</label>
          <ng-multiselect-dropdown
            [(ngModel)]='rolesForWhichReadOnly'
            [settings]='accessSelectionSettings'
            [data]='rolesForWhichReadOnlyOptions'
            id='readOnlyForRoleIds'
            name='readOnlyForRoleIds'
            placeholder='-- Select --'>
          </ng-multiselect-dropdown>
        </div>

        <div class="mb-3">
          <label class="control-label">Hidden From Pipeline For Roles</label>
          <ng-multiselect-dropdown
            [(ngModel)]='rolesForWhichHidden'
            [settings]='accessSelectionSettings'
            [data]='rolesForWhichReadOnlyOptions'
            id='hiddenForRoleIds'
            name='hiddenForRoleIds'
            placeholder='-- Select --'>
          </ng-multiselect-dropdown>
        </div>

        <div class="mb-3">
            <label class="control-label">TPO Status Alias</label>
            <input type="text" class="form-control" name="tpoStatusAlias"
                [(ngModel)]="loanStatusAssociation.tpoStatusAlias" />
        </div>
        <div class="mb-3">
            <label class="control-label">Borrower Friendly Status</label>
            <input type="text" class="form-control" name="borrowerFriendlyStatus"
                [(ngModel)]="loanStatusAssociation.borrowerFriendlyStatus" />
            <div class="invalid-feedback">Borrower Friendly status is required.</div>
        </div>
        <div class="mb-3">
            <label class="control-label">Borrower Friendly Blurb</label>
            <textarea class="form-control" name="borrowerFriendlyBlurb" cols='50' rows='3'
                [(ngModel)]="loanStatusAssociation.borrowerFriendlyBlurb">
            </textarea>
        </div>

        <div class="mb-3">
            <div class="card p-0 m-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-success">
                                <input class="form-check-input" type="checkbox"
                                    name="supressEmailCampaignNotifications" id="supressEmailCampaignNotifications"
                                    [(ngModel)]="loanStatusAssociation.supressEmailCampaignNotifications">
                                <label class="form-check-label" for="supressEmailCampaignNotifications">
                                    Suppress Email & SMS Campaigns</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-success">
                                <input class="form-check-input" type="checkbox" name="supressSystemGeneratedNotifications"
                                    id="supressSystemGeneratedNotifications"
                                    [(ngModel)]="loanStatusAssociation.supressSystemGeneratedNotifications">
                                <label class="form-check-label" for="supressSystemGeneratedNotifications">
                                    Suspend System Generated Communication</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <div class="card p-0 m-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-success">
                                <input class="form-check-input" type="checkbox" name="hideFromPortals"
                                    id="hideFromPortals" [(ngModel)]="loanStatusAssociation.hideFromPortals">
                                <label class="form-check-label" for="hideFromPortals">Hide From Portals</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-success">
                                <input class="form-check-input" type="checkbox" name="loanAmountHiddenOnPortals"
                                    id="loanAmountHiddenOnPortals"
                                    [(ngModel)]="loanStatusAssociation.loanAmountHiddenOnPortals">
                                <label class="form-check-label" for="loanAmountHiddenOnPortals">
                                    Loan Amount Hidden On Portals</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-success">
                                <input class="form-check-input" type="checkbox" name="interestRateHiddenOnPortals"
                                    id="interestRateHiddenOnPortals"
                                    [(ngModel)]="loanStatusAssociation.interestRateHiddenOnPortals">
                                <label class="form-check-label" for="interestRateHiddenOnPortals">
                                    Interest Rate Hidden On Portals</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="control-label">Caution After Days In Status</label>
            <input type="number" numeric [allowNegative]="false" class="form-control numeric-without-arrows" name="cautionAfterDaysInStatus"
                [ngClass]="{ 'is-invalid' : cautionAfterDaysInStatus && cautionAfterDaysInStatus.touched && cautionAfterDaysInStatus.invalid }"
                [(ngModel)]="loanStatusAssociation.cautionAfterDaysInStatus" #cautionAfterDaysInStatus="ngModel"
                max="9999" />
            <div class="invalid-feedback">Value must be between 0 and 9999.</div>
        </div>
        <div class="mb-3">
            <label class="control-label">Warn After Days In Status</label>
            <input type="number" class="form-control" name="warnAfterDaysInStatus"
                [ngClass]="{ 'is-invalid' : warnAfterDaysInStatus && warnAfterDaysInStatus.touched && warnAfterDaysInStatus.invalid }"
                [(ngModel)]="loanStatusAssociation.warnAfterDaysInStatus" #warnAfterDaysInStatus="ngModel" min="0"
                max="9999" />
            <div class="invalid-feedback">Value must be between 0 and 9999.
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="button-items">
        <button type="button" class="btn btn-secondary" (click)="onClose.emit()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" style="width: 75px">
            <span *ngIf="!saving">Save</span>
            <span class="spinner-border spinner-border-sm" role="status" *ngIf="saving"></span>
        </button>
    </div>
</div>
