<form #form='ngForm'>
  <div class='m-header'>
    <h5>{{ effectiveFee.name }}</h5>

    <!-- Close button -->
    <button
      (click)='onCancel()'
      aria-label='Close'
      class='btn btn-icon close-btn'
      title='Close'
      type='button'
    >
      <i class='fas fa-times'></i>
    </button>
  </div>

  <div class='m-body'>
    <!-- Estimated closing -->
    <div class='m-row'>
      <ng-container *ngLet='{
        id: getId("estimated-closing"),
        value: ""
      } as state'>
        <label [for]='state'>Estimated closing</label>

        <date-input
          (blur)='onClosingDateChange(state.value)'
          (focusin)='state.value = calculatedValues.estimatedClosingDate'
          (selectedDate)='calculatedValues.estimatedClosingDate = $event; onClosingDateChange()'
          [(ngModel)]='calculatedValues.estimatedClosingDate'
          [id]='state'
          [readonly]='this.form?.controls?.estimatedClosing?.disabled ?? false'
          [required]='editable'
          class='col-span-2'
          name='estimatedClosing'
        ></date-input>
      </ng-container>
    </div>

    <!-- Paid through -->
    <div class='m-row'>
      <ng-container *ngLet='getId("first-payment-date") as id'>
        <label [for]='id'>Paid through</label>

        <date-input
          [id]='id'
          [ngModel]='calculatedValues.paidThroughDate'
          [readonly]='true'
          class='col-span-2'
          name='firstPaymentDate'
        ></date-input>
      </ng-container>
    </div>

    <!-- Number of days -->
    <div class='m-row'>
      <ng-container *ngLet='getId("number-of-days") as id'>
        <label [for]='id'>Number of days</label>

        <input
          [id]='id'
          [ngModel]='calculatedValues.oddDays'
          [ngModelOptions]='{ standalone: true }'
          class='col-span-2'
          placeholder='0'
          type='number'
          disabled
        />
      </ng-container>
    </div>

    <!-- Calculation method -->
    <div class='m-row'>
      <ng-container *ngLet='getId("calculation-method") as id'>
        <label [for]='id'>Calculation method</label>

        <select
          (ngModelChange)='calculatedValues.calculationMethod = $event; updateFees()'
          [id]='id'
          [ngModel]='calculatedValues.calculationMethod'
          class='col-span-2'
          name='calculationMethod'
          required
        >
          <option
            *ngFor='let option of calculationMethodOptions'
            [ngValue]='option.value'
          >
            {{ option.name }}
          </option>
        </select>
      </ng-container>
    </div>

    <div class='m-divider'></div>

    <!-- Total fee amount -->
    <div class='m-row'>
      <ng-container *ngLet='getId("total-fee-amount") as id'>
        <label [for]='id'>Total fee amount</label>

        <span>{{ effectiveFee.total | currency }}</span>
      </ng-container>
    </div>

    <!-- Seller concessions -->
    <div class='m-row'>
      <ng-container *ngLet='getId("seller-concessions") as id'>
        <label [for]='id'>Seller concessions</label>

        <span>{{ calculatedValues.sellerConcessions || 0 | currency }}</span>
      </ng-container>
    </div>

    <!-- Borrower paid -->
    <div class='m-row'>
      <ng-container *ngLet='getId("borrower-paid") as id'>
        <label [for]='id'>Borrower paid</label>

        <span>{{ calculatedValues.borrowerTotal || 0 | currency }}</span>
      </ng-container>
    </div>
  </div>

  <div class='m-footer'>
    <ng-container *ngIf='editable; else nonEditable'>
      <!-- Cancel button -->
      <button
        (click)='onCancel()'
        [disabled]='isSaving'
        aria-label='Cancel'
        class='btn btn-outline-light'
        title='Cancel'
        type='button'
      >
        Cancel
      </button>

      <!-- Save button -->
      <button
        (click)='onSave()'
        [disabled]='isSaving'
        aria-label='Save'
        class='btn btn-primary'
        title='Save'
        type='button'
      >
        Save
      </button>
    </ng-container>

    <ng-template #nonEditable>
      <!-- Close button -->
      <button
        (click)='onCancel()'
        aria-label='Close'
        class='btn btn-primary'
        title='Close'
        type='button'
      >
        Close
      </button>
    </ng-template>
  </div>
</form>
