import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { finalize } from 'rxjs';
import { Key, ThirdPartyCredential } from 'src/app/models';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from "../../../../../../services/system-level.service";
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'app-du-credential-editor-dialog',
  templateUrl: './du-credential-editor-dialog.component.html',
  styleUrls: ['./du-credential-editor-dialog.component.scss']
})
export class DuCredentialEditorDialogComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;
  @Output() onCredentialSaved = new EventEmitter();
  @Input() credential: ThirdPartyCredential;
  @Input() userId: string;
  @Input() companyId: number;
  @Input() channels: EnumerationItem[];
  @Input() scope: ScopeType;
  @Input() branchId?: number;

  duCredential: ThirdPartyCredential = null;

  duCreditProviders: Array<Key> = [];

  duKeyValuePairs: any = {
    defaultCreditProvider: { key: 'DefaultCreditProvider', value: '', alias: '', userName: '', password: '' },
    creditProviders: [],
    institutions: []
  };

  loadingDuAgencies: boolean = false;
  duFannieMaeLoadingError: boolean = false;
  isSaving: boolean = false;
  agenciesRequested = false;
  selectedChannels: string[];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _channelService: ChannelService,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
  ) { }

  ngOnInit(): void {

    if (this.scope == 'TpoUser') {
      this.channels = this.channels.filter(x => x.name != 'Retail');
    }

    if (!this.credential) {
      this.duCredential = {
        credentialType: 'AusVendor',
        vendorName: 'DU',
        thirdPartyKeyValuePairs: [
          { key: 'DefaultCreditProvider', value: '', alias: '', userName: '', password: '' }
        ],
        alias: '',
        url: '',
        userName: '',
        password: '',
        userId: this.userId,
        companyId: this.companyId,
        active: true
      };

      if (this.scope == 'TpoUser') {
        this.selectedChannels = this.channels.map(channel => channel.value);
        this.setCredentialChannels();
      }

      if (this.scope === 'Branch') {
        this.duCredential.branchId = this.duCredential.branchId || String(this.branchId);
      }
    }
    else {
      if(this.scope == 'TpoUser')
        this.credential.active = true;

      this.duCredential = _.cloneDeep(this.credential);
      this.duCredential.userId = this.duCredential.userId || this.userId;
      
      if (this.scope === 'Branch') {
        this.duCredential.branchId = this.duCredential.branchId || String(this.branchId);
      }
      
      this.duCredential.companyId = this.duCredential.companyId || this.companyId;
    }

    if (this.channels.length > 0) {
      this.selectedChannels = this._channelService
        .getChannelsListForDropdown(this.credential.channels)
        .map(channel => channel.value);
    }

    const defaultCreditProvider = this.duCredential.thirdPartyKeyValuePairs.find(el => el.key === 'DefaultCreditProvider');
    if (defaultCreditProvider) {
      this.duKeyValuePairs.defaultCreditProvider = this.duCredential.thirdPartyKeyValuePairs.find(el => el.key === 'DefaultCreditProvider') || {};
    }
    this.duKeyValuePairs.creditProviders = this.duCredential.thirdPartyKeyValuePairs
      .filter(el => el.key?.includes('CreditProvider:'))
      .map(el => ({ ...el, key: el.key.replace('CreditProvider:', '') }));

    let value;
    this.duKeyValuePairs.institutions = this.duCredential.thirdPartyKeyValuePairs
      .filter(el => el.key === 'Institution')
      .map(el => {
        value = el.value ? el.value.split(',') : ['', '']
        return {
          ...el,
          name: value[0],
          institutionId: value[1]
        }
      });

    if (this.duCredential && this.duCredential.credentialId && this.duCredential.active || this.scope == 'TpoUser') {
      this.loadDuCreditProviders();
    } else {
      this.agenciesRequested = true;
    }
  }

  loadDuCreditProviders = () => {
    if (this.scope != 'TpoUser' && (!this.credential.userName || !this.credential.password)) {
      this.duFannieMaeLoadingError = true;
      return;
    }
    this.loadingDuAgencies = true;
    this._loanServicesService.getDuAgencies(true,this.scope != 'TpoUser' ? this.duCredential.credentialId : null)
      .pipe(finalize(() => this.loadingDuAgencies = false))
      .subscribe({
        next: (result) => {
          this.duFannieMaeLoadingError = false;
          this.agenciesRequested = true;
          this.isSaving = false;
          if (!result || !result.length) {
            this._notificationService.showError('No credit providers found', 'Credit Agencies');
          }
          this.duCreditProviders = _.orderBy(
            result.map(el => ({ key: el.name, displayText: el.name })),
            ['displayText', 'asc']
          );
        },
        error: (err) => {
          this.duCreditProviders = [];
          this.duFannieMaeLoadingError = true;
          this.agenciesRequested = true;
          this.isSaving = false;
          this._notificationService.showError(err?.message || 'Unable to load credit agencies', 'Credit Agencies');
        }
      });
  }

  addCreditProvider = () => {
    this.duKeyValuePairs.creditProviders.push({
      key: '',
      value: '',
      alias: '',
      userName: '',
      password: ''
    });
  }

  addInstitution = () => {
    this.duKeyValuePairs.institutions.push({
      key: 'Institution',
      name: '',
      institutionId: '',
    });
  }

  saveCredential = () => {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) {
      return;
    }
    this.setCredentialActiveIfChanged();
    this.duCredential.thirdPartyKeyValuePairs = [
      this.duKeyValuePairs.defaultCreditProvider,
      ...this.duKeyValuePairs.creditProviders.map(el => ({
        ...el,
        key: `CreditProvider:${el.key}`,
        value: `${el.userName},${el.password}`
      })),
      ...this.duKeyValuePairs.institutions.map(el => ({
        ...el,
        value: `${el.name},${el.institutionId}`
      }))
    ]

    this.activeModal.close(this.duCredential);
  }

  saveAndLoadCreditProviders = () => {
    this.isSaving = true;
    this.setCredentialActiveIfChanged();
    this._systemLevelService.saveCredential(this.duCredential).subscribe({
      next: (response) => {
        this.duCredential = response;
        this.onCredentialSaved.next(response);
        this.loadDuCreditProviders();
      },
      error: (err) => {
        this.isSaving = false;
        this._notificationService.showError(err?.message || "Couldn't save credential", "Credential");
      }
    });
  }

  setCredentialChannels(): void {
    this.duCredential.channels = this.selectedChannels.length ? this.selectedChannels.join(', ') : '';
  }

  setCredentialActiveIfChanged = () => {
    if (
      this.duCredential.userName !== this.credential.userName ||
      this.duCredential.password !== this.credential.password
    ) {
      this.duCredential.active = true;
    }
  };

}
