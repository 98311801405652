<div class="modal-header">
    <h5 class="modal-title">Esign Document</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div *ngIf="!isLoading" style="width: 100%; height: 800px;">
        <iframe *ngIf="signerViewUrl && (!documentSigningVendor || documentSigningVendor === 'DocuSign')" [src]="signerViewUrl" width="100%" height="100%">
        </iframe>

        <iframe *ngIf="signerViewUrl && documentSigningVendor === 'DocMagic'" sandbox="allow-forms allow-scripts" [src]="signerViewUrl" width="100%" height="100%">
        </iframe>
    </div>
</div>
<div class="modal-footer" [hidden]="signerViewUrl">
    <button type="button" class="btn btn-link" style="text-decoration: none;" (click)="activeModal.close('cancel')">Cancel</button>
</div>