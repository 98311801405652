import { Component, Input } from '@angular/core';
import { LeadEmployment } from 'src/app/modules/leads/models/lead-employment.model';
import { Lead } from '../../../models/lead.model';

@Component({
  selector: 'lead-employment',
  templateUrl: 'lead-employment.component.html',
  styleUrls: ['./lead-employment.component.scss']
})
export class LeadEmploymentComponent {

  @Input()
  employmentBorrower: any

  @Input()
  employmentCoBorrower: any

  @Input()
  set lead(lead: Lead) {
    this._lead = lead;
  }

  @Input()
  set employments(employments: LeadEmployment[]) {
    this._employments = employments;
  }

  get lead(): Lead {
    return this._lead;
  }

  get employments(): LeadEmployment[] {
    return this._employments;
  }

  incomes: any[] = [];
  incomeTotal: number = 0;
  grandTotalIncome: number = 0;

  private _lead: Lead;
  private _employments: LeadEmployment[] = [];

  constructor() {}

  grandTotalIncomeChanged = () => {
    this.grandTotalIncome = 0;

    this.grandTotalIncome += this.employmentBorrower?.calculatedStats?.totalMonthlyIncome || 0;
    this.grandTotalIncome += this.employmentCoBorrower?.calculatedStats?.totalMonthlyIncome || 0;
  }
}
