<div class="modal-header">
  <h5 class="modal-title w-100">Import Lead</h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <h5 *ngIf="!fileToUpload">Please upload a MISMO 3.4 or FNM 3.2 file.</h5>

  <button
    class="btn btn-primary"
    (click)="uploadLeadDuFile.click()"
  >
    Choose File
  </button>

  <em class="ms-3" *ngIf="fileToUpload">{{ fileToUpload.name }} </em>

  <form
    class="drop_form"
    file=""
    type="file"
    runat="server"
    enctype="multipart/form-data"
  >
    <input
      style="display: none"
      id="uploadLeadDuFile"
      #uploadLeadDuFile
      name="UploadView"
      type="file"
      (change)="validateDuFile($event.target.files)"
    />
  </form>

  <div class="text-danger" *ngIf="message">
    {{ message }}
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-soft-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="submit"
    class="btn btn-soft-primary "
    (click)="importLeadDuFile()"
    [disabled]="!fileToUpload"
  >
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
      *ngIf="isSaving"
    ></span>
    <i class="fa fa-file-import" *ngIf="!isSaving"></i> İmport
  </button>
</div>
