<ng-template [ngIf]="loading" [ngIfElse]="content">
  <loading-indicator class="no-shadow"></loading-indicator>
</ng-template>

<ng-template #content>
  <div class="modal-body">
    <p-table
      [value]="monthYears"
      [frozenValue]="lockedRows"
      [scrollable]="true"
      [scrollHeight]="scrollHeight"
      styleClass="p-datatable-gridlines p-datatable-sm"
      responsiveLayout="scroll"
      [autoLayout]="true"
    >
      <ng-template pTemplate="caption">
        <div class="row form-group mb-0">
          <label class="form-label col-md-3 align-self-center"
            >First Payment Date</label
          >
          <div class="col-md-3">
            <input
              class="form-control"
              name="firstPaymentDate"
              id="firstPaymentDate"
              type="date"
              [ngModel]="firstPaymentDate | date : 'yyyy-MM-dd'"
              (ngModelChange)="onFirstPaymentDateChanged($event)"
              required
              #firstPaymentDateInput="ngModel"
              [ngClass]="{
                'is-invalid':
                  firstPaymentDateInput && firstPaymentDateInput.invalid
              }"
            />
            <div class="invalid-feedback">
              First Payment Date must be required
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 85px !important">Date</th>
          <th
            style="min-width: 100px !important"
            *ngFor="let feeSchedule of escrowSchedule.feeSchedules"
          >
            {{ getEnumName(feeSchedule.feeType) }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="frozenbody" let-row let-index="rowIndex">
        <tr>
          <td style="min-width: 85px !important">{{ row.name }}</td>
          <td
            style="min-width: 100px !important"
            *ngFor="let feeSchedule of escrowSchedule.feeSchedules"
          >
            <div *ngIf="index == 0">
              <select
                class="form-select"
                id="cushionMonths-{{ feeSchedule.feeType }}"
                name="cushionMonths-{{ feeSchedule.feeType }}"
                [(ngModel)]="feeSchedule.cushionMonths"
                (ngModelChange)="onFieldChanged()"
              >
                <option [ngValue]="0">0</option>
                <option [ngValue]="1">1</option>
                <option [ngValue]="2">2</option>
              </select>
            </div>
            <div *ngIf="index > 0">
              <input
                class="form-control"
                name="duedate{{ index }}-{{ feeSchedule.feeType }}"
                id="duedate{{ index }}-{{ feeSchedule.feeType }}"
                type="date"
                [ngModel]="feeSchedule.disbursements[index - 1].disbursementDate | date : 'yyyy-MM-dd'"
                (ngModelChange)="onDueDateChanged(feeSchedule, index - 1, $event)"
                min="{{ firstPaymentDate | date : 'yyyy-MM-dd' }}"
              />
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-mYear let-index="rowIndex">
        <tr>
          <td>{{ mYear.monthYear }}</td>
          <td
            style="min-width: 100px !important"
            *ngFor="let feeSchedule of escrowSchedule.feeSchedules"
          >
            <div class="row">
              <div class="col-md-5">
                <input
                  type="number"
                  numeric
                  [allowNegative]="false"
                  id="months-{{ feeSchedule.feeType }}-{{ mYear.monthYear }}"
                  name="months-{{ feeSchedule.feeType }}-{{ mYear.monthYear }}"
                  [(ngModel)]="mYear.values[feeSchedule.feeType].months"
                  class="form-control numeric-without-arrows"
                  (ngModelChange)="onMonthsChanged(feeSchedule, mYear)"
                  [readonly]="
                    isAllDueDateFilled(feeSchedule) &&
                    !mYear.values[feeSchedule.feeType].months
                  "
                />
              </div>
              <div class="col-md-7 d-flex align-items-center">
                <span
                  [ngClass]="{
                    'fw-bold': !!mYear.values[feeSchedule.feeType].amount
                  }"
                  >{{
                    mYear.values[feeSchedule.feeType].amount | currency
                  }}</span
                >
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <span class="fw-normal">Aggregate Adjustment: </span> {{ escrowSchedule.aggregateAdjustmentAmount || 0 | currency }}
      </ng-template>
    </p-table>
  </div>
</ng-template>
