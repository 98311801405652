import { AfterViewInit, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';

export class PizzaTrackerStep {
  id: string;
  name: string;
  description?: string;
  order: number;
  status: MenuItemStatus;
  navigateToPath?: string;
  hiddenIcon?: boolean
}

@Component({
  selector: 'pizza-tracker',
  templateUrl: './pizza-tracker.component.html',
  styleUrls: ['./pizza-tracker.component.scss']
})
export class PizzaTrackerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() steps: PizzaTrackerStep[] = [];
  @Input() activeStepOrder: number;

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.steps && !changes.steps.isFirstChange()) {
      setTimeout(() => {
        this.adjustBar();
      }, 100);
    }
  }

  ngAfterViewInit(): void {
    this.adjustBar();
  }

  @HostListener('window:resize')
  onWindowResize = () => {
    this.adjustBar();
  }

  navigateToMenu = (path: string) => {
    this._router.navigate([path]);
  }

  // https://codepen.io/hari_shanx/pen/rVBEyJ
  private adjustBar = () => {
    const items = Array.from(document.querySelectorAll(".steps")) as any[];

    if (!items.length) {
      return;
    }

    const item = items[0];
    const elHeight = item.offsetHeight / 2; //Division by 2 because each pseudo which is skewed is only 50% of its parent.
    const skewOffset = Math.tan(45 * (Math.PI / 180)) * elHeight;
    const reduction = skewOffset + ((items.length - 1) * 4);
    const leftOffset = window.getComputedStyle(item, null).getPropertyValue("left");
    const factor = parseInt(leftOffset) * (-1) - 2;

    items.forEach(item => {
      // 4px for borders on either side
      item.style.width = 'calc((100% + 4px - ' + reduction + 'px) / ' + items.length + ')';
    });

    var itemsLoc = Array.from(document.querySelectorAll(".steps:first-child, .steps:last-child")) as HTMLElement[];
    itemsLoc.forEach(item => {
      // 26px because to make up for the left offset. Size of last-child is also increased to avoid the skewed area on right being shown
      item.style.width = 'calc((100% + 4px - ' + reduction + 'px) / ' + items.length + ' + ' + factor + 'px)';
    });

    // for fix bug
    itemsLoc = Array.from(document.querySelectorAll(".steps:last-child")) as HTMLElement[];
    itemsLoc.forEach(item => {
      item.style.width = '100%';
    });

    itemsLoc = Array.from(document.querySelectorAll(".steps span")) as HTMLElement[];
    itemsLoc.forEach(item => {
      item.style.paddingLeft = (skewOffset + 15) + "px";
    });

    itemsLoc = Array.from(document.querySelectorAll(".steps:first-child span, .steps:last-child span")) as HTMLElement[];
    itemsLoc.forEach(item => {
      item.style.width = 'calc(100% - ' + factor + 'px)';
    });
  }
}
