<form
    class="form-horizontal"
    id="credentialForm"
    name="credentialForm"
    #credentialForm="ngForm"
    (ngSubmit)="saveCredential()"
    novalidate
>
    <div class="modal-header">
        <h5 class="modal-title">{{credential.credentialId ? 'Edit' : 'Add'}} Credential</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <credential-modal-basic
                    [scope]="scope"
                    [isLosCredentials]="isLosCredentials"
                    [vendors]="vendors"
                    [credential]="credential"
                    [vendorSelectionDisabled]="credential.credentialId > 0"
                    (onChangeVendor)="onChangeVendor($event)"
                >
                </credential-modal-basic>
            </div>
            <ng-container *ngIf="vendorSchema">
                <div class="col-md-12 mt-1" *ngIf="
                    (
                        !vendorSchema.fixedBaseUrl
                        && !(scope !== 'Company' && credential.credentialType === 'LosVendor')
                    )
                    && !(scope == 'TpoUser' && vendorSchema.vendorName === 'MeridianLink')
                ">
                    <label class="custom-control-label">Url</label>
                    <input
                        type="text"
                        class="form-control"
                        name="url"
                        [(ngModel)]="credential.url"
                    />
                </div>
                <div
                    class="mt-1 col-md-6"
                    *ngIf="vendorSchema.hasUserName"
                >
                    <label class="custom-control-label">Username</label>
                    <input
                        type="text"
                        class="form-control"
                        name="userName"
                        [(ngModel)]="credential.userName"
                    />
                </div>
                <div class="col-md-6 mt-1" *ngIf="
                    (scope === 'Company' && vendorSchema.hasPassword)
                    || (scope !== 'Company' && credential.credentialType === 'LosVendor' && !isTpoUser && vendorSchema.hasPassword)
                ">
                    <label class="custom-control-label">Password</label>
                    <password-input
                        [name]="'password'"
                        [(ngModel)]="credential.password"
                    ></password-input>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="vendorSchema">
            <div
                class="row mt-1"
                *ngFor="
                    let kv of vendorSchema.kvPairs;
                    let index = index
                "
            >
                <div class="col-md-12" *ngIf="kvPairs[kv.name]">
                    <label class="custom-control-label">{{ kv.displayName }}</label>
                        <ng-container *ngIf="kv.name !== 'LoanFolders'">
                            <input
                                *ngIf="additionalConfigValueFieldType === 'Text'"
                                type="text"
                                class="form-control"
                                name="value_{{ index }}"
                                [ngClass]="{'is-invalid' : password && password.touched && password.invalid}"
                                #password[index]="ngModel"
                                [(ngModel)]="kvPairs[kv.name].value"
                                [required]="!kv.optional"
                            />
                            <password-input
                                *ngIf="additionalConfigValueFieldType === 'Password'"
                                name="value_{{ index }}"
                                [ngClass]="{'is-invalid' : password && password.touched && password.invalid}"
                                #password="ngModel"
                                [(ngModel)]="kvPairs[kv.name].value"
                                [required]="!kv.optional"
                            ></password-input>
                            <div *ngIf="password?.errors?.['required']" class="invalid-feedback">This field is required.</div>
                        </ng-container>


                    <tag-input
                        *ngIf="credential.credentialType === 'LosVendor' && kv.name === 'LoanFolders'"
                        theme="bootstrap"
                        name="LoanFolders"
                        [inputClass]="'form-control'"
                        [ngModel]="loanFolders"
                        [placeholder]="'+ loan folder'"
                        [secondaryPlaceholder]="'+ loan folder'"
                        (onAdd)="addLoanFolder($event)"
                        (onRemove)="removeLoanFolder($event)"
                    ></tag-input>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer form-footer">
        <button
            type="button"
            class="btn btn-secondary right"
            (click)="activeModal.dismiss()"
        >
            Cancel
        </button>
        <button
            *ngIf="
                credential.credentialType === 'LosVendor'
                && !!credential.vendorName
                && (saveAndTest || scope === 'Company')
            "
            type="button"
            class="btn btn-primary right"
            (click)="saveAndTestCredential()"
            [disabled]="isTestingAndSaving || isSaving"
        >
            <span *ngIf="!isTestingAndSaving"><i class="fa fa-save me-2"></i> Save & Test</span>
            <span *ngIf="isTestingAndSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving & Testing
            </span>
        </button>
        <button
            type="submit"
            class="btn btn-primary right"
            [disabled]="isTestingAndSaving || isSaving"
        >
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>
</form>
