import { Component, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ApplicationContext,
  Borrower,
  DataExchangeSyncDir,
  LoanApplication,
  LoanDoc,
  LoanStatus,
  LosLdeConnectionStatus,
  UserPermissions,
} from '../../../../models';
import { UrlaMortgage } from '../../../urla/models/urla-mortgage.model';
import { LoanPurpose, TransactionType } from '../../../../models/config/loan-purpose.model';
import { LoanType } from '../../../../models/config/loan-type.model';
import { BorrowerCharacteristic, LoanCharacteristic } from '../../../../models/characteristics';
import { Subscription } from 'rxjs';
import { AppDetailsService } from '../../services/app-details.service';
import { MortgageService } from '../../../../services/mortgage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoanService } from '../../../../services/loan';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../../services/notification.service';
import { MortgageCalculationService } from '../../../urla/services/mortgage-calculation.service';
import { NavigationEnd, Router } from '@angular/router';
import { Constants } from '../../../../services/constants';
import { LoanDocDashboardTask } from '../../../../models/borrower/loan-doc-dashboard-task.model';
import { isEmpty } from 'lodash';
import { EditTaskDialogComponent } from '../../../tasks/components/edit-task-dialog/edit-task-dialog.component';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { Utils } from 'src/app/core/services/utils';
import { IHaveMortgageLtvCalculationDetails, MortgageCalculationDetails, MortgageDtiCalculationDetails, MortgageFundsToCloseCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { TaskTypeEnum } from 'src/app/models/task/task.model';

@Component({
  selector: 'admin-loan-summary-top-bar',
  templateUrl: './admin-loan-summary-top-bar.component.html',
  styleUrls: ['./admin-loan-summary-top-bar.component.scss'],
})
export class AdminLoanSummaryTopBarComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  borrowerId: number;

  @Input()
  isDocumentSigningRequiredReview: boolean;

  @Input()
  isNiceInContactVisible: boolean;

  @Output()
  loadApplicationTaskCounts: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanActivityToggled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  setAppointmentClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sendEmailSmsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sendEmailClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  emailSmsHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewTitleHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanSummaryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  disclosureTrackingClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  identifyingDocumentsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewEsignatureHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  exportMismoClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  exportDUClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  importMismoClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  keyDatesClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  mcrAuditDataClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  benefitCalculatorsclicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  monsterInsightsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  checkListsClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  generateDocClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  denialClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  creditInquiryClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  createWireRequestClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  purchaseAdviceClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  reloadTasks: EventEmitter<any> = new EventEmitter<any>();

  states: any;

  application: LoanApplication;
  mortgage: UrlaMortgage;

  borrowers: Borrower[] = [];
  permissions: UserPermissions;

  onlineAppEnabled: boolean;
  onlineAppTaskExists: boolean;

  loanBeamEnabled: boolean;
  loanBeamDocSyncTaskExists: boolean;

  hasRateLockExpirationKeyDate: boolean;

  protected isLoanHeaderExpanded: boolean;

  losEnabled: boolean;
  ldeEnabled: boolean;

  losConnectionStatus: LosLdeConnectionStatus;
  ldeConnectionStatus: LosLdeConnectionStatus;

  showESignTaskOption: boolean;

  protected get isSolarTransactionType(): boolean {
    return this.loanPurposeTransactionType === 'Solar';
  }

  protected get showLosLdeButton(): boolean {
    const {
      losEnabled,
      ldeEnabled,
    } = this;

    return (losEnabled || ldeEnabled) && !this.isSolarTransactionType;
  };

  private _loanPurposes: LoanPurpose[];
  private _loanTypes: LoanType[];
  private _loanStatuses: LoanStatus[];

  ficoOnFile: number | undefined;

  interestRate: number | undefined;

  dtiDetails: MortgageDtiCalculationDetails = null;
  ftcDetails: MortgageFundsToCloseCalculationDetails = null;
  ltvDetails: IHaveMortgageLtvCalculationDetails = null;

  ftcSectionAmounts: any = {};

  currentLoanStatusName: string;

  urlaFormOpened: boolean = false;

  isRefinance: boolean = false;

  borrowerCharacteristics: BorrowerCharacteristic[] = [];

  loanCharacteristics: LoanCharacteristic[] = [];

  loanPurposeTransactionType: TransactionType;

  systemGenerateDocumentMenuActive: boolean = false;
  internalMenuActive: boolean = false;
  borrowerMenuActive: boolean = false;
  quickClientUpdateMenuActive: boolean = false;

  primaryBorrower: Borrower = null;

  borrowerName: string = null;

  applicationIdWithPadding: string = null;

  loanPurposeName: string = null;

  loanTypeName: string = null;

  dialerEnabled: boolean = false;

  private _loanInfoChangesSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _taskSubscription: Subscription;
  private _routerEventsSubscription: Subscription;
  private _keyDatesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _mortgageService: MortgageService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _router: Router,
  ) {
    super(injector);

    this._routerEventsSubscription = this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.urlaFormOpened = val.url.includes('/urla');
      }
    });
  }

  ngOnInit() {
    this._contextSubscription = this.applicationContextService.context.subscribe((context) => {
      this._loanTypes = context.globalConfig.loanType;
      this._loanPurposes = context.globalConfig.loanPurpose;
      this._loanStatuses = context.globalConfig.loanStatus;
      this.states = context.globalConfig.states;
      this.permissions = this.applicationContext.userPermissions;
      this.showESignTaskOption = this.permissions.documentSigningEnabled;
      this.onlineAppEnabled = this.permissions.onlineApplicationEnabled;
      this.loanBeamEnabled = this.permissions.loanBeamEnabled;
      this.losEnabled = this.permissions.losEnabled;
      this.ldeEnabled = this.permissions.ldeEnabled;
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.isRefinance = this._mortgageCalculationsService.isPurposeOfLoanRefinance(context.currentMortgage);
        this.populateLoanInfo(context);
        if (this.onlineAppEnabled) {
          this.checkForOnlineAppTask();
        }
        this.checkForLoanBeamDocSyncTask();
        var currentLoanStatus = this._loanStatuses.find(x => x.loanStatusId === context.application.loanStatusId);
        if (currentLoanStatus) {
          this.currentLoanStatusName = currentLoanStatus.loanStatusName;
        }
      }
    });

    this._taskSubscription = this.applicationContextService.loanTasksChanges.subscribe((context) => {
      if (!context.application) {
        return;
      }
      const newLoanStatus = this._loanStatuses.find(s => s.loanStatusId == this.application.loanStatusId);
      this.currentLoanStatusName = newLoanStatus?.loanStatusName;
    });

    this._keyDatesSubscription = this.applicationContextService.keyDatesChanges.subscribe((changes) => {
      const keyDatesByType = changes.keyDatesByType;
      if (keyDatesByType) {
        this.hasRateLockExpirationKeyDate = keyDatesByType ? !!keyDatesByType['rateLockExpiration'] : false;
      }
    })

    this.onWindowResize();
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
    this._routerEventsSubscription?.unsubscribe();
    this._taskSubscription?.unsubscribe();
    this._keyDatesSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  @HostListener('window:resize')
  onWindowResize = () => {
    this.isLoanHeaderExpanded = window.innerWidth > 1429;
  };

  onCloseLoanSummaryMoreMenu = () => {
    document.getElementById('buttonMenu').click();
  };

  exportUrlaDocs = () => {
    this._spinnerService.show();
    this._mortgageService.exportUrlaDocs(this.mortgage.applicationId).subscribe({
      next: () => {
        this._spinnerService.hide();
        this._notificationService.showSuccess("URLA docs exported successfully as loan docs.", "Success!");
      },
      error: error => {
        this._spinnerService.hide();
        this._notificationService.showError(error ? error.message : "An error occurred while exporting URLA docs.", "Error!")
      }
    });
  }

  onCreditInquiryClicked = () => {
    this.creditInquiryClicked.emit();
  }

  onCreateWireRequestClicked = () => {
    this.createWireRequestClicked.emit();
  }

  onPurchaseAdviceClicked = () => {
    this.purchaseAdviceClicked.emit();
  }

  onCalculatorsClicked = () => {
    this.benefitCalculatorsclicked.emit();
  };

  onMonsterInsightsClicked = () => {
    this.monsterInsightsClicked.emit();
  };

  onSendEmailSmsClicked = () => {
    this.sendEmailSmsClicked.emit();
  };

  onSendEmailClicked = () => {
    this.sendEmailClicked.emit();
  };

  onEmailSmsHistoryClicked = () => {
    this.emailSmsHistoryClicked.emit();
  };

  onSetAppointmentClicked = () => {
    this.setAppointmentClicked.emit();
  };

  onViewEsignatureHistoryClicked = () => {
    this.viewEsignatureHistoryClicked.emit();
  };

  onExportMismoClicked = () => {
    this.exportMismoClicked.emit();
  };

  onExportDUClicked = () => {
    this.exportDUClicked.emit();
  };

  onImportMismoClicked = () => {
    this.importMismoClicked.emit();
  };

  onKeyDatesClicked = () => {
    this.keyDatesClicked.emit();
  };

  onMcrAuditDataClicked = () => {
    this.mcrAuditDataClicked.emit();
  };

  onViewTitleHistoryClicked = () => {
    this.viewTitleHistoryClicked.emit();
  };

  onCheckListsClicked = () => {
    this.checkListsClicked.emit();
  };

  onDenialClicked = () => {
    this.denialClicked.emit();
  };

  onGenerateDocClicked = (isPrequalFilterActive: boolean = false) => {
    this.generateDocClicked.emit(isPrequalFilterActive);
  };

  onLoanSummaryClicked = () => {
    this.loanSummaryClicked.emit();
  };

  onDisclosureTrackingClicked = () => {
    this.disclosureTrackingClicked.emit();
  };

  onIdentifyingDocumentsClicked = () => {
    this.identifyingDocumentsClicked.emit()
  }

  onAddNewTaskClicked = (e: any) => {
    this.addNewTask(TaskTypeEnum.RequestDocument, e.condition, e.requestBorrower, e.loanDoc);
  }

  addNewTask = (taskType: TaskTypeEnum, condition: boolean, requestBorrower: boolean, loanDoc: LoanDoc) => {
    let task = new LoanDocDashboardTask();

    task.documentTypeId = 0;
    task.applicationId = this.application.applicationId;
    task.description = '';
    task.note = '';
    task.loanDocTaskId = 0;
    task.taskType = taskType;
    task.dueDays = 0;
    task.taskPriority = 'Normal';
    task.userId = this.permissions.userId;
    task.condition = condition;
    task.requestBorrower = requestBorrower;
    task.multipleBorrower = [];

    if (taskType == TaskTypeEnum.EsignDocument) {
      task.requiresReview = this.isDocumentSigningRequiredReview;
      if (task.requiresReview) {
        task.reviewPartyId = task.userId;
      }
    }

    if (loanDoc && !isEmpty(loanDoc)) {
      // task.docFiles = loanDoc.docFiles;
      task.loanDocId = loanDoc.loanDocId;
      if (taskType == TaskTypeEnum.EsignDocument) {
        task.documentTypeId = loanDoc.documentTypeId;
        task.description = loanDoc.description;
        task.note = loanDoc.note;
      }
    }

    const modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.ninetyPercentOfScreenWidth);
    modalRef.componentInstance.task = task;
    modalRef.result.then(result => {

      if (result === 'cancel') {
        console.log('cancel');
      } else {
        this.reloadTasks.emit();
      }
    }, (err) => {
    });
  };

  onAddLoanBeamDocSyncTaskClicked = () => {
    this.addLoanBeamDocSyncTask();
  }

  addLoanBeamDocSyncTask = () => {
    this._appDetailsService.loanBeamDocSyncTask(this.application.applicationId).subscribe((response) => {
      this._notificationService.showSuccess('Loan Beam doc sync task created!', 'Success');
      this.reloadApplicationTaskCounts();
      this.loanBeamDocSyncTaskExists = true;
    }, (err) => {
      this._notificationService.showError(err ? err.error.message || err.message : 'Error creating Loan Beam doc sync task!', 'Error');
    });
  };

  onAddOnlineAppTaskClicked = () => {
    this.addOnlineAppTask();
  }

  addOnlineAppTask = () => {
    this._appDetailsService.addOnlineAppTask(this.application.applicationId).subscribe((response) => {
      this._notificationService.showSuccess('Online app task created!', 'Success');
      this.reloadApplicationTaskCounts();
      this.onlineAppTaskExists = true;
    }, (err) => {
      this._notificationService.showError(err ? err.error.message || err.message : 'Error creating online app task!', 'Error');
    });
  };

  reloadApplicationTaskCounts = () => {
    this.loadApplicationTaskCounts.emit();
  };

  onLoanActivityToggled = () => {
    this.loanActivityToggled.emit();
  };

  protected quickClientUpdateClicked = (typeOfQuickClientTask: string) => {
    this._spinnerService.show();
    this._appDetailsService.updateQuickClientTask(this.application.applicationId, typeOfQuickClientTask)
      .subscribe({
        next: () => {
          this._notificationService.showSuccess('Quick client task updated!', 'Success');
        },
        error: (err) => {
          this._notificationService.showError(err ? err.error.message || err.message : 'Error updating quick client task!', 'Error');
        }
      }).add(() => this._spinnerService.hide());
  }

  private populateLoanInfo = (context: ApplicationContext) => {
    if (context.application) {
      this.application = context.application;
      this.mortgage = context.currentMortgage;

      this.dialerEnabled = context.userPermissions.dialerEnabled;

      this.losConnectionStatus = this.getLosConnectionStatus(this.application);
      this.ldeConnectionStatus = this.getLdeConnectionStatus(this.application);

      if (this.mortgage) {
        this.loadInitialDti(context.currentMortgageCalculationDetails);
        this.loadInitialFtc(context.currentMortgageCalculationDetails);
        this.loadInitialLtv(context.currentMortgageCalculationDetails);
        const creditScores = this.mortgage.borrowers.filter(b => b.creditScore).map(b => b.creditScore);
        if (creditScores && creditScores.length) {
          this.ficoOnFile = Math.min(...creditScores);
        }
      }

      this.loanPurposeTransactionType = context.globalConfig.loanPurpose.find(x => x.loanPurposeId == context.application.loanPurposeId)?.transactionType;

      this.applicationIdWithPadding = 1 + Utils.padLeft(this.application.applicationId + '', '0', 9);

      const keyDatesByType = context.applicationKeyDatesByType;
      if (keyDatesByType) {
        this.hasRateLockExpirationKeyDate = keyDatesByType ? !!keyDatesByType['rateLockExpiration'] : false;
      }

      this.borrowers = context.borrowers;

      this.borrowers.forEach(b => {
        b.fullName = Utils.getBorrowerFullName(b);
      });

      this.primaryBorrower = this.borrowers.find(b => b.borrowerId == this.application.primaryBorrowerId);
      if (this.primaryBorrower) {
        this.borrowerName = Utils.getPersonsDisplayName(this.primaryBorrower);
      } else {
        if (context.borrowers.length > 0) {
          this.borrowerName = Utils.getPersonsDisplayName(context.borrowers[0]);
        }
      }

      if (context.application.productPricing) {
        this.interestRate = context.application.productPricing.rate / 100;
      }

      const loanPurpose = this._loanPurposes.find(
        (lp) =>
          lp.loanPurposeId ===
          context.application.loanPurposeId,
      );
      if (loanPurpose) {
        this.loanPurposeName = loanPurpose.loanPurposeName;
      }

      const loanType = this._loanTypes.find(
        (lt) =>
          lt.loanTypeId === context.application.loanTypeId,
      );
      if (loanType) {
        this.loanTypeName = loanType.loanTypeName;
      }

      const loanStatus = this._loanStatuses.find(ls => ls.loanStatusId === context.application.loanStatusId);
      if (loanStatus) {
        //this.loanStatus = loanStatus.loanStatusName;
      }
    }
  };

  private loadInitialLtv = (calculationDetails: MortgageCalculationDetails) => {
    // initially load it from the mortgage object, they are already calculated and are in transaction detail
    if (calculationDetails) {
      this.ltvDetails = calculationDetails.ltv;
    }
  };

  private loadInitialDti = (calculationDetails: MortgageCalculationDetails) => {
    // initially load it from the mortgage object, they are already calculated and are in transaction detail
    if (calculationDetails) {
      this.dtiDetails = calculationDetails.dti;
    }
  };

  private loadInitialFtc = (calculationDetails: MortgageCalculationDetails) => {
    // initially load it from the mortgage object, they are already calculated and are in transaction detail
    if (calculationDetails) {
      this.ftcDetails = calculationDetails.fundsToClose;
    }
  };

  private checkForOnlineAppTask = () => {
    this._appDetailsService.checkForOnlineAppTask(this.application.applicationId).subscribe((response) => {
      this.onlineAppTaskExists = response == true || response == 'true';
      ;
    });
  };

  private checkForLoanBeamDocSyncTask = () => {
    this._appDetailsService.checkForLoanBeamDocSyncTask(this.application.applicationId).subscribe((response) => {
      this.loanBeamDocSyncTaskExists = response == true || response == 'true';
      ;
    });
  };

  private getLdeConnectionStatus = (application: LoanApplication): LosLdeConnectionStatus => {
    if (!!(application.ldeIdentifier && application.ldeVendor && application.ldeSyncDir)) {
      switch (application.ldeSyncDir) {
        case DataExchangeSyncDir.None:
          return LosLdeConnectionStatus.Referenced;
        case DataExchangeSyncDir.LosToLoda:
          return LosLdeConnectionStatus.LosToLoda;
        case DataExchangeSyncDir.Both:
          return LosLdeConnectionStatus.FullSync;
      }
    } else if (!!application.ldeRefNumber) {
      return LosLdeConnectionStatus.Referenced;
    } else {
      return LosLdeConnectionStatus.None;
    }
  };

  private getLosConnectionStatus = (application: LoanApplication): LosLdeConnectionStatus => {
    if (!!(application.losIdentifier && application.losVendor && application.losSyncDir)) {
      switch (this.application.losSyncDir) {
        case DataExchangeSyncDir.None:
          return LosLdeConnectionStatus.Referenced;
        case DataExchangeSyncDir.LosToLoda:
          return LosLdeConnectionStatus.LosToLoda;
        case DataExchangeSyncDir.Both:
          return LosLdeConnectionStatus.FullSync;
      }
    } else if (!!this.application.refNumber) {
      return LosLdeConnectionStatus.Referenced;
    } else {
      return LosLdeConnectionStatus.None;
    }
  };
}
