import { Component, Injector, Input, OnInit } from '@angular/core';
import { LoanServicesService } from '../../../../../services/loan';
import { ThirdPartyCredential, ThirdPartyCredentialArray, ThirdPartyCredentialType } from '../../../../../models';
import { Configuration } from '../../../../../models/configuration.model';
import { DocumentType } from 'src/app/models';
import { NotificationService } from '../../../../../services/notification.service';
import { Constants } from '../../../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditModalComponent } from './credit-modal/credit-modal.component';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScopeType } from '../integrations.component';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';

@Component({
	selector: 'credit',
	templateUrl: './credit.component.html'
})
export class CreditComponent extends ApplicationContextBoundComponent implements OnInit {
	@Input() scope: ScopeType;
	@Input() creditReportingEnabled: Configuration;
	@Input() requireLosLoanLinkForCreditReporting: Configuration;
	@Input() requireSoftPullBeforeHardPull: Configuration;
	@Input() creditReportDocumentType: Configuration;

	@Input() documentTypes: Array<DocumentType>;
	@Input() creditCredentials: ThirdPartyCredentialArray;

	@Input() branchId?: number;

	technicalAffiliates: CreditTechnicalAffiliate[] = [];

	constructor(
		private readonly injector: Injector,
		private readonly _modalService: NgbModal,
		private readonly _loanServicesService: LoanServicesService,
		private readonly _notificationService: NotificationService,
		private readonly _systemLevelService: SystemLevelService,
		private readonly route: ActivatedRoute,
		private readonly _spinner: NgxSpinnerService
	) {
		super(injector);
	}

	ngOnInit(): void {
		this.loadTechnicalAffiliates();

		if (!this.creditReportDocumentType) {
			this.creditReportDocumentType = new Configuration("CreditReportDocumentType", null);
		}

		if (!this.requireSoftPullBeforeHardPull) {
			this.requireSoftPullBeforeHardPull = new Configuration("RequireSoftPullBeforeHardPull", 0);
		}
	}

	loadTechnicalAffiliates = () => {
		this._loanServicesService
			.getTechnicalAffiliates('MeridianLinkHardPull')
			.subscribe({
				next: (result: CreditTechnicalAffiliate[]) => {
					this.technicalAffiliates = result
						.filter(el => el.mlcid && el.code);
				}, error: (err) => {
					this._notificationService.showError(err?.message || 'Unable to load technical affiliates', 'Credit Pull'
					);
				}
			});
	};

	openCredentialModal(credential: ThirdPartyCredential): void {
		const modalRef = this._modalService.open(CreditModalComponent, Constants.modalOptions.large);
		modalRef.componentInstance.scope = this.scope;
		modalRef.componentInstance.branchId = this.branchId;
		modalRef.componentInstance.credential = JSON.parse(JSON.stringify(credential));
		modalRef.componentInstance.technicalAffiliates = this.technicalAffiliates;
		modalRef.componentInstance.userCompanyGuid = this.route.snapshot.params.id;
		modalRef.result.then(
			(result) => {
				this.setCredentials(result);
			},
			() => { }
		);
	}

	onDocTypeChanged = () => {
		this._spinner.show();
		this._systemLevelService.saveConfiguration(this.creditReportDocumentType).subscribe({
			next: () => {
				this._notificationService.showSuccess('Document type saved successfully.', 'Success');
			},
			error: () => {
				this._notificationService.showError('Unable to save document type.', 'Error');
			}
		}).add(() => this._spinner.hide());
	}

	setCredentials = (credential: ThirdPartyCredential): void => {
		const index = this.creditCredentials.findIndex(el => el.credentialId === credential.credentialId);
		if (index > -1) {
			this.creditCredentials[index] = credential;
		} else {
			this.creditCredentials.push(credential);
		}

		if (!this.route.snapshot.params.id && credential.userId === this.applicationContext.currentlyLoggedInUser.userCompanyGuid) { // mean it's current logged in user
			this.applicationContextService.updateUserProfileThirdPartyCredentials(this.creditCredentials, ThirdPartyCredentialType.CreditVendor);
		}
	};
}
