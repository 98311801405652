export class CustomDataEditConfig {
  label: string;
  editorType: string;
  fieldName: string;
  serializedDropdownOptions: string;
  lookupType: string;

  constructor(label: string, editorType: string, fieldName: string,
    serializedDropdownOptions: string, lookupType: string) {
      this.label = label;
      this.editorType = editorType;
      this.fieldName = fieldName;
      this.serializedDropdownOptions = serializedDropdownOptions;
      this.lookupType = lookupType;
    }
}


