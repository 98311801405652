
    <div class="modal-header">
        <h5 class="modal-title">Credential</h5>
    </div>
    <div class="modal-body">
        <form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm" novalidate>
            <div class="row">
                <div class="col-md-12">
                    <credential-modal-basic [credential]="credential">
                    </credential-modal-basic>
                </div>
                <div class="col-md-6 mt-1">
                    <label class="custom-control-label">Username</label>
                    <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
                </div>
                <div class="col-md-6 mt-1">
                    <label class="custom-control-label">Password</label>
                    <password-input [name]="'password'" [(ngModel)]="credential.password"></password-input>
                </div>
                <div class="col-md-6 mt-1">
                    <label class="custom-control-label">AccountId</label>
                    <input
                        type="text"
                        name="url"
                        class="form-control"
                        [ngClass]="{'is-invalid' : accountId && accountId.touched && accountId.invalid}"
                        [(ngModel)]="keyValuePairs.accountId.value"
                        #accountId="ngModel"
                        required
                    />
                    <div class="invalid-feedback">Accound id is required.</div>
                </div>
                <div class="col-md-6 mt-1">
                    <label class="custom-control-label">Document Type</label>
                    <select class="form-select" name="savedDocumentTypeId"  [(ngModel)]="keyValuePairs.savedDocumentTypeId.value">
                        <option value="">-- Select One --</option>
                        <option *ngFor="let item of documentTypes" value={{item.documentTypeId}}>
                            {{item.documentTypeName}}
                        </option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input id="sendForESignByDefault" name="sendForESignByDefault" class="form-check-input" type="checkbox"
                                [(ngModel)]="keyValuePairs.sendForESignByDefault.value" ToggleValue [true]="'1'" [false]="'0'">
                            <label class="form-check-label" for="sendForESignByDefault">
                                Request e-Signature by Default
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input id="disclosuresReviewRequired" name="disclosuresReviewRequired" class="form-check-input" type="checkbox"
                                [(ngModel)]="keyValuePairs.reviewRequired.value" ToggleValue [true]="'1'" [false]="'0'">
                            <label class="form-check-label" for="disclosuresReviewRequired">
                                Auto select "Review Required"
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3" *ngIf="this.credential.credentialId">
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input id="enableLoanPlanFiltering" name="enableLoanPlanFiltering" class="form-check-input" type="checkbox"
                                [(ngModel)]="keyValuePairs.enableLoanPlanFiltering.value" ToggleValue [true]="'1'" [false]="'0'">
                            <label class="form-check-label" for="enableLoanPlanFiltering">
                                Enable Loan Plan Filtering
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input id="sendSampleBorrowerNames" name="sendSampleBorrowerNames" class="form-check-input" type="checkbox"
                                [(ngModel)]="keyValuePairs.sendSampleBorrowerNames.value" ToggleValue [true]="'True'" [false]="'False'">
                            <label class="form-check-label" for="sendSampleBorrowerNames">
                                Send All Requests as Test
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row" *ngIf="keyValuePairs.enableLoanPlanFiltering.value == 1 && !loadingPlans">
            <p-table #disclosureLoanPlansTable columnResizeMode="fit" [value]="disclosureLoanPlans" [paginator]="true"
                [rows]="10" [resizableColumns]="true" responsiveLayout="scroll" scrollable="true"
                [scrollHeight]="scrollHeight" name="disclosureLoanPlans-table" [rowsPerPageOptions]="[10, 25, 50]"
                [autoLayout]="true" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines p-datatable-sm" [selectionPageOnly]="true" sortMode="single"
                [globalFilterFields]="['description','code']">
                <ng-template pTemplate="caption">
                    <div class="d-flex">
                        <span class="p-input-icon-right ms-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control h-100" (ngModelChange)="disclosureLoanPlans.first=0"
                                (input)="disclosureLoanPlansTable.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search...">
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            Loan Plans
                        </th>
                        <th>
                            Products
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row let-index>
                    <tr>
                        <td>
                            {{row.description}} {{row.code}}
                        </td>
                        <td style='overflow: visible;'>
                            <ng-select name="losProductIds{{index}}" [items]="products" bindLabel="productName" bindValue="productId"
                                placeholder="Select Products" [closeOnSelect]="false" [multiple]="true"
                                [(ngModel)]="loanPlanFilters[row.code]">
                            </ng-select>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2" class="text-center"> There are no disclosure loan plans. </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf="loadingPlans" class="text-center">
            <span class="spinner-border spinner-border-sm"></span> Loading Loan Plans
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button
            (click)="saveCredential()"
            class="btn btn-primary right"
            [disabled]="isSaving"
        >
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>

