import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CocHistoryModel } from 'src/app/models/coc/coc-history.model';
import { TaskMessage } from 'src/app/models/message/task-message.model';
import { TrackingInfo } from 'src/app/models/tracking-info.model';
import { MessageService } from 'src/app/services/message.service';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'coc-task-dialog',
  templateUrl: './coc-task-dialog.component.html',
  styleUrls: ['./coc-task-dialog.component.scss']
})
export class CocTaskDialogComponent implements OnInit {

  @Input()
  task: CocHistoryModel;

  columns: any[];

  listInternalNotes: Array<TaskMessage> = [];

  historyVisible: boolean = false;

  taskTrackingInfo: TrackingInfo[];

  constructor(public activeModal: NgbActiveModal,
    private readonly _taskService: TaskService,
    private readonly _messageService: MessageService) { }

  ngOnInit(): void {
    this.columns = [
      { field: 'actionValue', header: 'Action' },
      { field: 'dateCreated', header: 'Date Created' },
      { field: 'by', header: 'By' },
    ];
    this._messageService.getInternalTaskMessages(this.task.loanDocTaskId).subscribe((response) => {
      this.listInternalNotes = response;
    });
  }

  toggleTaskView = () => {
    this.historyVisible = !this.historyVisible;
    if (this.historyVisible && !this.taskTrackingInfo) {

        this._taskService.getTaskTracking(this.task.loanDocTaskId).subscribe((response) => {
          this.taskTrackingInfo = response;
        }, (err) => {

        });
    }
  }
}
