<p-table #dt3 [value]="messagesToDisplay" sortField="statusConditionDescription" sortMode="single"
  styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="{{ isAudit ? '400px' : '' }}"
  [filters]="filters">
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon [field]="col.field"></p-sortIcon>

        <ng-container [ngSwitch]="col.field">
          <p-columnFilter *ngSwitchCase="'statusAndCode'" field="statusAndCode" matchMode="in" display="menu"
            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="font-bold">{{ isAudit ? 'Status' : 'Error Category Code' }} Picker</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="statuses" placeholder="Any" (onChange)="filter($event.value)">
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <p-columnFilter *ngSwitchCase="'statusConditionDescription'" field="statusConditionDescription" matchMode="in"
            display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="header">
              <div class="px-3 pt-3 pb-0">
                <span class="font-bold">{{ isAudit ? 'Category' : 'Message Category' }} Picker</span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="categories" placeholder="Any"
                (onChange)="filter($event.value)">
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-container>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-message>
    <tr>
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'statusConditionDescription'">
          <span *ngIf="message[col.field] == 'Error'" class="badge rounded-pill bg-danger"
            style="font-size: 14px !important"><i class="fas fa-times-circle me-1"></i>
            {{ message[col.field] }}</span>
          <span *ngIf="message[col.field] == 'Warning'" class="badge rounded-pill bg-warning"
            style="font-size: 14px !important">
            <i class="fas fa-exclamation-circle me-1"></i>{{ message[col.field] }}</span>
          <span *ngIf="message[col.field] == 'Pass'" class="badge rounded-pill bg-success"
            style="font-size: 14px !important"><i class="fas fa-check-circle me-1"></i>
            {{ message[col.field] }}</span>
          <span *ngIf="message[col.field] == 'Info'" class="badge rounded-pill bg-secondary"
            style="font-size: 14px !important">
            <i class="fas fa-info-circle me-1"></i>
            {{ message[col.field] }}</span>
          <span *ngIf="!['Error', 'Warning', 'Pass', 'Info'].includes(message[col.field])"
           class="badge rounded-pill bg-secondary" style="font-size: 14px !important">
           {{ message[col.field] }}</span>
        </span>

        <span *ngSwitchDefault>
          {{ message[col.field] }}
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center">No messages found.</td>
    </tr>
  </ng-template>
</p-table>
