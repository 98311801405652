
export class SolarInfoModel {
  solarId: number;
  firstName: string;
  lastName: string;
  ssn: string;
  homePhone: string;
  mobilePhone: string;
  email: string;
  primaryTitle: string;
  secondaryTitle: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  longitude: number;
  latitude: number;
  projectType: SolarProjectTypeEnum;
  propertyType: SolarPropertyTypeEnum;
  yearBuilt: number;
  authorityHavingJurisdiction: string;
  authorityHavingJurisdictionId: string;
  paymentMethod: SolarPaymentMethodEnum;
  pricePerWatt: number;
  systemSize: number;
  grossEPC: number;
  netEPC: number;
  janUsage: number;
  febUsage: number;
  marUsage: number;
  aprUsage: number;
  mayUsage: number;
  junUsage: number;
  julUsage: number;
  augUsage: number;
  sepUsage: number;
  octUsage: number;
  novUsage: number;
  decUsage: number;
  utilityProviderId: number;
  proposals: SolarProposal[] = []
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}

export class SolarProposal {
  proposalId: number;
  proposalSystemKey: string;
  description: string;
  proposalUrl: string;
  paymentMethod: SolarPaymentMethodEnum;
  interestRate: number;
  systemSize: number;
  grossEPC: number;
  netEPC: number;
  solarId: number;
  solarModel: SolarInfoModel;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}

export enum SolarProjectTypeEnum {
  Commercial = "Commercial",
  Residential = "Residential"
}

export enum SolarPropertyTypeEnum {
  SFRAttached = "SFRAttached",
  SFRDetached = "SFRDetached",
  Condominium = "Condominium",
  Cooperative = "Cooperative",
  Manufactured = "Manufactured",
  Modular = "Modular",
  PUD = "PUD",
  MultiFamilyResidence = "MultiFamilyResidence",
  Other = "Other"
}

export enum SolarPaymentMethodEnum {
  Cash = "Cash",
  Finance = "Finance"
}

