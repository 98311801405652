import { LoanApplication, UserRoleEnum } from "src/app/models";
import { LoanFee } from "src/app/models/fee/fee.model";
import { TitleOrderTypeEnum, TitleProductTypeEnum } from "src/app/models/title/create-title-order-request.model";
import { AgentTypeEnum } from "src/app/modules/agents/models/agent-list.model";
import { ThirdPartyIntegrationProvider } from "src/app/modules/fees-v2/fees.model";
import { InternalContact } from "src/app/modules/internal-contacts/models/internal-contact.model";
import { ExternalContact } from "src/app/modules/loan-docs/models/external-contact.model";
import { LoanCredit } from "src/app/modules/loan-services/credit/models/loan-credit.model";
import { UrlaMortgage } from "src/app/modules/urla/models/urla-mortgage.model";

export class TitleOrder {
  titleOrderId: number;
  loanDocTaskId: number;
  applicationSnapshotId: number;
  applicationSnapshot: ApplicationSnapshot;
  integrationProvider: ThirdPartyIntegrationProvider;
  thirdPartyOrderId: string;
  assignedLenderThirdPartyId: string;
  orderStatus: TitleOrderStatusEnum;
  orderType: TitleOrderTypeEnum;
  productType: TitleProductTypeEnum;
  thirdPartyOrderStatus: string;
  thirdPartyOrderStatusDescription: string;
  propertyValue: number;
  createdDate: Date;
  contacts: TitleContact[] = [];
  documents: TitleOrderDocument[] = [];
  events: TitleOrderEvent[] = [];
  titleDetail: TitleDetail;
  applicationId: number;
  integrationHistoryId: number;
  companyId: number;
  modelGuid: string;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class ApplicationSnapshot {
  applicationSnapshotId: number;
  applicationId: number;
  integrationProvider: ThirdPartyIntegrationProvider;
  integrationService: ThirdPartyIntegrationServiceEnum;
  loanApplicationData: LoanApplicationData;
  snapshotHash: string;
  companyId: number;
  modelGuid: string;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}

export class LoanApplicationData {
  mortgage: UrlaMortgage;
  application: LoanApplication;
  extraData: any;
  fees: LoanFee[];
  escrowInformation: EscrowInformation;
  creditInfo: LoanCredit;
  keyDateValues: {[keyDate: string]: any};
  internalContacts: { item1: UserRoleEnum, item2: InternalContact }[];
  externalContacts: { item1: AgentTypeEnum, item2: ExternalContact }[];
}

export class EscrowInformation {
  accountInitialBalance: number;
  accountMinimumBalance: number;
  aggregateAccountingAdjustment: number;
  cushionMonths: number;
  disclosures: EscrowDisclosure[];
}

export class EscrowDisclosure {
  currentBalance: number;
  disbursementMonth: number;
  disbursementYear: number;
  paymentType: EscrowPaymentTypeEnum;
  paymentTypeOtherDescription: string;
  paymentFromEscrowAccount: number;
  paymentToEscrowAccount: number;
}

export enum EscrowPaymentTypeEnum {
  Assessment = "Assessment",
  CityPropertyTax = "CityPropertyTax",
  CountyPropertyTax = "CountyPropertyTax",
  EarthquakeInsurance = "EarthquakeInsurance",
  FloodInsurance = "FloodInsurance",
  HazardInsurance = "HazardInsurance",
  MortgageInsurance = "MortgageInsurance",
  Other = "Other",
  SchoolPropertyTax = "SchoolPropertyTax",
  TownPropertyTax = "TownPropertyTax",
  USDAAnnualFee = "USDAAnnualFee",
  VillagePropertyTax = "VillagePropertyTax",
  WindstormInsurance = "WindstormInsurance"
}

export enum ThirdPartyIntegrationServiceEnum {
  AppraisalVendor = "AppraisalVendor",
  Disclosure = "Disclosure",
  Fees = "Fees",
  Title = "Title",
  CloudPlatform = "CloudPlatform",
  SocialMedia = "SocialMedia",
  Reporting = "Reporting",
  VOA = "VOA",
  VOI = "VOI",
  DocumentSigning = "DocumentSigning",
  LenderDataExchange = "LenderDataExchange",
  CreditVerificationProvider = "CreditVerificationProvider",
  HOI = "HOI",
  AUS = "AUS",
  AUSOriginator = "AUSOriginator",
  AUSCasefileAccess = "AUSCasefileAccess",
  TaxWallet = "TaxWallet",
  ContentGeneration = "ContentGeneration",
  Mers = "Mers",
  Pricing = "Pricing",
  Ocr = "Ocr",
  LicenseValidation = "LicenseValidation"
}

export class TitleBorrower {
  isPrimary: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  homePhone: string;
  cellPhone: string;
  workPhone: string;
}

export class TitleContact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export class TitleOrderDocument {
  titleOrderDocumentId: number;
  titleOrderId: number;
  integrationHistoryId: number;
  thirdPartyDocumentId: string;
  documentType: TitleDocumentTypeEnum;
  fileName: string;
  extension: string;
  mimeType: string;
  data: string;
  contentUrl: string;
  documentCreatedBy: string;
  documentCreatedDate: Date;
  docFileGuid: string;

  companyId: number;
  modelGuid: string;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date
}

export class TitleOrderEvent {
  eventName: string;
  comments: string;
  titleOrderDocumentIds: number[];
}

export class TitleDetail {
  vestingInformation: string;
  longLegalDescription: string;
  noteDate: string;
  recordingDate: string;
  book: string;
  page: string;
  instrumentNumber: string;
  grantee: string;
}

export enum TitleDocumentTypeEnum {
  Urla = "Urla",
  Affidavit = "Affidavit",
  BorrowersAuthorization = "BorrowersAuthorization",
  ClearToCloseCertificate = "ClearToCloseCertificate",
  ClosingInstructions = "ClosingInstructions",
  Commitment = "Commitment",
  Cpl = "Cpl",
  DeathCertificate = "DeathCertificate",
  Deed = "Deed",
  DisbursementLedger = "DisbursementLedger",
  EAndO = "EAndO",
  Eoi = "Eoi",
  FinalSettlementStatement = "FinalSettlementStatement",
  FundingDocs = "FundingDocs",
  Hoi = "HOI",
  IlapldCertificate = "IlapldCertificate",
  Invoice = "Invoice",
  LenderPackageUnsigned = "LenderPackageUnsigned",
  MortgageStatement = "MortgageStatement",
  NotaryConfirmationInformation = "NotaryConfirmationInformation",
  PaceLien = "PaceLien",
  PayoffRequest = "PayoffRequest",
  PayoffFirst = "PayoffFirst",
  PayoffSecond = "PayoffSecond",
  PlatMap = "PlatMap",
  PolicyPackage = "PolicyPackage",
  PowerOfAttorney = "PowerOfAttorney",
  PreliminarySettlementStatement = "PreliminarySettlementStatement",
  PrivacyPolicy = "PrivacyPolicy",
  RecissionNotice = "RecissionNotice",
  RecordedDocsDeed = "RecordedDocsDeed",
  RecordedDocsDeedOfTrust = "RecordedDocsDeedOfTrust",
  RecordedDocsMortgage = "RecordedDocsMortgage",
  SearchPackage = "SearchPackage",
  SettlementStatement = "SettlementStatement",
  ShippingLabel = "ShippingLabel",
  StructuredLegalText = "StructuredLegalText",
  StructuredVestingText = "StructuredVestingText",
  Survey = "Survey",
  TaxStatement = "TaxStatement",
  TitlePackage = "TitlePackage",
  TrustCertificate = "TrustCertificate",
  WiringInstructions = "WiringInstructions",
  Other = "Other"
}

export enum TitleOrderStatusEnum {
  Created = "Created",
  PartialDocumentsReturned = "PartialDocumentsReturned",
  DocumentsReturned = "DocumentsReturned",
  CompletedWithoutDocumentsReturned = "CompletedWithoutDocumentsReturned",
  Deleted = "Deleted",
  Cancelled = "Cancelled",
  Archived = "Archived",
  Rejected = "Rejected",
  Accepted = "Accepted"
}

export enum LoanPurposeTypeEnum {
  Refinance = "Refinance",
  Purchase = "Purchase",
  ConstructionOnly = "ConstructionOnly",
  ConstructionToPermanent = "ConstructionToPermanent",
  Other = "Other",
  MortgageModification = "MortgageModification",
  Unknown = "Unknown"
}

export enum MortgageAppliedForTypeEnum {
  VA = "VA",
  FHA = "FHA",
  Conventional = "Conventional",
  USDA = "USDA",
  OtherMortgage = "OtherMortgage",
  NonConforming = "NonConforming",
  NonAgency = "NonAgency",
  HomeEquity = "HomeEquity",
  Other = "Other",
  LocalAgency = "LocalAgency",
  PublicAndIndianHousing = "PublicAndIndianHousing",
  StateAgency = "StateAgency"
}

export enum PropertyWillBeTypeEnum {
  PrimaryResidence = "PrimaryResidence",
  SecondaryResidence = "SecondaryResidence",
  Investment = "Investment"
}
