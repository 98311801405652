<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-users me-2"></i>
                    File Contacts
                </h4>
                <!-- <div class="float-end button-items">
               
            </div> -->
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-12 col-xxl-5 col-xxxl-4">
                        <internal-contacts></internal-contacts>
                    </div>
                    <div class="col-xl-12 col-xxl-7 col-xxxl-8">
                        <external-contacts></external-contacts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>