import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loan-ltv-donut-chart',
  templateUrl: 'loan-ltv-donut-chart.component.html',
  styleUrls: ['loan-ltv-donut-chart.component.scss']
})
export class LoanLtvDonutChartComponent implements OnInit {

  @Input()
  value: number;

  @Input()
  title: string;

  constructor() { }

  ngOnInit(): void {
  }
}
