import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentPortalSettingsComponent } from './components/agent-portal-settings/agent-portal-settings.component';
import { ApplicationSettingsComponent } from './components/application-settings/application-settings.component';
import { BorrowerPortalSettingsComponent } from './components/borrower-portal-settings/borrower-portal-settings.component';
import { BusinessDaysCalendarComponent } from './components/business-days-calendar/business-days-calendar.component';
import { CompanyProfileExtComponent } from './components/company-profile-ext/company-profile-ext.component';
import { DocumentExportFormatComponent } from './components/document-export-format/document-export-format.component';
import { IntegrationContainerComponent } from './components/integrations/integration-container/integration-container.component';
import { OnlineAppSettingsComponent } from './components/online-app-settings/online-app-settings.component';
import { SupportToolsComponent } from './components/support-tools/support-tools.component';
import { SystemLevelComponent } from './system-level.component';
import { AppPasswordsComponent } from './components/app-passwords/app-passwords.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [],
    component: SystemLevelComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../../modules/email-configuration/email-configuration.module').then((m) => m.EmailConfigModule),
      },
      {
        path: 'email-configuration',
        loadChildren: () =>
          import('../../modules/email-configuration/email-configuration.module').then((m) => m.EmailConfigModule),
      },
      {
        path: 'sms-configuration',
        loadChildren: () =>
          import('../../modules/sms-configuration/sms-configuration.module').then((m) => m.SmsConfigModule),
      },
      {
        path: 'application-settings',
        component: ApplicationSettingsComponent,
      },
      {
        path: 'business-days-calendar',
        component: BusinessDaysCalendarComponent,
      },
      {
        path: 'borrower-portal-settings',
        component: BorrowerPortalSettingsComponent
      },
      {
        path: 'agent-portal-settings',
        component: AgentPortalSettingsComponent
      },
      {
        path: 'prequal-letter-generation-settings',
        loadChildren: () =>
          import('../../modules/prequal-letter-settings/prequal-letter-settings.module').then((m) => m.PrequalLetterSettingsModule),
      },
      {
        path: 'online-application-settings',
        component: OnlineAppSettingsComponent
      },
      {
        path: 'document-export-format',
        component: DocumentExportFormatComponent
      },
      {
        path: 'integrations',
        component: IntegrationContainerComponent
      },
      {
        path: 'app-passwords',
        component: AppPasswordsComponent
      },
      {
        path: 'company-profile-ext',
        component: CompanyProfileExtComponent
      },
      {
        path: 'super-admin-support',
        component: SupportToolsComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class SystemLevelRoutingModule { }
