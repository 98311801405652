<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm"
      (ngSubmit)="saveCredential()" novalidate>
    <div class="modal-header">
        <h5 class="modal-title">Credential</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <credential-modal-basic [credential]="credential" [scope]="scope">
                </credential-modal-basic>
            </div>
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Client ID</label>
                <input type="text" class="form-control" name="clientId" [(ngModel)]="credential.userName">
            </div>
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Client Secret</label>
                <password-input [(ngModel)]="credential.password" [name]="'clientSecret'" [placeholder]="'Client Secret'"></password-input>
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button type="submit" class="btn btn-primary right" [disabled]="isSaving">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>
</form>
