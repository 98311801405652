<div class="alert alert-light alert-dismissible fade show border-0 mb-0" *ngIf="releaseNotes" role="alert"
  id="alert-window" style="position: relative !important;">
  <div class="media">
    <i style="color:rgb(46, 168, 226)" class="fas fa-diagnoses alert-icon align-self-center font-30 me-3"></i>
    <div class="media-body align-self-center">
      <span class="mb-1 fw-bold mt-0">We've made some updates!</span><br>
      <span> <a href="{{releaseNotes.uri}}" style="color:rgb(46, 168, 226)" target="_blank">Click Here To View Release
          Notes v{{releaseNotes.version}}</a></span>
    </div>
  </div>
  <button type="button" class="btn-close align-self-center" (click)="onDismissVersionUpdateNoticeClicked()"
    aria-label="Close"><i class="fas fa-times"></i></button>
</div>
<div class="alert alert-light fade show border-0 mb-0" *ngIf="userIsImpersonating" role="alert" id="alert-window"
  style="position: relative !important;">
  <div class="media">
    <i style="color:rgb(46, 168, 226)" class="fas fa-diagnoses alert-icon align-self-center font-30 me-3"></i>
    <div class="media-body align-self-center">
      <span class="mb-1 fw-bold mt-0">You are currently impersonating company: '{{companyName}}'</span><br>
      <span> <a (click)="onStopImpersonatingClicked()" style="color:rgb(46, 168, 226)" target="_blank">Click Here To
          Stop Impersonating</a></span>
    </div>
  </div>
</div>
<div class="topbar navbar navbar-expand-xxl header-z-index">
  <div class="container-fluid g-0">
    <nav class="navbar-custom collapse d-flex navbar-collapse">
      <a class="navbar-toggle nav-link" id="mobileToggle" (click)="toggleNav()">
        <div class="lines">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </a><!-- End mobile menu toggle-->
      <a class="logo navbar-brand" *ngIf="companyId != 151">
        <span class="hide-on-md" *ngIf="config && companyId != 151">
          <img [src]="config && config.logo" style="height: 26px" alt="{{config.name}}" class="logo-sm hide-on-md" />
        </span>
        <span class="hide-on-lg" *ngIf="config">
          <img [src]="'assets/images/loda-mobile-logo.png'" style="height: 26px" alt="{{config.name}}"
            class="logo-sm hide-on-lg" />
        </span>
      </a>
      <a class="logo navbar-brand" *ngIf="companyId == 151">
        <span class="hide-on-md">
          <img [src]="'https://prodapi.lodasoft.com/company/co/logo'" style="height: 50px" alt="{{config.name}}"
            class="logo-sm hide-on-md" />
        </span>
        <span class="hide-on-lg">
          <img [src]="'https://prodapi.lodasoft.com/company/co/register'" style="height: 50px" alt="{{config.name}}"
            class="logo-sm hide-on-lg" />
        </span>
      </a>
      
      <div class="navbar-custom-menup-2 flex-fill">
        <header-menu [menu]="menu" (menuItemClicked)="onMenuItemClicked($event)" (newLeadClicked)="onNewLeadClicked()"
          (newAgentClicked)="onNewAgentClicked()" (newContactClicked)="onNewContactClicked()"
          (newApplicationClicked)="onNewApplicationClicked()" (viewLeadClicked)="onViewLeadClicked($event)"
          (viewAgentClicked)="onViewAgentClicked($event)">
        </header-menu>
      </div>
      <header-actions [user]="user" (mentionsClicked)="onMentionsClicked($event)"
        (niceInContactClicked)="onNiceInContactClicked($event)" (notificationsClicked)="onNotificationsClicked($event)"
        (alertsCreated)="onAlertsCreated($event)" (conversationsToggled)="onConversationsToggled($event)"
        (dialpadClicked)="onDialpadClicked()" [unreadConversations]="unreadConversations">
      </header-actions>
    </nav>
  </div>
</div>