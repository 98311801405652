import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { MortgageCallReportService } from 'src/app/services/mortgage-call-report.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { McrAuditData, McrAuditDataLookup, McrFullAuditData, McrPipelinePositionValues, McrQuarterYear } from '../../reports/mortgage-call/models/mcr-audit-data.model';
import { QuarterEnum } from '../../reports/mortgage-call/models/quarter-enum.model';

@Component({
  selector: 'summary-mcr-audit-data',
  templateUrl: './summary-mcr-audit-data.component.html',
  styleUrls: ['./summary-mcr-audit-data.component.scss']
})
export class SummaryMcrAuditDataComponent implements OnInit {

  @Input() mcrAuditSummaries: McrAuditDataLookup[] = [];
  @Input() appId: number;

  @Output() refreshRequired = new EventEmitter<any>();

  @ViewChild('newYearQuarterForm')
  newYearQuarterForm: NgForm | undefined;

  mcrDataAuditDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  mcrFullAuditData: McrFullAuditData = new McrFullAuditData();

  currentYear: number;
  currentQuarter: QuarterEnum;

  states: EnumerationItem[] = [];
  pipelinePositions: EnumerationItem[] = [];

  newYear: number;
  newQuarter: QuarterEnum;

  yearQuarters: EnumerationItem[] = [];

  constructor(
    private readonly _mortgageCallReportService: MortgageCallReportService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _enumerationService: EnumerationService,
    private readonly _drawerService: DrawerService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) {
    this.getQuarters();
  }

  ngOnInit(): void {
    this._applicationContextService.context.subscribe(context => {
      this.states = Object.keys(context.globalConfig.states).map(key => new EnumerationItem(context.globalConfig.states[key], key));
    });

    this.pipelinePositions = this._enumerationService.mcrApplicationPipelinePositions.map(e => {
      e.value = Utils.lowerCaseFirstLetter(e.value);
      return e;
    });

    if(!this.mcrAuditSummaries){
      this.mcrAuditSummaries = [];
    }

    this.fetchCurrentYearAndQuarter();
  }

  getQuarters = () => {
    for (let enumMember in QuarterEnum) {
      let enumName = this.getEnumName(QuarterEnum[enumMember]);
      if (enumName) {
        this.yearQuarters.push({ name: enumName, value: QuarterEnum[enumMember] });
      }
    }
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" && enumValue != "Unknown" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  getSelectedStateName = (stateValue: string): string => {
    return stateValue ? this.states.find(s => s.value == stateValue).name : null;
  }

  getSelectedPipelinePositions = (positions: McrPipelinePositionValues): string => {
    return positions ? Object.keys(positions).filter(key => !!positions[key]).map(key => this.pipelinePositions.find(p => p.value == key).name).toString() : null;
  }

  onAddNew = (pop: any) => {
    if (!this.newYearQuarterForm) return;

    this.newYearQuarterForm.form.markAllAsTouched();
    if (!this.newYearQuarterForm.form.valid) return;

    let exist = this.mcrAuditSummaries.find(s => s.quarter == this.newQuarter && s.year == this.newYear);
    if(!exist){

      let newMcrLookup = new McrAuditDataLookup();
      newMcrLookup.year = this.newYear;
      newMcrLookup.quarter = this.newQuarter;
      newMcrLookup.pipelinePositions = new McrPipelinePositionValues();

      this.mcrAuditSummaries.push(newMcrLookup);

      this._mortgageCallReportService.getFullAuditData(this.appId, this.newYear, this.newQuarter)
      .subscribe({
        next: (mcrData) => {
          this.mcrFullAuditData = mcrData;
          this._drawerService.show("mcrAuditDataEditorDrawer", 10);
        },
        error: (err) => {
          this._notificationService.showError(err.error.message || err.error, "Error");
        }
      })

      pop.hide();
      this.clearNewYearQuarter();
    }
    else {
      this._notificationService.showWarning(`There is already audit data for quarter "${this.newQuarter}", year "${this.newYear}".`,"Validation");
    }
  }

  onCancel = (pop: any) => {
    pop.hide();
    this.clearNewYearQuarter();
  }

  onEditClicked = (mcrAuditDataSummary: McrAuditDataLookup) => {
    this._spinner.show();

    this._mortgageCallReportService.getFullAuditData(this.appId, mcrAuditDataSummary.year, mcrAuditDataSummary.quarter)
      .subscribe({
        next: (data: McrFullAuditData) => {
          this.mcrFullAuditData = data;

          this._spinner.hide();
          this._drawerService.show("mcrAuditDataEditorDrawer", 10);
        },
        error: (err) => {
          this._spinner.hide();

          if (!this.mcrFullAuditData.auditData) {
            this.mcrFullAuditData.auditData = new McrAuditData();
          }

          this.mcrFullAuditData.auditData.applicationId = this.appId;

          this._drawerService.show("mcrAuditDataEditorDrawer", 10);
        }
      })

  }

  onDeleteClicked = (mcrAuditDataId: number, year: number, quarter: QuarterEnum) => {
    this._mortgageCallReportService.deleteAuditData(this.appId, year, quarter)
    .subscribe({
      next: () => {
        this._notificationService.showSuccess("MCR Audit Data deleted successfully", 'Success');
        this.mcrAuditSummaries = this.mcrAuditSummaries.filter(s => s.mcrAuditDataId != mcrAuditDataId);
      },
      error: (err) => {
        this._notificationService.showError(err.error.message || err.error || "Error", 'Error-Deleting Audit Data');
      }
    })
  }

  finishedMcrAuditDataSaving = (data: McrAuditData) => {
    this.mcrFullAuditData.auditData = { ...data };
    this._drawerService.hide("mcrAuditDataEditorDrawer", 10);
    this.refreshRequired.emit();
  }

  protected async onExitClicked(): Promise<void> {
    await this._drawerService.hide("mcrAuditDataEditorDrawer", 10);
  }

  private fetchCurrentYearAndQuarter = () => {
    this._mortgageCallReportService.getCurrentYearAndQuarter()
      .subscribe({
        next: (yearQuarter: McrQuarterYear) => {
          this.currentYear = yearQuarter.year;
          this.currentQuarter = yearQuarter.quarter;

          this.newYear = this.currentYear;
          this.newQuarter = this.currentQuarter;

          let exist = this.mcrAuditSummaries.find(s => s.quarter == this.newQuarter && s.year == this.newYear);
          if(exist){
            this.onEditClicked(exist);
          }
        },
        error: () => {
        }
      })
  }

  private clearNewYearQuarter = () => {
    this.newYear = this.currentYear;
    this.newQuarter = this.currentQuarter;
  }

}
