<div class="credential me-3 {{isFail ? 'fail-item' : ''}}" (click)="credentialModal()">
  <span *ngIf="!disableDelete" class="close" (click)="deleteCredentialsConfirmation(); $event.stopPropagation()">
    <i id="close-icon" class="fas fa-times-circle"></i>
  </span>
  <div [ngClass]="{'main': !disableDelete, 'main-v2': disableDelete, 'active': credential.active}">
    <p *ngIf="!credential.active" class="disabled-state-label badge badge-soft-danger" ngbTooltip="Edit and save to re-enable">
      Disabled
    </p>
    <div class="title text-ellipsis">{{credentialTitle}}
      <span *ngIf="isDefaultForOnlineApp" class="ms-1" placement="top" [ngbTooltip]="isDefaultForOnlineAppTooltip" container="body">
        <i class="fas fa-star me-2 text-secondary font-14"></i>
        <ng-template #isDefaultForOnlineAppTooltip>
          Default for Online App
        </ng-template>
      </span>
    </div>
    <div class="mt-2 text-ellipsis">
      <span>
        <i class="fa fa-tag"></i>
      </span>
      <span class="ms-2">{{credential.alias || '-'}}</span>
    </div>
    <div class="mt-2 text-ellipsis">
      <span>
        <i class="fas fa-user"></i>
      </span>
      <span class="ms-2">{{credential.userName || '-'}}</span>
    </div>
    <div class="mt-2 text-ellipsis">
      <span>
        <i class="fas fa-lock"></i>
      </span>
      <span class="ms-2">{{credential.password ? '************' : '-'}}</span>
    </div>
  </div>
  <div class="bottom">
    <div class="title text-ellipsis">{{credential.channels || '-'}}</div>
  </div>
</div>
