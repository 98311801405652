import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash';
import { ApplicationContext } from 'src/app/models';
import { User } from 'src/app/models/user/user.model';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';

@Component({
  selector: 'add-contacts-to-email-dialog',
  templateUrl: 'add-contacts-to-email-dialog.component.html',
  styleUrls: ['add-contacts-to-email-dialog.component.scss']
})

export class AddContactsToEmailDialogComponent implements OnInit {

  @Input()
  applicationContext: ApplicationContext;

  @Input()
  contacts: Contacts = new Contacts();

  selectedContacts: SelectedContacts = new SelectedContacts();
  extendedInternalContacts: any;
  extendedExternalContacts: any;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const users = this.applicationContext.globalConfig.usersAll.concat(this.applicationContext.globalConfig.tpoUsers);
    this.populateInternalContactsList(users);
    this.populateExternalContactsList();
  }

  private populateInternalContactsList = (users: User[]) => {
    this.extendedInternalContacts = this.contacts.internalContacts
      .filter(internalContact => internalContact.userId)
      .map(internalContact => {
        const matchingUser = users.find(u => u.userCompanyGuid == internalContact.userId);
        let formattedName = this.getFormattedName(matchingUser?.lastName, matchingUser?.firstName);
        formattedName = (formattedName === '' || matchingUser?.active) ? formattedName : `${formattedName} - (Inactive)`;
        return {
          ...internalContact,
          roleName: this.getRoleNameById(internalContact.roleId),
          formattedName,
          email: matchingUser?.email
        }
      });
  }

  getRoleNameById(roleId: number) {
    return this.applicationContext.globalConfig.roles.find(u => u.roleId == roleId)?.roleName || '';
  }

  populateExternalContactsList() {
    this.extendedExternalContacts = orderBy(this.contacts.externalContacts
      .map(externalContact => ({
        ...externalContact,
        formattedName: this.getFormattedName(externalContact.lastName, externalContact.firstName)
      })), item => [item.agentType, item.lastName]);
  }

  getFormattedName(lastName: string, firstName: string) {
    if (firstName && lastName) {
      return `${lastName}, ${firstName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  }
}

export class SelectedContacts {
  selectedInternalContactEmails: any[] = [];
  selectedExternalContactEmails: any[] = [];
}

export class Contacts {
  internalContacts: InternalContact[] = [];
  externalContacts: ExternalContact[] = [];
}
