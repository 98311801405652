import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Mortgage, MortgageInsuranceDetail} from '../../../models';

@Component({
  selector: 'finance-entire-fee-checkbox',
  templateUrl: './finance-entire-fee-checkbox.component.html',
  styleUrls: ['./finance-entire-fee-checkbox.component.scss'],
})
export class FinanceEntireFeeCheckboxComponent {
  @Input() mortgage: Mortgage = {
    mortgageInsuranceDetail: {
      financeEntireMiOrFundingFee: false,
    } as MortgageInsuranceDetail,
  } as Mortgage;

  @Input() label: string = 'Finance All';

  @Output() valueChange = new EventEmitter<boolean>();

  protected onChangeValue(value: boolean): void {
    this.mortgage.mortgageInsuranceDetail.financeEntireMiOrFundingFee = value;
    this.valueChange.emit(value);
  }
}
