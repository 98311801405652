import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-loan-purpose-change-dialog',
  templateUrl: './confirm-loan-purpose-change-dialog.component.html',
  styleUrls: ['./confirm-loan-purpose-change-dialog.component.scss']
})
export class ConfirmLoanPurposeChangeDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
