import { Role } from ".."

export class LoanStatus {
  readonly companyId: number;
  readonly dateInserted: string;
  readonly dateUpdated: string;
  emailFromRoleId: number | '';
  hideFromCounter: boolean;
  removeFromAllDialCampaigns: boolean;
  enabledChannels: string; // ChannelFlags[]
  isUsedForBilling: boolean;
  loanStage: StandardLoanStage;
  readonly insertedBy: string;
  readonly isLeadStatus: boolean;
  readonly loanStatusId: number;
  loanStatusName: string;
  order: number;
  roleModel: Role | null;
  selectByRole: string;
  showAsArchive: boolean;
  readonly updatedBy: string;
}

export enum StandardLoanStage {
  Application = 'Application',
  Origination = 'Origination',
  Setup = 'Setup',
  Processing = 'Processing',
  Underwriting = 'Underwriting',
  Approval = 'Approval',
  ClearToClose = 'ClearToClose',
  Completed = 'Completed',
  Withdrawn = 'Withdrawn',
  Denied = 'Denied',
  Unknown = ''
}

export enum ChannelFlags {
  Disabled,
  Retail,
  Wholesale,
  Broker,
  Correspondent,
  NonDelegatedCorrespondent
}
