import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { TpoLoanSummaryComponent, TpoBorrowerComponent, TpoClosingInfoComponent, TpoLoanDetailComponent, TpoLoanStatusComponent, TpoOriginationInfoComponent } from ".";
import { LoanStatusCardComponent } from "../widgets/loan-status-card/loan-status-card.component";
import { OutstandingConditionsCardComponent } from "../widgets/outstanding-conditions-card/outstanding-conditions-card.component";
import { TpoLoanSummaryV2Component } from './tpo-loan-summary-v2/tpo-loan-summary-v2.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TpoLoanSummaryComponent,
    TpoBorrowerComponent,
    TpoClosingInfoComponent,
    TpoLoanDetailComponent,
    TpoLoanStatusComponent,
    TpoOriginationInfoComponent,
    LoanStatusCardComponent,
    OutstandingConditionsCardComponent,
    TpoLoanSummaryV2Component
  ],
  exports: [
    TpoLoanSummaryComponent,
    TpoBorrowerComponent,
    TpoClosingInfoComponent,
    TpoLoanDetailComponent,
    TpoLoanStatusComponent,
    TpoOriginationInfoComponent,
    TpoLoanSummaryV2Component
  ],
  providers: []
})
export class TpoLoanSummaryModule {
  constructor() {
  }
}
