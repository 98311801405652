import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { DialList } from "src/app/modules/dialer/models/dial-list.model";
import { DoNotCall } from "../models/do-not-call.model";
import { Telephony } from "../models/telephony.model";

@Injectable()
export class DialerConfigService {
  constructor(private readonly _dataService: DataService) { }

  getDialLists = (): Observable<DialList[]> => {
    const url = `api/Admin/dial-list`;
    return this._dataService.get(url);
  };

  insertDialList = (dialList: DialList): Observable<DialList> => {
    const url = `api/Admin/dial-list`;
    return this._dataService.post(url, dialList);
  };

  updateDialList = (dialListId: number, dialList: DialList): Observable<DialList> => {
    const url = `api/Admin/dial-list/${dialListId}`;
    return this._dataService.post(url, dialList);
  };

  deleteDialList = (dialListId: number): Observable<any> => {
    const url = `api/Admin/dial-list/${dialListId}`;
    return this._dataService.delete(url);
  };

  reOrderDialLists = (dialListIds: number[]): Observable<any> => {
    const url = `api/Admin/dial-list/reorder`;
    return this._dataService.post(url, dialListIds);
  };

  // do not call list
  getAllDoNotCall = (prefix?: string): Observable<DoNotCall[]> => {
    const url = `api/Admin/GetAllDoNotCall${prefix ? '?prefix=' + prefix : ''}`;
    return this._dataService.get(url);
  };

  getDoNotCall = (phoneNumber: string): Observable<DoNotCall> => {
    const url = `api/Admin/GetDoNotCall?phoneNumber=${phoneNumber}`;
    return this._dataService.get(url);
  };

  insertDoNotCall = (doNotCall: DoNotCall): Observable<DoNotCall> => {
    const url = `api/Admin/InsertDoNotCall`;
    return this._dataService.post(url, doNotCall);
  };

  updateDoNotCall = (doNotCall: DoNotCall): Observable<DoNotCall> => {
    const url = `api/Admin/UpdateDoNotCall`;
    return this._dataService.post(url, doNotCall);
  };

  deleteDoNotCall = (doNotCallId: number): Observable<any> => {
    const url = `api/Admin/DeleteDoNotCall/${doNotCallId}`;
    return this._dataService.delete(url);
  };

}