import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingInfo } from 'src/app/models/tracking-info.model';

@Component({
  selector: 'file-tracking-dialog',
  templateUrl: './file-tracking-dialog.component.html',
  styleUrls: ['./file-tracking-dialog.component.scss']
})
export class FileTrackingDialogComponent implements OnInit {

  title: string;

  fileTrackingInfo: TrackingInfo[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
