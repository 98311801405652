import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Mortgage, SubjectProperty } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'task-order-title',
  templateUrl: './task-order-title.component.html',
  styleUrls: ['./task-order-title.component.scss']
})
export class TaskOrderTitleComponent implements OnInit {

  @Input()
  appId: number;

  @Input()
  task: LoanDocDashboardTask;

  @Input()
  globalConfig: GlobalConfig;

  mortgage: Mortgage;

  constructor(private readonly _mortgageService: MortgageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit(): void {
    this._spinner.show();
    this._mortgageService.getMortgage(this.appId).subscribe((response) => {
      this.mortgage = response;
      this._spinner.hide();
      if (!this.mortgage.subjectProperty) {
        this.mortgage.subjectProperty = new SubjectProperty();
      }
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err ? err.message || err : '', 'Error')
    });
  }
}
