<form #upsertLoanTypeForm="ngForm" ngbAutofocus class="row">
    <div class="p-3">
        <div class="mb-3">
            <label class="control-label">Loan Type</label>
            <input class="form-control"
                [ngClass]="{ 'is-invalid' : loanTypeName && loanTypeName.touched && loanTypeName.invalid }"
                name="loanTypeName" [(ngModel)]="loanType.loanTypeName" #loanTypeName="ngModel" required />
            <div class="invalid-feedback">Loan type is required.</div>
        </div>
        <div class="mb-3">
            <label class="control-label">Mortgage Applied For Type</label>
            <select class="form-select" name="mortgageAppliedForType" [(ngModel)]="loanType.mortgageAppliedForType">
                <option value="">-- Select One --</option>
                <option *ngFor="let mortgage of mortgageAppliedForTypes" value="{{mortgage.value}}">{{mortgage.name}}
                </option>
            </select>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-check form-switch form-switch-success">
                            <input class="form-check-input" type="checkbox" name="showInOnlineApp" id="showInOnlineApp"
                                [(ngModel)]="loanType.showInOnlineApp">
                            <label class="form-check-label" for="showInOnlineApp">Show In Online App</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-check form-switch form-switch-success">
                            <input class="form-check-input" type="checkbox" name="defaultForOnlineApp"
                                id="defaultForOnlineApp" [(ngModel)]="loanType.defaultForOnlineApp">
                            <label class="form-check-label" for="defaultForOnlineApp">Default For Online App</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-soft-secondary btn-sm me-2" (click)="onClose()">
                Close
            </button>
            <button type="button" class="btn btn-soft-primary btn-sm me-2" (click)="save()" [disabled]="saving">
                <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
                Save
            </button>
        </div>
    </div>
</form>