<div class="row">
    <div class="form-group col-xs-12 col-sm-8 col-lg-4">
        <label for="address1">Subject Property Address <a class="link" (click)="copyAddressFromPresent()">Copy from Present</a>
        </label>
      <address-autocomplete-input
        #address='ngModel'
        (addressChange)='handleAddressChange($event)'
        [(ngModel)]='mortgage.subjectProperty.address1'
        [disabled]='isSubjectPropertyAddressTbd'
        id='address1'
        name='address1'
        placeholder=''
      ></address-autocomplete-input>
    </div>
    <div class="form-group col-xs-12 col-sm-4 col-lg-1">
        <label for="address2">Unit/Suite</label>
        <input id="address2" name="address2" type="text" class="form-control"
            [disabled]='isSubjectPropertyAddressTbd'
            [(ngModel)]="mortgage.subjectProperty.address2"/>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
        <label for="propertyCity">City</label>
        <input id="propertyCity" name="propertyCity" type="text" class="form-control"
            [(ngModel)]="mortgage.subjectProperty.city"/>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
        <div class="row">
            <div class="col">
                <label for="propertyState">State</label>
                <select id="propertyState" name="propertyState" class="form-select" placeholder="State"
                    [(ngModel)]="mortgage.subjectProperty.state">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let state of states" [ngValue]="state.value">
                        {{ state.name }}
                    </option>
                </select>
            </div>
            <div class="col">
                <label for="propertyZip">Zip Code</label>
                <zip-code-input [(ngModel)]="mortgage.subjectProperty.zipCode" [name]="'propertyZip'" [required]="false"
                    (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
                </zip-code-input>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
        <label for="constructionMethod">Construction Method</label>
        <select class="form-select" name="constructionMethod" id="constructionMethod"
            [(ngModel)]="mortgage.subjectProperty.constructionMethod">
            <option [ngValue]="null">--Select One--</option>
            <option *ngFor="let contructionMethod of propertyConstructionMethods" [ngValue]="contructionMethod.value">{{contructionMethod.name}}</option>
        </select>
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
        <label for="propertyWillBe">Occupancy Type</label>
        <select class="form-select" name="propertyWillBe" id="propertyWillBe"
            [(ngModel)]="mortgage.subjectProperty.propertyWillBe">
            <option [ngValue]="null">--Select One--</option>
            <option *ngFor="let propertyType of propertyTypes" [ngValue]="propertyType.value">{{propertyType.name}}</option>
        </select>
    </div>

    <div class="form-group col-xs-12 col-sm-4 col-lg-2">
        <label for="noOfUnits">Number Of Units</label>
        <input class="form-control" name="noOfUnits" id="noOfUnits" [(ngModel)]="mortgage.subjectProperty.noOfUnits" numeric [min]="1" [max]="10" />
    </div>
</div>
