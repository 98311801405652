<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarMiddle>
  <div class="d-inline-block ms-2 h-100 d-flex align-items-center hide-on-mobile">
    <div
      class="form-check form-switch form-switch-success me-2"
      *ngIf="applicationTableConfig.isShowArchiveButtonVisible"
    >
      <label for="filter_ShowAsArchive" class="form-check-label">
        Show Archived</label
      >
      <input
        id="filter_ShowAsArchive"
        class="form-check-input"
        [ngModel]="isShowArchived"
        (ngModelChange)="showAsArchiveChange($event)"
        type="checkbox"
      />
    </div>
  </div>
</ng-template>

<ng-template #pageActionBarRight>
  <div class="d-inline-block ms-2 h-100 d-flex align-items-center hide-on-non-mobile">
    <div
      class="form-check form-switch form-switch-success me-2"
      *ngIf="applicationTableConfig.isShowArchiveButtonVisible"
    >
      <label for="filter_ShowAsArchive" class="form-check-label">
        Show Archived</label
      >
      <input
        id="filter_ShowAsArchive"
        class="form-check-input"
        [ngModel]="isShowArchived"
        (ngModelChange)="showAsArchiveChange($event)"
        type="checkbox"
      />
    </div>
  </div>

  <button class="btn btn-primary" (click)="onNewApplicationOpened()" [hidden]="hideNewApplicationButton">
    <i class="fas fa-plus"></i> New Application
  </button>
</ng-template>

<ng-template #mainContent>
  <application-table
    [applications]="applications"
    [applicationTableConfig]="applicationTableConfig"
  >
  </application-table>
</ng-template>

<admin-page-template
  [useActionBarSections]="true"
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarMiddleContentTemplate]="pageActionBarMiddle"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer
  [title]="'New Application'"
  [name]="'newApplicationDrawer'"
  [templateRef]="newApplicationContentRef"
  [options]="newApplicationDrawerOptions"
  (closed)="onNewApplicationDrawerClose()">
</drawer>
<ng-template #newApplicationContentRef>
  <new-application (closeDrawer)="onNewApplicationDrawerClose()"></new-application>
</ng-template>
