import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TableModule } from "primeng/table";
import { CharacteristicsComponent } from "./components/characteristics/characteristics.component";
import { BorrowerCharacteristicsCardComponent } from "./components/borrower-characteristics-card/borrower-characteristics-card.component";
import { BorrowerCharacteristicsHistoryDialogComponent } from "./components/borrower-characteristics-history-dialog/borrower-characteristics-history-dialog.component";
import { CharacteristicsPreviewDialogComponent } from "./components/characteristics-preview-dialog/characteristics-preview.dialog.component";
import { CharacteristicsQuantitiesDialogComponent } from "./components/characteristics-quantities-dialog/characteristics-quantities-dialog.component";
import { LoanCharacteristicsHistoryDialogComponent } from "./components/loan-characteristics-history-dialog/loan-characteristics-history-dialog.component";
import { LoanCharacteristicsCardComponent } from "./components/loan-characteristics-card/loan-characteristics-card.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    TableModule
  ],
  declarations: [
    CharacteristicsComponent,
    LoanCharacteristicsCardComponent,
    BorrowerCharacteristicsCardComponent,
    BorrowerCharacteristicsHistoryDialogComponent,
    LoanCharacteristicsHistoryDialogComponent,
    CharacteristicsPreviewDialogComponent,
    CharacteristicsQuantitiesDialogComponent
  ],
  exports: [
    CharacteristicsComponent
  ],
  providers: [
  ]
})
export class CharacteristicsModule {
  constructor() {
  }
}
