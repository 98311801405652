<p-table #recipientsTable
  [value]="recipients"
  [resizableColumns]="true"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption" *ngIf="showCaption">
    <div class="p-d-flex" style="align-items:center">
      <h5 style="margin: 0">Recipients ({{recipients.length}})</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="'name'" pResizableColumn>Name
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'email'" pResizableColumn>Email
        <p-sortIcon [field]="'email'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'recipientType'" pResizableColumn>Recipient Type
        <p-sortIcon [field]="'recipientType'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'status'" pResizableColumn>Status
        <p-sortIcon [field]="'status'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-recipient>
    <tr>
      <td>{{recipient.name}}</td>
      <td>{{recipient.email}}</td>
      <td>{{recipient.recipientType}}</td>
      <td>{{recipient.status}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="text-center"> There are no recipients. </td>
    </tr>
  </ng-template>
</p-table>
