<div class="task-order-title">
  <div class="row">
    <div class="col-md-4 text-end mt-1">
      <div class="title">Request HOI</div>
    </div>
    <div class="col-md-8">
      <button class="btn btn-primary" (click)="createOrder()">Create Order</button>
    </div>
  </div>

  <div class="row section" style="margin-top: 10px" *ngIf="orders && orders.length > 0">
    <div class="col-md-12">
      <div class="title">Order History</div>
    </div>
    <div class="col-md-12">
      <div class="row head">
        <div class="col-md-3">Request Date</div>
        <div class="col-md-2">Status</div>
        <div class="col-md-3">Agent</div>
        <div class="col-md-1">Quote</div>
        <div class="col-md-3">Company / MoPrem</div>
        <div class="col-md-12">
          <hr />
        </div>
      </div>
      <div class="row details" *ngFor="let order of orders | sort: 'dateInserted'">
        <div class="col-md-3">{{order.dateInserted | date:'MM/dd/yy hh:mm a'}}</div>
        <div class="col-md-2">{{order.thirdPartyOrderStatus}}</div>
        <div class="col-md-3">
          {{order.qiAgent}}
          <a *ngIf="order.qiAgentPhone" href="tel:{{order.qiAgentPhone}}">
            <i class="fa fa-fw fa-phone text-muted"></i>
          </a>
          <a *ngIf="order.qiAgentEmail" href="mailto:{{order.qiAgentEmail}}">
            <i class="fa fa-fw fa-envelope text-muted"></i>
          </a>
        </div>
        <div class="col-md-1">
          <ng-container *ngIf="!order.orderDocument.data">-</ng-container>
          <a *ngIf="order.orderDocument.data" (click)="downloadFile(order.orderDocument.data)">
            <i class="fa fa-file"></i>
          </a>
        </div>
        <div class="col-md-3">{{order.hoiCompanyName}}{{!!order.hoiPremium ? " / " + (order.hoiPremium / 12 | currency)
          : ""}}</div>
      </div>
    </div>
  </div>
</div>
