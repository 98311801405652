<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header" *ngIf="!embedded">
                <h4 class="card-title col-5" style="display: inline-block;">
                    <i class="fas fa-flag-checkered me-2"></i> Credit Reporting
                </h4>
            </div>
            <div class="card-body">

                <ng-container *ngIf="!isTpoUser">
                    <loading-indicator *ngIf="prequalificationCheckLoading" [loadingMessage]="'Saving prequalification settings...'">
                    </loading-indicator>
                    <div class="card" *ngIf="!prequalificationCheckLoading">
                        <div class="card-body" *ngIf="mortgage">
                            <div class="row" style="min-height: 3rem;" *ngFor="let borr of mortgage.borrowers">
                                <div class="col-md-12">
                                <div class="row soft-pull">
                                    <div class="col-md-6 d-flex ms-3">
                                        <div class="form-check" [ngClass]="{'cursor-not-allowed': borr.authorizationMethod === 'Internet'}">
                                            <input type="checkbox" style="margin-top: 0.3rem;" class="form-check-input" id="creditPullAuthorized{{borr.borrowerId}}"
                                              name="creditPullAuthorized{{borr.borrowerId}}" [(ngModel)]="borr.authorizedCreditCheck"
                                              (change)="onAuthorizedCreditChanged(borr)" [readonly]='borr.authorizationMethod === "Internet"'>
                                          </div>
                                        <span style="padding-top: 1px;">
                                        <strong>{{borr.firstName}} {{borr.lastName}}</strong>
                                        - {{isTpoUser ? "Do you have permission to run credit? (√ for yes)" : "Do we have your consent to pull your credit report?"}}
                                        </span>
                                    </div>
                                    <div class="col-md-5">
                                      <div class="d-flex">
                                        <ng-container *ngIf="borr.authorizedCreditCheck">
                                            <div class="d-flex" style="margin-top: -6px; width: 11rem;">
                                              <span class="mt-2 ms-1 me-1">On:</span>
                                              <date-input [id]="'creditPullAuthorizedDate' + borr.borrowerId" [name]="'creditPullAuthorizedDate' + borr.borrowerId"
                                                [(ngModel)]="borr.dateAuthorizedCreditCheck" [readonly]='borr.authorizationMethod === "Internet"'
                                                (ngModelChange)="onCreditAuthDateChanged(borr)">
                                              </date-input>
                                            </div>
                                            <div class="d-flex flex-grow-1 ms-2" style="margin-top: -6px;">
                                              <span class="mt-2 ms-1 me-1">Via:</span>
                                              <select class="form-select flex-grow-1" style="width: 8rem;" 
                                                id="creditPullAuthorizedMethod{{borr.borrowerId}}"
                                                name="creditPullAuthorizedMethod{{borr.borrowerId}}"
                                                [(ngModel)]="borr.authorizationMethod"
                                                (change)="onCreditAuthMethodChanged(borr)"
                                                [disabled]='borr.authorizationMethod === "Internet"'>
                                                <option *ngFor="let type of borr.authorizationMethods" [ngValue]="type.value">
                                                    {{ type.name }}
                                                  </option>
                                              </select>
                                            </div>
                                        </ng-container>
                                      </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end">
                          <button type="button" class="btn btn-primary" (click)="saveLoanMortgage()" [disabled]="!showSaveButtonForCreditAuthInfo">
                            Save
                          </button>
                        </div>
                    </div>
                </ng-container>

                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" (click)="openTab('CreditRun')">
                    <a class="nav-link" [ngClass]="{ active: tab === 'CreditRun' }" data-bs-toggle="tab"
                        href="#pullCreditReport" role="tab" aria-selected="true">
                        Pull Credit Report
                    </a>
                    </li>
                    <li class="nav-item" (click)="openTab('CreditReportHistory')">
                    <a class="nav-link" [ngClass]="{ active: tab === 'CreditReportHistory' }" data-bs-toggle="tab"
                        href="#creditReportHistory" role="tab" aria-selected="false">
                        Credit Report History
                    </a>
                    </li>
                    <li class="nav-item" (click)="openTab('Liabilities')" [hidden]="creditInfoId == null">
                    <a class="nav-link" [ngClass]="{ active: tab === 'Liabilities' }" data-bs-toggle="tab" href="#liabilities"
                        role="tab" aria-selected="false">
                        Import Liabilities
                    </a>
                    </li>
                </ul>

                <!-- Tab panes -->

                <loading-indicator *ngIf="loadingHistory" [loadingMessage]="'Loading credit history, please wait...'">
                </loading-indicator>

                <div class="tab-content" *ngIf="!loadingHistory">
                    <div *ngIf="
                        tab === 'CreditRun' &&
                        borrowerGroups &&
                        permissionLoaded &&
                        isServicesEnabled &&
                        isLatestRunCalculationsFinished
                      " class="tab-pane p-3" [ngClass]="{ active: tab === 'CreditRun' }" id="pullCreditReport" role="tabpanel">


                        <div *ngFor="let borrowers of borrowerGroups" >
                            <loan-credit-run
                                [creditVendors]="creditVendors"
                                [borrowers]="borrowers"
                                [application]="application"
                                [isLoanReadOnly]="isLoanReadOnly"
                                [servicesPermissions]="servicesPermissions"
                                [mortgage]="mortgage"
                                (creditVendorChange)='creditVendor = $event'
                                (creditRunCompleted)="onCreditRunCompleted()"
                                (creditRunSuccessfullyCompleted)="onCreditRunSuccessfullyCompleted()"
                                (reissueSucceeded)="onReissueSucceeded($event)"
                                (importLiabilitiesClicked)="onImportLiabilitiesClicked($event)"
                            ></loan-credit-run>
                        </div>
                    </div>


                    <div *ngIf="tab === 'CreditReportHistory' && creditHistory" class="tab-pane p-3"
                        [ngClass]="{ active: tab === 'CreditReportHistory' }" id="creditReportHistory" role="tabpanel">
                        <credit-report-history
                          [creditHistory]="creditHistory"
                          [creditInfoId]="creditInfoId"
                          (creditRunCompleted)="onCreditRunCompleted()"
                          (reissueSucceeded)="onReissueSucceeded($event)"
                          (importLiabilitiesClicked)="onImportLiabilitiesClicked($event)"
                        ></credit-report-history>
                    </div>
                    <div *ngIf="tab === 'Liabilities' && creditInfoId" class="tab-pane p-3"
                        [ngClass]="{ active: tab === 'Liabilities' }" id="liabilities" role="tabpanel">
                        <import-liabilities
                          [isLoanReadOnly]="isLoanReadOnly"
                          [borrowers]="borrowers"
                          [creditInfoId]="creditInfoId"
                          [applicationId]="application?.applicationId"
                          [liabilitiesHistory]="liabilitiesHistory"
                        ></import-liabilities>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
