import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { SharedModule } from 'src/app/shared/shared.module';
import { AtrQmManagementComponent } from './components/atr-qm-management.component';
import { AprAporComponent } from './components/apr-apor/apr-apor.component';
import { PointsAndFeesComponent } from './components/points-and-fees/points-and-fees.component';
import { AtrQmRecommendationComponent } from './components/atr-qm-recommendation/atr-qm-recommendation.component';
import { AtrQmExemptionEligibilityComponent } from './components/atr-qm-exemption-eligibility/atr-qm-exemption-eligibility.component';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
  ],
  declarations: [
    AtrQmManagementComponent,
    AprAporComponent,
    PointsAndFeesComponent,
    AtrQmRecommendationComponent,
    AtrQmExemptionEligibilityComponent
  ],
  exports: [

  ],
  providers: [
  ]
})
export class AtrQmManagementModule {
  constructor() {
  }
}
