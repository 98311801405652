import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-send-test-email',
  templateUrl: './send-test-email.component.html',
  styleUrls: ['./send-test-email.component.scss']
})
export class SendTestEmailComponent implements OnInit {
  @ViewChild('sendTestEmail')
  sendTestEmail: NgForm | undefined;

  @Input() body: string;
  @Input() emailAddress: string = '';
  
  isLoading: boolean;
  constructor(private readonly _notifyService: NotificationService,
    private readonly _emailService: EmailService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  sendEmail() {
    this.isLoading = true;
    this.sendTestEmail.form.markAllAsTouched();
    if (!this.sendTestEmail.form.valid) {
      return;
    }
    this._emailService.sendTestCampaignEmail(
      this.emailAddress,
      'Template Formatting Verification for Email Campaign.',
      this.body
    ).pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: () => {
          this._notifyService.showSuccess('Email queued for sending', 'Success');
          this.activeModal.close()
        }, error: (err) => {
          this._notifyService.showError(err ? err.message || err : 'Unable to queue email for sending', "Error");
        }
      });
  }

}
