<div class="d-inline-flex flex-wrap">
  <div class="btn-group d-inline-flex align-items-center">
    <label for="eventSourceFilter" class="control-label">Event Source:</label>
    <select name="eventSourceFilter" id="eventSourceFilter" class="form-select min-width-120p ms-1" [(ngModel)]="eventSourceFilter" (change)="onFilterChanged()">
      <option [ngValue]="null">All</option>
      <option *ngFor="let source of eventSources; let index = index" value="{{source.value}}">
        {{ source.name }}
      </option>
    </select>
  </div>
  <div class="btn-group d-inline-flex align-items-center ms-2">
    <label for="directionFilter" class="control-label">Direction:</label>
    <select name="directionFilter" id="directionFilter" class="form-select min-width-120p ms-1" [(ngModel)]="directionFilter" (change)="onFilterChanged()">
      <option [ngValue]="null">All</option>
      <option *ngFor="let direction of eventDirections; let index = index" value="{{direction.value}}">
        {{ direction.name }}
      </option>
    </select>
  </div>
  <div class="btn-group d-inline-flex align-items-center ms-2">
    <label for="operationFilter" class="control-label">Operation:</label>
    <select name="operationFilter" id="operationFilter" class="form-select min-width-120p ms-1" [(ngModel)]="operationFilter" (change)="onFilterChanged()">
      <option [ngValue]="null">All</option>
      <option *ngFor="let operation of eventOperations; let index = index" value="{{operation.value}}">
        {{ operation.name }}
      </option>
    </select>
  </div>
  <div class="btn-group d-inline-flex align-items-center ms-2">
    <label for="statuFilter" class="control-label">Status:</label>
    <select name="statuFilter" id="statuFilter" class="form-select min-width-120p ms-1" [(ngModel)]="statusFilter" (change)="onFilterChanged()">
      <option [ngValue]="null">All</option>
      <option *ngFor="let status of eventStatuses; let index = index" value="{{status.value}}">
        {{ status.name }}
      </option>
    </select>
  </div>
</div>