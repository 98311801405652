import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { isArray } from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { DialList } from "src/app/modules/dialer/models/dial-list.model";
import { DialerService } from "src/app/modules/dialer/services/dialer.service";
import { Constants } from "src/app/services/constants";
import { NotificationService } from "src/app/services/notification.service";
import { DeleteListConfirmationDialogComponent } from "../delete-list-confirmation-dialog/delete-list-confirmation-dialog.component";
import { NewDialListDialogComponent } from "../new-dial-list-dialog/new-dial-list-dialog.component";
import { ContactListType } from "src/app/models";

@Component({
  selector: 'add-to-dial-list-confirmation-dialog',
  templateUrl: './add-to-dial-list-confirmation-dialog.component.html',
  styleUrls: ['./add-to-dial-list-confirmation-dialog.component.scss']
})
export class AddToDialListConfirmationDialogComponent {

  @Input() selectedItems: any[] = [];

  @Input() contactListType: ContactListType = ContactListType.Borrower;

  dialLists: DialList[] = [];

  selectedDialListId: number = null;

  selectedDialList: DialList;

  dialListsLoading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _notify: NotificationService,
    private readonly _dialerService: DialerService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.dialListsLoading = true;
    this._dialerService.getDialListsByType(this.contactListType).subscribe(lists => {
      this.dialLists = isArray(lists) ? lists.filter(l => !l.adminManaged) : [];
      this.dialListsLoading = false;
    });
  }

  onOkClicked = (isRouting = false) => {
    this.activeModal.close({ toDialListId: this.selectedDialListId, isRoutingRequested: isRouting });
  }

  addNewDialList = (): void => {
    const modalRef = this._modalService.open(NewDialListDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.contactListType = this.contactListType;
    modalRef.result.then((res) => {
      this.dialLists.push(res);
      this.selectedDialList = res;
      this.selectedDialListId = res.dialListId;
    }, () => {
    });
  }

  moveToDialList = (fromDialListId: number, toDialListId: number): void => {
    this._dialerService
      .moveDialList(fromDialListId, toDialListId)
      .subscribe(() => {
        this._notify.showSuccess(`Dial List moved successfully.`, 'Dial List');
        this.selectedDialList = null;
      },
        (err) => {
          this._notify.showSuccess(`Error ${err.message} while moving dial list records to other dial list`, 'Dial List')
        });
  }

  deleteDialList = (dialListId: number): void => {
    this._spinner.show();
    this._dialerService
      .deleteDialList(dialListId)
      .subscribe(() => {
        this._notify.showSuccess('Dial List deleted successfully.', 'Dial List');
        this.selectedDialList = null;
        this.dialLists = this.dialLists.filter(list => list.dialListId != dialListId);
      },
        (err) => {
          this._notify.showError(err.message ? err.message : 'An error occurred while deleting the dial list.', 'Error!');
        }).add(() => this._spinner.hide());
  }

  openDeleteDialListDialog = (selectedDialList: DialList): void => {
    this.selectedDialList = selectedDialList;
    const modalRef = this._modalService.open(DeleteListConfirmationDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.deleteDialList = this.selectedDialList;
    modalRef.componentInstance.toDialLists = this.getOtherDialLists(this.selectedDialList);
    modalRef.result.then((res: { toDialListId: number, isMoving: string }) => {
      if (res.isMoving == 'yes') {
        this.moveToDialList(this.selectedDialList.dialListId, res.toDialListId);
      }
      this.deleteDialList(this.selectedDialList.dialListId);
    }, () => {
    });
  }

  private getOtherDialLists = (differentDialList: DialList): DialList[] => {
    return this.dialLists.filter(list => list.dialListId != differentDialList.dialListId);
  }

}
