import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DocumentType, ThirdPartyCredential } from '../../../../../../models';
import { NotificationService } from '../../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'twn-income-employment-verification-modal',
  templateUrl: './twn-income-employment-verification-modal.component.html'
})
export class TwnIncomeEmploymentVerificationModal extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  @Input() userId: string | null;
  @Input() documentTypes: Array<DocumentType>;
  @Input() credential: ThirdPartyCredential;
  @Input() scope: ScopeType;

  keyValuePairs = {
    savedDocumentTypeId: null,
    appId: null,
    platform: null,
    intermediary: null,
    resellerCustomer: null
  };

  isSaving: boolean;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keyValuePairs.savedDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SavedDocumentTypeId') || {};
    this.keyValuePairs.appId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'AppId') || {};
    this.keyValuePairs.platform = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'Platform') || {};
    this.keyValuePairs.intermediary = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'Intermediary') || {};
    this.keyValuePairs.resellerCustomer = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'ResellerCustomer') || {};
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);
    this.credential.companyId = this.applicationContext.userPermissions.companyId;
    this.credential.userId = this.userId;

    this.isSaving = true;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}
