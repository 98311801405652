<div class="my-2" [ngStyle]="{'width.px': width, 'height.px': height}">
  <div *ngIf="imageUrl" class="{{ customClass }} me-2">
    <img
      [src]="imageUrl | safeUrl"
      [alt]="alt"
      [height]="height"
      [width]="width"
      (error)="onImageError()"
      class=" rounded"
    />
  </div>
  <div>
    <input
      id="file-input"
      #Image
      style="display:none"
      type="file"
      (change)="handleFileUpload($event.target.files)"
    />
  </div>
</div>
<div class="button-items" *ngIf="!readonly">
  <button
    type="button"
    class="btn btn-outline-primary btn-sm"
    (click)="Image.click()"
  >
    <i class="mdi mdi-upload me-2"></i>{{ imageUrl ? 'Edit' : 'Upload' }}
  </button>
  <button
    type="button"
    class="btn btn-sm btn-outline-danger"
    *ngIf="imageUrl"
    (click)="onRemove()"
  >
    <i class="mdi mdi-alert-outline me-2"></i>Remove
  </button>
</div>
