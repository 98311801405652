import { Component, Injector, OnInit } from '@angular/core';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ExternalContactsService } from '../../services/external-contacts.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { Subscription } from "rxjs";
import { ApplicationContext } from 'src/app/models';

@Component({
  selector: 'external-contacts',
  templateUrl: './external-contacts.component.html',
  styleUrls: ['./external-contacts.component.scss']
})
export class ExternalContactsComponent extends ApplicationContextBoundComponent implements OnInit {

  constructor(
    private readonly _externalContactsService: ExternalContactsService,
    private readonly _notifyService: NotificationService,
    private readonly _drawerService: DrawerService,
    private readonly _navigationService: NavigationService,
    injector: Injector,
  ) {
    super(injector);
  }

  appId: number;

  borrowerId: number;

  externalContactList = [];

  selectedRows: any[];

  borrowerLastName: string;

  mailingStreet: string;

  applicationMode: string;

  isTpoUser: boolean;

  loadingContacts: boolean;

  deleteAgentId: number = -1;

  openAgentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  agentId: number = 0;

  private _loanInfoChangesSubscription: Subscription;

  ngOnInit(): void {

    this.initializeFromContext(this.applicationContext);

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (!context.application) {
        return;
      }
      this.initializeFromContext(context);
    });

    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onExternalContactNameClicked = (agentId: number) => {
    this.agentId = agentId;
    this._drawerService.show("openAgentEditorDrawer", 10);
  }

  loadExternalContactList = () => {
    this.loadingContacts = true;
    this._externalContactsService.getExternalContacts(this.appId).subscribe({
      next: (response) => {
        this.externalContactList = response;
      },
      error: (error) => {
        const fallbackMessage = 'Unable to load external contacts';
        const message = error?.message || fallbackMessage;
        console.error(fallbackMessage, error);

        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this.loadingContacts = false);
  }

  confirmDelete = (agentId: number) => {
    this._externalContactsService.removeAgentFromExternalContact(this.appId, agentId).subscribe({
      next: () => {
        this.loadExternalContactList();
      }, error: (error) => {
        const fallbackMessage = 'Unable to delete agent';
        const message = error?.message || fallbackMessage;
        console.error(fallbackMessage, error);

        this._notifyService.showError(message, 'Error');
      },
    });
  };

  cancelDelete = () => {
    this.deleteAgentId = -1;
  }

  deleteAgent = (agentId: number) => {
    this.deleteAgentId = agentId;
  }

  mailExternalContact = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      let mailTo = '';
      this.selectedRows.forEach((rowData) => {
        if (rowData.email != null && rowData.email != 'null') {
          mailTo += rowData.email + ';';
        }
      });
      window.location.href = 'mailto:' + mailTo + '?subject=' + this.borrowerLastName + ' - ' + this.mailingStreet;
    }

  }

  copyExternalContactToClipboard = () => {
    let text = '';
    if (this.selectedRows && this.selectedRows.length > 0) {
      this.selectedRows.forEach((externalContact) => {
        if (externalContact.orgName != null)
          text += externalContact.orgName + "\r\n";

        if (externalContact.firstName != null || externalContact.lastName != null) {
          text += (externalContact.lastName + ", " + externalContact.firstName);

          if (externalContact.agentType)
            text += " (" + externalContact.agentType + ")";

          text += "\r\n";
        }
        if (externalContact.phone != null && externalContact.phone != "")
          text += "P: " + externalContact.phone + "\r\n";

        if (externalContact.email != null)
          text += "E: " + externalContact.email + "\r\n";
        text += "\r\n"
      })
      navigator.clipboard.writeText(text);
      this._notifyService.showInfo("Contact info copied to clipboard", "Info");
    }
  }

  onAgentEditorDrawerClosed = () => {
    this._drawerService.hide("openAgentEditorDrawer", 10);
  }

  onAgentEdited = () => {
    this.loadExternalContactList();
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.appId = context.application.applicationId;
    this.borrowerId = context.application.primaryBorrowerId;
    if (context.borrowers.length > 0) {
      this.borrowerLastName = context.borrowers[0].lastName;
    }
    this.mailingStreet = context.application.mailingStreet;
    this.isTpoUser = context.isTpo;
    this.loadExternalContactList();
  }
}
