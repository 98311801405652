<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header" *ngIf="!embedded">
        <h4 class="card-title col-5" style="display: inline-block;">
          <i class="fas fa-flag-checkered me-2"></i> Credit Reporting
        </h4>
      </div>
      <div class="card-body">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" (click)="openTab('CreditRun')">
            <a class="nav-link" [ngClass]="{ active: tab === 'CreditRun' }" data-bs-toggle="tab"
              href="#pullCreditReport" role="tab" aria-selected="true">
              Pull Credit Report
            </a>
          </li>
          <li class="nav-item" (click)="openTab('CreditReportHistory')">
            <a class="nav-link" [ngClass]="{ active: tab === 'CreditReportHistory' }" data-bs-toggle="tab"
              href="#creditReportHistory" role="tab" aria-selected="false">
              Credit Report History
            </a>
          </li>
          <li class="nav-item" (click)="openTab('Liabilities')" [hidden]="creditInfoId == null">
            <a class="nav-link" [ngClass]="{ active: tab === 'Liabilities' }" data-bs-toggle="tab" href="#liabilities"
              role="tab" aria-selected="false">
              Import Liabilities
            </a>
          </li>
        </ul>

        <!-- Tab panes -->

        <loading-indicator *ngIf="loadingHistory" [loadingMessage]="'Loading credit history, please wait...'">
        </loading-indicator>

        <div class="tab-content" [hidden]="loadingHistory">
          <div *ngIf="
              tab === 'CreditRun' &&
              borrowers &&
              permissionLoaded &&
              isServicesEnabled &&
              isLatestRunCalculationsFinished
            " class="tab-pane p-3" [ngClass]="{ active: tab === 'CreditRun' }" id="pullCreditReport" role="tabpanel">
            <credit-run
              [borrowers]="borrowers"
              [creditVendors]="creditVendors"
              [creditVendor]="creditVendor"
              [applicationId]="applicationId"
              [leadId]="leadId"
              [servicesPermissions]="servicesPermissions"
              [creditInfoId]="creditInfoId"
              [creditHistory]="creditHistory"
              [latestPullCredit]="latestPullCredit"
              [mortgage]="mortgage"
              (creditRunCompleted)="onCreditRunCompleted($event)"
              (creditRunSuccessfullyCompleted)="onCreditRunSuccessfullyCompleted()"
              (reissueSucceeded)="onReissueSucceeded($event)"
              (importLiabilitiesClicked)="onImportLiabilitiesClicked($event)"
            ></credit-run>
          </div>
          <div *ngIf="tab === 'CreditReportHistory' && creditHistory" class="tab-pane p-3"
            [ngClass]="{ active: tab === 'CreditReportHistory' }" id="creditReportHistory" role="tabpanel">
            <credit-report-history
              [creditHistory]="creditHistory"
              [creditInfoId]="creditInfoId"
              (creditRunCompleted)="onCreditRunCompleted()"
              (reissueSucceeded)="onReissueSucceeded($event)"
              (importLiabilitiesClicked)="onImportLiabilitiesClicked($event)"
            ></credit-report-history>
          </div>
          <div *ngIf="tab === 'Liabilities' && creditInfoId" class="tab-pane p-3"
            [ngClass]="{ active: tab === 'Liabilities' }" id="liabilities" role="tabpanel">
            <import-liabilities
              [isLoanReadOnly]="isLoanReadOnly"
              [borrowers]="borrowers"
              [creditInfoId]="creditInfoId"
              [applicationId]="applicationId"
              [liabilitiesHistory]="liabilitiesHistory"
            ></import-liabilities>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
