export class BorrowerMessage {
  userId: string;
  content: string;
  applicationId: number;

  messageId?: number;
  loanDocTaskId?: number;
  borrowerId?: number;
  agentId?: number;
  flagInternalMessage?: boolean;
  isLosMessage?: boolean;
  postTime?: string;
  senderId?: string;
  senderFirstName?: string;
  senderLastName?: string;
  senderDisplayName?: string;
  senderAvatar?: string;
  notifyPartyId?: Array<string>;
  companyId?: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
  conversationId?: number;
  plainTextContent?: string;
}

export class BorrowerChatMessage extends BorrowerMessage {
  groupDate?: number;
}

export interface BorrowerChatRequested {
  appId: number,
  borrowerId: number,
  borrMessage: NewBorrowerMessagePostedModel
  openDrawer?: boolean
  external?: boolean
}

export interface InternalChatRequested {
  appId: number,
  borrMessage: NewBorrowerMessagePostedModel
  openDrawer?: boolean
  external?: boolean
}

export type NewBorrowerMessagePostedModel = Partial<BorrowerMessage>;
