<div class="container-fluid page-action-bar" id="tpo-bread-crumb">
  <div class="container container-width">
    <div class="d-flex flex-xs-column justify-content-xs-center text-xs-center mb-xs-2">
      <div class="page-action-bar-left d-flex"
        [ngClass]="{'page-action-bar-left-margin' : pageActionBarLeftContentTemplate}">
        <ng-container *ngTemplateOutlet="pageActionBarLeftContentTemplate"></ng-container>
      </div>
      <div class="ms-xs-0 ms-2 page-action-bar-middle flex-grow-1">
        <ng-container *ngTemplateOutlet="pageActionBarMiddleContentTemplate"></ng-container>
      </div>
      <div class="page-action-bar-right justify-content-xs-between text-xs-center mx-xs-2 align-items-xs-center"
        [ngClass]="{'page-action-bar-right-margin' : pageActionBarRightContentTemplate}">
        <ng-container *ngTemplateOutlet="pageActionBarRightContentTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<div class="page-wrapper">
  <div class="container container-width">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row mt-3 mb-4" id="tpo">
          <ng-container *ngTemplateOutlet="pageMainContentTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

