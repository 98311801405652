import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function nonZeroValueValidator(allowZero: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = !allowZero && control.value === 0;
    return invalid ? { cannotBeZero: { value: control.value } } : null;
  };
}

@Directive({
  selector: '[allowZero]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NonZeroValidatorDirective, multi: true }]
})
export class NonZeroValidatorDirective implements Validator {

  @Input('allowZero') allowZero = true;

  validate(control: AbstractControl): ValidationErrors | null {
    return nonZeroValueValidator(this.allowZero)(control);
  }
}
