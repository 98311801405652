import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { cloneDeep, isArray } from 'lodash';
import { Observable, catchError, firstValueFrom, of, switchMap } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { Address, DenialTerminationChangeDetail, LoanApplication, MortgageBorrower, ResidencyAddress, ResidencyType, SubjectProperty } from 'src/app/models';
import { Lender } from 'src/app/models/config/lender.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { LenderConfigService } from 'src/app/modules/admin/lender-config/services/lender-config.service';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanService } from 'src/app/services/loan';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LenderInfoDialogComponent } from '../loan-info/loan-details/lender-info-dialog/lender-info-dialog.component';
import { MortgageModelFieldsConfig } from 'src/app/shared/services/mortgage-field-validation-utils';

@Component({
  selector: 'app-hmda',
  templateUrl: 'app-hmda.component.html',
  styleUrls: ['app-hmda.component.scss']
})

export class AppHmdaComponent implements OnInit {
  @Input()
  mortgage: UrlaMortgage;

  @Input()
  application: LoanApplication;

  @Input()
  documentTypes: DocumentType[];

  @Input()
  deniedByUserName: string;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSave: EventEmitter<UrlaMortgage> = new EventEmitter<UrlaMortgage>();

  denialTerminationChangeDetail: DenialTerminationChangeDetail;
  denialDate: string;
  mailingDate: string;
  reason1: string;
  reason2: string;
  isSaving: boolean;
  isLoading: boolean;
  isGeneratingDoc: boolean;
  selectedDocumentTypeId: number;
  docGenMode: boolean = false;

  subjectPropertyAddressIsTbd: boolean = false;
  states: EnumerationItem[] = [];
  suffixes: EnumerationItem[] = [];

  quickApplyFieldsConfig: MortgageModelFieldsConfig;

  lenders: Lender[] = [];
  selectedLenderInfo: Lender;
  lenderAddress: Address = new Address();

  tab: number;

  borrowerGroups: MortgageBorrower[][] = [];
  borrowers: MortgageBorrower[] = [];
  subjectProperty: SubjectProperty;

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';
  protected mortgageBorrowerPresentAddresMap: Map<number, ResidencyAddress> = new Map<number, ResidencyAddress>();

  constructor(private readonly _mortgageService: MortgageService,
    private readonly _loanService: LoanService,
    private readonly _lenderService: LenderConfigService,
    private readonly _enumerationService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _notifsService: NotificationService
  ) {
    this.quickApplyFieldsConfig = this._mortgageService.quickApplyFieldsConfig;
  }

  ngOnInit(): void {
    this.denialTerminationChangeDetail = cloneDeep(this.mortgage.denialTerminationChangeDetail);
    this.borrowers = cloneDeep(this.mortgage.borrowers);
    this.subjectProperty = cloneDeep(this.mortgage.subjectProperty);

    if (isArray(this.denialTerminationChangeDetail.otherReasons)) {
      this.reason1 = this.denialTerminationChangeDetail.otherReasons[0] || '';
      this.reason2 = this.denialTerminationChangeDetail.otherReasons[1] || '';
    }
    this.getDenialKeyDates();

    this.states = this._enumerationService.states;
    this._enumerationService.getMortgageEnumerations().subscribe(enumerations => {
      this.suffixes = enumerations[Constants.enumerations.suffix];
    });

    this.loadLenders();
    this.adjustBorrowers(this.borrowers);

    this.getBorrowerDisplayName = Utils.getBorrowerDisplayName;

    if(this.subjectProperty.address1 == "TBD"){
      this.subjectPropertyAddressIsTbd = true;
    }
  }

  onSaveClicked(generateDocAfterSave: boolean): void {
    this.denialTerminationChangeDetail.otherReasons = [];
    if (this.reason1) {
      this.denialTerminationChangeDetail.otherReasons.push(this.reason1)
    }
    if (this.reason2) {
      this.denialTerminationChangeDetail.otherReasons.push(this.reason2)
    }
    this.isSaving = true;
    if (generateDocAfterSave) {
      this.isGeneratingDoc = true;
    }
    this.getAndSaveMortgage()
      .subscribe({
        next: (mortgage: UrlaMortgage) => {
          this.mortgage = mortgage;
          this.saveDenialKeyDates(generateDocAfterSave);
        },
        error: (err) => {
          this.isSaving = false;
          this._notifsService.showError(err.message || 'Unable to save informaiton.', 'Error!');
        }
      });
  }

  onGenerateDocClicked() {
    this.isGeneratingDoc = true;
    this._mortgageService.generateDenialTerminationDocument({
      mortgageId: this.mortgage.mortgageId,
      saveAsDocumentTypeId: this.selectedDocumentTypeId,
      sendToBorrowers: true
    })
      .subscribe({
        next: () => {
          this._notifsService.showSuccess('Document generated successfully.', 'Success!');
          this.docGenMode = false;
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to generate document.', 'Error!');
        }
      }).add(() => this.isGeneratingDoc = false)
  }

  private saveDenialKeyDates(generateDocAfterSave: boolean) {
    const request = {
      "DateDenied": this.isValidDate(this.denialDate) ? new Date(this.denialDate).toISOString() : null,
      "DateDenialSent": this.isValidDate(this.mailingDate) ? new Date(this.mailingDate).toISOString() : null
    }

    this._loanService.saveKeyDatesByType(request, this.mortgage.applicationId)
      .subscribe({
        next: () => {
          this.onSave.emit(this.mortgage);
          if (generateDocAfterSave) {
            this.onGenerateDocClicked();
          } else {
            this._notifsService.showSuccess("Your information has been saved successfully.", "Success!");
          }
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to get key dates for application.', 'Error!');
        }
      }).add(() => this.isSaving = false);
  }

  private getAndSaveMortgage(): Observable<UrlaMortgage> {
    return this._mortgageService.getUrlaMortgage(this.mortgage.applicationId)
      .pipe(
        catchError((error) => of(error)),
        switchMap((savedMortgage: UrlaMortgage) => {
          savedMortgage.denialTerminationChangeDetail = cloneDeep(this.denialTerminationChangeDetail);
          savedMortgage.borrowers.forEach(borr => {
            let updatePresentAddres = this.mortgageBorrowerPresentAddresMap.get(borr.borrowerId);
            let matcedPresentAddres = borr.residencyAddresses.find(ra => ra.residencyAddressId == updatePresentAddres.residencyAddressId);
            Object.assign(matcedPresentAddres.address, updatePresentAddres.address);

            let matchedBorr = this.borrowers.find(b => b.borrowerId == borr.borrowerId);
            borr.firstName = matchedBorr.firstName;
            borr.lastName = matchedBorr.lastName;
            borr.middleName = matchedBorr.middleName;
            borr.nameSuffix = matchedBorr.nameSuffix;
          });

          Object.assign(savedMortgage.subjectProperty, this.subjectProperty);
          return this._mortgageService.saveMortgage(savedMortgage)
        }));
  }

  private isValidDate(dateString: string) {
    if (!dateString) {
      return false;
    }
    const date = Date.parse(dateString);
    return !isNaN(date);
  }

  private getDenialKeyDates() {
    this.isLoading = true;
    this._loanService.getKeyDatesByType(this.mortgage.applicationId).subscribe({
      next: (response) => {
        this.denialDate = response['dateDenied']?.eventDate || null;
        this.mailingDate = response['dateDenialSent']?.eventDate || null;
      },
      error: (err) => {
        this._notifsService.showError(err.message || 'Unable to get key dates for application.', 'Error!');
      }
    }).add(() => this.isLoading = false);
  }

  onCloseClicked(): void {
    this.onClose.emit();
  }

  hasValue(reasonText: string): boolean {
    return !!reasonText;
  }

  showLenderInfoDialog() {
    const modalRef = this._modalService.open(LenderInfoDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.lenderInfo = this.selectedLenderInfo;
  }

  protected handleAddressChange(e: Partial<Address>, address: Address): void {
    address.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      address.address1 = e.address1;
      address.city = e.city;
      address.state = e.state;
      address.zipCode = e.zipCode;
      address.county = e.county;
    }, 200);
  }

  protected onSubjectPropertyTBDCheckChanged = () => {
    if (this.subjectPropertyAddressIsTbd) {
      this.subjectProperty.address1 = 'TBD';
      this.subjectProperty.address2 = '';
    }
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, address: Address) => {
    if (zipCode) {
      address["state"] = zipCode.state.toLowerCase();
      address["city"] = Utils.toTitleCase(zipCode.city);
      address["zipCode"] = zipCode.zipcode;
      address["county"] = Utils.toTitleCase(zipCode.county);
      address["country"] = 'us';
    }
  }

  private loadLenders = async (): Promise<void> => {
    const lenders = await firstValueFrom(
      this._lenderService.getAllLenders(),
    );
    this.lenders = lenders.filter((e) => e.active)
      .sort((a, b) => a.sortOrder - b.sortOrder);

    this.selectedLenderInfo = this.lenders.find(x => x.lenderId == this.application.lenderId);

    if(this.selectedLenderInfo){
      this.lenderAddress.address1 = this.selectedLenderInfo.address;
      this.lenderAddress.address2 = this.selectedLenderInfo.address2;
      this.lenderAddress.state = this.selectedLenderInfo.state;
      this.lenderAddress.zipCode = this.selectedLenderInfo.zip;
      this.lenderAddress.city = this.selectedLenderInfo.city;
    }
  };

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    this.borrowerGroups = [];

    this.borrowerGroups = borrowers.length > 0 ? Object.values(_.groupBy(borrowers, 'printApplicationIndex')) : [];

    if (this.borrowerGroups.length > 0) {
      this.tab = this.borrowerGroups[0][0].borrowerId;
    }

    borrowers.forEach(borrower => {
      let presentAddress = borrower.residencyAddresses.find(ra => ra.residencyType === ResidencyType.PresentAddress);

      if (!presentAddress) {
        presentAddress = new ResidencyAddress();
        presentAddress.residencyType = ResidencyType.PresentAddress;
        borrower.residencyAddresses.push(presentAddress)
      }

      this.mortgageBorrowerPresentAddresMap.set(borrower.borrowerId, presentAddress);
    });
  }
}
