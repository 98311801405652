import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Borrower } from 'src/app/models';
import { TaskCategory } from 'src/app/models/config/global-config.model';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';

@Component({
  selector: 'characteristics-preview-dialog',
  templateUrl: 'characteristics-preview-dialog.component.html'
})
export class CharacteristicsPreviewDialogComponent implements OnInit {

  tasksPreview: LoanDocTask[] = [];
  borrowers: Borrower[] = [];
  characteristicType: string;
  taskCategories: TaskCategory[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  getTaskCategoryName = (taskCategoryId: number): string => {
    const taskCategory = this.taskCategories.find(tc => tc.taskCategoryId == taskCategoryId);
    if (taskCategory) {
      return taskCategory.taskCategoryName;
    }
    return "";
  }

  getBorrowerName = (borrowerId: number): string => {
    const borrower = this.borrowers.find(b => b.borrowerId == borrowerId);
    if (borrower) {
      return (borrower.firstName ? borrower.firstName : '') + ' ' + (borrower.lastName ? borrower.lastName : '');
    }
    return "";
  }

  onSaveClicked = () => {
    this.activeModal.close(this.tasksPreview);
  }
}
