export interface BulkUpdateLead {
  leadIds: number[];
  updates: PropertUpdateSpec[];
}

export interface PropertUpdateSpec {
  propertyName: string;
  value: string;
  action: UpdateAction;
}

export enum UpdateAction {
  Set = "Set",
  Clear = "Clear"
}
