import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reset-to-default-mappings-dialog',
  templateUrl: './reset-to-default-mappings-dialog.component.html',
  styleUrls: ['./reset-to-default-mappings-dialog.component.scss']
})
export class ResetToDefaultMappingsDialogComponent {

  constructor(public activeModal: NgbActiveModal
  ) { }

}
