import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class LogService {
  constructor(private readonly _dataService: DataService) { }

  logSignalRAck(messageName, signalR_traceId): Observable<any> {

    let url  = "api/log/signalr/" + messageName + "/ack";

    if (signalR_traceId) {
      const header = new HttpHeaders({  'LE-ReqTraceID' : signalR_traceId })
      return this._dataService.post(url, {}, header);
    }

    return this._dataService.post(url, {});
  }

}
