import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { ThirdPartyCredential, ThirdPartyCredentialType } from '../../../../../models';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { chain, cloneDeep } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LenderPriceVendorEditorDialogComponent } from '../pricing/lenderprice-vendor-editor-dialog/lenderprice-vendor-editor-dialog.component';
import { Constants } from 'src/app/services/constants';
import { PollyVendorEditorDialogComponent } from '../pricing/polly-vendor-editor-dialog/polly-vendor-editor-dialog.component';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { MeridianVendorEditorDialogComponent } from '../pricing/meridian-vendor-editor-dialog/meridian-vendor-editor-dialog.component';
import { LoanPassProfileVendorEditorDialogComponent } from '../pricing/loanpass-profile-vendor-editor-dialog/loanpass-profile-vendor-editor-dialog.component';
import { LoanPassIFrameVendorEditorDialogComponent } from '../pricing/loanpass-iframe-vendor-editor-dialog/loanpass-iframe-vendor-editor-dialog.component';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { OBVendorEditorDialogComponent } from '../pricing/ob-vendor-editor-dialog/ob-vendor-editor-dialog.component';

@Component({
  selector: 'integration-pricing',
  templateUrl: './integration-pricing.component.html'
})
export class IntegrationPricingComponent extends ApplicationContextBoundComponent implements OnInit {


  @Input()
  optimalBlueCreds: ThirdPartyCredential[] = [];

  @Input()
  lenderPriceCreds: ThirdPartyCredential[] = [];

  @Input()
  pollyPriceCreds: ThirdPartyCredential[] = [];

  @Input()
  meridianLinkPricingCreds: ThirdPartyCredential[] = [];

  @Input()
  loanPassPricingCreds: ThirdPartyCredential[] = [];

  @Input()
  loanPassIframePricingCreds: ThirdPartyCredential[] = [];

  @Input()
  systemLevel: any;

  @Input()
  branchId?: number;

  @Input()
  scope: 'User' | 'Branch' | 'TpoUser' | 'ExternalCompanyBranch'

  @Input()
  enabledPricingEngines: PricingVendor[];

  @ViewChild('integrationPricingForm')
  integrationPricingForm: NgForm | undefined;

  isSaving: boolean;
  hasSystemLevelCredsForPolly: boolean;
  isOBEnabled: boolean;

  constructor(
    injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.isOBEnabled = this.enabledPricingEngines?.indexOf(PricingVendor.OptimalBlue) > -1;

    this.hasSystemLevelCredsForPolly = this.systemLevel
      .thirdPartyCredentials?.some(el => el.credentialType === ThirdPartyCredentialType.PricingVendor && el.vendorName === 'Polly');
  }

  clearOriginator(item): void {
    item.value = '';
  }

  trackByIndex(index: number): number {
    return index;
  };

  openCredentialModal(selectedVendor: 'LenderPrice' | 'Polly' | 'MeridianLink' | 'LoanPass' | 'LoanPassIframe' | 'OptimalBlue', credential: ThirdPartyCredential = null): void {
    const ModalComponent = this.getVendorModalComponent(selectedVendor);
    const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
    const newVendorCreds = new ThirdPartyCredential(ThirdPartyCredentialType.PricingVendor, selectedVendor);
    modalRef.componentInstance.scope = this.scope;
    modalRef.componentInstance.branchId = this.branchId;
    modalRef.componentInstance.userCompanyGuid = this.route.snapshot.params.id || this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
    modalRef.componentInstance.vendor = cloneDeep(credential) || newVendorCreds;
    modalRef.componentInstance.inEditMode = !!credential;
    modalRef.componentInstance.availableBusinessChannels = this.getBusinessChannelsSetAtSystemLevelForPolly();
    modalRef.result.then(
      (result) => {
        if (result.vendorName === PricingVendor.LenderPrice) {
          const index = this.lenderPriceCreds.findIndex(el => el.credentialId === result.credentialId);
          if (index > -1) {
            this.lenderPriceCreds[index] = result;
          } else {
            this.lenderPriceCreds.push(result);
          }
        }
        if (result.vendorName === PricingVendor.Polly) {
          const index = this.pollyPriceCreds.findIndex(el => el.credentialId === result.credentialId);
          if (index > -1) {
            this.pollyPriceCreds[index] = result;
          } else {
            this.pollyPriceCreds.push(result);
          }
        }
        if (result.vendorName === PricingVendor.MeridianLink) {
          const index = this.meridianLinkPricingCreds.findIndex(el => el.credentialId === result.credentialId);
          if (index > -1) {
            this.meridianLinkPricingCreds[index] = result;
          } else {
            this.meridianLinkPricingCreds.push(result);
          }
        }
        if (result.vendorName === PricingVendor.LoanPass) {
          const index = this.loanPassPricingCreds.findIndex(el => el.credentialId === result.credentialId);
          if (index > -1) {
            this.loanPassPricingCreds[index] = result;
          } else {
            this.loanPassPricingCreds.push(result);
          }
        }
        if (result.vendorName === PricingVendor.LoanPassIframe) {
          const index = this.loanPassIframePricingCreds.findIndex(el => el.credentialId === result.credentialId);
          if (index > -1) {
            this.loanPassIframePricingCreds[index] = result;
          } else {
            this.loanPassIframePricingCreds.push(result);
          }
        }
        if (result.vendorName === PricingVendor.OptimalBlue) {
          const index = this.optimalBlueCreds.findIndex(el => el.credentialId === result.credentialId);
          if (index > -1) {
            this.optimalBlueCreds[index] = result;
          } else {
            this.optimalBlueCreds.push(result);
          }
        }
      },
      () => { }
    );
  }

  private getVendorModalComponent(type: 'LenderPrice' | 'Polly' | 'MeridianLink' | 'LoanPass' | 'LoanPassIframe' | 'OptimalBlue') {
    if (type === 'OptimalBlue') return OBVendorEditorDialogComponent;
    if (type === 'LenderPrice') return LenderPriceVendorEditorDialogComponent;
    if (type === 'Polly') return PollyVendorEditorDialogComponent;
    if (type === 'MeridianLink') return MeridianVendorEditorDialogComponent;
    if (type === 'LoanPass') return LoanPassProfileVendorEditorDialogComponent;
    if (type === 'LoanPassIframe') return LoanPassIFrameVendorEditorDialogComponent;
  }

  private getBusinessChannelsSetAtSystemLevelForPolly() {
    const pollyCreds: ThirdPartyCredential = this.systemLevel.thirdPartyCredentials
      ?.find(el => el.credentialType === ThirdPartyCredentialType.PricingVendor && el.vendorName === PricingVendor.Polly);
    if (!pollyCreds) {
      return [];
    }
    return chain(pollyCreds.thirdPartyKeyValuePairs)
      .cloneDeep()
      .filter(el => el.key.startsWith('Channel:'))
      .map(kvp => {
        kvp.key = kvp.key.replace('Channel:', '');
        return new EnumerationItem(kvp.key, kvp.key)
      })
      .value();
  }
}
