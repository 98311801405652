import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendSms } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ContentGenerationDialogComponent } from 'src/app/modules/campaign/Components/content-generation-dialog/content-generation-dialog.component';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { Constants } from 'src/app/services/constants';
import { MergeFieldContextMenuComponent } from '../merge-field-context-menu/merge-field-context-menu.component';
import { FileAttachment } from '../send-mms/send-mms.component';

export class SmsRecipient {
  firstName: string;
  lastName: string;
  phoneNumbers: {
    number: string;
    type: PhoneType
  }[]
}

export class SmsInfo {
  body: string;
  toPhoneNumber: string;
  appendSmsSignature: boolean;
}

@Component({
  selector: 'common-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})
export class CommonSendSmsComponent implements OnInit {

  @ViewChild("sendSmsForm")
  sendSmsForm: NgForm;

  @ViewChild(MergeFieldContextMenuComponent)
  mergeFieldContextMenu: MergeFieldContextMenuComponent;

  @Input()
  recipients: SmsRecipient[] = [];

  @Input() toPhoneNumber: string;

  @Input()
  attachments: FileAttachment[] = [];

  @Input()
  recipientsVisible: boolean = true;

  @Input()
  smsGenerationEnabled: boolean = false;

  @Input()
  availableMergeFields: EnumerationItem[] = [];

  @Input()
  loanId: number;

  @Input()
  isShowOnlyMMS: boolean = false;

  body: string = "";
  appendSmsSignature: boolean = false;

  isMMS: boolean = false;

  mergeFieldsMentionConfig: any;

  constructor(private readonly _modalService: NgbModal) { }

  ngOnInit() {
    if (this.isShowOnlyMMS) {
      this.isMMS = true;
    }

    if (this.availableMergeFields && this.availableMergeFields.length > 0) {
      this.mergeFieldsMentionConfig = {
        items: this.availableMergeFields,
        triggerChar: '%',
        labelKey: 'value',
        mentionSelect: (el) => `%${el.value}%`
      }
    }
  }

  getSmsInfo = (): SendSms => {
    let mediaFiles = [];
    if (this.attachments) {
      this.attachments.forEach(attachment => {
        mediaFiles.push({
          fileName: attachment.file.name,
          data: attachment.base64.toString().split(',')[1],
          mimeType: attachment.file.type
        });
      });
    }
    let sms = new SendSms();
    sms.body = this.body;
    sms.to = this.toPhoneNumber;
    sms.applicationId = this.loanId;
    sms.appendSmsSignature = this.appendSmsSignature;
    sms.mediaFiles = mediaFiles;
    return sms;
  }

  validate = (): boolean => {
    if (this.sendSmsForm) {
      this.sendSmsForm.form.markAllAsTouched();
      return this.sendSmsForm.form.valid;
    }
  }

  switch = () => {
    this.isMMS = !this.isMMS;
    this.attachments = [];
  }

  onContextMenu($event: MouseEvent, targetProperty: string): void {
    this.mergeFieldContextMenu.show(
      $event,
      this,
      targetProperty,
      null
    );
    $event.preventDefault();
    $event.stopPropagation();
  }

  openSmsGenerationModal() {
    const modalRef = this._modalService.open(ContentGenerationDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.generationType = 'SMS';
    modalRef.result.then((suggestedBody: string) => {
      this.body = suggestedBody;
    }, () => {
    });
  }
}
