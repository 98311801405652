import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tpo-new-application.component',
  templateUrl: 'tpo-new-application.component.html'
})
export class TpoNewApplicationComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
