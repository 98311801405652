import { ResidencyBasis, ResidencyType } from "src/app/models";

export class NewAppBorrowerModel {
  borrowerId = -1;
  jointWithBorrowerId: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  primaryEmail: string;
  typeOfBorrower: string;
  mobilePhone: string;
  homePhone: string;
  workPhone: string;
  residencyAddresses = [
    {
      residencyBasis: "",
      residencyType: ResidencyType.PresentAddress,
      address: {
        address1: "",
        address2: "",
        city: "",
        country: "US",
        state: "",
        zipCode: ""
      }
    }
  ]
}
