<p>confirm-loan-purpose-change-dialog works!</p>
<div class="modal-header">
  <h5 class="modal-title">Confirm Loan Purpose Change</h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  Changing Loan Purpose will reset your Lead Status to the beginning. Are you
  sure you want to do this?
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-soft-secondary "
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>

  <button
    type="submit"
    class="btn btn-soft-primary "
    (click)="activeModal.close()"
  >
    Yes, change loan purpose
  </button>
</div>
