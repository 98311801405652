import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as ClassicEditor from 'src/app/shared/utils/ckeditor5/classic/build/ckeditor';

@Component({
  selector: 'function-editor-with-context-params',
  templateUrl: 'function-editor-with-context-params.component.html',
})
export class FunctionEditorWithContextParamsComponent
  implements OnInit, AfterViewInit {
  @ViewChild('functionEditorComponent')
  functionEditorComponent: any;

  @Output()
  functionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled: boolean = false;

  @Input()
  options: FunctionEditorWithParametersConfig[] = [];

  @Input()
  functionExpression: string = "";

  @Input()
  placeholder: string = '';

  functionEditor = ClassicEditor;

  editorConfig: any = {};

  private _currentParameterOptions: ContextParameter[] = [];

  constructor(private readonly _elementRef: ElementRef) {
  }

  ngAfterViewInit(): void { }

  ngOnInit() {
    this.editorConfig = {
      placeholder: this.placeholder,
      typing: {
        transformations: {
          include: []
        }
      },
      mention: {
        dropdownLimit: Infinity,
        feeds: [
          {
            marker: this.options[0].triggerCharacter,
            feed: this.getFeedItems,
          },
        ],
      },
    };
  }

  reset = () => {
    this.functionExpression = '';
  };

  onEditorReady(editor: ClassicEditor) {
    const dom: HTMLElement = this._elementRef.nativeElement;
    const ckToolbar = dom.querySelector('.ck-toolbar') as HTMLElement;
    ckToolbar.style.display = 'none';
    if (this.disabled) {
      editor.enableReadOnlyMode('function-editor');
    } else {
      editor.disableReadOnlyMode('function-editor');
    }
    editor.editing.view.document.on('keydown', (evt, data) => {
      const option = this.options.find((o) => o.triggerKeyCode == data.keyCode);
      if (option) {
        this._currentParameterOptions = option.parameters;
      }
    });
  }

  onFunctionChanged = () => {
    if (this.functionEditorComponent && this.functionEditorComponent.editorInstance) {
      const { convert } = require('html-to-text');
      let plainText: string = convert(this.functionEditorComponent.editorInstance.getData());
      this.functionChanged.emit(plainText);
    }
  };

  private getFeedItems = (queryText: string): ContextParameter[] => {
    const itemsToDisplay = this._currentParameterOptions
      // Filter out the full list of all items to only those matching the query text.
      .filter(isItemMatching);
    return itemsToDisplay;

    function isItemMatching(item: ContextParameter) {
      // Make the search case-insensitive.
      const searchString = queryText.toLowerCase();

      return (
        item.text.toLowerCase().includes(searchString) ||
        item.id.toLowerCase().includes(searchString)
      );
    }
  };
}

export class ContextParameter {
  id: string;
  text: string;
}

export class FunctionEditorWithParametersConfig {
  triggerCharacter: string;
  triggerKeyCode: number;
  parameters: ContextParameter[] = [];
}
