<form #taskLoanDocPreviewForm="ngForm" novalidate id="taskLoanDocPreviewForm" name="taskLoanDocPreviewForm" class="d-flex flex-column p-0"
  style="height: 100%">
  <div class="row" style="border-bottom: 2px solid #ececec;">
    <div class="col-md-12">
      <div class="row mb-2" style="padding: 1rem 0 0 0.5rem;">
        <div class="col-2">
          <div class="col-md-12 text-end" *ngIf="task.applicationId">
            <div class="mb-2"
              [hidden]="((task.borrowerId == undefined) && (!task.multipleBorrower || task.multipleBorrower.length == 0))">
              <label for="requestBorrower">
                Request From Borrower </label>
              <input class="form-check-input ms-2" type="checkbox" id="requestBorrower" name='requestBorrower'
                [(ngModel)]="task.requestBorrower" [checked]="task.requestBorrower" (change)="populateStatusDropdown()">
            </div>
          </div>
          <div class="col-md-12">
            <label for="taskStatus">Task Status</label>
            <select class="form-select" id="taskStatus" [(ngModel)]="task.taskStatus" name="taskStatus">
              <option value="ConditionImportPending" *ngIf="task.taskStatus=='ConditionImportPending'">
                Condition Import Pending</option>
              <option *ngFor="let status of taskStatusOptions" value="{{status.value}}"
                [selected]="task.taskStatus == status.value">
                {{status.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-10 ps-0">
          <div class="col-md-10 ms-1" style="width: 99%;" *ngIf="task.requestBorrower && task.applicationId">
            <label for="borrowerFacingNote" class="mb-lg-0 text-end">Borrower
              Facing Note</label>
            <message-editor-with-mentions #borrowerFacingNoteEditor [mentionables]="mentionables"
              (messageChanged)="onBorrowerFacingNoteChanged($event)">
            </message-editor-with-mentions>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="flex: 1 1 calc(100% - 123px);overflow-y: hidden;">
    <div class="col-md-2 d-flex flex-column" style="border-right: 2px solid #ececec; height: 100%">
      <div class="row toolbar my-1">
        <div class="d-flex justify-content-between">
          <div class="ms-3">
            <span class="fw-bold">
              Number of files: {{task.docFiles?.length || '0'}}
            </span>
          </div>
          <div>
            <a *ngIf="isMergeVisible()" class="badge text-info fw-bold hoverBadge px-0" style="line-height: 14px;"
            (click)="onMergeFilesClicked()"> MERGE
          </a>
          <span *ngIf="isMergeVisible()" class="text-muted mx-1">|</span>
          <a class="badge text-info fw-bold hoverBadge px-0" style="line-height: 14px;"
            (click)="onSelectAllButtonManagement(!selectAllClicked)"> {{!selectAllClicked ? 'Select All' : 'Unselect
            All'}}
          </a>
          <span class="text-muted mx-1">|</span>
          <input type="file" id="newLoanDocFile" name="newLoanDocFile" style="display:none" multiple
            (change)="onAddDocFileClicked($event)" #addNewDocFileBtn />
          <a class="badge text-info fw-bold hoverBadge ps-0" style="line-height: 14px;"
            (click)="onCreateDocFileClicked(true)"> Create
          </a>
          </div>
        </div>
      </div>
      <div id="scroll-end-point" class="file-box-content mt-1 ms-2 flex-grow-1">
        <div *ngFor="let docFile of task.docFiles" class="file-box ms-1 mt-2" style="width: 95%;"
          [ngClass]="{'active-box-shadow': previousFileGuid === docFile.guid, 'cursor': previousFileGuid !== docFile.guid}"
          (click)="onDocFileViewClicked(docFile)">
          <i *ngIf="!docFile['icon']" class="fas fa-sync-alt fa-spin text-primary loading-icon"></i>
          <div *ngIf="docFile['icon']" class="d-flex justify-content-between mb-1" style="margin: -12px;">
            <div class="checkbox-container">
              <input type="checkbox" (click)="checkboxFileSelected(docFile, $event)"
                [(ngModel)]="docFile.selectedForAction" name="isMergeFile{{docFile.guid}}"
                id="isMergeFile{{docFile.guid}}" [disabled]="!docFile.active" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="file-box-icons">
              <a *ngIf="docFile['showConvertToPdf']" (click)="onConvertToPdfClicked(docFile, $event)" class="download-icon-link">
                <i class="file-box-icon far fa-file-pdf"></i>
              </a>
              <a (click)="onDocFileEditClicked(docFile, $event)" class="download-icon-link">
                <i class="file-box-icon fas fa-edit" style="margin-left: 0.3rem;"></i>
              </a>
              <a (click)="onDownloadDocumentClicked(docFile, $event)" class="download-icon-link">
                <i class="file-box-icon fas fa-download"></i>
              </a>
              <a *ngIf="docFile.active" (click)="onDeleteFileClicked(docFile, $event)" class="download-icon-link">
                <i class="file-box-icon far fa-trash-alt ms-1"></i>
              </a>
              <a *ngIf="!docFile.active" (click)="onRestoreFileClicked(docFile, $event)" class="download-icon-link">
                <i class="file-box-icon fas fa-trash-restore ms-1"></i>
              </a>
            </div>
          </div>
          <div *ngIf="docFile['icon']" [ngClass]="{'cursor': previousFileGuid !== docFile.guid}"
            class="text-center mt-2">
            <i [class]="docFile['icon']"></i>
            <div *ngIf="!docFile['shouldEdit']">
              <a (click)="onEditFileName(docFile, $event)" [ngClass]="{'text-decoration-line-through': !docFile.active}">
                <span class="doc-file-name" style="color: #1f9ad6;">
                  {{docFile.fileName}}
                </span>
              </a>
              <span *ngIf="docFile.createDate" class="d-block text-muted text-center">
                {{'(' + (docFile.createDate | date:'short') + ')'}}
              </span>
            </div>
            <div *ngIf="docFile['shouldEdit']">
              <input style="width: 10rem;" type="text" class="form-control w-100" name="documentName{{docFile.guid}}"
                id="documentName{{docFile.guid}}" [(ngModel)]="docFile['fileNameWithoutExtension']" />
              <div class="mt-1">
                <a (click)="onFileRenameConfirmed(docFile, $event)">
                  <span> <i class="fa fa-check-circle text-success" style="font-size: small;"></i>
                  </span>
                </a>
                <a (click)="onFileRenameCancelled(docFile, $event)">
                  <span class="ms-2"> <i class="fa fa-times text-danger" style="font-size: small;"></i> </span>
                </a>
              </div>
            </div>
            <small class="text-muted">
              Type:
              <span style="font-weight: 500;"> {{docFile.docFileType || '--'}} /
                <span class="text-success" style="font-weight: 500;" *ngIf="docFile.active; else inActive">
                  Active
                </span>
                <ng-template #inActive>
                  <span class="text-danger" style="font-weight: 500;">
                    Inactive
                  </span>
                </ng-template>
              </span>
            </small>
          </div>
        </div>
        <div  class="create-doc-file ms-1" (click)="onCreateDocFileClicked()">
          <div class="icon">
            <i class="fas fa-plus"></i>
          </div>
          <div class="mt-2">
            Create
          </div>
        </div>
        <div class="file-boxes-end">
        </div>
      </div>
    </div>
    <div class="col-md-10 d-flex flex-column align-items-stretch flex-grow-1">
      <div class="row doc-file-frame my-3 me-1 flex-grow-1">
        <ng-container *ngIf="frameUrl">
          <embed [src]="frameUrl" frameborder="0" width="400" scale="tofit">
        </ng-container>

        <ng-container *ngIf="editingFile">
          <doc-files-edit-dialog #docFilesEditDialog
            [isModal]="false"
            [file]="editingFile"
            [fileExtension]="fileExtension"
            [appId]="appId"
            [globalConfig]="globalConfig"
            [loanDocs]="loanDocs">
          </doc-files-edit-dialog>
        </ng-container>
      </div>

      <div class="row me-1 mb-4" *ngIf="hasSaveCancelButtons">
        <div class="col-md-12 text-end mt-3">
          <button class="btn btn-danger" (click)="cancelled.emit(findExistingDrawer()); isDocFileEditClicked = false">Cancel</button>
          <button class="btn btn-primary ms-2" (click)="onSaveClicked(false)">
            Save & Close
          </button>
          <button class="btn btn-primary ms-2" (click)="onSaveClicked(true)">
            Save & Next
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
