import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-scenario-comparison-select-dialog',
  templateUrl: './scenario-comparison-select-dialog.component.html',
  styleUrls: ['./scenario-comparison-select-dialog.component.scss']
})
export class ScenarioComparisonSelectDialogComponent implements OnInit {

  @ViewChild('scenarioSelectionForm')
  scenarioSelectionForm: NgForm

  @Input() scenarios: PricingScenario[] = [];

  selectedScenarios: number[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.scenarios.forEach((s, index) => {
      s.name = s.name ? s.name : 'Scenario ' + (index + 1);
    })
  }

  onSelectClicked = () => {
    if (this.scenarioSelectionForm) {
      this.scenarioSelectionForm.form.markAllAsTouched();
      if (!this.scenarioSelectionForm.form.valid) {
        return;
      }
    }
    let selections = this.scenarios.filter(s => this.selectedScenarios.includes(s.pricingScenarioId));
    this.activeModal.close(selections);
  }
}
