import { Injectable } from '@angular/core';
import { StyleSheetUseType } from '../models/style-sheet-use-type.model';
import { DataService } from '../../../../core/services/data.service';
import { Observable } from 'rxjs';

@Injectable()
export class CustomCssService {

  constructor(
    private readonly _dataService: DataService,
  ) {
  }

  upload = (useType: StyleSheetUseType, formData: FormData) => {
    const url = `api/admin/custom-css/${useType}`;
    return this._dataService.postFormData(url, formData);
  };

  download = (useType: StyleSheetUseType): Observable<BlobPart> => {
    const url = `api/admin/custom-css/${useType}`;
    return this._dataService.downloadFile(url);
  };

  delete = (useType: StyleSheetUseType) => {
    const url = `api/admin/custom-css/${useType}`;
    return this._dataService.delete(url);
  };
}
