<mwl-text-input-autocomplete-container>
  <textarea type="text" id="expression" name="expression" class="form-control drop-area-x"
    [value]="'This is the expression'" placeholder="Press CTRL+SPACE to search..." rows="{{ rows }}"
    cols="{{ columns }}" [triggerCharacter]="triggerCharacter" [(ngModel)]="expression"
    (ngModelChange)="onExpressionChanged()" mwlTextInputAutocomplete (menuShown)="onMenuShown()"
    (menuHidden)="onMenuHidden()" [keyboardShortcut]="keyboardShortcut" [findChoices]="findChoices"
    [getChoiceLabel]="getChoiceLabel" (dragenter)="handleDragEnter($event)" (dragleave)="handleDragLeave($event)"
    (drop)="drop($event)" (dragover)="allowDrop($event)">
  </textarea>
  <div class="pt-2">
    <div class="row">
      <div class="col-md-12">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <button type="button" [disabled]="true" title="Validate Expression" class="btn btn-success"
            (click)="onValidateClicked()">
            Validate
          </button>
          <button type="button" title="Clear Expression" class="btn btn-danger" (click)="onClearClicked()">
            Clear
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2" *ngIf="alert">
      <alert class="col-md-12" [alert]="alert"></alert>
    </div>
  </div>
</mwl-text-input-autocomplete-container>
