import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ShortLink } from '../models/short-link.model';

@Injectable({ providedIn: 'root' })
export class ShortLinkService {

  constructor(private _dataService: DataService) { }

  getShortLinks = (): Observable<ShortLink[]> => {
    const url = 'api/short-link/';
    return this._dataService.get(url);
  }

  getShortLink = (id: number): Observable<ShortLink> => {
    const url = `api/short-link/${id}`;
    return this._dataService.get(url);
  }

  createShortLink = (shortLink: ShortLink): Observable<ShortLink> => {
    const url = 'api/short-link/';
    return this._dataService.post(url, shortLink);
  }

  updateShortLink = (id: number, shortLink: ShortLink): Observable<ShortLink> => {
    const url = `api/short-link/${id}`;
    return this._dataService.post(url, shortLink);
  }

  deleteShortLink = (id: number): Observable<boolean> => {
    const url = `api/short-link/${id}`;
    return this._dataService.delete(url);
  }

  getShortLinkRedirectUrl = (shortCode: string, queryString: string): Observable<ShortLink> => {
    let url = `api/short-link/redirect/${shortCode}`;
    if (queryString) {
      url += `?queryString=${queryString}`;
    }
    return this._dataService.get(url);
  }
}
