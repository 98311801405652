import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { MortgageService } from 'src/app/services/mortgage.service';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { forkJoin } from 'rxjs';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'manage-borrowers',
  templateUrl: 'manage-borrowers.component.html',
  styleUrls: ['manage-borrowers.component.scss']
})

export class ManageBorrowersComponent implements OnInit {

  @Input()
  mortgageBorrowers: UrlaBorrower[] = [];

  borrowers: UrlaBorrower[] = [];
  applications = [];

  protected signingRoleNames: Map<number, string> = new Map<number, string>();

  private _signingRoleOptions: EnumerationItem[] = [];

  constructor(public activeModal: NgbActiveModal,
    private readonly _mortgageService: MortgageService,
    private readonly _enumsService: EnumerationService,
    private readonly _spinner: NgxSpinnerService) {
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this._signingRoleOptions =
        enums[Constants.mortgageEnumerations.signingRole];
    });
  }

  ngOnInit() {
    this.mortgageBorrowers.forEach(val => this.borrowers.push(Object.assign({}, val)));

    this.initPrintIndex();
    this.sortBorrowers();
    this.initApplications();
    this.arrangeApplications();
  }

  initPrintIndex() {
    let maxPrintApplicationIndex = Math.max.apply(Math, this.mortgageBorrowers.map(o => o.printApplicationIndex));

    let nextIndex = Number.isNaN(maxPrintApplicationIndex) ? 0 : maxPrintApplicationIndex++;
    for (const borrower of this.mortgageBorrowers) {
      if (borrower.printApplicationIndex == undefined || borrower.printApplicationIndex == null) {
        borrower.printApplicationIndex = nextIndex;
        nextIndex++;
      }
    }
  }

  sortBorrowers() {
    this.mortgageBorrowers = _.orderBy(this.mortgageBorrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);
  }

  initApplications() {
    for (const borrower of this.mortgageBorrowers) {
      const signingRole = this._signingRoleOptions.find(sr => sr.value === borrower.signingRole);
      this.signingRoleNames.set(borrower.borrowerId, (signingRole?.name || ''));

      if (this.applications.length <= borrower.printApplicationIndex) {
        this.applications.push([]);
      }
      if (!this.applications[borrower.printApplicationIndex]) {
        this.applications[borrower.printApplicationIndex] = [];
      }

      this.applications[borrower.printApplicationIndex].push(borrower);
    }
  }

  arrangeApplications() {
    this.deleteEmptyContainers();
    if (this.mortgageBorrowers.length > 1) {
      this.applications.push([]);
    }
  }

  deleteEmptyContainers() {
    for (let i = 0; i <= this.applications.length - 1; i++) {
      if (this.applications[i].length === 0) {
        this.applications.splice(i, 1);
        i--;
      }
    }
  }

  drop(event: CdkDragDrop<number[]>) {
    // inside same container
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    // between containers
    else {
      // add borrower
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.arrangeApplications();
    }
  }

  deleteBorrower(appIndex: number, borrowerIndex: number) {
    this.applications[appIndex].splice(borrowerIndex, 1);
    this.arrangeApplications();
  }

  save() {
    const savedBorrowers = [];

    for (let appIndex = 0; appIndex < this.applications.length; appIndex++) {
      let borrowers = this.applications[appIndex];

      for (let borrowerIndex = 0; borrowerIndex < borrowers.length; borrowerIndex++) {
        borrowers[borrowerIndex].printApplicationIndex = appIndex;
        borrowers[borrowerIndex].jointWithBorrowerId = borrowerIndex == 0 ? null : borrowers[0].borrowerId;
        savedBorrowers.push(borrowers[borrowerIndex]);
      }
    }

    const apiCalls = [];
    const deletedBorrowers = this.mortgageBorrowers.filter(ab => !savedBorrowers.map(b => b.borrowerId).includes(ab.borrowerId));
    deletedBorrowers.forEach(b => {
      const apiCall = this._mortgageService.deleteBorrowerFromMortgage(b.borrowerId, true);
      apiCalls.push(apiCall);
    });

    if (apiCalls.length) {
      this._spinner.show();
      forkJoin(apiCalls).subscribe({
        next: (rep) => {
          this._spinner.hide();
          this.activeModal.close(savedBorrowers);
        },
        error: () => {
          this._spinner.hide();
        }
      })
    }
    else {
      this.activeModal.close(savedBorrowers);
    }
  }

  dropListEnterPredicate() {
    return function (drag: CdkDrag, drop: CdkDropList) {
      return drop.data.filter(b => b.signingRole == 'Borrower').length < 2 || (drag.data.signingRole != null && drag.data.signingRole != 'Borrower');
    };
  }
}
