import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/core/services/utils';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { EventEditorDialogComponent } from 'src/app/modules/events/components/event-editor-dialog/event-editor-dialog.component';
import { Event, EventType, EventVisibility } from 'src/app/modules/events/models/event.model';
import { EventsService } from 'src/app/modules/events/services/events.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() recordType: RecordType;
  @Input() recordTypeId: number;
  @Input() editViewType: EditViewType = EditViewType.Dialog;

  @Output() appointmentUpserted = new EventEmitter<EventWithOperationType>();

  isLoading: boolean = false;
  columns: any[] = [];
  selectedRows: any[] = [];
  selectedColumns: any[] = [];
  globalFilterFields: string[] = [];

  appointments: Event[] = [];

  isDeleteClicked = {};

  private _userId: string;
  private _isAdmin: boolean;

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _eventsService: EventsService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this._userId = context.userPermissions.userId;
      this._isAdmin = context.userPermissions.admin;
    });

    this.columns = [
      { field: 'title', header: 'Title', order: 1, visible: true },
      { field: 'description', header: 'Description', order: 2, visible: true },
      { field: 'dateStart', header: 'Start Time', order: 3, visible: true },
      { field: 'dateEnd', header: 'End Time', order: 4, visible: true }
    ];

    this.selectedColumns = [];

    this.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });

    this.getAppointments();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  getAppointments = () => {

    this.isLoading = true;

    this._eventsService.getEventsByType(this.recordType, this.recordTypeId)
      .subscribe(events => {
        this.isLoading = false;
        if (this.recordType != RecordType.Borrower) {
          this.appointments = events;
        } else {
          this.appointments = events.filter(e => e.borrowerId == this.recordTypeId);
        }
      },
        (err) => {
          this.isLoading = false;
        })
  }

  editAppointment = (event: Event): void => {
    if (this.editViewType == EditViewType.Inline) {
      const eventWithType = new EventWithOperationType();
      eventWithType.event = event;
      eventWithType.type = AppointmentType.Edit;
      this.appointmentUpserted.emit(eventWithType);
    } else {
      const modalRef = this._modalService.open(EventEditorDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.event = event;

      modalRef.result.then((response) => {
        this.ngOnInit();
      }, () => { })
    }
  }

  deleteAppointment = (event: Event): void => {

    if (this._userId == event.userId || this._isAdmin) {

      this._eventsService.deleteEvent(event.eventId, event.thirdPartyEventId)
        .subscribe(() => {
          this._notifyService.showSuccess("Event removed succesfully", "Success");
          this.ngOnInit();
        }, () => {
          this._notifyService.showSuccess("Error encountered while deleting event", "Error!");
        });

    }

  }

  setAppointmentClicked = () => {

    let event = new Event();
    event.eventType = EventType.Appointment;
    event.applicationId = this.recordType == RecordType.Application ? this.recordTypeId : null;
    event.borrowerId = this.recordType == RecordType.Borrower ? this.recordTypeId : null;
    event.leadId = this.recordType == RecordType.Lead ? this.recordTypeId : null;
    event.companyId = this.applicationContext.userPermissions.companyId;
    event.userId = this.applicationContext.userPermissions.userId;
    event.privilege = EventVisibility.Private;
    const startDate = new Date();
    event.dateStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 0, 0, 0).toISOString();
    event.dateEnd = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 15, 0, 0).toISOString();



    const eventWithType = new EventWithOperationType();
    eventWithType.event = event;
    eventWithType.type = AppointmentType.Create;

    if (this.editViewType == EditViewType.Inline) {
      this.appointmentUpserted.emit(eventWithType);
    } else {
      const modalRef = this._modalService.open(EventEditorDialogComponent, Constants.modalOptions.large);

      let idFieldName = Utils.getRecordIdFieldName(this.recordType);

      modalRef.componentInstance[idFieldName] = this.recordTypeId;
      modalRef.componentInstance.event = event;
      modalRef.componentInstance.isAppointmentEvent = true;

      modalRef.result.then((e: Event) => {
        eventWithType.event = e;
        this.appointmentUpserted.emit(eventWithType);
        this.ngOnInit();
      }, () => { });
    }

  }
}

export class EventWithOperationType {
  event: Event;
  type: AppointmentType;
}

export enum AppointmentType {
  Create = "Create",
  Edit = "Edit"
}

export enum EditViewType {
  Dialog = 'Dialog',
  Inline = 'Inline'
}