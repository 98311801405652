import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditPricingDetailsComponent } from './edit-pricing-details/edit-pricing-details.component';
import { EditPricingRoutingModule } from './edit-pricing-routing.module';
import { EditPricingComponent } from './edit-pricing.component';

@NgModule({
  declarations: [
    EditPricingComponent,
    EditPricingDetailsComponent
  ],
  imports: [
    SharedModule,
    EditPricingRoutingModule,
  ],
  exports: [
    EditPricingComponent,
    EditPricingDetailsComponent
  ]
})
export class EditPricingModule { }
