import { Features } from './features.model';

export class Company {
  companyId: number = 0;
  companyURL: string;
  borrowerURL: string;
  onlineAppURL: string;
  onlineAppV3Url: string;
  agentURL: string;
  companyGUID: string;
  companyName: string;
  address: string;
  city: string;
  state: string = "";
  zip: string;
  logo: string;
  smallLogo: string;
  signatureLogo: string;
  favicon: string;
  actionBgColor: string;
  actionTextColor: string;
  logInImage: string;
  registerImage: string;
  nmls: string;
  fhaLenderId: string;
  fhaSponsorId: string;
  vaLenderId: string;
  vaSponsorId: string;
  enabledChannels: string = "Disabled";
  tpoUserRegistrationURL: string;
  isTemplate: boolean;
  leadPostingApiAuthKey: string;
  supportEmails: string;
  timeZone: string = null;
  twilioSubaccountId: string;
  f: Features = new Features();
  cobrowseEnabled: boolean;
  docEditingEnabled: boolean;
  docGenerationEnabled: boolean;
  feesEnabled: boolean;
  full1003Enabled: boolean;
  leadManagementEnabled: boolean;
  dialerEnabled: boolean;
  onlineApplicationEnabled: boolean;
  onlineAppV3Enabled: boolean;
  digitalAssetVerificationEnabled: boolean;
  digitalEmploymentVerificationEnabled: boolean;
  prequalGenerationEnabled: boolean;
  smsAlertsEnabled: boolean;
  rvmAlertsEnabled: boolean;
  socialMediaAlertsEnabled: boolean;
  socialMediaIntegrationEnabled: boolean;
  emailTestsEnabled: boolean;
  inviteBorrowerCheckedByDefault: boolean;
  creditMonitoringEnabled: boolean;
  creditReportingEnabled: boolean;
  ausEnabled: boolean;
  desktopOriginatorEnabled: boolean;
  desktopUnderwriterEnabled: boolean;
  docMagicEnabled: boolean;
  enhancedReportingEnabled: boolean;
  lpaEnabled: boolean;
  losEnabled: boolean;
  pricingEnabled: boolean;
  campaignsEnabled: boolean;
  multipleCredentialSupportEnabled: boolean;
  leadPostingApiAuthEnabled: boolean;
  titleOrderingEnabled: boolean;
  externalCompanyEnabled: boolean;
  homeOwnersInsuranceEnabled: boolean;
  documentSigningEnabled: boolean;
  losDocumentSigningEnabled: boolean;
  losDocumentSigningExtendedTokenLifespanEnabled: boolean;
  loanBeamEnabled: boolean;
  ocrEnabled: boolean;
  branchesEnabled: boolean;
  isDisabled: boolean;

  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}
