import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SystemLevelService } from '../../../../services/system-level.service';
import { NotificationService } from '../../../../services/notification.service';
import { AgentPortalContent, SystemLevel } from 'src/app/models';
import { CustomCssControlsConfig } from 'src/app/modules/admin/config-shared/components/custom-css-controls/custom-css-controls.component';

@Component({
    selector: 'agent-portal-settings',
    templateUrl: './agent-portal-settings.component.html'
})
export class AgentPortalSettingsComponent implements OnInit {

    agentPortalContent: AgentPortalContent;
    loadingSystemData: boolean;
    isSaving: boolean;

    protected readonly customCssControlsConfig = CustomCssControlsConfig.AgentPortal();

    constructor(
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.loadingSystemData = true;
        this._systemLevelService.getAgentPortalContent().subscribe((data: AgentPortalContent) => {
            this.agentPortalContent = data;
        }).add(() => this.loadingSystemData = false);
    }

    save(): void {
        this.isSaving = true;
        this._systemLevelService.saveAgentPortalContent(this.agentPortalContent).pipe(finalize(() => {
            this.isSaving = false;
        })).subscribe({
            next: res => {
                this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
            },
            error: error => {
                this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
            }
        });
    }

    protected readonly CustomCssControlsConfig = CustomCssControlsConfig;
}
