<div class="modal-header modal-header--sticky">
  <h5 class="modal-title"> Editing Scenario Fees </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body mt-5 mb-3">
  <div class="row">
    <fees-editor
      #feesEditor
      [fees]="fees"
      [isScenario]="true"
      (openFeeEditor)="onFeeEditorDrawerOpened($event)"
      (feeAdded)="onFeesAdded($event)"
      (feeUpdated)="onFeeUpdated($event)"
      (feeRemoved)="onFeeRremoved($event)"
    ></fees-editor>
  </div>
</div>
<div class="modal-footer modal-footer--sticky">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()" data-bs-dismiss="modal"> Save
  </button>
</div>

<drawer
  #feesEditorDrawer
  [title]="feeEditorDrawerTitle"
  [name]="'feesEditorDrawer'"
  [templateRef]="feeDetailsDrawerRef"
  [options]="feeEditorDrawerOptions"
  (closed)="onFeeEditorDrawerClosed()">
</drawer>
<ng-template #feeDetailsDrawerRef>
  <fee-details-editor
    [fees]="fees"
    [fee]="selectedFeeForEditingDetails"
    [loanAmount]="loanAmount"
    [totalLoanAmount]="totalLoanAmount"
    [purchasePrice]="purchasePrice"
    [propertyAppraisedValue]="propertyAppraisedValue"
    (save)='onFeeEditorDrawerSaved($event)'
    (cancel)='onFeeEditorDrawerClosed()'
  ></fee-details-editor>
</ng-template>
