<side-nav
  [currentlyLoggedInUser]="currentlyLoggedInUser"
  [currentCompanyGuid]="currentCompanyGuid">
</side-nav>
<header [user]="user"
  (mentionsClicked)="onMentionsStreamToggled($event)"
  (niceInContactClicked)="onNiceInContactToggled($event)"
  (notificationsClicked)="onNotificationsToggled($event)"
  (alertsCreated)="onAlertsCreated($event)"
  (conversationsToggled)="onConversationsToggled($event)"
  [unreadConversations]="checkUnreadConversations()"></header>
<right-nav></right-nav>
<right-sub-nav></right-sub-nav>
<admin-loan-summary-top-bar *ngIf="insideLoanContext"></admin-loan-summary-top-bar>

<div class="container-fluid dashboard-wrapper" id="dashboard-wrapper">
  <router-outlet class="mt-5"></router-outlet>
</div>

<!-- <lead-route-leads></lead-route-leads> -->

<drawer [title]="'Mentions (' + numberOfLoadedMentions + ')'"
  #mentions
  [name]="'mentionsDrawer_NextGen'"
  [templateRef]="mentionsDrawerContentRef"
  [options]="mentionsDrawerOptions"
  (closed)="onMentionsDrawerClosed()">
</drawer>
<ng-template #mentionsDrawerContentRef>
  <mentions-stream
    (mentionClicked)="onMentionClicked($event)"
  (recordsLoaded)="onMentionsLoaded($event)">
  </mentions-stream>
</ng-template>

<drawer [title]="'Alerts & Notifications'"
  #alerts
  [name]="'alertsDrawer_NextGen'"
  [templateRef]="alertsDrawerContentRef"
  [options]="mentionsDrawerOptions"
  (closed)="onNotificationsDrawerClosed()">
</drawer>
<ng-template #alertsDrawerContentRef>
  <notifications-list
      [data]="notifications">
    </notifications-list>
</ng-template>

<drawer [title]="'Loan Activity'"
  [options]="loanActivityDrawerOptions"
  [name]="'loanActivityDrawer_NextGen'"
  [templateRef]="loanActivityRef"
  (closed)="onMentionDetailsDrawerClosed()">
</drawer>
<ng-template #loanActivityRef>
  <div style="height: 90%; padding: 12px;">
    <loan-activity
      [isSendingWhenHitEnterConfigurable]="false"
      [showOnlyActivityLog]="true"
      [idOfMessageToScrollTo]="messageId"
      [showFilters]="false"
      [activityTab]="'InternalMessage'"
      [appId]="mentionApplicationId"
      [sendWhenHitEnter]="true"
      *ngIf="mentionMessageScope == 'InternalMessage'">
    </loan-activity>
  </div>
</ng-template>

<drawer [title]="'Editing Task'"
  [options]="taskEditorDrawerOptions"
  [name]="'taskEditorDrawer_NextGen'"
  [templateRef]="taskEditorRef"
  (closed)="onMentionDetailsDrawerClosed()">
</drawer>
<ng-template #taskEditorRef>
  <img *ngIf="taskMentionTrackingUrl" [src]="taskMentionTrackingUrl" class="tracker-image" width="1" height="1">
  <task-editor style="height: 90%"
    *ngIf="mentionMessageScope == 'TaskNote' && mentionLoanDocTask"
    [hostedInModal]="false"
    [task]="mentionLoanDocTask">
  </task-editor>
</ng-template>

<drawer [title]="'Conversations'"
  [name]="'conversationsDrawer_NextGen'"
  [templateRef]="conversationsContentRef"
  [options]="conversationsDrawerOptions"
  (closed)="onConversationsDrawerClosed()">
</drawer>
<ng-template #conversationsContentRef>
  <conversations-list
    #conversationList
    [allChats]="allChats"
    [userId]="conversationsUserId"
    [loanStatus]="loanStatus"
    [senderAvatar]="avatarUrl"
    (impersonatedUserChanged)="onImpersonatedUserChanged($event)"
  >
  </conversations-list>
</ng-template>

