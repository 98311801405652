import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoanService } from 'src/app/services/loan/loan.service';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  VoaComponent,
  VoaHistoryComponent,
  VoaImportAssetsComponent,
  VoaOrderComponent,
} from '.';
import { ImportAssetsDialogComponent } from './components/import-assets-dialog/import-assets-dialog.component';
import { GenerateVoaOrderDialogComponent } from './components/generate-voa-order-dialog/generate-voa-order-dialog.component';

@NgModule({
    imports: [
      SharedModule,
      NgSelectModule
    ],
    declarations: [
      VoaComponent,
      VoaHistoryComponent,
      VoaImportAssetsComponent,
      VoaOrderComponent,
      ImportAssetsDialogComponent,
      GenerateVoaOrderDialogComponent
    ],
    exports: [
      VoaComponent,
      VoaHistoryComponent,
      VoaImportAssetsComponent,
      VoaOrderComponent,
      ImportAssetsDialogComponent,
      GenerateVoaOrderDialogComponent
    ],
    providers: [LoanService]
})
export class VoaModule {
  constructor() {}
}
