import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactList, ContactListType } from 'src/app/models';
import { AvailableFreeForAllLead } from 'src/app/models/available-free-for-all-lead';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { LeadRouteService } from 'src/app/services/lead-route.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';

@Component({
  selector: 'app-accept-and-dial-free-leads-dialog',
  templateUrl: './accept-and-dial-free-leads-dialog.component.html',
  styleUrls: ['./accept-and-dial-free-leads-dialog.component.scss']
})
export class AcceptAndDialFreeLeadsDialogComponent implements OnInit {

  @Input() model: AvailableFreeForAllLead;
  @Input() contactListName: string;

  disabledDialog: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _notifyService: NotificationService,
    private readonly _leadRouteService: LeadRouteService,
    private readonly _router: Router,
    private readonly _drawerService: DrawerService,
    private readonly _dialerService: DialerService,
    private readonly _conversationService: ConversationService) {
  }

  ngOnInit(): void {
  }

  acceptAndDialFreeForAllLead = () => {
    this.disabledDialog = true;

    this._leadRouteService.takeLead(this.model.leadRouteHistoryId)
      .subscribe({
        next: entityThatIsTransferred => {
        this._dialerService.openCallControlPanel(null,null, null, null, null, false, false, false, null);
        this.activeModal.close(entityThatIsTransferred);
          if (this.model.contactListId === 1) {
            this._router.navigate([`/admin/app-details/${entityThatIsTransferred.applicationId}`]);
          }
          else if (this.model.contactListId === 4) {
            this._drawerService.openDrawer({
              contactListType: ContactListType.Lead,
              recordId: entityThatIsTransferred.leadId
            });
          } if (this.model.contactListId === 8) {
            this._conversationService.smsChatRequested.emit({
              userName: entityThatIsTransferred.externalName,
              userPhone: entityThatIsTransferred.externalContactPhoneNumber,
              betweenPhone: entityThatIsTransferred.internalContactPhoneNumber,
              openChat: true,
              openDrawer: true
            });
          }
        }, error: err => {
          var errorMessage = err.error == "Lead has already been taken." && this.contactListName ? err.error : "Call was already answered.";

          this._notifyService.showError(errorMessage, "Error");
          this.activeModal.dismiss();
        }
      }).add(() => this.disabledDialog = false);
  }

  getTitle() {
    return this.contactListName
      ? `${this.contactListName} Available`
      : `Incoming call`;
  }

  getText() {
    return this.contactListName
      ? `New ${this.model.propertyState ? this.model.propertyState.toUpperCase() : ''} ${this.contactListName} Available`
      : `Incoming call ${this.model.propertyState ? 'from ' + this.model.propertyState.toUpperCase() : ''} - CALLER IS ON THE LINE`;
  }
}