<div class="modal-header">
  <h5 class="modal-title"> Revoke App Credentials </h5>
</div>
<div class="modal-body">
  <form #deleteAppPasswordsDialogForm="ngForm" ngbAutofocus class="row">
    <div class="col-md-12 form-group">
      <label for="app-name"> Please enter your password </label>
        <input type="password" id="app-name" name="app-name" class="form-control"
          [(ngModel)]="password" #passwordInput="ngModel" autocomplete="off" required
          [ngClass]="{'is-invalid' : passwordInput && passwordInput.touched && passwordInput.invalid}">
      <div class="invalid-feedback">Please enter password.</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()"> Close </button>
  <button type="button" class="btn btn-primary" (click)="revoke()"> Revoke </button>
</div>