<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Alias</label>
    </div>
    <div class="col-md-10">
      <input type="text" name="alias" class="form-control" [(ngModel)]="vendor.alias">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class='custom-control-label'>Email Override</label>
    </div>
    <div class='col-md-10'>
      <input type="text" name="email-override" class="form-control" [(ngModel)]="emailOverride">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
