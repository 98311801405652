<p-table *ngIf="!columnsLoading; else loading"
  #dialRecordsTable
  id="dialRecordsTable"
  name="dial-list-records-table-v2"
  dataKey="dialListRecordId"
  [columns]="selectedColumns"
  [value]="records"
  [paginator]="true"
  [rows]="pageSize"
  responsiveLayout="scroll"
  [tableStyle]="{'height.%': 100}"
  [autoLayout]="true"
  [showCurrentPageReport]="true"
  [globalFilterFields]="globalFilterFields"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  styleClass="p-datatable-gridlines p-datatable-sm"
  (onPage)="onPage($event)"
  [reorderableColumns]="true"
  stateStorage="local"
  stateKey="{{tableStateName}}"
  (onStateSave)="onStateSave($event)"
  (sortFunction)="sortBy($event)"
  [customSort]="true"
>
  <ng-template pTemplate="caption">
    <div class="d-flex justify-content-end">
      <span>
        <p-multiSelect
          [options]="columns"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          selectedItemsLabel="{0} columns selected"
          name="selectedColumns"
          ngDefaultControl
          defaultLabel="Choose Columns"
          (onPanelHide)="selectedColumnsChanged()"
        ></p-multiSelect>

        <span class="p-input-icon-right ms-2 h-100">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            class="form-control h-100"
            [ngModel]="searchText"
            (ngModelChange)="searchText = $event; customSearchDebounced(); dt.first=0;"
            placeholder="Search keyword"
          />
        </span>
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem" *ngIf="isRecordEditable"></th>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        pReorderableColumn
        [ngStyle]="getColumnStyles(col.field)"
      >
        {{ col.header }}
        <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-record>
    <tr id="record_{{record.dialListRecordId}}" #recordRows
    [ngClass]="{
    'strikeout': (record.needsToBeStruckOut && (!selectedRecordForCall || (selectedRecordForCall && record.dialListRecordId != selectedRecordForCall.dialListRecordId))),
    'selected-record': (selectedRecord && record.dialListRecordId == selectedRecord.dialListRecordId)  || (selectedRecordForCall && record.dialListRecordId === selectedRecordForCall.dialListRecordId)
      }"
    >
      <td class="text-center" *ngIf="isRecordEditable">
        <i class="fa fa-pencil-alt cursor text-info" [ngClass]="{'text-muted pe-none': dialListRefreshStatus}" title="Edit" (click)="!dialListRefreshStatus && onEditRecordClicked(record, false)"></i>
      </td>

      <td *ngFor="let col of selectedColumns">
        <span *ngIf="col.field == 'loanOfficer'" class="align-items-center" style="word-break: break-word;white-space: nowrap;">
          <span class="mb-0 text-sm">
            {{ record.loanOfficer }}
          </span>
        </span>
        <span *ngIf="col.field == 'lastName'" class="align-items-center" style="word-break: break-word;white-space: nowrap;">
            <a class="call-button border me-2 p-1 text-center"
              ><i
                class="fas fa-phone-alt"
                (click)="openCallPanel(record)"
              ></i
            ></a>
            <span>
              <strong>
                <span class="mb-0 text-sm"
                  >{{ record.firstName }} {{ record.lastName }}</span
                >
                <span style="color: red" class="mb-0 text-sm">{{
                  record.isFetched ? ' (Fetched Lead)' : ''
                }}</span>
              </strong>
            </span>
          </span>

        <span *ngIf="col.field.toLowerCase() == 'phone'" style="word-break: break-word;white-space: nowrap;">
            <a [ngClass]="{'selected-cell': selectedRecordForCall && record.dialListRecordId == selectedRecordForCall.dialListRecordId &&
            record.selectedPhoneType == 'mobile'}" *ngIf="record.mobilePhone || record[col.field]"
              (click)="openCallPanel(record, 'mobile')"><i class="fas fa-mobile-alt icon-mobile ms-1 p-1"></i>

              {{ (record.mobilePhone || record[col.field]) | phone }}
              <span *ngIf="selectedRecordForCall && record.dialListRecordId == selectedRecordForCall.dialListRecordId &&
              record.selectedPhoneType == 'mobile'">

                {{record.callStatus ? '(' + record.callStatus + ')' : ''}}

              </span>
            </a>
            <br *ngIf="record.mobilePhone || record[col.field]">
            <a [ngClass]="{'selected-cell': selectedRecordForCall && record.dialListRecordId == selectedRecordForCall.dialListRecordId &&
            record.selectedPhoneType == 'home'}" *ngIf="record.homePhone" (click)="openCallPanel(record, 'home')"><i
                class="fas fa-home icon-home p-1"></i>

              {{ record.homePhone | phone }}

              <span *ngIf="selectedRecordForCall && record.dialListRecordId == selectedRecordForCall.dialListRecordId &&
            record.selectedPhoneType == 'home'">
                {{record.callStatus ? '(' + record.callStatus + ')' : ''}}
              </span>
            </a>
        </span>

        <span *ngIf="col.field.toLowerCase() == 'email'">
          <a
            *ngIf="record.email"
            href="mailto:{{record.email}}"
            >
            {{ record.email }}
          </a>
        </span>

        <span *ngIf="col.field == 'lastMessageContent' && record.recordType">
          <div class="text-muted text-ellipsis"
            style="cursor: pointer; max-width: 100px !important;"
            title="{{record['lastMessageContentTitle'] ? record['lastMessageContentTitle'] : 'Edit Note'}}"
            container="body"
            placement="bottom"
            data-trigger="click"
            adaptivePosition="true"
            [outsideClick]="false"
            [popover]="notesTemplate"
            #pop="bs-popover"
            [innerHtml]="record.lastMessageContent ? record.lastMessageContent : '--'"
          >
          </div>
          <ng-template #notesTemplate>
            <edit-record-note [record]="record" (close)="pop.hide(); onSavedNote(record, $event)"></edit-record-note>
          </ng-template>
        </span>

        <span *ngIf="['interestRate', 'rate'].includes(col.field)">
          <span *ngIf="record.interestRate"
            >{{ record.interestRate | number }}%</span
          >
        </span>

        <span *ngIf="col.field =='dateInserted'">
          <p style="word-break: break-word;white-space: nowrap;">
              {{ record.dateInserted | date: 'short' }}
          </p>
        </span>

        <span *ngIf="col.field == 'lastAttemptedContact'">
          <p style="word-break: break-word;white-space: nowrap;">
            <i
              *ngIf="
                record.lastAttemptedContact &&
                record.lastAttemptedContact == record.lastSuccessfulContact
              "
              class="fa fa-check-circle text-success"
            ></i>
            {{ record.lastAttemptedContact | date: 'short' }}
          </p>
        </span>

        <span *ngIf="col.field == 'firstAttemptedContact'">
          <p style="word-break: break-word;white-space: nowrap;">
            <i
              *ngIf="
                record.firstAttemptedContact &&
                record.firstAttemptedContact == record.firstSuccessfulContact
              "
              class="fa fa-check-circle text-success"
            ></i>
            {{ record.firstAttemptedContact | date: 'short' }}
          </p>
        </span>

        <span *ngIf="col.field == 'currentDate'">
          <span class="text-cell-primary"><i *ngIf="record.lastSuccessfulContact"
            class="fa fa-check text-success me-2"></i>{{ getTimeSinceDate(record.lastSuccessfulContact) }}
          </span>
          <br *ngIf="record.lastSuccessfulContact" />
          <span class="text-muted" *ngIf="record.lastAttemptedContact !== record.lastSuccessfulContact">
            Last Attempted {{getTimeSinceDate(record.lastAttemptedContact)}} 
            </span>
        </span>

        <ng-container *ngIf="col.field == 'statusId'">
          <lead-status
            *ngIf="record.loanPurpose"
            [selectedRecord]="record"
            [currentDialOfRecord]="selectedDialList"
          >
          </lead-status>

          <em *ngIf="!record.loanPurpose">Missing Loan Purpose</em>
        </ng-container>

        <ng-container *ngIf="col.field == 'subStatus'">
          <application-substatus
            [selectedRecord]="record"
          >
          </application-substatus>
        </ng-container>

        <span *ngIf="!['lastName','email','phone','interestRate','rate', 'lastAttemptedContact', 'firstAttemptedContact', 'statusId', 'currentDate', 'subStatus'].includes(col.field)">
          <span *ngIf="['Text', 'String'].includes(col.dataType)">
            {{ record[col.field] }}
          </span>
          <span *ngIf="['Date', 'DateTime'].includes(col.dataType)">
            <p style="word-break: break-word;white-space: nowrap;margin: 0;">
              {{record[col.field] | date: 'short' }}
            </p>
          </span>
          <span *ngIf="col.dataType == 'Time'">
            <p style="word-break: break-word;white-space: nowrap;">
              {{record[col.field] | date: 'shortTime' }}
            </p>
          </span>
          <span *ngIf="col.dataType == 'Decimal'">
            <span *ngIf="col.fieldType == 'Currency'">
              {{record[col.field] | currency }}
            </span>
            <span *ngIf="col.fieldType == 'Rate'">
              {{record[col.field] | number }} %
            </span>
            <span *ngIf="['None', 'Email', 'MobilePhone', 'Phone'].includes(col.fieldType)">
              {{record[col.field] }}
            </span>
          </span>
          <span *ngIf="col.dataType == 'Integer'">
            {{record[col.field] | number }}
          </span>
          <span *ngIf="col.dataType == 'Boolean'">
            <input type="checkbox" [disabled]="true" [(ngModel)]="record[col.field]"/>
          </span>
          <span *ngIf="['Enum', 'EnumFlag', 'Array'].includes(col.dataType)">
            {{ record[col.field] }}
          </span>
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="loadingbody">
    <tr>
      <td style="width: 3rem"></td>
      <td *ngFor="let col of columns">
        <div class="loading-text"></div>
        <p-skeleton [ngStyle]="{ width: '100%' }"></p-skeleton>
      </td>
    </tr>
  </ng-template>
</p-table>
<ng-template #loading>
  <div class="w-100 text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
