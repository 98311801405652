import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { CampaignService } from '../../Services/campaign.service';

@Component({
  selector: 'app-content-generation-dialog',
  templateUrl: './content-generation-dialog.component.html',
  styleUrls: ['./content-generation-dialog.component.scss']
})
export class ContentGenerationDialogComponent implements OnInit {
  @Input()
  generationType: string;

  isLoading: boolean;
  promptText: string;
  suggestedText: string;
  selectedPrompt: string = "";
  prompts: any;

  protected suggestedEmail = { subject: '', body: '' };

  protected get isEmail(): boolean {
    return this.generationType.toLowerCase() === 'email';
  }

  constructor(
    private readonly _notifyService: NotificationService,
    private readonly _campaignService: CampaignService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.prompts = {
      'm-cashout': 'Write a marketing ' + this.generationType + ' for a mortgage company doing cash out refinances',
      'm-debt-consolidation': 'Write a marketing ' + this.generationType + ' for a mortgage company doing cash out refinances for debt consolidation purposes',
      'm-heloc': 'Write a marketing ' + this.generationType + ' for a mortgage company doing HELOC transactions',
      'm-purchase': 'Write a marketing ' + this.generationType + ' for a mortgage company doing purchase transactions',
      'm-custom': 'Write a marketing ' + this.generationType + ' for a mortgage company doing [place your transaction type and detail here]',
      't-new-inquiry': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that has just submitted a new inquiry',
      't-introduction': 'Write a transactional ' + this.generationType + ' for a mortgage company to introduce the loan officer on the transaction including direct phone and email',
      't-submit-processing': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that has just been submitted to processing',
      't-submit-uw': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that has just been submitted to underwriting',
      't-conditional': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that has just been conditionally approved',
      't-resubmit-uw': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that has just been resubmitted to underwriting',
      't-ctc': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that has just gone clear to close',
      't-custom': 'Write a transactional ' + this.generationType + ' for a mortgage company for a loan that [place your detail here]'
    };
  }

  promptChanged(value) {
    this.promptText = value == '' ? '' : this.prompts[value];
  }

  private parseEmail(text: string): { subject: string, body: string } {
    let subject: string = '';
    let body: string;

    const subjectMatch = text.match(
      /^(subject\s*line|subject)\s*:\s*(.*)\n?/i,
    );
    if (subjectMatch) {
      subject = subjectMatch[2];
      body = text.replace(subjectMatch[0], '').trimStart();
    } else {
      body = text;
    }

    return { subject, body };
  };

  generateText() {
    this.isLoading = true;
    this._campaignService.generateText(this.promptText)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (suggestedText: string) => {
          this.suggestedText = suggestedText;

          if (this.isEmail) {
            this.suggestedEmail = this.parseEmail(suggestedText.trim());
          }
        },
        error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to generate text', 'Error!');
        }
      })
  }

  protected onClickUseSuggestedButton(): void {
    const result = this.isEmail ? this.suggestedEmail : this.suggestedText;
    this.activeModal.close(result);
  }
}
