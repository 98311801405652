<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Property Information
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #docPrepLoanInfoForm="ngForm" id="docPrepPropertyInfoForm">
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="occupancy"> Occupancy </label>
          <select class="form-select" name="occupancy" id="occupancy" [(ngModel)]="docPrep.subjectPropertyWillBeType">
            <option [ngValue]="null">--Select One--</option>
            <option value="{{option.alternateValue}}" *ngFor="let option of occupancyOptions">{{option.name}}
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group">
          <label for="address1"> Property Address </label>
          <address-autocomplete-input
            #address='ngModel'
            (addressChange)='handleAddressChange($event)'
            [(ngModel)]='docPrep.subjectPropertyAddress'
            id='address1'
            name='address1'
            placeholder=''
          ></address-autocomplete-input>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-xs-6 col-sm-4 pe-0">
          <label for="city"> City </label>
          <input id="city" name="city" type="text" class="form-control custom-input-left-side"
            [(ngModel)]="docPrep.subjectPropertyCity" />
        </div>
        <div class="form-group col-xs-6 col-sm-2 px-0">
          <label for="state">State</label>
          <select id="state" name="state" class="form-select custom-input-left-side custom-input-right-side"
            [(ngModel)]="docPrep.subjectPropertyState">
            <option [ngValue]="null"></option>
            <option *ngFor="let state of states" [ngValue]="state.value">
              {{ state.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-xs-6 col-sm-3 px-0">
          <label for="zipCode">Zip Code</label>
          <zip-code-input class="custom-input-left-side custom-input-right-side"
            [(ngModel)]="docPrep.subjectPropertyZipCode" id="zipCode" name="zipCode"
            [required]="false" (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
          </zip-code-input>
        </div>
        <div class="form-group col-xs-6 col-sm-3 ps-0">
          <label for="county">County</label>
          <input id="county" name="county" type="text" class="form-control custom-input-right-side"
            [(ngModel)]="docPrep.subjectPropertyCounty" />
        </div>
      </div>
    </form>
  </div>
</div>
