import { MenuItemStatus } from "./enums/menu-item-status.enum";

export class MenuItemStatusChangedEvent {
  id: string;
  status: MenuItemStatus;

  constructor(id: string, status: MenuItemStatus) {
    this.id = id;
    this.status = status;
  }
}
