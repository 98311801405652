import { Component, OnInit } from '@angular/core';
import { TableColumn } from '../../../../../shared/models/tale-column.model';
import { DataService } from '../../../../../core/services/data.service';
import * as moment from 'moment';
import { IDateRange } from '../../../../../shared/components/date-range-filter/date-range-filter.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'customer-retention',
    templateUrl: './customer-retention.component.html',
    styleUrls: ['./customer-retention.component.scss']
})
export class CustomerRetentionComponent implements OnInit {
    globalFilterFields: string[] = ['name', 'email', 'phoneNumber', 'ssn', 'address', 'city', 'state', 'zipCode'];
    columns: TableColumn[] = [];
    alerts: any[] = [];
    stikkumEnrollmentStatus: string;

    constructor(
        private readonly _dataService: DataService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _notifsService: NotificationService
    ) { }

    ngOnInit(): void {
        this.columns = [
            { field: 'name', header: 'Name', visible: true },
            { field: 'email', header: 'Email', visible: true },
            { field: 'phoneNumber', header: 'Phone', visible: true },
            { field: 'ssn', header: 'SSN', visible: true },
            { field: 'address', header: 'Address', visible: true },
            { field: 'city', header: 'City', visible: true },
            { field: 'state', header: 'State', visible: true },
            { field: 'zipCode', header: 'Zip Code', visible: true },
        ];
        this.checkEnrollmentStatus();
    }

    private checkEnrollmentStatus(): void {
        this._spinner.show();
        this._dataService.post(`api/stikkum/client/enroll/status`, {}).subscribe(res => {
            this.stikkumEnrollmentStatus = res;
        }, error => {
            const errorMessage = (error && error.message) ? error.message : "An error occurred while checking enrollment status.";
            // this._notifsService.showError(errorMessage, "Error!")
        }).add(() => this._spinner.hide())
    }

    private fetchAll(startDate, endDate): void {
        this._spinner.show();
        this._dataService.get(`api/stikkum/client/alerts?dateFrom=${moment(startDate).format('YYYY-MM-DD')}&dateTo=${moment(endDate).format('YYYY-MM-DD')}`)
            .pipe(finalize(() => {
                this._spinner.hide();
            }))
            .subscribe({
                next: res => {
                    this.alerts = res;
                }
            });
    }

    dateRangeChanged(selectedDateRange: IDateRange): void {
        this.fetchAll(selectedDateRange.startDate, selectedDateRange.endDate);
    }

}
