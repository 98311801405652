import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, LoanStatus } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { UserRolesFilter } from 'src/app/models/pipeline/requests/user-roles-filter.model';
import { User } from 'src/app/models/user/user.model';
import { WebPreferences } from 'src/app/models/web-preferences.model';
import { BranchService } from 'src/app/modules/admin/company/services/branch.service';
import { BranchLoanOptions } from 'src/app/modules/app-details/models/branch-loan-options.model';
import { PipelineFilterComponent } from 'src/app/modules/pipeline/components/pipeline-filter/pipeline-filter.component';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { CommonService } from 'src/app/services/common.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PipelineService } from 'src/app/services/pipeline.service';

@Component({
  selector: 'tpo-pipeline',
  templateUrl: 'tpo-pipeline.component.html'
})

export class TpoPipelineComponent implements OnInit, AfterViewInit {

  @ViewChild(PipelineFilterComponent)
  pipelineFilterComponent: PipelineFilterComponent;

  loanStatusesThatCanBeUsedForFiltering: LoanStatus[] = null;
  loanPurposesThatCanBeUsedForFiltering: LoanPurpose[] = null;

  pipelineData: PipelineItem[];

  filter: UserRolesFilter;

  webPreferences: WebPreferences;

  constructor(private readonly _pipelineService: PipelineService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _branchService: BranchService,
    private readonly _router: Router,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _commonService: CommonService) {
    this.filter = new UserRolesFilter();
  }

  loanCount: number = 0
  upcomingClosingCount: number = 0
  lockExpiringCount: number = 0;
  totalLoanAmountUpcoming: number = 0;
  totalLoanAmountLockExpiring: number = 0;
  formattedTotalPipelineDollarValue: string;
  formattedtotalLoanAmountUpcoming: string;
  formattedtotalLoanAmountLockExpiring: string;
  loansUpcomingClosingStatsValue: string;
  loansLockExpiringStatsValue: string;

  onLoanDetailsClicked = (application: PipelineItem): void => {
    const url = this._router.createUrlTree(['/tpo/app-details/' + application.applicationId], {}).toString()
    this._router.navigateByUrl(url);
  }

  ngOnInit() {
    this._applicationContextService.context.subscribe(context => {
      const availableUsers = context.tpoUsersInCurrentCompany;
      const currentlyLoggedInTpoUser = availableUsers.find((user: User) => user.userCompanyGuid == context.currentlyLoggedInUser.userCompanyGuid);
      if (currentlyLoggedInTpoUser && currentlyLoggedInTpoUser.branchId) {
        const observer: Observer<BranchLoanOptions> = {
          next: (options: BranchLoanOptions): void => {
            this.setWhatLoanStatusesAndPurposesWeCanUseForFiltering(context, options);
            this.loadPipeline();
          },
          error: (err: any): void => {
            let errorMessage = "An error occurred while getting branch options.";
            if (err && err.message) {
              errorMessage = err.message;
            }
            this._notificationService.showError(errorMessage, "Error!");
          },
          complete: (): void => {
          }
        }
        this._spinner.show();
        this._commonService.getWebPreferences().subscribe(response => {
          this.webPreferences = response || new WebPreferences();
          this._branchService.getExternalBranchLoanOptions(currentlyLoggedInTpoUser.branchId).subscribe(observer).add(() => {
            this._spinner.hide();
          });
        }, (err) => {
          this._spinner.hide();
        });
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.pipelineFilterComponent) {
      this.pipelineFilterComponent.pipelineFilterChanged.subscribe(filter => {
        this.filter = filter;
        this.loadPipeline();
      })
    }
  }

  onPipelineFilterChanged = (filter: UserRolesFilter) => {
    this.filter = filter;
    this.loadPipeline();
  }

  private setWhatLoanStatusesAndPurposesWeCanUseForFiltering = (context: ApplicationContext, options: BranchLoanOptions) => {
    const loanStatusesThatCanBeUsedForFiltering: LoanStatus[] = [];
    if (options.allowedChannels) {
      const allStatuses = context.globalConfig.loanStatus;
      allStatuses.forEach(loanStatus => {
        if (loanStatus.enabledChannels) {
          const loanStatusChannels = loanStatus.enabledChannels.split(',').map(ls => ls.trim());
          const found = options.allowedChannels?.split(',').map(ac => ac.trim()).some(r => loanStatusChannels.includes(r));
          if (found) {
            loanStatusesThatCanBeUsedForFiltering.push(loanStatus);
          }
        }
      });
      if (loanStatusesThatCanBeUsedForFiltering.length) {
        this.loanStatusesThatCanBeUsedForFiltering = loanStatusesThatCanBeUsedForFiltering;
      }
    }

    if (options.allowedLoanPurposes) {
      const allowedLoanPurposeIds = options.allowedLoanPurposes.split(',').map(lp => lp.trim());
      const allLoanPurposes = context.globalConfig.loanPurpose;
      const allowedLoanPurposes = allLoanPurposes.filter(lp => allowedLoanPurposeIds.includes(lp.loanPurposeId.toString()));
      if (allowedLoanPurposes.length) {
        this.loanPurposesThatCanBeUsedForFiltering = allowedLoanPurposes;
      }
    }
  }

  private loadPipeline = () => {
    if (this.webPreferences?.pipelineReferences?.pipelineSelectedColumnsForTpo) {
      this.filter.columnsToReturn = this.webPreferences.pipelineReferences.pipelineSelectedColumns?.filter(psc => psc.dataType != 'keyDate' && psc.field).map(sc => Utils.lowerCaseFirstLetter(sc.field)); // backward compatibility
      const keyDateSelectedColumnFields = this.webPreferences.pipelineReferences.pipelineSelectedColumns?.filter(psc => psc.dataType == 'keyDate' && psc.field);
      this.filter.keyDatesConfigurationIdsToReturn = [];
      keyDateSelectedColumnFields?.forEach(sc => {
        if (sc.field) {
          const parts = sc.field.split('_');
          if (parts.length === 2) {
            this.filter.keyDatesConfigurationIdsToReturn.push(Number(parts[1]));
          }
        }
      });
    }

    this._spinner.show();
    this._pipelineService.getByFilter(this.filter).subscribe((data: PipelineItem[]) => {
      this.pipelineData = data;
      this._spinner.hide();
      this.calculateStatistics(this.pipelineData);
    }, err => {
      let errorMessage = "An error occurred while loading pipeline.";
      if (err && err.message) {
        errorMessage = err.message;
      }
      this._notificationService.showError(errorMessage, "Error!");
    });
  }

  private calculateStatistics(pipeline: PipelineItem[]) {
    this.loanCount = pipeline.length;
    this.upcomingClosingCount = 0
    this.lockExpiringCount = 0;
    var totalPipelineDollarValue: number = pipeline.reduce((prev, cur) => prev + cur.loanAmount, 0);
    this.formattedTotalPipelineDollarValue = "$" + (totalPipelineDollarValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    const msToDay = 86400000;

    for (const item of pipeline) {
      const { closingDate, lockExpirationDate, loanAmount } = item;
      const amount = isNaN(loanAmount) ? 0 : loanAmount;

      if (closingDate != null && new Date(closingDate).valueOf() <= Date.now() + 15 * msToDay) {
        this.totalLoanAmountUpcoming += amount;
        this.upcomingClosingCount++;
      }
      if (lockExpirationDate != null && new Date(lockExpirationDate).valueOf() <= Date.now() + 7 * msToDay) {
        this.totalLoanAmountLockExpiring += amount;
        this.lockExpiringCount++;
      }
    }

    this.formattedtotalLoanAmountUpcoming = "$" + (this.totalLoanAmountUpcoming).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    this.formattedtotalLoanAmountLockExpiring = "$" + (this.totalLoanAmountLockExpiring).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');;
  }
}
