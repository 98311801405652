import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LoanAmountCalculatorDialogComponent } from 'src/app/modules/loan-amount-calculator/loan-amount-calculator-dialog/loan-amount-calculator-dialog.component';
import { Constants } from 'src/app/services/constants';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { LeadUtilsService } from '../../../services/lead-utils.service';

@Component({
  selector: 'lead-loan-detail',
  templateUrl: './lead-loan-detail.component.html',
  styleUrls: ['./lead-loan-detail.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadLoanDetailComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  lead: Lead;

  @Input()
  isDrawer: boolean = false;

  @Input()
  isAppraisedValueHidden: boolean = false;

  totalLoanAmount: number = 0;
  ltv: number = 0;
  cltv: number = 0;

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _leadUtilsService: LeadUtilsService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onLoanAmountChanged();
  }

  protected onCalculatorClicked = () => {
    const modalRef = this._modalService.open(LoanAmountCalculatorDialogComponent, Constants.modalOptions.medium);
    const homeValue = this._leadUtilsService.calculateHomeValue(this.lead);
    modalRef.componentInstance.purchasePriceAmount = homeValue;
    modalRef.componentInstance.loanAmount = this.lead.loanAmount;

    modalRef.result.then((result) => {
      this.lead.loanAmount = result;
      this.onLoanAmountChanged();
    }, (err) => {
      console.log(err);
    });
  }

  protected isPurchaseTransaction = (loanPurposeId: number, defaultWhenUnset: boolean) => {
    return this._leadUtilsService.isPurchaseTransaction(loanPurposeId, defaultWhenUnset);
  }

  protected isRefiTransaction = (loanPurposeId: number, defaultWhenUnset: boolean) => {
    return this._leadUtilsService.isRefiTransaction(loanPurposeId, defaultWhenUnset);
  }

  protected onLoanAmountChanged = () => {
    const homeValue = this._leadUtilsService.calculateHomeValue(this.lead);
    const isPurchase = this.isPurchaseTransaction(this.lead.loanPurposeId, false);
    if (homeValue) {
      if (isPurchase) {
        this.lead.downPayment = (homeValue ?? 0) - (this.lead.loanAmount ?? 0);
      } else {
        this.lead.downPayment = 0;
      }
    } else if (this.lead.downPayment) {
      if (isPurchase) {
        this.lead.purchasePrice = this.lead.downPayment + (this.lead.loanAmount || 0);
      }
    }
    this.calculateLtvAndCltv();
  }

  protected onLtvValueChanged = () => {
    const homeValue = this._leadUtilsService.calculateHomeValue(this.lead);
    this.lead.loanAmount = this.ltv * homeValue;
  }

  protected onDownPaymentChanged = () => {
    const isPurchase = this.isPurchaseTransaction(this.lead.loanPurposeId, false);
    if (isPurchase) {
      const homeValue = this._leadUtilsService.calculateHomeValue(this.lead);
      this.lead.loanAmount = (homeValue || 0) - (this.lead.downPayment || 0);
    }
    this.calculateLtvAndCltv();
  }

  protected onPurchasePriceChanged = () => {
    this.onPurchasePriceOrAppraisedValueChanged();
    this.calculateLtvAndCltv();
  }

  protected onAppraisedValueChanged = () => {
    this.onPurchasePriceOrAppraisedValueChanged();
    this.calculateLtvAndCltv();
  }

  private calculateLtvAndCltv  = () => {
    let total = this.lead.loanAmount;
    this.totalLoanAmount = total;
    const ltvAndCltv = this._leadUtilsService.calculateLtvAndCltv(this.lead);
    this.ltv = ltvAndCltv.ltv;
    this.cltv = ltvAndCltv.cltv;
  }

  private onPurchasePriceOrAppraisedValueChanged = () => {
    if (this.lead.loanAmount > 0) {
      this.lead.downPayment = this.calculateDownPayment(this.lead);
    } else if (this.lead.downPayment > 0) {
      this.lead.loanAmount = this.calculateLoanAmount(this.lead);
    }
  }

  private calculateLoanAmount = (lead: Lead) => {
    const isRefi = this.isRefiTransaction(lead.loanPurposeId, false);
    if (isRefi) {
      return;
    }
    const homeValue = this._leadUtilsService.calculateHomeValue(lead);
    const loanAmount = (homeValue ?? 0) - (lead.downPayment ?? 0);
    return loanAmount;
  }

  private calculateDownPayment = (lead: Lead) => {
    const isRefi = this.isRefiTransaction(lead.loanPurposeId, false);
    if (isRefi) {
      return;
    }
    const homeValue = this._leadUtilsService.calculateHomeValue(lead);
    const downPayment = (homeValue ?? 0) - (lead.loanAmount ?? 0);
    return downPayment;
  }
}
