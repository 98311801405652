<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Fee Provider {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Fee Provider {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #lodestarProviderForm="ngForm" novalidate id="lodestarProviderForm">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Username </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="vendor.userName" #userNameInput="ngModel" name="userName"
          id="userName" [ngClass]="{'is-invalid': userNameInput && userNameInput.touched && userNameInput.invalid}"
          required>
        <div class="invalid-feedback"> This field is required </div>
      </div>
      <div class="col-md-1 text-end">
        <label class="custom-control-label"> Password </label>
      </div>
      <div class="col-md-5">
        <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName" required="true"></password-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Client Name </label>
      </div>
      <div class="col-md-10">
        <input type="text" class="form-control" [(ngModel)]="lodestarKeyValuePairs.clientName.value"
          #clientNameInput="ngModel" name="clientName" id="clientName"
          [ngClass]="{'is-invalid': clientNameInput && clientNameInput.touched && clientNameInput.invalid}" required>
      </div>
      <div class="invalid-feedback"> This field is required </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Document Type</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" name="savedDocumentTypeId"
          [(ngModel)]="lodestarKeyValuePairs.savedDocumentTypeId.value">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let docType of documentTypes" [ngValue]="docType.documentTypeId + ''">
            {{docType.documentTypeName}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4 offset-md-2">
        <div class="form-group">
          <div class="form-check form-check-inline">
            <input id="importSellerPaidFees" name="importSellerPaidFees" class="form-check-input" type="checkbox"
              [(ngModel)]="lodestarKeyValuePairs.importSellerPaidFees.value" ToggleValue [true]="'1'" [false]="'0'">
            <label class="form-check-label" for="importSellerPaidFees">
              Import Seller Paid Fees
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input id="autoSelectRequestPropertyTax" name="autoSelectRequestPropertyTax" class="form-check-input" type="checkbox"
              [(ngModel)]="lodestarKeyValuePairs.autoSelectRequestPropertyTax.value" ToggleValue [true]="'1'" [false]="'0'">
            <label class="form-check-label" for="autoSelectRequestPropertyTax">
              Auto-Select Request Property Tax
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
