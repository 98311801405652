import { Borrower, KeyDate, KeyDatesByType, UserPermissions, UserType } from '.';
import { TpoConfiguration } from '../modules/admin/tpo-config/models/tpo-configuration.model';
import { MenuItemStatus } from '../modules/tpo/models/enums/menu-item-status.enum';
import { UrlaMortgage } from '../modules/urla/models/urla-mortgage.model';
import { GlobalConfig } from './config/global-config.model';
import { CustomData } from './loan/custom-data.model';
import { LoanApplication } from './loan/loan-info.model';
import { MortgageCalculationDetails } from './mortgage-calculation-details.model';
import { Profile } from './profile.model';
import { EnumerationItem } from './simple-enum-item.model';
import { UserData } from './user/user-data.model';
import { User } from './user/user.model';

export class ApplicationContext {
  currentlyLoggedInUser: UserData;
  currentlyLoggedInUserProfile: Profile;
  application: LoanApplication;
  applicationIsReadOnly: boolean;
  customData: CustomData;
  currentMortgage: UrlaMortgage;
  currentMortgageCalculationDetails: MortgageCalculationDetails;
  applicationKeyDatesByType: KeyDatesByType;
  applicationKeyDates: KeyDate[] = [];
  tpoMenuStatuses: {[menuKey: string]: MenuItemStatus} | undefined;
  borrowers: Borrower[] = [];
  globalConfig: GlobalConfig;
  tpoConfig: TpoConfiguration;
  commonEnums: any;
  mortgageEnums: any;
  pricingEnums: any;
  userPermissions: UserPermissions;
  mergeFields: EnumerationItem[];

  isCallControlPanelOpen: boolean = false;
  isNiceInContactVisible: boolean = false;
  isLoanEditModeOpen: boolean = false;

  get isTpo(): boolean {
    return this.currentlyLoggedInUser.userType === UserType.Tpo;
  }

  get tpoUsersInCurrentCompany(): User[] {
    return this.globalConfig.tpoUsers.filter(user => user.companyId === this.userPermissions.companyId);
  }

  get isCompanyDeepHaven(): boolean {
    return [229, 276].includes(this.userPermissions.companyId);
  }

  get isCompanyPulseProcessing(): boolean {
    return [260].includes(this.userPermissions.companyId);
  }

  get isCompanyLodasoft(): boolean {
    return [2].includes(this.userPermissions.companyId);
  }

  get isCompanyLfhs(): boolean {
    return [128].includes(this.userPermissions.companyId);
  }

  get isCompanyPRMG(): boolean {
    return [222].includes(this.userPermissions.companyId);
  }

  get isCompanyOpenWholesale(): boolean {
    return [256].includes(this.userPermissions.companyId);
  }

  get isCompanyMortgegProcessing(): boolean {
    return [302].includes(this.userPermissions.companyId);
  }
}
