import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'process-adr-ocr-dialog',
    templateUrl: 'process-adr-ocr-dialog.component.html',
    styleUrls: ['process-adr-ocr-dialog.component.scss']
})

export class ProcessAdrOcrDialogComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) { }

    onCancelClicked = () => {
        this.activeModal.close('cancel');
    }

    ngOnInit() { }
}
