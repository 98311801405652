<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Loan Information
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #docPrepLoanInfoForm="ngForm" id="docPrepLoanInfoForm">
      <div class="row" *ngIf="docPrep">
        <div class="col-md-6 form-group">
          <label for="loanPurpose"> Loan Purpose </label>
          <select id="loanPurpose" name="loanPurpose" class="form-select"
            [(ngModel)]="docPrep.loanPurposeType"
            (change)="onLoanPurposeChanged()">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let lp of loanPurposeOptions" [ngValue]="lp.value">
              {{ lp.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group">
          <label for="loanTypes"> Loan Type </label>
          <select id="loanTypes" name="loanTypes" class="form-select" [(ngModel)]="docPrep.mortgageAppliedForType">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let lt of loanTypeOptions" [ngValue]="lt.value">
              {{ lt.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6 form-group">
          <label for="loanNumber"> Loan Number </label>
          <input class="form-control" id="loanNumber" name="loanNumber" type="text" [(ngModel)]="docPrep.loanNumber">
        </div>
        <div class="col-md-6 form-group">
          <label for="gfNumber"> GF Number </label>
          <input type="text" class="form-control" id="gfNumber" name="gfNumber" [(ngModel)]="docPrep.gfNumber">
        </div>
        <div class="col-md-6 form-group">
          <label for="loanAmount"> Loan Amount </label>
          <currency-input [name]="'loanAmount'" [isZeroValid]="false" [allowDecimals]="false"
            [(ngModel)]="docPrep.loanAmount">
          </currency-input>
        </div>
        <div class="col-md-6 form-group">
          <label for="closingDate"> Closing Date</label>
          <date-input name="closingDate" [id]="'closingDate'" [(ngModel)]="docPrep.closingDate"></date-input>
        </div>
      </div>
    </form>
  </div>
</div>
