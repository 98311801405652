import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingComponent, PricingHistoryTableComponent } from '.';
import { AmortizationComponent } from '../amortization/components/amortization.component';
import { ApplicationPricingScenariosComponent } from './components/application-pricing-scenarios/application-pricing-scenarios.component';
import { AtrQmManagementComponent } from '../app-details/components/atr-qm-management/components/atr-qm-management.component';

const routes: Routes = [
  {
    path: '',
    component: PricingComponent,
  },
  {
    path: 'pricing-history',
    component: PricingHistoryTableComponent
  },
  {
    path: 'amortization',
    component: AmortizationComponent
  },
  {
    path: 'atr-qm-management',
    component: AtrQmManagementComponent
  },
  {
    path: 'application-pricing-scenarios',
    component: ApplicationPricingScenariosComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PricingRoutingModule { }
