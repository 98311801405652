<ng-container *ngIf="!isDetailShowing">
  <div class="w-100 d-flex flex-column">
    <div
      class="card report-card bg-warning align-self-center m-4"
      (click)="selectCalculator(1)"
    >
      <div class="card-body row d-flex justify-content-center">
        <div class="col d-flex align-items-center">
          <p class="text-white mb-0 fw-semibold">Lower Payment</p>
        </div>
        <div class="col-auto align-self-center">
          <div class="report-main-icon bg-light-alt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-calculator feather feather-clock align-self-center text-muted icon-sm"
              viewBox="0 0 16 16"
            >
              <path
                d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
              />
              <path
                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card report-card bg-info align-self-center m-4"
      (click)="selectCalculator(2)"
    >
      <div class="card-body row d-flex justify-content-center">
        <div class="col d-flex align-items-center">
          <p class="text-white mb-0 fw-semibold">Debt Management</p>
        </div>
        <div class="col-auto align-self-center">
          <div class="report-main-icon bg-light-alt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-calculator feather feather-clock align-self-center text-muted icon-sm"
              viewBox="0 0 16 16"
            >
              <path
                d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
              />
              <path
                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card report-card bg-success align-self-center m-4"
      (click)="selectCalculator(3)"
    >
      <div class="card-body row d-flex justify-content-center">
        <div class="col d-flex align-items-center">
          <p class="text-white mb-0 fw-semibold">Cash Out</p>
        </div>
        <div class="col-auto align-self-center">
          <div class="report-main-icon bg-light-alt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-calculator feather feather-clock align-self-center text-muted icon-sm"
              viewBox="0 0 16 16"
            >
              <path
                d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
              />
              <path
                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card report-card bg-secondary align-self-center m-4"
      (click)="selectCalculator(4)"
    >
      <div class="card-body row d-flex justify-content-center">
        <div class="col d-flex align-items-center">
          <p class="text-white mb-0 fw-semibold">Reduce Term</p>
        </div>
        <div class="col-auto align-self-center">
          <div class="report-main-icon bg-light-alt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-calculator feather feather-clock align-self-center text-muted icon-sm"
              viewBox="0 0 16 16"
            >
              <path
                d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
              />
              <path
                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  

    <div class="card report-card bg-danger align-self-center m-4"
      *ngIf="!isDetailShowing && isRefinance" (click)="selectCalculator(5)">
      <div class="card-body row d-flex justify-content-center">
        <div class="col d-flex align-items-center">
          <p class="text-white mb-0 fw-semibold"> Refi Benefits </p>
        </div>
        <div class="col-auto align-self-center">
          <div class="report-main-icon bg-light-alt">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-calculator feather feather-clock align-self-center text-muted icon-sm" viewBox="0 0 16 16">
              <path
                d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
              <path
                d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isDetailShowing">
  <div class="button-group mb-2">
    <button
      type="button"
      id="buttonMenuInCalc"
      class="btn btn-outline-primary me-2"
      data-bs-container="body"
      data-bs-toggle="popover"
      placement="bottom"
      [popover]="templateMenu"
      data-trigger="click"
      [outsideClick]="true"
      [containerClass]="'templateMenu'"
    >
      <i class="ti-more-alt"></i>
    </button>
    <ng-template #templateMenu>
      <div class="popover-menu">
        <a class="dropdown-item" (click)="onSendAsHtmlInEmailClicked()"> Send Email </a>
        <!-- <a class="dropdown-item" id="subButtonMenuInCalculators" data-bs-container="body"
        data-bs-toggle="popover" placement="end" [popover]="templateSubMenu" data-trigger="click"
        [outsideClick]="false" [containerClass]="'templateSubMenuInCalculators'"
          >Send Email</a
        >
        <ng-template #templateSubMenu>
          <a class="dropdown-item" (click)="onSendAsHtmlInEmailClicked()"> Send As HTML </a>
          <a class="dropdown-item" (click)="onSendAsImageInEmailClicked()"> Send As Image </a>
          <a class="dropdown-item" (click)="onSendAsPdfInEmailClicked()"> Send As PDF </a>
        </ng-template>
        <a class="dropdown-item" (click)="onSendInTextClicked()">Send SMS</a> -->
      </div>
    </ng-template>

    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="goBack()"
      title="Go Back"
    >
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
  </div>

  <div class="calculators-container">
    <lower-payment-calculator
      *ngIf="selectedCalculatorType == 1"
      [mortgage]="mortgage"
      [currentLoanInterestRate]="currentLoanInterestRate"
      [currentLoanTerm]="currentLoanTerm"
      #lowerPayment
    ></lower-payment-calculator>
    <debt-management-calculator
      *ngIf="selectedCalculatorType == 2"
      [mortgage]="mortgage"
      #debtManagement
    ></debt-management-calculator>
    <cash-out-calculator
      *ngIf="selectedCalculatorType == 3"
      [mortgage]="mortgage"
      #cashOut
    ></cash-out-calculator>
    <reduce-term-calculator
      *ngIf="selectedCalculatorType == 4"
      [mortgage]="mortgage"
      #reduceTerm
    ></reduce-term-calculator>
    <refi-benefits-calculator 
      *ngIf="selectedCalculatorType == 5" 
      [mortgage]="mortgage"
      [currentLoanInterestRate]="currentLoanInterestRate" 
      [currentLoanTerm]="currentLoanTerm"
      #refiBenefits
    ></refi-benefits-calculator>
  </div>
</ng-container>

