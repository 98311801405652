import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashToCloseComponent } from './cash-to-close/cash-to-close.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { CalculateCreditsDialogComponent } from './calculate-credits-dialog/calculate-credits-dialog.component';


@NgModule({
  declarations: [
    CashToCloseComponent,
    CalculateCreditsDialogComponent,
  ],
  exports: [
    CashToCloseComponent,
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    SharedModule,
  ],
})
export class CashToCloseModule { }
