<div class="row">
  <div class="form-group col-xs-12 col-sm-3 col-lg-3">
    <label for="name">Hud#</label>
    <input
      id="hudNumber"
      type="text"
      name="hudNumber"
      class="form-control"
      [(ngModel)]="feeDefinition.hudNumber"
      #hudNumberInput="ngModel"
      [ngClass]="{
        'is-invalid':
          hudNumberInput && hudNumberInput.touched && hudNumberInput.invalid
      }"
      required
    />
    <div class="invalid-feedback">This field is required</div>
  </div>
  <div class="form-group col-xs-12 col-sm-3 col-lg-3">
    <label for="name">Sum in HUD#</label>
    <input
      id="sumInHudNumber"
      numeric
      [allowNegative]="false"
      name="sumInHudNumber"
      class="form-control"
      [(ngModel)]="feeDefinition.sumInHudNumber"
    />
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="name">Name</label>
    <input
      id="name"
      type="text"
      name="name"
      class="form-control"
      [(ngModel)]="feeDefinition.name"
      #nameInput="ngModel"
      [ngClass]="{
        'is-invalid': nameInput && nameInput.touched && nameInput.invalid
      }"
      required
    />
    <div class="invalid-feedback">This field is required</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="feeType">Fee Type</label>
    <select
      id="feeType"
      name="feeType"
      class="form-select"
      placeholder="feeType"
      [(ngModel)]="feeDefinition.feeType"
    >
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let type of feeTypes" [ngValue]="type.value">
        {{ type.name }}
      </option>
    </select>
  </div>
</div>
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="feePercentOf">Fee % Of</label>
    <select
      id="feePercentOf"
      name="feePercentOf"
      class="form-select"
      placeholder="feePercentOf"
      [ngModel]="feeDefinition.feePercentOf"
      (ngModelChange)="feeDefinition.feePercentOf = $event; onFeePercentOfChanged()"
    >
      <option [ngValue]="null">--Select One--</option>
      <option
        *ngFor="let feePercentOf of feePercentOfs"
        [ngValue]="feePercentOf.value"
      >
        {{ feePercentOf.name }}
      </option>
    </select>
  </div>
  <div
    class="form-group col-xs-12 col-sm-6 col-lg-3"
    *ngIf="feeDefinition.feePercentOf != null && feePercentOfs.length > 0"
  >
    <label for="totalFeePercent">Total Fee Percent</label>
    <percent-input
      [digitsInfo]="'1.3-3'"
      id="totalFeePercent"
      name="totalFeePercent"
      [ngModel]="totalFeePercent"
      (ngModelChange)="totalFeePercent = $event; onTotalFeePercentChanged()"
    ></percent-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="defaultPaidBy">Default Paid By</label>
    <select
      id="defaultPaidBy"
      name="defaultPaidBy"
      class="form-select"
      placeholder="defaultPaidBy"
      [ngModel]="feeDefinition.defaultPaidBy"
      (ngModelChange)="feeDefinition.defaultPaidBy = $event; onDefaultPaidByChanged()"
    >
      <option [ngValue]="null">--Select One--</option>
      <option
        *ngFor="let feeDefaultPaidBy of feeDefaultPaidBys"
        [ngValue]="feeDefaultPaidBy.value"
      >
        {{ feeDefaultPaidBy.name }}
      </option>
    </select>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="paidTo">Paid To</label>
    <select
      id="paidTo"
      name="paidTo"
      class="form-select"
      [(ngModel)]="feeDefinition.paidTo"
    >
      <option [ngValue]="null">--Select One--</option>
      <option
        *ngFor="let feePaidTo of feePaidTos"
        [ngValue]="feePaidTo.value"
      >
        {{ feePaidTo.name }}
      </option>
    </select>
  </div>
</div>
<div class="row">
  <div
    class="form-group col-xs-12 col-sm-12 col-lg-6"
    *ngIf="
      feeDefinition.feePercentOf == null ||
      (feeDefinition.feePercentOf != null && feePercentOfs.length === 0)
    "
  >
    <label for="feeDollar"> Bor/Sel/Len/3rd P.[$]</label>
    <div class="row">
      <currency-input
        id="borrowerFeeDollar"
        name="borrowerFeeDollar"
        class="col-3"
        [(ngModel)]="feeDefinition.borrowerFeeDollar"
        [readonly]="
          !feeDefinition.allowSplit && feeDefinition.defaultPaidBy != 'Borrower'
        "
      ></currency-input>
      <currency-input
        id="sellerFeeDollar"
        name="sellerFeeDollar"
        class="col-3"
        [(ngModel)]="feeDefinition.sellerFeeDollar"
        [readonly]="
          !feeDefinition.allowSplit && feeDefinition.defaultPaidBy != 'Seller'
        "
      ></currency-input>
      <currency-input
        id="lenderFeeDollar"
        name="lenderFeeDollar"
        class="col-3"
        [(ngModel)]="feeDefinition.lenderFeeDollar"
        [readonly]="
          !feeDefinition.allowSplit && feeDefinition.defaultPaidBy != 'Lender'
        "
      ></currency-input>
      <currency-input
        id="thirdPartyFeeDollar"
        name="thirdPartyFeeDollar"
        class="col-3"
        [(ngModel)]="feeDefinition.thirdPartyFeeDollar"
        [readonly]="
          !feeDefinition.allowSplit &&
          feeDefinition.defaultPaidBy != 'ThirdParty'
        "
      ></currency-input>
    </div>
  </div>
  <div
    class="form-group col-xs-12 col-sm-12 col-lg-6"
    *ngIf="feeDefinition.feePercentOf != null && feePercentOfs.length > 0"
  >
    <label for="feePercents"> Bor/Sel/Len/3rd P.[%]</label>
    <div class="row">
      <percent-input
        class="col-3"
        id="borrowerFeePercent"
        name="borrowerFeePercent"
        [ngModel]="borrowerFeePercent"
        (ngModelChange)="borrowerFeePercent = $event; percentChanged()"
        [readonly]="
          !feeDefinition.allowSplit && feeDefinition.defaultPaidBy != 'Borrower'
        "
        #borrowerFeePercentInput="ngModel"
        [ngClass]="{
          'is-invalid': feePercentAllocationsNotAddingUpTo100
        }"
      ></percent-input>

      <percent-input
        class="col-3"
        id="sellerFeePercent"
        name="sellerFeePercent"
        [ngModel]="sellerFeePercent"
        (ngModelChange)="sellerFeePercent = $event; percentChanged()"
        [readonly]="
          !feeDefinition.allowSplit && feeDefinition.defaultPaidBy != 'Seller'
        "
        #sellerFeePercentInput="ngModel"
        [ngClass]="{
          'is-invalid': feePercentAllocationsNotAddingUpTo100
        }"
      ></percent-input>

      <percent-input
        class="col-3"
        id="lenderFeePercent"
        name="lenderFeePercent"
        [ngModel]="lenderFeePercent"
        (ngModelChange)="lenderFeePercent = $event; percentChanged()"
        [readonly]="
          !feeDefinition.allowSplit && feeDefinition.defaultPaidBy != 'Lender'
        "
        #lenderFeePercentInput="ngModel"
        [ngClass]="{
          'is-invalid': feePercentAllocationsNotAddingUpTo100
        }"
      ></percent-input>

      <percent-input
        class="col-3"
        id="thirdPartyFeePercent"
        name="thirdPartyFeePercent"
        [ngModel]="thirdPartyFeePercent"
        (ngModelChange)="thirdPartyFeePercent = $event; percentChanged()"
        [readonly]="
          !feeDefinition.allowSplit &&
          feeDefinition.defaultPaidBy != 'ThirdParty'
        "
        #thirdPartyFeePercentInput="ngModel"
        [ngClass]="{
          'is-invalid': feePercentAllocationsNotAddingUpTo100
        }"
      ></percent-input>
      <div class="invalid-feedback">The percentages do not add up to 100</div>
    </div>
  </div>
</div>
<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="isAprFee"
      >Is Apr Fee?
      <input
        id="isAprFee"
        name="isAprFee"
        type="checkbox"
        class="ms-1 form-check-input"
        [(ngModel)]="feeDefinition.isAprFee"
      />
    </label>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="allowSplit"
      >Can Be Split?
      <input
        id="allowSplit"
        name="allowSplit"
        type="checkbox"
        class="ms-1 form-check-input"
        [(ngModel)]="feeDefinition.allowSplit"
        (ngModelChange)="onCanBeSplitChanged()"
      />
    </label>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="canSellerConcessionsApply"
      >Can Seller Concessions Apply?
      <input
        id="canSellerConcessionsApply"
        name="canSellerConcessionsApply"
        type="checkbox"
        class="ms-1 form-check-input"
        [(ngModel)]="feeDefinition.canSellerConcessionsApply"
      />
    </label>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3">
    <label for="canSetPaidTo"
      >Can Set Paid To?
      <input
        id="canSetPaidTo"
        name="canSetPaidTo"
        type="checkbox"
        class="ms-1 form-check-input"
        [(ngModel)]="feeDefinition.canSetPaidTo"
      />
    </label>
  </div>
</div>
