<div class="card border-top border-0 border-4 border-white">
  <div class="card-header">
    <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-line-chart me-2"></i> Pricing History
    </h4>
  </div>
  <div class="card-body">
    <loading-indicator *ngIf="isLoading" [loadingMessage]="'Loading pricing history...'"></loading-indicator>
    <p-table #dt1 *ngIf="!isLoading" [columns]="pricingHistoryColumns" columnResizeMode="fit" [value]="pricingHistory"
      [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll"
      [globalFilterFields]="['eventDate', 'eventAction', 'productName', 'rate', 'price', 'lockTerm', 'lockDate', 'lockExpirationDate']"
      [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" styleClass="p-datatable-gridlines p-datatable-sm"
      sortMode="single" [selectionPageOnly]="true">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-right p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control" style="height:33.5px!important" (ngModelChange)="dt1.first=0"
              (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th [class]="col.cssClass" *ngFor="let col of pricingHistoryColumns | filter: {visible: true}"
            [pSortableColumn]="col.field" [pSortableColumnDisabled]="!col.sortable">
            {{ col.header }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-history>
        <tr>
          <td> {{ history.eventDate | date: 'MMM d, y h:mm:ss a' }}</td>
          <td> {{ history.eventAction }}</td>
          <td> {{ history.productName }}</td>
          <td> {{ ((history.rate / 100 ) || 0) | percent:'1.3-3' }}</td>
          <td> {{ history.price }}</td>
          <td> {{ history.lockTerm }}</td>
          <td> {{ history.lockDate }}</td>
          <td> {{ history.lockExpirationDate }}</td>
          <td>
            <button class="btn btn-link text-decoration-none"
              (click)="onViewAdjustmentsClicked(history.pricingEventId)">
              View Adjustments
            </button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="9" class="text-center" style="height:200px"> History data not found. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>