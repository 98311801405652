<legend class="px-2 my-2 card-title fw-bold d-flex bg-legend">
    <span [ngClass]="{'handle': !deleting}"> ::: </span>
    {{keyDateGroup.keyDateGroupName}}
    <span class="ps-2">
        <div class="d-flex flex-row">
            <div class="me-1">
                <i class="far fa-edit cursor font-12 text-primary" (click)="keyDateGroupEditClicked()"
                    title="Edit key date group"></i>
            </div>
            <div class="me-1">
                <i *ngIf="!deleting" class="fa fa-trash-alt cursor font-12 text-danger" (click)="openDeleteConfirmDialog()"
                    title="Delete key date group"></i>
                <span *ngIf="deleting" class="spinner-border spinner-border-sm font-10 text-danger" role="status"></span>
            </div>
        </div>
    </span>
</legend>
<div id="group_{{keyDateGroup.keyDateGroupId}}" class="row key-date__group--body" [sortablejs]="keyDateGroup.keyDateConfigurations" *ngIf="loaded"
    [sortablejsOptions]="keyDateSortableOptions">
    <ng-container *ngFor="let keyDate of keyDateGroup.keyDateConfigurations">
        <key-date-box
            [keyDate]="keyDate"
            [keyDateGroup]="keyDateGroup"
            [keyDateTypes]="keyDateTypes"
            [companyId]="companyId"
            [baseMessageTitle]="baseMessageTitle"
            (onEdit)="keyDateEditClicked($event)"
            (keyDateDeleted)="onKeyDateDeletedFromGroup($event)">
        </key-date-box>
    </ng-container>
</div>
