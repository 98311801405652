<div class="d-flex">
  <div>
    <label for="escrowAnnually">Annually</label>
    <currency-input
      [(ngModel)]="annuallyEscrow"
      name="escrowAnnually"
      (blur)="calculateAnnualToMonth()"
      [readonly]="false"
      [disabled]="false"
      [required]="false"
    ></currency-input>
  </div>

  <div class="ms-2">
    <label for="escrowMonthly">Monthly</label>
    <currency-input
      name="escrowMonthly"
      [ngModel]="calculatedMonthlyEscrow"
      [readonly]="true"
      [disabled]="true"
    ></currency-input>
  </div>

  <div class="d-flex" style="margin-top: 1.5em;">
    <button title="Save" type="button" class="btn p-0 border-0 bg-transparent ms-2" (click)="onSave()">
      <i class="fa fa-check-circle text-success"></i>
    </button>
    <button title="Cancel" type="button" class="btn p-0 border-0 bg-transparent ms-2" (click)="onClose()">
      <i class="fa fa-times-circle text-info"></i>
    </button>
  </div>
</div>