<loading-indicator *ngIf="isLoading"></loading-indicator>
<div *ngIf="!isLoading" class="card">
    <div class="card-header d-flex align-items-center justify-content-center">
        <h4 class="card-title flex-grow-1">User Lead Routing</h4>
        <button *ngIf="leadRoutingTreeViewData.length > 0" class="btn btn-primary ms-1" (click)="save()">
            Save
        </button>
    </div>
    <div class="card-body">
        <p-tree [value]="leadRoutingTreeViewData" [draggableNodes]="false" [droppableNodes]="false" class="w-100 h-100">
            <ng-template let-node pTemplate="default">
                <div *ngIf="node.key.startsWith('grp_') || node.key.startsWith('route_')">
                    {{ node.label }}
                </div>
                <div *ngIf="node.key.startsWith('user_')" class="text-center position-relative">
                    <label class="throttle-label">Throttle (one every...)</label>
                    <div class="d-flex align-items-center justify-content-center">
                        <label class="no-wrap-username fw-bold text-dark">{{ node.label }}</label>
                        <input type="number" class="form-control mx-2" [(ngModel)]="node.data.throttleInterval" />
                        <select type="text" class="form-select" [(ngModel)]="node.data.throttlePeriod">
                            <option value="">-- Select One --</option>
                            <option value="Seconds">Seconds</option>
                            <option value="Minutes">Minutes</option>
                            <option value="Hours">Hours</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="node.key.startsWith('setting_')">
                    <div class="d-flex align-items-center justify-content-center">
                        <input id="{{node.key}}" type="checkbox" class="custom-control-input"
                            [(ngModel)]="node.data.addToRoute" />
                        <label for="{{node.key}}" class="ms-2 fw-bold text-dark">{{ node.label }}</label>
                        <div class="text-center me-2">
                            <label>Lead Cap</label>
                            <input type="number" [disabled]="!node.data.addToRoute" class="form-control mx-2"
                                [(ngModel)]="node.data.leadCap" />
                        </div>
                        <div class="text-center me-2">
                            <label>Weight</label>
                            <input type="number" [disabled]="!node.data.addToRoute" class="form-control mx-2"
                                [(ngModel)]="node.data.weight" />
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="empty">
                <div class="m-5 text-center">
                    No Data
                </div>
            </ng-template>
        </p-tree>
    </div>
</div>