<form #feesEditorForm="ngForm" novalidate id="feesEditorForm" name="feesEditorForm">
  <real-estate-commission
    #realEstateCommissions
    [fees]="realEstateFees"
    [feeSystemDetails]="feeSystemDetails"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onFeeAdded)="addFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onFeeRemoved)="removeFee($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </real-estate-commission>

  <origination-charges
    #originationCharges
    [fees]="originationFees"
    [feeSystemDetails]="feeSystemDetails"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onFeeAdded)="addFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onFeeRemoved)="removeFee($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </origination-charges>

  <services-borrower-not-shopped-for
    #servicesBorrowerNotShoppedFor
    [fees]="servicesNoShopFees"
    [feeSystemDetails]="feeSystemDetails"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onFeeAdded)="addFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onFeeRemoved)="removeFee($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </services-borrower-not-shopped-for>

  <services-borrower-can-shop-for
    #servicesBorrowerCanShopFor
    [fees]="servicesFees"
    [feeSystemDetails]="feeSystemDetails"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onFeeAdded)="addFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onFeeRemoved)="removeFee($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </services-borrower-can-shop-for>

  <taxes-other-government-fees
    #taxesAndGovernmentFees
    [fees]="governmentTaxesAndFees"
    [feeSystemDetails]="feeSystemDetails"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onFeeAdded)="addFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onFeeRemoved)="removeFee($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </taxes-other-government-fees>

  <prepaid-fees
    #prepaidFees
    [fees]="prepaidsFees"
    [feeSystemDetails]="feeSystemDetails"
    (onFeeAdded)="addFee($event)"
    (onFeeRemoved)="removeFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </prepaid-fees>

  <escrow-fees
    #escrowFeesComp
    [fees]="escrowFees"
    [feeSystemDetails]="feeSystemDetails"
    (onFeeAdded)="addFee($event)"
    (onFeeRemoved)="removeFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onValidationChanged)="changeValidationStatus($event)"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (escrowScheduleOpenRequested)="onEscrowScheduleOpenRequested()">
  </escrow-fees>

  <other-fees
    #otherFeesComp
    [fees]="otherFees"
    [feeSystemDetails]="feeSystemDetails"
    (openFeeEditor)="onFeeEditorOpened($event)"
    (onFeeAdded)="addFee($event)"
    (onFeeValueUpdated)="updateFeeValue($event)"
    (onFeeRemoved)="removeFee($event)"
    (onValidationChanged)="changeValidationStatus($event)">
  </other-fees>
  <br />
</form>
