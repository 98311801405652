<div class="modal-header">
  <h5 class="modal-title w-100">Attempted Contact</h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">

  <form>
    <div class="form-check mb-4">
      <input
        name="wasSuccess"
        class="form-check-input"
        type="checkbox"
        placeholder="To"
        [(ngModel)]="attemptedContact.wasSuccess"
      />
      <label for="wasSuccess" class="form-check-label"> Successful </label>
    </div>

    <div class="form-group">
      <label for="notes">Notes</label>
      <textarea
        name="notes"
        rows="5"
        class="form-control"
        [(ngModel)]="attemptedContact.notes"
      ></textarea>
    </div>
  </form>

</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary "
    [disabled]="isSaving"
    (click)="save()"
  >
    <span *ngIf="!isSaving">Save</span>
    <span *ngIf="isSaving">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving
    </span>
  </button>
</div>
