<div class="modal-header">
  <h2 class="modal-title">Add Dial List</h2>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body" *ngIf="!isLoading; else loading">
  <form #dialListForm="ngForm" novalidate class="row" >
    <div class="mb-3">
      <label class="control-label">Name</label>
      <div class="input-group">
        <input
          class="form-control"
          name="name"
          #name="ngModel"
          [(ngModel)]="data.name"
          [ngClass]="{'is-invalid' : name && name.touched && name.invalid}"
          required
        />
        <div class="invalid-feedback">
          Name is required.
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label class="control-label">Contact List</label>
      <div class="input-group">
        <select
          class="form-select"
          name="contactListId"
          #contactListId="ngModel"
          [(ngModel)]="data.contactListId"
          [ngClass]="{'is-invalid' : contactListId && contactListId.touched && contactListId.invalid}"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let list of contactLists" [ngValue]="list.contactListId">
            {{ list.name }}
          </option>
        </select>
        <div class="invalid-feedback">Contact list is required</div>
      </div>
    </div>

    <div class="mb-3">
      <label class="control-label">Description</label>
      <div class="input-group">
        <textarea
          [(ngModel)]="data.description"
          [ngClass]="{'is-invalid' : description && description.touched && description.invalid}"
          name="description"
          #description="ngModel"
          class="form-control"
          rows="3"
          required
        >
        </textarea>
        <div class="invalid-feedback">
          Description is required
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="!isLoading">
  <div class="button-items">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="save()"
      [disabled]="dialListForm?.invalid"
    >
      <span *ngIf="!isSaving">Save</span>
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="isSaving"
      ></span>
    </button>
  </div>
</div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading..."></loading-indicator>
</ng-template>
