import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'delete-with-replacement-confirmation-dialog',
  templateUrl: './delete-with-replacement-confirmation-dialog.component.html',
  styleUrls: ['./delete-with-replacement-confirmation-dialog.component.scss'],
})
export class DeleteWithReplacementConfirmationDialogComponent {
  @Input() ItemsList: Array<EnumerationItem> = [];
  @Input() itemType: 'Role' | 'Agent Type' | 'Lender' | 'Agent Tag' | 'Document Type';

  replacementId = 0;
  showItemListDropDown = false;

  constructor(public activeModal: NgbActiveModal) {}

  confirmDelete(): void {
    this.activeModal.close(this.replacementId);
  }
}
