<loading-indicator *ngIf="isLoading" [loadingMessage]="'Loading Change of Circumstance History...'"></loading-indicator>

<div class="card" *ngIf="!isLoading">
  <table class="table mb-0 table-centered">
    <thead>
    <tr>
      <th>Description</th>
      <th>Status</th>
      <th>Request Date</th>
      <th>Decision By</th>
      <th>Decision Date</th>
      <th>Detail</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let history of histories; let i = index;">
      <td>
        {{history.description}}
      </td>
      <td>
        {{history.taskStatus | splitCamelcase}}
      </td>
      <td>
        {{history.requestDate | date:'shortDate'}}
      </td>
      <td>
        {{history.updatedName}}
      </td>
      <td style="text-align: center;">
        {{history.dateInserted === history.dateUpdated ? "-" : history.dateUpdated | date:'shortDate' }}
      </td>
      <td>
        <a href="javascript:void(0);" (click)="openTaskInformationModal(history)">View Details</a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
