export interface DuHistoryDocument {
  readonly duHistoryDocumentId: number;
  readonly duHistoryId: number;
  readonly fileName: string;
  readonly mimeType: string;
  readonly data: Array<number>; // byte

  readonly companyId: number;
  readonly insertedBy: string;
  readonly dateInserted: Date;
  readonly updatedBy: string;
  readonly dateUpdated: Date;
}

export class DuHistory {
  readonly duHistoryId: number;
  readonly applicationId: number;
  readonly integrationHistoryId: number;
  readonly vendorFileId: string;
  readonly isSuccessful?: boolean;
  readonly status: string;
  readonly recommendation: string;
  isEligible?: boolean;
  readonly propertyInspectionWaiver?: boolean;
  readonly assetsVerified?: boolean;
  readonly employmentVerified?: boolean;
  readonly incomeVerified?: boolean;
  readonly ausSubmissionDate?: string;
  readonly documents: Array<DuHistoryDocument>;
  readonly dateInserted: string;
  readonly requestData?: any;
  readonly submittingInstitutionId: string;
  readonly brokerLenderId: number;
  readonly wasImported: boolean;
  readonly submissionStatus: string;
}

export class DoHistory extends DuHistory { };
export type DuHistoryArray = Array<DuHistory>;
