import { CreditVendorEnum } from "src/app/models";

export class LpaCreditRequest {
  creditBureau?: string = '';
  creditVendor?: CreditVendorEnum = null;
  technicalAffiliateCode?: string;
  textReportOnly?: boolean;
  runCreditWithoutUnderwriting?: boolean;
  jointBorrowerCreditRequests: LpaCreditAndBorrowerRequest[] = [];


  technicalAffiliateName?: string; //not used by api

}

export class LpaCreditAndBorrowerRequest {
  creditRequestType: string;
  borrowerIds: number[];
  creditReferenceNumber: string;
}
