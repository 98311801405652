import { ExpressionGroup } from "src/app/models/expressions/expression-group.model";

export class DialList {
  dialListId: number;
  name: string;
  description: string;
  userId: string;
  recordCount: number;
  includeExpressionGroupId: number;
  includeExpressionGroup: ExpressionGroup;
  excludeExpressionGroupId: number;
  excludeExpressionGroup: ExpressionGroup;
  contactListId: number = null;
  autoRefreshInterval: number;
  accessLevel: AccessLevel;
  permittedUserIds: string;
  permittedRoleIds: string;
  permittedBranchIds: string;
  order: number;
  ignoreUserLoanAccess:boolean;
  adminManaged: boolean;
  active: boolean = true;
  systemUseType: SystemUseType;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum AccessLevel {
  Public = "Public",
  AdminOnly = "AdminOnly",
  UserAccessList = "UserAccessList",
  RoleAccessList = "RoleAccessList",
  BranchAccessList = "BranchAccessList"
}

export enum SystemUseType {
  WarmTransfer = "WarmTransfer",
  ManualDial = "ManualDial",
  LeadFetch = "LeadFetch"
}
