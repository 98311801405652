<section>
  <div class="col-md-12 d-flex align-content-end flex-wrap">
    <div class="mt-4 me-2" *ngIf="formCredential">
      <credential-item [credential]="formCredential" [credentials]="[]"
        (openCredentialModal)="openCredentialModal()"
        (onCredentialSucessfullyDeleted)="onCredentialDeleted()">
      </credential-item>
    </div>
    <div class="mt-4 me-2" *ngIf="!formCredential">
      <create-credential
        [credentialType]="'LicenseValidation'"
        [credentials]="[]"
        (openCredentialModal)="openCredentialModal(true)">
      </create-credential>
    </div>
  </div>
</section>
