import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, fromEvent, Subscription } from 'rxjs';
import { DrawerUtils } from '../utils/drawer-utils';
import { debounceTime } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class FullSizeScrollableTableComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * If set to true, the p-table table height in the open drawer is
   * automatically set and {@link scrollOffset} is ignored.
   */
  @Input() autoAdaptHeight: boolean = false;

  scrollHeight: string;
  scrollOffset: number = 255;

  private _resizeSubscription: Subscription;

  protected constructor() {
  }

  ngOnInit() {
    const resetScrollHeight = this.resetScrollHeight.bind(this);

    this._resizeSubscription = fromEvent(window, 'resize').pipe(
      debounceTime(300),
    ).subscribe(resetScrollHeight);
  }

  ngOnDestroy() {
    this._resizeSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.resetScrollHeight();
    })
  }

  /**
   * @deprecated Now the component automatically sets the scroll height in
   * ngAfterViewInit. This function does not need to be called manually.
   */
  getScreenSize(): void {
    this.resetScrollHeight();
  }

  private async resetScrollHeight() {
    if (this.autoAdaptHeight) {
      await firstValueFrom(DrawerUtils.drawerIsReady$());

      if (this.tryAutoAdaptHeight()) {
        return;
      }
    }

    this.scrollHeight = window.innerHeight - this.scrollOffset + 'px';
  }

  private tryAutoAdaptHeight() {
    const scrollHeight = calculateAutoScrollHeight();
    if (scrollHeight == null) {
      return false;
    }

    this.scrollHeight = scrollHeight + 'px';
    return true;
  }
}


function getRowHeight(body: HTMLElement): number | undefined {
  const row = body
    .querySelector('tbody tr') as HTMLTableRowElement | undefined;

  return row?.offsetHeight;
}

function calculateRemainingHeight(body: HTMLElement): number | undefined {
  const getTableHeight = (fallbackTable: HTMLTableElement): number => {
    const pTable = body
      .querySelector('.p-datatable-wrapper') as HTMLElement | undefined;
    if (pTable == null) {
      console.error(
        'Cannot find .p-datatable-wrapper, will try to use table as fallback',
      );
    }

    return (pTable ?? fallbackTable).offsetHeight;
  };

  const table = body.querySelector('table');
  if (table == null) {
    console.error('Cannot find table');
    return null;
  }
  const tableNonBodyChildren =
    table.querySelectorAll(':scope > :not(tbody)');
  const tableNonBodyHeight = Array.from(tableNonBodyChildren)
    .reduce((acc, el: HTMLElement) => acc + el.offsetHeight, 0);
  const tableBodyHeight = getTableHeight(table) - tableNonBodyHeight;

  return Array.from(body.children)
    .reduce((acc, el: HTMLElement) => acc + el.offsetHeight, 0)
    - tableBodyHeight;
}

function calculateAutoScrollHeight(): number | undefined {
  const drawer = DrawerUtils.getOpenedDrawer();
  const body = drawer?.firstElementChild as HTMLElement | undefined;
  if (body == null) {
    return null;
  }

  // FIXME: This call is a side effect here, is that possible to move it to
  //  ngAfterViewInit?
  DrawerUtils.removeEmptyChildren(body);

  const bodyHeight = DrawerUtils.getHeight(body);
  const remainingHeight = calculateRemainingHeight(body);
  if (remainingHeight == null) {
    return null;
  }

  const scrollHeight = bodyHeight - remainingHeight;

  // Check the calculated value
  if (scrollHeight <= 0 || scrollHeight < getRowHeight(body)) {
    console.error('Scroll height cannot be calculated correctly');
    return undefined;
  }

  return scrollHeight;
}
