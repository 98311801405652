<div class='col-md-12 mb-0'>
  <div class='row my-1'>
    <div class='col-md-8'>
      <div class='d-flex'>
        <a
          [href]='filepath'
          [download]='filename'
          [attr.disabled]='dwld.busy'
          title='download {{filename}}'
          target='_blank'
          #dwld='file-download'
          class='btn btn-sm btn-soft-primary me-1'>
          Download Base CSS File
          <i class='fas fa-download font-15'></i>
        </a>
        <a class='btn btn-sm btn-soft-primary me-1' (click)='onViewYourCssFileClicked()'>
          View Your CSS File
          <i class='fas fa-eye font-15'></i>
        </a>
        <a class='btn btn-sm btn-soft-primary me-1' (click)='onUploadYourCssFileClicked()'>
          Upload Your CSS File
          <i class='fas fa-upload font-15'></i>
        </a>
        <a class='btn btn-sm btn-soft-danger' (click)='onResetToDefaultClicked()'>
          Reset to Default
          <i class='fas fa-upload font-15'></i>
        </a>
        <input #fileInput type='file' name='cssOverrideFile'
               style='display:none' (change)='onCssFileChanged($event)' />
      </div>
    </div>
  </div>
</div>
