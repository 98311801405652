<div class="modal-header">
  <h5 class="modal-title"> {{title == null ? '' : title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
      <h3 class="col-md-12 text-center"> {{message}} </h3>
  </div>
  <div class="row">
    <file-upload [(ngModel)]="uploadedFiles" multiple="multiple" name="fileUpload" [accept]="accept"></file-upload>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="button-items" style="float: right;">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">Cancel</button>
    <button type="button" class="btn btn-primary" 
      [disabled]="!uploadedFiles || (uploadedFiles && uploadedFiles.length === 0)"
      (click)="activeModal.close(uploadedFiles)">{{actionButtonText}}
    </button>
  </div>
</div>