<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" *ngIf="!syncingDoc">
    <div class="row mb-4" *ngIf="type">
        <div class="col-md-6">
            <div class="btn-toolbar" role="toolbar">
                <div class="btn-group">
                    <ng-container *ngFor="let docType of docFilterTypes">
                        <button
                            *ngIf="docType.type === 'both' || docType.type === type"
                            type="button"
                            class="btn btn-sm btn-outline-secondary waves-light waves-effect"
                            [ngClass]="{'current': docFilter === docType.value}"
                            (click)="switchFilter(docType.value)"
                        >
                            {{docType.name}}
                        </button>
                    </ng-container>
                    <div *ngIf="docFilter === 'PushToLos' || docFilter === 'PullFromLos'"
                        class="form-check checkbox-primary">
                        <input *ngIf="docFilter === 'PushToLos' || docFilter === 'PullFromLos'"
                            class="align-sub pointer" type="checkbox" (click)="onSelectAllByDocFilterClicked()"
                            name="selectAll" id="selectAll" />
                        <label for="selectAll" class="align-text-top ms-1 pointer"> Select All </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 text-end">
            <button *ngIf="type === 'los'" type="button" class="btn btn-primary btn-sm waves-effect waves-light"
                (click)="loadData('loda')">
                Show Lodasoft Documents </button>
            <button *ngIf="type === 'loda'" type="button" class="btn btn-primary btn-sm waves-effect waves-light"
                (click)="loadData('los')">
                Show LOS Documents </button>
        </div>
    </div>
    <div class="accordion" id="accordionSync">
        <ng-template [ngIf]="docFilter !== 'All'" [ngIfElse]="allContent">
            <ng-container *ngFor="let doc of docSyncList | filter: docFilter">
                <sync-loda-los-docs-accordion
                    [doc]="doc"
                    [loanDoc]="doc.loanDocs"
                    [docFilter]="docFilter"
                    [docSyncList]="docSyncList"
                    [isClickedSelectAll]="isClickedSelectAll"
                    [docTypes]="docTypes"
                    [companyId]="companyId"
                    [losVendor]="losVendor"
                ></sync-loda-los-docs-accordion>
            </ng-container>
            <div *ngIf="(docSyncList | filter: docFilter).length === 0">
                <h5 class="text-center"> There are no files to be synchronized. </h5>
            </div>
        </ng-template>
        <ng-template #allContent>
            <ng-container *ngFor="let doc of docSyncList">
                <sync-loda-los-docs-accordion
                    [doc]="doc"
                    [loanDoc]="doc.loanDocs"
                    [docFilter]="docFilter"
                    [docSyncList]="docSyncList"
                    [isClickedSelectAll]="isClickedSelectAll"
                    [docTypes]="docTypes"
                    [companyId]="companyId"
                    [losVendor]="losVendor"
                ></sync-loda-los-docs-accordion>
            </ng-container>
            <div *ngIf="docSyncList.length === 0">
                <h5 class="text-center"> There are no files to be synchronized. </h5>
            </div>
        </ng-template>
    </div>
</div>
<div *ngIf="syncingDoc" style="width: 95%; font-size: large;" class="ms-4 my-4">
    <div class="mb-2">
        <span> Syncing {{numberOfDocumentsToSync}} document(s) with LOS, please wait... </span>
    </div>
    <div class="progress" style="height: 20px">
        <div class="progress-bar progress-bar-striped" role="progressbar"
            [ngStyle]="{'width': getProgressValue(countUp) + '%'}" aria-valuenow="{{getProgressValue(countUp)}}"
            aria-valuemin="0" aria-valuemax="100"> <span style="font-size: larger;"> {{ getProgressValue(countUp) }} %
            </span>
        </div>
    </div>
    <div class="mt-2">
        <span> {{countUp + '/' + numberOfDocumentsToSync}} </span>
    </div>
</div>
<div class=" modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Close </button>
    <button type="button" [disabled]="!isAnyFileSelected()" *ngIf="!syncingDoc" class="btn btn-primary right"
        (click)="onSyncDocClicked()"> Sync Documents </button>
</div>