import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TableCheckbox, TableHeaderCheckbox } from 'primeng/table';
import { DocumentType, LoanDoc } from 'src/app/models';

@Component({
    selector: 'copy-application-accordion',
    templateUrl: 'copy-application-accordion.component.html',
    styleUrls: ['copy-application-accordion.component.scss']
})

export class CopyApplicationAccordionComponent implements OnInit, OnChanges {

    @ViewChildren("rowCheckBox")
    rowCheckBoxes: QueryList<TableCheckbox>;

    @ViewChildren('rowHeaderCheckBox')
    rowHeaderCheckBox: QueryList<TableHeaderCheckbox>;

    @Output()
    filesUpdated: EventEmitter<LoanDoc> = new EventEmitter<LoanDoc>();

    @Output()
    filesArrayUpdated: EventEmitter<LoanDoc[]> = new EventEmitter<LoanDoc[]>();

    @Input()
    set loanDocs(loanDocs: LoanDoc[]) {
        this.filteredDocs = loanDocs.filter(d => d.documentTypeId == this.document.documentTypeId);
    }

    @Input()
    set isSelectAllClicked(isClicked: boolean) {
        this.setRowCheckBoxStatus(isClicked)
    }

    @Input()
    document: DocumentType;

    filteredDocs: LoanDoc[] = [];

    documentTypeId: string;

    selectedRows: any[];

    constructor() { }

    ngOnChanges(_: SimpleChanges): void {
        this.selectedRows = this.filteredDocs.filter(({ checked }) => checked);
    }


    ngOnInit() {
    }

    onSelectAllByDocumentClicked = (documentTypeId: number, isChecked: boolean) => {
        this.filteredDocs.filter(doc => doc.documentTypeId == documentTypeId).forEach(docFile => {
            if (docFile.docFiles) {
                docFile.docFiles.forEach(dFile => dFile['isSelected'] = isChecked)
            }
        })
    }

    onToggleCheckboxClicked = (file: LoanDoc, checked: boolean) => {
        this.filteredDocs.filter(doc => doc.loanDocId == file.loanDocId).forEach(docFile => {
            if (docFile.docFiles) {
                docFile.docFiles.forEach(dFile => dFile['isSelected'] = checked);
            }
        })
    }

    private setRowCheckBoxStatus = (isChecked: boolean) => {
        if (this.filteredDocs.length > 0) {
            this.filteredDocs.forEach(file => file.checked = isChecked);
            this.ngOnChanges(null);
        }
    }
}