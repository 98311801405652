import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProcessSubmissionModel } from '../../models/process-submission.model';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { KeyDatesComponent } from '../key-dates/key-dates.component';
import { ChannelEnum } from 'src/app/models';

@Component({
	selector: 'missing-information',
	templateUrl: './missing-information.component.html',
	styleUrls: ['./missing-information.component.scss']
})
export class MissingInformationComponent extends ApplicationContextBoundComponent implements OnInit {

	@ViewChild('missingInformationForm')
	missingInformationForm: NgForm | undefined;

	@ViewChild('keyDates')
	keyDates: KeyDatesComponent | undefined;

	@Input()
	processSubmissionData: ProcessSubmissionModel;

	@Input()
	context;

	@Input()
	allowedLoanCharacterstics;

	@Input()
	allowedBorrowerCharacterstics;

	@Input()
	isCorrSearchForCreditOnSubmissionEnabled: boolean;

	@Input()
	showKeyDates: boolean;

	@Output()
	nextStep: EventEmitter<{ processSubmissionData: ProcessSubmissionModel }> = new EventEmitter<any>();

	@Output()
	previousStep: EventEmitter<any> = new EventEmitter<any>();

	urlaFieldsConfig: {} = {};

	showSubjectProperty: boolean = false;
	showCreditRefNumber: boolean = true;

	protected nothingIsMissing: boolean = false;

	protected borrowersWithoutCreditRefNumber: Array<any> = [];

	private _applications: Array<any> = [];

	constructor(
		private readonly injector: Injector,
	) {
		super(injector);
	}

	ngOnInit(): void {
		//validate property info is there
		this.showSubjectProperty = !this.processSubmissionData.loanData?.mortgage?.subjectProperty?.noOfUnits || false;

		const willCheckIfCreditRefsMissing =
			this.processSubmissionData.channel !== ChannelEnum.Correspondent ||
			(this.processSubmissionData.channel === ChannelEnum.Correspondent && this.isCorrSearchForCreditOnSubmissionEnabled);

		if (willCheckIfCreditRefsMissing) {
			if (!!this.processSubmissionData.loanData?.mortgage) {
				_.orderBy(this.processSubmissionData.loanData.mortgage.borrowers, [["printApplicationIndex", "asc"]]).forEach(borr => {
					if (borr.printApplicationIndex > -1) {
						var app = this._applications.find(x => x.appIndex == borr.printApplicationIndex);
						if (!app) {
							this._applications.push({ appIndex: borr.printApplicationIndex, displayName: borr.firstName + ' ' + borr.lastName, mortgageBorrowerId: borr.borrowerId, creditRefNumber: borr.creditRefNumber });
						} else {
							app.displayName = app.displayName + " & " + borr.firstName + ' ' + borr.lastName;
						}
					}
				});
			}
			// Find applications w/o credit ref number
			this.borrowersWithoutCreditRefNumber = this._applications.filter(app => !app.creditRefNumber);
			this.showCreditRefNumber = this.borrowersWithoutCreditRefNumber?.length > 0;
		}

		this.nothingIsMissing = !this.showCreditRefNumber && !this.showSubjectProperty && !this.showKeyDates;
	}

	back = () => {
		this.previousStep.emit();
	}

	proceed = () => {
		if (this.keyDates) {
			this.keyDates.keyDatesForm.form.markAllAsTouched();
		}

		this.missingInformationForm.form.markAllAsTouched();
		if (this.missingInformationForm.form.valid && (!this.keyDates || (this.keyDates && this.keyDates.keyDatesForm.form.valid))) {
			this.processSubmissionData.loanData.mortgage.borrowers.forEach(borr => {
				borr.creditRefNumber = this._applications.find(x => x.appIndex == borr.printApplicationIndex)?.creditRefNumber;
			});
			this.nextStep.emit({ processSubmissionData: this.processSubmissionData });
		}
	}
}
