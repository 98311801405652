import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { RowHeight } from 'src/app/shared/models/table-config.model';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { ApplicationTableConfig } from './application-table/application-table-config.model';
import { ApplicationService } from './services/applications.service';

@Component({
  selector: 'applications',
  templateUrl: './applications.component.html'
})
export class ApplicationsComponent extends ApplicationContextBoundComponent implements OnInit {

  applications: PipelineItem[] = [];

  applicationTableConfig: ApplicationTableConfig;

  isShowArchived: boolean = false;

  hideNewApplicationButton: boolean = false;

  newApplicationDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  constructor(
    private _notify: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _applicationService: ApplicationService,
    private readonly _drawerService: DrawerService,
    private readonly injector: Injector,
  ) {
    super(injector);
    this.applicationContextService.changes.subscribe(context => {
      if (!context) {
        return;
      }
      if (!context.userPermissions.showNewSubmissionForm && !context.userPermissions.superAdmin) {
        this.hideNewApplicationButton = true;
      }
    });
  }

  ngOnInit(): void {
    this.loadData();

    this.applicationTableConfig = {
      columns: [
        { field: 'applicationId', header: 'ID / Purpose', order: 1, visible: true },
        { field: 'lastName', header: 'Borrower / Property', order: 2, visible: true },
        { field: 'loanStatusName', header: 'Loan Status', order: 3, visible: true },
        { field: 'mobilePhone', header: 'Contact Info', order: 4, visible: true },
        { field: 'loanAmount', header: 'Loan Amount', order: 5, visible: true },
        { field: 'interestRate', header: 'Interest Rate', order: 6, visible: true },
        { field: 'primaryRoleContact', header: 'Loan Contact', order: 7, visible: true },
        { field: 'appCreateDate', header: 'Date Created', order: 8, visible: true },
        { field: 'refNumber', header: 'Ref#', order: 10, visible: false }
      ],
      extraGlobalFilterFields: ['firstName', 'email', 'homePhone', 'channel', 'mailingStreet', 'mailingCity', 'mailingState', 'mailingZip', 'applicationIdWithPadding'],
      isAddNewButtonVisible: false,
      isShowArchiveButtonVisible: true,
      isAddToDialListButtonVisible: true,
      scrollOffset: 234,
      rowHeight: RowHeight['80px'],
      scrollable: true
    }
    if (this.applicationContext) {
      if (!this.applicationContext.userPermissions.showNewSubmissionForm && !this.applicationContext.userPermissions.superAdmin) {
        this.hideNewApplicationButton = true;
      }
    }
  }

  loadData = () => {
    this._spinner.show();
    this._applicationService.getAllApplications(this.isShowArchived).subscribe((response) => {
      this.applications = response;
      this.applications.forEach(item => {
        item.enteredLoanStatus = Utils.timeSince(item.loanStatusChangedDate);
        item.lastUpdated = Utils.timeSince(item.dateUpdated);
      });
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
      this._notify.showError(err ? err.message : 'Unable to get applications', 'Applications');
    });
  }

  showAsArchiveChange = (isChecked: boolean): void => {
    this.isShowArchived = isChecked;
    this.loadData();
  }

  onNewApplicationOpened = () => {
    this._drawerService.show("newApplicationDrawer", 10);
  }

  onNewApplicationDrawerClose = () => {
    this._drawerService.hide("newApplicationDrawer", 10);
  }
}
