import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LenderPricePricerComponent } from "./lenderprice-pricer.component";
import { LenderPricePricerDialogComponent } from "./lenderprice-pricer-dialog/lenderprice-pricer-dialog.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    LenderPricePricerComponent,
    LenderPricePricerDialogComponent
  ],
  exports: [
    LenderPricePricerComponent,
    LenderPricePricerDialogComponent
  ]
})
export class LenderPricePricerModule {
  constructor() {
  }
}


