<div class="alert alert-dismissible d-flex align-items-center mb-0" *ngIf="isSubmitSuccess || isSubmitError"
    [ngClass]="{'alert-outline-success': isSubmitSuccess, 'alert-outline-danger': isSubmitError}" role="alert">
    <i class="ti ti-checks alert-icon me-2"></i>
    <div *ngIf="isSubmitSuccess">
        <span>
            <strong>Success:</strong> {{message}}
        </span>
    </div>
    <div *ngIf="isSubmitError">
        <div>
            <strong>Error:</strong> {{errorMessage ? errorMessage : 'Something went wrong.'}}
        </div>
    </div>

    <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeErrors()" aria-label="Close"></button>
</div>
<div class="card-body">
    <div *ngFor="let borrower of selectedBorrowers">
        <label class="mb-2">
            <div class="avatar-box thumb-xxs align-self-center me-1">
                <span class="avatar-title bg-purple rounded"><i class="fas fa-user"></i></span>
            </div>
            {{borrower.firstName + ' ' + borrower.lastName}}
        </label>
        <div class="table-responsive">
            <table class="table mb-0 table-centered mb-2">
                <thead class="table-light">
                    <tr>
                        <th> Lien Holder </th>
                        <th> Balance </th>
                        <th> Mo. Payment </th>
                        <th> Type </th>
                        <th> Import </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let liability of getLiabilitiesByBorrower(borrower)">
                        <td> {{liability.holderName}} </td>
                        <td> {{(liability.unpaidBalance ? liability.unpaidBalance : 0) | currency}} </td>
                        <td> {{(liability.monthlyPayment ? liability.monthlyPayment : 0) | currency}} </td>
                        <td> {{liability.displayName}} </td>
                        <td>
                            <div class="form-check form-switch">
                                <input [(ngModel)]="liability.isChecked" class="form-check-input" type="checkbox"
                                    id="pullSwitchCheck" name="pullSwitchCheck">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="form-check">
            <input id="deleteLiability" class="form-check-input" type="checkbox"
                [(ngModel)]="deleteExistingUnmatchedLiabilitiesFromLoan" name="deleteLiability">
            <label for="deleteLiability"> Delete all existing liabilities before import </label>
        </div>
    </div>
    <div *ngIf="!loadingLiabilities && !isLiabilityHistoryError">
        <div class="text-center">
            <button type="button" class="btn btn-primary"
                [disabled]="isLoanReadOnly || importingLiabilities"
                (click)="onImportLiabilitesClicked()"
                [ngShow]="!importingLiabilities">
                <span class="spinner-border spinner-border-sm" role="status" *ngIf="importingLiabilities">
                </span>
                {{!importingLiabilities ? (isLoanReadOnly ? 'Import (Readonly)' : 'Import') : 'Importing...'}}
            </button>
        </div>
    </div>
</div>
