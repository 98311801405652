import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WireRequestModel } from "../../../models/wire-request.model";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { formViewProvider } from "src/app/core/services/form-view.provider";

@Component({
  templateUrl: "create-wire-request-borrower.component.html",
  selector: "create-wire-request-borrower",
  viewProviders: [formViewProvider]
})
export class CreateWireRequestBorrower {
  @Input()
  wireRequest: WireRequestModel;

  @Input()
  ethnicities: EnumerationItem[];

  @Input()
  races: EnumerationItem[];

  @Input()
  sexTypes: EnumerationItem[];

  @Output()
  borrowerNamesChange = new EventEmitter<never>();

  constructor() {}
  
  onBorrowerNamesChaned() {
    this.borrowerNamesChange.emit();
  }
}