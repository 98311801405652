<div class="pricing-details-card">
  <ng-container *ngIf="application.productPricing; else notPricedYet">
    <div class="row">
      <div class="d-flex justify-content-between mb-3">
        <div class="header text-uppercase" style="font-weight: 500;">
          Product Details
        </div>
        <!---->
        <div>
          <a (click)="onRePriceLoanClicked()"  class="text-primary" style="font-size: small;">
            <i class="fas fa-dollar-sign fa-rotate-180 text-primary me-1" style="color: #3aaf80"></i> Re-price Loan </a>
          </div>
      </div>
      <div class="label" style="color: #172B4E;">
        {{application.productPricing && application.productPricing.productName ? application.productPricing.productName
        : '--' }}
      </div>
      <div>
        <span class="label-2">(TBD)</span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-4">
        <div class="header">
          RATE / APR
        </div>
        <div class="label" style="color: #09995D;">
          {{application.productPricing && (application.productPricing.rate || application.productPricing.rate == 0) ?
          (getPercentNumber(application.productPricing.rate) | percent:'1.3-3') :
          '--'}}
        </div>
        <div>
          <span class="label-2">
            {{application.productPricing && (application.productPricing.apr || application.productPricing.apr == 0) ?
            (getPercentNumber(application.productPricing.apr) | percent:'1.3-3') :
            '--'}}
            APR
          </span>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="header text-uppercase">
          Final Price
        </div>
        <div class="label">
          {{application.productPricing.price ? (application.productPricing.price | number: '1.3-3') : '--'}}
        </div>
        <div>
          <span class="label-2">(TBD)</span>
        </div>
      </div>
      <div class="col-sm-4" *ngIf="!(isTpo && isPRMG && ['Correspondent', 'NonDelegatedCorrespondent'].includes(application?.channel))">
        <div class="header">
          BROKER COMP - LP
        </div>
        <div class="label">
          {{application.productPricing && (application.productPricing.comp || application.productPricing.comp == 0) ?
          (getPercentNumber(application.productPricing.comp * 100) | percent:'1.3-3') :
          '--'
          }}
        </div>
        <div>
          <span class="label-2">(TBD)</span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <span class="fw-bold" style="font-size: 14px;"> Lock Status:
        <span [ngClass]="{'text-success': application.productPricing && application.productPricing.lockStatus == 'Accepted',
                'text-warning': application.productPricing && application.productPricing.lockStatus == 'Requested',
                'text-danger': application.productPricing && application.productPricing.lockStatus == 'Rejected'}">
          {{application.productPricing && application.productPricing.lockStatus ? application.productPricing.lockStatus
          : '--'}}
        </span>
      </span>
    </div>
  </ng-container>
  <ng-template #notPricedYet>
    <div class="header" style="font-weight: 500;">
      This loan has not been priced yet.
    </div>
  </ng-template>
</div>
