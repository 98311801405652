<div *ngIf="milestonesReport; else loading">
  <div class="card report-card">
    <h3 class="card-header border-bottom-0">
      Milestones for
      {{ milestonesReport.description }}
    </h3>
    <div
      class="card-body d-flex justify-content-between flex-wrap align-items-stretch"
    >
      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Speed to Attempted Contact</p>
            <h3 class="m-0">
              {{
                formatNumber(
                  milestonesReport.speedToContactAttemptSeconds / 60,
                  2
                )
              }}
              min
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-clock align-self-center text-muted icon-sm"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 6 12 12 16 14"></polyline>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Speed to Contact</p>
            <h3 class="m-0">
              {{ formatNumber(milestonesReport.speedToContactSeconds / 60, 2) }}
              min
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-clock align-self-center text-muted icon-sm"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 6 12 12 16 14"></polyline>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Total Leads</p>
            <h3 class="m-0">{{ milestonesReport.leads || 0 }}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Active Leads</p>
            <h3 class="m-0">
              {{ milestonesReport.activeLeads || 0 }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col-7">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Contact Rate</p>
            <h3 class="m-0">
              {{ formatNumber(milestonesReport.contactRate, 3) }}%
            </h3>
          </div>
          <div class="col-5">
            <circle-progress
              [percent]="formatNumber(milestonesReport.contactRate, 3)"
            >
            </circle-progress>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Converted Leads</p>
            <h3 class="m-0">
              {{ milestonesReport.convertedLeads || 0 }}
            </h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-light-alt">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-users align-self-center text-muted icon-sm"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="card report-card">
        <div class="card-body row d-flex justify-content-center">
          <div class="col-7">
            <p class="font-size-11 text-dark mb-0 fw-semibold">Conversion Rate</p>
            <h3 class="m-0">
              {{ formatNumber(milestonesReport.conversionRate, 3) }}%
            </h3>
          </div>
          <div class="col-5">
            <circle-progress
              [percent]="formatNumber(milestonesReport.conversionRate, 3)"
            >
            </circle-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading Reports..."></loading-indicator>
</ng-template>
