import { NgModule } from "@angular/core";
import { DialpadModule } from "src/app/shared/modules/dialpad/dialpad.module";
import { SharedModule } from "src/app/shared/shared.module";
import { DialNumberAndCallHistoryComponent } from "./dial-number-and-call-history.component";
import { DialCallHistoryComponent } from './dial-call-history/dial-call-history.component';

@NgModule({
  imports: [
    SharedModule,
    DialpadModule
  ],
  declarations: [
    DialNumberAndCallHistoryComponent,
    DialCallHistoryComponent
  ],
  exports: [
    DialNumberAndCallHistoryComponent,
  ],
  providers: [
  ]
})
export class DialNumberAndCallHistoryModule {
  constructor() {
  }
}
