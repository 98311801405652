import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TaskLoanDocPreviewComponentV2 } from './task-loan-doc-preview-v2.component';
import { DocFilesEditDialogModule } from 'src/app/modules/loan-docs/components/doc-files-edit-dialog/doc-files-edit-dialog.module';

@NgModule({
  imports: [
    SharedModule,
    DocFilesEditDialogModule
  ],
  declarations: [
    TaskLoanDocPreviewComponentV2
  ],
  exports: [
    TaskLoanDocPreviewComponentV2
  ],
  providers: [

  ]
})
export class TaskLoanDocPreviewModule {
  constructor() {

  }
}
