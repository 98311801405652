import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Message } from 'src/app/models';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-last-attempted-contact-dialog',
  templateUrl: './last-attempted-contact-dialog.component.html',
  styleUrls: ['./last-attempted-contact-dialog.component.scss']
})
export class LastAttemptedContactDialogComponent implements OnInit {

  appId: number;

  wasSuccess: boolean = false;
  notes: string;

  isSaving: boolean = false;

  constructor(
    private readonly _messageService: MessageService,
    private readonly _notifyService: NotificationService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  save = () => {

    this.isSaving = true;

    let adminMessage = new Message();
    adminMessage.applicationId = this.appId;
    adminMessage.content = this.notes;

    this._messageService.postInternalMessage(adminMessage, true, this.wasSuccess)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this._notifyService.showSuccess("Message sended succussfully", "Successful");
          this.activeModal.close();
        }, error: () => {
          this.isSaving = false;
          this._notifyService.showError("Error encountered while sending internal message", "Error!");
        }
      })
  }

}
