<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        ATR / QM Recommendation
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #atrQmRecommendationForm="ngForm" id="atrQmRecommendationForm">
      <div class="row">
        <div class="col-md-3 form-group">
          <label for="abilityToRepayMethodType"> Ability To Repay Method Type </label>
          <select id="abilityToRepayMethodType" name="abilityToRepayMethodType" class="form-select"
            [(ngModel)]="regulationZDetail.abilityToRepayMethodType">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of abilityToRepayMethodTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 form-group">
          <label for="qualifiedMortgageType"> Qualified Mortgage Type </label>
          <select id="qualifiedMortgageType" name="qualifiedMortgageType" class="form-select"
            [(ngModel)]="regulationZDetail.qualifiedMortgageType">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of qualifiedMortgageTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 form-group" *ngIf="regulationZDetail.qualifiedMortgageType === 'Other'">
          <label for="qualifiedMortgageTypeOtherDescription"> Qualified Mortgage Type Other Desc. </label>
          <input type="text" class="form-control" id="qualifiedMortgageTypeOtherDescription"
            name="qualifiedMortgageTypeOtherDescription"
            [(ngModel)]="regulationZDetail.qualifiedMortgageTypeOtherDescription">
        </div>
        <div class="col-md-3 form-group">
          <label for="presumptionOfComplianceType"> Presumption of Compliance Type </label>
          <select id="presumptionOfComplianceType" name="presumptionOfComplianceType" class="form-select"
            [(ngModel)]="regulationZDetail.presumptionOfComplianceType">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of presumptionOfComplianceTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>