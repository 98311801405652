import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminEvent, AdminEventType, AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'calls-on-leads-on',
  templateUrl: './calls-on-leads-on.component.html',
  styleUrls: ['./calls-on-leads-on.component.scss']
})
export class CallsOnLeadsOnComponent extends ApplicationContextBoundComponent implements OnInit {

  userId: string;
  isCallsOn: boolean = false;
  isLeadsOn: boolean = false;
  dialerEnabled: boolean = false;

  private _applicationContextSubscription: Subscription;
  private _adminServiceEventsSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _adminService: AdminService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(injector);

    this._adminServiceEventsSubscription = this._adminService.events.subscribe((e: AdminEvent) => {
      if (!e) {
        return;
      }

      switch (e.eventType) {
        case AdminEventType.CallsOnToggle:
          this.listener_callsOnToggle(!!e.data?.data);
          break;
        case AdminEventType.LeadsOnToggle:
          this.listener_leadsOnToggle(!!e.data?.data);
          break;
      }
    })
  }

  ngOnInit(): void {
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      if (!context.userPermissions.userId) {
        return;
      }
      this.userId = context.userPermissions.userId;
      this.dialerEnabled = context.userPermissions.dialerEnabled;
      this.initialize();
    })
  }

  ngOnDestroy(): void {
    this._applicationContextSubscription?.unsubscribe();
    this._adminServiceEventsSubscription?.unsubscribe();
  }

  initialize = () => {
    this._adminService.getUserDialerConfig(this.userId)
      .subscribe(userProfile => {
        this.isCallsOn = userProfile.callsOn;
        this.isLeadsOn = userProfile.leadsOn;
      });
  }

  listener_leadsOnToggle = (data: boolean) => {
    this.isLeadsOn = data;
  }

  listener_callsOnToggle = (data: boolean) => {
    this.isCallsOn = data;
  }

  leadsOn = () => {
    this._spinner.show();
    this._adminService.saveUserLeadsOnStatus(this.userId)
      .subscribe(userProfile => {
        this.isLeadsOn = userProfile.leadsOn;
        this.applicationContextService.callsOrLeadsOnOffStatusChanged();
      }, err => {
        let errorMessage = "An error occurred while turning leads on.";
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notifyService.showError(errorMessage, "Error!");
      }).add(() => this._spinner.hide());
  }

  leadsOff = () => {
    this._spinner.show();
    this._adminService.saveUserLeadsOffStatus(this.userId)
      .subscribe(userProfile => {
        this.isLeadsOn = userProfile.leadsOn;
        this.applicationContextService.callsOrLeadsOnOffStatusChanged();
      }, err => {
        let errorMessage = "An error occurred while turning leads off.";
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notifyService.showError(errorMessage, "Error!");
      }).add(() => this._spinner.hide());;
  }

  callsOn = () => {
    this._spinner.show();
    this._adminService.saveUserCallsOnStatus(this.userId)
      .subscribe(userProfile => {
        this.isCallsOn = userProfile.callsOn;
        this.applicationContextService.callsOrLeadsOnOffStatusChanged();
      }, err => {
        let errorMessage = "An error occurred while turning calls on.";
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notifyService.showError(errorMessage, "Error!");
      }).add(() => this._spinner.hide());;

  }

  callsOff = () => {
    this._spinner.show();
    this._adminService.saveUserCallsOffStatus(this.userId)
      .subscribe(userProfile => {
        this.isCallsOn = userProfile.callsOn;
        this.applicationContextService.callsOrLeadsOnOffStatusChanged();
      }, err => {
        let errorMessage = "An error occurred while turning calls off.";
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notifyService.showError(errorMessage, "Error!");
      }).add(() => this._spinner.hide());;
  }
}
