import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DateTime } from "luxon";
import { IHttpCacheStorage } from "./http-request-cache.decorator";

@Injectable({ providedIn: 'root' })
export class CacheService implements IHttpCacheStorage {

  readonly CACHE_DURATION_IN_MINUTES = 5;

  private cache: {
    [id: string]: {
      expires: Date,
      value: Observable<any>
    }
  } = {};

  constructor() {
  }

  getItem(key: string): Observable<any> {
    const item = this.cache[key];
    if (!item) {
      return null;
    }

    const now = DateTime.fromJSDate(new Date());
    if (now > DateTime.fromJSDate(item.expires)) {
      return null;
    }

    return item.value;
  }

  setItem(key: string, value: Observable<any>) {
    const expires = DateTime.fromJSDate(new Date())
      .plus({minutes: this.CACHE_DURATION_IN_MINUTES})
      .toJSDate();
    this.cache[key] = {expires, value};
  }

  clearCache() {
    this.cache = {};
  }
}

