<div *ngIf="isDocumentType(history.eventType); else otherEventTypes">
  <table>
    <tbody>
      <tr>
        <td>File Name:</td>
        <td class="ps-2">
          <a (click)="downloadDocument(history.documentEventData)" class="mt-2">
            <span class="dark-fa-color mx-1">
              {{ history.documentEventData.fileName }}
            </span>
          </a>

          <a
            (click)="viewDocument(history.documentEventData)"
            title="View"
            class="mx-2 align-text-bottom"
          >
            <i class="far fa-eye"></i>
          </a>
          <a
            title="Download"
            class="align-text-bottom"
            (click)="downloadDocument(history.documentEventData)"
          >
            <i class="fas fa-download"></i>
          </a>
        </td>
      </tr>

      <tr>
        <td>Third Party Document ID:</td>
        <td class="ps-2">
          {{ history.documentEventData.thirdPartyDocumentId }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #otherEventTypes>
  <p-table
    [value]="orderChanges"
    dataKey="key"
    [resizableColumns]="true"
    responsiveLayout="scroll"
    scrollable="true"
    styleClass="p-datatable-gridlines"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex" style="align-items: center">Order Changes</div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th class="text-center">Field</th>
        <th pResizableColumn>Old</th>
        <th pResizableColumn>New</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-changeItem let-expanded="expanded">
      <tr>
        <td>
          <button
            *ngIf="changeItem.isArray"
            type="button"
            pButton
            pRipple
            [pRowToggler]="changeItem"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
          ></button>
        </td>
        <td class="text-center">
          <span class="text-cell-primary">{{ changeItem.keyName }}</span>
        </td>
        <td>
          <span class="text-muted">{{ changeItem.dataType == 'currency' ? (changeItem.oldValue | currency) : changeItem.oldValue }}</span>
        </td>
        <td>
          <span class="text-muted">{{ changeItem.dataType == 'currency' ? (changeItem.newValue | currency) : changeItem.newValue }}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-changeItem>
      <tr>
        <td colspan="4">
          <div class="p-3">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" *ngFor="let change of changeItem.arrayChanges; let i = index;">
                <h2 class="accordion-header mt-0" [id]="'heading' + i">
                  <button class="accordion-button py-0" type="button" [ngClass]="{'collapsed' : i != 0}" [attr.data-bs-target]="'#collapse'+ i" data-bs-toggle="collapse" aria-expanded="true" [attr.aria-controls]="'collapse' + i">
                    {{ change.keyName}} - &nbsp;<span>
                     <span class="text-danger" *ngIf="change.changeType == 'deleted'"> {{change.changeType}}</span>
                     <span class="text-success" *ngIf="change.changeType == 'updated'"> {{change.changeType}}</span>
                     <span class="text-success" *ngIf="change.changeType == 'created'"> {{change.changeType}}</span>
                    </span>
                  </button>
                </h2>
                <div [id]="'collapse' + i" class="accordion-collapse collapse {{ i == 0 ? 'show' : '' }}" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p-table [value]="change.arrayChanges" dataKey="key">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-center">Field</th>
                          <th pResizableColumn>Old</th>
                          <th pResizableColumn>New</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-changeItemInner2>
                        <tr>
                          <td class="text-center">
                            <span class="text-cell-primary">{{
                              changeItemInner2.keyName
                            }}</span>
                          </td>
                          <td>
                            <span class="text-muted">{{ changeItemInner2.dataType == 'currency' ? (changeItemInner2.oldValue | currency) : changeItemInner2.oldValue
                            }}</span>
                          </td>
                          <td>
                            <span class="text-muted">{{
                              changeItemInner2.dataType == 'currency' ? (changeItemInner2.newValue | currency) : changeItemInner2.newValue
                            }}</span>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="3">No changes found</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4" class="text-center">No changes found</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
