import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Configuration } from 'src/app/models/configuration.model';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevelService } from '../../../../services/system-level.service';
import { TelephonyServiceModel } from '../../models';

@Component({
  selector: 'sms-settings',
  templateUrl: './sms-settings.component.html'
})

export class SmsSettingsComponent implements OnInit {
  @Input()
  telephonyService: TelephonyServiceModel;

  @Input()
  smsAllowedSendTimeRange: Configuration;

  @Input()
  smsAlertsEnabled: boolean;

  @Input()
  type: 'branch' | 'system-level';

  @Input()
  enableSmsAlertsByDefault: Configuration;

  @Output()
  onConfigSave: EventEmitter<void> = new EventEmitter<void>();

  sendingTestSms: boolean;
  sendTestSmsSuccess: boolean;
  sendTestSmsErrorMessage: string;
  isSaving: boolean;

  constructor(
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (!this.telephonyService) {
      this.telephonyService = new TelephonyServiceModel();
    }
    if (!this.smsAllowedSendTimeRange) {
      this.smsAllowedSendTimeRange = new Configuration();
    }
    if (!this.enableSmsAlertsByDefault) {
      this.enableSmsAlertsByDefault = new Configuration('EnableSmsAlertsByDefault', 0);
    }
  }

  sendTestEmail(): void {
    this.sendingTestSms = true;
    this._systemLevelService.sendTestSms(this.telephonyService)
      .pipe(finalize(() => this.sendingTestSms = false))
      .subscribe({
        next: (response) => {
          this.sendTestSmsSuccess = true;
          this.sendTestSmsErrorMessage = null;
        },
        error: (error) => {
          this.sendTestSmsSuccess = false;
          this.sendTestSmsErrorMessage = error.message;
        }
      });
  }

  save(): void {
    this.isSaving = true;
    forkJoin([
      this._systemLevelService.saveTelephonyService(this.telephonyService),
      this._systemLevelService.saveConfiguration(this.smsAllowedSendTimeRange),
      this._systemLevelService.saveConfiguration(this.enableSmsAlertsByDefault)
    ])
      .pipe(finalize(() => {
        this.isSaving = false;
      }))
      .subscribe({
        next: res => {
          this.onConfigSave.emit();
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}
