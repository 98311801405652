import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, isArray } from 'lodash';
import { finalize } from 'rxjs';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { ExpressionBuilderDialogService } from 'src/app/modules/expression-builder/services/expression-builder-dialog.service';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ErrorMessage } from 'src/app/shared/models/error.model';
import { FeeTemplateDetailsComponent } from './fee-template-details/fee-template-details.component';

@Component({
  selector: 'app-fee-template-details-dialog',
  templateUrl: './fee-template-details-dialog.component.html',
  styleUrls: ['./fee-template-details-dialog.component.scss']
})
export class FeeTemplateDetailsDialogComponent {

  @ViewChild("feeTemplateForm")
  feeTemplateForm: NgForm | undefined;

  @ViewChild("feeTemplateDetailComponent")
  feeTemplateDetailComponent: FeeTemplateDetailsComponent

  @Input()
  set purpose(value: FeeTemplateDetailsDialogPurpose | undefined) {
    this.title = value?.title || FeeTemplateDetailsDialogPurpose.defaultTitle;
    this.feeTemplate = value?.feeTemplate || new FeeTemplate();
    this._isCopyingTemplate = (value.type === 'copy');
  }

  protected title: string = FeeTemplateDetailsDialogPurpose.defaultTitle;

  @Output()
  getTemplateExpressionHTMLs: EventEmitter<FeeTemplate[]> = new EventEmitter<FeeTemplate[]>();

  isSaving: boolean = false;
  feeTemplate: FeeTemplate = new FeeTemplate();

  allFeeTemplates: FeeTemplate[] = [];

  error: ErrorMessage = null;

  private _isCopyingTemplate = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _feeService: FeeService,
    private readonly _notifyService: NotificationService,
    private readonly _expressionBuilderDialogService: ExpressionBuilderDialogService,
  ) { }

  save = () => {
    if (!this.feeTemplateForm) return;
    this.feeTemplateForm.form.markAllAsTouched();
    if (!this.feeTemplateForm.form.valid) return;

    const existingwithSameName = this.allFeeTemplates.find(t => t.name.toLowerCase() === this.feeTemplate.name.toLowerCase() &&
      t.feeTemplateId != this.feeTemplate.feeTemplateId);
    if (existingwithSameName) {
      this.error = new ErrorMessage("Error", "There is already another template with the same name.");
      return;
    }

    this.isSaving = true;
    if (!this.feeTemplateDetailComponent.expressionGroup && this.feeTemplate.expressionId) { // delete expression
      this.feeTemplateDetailComponent.deleteExpression(this.feeTemplate.expressionId)
        .subscribe({
          next: () => {
            this.feeTemplate.expressionId = null;
            this.saveFee();
          },
          error: (error) => {
            this.isSaving = false;
            this._notifyService.showError(error?.message || 'Unable to save expression group(s) and fee template', 'Fee Template');
          }
        })
    } else {
      if (!this.feeTemplateDetailComponent.expressionGroup) {
        this.saveFee();
      } else {
        if (this._isCopyingTemplate) {
          this.feeTemplateDetailComponent.expressionGroup.expressionGroupId = null;
        }
        this._expressionBuilderDialogService.saveExpressionGroups(this.feeTemplateDetailComponent.expressionGroup)
          .subscribe({
            next: (res) => {
              this.feeTemplate.expressionId = res.expressionGroupId;
              this.saveFee();
            },
            error: (error) => {
              this.isSaving = false;
              this._notifyService.showError(error?.message || 'Unable to save expression group(s) and fee template', 'Fee Template');
            }
          })
      }
    }
  }

  private saveFee = () => {
    if (this.feeTemplate.feeTemplateId) { // isUpdate
      this._feeService.updateFeeTemplate(this.feeTemplate.feeTemplateId, this.feeTemplate)
        .pipe(finalize(() => this.isSaving = false))
        .subscribe({
          next: (result) => {
            this.isSaving = false;
            this._notifyService.showSuccess("Fee Template updated Successfully", "Success");
            this.activeModal.close(result);
            this.getTemplateExpressionHTMLs.emit([this.feeTemplate]);
          },
          error: (error) => {
            this.isSaving = false;
            this._notifyService.showError(error?.message || "Couldn't update fee template", "Error");
          }
        })
    } else {
      this._feeService.insertFeeTemplate(this.feeTemplate)
        .pipe(finalize(() => this.isSaving = false))
        .subscribe({
          next: (result) => {
            this._notifyService.showSuccess("Fee Template inserted Successfully", "Success");
            this.activeModal.close(result);
            this.getTemplateExpressionHTMLs.emit([this.feeTemplate]);
          },
          error: (error) => {
            this._notifyService.showError(error?.message || "Couldn't insert fee template", "Error");
          }
        })
    }
  }
}

export abstract class FeeTemplateDetailsDialogPurpose {
  static readonly defaultTitle: string = 'Fee Template';

  readonly type: string;
  readonly title: string;
  readonly feeTemplate: FeeTemplate;

  protected constructor(
    type: string,
    feeTemplate: FeeTemplate,
  ) {
    this.type = type;
    this.feeTemplate = cloneDeep(feeTemplate);
  }
}

export class EditFeeTemplatePurpose extends FeeTemplateDetailsDialogPurpose {
  readonly title: string = 'Edit Fee Template';
  readonly feeTemplate: FeeTemplate;

  constructor(feeTemplate: FeeTemplate) {
    super('edit', feeTemplate);
  }
}

export class CopyFeeTemplatePurpose extends FeeTemplateDetailsDialogPurpose {
  readonly title: string = 'Copy Fee Template';
  readonly feeTemplate: FeeTemplate;

  constructor(feeTemplate: FeeTemplate) {
    super('copy', feeTemplate);

    this.feeTemplate.name =
      CopyFeeTemplatePurpose.normalizeName(this.feeTemplate.name);
    if (isArray(this.feeTemplate.fees)) {
      this.feeTemplate.fees.forEach(f => f.feeTemplateFeeId = 0);
    }
    delete this.feeTemplate.feeTemplateId;
  }

  /**
   * Adds (Copy) to the name if it doesn't already exist.
   * If it does, increment the number.
   * @param {string} name The name to normalize.
   * @returns {string} The normalized name.
   */
  private static normalizeName(name: string): string {
    const copyRegex = /\(Copy( \d+)?\)$/;
    const match = copyRegex.exec(name);

    if (!match) {
      return `${name} (Copy)`;
    }

    const copyNumber = match[1] ? parseInt(match[1]) + 1 : 2;
    return name.replace(copyRegex, `(Copy ${copyNumber})`);
  }
}
