import { Component, Input, OnInit } from '@angular/core';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { AuditMessage, IntegrationError } from 'src/app/models';

type MessageToDisplay = {
  descriptionAndMessage: string;
  statusCodeAndCategory: string;
  statusAndCode: string;
  statusConditionDescription: string;
};

@Component({
  selector: 'disclosure-message',
  templateUrl: 'disclosure-message.component.html',
})
export class DisclosureMessageComponent implements OnInit {
  @Input()
  set messages(messages: Array<AuditMessage> | Array<IntegrationError>) {
    this._messages = messages;
    this.initMessages();

    if(this.isAudit){
      this.filters["statusConditionDescription"] = [{value: ["Warning"], matchMode: "in"}];
    }
  }

  get messages(): Array<AuditMessage> | Array<IntegrationError> {
    return this._messages;
  }

  @Input() isAudit: boolean;

  messagesToDisplay: Array<MessageToDisplay> = [];
  columns: Array<any> = [];

  statuses: Array<string> = [];
  categories: Array<string> = [];

  filters: { [key in keyof (AuditMessage | IntegrationError)]: FilterMetadata[] } = {};

  private _messages: Array<AuditMessage> | Array<IntegrationError>;

  constructor() {}

  ngOnInit(): void {
    this.columns = [
      {
        field: 'descriptionAndMessage',
        header: this.isAudit ? 'Status Description' : 'Message',
      },
      {
        field: 'statusCodeAndCategory',
        header: this.isAudit ? 'Status Code' : 'Error Message Category Code',
      },
      {
        field: 'statusAndCode',
        header: this.isAudit ? 'Status' : 'Error Category Code',
      },
      {
        field: 'statusConditionDescription',
        header: this.isAudit
          ? 'Category'
          : 'Message Category',
      },
    ];

    this.initMessages();
  }

  private initMessages() {
    let audits = [];

    if (this.isAudit) {
      audits = this.messages.map((msg) => {
        return {
          descriptionAndMessage: msg.statusDescription,
          statusCodeAndCategory: msg.statusCode,
          statusAndCode: msg.status,
          statusConditionDescription: msg.statusConditionDescription,
        };
      });
    } else {
      audits = this.messages.map((msg) => {
        return {
          descriptionAndMessage: msg.message,
          statusCodeAndCategory: msg.category,
          statusAndCode: msg.code,
          statusConditionDescription: msg.messageCategory,
        };
      });
    }
    this.messagesToDisplay = [...audits];
    this.statuses = [...new Set(audits.filter(a => !!a.statusAndCode).map(a => a.statusAndCode))];
    this.categories = [...new Set(audits.filter(a => !!a.statusConditionDescription).map(a => a.statusConditionDescription))];
  }
}
