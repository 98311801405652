<ng-container *ngIf="!showLoading; else loading">
  <div class="card-body chat-body" data-simplebar>
    <div class="chat-detail">
      <!-- Date Group -->
      <div class="chat-date-group"
        *ngFor="let messageGroup of conversation.conversationMessages | groupBy: 'groupDate' | sort: 'asc': 'groupDate'">
        <div class="chat-group">
          <span class="date-divider-line"></span>
          <span class="date-text">{{ formatGroupDate(messageGroup.key) }}</span>
          <span class="date-divider-line"></span>
        </div>
        <!-- Messages -->
        <div *ngFor="let message of messageGroup.value | sort: 'asc': 'dateInserted'"
          [ngClass]="{'outbound': message.direction === 'Outbound', 'inbound': message.direction !== 'Outbound'}"
          class="media chat-bubble">

          <div>
            <!-- Sender Name and Timestamp -->
            <div class="message-meta">
              <span *ngIf="message.direction !== 'Outbound' && conversation.chatMedium !== 'Sms'"
                class="message-sender">
                {{ message['fromFullName'] || message.senderName | phone }}
              </span>
              <span class="message-time">
                {{ message.dateInserted | date: 'MMM d, y, h:mm:ss a' }}
              </span>
            </div>

            <!-- Message Content -->
            <div class="message-content" [innerHtml]="message.body"></div>

            <!-- Media Attachments (if any) -->
            <div *ngIf="message.hasMediaFiles && message.mediaFiles?.length > 0" class="media-attachments">
              <div *ngFor="let mediaFile of message.mediaFiles">
                <i *ngIf="!mediaFile" class="d-block task-value fas fa-sync-alt fa-spin mx-1 mt-1"
                  style="font-size: 25px; margin-top: 4px; margin-bottom: 4px;"></i>
                <ng-container *ngIf="mediaFile">
                  <!-- Display the image if it's fully loaded and is of image type -->
                  <img *ngIf="mediaFile.changingThisBreaksApplicationSecurity.startsWith('data:image')"
                    [src]="mediaFile" class="media-image" alt="Image attachment" (click)="showPreview(mediaFile, 'image')">

                  <!-- Display the video if it's fully loaded and is of video type -->
                  <video *ngIf="mediaFile.changingThisBreaksApplicationSecurity.startsWith('data:video')"
                    [src]="mediaFile" controls class="media-video" (click)="showPreview(mediaFile, 'video')">
                  </video>

                  <!-- Overlay for viewing the image in larger view -->
                  <div class="overlay">
                    <a class="icon" title="View" (click)="viewImage(mediaFile)">
                      <i class="fa fa-eye"></i>
                    </a>
                  </div>
                  <div class="preview-display-area" *ngIf="previewAttachment">
                    <button class="close-preview" (click)="closePreview()">✖</button>
                    <img *ngIf="previewAttachment.type.startsWith('image')" [src]="previewAttachment.url" class="preview-image" alt="Attachment">
                    <video *ngIf="previewAttachment.type.startsWith('video')" [src]="previewAttachment.url" controls class="preview-video"></video>
                  </div>
                  
                </ng-container>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="conversation-end" style="height: 10px;"></div>
  </div>
</ng-container>


<ng-template #loading>
  <div class="card-body chat-body">
    <div class="widget-center">
      <div class="spinner-border thumb-md text-primary" role="status"></div>
    </div>
  </div>
</ng-template>

<div class="chat-footer card-footer">
  <div class="row footer-features">
    <!-- Message Input Area -->
    <div class="col-md-12">
      <div class="editor-container" [hidden]="conversation.chatMedium != 'Sms'">        
        <emoji-picker  class="emoji-button" (onEmojiSelected)="onEmojiSelected($event)"></emoji-picker>
        <textarea id="messageEditor" name="messageEditor" rows="1" placeholder="Type something here..."
          (input)="autoResize($event)" (keydown)="onKeyDownOnMessageEditor($event)" [(ngModel)]="messageToSend"
          #textArea></textarea>

        <button class="attachment-button" (click)="addAttachment.click()">📎</button>
        <button *ngIf="!isSendingMessage" class="send-button" (click)="sendMessage()"><i
            class="fa fa-paper-plane"></i></button>
        <button *ngIf="isSendingMessage" class="send-button loading"><i class="fa fa-sync-alt fa-spin"></i></button>
      </div> 
      <div class="editor-container" [hidden]="conversation.chatMedium == 'Sms'">        
        <message-editor-with-mentions style="width: 100%;" [hidden]="conversation.chatMedium == 'Sms'" #messageEditorWithMentions
          [mentionables]="usersThatCanBeMentioned" (messageChanged)="onMessageChanged($event)">
        </message-editor-with-mentions>
        
        <button *ngIf="!isSendingMessage" class="send-button" (click)="sendMessage()"><i
            class="fa fa-paper-plane"></i></button>
        <button *ngIf="isSendingMessage" class="send-button loading"><i class="fa fa-sync-alt fa-spin"></i></button>
      </div>
    </div>

    <!-- Attachments and Emoji Picker -->
    <div class="col-md-12 attachments-container">
      <div *ngIf="attachments.length" class="attachments-display">
        <div *ngFor="let attachment of attachments" class="attachment-item">
          <img *ngIf="attachment.file.type.startsWith('image')" [src]="attachment.base64 | safeUrl" alt="Attachment"
            class="attachment-thumbnail">
          <video *ngIf="attachment.file.type.startsWith('video')" [src]="attachment.base64 | safeUrl" class="attachment-thumbnail"
            controls></video>
          <i *ngIf="!attachment.file.type.startsWith('image') && !attachment.file.type.startsWith('video')"
            class="fa fa-file attachment-icon">
            <span class="attachment-name">{{ attachment.file.name }}</span>
          </i>
          <button class="remove-attachment" (click)="deleteAttachment(attachment)"><i class="fa fa-trash"></i></button>
        </div>
      </div>

      <input type="file" style="display:none" #addAttachment (change)="handleFileUpload($event)" multiple />
    </div>
  </div>
</div>


<!-- 
<div class="chat-footer card-footer">
  <div class="row footer-features">
    <div class="col-md-12">
      <textarea [hidden]="conversation.chatMedium != 'Sms'" id="messageEditor" name="messageEditor" rows="3" cols="30"
        style="resize: none; border: none" class="form-control" placeholder="Type something here..."
        (keydown)="onKeyDownOnMessageEditor($event)" [(ngModel)]="messageToSend" #textArea>
      </textarea>

      <message-editor-with-mentions [hidden]="conversation.chatMedium == 'Sms'" #messageEditorWithMentions
        [mentionables]="usersThatCanBeMentioned" (messageChanged)="onMessageChanged($event)">
      </message-editor-with-mentions>

    </div>
    <div class="col-md-12 row pb-2 ">
      <div class="d-flex flex-wrap col-sm-11" name="attachments" id="attachments">
        <div class="d-flex ms-2 mb-2" *ngFor="let attachment of attachments; let index = index">
          <img *ngIf="attachment.file.type.startsWith('image')" [src]="attachment.base64 | safeUrl" [height]="30"
            (error)="onImageError(attachment)" class=" rounded" />
          <video *ngIf="attachment.file.type.startsWith('video')" [src]="attachment.base64 | safeUrl" [height]="30"
            (error)="onImageError(attachment)" class=" rounded">
          </video>
          <i class="fa fa-trash-alt cursor text-danger mt-2 ms-1" (click)="deleteAttachment(attachment)"></i>
        </div>
      </div>
      <div class="bottom-features col-sm-1 d-flex w-100 justify-content-end">
        <input type="file" [accept]="accept" style="display:none" #addAttachment [(ngModel)]="fakeData"
          (change)="handleFileUpload($event)" multiple>

        <emoji-picker class="me-2" (onEmojiSelected)="onEmojiSelected($event)"></emoji-picker>
        <a href="javascript:void(0)" class="me-2" (click)="addAttachment.click()"
          *ngIf="conversation.chatMedium == 'Sms' "><i class="fas fa-paperclip"></i></a>
        <a href="javascript:void(0)" *ngIf="!isSendingMessage" (click)="sendMessage()"><i
            class="far fa-paper-plane"></i></a>
        <a href="javascript:void(0)" *ngIf="isSendingMessage"><i class="fas fa-sync-alt fa-spin"></i></a>
      </div>
    </div>
  </div>
  <em *ngIf="conversation.chatMedium === 'Sms'" class="text-end d-block fw-bold">Press Ctrl+Enter to add a new line</em>
</div> -->