import { Branch, ExternalCompany } from "src/app/models"

interface BranchModel extends Branch {
    externalCompany: ExternalCompany
}

export class PrequalGenerationSettings {
    prequalGenerationSettingsId: number
    documentTemplateId: number = null
    branchId: number
    branchModel: BranchModel
    enabledForInternalUsers: boolean
    enabledForAgents: boolean
    enabledForBorrowers: boolean
    companyId: number
    insertedBy: string
    dateInserted: string
    updatedBy: string
    dateUpdated: string
}