import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HazardInsuranceDetail, HousingExpense, SubjectProperty } from 'src/app/models';
import * as _ from 'lodash';
import { UrlaMortgage } from '../../models/urla-mortgage.model';

@Component({
  selector: 'supplemental-property-insurance-breakdown-dialog',
  templateUrl: 'supplemental-property-insurance-breakdown-dialog.component.html',
  styleUrls: ['../breakdown.scss']
})
export class SupplementalPropertyInsuranceBreakdownDialogComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this.subjectProperty = _.cloneDeep(mortgage.subjectProperty);
    this.proposedHousingExpenses = _.cloneDeep(mortgage.proposedHousingExpense);
    this.isBrokenDown = (mortgage.proposedHousingExpense.earthquakeInsurance && Number(mortgage.proposedHousingExpense.earthquakeInsurance) > 0) ||
      (mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance != null && Number(mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance) > 0 && (mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance != mortgage.proposedHousingExpense.supplementalPropertyInsurance)) ||
      (mortgage.proposedHousingExpense.floodInsurance != null && Number(mortgage.proposedHousingExpense.floodInsurance) > 0) ||
      (mortgage.proposedHousingExpense.hailInsurance != null && Number(mortgage.proposedHousingExpense.hailInsurance) > 0) ||
      (mortgage.proposedHousingExpense.volcanoInsurance != null && Number(mortgage.proposedHousingExpense.volcanoInsurance) > 0) ||
      (mortgage.proposedHousingExpense.windAndStormInsurance != null && Number(mortgage.proposedHousingExpense.windAndStormInsurance) > 0);

    this.associateInsuranceFieldsWithHazardInsuranceDetails();
  }

  isBrokenDown: boolean = false;

  protected floodInsurance: HazardInsuranceDetail;
  protected hailInsurance: HazardInsuranceDetail;
  protected earthquakeInsurance: HazardInsuranceDetail;
  protected volcanoInsurance: HazardInsuranceDetail;
  protected windAndStormInsurance: HazardInsuranceDetail;
  protected otherSupplementalPropertyInsurance: HazardInsuranceDetail;
  protected insuranceTypes: string[] = ['earthquakeInsurance', 'floodInsurance', 'hailInsurance', 'volcanoInsurance', 'windAndStormInsurance', 'otherSupplementalPropertyInsurance'];

  protected proposedHousingExpenses: HousingExpense;
  protected subjectProperty: SubjectProperty;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.fixExistingDates();
  }

  onOkClicked = () => {
    if (!this.isBrokenDown) {
      this.subjectProperty.hazardInsuranceDetails = [];
    }
    const subjectPropertyAndProposedHousingExpenses = new SubjectPropertyAndProposedHousingExpenses();
    subjectPropertyAndProposedHousingExpenses.proposedHousingExpense = this.proposedHousingExpenses;
    subjectPropertyAndProposedHousingExpenses.subjectProperty = this.subjectProperty;
    this.convertEditedDatesToISO();
    this.activeModal.close(subjectPropertyAndProposedHousingExpenses);
  }

  onCancelClicked = () => {
    this.activeModal.close();
  }

  onBreakdownItemChanged = () => {
    const sum: number =
      (this.proposedHousingExpenses.earthquakeInsurance != null ? Number(this.proposedHousingExpenses.earthquakeInsurance) : 0) +
      (this.proposedHousingExpenses.floodInsurance != null ? Number(this.proposedHousingExpenses.floodInsurance) : 0) +
      (this.proposedHousingExpenses.hailInsurance != null ? Number(this.proposedHousingExpenses.hailInsurance) : 0) +
      (this.proposedHousingExpenses.volcanoInsurance != null ? Number(this.proposedHousingExpenses.volcanoInsurance) : 0) +
      (this.proposedHousingExpenses.windAndStormInsurance != null ? Number(this.proposedHousingExpenses.windAndStormInsurance) : 0) +
      (this.proposedHousingExpenses.otherSupplementalPropertyInsurance != null ? Number(this.proposedHousingExpenses.otherSupplementalPropertyInsurance) : 0);
    this.proposedHousingExpenses.supplementalPropertyInsurance = sum;
  }

  onIsBrokenDownChanged = () => {
    this.proposedHousingExpenses.earthquakeInsurance = 0;
    this.proposedHousingExpenses.floodInsurance = 0;
    this.proposedHousingExpenses.hailInsurance = 0;
    this.proposedHousingExpenses.volcanoInsurance = 0;
    this.proposedHousingExpenses.windAndStormInsurance = 0;
    this.proposedHousingExpenses.otherSupplementalPropertyInsurance = 0;
    this.proposedHousingExpenses.supplementalPropertyInsurance = 0;

    if (!this.isBrokenDown) {
      this.earthquakeInsurance.expirationDate = null;
      this.earthquakeInsurance.nextPremiumDueDate = null;
      this.earthquakeInsurance.policyCancellationDate = null;
      this.earthquakeInsurance.policyIdentifier = null;

      this.floodInsurance.expirationDate = null;
      this.floodInsurance.nextPremiumDueDate = null;
      this.floodInsurance.policyCancellationDate = null;
      this.floodInsurance.policyIdentifier = null;

      this.hailInsurance.expirationDate = null;
      this.hailInsurance.nextPremiumDueDate = null;
      this.hailInsurance.policyCancellationDate = null;
      this.hailInsurance.policyIdentifier = null;

      this.volcanoInsurance.expirationDate = null;
      this.volcanoInsurance.nextPremiumDueDate = null;
      this.volcanoInsurance.policyCancellationDate = null;
      this.volcanoInsurance.policyIdentifier = null;

      this.windAndStormInsurance.expirationDate = null;
      this.windAndStormInsurance.nextPremiumDueDate = null;
      this.windAndStormInsurance.policyCancellationDate = null;
      this.windAndStormInsurance.policyIdentifier = null;

      this.otherSupplementalPropertyInsurance.expirationDate = null;
      this.otherSupplementalPropertyInsurance.nextPremiumDueDate = null;
      this.otherSupplementalPropertyInsurance.policyCancellationDate = null;
      this.otherSupplementalPropertyInsurance.policyIdentifier = null;
    }
  }

  onSupplementalPropertyInsuranceChanged = () => {
    if (!this.isBrokenDown) {
      this.proposedHousingExpenses.otherSupplementalPropertyInsurance = this.proposedHousingExpenses.supplementalPropertyInsurance;
    }
  }

  private associateInsuranceFieldsWithHazardInsuranceDetails = () => {
    this.floodInsurance = this.subjectProperty.hazardInsuranceDetails.find(hi => hi.coverageType === 'Flood');
    if (!this.floodInsurance) {
      this.floodInsurance = new HazardInsuranceDetail();
      this.floodInsurance.coverageType = 'Flood';
      this.subjectProperty.hazardInsuranceDetails.push(this.floodInsurance);
    }

    this.hailInsurance = this.subjectProperty.hazardInsuranceDetails.find(hi => hi.coverageType === 'Hail');
    if (!this.hailInsurance) {
      this.hailInsurance = new HazardInsuranceDetail();
      // this.hailInsurance.coverageType = 'Hail';
      this.subjectProperty.hazardInsuranceDetails.push(this.hailInsurance);
    }

    this.earthquakeInsurance = this.subjectProperty.hazardInsuranceDetails.find(hi => hi.coverageType === 'Earthquake');
    if (!this.earthquakeInsurance) {
      this.earthquakeInsurance = new HazardInsuranceDetail();
      this.earthquakeInsurance.coverageType = 'Earthquake';
      this.subjectProperty.hazardInsuranceDetails.push(this.earthquakeInsurance);
    }

    this.volcanoInsurance = this.subjectProperty.hazardInsuranceDetails.find(hi => hi.coverageType === 'Volcano');
    if (!this.volcanoInsurance) {
      this.volcanoInsurance = new HazardInsuranceDetail();
      this.volcanoInsurance.coverageType = 'Volcano';
      this.subjectProperty.hazardInsuranceDetails.push(this.volcanoInsurance);
    }

    this.windAndStormInsurance = this.subjectProperty.hazardInsuranceDetails.find(hi => hi.coverageType === 'Wind');
    if (!this.windAndStormInsurance) {
      this.windAndStormInsurance = new HazardInsuranceDetail();
      this.windAndStormInsurance.coverageType = 'Wind';
      this.subjectProperty.hazardInsuranceDetails.push(this.windAndStormInsurance);
    }

    this.otherSupplementalPropertyInsurance = this.subjectProperty.hazardInsuranceDetails.find(hi => hi.coverageType === 'Other');
    if (!this.otherSupplementalPropertyInsurance) {
      this.otherSupplementalPropertyInsurance = new HazardInsuranceDetail();
      this.otherSupplementalPropertyInsurance.coverageType = 'Other';
      this.subjectProperty.hazardInsuranceDetails.push(this.otherSupplementalPropertyInsurance);
    }
  }

  private fixExistingDates = () => {
    this.insuranceTypes.forEach(type => {
      if (this[type]['expirationDate']) {
        this[type]['expirationDate'] = new Date(this[type]['expirationDate']).toLocaleDateString("en-US");
      }

      if (this[type]['nextPremiumDueDate']) {
        this[type]['nextPremiumDueDate'] = new Date(this[type]['nextPremiumDueDate']).toLocaleDateString("en-US");
      }

      if (this[type]['policyCancellationDate']) {
        this[type]['policyCancellationDate'] = new Date(this[type]['policyCancellationDate']).toLocaleDateString("en-US");
      }
    })
  }

  private convertEditedDatesToISO = () => {
    this.insuranceTypes.forEach(type => {
      if (this[type]['expirationDate']) {
        this[type]['expirationDate'] = new Date(this[type]['expirationDate']).toISOString();
      }

      if (this[type]['nextPremiumDueDate']) {
        this[type]['nextPremiumDueDate'] = new Date(this[type]['nextPremiumDueDate']).toISOString();
      }

      if (this[type]['policyCancellationDate']) {
        this[type]['policyCancellationDate'] = new Date(this[type]['policyCancellationDate']).toISOString();
      }
    })
  }
}

export class SubjectPropertyAndProposedHousingExpenses {
  subjectProperty: SubjectProperty;
  proposedHousingExpense: HousingExpense;
}
