<div class="card">
  <div class="card-header">
    <h4 class="card-title">Address Info</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_address">Address</label>
        <address-input
          [name]="'wireReq_address'"
          [(ngModel)]="wireRequest.address"
          (addressChanged)="onAddressChanged($event)"
          [required]="true"
        ></address-input>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_city">City</label>
        <input
          id="wireReq_city"
          name="wireReq_city"
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': city && city.touched && city.invalid}"
          [(ngModel)]="wireRequest.city"
          #city="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_state">State</label>
        <select
          id="wireReq_state"
          name="wireReq_state"
          class="form-select"
          [ngClass]="{'is-invalid': state && state.touched && state.invalid}"
          [(ngModel)]="wireRequest.state"
          #state="ngModel"
          required
        >
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let state of states" [ngValue]="state.value">
            {{ state.name }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_zip">Zip Code</label>
        <zip-code-input
          [(ngModel)]="wireRequest.zip"
          [name]="'wireReq_zip'"
          [use9digits]="false"
          [required]="true"
        ></zip-code-input>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-2 mt-3">
        <label for="wireReq_county">County</label>
        <input
          id="wireReq_county"
          name="wireReq_county"
          type="text"
          class="form-control"
          [ngClass]="{'is-invalid': county && county.touched && county.invalid}"
          [(ngModel)]="wireRequest.county"
          #county="ngModel"
          required
        />
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
  </div>
</div>