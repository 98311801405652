import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { isArray } from 'lodash';
import { finalize } from 'rxjs';
import { Configuration, ThirdPartyCredential } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'mers',
  templateUrl: './mers.component.html',
  styleUrls: ['./mers.component.scss']
})
export class MersComponent implements OnInit {
  @ViewChild('mersCredsForm') mersCredsForm: NgForm | undefined;
  @Input() isMersEnabled: boolean;
  @Input() mersConfig: Configuration;
  @Input() mersCredential: ThirdPartyCredential;
  @Output() mersCredsUpdated: EventEmitter<ThirdPartyCredential> = new EventEmitter<ThirdPartyCredential>();

  isSaving: boolean;
  mersOrganizationId: string;
  lodasoftMersVendorId: string;

  constructor(private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService
  ) { }

  ngOnInit(): void {
    if (isArray(this.mersCredential?.thirdPartyKeyValuePairs)) {
      this.mersOrganizationId = this.mersCredential.thirdPartyKeyValuePairs.find(c => c.key === 'MersOrganizationId')?.value;
      this.lodasoftMersVendorId = this.mersCredential.thirdPartyKeyValuePairs.find(c => c.key === 'LodasoftMersVendorId')?.value;
    }
  }

  save(): void {
    this.mersCredsForm.form.markAllAsTouched();
    if (!this.mersCredsForm.form.valid) {
      return;
    }

    this.prepareCredsRequest();
    this.isSaving = true;
    this._systemLevelService.saveCredential(this.mersCredential)
      .pipe(finalize(() => {
        this.isSaving = false;
      })).subscribe({
        next: res => {
          this.mersCredsUpdated.emit(res);
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }

  private prepareCredsRequest() {
    if (this.mersCredential) {
      const matchingOrgIdRecord = this.mersCredential.thirdPartyKeyValuePairs.find(c => c.key === 'MersOrganizationId');
      if (matchingOrgIdRecord) {
        matchingOrgIdRecord.value = this.mersOrganizationId;
      } else {
        this.mersCredential.thirdPartyKeyValuePairs.push({
          key: 'MersOrganizationId',
          value: this.mersOrganizationId,
        });
      }
      const matchingLodaVendorId = this.mersCredential.thirdPartyKeyValuePairs.find(c => c.key === 'LodasoftMersVendorId');
      if (matchingLodaVendorId) {
        matchingLodaVendorId.value = this.lodasoftMersVendorId;
      } else {
        this.mersCredential.thirdPartyKeyValuePairs.push({
          key: 'LodasoftMersVendorId',
          value: this.lodasoftMersVendorId,
        });
      }
      return;
    }

    this.mersCredential = {
      credentialType: 'Mers',
      vendorName: 'Mers',
      thirdPartyKeyValuePairs: [{
        key: 'MersOrganizationId',
        value: this.mersOrganizationId,
      }, {
        key: 'LodasoftMersVendorId',
        value: this.lodasoftMersVendorId
      }],
      alias: '',
      url: '',
      userName: '',
      password: '',
    };
  }
}
