<form #importfannieForm="ngForm" novalidate id="importfannieForm" name="importfannieForm" action="#">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Please Enter Casefile ID.</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-3 row">
                        <label for="casefileId" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">
                            Casefile ID
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="casefileId" name="casefileId"
                                [ngClass]="{'is-invalid' : casefileIdField && casefileIdField.touched && casefileIdField.invalid}"
                                #casefileIdField="ngModel" [(ngModel)]="casefileId" required>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="enabledChannels.length > 0">
        <div class="card-header">
            <h4 class="card-title">Choose the Channel</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Channel</label>
                        <div class="col-sm-10">
                            <select class="form-select" [(ngModel)]="selectedChannel"
                                (change)="handleChannelChange(selectedChannel)"
                                [ngClass]="{'is-invalid' : channel && channel.touched && channel.invalid}"
                                #channel="ngModel" name="selectedChannel" required>
                                <option value=""> -- Select One -- </option>
                                <option *ngFor="let item of enabledChannels" value="{{item.name}}">
                                    {{item.name == 'Wholesale' ? 'Brokered' : item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="selectedChannel === 'Wholesale'">
                    <div class="mb-3 row">
                        <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Company</label>
                        <div class="col-sm-10">
                            <select class="form-select" [(ngModel)]="selectedCompanyId" name="selectedCompanyId"
                                [ngClass]="{'is-invalid' : companyField && companyField.touched && companyField.invalid}"
                                #companyField="ngModel" (change)="handleCompanyChange(selectedCompanyId)" required>
                                <option value=""> -- Select One -- </option>
                                <option *ngFor="let company of externalCompanies" value="{{company.externalCompanyId}}">
                                    {{company.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card" *ngIf="firstRole">
        <div class="card-header">
            <h4 class="card-title">Choose the Contacts</h4>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <role-based-internal-contacts 
                        [selectedCompanyId]="selectedCompanyId" 
                        [selectedChannel]="selectedChannel"
                        [roleUsers]="availableRoleUsers" 
                        [firstRole]="firstRole" 
                        [secondaryRoles]="avaliableSecondaryRoles"
                        [isTpoUser]="isTpoUser" 
                        [loadingUsers]="false" 
                        [primaryRoleUserId]="primaryRoleUserId"
                        [primaryContactIsRequired]="true"
                        (primaryRoleContactChanged)="primaryRoleUserId = $event;"
                        (secondaryRoleContactChanged)="tpoAlignmentRoleUsers = $event;"
                        >
                    </role-based-internal-contacts>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <button class="btn btn-primary" [disabled]="processingFannie" (click)="importFannie()">
            <span *ngIf="!processingFannie"> Import From Fannie Mae</span>
            <span *ngIf="processingFannie" class="spinner-border spinner-border-sm align-middle" role="status"></span>
            <span *ngIf="processingFannie"> Please wait...</span>
        </button>
    </div>
</form>
