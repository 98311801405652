import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { UserProfile } from 'src/app/models';
import { InternalContact } from '../models/internal-contact.model';

@Injectable({
    providedIn: 'root'
})
export class InternalContactsService {
    constructor(private readonly _dataService: DataService) { }

    getInternalContacts = (loanId: number): Observable<InternalContact[]> => {
        const url = `api/Loan/${loanId}/InternalContacts`;
        return this._dataService.get(url);
    }

    saveInternalContact = (loanId: number, data: InternalContact[], updateTasks: boolean, updateBranch: boolean): Observable<InternalContact> => {
        const url = `api/Loan/${loanId}/UpsertInternalContacts?updateTasks=${updateTasks}&updateBranch=${updateBranch}`;
        return this._dataService.post(url, data);
    };

    updateInternalContactForRole = (data: InternalContact, loanId: number, roleId: number, userId: string): Observable<InternalContact> => {
        const url = `api/Loan/${loanId}/UpdateInternalContactForRole/${roleId}/${userId}`;
        return this._dataService.post(url, data);
    };

    getExternalCompanyUsers = (companyId: number, externalCompanyId: number): Observable<UserProfile[]> => {
        const url = `api/Admin/AllUserModel?companyId=${companyId}&externalCompanyId=${externalCompanyId}`;
        return this._dataService.get(url);
    }

    getAvailableAlignments = (loanId: number, userId: string) => {
        const url = `api/Loan/${loanId}/GetAvailableAlignments?userId=${userId}`;
        return this._dataService.get(url);
    }

    getInternalContactsHistory = (loanId: number) => {
        const url = `api/Loan/${loanId}/InternalContacts/TrackingHistory`;
        return this._dataService.get(url);
    }
}
