import { NgModule } from '@angular/core';
import { LoanService } from 'src/app/services/loan/loan.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderVoiVoeComponent, TwnComponent, VoiVoeHistoryComponent } from '.';
import { TableModule } from 'primeng/table';
import { InviteAllowedProductsDialogComponent } from './components/order-voi-voe/invite-allowed-products-dialog/invite-allowed-products-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        TableModule
    ],
    declarations: [TwnComponent, OrderVoiVoeComponent, VoiVoeHistoryComponent, InviteAllowedProductsDialogComponent],
    exports: [TwnComponent, OrderVoiVoeComponent, VoiVoeHistoryComponent],
    providers: [LoanService]
})
export class TwnModule {
  constructor() {}
}
