import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { DtiDebt, DtiIncome, MortgageCalculationDetails, MortgageDtiCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { MortgageService } from 'src/app/services/mortgage.service';

@Component({
  selector: 'loan-dti-summary',
  templateUrl: 'loan-dti-summary.component.html',
  styleUrls: ['./loan-dti-summary.component.scss'],
})
export class LoanDtiSummaryComponent implements OnInit {

  @Input()
  set dti(value: MortgageDtiCalculationDetails) {
    if (value) {
      this._dtiDetails = value;
      this.initialize();
    }
  }

  @Input()
  mortgageId: number | null = null;

  @Output() dtiRecalculationStarted = new EventEmitter<any>();
  @Output() dtiRecalculationCompleted = new EventEmitter<MortgageDtiCalculationDetails>();
  @Output() closeRequested: EventEmitter<any> = new EventEmitter<any>();

  protected frontEndDti: number = 0;
  protected backEndDti: number = 0;
  protected incomeMonthlyTotal: number = 0;
  protected debtMonthlyTotal: number = 0;
  protected frontEndDebt: number = 0;
  protected frontEndDebtInfo: string | null = null;

  protected incomeItems: DtiIncome[] = [];
  protected debtItems: DtiDebt[] = [];

  protected recalculatingDti: boolean = false;

  protected shouldNotShowFrontEndExpensesInfoIcon: boolean = false;

  private _dtiDetails: MortgageDtiCalculationDetails = new MortgageDtiCalculationDetails();

  constructor(
    private readonly _currencyPipe: CurrencyPipe,
    private readonly _spinner: NgxSpinnerService,
    private readonly _mortgageService: MortgageService,
  ) { }

  ngOnInit(): void {
  }

  onCloseClicked = () => {
    this.closeRequested.emit();
  }

  protected onRefreshClicked = () => {
    this.recalculateDti();
  };

  private initialize = () => {
    if (this._dtiDetails.incomeDetail) {
      this.incomeItems = this._dtiDetails.incomeDetail;
      this.incomeItems.forEach(item => {
        item['hasChildItems'] = item.childDetail?.length > 0 && item.childDetail?.some(i => i.amount > 0 || i.info?.length > 0);
      })
    }
    if (this._dtiDetails.debtDetail) {
      this.debtItems = this._dtiDetails.debtDetail;
      this.debtItems.forEach(item => {
        item['hasChildItems'] = item.childDetail?.length > 0 && item.childDetail?.some(i => i.amount > 0 || i.info?.length > 0);
      })
    }

    this.frontEndDti = this._dtiDetails.frontEndDti;
    this.backEndDti = this._dtiDetails.backEndDti;
    this.incomeMonthlyTotal = this._dtiDetails.incomeMonthlyTotal;
    this.debtMonthlyTotal = this._dtiDetails.debtMonthlyTotal;
    this.frontEndDebt = this._dtiDetails.frontEndDebt || 0;
    this.frontEndDebtInfo = this._dtiDetails.frontEndDebtInfo;

    this.shouldNotShowFrontEndExpensesInfoIcon = (this._dtiDetails.frontEndDebt == null && this.frontEndDebtInfo == null);
  }

  private recalculateDti = () => {
    if (this.mortgageId) {
      this.dtiRecalculationStarted.emit();
      this.recalculatingDti = true;
      this._dtiDetails = null;
      this._spinner.show();
      const observer: Observer<MortgageCalculationDetails> = {
        next: (calculationDetails => {
          this._dtiDetails = calculationDetails.dti;
          this.initialize();
        }),
        error: (() => {

        }),
        complete: (() => {
          this._spinner.hide();
          this.recalculatingDti = false;
          this.dtiRecalculationCompleted.emit(this._dtiDetails)
        })
      }
      this._mortgageService.getMortgageCalculationDetails(this.mortgageId).subscribe(observer);
    }
  }

  /**
   * Generates the tooltip element for the front-end debt-to-income ratio.
   * @param useHtmlBreaks Whether to use HTML breaks in the tooltip instead of
   * newlines.
   * @returns The tooltip string. If {@link useHtmlBreaks} is true, the return
   * value contains HTML breaks and should be used as the inner HTML of an HTML
   * element.
   */
  protected getDtiRatioTooltipText(useHtmlBreaks: boolean = false): string {
    const frontEndDebt = this._currencyPipe.transform(this.frontEndDebt || 0);
    const frontEndDebtInfo = this.frontEndDebtInfo;
    const breakTag = useHtmlBreaks ? '<br />' : '\n';
    const expenses =
      [frontEndDebt, frontEndDebtInfo].filter(x => x != null).join(breakTag);

    return `Front-end Expenses: ${expenses}`;
  }
}
