import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { LosService } from '../../../services/los.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'los-loan-reference',
  templateUrl: 'los-loan-reference.component.html'
})

export class LosLoanReferenceComponent implements OnInit {
  @Input()
  applicationId: number;

  @Input()
  refNumber: string;

  isSaving = false;
  losLoanNumber: string;

  constructor(public activeModal: NgbActiveModal,
    private readonly _losService: LosService,
    private readonly _notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.losLoanNumber = _.clone(this.refNumber);
  }

  save() {
    this.isSaving = true;
    this._losService.reference(this.applicationId, this.losLoanNumber).subscribe((response) => {
      if (!this.losLoanNumber) {
        this._notificationService.showSuccess('Reference number cleared successfully.', 'Success');
      }
      else {
        this._notificationService.showSuccess('Reference number saved successfully.', 'Success');
      }
      this.isSaving = false;
      this.activeModal.close(response.refNumber);
    }, error => {
      this._notificationService.showError(error ? error.message : 'Couldn\'t save the refernce number.', 'Error');
      this.isSaving = false;
    });
  }
}
