import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Lender } from 'src/app/models/config/lender.model';

@Component({
  selector: 'lender-payoff-info',
  templateUrl: './lender-payoff-info.component.html',
  styleUrls: ['./lender-payoff-info.component.scss'],
  viewProviders: [formViewProvider],
})
export class LenderPayoffInfoComponent implements OnInit {

  @Input() lender: Lender;

  @Input()
  isReadOnly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
}
