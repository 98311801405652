<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        High Cost Mortgage Details
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #pointsAndFeesForm="ngForm" id="pointsAndFeesForm">
      <div class="row my-1">
        <div class="col-md-12">
          <ng-container *ngFor="
              let detail of regulationZDetail.highCostMortgageDetail;
              let i = index
            ">
            <div class="row d-flex justify-content-end">
              <div class="col-md-8 col-sm-6 form-group">
                <button type="button" class="btn btn-sm btn-outline-danger float-end"
                  (click)="deleteHighCostMortgageDetail(i)">
                  <i class="fas fa-trash-alt me-1"></i> Delete
                </button>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-3">
                <label for="excludedBonaFideDiscountPoints-{{i}}" title="Excluded Bona Fide Discount Points"> Excluded
                  Bona Fide
                  Discount Pts. </label>
                <percent-input [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].excludedBonaFideDiscountPoints"
                  name="excludedBonaFideDiscountPoints-{{i}}">
                </percent-input>
              </div>
              <div class="form-group col-md-3">
                <label for="totalAffiliateFees-{{i}}"> Total Affiliate Fees </label>
                <currency-input [allowNegative]="false"
                  [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].totalAffiliateFees"
                  name="totalAffiliateFees-{{i}}">
                </currency-input>
              </div>

              <div class="form-group col-md-3">
                <label for="totalPointsAndFees-{{i}}"> Total Points and Fees </label>
                <currency-input [allowNegative]="false"
                  [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].regulationZTotalPointsAndFeesAmount"
                  name="totalPointsAndFees-{{i}}">
                </currency-input>
              </div>
              <div class="form-group col-md-3">
                <label for="regulationZTotalLoanAmount-{{i}}"> Regulation Z Total Loan Amount </label>
                <currency-input [allowNegative]="false"
                  [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].regulationZTotalLoanAmount"
                  name="regulationZTotalLoanAmount-{{i}}">
                </currency-input>
              </div>
              <div class="form-group col-md-3">
                <label for="isHighCostLoan-{{i}}"> Is High Cost Loan </label>
                <select name="isHighCostLoan-{{i}}" id="isHighCostLoan" class="form-select"
                  [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].isHighCostLoan">
                  <option [ngValue]="null">-- Select One --</option>
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="averagePrimeOfferRate-{{i}}"> Avarage Prime Offer Rate </label>
                <percent-input name="averagePrimeOfferRate"
                  [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].averagePrimeOfferRate"></percent-input>
              </div>
              <div class="form-group col-md-3">
                <label for="preDiscountedInterestRate-{{i}}"> Pre Discounted Interest Rate </label>
                <currency-input [allowNegative]="false"
                  [(ngModel)]="regulationZDetail.highCostMortgageDetail[i].preDiscountedInterestRate"
                  name="preDiscountedInterestRate-{{i}}">
                </currency-input>
              </div>
            </div>
            <hr class="mt-0" *ngIf="regulationZDetail.highCostMortgageDetail.length > 1">
          </ng-container>
          <div class="row mt-3">
            <div class="form-group">
              <button type="button" class="btn btn-sm btn-outline-primary" (click)="addHighCostMortgageDetail()">
                <i class="fas fa-plus me-1"></i>
                Add </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
