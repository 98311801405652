<form class="form-horizontal" #createFeeForm="ngForm" novalidate id="createFeeForm" name="createFeeForm">
  <div class="modal-header">
    <h6 class="modal-title">{{title}}</h6>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="allowFreeformFeeEntry">
      <div class="mb-3">
        <label class="form-label" for="hudNumber">Hud No</label>
        <input *ngIf="!restrictHudNumberEntryBySectionType"
          class="form-control"
          type="text"
          id="hudNumber"
          name="hudNumber"
          #hudNumberInput="ngModel"
          [(ngModel)]="fee.hudNumber"
          autocomplete="off"
          required
          (keyup.enter)="save()"
          (ngModelChange)="onHudNumberChanged()"
          [ngClass]="{'is-invalid' : hudNumberInput && hudNumberInput.touched && (hudNumberInput.invalid || isExistHud)}" [disabled]="hudNumberDisabled">
        <input *ngIf="restrictHudNumberEntryBySectionType"
          class="form-control"
          type="text"
          id="hudNumber"
          name="hudNumber"
          #hudNumberInput="ngModel"
          [(ngModel)]="fee.hudNumber"
          (ngModelChange)="onHudNumberChanged()"
          autocomplete="off"
          minlength="{{hudNumbeRestrictionMaskConfig.minlength}}"
          [mask]="hudNumbeRestrictionMaskConfig.mask"
          [patterns]="hudNumbeRestrictionMaskConfig.patterns"
          [prefix]="hudNumbeRestrictionMaskConfig.prefix"
          [showMaskTyped]="true"
          [dropSpecialCharacters]="false"
          (keyup.enter)="save()"
               [ngClass]="{'is-invalid' : hudNumberInput && hudNumberInput.touched && (hudNumberInput.invalid || isExistHud)}" [disabled]="hudNumberDisabled">
        <div class="invalid-feedback" *ngIf="hudNumberInput?.errors['required'] || hudNumberInput?.errors['minlength']">Please enter Hud No</div>
        <div class="invalid-feedback" *ngIf="isExistHud">There is an existing fee with the same HUD#</div>
      </div>
      <div class="mb-3">
        <label class="form-label" for="feeName">Fee Name</label>
        <input class="form-control" type="text" id="feeName" name="feeName" #feeNameInput="ngModel" [(ngModel)]="fee.feeName" autocomplete="off" required
                (keyup.enter)="save()"
               [ngClass]="{'is-invalid' : feeNameInput && feeNameInput.touched && feeNameInput.invalid}">
        <div class="invalid-feedback">Please enter Fee Name</div>
      </div>
    </div>
    <div *ngIf="!allowFreeformFeeEntry">
      <div class="mb-3">
        <label class="form-label" for="hudNumber">Fee Type</label>
        <select class="form-select" [(ngModel)]="feeType" #hudNumberSelect="ngModel" name="hudNumberSelect" required
                [ngClass]="{'is-invalid' : hudNumberSelect && hudNumberSelect.touched && hudNumberSelect.invalid}"
                (ngModelChange)="onSelectedFeeTypeChanged()">
          <option value="">--Select--</option>
          <option *ngFor="let feeType of availableFeeTypes" [ngValue]="feeType.value">{{feeType.name}}</option>
        </select>
        <div class="invalid-feedback">Please select a Fee type</div>
      </div>
      <div class="mb-3">
        <label class="form-label" for="feeName">Fee Name</label>
        <input class="form-control" type="text" id="feeName" name="feeName" #feeNameInput="ngModel" [(ngModel)]="fee.feeName" autocomplete="off" required
                (keyup.enter)="save()"
               [ngClass]="{'is-invalid' : feeNameInput && feeNameInput.touched && feeNameInput.invalid}">
        <div class="invalid-feedback">Please enter Fee name</div>
      </div>
      <!--
      <div class="mb-3">
        <label class="form-label" for="feeName">Fee Name</label>
        <select class="form-select" [(ngModel)]="fee.feeName" #feeNameSelect="ngModel" name="feeNameSelect" required
                [ngClass]="{'is-invalid' : feeNameSelect && feeNameSelect.touched && feeNameSelect.invalid}">
          <option value="">--Select--</option>
        </select>
        <div class="invalid-feedback">Please enter Fee Name</div>
      </div>
      -->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="activeModal.dismiss()">Close</button>
    <button type="button" class="btn btn-primary" (click)="onOkClicked()">OK</button>
  </div>
</form>
