<ng-container *ngIf="nextStatuses; else loading">
  <div class="row mb-4" *ngIf="application">
    <div class="col-12 mb-2">
      <select
        class="form-select"
        name="nextStatus"
        aria-label="Next statuses"
        [disabled]="isSuspenseSubStatus"
        [(ngModel)]="application.loanStatusId"
      >
        <option
          [ngValue]="status.loanStatusId"
          *ngFor="let status of nextStatuses"
        >
          {{ status.loanStatusName }}
        </option>
      </select>
    </div>

    <div class="col-12">
      <select
        class="form-select"
        name="subStatus"
        aria-label="Sub statuses"
        [(ngModel)]="application.subStatusId"
        (ngModelChange)="onSubStatusChanged()"
      >
        <option [ngValue]="undefined">--Select One--</option>
        <option
          [ngValue]="status.subStatusId"
          *ngFor="let status of subStatuses"
        >
          {{ status.subStatusName }}
        </option>
      </select>
    </div>
  </div>
  <div>
    <button
      class="btn"
      [ngClass]="{
        'btn-outline-primary': !isEmailSmsPaused,
        'btn-danger': isEmailSmsPaused
      }"
      type="button"
      [disabled]="isPausingEmailAndSms"
      (click)="onPauseOrUnPauseEmailAndSmsClicked()"
    >
      <span
        *ngIf="isPausingEmailAndSms"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {{
        isPausingEmailAndSms
          ? 'Please wait...'
          : !isEmailSmsPaused
          ? 'Pause All E-mail/SMS'
          : 'Un-Pause All E-mail/SMS'
      }}
    </button>
    <button
      class="btn btn-primary float-end"
      type="button"
      [disabled]="isSavingStatus"
      (click)="onSaveClicked()"
    >
      <span
        *ngIf="isSavingStatus"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {{ isSavingStatus ? 'Please wait...' : 'Save' }}
    </button>
  </div>
</ng-container>
<ng-template #loading>
  <loading-indicator [loadingMessage]="''"></loading-indicator>
</ng-template>
