import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import {
  LdeAppOpreationResult,
  SearchResponseItem,
  LdeTransactionHistory,
  LdeVendor,
  LdeProduct,
  LdeOptionModel,
  LosCredentialsArray,
  LdeCredential,
} from '../models';
import { SyncLodaDoc } from '../modules/loan-docs/models/sync-loan-docs/sync-loda-doc-with-los-lde.model';
import { LdeCondition } from '../models/lde/lde-condition.model';

@Injectable()
export class LdeService {
  constructor(private readonly _dataService: DataService) {}

  private filterCredentials(allowedFeatures?: Array<string>) {
    return map((credentials: any) => {
      if (!allowedFeatures) return credentials;

      const filteredCredentials = [];

      credentials.forEach(credential => {
        if (this.featureExists(credential, allowedFeatures)) {
          filteredCredentials.push(credential);
        }
      });

      return filteredCredentials;
    });
  }

  private featureExists(credential: any, allowedFeatures: Array<string>) {
    if (!credential.vendorFeatures) return false;
    return credential.vendorFeatures.some(cF => allowedFeatures.indexOf(cF) > -1);
  }

  getLdeVendors = (): Observable<any> => {
    return this._dataService.get(`api/Admin/LosConfig/losVendors?deCategoryFilter=LDE`);
  }

  getLdeCredentials({ forConfig, allowedFeatures }: {
    forConfig?: boolean;
    allowedFeatures?: Array<string>;
  }): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/admin/losConfig/losVendors/credentials?forConfig=${forConfig ? 'true' : 'false'}&deCategoryFilter=LDE`)
      .pipe(this.filterCredentials(allowedFeatures));
  }

  getLdeCredentialsForLoan(applicationId: number): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/Loan/LDE/losVendors/${applicationId}`)
  }

  getLosCredentialsForLinkLoanWithLde(applicationId: number): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/Loan/LDE/link/losVendors/${applicationId}`)
  }

  searchLde(filterUrl: string): Observable<SearchResponseItem> {
    const url = 'api/Loan/LDE/search/' + filterUrl;
    return this._dataService.get(url);
  }

  getTransactionHistory = (applicationId: number): Observable<LdeTransactionHistory> => {
    const url = `api/loan/lde/info/${applicationId}`;
    return this._dataService.get(url);
  }

  link = (applicationId: number, credentialId: number, ldeIdentifier: string, refNumber: string): Observable<LdeAppOpreationResult> => {
    const url = `api/Loan/LDE/link/${applicationId}/${credentialId}?ldeIdentifier=${ldeIdentifier}&ldeRefNumber=${refNumber}`;
    return this._dataService.post(url, {});
  }

  unlink = (applicationId: number) => {
    const url = `api/Loan/lde/unlink/${applicationId}`;
    return this._dataService.post(url, {});
  }

  reference = (applicationId: number, refNumber: string): Observable<LdeAppOpreationResult> => {
    const url = `api/Loan/LDE/reference/${applicationId}?ldeRefNumber=${refNumber}`;
    return this._dataService.post(url, {});
  }

  getLdeOptions = (applicationId: number, credentialId?: number): Observable<LdeOptionModel> => {
    const url = `api/loan/lde/create/options/${applicationId}/${credentialId}`;
    return this._dataService.get(url);
  }

  getSyncDocs = (applicationId: number, type: string): Observable<SyncLodaDoc[]> => {
    const url = `api/loan/lde/docSync/${type}/${applicationId}`;
    return this._dataService.get(url);
  }

  create = (applicationId: number, useCredntialId: number, data: any): Observable<LdeAppOpreationResult> => {
    const url = `api/Loan/LDE/create/${applicationId}/${useCredntialId}`;
    return this._dataService.post(url, data);
  }

  pullFromLde = (applicationId: number) => {
    const url = `api/Loan/LDE/pull/${applicationId}`;
    return this._dataService.post(url, {});
  }

  pushToLde = (applicationId: number) => {
    const url = `api/Loan/LDE/push/${applicationId}`;
    return this._dataService.post(url, {});
  }

  syncDirection = (applicationId: number, ldeSyncDir: string) => {
    const url = `api/Loan/LDE/info/${applicationId}/syncDir/${ldeSyncDir}`;
    return this._dataService.post(url, {});
  }

  getLdeVendorProducts = (ldeVendor: LdeVendor): Observable<LdeProduct[]> => {
    const url = `api/pricing/ldevendor/${ldeVendor}/products`;
    return this._dataService.get(url);
  }

  getLdeConditions = (applicationId: number): Observable<LdeCondition[]> => {
    const url = `api/Loan/LDE/conditions/${applicationId}`;
    return this._dataService.get(url);
  }

  getLdeConditionsUsingCredentialId = (ldeCredentialId: number, ldeIdentifier: string): Observable<LdeCondition[]> => {
    const url = `api/Loan/LDE/conditions/${ldeCredentialId}/${ldeIdentifier}`;
    return this._dataService.get(url);
  }

  getLdeVendorsForImportConditions = (applicationId: number): Observable<LdeCredential[]> => {
    const url = `api/Loan/LDE/conditions/losVendors/${applicationId}`;
    return this._dataService.get(url);
  }
}
