import { filter, interval, Observable, take, throwError, timeout } from 'rxjs';
import { map } from 'rxjs/operators';

export module DrawerUtils {

  /**
   * Finds and returns the open drawer, if any.
   */
  export function getOpenedDrawer(): HTMLElement | undefined {
    const drawers = Array.from(
      document.getElementsByTagName('drawer'),
    ) as HTMLElement[];

    return drawers.find((e) => {
      const body = e.firstElementChild as HTMLElement;

      const { style } = body;

      const width = cssUnitToNumber(style.width);
      const right = cssUnitToNumber(style.right);
      if (width == null || right == null) {
        return false;
      }

      // FIXME: This will not work correctly if one drawer is opening and the
      //  other is closing at the same time.
      return Math.abs(right) < width;
    });
  }

  export function getHeight(el: HTMLElement) {
    return el.offsetHeight;
  }

  function getNonCommentChildren(el: Element) {
    return Array.from(el.childNodes)
      .filter((e) => e.nodeType !== Node.COMMENT_NODE);
  }

  function getEmptyChildren(body: HTMLElement): HTMLElement[] {
    return Array.from(body.children)
      .filter((e) => getNonCommentChildren(e).length === 0) as
      HTMLElement[];
  }

  export function removeEmptyChildren(body: HTMLElement): void {
    getEmptyChildren(body).forEach((e) => e.remove());
  }

  export function cssUnitToNumber(style: string | undefined): number | undefined {
    const value = style.match(/-?\d+/)?.[0];
    return value != null ? Number.parseInt(value) : undefined;
  }

  export function drawerIsReady$(): Observable<void> {
    return interval(100).pipe(
      filter(() => {
        const drawer = getOpenedDrawer();
        const body = drawer
          ?.querySelector(':scope > *') as HTMLElement | undefined;
        return body?.offsetHeight > 0;
      }),
      take(1),
      map(() => void 0),
      timeout({
        each: 1000,
        with: () => throwError(
          () => new Error(
            'The drawer table timed out to become ready.',
          ),
        ),
      }),
    );
  }

  export function calculateHeaderAndFooterHeight(body: HTMLElement): number {
    // Even if they are empty, the heights of these two are taken into account.
    const header = body.querySelector('.notification-header');
    const footer = body.querySelector('.notification-footer');

    const children = [header, footer].filter(Boolean);

    // Total height of all
    return children.map(getHeight)
      .reduce((acc, num) => acc + num, 0);
  }
}
