
<form #sendSmsForm="ngForm" ngbAutofocus>
  <div class="mb-3">
    <label class="control-label" for="recipientEmailAddresses">To</label>
    <select class="form-select" name="to" id="to" [(ngModel)]="toPhoneNumber" required #sendTo="ngModel"
      [ngClass]="{ 'is-invalid' : sendTo && sendTo.touched && sendTo.invalid }"
      placeholder="Select a Borrower">
      <option value="">--Select a Borrower--</option>
      <option [value]="phoneNumber.number" *ngFor="let phoneNumber of phoneNumbers">
        {{phoneNumber.borrower}} - {{phoneNumber.type}}: {{phoneNumber.number | phone}}</option>
    </select>
  </div>
  <div class="mb-3">
    <label class="control-label" for="body">Body</label>
    <a class="float-end" (click)="switch()" [hidden]="isShowOnlyMMS">Switch to {{isMMS ? 'SMS' : 'MMS' }}</a>
    <div class="d-flex" *ngIf="!isMMS">
      <div class="flex-grow-1">
        <textarea required name="body" #bodyToSend="ngModel" class="form-control"
          [ngClass]="{ 'is-invalid' : bodyToSend && bodyToSend.touched && bodyToSend.invalid }"
          cols="12" rows="7" style="resize: none;" id="body" [(ngModel)]="body">
        </textarea>
        <div class="invalid-feedback">Body is required.</div>
      </div>
    </div>
    
    <div *ngIf="isMMS">
      <send-mms [type]="'image'" [(ngModel)]="body" [allowedTypes]="['image']" name="mms" [attachments]="attachments"></send-mms>
    </div>

  </div>
  <div class="custom-control custom-switch switch-success">
    <input type="checkbox" class="custom-control-input" id="appendSmsSignature" name="appendSmsSignature" [(ngModel)]="appendSmsSignature">
    <label style="padding-left: 4px;" class="form-label"
      for="appendSmsSignature">Append SMS Signature</label>
  </div>
</form>
