<ng-template #pageActionBarLeft>
    <bread-crum></bread-crum>
</ng-template>

<ng-template #pageActionBarRight>
    <button *ngIf="allowAddNew" class="btn btn-primary" (click)="showUpsertDialog()">
        <i class="fa fa-plus"></i>
        Add New
    </button>
</ng-template>

<ng-template #mainContent>
    <form #agentTypeForm="ngForm">
        <p-table #adverseTable [columns]="adverseColumns" columnResizeMode="fit" [value]="adverseReasons"
            [paginator]="true" [rows]="25" [resizableColumns]="true" responsiveLayout="scroll"
            name="adverse-reasons-list-table-v2" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true"
            [showCurrentPageReport]="true" scrollable="true" [scrollHeight]="scrollHeight"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [selectionPageOnly]="true" sortMode="single"
            [globalFilterFields]="['adverseReasonName']">
            <ng-template pTemplate="caption">
                <grid-top-action-bar #gridHeaderActionBar [options]="actionButtonOptions"></grid-top-action-bar>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="justify-content-left">
                        <span>Action</span>
                    </th>
                    <th [class]="col.cssClass" *ngFor="let col of adverseColumns" [pSortableColumn]="col.field"
                        [pSortableColumnDisabled]="!col.sortable">
                        <div *ngIf="col.field !== 'order'">
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </div>
                        <div *ngIf="col.field === 'order'">
                            <button *ngIf="allowAddNew" class="btn link-primary p-0 m-0 sort-col-header-text"
                                (click)="openOrderDrawer();$event.stopPropagation()">Sort</button>
                            <span *ngIf="!allowAddNew">Sort</span>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
                <tr>
                    <td class="width-100p">
                        <span class="d-flex align-items-center justify-content-center">
                            <i class="fa fa-pencil-alt cursor text-primary" (click)="showUpsertDialog(row)"
                                title="Edit Role"></i>
                            <span class="mx-2"> | </span>
                            <i class="fa fa-trash-alt cursor text-danger" (click)="showDeleteDialog(row)"
                                title="Delete Role"></i>
                        </span>
                    </td>
                    <td>{{ row.adverseReasonName }}</td>
                    <td>{{ row.order }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="text-center"> There are no adverse reasons. </td>
                </tr>
            </ng-template>
        </p-table>
    </form>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
    [pageActionBarLeftContentTemplate]="pageActionBarLeft" [pageActionBarRightContentTemplate]="pageActionBarRight">
</admin-page-template>

<drawer #adverseReasonOrderDrawer [title]="'Adverse Reason Order'" [name]="'adverseReasonOrderDrawer'"
    [templateRef]="adverseReasonOrderRef" [options]="orderDrawerOptions">
</drawer>
<ng-template #adverseReasonOrderRef>
    <rearrange-order #adverseReasonOrder [itemList]="itemsToOrder" (close)="closeOrderDrawer($event)"
        (save)="onOrderDrawerSave($event)"></rearrange-order>
</ng-template>
<drawer #upsertAdverseReasonDrawer
    [title]="currentAdverseReason?.adverseReasonId ? 'Edit Adverse Reason' : 'Add Adverse Reason'"
    [name]="'upsertAdverseReasonDrawer'" [templateRef]="upsertAdverseReasonRef"
    [options]="upsertAdverseReasonDrawerOptions">
</drawer>
<ng-template #upsertAdverseReasonRef>
    <upsert-adverse-reason [adverseReason]="currentAdverseReason" [companyId]="companyId"
        (close)="onUpsertAdverseReasonDrawerClose($event)"></upsert-adverse-reason>
</ng-template>
