import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assign, isArray } from 'lodash';
import { AddAppraisalNote, AppraisalNote } from 'src/app/models/appraisal/appraisal-note.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { AddAppraisalNoteDialogComponent } from '../add-appraisal-note-dialog/add-appraisal-note-dialog.component';

@Component({
  selector: 'appraisal-notes',
  templateUrl: './appraisal-notes.component.html',
  styleUrls: ['./appraisal-notes.component.scss']
})
export class AppraisalNotesComponent implements OnInit {
  @Input() noteDeliveryGroups: Array<EnumerationItem>
  @Input() notes: Array<AppraisalNote>
  @Input() isReadonly: boolean = false;
  @Output() updateNotes = new EventEmitter();

  constructor(private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  openAddNoteDialog(selectedNote: AddAppraisalNote = null) {
    const modalRef = this._modalService.open(AddAppraisalNoteDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.noteDeliveryGroups = this.noteDeliveryGroups;
    modalRef.componentInstance.selectedNote = selectedNote;
    modalRef.result.then((newNote: AppraisalNote) => {
      const matchingNote = this.notes.find((note: AppraisalNote) => note.thirdPartyNoteId === newNote.thirdPartyNoteId);
      if (!matchingNote) {
        this.notes.push(newNote);
      } else {
        assign(matchingNote, newNote);
      }
      this.updateNotes.emit(this.notes);
    }, () => { });
  }

  editNote(noteId: string) {
    const matchingNote = this.notes.find(note => note.thirdPartyNoteId === noteId);
    if (!matchingNote) {
      console.error('could not find the matching note record.');
      return;
    }
    let deliveryGroups: EnumerationItem[] = [];
    if (matchingNote.deliveryGroups) {
      deliveryGroups = matchingNote.deliveryGroups.split(',').map(item => ({
        name: item,
        value: item
      }));
    }
    this.openAddNoteDialog({
      id: matchingNote.thirdPartyNoteId,
      note: matchingNote.note,
      deliveryGroups: deliveryGroups,
      deliveryGroupsCsv: matchingNote.deliveryGroups
    });
  }

  removeNote(noteId: string) {
    const index = this.notes.findIndex(note => note.thirdPartyNoteId === noteId);
    this.notes.splice(index, 1);
    this.updateNotes.emit(this.notes);
  }
}
