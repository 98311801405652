import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { AlertModel, AlertType } from '../models/alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  get events(): BehaviorSubject<AlertsCreatedEvent> {
    return this._eventTracker;
  }

  publish(event: AlertsCreatedEvent): void {
    this._eventTracker.next(event);
  }

  private _eventTracker = new BehaviorSubject<AlertsCreatedEvent>(null);

  constructor(private readonly _dataService: DataService) {}

  getAlertsByType = (type: number): Observable<AlertModel[]> => {
    const url = `api/common/GetAlerts/${type}`;
    return this._dataService.get(url);
  };

  clearAlert = (alertId: number) => {
    const url = `api/Common/ClearAlert/${alertId}`;
    return this._dataService.post(url, {});
  };

  clearAllGeneralAlerts = () => {
    const url = `api/Common/ClearAllAlerts/ClearAllGeneralAlerts`;
    return this._dataService.post(url, {});
  }

  clearAllTaskStatusAlerts = () => {
    const url = `api/Common/ClearAllAlerts/TaskStatusAlert`;
    return this._dataService.post(url, {});
  }

  clearAllBorrowerMessageAlerts = () => {
    const url = `api/Common/ClearAllAlerts/BorrowerMessageAlert`;
    return this._dataService.post(url, {});
  }

  clearAllMentions = () => {
    const url = `api/Common/ClearAllAlerts/Mention`;
    return this._dataService.post(url, {});
  }

  setEmailPiorityToRetry = (id: number) => {
    const url = `api/Email/SetEmailPriorityToRetry/${id}`;
    return this._dataService.post(url, {});
  };

  clearAllByType = (type: AlertType) => {
    const url = `api/Common/ClearAllAlerts/${type}`;
    return this._dataService.post(url, {});
  }
}

export class AlertsCreatedEvent {
  alerts: AlertModel[] = [];
}

//api/Common/ClearAllAlerts/ClearAllGeneralAlerts
