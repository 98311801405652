<div class="modal-header">
  <h5 class="modal-title"> Recording Video </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <video-recorder (videoSelectedForUse)="onVideoSelectedForUse($event)"></video-recorder>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
  </button>
</div>
