export class DashboardTaskFilters {
    lpValues: string[];
    lsValues: string[];
    ltValues: string[];
    impersonateUserId: string;
    lenderId: string;
    branchId?: string;

    constructor(lpValues: string[], lsValues: string[], ltValues: string[], impersonateUserId: string, lenderId: string, branchId: string) {
        this.lpValues= lpValues;
        this.lsValues = lsValues;
        this.ltValues = ltValues;
        this.impersonateUserId = impersonateUserId;
        this.lenderId = lenderId;
        this.branchId = branchId
    }
}
