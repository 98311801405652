<ng-container *ngIf="!hostedInModal">
  <div class="card" *ngIf="application || loanDocTask; else loading">
    <div class="card-body">
      <ng-container *ngTemplateOutlet="cocReasons"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="hostedInModal">
  <ng-container *ngIf="application || loanDocTask; else loading">
    <ng-container *ngTemplateOutlet="cocReasons"></ng-container>
  </ng-container>
</ng-container>

<ng-template #cocReasons>
  <div class="card">
    <p-table #dt1 [value]="changeOfCircumstances" styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="caption" *ngIf="(!loanDocTask && !readonly) || loanDocTask?.taskStatus == 'Pending'">
        <div class="d-flex justify-content-end">
          <span>
            <button type="button" class="btn btn-primary" (click)="onAddNewClicked()">
              <i class="fas fa-plus"></i> Add New
            </button>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5rem">
            Actions
          </th>
          <th>
            Status
          </th>
          <th>
            Disclosure Reason
          </th>
          <th>
            Changes Received Date
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <div class="d-flex align-items-center justify-content-center">
              <span>
                <i *ngIf="!readonly && rowData.changeOfCircumstanceStatus === 'Requested'"
                  class="fa fa-pencil-alt cursor text-info" (click)="onEditClicked(rowData)" title="Edit"></i>

                <i *ngIf="readonly || rowData.changeOfCircumstanceStatus !== 'Requested'"
                  class="fas fa-eye cursor text-info" (click)="onEditClicked(rowData)" title="Preview"></i>
              </span>
              <span *ngIf="!readonly && rowData.changeOfCircumstanceStatus === 'Requested'">
                <span class="mx-1"> | </span>
                <i class="fa fa-trash-alt cursor text-danger" (click)="onDeleteClicked(rowData.changeOfCircumstanceId)"
                  title="Delete" id="btnMcrSummaryDelete"></i>
              </span>
            </div>
          </td>
          <td>
            <ng-container *ngIf="!!loanDocTask; else status">
              <button class="btn btn-xs dropdown-toggle fixed-pos-ddl-btn" [ngClass]="{
                'btn-success': rowData.changeOfCircumstanceStatus == 'Approved',
                'btn-danger': rowData.changeOfCircumstanceStatus == 'Rejected' || rowData.changeOfCircumstanceStatus == 'Cancelled',
                'btn-primary': rowData.changeOfCircumstanceStatus == 'Requested',
                'btn-info': rowData.changeOfCircumstanceStatus == 'Disclosed'
              }" data-bs-toggle="dropdown"
                data-offset="-90">
                {{ rowData.changeOfCircumstanceStatus }}<i class="mdi mdi-chevron-down"></i>
              </button>

              <div class="dropdown-menu fixed-pos-ddl-menu">
                <div class="list-group" *ngIf="loanDocTask?.taskStatus == 'ReviewReady'">
                  <a class="list-group-item list-group-item-action" *ngIf="rowData.changeOfCircumstanceStatus != 'Approved'"
                    (click)="setStatus('Approved', rowData)">Approved</a>
                  <a class="list-group-item list-group-item-action" *ngIf="rowData.changeOfCircumstanceStatus != 'Rejected'"
                    (click)="setStatus('Rejected', rowData)">Rejected</a>
                  <a class="list-group-item list-group-item-action" *ngIf="rowData.changeOfCircumstanceStatus != 'Cancelled'"
                    (click)="setStatus('Cancelled', rowData)">Cancelled</a>
                </div>
                <div class="list-group" *ngIf="loanDocTask?.taskStatus == 'Pending'">
                  <a class="list-group-item list-group-item-action" *ngIf="rowData.changeOfCircumstanceStatus != 'Cancelled'"
                    (click)="setStatus('Cancelled', rowData)">Cancelled</a>
                </div>
              </div>
            </ng-container>
            <ng-template #status>
              {{ rowData.changeOfCircumstanceStatus }}
            </ng-template>
          </td>
          <td> {{ rowData.disclosureReason | cocReason }} </td>
          <td> {{ rowData.changesOfCircumstanceReceivedDate | date:'short' }} </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" *ngIf="!!loanDocTask">
        <tr>
          <th colspan="4">
            <div class="m-2 text-end">
              <button type="button" class="btn btn-primary" [disabled]="!submitButtonEnabled"
                (click)="onSubmitCoCsClicked()">
                Submit CoCs
              </button>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4" class="text-center">
            There are no CoC Reasons
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>

<ng-template #loading>
  <loading-indicator [loadingMessage]="'Loading change of circumstance / reasons ...'"></loading-indicator>
</ng-template>

<drawer [title]="'Change Of Circumstance'" [name]="drawerName" [templateRef]="cocDetailsRef"
  [options]="cocDetailsDrawerOptions" (closed)="onCocDetailsDrawerClosed()"></drawer>
<ng-template #cocDetailsRef>
  <coc-details *ngIf="selectedCocDetails" [cocDetails]="selectedCocDetails" [loanDocTask]="loanDocTask"
    [applicationId]="appId" [mortgageId]="mortgageId" [urlaFieldsConfig]="urlaFieldsConfig" [readonly]="readonly"
    (onCocDetailsSaved)="onCocDetailsSaved($event)" (onCocDetailsClosed)="onCocDetailsDrawerClosed()"></coc-details>
</ng-template>
