import { FreddieMacUnderwriteRequest } from 'src/app/modules/loan-services/aus/models/freddie-mac-underwrite-request.model';
import { CaseStateType, CreditVendorEnum } from '..';

interface LpaError {
  readonly category: string;
  readonly code: string;
  readonly message: string;
}

export interface LpaHistoryDocument {
  readonly lpaHistoryDocumentId: number;
  readonly lpaHistoryId: number;
  readonly fileName: string;
  readonly mimeType: string;
  readonly data: Array<number>; // byte

  readonly companyId: number;
  readonly insertedBy: string;
  readonly dateInserted: Date;
  readonly updatedBy: string;
  readonly dateUpdated: Date;
}

export class LpaHistory {
  constructor() {}

  readonly lpaHistoryId: number;
  readonly applicationId: number;
  readonly integrationHistoryId?: number;
  readonly keyIdentifier: string;
  readonly loanIdentifier: string;
  readonly isSuccessful: boolean;
  readonly status: string;
  readonly recommendation: string;
  readonly loanStatus: CaseStateType;
  readonly isEligible?: boolean;
  readonly propertyInspectionWaiver?: boolean;
  readonly assetsVerified?: boolean;
  readonly employmentVerified?: boolean;
  readonly incomeVerified?: boolean;
  readonly ausSubmissionDate: Date;
  readonly creditVendor?: CreditVendorEnum;
  readonly isMismo34?: boolean; //TODO: Remove when old LPA logic is removed after we've moved to LPA 5 entirely (message from api)
  readonly ausMessages: string;
  readonly errors: LpaErrorArray;
  readonly rawResponse: string;
  readonly dateInserted: string;
  documents: Array<LpaHistoryDocument>;

  requestData: FreddieMacUnderwriteRequest;
}

export type LpaErrorArray = Array<LpaError>;
