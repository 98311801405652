import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'pipeline-stats',
    templateUrl: 'pipeline-stats.component.html',
    styleUrls: ['pipeline-stats.component.scss']
})

export class PipelineStatsComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    description: string;

    @Input()
    color: string;

    @Input()
    value: number;

    @Input()
    text: string;

    getColor = () => {
        if (this.color) {
            if (this.color === "'green'") {
                return "#1CBB8C";
            } else if (this.color === "'purple'") {
                return "#684AAF";
            } else if (this.color === "'red'") {
                return "#E46262";
            } else if (this.color === "'yellow'") {
                return "#F0B640";
            }
        } else return;
    }

    constructor() { }

    ngOnInit() {
        this.getColor();
    }
}