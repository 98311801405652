<!-- <loading-indicator *ngIf="loadingLoanDocs" [loadingMessage]="'Loading loan docs, please wait...'">
</loading-indicator> -->

<div class="card" *ngIf="!errorMessage">
  <div class="card-header">
    <h4 class="card-title"><i class="fas fa-files-o me-2"></i> Loan Docs V2 </h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="form-group col-sm-4 col-md-2">
            <label for="docType"> Doc Type </label>
            <select class="form-select" [(ngModel)]="selectedDocumentTypeId" name="docType"
              (ngModelChange)="onDocumentTypeFilterChanged()">
              <option [ngValue]="-99">All</option>
              <option *ngFor="let docType of filteredDocTypesByLoanDoc" [ngValue]="docType.documentTypeId">
                {{ docType.documentTypeName }}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-8 col-md-3" *ngIf="selectedView == 'tableView'">
            <label for="borrower"> Borrower </label>
            <select class="form-select" [(ngModel)]="selectedBorrowerId" name="borrower"
              (ngModelChange)="onBorrowerFilterChanged(selectedBorrowerId)">
              <option [ngValue]="0">All</option>
              <option [ngValue]="null">No Borrower</option>
              <option *ngFor="let borrower of borrowers" [ngValue]="borrower.borrowerId">
                {{ getBorrowerFullName(borrower) }}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-4 col-md-2">
            <label for="stackOrder"> Stack Order </label>
            <select class="form-select" [(ngModel)]="selectedStackingOrderId" name="stackOrder"
              (ngModelChange)="onStackingOrderFilterChanged(selectedStackingOrderId)">
              <option [ngValue]="0">All</option>
              <option *ngFor="let stackingOrder of filteredStackingOrders" [ngValue]="stackingOrder.stakingOrderId">
                {{ stackingOrder.docType }}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-4 col-md-2">
            <label for="linked"> Linked </label>
            <select class="form-select" [(ngModel)]="selectedLinkedId" name="linked"
              (ngModelChange)="onLinkedFilterChanged(selectedLinkedId)">
              <option [ngValue]="0">All</option>
              <option [ngValue]="1"> Loda/LOS </option>
              <option [ngValue]="2"> Loda </option>
              <option [ngValue]="3"> LOS </option>
            </select>
          </div>
          <div class="form-group col-sm-4 col-md-3">
            <span class="p-input-icon-right p-ml-auto" style="margin-top: 1.2rem;">
              <i class="pi pi-search"></i>
              <input pInputText #searchText type="text" class="form-control" [(ngModel)]="globalFilterText"
                (input)="filterGlobal($event.target.value)" placeholder="Search keyword" />
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-2">
        <div class="d-flex justify-content-end mt-3">
          <button type="button" class="btn btn-outline-primary me-2" (click)="onUploadClicked()">
            <i class="fas fa-upload"></i> Upload
          </button>
          <!-- <div class="btn-group">
            <button type="button" class="btn" title="Show Table View"
              [ngClass]="{'btn-primary': selectedView == 'tableView', 'btn-outline-primary': selectedView == 'cardView'}"
              (click)="selectedView = 'tableView'">
              <i class="fas fa-list"></i>
            </button>
            <button type="button" class="btn" title="Show Card View"
              [ngClass]="{'btn-primary': selectedView == 'cardView', 'btn-outline-primary': selectedView == 'tableView'}"
              (click)="selectedView = 'cardView'">
              <i class="fas fa-th"></i>
            </button>
          </div> -->
        </div>
      </div>
    </div>

    <!-- <ng-container *ngIf="selectedView == 'tableView'"> -->
      <loan-docs-table-view
        [loanDocTasks]="loanDocTasks"
        [borrowers]="borrowers"
        [roles]="roles"
        [loanDocs]="filteredLoanDocs"
        [loading]="loadingLoanDocs"
        [appId]="currentApplication.applicationId"
        [userId]="userPermissions.userId"
        [showESignTaskOption]="userPermissions.documentSigningEnabled"
        [isDocumentSigningRequiredReview]="isDocumentSigningRequiredReview"
        [filteredDocumentTypes]="filteredDocumentTypes"
        [usersAll]="usersAll"
        [globalConfig]="globalConfig"
        (loanDocUpdated)="onLoanDocUpdated()"
        (uploadFilesClicked)="onUploadFilesClicked($event)"
        (taskUpdated)="onTaskUpdated()">
      </loan-docs-table-view>
    <!-- </ng-container> -->

    <!-- <ng-container *ngIf="selectedView == 'cardView'">
      <loan-docs-card-view
        [loanDocs]="filteredLoanDocs"
        [filteredDocumentTypes]="filteredDocumentTypes">
      </loan-docs-card-view>
    </ng-container> -->

  </div>

<!-- <div *ngIf="errorMessage" class="alert alert-outline-danger d-flex align-items-center mb-0" role="alert">
  <i class="ti ti-checks alert-icon me-2"></i>
  <strong>Error:</strong> {{ errorMessage }}
</div> -->
