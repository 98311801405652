<div class="row">
  <div class="col-md-12">
    <div class="card" style="height: {{height}}">
      <div class="card-header" *ngIf="!iframeURL">
        <h4 class="card-title col-5" style="display: inline-block">
          <i class="fas fa-tasks me-2"></i> Reggora Appraisals
        </h4>
        <div class="float-end button-items">
          <button type="button" class="btn btn-primary" (click)="getIframeURL()"
          *ngIf="!iframeURL && errors">
            Retry
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <ng-template [ngIf]="!isLoading" [ngIfElse]="loading">

          <div *ngIf="!iframeURL && errors"
            class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 mx-3" role="alert">
            <div class="media">
              <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
              <div class="media-body align-self-center">
                <span>{{ errors }}</span>
              </div>
            </div>
          </div>

          <iframe *ngIf="iframeURL" height="100%" width="100%"
            [src]="safeUrl">
          </iframe>
        </ng-template>
        <ng-template #loading>
          <loading-indicator></loading-indicator>
        </ng-template>
      </div>
    </div>
  </div>
</div>
