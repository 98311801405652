<div class="lde-los">
  <div class="d-flex justify-content-between">
    <h4 class="card-title mt-2">
      <strong>
        <span *ngIf="losEnabled && type === 'los'">Loan Origination System Sync Info</span>
        <span *ngIf="ldeEnabled && type === 'lde'">Lender Data Exchange Sync Info</span>
      </strong>
    </h4>
    <div class="special-radio-btn-group" *ngIf="losEnabled && ldeEnabled">
      <div class="btn-group btn-group-left" role="group">
        <input type="radio" id="los" name="btnType" class="btn-check" value="los" autocomplete="off"
          [(ngModel)]="type">
        <label class="btn btn-outline-secondary" for="los"> LOS </label>
        <input type="radio" id="lde" name="btnType" class="btn-check" value="lde" autocomplete="off"
          [(ngModel)]="type">
        <label class="btn btn-outline-secondary" for="lde"> LDE </label>
      </div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12" class="los-lde-container">
      <div [hidden]="type === 'lde'">
        <los-info (syncDirectionUpdated)="onLosSyncDirectionUpdated($event)"></los-info>
      </div>
      <div [hidden]="type === 'los'">
        <lde-info (syncInfoUpdated)="onLdeSyncInfoUpdated()"></lde-info>
      </div>
    </div>
  </div>
</div>
