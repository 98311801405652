import {Mortgage, MortgageBorrower} from '../../../models';
import {BorrowerType} from '../../../modules/leads/models/lead-credit.model';

export module MortgageUtils {
  export function getPrimaryBorrowerOrFirst(
    mortgage: Mortgage,
  ): MortgageBorrower | undefined {
    return (
      mortgage.borrowers.find(
        b => b.typeOfBorrower === BorrowerType.PrimaryBorrower,
      ) ?? mortgage.borrowers?.[0]
    );
  }

  export function hasSubjectPropertyRentalIncome(mortgage: Mortgage): boolean {
    return !!mortgage.subjectProperty?.expectedGrossMonthlyRentalIncome ||
      !!mortgage.subjectProperty?.expectedNetMonthlyRentalIncome;
  }

  export function clearSubjectPropertyRentalIncomeProperties(mortgage: Mortgage): void {
    const subjectProperty = mortgage.subjectProperty;
    if (subjectProperty) {
      subjectProperty.expectedGrossMonthlyRentalIncome = null;
      subjectProperty.expectedNetMonthlyRentalIncome = null;
    }

    const borrower = getPrimaryBorrowerOrFirst(mortgage);
    if (borrower) {
      borrower.hasOneYearLandlordExperience = null;
    }
  }
}
