import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Role, SubStatus } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SubStatusService } from '../../../services';

@Component({
  selector: 'upsert-sub-status',
  templateUrl: 'upsert-sub-status-dialog.component.html',
  styleUrls: ['./upsert-sub-status-dialog.component.scss'],
})
export class UpsertSubStatusDialog implements OnInit, OnChanges {
  @Input() subStatus: SubStatus;
  @Input() roles: Array<Role>;
  @Input() companyId: number;

  @Output() close: EventEmitter<SubStatus> = new EventEmitter<SubStatus>();

  @ViewChild('upsertSubStatusForm') upsertSubStatusForm: NgForm | undefined;

  saving: boolean;
  editMode: boolean;

  multiselectSelectByRole = [];
  multiselectSelectByRoleModel = [];

  constructor(
    private readonly _subStatusService: SubStatusService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.editMode = this.subStatus.subStatusId ? true : false;
    this.setMultiSelectData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subStatus.currentValue && changes.subStatus.currentValue != changes.subStatus.previousValue) {
      this.editMode = this.subStatus.subStatusId ? true : false;
      this.setMultiSelectData();
    }
  }

  save() {
    this.upsertSubStatusForm.form.markAllAsTouched();
    if (!this.upsertSubStatusForm.form.valid) {
      return;
    }
    this.subStatus.selectByRole = this.multiselectSelectByRoleModel?.join(',');
    this.saving = true;
    if (this.editMode) {
      this.update();
      return;
    }
    this.insert();
  }

  onClose() {
    this.close.emit();
  }

  private setMultiSelectData() {
    this.multiselectSelectByRole = this.roles?.map((role) => ({
      id: role.roleId.toString(),
      text: role.roleName,
    }));

    this.multiselectSelectByRoleModel = this.subStatus.selectByRole?.split(',');
  }

  private insert() {
    this._subStatusService.insertSubStatus(this.companyId, this.subStatus)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Sub status added successfully',
            'Sub Status'
          );
          this.close.emit(res);
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't insert new sub status",
            'Sub Status'
          );
        }
      });
  }

  private update() {
    this._subStatusService.updateSubStatus(this.companyId, this.subStatus)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Sub status updated successfully',
            'Sub Status'
          );
          this.close.emit(this.subStatus);
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't update selected sub status",
            'Sub Status'
          );
        }
      });
  }
}
