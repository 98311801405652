import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfigurationProduct } from 'src/app/modules/admin/tpo-config/models/configuration-product.model';
import { Constants } from 'src/app/services/constants';
import { LoanServicesService } from 'src/app/services/loan';
import { ProductService } from 'src/app/services/product.service';
import { DisclosureLoanPlanArray, DocumentType, ThirdPartyCredential, ThirdPartyKeyValue } from '../../../../../../models';
import { ChannelService } from '../../../../../../services/channel.service';
import { NotificationService } from '../../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../../services/system-level.service';
import { ThirdPartyCredentialsService } from '../../../../../../services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';

@Component({
  selector: 'disclosures-credential-modal',
  templateUrl: './disclosures-credential-modal.component.html'
})
export class DisclosuresCredentialModalComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  documentTypes: Array<DocumentType>;
  credential: ThirdPartyCredential;
  keyValuePairs = {
    accountId: null,
    sendForESignByDefault: null,
    savedDocumentTypeId: null,
    reviewRequired: null,
    enableLoanPlanFiltering: null,
    loanPlanFilters: null,
    sendSampleBorrowerNames: null
  };
  isSaving: boolean;
  loadingPlans: boolean;

  loanPlanFilters = {};

  disclosureLoanPlans: DisclosureLoanPlanArray;
  products: ConfigurationProduct[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _channelService: ChannelService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    public activeModal: NgbActiveModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _productService: ProductService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keyValuePairs.accountId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'AccountId') || {};
    this.keyValuePairs.sendForESignByDefault = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SendForESignByDefault') || {};
    this.keyValuePairs.savedDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SavedDocumentTypeId') || {};
    this.keyValuePairs.reviewRequired = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'ReviewRequired') || {};
    this.keyValuePairs.enableLoanPlanFiltering = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'EnableLoanPlanFiltering') || {};
    this.keyValuePairs.loanPlanFilters = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'LoanPlanFilters') || {};
    this.keyValuePairs.sendSampleBorrowerNames = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SendSampleBorrowerNames') || { value: true };

    if (this.keyValuePairs.loanPlanFilters && this.keyValuePairs.loanPlanFilters.value) {
      JSON.parse(this.keyValuePairs.loanPlanFilters.value).forEach(loanPlanFilter => {
        this.loanPlanFilters[loanPlanFilter.loanPlanCode] = loanPlanFilter.losProductIds;
      });
    }

    if (this.credential.credentialId) {
      this.loadingPlans = true;

      forkJoin({
        products: this._productService.getProducts(),
        loanPlans: this._loanServicesService.getDisclosureLoanPlans()
      }).subscribe({
        next: ({products, loanPlans}) => {
          this.products = products;
          this.disclosureLoanPlans = loanPlans;

          const planCacheKey = Constants.disclosureLoanPlansCacheKey;
          let loanPlanCacheIdx = this.credential.thirdPartyKeyValuePairs.findIndex(keyValue => keyValue.key == planCacheKey);

          if (loanPlanCacheIdx == -1) {
            const loanPlanCache = new ThirdPartyKeyValue(Constants.disclosureLoanPlansCacheKey, JSON.stringify(this.disclosureLoanPlans));
            this.credential.thirdPartyKeyValuePairs.push(loanPlanCache);
          }
          else {
            let loanPlanCache = this.credential.thirdPartyKeyValuePairs[loanPlanCacheIdx];
            loanPlanCache.value = JSON.stringify(this.disclosureLoanPlans);
          }

          this._systemLevelService.saveCredential(this.credential).subscribe({
            next: () => {
            },
            error: (err) => {
              this._notificationService.showError(`Loan plans couldn't be saved to cache`, 'System Level');
            }
          })
            .add(() => this.loadingPlans = false)
        },
        error: error => {
          this.loadingPlans = false;
          this._notificationService.showError(`${error ? error.message : ''}`, 'System Level');
        }
      })
    }
  }

  saveCredential(): void {
    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;

    let loanPlanFilters: LoanPlanFilter[] = Object.keys(this.loanPlanFilters).map(
      (key) => new LoanPlanFilter(key, this.loanPlanFilters[key])
    )
    this.keyValuePairs.loanPlanFilters.value = JSON.stringify(loanPlanFilters);

    this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);
    this.isSaving = true;
    this.credential.companyId = this.applicationContext.userPermissions.companyId;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
          this.activeModal.close(res)
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
        }
      });
  }
}

export class LoanPlanFilter {
  loanPlanCode: string;
  losProductIds: number[];

  constructor(loanPlanCode: string, losProductIds: number[]) {
    this.loanPlanCode = loanPlanCode;
    this.losProductIds = losProductIds;
  }
}
