import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractValueAccessor,
  MakeProvider,
} from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  selector: 'app-image-upload',
  templateUrl: 'image-upload.component.html',
  providers: [MakeProvider(ImageUploadComponent)],
  viewProviders: [formViewProvider],
})

export class ImageUploadComponent extends AbstractValueAccessor {
  @Input() imageUrl;
  @Input() height;
  @Input() width;
  @Input() alt: string = 'image';
  @Input() readonly: boolean = false;
  @Input() customClass: string = '';

  @Output() upload = new EventEmitter();
  @Output() remove = new EventEmitter();

  handleFileUpload(files) {
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); // read file as data url
    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.imageUrl = event.target.result;
      this.upload.emit(event.target.result);
    };
  }

  onRemove() {
    this.imageUrl = '';
    this.remove.emit();
  }

  onImageError() {
    this.imageUrl = '';
  }
}
