<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Lender Information
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #docPreLenderInfoForm="ngForm" id="docPreLenderInfoForm">
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="lenderName"> Lender Name </label>
          <input type="text" [(ngModel)]="docPrep.lenderName" class="form-control" id="lenderName" name="lenderName">
        </div>
        <div class="col-md-6 form-group">
          <label for="lenderContact"> Lender Contact </label>
          <input type="text" class="form-control" [(ngModel)]="docPrep.lenderContact" id="lenderContact"
            name="lenderContact">
        </div>
        <div class="col-md-6 form-group">
          <label for="contactEmail"> Contact Email </label>
          <input type="email" trim class="form-control" [(ngModel)]="docPrep.lenderContactEmail" id="contactEmail"
            name="contactEmail" #emailInput="ngModel" [ngClass]="{
              'is-invalid':
                emailInput &&
                emailInput.touched &&
                (!emailFormatValidate(docPrep.lenderContactEmail))
            }">
          <div for="contactEmail" class="invalid-feedback" *ngIf="!emailFormatValidate(docPrep.lenderContactEmail)">
            Please enter a valid email address
          </div>
        </div>
        <div class="col-md-6 form-group">
          <label for="contactPhone"> Contact Phone</label>
          <input id="contactPhone" name="contactPhone" mask="(000) 000-0000" type="text" placeHolderCharacter="X"
            class="form-control custom-input-right-side" [(ngModel)]="docPrep.lenderContactPhone"
            [showMaskTyped]="true" />
        </div>
        <div class="col-md-6 form-group">
          <label for="fileNotes"> File Notes </label>
          <textarea id="fileNotes" name="fileNotes" class="form-control" [(ngModel)]="docPrep.lenderFileNotes" rows="4">
          </textarea>
        </div>
      </div>
    </form>
  </div>
</div>