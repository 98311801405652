import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

export class ActionButtonOption {
  type: number;
  label?: string | (() => void);
  iconClass?: string;
  buttonClass?: string;
  searchInputPlaceholder?: string;
  enabledChannels?: EnumerationItem[];
  showSelectOneOption?: boolean = true;
  defaultSelection?: string | number;
  buttons?: Array<GridActionGroupButton>;
  onClick?: () => void;
  onSearch?: (value: string) => void;
  onSelectionChanged?: (selectedChannel: string) => void;
  onCheckboxClick?: (value: boolean) => void;
  isLoading?: () => boolean;
  isDisabled?: () => boolean;
}

export interface GridActionGroupButton {
  text: string;
  onClick: () => void;
  isChecked?: boolean;
}

export enum GridActionButtonType {
  SimpleButton = 1,
  ChannelDropDown,
  ButtonsGroup,
  Checkbox,
  SearchInput,
}
