<ng-container *ngIf="!isLoading; else loadingContent">
    <div class="fieldset-title">Prequal Letter Template</div>
    <div class="pt-3">
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="custom-control-label">Template To Use</label>
            </div>
            <div class="col-md-4">
                <select class="form-select" (change)="getDocumentTemplate()"
                    [(ngModel)]="prequalGenerationSettings.documentTemplateId">
                    <option value="null">-- Select One --</option>
                    <option *ngFor="let item of documentTemplates" value={{item.documentTemplateId}}>
                        {{item.documentTemplateName}}
                    </option>
                </select>
            </div>
        </div>

        <div *ngIf="documentTemplate">
            <div class="row mb-3">
                <div class="col-md-2 text-end">
                    <label class="custom-control-label">Header</label>
                </div>
                <div class="col-md-10">
                    <div [innerHTML]="documentTemplate.headerHtml"></div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-2 text-end">
                    <label class="custom-control-label">Body</label>
                </div>
                <div class="col-md-10">
                    <div [innerHTML]="documentTemplate.bodyHtml"></div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-2 text-end">
                    <label class="custom-control-label">Footer</label>
                </div>
                <div class="col-md-10">
                    <div [innerHTML]="documentTemplate.footerHtml"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="fieldset-title mt-5">Enable Prequal Letter Generation</div>
    <div class="pt-3">
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="enabledForInternalUsers">For Internal Users</label>
            </div>
            <div class="col-md-10">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="enabledForInternalUsers" type="checkbox"
                        [(ngModel)]="prequalGenerationSettings.enabledForInternalUsers">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="enabledForAgents">For Third Party Agents</label>
            </div>
            <div class="col-md-10">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="enabledForAgents" type="checkbox"
                        [(ngModel)]="prequalGenerationSettings.enabledForAgents">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2 text-end">
                <label class="form-check-label" for="enabledForBorrowers">For Borrower</label>
            </div>
            <div class="col-md-10">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="enabledForBorrowers" type="checkbox"
                        [(ngModel)]="prequalGenerationSettings.enabledForBorrowers">
                </div>
            </div>
        </div>
    </div>
    <div class="fieldset-title mt-5" *ngIf="type == 'system-level'">Set Proof Of Funds Document Type Filter</div>
    <div *ngIf="type == 'system-level'" class="pt-3">
        <div class="row mt-3">
            <div class="col-md-2">
                <label class="custom-control-label">Document Type</label>
            </div>
            <div class="col-md-4">
                <select class="form-select" [(ngModel)]="proofOfFundsDocTypeId.value">
                    <option value="">-- Select One --</option>
                    <option *ngFor="let item of documentTypes" value={{item.documentTypeId}}>
                        {{item.documentTypeName}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="text-end" *ngIf="type != 'branch'">
        <div class="col-md-12 my-1">
            <hr />
        </div>
        <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>
</ng-container>
<ng-template #loadingContent>
    <loading-indicator loadingMessage="Loading Prequal Letter Settings..."
        [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>
