export class ForgotPasswordModel {
    email: string = "";
    scope: string = "";
    userCompanyGuid: string = "";

    constructor(email: string, scope: string, userCompanyGuid: string) {
        this.email = email;
        this.scope = scope;
        this.userCompanyGuid = userCompanyGuid;
    }
}