import { Component, Injector, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { Lender } from 'src/app/models/config/lender.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { PricingInvestor } from 'src/app/modules/pricing/models/pricing/pricing-investor-product.model';
import { LdeService } from 'src/app/services/lde.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lender-info',
  templateUrl: './lender-info.component.html',
  styleUrls: ['./lender-info.component.scss'],
  viewProviders: [formViewProvider],
})
export class LenderInfoComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() lender: Lender;
  @Input() pricingVendors: { name: string, value: string, investors: PricingInvestor[] }[] = [];

  states: EnumerationItem[] = [];
  ldeEnabled: boolean = false;
  vendorsList: any;
  filteredPricingVendorInvestors: PricingInvestor[] = [];

  urlPattern: string = "[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?";

  private _applicationContextSubscription: Subscription;

  constructor(private readonly _ldeService: LdeService,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.lender.pricingVendor || this.lender.pricingVendor === 'Unknown') {
      this.lender.pricingVendor = null;
    } else {
      this.getFilteredVendorProducts()
    }
    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.states = Object.keys(context.globalConfig.states).map(key => new EnumerationItem(context.globalConfig.states[key], key));
      this.ldeEnabled = context.userPermissions.ldeEnabled;
      if (this.ldeEnabled) {
        this._ldeService.getLdeVendors().subscribe({
          next: (result) => {
            this.vendorsList = result;
            console.log(this.vendorsList)
          },
          error: (err) => {
          }
        });
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.lender.state = zipCode.state.toLowerCase();
      this.lender.city = Utils.toTitleCase(zipCode.city);
      this.lender.zip = zipCode.zipcode;
    }
  }

  handlePricingVendorChange = () => {
    this.lender.pricingVendorInvestorId = null;
    this.getFilteredVendorProducts();
  }

  private getFilteredVendorProducts = () => {
    if (this.lender.pricingVendor) {
      this.filteredPricingVendorInvestors = this.pricingVendors.find(i => i.value == this.lender.pricingVendor)?.investors?.map(investor => ({
        ...investor,
        display: `${investor.investorId} : ${investor.name}`
      })) || [];
    } else {
      this.filteredPricingVendorInvestors = [];
    }

    this.filteredPricingVendorInvestors = [...this.filteredPricingVendorInvestors];
  }
}
