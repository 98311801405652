<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #lenderPriceForm="ngForm" novalidate id="lenderPriceForm">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Alias </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" name="alias"
          [ngClass]="{'is-invalid': alias && alias.touched && alias.invalid}" #alias="ngModel"
          [(ngModel)]="vendor.alias" required>
        <div class="invalid-feedback">Alias is required.</div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Username </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="vendor.userName" #userNameInput="ngModel" name="userName"
          id="userName" [ngClass]="{'is-invalid': userNameInput && userNameInput.touched && userNameInput.invalid}"
          required>
        <div class="invalid-feedback"> This field is required </div>
      </div>
      <ng-container *ngIf="vendor.vendorName == 'LenderPriceIframe'">
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Password </label>
        </div>
        <div class="col-md-4">
          <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName" required="true"></password-input>
        </div>
      </ng-container>
      <ng-container *ngIf="vendor.vendorName != 'LenderPriceIframe'">
        <div class="col-md-3">
          <div class="form-check form-check-inline" style="vertical-align: sub; margin-left: 1.6rem;">
            <input id="useLegacyAuth" name="useLegacyAuth" class="form-check-input" type="checkbox"
              [value]="lenderPriceKeyValuePairs.useLegacyAuth" [checked]="lenderPriceKeyValuePairs.useLegacyAuth == '1'"
              ToggleValue [true]="1" [false]="0"
              (change)="isValChanged(lenderPriceKeyValuePairs.useLegacyAuth, 'useLegacyAuth')">
            <label class="form-check-label" for="useLegacyAuth"> Use Legacy Auth. </label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-check form-check-inline" style="vertical-align: sub;">
            <input id="isProductCodeUnique" name="isProductCodeUnique" class="form-check-input" type="checkbox"
              [value]="lenderPriceKeyValuePairs.isProductCodeUnique"
              [checked]="lenderPriceKeyValuePairs.isProductCodeUnique == '1'" ToggleValue [true]="1" [false]="0"
              (change)="isValChanged(lenderPriceKeyValuePairs.isProductCodeUnique, 'isProductCodeUnique')">
            <label class="form-check-label" for="isProductCodeUnique"> Is Product Code Unique </label>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row mt-3" *ngIf="vendor.vendorName == 'LenderPriceIframe'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Base Url </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="vendor.url" #urlInput="ngModel" name="url" id="url"
          [ngClass]="{'is-invalid': urlInput && urlInput.touched && urlInput.invalid}" required>
        <div class="invalid-feedback"> This field is required </div>
      </div>
    </div>
    <div *ngIf="vendor.vendorName != 'LenderPriceIframe'">
      <div class="row mt-3">
        <ng-container *ngIf="lenderPriceKeyValuePairs.useLegacyAuth == '0'">
          <div class="col-md-2 text-end">
            <label class="custom-control-label"> Password </label>
          </div>
          <div class="col-md-4">
            <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName" required="true"></password-input>
          </div>
        </ng-container>
        <ng-container *ngIf="lenderPriceKeyValuePairs.useLegacyAuth == '1'">
          <div class="col-md-2 text-end">
            <label class="custom-control-label"> API Key </label>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control" [(ngModel)]="lenderPriceKeyValuePairs.apiKey" #apiKeyInput="ngModel"
              name="apiKey" id="apiKey"
              [ngClass]="{'is-invalid': apiKeyInput && apiKeyInput.touched && apiKeyInput.invalid}" required>
            <div class="invalid-feedback"> This field is required </div>
          </div>
        </ng-container>
        <ng-container *ngIf="lenderPriceKeyValuePairs.useLegacyAuth == '1'">
          <div class="col-md-2 text-end">
            <label class="custom-control-label"> LenderPrice Company ID </label>
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control" [(ngModel)]="lenderPriceKeyValuePairs.lenderPriceCompanyId"
              #lenderPriceCompanyIdInput="ngModel" name="lenderPriceCompanyId" id="lenderPriceCompanyId"
              [ngClass]="{'is-invalid': lenderPriceCompanyIdInput && lenderPriceCompanyIdInput.touched && lenderPriceCompanyIdInput.invalid}"
              required>
            <div class="invalid-feedback"> This field is required </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Url </label>
        </div>
        <div class="col-md-4">
          <select class="form-select" [(ngModel)]="vendor.url" name="vendorUrl">
            <option value="">-- Select One --</option>
            <ng-container *ngFor="let vurl of vendorUrlList">
              <option [value]="vurl"> {{vurl}} </option>
            </ng-container>
          </select>
        </div>
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> OAuth Base Url </label>
        </div>
        <div class="col-md-4">
          <select class="form-select" [(ngModel)]="lenderPriceKeyValuePairs.oAuthBaseUrl" name="oAuthBaseUrl">
            <option value="">-- Select One --</option>
            <ng-container *ngFor="let burl of vendoroAuthBaseUrlList">
              <option [value]="burl"> {{burl}} </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row mt-3" *ngIf="vendor?.credentialId">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <button type="button" class="btn btn-primary me-2" (click)="onRepriceFieldsClicked()">
            Field Editor
          </button>
        </div>
      </div>
      <ng-container *ngIf="!['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(scope)">
        <div class="row pt-4">
          <div class="col-md-12 ">
            <div class="fieldset-title">
              <div class="row">
                <div class="col-md-2 pt-2">Custom Fields</div>
                <div class="col-md-4 text-start">
                  <button class="btn btn-outline-primary" (click)="onCustomFieldsMappingClicked()">
                    Manage
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container> 
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
