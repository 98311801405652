
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentSigningVendor } from 'src/app/modules/loan-docs/models/document-signing-vendor.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SigningService } from 'src/app/services/signing.service';

@Component({
  selector: 'esign-document-dialog',
  templateUrl: './esign-document-dialog.component.html',
  styleUrls: ['./esign-document-dialog.component.scss']
})
export class EsignDocumentDialogComponent implements OnInit {

  @Input()
  loanDocTaskId: number;

  @Input()
  documentSigningVendor: DocumentSigningVendor = DocumentSigningVendor.DocuSign;

  isLoading: boolean = true;

  signerViewUrl: SafeUrl;

  constructor(public activeModal: NgbActiveModal,
    private readonly _signingService: SigningService,
    private readonly _notifyService: NotificationService,
    private readonly _domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const data = {
      returnUrl: location.protocol + '//' + location.host + '/iframe-confirmation/document-esigning',
    };
    this._signingService.openSignerView(this.loanDocTaskId, data).subscribe({
      next: (response) => {
        this.signerViewUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(response.signerViewUrl);
        this.isLoading = false;
        window.addEventListener('message', event => {
          if (event.data.shouldCloseEsignDocIframe) {
            this.activeModal.close(event.data.response);
          }
        }, false);
      },
      error: (err) => {
        this._notifyService.showError(err && err.error ? err.error.message || err.message : "Erorr occured in Esign Document", 'Error');
        this.isLoading = false;
      }
    });
  }

}
