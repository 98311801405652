<div class="d-flex">
  <textarea class="form-control" placeholder="Enter notes" name="note" [(ngModel)]="note" rows="5"></textarea>

  <button title="Save" type="button" class="btn p-0 border-0 bg-transparent ms-2" (click)="saveNote()">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button title="Cancel" type="button" class="btn p-0 border-0 bg-transparent ms-2" (click)="closePopover()">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
</div>
