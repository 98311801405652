<p-table #recipientsTable
  [value]="recipients"
  [resizableColumns]="true"
  styleClass="p-datatable-gridlines">
  <ng-template pTemplate="caption" *ngIf="showCaption">
    <div class="p-d-flex" style="align-items:center">
      <h5 style="margin: 0">Recipients ({{recipients.length}})</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="'name'" pResizableColumn>Name
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'email'" pResizableColumn>Email
        <p-sortIcon [field]="'email'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'status'" pResizableColumn>Status
        <p-sortIcon [field]="'status'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'deliveredAt'" pResizableColumn>Delivered At
        <p-sortIcon [field]="'deliveredAt'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'signedAt'" pResizableColumn>Signed At
        <p-sortIcon [field]="'signedAt'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'declinedAt'" pResizableColumn>Declined At
        <p-sortIcon [field]="'declinedAt'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'clientIpAddress'" pResizableColumn>Client IP Address
        <p-sortIcon [field]="'clientIpAddress'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-recipient>
    <tr>
      <td>{{recipient.name}}</td>
      <td>{{recipient.email}}</td>
      <td>{{recipient.status}}</td>
      <td>{{recipient.deliveredDateTime | date:'MMM d, y h:mm:ss a'}}</td>
      <td>{{recipient.signedDateTime | date:'MMM d, y h:mm:ss a'}}</td>
      <td>{{recipient.declineWithReason}}</td>
      <td>{{recipient.clientIpAddress}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7" class="text-center"> {{emptyMessage}} </td>
    </tr>
  </ng-template>
</p-table>
