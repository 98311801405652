import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { handleNonErrorDismissals, Utils } from 'src/app/core/services/utils';
import { LosVendor, SystemLevel, ThirdPartyCredentialType } from 'src/app/models';
import { LosProduct } from 'src/app/models/los/los-product.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { InvestorProductList } from 'src/app/modules/pricing/models/pricing/pricing-investor-product.model';
import { Product } from 'src/app/modules/pricing/models/pricing/product-search-result.model';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product.service';
import { ApplicationContextBoundComponent, RearrangeOrderComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { FilterOption } from 'src/app/shared/components/generic-filter/generic-filter.component';
import { TableColumn } from 'src/app/shared/models/tale-column.model';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { ConfigurationProduct, PricingEngineVendor } from '../../../tpo-config/models/configuration-product.model';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { PricingVendor } from '../../../../../models/pricing/pricing-vendor';
import { catchError, EMPTY, from, Observer, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ImportVendorProductsDialog,
} from '../../../../pricing/components/pricing-vendor-products-dialog/import-vendor-products-dialog.component';
import { Constants } from '../../../../../services/constants';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'app-lender-config-products',
  templateUrl: './lender-config-products.component.html',
  styleUrls: ['./lender-config-products.component.scss']
})
export class LenderConfigProductsComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('lenderProductsOrderDrawer')
  lenderProductsOrderDrawer: DrawerComponent;

  @ViewChild('lenderProductsOrder')
  lenderProductsOrder: RearrangeOrderComponent

  @ViewChild('lenderProductsCategoryOrderDrawer')
  lenderProductsCategoryOrderDrawer: DrawerComponent;

  @ViewChild('lenderProductsCategoryOrder')
  lenderProductsCategoryOrder: RearrangeOrderComponent

  products: ConfigurationProduct[] = [];
  filteredProducts: ConfigurationProduct[] = [];
  selectedColumns: any[] = [];
  columns: TableColumn[] = [];
  globalFilterFields: string[] = [];
  allCategories: string[] = [];
  itemsToOrder: Array<{ name: string, value: number }> = [];

  pricingVendors: { name: string, value: string, products: InvestorProductList[], flatProducts: Product[] }[] = [];
  private _enabledPricingVendors: PricingVendor[];
  losVendors: { name: string, value: string, products: LosProduct[] }[] = [];

  isDeleteClicked = {};
  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  upsertLenderProductDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  isLoading: boolean = false;
  protected canImportProducts: boolean = false;
  superAdmin: boolean = false;
  isProductEnabled: boolean = false;
  losEnabled: boolean;
  enabledChannels: EnumerationItem[] = [];
  companyId: number;
  currentLenderProduct: any;
  companyName: string;
  defaultSortingField: string;
  selectedCategory: string = null;

  allCategories2: EnumerationItem[] = [];
  categoriesForFiltering: FilterOption[] = [];

  private _getEnabledPricingVendorsSubscription?: Subscription;
  private _importProductsModalSubscription?: Subscription;

  constructor(
    injector: Injector,
    private readonly _productService: ProductService,
    private readonly _losVendorService: LosService,
    private readonly _pricingVendorService: PricingService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _losService: LosService,
    private readonly _drawerService: DrawerService,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.applicationContextService.context.subscribe(context => {
      this.superAdmin = context.userPermissions.superAdmin;

      let matchedCompany = context.globalConfig.company.find(c => c.companyGUID == context.currentlyLoggedInUser.userCompanyGuid)
      this.companyName = matchedCompany ? matchedCompany.companyName : null;

      this.companyId = context.userPermissions.companyId;
      this.isProductEnabled = context.userPermissions.pricingEnabled;
      this.losEnabled = context.userPermissions.losEnabled;
      this.enabledChannels = context.globalConfig.enabledChannels;
      if (this.superAdmin) {
        this.defaultSortingField = "active";
      }
      else {
        this.defaultSortingField = "sortOrder";
      }

      this.columns.push({ field: 'productName', header: 'Product Name' });
      this.columns.push({ field: 'productCategory', header: 'Product Category' });
      if (this.enabledChannels.length > 0) {
        this.columns.push({ field: 'enabledChannels', header: 'Role Channels' });
      }
      this.columns.push({ field: 'term', header: 'Term' });
      this.columns.push({ field: 'active', header: 'Active' });

      if (this.isProductEnabled) {
        this.columns.push({ field: 'pricingVendor', header: 'Pricing Vendor' });
        this.columns.push({ field: 'pricingVendorProductId', header: 'Vendor Product Id' });
      }

      if (this.losEnabled) {
        this.columns.push({ field: 'losVendor', header: 'Los Vendor' });
        this.columns.push({ field: 'losVendorProductId', header: 'Los Vendor Product Id' });
      }

      this.columns.push({ field: 'sortOrder', header: 'Sort' });

      this.columns.forEach((c, index) => {
        c.order = index + 1;
        c.visible = true;
      })

      this.columns.forEach(column => {
        this.globalFilterFields.push(column.field);
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });

      this.getProducts();
      this.getPricingVendors();

      if (this.losEnabled) {
        this._losService.getLosCredentials({ allowedFeatures: ["GetProducts"] }).subscribe((losVendors) => {
          losVendors.forEach(vendor => {
            this.getLosVendorProducts(LosVendor[vendor.losVendor], (products) => {
              this.losVendors.push({ name: this.getEnumName(vendor.losVendor), value: LosVendor[vendor.losVendor], products: products });
            })
          })
        }, (err) => {
          this._notifyService.showError(err.error.message || err.error, "Error!")
        })
      }
    });

    this.getEnabledPricingVendors();
  }

  ngOnDestroy() {
    this._getEnabledPricingVendorsSubscription?.unsubscribe();
    this._importProductsModalSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  private getEnabledPricingVendors() {
    this._getEnabledPricingVendorsSubscription?.unsubscribe();
    this._spinner.show();
    this._getEnabledPricingVendorsSubscription = this._pricingVendorService.getEnabledPricingVendors(true)
      .subscribe({
        next: (vendors) => {
          vendors = vendors.filter(v => v !== PricingVendor.LoanPass);

          this._systemLevelService.getSystemLevel().subscribe({
            next: (systemLevel: SystemLevel) => {
              const credentials = systemLevel.thirdPartyCredentials.filter(c => c.credentialType === ThirdPartyCredentialType.PricingVendor);
              const enabledVendors = vendors.filter(v => credentials.some(c => c.vendorName.toLowerCase() === v.toLowerCase()));
              this._enabledPricingVendors = enabledVendors;
              this.canImportProducts = enabledVendors.length > 0;
            }
          })
            .add(() => this._spinner.hide());
        },
        error: () => {
          this._spinner.hide()
        }
      })
  }

  onUpsertLenderProductDrawerClose(updatedProduct) {
    if (!updatedProduct) {
      this._drawerService.hide("upsertLenderProductDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertLenderProductDrawer", 100);
    this.getProducts();
  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  protected showImportProductsModal(): void {
    const modalRef = this._modalService.open(ImportVendorProductsDialog, Constants.modalOptions.large);
    const instance = modalRef.componentInstance as ImportVendorProductsDialog;
    // FIXME: Possible type mismatch between PricingEngineVendor and PricingVendor?
    instance.vendors = this._enabledPricingVendors as unknown as PricingEngineVendor[];
    instance.enabledChannels = this.enabledChannels;

    this._importProductsModalSubscription?.unsubscribe();
    this._importProductsModalSubscription = from(modalRef.result).pipe(
      catchError((error) => {
        handleNonErrorDismissals(error);
        return EMPTY;
      }),
    ).subscribe(_ => {
      this.getProducts();
    });
  }

  showUpsertDrawer(row?: ConfigurationProduct) {
    this._drawerService.hideAll();
    this.currentLenderProduct = cloneDeep(row) || {};
    this._drawerService.show("upsertLenderProductDrawer", 100);
  }

  getProducts = (): void => {
    this.isLoading = true;
    this._spinner.show();
    this._productService.getProducts()
      .pipe(finalize(() => {
        this.isLoading = false;
        this._spinner.hide();
      }))
      .subscribe({
        next: (result) => {
          this.products = result;
          let rowCategories = result.filter(p => p.productCategory);
          this.allCategories = _.uniqBy(rowCategories, 'productCategory').map(p => p.productCategory);
          this.products = _.sortBy(this.products, [this.defaultSortingField], ["asc"]);
          this.filteredProducts = _.cloneDeep(this.products);
          this.setProductByCategoriesOrder();
          this.setCategories();

        },
        error: (err) => {
          this._notifyService.showError(err.error.message || err.error, "Error!");
        }
      });
  }

  openProductsOrderDrawer = () => {
    if (_.isEmpty(this.products)) {
      return;
    }

    this.itemsToOrder = _.chain(this.products)
      .orderBy(a => a.sortOrder)
      .map(a => ({
        name: a.productName,
        value: a.productId
      }))
      .value();

    this._drawerService.show("lenderProductsOrderDrawer", 100);
  }

  onProductsOrderDrawerSave(sortedProducts: Array<EnumerationItem>) {
    this.lenderProductsOrder.saveInProgress = true;

    const sortedProductIds = sortedProducts.map(r => r.value as number);

    this._productService.reOrderProducts(sortedProductIds)
      .pipe(finalize(() => {
        this.lenderProductsOrder.saveInProgress = false;
        this.onCloseProductsOrderDrawer();
      }))
      .subscribe({
        next: () => {
          this.getProducts();
          this._notifyService.showSuccess('Product order updated Successfully', 'Update Product Order')
        },
        error: (error) => {
          this._notifyService.showError(error?.message || 'Unable to update Product order ', 'Update Product Order')
        }
      });
  }

  onCloseProductsOrderDrawer() {
    this._drawerService.hide("lenderProductsOrderDrawer", 100);
  }

  openProductCategoryOrderDrawer = () => {
    if (_.isEmpty(this.allCategories)) {
      return;
    }

    const orderedCategories = (this._localStorageService.getItem("productCategoryOrder") || []) as EnumerationItem[];

    for (let i = orderedCategories.length - 1; i >= 0; i--) {
      if (!this.allCategories.includes(orderedCategories[i].value)) {
        orderedCategories.splice(i, 1);
      }
    }

    this.allCategories.forEach(c => {
      const exists = orderedCategories.find(oc => oc.value === c);
      if (!exists) {
        orderedCategories.push(new EnumerationItem(c, c));
      }
    })

    this.itemsToOrder = _.chain(!orderedCategories.length ? this.allCategories : orderedCategories.map(o => o.value))
      .map(c => ({
        name: c,
        value: c
      }))
      .value();
    this._drawerService.show("lenderProductsCategoryOrderDrawer", 100);
  }

  onCategoryFilterChanged = (selectedOptions: FilterOption[]) => {
    if (selectedOptions.length > 0) {
      this.products = this.filteredProducts.filter(p => p.productCategory === selectedOptions[0].displayName);
    } else {
      this.products = this.filteredProducts;
    }
  }

  onProductsCategoryOrderDrawerSave(sortedProductCategories: Array<EnumerationItem>) {
    this._localStorageService.setItem("productCategoryOrder", sortedProductCategories);
    this.setProductByCategoriesOrder();
    this.onCloseProductsCategoryOrderDrawer();
  }

  onCloseProductsCategoryOrderDrawer() {
    this._drawerService.hide("lenderProductsCategoryOrderDrawer", 100);
  }

  setProductByCategoriesOrder = () => {
    const order = (this._localStorageService.getItem("productCategoryOrder") || []) as EnumerationItem[];

    if (!order.length) {
      return;
    }

    const last = this.products.length;
    const categories = order.map(c => c.value);

    let data = _.orderBy(this.products, (item) => {
      return categories.indexOf(item.productCategory) !== -1 ? categories.indexOf(item.productCategory) : last;
    });

    this.products = data;
  }

  onDeleteProductClicked = (productId: number) => {
    this._productService.deleteProduct(productId)
      .subscribe({
        next: () => {
          this._notifyService.showSuccess("Product removed succesfully", "Success");
          this.getProducts();
        },
        error: (err) => {
          this._notifyService.showError(err?.message || "Error encountered while deleting product", "Error!");
        }
      });
  }

  getPricingVendors = () => {
    for (let enumMember in PricingEngineVendor) {
      let enumName = this.getEnumName(PricingEngineVendor[enumMember]);
      if (enumName) {
        if (enumMember == PricingEngineVendor.OptimalBlue) {
          this.getPricingProducts(PricingEngineVendor[enumMember], (products) => {
            this.pricingVendors.push({ name: enumName, value: PricingEngineVendor[enumMember], products: products, flatProducts: products.flatMap(p => p.products) });
          })
        } else if (enumMember == PricingEngineVendor.LenderPrice || enumMember == PricingEngineVendor.Polly || enumMember == PricingEngineVendor.MeridianLink || enumMember == PricingEngineVendor.LoanPass || enumMember == PricingEngineVendor.LoanPassIframe) {
          this.pricingVendors.push({ name: enumName, value: PricingEngineVendor[enumMember], products: [], flatProducts: [] });
        }
      }
    }
  }

  getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" && enumValue != "Unknown" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  getPricingProducts = (vendorName: PricingEngineVendor, callback) => {
    this._pricingVendorService.getPricingProductLists(vendorName)
      .subscribe({
        next: (result) => {
          callback(result);
        },
        error: (err) => {
          this._notifyService.showError(err?.message || "Error encountered while fetching " + this.getEnumName(vendorName) + " products", "Error!");
          callback([]);
        }
      });
  }

  getPricingProductName = (pricingVendor: PricingEngineVendor, pricingVendorProductId: string): string => {
    if (pricingVendor !== PricingEngineVendor.OptimalBlue) {
      return pricingVendorProductId || '';
    }
    let matchedVendor = this.pricingVendors.find(v => v.value == pricingVendor);

    if (matchedVendor) {
      let product = matchedVendor.flatProducts.find(pro => pro.productId == pricingVendorProductId);
      if (product) {
        if (product) {
          let prodText = product.productId + ': ';
          if (product.productCode) {
            prodText += '(' + product.productCode + ') - ';
          }
          prodText += product.productName;
          return prodText;
        }
      }
    }
    return '';
  }

  getLosVendorProducts = (vendorName: LosVendor, callback) => {
    this._losVendorService.getLosVendorProducts(vendorName)
      .subscribe({
        next: (result) => {
          callback(result);
        },
        error: (err) => {
          this._notifyService.showError(err?.message || "Error encountered while fetching " + this.getEnumName(vendorName) + " products", "Error!");
          callback([]);
        }
      });
  }

  getLosVendorName = (losVendor: LosVendor) => {
    const vendor = this.losVendors.find(v => v.value == losVendor);
    return vendor?.name || "";
  }

  getLosVendorProductName = (losVendor: LosVendor, losVendorProductId: number): string => {
    const matchedVendor = this.losVendors.find(v => v.value == losVendor);

    if (matchedVendor) {
      const product = matchedVendor.products.find(pro => pro.losProductId == losVendorProductId);
      if (product) {
        const prodText = product.losProductId + ': ' + product.losProductName;
        return prodText;
      }
    }
    return '';
  }

  private setCategories = () => {
    let rowCategories = this.products.filter(p => p.productCategory);
    this.categoriesForFiltering = _.uniqBy(rowCategories, 'productCategory').map(b => ({
      displayName: b.productCategory,
      value: b.productId,
      groupName: "All",
      isSelected: false,
    }));
  }
}
