export enum MessageDeliveryEnum {
  Unspecified = "Unspecified",
  Email = "Email",
  SMS = "SMS",
  Toaster = "Toaster",
  Rvm = "Rvm",
  API = "API",
  SocialMedia = "SocialMedia"
}

