<ng-container *ngIf="!isLoading; else loading">
    <div class="accordion" id="checkListAccordion">
        <h4 *ngIf="checklistsForApp.length === 0" class="text-danger text-center">
            There are no checklists associated with this application.
        </h4>
        <div class="accordion-item" *ngFor="let chklist of checklistsForApp; let i = index;">
            <h5 class="accordion-header m-0" [id]="'heading' + i">
                <button class="accordion-button fw-semibold" type="button" [ngClass]="{'collapsed' : i != 0}"
                    [attr.data-bs-target]="'#collapse'+ i" data-bs-toggle="collapse" aria-expanded="true"
                    [attr.aria-controls]="'collapse' + i">
                    {{ chklist.name }}
                </button>
            </h5>
            <div [id]="'collapse' + i" class="accordion-collapse collapse {{ i == 0 ? 'show' : '' }}"
                [attr.aria-labelledby]="'heading' + i" data-bs-parent="#checkListAccordion">
                <div class="accordion-body">
                    <div *ngIf="chklist.questions.length === 0" class="fw-bold text-muted">
                        Could not find any valid questions for this checklist.
                    </div>
                    <div class="p-2" *ngFor="let clq of chklist.questions">
                        <label *ngIf="clq.borrowerDisplayName" class="mb-1">
                            Borrower: {{ clq.borrowerDisplayName }}
                        </label>
                        <label class="fw-bold d-block mb-2" for="selectedAnswers">
                            {{ clq.text }}
                        </label>
                        <label class="mb-1 me-2" for="selectedAnswers"> Answer:</label>
                        <label *ngIf="clq.submittedAnswerText">{{ clq.submittedAnswerText }}</label>
                        <label *ngIf="!clq.submittedAnswerText" class="text-danger">NOT ANSWERED YET</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div class="offset-lg-0 offset-xxl-1 offset-xxxl-2 col-lg-12 col-xxl-10 col-xxxl-8">
        <loading-indicator [loadingMessage]="'Please wait...'"
            [customClass]="'init-loading-indicator'"></loading-indicator>
    </div>
</ng-template>