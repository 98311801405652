import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoanAmountCalculatorDialogComponent } from './loan-amount-calculator-dialog/loan-amount-calculator-dialog.component';



@NgModule({
  declarations: [LoanAmountCalculatorDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [LoanAmountCalculatorDialogComponent]
})
export class LoanAmountCalculatorModule { }
