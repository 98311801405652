export class LpaProperty {
  constructor() {}

  sellerServicerNumber?: string;
  lpaUserId?: string;
  lpaPassword?: string;
  fhaLenderId?: string;
  fhaSponsorId?: string;
  nonOriginatingThirdPartyId?: string;
  thirdPartyOriginatorId?: string;
  enableMismo34?: string;
  thirdPartyOriginatorIsCorrespondentLender?: string;
}

export type LpaPropertyArray = Array<LpaProperty>;
