import { Component, Input, OnInit } from '@angular/core';
import { ElibilityCheckStatus, EligibilityFaultTypes, LoanValidationResponse, LosPackageConditionReport, LosRequiredValuesReport } from '../../../request-models/loan-validation-response.model';

@Component({
  selector: 'encompass-disclosure-loan-validation-results',
  templateUrl: './encompass-disclosure-loan-validation-results.component.html',
  styleUrls: ['./encompass-disclosure-loan-validation-results.component.scss']
})
export class EncompassDisclosureLoanValidationResultsComponent implements OnInit {

  @Input() incorrectValues: LosRequiredValuesReport[];
  @Input() validationResponse: LoanValidationResponse;
  @Input() invalidPackageConditions: LosPackageConditionReport[];
  @Input() correctedValues: any;

  showComplianceReport: boolean = false;
  invalidElibilityCheckRuleStatus: ElibilityCheckStatus[] = [];

  faultTypeDescriptions: any = {
    BrokerAffiliatedWithEscrow : "You have indicated in Fee Wizard that you have an affiliation with the Escrow Company being used. Disclosures can't be processed automatically and will be sent out by our Disclosure Team manually. Thank you.",
    LoanIsTBD : 'TBD Property Addresses are not eligible for Automated Disclosures and will be sent out by our Disclosure Team manually. Thank you.',
    LoanTypeNotSupported : 'The selected Loan Type is not eligible for Automated Disclosures and will be sent out by our Disclosure Team manually. Thank you.',
    FailingTridApplication : 'Loan must have all data for TRID before being eligible for Disclosures.',
    UnsupportedDisclosureType : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    LoanIsDPA : "A DPA (Down Payment Assistance) loan product has been selected, due to the specific disclosure requirements they can't be processed automatically and will be sent out by our Disclosure Team manually. Thank you.",
    ItemizationFeesIssue : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    UnsupportedPropertyState : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    MissingBorrowerEmails : "The borrower(s) do not have email addresses.  Disclosures can't be processed automatically and will be sent out by our Disclosure Team manually & mailed to the borrower(s).",
    LoanMissingBrokerInformation : "Broker information is missing from the loan file, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    LoanHasCompensationConflict : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    LoanProgramNotSupported : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    FloodReportUnavailable : 'FloodReportUnavailable',
    PropertyInFloodZone : 'PropertyInFloodZone',
    ManualLocksNotSupported : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    LoanHasBuyDownOption : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    NmlsComplianceReviewFailed : "The NMLS number is failing Compliance Review.  A relationship between the Loan Officer and the company does not exist in the NMLS as of TRID applicatoin date. Disclosures can't be processed automaticaly, once cleared the disclosures will be sent out by our Disclosure Team manually.",
    BorrowerMissingValidPhoneNumber : "The borrower(s) do not have valid phone numbers.  Disclosures can't be processed. Please correct and try again.",
    AmortizationTypeNotSupported : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
    UspsVerificationHasRun : "The file requires a second review validation, disclosures can't be processed automatically and will be sent out by our Disclosure Team manually.",
  };


  constructor() { }

  ngOnInit(): void {
    this.invalidElibilityCheckRuleStatus = this.validationResponse.elibilityCheckRuleStatus?.filter(x => !x.passed) || [];
  }

}
