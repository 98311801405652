import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import Swal from 'sweetalert2';
import { ExternalContact, MortgageSigner } from 'src/app/modules/loan-docs/models/external-contact.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { Utils } from 'src/app/core/services/utils';
import { Address } from '../../../../../models';

@Component({
  selector: 'document-preparation-seller-v2',
  templateUrl: 'document-preparation-seller-v2.component.html',
  styleUrls: ['./document-preparation-seller-v2.component.scss']
})

export class DocumentPreparationSellerV2Component implements OnInit {

  @Output()
  deleteSellerClicked: EventEmitter<ExternalContact> = new EventEmitter<ExternalContact>();

  @Input()
  seller: ExternalContact;

  @Input()
  sellerTypes: EnumerationItem[] = [];

  @Input()
  suffixes: EnumerationItem[] = [];

  @Input()
  entityTypes: EnumerationItem[] = [];

  @Input()
  index: number;

  @Input()
  maritalStatuses: EnumerationItem[] = [];

  protected states: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService) { }

  ngOnInit() {
    this.states = this._enumsService.states;
  }

  onDeleteSellerClicked = () => {
    this.deleteSellerClicked.emit(this.seller);
  }

  protected onAddSignerClicked = () => {
    let newSigner = new MortgageSigner();
    if (!this.seller.signers) {
      this.seller.signers = [];
    }
    this.seller.signers.push(newSigner);
  }

  protected onSellerTypeChanged = () => {
    if (this.seller.mortgagePartyType !== 'Entity' && this.seller.signers?.length) {
      this.seller.signers = [];
    }
  }

  protected onDeleteSignerClicked = (index: number) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this signer?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (index >= 0) {
          self.seller.signers.splice(index, 1);
        }
      }
    });
  }

  protected handleAddressChange(e: Partial<Address>): void {
    const seller = this.seller;
    seller.mailingStreet = ''; // to reset the last populated address.

    setTimeout(() => {
      seller.mailingStreet = e.address1;
      seller.mailingCity = e.city;
      seller.mailingState = e.state;
      seller.mailingZip = e.zipCode;
    }, 200);
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.seller.mailingState = zipCode.state.toLowerCase();
      this.seller.mailingCity = Utils.toTitleCase(zipCode.city);
      this.seller.mailingZip = zipCode.zipcode;
      this.seller.mailingCountry = 'us';
    }
  }

  protected onPOAChanged = (isChecked: boolean) => {
    this.seller.powerOfAttorney = isChecked;
    if (!isChecked) {
      this.seller.powerOfAttorneyName = '';
    }
  }
}
