<form id="questionForm" #questionForm="ngForm" name="questionForm">
  <div class="p-3">
    <div class="mb-3">
      <label class="form-label" for="questionText">Question Text</label>
      <textarea id="questionText" name="questionText" class="form-control" style="height: 95px"
        [ngClass]="{'is-invalid': questionTextInput && questionTextInput.touched && questionTextInput.invalid}" rows="2"
        [(ngModel)]="questionData.questionText" #questionTextInput="ngModel" required></textarea>
    </div>

    <div class="mb-3 position-relative">
      <label class="form-label" for="questionType">Question Type</label>
      <span *ngIf="loadingQuestionTypes"
        class="spinner-border spinner-border-sm position-absolute spinner-inside-dd mx-3" role="status"
        aria-hidden="true"></span>
      <select id="questionType" name="questionType" class="form-select" [(ngModel)]="questionData.questionType"
        [ngClass]="{'is-invalid': questionTypeInput && questionTypeInput.touched && questionTypeInput.invalid}"
        #questionTypeInput="ngModel" required>
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let type of selectableQuestionTypes" [ngValue]="type.value">
          {{ type.name }}
        </option>
      </select>
    </div>
    <div class="mb-3 position-relative">
      <label class="form-label" for="mortgageFunction">
        Mortgage Functions<i class="fa fa-info-circle text-primary ms-1" placement="top"
          ngbTooltip="Enter ' [ ' to expose available mortgage functions"></i>
        <ng-container *ngIf="questionData.mortgageFunctionExpression">

          <i *ngIf="mortExpressionValid === true" class="fa fa-check-circle cursor text-success"></i>
          <i *ngIf="mortExpressionValid === false" class="fa fa-times-circle cursor text-danger"></i>
          <span *ngIf="!loadingMortgageFunctions && !validatingMortExpression" class="cursor ms-1 fw-bold text-primary"
            (click)="validateExpression('Mortgage')">(Test Expression)
          </span>
        </ng-container>
      </label>
      <span *ngIf="loadingMortgageFunctions || validatingMortExpression"
        class="spinner-border spinner-border-sm position-absolute spinner-inside-dd mx-3" role="status"
        aria-hidden="true"></span>
      <function-editor-with-context-params *ngIf="mortgageFunctionConfig" [options]="mortgageFunctionConfig"
        [functionExpression]="mortgageFunctionExpression" (functionChanged)="onMortgageFunctionChanged($event)">
      </function-editor-with-context-params>
    </div>
    <div class="mb-3 position-relative">
      <label class="form-label" for="questionType">
        Borrower Functions<i class="fa fa-info-circle text-primary ms-1" placement="top"
          ngbTooltip="Enter ' [ ' to expose available borrower functions"></i>
        <ng-container *ngIf="questionData.borrowerFunctionExpression">

          <i *ngIf="borrExpressionValid === true" class="fa fa-check-circle cursor text-success"></i>
          <i *ngIf="borrExpressionValid === false" class="fa fa-times-circle cursor text-danger"></i>
          <span *ngIf="!loadingBorrowerFunctions && !validatingBorrExpression" class="cursor ms-1 fw-bold text-primary"
            (click)="validateExpression('Borrower')">(Test Expression)</span>
        </ng-container>
      </label>
      <span *ngIf="loadingBorrowerFunctions || validatingBorrExpression"
        class="spinner-border spinner-border-sm position-absolute spinner-inside-dd mx-3" role="status"
        aria-hidden="true">
      </span>
      <function-editor-with-context-params *ngIf="borrowerFunctionConfig" [options]="borrowerFunctionConfig"
        [functionExpression]="borrowerFunctionExpression" (functionChanged)="onBorrowerFunctionChanged($event)">
      </function-editor-with-context-params>

    </div>
    <div class="mb-3">
      <label class="form-label">Expression</label>
      <expression-input [companyId]="companyId" (onExpressionChanged)="updateExpressionGroup($event)"
        [expression]="expressionInfo">
      </expression-input>
    </div>
    <div *ngIf="!['Value','TriggerOnly'].includes(questionData.questionType)" class="mb-3">
      <question-answers-list #questionAnsList [questionData]="questionData"></question-answers-list>
    </div>
    <div *ngIf="questionData.questionType === 'TriggerOnly'">
      <div class="mb-3">
        <label class="form-label" for="tasks">Tasks</label>
        <ng-select width="100%" name="tasks" bindLabel="name" bindValue="value" class="custom-ng-select"
          placeholder="Select one or more Tasks" [closeOnSelect]="false" [items]="tasks"
          [(ngModel)]="questionData.taskIds" multiple="true" appendTo="body">
        </ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label" for="loanCharacteristic">Loan Characteristic</label>
        <ng-select width="100%" name="loanCharacteristic" bindLabel="name" bindValue="value" class="custom-ng-select"
          placeholder="Select one or more Loan Characteristic" [closeOnSelect]="false" [items]="loanCharacteristicList"
          [(ngModel)]="questionData.loanCharacteristicIds" multiple="true" appendTo="body">
        </ng-select>
      </div>
      <div class="mb-3">
        <label class="form-label" for="borrCharacteristic">Borrower Characteristic</label>
        <ng-select width="100%" name="borrCharacteristic" bindLabel="name" bindValue="value" class="custom-ng-select"
          placeholder="Select one or more Borrower Characteristic" [closeOnSelect]="false"
          [items]="borrowerCharacteristicList" [(ngModel)]="questionData.borrowerCharacteristicIds" multiple="true"
          appendTo="body">
        </ng-select>
      </div>
    </div>
  </div>
</form>

<div class="text-end">
  <button type="button" class="btn btn-secondary me-2" (click)="onClose()">
    <i class="fa fa-close"></i> Cancel
  </button>
  <button type="button" class="btn btn-primary me-3" (click)="save()" [disabled]="isSaving">
    <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving"></span>
    Save
  </button>
</div>