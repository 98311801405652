import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskCategory } from 'src/app/models/config/global-config.model';
import { ICharacteristic } from 'src/app/models/characteristics/characterictic-interface';
import { ICharacteristicFactory } from './characteristic-factory.interface';
import { CharacteristicMergeValue } from 'src/app/models/characteristics';
import * as _ from 'lodash';

@Component({
  selector: 'characteristics-quantities-dialog',
  templateUrl: 'characteristics-quantities-dialog.component.html',
  styleUrls: ['characteristics-quantities-dialog.component.scss']
})

export class CharacteristicsQuantitiesDialogComponent implements OnInit {

  @Input()
  set taskCategory(taskCategory: TaskCategory) {
    this._taskCategory = taskCategory;
    this.title = (this._taskCategory.characteristicType === 'LoanCharacteristic' ? 'Loan ' : 'Borrower ') + 'Characteristics Quantities';
  }

  @Input()
  set characteristics(characteristics: ICharacteristic[]) {
    this._characteristics = _.cloneDeep(characteristics);
  }

  get characteristics() {
    return this._characteristics;
  }

  @Input()
  nextCharacteristicId: number;

  @Input()
  applicationId: number;

  @Input()
  ownerId: number | undefined;

  @Input()
  characteristicFactory: ICharacteristicFactory;

  get taskCategory(): TaskCategory {
    return this._taskCategory;
  }

  title: string = "";

  private _taskCategory: TaskCategory;

  private _characteristics: ICharacteristic[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  onAddClicked = () => {
    const newOne = this.characteristicFactory.create(this.nextCharacteristicId--, this.applicationId, this._taskCategory.taskCategoryId, this.ownerId);
    this.taskCategory.fields.forEach(f => {
      newOne.characteristicMergeValues.push(new CharacteristicMergeValue(f.characteristicFieldId));
    });
    this.characteristics.push(newOne);
  }

  onUpdateClicked = () => {
    this.activeModal.close(this._characteristics.filter(c => !c.pendingDeletion));
  }
}
