<div class="table-responsive" [hidden]="fees.length === 0">
  <table class="table mb-0 table-centered">
    <thead>
      <tr>
        <th class="min-width-300p"></th>
        <th class="min-width-400p">Borrower Paid</th>
        <th class="min-width-400p">Seller Paid</th>
        <th class="min-width-150p">Total Fee</th>
        <th width="50px"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let fee of fees; let i = index">
        <tr>
          <td>
            <span
              [ngStyle]="{
                fontWeight: fee.isSummaryFee ? 'bold' : '',
                marginLeft:
                  !fee.isSummaryFee && fee.sumInHudNumber ? '10px' : null
              }"
            >
              <span *ngIf="fee.sumInHudNumber !== null"
                >&nbsp;&nbsp;&nbsp;</span
              >
              <span *ngIf="!fee.isSummaryFee" class="me-2">
                <a
                  class="text-primary"
                  style="font-size: 0.8em"
                  (click)="onFeeEditorOpened(fee)"
                >
                  <i class="fa fa-external-link-alt"></i>
                </a>
              </span>
              <label>
                {{ fee.hudNumber ? fee.hudNumber + ' - ' : '' }} {{ fee.name }}
              </label>
            </span>
          </td>
          <td>
            <div class="row">
              <currency-input class="col-md-6"
                [id]="'borrowerDollar_' + fee.loanFeeId"
                [name]="'borrowerDollar_' + fee.loanFeeId"
                [(ngModel)]="fee.borrowerFeeDollar"
                [hidden]="fee.isSummaryFee"
                [disabled]="!fee.calculatedValues.isChargeUserEditable"
                [required]="true"
                (change)="onDollarChanged(fee, 'borrower')"
              >
              </currency-input>
              <percent-input class="col-md-6"
                *ngIf="!!fee.feePercentOf"
                [id]="'borrowerPercent_' + fee.loanFeeId"
                [name]="'borrowerPercent_' + fee.loanFeeId"
                [(ngModel)]="fee.borrowerFeePercent"
                [hidden]="fee.isSummaryFee"
                (ngModelChange)="onPercentChanged(fee, 'borrower')"
              ></percent-input>
            </div>
          </td>
          <td>
            <div class="row">
              <currency-input class="col-md-6"
                [id]="'sellerDollar_' + fee.loanFeeId"
                [name]="'sellerDollar_' + fee.loanFeeId"
                [hidden]="fee.isSummaryFee"
                [(ngModel)]="fee.sellerFeeDollar"
                (change)="onDollarChanged(fee, 'seller')"
              ></currency-input>
              <percent-input class="col-md-6"
                *ngIf="!!fee.feePercentOf"
                [id]="'sellerPercent_' + fee.loanFeeId"
                [name]="'sellerPercent_' + fee.loanFeeId"
                [hidden]="fee.isSummaryFee"
                [(ngModel)]="fee.sellerFeePercent"
                (ngModelChange)="onPercentChanged(fee, 'seller')"
              ></percent-input>
            </div>
          </td>
          <td>
            <currency-input
              [id]="'totalFee_' + fee.loanFeeId"
              [name]="'totalFee_' + fee.loanFeeId"
              [hidden]="fee.isSummaryFee"
              [(ngModel)]="fee.calculatedValues.totalFee"
              [disabled]="true"
            >
            </currency-input>
          </td>
          <td>
            <span *ngIf="deleteAllowed && !fee.isSummaryFee">
              <a
                class="text-danger"
                [hidden]="pendingDeleteIndex == i"
                (click)="pendingDeleteIndex = i"
                ><i class="fa fa-trash-alt"></i
              ></a>
              <a
                class="text-success mx-2"
                [hidden]="pendingDeleteIndex != i"
                (click)="removeFee(fee)"
                ><i class="fa fa-check"></i
              ></a>
              <a
                class="text-danger"
                [hidden]="pendingDeleteIndex != i"
                (click)="pendingDeleteIndex = -1"
                ><i class="fa fa-times"></i
              ></a>
            </span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
