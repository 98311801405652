<div class="card-body">
    <div class="table-responsive">
        <table class="table mb-0 table-centered">
            <thead class="table-light text-center">
                <tr>
                    <th>Appraisal ID</th>
                    <th>Requested Date</th>
                    <th class="text-start">Appraisal Forms</th>
                    <th>Is Rush Order</th>
                    <th>Order Status</th>
                    <th class="text-start">Messages</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let history of appraisalHistory">
                    <td>{{history.thirdPartyOrderId}}</td>
                    <td>{{history.dateInserted | date: 'short'}}</td>
                    <td>
                        <ul class="bullet-list">
                            <li *ngFor="let aForm of history.appraisalForms">
                                {{aForm}}
                            </li>
                        </ul>
                    </td>
                    <td>{{history.isRushOrder | yesNo }}</td>
                    <td>{{history.orderStatus}}</td>
                    <td>
                        <ul class="bullet-list">
                            <li *ngFor="let msg of history.messages">
                                {{msg}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr *ngIf="appraisalHistory && !appraisalHistory.length">
                    <td colSpan="7">
                        <div class="text-center">
                            <span> No data found </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
