import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentType, LoanApplication, LoanDoc, Role, ThirdPartyCredential, ThirdPartyKeyValue, UserPermissions } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanDocsService } from '../../services/loan-docs.service';
import { LoanDocsDialogComponent } from '../loan-docs-dialog/loan-docs-dialog.component';
import * as _ from 'lodash';
import { DocMappingForVendorDialogComponent } from '../doc-mapping-for-vendor-dialog/doc-mapping-for-vendor-dialog.component';
import { VendorsList } from '../../models/vendors-list-model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';

@Component({
  selector: 'loan-docs-accordion',
  templateUrl: 'loan-docs-accordion.component.html',
  styleUrls: ['loan-docs-accordion.component.scss']
})

export class LoanDocsAccordionComponent implements OnInit {

  @Input()
  docType: DocumentType;

  @Input()
  documentTypesPerRoles: DocumentType[];

  @Input()
  set loanDocs(loanDocs: LoanDoc[]) {
    this.editLoanDocs = loanDocs;
    this.filteredDocs = loanDocs
      .filter(d => d.documentTypeId == this.docType.documentTypeId)
      .map(d => ({
        ...d,
        linked: this.getLoanDocLinked(d),
      }));
    this._filteredDocs = [...this.filteredDocs];
  }

  @Input()
  isTpoUser: boolean;

  @Input()
  userPermissions: UserPermissions;

  @Input()
  currentApplication: LoanApplication;

  @Input()
  roles: Role[];

  @Input()
  vendorsList: VendorsList;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  set docFilter(filter: string) {
    if (filter === 'loda') {
      this.filteredDocs = this._filteredDocs.filter(ld => ld.loanDocId != null);
    } else if (filter === 'los') {
      this.filteredDocs = this._filteredDocs.filter(ld => ld.losLoanDocId != null);
    } else {
      this.filteredDocs = [...this._filteredDocs];
    }
  }

  @Output()
  loanDocUpdated: EventEmitter<LoanDoc> = new EventEmitter<LoanDoc>();

  columns: any[];

  activeDeletionIndex: number = -1;

  isEdit: boolean = false;

  dateFormat = 'yyyy-MM-dd';

  filteredDocs: LoanDoc[] = [];

  showESignTaskOption: boolean;

  icOcrEnabled: boolean;

  losVendorStr: string;

  private _isDocumentSigningRequiredReview: boolean = undefined;

  private _filteredDocs: LoanDoc[] = [];

  private readonly _modalOptions: NgbModalOptions;

  editLoanDocs: LoanDoc[];

  constructor(private readonly _modalService: NgbModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _dashboardService: DashboardService) {
    this._modalOptions = {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'loan-docs-task',
      scrollable: true,
    };
  }

  ngOnInit() {

    this.columns = [
      { field: 'action', header: 'Action', sortable: false },
      { field: 'description', header: 'Description', sortable: true },
      { field: 'note', header: 'Note', sortable: true },
      { field: 'loanDocId', header: 'Linked', sortable: true },
      { field: 'docFiles', header: 'View File', sortable: true },
      { field: 'expirationDate', header: 'Expiration Date', sortable: true },
    ];

    if (this.isTpoUser) {
      this.columns.shift(); // remove action column
    }

    this.showESignTaskOption = this.userPermissions.documentSigningEnabled;
    this.icOcrEnabled = this.userPermissions.ocrEnabled;
  }

  editLoanDoc = (loanDoc: LoanDoc) => {
    let modalRef = this._modalService.open(LoanDocsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.loanDoc = loanDoc;
    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.roleId = this.userPermissions.roleId;
    modalRef.componentInstance.docTypes = this.documentTypesPerRoles;
    modalRef.componentInstance.title = "Edit Loan Doc";
    modalRef.componentInstance.autoConvertToPdf = this.userPermissions.autoConvertToPdf;
    modalRef.result.then(result => {
      this.loanDocUpdated.emit(result);
    },
      err => { }
    );
  }

  onAddMappingForVendorClicked = (loanDoc: LoanDoc) => {
    const modalRef = this._modalService.open(DocMappingForVendorDialogComponent, Constants.modalOptions.medium)
    modalRef.componentInstance.title = `Add ${loanDoc.title} Mapping`;
    modalRef.componentInstance.doc = loanDoc;
    modalRef.componentInstance.docTypes = this.documentTypesPerRoles.filter(doc => doc.documentTypeId > -1);
    modalRef.componentInstance.companyId = this.userPermissions.companyId;
    modalRef.componentInstance.losVendor = this.filteredVendors(this.vendorsList, this.currentApplication.losVendor);
    modalRef.result.then((result) => {
      if (result === 'cancel') {
        return;
      }
      this.loanDocUpdated.emit(loanDoc);
    }, (res) => {
    });
  }

  onLoanDocUpdated = (loanDoc: LoanDoc) => {
    this.loanDocUpdated.emit(loanDoc);
    this._dashboardService.documentDeleted.next(null);
  }

  addNewTask = (condition: boolean, requestBorrower: boolean, eSignTask: boolean, loanDoc: LoanDoc) => {

    if (this._isDocumentSigningRequiredReview === undefined) {
      this._loanDocsService.loadDocumentSigningReviewRequiredConfig().subscribe(result => {
        this._isDocumentSigningRequiredReview = result;
        if (this._isDocumentSigningRequiredReview === undefined)
          this._isDocumentSigningRequiredReview = false;

        this.addNewTask(condition, requestBorrower, eSignTask, loanDoc);
      });
      return;
    }

    let task = new LoanDocDashboardTask();

    task.documentTypeId = 0;
    task.applicationId = this.currentApplication.applicationId;
    task.description = '';
    task.note = '';
    task.loanDocTaskId = 0;
    task.taskType = 'RequestDocument';
    task.dueDays = 0;
    task.taskPriority = 'Normal';
    task.userId = this.userPermissions.userId;
    task.condition = condition;
    task.requestBorrower = requestBorrower;
    task.multipleBorrower = [];

    if (eSignTask) {
      task.taskType = 'EsignDocument';
      task.requiresReview = this._isDocumentSigningRequiredReview;
      if (task.requiresReview) {
        task.reviewPartyId = task.userId;
      }
    }

    if (!_.isEmpty(loanDoc)) {
      let docFiles = [];
      loanDoc.docFiles.forEach(docFile => {
        let file: FileDto = new FileDto();
        file.active = docFile.active;
        file.borrowerNote = docFile.borrowerNote;
        file.condition = docFile.condition;
        file.docFileType = docFile.docFileType;
        file.file = docFile.file;
        file.fileName = docFile.fileName;
        file.guid = docFile.guid;
        file.loanDocId = docFile.loanDocId;
        file.losDocFileId = docFile.losDocFileId;
        file.note = docFile.note;
        docFiles.push(file);
      })
      task.docFiles = docFiles
      task.loanDocId = loanDoc.loanDocId;
      if (eSignTask) {
        task.documentTypeId = loanDoc.documentTypeId;
        task.description = loanDoc.description;
        task.note = loanDoc.note;
      }
    }

    // let modalOptions = Constants.modalOptions.xlarge;
    let modalOptions: NgbModalOptions = {
      windowClass: 'modal-w-90',
      backdrop: 'static',
      centered: true,
    }
    // modalOptions.windowClass = 'modal-w-90';
    const modalRef = this._modalService.open(EditTaskDialogComponent, modalOptions);
    modalRef.componentInstance.task = _.cloneDeep(task);
    modalRef.result.then(result => {
      if (result === 'cancel') {
        return;
      }
      this.loanDocUpdated.emit(result);
    },
      err => { }
    );
  }

  getLoanDocLinked = (loanDoc: LoanDoc) => {
    if (loanDoc.loanDocId && loanDoc.losLoanDocId) {
      return 'Loda/LOS';
    } else if (loanDoc.loanDocId) {
      return 'Loda';
    } else if (loanDoc.losLoanDocId) {
      return 'LOS';
    } else {
      return '';
    }
  }

  onDeleteLoanDocCancelClicked = () => {
    this.activeDeletionIndex = -1;
  }

  onDeleteLoanDocClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onDeleteLoanDocConfirmClicked = (loanDoc: LoanDoc) => {
    this._spinner.show();
    this.activeDeletionIndex = -1;
    this._loanDocsService.deleteLoanDoc(loanDoc.loanDocId).subscribe(result => {
      this._spinner.hide();
      this._notifyService.showSuccess('The loan doc has been successfully removed!', 'Success');
      const index = this.filteredDocs.indexOf(loanDoc);
      if (index >= 0) {
        this.filteredDocs[index].documentTypeId = -1;
        this.filteredDocs.splice(index, 1);
        this.loanDocUpdated.emit(loanDoc);
      }
    }, err => {
      this._spinner.hide();
      this._notifyService.showError(err.message || 'Failed to remove the loan doc!', 'Failure');
    })
  }

  isMergeVisible = (loanDoc: LoanDoc) => {
    return loanDoc.docFiles && loanDoc.docFiles.filter(x => x.selectedForAction == true).length > 1;
  }

  onMergeFilesClicked = (loanDoc: LoanDoc) => {
    this._spinner.show();
    let docGuids = loanDoc.docFiles.filter(x => x.selectedForAction == true).map(x => x.guid);
    this._loanDocsService.setMergeFiles(this.currentApplication.applicationId, loanDoc.loanDocId, docGuids).subscribe(result => {
      this._spinner.hide();
      loanDoc.docFiles = result.docFiles;
      this._notifyService.showSuccess('Successfully merged ' + docGuids.length + ' documents!', 'Success');
    }, err => {
      this._spinner.hide();
      this._notifyService.showError(err.message || 'Error while merging documents!' + err ? err.error.message || err : '', 'Failure');
    })
  }

  onSyncLoanDocWithLosClicked = (loanDoc: LoanDoc) => {
    if (this.isLosInfoMissing()) {
      return;
    }
    this._spinner.show();
    this._loanDocsService.syncLoanDocWithLos(loanDoc).subscribe(result => {
      loanDoc = result;
      this._spinner.hide();
      this._notifyService.showSuccess('Pushed to los successfully!', 'Success');
    }, err => {
      this._spinner.hide();
      this._notifyService.showError(err.message || 'Unable to push to los', 'Failure');
    })
  }

  private filteredVendors = (vendorsList: VendorsList, losVendor: string) => {
    if (vendorsList.values.length === 0) return "";
    const index = vendorsList.values.findIndex(value => value === losVendor);
    this.losVendorStr = vendorsList.names[index];
    return this.losVendorStr;
  }

  isLosInfoMissing = () => {
    if (!this.currentApplication) {
      return true;
    }
    return !this.currentApplication.losVendor || !this.currentApplication.refNumber || !this.currentApplication.losInstanceId;
  }
}
