import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChecklistsRoutingModule } from './checklists-routing.module';
import { ChecklistsQuestionsComponent } from './components/checklists-questions/checklists-questions.component';
import { ChecklistsChecklistComponent } from './components/checklists-checklist/checklists-checklist.component';
import { ChecklistEvaluatorComponent } from './components/checklist-evaluator/checklist-evaluator.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';
import { QuestionDetailsDialogComponent } from './dialogs/question-details-dialog/question-details-dialog.component';
import { QuestionAnswersDialogComponent } from './dialogs/question-answers-dialog/question-answers-dialog.component';
import { FormsModule } from '@angular/forms';
import { QuestionAnswerDetailsDialogComponent } from './dialogs/question-answer-details-dialog/question-answer-details-dialog.component';
import { CheckListItemDetailsDialogComponent } from './dialogs/check-list-item-details-dialog/check-list-item-details-dialog.component';
import { CheckListItemEvalDialogComponent } from './dialogs/check-list-item-eval-dialog/check-list-item-eval-dialog.component';
import { CheckListItemTreelistDialogComponent } from './dialogs/check-list-item-treelist-dialog/check-list-item-treelist-dialog.component';
import { EvalQuestionDialogComponent } from './dialogs/eval-question-dialog/eval-question-dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpsertQuestionComponent } from './components/upsert-question/upsert-question.component';
import { ChecklistEvaluatorAllQuestionsVisibleComponent } from './components/checklist-evaluator-all-questions-visible/checklist-evaluator-all-questions-visible.component';

@NgModule({
  declarations: [
    ChecklistsQuestionsComponent,
    ChecklistsChecklistComponent,
    QuestionDetailsDialogComponent,
    QuestionAnswersDialogComponent,
    QuestionAnswerDetailsDialogComponent,
    CheckListItemDetailsDialogComponent,
    CheckListItemEvalDialogComponent,
    CheckListItemTreelistDialogComponent,
    EvalQuestionDialogComponent,
    UpsertQuestionComponent,
    ChecklistEvaluatorComponent,
    ChecklistEvaluatorAllQuestionsVisibleComponent
  ],
  exports: [
    ChecklistEvaluatorComponent,
    ChecklistEvaluatorAllQuestionsVisibleComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChecklistsRoutingModule,
    TableModule,
    MultiSelectModule,
    FormsModule,
    TreeModule,
    NgSelectModule
  ],
})
export class ChecklistsModule { }
