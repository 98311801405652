import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { Conversation, Profile } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private _dataService: DataService) { }

  isChatWindowOpen: boolean = false;

  getProfile = (userCompanyGuid: string): Observable<Profile> => {
    const url = 'api/Admin/Profile/' + userCompanyGuid;
    return this._dataService.get(url);
  }

  getAppRelatedEntities = (appId: number) => {
    const url = 'api/chat/GetAppRelatedEntities/' + appId;
    return this._dataService.get(url);
  }

  getRelatedEntities = (betweenPhone: string) => {
    const url = 'api/chat/GetRelatedEntities/';
    return this._dataService.post(url, { betweenPhone });
  }

  getBorrowerChatMessages = (appId: number): Observable<any> => {
    const url = 'api/chat/GetBorrowerChatMessages/' + appId;
    return this._dataService.get(url);
  }

  getAgentChatMessages = (appId: number, agentId: number): Observable<any> => {
    const url = `api/chat/GetAgentChatMessages/${appId}/${agentId}`;
    return this._dataService.get(url);
  }

  getInternalChatMessages = (appId: number): Observable<any> => {
    const url = 'api/chat/GetInternalChatMessages/' + appId;
    return this._dataService.get(url);
  }

  getSmsChatMessages = (userPhone: string, betweenPhone: string) => {
    const url = 'api/chat/GetSmsChatMessages';
    return this._dataService.post(url, { userPhone, betweenPhone });
  }

  getGroupChatMessages = (conversationId: number) => {
    const url = `api/chat/getGroupChatMessages/${conversationId}`;
    return this._dataService.get(url, {});
  }

  sendSms = (sms: any, impersonatedUserCompanyGuid?: string) => {
    let url = 'api/Sms/send';
    if (impersonatedUserCompanyGuid)
      url += "?impersonatedUserCompanyGuid=" + impersonatedUserCompanyGuid;
    return this._dataService.post(url, sms);
  }

  sendGroupMms = (sms: any, impersonatedUserCompanyGuid?: string) => {
    sms.fromUserId = impersonatedUserCompanyGuid;
    let url = 'api/Sms/send-group-mms';
    if (impersonatedUserCompanyGuid)
      url += "?impersonatedUserCompanyGuid=" + impersonatedUserCompanyGuid;
    return this._dataService.post(url, sms);
  }

  queueSms = (sms: any) => {
    const url = 'api/Sms/queue';
    return this._dataService.post(url, sms);
  }

  /** used to get borrower messages and SMSes from API */
  getConversations = (): Observable<Conversation[]> => {
    const url = "api/open-conversation/user-conversations"
    return this._dataService.get(url);
  }

  toggleOpened = (openConversationId: string): Observable<any> => {
    const url = `api/open-conversation/toggle-opened/${openConversationId}`;
    return this._dataService.patch(url, {});
  }

  markAsRead = (openConversationId: string): Observable<boolean> => {
    const url = `api/open-conversation/mark-as-read/${openConversationId}`;
    return this._dataService.patch(url, {});
  }

  getMediaFile = (id: number) => {
    const url = 'api/Sms/media-file/' + id;
    return this._dataService.get(url);
  }
}
