<div class="modal-header">
  <h5 class="modal-title w-100">Loan Fees</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  <div class="row">
    <p-table #dt1 [value]="histories" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 100px">HUD Number</th>
          <th style="min-width: 100px">Fee Type</th>
          <th style="min-width: 100px">Name</th>
          <th style="min-width: 100px">Total</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-history let-index>
        <tr>
          <td>{{ history.hudNumber }}</td>
          <td>{{ getEnumName(history.feeType) }}</td>
          <td>{{ history.name }}</td>
          <td>
            {{ history?.calculatedValues?.borrowerTotal || 0 | currency }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4" class="text-center">No history found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
