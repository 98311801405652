import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListColumnDefinition } from 'src/app/models';
import { FeeDefinitionModel } from 'src/app/models/fee/fee-definition.model';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeTemplateFee } from 'src/app/models/fee/fee-template-fee.model';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'lender-config-fees-library',
  templateUrl: './lender-config-fees-library.component.html',
  styleUrls: ['./lender-config-fees-library.component.scss']
})
export class LenderConfigFeesLibraryComponent implements OnInit {

  get feeDefinitions(): FeeTemplateFee[] {
    return this._feeDefinitions;
  }

  @Input()
  set feeDefinitions(value: FeeTemplateFee[]) {
    this._feeDefinitions = value;
    if (value) {
      this.adjustFeeGroups();
    }
  }

  @Input() title: string = "Fees Library";
  @Input() defaultPlaceholder: string;
  @Input() template: FeeTemplate = null;

  @Output()
  onTemplateFeesChanged: EventEmitter<FeeTemplateFee[]> = new EventEmitter<FeeTemplateFee[]>();

  realEstateFees: FeeTemplateFee[] = [];
  originationFees: FeeTemplateFee[] = [];
  servicesNoShopFees: FeeTemplateFee[] = [];
  servicesFees: FeeTemplateFee[] = [];
  governmentTaxesAndFees: FeeTemplateFee[] = [];
  prepaidsFees: FeeDefinitionModel[] = [];
  escrowFees: FeeDefinitionModel[] = [];
  otherFees: FeeTemplateFee[] = [];

  expressionFields: ContactListColumnDefinition[] = [];

  private _feeDefinitions: FeeTemplateFee[];
  private _libraryFeeDefinitions: FeeDefinitionModel[] = [];

  constructor(
    private readonly _feeService: FeeService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllFeeDefinitions();
    this.getContactListColumns();
  }

  templateFeesChanged = (fees: FeeTemplateFee[]) => {
    this.onTemplateFeesChanged.emit(fees)
  }

  private getAllFeeDefinitions = () => {
    this._spinner.show();
    this._feeService.getFeeDefinitions()
      .subscribe(definitions => {
        this._libraryFeeDefinitions = definitions;
        this.feeDefinitions = definitions as FeeTemplateFee[];

        this.adjustFeeGroups();
        this._spinner.hide();

      }, (err) => {
        this._spinner.hide()
        this._notifyService.showError(err?.message || err.error, "Error!");
      });
  }

  private getContactListColumns = () => {
    this._feeService.getContactListColumnsForExpressionBuilder()
      .subscribe(columns => {
        this.expressionFields = columns;
      }, (err) => {
        this._notifyService.showError(err?.message || err.error, "Error!");
      })
  }

  private adjustFeeGroups = (): void => {

    this.realEstateFees = [];
    this.originationFees = [];
    this.servicesNoShopFees = [];
    this.servicesFees = [];
    this.governmentTaxesAndFees = [];
    this.prepaidsFees = [];
    this.escrowFees = [];
    this.otherFees = [];

    this.feeDefinitions.forEach((fee) => {

      if (this._feeService.isRealEstateFee(fee)) {
        this.realEstateFees.push(fee);
      } else if (fee.feeSection === FeeSectionEnum.Origination) {
        this.originationFees.push(fee);
      } else if (fee.feeSection === FeeSectionEnum.ServicesNoShop) {
        this.servicesNoShopFees.push(fee);
      } else if (fee.feeSection === FeeSectionEnum.Services) {
        this.servicesFees.push(fee);
      } else if (fee.feeSection === FeeSectionEnum.GovernmentTaxesAndFees) {
        this.governmentTaxesAndFees.push(fee);
      } else if (fee.feeSection === FeeSectionEnum.Other) {
        this.otherFees.push(fee);
      } else if (fee.feeSection === FeeSectionEnum.Prepaids) {
        this.prepaidsFees.push(fee);
      }
    });

    this._libraryFeeDefinitions.forEach(fee => {
      if (fee.feeSection === FeeSectionEnum.Prepaids) {
        this.prepaidsFees.push(fee);
      }
      else if (fee.feeSection === FeeSectionEnum.Escrow) {
        this.escrowFees.push(fee);
      }
    })

  }

}
