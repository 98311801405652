import { Component, OnInit, Input } from '@angular/core';
import { LoanFee } from 'src/app/models/fee/fee.model';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeUtils } from '../../services/fee-utils.service';

@Component({
  selector: 'fee-comparison',
  templateUrl: 'fee-comparison.component.html',
  styleUrls: ['./fee-comparison.component.scss']
})
export class FeeComparisonComponent implements OnInit {

  @Input()
  set allFees(allFees: FeeCompilation) {
    this._allFees = allFees;
    this.feeComparisonRows = this.prepareFeeComparisonTable(allFees);
    this._selectedFees = this.feeComparisonRows.map(r => r.selectedFee).filter(f => f);
    this.calculateTotalAmountsBySection();
  }

  @Input()
  set selectedFees(selectedFees: FeeToApplyToLoan[]) {
    this._selectedFees = selectedFees;
  }

  get selectedFees(): FeeToApplyToLoan[] {
    return this._selectedFees;
  }

  feeComparisonRows: FeeComparisonRow[] = [];

  feeSectionDisplayNames: any = {};

  totalAmountBySection: Map<FeeSectionEnum, number> = new Map<FeeSectionEnum, number>();

  private _selectedFees: FeeToApplyToLoan[] = null;

  private _allFees: FeeCompilation;

  private _feeSectionMetaData: FeeSectionMetaData[] = [];

  constructor(private readonly _feeUtils: FeeUtils) {
    this.initializeSectionDisplayNames();
    this.initializeFeeSectionMetaData();
  }

  ngOnInit() {
    console.log(this._allFees);
  }

  onFeeCellClicked = (row: FeeComparisonRow, type: FeeAreaOnComparisonRow) => {
    const targetFee = row[type];
    if (!targetFee) {
      return;
    }
    if (row.vendorFee) {
      return;
    }
    const isSelected = targetFee.isSelected;

    if (!isSelected && row.isDuplicate) {
      const self = this;
      Swal.fire({
        title: 'Are you sure?',
        text: 'This is a duplicate fee. Selecting this will un-select all other duplicate ones?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      }).then(function (result: any) {
        if (result.value) {
          self.unselectAllFeePieces(row);
          const duplicateRows = self.feeComparisonRows.filter(r => r.hudNumber === row.hudNumber);
          duplicateRows.forEach(row => {
            row.selectedFee = null;
            if (row[type]) {
              row[type].isSelected = false;
            }
          });
          targetFee.isSelected = !isSelected;
          self.onAfterFeeSelectionChanged(row, targetFee, type);
        }
      });
    } else {
      this.unselectAllFeePieces(row);
      targetFee.isSelected = !isSelected;
      this.onAfterFeeSelectionChanged(row, targetFee, type);
    }
  }

  private onAfterFeeSelectionChanged = (row: FeeComparisonRow, targetFee: any, type: FeeAreaOnComparisonRow) => {
    row.selectedFeeAmountAndPayerDisplayText = "";
    if (targetFee.isSelected) {
      row.selectedFeeAmountAndPayerDisplayText = targetFee.amountAndPayerDisplayText;
      this.setSelectedFeeOnRowAfterCellClicked(row, type);
    }
    this.calculateTotalAmountsBySection();
    this._selectedFees = this.feeComparisonRows.map(r => r.selectedFee).filter(f => f);
  }

  private setSelectedFeeOnRowAfterCellClicked = (row: FeeComparisonRow, type: FeeAreaOnComparisonRow) => {
    switch (type) {
      case FeeAreaOnComparisonRow.ExistingLoanFee:
        const existingFee = this._allFees.existingFees.find(fee => fee.feeSection === row.section && fee.hudNumber === row.hudNumber);
        row.selectedFee = existingFee as FeeToApplyToLoan;
        row.selectedFee.pickedFrom = PickedFeeFrom.Existing;
        return;
      case FeeAreaOnComparisonRow.TemplateFee:
        const templateFee = this._allFees.templateFees.find(fee => fee.feeSection === row.section && fee.hudNumber === row.hudNumber);
        row.selectedFee = templateFee as FeeToApplyToLoan;
        row.selectedFee.pickedFrom = PickedFeeFrom.Template;
        return;
      case FeeAreaOnComparisonRow.CalculatedLoanFee:
        const calculated = this._allFees.calculatedFees.find(fee => fee.feeSection === row.section && fee.hudNumber === row.hudNumber);
        row.selectedFee = calculated as FeeToApplyToLoan;
        row.selectedFee.pickedFrom = PickedFeeFrom.Calculated;
        return;
      case FeeAreaOnComparisonRow.VendorFee:
        const vendorFee = this._allFees.vendorFees.find(fee => fee.feeSection === row.section && fee.hudNumber === row.hudNumber);
        row.selectedFee = vendorFee as FeeToApplyToLoan;
        row.selectedFee.pickedFrom = PickedFeeFrom.Vendor;
        return;
    }
  }

  private prepareFeeComparisonTable = (allFees: FeeCompilation): FeeComparisonRow[] => {
    let feeComparisonRows: FeeComparisonRow[] = [];

    allFees.existingFees.forEach(ef => {
      let comparisonRow = new FeeComparisonRow();
      comparisonRow.hudNumber = ef.hudNumber;
      comparisonRow.section = ef.feeSection;
      comparisonRow.feeType = ef.feeType;
      comparisonRow.name = ef.name;
      comparisonRow.existingLoanFee = this.convertLoanFeeToFeeInfo(ef);
      comparisonRow.existingLoanFee.isSelected = true;
      comparisonRow.selectedFee = ef as FeeToApplyToLoan;
      comparisonRow.selectedFee.pickedFrom = PickedFeeFrom.Existing
      comparisonRow.selectedFeeAmountAndPayerDisplayText = this._feeUtils.getSingleAmountAndMultiPayerDisplayText(ef);
      const sectionMedatData = this._feeSectionMetaData.find(md => md.name === comparisonRow.section);
      if (sectionMedatData) {
        comparisonRow.order = sectionMedatData.order;
      }
      feeComparisonRows.push(comparisonRow);
    });

    allFees.templateFees.forEach(tf => {
      this.processFeeAndAddToComparisonRow(feeComparisonRows, tf, FeeAreaOnComparisonRow.TemplateFee, PickedFeeFrom.Template);
    });

    allFees.calculatedFees.forEach(cf => {
      this.processFeeAndAddToComparisonRow(feeComparisonRows, cf, FeeAreaOnComparisonRow.CalculatedLoanFee, PickedFeeFrom.Calculated);
    });

    allFees.vendorFees.filter(x => x.calculatedValues?.totalFee > 0 || x.calculatedValues?.totalFeePercent > 0).forEach(vf => {
      this.processFeeAndAddToComparisonRow(feeComparisonRows, vf, FeeAreaOnComparisonRow.VendorFee, PickedFeeFrom.Vendor);
    });

    feeComparisonRows = _.map(feeComparisonRows, f => {
      var duplicates = _.filter(feeComparisonRows, e => {
        return !!f.hudNumber && e.hudNumber === f.hudNumber;
      });
      f.isDuplicate = duplicates.length > 1;
      return f;
    })

    return _.orderBy(feeComparisonRows, ['order', 'hudNumber'], ['asc', 'asc']);;
  }

  private processFeeAndAddToComparisonRow = (feeComparisonRows: FeeComparisonRow[], fee: LoanFee, areaToSetOnRow: FeeAreaOnComparisonRow,
    pickedFeeFrom: PickedFeeFrom) => {
    var existing =
      feeComparisonRows.find(x => (!!x.hudNumber && x.hudNumber == fee.hudNumber)
        || (!x.hudNumber && !!x.feeType && x.section == fee.feeSection && x.feeType == fee.feeType)
        || (!x.hudNumber && !x.feeType && x.section == fee.feeSection && x.name == fee.name));

    if (existing) {
      if (existing[areaToSetOnRow]) {
        // This is a dupe, cause there was a template/calculated/vendor fee in the row and now there is a new one coming
        // We need to create a new row
        let comparisonRow = this.createComparisonRowFromFee(fee, areaToSetOnRow);
        comparisonRow.isDuplicate = true;
        comparisonRow[areaToSetOnRow].isSelected = false;
        feeComparisonRows.push(comparisonRow);
      } else {
        this.unselectAllFeePieces(existing);
        existing[areaToSetOnRow] = this.convertLoanFeeToFeeInfo(fee);
        existing[areaToSetOnRow].isSelected = true;
        existing.selectedFee = fee as FeeToApplyToLoan;
        existing.selectedFee.pickedFrom = pickedFeeFrom;
        existing.selectedFeeAmountAndPayerDisplayText = this._feeUtils.getSingleAmountAndMultiPayerDisplayText(fee);
        if (fee.name && !existing.name) {
          existing.name = fee.name;
        }
      }
    } else {
      let comparisonRow = this.createComparisonRowFromFee(fee, areaToSetOnRow);
      feeComparisonRows.push(comparisonRow);
    }
  }

  private createComparisonRowFromFee = (fee: LoanFee, areaToSetOnRow: FeeAreaOnComparisonRow) => {
    let comparisonRow = new FeeComparisonRow();
    comparisonRow.hudNumber = fee.hudNumber;
    comparisonRow.section = fee.feeSection;
    comparisonRow.feeType = fee.feeType;
    comparisonRow.name = fee.name;
    comparisonRow[areaToSetOnRow] = this.convertLoanFeeToFeeInfo(fee);
    comparisonRow[areaToSetOnRow].isSelected = true;
    comparisonRow.selectedFee = fee as FeeToApplyToLoan;
    comparisonRow.selectedFee.pickedFrom = PickedFeeFrom.Template;
    comparisonRow.selectedFeeAmountAndPayerDisplayText = this._feeUtils.getSingleAmountAndMultiPayerDisplayText(fee);
    const sectionMedatData = this._feeSectionMetaData.find(md => md.name === comparisonRow.section);
    if (sectionMedatData) {
      comparisonRow.order = sectionMedatData.order;
    }
    return comparisonRow;
  }

  private calculateTotalAmountsBySection = () => {
    for (let [key, value] of this.totalAmountBySection) {
      this.totalAmountBySection.set(key, 0);
    }
    this.feeComparisonRows.forEach(row => {
      if (row.selectedFee) {
        let totalBySection = this.totalAmountBySection.get(row.section);
        totalBySection += (row.selectedFee.calculatedValues.totalFee || 0);
        this.totalAmountBySection.set(row.section, totalBySection);
      }
    })
  }

  private unselectAllFeePieces = (fee: FeeComparisonRow) => {
    fee.selectedFee = null;
    if (fee.existingLoanFee) {
      fee.existingLoanFee.isSelected = false;
    }
    if (fee.templateFee) {
      fee.templateFee.isSelected = false;
    }
    if (fee.calculatedLoanFee) {
      fee.calculatedLoanFee.isSelected = false;
    }
    if (fee.vendorFee) {
      fee.vendorFee.isSelected = false;
    }
  }

  private convertLoanFeeToFeeInfo = (loanFee: LoanFee): FeeInfo => {
    const feeInfo = new FeeInfo();
    feeInfo.amountAndPayerDisplayText = this._feeUtils.getSingleAmountAndMultiPayerDisplayText(loanFee);
    feeInfo.amount = loanFee.calculatedValues.totalFee;
    feeInfo.name = loanFee.name;
    if (loanFee.loanFeeId) {
      feeInfo.id = loanFee.loanFeeId.toString();
    } else {
      feeInfo.id = uuidv4();
    }
    return feeInfo;
  }

  private initializeFeeSectionMetaData = () => {
    const realEstateCommission = {
      name: FeeSectionEnum.RealEstateCommission,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.RealEstateCommission],
      order: 1
    };

    const origination = {
      name: FeeSectionEnum.Origination,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.Origination],
      order: 2
    };

    const nonShoppableServices = {
      name: FeeSectionEnum.ServicesNoShop,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.ServicesNoShop],
      order: 3
    };

    const shoppableServices = {
      name: FeeSectionEnum.Services,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.Services],
      order: 4
    };

    const governmentTaxesAndFees = {
      name: FeeSectionEnum.GovernmentTaxesAndFees,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.GovernmentTaxesAndFees],
      order: 5
    };

    const prepaid = {
      name: FeeSectionEnum.Prepaids,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.Prepaids],
      order: 6
    };

    const escrow = {
      name: FeeSectionEnum.Escrow,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.Escrow],
      order: 7
    };

    const other = {
      name: FeeSectionEnum.Other,
      displayName: this.feeSectionDisplayNames[FeeSectionEnum.Other],
      order: 8
    }

    this._feeSectionMetaData = [realEstateCommission, origination, nonShoppableServices,
      shoppableServices, governmentTaxesAndFees, prepaid, escrow, other];
  }

  private initializeSectionDisplayNames = () => {
    this.feeSectionDisplayNames[FeeSectionEnum.Origination] = 'A. ORIGINATION CHARGES 800. Items Payable in Connection with Loan';
    this.feeSectionDisplayNames[FeeSectionEnum.Escrow] = 'G. INITIAL ESCROW PAYMENT AT CLOSING 1000. Reserves Deposited with Lender';
    this.feeSectionDisplayNames[FeeSectionEnum.RealEstateCommission] = 'Real Estate Commission';
    this.feeSectionDisplayNames[FeeSectionEnum.Services] = 'C. SERVICES BORROWER CAN SHOP FOR 1100. Title Charge';
    this.feeSectionDisplayNames[FeeSectionEnum.ServicesNoShop] = 'B. SERVICES BORROWER DID NOT SHOP FOR';
    this.feeSectionDisplayNames[FeeSectionEnum.GovernmentTaxesAndFees] = 'E. TAXES AND OTHER GOVERNMENT FEES 1200. Government Recording & Transfer Charges';
    this.feeSectionDisplayNames[FeeSectionEnum.Other] = 'H. OTHER 1300. Additional Settlement Charges';
    this.feeSectionDisplayNames[FeeSectionEnum.Prepaids] = 'F. PREPAID 900. Items Required by Lender to Be Paid in Advance';
    Object.keys(FeeSectionEnum).forEach(section => {
      this.totalAmountBySection.set(section as FeeSectionEnum, 0);
    })
  }
}
export class FeeComparisonRow {
  section: FeeSectionEnum;
  feeType: string;
  name: string;
  hudNumber: string;
  existingLoanFee: FeeInfo;
  templateFee: FeeInfo;
  calculatedLoanFee: FeeInfo;
  vendorFee: FeeInfo;

  selectedFee: FeeToApplyToLoan;
  selectedFeeAmountAndPayerDisplayText: string;
  order: number;

  isDuplicate: boolean;

  get isLockedForSelection(): boolean {
    return this.vendorFee != null;
  }
}

export class FeeInfo {
  amount: number;
  amountAndPayerDisplayText: string;
  payer: string;
  id: string;
  name: string;
  isSelected: boolean = false;
}

export class FeeCompilation {
  existingFees: LoanFee[] = [];
  templateFees: LoanFee[] = [];
  calculatedFees: LoanFee[] = [];
  vendorFees: LoanFee[] = [];
}

export class FeeSectionMetaData {
  name: FeeSectionEnum;
  displayName: string;
  order: number;
}

export class FeeToApplyToLoan extends LoanFee {
  pickedFrom: PickedFeeFrom;
}

export enum PickedFeeFrom {
  Existing = 'Existing',
  Template = 'Template',
  Calculated = 'Calculated',
  Vendor = 'Vendor'
}

export enum FeeAreaOnComparisonRow {
  ExistingLoanFee = "existingLoanFee",
  TemplateFee = "templateFee",
  CalculatedLoanFee = "calculatedLoanFee",
  VendorFee = "vendorFee"
}
