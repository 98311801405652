import { FeeTemplateFee } from './fee-template-fee.model';

export class FeeTemplate {
  feeTemplateId?: number;
  name?: null | string;
  expressionId?: number;
  enabledChannels?: null | string;
  inactive: boolean;
  companyId?: number;
  insertedBy?: null | string;
  dateInserted: Date;
  updatedBy?: null | string;
  dateUpdated: Date;
  fees: FeeTemplateFee[];
}
