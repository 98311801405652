import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { LoanStatus, Role } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanStatusAssociation } from '../../../../models';
import { LoanStatusAssociationService } from '../../../../services';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: "upsert-loan-status-association",
  templateUrl: 'upsert-loan-status-association.component.html',
})
export class UpsertLoanStatusAssociation implements OnInit, OnDestroy {

  @Input()
  loanStatusAssociation: LoanStatusAssociation;

  @Input()
  loanStatuses: Array<LoanStatus>;

  @Input()
  loanPurposes: Array<LoanPurpose>;

  @Input()
  enabledChannels: Array<EnumerationItem>;

  @Input()
  permissions: {
    isCreditReportingEnabled: boolean;
    isLosEnabled: boolean;
    isVoaEnabled: boolean;
    isVoiEnabled: boolean;
    isDuEnabled: boolean;
    isLpaEnabled: boolean;
  };

  @Input()
  loanStatusAssociations: LoanStatusAssociation[];

  @Output()
  onSave: EventEmitter<LoanStatusAssociation> = new EventEmitter<LoanStatusAssociation>();

  @Output()
  onClose: EventEmitter<never> = new EventEmitter<never>();

  @ViewChild('upsertLoanStatusAssociationForm')
  upsertLoanStatusAssociationForm: NgForm | null;

  editMode: boolean;
  saving: boolean;

  disallowedThirdPartyServices: Array<EnumerationItem> = [];
  disallowedServicesModel = [];

  protected accessSelectionSettings: IDropdownSettings = Object.freeze({
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  });

  protected rolesForWhichReadOnly: EnumerationItem[] = [];
  protected rolesForWhichHidden: EnumerationItem[] = [];
  protected rolesForWhichReadOnlyOptions: EnumerationItem[] = [];

  private _contextSubscription: Subscription;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _loanStatusAssociationService: LoanStatusAssociationService,
    private readonly _enumerationService: EnumerationService,
    private readonly _applicationContextService: ApplicationContextService
  ) {
  }

  ngOnInit(): void {
    if (this.enabledChannels.length === 1) {
      this.loanStatusAssociation.channel = this.enabledChannels[0].name;
    } else if (!this.loanStatusAssociation.channel) {
      this.loanStatusAssociation.channel = null;
    }
    this._enumerationService.getMortgageEnumerations().subscribe((res) => {
      this.getDisallowedServicesEnum(res);
      this.disallowedServicesModel = this.loanStatusAssociation.disallowedThirdPartyServices
        ? this.loanStatusAssociation.disallowedThirdPartyServices.split(',')
        : [];
    });

    this.editMode = this.loanStatusAssociation.loanPurposeId ? true : false;
    if (!this.editMode) {
      this.loanStatusAssociation.loanStatusId = '';
      this.loanStatusAssociation.loanPurposeId = this.loanPurposes[0].loanPurposeId;
    }
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      const companyRoles = context.globalConfig.roles.filter(
        r => r.companyId === context.userPermissions.companyId,
      );
      this.rolesForWhichReadOnlyOptions = companyRoles.map(r => new EnumerationItem(
        r.roleName,
        r.roleId,
      ));
      this.rolesForWhichReadOnlyOptions = _.orderBy(this.rolesForWhichReadOnlyOptions, ["name"], ["asc"]);

      this.populateRolesForWhichReadOnly(companyRoles);
      this.populateRolesForWhichHidden(companyRoles);
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  onStatusChanged(loanStatusId) {
    let status = this.loanStatuses.find(x => x.loanStatusId == loanStatusId);
    if (status)
      this.loanStatusAssociation.borrowerFriendlyStatus = status.loanStatusName;
  }

  save() {
    if (this.upsertLoanStatusAssociationForm) {
      this.upsertLoanStatusAssociationForm.form.markAllAsTouched();
      if (!this.upsertLoanStatusAssociationForm.form.valid) return;
    }

    this.loanStatusAssociation.readOnlyForRoleIds = this.rolesForWhichReadOnly.map(r => r.value).join();
    this.loanStatusAssociation.hiddenFromPipelineForRoleIds = this.rolesForWhichHidden.map(r => r.value).join();

    this.loanStatusAssociation.disallowedThirdPartyServices = this.disallowedServicesModel.join();
    this.saving = true;
    this.editMode ? this.update() : this.insert();
  }

  private update() {
    this._loanStatusAssociationService.updateLoanStatusAssociation(this.loanStatusAssociation)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'Loan status associaton updated successfully',
            'Loan Status Associaton'
          );

          this.loanStatusAssociation['loanPurposeName'] = this.loanPurposes[0]?.loanPurposeName;
          this.loanStatusAssociation['loanStatusName'] = this.loanStatuses.find(ls => ls.loanStatusId == this.loanStatusAssociation.loanStatusId)?.loanStatusName

          this.onSave.emit(this.loanStatusAssociation)
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't update selected loan status associaton",
            'Loan Status Associaton'
          );
        }
      });
  }

  private insert() {
    let orderNumber = null;
    if (this.loanStatusAssociation.channel) {
      const filteredAssociations = this.loanStatusAssociations
        .filter(ls => ls.channel === this.loanStatusAssociation.channel);

      orderNumber = filteredAssociations.length > 0 ? filteredAssociations
        .sort((a, b) => a.order > b.order ? 1 : -1)[filteredAssociations.length - 1].order : 0;
    } else {
      const filteredAssociations = this.loanStatusAssociations
        .filter(ls => !ls.channel);

      orderNumber = filteredAssociations.length > 0 ? filteredAssociations
        .sort((a, b) => a.order > b.order ? 1 : -1)[filteredAssociations.length - 1].order : 0;
    }

    this.loanStatusAssociation.order = orderNumber + 1;

    this._loanStatusAssociationService.insertLoanStatusAssociation(this.loanStatusAssociation)
      .pipe(finalize(() => this.saving = false))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Loan status associaton added successfully',
            'Loan Status Associaton'
          );

          res['loanPurposeName'] = this.loanPurposes[0]?.loanPurposeName;
          res['loanStatusName'] = this.loanStatuses.find(ls => ls.loanStatusId == this.loanStatusAssociation.loanStatusId)?.loanStatusName

          this.onSave.emit(res);
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't insert new loan status associaton",
            'Loan Status Associaton'
          );
        }
      })
  }

  private populateRolesForWhichReadOnly = (companyRoles: Role[]) => {
    if (this.loanStatusAssociation.readOnlyForRoleIds) {
      const roleIds = this.loanStatusAssociation.readOnlyForRoleIds?.split(',').filter(rid => !!rid);
      roleIds.forEach(rid => {
        const role = companyRoles.find(r => r.roleId == Number(rid.trim()));
        if (role) {
          const selectedRole = new EnumerationItem(role.roleName, role.roleId);
          this.rolesForWhichReadOnly.push(selectedRole);
        }
      });
    }
  }

  private populateRolesForWhichHidden = (companyRoles: Role[]) => {
    if (this.loanStatusAssociation.hiddenFromPipelineForRoleIds) {
      const roleIds = this.loanStatusAssociation.hiddenFromPipelineForRoleIds?.split(',').filter(rid => !!rid);
      roleIds.forEach(rid => {
        const role = companyRoles.find(r => r.roleId == Number(rid.trim()));
        if (role) {
          const selectedRole = new EnumerationItem(role.roleName, role.roleId);
          this.rolesForWhichHidden.push(selectedRole);
        }
      });
    }
  }

  private getDisallowedServicesEnum(value) {
    const result = value['ThirdPartyServiceType'] || [];
    let params = [];

    if (this.permissions.isCreditReportingEnabled) {
      const item0 = result.find((el) => el.value == 'CreditHardPull'); // hard pull
      const item1 = result.find((el) => el.value == 'CreditSoftPull'); // soft pull
      params.push(item0);
      params.push(item1);
    }
    if (this.permissions.isLosEnabled) {
      const item = result.find((el) => el.value == 'Los');
      params.push(item);
    }
    if (this.permissions.isVoaEnabled) {
      const item = result.find((el) => el.value == 'Voa');
      params.push(item);
    }
    if (this.permissions.isVoiEnabled) {
      const item = result.find((el) => el.value == 'Voi');
      params.push(item);
    }
    if (this.permissions.isDuEnabled) {
      const item = result.find((el) => el.value == 'Du');
      params.push(item);
    }
    if (this.permissions.isLpaEnabled) {
      const item = result.find((el) => el.value == 'Lpa');
      params.push(item);
    }
    this.disallowedThirdPartyServices = params.map((p) => ({
      value: p.value,
      name: p.name,
    }));
  }
}
