<div class="row">
  <div class="col-md-2 text-end">
    <label class="custom-control-label">{{label}}</label>
  </div>
  <div class="col-md-8">
    <input type="text" class="form-control" [(ngModel)]="model" disabled>
  </div>
  <div class="col-md-2">
    <button class="btn btn-outline-primary waves-effect waves-light" ngxClipboard [cbContent]="model" [disabled]="disableCopy">Copy to Clipboard</button>
  </div>
</div>
