<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title d-flex align-items-center justify-content-between">
          <span><i class="fas fa-file-pdf-o me-2"></i> Disclosures Wizard</span>
          <span>
            <button *ngIf="(!atFirstStep && !isWizardCompleted) || hasCancelButton" type="button"
              class="btn btn-primary" (click)="onCancelClicked()">
              Cancel
            </button>
          </span>
        </h4>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <p-steps [model]="steps" [(activeIndex)]="activeStepIndex"></p-steps>
        </div>
        <div class="d-flex justify-content-center align-items-center"
          [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}" *ngIf="
              loadingData && !correctingData && !generatingProformaLe &&
              [2, 3].includes(activeStepIndexWithOffset) && generatedJob?.jobStatus != 'Completed' && !isWizardCompleted
            ">
          <div class="d-flex">
            <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
            <h5 class="font-18 text-muted me-4">
              {{
              jobType == 'audit'
              ? 'Fetching Audits'
              :
              'Queueing Disclosures'
              }}
              - {{ generatedJob?.jobStatus || 'Pending' }} ...
            </h5>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center"
          [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}" *ngIf="
            loadingData && !correctingData &&
            [3].includes(activeStepIndexWithOffset) && generatedJob?.jobStatus == 'Completed' && !isWizardCompleted
          ">
          <div class="d-flex">
            <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
            <h5 class="font-18 text-muted me-4">
              Syncing Loan File ...
            </h5>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center"
          [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}"
          *ngIf="correctingData && [1, 2].includes(activeStepIndexWithOffset)">
          <div class="d-flex">
            <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
            <h5 class="font-18 text-muted me-4">
              Correcting Loan Data ...
            </h5>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center"
          [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}"
          *ngIf="generatingProformaLe && [1, 2].includes(activeStepIndexWithOffset)">
          <div class="d-flex">
            <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
            <h5 class="font-18 text-muted me-4">
              Generating Proforma LE ...
            </h5>
          </div>
        </div>

        <div *ngIf="generatedJob?.jobStatus == 'Failed' && [2, 3].includes(activeStepIndexWithOffset)"
          class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <span>Please see below for errors. Please correct and click
                {{activeStepIndexWithOffset == 2 ? 'Save and Re-Audit' : 'Retry' }}.</span>
            </div>
          </div>
        </div>

        <div *ngIf="errors" class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3 mt-5"
          role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <h5>{{ errors }}</h5>
            </div>
          </div>
        </div>

        <div *ngIf="preventDisclosures"
          class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3 mt-5" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <h5 [innerHTML]="preventDisclosuresErrorMessage | safeHtml"></h5>
            </div>
          </div>
        </div>

        <div *ngIf="disclosuresAlreadyProcessed"
          class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3 mt-5" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <h5>Disclosures have already been sent on this file. If you would like to view/sign them, please go to
                Conditions.</h5>
            </div>
          </div>
        </div>

        <div *ngIf="disclosuresSentForManualProcessing"
          class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3 mt-5" role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <h5>Disclosures for this file have already been flagged to be sent by our Disclosure Team. Please
                proceed to Loan Submission.</h5>
            </div>
          </div>
        </div>

        <div *ngIf="loanIsTbd" class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3 mt-5"
          role="alert">
          <div class="media">
            <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
            <div class="media-body align-self-center">
              <h5>Loans with a 'TBD' subject property address are not eligible for disclosures. Please
                proceed to Loan Submission.</h5>
            </div>
          </div>
        </div>

        <div *ngIf="activeStepIndexWithOffset == 1" [ngStyle]="{'min-height': isTpoUser ? '394px' : '550px'}">
          <div class="d-flex justify-content-center align-items-center"
            [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}" *ngIf="
                (!loanValidationResponse || !validateStepSkipped) && loadingData
              ">
            <div class="d-flex">
              <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
              <h5 class="font-18 text-muted me-4">Validating Loan Data...</h5>
            </div>
          </div>
          <div *ngIf="isLoanDataValid">
            <div class="d-flex justify-content-center align-items-center"
              [ngStyle]="{'min-height': showComplianceReport ? '100px' : (isTpoUser ? '466px' : '603px')}">
              <div class="d-flex">
                <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></span>
                <h5 class="font-18 text-muted me-4">Loan Data is valid!</h5>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center mb-3"
              *ngIf="loanValidationResponse.complianceReportResponse?.reportFileData">
              <a (click)="showComplianceReport = !showComplianceReport;">{{showComplianceReport ? 'Hide' : 'Show' }}
                Compliance Report</a>
            </div>
            <div *ngIf="showComplianceReport == true">
              <encompass-disclosure-preview-pdf
                [documentData]="loanValidationResponse.complianceReportResponse.reportFileData">
              </encompass-disclosure-preview-pdf>
            </div>
          </div>
          <encompass-disclosure-loan-validation-results
            *ngIf="!isLoanDataValid && loanValidationResponse && !loadingData" [incorrectValues]="incorrectValues"
            [validationResponse]="loanValidationResponse" [invalidPackageConditions]="invalidPackageConditions"
            [correctedValues]="correctedValues">
          </encompass-disclosure-loan-validation-results>
        </div>

        <div *ngIf="activeStepIndexWithOffset == 2">
          <form id="disclosuresForm" #disclosuresForm="ngForm" class="w-100" *ngIf="!selectedDisclosureType">
            <div class="d-flex align-items-center justify-content-center"
              [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
              <div>
                <h4 class="card-title mb-3">Disclosure Type</h4>
                <select [(ngModel)]="selectedDisclosureType" (ngModelChange)="onDisclosureTypeChanged()" [ngClass]="{
                      'is-invalid':
                        disclosureType &&
                        disclosureType.touched &&
                        disclosureType.invalid
                    }" name="disclosureType" #disclosureType="ngModel" required class="form-select">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let disclosureType of disclosureTypes" [ngValue]="disclosureType.value">
                    {{ disclosureType.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Disclosure Type is required.</div>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-center align-items-center"
            [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}"
            *ngIf="selectedDisclosureType && generatedJob && !loadingData && auditPassedNoRecommendations">
            <div class="d-flex">
              <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-check-circle">
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg></span>
              <h5 class="font-18 text-muted me-4">
                Loan Data Audit Passed.
              </h5>
            </div>
          </div>
          <div class="row mt-4"
            *ngIf="selectedDisclosureType && generatedJob && !loadingData && !auditPassedNoRecommendations"
            class="d-flex justify-content-center align-items-center"
            [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
            <div class="col-md-12">
              <encompass-disclosure-info [type]="'audit'" [job]="generatedJob" [documentsVisible]="false"
                [correctedValues]="correctedValues">
              </encompass-disclosure-info>
            </div>
          </div>
        </div>

        <div *ngIf="activeStepIndexWithOffset == 3">
          <div class="row mt-4" *ngIf="leDocumentUrlToView" class="d-flex justify-content-center align-items-center"
            [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}">
            <div class="col-md-12">
              <div style="width: 100%; height: 800px;">
                <iframe [src]="leDocumentUrlToView" width="100%" height="100%">
                </iframe>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center"
            [ngStyle]="{'min-height': isTpoUser ? '466px' : '603px'}"
            *ngIf="!isStepRunning && !loadingData && isWizardCompleted && disclosuresHaveBeenQueued">
            <div class="d-flex">
              <span class="mt-2 me-2 text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" class="feather feather-check-circle">
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg></span>
              <h5 class="font-18 text-muted me-4">
                Disclosures have been queued successfully.
              </h5>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center my-2"
          *ngIf="!sendForManualDisclosure && !disclosuresSentForManualProcessing && !disclosuresAlreadyProcessed && !loanIsTbd && !preventDisclosures">
          <button type="button" *ngIf="isWizardCompleted && isTpoUser" class="btn btn-primary me-1"
            (click)="onProceedToLoanSubmission()">
            Proceed to Loan Submission
          </button>
          <button type="button" *ngIf="!isWizardCompleted" [disabled]="atFirstStep || loadingData || isStepRunning"
            class="btn btn-primary me-1" (click)="onBackClicked()">
            &lt; Back
          </button>
          <button class="btn btn-primary me-1" type="button" (click)="onNextClicked()" *ngIf="!isWizardCompleted &&
                ((
                  generatedJob?.jobStatus != 'Failed' &&
                  (
                    activeStepIndexWithOffset != 2 ||
                    (activeStepIndexWithOffset == 2 &&
                      generatedJob &&
                      !thereAreRequiredFieldValuesInAudits(generatedJob) &&
                      !thereAreRecommendedFieldValuesInAudits(generatedJob)
                    )
                )
                ) || (loadingData && !generatedJob && !correctingData && [2, 3].includes(activeStepIndexWithOffset)))
              " [disabled]="
                loadingData ||
                (!loanValidationResponse && !validateStepSkipped) ||
                (invalidPackageConditions.length || !loanValidationResponse?.planCodePassed || !loanValidationResponse?.passed) ||
                errors
              ">
            <span *ngIf="isStepRunning ||
              ( loadingData && !correctingData && [2, 3].includes(activeStepIndexWithOffset) && (generatedJob?.jobStatus != 'Completed' || !generatedJob))
              ; else nextBtnLabel">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Please wait...
            </span>
            <ng-template #nextBtnLabel>
              {{nextButtonLabel}}
            </ng-template>
          </button>
          <button type="button" class="btn btn-primary me-1"
            *ngIf="correctingData && [1, 2].includes(activeStepIndexWithOffset)" [disabled]="true">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Please wait...
          </button>
          <button type="button" class="btn btn-primary me-1" (click)="correctAndReAudit()"
            [disabled]="loadingData || isStepRunning" *ngIf="
                activeStepIndexWithOffset == 2 &&
                generatedJob &&
                  thereAreCorrectableFieldValues(generatedJob)
              ">
            Save and Re-Audit
          </button>
          <button type="button" class="btn btn-primary" (click)="skipAndGenerate()"
            [disabled]="loadingData || isStepRunning" *ngIf="
                activeStepIndexWithOffset == 2 &&
                generatedJob &&
                !thereAreRequiredFieldValuesInAudits(generatedJob) &&
                thereAreRecommendedFieldValuesInAudits(generatedJob)
              "> Generate >
          </button>

          <button type="button" class="btn btn-primary" (click)="onRetryStartDisclosureProcess()" 
            *ngIf="activeStepIndexWithOffset == 1 && (errors || invalidPackageConditions.length ||
              (loanValidationResponse && !loanValidationResponse.planCodePassed))" [disabled]="loadingData">
            Retry
          </button>

          <button type="button" class="btn btn-primary" (click)="onRetryJob('audit')"
            *ngIf="activeStepIndexWithOffset == 2 &&!proformaLegenerationFailed && errors">
            Retry
          </button>

          <button type="button" class="btn btn-primary" (click)="onRetryGenerateProformaLe()"
            *ngIf="activeStepIndexWithOffset == 2 && proformaLegenerationFailed">
            Retry
          </button>

          <button type="button" class="btn btn-primary" (click)="onRetryJob('queue')"
            *ngIf="activeStepIndexWithOffset == 3 && (generatedJob?.jobStatus == 'Failed' || errors)">
            Retry
          </button>
        </div>

        <div class="d-flex justify-content-center my-2"
          *ngIf="(sendForManualDisclosure || disclosuresSentForManualProcessing || disclosuresAlreadyProcessed || loanIsTbd) && !preventDisclosures && !loanIsSubmitted">
          <button type="button" class="btn btn-primary" (click)="onProceedToLoanSubmission()">
            Proceed to Loan Submission
          </button>
        </div>
      </div>
    </div>
  </div>
</div>