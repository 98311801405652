import {FeeViewModel} from './fee-view-model';
import {LoanFee} from './fees.model';
import {FeeSectionViewType} from './fee-section.model';
import {createFeeSections} from './create-fee-sections';

/**
 * Factory for creating fee view models from loan fees.
 *
 * @warning This class assumes fees to be deep at most 2 levels. It must be improved for a deeper
 * hierarchy.
 */
export class FeeViewModelFactory {
  // TODO: Consider making these sets of HUD numbers configurable
  private readonly cannotBeSplitFeeHudNumbers: ReadonlySet<string> = Object.freeze(new Set([
    '901', // Odd Days Interest
  ]));
  private readonly cannotBeAprFeeHudNumbers: ReadonlySet<string> = Object.freeze(new Set([
    '901', // Odd Days Interest
  ]));
  private readonly nonDeletableFeeHudNumbers: ReadonlySet<string> = Object.freeze(new Set([
    '901', // Odd Days Interest
  ]));
  private readonly monthsTypeFeeSections: ReadonlySet<FeeSectionViewType> = Object.freeze(new Set([
    FeeSectionViewType.Prepaids,
    FeeSectionViewType.Escrow,
  ]));
  private readonly daysTypeHudNumbers: ReadonlySet<string> = Object.freeze(new Set([
    '901', // Odd Days Interest
  ]));

  // These are the "summary fees" that have children fees.
  private topLevelFees: Map<string | undefined, FeeViewModel>;
  // This is a buffer for child fees. We need to buffer them until the parent fee is found.
  private childFeesLookup: Map<string, FeeViewModel[]>;

  private feeSectionTypesByHudNumber: Map<string, FeeSectionViewType>;

  public create(fees: readonly LoanFee[]): FeeViewModel[] {
    this.topLevelFees = new Map<string | undefined, FeeViewModel>();
    this.childFeesLookup = new Map<string, FeeViewModel[]>();
    this.initFeeSectionTypesByFeeId(fees);

    fees.forEach(fee => {
      const feeViewModel = this.createFeeViewModel(fee);
      if (fee.isSummaryFee) {
        this.handleSummaryFee(fee, feeViewModel);
      } else if (stringOrUndefined(fee.sumInHudNumber)) {
        this.handleChildFee(fee, feeViewModel);
      } else {
        this.addParentFeeViewModel(feeViewModel);
      }
    });
    if (this.childFeesLookup.size > 0) {
      console.error("Failed to find parent fee for the following child fees", this.childFeesLookup);
    }
    return Array.from(this.topLevelFees.values());
  }

  private initFeeSectionTypesByFeeId(fees: readonly LoanFee[]): void {
    // TODO: This is a costly operation. We should consider injecting the fee section types somehow
    //       instead of calculating them here.
    const feeSections = createFeeSections(fees);
    this.feeSectionTypesByHudNumber = new Map<string, FeeSectionViewType>(
      feeSections.flatMap(section => section.fees.map(fee => [fee.hudNumber, section.type])),
    );
  }

  private createFeeViewModel(
    fee: LoanFee,
    subFees?: readonly FeeViewModel[],
  ): FeeViewModel {
    return FeeViewModel.fromFee(fee, {
      subFees,
      sectionType: this.feeSectionTypesByHudNumber.get(fee.hudNumber),
      durationType: this.getDurationType(fee),
      canBeSplit: !this.cannotBeSplitFeeHudNumbers.has(fee.hudNumber),
      canBeApr: !this.cannotBeAprFeeHudNumbers.has(fee.hudNumber),
      deletable: !this.nonDeletableFeeHudNumbers.has(fee.hudNumber),
    });
  }

  private getDurationType(fee: LoanFee): typeof FeeViewModel.prototype.durationType {
    if (fee.hudNumber) {
      if (this.daysTypeHudNumbers.has(fee.hudNumber)) {
        return 'days';
      }
      if (fee.feeType != 'AggregateAccountingAdjustment' && this.monthsTypeFeeSections.has(this.feeSectionTypesByHudNumber.get(fee.hudNumber))) {
        return 'months';
      }
    }
    return undefined;
  }

  /**
   * Summary fees has children fees. We need to check the buffer to see if any of the children
   * fees are already has been encountered. If so, we can add the child fee to the parent fee.
   * @param {LoanFee} fee - The fee to be added to the view model
   * @param {FeeViewModel} feeViewModel - The view model of the fee
   * @private
   */
  private handleSummaryFee(fee: LoanFee, feeViewModel: FeeViewModel): void {
    const hudNumber = fee.hudNumber;
    const subFees = this.childFeesLookup.get(hudNumber) ?? [];
    this.addParentFeeViewModel(feeViewModel, subFees);
    // Remove the child fees from the buffer as we don't need to buffer them anymore. Further
    // child fees will be added directly to the parent fee.
    this.childFeesLookup.delete(hudNumber);
  }

  /**
   * If the fee has a parent hud number, we need to check if the parent fee has been encountered.
   * If so, we can add the child fee to the parent fee.
   * @param {LoanFee} fee - The fee to be added to the view model
   * @param {FeeViewModel} feeViewModel - The view model of the fee
   * @private
   */
  private handleChildFee(fee: LoanFee, feeViewModel: FeeViewModel): void {
    const parentHudNumber = stringOrUndefined(fee.sumInHudNumber);
    const parentHudGroup = this.topLevelFees.get(parentHudNumber);
    if (parentHudGroup) {
      // @ts-ignore - Ignore the readonly error while creating the model.
      parentHudGroup.subFees.push(feeViewModel);
    } else {
      this.bufferChildFee(feeViewModel, parentHudNumber);
    }
  }

  /**
   * If the parent fee has not been encountered, we need to buffer the child fee until the parent
   * fee is found.
   * @param {FeeViewModel} feeViewModel - The view model of the fee
   * @param {string} parentHudNumber - The HUD number of the parent fee
   * @private
   */
  private bufferChildFee(feeViewModel: FeeViewModel, parentHudNumber: string): void {
    const childFees = this.childFeesLookup.get(parentHudNumber)
      ? this.childFeesLookup.get(parentHudNumber)
      : this.createAndAddChildFees(parentHudNumber);
    childFees.push(feeViewModel);
  }

  /**
   * Create a new buffer for child fees and add it to the lookup.
   * @param {string} parentHudNumber - The HUD number of the parent fee
   * @returns {FeeViewModel[]} - The new buffer for child fees
   * @private
   */
  private createAndAddChildFees(parentHudNumber: string): FeeViewModel[] {
    const newChildFees = [] as FeeViewModel[];
    this.childFeesLookup.set(parentHudNumber, newChildFees);
    return newChildFees;
  }

  /**
   * If the fee is not a parent (summary) fee and does not have a parent fee, we can add it
   * as a standalone fee.
   * @param {FeeViewModel} feeViewModel - The view model of the fee
   * @param {FeeViewModel[]} [subFees] - The sub fees of the fee
   * @private
   */
  private addParentFeeViewModel(
    feeViewModel: FeeViewModel,
    subFees?: readonly FeeViewModel[],
  ): void {
    const hudNumber = feeViewModel.fee.hudNumber;
    this.topLevelFees.set(hudNumber, this.createFeeViewModel(feeViewModel.fee, subFees));
  }
}

function stringOrUndefined(value?: any): string | undefined {
  return value == null ? undefined : String(value);
}
