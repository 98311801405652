<loading-indicator *ngIf="loadingContacts" [loadingMessage]="'Loading external contacts, please wait...'">
</loading-indicator>
<div class="row" *ngIf="!loadingContacts">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title"> External Contacts </h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <p-table #dt [value]="externalContactList" [paginator]="true" [rows]="25"
                        [rowsPerPageOptions]="[10,25,50]"
                        [resizableColumns]="true" responsiveLayout="scroll" [autoLayout]="true"
                        [globalFilterFields]="['lastName','firstName','agentType','phone','email','orgName','fax']"
                        [showCurrentPageReport]="true" [(selection)]="selectedRows"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        styleClass="p-datatable-gridlines" [sortField]="'agentType'" sortMode="single"
                        [sortOrder]="1">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex">
                                <div class="col-sm-6">
                                    <button class="btn btn-primary me-3" [routerLink]="'/' + applicationMode + '/app-details/' + appId + '/associate-file-contact'">
                                        <i class='fas fa-plus'></i> Associate
                                    </button>
                                    <button [disabled]="(selectedRows && selectedRows.length === 0) || !selectedRows" class="btn btn-outline-primary pull-right me-3" (click)="mailExternalContact()">
                                        <i class='fa fa-envelope'></i> Checked
                                    </button>
                                </div>
                                <div class="col-sm-6 d-flex">
                                    <button  [disabled]="(selectedRows && selectedRows.length === 0) || !selectedRows" class="btn btn-outline-primary" (click)="copyExternalContactToClipboard()">
                                        <i class='fa fa-clipboard'></i> Copy Contact Info
                                    </button>
                                    <span class="p-input-icon-right p-ml-auto">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" class="form-control"
                                            (ngModelChange)="dt.first=0"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search keyword" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 15%;">
                                    Action
                                </th>
                                <th [pSortableColumn]="'agentType'" style="width: 20%;">
                                    <i class="fa fa-fw fa-user text-muted hidden-md hidden-sm hidden-xs"></i> Agent Type
                                    <p-sortIcon [field]="'agentType'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'lastName'">
                                    <i class="fa fa-fw fa-user text-muted hidden-md hidden-sm hidden-xs"></i> Name
                                    <p-sortIcon [field]="'lastName'"></p-sortIcon>
                                </th>
                                <th>
                                    <i class="fa fa-fw fa-phone text-muted hidden-md hidden-sm hidden-xs"></i> Phone
                                    <p-sortIcon [field]="'phone'"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-externalContactList>
                            <tr>
                                <td>
                                    <div class="d-flex align-items-center justify-content-center w-100">
                                        <p-tableCheckbox [value]="externalContactList">
                                        </p-tableCheckbox>
                                        <a href="javascript: void(0);" [hidden]="deleteAgentId == externalContactList.agentId" (click)="deleteAgent(externalContactList.agentId)"><i class="fa fa-trash-alt"></i></a>
                                        <a href="javascript: void(0);" class="danger" [hidden]="deleteAgentId != externalContactList.agentId" (click)="cancelDelete()"><i class="fa fa-times"></i></a>
                                        <a href="javascript: void(0);" class="danger" [hidden]="deleteAgentId != externalContactList.agentId" (click)="confirmDelete(externalContactList.agentId)"><i class="fa fa-check-circle"></i></a>
                                        <span *ngIf="externalContactList.email" class="text-muted mx-1">|</span>
                                        <a *ngIf="externalContactList.email" href="mailto:{{externalContactList.email}}?subject={{mailingStreet}}"><i class="far fa-fw fa-envelope text-muted hidden-md hidden-sm hidden-xs"></i></a>
                                    </div>
                                </td>
                                <td>
                                    {{externalContactList.agentType}}
                                </td>
                                <td>
                                    <a class="pe-auto" href="javascript: void(0);" (click)="onExternalContactNameClicked(externalContactList.agentId)" class="text-cell-primary">{{externalContactList.lastName}}, {{externalContactList.firstName}}</a>
                                    <br>
                                    <span class="text-muted">{{externalContactList.orgName || '--'}}</span>
                                </td>
                                <td>
                                    <span class="text-muted">P: {{externalContactList.phone ? (externalContactList.phone | phone) : '--'}}</span>
                                    <br>
                                    <span class="text-muted">F: {{externalContactList.fax ? (externalContactList.fax | phone) : '--'}}</span>
                                    <br>
                                    <span class="text-muted">E: {{externalContactList.email || '--'}}</span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7" class="text-center"> No data available in table </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<drawer [title]="'Edit Agent'"
    #openAgentEditor
    [name]="'openAgentEditorDrawer'"
    [templateRef]="openAgentEditorDrawerContentRef"
    [options]="openAgentsDrawerOptions"
    (closed)="onAgentEditorDrawerClosed()">
</drawer>
<ng-template #openAgentEditorDrawerContentRef>
<upsert-agent
  [agentId]="agentId"
  [isDrawer]="true"
  (onAddEditAgent)="onAgentEdited()"
  (closeDrawer)="onAgentEditorDrawerClosed()">
</upsert-agent>
</ng-template>
