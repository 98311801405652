import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MortgageSigner } from 'src/app/modules/loan-docs/models/external-contact.model';

@Component({
  selector: 'document-preparation-signer',
  templateUrl: 'document-preparation-signer.component.html'
})

export class DocumentPreparationSignerComponent implements OnInit {

  @Output()
  deleteSigner: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  signer: MortgageSigner;

  @Input()
  sellerBorrowerType: {source: string, type: string};

  @Input()
  index: number;

  constructor() { }

  ngOnInit() { }

  onDeleteClicked = (index: number) => {
    this.deleteSigner.emit(index);
  }

  noSignerInfoClicked = () => {
    if (this.signer.noSignerInformation) {
      this.signer.name = null;
      this.signer.title = null;
    }
  }
}
