import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { FeeService } from 'src/app/services/fee.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'import-loan-calculated-fees',
  templateUrl: 'import-loan-calculated-fees.component.html',
})
export class ImportLoanCalculatedFeesComponent implements OnInit {

  @Input()
  applicationId: number;

  @Input()
  autoCalculateLoanFees: boolean = true;

  @Output()
  loanFeesCalculated: EventEmitter<LoanFee[]> = new EventEmitter<LoanFee[]>();

  @Output()
  executionStarted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  executionCompleted: EventEmitter<any> = new EventEmitter<any>();

  isCalculatingFees: boolean = false;

  feesCalculated: boolean = false;

  constructor(
    private readonly _feesService: FeeService,
    private readonly _notifsService: NotificationService
  ) {}

  ngOnInit() {
    if (this.autoCalculateLoanFees) {
      this.calculateFeesForLoan();
    }
  }

  public calculateFeesForLoan = () => {
    const observer = {
      next: (fees) => {
        this.isCalculatingFees = false;
        const calculatedLoanFees = fees.filter(fee => !fee.isSummaryFee);
        this.feesCalculated = true;
        setTimeout(() => {
          this.executionCompleted.emit();
          this.loanFeesCalculated.emit(calculatedLoanFees);
        }, 2000);
      },
      error: (error) => {
        this.isCalculatingFees = false;
        this.executionCompleted.emit();
        this._notifsService.showError(
          error.error
            ? error.error.message
            : 'An error occurred while importing calculated loan fees.',
          'Error!'
        );
      },
    };
    this.autoCalculateLoanFees = true;
    this.isCalculatingFees = true;
    this.executionStarted.emit();
    this._feesService.updateFeesFromLoan(this.applicationId).subscribe(observer);
  };
}
