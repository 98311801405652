<form id="editPricingDetailsForm" name="editPricingDetailsForm" #editPricingDetailsForm="ngForm" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="loanProduct" class="form-label"> Loan Product </label>
        <select class="form-select" id="loanProduct" name="loanProduct" #loanProductInput="ngModel"
          [ngClass]="{'is-invalid' : loanProductInput && loanProductInput.touched && loanProductInput.invalid}"
          [(ngModel)]="application.productPricing.productId"
          (blur)="selectProduct(application.productPricing.productId)">
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let product of products" [ngValue]="product.productId">
            {{product.productName}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="mb-3">
        <label for="lender" class="form-label"> Lender </label>
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <select class="form-select" id="lender" name="lender" #lenderInput="ngModel"
              [ngClass]="{'is-invalid' : lenderInput && lenderInput.touched && lenderInput.invalid}"
              [(ngModel)]="application.lenderId">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let lender of lenders" [ngValue]="lender.lenderId">
                {{lender.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
          <button *ngIf="application.lenderId" (click)="showLenderInfoDialog()"
            class="btn d-flex justify-content-center align-items-center ms-1 p-0" type="button">
            <i class="fas fa-info-circle" style="color: var(--ck-color-button-on-color)"></i>
          </button>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label"> Interest Rate / APR </label>
        <span class="d-flex justify-content-start align-items-center">
          <i *ngIf="application &&
                    (
                      (application.productPricing && application.productPricing.lockStatus && application.productPricing.lockStatus == 'Accepted') ||
                      (!application.productPricing?.lockStatus && hasRateLockExpirationKeyDate)
                    )" class="fas fa-lock me-1" style="color: orange"></i>
          <i *ngIf="application &&
                    (
                      (application.productPricing && application.productPricing.lockStatus &&  application.productPricing.lockStatus != 'Accepted') ||
                      (!application.productPricing?.lockStatus && !hasRateLockExpirationKeyDate)
                    )" class="fas fa-lock-open me-1" style="color: orange"></i>
          <percent-input class="d-inline-block w-100" [inlineTextClass]="'dark-success'" [rateIsTrueToTextValue]="true"
            [(ngModel)]="application.productPricing.rate" name="interestRate" [digitsInfo]="'1.3-3'"
            (blur)="onProductPricingRateChanged()"></percent-input>
          <span class="mx-2">/</span>
          <percent-input class="d-inline-block w-100" [inlineTextClass]="'text-secondary'"
            [rateIsTrueToTextValue]="true" [(ngModel)]="application.productPricing.apr" name="apr"
            [digitsInfo]="'1.3-3'" [placeholder]="'APR'"></percent-input>
        </span>
      </div>
      <div class="mb-3">
        <label for="lockExpiration" class="form-label"> Lock Expiration </label>
        <date-input [name]="'lockExpiration'" [id]="'lockExpiration'"
          [(ngModel)]="application.productPricing.lockExpirationDate"></date-input>
      </div>
      <div class="mb-3">
        <label for="compType" class="form-label"> Comp Type </label>
        <select class="form-select" id="compType" name="compType" #compTypeInput="ngModel"
          [ngClass]="{'is-invalid' : compTypeInput && compTypeInput.touched && compTypeInput.invalid}"
          [(ngModel)]="application.productPricing.compType">
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let type of compTypes" [ngValue]="type.value">
            {{type.name}}</option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="mb-3">
        <label for="compensation" class="form-label"> Compensation</label>
        <percent-input [(ngModel)]="application.productPricing.comp" name="compensation" [digitsInfo]="'1.3-3'">
        </percent-input>
      </div>
      <div class="mb-3">
        <label for="margin" class="form-label"> Margin</label>
        <number-input class="inline-editor-text-wrapper" [(ngModel)]="application.productPricing.margin" name="margin"
          [decimals]="'3'">
        </number-input>
      </div>
      <div class="mb-3">
        <label for="points" class="form-label"> Points</label>
        <number-input [(ngModel)]="application.productPricing.price" name="points" [decimals]="'3'"
          [allowNegative]="false" [required]="true">
        </number-input>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="modal-footer" style="display: unset">
      <button type="button" class="btn btn-primary float-end" (click)="saveLoanInfo()" [disabled]="isSaving">
        <span *ngIf="!isSaving">
          <i class="fa fa-save"></i>
          Save
        </span>
        <span *ngIf="isSaving">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Saving
        </span>
      </button>
    </div>
  </div>

</form>
