<h4 *ngIf="!smsAlertsEnabled">Call to enable this feature!</h4>
<div *ngIf="smsAlertsEnabled">
  <div class="fieldset-title">SMS Settings</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Server Type</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" [(ngModel)]="telephonyService.serviceType" required>
          <option value="Twilio">Lodasoft</option>
        </select>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Phone #</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Phone Number" [(ngModel)]="telephonyService.fromPhoneNumber" />
      </div>
    </div>
    <div class="row mb-3" *ngIf="telephonyService.serviceType === 'Twilio'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Account SID</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Account SID" [(ngModel)]="telephonyService.accountIdentifier" />
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Auth Token</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Auth Token" [(ngModel)]="telephonyService.authToken" />
      </div>
    </div>
    <div class="row" *ngIf="sendTestSmsSuccess === true || sendTestSmsSuccess === false">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div *ngIf="sendTestSmsSuccess === true" class="alert alert-success">
          <span><i class="fa fa-check-circle"></i>&nbsp;Test SMS Sent
            Successfully</span>
        </div>
        <div *ngIf="sendTestSmsSuccess === false" class="alert alert-danger">
          <span>
            <i class="fa fa-times-circle"></i>&nbsp;{{
            sendTestSmsErrorMessage
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="telephonyService.serviceType === 'Twilio'">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Test SMS #</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Send sms test to #"
          [(ngModel)]="telephonyService.toPhoneNumber" />
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <button class="btn btn-outline-primary" (click)="sendTestEmail()">
          <span *ngIf="sendingTestSms" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!sendingTestSms">Send Test SMS</span>
        </button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Allowed Send Time Range</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" type="text" [mask]="'00:00-00:00'" [dropSpecialCharacters]="false"
          [placeholder]="'##:##-##:##'" [(ngModel)]="smsAllowedSendTimeRange.valueStr" />
        <div>Use eastern standard time zone, 24 hour clock.</div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 offset-md-2">
        <div class="form-check form-switch form-switch-success">
          <input class="form-check-input" type="checkbox" id="disableVoiceRecording"
            [(ngModel)]="telephonyService.disableVoiceRecording">
          <label class="form-check-label" for="disableVoiceRecording">Disable Call Recording</label>
        </div>
        <div class="form-check form-switch form-switch-success">
          <input class="form-check-input" type="checkbox" id="disableAllOutOfTheBoxCommunication"
            [(ngModel)]="telephonyService.disableAllOutOfTheBoxCommunication">
          <label class="form-check-label" for="disableAllOutOfTheBoxCommunication">Disable All Out Of The Box
            Communication </label>
        </div>
        <div class="form-check form-switch form-switch-success">
          <input class="form-check-input" type="checkbox" id="enableSMSAlertsByDefault" ToggleValue [true]="1"
            [false]="0" [(ngModel)]="enableSmsAlertsByDefault.value">
          <label class="form-check-label" for="enableSMSAlertsByDefault">Enable SMS Alerts By Default</label>
        </div>
      </div>
    </div>
    <div class="text-end" *ngIf="type === 'system-level'">
      <div class="col-md-12 my-1">
        <hr />
      </div>
      <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
        <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
        <span *ngIf="isSaving">
          <span class="spinner-border spinner-border-sm"></span> Saving
        </span>
      </button>
    </div>
  </div>