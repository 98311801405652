import { Component, Input, OnInit } from '@angular/core';
import { LoanSummary } from './loan-summary.model';

@Component({
  selector: 'loan-summary-card',
  templateUrl: './loan-summary-card.component.html',
  styleUrls: ['./loan-summary-card.component.scss']
})
export class LoanSummaryCardComponent implements OnInit {

  @Input()
  loanSummary: LoanSummary;

  constructor() {}

  ngOnInit(): void {
  }

}
