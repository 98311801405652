import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DialListRecordBasic } from '../../models/dial-list-record-basic.model';
import { SubStatus } from 'src/app/models';

@Component({
  selector: 'application-substatus',
  templateUrl: './application-substatus.component.html',
  styleUrls: ['./application-substatus.component.scss']
})
export class ApplicationSubstatusComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input() selectedRecord: DialListRecordBasic;
  @Output() statusChanged = new EventEmitter<number>();

  subStatuses: SubStatus[] = [];

  constructor(
    private readonly _loanService: LoanService,
    private readonly _notifyService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.selectedRecord.subStatusId) {
      this.selectedRecord.subStatusId = null;
    }

    this.applicationContextService.context.subscribe(context => {
      this.subStatuses = context.globalConfig.subStatus;

      if (this.selectedRecord.subStatus) {
        const matched = this.subStatuses.find(s => s.subStatusName == this.selectedRecord.subStatus);
        if (matched) {
          this.selectedRecord.subStatusId = matched.subStatusId;
        }
      }
    });
  }

  updateStatus = () => {
    this._loanService.saveLoanStatus(this.selectedRecord.applicationId, this.selectedRecord.statusId, this.selectedRecord.subStatusId).subscribe({
      next: () => {
        this.statusChanged.emit(this.selectedRecord.subStatusId)
        this._notifyService.showSuccess("Status changed successfully.", "Status");
      },
      error: (err) => {
        this._notifyService.showError(err.error.message || err.error, "Error!");
      }
    });
  }

}
