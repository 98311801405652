<div class="modal-header">
  <h6 class="modal-title">Change LOS Sync Direction</h6>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">Sync Direction</label>
        <div class="col-sm-6">
          <select class="form-select" id="syncDirection" name="syncDirection" [(ngModel)]="syncDirection" [disabled]="isSaving">
            <option value="">--Select One--</option>
            <option value="None">No Data Flow</option>
            <option value="LosToLoda">LOS to Loda Only</option>
            <option value="Both">Bi-directional Data Flow</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="isSaving || !syncDirection">
          <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
