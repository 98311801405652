import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CreditBorrower } from '../credit-inquiry/credit-inquiry.component';
import { CreditInquiryDetailsEntry } from '../../models/credit-inquiry-details.model';
import { DateTime } from 'luxon';

@Component({
  selector: 'borrower-credit-inquiry',
  templateUrl: './borrower-credit-inquiry.component.html',
  styleUrls: ['./borrower-credit-inquiry.component.scss']
})
export class BorrowerCreditInquiryComponent implements OnInit {

  @ViewChild('borrowerInfoForm') borrowerInfoForm: NgForm | undefined;

  @Input()
  borrower!: CreditBorrower;

  @Input()
  showOnlyLast4Months: boolean = false;

  protected entries: CreditInquiryDetailsEntry[] = [];

  validate = () => {
    this.borrowerInfoForm?.form.markAllAsTouched();
    if (this.borrowerInfoForm?.form.valid) {
        return true;
    }
    return false;
}

  ngOnInit() {

    if(this.borrower.creditInquiry?.entries.length){
      this.entries = this.borrower.creditInquiry?.entries;
    }

    if(this.showOnlyLast4Months){
      this.entries = this.entries.filter(e => this.isInquiryToBeShown(e.creditInquiryDate));
    }
  }

  private isInquiryToBeShown = (date: string) => {
    if(!date){
      return true;
    }

    const startDate = DateTime.fromISO(date);
    const diff = startDate.diffNow(['days']);
    return Math.abs(diff.days) <= 120;
  }
}
