import { Component, Input, OnDestroy } from '@angular/core';
import { Product } from '../../models/pricing/product-search-result.model';
import { PricingService } from '../../services/pricing.service';
import { PricingEngineVendor } from '../../../admin/tpo-config/models/configuration-product.model';
import { Subscription } from 'rxjs';
import { EnumerationItem } from '../../../../models/simple-enum-item.model';
import { NotificationService } from '../../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { PricingImportProductsRequestModel } from '../../models/pricing/pricing-import-products-request.model';
import { PricingChannelFlags } from '../../../../models/pricing/pricing-channel-flags.model';
import { enumLikeValueToDisplayName } from '../../../../core/services/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './import-vendor-products-dialog.component.html',
  styleUrls: ['./import-vendor-products-dialog.component.scss'],
})
export class ImportVendorProductsDialog implements OnDestroy {

  @Input()
  set vendors(value: PricingEngineVendor[]) {
    this._vendors = value;

    this.selectedVendor = this._vendors?.[0];
    if (this.selectedVendor) {
      this.loadProducts(this.selectedVendor);
    }

    this.vendorOptions = this._vendors.map(vendor => ({
      name: vendor,
      value: vendor,
    }));
  }

  @Input()
  set enabledChannels(value: readonly EnumerationItem[]) {
    this.channelOptions = value.map((option) => {
      const { name, ...rest } = option;
      return {
        ...rest,
        name: enumLikeValueToDisplayName(name),
        value: name as PricingChannelFlags,
      };
    });
  }

  private _vendors: PricingEngineVendor[] = [];
  protected vendorOptions: EnumerationItem<PricingEngineVendor>[] = [];
  protected channelOptions: EnumerationItem<PricingChannelFlags>[] = [];

  protected isLoadingProducts = false;
  protected isImportingProducts = false;
  private readonly _productsByVendor = new Map<string, readonly Product[]>();
  protected selectedVendor?: PricingEngineVendor;
  protected selectedChannels: PricingChannelFlags[] = [];
  protected products: Product[] = [];
  protected selectedProducts: Product[] = [];

  protected globalFilterFields: string[] = ['productName'];

  private _loadProductsSubscription?: Subscription;
  private _importProductsSubscription?: Subscription;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _pricingService: PricingService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
  ) {
  }

  ngOnDestroy() {
    this._loadProductsSubscription?.unsubscribe();
  }

  protected onClickCancel(): void {
    this._activeModal.dismiss();
  }

  protected onChangeSelectedVendor(vendor: PricingEngineVendor): void {
    this.selectedVendor = vendor;
    this.selectedProducts = [];
    this.loadProducts(vendor);
  }

  private loadProducts(vendor: PricingEngineVendor): void {
    this._loadProductsSubscription?.unsubscribe();

    if (this._productsByVendor.has(vendor)) {
      this.products = [...this._productsByVendor.get(vendor)] ?? [];
      return;
    }

    this.isLoadingProducts = true;
    this._loadProductsSubscription = this._pricingService
      .getPricingProductLists(vendor, true).pipe(
        finalize(() => {
          this.isLoadingProducts = false;
        }),
      ).subscribe({
        next: (productLists) => {
          const products = productLists.flatMap(list => list.products);
          this._productsByVendor.set(vendor, products);
          this.products = [...products];
        },
        error: (error: any) => {
          const defaultMessage = 'An error occurred while loading the products.';
          console.error(defaultMessage, error);
          const message = error?.message ?? error?.error?.message ?? defaultMessage;

          this._notificationService.showError(message, 'Error Loading Products');

          this._productsByVendor.delete(vendor);
          this.products = [];
        },
      });
  }

  protected onClickImport(): void {
    if (this.isImportingProducts) {
      console.warn('An import operation is already in progress.');
      return;
    }

    this.importProducts();
  }

  private importProducts(): void {
    this._importProductsSubscription?.unsubscribe();

    this.isImportingProducts = true;
    this._spinnerService.show().then();

    const productIds = this.selectedProducts.map(product => product.productId);
    const request: PricingImportProductsRequestModel = {
      productIds,
      enabledChannels: this.serializeSelectedChannels(),
    };

    this._importProductsSubscription = this._pricingService
      .importProducts(this.selectedVendor, request).pipe(
        finalize(() => {
          this.isImportingProducts = false;
          this._spinnerService.hide().then();
        }),
      ).subscribe({
        next: () => {
          this._notificationService.showSuccess(
            'The selected products have been imported successfully.',
            'Products Imported',
          );

          this._activeModal.close(this.selectedProducts);
        },
        error: (error: any) => {
          const defaultMessage = 'An error occurred while importing the selected products.';
          console.error(defaultMessage, error);
          const message = error?.message ?? error?.error?.message ?? defaultMessage;

          this._notificationService.showError(message, 'Error Importing Products');
        },
      });
  }

  private serializeSelectedChannels(): string | undefined {
    return this.selectedChannels?.length > 0
      ? this.selectedChannels.map(channel => channel.toString()).join(',')
      : undefined;
  }
}
