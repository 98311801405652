<div class="card">
  <div class="card-body">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#entry" role="tab" aria-selected="true"
           (click)="changeTab('entry'); " [ngClass]="{'active' : selectedTab === 'entry'}">
          Change of Circumstance Request Entry
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#history" role="tab" aria-selected="false"
           (click)="changeTab('history');" [ngClass]="{'active' : selectedTab === 'history'}">
          Change of Circumstance Request History
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane p-3" id="entry" role="tabpanel" [ngClass]="{'active' : selectedTab === 'entry'}">
        <coc-entry (changeTab)="changeTab($event)"></coc-entry>
      </div>
      <div class="tab-pane p-3" id="history" role="tabpanel" [ngClass]="{'active' : selectedTab === 'history'}">
        <coc-history></coc-history>
      </div>
    </div>
  </div>
</div>
