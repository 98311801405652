export enum FiAccountEnum {}

export interface LiteAccountInfo {
  readonly accountId: string;
  accountType: string; // AssetType enum
  accountName: string;
  accountNumber: string;
  accountHolder: string;
  fiName: string;
  fiAccountType: string;
  fiPlanName: string;
  balance: number;
  balanceDate: string;
}

export type LiteAccountInfoReadonlyArray = ReadonlyArray<LiteAccountInfo>;
