<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" id="nextCopyAppDialog" *ngIf="contactRoles">
  <div class="row">
    <div class="col-md-12">
      <div class="alignment-selection">
        <div class="col-md-6 alignment-option"
          [ngClass]="{'alignment-option-disabled': contactSelection != 'original'}">
          <div class="form-group radio-button">
            <input type="radio" id="original" name="contacts" value="original" [(ngModel)]="contactSelection">
            <label for="original" class="col-md-4 ms-2"> Original internal contacts </label>
          </div>
          <fieldset [ngClass]="{'fieldset-disabled': contactSelection != 'original'}">
            <div class="form-group d-flex" *ngFor="let role of contactRoles">
              <label class="col-md-4 text-end"> {{role.roleName}} </label>
              <div class="col-md-8 ps-3">
                <select disabled class="form-select" [(ngModel)]="contactUserIdsByRole[role.roleId]"
                  id="{{role.roleId}}" name="{{role.roleId}}">
                  <optgroup label="Branch Users" *ngIf="(branchUsers | filter : { 'roleId' : role.roleId}).length > 0">
                    <ng-container *ngFor="let user of branchUsers | filter : { 'roleId' : role.roleId}">
                      <option [ngValue]="user.userCompanyGuid">
                        {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> - (Inactive)</span>
                      </option>
                    </ng-container>
                  </optgroup>
                  <optgroup label="TPO All Users"
                    *ngIf="externalCompanyUsers?.length > 0">
                    <option class="user-select" *ngFor="let user of externalCompanyUsers"
                      [ngValue]="user.userCompanyGuid">
                      {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> - (Inactive)</span>
                    </option>
                  </optgroup>
                  <ng-container *ngIf="role.order == 1">
                    <option *ngFor="let user of users | filter : { 'roleId': role.roleId }"
                      [ngValue]="user.userCompanyGuid">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </ng-container>
                  <ng-container *ngIf="role.order != 1">
                    <ng-container *ngFor="let users of usersAll | groupBy: 'roleId'">
                      <optgroup label="{{contactRoles[0].roleName}}"
                        [hidden]="(((users.value | filter : { 'id' : contactUserIdsByRole[role.roleId] }).length == 0) && ((users.value | filter : { 'active' : 1 }).length == 0)) || users.key != role.roleId">
                        <ng-container *ngFor="let user of users.value">
                          <option class="user-select"
                            *ngIf="(user.roleId == users.key && !!user.active) || (user.roleId == users.key && user.userCompanyGuid == contactUserIdsByRole[role.roleId])"
                            [ngValue]="user.userCompanyGuid">
                            {{user.lastName + ", " + user.firstName}} <span *ngIf="!user.active"> - (Inactive)</span>
                          </option>
                        </ng-container>
                      </optgroup>
                    </ng-container>
                    <optgroup label="All Users">
                      <ng-container *ngFor="let user of users">
                        <option *ngIf="user.active  && user.roleId != role.roleId" [ngValue]="user.userCompanyGuid">
                          {{user.lastName + ", " + user.firstName}}
                        </option>
                      </ng-container>
                    </optgroup>
                  </ng-container>

                </select>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6 alignment-option" [ngClass]="{'alignment-option-disabled': contactSelection != 'lo'}">
          <div class="form-group radio-button">
            <input type="radio" id="lo" name="contacts" value="lo" [(ngModel)]="contactSelection">
            <label for="lo" class="col-md-4 ms-2"> Auto assign alignment for user </label>
          </div>
          <fieldset [ngClass]="{'fieldset-disabled': contactSelection != 'lo'}">
            <div class="form-group d-flex" *ngFor="let role of contactRoles">
              <label *ngIf="role.order == 1" class="col-md-4 text-end"> {{role.roleName}} </label>
              <div class="col-md-8 ps-3">
                <select *ngIf="role.order == 1" [disabled]="contactSelection != 'lo'"
                  (change)="updateContactSelection(role.roleId, losAligmentModel[role.roleId])" class="form-select"
                  [(ngModel)]="losAligmentModel[role.roleId]" id="{{role.roleId}}">
                  <option value="">-- Select One --</option>
                  <ng-container *ngFor="let user of users">
                    <option *ngIf="user.roleId == role.roleId" value="{{user.userCompanyGuid}}"
                      [selected]="user.userCompanyGuid == losAligmentModel[role.roleId]">
                      {{user.lastName + ", " + user.firstName}}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()"> Save </button>
</div>
