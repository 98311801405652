import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { debounce } from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { User } from 'src/app/models/user/user.model';
import { TableColumn } from 'src/app/shared/models/tale-column.model';
import { CallStatus, ConferenceParticipant } from '../../models/conference-participant.model';
import { DialListBasic } from '../../models/dial-list-basic.model';
import { DialListRecordBasic, RecordType } from '../../models/dial-list-record-basic.model';
import { PredefinedNote } from '../../models/dialer-page-data.model';
import { PhoneType } from '../../models/phone-type.model';
import { DialerEvent, DialerService } from '../../services/dialer.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ContactListType } from 'src/app/models/contact-list.model';
import { CommonService } from 'src/app/services/common.service';
import { DialerPreferences, WebPreferences } from 'src/app/models/web-preferences.model';
import { FieldType } from 'src/app/modules/contact-lists/models/contact-list-search-result.model';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as luxon from 'luxon';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'dial-list-records-table',
  templateUrl: './dial-list-records-table.component.html',
  styleUrls: ['./dial-list-records-table.component.scss']
})
export class DialListRecordsTableComponent implements OnInit {

  @Input()
  set selectedDialList(value: DialListBasic) {
    this._selectedDialList = value;
    if (value) {
      this.records = this._selectedDialList.records;
      this.records.forEach(record => {
        this.setSubjectPropertyForRecord(record);
        this.setLoanOfficerForRecord(record);
        this.isRecordEditable = !!(record.leadId || record.applicationId || record.borrowerId ||
          record.agentId || record.creditMonitoringDataId || record.externalCompanyId);
      })
      this.originalRecords = _.cloneDeep(this.records);
      this.tableStateName = "dial-list-records-table-state-" + this._selectedDialList.dialListId;
    }
  }

  get selectedDialList(): DialListBasic {
    return this._selectedDialList;
  }

  @Input() loanOfficers: User[] = [];
  @Input() predefinedNotes: PredefinedNote[] = [];
  @Input() dialLists: DialListBasic[] = [];
  @Input() selectedRecordForCall: DialListRecordBasic;
  @Input() selectedRecord: DialListRecordBasic;
  @Input() sortOnInit: boolean;

  @Input()
  strikeOutHours: number = 4;

  get manualOpenCallPanel(): { record: DialListRecordBasic; phoneType: PhoneType; } {
    return this._manualOpenCallPanel;
  }

  @Input()
  set manualOpenCallPanel(value: { record: DialListRecordBasic; phoneType: PhoneType; }) {
    this._manualOpenCallPanel = value;
    if (value) {
      this.openCallPanel(value.record, value.phoneType);
    }
  }

  @Input()
  dialListRefreshStatus: boolean = false;

  @Output() onPhoneNumberClicked = new EventEmitter<{ recordToDial: DialListRecordBasic, phoneType: PhoneType }>();
  @Output() onPageChanged = new EventEmitter<{ currentPage: number, numOfItemsOnPage: number }>();
  @Output() editRecordClicked = new EventEmitter<{ record: DialListRecordBasic, fromFindOnDiallist: boolean }>();

  @ViewChildren("recordRows") recordRows: QueryList<ElementRef> | undefined;
  @ViewChild("dialRecordsTable") dialRecordsTable: ElementRef;

  columns: TableColumn[] = [];
  selectedColumns: TableColumn[] = [];
  globalFilterFields: string[] = [];

  records: DialListRecordBasic[] = [];
  originalRecords: DialListRecordBasic[] = [];

  fieldsOrderingStatus = {};
  searchText: string;
  currentPage: number = 1;
  pageSize: number = 100;
  columnsLoading: boolean = true;

  webPreferences: WebPreferences;

  tableStateName: string = "dial-list-records-table-state";

  colOrder: string[];

  init: boolean = true;

  protected isRecordEditable: boolean = true;

  private _selectedDialList: DialListBasic;

  private _completedStatuses: CallStatus[] = [
    CallStatus.Completed, CallStatus.Failed, CallStatus.Busy,
    CallStatus.NoAnswer, CallStatus.Disconnected, CallStatus.Canceled
  ];

  private _manualOpenCallPanel: { record: DialListRecordBasic; phoneType: PhoneType; } = null;

  constructor(
    private readonly _dialerService: DialerService,
    private readonly _notificationService: NotificationService,
    private readonly _commonService: CommonService,
    private readonly _localStorageService: LocalStorageService
  ) {
    this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }

      switch (e.eventType) {
        case 'dialerv2::expandCollapseRecord':
          this.onEditRecordClicked(e.data);
          break;
        case 'changedSelectedRecordForCall':
          this.listener_changedSelectedRecordForCall(e.data);
          break;
        case 'changeRecordCallStatus':
          this.listener_changeRecordCallStatusData(e.data);
          break;
        case 'changeParticipants':
          this.listener_changeParticipants(e.data);
          break;
      }
    });

    this._commonService.webPreferencesChanged.subscribe(webPreferences => {
      this.webPreferences = webPreferences;
      if (!this.webPreferences.dialerReferences) {
        this.webPreferences.dialerReferences = new DialerPreferences();
      }
      this.webPreferences.dialerReferences.selectedColumns[this.selectedDialList.dialListId] = this.selectedColumns;
    });
  }

  ngOnInit(): void {
    this.loadDynamicData();
  }

  selectedColumnsChanged = () => {
    if (!this.webPreferences.dialerReferences) {
      this.webPreferences.dialerReferences = new DialerPreferences();
    }
    if (this.webPreferences.dialerReferences.selectedColumns[this.selectedDialList.dialListId] != this.selectedColumns) {
      this.webPreferences.dialerReferences.selectedColumns[this.selectedDialList.dialListId] = this.selectedColumns;
      this._commonService.changeWebPreferences(this.webPreferences);
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => {
      });
    }
  }

  loadDynamicData = () => {
    this.columnsLoading = true;
    this._dialerService.getBasicDialListNew(this.selectedDialList.dialListId)
      .subscribe({
        next: (dialList) => {
          const dynamicColumns = this.getFilteredDynamicColumns(dialList.recordData.columns);

          this.records.forEach(record => {
            const dynamicRow = dialList.recordData.data.find(drd =>
              (drd.applicationId && drd.applicationId == record.applicationId) ||
              (drd.leadId && drd.leadId == record.leadId) ||
              (drd.borrowerId && drd.borrowerId == record.borrowerId) ||
              (drd.agentId && drd.agentId == record.agentId) ||
              (drd.recordId && drd.recordId == record.contactListRecordId) ||
              (drd.creditMonitoringDataId && drd.creditMonitoringDataId == record.creditMonitoringDataId) ||
              (drd.externalCompanyId && drd.externalCompanyId == record.externalCompanyId));

            if (dynamicRow) {
              dynamicColumns.forEach(column => {
                const fieldValue = dynamicRow.rowData[column.order];
                record[column.field] = fieldValue;
                if (this.isCustomContactListRecord(record)) {
                  if (!record.mobilePhone && column.fieldType === FieldType.MobilePhone) {
                    record.mobilePhone = fieldValue;
                    delete record[fieldValue];
                  }
                  if (!record.email && column.fieldType === FieldType.Email) {
                    record.email = fieldValue;
                    delete record[fieldValue];
                  }
                  if (record.homePhone && column.fieldType === FieldType.LandLinePhone) {
                    record.homePhone = fieldValue;
                    delete record[fieldValue];
                  }
                }
              })
            }

            const recordNew = dialList.records.find(r => r.dialListRecordId == record.dialListRecordId);
            if (recordNew) {
              record.lastMessageContent = recordNew.lastMessageContent;
              if (record.recordType === RecordType.Application) {
                const message = record.lastMessageContent;
                record.lastMessageContent = MentionsUtils.generateDisplayHtmlWithMentions(message);
                record['lastMessageContentTitle'] = MentionsUtils.generateDisplayTextWithMentions(message);
              }
            }
          })
          this.populateColumns(dynamicColumns);
        },
        error: (err) => {
          this.columnsLoading = false;
          this._notificationService.showError(err.message || err, "Fetching Columns Error");
        }
      })
  }

  populateColumns = (dynamicFilteredColumns: TableColumn[]) => {
    this.columns = [
      { field: 'loanOfficer', header: 'Loan Officer', order: 1, visible: true },
      { field: 'lastName', header: 'Name', order: 2, visible: true },
      { field: 'phone', header: 'Phone', order: 3, visible: true },
      { field: 'email', header: 'Email', order: 4, visible: true }
    ];

    if (this.isRecordEditable) {
      this.columns.push({
        field: 'lastMessageContent', header: 'Note', order: 5, visible: true
      })
    }

    dynamicFilteredColumns.forEach((col, index) => {
      const existing = this.columns.find(c => c.field === col.field);
      if (!existing) {
        this.columns.push({
          ...col,
          order: 5 + (index + 1),
          visible: index < 8
        });
      }
    });
    this.columns.push({ field: 'statusId', header: 'Status', order: (this.columns.length + 1), visible: true });
    if(this.records[0]?.recordType == RecordType.Application){
      const subStatusIdx = this.columns.findIndex(c => c.field == "subStatus");
      this.columns[subStatusIdx].order = this.columns.length + 2;
      this.columns.sort((c1,c2) => c1.order - c2.order);
    }

    if (!this.isRecordEditable) {
      this.columns = this.columns.filter(c => c.field !== 'loanOfficer' && c.field !== 'lastName');
    }

    this._commonService.getWebPreferences().subscribe(response => {
      this.columnsLoading = false;
      this.webPreferences = response || new WebPreferences();

      if (!!this.webPreferences?.dialerReferences?.selectedColumns[this.selectedDialList.dialListId]?.length) {
        this.selectedColumns = this.webPreferences?.dialerReferences?.selectedColumns[this.selectedDialList.dialListId];
        if (!this.isRecordEditable) {
          this.selectedColumns = this.selectedColumns.filter(c => c.field !== 'loanOfficer' && c.field !== 'lastName');
        }

        // backwards compatibility: removing second 'Rate' column
        let colIndex = this.selectedColumns.findIndex(c => c.field === "rate");
        if (colIndex > -1) {
          this.selectedColumns.splice(colIndex, 1);
        }

        colIndex = this.selectedColumns.findIndex(c => c.field === "mostRecentNote");
        if (colIndex > -1) {
          this.selectedColumns.splice(colIndex, 1);
        }

        colIndex = this.selectedColumns.findIndex(c => c.field === "subStatus");
        if (colIndex == 6) {
          this.selectedColumns.splice(colIndex, 1);
        }

        Object.keys(this.webPreferences.dialerReferences.selectedColumns).forEach(dialListId => {
          let isDeleted = this.dialLists.some(d => d.dialListId == Number(dialListId));
          if (isDeleted) {
            delete this.webPreferences.dialerReferences.selectedColumns[dialListId];
          }
        });
        this.columns.forEach(column => {
          this.globalFilterFields.push(column.field);
        });
      } else {
        this.selectedColumns = [];

        this.columns.forEach(column => {
          this.globalFilterFields.push(column.field);
          if (column.visible) {
            this.selectedColumns.push(column);
          }
        });
      }
    }, (err) => {
      this.columnsLoading = false;
      this.selectedColumns = [];

      this.columns.forEach(column => {
        this.globalFilterFields.push(column.field);
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });
    });

    if (this.sortOnInit) {
      this.fieldsOrderingStatus["lastAttemptedContact"] = -1;
      this.sortBy({field: 'lastAttemptedContact', data: this.selectedDialList.records});
    }

    this.onPageChanged.emit({ currentPage: this.currentPage, numOfItemsOnPage: this.pageSize });
  }

  getFilteredDynamicColumns = (columns) => {
    return columns.filter(col => (!col.name.includes("Company_") && !col.name.includes("System_")) || col.name.includes("System_CurrentDate"))
      .map(col => {
        let cleanedField = this.cleanColumnName(col.name)
        return {
          field: cleanedField == 'Rate' ? 'interestRate' : (cleanedField.charAt(0).toLowerCase() + cleanedField.slice(1)),
          header: Utils.splitCamelCaseString(cleanedField).replaceAll("_", " ")
            .replace("P O", "PO")
            .replace("S S N", "SSN")
            .replace("N M L S", "NMLS")
            .replace("L O", "LO")
            .replace("L T V", "LTV")
            .replace("C L T V", "CLTV")
            .replace("D O B", "DOB")
            .replace("P A N D I", "PANDI")
            .replace("Address2", "Address 2")
            .replace("E Signature", "E-Signature")
            .replace("Date Inserted", "Record Created")
            .replace("Current Date", "Success / Attempted Contact"),
          order: col.ordinal,
          dataType: col.dataType,
          fieldType: col.fieldType
        } as TableColumn
      })
      .filter(col => !['borrowerId', 'agentId', 'leadId', 'applicationId', 'creditMonitoringDataId', 'userId',
        'firstName', 'lastName', 'middleName', 'mobilePhone', 'homePhone', 'workPhone', 'extPhone'].includes(col.field))
      .sort((col1, col2) => col1.order - col2.order)
  }

  cleanColumnName = (name: string) => {
    if (!name) {
      return null;
    }

    let willBeCleaningWords = Object.values(ContactListType).map(val => val + "_");
    willBeCleaningWords.forEach(word => {
      if (name.includes(word)) {
        name = name.replace(word, "");
      }
    })

    return name;
  }

  refresh = (dialList: DialListBasic) => {
    this.selectedDialList = dialList;
  }

  getSortedColumns = (): any[] => {
    const sortedColumns = this.selectedColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }

  customSearch = (): void => {
    if (this.searchText) {
      let filteredData = this.originalRecords.filter(r => {
        let matchedFields = Object.keys(r).filter(fieldName => {
          return r[fieldName] && (
            (["firstName", "lastName"].includes(fieldName) ? r["firstName"] + " " + r["lastName"] : r[fieldName])
              .toString().toLowerCase()).indexOf(this.searchText.toLowerCase()) > -1
        });
        return matchedFields && matchedFields.length > 0;
      });
      this.records = filteredData;
    } else {
      this.records = this.originalRecords;
    }
  }

  customSearchDebounced = debounce(this.customSearch, 400);

  openCallPanel = (selectedRecord: DialListRecordBasic, phoneType: PhoneType) => {
    //selectedRecord.selectedPhoneType = phoneType;
    selectedRecord.lastAttemptedContact = new Date();
    this.records.forEach(record => {
      const needsToBeStruckOut = this.needsToBeStruckOut(record);
      record.needsToBeStruckOut = needsToBeStruckOut;
    });
    this.onPhoneNumberClicked.emit({ recordToDial: selectedRecord, phoneType: phoneType })
  }

  onEditRecordClicked = (record: DialListRecordBasic, fromFindOnDiallist: boolean = true): void => {
    if (fromFindOnDiallist) {
      let matchedTr = this.recordRows.toArray().find(c => c.nativeElement.id.split("record_")[1] == record.dialListRecordId);
      if (matchedTr) {
        let element = matchedTr.nativeElement as Element;
        element.scrollIntoView();
      }
    }

    this.editRecordClicked.emit({
      record: record,
      fromFindOnDiallist: fromFindOnDiallist
    });
  }

  listener_changedSelectedRecordForCall = (record: DialListRecordBasic) => {
    let selectedRecord = this.records.find(r => r.dialListRecordId == record.dialListRecordId);
    if (selectedRecord) {
      selectedRecord.selectedPhoneType = record.selectedPhoneType;

      this.selectedRecordForCall = selectedRecord;
    }
  }

  sortBy = (e: SortEvent) => {

    const fieldName = e.field;

    Object.keys(this.fieldsOrderingStatus).forEach(field => {
      if (field != fieldName) {
        this.fieldsOrderingStatus[field] = undefined;
      }
    });

    let status = this.fieldsOrderingStatus[fieldName];
    let newStatusOrder = status == 1 ? -1 : 1;
    this.fieldsOrderingStatus[fieldName] = newStatusOrder;

    e.data.sort((data1, data2) => {
      let value1 = fieldName == "lastName" ? data1["firstName"] + ' ' + data1[fieldName] : data1[fieldName];
      let value2 = fieldName == "lastName" ? data2["firstName"] + ' ' + data2[fieldName] : data2[fieldName];
      let result = null;

      // 2 digit: decimal points , 1 digit: dot char = last 3 digit
      if(value1?.charAt(0) == "$"){
        value1 = Number(value1.slice(1,-3).replaceAll(",", ""));
      }

      if(value2?.charAt(0) == "$"){
        value2 = Number(value2.slice(1,-3).replaceAll(",", ""));
      }

      if (value1 == null && value2 != null){
        result = -1;
      }
      else if (value1 != null && value2 == null){
        result = 1;
      }
      else if (value1 == null && value2 == null){
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string'){
        result = value1.localeCompare(value2);
      }
      else{
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (newStatusOrder * result);
    });

  }

  onPage = (e: { first: number, rows: number }) => {
    let currentPage = Math.ceil(e.first / this.pageSize) + 1;
    this.currentPage = currentPage;
    this.onPageChanged.emit({ currentPage: currentPage, numOfItemsOnPage: this.pageSize })
  }

  listener_changeRecordCallStatusData = (data: { dialListRecordId: number, callStatus: CallStatus, phoneType: PhoneType }) => {
    let matched = this.records.find(r => r.dialListRecordId == data.dialListRecordId);

    if (!matched) {
      return;
    }

    if (matched.selectedPhoneType != data.phoneType) {
      matched.callStatus = null;
    }

    matched.selectedPhoneType = data.phoneType;

    if (matched.callStatus != data.callStatus) {

      matched.callStatus = data.callStatus;

      if (this._completedStatuses.indexOf(data.callStatus) > -1) {
        setTimeout(() => {
          matched.callStatus = null;
        }, 1000)
      }
    }
  }

  listener_changeParticipants = (participants: ConferenceParticipant[]) => {
    participants.forEach(p => {

      let matched = this.records.find(r => r.dialListRecordId == p.voiceHistory?.dialListRecordId);
      if (matched) {

        if (!matched.selectedPhoneType && p.phoneType) {
          matched.selectedPhoneType = p.phoneType;
          matched.callStatus = p.callStatus;
        }

        if (this._completedStatuses.indexOf(p.callStatus) > -1) {
          setTimeout(() => {
            // matched.selectedPhoneType = null;
            matched.callStatus = null;
          }, 1000)
        }
      }
    })
  }

  getTimeSinceDate = (value) => {
    return Utils.timeSinceDate(value);
  }

  onSavedNote = (record: DialListRecordBasic, res: string) => {
    record.lastMessageContent = MentionsUtils.generateDisplayHtmlWithMentions(res);
    record['lastMessageContentTitle'] = MentionsUtils.generateDisplayTextWithMentions(res);
  }

  onStateSave = (event) => {
    this.colOrder = event.columnOrder || [];
    if (this.init && this.colOrder.length > 0) {
      const columnsWithOrder = this.columns.filter(col => this.colOrder.indexOf(col.field) > -1).sort((a, b) => this.colOrder.indexOf(a.field) - this.colOrder.indexOf(b.field));
      const columnsWithoutOrder = this.columns.filter(col => this.colOrder.indexOf(col.field) === -1);
      this.columns = [...columnsWithOrder, ...columnsWithoutOrder].map((col, index) => ({
        ...col,
        order: col.order = index + 1
      }));
      this.init = false;
    }

    this.selectedColumns.forEach((col, index) => col.order = index + 1);

    this._localStorageService.removeItem(this.tableStateName);
    if (this.webPreferences) {
      this.webPreferences.dialerReferences.selectedColumns[this.selectedDialList.dialListId] = this.selectedColumns
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { });
    }
  }

  getColumnStyles(colField: string): { [key: string]: string } {
    if (colField === 'interestRate') {
      return { 'min-width': '67px' };
    }

    if (colField === 'loanType') {
      return { 'min-width': '102px' };
    }

    if (colField === 'currentDate' || colField === 'purchasePrice' || colField === 'loanAmount') {
      return { 'min-width': '92px' };
    }

    if (colField === 'lastMessageContent') {
      return { 'min-width': '70px' };
    }

    return {};
  }

  private setSubjectPropertyForRecord = (record: DialListRecordBasic) => {
    record['subjectPropertyAddress'] = ""; // reset when accordion is opened again

    record['subjectPropertyAddress'] += record.address ? record.address : "";

    if (record.city) {
      if (record['subjectPropertyAddress'].length > 0) {
        record['subjectPropertyAddress'] += ", ";
      }
      record['subjectPropertyAddress'] += record.city;
    }

    if (record['subjectPropertyAddress'].length > 0) {
      record['subjectPropertyAddress'] += ", ";
    }

    record['subjectPropertyAddress'] += record.state ? record.state.toUpperCase() : "";
    record['subjectPropertyAddress'] += record.zip ? " " + record.zip : "";
  }

  private setLoanOfficerForRecord = (record: DialListRecordBasic) => {
    const lo = this.loanOfficers.find(u => u.userCompanyGuid === record.loanOfficerUserId);
    record['loanOfficer'] = Utils.getPersonsDisplayName(lo);
  }

  private isCustomContactListRecord = (record: DialListRecordBasic) => {
    return !record.applicationId && !record.leadId && !record.borrowerId && !record.agentId && !record.creditMonitoringDataId && !record.externalCompanyId;
  }

  private needsToBeStruckOut = (record: DialListRecordBasic): boolean => {
    // If the record has a last attempted contact time, and it's less than 4 hours ago, strike it out
    const lastAttemptedContact = record.lastAttemptedContact || record.lastSuccessfulContact;
    if (lastAttemptedContact) {
      const now = luxon.DateTime.fromJSDate(new Date());
      const lastAttemptedContact = (typeof record.lastAttemptedContact.getMonth === 'function') ?
        luxon.DateTime.fromJSDate(record.lastAttemptedContact) :
        luxon.DateTime.fromISO(record.lastAttemptedContact.toString());
      const diff = now.diff(lastAttemptedContact, ["hours"])

      const needsToBeStruckOut = (diff as any).values.hours <= this.strikeOutHours;
      return needsToBeStruckOut;
    }
  }
}
