import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PricedProduct } from '../../models/pricing/product-pricing-detail.model';
import { ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';
import { Product } from '../../models/pricing/product-search-result.model';

@Component({
  selector: 'app-qm-details-dialog',
  templateUrl: './qm-details-dialog.component.html',
  styleUrls: ['./qm-details-dialog.component.scss']
})
export class QmDetailsDialogComponent implements OnInit {

  @Input()
  qualifiedMortgageDetails: PricedProduct;

  @Input()
  product: Product;

  @Input()
  set productSearchRequest(productSearchRequest: ProductSearchRequest) {
    this._productSearchRequest = productSearchRequest;
    if (!this._productSearchRequest) {
      return;
    }
  }

  get productSearchRequest(): ProductSearchRequest {
    return this._productSearchRequest;
  }

  private _productSearchRequest: ProductSearchRequest;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
