export class LoanDocESign {
    guid: string;
    loanDocId: number;
    applicationId: number;
    documentTypeId: number;
    description: string;
    note: string;
    expirationDate: string;

    constructor(guid: string, loanDocId: number, applicationId: number, documentTypeId: number, description: string, note: string, expirationDate: string) {
        this.guid = guid;
        this.loanDocId = loanDocId;
        this.applicationId = applicationId;
        this.documentTypeId = documentTypeId;
        this.description = description;
        this.note = note;
        this.expirationDate = expirationDate;
    }
}