<div class='modal-header'>
  <h2 class='modal-title'>Import Products from Vendor</h2>

  <button
    (click)='onClickCancel()'
    aria-label='Close'
    class='btn-close'
    type='button'
  ></button>
</div>

<div class='modal-body'>
  <div class='table-options'>
    <span class='form-group'>
      <label for='vendor'>Select Vendor</label>
      <select
        (ngModelChange)='onChangeSelectedVendor($event)'
        [ngModel]='selectedVendor'
        class='form-select'
        id='vendor'
        name='vendor'
      >
        <option *ngFor='let option of vendorOptions' [value]='option.value'>
          {{ option.name }}
        </option>
      </select>
    </span>

    <span class='form-group'>
      <label for='channel'>Select Channels</label>
      <ng-select
        [(ngModel)]='selectedChannels'
        [closeOnSelect]='false'
        [items]='channelOptions'
        [multiple]='true'
        bindLabel='name'
        bindValue='value'
        id='channel'
        name='channel'
        placeholder='Select Channels'
      ></ng-select>
    </span>
  </div>

  <div class='product-table form-group'>
    <p-table
      #dt
      [globalFilterFields]="globalFilterFields"
      [(selection)]='selectedProducts'
      [loading]='isLoadingProducts'
      [paginator]='true'
      [rows]='20'
      [scrollable]='true'
      [value]='products | sort:"asc":"productName"'
      dataKey='productId'
      responsiveLayout='scroll'
      scrollHeight='flex'
    >
      <ng-template pTemplate='header'>
        <tr>
          <th style='width: 3em'>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>

          <th class="product-name-column-header">
            Product Name
            <span class="p-input-icon-right h-100">
              <i class="pi pi-search"></i>
              <input pInputText #searchText type="text" class="form-control pt-2 pb-2" (ngModelChange)="dt.first=0"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search product" />
            </span>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate='body' let-product>
        <tr>
          <td>
            <p-tableCheckbox [value]='product'></p-tableCheckbox>
          </td>

          <td>
            <span
              *ngLet='product.productName as name'
              [title]='name'
            >
              {{ name }}
            </span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate='emptymessage'>
        <tr>
          <td [attr.colspan]='2'>No products found.</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <div class="paginator">
            Showing
            {{state.rows * state.page + 1}}
            to
            {{(state.rows * state.page) + state.rows > state.totalRecords ? state.totalRecords : (state.rows * state.page) + state.rows}}
            of
            {{state.totalRecords}}
            entries
          </div>
      </ng-template>

    </p-table>
  </div>
</div>

<div class='modal-footer gap-2'>
  <button
    (click)='onClickCancel()'
    aria-label='Cancel'
    class='btn btn-secondary'
    type='button'
  >
    Cancel
  </button>

  <button
    (click)='onClickImport()'
    *ngLet='selectedProducts.length as count'
    [disabled]='isLoadingProducts || isImportingProducts || count < 1'
    aria-label='Import'
    class='btn btn-primary'
    type='button'
  >
    <ng-container *ngIf='isImportingProducts'>
      <span class='spinner-border spinner-border-sm me-1' role='status'></span>
    </ng-container>

    Import {{ count > 0 ? '(' + count + ')' : '' }}
  </button>
</div>