import { LosVendor } from "src/app/models";
import { ExternalCompany } from "src/app/models/external-company";
import { FeeTemplate } from "src/app/models/fee/fee-template.model";
import { LoanFee } from "src/app/models/fee/fee.model";
import { MortgageDuAusRecommendation, MortgageLpaAusRecommendation } from "src/app/modules/admin/tpo-config/models/configuration-product.model";
import { Channel } from "src/app/modules/leads/models/lead.model";
import { QuarterEnum } from "./quarter-enum.model";

export class McrFullAuditData {
  auditData: McrAuditData;
  validationResults: McrAuditDataValidationResult[];

  constructor() {
    this.auditData = new McrAuditData();
  }
}

export class McrAuditData {
  mcrAuditDataId: number;
  applicationId: number;
  applicationModel: ApplicationModel;
  quarter: QuarterEnum;
  year: number;
  isComplete: boolean;
  dirty: boolean;
  calculated: AuditDataValues = new AuditDataValues();
  manual: AuditDataValues = new AuditDataValues();
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class McrAuditDataValidationResult {
  memberNames: string[];
  errorMessage: string;
}

export class McrAuditDataQuarter {
  item1: QuarterEnum;
  item2: number;
}

export class McrQuarterYear {
  quarter: QuarterEnum;
  year: number;
}

export class AuditDataValues {
  applicationAmountStartOfQuarter: number = null;
  applicationAmountEndOfQuarter: number = null;
  loanType: MCRLoanType = null;
  propertyType: MCRPropertyType = null;
  loanPurpose: MCRLoanPurpose = null;
  isHOEPA: boolean = null;
  syncFromApplication: boolean = null;
  lienType: MCRLienType = null;
  brokerFeesCollected: number = null;
  lenderFeesCollected: number = null;
  mortgageDirection: MCRMortgageDirection = null;
  reverseMortgageType: MCRReverseMortgageType = null;
  reverseMortgagePurpose: MCRReverseMortgagePurpose = null;
  qualifiedMortgageType: MCRQualifiedMortgageType = null;
  mortgageServicingDisposition: MCRMortgageServicingDisposition = null;
  branchGrossProfit: number = null;
  grossProfit: number = null;
  netProfit: number = null;
  totalPricingExceptions: number = null;
  loanOfficerCompensation: number = null;
  excludeFromReport: boolean = null;
  channel: MCRChannel = null;
  state: string = null;
  pipelinePositions: McrPipelinePositionValues = new McrPipelinePositionValues();
  year: number = null;
  quarter: string = null;
  mcrAuditDataId: number = null;
}

export class LoanAuditDataValues extends AuditDataValues {
  applicationId: number;
}

export enum MCRChannel {
  Retail = "Retail",
  Wholesale = "Wholesale",
  Brokered = "Brokered"
}

export enum MCRApplicationPipelinePosition {
  InProcessAtStartOfPeriod = "InProcessAtStartOfPeriod",
  Received = "Received",
  ApprovedButNotAccepted = "ApprovedButNotAccepted",
  Denied = "Denied",
  Withdrawn = "Withdrawn",
  ClosedForIncompleteness = "ClosedForIncompleteness",
  PreApprovalRequestDenied = "PreApprovalRequestDenied",
  PreApprovalRequestApprovedButNotAccepted = "PreApprovalRequestApprovedButNotAccepted",
  ClosedAndFunded = "ClosedAndFunded",
  InProcessAtEndOfPeriod = "InProcessAtEndOfPeriod"
}

export enum MCRLoanType {
  Conventional = "Conventional",
  FHAInsured = "FHAInsured",
  VAGuaranteed = "VAGuaranteed",
  FSA_RHSGuaranteed = "FSA_RHSGuaranteed"
}

export enum MCRPropertyType {
  OneToFourFamilyDwelling = "OneToFourFamilyDwelling",
  ManufacturedHousing = "ManufacturedHousing",
  MultifamilyDwelling = "MultifamilyDwelling"
}

export enum MCRLoanPurpose {
  HomePurchase = "HomePurchase",
  HomeImprovement = "HomeImprovement",
  Refinancing = "Refinancing"
}

export enum MCRLienType {
  FirstLien = "FirstLien",
  SubordinateLien = "SubordinateLien",
  NotSecuredByALien = "NotSecuredByALien"
}

export enum MCRMortgageDirection {
  Forward = "Forward",
  Reverse = "Reverse"
}

export enum MCRReverseMortgageType {
  HECMStandard = "HECMStandard",
  HECMSaver = "HECMSaver",
  ProprietaryOther = "ProprietaryOther"
}

export enum MCRReverseMortgagePurpose {
  HomePurchase = "HomePurchase",
  Other = "Other"
}

export enum MCRQualifiedMortgageType {
  QualifiedMortgage = "QualifiedMortgage",
  NonQualifiedMortgage = "NonQualifiedMortgage",
  NotSubjectToQM = "NotSubjectToQM"
}

export enum MCRMortgageServicingDisposition {
  IntendToRetainRights = "IntendToRetainRights",
  IntendToSellRights = "IntendToSellRights"
}

export enum LosSyncDir {
  LosToLoda = "LosToLoda",
  Both = "Both"
}

export enum DisclosurePath {
  NotSet = "NotSet",
  Lender = "Lender",
  Broker = "Broker"
}

export enum ApplicationCopyReason {
  Reorigination = "Reorigination",
  ChangeLender = "ChangeLender",
  Other = "Other"
}

export enum AmortizationType {
  FixedRate = "FixedRate",
  GEM = "GEM",
  GPM = "GPM",
  ARM = "ARM",
  OtherAmortization = "OtherAmortization",
  GraduatedPaymentARM = "GraduatedPaymentARM",
  RateImprovementMortgage = "RateImprovementMortgage",
  Step = "Step"
}

export class ApplicationModel {
  applicationId: number;
  userId: string;
  primaryBorrowerId: number;
  loanStatusId: number;
  subStatusId: number;
  adverseReasonId: number;
  loanTypeId: number;
  loanPurposeId: number;
  branchId: number;
  refNumber: string;
  losIdentifier: string;
  losVendor: LosVendor;
  losSyncDir: LosSyncDir;
  loanAmount: number;
  mipAmount: number;
  cashOutAmount: number;
  purchasePrice: number;
  subordinateLienAmount: number;
  presentValue: number;
  downAmount: number;
  existingLenderId: number;
  pauseMessages: boolean;
  hoiAgentId: number;
  titleAgentId: number;
  escrowAgentId: number;
  duCaseFileId: string;
  duRecommendation: MortgageDuAusRecommendation;
  lastDuRunDate: Date;
  lastLpaRunDate: Date;
  productPricingId: number;
  lenderId: number;
  estimatedClosingDate: Date;
  closingDate: Date;
  fundingDate: Date;
  cdIssueDate: Date;
  cdSignedDate: Date;
  originationDate: Date;
  payoffExpirationDate: Date;
  lastPaymentDate: Date;
  disbursementDate: Date;
  firstPaymentDate: Date;
  mailingStreet: string;
  mailingPOBox: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  referralSource: number;
  leadSource: string;
  onlineApplicationName: string;
  onlineApplicationVersion: string;
  leadCampaignId: number;
  channel: Channel;
  disclosurePath: DisclosurePath;
  externalCompanyId: number;
  externalCompanyModel: ExternalCompany;
  leadRefId: string;
  firstAttemptedContact: Date;
  firstSuccessfulContact: Date;
  lastAttemptedContact: Date;
  lastSuccessfulContact: Date;
  redistributionCount: number;
  lastRedistributionDate: Date;
  fetchCount: number;
  lastFetchDate: Date;
  contactAttempts: number;
  loanStatusDate: Date;
  isMismo34Application: boolean;
  copiedFromApplicationId: number;
  copiedApplicationModel: ApplicationModel;
  copyReason: ApplicationCopyReason;
  removeFromReporting: boolean;
  hasBeenCopied: boolean;
  currentFeeTemplateId: number;
  currentFeeTemplate: FeeTemplate;
  contactListId: number;
  contactListRecordId: number;
  tpoSubmissionDate: Date;
  fees: LoanFee[] = [];
  currentLoanUnpaidPrincipalBalance: number;
  currentLoanInterestRate: number;
  currentLoanTerm: number;
  currentLoanAmortizationType: AmortizationType;

  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class McrAuditDataLookup {
  mcrAuditDataId: number;
  year: number;
  quarter: QuarterEnum;
  excludeFromReport: boolean;
  syncFromApplication: boolean;
  state?: string | null;
  mcrChannel: MCRChannel;
  pipelinePositions: McrPipelinePositionValues;
}

export class McrPipelinePositionValues {
  inProcessAtStartOfPeriod?: boolean | null = null;
  received?: boolean | null = null;
  approvedButNotAccepted?: boolean | null = null;
  denied?: boolean | null = null;
  withdrawn?: boolean | null = null;
  closedForIncompleteness?: boolean | null = null;
  preApprovalRequestDenied?: boolean | null = null;
  preApprovalRequestApprovedButNotAccepted?: boolean | null = null;
  closedAndFunded?: boolean | null = null;
  inProcessAtEndOfPeriod?: boolean | null = null;
}


