import {Component, Injector, Input, OnInit} from '@angular/core';
import {ApplicationContextBoundComponent} from '../../../../../shared/components';
import * as _ from 'lodash';
import {User} from '../../../../../models/user/user.model';
import {DataService} from '../../../../../core/services/data.service';
import {UserProfile, UserType} from '../../../../../models';
import {NotificationService} from '../../../../../services/notification.service';
import {State} from '../../../../leads/components/lead-editor/lead-general-info/lead-general-info.component';
import {ZipCodeLookupResult} from '../../../../../models/zipcode-lookup-result.model';
import {DomSanitizer} from '@angular/platform-browser';
import { Utils } from 'src/app/core/services/utils';

@Component({
    selector: 'credit-monitoring',
    templateUrl: './credit-monitoring.component.html'
})
export class CreditMonitoringComponent extends ApplicationContextBoundComponent implements OnInit {
    @Input() companyExtendedModel: any;
    @Input() isCreditMonitoringEnabled: boolean;

    states: State[] = [];
    users: User[] = [];
    stikkumEnrollmentStatus: string;
    iframeLinkToEnroll: any;
    enrollmentData: any;
    enrollingClient: boolean;
    profile: UserProfile;
    personalAddress: any;
    usersEnrolled = {};
    usersEnrolling = {};

    constructor(
        private readonly injector: Injector,
        private sanitizer: DomSanitizer,
        private readonly _dataService: DataService,
        private readonly _notificationService: NotificationService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if(!this.isCreditMonitoringEnabled) {
            return;
        }
        this.users = _.orderBy(
            this.applicationContext.globalConfig.usersAll.filter(user => user.userType == UserType.Admin),
            ['firstName', 'lastName']
        );
        this.profile = this.applicationContext.currentlyLoggedInUserProfile.userProfile;
        this.states = Object.keys(this.applicationContext.globalConfig.states)
            .map(key => new State({ code: key, name: this.applicationContext.globalConfig.states[key] }));
        this.checkEnrollmentStatus();
    }

    checkEnrollmentStatus(): void {
        const url ='api/stikkum/client/enroll/';
        this._dataService.post(`${url}status`, {})
            .subscribe({
                next: res => {
                    this.stikkumEnrollmentStatus = res.status;
                    if (this.stikkumEnrollmentStatus == 'Pending') {
                        this._dataService.get(`${url}link`).subscribe({
                            next: result => {
                                this.iframeLinkToEnroll = this.sanitizer.bypassSecurityTrustResourceUrl(result);
                            }
                        });
                    }

                    if (this.stikkumEnrollmentStatus == 'Approved') {
                        this.populateEnrolledUsers();
                    }
                }
            });
    }

    populateEnrolledUsers(): void {
        this._dataService.get('api/stikkum/client/enroll/users')
            .subscribe({
                next: enrolledUsersIds => {
                    this.users.forEach(user => {
                        this.usersEnrolled[user.userCompanyGuid] = _.includes(enrolledUsersIds, user.userCompanyGuid);
                    });
                }
            });
    }

    enrollUser(user: User): void {
        this.usersEnrolling[user.userCompanyGuid] = true;
        this._dataService.post(`api/stikkum/client/enroll/users/${user.userCompanyGuid}`, {})
            .subscribe({
                next: response => {
                    if (response.token) {
                        this.populateEnrolledUsers();
                        this.usersEnrolling[user.userCompanyGuid] = false;
                        return;
                    }
                    this.usersEnrolling[user.userCompanyGuid] = false;
                    this._notificationService.showError(response.remarks, 'Error');
                },
                error: err => {
                    this.usersEnrolling[user.userCompanyGuid] = false;
                    this._notificationService.showError( err ? err.message || err : '', 'Error');
                }
            })
    }

    enrollForRetention(): void {
        this.personalAddress = {
            mailingStreet: this.profile.mailingStreet,
            mailingPOBox: this.profile.mailingPOBox,
            mailingCity: this.profile.mailingCity,
            mailingState: this.profile.mailingState,
            mailingZip: this.profile.mailingZip
        };
        this.enrollmentData = {
            personal_first_name: this.profile.firstName,
            personal_last_name: this.profile.lastName,
            personal_cell_phone: this.profile.cellPhone,
            personal_work_phone: this.profile.workPhone,
            personal_phone: this.profile.homePhone,
            personal_fax: this.profile.fax,
            personal_title: this.profile.title,
            personal_nmls: this.profile.nmls,
            personal_email: this.profile.email
        }
        if (this.enrollmentData.personal_cell_phone) {
            this.enrollmentData.personal_cell_phone = this.enrollmentData.personal_cell_phone.substr(0, 10);
        }
        if (this.enrollmentData.personal_phone) {
            this.enrollmentData.personal_phone = this.enrollmentData.personal_phone.substr(0, 10);
        }
        if (this.enrollmentData.personal_work_phone) {
            this.enrollmentData.personal_work_phone = this.enrollmentData.personal_work_phone.substr(0, 10);
        }
        this.stikkumEnrollmentStatus = 'InProgress';
    }

    enrollClient(): void {
        this.mapEnrollmentData();
        this._dataService.post('api/stikkum/client/enroll', this.enrollmentData)
            .subscribe({
                next: response => {
                    if (response.link_to_enroll) {
                        this.iframeLinkToEnroll = this.sanitizer.bypassSecurityTrustResourceUrl(response.link_to_enroll);
                        this.stikkumEnrollmentStatus = "Pending";
                    } else {
                        this._notificationService.showError(response.message, 'Error');
                    }
                }
            });
    }

    mapEnrollmentData(): void {
        const company = this.applicationContext.globalConfig.company.find(c => c.companyId == this.applicationContext.userPermissions.companyId);
        let url: any = company.companyURL && company.companyURL.split("|");
        url = url.length && url[0];
        const enrollmentData = {
            personal_zip_code: this.personalAddress.mailingZip,
            personal_address_1: this.personalAddress.mailingStreet,
            personal_address_2: this.personalAddress.mailingPOBox,
            personal_state: this.personalAddress.mailingState,
            personal_city: this.personalAddress.mailingCity,
            /*main poc*/
            main_poc_name: this.companyExtendedModel.mainPOCFirstName,
            main_poc_last_name: this.companyExtendedModel.mainPOCLastName,
            main_poc_title: this.companyExtendedModel.mainPOCTitle,
            main_poc_phone: this.companyExtendedModel.mainPOCWorkPhone,
            main_poc_cell_phone: this.companyExtendedModel.mainPOCMobilePhone,
            main_poc_email: this.companyExtendedModel.mainPOCEmail,
            main_poc_nmls: this.companyExtendedModel.mainPOCNmls,
            main_poc_address_1: this.companyExtendedModel.mainPOCAddress,
            main_poc_city: this.companyExtendedModel.mainPOCCity,
            main_poc_state: this.companyExtendedModel.mainPOCState,
            main_poc_zip_code: this.companyExtendedModel.mainPOCZipCode,
            /*company*/
            company_legal_name: this.companyExtendedModel.companyLegalName,
            company_dba_name: this.companyExtendedModel.companyDBAName,
            company_federal_tax_id: this.companyExtendedModel.federalTaxId,
            company_location_type: this.companyExtendedModel.locationType,
            company_phone: this.companyExtendedModel.phoneNumber,
            company_website: url,
            company_email: this.companyExtendedModel.email,
            company_fax: this.companyExtendedModel.faxNumber,
            company_nmls: company.nmls,
            company_type: this.companyExtendedModel.companyType,
            company_type_name: this.companyExtendedModel.companyTypeName,
            company_business_classification: this.companyExtendedModel.businessType,
            company_years_old: this.companyExtendedModel.yearsOld,
            loan_officers: this.companyExtendedModel.numberOfLoanOfficers,
            loans_under_management: this.companyExtendedModel.numberOfLoansUnderManagement,
            company_address_1: company.address,
            company_state: company.state,
            company_city: company.city,
            company_zip_code: company.zip
        }
        this.enrollmentData = Object.assign(enrollmentData, this.enrollmentData);
    }

    onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
        if (zipCode) {
            this.personalAddress.mailingState = zipCode.state.toLowerCase();
            this.personalAddress.mailingCity = Utils.toTitleCase(zipCode.city);
            this.personalAddress.mailingZip = zipCode.zipcode;
        }
    }
}
