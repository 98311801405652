import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentService } from 'src/app/core/services/document.service';
import {
  ExtendedFormFreeReportHistory,
  FormFreeHistory,
} from 'src/app/models';

@Component({
  selector: 'voa-history',
  templateUrl: 'voa-history.component.html',
})
export class VoaHistoryComponent implements OnInit {
  @Input('orders') orders: FormFreeHistory[];
  @Input('orderHistory') orderHistory: ExtendedFormFreeReportHistory[];
  @Output('getSelectedReportIds') getSelectedReportIds = new EventEmitter();

  constructor(private readonly _documentService: DocumentService) {}

  private isOrderStatusAlert = (order: ExtendedFormFreeReportHistory) => {
    return (
      order.orderStatus === 509 ||
      order.orderStatus === 511 ||
      order.orderStatus === 512 ||
      order.orderStatus === 5031 ||
      order.orderStatus === 5032 ||
      order.orderStatus === 5040
    );
  };

  private isOrderInHistoryStatusAlert = (
    order: ExtendedFormFreeReportHistory
  ) => {
    return (
      order.status == 509 ||
      order.status == 511 ||
      order.status == 512 ||
      order.status == 5031 ||
      order.status == 5032 ||
      order.status == 5040
    );
  };

  private isAnyOrderHistoryStatusAlert = () => {
    if (!this.orderHistory.length) return;
    return this.orderHistory.some(
      (el) =>
        el.status == 509 ||
        el.status == 511 ||
        el.status == 512 ||
        el.status == 5031 ||
        el.status == 5032 ||
        el.status == 5040
    );
  };

  importAssets = (order: ExtendedFormFreeReportHistory) => {
    this.getSelectedReportIds.emit(order);
  };

  isVoaAlert = (order: ExtendedFormFreeReportHistory = null) => {
    if (order) {
      if (order.orderStatus) return this.isOrderStatusAlert(order);
      if (order.status) return this.isOrderInHistoryStatusAlert(order);
    }
    return this.isAnyOrderHistoryStatusAlert();
  };

  downloadReport = (documentData) => {
    const fileUrl = this._documentService.convertBase64IntoPdf(documentData);
    window.open(fileUrl);
  };

  ngOnInit() {}
}
