import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { clone } from "lodash";
import { NotifyPartyLoanContact } from "src/app/modules/loan-docs/models/notify-party-loan-contact.model";
import { NotificationService } from "src/app/services/notification.service";
import { User } from 'src/app/models/user/user.model';
import Swal, { SweetAlertResult } from "sweetalert2";
import { GlobalConfig } from "src/app/models/config/global-config.model";
import { DocumentType, Role, UserPermissions, UserProfile } from "src/app/models";
import { ChannelService } from "src/app/services/channel.service";
import { InternalContactsService } from "src/app/modules/internal-contacts/services/internal-contacts.service";
import { InternalContact } from "src/app/modules/internal-contacts/models/internal-contact.model";
import { Utils } from "src/app/core/services/utils";
import { TaskService } from "src/app/services/task.service";
import { combineLatest } from "rxjs";
import { LoanDocTask } from "src/app/models/loan/loan-doc-task.model";

@Component({
  templateUrl: "import-conditions-dialog-bulk.component.html",
  styleUrls: ['./import-conditions-dialog-bulk.component.scss'],
})
export class ImportConditionsDialogBulkComponent implements OnInit {
  @Input()
  userCompanyGuid: string;

  @Input()
  loanChannel: string;

  @Input()
  users?: User[] = [];

  @Input()
  appId: number;

  @Input()
  branchId: number

  @Input()
  externalCompanyId: number

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  userPermissions: UserPermissions;

  @ViewChild("pasteAreaForm")
  pasteAreaForm: NgForm | undefined;

  @ViewChild("tableDataForm")
  tableDataForm: NgForm | undefined;

  @ViewChild("textArea")
  textArea: ElementRef<HTMLTextAreaElement>

  conditionList: ConditionListArr = [];
  filteredConditionList: ConditionListArr = [];
  errorMessage: string[] = [];
  notifyPartyLoanContacts: NotifyPartyLoanContact[] = [];
  internalContacts: InternalContact[] = [];
  externalCompanyUsers: UserProfile[] = [];
  branchUsers: UserProfile[] = [];
  documentTypes: DocumentType[];

  specialCharacter: string = "";

  maxDescriptionLength: number = 0;

  isLoanTpo: boolean = false;
  isSeparatorChecked: boolean = false;
  showTable: boolean = false;
  areDeselectedRowsShown: boolean = true;
  isEditTableEnabled: boolean = false;
  importing: boolean = false;

  constructor(
    private readonly _notifyService: NotificationService,
    private readonly _channelService: ChannelService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _taskService: TaskService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.isLoanTpo = this.externalCompanyId > 0;
    this.documentTypes = this.globalConfig.documentType || [];

    this.loadInternalContacts();

    if (this.externalCompanyId) {
      this.loadExternalCompanyUsers();
    }
  }

  formatCopiedConditions() {
    this.errorMessage = [];
    this.conditionList = [];
    this.filteredConditionList = [];

    if (!this.isPasteAreaValid()) return;

    let currentConditionDescription = ""; // keeps current description so a description (array) can be assigned to
    const conditiondsArr = this.textArea?.nativeElement?.value?.split("\n") || [];

    conditiondsArr.forEach((row, index) => {
      // there is no alphabetical character in a row
      if (row.search(/[a-zA-Z]/) === -1) return;

      if (this.isSeparatorChecked) {
        const hasConditionDescription = row.indexOf(this.specialCharacter) > -1;
        const condition = row.split(this.specialCharacter);
        if (hasConditionDescription) {
          currentConditionDescription = condition[0].replaceAll(this.specialCharacter, "");
          this.conditionList.push({
            description: currentConditionDescription,
            conditionText: condition[1] || "",
            isPotentialInvalid: this.conditionList[index]?.isPotentialInvalid || this.checkIsInvalid(row, this.specialCharacter, currentConditionDescription),
          })
        } else {
          this.parseWithoutConditionType(row, currentConditionDescription, conditiondsArr, index);
        }
      } else {
        const hasConditionDescription = row.indexOf(this.specialCharacter) > -1;
        if (hasConditionDescription) {
          currentConditionDescription = row.replaceAll(this.specialCharacter, "");
          this.conditionList.push({
            description: currentConditionDescription,
            isPotentialInvalid: this.conditionList[index]?.isPotentialInvalid || this.checkIsInvalid(row, null, currentConditionDescription),
            conditionText: "",
          });
        } else {
          this.parseWithoutConditionType(row, currentConditionDescription, conditiondsArr, index);
        }
      }
    })

    this.conditionList = this.conditionList.map(el => ({
      ...el,
      isSelected: true, // by default
      isPotentialInvalid: el.isPotentialInvalid || !el.description || !el.conditionText,
      userId: this.userCompanyGuid,
      ['displayUserName']: this.getDisplayUserNameForCondition(this.userCompanyGuid),
    }))

    const isWithoutDescriptionOrText = this.conditionList.some(c => !c.description || !c.conditionText);
    if (isWithoutDescriptionOrText) {
      this.addNewErrorMessage(ErrorMessagesEnum.WITHOUT_DESCRIPTION_OR_TEXT);
    }

    this.filteredConditionList = clone(this.conditionList);
    this.showTable = true;
  }

  toggleSelectedCondition(description: string) {
    const cond = this.conditionList.find(c => c.description === description);
    if (!cond) return;

    cond.isSelected = !cond.isSelected;

    this.filterConditionList();
  }

  toggleDeselectedRows() {
    this.filterConditionList();
  }

  importConditions() {
    if (!this.conditionList.length) return;

    const selectedConditions = this.conditionList.filter(c => c.isSelected);

    if (this.errorMessage.length > 0 && selectedConditions.length > 0) {
      this.showImportConfirmationSwal(selectedConditions);
      return;
    }

    this.import(selectedConditions);
  }

  isInNotifyPartyList(userId: string) {
    return (
      this.notifyPartyLoanContacts &&
      !!this.notifyPartyLoanContacts.find((user) => user.userId == userId)
    );
  };

  onResponsiblePartyChanged(condition: ConditionList) {
    condition['displayUserName'] = this.getDisplayUserNameForCondition(condition.userId);
  }

  onReviewPartyChanged(condition: ConditionList) {
    condition['displayReviewPartyName'] = this.getDisplayUserNameForCondition(condition.reviewPartyId);
  }

  onDocTypeChanged(condition: ConditionList) {
    condition['displayDocTypeName'] = this.documentTypes.find(docType => docType.documentTypeId === condition.documentTypeId)?.documentTypeName || "";
  }

  onReviewRequiredChanged(condition: ConditionList) {
    if (!condition.requiresReview) {
      condition.reviewPartyId = null;
      condition['displayReviewPartyName'] = null;
    }
  }

  onRequestBorrowerChanged(condition: ConditionList) {
    if (!condition.requestBorrower) {
      condition.borrowerFacingNote = null;
    }
  }

  // actual import
  private import(selectedConditions) {
    if (selectedConditions.length === 0) {
      this._notifyService.showError("There are no selected conditions to be imported.", "Error");
      return;
    }

    // when in edit mode
    if (!this.tableDataForm?.form) return;
    this.tableDataForm.form.markAllAsTouched();
    if (!this.tableDataForm.form.valid) return;

    if (this.conditionList.some(cond => cond.requiresReview && !cond.reviewPartyId)) {
      this._notifyService.showError("Some of your conditions don't have required reviewer", "Error!");
      return;
    }

    this.conditionList.forEach(cond => {
      cond.condition = true;
      cond.applicationId = this.appId;
      cond.taskType = "RequestDocument";
      cond.taskPriority = "Normal";
    })

    const reqArr = this.conditionList.map(c => this._taskService.upsertLoanDocTask({
      loanDocTask: c as unknown as LoanDocTask,
    }));

    this.importing = true;
    combineLatest([...reqArr]).subscribe({
      next: (result) => {
        this._notifyService.showSuccess("Imported conditions successfully", "Success");
        this.activeModal.close(result);
      },
      error: (error) =>{
        this._notifyService.showError(error?.message || "Couldn't import conditions.", "Error");
      }
    }).add(() => this.importing = false)
  }

  private showImportConfirmationSwal(selectedConditions) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to import conditions with potential issues?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result: SweetAlertResult) => {
      if (!result.value) return;

      this.import(selectedConditions);
    });
  }

  private filterConditionList() {
    if (this.areDeselectedRowsShown) {
      this.filteredConditionList = clone(this.conditionList);
    } else {
      this.filteredConditionList = this.conditionList.filter(c => c.isSelected);
    }
  }

  private isPasteAreaValid(): boolean {
    if (!this.pasteAreaForm?.form) return;
    this.pasteAreaForm.form.markAllAsTouched();
    return this.pasteAreaForm.form.valid;
  }

  private parseWithoutConditionType(row: string, currentConditionDescription: string, conditiondsArr: string[], index: number) {
    if (conditiondsArr[index - 1]) {
      const i = this.conditionList.findIndex(c => c.description === currentConditionDescription);
      if (i > -1) {
        this.conditionList[i] = {
          ...this.conditionList[i],
          isPotentialInvalid: this.conditionList[i]?.isPotentialInvalid || this.checkIsInvalid(row),
          conditionText: this.conditionList[i].conditionText + row
        }
      } else {
        this.addNewErrorMessage(ErrorMessagesEnum.UNKNOWN);
      }
    } else {
      // when user starts copying without special character
      currentConditionDescription = row.trim();
      this.conditionList.push({
        description: currentConditionDescription,
        isPotentialInvalid: true,
        conditionText: "",
      });
      this.addNewErrorMessage(ErrorMessagesEnum.UNKNOWN);
    }
  }

  private checkIsInvalid(row: string, specialCharacter?: string, description?: string): boolean {
    const containsPage = row.includes("Page");
    if (containsPage) {
      this.addNewErrorMessage(ErrorMessagesEnum.PAGE);
    }

    const hasMoreThenOneSpecialCharacter = specialCharacter ? row.split(specialCharacter).length > 2 : false;
    if (hasMoreThenOneSpecialCharacter) {
      this.addNewErrorMessage(ErrorMessagesEnum.TWO_SEPARATORS);
    }

    const hasTooLongDescription = description ? this.maxDescriptionLength > 0 && description.length > this.maxDescriptionLength : false;
    if (hasTooLongDescription) {
      this.addNewErrorMessage(ErrorMessagesEnum.TOO_LONG_DESCRIPTION);
    }

    return containsPage || hasMoreThenOneSpecialCharacter || hasTooLongDescription;
  }

  private addNewErrorMessage(message: ErrorMessagesEnum) {
    if (this.errorMessage.indexOf(message + '<br/>') > -1) return;
    this.errorMessage.push(message + '<br/>');
  }

  private getContactRoles = (channel: string): Role[] => {
    let contactRoles = [];
    if (this.userPermissions.enabledChannels && channel) {
      const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this.userPermissions.enabledChannels)
        .map((c) => c.value);
      if (enabledChannels.includes(channel)) {
        this.globalConfig.channelRoles[channel.toLowerCase()].forEach(role => {
          if (role.isLoanContact) {
            const roleChannel = role.roleChannels.find(roleChannel => roleChannel.channel === channel);
            role.orderByChannel = roleChannel.order || null;
            role.order = role.orderByChannel;
            contactRoles.push(role);
          }
        }
        );
      }
    } else {
      this.globalConfig.roles.forEach((role) => {
        if (role.isLoanContact) {
          contactRoles.push(role);
        }
      });
    }
    contactRoles.sort((a, b) => a.orderByChannel - b.orderByChannel);
    return contactRoles;
  };

  private loadInternalContacts = () => {
    this._internalContactsService
      .getInternalContacts(this.appId)
      .subscribe({
        next: (result) => {
          this.internalContacts = result || [];
          this.buildNotifyPartyLists();
        },
        error: (error) => {

        }
      })
  }

  private loadExternalCompanyUsers = () => {
    this._internalContactsService.getExternalCompanyUsers(this.userPermissions.companyId, this.externalCompanyId)
      .subscribe({
        next: (res) => {
          this.externalCompanyUsers = res.filter(u => u.branchId != this.branchId);
          this.branchUsers = res.filter(u => u.branchId == this.branchId && u.active);
        },
        error: (error) => {

        }
      })
  };

  private buildNotifyPartyLists = () => {
    const contactRoles = this.getContactRoles(this.loanChannel);
    contactRoles.forEach(contactRole => {
      const internalContact = this.internalContacts.find(contact => contact.roleId === contactRole.roleId);
      if (internalContact && internalContact.userId) {
        const userAsInternalContact = this.users.find(user => user.userCompanyGuid === internalContact.userId) ||
          this.externalCompanyUsers.find(externalUser => externalUser.userCompanyGuid === internalContact.userId);
        if (userAsInternalContact) {
          this.notifyPartyLoanContacts.push(
            new NotifyPartyLoanContact(
              userAsInternalContact.userCompanyGuid,
              contactRole.roleId,
              userAsInternalContact.firstName,
              userAsInternalContact.lastName,
              contactRole.roleName,
              contactRole.order
            )
          );
        }
      }
    });
    this.branchUsers = this.branchUsers.filter(
      branchUser => !this.notifyPartyLoanContacts.find(contact => contact.userId === branchUser.userCompanyGuid)
    );
  }

  private getDisplayUserNameForCondition = (userId: string): string => {
    let user = this.users.find(u => u.userCompanyGuid === userId);
    if (!user) {
      user = this.branchUsers.find(u => u.userCompanyGuid === userId) as any;
    }
    if (!user) {
      user = this.notifyPartyLoanContacts.find(u => u.userId === userId) as any;
    }
    if (!user && this.isLoanTpo) {
      user = this.externalCompanyUsers.find(u => u.userCompanyGuid === userId) as any;
    }
    if (!user) {
      return ""
    };

    return Utils.getPersonsDisplayName(user);
  }
}

enum ErrorMessagesEnum {
  GENEARL = "Some of your conditions may be invalid.",
  PAGE = "Some of your conditions include 'page' word.",
  TWO_SEPARATORS = "Some of your conditions include more then one separator.",
  TOO_LONG_DESCRIPTION = "Some of your conditions have too long description.",
  WITHOUT_DESCRIPTION_OR_TEXT = "Some of your conditions don't include description or text.",
  UNKNOWN = "Unknown parsing issue detected.",
}

type ConditionList = {
  description: string
  conditionText: string
  userId?: string
  applicationId?: number
  isSelected?: boolean
  isPotentialInvalid?: boolean
  requestBorrower?: boolean
  condition?: boolean
  taskType?: string
  taskPriority?: string
  borrowerFacingNote?: string
  documentTypeId?: number;
  requiresReview?: boolean;
  reviewPartyId?: string;
}
type ConditionListArr = Array<ConditionList>;
