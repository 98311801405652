import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FeeViewModel} from '../fee-view-model';
import {autoId} from '../../../core/services/utils';
import {splitCamelCase, toKebabCase, toProperCase} from '../../../core/services/string-utils';
import {concatMap, filter, map, Observable, Subscription, take} from 'rxjs';
import {EnumerationItem} from '../../../models/simple-enum-item.model';
import {FeePercentOfFieldEnum} from '../../../models/fee/fee-percent-of-field.enum';
import {FormGroup, NgForm} from '@angular/forms';
import {FeeUpdater, FeeUpdaterService} from '../services/fee-updater.service';
import {FeePercentOfField} from '../fees.model';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'fee-split-editor',
  templateUrl: './fee-split-editor.component.html',
  styleUrls: ['./fee-split-editor.component.scss'],
  providers: [FeeUpdaterService],
})
export class FeeSplitEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() fee: FeeViewModel;

  protected get effectiveFee(): FeeViewModel {
    return this._feeUpdater?.fee ?? this._fallbackFee;
  }

  @Input() editable: boolean = true;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<FeeViewModel>();

  @ViewChild('form') ngForm: NgForm;

  private get form(): FormGroup | undefined {
    return this.ngForm?.form;
  }

  private _id: string = autoId();

  protected feePercentOfOptions: EnumerationItem[];
  protected isPercentOfDisabled: boolean = true;
  private _percentPlusDollarAmount: number | null = null;

  protected get disabled(): boolean {
    return !this.editable || (this.form?.disabled ?? false);
  }

  protected get isSaving(): boolean {
    return this._saveSubscription != null;
  }

  protected get isPercentPlusDollarAmountDisabled(): boolean {
    return this.effectiveFee.isSplit;
  }

  protected get isPercentPlusDollarAmountInvalid(): boolean {
    return (
      this.isPercentPlusDollarAmountDisabled && this.effectiveFee.fee.percentPlusDollarAmount > 0
    );
  }

  protected get percentPlusDollarAmountError(): string | undefined {
    return this.isPercentPlusDollarAmountInvalid
      ? 'Split fees cannot have additional dollar amounts'
      : undefined;
  }

  private _feeUpdater?: FeeUpdater;

  // Placeholder for the input field binding
  private _fallbackFee = FeeViewModel.empty();

  private _applicationIdSubscription: Subscription | null = null;
  private _updateFeesSubscription: Subscription | null = null;
  private _calculatePayerTotalsSubscription: Subscription | null = null;
  private _saveSubscription: Subscription | null = null;

  constructor(
    private readonly _feeUpdaterService: FeeUpdaterService,
    private readonly _notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('fee' in changes) {
      this._feeUpdater?.destroy();
      this._feeUpdater = this._feeUpdaterService.createUpdater(this.fee);

      this.initFeePercentOfFields();
    }

    if ('editable' in changes) {
      this.invalidateUpdatingStateSubscription();
    }
  }

  ngOnInit() {
    this.initFeePercentOfOptions();
    if (this._updateFeesSubscription == null) {
      this.invalidateUpdatingStateSubscription();
    }
  }

  ngOnDestroy() {
    this._applicationIdSubscription?.unsubscribe();
    this._updateFeesSubscription?.unsubscribe();
    this._calculatePayerTotalsSubscription?.unsubscribe();
    this._saveSubscription?.unsubscribe();
    this._feeUpdater?.destroy();
  }

  private initFeePercentOfOptions(): void {
    this.feePercentOfOptions = Object.values(FeePercentOfFieldEnum).map(value => ({
      value,
      name: toProperCase(splitCamelCase(value)),
    }));
  }

  private invalidateUpdatingStateSubscription(): void {
    if (this.editable) {
      this.subscribeToUpdatingState();
    } else {
      this._updateFeesSubscription?.unsubscribe();
    }
  }

  private subscribeToUpdatingState(): void {
    this._updateFeesSubscription?.unsubscribe();
    this._updateFeesSubscription = this._feeUpdater.updatingState$.subscribe(isUpdating => {
      if (isUpdating) {
        this.form?.disable();
      } else {
        this.form?.enable();

        this.invalidateFormErrors();
      }
    });
  }

  private invalidateFormErrors(): void {
    this.form?.markAllAsTouched();

    if (this.isPercentPlusDollarAmountInvalid) {
      this.form?.get('percentPlusDollarAmount')?.setErrors({
        split: this.percentPlusDollarAmountError!,
      });
    }
  }

  private initFeePercentOfFields(): void {
    const fee = this.effectiveFee.fee;

    this.invalidateIsPercentOfDisabled();
    this._percentPlusDollarAmount = fee.percentPlusDollarAmount;
  }

  private invalidateIsPercentOfDisabled(): void {
    this.isPercentOfDisabled = this.effectiveFee.fee.feePercentOf == null;
  }

  protected setFeePercentOf(value: FeePercentOfField | undefined): void {
    const fee = this.effectiveFee.fee;
    fee.feePercentOf = value;
    this.invalidateIsPercentOfDisabled();

    if (this.isPercentOfDisabled) {
      this._percentPlusDollarAmount = fee.percentPlusDollarAmount;
      fee.percentPlusDollarAmount = undefined;
    } else {
      fee.percentPlusDollarAmount = this._percentPlusDollarAmount;
    }

    this.updateFees();
  }

  protected setPercentPlusDollarAmount(value: number | undefined): void {
    const fee = this.effectiveFee.fee;
    fee.percentPlusDollarAmount = value;
    this._percentPlusDollarAmount = value;
  }

  protected updateFees(): void {
    this._feeUpdater.update();
  }

  protected onClickClose(): void {
    this.cancel.emit();
  }

  protected onClickCancel(): void {
    this.cancel.emit();
  }

  /**
   * Waits for the fee payer updater to finish updating the fees. It emits and completes immediately
   * if it is not updated.
   * @private
   */
  private waitForFeesToUpdate(): Observable<void> {
    return this._feeUpdater.updatingState$.pipe(
      filter(isUpdating => !isUpdating),
      take(1),
      map(() => undefined)
    );
  }

  protected onClickSave(): void {
    this.invalidateFormErrors();
    if (this.form?.invalid) {
      this._notificationService.showError('Please fix the errors in the form.', 'Error');

      return;
    }

    this._saveSubscription?.unsubscribe();

    // We must wait for the current changes (if any) to be updated before we can save the fee.
    this._saveSubscription = this.waitForFeesToUpdate()
      .pipe(
        // Fees should already be updated on blur. Still, we want to make sure that the fees are
        // updated before we save the fee.
        concatMap(() => this._feeUpdater.updateWithResult())
      )
      .subscribe(result => {
        this.save.emit(result);
      });
  }

  protected onClickReset(): void {
    this._feeUpdater.discardChanges();
  }

  protected id(name: string): string {
    return `${this._id}-${toKebabCase(splitCamelCase(name))}`;
  }
}
