<ng-template #pageActionBarLeft>
  <bread-crum></bread-crum>
</ng-template>

<ng-template #mainContent>
  <loading-indicator *ngIf="loadingExportingFiles" [loadingMessage]="'Loading exporting docs, please wait...'">
  </loading-indicator>
  <div class="card" *ngIf="!loadingExportingFiles">
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="btn-toolbar" role="toolbar">
            <div class="btn-group">
              <button type="button" class="btn btn-md btn-outline-secondary waves-light waves-effect"
                [ngClass]="{'current': filter.filter === 'all'}" (click)="onFilterChanged('all')"> All
              </button>
              <button type="button" class="btn btn-md btn-outline-secondary waves-light waves-effect"
                [ngClass]="{'current': filter.filter === 'task'}" (click)="onFilterChanged('task')"> Task
              </button>
              <button type="button" class="btn btn-md btn-outline-secondary waves-light waves-effect"
                [ngClass]="{'current': filter.filter === 'condition'}" (click)="onFilterChanged('condition')">
                Condition
              </button>
            </div>
            <div class="form-check checkbox form-check-inline pointer mt-2">
              <input type="checkbox" id="selectAll" (click)="onSelectAllByFilterClicked()"
                [(ngModel)]="isSelectAllClicked">
              <label for="selectAll" class="pointer"> Select All </label>
            </div>
            <div class="form-check checkbox form-check-inline pointer mt-2">
              <input type="checkbox" id="exportSinglePdf" [(ngModel)]="filter.exportPdf"
                (ngModelChange)="onFilterExclusionsChanged()">
              <label for="exportSinglePdf" class="pointer"> Export as Single PDF
              </label>
            </div>
            <div class="form-check checkbox form-check-inline pointer mt-2">
              <input type="checkbox" id="excludeNA" [(ngModel)]="filter.excludeNa"
                (ngModelChange)="onFilterExclusionsChanged()">
              <label for="excludeNA" class="pointer"> Exclude N/A </label>
            </div>
            <div class="form-check checkbox form-check-inline pointer mt-2">
              <input type="checkbox" id="excludeCleared" [(ngModel)]="filter.excludeCleared"
                (ngModelChange)="onFilterExclusionsChanged()">
              <label for="excludeCleared" class="pointer"> Exclude Cleared </label>
            </div>
          </div>
        </div>
        <div class="col-md-1 text-end mt-1">
          <label style="vertical-align: sub;"> Stack Order </label>
        </div>
        <div class="col-md-3">
          <select class="form-select" name="stackingOrderId" [(ngModel)]="selectedStackingOrderId" style="height:36px;" (ngModelChange)="onStackingOrderChanged()">
            <option [ngValue]="0">All</option>
            <option *ngFor="let stackingOrder of filteredStackingOrders" value="{{stackingOrder.stakingOrderId}}">
              {{stackingOrder.docType}}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <button type="button" class="btn btn-primary btn-md waves-effect waves-light float-end" *ngIf="hasPageActionBar"
            [routerLink]="['/'+ userType + '/' + routerAppDetail + '/' + currentApplication.applicationId]">
            Cancel
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-primary btn-md waves-effect waves-light"
            (click)="onExportClicked()"
            [disabled]="!hasCheckedExportDocs"
          >
            Export ({{selectedFiles.length}})
          </button>
          <button
            *ngIf="hasTaskFiles"
            type="button"
            class="btn btn-primary btn-md waves-effect waves-light ms-2"
            (click)="onExportAllTasksClicked()"
          >
            Export All Tasks
          </button>
          <button
            *ngIf="hasConditionFiles"
            type="button"
            class="btn btn-primary btn-md waves-effect waves-light ms-2"
            (click)="onExportAllConditionsClicked()"
          >
            Export All Conditions
          </button>
          <span>
            <div class="form-check checkbox form-check-inline pointer mt-2">
              <input type="checkbox" id="isAllExpanded" [(ngModel)]="isAllExpanded">
              <label for="isAllExpanded" class="pointer">
                Expand/Collapse All Folders
              </label>
            </div>
          </span>
        </div>
      </div>
      <div class="card-body">
        <div class="accordion" id="accordion">
          <ng-container *ngFor="let docFiles of filteredDocumentTypes">
            <export-loan-docs-accordion
              *ngIf="docFiles.docFiles.length > 0"
              [filter]="filter"
              [files]="docFiles"
              [isSelectAllClicked]="isSelectAllClicked"
              [allExpanded]="isAllExpanded"
              (filesUpdated)="onFilesUpdatedFromTableCheckBox($event)"
              (filesArrayUpdated)="onFilesUpdatedFromHeaderCheckBox($event)"
            ></export-loan-docs-accordion>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<admin-page-template [useActionBarSections]="true" [hasPageActionBar]="hasPageActionBar" [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</admin-page-template>
