import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageHistoryItem } from 'src/app/models/message.model';

@Component({
  selector: 'preview-sent-email',
  templateUrl: './preview-sent-email-dialog.component.html',
  styleUrls: ['./preview-sent-email-dialog.component.scss']
})
export class PreviewSentEmailDialogComponent implements OnInit {

  @Input()
  message: MessageHistoryItem;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.message.delivery === 'Email') {
      this.loadEmailTemplatePreview();
    }
  }

  private loadEmailTemplatePreview = () => {
    setTimeout(() => {
      let iframe = document.getElementById(`emailPreviewPanel`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      setTimeout(() => {
        doc.open();
        doc.write(this.message.body);
        doc.close();
      }, 100)
    });
  }
}
