import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Lender } from 'src/app/models/config/lender.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ApplicationContextBoundComponent,
  RearrangeOrderComponent,
} from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import {
  DeleteWithReplacementConfirmationDialogComponent,
} from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import {
  LenderDetailsDialogComponent,
} from '../../dialogs/lender-details-dialog/lender-details-dialog.component';
import { LenderConfigService } from '../../services/lender-config.service';
import { PricingEngineVendor } from '../../../tpo-config/models/configuration-product.model';
import {
  PricingInvestor,
} from 'src/app/modules/pricing/models/pricing/pricing-investor-product.model';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { Utils } from 'src/app/core/services/utils';


@Component({
  selector: 'app-lender-config-lenders',
  templateUrl: './lender-config-lenders.component.html',
  styleUrls: ['./lender-config-lenders.component.scss']
})
export class LenderConfigLendersComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('lendersOrderDrawer')
  lendersOrderDrawer: DrawerComponent;

  @ViewChild('lendersOrder')
  lendersOrder: RearrangeOrderComponent

  lenders: Lender[] = [];
  selectedColumns: any[] = [];
  columns: any[] = [];
  globalFilterFields: string[] = [];
  states: EnumerationItem[] = [];
  itemsToOrder: Array<{ name: string, value: number }> = [];
  pricingVendors: { name: string, value: string, investors: PricingInvestor[] }[] = [];

  isDeleteClicked = {};
  orderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  isLoading: boolean = false;
  superAdmin: boolean = false;
  ldeEnabled: boolean = false;
  isProductEnabled: boolean = false;
  companyName: string;
  defaultSortingField: string;
  vendorsList: any;

  constructor(
    private readonly _lenderConfigService: LenderConfigService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _pricingVendorService: PricingService,
    private readonly _drawerService: DrawerService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.getPricingVendors();
    this.applicationContextService.context.subscribe(context => {
      this.superAdmin = context.userPermissions.superAdmin;
      this.isProductEnabled = context.userPermissions.pricingEnabled && context.userPermissions.defaultPricingVendor === "OptimalBlue";
      this.ldeEnabled = context.userPermissions.ldeEnabled;
      let matchedCompany = context.globalConfig.company.find(c => c.companyGUID == context.currentlyLoggedInUser.userCompanyGuid)
      this.companyName = matchedCompany ? matchedCompany.companyName : null;
      this.states = Object.keys(context.globalConfig.states).map(key => new EnumerationItem(context.globalConfig.states[key], key));

      if (this.superAdmin) {
        this.defaultSortingField = "active";
      }
      else {
        this.defaultSortingField = "sortOrder";
      }
    });

    this.columns = [
      { field: 'name', header: 'Name of Lender', order: 1, visible: true }
    ];

    if (this.ldeEnabled)
      this.columns.push({ field: 'ldeVendor', header: 'Lender Data Exchange', order: 2, visible: true });
    if (this.isProductEnabled) {
      this.columns.push({ field: 'pricingVendor', header: 'Pricing Vendor', order: 3, visible: true });
      this.columns.push({ field: 'pricingVendorInvestorId', header: 'Vendor Investor Id', order: 4, visible: true });
    }
    this.columns.push({ field: 'address', header: 'Phone / Address', order: 5, visible: true });
    this.columns.push({ field: 'aeName', header: 'AE Name', order: 6, visible: true });
    this.columns.push({ field: 'mortgageeClause', header: 'Mortgagee Clause', order: 7, visible: true });
    this.columns.push({ field: 'titleClause', header: 'Title Clause', order: 8, visible: true });
    this.columns.push({ field: 'active', header: 'Active', order: 9, visible: true });
    this.columns.push({ field: 'sortOrder', header: 'Sort', order: 10, visible: true });

    this.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });

    this.getLenders();

  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  addNewLender = (): void => {
    let modalRef = this._modalService.open(LenderDetailsDialogComponent,
      { ...Constants.modalOptions.ninetyPercentOfScreenWidth, scrollable: true });
    modalRef.componentInstance.pricingVendors = this.pricingVendors;
    modalRef.result.then(() => {
      this.getLenders();
    }, () => {
    });
  }

  getLenders = (): void => {
    this.isLoading = true;

    this._spinner.show();

    this._lenderConfigService.getAllLenders().subscribe(result => {
      this.lenders = result;
    }, (err) => {
      this._notifyService.showError(err.error.message || err.error, "Error!");
    }).add(() => {
      this.isLoading = false;
      this._spinner.hide();
    });
  }

  onEditLenderClicked = (lender: Lender) => {
    let modalRef = this._modalService.open(LenderDetailsDialogComponent,
      { ...Constants.modalOptions.ninetyPercentOfScreenWidth, scrollable: true });
    modalRef.componentInstance.pricingVendors = this.pricingVendors;
    modalRef.componentInstance.lender = lender;

    modalRef.result.then(() => {
      this.getLenders();
    }, () => {
    });
  }

  openOrderDrawer = () => {
    if (_.isEmpty(this.lenders)) {
      return;
    }

    this.itemsToOrder = _.chain(this.lenders)
      .orderBy(a => a.sortOrder)
      .map(a => ({
        name: a.name,
        value: a.lenderId
      }))
      .value();

    this._drawerService.show("lendersOrderDrawer", 100);
  }

  onOrderDrawerSave(sortedLenders: Array<EnumerationItem>) {
    this.lendersOrder.saveInProgress = true;

    const sortedLenderIds = sortedLenders.map(r => r.value as number);

    this._lenderConfigService.reOrderLenders(sortedLenderIds)
      .pipe(finalize(() => {
        this.lendersOrder.saveInProgress = false;
        this.closeOrderDrawer();
      }))
      .subscribe(() => {
        this.getLenders();
        this._notifyService.showSuccess('Lender order updated Successfully', 'Update Lender Order')
      }, (error) => {
        this._notifyService.showError(error.message || 'Unable to update Lender order ', 'Update Lender Order')
      });
  }

  closeOrderDrawer() {
    this._drawerService.hide("lendersOrderDrawer", 100);
  }

  onDeleteLenderClicked = (lenderId: number) => {

    let modalRef = this._modalService.open(DeleteWithReplacementConfirmationDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.ItemsList = this.lenders
      .filter(a => a.lenderId !== lenderId)
      .map(a => ({
        value: a.lenderId,
        name: a.name
      }));

    modalRef.componentInstance.itemType = "Lender";

    modalRef.result.then((replacementLenderId: number) => {
      this._lenderConfigService.deleteLender(lenderId, replacementLenderId)
        .subscribe(() => {
          this._notifyService.showSuccess("Lender removed succesfully", "Success");
          this.getLenders();
        }, (err) => {
          this._notifyService.showError(err.error.message || "Error encountered while deleting lender", "Error!");
        });

    }, () => {
    });

  }

  getState = (stateShort: string) => {
    return this.states.find(s => s.value == stateShort);
  }

  getPricingVendors = () => {
    for (let enumMember in PricingEngineVendor) {
      let enumName = this.getEnumName(PricingEngineVendor[enumMember]);
      if (enumName) {
        // TO-DO: other types have not shown in selection
        if (enumMember == PricingEngineVendor.OptimalBlue) {
          this.getPricingInvestors(PricingEngineVendor[enumMember], (investors) => {
            this.pricingVendors.push({ name: enumName, value: PricingEngineVendor[enumMember], investors: investors });
          })
        }
      }
    }
  }
  private getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" && enumValue != "Unknown" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  getPricingInvestors = (vendorName: PricingEngineVendor, callback) => {
    this._pricingVendorService.getPricingInvestors(vendorName)
      .subscribe({
        next: (result) => {
          callback(result);
        },
        error: (err) => {
          this._notifyService.showError(err?.message || "Error encountered while fetching " + this.getEnumName(vendorName) + " investors", "Error!");
          callback([]);
        }
      });
  }

  getInvestorNameById = (pricingVendor: PricingEngineVendor, pricingVendorInvestorId: number): string => {
    let matchedVendor = this.pricingVendors.find(v => v.value == pricingVendor);

    if (matchedVendor) {
      const investor = matchedVendor.investors.find(pro => pro.investorId == pricingVendorInvestorId);
      return investor?.name || '';
    }
    return '';
  }
}
