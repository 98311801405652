<div
  class="inline-editor"
  *ngIf="editorMode != 'InlineReadOnly' && !disabled"
  [ngClass]="{
    'input-group': editorMode == 'Inline',
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }"
  [hidden]="
    editorMode === 'Inline' &&
    !isEditActive &&
    !(this.model && this.model.touched && this.model.invalid) &&
    !disabled
  "
>
  <ng-select
    *ngIf="isMultiple"
    style="max-width: calc(100% - 40px) !important"
    [readonly]="readonly"
    [name]="name"
    [required]="required"
    [disabled]="disabled"
    [bindLabel]="optionNameKey"
    [bindValue]="optionValueKey"
    [groupBy]="groupingEnabled ? optionGroupByKey : null"
    [placeholder]="placeholder || 'Select Items'"
    class="custom-ng-select"
    #model="ngModel"
    #control
    [multiple]="true"
    [(ngModel)]="value"
    [items]="options"
    (focus)="isEditActive = true"
    (blur)="onBlur($event)"
    appendTo="body"
    [ngClass]="{ 'is-invalid': model && model.touched && model.invalid }"
  >
    <ng-template ng-optgroup-tmp let-item="item">
      {{ item[optionGroupByKey] || 'Unnamed group' }}
    </ng-template>
  </ng-select>

  <select
    *ngIf="!isMultiple"
    class="form-select"
    [(ngModel)]="value"
    #model="ngModel"
    #control
    name="{{ name }}"
    [readonly]="readonly"
    [required]="required"
    [id]="id"
    placeholder="{{ placeholder }}"
    [disabled]="disabled"
    (focus)="isEditActive = true"
    (blur)="onBlur($event)"
    [ngClass]="{ 'is-invalid': model && model.touched && model.invalid }"
  >
    <option *ngIf='!noneOptionDisabled' [ngValue]="null">--Select One--</option>

    <ng-container *ngIf="!groupingEnabled">
      <option [ngValue]="option[optionValueKey]" *ngFor="let option of options">
        {{ option[optionNameKey] }}
      </option>
    </ng-container>

    <ng-container *ngIf="groupingEnabled">
      <optgroup *ngFor="let group of groups" label="{{ group.groupName }}">
        <option [ngValue]="option[optionValueKey]" *ngFor="let option of group.options">
          {{ option[optionNameKey] }}
        </option>
      </optgroup>
    </ng-container>
  </select>

  <button
    class="btn p-0 border-0 bg-transparent ms-1"
    title="Apply"
    type="button"
    *ngIf="editorMode == 'Inline' && !disabled"
    (mousedown)="onApplyClicked($event)"
  >
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button
    class="btn p-0 border-0 bg-transparent ms-1"
    title="Cancel"
    type="button"
    *ngIf="editorMode == 'Inline' && !disabled"
    (mousedown)="onCancelClicked($event)"
  >
    <i class="fa fa-times-circle text-danger"></i>
  </button>
  <div class="invalid-feedback" *ngIf="!isMultiple">This field is required</div>
</div>

<span
  [ngClass]="{
    'empty-field': value == null || value == undefined
  }"
  class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}"
  title="Click to Edit"
  *ngIf="
    editorMode == 'Inline' &&
    !isEditActive &&
    !(model && model.touched && model.invalid) &&
    !disabled
  "
  (click)="onEditModeToggledOn()"
>
  <span class="val" *ngIf="value != null && value != undefined">{{
    getSelectedOptionName(value)
  }}</span>
  <span class="placeholder" *ngIf="value == null || value == undefined"
    >{{ inlinePlaceholder }}</span
  >
</span>

<span
  [ngClass]="{
    'empty-field': value == null || value == undefined
  }"
  class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}"
  *ngIf="editorMode == 'InlineReadOnly' || disabled"
>
  <span class="val" *ngIf="value != null && value != undefined">{{
    getSelectedOptionName(value)
  }}</span>
  <span class="placeholder" *ngIf="value == null || value == undefined"
    >{{ inlinePlaceholder }}</span
  >
</span>
