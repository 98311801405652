import { ExpressionGroup } from "src/app/models/expressions/expression-group.model";

export class ContactListSearchResult {
  contactListId: number;
  columns: ContactListSearchResultColumn[] = [];
  data: ContactListSearchResultRow[] = [];
  totalRecords: number;

  constructor(args?: ContactListSearchResult) {
    if (args) {
      this.contactListId = args.contactListId;
      this.columns = args.columns || [];
      this.data = args.data || [];
      this.totalRecords = args.totalRecords || 0;
    }
  }
}

export class ContactListSearchResultColumn {
  name: string;
  ordinal: number;
  dataType: string;
  fieldType: FieldType;
}

export class ContactListSearchResultRow {
  recordId: number;
  leadId: number;
  applicationId: number;
  agentId: number;
  borrowerId: number;
  externalCompanyId: number;
  creditMonitoringDataId: number;
  contactListRecordId: number;
  rowData: string[];
}

export class ContactListSearchExpressionParams {
  includeExpression: ExpressionGroup;
  excludeExpression: ExpressionGroup;
  ignoreUserFilter: boolean;
}

export enum FieldType {
  None = 'None',
  Email = 'Email',
  MobilePhone = 'MobilePhone',
  LandLinePhone = 'LandLinePhone',
  FaxPhone = 'FaxPhone',
  LoanType = 'LoanType',
  LoanPurpose = 'LoanPurpose',
  LoanStatus = 'LoanStatus',
  LeadStatus = 'LeadStatus',
  User = 'User',
  Product = 'Product',
  Lender = 'Lender',
  Enum = 'Enum',
  Currency = 'Currency',
  Rate = 'Rate',
  LeadCampaign = 'LeadCampaign',
  Branch = 'Branch',
  LeadRoute = 'LeadRoute',
  LeadRouteGroup = 'LeadRouteGroup',
  RedistributionRoute = 'RedistributionRoute',
  RedistributionRouteGroup = 'RedistributionRouteGroup',
  Multiline = 'Multiline',
  SubStatus = 'SubStatus',
  TimeZone = 'TimeZone',
  Custom = 'Custom',
  Channel = 'Channel',
  ChannelFlags = 'ChannelFlags',
  CompanyStatus = 'CompanyStatus',
  State = 'State',
  AgentTags = 'AgentTags',
  Holiday = 'Holiday',
  CompensationType = 'CompensationType',
  DisclosurePath = 'DisclosurePath',
  TpoDisclosurePathPermissions = 'TpoDisclosurePathPermissions'
}
