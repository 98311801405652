import { Component, Input } from "@angular/core";
import { WireRequestModel } from "../../../models/wire-request.model";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { formViewProvider } from "src/app/core/services/form-view.provider";

@Component({
  templateUrl: "create-wire-request-info.component.html",
  selector: "create-wire-request-info",
  viewProviders: [formViewProvider]
})
export class CreateWireRequestInfo {
  @Input()
  wireRequest: WireRequestModel;

  @Input()
  investorNames: EnumerationItem[] = [];

  constructor() {}
}