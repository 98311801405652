import { Component, Input, OnInit } from '@angular/core';
import { CustomData, CustomDataConfig } from 'src/app/models';
import { ApplicationContextService } from 'src/app/services/application-context.service'
import { CustomDataEditConfig } from './custom-field-editor/custom-data-edit-config.model';
import { AppDetailsService } from '../../app-details/services/app-details.service';

export enum CustomInfoTemplateType {
  Label_LEFT = "Label_LEFT",
  Label_BOTTOM = "Label_BOTTOM"
}
@Component({
  selector: 'custom-data-info',
  templateUrl: './custom-data-info.component.html',
  styleUrls: ['./custom-data-info.component.scss']
})
export class CustomDataInfoComponent implements OnInit {

  @Input()
  set customData(customData: CustomData) {
    this._customData = customData;
    this.reRender();
  }

  get customData(): CustomData {
    return this._customData;
  }

  @Input()
  isInClassicEditMode: boolean = true;

  @Input()
  templateType: CustomInfoTemplateType = CustomInfoTemplateType.Label_LEFT;

  @Input()
  labelAlignment: 'left' | 'right' = 'right';

  customDataConfig: CustomDataConfig = new CustomDataConfig();

  editConfigForField1: CustomDataEditConfig;
  editConfigForField2: CustomDataEditConfig;
  editConfigForField3: CustomDataEditConfig;
  editConfigForField4: CustomDataEditConfig;
  editConfigForField5: CustomDataEditConfig;
  editConfigForField6: CustomDataEditConfig;
  editConfigForField7: CustomDataEditConfig;
  editConfigForField8: CustomDataEditConfig;
  editConfigForField9: CustomDataEditConfig;
  editConfigForField10: CustomDataEditConfig;

  isLoading: boolean = false;

  private _customData: CustomData;

  constructor(
    private readonly _appService: AppDetailsService,
    private readonly _applicationContextService: ApplicationContextService
  ) {
  }

  ngOnInit() {

    this.isLoading = true;

    this._appService.getCustomDataConfig().subscribe(response => {
      this.customDataConfig = response || new CustomDataConfig();
      this.prepareEditConfigsForEachCustomField();
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  protected reRender = () => {
    this.isLoading = true;
    setTimeout(() => { this.isLoading = false; }, 100);
  }

  private prepareEditConfigsForEachCustomField = () => {
    this.editConfigForField1 = new CustomDataEditConfig(this.customDataConfig.label1Text,
      this.customDataConfig.editor1Type, "customData1", this.customDataConfig.customDropDownOptions1,
      this.customDataConfig.multiLookupType1);
    this.editConfigForField2 = new CustomDataEditConfig(this.customDataConfig.label2Text,
      this.customDataConfig.editor2Type, "customData2", this.customDataConfig.customDropDownOptions2,
      this.customDataConfig.multiLookupType2);
    this.editConfigForField3 = new CustomDataEditConfig(this.customDataConfig.label3Text,
      this.customDataConfig.editor3Type, "customData3", this.customDataConfig.customDropDownOptions3,
      this.customDataConfig.multiLookupType3);
    this.editConfigForField4 = new CustomDataEditConfig(this.customDataConfig.label4Text,
      this.customDataConfig.editor4Type, "customData4", this.customDataConfig.customDropDownOptions4,
      this.customDataConfig.multiLookupType4);
    this.editConfigForField5 = new CustomDataEditConfig(this.customDataConfig.label5Text,
      this.customDataConfig.editor5Type, "customData5", this.customDataConfig.customDropDownOptions5,
      this.customDataConfig.multiLookupType5);
    this.editConfigForField6 = new CustomDataEditConfig(this.customDataConfig.label6Text,
      this.customDataConfig.editor6Type, "customData6", this.customDataConfig.customDropDownOptions6,
      this.customDataConfig.multiLookupType6);
    this.editConfigForField7 = new CustomDataEditConfig(this.customDataConfig.label7Text,
      this.customDataConfig.editor7Type, "customData7", this.customDataConfig.customDropDownOptions7,
      this.customDataConfig.multiLookupType7);
    this.editConfigForField8 = new CustomDataEditConfig(this.customDataConfig.label8Text,
      this.customDataConfig.editor8Type, "customData8", this.customDataConfig.customDropDownOptions8,
      this.customDataConfig.multiLookupType8);
    this.editConfigForField9 = new CustomDataEditConfig(this.customDataConfig.label9Text,
      this.customDataConfig.editor9Type, "customData9", this.customDataConfig.customDropDownOptions9,
      this.customDataConfig.multiLookupType9);
    this.editConfigForField10 = new CustomDataEditConfig(this.customDataConfig.label10Text,
      this.customDataConfig.editor10Type, "customData10", this.customDataConfig.customDropDownOptions10,
      this.customDataConfig.multiLookupType10);
  }
}
