<!-- Since ng-content cannot be used more than once, it is enclosed in a
 template. -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf='href; else noHref'>
  <a [href]='href'>
    <ng-container *ngTemplateOutlet='content'>
    </ng-container>
  </a>
</ng-container>

<ng-template #noHref>
  <ng-container *ngTemplateOutlet='content'>
  </ng-container>
</ng-template>
