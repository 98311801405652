<div class="activity-info shadow-sm" [ngClass]="{ 'activity-info-unread': !mention.openDate }" (click)="onMentionClicked($event)">
  <img *ngIf="mentionTrackingUrl" [src]="mentionTrackingUrl" class="tracker-image" width="1" height="1" border="0">

  <div class="icon-info-activity">
    <div class="h-100 d-flex justify-content-between align-items-center">
      <img [src]="mention.fromAvatarUrl" alt="profile-user" class="rounded-circle thumb-sm" />
    </div>
  </div>
  <div class="activity-info-text">
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-1 font-13">
        <span>{{ mention.fromFullName }}</span>
      </p>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-1 font-13 w-75" [innerHTML]="mention.content | safeHtml"></p>
      <small class="text-muted float-end">{{ mention.timeAgo }}</small>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-0 font-13 w-75">
        <span *ngIf="borrowerLoading;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="!borrowerLoading">
          <a href="javascript:void(0)" id="appInMention" class="d-inline p-0">
            <i class="fas fa-user"></i> Borrower: {{ borrowerName }}
          </a>
        </span>
      </p>
      <p *ngIf="!mention.openDate" class="w-25 mb-0">
        <button class="btn btn-link btn-sm float-end text-success pe-0" (click)="markAsRead()">
          <i title="Mark As Read" class="far fa-check-square font-13"></i>
        </button>
      </p>
    </div>
  </div>
</div>
