import { NgModule } from '@angular/core';
import { CreditCredentialsConfigurationComponent } from './credit-credentials-configuration.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreditCredentialsConfigurationDialogComponent } from './credit-credentials-configuration-dialog/credit-credentials-configuration-dialog.component';

@NgModule({
  declarations: [
    CreditCredentialsConfigurationComponent,
    CreditCredentialsConfigurationDialogComponent
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    CreditCredentialsConfigurationComponent,
    CreditCredentialsConfigurationDialogComponent
  ],
})
export class CreditCredentialsConfigurationModule {
  constructor() { }
}
