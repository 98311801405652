import { Component, Injector, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { Borrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DisclosureTracking, DisclosureTrackingBorrower, DisclosureTrackingDocument } from '../../../models/disclosure-tracking.model';

@Component({
  selector: 'disclosure-tracking-details',
  templateUrl: 'disclosure-tracking-details.component.html',
  styleUrls: ['disclosure-tracking-details.component.scss']
})

export class DisclosureTrackingDetailsComponent extends ApplicationContextBoundComponent implements OnInit {

  selection1: any;

  @Input()
  disclosureTrackingItem: DisclosureTracking;

  @Input()
  appId: number;

  @Input()
  borrowers: Borrower[];

  @Input()
  user: User;

  @Input()
  primaryBorrower: Borrower;

  disclosureTypes: EnumerationItem[] = [];
  // selectedDisclosureType: string;
  sentMethod: string;
  sentMethodPlus: string;
  isIntentToProceed: boolean = false;
  coBorrowers: DisclosureTrackingBorrower[] = [];
  userBy: string;

  columns: any[];

  protected isReadonly: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _enumerationService: EnumerationService,
    private readonly _loanDocService: LoanDocsService,
    private readonly _spinnerService: NgxSpinnerService) {
    super(injector);
  }

  ngOnInit() {
    this.isReadonly = this.disclosureTrackingItem.losDisclosureTrackingId ? true : false;

    this.columns = [
      { field: 'title', header: 'Title' }
    ];
    if (this.disclosureTrackingItem) {
      // this.selectedDisclosureType = this.disclosureTrackingItem.disclosureVersion;
      this.sentMethod = this.disclosureTrackingItem.borrowers[0].receivedMethod;
      this.userBy = Utils.getPersonsFullName(this.user);
    }
    if (this.disclosureTrackingItem.intentToProceedReceived) {
      this.isIntentToProceed = true;
    }
    this.disclosureTypes = this._enumerationService.disclosureTypes;
    const copyOfBorrowers = _.cloneDeep(this.disclosureTrackingItem.borrowers);
    copyOfBorrowers.shift(); //to identify co-borrowers, delete first element from array.
    this.coBorrowers = copyOfBorrowers;
  }

  getCoBorrowerName = (borrower: DisclosureTrackingBorrower) => {
    const coBorrower = this.borrowers.find(b => b.borrowerId === borrower.borrowerId);
    if (coBorrower) {
      return Utils.getPersonsFullName(coBorrower);
    }
  }

  onViewDocumentClicked = (doc: DisclosureTrackingDocument) => {
    this._spinnerService.show();
    this._loanDocService.viewDocFileContent(this.appId,this.applicationContext.userPermissions.userId, doc.docFileId, doc.losDocFileId )
      .subscribe({
        next: (data) => {
          this._spinnerService.hide();
          const blob = new Blob([data], { type: data['type'] });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        error: () => {
          this._spinnerService.hide();
        }
      }).add(() => this._spinnerService.hide());
  }

  onIntentToProceedChanged = () => {
    if (!this.disclosureTrackingItem.intentToProceedReceived && !this.isIntentToProceed) {
      const today = new Date();
      this.disclosureTrackingItem.intentToProceedReceived = today;
    }
    else if (this.isIntentToProceed) {
      this.disclosureTrackingItem.intentToProceedReceived = null;
    }
  }

}
