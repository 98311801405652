<ng-container *ngIf="!isCarouselViewLoading; else loading">
  <div class="container-fluid g-0">
    <p-carousel [showNavigators]="scenarios.length > 6"
      [value]="scenarios" [numVisible]="6" [numScroll]="6" [circular]="true" [responsiveOptions]="responsiveOptions">
      <ng-template let-scenario pTemplate="item">
        <div class="scenario-item">
          <div class="scenario-item-content me-1" style="min-width: 265px;" [ngStyle]="{'max-width': scenarios.length <= 5 ? '265px' : null}">
            <pinned-scenario-card #scenarioCard
              [scenario]="scenario"
              [index]="scenario['index']"
              (createLoanClicked)="onCreateLoanClicked($event)"
              (deleteScenarioClicked)="onDeleteScenarioClicked($event)"
              (editScenarioClicked)="onScenarioEditClicked($event)"
              (loanQuoteClicked)="onLoanQuoteClicked($event)"
              (rateAprEditClicked)="onRateAprEditClicked($event)"
              (feesEditClicked)="onFeesEditClicked($event)"
              (refreshCompleted)="onCardRefreshCompleted($event)"
              [displayExpensesOverlay] = "!turnOffPrimeNgFeaturesDueToHtmlEditorPrimeNgConflict"
              (applyScenarioToLoanClicked)="onApplyScenarioToLoanClicked($event)"
              [applicationId]="applicationId"
              [isLoanReadOnly]="isLoanReadOnly">
            </pinned-scenario-card>
          </div>
        </div>
      </ng-template>
    </p-carousel>
    <div *ngIf="scenarios.length == 0">
      <h5 class="text-center text-muted"> <span> There are no saved scenarios. </span> </h5>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="offset-lg-0 offset-xxl-1 offset-xxxl-2 col-lg-12 col-xxl-10 col-xxxl-8">
    <loading-indicator [loadingMessage]="'Please wait...'" [customClass]="'init-loading-indicator'"></loading-indicator>
  </div>
</ng-template>
