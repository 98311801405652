import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer } from 'rxjs';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { MortgageCalculationDetails, MortgageFundsToCloseCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { InitialFeesWorksheetDialog } from 'src/app/modules/pricing/components/initial-fees-worksheet-dialog/initial-fees-worksheet-dialog.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { FeeService } from 'src/app/services/fee.service';
import { MortgageService } from 'src/app/services/mortgage.service';

@Component({
  selector: 'loan-ftc-summary',
  templateUrl: 'loan-ftc-summary.component.html',
  styleUrls: ['loan-ftc-summary.component.scss']
})
export class LoanFtcSummaryComponent implements OnInit, OnChanges {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  set ftc(value: MortgageFundsToCloseCalculationDetails) {
    if (value) {
      this._ftcDetails = value;
    }
  }

  get ftc(): MortgageFundsToCloseCalculationDetails {
    return this._ftcDetails;
  }

  @Output() ftcRecalculationStarted = new EventEmitter<any>();
  @Output() ftcRecalculationCompleted = new EventEmitter<MortgageFundsToCloseCalculationDetails>();

  @Input()
  amounts: any = {};

  @Input()
  isRefinance: boolean;

  loanFees: LoanFee[] = null;
  payoffs: number;

  isExceedFtc: boolean;

  protected recalculatingFtc: boolean = false;

  private _ftcDetails: MortgageFundsToCloseCalculationDetails = null;

  constructor(
    private readonly _feeService: FeeService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _mortgageService: MortgageService,
    private readonly _applicationContextService: ApplicationContextService
  ) { }

  ngOnInit() {
    this.fetchFees();
  }

  ngOnChanges(changes: SimpleChanges) {
    const ftcChange = changes['ftc'];
    const mortgageChange = changes['mortgage'];

    if ((ftcChange && ftcChange.currentValue) || (mortgageChange && mortgageChange.currentValue)) {
      this.calculateIfTotalAssetsExceedsFtc();
    }
  }

  onRefreshClicked() {
    this.recalculateFtc();
  }

  onLoanQuoteClicked() {
    const modalRef = this._modalService.open(InitialFeesWorksheetDialog, Constants.modalOptions.xlarge);
    modalRef.componentInstance.appId = this.mortgage.applicationId;
    modalRef.componentInstance.fees = this.loanFees;
    this._applicationContextService.sendEmailDialogLaunched();

    modalRef.result.then(() => {
      this._applicationContextService.sendEmailDialogClosed();
    }, err => {
      this._applicationContextService.sendEmailDialogClosed();
    });
  }

  private fetchFees = () => {
    this._feeService.getFees(this.mortgage.applicationId).subscribe(loanFees => {
      this.loanFees = loanFees;
    });
  }

  private recalculateFtc = () => {
    if (this.mortgage) {
      this.ftcRecalculationStarted.emit();
      this.recalculatingFtc = true;
      this._ftcDetails = null;
      this._spinner.show();
      const observer: Observer<MortgageCalculationDetails> = {
        next: (calculationDetails => {
          this._ftcDetails = calculationDetails.fundsToClose;
          this.calculateIfTotalAssetsExceedsFtc();
        }),
        error: (error => {

        }),
        complete: (() => {
          this._spinner.hide();
          this.recalculatingFtc = false;
          this.ftcRecalculationCompleted.emit(this._ftcDetails);
        })
      }
      this._mortgageService.getMortgageCalculationDetails(this.mortgage.mortgageId).subscribe(observer);
    }
  }

  private calculateIfTotalAssetsExceedsFtc = () => {
    let cashMarketSubTotal = 0;
    this.mortgage?.assets?.forEach(asset => {
      cashMarketSubTotal += Number(asset.cashMarketValue || 0);
    });

    this.isExceedFtc = cashMarketSubTotal >= this._ftcDetails.cashFromToBorrower;
  }
}
