export class InternalContact {
    applicationId: number;
    companyId?: number;
    dateInserted?: string;
    dateUpdated?: string;
    insertedBy?: string;
    internalContactId?: number;
    roleId: number;
    updatedBy?: string;
    userId: string;
    email?: string;
    fax?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;

    constructor(roleId: number, userId: string, applicationId: number) {
        this.roleId = roleId;
        this.userId = userId;
        this.applicationId = applicationId;
    }
}
