<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm"
    (ngSubmit)="saveCredential()" novalidate>

    <div class="modal-header">
        <h5 class="modal-title">DDA Credential</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Alias</label>
                <input type="text" class="form-control" name="alias"
                    [ngClass]="{'is-invalid': alias && alias.touched && alias.invalid}" #alias="ngModel"
                    [(ngModel)]="credential.alias" required>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Url</label>
                <input type="text" class="form-control" name="url" [(ngModel)]="credential.url" disabled>
            </div>
        </div>
        <div class="row">
            <div class="mt-1 col-md-6">
                <label class="custom-control-label">Username</label>
                <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName"
                    [ngClass]="{'is-invalid': userNameField && userNameField.touched && userNameField.invalid}"
                    #userNameField="ngModel" required>
            </div>
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Password</label>
                <password-input [name]="'password'" [(ngModel)]="credential.password"
                    [required]="true"></password-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Application Secret</label>
                <password-input [name]="'appSecret'" [(ngModel)]="ddaKeyValuePairs.applicationSecret"
                    [required]="true"></password-input>
            </div>
        </div>
        <div class="row">
            <div class="mt-1 col-md-6">
                <label class="custom-control-label">Site Address</label>
                <input type="text" class="form-control" name="siteAddress" [(ngModel)]="ddaKeyValuePairs.siteAddress"
                    [ngClass]="{'is-invalid': siteAddressField && siteAddressField.touched && siteAddressField.invalid}"
                    #siteAddressField="ngModel" required>
            </div>
            <div class="col-md-6 mt-1">
                <label class="custom-control-label">Cabinet Id</label>
                <input type="text" class="form-control" name="siteAddress" [(ngModel)]="ddaKeyValuePairs.cabinetId"
                    [ngClass]="{'is-invalid': cabinetIdField && cabinetIdField.touched && cabinetIdField.invalid}"
                    #cabinetIdField="ngModel" required>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="fieldset-title mt-4">
                    <div class="row">
                        <div class="col-md-6 pt-2">DDA Imported Fields</div>
                        <div class="col-md-6 pt-2 text-end">
                            <button type="button" class="btn btn-primary right" [disabled]="importing"
                                (click)="import()">
                                <span *ngIf="!importing">Import</span>
                                <span *ngIf="importing">
                                    <span class="spinner-border spinner-border-sm"></span> Importing
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 mt-2">
                <label class="custom-control-label">Site ID</label>
            </div>
            <div class="col-md-9 mt-2">
                <input type="text" class="form-control" name="siteId" [(ngModel)]="ddaKeyValuePairs.siteId" disabled>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 mt-2">
                <label class="custom-control-label">Gateway Base URL</label>
            </div>
            <div class="col-md-9 mt-2">
                <input type="text" class="form-control" name="gatewayBaseURL"
                    [(ngModel)]="ddaKeyValuePairs.gatewayBaseURL" disabled>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 mt-2">
                <label class="custom-control-label">Upload Base URL</label>
            </div>
            <div class="col-md-9 mt-2">
                <input type="text" class="form-control" name="uploadBaseURL"
                    [(ngModel)]="ddaKeyValuePairs.uploadBaseURL" disabled>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 mt-2">
                <label class="custom-control-label">Doc Explorer URL</label>
            </div>
            <div class="col-md-9 mt-2">
                <input type="text" class="form-control" name="docExplorerURL"
                    [(ngModel)]="ddaKeyValuePairs.docExplorerURL" disabled>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 mt-2">
                <label class="custom-control-label">Download Base URL</label>
            </div>
            <div class="col-md-9 mt-2">
                <input type="text" class="form-control" name="downloadBaseURL"
                    [(ngModel)]="ddaKeyValuePairs.downloadBaseURL" disabled>
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button type="submit" class="btn btn-primary right" [disabled]="isSaving">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>

</form>